import AxiosHttpApi from "../core/AxiosHttpApi";
const BASE_TARGET = `/users`;

class UserHttpApi extends AxiosHttpApi {
  signIn(data) {
    return this.postRequest(`${BASE_TARGET}/login`, data);
  }

  signUp(data) {
    return this.postRequest(`${BASE_TARGET}/`, data);
  }

  getUser() {
    return this.getRequest(`${BASE_TARGET}/authenticated`);
  }

  resetPassword(data) {
    return this.postRequest(`${BASE_TARGET}/resetpassword`, data);
  }

  confirmResetPassword(data) {
    return this.postRequest(`${BASE_TARGET}/resetpassword/confirmation`, data);
  }

  updateUserData(data) {
    return this.putRequest(`${BASE_TARGET}/authenticated`, data);
  }

  updateUserPassword(data) {
    return this.putRequest(
      `${BASE_TARGET}/authenticated?action=updatePassword`,
      data
    );
  }

  sendActivationEmail(data) {
    return this.postRequest(`${BASE_TARGET}/send_activation_email`, data);
  }

  decreasePurchasedDceQty(user, qty, aoref, sourceDb) {
    return this.getRequest(`${BASE_TARGET}/decrease_credit_dce/${user.id}/${qty}/${aoref}/${sourceDb}`);
  }

  getDetailAo(userid, aoref) {
    return this.getRequest(`${BASE_TARGET}/getdetailao/${userid}/${aoref}`);
  }

  allowUserToVisualizeMOE(projectId) {
    return this.postRequest(`${BASE_TARGET}/allowvisualizemoe`,{ projectId });
  }

}

export default new UserHttpApi();
