import paymentHttpApi from "../../api/paymentHttpApi";
import { getErrorMessage } from "../../helpers/errorHelper";
import { showNotif } from "./appActionCreators";
import paymentActionTypes from "../actionsTypes/paymentActionTypes";
import userActionTypes from "../actionsTypes/userActionTypes";
import { getRouterHistory } from "../../helpers/routerHelper";
import {
  clearCart,
  clearPromoCode,
  setPendingOrderStep,
} from "./orderActionCreators";
import {
  notifMessages,
  notifTypes,
} from "../../constants/notificationConstants";
import {
  mappedPaymentStatusPage,
  paymentStatus,
} from "../../constants/paymentConstants";

/**
 *  Créer le paiement
 *
 * @async
 * @param {Stripe} stripe
 * @param {StripeElements} elements
 * @param {CardElementComponent} CardElementComp
 */
export const createPayment =
  (stripe, elements, CardElement) => async (dispatch, getState) => {
    const history = getRouterHistory();
    try {
      const { user } = getState().userReducer;
      const { promoCodeData, cartId } = getState().orderReducer;
      const { error, paymentMethod } = await createPaymentMethod({
        stripe,
        elements,
        CardElement,
        user,
      });
      if (error) throw error;

      const { id } = paymentMethod;

      const paymentData = {
        paymentMethodId: id,
        stripeId: user.stripeId,
        orderId: cartId,
        promoCodeId: promoCodeData?.id,
      };
      const response = await paymentHttpApi.createOneTimePayment(paymentData);
      const { data } = response;
      const receivedPaymentStatus = data?.data?.paymentStatus;
      if (receivedPaymentStatus === paymentStatus.requires_action) {
        const result = await stripe.confirmCardPayment(
          data?.data?.clientSecret
        );
        if (result.error) {
          await paymentHttpApi.detachPaymentMethod(paymentMethod.id);
          dispatch(showNotif(notifMessages.paymentHasFailed));
          return;
        }
      }

      dispatch(clearCart());
      dispatch(clearPromoCode());
      dispatch(setPendingOrderStep(false));
      history.push(
        `/paymentstatus?key=${mappedPaymentStatusPage[receivedPaymentStatus]}`
      );
      dispatch(showNotif({ message: data?.message, type: notifTypes.success }));
    } catch (err) {
      dispatch(
        showNotif({ message: getErrorMessage(err), type: notifTypes.error })
      );
    }
  };

/**
 *  Créer un paiement avec le mode de paiement sélectionné
 *
 * @async
 * @param {Stripe} paymentMethodId
 */
export const createPaymentWithSelectedMethod =
  (stripe, paymentMethodId) => async (dispatch, getState) => {
    const history = getRouterHistory();
    try {
      const { user } = getState().userReducer;
      const { promoCodeData, cartId } = getState().orderReducer;
      const paymentData = {
        paymentMethodId,
        stripeId: user.stripeId,
        orderId: cartId,
        promoCodeId: promoCodeData?.id,
      };
      const response = await paymentHttpApi.createOneTimePayment(paymentData);
      const { data } = response;
      const receivedPaymentStatus = data?.data?.paymentStatus;
      if (receivedPaymentStatus === paymentStatus.requires_action) {
        const result = await stripe.confirmCardPayment(
          data?.data?.clientSecret
        );
        if (result.error) {
          dispatch(showNotif(notifMessages.paymentHasFailed));
          return;
        }
      }
      dispatch(clearCart());
      dispatch(clearPromoCode());
      dispatch(setPendingOrderStep(false));
      history.push(
        `/paymentstatus?key=${mappedPaymentStatusPage[receivedPaymentStatus]}`
      );
      dispatch(showNotif({ message: data?.message, type: notifTypes.success }));
    } catch (err) {
      dispatch(
        showNotif({ message: getErrorMessage(err), type: notifTypes.error })
      );
    }
  };

/**
 *  Créer et ouvrir la session du portail stripe
 *
 * @async
 */
export const createPortalSession = () => async (dispatch, getState) => {
  try {
    const { user } = getState().userReducer;
    const response = await paymentHttpApi.createPortalSession(user);
    const { status, data } = response;
    if (status === 200) {
      const url = data?.data?.portalSession?.url;
      if (url) {
        window.open(url, "_blank");
      }
    }
  } catch (err) {
    dispatch(
      showNotif({ message: getErrorMessage(err), type: notifTypes.error })
    );
  }
};

/**
 *  Créer l'abonnement
 *
 * @async
 */
export const createSubscription =
  (stripe, elements, CardElement, project) => async (dispatch, getState) => {
    const history = getRouterHistory();
    try {
      const { user } = getState().userReducer;
      const { promoCodeData, cartId } = getState().orderReducer;

      const { error, paymentMethod } = await createPaymentMethod({
        stripe,
        elements,
        CardElement,
        user,
      });

      if (error) throw error;

      const response = await paymentHttpApi.createSubscription({
        user,
        paymentMethodId: paymentMethod.id,
        orderId: cartId,
        promoCodeId: promoCodeData?.id,
      });
      const { status, data } = response;
      if (status === 200) {
        const receivedPaymentStatus = data?.data?.paymentStatus;
        if (receivedPaymentStatus === paymentStatus.requires_action) {
          const result = await stripe.confirmCardPayment(
            data?.data?.clientSecret
          );
          if (result.error) {
            await paymentHttpApi.detachPaymentMethod(paymentMethod.id);
            dispatch(showNotif(notifMessages.paymentHasFailed));
            return;
          }
        }

        dispatch(clearCart());
        dispatch(clearPromoCode());
        dispatch(setPendingOrderStep(false));
        dispatch({
          type: userActionTypes.UPDATE_USER,
          payload: data?.data?.user,
        });
        if (
          receivedPaymentStatus === paymentStatus.paid ||
          receivedPaymentStatus === paymentStatus.succeeded
        )
          history.push("/account/subscriptionsucceeded", { project });
        else
          history.push(
            `/paymentStatus?key=${mappedPaymentStatusPage[receivedPaymentStatus]}`
          );
      }
    } catch (err) {
      const message = getErrorMessage(err);
      if (message.includes("User already have a subscription")) {
        return dispatch(createPortalSession());
      }
      dispatch(showNotif({ message, type: notifTypes.error }));
    }
  };

const createPaymentMethod = (data) => {
  const { stripe, elements, CardElement, user } = data;

  const billing_details = {
    address: {
      city: user.city,
      line1: user.address,
      postal_code: user.cp,
    },
    email: user.billingEmail,
    name: user.entrepriseName,
    phone: user.phone,
  };

  return stripe.createPaymentMethod({
    type: "card",
    card: elements.getElement(CardElement),
    billing_details,
  });
};

/**
 *  Créer l'abonnement avec le mode de paiement sélectionné
 *
 * @async
 * @param paymentMethodId
 */
export const createSubscriptionWithPaymentMethod =
  (stripe, paymentMethodId, project) => async (dispatch, getState) => {
    const history = getRouterHistory();
    try {
      const { user } = getState().userReducer;
      const { promoCodeData, cartId } = getState().orderReducer;

      const response = await paymentHttpApi.createSubscription({
        user,
        paymentMethodId,
        orderId: cartId,
        promoCodeId: promoCodeData?.id,
      });
      const { status, data } = response;
      if (status === 200) {
        const receivedPaymentStatus = data?.data?.paymentStatus;
        if (receivedPaymentStatus === paymentStatus.requires_action) {
          const result = await stripe.confirmCardPayment(
            data?.data?.clientSecret
          );
          if (result.error) {
            dispatch(showNotif(notifMessages.paymentHasFailed));
            return;
          }
        }

        dispatch(clearCart());
        dispatch(clearPromoCode());
        dispatch(setPendingOrderStep(false));
        dispatch({
          type: userActionTypes.UPDATE_USER,
          payload: data?.data?.user,
        });
        if (
          receivedPaymentStatus === paymentStatus.paid ||
          receivedPaymentStatus === paymentStatus.succeeded
        )
          history.push("/account/subscriptionsucceeded", { project });
        else
          history.push(
            `/paymentStatus?key=${mappedPaymentStatusPage[receivedPaymentStatus]}`
          );
      }
    } catch (err) {
      const message = getErrorMessage(err);
      if (message.includes("User already have a subscription")) {
        return dispatch(createPortalSession());
      }
      dispatch(showNotif({ message, type: notifTypes.error }));
    }
  };

/**
 *  Récupérer la liste des paiements de l'utilisateur
 *
 * @async
 */
export const initPayments = () => async (dispatch, getState) => {
  try {
    const { user } = getState().userReducer;
    const res = await paymentHttpApi.getPayments(user.id);
    dispatch({
      type: paymentActionTypes.INIT_PAYMENTS,
      payload: res?.data?.data,
    });
  } catch (err) {
    showNotif({ message: getErrorMessage(err), type: notifTypes.error });
  }
};

/**
 *  Récupérer les méthodes de paiement
 *
 * @async
 */
export const initPaymentMethods = () => async (dispatch, getState) => {
  const { user } = getState().userReducer;
  const res = await paymentHttpApi.getPaymentMethods(user.stripeId);
  dispatch({
    type: paymentActionTypes.INIT_PAYMENT_METHODS,
    payload: res?.data?.data,
  });
};

/**
 *  Créer Iban paiement
 *
 * @async
 * @param {Stripe} stripe
 * @param {StripeElements} elements
 * @param {CardElementComponent} CardElementComp
 */

export const createIbanPayment =
  (stripe, elements, IbanElement, history) => async (dispatch, getState) => {
    try {
      const { user } = getState().userReducer;
      const { promoCodeData, cartId } = getState().orderReducer;

      const paymentMethodResponse = await stripe.createPaymentMethod({
        type: "sepa_debit",
        sepa_debit: elements.getElement(IbanElement),
        billing_details: {
          name: user.lastname + " " + user.firstname,
          email: user.email,
        },
      });

      if (paymentMethodResponse?.error) throw paymentMethodResponse?.error;

      const paymentMethodId = paymentMethodResponse?.paymentMethod?.id;

      if (!paymentMethodId) return dispatch(showNotif());

      const res = await paymentHttpApi.createOneTimePayment({
        stripeId: user.stripeId,
        paymentMethodId,
        orderId: cartId,
        promoCodeId: promoCodeData?.id,
      });

      const clientSecret = res?.data?.data?.clientSecret;

      const confirmationResponse = await stripe.confirmSepaDebitPayment(
        clientSecret,
        {
          payment_method: paymentMethodId,
        }
      );

      const paymentStatus = confirmationResponse?.paymentIntent?.status;

      dispatch(clearCart());
      dispatch(clearPromoCode());
      dispatch(setPendingOrderStep(false));
      history.push(
        `/paymentstatus?key=${mappedPaymentStatusPage[paymentStatus]}`
      );

      const notifToShow = notifMessages[mappedPaymentStatusPage[paymentStatus]];

      dispatch(showNotif(notifToShow));
    } catch (err) {
      dispatch(
        showNotif({ message: getErrorMessage(err), type: notifTypes.error })
      );
    }
  };

export const createSetupIntent =
  (stripe, elements, IbanElement, history, project) => async (dispatch, getState) => {
    try {
      const { user } = getState().userReducer;
      const { promoCodeData, cartId } = getState().orderReducer;
      const res = await paymentHttpApi.createSetupIntent({
        stripeId: user.stripeId,
      });
      const { setupIntent } = res?.data?.data;

      const clientSecret = setupIntent.client_secret;

      const result = await stripe.confirmSepaDebitSetup(clientSecret, {
        payment_method: {
          sepa_debit: elements.getElement(IbanElement),
          billing_details: {
            name: user.lastname + " " + user.firstname,
            email: user.email,
          },
        },
      });

      const paymentMethodId = result?.setupIntent?.payment_method;

      if (result.error) {
        // Show error to your customer.
        throw result.error;
      } else {
        const response = await paymentHttpApi.createSubscription({
          user,
          paymentMethodId,
          orderId: cartId,
          promoCodeId: promoCodeData?.id,
        });
        const { status, data } = response;
        if (status === 200) {
          const receivedPaymentStatus = data?.data?.paymentStatus;
          if (receivedPaymentStatus === paymentStatus.requires_action) {
            const confirmationResult = await stripe.confirmCardPayment(
              data?.data?.clientSecret
            );
            if (confirmationResult.error) {
              await paymentHttpApi.detachPaymentMethod(paymentMethodId);
              dispatch(showNotif(notifMessages.paymentHasFailed));
              return;
            }
          }
          dispatch(clearCart());
          dispatch(clearPromoCode());
          dispatch(setPendingOrderStep(false));
          dispatch({
            type: userActionTypes.UPDATE_USER,
            payload: data?.data?.user,
          });
          if (
            receivedPaymentStatus === paymentStatus.paid ||
            receivedPaymentStatus === paymentStatus.succeeded
          )
            history.push("/account/subscriptionsucceeded",{ project });
          else
            history.push(
              `/paymentStatus?key=${mappedPaymentStatusPage[receivedPaymentStatus]}`
            );
        }
      }
    } catch (err) {
      console.log({ err });
    }
  };
