import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { VStack } from "@chakra-ui/react";
import { BrowserRouter } from "react-router-dom";
import NavBar from "./includes/NavBar/NavBar";
import Main from "./routes/Main";
import Footer from "./includes/Footer/Footer";
import Loader from "./includes/Loader/Loader";
import Notifications from "./components/Notifications/Notifications";
import { bootApp } from "./redux/actionsCreators/generalActionCreator";
import { StickyContainer } from "react-sticky";
import HeadMetas from "./includes/HeadMetas/HeadMetas";

const App = () => {
  const { isLoading } = useSelector((state) => state.appReducer);
  const { isLoadingUser } = useSelector((state) => state.userReducer);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(bootApp());
    return () => {};
  }, [dispatch]);

  return (
    <BrowserRouter>
      <VStack width="100%" minHeight="100vh">
        <HeadMetas />
        <Loader isLoading={isLoading} />
        <NavBar />
        <StickyContainer
          style={{
            position: "relative",
            width: "100%",
            flex: 1,
            margin: 0,
            display: "flex",
          }}
        >
          <Notifications />
          {!isLoadingUser && <Main />}
        </StickyContainer>
      </VStack>
      <Footer />
    </BrowserRouter>
  );
};

export default App;
