import axios from "axios";
import { getToken } from "../helpers/sessionHelper";

class AxiosHttpApi {
  constructor(options = false) {
    this.options = options;
    this.initAxiosInstance(options);
  }

  initAxiosInstance = () => {
    this.axios = axios.create(this.makeOptions());
  };

  makeOptions = () => {
    if (this.options) return this.options;
    const defaultOptions = {
      baseURL: process.env.REACT_APP_API_URL,
      headers: {
        "Content-Type": "application/json",
      },
      // timeout: 6000,
    };

    const token = getToken();
    if (token) {
      defaultOptions.headers["Authorization"] = `Bearer ${token}`;
    }
    return defaultOptions;
  };

  getRequest = (target) => {
    this.initAxiosInstance();
    return this.axios.get(target);
  };
  postRequest = (target, data, signal) => {
    this.initAxiosInstance();
    return this.axios.post(target, data, { signal });
  };
  putRequest = (target, data) => {
    this.initAxiosInstance();
    return this.axios.put(target, data);
  };
  deleteRequest = (target) => {
    this.initAxiosInstance();
    return this.axios.delete(target);
  };
}

new AxiosHttpApi({});

export default AxiosHttpApi;
