import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  ModalFooter,
} from "@chakra-ui/react";
import React from "react";
import CustomButton from "../../shared/Button/CustomButton";

const TermsConditionsModal = ({ onClose, isOpen, handleAcceptConditions }) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} scrollBehavior="inside">
      <ModalOverlay />
      <ModalContent
        maxW={"unset"}
        width={"800px"}
        height={"85%"}
        overflow={"hidden"}
        backgroundColor={"#efefef"}
      >
        <ModalHeader> Conditions générales d'utilisation</ModalHeader>
        <ModalCloseButton />
        <ModalBody backgroundColor={"#fff"}>
          <Text fontWeight="bold" mb="0.6rem">
            1. Objet et contenu des Conditions Générales
          </Text>

          <Text mb="1.2rem" textAlign="justify">
            La société WANAO, société par actions simplifiée à associé unique au capital de 138 200 euros, dont le siège est situé au Technopole Izarbel – 230, allée Théodore Monod à Bidart (64210), immatriculée au registre du commerce et des sociétés de Bayonne sous le numéro 438 772 089, (« Wanao »)  produit et commercialise une plateforme d’assistance à la recherche de marchés publics et à la gestion des candidatures auxdits marchés (la « Plateforme EasyMarchés »).
            <br />Les présentes conditions générales (les « Conditions Générales ») définissent les conditions dans lesquelles Wanao fournit à son client (le « Client ») l’accès à la Plateforme EasyMarchés et les services associés (les « Services »).
            <br />Le fait de passer commande de Services à Wanao implique l’adhésion pleine et entière, sans réserve ni condition, du Client aux présentes Conditions Générales, à l’exclusion de tout autre document, tels que les propositions ou documents commerciaux, les prospectus ou les catalogues émis par Wanao qui n’ont qu’une valeur indicative.
            <br />Les Conditions Générales ainsi que les conditions particulières choisies (c’est-à-dire l’offre souscrite) sur la Platefome EasyMarchés constituent l'intégralité du contrat conclu entre Wanao et le Client (le « Contrat »).
          </Text>
          <Text fontWeight="bold" mb="0.6rem">
            2. Souscription des Services
          </Text>

          <Text mb="1.2rem" textAlign="justify">
            La commande de Services auprès de Wanao est réservée uniquement à des clients professionnels agissant dans le cadre de leur activité professionnelle.
            <br />
            Les Services sont déclinés en deux offres :
            <br />
            - une offre dite de découverte permettant d’accéder aux services de façon limitée (l’« Offre Découverte ») ;
            <br />
            - une offre dite premium permettant d’accéder pendant une durée prédéterminée et renouvelable automatiquement aux Services dans le cadre d’un abonnement (l’ « Offre Premium » et l’« Abonnement »).
            <br />
            L’Offre Découverte ne peut être souscrite par une même personne qu’une seule fois. Le Client s’engage à ne pas souscrire plusieurs fois, simultanément ou de façon différée, l’Offre Découverte, que ce soit par l’intermédiaire d’une même personne physique ou de plusieurs personnes physiques. Wanao se réserve le droit de mettre fin immédiatement à tout Contrat qui serait souscrit en violation de cette règle, sans préavis, sans décision judiciaire préalable et sans indemnité pour le Client, par simple clôture du Compte EasyMarchés concerné doublé d’une notification adressée à l’adresse électronique rattachée audit Compte EasyMarchés, et ce, sans préjudice de tout dommage et intérêt auquel Wanao pourra prétendre.
            <br />
            Le Client souscrit en ligne aux Services sur la Plateforme en :
            <br />
            - créant un compte sur la Plateforme EasyMarchés (le « Compte EasyMarchés ») ;
            <br />
            - sélectionnant l’offre qu’il souhaite retenir (qui constituent les conditions particulières du Contrat ;
            <br />
            - acceptant les présentes Conditions Générales.
            <br />
            Lors de la souscription des Services, le Client s’engage à remplir correctement les formulaires de création de compte et de souscription aux Services, et notamment à ne communiquer que des informations exactes, à jour et complètes.
            <br />
            Si Wanao a connaissance que tout ou partie des informations communiquées sont erronées, peu précises, obsolètes, fantaisistes ou incomplètes, elle se réserve le droit de clôturer le Compte EasyMarchés et/ou de bloquer tout nouvel usage des Services, sans préjudice de tout autre recours ou action.
          </Text>
          <Text fontWeight="bold" mb="0.6rem">
            3. Définition des Services
          </Text>

          <Text mb="1.2rem" textAlign="justify">
            Les Services pouvant être fournis par Wanao, en fonction de l’Offre Découverte ou de l’Offre Premium, incluent :
            <br />
            - la possibilité pour le Client d’effectuer des recherches portant sur des appels d’offres de marchés publics français publiés par les services de l'Etat en administration centrale et en services déconcentrés, les établissements publics de l'Etat et des organismes, les autorités publiques indépendantes, les autorités administratives indépendantes, les groupements d'intérêt public, les groupements d'intérêt économique investis d'une mission de service public d'intérêt national, les organismes de sécurité sociale, de l'Union des caisses nationales de sécurité sociale et de la Caisse des Dépôts et Consignations (les « Marchés ») ;
            <br />
            - la possibilité pour le Client d’effectuer des recherches portant sur les avis et Marchés clôturés, ainsi que les avis d’attribution des Marchés (uniquement dans le cadre de l’Offre Premium) ;
            <br />
            - la possibilité de sauvegarder les recherches effectuées (uniquement dans le cadre de l’Offre Premium) ;
            <br />
            - un accès plus ou moins étendu aux informations et documents afférents à chaque Marché (les « Informations ») ;
            <br />
            - la possibilité d’analyser et de télécharger un nombre limité de dossiers de consultation des entreprises des Marchés (les « DCE »).
          </Text>
          <Text fontWeight="bold" mb="0.6rem">
            4. Consommation de DCE
          </Text>

          <Text mb="1.2rem" textAlign="justify">
            Le nombre de DCE inclus dans l’offre souscrite par le Client est indiqué sur la Plateforme EasyMarchés.
            <br />
            En cas de souscription à l’Offre Découverte, le nombre de téléchargements de DCE non consommé ne pourra pas être utilisé à la fin du Contrat et ne sera pas remboursé au Client.
            <br />
            En cas de souscription à l’Offre Premium :
            <br />
            en cas de consommation intégrale du crédit de téléchargement de DCE octroyé au Client, le Client pourra acheter un nouveau lot de DCE sur la Plateforme EasyMarchés en se rendant à la rubrique « nos offres » et en sélectionnant le pack de son choix
            <br />
            - le nombre de téléchargements de DCE octroyé au Client est automatiquement renouvelé à chaque nouvelle période contractuelle de l’Abonnement ;
            <br />
            - lors de ce renouvellement, aucun report de crédit non consommé ne sera fait. Ainsi, le crédit de téléchargement de DCE non consommé durant une période contractuelle ne pourra pas être utilisé à la fin de cette période et ne sera pas remboursé au Client.
          </Text>
          <Text fontWeight="bold" mb="0.6rem">
            5. Accès par le Client aux Services
          </Text>

          <Text mb="1.2rem" textAlign="justify">
            5.1 Prérequis technique
            <br />
            Le Client reconnaît avoir été informé du fait que l’accès aux Services implique de disposer d’une connexion à Internet, du matériel informatique adéquat, et plus généralement d’outils et de connaissances techniques, dont il est seul responsable de l’acquisition et de l’entretien et dont l’ensemble des coûts, frais, abonnements et taxes demeurent à sa charge exclusive:
            <br />
            Wanao ne s’engage pas sur le fait que la Plateforme EasyMarchés sera compatible avec tous les appareils de consultation. Il appartient au Client de vérifier si les appareils de consultation qu’il utilisera sont bien compatibles avec la Plateforme EasyMarchés avant de conclure le présent Contrat.
            <br />
            Le Client s’engage par ailleurs à vérifier le bon fonctionnement de sa connexion Internet, de ses équipements informatiques et de sa connexion avec la Plateforme EasyMarchés afin d’anticiper en temps utile tout problème d’accès à la Plateforme EasyMarchés.
            <br /><br />


            5.2 Compte EasyMarchés
            <br />
            L’accès à la Plateforme EasyMarchés et aux Services est subordonné à la création d’un Compte EasyMarchés.
            <br />
            Seule une personne physique à la fois pourra se connecter sur le Compte EasyMarchés pour accéder aux Services. La connexion simultanée de plusieurs personnes au même Compte EasyMarchés pour accéder aux Services est strictement interdite.
            <br />
            Le Client peut demander à Wanao à tout moment de clôturer son Compte EasyMarchés au travers de l’interface Facturation et Paiement, Section Gérer mon abonnement Conformément à l’article « Conséquences de la fin du Contrat », cette clôture entraîne :
            <br />
            la résiliation, immédiate pour l’Offre Découverte, à la prochaine échéance contractuelle pour l’Offre Premium, du Contrat ;
            <br />
            en conséquence, la fin, immédiate pour l’Offre Découverte, à la prochaine échéance contractuelle pour l’Offre Premium, de la fourniture au Client des Services
            <br />
            Le Client est responsable et se porte fort du respect par les personnes qui accéderont à la Plateforme EasyMarchés par le biais du Compte EasyMarchés des règles d’utilisation de la Plateforme EasyMarchés prévues aux présentes Conditions Générales. Il garantit Wanao de toute conséquence liée au non-respect par ces personnes desdites règles.
          </Text>
          <Text fontWeight="bold" mb="0.6rem">
            6. Identifiants de connexion au Compte EasyMarchés
          </Text>

          <Text mb="1.2rem" textAlign="justify">
            Pour chaque authentification sur la Plateforme EasyMarchés par le biais du Compte EasyMarchés, le Client devra renseigner ses identifiants de connexion.
            <br />
            Ceux-ci pourront, dans certains cas, faire l’objet d’une sauvegarde automatique dans les appareils utilisés pour consulter la Plateforme EasyMarchés (ordinateur, smartphone, etc.).
            <br />
            Les identifiants de connexion à la Plateforme EasyMarchés sont strictement personnels et confidentiels. Le Client doit s’assurer de conserver leur caractère confidentiel et s’engage à faire respecter la confidentialité des codes d’accès.
            <br />
            Pour garantir la sécurité du Compte EasyMarchés, le Client garantit notamment qu’il :
            <br />
            - choisira un mot de passe suffisamment robuste (voir les conseils de la CNIL : https://www.cnil.fr/fr/les-conseils-de-la-cnil-pour-un-bon-mot-de-passe) ;
            <br />
            - ne divulguera pas à des tiers ses identifiants de connexion ;
            <br />
            - prendra toutes les précautions nécessaires pour éviter que des tiers ne puissent y avoir accès ;
            <br />
            - prendra toutes les mesures afin d’éviter qu’un tiers puisse accéder son compte au Compte EasyMarchés, même à son insu (en verrouillant notamment l’accès aux appareils de consultation de la Plateforme EasyMarchés – ordinateur, smartphone, etc. – à l’aide d’un autre mot de passe également robuste) ;
            <br />
            - ne pas donner accès au Compte EasyMarchés à des tiers.
            <br />
            En cas de perte ou d’oubli de son mot de passe, le Client peut procéder à une réinitialisation de son mot de passe en cliquant sur « Mot de passe oublié » sur la page lui permettant de s’authentifier qui s’affiche sur la Plateforme. Il recevra alors un courrier électronique l’invitant à réinitialiser son mot de passe.
            <br />
            Jusqu’à cette réinitialisation, Wanao ne peut être tenue responsable des conséquences potentiellement préjudiciables d’un accès par un tiers non autorisé audit compte via les identifiants de connexion compromis, sauf si la divulgation à ce tiers des identifiants de connexion au compte concerné est causée par sa propre faute ou négligence.
            <br />
            En conséquence, à l’exception de la divulgation d’identifiants de connexion causée par une faute ou une négligence de Wanao, le Client accepte que toutes les actions liées au Compte EasyMarchés seront réputées avoir été effectuées par le Client jusqu’à la réinitialisation des identifiants de connexion, y compris toute souscription à des Services, tout téléchargement de DCE ou tout achat de nouveaux lots de DCE. Le Client pourra néanmoins en rapporter la preuve contraire et démontrer l’existence d’une fraude imputable à un tiers.
          </Text>
          <Text fontWeight="bold" mb="0.6rem">
            7.  Fonctionnement de la Plateforme
          </Text>

          <Text mb="1.2rem" textAlign="justify">
            Le Client s’engage à ne pas effectuer une utilisation de la Plateforme EasyMarchés détournée, excessive, malveillante et/ou contraire au Contrat et/ou aux lois et règlements applicables et à ce que son utilisation de la Plateforme EasyMarchés n’affecte pas sa stabilité et sa sécurité. Wanao ne pourra en aucun cas être tenue responsable de dysfonctionnements de la Plateforme EasyMarchés en cas de manquement à cet engagement.
            <br />
            Wanao fera ses meilleurs efforts pour assurer la disponibilité et le bon fonctionnement de la Plateforme EasyMarchés, assurer la transmission des Informations transitant vers et à partir de la Plateforme EasyMarchés et pour résoudre les éventuels dysfonctionnements dans les meilleurs délais.
            <br />
            Wanao ne peut en aucun cas être tenue responsable de problèmes ne provenant pas de la Plateforme EasyMarchés et/ou de ses installations techniques, et notamment de ceux susceptibles de provenir des installations techniques des fournisseurs d’accès à Internet et/ou de l’équipement informatique utilisé par le Client pour accéder à la Plateforme EasyMarchés.
            <br />
            Le Client a également connaissance des aléas techniques qui peuvent affecter le réseau Internet et entraîner des ralentissements ou des indisponibilités rendant la connexion impossible. Le Client reconnaît et accepte que le réseau Internet, et plus généralement tout réseau, moyen ou protocole utilisé à des fins de transmission de données, peut connaître des périodes de saturation en raison de l'encombrement de la bande passante, de coupures dues à des incidents techniques, d'interventions de maintenance ou d'autres évènements indépendants de la volonté de Wanao. Wanao ne peut être tenue responsable des difficultés d’accès ou de fonctionnement de la Plateforme EasyMarchés ou de toute autre conséquence dommageable dues à des perturbations du réseau, d’un moyen de communication ou d’un protocole.
            <br />
            Le Client a aussi connaissance du fait que la sécurité informatique ne peut faire l’objet d’une garantie sans réserve compte tenu de l’état de l’art et de la technique. En particulier, la protection des informations transmises par le réseau Internet contre tout risque de détournement ne peut être garantie, ainsi que l’absence :
            <br />
            - d’intrusion frauduleuse, malveillante et non autorisée sur les équipements utilisés pour accéder à la Plateforme EasyMarchés et les équipements auxquels ils sont reliés ;
            <br />
            - d’extraction et d’altération non autorisée des données présentes sur ces équipements ;
            <br />
            - ou encore d’attaques par virus informatiques, bombes logiques, menace permanente avancée (advanced persistent threat) ayant pour effet de diminuer, de désorganiser, d’empêcher l’utilisation normale des équipements utilisés pour accéder à la Plateforme EasyMarchés et les équipements auxquels ils sont reliés, d’interrompre et/ou de ralentir la circulation normale des communications électroniques.
            <br />
            Une bonne sécurité informatique implique que le Client se prémunisse efficacement lui-même contre ces risques, notamment en disposant d’un antivirus de qualité et mis à jour lors de son accès à la Plateforme EasyMarchés.
            <br />
            D’une manière plus générale, Wanao ne garantit pas que :
            <br />
            - la Plateforme EasyMarchés fonctionne sans erreur, sans interruption, de manière complète ou exacte ;
            <br />
            - la Plateforme EasyMarchés, les serveurs qui l’hébergent et les Informations soient exempts d’anomalies ou de virus ou de bugs ou d’erreurs techniques ou autres.
            <br />
            Au regard de ce qui précède, Wanao ne pourra pas être tenue responsable envers quiconque de tout dommage direct ou indirect découlant d’un dysfonctionnement ou d’une interruption de la Plateforme EasyMarchés trouvant leur origine, directe ou indirecte, totale ou partielle, dans des évènements indépendants de la volonté de Wanao et échappant à son contrôle ou qui seraient résolus dans des délais raisonnables par Wanao.
            <br />
            Wanao se réserve le droit de suspendre l’accès à la Plateforme EasyMarchés et aux Services, en tout ou partie, moyennant une information préalable par tous moyens du Client, pour procéder à toute opération de correction, de mise à jour, de maintenance ou pour faire évoluer la Plateforme EasyMarchés et les Services. En cas de nécessité d’effectuer une maintenance d’urgence, Wanao pourra procéder, sans préavis, à une interruption momentanée de la Plateforme EasyMarchés et des Services. Wanao se réserve le droit de suspendre à tout moment l’accès à la Plateforme EasyMarchés et aux Services en cas de risques pesant sur la sécurité des équipements de Wanao et la Plateforme EasyMarchés. Wanao ne peut en aucun cas être tenue responsable de tout préjudice et/ou perte qui résulterait de ces suspensions pour le Client.
            <br />
            Il appartient au Client de prendre toute mesure appropriée pour minimiser les conséquences dommageables liées à une possible interruption de la Plateforme EasyMarchés, à une perte des données accessibles à partir de ladite plateforme ou toute faille de sécurité.
          </Text>
          <Text fontWeight="bold" mb="0.6rem">
            8. Propriété intellectuelle
          </Text>

          <Text mb="1.2rem" textAlign="justify">
            8.1 Licence d’utilisation de la Plateforme
            <br />
            La Plateforme EasyMarchés, y compris les logiciels, marques, logos, icônes et bases de données (les « Éléments de la Plateforme »), sont protégés par des droits de propriété intellectuelle.
            <br />
            Le Client s’engage à respecter les droits de propriété intellectuelle de Wanao sur les Éléments de la Plateforme.
            <br />
            Sous réserve de l’acceptation et du respect du Contrat par le Client, Wanao concède au Client une licence d’accès et d’utilisation portant sur les Éléments de la Plateforme dans les conditions et limites ci-après.
            <br />
            Cette licence est non-exclusive et concédée pour la durée du Contrat aux seules fins d’accéder et utiliser la Plateforme EasyMarchés dans le cadre des Services et dans les conditions fixées au Contrat.
            <br />
            Au titre du droit d’utilisation concédé par Wanao et pour les fins visées ci-avant, le Client est autorisé à reproduire et à autoriser la reproduction, de façon permanente ou provisoire, les Éléments de la Plateforme pour les charger, afficher, lire, exécuter, télécharger ou transmettre sur les appareils utilisés pour accéder à la Plateforme.
            <br />
            Wanao se réserve tous les autres droits sur les Éléments de la Plateforme. Le Client s’engage à ne pas exploiter ces éléments en dehors du périmètre déterminé par la présente licence.
            <br />
            Toute utilisation, reproduction ou représentation, totale ou partielle, des Éléments de la Plateforme non autorisée par Wanao ou par la loi est constitutive du délit civil et pénal de contrefaçon et est passible des sanctions prévues à l’article « Sanctions de l’inexécution » du Contrat.
            <br />
            Sans préjudice des droits concédés au présent article et sous réserve des dispositions législatives applicables, le Client n’est notamment pas autorisé au titre des présentes à :
            <br />
            - corriger lui-même toute anomalie quelle qu’elle soit constatée dans les Éléments de la Plateforme ;
            <br />
            - copier, imprimer, transférer, transmettre ou représenter tout ou partie des Éléments de la Plateforme ;
            <br />
            - vendre, louer, sous-licencier ou distribuer de quelque façon que ce soit tout ou partie des Éléments de la Plateforme ;
            <br />
            - altérer, modifier, adapter ou dénaturer tout ou partie des Éléments de la Plateforme et/ou fusionner tout ou partie des Éléments de la Plateforme, dans d’autres programmes informatiques ;
            <br />
            - procéder à une compilation, décompilation, désassemblage, rétro-ingénierie, traduction de tout ou partie des Éléments de la Plateforme, et/ou tenter d’y procéder ;
            <br />
            - contourner les mesures techniques d’information ou de protection de tout ou partie des Éléments de la Plateforme.
            <br />
            Le Client s’engage à ne pas retirer, modifier ou occulter une quelconque mention faite par Wanao relative à ses droits de propriété intellectuelle sur les Éléments de la Plateforme.
            <br />
            <br />

            8.2 Droits des tiers
            <br />
            Les Informations disponibles sur la Plateforme EasyMarchés peuvent être protégés par les droits de propriété intellectuelle de tiers.
            <br />
            Le Client s’engage à s’abstenir de tout acte susceptible de porter atteinte, directement ou indirectement, à ces droits.
            <br />
            A toutes fins utiles, il est rappelé que la mise à disposition de ces Informations n’emporte ni cession ni transfert d’un quelconque droit de propriété intellectuelle dont lesdits tiers seraient titulaires.
          </Text>
          <Text fontWeight="bold" mb="0.6rem">
            9. Contenu des Informations et liens hypertextes
          </Text>

          <Text mb="1.2rem" textAlign="justify">
            Wanao décline toute responsabilité quant au contenu des Informations accessibles sur la Plateforme EasyMarchés.
            <br />
            Le Client reconnaît et accepte que Wanao ne procède pas nécessairement à des vérifications de l’exactitude de ces Informations et qu’elle fait ses meilleurs efforts pour les mettre à jour. Le Client reconnaît et accepte par conséquent que ces Informations ne sont pas exemptes d’erreurs, d’inexactitudes ou d’omissions.
            <br />
            La Plateforme EasyMarchés et les Informations peuvent contenir des liens hypertextes vers des sites Internet exploités par des tiers. La décision d’activer ces liens relève de la pleine et entière responsabilité de l’Abonné ou Utilisateur.
            <br />
            Le Client est averti qu’en cliquant sur ces liens, il sera conduit sur des sites Internet tierces. Wanao n’est pas responsable de la qualité ou de l'exactitude de ces sites Internet et de leurs contenus. Elle ne peut pas non plus être considérée comme approuvant, publiant ou autorisant ces sites Internet et leurs contenus.
            <br />
            Par conséquent, les exploitants de ces sites Internet sont seuls responsables du respect des législations et réglementations applicables. Wanao décline toute responsabilité quant aux dommages pouvant résulter de l’utilisation de ces sites Internet.
          </Text>
          <Text fontWeight="bold" mb="0.6rem">
            10. Évolution de la Plateforme
          </Text>

          <Text mb="1.2rem" textAlign="justify">
            La Plateforme EasyMarchés est en constante évolution. Le Client reconnaît et accepte que leur nature et forme puissent changer ponctuellement.
            <br />
            Wanao se réserve le droit d’apporter toute modification à la Plateforme EasyMarchés, et notamment d’ajouter des fonctionnalités, de substituer des fonctionnalités nouvelles à des fonctionnalités existantes, voire de supprimer des fonctionnalités sous réserve de ne pas dénaturer substantiellement les Services accessibles sur la Plateforme EasyMarchés au jour de la conclusion du Contrat.
          </Text>
          <Text fontWeight="bold" mb="0.6rem">
            11. Prix de l’Offre Premium et modalités de règlement
          </Text>

          <Text mb="1.2rem" textAlign="justify">
            L’Offre Découverte est fournie à titre gratuit.
            <br />
            Quant à l’Offre Premium, les conditions financières correspondant aux Services, en ce inclus, le prix de l’Abonnement et le prix des nouveaux lots de DCE pouvant être achetés dans le cadre de l’Offre Premium,  sont précisées sur la Plateforme EasyMarchés.
            <br />
            Elles sont récapitulées lors du processus de souscription par le Client des Services sur la Plateforme EasyMarchés, ainsi que dans l’email de confirmation de ladite souscription adressée par Wanao au Client.
            <br />
            Les prix sont mentionnés hors taxes.
            <br />
            Le Client s’engage à fournir et à mettre à jour toutes les informations nécessaires à la facturation. Sans réception de ces éléments, Wanao se réserve le droit de suspendre les Services.
            <br />
            Les factures sont mises à la disposition du Client sur le Compte EasyMarchés au format numérique uniquement, ce que le Client accepte expressément.
            <br />
            Les factures sont payables au comptant à la commande, puis en cas de reconduction de l’Abonnement, à réception.
            <br />
            Les paiements sont effectués par carte bancaire ou par virement SEPA. Tout autre moyen de paiement n’est pas accepté par Wanao, ce que le Client accepte. Le Client s’engage à régler ces prix selon ces modalités de paiement.
            <br />
            Les conditions tarifaires des Services sont révisables à tout moment sous réserve d’un préavis de trois (3) mois. Le cas échéant, Wanao notifiera au Client les nouvelles conditions tarifaires sur l’adresse électronique rattachée au Compte EasyMarchés. S’il refuse la révision des conditions tarifaires, le Client disposera de la faculté de résilier le Contrat dans les conditions définies à l’article « Durée du Contrat ». A défaut de résiliation par le Client, les nouvelles conditions tarifaires seront acceptées par le Client et s’appliqueront automatiquement à la prochaine échéance de reconduction de l’Abonnement qui interviendra après l’expiration d’un délai de trois (3) mois à compter de la notification des nouvelles conditions tarifaires.
            <br />
            Toute facture émise en application du Contrat non réglée, totalement ou partiellement, à son échéance, porte intérêt à hauteur de trois (3) fois le taux d'intérêt légal en vigueur à la date d’émission de la facture. Ces intérêts sont calculés à compter du jour suivant la date d’échéance du montant non réglé jusqu’à son paiement intégral. Une indemnité forfaitaire de quarante euros (40 €) par facture sera due à Wanao à l'occasion de tout retard de paiement. Les frais de recouvrement excédant cette indemnité forfaitaire seront à la charge du Client en cas d’obtention par Wanao d’un titre exécutoire ou d’exécution forcée contre lui. Si Wanao devait confier le recouvrement de sa créance à un tiers, le Client serait redevable, en outre, du remboursement des frais et honoraires engagés.
          </Text>
          <Text fontWeight="bold" mb="0.6rem">
            12. Responsabilités et garanties
          </Text>

          <Text mb="1.2rem" textAlign="justify">
            12.1 Responsabilités et garanties de Wanao
            <br />
            Wanao s’engage à faire ses meilleurs efforts pour assurer les Services dans les meilleures conditions. A cet égard, il est expressément convenu que Wanao ne souscrit à l’égard du Client qu’une obligation de moyens.
            <br />
            Pour mémoire, Wanao ne garantit pas l’exactitude des Informations. Wanao ne garantit pas non plus l’exhaustivité des Marchés recensés sur la Plateforme EasyMarchés et des Informations afférentes à chaque Marché.
            <br />
            Les omissions dans la sélection, la fourniture d’un document défaillant techniquement et/ou un retard indépendant de la volonté de Wanao dans la mise à disposition d’Informations afférentes à un Marché ne sont pas considérés comme une inexécution du Contrat.
            <br />
            En toutes hypothèses, la responsabilité de Wanao ne pourra être engagée qu’en cas de faute prouvée et pour des dommages directs découlant de faits qui lui sont directement imputables au titre du Contrat.
            <br />
            Le Client accepte expressément que les dommages indirects et/ou immatériels tels que les pertes de bénéfices, la perte de clientèle, les pertes d’exploitation, le manque à gagner, les pertes de données, les perturbations des activités du Client, l’augmentation de ses coûts internes, l’atteinte à l'image ou tout autre préjudice moral n’ouvrent pas droit à réparation, et ce même si Wanao a été informée de la possibilité de tels dommages.
            <br />
            À titre de condition essentielle et déterminante du Contrat, dans tous les cas où la responsabilité de Wanao serait établie et prouvée, il est expressément entendu que, sauf faute grave ou dolosive dont la preuve serait dument rapportée par le Client :
            <br />
            - en cas de souscription de l’Offre Découverte, la responsabilité de Wanao au titre du Contrat ne saurait faire l’objet de quelconque réclamation financière.
            <br />
            - en cas de souscription de l’Offre Premium, la responsabilité de Wanao au titre du Contrat ne pourrait excéder la somme totale effectivement versée par le Client à Wanao au titre du Contrat sur les douze (12) mois précédant le fait générateur de responsabilité.
            <br />
            Aucune des parties n’encourra de responsabilité ni ne sera considérée comme enfreignant l’une quelconque des clauses du Contrat si elle est retardée ou empêchée d’exécuter une obligation lui incombant en raison d’un cas de force majeure ou en cas de survenance de tout acte ou événement échappant à son contrôle. De convention expresse, sont considérés comme cas de force majeure ou d’acte ou évènement échappant au contrôle d’une partie, outre ceux retenus habituellement par la jurisprudence des cours et tribunaux français et sans que la liste ci-après ne soit limitative, les guerres, insurrections, émeutes, catastrophes naturelles ou chimiques, conflits sociaux (internes ou affectant des fournisseurs ou intermédiaires tiers), lockout, intempéries, blocages des moyens de transport ou d’approvisionnement pour quelque raison que ce soit, tremblements de terre, incendies, tempêtes, inondations, dégâts des eaux, restrictions gouvernementales ou légales, blocages ou interruptions des télécommunications ou des réseaux électroniques ou des fournisseurs d’hébergement, interruptions de fourniture d’énergie, défaillances des systèmes informatiques, logiciels ou autres matériels ou technologies dont dépend une partie, dès lors qu’ils empêcheront ou retarderont l’exécution du Contrat par une partie.
            <br />
            La survenance d’un cas de force majeure ou d’un acte ou évènement échappant au contrôle de l’une des parties suspend l’exécution du Contrat par les deux parties.
            <br />
            Si un cas de force majeure se poursuit au-delà d’une durée d’un (1) mois, le Contrat pourra être résilié immédiatement et de plein droit, sans indemnité, par l’une ou l’autre des parties par l’envoi d’une lettre recommandée avec accusé de réception.
            <br />
            De convention expresse, les parties conviennent que le présent article survivra en cas de résiliation ou résolution totale ou partie du Contrat, quelle qu’en soit la cause.
            <br />
            <br />
            12.2 Responsabilités et garanties du Client
            <br />
            Le Client a procédé préalablement à la souscription des Services à la vérification de l’adéquation des Services avec ses besoins et reconnaît que tous les informations et conseils qui lui étaient nécessaires pour s’engager au titre du Contrat lui ont été communiqués par Wanao.
            <br />
            Le Client s’engage à utiliser les Services et les Informations conformément aux lois et règlements en vigueur et aux stipulations du Contrat.
            <br />
            Le Client est responsable de l’utilisation faite par le biais de son Compte EasyMarchés des Services et notamment des Informations.
            <br />
            Toute utilisation des Services et Informations en dehors des limites prévues au Contrat relève de la seule responsabilité du Client.
            <br />
            Le Client s’engage à mettre en place les sécurités (notamment techniques, procédurales et juridiques) nécessaires pour respecter ses obligations et informer son personnel des conditions et limites d’utilisation des Services et Informations prévus au Contrat.
            <br />
            Wanao ne peut être tenue responsable pour une utilisation non autorisée, détournée ou frauduleuse des Services et/ou Informations et le Client garantit Wanao contre toutes réclamations, tous recours ou toutes revendications sur ce point.
          </Text>
          <Text fontWeight="bold" mb="0.6rem">
            13. Confidentialité
          </Text>

          <Text mb="1.2rem" textAlign="justify">
            Sauf indication contraire des parties, sont considérées comme des informations confidentielles toutes les informations non publiques portant une mention confidentielle, toutes les informations non publiques relatives aux clients, prospects et comptes de chaque partie, ainsi que les informations et renseignements non publics relatifs aux Services, à la Plateforme, aux opérations techniques et commerciales et aux activités administratives, financières, marketing et stratégiques de chaque partie auxquels l’autre partie a eu accès lors des négociations du Contrat ou dans le cadre de l’exécution du Contrat, par écrit, par oral, par système informatique ou par tout autre moyen, sur tout support, que ces informations soient signalées ou non comme confidentielles par l’autre partie, dès lors qu’elles ne sont pas publiques (les « Informations Confidentielles »).
            <br />
            Il est également précisé que le contenu du Contrat revêt un caractère confidentiel et constitue donc également une Information Confidentielle.
            <br />
            Il est aussi précisé à toutes fins utiles que les Informations ne constituent en revanche pas des Informations Confidentielles.
            <br />
            Les Informations Confidentielles sont transmises par chaque partie à la seule et unique fin de la bonne exécution du présent Contrat. Il est interdit à chaque partie de reproduire, d’utiliser, d’exploiter et/ou de transmettre les Informations Confidentielles à d’autres fins que l’objet du présent Contrat. Notamment il est interdit de s’inspirer, imiter, contrefaire, adapter, donner en licence, en sous-licence, louer, concéder, transférer ou révéler les Informations Confidentielles à un tiers.
            <br />
            La communication d’Informations Confidentielles par chaque partie n’entraîne aucun transfert de propriété de celles-ci.
            <br />
            Chaque partie prend toutes mesures nécessaires, notamment les mesures de sécurité, y compris informatique, afin de préserver le caractère confidentiel des Informations Confidentielles. Chaque partie limite en interne la divulgation d’Informations Confidentielles aux seuls responsables, salariés ou préposés dont l’intervention est nécessaire à l’exécution du Contrat. Il est entendu que chaque partie se charge d'informer ces destinataires des Informations Confidentielles du caractère confidentiel des Informations Confidentielles. Chaque partie informe également ces destinataires des sanctions de la violation de la présente obligation.
            <br />
            En cas de divulgation des Informations Confidentielles d’une partie, il appartiendra à l’autre partie de démontrer que ladite divulgation n’est pas de son fait ni du fait des personnes qu’il a rendu légitiment destinataires des Informations Confidentielles. Elle ne pourra s’exonérer de sa responsabilité qu’en démontrant que les informations étaient connues d’elle ou du public avant leur transmission par l’autre partie, qu’elles l’ont été par la suite autrement que par sa faute ou sous sa responsabilité ou bien qu’elle en a pris elle-même connaissance par des moyens légitimes auprès de tiers.
            <br />
            Si, dans le cadre de toute procédure judiciaire, administrative ou tout autre cas, une partie se voyait requise de communiquer tout ou partie des Informations Confidentielles, par demande orale ou écrite, afin de respecter la législation et la réglementation en vigueur en France ou dans un autre État :
            <br />
            - ladite partie s’engage à notifier, immédiatement, une telle demande à l’autre partie,
            <br />
            - ladite partie s’engage à obtenir l'assurance que les informations ainsi fournies, bénéficieront d'un traitement confidentiel tel que défini dans le cadre du présent Contrat dans les limites permises par la loi,
            <br />
            - ladite partie s’engage à ne communiquer que les Informations Confidentielles absolument indispensables, après avis motivé de ses avocats.
            <br />
            La présente obligation de confidentialité s’applique pendant la durée du Contrat et continuera à s’appliquer pendant cinq (5) ans à compter de la fin du Contrat, quel que soit le motif d’extinction du Contrat.
          </Text>
          <Text fontWeight="bold" mb="0.6rem">
            14. Informatique et libertés
          </Text>

          <Text mb="1.2rem" textAlign="justify">
            14.1 Données à caractère personnel
            <br />
            Le Client est amené à communiquer à Wanao des données à caractère personnel dans le cadre de la conclusion et/ou de l’exécution du Contrat. Wanao collecte également de telles données par d’autres biais, et notamment via ses propres outils ou traceurs sur la Plateforme EasyMarchés.
            <br />
            Certaines des données collectées et traitées par Wanao sont nécessaires pour permettre à Wanao de poursuivre certaines finalités, et notamment pour la fourniture des Services. Leur non-communication est en conséquence notamment susceptible d’empêcher la fourniture par Wanao des Services, en particulier la connexion au Compte EasyMarchés, l’accès aux Informations et le téléchargement de DCE. Wanao ne pourra être tenue responsable d’un tel empêchement en cas de refus de communication des données par le Client et/ou les personnes physiques se connectant à son Compte EasyMarchés à partir de ses identifiants de connexion.
            <br />

            <br />
            14.2 Cookies
            <br />
            Wanao utilise des traceurs ou cookies.
            <br />
            Notamment, le Client est spécialement que Wanao traite des données de connexion permettant la traçabilité de chaque personne et de chaque poste informatique qui se visitent la Plateforme EasyMarchés et/ou se connectent sur le Compte EasyMarchés (date, heure, adresse IP, paramètres de l’ordinateur du visiteur, page(s) consultée(s), navigateur utilisé, adresse électronique, logs, actions de consultation des Informations et de connexion au Compte EasyMarchés).
            <br />
            Le recours à certains traceurs ou cookies est nécessaire pour permettre au Client d’accéder aux Services, au Compte EasyMarchés et aux Informations. Wanao ne pourra être tenue responsable en cas d’impossibilité d’accès aux Services, au Compte EasyMarchés et/ou aux Informations qui serait consécutive à une opposition à l’utilisation de ces traceurs ou cookies par le Client et/ou les personnes physiques se connectant à son Compte EasyMarchés à l’aide de ses identifiants de connexion.
          </Text>
          <Text fontWeight="bold" mb="0.6rem">
            15. Durée du Contrat
          </Text>

          <Text mb="1.2rem" textAlign="justify">
            Le Contrat prend effet à compter de la date d’acceptation par le Client des Conditions Générales sur la Plateforme EasyMarchés.
            <br />
            Le Contrat est conclu pour la durée déterminée lors de la souscription des Services.
            <br />
            En cas de souscription à l’Offre Découverte, le Contrat n’est pas susceptible de renouvellement et prend automatiquement fin à l’issue de la durée déterminée lors de la souscription des Services ou en cas de clôture avant cette date du Compte EasyMarchés par le Client en envoyant une demande par mail depuis l’adresse mail avec laquelle le compte Easy marchés a été créé,  à l’adresse support@easymarches.fr.
            <br />
            En cas de souscription à l’Offre Premium, le Contrat est renouvelé par reconduction tacite selon la périodicité définie lors de la souscription des Services. Le Client qui souhaite résilier le Contrat à l’issue d’une période contractuelle doit demander cette résiliation à Wanao a minima un (1) mois avant le terme de la période contractuelle en cours. Cette résiliation peut être notifiée à Wanao :
            <br />
            (i) par le biais de la Plateforme EasyMarchés :
            <br />
            - Depuis l’interface « Facturation et Paiement », Section Gérer mon abonnement bouton « Modifier »
            <br />
            ou (ii) par notification à Wanao :
            <br />
            - par courrier électronique à support@easymarches.fr .
            <br />
            A défaut de demande de résiliation adressée par le Client à Wanao (dans le respect des conditions précitées), le Contrat est tacitement reconduit pour une nouvelle période contractuelle.
          </Text>
          <Text fontWeight="bold" mb="0.6rem">
            16. Sanctions de l’inexécution
          </Text>

          <Text mb="1.2rem" textAlign="justify">
            En cas de manquement grave de l’une des parties, l’autre partie pourra :
            <br />
            - refuser d'exécuter ou suspendre l'exécution de sa propre obligation, y compris par anticipation, et notamment suspendre temporairement les accès du Client à la Plateforme EasyMarchés et aux Services ;
            <br />
            - provoquer la résolution du Contrat en application de la clause résolutoire stipulée ci-après;
            <br />
            - demander réparation des conséquences de l'inexécution.
            <br />
            Les sanctions qui ne sont pas incompatibles peuvent être cumulées et des dommages et intérêts peuvent toujours s'y ajouter.
            <br />
            Concernant la faculté de résolution précitée, les parties conviennent de la clause résolutoire suivante.
            <br />
            Chaque partie pourra procéder à la résiliation de plein droit du Contrat sans décision judiciaire préalable et sans indemnité, en cas de manquement grave par l’autre partie à l’une de ses obligations prévues aux articles « Identifiants de connexion au Compte EasyMarchés », « Fonctionnement de la Plateforme », « Propriété intellectuelle », « Prix et modalités de règlement », « Responsabilités et garanties » du Contrat, s’il n’est pas entièrement remédié à ce manquement par la partie fautive dans un délai raisonnable au regard des circonstances du manquement après l’envoi d’une lettre recommandée avec accusé de réception à cette dernière.
            <br />
            Constituent notamment des manquements graves :
            <br />
            - l’absence de mise à disposition par Wanao des Services au Client pendant plus d’un (1) mois ;
            <br />
            - une utilisation de la Plateforme EasyMarchés par le Client détournée, abusive, malveillante et/ou contraire au Contrat et/ou aux lois et règlements applicables.
            <br />
            - le non-respect par le Client de la licence concédée au Client ;
            <br />
            - tout défaut de paiement par le Client au-delà de trente (30) jours à compter de l’exigibilité des sommes dues.
            <br />
            Il est expressément convenu entre les parties d’exclure l’application de l’article 1226 du code civil et de retenir comme seule possibilité de résolution ou de résiliation pour inexécution de ses obligations par l’une des parties la mise en œuvre de la clause résolutoire aux deux alinéas précédents.
          </Text>
          <Text fontWeight="bold" mb="0.6rem">
            17. Conséquences de la fin du Contrat
          </Text>

          <Text mb="1.2rem" textAlign="justify">
            Quelle que soit la cause de la fin du Contrat :
            <br />
            - le Client reste tenu du paiement de tous les Services fournis par Wanao jusqu’à la date d’effet de la fin du Contrat, et plus généralement du paiement de toutes factures dues à Wanao et non encore payées ;
            <br />
            - l’accès au Compte EasyMarchés, aux Services et aux Informations sera clôturé à la date de fin du Contrat ;
            <br />
            - les Informations mises à la disposition du Client devront être supprimées immédiatement par le Client ;
            <br />
            - les éventuels téléchargements de DCE non consommés ne pourront être remboursés au Client, sauf résiliation du Contrat aux torts exclusifs de Wanao.
          </Text>
          <Text fontWeight="bold" mb="0.6rem">
            18. Imprévision
          </Text>

          <Text mb="1.2rem" textAlign="justify">
            Les parties conviennent d’aménager les dispositions de l’article 1195 du code civil de la manière suivante :
            <br />
            - aucune des parties n’entend assumer les risques de l’imprévision au sens de ce texte ;
            <br />
            - un changement de circonstances imprévisible peut être un changement dans les conditions commerciales, économiques, monétaires ou financières mais aussi juridiques (notamment changement de législation, de réglementation ou des conditions de fourniture des Informations) voire politiques, scientifiques, culturelles ou technologiques ;
            <br />
            - le Contrat sera par exemple excessivement onéreux si Wanao ne couvre plus le coût de revient des Services ;
            <br />
            - la partie qui constate qu’un changement de circonstance imprévisible lors de la conclusion du Contrat rend l’exécution du Contrat excessivement onéreuse pour elle devra solliciter la renégociation des conditions contractuelles ou la résolution ou résiliation du Contrat à l’autre partie en la notifiant par lettre recommandée avec accusé de réception laquelle comportera un exposé des éléments justifiant le changement de circonstances imprévisible et le caractère excessivement onéreux de l’exécution du Contrat ;
            <br />
            - les parties se réuniront dans un délai de quinze (15) jours calendaires à compter de la notification précitée afin de discuter de la demande de révision, de résiliation ou de résolution du Contrat ;
            <br />
            - à défaut d’accord des parties dans un délai de trente (30) jours calendaires à compter de la première rencontre entre les parties, délai au cours duquel les parties se seront réunies a minima une seconde fois pour discuter de la révision éventuelle du Contrat, chaque partie pourra saisir le juge afin de lui demander soit de résilier le Contrat, soit de le réviser. Dans les deux cas, le juge sera tenu de tenir compte des usages en vigueur dans le secteur d’activités de Wanao et des aspects économiques, juridiques et commerciaux du Contrat ;
            <br />
            - tant que les parties n’auront pas trouvé une solution ou que le juge n’aura pas tranché le litige, chacune des parties continue d’exécuter ses obligations contractuelles.
          </Text>
          <Text fontWeight="bold" mb="0.6rem">
            19. Caractère évolutif des Conditions Générales
          </Text>

          <Text mb="1.2rem" textAlign="justify">
            Les présentes Conditions Générales ont un caractère évolutif, ce que les parties acceptent expressément.
            <br />
            En conséquence, Wanao se réserve la possibilité de modifier à tout moment ces Conditions Générales, sous réserve d’un préavis de trente (30) jours ouvrables, pour les modifications autres que les modifications de tarifs. En continuant de bénéficier des Services après l’expiration de ce délai et/ou en payant les factures y afférentes, le Client consent à être lié par les nouvelles conditions telles que modifiées. En cas de refus des conditions modifiées, le Client devra mettre fin au Contrat avant l’expiration du délai précité par lettre recommandée avec accusé de réception.
            <br />
            Le Client sera présumé avoir accepté les nouvelles Conditions Générales, sans refus par le Client de celles-ci notifiées par écrit à Wanao - Technopole Izarbel – 230, allée Théodore Monod – 64210 Bidart ou par courrier électronique à l’adresse support@easymarches.fr dans le préavis de trente (30) jours susvisé, ainsi qu’en cas de paiement par le Client de toute somme due au titre des Services après la notification des nouvelles Conditions Générales.
          </Text>
          <Text fontWeight="bold" mb="0.6rem">
            20. Convention de preuve
          </Text>

          <Text mb="1.2rem" textAlign="justify">
            Conformément à la réglementation en vigueur, les données collectées lors de la souscription des Services, puis lors de l’exécution du Contrat, notamment lors des connexions au Compte EasyMarchés et de la navigation des personnes accédant audit compte sur la Plateforme EasyMarchés, sont conservées par Wanao comme preuve.
            <br />
            Le Client reconnaît et accepte notamment que Wanao peut collecter les adresses IP et les données de connexion des équipements informatiques accédant à la Plateforme EasyMarchés, notamment à des fins de contrôle des accès, du respect des droits de Wanao et de preuve de toute violation du Contrat ou des droits de Wanao.
            <br />
            L’archivage de ces données par Wanao est effectué sur un support fiable et durable de manière à correspondre à une copie fidèle et durable, conformément aux règles du code civil.
            <br />
            En cas de litige entre les données ainsi conservées par Wanao et tout document fourni par le Client, il est expressément convenu à titre de convention de preuve que les informations et données de Wanao primeront en cas de contradiction avec d’autres éléments de preuve, cette présomption n’étant pas irréfragable.
          </Text>
          <Text fontWeight="bold" mb="0.6rem">
            21. Communication entre les parties
          </Text>

          <Text mb="1.2rem" textAlign="justify">
            Des notifications importantes relatives au Contrat et aux Services seront adressées par Wanao au Client sur l’adresse électronique communiquée par ce dernier lors de la création du Compte EasyMarchés et confirmée lors de la validation par Wanao de la conclusion du Contrat.
            <br />
            Le Client s’engage en conséquence à veiller à ce que le compte rattaché à cette adresse électronique soit régulièrement consulté et à notifier immédiatement tout changement de coordonnées à Wanao par courrier électronique à l’adresse support@easymarches.fr .
            <br />
            En l’absence de notification de changement, toutes les communications adressées par Wanao sur l’adresse électronique rattachée au Compte EasyMarchés seront réputées reçues par le Client.
          </Text>
          <Text fontWeight="bold" mb="0.6rem">
            22. Références commerciales
          </Text>

          <Text mb="1.2rem" textAlign="justify">
            Le Client autorise expressément Wanao à utiliser son nom commercial, sa dénomination sociale, son logo et/ou l’un quelconque de ses signes distinctifs à titre de référence commerciale.
            <br />
            À ce titre, Wanao pourra notamment faire état de sa qualité de cocontractant dans sa communication et faire figurer sur son site Internet ou dans ses documents publicitaires et commerciaux (tels que, à titre d’exemple, des plaquettes de présentation) le logo du Client.
          </Text>
          <Text fontWeight="bold" mb="0.6rem">
            23. Transfert du Contrat
          </Text>

          <Text mb="1.2rem" textAlign="justify">
            Wanao pourra librement céder à tout moment tout ou partie de ses droits et obligations au titre du Contrat (notamment par cession de fonds de commerce, apport d’actif, fusion).
            <br />
            Dans le cas où une telle cession serait susceptible d’engendrer une diminution des droits du Client aux termes du Contrat et dans le cadre de l’utilisation des Services, Wanao en informera préalablement l’Abonné ou Utilisateur qui pourra, s’il refuse les termes de la cession, résilier le Contrat à compter de la cession par envoi d’une lettre recommandée avec accusé de réception à Wanao.
            <br />
            Le Client ne pourra quant à lui pas céder tout ou partie de ses droits et obligations sans l’accord préalable et écrit de Wanao.
          </Text>
          <Text fontWeight="bold" mb="0.6rem">
            24. Intégralité de l'accord
          </Text>

          <Text mb="1.2rem" textAlign="justify">
            Les stipulations du Contrat prévalent sur toutes les négociations, discussions, communications, ententes et conventions antérieures entre Wanao et le Client relatives à l'objet du présent Contrat, ainsi que sur tous les projets de conventions antérieurs et tout autre document antérieur susceptible d’être signé par les parties.
            <br />
            Toute modification du Contrat devra faire l’objet d’un avenant écrit et signé par les parties.
            <br />
            Si l’une quelconque des stipulations du Contrat, ou une partie d’entre elles, est ou devient nulle au regard d’un règlement, d’une loi en vigueur ou à la suite d’une décision judiciaire devenue définitive, elle sera réputée non écrite et remplacée par une ou plusieurs clauses valides et conformes à l’intention
          </Text>
        </ModalBody>

        <ModalFooter>
          <CustomButton
            backgroundColor={"#2f80ed"}
            onClick={handleAcceptConditions}
          >
            Accepter
          </CustomButton>
          <CustomButton
            color={"#646464"}
            backgroundColor={"#efefef"}
            marginLeft={"15px"}
            onClick={onClose}
          >
            Annuler
          </CustomButton>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default TermsConditionsModal;
