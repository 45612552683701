import React from "react";
import { Helmet } from "react-helmet";
import { useSelector } from "react-redux";
import { useLocation } from "react-router";
import { metaData } from "../../constants/generalConstants";

const initialMetas = {
  default: {
    title: "EasyMarchés",
    description: "",
    keywords:[]
  },
  easyao: {
    title: "EasyMarchés",
    description: "",
    keywords:[]
  },
  easyprojects: {
    title: "EasyMarchés",
    description: "",
    keywords:[]
  },
};

const HeadMetas = () => {
  const { currentProject } = useSelector((state) => state.generalReducer);
  const location = useLocation();
  const path = location?.pathname?.split
    ? location?.pathname.split("/")[1]
    : "";
  const route = "details" === path ? "/details" : location?.pathname;
  const metas = metaData[route] || initialMetas;
  const mappedMetas = metas[currentProject] || metas["default"];
  return (
    <Helmet key={`${route}#${currentProject}`}>
      <title>{mappedMetas?.title}</title>
      <meta name="description" content={mappedMetas?.description} />
      <meta name="keywords" content={mappedMetas?.keywords?.join(", ")} />
    </Helmet>
  );
};

export default HeadMetas;
