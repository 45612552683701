import React from "react";
import { useSelector } from "react-redux";
import { Stack } from "@chakra-ui/react";
import NotificationItem from "./NotificationItem";
import { Sticky } from "react-sticky";

const Notifications = () => {
  const { notifs } = useSelector((state) => state.appReducer);

  return (
    <Stack
      style={{
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        zIndex: 999,
      }}
    >
      <Sticky>
        {({ style }) => (
          <Stack style={style}>
            {notifs.map((notif) => (
              <NotificationItem key={notif.id} notif={notif} />
            ))}
          </Stack>
        )}
      </Sticky>
    </Stack>
  );
};

export default Notifications;
