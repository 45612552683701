import KeyMirror from "keymirror";

/**
 * Mappage de devises
 * @readonly
 * @enum {string}
 */
export const currencyCodeMapping = {
  eur: "€",
  usd: "$",
};

/**
 * Les status du paiement
 * @readonly
 * @enum {string}
 */
export const paymentStatus = KeyMirror({
  awaiting_payment: null,
  pending: null,
  canceled: null,
  failed: null,
  requires_action: null,
  paid: null,
  succeeded: null,
});

export const mappedPaymentStatusPage = {
  awaiting_payment: "paymentProcessing",
  pending: "paymentProcessing",
  processing: "paymentProcessing",
  canceled: "paymentHasFailed",
  failed: "paymentHasFailed",
  requires_action: "paymentProcessing",
  paid: "paymentSucceeded",
  succeeded: "paymentSucceeded",
};

export const mappedPaymentStatus = {
  awaiting_payment: "En attente",
  pending: "En cours",
  canceled: "Annulé",
  failed: "Echoué",
  requires_action: "Nécessite action",
  paid: "Payé",
};

export const mappedPaymentPeriod = {
  month: "mois",
  year: "an",
  week: "semaine",
};
