import React from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import PaymentMethodSelector from "./PaymentMethodSelector";

const stripeTestPromise = loadStripe(
  process.env.REACT_APP_STRIPE_PUBLIC_KEY_TEST
);

export default function PaymentMethodSelectorContainer({ paymentMethods }) {
  return (
    <Elements stripe={stripeTestPromise}>
      <PaymentMethodSelector paymentMethods={paymentMethods} />
    </Elements>
  );
}
