import React from "react";
import { useSelector } from "react-redux";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import PaymentForm from "./PaymentForm";

const stripeTestPromise = loadStripe(
  process.env.REACT_APP_STRIPE_PUBLIC_KEY_TEST
);

export default function PaymentFormContainer({ className }) {
  const { cart } = useSelector((state) => state.orderReducer);
  return (
    <Elements stripe={stripeTestPromise}>
      <PaymentForm className={className} orderType={cart.type} />
    </Elements>
  );
}
