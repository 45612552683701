import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { withRouter, Link as RouterLink } from "react-router-dom";
import { Link } from "@chakra-ui/react";
import CustomIcon from "../../shared/Icon/CustomIcon";
import { initCart } from "../../redux/actionsCreators/orderActionCreators";

const NavBarItem = ({ location, target, children, iconName }) => {
  const dispatch = useDispatch();
  const isRouteActive = (target) => {
    if (!location || !location.pathname) return false;
    const currentTarget = location.pathname.split("/")[1];
    const navTarget = target.split("/")[1];
    return currentTarget === navTarget;
  };

  useEffect(() => {
    dispatch(initCart());
    return () => {};
  }, [dispatch]);

  return (
    <Link
      className={`navItem ${isRouteActive(target) ? "navItem--active" : ""}`}
      to={target}
      as={RouterLink}
      _hover={{ textDecoration: "none" }}
    >
      {iconName && <CustomIcon className="navItem_icon" name={iconName} />}
      {children}
    </Link>
  );
};

export default withRouter(NavBarItem);
