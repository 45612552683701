import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Divider,
  VStack,
  HStack,
  Stack,
  Text,
  Spacer,
  useDisclosure,
} from "@chakra-ui/react";
import CustomInput from "../../shared/Input/CustomInput";
import CustomForm from "../../shared/Form/CustomForm";
import CustomButton from "../../shared/Button/CustomButton";
import CustomSelect from "../../shared/Select/CustomSelect";
import CustomCheckbox from "../../shared/Checkbox/CustomCheckbox";
import { StepOneInfosSchema } from "../../validation/UserSchemas";
import {
  initUser,
  setPendingUserData,
} from "../../redux/actionsCreators/userActionCreators";
import { navigateToOrderStep } from "../../redux/actionsCreators/orderActionCreators";
import { orderSteps } from "../../constants/orderConstants";
import "./CommandePage.css";
import { userTypes } from "../../constants/userConstants";
import TermsConditionsModal from "../../components/TermsConditionsModal/TermsConditionsModal";
import { PROJECTS } from "../../constants/generalConstants";
import Banner from "../../includes/Banner/Banner";

const OrderStepOnePage = ({ location }) => {
  const dispatch = useDispatch();
  const { user, pendingUser } = useSelector((state) => state.userReducer);
  const { sectors } = useSelector((state) => state.generalReducer);
  const sectorOptions = sectors.map((sector) => ({
    key: sector.id,
    value: sector.title,
  }));
  const userData = { ...user, ...pendingUser };
  userData.confirmEmail = userData.confirmEmail || user.email;

  const [acceptConditions, setAcceptConditions] = useState(
    userData.acceptConditions
  );

  useEffect(() => {
    dispatch(initUser(true));
  }, [dispatch]);

  const initialValues = {
    firstname: userData.firstname,
    lastname: userData.lastname,
    email: userData.email,
    confirmEmail: userData.confirmEmail,
    entrepriseName: userData.entrepriseName,
    sector: userData.sector,
    receiveNewsletters: userData.receiveNewsletters,
    receivePromotions: userData.receivePromotions,
    acceptConditions: userData.acceptConditions,
    password: "",
    confirmPassword: "",
  };

  const project = location?.state?.project;

  const userType =
    project === PROJECTS.easyao ? user?.type : user?.easyProjectsType;

  const handleSubmit = (values, actions) => {
    dispatch(navigateToOrderStep(orderSteps.step2, project));
    actions.setSubmitting(false);
  };

  const handleChange = (e) => {
    userData[e.target.name] = e.target.value;
    dispatch(setPendingUserData(userData));
  };

  const { onClose, isOpen, onOpen } = useDisclosure();
  const handleAcceptConditions = (formProps) => {
    setAcceptConditions(true);
    formProps.setFieldValue("acceptConditions", true, true);
    onClose();
  };

  const handleCheckAcceptConditions = (event, formProps) => {
    if (event.target.id === "acceptConditions") {
      if (acceptConditions) {
        formProps.setFieldValue("acceptConditions", false, true);
        return setAcceptConditions(false);
      }
      onOpen();
    }
  };

  return (
    <Stack flex={1}>
      <VStack flex={1}>
        <Banner
          title={
            userType === userTypes.discovery
              ? "Inscription"
              : ["Vérification", "des informations"]
          }
          secondaryTitle="Etape 1"
        />
        <VStack className="account_content">
          <VStack className="accountInfos_section">
            <Text className="accountInfos_title">Mes informations</Text>
            <Divider className="accountInfos_divider" />
            <CustomForm
              initialValues={initialValues}
              validationSchema={StepOneInfosSchema}
              enableReinitialize={true}
              onSubmit={handleSubmit}
              onChange={handleChange}
            >
              {(formProps) => {
                return (
                  <>
                    <VStack className="form">
                      <HStack className="form_row">
                        <CustomInput
                          id="lastname"
                          name="lastname"
                          placeholder="Saisir votre nom"
                        />
                        <CustomInput
                          id="firstname"
                          name="firstname"
                          placeholder="Entrer votre prénom"
                        />
                      </HStack>
                      <HStack className="form_row">
                        <CustomInput
                          id="entrepriseName"
                          name="entrepriseName"
                          placeholder="Entrer le nom de votre enterprise"
                        />
                        <CustomSelect
                          id="sector"
                          name="sector"
                          placeholder="Sélectionner le secteur"
                          options={sectorOptions}
                        />
                      </HStack>
                      <HStack className="form_row">
                        <CustomInput
                          id="email"
                          name="email"
                          placeholder="Saisir votre email"
                          type="email"
                          autoComplete={false}
                        />
                        <CustomInput
                          id="confirmEmail"
                          name="confirmEmail"
                          placeholder="Confirmer votre adresse e-mail*"
                          type="email"
                          autoComplete={false}
                        />
                      </HStack>
                      <HStack className="form_row">
                        <CustomInput
                          id="password"
                          name="password"
                          placeholder="Entrer le mot de passe"
                          type="password"
                          autoComplete={false}
                        />
                        <CustomInput
                          id="confirmPassword"
                          name="confirmPassword"
                          placeholder="Répéter le mot de passe*"
                          type="password"
                          autoComplete={false}
                        />
                      </HStack>
                      <VStack spacing="10px" width="100%">
                        <VStack className="accountInfos_checkboxs">
                          <CustomCheckbox
                            id="receiveNewsletters"
                            name="receiveNewsletters"
                          >
                            <Text className="accountInfos_checkboxs-text">
                              Je souhaite recevoir les Newsletters Easy Marchés
                            </Text>
                          </CustomCheckbox>
                          <CustomCheckbox
                            id="receivePromotions"
                            name="receivePromotions"
                          >
                            <Text className="accountInfos_checkboxs-text">
                              Je souhaite recevoir des Promotions de Easy
                              Marchés et de ses partenaires
                            </Text>
                          </CustomCheckbox>

                          <CustomCheckbox
                            id="acceptConditions"
                            name="acceptConditions"
                            isChecked={acceptConditions}
                            onClick={(event) =>
                              handleCheckAcceptConditions(event, formProps)
                            }
                          >
                            <Text className="accountInfos_checkboxs-text">
                              J'accepte les{" "}
                              <span className="conditions_label">
                                conditions générales
                              </span>{" "}
                              de Easy Marchés
                            </Text>
                          </CustomCheckbox>
                        </VStack>

                        <HStack className="form_row">
                          <Spacer />
                          <CustomButton
                            loadingText="Suivant"
                            isLoading={formProps.isSubmitting}
                            type="submit"
                          >
                            Suivant
                          </CustomButton>
                        </HStack>
                      </VStack>
                    </VStack>
                    <TermsConditionsModal
                      handleAcceptConditions={() =>
                        handleAcceptConditions(formProps)
                      }
                      isOpen={isOpen}
                      onClose={onClose}
                    />
                  </>
                );
              }}
            </CustomForm>
          </VStack>
        </VStack>
      </VStack>
    </Stack>
  );
};

export default OrderStepOnePage;
