import React from "react";
import { VStack, HStack, Text, Image } from "@chakra-ui/react";
import logo from "../../assets/images/logo.png";
import "./footer.css";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <VStack className="footer" spacing="20px">
      <HStack spacing="50px">
        <Link className="footer_link--left" to="/legalNotice">
          <Text>Mentions légales</Text>
        </Link>
        <Link className="footer_link" to="/generalConditionsOfUse">
          <Text>Conditions générales</Text>
        </Link>
        <Link className="footer_link--right" to="/generalConditionsOfSale">
          <Text>Politique de confidentialité</Text>
        </Link>
      </HStack>
      <VStack spacing={"6px"}>
        <Image height="50px" src={logo} />
        <Text className="footer_copyright">Easy marchés © 2022</Text>
      </VStack>
    </VStack>
  );
};
export default Footer;
