import React, { useCallback, useEffect } from "react";
import { useDispatch } from "react-redux";
import { HStack, Spacer, Text, Box } from "@chakra-ui/react";
import CustomIcon from "../../shared/Icon/CustomIcon";
import { hideNotif } from "../../redux/actionsCreators/appActionCreators";
import { notifTypes } from "../../constants/notificationConstants";

const iconStyles = {
  success: {
    color: "#4BB543",
    name: notifTypes.success,
  },
  info: {
    color: "#2f80ed",
    name: notifTypes.info,
  },
  warning: {
    color: "#ffcc00",
    name: notifTypes.warning,
  },
  error: {
    color: "#ff3333",
    name: notifTypes.error,
  },
};

const NotificationItem = ({ notif }) => {
  const dispatch = useDispatch();
  const closeNotif = useCallback(() => {
    dispatch(hideNotif(notif.id));
  }, [dispatch, notif.id]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      closeNotif();
    }, 3000);
    return () => {
      clearTimeout(timeout);
    };
  }, [closeNotif]);

  return (
    <HStack
      style={{
        backgroundColor: "#333333",
        marginTop: 0,
        marginBottom: "1px",
        padding: "12px",
        position: "relative",
      }}
      shadow="md"
    >
      <Box
        width="8px"
        height="100%"
        backgroundColor={iconStyles[notif.type].color}
        position="absolute"
        left="0"
        top="0"
      />
      <CustomIcon
        name={iconStyles[notif.type].name}
        fill={iconStyles[notif.type].color}
        fontSize={"24px"}
      />
      <Text style={{ color: "white", marginLeft: "12px" }}>
        {notif.message}
      </Text>
      <Spacer />
      <CustomIcon
        onClick={closeNotif}
        name="close"
        style={{ fill: "white", fontSize: "22px", cursor: "pointer" }}
      />
    </HStack>
  );
};

export default NotificationItem;
