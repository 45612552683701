import React from "react";
import { useSelector } from "react-redux";
import { Redirect, Route, Switch } from "react-router-dom";
import { orderSteps, orderTypes } from "../constants/orderConstants";
import OrderStepOnePage from "../pages/OrderPage/OrderStepOnePage";
import OrderStepTwoPage from "../pages/OrderPage/OrderStepTwoPage";
import OrderStepThreePage from "../pages/OrderPage/OrderStepThreePage";

const OrderStepsRoutes = () => {
  let { pendingOrderStep, cart } = useSelector((state) => state.orderReducer);
  const { isAuthenticated } = useSelector((state) => state.userReducer);
  if (!isAuthenticated) return <Redirect to="/account/signin" />;
  if (!pendingOrderStep) {
    if (cart?.type === orderTypes.product) return <Redirect to="/cart" />;
    else return <Redirect to="/shop" />;
  }

  return (
    <Switch>
      {pendingOrderStep === orderSteps.step1 && (
        <Route exact path="/cart/order/step1" component={OrderStepOnePage} />
      )}
      {pendingOrderStep === orderSteps.step2 && (
        <Route exact path="/cart/order/step2" component={OrderStepTwoPage} />
      )}
      {pendingOrderStep === orderSteps.step3 && (
        <Route exact path="/cart/order/step3" component={OrderStepThreePage} />
      )}
      <Redirect to={"/cart/order/" + pendingOrderStep} />
    </Switch>
  );
};

export default OrderStepsRoutes;
