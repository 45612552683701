import generalHttpApi from "../api/generalHttpApi";
import { PROJECTS } from "../constants/generalConstants";
import { formatFrenchDate } from "./dateHelper";
import { formatToMonthYear } from "./formatHelper";

class CreateSendMail {
  /** 
    * envoi un mail apres c'éation de l'url de visu d'un appel d'offre
    * @param {string} aoref - aoref de l'appel d'offre
    * @param {string} objetdumarche - objet du marché en cours
    * @param {string} uident - GUID du client
    * @param {string} datepub - date de publication
    * @param {string} org - organisme
    * @param {string} dpt - département de l'organisme
    * @author SENDAO - Didier 
    exemple de parametres
      
      url="https://ae41omxmu2.execute-api.eu-central-1.amazonaws.com/dev/createurl?aoref=%aoref%&UIDENT=%UIDENT%"
      aoref="06W4172300099126"
      objetdumarche="un objet de marché" 
      uident="{GUIDTestDidier}"
      datepub = '08/11/2011'
      org="un organisme"
      dpt="64"
    */
  async shareAOByMail(aoref, objetdumarche, uident, datepub, org, dpt) {
    let email = "";
    let subject =
      "Cet appel d'offres trouvé sur EasyMarchés pourrait vous intéresser !";

    // %0A = retour chariot

    let lBody = "Bonjour,%0A%0A";
    lBody =
      lBody +
      "J'ai repéré un appel d'offre qui pourrait vous intéresser  :%0A%0A";
    lBody = lBody + objetdumarche + "%0A%0A";
    lBody = lBody + "L'annonce a été publiée le " + formatFrenchDate(datepub);
    if (org) {
      lBody = lBody + " par l'organisme " + org;
    }
    if (dpt) {
      lBody = lBody + " (" + dpt + ")";
    }

    lBody =
      lBody +
      "%0A%0ACette annonce est à consulter sur l'application EasyMarchés que j'utilise pour détecter mes appels d'offres :%0A%0A";

    if (!uident) {
      uident = "usernonconnecte";
    }

    try {
      // aller chercher l'url sur le serveur
      const reponse = await generalHttpApi.geturl(aoref, uident, PROJECTS.easyao);
      if (reponse.statusText === "OK" && !reponse.data?.data?.error) {
        lBody = lBody + encodeURIComponent(reponse.data.data) + "%0A%0A";
        let params = subject || lBody ? "?" : "";

        if (subject) params += `subject=${encodeURIComponent(subject)}`;
        if (lBody) params += `${subject ? "&" : ""}body=${lBody}`;

        window.location.href = `mailto:${email}${params}`;
      } else {
        console.log("impossible de partager l'annonce");
      }
    } catch (err) {}
  }

  async shareProjectByMail({
    projTitle,
    moaName,
    moaCity,
    moaDpt,
    projCity,
    projDpt,
    projWorkStartDate,
    projRef,
    userId="usernonconnecte"
  }) {
    const args = [];
    const subject =
      "Ce projet trouvé sur Easy Marchés pourrait vous intéresser ! ";
    let body = `Bonjour,

J’ai repéré un projet qui pourrait vous intéresser :  
${projTitle}

Le maître d’ouvrage est ${moaName} situé à ${moaCity} (${moaDpt})
Localisation du projet : ${projCity} (${projDpt})
La date estimée de début des travaux est en ${formatToMonthYear(projWorkStartDate)}

Ce projet est à consulter sur l’application Easy Marchés que j’utilise pour détecter mes projets :
`;

    try{
      const response = await generalHttpApi.geturl(projRef, userId, PROJECTS.easyprojects);
      if (response.statusText === "OK" && !response.data?.data?.error) {
        body += response.data.data;
        args.push("subject=" + encodeURIComponent(subject));
        args.push("body=" + encodeURIComponent(body));
        window.location.href = "mailto:?" + args.join("&");
      }else{
        console.log("impossible de partager le projet");
      }
    }catch(err){}
  }


  async getInTouch() {
    const args = [];
    const subject =
      "Je souhaite être contacté pour une présentation d’Easy Projets";
    const body = `Bonjour,
je souhaiterai être contacté pour une présentation du produit Easy Projet :

o	Nom :  
o	Prenom :  
o	Société :  
o	Numéro de téléphone :`;
    args.push("subject=" + encodeURIComponent(subject));
    args.push("body=" + encodeURIComponent(body));
    window.location.href = "mailto:support@easymarches.fr?"+args.join("&");
  }
}

export default new CreateSendMail();
