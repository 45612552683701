import keyMirror from "keymirror";

export default keyMirror({
  ADD_CART_ITEM: null,
  UPDATE_CART_ITEM_QTY: null,
  REMOVE_CART_ITEM: null,
  INIT_CART: null,
  CLEAR_CART: null,
  UPDATE_CART: null,
  INIT_ORDERS: null,
  CANCEL_ORDER: null,
  SET_PENDING_SUBSCRIPTION: null,
  INIT_TVA: null,
  INIT_SUBSCRIPTION_ORDERS: null,
  SET_PROMOCODE: null,
  CLEAR_PROMOCODE: null,
  UPDATE_CART_PENDING: null,
  REMOVE_ITEM_PENDING: null,
});
