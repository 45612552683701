import projectsHttpApi from "../../api/projectsHttpApi";
import userHttpApi from "../../api/userHttpApi";
import {
  notifMessages,
  notifTypes,
} from "../../constants/notificationConstants";
import { formatSearchParams } from "../../helpers/commonUtils";
import projectsSearchActionTypes from "../actionsTypes/projectsSearchActionTypes";
import { showNotif } from "./appActionCreators";
import { initUser } from "./userActionCreators";

/**
 *  Récupérer le résultat de recherche
 *
 * @async
 */
export const getSearchResults = (searchParams) => async (dispatch, getState) => {
  try {
    const isAuthenticated = getState()?.userReducer?.isAuthenticated;
    dispatch({
      type: projectsSearchActionTypes.SET_SEARCH_PARAMS,
      payload: searchParams,
    });
    dispatch({ type: projectsSearchActionTypes.SEARCH_LOADING, payload: true });
    const formatedSearchParams = formatSearchParams(searchParams);
    const res = await projectsHttpApi.searchProjects(formatedSearchParams, isAuthenticated);

    if (res?.status === 200) {
      const projects = res?.data?.data?.projects || [];
      const totalResults = res?.data?.data?.total || 0;

      dispatch({
        type: projectsSearchActionTypes.SET_SEARCH_RESULTS,
        payload: { projects, totalResults },
      });
      dispatch({
        type: projectsSearchActionTypes.SEARCH_LOADING,
        payload: false,
      });
    }
  } catch (err) {
    console.log("SEARCH ERROR", { err });
    if (err.message !== "canceled") {
      dispatch({
        type: projectsSearchActionTypes.SET_SEARCH_RESULTS,
        payload: { projects: [], totalResults: 0 },
      });
      dispatch({
        type: projectsSearchActionTypes.SEARCH_LOADING,
        payload: false,
      });
    }
  }
};

/**
 *  Récupérer les status de recherche
 *
 * @async
 */
export const getSearchStatus = () => async (dispatch) => {
  const res = await projectsHttpApi.getSearchStats();

  return dispatch({
    type: projectsSearchActionTypes.SET_SEARCH_STATUS,
    payload: res?.data?.data,
  });
};

/**
 *  Récupérer les informations essentielles
 *
 * @async
 */
export const getEessentialDetails = (projRef) => async (dispatch, getState) => {
  const { user } = getState().userReducer;
  const res = await projectsHttpApi.getProjectBasicDetails(projRef, user?.id);
  const essentialDetails = res?.data?.data;

  return dispatch({
    type: projectsSearchActionTypes.SET_ESSENTIAL_DETAILS,
    payload: essentialDetails,
  });
};

/**
 *  Récupérer les informations Types d’actifs & Répartition Surface
 *
 * @async
 */
export const getAssetTypes = (projRef) => async (dispatch) => {
  const res = await projectsHttpApi.getAssetsTypes(projRef);
  const assetTypes = res?.data?.data?.Value;

  return dispatch({
    type: projectsSearchActionTypes.SET_ASSET_TYPES,
    payload: assetTypes,
  });
};

/**
 *  Récupérer les informations du Maître d’ouvrage
 *
 * @async
 */
export const getBuildingOwnerInfos = (projRef) => async (dispatch) => {
  const res = await projectsHttpApi.getMOAdata(projRef);
  const buildingOwnerInfos = res?.data?.data?.Value;

  return dispatch({
    type: projectsSearchActionTypes.SET_MOA_INFOS,
    payload: buildingOwnerInfos,
  });
};

/**
 *  Récupérer les informations Types d’actifs & Répartition Surface
 *
 * @async
 */
export const getBuildingPermitData = (projRef) => async (dispatch) => {
  const res = await projectsHttpApi.getProjectPermitData(projRef);

  return dispatch({
    type: projectsSearchActionTypes.SET_BUILDING_PERMIT,
    payload: res?.data?.data,
  });
};
/**
 *  Récupérer les informations Types d’actifs & Répartition Surface
 *
 * @async
 */
export const getProjectBasicInfos = (projRef) => async (dispatch) => {
  const res = await projectsHttpApi.getProjectInformations(projRef);
  const projectCard = res?.data?.data?.Value;

  return dispatch({
    type: projectsSearchActionTypes.SET_PROJECT_BASIC_INFOS,
    payload: projectCard,
  });
};

/**
 *  Récupérer les informations des Maîtres d’œuvre disponibles
 *
 * @async
 */
export const getProjectManagersDetails =
  (projRef) => async (dispatch, getState) => {
    const { isAuthenticated } = getState().userReducer;
    const res = isAuthenticated
      ? await projectsHttpApi.getMOEdata(projRef)
      : await projectsHttpApi.getMOEPreviewData(projRef);
    const projectManagersDetails = res?.data?.data;

    return dispatch({
      type: projectsSearchActionTypes.SET_MOE_INFOS,
      payload: projectManagersDetails,
    });
  };

export const visualizeMOE = (projRef) => async (dispatch) => {
  try {
    const res = await userHttpApi.allowUserToVisualizeMOE(projRef);
    if (res.status === 200) {
      const moeRes = await projectsHttpApi.getMOEdata(projRef);
      const projectManagersDetails = moeRes?.data?.data;
      dispatch({
        type: projectsSearchActionTypes.SET_MOE_INFOS,
        payload: projectManagersDetails,
      });
      dispatch(showNotif(notifMessages.moeHaveBeenVisualized));
      dispatch(initUser());
    }
  } catch (err) {
    dispatch(
      showNotif({
        type: notifTypes.error,
        message: err?.response?.data?.message || err?.message,
      })
    );
  }
};

export const clearSearch = () => (dispatch) => {
  dispatch({
    type: projectsSearchActionTypes.SET_SEARCH_RESULTS,
    payload: { projects: [], totalResults: 0 },
  });
  dispatch({
    type: projectsSearchActionTypes.SET_SEARCH_PARAMS,
    payload: {},
  });
};


export const clearProjectDetails = () => (dispatch) => {
  dispatch({
    type: projectsSearchActionTypes.CLEAR_PROJECT_DETAILS
  })
}