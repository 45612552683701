import keyMirror from "keymirror";

export default keyMirror({
  INIT_USER_DATA: null,
  LOADING_USER_DATA: null,
  SIGNIN: null,
  SIGNUP: null,
  LOGOUT: null,
  UPDATE_USER: null,
  SET_PENDING_USER_DATA: null,
  SET_PLAN: null,
});
