import { addDays } from "date-fns";

export const addDaysToDate = (isoData, days) => {
  return addDays(new Date(isoData), days);
};

//// Fonctions de calcul de dates ////
export const getDiffDate = (date1, date2) => {
  const diffInMs = date2 - date1;
  if (diffInMs > 172800000) {
    return "Clôture dans " + (diffInMs / (1000 * 60 * 60 * 24)).toFixed(0) + " jours";
  }
  else if (diffInMs <= 172800000 && diffInMs > 3600000) {
    return "Clôture dans " + (diffInMs / (1000 * 60 * 60)).toFixed(0) + " heures";
  }
  else if (diffInMs <= 3600000 && diffInMs > 0) {
    return "Clôture dans " + (diffInMs / (1000 * 60)).toFixed(0) + " minutes";
  }
  else if (diffInMs <= 0 && diffInMs > -3600000) {
    return "Clôturé depuis " + Math.abs(diffInMs / (1000 * 60)).toFixed(0) + " minutes";
  }
  else if (diffInMs <= -3600000 && diffInMs > -172800000) {
    return "Clôturé depuis " + Math.abs(diffInMs / (1000 * 60 * 60)).toFixed(0) + " heures";
  }
  else if (diffInMs < -172800000) {
      // si date2 supérieur à 1980 > on affiche
      if (diffInMs > -1000825593816) { 
          return "Clôturé depuis " + Math.abs(diffInMs / (1000 * 60 * 60 * 24)).toFixed(0) + " jours";
      }
      // sinon > on retourne "vide" pour ne pas afficher
      else {
        return "";
      }
  }
}

export const formatFrenchDate = (date) => {
  let frenchDate = date.replace(/(\d{4})-(\d{2})-(\d{2})T?[\d:]*[\d+\.Z:\+]*/, "$3/$2/$1")
  // let frenchHour = /^.+T([\d:]+)[\d+\.Z]*$/.test(date) ? date.replace(/\d{4}-\d{2}-\d{2}T([\d:]+)[\d+\.Z]*/, " à $1") : ""
  return frenchDate
}


