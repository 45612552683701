import React from "react";
import { useSelector } from "react-redux";
import { Stack, VStack } from "@chakra-ui/react";
import { orderTypes } from "../../constants/orderConstants";
import OneTimePayment from "../../components/OneTimePayment/OneTimePayment";
import SubscriptionPayment from "../../components/SubscriptionPayment/SubscriptionPayment";
import Banner from "../../includes/Banner/Banner";

const OrderStepThreePage = ({ location }) => {
  const { cart } = useSelector((state) => state.orderReducer);

  return (
    <Stack flex={1}>
      <VStack flex={1}>
        <Banner title="Paiement" />
        <VStack className="payment_content">
          <VStack className="accountInfos_section">
            {cart.type === orderTypes.product ? (
              <OneTimePayment />
            ) : (
              <SubscriptionPayment project={location?.state?.project} />
            )}
          </VStack>
        </VStack>
      </VStack>
    </Stack>
  );
};

export default OrderStepThreePage;
