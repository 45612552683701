import KeyMirror from "keymirror";

export const CACHE_TIME = 2 * 60 * 1000; //2 mins
export const PROJECTS = KeyMirror({
  easyao: null,
  easyprojects: null,
});

export const PAGE_SIZE = 10;

export const OfferTitles = {
  easyao: "Easy Marchés",
  easyprojects: "Easy Projets",
};

export const OfferTypesLabels = {
  premium: "Offre Premium",
  discovery: "Offre découverte",
};

export const productsLabels = {
  easyao: "marchés publics",
  easyprojects: "projets privés",
};

export const offerTypesLabels = {
  premium: "offre premium",
  discovery: "offre découverte",
  complete: "offre complète",
};

export const metaData = {
  "/search": {
    easyao: {
      title: "EasyMarchés - Recherche des appel d'offres",
      description: "Recherche des appel d'offres",
      keywords: [],
    },
    easyprojects: {
      title: "EasyMarchés - Recherche des projets privés",
      description: "Recherche des projets privés",
      keywords: [],
    },
  },
  "/details": {
    easyao: {
      title: "EasyMarchés - Détails d'appel d'offre",
      description: "Détails d'appel d'offre",
      keywords: [],
    },
    easyprojects: {
      title: "EasyMarchés - Détails du projet privé",
      description: "Détails du projet privé",
      keywords: [],
    },
  },
  "/shop": {
    default: {
      title: "EasyMarchés - Boutique",
      description: "Boutique",
      keywords: [],
    },
  },
  "/account/signin": {
    default: {
      title: "EasyMarchés - Login",
      description: "Login",
      keywords: [],
    },
  },
  "/account/signup": {
    default: {
      title: "EasyMarchés - Créer un compte",
      description: "Créer un compte",
      keywords: [],
    },
  },
  "/account/infos": {
    default: {
      title: "EasyMarchés - Compte informations",
      description: "Compte informations",
      keywords: [],
    },
  },
  "/account/billing": {
    default: {
      title: "EasyMarchés - Facturation & paiement",
      description: "Facturation & paiement",
      keywords: [],
    },
  },
  "/account/consommation": {
    default: {
      title: "EasyMarchés - Consommationt",
      description: "Les crédits DCE et MOE du compte",
      keywords: [],
    },
  },
  "/account/contacts": {
    default: {
      title: "EasyMarchés - Contacts",
      description: "Contacter le service support",
      keywords: [],
    },
  },
  "/favorites": {
    easyao: {
      title: "EasyMarchés - Favoris des appel d'offres",
      description: "Favoris des appel d'offres",
      keywords: [],
    },
    easyprojects: {
      title: "EasyMarchés - Favoris des projets privés",
      description: "Favoris des projets privés",
      keywords: [],
    },
  },
  "/cart": {
    default: {
      title: "EasyMarchés - Panier",
      description: "Le Panier",
      keywords: [],
    },
  },
};

export const listColors = [
  "#eb5757",
  "#57ccf2",
  "#7B68EE",
  "#008B8B",
  "#1f77b4",
  "#ffbb78",
  "#98df8a",
  "#d62728",
  "#ff9896",
  "#9467bd",
  "#c5b0d5",
  "#8c564b",
  "#c49c94",
  "#e377c2",
  "#f7b6d2",
  "#7f7f7f",
  "#c7c7c7",
  "#bcbd22",
  "#dbdb8d",
  "#17becf",
  "#9edae5",
];