import {
  format,
  getYear,
  getMonth,
  getHours,
  getMinutes,
  getDate,
  isMatch,
} from "date-fns";
import { fr } from "date-fns/locale";

export const formatPrice = (value) => {
  return parseFloat(value).toFixed(2);
};

export const formatDate = (isoDate, form = "dd/MM/yyyy HH:mm") => {
  if (new Date(isoDate).toString() === "Invalid Date") return "";
  return format(new Date(isoDate), form);
};

export const formatDateWithoutHour = (isoDate, form = "dd/MM/yyyy") => {
  if (new Date(isoDate).toString() === "Invalid Date") return "";
  return format(new Date(isoDate), form);
};

export const capitalizeWord = (word) => {
  if (!word?.length) return word;
  return word.charAt(0).toUpperCase() + word.substring(1);
};

export const formatDatetoISOString = (dateToFormat) => {
  const date = new Date(dateToFormat);
  if (!dateToFormat || date.toString() === "Invalid Date") return "";
  return date.toISOString();
};

export const formatToMonthYear = (isoDate) => {
  const date = new Date(isoDate);
  if (date.toString() === "Invalid Date") return "";
  const formatedDate = format(date, "LLLL yyyy", { locale: fr });
  return formatedDate.toUpperCase().charAt(0) + formatedDate.substring(1);
};

export const getYearFromIso = (isoDate) => {
  const date = new Date(isoDate);
  if (date.toString() === "Invalid Date") return "";
  return getYear(date);
};

export const getMonthFromIso = (isoDate) => {
  const date = new Date(isoDate);
  if (date.toString() === "Invalid Date") return "";
  return getMonth(date) + 1;
};

export const getDateFromIso = (isoDate) => {
  const date = new Date(isoDate);
  if (date.toString() === "Invalid Date") return "";
  return getDate(date);
};
export const getHoursFromIso = (isoDate) => {
  const date = new Date(isoDate);
  if (date.toString() === "Invalid Date") return "";
  return getHours(date);
};
export const getMinutesFromIso = (isoDate) => {
  const date = new Date(isoDate);
  if (date.toString() === "Invalid Date") return "";
  return getMinutes(date);
};

export const isMatchDateFormat = (dateString, formatString) => {
  return isMatch(dateString, formatString);
};

export const addSeparator = (value, charNumber, separator) => {
  try {
    const regExp = new RegExp(
      `(?!^)(?=(?:\\d{${charNumber}})+(?:\\.|$))`,
      "gm"
    );
    return value.toString().replace(regExp, separator);
  } catch (error) {
    return "0";
  }
};

export const formatSiret = (siret) => {
  try {
    const part1 = siret.substring(0, 9);
    const part2 = siret.substring(9);
    return `${addSeparator(part1, 3, " ")} ${part2}`;
  } catch (error) {
    return siret;
  }
};
