export const regionsList = [
  {
    label: "OCCITANIE",
    value: "76",
    type: "region",
  },
  {
    label: "CENTRE-VAL DE LOIRE",
    value: "24",
    type: "region",
  },
  {
    label: "NORMANDIE",
    value: "28",
    type: "region",
  },
  {
    label: "NOUVELLE-AQUITAINE",
    value: "75",
    type: "region",
  },
  {
    label: "GRAND EST",
    value: "44",
    type: "region",
  },
  {
    label: "PROVENCE-ALPES-COTE D'AZUR",
    value: "93",
    type: "region",
  },
  {
    label: "AUVERGNE-RHONE-ALPES",
    value: "84",
    type: "region",
  },
  {
    label: "BOURGOGNE-FRANCHE-COMTE",
    value: "27",
    type: "region",
  },
  {
    label: "PAYS DE LA LOIRE",
    value: "52",
    type: "region",
  },
  {
    label: "BRETAGNE",
    value: "53",
    type: "region",
  },
  {
    label: "HAUTS-DE-FRANCE",
    value: "32",
    type: "region",
  },
  {
    label: "CORSE",
    value: "94",
    type: "region",
  },
  {
    label: "ILE-DE-FRANCE",
    value: "11",
    type: "region",
  },
  {
    label: "GUADELOUPE",
    value: "01",
    type: "region",
  },
  {
    label: "MARTINIQUE",
    value: "02",
    type: "region",
  },
  {
    label: "MAYOTTE",
    value: "06",
    type: "region",
  },
  {
    label: "LA REUNION",
    value: "04",
    type: "region",
  },
  {
    label: "GUYANE",
    value: "03",
    type: "region",
  },
];

export const departementsList = [
  {
    label: "AIN",
    value: "01",
    type: "depart",
  },
  {
    label: "AISNE",
    value: "02",
    type: "depart",
  },
  {
    label: "ALLIER",
    value: "03",
    type: "depart",
  },
  {
    label: "ALPES-DE-HAUTE-PROVENCE",
    value: "04",
    type: "depart",
  },
  {
    label: "HAUTES-ALPES",
    value: "05",
    type: "depart",
  },
  {
    label: "ALPES-MARITIMES",
    value: "06",
    type: "depart",
  },
  {
    label: "ARDECHE",
    value: "07",
    type: "depart",
  },
  {
    label: "ARDENNES",
    value: "08",
    type: "depart",
  },
  {
    label: "ARIEGE",
    value: "09",
    type: "depart",
  },
  {
    label: "AUBE",
    value: "10",
    type: "depart",
  },
  {
    label: "AUDE",
    value: "11",
    type: "depart",
  },
  {
    label: "AVEYRON",
    value: "12",
    type: "depart",
  },
  {
    label: "BOUCHES-DU-RHONE",
    value: "13",
    type: "depart",
  },
  {
    label: "CALVADOS",
    value: "14",
    type: "depart",
  },
  {
    label: "CANTAL",
    value: "15",
    type: "depart",
  },
  {
    label: "CHARENTE",
    value: "16",
    type: "depart",
  },
  {
    label: "CHARENTE-MARITIME",
    value: "17",
    type: "depart",
  },
  {
    label: "CHER",
    value: "18",
    type: "depart",
  },
  {
    label: "CORREZE",
    value: "19",
    type: "depart",
  },
  {
    label: "COTE-D'OR",
    value: "21",
    type: "depart",
  },
  {
    label: "COTES-D'ARMOR",
    value: "22",
    type: "depart",
  },
  {
    label: "CREUSE",
    value: "23",
    type: "depart",
  },
  {
    label: "DORDOGNE",
    value: "24",
    type: "depart",
  },
  {
    label: "DOUBS",
    value: "25",
    type: "depart",
  },
  {
    label: "DROME",
    value: "26",
    type: "depart",
  },
  {
    label: "EURE",
    value: "27",
    type: "depart",
  },
  {
    label: "EURE-ET-LOIR",
    value: "28",
    type: "depart",
  },
  {
    label: "FINISTERE",
    value: "29",
    type: "depart",
  },
  {
    label: "CORSE-DU-SUD",
    value: "2A",
    type: "depart",
  },
  {
    label: "HAUTE-CORSE",
    value: "2B",
    type: "depart",
  },
  {
    label: "GARD",
    value: "30",
    type: "depart",
  },
  {
    label: "HAUTE-GARONNE",
    value: "31",
    type: "depart",
  },
  {
    label: "GERS",
    value: "32",
    type: "depart",
  },
  {
    label: "GIRONDE",
    value: "33",
    type: "depart",
  },
  {
    label: "HERAULT",
    value: "34",
    type: "depart",
  },
  {
    label: "ILLE-ET-VILAINE",
    value: "35",
    type: "depart",
  },
  {
    label: "INDRE",
    value: "36",
    type: "depart",
  },
  {
    label: "INDRE-ET-LOIRE",
    value: "37",
    type: "depart",
  },
  {
    label: "ISERE",
    value: "38",
    type: "depart",
  },
  {
    label: "JURA",
    value: "39",
    type: "depart",
  },
  {
    label: "LANDES",
    value: "40",
    type: "depart",
  },
  {
    label: "LOIR-ET-CHER",
    value: "41",
    type: "depart",
  },
  {
    label: "LOIRE",
    value: "42",
    type: "depart",
  },
  {
    label: "HAUTE-LOIRE",
    value: "43",
    type: "depart",
  },
  {
    label: "LOIRE-ATLANTIQUE",
    value: "44",
    type: "depart",
  },
  {
    label: "LOIRET",
    value: "45",
    type: "depart",
  },
  {
    label: "LOT",
    value: "46",
    type: "depart",
  },
  {
    label: "LOT-ET-GARONNE",
    value: "47",
    type: "depart",
  },
  {
    label: "LOZERE",
    value: "48",
    type: "depart",
  },
  {
    label: "MAINE-ET-LOIRE",
    value: "49",
    type: "depart",
  },
  {
    label: "MANCHE",
    value: "50",
    type: "depart",
  },
  {
    label: "MARNE",
    value: "51",
    type: "depart",
  },
  {
    label: "HAUTE-MARNE",
    value: "52",
    type: "depart",
  },
  {
    label: "MAYENNE",
    value: "53",
    type: "depart",
  },
  {
    label: "MEURTHE-ET-MOSELLE",
    value: "54",
    type: "depart",
  },
  {
    label: "MEUSE",
    value: "55",
    type: "depart",
  },
  {
    label: "MORBIHAN",
    value: "56",
    type: "depart",
  },
  {
    label: "MOSELLE",
    value: "57",
    type: "depart",
  },
  {
    label: "NIEVRE",
    value: "58",
    type: "depart",
  },
  {
    label: "NORD",
    value: "59",
    type: "depart",
  },
  {
    label: "OISE",
    value: "60",
    type: "depart",
  },
  {
    label: "ORNE",
    value: "61",
    type: "depart",
  },
  {
    label: "PAS-DE-CALAIS",
    value: "62",
    type: "depart",
  },
  {
    label: "PUY-DE-DOME",
    value: "63",
    type: "depart",
  },
  {
    label: "PYRENEES-ATLANTIQUES",
    value: "64",
    type: "depart",
  },
  {
    label: "HAUTES-PYRENEES",
    value: "65",
    type: "depart",
  },
  {
    label: "PYRENEES-ORIENTALES",
    value: "66",
    type: "depart",
  },
  {
    label: "BAS-RHIN",
    value: "67",
    type: "depart",
  },
  {
    label: "HAUT-RHIN",
    value: "68",
    type: "depart",
  },
  {
    label: "RHONE",
    value: "69",
    type: "depart",
  },
  {
    label: "HAUTE-SAONE",
    value: "70",
    type: "depart",
  },
  {
    label: "SAONE-ET-LOIRE",
    value: "71",
    type: "depart",
  },
  {
    label: "SARTHE",
    value: "72",
    type: "depart",
  },
  {
    label: "SAVOIE",
    value: "73",
    type: "depart",
  },
  {
    label: "HAUTE-SAVOIE",
    value: "74",
    type: "depart",
  },
  {
    label: "PARIS",
    value: "75",
    type: "depart",
  },
  {
    label: "SEINE-MARITIME",
    value: "76",
    type: "depart",
  },
  {
    label: "SEINE-ET-MARNE",
    value: "77",
    type: "depart",
  },
  {
    label: "YVELINES",
    value: "78",
    type: "depart",
  },
  {
    label: "DEUX-SEVRES",
    value: "79",
    type: "depart",
  },
  {
    label: "SOMME",
    value: "80",
    type: "depart",
  },
  {
    label: "TARN",
    value: "81",
    type: "depart",
  },
  {
    label: "TARN-ET-GARONNE",
    value: "82",
    type: "depart",
  },
  {
    label: "VAR",
    value: "83",
    type: "depart",
  },
  {
    label: "VAUCLUSE",
    value: "84",
    type: "depart",
  },
  {
    label: "VENDEE",
    value: "85",
    type: "depart",
  },
  {
    label: "VIENNE",
    value: "86",
    type: "depart",
  },
  {
    label: "HAUTE-VIENNE",
    value: "87",
    type: "depart",
  },
  {
    label: "VOSGES",
    value: "88",
    type: "depart",
  },
  {
    label: "YONNE",
    value: "89",
    type: "depart",
  },
  {
    label: "TERRITOIRE DE BELFORT",
    value: "90",
    type: "depart",
  },
  {
    label: "ESSONNE",
    value: "91",
    type: "depart",
  },
  {
    label: "HAUTS-DE-SEINE",
    value: "92",
    type: "depart",
  },
  {
    label: "SEINE-SAINT-DENIS",
    value: "93",
    type: "depart",
  },
  {
    label: "VAL-DE-MARNE",
    value: "94",
    type: "depart",
  },
  {
    label: "VAL-D'OISE",
    value: "95",
    type: "depart",
  },
  {
    label: "GUADELOUPE",
    value: "971",
    type: "depart",
  },
  {
    label: "MARTINIQUE",
    value: "972",
    type: "depart",
  },
  {
    label: "GUYANE",
    value: "973",
    type: "depart",
  },
  {
    label: "LA REUNION",
    value: "974",
    type: "depart",
  },
  {
    label: "MAYOTTE",
    value: "976",
    type: "depart",
  },
];

export const communesList = [
  {
    label: "ZUYDCOOTE",
    value: "59668",
    type: "common",
  },
  {
    label: "LEFFRINCKOUCKE",
    value: "59340",
    type: "common",
  },
  {
    label: "GHYVELDE",
    value: "59260",
    type: "common",
  },
  {
    label: "BRAY-DUNES",
    value: "59107",
    type: "common",
  },
  {
    label: "UXEM",
    value: "59605",
    type: "common",
  },
  {
    label: "TETEGHEM-COUDEKERQUE-VILLAGE",
    value: "59588",
    type: "common",
  },
  {
    label: "DUNKERQUE",
    value: "59183",
    type: "common",
  },
  {
    label: "COUDEKERQUE-BRANCHE",
    value: "59155",
    type: "common",
  },
  {
    label: "CAPPELLE-LA-GRANDE",
    value: "59131",
    type: "common",
  },
  {
    label: "GRANDE-SYNTHE",
    value: "59271",
    type: "common",
  },
  {
    label: "ARMBOUTS-CAPPEL",
    value: "59016",
    type: "common",
  },
  {
    label: "LOON-PLAGE",
    value: "59359",
    type: "common",
  },
  {
    label: "GRAVELINES",
    value: "59273",
    type: "common",
  },
  {
    label: "GRAND-FORT-PHILIPPE",
    value: "59272",
    type: "common",
  },
  {
    label: "OYE-PLAGE",
    value: "62645",
    type: "common",
  },
  {
    label: "OOST-CAPPEL",
    value: "59448",
    type: "common",
  },
  {
    label: "KILLEM",
    value: "59326",
    type: "common",
  },
  {
    label: "HONDSCHOOTE",
    value: "59309",
    type: "common",
  },
  {
    label: "WEST-CAPPEL",
    value: "59657",
    type: "common",
  },
  {
    label: "WARHEM",
    value: "59641",
    type: "common",
  },
  {
    label: "REXPOEDE",
    value: "59499",
    type: "common",
  },
  {
    label: "QUAEDYPRE",
    value: "59478",
    type: "common",
  },
  {
    label: "STEENE",
    value: "59579",
    type: "common",
  },
  {
    label: "SOCX",
    value: "59570",
    type: "common",
  },
  {
    label: "HOYMILLE",
    value: "59319",
    type: "common",
  },
  {
    label: "CROCHTE",
    value: "59162",
    type: "common",
  },
  {
    label: "BIERNE",
    value: "59082",
    type: "common",
  },
  {
    label: "BERGUES",
    value: "59067",
    type: "common",
  },
  {
    label: "SPYCKER",
    value: "59576",
    type: "common",
  },
  {
    label: "PITGAM",
    value: "59463",
    type: "common",
  },
  {
    label: "BROUCKERQUE",
    value: "59110",
    type: "common",
  },
  {
    label: "CRAYWICK",
    value: "59159",
    type: "common",
  },
  {
    label: "BOURBOURG",
    value: "59094",
    type: "common",
  },
  {
    label: "SAINT-OMER-CAPELLE",
    value: "62766",
    type: "common",
  },
  {
    label: "SAINT-FOLQUIN",
    value: "62748",
    type: "common",
  },
  {
    label: "SAINT-GEORGES-SUR-L'AA",
    value: "59532",
    type: "common",
  },
  {
    label: "VIEILLE-EGLISE",
    value: "62852",
    type: "common",
  },
  {
    label: "OFFEKERQUE",
    value: "62634",
    type: "common",
  },
  {
    label: "NOUVELLE-EGLISE",
    value: "62623",
    type: "common",
  },
  {
    label: "GUEMPS",
    value: "62393",
    type: "common",
  },
  {
    label: "MARCK",
    value: "62548",
    type: "common",
  },
  {
    label: "COQUELLES",
    value: "62239",
    type: "common",
  },
  {
    label: "CALAIS",
    value: "62193",
    type: "common",
  },
  {
    label: "SANGATTE",
    value: "62774",
    type: "common",
  },
  {
    label: "HOUTKERQUE",
    value: "59318",
    type: "common",
  },
  {
    label: "BAMBECQUE",
    value: "59046",
    type: "common",
  },
  {
    label: "WYLDER",
    value: "59665",
    type: "common",
  },
  {
    label: "WORMHOUT",
    value: "59663",
    type: "common",
  },
  {
    label: "HERZEELE",
    value: "59305",
    type: "common",
  },
  {
    label: "ZEGERSCAPPEL",
    value: "59666",
    type: "common",
  },
  {
    label: "LEDRINGHEM",
    value: "59338",
    type: "common",
  },
  {
    label: "ESQUELBECQ",
    value: "59210",
    type: "common",
  },
  {
    label: "BISSEZEELE",
    value: "59083",
    type: "common",
  },
  {
    label: "MERCKEGHEM",
    value: "59397",
    type: "common",
  },
  {
    label: "LOOBERGHE",
    value: "59358",
    type: "common",
  },
  {
    label: "ERINGHEM",
    value: "59200",
    type: "common",
  },
  {
    label: "DRINCHAM",
    value: "59182",
    type: "common",
  },
  {
    label: "BOLLEZEELE",
    value: "59089",
    type: "common",
  },
  {
    label: "SAINT-PIERRE-BROUCK",
    value: "59539",
    type: "common",
  },
  {
    label: "CAPPELLE-BROUCK",
    value: "59130",
    type: "common",
  },
  {
    label: "SAINTE-MARIE-KERQUE",
    value: "62756",
    type: "common",
  },
  {
    label: "AUDRUICQ",
    value: "62057",
    type: "common",
  },
  {
    label: "NORTKERQUE",
    value: "62621",
    type: "common",
  },
  {
    label: "LES ATTAQUES",
    value: "62043",
    type: "common",
  },
  {
    label: "ARDRES",
    value: "62038",
    type: "common",
  },
  {
    label: "ANDRES",
    value: "62031",
    type: "common",
  },
  {
    label: "SAINT-TRICAT",
    value: "62769",
    type: "common",
  },
  {
    label: "NIELLES-LES-CALAIS",
    value: "62615",
    type: "common",
  },
  {
    label: "HAMES-BOUCRES",
    value: "62408",
    type: "common",
  },
  {
    label: "FRETHUN",
    value: "62360",
    type: "common",
  },
  {
    label: "COULOGNE",
    value: "62244",
    type: "common",
  },
  {
    label: "SAINT-INGLEVERT",
    value: "62751",
    type: "common",
  },
  {
    label: "PIHEN-LES-GUINES",
    value: "62657",
    type: "common",
  },
  {
    label: "PEUPLINGUES",
    value: "62654",
    type: "common",
  },
  {
    label: "HERVELINGHEN",
    value: "62444",
    type: "common",
  },
  {
    label: "ESCALLES",
    value: "62307",
    type: "common",
  },
  {
    label: "BONNINGUES-LES-CALAIS",
    value: "62156",
    type: "common",
  },
  {
    label: "WISSANT",
    value: "62899",
    type: "common",
  },
  {
    label: "TARDINGHEN",
    value: "62806",
    type: "common",
  },
  {
    label: "AUDEMBERT",
    value: "62052",
    type: "common",
  },
  {
    label: "BOESCHEPE",
    value: "59086",
    type: "common",
  },
  {
    label: "WINNEZEELE",
    value: "59662",
    type: "common",
  },
  {
    label: "STEENVOORDE",
    value: "59580",
    type: "common",
  },
  {
    label: "ZERMEZEELE",
    value: "59667",
    type: "common",
  },
  {
    label: "OUDEZEELE",
    value: "59453",
    type: "common",
  },
  {
    label: "HARDIFORT",
    value: "59282",
    type: "common",
  },
  {
    label: "WEMAERS-CAPPEL",
    value: "59655",
    type: "common",
  },
  {
    label: "RUBROUCK",
    value: "59516",
    type: "common",
  },
  {
    label: "OCHTEZEELE",
    value: "59443",
    type: "common",
  },
  {
    label: "ARNEKE",
    value: "59018",
    type: "common",
  },
  {
    label: "VOLCKERINCKHOVE",
    value: "59628",
    type: "common",
  },
  {
    label: "LEDERZEELE",
    value: "59337",
    type: "common",
  },
  {
    label: "BUYSSCHEURE",
    value: "59119",
    type: "common",
  },
  {
    label: "BROXEELE",
    value: "59111",
    type: "common",
  },
  {
    label: "WULVERDINGHE",
    value: "59664",
    type: "common",
  },
  {
    label: "WATTEN",
    value: "59647",
    type: "common",
  },
  {
    label: "SAINT-MOMELIN",
    value: "59538",
    type: "common",
  },
  {
    label: "MILLAM",
    value: "59402",
    type: "common",
  },
  {
    label: "HOLQUE",
    value: "59307",
    type: "common",
  },
  {
    label: "RUMINGHEM",
    value: "62730",
    type: "common",
  },
  {
    label: "POLINCOVE",
    value: "62662",
    type: "common",
  },
  {
    label: "NORDAUSQUES",
    value: "62618",
    type: "common",
  },
  {
    label: "MUNCQ-NIEURLET",
    value: "62598",
    type: "common",
  },
  {
    label: "EPERLECQUES",
    value: "62297",
    type: "common",
  },
  {
    label: "BAYENGHEM-LES-EPERLECQUES",
    value: "62087",
    type: "common",
  },
  {
    label: "ZUTKERQUE",
    value: "62906",
    type: "common",
  },
  {
    label: "ZOUAFQUES",
    value: "62904",
    type: "common",
  },
  {
    label: "RECQUES-SUR-HEM",
    value: "62699",
    type: "common",
  },
  {
    label: "NIELLES-LES-ARDRES",
    value: "62614",
    type: "common",
  },
  {
    label: "LOUCHES",
    value: "62531",
    type: "common",
  },
  {
    label: "RODELINGHEM",
    value: "62716",
    type: "common",
  },
  {
    label: "LANDRETHUN-LES-ARDRES",
    value: "62488",
    type: "common",
  },
  {
    label: "BREMES",
    value: "62174",
    type: "common",
  },
  {
    label: "BOUQUEHAULT",
    value: "62161",
    type: "common",
  },
  {
    label: "BALINGHEM",
    value: "62078",
    type: "common",
  },
  {
    label: "AUTINGUES",
    value: "62059",
    type: "common",
  },
  {
    label: "HERMELINGHEN",
    value: "62439",
    type: "common",
  },
  {
    label: "GUINES",
    value: "62397",
    type: "common",
  },
  {
    label: "FIENNES",
    value: "62334",
    type: "common",
  },
  {
    label: "CAMPAGNE-LES-GUINES",
    value: "62203",
    type: "common",
  },
  {
    label: "CAFFIERS",
    value: "62191",
    type: "common",
  },
  {
    label: "RINXENT",
    value: "62711",
    type: "common",
  },
  {
    label: "LEULINGHEN-BERNES",
    value: "62505",
    type: "common",
  },
  {
    label: "LEUBRINGHEN",
    value: "62503",
    type: "common",
  },
  {
    label: "LANDRETHUN-LE-NORD",
    value: "62487",
    type: "common",
  },
  {
    label: "FERQUES",
    value: "62329",
    type: "common",
  },
  {
    label: "MARQUISE",
    value: "62560",
    type: "common",
  },
  {
    label: "BAZINGHEN",
    value: "62089",
    type: "common",
  },
  {
    label: "AUDRESSELLES",
    value: "62056",
    type: "common",
  },
  {
    label: "AUDINGHEN",
    value: "62054",
    type: "common",
  },
  {
    label: "AMBLETEUSE",
    value: "62025",
    type: "common",
  },
  {
    label: "RONCQ",
    value: "59508",
    type: "common",
  },
  {
    label: "NEUVILLE-EN-FERRAIN",
    value: "59426",
    type: "common",
  },
  {
    label: "HALLUIN",
    value: "59279",
    type: "common",
  },
  {
    label: "WERVICQ-SUD",
    value: "59656",
    type: "common",
  },
  {
    label: "COMINES",
    value: "59152",
    type: "common",
  },
  {
    label: "BOUSBECQUE",
    value: "59098",
    type: "common",
  },
  {
    label: "SAINT-JANS-CAPPEL",
    value: "59535",
    type: "common",
  },
  {
    label: "METEREN",
    value: "59401",
    type: "common",
  },
  {
    label: "GODEWAERSVELDE",
    value: "59262",
    type: "common",
  },
  {
    label: "FLETRE",
    value: "59237",
    type: "common",
  },
  {
    label: "BERTHEN",
    value: "59073",
    type: "common",
  },
  {
    label: "TERDEGHEM",
    value: "59587",
    type: "common",
  },
  {
    label: "SAINT-SYLVESTRE-CAPPEL",
    value: "59546",
    type: "common",
  },
  {
    label: "EECKE",
    value: "59189",
    type: "common",
  },
  {
    label: "CAESTRE",
    value: "59120",
    type: "common",
  },
  {
    label: "SAINTE-MARIE-CAPPEL",
    value: "59536",
    type: "common",
  },
  {
    label: "OXELAERE",
    value: "59454",
    type: "common",
  },
  {
    label: "HONDEGHEM",
    value: "59308",
    type: "common",
  },
  {
    label: "CASSEL",
    value: "59135",
    type: "common",
  },
  {
    label: "ZUYTPEENE",
    value: "59669",
    type: "common",
  },
  {
    label: "STAPLE",
    value: "59577",
    type: "common",
  },
  {
    label: "NOORDPEENE",
    value: "59436",
    type: "common",
  },
  {
    label: "BAVINCHOVE",
    value: "59054",
    type: "common",
  },
  {
    label: "CLAIRMARAIS",
    value: "62225",
    type: "common",
  },
  {
    label: "ARQUES",
    value: "62040",
    type: "common",
  },
  {
    label: "NIEURLET",
    value: "59433",
    type: "common",
  },
  {
    label: "TILQUES",
    value: "62819",
    type: "common",
  },
  {
    label: "SERQUES",
    value: "62792",
    type: "common",
  },
  {
    label: "SALPERWICK",
    value: "62772",
    type: "common",
  },
  {
    label: "SAINT-OMER",
    value: "62765",
    type: "common",
  },
  {
    label: "SAINT-MARTIN-LEZ-TATINGHEM",
    value: "62757",
    type: "common",
  },
  {
    label: "ZUDAUSQUES",
    value: "62905",
    type: "common",
  },
  {
    label: "NORT-LEULINGHEM",
    value: "62622",
    type: "common",
  },
  {
    label: "MOULLE",
    value: "62595",
    type: "common",
  },
  {
    label: "MORINGHEM",
    value: "62592",
    type: "common",
  },
  {
    label: "MENTQUE-NORTBECOURT",
    value: "62567",
    type: "common",
  },
  {
    label: "HOULLE",
    value: "62458",
    type: "common",
  },
  {
    label: "BOISDINGHEM",
    value: "62149",
    type: "common",
  },
  {
    label: "TOURNEHEM-SUR-LA-HEM",
    value: "62827",
    type: "common",
  },
  {
    label: "QUERCAMPS",
    value: "62675",
    type: "common",
  },
  {
    label: "JOURNY",
    value: "62478",
    type: "common",
  },
  {
    label: "BONNINGUES-LES-ARDRES",
    value: "62155",
    type: "common",
  },
  {
    label: "SURQUES",
    value: "62803",
    type: "common",
  },
  {
    label: "REBERGUES",
    value: "62692",
    type: "common",
  },
  {
    label: "LICQUES",
    value: "62506",
    type: "common",
  },
  {
    label: "HOCQUINGHEN",
    value: "62455",
    type: "common",
  },
  {
    label: "CLERQUES",
    value: "62228",
    type: "common",
  },
  {
    label: "BAINGHEN",
    value: "62076",
    type: "common",
  },
  {
    label: "AUDREHEM",
    value: "62055",
    type: "common",
  },
  {
    label: "SANGHEN",
    value: "62775",
    type: "common",
  },
  {
    label: "HERBINGHEN",
    value: "62432",
    type: "common",
  },
  {
    label: "HARDINGHEN",
    value: "62412",
    type: "common",
  },
  {
    label: "COLEMBERT",
    value: "62230",
    type: "common",
  },
  {
    label: "BOURSIN",
    value: "62167",
    type: "common",
  },
  {
    label: "ALEMBON",
    value: "62020",
    type: "common",
  },
  {
    label: "WIERRE-EFFROY",
    value: "62889",
    type: "common",
  },
  {
    label: "LE WAST",
    value: "62880",
    type: "common",
  },
  {
    label: "RETY",
    value: "62705",
    type: "common",
  },
  {
    label: "CONTEVILLE-LES-BOULOGNE",
    value: "62237",
    type: "common",
  },
  {
    label: "BELLE-ET-HOULLEFORT",
    value: "62105",
    type: "common",
  },
  {
    label: "WIMILLE",
    value: "62894",
    type: "common",
  },
  {
    label: "WACQUINGHEN",
    value: "62867",
    type: "common",
  },
  {
    label: "PITTEFAUX",
    value: "62658",
    type: "common",
  },
  {
    label: "PERNES-LES-BOULOGNE",
    value: "62653",
    type: "common",
  },
  {
    label: "OFFRETHUN",
    value: "62636",
    type: "common",
  },
  {
    label: "MANINGHEN-HENNE",
    value: "62546",
    type: "common",
  },
  {
    label: "BEUVREQUEN",
    value: "62125",
    type: "common",
  },
  {
    label: "WIMEREUX",
    value: "62893",
    type: "common",
  },
  {
    label: "WATTRELOS",
    value: "59650",
    type: "common",
  },
  {
    label: "TOURCOING",
    value: "59599",
    type: "common",
  },
  {
    label: "ROUBAIX",
    value: "59512",
    type: "common",
  },
  {
    label: "MOUVAUX",
    value: "59421",
    type: "common",
  },
  {
    label: "WAMBRECHIES",
    value: "59636",
    type: "common",
  },
  {
    label: "QUESNOY-SUR-DEULE",
    value: "59482",
    type: "common",
  },
  {
    label: "LINSELLES",
    value: "59352",
    type: "common",
  },
  {
    label: "BONDUES",
    value: "59090",
    type: "common",
  },
  {
    label: "WARNETON",
    value: "59643",
    type: "common",
  },
  {
    label: "VERLINGHEM",
    value: "59611",
    type: "common",
  },
  {
    label: "HOUPLINES",
    value: "59317",
    type: "common",
  },
  {
    label: "FRELINGHIEN",
    value: "59252",
    type: "common",
  },
  {
    label: "DEULEMONT",
    value: "59173",
    type: "common",
  },
  {
    label: "NIEPPE",
    value: "59431",
    type: "common",
  },
  {
    label: "ARMENTIERES",
    value: "59017",
    type: "common",
  },
  {
    label: "STEENWERCK",
    value: "59581",
    type: "common",
  },
  {
    label: "BAILLEUL",
    value: "59043",
    type: "common",
  },
  {
    label: "MERRIS",
    value: "59399",
    type: "common",
  },
  {
    label: "VIEUX-BERQUIN",
    value: "59615",
    type: "common",
  },
  {
    label: "STRAZEELE",
    value: "59582",
    type: "common",
  },
  {
    label: "PRADELLES",
    value: "59469",
    type: "common",
  },
  {
    label: "MORBECQUE",
    value: "59416",
    type: "common",
  },
  {
    label: "BORRE",
    value: "59091",
    type: "common",
  },
  {
    label: "WALLON-CAPPEL",
    value: "59634",
    type: "common",
  },
  {
    label: "HAZEBROUCK",
    value: "59295",
    type: "common",
  },
  {
    label: "SERCUS",
    value: "59568",
    type: "common",
  },
  {
    label: "RENESCURE",
    value: "59497",
    type: "common",
  },
  {
    label: "LYNDE",
    value: "59366",
    type: "common",
  },
  {
    label: "EBBLINGHEM",
    value: "59184",
    type: "common",
  },
  {
    label: "WARDRECQUES",
    value: "62875",
    type: "common",
  },
  {
    label: "RACQUINGHEM",
    value: "62684",
    type: "common",
  },
  {
    label: "HEURINGHEM",
    value: "62452",
    type: "common",
  },
  {
    label: "CAMPAGNE-LES-WARDRECQUES",
    value: "62205",
    type: "common",
  },
  {
    label: "BLENDECQUES",
    value: "62139",
    type: "common",
  },
  {
    label: "WIZERNES",
    value: "62902",
    type: "common",
  },
  {
    label: "WISQUES",
    value: "62898",
    type: "common",
  },
  {
    label: "LONGUENESSE",
    value: "62525",
    type: "common",
  },
  {
    label: "HELFAUT",
    value: "62423",
    type: "common",
  },
  {
    label: "HALLINES",
    value: "62403",
    type: "common",
  },
  {
    label: "ESQUERDES",
    value: "62309",
    type: "common",
  },
  {
    label: "SETQUES",
    value: "62794",
    type: "common",
  },
  {
    label: "QUELMES",
    value: "62674",
    type: "common",
  },
  {
    label: "LUMBRES",
    value: "62534",
    type: "common",
  },
  {
    label: "LEULINGHEM",
    value: "62504",
    type: "common",
  },
  {
    label: "ELNES",
    value: "62292",
    type: "common",
  },
  {
    label: "SENINGHEM",
    value: "62788",
    type: "common",
  },
  {
    label: "COULOMBY",
    value: "62245",
    type: "common",
  },
  {
    label: "BOUVELINGHEM",
    value: "62169",
    type: "common",
  },
  {
    label: "BAYENGHEM-LES-SENINGHEM",
    value: "62088",
    type: "common",
  },
  {
    label: "ALQUINES",
    value: "62024",
    type: "common",
  },
  {
    label: "AFFRINGUES",
    value: "62010",
    type: "common",
  },
  {
    label: "ACQUIN-WESTBECOURT",
    value: "62008",
    type: "common",
  },
  {
    label: "QUESQUES",
    value: "62678",
    type: "common",
  },
  {
    label: "HAUT-LOQUIN",
    value: "62419",
    type: "common",
  },
  {
    label: "ESCŒUILLES",
    value: "62308",
    type: "common",
  },
  {
    label: "SELLES",
    value: "62786",
    type: "common",
  },
  {
    label: "NABRINGHEN",
    value: "62599",
    type: "common",
  },
  {
    label: "LONGUEVILLE",
    value: "62526",
    type: "common",
  },
  {
    label: "HENNEVEUX",
    value: "62429",
    type: "common",
  },
  {
    label: "BRUNEMBERT",
    value: "62179",
    type: "common",
  },
  {
    label: "BOURNONVILLE",
    value: "62165",
    type: "common",
  },
  {
    label: "ALINCTHUN",
    value: "62022",
    type: "common",
  },
  {
    label: "LA CAPELLE-LES-BOULOGNE",
    value: "62908",
    type: "common",
  },
  {
    label: "WIRWIGNES",
    value: "62896",
    type: "common",
  },
  {
    label: "CREMAREST",
    value: "62255",
    type: "common",
  },
  {
    label: "BELLEBRUNE",
    value: "62104",
    type: "common",
  },
  {
    label: "SAINT-MARTIN-BOULOGNE",
    value: "62758",
    type: "common",
  },
  {
    label: "ECHINGHEN",
    value: "62281",
    type: "common",
  },
  {
    label: "BAINCTHUN",
    value: "62075",
    type: "common",
  },
  {
    label: "LE PORTEL",
    value: "62667",
    type: "common",
  },
  {
    label: "SAINT-LEONARD",
    value: "62755",
    type: "common",
  },
  {
    label: "OUTREAU",
    value: "62643",
    type: "common",
  },
  {
    label: "BOULOGNE-SUR-MER",
    value: "62160",
    type: "common",
  },
  {
    label: "WILLEMS",
    value: "59660",
    type: "common",
  },
  {
    label: "TOUFFLERS",
    value: "59598",
    type: "common",
  },
  {
    label: "SAILLY-LEZ-LANNOY",
    value: "59522",
    type: "common",
  },
  {
    label: "LYS-LEZ-LANNOY",
    value: "59367",
    type: "common",
  },
  {
    label: "LEERS",
    value: "59339",
    type: "common",
  },
  {
    label: "LANNOY",
    value: "59332",
    type: "common",
  },
  {
    label: "VILLENEUVE-D'ASCQ",
    value: "59009",
    type: "common",
  },
  {
    label: "WASQUEHAL",
    value: "59646",
    type: "common",
  },
  {
    label: "MONS-EN-BARŒUL",
    value: "59410",
    type: "common",
  },
  {
    label: "HEM",
    value: "59299",
    type: "common",
  },
  {
    label: "FOREST-SUR-MARQUE",
    value: "59247",
    type: "common",
  },
  {
    label: "CROIX",
    value: "59163",
    type: "common",
  },
  {
    label: "LILLE",
    value: "59350",
    type: "common",
  },
  {
    label: "SAINT-ANDRE-LEZ-LILLE",
    value: "59527",
    type: "common",
  },
  {
    label: "MARQUETTE-LEZ-LILLE",
    value: "59386",
    type: "common",
  },
  {
    label: "MARCQ-EN-BARŒUL",
    value: "59378",
    type: "common",
  },
  {
    label: "LA MADELEINE",
    value: "59368",
    type: "common",
  },
  {
    label: "LAMBERSART",
    value: "59328",
    type: "common",
  },
  {
    label: "ENNETIERES-EN-WEPPES",
    value: "59196",
    type: "common",
  },
  {
    label: "SEQUEDIN",
    value: "59566",
    type: "common",
  },
  {
    label: "ENGLOS",
    value: "59195",
    type: "common",
  },
  {
    label: "PREMESQUES",
    value: "59470",
    type: "common",
  },
  {
    label: "PERENCHIES",
    value: "59457",
    type: "common",
  },
  {
    label: "LOMPRET",
    value: "59356",
    type: "common",
  },
  {
    label: "CAPINGHEM",
    value: "59128",
    type: "common",
  },
  {
    label: "RADINGHEM-EN-WEPPES",
    value: "59487",
    type: "common",
  },
  {
    label: "FLEURBAIX",
    value: "62338",
    type: "common",
  },
  {
    label: "ERQUINGHEM-LYS",
    value: "59202",
    type: "common",
  },
  {
    label: "LA CHAPELLE-D'ARMENTIERES",
    value: "59143",
    type: "common",
  },
  {
    label: "BOIS-GRENIER",
    value: "59088",
    type: "common",
  },
  {
    label: "SAILLY-SUR-LA-LYS",
    value: "62736",
    type: "common",
  },
  {
    label: "LA GORGUE",
    value: "59268",
    type: "common",
  },
  {
    label: "NEUF-BERQUIN",
    value: "59423",
    type: "common",
  },
  {
    label: "MERVILLE",
    value: "59400",
    type: "common",
  },
  {
    label: "ESTAIRES",
    value: "59212",
    type: "common",
  },
  {
    label: "LE DOULIEU",
    value: "59180",
    type: "common",
  },
  {
    label: "HAVERSKERQUE",
    value: "59293",
    type: "common",
  },
  {
    label: "THIENNES",
    value: "59590",
    type: "common",
  },
  {
    label: "STEENBECQUE",
    value: "59578",
    type: "common",
  },
  {
    label: "WITTES",
    value: "62901",
    type: "common",
  },
  {
    label: "AIRE-SUR-LA-LYS",
    value: "62014",
    type: "common",
  },
  {
    label: "BOESEGHEM",
    value: "59087",
    type: "common",
  },
  {
    label: "BLARINGHEM",
    value: "59084",
    type: "common",
  },
  {
    label: "ROQUETOIRE",
    value: "62721",
    type: "common",
  },
  {
    label: "SAINT-AUGUSTIN",
    value: "62691",
    type: "common",
  },
  {
    label: "QUIESTEDE",
    value: "62681",
    type: "common",
  },
  {
    label: "MAMETZ",
    value: "62543",
    type: "common",
  },
  {
    label: "ECQUES",
    value: "62288",
    type: "common",
  },
  {
    label: "THEROUANNE",
    value: "62811",
    type: "common",
  },
  {
    label: "REMILLY-WIRQUIN",
    value: "62702",
    type: "common",
  },
  {
    label: "PIHEM",
    value: "62656",
    type: "common",
  },
  {
    label: "BELLINGHEM",
    value: "62471",
    type: "common",
  },
  {
    label: "DOHEM",
    value: "62271",
    type: "common",
  },
  {
    label: "CLETY",
    value: "62229",
    type: "common",
  },
  {
    label: "WAVRANS-SUR-L'AA",
    value: "62882",
    type: "common",
  },
  {
    label: "OUVE-WIRQUIN",
    value: "62644",
    type: "common",
  },
  {
    label: "MERCK-SAINT-LIEVIN",
    value: "62569",
    type: "common",
  },
  {
    label: "AVROULT",
    value: "62067",
    type: "common",
  },
  {
    label: "WISMES",
    value: "62897",
    type: "common",
  },
  {
    label: "VAUDRINGHEM",
    value: "62837",
    type: "common",
  },
  {
    label: "NIELLES-LES-BLEQUIN",
    value: "62613",
    type: "common",
  },
  {
    label: "VIEIL-MOUTIER",
    value: "62853",
    type: "common",
  },
  {
    label: "SENLECQUES",
    value: "62789",
    type: "common",
  },
  {
    label: "LOTTINGHEN",
    value: "62530",
    type: "common",
  },
  {
    label: "LEDINGHEM",
    value: "62495",
    type: "common",
  },
  {
    label: "BLEQUIN",
    value: "62140",
    type: "common",
  },
  {
    label: "BECOURT",
    value: "62102",
    type: "common",
  },
  {
    label: "SAINT-MARTIN-CHOQUEL",
    value: "62759",
    type: "common",
  },
  {
    label: "MENNEVILLE",
    value: "62566",
    type: "common",
  },
  {
    label: "LONGFOSSE",
    value: "62524",
    type: "common",
  },
  {
    label: "DESVRES",
    value: "62268",
    type: "common",
  },
  {
    label: "COURSET",
    value: "62251",
    type: "common",
  },
  {
    label: "WIERRE-AU-BOIS",
    value: "62888",
    type: "common",
  },
  {
    label: "SAMER",
    value: "62773",
    type: "common",
  },
  {
    label: "QUESTRECQUES",
    value: "62679",
    type: "common",
  },
  {
    label: "CARLY",
    value: "62214",
    type: "common",
  },
  {
    label: "VERLINCTHUN",
    value: "62845",
    type: "common",
  },
  {
    label: "ISQUES",
    value: "62474",
    type: "common",
  },
  {
    label: "HESDIN-L'ABBE",
    value: "62448",
    type: "common",
  },
  {
    label: "HESDIGNEUL-LES-BOULOGNE",
    value: "62446",
    type: "common",
  },
  {
    label: "CONDETTE",
    value: "62235",
    type: "common",
  },
  {
    label: "SAINT-ETIENNE-AU-MONT",
    value: "62746",
    type: "common",
  },
  {
    label: "EQUIHEN-PLAGE",
    value: "62300",
    type: "common",
  },
  {
    label: "WANNEHAIN",
    value: "59638",
    type: "common",
  },
  {
    label: "GRUSON",
    value: "59275",
    type: "common",
  },
  {
    label: "CYSOING",
    value: "59168",
    type: "common",
  },
  {
    label: "CHERENG",
    value: "59146",
    type: "common",
  },
  {
    label: "CAMPHIN-EN-PEVELE",
    value: "59124",
    type: "common",
  },
  {
    label: "BOURGHELLES",
    value: "59096",
    type: "common",
  },
  {
    label: "BAISIEUX",
    value: "59044",
    type: "common",
  },
  {
    label: "LESQUIN",
    value: "59343",
    type: "common",
  },
  {
    label: "TRESSIN",
    value: "59602",
    type: "common",
  },
  {
    label: "SAINGHIN-EN-MELANTOIS",
    value: "59523",
    type: "common",
  },
  {
    label: "PERONNE-EN-MELANTOIS",
    value: "59458",
    type: "common",
  },
  {
    label: "LEZENNES",
    value: "59346",
    type: "common",
  },
  {
    label: "FRETIN",
    value: "59256",
    type: "common",
  },
  {
    label: "BOUVINES",
    value: "59106",
    type: "common",
  },
  {
    label: "ANSTAING",
    value: "59013",
    type: "common",
  },
  {
    label: "WATTIGNIES",
    value: "59648",
    type: "common",
  },
  {
    label: "VENDEVILLE",
    value: "59609",
    type: "common",
  },
  {
    label: "TEMPLEMARS",
    value: "59585",
    type: "common",
  },
  {
    label: "NOYELLES-LES-SECLIN",
    value: "59437",
    type: "common",
  },
  {
    label: "RONCHIN",
    value: "59507",
    type: "common",
  },
  {
    label: "LOOS",
    value: "59360",
    type: "common",
  },
  {
    label: "FACHES-THUMESNIL",
    value: "59220",
    type: "common",
  },
  {
    label: "HALLENNES-LEZ-HAUBOURDIN",
    value: "59278",
    type: "common",
  },
  {
    label: "ESCOBECQUES",
    value: "59208",
    type: "common",
  },
  {
    label: "ERQUINGHEM-LE-SEC",
    value: "59201",
    type: "common",
  },
  {
    label: "HOUPLIN-ANCOISNE",
    value: "59316",
    type: "common",
  },
  {
    label: "WAVRIN",
    value: "59653",
    type: "common",
  },
  {
    label: "SANTES",
    value: "59553",
    type: "common",
  },
  {
    label: "HAUBOURDIN",
    value: "59286",
    type: "common",
  },
  {
    label: "EMMERIN",
    value: "59193",
    type: "common",
  },
  {
    label: "LE MAISNIL",
    value: "59371",
    type: "common",
  },
  {
    label: "BEAUCAMPS-LIGNY",
    value: "59056",
    type: "common",
  },
  {
    label: "WICRES",
    value: "59658",
    type: "common",
  },
  {
    label: "HERLIES",
    value: "59303",
    type: "common",
  },
  {
    label: "FROMELLES",
    value: "59257",
    type: "common",
  },
  {
    label: "FOURNES-EN-WEPPES",
    value: "59250",
    type: "common",
  },
  {
    label: "NEUVE-CHAPELLE",
    value: "62606",
    type: "common",
  },
  {
    label: "LORGIES",
    value: "62529",
    type: "common",
  },
  {
    label: "LAVENTIE",
    value: "62491",
    type: "common",
  },
  {
    label: "RICHEBOURG",
    value: "62706",
    type: "common",
  },
  {
    label: "AUBERS",
    value: "59025",
    type: "common",
  },
  {
    label: "LESTREM",
    value: "62502",
    type: "common",
  },
  {
    label: "VIEILLE-CHAPELLE",
    value: "62851",
    type: "common",
  },
  {
    label: "LA COUTURE",
    value: "62252",
    type: "common",
  },
  {
    label: "LOCON",
    value: "62520",
    type: "common",
  },
  {
    label: "MONT-BERNANCHON",
    value: "62584",
    type: "common",
  },
  {
    label: "CALONNE-SUR-LA-LYS",
    value: "62195",
    type: "common",
  },
  {
    label: "SAINT-FLORIS",
    value: "62747",
    type: "common",
  },
  {
    label: "ROBECQ",
    value: "62713",
    type: "common",
  },
  {
    label: "GONNEHEM",
    value: "62376",
    type: "common",
  },
  {
    label: "SAINT-VENANT",
    value: "62770",
    type: "common",
  },
  {
    label: "GUARBECQUE",
    value: "62391",
    type: "common",
  },
  {
    label: "BUSNES",
    value: "62190",
    type: "common",
  },
  {
    label: "HAM-EN-ARTOIS",
    value: "62407",
    type: "common",
  },
  {
    label: "ISBERGUES",
    value: "62473",
    type: "common",
  },
  {
    label: "WITTERNESSE",
    value: "62900",
    type: "common",
  },
  {
    label: "NORRENT-FONTES",
    value: "62620",
    type: "common",
  },
  {
    label: "QUERNES",
    value: "62676",
    type: "common",
  },
  {
    label: "LINGHEM",
    value: "62517",
    type: "common",
  },
  {
    label: "ROMBLY",
    value: "62720",
    type: "common",
  },
  {
    label: "MAZINGHEM",
    value: "62564",
    type: "common",
  },
  {
    label: "LAMBRES",
    value: "62486",
    type: "common",
  },
  {
    label: "BOURECQ",
    value: "62162",
    type: "common",
  },
  {
    label: "RELY",
    value: "62701",
    type: "common",
  },
  {
    label: "LIETTRES",
    value: "62509",
    type: "common",
  },
  {
    label: "ESTREE-BLANCHE",
    value: "62313",
    type: "common",
  },
  {
    label: "BLESSY",
    value: "62141",
    type: "common",
  },
  {
    label: "ENQUIN-LEZ-GUINEGATTE",
    value: "62295",
    type: "common",
  },
  {
    label: "ERNY-SAINT-JULIEN",
    value: "62304",
    type: "common",
  },
  {
    label: "DELETTES",
    value: "62265",
    type: "common",
  },
  {
    label: "COYECQUES",
    value: "62254",
    type: "common",
  },
  {
    label: "BOMY",
    value: "62153",
    type: "common",
  },
  {
    label: "SAINT-MARTIN-D'HARDINGHEM",
    value: "62760",
    type: "common",
  },
  {
    label: "RECLINGHEM",
    value: "62696",
    type: "common",
  },
  {
    label: "FAUQUEMBERGUES",
    value: "62325",
    type: "common",
  },
  {
    label: "DENNEBRŒUCQ",
    value: "62267",
    type: "common",
  },
  {
    label: "AUDINCTHUN",
    value: "62053",
    type: "common",
  },
  {
    label: "THIEMBRONNE",
    value: "62812",
    type: "common",
  },
  {
    label: "RUMILLY",
    value: "62729",
    type: "common",
  },
  {
    label: "RENTY",
    value: "62704",
    type: "common",
  },
  {
    label: "CAMPAGNE-LES-BOULONNAIS",
    value: "62202",
    type: "common",
  },
  {
    label: "AIX-EN-ERGNY",
    value: "62017",
    type: "common",
  },
  {
    label: "WICQUINGHEM",
    value: "62886",
    type: "common",
  },
  {
    label: "ERGNY",
    value: "62302",
    type: "common",
  },
  {
    label: "BOURTHES",
    value: "62168",
    type: "common",
  },
  {
    label: "ZOTEUX",
    value: "62903",
    type: "common",
  },
  {
    label: "PREURES",
    value: "62670",
    type: "common",
  },
  {
    label: "ENQUIN-SUR-BAILLONS",
    value: "62296",
    type: "common",
  },
  {
    label: "DOUDEAUVILLE",
    value: "62273",
    type: "common",
  },
  {
    label: "BEZINGHEM",
    value: "62127",
    type: "common",
  },
  {
    label: "TINGRY",
    value: "62821",
    type: "common",
  },
  {
    label: "PARENTY",
    value: "62648",
    type: "common",
  },
  {
    label: "LACRES",
    value: "62483",
    type: "common",
  },
  {
    label: "HUBERSENT",
    value: "62460",
    type: "common",
  },
  {
    label: "WIDEHEM",
    value: "62887",
    type: "common",
  },
  {
    label: "NESLES",
    value: "62603",
    type: "common",
  },
  {
    label: "HALINGHEN",
    value: "62402",
    type: "common",
  },
  {
    label: "FRENCQ",
    value: "62354",
    type: "common",
  },
  {
    label: "NEUFCHATEL-HARDELOT",
    value: "62604",
    type: "common",
  },
  {
    label: "DANNES",
    value: "62264",
    type: "common",
  },
  {
    label: "FLINES-LES-MORTAGNE",
    value: "59238",
    type: "common",
  },
  {
    label: "MOUCHIN",
    value: "59419",
    type: "common",
  },
  {
    label: "NOMAIN",
    value: "59435",
    type: "common",
  },
  {
    label: "GENECH",
    value: "59258",
    type: "common",
  },
  {
    label: "COBRIEUX",
    value: "59150",
    type: "common",
  },
  {
    label: "BACHY",
    value: "59042",
    type: "common",
  },
  {
    label: "TEMPLEUVE-EN-PEVELE",
    value: "59586",
    type: "common",
  },
  {
    label: "PONT-A-MARCQ",
    value: "59466",
    type: "common",
  },
  {
    label: "LOUVIL",
    value: "59364",
    type: "common",
  },
  {
    label: "ENNEVELIN",
    value: "59197",
    type: "common",
  },
  {
    label: "SECLIN",
    value: "59560",
    type: "common",
  },
  {
    label: "PHALEMPIN",
    value: "59462",
    type: "common",
  },
  {
    label: "AVELIN",
    value: "59034",
    type: "common",
  },
  {
    label: "ATTICHES",
    value: "59022",
    type: "common",
  },
  {
    label: "DON",
    value: "59670",
    type: "common",
  },
  {
    label: "HERRIN",
    value: "59304",
    type: "common",
  },
  {
    label: "GONDECOURT",
    value: "59266",
    type: "common",
  },
  {
    label: "CARNIN",
    value: "59133",
    type: "common",
  },
  {
    label: "CHEMY",
    value: "59145",
    type: "common",
  },
  {
    label: "CAMPHIN-EN-CAREMBAULT",
    value: "59123",
    type: "common",
  },
  {
    label: "ANNŒULLIN",
    value: "59011",
    type: "common",
  },
  {
    label: "ALLENNES-LES-MARAIS",
    value: "59005",
    type: "common",
  },
  {
    label: "DOUVRIN",
    value: "62276",
    type: "common",
  },
  {
    label: "BILLY-BERCLAU",
    value: "62132",
    type: "common",
  },
  {
    label: "PROVIN",
    value: "59477",
    type: "common",
  },
  {
    label: "BAUVIN",
    value: "59052",
    type: "common",
  },
  {
    label: "SALOME",
    value: "59550",
    type: "common",
  },
  {
    label: "SAINGHIN-EN-WEPPES",
    value: "59524",
    type: "common",
  },
  {
    label: "MARQUILLIES",
    value: "59388",
    type: "common",
  },
  {
    label: "ILLIES",
    value: "59320",
    type: "common",
  },
  {
    label: "HANTAY",
    value: "59281",
    type: "common",
  },
  {
    label: "CUINCHY",
    value: "62262",
    type: "common",
  },
  {
    label: "CAMBRIN",
    value: "62200",
    type: "common",
  },
  {
    label: "VIOLAINES",
    value: "62863",
    type: "common",
  },
  {
    label: "GIVENCHY-LES-LA-BASSEE",
    value: "62373",
    type: "common",
  },
  {
    label: "LA BASSEE",
    value: "59051",
    type: "common",
  },
  {
    label: "BEUVRY",
    value: "62126",
    type: "common",
  },
  {
    label: "BETHUNE",
    value: "62119",
    type: "common",
  },
  {
    label: "FESTUBERT",
    value: "62330",
    type: "common",
  },
  {
    label: "ESSARS",
    value: "62310",
    type: "common",
  },
  {
    label: "LABEUVRIERE",
    value: "62479",
    type: "common",
  },
  {
    label: "FOUQUIERES-LES-BETHUNE",
    value: "62350",
    type: "common",
  },
  {
    label: "FOUQUEREUIL",
    value: "62349",
    type: "common",
  },
  {
    label: "VENDIN-LES-BETHUNE",
    value: "62841",
    type: "common",
  },
  {
    label: "OBLINGHEM",
    value: "62632",
    type: "common",
  },
  {
    label: "CHOCQUES",
    value: "62224",
    type: "common",
  },
  {
    label: "ANNEZIN",
    value: "62035",
    type: "common",
  },
  {
    label: "GOSNAY",
    value: "62377",
    type: "common",
  },
  {
    label: "HINGES",
    value: "62454",
    type: "common",
  },
  {
    label: "LAPUGNOY",
    value: "62489",
    type: "common",
  },
  {
    label: "ALLOUAGNE",
    value: "62023",
    type: "common",
  },
  {
    label: "LOZINGHEM",
    value: "62532",
    type: "common",
  },
  {
    label: "BURBURE",
    value: "62188",
    type: "common",
  },
  {
    label: "AUCHEL",
    value: "62048",
    type: "common",
  },
  {
    label: "LILLERS",
    value: "62516",
    type: "common",
  },
  {
    label: "LESPESSES",
    value: "62500",
    type: "common",
  },
  {
    label: "LIERES",
    value: "62508",
    type: "common",
  },
  {
    label: "ECQUEDECQUES",
    value: "62286",
    type: "common",
  },
  {
    label: "FERFAY",
    value: "62328",
    type: "common",
  },
  {
    label: "AMETTES",
    value: "62029",
    type: "common",
  },
  {
    label: "AMES",
    value: "62028",
    type: "common",
  },
  {
    label: "AUCHY-AU-BOIS",
    value: "62049",
    type: "common",
  },
  {
    label: "SAINT-HILAIRE-COTTES",
    value: "62750",
    type: "common",
  },
  {
    label: "NEDON",
    value: "62600",
    type: "common",
  },
  {
    label: "LIGNY-LES-AIRE",
    value: "62512",
    type: "common",
  },
  {
    label: "WESTREHEM",
    value: "62885",
    type: "common",
  },
  {
    label: "NEDONCHEL",
    value: "62601",
    type: "common",
  },
  {
    label: "FONTAINE-LES-HERMANS",
    value: "62344",
    type: "common",
  },
  {
    label: "FLECHIN",
    value: "62336",
    type: "common",
  },
  {
    label: "FEBVIN-PALFART",
    value: "62327",
    type: "common",
  },
  {
    label: "VINCLY",
    value: "62862",
    type: "common",
  },
  {
    label: "LISBOURG",
    value: "62519",
    type: "common",
  },
  {
    label: "LAIRES",
    value: "62485",
    type: "common",
  },
  {
    label: "HEZECQUES",
    value: "62453",
    type: "common",
  },
  {
    label: "BEAUMETZ-LES-AIRE",
    value: "62095",
    type: "common",
  },
  {
    label: "SENLIS",
    value: "62790",
    type: "common",
  },
  {
    label: "RADINGHEM",
    value: "62685",
    type: "common",
  },
  {
    label: "MENCAS",
    value: "62565",
    type: "common",
  },
  {
    label: "MATRINGHEM",
    value: "62562",
    type: "common",
  },
  {
    label: "LUGY",
    value: "62533",
    type: "common",
  },
  {
    label: "FRUGES",
    value: "62364",
    type: "common",
  },
  {
    label: "VERCHOCQ",
    value: "62844",
    type: "common",
  },
  {
    label: "HERLY",
    value: "62437",
    type: "common",
  },
  {
    label: "COUPELLE-VIEILLE",
    value: "62247",
    type: "common",
  },
  {
    label: "SAINT-MICHEL-SOUS-BOIS",
    value: "62762",
    type: "common",
  },
  {
    label: "RIMBOVAL",
    value: "62710",
    type: "common",
  },
  {
    label: "QUILEN",
    value: "62682",
    type: "common",
  },
  {
    label: "MANINGHEM",
    value: "62545",
    type: "common",
  },
  {
    label: "HUCQUELIERS",
    value: "62463",
    type: "common",
  },
  {
    label: "BIMONT",
    value: "62134",
    type: "common",
  },
  {
    label: "AVESNES",
    value: "62062",
    type: "common",
  },
  {
    label: "MONTCAVREL",
    value: "62585",
    type: "common",
  },
  {
    label: "CLENLEU",
    value: "62227",
    type: "common",
  },
  {
    label: "BEUSSENT",
    value: "62123",
    type: "common",
  },
  {
    label: "ALETTE",
    value: "62021",
    type: "common",
  },
  {
    label: "LONGVILLIERS",
    value: "62527",
    type: "common",
  },
  {
    label: "RECQUES-SUR-COURSE",
    value: "62698",
    type: "common",
  },
  {
    label: "MARESVILLE",
    value: "62554",
    type: "common",
  },
  {
    label: "INXENT",
    value: "62472",
    type: "common",
  },
  {
    label: "CORMONT",
    value: "62241",
    type: "common",
  },
  {
    label: "BREXENT-ENOCQ",
    value: "62176",
    type: "common",
  },
  {
    label: "BERNIEULLES",
    value: "62116",
    type: "common",
  },
  {
    label: "TUBERSENT",
    value: "62832",
    type: "common",
  },
  {
    label: "LEFAUX",
    value: "62496",
    type: "common",
  },
  {
    label: "ETAPLES",
    value: "62318",
    type: "common",
  },
  {
    label: "LE TOUQUET-PARIS-PLAGE",
    value: "62826",
    type: "common",
  },
  {
    label: "CAMIERS",
    value: "62201",
    type: "common",
  },
  {
    label: "SAINT-AYBERT",
    value: "59530",
    type: "common",
  },
  {
    label: "VIEUX-CONDE",
    value: "59616",
    type: "common",
  },
  {
    label: "THIVENCELLE",
    value: "59591",
    type: "common",
  },
  {
    label: "CONDE-SUR-L'ESCAUT",
    value: "59153",
    type: "common",
  },
  {
    label: "ODOMEZ",
    value: "59444",
    type: "common",
  },
  {
    label: "HERGNIES",
    value: "59301",
    type: "common",
  },
  {
    label: "CHATEAU-L'ABBAYE",
    value: "59144",
    type: "common",
  },
  {
    label: "BRUILLE-SAINT-AMAND",
    value: "59114",
    type: "common",
  },
  {
    label: "THUN-SAINT-AMAND",
    value: "59594",
    type: "common",
  },
  {
    label: "NIVELLE",
    value: "59434",
    type: "common",
  },
  {
    label: "MORTAGNE-DU-NORD",
    value: "59418",
    type: "common",
  },
  {
    label: "MAULDE",
    value: "59393",
    type: "common",
  },
  {
    label: "LECELLES",
    value: "59335",
    type: "common",
  },
  {
    label: "SARS-ET-ROSIERES",
    value: "59554",
    type: "common",
  },
  {
    label: "SAMEON",
    value: "59551",
    type: "common",
  },
  {
    label: "RUMEGIES",
    value: "59519",
    type: "common",
  },
  {
    label: "ROSULT",
    value: "59511",
    type: "common",
  },
  {
    label: "LANDAS",
    value: "59330",
    type: "common",
  },
  {
    label: "AIX-EN-PEVELE",
    value: "59004",
    type: "common",
  },
  {
    label: "ORCHIES",
    value: "59449",
    type: "common",
  },
  {
    label: "COUTICHES",
    value: "59158",
    type: "common",
  },
  {
    label: "BEUVRY-LA-FORET",
    value: "59080",
    type: "common",
  },
  {
    label: "AUCHY-LEZ-ORCHIES",
    value: "59029",
    type: "common",
  },
  {
    label: "MONS-EN-PEVELE",
    value: "59411",
    type: "common",
  },
  {
    label: "MERIGNIES",
    value: "59398",
    type: "common",
  },
  {
    label: "FAUMONT",
    value: "59222",
    type: "common",
  },
  {
    label: "CAPPELLE-EN-PEVELE",
    value: "59129",
    type: "common",
  },
  {
    label: "BERSEE",
    value: "59071",
    type: "common",
  },
  {
    label: "WAHAGNIES",
    value: "59630",
    type: "common",
  },
  {
    label: "TOURMIGNIES",
    value: "59600",
    type: "common",
  },
  {
    label: "THUMERIES",
    value: "59592",
    type: "common",
  },
  {
    label: "OSTRICOURT",
    value: "59452",
    type: "common",
  },
  {
    label: "LA NEUVILLE",
    value: "59427",
    type: "common",
  },
  {
    label: "MONCHEAUX",
    value: "59408",
    type: "common",
  },
  {
    label: "LIBERCOURT",
    value: "62907",
    type: "common",
  },
  {
    label: "OIGNIES",
    value: "62637",
    type: "common",
  },
  {
    label: "COURRIERES",
    value: "62250",
    type: "common",
  },
  {
    label: "CARVIN",
    value: "62215",
    type: "common",
  },
  {
    label: "WINGLES",
    value: "62895",
    type: "common",
  },
  {
    label: "VENDIN-LE-VIEIL",
    value: "62842",
    type: "common",
  },
  {
    label: "PONT-A-VENDIN",
    value: "62666",
    type: "common",
  },
  {
    label: "MEURCHIN",
    value: "62573",
    type: "common",
  },
  {
    label: "HARNES",
    value: "62413",
    type: "common",
  },
  {
    label: "ESTEVELLES",
    value: "62311",
    type: "common",
  },
  {
    label: "BENIFONTAINE",
    value: "62107",
    type: "common",
  },
  {
    label: "ANNAY",
    value: "62033",
    type: "common",
  },
  {
    label: "AUCHY-LES-MINES",
    value: "62051",
    type: "common",
  },
  {
    label: "NOYELLES-LES-VERMELLES",
    value: "62626",
    type: "common",
  },
  {
    label: "VERMELLES",
    value: "62846",
    type: "common",
  },
  {
    label: "HAISNES",
    value: "62401",
    type: "common",
  },
  {
    label: "LOOS-EN-GOHELLE",
    value: "62528",
    type: "common",
  },
  {
    label: "HULLUCH",
    value: "62464",
    type: "common",
  },
  {
    label: "GRENAY",
    value: "62386",
    type: "common",
  },
  {
    label: "VERQUIN",
    value: "62848",
    type: "common",
  },
  {
    label: "ANNEQUIN",
    value: "62034",
    type: "common",
  },
  {
    label: "NŒUX-LES-MINES",
    value: "62617",
    type: "common",
  },
  {
    label: "SAILLY-LABOURSE",
    value: "62735",
    type: "common",
  },
  {
    label: "LABOURSE",
    value: "62480",
    type: "common",
  },
  {
    label: "SAINS-EN-GOHELLE",
    value: "62737",
    type: "common",
  },
  {
    label: "MAZINGARBE",
    value: "62563",
    type: "common",
  },
  {
    label: "BRUAY-LA-BUISSIERE",
    value: "62178",
    type: "common",
  },
  {
    label: "DROUVIN-LE-MARAIS",
    value: "62278",
    type: "common",
  },
  {
    label: "HESDIGNEUL-LES-BETHUNE",
    value: "62445",
    type: "common",
  },
  {
    label: "HOUCHIN",
    value: "62456",
    type: "common",
  },
  {
    label: "VAUDRICOURT",
    value: "62836",
    type: "common",
  },
  {
    label: "RUITZ",
    value: "62727",
    type: "common",
  },
  {
    label: "HAILLICOURT",
    value: "62400",
    type: "common",
  },
  {
    label: "BARLIN",
    value: "62083",
    type: "common",
  },
  {
    label: "OURTON",
    value: "62642",
    type: "common",
  },
  {
    label: "HOUDAIN",
    value: "62457",
    type: "common",
  },
  {
    label: "CALONNE-RICOUART",
    value: "62194",
    type: "common",
  },
  {
    label: "MARLES-LES-MINES",
    value: "62555",
    type: "common",
  },
  {
    label: "DIVION",
    value: "62270",
    type: "common",
  },
  {
    label: "CAUCHY-A-LA-TOUR",
    value: "62217",
    type: "common",
  },
  {
    label: "CAMBLAIN-CHATELAIN",
    value: "62197",
    type: "common",
  },
  {
    label: "SACHIN",
    value: "62732",
    type: "common",
  },
  {
    label: "PRESSY",
    value: "62669",
    type: "common",
  },
  {
    label: "PERNES",
    value: "62652",
    type: "common",
  },
  {
    label: "MAREST",
    value: "62553",
    type: "common",
  },
  {
    label: "FLORINGHEM",
    value: "62340",
    type: "common",
  },
  {
    label: "BAILLEUL-LES-PERNES",
    value: "62071",
    type: "common",
  },
  {
    label: "AUMERVAL",
    value: "62058",
    type: "common",
  },
  {
    label: "TANGRY",
    value: "62805",
    type: "common",
  },
  {
    label: "SAINS-LES-PERNES",
    value: "62740",
    type: "common",
  },
  {
    label: "HEUCHIN",
    value: "62451",
    type: "common",
  },
  {
    label: "FONTAINE-LES-BOULANS",
    value: "62342",
    type: "common",
  },
  {
    label: "FIEFS",
    value: "62333",
    type: "common",
  },
  {
    label: "EPS",
    value: "62299",
    type: "common",
  },
  {
    label: "BOYAVAL",
    value: "62171",
    type: "common",
  },
  {
    label: "VERCHIN",
    value: "62843",
    type: "common",
  },
  {
    label: "TENEUR",
    value: "62808",
    type: "common",
  },
  {
    label: "PREDEFIN",
    value: "62668",
    type: "common",
  },
  {
    label: "EQUIRRE",
    value: "62301",
    type: "common",
  },
  {
    label: "CREPY",
    value: "62256",
    type: "common",
  },
  {
    label: "BERGUENEUSE",
    value: "62109",
    type: "common",
  },
  {
    label: "ANVIN",
    value: "62036",
    type: "common",
  },
  {
    label: "TRAMECOURT",
    value: "62828",
    type: "common",
  },
  {
    label: "RUISSEAUVILLE",
    value: "62726",
    type: "common",
  },
  {
    label: "PLANQUES",
    value: "62659",
    type: "common",
  },
  {
    label: "MAISONCELLE",
    value: "62541",
    type: "common",
  },
  {
    label: "COUPELLE-NEUVE",
    value: "62246",
    type: "common",
  },
  {
    label: "CANLERS",
    value: "62209",
    type: "common",
  },
  {
    label: "AZINCOURT",
    value: "62069",
    type: "common",
  },
  {
    label: "AVONDANCE",
    value: "62066",
    type: "common",
  },
  {
    label: "AMBRICOURT",
    value: "62026",
    type: "common",
  },
  {
    label: "TORCY",
    value: "62823",
    type: "common",
  },
  {
    label: "SAINS-LES-FRESSIN",
    value: "62738",
    type: "common",
  },
  {
    label: "ROYON",
    value: "62725",
    type: "common",
  },
  {
    label: "CREQUY",
    value: "62257",
    type: "common",
  },
  {
    label: "SAINT-DENŒUX",
    value: "62745",
    type: "common",
  },
  {
    label: "LEBIEZ",
    value: "62492",
    type: "common",
  },
  {
    label: "HUMBERT",
    value: "62466",
    type: "common",
  },
  {
    label: "HESMOND",
    value: "62449",
    type: "common",
  },
  {
    label: "EMBRY",
    value: "62293",
    type: "common",
  },
  {
    label: "BOUBERS-LES-HESMOND",
    value: "62157",
    type: "common",
  },
  {
    label: "SEMPY",
    value: "62787",
    type: "common",
  },
  {
    label: "MARLES-SUR-CANCHE",
    value: "62556",
    type: "common",
  },
  {
    label: "MARENLA",
    value: "62551",
    type: "common",
  },
  {
    label: "MARANT",
    value: "62547",
    type: "common",
  },
  {
    label: "AIX-EN-ISSART",
    value: "62018",
    type: "common",
  },
  {
    label: "SORRUS",
    value: "62799",
    type: "common",
  },
  {
    label: "NEUVILLE-SOUS-MONTREUIL",
    value: "62610",
    type: "common",
  },
  {
    label: "MONTREUIL",
    value: "62588",
    type: "common",
  },
  {
    label: "LA MADELAINE-SOUS-MONTREUIL",
    value: "62535",
    type: "common",
  },
  {
    label: "ESTREELLES",
    value: "62315",
    type: "common",
  },
  {
    label: "ESTREE",
    value: "62312",
    type: "common",
  },
  {
    label: "BEUTIN",
    value: "62124",
    type: "common",
  },
  {
    label: "ATTIN",
    value: "62044",
    type: "common",
  },
  {
    label: "SAINT-JOSSE",
    value: "62752",
    type: "common",
  },
  {
    label: "SAINT-AUBIN",
    value: "62742",
    type: "common",
  },
  {
    label: "LA CALOTTERIE",
    value: "62196",
    type: "common",
  },
  {
    label: "CUCQ",
    value: "62261",
    type: "common",
  },
  {
    label: "MERLIMONT",
    value: "62571",
    type: "common",
  },
  {
    label: "QUIEVRECHAIN",
    value: "59484",
    type: "common",
  },
  {
    label: "VICQ",
    value: "59613",
    type: "common",
  },
  {
    label: "QUAROUBLE",
    value: "59479",
    type: "common",
  },
  {
    label: "ONNAING",
    value: "59447",
    type: "common",
  },
  {
    label: "FRESNES-SUR-ESCAUT",
    value: "59253",
    type: "common",
  },
  {
    label: "CRESPIN",
    value: "59160",
    type: "common",
  },
  {
    label: "RAISMES",
    value: "59491",
    type: "common",
  },
  {
    label: "ESCAUTPONT",
    value: "59207",
    type: "common",
  },
  {
    label: "BRUAY-SUR-L'ESCAUT",
    value: "59112",
    type: "common",
  },
  {
    label: "BEUVRAGES",
    value: "59079",
    type: "common",
  },
  {
    label: "SAINT-AMAND-LES-EAUX",
    value: "59526",
    type: "common",
  },
  {
    label: "MILLONFOSSE",
    value: "59403",
    type: "common",
  },
  {
    label: "HASNON",
    value: "59284",
    type: "common",
  },
  {
    label: "WARLAING",
    value: "59642",
    type: "common",
  },
  {
    label: "WANDIGNIES-HAMAGE",
    value: "59637",
    type: "common",
  },
  {
    label: "TILLOY-LEZ-MARCHIENNES",
    value: "59596",
    type: "common",
  },
  {
    label: "BRILLON",
    value: "59109",
    type: "common",
  },
  {
    label: "BOUSIGNIES",
    value: "59100",
    type: "common",
  },
  {
    label: "VRED",
    value: "59629",
    type: "common",
  },
  {
    label: "RIEULAY",
    value: "59501",
    type: "common",
  },
  {
    label: "MARCHIENNES",
    value: "59375",
    type: "common",
  },
  {
    label: "BOUVIGNIES",
    value: "59105",
    type: "common",
  },
  {
    label: "ROOST-WARENDIN",
    value: "59509",
    type: "common",
  },
  {
    label: "RAIMBEAUCOURT",
    value: "59489",
    type: "common",
  },
  {
    label: "RACHES",
    value: "59486",
    type: "common",
  },
  {
    label: "FLINES-LEZ-RACHES",
    value: "59239",
    type: "common",
  },
  {
    label: "ANHIERS",
    value: "59007",
    type: "common",
  },
  {
    label: "LAUWIN-PLANQUE",
    value: "59334",
    type: "common",
  },
  {
    label: "FLERS-EN-ESCREBIEUX",
    value: "59234",
    type: "common",
  },
  {
    label: "AUBY",
    value: "59028",
    type: "common",
  },
  {
    label: "LEFOREST",
    value: "62497",
    type: "common",
  },
  {
    label: "EVIN-MALMAISON",
    value: "62321",
    type: "common",
  },
  {
    label: "COURCELLES-LES-LENS",
    value: "62249",
    type: "common",
  },
  {
    label: "NOYELLES-GODAULT",
    value: "62624",
    type: "common",
  },
  {
    label: "MONTIGNY-EN-GOHELLE",
    value: "62587",
    type: "common",
  },
  {
    label: "HENIN-BEAUMONT",
    value: "62427",
    type: "common",
  },
  {
    label: "DROCOURT",
    value: "62277",
    type: "common",
  },
  {
    label: "DOURGES",
    value: "62274",
    type: "common",
  },
  {
    label: "NOYELLES-SOUS-LENS",
    value: "62628",
    type: "common",
  },
  {
    label: "FOUQUIERES-LES-LENS",
    value: "62351",
    type: "common",
  },
  {
    label: "BILLY-MONTIGNY",
    value: "62133",
    type: "common",
  },
  {
    label: "SALLAUMINES",
    value: "62771",
    type: "common",
  },
  {
    label: "ROUVROY",
    value: "62724",
    type: "common",
  },
  {
    label: "MERICOURT",
    value: "62570",
    type: "common",
  },
  {
    label: "LOISON-SOUS-LENS",
    value: "62523",
    type: "common",
  },
  {
    label: "SOUCHEZ",
    value: "62801",
    type: "common",
  },
  {
    label: "LIEVIN",
    value: "62510",
    type: "common",
  },
  {
    label: "LENS",
    value: "62498",
    type: "common",
  },
  {
    label: "GIVENCHY-EN-GOHELLE",
    value: "62371",
    type: "common",
  },
  {
    label: "ELEU-DIT-LEAUWETTE",
    value: "62291",
    type: "common",
  },
  {
    label: "AVION",
    value: "62065",
    type: "common",
  },
  {
    label: "ANGRES",
    value: "62032",
    type: "common",
  },
  {
    label: "HERSIN-COUPIGNY",
    value: "62443",
    type: "common",
  },
  {
    label: "GOUY-SERVINS",
    value: "62380",
    type: "common",
  },
  {
    label: "BOUVIGNY-BOYEFFLES",
    value: "62170",
    type: "common",
  },
  {
    label: "AIX-NOULETTE",
    value: "62019",
    type: "common",
  },
  {
    label: "BULLY-LES-MINES",
    value: "62186",
    type: "common",
  },
  {
    label: "ABLAIN-SAINT-NAZAIRE",
    value: "62001",
    type: "common",
  },
  {
    label: "REBREUVE-RANCHICOURT",
    value: "62693",
    type: "common",
  },
  {
    label: "FRESNICOURT-LE-DOLMEN",
    value: "62356",
    type: "common",
  },
  {
    label: "MAISNIL-LES-RUITZ",
    value: "62540",
    type: "common",
  },
  {
    label: "HERMIN",
    value: "62441",
    type: "common",
  },
  {
    label: "ESTREE-CAUCHY",
    value: "62314",
    type: "common",
  },
  {
    label: "GAUCHIN-LEGAL",
    value: "62366",
    type: "common",
  },
  {
    label: "SERVINS",
    value: "62793",
    type: "common",
  },
  {
    label: "BEUGIN",
    value: "62120",
    type: "common",
  },
  {
    label: "MAGNICOURT-EN-COMTE",
    value: "62536",
    type: "common",
  },
  {
    label: "FREVILLERS",
    value: "62362",
    type: "common",
  },
  {
    label: "LA COMTE",
    value: "62232",
    type: "common",
  },
  {
    label: "BAJUS",
    value: "62077",
    type: "common",
  },
  {
    label: "BRIAS",
    value: "62180",
    type: "common",
  },
  {
    label: "VALHUON",
    value: "62835",
    type: "common",
  },
  {
    label: "LA THIEULOYE",
    value: "62813",
    type: "common",
  },
  {
    label: "OSTREVILLE",
    value: "62641",
    type: "common",
  },
  {
    label: "MONCHY-BRETON",
    value: "62580",
    type: "common",
  },
  {
    label: "DIEVAL",
    value: "62269",
    type: "common",
  },
  {
    label: "BOURS",
    value: "62166",
    type: "common",
  },
  {
    label: "WAVRANS-SUR-TERNOISE",
    value: "62883",
    type: "common",
  },
  {
    label: "TROISVAUX",
    value: "62831",
    type: "common",
  },
  {
    label: "MONCHY-CAYEUX",
    value: "62581",
    type: "common",
  },
  {
    label: "HUCLIER",
    value: "62462",
    type: "common",
  },
  {
    label: "HESTRUS",
    value: "62450",
    type: "common",
  },
  {
    label: "HERNICOURT",
    value: "62442",
    type: "common",
  },
  {
    label: "GAUCHIN-VERLOINGT",
    value: "62367",
    type: "common",
  },
  {
    label: "CONTEVILLE-EN-TERNOIS",
    value: "62238",
    type: "common",
  },
  {
    label: "TILLY-CAPELLE",
    value: "62818",
    type: "common",
  },
  {
    label: "PIERREMONT",
    value: "62655",
    type: "common",
  },
  {
    label: "HUMERŒUILLE",
    value: "62467",
    type: "common",
  },
  {
    label: "FLEURY",
    value: "62339",
    type: "common",
  },
  {
    label: "ERIN",
    value: "62303",
    type: "common",
  },
  {
    label: "ECLIMEUX",
    value: "62282",
    type: "common",
  },
  {
    label: "BERMICOURT",
    value: "62114",
    type: "common",
  },
  {
    label: "ROLLANCOURT",
    value: "62719",
    type: "common",
  },
  {
    label: "BLINGEL",
    value: "62142",
    type: "common",
  },
  {
    label: "BLANGY-SUR-TERNOISE",
    value: "62138",
    type: "common",
  },
  {
    label: "BEALENCOURT",
    value: "62090",
    type: "common",
  },
  {
    label: "AUCHY-LES-HESDIN",
    value: "62050",
    type: "common",
  },
  {
    label: "WAMIN",
    value: "62872",
    type: "common",
  },
  {
    label: "WAMBERCOURT",
    value: "62871",
    type: "common",
  },
  {
    label: "LA LOGE",
    value: "62521",
    type: "common",
  },
  {
    label: "HUBY-SAINT-LEU",
    value: "62461",
    type: "common",
  },
  {
    label: "FRESSIN",
    value: "62359",
    type: "common",
  },
  {
    label: "CAVRON-SAINT-MARTIN",
    value: "62220",
    type: "common",
  },
  {
    label: "OFFIN",
    value: "62635",
    type: "common",
  },
  {
    label: "MARESQUEL-ECQUEMICOURT",
    value: "62552",
    type: "common",
  },
  {
    label: "LOISON-SUR-CREQUOISE",
    value: "62522",
    type: "common",
  },
  {
    label: "CONTES",
    value: "62236",
    type: "common",
  },
  {
    label: "BEAURAINVILLE",
    value: "62100",
    type: "common",
  },
  {
    label: "LESPINOY",
    value: "62501",
    type: "common",
  },
  {
    label: "CAMPAGNE-LES-HESDIN",
    value: "62204",
    type: "common",
  },
  {
    label: "BUIRE-LE-SEC",
    value: "62183",
    type: "common",
  },
  {
    label: "BRIMEUX",
    value: "62177",
    type: "common",
  },
  {
    label: "WAILLY-BEAUCAMP",
    value: "62870",
    type: "common",
  },
  {
    label: "ECUIRES",
    value: "62289",
    type: "common",
  },
  {
    label: "CAMPIGNEULLES-LES-PETITES",
    value: "62207",
    type: "common",
  },
  {
    label: "BOISJEAN",
    value: "62150",
    type: "common",
  },
  {
    label: "BEAUMERIE-SAINT-MARTIN",
    value: "62094",
    type: "common",
  },
  {
    label: "VERTON",
    value: "62849",
    type: "common",
  },
  {
    label: "RANG-DU-FLIERS",
    value: "62688",
    type: "common",
  },
  {
    label: "AIRON-SAINT-VAAST",
    value: "62016",
    type: "common",
  },
  {
    label: "AIRON-NOTRE-DAME",
    value: "62015",
    type: "common",
  },
  {
    label: "CAMPIGNEULLES-LES-GRANDES",
    value: "62206",
    type: "common",
  },
  {
    label: "BERCK",
    value: "62108",
    type: "common",
  },
  {
    label: "VILLERS-SIRE-NICOLE",
    value: "59627",
    type: "common",
  },
  {
    label: "GOGNIES-CHAUSSEE",
    value: "59264",
    type: "common",
  },
  {
    label: "BETTIGNIES",
    value: "59076",
    type: "common",
  },
  {
    label: "BERSILLIES",
    value: "59072",
    type: "common",
  },
  {
    label: "TAISNIERES-SUR-HON",
    value: "59584",
    type: "common",
  },
  {
    label: "HOUDAIN-LEZ-BAVAY",
    value: "59315",
    type: "common",
  },
  {
    label: "HON-HERGIES",
    value: "59310",
    type: "common",
  },
  {
    label: "GUSSIGNIES",
    value: "59277",
    type: "common",
  },
  {
    label: "BELLIGNIES",
    value: "59065",
    type: "common",
  },
  {
    label: "ETH",
    value: "59217",
    type: "common",
  },
  {
    label: "SEBOURG",
    value: "59559",
    type: "common",
  },
  {
    label: "SAULTAIN",
    value: "59557",
    type: "common",
  },
  {
    label: "SAINT-SAULVE",
    value: "59544",
    type: "common",
  },
  {
    label: "ROMBIES-ET-MARCHIPONT",
    value: "59505",
    type: "common",
  },
  {
    label: "ESTREUX",
    value: "59215",
    type: "common",
  },
  {
    label: "CURGIES",
    value: "59166",
    type: "common",
  },
  {
    label: "VALENCIENNES",
    value: "59606",
    type: "common",
  },
  {
    label: "TRITH-SAINT-LEGER",
    value: "59603",
    type: "common",
  },
  {
    label: "LA SENTINELLE",
    value: "59564",
    type: "common",
  },
  {
    label: "PETITE-FORET",
    value: "59459",
    type: "common",
  },
  {
    label: "MARLY",
    value: "59383",
    type: "common",
  },
  {
    label: "ANZIN",
    value: "59014",
    type: "common",
  },
  {
    label: "WAVRECHAIN-SOUS-DENAIN",
    value: "59651",
    type: "common",
  },
  {
    label: "WALLERS",
    value: "59632",
    type: "common",
  },
  {
    label: "ROUVIGNIES",
    value: "59515",
    type: "common",
  },
  {
    label: "OISY",
    value: "59446",
    type: "common",
  },
  {
    label: "HERIN",
    value: "59302",
    type: "common",
  },
  {
    label: "HAVELUY",
    value: "59292",
    type: "common",
  },
  {
    label: "DENAIN",
    value: "59172",
    type: "common",
  },
  {
    label: "BELLAING",
    value: "59064",
    type: "common",
  },
  {
    label: "AUBRY-DU-HAINAUT",
    value: "59027",
    type: "common",
  },
  {
    label: "HORNAING",
    value: "59314",
    type: "common",
  },
  {
    label: "HELESMES",
    value: "59297",
    type: "common",
  },
  {
    label: "FENAIN",
    value: "59227",
    type: "common",
  },
  {
    label: "ESCAUDAIN",
    value: "59205",
    type: "common",
  },
  {
    label: "ERRE",
    value: "59203",
    type: "common",
  },
  {
    label: "SOMAIN",
    value: "59574",
    type: "common",
  },
  {
    label: "PECQUENCOURT",
    value: "59456",
    type: "common",
  },
  {
    label: "MASNY",
    value: "59390",
    type: "common",
  },
  {
    label: "ECAILLON",
    value: "59185",
    type: "common",
  },
  {
    label: "BRUILLE-LEZ-MARCHIENNES",
    value: "59113",
    type: "common",
  },
  {
    label: "AUBERCHICOURT",
    value: "59024",
    type: "common",
  },
  {
    label: "ANICHE",
    value: "59008",
    type: "common",
  },
  {
    label: "WAZIERS",
    value: "59654",
    type: "common",
  },
  {
    label: "SIN-LE-NOBLE",
    value: "59569",
    type: "common",
  },
  {
    label: "MONTIGNY-EN-OSTREVENT",
    value: "59414",
    type: "common",
  },
  {
    label: "LOFFRE",
    value: "59354",
    type: "common",
  },
  {
    label: "LEWARDE",
    value: "59345",
    type: "common",
  },
  {
    label: "LALLAING",
    value: "59327",
    type: "common",
  },
  {
    label: "GUESNAIN",
    value: "59276",
    type: "common",
  },
  {
    label: "DECHY",
    value: "59170",
    type: "common",
  },
  {
    label: "LAMBRES-LEZ-DOUAI",
    value: "59329",
    type: "common",
  },
  {
    label: "ESQUERCHIN",
    value: "59211",
    type: "common",
  },
  {
    label: "CUINCY",
    value: "59165",
    type: "common",
  },
  {
    label: "COURCHELETTES",
    value: "59156",
    type: "common",
  },
  {
    label: "CORBEHEM",
    value: "62240",
    type: "common",
  },
  {
    label: "BREBIERES",
    value: "62173",
    type: "common",
  },
  {
    label: "FERIN",
    value: "59228",
    type: "common",
  },
  {
    label: "DOUAI",
    value: "59178",
    type: "common",
  },
  {
    label: "QUIERY-LA-MOTTE",
    value: "62680",
    type: "common",
  },
  {
    label: "IZEL-LES-EQUERCHIN",
    value: "62476",
    type: "common",
  },
  {
    label: "FRESNES-LES-MONTAUBAN",
    value: "62355",
    type: "common",
  },
  {
    label: "BOIS-BERNARD",
    value: "62148",
    type: "common",
  },
  {
    label: "WILLERVAL",
    value: "62892",
    type: "common",
  },
  {
    label: "OPPY",
    value: "62639",
    type: "common",
  },
  {
    label: "NEUVIREUIL",
    value: "62612",
    type: "common",
  },
  {
    label: "FRESNOY-EN-GOHELLE",
    value: "62358",
    type: "common",
  },
  {
    label: "FARBUS",
    value: "62324",
    type: "common",
  },
  {
    label: "BAILLEUL-SIR-BERTHOULT",
    value: "62073",
    type: "common",
  },
  {
    label: "ARLEUX-EN-GOHELLE",
    value: "62039",
    type: "common",
  },
  {
    label: "ACHEVILLE",
    value: "62003",
    type: "common",
  },
  {
    label: "ROCLINCOURT",
    value: "62714",
    type: "common",
  },
  {
    label: "ECURIE",
    value: "62290",
    type: "common",
  },
  {
    label: "VIMY",
    value: "62861",
    type: "common",
  },
  {
    label: "THELUS",
    value: "62810",
    type: "common",
  },
  {
    label: "NEUVILLE-SAINT-VAAST",
    value: "62609",
    type: "common",
  },
  {
    label: "MONT-SAINT-ELOI",
    value: "62589",
    type: "common",
  },
  {
    label: "MARŒUIL",
    value: "62557",
    type: "common",
  },
  {
    label: "ACQ",
    value: "62007",
    type: "common",
  },
  {
    label: "VILLERS-AU-BOIS",
    value: "62854",
    type: "common",
  },
  {
    label: "CARENCY",
    value: "62213",
    type: "common",
  },
  {
    label: "CAMBLAIN-L'ABBE",
    value: "62199",
    type: "common",
  },
  {
    label: "CAUCOURT",
    value: "62218",
    type: "common",
  },
  {
    label: "VILLERS-CHATEL",
    value: "62857",
    type: "common",
  },
  {
    label: "SAVY-BERLETTE",
    value: "62785",
    type: "common",
  },
  {
    label: "MINGOVAL",
    value: "62574",
    type: "common",
  },
  {
    label: "FREVIN-CAPELLE",
    value: "62363",
    type: "common",
  },
  {
    label: "CAPELLE-FERMONT",
    value: "62211",
    type: "common",
  },
  {
    label: "CAMBLIGNEUL",
    value: "62198",
    type: "common",
  },
  {
    label: "AUBIGNY-EN-ARTOIS",
    value: "62045",
    type: "common",
  },
  {
    label: "AGNIERES",
    value: "62012",
    type: "common",
  },
  {
    label: "VILLERS-BRULIN",
    value: "62856",
    type: "common",
  },
  {
    label: "TINCQUES",
    value: "62820",
    type: "common",
  },
  {
    label: "PENIN",
    value: "62651",
    type: "common",
  },
  {
    label: "CHELERS",
    value: "62221",
    type: "common",
  },
  {
    label: "BETHONSART",
    value: "62118",
    type: "common",
  },
  {
    label: "BERLES-MONCHEL",
    value: "62113",
    type: "common",
  },
  {
    label: "TERNAS",
    value: "62809",
    type: "common",
  },
  {
    label: "SAINT-MICHEL-SUR-TERNOISE",
    value: "62763",
    type: "common",
  },
  {
    label: "ROELLECOURT",
    value: "62717",
    type: "common",
  },
  {
    label: "NEUVILLE-AU-CORNET",
    value: "62607",
    type: "common",
  },
  {
    label: "MARQUAY",
    value: "62558",
    type: "common",
  },
  {
    label: "LIGNY-SAINT-FLOCHEL",
    value: "62514",
    type: "common",
  },
  {
    label: "FOUFFLIN-RICAMETZ",
    value: "62348",
    type: "common",
  },
  {
    label: "BAILLEUL-AUX-CORNAILLES",
    value: "62070",
    type: "common",
  },
  {
    label: "AVERDOINGT",
    value: "62061",
    type: "common",
  },
  {
    label: "SAINT-POL-SUR-TERNOISE",
    value: "62767",
    type: "common",
  },
  {
    label: "RAMECOURT",
    value: "62686",
    type: "common",
  },
  {
    label: "MAISNIL",
    value: "62539",
    type: "common",
  },
  {
    label: "HERLIN-LE-SEC",
    value: "62436",
    type: "common",
  },
  {
    label: "HERLINCOURT",
    value: "62435",
    type: "common",
  },
  {
    label: "HAUTECLOQUE",
    value: "62416",
    type: "common",
  },
  {
    label: "SIRACOURT",
    value: "62797",
    type: "common",
  },
  {
    label: "ŒUF-EN-TERNOIS",
    value: "62633",
    type: "common",
  },
  {
    label: "LINZEUX",
    value: "62518",
    type: "common",
  },
  {
    label: "HUMIERES",
    value: "62468",
    type: "common",
  },
  {
    label: "HERICOURT",
    value: "62433",
    type: "common",
  },
  {
    label: "GUINECOURT",
    value: "62396",
    type: "common",
  },
  {
    label: "CROIX-EN-TERNOIS",
    value: "62260",
    type: "common",
  },
  {
    label: "CROISETTE",
    value: "62258",
    type: "common",
  },
  {
    label: "BEAUVOIS",
    value: "62101",
    type: "common",
  },
  {
    label: "WILLEMAN",
    value: "62890",
    type: "common",
  },
  {
    label: "WAIL",
    value: "62868",
    type: "common",
  },
  {
    label: "VIEIL-HESDIN",
    value: "62850",
    type: "common",
  },
  {
    label: "LE PARCQ",
    value: "62647",
    type: "common",
  },
  {
    label: "NOYELLES-LES-HUMIERES",
    value: "62625",
    type: "common",
  },
  {
    label: "NEULETTE",
    value: "62605",
    type: "common",
  },
  {
    label: "INCOURT",
    value: "62470",
    type: "common",
  },
  {
    label: "FRESNOY",
    value: "62357",
    type: "common",
  },
  {
    label: "SAINT-GEORGES",
    value: "62749",
    type: "common",
  },
  {
    label: "SAINTE-AUSTREBERTHE",
    value: "62743",
    type: "common",
  },
  {
    label: "MARCONNELLE",
    value: "62550",
    type: "common",
  },
  {
    label: "MARCONNE",
    value: "62549",
    type: "common",
  },
  {
    label: "HESDIN",
    value: "62447",
    type: "common",
  },
  {
    label: "GUISY",
    value: "62398",
    type: "common",
  },
  {
    label: "GRIGNY",
    value: "62388",
    type: "common",
  },
  {
    label: "CAPELLE-LES-HESDIN",
    value: "62212",
    type: "common",
  },
  {
    label: "BREVILLERS",
    value: "62175",
    type: "common",
  },
  {
    label: "TORTEFONTAINE",
    value: "62824",
    type: "common",
  },
  {
    label: "BOUIN-PLUMOISON",
    value: "62661",
    type: "common",
  },
  {
    label: "MOURIEZ",
    value: "62596",
    type: "common",
  },
  {
    label: "GOUY-SAINT-ANDRE",
    value: "62382",
    type: "common",
  },
  {
    label: "AUBIN-SAINT-VAAST",
    value: "62046",
    type: "common",
  },
  {
    label: "ARGOULES",
    value: "80025",
    type: "common",
  },
  {
    label: "SAULCHOY",
    value: "62783",
    type: "common",
  },
  {
    label: "SAINT-REMY-AU-BOIS",
    value: "62768",
    type: "common",
  },
  {
    label: "MAINTENAY",
    value: "62538",
    type: "common",
  },
  {
    label: "DOURIEZ",
    value: "62275",
    type: "common",
  },
  {
    label: "NAMPONT",
    value: "80580",
    type: "common",
  },
  {
    label: "ROUSSENT",
    value: "62723",
    type: "common",
  },
  {
    label: "NEMPONT-SAINT-FIRMIN",
    value: "62602",
    type: "common",
  },
  {
    label: "WABEN",
    value: "62866",
    type: "common",
  },
  {
    label: "TIGNY-NOYELLE",
    value: "62815",
    type: "common",
  },
  {
    label: "CONCHIL-LE-TEMPLE",
    value: "62233",
    type: "common",
  },
  {
    label: "COLLINE-BEAUMONT",
    value: "62231",
    type: "common",
  },
  {
    label: "LEPINE",
    value: "62499",
    type: "common",
  },
  {
    label: "GROFFLIERS",
    value: "62390",
    type: "common",
  },
  {
    label: "FORT-MAHON-PLAGE",
    value: "80333",
    type: "common",
  },
  {
    label: "BOUSIGNIES-SUR-ROC",
    value: "59101",
    type: "common",
  },
  {
    label: "VIEUX-RENG",
    value: "59618",
    type: "common",
  },
  {
    label: "RECQUIGNIES",
    value: "59495",
    type: "common",
  },
  {
    label: "MARPENT",
    value: "59385",
    type: "common",
  },
  {
    label: "JEUMONT",
    value: "59324",
    type: "common",
  },
  {
    label: "COLLERET",
    value: "59151",
    type: "common",
  },
  {
    label: "BOUSSOIS",
    value: "59104",
    type: "common",
  },
  {
    label: "ASSEVENT",
    value: "59021",
    type: "common",
  },
  {
    label: "ROUSIES",
    value: "59514",
    type: "common",
  },
  {
    label: "MAUBEUGE",
    value: "59392",
    type: "common",
  },
  {
    label: "MAIRIEUX",
    value: "59370",
    type: "common",
  },
  {
    label: "ELESMES",
    value: "59190",
    type: "common",
  },
  {
    label: "NEUF-MESNIL",
    value: "59424",
    type: "common",
  },
  {
    label: "LA LONGUEVILLE",
    value: "59357",
    type: "common",
  },
  {
    label: "FEIGNIES",
    value: "59225",
    type: "common",
  },
  {
    label: "SAINT-WAAST",
    value: "59548",
    type: "common",
  },
  {
    label: "OBIES",
    value: "59441",
    type: "common",
  },
  {
    label: "MECQUIGNIES",
    value: "59396",
    type: "common",
  },
  {
    label: "BETTRECHIES",
    value: "59077",
    type: "common",
  },
  {
    label: "BERMERIES",
    value: "59070",
    type: "common",
  },
  {
    label: "BAVAY",
    value: "59053",
    type: "common",
  },
  {
    label: "AUDIGNIES",
    value: "59031",
    type: "common",
  },
  {
    label: "WARGNIES-LE-PETIT",
    value: "59640",
    type: "common",
  },
  {
    label: "WARGNIES-LE-GRAND",
    value: "59639",
    type: "common",
  },
  {
    label: "PREUX-AU-SART",
    value: "59473",
    type: "common",
  },
  {
    label: "GOMMEGNIES",
    value: "59265",
    type: "common",
  },
  {
    label: "FRASNOY",
    value: "59251",
    type: "common",
  },
  {
    label: "LA FLAMENGRIE",
    value: "59232",
    type: "common",
  },
  {
    label: "BRY",
    value: "59116",
    type: "common",
  },
  {
    label: "AMFROIPRET",
    value: "59006",
    type: "common",
  },
  {
    label: "VILLERS-POL",
    value: "59626",
    type: "common",
  },
  {
    label: "SEPMERIES",
    value: "59565",
    type: "common",
  },
  {
    label: "PRESEAU",
    value: "59471",
    type: "common",
  },
  {
    label: "ORSINVAL",
    value: "59451",
    type: "common",
  },
  {
    label: "MARESCHES",
    value: "59381",
    type: "common",
  },
  {
    label: "JENLAIN",
    value: "59323",
    type: "common",
  },
  {
    label: "QUERENAING",
    value: "59480",
    type: "common",
  },
  {
    label: "MAING",
    value: "59369",
    type: "common",
  },
  {
    label: "FAMARS",
    value: "59221",
    type: "common",
  },
  {
    label: "AULNOY-LEZ-VALENCIENNES",
    value: "59032",
    type: "common",
  },
  {
    label: "ARTRES",
    value: "59019",
    type: "common",
  },
  {
    label: "THIANT",
    value: "59589",
    type: "common",
  },
  {
    label: "PROUVY",
    value: "59475",
    type: "common",
  },
  {
    label: "NOYELLES-SUR-SELLE",
    value: "59440",
    type: "common",
  },
  {
    label: "MONCHAUX-SUR-ECAILLON",
    value: "59407",
    type: "common",
  },
  {
    label: "HAULCHIN",
    value: "59288",
    type: "common",
  },
  {
    label: "DOUCHY-LES-MINES",
    value: "59179",
    type: "common",
  },
  {
    label: "RŒULX",
    value: "59504",
    type: "common",
  },
  {
    label: "NEUVILLE-SUR-ESCAUT",
    value: "59429",
    type: "common",
  },
  {
    label: "MASTAING",
    value: "59391",
    type: "common",
  },
  {
    label: "LOURCHES",
    value: "59361",
    type: "common",
  },
  {
    label: "LIEU-SAINT-AMAND",
    value: "59348",
    type: "common",
  },
  {
    label: "BOUCHAIN",
    value: "59092",
    type: "common",
  },
  {
    label: "ABSCON",
    value: "59002",
    type: "common",
  },
  {
    label: "WASNES-AU-BAC",
    value: "59645",
    type: "common",
  },
  {
    label: "MONCHECOURT",
    value: "59409",
    type: "common",
  },
  {
    label: "MARQUETTE-EN-OSTREVANT",
    value: "59387",
    type: "common",
  },
  {
    label: "MARCQ-EN-OSTREVENT",
    value: "59379",
    type: "common",
  },
  {
    label: "FRESSAIN",
    value: "59254",
    type: "common",
  },
  {
    label: "EMERCHICOURT",
    value: "59192",
    type: "common",
  },
  {
    label: "VILLERS-AU-TERTRE",
    value: "59620",
    type: "common",
  },
  {
    label: "ROUCOURT",
    value: "59513",
    type: "common",
  },
  {
    label: "ERCHIN",
    value: "59199",
    type: "common",
  },
  {
    label: "CANTIN",
    value: "59126",
    type: "common",
  },
  {
    label: "BUGNICOURT",
    value: "59117",
    type: "common",
  },
  {
    label: "BRUNEMONT",
    value: "59115",
    type: "common",
  },
  {
    label: "ARLEUX",
    value: "59015",
    type: "common",
  },
  {
    label: "TORTEQUESNE",
    value: "62825",
    type: "common",
  },
  {
    label: "NOYELLES-SOUS-BELLONNE",
    value: "62627",
    type: "common",
  },
  {
    label: "GOUY-SOUS-BELLONNE",
    value: "62383",
    type: "common",
  },
  {
    label: "BELLONNE",
    value: "62106",
    type: "common",
  },
  {
    label: "LECLUSE",
    value: "59336",
    type: "common",
  },
  {
    label: "HAMEL",
    value: "59280",
    type: "common",
  },
  {
    label: "GŒULZIN",
    value: "59263",
    type: "common",
  },
  {
    label: "ESTREES",
    value: "59214",
    type: "common",
  },
  {
    label: "VITRY-EN-ARTOIS",
    value: "62865",
    type: "common",
  },
  {
    label: "SAILLY-EN-OSTREVENT",
    value: "62734",
    type: "common",
  },
  {
    label: "PLOUVAIN",
    value: "62660",
    type: "common",
  },
  {
    label: "PELVES",
    value: "62650",
    type: "common",
  },
  {
    label: "HAMBLAIN-LES-PRES",
    value: "62405",
    type: "common",
  },
  {
    label: "ETAING",
    value: "62317",
    type: "common",
  },
  {
    label: "BOIRY-NOTRE-DAME",
    value: "62145",
    type: "common",
  },
  {
    label: "BIACHE-SAINT-VAAST",
    value: "62128",
    type: "common",
  },
  {
    label: "RŒUX",
    value: "62718",
    type: "common",
  },
  {
    label: "GAVRELLE",
    value: "62369",
    type: "common",
  },
  {
    label: "FEUCHY",
    value: "62331",
    type: "common",
  },
  {
    label: "FAMPOUX",
    value: "62323",
    type: "common",
  },
  {
    label: "ATHIES",
    value: "62042",
    type: "common",
  },
  {
    label: "SAINTE-CATHERINE",
    value: "62744",
    type: "common",
  },
  {
    label: "ANZIN-SAINT-AUBIN",
    value: "62037",
    type: "common",
  },
  {
    label: "TILLOY-LES-MOFFLAINES",
    value: "62817",
    type: "common",
  },
  {
    label: "SAINT-NICOLAS",
    value: "62764",
    type: "common",
  },
  {
    label: "SAINT-LAURENT-BLANGY",
    value: "62753",
    type: "common",
  },
  {
    label: "ARRAS",
    value: "62041",
    type: "common",
  },
  {
    label: "ACHICOURT",
    value: "62004",
    type: "common",
  },
  {
    label: "ETRUN",
    value: "62320",
    type: "common",
  },
  {
    label: "DUISANS",
    value: "62279",
    type: "common",
  },
  {
    label: "DAINVILLE",
    value: "62263",
    type: "common",
  },
  {
    label: "WARLUS",
    value: "62878",
    type: "common",
  },
  {
    label: "HAUTE-AVESNES",
    value: "62415",
    type: "common",
  },
  {
    label: "GOUVES",
    value: "62378",
    type: "common",
  },
  {
    label: "AGNEZ-LES-DUISANS",
    value: "62011",
    type: "common",
  },
  {
    label: "TILLOY-LES-HERMAVILLE",
    value: "62816",
    type: "common",
  },
  {
    label: "NOYELLE-VION",
    value: "62630",
    type: "common",
  },
  {
    label: "NOYELLETTE",
    value: "62629",
    type: "common",
  },
  {
    label: "MONTENESCOURT",
    value: "62586",
    type: "common",
  },
  {
    label: "LATTRE-SAINT-QUENTIN",
    value: "62490",
    type: "common",
  },
  {
    label: "HERMAVILLE",
    value: "62438",
    type: "common",
  },
  {
    label: "HABARCQ",
    value: "62399",
    type: "common",
  },
  {
    label: "VILLERS-SIR-SIMON",
    value: "62860",
    type: "common",
  },
  {
    label: "MANIN",
    value: "62544",
    type: "common",
  },
  {
    label: "LIGNEREUIL",
    value: "62511",
    type: "common",
  },
  {
    label: "IZEL-LES-HAMEAU",
    value: "62477",
    type: "common",
  },
  {
    label: "GIVENCHY-LE-NOBLE",
    value: "62372",
    type: "common",
  },
  {
    label: "BEAUFORT-BLAVINCOURT",
    value: "62092",
    type: "common",
  },
  {
    label: "AMBRINES",
    value: "62027",
    type: "common",
  },
  {
    label: "SARS-LE-BOIS",
    value: "62778",
    type: "common",
  },
  {
    label: "MONTS-EN-TERNOIS",
    value: "62590",
    type: "common",
  },
  {
    label: "MONCHEAUX-LES-FREVENT",
    value: "62576",
    type: "common",
  },
  {
    label: "MAIZIERES",
    value: "62542",
    type: "common",
  },
  {
    label: "MAGNICOURT-SUR-CANCHE",
    value: "62537",
    type: "common",
  },
  {
    label: "LIENCOURT",
    value: "62507",
    type: "common",
  },
  {
    label: "HOUVIN-HOUVIGNEUL",
    value: "62459",
    type: "common",
  },
  {
    label: "GOUY-EN-TERNOIS",
    value: "62381",
    type: "common",
  },
  {
    label: "DENIER",
    value: "62266",
    type: "common",
  },
  {
    label: "CANETTEMONT",
    value: "62208",
    type: "common",
  },
  {
    label: "BERLENCOURT-LE-CAUROY",
    value: "62111",
    type: "common",
  },
  {
    label: "SIBIVILLE",
    value: "62795",
    type: "common",
  },
  {
    label: "SERICOURT",
    value: "62791",
    type: "common",
  },
  {
    label: "NUNCQ-HAUTECOTE",
    value: "62631",
    type: "common",
  },
  {
    label: "FRAMECOURT",
    value: "62352",
    type: "common",
  },
  {
    label: "ECOIVRES",
    value: "62283",
    type: "common",
  },
  {
    label: "BUNEVILLE",
    value: "62187",
    type: "common",
  },
  {
    label: "MONCHEL-SUR-CANCHE",
    value: "62577",
    type: "common",
  },
  {
    label: "LIGNY-SUR-CANCHE",
    value: "62513",
    type: "common",
  },
  {
    label: "FLERS",
    value: "62337",
    type: "common",
  },
  {
    label: "CONCHY-SUR-CANCHE",
    value: "62234",
    type: "common",
  },
  {
    label: "BOUBERS-SUR-CANCHE",
    value: "62158",
    type: "common",
  },
  {
    label: "BLANGERVAL-BLANGERMONT",
    value: "62137",
    type: "common",
  },
  {
    label: "AUBROMETZ",
    value: "62047",
    type: "common",
  },
  {
    label: "ROUGEFAY",
    value: "62722",
    type: "common",
  },
  {
    label: "QUŒUX-HAUT-MAINIL",
    value: "62683",
    type: "common",
  },
  {
    label: "HARAVESNES",
    value: "62411",
    type: "common",
  },
  {
    label: "GALAMETZ",
    value: "62365",
    type: "common",
  },
  {
    label: "FILLIEVRES",
    value: "62335",
    type: "common",
  },
  {
    label: "VACQUERIETTE-ERQUIERES",
    value: "62834",
    type: "common",
  },
  {
    label: "TOLLENT",
    value: "62822",
    type: "common",
  },
  {
    label: "REGNAUVILLE",
    value: "62700",
    type: "common",
  },
  {
    label: "LE QUESNOY-EN-ARTOIS",
    value: "62677",
    type: "common",
  },
  {
    label: "GENNES-IVERGNY",
    value: "62370",
    type: "common",
  },
  {
    label: "FONTAINE-L'ETALON",
    value: "62345",
    type: "common",
  },
  {
    label: "CHERIENNES",
    value: "62222",
    type: "common",
  },
  {
    label: "CAUMONT",
    value: "62219",
    type: "common",
  },
  {
    label: "DOMPIERRE-SUR-AUTHIE",
    value: "80248",
    type: "common",
  },
  {
    label: "RAYE-SUR-AUTHIE",
    value: "62690",
    type: "common",
  },
  {
    label: "LABROYE",
    value: "62481",
    type: "common",
  },
  {
    label: "GUIGNY",
    value: "62395",
    type: "common",
  },
  {
    label: "VIRONCHAUX",
    value: "80808",
    type: "common",
  },
  {
    label: "PONCHES-ESTRUVAL",
    value: "80631",
    type: "common",
  },
  {
    label: "MACHIEL",
    value: "80496",
    type: "common",
  },
  {
    label: "LIGESCOURT",
    value: "80477",
    type: "common",
  },
  {
    label: "DOMINOIS",
    value: "80244",
    type: "common",
  },
  {
    label: "VRON",
    value: "80815",
    type: "common",
  },
  {
    label: "REGNIERE-ECLUSE",
    value: "80665",
    type: "common",
  },
  {
    label: "MACHY",
    value: "80497",
    type: "common",
  },
  {
    label: "ARRY",
    value: "80030",
    type: "common",
  },
  {
    label: "VILLERS-SUR-AUTHIE",
    value: "80806",
    type: "common",
  },
  {
    label: "VERCOURT",
    value: "80787",
    type: "common",
  },
  {
    label: "RUE",
    value: "80688",
    type: "common",
  },
  {
    label: "SAINT-QUENTIN-EN-TOURMONT",
    value: "80713",
    type: "common",
  },
  {
    label: "QUEND",
    value: "80649",
    type: "common",
  },
  {
    label: "HESTRUD",
    value: "59306",
    type: "common",
  },
  {
    label: "COUSOLRE",
    value: "59157",
    type: "common",
  },
  {
    label: "BERELLES",
    value: "59066",
    type: "common",
  },
  {
    label: "SOLRINNES",
    value: "59573",
    type: "common",
  },
  {
    label: "QUIEVELON",
    value: "59483",
    type: "common",
  },
  {
    label: "OBRECHIES",
    value: "59442",
    type: "common",
  },
  {
    label: "FERRIERE-LA-PETITE",
    value: "59231",
    type: "common",
  },
  {
    label: "CHOISIES",
    value: "59147",
    type: "common",
  },
  {
    label: "CERFONTAINE",
    value: "59142",
    type: "common",
  },
  {
    label: "AIBES",
    value: "59003",
    type: "common",
  },
  {
    label: "LOUVROIL",
    value: "59365",
    type: "common",
  },
  {
    label: "LIMONT-FONTAINE",
    value: "59351",
    type: "common",
  },
  {
    label: "FERRIERE-LA-GRANDE",
    value: "59230",
    type: "common",
  },
  {
    label: "DAMOUSIES",
    value: "59169",
    type: "common",
  },
  {
    label: "BEAUFORT",
    value: "59058",
    type: "common",
  },
  {
    label: "VIEUX-MESNIL",
    value: "59617",
    type: "common",
  },
  {
    label: "SAINT-REMY-DU-NORD",
    value: "59543",
    type: "common",
  },
  {
    label: "PONT-SUR-SAMBRE",
    value: "59467",
    type: "common",
  },
  {
    label: "HAUTMONT",
    value: "59291",
    type: "common",
  },
  {
    label: "HARGNIES",
    value: "59283",
    type: "common",
  },
  {
    label: "BOUSSIERES-SUR-SAMBRE",
    value: "59103",
    type: "common",
  },
  {
    label: "BACHANT",
    value: "59041",
    type: "common",
  },
  {
    label: "AULNOYE-AYMERIES",
    value: "59033",
    type: "common",
  },
  {
    label: "BERLAIMONT",
    value: "59068",
    type: "common",
  },
  {
    label: "VILLEREAU",
    value: "59619",
    type: "common",
  },
  {
    label: "POTELLE",
    value: "59468",
    type: "common",
  },
  {
    label: "LOCQUIGNOL",
    value: "59353",
    type: "common",
  },
  {
    label: "JOLIMETZ",
    value: "59325",
    type: "common",
  },
  {
    label: "SALESCHES",
    value: "59549",
    type: "common",
  },
  {
    label: "RUESNES",
    value: "59518",
    type: "common",
  },
  {
    label: "LE QUESNOY",
    value: "59481",
    type: "common",
  },
  {
    label: "LOUVIGNIES-QUESNOY",
    value: "59363",
    type: "common",
  },
  {
    label: "GHISSIGNIES",
    value: "59259",
    type: "common",
  },
  {
    label: "CAPELLE",
    value: "59127",
    type: "common",
  },
  {
    label: "BEAUDIGNIES",
    value: "59057",
    type: "common",
  },
  {
    label: "VERTAIN",
    value: "59612",
    type: "common",
  },
  {
    label: "VENDEGIES-SUR-ECAILLON",
    value: "59608",
    type: "common",
  },
  {
    label: "SOMMAING",
    value: "59575",
    type: "common",
  },
  {
    label: "SAINT-MARTIN-SUR-ECAILLON",
    value: "59537",
    type: "common",
  },
  {
    label: "HAUSSY",
    value: "59289",
    type: "common",
  },
  {
    label: "ESCARMAIN",
    value: "59204",
    type: "common",
  },
  {
    label: "BERMERAIN",
    value: "59069",
    type: "common",
  },
  {
    label: "VILLERS-EN-CAUCHIES",
    value: "59622",
    type: "common",
  },
  {
    label: "VERCHAIN-MAUGRE",
    value: "59610",
    type: "common",
  },
  {
    label: "SAULZOIR",
    value: "59558",
    type: "common",
  },
  {
    label: "MONTRECOURT",
    value: "59415",
    type: "common",
  },
  {
    label: "HASPRES",
    value: "59285",
    type: "common",
  },
  {
    label: "ESTRUN",
    value: "59219",
    type: "common",
  },
  {
    label: "WAVRECHAIN-SOUS-FAULX",
    value: "59652",
    type: "common",
  },
  {
    label: "THUN-SAINT-MARTIN",
    value: "59595",
    type: "common",
  },
  {
    label: "THUN-L'EVEQUE",
    value: "59593",
    type: "common",
  },
  {
    label: "IWUY",
    value: "59322",
    type: "common",
  },
  {
    label: "HORDAIN",
    value: "59313",
    type: "common",
  },
  {
    label: "AVESNES-LE-SEC",
    value: "59038",
    type: "common",
  },
  {
    label: "SANCOURT",
    value: "59552",
    type: "common",
  },
  {
    label: "PAILLENCOURT",
    value: "59455",
    type: "common",
  },
  {
    label: "HEM-LENGLET",
    value: "59300",
    type: "common",
  },
  {
    label: "FRESSIES",
    value: "59255",
    type: "common",
  },
  {
    label: "FECHAIN",
    value: "59224",
    type: "common",
  },
  {
    label: "ESWARS",
    value: "59216",
    type: "common",
  },
  {
    label: "CUVILLERS",
    value: "59167",
    type: "common",
  },
  {
    label: "BLECOURT",
    value: "59085",
    type: "common",
  },
  {
    label: "BANTIGNY",
    value: "59048",
    type: "common",
  },
  {
    label: "ABANCOURT",
    value: "59001",
    type: "common",
  },
  {
    label: "SAUCHY-LESTREE",
    value: "62781",
    type: "common",
  },
  {
    label: "SAUCHY-CAUCHY",
    value: "62780",
    type: "common",
  },
  {
    label: "PALLUEL",
    value: "62646",
    type: "common",
  },
  {
    label: "OISY-LE-VERGER",
    value: "62638",
    type: "common",
  },
  {
    label: "EPINOY",
    value: "62298",
    type: "common",
  },
  {
    label: "AUBIGNY-AU-BAC",
    value: "59026",
    type: "common",
  },
  {
    label: "AUBENCHEUL-AU-BAC",
    value: "59023",
    type: "common",
  },
  {
    label: "VILLERS-LES-CAGNICOURT",
    value: "62858",
    type: "common",
  },
  {
    label: "SAUDEMONT",
    value: "62782",
    type: "common",
  },
  {
    label: "RUMAUCOURT",
    value: "62728",
    type: "common",
  },
  {
    label: "RECOURT",
    value: "62697",
    type: "common",
  },
  {
    label: "ECOURT-SAINT-QUENTIN",
    value: "62284",
    type: "common",
  },
  {
    label: "BARALLE",
    value: "62081",
    type: "common",
  },
  {
    label: "VIS-EN-ARTOIS",
    value: "62864",
    type: "common",
  },
  {
    label: "REMY",
    value: "62703",
    type: "common",
  },
  {
    label: "HENDECOURT-LES-CAGNICOURT",
    value: "62424",
    type: "common",
  },
  {
    label: "HAUCOURT",
    value: "62414",
    type: "common",
  },
  {
    label: "ETERPIGNY",
    value: "62319",
    type: "common",
  },
  {
    label: "DURY",
    value: "62280",
    type: "common",
  },
  {
    label: "CHERISY",
    value: "62223",
    type: "common",
  },
  {
    label: "WANCOURT",
    value: "62873",
    type: "common",
  },
  {
    label: "SAINT-MARTIN-SUR-COJEUL",
    value: "62761",
    type: "common",
  },
  {
    label: "MONCHY-LE-PREUX",
    value: "62582",
    type: "common",
  },
  {
    label: "HENIN-SUR-COJEUL",
    value: "62428",
    type: "common",
  },
  {
    label: "HENINEL",
    value: "62426",
    type: "common",
  },
  {
    label: "GUEMAPPE",
    value: "62392",
    type: "common",
  },
  {
    label: "FONTAINE-LES-CROISILLES",
    value: "62343",
    type: "common",
  },
  {
    label: "NEUVILLE-VITASSE",
    value: "62611",
    type: "common",
  },
  {
    label: "MERCATEL",
    value: "62568",
    type: "common",
  },
  {
    label: "FICHEUX",
    value: "62332",
    type: "common",
  },
  {
    label: "BOISLEUX-SAINT-MARC",
    value: "62152",
    type: "common",
  },
  {
    label: "BOIRY-BECQUERELLE",
    value: "62144",
    type: "common",
  },
  {
    label: "BEAURAINS",
    value: "62099",
    type: "common",
  },
  {
    label: "AGNY",
    value: "62013",
    type: "common",
  },
  {
    label: "WAILLY",
    value: "62869",
    type: "common",
  },
  {
    label: "SIMENCOURT",
    value: "62796",
    type: "common",
  },
  {
    label: "RIVIERE",
    value: "62712",
    type: "common",
  },
  {
    label: "BLAIRVILLE",
    value: "62135",
    type: "common",
  },
  {
    label: "BERNEVILLE",
    value: "62115",
    type: "common",
  },
  {
    label: "BEAUMETZ-LES-LOGES",
    value: "62097",
    type: "common",
  },
  {
    label: "BASSEUX",
    value: "62085",
    type: "common",
  },
  {
    label: "WANQUETIN",
    value: "62874",
    type: "common",
  },
  {
    label: "MONCHIET",
    value: "62578",
    type: "common",
  },
  {
    label: "HAUTEVILLE",
    value: "62418",
    type: "common",
  },
  {
    label: "GOUY-EN-ARTOIS",
    value: "62379",
    type: "common",
  },
  {
    label: "FOSSEUX",
    value: "62347",
    type: "common",
  },
  {
    label: "BAVINCOURT",
    value: "62086",
    type: "common",
  },
  {
    label: "BAILLEULVAL",
    value: "62074",
    type: "common",
  },
  {
    label: "WARLUZEL",
    value: "62879",
    type: "common",
  },
  {
    label: "SOMBRIN",
    value: "62798",
    type: "common",
  },
  {
    label: "SAULTY",
    value: "62784",
    type: "common",
  },
  {
    label: "GRAND-RULLECOURT",
    value: "62385",
    type: "common",
  },
  {
    label: "COULLEMONT",
    value: "62243",
    type: "common",
  },
  {
    label: "BARLY",
    value: "62084",
    type: "common",
  },
  {
    label: "AVESNES-LE-COMTE",
    value: "62063",
    type: "common",
  },
  {
    label: "SUS-SAINT-LEGER",
    value: "62804",
    type: "common",
  },
  {
    label: "LE SOUICH",
    value: "62802",
    type: "common",
  },
  {
    label: "REBREUVIETTE",
    value: "62695",
    type: "common",
  },
  {
    label: "IVERGNY",
    value: "62475",
    type: "common",
  },
  {
    label: "ESTREE-WAMIN",
    value: "62316",
    type: "common",
  },
  {
    label: "BEAUDRICOURT",
    value: "62091",
    type: "common",
  },
  {
    label: "REBREUVE-SUR-CANCHE",
    value: "62694",
    type: "common",
  },
  {
    label: "FREVENT",
    value: "62361",
    type: "common",
  },
  {
    label: "BOURET-SUR-CANCHE",
    value: "62163",
    type: "common",
  },
  {
    label: "VILLERS-L'HOPITAL",
    value: "62859",
    type: "common",
  },
  {
    label: "VACQUERIE-LE-BOUCQ",
    value: "62833",
    type: "common",
  },
  {
    label: "NŒUX-LES-AUXI",
    value: "62616",
    type: "common",
  },
  {
    label: "FORTEL-EN-ARTOIS",
    value: "62346",
    type: "common",
  },
  {
    label: "BOFFLES",
    value: "62143",
    type: "common",
  },
  {
    label: "BEAUVOIR-WAVANS",
    value: "62881",
    type: "common",
  },
  {
    label: "VAULX",
    value: "62838",
    type: "common",
  },
  {
    label: "LE PONCHEL",
    value: "62665",
    type: "common",
  },
  {
    label: "BUIRE-AU-BOIS",
    value: "62182",
    type: "common",
  },
  {
    label: "AUXI-LE-CHATEAU",
    value: "62060",
    type: "common",
  },
  {
    label: "VITZ-SUR-AUTHIE",
    value: "80810",
    type: "common",
  },
  {
    label: "NEUILLY-LE-DIEN",
    value: "80589",
    type: "common",
  },
  {
    label: "GUESCHART",
    value: "80396",
    type: "common",
  },
  {
    label: "BOUFFLERS",
    value: "80118",
    type: "common",
  },
  {
    label: "WILLENCOURT",
    value: "62891",
    type: "common",
  },
  {
    label: "FROYELLES",
    value: "80371",
    type: "common",
  },
  {
    label: "FONTAINE-SUR-MAYE",
    value: "80327",
    type: "common",
  },
  {
    label: "ESTREES-LES-CRECY",
    value: "80290",
    type: "common",
  },
  {
    label: "BRAILLY-CORNEHOTTE",
    value: "80133",
    type: "common",
  },
  {
    label: "LE BOISLE",
    value: "80109",
    type: "common",
  },
  {
    label: "CRECY-EN-PONTHIEU",
    value: "80222",
    type: "common",
  },
  {
    label: "NOUVION",
    value: "80598",
    type: "common",
  },
  {
    label: "FOREST-MONTIERS",
    value: "80332",
    type: "common",
  },
  {
    label: "BERNAY-EN-PONTHIEU",
    value: "80087",
    type: "common",
  },
  {
    label: "FAVIERES",
    value: "80303",
    type: "common",
  },
  {
    label: "LE CROTOY",
    value: "80228",
    type: "common",
  },
  {
    label: "GIVET",
    value: "08190",
    type: "common",
  },
  {
    label: "CLAIRFAYTS",
    value: "59148",
    type: "common",
  },
  {
    label: "BEAURIEUX",
    value: "59062",
    type: "common",
  },
  {
    label: "SOLRE-LE-CHATEAU",
    value: "59572",
    type: "common",
  },
  {
    label: "SARS-POTERIES",
    value: "59555",
    type: "common",
  },
  {
    label: "LEZ-FONTAINE",
    value: "59342",
    type: "common",
  },
  {
    label: "ECCLES",
    value: "59186",
    type: "common",
  },
  {
    label: "DIMONT",
    value: "59175",
    type: "common",
  },
  {
    label: "DIMECHAUX",
    value: "59174",
    type: "common",
  },
  {
    label: "WATTIGNIES-LA-VICTOIRE",
    value: "59649",
    type: "common",
  },
  {
    label: "SEMOUSIES",
    value: "59563",
    type: "common",
  },
  {
    label: "FLOURSIES",
    value: "59240",
    type: "common",
  },
  {
    label: "ECLAIBES",
    value: "59187",
    type: "common",
  },
  {
    label: "DOURLERS",
    value: "59181",
    type: "common",
  },
  {
    label: "BEUGNIES",
    value: "59078",
    type: "common",
  },
  {
    label: "SAINT-REMY-CHAUSSEE",
    value: "59542",
    type: "common",
  },
  {
    label: "SAINT-AUBIN",
    value: "59529",
    type: "common",
  },
  {
    label: "MONCEAU-SAINT-WAAST",
    value: "59406",
    type: "common",
  },
  {
    label: "LEVAL",
    value: "59344",
    type: "common",
  },
  {
    label: "ECUELIN",
    value: "59188",
    type: "common",
  },
  {
    label: "TAISNIERES-EN-THIERACHE",
    value: "59583",
    type: "common",
  },
  {
    label: "SASSEGNIES",
    value: "59556",
    type: "common",
  },
  {
    label: "NOYELLES-SUR-SAMBRE",
    value: "59439",
    type: "common",
  },
  {
    label: "RAUCOURT-AU-BOIS",
    value: "59494",
    type: "common",
  },
  {
    label: "PREUX-AU-BOIS",
    value: "59472",
    type: "common",
  },
  {
    label: "HECQ",
    value: "59296",
    type: "common",
  },
  {
    label: "VENDEGIES-AU-BOIS",
    value: "59607",
    type: "common",
  },
  {
    label: "ROBERSART",
    value: "59503",
    type: "common",
  },
  {
    label: "POIX-DU-NORD",
    value: "59464",
    type: "common",
  },
  {
    label: "NEUVILLE-EN-AVESNOIS",
    value: "59425",
    type: "common",
  },
  {
    label: "ENGLEFONTAINE",
    value: "59194",
    type: "common",
  },
  {
    label: "CROIX-CALUYAU",
    value: "59164",
    type: "common",
  },
  {
    label: "BOUSIES",
    value: "59099",
    type: "common",
  },
  {
    label: "SOLESMES",
    value: "59571",
    type: "common",
  },
  {
    label: "SAINT-PYTHON",
    value: "59541",
    type: "common",
  },
  {
    label: "ROMERIES",
    value: "59506",
    type: "common",
  },
  {
    label: "BRIASTRE",
    value: "59108",
    type: "common",
  },
  {
    label: "BEAURAIN",
    value: "59060",
    type: "common",
  },
  {
    label: "VIESLY",
    value: "59614",
    type: "common",
  },
  {
    label: "SAINT-VAAST-EN-CAMBRESIS",
    value: "59547",
    type: "common",
  },
  {
    label: "SAINT-HILAIRE-LEZ-CAMBRAI",
    value: "59533",
    type: "common",
  },
  {
    label: "SAINT-AUBERT",
    value: "59528",
    type: "common",
  },
  {
    label: "QUIEVY",
    value: "59485",
    type: "common",
  },
  {
    label: "BEVILLERS",
    value: "59081",
    type: "common",
  },
  {
    label: "AVESNES-LES-AUBERT",
    value: "59037",
    type: "common",
  },
  {
    label: "RIEUX-EN-CAMBRESIS",
    value: "59502",
    type: "common",
  },
  {
    label: "NAVES",
    value: "59422",
    type: "common",
  },
  {
    label: "ESTOURMEL",
    value: "59213",
    type: "common",
  },
  {
    label: "CAUROIR",
    value: "59141",
    type: "common",
  },
  {
    label: "CARNIERES",
    value: "59132",
    type: "common",
  },
  {
    label: "CAGNONCLES",
    value: "59121",
    type: "common",
  },
  {
    label: "BOUSSIERES-EN-CAMBRESIS",
    value: "59102",
    type: "common",
  },
  {
    label: "AWOINGT",
    value: "59039",
    type: "common",
  },
  {
    label: "TILLOY-LEZ-CAMBRAI",
    value: "59597",
    type: "common",
  },
  {
    label: "RAMILLIES",
    value: "59492",
    type: "common",
  },
  {
    label: "PROVILLE",
    value: "59476",
    type: "common",
  },
  {
    label: "NEUVILLE-SAINT-REMY",
    value: "59428",
    type: "common",
  },
  {
    label: "ESCAUDŒUVRES",
    value: "59206",
    type: "common",
  },
  {
    label: "CAMBRAI",
    value: "59122",
    type: "common",
  },
  {
    label: "BOURLON",
    value: "62164",
    type: "common",
  },
  {
    label: "SAILLY-LEZ-CAMBRAI",
    value: "59521",
    type: "common",
  },
  {
    label: "RAILLENCOURT-SAINTE-OLLE",
    value: "59488",
    type: "common",
  },
  {
    label: "HAYNECOURT",
    value: "59294",
    type: "common",
  },
  {
    label: "FONTAINE-NOTRE-DAME",
    value: "59244",
    type: "common",
  },
  {
    label: "ANNEUX",
    value: "59010",
    type: "common",
  },
  {
    label: "SAINS-LES-MARQUION",
    value: "62739",
    type: "common",
  },
  {
    label: "PRONVILLE-EN-ARTOIS",
    value: "62671",
    type: "common",
  },
  {
    label: "MARQUION",
    value: "62559",
    type: "common",
  },
  {
    label: "INCHY-EN-ARTOIS",
    value: "62469",
    type: "common",
  },
  {
    label: "BUISSY",
    value: "62184",
    type: "common",
  },
  {
    label: "MŒUVRES",
    value: "59405",
    type: "common",
  },
  {
    label: "RIENCOURT-LES-CAGNICOURT",
    value: "62709",
    type: "common",
  },
  {
    label: "QUEANT",
    value: "62673",
    type: "common",
  },
  {
    label: "NOREUIL",
    value: "62619",
    type: "common",
  },
  {
    label: "LAGNICOURT-MARCEL",
    value: "62484",
    type: "common",
  },
  {
    label: "CAGNICOURT",
    value: "62192",
    type: "common",
  },
  {
    label: "BULLECOURT",
    value: "62185",
    type: "common",
  },
  {
    label: "SAINT-LEGER",
    value: "62754",
    type: "common",
  },
  {
    label: "MORY",
    value: "62594",
    type: "common",
  },
  {
    label: "ERVILLERS",
    value: "62306",
    type: "common",
  },
  {
    label: "ECOUST-SAINT-MEIN",
    value: "62285",
    type: "common",
  },
  {
    label: "CROISILLES",
    value: "62259",
    type: "common",
  },
  {
    label: "BOYELLES",
    value: "62172",
    type: "common",
  },
  {
    label: "MOYENNEVILLE",
    value: "62597",
    type: "common",
  },
  {
    label: "HENDECOURT-LES-RANSART",
    value: "62425",
    type: "common",
  },
  {
    label: "HAMELINCOURT",
    value: "62406",
    type: "common",
  },
  {
    label: "COURCELLES-LE-COMTE",
    value: "62248",
    type: "common",
  },
  {
    label: "BOISLEUX-AU-MONT",
    value: "62151",
    type: "common",
  },
  {
    label: "BOIRY-SAINTE-RICTRUDE",
    value: "62147",
    type: "common",
  },
  {
    label: "BOIRY-SAINT-MARTIN",
    value: "62146",
    type: "common",
  },
  {
    label: "AYETTE",
    value: "62068",
    type: "common",
  },
  {
    label: "RANSART",
    value: "62689",
    type: "common",
  },
  {
    label: "MONCHY-AU-BOIS",
    value: "62579",
    type: "common",
  },
  {
    label: "HANNESCAMPS",
    value: "62409",
    type: "common",
  },
  {
    label: "DOUCHY-LES-AYETTE",
    value: "62272",
    type: "common",
  },
  {
    label: "ADINFER",
    value: "62009",
    type: "common",
  },
  {
    label: "SAINT-AMAND",
    value: "62741",
    type: "common",
  },
  {
    label: "POMMIER",
    value: "62664",
    type: "common",
  },
  {
    label: "HUMBERCAMPS",
    value: "62465",
    type: "common",
  },
  {
    label: "LA HERLIERE",
    value: "62434",
    type: "common",
  },
  {
    label: "LA CAUCHIE",
    value: "62216",
    type: "common",
  },
  {
    label: "BIENVILLERS-AU-BOIS",
    value: "62130",
    type: "common",
  },
  {
    label: "BERLES-AU-BOIS",
    value: "62112",
    type: "common",
  },
  {
    label: "BAILLEULMONT",
    value: "62072",
    type: "common",
  },
  {
    label: "WARLINCOURT-LES-PAS",
    value: "62877",
    type: "common",
  },
  {
    label: "MONDICOURT",
    value: "62583",
    type: "common",
  },
  {
    label: "HENU",
    value: "62430",
    type: "common",
  },
  {
    label: "GRINCOURT-LES-PAS",
    value: "62389",
    type: "common",
  },
  {
    label: "GAUDIEMPRE",
    value: "62368",
    type: "common",
  },
  {
    label: "COUTURELLE",
    value: "62253",
    type: "common",
  },
  {
    label: "LUCHEUX",
    value: "80495",
    type: "common",
  },
  {
    label: "HUMBERCOURT",
    value: "80445",
    type: "common",
  },
  {
    label: "GROUCHES-LUCHUEL",
    value: "80392",
    type: "common",
  },
  {
    label: "BREVILLERS",
    value: "80140",
    type: "common",
  },
  {
    label: "POMMERA",
    value: "62663",
    type: "common",
  },
  {
    label: "HALLOY",
    value: "62404",
    type: "common",
  },
  {
    label: "OCCOCHES",
    value: "80602",
    type: "common",
  },
  {
    label: "NEUVILLETTE",
    value: "80596",
    type: "common",
  },
  {
    label: "DOULLENS",
    value: "80253",
    type: "common",
  },
  {
    label: "BOUQUEMAISON",
    value: "80122",
    type: "common",
  },
  {
    label: "BARLY",
    value: "80055",
    type: "common",
  },
  {
    label: "REMAISNIL",
    value: "80666",
    type: "common",
  },
  {
    label: "OUTREBOIS",
    value: "80614",
    type: "common",
  },
  {
    label: "MEZEROLLES",
    value: "80544",
    type: "common",
  },
  {
    label: "LE MEILLARD",
    value: "80526",
    type: "common",
  },
  {
    label: "FROHEN-SUR-AUTHIE",
    value: "80369",
    type: "common",
  },
  {
    label: "BEALCOURT",
    value: "80060",
    type: "common",
  },
  {
    label: "SAINT-ACHEUL",
    value: "80697",
    type: "common",
  },
  {
    label: "MONTIGNY-LES-JONGLEURS",
    value: "80563",
    type: "common",
  },
  {
    label: "MAIZICOURT",
    value: "80503",
    type: "common",
  },
  {
    label: "HEUZECOURT",
    value: "80439",
    type: "common",
  },
  {
    label: "BERNATRE",
    value: "80085",
    type: "common",
  },
  {
    label: "AGENVILLE",
    value: "80005",
    type: "common",
  },
  {
    label: "YVRENCH",
    value: "80832",
    type: "common",
  },
  {
    label: "MAISON-PONTHIEU",
    value: "80501",
    type: "common",
  },
  {
    label: "HIERMONT",
    value: "80440",
    type: "common",
  },
  {
    label: "CONTEVILLE",
    value: "80208",
    type: "common",
  },
  {
    label: "YVRENCHEUX",
    value: "80833",
    type: "common",
  },
  {
    label: "ONEUX",
    value: "80609",
    type: "common",
  },
  {
    label: "NOYELLES-EN-CHAUSSEE",
    value: "80599",
    type: "common",
  },
  {
    label: "GAPENNES",
    value: "80374",
    type: "common",
  },
  {
    label: "DOMVAST",
    value: "80250",
    type: "common",
  },
  {
    label: "AGENVILLERS",
    value: "80006",
    type: "common",
  },
  {
    label: "NEUILLY-L'HOPITAL",
    value: "80590",
    type: "common",
  },
  {
    label: "LAMOTTE-BULEUX",
    value: "80462",
    type: "common",
  },
  {
    label: "HAUTVILLERS-OUVILLE",
    value: "80422",
    type: "common",
  },
  {
    label: "FOREST-L'ABBAYE",
    value: "80331",
    type: "common",
  },
  {
    label: "CANCHY",
    value: "80167",
    type: "common",
  },
  {
    label: "LE TITRE",
    value: "80763",
    type: "common",
  },
  {
    label: "SAILLY-FLIBEAUCOURT",
    value: "80692",
    type: "common",
  },
  {
    label: "PORT-LE-GRAND",
    value: "80637",
    type: "common",
  },
  {
    label: "SAINT-VALERY-SUR-SOMME",
    value: "80721",
    type: "common",
  },
  {
    label: "PONTHOILE",
    value: "80633",
    type: "common",
  },
  {
    label: "NOYELLES-SUR-MER",
    value: "80600",
    type: "common",
  },
  {
    label: "ESTREBŒUF",
    value: "80287",
    type: "common",
  },
  {
    label: "BOISMONT",
    value: "80110",
    type: "common",
  },
  {
    label: "PENDE",
    value: "80618",
    type: "common",
  },
  {
    label: "LANCHERES",
    value: "80464",
    type: "common",
  },
  {
    label: "CAYEUX-SUR-MER",
    value: "80182",
    type: "common",
  },
  {
    label: "FROMELENNES",
    value: "08183",
    type: "common",
  },
  {
    label: "CHARNOIS",
    value: "08106",
    type: "common",
  },
  {
    label: "RANCENNES",
    value: "08353",
    type: "common",
  },
  {
    label: "LANDRICHAMPS",
    value: "08247",
    type: "common",
  },
  {
    label: "FOISCHES",
    value: "08175",
    type: "common",
  },
  {
    label: "HAM-SUR-MEUSE",
    value: "08207",
    type: "common",
  },
  {
    label: "CHOOZ",
    value: "08122",
    type: "common",
  },
  {
    label: "AUBRIVES",
    value: "08028",
    type: "common",
  },
  {
    label: "VIREUX-MOLHAIN",
    value: "08486",
    type: "common",
  },
  {
    label: "HIERGES",
    value: "08226",
    type: "common",
  },
  {
    label: "MOUSTIER-EN-FAGNE",
    value: "59420",
    type: "common",
  },
  {
    label: "WILLIES",
    value: "59661",
    type: "common",
  },
  {
    label: "EPPE-SAUVAGE",
    value: "59198",
    type: "common",
  },
  {
    label: "SAINS-DU-NORD",
    value: "59525",
    type: "common",
  },
  {
    label: "RAMOUSIES",
    value: "59493",
    type: "common",
  },
  {
    label: "LIESSIES",
    value: "59347",
    type: "common",
  },
  {
    label: "FELLERIES",
    value: "59226",
    type: "common",
  },
  {
    label: "SEMERIES",
    value: "59562",
    type: "common",
  },
  {
    label: "FLAUMONT-WAUDRECHIES",
    value: "59233",
    type: "common",
  },
  {
    label: "BAS-LIEU",
    value: "59050",
    type: "common",
  },
  {
    label: "AVESNES-SUR-HELPE",
    value: "59036",
    type: "common",
  },
  {
    label: "AVESNELLES",
    value: "59035",
    type: "common",
  },
  {
    label: "SAINT-HILAIRE-SUR-HELPE",
    value: "59534",
    type: "common",
  },
  {
    label: "MARBAIX",
    value: "59374",
    type: "common",
  },
  {
    label: "HAUT-LIEU",
    value: "59290",
    type: "common",
  },
  {
    label: "DOMPIERRE-SUR-HELPE",
    value: "59177",
    type: "common",
  },
  {
    label: "PETIT-FAYT",
    value: "59461",
    type: "common",
  },
  {
    label: "MAROILLES",
    value: "59384",
    type: "common",
  },
  {
    label: "GRAND-FAYT",
    value: "59270",
    type: "common",
  },
  {
    label: "LANDRECIES",
    value: "59331",
    type: "common",
  },
  {
    label: "FONTAINE-AU-BOIS",
    value: "59242",
    type: "common",
  },
  {
    label: "LE FAVRIL",
    value: "59223",
    type: "common",
  },
  {
    label: "POMMEREUIL",
    value: "59465",
    type: "common",
  },
  {
    label: "ORS",
    value: "59450",
    type: "common",
  },
  {
    label: "FOREST-EN-CAMBRESIS",
    value: "59246",
    type: "common",
  },
  {
    label: "TROISVILLES",
    value: "59604",
    type: "common",
  },
  {
    label: "NEUVILLY",
    value: "59430",
    type: "common",
  },
  {
    label: "MONTAY",
    value: "59412",
    type: "common",
  },
  {
    label: "INCHY",
    value: "59321",
    type: "common",
  },
  {
    label: "LE CATEAU-CAMBRESIS",
    value: "59136",
    type: "common",
  },
  {
    label: "LIGNY-EN-CAMBRESIS",
    value: "59349",
    type: "common",
  },
  {
    label: "CAUDRY",
    value: "59139",
    type: "common",
  },
  {
    label: "BETHENCOURT",
    value: "59075",
    type: "common",
  },
  {
    label: "BEAUVOIS-EN-CAMBRESIS",
    value: "59063",
    type: "common",
  },
  {
    label: "BEAUMONT-EN-CAMBRESIS",
    value: "59059",
    type: "common",
  },
  {
    label: "HAUCOURT-EN-CAMBRESIS",
    value: "59287",
    type: "common",
  },
  {
    label: "WAMBAIX",
    value: "59635",
    type: "common",
  },
  {
    label: "SERANVILLERS-FORENVILLE",
    value: "59567",
    type: "common",
  },
  {
    label: "FONTAINE-AU-PIRE",
    value: "59243",
    type: "common",
  },
  {
    label: "CATTENIERES",
    value: "59138",
    type: "common",
  },
  {
    label: "RUMILLY-EN-CAMBRESIS",
    value: "59520",
    type: "common",
  },
  {
    label: "NIERGNIES",
    value: "59432",
    type: "common",
  },
  {
    label: "MASNIERES",
    value: "59389",
    type: "common",
  },
  {
    label: "GRAINCOURT-LES-HAVRINCOURT",
    value: "62384",
    type: "common",
  },
  {
    label: "RIBECOURT-LA-TOUR",
    value: "59500",
    type: "common",
  },
  {
    label: "NOYELLES-SUR-ESCAUT",
    value: "59438",
    type: "common",
  },
  {
    label: "MARCOING",
    value: "59377",
    type: "common",
  },
  {
    label: "FLESQUIERES",
    value: "59236",
    type: "common",
  },
  {
    label: "CANTAING-SUR-ESCAUT",
    value: "59125",
    type: "common",
  },
  {
    label: "HERMIES",
    value: "62440",
    type: "common",
  },
  {
    label: "HAVRINCOURT",
    value: "62421",
    type: "common",
  },
  {
    label: "DOIGNIES",
    value: "59176",
    type: "common",
  },
  {
    label: "BOURSIES",
    value: "59097",
    type: "common",
  },
  {
    label: "VELU",
    value: "62840",
    type: "common",
  },
  {
    label: "MORCHIES",
    value: "62591",
    type: "common",
  },
  {
    label: "LEBUCQUIERE",
    value: "62493",
    type: "common",
  },
  {
    label: "BEUGNY",
    value: "62122",
    type: "common",
  },
  {
    label: "BEAUMETZ-LES-CAMBRAI",
    value: "62096",
    type: "common",
  },
  {
    label: "VAULX-VRAUCOURT",
    value: "62839",
    type: "common",
  },
  {
    label: "SAPIGNIES",
    value: "62776",
    type: "common",
  },
  {
    label: "FREMICOURT",
    value: "62353",
    type: "common",
  },
  {
    label: "FAVREUIL",
    value: "62326",
    type: "common",
  },
  {
    label: "BIEFVILLERS-LES-BAPAUME",
    value: "62129",
    type: "common",
  },
  {
    label: "BEUGNATRE",
    value: "62121",
    type: "common",
  },
  {
    label: "BEHAGNIES",
    value: "62103",
    type: "common",
  },
  {
    label: "BAPAUME",
    value: "62080",
    type: "common",
  },
  {
    label: "BANCOURT",
    value: "62079",
    type: "common",
  },
  {
    label: "AVESNES-LES-BAPAUME",
    value: "62064",
    type: "common",
  },
  {
    label: "IRLES",
    value: "80451",
    type: "common",
  },
  {
    label: "GREVILLERS",
    value: "62387",
    type: "common",
  },
  {
    label: "GOMIECOURT",
    value: "62374",
    type: "common",
  },
  {
    label: "BIHUCOURT",
    value: "62131",
    type: "common",
  },
  {
    label: "ACHIET-LE-PETIT",
    value: "62006",
    type: "common",
  },
  {
    label: "ACHIET-LE-GRAND",
    value: "62005",
    type: "common",
  },
  {
    label: "ABLAINZEVELLE",
    value: "62002",
    type: "common",
  },
  {
    label: "MIRAUMONT",
    value: "80549",
    type: "common",
  },
  {
    label: "PUISIEUX",
    value: "62672",
    type: "common",
  },
  {
    label: "HEBUTERNE",
    value: "62422",
    type: "common",
  },
  {
    label: "GOMMECOURT",
    value: "62375",
    type: "common",
  },
  {
    label: "BUCQUOY",
    value: "62181",
    type: "common",
  },
  {
    label: "COURCELLES-AU-BOIS",
    value: "80217",
    type: "common",
  },
  {
    label: "COLINCAMPS",
    value: "80203",
    type: "common",
  },
  {
    label: "COIGNEUX",
    value: "80201",
    type: "common",
  },
  {
    label: "BAYENCOURT",
    value: "80057",
    type: "common",
  },
  {
    label: "SOUASTRE",
    value: "62800",
    type: "common",
  },
  {
    label: "SAILLY-AU-BOIS",
    value: "62733",
    type: "common",
  },
  {
    label: "FONCQUEVILLERS",
    value: "62341",
    type: "common",
  },
  {
    label: "THIEVRES",
    value: "80756",
    type: "common",
  },
  {
    label: "SAINT-LEGER-LES-AUTHIE",
    value: "80705",
    type: "common",
  },
  {
    label: "BUS-LES-ARTOIS",
    value: "80153",
    type: "common",
  },
  {
    label: "AUTHIE",
    value: "80043",
    type: "common",
  },
  {
    label: "PAS-EN-ARTOIS",
    value: "62649",
    type: "common",
  },
  {
    label: "FAMECHON",
    value: "62322",
    type: "common",
  },
  {
    label: "COUIN",
    value: "62242",
    type: "common",
  },
  {
    label: "TERRAMESNIL",
    value: "80749",
    type: "common",
  },
  {
    label: "MARIEUX",
    value: "80514",
    type: "common",
  },
  {
    label: "AUTHIEULE",
    value: "80044",
    type: "common",
  },
  {
    label: "THIEVRES",
    value: "62814",
    type: "common",
  },
  {
    label: "SARTON",
    value: "62779",
    type: "common",
  },
  {
    label: "ORVILLE",
    value: "62640",
    type: "common",
  },
  {
    label: "AMPLIER",
    value: "62030",
    type: "common",
  },
  {
    label: "LONGUEVILLETTE",
    value: "80491",
    type: "common",
  },
  {
    label: "HEM-HARDINVAL",
    value: "80427",
    type: "common",
  },
  {
    label: "GEZAINCOURT",
    value: "80377",
    type: "common",
  },
  {
    label: "CANDAS",
    value: "80168",
    type: "common",
  },
  {
    label: "BEAUVAL",
    value: "80071",
    type: "common",
  },
  {
    label: "GORGES",
    value: "80381",
    type: "common",
  },
  {
    label: "FIENVILLERS",
    value: "80310",
    type: "common",
  },
  {
    label: "BOISBERGUES",
    value: "80108",
    type: "common",
  },
  {
    label: "AUTHEUX",
    value: "80042",
    type: "common",
  },
  {
    label: "RIBEAUCOURT",
    value: "80671",
    type: "common",
  },
  {
    label: "PROUVILLE",
    value: "80642",
    type: "common",
  },
  {
    label: "FRANSU",
    value: "80348",
    type: "common",
  },
  {
    label: "EPECAMPS",
    value: "80270",
    type: "common",
  },
  {
    label: "DOMLEGER-LONGVILLERS",
    value: "80245",
    type: "common",
  },
  {
    label: "DOMESMONT",
    value: "80243",
    type: "common",
  },
  {
    label: "BERNAVILLE",
    value: "80086",
    type: "common",
  },
  {
    label: "BEAUMETZ",
    value: "80068",
    type: "common",
  },
  {
    label: "MESNIL-DOMQUEUR",
    value: "80537",
    type: "common",
  },
  {
    label: "MAISON-ROLAND",
    value: "80502",
    type: "common",
  },
  {
    label: "DOMQUEUR",
    value: "80249",
    type: "common",
  },
  {
    label: "CRAMONT",
    value: "80221",
    type: "common",
  },
  {
    label: "COULONVILLERS",
    value: "80215",
    type: "common",
  },
  {
    label: "BUSSUS-BUSSUEL",
    value: "80155",
    type: "common",
  },
  {
    label: "YAUCOURT-BUSSUS",
    value: "80830",
    type: "common",
  },
  {
    label: "SAINT-RIQUIER",
    value: "80716",
    type: "common",
  },
  {
    label: "NEUFMOULIN",
    value: "80588",
    type: "common",
  },
  {
    label: "MILLENCOURT-EN-PONTHIEU",
    value: "80548",
    type: "common",
  },
  {
    label: "BUIGNY-L'ABBE",
    value: "80147",
    type: "common",
  },
  {
    label: "VAUCHELLES-LES-QUESNOY",
    value: "80779",
    type: "common",
  },
  {
    label: "DRUCAT",
    value: "80260",
    type: "common",
  },
  {
    label: "CAOURS",
    value: "80171",
    type: "common",
  },
  {
    label: "BUIGNY-SAINT-MACLOU",
    value: "80149",
    type: "common",
  },
  {
    label: "ABBEVILLE",
    value: "80001",
    type: "common",
  },
  {
    label: "SAIGNEVILLE",
    value: "80691",
    type: "common",
  },
  {
    label: "GRAND-LAVIERS",
    value: "80385",
    type: "common",
  },
  {
    label: "CAMBRON",
    value: "80163",
    type: "common",
  },
  {
    label: "CAHON",
    value: "80161",
    type: "common",
  },
  {
    label: "QUESNOY-LE-MONTANT",
    value: "80654",
    type: "common",
  },
  {
    label: "MONS-BOUBERT",
    value: "80556",
    type: "common",
  },
  {
    label: "FRANLEU",
    value: "80345",
    type: "common",
  },
  {
    label: "VAUDRICOURT",
    value: "80780",
    type: "common",
  },
  {
    label: "SAINT-BLIMONT",
    value: "80700",
    type: "common",
  },
  {
    label: "OCHANCOURT",
    value: "80603",
    type: "common",
  },
  {
    label: "ARREST",
    value: "80029",
    type: "common",
  },
  {
    label: "WOIGNARUE",
    value: "80826",
    type: "common",
  },
  {
    label: "BRUTELLES",
    value: "80146",
    type: "common",
  },
  {
    label: "BOURSEVILLE",
    value: "80124",
    type: "common",
  },
  {
    label: "AULT",
    value: "80039",
    type: "common",
  },
  {
    label: "VIREUX-WALLERAND",
    value: "08487",
    type: "common",
  },
  {
    label: "MONTIGNY-SUR-MEUSE",
    value: "08304",
    type: "common",
  },
  {
    label: "FEPIN",
    value: "08166",
    type: "common",
  },
  {
    label: "BAIVES",
    value: "59045",
    type: "common",
  },
  {
    label: "WALLERS-EN-FAGNE",
    value: "59633",
    type: "common",
  },
  {
    label: "TRELON",
    value: "59601",
    type: "common",
  },
  {
    label: "OHAIN",
    value: "59445",
    type: "common",
  },
  {
    label: "GLAGEON",
    value: "59261",
    type: "common",
  },
  {
    label: "FERON",
    value: "59229",
    type: "common",
  },
  {
    label: "RAINSARS",
    value: "59490",
    type: "common",
  },
  {
    label: "LAROUILLIES",
    value: "59333",
    type: "common",
  },
  {
    label: "ETRŒUNGT",
    value: "59218",
    type: "common",
  },
  {
    label: "FONTENELLE",
    value: "02324",
    type: "common",
  },
  {
    label: "FLOYON",
    value: "59241",
    type: "common",
  },
  {
    label: "CARTIGNIES",
    value: "59134",
    type: "common",
  },
  {
    label: "BOULOGNE-SUR-HELPE",
    value: "59093",
    type: "common",
  },
  {
    label: "BARZY-EN-THIERACHE",
    value: "02050",
    type: "common",
  },
  {
    label: "PRISCHES",
    value: "59474",
    type: "common",
  },
  {
    label: "BEAUREPAIRE-SUR-SAMBRE",
    value: "59061",
    type: "common",
  },
  {
    label: "FESMY-LE-SART",
    value: "02308",
    type: "common",
  },
  {
    label: "BERGUES-SUR-SAMBRE",
    value: "02067",
    type: "common",
  },
  {
    label: "LA GROISE",
    value: "59274",
    type: "common",
  },
  {
    label: "CATILLON-SUR-SAMBRE",
    value: "59137",
    type: "common",
  },
  {
    label: "REJET-DE-BEAULIEU",
    value: "59496",
    type: "common",
  },
  {
    label: "MAZINGHIEN",
    value: "59395",
    type: "common",
  },
  {
    label: "BAZUEL",
    value: "59055",
    type: "common",
  },
  {
    label: "SAINT-MARTIN-RIVIERE",
    value: "02683",
    type: "common",
  },
  {
    label: "RIBEAUVILLE",
    value: "02647",
    type: "common",
  },
  {
    label: "SAINT-SOUPLET",
    value: "59545",
    type: "common",
  },
  {
    label: "SAINT-BENIN",
    value: "59531",
    type: "common",
  },
  {
    label: "REUMONT",
    value: "59498",
    type: "common",
  },
  {
    label: "MAUROIS",
    value: "59394",
    type: "common",
  },
  {
    label: "HONNECHY",
    value: "59311",
    type: "common",
  },
  {
    label: "MOLAIN",
    value: "02488",
    type: "common",
  },
  {
    label: "MONTIGNY-EN-CAMBRESIS",
    value: "59413",
    type: "common",
  },
  {
    label: "MARETZ",
    value: "59382",
    type: "common",
  },
  {
    label: "ELINCOURT",
    value: "59191",
    type: "common",
  },
  {
    label: "CLARY",
    value: "59149",
    type: "common",
  },
  {
    label: "CAULLERY",
    value: "59140",
    type: "common",
  },
  {
    label: "BERTRY",
    value: "59074",
    type: "common",
  },
  {
    label: "WALINCOURT-SELVIGNY",
    value: "59631",
    type: "common",
  },
  {
    label: "MALINCOURT",
    value: "59372",
    type: "common",
  },
  {
    label: "ESNES",
    value: "59209",
    type: "common",
  },
  {
    label: "DEHERIES",
    value: "59171",
    type: "common",
  },
  {
    label: "LES RUES-DES-VIGNES",
    value: "59517",
    type: "common",
  },
  {
    label: "LESDAIN",
    value: "59341",
    type: "common",
  },
  {
    label: "HONNECOURT-SUR-ESCAUT",
    value: "59312",
    type: "common",
  },
  {
    label: "CREVECŒUR-SUR-L'ESCAUT",
    value: "59161",
    type: "common",
  },
  {
    label: "BANTOUZELLE",
    value: "59049",
    type: "common",
  },
  {
    label: "BANTEUX",
    value: "59047",
    type: "common",
  },
  {
    label: "TRESCAULT",
    value: "62830",
    type: "common",
  },
  {
    label: "VILLERS-PLOUICH",
    value: "59625",
    type: "common",
  },
  {
    label: "VILLERS-GUISLAIN",
    value: "59623",
    type: "common",
  },
  {
    label: "GOUZEAUCOURT",
    value: "59269",
    type: "common",
  },
  {
    label: "GONNELIEU",
    value: "59267",
    type: "common",
  },
  {
    label: "FINS",
    value: "80312",
    type: "common",
  },
  {
    label: "EQUANCOURT",
    value: "80275",
    type: "common",
  },
  {
    label: "RUYAULCOURT",
    value: "62731",
    type: "common",
  },
  {
    label: "NEUVILLE-BOURJONVAL",
    value: "62608",
    type: "common",
  },
  {
    label: "METZ-EN-COUTURE",
    value: "62572",
    type: "common",
  },
  {
    label: "MESNIL-EN-ARROUAISE",
    value: "80538",
    type: "common",
  },
  {
    label: "YTRES",
    value: "62909",
    type: "common",
  },
  {
    label: "ROCQUIGNY",
    value: "62715",
    type: "common",
  },
  {
    label: "LECHELLE",
    value: "62494",
    type: "common",
  },
  {
    label: "HAPLINCOURT",
    value: "62410",
    type: "common",
  },
  {
    label: "BUS",
    value: "62189",
    type: "common",
  },
  {
    label: "BERTINCOURT",
    value: "62117",
    type: "common",
  },
  {
    label: "BARASTRE",
    value: "62082",
    type: "common",
  },
  {
    label: "LESBŒUFS",
    value: "80472",
    type: "common",
  },
  {
    label: "GUEUDECOURT",
    value: "80397",
    type: "common",
  },
  {
    label: "VILLERS-AU-FLOS",
    value: "62855",
    type: "common",
  },
  {
    label: "LE TRANSLOY",
    value: "62829",
    type: "common",
  },
  {
    label: "RIENCOURT-LES-BAPAUME",
    value: "62708",
    type: "common",
  },
  {
    label: "LIGNY-THILLOY",
    value: "62515",
    type: "common",
  },
  {
    label: "BEAULENCOURT",
    value: "62093",
    type: "common",
  },
  {
    label: "PYS",
    value: "80648",
    type: "common",
  },
  {
    label: "FLERS",
    value: "80314",
    type: "common",
  },
  {
    label: "COURCELETTE",
    value: "80216",
    type: "common",
  },
  {
    label: "WARLENCOURT-EAUCOURT",
    value: "62876",
    type: "common",
  },
  {
    label: "LE SARS",
    value: "62777",
    type: "common",
  },
  {
    label: "MARTINPUICH",
    value: "62561",
    type: "common",
  },
  {
    label: "THIEPVAL",
    value: "80753",
    type: "common",
  },
  {
    label: "POZIERES",
    value: "80640",
    type: "common",
  },
  {
    label: "GRANDCOURT",
    value: "80384",
    type: "common",
  },
  {
    label: "BEAUMONT-HAMEL",
    value: "80069",
    type: "common",
  },
  {
    label: "BEAUCOURT-SUR-L'ANCRE",
    value: "80065",
    type: "common",
  },
  {
    label: "AUTHUILLE",
    value: "80045",
    type: "common",
  },
  {
    label: "MESNIL-MARTINSART",
    value: "80540",
    type: "common",
  },
  {
    label: "MAILLY-MAILLET",
    value: "80498",
    type: "common",
  },
  {
    label: "HEDAUVILLE",
    value: "80425",
    type: "common",
  },
  {
    label: "FORCEVILLE",
    value: "80329",
    type: "common",
  },
  {
    label: "ENGLEBELMER",
    value: "80266",
    type: "common",
  },
  {
    label: "BERTRANCOURT",
    value: "80095",
    type: "common",
  },
  {
    label: "AUCHONVILLERS",
    value: "80038",
    type: "common",
  },
  {
    label: "VAUCHELLES-LES-AUTHIE",
    value: "80777",
    type: "common",
  },
  {
    label: "VARENNES",
    value: "80776",
    type: "common",
  },
  {
    label: "TOUTENCOURT",
    value: "80766",
    type: "common",
  },
  {
    label: "LOUVENCOURT",
    value: "80493",
    type: "common",
  },
  {
    label: "LEALVILLERS",
    value: "80470",
    type: "common",
  },
  {
    label: "ARQUEVES",
    value: "80028",
    type: "common",
  },
  {
    label: "ACHEUX-EN-AMIENOIS",
    value: "80003",
    type: "common",
  },
  {
    label: "RAINCHEVAL",
    value: "80659",
    type: "common",
  },
  {
    label: "PUCHEVILLERS",
    value: "80645",
    type: "common",
  },
  {
    label: "BEAUQUESNE",
    value: "80070",
    type: "common",
  },
  {
    label: "LA VICOGNE",
    value: "80792",
    type: "common",
  },
  {
    label: "TALMAS",
    value: "80746",
    type: "common",
  },
  {
    label: "NAOURS",
    value: "80584",
    type: "common",
  },
  {
    label: "WARGNIES",
    value: "80819",
    type: "common",
  },
  {
    label: "PERNOIS",
    value: "80619",
    type: "common",
  },
  {
    label: "FIEFFES-MONTRELET",
    value: "80566",
    type: "common",
  },
  {
    label: "HALLOY-LES-PERNOIS",
    value: "80408",
    type: "common",
  },
  {
    label: "CANAPLES",
    value: "80166",
    type: "common",
  },
  {
    label: "BONNEVILLE",
    value: "80113",
    type: "common",
  },
  {
    label: "VILLE-LE-MARCLET",
    value: "80795",
    type: "common",
  },
  {
    label: "SAINT-LEGER-LES-DOMART",
    value: "80706",
    type: "common",
  },
  {
    label: "LANCHES-SAINT-HILAIRE",
    value: "80466",
    type: "common",
  },
  {
    label: "FRANQUEVILLE",
    value: "80346",
    type: "common",
  },
  {
    label: "DOMART-EN-PONTHIEU",
    value: "80241",
    type: "common",
  },
  {
    label: "BERTEAUCOURT-LES-DAMES",
    value: "80093",
    type: "common",
  },
  {
    label: "BERNEUIL",
    value: "80089",
    type: "common",
  },
  {
    label: "VILLERS-SOUS-AILLY",
    value: "80804",
    type: "common",
  },
  {
    label: "VAUCHELLES-LES-DOMART",
    value: "80778",
    type: "common",
  },
  {
    label: "SURCAMPS",
    value: "80742",
    type: "common",
  },
  {
    label: "MOUFLERS",
    value: "80574",
    type: "common",
  },
  {
    label: "GORENFLOS",
    value: "80380",
    type: "common",
  },
  {
    label: "ERGNIES",
    value: "80281",
    type: "common",
  },
  {
    label: "BRUCAMPS",
    value: "80145",
    type: "common",
  },
  {
    label: "BOUCHON",
    value: "80117",
    type: "common",
  },
  {
    label: "AILLY-LE-HAUT-CLOCHER",
    value: "80009",
    type: "common",
  },
  {
    label: "PONT-REMY",
    value: "80635",
    type: "common",
  },
  {
    label: "LONG",
    value: "80486",
    type: "common",
  },
  {
    label: "FRANCIERES",
    value: "80344",
    type: "common",
  },
  {
    label: "COCQUEREL",
    value: "80200",
    type: "common",
  },
  {
    label: "BELLANCOURT",
    value: "80078",
    type: "common",
  },
  {
    label: "MAREUIL-CAUBERT",
    value: "80512",
    type: "common",
  },
  {
    label: "ERONDELLE",
    value: "80282",
    type: "common",
  },
  {
    label: "EPAGNE-EPAGNETTE",
    value: "80268",
    type: "common",
  },
  {
    label: "EAUCOURT-SUR-SOMME",
    value: "80262",
    type: "common",
  },
  {
    label: "BRAY-LES-MAREUIL",
    value: "80135",
    type: "common",
  },
  {
    label: "YONVAL",
    value: "80836",
    type: "common",
  },
  {
    label: "MOYENNEVILLE",
    value: "80578",
    type: "common",
  },
  {
    label: "HUCHENNEVILLE",
    value: "80444",
    type: "common",
  },
  {
    label: "ERCOURT",
    value: "80280",
    type: "common",
  },
  {
    label: "BEHEN",
    value: "80076",
    type: "common",
  },
  {
    label: "TOURS-EN-VIMEU",
    value: "80765",
    type: "common",
  },
  {
    label: "TŒUFLES",
    value: "80764",
    type: "common",
  },
  {
    label: "MIANNAY",
    value: "80546",
    type: "common",
  },
  {
    label: "CHEPY",
    value: "80190",
    type: "common",
  },
  {
    label: "ACHEUX-EN-VIMEU",
    value: "80004",
    type: "common",
  },
  {
    label: "WOINCOURT",
    value: "80827",
    type: "common",
  },
  {
    label: "VALINES",
    value: "80775",
    type: "common",
  },
  {
    label: "NIBAS",
    value: "80597",
    type: "common",
  },
  {
    label: "FRIVILLE-ESCARBOTIN",
    value: "80368",
    type: "common",
  },
  {
    label: "FRESSENNEVILLE",
    value: "80360",
    type: "common",
  },
  {
    label: "FEUQUIERES-EN-VIMEU",
    value: "80308",
    type: "common",
  },
  {
    label: "AIGNEVILLE",
    value: "80008",
    type: "common",
  },
  {
    label: "YZENGREMER",
    value: "80834",
    type: "common",
  },
  {
    label: "TULLY",
    value: "80770",
    type: "common",
  },
  {
    label: "SAINT-QUENTIN-LA-MOTTE-CROIX-AU-BAILLY",
    value: "80714",
    type: "common",
  },
  {
    label: "OUST-MAREST",
    value: "80613",
    type: "common",
  },
  {
    label: "MENESLIES",
    value: "80527",
    type: "common",
  },
  {
    label: "FRIAUCOURT",
    value: "80364",
    type: "common",
  },
  {
    label: "DARGNIES",
    value: "80235",
    type: "common",
  },
  {
    label: "BOUVAINCOURT-SUR-BRESLE",
    value: "80127",
    type: "common",
  },
  {
    label: "BETHENCOURT-SUR-MER",
    value: "80096",
    type: "common",
  },
  {
    label: "ALLENAY",
    value: "80018",
    type: "common",
  },
  {
    label: "MERS-LES-BAINS",
    value: "80533",
    type: "common",
  },
  {
    label: "LE TREPORT",
    value: "76711",
    type: "common",
  },
  {
    label: "PONTS-ET-MARAIS",
    value: "76507",
    type: "common",
  },
  {
    label: "FLOCQUES",
    value: "76266",
    type: "common",
  },
  {
    label: "HARGNIES",
    value: "08214",
    type: "common",
  },
  {
    label: "HAYBES",
    value: "08222",
    type: "common",
  },
  {
    label: "FUMAY",
    value: "08185",
    type: "common",
  },
  {
    label: "ANOR",
    value: "59012",
    type: "common",
  },
  {
    label: "WIGNEHIES",
    value: "59659",
    type: "common",
  },
  {
    label: "FOURMIES",
    value: "59249",
    type: "common",
  },
  {
    label: "LA FLAMENGRIE",
    value: "02312",
    type: "common",
  },
  {
    label: "CLAIRFONTAINE",
    value: "02197",
    type: "common",
  },
  {
    label: "LA CAPELLE",
    value: "02141",
    type: "common",
  },
  {
    label: "ROCQUIGNY",
    value: "02650",
    type: "common",
  },
  {
    label: "PAPLEUX",
    value: "02584",
    type: "common",
  },
  {
    label: "ESQUEHERIES",
    value: "02286",
    type: "common",
  },
  {
    label: "LE NOUVION-EN-THIERACHE",
    value: "02558",
    type: "common",
  },
  {
    label: "ETREUX",
    value: "02298",
    type: "common",
  },
  {
    label: "BOUE",
    value: "02103",
    type: "common",
  },
  {
    label: "OISY",
    value: "02569",
    type: "common",
  },
  {
    label: "LA NEUVILLE-LES-DORENGT",
    value: "02548",
    type: "common",
  },
  {
    label: "MENNEVRET",
    value: "02476",
    type: "common",
  },
  {
    label: "HANNAPES",
    value: "02366",
    type: "common",
  },
  {
    label: "WASSIGNY",
    value: "02830",
    type: "common",
  },
  {
    label: "VENEROLLES",
    value: "02779",
    type: "common",
  },
  {
    label: "LA VALLEE-MULATRE",
    value: "02760",
    type: "common",
  },
  {
    label: "BOHAIN-EN-VERMANDOIS",
    value: "02095",
    type: "common",
  },
  {
    label: "BECQUIGNY",
    value: "02061",
    type: "common",
  },
  {
    label: "BUSIGNY",
    value: "59118",
    type: "common",
  },
  {
    label: "VAUX-ANDIGNY",
    value: "02769",
    type: "common",
  },
  {
    label: "BRANCOURT-LE-GRAND",
    value: "02112",
    type: "common",
  },
  {
    label: "PREMONT",
    value: "02618",
    type: "common",
  },
  {
    label: "BEAUREVOIR",
    value: "02057",
    type: "common",
  },
  {
    label: "VILLERS-OUTREAUX",
    value: "59624",
    type: "common",
  },
  {
    label: "SERAIN",
    value: "02709",
    type: "common",
  },
  {
    label: "GOUY",
    value: "02352",
    type: "common",
  },
  {
    label: "LE CATELET",
    value: "02143",
    type: "common",
  },
  {
    label: "BONY",
    value: "02100",
    type: "common",
  },
  {
    label: "AUBENCHEUL-AUX-BOIS",
    value: "02030",
    type: "common",
  },
  {
    label: "VENDHUILE",
    value: "02776",
    type: "common",
  },
  {
    label: "LEMPIRE",
    value: "02417",
    type: "common",
  },
  {
    label: "VILLERS-FAUCON",
    value: "80802",
    type: "common",
  },
  {
    label: "RONSSOY",
    value: "80679",
    type: "common",
  },
  {
    label: "HEUDICOURT",
    value: "80438",
    type: "common",
  },
  {
    label: "EPEHY",
    value: "80271",
    type: "common",
  },
  {
    label: "SOREL",
    value: "80737",
    type: "common",
  },
  {
    label: "NURLU",
    value: "80601",
    type: "common",
  },
  {
    label: "LIERAMONT",
    value: "80475",
    type: "common",
  },
  {
    label: "GUYENCOURT-SAULCOURT",
    value: "80404",
    type: "common",
  },
  {
    label: "AIZECOURT-LE-BAS",
    value: "80014",
    type: "common",
  },
  {
    label: "SAILLY-SAILLISEL",
    value: "80695",
    type: "common",
  },
  {
    label: "MOISLAINS",
    value: "80552",
    type: "common",
  },
  {
    label: "ETRICOURT-MANANCOURT",
    value: "80298",
    type: "common",
  },
  {
    label: "BOUCHAVESNES-BERGEN",
    value: "80115",
    type: "common",
  },
  {
    label: "RANCOURT",
    value: "80664",
    type: "common",
  },
  {
    label: "MAUREPAS",
    value: "80521",
    type: "common",
  },
  {
    label: "GUILLEMONT",
    value: "80401",
    type: "common",
  },
  {
    label: "GINCHY",
    value: "80378",
    type: "common",
  },
  {
    label: "COMBLES",
    value: "80204",
    type: "common",
  },
  {
    label: "MORVAL",
    value: "62593",
    type: "common",
  },
  {
    label: "MONTAUBAN-DE-PICARDIE",
    value: "80560",
    type: "common",
  },
  {
    label: "MARICOURT",
    value: "80513",
    type: "common",
  },
  {
    label: "LONGUEVAL",
    value: "80490",
    type: "common",
  },
  {
    label: "HARDECOURT-AUX-BOIS",
    value: "80418",
    type: "common",
  },
  {
    label: "CONTALMAISON",
    value: "80206",
    type: "common",
  },
  {
    label: "BAZENTIN",
    value: "80059",
    type: "common",
  },
  {
    label: "OVILLERS-LA-BOISSELLE",
    value: "80615",
    type: "common",
  },
  {
    label: "FRICOURT",
    value: "80366",
    type: "common",
  },
  {
    label: "BECORDEL-BECOURT",
    value: "80073",
    type: "common",
  },
  {
    label: "AVELUY",
    value: "80047",
    type: "common",
  },
  {
    label: "ALBERT",
    value: "80016",
    type: "common",
  },
  {
    label: "SENLIS-LE-SEC",
    value: "80733",
    type: "common",
  },
  {
    label: "MILLENCOURT",
    value: "80547",
    type: "common",
  },
  {
    label: "LAVIEVILLE",
    value: "80468",
    type: "common",
  },
  {
    label: "HENENCOURT",
    value: "80429",
    type: "common",
  },
  {
    label: "DERNANCOURT",
    value: "80238",
    type: "common",
  },
  {
    label: "BRESLE",
    value: "80138",
    type: "common",
  },
  {
    label: "BOUZINCOURT",
    value: "80129",
    type: "common",
  },
  {
    label: "WARLOY-BAILLON",
    value: "80820",
    type: "common",
  },
  {
    label: "VADENCOURT",
    value: "80773",
    type: "common",
  },
  {
    label: "HARPONVILLE",
    value: "80420",
    type: "common",
  },
  {
    label: "CONTAY",
    value: "80207",
    type: "common",
  },
  {
    label: "BAIZIEUX",
    value: "80052",
    type: "common",
  },
  {
    label: "RUBEMPRE",
    value: "80686",
    type: "common",
  },
  {
    label: "PIERREGOT",
    value: "80624",
    type: "common",
  },
  {
    label: "MONTIGNY-SUR-L'HALLUE",
    value: "80562",
    type: "common",
  },
  {
    label: "MOLLIENS-AU-BOIS",
    value: "80553",
    type: "common",
  },
  {
    label: "MIRVAUX",
    value: "80550",
    type: "common",
  },
  {
    label: "HERISSART",
    value: "80431",
    type: "common",
  },
  {
    label: "BEAUCOURT-SUR-L'HALLUE",
    value: "80066",
    type: "common",
  },
  {
    label: "BAVELINCOURT",
    value: "80056",
    type: "common",
  },
  {
    label: "VILLERS-BOCAGE",
    value: "80798",
    type: "common",
  },
  {
    label: "MONTONVILLERS",
    value: "80565",
    type: "common",
  },
  {
    label: "VIGNACOURT",
    value: "80793",
    type: "common",
  },
  {
    label: "HAVERNAS",
    value: "80423",
    type: "common",
  },
  {
    label: "FLESSELLES",
    value: "80316",
    type: "common",
  },
  {
    label: "YZEUX",
    value: "80835",
    type: "common",
  },
  {
    label: "SAINT-OUEN",
    value: "80711",
    type: "common",
  },
  {
    label: "BETTENCOURT-SAINT-OUEN",
    value: "80100",
    type: "common",
  },
  {
    label: "BELLOY-SUR-SOMME",
    value: "80082",
    type: "common",
  },
  {
    label: "FLIXECOURT",
    value: "80318",
    type: "common",
  },
  {
    label: "L'ETOILE",
    value: "80296",
    type: "common",
  },
  {
    label: "CONDE-FOLIE",
    value: "80205",
    type: "common",
  },
  {
    label: "BOURDON",
    value: "80123",
    type: "common",
  },
  {
    label: "SOREL-EN-VIMEU",
    value: "80736",
    type: "common",
  },
  {
    label: "LONGPRE-LES-CORPS-SAINTS",
    value: "80488",
    type: "common",
  },
  {
    label: "FONTAINE-SUR-SOMME",
    value: "80328",
    type: "common",
  },
  {
    label: "BETTENCOURT-RIVIERE",
    value: "80099",
    type: "common",
  },
  {
    label: "LIERCOURT",
    value: "80476",
    type: "common",
  },
  {
    label: "HALLENCOURT",
    value: "80406",
    type: "common",
  },
  {
    label: "CITERNE",
    value: "80196",
    type: "common",
  },
  {
    label: "BAILLEUL",
    value: "80051",
    type: "common",
  },
  {
    label: "VAUX-MARQUENNEVILLE",
    value: "80783",
    type: "common",
  },
  {
    label: "SAINT-MAXENT",
    value: "80710",
    type: "common",
  },
  {
    label: "LIMEUX",
    value: "80482",
    type: "common",
  },
  {
    label: "HUPPY",
    value: "80446",
    type: "common",
  },
  {
    label: "FRUCOURT",
    value: "80372",
    type: "common",
  },
  {
    label: "FRESNES-TILLOLOY",
    value: "80354",
    type: "common",
  },
  {
    label: "DOUDELAINVILLE",
    value: "80251",
    type: "common",
  },
  {
    label: "VISMES",
    value: "80809",
    type: "common",
  },
  {
    label: "MARTAINNEVILLE",
    value: "80518",
    type: "common",
  },
  {
    label: "GREBAULT-MESNIL",
    value: "80388",
    type: "common",
  },
  {
    label: "FRETTEMEULE",
    value: "80362",
    type: "common",
  },
  {
    label: "TILLOY-FLORIVILLE",
    value: "80760",
    type: "common",
  },
  {
    label: "MAISNIERES",
    value: "80500",
    type: "common",
  },
  {
    label: "GAMACHES",
    value: "80373",
    type: "common",
  },
  {
    label: "EMBREVILLE",
    value: "80265",
    type: "common",
  },
  {
    label: "BUIGNY-LES-GAMACHES",
    value: "80148",
    type: "common",
  },
  {
    label: "BEAUCHAMPS",
    value: "80063",
    type: "common",
  },
  {
    label: "MONCHY-SUR-EU",
    value: "76442",
    type: "common",
  },
  {
    label: "MILLEBOSC",
    value: "76438",
    type: "common",
  },
  {
    label: "LONGROY",
    value: "76394",
    type: "common",
  },
  {
    label: "INCHEVILLE",
    value: "76374",
    type: "common",
  },
  {
    label: "SAINT-REMY-BOSCROCOURT",
    value: "76644",
    type: "common",
  },
  {
    label: "SAINT-PIERRE-EN-VAL",
    value: "76638",
    type: "common",
  },
  {
    label: "SAINT-MARTIN-LE-GAILLARD",
    value: "76619",
    type: "common",
  },
  {
    label: "EU",
    value: "76255",
    type: "common",
  },
  {
    label: "ETALONDES",
    value: "76252",
    type: "common",
  },
  {
    label: "BAROMESNIL",
    value: "76058",
    type: "common",
  },
  {
    label: "TOUFFREVILLE-SUR-EU",
    value: "76703",
    type: "common",
  },
  {
    label: "CRIEL-SUR-MER",
    value: "76192",
    type: "common",
  },
  {
    label: "CANEHAN",
    value: "76155",
    type: "common",
  },
  {
    label: "LES HAUTES-RIVIERES",
    value: "08218",
    type: "common",
  },
  {
    label: "THILAY",
    value: "08448",
    type: "common",
  },
  {
    label: "MONTHERME",
    value: "08302",
    type: "common",
  },
  {
    label: "ANCHAMPS",
    value: "08011",
    type: "common",
  },
  {
    label: "REVIN",
    value: "08363",
    type: "common",
  },
  {
    label: "LAIFOUR",
    value: "08242",
    type: "common",
  },
  {
    label: "ROCROI",
    value: "08367",
    type: "common",
  },
  {
    label: "LES MAZURES",
    value: "08284",
    type: "common",
  },
  {
    label: "TAILLETTE",
    value: "08436",
    type: "common",
  },
  {
    label: "GUE-D'HOSSUS",
    value: "08202",
    type: "common",
  },
  {
    label: "REGNIOWEZ",
    value: "08355",
    type: "common",
  },
  {
    label: "NEUVILLE-LEZ-BEAULIEU",
    value: "08319",
    type: "common",
  },
  {
    label: "SIGNY-LE-PETIT",
    value: "08420",
    type: "common",
  },
  {
    label: "BROGNON",
    value: "08087",
    type: "common",
  },
  {
    label: "LA NEUVILLE-AUX-JOUTES",
    value: "08318",
    type: "common",
  },
  {
    label: "WATIGNY",
    value: "02831",
    type: "common",
  },
  {
    label: "HIRSON",
    value: "02381",
    type: "common",
  },
  {
    label: "SAINT-MICHEL",
    value: "02684",
    type: "common",
  },
  {
    label: "BUIRE",
    value: "02134",
    type: "common",
  },
  {
    label: "OHIS",
    value: "02567",
    type: "common",
  },
  {
    label: "NEUVE-MAISON",
    value: "02544",
    type: "common",
  },
  {
    label: "MONDREPUIS",
    value: "02495",
    type: "common",
  },
  {
    label: "LUZOIR",
    value: "02445",
    type: "common",
  },
  {
    label: "GERGNY",
    value: "02342",
    type: "common",
  },
  {
    label: "EFFRY",
    value: "02275",
    type: "common",
  },
  {
    label: "WIMY",
    value: "02833",
    type: "common",
  },
  {
    label: "SOMMERON",
    value: "02725",
    type: "common",
  },
  {
    label: "LERZY",
    value: "02418",
    type: "common",
  },
  {
    label: "FROIDESTREES",
    value: "02337",
    type: "common",
  },
  {
    label: "ERLOY",
    value: "02284",
    type: "common",
  },
  {
    label: "BUIRONFOSSE",
    value: "02135",
    type: "common",
  },
  {
    label: "SORBAIS",
    value: "02728",
    type: "common",
  },
  {
    label: "LESCHELLE",
    value: "02419",
    type: "common",
  },
  {
    label: "ENGLANCOURT",
    value: "02276",
    type: "common",
  },
  {
    label: "CRUPILLY",
    value: "02244",
    type: "common",
  },
  {
    label: "CHIGNY",
    value: "02188",
    type: "common",
  },
  {
    label: "MALZY",
    value: "02455",
    type: "common",
  },
  {
    label: "LAVAQUERESSE",
    value: "02414",
    type: "common",
  },
  {
    label: "IRON",
    value: "02386",
    type: "common",
  },
  {
    label: "DORENGT",
    value: "02269",
    type: "common",
  },
  {
    label: "VILLERS-LES-GUISE",
    value: "02814",
    type: "common",
  },
  {
    label: "LESQUIELLES-SAINT-GERMAIN",
    value: "02422",
    type: "common",
  },
  {
    label: "PETIT-VERLY",
    value: "02784",
    type: "common",
  },
  {
    label: "GRAND-VERLY",
    value: "02783",
    type: "common",
  },
  {
    label: "VADENCOURT",
    value: "02757",
    type: "common",
  },
  {
    label: "TUPIGNY",
    value: "02753",
    type: "common",
  },
  {
    label: "GROUGIS",
    value: "02358",
    type: "common",
  },
  {
    label: "AISONVILLE-ET-BERNOVILLE",
    value: "02006",
    type: "common",
  },
  {
    label: "SEBONCOURT",
    value: "02703",
    type: "common",
  },
  {
    label: "FRESNOY-LE-GRAND",
    value: "02334",
    type: "common",
  },
  {
    label: "FONTAINE-UTERTE",
    value: "02323",
    type: "common",
  },
  {
    label: "ETAVES-ET-BOCQUIAUX",
    value: "02293",
    type: "common",
  },
  {
    label: "CROIX-FONSOMME",
    value: "02240",
    type: "common",
  },
  {
    label: "MAGNY-LA-FOSSE",
    value: "02451",
    type: "common",
  },
  {
    label: "LEVERGIES",
    value: "02426",
    type: "common",
  },
  {
    label: "JONCOURT",
    value: "02392",
    type: "common",
  },
  {
    label: "ESTREES",
    value: "02291",
    type: "common",
  },
  {
    label: "SEQUEHART",
    value: "02708",
    type: "common",
  },
  {
    label: "RAMICOURT",
    value: "02635",
    type: "common",
  },
  {
    label: "MONTBREHAIN",
    value: "02500",
    type: "common",
  },
  {
    label: "HARGICOURT",
    value: "02370",
    type: "common",
  },
  {
    label: "BELLICOURT",
    value: "02065",
    type: "common",
  },
  {
    label: "BELLENGLISE",
    value: "02063",
    type: "common",
  },
  {
    label: "VILLERET",
    value: "02808",
    type: "common",
  },
  {
    label: "PONTRU",
    value: "02614",
    type: "common",
  },
  {
    label: "NAUROY",
    value: "02539",
    type: "common",
  },
  {
    label: "JEANCOURT",
    value: "02390",
    type: "common",
  },
  {
    label: "TEMPLEUX-LE-GUERARD",
    value: "80748",
    type: "common",
  },
  {
    label: "ROISEL",
    value: "80677",
    type: "common",
  },
  {
    label: "HESBECOURT",
    value: "80435",
    type: "common",
  },
  {
    label: "HERVILLY",
    value: "80434",
    type: "common",
  },
  {
    label: "LE VERGUIER",
    value: "02782",
    type: "common",
  },
  {
    label: "TINCOURT-BOUCLY",
    value: "80762",
    type: "common",
  },
  {
    label: "TEMPLEUX-LA-FOSSE",
    value: "80747",
    type: "common",
  },
  {
    label: "MARQUAIX",
    value: "80516",
    type: "common",
  },
  {
    label: "LONGAVESNES",
    value: "80487",
    type: "common",
  },
  {
    label: "DRIENCOURT",
    value: "80258",
    type: "common",
  },
  {
    label: "BUIRE-COURCELLES",
    value: "80150",
    type: "common",
  },
  {
    label: "PERONNE",
    value: "80620",
    type: "common",
  },
  {
    label: "DOINGT",
    value: "80240",
    type: "common",
  },
  {
    label: "BUSSU",
    value: "80154",
    type: "common",
  },
  {
    label: "ALLAINES",
    value: "80017",
    type: "common",
  },
  {
    label: "AIZECOURT-LE-HAUT",
    value: "80015",
    type: "common",
  },
  {
    label: "HERBECOURT",
    value: "80430",
    type: "common",
  },
  {
    label: "HEM-MONACU",
    value: "80428",
    type: "common",
  },
  {
    label: "FEUILLERES",
    value: "80307",
    type: "common",
  },
  {
    label: "CLERY-SUR-SOMME",
    value: "80199",
    type: "common",
  },
  {
    label: "BIACHES",
    value: "80102",
    type: "common",
  },
  {
    label: "SUZANNE",
    value: "80743",
    type: "common",
  },
  {
    label: "FRISE",
    value: "80367",
    type: "common",
  },
  {
    label: "ECLUSIER-VAUX",
    value: "80264",
    type: "common",
  },
  {
    label: "CURLU",
    value: "80231",
    type: "common",
  },
  {
    label: "CAPPY",
    value: "80172",
    type: "common",
  },
  {
    label: "LA NEUVILLE-LES-BRAY",
    value: "80593",
    type: "common",
  },
  {
    label: "MORLANCOURT",
    value: "80572",
    type: "common",
  },
  {
    label: "MEAULTE",
    value: "80523",
    type: "common",
  },
  {
    label: "ETINEHEM-MERICOURT",
    value: "80295",
    type: "common",
  },
  {
    label: "BRAY-SUR-SOMME",
    value: "80136",
    type: "common",
  },
  {
    label: "VILLE-SUR-ANCRE",
    value: "80807",
    type: "common",
  },
  {
    label: "VAUX-SUR-SOMME",
    value: "80784",
    type: "common",
  },
  {
    label: "TREUX",
    value: "80769",
    type: "common",
  },
  {
    label: "SAILLY-LE-SEC",
    value: "80694",
    type: "common",
  },
  {
    label: "SAILLY-LAURETTE",
    value: "80693",
    type: "common",
  },
  {
    label: "RIBEMONT-SUR-ANCRE",
    value: "80672",
    type: "common",
  },
  {
    label: "MERICOURT-L'ABBE",
    value: "80530",
    type: "common",
  },
  {
    label: "BUIRE-SUR-L'ANCRE",
    value: "80151",
    type: "common",
  },
  {
    label: "PONT-NOYELLES",
    value: "80634",
    type: "common",
  },
  {
    label: "LAHOUSSOYE",
    value: "80458",
    type: "common",
  },
  {
    label: "HEILLY",
    value: "80426",
    type: "common",
  },
  {
    label: "FRANVILLERS",
    value: "80350",
    type: "common",
  },
  {
    label: "CORBIE",
    value: "80212",
    type: "common",
  },
  {
    label: "BONNAY",
    value: "80112",
    type: "common",
  },
  {
    label: "BEHENCOURT",
    value: "80077",
    type: "common",
  },
  {
    label: "SAINT-GRATIEN",
    value: "80704",
    type: "common",
  },
  {
    label: "RAINNEVILLE",
    value: "80661",
    type: "common",
  },
  {
    label: "QUERRIEU",
    value: "80650",
    type: "common",
  },
  {
    label: "FRECHENCOURT",
    value: "80351",
    type: "common",
  },
  {
    label: "POULAINVILLE",
    value: "80639",
    type: "common",
  },
  {
    label: "COISY",
    value: "80202",
    type: "common",
  },
  {
    label: "CARDONNETTE",
    value: "80173",
    type: "common",
  },
  {
    label: "BERTANGLES",
    value: "80092",
    type: "common",
  },
  {
    label: "ALLONVILLE",
    value: "80020",
    type: "common",
  },
  {
    label: "VAUX-EN-AMIENOIS",
    value: "80782",
    type: "common",
  },
  {
    label: "SAINT-VAAST-EN-CHAUSSEE",
    value: "80722",
    type: "common",
  },
  {
    label: "SAINT-SAUVEUR",
    value: "80718",
    type: "common",
  },
  {
    label: "ARGŒUVES",
    value: "80024",
    type: "common",
  },
  {
    label: "PICQUIGNY",
    value: "80622",
    type: "common",
  },
  {
    label: "FOURDRINOY",
    value: "80341",
    type: "common",
  },
  {
    label: "CROUY-SAINT-PIERRE",
    value: "80229",
    type: "common",
  },
  {
    label: "LA CHAUSSEE-TIRANCOURT",
    value: "80187",
    type: "common",
  },
  {
    label: "BREILLY",
    value: "80137",
    type: "common",
  },
  {
    label: "SOUES",
    value: "80738",
    type: "common",
  },
  {
    label: "RIENCOURT",
    value: "80673",
    type: "common",
  },
  {
    label: "LE MESGE",
    value: "80535",
    type: "common",
  },
  {
    label: "HANGEST-SUR-SOMME",
    value: "80416",
    type: "common",
  },
  {
    label: "TAILLY",
    value: "80744",
    type: "common",
  },
  {
    label: "QUESNOY-SUR-AIRAINES",
    value: "80655",
    type: "common",
  },
  {
    label: "METIGNY",
    value: "80543",
    type: "common",
  },
  {
    label: "LALEU",
    value: "80459",
    type: "common",
  },
  {
    label: "AIRAINES",
    value: "80013",
    type: "common",
  },
  {
    label: "WOIREL",
    value: "80828",
    type: "common",
  },
  {
    label: "WIRY-AU-MONT",
    value: "80825",
    type: "common",
  },
  {
    label: "VERGIES",
    value: "80788",
    type: "common",
  },
  {
    label: "MERELESSART",
    value: "80529",
    type: "common",
  },
  {
    label: "HEUCOURT-CROQUOISON",
    value: "80437",
    type: "common",
  },
  {
    label: "ALLERY",
    value: "80019",
    type: "common",
  },
  {
    label: "VILLEROY",
    value: "80796",
    type: "common",
  },
  {
    label: "OISEMONT",
    value: "80606",
    type: "common",
  },
  {
    label: "NEUVILLE-AU-BOIS",
    value: "80591",
    type: "common",
  },
  {
    label: "MOUFLIERES",
    value: "80575",
    type: "common",
  },
  {
    label: "FRETTECUISSE",
    value: "80361",
    type: "common",
  },
  {
    label: "FORCEVILLE-EN-VIMEU",
    value: "80330",
    type: "common",
  },
  {
    label: "FONTAINE-LE-SEC",
    value: "80324",
    type: "common",
  },
  {
    label: "CERISY-BULEUX",
    value: "80183",
    type: "common",
  },
  {
    label: "CANNESSIERES",
    value: "80169",
    type: "common",
  },
  {
    label: "AUMATRE",
    value: "80040",
    type: "common",
  },
  {
    label: "LE TRANSLAY",
    value: "80767",
    type: "common",
  },
  {
    label: "RAMBURES",
    value: "80663",
    type: "common",
  },
  {
    label: "RAMBURELLES",
    value: "80662",
    type: "common",
  },
  {
    label: "NESLETTE",
    value: "80587",
    type: "common",
  },
  {
    label: "FRAMICOURT",
    value: "80343",
    type: "common",
  },
  {
    label: "BIENCOURT",
    value: "80104",
    type: "common",
  },
  {
    label: "BOUTTENCOURT",
    value: "80126",
    type: "common",
  },
  {
    label: "BOUILLANCOURT-EN-SERY",
    value: "80120",
    type: "common",
  },
  {
    label: "RIEUX",
    value: "76528",
    type: "common",
  },
  {
    label: "MONCHAUX-SORENG",
    value: "76441",
    type: "common",
  },
  {
    label: "BLANGY-SUR-BRESLE",
    value: "76101",
    type: "common",
  },
  {
    label: "BAZINVAL",
    value: "76059",
    type: "common",
  },
  {
    label: "VILLY-SUR-YERES",
    value: "76745",
    type: "common",
  },
  {
    label: "MELLEVILLE",
    value: "76422",
    type: "common",
  },
  {
    label: "GUERVILLE",
    value: "76333",
    type: "common",
  },
  {
    label: "GRANDCOURT",
    value: "76320",
    type: "common",
  },
  {
    label: "SEPT-MEULES",
    value: "76671",
    type: "common",
  },
  {
    label: "LE MESNIL-REAUME",
    value: "76435",
    type: "common",
  },
  {
    label: "CUVERVILLE-SUR-YERES",
    value: "76207",
    type: "common",
  },
  {
    label: "AVESNES-EN-VAL",
    value: "76049",
    type: "common",
  },
  {
    label: "SAUCHAY",
    value: "76665",
    type: "common",
  },
  {
    label: "PETIT-CAUX",
    value: "76618",
    type: "common",
  },
  {
    label: "GREGES",
    value: "76324",
    type: "common",
  },
  {
    label: "DIEPPE",
    value: "76217",
    type: "common",
  },
  {
    label: "BOGNY-SUR-MEUSE",
    value: "08081",
    type: "common",
  },
  {
    label: "TOURNAVAUX",
    value: "08456",
    type: "common",
  },
  {
    label: "HAULME",
    value: "08217",
    type: "common",
  },
  {
    label: "SECHEVAL",
    value: "08408",
    type: "common",
  },
  {
    label: "DEVILLE",
    value: "08139",
    type: "common",
  },
  {
    label: "BOURG-FIDELE",
    value: "08078",
    type: "common",
  },
  {
    label: "RENWEZ",
    value: "08361",
    type: "common",
  },
  {
    label: "HARCY",
    value: "08212",
    type: "common",
  },
  {
    label: "LE CHATELET-SUR-SORMONNE",
    value: "08110",
    type: "common",
  },
  {
    label: "TREMBLOIS-LES-ROCROI",
    value: "08460",
    type: "common",
  },
  {
    label: "SEVIGNY-LA-FORET",
    value: "08417",
    type: "common",
  },
  {
    label: "CHILLY",
    value: "08121",
    type: "common",
  },
  {
    label: "ETALLE",
    value: "08155",
    type: "common",
  },
  {
    label: "MAUBERT-FONTAINE",
    value: "08282",
    type: "common",
  },
  {
    label: "AUVILLERS-LES-FORGES",
    value: "08037",
    type: "common",
  },
  {
    label: "ETEIGNIERES",
    value: "08156",
    type: "common",
  },
  {
    label: "GIRONDELLE",
    value: "08189",
    type: "common",
  },
  {
    label: "TARZY",
    value: "08440",
    type: "common",
  },
  {
    label: "FLIGNY",
    value: "08172",
    type: "common",
  },
  {
    label: "AUGE",
    value: "08030",
    type: "common",
  },
  {
    label: "ANY-MARTIN-RIEUX",
    value: "02020",
    type: "common",
  },
  {
    label: "MARTIGNY",
    value: "02470",
    type: "common",
  },
  {
    label: "LEUZE",
    value: "02425",
    type: "common",
  },
  {
    label: "BUCILLY",
    value: "02130",
    type: "common",
  },
  {
    label: "LANDOUZY-LA-VILLE",
    value: "02405",
    type: "common",
  },
  {
    label: "LA HERIE",
    value: "02378",
    type: "common",
  },
  {
    label: "EPARCY",
    value: "02278",
    type: "common",
  },
  {
    label: "ORIGNY-EN-THIERACHE",
    value: "02574",
    type: "common",
  },
  {
    label: "FONTAINE-LES-VERVINS",
    value: "02321",
    type: "common",
  },
  {
    label: "ETREAUPONT",
    value: "02295",
    type: "common",
  },
  {
    label: "LA BOUTEILLE",
    value: "02109",
    type: "common",
  },
  {
    label: "LAIGNY",
    value: "02401",
    type: "common",
  },
  {
    label: "HAUTION",
    value: "02377",
    type: "common",
  },
  {
    label: "AUTREPPES",
    value: "02040",
    type: "common",
  },
  {
    label: "SAINT-ALGIS",
    value: "02670",
    type: "common",
  },
  {
    label: "MARLY-GOMONT",
    value: "02469",
    type: "common",
  },
  {
    label: "LA VALLEE-AU-BLE",
    value: "02759",
    type: "common",
  },
  {
    label: "LE SOURD",
    value: "02731",
    type: "common",
  },
  {
    label: "ROMERY",
    value: "02654",
    type: "common",
  },
  {
    label: "PROISY",
    value: "02624",
    type: "common",
  },
  {
    label: "FLAVIGNY-LE-GRAND-ET-BEAURAIN",
    value: "02313",
    type: "common",
  },
  {
    label: "COLONFAY",
    value: "02206",
    type: "common",
  },
  {
    label: "AUDIGNY",
    value: "02035",
    type: "common",
  },
  {
    label: "WIEGE-FATY",
    value: "02832",
    type: "common",
  },
  {
    label: "MONCEAU-SUR-OISE",
    value: "02494",
    type: "common",
  },
  {
    label: "MACQUIGNY",
    value: "02450",
    type: "common",
  },
  {
    label: "GUISE",
    value: "02361",
    type: "common",
  },
  {
    label: "PROIX",
    value: "02625",
    type: "common",
  },
  {
    label: "HAUTEVILLE",
    value: "02376",
    type: "common",
  },
  {
    label: "BERNOT",
    value: "02070",
    type: "common",
  },
  {
    label: "NOYALES",
    value: "02563",
    type: "common",
  },
  {
    label: "NEUVILLETTE",
    value: "02552",
    type: "common",
  },
  {
    label: "MONTIGNY-EN-ARROUAISE",
    value: "02511",
    type: "common",
  },
  {
    label: "HOMBLIERES",
    value: "02383",
    type: "common",
  },
  {
    label: "FONTAINE-NOTRE-DAME",
    value: "02322",
    type: "common",
  },
  {
    label: "FONSOMME",
    value: "02319",
    type: "common",
  },
  {
    label: "FIEULAINE",
    value: "02310",
    type: "common",
  },
  {
    label: "ESSIGNY-LE-PETIT",
    value: "02288",
    type: "common",
  },
  {
    label: "LESDINS",
    value: "02420",
    type: "common",
  },
  {
    label: "LEHAUCOURT",
    value: "02374",
    type: "common",
  },
  {
    label: "ROUVROY",
    value: "02659",
    type: "common",
  },
  {
    label: "REMAUCOURT",
    value: "02637",
    type: "common",
  },
  {
    label: "OMISSY",
    value: "02571",
    type: "common",
  },
  {
    label: "MORCOURT",
    value: "02525",
    type: "common",
  },
  {
    label: "MAISSEMY",
    value: "02452",
    type: "common",
  },
  {
    label: "HOLNON",
    value: "02382",
    type: "common",
  },
  {
    label: "GRICOURT",
    value: "02355",
    type: "common",
  },
  {
    label: "FAYET",
    value: "02303",
    type: "common",
  },
  {
    label: "PONTRUET",
    value: "02615",
    type: "common",
  },
  {
    label: "CAULAINCOURT",
    value: "02144",
    type: "common",
  },
  {
    label: "PŒUILLY",
    value: "80629",
    type: "common",
  },
  {
    label: "BERNES",
    value: "80088",
    type: "common",
  },
  {
    label: "VERMAND",
    value: "02785",
    type: "common",
  },
  {
    label: "VENDELLES",
    value: "02774",
    type: "common",
  },
  {
    label: "VRAIGNES-EN-VERMANDOIS",
    value: "80812",
    type: "common",
  },
  {
    label: "TERTRY",
    value: "80750",
    type: "common",
  },
  {
    label: "ESTREES-MONS",
    value: "80557",
    type: "common",
  },
  {
    label: "HANCOURT",
    value: "80413",
    type: "common",
  },
  {
    label: "CARTIGNY",
    value: "80177",
    type: "common",
  },
  {
    label: "BOUVINCOURT-EN-VERMANDOIS",
    value: "80128",
    type: "common",
  },
  {
    label: "MESNIL-BRUNTEL",
    value: "80536",
    type: "common",
  },
  {
    label: "ETERPIGNY",
    value: "80294",
    type: "common",
  },
  {
    label: "BRIE",
    value: "80141",
    type: "common",
  },
  {
    label: "VILLERS-CARBONNEL",
    value: "80801",
    type: "common",
  },
  {
    label: "FLAUCOURT",
    value: "80313",
    type: "common",
  },
  {
    label: "ESTREES-DENIECOURT",
    value: "80288",
    type: "common",
  },
  {
    label: "BERNY-EN-SANTERRE",
    value: "80090",
    type: "common",
  },
  {
    label: "BELLOY-EN-SANTERRE",
    value: "80080",
    type: "common",
  },
  {
    label: "BARLEUX",
    value: "80054",
    type: "common",
  },
  {
    label: "ASSEVILLERS",
    value: "80033",
    type: "common",
  },
  {
    label: "SOYECOURT",
    value: "80741",
    type: "common",
  },
  {
    label: "FOUCAUCOURT-EN-SANTERRE",
    value: "80335",
    type: "common",
  },
  {
    label: "FONTAINE-LES-CAPPY",
    value: "80325",
    type: "common",
  },
  {
    label: "FAY",
    value: "80304",
    type: "common",
  },
  {
    label: "DOMPIERRE-BECQUINCOURT",
    value: "80247",
    type: "common",
  },
  {
    label: "CHUIGNES",
    value: "80194",
    type: "common",
  },
  {
    label: "PROYART",
    value: "80644",
    type: "common",
  },
  {
    label: "MORCOURT",
    value: "80569",
    type: "common",
  },
  {
    label: "FRAMERVILLE-RAINECOURT",
    value: "80342",
    type: "common",
  },
  {
    label: "CHUIGNOLLES",
    value: "80195",
    type: "common",
  },
  {
    label: "CHIPILLY",
    value: "80192",
    type: "common",
  },
  {
    label: "VAIRE-SOUS-CORBIE",
    value: "80774",
    type: "common",
  },
  {
    label: "LAMOTTE-WARFUSEE",
    value: "80463",
    type: "common",
  },
  {
    label: "LE HAMEL",
    value: "80411",
    type: "common",
  },
  {
    label: "CERISY",
    value: "80184",
    type: "common",
  },
  {
    label: "BAYONVILLERS",
    value: "80058",
    type: "common",
  },
  {
    label: "VILLERS-BRETONNEUX",
    value: "80799",
    type: "common",
  },
  {
    label: "HAMELET",
    value: "80412",
    type: "common",
  },
  {
    label: "FOUILLOY",
    value: "80338",
    type: "common",
  },
  {
    label: "AUBIGNY",
    value: "80036",
    type: "common",
  },
  {
    label: "VECQUEMONT",
    value: "80785",
    type: "common",
  },
  {
    label: "LAMOTTE-BREBIERE",
    value: "80461",
    type: "common",
  },
  {
    label: "GLISY",
    value: "80379",
    type: "common",
  },
  {
    label: "DAOURS",
    value: "80234",
    type: "common",
  },
  {
    label: "BUSSY-LES-DAOURS",
    value: "80156",
    type: "common",
  },
  {
    label: "BLANGY-TRONVILLE",
    value: "80107",
    type: "common",
  },
  {
    label: "RIVERY",
    value: "80674",
    type: "common",
  },
  {
    label: "LONGUEAU",
    value: "80489",
    type: "common",
  },
  {
    label: "CAMON",
    value: "80164",
    type: "common",
  },
  {
    label: "AMIENS",
    value: "80021",
    type: "common",
  },
  {
    label: "SAVEUSE",
    value: "80730",
    type: "common",
  },
  {
    label: "SALOUEL",
    value: "80725",
    type: "common",
  },
  {
    label: "SALEUX",
    value: "80724",
    type: "common",
  },
  {
    label: "PONT-DE-METZ",
    value: "80632",
    type: "common",
  },
  {
    label: "DREUIL-LES-AMIENS",
    value: "80256",
    type: "common",
  },
  {
    label: "AILLY-SUR-SOMME",
    value: "80011",
    type: "common",
  },
  {
    label: "SEUX",
    value: "80735",
    type: "common",
  },
  {
    label: "SAISSEVAL",
    value: "80723",
    type: "common",
  },
  {
    label: "PISSY",
    value: "80626",
    type: "common",
  },
  {
    label: "GUIGNEMICOURT",
    value: "80399",
    type: "common",
  },
  {
    label: "FERRIERES",
    value: "80305",
    type: "common",
  },
  {
    label: "BOVELLES",
    value: "80130",
    type: "common",
  },
  {
    label: "OISSY",
    value: "80607",
    type: "common",
  },
  {
    label: "MOLLIENS-DREUIL",
    value: "80554",
    type: "common",
  },
  {
    label: "CAVILLON",
    value: "80180",
    type: "common",
  },
  {
    label: "BRIQUEMESNIL-FLOXICOURT",
    value: "80142",
    type: "common",
  },
  {
    label: "BOUGAINVILLE",
    value: "80119",
    type: "common",
  },
  {
    label: "WARLUS",
    value: "80821",
    type: "common",
  },
  {
    label: "MONTAGNE-FAYEL",
    value: "80559",
    type: "common",
  },
  {
    label: "MERICOURT-EN-VIMEU",
    value: "80531",
    type: "common",
  },
  {
    label: "CAMPS-EN-AMIENOIS",
    value: "80165",
    type: "common",
  },
  {
    label: "BELLOY-SAINT-LEONARD",
    value: "80081",
    type: "common",
  },
  {
    label: "AVELESGES",
    value: "80046",
    type: "common",
  },
  {
    label: "AUMONT",
    value: "80041",
    type: "common",
  },
  {
    label: "VILLERS-CAMPSART",
    value: "80800",
    type: "common",
  },
  {
    label: "SAINT-MAULVIS",
    value: "80709",
    type: "common",
  },
  {
    label: "FRESNEVILLE",
    value: "80355",
    type: "common",
  },
  {
    label: "ETREJUST",
    value: "80297",
    type: "common",
  },
  {
    label: "EPAUMESNIL",
    value: "80269",
    type: "common",
  },
  {
    label: "DROMESNIL",
    value: "80259",
    type: "common",
  },
  {
    label: "AVESNES-CHAUSSOY",
    value: "80048",
    type: "common",
  },
  {
    label: "SAINT-AUBIN-RIVIERE",
    value: "80699",
    type: "common",
  },
  {
    label: "LE QUESNE",
    value: "80651",
    type: "common",
  },
  {
    label: "LE MAZIS",
    value: "80522",
    type: "common",
  },
  {
    label: "LIOMER",
    value: "80484",
    type: "common",
  },
  {
    label: "LIGNIERES-EN-VIMEU",
    value: "80480",
    type: "common",
  },
  {
    label: "INVAL-BOIRON",
    value: "80450",
    type: "common",
  },
  {
    label: "FRESNOY-ANDAINVILLE",
    value: "80356",
    type: "common",
  },
  {
    label: "BERMESNIL",
    value: "80084",
    type: "common",
  },
  {
    label: "ARGUEL",
    value: "80026",
    type: "common",
  },
  {
    label: "ANDAINVILLE",
    value: "80022",
    type: "common",
  },
  {
    label: "SENARPONT",
    value: "80732",
    type: "common",
  },
  {
    label: "SAINT-LEGER-SUR-BRESLE",
    value: "80707",
    type: "common",
  },
  {
    label: "NESLE-L'HOPITAL",
    value: "80586",
    type: "common",
  },
  {
    label: "FOUCAUCOURT-HORS-NESLE",
    value: "80336",
    type: "common",
  },
  {
    label: "PIERRECOURT",
    value: "76500",
    type: "common",
  },
  {
    label: "NESLE-NORMANDEUSE",
    value: "76460",
    type: "common",
  },
  {
    label: "HODENG-AU-BOSC",
    value: "76363",
    type: "common",
  },
  {
    label: "CAMPNEUSEVILLE",
    value: "76154",
    type: "common",
  },
  {
    label: "SAINT-RIQUIER-EN-RIVIERE",
    value: "76645",
    type: "common",
  },
  {
    label: "FALLENCOURT",
    value: "76257",
    type: "common",
  },
  {
    label: "DANCOURT",
    value: "76211",
    type: "common",
  },
  {
    label: "PUISENVAL",
    value: "76512",
    type: "common",
  },
  {
    label: "PREUSEVILLE",
    value: "76511",
    type: "common",
  },
  {
    label: "WANCHY-CAPVAL",
    value: "76749",
    type: "common",
  },
  {
    label: "LES IFS",
    value: "76371",
    type: "common",
  },
  {
    label: "FRESNOY-FOLNY",
    value: "76286",
    type: "common",
  },
  {
    label: "SAINT-OUEN-SOUS-BAILLY",
    value: "76630",
    type: "common",
  },
  {
    label: "DOUVREND",
    value: "76220",
    type: "common",
  },
  {
    label: "BAILLY-EN-RIVIERE",
    value: "76054",
    type: "common",
  },
  {
    label: "SAINT-NICOLAS-D'ALIERMONT",
    value: "76624",
    type: "common",
  },
  {
    label: "ENVERMEU",
    value: "76235",
    type: "common",
  },
  {
    label: "DAMPIERRE-SAINT-NICOLAS",
    value: "76210",
    type: "common",
  },
  {
    label: "BELLENGREVILLE",
    value: "76071",
    type: "common",
  },
  {
    label: "TOURVILLE-SUR-ARQUES",
    value: "76707",
    type: "common",
  },
  {
    label: "SAINT-AUBIN-LE-CAUF",
    value: "76562",
    type: "common",
  },
  {
    label: "ROUXMESNIL-BOUTEILLES",
    value: "76545",
    type: "common",
  },
  {
    label: "MARTIN-EGLISE",
    value: "76414",
    type: "common",
  },
  {
    label: "MARTIGNY",
    value: "76413",
    type: "common",
  },
  {
    label: "ARQUES-LA-BATAILLE",
    value: "76026",
    type: "common",
  },
  {
    label: "ANCOURT",
    value: "76008",
    type: "common",
  },
  {
    label: "SAINT-AUBIN-SUR-SCIE",
    value: "76565",
    type: "common",
  },
  {
    label: "OFFRANVILLE",
    value: "76482",
    type: "common",
  },
  {
    label: "HAUTOT-SUR-MER",
    value: "76349",
    type: "common",
  },
  {
    label: "VARENGEVILLE-SUR-MER",
    value: "76720",
    type: "common",
  },
  {
    label: "SAINTE-MARGUERITE-SUR-MER",
    value: "76605",
    type: "common",
  },
  {
    label: "SAINT-DENIS-D'ACLON",
    value: "76572",
    type: "common",
  },
  {
    label: "QUIBERVILLE",
    value: "76515",
    type: "common",
  },
  {
    label: "OUVILLE-LA-RIVIERE",
    value: "76492",
    type: "common",
  },
  {
    label: "LONGUEIL",
    value: "76395",
    type: "common",
  },
  {
    label: "AMBRUMESNIL",
    value: "76004",
    type: "common",
  },
  {
    label: "LE BOURG-DUN",
    value: "76133",
    type: "common",
  },
  {
    label: "SOTTEVILLE-SUR-MER",
    value: "76683",
    type: "common",
  },
  {
    label: "SAINT-AUBIN-SUR-MER",
    value: "76564",
    type: "common",
  },
  {
    label: "LA CHAPELLE-SUR-DUN",
    value: "76172",
    type: "common",
  },
  {
    label: "VEULES-LES-ROSES",
    value: "76735",
    type: "common",
  },
  {
    label: "MANNEVILLE-ES-PLAINS",
    value: "76407",
    type: "common",
  },
  {
    label: "SAINT-VALERY-EN-CAUX",
    value: "76655",
    type: "common",
  },
  {
    label: "GESPUNSART",
    value: "08188",
    type: "common",
  },
  {
    label: "MONTCY-NOTRE-DAME",
    value: "08298",
    type: "common",
  },
  {
    label: "AIGLEMONT",
    value: "08003",
    type: "common",
  },
  {
    label: "LA GRANDVILLE",
    value: "08199",
    type: "common",
  },
  {
    label: "NEUFMANIL",
    value: "08316",
    type: "common",
  },
  {
    label: "JOIGNY-SUR-MEUSE",
    value: "08237",
    type: "common",
  },
  {
    label: "CHARLEVILLE-MEZIERES",
    value: "08105",
    type: "common",
  },
  {
    label: "HOULDIZY",
    value: "08230",
    type: "common",
  },
  {
    label: "DAMOUZY",
    value: "08137",
    type: "common",
  },
  {
    label: "TOURNES",
    value: "08457",
    type: "common",
  },
  {
    label: "MONTCORNET",
    value: "08297",
    type: "common",
  },
  {
    label: "ARREUX",
    value: "08022",
    type: "common",
  },
  {
    label: "NOUZONVILLE",
    value: "08328",
    type: "common",
  },
  {
    label: "REMILLY-LES-POTHEES",
    value: "08358",
    type: "common",
  },
  {
    label: "SORMONNE",
    value: "08429",
    type: "common",
  },
  {
    label: "LONNY",
    value: "08260",
    type: "common",
  },
  {
    label: "HAUDRECY",
    value: "08216",
    type: "common",
  },
  {
    label: "HAM-LES-MOINES",
    value: "08206",
    type: "common",
  },
  {
    label: "CLIRON",
    value: "08125",
    type: "common",
  },
  {
    label: "BLOMBAY",
    value: "08071",
    type: "common",
  },
  {
    label: "RIMOGNE",
    value: "08365",
    type: "common",
  },
  {
    label: "LAVAL-MORENCY",
    value: "08249",
    type: "common",
  },
  {
    label: "ROUVROY-SUR-AUDRY",
    value: "08370",
    type: "common",
  },
  {
    label: "L'ECHELLE",
    value: "08149",
    type: "common",
  },
  {
    label: "MURTIN-ET-BOGNY",
    value: "08312",
    type: "common",
  },
  {
    label: "MARBY",
    value: "08273",
    type: "common",
  },
  {
    label: "CERNION",
    value: "08094",
    type: "common",
  },
  {
    label: "FLAIGNES-HAVYS",
    value: "08169",
    type: "common",
  },
  {
    label: "CHAMPLIN",
    value: "08100",
    type: "common",
  },
  {
    label: "ANTHENY",
    value: "08015",
    type: "common",
  },
  {
    label: "PREZ",
    value: "08344",
    type: "common",
  },
  {
    label: "ESTREBAY",
    value: "08154",
    type: "common",
  },
  {
    label: "AOUSTE",
    value: "08016",
    type: "common",
  },
  {
    label: "LOGNY-LES-AUBENTON",
    value: "02435",
    type: "common",
  },
  {
    label: "BOSSUS-LES-RUMIGNY",
    value: "08073",
    type: "common",
  },
  {
    label: "RUMIGNY",
    value: "08373",
    type: "common",
  },
  {
    label: "HANNAPPES",
    value: "08208",
    type: "common",
  },
  {
    label: "AUBENTON",
    value: "02031",
    type: "common",
  },
  {
    label: "MONT-SAINT-JEAN",
    value: "02522",
    type: "common",
  },
  {
    label: "COINGT",
    value: "02204",
    type: "common",
  },
  {
    label: "BESMONT",
    value: "02079",
    type: "common",
  },
  {
    label: "BEAUME",
    value: "02055",
    type: "common",
  },
  {
    label: "JEANTES",
    value: "02391",
    type: "common",
  },
  {
    label: "BANCIGNY",
    value: "02044",
    type: "common",
  },
  {
    label: "PLOMION",
    value: "02608",
    type: "common",
  },
  {
    label: "LANDOUZY-LA-COUR",
    value: "02404",
    type: "common",
  },
  {
    label: "HARY",
    value: "02373",
    type: "common",
  },
  {
    label: "HARCIGNY",
    value: "02369",
    type: "common",
  },
  {
    label: "VERVINS",
    value: "02789",
    type: "common",
  },
  {
    label: "THENAILLES",
    value: "02740",
    type: "common",
  },
  {
    label: "GRONARD",
    value: "02357",
    type: "common",
  },
  {
    label: "GERCY",
    value: "02341",
    type: "common",
  },
  {
    label: "VOULPAIX",
    value: "02826",
    type: "common",
  },
  {
    label: "SAINT-GOBERT",
    value: "02681",
    type: "common",
  },
  {
    label: "MARFONTAINE",
    value: "02463",
    type: "common",
  },
  {
    label: "LEME",
    value: "02416",
    type: "common",
  },
  {
    label: "FRANQUEVILLE",
    value: "02331",
    type: "common",
  },
  {
    label: "CHEVENNES",
    value: "02182",
    type: "common",
  },
  {
    label: "SAINT-PIERRE-LES-FRANQUEVILLE",
    value: "02688",
    type: "common",
  },
  {
    label: "ROUGERIES",
    value: "02657",
    type: "common",
  },
  {
    label: "HOUSSET",
    value: "02385",
    type: "common",
  },
  {
    label: "LE HERIE-LA-VIEVILLE",
    value: "02379",
    type: "common",
  },
  {
    label: "SAINS-RICHAUMONT",
    value: "02668",
    type: "common",
  },
  {
    label: "PUISIEUX-ET-CLANLIEU",
    value: "02629",
    type: "common",
  },
  {
    label: "LANDIFAY-ET-BERTAIGNEMONT",
    value: "02403",
    type: "common",
  },
  {
    label: "PARPEVILLE",
    value: "02592",
    type: "common",
  },
  {
    label: "THENELLES",
    value: "02741",
    type: "common",
  },
  {
    label: "ORIGNY-SAINTE-BENOITE",
    value: "02575",
    type: "common",
  },
  {
    label: "MONT-D'ORIGNY",
    value: "02503",
    type: "common",
  },
  {
    label: "MARCY",
    value: "02459",
    type: "common",
  },
  {
    label: "SISSY",
    value: "02721",
    type: "common",
  },
  {
    label: "REGNY",
    value: "02636",
    type: "common",
  },
  {
    label: "MESNIL-SAINT-LAURENT",
    value: "02481",
    type: "common",
  },
  {
    label: "ITANCOURT",
    value: "02387",
    type: "common",
  },
  {
    label: "HARLY",
    value: "02371",
    type: "common",
  },
  {
    label: "GRUGIES",
    value: "02359",
    type: "common",
  },
  {
    label: "GAUCHY",
    value: "02340",
    type: "common",
  },
  {
    label: "SAINT-QUENTIN",
    value: "02691",
    type: "common",
  },
  {
    label: "NEUVILLE-SAINT-AMAND",
    value: "02549",
    type: "common",
  },
  {
    label: "FRANCILLY-SELENCY",
    value: "02330",
    type: "common",
  },
  {
    label: "FONTAINE-LES-CLERCS",
    value: "02320",
    type: "common",
  },
  {
    label: "DALLON",
    value: "02257",
    type: "common",
  },
  {
    label: "SAVY",
    value: "02702",
    type: "common",
  },
  {
    label: "ROUPY",
    value: "02658",
    type: "common",
  },
  {
    label: "GERMAINE",
    value: "02343",
    type: "common",
  },
  {
    label: "FORESTE",
    value: "02327",
    type: "common",
  },
  {
    label: "FLUQUIERES",
    value: "02317",
    type: "common",
  },
  {
    label: "ETREILLERS",
    value: "02296",
    type: "common",
  },
  {
    label: "BEAUVOIS-EN-VERMANDOIS",
    value: "02060",
    type: "common",
  },
  {
    label: "ATTILLY",
    value: "02029",
    type: "common",
  },
  {
    label: "VAUX-EN-VERMANDOIS",
    value: "02772",
    type: "common",
  },
  {
    label: "TREFCON",
    value: "02747",
    type: "common",
  },
  {
    label: "LANCHY",
    value: "02402",
    type: "common",
  },
  {
    label: "UGNY-L'EQUIPEE",
    value: "80771",
    type: "common",
  },
  {
    label: "QUIVIERES",
    value: "80658",
    type: "common",
  },
  {
    label: "MONCHY-LAGACHE",
    value: "80555",
    type: "common",
  },
  {
    label: "DEVISE",
    value: "80239",
    type: "common",
  },
  {
    label: "CROIX-MOLIGNEAUX",
    value: "80226",
    type: "common",
  },
  {
    label: "Y",
    value: "80829",
    type: "common",
  },
  {
    label: "SAINT-CHRIST-BRIOST",
    value: "80701",
    type: "common",
  },
  {
    label: "PARGNY",
    value: "80616",
    type: "common",
  },
  {
    label: "FALVY",
    value: "80300",
    type: "common",
  },
  {
    label: "EPENANCOURT",
    value: "80272",
    type: "common",
  },
  {
    label: "ENNEMAIN",
    value: "80267",
    type: "common",
  },
  {
    label: "CIZANCOURT",
    value: "80197",
    type: "common",
  },
  {
    label: "ATHIES",
    value: "80034",
    type: "common",
  },
  {
    label: "HYPERCOURT",
    value: "80621",
    type: "common",
  },
  {
    label: "LICOURT",
    value: "80474",
    type: "common",
  },
  {
    label: "FRESNES-MAZANCOURT",
    value: "80353",
    type: "common",
  },
  {
    label: "ABLAINCOURT-PRESSOIR",
    value: "80002",
    type: "common",
  },
  {
    label: "VERMANDOVILLERS",
    value: "80789",
    type: "common",
  },
  {
    label: "LIHONS",
    value: "80481",
    type: "common",
  },
  {
    label: "HERLEVILLE",
    value: "80432",
    type: "common",
  },
  {
    label: "CHAULNES",
    value: "80186",
    type: "common",
  },
  {
    label: "VAUVILLERS",
    value: "80781",
    type: "common",
  },
  {
    label: "ROSIERES-EN-SANTERRE",
    value: "80680",
    type: "common",
  },
  {
    label: "HARBONNIERES",
    value: "80417",
    type: "common",
  },
  {
    label: "CAIX",
    value: "80162",
    type: "common",
  },
  {
    label: "WIENCOURT-L'EQUIPEE",
    value: "80824",
    type: "common",
  },
  {
    label: "MARCELCAVE",
    value: "80507",
    type: "common",
  },
  {
    label: "IGNAUCOURT",
    value: "80449",
    type: "common",
  },
  {
    label: "GUILLAUCOURT",
    value: "80400",
    type: "common",
  },
  {
    label: "CAYEUX-EN-SANTERRE",
    value: "80181",
    type: "common",
  },
  {
    label: "AUBERCOURT",
    value: "80035",
    type: "common",
  },
  {
    label: "HANGARD",
    value: "80414",
    type: "common",
  },
  {
    label: "GENTELLES",
    value: "80376",
    type: "common",
  },
  {
    label: "DOMART-SUR-LA-LUCE",
    value: "80242",
    type: "common",
  },
  {
    label: "DEMUIN",
    value: "80237",
    type: "common",
  },
  {
    label: "CACHY",
    value: "80159",
    type: "common",
  },
  {
    label: "BERTEAUCOURT-LES-THENNES",
    value: "80094",
    type: "common",
  },
  {
    label: "THEZY-GLIMONT",
    value: "80752",
    type: "common",
  },
  {
    label: "FOUENCAMPS",
    value: "80337",
    type: "common",
  },
  {
    label: "BOVES",
    value: "80131",
    type: "common",
  },
  {
    label: "SAINT-FUSCIEN",
    value: "80702",
    type: "common",
  },
  {
    label: "SAINS-EN-AMIENOIS",
    value: "80696",
    type: "common",
  },
  {
    label: "RUMIGNY",
    value: "80690",
    type: "common",
  },
  {
    label: "DURY",
    value: "80261",
    type: "common",
  },
  {
    label: "CAGNY",
    value: "80160",
    type: "common",
  },
  {
    label: "VERS-SUR-SELLE",
    value: "80791",
    type: "common",
  },
  {
    label: "PROUZEL",
    value: "80643",
    type: "common",
  },
  {
    label: "PLACHY-BUYON",
    value: "80627",
    type: "common",
  },
  {
    label: "HEBECOURT",
    value: "80424",
    type: "common",
  },
  {
    label: "FOSSEMANANT",
    value: "80334",
    type: "common",
  },
  {
    label: "BACOUEL-SUR-SELLE",
    value: "80050",
    type: "common",
  },
  {
    label: "REVELLES",
    value: "80670",
    type: "common",
  },
  {
    label: "NAMPS-MAISNIL",
    value: "80582",
    type: "common",
  },
  {
    label: "CREUSE",
    value: "80225",
    type: "common",
  },
  {
    label: "CLAIRY-SAULCHOIX",
    value: "80198",
    type: "common",
  },
  {
    label: "SAINT-AUBIN-MONTENOY",
    value: "80698",
    type: "common",
  },
  {
    label: "QUEVAUVILLERS",
    value: "80656",
    type: "common",
  },
  {
    label: "FRESNOY-AU-VAL",
    value: "80357",
    type: "common",
  },
  {
    label: "FLUY",
    value: "80319",
    type: "common",
  },
  {
    label: "COURCELLES-SOUS-MOYENCOURT",
    value: "80218",
    type: "common",
  },
  {
    label: "BUSSY-LES-POIX",
    value: "80157",
    type: "common",
  },
  {
    label: "VRAIGNES-LES-HORNOY",
    value: "80813",
    type: "common",
  },
  {
    label: "THIEULLOY-L'ABBAYE",
    value: "80754",
    type: "common",
  },
  {
    label: "FRICAMPS",
    value: "80365",
    type: "common",
  },
  {
    label: "LAMARONDE",
    value: "80460",
    type: "common",
  },
  {
    label: "HORNOY-LE-BOURG",
    value: "80443",
    type: "common",
  },
  {
    label: "BROCOURT",
    value: "80143",
    type: "common",
  },
  {
    label: "BETTEMBOS",
    value: "80098",
    type: "common",
  },
  {
    label: "SAINT-GERMAIN-SUR-BRESLE",
    value: "80703",
    type: "common",
  },
  {
    label: "NEUVILLE-COPPEGUEULE",
    value: "80592",
    type: "common",
  },
  {
    label: "LAFRESGUIMONT-SAINT-MARTIN",
    value: "80456",
    type: "common",
  },
  {
    label: "BEAUCAMPS-LE-VIEUX",
    value: "80062",
    type: "common",
  },
  {
    label: "BEAUCAMPS-LE-JEUNE",
    value: "80061",
    type: "common",
  },
  {
    label: "VIEUX-ROUEN-SUR-BRESLE",
    value: "76739",
    type: "common",
  },
  {
    label: "SAINT-MARTIN-AU-BOSC",
    value: "76612",
    type: "common",
  },
  {
    label: "RICHEMONT",
    value: "76527",
    type: "common",
  },
  {
    label: "ELLECOURT",
    value: "76233",
    type: "common",
  },
  {
    label: "AUBEGUIMONT",
    value: "76028",
    type: "common",
  },
  {
    label: "VILLERS-SOUS-FOUCARMONT",
    value: "76744",
    type: "common",
  },
  {
    label: "SAINT-LEGER-AUX-BOIS",
    value: "76598",
    type: "common",
  },
  {
    label: "RETONVAL",
    value: "76523",
    type: "common",
  },
  {
    label: "REALCAMP",
    value: "76520",
    type: "common",
  },
  {
    label: "FOUCARMONT",
    value: "76278",
    type: "common",
  },
  {
    label: "AUBERMESNIL-AUX-ERABLES",
    value: "76029",
    type: "common",
  },
  {
    label: "SMERMESNIL",
    value: "76677",
    type: "common",
  },
  {
    label: "SAINT-PIERRE-DES-JONQUIERES",
    value: "76635",
    type: "common",
  },
  {
    label: "CLAIS",
    value: "76175",
    type: "common",
  },
  {
    label: "CALLENGEVILLE",
    value: "76122",
    type: "common",
  },
  {
    label: "LONDINIERES",
    value: "76392",
    type: "common",
  },
  {
    label: "FREAUVILLE",
    value: "76280",
    type: "common",
  },
  {
    label: "CROIXDALLE",
    value: "76202",
    type: "common",
  },
  {
    label: "BAILLEUL-NEUVILLE",
    value: "76052",
    type: "common",
  },
  {
    label: "SAINTE-AGATHE-D'ALIERMONT",
    value: "76553",
    type: "common",
  },
  {
    label: "RICARVILLE-DU-VAL",
    value: "76526",
    type: "common",
  },
  {
    label: "OSMOY-SAINT-VALERY",
    value: "76487",
    type: "common",
  },
  {
    label: "NOTRE-DAME-D'ALIERMONT",
    value: "76472",
    type: "common",
  },
  {
    label: "TORCY-LE-PETIT",
    value: "76698",
    type: "common",
  },
  {
    label: "SAINT-VAAST-D'EQUIQUEVILLE",
    value: "76652",
    type: "common",
  },
  {
    label: "SAINT-JACQUES-D'ALIERMONT",
    value: "76590",
    type: "common",
  },
  {
    label: "SAINT-GERMAIN-D'ETABLES",
    value: "76582",
    type: "common",
  },
  {
    label: "MEULERS",
    value: "76437",
    type: "common",
  },
  {
    label: "FREULLEVILLE",
    value: "76288",
    type: "common",
  },
  {
    label: "SAINTE-FOY",
    value: "76577",
    type: "common",
  },
  {
    label: "DENESTANVILLE",
    value: "76214",
    type: "common",
  },
  {
    label: "LA CHAUSSEE",
    value: "76173",
    type: "common",
  },
  {
    label: "LA CHAPELLE-DU-BOURGAY",
    value: "76170",
    type: "common",
  },
  {
    label: "LE BOIS-ROBERT",
    value: "76112",
    type: "common",
  },
  {
    label: "AUBERMESNIL-BEAUMAIS",
    value: "76030",
    type: "common",
  },
  {
    label: "ANNEVILLE-SUR-SCIE",
    value: "76019",
    type: "common",
  },
  {
    label: "THIL-MANNEVILLE",
    value: "76690",
    type: "common",
  },
  {
    label: "SAUQUEVILLE",
    value: "76667",
    type: "common",
  },
  {
    label: "MANEHOUVILLE",
    value: "76405",
    type: "common",
  },
  {
    label: "LINTOT-LES-BOIS",
    value: "76389",
    type: "common",
  },
  {
    label: "CROSVILLE-SUR-SCIE",
    value: "76205",
    type: "common",
  },
  {
    label: "COLMESNIL-MANNEVILLE",
    value: "76184",
    type: "common",
  },
  {
    label: "BERTREVILLE-SAINT-OUEN",
    value: "76085",
    type: "common",
  },
  {
    label: "BACQUEVILLE-EN-CAUX",
    value: "76051",
    type: "common",
  },
  {
    label: "AUPPEGARD",
    value: "76036",
    type: "common",
  },
  {
    label: "LUNERAY",
    value: "76400",
    type: "common",
  },
  {
    label: "HERMANVILLE",
    value: "76356",
    type: "common",
  },
  {
    label: "GUEURES",
    value: "76334",
    type: "common",
  },
  {
    label: "GREUVILLE",
    value: "76327",
    type: "common",
  },
  {
    label: "BRACHY",
    value: "76136",
    type: "common",
  },
  {
    label: "AVREMESNIL",
    value: "76050",
    type: "common",
  },
  {
    label: "VENESTANVILLE",
    value: "76731",
    type: "common",
  },
  {
    label: "SAINT-PIERRE-LE-VIGER",
    value: "76642",
    type: "common",
  },
  {
    label: "SAINT-PIERRE-LE-VIEUX",
    value: "76641",
    type: "common",
  },
  {
    label: "GRUCHET-SAINT-SIMEON",
    value: "76330",
    type: "common",
  },
  {
    label: "LA GAILLARDE",
    value: "76294",
    type: "common",
  },
  {
    label: "FONTAINE-LE-DUN",
    value: "76272",
    type: "common",
  },
  {
    label: "CRASVILLE-LA-ROCQUEFORT",
    value: "76190",
    type: "common",
  },
  {
    label: "BOURVILLE",
    value: "76134",
    type: "common",
  },
  {
    label: "AUTIGNY",
    value: "76040",
    type: "common",
  },
  {
    label: "SAINTE-COLOMBE",
    value: "76569",
    type: "common",
  },
  {
    label: "PLEINE-SEVE",
    value: "76504",
    type: "common",
  },
  {
    label: "LE MESNIL-DURDENT",
    value: "76428",
    type: "common",
  },
  {
    label: "HOUDETOT",
    value: "76365",
    type: "common",
  },
  {
    label: "GUEUTTEVILLE-LES-GRES",
    value: "76336",
    type: "common",
  },
  {
    label: "ERMENOUVILLE",
    value: "76241",
    type: "common",
  },
  {
    label: "DROSAY",
    value: "76221",
    type: "common",
  },
  {
    label: "CAILLEVILLE",
    value: "76151",
    type: "common",
  },
  {
    label: "BLOSSEVILLE",
    value: "76104",
    type: "common",
  },
  {
    label: "ANGIENS",
    value: "76015",
    type: "common",
  },
  {
    label: "VITTEFLEUR",
    value: "76748",
    type: "common",
  },
  {
    label: "SAINT-SYLVAIN",
    value: "76651",
    type: "common",
  },
  {
    label: "SAINT-RIQUIER-ES-PLAINS",
    value: "76646",
    type: "common",
  },
  {
    label: "OCQUEVILLE",
    value: "76480",
    type: "common",
  },
  {
    label: "NEVILLE",
    value: "76467",
    type: "common",
  },
  {
    label: "INGOUVILLE",
    value: "76375",
    type: "common",
  },
  {
    label: "CRASVILLE-LA-MALLET",
    value: "76189",
    type: "common",
  },
  {
    label: "VINNEMERVILLE",
    value: "76746",
    type: "common",
  },
  {
    label: "VEULETTES-SUR-MER",
    value: "76736",
    type: "common",
  },
  {
    label: "BUTOT-VENESVILLE",
    value: "76732",
    type: "common",
  },
  {
    label: "SAINT-MARTIN-AUX-BUNEAUX",
    value: "76613",
    type: "common",
  },
  {
    label: "PALUEL",
    value: "76493",
    type: "common",
  },
  {
    label: "MALLEVILLE-LES-GRES",
    value: "76403",
    type: "common",
  },
  {
    label: "CRIQUETOT-LE-MAUCONDUIT",
    value: "76195",
    type: "common",
  },
  {
    label: "CLASVILLE",
    value: "76176",
    type: "common",
  },
  {
    label: "CANOUVILLE",
    value: "76156",
    type: "common",
  },
  {
    label: "AUBERVILLE-LA-MANUEL",
    value: "76032",
    type: "common",
  },
  {
    label: "SASSETOT-LE-MAUCONDUIT",
    value: "76663",
    type: "common",
  },
  {
    label: "SAINT-PIERRE-EN-PORT",
    value: "76637",
    type: "common",
  },
  {
    label: "ECRETTEVILLE-SUR-MER",
    value: "76226",
    type: "common",
  },
  {
    label: "ANCRETTEVILLE-SUR-MER",
    value: "76011",
    type: "common",
  },
  {
    label: "FRANCHEVAL",
    value: "08179",
    type: "common",
  },
  {
    label: "LA CHAPELLE",
    value: "08101",
    type: "common",
  },
  {
    label: "GIVONNE",
    value: "08191",
    type: "common",
  },
  {
    label: "SAINT-MENGES",
    value: "08391",
    type: "common",
  },
  {
    label: "FLOING",
    value: "08174",
    type: "common",
  },
  {
    label: "ILLY",
    value: "08232",
    type: "common",
  },
  {
    label: "GLAIRE",
    value: "08194",
    type: "common",
  },
  {
    label: "FLEIGNEUX",
    value: "08170",
    type: "common",
  },
  {
    label: "ISSANCOURT-ET-RUMEL",
    value: "08235",
    type: "common",
  },
  {
    label: "VIVIER-AU-COURT",
    value: "08488",
    type: "common",
  },
  {
    label: "GERNELLE",
    value: "08187",
    type: "common",
  },
  {
    label: "VRIGNE AUX BOIS",
    value: "08491",
    type: "common",
  },
  {
    label: "DONCHERY",
    value: "08142",
    type: "common",
  },
  {
    label: "SAINT-LAURENT",
    value: "08385",
    type: "common",
  },
  {
    label: "VILLE-SUR-LUMES",
    value: "08483",
    type: "common",
  },
  {
    label: "VILLERS-SEMEUSE",
    value: "08480",
    type: "common",
  },
  {
    label: "LUMES",
    value: "08263",
    type: "common",
  },
  {
    label: "LES AYVELLES",
    value: "08040",
    type: "common",
  },
  {
    label: "LA FRANCHEVILLE",
    value: "08180",
    type: "common",
  },
  {
    label: "PRIX-LES-MEZIERES",
    value: "08346",
    type: "common",
  },
  {
    label: "WARNECOURT",
    value: "08498",
    type: "common",
  },
  {
    label: "WARCQ",
    value: "08497",
    type: "common",
  },
  {
    label: "EVIGNY",
    value: "08160",
    type: "common",
  },
  {
    label: "BELVAL",
    value: "08058",
    type: "common",
  },
  {
    label: "CLAVY-WARBY",
    value: "08124",
    type: "common",
  },
  {
    label: "NEUVILLE-LES-THIS",
    value: "08322",
    type: "common",
  },
  {
    label: "THIS",
    value: "08450",
    type: "common",
  },
  {
    label: "SURY",
    value: "08432",
    type: "common",
  },
  {
    label: "FAGNON",
    value: "08162",
    type: "common",
  },
  {
    label: "SAINT-MARCEL",
    value: "08389",
    type: "common",
  },
  {
    label: "NEUFMAISON",
    value: "08315",
    type: "common",
  },
  {
    label: "VAUX-VILLAINE",
    value: "08468",
    type: "common",
  },
  {
    label: "LEPRON-LES-VALLEES",
    value: "08251",
    type: "common",
  },
  {
    label: "LOGNY-BOGNY",
    value: "08257",
    type: "common",
  },
  {
    label: "AUBIGNY-LES-POTHEES",
    value: "08026",
    type: "common",
  },
  {
    label: "MARLEMONT",
    value: "08277",
    type: "common",
  },
  {
    label: "MARANWEZ",
    value: "08272",
    type: "common",
  },
  {
    label: "LA FEREE",
    value: "08167",
    type: "common",
  },
  {
    label: "LIART",
    value: "08254",
    type: "common",
  },
  {
    label: "GRANDRIEUX",
    value: "02354",
    type: "common",
  },
  {
    label: "LE FRETY",
    value: "08182",
    type: "common",
  },
  {
    label: "BLANCHEFOSSE-ET-BAY",
    value: "08069",
    type: "common",
  },
  {
    label: "BRUNEHAMEL",
    value: "02126",
    type: "common",
  },
  {
    label: "LES AUTELS",
    value: "02038",
    type: "common",
  },
  {
    label: "RESIGNY",
    value: "02642",
    type: "common",
  },
  {
    label: "IVIERS",
    value: "02388",
    type: "common",
  },
  {
    label: "DOHIS",
    value: "02265",
    type: "common",
  },
  {
    label: "CUIRY-LES-IVIERS",
    value: "02251",
    type: "common",
  },
  {
    label: "ARCHON",
    value: "02021",
    type: "common",
  },
  {
    label: "PARFONDEVAL",
    value: "02586",
    type: "common",
  },
  {
    label: "DAGNY-LAMBERCY",
    value: "02256",
    type: "common",
  },
  {
    label: "SAINT-CLEMENT",
    value: "02674",
    type: "common",
  },
  {
    label: "RENNEVAL",
    value: "02641",
    type: "common",
  },
  {
    label: "NAMPCELLES-LA-COUR",
    value: "02535",
    type: "common",
  },
  {
    label: "MORGNY-EN-THIERACHE",
    value: "02526",
    type: "common",
  },
  {
    label: "BURELLES",
    value: "02136",
    type: "common",
  },
  {
    label: "BRAYE-EN-THIERACHE",
    value: "02116",
    type: "common",
  },
  {
    label: "VIGNEUX-HOCQUET",
    value: "02801",
    type: "common",
  },
  {
    label: "LUGNY",
    value: "02444",
    type: "common",
  },
  {
    label: "HOURY",
    value: "02384",
    type: "common",
  },
  {
    label: "CILLY",
    value: "02194",
    type: "common",
  },
  {
    label: "BOSMONT-SUR-SERRE",
    value: "02101",
    type: "common",
  },
  {
    label: "ROGNY",
    value: "02652",
    type: "common",
  },
  {
    label: "PRISCES",
    value: "02623",
    type: "common",
  },
  {
    label: "MARLE",
    value: "02468",
    type: "common",
  },
  {
    label: "MARCY-SOUS-MARLE",
    value: "02460",
    type: "common",
  },
  {
    label: "BERLANCOURT",
    value: "02068",
    type: "common",
  },
  {
    label: "VOHARIES",
    value: "02823",
    type: "common",
  },
  {
    label: "THIERNU",
    value: "02742",
    type: "common",
  },
  {
    label: "LA NEUVILLE-HOUSSET",
    value: "02547",
    type: "common",
  },
  {
    label: "MONTIGNY-SOUS-MARLE",
    value: "02516",
    type: "common",
  },
  {
    label: "CHATILLON-LES-SONS",
    value: "02169",
    type: "common",
  },
  {
    label: "BOIS-LES-PARGNY",
    value: "02096",
    type: "common",
  },
  {
    label: "SONS-ET-RONCHERES",
    value: "02727",
    type: "common",
  },
  {
    label: "CHEVRESIS-MONCEAU",
    value: "02184",
    type: "common",
  },
  {
    label: "MONCEAU-LE-NEUF-ET-FAUCOUZY",
    value: "02491",
    type: "common",
  },
  {
    label: "VILLERS-LE-SEC",
    value: "02813",
    type: "common",
  },
  {
    label: "SURFONTAINE",
    value: "02732",
    type: "common",
  },
  {
    label: "RIBEMONT",
    value: "02648",
    type: "common",
  },
  {
    label: "PLEINE-SELVE",
    value: "02605",
    type: "common",
  },
  {
    label: "CHATILLON-SUR-OISE",
    value: "02170",
    type: "common",
  },
  {
    label: "BERTHENICOURT",
    value: "02075",
    type: "common",
  },
  {
    label: "ALAINCOURT",
    value: "02009",
    type: "common",
  },
  {
    label: "SERY-LES-MEZIERES",
    value: "02717",
    type: "common",
  },
  {
    label: "MEZIERES-SUR-OISE",
    value: "02483",
    type: "common",
  },
  {
    label: "HINACOURT",
    value: "02380",
    type: "common",
  },
  {
    label: "ESSIGNY-LE-GRAND",
    value: "02287",
    type: "common",
  },
  {
    label: "CERIZY",
    value: "02149",
    type: "common",
  },
  {
    label: "BENAY",
    value: "02066",
    type: "common",
  },
  {
    label: "URVILLERS",
    value: "02756",
    type: "common",
  },
  {
    label: "MOŸ-DE-L'AISNE",
    value: "02532",
    type: "common",
  },
  {
    label: "HAPPENCOURT",
    value: "02367",
    type: "common",
  },
  {
    label: "CONTESCOURT",
    value: "02214",
    type: "common",
  },
  {
    label: "CLASTRES",
    value: "02199",
    type: "common",
  },
  {
    label: "CASTRES",
    value: "02142",
    type: "common",
  },
  {
    label: "ARTEMPS",
    value: "02025",
    type: "common",
  },
  {
    label: "SERAUCOURT-LE-GRAND",
    value: "02710",
    type: "common",
  },
  {
    label: "SAINT-SIMON",
    value: "02694",
    type: "common",
  },
  {
    label: "DURY",
    value: "02273",
    type: "common",
  },
  {
    label: "DOUCHY",
    value: "02270",
    type: "common",
  },
  {
    label: "BRAY-SAINT-CHRISTOPHE",
    value: "02117",
    type: "common",
  },
  {
    label: "AUBIGNY-AUX-KAISNES",
    value: "02032",
    type: "common",
  },
  {
    label: "VILLERS-SAINT-CHRISTOPHE",
    value: "02815",
    type: "common",
  },
  {
    label: "TUGNY-ET-PONT",
    value: "02752",
    type: "common",
  },
  {
    label: "PITHON",
    value: "02604",
    type: "common",
  },
  {
    label: "SANCOURT",
    value: "80726",
    type: "common",
  },
  {
    label: "OFFOY",
    value: "80605",
    type: "common",
  },
  {
    label: "MATIGNY",
    value: "80519",
    type: "common",
  },
  {
    label: "HAM",
    value: "80410",
    type: "common",
  },
  {
    label: "DOUILLY",
    value: "80252",
    type: "common",
  },
  {
    label: "VOYENNES",
    value: "80811",
    type: "common",
  },
  {
    label: "VILLECOURT",
    value: "80794",
    type: "common",
  },
  {
    label: "ROUY-LE-PETIT",
    value: "80684",
    type: "common",
  },
  {
    label: "ROUY-LE-GRAND",
    value: "80683",
    type: "common",
  },
  {
    label: "NESLE",
    value: "80585",
    type: "common",
  },
  {
    label: "MORCHAIN",
    value: "80568",
    type: "common",
  },
  {
    label: "MESNIL-SAINT-NICAISE",
    value: "80542",
    type: "common",
  },
  {
    label: "LANGUEVOISIN-QUIQUERY",
    value: "80465",
    type: "common",
  },
  {
    label: "BETHENCOURT-SUR-SOMME",
    value: "80097",
    type: "common",
  },
  {
    label: "PUZEAUX",
    value: "80647",
    type: "common",
  },
  {
    label: "POTTE",
    value: "80638",
    type: "common",
  },
  {
    label: "LIANCOURT-FOSSE",
    value: "80473",
    type: "common",
  },
  {
    label: "HERLY",
    value: "80433",
    type: "common",
  },
  {
    label: "FONCHES-FONCHETTE",
    value: "80322",
    type: "common",
  },
  {
    label: "ETALON",
    value: "80292",
    type: "common",
  },
  {
    label: "CURCHY",
    value: "80230",
    type: "common",
  },
  {
    label: "CREMERY",
    value: "80223",
    type: "common",
  },
  {
    label: "PUNCHY",
    value: "80646",
    type: "common",
  },
  {
    label: "PARVILLERS-LE-QUESNOY",
    value: "80617",
    type: "common",
  },
  {
    label: "MEHARICOURT",
    value: "80524",
    type: "common",
  },
  {
    label: "MAUCOURT",
    value: "80520",
    type: "common",
  },
  {
    label: "HATTENCOURT",
    value: "80421",
    type: "common",
  },
  {
    label: "HALLU",
    value: "80409",
    type: "common",
  },
  {
    label: "FRANSART",
    value: "80347",
    type: "common",
  },
  {
    label: "FOUQUESCOURT",
    value: "80339",
    type: "common",
  },
  {
    label: "CHILLY",
    value: "80191",
    type: "common",
  },
  {
    label: "LA CHAVATTE",
    value: "80189",
    type: "common",
  },
  {
    label: "WARVILLERS",
    value: "80823",
    type: "common",
  },
  {
    label: "VRELY",
    value: "80814",
    type: "common",
  },
  {
    label: "ROUVROY-EN-SANTERRE",
    value: "80682",
    type: "common",
  },
  {
    label: "FOLIES",
    value: "80320",
    type: "common",
  },
  {
    label: "BOUCHOIR",
    value: "80116",
    type: "common",
  },
  {
    label: "BEAUFORT-EN-SANTERRE",
    value: "80067",
    type: "common",
  },
  {
    label: "LE QUESNEL",
    value: "80652",
    type: "common",
  },
  {
    label: "LE PLESSIER-ROZAINVILLERS",
    value: "80628",
    type: "common",
  },
  {
    label: "MEZIERES-EN-SANTERRE",
    value: "80545",
    type: "common",
  },
  {
    label: "HANGEST-EN-SANTERRE",
    value: "80415",
    type: "common",
  },
  {
    label: "FRESNOY-EN-CHAUSSEE",
    value: "80358",
    type: "common",
  },
  {
    label: "BEAUCOURT-EN-SANTERRE",
    value: "80064",
    type: "common",
  },
  {
    label: "VILLERS-AUX-ERABLES",
    value: "80797",
    type: "common",
  },
  {
    label: "THENNES",
    value: "80751",
    type: "common",
  },
  {
    label: "LA NEUVILLE-SIRE-BERNARD",
    value: "80595",
    type: "common",
  },
  {
    label: "MORISEL",
    value: "80571",
    type: "common",
  },
  {
    label: "MOREUIL",
    value: "80570",
    type: "common",
  },
  {
    label: "ROUVREL",
    value: "80681",
    type: "common",
  },
  {
    label: "REMIENCOURT",
    value: "80668",
    type: "common",
  },
  {
    label: "HAILLES",
    value: "80405",
    type: "common",
  },
  {
    label: "GUYENCOURT-SUR-NOYE",
    value: "80403",
    type: "common",
  },
  {
    label: "DOMMARTIN",
    value: "80246",
    type: "common",
  },
  {
    label: "COTTENCHY",
    value: "80213",
    type: "common",
  },
  {
    label: "JUMEL",
    value: "80452",
    type: "common",
  },
  {
    label: "GRATTEPANCHE",
    value: "80387",
    type: "common",
  },
  {
    label: "ESTREES-SUR-NOYE",
    value: "80291",
    type: "common",
  },
  {
    label: "SAINT-SAUFLIEU",
    value: "80717",
    type: "common",
  },
  {
    label: "ORESMAUX",
    value: "80611",
    type: "common",
  },
  {
    label: "NAMPTY",
    value: "80583",
    type: "common",
  },
  {
    label: "ESSERTAUX",
    value: "80285",
    type: "common",
  },
  {
    label: "VELENNES",
    value: "80786",
    type: "common",
  },
  {
    label: "CONTY",
    value: "80211",
    type: "common",
  },
  {
    label: "CONTRE",
    value: "80210",
    type: "common",
  },
  {
    label: "MOYENCOURT-LES-POIX",
    value: "80577",
    type: "common",
  },
  {
    label: "FREMONTIERS",
    value: "80352",
    type: "common",
  },
  {
    label: "FAMECHON",
    value: "80301",
    type: "common",
  },
  {
    label: "BLANGY-SOUS-POIX",
    value: "80106",
    type: "common",
  },
  {
    label: "BERGICOURT",
    value: "80083",
    type: "common",
  },
  {
    label: "THIEULLOY-LA-VILLE",
    value: "80755",
    type: "common",
  },
  {
    label: "SAULCHOY-SOUS-POIX",
    value: "80728",
    type: "common",
  },
  {
    label: "SAINTE-SEGREE",
    value: "80719",
    type: "common",
  },
  {
    label: "POIX-DE-PICARDIE",
    value: "80630",
    type: "common",
  },
  {
    label: "LACHAPELLE",
    value: "80455",
    type: "common",
  },
  {
    label: "EPLESSIER",
    value: "80273",
    type: "common",
  },
  {
    label: "CROIXRAULT",
    value: "80227",
    type: "common",
  },
  {
    label: "OFFIGNIES",
    value: "80604",
    type: "common",
  },
  {
    label: "MORVILLERS-SAINT-SATURNIN",
    value: "80573",
    type: "common",
  },
  {
    label: "MEIGNEUX",
    value: "80525",
    type: "common",
  },
  {
    label: "MARLERS",
    value: "80515",
    type: "common",
  },
  {
    label: "LIGNIERES-CHATELAIN",
    value: "80479",
    type: "common",
  },
  {
    label: "FOURCIGNY",
    value: "80340",
    type: "common",
  },
  {
    label: "CAULIERES",
    value: "80179",
    type: "common",
  },
  {
    label: "GAUVILLE",
    value: "80375",
    type: "common",
  },
  {
    label: "AUMALE",
    value: "76035",
    type: "common",
  },
  {
    label: "QUINCAMPOIX-FLEUZY",
    value: "60521",
    type: "common",
  },
  {
    label: "ESCLES-SAINT-PIERRE",
    value: "60219",
    type: "common",
  },
  {
    label: "MORIENNE",
    value: "76606",
    type: "common",
  },
  {
    label: "NULLEMONT",
    value: "76479",
    type: "common",
  },
  {
    label: "MARQUES",
    value: "76411",
    type: "common",
  },
  {
    label: "LANDES-VIEILLES-ET-NEUVES",
    value: "76381",
    type: "common",
  },
  {
    label: "MORTEMER",
    value: "76454",
    type: "common",
  },
  {
    label: "LE CAULE-SAINTE-BEUVE",
    value: "76166",
    type: "common",
  },
  {
    label: "AUVILLIERS",
    value: "76042",
    type: "common",
  },
  {
    label: "VATIERVILLE",
    value: "76724",
    type: "common",
  },
  {
    label: "SAINT-GERMAIN-SUR-EAULNE",
    value: "76584",
    type: "common",
  },
  {
    label: "SAINTE-BEUVE-EN-RIVIERE",
    value: "76567",
    type: "common",
  },
  {
    label: "MENONVAL",
    value: "76424",
    type: "common",
  },
  {
    label: "FESQUES",
    value: "76262",
    type: "common",
  },
  {
    label: "SAINT-MARTIN-L'HORTIER",
    value: "76620",
    type: "common",
  },
  {
    label: "NEUFCHATEL-EN-BRAY",
    value: "76462",
    type: "common",
  },
  {
    label: "MESNIERES-EN-BRAY",
    value: "76427",
    type: "common",
  },
  {
    label: "LUCY",
    value: "76399",
    type: "common",
  },
  {
    label: "BAILLOLET",
    value: "76053",
    type: "common",
  },
  {
    label: "MESNIL-FOLLEMPRISE",
    value: "76430",
    type: "common",
  },
  {
    label: "FRESLES",
    value: "76283",
    type: "common",
  },
  {
    label: "BURES-EN-BRAY",
    value: "76148",
    type: "common",
  },
  {
    label: "ARDOUVAL",
    value: "76024",
    type: "common",
  },
  {
    label: "MUCHEDENT",
    value: "76458",
    type: "common",
  },
  {
    label: "LES GRANDES-VENTES",
    value: "76321",
    type: "common",
  },
  {
    label: "TORCY-LE-GRAND",
    value: "76697",
    type: "common",
  },
  {
    label: "SAINT-HONORE",
    value: "76589",
    type: "common",
  },
  {
    label: "SAINT-CRESPIN",
    value: "76570",
    type: "common",
  },
  {
    label: "NOTRE-DAME-DU-PARC",
    value: "76478",
    type: "common",
  },
  {
    label: "LONGUEVILLE-SUR-SCIE",
    value: "76397",
    type: "common",
  },
  {
    label: "HEUGLEVILLE-SUR-SCIE",
    value: "76360",
    type: "common",
  },
  {
    label: "LES CENT-ACRES",
    value: "76168",
    type: "common",
  },
  {
    label: "LE CATELIER",
    value: "76162",
    type: "common",
  },
  {
    label: "SAINT-MARDS",
    value: "76604",
    type: "common",
  },
  {
    label: "OMONVILLE",
    value: "76485",
    type: "common",
  },
  {
    label: "LAMBERVILLE",
    value: "76379",
    type: "common",
  },
  {
    label: "GONNEVILLE-SUR-SCIE",
    value: "76308",
    type: "common",
  },
  {
    label: "CRIQUETOT-SUR-LONGUEVILLE",
    value: "76197",
    type: "common",
  },
  {
    label: "BELMESNIL",
    value: "76075",
    type: "common",
  },
  {
    label: "BEAUVAL-EN-CAUX",
    value: "76063",
    type: "common",
  },
  {
    label: "TOCQUEVILLE-EN-CAUX",
    value: "76694",
    type: "common",
  },
  {
    label: "SAINT-PIERRE-BENOUVILLE",
    value: "76632",
    type: "common",
  },
  {
    label: "SAINT-OUEN-LE-MAUGER",
    value: "76629",
    type: "common",
  },
  {
    label: "SAANE-SAINT-JUST",
    value: "76549",
    type: "common",
  },
  {
    label: "ROYVILLE",
    value: "76546",
    type: "common",
  },
  {
    label: "RAINFREVILLE",
    value: "76519",
    type: "common",
  },
  {
    label: "LESTANVILLE",
    value: "76383",
    type: "common",
  },
  {
    label: "LAMMERVILLE",
    value: "76380",
    type: "common",
  },
  {
    label: "BIVILLE-LA-RIVIERE",
    value: "76097",
    type: "common",
  },
  {
    label: "AUZOUVILLE-SUR-SAANE",
    value: "76047",
    type: "common",
  },
  {
    label: "SASSETOT-LE-MALGARDE",
    value: "76662",
    type: "common",
  },
  {
    label: "SAINT-LAURENT-EN-CAUX",
    value: "76597",
    type: "common",
  },
  {
    label: "REUVILLE",
    value: "76524",
    type: "common",
  },
  {
    label: "PRETOT-VICQUEMARE",
    value: "76510",
    type: "common",
  },
  {
    label: "GONNETOT",
    value: "76306",
    type: "common",
  },
  {
    label: "CANVILLE-LES-DEUX-EGLISES",
    value: "76158",
    type: "common",
  },
  {
    label: "BRETTEVILLE-SAINT-LAURENT",
    value: "76144",
    type: "common",
  },
  {
    label: "BRAMETOT",
    value: "76140",
    type: "common",
  },
  {
    label: "BENESVILLE",
    value: "76077",
    type: "common",
  },
  {
    label: "HEBERVILLE",
    value: "76353",
    type: "common",
  },
  {
    label: "HAUTOT-L'AUVRAY",
    value: "76346",
    type: "common",
  },
  {
    label: "GONZEVILLE",
    value: "76309",
    type: "common",
  },
  {
    label: "FULTOT",
    value: "76293",
    type: "common",
  },
  {
    label: "ANGLESQUEVILLE-LA-BRAS-LONG",
    value: "76016",
    type: "common",
  },
  {
    label: "VEAUVILLE-LES-QUELLES",
    value: "76730",
    type: "common",
  },
  {
    label: "SASSEVILLE",
    value: "76664",
    type: "common",
  },
  {
    label: "SAINT-VAAST-DIEPPEDALLE",
    value: "76653",
    type: "common",
  },
  {
    label: "GRAINVILLE-LA-TEINTURIERE",
    value: "76315",
    type: "common",
  },
  {
    label: "BOSVILLE",
    value: "76128",
    type: "common",
  },
  {
    label: "THEUVILLE-AUX-MAILLOTS",
    value: "76686",
    type: "common",
  },
  {
    label: "OURVILLE-EN-CAUX",
    value: "76490",
    type: "common",
  },
  {
    label: "OUAINVILLE",
    value: "76488",
    type: "common",
  },
  {
    label: "GERPONVILLE",
    value: "76299",
    type: "common",
  },
  {
    label: "CANY-BARVILLE",
    value: "76159",
    type: "common",
  },
  {
    label: "BERTREVILLE",
    value: "76084",
    type: "common",
  },
  {
    label: "BERTHEAUVILLE",
    value: "76083",
    type: "common",
  },
  {
    label: "VALMONT",
    value: "76719",
    type: "common",
  },
  {
    label: "THEROULDEVILLE",
    value: "76685",
    type: "common",
  },
  {
    label: "ELETOT",
    value: "76232",
    type: "common",
  },
  {
    label: "COLLEVILLE",
    value: "76183",
    type: "common",
  },
  {
    label: "ANGERVILLE-LA-MARTEL",
    value: "76013",
    type: "common",
  },
  {
    label: "TOUSSAINT",
    value: "76708",
    type: "common",
  },
  {
    label: "SENNEVILLE-SUR-FECAMP",
    value: "76670",
    type: "common",
  },
  {
    label: "SAINTE-HELENE-BONDEVILLE",
    value: "76587",
    type: "common",
  },
  {
    label: "GANZEVILLE",
    value: "76298",
    type: "common",
  },
  {
    label: "FECAMP",
    value: "76259",
    type: "common",
  },
  {
    label: "YPORT",
    value: "76754",
    type: "common",
  },
  {
    label: "SAINT-LEONARD",
    value: "76600",
    type: "common",
  },
  {
    label: "CRIQUEBEUF-EN-CAUX",
    value: "76194",
    type: "common",
  },
  {
    label: "VICQ-SUR-MER",
    value: "50142",
    type: "common",
  },
  {
    label: "MOGUES",
    value: "08291",
    type: "common",
  },
  {
    label: "WILLIERS",
    value: "08501",
    type: "common",
  },
  {
    label: "MATTON-ET-CLEMENCY",
    value: "08281",
    type: "common",
  },
  {
    label: "TREMBLOIS-LES-CARIGNAN",
    value: "08459",
    type: "common",
  },
  {
    label: "PURE",
    value: "08349",
    type: "common",
  },
  {
    label: "POURU-SAINT-REMY",
    value: "08343",
    type: "common",
  },
  {
    label: "POURU-AUX-BOIS",
    value: "08342",
    type: "common",
  },
  {
    label: "ESCOMBRES-ET-LE-CHESNOIS",
    value: "08153",
    type: "common",
  },
  {
    label: "MESSINCOURT",
    value: "08289",
    type: "common",
  },
  {
    label: "SACHY",
    value: "08375",
    type: "common",
  },
  {
    label: "OSNES",
    value: "08336",
    type: "common",
  },
  {
    label: "LA MONCELLE",
    value: "08294",
    type: "common",
  },
  {
    label: "DAIGNY",
    value: "08136",
    type: "common",
  },
  {
    label: "DOUZY",
    value: "08145",
    type: "common",
  },
  {
    label: "BREVILLY",
    value: "08083",
    type: "common",
  },
  {
    label: "SEDAN",
    value: "08409",
    type: "common",
  },
  {
    label: "NOYERS-PONT-MAUGIS",
    value: "08331",
    type: "common",
  },
  {
    label: "WADELINCOURT",
    value: "08494",
    type: "common",
  },
  {
    label: "BALAN",
    value: "08043",
    type: "common",
  },
  {
    label: "BAZEILLES",
    value: "08053",
    type: "common",
  },
  {
    label: "VRIGNE-MEUSE",
    value: "08492",
    type: "common",
  },
  {
    label: "HANNOGNE-SAINT-MARTIN",
    value: "08209",
    type: "common",
  },
  {
    label: "VILLERS-SUR-BAR",
    value: "08481",
    type: "common",
  },
  {
    label: "CHEVEUGES",
    value: "08119",
    type: "common",
  },
  {
    label: "NOUVION-SUR-MEUSE",
    value: "08327",
    type: "common",
  },
  {
    label: "DOM-LE-MESNIL",
    value: "08140",
    type: "common",
  },
  {
    label: "CHALANDRY-ELAIRE",
    value: "08096",
    type: "common",
  },
  {
    label: "ETREPIGNY",
    value: "08158",
    type: "common",
  },
  {
    label: "BOULZICOURT",
    value: "08076",
    type: "common",
  },
  {
    label: "YVERNAUMONT",
    value: "08503",
    type: "common",
  },
  {
    label: "CHAMPIGNEUL-SUR-VENCE",
    value: "08099",
    type: "common",
  },
  {
    label: "SAINT-PIERRE-SUR-VENCE",
    value: "08395",
    type: "common",
  },
  {
    label: "VILLERS-SUR-LE-MONT",
    value: "08482",
    type: "common",
  },
  {
    label: "SAINT-MARCEAU",
    value: "08388",
    type: "common",
  },
  {
    label: "MONDIGNY",
    value: "08295",
    type: "common",
  },
  {
    label: "GUIGNICOURT-SUR-VENCE",
    value: "08203",
    type: "common",
  },
  {
    label: "JANDUN",
    value: "08236",
    type: "common",
  },
  {
    label: "GRUYERES",
    value: "08201",
    type: "common",
  },
  {
    label: "BARBAISE",
    value: "08047",
    type: "common",
  },
  {
    label: "TOULIGNY",
    value: "08454",
    type: "common",
  },
  {
    label: "THIN-LE-MOUTIER",
    value: "08449",
    type: "common",
  },
  {
    label: "DOMMERY",
    value: "08141",
    type: "common",
  },
  {
    label: "LALOBBE",
    value: "08243",
    type: "common",
  },
  {
    label: "SIGNY-L'ABBAYE",
    value: "08419",
    type: "common",
  },
  {
    label: "SAINT-JEAN-AUX-BOIS",
    value: "08382",
    type: "common",
  },
  {
    label: "LA ROMAGNE",
    value: "08369",
    type: "common",
  },
  {
    label: "MONTMEILLANT",
    value: "08307",
    type: "common",
  },
  {
    label: "VAUX-LES-RUBIGNY",
    value: "08465",
    type: "common",
  },
  {
    label: "RUBIGNY",
    value: "08372",
    type: "common",
  },
  {
    label: "ROCQUIGNY",
    value: "08366",
    type: "common",
  },
  {
    label: "CHERY-LES-ROZOY",
    value: "02181",
    type: "common",
  },
  {
    label: "ROZOY-SUR-SERRE",
    value: "02666",
    type: "common",
  },
  {
    label: "ROUVROY-SUR-SERRE",
    value: "02660",
    type: "common",
  },
  {
    label: "RAILLIMONT",
    value: "02634",
    type: "common",
  },
  {
    label: "LISLET",
    value: "02433",
    type: "common",
  },
  {
    label: "DOLIGNON",
    value: "02266",
    type: "common",
  },
  {
    label: "VINCY-REUIL-ET-MAGNY",
    value: "02819",
    type: "common",
  },
  {
    label: "SOIZE",
    value: "02723",
    type: "common",
  },
  {
    label: "SAINTE-GENEVIEVE",
    value: "02678",
    type: "common",
  },
  {
    label: "MONTCORNET",
    value: "02502",
    type: "common",
  },
  {
    label: "CHAOURSE",
    value: "02160",
    type: "common",
  },
  {
    label: "AGNICOURT-ET-SECHELLES",
    value: "02004",
    type: "common",
  },
  {
    label: "TAVAUX-ET-PONTSERICOURT",
    value: "02737",
    type: "common",
  },
  {
    label: "MONTIGNY-LE-FRANC",
    value: "02513",
    type: "common",
  },
  {
    label: "EBOULEAU",
    value: "02274",
    type: "common",
  },
  {
    label: "CUIRIEUX",
    value: "02248",
    type: "common",
  },
  {
    label: "SAINT-PIERREMONT",
    value: "02689",
    type: "common",
  },
  {
    label: "LA NEUVILLE-BOSMONT",
    value: "02545",
    type: "common",
  },
  {
    label: "AUTREMENCOURT",
    value: "02039",
    type: "common",
  },
  {
    label: "VOYENNE",
    value: "02827",
    type: "common",
  },
  {
    label: "TOULIS-ET-ATTENCOURT",
    value: "02745",
    type: "common",
  },
  {
    label: "FROIDMONT-COHARTILLE",
    value: "02338",
    type: "common",
  },
  {
    label: "ERLON",
    value: "02283",
    type: "common",
  },
  {
    label: "DERCY",
    value: "02261",
    type: "common",
  },
  {
    label: "MORTIERS",
    value: "02529",
    type: "common",
  },
  {
    label: "LA FERTE-CHEVRESIS",
    value: "02306",
    type: "common",
  },
  {
    label: "CRECY-SUR-SERRE",
    value: "02237",
    type: "common",
  },
  {
    label: "ASSIS-SUR-SERRE",
    value: "02027",
    type: "common",
  },
  {
    label: "POUILLY-SUR-SERRE",
    value: "02617",
    type: "common",
  },
  {
    label: "PARGNY-LES-BOIS",
    value: "02591",
    type: "common",
  },
  {
    label: "MONTIGNY-SUR-CRECY",
    value: "02517",
    type: "common",
  },
  {
    label: "MESBRECOURT-RICHECOURT",
    value: "02480",
    type: "common",
  },
  {
    label: "COURBES",
    value: "02222",
    type: "common",
  },
  {
    label: "RENANSART",
    value: "02640",
    type: "common",
  },
  {
    label: "REMIES",
    value: "02638",
    type: "common",
  },
  {
    label: "NOUVION-LE-COMTE",
    value: "02560",
    type: "common",
  },
  {
    label: "NOUVION-ET-CATILLON",
    value: "02559",
    type: "common",
  },
  {
    label: "MAYOT",
    value: "02473",
    type: "common",
  },
  {
    label: "BRISSY-HAMEGICOURT",
    value: "02124",
    type: "common",
  },
  {
    label: "BRISSAY-CHOIGNY",
    value: "02123",
    type: "common",
  },
  {
    label: "ANGUILCOURT-LE-SART",
    value: "02017",
    type: "common",
  },
  {
    label: "ACHERY",
    value: "02002",
    type: "common",
  },
  {
    label: "MENNESSIS",
    value: "02474",
    type: "common",
  },
  {
    label: "LY-FONTAINE",
    value: "02446",
    type: "common",
  },
  {
    label: "LIEZ",
    value: "02431",
    type: "common",
  },
  {
    label: "GIBERCOURT",
    value: "02345",
    type: "common",
  },
  {
    label: "VENDEUIL",
    value: "02775",
    type: "common",
  },
  {
    label: "TRAVECY",
    value: "02746",
    type: "common",
  },
  {
    label: "REMIGNY",
    value: "02639",
    type: "common",
  },
  {
    label: "JUSSY",
    value: "02397",
    type: "common",
  },
  {
    label: "FRIERES-FAILLOUEL",
    value: "02336",
    type: "common",
  },
  {
    label: "FLAVY-LE-MARTEL",
    value: "02315",
    type: "common",
  },
  {
    label: "MONTESCOURT-LIZEROLLES",
    value: "02504",
    type: "common",
  },
  {
    label: "CUGNY",
    value: "02246",
    type: "common",
  },
  {
    label: "BEAUMONT-EN-BEINE",
    value: "02056",
    type: "common",
  },
  {
    label: "ANNOIS",
    value: "02019",
    type: "common",
  },
  {
    label: "BROUCHY",
    value: "80144",
    type: "common",
  },
  {
    label: "VILLESELVE",
    value: "60693",
    type: "common",
  },
  {
    label: "SOMMETTE-EAUCOURT",
    value: "02726",
    type: "common",
  },
  {
    label: "OLLEZY",
    value: "02570",
    type: "common",
  },
  {
    label: "MUILLE-VILLETTE",
    value: "80579",
    type: "common",
  },
  {
    label: "ESMERY-HALLON",
    value: "80284",
    type: "common",
  },
  {
    label: "EPPEVILLE",
    value: "80274",
    type: "common",
  },
  {
    label: "LE PLESSIS-PATTE-D'OIE",
    value: "60502",
    type: "common",
  },
  {
    label: "GOLANCOURT",
    value: "60278",
    type: "common",
  },
  {
    label: "FLAVY-LE-MELDEUX",
    value: "60236",
    type: "common",
  },
  {
    label: "MOYENCOURT",
    value: "80576",
    type: "common",
  },
  {
    label: "ERCHEU",
    value: "80279",
    type: "common",
  },
  {
    label: "CRESSY-OMENCOURT",
    value: "80224",
    type: "common",
  },
  {
    label: "BUVERCHY",
    value: "80158",
    type: "common",
  },
  {
    label: "BREUIL",
    value: "80139",
    type: "common",
  },
  {
    label: "OGNOLLES",
    value: "60474",
    type: "common",
  },
  {
    label: "LIBERMONT",
    value: "60362",
    type: "common",
  },
  {
    label: "RETHONVILLERS",
    value: "80669",
    type: "common",
  },
  {
    label: "MARCHE-ALLOUARDE",
    value: "80508",
    type: "common",
  },
  {
    label: "GRUNY",
    value: "80393",
    type: "common",
  },
  {
    label: "CHAMPIEN",
    value: "80185",
    type: "common",
  },
  {
    label: "CARREPUIS",
    value: "80176",
    type: "common",
  },
  {
    label: "BILLANCOURT",
    value: "80105",
    type: "common",
  },
  {
    label: "BIARRE",
    value: "80103",
    type: "common",
  },
  {
    label: "BALATRE",
    value: "80053",
    type: "common",
  },
  {
    label: "SOLENTE",
    value: "60621",
    type: "common",
  },
  {
    label: "VILLERS-LES-ROYE",
    value: "80803",
    type: "common",
  },
  {
    label: "SAINT-MARD",
    value: "80708",
    type: "common",
  },
  {
    label: "ROYE",
    value: "80685",
    type: "common",
  },
  {
    label: "GOYENCOURT",
    value: "80383",
    type: "common",
  },
  {
    label: "FRESNOY-LES-ROYE",
    value: "80359",
    type: "common",
  },
  {
    label: "DAMERY",
    value: "80232",
    type: "common",
  },
  {
    label: "WARSY",
    value: "80822",
    type: "common",
  },
  {
    label: "GUERBIGNY",
    value: "80395",
    type: "common",
  },
  {
    label: "ERCHES",
    value: "80278",
    type: "common",
  },
  {
    label: "L'ECHELLE-SAINT-AURIN",
    value: "80263",
    type: "common",
  },
  {
    label: "ARVILLERS",
    value: "80031",
    type: "common",
  },
  {
    label: "ANDECHY",
    value: "80023",
    type: "common",
  },
  {
    label: "GRATIBUS",
    value: "80386",
    type: "common",
  },
  {
    label: "DAVENESCOURT",
    value: "80236",
    type: "common",
  },
  {
    label: "BOUSSICOURT",
    value: "80125",
    type: "common",
  },
  {
    label: "BECQUIGNY",
    value: "80074",
    type: "common",
  },
  {
    label: "SAUVILLERS-MONGIVAL",
    value: "80729",
    type: "common",
  },
  {
    label: "MALPART",
    value: "80504",
    type: "common",
  },
  {
    label: "GRIVESNES",
    value: "80390",
    type: "common",
  },
  {
    label: "BRACHES",
    value: "80132",
    type: "common",
  },
  {
    label: "BOUILLANCOURT-LA-BATAILLE",
    value: "80121",
    type: "common",
  },
  {
    label: "AUBVILLERS",
    value: "80037",
    type: "common",
  },
  {
    label: "THORY",
    value: "80758",
    type: "common",
  },
  {
    label: "SOURDON",
    value: "80740",
    type: "common",
  },
  {
    label: "MAILLY-RAINEVAL",
    value: "80499",
    type: "common",
  },
  {
    label: "LOUVRECHY",
    value: "80494",
    type: "common",
  },
  {
    label: "ESCLAINVILLERS",
    value: "80283",
    type: "common",
  },
  {
    label: "CHIRMONT",
    value: "80193",
    type: "common",
  },
  {
    label: "LAWARDE-MAUGER-L'HORTOY",
    value: "80469",
    type: "common",
  },
  {
    label: "HALLIVILLERS",
    value: "80407",
    type: "common",
  },
  {
    label: "LA FALOISE",
    value: "80299",
    type: "common",
  },
  {
    label: "CHAUSSOY-EPAGNY",
    value: "80188",
    type: "common",
  },
  {
    label: "AILLY-SUR-NOYE",
    value: "80010",
    type: "common",
  },
  {
    label: "ROGY",
    value: "80675",
    type: "common",
  },
  {
    label: "FRANSURES",
    value: "80349",
    type: "common",
  },
  {
    label: "FLERS-SUR-NOYE",
    value: "80315",
    type: "common",
  },
  {
    label: "BOSQUEL",
    value: "80114",
    type: "common",
  },
  {
    label: "GOUY-LES-GROSEILLERS",
    value: "60283",
    type: "common",
  },
  {
    label: "MONSURES",
    value: "80558",
    type: "common",
  },
  {
    label: "FLEURY",
    value: "80317",
    type: "common",
  },
  {
    label: "BELLEUSE",
    value: "80079",
    type: "common",
  },
  {
    label: "CROISSY-SUR-CELLE",
    value: "60183",
    type: "common",
  },
  {
    label: "THOIX",
    value: "80757",
    type: "common",
  },
  {
    label: "SENTELIE",
    value: "80734",
    type: "common",
  },
  {
    label: "COURCELLES-SOUS-THOIX",
    value: "80219",
    type: "common",
  },
  {
    label: "BRASSY",
    value: "80134",
    type: "common",
  },
  {
    label: "OFFOY",
    value: "60472",
    type: "common",
  },
  {
    label: "LAVERRIERE",
    value: "60354",
    type: "common",
  },
  {
    label: "MEREAUCOURT",
    value: "80528",
    type: "common",
  },
  {
    label: "GUIZANCOURT",
    value: "80402",
    type: "common",
  },
  {
    label: "EQUENNES-ERAMECOURT",
    value: "80276",
    type: "common",
  },
  {
    label: "DARGIES",
    value: "60194",
    type: "common",
  },
  {
    label: "DAMERAUCOURT",
    value: "60193",
    type: "common",
  },
  {
    label: "HESCAMPS",
    value: "80436",
    type: "common",
  },
  {
    label: "SARCUS",
    value: "60604",
    type: "common",
  },
  {
    label: "SAINT-THIBAULT",
    value: "60599",
    type: "common",
  },
  {
    label: "FOUILLOY",
    value: "60248",
    type: "common",
  },
  {
    label: "ELENCOURT",
    value: "60205",
    type: "common",
  },
  {
    label: "SAINT-VALERY",
    value: "60602",
    type: "common",
  },
  {
    label: "ROMESCAMPS",
    value: "60545",
    type: "common",
  },
  {
    label: "LANNOY-CUILLERE",
    value: "60347",
    type: "common",
  },
  {
    label: "GOURCHELLES",
    value: "60280",
    type: "common",
  },
  {
    label: "ABANCOURT",
    value: "60001",
    type: "common",
  },
  {
    label: "ILLOIS",
    value: "76372",
    type: "common",
  },
  {
    label: "HAUDRICOURT",
    value: "76344",
    type: "common",
  },
  {
    label: "CONTEVILLE",
    value: "76186",
    type: "common",
  },
  {
    label: "RONCHOIS",
    value: "76537",
    type: "common",
  },
  {
    label: "GRAVAL",
    value: "76323",
    type: "common",
  },
  {
    label: "FLAMETS-FRETILS",
    value: "76265",
    type: "common",
  },
  {
    label: "BEAUSSAULT",
    value: "76065",
    type: "common",
  },
  {
    label: "SAINT-SAIRE",
    value: "76649",
    type: "common",
  },
  {
    label: "NEUVILLE-FERRIERES",
    value: "76465",
    type: "common",
  },
  {
    label: "NESLE-HODENG",
    value: "76459",
    type: "common",
  },
  {
    label: "BOUELLES",
    value: "76130",
    type: "common",
  },
  {
    label: "QUIEVRECOURT",
    value: "76516",
    type: "common",
  },
  {
    label: "MASSY",
    value: "76415",
    type: "common",
  },
  {
    label: "ESCLAVELLES",
    value: "76244",
    type: "common",
  },
  {
    label: "VENTES-SAINT-REMY",
    value: "76733",
    type: "common",
  },
  {
    label: "POMMEREVAL",
    value: "76506",
    type: "common",
  },
  {
    label: "MAUCOMBLE",
    value: "76417",
    type: "common",
  },
  {
    label: "BULLY",
    value: "76147",
    type: "common",
  },
  {
    label: "SAINT-HELLIER",
    value: "76588",
    type: "common",
  },
  {
    label: "ROSAY",
    value: "76538",
    type: "common",
  },
  {
    label: "LA CRIQUE",
    value: "76193",
    type: "common",
  },
  {
    label: "BELLENCOMBRE",
    value: "76070",
    type: "common",
  },
  {
    label: "MONTREUIL-EN-CAUX",
    value: "76449",
    type: "common",
  },
  {
    label: "CROPUS",
    value: "76204",
    type: "common",
  },
  {
    label: "VASSONVILLE",
    value: "76723",
    type: "common",
  },
  {
    label: "SAINT-VAAST-DU-VAL",
    value: "76654",
    type: "common",
  },
  {
    label: "SAINT-DENIS-SUR-SCIE",
    value: "76574",
    type: "common",
  },
  {
    label: "CALLEVILLE-LES-DEUX-EGLISES",
    value: "76153",
    type: "common",
  },
  {
    label: "BIVILLE-LA-BAIGNARDE",
    value: "76096",
    type: "common",
  },
  {
    label: "VIBEUF",
    value: "76737",
    type: "common",
  },
  {
    label: "IMBLEVILLE",
    value: "76373",
    type: "common",
  },
  {
    label: "LA FONTELAYE",
    value: "76274",
    type: "common",
  },
  {
    label: "BELLEVILLE-EN-CAUX",
    value: "76072",
    type: "common",
  },
  {
    label: "VAL-DE-SAANE",
    value: "76018",
    type: "common",
  },
  {
    label: "LE TORP-MESNIL",
    value: "76699",
    type: "common",
  },
  {
    label: "OUVILLE-L'ABBAYE",
    value: "76491",
    type: "common",
  },
  {
    label: "LINDEBEUF",
    value: "76387",
    type: "common",
  },
  {
    label: "ETALLEVILLE",
    value: "76251",
    type: "common",
  },
  {
    label: "BOUDEVILLE",
    value: "76129",
    type: "common",
  },
  {
    label: "BERVILLE-EN-CAUX",
    value: "76087",
    type: "common",
  },
  {
    label: "AMFREVILLE-LES-CHAMPS",
    value: "76006",
    type: "common",
  },
  {
    label: "YVECRIQUE",
    value: "76757",
    type: "common",
  },
  {
    label: "ROUTES",
    value: "76542",
    type: "common",
  },
  {
    label: "HAUTOT-SAINT-SULPICE",
    value: "76348",
    type: "common",
  },
  {
    label: "HARCANVILLE",
    value: "76340",
    type: "common",
  },
  {
    label: "DOUDEVILLE",
    value: "76219",
    type: "common",
  },
  {
    label: "ANVEVILLE",
    value: "76023",
    type: "common",
  },
  {
    label: "SOMMESNIL",
    value: "76679",
    type: "common",
  },
  {
    label: "ROBERTOT",
    value: "76530",
    type: "common",
  },
  {
    label: "OHERVILLE",
    value: "76483",
    type: "common",
  },
  {
    label: "HERICOURT-EN-CAUX",
    value: "76355",
    type: "common",
  },
  {
    label: "LE HANOUARD",
    value: "76339",
    type: "common",
  },
  {
    label: "CLEUVILLE",
    value: "76180",
    type: "common",
  },
  {
    label: "CARVILLE-POT-DE-FER",
    value: "76161",
    type: "common",
  },
  {
    label: "ANCOURTEVILLE-SUR-HERICOURT",
    value: "76009",
    type: "common",
  },
  {
    label: "THIOUVILLE",
    value: "76692",
    type: "common",
  },
  {
    label: "SORQUAINVILLE",
    value: "76680",
    type: "common",
  },
  {
    label: "RIVILLE",
    value: "76529",
    type: "common",
  },
  {
    label: "NORMANVILLE",
    value: "76470",
    type: "common",
  },
  {
    label: "BEUZEVILLE-LA-GUERARD",
    value: "76091",
    type: "common",
  },
  {
    label: "YPREVILLE-BIVILLE",
    value: "76755",
    type: "common",
  },
  {
    label: "THIETREVILLE",
    value: "76689",
    type: "common",
  },
  {
    label: "THIERGEVILLE",
    value: "76688",
    type: "common",
  },
  {
    label: "LIMPIVILLE",
    value: "76386",
    type: "common",
  },
  {
    label: "DAUBEUF-SERVILLE",
    value: "76213",
    type: "common",
  },
  {
    label: "BENARVILLE",
    value: "76076",
    type: "common",
  },
  {
    label: "TOURVILLE-LES-IFS",
    value: "76706",
    type: "common",
  },
  {
    label: "MENTHEVILLE",
    value: "76425",
    type: "common",
  },
  {
    label: "CONTREMOULINS",
    value: "76187",
    type: "common",
  },
  {
    label: "BEC-DE-MORTAGNE",
    value: "76068",
    type: "common",
  },
  {
    label: "AUBERVILLE-LA-RENAULT",
    value: "76033",
    type: "common",
  },
  {
    label: "ANNOUVILLE-VILMESNIL",
    value: "76021",
    type: "common",
  },
  {
    label: "VATTETOT-SUR-MER",
    value: "76726",
    type: "common",
  },
  {
    label: "SAUSSEUZEMARE-EN-CAUX",
    value: "76669",
    type: "common",
  },
  {
    label: "MANIQUERVILLE",
    value: "76406",
    type: "common",
  },
  {
    label: "LES LOGES",
    value: "76390",
    type: "common",
  },
  {
    label: "GERVILLE",
    value: "76300",
    type: "common",
  },
  {
    label: "FROBERVILLE",
    value: "76291",
    type: "common",
  },
  {
    label: "FONGUEUSEMARE",
    value: "76268",
    type: "common",
  },
  {
    label: "EPREVILLE",
    value: "76240",
    type: "common",
  },
  {
    label: "LE TILLEUL",
    value: "76693",
    type: "common",
  },
  {
    label: "PIERREFIQUES",
    value: "76501",
    type: "common",
  },
  {
    label: "ETRETAT",
    value: "76254",
    type: "common",
  },
  {
    label: "CUVERVILLE",
    value: "76206",
    type: "common",
  },
  {
    label: "BORDEAUX-SAINT-CLAIR",
    value: "76117",
    type: "common",
  },
  {
    label: "BENOUVILLE",
    value: "76079",
    type: "common",
  },
  {
    label: "LA POTERIE-CAP-D'ANTIFER",
    value: "76508",
    type: "common",
  },
  {
    label: "MONTFARVILLE",
    value: "50342",
    type: "common",
  },
  {
    label: "BARFLEUR",
    value: "50030",
    type: "common",
  },
  {
    label: "GATTEVILLE-LE-PHARE",
    value: "50196",
    type: "common",
  },
  {
    label: "TOCQUEVILLE",
    value: "50598",
    type: "common",
  },
  {
    label: "LE VICEL",
    value: "50633",
    type: "common",
  },
  {
    label: "ANNEVILLE-EN-SAIRE",
    value: "50013",
    type: "common",
  },
  {
    label: "SAINTE-GENEVIEVE",
    value: "50469",
    type: "common",
  },
  {
    label: "VALCANVILLE",
    value: "50613",
    type: "common",
  },
  {
    label: "VAROUVILLE",
    value: "50618",
    type: "common",
  },
  {
    label: "CLITOURPS",
    value: "50135",
    type: "common",
  },
  {
    label: "THEVILLE",
    value: "50596",
    type: "common",
  },
  {
    label: "SAINT-PIERRE-EGLISE",
    value: "50539",
    type: "common",
  },
  {
    label: "FERMANVILLE",
    value: "50178",
    type: "common",
  },
  {
    label: "CANTELOUP",
    value: "50096",
    type: "common",
  },
  {
    label: "BRETTEVILLE",
    value: "50077",
    type: "common",
  },
  {
    label: "CARNEVILLE",
    value: "50101",
    type: "common",
  },
  {
    label: "MAUPERTUS-SUR-MER",
    value: "50296",
    type: "common",
  },
  {
    label: "GONNEVILLE-LE THEIL",
    value: "50209",
    type: "common",
  },
  {
    label: "CHERBOURG-EN-COTENTIN",
    value: "50129",
    type: "common",
  },
  {
    label: "NOUAINVILLE",
    value: "50382",
    type: "common",
  },
  {
    label: "LA HAGUE",
    value: "50041",
    type: "common",
  },
  {
    label: "MARGNY",
    value: "08275",
    type: "common",
  },
  {
    label: "HERBEUVAL",
    value: "08223",
    type: "common",
  },
  {
    label: "BREUX",
    value: "55077",
    type: "common",
  },
  {
    label: "MOIRY",
    value: "08293",
    type: "common",
  },
  {
    label: "SAPOGNE-SUR-MARCHE",
    value: "08399",
    type: "common",
  },
  {
    label: "PUILLY-ET-CHARBEAUX",
    value: "08347",
    type: "common",
  },
  {
    label: "AUFLANCE",
    value: "08029",
    type: "common",
  },
  {
    label: "FROMY",
    value: "08184",
    type: "common",
  },
  {
    label: "VILLY",
    value: "08485",
    type: "common",
  },
  {
    label: "SAILLY",
    value: "08376",
    type: "common",
  },
  {
    label: "LINAY",
    value: "08255",
    type: "common",
  },
  {
    label: "LES DEUX-VILLES",
    value: "08138",
    type: "common",
  },
  {
    label: "BLAGNY",
    value: "08067",
    type: "common",
  },
  {
    label: "CARIGNAN",
    value: "08090",
    type: "common",
  },
  {
    label: "TETAIGNE",
    value: "08444",
    type: "common",
  },
  {
    label: "VAUX-LES-MOUZON",
    value: "08466",
    type: "common",
  },
  {
    label: "EUILLY-ET-LOMBUT",
    value: "08159",
    type: "common",
  },
  {
    label: "REMILLY-AILLICOURT",
    value: "08357",
    type: "common",
  },
  {
    label: "VILLERS-DEVANT-MOUZON",
    value: "08477",
    type: "common",
  },
  {
    label: "AUTRECOURT-ET-POURRON",
    value: "08034",
    type: "common",
  },
  {
    label: "HARAUCOURT",
    value: "08211",
    type: "common",
  },
  {
    label: "BULSON",
    value: "08088",
    type: "common",
  },
  {
    label: "ANGECOURT",
    value: "08013",
    type: "common",
  },
  {
    label: "THELONNE",
    value: "08445",
    type: "common",
  },
  {
    label: "OMICOURT",
    value: "08334",
    type: "common",
  },
  {
    label: "CHEMERY-CHEHERY",
    value: "08115",
    type: "common",
  },
  {
    label: "SAINT-AIGNAN",
    value: "08377",
    type: "common",
  },
  {
    label: "VENDRESSE",
    value: "08469",
    type: "common",
  },
  {
    label: "VILLERS-LE-TILLEUL",
    value: "08478",
    type: "common",
  },
  {
    label: "SAPOGNE-ET-FEUCHERES",
    value: "08400",
    type: "common",
  },
  {
    label: "LA HORGNE",
    value: "08228",
    type: "common",
  },
  {
    label: "SINGLY",
    value: "08422",
    type: "common",
  },
  {
    label: "POIX-TERRON",
    value: "08341",
    type: "common",
  },
  {
    label: "RAILLICOURT",
    value: "08352",
    type: "common",
  },
  {
    label: "MONTIGNY-SUR-VENCE",
    value: "08305",
    type: "common",
  },
  {
    label: "MAZERNY",
    value: "08283",
    type: "common",
  },
  {
    label: "HAGNICOURT",
    value: "08205",
    type: "common",
  },
  {
    label: "VILLERS-LE-TOURNEUR",
    value: "08479",
    type: "common",
  },
  {
    label: "NEUVIZY",
    value: "08324",
    type: "common",
  },
  {
    label: "LAUNOIS-SUR-VENCE",
    value: "08248",
    type: "common",
  },
  {
    label: "VIEL-SAINT-REMY",
    value: "08472",
    type: "common",
  },
  {
    label: "WAGNON",
    value: "08496",
    type: "common",
  },
  {
    label: "LA NEUVILLE-LES-WASIGNY",
    value: "08323",
    type: "common",
  },
  {
    label: "WASIGNY",
    value: "08499",
    type: "common",
  },
  {
    label: "MESMONT",
    value: "08288",
    type: "common",
  },
  {
    label: "GRANDCHAMP",
    value: "08196",
    type: "common",
  },
  {
    label: "DOUMELY-BEGNY",
    value: "08143",
    type: "common",
  },
  {
    label: "GIVRON",
    value: "08192",
    type: "common",
  },
  {
    label: "DRAIZE",
    value: "08146",
    type: "common",
  },
  {
    label: "CHAUMONT-PORCIEN",
    value: "08113",
    type: "common",
  },
  {
    label: "SERAINCOURT",
    value: "08413",
    type: "common",
  },
  {
    label: "RENNEVILLE",
    value: "08360",
    type: "common",
  },
  {
    label: "FRAILLICOURT",
    value: "08178",
    type: "common",
  },
  {
    label: "BERLISE",
    value: "02069",
    type: "common",
  },
  {
    label: "LE THUEL",
    value: "02743",
    type: "common",
  },
  {
    label: "NOIRCOURT",
    value: "02556",
    type: "common",
  },
  {
    label: "DIZY-LE-GROS",
    value: "02264",
    type: "common",
  },
  {
    label: "SEVIGNY-WALEPPE",
    value: "08418",
    type: "common",
  },
  {
    label: "MONTLOUE",
    value: "02519",
    type: "common",
  },
  {
    label: "CLERMONT-LES-FERMES",
    value: "02200",
    type: "common",
  },
  {
    label: "BUCY-LES-PIERREPONT",
    value: "02133",
    type: "common",
  },
  {
    label: "BONCOURT",
    value: "02097",
    type: "common",
  },
  {
    label: "LA VILLE-AUX-BOIS-LES-DIZY",
    value: "02802",
    type: "common",
  },
  {
    label: "SAINTE-PREUVE",
    value: "02690",
    type: "common",
  },
  {
    label: "MACHECOURT",
    value: "02448",
    type: "common",
  },
  {
    label: "GOUDELANCOURT-LES-PIERREPONT",
    value: "02350",
    type: "common",
  },
  {
    label: "CHIVRES-EN-LAONNOIS",
    value: "02189",
    type: "common",
  },
  {
    label: "GRANDLUP-ET-FAY",
    value: "02353",
    type: "common",
  },
  {
    label: "VESLES-ET-CAUMONT",
    value: "02790",
    type: "common",
  },
  {
    label: "PIERREPONT",
    value: "02600",
    type: "common",
  },
  {
    label: "MISSY-LES-PIERREPONT",
    value: "02486",
    type: "common",
  },
  {
    label: "CHALANDRY",
    value: "02156",
    type: "common",
  },
  {
    label: "BARENTON-SUR-SERRE",
    value: "02048",
    type: "common",
  },
  {
    label: "BARENTON-CEL",
    value: "02047",
    type: "common",
  },
  {
    label: "BARENTON-BUGNY",
    value: "02046",
    type: "common",
  },
  {
    label: "VERNEUIL-SUR-SERRE",
    value: "02787",
    type: "common",
  },
  {
    label: "MONCEAU-LE-WAAST",
    value: "02493",
    type: "common",
  },
  {
    label: "CHERY-LES-POUILLY",
    value: "02180",
    type: "common",
  },
  {
    label: "AULNOIS-SOUS-LAON",
    value: "02037",
    type: "common",
  },
  {
    label: "VIVAISE",
    value: "02821",
    type: "common",
  },
  {
    label: "COUVRON-ET-AUMENCOURT",
    value: "02231",
    type: "common",
  },
  {
    label: "MONCEAU-LES-LEUPS",
    value: "02492",
    type: "common",
  },
  {
    label: "FRESSANCOURT",
    value: "02335",
    type: "common",
  },
  {
    label: "LA FERE",
    value: "02304",
    type: "common",
  },
  {
    label: "DEUILLET",
    value: "02262",
    type: "common",
  },
  {
    label: "DANIZY",
    value: "02260",
    type: "common",
  },
  {
    label: "CHARMES",
    value: "02165",
    type: "common",
  },
  {
    label: "BERTAUCOURT-EPOURDON",
    value: "02074",
    type: "common",
  },
  {
    label: "ANDELAIN",
    value: "02016",
    type: "common",
  },
  {
    label: "VERSIGNY",
    value: "02788",
    type: "common",
  },
  {
    label: "ROGECOURT",
    value: "02651",
    type: "common",
  },
  {
    label: "CONDREN",
    value: "02212",
    type: "common",
  },
  {
    label: "BEAUTOR",
    value: "02059",
    type: "common",
  },
  {
    label: "TERGNIER",
    value: "02738",
    type: "common",
  },
  {
    label: "VIRY-NOUREUIL",
    value: "02820",
    type: "common",
  },
  {
    label: "VILLEQUIER-AUMONT",
    value: "02807",
    type: "common",
  },
  {
    label: "GUIVRY",
    value: "02362",
    type: "common",
  },
  {
    label: "COMMENCHON",
    value: "02207",
    type: "common",
  },
  {
    label: "CAUMONT",
    value: "02145",
    type: "common",
  },
  {
    label: "BETHANCOURT-EN-VAUX",
    value: "02081",
    type: "common",
  },
  {
    label: "BERLANCOURT",
    value: "60062",
    type: "common",
  },
  {
    label: "BEAUGIES-SOUS-BOIS",
    value: "60052",
    type: "common",
  },
  {
    label: "UGNY-LE-GAY",
    value: "02754",
    type: "common",
  },
  {
    label: "LA NEUVILLE-EN-BEINE",
    value: "02546",
    type: "common",
  },
  {
    label: "QUESMY",
    value: "60519",
    type: "common",
  },
  {
    label: "MUIRANCOURT",
    value: "60443",
    type: "common",
  },
  {
    label: "MAUCOURT",
    value: "60389",
    type: "common",
  },
  {
    label: "GUISCARD",
    value: "60291",
    type: "common",
  },
  {
    label: "FRENICHES",
    value: "60255",
    type: "common",
  },
  {
    label: "FRETOY-LE-CHATEAU",
    value: "60263",
    type: "common",
  },
  {
    label: "ECUVILLY",
    value: "60204",
    type: "common",
  },
  {
    label: "CATIGNY",
    value: "60132",
    type: "common",
  },
  {
    label: "CAMPAGNE",
    value: "60121",
    type: "common",
  },
  {
    label: "BUSSY",
    value: "60117",
    type: "common",
  },
  {
    label: "BEAULIEU-LES-FONTAINES",
    value: "60053",
    type: "common",
  },
  {
    label: "VERPILLIERES",
    value: "80790",
    type: "common",
  },
  {
    label: "ROIGLISE",
    value: "80676",
    type: "common",
  },
  {
    label: "MARGNY-AUX-CERISES",
    value: "60381",
    type: "common",
  },
  {
    label: "AVRICOURT",
    value: "60035",
    type: "common",
  },
  {
    label: "AMY",
    value: "60011",
    type: "common",
  },
  {
    label: "TILLOLOY",
    value: "80759",
    type: "common",
  },
  {
    label: "LAUCOURT",
    value: "80467",
    type: "common",
  },
  {
    label: "DANCOURT-POPINCOURT",
    value: "80233",
    type: "common",
  },
  {
    label: "BEUVRAIGNES",
    value: "80101",
    type: "common",
  },
  {
    label: "CRAPEAUMESNIL",
    value: "60174",
    type: "common",
  },
  {
    label: "MARQUIVILLERS",
    value: "80517",
    type: "common",
  },
  {
    label: "LIGNIERES",
    value: "80478",
    type: "common",
  },
  {
    label: "LABOISSIERE-EN-SANTERRE",
    value: "80453",
    type: "common",
  },
  {
    label: "GRIVILLERS",
    value: "80391",
    type: "common",
  },
  {
    label: "FESCAMPS",
    value: "80306",
    type: "common",
  },
  {
    label: "BUS-LA-MESIERE",
    value: "80152",
    type: "common",
  },
  {
    label: "ARMANCOURT",
    value: "80027",
    type: "common",
  },
  {
    label: "MONTDIDIER",
    value: "80561",
    type: "common",
  },
  {
    label: "FIGNIERES",
    value: "80311",
    type: "common",
  },
  {
    label: "FAVEROLLES",
    value: "80302",
    type: "common",
  },
  {
    label: "ETELFAY",
    value: "80293",
    type: "common",
  },
  {
    label: "COURTEMANCHE",
    value: "80220",
    type: "common",
  },
  {
    label: "AYENCOURT",
    value: "80049",
    type: "common",
  },
  {
    label: "VILLERS-TOURNELLE",
    value: "80805",
    type: "common",
  },
  {
    label: "MESNIL-SAINT-GEORGES",
    value: "80541",
    type: "common",
  },
  {
    label: "MARESTMONTIERS",
    value: "80511",
    type: "common",
  },
  {
    label: "FONTAINE-SOUS-MONTDIDIER",
    value: "80326",
    type: "common",
  },
  {
    label: "LE CARDONNOIS",
    value: "80174",
    type: "common",
  },
  {
    label: "CANTIGNY",
    value: "80170",
    type: "common",
  },
  {
    label: "BROYES",
    value: "60111",
    type: "common",
  },
  {
    label: "QUIRY-LE-SEC",
    value: "80657",
    type: "common",
  },
  {
    label: "COULLEMELLE",
    value: "80214",
    type: "common",
  },
  {
    label: "TARTIGNY",
    value: "60627",
    type: "common",
  },
  {
    label: "SEREVILLERS",
    value: "60615",
    type: "common",
  },
  {
    label: "ROCQUENCOURT",
    value: "60544",
    type: "common",
  },
  {
    label: "FOLLEVILLE",
    value: "80321",
    type: "common",
  },
  {
    label: "ROUVROY-LES-MERLES",
    value: "60555",
    type: "common",
  },
  {
    label: "PAILLART",
    value: "60486",
    type: "common",
  },
  {
    label: "ESQUENNOY",
    value: "60221",
    type: "common",
  },
  {
    label: "BRETEUIL",
    value: "60104",
    type: "common",
  },
  {
    label: "VILLERS-VICOMTE",
    value: "60692",
    type: "common",
  },
  {
    label: "FLECHY",
    value: "60237",
    type: "common",
  },
  {
    label: "CORMEILLES",
    value: "60163",
    type: "common",
  },
  {
    label: "BONNEUIL-LES-EAUX",
    value: "60082",
    type: "common",
  },
  {
    label: "BLANCFOSSE",
    value: "60075",
    type: "common",
  },
  {
    label: "LE SAULCHOY",
    value: "60608",
    type: "common",
  },
  {
    label: "LAVACQUERIE",
    value: "60353",
    type: "common",
  },
  {
    label: "LE GALLET",
    value: "60267",
    type: "common",
  },
  {
    label: "FONTAINE-BONNELEAU",
    value: "60240",
    type: "common",
  },
  {
    label: "DOMELIERS",
    value: "60199",
    type: "common",
  },
  {
    label: "CATHEUX",
    value: "60131",
    type: "common",
  },
  {
    label: "SOMMEREUX",
    value: "60622",
    type: "common",
  },
  {
    label: "LE MESNIL-CONTEVILLE",
    value: "60397",
    type: "common",
  },
  {
    label: "HETOMESNIL",
    value: "60314",
    type: "common",
  },
  {
    label: "LE HAMEL",
    value: "60297",
    type: "common",
  },
  {
    label: "CONTEVILLE",
    value: "60161",
    type: "common",
  },
  {
    label: "CHOQUEUSE-LES-BENARDS",
    value: "60153",
    type: "common",
  },
  {
    label: "BEAUDEDUIT",
    value: "60051",
    type: "common",
  },
  {
    label: "THIEULOY-SAINT-ANTOINE",
    value: "60633",
    type: "common",
  },
  {
    label: "SARNOIS",
    value: "60605",
    type: "common",
  },
  {
    label: "HALLOY",
    value: "60295",
    type: "common",
  },
  {
    label: "GREZ",
    value: "60289",
    type: "common",
  },
  {
    label: "GRANDVILLIERS",
    value: "60286",
    type: "common",
  },
  {
    label: "CEMPUIS",
    value: "60136",
    type: "common",
  },
  {
    label: "BRIOT",
    value: "60108",
    type: "common",
  },
  {
    label: "HAUTBOS",
    value: "60303",
    type: "common",
  },
  {
    label: "FEUQUIERES",
    value: "60233",
    type: "common",
  },
  {
    label: "BROQUIERS",
    value: "60110",
    type: "common",
  },
  {
    label: "BROMBOS",
    value: "60109",
    type: "common",
  },
  {
    label: "SAINT-ARNOULT",
    value: "60566",
    type: "common",
  },
  {
    label: "MONCEAUX-L'ABBAYE",
    value: "60407",
    type: "common",
  },
  {
    label: "MOLIENS",
    value: "60405",
    type: "common",
  },
  {
    label: "BOUVRESSE",
    value: "60098",
    type: "common",
  },
  {
    label: "BLARGIES",
    value: "60076",
    type: "common",
  },
  {
    label: "HAUCOURT",
    value: "76343",
    type: "common",
  },
  {
    label: "CRIQUIERS",
    value: "76199",
    type: "common",
  },
  {
    label: "LE THIL-RIBERPRE",
    value: "76691",
    type: "common",
  },
  {
    label: "GAILLEFONTAINE",
    value: "76295",
    type: "common",
  },
  {
    label: "COMPAINVILLE",
    value: "76185",
    type: "common",
  },
  {
    label: "SERQUEUX",
    value: "76672",
    type: "common",
  },
  {
    label: "MESNIL-MAUGER",
    value: "76432",
    type: "common",
  },
  {
    label: "BEAUBEC-LA-ROSIERE",
    value: "76060",
    type: "common",
  },
  {
    label: "SOMMERY",
    value: "76678",
    type: "common",
  },
  {
    label: "SAINTE-GENEVIEVE",
    value: "76578",
    type: "common",
  },
  {
    label: "MATHONVILLE",
    value: "76416",
    type: "common",
  },
  {
    label: "FONTAINE-EN-BRAY",
    value: "76269",
    type: "common",
  },
  {
    label: "BRADIANCOURT",
    value: "76139",
    type: "common",
  },
  {
    label: "SAINT-SAENS",
    value: "76648",
    type: "common",
  },
  {
    label: "SAINT-MARTIN-OSMONVILLE",
    value: "76621",
    type: "common",
  },
  {
    label: "NEUFBOSC",
    value: "76461",
    type: "common",
  },
  {
    label: "MONTEROLIER",
    value: "76445",
    type: "common",
  },
  {
    label: "BOSC-MESNIL",
    value: "76126",
    type: "common",
  },
  {
    label: "GRIGNEUSEVILLE",
    value: "76328",
    type: "common",
  },
  {
    label: "COTTEVRARD",
    value: "76188",
    type: "common",
  },
  {
    label: "BOSC-LE-HARD",
    value: "76125",
    type: "common",
  },
  {
    label: "BOSC-BERENGER",
    value: "76119",
    type: "common",
  },
  {
    label: "BEAUMONT-LE-HARENG",
    value: "76062",
    type: "common",
  },
  {
    label: "SAINT-VICTOR-L'ABBAYE",
    value: "76656",
    type: "common",
  },
  {
    label: "FRICHEMESNIL",
    value: "76290",
    type: "common",
  },
  {
    label: "ETAIMPUIS",
    value: "76249",
    type: "common",
  },
  {
    label: "BRACQUETUIT",
    value: "76138",
    type: "common",
  },
  {
    label: "VARNEVILLE-BRETTEVILLE",
    value: "76721",
    type: "common",
  },
  {
    label: "TOTES",
    value: "76700",
    type: "common",
  },
  {
    label: "SAINT-OUEN-DU-BREUIL",
    value: "76628",
    type: "common",
  },
  {
    label: "SAINT-MACLOU-DE-FOLLEVILLE",
    value: "76602",
    type: "common",
  },
  {
    label: "LA HOUSSAYE-BERANGER",
    value: "76369",
    type: "common",
  },
  {
    label: "GUEUTTEVILLE",
    value: "76335",
    type: "common",
  },
  {
    label: "FRESNAY-LE-LONG",
    value: "76284",
    type: "common",
  },
  {
    label: "BEAUTOT",
    value: "76066",
    type: "common",
  },
  {
    label: "SAUSSAY",
    value: "76668",
    type: "common",
  },
  {
    label: "HUGLEVILLE-EN-CAUX",
    value: "76370",
    type: "common",
  },
  {
    label: "EMANVILLE",
    value: "76234",
    type: "common",
  },
  {
    label: "ECTOT-L'AUBER",
    value: "76227",
    type: "common",
  },
  {
    label: "BOURDAINVILLE",
    value: "76132",
    type: "common",
  },
  {
    label: "BERTRIMONT",
    value: "76086",
    type: "common",
  },
  {
    label: "ANCRETIEVILLE-SAINT-VICTOR",
    value: "76010",
    type: "common",
  },
  {
    label: "YERVILLE",
    value: "76752",
    type: "common",
  },
  {
    label: "SAINT-MARTIN-AUX-ARBRES",
    value: "76611",
    type: "common",
  },
  {
    label: "MOTTEVILLE",
    value: "76456",
    type: "common",
  },
  {
    label: "GREMONVILLE",
    value: "76325",
    type: "common",
  },
  {
    label: "FLAMANVILLE",
    value: "76264",
    type: "common",
  },
  {
    label: "CRIQUETOT-SUR-OUVILLE",
    value: "76198",
    type: "common",
  },
  {
    label: "AUZOUVILLE-L'ESNEVAL",
    value: "76045",
    type: "common",
  },
  {
    label: "YVETOT",
    value: "76758",
    type: "common",
  },
  {
    label: "SAINTE-MARIE-DES-CHAMPS",
    value: "76610",
    type: "common",
  },
  {
    label: "ETOUTTEVILLE",
    value: "76253",
    type: "common",
  },
  {
    label: "ECTOT-LES-BAONS",
    value: "76228",
    type: "common",
  },
  {
    label: "ECALLES-ALIX",
    value: "76223",
    type: "common",
  },
  {
    label: "BAONS-LE-COMTE",
    value: "76055",
    type: "common",
  },
  {
    label: "VALLIQUERVILLE",
    value: "76718",
    type: "common",
  },
  {
    label: "ROCQUEFORT",
    value: "76531",
    type: "common",
  },
  {
    label: "HAUTOT-LE-VATOIS",
    value: "76347",
    type: "common",
  },
  {
    label: "ENVRONVILLE",
    value: "76236",
    type: "common",
  },
  {
    label: "ECRETTEVILLE-LES-BAONS",
    value: "76225",
    type: "common",
  },
  {
    label: "CLIPONVILLE",
    value: "76182",
    type: "common",
  },
  {
    label: "TERRES-DE-CAUX",
    value: "76258",
    type: "common",
  },
  {
    label: "CLEVILLE",
    value: "76181",
    type: "common",
  },
  {
    label: "YEBLERON",
    value: "76751",
    type: "common",
  },
  {
    label: "TREMAUVILLE",
    value: "76710",
    type: "common",
  },
  {
    label: "TOCQUEVILLE-LES-MURS",
    value: "76695",
    type: "common",
  },
  {
    label: "SAINT-MACLOU-LA-BRIERE",
    value: "76603",
    type: "common",
  },
  {
    label: "ROUVILLE",
    value: "76543",
    type: "common",
  },
  {
    label: "HATTENVILLE",
    value: "76342",
    type: "common",
  },
  {
    label: "BERNIERES",
    value: "76082",
    type: "common",
  },
  {
    label: "VATTETOT-SOUS-BEAUMONT",
    value: "76725",
    type: "common",
  },
  {
    label: "GRAINVILLE-YMAUVILLE",
    value: "76317",
    type: "common",
  },
  {
    label: "GONFREVILLE-CAILLOT",
    value: "76304",
    type: "common",
  },
  {
    label: "BRETTEVILLE-DU-GRAND-CAUX",
    value: "76143",
    type: "common",
  },
  {
    label: "BREAUTE",
    value: "76141",
    type: "common",
  },
  {
    label: "ANGERVILLE-BAILLEUL",
    value: "76012",
    type: "common",
  },
  {
    label: "VERGETOT",
    value: "76734",
    type: "common",
  },
  {
    label: "SAINT-SAUVEUR-D'EMALLEVILLE",
    value: "76650",
    type: "common",
  },
  {
    label: "MANNEVILLE-LA-GOUPIL",
    value: "76408",
    type: "common",
  },
  {
    label: "GODERVILLE",
    value: "76302",
    type: "common",
  },
  {
    label: "ECRAINVILLE",
    value: "76224",
    type: "common",
  },
  {
    label: "BORNAMBUSC",
    value: "76118",
    type: "common",
  },
  {
    label: "VILLAINVILLE",
    value: "76741",
    type: "common",
  },
  {
    label: "TURRETOT",
    value: "76716",
    type: "common",
  },
  {
    label: "SAINTE-MARIE-AU-BOSC",
    value: "76609",
    type: "common",
  },
  {
    label: "GONNEVILLE-LA-MALLET",
    value: "76307",
    type: "common",
  },
  {
    label: "CRIQUETOT-L'ESNEVAL",
    value: "76196",
    type: "common",
  },
  {
    label: "BEAUREPAIRE",
    value: "76064",
    type: "common",
  },
  {
    label: "ANGLESQUEVILLE-L'ESNEVAL",
    value: "76017",
    type: "common",
  },
  {
    label: "SAINT-JOUIN-BRUNEVAL",
    value: "76595",
    type: "common",
  },
  {
    label: "HEUQUEVILLE",
    value: "76361",
    type: "common",
  },
  {
    label: "REVILLE",
    value: "50433",
    type: "common",
  },
  {
    label: "SAINT-VAAST-LA-HOUGUE",
    value: "50562",
    type: "common",
  },
  {
    label: "LA PERNELLE",
    value: "50395",
    type: "common",
  },
  {
    label: "LE VAST",
    value: "50619",
    type: "common",
  },
  {
    label: "BRILLEVAST",
    value: "50086",
    type: "common",
  },
  {
    label: "TEURTHEVILLE-BOCAGE",
    value: "50593",
    type: "common",
  },
  {
    label: "LE MESNIL-AU-VAL",
    value: "50305",
    type: "common",
  },
  {
    label: "DIGOSVILLE",
    value: "50162",
    type: "common",
  },
  {
    label: "SAUSSEMESNIL",
    value: "50567",
    type: "common",
  },
  {
    label: "TOLLEVAST",
    value: "50599",
    type: "common",
  },
  {
    label: "HARDINVAST",
    value: "50230",
    type: "common",
  },
  {
    label: "MARTINVAST",
    value: "50294",
    type: "common",
  },
  {
    label: "SIDEVILLE",
    value: "50575",
    type: "common",
  },
  {
    label: "TEURTHEVILLE-HAGUE",
    value: "50594",
    type: "common",
  },
  {
    label: "VIRANDEVILLE",
    value: "50643",
    type: "common",
  },
  {
    label: "HEAUVILLE",
    value: "50238",
    type: "common",
  },
  {
    label: "SIOUVILLE-HAGUE",
    value: "50576",
    type: "common",
  },
  {
    label: "SAULNES",
    value: "54493",
    type: "common",
  },
  {
    label: "LONGLAVILLE",
    value: "54321",
    type: "common",
  },
  {
    label: "MONT-SAINT-MARTIN",
    value: "54382",
    type: "common",
  },
  {
    label: "LONGWY",
    value: "54323",
    type: "common",
  },
  {
    label: "HERSERANGE",
    value: "54261",
    type: "common",
  },
  {
    label: "COSNES-ET-ROMAIN",
    value: "54138",
    type: "common",
  },
  {
    label: "VILLE-HOUDLEMONT",
    value: "54572",
    type: "common",
  },
  {
    label: "SAINT-PANCRE",
    value: "54485",
    type: "common",
  },
  {
    label: "GORCY",
    value: "54234",
    type: "common",
  },
  {
    label: "ECOUVIEZ",
    value: "55169",
    type: "common",
  },
  {
    label: "VERNEUIL-PETIT",
    value: "55547",
    type: "common",
  },
  {
    label: "VERNEUIL-GRAND",
    value: "55546",
    type: "common",
  },
  {
    label: "THONNELLE",
    value: "55511",
    type: "common",
  },
  {
    label: "THONNE-LA-LONG",
    value: "55508",
    type: "common",
  },
  {
    label: "AVIOTH",
    value: "55022",
    type: "common",
  },
  {
    label: "BIEVRES",
    value: "08065",
    type: "common",
  },
  {
    label: "MARGUT",
    value: "08276",
    type: "common",
  },
  {
    label: "SIGNY-MONTLIBERT",
    value: "08421",
    type: "common",
  },
  {
    label: "THONNE-LES-PRES",
    value: "55510",
    type: "common",
  },
  {
    label: "THONNE-LE-THIL",
    value: "55509",
    type: "common",
  },
  {
    label: "CHAUVENCY-SAINT-HUBERT",
    value: "55110",
    type: "common",
  },
  {
    label: "LA FERTE-SUR-CHIERS",
    value: "08168",
    type: "common",
  },
  {
    label: "MALANDRY",
    value: "08269",
    type: "common",
  },
  {
    label: "OLIZY-SUR-CHIERS",
    value: "55391",
    type: "common",
  },
  {
    label: "NEPVANT",
    value: "55377",
    type: "common",
  },
  {
    label: "MARTINCOURT-SUR-MEUSE",
    value: "55323",
    type: "common",
  },
  {
    label: "LAMOUILLY",
    value: "55275",
    type: "common",
  },
  {
    label: "LETANNE",
    value: "08252",
    type: "common",
  },
  {
    label: "POUILLY-SUR-MEUSE",
    value: "55408",
    type: "common",
  },
  {
    label: "MOULINS-SAINT-HUBERT",
    value: "55362",
    type: "common",
  },
  {
    label: "INOR",
    value: "55250",
    type: "common",
  },
  {
    label: "AUTREVILLE-SAINT-LAMBERT",
    value: "55018",
    type: "common",
  },
  {
    label: "YONCQ",
    value: "08502",
    type: "common",
  },
  {
    label: "MOUZON",
    value: "08311",
    type: "common",
  },
  {
    label: "MAISONCELLE-ET-VILLERS",
    value: "08268",
    type: "common",
  },
  {
    label: "STONNE",
    value: "08430",
    type: "common",
  },
  {
    label: "RAUCOURT-ET-FLABA",
    value: "08354",
    type: "common",
  },
  {
    label: "LA BESACE",
    value: "08063",
    type: "common",
  },
  {
    label: "LA NEUVILLE-A-MAIRE",
    value: "08317",
    type: "common",
  },
  {
    label: "ARTAISE-LE-VIVIER",
    value: "08023",
    type: "common",
  },
  {
    label: "LE MONT-DIEU",
    value: "08300",
    type: "common",
  },
  {
    label: "SAUVILLE",
    value: "08405",
    type: "common",
  },
  {
    label: "OMONT",
    value: "08335",
    type: "common",
  },
  {
    label: "LA SABOTTERIE",
    value: "08374",
    type: "common",
  },
  {
    label: "MARQUIGNY",
    value: "08278",
    type: "common",
  },
  {
    label: "JONVAL",
    value: "08238",
    type: "common",
  },
  {
    label: "GUINCOURT",
    value: "08204",
    type: "common",
  },
  {
    label: "CHAGNY",
    value: "08095",
    type: "common",
  },
  {
    label: "BOUVELLEMONT",
    value: "08080",
    type: "common",
  },
  {
    label: "BAALONS",
    value: "08041",
    type: "common",
  },
  {
    label: "SAINT-LOUP-TERRIER",
    value: "08387",
    type: "common",
  },
  {
    label: "PUISEUX",
    value: "08348",
    type: "common",
  },
  {
    label: "WIGNICOURT",
    value: "08500",
    type: "common",
  },
  {
    label: "VAUX-MONTREUIL",
    value: "08467",
    type: "common",
  },
  {
    label: "CHESNOIS-AUBONCOURT",
    value: "08117",
    type: "common",
  },
  {
    label: "CORNY-MACHEROMENIL",
    value: "08132",
    type: "common",
  },
  {
    label: "FAISSAULT",
    value: "08163",
    type: "common",
  },
  {
    label: "SAULCES-MONCLIN",
    value: "08402",
    type: "common",
  },
  {
    label: "AUBONCOURT-VAUZELLES",
    value: "08027",
    type: "common",
  },
  {
    label: "SORBON",
    value: "08427",
    type: "common",
  },
  {
    label: "SERY",
    value: "08415",
    type: "common",
  },
  {
    label: "NOVION-PORCIEN",
    value: "08329",
    type: "common",
  },
  {
    label: "ARNICOURT",
    value: "08021",
    type: "common",
  },
  {
    label: "JUSTINE-HERBIGNY",
    value: "08240",
    type: "common",
  },
  {
    label: "CHAPPES",
    value: "08102",
    type: "common",
  },
  {
    label: "SON",
    value: "08426",
    type: "common",
  },
  {
    label: "INAUMONT",
    value: "08234",
    type: "common",
  },
  {
    label: "HAUTEVILLE",
    value: "08219",
    type: "common",
  },
  {
    label: "ECLY",
    value: "08150",
    type: "common",
  },
  {
    label: "REMAUCOURT",
    value: "08356",
    type: "common",
  },
  {
    label: "SAINT-FERGEUX",
    value: "08380",
    type: "common",
  },
  {
    label: "HANNOGNE-SAINT-REMY",
    value: "08210",
    type: "common",
  },
  {
    label: "BANOGNE-RECOUVRANCE",
    value: "08046",
    type: "common",
  },
  {
    label: "SAINT-QUENTIN-LE-PETIT",
    value: "08396",
    type: "common",
  },
  {
    label: "LA SELVE",
    value: "02705",
    type: "common",
  },
  {
    label: "NIZY-LE-COMTE",
    value: "02553",
    type: "common",
  },
  {
    label: "LAPPION",
    value: "02409",
    type: "common",
  },
  {
    label: "MARCHAIS",
    value: "02457",
    type: "common",
  },
  {
    label: "LIESSE-NOTRE-DAME",
    value: "02430",
    type: "common",
  },
  {
    label: "MONTAIGU",
    value: "02498",
    type: "common",
  },
  {
    label: "GIZY",
    value: "02346",
    type: "common",
  },
  {
    label: "SAMOUSSY",
    value: "02697",
    type: "common",
  },
  {
    label: "CHAMBRY",
    value: "02157",
    type: "common",
  },
  {
    label: "ATHIES-SOUS-LAON",
    value: "02028",
    type: "common",
  },
  {
    label: "LAON",
    value: "02408",
    type: "common",
  },
  {
    label: "CERNY-LES-BUCY",
    value: "02151",
    type: "common",
  },
  {
    label: "BESNY-ET-LOIZY",
    value: "02080",
    type: "common",
  },
  {
    label: "MOLINCHART",
    value: "02489",
    type: "common",
  },
  {
    label: "FOURDRAIN",
    value: "02329",
    type: "common",
  },
  {
    label: "CREPY",
    value: "02238",
    type: "common",
  },
  {
    label: "BUCY-LES-CERNY",
    value: "02132",
    type: "common",
  },
  {
    label: "BRIE",
    value: "02122",
    type: "common",
  },
  {
    label: "SEPTVAUX",
    value: "02707",
    type: "common",
  },
  {
    label: "SAINT-NICOLAS-AUX-BOIS",
    value: "02685",
    type: "common",
  },
  {
    label: "SAINT-GOBAIN",
    value: "02680",
    type: "common",
  },
  {
    label: "BARISIS-AUX-BOIS",
    value: "02049",
    type: "common",
  },
  {
    label: "AMIGNY-ROUY",
    value: "02014",
    type: "common",
  },
  {
    label: "SINCENY",
    value: "02719",
    type: "common",
  },
  {
    label: "SERVAIS",
    value: "02716",
    type: "common",
  },
  {
    label: "MANICAMP",
    value: "02456",
    type: "common",
  },
  {
    label: "CHAUNY",
    value: "02173",
    type: "common",
  },
  {
    label: "BICHANCOURT",
    value: "02086",
    type: "common",
  },
  {
    label: "AUTREVILLE",
    value: "02041",
    type: "common",
  },
  {
    label: "ABBECOURT",
    value: "02001",
    type: "common",
  },
  {
    label: "PIERREMANDE",
    value: "02599",
    type: "common",
  },
  {
    label: "OGNES",
    value: "02566",
    type: "common",
  },
  {
    label: "MAREST-DAMPCOURT",
    value: "02461",
    type: "common",
  },
  {
    label: "CAILLOUEL-CREPIGNY",
    value: "02139",
    type: "common",
  },
  {
    label: "MONDESCOURT",
    value: "60410",
    type: "common",
  },
  {
    label: "BABŒUF",
    value: "60037",
    type: "common",
  },
  {
    label: "APPILLY",
    value: "60021",
    type: "common",
  },
  {
    label: "QUIERZY",
    value: "02631",
    type: "common",
  },
  {
    label: "NEUFLIEUX",
    value: "02542",
    type: "common",
  },
  {
    label: "SALENCY",
    value: "60603",
    type: "common",
  },
  {
    label: "NOYON",
    value: "60471",
    type: "common",
  },
  {
    label: "MORLINCOURT",
    value: "60431",
    type: "common",
  },
  {
    label: "GRANDRU",
    value: "60287",
    type: "common",
  },
  {
    label: "GENVRY",
    value: "60270",
    type: "common",
  },
  {
    label: "CRISOLLES",
    value: "60181",
    type: "common",
  },
  {
    label: "BEHERICOURT",
    value: "60059",
    type: "common",
  },
  {
    label: "VAUCHELLES",
    value: "60657",
    type: "common",
  },
  {
    label: "SUZOY",
    value: "60625",
    type: "common",
  },
  {
    label: "SERMAIZE",
    value: "60617",
    type: "common",
  },
  {
    label: "PORQUERICOURT",
    value: "60511",
    type: "common",
  },
  {
    label: "LARBROYE",
    value: "60348",
    type: "common",
  },
  {
    label: "LAGNY",
    value: "60340",
    type: "common",
  },
  {
    label: "EVRICOURT",
    value: "60227",
    type: "common",
  },
  {
    label: "CUY",
    value: "60192",
    type: "common",
  },
  {
    label: "BEAURAINS-LES-NOYON",
    value: "60055",
    type: "common",
  },
  {
    label: "PLESSIS-DE-ROYE",
    value: "60499",
    type: "common",
  },
  {
    label: "LASSIGNY",
    value: "60350",
    type: "common",
  },
  {
    label: "DIVES",
    value: "60198",
    type: "common",
  },
  {
    label: "CANDOR",
    value: "60124",
    type: "common",
  },
  {
    label: "ROYE-SUR-MATZ",
    value: "60558",
    type: "common",
  },
  {
    label: "LABERLIERE",
    value: "60329",
    type: "common",
  },
  {
    label: "GURY",
    value: "60292",
    type: "common",
  },
  {
    label: "FRESNIERES",
    value: "60258",
    type: "common",
  },
  {
    label: "CONCHY-LES-POTS",
    value: "60160",
    type: "common",
  },
  {
    label: "CANNY-SUR-MATZ",
    value: "60127",
    type: "common",
  },
  {
    label: "BIERMONT",
    value: "60071",
    type: "common",
  },
  {
    label: "ROLLOT",
    value: "80678",
    type: "common",
  },
  {
    label: "REMAUGIES",
    value: "80667",
    type: "common",
  },
  {
    label: "PIENNES-ONVILLERS",
    value: "80623",
    type: "common",
  },
  {
    label: "ORVILLERS-SOREL",
    value: "60483",
    type: "common",
  },
  {
    label: "MORTEMER",
    value: "60434",
    type: "common",
  },
  {
    label: "HAINVILLERS",
    value: "60294",
    type: "common",
  },
  {
    label: "BOULOGNE-LA-GRASSE",
    value: "60093",
    type: "common",
  },
  {
    label: "RUBESCOURT",
    value: "80687",
    type: "common",
  },
  {
    label: "ASSAINVILLERS",
    value: "80032",
    type: "common",
  },
  {
    label: "LE PLOYRON",
    value: "60503",
    type: "common",
  },
  {
    label: "GODENVILLERS",
    value: "60276",
    type: "common",
  },
  {
    label: "LE FRESTOY-VAUX",
    value: "60262",
    type: "common",
  },
  {
    label: "DOMFRONT",
    value: "60200",
    type: "common",
  },
  {
    label: "COURCELLES-EPAYELLES",
    value: "60168",
    type: "common",
  },
  {
    label: "WELLES-PERENNES",
    value: "60702",
    type: "common",
  },
  {
    label: "SAINS-MORAINVILLERS",
    value: "60564",
    type: "common",
  },
  {
    label: "ROYAUCOURT",
    value: "60556",
    type: "common",
  },
  {
    label: "FERRIERES",
    value: "60232",
    type: "common",
  },
  {
    label: "DOMPIERRE",
    value: "60201",
    type: "common",
  },
  {
    label: "CREVECŒUR-LE-PETIT",
    value: "60179",
    type: "common",
  },
  {
    label: "PLAINVILLE",
    value: "60496",
    type: "common",
  },
  {
    label: "MORY-MONTCRUX",
    value: "60436",
    type: "common",
  },
  {
    label: "LE MESNIL-SAINT-FIRMIN",
    value: "60399",
    type: "common",
  },
  {
    label: "LA HERELLE",
    value: "60311",
    type: "common",
  },
  {
    label: "GANNES",
    value: "60268",
    type: "common",
  },
  {
    label: "CHEPOIX",
    value: "60146",
    type: "common",
  },
  {
    label: "BACOUEL",
    value: "60039",
    type: "common",
  },
  {
    label: "ANSAUVILLERS",
    value: "60017",
    type: "common",
  },
  {
    label: "VENDEUIL-CAPLY",
    value: "60664",
    type: "common",
  },
  {
    label: "SAINT-ANDRE-FARIVILLERS",
    value: "60565",
    type: "common",
  },
  {
    label: "BONVILLERS",
    value: "60085",
    type: "common",
  },
  {
    label: "BEAUVOIR",
    value: "60058",
    type: "common",
  },
  {
    label: "TROUSSENCOURT",
    value: "60648",
    type: "common",
  },
  {
    label: "SAINTE-EUSOYE",
    value: "60573",
    type: "common",
  },
  {
    label: "PUITS-LA-VALLEE",
    value: "60518",
    type: "common",
  },
  {
    label: "MAISONCELLE-TUILERIE",
    value: "60377",
    type: "common",
  },
  {
    label: "HARDIVILLERS",
    value: "60299",
    type: "common",
  },
  {
    label: "LE CROCQ",
    value: "60182",
    type: "common",
  },
  {
    label: "OURSEL-MAISON",
    value: "60485",
    type: "common",
  },
  {
    label: "VIEFVILLERS",
    value: "60673",
    type: "common",
  },
  {
    label: "FRANCASTEL",
    value: "60253",
    type: "common",
  },
  {
    label: "AUCHY-LA-MONTAGNE",
    value: "60026",
    type: "common",
  },
  {
    label: "ROTANGY",
    value: "60549",
    type: "common",
  },
  {
    label: "PREVILLERS",
    value: "60514",
    type: "common",
  },
  {
    label: "LA NEUVILLE-SUR-OUDEUIL",
    value: "60458",
    type: "common",
  },
  {
    label: "LIHUS",
    value: "60365",
    type: "common",
  },
  {
    label: "HAUTE-EPINE",
    value: "60304",
    type: "common",
  },
  {
    label: "CREVECŒUR-LE-GRAND",
    value: "60178",
    type: "common",
  },
  {
    label: "SAINT-MAUR",
    value: "60588",
    type: "common",
  },
  {
    label: "ROY-BOISSY",
    value: "60557",
    type: "common",
  },
  {
    label: "ROTHOIS",
    value: "60550",
    type: "common",
  },
  {
    label: "MARSEILLE-EN-BEAUVAISIS",
    value: "60387",
    type: "common",
  },
  {
    label: "GAUDECHART",
    value: "60269",
    type: "common",
  },
  {
    label: "FONTAINE-LAVAGANNE",
    value: "60242",
    type: "common",
  },
  {
    label: "THERINES",
    value: "60629",
    type: "common",
  },
  {
    label: "SAINT-DENISCOURT",
    value: "60571",
    type: "common",
  },
  {
    label: "OMECOURT",
    value: "60476",
    type: "common",
  },
  {
    label: "MORVILLERS",
    value: "60435",
    type: "common",
  },
  {
    label: "LOUEUSE",
    value: "60371",
    type: "common",
  },
  {
    label: "VILLERS-VERMONT",
    value: "60691",
    type: "common",
  },
  {
    label: "SAINT-SAMSON-LA-POTERIE",
    value: "60596",
    type: "common",
  },
  {
    label: "MUREAUMONT",
    value: "60444",
    type: "common",
  },
  {
    label: "HERICOURT-SUR-THERAIN",
    value: "60312",
    type: "common",
  },
  {
    label: "FONTENAY-TORCY",
    value: "60244",
    type: "common",
  },
  {
    label: "ERNEMONT-BOUTAVENT",
    value: "60214",
    type: "common",
  },
  {
    label: "CAMPEAUX",
    value: "60122",
    type: "common",
  },
  {
    label: "SAINT-MICHEL-D'HALESCOURT",
    value: "76623",
    type: "common",
  },
  {
    label: "HAUSSEZ",
    value: "76345",
    type: "common",
  },
  {
    label: "GRUMESNIL",
    value: "76332",
    type: "common",
  },
  {
    label: "DOUDEAUVILLE",
    value: "76218",
    type: "common",
  },
  {
    label: "CANNY-SUR-THERAIN",
    value: "60128",
    type: "common",
  },
  {
    label: "SAUMONT-LA-POTERIE",
    value: "76666",
    type: "common",
  },
  {
    label: "POMMEREUX",
    value: "76505",
    type: "common",
  },
  {
    label: "LONGMESNIL",
    value: "76393",
    type: "common",
  },
  {
    label: "LA BELLIERE",
    value: "76074",
    type: "common",
  },
  {
    label: "ROUVRAY-CATILLON",
    value: "76544",
    type: "common",
  },
  {
    label: "RONCHEROLLES-EN-BRAY",
    value: "76535",
    type: "common",
  },
  {
    label: "MAUQUENCHY",
    value: "76420",
    type: "common",
  },
  {
    label: "FORGES-LES-EAUX",
    value: "76276",
    type: "common",
  },
  {
    label: "LA FERTE-SAINT-SAMSON",
    value: "76261",
    type: "common",
  },
  {
    label: "BOSC-EDELINE",
    value: "76121",
    type: "common",
  },
  {
    label: "BOSC-BORDEL",
    value: "76120",
    type: "common",
  },
  {
    label: "SAINTE-CROIX-SUR-BUCHY",
    value: "76571",
    type: "common",
  },
  {
    label: "ROCQUEMONT",
    value: "76532",
    type: "common",
  },
  {
    label: "BUCHY",
    value: "76146",
    type: "common",
  },
  {
    label: "YQUEBEUF",
    value: "76756",
    type: "common",
  },
  {
    label: "SAINT-GERMAIN-SOUS-CAILLY",
    value: "76583",
    type: "common",
  },
  {
    label: "LA RUE-SAINT-PIERRE",
    value: "76547",
    type: "common",
  },
  {
    label: "ESTEVILLE",
    value: "76247",
    type: "common",
  },
  {
    label: "CRITOT",
    value: "76200",
    type: "common",
  },
  {
    label: "CLAVILLE-MOTTEVILLE",
    value: "76177",
    type: "common",
  },
  {
    label: "CAILLY",
    value: "76152",
    type: "common",
  },
  {
    label: "MONT-CAUVAIRE",
    value: "76443",
    type: "common",
  },
  {
    label: "GRUGNY",
    value: "76331",
    type: "common",
  },
  {
    label: "FONTAINE-LE-BOURG",
    value: "76271",
    type: "common",
  },
  {
    label: "CLERES",
    value: "76179",
    type: "common",
  },
  {
    label: "AUTHIEUX-RATIEVILLE",
    value: "76038",
    type: "common",
  },
  {
    label: "FRESQUIENNES",
    value: "76287",
    type: "common",
  },
  {
    label: "SIERVILLE",
    value: "76675",
    type: "common",
  },
  {
    label: "BUTOT",
    value: "76149",
    type: "common",
  },
  {
    label: "LE BOCASSE",
    value: "76105",
    type: "common",
  },
  {
    label: "ANCEAUMEVILLE",
    value: "76007",
    type: "common",
  },
  {
    label: "SAINTE-AUSTREBERTHE",
    value: "76566",
    type: "common",
  },
  {
    label: "PAVILLY",
    value: "76495",
    type: "common",
  },
  {
    label: "LIMESY",
    value: "76385",
    type: "common",
  },
  {
    label: "GOUPILLIERES",
    value: "76311",
    type: "common",
  },
  {
    label: "MESNIL-PANNEVILLE",
    value: "76433",
    type: "common",
  },
  {
    label: "SAINT MARTIN DE L'IF",
    value: "76289",
    type: "common",
  },
  {
    label: "CROIX-MARE",
    value: "76203",
    type: "common",
  },
  {
    label: "CIDEVILLE",
    value: "76174",
    type: "common",
  },
  {
    label: "BLACQUEVILLE",
    value: "76099",
    type: "common",
  },
  {
    label: "TOUFFREVILLE-LA-CORBELINE",
    value: "76702",
    type: "common",
  },
  {
    label: "SAINT-CLAIR-SUR-LES-MONTS",
    value: "76568",
    type: "common",
  },
  {
    label: "AUZEBOSC",
    value: "76043",
    type: "common",
  },
  {
    label: "MAULEVRIER-SAINTE-GERTRUDE",
    value: "76418",
    type: "common",
  },
  {
    label: "LOUVETOT",
    value: "76398",
    type: "common",
  },
  {
    label: "BOIS-HIMONT",
    value: "76110",
    type: "common",
  },
  {
    label: "ALLOUVILLE-BELLEFOSSE",
    value: "76001",
    type: "common",
  },
  {
    label: "TROUVILLE",
    value: "76715",
    type: "common",
  },
  {
    label: "SAINT-NICOLAS-DE-LA-HAIE",
    value: "76626",
    type: "common",
  },
  {
    label: "SAINT-AUBIN-DE-CRETOT",
    value: "76559",
    type: "common",
  },
  {
    label: "LINTOT",
    value: "76388",
    type: "common",
  },
  {
    label: "FOUCART",
    value: "76279",
    type: "common",
  },
  {
    label: "BOLLEVILLE",
    value: "76115",
    type: "common",
  },
  {
    label: "ALVIMARE",
    value: "76002",
    type: "common",
  },
  {
    label: "RAFFETOT",
    value: "76518",
    type: "common",
  },
  {
    label: "NOINTOT",
    value: "76468",
    type: "common",
  },
  {
    label: "LANQUETOT",
    value: "76382",
    type: "common",
  },
  {
    label: "BOLBEC",
    value: "76114",
    type: "common",
  },
  {
    label: "BEUZEVILLETTE",
    value: "76092",
    type: "common",
  },
  {
    label: "LES TROIS-PIERRES",
    value: "76714",
    type: "common",
  },
  {
    label: "SAINT-JEAN-DE-LA-NEUVILLE",
    value: "76593",
    type: "common",
  },
  {
    label: "SAINT-EUSTACHE-LA-FORET",
    value: "76576",
    type: "common",
  },
  {
    label: "PARC-D'ANXTOT",
    value: "76494",
    type: "common",
  },
  {
    label: "MIRVILLE",
    value: "76439",
    type: "common",
  },
  {
    label: "HOUQUETOT",
    value: "76368",
    type: "common",
  },
  {
    label: "BEUZEVILLE-LA-GRENIER",
    value: "76090",
    type: "common",
  },
  {
    label: "VIRVILLE",
    value: "76747",
    type: "common",
  },
  {
    label: "SAINT-GILLES-DE-LA-NEUVILLE",
    value: "76586",
    type: "common",
  },
  {
    label: "SAINNEVILLE",
    value: "76551",
    type: "common",
  },
  {
    label: "GRAIMBOUVILLE",
    value: "76314",
    type: "common",
  },
  {
    label: "GOMMERVILLE",
    value: "76303",
    type: "common",
  },
  {
    label: "ETAINHUS",
    value: "76250",
    type: "common",
  },
  {
    label: "ANGERVILLE-L'ORCHER",
    value: "76014",
    type: "common",
  },
  {
    label: "SAINT-MARTIN-DU-BEC",
    value: "76615",
    type: "common",
  },
  {
    label: "ROLLEVILLE",
    value: "76534",
    type: "common",
  },
  {
    label: "NOTRE-DAME-DU-BEC",
    value: "76477",
    type: "common",
  },
  {
    label: "MANEGLISE",
    value: "76404",
    type: "common",
  },
  {
    label: "HERMEVILLE",
    value: "76357",
    type: "common",
  },
  {
    label: "EPOUVILLE",
    value: "76238",
    type: "common",
  },
  {
    label: "CAUVILLE-SUR-MER",
    value: "76167",
    type: "common",
  },
  {
    label: "OCTEVILLE-SUR-MER",
    value: "76481",
    type: "common",
  },
  {
    label: "MANNEVILLETTE",
    value: "76409",
    type: "common",
  },
  {
    label: "FONTENAY",
    value: "76275",
    type: "common",
  },
  {
    label: "CRASVILLE",
    value: "50150",
    type: "common",
  },
  {
    label: "AUMEVILLE-LESTRE",
    value: "50022",
    type: "common",
  },
  {
    label: "LESTRE",
    value: "50268",
    type: "common",
  },
  {
    label: "MONTAIGU-LA-BRISETTE",
    value: "50335",
    type: "common",
  },
  {
    label: "HUBERVILLE",
    value: "50251",
    type: "common",
  },
  {
    label: "OCTEVILLE-L'AVENEL",
    value: "50384",
    type: "common",
  },
  {
    label: "VIDECOSVILLE",
    value: "50634",
    type: "common",
  },
  {
    label: "SAINT-MARTIN-D'AUDOUVILLE",
    value: "50511",
    type: "common",
  },
  {
    label: "VAUDREVILLE",
    value: "50621",
    type: "common",
  },
  {
    label: "SAINT-GERMAIN-DE-TOURNEBUT",
    value: "50478",
    type: "common",
  },
  {
    label: "VALOGNES",
    value: "50615",
    type: "common",
  },
  {
    label: "TAMERVILLE",
    value: "50588",
    type: "common",
  },
  {
    label: "SAINT-JOSEPH",
    value: "50498",
    type: "common",
  },
  {
    label: "SOTTEVAST",
    value: "50579",
    type: "common",
  },
  {
    label: "NEGREVILLE",
    value: "50369",
    type: "common",
  },
  {
    label: "BRIX",
    value: "50087",
    type: "common",
  },
  {
    label: "SAINT-MARTIN-LE-GREARD",
    value: "50519",
    type: "common",
  },
  {
    label: "COUVILLE",
    value: "50149",
    type: "common",
  },
  {
    label: "RAUVILLE-LA-BIGOT",
    value: "50425",
    type: "common",
  },
  {
    label: "BREUVILLE",
    value: "50079",
    type: "common",
  },
  {
    label: "BRICQUEBOSQ",
    value: "50083",
    type: "common",
  },
  {
    label: "GROSVILLE",
    value: "50222",
    type: "common",
  },
  {
    label: "SOTTEVILLE",
    value: "50580",
    type: "common",
  },
  {
    label: "SAINT-CHRISTOPHE-DU-FOC",
    value: "50454",
    type: "common",
  },
  {
    label: "HELLEVILLE",
    value: "50240",
    type: "common",
  },
  {
    label: "BENOITVILLE",
    value: "50045",
    type: "common",
  },
  {
    label: "TREAUVILLE",
    value: "50604",
    type: "common",
  },
  {
    label: "FLAMANVILLE",
    value: "50184",
    type: "common",
  },
  {
    label: "LES PIEUX",
    value: "50402",
    type: "common",
  },
  {
    label: "LAUNSTROFF",
    value: "57388",
    type: "common",
  },
  {
    label: "RUSTROFF",
    value: "57604",
    type: "common",
  },
  {
    label: "MERSCHWEILLER",
    value: "57459",
    type: "common",
  },
  {
    label: "CONTZ-LES-BAINS",
    value: "57152",
    type: "common",
  },
  {
    label: "APACH",
    value: "57026",
    type: "common",
  },
  {
    label: "RODEMACK",
    value: "57588",
    type: "common",
  },
  {
    label: "PUTTELANGE-LES-THIONVILLE",
    value: "57557",
    type: "common",
  },
  {
    label: "MONDORFF",
    value: "57475",
    type: "common",
  },
  {
    label: "HAUTE-KONTZ",
    value: "57371",
    type: "common",
  },
  {
    label: "BEYREN-LES-SIERCK",
    value: "57076",
    type: "common",
  },
  {
    label: "ROUSSY-LE-VILLAGE",
    value: "57600",
    type: "common",
  },
  {
    label: "BASSE-RENTGEN",
    value: "57574",
    type: "common",
  },
  {
    label: "HAGEN",
    value: "57282",
    type: "common",
  },
  {
    label: "EVRANGE",
    value: "57203",
    type: "common",
  },
  {
    label: "BREISTROFF-LA-GRANDE",
    value: "57109",
    type: "common",
  },
  {
    label: "ZOUFFTGEN",
    value: "57764",
    type: "common",
  },
  {
    label: "RUSSANGE",
    value: "57603",
    type: "common",
  },
  {
    label: "REDANGE",
    value: "57565",
    type: "common",
  },
  {
    label: "AUDUN-LE-TICHE",
    value: "57038",
    type: "common",
  },
  {
    label: "VILLERUPT",
    value: "54580",
    type: "common",
  },
  {
    label: "TIERCELET",
    value: "54525",
    type: "common",
  },
  {
    label: "THIL",
    value: "54521",
    type: "common",
  },
  {
    label: "VILLERS-LA-MONTAGNE",
    value: "54575",
    type: "common",
  },
  {
    label: "HUSSIGNY-GODBRANGE",
    value: "54270",
    type: "common",
  },
  {
    label: "HAUCOURT-MOULAINE",
    value: "54254",
    type: "common",
  },
  {
    label: "REHON",
    value: "54451",
    type: "common",
  },
  {
    label: "MEXY",
    value: "54367",
    type: "common",
  },
  {
    label: "LEXY",
    value: "54314",
    type: "common",
  },
  {
    label: "CUTRY",
    value: "54151",
    type: "common",
  },
  {
    label: "CHENIERES",
    value: "54127",
    type: "common",
  },
  {
    label: "VILLERS-LA-CHEVRE",
    value: "54574",
    type: "common",
  },
  {
    label: "TELLANCOURT",
    value: "54514",
    type: "common",
  },
  {
    label: "MONTIGNY-SUR-CHIERS",
    value: "54378",
    type: "common",
  },
  {
    label: "FRESNOIS-LA-MONTAGNE",
    value: "54212",
    type: "common",
  },
  {
    label: "CONS-LA-GRANDVILLE",
    value: "54137",
    type: "common",
  },
  {
    label: "VILLETTE",
    value: "54582",
    type: "common",
  },
  {
    label: "ALLONDRELLE-LA-MALMAISON",
    value: "54011",
    type: "common",
  },
  {
    label: "VELOSNES",
    value: "55544",
    type: "common",
  },
  {
    label: "FLASSIGNY",
    value: "55188",
    type: "common",
  },
  {
    label: "OTHE",
    value: "54412",
    type: "common",
  },
  {
    label: "EPIEZ-SUR-CHIERS",
    value: "54178",
    type: "common",
  },
  {
    label: "CHARENCY-VEZIN",
    value: "54118",
    type: "common",
  },
  {
    label: "VILLECLOYE",
    value: "55554",
    type: "common",
  },
  {
    label: "MONTMEDY",
    value: "55351",
    type: "common",
  },
  {
    label: "IRE-LE-SEC",
    value: "55252",
    type: "common",
  },
  {
    label: "BAZEILLES-SUR-OTHAIN",
    value: "55034",
    type: "common",
  },
  {
    label: "VIGNEUL-SOUS-MONTMEDY",
    value: "55552",
    type: "common",
  },
  {
    label: "QUINCY-LANDZECOURT",
    value: "55410",
    type: "common",
  },
  {
    label: "HAN-LES-JUVIGNY",
    value: "55226",
    type: "common",
  },
  {
    label: "CHAUVENCY-LE-CHATEAU",
    value: "55109",
    type: "common",
  },
  {
    label: "BAALON",
    value: "55025",
    type: "common",
  },
  {
    label: "STENAY",
    value: "55502",
    type: "common",
  },
  {
    label: "BROUENNES",
    value: "55083",
    type: "common",
  },
  {
    label: "LUZY-SAINT-MARTIN",
    value: "55310",
    type: "common",
  },
  {
    label: "LANEUVILLE-SUR-MEUSE",
    value: "55279",
    type: "common",
  },
  {
    label: "CESSE",
    value: "55095",
    type: "common",
  },
  {
    label: "BEAUFORT-EN-ARGONNE",
    value: "55037",
    type: "common",
  },
  {
    label: "VAUX-EN-DIEULET",
    value: "08463",
    type: "common",
  },
  {
    label: "SOMMAUTHE",
    value: "08424",
    type: "common",
  },
  {
    label: "BELVAL-BOIS-DES-DAMES",
    value: "08059",
    type: "common",
  },
  {
    label: "BEAUMONT-EN-ARGONNE",
    value: "08055",
    type: "common",
  },
  {
    label: "LES GRANDES-ARMOISES",
    value: "08019",
    type: "common",
  },
  {
    label: "LA BERLIERE",
    value: "08061",
    type: "common",
  },
  {
    label: "OCHES",
    value: "08332",
    type: "common",
  },
  {
    label: "LES PETITES-ARMOISES",
    value: "08020",
    type: "common",
  },
  {
    label: "VERRIERES",
    value: "08471",
    type: "common",
  },
  {
    label: "TANNAY",
    value: "08439",
    type: "common",
  },
  {
    label: "SY",
    value: "08434",
    type: "common",
  },
  {
    label: "MONTGON",
    value: "08301",
    type: "common",
  },
  {
    label: "BAIRON ET SES ENVIRONS",
    value: "08116",
    type: "common",
  },
  {
    label: "SAINT-LAMBERT-ET-MONT-DE-JEUX",
    value: "08384",
    type: "common",
  },
  {
    label: "SEMUY",
    value: "08411",
    type: "common",
  },
  {
    label: "SUZANNE",
    value: "08433",
    type: "common",
  },
  {
    label: "NEUVILLE-DAY",
    value: "08321",
    type: "common",
  },
  {
    label: "TOURTERON",
    value: "08458",
    type: "common",
  },
  {
    label: "LAMETZ",
    value: "08244",
    type: "common",
  },
  {
    label: "CHARBOGNE",
    value: "08103",
    type: "common",
  },
  {
    label: "GIVRY",
    value: "08193",
    type: "common",
  },
  {
    label: "ALLAND'HUY-ET-SAUSSEUIL",
    value: "08006",
    type: "common",
  },
  {
    label: "ECORDAL",
    value: "08151",
    type: "common",
  },
  {
    label: "SORCY-BAUTHEMONT",
    value: "08428",
    type: "common",
  },
  {
    label: "AMAGNE",
    value: "08008",
    type: "common",
  },
  {
    label: "COUCY",
    value: "08133",
    type: "common",
  },
  {
    label: "AMBLY-FLEURY",
    value: "08010",
    type: "common",
  },
  {
    label: "LUCQUY",
    value: "08262",
    type: "common",
  },
  {
    label: "FAUX",
    value: "08165",
    type: "common",
  },
  {
    label: "RETHEL",
    value: "08362",
    type: "common",
  },
  {
    label: "BERTONCOURT",
    value: "08062",
    type: "common",
  },
  {
    label: "NOVY-CHEVRIERES",
    value: "08330",
    type: "common",
  },
  {
    label: "DOUX",
    value: "08144",
    type: "common",
  },
  {
    label: "NANTEUIL-SUR-AISNE",
    value: "08313",
    type: "common",
  },
  {
    label: "ACY-ROMANCE",
    value: "08001",
    type: "common",
  },
  {
    label: "BARBY",
    value: "08048",
    type: "common",
  },
  {
    label: "CHATEAU-PORCIEN",
    value: "08107",
    type: "common",
  },
  {
    label: "TAIZY",
    value: "08438",
    type: "common",
  },
  {
    label: "HERPY-L'ARLESIENNE",
    value: "08225",
    type: "common",
  },
  {
    label: "CONDE-LES-HERPY",
    value: "08126",
    type: "common",
  },
  {
    label: "GOMONT",
    value: "08195",
    type: "common",
  },
  {
    label: "LE THOUR",
    value: "08451",
    type: "common",
  },
  {
    label: "SAINT-GERMAINMONT",
    value: "08381",
    type: "common",
  },
  {
    label: "LA MALMAISON",
    value: "02454",
    type: "common",
  },
  {
    label: "LOR",
    value: "02440",
    type: "common",
  },
  {
    label: "VILLERS-DEVANT-LE-THOUR",
    value: "08476",
    type: "common",
  },
  {
    label: "SISSONNE",
    value: "02720",
    type: "common",
  },
  {
    label: "SAINT-THOMAS",
    value: "02696",
    type: "common",
  },
  {
    label: "SAINT-ERME-OUTRE-ET-RAMECOURT",
    value: "02676",
    type: "common",
  },
  {
    label: "MAUREGNY-EN-HAYE",
    value: "02472",
    type: "common",
  },
  {
    label: "FESTIEUX",
    value: "02309",
    type: "common",
  },
  {
    label: "EPPES",
    value: "02282",
    type: "common",
  },
  {
    label: "COURTRIZY-ET-FUSSIGNY",
    value: "02229",
    type: "common",
  },
  {
    label: "COUCY-LES-EPPES",
    value: "02218",
    type: "common",
  },
  {
    label: "ARRANCY",
    value: "02024",
    type: "common",
  },
  {
    label: "VESLUD",
    value: "02791",
    type: "common",
  },
  {
    label: "MONTCHALONS",
    value: "02501",
    type: "common",
  },
  {
    label: "CHERET",
    value: "02177",
    type: "common",
  },
  {
    label: "BRUYERES-ET-MONTBERAULT",
    value: "02128",
    type: "common",
  },
  {
    label: "VORGES",
    value: "02824",
    type: "common",
  },
  {
    label: "PARFONDRU",
    value: "02587",
    type: "common",
  },
  {
    label: "ORGEVAL",
    value: "02573",
    type: "common",
  },
  {
    label: "LAVAL-EN-LAONNOIS",
    value: "02413",
    type: "common",
  },
  {
    label: "ETOUVELLES",
    value: "02294",
    type: "common",
  },
  {
    label: "CLACY-ET-THIERRET",
    value: "02196",
    type: "common",
  },
  {
    label: "CHIVY-LES-ETOUVELLES",
    value: "02191",
    type: "common",
  },
  {
    label: "VAUCELLES-ET-BEFFECOURT",
    value: "02765",
    type: "common",
  },
  {
    label: "PRESLES-ET-THIERNY",
    value: "02621",
    type: "common",
  },
  {
    label: "NOUVION-LE-VINEUX",
    value: "02561",
    type: "common",
  },
  {
    label: "MONS-EN-LAONNOIS",
    value: "02497",
    type: "common",
  },
  {
    label: "LANISCOURT",
    value: "02407",
    type: "common",
  },
  {
    label: "CHAILLEVOIS",
    value: "02155",
    type: "common",
  },
  {
    label: "BOURGUIGNON-SOUS-MONTBAVIN",
    value: "02108",
    type: "common",
  },
  {
    label: "ROYAUCOURT-ET-CHAILVET",
    value: "02661",
    type: "common",
  },
  {
    label: "MONTBAVIN",
    value: "02499",
    type: "common",
  },
  {
    label: "MERLIEUX-ET-FOUQUEROLLES",
    value: "02478",
    type: "common",
  },
  {
    label: "FRESNES-SOUS-COUCY",
    value: "02333",
    type: "common",
  },
  {
    label: "BRANCOURT-EN-LAONNOIS",
    value: "02111",
    type: "common",
  },
  {
    label: "BASSOLES-AULERS",
    value: "02052",
    type: "common",
  },
  {
    label: "WISSIGNICOURT",
    value: "02834",
    type: "common",
  },
  {
    label: "QUINCY-BASSE",
    value: "02632",
    type: "common",
  },
  {
    label: "PREMONTRE",
    value: "02619",
    type: "common",
  },
  {
    label: "JUMENCOURT",
    value: "02395",
    type: "common",
  },
  {
    label: "GUNY",
    value: "02363",
    type: "common",
  },
  {
    label: "FOLEMBRAY",
    value: "02318",
    type: "common",
  },
  {
    label: "COUCY-LA-VILLE",
    value: "02219",
    type: "common",
  },
  {
    label: "COUCY-LE-CHATEAU-AUFFRIQUE",
    value: "02217",
    type: "common",
  },
  {
    label: "VERNEUIL-SOUS-COUCY",
    value: "02786",
    type: "common",
  },
  {
    label: "CHAMPS",
    value: "02159",
    type: "common",
  },
  {
    label: "TROSLY-LOIRE",
    value: "02750",
    type: "common",
  },
  {
    label: "SAINT-PAUL-AUX-BOIS",
    value: "02686",
    type: "common",
  },
  {
    label: "SAINT-AUBIN",
    value: "02671",
    type: "common",
  },
  {
    label: "CAMELIN",
    value: "02140",
    type: "common",
  },
  {
    label: "BOURGUIGNON-SOUS-COUCY",
    value: "02107",
    type: "common",
  },
  {
    label: "BESME",
    value: "02078",
    type: "common",
  },
  {
    label: "CUTS",
    value: "60189",
    type: "common",
  },
  {
    label: "BRETIGNY",
    value: "60105",
    type: "common",
  },
  {
    label: "VARESNES",
    value: "60655",
    type: "common",
  },
  {
    label: "SEMPIGNY",
    value: "60610",
    type: "common",
  },
  {
    label: "PONTOISE-LES-NOYON",
    value: "60507",
    type: "common",
  },
  {
    label: "CARLEPONT",
    value: "60129",
    type: "common",
  },
  {
    label: "CAISNES",
    value: "60118",
    type: "common",
  },
  {
    label: "CHIRY-OURSCAMP",
    value: "60150",
    type: "common",
  },
  {
    label: "VILLE",
    value: "60676",
    type: "common",
  },
  {
    label: "RIBECOURT-DRESLINCOURT",
    value: "60537",
    type: "common",
  },
  {
    label: "PONT-L'EVEQUE",
    value: "60506",
    type: "common",
  },
  {
    label: "PIMPREZ",
    value: "60492",
    type: "common",
  },
  {
    label: "PASSEL",
    value: "60488",
    type: "common",
  },
  {
    label: "THIESCOURT",
    value: "60632",
    type: "common",
  },
  {
    label: "MACHEMONT",
    value: "60373",
    type: "common",
  },
  {
    label: "ELINCOURT-SAINTE-MARGUERITE",
    value: "60206",
    type: "common",
  },
  {
    label: "CHEVINCOURT",
    value: "60147",
    type: "common",
  },
  {
    label: "CANNECTANCOURT",
    value: "60126",
    type: "common",
  },
  {
    label: "CAMBRONNE-LES-RIBECOURT",
    value: "60119",
    type: "common",
  },
  {
    label: "VANDELICOURT",
    value: "60654",
    type: "common",
  },
  {
    label: "RICQUEBOURG",
    value: "60538",
    type: "common",
  },
  {
    label: "RESSONS-SUR-MATZ",
    value: "60533",
    type: "common",
  },
  {
    label: "LA NEUVILLE-SUR-RESSONS",
    value: "60459",
    type: "common",
  },
  {
    label: "MARQUEGLISE",
    value: "60386",
    type: "common",
  },
  {
    label: "MARGNY-SUR-MATZ",
    value: "60383",
    type: "common",
  },
  {
    label: "MAREUIL-LA-MOTTE",
    value: "60379",
    type: "common",
  },
  {
    label: "NEUFVY-SUR-ARONDE",
    value: "60449",
    type: "common",
  },
  {
    label: "LATAULE",
    value: "60351",
    type: "common",
  },
  {
    label: "CUVILLY",
    value: "60191",
    type: "common",
  },
  {
    label: "BELLOY",
    value: "60061",
    type: "common",
  },
  {
    label: "WACQUEMOULIN",
    value: "60698",
    type: "common",
  },
  {
    label: "TRICOT",
    value: "60643",
    type: "common",
  },
  {
    label: "SAINT-MARTIN-AUX-BOIS",
    value: "60585",
    type: "common",
  },
  {
    label: "MONTGERAIN",
    value: "60416",
    type: "common",
  },
  {
    label: "MERY-LA-BATAILLE",
    value: "60396",
    type: "common",
  },
  {
    label: "MENEVILLERS",
    value: "60394",
    type: "common",
  },
  {
    label: "COIVREL",
    value: "60158",
    type: "common",
  },
  {
    label: "RAVENEL",
    value: "60526",
    type: "common",
  },
  {
    label: "LE PLESSIER-SUR-SAINT-JUST",
    value: "60498",
    type: "common",
  },
  {
    label: "PLAINVAL",
    value: "60495",
    type: "common",
  },
  {
    label: "MAIGNELAY-MONTIGNY",
    value: "60374",
    type: "common",
  },
  {
    label: "BRUNVILLERS-LA-MOTTE",
    value: "60112",
    type: "common",
  },
  {
    label: "QUINQUEMPOIX",
    value: "60522",
    type: "common",
  },
  {
    label: "CATILLON-FUMECHON",
    value: "60133",
    type: "common",
  },
  {
    label: "WAVIGNIES",
    value: "60701",
    type: "common",
  },
  {
    label: "THIEUX",
    value: "60634",
    type: "common",
  },
  {
    label: "MONTREUIL-SUR-BRECHE",
    value: "60425",
    type: "common",
  },
  {
    label: "CAMPREMY",
    value: "60123",
    type: "common",
  },
  {
    label: "BUCAMPS",
    value: "60113",
    type: "common",
  },
  {
    label: "REUIL-SUR-BRECHE",
    value: "60535",
    type: "common",
  },
  {
    label: "NOYERS-SAINT-MARTIN",
    value: "60470",
    type: "common",
  },
  {
    label: "NOIREMONT",
    value: "60465",
    type: "common",
  },
  {
    label: "LA NEUVILLE-SAINT-PIERRE",
    value: "60457",
    type: "common",
  },
  {
    label: "FROISSY",
    value: "60265",
    type: "common",
  },
  {
    label: "MUIDORGE",
    value: "60442",
    type: "common",
  },
  {
    label: "MAULERS",
    value: "60390",
    type: "common",
  },
  {
    label: "MAISONCELLE-SAINT-PIERRE",
    value: "60376",
    type: "common",
  },
  {
    label: "LUCHY",
    value: "60372",
    type: "common",
  },
  {
    label: "LACHAUSSEE-DU-BOIS-D'ECU",
    value: "60336",
    type: "common",
  },
  {
    label: "JUVIGNIES",
    value: "60328",
    type: "common",
  },
  {
    label: "ABBEVILLE-SAINT-LUCIEN",
    value: "60003",
    type: "common",
  },
  {
    label: "SAINT-OMER-EN-CHAUSSEE",
    value: "60590",
    type: "common",
  },
  {
    label: "PISSELEU",
    value: "60493",
    type: "common",
  },
  {
    label: "OUDEUIL",
    value: "60484",
    type: "common",
  },
  {
    label: "MILLY-SUR-THERAIN",
    value: "60403",
    type: "common",
  },
  {
    label: "BLICOURT",
    value: "60077",
    type: "common",
  },
  {
    label: "VILLERS-SUR-BONNIERES",
    value: "60688",
    type: "common",
  },
  {
    label: "CRILLON",
    value: "60180",
    type: "common",
  },
  {
    label: "ACHY",
    value: "60004",
    type: "common",
  },
  {
    label: "WAMBEZ",
    value: "60699",
    type: "common",
  },
  {
    label: "VROCOURT",
    value: "60697",
    type: "common",
  },
  {
    label: "SONGEONS",
    value: "60623",
    type: "common",
  },
  {
    label: "MARTINCOURT",
    value: "60388",
    type: "common",
  },
  {
    label: "LACHAPELLE-SOUS-GERBEROY",
    value: "60335",
    type: "common",
  },
  {
    label: "HANVOILE",
    value: "60298",
    type: "common",
  },
  {
    label: "GREMEVILLERS",
    value: "60288",
    type: "common",
  },
  {
    label: "GERBEROY",
    value: "60271",
    type: "common",
  },
  {
    label: "SULLY",
    value: "60624",
    type: "common",
  },
  {
    label: "SAINT-QUENTIN-DES-PRES",
    value: "60594",
    type: "common",
  },
  {
    label: "HECOURT",
    value: "60306",
    type: "common",
  },
  {
    label: "HANNACHES",
    value: "60296",
    type: "common",
  },
  {
    label: "ESCAMES",
    value: "60217",
    type: "common",
  },
  {
    label: "BUICOURT",
    value: "60114",
    type: "common",
  },
  {
    label: "BAZANCOURT",
    value: "60049",
    type: "common",
  },
  {
    label: "MOLAGNIES",
    value: "76440",
    type: "common",
  },
  {
    label: "MENERVAL",
    value: "76423",
    type: "common",
  },
  {
    label: "GANCOURT-SAINT-ETIENNE",
    value: "76297",
    type: "common",
  },
  {
    label: "DAMPIERRE-EN-BRAY",
    value: "76209",
    type: "common",
  },
  {
    label: "CUY-SAINT-FIACRE",
    value: "76208",
    type: "common",
  },
  {
    label: "MESANGUEVILLE",
    value: "76426",
    type: "common",
  },
  {
    label: "HODENG-HODENGER",
    value: "76364",
    type: "common",
  },
  {
    label: "BREMONTIER-MERVAL",
    value: "76142",
    type: "common",
  },
  {
    label: "SIGY-EN-BRAY",
    value: "76676",
    type: "common",
  },
  {
    label: "LE MESNIL-LIEUBRAY",
    value: "76431",
    type: "common",
  },
  {
    label: "LA HALLOTIERE",
    value: "76338",
    type: "common",
  },
  {
    label: "FRY",
    value: "76292",
    type: "common",
  },
  {
    label: "ARGUEIL",
    value: "76025",
    type: "common",
  },
  {
    label: "REBETS",
    value: "76521",
    type: "common",
  },
  {
    label: "HERONCHELLES",
    value: "76359",
    type: "common",
  },
  {
    label: "LA CHAPELLE-SAINT-OUEN",
    value: "76171",
    type: "common",
  },
  {
    label: "BOIS-HEROULT",
    value: "76109",
    type: "common",
  },
  {
    label: "BOIS-GUILBERT",
    value: "76107",
    type: "common",
  },
  {
    label: "VIEUX-MANOIR",
    value: "76738",
    type: "common",
  },
  {
    label: "SAINT-GERMAIN-DES-ESSOURTS",
    value: "76581",
    type: "common",
  },
  {
    label: "LONGUERUE",
    value: "76396",
    type: "common",
  },
  {
    label: "ERNEMONT-SUR-BUCHY",
    value: "76243",
    type: "common",
  },
  {
    label: "CATENAY",
    value: "76163",
    type: "common",
  },
  {
    label: "BOISSAY",
    value: "76113",
    type: "common",
  },
  {
    label: "BLAINVILLE-CREVON",
    value: "76100",
    type: "common",
  },
  {
    label: "SAINT-ANDRE-SUR-CAILLY",
    value: "76555",
    type: "common",
  },
  {
    label: "PIERREVAL",
    value: "76502",
    type: "common",
  },
  {
    label: "MORGNY-LA-POMMERAYE",
    value: "76453",
    type: "common",
  },
  {
    label: "BIERVILLE",
    value: "76094",
    type: "common",
  },
  {
    label: "SAINT-GEORGES-SUR-FONTAINE",
    value: "76580",
    type: "common",
  },
  {
    label: "QUINCAMPOIX",
    value: "76517",
    type: "common",
  },
  {
    label: "ISNEAUVILLE",
    value: "76377",
    type: "common",
  },
  {
    label: "BOSC-GUERARD-SAINT-ADRIEN",
    value: "76123",
    type: "common",
  },
  {
    label: "SAINT-JEAN-DU-CARDONNAY",
    value: "76594",
    type: "common",
  },
  {
    label: "MONTVILLE",
    value: "76452",
    type: "common",
  },
  {
    label: "MALAUNAY",
    value: "76402",
    type: "common",
  },
  {
    label: "HOUPPEVILLE",
    value: "76367",
    type: "common",
  },
  {
    label: "LE HOULME",
    value: "76366",
    type: "common",
  },
  {
    label: "ESLETTES",
    value: "76245",
    type: "common",
  },
  {
    label: "VILLERS-ECALLES",
    value: "76743",
    type: "common",
  },
  {
    label: "SAINT-PIERRE-DE-VARENGEVILLE",
    value: "76636",
    type: "common",
  },
  {
    label: "ROUMARE",
    value: "76541",
    type: "common",
  },
  {
    label: "PISSY-POVILLE",
    value: "76503",
    type: "common",
  },
  {
    label: "BARENTIN",
    value: "76057",
    type: "common",
  },
  {
    label: "SAINT-PAER",
    value: "76631",
    type: "common",
  },
  {
    label: "SAINTE-MARGUERITE-SUR-DUCLAIR",
    value: "76608",
    type: "common",
  },
  {
    label: "EPINAY-SUR-DUCLAIR",
    value: "76237",
    type: "common",
  },
  {
    label: "BOUVILLE",
    value: "76135",
    type: "common",
  },
  {
    label: "NOTRE-DAME-DE-BLIQUETUIT",
    value: "76473",
    type: "common",
  },
  {
    label: "RIVES-EN-SEINE",
    value: "76164",
    type: "common",
  },
  {
    label: "CARVILLE-LA-FOLLETIERE",
    value: "76160",
    type: "common",
  },
  {
    label: "SAINT-GILLES-DE-CRETOT",
    value: "76585",
    type: "common",
  },
  {
    label: "SAINT-ARNOULT",
    value: "76557",
    type: "common",
  },
  {
    label: "LA TRINITE-DU-MONT",
    value: "76712",
    type: "common",
  },
  {
    label: "GRAND-CAMP",
    value: "76318",
    type: "common",
  },
  {
    label: "LA FRENAYE",
    value: "76281",
    type: "common",
  },
  {
    label: "ANQUETIERVILLE",
    value: "76022",
    type: "common",
  },
  {
    label: "SAINT-NICOLAS-DE-LA-TAILLE",
    value: "76627",
    type: "common",
  },
  {
    label: "SAINT-JEAN-DE-FOLLEVILLE",
    value: "76592",
    type: "common",
  },
  {
    label: "SAINT-ANTOINE-LA-FORET",
    value: "76556",
    type: "common",
  },
  {
    label: "LILLEBONNE",
    value: "76384",
    type: "common",
  },
  {
    label: "GRUCHET-LE-VALASSE",
    value: "76329",
    type: "common",
  },
  {
    label: "LA REMUEE",
    value: "76522",
    type: "common",
  },
  {
    label: "MELAMARE",
    value: "76421",
    type: "common",
  },
  {
    label: "SAINT-VINCENT-CRAMESNIL",
    value: "76658",
    type: "common",
  },
  {
    label: "SAINT-ROMAIN-DE-COLBOSC",
    value: "76647",
    type: "common",
  },
  {
    label: "SAINT-AUBIN-ROUTOT",
    value: "76563",
    type: "common",
  },
  {
    label: "EPRETOT",
    value: "76239",
    type: "common",
  },
  {
    label: "SAINT-MARTIN-DU-MANOIR",
    value: "76616",
    type: "common",
  },
  {
    label: "SAINT-LAURENT-DE-BREVEDENT",
    value: "76596",
    type: "common",
  },
  {
    label: "HARFLEUR",
    value: "76341",
    type: "common",
  },
  {
    label: "GONFREVILLE-L'ORCHER",
    value: "76305",
    type: "common",
  },
  {
    label: "GAINNEVILLE",
    value: "76296",
    type: "common",
  },
  {
    label: "MONTIVILLIERS",
    value: "76447",
    type: "common",
  },
  {
    label: "LE HAVRE",
    value: "76351",
    type: "common",
  },
  {
    label: "FONTAINE-LA-MALLET",
    value: "76270",
    type: "common",
  },
  {
    label: "SAINTE-ADRESSE",
    value: "76552",
    type: "common",
  },
  {
    label: "SAINT-MARCOUF",
    value: "50507",
    type: "common",
  },
  {
    label: "QUINEVILLE",
    value: "50421",
    type: "common",
  },
  {
    label: "OZEVILLE",
    value: "50390",
    type: "common",
  },
  {
    label: "FONTENAY-SUR-MER",
    value: "50190",
    type: "common",
  },
  {
    label: "EMONDEVILLE",
    value: "50172",
    type: "common",
  },
  {
    label: "AZEVILLE",
    value: "50026",
    type: "common",
  },
  {
    label: "SORTOSVILLE",
    value: "50578",
    type: "common",
  },
  {
    label: "ECAUSSEVILLE",
    value: "50169",
    type: "common",
  },
  {
    label: "JOGANVILLE",
    value: "50258",
    type: "common",
  },
  {
    label: "SAINT-FLOXEL",
    value: "50467",
    type: "common",
  },
  {
    label: "SAINT-CYR",
    value: "50461",
    type: "common",
  },
  {
    label: "MONTEBOURG",
    value: "50341",
    type: "common",
  },
  {
    label: "LE HAM",
    value: "50227",
    type: "common",
  },
  {
    label: "EROUDEVILLE",
    value: "50175",
    type: "common",
  },
  {
    label: "MORVILLE",
    value: "50360",
    type: "common",
  },
  {
    label: "LIEUSAINT",
    value: "50270",
    type: "common",
  },
  {
    label: "YVETOT-BOCAGE",
    value: "50648",
    type: "common",
  },
  {
    label: "COLOMBY",
    value: "50138",
    type: "common",
  },
  {
    label: "HEMEVEZ",
    value: "50241",
    type: "common",
  },
  {
    label: "FLOTTEMANVILLE",
    value: "50186",
    type: "common",
  },
  {
    label: "URVILLE",
    value: "50610",
    type: "common",
  },
  {
    label: "ROCHEVILLE",
    value: "50435",
    type: "common",
  },
  {
    label: "MAGNEVILLE",
    value: "50285",
    type: "common",
  },
  {
    label: "L'ETANG-BERTRAND",
    value: "50176",
    type: "common",
  },
  {
    label: "BRICQUEBEC-EN-COTENTIN",
    value: "50082",
    type: "common",
  },
  {
    label: "SAINT-GERMAIN-LE-GAILLARD",
    value: "50480",
    type: "common",
  },
  {
    label: "PIERREVILLE",
    value: "50401",
    type: "common",
  },
  {
    label: "SURTAINVILLE",
    value: "50585",
    type: "common",
  },
  {
    label: "LE ROZEL",
    value: "50442",
    type: "common",
  },
  {
    label: "GRINDORFF-BIZING",
    value: "57259",
    type: "common",
  },
  {
    label: "WALDWISSE",
    value: "57740",
    type: "common",
  },
  {
    label: "FLASTROFF",
    value: "57215",
    type: "common",
  },
  {
    label: "REMELING",
    value: "57569",
    type: "common",
  },
  {
    label: "KIRSCHNAUMEN",
    value: "57365",
    type: "common",
  },
  {
    label: "HALSTROFF",
    value: "57286",
    type: "common",
  },
  {
    label: "SIERCK-LES-BAINS",
    value: "57650",
    type: "common",
  },
  {
    label: "MONTENACH",
    value: "57479",
    type: "common",
  },
  {
    label: "KIRSCH-LES-SIERCK",
    value: "57364",
    type: "common",
  },
  {
    label: "KERLING-LES-SIERCK",
    value: "57361",
    type: "common",
  },
  {
    label: "RETTEL",
    value: "57576",
    type: "common",
  },
  {
    label: "MALLING",
    value: "57437",
    type: "common",
  },
  {
    label: "KŒNIGSMACKER",
    value: "57370",
    type: "common",
  },
  {
    label: "HUNTING",
    value: "57341",
    type: "common",
  },
  {
    label: "GAVISSE",
    value: "57245",
    type: "common",
  },
  {
    label: "FIXEM",
    value: "57214",
    type: "common",
  },
  {
    label: "BERG-SUR-MOSELLE",
    value: "57062",
    type: "common",
  },
  {
    label: "THIONVILLE",
    value: "57672",
    type: "common",
  },
  {
    label: "HETTANGE-GRANDE",
    value: "57323",
    type: "common",
  },
  {
    label: "CATTENOM",
    value: "57124",
    type: "common",
  },
  {
    label: "BOUST",
    value: "57104",
    type: "common",
  },
  {
    label: "VOLMERANGE-LES-MINES",
    value: "57731",
    type: "common",
  },
  {
    label: "KANFEN",
    value: "57356",
    type: "common",
  },
  {
    label: "ESCHERANGE",
    value: "57199",
    type: "common",
  },
  {
    label: "ENTRANGE",
    value: "57194",
    type: "common",
  },
  {
    label: "TRESSANGE",
    value: "57678",
    type: "common",
  },
  {
    label: "ROCHONVILLERS",
    value: "57586",
    type: "common",
  },
  {
    label: "OTTANGE",
    value: "57529",
    type: "common",
  },
  {
    label: "AUMETZ",
    value: "57041",
    type: "common",
  },
  {
    label: "ERROUVILLE",
    value: "54181",
    type: "common",
  },
  {
    label: "CRUSNES",
    value: "54149",
    type: "common",
  },
  {
    label: "VILLE-AU-MONTOIS",
    value: "54568",
    type: "common",
  },
  {
    label: "MORFONTAINE",
    value: "54385",
    type: "common",
  },
  {
    label: "FILLIERES",
    value: "54194",
    type: "common",
  },
  {
    label: "BREHAIN-LA-VILLE",
    value: "54096",
    type: "common",
  },
  {
    label: "UGNY",
    value: "54537",
    type: "common",
  },
  {
    label: "PIERREPONT",
    value: "54428",
    type: "common",
  },
  {
    label: "LAIX",
    value: "54290",
    type: "common",
  },
  {
    label: "DONCOURT-LES-LONGUYON",
    value: "54172",
    type: "common",
  },
  {
    label: "BOISMONT",
    value: "54081",
    type: "common",
  },
  {
    label: "BAZAILLES",
    value: "54056",
    type: "common",
  },
  {
    label: "BASLIEUX",
    value: "54049",
    type: "common",
  },
  {
    label: "ARRANCY-SUR-CRUSNE",
    value: "55013",
    type: "common",
  },
  {
    label: "VIVIERS-SUR-CHIERS",
    value: "54590",
    type: "common",
  },
  {
    label: "BEUVEILLE",
    value: "54067",
    type: "common",
  },
  {
    label: "LONGUYON",
    value: "54322",
    type: "common",
  },
  {
    label: "GRAND-FAILLY",
    value: "54236",
    type: "common",
  },
  {
    label: "COLMEY",
    value: "54134",
    type: "common",
  },
  {
    label: "RUPT-SUR-OTHAIN",
    value: "55450",
    type: "common",
  },
  {
    label: "MARVILLE",
    value: "55324",
    type: "common",
  },
  {
    label: "VILLERS-LE-ROND",
    value: "54576",
    type: "common",
  },
  {
    label: "SAINT-JEAN-LES-LONGUYON",
    value: "54476",
    type: "common",
  },
  {
    label: "PETIT-FAILLY",
    value: "54420",
    type: "common",
  },
  {
    label: "REMOIVILLE",
    value: "55425",
    type: "common",
  },
  {
    label: "JAMETZ",
    value: "55255",
    type: "common",
  },
  {
    label: "DELUT",
    value: "55149",
    type: "common",
  },
  {
    label: "LOUPPY-SUR-LOISON",
    value: "55306",
    type: "common",
  },
  {
    label: "LION-DEVANT-DUN",
    value: "55293",
    type: "common",
  },
  {
    label: "JUVIGNY-SUR-LOISON",
    value: "55262",
    type: "common",
  },
  {
    label: "WISEPPE",
    value: "55582",
    type: "common",
  },
  {
    label: "SAULMORY-VILLEFRANCHE",
    value: "55471",
    type: "common",
  },
  {
    label: "MOUZAY",
    value: "55364",
    type: "common",
  },
  {
    label: "MONTIGNY-DEVANT-SASSEY",
    value: "55349",
    type: "common",
  },
  {
    label: "HALLES-SOUS-LES-COTES",
    value: "55225",
    type: "common",
  },
  {
    label: "BEAUCLAIR",
    value: "55036",
    type: "common",
  },
  {
    label: "NOUART",
    value: "08326",
    type: "common",
  },
  {
    label: "FOSSE",
    value: "08176",
    type: "common",
  },
  {
    label: "AUTRUCHE",
    value: "08035",
    type: "common",
  },
  {
    label: "BAR-LES-BUZANCY",
    value: "08049",
    type: "common",
  },
  {
    label: "SAINT-PIERREMONT",
    value: "08394",
    type: "common",
  },
  {
    label: "HARRICOURT",
    value: "08215",
    type: "common",
  },
  {
    label: "BELLEVILLE-ET-CHATILLON-SUR-BAR",
    value: "08057",
    type: "common",
  },
  {
    label: "GERMONT",
    value: "08186",
    type: "common",
  },
  {
    label: "AUTHE",
    value: "08033",
    type: "common",
  },
  {
    label: "BRIEULLES-SUR-BAR",
    value: "08085",
    type: "common",
  },
  {
    label: "QUATRE-CHAMPS",
    value: "08350",
    type: "common",
  },
  {
    label: "VANDY",
    value: "08461",
    type: "common",
  },
  {
    label: "TOGES",
    value: "08453",
    type: "common",
  },
  {
    label: "NOIRVAL",
    value: "08325",
    type: "common",
  },
  {
    label: "GRIVY-LOISY",
    value: "08200",
    type: "common",
  },
  {
    label: "CHUFFILLY-ROCHE",
    value: "08123",
    type: "common",
  },
  {
    label: "RILLY-SUR-AISNE",
    value: "08364",
    type: "common",
  },
  {
    label: "VONCQ",
    value: "08489",
    type: "common",
  },
  {
    label: "VAUX-CHAMPAGNE",
    value: "08462",
    type: "common",
  },
  {
    label: "SAINTE-VAUBOURG",
    value: "08398",
    type: "common",
  },
  {
    label: "COULOMMES-ET-MARQUENY",
    value: "08134",
    type: "common",
  },
  {
    label: "ATTIGNY",
    value: "08025",
    type: "common",
  },
  {
    label: "SAULCES-CHAMPENOISES",
    value: "08401",
    type: "common",
  },
  {
    label: "SEUIL",
    value: "08416",
    type: "common",
  },
  {
    label: "MONT-LAURENT",
    value: "08306",
    type: "common",
  },
  {
    label: "MENIL-ANNELLES",
    value: "08286",
    type: "common",
  },
  {
    label: "THUGNY-TRUGNY",
    value: "08452",
    type: "common",
  },
  {
    label: "SAULT-LES-RETHEL",
    value: "08403",
    type: "common",
  },
  {
    label: "BIERMES",
    value: "08064",
    type: "common",
  },
  {
    label: "PERTHES",
    value: "08339",
    type: "common",
  },
  {
    label: "ANNELLES",
    value: "08014",
    type: "common",
  },
  {
    label: "TAGNON",
    value: "08435",
    type: "common",
  },
  {
    label: "SAINT-LOUP-EN-CHAMPAGNE",
    value: "08386",
    type: "common",
  },
  {
    label: "AVANCON",
    value: "08038",
    type: "common",
  },
  {
    label: "BLANZY-LA-SALONNAISE",
    value: "08070",
    type: "common",
  },
  {
    label: "VIEUX-LES-ASFELD",
    value: "08473",
    type: "common",
  },
  {
    label: "AIRE",
    value: "08004",
    type: "common",
  },
  {
    label: "ASFELD",
    value: "08024",
    type: "common",
  },
  {
    label: "BALHAM",
    value: "08044",
    type: "common",
  },
  {
    label: "EVERGNICOURT",
    value: "02299",
    type: "common",
  },
  {
    label: "AVAUX",
    value: "08039",
    type: "common",
  },
  {
    label: "PROVISEUX-ET-PLESNOY",
    value: "02627",
    type: "common",
  },
  {
    label: "AMIFONTAINE",
    value: "02013",
    type: "common",
  },
  {
    label: "PROUVAIS",
    value: "02626",
    type: "common",
  },
  {
    label: "JUVINCOURT-ET-DAMARY",
    value: "02399",
    type: "common",
  },
  {
    label: "GOUDELANCOURT-LES-BERRIEUX",
    value: "02349",
    type: "common",
  },
  {
    label: "CORBENY",
    value: "02215",
    type: "common",
  },
  {
    label: "BERRIEUX",
    value: "02072",
    type: "common",
  },
  {
    label: "AIZELLES",
    value: "02007",
    type: "common",
  },
  {
    label: "CRAONNE",
    value: "02234",
    type: "common",
  },
  {
    label: "CHERMIZY-AILLES",
    value: "02178",
    type: "common",
  },
  {
    label: "BOUCONVILLE-VAUCLAIR",
    value: "02102",
    type: "common",
  },
  {
    label: "AUBIGNY-EN-LAONNOIS",
    value: "02033",
    type: "common",
  },
  {
    label: "SAINTE-CROIX",
    value: "02675",
    type: "common",
  },
  {
    label: "PLOYART-ET-VAURSEINE",
    value: "02609",
    type: "common",
  },
  {
    label: "MARTIGNY-COURPIERRE",
    value: "02471",
    type: "common",
  },
  {
    label: "COLLIGIS-CRANDELAIN",
    value: "02205",
    type: "common",
  },
  {
    label: "CHAMOUILLE",
    value: "02158",
    type: "common",
  },
  {
    label: "CERNY-EN-LAONNOIS",
    value: "02150",
    type: "common",
  },
  {
    label: "BIEVRES",
    value: "02088",
    type: "common",
  },
  {
    label: "PANCY-COURTECON",
    value: "02583",
    type: "common",
  },
  {
    label: "NEUVILLE-SUR-AILETTE",
    value: "02550",
    type: "common",
  },
  {
    label: "MONTHENAULT",
    value: "02508",
    type: "common",
  },
  {
    label: "LIERVAL",
    value: "02429",
    type: "common",
  },
  {
    label: "FILAIN",
    value: "02311",
    type: "common",
  },
  {
    label: "CHEVREGNY",
    value: "02183",
    type: "common",
  },
  {
    label: "URCEL",
    value: "02755",
    type: "common",
  },
  {
    label: "TRUCY",
    value: "02751",
    type: "common",
  },
  {
    label: "PARGNY-FILAIN",
    value: "02589",
    type: "common",
  },
  {
    label: "MONAMPTEUIL",
    value: "02490",
    type: "common",
  },
  {
    label: "CHAVIGNON",
    value: "02174",
    type: "common",
  },
  {
    label: "ALLEMANT",
    value: "02010",
    type: "common",
  },
  {
    label: "VAUDESSON",
    value: "02766",
    type: "common",
  },
  {
    label: "PINON",
    value: "02602",
    type: "common",
  },
  {
    label: "LANDRICOURT",
    value: "02406",
    type: "common",
  },
  {
    label: "VAUXAILLON",
    value: "02768",
    type: "common",
  },
  {
    label: "NEUVILLE-SUR-MARGIVAL",
    value: "02551",
    type: "common",
  },
  {
    label: "LEUILLY-SOUS-COUCY",
    value: "02423",
    type: "common",
  },
  {
    label: "JUVIGNY",
    value: "02398",
    type: "common",
  },
  {
    label: "CRECY-AU-MONT",
    value: "02236",
    type: "common",
  },
  {
    label: "BIEUXY",
    value: "02087",
    type: "common",
  },
  {
    label: "BAGNEUX",
    value: "02043",
    type: "common",
  },
  {
    label: "PONT-SAINT-MARD",
    value: "02616",
    type: "common",
  },
  {
    label: "EPAGNY",
    value: "02277",
    type: "common",
  },
  {
    label: "VEZAPONIN",
    value: "02793",
    type: "common",
  },
  {
    label: "SELENS",
    value: "02704",
    type: "common",
  },
  {
    label: "MORSAIN",
    value: "02527",
    type: "common",
  },
  {
    label: "BLERANCOURT",
    value: "02093",
    type: "common",
  },
  {
    label: "AUDIGNICOURT",
    value: "02034",
    type: "common",
  },
  {
    label: "NAMPCEL",
    value: "60445",
    type: "common",
  },
  {
    label: "AUTRECHES",
    value: "60032",
    type: "common",
  },
  {
    label: "VASSENS",
    value: "02762",
    type: "common",
  },
  {
    label: "TRACY-LE-VAL",
    value: "60642",
    type: "common",
  },
  {
    label: "TRACY-LE-MONT",
    value: "60641",
    type: "common",
  },
  {
    label: "MOULIN-SOUS-TOUVENT",
    value: "60438",
    type: "common",
  },
  {
    label: "SAINT-LEGER-AUX-BOIS",
    value: "60582",
    type: "common",
  },
  {
    label: "LE PLESSIS-BRION",
    value: "60501",
    type: "common",
  },
  {
    label: "MONTMACQ",
    value: "60423",
    type: "common",
  },
  {
    label: "BAILLY",
    value: "60043",
    type: "common",
  },
  {
    label: "THOUROTTE",
    value: "60636",
    type: "common",
  },
  {
    label: "MELICOCQ",
    value: "60392",
    type: "common",
  },
  {
    label: "MAREST-SUR-MATZ",
    value: "60378",
    type: "common",
  },
  {
    label: "LONGUEIL-ANNEL",
    value: "60368",
    type: "common",
  },
  {
    label: "JANVILLE",
    value: "60323",
    type: "common",
  },
  {
    label: "GIRAUMONT",
    value: "60273",
    type: "common",
  },
  {
    label: "BIENVILLE",
    value: "60070",
    type: "common",
  },
  {
    label: "VILLERS-SUR-COUDUN",
    value: "60689",
    type: "common",
  },
  {
    label: "VIGNEMONT",
    value: "60675",
    type: "common",
  },
  {
    label: "MONCHY-HUMIERES",
    value: "60408",
    type: "common",
  },
  {
    label: "COUDUN",
    value: "60166",
    type: "common",
  },
  {
    label: "BRAISNES-SUR-ARONDE",
    value: "60099",
    type: "common",
  },
  {
    label: "BAUGY",
    value: "60048",
    type: "common",
  },
  {
    label: "ANTHEUIL-PORTES",
    value: "60019",
    type: "common",
  },
  {
    label: "ROUVILLERS",
    value: "60553",
    type: "common",
  },
  {
    label: "MOYENNEVILLE",
    value: "60440",
    type: "common",
  },
  {
    label: "MONTMARTIN",
    value: "60424",
    type: "common",
  },
  {
    label: "HEMEVILLERS",
    value: "60308",
    type: "common",
  },
  {
    label: "GOURNAY-SUR-ARONDE",
    value: "60281",
    type: "common",
  },
  {
    label: "LA NEUVILLE-ROY",
    value: "60456",
    type: "common",
  },
  {
    label: "PRONLEROY",
    value: "60515",
    type: "common",
  },
  {
    label: "MONTIERS",
    value: "60418",
    type: "common",
  },
  {
    label: "GRANDVILLERS-AUX-BOIS",
    value: "60285",
    type: "common",
  },
  {
    label: "LIEUVILLERS",
    value: "60364",
    type: "common",
  },
  {
    label: "LEGLANTIERS",
    value: "60357",
    type: "common",
  },
  {
    label: "ERQUINVILLERS",
    value: "60216",
    type: "common",
  },
  {
    label: "ANGIVILLERS",
    value: "60014",
    type: "common",
  },
  {
    label: "VALESCOURT",
    value: "60653",
    type: "common",
  },
  {
    label: "SAINT-REMY-EN-L'EAU",
    value: "60595",
    type: "common",
  },
  {
    label: "SAINT-JUST-EN-CHAUSSEE",
    value: "60581",
    type: "common",
  },
  {
    label: "NOURARD-LE-FRANC",
    value: "60468",
    type: "common",
  },
  {
    label: "FOURNIVAL",
    value: "60252",
    type: "common",
  },
  {
    label: "LE QUESNEL-AUBRY",
    value: "60520",
    type: "common",
  },
  {
    label: "LE PLESSIER-SUR-BULLES",
    value: "60497",
    type: "common",
  },
  {
    label: "LE MESNIL-SUR-BULLES",
    value: "60400",
    type: "common",
  },
  {
    label: "ESSUILES",
    value: "60222",
    type: "common",
  },
  {
    label: "BULLES",
    value: "60115",
    type: "common",
  },
  {
    label: "VELENNES",
    value: "60663",
    type: "common",
  },
  {
    label: "OROER",
    value: "60480",
    type: "common",
  },
  {
    label: "LAFRAYE",
    value: "60339",
    type: "common",
  },
  {
    label: "HAUDIVILLERS",
    value: "60302",
    type: "common",
  },
  {
    label: "FOUQUEROLLES",
    value: "60251",
    type: "common",
  },
  {
    label: "VERDEREL-LES-SAUQUEUSE",
    value: "60668",
    type: "common",
  },
  {
    label: "TILLE",
    value: "60639",
    type: "common",
  },
  {
    label: "NIVILLERS",
    value: "60461",
    type: "common",
  },
  {
    label: "GUIGNECOURT",
    value: "60290",
    type: "common",
  },
  {
    label: "FONTAINE-SAINT-LUCIEN",
    value: "60243",
    type: "common",
  },
  {
    label: "BONLIER",
    value: "60081",
    type: "common",
  },
  {
    label: "TROISSEREUX",
    value: "60646",
    type: "common",
  },
  {
    label: "LE MONT-SAINT-ADRIEN",
    value: "60428",
    type: "common",
  },
  {
    label: "HERCHIES",
    value: "60310",
    type: "common",
  },
  {
    label: "FOUQUENIES",
    value: "60250",
    type: "common",
  },
  {
    label: "SAVIGNIES",
    value: "60609",
    type: "common",
  },
  {
    label: "PIERREFITTE-EN-BEAUVAISIS",
    value: "60490",
    type: "common",
  },
  {
    label: "LA NEUVILLE-VAULT",
    value: "60460",
    type: "common",
  },
  {
    label: "LHERAULE",
    value: "60359",
    type: "common",
  },
  {
    label: "HAUCOURT",
    value: "60301",
    type: "common",
  },
  {
    label: "BONNIERES",
    value: "60084",
    type: "common",
  },
  {
    label: "VILLEMBRAY",
    value: "60677",
    type: "common",
  },
  {
    label: "SENANTES",
    value: "60611",
    type: "common",
  },
  {
    label: "HODENC-EN-BRAY",
    value: "60315",
    type: "common",
  },
  {
    label: "GLATIGNY",
    value: "60275",
    type: "common",
  },
  {
    label: "BLACOURT",
    value: "60073",
    type: "common",
  },
  {
    label: "FERRIERES-EN-BRAY",
    value: "76260",
    type: "common",
  },
  {
    label: "VILLERS-SUR-AUCHY",
    value: "60687",
    type: "common",
  },
  {
    label: "GOURNAY-EN-BRAY",
    value: "76312",
    type: "common",
  },
  {
    label: "ERNEMONT-LA-VILLETTE",
    value: "76242",
    type: "common",
  },
  {
    label: "ELBEUF-EN-BRAY",
    value: "76229",
    type: "common",
  },
  {
    label: "BOSC-HYONS",
    value: "76124",
    type: "common",
  },
  {
    label: "AVESNES-EN-BRAY",
    value: "76048",
    type: "common",
  },
  {
    label: "BEAUVOIR-EN-LYONS",
    value: "76067",
    type: "common",
  },
  {
    label: "NOLLEVAL",
    value: "76469",
    type: "common",
  },
  {
    label: "LA FEUILLIE",
    value: "76263",
    type: "common",
  },
  {
    label: "MORVILLE-SUR-ANDELLE",
    value: "76455",
    type: "common",
  },
  {
    label: "LE HERON",
    value: "76358",
    type: "common",
  },
  {
    label: "LA HAYE",
    value: "76352",
    type: "common",
  },
  {
    label: "ELBEUF-SUR-ANDELLE",
    value: "76230",
    type: "common",
  },
  {
    label: "CROISY-SUR-ANDELLE",
    value: "76201",
    type: "common",
  },
  {
    label: "SAINT-DENIS-LE-THIBOULT",
    value: "76573",
    type: "common",
  },
  {
    label: "SAINT-AIGNAN-SUR-RY",
    value: "76554",
    type: "common",
  },
  {
    label: "RY",
    value: "76548",
    type: "common",
  },
  {
    label: "MARTAINVILLE-EPREVILLE",
    value: "76412",
    type: "common",
  },
  {
    label: "GRAINVILLE-SUR-RY",
    value: "76316",
    type: "common",
  },
  {
    label: "LA VIEUX-RUE",
    value: "76740",
    type: "common",
  },
  {
    label: "SERVAVILLE-SALMONVILLE",
    value: "76673",
    type: "common",
  },
  {
    label: "SAINT-JACQUES-SUR-DARNETAL",
    value: "76591",
    type: "common",
  },
  {
    label: "PREAUX",
    value: "76509",
    type: "common",
  },
  {
    label: "BOIS-L'EVEQUE",
    value: "76111",
    type: "common",
  },
  {
    label: "SAINT-MARTIN-DU-VIVIER",
    value: "76617",
    type: "common",
  },
  {
    label: "RONCHEROLLES-SUR-LE-VIVIER",
    value: "76536",
    type: "common",
  },
  {
    label: "FONTAINE-SOUS-PREAUX",
    value: "76273",
    type: "common",
  },
  {
    label: "BOIS-GUILLAUME",
    value: "76108",
    type: "common",
  },
  {
    label: "ROUEN",
    value: "76540",
    type: "common",
  },
  {
    label: "NOTRE-DAME-DE-BONDEVILLE",
    value: "76474",
    type: "common",
  },
  {
    label: "MONT-SAINT-AIGNAN",
    value: "76451",
    type: "common",
  },
  {
    label: "MAROMME",
    value: "76410",
    type: "common",
  },
  {
    label: "DEVILLE-LES-ROUEN",
    value: "76216",
    type: "common",
  },
  {
    label: "LA VAUPALIERE",
    value: "76728",
    type: "common",
  },
  {
    label: "SAINT-MARTIN-DE-BOSCHERVILLE",
    value: "76614",
    type: "common",
  },
  {
    label: "MONTIGNY",
    value: "76446",
    type: "common",
  },
  {
    label: "HENOUVILLE",
    value: "76354",
    type: "common",
  },
  {
    label: "YAINVILLE",
    value: "76750",
    type: "common",
  },
  {
    label: "DUCLAIR",
    value: "76222",
    type: "common",
  },
  {
    label: "BERVILLE-SUR-SEINE",
    value: "76088",
    type: "common",
  },
  {
    label: "ANNEVILLE-AMBOURVILLE",
    value: "76020",
    type: "common",
  },
  {
    label: "LE TRAIT",
    value: "76709",
    type: "common",
  },
  {
    label: "ARELAUNE-EN-SEINE",
    value: "76401",
    type: "common",
  },
  {
    label: "HEURTEAUVILLE",
    value: "76362",
    type: "common",
  },
  {
    label: "SAINT-MAURICE-D'ETELAN",
    value: "76622",
    type: "common",
  },
  {
    label: "PETIVILLE",
    value: "76499",
    type: "common",
  },
  {
    label: "PORT-JEROME-SUR-SEINE",
    value: "76476",
    type: "common",
  },
  {
    label: "NORVILLE",
    value: "76471",
    type: "common",
  },
  {
    label: "SAINT-AUBIN-SUR-QUILLEBEUF",
    value: "27518",
    type: "common",
  },
  {
    label: "QUILLEBEUF-SUR-SEINE",
    value: "27485",
    type: "common",
  },
  {
    label: "TANCARVILLE",
    value: "76684",
    type: "common",
  },
  {
    label: "LA CERLANGUE",
    value: "76169",
    type: "common",
  },
  {
    label: "MARAIS-VERNIER",
    value: "27388",
    type: "common",
  },
  {
    label: "SANDOUVILLE",
    value: "76660",
    type: "common",
  },
  {
    label: "SAINT-VIGOR-D'YMONVILLE",
    value: "76657",
    type: "common",
  },
  {
    label: "ROGERVILLE",
    value: "76533",
    type: "common",
  },
  {
    label: "OUDALLE",
    value: "76489",
    type: "common",
  },
  {
    label: "SAINT-MARTIN-DE-VARREVILLE",
    value: "50517",
    type: "common",
  },
  {
    label: "AUDOUVILLE-LA-HUBERT",
    value: "50021",
    type: "common",
  },
  {
    label: "SAINTE-MARIE-DU-MONT",
    value: "50509",
    type: "common",
  },
  {
    label: "SAINT-GERMAIN-DE-VARREVILLE",
    value: "50479",
    type: "common",
  },
  {
    label: "NEUVILLE-AU-PLAIN",
    value: "50373",
    type: "common",
  },
  {
    label: "TURQUEVILLE",
    value: "50609",
    type: "common",
  },
  {
    label: "SAINTE-MERE-EGLISE",
    value: "50523",
    type: "common",
  },
  {
    label: "FRESVILLE",
    value: "50194",
    type: "common",
  },
  {
    label: "SAINTE-COLOMBE",
    value: "50457",
    type: "common",
  },
  {
    label: "RAUVILLE-LA-PLACE",
    value: "50426",
    type: "common",
  },
  {
    label: "GOLLEVILLE",
    value: "50207",
    type: "common",
  },
  {
    label: "HAUTTEVILLE-BOCAGE",
    value: "50233",
    type: "common",
  },
  {
    label: "REIGNEVILLE-BOCAGE",
    value: "50430",
    type: "common",
  },
  {
    label: "ORGLANDES",
    value: "50387",
    type: "common",
  },
  {
    label: "BINIVILLE",
    value: "50055",
    type: "common",
  },
  {
    label: "NEHOU",
    value: "50370",
    type: "common",
  },
  {
    label: "SAINT-PIERRE-D'ARTHEGLISE",
    value: "50536",
    type: "common",
  },
  {
    label: "SAINT-MAURICE-EN-COTENTIN",
    value: "50522",
    type: "common",
  },
  {
    label: "FIERVILLE-LES-MINES",
    value: "50183",
    type: "common",
  },
  {
    label: "SAINT-JACQUES-DE-NEHOU",
    value: "50486",
    type: "common",
  },
  {
    label: "LES MOITIERS-D'ALLONNE",
    value: "50332",
    type: "common",
  },
  {
    label: "SENOVILLE",
    value: "50572",
    type: "common",
  },
  {
    label: "SORTOSVILLE-EN-BEAUMONT",
    value: "50577",
    type: "common",
  },
  {
    label: "LA HAYE-D'ECTOT",
    value: "50235",
    type: "common",
  },
  {
    label: "BARNEVILLE-CARTERET",
    value: "50031",
    type: "common",
  },
  {
    label: "BAUBIGNY",
    value: "50033",
    type: "common",
  },
  {
    label: "SCHWERDORFF",
    value: "57640",
    type: "common",
  },
  {
    label: "NEUNKIRCHEN-LES-BOUZONVILLE",
    value: "57502",
    type: "common",
  },
  {
    label: "GUERSTLING",
    value: "57273",
    type: "common",
  },
  {
    label: "FILSTROFF",
    value: "57213",
    type: "common",
  },
  {
    label: "COLMEN",
    value: "57149",
    type: "common",
  },
  {
    label: "WALDWEISTROFF",
    value: "57739",
    type: "common",
  },
  {
    label: "SAINT-FRANCOIS-LACROIX",
    value: "57610",
    type: "common",
  },
  {
    label: "LAUMESFELD",
    value: "57387",
    type: "common",
  },
  {
    label: "BIBICHE",
    value: "57079",
    type: "common",
  },
  {
    label: "VECKRING",
    value: "57704",
    type: "common",
  },
  {
    label: "OUDRENNE",
    value: "57531",
    type: "common",
  },
  {
    label: "MONNEREN",
    value: "57476",
    type: "common",
  },
  {
    label: "KEMPLICH",
    value: "57359",
    type: "common",
  },
  {
    label: "BUDLING",
    value: "57118",
    type: "common",
  },
  {
    label: "VALMESTROFF",
    value: "57689",
    type: "common",
  },
  {
    label: "INGLANGE",
    value: "57345",
    type: "common",
  },
  {
    label: "ELZANGE",
    value: "57191",
    type: "common",
  },
  {
    label: "DISTROFF",
    value: "57179",
    type: "common",
  },
  {
    label: "BUDING",
    value: "57117",
    type: "common",
  },
  {
    label: "YUTZ",
    value: "57757",
    type: "common",
  },
  {
    label: "MANOM",
    value: "57441",
    type: "common",
  },
  {
    label: "KUNTZIG",
    value: "57372",
    type: "common",
  },
  {
    label: "BASSE-HAM",
    value: "57287",
    type: "common",
  },
  {
    label: "TERVILLE",
    value: "57666",
    type: "common",
  },
  {
    label: "NILVANGE",
    value: "57508",
    type: "common",
  },
  {
    label: "HAVANGE",
    value: "57305",
    type: "common",
  },
  {
    label: "FONTOY",
    value: "57226",
    type: "common",
  },
  {
    label: "ANGEVILLERS",
    value: "57022",
    type: "common",
  },
  {
    label: "ALGRANGE",
    value: "57012",
    type: "common",
  },
  {
    label: "BOULANGE",
    value: "57096",
    type: "common",
  },
  {
    label: "SERROUVILLE",
    value: "54504",
    type: "common",
  },
  {
    label: "SANCY",
    value: "54491",
    type: "common",
  },
  {
    label: "BEUVILLERS",
    value: "54069",
    type: "common",
  },
  {
    label: "AUDUN-LE-ROMAN",
    value: "54029",
    type: "common",
  },
  {
    label: "ANDERNY",
    value: "54015",
    type: "common",
  },
  {
    label: "PREUTIN-HIGNY",
    value: "54436",
    type: "common",
  },
  {
    label: "MERCY-LE-HAUT",
    value: "54363",
    type: "common",
  },
  {
    label: "MALAVILLERS",
    value: "54337",
    type: "common",
  },
  {
    label: "JOPPECOURT",
    value: "54282",
    type: "common",
  },
  {
    label: "HAN-DEVANT-PIERREPONT",
    value: "54602",
    type: "common",
  },
  {
    label: "XIVRY-CIRCOURT",
    value: "54598",
    type: "common",
  },
  {
    label: "SAINT-SUPPLET",
    value: "54489",
    type: "common",
  },
  {
    label: "MERCY-LE-BAS",
    value: "54362",
    type: "common",
  },
  {
    label: "SAINT-PIERREVILLERS",
    value: "55464",
    type: "common",
  },
  {
    label: "ROUVROIS-SUR-OTHAIN",
    value: "55445",
    type: "common",
  },
  {
    label: "NOUILLONPONT",
    value: "55387",
    type: "common",
  },
  {
    label: "DUZEY",
    value: "55168",
    type: "common",
  },
  {
    label: "SORBEY",
    value: "55495",
    type: "common",
  },
  {
    label: "SAINT-LAURENT-SUR-OTHAIN",
    value: "55461",
    type: "common",
  },
  {
    label: "PILLON",
    value: "55405",
    type: "common",
  },
  {
    label: "MANGIENNES",
    value: "55316",
    type: "common",
  },
  {
    label: "VILLERS-LES-MANGIENNES",
    value: "55563",
    type: "common",
  },
  {
    label: "MERLES-SUR-LOISON",
    value: "55336",
    type: "common",
  },
  {
    label: "DOMBRAS",
    value: "55156",
    type: "common",
  },
  {
    label: "VITTARVILLE",
    value: "55572",
    type: "common",
  },
  {
    label: "PEUVILLERS",
    value: "55403",
    type: "common",
  },
  {
    label: "LISSEY",
    value: "55297",
    type: "common",
  },
  {
    label: "ECUREY-EN-VERDUNOIS",
    value: "55170",
    type: "common",
  },
  {
    label: "BREHEVILLE",
    value: "55076",
    type: "common",
  },
  {
    label: "MURVAUX",
    value: "55365",
    type: "common",
  },
  {
    label: "BRANDEVILLE",
    value: "55071",
    type: "common",
  },
  {
    label: "CLERY-LE-PETIT",
    value: "55119",
    type: "common",
  },
  {
    label: "SASSEY-SUR-MEUSE",
    value: "55469",
    type: "common",
  },
  {
    label: "MILLY-SUR-BRADON",
    value: "55338",
    type: "common",
  },
  {
    label: "FONTAINES-SAINT-CLAIR",
    value: "55192",
    type: "common",
  },
  {
    label: "DUN-SUR-MEUSE",
    value: "55167",
    type: "common",
  },
  {
    label: "TAILLY",
    value: "08437",
    type: "common",
  },
  {
    label: "VILLERS-DEVANT-DUN",
    value: "55561",
    type: "common",
  },
  {
    label: "MONT-DEVANT-SASSEY",
    value: "55345",
    type: "common",
  },
  {
    label: "DOULCON",
    value: "55165",
    type: "common",
  },
  {
    label: "AINCREVILLE",
    value: "55004",
    type: "common",
  },
  {
    label: "BAYONVILLE",
    value: "08052",
    type: "common",
  },
  {
    label: "BEFFU-ET-LE-MORTHOMME",
    value: "08056",
    type: "common",
  },
  {
    label: "IMECOURT",
    value: "08233",
    type: "common",
  },
  {
    label: "VERPEL",
    value: "08470",
    type: "common",
  },
  {
    label: "THENORGUES",
    value: "08446",
    type: "common",
  },
  {
    label: "BUZANCY",
    value: "08089",
    type: "common",
  },
  {
    label: "LONGWE",
    value: "08259",
    type: "common",
  },
  {
    label: "BOULT-AUX-BOIS",
    value: "08075",
    type: "common",
  },
  {
    label: "BRIQUENAY",
    value: "08086",
    type: "common",
  },
  {
    label: "LA CROIX-AUX-BOIS",
    value: "08135",
    type: "common",
  },
  {
    label: "FALAISE",
    value: "08164",
    type: "common",
  },
  {
    label: "BALLAY",
    value: "08045",
    type: "common",
  },
  {
    label: "VOUZIERS",
    value: "08490",
    type: "common",
  },
  {
    label: "SAINTE-MARIE",
    value: "08390",
    type: "common",
  },
  {
    label: "MARS-SOUS-BOURCQ",
    value: "08279",
    type: "common",
  },
  {
    label: "BOURCQ",
    value: "08077",
    type: "common",
  },
  {
    label: "TOURCELLES-CHAUMONT",
    value: "08455",
    type: "common",
  },
  {
    label: "QUILLY",
    value: "08351",
    type: "common",
  },
  {
    label: "LEFFINCOURT",
    value: "08250",
    type: "common",
  },
  {
    label: "CHARDENY",
    value: "08104",
    type: "common",
  },
  {
    label: "DRICOURT",
    value: "08147",
    type: "common",
  },
  {
    label: "MONT-SAINT-REMY",
    value: "08309",
    type: "common",
  },
  {
    label: "PAUVRES",
    value: "08338",
    type: "common",
  },
  {
    label: "VILLE-SUR-RETOURNE",
    value: "08484",
    type: "common",
  },
  {
    label: "BIGNICOURT",
    value: "08066",
    type: "common",
  },
  {
    label: "JUNIVILLE",
    value: "08239",
    type: "common",
  },
  {
    label: "NEUFLIZE",
    value: "08314",
    type: "common",
  },
  {
    label: "LE CHATELET-SUR-RETOURNE",
    value: "08111",
    type: "common",
  },
  {
    label: "ALINCOURT",
    value: "08005",
    type: "common",
  },
  {
    label: "BERGNICOURT",
    value: "08060",
    type: "common",
  },
  {
    label: "SAINT-REMY-LE-PETIT",
    value: "08397",
    type: "common",
  },
  {
    label: "L'ECAILLE",
    value: "08148",
    type: "common",
  },
  {
    label: "ROIZY",
    value: "08368",
    type: "common",
  },
  {
    label: "BOULT-SUR-SUIPPE",
    value: "51074",
    type: "common",
  },
  {
    label: "SAINT-ETIENNE-SUR-SUIPPE",
    value: "51477",
    type: "common",
  },
  {
    label: "POILCOURT-SYDNEY",
    value: "08340",
    type: "common",
  },
  {
    label: "SAULT-SAINT-REMY",
    value: "08404",
    type: "common",
  },
  {
    label: "HOUDILCOURT",
    value: "08229",
    type: "common",
  },
  {
    label: "AUMENANCOURT",
    value: "51025",
    type: "common",
  },
  {
    label: "BRIENNE-SUR-AISNE",
    value: "08084",
    type: "common",
  },
  {
    label: "BERTRICOURT",
    value: "02076",
    type: "common",
  },
  {
    label: "PIGNICOURT",
    value: "02601",
    type: "common",
  },
  {
    label: "NEUFCHATEL-SUR-AISNE",
    value: "02541",
    type: "common",
  },
  {
    label: "CONDE-SUR-SUIPPE",
    value: "02211",
    type: "common",
  },
  {
    label: "CORMICY",
    value: "51171",
    type: "common",
  },
  {
    label: "AGUILCOURT",
    value: "02005",
    type: "common",
  },
  {
    label: "VARISCOURT",
    value: "02761",
    type: "common",
  },
  {
    label: "BERRY-AU-BAC",
    value: "02073",
    type: "common",
  },
  {
    label: "LA VILLE-AUX-BOIS-LES-PONTAVERT",
    value: "02803",
    type: "common",
  },
  {
    label: "PONTAVERT",
    value: "02613",
    type: "common",
  },
  {
    label: "JUMIGNY",
    value: "02396",
    type: "common",
  },
  {
    label: "CUIRY-LES-CHAUDARDES",
    value: "02250",
    type: "common",
  },
  {
    label: "CRAONNELLE",
    value: "02235",
    type: "common",
  },
  {
    label: "CHAUDARDES",
    value: "02171",
    type: "common",
  },
  {
    label: "BEAURIEUX",
    value: "02058",
    type: "common",
  },
  {
    label: "VASSOGNE",
    value: "02764",
    type: "common",
  },
  {
    label: "OULCHES-LA-VALLEE-FOULON",
    value: "02578",
    type: "common",
  },
  {
    label: "CUISSY-ET-GENY",
    value: "02252",
    type: "common",
  },
  {
    label: "BOURG-ET-COMIN",
    value: "02106",
    type: "common",
  },
  {
    label: "VENDRESSE-BEAULNE",
    value: "02778",
    type: "common",
  },
  {
    label: "PARGNAN",
    value: "02588",
    type: "common",
  },
  {
    label: "PAISSY",
    value: "02582",
    type: "common",
  },
  {
    label: "ŒUILLY",
    value: "02565",
    type: "common",
  },
  {
    label: "MOUSSY-VERNEUIL",
    value: "02531",
    type: "common",
  },
  {
    label: "MOULINS",
    value: "02530",
    type: "common",
  },
  {
    label: "CYS-LA-COMMUNE",
    value: "02255",
    type: "common",
  },
  {
    label: "CHAVONNE",
    value: "02176",
    type: "common",
  },
  {
    label: "BRAYE-EN-LAONNOIS",
    value: "02115",
    type: "common",
  },
  {
    label: "SOUPIR",
    value: "02730",
    type: "common",
  },
  {
    label: "SAINT-MARD",
    value: "02682",
    type: "common",
  },
  {
    label: "PRESLES-ET-BOVES",
    value: "02620",
    type: "common",
  },
  {
    label: "PONT-ARCY",
    value: "02612",
    type: "common",
  },
  {
    label: "OSTEL",
    value: "02577",
    type: "common",
  },
  {
    label: "CONDE-SUR-AISNE",
    value: "02210",
    type: "common",
  },
  {
    label: "CELLES-SUR-AISNE",
    value: "02148",
    type: "common",
  },
  {
    label: "AIZY-JOUY",
    value: "02008",
    type: "common",
  },
  {
    label: "VAILLY-SUR-AISNE",
    value: "02758",
    type: "common",
  },
  {
    label: "SANCY-LES-CHEMINOTS",
    value: "02698",
    type: "common",
  },
  {
    label: "NANTEUIL-LA-FOSSE",
    value: "02537",
    type: "common",
  },
  {
    label: "MARGIVAL",
    value: "02464",
    type: "common",
  },
  {
    label: "LAFFAUX",
    value: "02400",
    type: "common",
  },
  {
    label: "CROUY",
    value: "02243",
    type: "common",
  },
  {
    label: "CLAMECY",
    value: "02198",
    type: "common",
  },
  {
    label: "CHIVRES-VAL",
    value: "02190",
    type: "common",
  },
  {
    label: "BUCY-LE-LONG",
    value: "02131",
    type: "common",
  },
  {
    label: "BRAYE",
    value: "02118",
    type: "common",
  },
  {
    label: "VUILLERY",
    value: "02829",
    type: "common",
  },
  {
    label: "VREGNY",
    value: "02828",
    type: "common",
  },
  {
    label: "TERNY-SORNY",
    value: "02739",
    type: "common",
  },
  {
    label: "MISSY-SUR-AISNE",
    value: "02487",
    type: "common",
  },
  {
    label: "LEURY",
    value: "02424",
    type: "common",
  },
  {
    label: "CUFFIES",
    value: "02245",
    type: "common",
  },
  {
    label: "CHAVIGNY",
    value: "02175",
    type: "common",
  },
  {
    label: "VAUXREZIS",
    value: "02767",
    type: "common",
  },
  {
    label: "POMMIERS",
    value: "02610",
    type: "common",
  },
  {
    label: "PASLY",
    value: "02593",
    type: "common",
  },
  {
    label: "FONTENOY",
    value: "02326",
    type: "common",
  },
  {
    label: "CUISY-EN-ALMONT",
    value: "02253",
    type: "common",
  },
  {
    label: "TARTIERS",
    value: "02736",
    type: "common",
  },
  {
    label: "OSLY-COURTIL",
    value: "02576",
    type: "common",
  },
  {
    label: "NOUVRON-VINGRE",
    value: "02562",
    type: "common",
  },
  {
    label: "BERNY-RIVIERE",
    value: "02071",
    type: "common",
  },
  {
    label: "SAINT-PIERRE-LES-BITRY",
    value: "60593",
    type: "common",
  },
  {
    label: "BITRY",
    value: "60072",
    type: "common",
  },
  {
    label: "VIC-SUR-AISNE",
    value: "02795",
    type: "common",
  },
  {
    label: "SAINT-CHRISTOPHE-A-BERRY",
    value: "02673",
    type: "common",
  },
  {
    label: "JAULZY",
    value: "60324",
    type: "common",
  },
  {
    label: "COULOISY",
    value: "60167",
    type: "common",
  },
  {
    label: "BERNEUIL-SUR-AISNE",
    value: "60064",
    type: "common",
  },
  {
    label: "ATTICHY",
    value: "60025",
    type: "common",
  },
  {
    label: "TROSLY-BREUIL",
    value: "60647",
    type: "common",
  },
  {
    label: "SAINT-CREPIN-AUX-BOIS",
    value: "60569",
    type: "common",
  },
  {
    label: "RETHONDES",
    value: "60534",
    type: "common",
  },
  {
    label: "COMPIEGNE",
    value: "60159",
    type: "common",
  },
  {
    label: "CLAIROIX",
    value: "60156",
    type: "common",
  },
  {
    label: "CHOISY-AU-BAC",
    value: "60151",
    type: "common",
  },
  {
    label: "VENETTE",
    value: "60665",
    type: "common",
  },
  {
    label: "MARGNY-LES-COMPIEGNE",
    value: "60382",
    type: "common",
  },
  {
    label: "LACHELLE",
    value: "60337",
    type: "common",
  },
  {
    label: "JONQUIERES",
    value: "60326",
    type: "common",
  },
  {
    label: "JAUX",
    value: "60325",
    type: "common",
  },
  {
    label: "REMY",
    value: "60531",
    type: "common",
  },
  {
    label: "MOYVILLERS",
    value: "60441",
    type: "common",
  },
  {
    label: "FRANCIERES",
    value: "60254",
    type: "common",
  },
  {
    label: "ARSY",
    value: "60024",
    type: "common",
  },
  {
    label: "FOUILLEUSE",
    value: "60247",
    type: "common",
  },
  {
    label: "ESTREES-SAINT-DENIS",
    value: "60223",
    type: "common",
  },
  {
    label: "EPINEUSE",
    value: "60210",
    type: "common",
  },
  {
    label: "CRESSONSACQ",
    value: "60177",
    type: "common",
  },
  {
    label: "BAILLEUL-LE-SOC",
    value: "60040",
    type: "common",
  },
  {
    label: "SAINT-AUBIN-SOUS-ERQUERY",
    value: "60568",
    type: "common",
  },
  {
    label: "REMECOURT",
    value: "60529",
    type: "common",
  },
  {
    label: "NOROY",
    value: "60466",
    type: "common",
  },
  {
    label: "MAIMBEVILLE",
    value: "60375",
    type: "common",
  },
  {
    label: "LAMECOURT",
    value: "60345",
    type: "common",
  },
  {
    label: "ERQUERY",
    value: "60215",
    type: "common",
  },
  {
    label: "CUIGNIERES",
    value: "60186",
    type: "common",
  },
  {
    label: "CERNOY",
    value: "60137",
    type: "common",
  },
  {
    label: "FITZ-JAMES",
    value: "60234",
    type: "common",
  },
  {
    label: "ETOUY",
    value: "60225",
    type: "common",
  },
  {
    label: "AVRECHY",
    value: "60034",
    type: "common",
  },
  {
    label: "AIRION",
    value: "60008",
    type: "common",
  },
  {
    label: "AGNETZ",
    value: "60007",
    type: "common",
  },
  {
    label: "LA RUE-SAINT-PIERRE",
    value: "60559",
    type: "common",
  },
  {
    label: "REMERANGLES",
    value: "60530",
    type: "common",
  },
  {
    label: "LITZ",
    value: "60366",
    type: "common",
  },
  {
    label: "ROCHY-CONDE",
    value: "60542",
    type: "common",
  },
  {
    label: "LAVERSINES",
    value: "60355",
    type: "common",
  },
  {
    label: "LE FAY-SAINT-QUENTIN",
    value: "60230",
    type: "common",
  },
  {
    label: "BRESLES",
    value: "60103",
    type: "common",
  },
  {
    label: "THERDONNE",
    value: "60628",
    type: "common",
  },
  {
    label: "ALLONNE",
    value: "60009",
    type: "common",
  },
  {
    label: "AUX MARAIS",
    value: "60703",
    type: "common",
  },
  {
    label: "SAINT-MARTIN-LE-NŒUD",
    value: "60586",
    type: "common",
  },
  {
    label: "RAINVILLERS",
    value: "60523",
    type: "common",
  },
  {
    label: "GOINCOURT",
    value: "60277",
    type: "common",
  },
  {
    label: "BEAUVAIS",
    value: "60057",
    type: "common",
  },
  {
    label: "VILLERS-SAINT-BARTHELEMY",
    value: "60681",
    type: "common",
  },
  {
    label: "SAINT-PAUL",
    value: "60591",
    type: "common",
  },
  {
    label: "SAINT-GERMAIN-LA-POTERIE",
    value: "60576",
    type: "common",
  },
  {
    label: "ONS-EN-BRAY",
    value: "60477",
    type: "common",
  },
  {
    label: "LACHAPELLE-AUX-POTS",
    value: "60333",
    type: "common",
  },
  {
    label: "SAINT-AUBIN-EN-BRAY",
    value: "60567",
    type: "common",
  },
  {
    label: "LALANDELLE",
    value: "60344",
    type: "common",
  },
  {
    label: "ESPAUBOURG",
    value: "60220",
    type: "common",
  },
  {
    label: "CUIGY-EN-BRAY",
    value: "60187",
    type: "common",
  },
  {
    label: "LE COUDRAY-SAINT-GERMER",
    value: "60164",
    type: "common",
  },
  {
    label: "TALMONTIERS",
    value: "60626",
    type: "common",
  },
  {
    label: "SAINT-PIERRE-ES-CHAMPS",
    value: "60592",
    type: "common",
  },
  {
    label: "SAINT-GERMER-DE-FLY",
    value: "60577",
    type: "common",
  },
  {
    label: "PUISEUX-EN-BRAY",
    value: "60516",
    type: "common",
  },
  {
    label: "LALANDE-EN-SON",
    value: "60343",
    type: "common",
  },
  {
    label: "NEUF-MARCHE",
    value: "76463",
    type: "common",
  },
  {
    label: "MONTROTY",
    value: "76450",
    type: "common",
  },
  {
    label: "MARTAGNY",
    value: "27392",
    type: "common",
  },
  {
    label: "BOUCHEVILLIERS",
    value: "27098",
    type: "common",
  },
  {
    label: "BEZANCOURT",
    value: "76093",
    type: "common",
  },
  {
    label: "LILLY",
    value: "27369",
    type: "common",
  },
  {
    label: "FLEURY-LA-FORET",
    value: "27245",
    type: "common",
  },
  {
    label: "BOSQUENTIN",
    value: "27094",
    type: "common",
  },
  {
    label: "BEZU-LA-FORET",
    value: "27066",
    type: "common",
  },
  {
    label: "LE TRONQUAY",
    value: "27664",
    type: "common",
  },
  {
    label: "LORLEAU",
    value: "27373",
    type: "common",
  },
  {
    label: "BEAUFICEL-EN-LYONS",
    value: "27048",
    type: "common",
  },
  {
    label: "VASCŒUIL",
    value: "27672",
    type: "common",
  },
  {
    label: "LES HOGUES",
    value: "27338",
    type: "common",
  },
  {
    label: "FRESNE-LE-PLAN",
    value: "76285",
    type: "common",
  },
  {
    label: "AUZOUVILLE-SUR-RY",
    value: "76046",
    type: "common",
  },
  {
    label: "RENNEVILLE",
    value: "27488",
    type: "common",
  },
  {
    label: "PERRUEL",
    value: "27454",
    type: "common",
  },
  {
    label: "PERRIERS-SUR-ANDELLE",
    value: "27453",
    type: "common",
  },
  {
    label: "LETTEGUIVES",
    value: "27366",
    type: "common",
  },
  {
    label: "SAINT-AUBIN-EPINAY",
    value: "76560",
    type: "common",
  },
  {
    label: "MONTMAIN",
    value: "76448",
    type: "common",
  },
  {
    label: "MESNIL-RAOUL",
    value: "76434",
    type: "common",
  },
  {
    label: "BOIS-D'ENNEBOURG",
    value: "76106",
    type: "common",
  },
  {
    label: "SAINT-LEGER-DU-BOURG-DENIS",
    value: "76599",
    type: "common",
  },
  {
    label: "FRANQUEVILLE-SAINT-PIERRE",
    value: "76475",
    type: "common",
  },
  {
    label: "LE MESNIL-ESNARD",
    value: "76429",
    type: "common",
  },
  {
    label: "DARNETAL",
    value: "76212",
    type: "common",
  },
  {
    label: "BONSECOURS",
    value: "76103",
    type: "common",
  },
  {
    label: "AMFREVILLE-LA-MI-VOIE",
    value: "76005",
    type: "common",
  },
  {
    label: "SOTTEVILLE-LES-ROUEN",
    value: "76681",
    type: "common",
  },
  {
    label: "SAINT-ETIENNE-DU-ROUVRAY",
    value: "76575",
    type: "common",
  },
  {
    label: "LE PETIT-QUEVILLY",
    value: "76498",
    type: "common",
  },
  {
    label: "PETIT-COURONNE",
    value: "76497",
    type: "common",
  },
  {
    label: "LE GRAND-QUEVILLY",
    value: "76322",
    type: "common",
  },
  {
    label: "CANTELEU",
    value: "76157",
    type: "common",
  },
  {
    label: "VAL-DE-LA-HAYE",
    value: "76717",
    type: "common",
  },
  {
    label: "SAINT-PIERRE-DE-MANNEVILLE",
    value: "76634",
    type: "common",
  },
  {
    label: "QUEVILLON",
    value: "76513",
    type: "common",
  },
  {
    label: "BARDOUVILLE",
    value: "76056",
    type: "common",
  },
  {
    label: "YVILLE-SUR-SEINE",
    value: "76759",
    type: "common",
  },
  {
    label: "LE MESNIL-SOUS-JUMIEGES",
    value: "76436",
    type: "common",
  },
  {
    label: "MAUNY",
    value: "76419",
    type: "common",
  },
  {
    label: "JUMIEGES",
    value: "76378",
    type: "common",
  },
  {
    label: "BARNEVILLE-SUR-SEINE",
    value: "27039",
    type: "common",
  },
  {
    label: "LE LANDIN",
    value: "27363",
    type: "common",
  },
  {
    label: "HAUVILLE",
    value: "27316",
    type: "common",
  },
  {
    label: "VATTEVILLE-LA-RUE",
    value: "76727",
    type: "common",
  },
  {
    label: "ROUTOT",
    value: "27500",
    type: "common",
  },
  {
    label: "LA HAYE-DE-ROUTOT",
    value: "27319",
    type: "common",
  },
  {
    label: "LA HAYE-AUBREE",
    value: "27317",
    type: "common",
  },
  {
    label: "ETREVILLE",
    value: "27227",
    type: "common",
  },
  {
    label: "VIEUX-PORT",
    value: "27686",
    type: "common",
  },
  {
    label: "TROUVILLE-LA-HAULE",
    value: "27665",
    type: "common",
  },
  {
    label: "TOCQUEVILLE",
    value: "27645",
    type: "common",
  },
  {
    label: "BOURNEVILLE-SAINTE-CROIX",
    value: "27107",
    type: "common",
  },
  {
    label: "AIZIER",
    value: "27006",
    type: "common",
  },
  {
    label: "SAINTE-OPPORTUNE-LA-MARE",
    value: "27577",
    type: "common",
  },
  {
    label: "SAINT-MARDS-DE-BLACARVILLE",
    value: "27563",
    type: "common",
  },
  {
    label: "BOUQUELON",
    value: "27101",
    type: "common",
  },
  {
    label: "SAINT-SULPICE-DE-GRIMBOUVILLE",
    value: "27604",
    type: "common",
  },
  {
    label: "SAINT-SAMSON-DE-LA-ROQUE",
    value: "27601",
    type: "common",
  },
  {
    label: "FOULBEC",
    value: "27260",
    type: "common",
  },
  {
    label: "CONTEVILLE",
    value: "27169",
    type: "common",
  },
  {
    label: "SAINT-PIERRE-DU-VAL",
    value: "27597",
    type: "common",
  },
  {
    label: "FIQUEFLEUR-EQUAINVILLE",
    value: "27243",
    type: "common",
  },
  {
    label: "FATOUVILLE-GRESTAIN",
    value: "27233",
    type: "common",
  },
  {
    label: "BERVILLE-SUR-MER",
    value: "27064",
    type: "common",
  },
  {
    label: "ABLON",
    value: "14001",
    type: "common",
  },
  {
    label: "LA RIVIERE-SAINT-SAUVEUR",
    value: "14536",
    type: "common",
  },
  {
    label: "HONFLEUR",
    value: "14333",
    type: "common",
  },
  {
    label: "GONNEVILLE-SUR-HONFLEUR",
    value: "14304",
    type: "common",
  },
  {
    label: "EQUEMAUVILLE",
    value: "14243",
    type: "common",
  },
  {
    label: "BARNEVILLE-LA-BERTRAN",
    value: "14041",
    type: "common",
  },
  {
    label: "VILLERVILLE",
    value: "14755",
    type: "common",
  },
  {
    label: "TROUVILLE-SUR-MER",
    value: "14715",
    type: "common",
  },
  {
    label: "PENNEDEPIE",
    value: "14492",
    type: "common",
  },
  {
    label: "CRICQUEBŒUF",
    value: "14202",
    type: "common",
  },
  {
    label: "SAINT-LAURENT-SUR-MER",
    value: "14605",
    type: "common",
  },
  {
    label: "COLLEVILLE-SUR-MER",
    value: "14165",
    type: "common",
  },
  {
    label: "VIERVILLE-SUR-MER",
    value: "14745",
    type: "common",
  },
  {
    label: "SAINT-PIERRE-DU-MONT",
    value: "14652",
    type: "common",
  },
  {
    label: "ENGLESQUEVILLE-LA-PERCEE",
    value: "14239",
    type: "common",
  },
  {
    label: "DEUX-JUMEAUX",
    value: "14224",
    type: "common",
  },
  {
    label: "ASNIERES-EN-BESSIN",
    value: "14023",
    type: "common",
  },
  {
    label: "GRANDCAMP-MAISY",
    value: "14312",
    type: "common",
  },
  {
    label: "CRICQUEVILLE-EN-BESSIN",
    value: "14204",
    type: "common",
  },
  {
    label: "CARDONVILLE",
    value: "14136",
    type: "common",
  },
  {
    label: "GEFOSSE-FONTENAY",
    value: "14298",
    type: "common",
  },
  {
    label: "SEBEVILLE",
    value: "50571",
    type: "common",
  },
  {
    label: "LIESVILLE-SUR-DOUVE",
    value: "50269",
    type: "common",
  },
  {
    label: "HIESVILLE",
    value: "50246",
    type: "common",
  },
  {
    label: "BLOSVILLE",
    value: "50059",
    type: "common",
  },
  {
    label: "BOUTTEVILLE",
    value: "50070",
    type: "common",
  },
  {
    label: "PICAUVILLE",
    value: "50400",
    type: "common",
  },
  {
    label: "BEUZEVILLE-LA-BASTILLE",
    value: "50052",
    type: "common",
  },
  {
    label: "CROSVILLE-SUR-DOUVE",
    value: "50156",
    type: "common",
  },
  {
    label: "ETIENVILLE",
    value: "50177",
    type: "common",
  },
  {
    label: "LA BONNEVILLE",
    value: "50064",
    type: "common",
  },
  {
    label: "VARENGUEBEC",
    value: "50617",
    type: "common",
  },
  {
    label: "NEUVILLE-EN-BEAUMONT",
    value: "50374",
    type: "common",
  },
  {
    label: "SAINT-SAUVEUR-LE-VICOMTE",
    value: "50551",
    type: "common",
  },
  {
    label: "TAILLEPIED",
    value: "50587",
    type: "common",
  },
  {
    label: "CATTEVILLE",
    value: "50105",
    type: "common",
  },
  {
    label: "DOVILLE",
    value: "50166",
    type: "common",
  },
  {
    label: "LE MESNIL",
    value: "50299",
    type: "common",
  },
  {
    label: "BESNEVILLE",
    value: "50049",
    type: "common",
  },
  {
    label: "SAINT-SAUVEUR-DE-PIERREPONT",
    value: "50548",
    type: "common",
  },
  {
    label: "CANVILLE-LA-ROCQUE",
    value: "50097",
    type: "common",
  },
  {
    label: "SAINT-JEAN-DE-LA-RIVIERE",
    value: "50490",
    type: "common",
  },
  {
    label: "SAINT-GEORGES-DE-LA-RIVIERE",
    value: "50471",
    type: "common",
  },
  {
    label: "REMERING",
    value: "57570",
    type: "common",
  },
  {
    label: "VŒLFLING-LES-BOUZONVILLE",
    value: "57749",
    type: "common",
  },
  {
    label: "VILLING",
    value: "57720",
    type: "common",
  },
  {
    label: "CHATEAU-ROUGE",
    value: "57131",
    type: "common",
  },
  {
    label: "BERVILLER-EN-MOSELLE",
    value: "57069",
    type: "common",
  },
  {
    label: "VAUDRECHING",
    value: "57700",
    type: "common",
  },
  {
    label: "REMELFANG",
    value: "57567",
    type: "common",
  },
  {
    label: "OBERDORFF",
    value: "57516",
    type: "common",
  },
  {
    label: "HEINING-LES-BOUZONVILLE",
    value: "57309",
    type: "common",
  },
  {
    label: "BRETTNACH",
    value: "57110",
    type: "common",
  },
  {
    label: "BOUZONVILLE",
    value: "57106",
    type: "common",
  },
  {
    label: "ALZING",
    value: "57016",
    type: "common",
  },
  {
    label: "MENSKIRCH",
    value: "57457",
    type: "common",
  },
  {
    label: "HESTROFF",
    value: "57322",
    type: "common",
  },
  {
    label: "FREISTROFF",
    value: "57235",
    type: "common",
  },
  {
    label: "CHEMERY-LES-DEUX",
    value: "57136",
    type: "common",
  },
  {
    label: "ANZELING",
    value: "57025",
    type: "common",
  },
  {
    label: "KLANG",
    value: "57367",
    type: "common",
  },
  {
    label: "KEDANGE-SUR-CANNER",
    value: "57358",
    type: "common",
  },
  {
    label: "HOMBOURG-BUDANGE",
    value: "57331",
    type: "common",
  },
  {
    label: "EBERSVILLER",
    value: "57186",
    type: "common",
  },
  {
    label: "DALSTEIN",
    value: "57167",
    type: "common",
  },
  {
    label: "VOLSTROFF",
    value: "57733",
    type: "common",
  },
  {
    label: "RURANGE-LES-THIONVILLE",
    value: "57602",
    type: "common",
  },
  {
    label: "METZERVISSE",
    value: "57465",
    type: "common",
  },
  {
    label: "METZERESCHE",
    value: "57464",
    type: "common",
  },
  {
    label: "LUTTANGE",
    value: "57426",
    type: "common",
  },
  {
    label: "STUCKANGE",
    value: "57767",
    type: "common",
  },
  {
    label: "UCKANGE",
    value: "57683",
    type: "common",
  },
  {
    label: "RICHEMONT",
    value: "57582",
    type: "common",
  },
  {
    label: "ILLANGE",
    value: "57343",
    type: "common",
  },
  {
    label: "GUENANGE",
    value: "57269",
    type: "common",
  },
  {
    label: "BOUSSE",
    value: "57102",
    type: "common",
  },
  {
    label: "BERTRANGE",
    value: "57067",
    type: "common",
  },
  {
    label: "VITRY-SUR-ORNE",
    value: "57724",
    type: "common",
  },
  {
    label: "SEREMANGE-ERZANGE",
    value: "57647",
    type: "common",
  },
  {
    label: "GANDRANGE",
    value: "57242",
    type: "common",
  },
  {
    label: "FLORANGE",
    value: "57221",
    type: "common",
  },
  {
    label: "FAMECK",
    value: "57206",
    type: "common",
  },
  {
    label: "RANGUEVAUX",
    value: "57562",
    type: "common",
  },
  {
    label: "NEUFCHEF",
    value: "57498",
    type: "common",
  },
  {
    label: "MOYEUVRE-PETITE",
    value: "57492",
    type: "common",
  },
  {
    label: "LOMMERANGE",
    value: "57411",
    type: "common",
  },
  {
    label: "KNUTANGE",
    value: "57368",
    type: "common",
  },
  {
    label: "HAYANGE",
    value: "57306",
    type: "common",
  },
  {
    label: "AVRIL",
    value: "54036",
    type: "common",
  },
  {
    label: "TUCQUEGNIEUX",
    value: "54536",
    type: "common",
  },
  {
    label: "TRIEUX",
    value: "54533",
    type: "common",
  },
  {
    label: "BETTAINVILLERS",
    value: "54066",
    type: "common",
  },
  {
    label: "MURVILLE",
    value: "54394",
    type: "common",
  },
  {
    label: "MAIRY-MAINVILLE",
    value: "54334",
    type: "common",
  },
  {
    label: "LANDRES",
    value: "54295",
    type: "common",
  },
  {
    label: "MONT-BONVILLERS",
    value: "54084",
    type: "common",
  },
  {
    label: "SPINCOURT",
    value: "55500",
    type: "common",
  },
  {
    label: "DOMREMY-LA-CANNE",
    value: "55162",
    type: "common",
  },
  {
    label: "BOULIGNY",
    value: "55063",
    type: "common",
  },
  {
    label: "PIENNES",
    value: "54425",
    type: "common",
  },
  {
    label: "JOUDREVILLE",
    value: "54284",
    type: "common",
  },
  {
    label: "DOMPRIX",
    value: "54169",
    type: "common",
  },
  {
    label: "AVILLERS",
    value: "54033",
    type: "common",
  },
  {
    label: "VAUDONCOURT",
    value: "55535",
    type: "common",
  },
  {
    label: "MUZERAY",
    value: "55367",
    type: "common",
  },
  {
    label: "LOISON",
    value: "55299",
    type: "common",
  },
  {
    label: "GOURAINCOURT",
    value: "55216",
    type: "common",
  },
  {
    label: "BILLY-SOUS-MANGIENNES",
    value: "55053",
    type: "common",
  },
  {
    label: "ROMAGNE-SOUS-LES-COTES",
    value: "55437",
    type: "common",
  },
  {
    label: "CHAUMONT-DEVANT-DAMVILLERS",
    value: "55107",
    type: "common",
  },
  {
    label: "WAVRILLE",
    value: "55580",
    type: "common",
  },
  {
    label: "MOIREY-FLABAS-CREPION",
    value: "55341",
    type: "common",
  },
  {
    label: "ETRAYE",
    value: "55183",
    type: "common",
  },
  {
    label: "DAMVILLERS",
    value: "55145",
    type: "common",
  },
  {
    label: "VILOSNES-HARAUMONT",
    value: "55571",
    type: "common",
  },
  {
    label: "SIVRY-SUR-MEUSE",
    value: "55490",
    type: "common",
  },
  {
    label: "REVILLE-AUX-BOIS",
    value: "55428",
    type: "common",
  },
  {
    label: "CONSENVOYE",
    value: "55124",
    type: "common",
  },
  {
    label: "LINY-DEVANT-DUN",
    value: "55292",
    type: "common",
  },
  {
    label: "DANNEVOUX",
    value: "55146",
    type: "common",
  },
  {
    label: "BRIEULLES-SUR-MEUSE",
    value: "55078",
    type: "common",
  },
  {
    label: "CLERY-LE-GRAND",
    value: "55118",
    type: "common",
  },
  {
    label: "ROMAGNE-SOUS-MONTFAUCON",
    value: "55438",
    type: "common",
  },
  {
    label: "CUNEL",
    value: "55140",
    type: "common",
  },
  {
    label: "CIERGES-SOUS-MONTFAUCON",
    value: "55115",
    type: "common",
  },
  {
    label: "BANTHEVILLE",
    value: "55028",
    type: "common",
  },
  {
    label: "SOMMERANCE",
    value: "08425",
    type: "common",
  },
  {
    label: "FLEVILLE",
    value: "08171",
    type: "common",
  },
  {
    label: "LANDRES-ET-SAINT-GEORGES",
    value: "08246",
    type: "common",
  },
  {
    label: "GESNES-EN-ARGONNE",
    value: "55208",
    type: "common",
  },
  {
    label: "SAINT-JUVIN",
    value: "08383",
    type: "common",
  },
  {
    label: "MARCQ",
    value: "08274",
    type: "common",
  },
  {
    label: "CHAMPIGNEULLE",
    value: "08098",
    type: "common",
  },
  {
    label: "CHEVIERES",
    value: "08120",
    type: "common",
  },
  {
    label: "SENUC",
    value: "08412",
    type: "common",
  },
  {
    label: "GRANDPRE",
    value: "08198",
    type: "common",
  },
  {
    label: "OLIZY-PRIMAT",
    value: "08333",
    type: "common",
  },
  {
    label: "MOURON",
    value: "08310",
    type: "common",
  },
  {
    label: "CHALLERANGE",
    value: "08097",
    type: "common",
  },
  {
    label: "SAVIGNY-SUR-AISNE",
    value: "08406",
    type: "common",
  },
  {
    label: "BRECY-BRIERES",
    value: "08082",
    type: "common",
  },
  {
    label: "MONT-SAINT-MARTIN",
    value: "08308",
    type: "common",
  },
  {
    label: "SAINT-MOREL",
    value: "08392",
    type: "common",
  },
  {
    label: "SUGNY",
    value: "08431",
    type: "common",
  },
  {
    label: "CONTREUVE",
    value: "08130",
    type: "common",
  },
  {
    label: "SEMIDE",
    value: "08410",
    type: "common",
  },
  {
    label: "CAUROY",
    value: "08092",
    type: "common",
  },
  {
    label: "MACHAULT",
    value: "08264",
    type: "common",
  },
  {
    label: "HAUVINE",
    value: "08220",
    type: "common",
  },
  {
    label: "LA NEUVILLE-EN-TOURNE-A-FUY",
    value: "08320",
    type: "common",
  },
  {
    label: "HEUTREGIVILLE",
    value: "51293",
    type: "common",
  },
  {
    label: "MENIL-LEPINOIS",
    value: "08287",
    type: "common",
  },
  {
    label: "AUSSONCE",
    value: "08032",
    type: "common",
  },
  {
    label: "BAZANCOURT",
    value: "51043",
    type: "common",
  },
  {
    label: "WARMERIVILLE",
    value: "51660",
    type: "common",
  },
  {
    label: "ISLES-SUR-SUIPPE",
    value: "51299",
    type: "common",
  },
  {
    label: "POMACLE",
    value: "51439",
    type: "common",
  },
  {
    label: "BOURGOGNE-FRESNE",
    value: "51075",
    type: "common",
  },
  {
    label: "BRIMONT",
    value: "51088",
    type: "common",
  },
  {
    label: "BERMERICOURT",
    value: "51051",
    type: "common",
  },
  {
    label: "ORAINVILLE",
    value: "02572",
    type: "common",
  },
  {
    label: "HERMONVILLE",
    value: "51291",
    type: "common",
  },
  {
    label: "CAUROY-LES-HERMONVILLE",
    value: "51102",
    type: "common",
  },
  {
    label: "VILLERS-FRANQUEUX",
    value: "51633",
    type: "common",
  },
  {
    label: "LOIVRE",
    value: "51329",
    type: "common",
  },
  {
    label: "GUYENCOURT",
    value: "02364",
    type: "common",
  },
  {
    label: "BOUVANCOURT",
    value: "51077",
    type: "common",
  },
  {
    label: "BOUFFIGNEREUX",
    value: "02104",
    type: "common",
  },
  {
    label: "ROUCY",
    value: "02656",
    type: "common",
  },
  {
    label: "MAIZY",
    value: "02453",
    type: "common",
  },
  {
    label: "CONCEVREUX",
    value: "02208",
    type: "common",
  },
  {
    label: "ROMAIN",
    value: "51464",
    type: "common",
  },
  {
    label: "VENTELAY",
    value: "51604",
    type: "common",
  },
  {
    label: "BASLIEUX-LES-FISMES",
    value: "51037",
    type: "common",
  },
  {
    label: "MUSCOURT",
    value: "02534",
    type: "common",
  },
  {
    label: "MEURIVAL",
    value: "02482",
    type: "common",
  },
  {
    label: "LES SEPTVALLONS",
    value: "02439",
    type: "common",
  },
  {
    label: "BLANZY-LES-FISMES",
    value: "02091",
    type: "common",
  },
  {
    label: "VIEL-ARCY",
    value: "02797",
    type: "common",
  },
  {
    label: "SERVAL",
    value: "02715",
    type: "common",
  },
  {
    label: "DHUIZEL",
    value: "02263",
    type: "common",
  },
  {
    label: "COURCELLES-SUR-VESLE",
    value: "02224",
    type: "common",
  },
  {
    label: "BRENELLE",
    value: "02120",
    type: "common",
  },
  {
    label: "VAUXTIN",
    value: "02773",
    type: "common",
  },
  {
    label: "PAARS",
    value: "02581",
    type: "common",
  },
  {
    label: "COUVRELLES",
    value: "02230",
    type: "common",
  },
  {
    label: "CIRY-SALSOGNE",
    value: "02195",
    type: "common",
  },
  {
    label: "CHASSEMY",
    value: "02167",
    type: "common",
  },
  {
    label: "BRAINE",
    value: "02110",
    type: "common",
  },
  {
    label: "AUGY",
    value: "02036",
    type: "common",
  },
  {
    label: "VASSENY",
    value: "02763",
    type: "common",
  },
  {
    label: "SERMOISE",
    value: "02714",
    type: "common",
  },
  {
    label: "SERCHES",
    value: "02711",
    type: "common",
  },
  {
    label: "BILLY-SUR-AISNE",
    value: "02089",
    type: "common",
  },
  {
    label: "ACY",
    value: "02003",
    type: "common",
  },
  {
    label: "VILLENEUVE-SAINT-GERMAIN",
    value: "02805",
    type: "common",
  },
  {
    label: "VENIZEL",
    value: "02780",
    type: "common",
  },
  {
    label: "SEPTMONTS",
    value: "02706",
    type: "common",
  },
  {
    label: "COURMELLES",
    value: "02226",
    type: "common",
  },
  {
    label: "BELLEU",
    value: "02064",
    type: "common",
  },
  {
    label: "VAUXBUIN",
    value: "02770",
    type: "common",
  },
  {
    label: "SOISSONS",
    value: "02722",
    type: "common",
  },
  {
    label: "PLOISY",
    value: "02607",
    type: "common",
  },
  {
    label: "NOYANT-ET-ACONIN",
    value: "02564",
    type: "common",
  },
  {
    label: "MERCIN-ET-VAUX",
    value: "02477",
    type: "common",
  },
  {
    label: "CUTRY",
    value: "02254",
    type: "common",
  },
  {
    label: "AMBLENY",
    value: "02011",
    type: "common",
  },
  {
    label: "SACONIN-ET-BREUIL",
    value: "02667",
    type: "common",
  },
  {
    label: "PERNANT",
    value: "02598",
    type: "common",
  },
  {
    label: "MISSY-AUX-BOIS",
    value: "02485",
    type: "common",
  },
  {
    label: "LAVERSINE",
    value: "02415",
    type: "common",
  },
  {
    label: "COURTIEUX",
    value: "60171",
    type: "common",
  },
  {
    label: "SAINT-BANDRY",
    value: "02672",
    type: "common",
  },
  {
    label: "RESSONS-LE-LONG",
    value: "02643",
    type: "common",
  },
  {
    label: "MORTEFONTAINE",
    value: "02528",
    type: "common",
  },
  {
    label: "MONTIGNY-LENGRAIN",
    value: "02514",
    type: "common",
  },
  {
    label: "SAINT-ETIENNE-ROILAYE",
    value: "60572",
    type: "common",
  },
  {
    label: "HAUTEFONTAINE",
    value: "60305",
    type: "common",
  },
  {
    label: "CUISE-LA-MOTTE",
    value: "60188",
    type: "common",
  },
  {
    label: "CROUTOY",
    value: "60184",
    type: "common",
  },
  {
    label: "CHELLES",
    value: "60145",
    type: "common",
  },
  {
    label: "VIEUX-MOULIN",
    value: "60674",
    type: "common",
  },
  {
    label: "PIERREFONDS",
    value: "60491",
    type: "common",
  },
  {
    label: "SAINT-JEAN-AUX-BOIS",
    value: "60579",
    type: "common",
  },
  {
    label: "RIVECOURT",
    value: "60540",
    type: "common",
  },
  {
    label: "LE MEUX",
    value: "60402",
    type: "common",
  },
  {
    label: "LACROIX-SAINT-OUEN",
    value: "60338",
    type: "common",
  },
  {
    label: "ARMANCOURT",
    value: "60023",
    type: "common",
  },
  {
    label: "LONGUEIL-SAINTE-MARIE",
    value: "60369",
    type: "common",
  },
  {
    label: "HOUDANCOURT",
    value: "60318",
    type: "common",
  },
  {
    label: "GRANDFRESNOY",
    value: "60284",
    type: "common",
  },
  {
    label: "LE FAYEL",
    value: "60229",
    type: "common",
  },
  {
    label: "CHEVRIERES",
    value: "60149",
    type: "common",
  },
  {
    label: "CANLY",
    value: "60125",
    type: "common",
  },
  {
    label: "SAINT-MARTIN-LONGUEAU",
    value: "60587",
    type: "common",
  },
  {
    label: "SACY-LE-PETIT",
    value: "60563",
    type: "common",
  },
  {
    label: "SACY-LE-GRAND",
    value: "60562",
    type: "common",
  },
  {
    label: "CHOISY-LA-VICTOIRE",
    value: "60152",
    type: "common",
  },
  {
    label: "BLINCOURT",
    value: "60078",
    type: "common",
  },
  {
    label: "BAZICOURT",
    value: "60050",
    type: "common",
  },
  {
    label: "AVRIGNY",
    value: "60036",
    type: "common",
  },
  {
    label: "ROSOY",
    value: "60547",
    type: "common",
  },
  {
    label: "NOINTEL",
    value: "60464",
    type: "common",
  },
  {
    label: "LABRUYERE",
    value: "60332",
    type: "common",
  },
  {
    label: "CATENOY",
    value: "60130",
    type: "common",
  },
  {
    label: "BAILLEVAL",
    value: "60042",
    type: "common",
  },
  {
    label: "RANTIGNY",
    value: "60524",
    type: "common",
  },
  {
    label: "NEUILLY-SOUS-CLERMONT",
    value: "60451",
    type: "common",
  },
  {
    label: "CLERMONT",
    value: "60157",
    type: "common",
  },
  {
    label: "BREUIL-LE-VERT",
    value: "60107",
    type: "common",
  },
  {
    label: "BREUIL-LE-SEC",
    value: "60106",
    type: "common",
  },
  {
    label: "ANSACQ",
    value: "60016",
    type: "common",
  },
  {
    label: "THURY-SOUS-CLERMONT",
    value: "60638",
    type: "common",
  },
  {
    label: "SAINT-FELIX",
    value: "60574",
    type: "common",
  },
  {
    label: "LA NEUVILLE-EN-HEZ",
    value: "60454",
    type: "common",
  },
  {
    label: "HONDAINVILLE",
    value: "60317",
    type: "common",
  },
  {
    label: "ANGY",
    value: "60015",
    type: "common",
  },
  {
    label: "VILLERS-SAINT-SEPULCRE",
    value: "60685",
    type: "common",
  },
  {
    label: "PONCHON",
    value: "60504",
    type: "common",
  },
  {
    label: "MONTREUIL-SUR-THERAIN",
    value: "60426",
    type: "common",
  },
  {
    label: "HERMES",
    value: "60313",
    type: "common",
  },
  {
    label: "HEILLES",
    value: "60307",
    type: "common",
  },
  {
    label: "BERTHECOURT",
    value: "60065",
    type: "common",
  },
  {
    label: "BAILLEUL-SUR-THERAIN",
    value: "60041",
    type: "common",
  },
  {
    label: "WARLUIS",
    value: "60700",
    type: "common",
  },
  {
    label: "SAINT-SULPICE",
    value: "60598",
    type: "common",
  },
  {
    label: "HODENC-L'EVEQUE",
    value: "60316",
    type: "common",
  },
  {
    label: "AUTEUIL",
    value: "60030",
    type: "common",
  },
  {
    label: "ABBECOURT",
    value: "60002",
    type: "common",
  },
  {
    label: "SAINT-LEGER-EN-BRAY",
    value: "60583",
    type: "common",
  },
  {
    label: "FROCOURT",
    value: "60264",
    type: "common",
  },
  {
    label: "BERNEUIL-EN-BRAY",
    value: "60063",
    type: "common",
  },
  {
    label: "LE VAUROUX",
    value: "60662",
    type: "common",
  },
  {
    label: "PORCHEUX",
    value: "60510",
    type: "common",
  },
  {
    label: "LA HOUSSOYE",
    value: "60319",
    type: "common",
  },
  {
    label: "AUNEUIL",
    value: "60029",
    type: "common",
  },
  {
    label: "LE VAUMAIN",
    value: "60660",
    type: "common",
  },
  {
    label: "LABOSSE",
    value: "60331",
    type: "common",
  },
  {
    label: "FLAVACOURT",
    value: "60235",
    type: "common",
  },
  {
    label: "SERIFONTAINE",
    value: "60616",
    type: "common",
  },
  {
    label: "SANCOURT",
    value: "27614",
    type: "common",
  },
  {
    label: "MESNIL-SOUS-VIENNE",
    value: "27405",
    type: "common",
  },
  {
    label: "MAINNEVILLE",
    value: "27379",
    type: "common",
  },
  {
    label: "HEUDICOURT",
    value: "27333",
    type: "common",
  },
  {
    label: "HEBECOURT",
    value: "27324",
    type: "common",
  },
  {
    label: "AMECOURT",
    value: "27010",
    type: "common",
  },
  {
    label: "LA NEUVE-GRANGE",
    value: "27430",
    type: "common",
  },
  {
    label: "MORGNY",
    value: "27417",
    type: "common",
  },
  {
    label: "LONGCHAMPS",
    value: "27372",
    type: "common",
  },
  {
    label: "PUCHAY",
    value: "27480",
    type: "common",
  },
  {
    label: "LYONS-LA-FORET",
    value: "27377",
    type: "common",
  },
  {
    label: "LISORS",
    value: "27370",
    type: "common",
  },
  {
    label: "COUDRAY",
    value: "27176",
    type: "common",
  },
  {
    label: "TOUFFREVILLE",
    value: "27649",
    type: "common",
  },
  {
    label: "ROSAY-SUR-LIEURE",
    value: "27496",
    type: "common",
  },
  {
    label: "MENESQUEVILLE",
    value: "27396",
    type: "common",
  },
  {
    label: "CHARLEVAL",
    value: "27151",
    type: "common",
  },
  {
    label: "VANDRIMARE",
    value: "27670",
    type: "common",
  },
  {
    label: "RADEPONT",
    value: "27487",
    type: "common",
  },
  {
    label: "PONT-SAINT-PIERRE",
    value: "27470",
    type: "common",
  },
  {
    label: "VAL D'ORGER",
    value: "27294",
    type: "common",
  },
  {
    label: "FLEURY-SUR-ANDELLE",
    value: "27246",
    type: "common",
  },
  {
    label: "DOUVILLE-SUR-ANDELLE",
    value: "27205",
    type: "common",
  },
  {
    label: "BOURG-BEAUDOUIN",
    value: "27104",
    type: "common",
  },
  {
    label: "QUEVREVILLE-LA-POTERIE",
    value: "76514",
    type: "common",
  },
  {
    label: "LA NEUVILLE-CHANT-D'OISEL",
    value: "76464",
    type: "common",
  },
  {
    label: "BOOS",
    value: "76116",
    type: "common",
  },
  {
    label: "ROMILLY-SUR-ANDELLE",
    value: "27493",
    type: "common",
  },
  {
    label: "PITRES",
    value: "27458",
    type: "common",
  },
  {
    label: "YMARE",
    value: "76753",
    type: "common",
  },
  {
    label: "SAINT-AUBIN-CELLOVILLE",
    value: "76558",
    type: "common",
  },
  {
    label: "GOUY",
    value: "76313",
    type: "common",
  },
  {
    label: "BELBEUF",
    value: "76069",
    type: "common",
  },
  {
    label: "LES AUTHIEUX-SUR-LE-PORT-SAINT-OUEN",
    value: "76039",
    type: "common",
  },
  {
    label: "IGOVILLE",
    value: "27348",
    type: "common",
  },
  {
    label: "TOURVILLE-LA-RIVIERE",
    value: "76705",
    type: "common",
  },
  {
    label: "OISSEL",
    value: "76484",
    type: "common",
  },
  {
    label: "SAHURS",
    value: "76550",
    type: "common",
  },
  {
    label: "MOULINEAUX",
    value: "76457",
    type: "common",
  },
  {
    label: "HAUTOT-SUR-SEINE",
    value: "76350",
    type: "common",
  },
  {
    label: "GRAND-COURONNE",
    value: "76319",
    type: "common",
  },
  {
    label: "LA BOUILLE",
    value: "76131",
    type: "common",
  },
  {
    label: "LA TRINITE-DE-THOUBERVILLE",
    value: "27661",
    type: "common",
  },
  {
    label: "SAINT-OUEN-DE-THOUBERVILLE",
    value: "27580",
    type: "common",
  },
  {
    label: "CAUMONT",
    value: "27133",
    type: "common",
  },
  {
    label: "BOSGOUET",
    value: "27091",
    type: "common",
  },
  {
    label: "HONGUEMARE-GUENOUVILLE",
    value: "27340",
    type: "common",
  },
  {
    label: "BOURG-ACHARD",
    value: "27103",
    type: "common",
  },
  {
    label: "BOUQUETOT",
    value: "27102",
    type: "common",
  },
  {
    label: "ROUGEMONTIERS",
    value: "27497",
    type: "common",
  },
  {
    label: "ILLEVILLE-SUR-MONTFORT",
    value: "27349",
    type: "common",
  },
  {
    label: "ETURQUERAYE",
    value: "27228",
    type: "common",
  },
  {
    label: "CAUVERVILLE-EN-ROUMOIS",
    value: "27134",
    type: "common",
  },
  {
    label: "BRESTOT",
    value: "27110",
    type: "common",
  },
  {
    label: "VALLETOT",
    value: "27669",
    type: "common",
  },
  {
    label: "CORNEVILLE-SUR-RISLE",
    value: "27174",
    type: "common",
  },
  {
    label: "COLLETOT",
    value: "27163",
    type: "common",
  },
  {
    label: "TOURVILLE-SUR-PONT-AUDEMER",
    value: "27655",
    type: "common",
  },
  {
    label: "LES PREAUX",
    value: "27476",
    type: "common",
  },
  {
    label: "PONT-AUDEMER",
    value: "27467",
    type: "common",
  },
  {
    label: "MANNEVILLE-SUR-RISLE",
    value: "27385",
    type: "common",
  },
  {
    label: "TRIQUEVILLE",
    value: "27662",
    type: "common",
  },
  {
    label: "TOUTAINVILLE",
    value: "27656",
    type: "common",
  },
  {
    label: "LE TORPT",
    value: "27646",
    type: "common",
  },
  {
    label: "SAINT-MACLOU",
    value: "27561",
    type: "common",
  },
  {
    label: "FORT-MOVILLE",
    value: "27258",
    type: "common",
  },
  {
    label: "BOULLEVILLE",
    value: "27100",
    type: "common",
  },
  {
    label: "MANNEVILLE-LA-RAOULT",
    value: "27384",
    type: "common",
  },
  {
    label: "BEUZEVILLE",
    value: "27065",
    type: "common",
  },
  {
    label: "QUETTEVILLE",
    value: "14528",
    type: "common",
  },
  {
    label: "LE THEIL-EN-AUGE",
    value: "14687",
    type: "common",
  },
  {
    label: "SAINT-BENOIT-D'HEBERTOT",
    value: "14563",
    type: "common",
  },
  {
    label: "GENNEVILLE",
    value: "14299",
    type: "common",
  },
  {
    label: "FOURNEVILLE",
    value: "14286",
    type: "common",
  },
  {
    label: "TOURVILLE-EN-AUGE",
    value: "14706",
    type: "common",
  },
  {
    label: "SAINT-GATIEN-DES-BOIS",
    value: "14578",
    type: "common",
  },
  {
    label: "ENGLESQUEVILLE-EN-AUGE",
    value: "14238",
    type: "common",
  },
  {
    label: "CANAPVILLE",
    value: "14131",
    type: "common",
  },
  {
    label: "BONNEVILLE-SUR-TOUQUES",
    value: "14086",
    type: "common",
  },
  {
    label: "VAUVILLE",
    value: "14731",
    type: "common",
  },
  {
    label: "TOURGEVILLE",
    value: "14701",
    type: "common",
  },
  {
    label: "TOUQUES",
    value: "14699",
    type: "common",
  },
  {
    label: "SAINT-ARNOULT",
    value: "14557",
    type: "common",
  },
  {
    label: "DEAUVILLE",
    value: "14220",
    type: "common",
  },
  {
    label: "BLONVILLE-SUR-MER",
    value: "14079",
    type: "common",
  },
  {
    label: "BENERVILLE-SUR-MER",
    value: "14059",
    type: "common",
  },
  {
    label: "VILLERS-SUR-MER",
    value: "14754",
    type: "common",
  },
  {
    label: "AUBERVILLE",
    value: "14024",
    type: "common",
  },
  {
    label: "LION-SUR-MER",
    value: "14365",
    type: "common",
  },
  {
    label: "SAINT-AUBIN-SUR-MER",
    value: "14562",
    type: "common",
  },
  {
    label: "LUC-SUR-MER",
    value: "14384",
    type: "common",
  },
  {
    label: "LANGRUNE-SUR-MER",
    value: "14354",
    type: "common",
  },
  {
    label: "BERNIERES-SUR-MER",
    value: "14066",
    type: "common",
  },
  {
    label: "SAINTE-CROIX-SUR-MER",
    value: "14569",
    type: "common",
  },
  {
    label: "REVIERS",
    value: "14535",
    type: "common",
  },
  {
    label: "GRAYE-SUR-MER",
    value: "14318",
    type: "common",
  },
  {
    label: "COURSEULLES-SUR-MER",
    value: "14191",
    type: "common",
  },
  {
    label: "COLOMBIERS-SUR-SEULLES",
    value: "14169",
    type: "common",
  },
  {
    label: "BANVILLE",
    value: "14038",
    type: "common",
  },
  {
    label: "VER-SUR-MER",
    value: "14739",
    type: "common",
  },
  {
    label: "SAINT-COME-DE-FRESNE",
    value: "14565",
    type: "common",
  },
  {
    label: "MEUVAINES",
    value: "14430",
    type: "common",
  },
  {
    label: "CREPON",
    value: "14196",
    type: "common",
  },
  {
    label: "BAZENVILLE",
    value: "14049",
    type: "common",
  },
  {
    label: "ASNELLES",
    value: "14022",
    type: "common",
  },
  {
    label: "TRACY-SUR-MER",
    value: "14709",
    type: "common",
  },
  {
    label: "SOMMERVIEU",
    value: "14676",
    type: "common",
  },
  {
    label: "RYES",
    value: "14552",
    type: "common",
  },
  {
    label: "MANVIEUX",
    value: "14401",
    type: "common",
  },
  {
    label: "MAGNY-EN-BESSIN",
    value: "14385",
    type: "common",
  },
  {
    label: "LONGUES-SUR-MER",
    value: "14377",
    type: "common",
  },
  {
    label: "ARROMANCHES-LES-BAINS",
    value: "14021",
    type: "common",
  },
  {
    label: "VAUX-SUR-AURE",
    value: "14732",
    type: "common",
  },
  {
    label: "TOUR-EN-BESSIN",
    value: "14700",
    type: "common",
  },
  {
    label: "SULLY",
    value: "14680",
    type: "common",
  },
  {
    label: "PORT-EN-BESSIN-HUPPAIN",
    value: "14515",
    type: "common",
  },
  {
    label: "MAISONS",
    value: "14391",
    type: "common",
  },
  {
    label: "COMMES",
    value: "14172",
    type: "common",
  },
  {
    label: "SURRAIN",
    value: "14681",
    type: "common",
  },
  {
    label: "AURE SUR MER",
    value: "14591",
    type: "common",
  },
  {
    label: "MOSLES",
    value: "14453",
    type: "common",
  },
  {
    label: "MANDEVILLE-EN-BESSIN",
    value: "14397",
    type: "common",
  },
  {
    label: "ETREHAM",
    value: "14256",
    type: "common",
  },
  {
    label: "TREVIERES",
    value: "14711",
    type: "common",
  },
  {
    label: "LONGUEVILLE",
    value: "14378",
    type: "common",
  },
  {
    label: "FORMIGNY LA BATAILLE",
    value: "14281",
    type: "common",
  },
  {
    label: "BRICQUEVILLE",
    value: "14107",
    type: "common",
  },
  {
    label: "SAINT-GERMAIN-DU-PERT",
    value: "14586",
    type: "common",
  },
  {
    label: "MONFREVILLE",
    value: "14439",
    type: "common",
  },
  {
    label: "COLOMBIERES",
    value: "14168",
    type: "common",
  },
  {
    label: "CANCHY",
    value: "14132",
    type: "common",
  },
  {
    label: "LA CAMBE",
    value: "14124",
    type: "common",
  },
  {
    label: "OSMANVILLE",
    value: "14480",
    type: "common",
  },
  {
    label: "ISIGNY-SUR-MER",
    value: "14342",
    type: "common",
  },
  {
    label: "MEAUTIS",
    value: "50298",
    type: "common",
  },
  {
    label: "CARENTAN-LES-MARAIS",
    value: "50099",
    type: "common",
  },
  {
    label: "AUVERS",
    value: "50023",
    type: "common",
  },
  {
    label: "BAUPTE",
    value: "50036",
    type: "common",
  },
  {
    label: "APPEVILLE",
    value: "50016",
    type: "common",
  },
  {
    label: "LE PLESSIS-LASTELLE",
    value: "50405",
    type: "common",
  },
  {
    label: "MONTSENELLE",
    value: "50273",
    type: "common",
  },
  {
    label: "SAINT-NICOLAS-DE-PIERREPONT",
    value: "50528",
    type: "common",
  },
  {
    label: "NEUFMESNIL",
    value: "50372",
    type: "common",
  },
  {
    label: "LA HAYE",
    value: "50236",
    type: "common",
  },
  {
    label: "ROLBING",
    value: "57590",
    type: "common",
  },
  {
    label: "SPICHEREN",
    value: "57659",
    type: "common",
  },
  {
    label: "STIRING-WENDEL",
    value: "57660",
    type: "common",
  },
  {
    label: "SCHŒNECK",
    value: "57638",
    type: "common",
  },
  {
    label: "PETITE-ROSSELLE",
    value: "57537",
    type: "common",
  },
  {
    label: "FORBACH",
    value: "57227",
    type: "common",
  },
  {
    label: "TROMBORN",
    value: "57681",
    type: "common",
  },
  {
    label: "MERTEN",
    value: "57460",
    type: "common",
  },
  {
    label: "HARGARTEN-AUX-MINES",
    value: "57296",
    type: "common",
  },
  {
    label: "GUERTING",
    value: "57274",
    type: "common",
  },
  {
    label: "FALCK",
    value: "57205",
    type: "common",
  },
  {
    label: "DALEM",
    value: "57165",
    type: "common",
  },
  {
    label: "CREUTZWALD",
    value: "57160",
    type: "common",
  },
  {
    label: "VELVING",
    value: "57705",
    type: "common",
  },
  {
    label: "VALMUNSTER",
    value: "57691",
    type: "common",
  },
  {
    label: "TETERCHEN",
    value: "57667",
    type: "common",
  },
  {
    label: "OTTONVILLE",
    value: "57530",
    type: "common",
  },
  {
    label: "COUME",
    value: "57154",
    type: "common",
  },
  {
    label: "ROUPELDANGE",
    value: "57599",
    type: "common",
  },
  {
    label: "PIBLANGE",
    value: "57542",
    type: "common",
  },
  {
    label: "MEGANGE",
    value: "57455",
    type: "common",
  },
  {
    label: "HOLLING",
    value: "57329",
    type: "common",
  },
  {
    label: "GUINKIRCHEN",
    value: "57277",
    type: "common",
  },
  {
    label: "GOMELANGE",
    value: "57252",
    type: "common",
  },
  {
    label: "EBLANGE",
    value: "57187",
    type: "common",
  },
  {
    label: "BETTANGE",
    value: "57070",
    type: "common",
  },
  {
    label: "SAINT-HUBERT",
    value: "57612",
    type: "common",
  },
  {
    label: "BURTONCOURT",
    value: "57121",
    type: "common",
  },
  {
    label: "ABONCOURT",
    value: "57001",
    type: "common",
  },
  {
    label: "VIGY",
    value: "57716",
    type: "common",
  },
  {
    label: "TREMERY",
    value: "57677",
    type: "common",
  },
  {
    label: "FLEVY",
    value: "57219",
    type: "common",
  },
  {
    label: "CHAILLY-LES-ENNERY",
    value: "57125",
    type: "common",
  },
  {
    label: "BETTELAINVILLE",
    value: "57072",
    type: "common",
  },
  {
    label: "TALANGE",
    value: "57663",
    type: "common",
  },
  {
    label: "MONDELANGE",
    value: "57474",
    type: "common",
  },
  {
    label: "HAUCONCOURT",
    value: "57303",
    type: "common",
  },
  {
    label: "HAGONDANGE",
    value: "57283",
    type: "common",
  },
  {
    label: "ENNERY",
    value: "57193",
    type: "common",
  },
  {
    label: "AY-SUR-MOSELLE",
    value: "57043",
    type: "common",
  },
  {
    label: "ROSSELANGE",
    value: "57597",
    type: "common",
  },
  {
    label: "ROMBAS",
    value: "57591",
    type: "common",
  },
  {
    label: "PIERREVILLERS",
    value: "57543",
    type: "common",
  },
  {
    label: "CLOUANGE",
    value: "57143",
    type: "common",
  },
  {
    label: "AMNEVILLE",
    value: "57019",
    type: "common",
  },
  {
    label: "MOYEUVRE-GRANDE",
    value: "57491",
    type: "common",
  },
  {
    label: "MONTOIS-LA-MONTAGNE",
    value: "57481",
    type: "common",
  },
  {
    label: "JŒUF",
    value: "54280",
    type: "common",
  },
  {
    label: "HOMECOURT",
    value: "54263",
    type: "common",
  },
  {
    label: "VAL DE BRIEY",
    value: "54099",
    type: "common",
  },
  {
    label: "MOUTIERS",
    value: "54391",
    type: "common",
  },
  {
    label: "LANTEFONTAINE",
    value: "54302",
    type: "common",
  },
  {
    label: "LES BAROCHES",
    value: "54048",
    type: "common",
  },
  {
    label: "OZERAILLES",
    value: "54413",
    type: "common",
  },
  {
    label: "NORROY-LE-SEC",
    value: "54402",
    type: "common",
  },
  {
    label: "LUBEY",
    value: "54326",
    type: "common",
  },
  {
    label: "FLEVILLE-LIXIERES",
    value: "54198",
    type: "common",
  },
  {
    label: "ANOUX",
    value: "54018",
    type: "common",
  },
  {
    label: "DOMMARY-BARONCOURT",
    value: "55158",
    type: "common",
  },
  {
    label: "GONDRECOURT-AIX",
    value: "54231",
    type: "common",
  },
  {
    label: "AFFLEVILLE",
    value: "54004",
    type: "common",
  },
  {
    label: "SENON",
    value: "55481",
    type: "common",
  },
  {
    label: "FOAMEIX-ORNEL",
    value: "55191",
    type: "common",
  },
  {
    label: "ETON",
    value: "55182",
    type: "common",
  },
  {
    label: "AMEL-SUR-L'ETANG",
    value: "55008",
    type: "common",
  },
  {
    label: "MORGEMOULIN",
    value: "55357",
    type: "common",
  },
  {
    label: "MOGEVILLE",
    value: "55339",
    type: "common",
  },
  {
    label: "MAUCOURT-SUR-ORNE",
    value: "55325",
    type: "common",
  },
  {
    label: "GREMILLY",
    value: "55218",
    type: "common",
  },
  {
    label: "GINCREY",
    value: "55211",
    type: "common",
  },
  {
    label: "VILLE-DEVANT-CHAUMONT",
    value: "55556",
    type: "common",
  },
  {
    label: "ORNES",
    value: "55394",
    type: "common",
  },
  {
    label: "AZANNES-ET-SOUMAZANNES",
    value: "55024",
    type: "common",
  },
  {
    label: "SAMOGNEUX",
    value: "55468",
    type: "common",
  },
  {
    label: "LOUVEMONT-COTE-DU-POIVRE",
    value: "55307",
    type: "common",
  },
  {
    label: "HAUMONT-PRES-SAMOGNEUX",
    value: "55239",
    type: "common",
  },
  {
    label: "BEAUMONT-EN-VERDUNOIS",
    value: "55039",
    type: "common",
  },
  {
    label: "REGNEVILLE-SUR-MEUSE",
    value: "55422",
    type: "common",
  },
  {
    label: "FORGES-SUR-MEUSE",
    value: "55193",
    type: "common",
  },
  {
    label: "CHAMPNEUVILLE",
    value: "55099",
    type: "common",
  },
  {
    label: "BRABANT-SUR-MEUSE",
    value: "55070",
    type: "common",
  },
  {
    label: "SEPTSARGES",
    value: "55484",
    type: "common",
  },
  {
    label: "MALANCOURT",
    value: "55313",
    type: "common",
  },
  {
    label: "GERCOURT-ET-DRILLANCOURT",
    value: "55206",
    type: "common",
  },
  {
    label: "CUISY",
    value: "55137",
    type: "common",
  },
  {
    label: "BETHINCOURT",
    value: "55048",
    type: "common",
  },
  {
    label: "NANTILLOIS",
    value: "55375",
    type: "common",
  },
  {
    label: "MONTFAUCON-D'ARGONNE",
    value: "55346",
    type: "common",
  },
  {
    label: "EPINONVILLE",
    value: "55174",
    type: "common",
  },
  {
    label: "APREMONT",
    value: "08017",
    type: "common",
  },
  {
    label: "EXERMONT",
    value: "08161",
    type: "common",
  },
  {
    label: "CHARPENTRY",
    value: "55103",
    type: "common",
  },
  {
    label: "BAULNY",
    value: "55033",
    type: "common",
  },
  {
    label: "CORNAY",
    value: "08131",
    type: "common",
  },
  {
    label: "LANCON",
    value: "08245",
    type: "common",
  },
  {
    label: "CHATEL-CHEHERY",
    value: "08109",
    type: "common",
  },
  {
    label: "GRANDHAM",
    value: "08197",
    type: "common",
  },
  {
    label: "CONDE-LES-AUTRY",
    value: "08128",
    type: "common",
  },
  {
    label: "MONTCHEUTIN",
    value: "08296",
    type: "common",
  },
  {
    label: "AUTRY",
    value: "08036",
    type: "common",
  },
  {
    label: "VAUX-LES-MOURON",
    value: "08464",
    type: "common",
  },
  {
    label: "MONTHOIS",
    value: "08303",
    type: "common",
  },
  {
    label: "SECHAULT",
    value: "08407",
    type: "common",
  },
  {
    label: "BOUCONVILLE",
    value: "08074",
    type: "common",
  },
  {
    label: "ARDEUIL-ET-MONTFAUXELLES",
    value: "08018",
    type: "common",
  },
  {
    label: "LIRY",
    value: "08256",
    type: "common",
  },
  {
    label: "MARVAUX-VIEUX",
    value: "08280",
    type: "common",
  },
  {
    label: "AURE",
    value: "08031",
    type: "common",
  },
  {
    label: "SOMMEPY-TAHURE",
    value: "51544",
    type: "common",
  },
  {
    label: "SAINT-ETIENNE-A-ARNES",
    value: "08379",
    type: "common",
  },
  {
    label: "SAINT-PIERRE-A-ARNES",
    value: "08393",
    type: "common",
  },
  {
    label: "SAINT-HILAIRE-LE-PETIT",
    value: "51487",
    type: "common",
  },
  {
    label: "BETHENIVILLE",
    value: "51054",
    type: "common",
  },
  {
    label: "SAINT-CLEMENT-A-ARNES",
    value: "08378",
    type: "common",
  },
  {
    label: "PONTFAVERGER-MORONVILLIERS",
    value: "51440",
    type: "common",
  },
  {
    label: "SELLES",
    value: "51529",
    type: "common",
  },
  {
    label: "LAVANNES",
    value: "51318",
    type: "common",
  },
  {
    label: "SAINT-MASMES",
    value: "51505",
    type: "common",
  },
  {
    label: "EPOYE",
    value: "51232",
    type: "common",
  },
  {
    label: "WITRY-LES-REIMS",
    value: "51662",
    type: "common",
  },
  {
    label: "CAUREL",
    value: "51101",
    type: "common",
  },
  {
    label: "BERRU",
    value: "51052",
    type: "common",
  },
  {
    label: "COURCY",
    value: "51183",
    type: "common",
  },
  {
    label: "SAINT-BRICE-COURCELLES",
    value: "51474",
    type: "common",
  },
  {
    label: "BETHENY",
    value: "51055",
    type: "common",
  },
  {
    label: "CHENAY",
    value: "51145",
    type: "common",
  },
  {
    label: "CHALONS-SUR-VESLE",
    value: "51109",
    type: "common",
  },
  {
    label: "SAINT-THIERRY",
    value: "51518",
    type: "common",
  },
  {
    label: "MERFY",
    value: "51362",
    type: "common",
  },
  {
    label: "THIL",
    value: "51568",
    type: "common",
  },
  {
    label: "POUILLON",
    value: "51444",
    type: "common",
  },
  {
    label: "CHAMPIGNY",
    value: "51118",
    type: "common",
  },
  {
    label: "JONCHERY-SUR-VESLE",
    value: "51308",
    type: "common",
  },
  {
    label: "TRIGNY",
    value: "51582",
    type: "common",
  },
  {
    label: "PEVY",
    value: "51429",
    type: "common",
  },
  {
    label: "PROUILLY",
    value: "51448",
    type: "common",
  },
  {
    label: "BRANSCOURT",
    value: "51081",
    type: "common",
  },
  {
    label: "MUIZON",
    value: "51391",
    type: "common",
  },
  {
    label: "BREUIL-SUR-VESLE",
    value: "51086",
    type: "common",
  },
  {
    label: "UNCHAIR",
    value: "51586",
    type: "common",
  },
  {
    label: "VANDEUIL",
    value: "51591",
    type: "common",
  },
  {
    label: "MONTIGNY-SUR-VESLE",
    value: "51379",
    type: "common",
  },
  {
    label: "MAGNEUX",
    value: "51337",
    type: "common",
  },
  {
    label: "HOURGES",
    value: "51294",
    type: "common",
  },
  {
    label: "COURLANDON",
    value: "51187",
    type: "common",
  },
  {
    label: "FISMES",
    value: "51250",
    type: "common",
  },
  {
    label: "SAINT-GILLES",
    value: "51484",
    type: "common",
  },
  {
    label: "COURVILLE",
    value: "51194",
    type: "common",
  },
  {
    label: "VILLE-SAVOYE",
    value: "02817",
    type: "common",
  },
  {
    label: "MONT-SAINT-MARTIN",
    value: "02523",
    type: "common",
  },
  {
    label: "LIME",
    value: "02432",
    type: "common",
  },
  {
    label: "LHUYS",
    value: "02427",
    type: "common",
  },
  {
    label: "BAZOCHES-SUR-VESLES",
    value: "02054",
    type: "common",
  },
  {
    label: "TANNIERES",
    value: "02735",
    type: "common",
  },
  {
    label: "SAINT-THIBAUT",
    value: "02695",
    type: "common",
  },
  {
    label: "QUINCY-SOUS-LE-MONT",
    value: "02633",
    type: "common",
  },
  {
    label: "MONT-NOTRE-DAME",
    value: "02520",
    type: "common",
  },
  {
    label: "MAAST-ET-VIOLAINE",
    value: "02447",
    type: "common",
  },
  {
    label: "LESGES",
    value: "02421",
    type: "common",
  },
  {
    label: "JOUAIGNES",
    value: "02393",
    type: "common",
  },
  {
    label: "CUIRY-HOUSSE",
    value: "02249",
    type: "common",
  },
  {
    label: "CERSEUIL",
    value: "02152",
    type: "common",
  },
  {
    label: "HARTENNES-ET-TAUX",
    value: "02372",
    type: "common",
  },
  {
    label: "DROIZY",
    value: "02272",
    type: "common",
  },
  {
    label: "CHACRISE",
    value: "02154",
    type: "common",
  },
  {
    label: "AMBRIEF",
    value: "02012",
    type: "common",
  },
  {
    label: "ROZIERES-SUR-CRISE",
    value: "02663",
    type: "common",
  },
  {
    label: "NAMPTEUIL-SOUS-MURET",
    value: "02536",
    type: "common",
  },
  {
    label: "MURET-ET-CROUTTES",
    value: "02533",
    type: "common",
  },
  {
    label: "BUZANCY",
    value: "02138",
    type: "common",
  },
  {
    label: "BERZY-LE-SEC",
    value: "02077",
    type: "common",
  },
  {
    label: "VILLEMONTOIRE",
    value: "02804",
    type: "common",
  },
  {
    label: "VIERZY",
    value: "02799",
    type: "common",
  },
  {
    label: "PARCY-ET-TIGNY",
    value: "02585",
    type: "common",
  },
  {
    label: "LONGPONT",
    value: "02438",
    type: "common",
  },
  {
    label: "DOMMIERS",
    value: "02267",
    type: "common",
  },
  {
    label: "CHAUDUN",
    value: "02172",
    type: "common",
  },
  {
    label: "SAINT-PIERRE-AIGLE",
    value: "02687",
    type: "common",
  },
  {
    label: "MONTGOBERT",
    value: "02506",
    type: "common",
  },
  {
    label: "CŒUVRES-ET-VALSERY",
    value: "02201",
    type: "common",
  },
  {
    label: "VIVIERES",
    value: "02822",
    type: "common",
  },
  {
    label: "SOUCY",
    value: "02729",
    type: "common",
  },
  {
    label: "PUISEUX-EN-RETZ",
    value: "02628",
    type: "common",
  },
  {
    label: "HARAMONT",
    value: "02368",
    type: "common",
  },
  {
    label: "EMEVILLE",
    value: "60207",
    type: "common",
  },
  {
    label: "TAILLEFONTAINE",
    value: "02734",
    type: "common",
  },
  {
    label: "RETHEUIL",
    value: "02644",
    type: "common",
  },
  {
    label: "MORIENVAL",
    value: "60430",
    type: "common",
  },
  {
    label: "FRESNOY-LA-RIVIERE",
    value: "60260",
    type: "common",
  },
  {
    label: "BONNEUIL-EN-VALOIS",
    value: "60083",
    type: "common",
  },
  {
    label: "ORROUY",
    value: "60481",
    type: "common",
  },
  {
    label: "GLAIGNES",
    value: "60274",
    type: "common",
  },
  {
    label: "GILOCOURT",
    value: "60272",
    type: "common",
  },
  {
    label: "BETHISY-SAINT-MARTIN",
    value: "60067",
    type: "common",
  },
  {
    label: "BETHANCOURT-EN-VALOIS",
    value: "60066",
    type: "common",
  },
  {
    label: "VERBERIE",
    value: "60667",
    type: "common",
  },
  {
    label: "SAINT-VAAST-DE-LONGMONT",
    value: "60600",
    type: "common",
  },
  {
    label: "SAINT-SAUVEUR",
    value: "60597",
    type: "common",
  },
  {
    label: "SAINTINES",
    value: "60578",
    type: "common",
  },
  {
    label: "BETHISY-SAINT-PIERRE",
    value: "60068",
    type: "common",
  },
  {
    label: "ROBERVAL",
    value: "60541",
    type: "common",
  },
  {
    label: "RHUIS",
    value: "60536",
    type: "common",
  },
  {
    label: "PONTPOINT",
    value: "60508",
    type: "common",
  },
  {
    label: "PONT-SAINTE-MAXENCE",
    value: "60509",
    type: "common",
  },
  {
    label: "MONCEAUX",
    value: "60406",
    type: "common",
  },
  {
    label: "BRENOUILLE",
    value: "60102",
    type: "common",
  },
  {
    label: "BEAUREPAIRE",
    value: "60056",
    type: "common",
  },
  {
    label: "LES AGEUX",
    value: "60006",
    type: "common",
  },
  {
    label: "VILLERS-SAINT-PAUL",
    value: "60684",
    type: "common",
  },
  {
    label: "VERNEUIL-EN-HALATTE",
    value: "60670",
    type: "common",
  },
  {
    label: "VERDERONNE",
    value: "60669",
    type: "common",
  },
  {
    label: "RIEUX",
    value: "60539",
    type: "common",
  },
  {
    label: "NOGENT-SUR-OISE",
    value: "60463",
    type: "common",
  },
  {
    label: "MONCHY-SAINT-ELOI",
    value: "60409",
    type: "common",
  },
  {
    label: "MOGNEVILLE",
    value: "60404",
    type: "common",
  },
  {
    label: "LIANCOURT",
    value: "60360",
    type: "common",
  },
  {
    label: "CINQUEUX",
    value: "60154",
    type: "common",
  },
  {
    label: "ANGICOURT",
    value: "60013",
    type: "common",
  },
  {
    label: "SAINT-VAAST-LES-MELLO",
    value: "60601",
    type: "common",
  },
  {
    label: "ROUSSELOY",
    value: "60551",
    type: "common",
  },
  {
    label: "MELLO",
    value: "60393",
    type: "common",
  },
  {
    label: "LAIGNEVILLE",
    value: "60342",
    type: "common",
  },
  {
    label: "CAUFFRY",
    value: "60134",
    type: "common",
  },
  {
    label: "CAMBRONNE-LES-CLERMONT",
    value: "60120",
    type: "common",
  },
  {
    label: "ULLY-SAINT-GEORGES",
    value: "60651",
    type: "common",
  },
  {
    label: "MOUY",
    value: "60439",
    type: "common",
  },
  {
    label: "FOULANGUES",
    value: "60249",
    type: "common",
  },
  {
    label: "BURY",
    value: "60116",
    type: "common",
  },
  {
    label: "BALAGNY-SUR-THERAIN",
    value: "60044",
    type: "common",
  },
  {
    label: "SAINTE-GENEVIEVE",
    value: "60575",
    type: "common",
  },
  {
    label: "NOAILLES",
    value: "60462",
    type: "common",
  },
  {
    label: "MOUCHY-LE-CHATEL",
    value: "60437",
    type: "common",
  },
  {
    label: "CAUVIGNY",
    value: "60135",
    type: "common",
  },
  {
    label: "SILLY-TILLARD",
    value: "60620",
    type: "common",
  },
  {
    label: "LABOISSIERE-EN-THELLE",
    value: "60330",
    type: "common",
  },
  {
    label: "LA DRENNE",
    value: "60196",
    type: "common",
  },
  {
    label: "LE COUDRAY-SUR-THELLE",
    value: "60165",
    type: "common",
  },
  {
    label: "CORBEIL-CERF",
    value: "60162",
    type: "common",
  },
  {
    label: "VALDAMPIERRE",
    value: "60652",
    type: "common",
  },
  {
    label: "LE MESNIL-THERIBUS",
    value: "60401",
    type: "common",
  },
  {
    label: "JOUY-SOUS-THELLE",
    value: "60327",
    type: "common",
  },
  {
    label: "TRIE-LA-VILLE",
    value: "60645",
    type: "common",
  },
  {
    label: "THIBIVILLERS",
    value: "60630",
    type: "common",
  },
  {
    label: "JAMERICOURT",
    value: "60322",
    type: "common",
  },
  {
    label: "ENENCOURT-LEAGE",
    value: "60208",
    type: "common",
  },
  {
    label: "CHAUMONT-EN-VEXIN",
    value: "60143",
    type: "common",
  },
  {
    label: "BOUTENCOURT",
    value: "60097",
    type: "common",
  },
  {
    label: "TRIE-CHATEAU",
    value: "60644",
    type: "common",
  },
  {
    label: "ERAGNY-SUR-EPTE",
    value: "60211",
    type: "common",
  },
  {
    label: "GISORS",
    value: "27284",
    type: "common",
  },
  {
    label: "BAZINCOURT-SUR-EPTE",
    value: "27045",
    type: "common",
  },
  {
    label: "SAINT-DENIS-LE-FERMENT",
    value: "27533",
    type: "common",
  },
  {
    label: "NEAUFLES-SAINT-MARTIN",
    value: "27426",
    type: "common",
  },
  {
    label: "CHAUVINCOURT-PROVEMONT",
    value: "27153",
    type: "common",
  },
  {
    label: "BEZU-SAINT-ELOI",
    value: "27067",
    type: "common",
  },
  {
    label: "BERNOUVILLE",
    value: "27059",
    type: "common",
  },
  {
    label: "LE THIL",
    value: "27632",
    type: "common",
  },
  {
    label: "SAINTE-MARIE-DE-VATIMESNIL",
    value: "27567",
    type: "common",
  },
  {
    label: "NOJEON-EN-VEXIN",
    value: "27437",
    type: "common",
  },
  {
    label: "HACQUEVILLE",
    value: "27310",
    type: "common",
  },
  {
    label: "ETREPAGNY",
    value: "27226",
    type: "common",
  },
  {
    label: "DOUDEAUVILLE-EN-VEXIN",
    value: "27204",
    type: "common",
  },
  {
    label: "SUZAY",
    value: "27625",
    type: "common",
  },
  {
    label: "SAUSSAY-LA-CAMPAGNE",
    value: "27617",
    type: "common",
  },
  {
    label: "MESNIL-VERCLIVES",
    value: "27407",
    type: "common",
  },
  {
    label: "FARCEAUX",
    value: "27232",
    type: "common",
  },
  {
    label: "ECOUIS",
    value: "27214",
    type: "common",
  },
  {
    label: "CUVERVILLE",
    value: "27194",
    type: "common",
  },
  {
    label: "VATTEVILLE",
    value: "27673",
    type: "common",
  },
  {
    label: "HOUVILLE-EN-VEXIN",
    value: "27346",
    type: "common",
  },
  {
    label: "HEUQUEVILLE",
    value: "27337",
    type: "common",
  },
  {
    label: "FLIPOU",
    value: "27247",
    type: "common",
  },
  {
    label: "BACQUEVILLE",
    value: "27034",
    type: "common",
  },
  {
    label: "AMFREVILLE-LES-CHAMPS",
    value: "27012",
    type: "common",
  },
  {
    label: "POSES",
    value: "27474",
    type: "common",
  },
  {
    label: "LE MANOIR",
    value: "27386",
    type: "common",
  },
  {
    label: "AMFREVILLE-SOUS-LES-MONTS",
    value: "27013",
    type: "common",
  },
  {
    label: "SOTTEVILLE-SOUS-LE-VAL",
    value: "76682",
    type: "common",
  },
  {
    label: "PONT-DE-L'ARCHE",
    value: "27469",
    type: "common",
  },
  {
    label: "LERY",
    value: "27365",
    type: "common",
  },
  {
    label: "LES DAMPS",
    value: "27196",
    type: "common",
  },
  {
    label: "ALIZAY",
    value: "27008",
    type: "common",
  },
  {
    label: "SAINT-PIERRE-LES-ELBEUF",
    value: "76640",
    type: "common",
  },
  {
    label: "SAINT-AUBIN-LES-ELBEUF",
    value: "76561",
    type: "common",
  },
  {
    label: "FRENEUSE",
    value: "76282",
    type: "common",
  },
  {
    label: "CLEON",
    value: "76178",
    type: "common",
  },
  {
    label: "CAUDEBEC-LES-ELBEUF",
    value: "76165",
    type: "common",
  },
  {
    label: "MARTOT",
    value: "27394",
    type: "common",
  },
  {
    label: "CRIQUEBEUF-SUR-SEINE",
    value: "27188",
    type: "common",
  },
  {
    label: "ORIVAL",
    value: "76486",
    type: "common",
  },
  {
    label: "ELBEUF",
    value: "76231",
    type: "common",
  },
  {
    label: "SAINT-OUEN-DU-TILLEUL",
    value: "27582",
    type: "common",
  },
  {
    label: "LA LONDE",
    value: "76391",
    type: "common",
  },
  {
    label: "GRAND BOURGTHEROULDE",
    value: "27105",
    type: "common",
  },
  {
    label: "BOSROUMOIS",
    value: "27090",
    type: "common",
  },
  {
    label: "LES MONTS DU ROUMOIS",
    value: "27062",
    type: "common",
  },
  {
    label: "VOISCREVILLE",
    value: "27699",
    type: "common",
  },
  {
    label: "SAINT-LEGER-DU-GENNETEY",
    value: "27558",
    type: "common",
  },
  {
    label: "THENOUVILLE",
    value: "27089",
    type: "common",
  },
  {
    label: "FLANCOURT-CRESCY-EN-ROUMOIS",
    value: "27085",
    type: "common",
  },
  {
    label: "BOISSEY-LE-CHATEL",
    value: "27077",
    type: "common",
  },
  {
    label: "THIERVILLE",
    value: "27631",
    type: "common",
  },
  {
    label: "MONTFORT-SUR-RISLE",
    value: "27413",
    type: "common",
  },
  {
    label: "GLOS-SUR-RISLE",
    value: "27288",
    type: "common",
  },
  {
    label: "ECAQUELON",
    value: "27209",
    type: "common",
  },
  {
    label: "APPEVILLE-ANNEBAULT",
    value: "27018",
    type: "common",
  },
  {
    label: "SAINT-PIERRE-DES-IFS",
    value: "27594",
    type: "common",
  },
  {
    label: "SAINT-PHILBERT-SUR-RISLE",
    value: "27587",
    type: "common",
  },
  {
    label: "SAINT-MARTIN-SAINT-FIRMIN",
    value: "27571",
    type: "common",
  },
  {
    label: "SAINT-CHRISTOPHE-SUR-CONDE",
    value: "27522",
    type: "common",
  },
  {
    label: "CONDE-SUR-RISLE",
    value: "27167",
    type: "common",
  },
  {
    label: "CAMPIGNY",
    value: "27126",
    type: "common",
  },
  {
    label: "SELLES",
    value: "27620",
    type: "common",
  },
  {
    label: "SAINT-SIMEON",
    value: "27603",
    type: "common",
  },
  {
    label: "SAINT-ETIENNE-L'ALLIER",
    value: "27538",
    type: "common",
  },
  {
    label: "LA NOE-POULAIN",
    value: "27435",
    type: "common",
  },
  {
    label: "VANNECROCQ",
    value: "27671",
    type: "common",
  },
  {
    label: "SAINT-SYMPHORIEN",
    value: "27606",
    type: "common",
  },
  {
    label: "MARTAINVILLE",
    value: "27393",
    type: "common",
  },
  {
    label: "EPAIGNES",
    value: "27218",
    type: "common",
  },
  {
    label: "LA CHAPELLE-BAYVEL",
    value: "27146",
    type: "common",
  },
  {
    label: "LE BOIS-HELLAIN",
    value: "27071",
    type: "common",
  },
  {
    label: "LA LANDE-SAINT-LEGER",
    value: "27361",
    type: "common",
  },
  {
    label: "BONNEVILLE-LA-LOUVET",
    value: "14085",
    type: "common",
  },
  {
    label: "VIEUX-BOURG",
    value: "14748",
    type: "common",
  },
  {
    label: "SURVILLE",
    value: "14682",
    type: "common",
  },
  {
    label: "SAINT-JULIEN-SUR-CALONNE",
    value: "14601",
    type: "common",
  },
  {
    label: "SAINT-ANDRE-D'HEBERTOT",
    value: "14555",
    type: "common",
  },
  {
    label: "LE MESNIL-SUR-BLANGY",
    value: "14426",
    type: "common",
  },
  {
    label: "MANNEVILLE-LA-PIPARD",
    value: "14399",
    type: "common",
  },
  {
    label: "LES AUTHIEUX-SUR-CALONNE",
    value: "14032",
    type: "common",
  },
  {
    label: "SAINT-MARTIN-AUX-CHARTRAINS",
    value: "14620",
    type: "common",
  },
  {
    label: "SAINT-ETIENNE-LA-THILLAYE",
    value: "14575",
    type: "common",
  },
  {
    label: "REUX",
    value: "14534",
    type: "common",
  },
  {
    label: "PIERREFITTE-EN-AUGE",
    value: "14500",
    type: "common",
  },
  {
    label: "BEAUMONT-EN-AUGE",
    value: "14055",
    type: "common",
  },
  {
    label: "SAINT-PIERRE-AZIF",
    value: "14645",
    type: "common",
  },
  {
    label: "GLANVILLE",
    value: "14302",
    type: "common",
  },
  {
    label: "BRANVILLE",
    value: "14093",
    type: "common",
  },
  {
    label: "BOURGEAUVILLE",
    value: "14091",
    type: "common",
  },
  {
    label: "SAINT-VAAST-EN-AUGE",
    value: "14660",
    type: "common",
  },
  {
    label: "HOULGATE",
    value: "14338",
    type: "common",
  },
  {
    label: "HEULAND",
    value: "14329",
    type: "common",
  },
  {
    label: "GRANGUES",
    value: "14316",
    type: "common",
  },
  {
    label: "GONNEVILLE-SUR-MER",
    value: "14305",
    type: "common",
  },
  {
    label: "DOUVILLE-EN-AUGE",
    value: "14227",
    type: "common",
  },
  {
    label: "VARAVILLE",
    value: "14724",
    type: "common",
  },
  {
    label: "PERIERS-EN-AUGE",
    value: "14494",
    type: "common",
  },
  {
    label: "DIVES-SUR-MER",
    value: "14225",
    type: "common",
  },
  {
    label: "CABOURG",
    value: "14117",
    type: "common",
  },
  {
    label: "SALLENELLES",
    value: "14665",
    type: "common",
  },
  {
    label: "MERVILLE-FRANCEVILLE-PLAGE",
    value: "14409",
    type: "common",
  },
  {
    label: "GONNEVILLE-EN-AUGE",
    value: "14306",
    type: "common",
  },
  {
    label: "AMFREVILLE",
    value: "14009",
    type: "common",
  },
  {
    label: "SAINT-AUBIN-D'ARQUENAY",
    value: "14558",
    type: "common",
  },
  {
    label: "PERIERS-SUR-LE-DAN",
    value: "14495",
    type: "common",
  },
  {
    label: "OUISTREHAM",
    value: "14488",
    type: "common",
  },
  {
    label: "HERMANVILLE-SUR-MER",
    value: "14325",
    type: "common",
  },
  {
    label: "COLLEVILLE-MONTGOMERY",
    value: "14166",
    type: "common",
  },
  {
    label: "BIEVILLE-BEUVILLE",
    value: "14068",
    type: "common",
  },
  {
    label: "PLUMETOT",
    value: "14509",
    type: "common",
  },
  {
    label: "MATHIEU",
    value: "14407",
    type: "common",
  },
  {
    label: "DOUVRES-LA-DELIVRANDE",
    value: "14228",
    type: "common",
  },
  {
    label: "CRESSERONS",
    value: "14197",
    type: "common",
  },
  {
    label: "BASLY",
    value: "14044",
    type: "common",
  },
  {
    label: "ANISY",
    value: "14015",
    type: "common",
  },
  {
    label: "COLOMBY-ANGUERNY",
    value: "14014",
    type: "common",
  },
  {
    label: "THAON",
    value: "14685",
    type: "common",
  },
  {
    label: "PONTS SUR SEULLES",
    value: "14355",
    type: "common",
  },
  {
    label: "LE FRESNE-CAMILLY",
    value: "14288",
    type: "common",
  },
  {
    label: "FONTAINE-HENRY",
    value: "14275",
    type: "common",
  },
  {
    label: "BENY-SUR-MER",
    value: "14062",
    type: "common",
  },
  {
    label: "MOULINS-EN-BESSIN",
    value: "14406",
    type: "common",
  },
  {
    label: "LE MANOIR",
    value: "14400",
    type: "common",
  },
  {
    label: "CREULLY SUR SEULLES",
    value: "14200",
    type: "common",
  },
  {
    label: "VIENNE-EN-BESSIN",
    value: "14744",
    type: "common",
  },
  {
    label: "VAUX-SUR-SEULLES",
    value: "14733",
    type: "common",
  },
  {
    label: "SAINT-VIGOR-LE-GRAND",
    value: "14663",
    type: "common",
  },
  {
    label: "SAINT-MARTIN-DES-ENTREES",
    value: "14630",
    type: "common",
  },
  {
    label: "NONANT",
    value: "14465",
    type: "common",
  },
  {
    label: "MONCEAUX-EN-BESSIN",
    value: "14436",
    type: "common",
  },
  {
    label: "ESQUAY-SUR-SEULLES",
    value: "14250",
    type: "common",
  },
  {
    label: "CARCAGNY",
    value: "14135",
    type: "common",
  },
  {
    label: "VAUCELLES",
    value: "14728",
    type: "common",
  },
  {
    label: "SUBLES",
    value: "14679",
    type: "common",
  },
  {
    label: "SAINT-LOUP-HORS",
    value: "14609",
    type: "common",
  },
  {
    label: "RANCHY",
    value: "14529",
    type: "common",
  },
  {
    label: "GUERON",
    value: "14322",
    type: "common",
  },
  {
    label: "CUSSY",
    value: "14214",
    type: "common",
  },
  {
    label: "COTTUN",
    value: "14184",
    type: "common",
  },
  {
    label: "BAYEUX",
    value: "14047",
    type: "common",
  },
  {
    label: "BARBEVILLE",
    value: "14040",
    type: "common",
  },
  {
    label: "AGY",
    value: "14003",
    type: "common",
  },
  {
    label: "SAON",
    value: "14667",
    type: "common",
  },
  {
    label: "CROUAY",
    value: "14209",
    type: "common",
  },
  {
    label: "CAMPIGNY",
    value: "14130",
    type: "common",
  },
  {
    label: "LE BREUIL-EN-BESSIN",
    value: "14103",
    type: "common",
  },
  {
    label: "BLAY",
    value: "14078",
    type: "common",
  },
  {
    label: "TOURNIERES",
    value: "14705",
    type: "common",
  },
  {
    label: "SAONNET",
    value: "14668",
    type: "common",
  },
  {
    label: "SAINT-MARTIN-DE-BLAGNY",
    value: "14622",
    type: "common",
  },
  {
    label: "RUBERCY",
    value: "14547",
    type: "common",
  },
  {
    label: "LE MOLAY-LITTRY",
    value: "14370",
    type: "common",
  },
  {
    label: "BERNESQ",
    value: "14063",
    type: "common",
  },
  {
    label: "SAINT-MARCOUF",
    value: "14613",
    type: "common",
  },
  {
    label: "LISON",
    value: "14367",
    type: "common",
  },
  {
    label: "LA FOLIE",
    value: "14272",
    type: "common",
  },
  {
    label: "CARTIGNY-L'EPINAY",
    value: "14138",
    type: "common",
  },
  {
    label: "SAINT-JEAN-DE-DAYE",
    value: "50488",
    type: "common",
  },
  {
    label: "SAINT-FROMOND",
    value: "50468",
    type: "common",
  },
  {
    label: "GRAIGNES-MESNIL-ANGOT",
    value: "50216",
    type: "common",
  },
  {
    label: "SAINT-ANDRE-DE-BOHON",
    value: "50445",
    type: "common",
  },
  {
    label: "TERRE-ET-MARAIS",
    value: "50564",
    type: "common",
  },
  {
    label: "NAY",
    value: "50368",
    type: "common",
  },
  {
    label: "GORGES",
    value: "50210",
    type: "common",
  },
  {
    label: "GONFREVILLE",
    value: "50208",
    type: "common",
  },
  {
    label: "VESLY",
    value: "50629",
    type: "common",
  },
  {
    label: "SAINT-PATRICE-DE-CLAIDS",
    value: "50533",
    type: "common",
  },
  {
    label: "LAULNE",
    value: "50265",
    type: "common",
  },
  {
    label: "LESSAY",
    value: "50267",
    type: "common",
  },
  {
    label: "SAINT-GERMAIN-SUR-AY",
    value: "50481",
    type: "common",
  },
  {
    label: "BRETTEVILLE-SUR-AY",
    value: "50078",
    type: "common",
  },
  {
    label: "LIEDERSCHIEDT",
    value: "57402",
    type: "common",
  },
  {
    label: "WALSCHBRONN",
    value: "57741",
    type: "common",
  },
  {
    label: "WALDHOUSE",
    value: "57738",
    type: "common",
  },
  {
    label: "SCHWEYEN",
    value: "57641",
    type: "common",
  },
  {
    label: "LENGELSHEIM",
    value: "57393",
    type: "common",
  },
  {
    label: "BREIDENBACH",
    value: "57108",
    type: "common",
  },
  {
    label: "BOUSSEVILLER",
    value: "57103",
    type: "common",
  },
  {
    label: "VOLMUNSTER",
    value: "57732",
    type: "common",
  },
  {
    label: "ORMERSVILLER",
    value: "57526",
    type: "common",
  },
  {
    label: "LOUTZVILLER",
    value: "57421",
    type: "common",
  },
  {
    label: "OBERGAILBACH",
    value: "57517",
    type: "common",
  },
  {
    label: "ERCHING",
    value: "57196",
    type: "common",
  },
  {
    label: "FRAUENBERG",
    value: "57234",
    type: "common",
  },
  {
    label: "BLIES-GUERSVILLER",
    value: "57093",
    type: "common",
  },
  {
    label: "ROUHLING",
    value: "57598",
    type: "common",
  },
  {
    label: "LIXING-LES-ROUHLING",
    value: "57408",
    type: "common",
  },
  {
    label: "KERBACH",
    value: "57360",
    type: "common",
  },
  {
    label: "GROSBLIEDERSTROFF",
    value: "57260",
    type: "common",
  },
  {
    label: "ETZLING",
    value: "57202",
    type: "common",
  },
  {
    label: "BOUSBACH",
    value: "57101",
    type: "common",
  },
  {
    label: "ALSTING",
    value: "57013",
    type: "common",
  },
  {
    label: "THEDING",
    value: "57669",
    type: "common",
  },
  {
    label: "ROSBRUCK",
    value: "57596",
    type: "common",
  },
  {
    label: "ŒTING",
    value: "57521",
    type: "common",
  },
  {
    label: "MORSBACH",
    value: "57484",
    type: "common",
  },
  {
    label: "FOLKLING",
    value: "57222",
    type: "common",
  },
  {
    label: "COCHEREN",
    value: "57144",
    type: "common",
  },
  {
    label: "BEHREN-LES-FORBACH",
    value: "57058",
    type: "common",
  },
  {
    label: "FREYMING-MERLEBACH",
    value: "57240",
    type: "common",
  },
  {
    label: "BETTING",
    value: "57073",
    type: "common",
  },
  {
    label: "BENING-LES-SAINT-AVOLD",
    value: "57061",
    type: "common",
  },
  {
    label: "DIESEN",
    value: "57765",
    type: "common",
  },
  {
    label: "L'HOPITAL",
    value: "57336",
    type: "common",
  },
  {
    label: "CARLING",
    value: "57123",
    type: "common",
  },
  {
    label: "VARSBERG",
    value: "57696",
    type: "common",
  },
  {
    label: "PORCELETTE",
    value: "57550",
    type: "common",
  },
  {
    label: "HAM-SOUS-VARSBERG",
    value: "57288",
    type: "common",
  },
  {
    label: "BOUCHEPORN",
    value: "57095",
    type: "common",
  },
  {
    label: "BISTEN-EN-LORRAINE",
    value: "57087",
    type: "common",
  },
  {
    label: "OBERVISSE",
    value: "57519",
    type: "common",
  },
  {
    label: "NIEDERVISSE",
    value: "57507",
    type: "common",
  },
  {
    label: "MOMERSTROFF",
    value: "57471",
    type: "common",
  },
  {
    label: "DENTING",
    value: "57172",
    type: "common",
  },
  {
    label: "BOULAY-MOSELLE",
    value: "57097",
    type: "common",
  },
  {
    label: "VOLMERANGE-LES-BOULAY",
    value: "57730",
    type: "common",
  },
  {
    label: "HINCKANGE",
    value: "57326",
    type: "common",
  },
  {
    label: "HELSTROFF",
    value: "57312",
    type: "common",
  },
  {
    label: "CONDE-NORTHEN",
    value: "57150",
    type: "common",
  },
  {
    label: "CHARLEVILLE-SOUS-BOIS",
    value: "57128",
    type: "common",
  },
  {
    label: "VRY",
    value: "57736",
    type: "common",
  },
  {
    label: "SAINTE-BARBE",
    value: "57607",
    type: "common",
  },
  {
    label: "HAYES",
    value: "57307",
    type: "common",
  },
  {
    label: "LES ETANGS",
    value: "57200",
    type: "common",
  },
  {
    label: "VANY",
    value: "57694",
    type: "common",
  },
  {
    label: "SERVIGNY-LES-SAINTE-BARBE",
    value: "57649",
    type: "common",
  },
  {
    label: "SANRY-LES-VIGY",
    value: "57626",
    type: "common",
  },
  {
    label: "FAILLY",
    value: "57204",
    type: "common",
  },
  {
    label: "CHARLY-ORADOUR",
    value: "57129",
    type: "common",
  },
  {
    label: "ANTILLY",
    value: "57024",
    type: "common",
  },
  {
    label: "WOIPPY",
    value: "57751",
    type: "common",
  },
  {
    label: "LA MAXE",
    value: "57452",
    type: "common",
  },
  {
    label: "MALROY",
    value: "57438",
    type: "common",
  },
  {
    label: "MAIZIERES-LES-METZ",
    value: "57433",
    type: "common",
  },
  {
    label: "CHIEULLES",
    value: "57142",
    type: "common",
  },
  {
    label: "ARGANCY",
    value: "57028",
    type: "common",
  },
  {
    label: "SEMECOURT",
    value: "57645",
    type: "common",
  },
  {
    label: "SAULNY",
    value: "57634",
    type: "common",
  },
  {
    label: "PLESNOIS",
    value: "57546",
    type: "common",
  },
  {
    label: "NORROY-LE-VENEUR",
    value: "57511",
    type: "common",
  },
  {
    label: "MARANGE-SILVANGE",
    value: "57443",
    type: "common",
  },
  {
    label: "FEVES",
    value: "57211",
    type: "common",
  },
  {
    label: "BRONVAUX",
    value: "57111",
    type: "common",
  },
  {
    label: "SAINT-PRIVAT-LA-MONTAGNE",
    value: "57622",
    type: "common",
  },
  {
    label: "SAINTE-MARIE-AUX-CHENES",
    value: "57620",
    type: "common",
  },
  {
    label: "RONCOURT",
    value: "57593",
    type: "common",
  },
  {
    label: "SAINT-AIL",
    value: "54469",
    type: "common",
  },
  {
    label: "BATILLY",
    value: "54051",
    type: "common",
  },
  {
    label: "AUBOUE",
    value: "54028",
    type: "common",
  },
  {
    label: "VALLEROY",
    value: "54542",
    type: "common",
  },
  {
    label: "MOINEVILLE",
    value: "54371",
    type: "common",
  },
  {
    label: "LABRY",
    value: "54286",
    type: "common",
  },
  {
    label: "JOUAVILLE",
    value: "54283",
    type: "common",
  },
  {
    label: "HATRIZE",
    value: "54253",
    type: "common",
  },
  {
    label: "GIRAUMONT",
    value: "54227",
    type: "common",
  },
  {
    label: "THUMEREVILLE",
    value: "54524",
    type: "common",
  },
  {
    label: "MOUAVILLE",
    value: "54389",
    type: "common",
  },
  {
    label: "JEANDELIZE",
    value: "54277",
    type: "common",
  },
  {
    label: "CONFLANS-EN-JARNISY",
    value: "54136",
    type: "common",
  },
  {
    label: "BONCOURT",
    value: "54082",
    type: "common",
  },
  {
    label: "ABBEVILLE-LES-CONFLANS",
    value: "54002",
    type: "common",
  },
  {
    label: "SAINT-JEAN-LES-BUZY",
    value: "55458",
    type: "common",
  },
  {
    label: "ROUVRES-EN-WOEVRE",
    value: "55443",
    type: "common",
  },
  {
    label: "LANHERES",
    value: "55280",
    type: "common",
  },
  {
    label: "BUZY-DARMONT",
    value: "55094",
    type: "common",
  },
  {
    label: "OLLEY",
    value: "54408",
    type: "common",
  },
  {
    label: "BECHAMPS",
    value: "54058",
    type: "common",
  },
  {
    label: "WARCQ",
    value: "55578",
    type: "common",
  },
  {
    label: "HERMEVILLE-EN-WOEVRE",
    value: "55244",
    type: "common",
  },
  {
    label: "GUSSAINVILLE",
    value: "55222",
    type: "common",
  },
  {
    label: "ETAIN",
    value: "55181",
    type: "common",
  },
  {
    label: "BOINVILLE-EN-WOEVRE",
    value: "55057",
    type: "common",
  },
  {
    label: "MORANVILLE",
    value: "55356",
    type: "common",
  },
  {
    label: "FROMEZEY",
    value: "55201",
    type: "common",
  },
  {
    label: "DIEPPE-SOUS-DOUAUMONT",
    value: "55153",
    type: "common",
  },
  {
    label: "ABAUCOURT-HAUTECOURT",
    value: "55002",
    type: "common",
  },
  {
    label: "FLEURY-DEVANT-DOUAUMONT",
    value: "55189",
    type: "common",
  },
  {
    label: "EIX",
    value: "55171",
    type: "common",
  },
  {
    label: "DAMLOUP",
    value: "55143",
    type: "common",
  },
  {
    label: "BEZONVAUX",
    value: "55050",
    type: "common",
  },
  {
    label: "VACHERAUVILLE",
    value: "55523",
    type: "common",
  },
  {
    label: "CHARNY-SUR-MEUSE",
    value: "55102",
    type: "common",
  },
  {
    label: "BRAS-SUR-MEUSE",
    value: "55073",
    type: "common",
  },
  {
    label: "BELLEVILLE-SUR-MEUSE",
    value: "55043",
    type: "common",
  },
  {
    label: "MARRE",
    value: "55321",
    type: "common",
  },
  {
    label: "CUMIERES-LE-MORT-HOMME",
    value: "55139",
    type: "common",
  },
  {
    label: "CHATTANCOURT",
    value: "55106",
    type: "common",
  },
  {
    label: "MONTZEVILLE",
    value: "55355",
    type: "common",
  },
  {
    label: "ESNES-EN-ARGONNE",
    value: "55180",
    type: "common",
  },
  {
    label: "VERY",
    value: "55549",
    type: "common",
  },
  {
    label: "VAUQUOIS",
    value: "55536",
    type: "common",
  },
  {
    label: "CHEPPY",
    value: "55113",
    type: "common",
  },
  {
    label: "AVOCOURT",
    value: "55023",
    type: "common",
  },
  {
    label: "VARENNES-EN-ARGONNE",
    value: "55527",
    type: "common",
  },
  {
    label: "MONTBLAINVILLE",
    value: "55343",
    type: "common",
  },
  {
    label: "BOUREUILLES",
    value: "55065",
    type: "common",
  },
  {
    label: "VIENNE-LE-CHATEAU",
    value: "51621",
    type: "common",
  },
  {
    label: "BINARVILLE",
    value: "51062",
    type: "common",
  },
  {
    label: "SERVON-MELZICOURT",
    value: "51533",
    type: "common",
  },
  {
    label: "SAINT-THOMAS-EN-ARGONNE",
    value: "51519",
    type: "common",
  },
  {
    label: "FONTAINE-EN-DORMOIS",
    value: "51255",
    type: "common",
  },
  {
    label: "CERNAY-EN-DORMOIS",
    value: "51104",
    type: "common",
  },
  {
    label: "VILLE-SUR-TOURBE",
    value: "51640",
    type: "common",
  },
  {
    label: "MASSIGES",
    value: "51355",
    type: "common",
  },
  {
    label: "ROUVROY-RIPONT",
    value: "51470",
    type: "common",
  },
  {
    label: "GRATREUIL",
    value: "51280",
    type: "common",
  },
  {
    label: "MANRE",
    value: "08271",
    type: "common",
  },
  {
    label: "SOUAIN-PERTHES-LES-HURLUS",
    value: "51553",
    type: "common",
  },
  {
    label: "SAINTE-MARIE-A-PY",
    value: "51501",
    type: "common",
  },
  {
    label: "SAINT-SOUPLET-SUR-PY",
    value: "51517",
    type: "common",
  },
  {
    label: "VAUDESINCOURT",
    value: "51600",
    type: "common",
  },
  {
    label: "SAINT-MARTIN-L'HEUREUX",
    value: "51503",
    type: "common",
  },
  {
    label: "DONTRIEN",
    value: "51216",
    type: "common",
  },
  {
    label: "AUBERIVE",
    value: "51019",
    type: "common",
  },
  {
    label: "BEINE-NAUROY",
    value: "51046",
    type: "common",
  },
  {
    label: "PRUNAY",
    value: "51449",
    type: "common",
  },
  {
    label: "NOGENT-L'ABBESSE",
    value: "51403",
    type: "common",
  },
  {
    label: "SAINT-LEONARD",
    value: "51493",
    type: "common",
  },
  {
    label: "TAISSY",
    value: "51562",
    type: "common",
  },
  {
    label: "CERNAY-LES-REIMS",
    value: "51105",
    type: "common",
  },
  {
    label: "TROIS-PUITS",
    value: "51584",
    type: "common",
  },
  {
    label: "CORMONTREUIL",
    value: "51172",
    type: "common",
  },
  {
    label: "BEZANNES",
    value: "51058",
    type: "common",
  },
  {
    label: "TINQUEUX",
    value: "51573",
    type: "common",
  },
  {
    label: "REIMS",
    value: "51454",
    type: "common",
  },
  {
    label: "VRIGNY",
    value: "51657",
    type: "common",
  },
  {
    label: "PARGNY-LES-REIMS",
    value: "51422",
    type: "common",
  },
  {
    label: "GUEUX",
    value: "51282",
    type: "common",
  },
  {
    label: "COULOMMES-LA-MONTAGNE",
    value: "51177",
    type: "common",
  },
  {
    label: "VILLE-DOMMANGE",
    value: "51622",
    type: "common",
  },
  {
    label: "LES MESNEUX",
    value: "51365",
    type: "common",
  },
  {
    label: "JOUY-LES-REIMS",
    value: "51310",
    type: "common",
  },
  {
    label: "THILLOIS",
    value: "51569",
    type: "common",
  },
  {
    label: "ORMES",
    value: "51418",
    type: "common",
  },
  {
    label: "COURCELLES-SAPICOURT",
    value: "51181",
    type: "common",
  },
  {
    label: "TRESLON",
    value: "51581",
    type: "common",
  },
  {
    label: "TRAMERY",
    value: "51577",
    type: "common",
  },
  {
    label: "SAINT-EUPHRAISE-ET-CLAIRIZET",
    value: "51479",
    type: "common",
  },
  {
    label: "POILLY",
    value: "51437",
    type: "common",
  },
  {
    label: "ROSNAY",
    value: "51468",
    type: "common",
  },
  {
    label: "MERY-PREMECY",
    value: "51364",
    type: "common",
  },
  {
    label: "JANVRY",
    value: "51305",
    type: "common",
  },
  {
    label: "GERMIGNY",
    value: "51267",
    type: "common",
  },
  {
    label: "BOULEUSE",
    value: "51073",
    type: "common",
  },
  {
    label: "AUBILLY",
    value: "51020",
    type: "common",
  },
  {
    label: "CRUGNY",
    value: "51198",
    type: "common",
  },
  {
    label: "SERZY-ET-PRIN",
    value: "51534",
    type: "common",
  },
  {
    label: "SAVIGNY-SUR-ARDRES",
    value: "51527",
    type: "common",
  },
  {
    label: "LHERY",
    value: "51321",
    type: "common",
  },
  {
    label: "FAVEROLLES-ET-COEMY",
    value: "51245",
    type: "common",
  },
  {
    label: "LAGERY",
    value: "51314",
    type: "common",
  },
  {
    label: "BROUILLET",
    value: "51089",
    type: "common",
  },
  {
    label: "DRAVEGNY",
    value: "02271",
    type: "common",
  },
  {
    label: "ARCIS-LE-PONSART",
    value: "51014",
    type: "common",
  },
  {
    label: "MONT-SUR-COURVILLE",
    value: "51382",
    type: "common",
  },
  {
    label: "MAREUIL-EN-DOLE",
    value: "02462",
    type: "common",
  },
  {
    label: "CHERY-CHARTREUVE",
    value: "02179",
    type: "common",
  },
  {
    label: "BRUYS",
    value: "02129",
    type: "common",
  },
  {
    label: "SERINGES-ET-NESLES",
    value: "02713",
    type: "common",
  },
  {
    label: "LOUPEIGNE",
    value: "02442",
    type: "common",
  },
  {
    label: "CRAMAILLE",
    value: "02233",
    type: "common",
  },
  {
    label: "ARCY-SAINTE-RESTITUE",
    value: "02022",
    type: "common",
  },
  {
    label: "SAPONAY",
    value: "02699",
    type: "common",
  },
  {
    label: "LAUNOY",
    value: "02412",
    type: "common",
  },
  {
    label: "BEUGNEUX",
    value: "02082",
    type: "common",
  },
  {
    label: "GRAND-ROZOY",
    value: "02665",
    type: "common",
  },
  {
    label: "BILLY-SUR-OURCQ",
    value: "02090",
    type: "common",
  },
  {
    label: "VILLERS-HELON",
    value: "02812",
    type: "common",
  },
  {
    label: "SAINT-REMY-BLANZY",
    value: "02693",
    type: "common",
  },
  {
    label: "LE PLESSIER-HULEU",
    value: "02606",
    type: "common",
  },
  {
    label: "OULCHY-LA-VILLE",
    value: "02579",
    type: "common",
  },
  {
    label: "LOUATRE",
    value: "02441",
    type: "common",
  },
  {
    label: "FAVEROLLES",
    value: "02302",
    type: "common",
  },
  {
    label: "CORCY",
    value: "02216",
    type: "common",
  },
  {
    label: "CHOUY",
    value: "02192",
    type: "common",
  },
  {
    label: "ANCIENVILLE",
    value: "02015",
    type: "common",
  },
  {
    label: "FLEURY",
    value: "02316",
    type: "common",
  },
  {
    label: "DAMPLEUX",
    value: "02259",
    type: "common",
  },
  {
    label: "VILLERS-COTTERETS",
    value: "02810",
    type: "common",
  },
  {
    label: "OIGNY-EN-VALOIS",
    value: "02568",
    type: "common",
  },
  {
    label: "LARGNY-SUR-AUTOMNE",
    value: "02410",
    type: "common",
  },
  {
    label: "COYOLLES",
    value: "02232",
    type: "common",
  },
  {
    label: "VEZ",
    value: "60672",
    type: "common",
  },
  {
    label: "VAUCIENNES",
    value: "60658",
    type: "common",
  },
  {
    label: "VAUMOISE",
    value: "60661",
    type: "common",
  },
  {
    label: "RUSSY-BEMONT",
    value: "60561",
    type: "common",
  },
  {
    label: "GONDREVILLE",
    value: "60279",
    type: "common",
  },
  {
    label: "FEIGNEUX",
    value: "60231",
    type: "common",
  },
  {
    label: "SERY-MAGNEVAL",
    value: "60618",
    type: "common",
  },
  {
    label: "ROCQUEMONT",
    value: "60543",
    type: "common",
  },
  {
    label: "DUVY",
    value: "60203",
    type: "common",
  },
  {
    label: "CREPY-EN-VALOIS",
    value: "60176",
    type: "common",
  },
  {
    label: "TRUMILLY",
    value: "60650",
    type: "common",
  },
  {
    label: "NERY",
    value: "60447",
    type: "common",
  },
  {
    label: "VILLENEUVE-SUR-VERBERIE",
    value: "60680",
    type: "common",
  },
  {
    label: "RULLY",
    value: "60560",
    type: "common",
  },
  {
    label: "RARAY",
    value: "60525",
    type: "common",
  },
  {
    label: "MONTEPILLOY",
    value: "60415",
    type: "common",
  },
  {
    label: "BRASSEUSE",
    value: "60100",
    type: "common",
  },
  {
    label: "BARBERY",
    value: "60045",
    type: "common",
  },
  {
    label: "FLEURINES",
    value: "60238",
    type: "common",
  },
  {
    label: "CHAMANT",
    value: "60138",
    type: "common",
  },
  {
    label: "AUMONT-EN-HALATTE",
    value: "60028",
    type: "common",
  },
  {
    label: "SAINT-MAXIMIN",
    value: "60589",
    type: "common",
  },
  {
    label: "CREIL",
    value: "60175",
    type: "common",
  },
  {
    label: "APREMONT",
    value: "60022",
    type: "common",
  },
  {
    label: "VILLERS-SOUS-SAINT-LEU",
    value: "60686",
    type: "common",
  },
  {
    label: "THIVERNY",
    value: "60635",
    type: "common",
  },
  {
    label: "SAINT-LEU-D'ESSERENT",
    value: "60584",
    type: "common",
  },
  {
    label: "MONTATAIRE",
    value: "60414",
    type: "common",
  },
  {
    label: "MAYSEL",
    value: "60391",
    type: "common",
  },
  {
    label: "CRAMOISY",
    value: "60173",
    type: "common",
  },
  {
    label: "BLAINCOURT-LES-PRECY",
    value: "60074",
    type: "common",
  },
  {
    label: "NEUILLY-EN-THELLE",
    value: "60450",
    type: "common",
  },
  {
    label: "ERCUIS",
    value: "60212",
    type: "common",
  },
  {
    label: "CIRES-LES-MELLO",
    value: "60155",
    type: "common",
  },
  {
    label: "PUISEUX-LE-HAUBERGER",
    value: "60517",
    type: "common",
  },
  {
    label: "NOVILLERS",
    value: "60469",
    type: "common",
  },
  {
    label: "MORTEFONTAINE-EN-THELLE",
    value: "60433",
    type: "common",
  },
  {
    label: "LACHAPELLE-SAINT-PIERRE",
    value: "60334",
    type: "common",
  },
  {
    label: "DIEUDONNE",
    value: "60197",
    type: "common",
  },
  {
    label: "MERU",
    value: "60395",
    type: "common",
  },
  {
    label: "LORMAISON",
    value: "60370",
    type: "common",
  },
  {
    label: "ESCHES",
    value: "60218",
    type: "common",
  },
  {
    label: "ANDEVILLE",
    value: "60012",
    type: "common",
  },
  {
    label: "VILLENEUVE-LES-SABLONS",
    value: "60678",
    type: "common",
  },
  {
    label: "SENOTS",
    value: "60613",
    type: "common",
  },
  {
    label: "SAINT-CREPIN-IBOUVILLERS",
    value: "60570",
    type: "common",
  },
  {
    label: "POUILLY",
    value: "60512",
    type: "common",
  },
  {
    label: "IVRY-LE-TEMPLE",
    value: "60321",
    type: "common",
  },
  {
    label: "TOURLY",
    value: "60640",
    type: "common",
  },
  {
    label: "MONTS",
    value: "60427",
    type: "common",
  },
  {
    label: "LOCONVILLE",
    value: "60367",
    type: "common",
  },
  {
    label: "FRESNE-LEGUILLON",
    value: "60257",
    type: "common",
  },
  {
    label: "FLEURY",
    value: "60239",
    type: "common",
  },
  {
    label: "FAY-LES-ETANGS",
    value: "60228",
    type: "common",
  },
  {
    label: "REILLY",
    value: "60528",
    type: "common",
  },
  {
    label: "LIANCOURT-SAINT-PIERRE",
    value: "60361",
    type: "common",
  },
  {
    label: "DELINCOURT",
    value: "60195",
    type: "common",
  },
  {
    label: "BOUBIERS",
    value: "60089",
    type: "common",
  },
  {
    label: "VAUDANCOURT",
    value: "60659",
    type: "common",
  },
  {
    label: "MONTJAVOULT",
    value: "60420",
    type: "common",
  },
  {
    label: "LATTAINVILLE",
    value: "60352",
    type: "common",
  },
  {
    label: "COURCELLES-LES-GISORS",
    value: "60169",
    type: "common",
  },
  {
    label: "CHAMBORS",
    value: "60140",
    type: "common",
  },
  {
    label: "BOURY-EN-VEXIN",
    value: "60095",
    type: "common",
  },
  {
    label: "SAINT-CLAIR-SUR-EPTE",
    value: "95541",
    type: "common",
  },
  {
    label: "NOYERS",
    value: "27445",
    type: "common",
  },
  {
    label: "GUERNY",
    value: "27304",
    type: "common",
  },
  {
    label: "DANGU",
    value: "27199",
    type: "common",
  },
  {
    label: "VILLERS-EN-VEXIN",
    value: "27690",
    type: "common",
  },
  {
    label: "VESLY",
    value: "27682",
    type: "common",
  },
  {
    label: "LES THILLIERS-EN-VEXIN",
    value: "27633",
    type: "common",
  },
  {
    label: "GAMACHES-EN-VEXIN",
    value: "27276",
    type: "common",
  },
  {
    label: "AUTHEVERNES",
    value: "27026",
    type: "common",
  },
  {
    label: "RICHEVILLE",
    value: "27490",
    type: "common",
  },
  {
    label: "MOUFLAINES",
    value: "27420",
    type: "common",
  },
  {
    label: "HARQUENCY",
    value: "27315",
    type: "common",
  },
  {
    label: "GUISENIERS",
    value: "27307",
    type: "common",
  },
  {
    label: "VEZILLON",
    value: "27683",
    type: "common",
  },
  {
    label: "LES ANDELYS",
    value: "27016",
    type: "common",
  },
  {
    label: "LE THUIT",
    value: "27635",
    type: "common",
  },
  {
    label: "LA ROQUETTE",
    value: "27495",
    type: "common",
  },
  {
    label: "MUIDS",
    value: "27422",
    type: "common",
  },
  {
    label: "DAUBEUF-PRES-VATTEVILLE",
    value: "27202",
    type: "common",
  },
  {
    label: "VAL-DE-REUIL",
    value: "27701",
    type: "common",
  },
  {
    label: "SAINT-PIERRE-DU-VAUVRAY",
    value: "27598",
    type: "common",
  },
  {
    label: "SAINT-ETIENNE-DU-VAUVRAY",
    value: "27537",
    type: "common",
  },
  {
    label: "PORTE-DE-SEINE",
    value: "27471",
    type: "common",
  },
  {
    label: "HERQUEVILLE",
    value: "27330",
    type: "common",
  },
  {
    label: "CONNELLES",
    value: "27168",
    type: "common",
  },
  {
    label: "ANDE",
    value: "27015",
    type: "common",
  },
  {
    label: "LE VAUDREUIL",
    value: "27528",
    type: "common",
  },
  {
    label: "LOUVIERS",
    value: "27375",
    type: "common",
  },
  {
    label: "INCARVILLE",
    value: "27351",
    type: "common",
  },
  {
    label: "VRAIVILLE",
    value: "27700",
    type: "common",
  },
  {
    label: "SAINT-DIDIER-DES-BOIS",
    value: "27534",
    type: "common",
  },
  {
    label: "SAINT-CYR-LA-CAMPAGNE",
    value: "27529",
    type: "common",
  },
  {
    label: "TERRES DE BORD",
    value: "27412",
    type: "common",
  },
  {
    label: "LA HAYE-MALHERBE",
    value: "27322",
    type: "common",
  },
  {
    label: "LE THUIT DE L'OISON",
    value: "27638",
    type: "common",
  },
  {
    label: "LA SAUSSAYE",
    value: "27616",
    type: "common",
  },
  {
    label: "SAINT-PIERRE-DES-FLEURS",
    value: "27593",
    type: "common",
  },
  {
    label: "SAINT-OUEN-DE-PONTCHEUIL",
    value: "27579",
    type: "common",
  },
  {
    label: "SAINT-GERMAIN-DE-PASQUIER",
    value: "27545",
    type: "common",
  },
  {
    label: "MANDEVILLE",
    value: "27382",
    type: "common",
  },
  {
    label: "LA HARENGERE",
    value: "27313",
    type: "common",
  },
  {
    label: "FOUQUEVILLE",
    value: "27261",
    type: "common",
  },
  {
    label: "LE BEC-THOMAS",
    value: "27053",
    type: "common",
  },
  {
    label: "AMFREVILLE-SAINT-AMAND",
    value: "27011",
    type: "common",
  },
  {
    label: "TOURVILLE-LA-CAMPAGNE",
    value: "27654",
    type: "common",
  },
  {
    label: "SAINT-PIERRE-DU-BOSGUERARD",
    value: "27595",
    type: "common",
  },
  {
    label: "SAINT-MESLIN-DU-BOSC",
    value: "27572",
    type: "common",
  },
  {
    label: "LA HAYE-DU-THEIL",
    value: "27320",
    type: "common",
  },
  {
    label: "LE BOSC DU THEIL",
    value: "27302",
    type: "common",
  },
  {
    label: "SAINT-PHILBERT-SUR-BOISSEY",
    value: "27586",
    type: "common",
  },
  {
    label: "SAINT-PAUL-DE-FOURQUES",
    value: "27584",
    type: "common",
  },
  {
    label: "SAINT-ELOI-DE-FOURQUES",
    value: "27536",
    type: "common",
  },
  {
    label: "SAINT-DENIS-DES-MONTS",
    value: "27531",
    type: "common",
  },
  {
    label: "MALLEVILLE-SUR-LE-BEC",
    value: "27380",
    type: "common",
  },
  {
    label: "BOSROBERT",
    value: "27095",
    type: "common",
  },
  {
    label: "BONNEVILLE-APTOT",
    value: "27083",
    type: "common",
  },
  {
    label: "PONT-AUTHOU",
    value: "27468",
    type: "common",
  },
  {
    label: "LIVET-SUR-AUTHOU",
    value: "27371",
    type: "common",
  },
  {
    label: "FRENEUSE-SUR-RISLE",
    value: "27267",
    type: "common",
  },
  {
    label: "BRETIGNY",
    value: "27113",
    type: "common",
  },
  {
    label: "LE BEC-HELLOUIN",
    value: "27052",
    type: "common",
  },
  {
    label: "AUTHOU",
    value: "27028",
    type: "common",
  },
  {
    label: "SAINT-VICTOR-D'EPINE",
    value: "27609",
    type: "common",
  },
  {
    label: "SAINT-GREGOIRE-DU-VIEVRE",
    value: "27550",
    type: "common",
  },
  {
    label: "SAINT-GEORGES-DU-VIEVRE",
    value: "27542",
    type: "common",
  },
  {
    label: "SAINT-BENOIT-DES-OMBRES",
    value: "27520",
    type: "common",
  },
  {
    label: "NEUVILLE-SUR-AUTHOU",
    value: "27433",
    type: "common",
  },
  {
    label: "LA POTERIE-MATHIEU",
    value: "27475",
    type: "common",
  },
  {
    label: "NOARDS",
    value: "27434",
    type: "common",
  },
  {
    label: "LIEUREY",
    value: "27367",
    type: "common",
  },
  {
    label: "EPREVILLE-EN-LIEUVIN",
    value: "27222",
    type: "common",
  },
  {
    label: "SAINT-SYLVESTRE-DE-CORMEILLES",
    value: "27605",
    type: "common",
  },
  {
    label: "MORAINVILLE-JOUVEAUX",
    value: "27415",
    type: "common",
  },
  {
    label: "FRESNE-CAUVERVILLE",
    value: "27269",
    type: "common",
  },
  {
    label: "CORMEILLES",
    value: "27170",
    type: "common",
  },
  {
    label: "ASNIERES",
    value: "27021",
    type: "common",
  },
  {
    label: "SAINT-PIERRE-DE-CORMEILLES",
    value: "27591",
    type: "common",
  },
  {
    label: "LE PIN",
    value: "14504",
    type: "common",
  },
  {
    label: "MOYAUX",
    value: "14460",
    type: "common",
  },
  {
    label: "LE FAULQ",
    value: "14261",
    type: "common",
  },
  {
    label: "SAINT-PHILBERT-DES-CHAMPS",
    value: "14644",
    type: "common",
  },
  {
    label: "NOROLLES",
    value: "14466",
    type: "common",
  },
  {
    label: "FIERVILLE-LES-PARCS",
    value: "14269",
    type: "common",
  },
  {
    label: "LE BREVEDENT",
    value: "14104",
    type: "common",
  },
  {
    label: "LE BREUIL-EN-AUGE",
    value: "14102",
    type: "common",
  },
  {
    label: "BLANGY-LE-CHATEAU",
    value: "14077",
    type: "common",
  },
  {
    label: "LE TORQUESNE",
    value: "14694",
    type: "common",
  },
  {
    label: "SAINT-HYMER",
    value: "14593",
    type: "common",
  },
  {
    label: "FORMENTIN",
    value: "14280",
    type: "common",
  },
  {
    label: "COQUAINVILLIERS",
    value: "14177",
    type: "common",
  },
  {
    label: "CLARBEC",
    value: "14161",
    type: "common",
  },
  {
    label: "VALSEME",
    value: "14723",
    type: "common",
  },
  {
    label: "REPENTIGNY",
    value: "14533",
    type: "common",
  },
  {
    label: "LE FOURNET",
    value: "14285",
    type: "common",
  },
  {
    label: "DRUBEC",
    value: "14230",
    type: "common",
  },
  {
    label: "DANESTAL",
    value: "14218",
    type: "common",
  },
  {
    label: "BONNEBOSQ",
    value: "14083",
    type: "common",
  },
  {
    label: "AUVILLARS",
    value: "14033",
    type: "common",
  },
  {
    label: "ANNEBAULT",
    value: "14016",
    type: "common",
  },
  {
    label: "SAINT-LEGER-DUBOSQ",
    value: "14606",
    type: "common",
  },
  {
    label: "SAINT-JOUIN",
    value: "14598",
    type: "common",
  },
  {
    label: "BEAUFOUR-DRUVAL",
    value: "14231",
    type: "common",
  },
  {
    label: "DOZULE",
    value: "14229",
    type: "common",
  },
  {
    label: "CRESSEVEUILLE",
    value: "14198",
    type: "common",
  },
  {
    label: "BEUVRON-EN-AUGE",
    value: "14070",
    type: "common",
  },
  {
    label: "ANGERVILLE",
    value: "14012",
    type: "common",
  },
  {
    label: "SAINT-SAMSON",
    value: "14657",
    type: "common",
  },
  {
    label: "PUTOT-EN-AUGE",
    value: "14524",
    type: "common",
  },
  {
    label: "GOUSTRANVILLE",
    value: "14308",
    type: "common",
  },
  {
    label: "CRICQUEVILLE-EN-AUGE",
    value: "14203",
    type: "common",
  },
  {
    label: "BRUCOURT",
    value: "14110",
    type: "common",
  },
  {
    label: "BASSENEVILLE",
    value: "14045",
    type: "common",
  },
  {
    label: "BREVILLE-LES-MONTS",
    value: "14106",
    type: "common",
  },
  {
    label: "TOUFFREVILLE",
    value: "14698",
    type: "common",
  },
  {
    label: "PETIVILLE",
    value: "14499",
    type: "common",
  },
  {
    label: "BAVENT",
    value: "14046",
    type: "common",
  },
  {
    label: "RANVILLE",
    value: "14530",
    type: "common",
  },
  {
    label: "HEROUVILLETTE",
    value: "14328",
    type: "common",
  },
  {
    label: "HEROUVILLE-SAINT-CLAIR",
    value: "14327",
    type: "common",
  },
  {
    label: "ESCOVILLE",
    value: "14246",
    type: "common",
  },
  {
    label: "CUVERVILLE",
    value: "14215",
    type: "common",
  },
  {
    label: "COLOMBELLES",
    value: "14167",
    type: "common",
  },
  {
    label: "BLAINVILLE-SUR-ORNE",
    value: "14076",
    type: "common",
  },
  {
    label: "BENOUVILLE",
    value: "14060",
    type: "common",
  },
  {
    label: "VILLONS-LES-BUISSONS",
    value: "14758",
    type: "common",
  },
  {
    label: "SAINT-GERMAIN-LA-BLANCHE-HERBE",
    value: "14587",
    type: "common",
  },
  {
    label: "SAINT-CONTEST",
    value: "14566",
    type: "common",
  },
  {
    label: "EPRON",
    value: "14242",
    type: "common",
  },
  {
    label: "CAMBES-EN-PLAINE",
    value: "14125",
    type: "common",
  },
  {
    label: "CAEN",
    value: "14118",
    type: "common",
  },
  {
    label: "SAINT-MANVIEU-NORREY",
    value: "14610",
    type: "common",
  },
  {
    label: "ROTS",
    value: "14543",
    type: "common",
  },
  {
    label: "ROSEL",
    value: "14542",
    type: "common",
  },
  {
    label: "CARPIQUET",
    value: "14137",
    type: "common",
  },
  {
    label: "CAIRON",
    value: "14123",
    type: "common",
  },
  {
    label: "THUE ET MUE",
    value: "14098",
    type: "common",
  },
  {
    label: "AUTHIE",
    value: "14030",
    type: "common",
  },
  {
    label: "LOUCELLES",
    value: "14380",
    type: "common",
  },
  {
    label: "CRISTOT",
    value: "14205",
    type: "common",
  },
  {
    label: "AUDRIEU",
    value: "14026",
    type: "common",
  },
  {
    label: "JUAYE-MONDAYE",
    value: "14346",
    type: "common",
  },
  {
    label: "ELLON",
    value: "14236",
    type: "common",
  },
  {
    label: "DUCY-SAINTE-MARGUERITE",
    value: "14232",
    type: "common",
  },
  {
    label: "CONDE-SUR-SEULLES",
    value: "14175",
    type: "common",
  },
  {
    label: "CHOUAIN",
    value: "14159",
    type: "common",
  },
  {
    label: "BUCEELS",
    value: "14111",
    type: "common",
  },
  {
    label: "TRUNGY",
    value: "14716",
    type: "common",
  },
  {
    label: "SAINT-PAUL-DU-VERNAY",
    value: "14643",
    type: "common",
  },
  {
    label: "NORON-LA-POTERIE",
    value: "14468",
    type: "common",
  },
  {
    label: "ARGANCHY",
    value: "14019",
    type: "common",
  },
  {
    label: "LE TRONQUAY",
    value: "14714",
    type: "common",
  },
  {
    label: "CASTILLON",
    value: "14140",
    type: "common",
  },
  {
    label: "BALLEROY-SUR-DROME",
    value: "14035",
    type: "common",
  },
  {
    label: "CERISY-LA-FORET",
    value: "50110",
    type: "common",
  },
  {
    label: "MONTFIQUET",
    value: "14445",
    type: "common",
  },
  {
    label: "SAINT-JEAN-DE-SAVIGNY",
    value: "50491",
    type: "common",
  },
  {
    label: "SAINT-CLAIR-SUR-L'ELLE",
    value: "50455",
    type: "common",
  },
  {
    label: "MOON-SUR-ELLE",
    value: "50356",
    type: "common",
  },
  {
    label: "SAINTE-MARGUERITE-D'ELLE",
    value: "14614",
    type: "common",
  },
  {
    label: "LA MEAUFFE",
    value: "50297",
    type: "common",
  },
  {
    label: "CAVIGNY",
    value: "50106",
    type: "common",
  },
  {
    label: "AIREL",
    value: "50004",
    type: "common",
  },
  {
    label: "PONT-HEBERT",
    value: "50409",
    type: "common",
  },
  {
    label: "LE MESNIL-VENERON",
    value: "50324",
    type: "common",
  },
  {
    label: "LE DEZERT",
    value: "50161",
    type: "common",
  },
  {
    label: "TRIBEHOU",
    value: "50606",
    type: "common",
  },
  {
    label: "REMILLY LES MARAIS",
    value: "50431",
    type: "common",
  },
  {
    label: "MARCHESIEUX",
    value: "50289",
    type: "common",
  },
  {
    label: "AUXAIS",
    value: "50024",
    type: "common",
  },
  {
    label: "SAINT-SEBASTIEN-DE-RAIDS",
    value: "50552",
    type: "common",
  },
  {
    label: "SAINT-MARTIN-D'AUBIGNY",
    value: "50510",
    type: "common",
  },
  {
    label: "SAINT-GERMAIN-SUR-SEVES",
    value: "50482",
    type: "common",
  },
  {
    label: "RAIDS",
    value: "50422",
    type: "common",
  },
  {
    label: "PERIERS",
    value: "50394",
    type: "common",
  },
  {
    label: "MILLIERES",
    value: "50328",
    type: "common",
  },
  {
    label: "LA FEUILLIE",
    value: "50182",
    type: "common",
  },
  {
    label: "PIROU",
    value: "50403",
    type: "common",
  },
  {
    label: "CREANCES",
    value: "50151",
    type: "common",
  },
  {
    label: "WINGEN",
    value: "67537",
    type: "common",
  },
  {
    label: "OBERSTEINBACH",
    value: "67353",
    type: "common",
  },
  {
    label: "NIEDERSTEINBACH",
    value: "67334",
    type: "common",
  },
  {
    label: "STURZELBRONN",
    value: "57661",
    type: "common",
  },
  {
    label: "ROPPEVILLER",
    value: "57594",
    type: "common",
  },
  {
    label: "HASPELSCHIEDT",
    value: "57301",
    type: "common",
  },
  {
    label: "SCHORBACH",
    value: "57639",
    type: "common",
  },
  {
    label: "HANVILLER",
    value: "57294",
    type: "common",
  },
  {
    label: "SIERSTHAL",
    value: "57651",
    type: "common",
  },
  {
    label: "PETIT-REDERCHING",
    value: "57535",
    type: "common",
  },
  {
    label: "NOUSSEVILLER-LES-BITCHE",
    value: "57513",
    type: "common",
  },
  {
    label: "HOTTVILLER",
    value: "57338",
    type: "common",
  },
  {
    label: "EPPING",
    value: "57195",
    type: "common",
  },
  {
    label: "BETTVILLER",
    value: "57074",
    type: "common",
  },
  {
    label: "RIMLING",
    value: "57584",
    type: "common",
  },
  {
    label: "GROS-REDERCHING",
    value: "57261",
    type: "common",
  },
  {
    label: "ZETTING",
    value: "57760",
    type: "common",
  },
  {
    label: "WŒLFLING-LES-SARREGUEMINES",
    value: "57750",
    type: "common",
  },
  {
    label: "WIESVILLER",
    value: "57745",
    type: "common",
  },
  {
    label: "SARREINSMING",
    value: "57633",
    type: "common",
  },
  {
    label: "BLIES-EBERSING",
    value: "57092",
    type: "common",
  },
  {
    label: "BLIESBRUCK",
    value: "57091",
    type: "common",
  },
  {
    label: "SILTZHEIM",
    value: "67468",
    type: "common",
  },
  {
    label: "SARREGUEMINES",
    value: "57631",
    type: "common",
  },
  {
    label: "REMELFING",
    value: "57568",
    type: "common",
  },
  {
    label: "NEUFGRANGE",
    value: "57499",
    type: "common",
  },
  {
    label: "WOUSTVILLER",
    value: "57752",
    type: "common",
  },
  {
    label: "NOUSSEVILLER-SAINT-NABOR",
    value: "57514",
    type: "common",
  },
  {
    label: "METZING",
    value: "57466",
    type: "common",
  },
  {
    label: "IPPLING",
    value: "57348",
    type: "common",
  },
  {
    label: "HUNDLING",
    value: "57340",
    type: "common",
  },
  {
    label: "GUEBENHOUSE",
    value: "57264",
    type: "common",
  },
  {
    label: "TENTELING",
    value: "57665",
    type: "common",
  },
  {
    label: "LOUPERSHOUSE",
    value: "57419",
    type: "common",
  },
  {
    label: "HENRIVILLE",
    value: "57316",
    type: "common",
  },
  {
    label: "FARSCHVILLER",
    value: "57208",
    type: "common",
  },
  {
    label: "FAREBERSVILLER",
    value: "57207",
    type: "common",
  },
  {
    label: "DIEBLING",
    value: "57176",
    type: "common",
  },
  {
    label: "CAPPEL",
    value: "57122",
    type: "common",
  },
  {
    label: "SEINGBOUSE",
    value: "57644",
    type: "common",
  },
  {
    label: "MACHEREN",
    value: "57428",
    type: "common",
  },
  {
    label: "HOMBOURG-HAUT",
    value: "57332",
    type: "common",
  },
  {
    label: "GUENVILLER",
    value: "57271",
    type: "common",
  },
  {
    label: "BARST",
    value: "57052",
    type: "common",
  },
  {
    label: "VALMONT",
    value: "57690",
    type: "common",
  },
  {
    label: "SAINT-AVOLD",
    value: "57606",
    type: "common",
  },
  {
    label: "LACHAMBRE",
    value: "57373",
    type: "common",
  },
  {
    label: "ZIMMING",
    value: "57762",
    type: "common",
  },
  {
    label: "LONGEVILLE-LES-SAINT-AVOLD",
    value: "57413",
    type: "common",
  },
  {
    label: "LAUDREFANG",
    value: "57386",
    type: "common",
  },
  {
    label: "BAMBIDERSTROFF",
    value: "57047",
    type: "common",
  },
  {
    label: "HAUTE-VIGNEULLES",
    value: "57714",
    type: "common",
  },
  {
    label: "NARBEFONTAINE",
    value: "57495",
    type: "common",
  },
  {
    label: "MARANGE-ZONDRANGE",
    value: "57444",
    type: "common",
  },
  {
    label: "HALLERING",
    value: "57284",
    type: "common",
  },
  {
    label: "FOULIGNY",
    value: "57230",
    type: "common",
  },
  {
    label: "BROUCK",
    value: "57112",
    type: "common",
  },
  {
    label: "VARIZE-VAUDONCOURT",
    value: "57695",
    type: "common",
  },
  {
    label: "RAVILLE",
    value: "57563",
    type: "common",
  },
  {
    label: "COURCELLES-CHAUSSY",
    value: "57155",
    type: "common",
  },
  {
    label: "BIONVILLE-SUR-NIED",
    value: "57085",
    type: "common",
  },
  {
    label: "BANNAY",
    value: "57048",
    type: "common",
  },
  {
    label: "SILLY-SUR-NIED",
    value: "57654",
    type: "common",
  },
  {
    label: "GLATIGNY",
    value: "57249",
    type: "common",
  },
  {
    label: "COLLIGNY-MAIZERY",
    value: "57148",
    type: "common",
  },
  {
    label: "VANTOUX",
    value: "57693",
    type: "common",
  },
  {
    label: "RETONFEY",
    value: "57575",
    type: "common",
  },
  {
    label: "NOUILLY",
    value: "57512",
    type: "common",
  },
  {
    label: "NOISSEVILLE",
    value: "57510",
    type: "common",
  },
  {
    label: "OGY-MONTOY-FLANVILLE",
    value: "57482",
    type: "common",
  },
  {
    label: "MEY",
    value: "57467",
    type: "common",
  },
  {
    label: "MARSILLY",
    value: "57449",
    type: "common",
  },
  {
    label: "COINCY",
    value: "57145",
    type: "common",
  },
  {
    label: "SAINT-JULIEN-LES-METZ",
    value: "57616",
    type: "common",
  },
  {
    label: "METZ",
    value: "57463",
    type: "common",
  },
  {
    label: "LONGEVILLE-LES-METZ",
    value: "57412",
    type: "common",
  },
  {
    label: "LE BAN-SAINT-MARTIN",
    value: "57049",
    type: "common",
  },
  {
    label: "SCY-CHAZELLES",
    value: "57642",
    type: "common",
  },
  {
    label: "SAINTE-RUFFINE",
    value: "57624",
    type: "common",
  },
  {
    label: "ROZERIEULLES",
    value: "57601",
    type: "common",
  },
  {
    label: "PLAPPEVILLE",
    value: "57545",
    type: "common",
  },
  {
    label: "LORRY-LES-METZ",
    value: "57415",
    type: "common",
  },
  {
    label: "LESSY",
    value: "57396",
    type: "common",
  },
  {
    label: "JUSSY",
    value: "57352",
    type: "common",
  },
  {
    label: "CHATEL-SAINT-GERMAIN",
    value: "57134",
    type: "common",
  },
  {
    label: "VERNEVILLE",
    value: "57707",
    type: "common",
  },
  {
    label: "GRAVELOTTE",
    value: "57256",
    type: "common",
  },
  {
    label: "AMANVILLERS",
    value: "57017",
    type: "common",
  },
  {
    label: "SAINT-MARCEL",
    value: "54478",
    type: "common",
  },
  {
    label: "VILLE-SUR-YRON",
    value: "54581",
    type: "common",
  },
  {
    label: "JARNY",
    value: "54273",
    type: "common",
  },
  {
    label: "DONCOURT-LES-CONFLANS",
    value: "54171",
    type: "common",
  },
  {
    label: "BRUVILLE",
    value: "54103",
    type: "common",
  },
  {
    label: "PUXE",
    value: "54440",
    type: "common",
  },
  {
    label: "FRIAUVILLE",
    value: "54213",
    type: "common",
  },
  {
    label: "BRAINVILLE",
    value: "54093",
    type: "common",
  },
  {
    label: "VILLERS-SOUS-PAREID",
    value: "55565",
    type: "common",
  },
  {
    label: "PARFONDRUPT",
    value: "55400",
    type: "common",
  },
  {
    label: "PAREID",
    value: "55399",
    type: "common",
  },
  {
    label: "ALLAMONT",
    value: "54009",
    type: "common",
  },
  {
    label: "VILLE-EN-WOEVRE",
    value: "55557",
    type: "common",
  },
  {
    label: "PINTHEVILLE",
    value: "55406",
    type: "common",
  },
  {
    label: "MANHEULLES",
    value: "55317",
    type: "common",
  },
  {
    label: "HENNEMONT",
    value: "55242",
    type: "common",
  },
  {
    label: "BRAQUIS",
    value: "55072",
    type: "common",
  },
  {
    label: "WATRONVILLE",
    value: "55579",
    type: "common",
  },
  {
    label: "RONVAUX",
    value: "55439",
    type: "common",
  },
  {
    label: "HAUDIOMONT",
    value: "55237",
    type: "common",
  },
  {
    label: "GRIMAUCOURT-EN-WOEVRE",
    value: "55219",
    type: "common",
  },
  {
    label: "CHATILLON-SOUS-LES-COTES",
    value: "55105",
    type: "common",
  },
  {
    label: "BLANZEE",
    value: "55055",
    type: "common",
  },
  {
    label: "MOULAINVILLE",
    value: "55361",
    type: "common",
  },
  {
    label: "HAUDAINVILLE",
    value: "55236",
    type: "common",
  },
  {
    label: "BELRUPT-EN-VERDUNOIS",
    value: "55045",
    type: "common",
  },
  {
    label: "VERDUN",
    value: "55545",
    type: "common",
  },
  {
    label: "THIERVILLE-SUR-MEUSE",
    value: "55505",
    type: "common",
  },
  {
    label: "BELLERAY",
    value: "55042",
    type: "common",
  },
  {
    label: "SIVRY-LA-PERCHE",
    value: "55489",
    type: "common",
  },
  {
    label: "FROMEREVILLE-LES-VALLONS",
    value: "55200",
    type: "common",
  },
  {
    label: "RECICOURT",
    value: "55419",
    type: "common",
  },
  {
    label: "BRABANT-EN-ARGONNE",
    value: "55068",
    type: "common",
  },
  {
    label: "JOUY-EN-ARGONNE",
    value: "55257",
    type: "common",
  },
  {
    label: "DOMBASLE-EN-ARGONNE",
    value: "55155",
    type: "common",
  },
  {
    label: "BETHELAINVILLE",
    value: "55047",
    type: "common",
  },
  {
    label: "NEUVILLY-EN-ARGONNE",
    value: "55383",
    type: "common",
  },
  {
    label: "AUBREVILLE",
    value: "55014",
    type: "common",
  },
  {
    label: "LE NEUFOUR",
    value: "55379",
    type: "common",
  },
  {
    label: "LACHALADE",
    value: "55266",
    type: "common",
  },
  {
    label: "LE CLAON",
    value: "55116",
    type: "common",
  },
  {
    label: "MOIREMONT",
    value: "51370",
    type: "common",
  },
  {
    label: "FLORENT-EN-ARGONNE",
    value: "51253",
    type: "common",
  },
  {
    label: "VIENNE-LA-VILLE",
    value: "51620",
    type: "common",
  },
  {
    label: "MALMY",
    value: "51341",
    type: "common",
  },
  {
    label: "BERZIEUX",
    value: "51053",
    type: "common",
  },
  {
    label: "LA NEUVILLE-AU-PONT",
    value: "51399",
    type: "common",
  },
  {
    label: "VIRGINY",
    value: "51646",
    type: "common",
  },
  {
    label: "COURTEMONT",
    value: "51191",
    type: "common",
  },
  {
    label: "WARGEMOULIN-HURLUS",
    value: "51659",
    type: "common",
  },
  {
    label: "MINAUCOURT-LE-MESNIL-LES-HURLUS",
    value: "51368",
    type: "common",
  },
  {
    label: "LAVAL-SUR-TOURBE",
    value: "51317",
    type: "common",
  },
  {
    label: "SAINT-HILAIRE-LE-GRAND",
    value: "51486",
    type: "common",
  },
  {
    label: "BACONNES",
    value: "51031",
    type: "common",
  },
  {
    label: "PROSNES",
    value: "51447",
    type: "common",
  },
  {
    label: "SEPT-SAULX",
    value: "51530",
    type: "common",
  },
  {
    label: "VAL-DE-VESLE",
    value: "51571",
    type: "common",
  },
  {
    label: "VERZY",
    value: "51614",
    type: "common",
  },
  {
    label: "BEAUMONT-SUR-VESLE",
    value: "51044",
    type: "common",
  },
  {
    label: "VERZENAY",
    value: "51613",
    type: "common",
  },
  {
    label: "PUISIEULX",
    value: "51450",
    type: "common",
  },
  {
    label: "SILLERY",
    value: "51536",
    type: "common",
  },
  {
    label: "MAILLY-CHAMPAGNE",
    value: "51338",
    type: "common",
  },
  {
    label: "LUDES",
    value: "51333",
    type: "common",
  },
  {
    label: "CHAMPFLEURY",
    value: "51115",
    type: "common",
  },
  {
    label: "VILLERS-AUX-NŒUDS",
    value: "51631",
    type: "common",
  },
  {
    label: "CHIGNY-LES-ROSES",
    value: "51152",
    type: "common",
  },
  {
    label: "VILLERS-ALLERAND",
    value: "51629",
    type: "common",
  },
  {
    label: "RILLY-LA-MONTAGNE",
    value: "51461",
    type: "common",
  },
  {
    label: "MONTBRE",
    value: "51375",
    type: "common",
  },
  {
    label: "POURCY",
    value: "51445",
    type: "common",
  },
  {
    label: "SACY",
    value: "51471",
    type: "common",
  },
  {
    label: "MARFAUX",
    value: "51348",
    type: "common",
  },
  {
    label: "ECUEIL",
    value: "51225",
    type: "common",
  },
  {
    label: "COURMAS",
    value: "51188",
    type: "common",
  },
  {
    label: "BOUILLY",
    value: "51072",
    type: "common",
  },
  {
    label: "SERMIERS",
    value: "51532",
    type: "common",
  },
  {
    label: "CHAMERY",
    value: "51112",
    type: "common",
  },
  {
    label: "CHAMPLAT-ET-BOUJACOURT",
    value: "51120",
    type: "common",
  },
  {
    label: "SARCY",
    value: "51523",
    type: "common",
  },
  {
    label: "CHAMBRECY",
    value: "51111",
    type: "common",
  },
  {
    label: "CHAUMUZY",
    value: "51140",
    type: "common",
  },
  {
    label: "BLIGNY",
    value: "51069",
    type: "common",
  },
  {
    label: "OLIZY",
    value: "51414",
    type: "common",
  },
  {
    label: "JONQUERY",
    value: "51309",
    type: "common",
  },
  {
    label: "VILLE-EN-TARDENOIS",
    value: "51624",
    type: "common",
  },
  {
    label: "ROMIGNY",
    value: "51466",
    type: "common",
  },
  {
    label: "AOUGNY",
    value: "51013",
    type: "common",
  },
  {
    label: "VILLERS-AGRON-AIGUIZY",
    value: "02809",
    type: "common",
  },
  {
    label: "GOUSSANCOURT",
    value: "02351",
    type: "common",
  },
  {
    label: "COULONGES-COHAN",
    value: "02220",
    type: "common",
  },
  {
    label: "VEZILLY",
    value: "02794",
    type: "common",
  },
  {
    label: "CIERGES",
    value: "02193",
    type: "common",
  },
  {
    label: "VILLERS-SUR-FERE",
    value: "02816",
    type: "common",
  },
  {
    label: "SERGY",
    value: "02712",
    type: "common",
  },
  {
    label: "FERE-EN-TARDENOIS",
    value: "02305",
    type: "common",
  },
  {
    label: "BRUYERES-SUR-FERE",
    value: "02127",
    type: "common",
  },
  {
    label: "VILLENEUVE-SUR-FERE",
    value: "02806",
    type: "common",
  },
  {
    label: "LA CROIX-SUR-OURCQ",
    value: "02241",
    type: "common",
  },
  {
    label: "COINCY",
    value: "02203",
    type: "common",
  },
  {
    label: "BRENY",
    value: "02121",
    type: "common",
  },
  {
    label: "ARMENTIERES-SUR-OURCQ",
    value: "02023",
    type: "common",
  },
  {
    label: "OULCHY-LE-CHATEAU",
    value: "02580",
    type: "common",
  },
  {
    label: "NANTEUIL-NOTRE-DAME",
    value: "02538",
    type: "common",
  },
  {
    label: "LATILLY",
    value: "02411",
    type: "common",
  },
  {
    label: "VICHEL-NANTEUIL",
    value: "02796",
    type: "common",
  },
  {
    label: "ROZET-SAINT-ALBIN",
    value: "02662",
    type: "common",
  },
  {
    label: "NEUILLY-SAINT-FRONT",
    value: "02543",
    type: "common",
  },
  {
    label: "MONTGRU-SAINT-HILAIRE",
    value: "02507",
    type: "common",
  },
  {
    label: "MARIZY-SAINT-MARD",
    value: "02467",
    type: "common",
  },
  {
    label: "MARIZY-SAINTE-GENEVIEVE",
    value: "02466",
    type: "common",
  },
  {
    label: "MACOGNY",
    value: "02449",
    type: "common",
  },
  {
    label: "TROESNES",
    value: "02749",
    type: "common",
  },
  {
    label: "PASSY-EN-VALOIS",
    value: "02594",
    type: "common",
  },
  {
    label: "NOROY-SUR-OURCQ",
    value: "02557",
    type: "common",
  },
  {
    label: "LA FERTE-MILON",
    value: "02307",
    type: "common",
  },
  {
    label: "SILLY-LA-POTERIE",
    value: "02718",
    type: "common",
  },
  {
    label: "LA VILLENEUVE-SOUS-THURY",
    value: "60679",
    type: "common",
  },
  {
    label: "IVORS",
    value: "60320",
    type: "common",
  },
  {
    label: "CUVERGNON",
    value: "60190",
    type: "common",
  },
  {
    label: "BOURSONNE",
    value: "60094",
    type: "common",
  },
  {
    label: "AUTHEUIL-EN-VALOIS",
    value: "60031",
    type: "common",
  },
  {
    label: "ORMOY-LE-DAVIEN",
    value: "60478",
    type: "common",
  },
  {
    label: "LEVIGNEN",
    value: "60358",
    type: "common",
  },
  {
    label: "BETZ",
    value: "60069",
    type: "common",
  },
  {
    label: "BARGNY",
    value: "60046",
    type: "common",
  },
  {
    label: "ANTILLY",
    value: "60020",
    type: "common",
  },
  {
    label: "ROUVILLE",
    value: "60552",
    type: "common",
  },
  {
    label: "PEROY-LES-GOMBRIES",
    value: "60489",
    type: "common",
  },
  {
    label: "ORMOY-VILLERS",
    value: "60479",
    type: "common",
  },
  {
    label: "BOISSY-FRESNOY",
    value: "60079",
    type: "common",
  },
  {
    label: "AUGER-SAINT-VINCENT",
    value: "60027",
    type: "common",
  },
  {
    label: "VERSIGNY",
    value: "60671",
    type: "common",
  },
  {
    label: "ROSIERES",
    value: "60546",
    type: "common",
  },
  {
    label: "FRESNOY-LE-LUAT",
    value: "60261",
    type: "common",
  },
  {
    label: "BARON",
    value: "60047",
    type: "common",
  },
  {
    label: "MONT-L'EVEQUE",
    value: "60421",
    type: "common",
  },
  {
    label: "BOREST",
    value: "60087",
    type: "common",
  },
  {
    label: "THIERS-SUR-THEVE",
    value: "60631",
    type: "common",
  },
  {
    label: "SENLIS",
    value: "60612",
    type: "common",
  },
  {
    label: "PONTARME",
    value: "60505",
    type: "common",
  },
  {
    label: "FONTAINE-CHAALIS",
    value: "60241",
    type: "common",
  },
  {
    label: "COURTEUIL",
    value: "60170",
    type: "common",
  },
  {
    label: "VINEUIL-SAINT-FIRMIN",
    value: "60695",
    type: "common",
  },
  {
    label: "CHANTILLY",
    value: "60141",
    type: "common",
  },
  {
    label: "AVILLY-SAINT-LEONARD",
    value: "60033",
    type: "common",
  },
  {
    label: "LAMORLAYE",
    value: "60346",
    type: "common",
  },
  {
    label: "GOUVIEUX",
    value: "60282",
    type: "common",
  },
  {
    label: "BRUYERES-SUR-OISE",
    value: "95116",
    type: "common",
  },
  {
    label: "BERNES-SUR-OISE",
    value: "95058",
    type: "common",
  },
  {
    label: "PRECY-SUR-OISE",
    value: "60513",
    type: "common",
  },
  {
    label: "MORANGLES",
    value: "60429",
    type: "common",
  },
  {
    label: "LE MESNIL-EN-THELLE",
    value: "60398",
    type: "common",
  },
  {
    label: "CROUY-EN-THELLE",
    value: "60185",
    type: "common",
  },
  {
    label: "BORAN-SUR-OISE",
    value: "60086",
    type: "common",
  },
  {
    label: "RONQUEROLLES",
    value: "95529",
    type: "common",
  },
  {
    label: "FRESNOY-EN-THELLE",
    value: "60259",
    type: "common",
  },
  {
    label: "CHAMBLY",
    value: "60139",
    type: "common",
  },
  {
    label: "BORNEL",
    value: "60088",
    type: "common",
  },
  {
    label: "BELLE-EGLISE",
    value: "60060",
    type: "common",
  },
  {
    label: "HEDOUVILLE",
    value: "95304",
    type: "common",
  },
  {
    label: "FROUVILLE",
    value: "95258",
    type: "common",
  },
  {
    label: "ARRONVILLE",
    value: "95023",
    type: "common",
  },
  {
    label: "AMBLAINVILLE",
    value: "60010",
    type: "common",
  },
  {
    label: "THEUVILLE",
    value: "95611",
    type: "common",
  },
  {
    label: "HARAVILLIERS",
    value: "95298",
    type: "common",
  },
  {
    label: "BERVILLE",
    value: "95059",
    type: "common",
  },
  {
    label: "NEUVILLE-BOSC",
    value: "60452",
    type: "common",
  },
  {
    label: "HENONVILLE",
    value: "60309",
    type: "common",
  },
  {
    label: "NEUILLY-EN-VEXIN",
    value: "95447",
    type: "common",
  },
  {
    label: "LE HEAULME",
    value: "95303",
    type: "common",
  },
  {
    label: "CHARS",
    value: "95142",
    type: "common",
  },
  {
    label: "MONNEVILLE",
    value: "60411",
    type: "common",
  },
  {
    label: "LAVILLETERTRE",
    value: "60356",
    type: "common",
  },
  {
    label: "CHAVENCON",
    value: "60144",
    type: "common",
  },
  {
    label: "NUCOURT",
    value: "95459",
    type: "common",
  },
  {
    label: "LIERVILLE",
    value: "60363",
    type: "common",
  },
  {
    label: "HADANCOURT-LE-HAUT-CLOCHER",
    value: "60293",
    type: "common",
  },
  {
    label: "BOUCONVILLERS",
    value: "60090",
    type: "common",
  },
  {
    label: "SAINT-GERVAIS",
    value: "95554",
    type: "common",
  },
  {
    label: "SERANS",
    value: "60614",
    type: "common",
  },
  {
    label: "PARNES",
    value: "60487",
    type: "common",
  },
  {
    label: "MONTAGNY-EN-VEXIN",
    value: "60412",
    type: "common",
  },
  {
    label: "MONTREUIL-SUR-EPTE",
    value: "95429",
    type: "common",
  },
  {
    label: "LA CHAPELLE-EN-VEXIN",
    value: "95139",
    type: "common",
  },
  {
    label: "BUHY",
    value: "95119",
    type: "common",
  },
  {
    label: "CHATEAU-SUR-EPTE",
    value: "27152",
    type: "common",
  },
  {
    label: "MEZIERES-EN-VEXIN",
    value: "27408",
    type: "common",
  },
  {
    label: "PORT-MORT",
    value: "27473",
    type: "common",
  },
  {
    label: "NOTRE-DAME-DE-L'ISLE",
    value: "27440",
    type: "common",
  },
  {
    label: "HENNEZIS",
    value: "27329",
    type: "common",
  },
  {
    label: "BOUAFLES",
    value: "27097",
    type: "common",
  },
  {
    label: "VILLERS-SUR-LE-ROULE",
    value: "27691",
    type: "common",
  },
  {
    label: "LES TROIS LACS",
    value: "27058",
    type: "common",
  },
  {
    label: "GAILLON",
    value: "27275",
    type: "common",
  },
  {
    label: "COURCELLES-SUR-SEINE",
    value: "27180",
    type: "common",
  },
  {
    label: "LE VAL D'HAZEY",
    value: "27022",
    type: "common",
  },
  {
    label: "VIRONVAY",
    value: "27697",
    type: "common",
  },
  {
    label: "HEUDEBOUVILLE",
    value: "27332",
    type: "common",
  },
  {
    label: "FONTAINE-BELLENGER",
    value: "27249",
    type: "common",
  },
  {
    label: "AILLY",
    value: "27005",
    type: "common",
  },
  {
    label: "PINTERVILLE",
    value: "27456",
    type: "common",
  },
  {
    label: "LE MESNIL-JOURDAIN",
    value: "27403",
    type: "common",
  },
  {
    label: "LA HAYE-LE-COMTE",
    value: "27321",
    type: "common",
  },
  {
    label: "AMFREVILLE-SUR-ITON",
    value: "27014",
    type: "common",
  },
  {
    label: "ACQUIGNY",
    value: "27003",
    type: "common",
  },
  {
    label: "VILLETTES",
    value: "27692",
    type: "common",
  },
  {
    label: "VENON",
    value: "27677",
    type: "common",
  },
  {
    label: "SURVILLE",
    value: "27624",
    type: "common",
  },
  {
    label: "SURTAUVILLE",
    value: "27623",
    type: "common",
  },
  {
    label: "QUATREMARE",
    value: "27483",
    type: "common",
  },
  {
    label: "DAUBEUF-LA-CAMPAGNE",
    value: "27201",
    type: "common",
  },
  {
    label: "CRASVILLE",
    value: "27184",
    type: "common",
  },
  {
    label: "CANAPPEVILLE",
    value: "27127",
    type: "common",
  },
  {
    label: "MARBEUF",
    value: "27389",
    type: "common",
  },
  {
    label: "IVILLE",
    value: "27354",
    type: "common",
  },
  {
    label: "HECTOMARE",
    value: "27327",
    type: "common",
  },
  {
    label: "ECQUETOT",
    value: "27215",
    type: "common",
  },
  {
    label: "CROSVILLE-LA-VIEILLE",
    value: "27192",
    type: "common",
  },
  {
    label: "CRIQUEBEUF-LA-CAMPAGNE",
    value: "27187",
    type: "common",
  },
  {
    label: "CRESTOT",
    value: "27185",
    type: "common",
  },
  {
    label: "CESSEVILLE",
    value: "27135",
    type: "common",
  },
  {
    label: "VITOT",
    value: "27698",
    type: "common",
  },
  {
    label: "LE TRONCQ",
    value: "27663",
    type: "common",
  },
  {
    label: "SAINTE-OPPORTUNE-DU-BOSC",
    value: "27576",
    type: "common",
  },
  {
    label: "LA PYLE",
    value: "27482",
    type: "common",
  },
  {
    label: "LE NEUBOURG",
    value: "27428",
    type: "common",
  },
  {
    label: "EPEGARD",
    value: "27219",
    type: "common",
  },
  {
    label: "ROUGE-PERRIERS",
    value: "27498",
    type: "common",
  },
  {
    label: "LA NEUVILLE-DU-BOSC",
    value: "27432",
    type: "common",
  },
  {
    label: "LA HAYE-DE-CALLEVILLE",
    value: "27318",
    type: "common",
  },
  {
    label: "HARCOURT",
    value: "27311",
    type: "common",
  },
  {
    label: "CALLEVILLE",
    value: "27125",
    type: "common",
  },
  {
    label: "SAINT-PIERRE-DE-SALERNE",
    value: "27592",
    type: "common",
  },
  {
    label: "SAINT-CYR-DE-SALERNE",
    value: "27527",
    type: "common",
  },
  {
    label: "HECMANVILLE",
    value: "27325",
    type: "common",
  },
  {
    label: "FRANQUEVILLE",
    value: "27266",
    type: "common",
  },
  {
    label: "BRIONNE",
    value: "27116",
    type: "common",
  },
  {
    label: "ACLOU",
    value: "27001",
    type: "common",
  },
  {
    label: "NOTRE-DAME-D'EPINE",
    value: "27441",
    type: "common",
  },
  {
    label: "MORSAN",
    value: "27418",
    type: "common",
  },
  {
    label: "GIVERVILLE",
    value: "27286",
    type: "common",
  },
  {
    label: "BOISSY-LAMBERVILLE",
    value: "27079",
    type: "common",
  },
  {
    label: "BOISNEY",
    value: "27074",
    type: "common",
  },
  {
    label: "BERTHOUVILLE",
    value: "27061",
    type: "common",
  },
  {
    label: "LE THEIL-NOLENT",
    value: "27627",
    type: "common",
  },
  {
    label: "HEUDREVILLE-EN-LIEUVIN",
    value: "27334",
    type: "common",
  },
  {
    label: "FOLLEVILLE",
    value: "27248",
    type: "common",
  },
  {
    label: "LE FAVRIL",
    value: "27237",
    type: "common",
  },
  {
    label: "DURANVILLE",
    value: "27208",
    type: "common",
  },
  {
    label: "BAZOQUES",
    value: "27046",
    type: "common",
  },
  {
    label: "BARVILLE",
    value: "27042",
    type: "common",
  },
  {
    label: "SAINT-AUBIN-DE-SCELLON",
    value: "27512",
    type: "common",
  },
  {
    label: "LES PLACES",
    value: "27459",
    type: "common",
  },
  {
    label: "PIENCOURT",
    value: "27455",
    type: "common",
  },
  {
    label: "FONTAINE-LA-LOUVET",
    value: "27252",
    type: "common",
  },
  {
    label: "BAILLEUL-LA-VALLEE",
    value: "27035",
    type: "common",
  },
  {
    label: "L'HOTELLERIE",
    value: "14334",
    type: "common",
  },
  {
    label: "OUILLY-DU-HOULEY",
    value: "14484",
    type: "common",
  },
  {
    label: "FUMICHON",
    value: "14293",
    type: "common",
  },
  {
    label: "FIRFOL",
    value: "14270",
    type: "common",
  },
  {
    label: "ROCQUES",
    value: "14540",
    type: "common",
  },
  {
    label: "OUILLY-LE-VICOMTE",
    value: "14487",
    type: "common",
  },
  {
    label: "LISIEUX",
    value: "14366",
    type: "common",
  },
  {
    label: "HERMIVAL-LES-VAUX",
    value: "14326",
    type: "common",
  },
  {
    label: "FAUGUERNON",
    value: "14260",
    type: "common",
  },
  {
    label: "SAINT-DESIR",
    value: "14574",
    type: "common",
  },
  {
    label: "LE PRE-D'AUGE",
    value: "14520",
    type: "common",
  },
  {
    label: "MANERBE",
    value: "14398",
    type: "common",
  },
  {
    label: "SAINT-OUEN-LE-PIN",
    value: "14639",
    type: "common",
  },
  {
    label: "LA ROQUE-BAIGNARD",
    value: "14541",
    type: "common",
  },
  {
    label: "MONTREUIL-EN-AUGE",
    value: "14448",
    type: "common",
  },
  {
    label: "LEAUPARTIE",
    value: "14358",
    type: "common",
  },
  {
    label: "VICTOT-PONTFOL",
    value: "14743",
    type: "common",
  },
  {
    label: "RUMESNIL",
    value: "14550",
    type: "common",
  },
  {
    label: "NOTRE-DAME-D'ESTREES-CORBON",
    value: "14474",
    type: "common",
  },
  {
    label: "GERROTS",
    value: "14300",
    type: "common",
  },
  {
    label: "SAINT-PIERRE-DU-JONQUET",
    value: "14651",
    type: "common",
  },
  {
    label: "SAINT-OUEN-DU-MESNIL-OGER",
    value: "14637",
    type: "common",
  },
  {
    label: "HOTOT-EN-AUGE",
    value: "14335",
    type: "common",
  },
  {
    label: "CLEVILLE",
    value: "14163",
    type: "common",
  },
  {
    label: "CANTELOUP",
    value: "14134",
    type: "common",
  },
  {
    label: "VIMONT",
    value: "14761",
    type: "common",
  },
  {
    label: "SAINT-PAIR",
    value: "14640",
    type: "common",
  },
  {
    label: "JANVILLE",
    value: "14344",
    type: "common",
  },
  {
    label: "EMIEVILLE",
    value: "14237",
    type: "common",
  },
  {
    label: "BANNEVILLE-LA-CAMPAGNE",
    value: "14036",
    type: "common",
  },
  {
    label: "ARGENCES",
    value: "14020",
    type: "common",
  },
  {
    label: "SOLIERS",
    value: "14675",
    type: "common",
  },
  {
    label: "MONDEVILLE",
    value: "14437",
    type: "common",
  },
  {
    label: "GRENTHEVILLE",
    value: "14319",
    type: "common",
  },
  {
    label: "GIBERVILLE",
    value: "14301",
    type: "common",
  },
  {
    label: "FRENOUVILLE",
    value: "14287",
    type: "common",
  },
  {
    label: "DEMOUVILLE",
    value: "14221",
    type: "common",
  },
  {
    label: "CORMELLES-LE-ROYAL",
    value: "14181",
    type: "common",
  },
  {
    label: "CAGNY",
    value: "14119",
    type: "common",
  },
  {
    label: "SAINT-ANDRE-SUR-ORNE",
    value: "14556",
    type: "common",
  },
  {
    label: "LOUVIGNY",
    value: "14383",
    type: "common",
  },
  {
    label: "IFS",
    value: "14341",
    type: "common",
  },
  {
    label: "FLEURY-SUR-ORNE",
    value: "14271",
    type: "common",
  },
  {
    label: "BRETTEVILLE-SUR-ODON",
    value: "14101",
    type: "common",
  },
  {
    label: "VERSON",
    value: "14738",
    type: "common",
  },
  {
    label: "TOURVILLE-SUR-ODON",
    value: "14707",
    type: "common",
  },
  {
    label: "MOUEN",
    value: "14454",
    type: "common",
  },
  {
    label: "MALTOT",
    value: "14396",
    type: "common",
  },
  {
    label: "FONTAINE-ETOUPEFOUR",
    value: "14274",
    type: "common",
  },
  {
    label: "ETERVILLE",
    value: "14254",
    type: "common",
  },
  {
    label: "BARON-SUR-ODON",
    value: "14042",
    type: "common",
  },
  {
    label: "VENDES",
    value: "14734",
    type: "common",
  },
  {
    label: "TESSEL",
    value: "14684",
    type: "common",
  },
  {
    label: "VAL D'ARRY",
    value: "14475",
    type: "common",
  },
  {
    label: "MONDRAINVILLE",
    value: "14438",
    type: "common",
  },
  {
    label: "GRAINVILLE-SUR-ODON",
    value: "14311",
    type: "common",
  },
  {
    label: "FONTENAY-LE-PESNEL",
    value: "14278",
    type: "common",
  },
  {
    label: "TILLY-SUR-SEULLES",
    value: "14692",
    type: "common",
  },
  {
    label: "SAINT-VAAST-SUR-SEULLES",
    value: "14661",
    type: "common",
  },
  {
    label: "MONTS-EN-BESSIN",
    value: "14449",
    type: "common",
  },
  {
    label: "LINGEVRES",
    value: "14364",
    type: "common",
  },
  {
    label: "JUVIGNY-SUR-SEULLES",
    value: "14348",
    type: "common",
  },
  {
    label: "HOTTOT-LES-BAGUES",
    value: "14336",
    type: "common",
  },
  {
    label: "CAHAGNOLLES",
    value: "14121",
    type: "common",
  },
  {
    label: "SAINTE-HONORINE-DE-DUCY",
    value: "14590",
    type: "common",
  },
  {
    label: "PLANQUERY",
    value: "14506",
    type: "common",
  },
  {
    label: "FOULOGNES",
    value: "14282",
    type: "common",
  },
  {
    label: "CORMOLAIN",
    value: "14182",
    type: "common",
  },
  {
    label: "SAINT-GERMAIN-D'ELLE",
    value: "50476",
    type: "common",
  },
  {
    label: "BERIGNY",
    value: "50046",
    type: "common",
  },
  {
    label: "LITTEAU",
    value: "14369",
    type: "common",
  },
  {
    label: "LA BAZOQUE",
    value: "14050",
    type: "common",
  },
  {
    label: "SAINT-PIERRE-DE-SEMILLY",
    value: "50538",
    type: "common",
  },
  {
    label: "SAINT-GEORGES-D'ELLE",
    value: "50473",
    type: "common",
  },
  {
    label: "SAINT-ANDRE-DE-L'EPINE",
    value: "50446",
    type: "common",
  },
  {
    label: "LA LUZERNE",
    value: "50283",
    type: "common",
  },
  {
    label: "COUVAINS",
    value: "50148",
    type: "common",
  },
  {
    label: "VILLIERS-FOSSARD",
    value: "50641",
    type: "common",
  },
  {
    label: "SAINT-GEORGES-MONTCOCQ",
    value: "50475",
    type: "common",
  },
  {
    label: "RAMPAN",
    value: "50423",
    type: "common",
  },
  {
    label: "LE MESNIL-ROUXELIN",
    value: "50321",
    type: "common",
  },
  {
    label: "AGNEAUX",
    value: "50002",
    type: "common",
  },
  {
    label: "LE MESNIL-AMEY",
    value: "50302",
    type: "common",
  },
  {
    label: "THEREVAL",
    value: "50239",
    type: "common",
  },
  {
    label: "AMIGNY",
    value: "50006",
    type: "common",
  },
  {
    label: "MONTREUIL-SUR-LOZON",
    value: "50352",
    type: "common",
  },
  {
    label: "LE MESNIL-EURY",
    value: "50310",
    type: "common",
  },
  {
    label: "HAUTEVILLE-LA-GUICHARD",
    value: "50232",
    type: "common",
  },
  {
    label: "FEUGERES",
    value: "50181",
    type: "common",
  },
  {
    label: "MONTCUIT",
    value: "50340",
    type: "common",
  },
  {
    label: "MUNEVILLE-LE-BINGARD",
    value: "50364",
    type: "common",
  },
  {
    label: "GOUVILLE-SUR-MER",
    value: "50215",
    type: "common",
  },
  {
    label: "GEFFOSSES",
    value: "50198",
    type: "common",
  },
  {
    label: "LAUTERBOURG",
    value: "67261",
    type: "common",
  },
  {
    label: "SCHEIBENHARD",
    value: "67443",
    type: "common",
  },
  {
    label: "NIEDERLAUTERBACH",
    value: "67327",
    type: "common",
  },
  {
    label: "SCHLEITHAL",
    value: "67451",
    type: "common",
  },
  {
    label: "SALMBACH",
    value: "67432",
    type: "common",
  },
  {
    label: "WISSEMBOURG",
    value: "67544",
    type: "common",
  },
  {
    label: "STEINSELTZ",
    value: "67479",
    type: "common",
  },
  {
    label: "RIEDSELTZ",
    value: "67400",
    type: "common",
  },
  {
    label: "SEEBACH",
    value: "67351",
    type: "common",
  },
  {
    label: "INGOLSHEIM",
    value: "67221",
    type: "common",
  },
  {
    label: "ROTT",
    value: "67416",
    type: "common",
  },
  {
    label: "OBERHOFFEN-LES-WISSEMBOURG",
    value: "67344",
    type: "common",
  },
  {
    label: "DRACHENBRONN-BIRLENBACH",
    value: "67104",
    type: "common",
  },
  {
    label: "CLIMBACH",
    value: "67075",
    type: "common",
  },
  {
    label: "CLEEBOURG",
    value: "67074",
    type: "common",
  },
  {
    label: "LEMBACH",
    value: "67263",
    type: "common",
  },
  {
    label: "LAMPERTSLOCH",
    value: "67257",
    type: "common",
  },
  {
    label: "WINDSTEIN",
    value: "67536",
    type: "common",
  },
  {
    label: "DAMBACH",
    value: "67083",
    type: "common",
  },
  {
    label: "PHILIPPSBOURG",
    value: "57541",
    type: "common",
  },
  {
    label: "EGUELSHARDT",
    value: "57188",
    type: "common",
  },
  {
    label: "REYERSVILLER",
    value: "57577",
    type: "common",
  },
  {
    label: "MOUTERHOUSE",
    value: "57489",
    type: "common",
  },
  {
    label: "BITCHE",
    value: "57089",
    type: "common",
  },
  {
    label: "MONTBRONN",
    value: "57477",
    type: "common",
  },
  {
    label: "LEMBERG",
    value: "57390",
    type: "common",
  },
  {
    label: "LAMBACH",
    value: "57376",
    type: "common",
  },
  {
    label: "ENCHENBERG",
    value: "57192",
    type: "common",
  },
  {
    label: "ROHRBACH-LES-BITCHE",
    value: "57589",
    type: "common",
  },
  {
    label: "RAHLING",
    value: "57561",
    type: "common",
  },
  {
    label: "BINING",
    value: "57083",
    type: "common",
  },
  {
    label: "OERMINGEN",
    value: "67355",
    type: "common",
  },
  {
    label: "WITTRING",
    value: "57748",
    type: "common",
  },
  {
    label: "SCHMITTVILLER",
    value: "57636",
    type: "common",
  },
  {
    label: "KALHAUSEN",
    value: "57355",
    type: "common",
  },
  {
    label: "ETTING",
    value: "57201",
    type: "common",
  },
  {
    label: "ACHEN",
    value: "57006",
    type: "common",
  },
  {
    label: "WILLERWALD",
    value: "57746",
    type: "common",
  },
  {
    label: "HERBITZHEIM",
    value: "67191",
    type: "common",
  },
  {
    label: "HAMBACH",
    value: "57289",
    type: "common",
  },
  {
    label: "RICHELING",
    value: "57581",
    type: "common",
  },
  {
    label: "HOLVING",
    value: "57330",
    type: "common",
  },
  {
    label: "ERNESTVILLER",
    value: "57197",
    type: "common",
  },
  {
    label: "GRUNDVILLER",
    value: "57263",
    type: "common",
  },
  {
    label: "REMERING-LES-PUTTELANGE",
    value: "57571",
    type: "common",
  },
  {
    label: "PUTTELANGE-AUX-LACS",
    value: "57556",
    type: "common",
  },
  {
    label: "SAINT-JEAN-ROHRBACH",
    value: "57615",
    type: "common",
  },
  {
    label: "LEYVILLER",
    value: "57398",
    type: "common",
  },
  {
    label: "HOSTE",
    value: "57337",
    type: "common",
  },
  {
    label: "MAXSTADT",
    value: "57453",
    type: "common",
  },
  {
    label: "LANING",
    value: "57384",
    type: "common",
  },
  {
    label: "BIDING",
    value: "57082",
    type: "common",
  },
  {
    label: "ALTRIPPE",
    value: "57014",
    type: "common",
  },
  {
    label: "VAHL-EBERSING",
    value: "57684",
    type: "common",
  },
  {
    label: "TETING-SUR-NIED",
    value: "57668",
    type: "common",
  },
  {
    label: "LIXING-LES-SAINT-AVOLD",
    value: "57409",
    type: "common",
  },
  {
    label: "LELLING",
    value: "57389",
    type: "common",
  },
  {
    label: "GUESSLING-HEMERING",
    value: "57275",
    type: "common",
  },
  {
    label: "FOLSCHVILLER",
    value: "57224",
    type: "common",
  },
  {
    label: "ALTVILLER",
    value: "57015",
    type: "common",
  },
  {
    label: "TRITTELING-REDLACH",
    value: "57679",
    type: "common",
  },
  {
    label: "VAHL-LES-FAULQUEMONT",
    value: "57686",
    type: "common",
  },
  {
    label: "PONTPIERRE",
    value: "57549",
    type: "common",
  },
  {
    label: "FAULQUEMONT",
    value: "57209",
    type: "common",
  },
  {
    label: "MAINVILLERS",
    value: "57430",
    type: "common",
  },
  {
    label: "HEMILLY",
    value: "57313",
    type: "common",
  },
  {
    label: "GUINGLANGE",
    value: "57276",
    type: "common",
  },
  {
    label: "FLETRANGE",
    value: "57217",
    type: "common",
  },
  {
    label: "ELVANGE",
    value: "57190",
    type: "common",
  },
  {
    label: "CREHANGE",
    value: "57159",
    type: "common",
  },
  {
    label: "VITTONCOURT",
    value: "57726",
    type: "common",
  },
  {
    label: "VILLERS-STONCOURT",
    value: "57718",
    type: "common",
  },
  {
    label: "SERVIGNY-LES-RAVILLE",
    value: "57648",
    type: "common",
  },
  {
    label: "CHANVILLE",
    value: "57127",
    type: "common",
  },
  {
    label: "ANCERVILLE",
    value: "57020",
    type: "common",
  },
  {
    label: "SORBEY",
    value: "57656",
    type: "common",
  },
  {
    label: "SANRY-SUR-NIED",
    value: "57627",
    type: "common",
  },
  {
    label: "PANGE",
    value: "57533",
    type: "common",
  },
  {
    label: "MAIZEROY",
    value: "57431",
    type: "common",
  },
  {
    label: "LEMUD",
    value: "57392",
    type: "common",
  },
  {
    label: "LAQUENEXY",
    value: "57385",
    type: "common",
  },
  {
    label: "COURCELLES-SUR-NIED",
    value: "57156",
    type: "common",
  },
  {
    label: "BAZONCOURT",
    value: "57055",
    type: "common",
  },
  {
    label: "PELTRE",
    value: "57534",
    type: "common",
  },
  {
    label: "MECLEUVES",
    value: "57454",
    type: "common",
  },
  {
    label: "JURY",
    value: "57351",
    type: "common",
  },
  {
    label: "CHESNY",
    value: "57140",
    type: "common",
  },
  {
    label: "ARS-LAQUENEXY",
    value: "57031",
    type: "common",
  },
  {
    label: "POUILLY",
    value: "57552",
    type: "common",
  },
  {
    label: "MONTIGNY-LES-METZ",
    value: "57480",
    type: "common",
  },
  {
    label: "MARLY",
    value: "57447",
    type: "common",
  },
  {
    label: "FLEURY",
    value: "57218",
    type: "common",
  },
  {
    label: "CUVRY",
    value: "57162",
    type: "common",
  },
  {
    label: "VAUX",
    value: "57701",
    type: "common",
  },
  {
    label: "MOULINS-LES-METZ",
    value: "57487",
    type: "common",
  },
  {
    label: "JOUY-AUX-ARCHES",
    value: "57350",
    type: "common",
  },
  {
    label: "AUGNY",
    value: "57039",
    type: "common",
  },
  {
    label: "ARS-SUR-MOSELLE",
    value: "57032",
    type: "common",
  },
  {
    label: "ANCY-DORNOT",
    value: "57021",
    type: "common",
  },
  {
    label: "NOVEANT-SUR-MOSELLE",
    value: "57515",
    type: "common",
  },
  {
    label: "GORZE",
    value: "57254",
    type: "common",
  },
  {
    label: "TRONVILLE",
    value: "54535",
    type: "common",
  },
  {
    label: "PUXIEUX",
    value: "54441",
    type: "common",
  },
  {
    label: "MARS-LA-TOUR",
    value: "54353",
    type: "common",
  },
  {
    label: "CHAMBLEY-BUSSIERES",
    value: "54112",
    type: "common",
  },
  {
    label: "LATOUR-EN-WOEVRE",
    value: "55281",
    type: "common",
  },
  {
    label: "LABEUVILLE",
    value: "55265",
    type: "common",
  },
  {
    label: "JONVILLE-EN-WOEVRE",
    value: "55256",
    type: "common",
  },
  {
    label: "XONVILLE",
    value: "54599",
    type: "common",
  },
  {
    label: "SPONVILLE",
    value: "54511",
    type: "common",
  },
  {
    label: "HANNONVILLE-SUZEMONT",
    value: "54249",
    type: "common",
  },
  {
    label: "SAINT-HILAIRE-EN-WOEVRE",
    value: "55457",
    type: "common",
  },
  {
    label: "MOULOTTE",
    value: "55363",
    type: "common",
  },
  {
    label: "MAIZERAY",
    value: "55311",
    type: "common",
  },
  {
    label: "HARVILLE",
    value: "55232",
    type: "common",
  },
  {
    label: "DONCOURT-AUX-TEMPLIERS",
    value: "55163",
    type: "common",
  },
  {
    label: "TRESAUVAUX",
    value: "55515",
    type: "common",
  },
  {
    label: "SAULX-LES-CHAMPLON",
    value: "55473",
    type: "common",
  },
  {
    label: "RIAVILLE",
    value: "55429",
    type: "common",
  },
  {
    label: "MARCHEVILLE-EN-WOEVRE",
    value: "55320",
    type: "common",
  },
  {
    label: "FRESNES-EN-WOEVRE",
    value: "55198",
    type: "common",
  },
  {
    label: "COMBRES-SOUS-LES-COTES",
    value: "55121",
    type: "common",
  },
  {
    label: "RUPT-EN-WOEVRE",
    value: "55449",
    type: "common",
  },
  {
    label: "LES EPARGES",
    value: "55172",
    type: "common",
  },
  {
    label: "BONZEE",
    value: "55060",
    type: "common",
  },
  {
    label: "SOMMEDIEUE",
    value: "55492",
    type: "common",
  },
  {
    label: "DIEUE-SUR-MEUSE",
    value: "55154",
    type: "common",
  },
  {
    label: "SENONCOURT-LES-MAUJOUY",
    value: "55482",
    type: "common",
  },
  {
    label: "LANDRECOURT-LEMPIRE",
    value: "55276",
    type: "common",
  },
  {
    label: "DUGNY-SUR-MEUSE",
    value: "55166",
    type: "common",
  },
  {
    label: "ANCEMONT",
    value: "55009",
    type: "common",
  },
  {
    label: "VADELAINCOURT",
    value: "55525",
    type: "common",
  },
  {
    label: "LES SOUHESMES-RAMPONT",
    value: "55497",
    type: "common",
  },
  {
    label: "NIXEVILLE-BLERCOURT",
    value: "55385",
    type: "common",
  },
  {
    label: "LEMMES",
    value: "55286",
    type: "common",
  },
  {
    label: "BROCOURT-EN-ARGONNE",
    value: "55082",
    type: "common",
  },
  {
    label: "VILLE-SUR-COUSANCES",
    value: "55567",
    type: "common",
  },
  {
    label: "RARECOURT",
    value: "55416",
    type: "common",
  },
  {
    label: "CLERMONT-EN-ARGONNE",
    value: "55117",
    type: "common",
  },
  {
    label: "LES ISLETTES",
    value: "55253",
    type: "common",
  },
  {
    label: "FUTEAU",
    value: "55202",
    type: "common",
  },
  {
    label: "SAINTE-MENEHOULD",
    value: "51507",
    type: "common",
  },
  {
    label: "DOMMARTIN-SOUS-HANS",
    value: "51213",
    type: "common",
  },
  {
    label: "BRAUX-SAINTE-COHIERE",
    value: "51082",
    type: "common",
  },
  {
    label: "MAFFRECOURT",
    value: "51336",
    type: "common",
  },
  {
    label: "CHAUDEFONTAINE",
    value: "51139",
    type: "common",
  },
  {
    label: "HANS",
    value: "51283",
    type: "common",
  },
  {
    label: "VALMY",
    value: "51588",
    type: "common",
  },
  {
    label: "SOMME-BIONNE",
    value: "51543",
    type: "common",
  },
  {
    label: "SAINT-JEAN-SUR-TOURBE",
    value: "51491",
    type: "common",
  },
  {
    label: "SOMME-TOURBE",
    value: "51547",
    type: "common",
  },
  {
    label: "SOMME-SUIPPE",
    value: "51546",
    type: "common",
  },
  {
    label: "SUIPPES",
    value: "51559",
    type: "common",
  },
  {
    label: "JONCHERY-SUR-SUIPPE",
    value: "51307",
    type: "common",
  },
  {
    label: "BOUY",
    value: "51078",
    type: "common",
  },
  {
    label: "VADENAY",
    value: "51587",
    type: "common",
  },
  {
    label: "MOURMELON-LE-GRAND",
    value: "51388",
    type: "common",
  },
  {
    label: "MOURMELON-LE-PETIT",
    value: "51389",
    type: "common",
  },
  {
    label: "LIVRY-LOUVERCY",
    value: "51326",
    type: "common",
  },
  {
    label: "VAUDEMANGE",
    value: "51599",
    type: "common",
  },
  {
    label: "BILLY-LE-GRAND",
    value: "51061",
    type: "common",
  },
  {
    label: "VILLERS-MARMERY",
    value: "51636",
    type: "common",
  },
  {
    label: "TREPAIL",
    value: "51580",
    type: "common",
  },
  {
    label: "LES PETITES-LOGES",
    value: "51428",
    type: "common",
  },
  {
    label: "VAL DE LIVRE",
    value: "51564",
    type: "common",
  },
  {
    label: "FONTAINE-SUR-AY",
    value: "51256",
    type: "common",
  },
  {
    label: "BOUZY",
    value: "51079",
    type: "common",
  },
  {
    label: "VILLE-EN-SELVE",
    value: "51623",
    type: "common",
  },
  {
    label: "SAINT-IMOGES",
    value: "51488",
    type: "common",
  },
  {
    label: "GERMAINE",
    value: "51266",
    type: "common",
  },
  {
    label: "COURTAGNON",
    value: "51190",
    type: "common",
  },
  {
    label: "NANTEUIL-LA-FORET",
    value: "51393",
    type: "common",
  },
  {
    label: "HAUTVILLERS",
    value: "51287",
    type: "common",
  },
  {
    label: "ROMERY",
    value: "51465",
    type: "common",
  },
  {
    label: "CORMOYEUX",
    value: "51173",
    type: "common",
  },
  {
    label: "LA NEUVILLE-AUX-LARRIS",
    value: "51398",
    type: "common",
  },
  {
    label: "BELVAL-SOUS-CHATILLON",
    value: "51048",
    type: "common",
  },
  {
    label: "VILLERS-SOUS-CHATILLON",
    value: "51637",
    type: "common",
  },
  {
    label: "REUIL",
    value: "51457",
    type: "common",
  },
  {
    label: "CUCHERY",
    value: "51199",
    type: "common",
  },
  {
    label: "FLEURY-LA-RIVIERE",
    value: "51252",
    type: "common",
  },
  {
    label: "BINSON-ET-ORQUIGNY",
    value: "51063",
    type: "common",
  },
  {
    label: "ANTHENAY",
    value: "51012",
    type: "common",
  },
  {
    label: "VANDIERES",
    value: "51592",
    type: "common",
  },
  {
    label: "CHATILLON-SUR-MARNE",
    value: "51136",
    type: "common",
  },
  {
    label: "BASLIEUX-SOUS-CHATILLON",
    value: "51038",
    type: "common",
  },
  {
    label: "SAINTE-GEMME",
    value: "51480",
    type: "common",
  },
  {
    label: "PASSY-GRIGNY",
    value: "51425",
    type: "common",
  },
  {
    label: "VINCELLES",
    value: "51644",
    type: "common",
  },
  {
    label: "VERNEUIL",
    value: "51609",
    type: "common",
  },
  {
    label: "CHAMPVOISY",
    value: "51121",
    type: "common",
  },
  {
    label: "FRESNES-EN-TARDENOIS",
    value: "02332",
    type: "common",
  },
  {
    label: "COURMONT",
    value: "02227",
    type: "common",
  },
  {
    label: "LE CHARMEL",
    value: "02164",
    type: "common",
  },
  {
    label: "BARZY-SUR-MARNE",
    value: "02051",
    type: "common",
  },
  {
    label: "TRELOU-SUR-MARNE",
    value: "02748",
    type: "common",
  },
  {
    label: "RONCHERES",
    value: "02655",
    type: "common",
  },
  {
    label: "EPIEDS",
    value: "02280",
    type: "common",
  },
  {
    label: "CHARTEVES",
    value: "02166",
    type: "common",
  },
  {
    label: "BEUVARDES",
    value: "02083",
    type: "common",
  },
  {
    label: "GRISOLLES",
    value: "02356",
    type: "common",
  },
  {
    label: "BRECY",
    value: "02119",
    type: "common",
  },
  {
    label: "BEZU-SAINT-GERMAIN",
    value: "02085",
    type: "common",
  },
  {
    label: "ROCOURT-SAINT-MARTIN",
    value: "02649",
    type: "common",
  },
  {
    label: "LICY-CLIGNON",
    value: "02428",
    type: "common",
  },
  {
    label: "EPAUX-BEZU",
    value: "02279",
    type: "common",
  },
  {
    label: "COURCHAMPS",
    value: "02225",
    type: "common",
  },
  {
    label: "BONNESVALYN",
    value: "02099",
    type: "common",
  },
  {
    label: "SOMMELANS",
    value: "02724",
    type: "common",
  },
  {
    label: "PRIEZ",
    value: "02622",
    type: "common",
  },
  {
    label: "MONTHIERS",
    value: "02509",
    type: "common",
  },
  {
    label: "HAUTEVESNES",
    value: "02375",
    type: "common",
  },
  {
    label: "DAMMARD",
    value: "02258",
    type: "common",
  },
  {
    label: "CHEZY-EN-ORXOIS",
    value: "02185",
    type: "common",
  },
  {
    label: "SAINT-GENGOULPH",
    value: "02679",
    type: "common",
  },
  {
    label: "MONNES",
    value: "02496",
    type: "common",
  },
  {
    label: "BRUMETZ",
    value: "02125",
    type: "common",
  },
  {
    label: "MAROLLES",
    value: "60385",
    type: "common",
  },
  {
    label: "MONTIGNY-L'ALLIER",
    value: "02512",
    type: "common",
  },
  {
    label: "ROUVRES-EN-MULTIEN",
    value: "60554",
    type: "common",
  },
  {
    label: "VARINFROY",
    value: "60656",
    type: "common",
  },
  {
    label: "THURY-EN-VALOIS",
    value: "60637",
    type: "common",
  },
  {
    label: "ROSOY-EN-MULTIEN",
    value: "60548",
    type: "common",
  },
  {
    label: "NEUFCHELLES",
    value: "60448",
    type: "common",
  },
  {
    label: "MAREUIL-SUR-OURCQ",
    value: "60380",
    type: "common",
  },
  {
    label: "BOULLARRE",
    value: "60092",
    type: "common",
  },
  {
    label: "VILLERS-SAINT-GENEST",
    value: "60683",
    type: "common",
  },
  {
    label: "ETAVIGNY",
    value: "60224",
    type: "common",
  },
  {
    label: "BOUILLANCY",
    value: "60091",
    type: "common",
  },
  {
    label: "ACY-EN-MULTIEN",
    value: "60005",
    type: "common",
  },
  {
    label: "OGNES",
    value: "60473",
    type: "common",
  },
  {
    label: "NANTEUIL-LE-HAUDOUIN",
    value: "60446",
    type: "common",
  },
  {
    label: "CHEVREVILLE",
    value: "60148",
    type: "common",
  },
  {
    label: "SILLY-LE-LONG",
    value: "60619",
    type: "common",
  },
  {
    label: "LE PLESSIS-BELLEVILLE",
    value: "60500",
    type: "common",
  },
  {
    label: "MONTAGNY-SAINTE-FELICITE",
    value: "60413",
    type: "common",
  },
  {
    label: "VER-SUR-LAUNETTE",
    value: "60666",
    type: "common",
  },
  {
    label: "MONTLOGNON",
    value: "60422",
    type: "common",
  },
  {
    label: "ERMENONVILLE",
    value: "60213",
    type: "common",
  },
  {
    label: "SURVILLIERS",
    value: "95604",
    type: "common",
  },
  {
    label: "PLAILLY",
    value: "60494",
    type: "common",
  },
  {
    label: "MORTEFONTAINE",
    value: "60432",
    type: "common",
  },
  {
    label: "FOSSES",
    value: "95250",
    type: "common",
  },
  {
    label: "ORRY-LA-VILLE",
    value: "60482",
    type: "common",
  },
  {
    label: "COYE-LA-FORET",
    value: "60172",
    type: "common",
  },
  {
    label: "LA CHAPELLE-EN-SERVAL",
    value: "60142",
    type: "common",
  },
  {
    label: "ASNIERES-SUR-OISE",
    value: "95026",
    type: "common",
  },
  {
    label: "SEUGY",
    value: "95594",
    type: "common",
  },
  {
    label: "VIARMES",
    value: "95652",
    type: "common",
  },
  {
    label: "LUZARCHES",
    value: "95352",
    type: "common",
  },
  {
    label: "CHAUMONTEL",
    value: "95149",
    type: "common",
  },
  {
    label: "SAINT-MARTIN-DU-TERTRE",
    value: "95566",
    type: "common",
  },
  {
    label: "NOISY-SUR-OISE",
    value: "95456",
    type: "common",
  },
  {
    label: "PRESLES",
    value: "95504",
    type: "common",
  },
  {
    label: "NOINTEL",
    value: "95452",
    type: "common",
  },
  {
    label: "BEAUMONT-SUR-OISE",
    value: "95052",
    type: "common",
  },
  {
    label: "PERSAN",
    value: "95487",
    type: "common",
  },
  {
    label: "PARMAIN",
    value: "95480",
    type: "common",
  },
  {
    label: "MOURS",
    value: "95436",
    type: "common",
  },
  {
    label: "L'ISLE-ADAM",
    value: "95313",
    type: "common",
  },
  {
    label: "CHAMPAGNE-SUR-OISE",
    value: "95134",
    type: "common",
  },
  {
    label: "VALMONDOIS",
    value: "95628",
    type: "common",
  },
  {
    label: "VALLANGOUJARD",
    value: "95627",
    type: "common",
  },
  {
    label: "NESLES-LA-VALLEE",
    value: "95446",
    type: "common",
  },
  {
    label: "MENOUVILLE",
    value: "95387",
    type: "common",
  },
  {
    label: "LABBEVILLE",
    value: "95328",
    type: "common",
  },
  {
    label: "HEROUVILLE-EN-VEXIN",
    value: "95308",
    type: "common",
  },
  {
    label: "LIVILLIERS",
    value: "95341",
    type: "common",
  },
  {
    label: "GRISY-LES-PLATRES",
    value: "95287",
    type: "common",
  },
  {
    label: "GENICOURT",
    value: "95271",
    type: "common",
  },
  {
    label: "EPIAIS-RHUS",
    value: "95213",
    type: "common",
  },
  {
    label: "CORMEILLES-EN-VEXIN",
    value: "95177",
    type: "common",
  },
  {
    label: "BREANCON",
    value: "95102",
    type: "common",
  },
  {
    label: "US",
    value: "95625",
    type: "common",
  },
  {
    label: "SANTEUIL",
    value: "95584",
    type: "common",
  },
  {
    label: "LE PERCHAY",
    value: "95483",
    type: "common",
  },
  {
    label: "MARINES",
    value: "95370",
    type: "common",
  },
  {
    label: "FREMECOURT",
    value: "95254",
    type: "common",
  },
  {
    label: "BRIGNANCOURT",
    value: "95110",
    type: "common",
  },
  {
    label: "WY-DIT-JOLI-VILLAGE",
    value: "95690",
    type: "common",
  },
  {
    label: "MOUSSY",
    value: "95438",
    type: "common",
  },
  {
    label: "GUIRY-EN-VEXIN",
    value: "95295",
    type: "common",
  },
  {
    label: "GOUZANGREZ",
    value: "95282",
    type: "common",
  },
  {
    label: "COMMENY",
    value: "95169",
    type: "common",
  },
  {
    label: "CLERY-EN-VEXIN",
    value: "95166",
    type: "common",
  },
  {
    label: "LE BELLAY-EN-VEXIN",
    value: "95054",
    type: "common",
  },
  {
    label: "MAUDETOUR-EN-VEXIN",
    value: "95379",
    type: "common",
  },
  {
    label: "MAGNY-EN-VEXIN",
    value: "95355",
    type: "common",
  },
  {
    label: "HODENT",
    value: "95309",
    type: "common",
  },
  {
    label: "GENAINVILLE",
    value: "95270",
    type: "common",
  },
  {
    label: "CHARMONT",
    value: "95141",
    type: "common",
  },
  {
    label: "BANTHELU",
    value: "95046",
    type: "common",
  },
  {
    label: "ARTHIES",
    value: "95024",
    type: "common",
  },
  {
    label: "OMERVILLE",
    value: "95462",
    type: "common",
  },
  {
    label: "CHAUSSY",
    value: "95150",
    type: "common",
  },
  {
    label: "BRAY-ET-LU",
    value: "95101",
    type: "common",
  },
  {
    label: "AMENUCOURT",
    value: "95012",
    type: "common",
  },
  {
    label: "AMBLEVILLE",
    value: "95011",
    type: "common",
  },
  {
    label: "HEUBECOURT-HARICOURT",
    value: "27331",
    type: "common",
  },
  {
    label: "GASNY",
    value: "27279",
    type: "common",
  },
  {
    label: "VEXIN-SUR-EPTE",
    value: "27213",
    type: "common",
  },
  {
    label: "TILLY",
    value: "27644",
    type: "common",
  },
  {
    label: "PRESSAGNY-L'ORGUEILLEUX",
    value: "27477",
    type: "common",
  },
  {
    label: "BOIS-JEROME-SAINT-OUEN",
    value: "27072",
    type: "common",
  },
  {
    label: "SAINT-PIERRE-LA-GARENNE",
    value: "27599",
    type: "common",
  },
  {
    label: "SAINT-PIERRE-DE-BAILLEUL",
    value: "27589",
    type: "common",
  },
  {
    label: "SAINT-MARCEL",
    value: "27562",
    type: "common",
  },
  {
    label: "LA CHAPELLE-LONGUEVILLE",
    value: "27554",
    type: "common",
  },
  {
    label: "SAINT-ETIENNE-SOUS-BAILLEUL",
    value: "27539",
    type: "common",
  },
  {
    label: "VILLEZ-SOUS-BAILLEUL",
    value: "27694",
    type: "common",
  },
  {
    label: "SAINTE-COLOMBE-PRES-VERNON",
    value: "27525",
    type: "common",
  },
  {
    label: "SAINT-AUBIN-SUR-GAILLON",
    value: "27517",
    type: "common",
  },
  {
    label: "CHAMPENARD",
    value: "27142",
    type: "common",
  },
  {
    label: "AUTHEUIL-AUTHOUILLET",
    value: "27025",
    type: "common",
  },
  {
    label: "SAINT-JULIEN-DE-LA-LIEGUE",
    value: "27553",
    type: "common",
  },
  {
    label: "IRREVILLE",
    value: "27353",
    type: "common",
  },
  {
    label: "CLEF VALLEE D'EURE",
    value: "27191",
    type: "common",
  },
  {
    label: "CAILLY-SUR-EURE",
    value: "27124",
    type: "common",
  },
  {
    label: "LA VACHERIE",
    value: "27666",
    type: "common",
  },
  {
    label: "TOURNEVILLE",
    value: "27652",
    type: "common",
  },
  {
    label: "HONDOUVILLE",
    value: "27339",
    type: "common",
  },
  {
    label: "HEUDREVILLE-SUR-EURE",
    value: "27335",
    type: "common",
  },
  {
    label: "EMALLEVILLE",
    value: "27216",
    type: "common",
  },
  {
    label: "LA CHAPELLE-DU-BOIS-DES-FAULX",
    value: "27147",
    type: "common",
  },
  {
    label: "HOUETTEVILLE",
    value: "27342",
    type: "common",
  },
  {
    label: "FEUGUEROLLES",
    value: "27241",
    type: "common",
  },
  {
    label: "BROSVILLE",
    value: "27118",
    type: "common",
  },
  {
    label: "BERENGEVILLE-LA-CAMPAGNE",
    value: "27055",
    type: "common",
  },
  {
    label: "BACQUEPUIS",
    value: "27033",
    type: "common",
  },
  {
    label: "SAINTE-COLOMBE-LA-COMMANDERIE",
    value: "27524",
    type: "common",
  },
  {
    label: "SAINT-AUBIN-D'ECROSVILLE",
    value: "27511",
    type: "common",
  },
  {
    label: "QUITTEBEUF",
    value: "27486",
    type: "common",
  },
  {
    label: "GRAVERON-SEMERVILLE",
    value: "27298",
    type: "common",
  },
  {
    label: "ECAUVILLE",
    value: "27212",
    type: "common",
  },
  {
    label: "VILLEZ-SUR-LE-NEUBOURG",
    value: "27695",
    type: "common",
  },
  {
    label: "LE TREMBLAY-OMONVILLE",
    value: "27658",
    type: "common",
  },
  {
    label: "EPREVILLE-PRES-LE-NEUBOURG",
    value: "27224",
    type: "common",
  },
  {
    label: "COMBON",
    value: "27164",
    type: "common",
  },
  {
    label: "BRAY",
    value: "27109",
    type: "common",
  },
  {
    label: "THIBOUVILLE",
    value: "27630",
    type: "common",
  },
  {
    label: "GOUPIL-OTHON",
    value: "27290",
    type: "common",
  },
  {
    label: "ECARDENVILLE-LA-CAMPAGNE",
    value: "27210",
    type: "common",
  },
  {
    label: "BEAUMONTEL",
    value: "27050",
    type: "common",
  },
  {
    label: "SERQUIGNY",
    value: "27622",
    type: "common",
  },
  {
    label: "SAINT-LEGER-DE-ROTES",
    value: "27557",
    type: "common",
  },
  {
    label: "NASSANDRES SUR RISLE",
    value: "27425",
    type: "common",
  },
  {
    label: "LAUNAY",
    value: "27364",
    type: "common",
  },
  {
    label: "FONTAINE-L'ABBE",
    value: "27251",
    type: "common",
  },
  {
    label: "VALAILLES",
    value: "27667",
    type: "common",
  },
  {
    label: "PLASNES",
    value: "27463",
    type: "common",
  },
  {
    label: "MENNEVAL",
    value: "27398",
    type: "common",
  },
  {
    label: "COURBEPINE",
    value: "27179",
    type: "common",
  },
  {
    label: "BERNAY",
    value: "27056",
    type: "common",
  },
  {
    label: "SAINT-VINCENT-DU-BOULAY",
    value: "27613",
    type: "common",
  },
  {
    label: "SAINT-MARTIN-DU-TILLEUL",
    value: "27569",
    type: "common",
  },
  {
    label: "MALOUY",
    value: "27381",
    type: "common",
  },
  {
    label: "BOURNAINVILLE-FAVEROLLES",
    value: "27106",
    type: "common",
  },
  {
    label: "THIBERVILLE",
    value: "27629",
    type: "common",
  },
  {
    label: "SAINT-MARDS-DE-FRESNE",
    value: "27564",
    type: "common",
  },
  {
    label: "LE PLANQUAY",
    value: "27462",
    type: "common",
  },
  {
    label: "DRUCOURT",
    value: "27207",
    type: "common",
  },
  {
    label: "LA CHAPELLE-HARENG",
    value: "27149",
    type: "common",
  },
  {
    label: "SAINT-DENIS-DE-MAILLOC",
    value: "14571",
    type: "common",
  },
  {
    label: "MAROLLES",
    value: "14403",
    type: "common",
  },
  {
    label: "COURTONNE-LES-DEUX-EGLISES",
    value: "14194",
    type: "common",
  },
  {
    label: "COURTONNE-LA-MEURDRAC",
    value: "14193",
    type: "common",
  },
  {
    label: "CORDEBUGLE",
    value: "14179",
    type: "common",
  },
  {
    label: "SAINT-JEAN-DE-LIVET",
    value: "14595",
    type: "common",
  },
  {
    label: "SAINT-MARTIN-DE-MAILLOC",
    value: "14626",
    type: "common",
  },
  {
    label: "SAINT-MARTIN-DE-LA-LIEUE",
    value: "14625",
    type: "common",
  },
  {
    label: "LE MESNIL-GUILLAUME",
    value: "14421",
    type: "common",
  },
  {
    label: "GLOS",
    value: "14303",
    type: "common",
  },
  {
    label: "BEUVILLERS",
    value: "14069",
    type: "common",
  },
  {
    label: "SAINT-PIERRE-DES-IFS",
    value: "14648",
    type: "common",
  },
  {
    label: "LES MONCEAUX",
    value: "14435",
    type: "common",
  },
  {
    label: "LE MESNIL-SIMON",
    value: "14425",
    type: "common",
  },
  {
    label: "LE MESNIL-EUDES",
    value: "14419",
    type: "common",
  },
  {
    label: "LA BOISSIERE",
    value: "14082",
    type: "common",
  },
  {
    label: "LA HOUBLONNIERE",
    value: "14337",
    type: "common",
  },
  {
    label: "NOTRE-DAME-DE-LIVAYE",
    value: "14473",
    type: "common",
  },
  {
    label: "BELLE VIE EN AUGE",
    value: "14527",
    type: "common",
  },
  {
    label: "MEZIDON VALLEE D'AUGE",
    value: "14431",
    type: "common",
  },
  {
    label: "MERY-BISSIERES-EN-AUGE",
    value: "14410",
    type: "common",
  },
  {
    label: "MOULT-CHICHEBOVILLE",
    value: "14456",
    type: "common",
  },
  {
    label: "BELLENGREVILLE",
    value: "14057",
    type: "common",
  },
  {
    label: "VALAMBRAY",
    value: "14005",
    type: "common",
  },
  {
    label: "BOURGUEBUS",
    value: "14092",
    type: "common",
  },
  {
    label: "SAINT-MARTIN-DE-FONTENAY",
    value: "14623",
    type: "common",
  },
  {
    label: "MAY-SUR-ORNE",
    value: "14408",
    type: "common",
  },
  {
    label: "LAIZE-CLINCHAMPS",
    value: "14349",
    type: "common",
  },
  {
    label: "FRESNEY-LE-PUCEUX",
    value: "14290",
    type: "common",
  },
  {
    label: "FONTENAY-LE-MARMION",
    value: "14277",
    type: "common",
  },
  {
    label: "FEUGUEROLLES-BULLY",
    value: "14266",
    type: "common",
  },
  {
    label: "VIEUX",
    value: "14747",
    type: "common",
  },
  {
    label: "SAINTE-HONORINE-DU-FAY",
    value: "14592",
    type: "common",
  },
  {
    label: "MAIZET",
    value: "14393",
    type: "common",
  },
  {
    label: "GAVRUS",
    value: "14297",
    type: "common",
  },
  {
    label: "EVRECY",
    value: "14257",
    type: "common",
  },
  {
    label: "ESQUAY-NOTRE-DAME",
    value: "14249",
    type: "common",
  },
  {
    label: "AVENAY",
    value: "14034",
    type: "common",
  },
  {
    label: "AMAYE-SUR-ORNE",
    value: "14006",
    type: "common",
  },
  {
    label: "VACOGNES-NEUILLY",
    value: "14721",
    type: "common",
  },
  {
    label: "LANDES-SUR-AJON",
    value: "14353",
    type: "common",
  },
  {
    label: "BOUGY",
    value: "14089",
    type: "common",
  },
  {
    label: "MALHERBE-SUR-AJON",
    value: "14037",
    type: "common",
  },
  {
    label: "VILLY-BOCAGE",
    value: "14760",
    type: "common",
  },
  {
    label: "VILLERS-BOCAGE",
    value: "14752",
    type: "common",
  },
  {
    label: "SAINT-LOUET-SUR-SEULLES",
    value: "14607",
    type: "common",
  },
  {
    label: "PARFOURU-SUR-ODON",
    value: "14491",
    type: "common",
  },
  {
    label: "MAISONCELLES-PELVEY",
    value: "14389",
    type: "common",
  },
  {
    label: "EPINAY-SUR-ODON",
    value: "14241",
    type: "common",
  },
  {
    label: "TRACY-BOCAGE",
    value: "14708",
    type: "common",
  },
  {
    label: "CAHAGNES",
    value: "14120",
    type: "common",
  },
  {
    label: "AURSEULLES",
    value: "14011",
    type: "common",
  },
  {
    label: "AMAYE-SUR-SEULLES",
    value: "14007",
    type: "common",
  },
  {
    label: "VAL DE DROME",
    value: "14672",
    type: "common",
  },
  {
    label: "SALLEN",
    value: "14664",
    type: "common",
  },
  {
    label: "CAUMONT-SUR-AURE",
    value: "14143",
    type: "common",
  },
  {
    label: "LE PERRON",
    value: "50398",
    type: "common",
  },
  {
    label: "MONTRABOT",
    value: "50351",
    type: "common",
  },
  {
    label: "LAMBERVILLE",
    value: "50261",
    type: "common",
  },
  {
    label: "BIEVILLE",
    value: "50054",
    type: "common",
  },
  {
    label: "SAINT-JEAN-D'ELLE",
    value: "50492",
    type: "common",
  },
  {
    label: "LA BARRE-DE-SEMILLY",
    value: "50032",
    type: "common",
  },
  {
    label: "SAINTE-SUZANNE-SUR-VIRE",
    value: "50556",
    type: "common",
  },
  {
    label: "BOURGVALLEES",
    value: "50546",
    type: "common",
  },
  {
    label: "SAINT-LO",
    value: "50502",
    type: "common",
  },
  {
    label: "BAUDRE",
    value: "50034",
    type: "common",
  },
  {
    label: "SAINT-GILLES",
    value: "50483",
    type: "common",
  },
  {
    label: "QUIBOU",
    value: "50420",
    type: "common",
  },
  {
    label: "CANISY",
    value: "50095",
    type: "common",
  },
  {
    label: "MARIGNY-LE-LOZON",
    value: "50292",
    type: "common",
  },
  {
    label: "LE LOREY",
    value: "50279",
    type: "common",
  },
  {
    label: "CARANTILLY",
    value: "50098",
    type: "common",
  },
  {
    label: "CAMETOURS",
    value: "50093",
    type: "common",
  },
  {
    label: "SAVIGNY",
    value: "50569",
    type: "common",
  },
  {
    label: "COURCY",
    value: "50145",
    type: "common",
  },
  {
    label: "CAMPROND",
    value: "50094",
    type: "common",
  },
  {
    label: "CAMBERNON",
    value: "50092",
    type: "common",
  },
  {
    label: "BELVAL",
    value: "50044",
    type: "common",
  },
  {
    label: "LA VENDELEE",
    value: "50624",
    type: "common",
  },
  {
    label: "SAINT-PIERRE-DE-COUTANCES",
    value: "50537",
    type: "common",
  },
  {
    label: "MONTHUCHON",
    value: "50345",
    type: "common",
  },
  {
    label: "GRATOT",
    value: "50219",
    type: "common",
  },
  {
    label: "COUTANCES",
    value: "50147",
    type: "common",
  },
  {
    label: "BRICQUEVILLE-LA-BLOUETTE",
    value: "50084",
    type: "common",
  },
  {
    label: "TOURVILLE-SUR-SIENNE",
    value: "50603",
    type: "common",
  },
  {
    label: "SAINT-MALO-DE-LA-LANDE",
    value: "50506",
    type: "common",
  },
  {
    label: "HEUGUEVILLE-SUR-SIENNE",
    value: "50243",
    type: "common",
  },
  {
    label: "BRAINVILLE",
    value: "50072",
    type: "common",
  },
  {
    label: "BLAINVILLE-SUR-MER",
    value: "50058",
    type: "common",
  },
  {
    label: "AGON-COUTAINVILLE",
    value: "50003",
    type: "common",
  },
  {
    label: "WINTZENBACH",
    value: "67541",
    type: "common",
  },
  {
    label: "SCHAFFHOUSE-PRES-SELTZ",
    value: "67440",
    type: "common",
  },
  {
    label: "NEEWILLER-PRES-LAUTERBOURG",
    value: "67315",
    type: "common",
  },
  {
    label: "MUNCHHAUSEN",
    value: "67308",
    type: "common",
  },
  {
    label: "MOTHERN",
    value: "67305",
    type: "common",
  },
  {
    label: "STUNDWILLER",
    value: "67484",
    type: "common",
  },
  {
    label: "TRIMBACH",
    value: "67494",
    type: "common",
  },
  {
    label: "SIEGEN",
    value: "67466",
    type: "common",
  },
  {
    label: "OBERLAUTERBACH",
    value: "67346",
    type: "common",
  },
  {
    label: "NIEDERRŒDERN",
    value: "67330",
    type: "common",
  },
  {
    label: "EBERBACH-SELTZ",
    value: "67113",
    type: "common",
  },
  {
    label: "CRŒTTWILLER",
    value: "67079",
    type: "common",
  },
  {
    label: "BUHL",
    value: "67069",
    type: "common",
  },
  {
    label: "OBERRŒDERN",
    value: "67349",
    type: "common",
  },
  {
    label: "ASCHBACH",
    value: "67012",
    type: "common",
  },
  {
    label: "SCHŒNENBOURG",
    value: "67455",
    type: "common",
  },
  {
    label: "HUNSPACH",
    value: "67213",
    type: "common",
  },
  {
    label: "HOFFEN",
    value: "67206",
    type: "common",
  },
  {
    label: "SOULTZ-SOUS-FORETS",
    value: "67474",
    type: "common",
  },
  {
    label: "RETSCHWILLER",
    value: "67394",
    type: "common",
  },
  {
    label: "MERKWILLER-PECHELBRONN",
    value: "67290",
    type: "common",
  },
  {
    label: "MEMMELSHOFFEN",
    value: "67288",
    type: "common",
  },
  {
    label: "LOBSANN",
    value: "67271",
    type: "common",
  },
  {
    label: "KUTZENHAUSEN",
    value: "67254",
    type: "common",
  },
  {
    label: "KEFFENACH",
    value: "67232",
    type: "common",
  },
  {
    label: "WŒRTH",
    value: "67550",
    type: "common",
  },
  {
    label: "PREUSCHDORF",
    value: "67379",
    type: "common",
  },
  {
    label: "OBERDORF-SPACHBACH",
    value: "67341",
    type: "common",
  },
  {
    label: "GUNSTETT",
    value: "67177",
    type: "common",
  },
  {
    label: "GŒRSDORF",
    value: "67160",
    type: "common",
  },
  {
    label: "DIEFFENBACH-LES-WŒRTH",
    value: "67093",
    type: "common",
  },
  {
    label: "REICHSHOFFEN",
    value: "67388",
    type: "common",
  },
  {
    label: "NIEDERBRONN-LES-BAINS",
    value: "67324",
    type: "common",
  },
  {
    label: "LANGENSOULTZBACH",
    value: "67259",
    type: "common",
  },
  {
    label: "FRŒSCHWILLER",
    value: "67147",
    type: "common",
  },
  {
    label: "OBERBRONN",
    value: "67340",
    type: "common",
  },
  {
    label: "BAERENTHAL",
    value: "57046",
    type: "common",
  },
  {
    label: "REIPERTSWILLER",
    value: "67392",
    type: "common",
  },
  {
    label: "VOLKSBERG",
    value: "67509",
    type: "common",
  },
  {
    label: "ROSTEIG",
    value: "67413",
    type: "common",
  },
  {
    label: "SOUCHT",
    value: "57658",
    type: "common",
  },
  {
    label: "SAINT-LOUIS-LES-BITCHE",
    value: "57619",
    type: "common",
  },
  {
    label: "MEISENTHAL",
    value: "57456",
    type: "common",
  },
  {
    label: "GOETZENBRUCK",
    value: "57250",
    type: "common",
  },
  {
    label: "RATZWILLER",
    value: "67385",
    type: "common",
  },
  {
    label: "DIEMERINGEN",
    value: "67095",
    type: "common",
  },
  {
    label: "BUTTEN",
    value: "67072",
    type: "common",
  },
  {
    label: "VŒLLERDINGEN",
    value: "67508",
    type: "common",
  },
  {
    label: "LORENTZEN",
    value: "67274",
    type: "common",
  },
  {
    label: "DOMFESSEL",
    value: "67099",
    type: "common",
  },
  {
    label: "DEHLINGEN",
    value: "67088",
    type: "common",
  },
  {
    label: "SCHOPPERTEN",
    value: "67456",
    type: "common",
  },
  {
    label: "SARRE-UNION",
    value: "67434",
    type: "common",
  },
  {
    label: "KESKASTEL",
    value: "67234",
    type: "common",
  },
  {
    label: "BISSERT",
    value: "67047",
    type: "common",
  },
  {
    label: "SARRALBE",
    value: "57628",
    type: "common",
  },
  {
    label: "LE VAL-DE-GUEBLANGE",
    value: "57267",
    type: "common",
  },
  {
    label: "KIRVILLER",
    value: "57366",
    type: "common",
  },
  {
    label: "HAZEMBOURG",
    value: "57308",
    type: "common",
  },
  {
    label: "VITTERSBOURG",
    value: "57725",
    type: "common",
  },
  {
    label: "HINSINGEN",
    value: "67199",
    type: "common",
  },
  {
    label: "NELLING",
    value: "57497",
    type: "common",
  },
  {
    label: "HILSPRICH",
    value: "57325",
    type: "common",
  },
  {
    label: "KAPPELKINGER",
    value: "57357",
    type: "common",
  },
  {
    label: "INSMING",
    value: "57346",
    type: "common",
  },
  {
    label: "PETIT-TENQUIN",
    value: "57536",
    type: "common",
  },
  {
    label: "GRENING",
    value: "57258",
    type: "common",
  },
  {
    label: "DIFFEMBACH-LES-HELLIMER",
    value: "57178",
    type: "common",
  },
  {
    label: "FRANCALTROFF",
    value: "57232",
    type: "common",
  },
  {
    label: "LENING",
    value: "57394",
    type: "common",
  },
  {
    label: "HELLIMER",
    value: "57311",
    type: "common",
  },
  {
    label: "FREYBOUSE",
    value: "57239",
    type: "common",
  },
  {
    label: "FREMESTROFF",
    value: "57237",
    type: "common",
  },
  {
    label: "ERSTROFF",
    value: "57198",
    type: "common",
  },
  {
    label: "HARPRICH",
    value: "57297",
    type: "common",
  },
  {
    label: "GROSTENQUIN",
    value: "57262",
    type: "common",
  },
  {
    label: "BISTROFF",
    value: "57088",
    type: "common",
  },
  {
    label: "BERIG-VINTRANGE",
    value: "57063",
    type: "common",
  },
  {
    label: "VILLER",
    value: "57717",
    type: "common",
  },
  {
    label: "SUISSE",
    value: "57662",
    type: "common",
  },
  {
    label: "LANDROFF",
    value: "57379",
    type: "common",
  },
  {
    label: "EINCHEVILLE",
    value: "57189",
    type: "common",
  },
  {
    label: "BOUSTROFF",
    value: "57105",
    type: "common",
  },
  {
    label: "ADELANGE",
    value: "57008",
    type: "common",
  },
  {
    label: "THONVILLE",
    value: "57673",
    type: "common",
  },
  {
    label: "THICOURT",
    value: "57670",
    type: "common",
  },
  {
    label: "MANY",
    value: "57442",
    type: "common",
  },
  {
    label: "HOLACOURT",
    value: "57328",
    type: "common",
  },
  {
    label: "ARRIANCE",
    value: "57029",
    type: "common",
  },
  {
    label: "ARRAINCOURT",
    value: "57027",
    type: "common",
  },
  {
    label: "VOIMHAUT",
    value: "57728",
    type: "common",
  },
  {
    label: "VATIMONT",
    value: "57698",
    type: "common",
  },
  {
    label: "HERNY",
    value: "57319",
    type: "common",
  },
  {
    label: "HAN-SUR-NIED",
    value: "57293",
    type: "common",
  },
  {
    label: "FLOCOURT",
    value: "57220",
    type: "common",
  },
  {
    label: "BECHY",
    value: "57057",
    type: "common",
  },
  {
    label: "ADAINCOURT",
    value: "57007",
    type: "common",
  },
  {
    label: "SOLGNE",
    value: "57655",
    type: "common",
  },
  {
    label: "REMILLY",
    value: "57572",
    type: "common",
  },
  {
    label: "LUPPY",
    value: "57425",
    type: "common",
  },
  {
    label: "BEUX",
    value: "57075",
    type: "common",
  },
  {
    label: "AUBE",
    value: "57037",
    type: "common",
  },
  {
    label: "SILLY-EN-SAULNOIS",
    value: "57653",
    type: "common",
  },
  {
    label: "POURNOY-LA-GRASSE",
    value: "57554",
    type: "common",
  },
  {
    label: "PONTOY",
    value: "57548",
    type: "common",
  },
  {
    label: "ORNY",
    value: "57527",
    type: "common",
  },
  {
    label: "LIEHON",
    value: "57403",
    type: "common",
  },
  {
    label: "GOIN",
    value: "57251",
    type: "common",
  },
  {
    label: "CHERISEY",
    value: "57139",
    type: "common",
  },
  {
    label: "BUCHY",
    value: "57116",
    type: "common",
  },
  {
    label: "VERNY",
    value: "57708",
    type: "common",
  },
  {
    label: "SILLEGNY",
    value: "57652",
    type: "common",
  },
  {
    label: "POURNOY-LA-CHETIVE",
    value: "57553",
    type: "common",
  },
  {
    label: "POMMERIEUX",
    value: "57547",
    type: "common",
  },
  {
    label: "COIN-SUR-SEILLE",
    value: "57147",
    type: "common",
  },
  {
    label: "COIN-LES-CUVRY",
    value: "57146",
    type: "common",
  },
  {
    label: "MARIEULLES",
    value: "57445",
    type: "common",
  },
  {
    label: "LORRY-MARDIGNY",
    value: "57416",
    type: "common",
  },
  {
    label: "FEY",
    value: "57212",
    type: "common",
  },
  {
    label: "CORNY-SUR-MOSELLE",
    value: "57153",
    type: "common",
  },
  {
    label: "ARRY",
    value: "57030",
    type: "common",
  },
  {
    label: "VILLECEY-SUR-MAD",
    value: "54570",
    type: "common",
  },
  {
    label: "VANDELAINVILLE",
    value: "54544",
    type: "common",
  },
  {
    label: "PAGNY-SUR-MOSELLE",
    value: "54415",
    type: "common",
  },
  {
    label: "ONVILLE",
    value: "54410",
    type: "common",
  },
  {
    label: "BAYONVILLE-SUR-MAD",
    value: "54055",
    type: "common",
  },
  {
    label: "ARNAVILLE",
    value: "54022",
    type: "common",
  },
  {
    label: "WAVILLE",
    value: "54593",
    type: "common",
  },
  {
    label: "SAINT-JULIEN-LES-GORZE",
    value: "54477",
    type: "common",
  },
  {
    label: "REMBERCOURT-SUR-MAD",
    value: "54453",
    type: "common",
  },
  {
    label: "HAGEVILLE",
    value: "54244",
    type: "common",
  },
  {
    label: "DOMMARTIN-LA-CHAUSSEE",
    value: "54166",
    type: "common",
  },
  {
    label: "CHAREY",
    value: "54119",
    type: "common",
  },
  {
    label: "LACHAUSSEE",
    value: "55267",
    type: "common",
  },
  {
    label: "DAMPVITOUX",
    value: "54153",
    type: "common",
  },
  {
    label: "WOEL",
    value: "55583",
    type: "common",
  },
  {
    label: "AVILLERS-SAINTE-CROIX",
    value: "55021",
    type: "common",
  },
  {
    label: "THILLOT",
    value: "55507",
    type: "common",
  },
  {
    label: "SAINT-MAURICE-SOUS-LES-COTES",
    value: "55462",
    type: "common",
  },
  {
    label: "HERBEUVILLE",
    value: "55243",
    type: "common",
  },
  {
    label: "HANNONVILLE-SOUS-LES-COTES",
    value: "55228",
    type: "common",
  },
  {
    label: "DOMPIERRE-AUX-BOIS",
    value: "55160",
    type: "common",
  },
  {
    label: "DOMMARTIN-LA-MONTAGNE",
    value: "55157",
    type: "common",
  },
  {
    label: "VAUX-LES-PALAMEIX",
    value: "55540",
    type: "common",
  },
  {
    label: "TROYON",
    value: "55521",
    type: "common",
  },
  {
    label: "SAINT-REMY-LA-CALONNE",
    value: "55465",
    type: "common",
  },
  {
    label: "RANZIERES",
    value: "55415",
    type: "common",
  },
  {
    label: "MOUILLY",
    value: "55360",
    type: "common",
  },
  {
    label: "GENICOURT-SUR-MEUSE",
    value: "55204",
    type: "common",
  },
  {
    label: "AMBLY-SUR-MEUSE",
    value: "55007",
    type: "common",
  },
  {
    label: "VILLERS-SUR-MEUSE",
    value: "55566",
    type: "common",
  },
  {
    label: "LES MONTHAIRONS",
    value: "55347",
    type: "common",
  },
  {
    label: "SOUILLY",
    value: "55498",
    type: "common",
  },
  {
    label: "SAINT-ANDRE-EN-BARROIS",
    value: "55453",
    type: "common",
  },
  {
    label: "OSCHES",
    value: "55395",
    type: "common",
  },
  {
    label: "NUBECOURT",
    value: "55389",
    type: "common",
  },
  {
    label: "LAVOYE",
    value: "55285",
    type: "common",
  },
  {
    label: "JULVECOURT",
    value: "55260",
    type: "common",
  },
  {
    label: "IPPECOURT",
    value: "55251",
    type: "common",
  },
  {
    label: "AUTRECOURT-SUR-AIRE",
    value: "55017",
    type: "common",
  },
  {
    label: "WALY",
    value: "55577",
    type: "common",
  },
  {
    label: "FROIDOS",
    value: "55199",
    type: "common",
  },
  {
    label: "BRIZEAUX",
    value: "55081",
    type: "common",
  },
  {
    label: "PASSAVANT-EN-ARGONNE",
    value: "51424",
    type: "common",
  },
  {
    label: "CHATRICES",
    value: "51138",
    type: "common",
  },
  {
    label: "BEAULIEU-EN-ARGONNE",
    value: "55038",
    type: "common",
  },
  {
    label: "VERRIERES",
    value: "51610",
    type: "common",
  },
  {
    label: "VILLERS-EN-ARGONNE",
    value: "51632",
    type: "common",
  },
  {
    label: "RAPSECOURT",
    value: "51452",
    type: "common",
  },
  {
    label: "VOILEMONT",
    value: "51650",
    type: "common",
  },
  {
    label: "ELISE-DAUCOURT",
    value: "51228",
    type: "common",
  },
  {
    label: "DOMMARTIN-DAMPIERRE",
    value: "51211",
    type: "common",
  },
  {
    label: "BRAUX-SAINT-REMY",
    value: "51083",
    type: "common",
  },
  {
    label: "ARGERS",
    value: "51015",
    type: "common",
  },
  {
    label: "SAINT-MARD-SUR-AUVE",
    value: "51498",
    type: "common",
  },
  {
    label: "LA CHAPELLE-FELCOURT",
    value: "51126",
    type: "common",
  },
  {
    label: "GIZAUCOURT",
    value: "51274",
    type: "common",
  },
  {
    label: "TILLOY-ET-BELLAY",
    value: "51572",
    type: "common",
  },
  {
    label: "AUVE",
    value: "51027",
    type: "common",
  },
  {
    label: "LA CROIX-EN-CHAMPAGNE",
    value: "51197",
    type: "common",
  },
  {
    label: "BUSSY-LE-CHATEAU",
    value: "51097",
    type: "common",
  },
  {
    label: "SAINT-REMY-SUR-BUSSY",
    value: "51515",
    type: "common",
  },
  {
    label: "SAINT-ETIENNE-AU-TEMPLE",
    value: "51476",
    type: "common",
  },
  {
    label: "CUPERLY",
    value: "51203",
    type: "common",
  },
  {
    label: "LA CHEPPE",
    value: "51147",
    type: "common",
  },
  {
    label: "LA VEUVE",
    value: "51617",
    type: "common",
  },
  {
    label: "SAINT-HILAIRE-AU-TEMPLE",
    value: "51485",
    type: "common",
  },
  {
    label: "DAMPIERRE-AU-TEMPLE",
    value: "51205",
    type: "common",
  },
  {
    label: "VRAUX",
    value: "51656",
    type: "common",
  },
  {
    label: "LES GRANDES-LOGES",
    value: "51278",
    type: "common",
  },
  {
    label: "ISSE",
    value: "51301",
    type: "common",
  },
  {
    label: "CONDE-SUR-MARNE",
    value: "51161",
    type: "common",
  },
  {
    label: "AIGNY",
    value: "51003",
    type: "common",
  },
  {
    label: "AMBONNAY",
    value: "51007",
    type: "common",
  },
  {
    label: "TOURS-SUR-MARNE",
    value: "51576",
    type: "common",
  },
  {
    label: "MUTIGNY",
    value: "51392",
    type: "common",
  },
  {
    label: "AŸ-CHAMPAGNE",
    value: "51030",
    type: "common",
  },
  {
    label: "AVENAY-VAL-D'OR",
    value: "51028",
    type: "common",
  },
  {
    label: "CUMIERES",
    value: "51202",
    type: "common",
  },
  {
    label: "CHAMPILLON",
    value: "51119",
    type: "common",
  },
  {
    label: "MAGENTA",
    value: "51663",
    type: "common",
  },
  {
    label: "DIZY",
    value: "51210",
    type: "common",
  },
  {
    label: "EPERNAY",
    value: "51230",
    type: "common",
  },
  {
    label: "MARDEUIL",
    value: "51344",
    type: "common",
  },
  {
    label: "BOURSAULT",
    value: "51076",
    type: "common",
  },
  {
    label: "ŒUILLY",
    value: "51410",
    type: "common",
  },
  {
    label: "VENTEUIL",
    value: "51605",
    type: "common",
  },
  {
    label: "VAUCIENNES",
    value: "51597",
    type: "common",
  },
  {
    label: "DAMERY",
    value: "51204",
    type: "common",
  },
  {
    label: "MAREUIL-LE-PORT",
    value: "51346",
    type: "common",
  },
  {
    label: "NESLE-LE-REPONS",
    value: "51396",
    type: "common",
  },
  {
    label: "LEUVRIGNY",
    value: "51320",
    type: "common",
  },
  {
    label: "TROISSY",
    value: "51585",
    type: "common",
  },
  {
    label: "DORMANS",
    value: "51217",
    type: "common",
  },
  {
    label: "JAULGONNE",
    value: "02389",
    type: "common",
  },
  {
    label: "COURTEMONT-VARENNES",
    value: "02228",
    type: "common",
  },
  {
    label: "CONNIGIS",
    value: "02213",
    type: "common",
  },
  {
    label: "COURTHIEZY",
    value: "51192",
    type: "common",
  },
  {
    label: "REUILLY-SAUVIGNY",
    value: "02645",
    type: "common",
  },
  {
    label: "PASSY-SUR-MARNE",
    value: "02595",
    type: "common",
  },
  {
    label: "GLAND",
    value: "02347",
    type: "common",
  },
  {
    label: "FOSSOY",
    value: "02328",
    type: "common",
  },
  {
    label: "CREZANCY",
    value: "02239",
    type: "common",
  },
  {
    label: "MONT-SAINT-PERE",
    value: "02524",
    type: "common",
  },
  {
    label: "MEZY-MOULINS",
    value: "02484",
    type: "common",
  },
  {
    label: "ETREPILLY",
    value: "02297",
    type: "common",
  },
  {
    label: "CHIERRY",
    value: "02187",
    type: "common",
  },
  {
    label: "CHATEAU-THIERRY",
    value: "02168",
    type: "common",
  },
  {
    label: "BRASLES",
    value: "02114",
    type: "common",
  },
  {
    label: "VERDILLY",
    value: "02781",
    type: "common",
  },
  {
    label: "LUCY-LE-BOCAGE",
    value: "02443",
    type: "common",
  },
  {
    label: "ESSOMES-SUR-MARNE",
    value: "02290",
    type: "common",
  },
  {
    label: "BOURESCHES",
    value: "02105",
    type: "common",
  },
  {
    label: "BELLEAU",
    value: "02062",
    type: "common",
  },
  {
    label: "TORCY-EN-VALOIS",
    value: "02744",
    type: "common",
  },
  {
    label: "MARIGNY-EN-ORXOIS",
    value: "02465",
    type: "common",
  },
  {
    label: "GANDELU",
    value: "02339",
    type: "common",
  },
  {
    label: "BUSSIARES",
    value: "02137",
    type: "common",
  },
  {
    label: "VEUILLY-LA-POTERIE",
    value: "02792",
    type: "common",
  },
  {
    label: "VENDREST",
    value: "77490",
    type: "common",
  },
  {
    label: "GERMIGNY-SOUS-COULOMBS",
    value: "77204",
    type: "common",
  },
  {
    label: "DHUISY",
    value: "77157",
    type: "common",
  },
  {
    label: "CROUY-SUR-OURCQ",
    value: "77148",
    type: "common",
  },
  {
    label: "COULOMBS-EN-VALOIS",
    value: "77129",
    type: "common",
  },
  {
    label: "LE PLESSIS-PLACY",
    value: "77367",
    type: "common",
  },
  {
    label: "MAY-EN-MULTIEN",
    value: "77283",
    type: "common",
  },
  {
    label: "LIZY-SUR-OURCQ",
    value: "77257",
    type: "common",
  },
  {
    label: "VINCY-MANŒUVRE",
    value: "77526",
    type: "common",
  },
  {
    label: "TROCY-EN-MULTIEN",
    value: "77476",
    type: "common",
  },
  {
    label: "PUISIEUX",
    value: "77380",
    type: "common",
  },
  {
    label: "ETREPILLY",
    value: "77173",
    type: "common",
  },
  {
    label: "REEZ-FOSSE-MARTIN",
    value: "60527",
    type: "common",
  },
  {
    label: "OISSERY",
    value: "77344",
    type: "common",
  },
  {
    label: "MARCILLY",
    value: "77274",
    type: "common",
  },
  {
    label: "FORFRY",
    value: "77193",
    type: "common",
  },
  {
    label: "DOUY-LA-RAMEE",
    value: "77163",
    type: "common",
  },
  {
    label: "BREGY",
    value: "60101",
    type: "common",
  },
  {
    label: "SAINT-SOUPPLETS",
    value: "77437",
    type: "common",
  },
  {
    label: "SAINT-PATHUS",
    value: "77430",
    type: "common",
  },
  {
    label: "MARCHEMORET",
    value: "77273",
    type: "common",
  },
  {
    label: "LAGNY-LE-SEC",
    value: "60341",
    type: "common",
  },
  {
    label: "SAINT-MARD",
    value: "77420",
    type: "common",
  },
  {
    label: "ROUVRES",
    value: "77392",
    type: "common",
  },
  {
    label: "OTHIS",
    value: "77349",
    type: "common",
  },
  {
    label: "LONGPERRIER",
    value: "77259",
    type: "common",
  },
  {
    label: "DAMMARTIN-EN-GOELE",
    value: "77153",
    type: "common",
  },
  {
    label: "EVE",
    value: "60226",
    type: "common",
  },
  {
    label: "CHENNEVIERES-LES-LOUVRES",
    value: "95154",
    type: "common",
  },
  {
    label: "VEMARS",
    value: "95641",
    type: "common",
  },
  {
    label: "SAINT-WITZ",
    value: "95580",
    type: "common",
  },
  {
    label: "MOUSSY-LE-VIEUX",
    value: "77323",
    type: "common",
  },
  {
    label: "MOUSSY-LE-NEUF",
    value: "77322",
    type: "common",
  },
  {
    label: "VILLERON",
    value: "95675",
    type: "common",
  },
  {
    label: "PUISEUX-EN-FRANCE",
    value: "95509",
    type: "common",
  },
  {
    label: "MARLY-LA-VILLE",
    value: "95371",
    type: "common",
  },
  {
    label: "BELLEFONTAINE",
    value: "95055",
    type: "common",
  },
  {
    label: "LE PLESSIS-LUZARCHES",
    value: "95493",
    type: "common",
  },
  {
    label: "CHATENAY-EN-FRANCE",
    value: "95144",
    type: "common",
  },
  {
    label: "LOUVRES",
    value: "95351",
    type: "common",
  },
  {
    label: "LE MESNIL-AUBRY",
    value: "95395",
    type: "common",
  },
  {
    label: "EZANVILLE",
    value: "95229",
    type: "common",
  },
  {
    label: "BELLOY-EN-FRANCE",
    value: "95056",
    type: "common",
  },
  {
    label: "FONTENAY-EN-PARISIS",
    value: "95241",
    type: "common",
  },
  {
    label: "EPINAY-CHAMPLATREUX",
    value: "95214",
    type: "common",
  },
  {
    label: "VILLIERS-LE-SEC",
    value: "95682",
    type: "common",
  },
  {
    label: "MAREIL-EN-FRANCE",
    value: "95365",
    type: "common",
  },
  {
    label: "JAGNY-SOUS-BOIS",
    value: "95316",
    type: "common",
  },
  {
    label: "LASSY",
    value: "95331",
    type: "common",
  },
  {
    label: "ATTAINVILLE",
    value: "95028",
    type: "common",
  },
  {
    label: "MOISSELLES",
    value: "95409",
    type: "common",
  },
  {
    label: "BOUFFEMONT",
    value: "95091",
    type: "common",
  },
  {
    label: "BAILLET-EN-FRANCE",
    value: "95042",
    type: "common",
  },
  {
    label: "MONTSOULT",
    value: "95430",
    type: "common",
  },
  {
    label: "VILLAINES-SOUS-BOIS",
    value: "95660",
    type: "common",
  },
  {
    label: "MAFFLIERS",
    value: "95353",
    type: "common",
  },
  {
    label: "NERVILLE-LA-FORET",
    value: "95445",
    type: "common",
  },
  {
    label: "CHAUVRY",
    value: "95151",
    type: "common",
  },
  {
    label: "VILLIERS-ADAM",
    value: "95678",
    type: "common",
  },
  {
    label: "MERIEL",
    value: "95392",
    type: "common",
  },
  {
    label: "FREPILLON",
    value: "95256",
    type: "common",
  },
  {
    label: "BUTRY-SUR-OISE",
    value: "95120",
    type: "common",
  },
  {
    label: "BETHEMONT-LA-FORET",
    value: "95061",
    type: "common",
  },
  {
    label: "BESSANCOURT",
    value: "95060",
    type: "common",
  },
  {
    label: "SAINT-OUEN-L'AUMONE",
    value: "95572",
    type: "common",
  },
  {
    label: "PONTOISE",
    value: "95500",
    type: "common",
  },
  {
    label: "MERY-SUR-OISE",
    value: "95394",
    type: "common",
  },
  {
    label: "ENNERY",
    value: "95211",
    type: "common",
  },
  {
    label: "AUVERS-SUR-OISE",
    value: "95039",
    type: "common",
  },
  {
    label: "PUISEUX-PONTOISE",
    value: "95510",
    type: "common",
  },
  {
    label: "OSNY",
    value: "95476",
    type: "common",
  },
  {
    label: "MONTGEROULT",
    value: "95422",
    type: "common",
  },
  {
    label: "CERGY",
    value: "95127",
    type: "common",
  },
  {
    label: "BOISSY-L'AILLERIE",
    value: "95078",
    type: "common",
  },
  {
    label: "VIGNY",
    value: "95658",
    type: "common",
  },
  {
    label: "SAGY",
    value: "95535",
    type: "common",
  },
  {
    label: "LONGUESSE",
    value: "95348",
    type: "common",
  },
  {
    label: "COURDIMANCHE",
    value: "95183",
    type: "common",
  },
  {
    label: "COURCELLES-SUR-VIOSNE",
    value: "95181",
    type: "common",
  },
  {
    label: "CONDECOURT",
    value: "95170",
    type: "common",
  },
  {
    label: "ABLEIGES",
    value: "95002",
    type: "common",
  },
  {
    label: "THEMERICOURT",
    value: "95610",
    type: "common",
  },
  {
    label: "SERAINCOURT",
    value: "95592",
    type: "common",
  },
  {
    label: "FREMAINVILLE",
    value: "95253",
    type: "common",
  },
  {
    label: "AVERNES",
    value: "95040",
    type: "common",
  },
  {
    label: "MONTALET-LE-BOIS",
    value: "78416",
    type: "common",
  },
  {
    label: "JAMBVILLE",
    value: "78317",
    type: "common",
  },
  {
    label: "LAINVILLE-EN-VEXIN",
    value: "78329",
    type: "common",
  },
  {
    label: "SAINT-CYR-EN-ARTHIES",
    value: "95543",
    type: "common",
  },
  {
    label: "AINCOURT",
    value: "95008",
    type: "common",
  },
  {
    label: "SAILLY",
    value: "78536",
    type: "common",
  },
  {
    label: "DROCOURT",
    value: "78202",
    type: "common",
  },
  {
    label: "VILLERS-EN-ARTHIES",
    value: "95676",
    type: "common",
  },
  {
    label: "VIENNE-EN-ARTHIES",
    value: "95656",
    type: "common",
  },
  {
    label: "VETHEUIL",
    value: "95651",
    type: "common",
  },
  {
    label: "HAUTE-ISLE",
    value: "95301",
    type: "common",
  },
  {
    label: "CHERENCE",
    value: "95157",
    type: "common",
  },
  {
    label: "SAINT-MARTIN-LA-GARENNE",
    value: "78567",
    type: "common",
  },
  {
    label: "MOUSSEAUX-SUR-SEINE",
    value: "78437",
    type: "common",
  },
  {
    label: "MOISSON",
    value: "78410",
    type: "common",
  },
  {
    label: "LA ROCHE-GUYON",
    value: "95523",
    type: "common",
  },
  {
    label: "MERICOURT",
    value: "78391",
    type: "common",
  },
  {
    label: "GOMMECOURT",
    value: "78276",
    type: "common",
  },
  {
    label: "FRENEUSE",
    value: "78255",
    type: "common",
  },
  {
    label: "BENNECOURT",
    value: "78057",
    type: "common",
  },
  {
    label: "SAINTE-GENEVIEVE-LES-GASNY",
    value: "27540",
    type: "common",
  },
  {
    label: "LIMETZ-VILLEZ",
    value: "78337",
    type: "common",
  },
  {
    label: "BLARU",
    value: "78068",
    type: "common",
  },
  {
    label: "VERNON",
    value: "27681",
    type: "common",
  },
  {
    label: "GIVERNY",
    value: "27285",
    type: "common",
  },
  {
    label: "SAINT-VINCENT-DES-BOIS",
    value: "27612",
    type: "common",
  },
  {
    label: "MERCEY",
    value: "27399",
    type: "common",
  },
  {
    label: "LA HEUNIERE",
    value: "27336",
    type: "common",
  },
  {
    label: "DOUAINS",
    value: "27203",
    type: "common",
  },
  {
    label: "VAUX-SUR-EURE",
    value: "27674",
    type: "common",
  },
  {
    label: "ROUVRAY",
    value: "27501",
    type: "common",
  },
  {
    label: "MENILLES",
    value: "27397",
    type: "common",
  },
  {
    label: "JOUY-SUR-EURE",
    value: "27358",
    type: "common",
  },
  {
    label: "HOULBEC-COCHEREL",
    value: "27343",
    type: "common",
  },
  {
    label: "HARDENCOURT-COCHEREL",
    value: "27312",
    type: "common",
  },
  {
    label: "CHAMBRAY",
    value: "27140",
    type: "common",
  },
  {
    label: "SASSEY",
    value: "27615",
    type: "common",
  },
  {
    label: "SAINT-VIGOR",
    value: "27611",
    type: "common",
  },
  {
    label: "REUILLY",
    value: "27489",
    type: "common",
  },
  {
    label: "HUEST",
    value: "27347",
    type: "common",
  },
  {
    label: "GAUCIEL",
    value: "27280",
    type: "common",
  },
  {
    label: "FONTAINE-SOUS-JOUY",
    value: "27254",
    type: "common",
  },
  {
    label: "DARDEZ",
    value: "27200",
    type: "common",
  },
  {
    label: "SAINT-GERMAIN-DES-ANGLES",
    value: "27546",
    type: "common",
  },
  {
    label: "NORMANVILLE",
    value: "27439",
    type: "common",
  },
  {
    label: "LE MESNIL-FUGUET",
    value: "27401",
    type: "common",
  },
  {
    label: "GRAVIGNY",
    value: "27299",
    type: "common",
  },
  {
    label: "LE BOULAY-MORIN",
    value: "27099",
    type: "common",
  },
  {
    label: "AVIRON",
    value: "27031",
    type: "common",
  },
  {
    label: "SAINT-MARTIN-LA-CAMPAGNE",
    value: "27570",
    type: "common",
  },
  {
    label: "SACQUENVILLE",
    value: "27504",
    type: "common",
  },
  {
    label: "PARVILLE",
    value: "27451",
    type: "common",
  },
  {
    label: "GAUVILLE-LA-CAMPAGNE",
    value: "27282",
    type: "common",
  },
  {
    label: "CLAVILLE",
    value: "27161",
    type: "common",
  },
  {
    label: "BERNIENVILLE",
    value: "27057",
    type: "common",
  },
  {
    label: "TOURNEDOS-BOIS-HUBERT",
    value: "27650",
    type: "common",
  },
  {
    label: "LE TILLEUL-LAMBERT",
    value: "27641",
    type: "common",
  },
  {
    label: "ORMES",
    value: "27446",
    type: "common",
  },
  {
    label: "LE PLESSIS-SAINTE-OPPORTUNE",
    value: "27466",
    type: "common",
  },
  {
    label: "EMANVILLE",
    value: "27217",
    type: "common",
  },
  {
    label: "BARQUET",
    value: "27040",
    type: "common",
  },
  {
    label: "GROSLEY-SUR-RISLE",
    value: "27300",
    type: "common",
  },
  {
    label: "BEAUMONT-LE-ROGER",
    value: "27051",
    type: "common",
  },
  {
    label: "BARC",
    value: "27037",
    type: "common",
  },
  {
    label: "CORNEVILLE-LA-FOUQUETIERE",
    value: "27173",
    type: "common",
  },
  {
    label: "SAINT-VICTOR-DE-CHRETIENVILLE",
    value: "27608",
    type: "common",
  },
  {
    label: "PLAINVILLE",
    value: "27460",
    type: "common",
  },
  {
    label: "GRAND-CAMP",
    value: "27295",
    type: "common",
  },
  {
    label: "FERRIERES-SAINT-HILAIRE",
    value: "27239",
    type: "common",
  },
  {
    label: "CAORCHES-SAINT-NICOLAS",
    value: "27129",
    type: "common",
  },
  {
    label: "SAINT-GERMAIN-LA-CAMPAGNE",
    value: "27547",
    type: "common",
  },
  {
    label: "CAPELLE-LES-GRANDS",
    value: "27130",
    type: "common",
  },
  {
    label: "SAINT-MARTIN-DE-BIENFAITE-LA-CRESSONNIERE",
    value: "14621",
    type: "common",
  },
  {
    label: "VALORBIQUET",
    value: "14570",
    type: "common",
  },
  {
    label: "ORBEC",
    value: "14478",
    type: "common",
  },
  {
    label: "PRETREVILLE",
    value: "14522",
    type: "common",
  },
  {
    label: "SAINT-GERMAIN-DE-LIVET",
    value: "14582",
    type: "common",
  },
  {
    label: "LESSARD-ET-LE-CHENE",
    value: "14362",
    type: "common",
  },
  {
    label: "CASTILLON-EN-AUGE",
    value: "14141",
    type: "common",
  },
  {
    label: "SAINT-PIERRE-EN-AUGE",
    value: "14654",
    type: "common",
  },
  {
    label: "ERNES",
    value: "14245",
    type: "common",
  },
  {
    label: "CONDE-SUR-IFS",
    value: "14173",
    type: "common",
  },
  {
    label: "SOIGNOLLES",
    value: "14674",
    type: "common",
  },
  {
    label: "SAINT-SYLVAIN",
    value: "14659",
    type: "common",
  },
  {
    label: "MAIZIERES",
    value: "14394",
    type: "common",
  },
  {
    label: "LE BU-SUR-ROUVRES",
    value: "14116",
    type: "common",
  },
  {
    label: "URVILLE",
    value: "14719",
    type: "common",
  },
  {
    label: "GOUVIX",
    value: "14309",
    type: "common",
  },
  {
    label: "CINTHEAUX",
    value: "14160",
    type: "common",
  },
  {
    label: "CAUVICOURT",
    value: "14145",
    type: "common",
  },
  {
    label: "BRETTEVILLE-SUR-LAIZE",
    value: "14100",
    type: "common",
  },
  {
    label: "BRETTEVILLE-LE-RABET",
    value: "14097",
    type: "common",
  },
  {
    label: "SAINT-LAURENT-DE-CONDEL",
    value: "14603",
    type: "common",
  },
  {
    label: "BOULON",
    value: "14090",
    type: "common",
  },
  {
    label: "BARBERY",
    value: "14039",
    type: "common",
  },
  {
    label: "OUFFIERES",
    value: "14483",
    type: "common",
  },
  {
    label: "MUTRECY",
    value: "14461",
    type: "common",
  },
  {
    label: "LES MOUTIERS-EN-CINGLAIS",
    value: "14458",
    type: "common",
  },
  {
    label: "GRIMBOSQ",
    value: "14320",
    type: "common",
  },
  {
    label: "CROISILLES",
    value: "14207",
    type: "common",
  },
  {
    label: "PREAUX-BOCAGE",
    value: "14519",
    type: "common",
  },
  {
    label: "MONTIGNY",
    value: "14446",
    type: "common",
  },
  {
    label: "MAISONCELLES-SUR-AJON",
    value: "14390",
    type: "common",
  },
  {
    label: "COURVAUDON",
    value: "14195",
    type: "common",
  },
  {
    label: "LA CAINE",
    value: "14122",
    type: "common",
  },
  {
    label: "BONNEMAISON",
    value: "14084",
    type: "common",
  },
  {
    label: "LE MESNIL-AU-GRAIN",
    value: "14412",
    type: "common",
  },
  {
    label: "LONGVILLERS",
    value: "14379",
    type: "common",
  },
  {
    label: "LES MONTS D'AUNAY",
    value: "14027",
    type: "common",
  },
  {
    label: "SAINT-PIERRE-DU-FRESNE",
    value: "14650",
    type: "common",
  },
  {
    label: "SEULLINE",
    value: "14579",
    type: "common",
  },
  {
    label: "DIALAN SUR CHAINE",
    value: "14347",
    type: "common",
  },
  {
    label: "LES LOGES",
    value: "14374",
    type: "common",
  },
  {
    label: "BREMOY",
    value: "14096",
    type: "common",
  },
  {
    label: "SAINT-AMAND-VILLAGES",
    value: "50444",
    type: "common",
  },
  {
    label: "TORIGNY-LES-VILLES",
    value: "50601",
    type: "common",
  },
  {
    label: "SAINT-LOUET-SUR-VIRE",
    value: "50504",
    type: "common",
  },
  {
    label: "DOMJEAN",
    value: "50164",
    type: "common",
  },
  {
    label: "CONDE-SUR-VIRE",
    value: "50139",
    type: "common",
  },
  {
    label: "SAINT-MARTIN-DE-BONFOSSE",
    value: "50512",
    type: "common",
  },
  {
    label: "MOYON VILLAGES",
    value: "50363",
    type: "common",
  },
  {
    label: "DANGY",
    value: "50159",
    type: "common",
  },
  {
    label: "NOTRE-DAME-DE-CENILLY",
    value: "50378",
    type: "common",
  },
  {
    label: "CERISY-LA-SALLE",
    value: "50111",
    type: "common",
  },
  {
    label: "SAINT-DENIS-LE-VETU",
    value: "50464",
    type: "common",
  },
  {
    label: "RONCEY",
    value: "50437",
    type: "common",
  },
  {
    label: "OUVILLE",
    value: "50389",
    type: "common",
  },
  {
    label: "MONTPINCHON",
    value: "50350",
    type: "common",
  },
  {
    label: "SAUSSEY",
    value: "50568",
    type: "common",
  },
  {
    label: "ORVAL SUR SIENNE",
    value: "50388",
    type: "common",
  },
  {
    label: "NICORPS",
    value: "50376",
    type: "common",
  },
  {
    label: "REGNEVILLE-SUR-MER",
    value: "50429",
    type: "common",
  },
  {
    label: "MONTMARTIN-SUR-MER",
    value: "50349",
    type: "common",
  },
  {
    label: "HAUTEVILLE-SUR-MER",
    value: "50231",
    type: "common",
  },
  {
    label: "PERROS-GUIREC",
    value: "22168",
    type: "common",
  },
  {
    label: "SELTZ",
    value: "67463",
    type: "common",
  },
  {
    label: "BEINHEIM",
    value: "67025",
    type: "common",
  },
  {
    label: "KESSELDORF",
    value: "67235",
    type: "common",
  },
  {
    label: "KAUFFENHEIM",
    value: "67231",
    type: "common",
  },
  {
    label: "HATTEN",
    value: "67184",
    type: "common",
  },
  {
    label: "FORSTFELD",
    value: "67140",
    type: "common",
  },
  {
    label: "RITTERSHOFFEN",
    value: "67404",
    type: "common",
  },
  {
    label: "BETSCHDORF",
    value: "67339",
    type: "common",
  },
  {
    label: "LEUTENHEIM",
    value: "67264",
    type: "common",
  },
  {
    label: "SURBOURG",
    value: "67487",
    type: "common",
  },
  {
    label: "WALBOURG",
    value: "67511",
    type: "common",
  },
  {
    label: "MORSBRONN-LES-BAINS",
    value: "67303",
    type: "common",
  },
  {
    label: "HEGENEY",
    value: "67186",
    type: "common",
  },
  {
    label: "ESCHBACH",
    value: "67132",
    type: "common",
  },
  {
    label: "DURRENBACH",
    value: "67110",
    type: "common",
  },
  {
    label: "BIBLISHEIM",
    value: "67037",
    type: "common",
  },
  {
    label: "UTTENHOFFEN",
    value: "67502",
    type: "common",
  },
  {
    label: "MIETESHEIM",
    value: "67292",
    type: "common",
  },
  {
    label: "MERTZWILLER",
    value: "67291",
    type: "common",
  },
  {
    label: "LAUBACH",
    value: "67260",
    type: "common",
  },
  {
    label: "GUNDERSHOFFEN",
    value: "67176",
    type: "common",
  },
  {
    label: "FORSTHEIM",
    value: "67141",
    type: "common",
  },
  {
    label: "ZINSWILLER",
    value: "67558",
    type: "common",
  },
  {
    label: "UHRWILLER",
    value: "67498",
    type: "common",
  },
  {
    label: "MULHAUSEN",
    value: "67307",
    type: "common",
  },
  {
    label: "KINDWILLER",
    value: "67238",
    type: "common",
  },
  {
    label: "GUMBRECHTSHOFFEN",
    value: "67174",
    type: "common",
  },
  {
    label: "ENGWILLER",
    value: "67123",
    type: "common",
  },
  {
    label: "BITSCHHOFFEN",
    value: "67048",
    type: "common",
  },
  {
    label: "SCHILLERSDORF",
    value: "67446",
    type: "common",
  },
  {
    label: "ROTHBACH",
    value: "67415",
    type: "common",
  },
  {
    label: "OFFWILLER",
    value: "67358",
    type: "common",
  },
  {
    label: "LICHTENBERG",
    value: "67265",
    type: "common",
  },
  {
    label: "INGWILLER",
    value: "67222",
    type: "common",
  },
  {
    label: "BISCHHOLTZ",
    value: "67044",
    type: "common",
  },
  {
    label: "WIMMENAU",
    value: "67535",
    type: "common",
  },
  {
    label: "WEINBOURG",
    value: "67521",
    type: "common",
  },
  {
    label: "SPARSBACH",
    value: "67475",
    type: "common",
  },
  {
    label: "ZITTERSHEIM",
    value: "67559",
    type: "common",
  },
  {
    label: "WINGEN-SUR-MODER",
    value: "67538",
    type: "common",
  },
  {
    label: "PUBERG",
    value: "67381",
    type: "common",
  },
  {
    label: "HINSBOURG",
    value: "67198",
    type: "common",
  },
  {
    label: "ERCKARTSWILLER",
    value: "67126",
    type: "common",
  },
  {
    label: "WEISLINGEN",
    value: "67522",
    type: "common",
  },
  {
    label: "WALDHAMBACH",
    value: "67514",
    type: "common",
  },
  {
    label: "TIEFFENBACH",
    value: "67491",
    type: "common",
  },
  {
    label: "STRUTH",
    value: "67483",
    type: "common",
  },
  {
    label: "PETERSBACH",
    value: "67370",
    type: "common",
  },
  {
    label: "FROHMUHL",
    value: "67148",
    type: "common",
  },
  {
    label: "DURSTEL",
    value: "67111",
    type: "common",
  },
  {
    label: "ASSWILLER",
    value: "67013",
    type: "common",
  },
  {
    label: "ADAMSWILLER",
    value: "67002",
    type: "common",
  },
  {
    label: "THAL-DRULINGEN",
    value: "67488",
    type: "common",
  },
  {
    label: "RIMSDORF",
    value: "67401",
    type: "common",
  },
  {
    label: "REXINGEN",
    value: "67396",
    type: "common",
  },
  {
    label: "MACKWILLER",
    value: "67278",
    type: "common",
  },
  {
    label: "BURBACH",
    value: "67070",
    type: "common",
  },
  {
    label: "BETTWILLER",
    value: "67036",
    type: "common",
  },
  {
    label: "BERG",
    value: "67029",
    type: "common",
  },
  {
    label: "SARREWERDEN",
    value: "67435",
    type: "common",
  },
  {
    label: "HARSKIRCHEN",
    value: "67183",
    type: "common",
  },
  {
    label: "DIEDENDORF",
    value: "67091",
    type: "common",
  },
  {
    label: "VIBERSVILLER",
    value: "57711",
    type: "common",
  },
  {
    label: "HONSKIRCH",
    value: "57335",
    type: "common",
  },
  {
    label: "ALTWILLER",
    value: "67009",
    type: "common",
  },
  {
    label: "TORCHEVILLE",
    value: "57675",
    type: "common",
  },
  {
    label: "RENING",
    value: "57573",
    type: "common",
  },
  {
    label: "GIVRYCOURT",
    value: "57248",
    type: "common",
  },
  {
    label: "MUNSTER",
    value: "57494",
    type: "common",
  },
  {
    label: "INSVILLER",
    value: "57347",
    type: "common",
  },
  {
    label: "ALBESTROFF",
    value: "57011",
    type: "common",
  },
  {
    label: "NEUFVILLAGE",
    value: "57501",
    type: "common",
  },
  {
    label: "VIRMING",
    value: "57723",
    type: "common",
  },
  {
    label: "VAHL-LES-BENESTROFF",
    value: "57685",
    type: "common",
  },
  {
    label: "MONTDIDIER",
    value: "57478",
    type: "common",
  },
  {
    label: "NEBING",
    value: "57496",
    type: "common",
  },
  {
    label: "BENESTROFF",
    value: "57060",
    type: "common",
  },
  {
    label: "RODALBE",
    value: "57587",
    type: "common",
  },
  {
    label: "BERMERING",
    value: "57065",
    type: "common",
  },
  {
    label: "VALLERANGE",
    value: "57687",
    type: "common",
  },
  {
    label: "RACRANGE",
    value: "57560",
    type: "common",
  },
  {
    label: "PEVANGE",
    value: "57539",
    type: "common",
  },
  {
    label: "MORHANGE",
    value: "57483",
    type: "common",
  },
  {
    label: "DESTRY",
    value: "57174",
    type: "common",
  },
  {
    label: "BARONVILLE",
    value: "57051",
    type: "common",
  },
  {
    label: "ACHAIN",
    value: "57004",
    type: "common",
  },
  {
    label: "VILLERS-SUR-NIED",
    value: "57719",
    type: "common",
  },
  {
    label: "MARTHILLE",
    value: "57451",
    type: "common",
  },
  {
    label: "LUCY",
    value: "57424",
    type: "common",
  },
  {
    label: "LESSE",
    value: "57395",
    type: "common",
  },
  {
    label: "CHICOURT",
    value: "57141",
    type: "common",
  },
  {
    label: "CHENOIS",
    value: "57138",
    type: "common",
  },
  {
    label: "BRULANGE",
    value: "57115",
    type: "common",
  },
  {
    label: "BREHAIN",
    value: "57107",
    type: "common",
  },
  {
    label: "THIMONVILLE",
    value: "57671",
    type: "common",
  },
  {
    label: "SAINT-EPVRE",
    value: "57609",
    type: "common",
  },
  {
    label: "PREVOCOURT",
    value: "57555",
    type: "common",
  },
  {
    label: "MORVILLE-SUR-NIED",
    value: "57486",
    type: "common",
  },
  {
    label: "HANNOCOURT",
    value: "57292",
    type: "common",
  },
  {
    label: "FREMERY",
    value: "57236",
    type: "common",
  },
  {
    label: "BAUDRECOURT",
    value: "57054",
    type: "common",
  },
  {
    label: "BACOURT",
    value: "57045",
    type: "common",
  },
  {
    label: "XOCOURT",
    value: "57755",
    type: "common",
  },
  {
    label: "VULMONT",
    value: "57737",
    type: "common",
  },
  {
    label: "TRAGNY",
    value: "57676",
    type: "common",
  },
  {
    label: "SAILLY-ACHATEL",
    value: "57605",
    type: "common",
  },
  {
    label: "MONCHEUX",
    value: "57472",
    type: "common",
  },
  {
    label: "JUVILLE",
    value: "57354",
    type: "common",
  },
  {
    label: "FOVILLE",
    value: "57231",
    type: "common",
  },
  {
    label: "VIGNY",
    value: "57715",
    type: "common",
  },
  {
    label: "SECOURT",
    value: "57643",
    type: "common",
  },
  {
    label: "SAINT-JURE",
    value: "57617",
    type: "common",
  },
  {
    label: "PAGNY-LES-GOIN",
    value: "57532",
    type: "common",
  },
  {
    label: "RAUCOURT",
    value: "54444",
    type: "common",
  },
  {
    label: "PHLIN",
    value: "54424",
    type: "common",
  },
  {
    label: "MAILLY-SUR-SEILLE",
    value: "54333",
    type: "common",
  },
  {
    label: "LOUVIGNY",
    value: "57422",
    type: "common",
  },
  {
    label: "CHEMINOT",
    value: "57137",
    type: "common",
  },
  {
    label: "MORVILLE-SUR-SEILLE",
    value: "54387",
    type: "common",
  },
  {
    label: "EPLY",
    value: "54179",
    type: "common",
  },
  {
    label: "VITTONVILLE",
    value: "54589",
    type: "common",
  },
  {
    label: "VANDIERES",
    value: "54546",
    type: "common",
  },
  {
    label: "PONT-A-MOUSSON",
    value: "54431",
    type: "common",
  },
  {
    label: "LESMENILS",
    value: "54312",
    type: "common",
  },
  {
    label: "CHAMPEY-SUR-MOSELLE",
    value: "54114",
    type: "common",
  },
  {
    label: "BOUXIERES-SOUS-FROIDMONT",
    value: "54091",
    type: "common",
  },
  {
    label: "VILLERS-SOUS-PRENY",
    value: "54579",
    type: "common",
  },
  {
    label: "VILCEY-SUR-TREY",
    value: "54566",
    type: "common",
  },
  {
    label: "PRENY",
    value: "54435",
    type: "common",
  },
  {
    label: "NORROY-LES-PONT-A-MOUSSON",
    value: "54403",
    type: "common",
  },
  {
    label: "VIEVILLE-EN-HAYE",
    value: "54564",
    type: "common",
  },
  {
    label: "THIAUCOURT-REGNIEVILLE",
    value: "54518",
    type: "common",
  },
  {
    label: "JAULNY",
    value: "54275",
    type: "common",
  },
  {
    label: "BENEY-EN-WOEVRE",
    value: "55046",
    type: "common",
  },
  {
    label: "XAMMES",
    value: "54594",
    type: "common",
  },
  {
    label: "PANNES",
    value: "54416",
    type: "common",
  },
  {
    label: "BOUILLONVILLE",
    value: "54087",
    type: "common",
  },
  {
    label: "VIGNEULLES-LES-HATTONCHATEL",
    value: "55551",
    type: "common",
  },
  {
    label: "NONSARD-LAMARCHE",
    value: "55386",
    type: "common",
  },
  {
    label: "HEUDICOURT-SOUS-LES-COTES",
    value: "55245",
    type: "common",
  },
  {
    label: "LAMORVILLE",
    value: "55274",
    type: "common",
  },
  {
    label: "CHAILLON",
    value: "55096",
    type: "common",
  },
  {
    label: "SEUZEY",
    value: "55487",
    type: "common",
  },
  {
    label: "ROUVROIS-SUR-MEUSE",
    value: "55444",
    type: "common",
  },
  {
    label: "LACROIX-SUR-MEUSE",
    value: "55268",
    type: "common",
  },
  {
    label: "WOIMBEY",
    value: "55584",
    type: "common",
  },
  {
    label: "TILLY-SUR-MEUSE",
    value: "55512",
    type: "common",
  },
  {
    label: "BOUQUEMONT",
    value: "55064",
    type: "common",
  },
  {
    label: "BANNONCOURT",
    value: "55027",
    type: "common",
  },
  {
    label: "THILLOMBOIS",
    value: "55506",
    type: "common",
  },
  {
    label: "RECOURT-LE-CREUX",
    value: "55420",
    type: "common",
  },
  {
    label: "RAMBLUZIN-ET-BENOITE-VAUX",
    value: "55411",
    type: "common",
  },
  {
    label: "NEUVILLE-EN-VERDUNOIS",
    value: "55380",
    type: "common",
  },
  {
    label: "LES TROIS-DOMAINES",
    value: "55254",
    type: "common",
  },
  {
    label: "HEIPPES",
    value: "55241",
    type: "common",
  },
  {
    label: "PRETZ-EN-ARGONNE",
    value: "55409",
    type: "common",
  },
  {
    label: "BEAUSITE",
    value: "55040",
    type: "common",
  },
  {
    label: "SEUIL-D'ARGONNE",
    value: "55517",
    type: "common",
  },
  {
    label: "FOUCAUCOURT-SUR-THABAS",
    value: "55194",
    type: "common",
  },
  {
    label: "EVRES",
    value: "55185",
    type: "common",
  },
  {
    label: "ECLAIRES",
    value: "51222",
    type: "common",
  },
  {
    label: "LE CHEMIN",
    value: "51143",
    type: "common",
  },
  {
    label: "LES CHARMONTOIS",
    value: "51132",
    type: "common",
  },
  {
    label: "LE VIEIL-DAMPIERRE",
    value: "51619",
    type: "common",
  },
  {
    label: "LA NEUVILLE-AUX-BOIS",
    value: "51397",
    type: "common",
  },
  {
    label: "SIVRY-ANTE",
    value: "51537",
    type: "common",
  },
  {
    label: "EPENSE",
    value: "51229",
    type: "common",
  },
  {
    label: "DAMPIERRE-LE-CHATEAU",
    value: "51206",
    type: "common",
  },
  {
    label: "DOMMARTIN-VARIMONT",
    value: "51214",
    type: "common",
  },
  {
    label: "HERPONT",
    value: "51292",
    type: "common",
  },
  {
    label: "SOMME-VESLE",
    value: "51548",
    type: "common",
  },
  {
    label: "POIX",
    value: "51438",
    type: "common",
  },
  {
    label: "COURTISOLS",
    value: "51193",
    type: "common",
  },
  {
    label: "L'EPINE",
    value: "51231",
    type: "common",
  },
  {
    label: "CHALONS-EN-CHAMPAGNE",
    value: "51108",
    type: "common",
  },
  {
    label: "SAINT-MARTIN-SUR-LE-PRE",
    value: "51504",
    type: "common",
  },
  {
    label: "SAINT-GIBRIEN",
    value: "51483",
    type: "common",
  },
  {
    label: "RECY",
    value: "51453",
    type: "common",
  },
  {
    label: "JUVIGNY",
    value: "51312",
    type: "common",
  },
  {
    label: "MATOUGUES",
    value: "51357",
    type: "common",
  },
  {
    label: "CHAMPIGNEUL-CHAMPAGNE",
    value: "51117",
    type: "common",
  },
  {
    label: "AULNAY-SUR-MARNE",
    value: "51023",
    type: "common",
  },
  {
    label: "JALONS",
    value: "51303",
    type: "common",
  },
  {
    label: "CHERVILLE",
    value: "51150",
    type: "common",
  },
  {
    label: "ATHIS",
    value: "51018",
    type: "common",
  },
  {
    label: "LES ISTRES-ET-BURY",
    value: "51302",
    type: "common",
  },
  {
    label: "PLIVOT",
    value: "51434",
    type: "common",
  },
  {
    label: "AVIZE",
    value: "51029",
    type: "common",
  },
  {
    label: "OIRY",
    value: "51413",
    type: "common",
  },
  {
    label: "CRAMANT",
    value: "51196",
    type: "common",
  },
  {
    label: "FLAVIGNY",
    value: "51251",
    type: "common",
  },
  {
    label: "CHOUILLY",
    value: "51153",
    type: "common",
  },
  {
    label: "CUIS",
    value: "51200",
    type: "common",
  },
  {
    label: "MONTHELON",
    value: "51378",
    type: "common",
  },
  {
    label: "MANCY",
    value: "51342",
    type: "common",
  },
  {
    label: "CHAVOT-COURCOURT",
    value: "51142",
    type: "common",
  },
  {
    label: "PIERRY",
    value: "51431",
    type: "common",
  },
  {
    label: "VINAY",
    value: "51643",
    type: "common",
  },
  {
    label: "MOUSSY",
    value: "51390",
    type: "common",
  },
  {
    label: "BRUGNY-VAUDANCOURT",
    value: "51093",
    type: "common",
  },
  {
    label: "LE BAIZIL",
    value: "51033",
    type: "common",
  },
  {
    label: "SAINT-MARTIN-D'ABLOIS",
    value: "51002",
    type: "common",
  },
  {
    label: "IGNY-COMBLIZY",
    value: "51298",
    type: "common",
  },
  {
    label: "FESTIGNY",
    value: "51249",
    type: "common",
  },
  {
    label: "LE BREUIL",
    value: "51085",
    type: "common",
  },
  {
    label: "CONDE-EN-BRIE",
    value: "02209",
    type: "common",
  },
  {
    label: "CELLES-LES-CONDE",
    value: "02146",
    type: "common",
  },
  {
    label: "VALLEES EN CHAMPAGNE",
    value: "02053",
    type: "common",
  },
  {
    label: "MONTIGNY-LES-CONDE",
    value: "02515",
    type: "common",
  },
  {
    label: "MONTHUREL",
    value: "02510",
    type: "common",
  },
  {
    label: "COURBOIN",
    value: "02223",
    type: "common",
  },
  {
    label: "BLESMES",
    value: "02094",
    type: "common",
  },
  {
    label: "SAINT-EUGENE",
    value: "02677",
    type: "common",
  },
  {
    label: "NESLES-LA-MONTAGNE",
    value: "02540",
    type: "common",
  },
  {
    label: "ETAMPES-SUR-MARNE",
    value: "02292",
    type: "common",
  },
  {
    label: "CHEZY-SUR-MARNE",
    value: "02186",
    type: "common",
  },
  {
    label: "AZY-SUR-MARNE",
    value: "02042",
    type: "common",
  },
  {
    label: "NOGENTEL",
    value: "02554",
    type: "common",
  },
  {
    label: "DOMPTIN",
    value: "02268",
    type: "common",
  },
  {
    label: "COUPRU",
    value: "02221",
    type: "common",
  },
  {
    label: "CHARLY-SUR-MARNE",
    value: "02163",
    type: "common",
  },
  {
    label: "BONNEIL",
    value: "02098",
    type: "common",
  },
  {
    label: "VILLIERS-SAINT-DENIS",
    value: "02818",
    type: "common",
  },
  {
    label: "SAULCHERY",
    value: "02701",
    type: "common",
  },
  {
    label: "ROMENY-SUR-MARNE",
    value: "02653",
    type: "common",
  },
  {
    label: "CROUTTES-SUR-MARNE",
    value: "02242",
    type: "common",
  },
  {
    label: "BEZU-LE-GUERY",
    value: "02084",
    type: "common",
  },
  {
    label: "SAINTE-AULDE",
    value: "77401",
    type: "common",
  },
  {
    label: "NANTEUIL-SUR-MARNE",
    value: "77331",
    type: "common",
  },
  {
    label: "LUZANCY",
    value: "77265",
    type: "common",
  },
  {
    label: "MONTREUIL-AUX-LIONS",
    value: "02521",
    type: "common",
  },
  {
    label: "TANCROU",
    value: "77460",
    type: "common",
  },
  {
    label: "COCHEREL",
    value: "77120",
    type: "common",
  },
  {
    label: "CHAMIGNY",
    value: "77078",
    type: "common",
  },
  {
    label: "OCQUERRE",
    value: "77343",
    type: "common",
  },
  {
    label: "MARY-SUR-MARNE",
    value: "77280",
    type: "common",
  },
  {
    label: "JAIGNES",
    value: "77235",
    type: "common",
  },
  {
    label: "ISLES-LES-MELDEUSES",
    value: "77231",
    type: "common",
  },
  {
    label: "ARMENTIERES-EN-BRIE",
    value: "77008",
    type: "common",
  },
  {
    label: "VARREDDES",
    value: "77483",
    type: "common",
  },
  {
    label: "GERMIGNY-L'EVEQUE",
    value: "77203",
    type: "common",
  },
  {
    label: "CONGIS-SUR-THEROUANNE",
    value: "77126",
    type: "common",
  },
  {
    label: "PENCHARD",
    value: "77358",
    type: "common",
  },
  {
    label: "MONTHYON",
    value: "77309",
    type: "common",
  },
  {
    label: "GESVRES-LE-CHAPITRE",
    value: "77205",
    type: "common",
  },
  {
    label: "CHAMBRY",
    value: "77077",
    type: "common",
  },
  {
    label: "BARCY",
    value: "77023",
    type: "common",
  },
  {
    label: "VINANTES",
    value: "77525",
    type: "common",
  },
  {
    label: "VILLEROY",
    value: "77515",
    type: "common",
  },
  {
    label: "LE PLESSIS-L'EVEQUE",
    value: "77366",
    type: "common",
  },
  {
    label: "LE PLESSIS-AUX-BOIS",
    value: "77364",
    type: "common",
  },
  {
    label: "MONTGE-EN-GOELE",
    value: "77308",
    type: "common",
  },
  {
    label: "IVERNY",
    value: "77233",
    type: "common",
  },
  {
    label: "CUISY",
    value: "77150",
    type: "common",
  },
  {
    label: "VILLENEUVE-SOUS-DAMMARTIN",
    value: "77511",
    type: "common",
  },
  {
    label: "THIEUX",
    value: "77462",
    type: "common",
  },
  {
    label: "SAINT-MESMES",
    value: "77427",
    type: "common",
  },
  {
    label: "NANTOUILLET",
    value: "77332",
    type: "common",
  },
  {
    label: "JUILLY",
    value: "77241",
    type: "common",
  },
  {
    label: "COMPANS",
    value: "77123",
    type: "common",
  },
  {
    label: "EPIAIS-LES-LOUVRES",
    value: "95212",
    type: "common",
  },
  {
    label: "MITRY-MORY",
    value: "77294",
    type: "common",
  },
  {
    label: "LE MESNIL-AMELOT",
    value: "77291",
    type: "common",
  },
  {
    label: "MAUREGARD",
    value: "77282",
    type: "common",
  },
  {
    label: "GONESSE",
    value: "95277",
    type: "common",
  },
  {
    label: "ROISSY-EN-FRANCE",
    value: "95527",
    type: "common",
  },
  {
    label: "VAUDHERLAND",
    value: "95633",
    type: "common",
  },
  {
    label: "LE THILLAY",
    value: "95612",
    type: "common",
  },
  {
    label: "GOUSSAINVILLE",
    value: "95280",
    type: "common",
  },
  {
    label: "TREMBLAY-EN-FRANCE",
    value: "93073",
    type: "common",
  },
  {
    label: "BOUQUEVAL",
    value: "95094",
    type: "common",
  },
  {
    label: "ECOUEN",
    value: "95205",
    type: "common",
  },
  {
    label: "LE PLESSIS-GASSOT",
    value: "95492",
    type: "common",
  },
  {
    label: "VILLIERS-LE-BEL",
    value: "95680",
    type: "common",
  },
  {
    label: "ARNOUVILLE",
    value: "95019",
    type: "common",
  },
  {
    label: "SARCELLES",
    value: "95585",
    type: "common",
  },
  {
    label: "SOISY-SOUS-MONTMORENCY",
    value: "95598",
    type: "common",
  },
  {
    label: "MARGENCY",
    value: "95369",
    type: "common",
  },
  {
    label: "ANDILLY",
    value: "95014",
    type: "common",
  },
  {
    label: "SAINT-BRICE-SOUS-FORET",
    value: "95539",
    type: "common",
  },
  {
    label: "PISCOP",
    value: "95489",
    type: "common",
  },
  {
    label: "DOMONT",
    value: "95199",
    type: "common",
  },
  {
    label: "MONTMORENCY",
    value: "95428",
    type: "common",
  },
  {
    label: "GROSLAY",
    value: "95288",
    type: "common",
  },
  {
    label: "SAINT-PRIX",
    value: "95574",
    type: "common",
  },
  {
    label: "MONTLIGNON",
    value: "95426",
    type: "common",
  },
  {
    label: "EAUBONNE",
    value: "95203",
    type: "common",
  },
  {
    label: "TAVERNY",
    value: "95607",
    type: "common",
  },
  {
    label: "SAINT-LEU-LA-FORET",
    value: "95563",
    type: "common",
  },
  {
    label: "LE PLESSIS-BOUCHARD",
    value: "95491",
    type: "common",
  },
  {
    label: "MONTIGNY-LES-CORMEILLES",
    value: "95424",
    type: "common",
  },
  {
    label: "FRANCONVILLE",
    value: "95252",
    type: "common",
  },
  {
    label: "ERMONT",
    value: "95219",
    type: "common",
  },
  {
    label: "BEAUCHAMP",
    value: "95051",
    type: "common",
  },
  {
    label: "PIERRELAYE",
    value: "95488",
    type: "common",
  },
  {
    label: "HERBLAY-SUR-SEINE",
    value: "95306",
    type: "common",
  },
  {
    label: "ERAGNY",
    value: "95218",
    type: "common",
  },
  {
    label: "CONFLANS-SAINTE-HONORINE",
    value: "78172",
    type: "common",
  },
  {
    label: "VAUREAL",
    value: "95637",
    type: "common",
  },
  {
    label: "NEUVILLE-SUR-OISE",
    value: "95450",
    type: "common",
  },
  {
    label: "JOUY-LE-MOUTIER",
    value: "95323",
    type: "common",
  },
  {
    label: "TRIEL-SUR-SEINE",
    value: "78624",
    type: "common",
  },
  {
    label: "MAURECOURT",
    value: "78382",
    type: "common",
  },
  {
    label: "CHANTELOUP-LES-VIGNES",
    value: "78138",
    type: "common",
  },
  {
    label: "ANDRESY",
    value: "78015",
    type: "common",
  },
  {
    label: "MENUCOURT",
    value: "95388",
    type: "common",
  },
  {
    label: "BOISEMONT",
    value: "95074",
    type: "common",
  },
  {
    label: "VERNEUIL-SUR-SEINE",
    value: "78642",
    type: "common",
  },
  {
    label: "VAUX-SUR-SEINE",
    value: "78638",
    type: "common",
  },
  {
    label: "TESSANCOURT-SUR-AUBETTE",
    value: "78609",
    type: "common",
  },
  {
    label: "MEULAN-EN-YVELINES",
    value: "78401",
    type: "common",
  },
  {
    label: "EVECQUEMONT",
    value: "78227",
    type: "common",
  },
  {
    label: "OINVILLE-SUR-MONTCIENT",
    value: "78460",
    type: "common",
  },
  {
    label: "LES MUREAUX",
    value: "78440",
    type: "common",
  },
  {
    label: "MEZY-SUR-SEINE",
    value: "78403",
    type: "common",
  },
  {
    label: "JUZIERS",
    value: "78327",
    type: "common",
  },
  {
    label: "HARDRICOURT",
    value: "78299",
    type: "common",
  },
  {
    label: "GAILLON-SUR-MONTCIENT",
    value: "78261",
    type: "common",
  },
  {
    label: "LIMAY",
    value: "78335",
    type: "common",
  },
  {
    label: "ISSOU",
    value: "78314",
    type: "common",
  },
  {
    label: "GUITRANCOURT",
    value: "78296",
    type: "common",
  },
  {
    label: "GARGENVILLE",
    value: "78267",
    type: "common",
  },
  {
    label: "FONTENAY-SAINT-PERE",
    value: "78246",
    type: "common",
  },
  {
    label: "BRUEIL-EN-VEXIN",
    value: "78113",
    type: "common",
  },
  {
    label: "MANTES-LA-JOLIE",
    value: "78361",
    type: "common",
  },
  {
    label: "FOLLAINVILLE-DENNEMONT",
    value: "78239",
    type: "common",
  },
  {
    label: "BUCHELAY",
    value: "78118",
    type: "common",
  },
  {
    label: "ROSNY-SUR-SEINE",
    value: "78531",
    type: "common",
  },
  {
    label: "ROLLEBOISE",
    value: "78528",
    type: "common",
  },
  {
    label: "GUERNES",
    value: "78290",
    type: "common",
  },
  {
    label: "BONNIERES-SUR-SEINE",
    value: "78089",
    type: "common",
  },
  {
    label: "LA VILLENEUVE-EN-CHEVRIE",
    value: "78668",
    type: "common",
  },
  {
    label: "SAINT-ILLIERS-LA-VILLE",
    value: "78558",
    type: "common",
  },
  {
    label: "LOMMOYE",
    value: "78344",
    type: "common",
  },
  {
    label: "CRAVENT",
    value: "78188",
    type: "common",
  },
  {
    label: "CHAUFOUR-LES-BONNIERES",
    value: "78147",
    type: "common",
  },
  {
    label: "VILLEGATS",
    value: "27689",
    type: "common",
  },
  {
    label: "PACY-SUR-EURE",
    value: "27448",
    type: "common",
  },
  {
    label: "HECOURT",
    value: "27326",
    type: "common",
  },
  {
    label: "GADENCOURT",
    value: "27273",
    type: "common",
  },
  {
    label: "FAINS",
    value: "27231",
    type: "common",
  },
  {
    label: "CHAIGNES",
    value: "27136",
    type: "common",
  },
  {
    label: "AIGLEVILLE",
    value: "27004",
    type: "common",
  },
  {
    label: "LE PLESSIS-HEBERT",
    value: "27465",
    type: "common",
  },
  {
    label: "CROISY-SUR-EURE",
    value: "27190",
    type: "common",
  },
  {
    label: "LE CORMIER",
    value: "27171",
    type: "common",
  },
  {
    label: "CAILLOUET-ORGEVILLE",
    value: "27123",
    type: "common",
  },
  {
    label: "BONCOURT",
    value: "27081",
    type: "common",
  },
  {
    label: "LE VIEIL-EVREUX",
    value: "27684",
    type: "common",
  },
  {
    label: "LE VAL-DAVID",
    value: "27668",
    type: "common",
  },
  {
    label: "LA TRINITE",
    value: "27659",
    type: "common",
  },
  {
    label: "SAINT-LUC",
    value: "27560",
    type: "common",
  },
  {
    label: "MISEREY",
    value: "27410",
    type: "common",
  },
  {
    label: "FAUVILLE",
    value: "27234",
    type: "common",
  },
  {
    label: "CIERREY",
    value: "27158",
    type: "common",
  },
  {
    label: "EVREUX",
    value: "27229",
    type: "common",
  },
  {
    label: "ANGERVILLE-LA-CAMPAGNE",
    value: "27017",
    type: "common",
  },
  {
    label: "SAINT-SEBASTIEN-DE-MORSENT",
    value: "27602",
    type: "common",
  },
  {
    label: "CAUGE",
    value: "27132",
    type: "common",
  },
  {
    label: "LA BONNEVILLE-SUR-ITON",
    value: "27082",
    type: "common",
  },
  {
    label: "AULNAY-SUR-ITON",
    value: "27023",
    type: "common",
  },
  {
    label: "ARNIERES-SUR-ITON",
    value: "27020",
    type: "common",
  },
  {
    label: "SAINT-ELIER",
    value: "27535",
    type: "common",
  },
  {
    label: "PORTES",
    value: "27472",
    type: "common",
  },
  {
    label: "GLISOLLES",
    value: "27287",
    type: "common",
  },
  {
    label: "FERRIERES-HAUT-CLOCHER",
    value: "27238",
    type: "common",
  },
  {
    label: "LA CROISILLE",
    value: "27189",
    type: "common",
  },
  {
    label: "BUREY",
    value: "27120",
    type: "common",
  },
  {
    label: "TILLEUL-DAME-AGNES",
    value: "27640",
    type: "common",
  },
  {
    label: "LOUVERSEY",
    value: "27374",
    type: "common",
  },
  {
    label: "FAVEROLLES-LA-CAMPAGNE",
    value: "27235",
    type: "common",
  },
  {
    label: "COLLANDRES-QUINCARNON",
    value: "27162",
    type: "common",
  },
  {
    label: "BERVILLE-LA-CAMPAGNE",
    value: "27063",
    type: "common",
  },
  {
    label: "SEBECOURT",
    value: "27618",
    type: "common",
  },
  {
    label: "ROMILLY-LA-PUTHENAYE",
    value: "27492",
    type: "common",
  },
  {
    label: "LE NOYER-EN-OUCHE",
    value: "27444",
    type: "common",
  },
  {
    label: "LA HOUSSAYE",
    value: "27345",
    type: "common",
  },
  {
    label: "LA FERRIERE-SUR-RISLE",
    value: "27240",
    type: "common",
  },
  {
    label: "MESNIL-EN-OUCHE",
    value: "27049",
    type: "common",
  },
  {
    label: "LA TRINITE-DE-REVILLE",
    value: "27660",
    type: "common",
  },
  {
    label: "SAINT-AUBIN-DU-THENNEY",
    value: "27514",
    type: "common",
  },
  {
    label: "CHAMBLAC",
    value: "27138",
    type: "common",
  },
  {
    label: "BROGLIE",
    value: "27117",
    type: "common",
  },
  {
    label: "SAINT-JEAN-DU-THENNEY",
    value: "27552",
    type: "common",
  },
  {
    label: "LA CHAPELLE-GAUTHIER",
    value: "27148",
    type: "common",
  },
  {
    label: "LA VESPIERE-FRIARDEL",
    value: "14740",
    type: "common",
  },
  {
    label: "LA FOLLETIERE-ABENON",
    value: "14273",
    type: "common",
  },
  {
    label: "CERNAY",
    value: "14147",
    type: "common",
  },
  {
    label: "LIVAROT-PAYS-D'AUGE",
    value: "14371",
    type: "common",
  },
  {
    label: "COURCY",
    value: "14190",
    type: "common",
  },
  {
    label: "VENDEUVRE",
    value: "14735",
    type: "common",
  },
  {
    label: "SASSY",
    value: "14669",
    type: "common",
  },
  {
    label: "PERRIERES",
    value: "14497",
    type: "common",
  },
  {
    label: "JORT",
    value: "14345",
    type: "common",
  },
  {
    label: "ROUVRES",
    value: "14546",
    type: "common",
  },
  {
    label: "OUILLY-LE-TESSON",
    value: "14486",
    type: "common",
  },
  {
    label: "OLENDON",
    value: "14476",
    type: "common",
  },
  {
    label: "BONS-TASSILLY",
    value: "14088",
    type: "common",
  },
  {
    label: "SOUMONT-SAINT-QUENTIN",
    value: "14678",
    type: "common",
  },
  {
    label: "SAINT-GERMAIN-LE-VASSON",
    value: "14589",
    type: "common",
  },
  {
    label: "POTIGNY",
    value: "14516",
    type: "common",
  },
  {
    label: "GRAINVILLE-LANGANNERIE",
    value: "14310",
    type: "common",
  },
  {
    label: "FONTAINE-LE-PIN",
    value: "14276",
    type: "common",
  },
  {
    label: "ESTREES-LA-CAMPAGNE",
    value: "14252",
    type: "common",
  },
  {
    label: "MOULINES",
    value: "14455",
    type: "common",
  },
  {
    label: "MESLAY",
    value: "14411",
    type: "common",
  },
  {
    label: "FRESNEY-LE-VIEUX",
    value: "14291",
    type: "common",
  },
  {
    label: "ESPINS",
    value: "14248",
    type: "common",
  },
  {
    label: "LE HOM",
    value: "14689",
    type: "common",
  },
  {
    label: "ESSON",
    value: "14251",
    type: "common",
  },
  {
    label: "DONNAY",
    value: "14226",
    type: "common",
  },
  {
    label: "COMBRAY",
    value: "14171",
    type: "common",
  },
  {
    label: "CULEY-LE-PATRY",
    value: "14211",
    type: "common",
  },
  {
    label: "CAUVILLE",
    value: "14146",
    type: "common",
  },
  {
    label: "BEUVRIGNY",
    value: "50050",
    type: "common",
  },
  {
    label: "SAINTE-MARIE-OUTRE-L'EAU",
    value: "14619",
    type: "common",
  },
  {
    label: "TESSY-BOCAGE",
    value: "50592",
    type: "common",
  },
  {
    label: "FOURNEAUX",
    value: "50192",
    type: "common",
  },
  {
    label: "VILLEBAUDON",
    value: "50637",
    type: "common",
  },
  {
    label: "MONTABOT",
    value: "50334",
    type: "common",
  },
  {
    label: "MAUPERTUIS",
    value: "50295",
    type: "common",
  },
  {
    label: "LA HAYE-BELLEFOND",
    value: "50234",
    type: "common",
  },
  {
    label: "LE GUISLAIN",
    value: "50225",
    type: "common",
  },
  {
    label: "BEAUCOUDRAY",
    value: "50039",
    type: "common",
  },
  {
    label: "SAINT-MARTIN-DE-CENILLY",
    value: "50513",
    type: "common",
  },
  {
    label: "HAMBYE",
    value: "50228",
    type: "common",
  },
  {
    label: "LA BALEINE",
    value: "50028",
    type: "common",
  },
  {
    label: "SAINT-DENIS-LE-GAST",
    value: "50463",
    type: "common",
  },
  {
    label: "LENGRONNE",
    value: "50266",
    type: "common",
  },
  {
    label: "GRIMESNIL",
    value: "50221",
    type: "common",
  },
  {
    label: "QUETTREVILLE-SUR-SIENNE",
    value: "50419",
    type: "common",
  },
  {
    label: "LE MESNIL-AUBERT",
    value: "50304",
    type: "common",
  },
  {
    label: "MUNEVILLE-SUR-MER",
    value: "50365",
    type: "common",
  },
  {
    label: "LINGREVILLE",
    value: "50272",
    type: "common",
  },
  {
    label: "BRICQUEVILLE-SUR-MER",
    value: "50085",
    type: "common",
  },
  {
    label: "ANNOVILLE",
    value: "50015",
    type: "common",
  },
  {
    label: "LANMODEZ",
    value: "22111",
    type: "common",
  },
  {
    label: "PLOUGRESCANT",
    value: "22218",
    type: "common",
  },
  {
    label: "PENVENAN",
    value: "22166",
    type: "common",
  },
  {
    label: "NEUHAEUSEL",
    value: "67319",
    type: "common",
  },
  {
    label: "STATTMATTEN",
    value: "67476",
    type: "common",
  },
  {
    label: "ROPPENHEIM",
    value: "67409",
    type: "common",
  },
  {
    label: "RŒSCHWOOG",
    value: "67405",
    type: "common",
  },
  {
    label: "FORT-LOUIS",
    value: "67142",
    type: "common",
  },
  {
    label: "SOUFFLENHEIM",
    value: "67472",
    type: "common",
  },
  {
    label: "SESSENHEIM",
    value: "67465",
    type: "common",
  },
  {
    label: "SCHIRRHOFFEN",
    value: "67450",
    type: "common",
  },
  {
    label: "SCHIRRHEIN",
    value: "67449",
    type: "common",
  },
  {
    label: "HAGUENAU",
    value: "67180",
    type: "common",
  },
  {
    label: "SCHWEIGHOUSE-SUR-MODER",
    value: "67458",
    type: "common",
  },
  {
    label: "UHLWILLER",
    value: "67497",
    type: "common",
  },
  {
    label: "OHLUNGEN",
    value: "67359",
    type: "common",
  },
  {
    label: "NIEDERMODERN",
    value: "67328",
    type: "common",
  },
  {
    label: "MORSCHWILLER",
    value: "67304",
    type: "common",
  },
  {
    label: "HUTTENDORF",
    value: "67215",
    type: "common",
  },
  {
    label: "DAUENDORF",
    value: "67087",
    type: "common",
  },
  {
    label: "SCHALKENDORF",
    value: "67441",
    type: "common",
  },
  {
    label: "RINGENDORF",
    value: "67403",
    type: "common",
  },
  {
    label: "VAL-DE-MODER",
    value: "67372",
    type: "common",
  },
  {
    label: "GRASSENDORF",
    value: "67166",
    type: "common",
  },
  {
    label: "ETTENDORF",
    value: "67135",
    type: "common",
  },
  {
    label: "BUSWILLER",
    value: "67068",
    type: "common",
  },
  {
    label: "UTTWILLER",
    value: "67503",
    type: "common",
  },
  {
    label: "OBERMODERN-ZUTZENDORF",
    value: "67347",
    type: "common",
  },
  {
    label: "NIEDERSOULTZBACH",
    value: "67333",
    type: "common",
  },
  {
    label: "MENCHHOFFEN",
    value: "67289",
    type: "common",
  },
  {
    label: "KIRRWILLER",
    value: "67242",
    type: "common",
  },
  {
    label: "BOUXWILLER",
    value: "67061",
    type: "common",
  },
  {
    label: "WEITERSWILLER",
    value: "67524",
    type: "common",
  },
  {
    label: "OBERSOULTZBACH",
    value: "67352",
    type: "common",
  },
  {
    label: "NEUWILLER-LES-SAVERNE",
    value: "67322",
    type: "common",
  },
  {
    label: "LA PETITE-PIERRE",
    value: "67371",
    type: "common",
  },
  {
    label: "ESCHBOURG",
    value: "67133",
    type: "common",
  },
  {
    label: "SIEWILLER",
    value: "67467",
    type: "common",
  },
  {
    label: "SCHŒNBOURG",
    value: "67454",
    type: "common",
  },
  {
    label: "OTTWILLER",
    value: "67369",
    type: "common",
  },
  {
    label: "LOHR",
    value: "67273",
    type: "common",
  },
  {
    label: "DRULINGEN",
    value: "67105",
    type: "common",
  },
  {
    label: "BUST",
    value: "67071",
    type: "common",
  },
  {
    label: "VECKERSVILLER",
    value: "57703",
    type: "common",
  },
  {
    label: "WEYER",
    value: "67528",
    type: "common",
  },
  {
    label: "HIRSCHLAND",
    value: "67201",
    type: "common",
  },
  {
    label: "GUNGWILLER",
    value: "67178",
    type: "common",
  },
  {
    label: "EYWILLER",
    value: "67136",
    type: "common",
  },
  {
    label: "ESCHWILLER",
    value: "67134",
    type: "common",
  },
  {
    label: "WOLFSKIRCHEN",
    value: "67552",
    type: "common",
  },
  {
    label: "KIRRBERG",
    value: "67241",
    type: "common",
  },
  {
    label: "BAERENDORF",
    value: "67017",
    type: "common",
  },
  {
    label: "ROMELFING",
    value: "57592",
    type: "common",
  },
  {
    label: "POSTROFF",
    value: "57551",
    type: "common",
  },
  {
    label: "NIEDERSTINZEL",
    value: "57506",
    type: "common",
  },
  {
    label: "MITTERSHEIM",
    value: "57469",
    type: "common",
  },
  {
    label: "FENETRANGE",
    value: "57210",
    type: "common",
  },
  {
    label: "LOSTROFF",
    value: "57417",
    type: "common",
  },
  {
    label: "LOUDREFING",
    value: "57418",
    type: "common",
  },
  {
    label: "LHOR",
    value: "57410",
    type: "common",
  },
  {
    label: "GUINZELING",
    value: "57278",
    type: "common",
  },
  {
    label: "DOMNOM-LES-DIEUZE",
    value: "57181",
    type: "common",
  },
  {
    label: "CUTTING",
    value: "57161",
    type: "common",
  },
  {
    label: "MOLRING",
    value: "57470",
    type: "common",
  },
  {
    label: "MARIMONT-LES-BENESTROFF",
    value: "57446",
    type: "common",
  },
  {
    label: "VERGAVILLE",
    value: "57706",
    type: "common",
  },
  {
    label: "BOURGALTROFF",
    value: "57098",
    type: "common",
  },
  {
    label: "BIDESTROFF",
    value: "57081",
    type: "common",
  },
  {
    label: "BASSING",
    value: "57053",
    type: "common",
  },
  {
    label: "ZARBELING",
    value: "57759",
    type: "common",
  },
  {
    label: "WUISSE",
    value: "57753",
    type: "common",
  },
  {
    label: "LIDREZING",
    value: "57401",
    type: "common",
  },
  {
    label: "GUEBLING",
    value: "57268",
    type: "common",
  },
  {
    label: "GUEBESTROFF",
    value: "57265",
    type: "common",
  },
  {
    label: "CONTHIL",
    value: "57151",
    type: "common",
  },
  {
    label: "SOTZELING",
    value: "57657",
    type: "common",
  },
  {
    label: "RICHE",
    value: "57580",
    type: "common",
  },
  {
    label: "OBRECK",
    value: "57520",
    type: "common",
  },
  {
    label: "HABOUDANGE",
    value: "57281",
    type: "common",
  },
  {
    label: "DALHAIN",
    value: "57166",
    type: "common",
  },
  {
    label: "BURLIONCOURT",
    value: "57120",
    type: "common",
  },
  {
    label: "BELLANGE",
    value: "57059",
    type: "common",
  },
  {
    label: "VAXY",
    value: "57702",
    type: "common",
  },
  {
    label: "VANNECOURT",
    value: "57692",
    type: "common",
  },
  {
    label: "PUTTIGNY",
    value: "57558",
    type: "common",
  },
  {
    label: "ORON",
    value: "57528",
    type: "common",
  },
  {
    label: "GERBECOURT",
    value: "57247",
    type: "common",
  },
  {
    label: "FONTENY",
    value: "57225",
    type: "common",
  },
  {
    label: "CHATEAU-BREHAIN",
    value: "57130",
    type: "common",
  },
  {
    label: "VIVIERS",
    value: "57727",
    type: "common",
  },
  {
    label: "TINCRY",
    value: "57674",
    type: "common",
  },
  {
    label: "ORIOCOURT",
    value: "57525",
    type: "common",
  },
  {
    label: "LEMONCOURT",
    value: "57391",
    type: "common",
  },
  {
    label: "LANEUVEVILLE-EN-SAULNOIS",
    value: "57381",
    type: "common",
  },
  {
    label: "DONJEUX",
    value: "57182",
    type: "common",
  },
  {
    label: "DELME",
    value: "57171",
    type: "common",
  },
  {
    label: "PUZIEUX",
    value: "57559",
    type: "common",
  },
  {
    label: "LIOCOURT",
    value: "57406",
    type: "common",
  },
  {
    label: "CRAINCOURT",
    value: "57158",
    type: "common",
  },
  {
    label: "AULNOIS-SUR-SEILLE",
    value: "57040",
    type: "common",
  },
  {
    label: "ALAINCOURT-LA-COTE",
    value: "57010",
    type: "common",
  },
  {
    label: "THEZEY-SAINT-MARTIN",
    value: "54517",
    type: "common",
  },
  {
    label: "NOMENY",
    value: "54400",
    type: "common",
  },
  {
    label: "LETRICOURT",
    value: "54313",
    type: "common",
  },
  {
    label: "CHENICOURT",
    value: "54126",
    type: "common",
  },
  {
    label: "ABAUCOURT",
    value: "54001",
    type: "common",
  },
  {
    label: "SAINTE-GENEVIEVE",
    value: "54474",
    type: "common",
  },
  {
    label: "ROUVES",
    value: "54464",
    type: "common",
  },
  {
    label: "PORT-SUR-SEILLE",
    value: "54433",
    type: "common",
  },
  {
    label: "CLEMERY",
    value: "54131",
    type: "common",
  },
  {
    label: "MOUSSON",
    value: "54390",
    type: "common",
  },
  {
    label: "MAIDIERES",
    value: "54332",
    type: "common",
  },
  {
    label: "LOISY",
    value: "54320",
    type: "common",
  },
  {
    label: "BLENOD-LES-PONT-A-MOUSSON",
    value: "54079",
    type: "common",
  },
  {
    label: "ATTON",
    value: "54027",
    type: "common",
  },
  {
    label: "MONTAUVILLE",
    value: "54375",
    type: "common",
  },
  {
    label: "MAMEY",
    value: "54340",
    type: "common",
  },
  {
    label: "JEZAINVILLE",
    value: "54279",
    type: "common",
  },
  {
    label: "FEY-EN-HAYE",
    value: "54193",
    type: "common",
  },
  {
    label: "LIRONVILLE",
    value: "54317",
    type: "common",
  },
  {
    label: "LIMEY-REMENAUVILLE",
    value: "54316",
    type: "common",
  },
  {
    label: "FLIREY",
    value: "54200",
    type: "common",
  },
  {
    label: "EUVEZIN",
    value: "54187",
    type: "common",
  },
  {
    label: "LAHAYVILLE",
    value: "55270",
    type: "common",
  },
  {
    label: "SAINT-BAUSSANT",
    value: "54470",
    type: "common",
  },
  {
    label: "ESSEY-ET-MAIZERAIS",
    value: "54182",
    type: "common",
  },
  {
    label: "RICHECOURT",
    value: "55431",
    type: "common",
  },
  {
    label: "MONTSEC",
    value: "55353",
    type: "common",
  },
  {
    label: "BUXIERES-SOUS-LES-COTES",
    value: "55093",
    type: "common",
  },
  {
    label: "VALBOIS",
    value: "55530",
    type: "common",
  },
  {
    label: "VARNEVILLE",
    value: "55528",
    type: "common",
  },
  {
    label: "SAINT-MIHIEL",
    value: "55463",
    type: "common",
  },
  {
    label: "MAIZEY",
    value: "55312",
    type: "common",
  },
  {
    label: "CHAUVONCOURT",
    value: "55111",
    type: "common",
  },
  {
    label: "LES PAROCHES",
    value: "55401",
    type: "common",
  },
  {
    label: "LAHAYMEIX",
    value: "55269",
    type: "common",
  },
  {
    label: "FRESNES-AU-MONT",
    value: "55197",
    type: "common",
  },
  {
    label: "DOMPCEVRIN",
    value: "55159",
    type: "common",
  },
  {
    label: "PIERREFITTE-SUR-AIRE",
    value: "55404",
    type: "common",
  },
  {
    label: "NICEY-SUR-AIRE",
    value: "55384",
    type: "common",
  },
  {
    label: "LONGCHAMPS-SUR-AIRE",
    value: "55301",
    type: "common",
  },
  {
    label: "COUROUVRE",
    value: "55129",
    type: "common",
  },
  {
    label: "RAIVAL",
    value: "55442",
    type: "common",
  },
  {
    label: "ERIZE-LA-PETITE",
    value: "55177",
    type: "common",
  },
  {
    label: "COURCELLES-SUR-AIRE",
    value: "55128",
    type: "common",
  },
  {
    label: "CHAUMONT-SUR-AIRE",
    value: "55108",
    type: "common",
  },
  {
    label: "REMBERCOURT-SOMMAISNE",
    value: "55423",
    type: "common",
  },
  {
    label: "VILLOTTE-DEVANT-LOUPPY",
    value: "55569",
    type: "common",
  },
  {
    label: "VAUBECOURT",
    value: "55532",
    type: "common",
  },
  {
    label: "LISLE-EN-BARROIS",
    value: "55295",
    type: "common",
  },
  {
    label: "BELVAL-EN-ARGONNE",
    value: "51047",
    type: "common",
  },
  {
    label: "SOMMEILLES",
    value: "55493",
    type: "common",
  },
  {
    label: "LAHEYCOURT",
    value: "55271",
    type: "common",
  },
  {
    label: "GIVRY-EN-ARGONNE",
    value: "51272",
    type: "common",
  },
  {
    label: "LE CHATELIER",
    value: "51133",
    type: "common",
  },
  {
    label: "POSSESSE",
    value: "51442",
    type: "common",
  },
  {
    label: "SAINT-MARD-SUR-LE-MONT",
    value: "51500",
    type: "common",
  },
  {
    label: "REMICOURT",
    value: "51456",
    type: "common",
  },
  {
    label: "NOIRLIEU",
    value: "51404",
    type: "common",
  },
  {
    label: "CONTAULT",
    value: "51166",
    type: "common",
  },
  {
    label: "BUSSY-LE-REPOS",
    value: "51098",
    type: "common",
  },
  {
    label: "SOMME-YEVRE",
    value: "51549",
    type: "common",
  },
  {
    label: "MOIVRE",
    value: "51371",
    type: "common",
  },
  {
    label: "LE FRESNE",
    value: "51260",
    type: "common",
  },
  {
    label: "COUPEVILLE",
    value: "51179",
    type: "common",
  },
  {
    label: "MARSON",
    value: "51354",
    type: "common",
  },
  {
    label: "MONCETZ-LONGEVAS",
    value: "51372",
    type: "common",
  },
  {
    label: "CHEPY",
    value: "51149",
    type: "common",
  },
  {
    label: "SAINT-MEMMIE",
    value: "51506",
    type: "common",
  },
  {
    label: "COMPERTRIX",
    value: "51160",
    type: "common",
  },
  {
    label: "SARRY",
    value: "51525",
    type: "common",
  },
  {
    label: "FAGNIERES",
    value: "51242",
    type: "common",
  },
  {
    label: "COOLUS",
    value: "51168",
    type: "common",
  },
  {
    label: "VILLERS-LE-CHATEAU",
    value: "51634",
    type: "common",
  },
  {
    label: "SAINT-PIERRE",
    value: "51509",
    type: "common",
  },
  {
    label: "THIBIE",
    value: "51566",
    type: "common",
  },
  {
    label: "POCANCY",
    value: "51435",
    type: "common",
  },
  {
    label: "SAINT-MARD-LES-ROUFFY",
    value: "51499",
    type: "common",
  },
  {
    label: "VOUZY",
    value: "51655",
    type: "common",
  },
  {
    label: "ROUFFY",
    value: "51469",
    type: "common",
  },
  {
    label: "VILLENEUVE-RENNEVILLE-CHEVIGNY",
    value: "51627",
    type: "common",
  },
  {
    label: "LE MESNIL-SUR-OGER",
    value: "51367",
    type: "common",
  },
  {
    label: "GRAUVES",
    value: "51281",
    type: "common",
  },
  {
    label: "VILLERS-AUX-BOIS",
    value: "51630",
    type: "common",
  },
  {
    label: "MOSLINS",
    value: "51387",
    type: "common",
  },
  {
    label: "MORANGIS",
    value: "51384",
    type: "common",
  },
  {
    label: "CHALTRAIT",
    value: "51110",
    type: "common",
  },
  {
    label: "MONTMORT-LUCY",
    value: "51381",
    type: "common",
  },
  {
    label: "SUIZY-LE-FRANC",
    value: "51560",
    type: "common",
  },
  {
    label: "MAREUIL-EN-BRIE",
    value: "51345",
    type: "common",
  },
  {
    label: "CORRIBERT",
    value: "51174",
    type: "common",
  },
  {
    label: "LA CHAPELLE-SOUS-ORBAIS",
    value: "51128",
    type: "common",
  },
  {
    label: "ORBAIS-L'ABBAYE",
    value: "51416",
    type: "common",
  },
  {
    label: "LA VILLE-SOUS-ORBAIS",
    value: "51639",
    type: "common",
  },
  {
    label: "MARGNY",
    value: "51350",
    type: "common",
  },
  {
    label: "VERDON",
    value: "51607",
    type: "common",
  },
  {
    label: "CORROBERT",
    value: "51175",
    type: "common",
  },
  {
    label: "PARGNY-LA-DHUYS",
    value: "02590",
    type: "common",
  },
  {
    label: "VIFFORT",
    value: "02800",
    type: "common",
  },
  {
    label: "ROZOY-BELLEVALLE",
    value: "02664",
    type: "common",
  },
  {
    label: "MONTLEVON",
    value: "02518",
    type: "common",
  },
  {
    label: "ESSISES",
    value: "02289",
    type: "common",
  },
  {
    label: "LA CHAPELLE-SUR-CHEZY",
    value: "02162",
    type: "common",
  },
  {
    label: "MONTFAUCON",
    value: "02505",
    type: "common",
  },
  {
    label: "BASSEVELLE",
    value: "77024",
    type: "common",
  },
  {
    label: "PAVANT",
    value: "02596",
    type: "common",
  },
  {
    label: "NOGENT-L'ARTAUD",
    value: "02555",
    type: "common",
  },
  {
    label: "SAINT-CYR-SUR-MORIN",
    value: "77405",
    type: "common",
  },
  {
    label: "SAACY-SUR-MARNE",
    value: "77397",
    type: "common",
  },
  {
    label: "MERY-SUR-MARNE",
    value: "77290",
    type: "common",
  },
  {
    label: "CITRY",
    value: "77117",
    type: "common",
  },
  {
    label: "BUSSIERES",
    value: "77057",
    type: "common",
  },
  {
    label: "USSY-SUR-MARNE",
    value: "77478",
    type: "common",
  },
  {
    label: "SEPT-SORTS",
    value: "77448",
    type: "common",
  },
  {
    label: "REUIL-EN-BRIE",
    value: "77388",
    type: "common",
  },
  {
    label: "LA FERTE-SOUS-JOUARRE",
    value: "77183",
    type: "common",
  },
  {
    label: "SAMMERON",
    value: "77440",
    type: "common",
  },
  {
    label: "SAINT-JEAN-LES-DEUX-JUMEAUX",
    value: "77415",
    type: "common",
  },
  {
    label: "MONTCEAUX-LES-MEAUX",
    value: "77300",
    type: "common",
  },
  {
    label: "CHANGIS-SUR-MARNE",
    value: "77084",
    type: "common",
  },
  {
    label: "VILLEMAREUIL",
    value: "77505",
    type: "common",
  },
  {
    label: "TRILPORT",
    value: "77475",
    type: "common",
  },
  {
    label: "SAINT-FIACRE",
    value: "77408",
    type: "common",
  },
  {
    label: "POINCY",
    value: "77369",
    type: "common",
  },
  {
    label: "NANTEUIL-LES-MEAUX",
    value: "77330",
    type: "common",
  },
  {
    label: "MEAUX",
    value: "77284",
    type: "common",
  },
  {
    label: "FUBLAINES",
    value: "77199",
    type: "common",
  },
  {
    label: "VILLENOY",
    value: "77513",
    type: "common",
  },
  {
    label: "VIGNELY",
    value: "77498",
    type: "common",
  },
  {
    label: "TRILBARDOU",
    value: "77474",
    type: "common",
  },
  {
    label: "CHAUCONIN-NEUFMONTIERS",
    value: "77335",
    type: "common",
  },
  {
    label: "MAREUIL-LES-MEAUX",
    value: "77276",
    type: "common",
  },
  {
    label: "CREGY-LES-MEAUX",
    value: "77143",
    type: "common",
  },
  {
    label: "PRECY-SUR-MARNE",
    value: "77376",
    type: "common",
  },
  {
    label: "FRESNES-SUR-MARNE",
    value: "77196",
    type: "common",
  },
  {
    label: "CHARNY",
    value: "77095",
    type: "common",
  },
  {
    label: "CHARMENTRAY",
    value: "77094",
    type: "common",
  },
  {
    label: "CLAYE-SOUILLY",
    value: "77118",
    type: "common",
  },
  {
    label: "ANNET-SUR-MARNE",
    value: "77005",
    type: "common",
  },
  {
    label: "MESSY",
    value: "77292",
    type: "common",
  },
  {
    label: "GRESSY",
    value: "77214",
    type: "common",
  },
  {
    label: "VILLEPARISIS",
    value: "77514",
    type: "common",
  },
  {
    label: "VAUJOURS",
    value: "93074",
    type: "common",
  },
  {
    label: "VILLEPINTE",
    value: "93078",
    type: "common",
  },
  {
    label: "SEVRAN",
    value: "93071",
    type: "common",
  },
  {
    label: "LIVRY-GARGAN",
    value: "93046",
    type: "common",
  },
  {
    label: "LE BLANC-MESNIL",
    value: "93007",
    type: "common",
  },
  {
    label: "AULNAY-SOUS-BOIS",
    value: "93005",
    type: "common",
  },
  {
    label: "GARGES-LES-GONESSE",
    value: "95268",
    type: "common",
  },
  {
    label: "BONNEUIL-EN-FRANCE",
    value: "95088",
    type: "common",
  },
  {
    label: "LA COURNEUVE",
    value: "93027",
    type: "common",
  },
  {
    label: "STAINS",
    value: "93072",
    type: "common",
  },
  {
    label: "PIERREFITTE-SUR-SEINE",
    value: "93059",
    type: "common",
  },
  {
    label: "DUGNY",
    value: "93030",
    type: "common",
  },
  {
    label: "LE BOURGET",
    value: "93013",
    type: "common",
  },
  {
    label: "SAINT-GRATIEN",
    value: "95555",
    type: "common",
  },
  {
    label: "DEUIL-LA-BARRE",
    value: "95197",
    type: "common",
  },
  {
    label: "MONTMAGNY",
    value: "95427",
    type: "common",
  },
  {
    label: "ENGHIEN-LES-BAINS",
    value: "95210",
    type: "common",
  },
  {
    label: "L'ILE-SAINT-DENIS",
    value: "93039",
    type: "common",
  },
  {
    label: "VILLETANEUSE",
    value: "93079",
    type: "common",
  },
  {
    label: "EPINAY-SUR-SEINE",
    value: "93031",
    type: "common",
  },
  {
    label: "SAINT-DENIS",
    value: "93066",
    type: "common",
  },
  {
    label: "VILLENEUVE-LA-GARENNE",
    value: "92078",
    type: "common",
  },
  {
    label: "GENNEVILLIERS",
    value: "92036",
    type: "common",
  },
  {
    label: "SANNOIS",
    value: "95582",
    type: "common",
  },
  {
    label: "CORMEILLES-EN-PARISIS",
    value: "95176",
    type: "common",
  },
  {
    label: "BEZONS",
    value: "95063",
    type: "common",
  },
  {
    label: "ARGENTEUIL",
    value: "95018",
    type: "common",
  },
  {
    label: "COLOMBES",
    value: "92025",
    type: "common",
  },
  {
    label: "HOUILLES",
    value: "78311",
    type: "common",
  },
  {
    label: "LA FRETTE-SUR-SEINE",
    value: "95257",
    type: "common",
  },
  {
    label: "SARTROUVILLE",
    value: "78586",
    type: "common",
  },
  {
    label: "LE MESNIL-LE-ROI",
    value: "78396",
    type: "common",
  },
  {
    label: "MAISONS-LAFFITTE",
    value: "78358",
    type: "common",
  },
  {
    label: "POISSY",
    value: "78498",
    type: "common",
  },
  {
    label: "CARRIERES-SOUS-POISSY",
    value: "78123",
    type: "common",
  },
  {
    label: "ACHERES",
    value: "78005",
    type: "common",
  },
  {
    label: "VILLENNES-SUR-SEINE",
    value: "78672",
    type: "common",
  },
  {
    label: "VERNOUILLET",
    value: "78643",
    type: "common",
  },
  {
    label: "ORGEVAL",
    value: "78466",
    type: "common",
  },
  {
    label: "MORAINVILLIERS",
    value: "78431",
    type: "common",
  },
  {
    label: "MEDAN",
    value: "78384",
    type: "common",
  },
  {
    label: "ECQUEVILLY",
    value: "78206",
    type: "common",
  },
  {
    label: "CHAPET",
    value: "78140",
    type: "common",
  },
  {
    label: "NEZEL",
    value: "78451",
    type: "common",
  },
  {
    label: "FLINS-SUR-SEINE",
    value: "78238",
    type: "common",
  },
  {
    label: "LA FALAISE",
    value: "78230",
    type: "common",
  },
  {
    label: "BOUAFLE",
    value: "78090",
    type: "common",
  },
  {
    label: "BAZEMONT",
    value: "78049",
    type: "common",
  },
  {
    label: "AULNAY-SUR-MAULDRE",
    value: "78033",
    type: "common",
  },
  {
    label: "AUBERGENVILLE",
    value: "78029",
    type: "common",
  },
  {
    label: "PORCHEVILLE",
    value: "78501",
    type: "common",
  },
  {
    label: "MEZIERES-SUR-SEINE",
    value: "78402",
    type: "common",
  },
  {
    label: "GUERVILLE",
    value: "78291",
    type: "common",
  },
  {
    label: "EPONE",
    value: "78217",
    type: "common",
  },
  {
    label: "BOINVILLE-EN-MANTOIS",
    value: "78070",
    type: "common",
  },
  {
    label: "VILLETTE",
    value: "78677",
    type: "common",
  },
  {
    label: "VERT",
    value: "78647",
    type: "common",
  },
  {
    label: "SOINDRES",
    value: "78597",
    type: "common",
  },
  {
    label: "MANTES-LA-VILLE",
    value: "78362",
    type: "common",
  },
  {
    label: "MAGNANVILLE",
    value: "78354",
    type: "common",
  },
  {
    label: "JOUY-MAUVOISIN",
    value: "78324",
    type: "common",
  },
  {
    label: "FONTENAY-MAUVOISIN",
    value: "78245",
    type: "common",
  },
  {
    label: "BREUIL-BOIS-ROBERT",
    value: "78104",
    type: "common",
  },
  {
    label: "BOINVILLIERS",
    value: "78072",
    type: "common",
  },
  {
    label: "AUFFREVILLE-BRASSEUIL",
    value: "78031",
    type: "common",
  },
  {
    label: "LE TERTRE-SAINT-DENIS",
    value: "78608",
    type: "common",
  },
  {
    label: "PERDREAUVILLE",
    value: "78484",
    type: "common",
  },
  {
    label: "MENERVILLE",
    value: "78385",
    type: "common",
  },
  {
    label: "LONGNES",
    value: "78346",
    type: "common",
  },
  {
    label: "FLACOURT",
    value: "78234",
    type: "common",
  },
  {
    label: "FAVRIEUX",
    value: "78231",
    type: "common",
  },
  {
    label: "BOISSY-MAUVOISIN",
    value: "78082",
    type: "common",
  },
  {
    label: "SAINT-ILLIERS-LE-BOIS",
    value: "78559",
    type: "common",
  },
  {
    label: "NEAUPHLETTE",
    value: "78444",
    type: "common",
  },
  {
    label: "BREVAL",
    value: "78107",
    type: "common",
  },
  {
    label: "GUAINVILLE",
    value: "28187",
    type: "common",
  },
  {
    label: "VILLIERS-EN-DESŒUVRE",
    value: "27696",
    type: "common",
  },
  {
    label: "NEUILLY",
    value: "27429",
    type: "common",
  },
  {
    label: "MEREY",
    value: "27400",
    type: "common",
  },
  {
    label: "EPIEDS",
    value: "27220",
    type: "common",
  },
  {
    label: "BUEIL",
    value: "27119",
    type: "common",
  },
  {
    label: "BREUILPONT",
    value: "27114",
    type: "common",
  },
  {
    label: "SEREZ",
    value: "27621",
    type: "common",
  },
  {
    label: "FRESNEY",
    value: "27271",
    type: "common",
  },
  {
    label: "FOUCRAINVILLE",
    value: "27259",
    type: "common",
  },
  {
    label: "BRETAGNOLLES",
    value: "27111",
    type: "common",
  },
  {
    label: "LA BOISSIERE",
    value: "27078",
    type: "common",
  },
  {
    label: "BOISSET-LES-PREVANCHES",
    value: "27076",
    type: "common",
  },
  {
    label: "SAINT-GERMAIN-DE-FRESNEY",
    value: "27544",
    type: "common",
  },
  {
    label: "PREY",
    value: "27478",
    type: "common",
  },
  {
    label: "LA BARONNIE",
    value: "27277",
    type: "common",
  },
  {
    label: "LA FORET-DU-PARC",
    value: "27256",
    type: "common",
  },
  {
    label: "LE PLESSIS-GROHAN",
    value: "27464",
    type: "common",
  },
  {
    label: "GUICHAINVILLE",
    value: "27306",
    type: "common",
  },
  {
    label: "GROSSŒUVRE",
    value: "27301",
    type: "common",
  },
  {
    label: "LES BAUX-SAINTE-CROIX",
    value: "27044",
    type: "common",
  },
  {
    label: "CHAMBOIS",
    value: "27032",
    type: "common",
  },
  {
    label: "LES VENTES",
    value: "27678",
    type: "common",
  },
  {
    label: "GAUDREVILLE-LA-RIVIERE",
    value: "27281",
    type: "common",
  },
  {
    label: "NOGENT-LE-SEC",
    value: "27436",
    type: "common",
  },
  {
    label: "NAGEL-SEEZ-MESNIL",
    value: "27424",
    type: "common",
  },
  {
    label: "LE VAL-DORE",
    value: "27447",
    type: "common",
  },
  {
    label: "CHAMP-DOLENT",
    value: "27141",
    type: "common",
  },
  {
    label: "SAINTE-MARTHE",
    value: "27568",
    type: "common",
  },
  {
    label: "LE LESME",
    value: "27565",
    type: "common",
  },
  {
    label: "CONCHES-EN-OUCHE",
    value: "27165",
    type: "common",
  },
  {
    label: "LE FIDELAIRE",
    value: "27242",
    type: "common",
  },
  {
    label: "BOIS-ANZERAY",
    value: "27068",
    type: "common",
  },
  {
    label: "LA HAYE-SAINT-SYLVESTRE",
    value: "27323",
    type: "common",
  },
  {
    label: "SAINT-PIERRE-DE-CERNIERES",
    value: "27590",
    type: "common",
  },
  {
    label: "SAINT-AGNAN-DE-CERNIERES",
    value: "27505",
    type: "common",
  },
  {
    label: "MELICOURT",
    value: "27395",
    type: "common",
  },
  {
    label: "VERNEUSSES",
    value: "27680",
    type: "common",
  },
  {
    label: "SAINT-DENIS-D'AUGERONS",
    value: "27530",
    type: "common",
  },
  {
    label: "MONTREUIL-L'ARGILLE",
    value: "27414",
    type: "common",
  },
  {
    label: "LA GOULAFRIERE",
    value: "27289",
    type: "common",
  },
  {
    label: "SAINT-GERMAIN-D'AUNAY",
    value: "61392",
    type: "common",
  },
  {
    label: "SAINT-AUBIN-DE-BONNEVAL",
    value: "61366",
    type: "common",
  },
  {
    label: "LE BOSC-RENOULT",
    value: "61054",
    type: "common",
  },
  {
    label: "TICHEVILLE",
    value: "61485",
    type: "common",
  },
  {
    label: "PONTCHARDON",
    value: "61333",
    type: "common",
  },
  {
    label: "CANAPVILLE",
    value: "61072",
    type: "common",
  },
  {
    label: "AVERNES-SAINT-GOURGON",
    value: "61018",
    type: "common",
  },
  {
    label: "VIMOUTIERS",
    value: "61508",
    type: "common",
  },
  {
    label: "CROUTTES",
    value: "61139",
    type: "common",
  },
  {
    label: "VAL-DE-VIE",
    value: "14576",
    type: "common",
  },
  {
    label: "LISORES",
    value: "14368",
    type: "common",
  },
  {
    label: "SAINT-GERVAIS-DES-SABLONS",
    value: "61399",
    type: "common",
  },
  {
    label: "LE RENOUARD",
    value: "61346",
    type: "common",
  },
  {
    label: "NORREY-EN-AUGE",
    value: "14469",
    type: "common",
  },
  {
    label: "LES MOUTIERS-EN-AUGE",
    value: "14457",
    type: "common",
  },
  {
    label: "LOUVAGNY",
    value: "14381",
    type: "common",
  },
  {
    label: "BAROU-EN-AUGE",
    value: "14043",
    type: "common",
  },
  {
    label: "VILLY-LEZ-FALAISE",
    value: "14759",
    type: "common",
  },
  {
    label: "VICQUES",
    value: "14742",
    type: "common",
  },
  {
    label: "MORTEAUX-COULIBŒUF",
    value: "14452",
    type: "common",
  },
  {
    label: "DAMBLAINVILLE",
    value: "14216",
    type: "common",
  },
  {
    label: "BERNIERES-D'AILLY",
    value: "14064",
    type: "common",
  },
  {
    label: "BEAUMAIS",
    value: "14053",
    type: "common",
  },
  {
    label: "VERSAINVILLE",
    value: "14737",
    type: "common",
  },
  {
    label: "SOULANGY",
    value: "14677",
    type: "common",
  },
  {
    label: "ERAINES",
    value: "14244",
    type: "common",
  },
  {
    label: "EPANEY",
    value: "14240",
    type: "common",
  },
  {
    label: "AUBIGNY",
    value: "14025",
    type: "common",
  },
  {
    label: "VILLERS-CANIVET",
    value: "14753",
    type: "common",
  },
  {
    label: "USSY",
    value: "14720",
    type: "common",
  },
  {
    label: "SAINT-PIERRE-CANIVET",
    value: "14646",
    type: "common",
  },
  {
    label: "NORON-L'ABBAYE",
    value: "14467",
    type: "common",
  },
  {
    label: "MARTIGNY-SUR-L'ANTE",
    value: "14405",
    type: "common",
  },
  {
    label: "LEFFARD",
    value: "14360",
    type: "common",
  },
  {
    label: "TREPREL",
    value: "14710",
    type: "common",
  },
  {
    label: "SAINT-GERMAIN-LANGOT",
    value: "14588",
    type: "common",
  },
  {
    label: "PIERREFITTE-EN-CINGLAIS",
    value: "14501",
    type: "common",
  },
  {
    label: "MARTAINVILLE",
    value: "14404",
    type: "common",
  },
  {
    label: "BONNŒIL",
    value: "14087",
    type: "common",
  },
  {
    label: "LE VEY",
    value: "14741",
    type: "common",
  },
  {
    label: "SAINT-REMY",
    value: "14656",
    type: "common",
  },
  {
    label: "SAINT-OMER",
    value: "14635",
    type: "common",
  },
  {
    label: "LA POMMERAYE",
    value: "14510",
    type: "common",
  },
  {
    label: "COSSESSEVILLE",
    value: "14183",
    type: "common",
  },
  {
    label: "CLECY",
    value: "14162",
    type: "common",
  },
  {
    label: "LE BO",
    value: "14080",
    type: "common",
  },
  {
    label: "LA VILLETTE",
    value: "14756",
    type: "common",
  },
  {
    label: "SAINT-LAMBERT",
    value: "14602",
    type: "common",
  },
  {
    label: "PONTECOULANT",
    value: "14512",
    type: "common",
  },
  {
    label: "PERIGNY",
    value: "14496",
    type: "common",
  },
  {
    label: "TERRES DE DRUANCE",
    value: "14357",
    type: "common",
  },
  {
    label: "SOULEUVRE EN BOCAGE",
    value: "14061",
    type: "common",
  },
  {
    label: "CAMPAGNOLLES",
    value: "14127",
    type: "common",
  },
  {
    label: "PONT-BELLANGER",
    value: "14511",
    type: "common",
  },
  {
    label: "LE MESNIL-ROBERT",
    value: "14424",
    type: "common",
  },
  {
    label: "LANDELLES-ET-COUPIGNY",
    value: "14352",
    type: "common",
  },
  {
    label: "BEAUMESNIL",
    value: "14054",
    type: "common",
  },
  {
    label: "SAINT-VIGOR-DES-MONTS",
    value: "50563",
    type: "common",
  },
  {
    label: "MORIGNY",
    value: "50357",
    type: "common",
  },
  {
    label: "MONTBRAY",
    value: "50338",
    type: "common",
  },
  {
    label: "MARGUERAY",
    value: "50291",
    type: "common",
  },
  {
    label: "GOUVETS",
    value: "50214",
    type: "common",
  },
  {
    label: "PERCY-EN-NORMANDIE",
    value: "50393",
    type: "common",
  },
  {
    label: "LA COLOMBE",
    value: "50137",
    type: "common",
  },
  {
    label: "MONTAIGU-LES-BOIS",
    value: "50336",
    type: "common",
  },
  {
    label: "LE MESNIL-GARNIER",
    value: "50311",
    type: "common",
  },
  {
    label: "LA BLOUTIERE",
    value: "50060",
    type: "common",
  },
  {
    label: "GAVRAY-SUR-SIENNE",
    value: "50197",
    type: "common",
  },
  {
    label: "VER",
    value: "50626",
    type: "common",
  },
  {
    label: "LA MEURDRAQUIERE",
    value: "50327",
    type: "common",
  },
  {
    label: "LE LOREUR",
    value: "50278",
    type: "common",
  },
  {
    label: "HUDIMESNIL",
    value: "50252",
    type: "common",
  },
  {
    label: "CHANTELOUP",
    value: "50120",
    type: "common",
  },
  {
    label: "CERENCES",
    value: "50109",
    type: "common",
  },
  {
    label: "COUDEVILLE-SUR-MER",
    value: "50143",
    type: "common",
  },
  {
    label: "BREVILLE-SUR-MER",
    value: "50081",
    type: "common",
  },
  {
    label: "BREHAL",
    value: "50076",
    type: "common",
  },
  {
    label: "GRANVILLE",
    value: "50218",
    type: "common",
  },
  {
    label: "PLOUBAZLANEC",
    value: "22210",
    type: "common",
  },
  {
    label: "ILE-DE-BREHAT",
    value: "22016",
    type: "common",
  },
  {
    label: "LEZARDRIEUX",
    value: "22127",
    type: "common",
  },
  {
    label: "PLEUMEUR-GAUTIER",
    value: "22199",
    type: "common",
  },
  {
    label: "PLEUBIAN",
    value: "22195",
    type: "common",
  },
  {
    label: "TREDARZEC",
    value: "22347",
    type: "common",
  },
  {
    label: "KERBORS",
    value: "22085",
    type: "common",
  },
  {
    label: "PLOUGUIEL",
    value: "22221",
    type: "common",
  },
  {
    label: "TREVOU-TREGUIGNEC",
    value: "22379",
    type: "common",
  },
  {
    label: "TRELEVERN",
    value: "22363",
    type: "common",
  },
  {
    label: "LOUANNEC",
    value: "22134",
    type: "common",
  },
  {
    label: "SAINT-QUAY-PERROS",
    value: "22324",
    type: "common",
  },
  {
    label: "TREGASTEL",
    value: "22353",
    type: "common",
  },
  {
    label: "TREBEURDEN",
    value: "22343",
    type: "common",
  },
  {
    label: "PLEUMEUR-BODOU",
    value: "22198",
    type: "common",
  },
  {
    label: "DALHUNDEN",
    value: "67082",
    type: "common",
  },
  {
    label: "ROHRWILLER",
    value: "67407",
    type: "common",
  },
  {
    label: "HERRLISHEIM",
    value: "67194",
    type: "common",
  },
  {
    label: "DRUSENHEIM",
    value: "67106",
    type: "common",
  },
  {
    label: "OBERHOFFEN-SUR-MODER",
    value: "67345",
    type: "common",
  },
  {
    label: "KURTZENHOUSE",
    value: "67252",
    type: "common",
  },
  {
    label: "KALTENHOUSE",
    value: "67230",
    type: "common",
  },
  {
    label: "GRIES",
    value: "67169",
    type: "common",
  },
  {
    label: "BISCHWILLER",
    value: "67046",
    type: "common",
  },
  {
    label: "WEITBRUCH",
    value: "67523",
    type: "common",
  },
  {
    label: "NIEDERSCHAEFFOLSHEIM",
    value: "67331",
    type: "common",
  },
  {
    label: "KRIEGSHEIM",
    value: "67250",
    type: "common",
  },
  {
    label: "WITTERSHEIM",
    value: "67546",
    type: "common",
  },
  {
    label: "WINTERSHOUSE",
    value: "67540",
    type: "common",
  },
  {
    label: "WALTENHEIM-SUR-ZORN",
    value: "67516",
    type: "common",
  },
  {
    label: "WAHLENHEIM",
    value: "67510",
    type: "common",
  },
  {
    label: "ROTTELSHEIM",
    value: "67417",
    type: "common",
  },
  {
    label: "MOMMENHEIM",
    value: "67301",
    type: "common",
  },
  {
    label: "MINVERSHEIM",
    value: "67293",
    type: "common",
  },
  {
    label: "HOCHSTETT",
    value: "67203",
    type: "common",
  },
  {
    label: "BERSTHEIM",
    value: "67035",
    type: "common",
  },
  {
    label: "BERNOLSHEIM",
    value: "67033",
    type: "common",
  },
  {
    label: "BATZENDORF",
    value: "67023",
    type: "common",
  },
  {
    label: "WICKERSHEIM-WILSHAUSEN",
    value: "67530",
    type: "common",
  },
  {
    label: "SCHWINDRATZHEIM",
    value: "67460",
    type: "common",
  },
  {
    label: "SCHERLENHEIM",
    value: "67444",
    type: "common",
  },
  {
    label: "LIXHAUSEN",
    value: "67270",
    type: "common",
  },
  {
    label: "HOCHFELDEN",
    value: "67202",
    type: "common",
  },
  {
    label: "BOSSENDORF",
    value: "67058",
    type: "common",
  },
  {
    label: "ALTECKENDORF",
    value: "67005",
    type: "common",
  },
  {
    label: "WILWISHEIM",
    value: "67534",
    type: "common",
  },
  {
    label: "PRINTZHEIM",
    value: "67380",
    type: "common",
  },
  {
    label: "MELSHEIM",
    value: "67287",
    type: "common",
  },
  {
    label: "ISSENHAUSEN",
    value: "67225",
    type: "common",
  },
  {
    label: "HATTMATT",
    value: "67185",
    type: "common",
  },
  {
    label: "GOTTESHEIM",
    value: "67162",
    type: "common",
  },
  {
    label: "GEISWILLER-ZŒBERSDORF",
    value: "67153",
    type: "common",
  },
  {
    label: "DETTWILLER",
    value: "67089",
    type: "common",
  },
  {
    label: "STEINBOURG",
    value: "67478",
    type: "common",
  },
  {
    label: "MONSWILLER",
    value: "67302",
    type: "common",
  },
  {
    label: "ERNOLSHEIM-LES-SAVERNE",
    value: "67129",
    type: "common",
  },
  {
    label: "DOSSENHEIM-SUR-ZINSEL",
    value: "67103",
    type: "common",
  },
  {
    label: "SAINT-JEAN-SAVERNE",
    value: "67425",
    type: "common",
  },
  {
    label: "OTTERSTHAL",
    value: "67366",
    type: "common",
  },
  {
    label: "ECKARTSWILLER",
    value: "67117",
    type: "common",
  },
  {
    label: "DANNE-ET-QUATRE-VENTS",
    value: "57168",
    type: "common",
  },
  {
    label: "PFALZWEYER",
    value: "67373",
    type: "common",
  },
  {
    label: "ZILLING",
    value: "57761",
    type: "common",
  },
  {
    label: "WINTERSBOURG",
    value: "57747",
    type: "common",
  },
  {
    label: "VILSBERG",
    value: "57721",
    type: "common",
  },
  {
    label: "VESCHEIM",
    value: "57709",
    type: "common",
  },
  {
    label: "PHALSBOURG",
    value: "57540",
    type: "common",
  },
  {
    label: "MITTELBRONN",
    value: "57468",
    type: "common",
  },
  {
    label: "METTING",
    value: "57462",
    type: "common",
  },
  {
    label: "HANGVILLER",
    value: "57291",
    type: "common",
  },
  {
    label: "BOURSCHEID",
    value: "57100",
    type: "common",
  },
  {
    label: "BERLING",
    value: "57064",
    type: "common",
  },
  {
    label: "RAUWILLER",
    value: "67386",
    type: "common",
  },
  {
    label: "VIEUX-LIXHEIM",
    value: "57713",
    type: "common",
  },
  {
    label: "SCHALBACH",
    value: "57635",
    type: "common",
  },
  {
    label: "LIXHEIM",
    value: "57407",
    type: "common",
  },
  {
    label: "HILBESHEIM",
    value: "57324",
    type: "common",
  },
  {
    label: "HERANGE",
    value: "57317",
    type: "common",
  },
  {
    label: "FLEISHEIM",
    value: "57216",
    type: "common",
  },
  {
    label: "BICKENHOLTZ",
    value: "57080",
    type: "common",
  },
  {
    label: "GŒRLINGEN",
    value: "67159",
    type: "common",
  },
  {
    label: "SARRALTROFF",
    value: "57629",
    type: "common",
  },
  {
    label: "OBERSTINZEL",
    value: "57518",
    type: "common",
  },
  {
    label: "HELLERING-LES-FENETRANGE",
    value: "57310",
    type: "common",
  },
  {
    label: "DOLVING",
    value: "57180",
    type: "common",
  },
  {
    label: "BETTBORN",
    value: "57071",
    type: "common",
  },
  {
    label: "SAINT-JEAN-DE-BASSEL",
    value: "57613",
    type: "common",
  },
  {
    label: "GOSSELMING",
    value: "57255",
    type: "common",
  },
  {
    label: "BERTHELMING",
    value: "57066",
    type: "common",
  },
  {
    label: "RORBACH-LES-DIEUZE",
    value: "57595",
    type: "common",
  },
  {
    label: "DESSELING",
    value: "57173",
    type: "common",
  },
  {
    label: "BELLES-FORETS",
    value: "57086",
    type: "common",
  },
  {
    label: "ZOMMANGE",
    value: "57763",
    type: "common",
  },
  {
    label: "TARQUIMPOL",
    value: "57664",
    type: "common",
  },
  {
    label: "LINDRE-HAUTE",
    value: "57405",
    type: "common",
  },
  {
    label: "LINDRE-BASSE",
    value: "57404",
    type: "common",
  },
  {
    label: "GUERMANGE",
    value: "57272",
    type: "common",
  },
  {
    label: "MULCEY",
    value: "57493",
    type: "common",
  },
  {
    label: "VAL-DE-BRIDE",
    value: "57270",
    type: "common",
  },
  {
    label: "DIEUZE",
    value: "57177",
    type: "common",
  },
  {
    label: "BLANCHE-EGLISE",
    value: "57090",
    type: "common",
  },
  {
    label: "SAINT-MEDARD",
    value: "57621",
    type: "common",
  },
  {
    label: "HARAUCOURT-SUR-SEILLE",
    value: "57295",
    type: "common",
  },
  {
    label: "HAMPONT",
    value: "57290",
    type: "common",
  },
  {
    label: "CHATEAU-VOUE",
    value: "57133",
    type: "common",
  },
  {
    label: "SALONNES",
    value: "57625",
    type: "common",
  },
  {
    label: "MORVILLE-LES-VIC",
    value: "57485",
    type: "common",
  },
  {
    label: "LUBECOURT",
    value: "57423",
    type: "common",
  },
  {
    label: "CHATEAU-SALINS",
    value: "57132",
    type: "common",
  },
  {
    label: "AMELECOURT",
    value: "57018",
    type: "common",
  },
  {
    label: "JALLAUCOURT",
    value: "57349",
    type: "common",
  },
  {
    label: "GREMECEY",
    value: "57257",
    type: "common",
  },
  {
    label: "FRESNES-EN-SAULNOIS",
    value: "57238",
    type: "common",
  },
  {
    label: "CHAMBREY",
    value: "57126",
    type: "common",
  },
  {
    label: "ATTILLONCOURT",
    value: "57036",
    type: "common",
  },
  {
    label: "MANHOUE",
    value: "57440",
    type: "common",
  },
  {
    label: "MALAUCOURT-SUR-SEILLE",
    value: "57436",
    type: "common",
  },
  {
    label: "FOSSIEUX",
    value: "57228",
    type: "common",
  },
  {
    label: "BIONCOURT",
    value: "57084",
    type: "common",
  },
  {
    label: "AJONCOURT",
    value: "57009",
    type: "common",
  },
  {
    label: "ABONCOURT-SUR-SEILLE",
    value: "57002",
    type: "common",
  },
  {
    label: "LANFROICOURT",
    value: "54301",
    type: "common",
  },
  {
    label: "BEY-SUR-SEILLE",
    value: "54070",
    type: "common",
  },
  {
    label: "ARRAYE-ET-HAN",
    value: "54024",
    type: "common",
  },
  {
    label: "ARMAUCOURT",
    value: "54021",
    type: "common",
  },
  {
    label: "VILLERS-LES-MOIVRONS",
    value: "54577",
    type: "common",
  },
  {
    label: "SIVRY",
    value: "54508",
    type: "common",
  },
  {
    label: "MOIVRONS",
    value: "54372",
    type: "common",
  },
  {
    label: "LEYR",
    value: "54315",
    type: "common",
  },
  {
    label: "JEANDELAINCOURT",
    value: "54276",
    type: "common",
  },
  {
    label: "BRATTE",
    value: "54095",
    type: "common",
  },
  {
    label: "VILLE-AU-VAL",
    value: "54569",
    type: "common",
  },
  {
    label: "MILLERY",
    value: "54369",
    type: "common",
  },
  {
    label: "LANDREMONT",
    value: "54294",
    type: "common",
  },
  {
    label: "BELLEAU",
    value: "54059",
    type: "common",
  },
  {
    label: "AUTREVILLE-SUR-MOSELLE",
    value: "54031",
    type: "common",
  },
  {
    label: "DIEULOUARD",
    value: "54157",
    type: "common",
  },
  {
    label: "BEZAUMONT",
    value: "54072",
    type: "common",
  },
  {
    label: "BELLEVILLE",
    value: "54060",
    type: "common",
  },
  {
    label: "VILLERS-EN-HAYE",
    value: "54573",
    type: "common",
  },
  {
    label: "ROGEVILLE",
    value: "54460",
    type: "common",
  },
  {
    label: "MARTINCOURT",
    value: "54355",
    type: "common",
  },
  {
    label: "GRISCOURT",
    value: "54239",
    type: "common",
  },
  {
    label: "GEZONCOURT",
    value: "54225",
    type: "common",
  },
  {
    label: "DOMEVRE-EN-HAYE",
    value: "54160",
    type: "common",
  },
  {
    label: "NOVIANT-AUX-PRES",
    value: "54404",
    type: "common",
  },
  {
    label: "MINORVILLE",
    value: "54370",
    type: "common",
  },
  {
    label: "MANONVILLE",
    value: "54348",
    type: "common",
  },
  {
    label: "GROSROUVRES",
    value: "54240",
    type: "common",
  },
  {
    label: "RAMBUCOURT",
    value: "55412",
    type: "common",
  },
  {
    label: "SEICHEPREY",
    value: "54499",
    type: "common",
  },
  {
    label: "MANDRES-AUX-QUATRE-TOURS",
    value: "54343",
    type: "common",
  },
  {
    label: "HAMONVILLE",
    value: "54248",
    type: "common",
  },
  {
    label: "BERNECOURT",
    value: "54063",
    type: "common",
  },
  {
    label: "BEAUMONT",
    value: "54057",
    type: "common",
  },
  {
    label: "ANSAUVILLE",
    value: "54019",
    type: "common",
  },
  {
    label: "XIVRAY-ET-MARVOISIN",
    value: "55586",
    type: "common",
  },
  {
    label: "LOUPMONT",
    value: "55303",
    type: "common",
  },
  {
    label: "BROUSSEY-RAULECOURT",
    value: "55085",
    type: "common",
  },
  {
    label: "BOUCONVILLE-SUR-MADT",
    value: "55062",
    type: "common",
  },
  {
    label: "APREMONT-LA-FORET",
    value: "55012",
    type: "common",
  },
  {
    label: "SAMPIGNY",
    value: "55467",
    type: "common",
  },
  {
    label: "MECRIN",
    value: "55329",
    type: "common",
  },
  {
    label: "HAN-SUR-MEUSE",
    value: "55229",
    type: "common",
  },
  {
    label: "BISLEE",
    value: "55054",
    type: "common",
  },
  {
    label: "KŒUR-LA-PETITE",
    value: "55264",
    type: "common",
  },
  {
    label: "KŒUR-LA-GRANDE",
    value: "55263",
    type: "common",
  },
  {
    label: "COURCELLES-EN-BARROIS",
    value: "55127",
    type: "common",
  },
  {
    label: "VILLOTTE-SUR-AIRE",
    value: "55570",
    type: "common",
  },
  {
    label: "GIMECOURT",
    value: "55210",
    type: "common",
  },
  {
    label: "VILLE-DEVANT-BELRAIN",
    value: "55555",
    type: "common",
  },
  {
    label: "RUPT-DEVANT-SAINT-MIHIEL",
    value: "55448",
    type: "common",
  },
  {
    label: "LEVONCOURT",
    value: "55289",
    type: "common",
  },
  {
    label: "BAUDREMONT",
    value: "55032",
    type: "common",
  },
  {
    label: "BELRAIN",
    value: "55044",
    type: "common",
  },
  {
    label: "SEIGNEULLES",
    value: "55479",
    type: "common",
  },
  {
    label: "RUMONT",
    value: "55446",
    type: "common",
  },
  {
    label: "ERIZE-LA-BRULEE",
    value: "55175",
    type: "common",
  },
  {
    label: "LES HAUTS-DE-CHEE",
    value: "55123",
    type: "common",
  },
  {
    label: "CHARDOGNE",
    value: "55101",
    type: "common",
  },
  {
    label: "LOUPPY-LE-CHATEAU",
    value: "55304",
    type: "common",
  },
  {
    label: "LAIMONT",
    value: "55272",
    type: "common",
  },
  {
    label: "VILLERS-AUX-VENTS",
    value: "55560",
    type: "common",
  },
  {
    label: "NOYERS-AUZECOURT",
    value: "55388",
    type: "common",
  },
  {
    label: "BRABANT-LE-ROI",
    value: "55069",
    type: "common",
  },
  {
    label: "VROIL",
    value: "51658",
    type: "common",
  },
  {
    label: "CHARMONT",
    value: "51130",
    type: "common",
  },
  {
    label: "NETTANCOURT",
    value: "55378",
    type: "common",
  },
  {
    label: "SAINT-JEAN-DEVANT-POSSESSE",
    value: "51489",
    type: "common",
  },
  {
    label: "VERNANCOURT",
    value: "51608",
    type: "common",
  },
  {
    label: "VANAULT-LES-DAMES",
    value: "51590",
    type: "common",
  },
  {
    label: "VANAULT-LE-CHATEL",
    value: "51589",
    type: "common",
  },
  {
    label: "FRANCHEVILLE",
    value: "51259",
    type: "common",
  },
  {
    label: "DAMPIERRE-SUR-MOIVRE",
    value: "51208",
    type: "common",
  },
  {
    label: "SAINT-JEAN-SUR-MOIVRE",
    value: "51490",
    type: "common",
  },
  {
    label: "LA CHAUSSEE-SUR-MARNE",
    value: "51141",
    type: "common",
  },
  {
    label: "SAINT-GERMAIN-LA-VILLE",
    value: "51482",
    type: "common",
  },
  {
    label: "VESIGNEUL-SUR-MARNE",
    value: "51616",
    type: "common",
  },
  {
    label: "POGNY",
    value: "51436",
    type: "common",
  },
  {
    label: "OMEY",
    value: "51415",
    type: "common",
  },
  {
    label: "TOGNY-AUX-BŒUFS",
    value: "51574",
    type: "common",
  },
  {
    label: "SOGNY-AUX-MOULINS",
    value: "51538",
    type: "common",
  },
  {
    label: "MAIRY-SUR-MARNE",
    value: "51339",
    type: "common",
  },
  {
    label: "ECURY-SUR-COOLE",
    value: "51227",
    type: "common",
  },
  {
    label: "SAINT-QUENTIN-SUR-COOLE",
    value: "51512",
    type: "common",
  },
  {
    label: "NUISEMENT-SUR-COOLE",
    value: "51409",
    type: "common",
  },
  {
    label: "BREUVERY-SUR-COOLE",
    value: "51087",
    type: "common",
  },
  {
    label: "CHENIERS",
    value: "51146",
    type: "common",
  },
  {
    label: "GERMINON",
    value: "51268",
    type: "common",
  },
  {
    label: "VELYE",
    value: "51603",
    type: "common",
  },
  {
    label: "TRECON",
    value: "51578",
    type: "common",
  },
  {
    label: "CHAINTRIX-BIERGES",
    value: "51107",
    type: "common",
  },
  {
    label: "BERGERES-LES-VERTUS",
    value: "51049",
    type: "common",
  },
  {
    label: "BLANCS-COTEAUX",
    value: "51612",
    type: "common",
  },
  {
    label: "ETRECHY",
    value: "51239",
    type: "common",
  },
  {
    label: "SOULIERES",
    value: "51558",
    type: "common",
  },
  {
    label: "LOISY-EN-BRIE",
    value: "51327",
    type: "common",
  },
  {
    label: "GIVRY-LES-LOISY",
    value: "51273",
    type: "common",
  },
  {
    label: "BEAUNAY",
    value: "51045",
    type: "common",
  },
  {
    label: "FEREBRIANGES",
    value: "51247",
    type: "common",
  },
  {
    label: "ETOGES",
    value: "51238",
    type: "common",
  },
  {
    label: "CONGY",
    value: "51163",
    type: "common",
  },
  {
    label: "CHAMPAUBERT",
    value: "51113",
    type: "common",
  },
  {
    label: "BAYE",
    value: "51042",
    type: "common",
  },
  {
    label: "BANNAY",
    value: "51034",
    type: "common",
  },
  {
    label: "LA CAURE",
    value: "51100",
    type: "common",
  },
  {
    label: "FROMENTIERES",
    value: "51263",
    type: "common",
  },
  {
    label: "LE THOULT-TROSNAY",
    value: "51570",
    type: "common",
  },
  {
    label: "VAUCHAMPS",
    value: "51596",
    type: "common",
  },
  {
    label: "JANVILLIERS",
    value: "51304",
    type: "common",
  },
  {
    label: "MONTMIRAIL",
    value: "51380",
    type: "common",
  },
  {
    label: "DHUYS ET MORIN-EN-BRIE",
    value: "02458",
    type: "common",
  },
  {
    label: "L'EPINE-AUX-BOIS",
    value: "02281",
    type: "common",
  },
  {
    label: "MECRINGES",
    value: "51359",
    type: "common",
  },
  {
    label: "VENDIERES",
    value: "02777",
    type: "common",
  },
  {
    label: "VERDELOT",
    value: "77492",
    type: "common",
  },
  {
    label: "VIELS-MAISONS",
    value: "02798",
    type: "common",
  },
  {
    label: "VILLENEUVE-SUR-BELLOT",
    value: "77512",
    type: "common",
  },
  {
    label: "LA TRETOIRE",
    value: "77472",
    type: "common",
  },
  {
    label: "SABLONNIERES",
    value: "77398",
    type: "common",
  },
  {
    label: "HONDEVILLIERS",
    value: "77228",
    type: "common",
  },
  {
    label: "BOITRON",
    value: "77043",
    type: "common",
  },
  {
    label: "SAINT-OUEN-SUR-MORIN",
    value: "77429",
    type: "common",
  },
  {
    label: "REBAIS",
    value: "77385",
    type: "common",
  },
  {
    label: "ORLY-SUR-MORIN",
    value: "77345",
    type: "common",
  },
  {
    label: "JOUARRE",
    value: "77238",
    type: "common",
  },
  {
    label: "DOUE",
    value: "77162",
    type: "common",
  },
  {
    label: "SIGNY-SIGNETS",
    value: "77451",
    type: "common",
  },
  {
    label: "PIERRE-LEVEE",
    value: "77361",
    type: "common",
  },
  {
    label: "LA HAUTE-MAISON",
    value: "77225",
    type: "common",
  },
  {
    label: "VAUCOURTOIS",
    value: "77484",
    type: "common",
  },
  {
    label: "SANCY",
    value: "77443",
    type: "common",
  },
  {
    label: "CRECY-LA-CHAPELLE",
    value: "77142",
    type: "common",
  },
  {
    label: "COULOMMES",
    value: "77130",
    type: "common",
  },
  {
    label: "BOUTIGNY",
    value: "77049",
    type: "common",
  },
  {
    label: "BOULEURS",
    value: "77047",
    type: "common",
  },
  {
    label: "MAGNY-LE-HONGRE",
    value: "77268",
    type: "common",
  },
  {
    label: "SAINT-GERMAIN-SUR-MORIN",
    value: "77413",
    type: "common",
  },
  {
    label: "QUINCY-VOISINS",
    value: "77382",
    type: "common",
  },
  {
    label: "MONTRY",
    value: "77315",
    type: "common",
  },
  {
    label: "ISLES-LES-VILLENOY",
    value: "77232",
    type: "common",
  },
  {
    label: "ESBLY",
    value: "77171",
    type: "common",
  },
  {
    label: "COUILLY-PONT-AUX-DAMES",
    value: "77128",
    type: "common",
  },
  {
    label: "CONDE-SAINTE-LIBIAIRE",
    value: "77125",
    type: "common",
  },
  {
    label: "MONTEVRAIN",
    value: "77307",
    type: "common",
  },
  {
    label: "LESCHES",
    value: "77248",
    type: "common",
  },
  {
    label: "JABLINES",
    value: "77234",
    type: "common",
  },
  {
    label: "DAMPMART",
    value: "77155",
    type: "common",
  },
  {
    label: "COUPVRAY",
    value: "77132",
    type: "common",
  },
  {
    label: "CHESSY",
    value: "77111",
    type: "common",
  },
  {
    label: "CHALIFERT",
    value: "77075",
    type: "common",
  },
  {
    label: "VAIRES-SUR-MARNE",
    value: "77479",
    type: "common",
  },
  {
    label: "BROU-SUR-CHANTEREINE",
    value: "77055",
    type: "common",
  },
  {
    label: "THORIGNY-SUR-MARNE",
    value: "77464",
    type: "common",
  },
  {
    label: "CARNETIN",
    value: "77062",
    type: "common",
  },
  {
    label: "SAINT-THIBAULT-DES-VIGNES",
    value: "77438",
    type: "common",
  },
  {
    label: "POMPONNE",
    value: "77372",
    type: "common",
  },
  {
    label: "LAGNY-SUR-MARNE",
    value: "77243",
    type: "common",
  },
  {
    label: "VILLEVAUDE",
    value: "77517",
    type: "common",
  },
  {
    label: "LE PIN",
    value: "77363",
    type: "common",
  },
  {
    label: "COURTRY",
    value: "77139",
    type: "common",
  },
  {
    label: "CHELLES",
    value: "77108",
    type: "common",
  },
  {
    label: "MONTFERMEIL",
    value: "93047",
    type: "common",
  },
  {
    label: "GOURNAY-SUR-MARNE",
    value: "93033",
    type: "common",
  },
  {
    label: "GAGNY",
    value: "93032",
    type: "common",
  },
  {
    label: "COUBRON",
    value: "93015",
    type: "common",
  },
  {
    label: "CLICHY-SOUS-BOIS",
    value: "93014",
    type: "common",
  },
  {
    label: "VILLEMOMBLE",
    value: "93077",
    type: "common",
  },
  {
    label: "ROSNY-SOUS-BOIS",
    value: "93064",
    type: "common",
  },
  {
    label: "LE RAINCY",
    value: "93062",
    type: "common",
  },
  {
    label: "LES PAVILLONS-SOUS-BOIS",
    value: "93057",
    type: "common",
  },
  {
    label: "NOISY-LE-SEC",
    value: "93053",
    type: "common",
  },
  {
    label: "NEUILLY-PLAISANCE",
    value: "93049",
    type: "common",
  },
  {
    label: "MONTREUIL",
    value: "93048",
    type: "common",
  },
  {
    label: "BONDY",
    value: "93010",
    type: "common",
  },
  {
    label: "AUBERVILLIERS",
    value: "93001",
    type: "common",
  },
  {
    label: "ROMAINVILLE",
    value: "93063",
    type: "common",
  },
  {
    label: "LE PRE-SAINT-GERVAIS",
    value: "93061",
    type: "common",
  },
  {
    label: "PANTIN",
    value: "93055",
    type: "common",
  },
  {
    label: "LES LILAS",
    value: "93045",
    type: "common",
  },
  {
    label: "DRANCY",
    value: "93029",
    type: "common",
  },
  {
    label: "BOBIGNY",
    value: "93008",
    type: "common",
  },
  {
    label: "BAGNOLET",
    value: "93006",
    type: "common",
  },
  {
    label: "SAINT-OUEN-SUR-SEINE",
    value: "93070",
    type: "common",
  },
  {
    label: "LEVALLOIS-PERRET",
    value: "92044",
    type: "common",
  },
  {
    label: "CLICHY",
    value: "92024",
    type: "common",
  },
  {
    label: "ASNIERES-SUR-SEINE",
    value: "92004",
    type: "common",
  },
  {
    label: "PARIS",
    value: "75056",
    type: "common",
  },
  {
    label: "SURESNES",
    value: "92073",
    type: "common",
  },
  {
    label: "PUTEAUX",
    value: "92062",
    type: "common",
  },
  {
    label: "NEUILLY-SUR-SEINE",
    value: "92051",
    type: "common",
  },
  {
    label: "NANTERRE",
    value: "92050",
    type: "common",
  },
  {
    label: "LA GARENNE-COLOMBES",
    value: "92035",
    type: "common",
  },
  {
    label: "COURBEVOIE",
    value: "92026",
    type: "common",
  },
  {
    label: "BOIS-COLOMBES",
    value: "92009",
    type: "common",
  },
  {
    label: "RUEIL-MALMAISON",
    value: "92063",
    type: "common",
  },
  {
    label: "LE VESINET",
    value: "78650",
    type: "common",
  },
  {
    label: "LE PORT-MARLY",
    value: "78502",
    type: "common",
  },
  {
    label: "LE PECQ",
    value: "78481",
    type: "common",
  },
  {
    label: "MONTESSON",
    value: "78418",
    type: "common",
  },
  {
    label: "LOUVECIENNES",
    value: "78350",
    type: "common",
  },
  {
    label: "CROISSY-SUR-SEINE",
    value: "78190",
    type: "common",
  },
  {
    label: "CHATOU",
    value: "78146",
    type: "common",
  },
  {
    label: "CARRIERES-SUR-SEINE",
    value: "78124",
    type: "common",
  },
  {
    label: "BOUGIVAL",
    value: "78092",
    type: "common",
  },
  {
    label: "SAINT-NOM-LA-BRETECHE",
    value: "78571",
    type: "common",
  },
  {
    label: "MARLY-LE-ROI",
    value: "78372",
    type: "common",
  },
  {
    label: "MAREIL-MARLY",
    value: "78367",
    type: "common",
  },
  {
    label: "L'ETANG-LA-VILLE",
    value: "78224",
    type: "common",
  },
  {
    label: "CHAMBOURCY",
    value: "78133",
    type: "common",
  },
  {
    label: "AIGREMONT",
    value: "78007",
    type: "common",
  },
  {
    label: "FEUCHEROLLES",
    value: "78233",
    type: "common",
  },
  {
    label: "DAVRON",
    value: "78196",
    type: "common",
  },
  {
    label: "CRESPIERES",
    value: "78189",
    type: "common",
  },
  {
    label: "LES ALLUETS-LE-ROI",
    value: "78010",
    type: "common",
  },
  {
    label: "MONTAINVILLE",
    value: "78415",
    type: "common",
  },
  {
    label: "MAULE",
    value: "78380",
    type: "common",
  },
  {
    label: "MAREIL-SUR-MAULDRE",
    value: "78368",
    type: "common",
  },
  {
    label: "HERBEVILLE",
    value: "78305",
    type: "common",
  },
  {
    label: "THOIRY",
    value: "78616",
    type: "common",
  },
  {
    label: "MARCQ",
    value: "78364",
    type: "common",
  },
  {
    label: "JUMEAUVILLE",
    value: "78325",
    type: "common",
  },
  {
    label: "HARGEVILLE",
    value: "78300",
    type: "common",
  },
  {
    label: "GOUSSONVILLE",
    value: "78281",
    type: "common",
  },
  {
    label: "GOUPILLIERES",
    value: "78278",
    type: "common",
  },
  {
    label: "ANDELU",
    value: "78013",
    type: "common",
  },
  {
    label: "SEPTEUIL",
    value: "78591",
    type: "common",
  },
  {
    label: "SAINT-MARTIN-DES-CHAMPS",
    value: "78565",
    type: "common",
  },
  {
    label: "ROSAY",
    value: "78530",
    type: "common",
  },
  {
    label: "PRUNAY-LE-TEMPLE",
    value: "78505",
    type: "common",
  },
  {
    label: "OSMOY",
    value: "78475",
    type: "common",
  },
  {
    label: "MULCENT",
    value: "78439",
    type: "common",
  },
  {
    label: "COURGENT",
    value: "78185",
    type: "common",
  },
  {
    label: "ARNOUVILLE-LES-MANTES",
    value: "78020",
    type: "common",
  },
  {
    label: "TILLY",
    value: "78618",
    type: "common",
  },
  {
    label: "MONTCHAUVET",
    value: "78417",
    type: "common",
  },
  {
    label: "FLINS-NEUVE-EGLISE",
    value: "78237",
    type: "common",
  },
  {
    label: "DAMMARTIN-EN-SERVE",
    value: "78192",
    type: "common",
  },
  {
    label: "CIVRY-LA-FORET",
    value: "78163",
    type: "common",
  },
  {
    label: "BOISSETS",
    value: "78076",
    type: "common",
  },
  {
    label: "MONDREVILLE",
    value: "78413",
    type: "common",
  },
  {
    label: "OULINS",
    value: "28293",
    type: "common",
  },
  {
    label: "LE MESNIL-SIMON",
    value: "28247",
    type: "common",
  },
  {
    label: "GILLES",
    value: "28180",
    type: "common",
  },
  {
    label: "LA CHAUSSEE-D'IVRY",
    value: "28096",
    type: "common",
  },
  {
    label: "IVRY-LA-BATAILLE",
    value: "27355",
    type: "common",
  },
  {
    label: "GARENNES-SUR-EURE",
    value: "27278",
    type: "common",
  },
  {
    label: "EZY-SUR-EURE",
    value: "27230",
    type: "common",
  },
  {
    label: "LA COUTURE-BOUSSEY",
    value: "27183",
    type: "common",
  },
  {
    label: "MOUSSEAUX-NEUVILLE",
    value: "27421",
    type: "common",
  },
  {
    label: "MOUETTES",
    value: "27419",
    type: "common",
  },
  {
    label: "L'HABIT",
    value: "27309",
    type: "common",
  },
  {
    label: "CHAMPIGNY-LA-FUTELAYE",
    value: "27144",
    type: "common",
  },
  {
    label: "BOIS-LE-ROI",
    value: "27073",
    type: "common",
  },
  {
    label: "SAINT-ANDRE-DE-L'EURE",
    value: "27507",
    type: "common",
  },
  {
    label: "JUMELLES",
    value: "27360",
    type: "common",
  },
  {
    label: "COUDRES",
    value: "27177",
    type: "common",
  },
  {
    label: "CHAVIGNY-BAILLEUL",
    value: "27154",
    type: "common",
  },
  {
    label: "LES AUTHIEUX",
    value: "27027",
    type: "common",
  },
  {
    label: "SYLVAINS-LES-MOULINS",
    value: "27693",
    type: "common",
  },
  {
    label: "MESNILS-SUR-ITON",
    value: "27198",
    type: "common",
  },
  {
    label: "MARBOIS",
    value: "27157",
    type: "common",
  },
  {
    label: "BRETEUIL",
    value: "27112",
    type: "common",
  },
  {
    label: "BEAUBRAY",
    value: "27047",
    type: "common",
  },
  {
    label: "LES BAUX-DE-BRETEUIL",
    value: "27043",
    type: "common",
  },
  {
    label: "LA NEUVE-LYRE",
    value: "27431",
    type: "common",
  },
  {
    label: "NEAUFLES-AUVERGNY",
    value: "27427",
    type: "common",
  },
  {
    label: "LES BOTTEREAUX",
    value: "27096",
    type: "common",
  },
  {
    label: "BOIS-NORMAND-PRES-LYRE",
    value: "27075",
    type: "common",
  },
  {
    label: "JUIGNETTES",
    value: "27359",
    type: "common",
  },
  {
    label: "CHAMBORD",
    value: "27139",
    type: "common",
  },
  {
    label: "NOTRE-DAME-DU-HAMEL",
    value: "27442",
    type: "common",
  },
  {
    label: "MESNIL-ROUSSET",
    value: "27404",
    type: "common",
  },
  {
    label: "SAINT-LAURENT-DU-TENCEMENT",
    value: "27556",
    type: "common",
  },
  {
    label: "SAP-EN-AUGE",
    value: "61460",
    type: "common",
  },
  {
    label: "ROIVILLE",
    value: "61351",
    type: "common",
  },
  {
    label: "NEUVILLE-SUR-TOUQUES",
    value: "61307",
    type: "common",
  },
  {
    label: "GUERQUESALLES",
    value: "61198",
    type: "common",
  },
  {
    label: "AUBRY-LE-PANTHOU",
    value: "61010",
    type: "common",
  },
  {
    label: "FRESNAY-LE-SAMSON",
    value: "61180",
    type: "common",
  },
  {
    label: "COUDEHARD",
    value: "61120",
    type: "common",
  },
  {
    label: "CHAMPOSOULT",
    value: "61089",
    type: "common",
  },
  {
    label: "LES CHAMPEAUX",
    value: "61086",
    type: "common",
  },
  {
    label: "CAMEMBERT",
    value: "61071",
    type: "common",
  },
  {
    label: "TRUN",
    value: "61494",
    type: "common",
  },
  {
    label: "NEAUPHE-SUR-DIVE",
    value: "61302",
    type: "common",
  },
  {
    label: "ECORCHES",
    value: "61152",
    type: "common",
  },
  {
    label: "OMMOY",
    value: "61316",
    type: "common",
  },
  {
    label: "MONTREUIL-LA-CAMBE",
    value: "61291",
    type: "common",
  },
  {
    label: "MERRI",
    value: "61276",
    type: "common",
  },
  {
    label: "LOUVIERES-EN-AUGE",
    value: "61238",
    type: "common",
  },
  {
    label: "FONTAINE-LES-BASSETS",
    value: "61171",
    type: "common",
  },
  {
    label: "LE MARAIS-LA-CHAPELLE",
    value: "14402",
    type: "common",
  },
  {
    label: "VIGNATS",
    value: "14751",
    type: "common",
  },
  {
    label: "PERTHEVILLE-NERS",
    value: "14498",
    type: "common",
  },
  {
    label: "FRESNE-LA-MERE",
    value: "14289",
    type: "common",
  },
  {
    label: "FOURCHES",
    value: "14283",
    type: "common",
  },
  {
    label: "CROCY",
    value: "14206",
    type: "common",
  },
  {
    label: "SAINT-PIERRE-DU-BU",
    value: "14649",
    type: "common",
  },
  {
    label: "LA HOGUETTE",
    value: "14332",
    type: "common",
  },
  {
    label: "FALAISE",
    value: "14258",
    type: "common",
  },
  {
    label: "CORDEY",
    value: "14180",
    type: "common",
  },
  {
    label: "SAINT-MARTIN-DE-MIEUX",
    value: "14627",
    type: "common",
  },
  {
    label: "LES LOGES-SAULCES",
    value: "14375",
    type: "common",
  },
  {
    label: "FOURNEAUX-LE-VAL",
    value: "14284",
    type: "common",
  },
  {
    label: "MENIL-VIN",
    value: "61273",
    type: "common",
  },
  {
    label: "RAPILLY",
    value: "14531",
    type: "common",
  },
  {
    label: "PIERREPONT",
    value: "14502",
    type: "common",
  },
  {
    label: "LE MESNIL-VILLEMENT",
    value: "14427",
    type: "common",
  },
  {
    label: "LES ISLES-BARDEL",
    value: "14343",
    type: "common",
  },
  {
    label: "LE DETROIT",
    value: "14223",
    type: "common",
  },
  {
    label: "MENIL-HUBERT-SUR-ORNE",
    value: "61269",
    type: "common",
  },
  {
    label: "CAHAN",
    value: "61069",
    type: "common",
  },
  {
    label: "BERJOU",
    value: "61044",
    type: "common",
  },
  {
    label: "PONT-D'OUILLY",
    value: "14764",
    type: "common",
  },
  {
    label: "SAINT-PIERRE-DU-REGARD",
    value: "61447",
    type: "common",
  },
  {
    label: "SAINT-DENIS-DE-MERE",
    value: "14572",
    type: "common",
  },
  {
    label: "CONDE-EN-NORMANDIE",
    value: "14174",
    type: "common",
  },
  {
    label: "MONCY",
    value: "61281",
    type: "common",
  },
  {
    label: "VALDALLIERE",
    value: "14726",
    type: "common",
  },
  {
    label: "VIRE NORMANDIE",
    value: "14762",
    type: "common",
  },
  {
    label: "NOUES DE SIENNE",
    value: "14658",
    type: "common",
  },
  {
    label: "SAINT-MAUR-DES-BOIS",
    value: "50521",
    type: "common",
  },
  {
    label: "SAINTE-CECILE",
    value: "50453",
    type: "common",
  },
  {
    label: "LA CHAPELLE-CECELIN",
    value: "50121",
    type: "common",
  },
  {
    label: "BESLON",
    value: "50048",
    type: "common",
  },
  {
    label: "SAINT-AUBIN-DES-BOIS",
    value: "14559",
    type: "common",
  },
  {
    label: "VILLEDIEU-LES-POELES-ROUFFIGNY",
    value: "50639",
    type: "common",
  },
  {
    label: "LA LANDE-D'AIROU",
    value: "50262",
    type: "common",
  },
  {
    label: "FLEURY",
    value: "50185",
    type: "common",
  },
  {
    label: "LE MESNIL-VILLEMAN",
    value: "50326",
    type: "common",
  },
  {
    label: "LA HAYE-PESNEL",
    value: "50237",
    type: "common",
  },
  {
    label: "CHAMPREPUS",
    value: "50118",
    type: "common",
  },
  {
    label: "BEAUCHAMPS",
    value: "50038",
    type: "common",
  },
  {
    label: "SAINT-SAUVEUR-LA-POMMERAYE",
    value: "50549",
    type: "common",
  },
  {
    label: "SAINT-JEAN-DES-CHAMPS",
    value: "50493",
    type: "common",
  },
  {
    label: "HOCQUIGNY",
    value: "50247",
    type: "common",
  },
  {
    label: "FOLLIGNY",
    value: "50188",
    type: "common",
  },
  {
    label: "EQUILLY",
    value: "50174",
    type: "common",
  },
  {
    label: "YQUELON",
    value: "50647",
    type: "common",
  },
  {
    label: "SAINT-PLANCHERS",
    value: "50541",
    type: "common",
  },
  {
    label: "SAINT-PAIR-SUR-MER",
    value: "50532",
    type: "common",
  },
  {
    label: "SAINT-AUBIN-DES-PREAUX",
    value: "50447",
    type: "common",
  },
  {
    label: "LONGUEVILLE",
    value: "50277",
    type: "common",
  },
  {
    label: "ANCTOVILLE-SUR-BOSCQ",
    value: "50008",
    type: "common",
  },
  {
    label: "DONVILLE-LES-BAINS",
    value: "50165",
    type: "common",
  },
  {
    label: "PLOUEZEC",
    value: "22214",
    type: "common",
  },
  {
    label: "PAIMPOL",
    value: "22162",
    type: "common",
  },
  {
    label: "PLOURIVO",
    value: "22233",
    type: "common",
  },
  {
    label: "PLEUDANIEL",
    value: "22196",
    type: "common",
  },
  {
    label: "TROGUERY",
    value: "22383",
    type: "common",
  },
  {
    label: "TREGUIER",
    value: "22362",
    type: "common",
  },
  {
    label: "MINIHY-TREGUIER",
    value: "22152",
    type: "common",
  },
  {
    label: "QUEMPERVEN",
    value: "22257",
    type: "common",
  },
  {
    label: "LANGOAT",
    value: "22101",
    type: "common",
  },
  {
    label: "CAMLEZ",
    value: "22028",
    type: "common",
  },
  {
    label: "COATREVEN",
    value: "22042",
    type: "common",
  },
  {
    label: "KERMARIA-SULARD",
    value: "22090",
    type: "common",
  },
  {
    label: "ROSPEZ",
    value: "22265",
    type: "common",
  },
  {
    label: "TREZENY",
    value: "22381",
    type: "common",
  },
  {
    label: "LANMERIN",
    value: "22110",
    type: "common",
  },
  {
    label: "PLOULEC'H",
    value: "22224",
    type: "common",
  },
  {
    label: "LANNION",
    value: "22113",
    type: "common",
  },
  {
    label: "CARANTEC",
    value: "29023",
    type: "common",
  },
  {
    label: "SANTEC",
    value: "29273",
    type: "common",
  },
  {
    label: "ROSCOFF",
    value: "29239",
    type: "common",
  },
  {
    label: "ILE-DE-BATZ",
    value: "29082",
    type: "common",
  },
  {
    label: "OFFENDORF",
    value: "67356",
    type: "common",
  },
  {
    label: "GAMBSHEIM",
    value: "67151",
    type: "common",
  },
  {
    label: "WEYERSHEIM",
    value: "67529",
    type: "common",
  },
  {
    label: "KILSTETT",
    value: "67237",
    type: "common",
  },
  {
    label: "HŒRDT",
    value: "67205",
    type: "common",
  },
  {
    label: "GEUDERTHEIM",
    value: "67156",
    type: "common",
  },
  {
    label: "BRUMATH",
    value: "67067",
    type: "common",
  },
  {
    label: "BIETLENHEIM",
    value: "67038",
    type: "common",
  },
  {
    label: "WINGERSHEIM LES QUATRE BANS",
    value: "67539",
    type: "common",
  },
  {
    label: "OLWISHEIM",
    value: "67361",
    type: "common",
  },
  {
    label: "MITTELSCHAEFFOLSHEIM",
    value: "67298",
    type: "common",
  },
  {
    label: "KRAUTWILLER",
    value: "67249",
    type: "common",
  },
  {
    label: "ECKWERSHEIM",
    value: "67119",
    type: "common",
  },
  {
    label: "DONNENHEIM",
    value: "67100",
    type: "common",
  },
  {
    label: "BILWISHEIM",
    value: "67039",
    type: "common",
  },
  {
    label: "ROHR",
    value: "67406",
    type: "common",
  },
  {
    label: "MUTZENHOUSE",
    value: "67312",
    type: "common",
  },
  {
    label: "KIENHEIM",
    value: "67236",
    type: "common",
  },
  {
    label: "HOHFRANKENHEIM",
    value: "67209",
    type: "common",
  },
  {
    label: "GOUGENHEIM",
    value: "67163",
    type: "common",
  },
  {
    label: "DUNTZENHEIM",
    value: "67107",
    type: "common",
  },
  {
    label: "WOLSCHHEIM",
    value: "67553",
    type: "common",
  },
  {
    label: "SAESSOLSHEIM",
    value: "67423",
    type: "common",
  },
  {
    label: "MAENNOLSHEIM",
    value: "67279",
    type: "common",
  },
  {
    label: "LUPSTEIN",
    value: "67275",
    type: "common",
  },
  {
    label: "LITTENHEIM",
    value: "67269",
    type: "common",
  },
  {
    label: "LANDERSHEIM",
    value: "67258",
    type: "common",
  },
  {
    label: "INGENHEIM",
    value: "67220",
    type: "common",
  },
  {
    label: "FRIEDOLSHEIM",
    value: "67145",
    type: "common",
  },
  {
    label: "ALTENHEIM",
    value: "67006",
    type: "common",
  },
  {
    label: "WALDOLWISHEIM",
    value: "67515",
    type: "common",
  },
  {
    label: "SCHWENHEIM",
    value: "67459",
    type: "common",
  },
  {
    label: "OTTERSWILLER",
    value: "67367",
    type: "common",
  },
  {
    label: "MARMOUTIER",
    value: "67283",
    type: "common",
  },
  {
    label: "LOCHWILLER",
    value: "67272",
    type: "common",
  },
  {
    label: "GOTTENHOUSE",
    value: "67161",
    type: "common",
  },
  {
    label: "FURCHHAUSEN",
    value: "67149",
    type: "common",
  },
  {
    label: "THAL-MARMOUTIER",
    value: "67489",
    type: "common",
  },
  {
    label: "SAVERNE",
    value: "67437",
    type: "common",
  },
  {
    label: "HAEGEN",
    value: "67179",
    type: "common",
  },
  {
    label: "HULTEHOUSE",
    value: "57339",
    type: "common",
  },
  {
    label: "WALTEMBOURG",
    value: "57743",
    type: "common",
  },
  {
    label: "SAINT-LOUIS",
    value: "57618",
    type: "common",
  },
  {
    label: "SAINT-JEAN-KOURTZERODE",
    value: "57614",
    type: "common",
  },
  {
    label: "LUTZELBOURG",
    value: "57427",
    type: "common",
  },
  {
    label: "HENRIDORFF",
    value: "57315",
    type: "common",
  },
  {
    label: "GARREBOURG",
    value: "57244",
    type: "common",
  },
  {
    label: "DANNELBOURG",
    value: "57169",
    type: "common",
  },
  {
    label: "REDING",
    value: "57566",
    type: "common",
  },
  {
    label: "NIDERVILLER",
    value: "57505",
    type: "common",
  },
  {
    label: "HOMMARTING",
    value: "57333",
    type: "common",
  },
  {
    label: "BROUVILLER",
    value: "57114",
    type: "common",
  },
  {
    label: "ARZVILLER",
    value: "57033",
    type: "common",
  },
  {
    label: "SARREBOURG",
    value: "57630",
    type: "common",
  },
  {
    label: "IMLING",
    value: "57344",
    type: "common",
  },
  {
    label: "BUHL-LORRAINE",
    value: "57119",
    type: "common",
  },
  {
    label: "LANGATTE",
    value: "57382",
    type: "common",
  },
  {
    label: "KERPRICH-AUX-BOIS",
    value: "57362",
    type: "common",
  },
  {
    label: "HAUT-CLOCHER",
    value: "57304",
    type: "common",
  },
  {
    label: "DIANE-CAPELLE",
    value: "57175",
    type: "common",
  },
  {
    label: "BEBING",
    value: "57056",
    type: "common",
  },
  {
    label: "RHODES",
    value: "57579",
    type: "common",
  },
  {
    label: "LANGUIMBERG",
    value: "57383",
    type: "common",
  },
  {
    label: "FRIBOURG",
    value: "57241",
    type: "common",
  },
  {
    label: "AZOUDANGE",
    value: "57044",
    type: "common",
  },
  {
    label: "GELUCOURT",
    value: "57246",
    type: "common",
  },
  {
    label: "BOURDONNAY",
    value: "57099",
    type: "common",
  },
  {
    label: "ASSENONCOURT",
    value: "57035",
    type: "common",
  },
  {
    label: "LEY",
    value: "57397",
    type: "common",
  },
  {
    label: "JUVELIZE",
    value: "57353",
    type: "common",
  },
  {
    label: "GUEBLANGE-LES-DIEUZE",
    value: "57266",
    type: "common",
  },
  {
    label: "DONNELAY",
    value: "57183",
    type: "common",
  },
  {
    label: "XANREY",
    value: "57754",
    type: "common",
  },
  {
    label: "MOYENVIC",
    value: "57490",
    type: "common",
  },
  {
    label: "MARSAL",
    value: "57448",
    type: "common",
  },
  {
    label: "LEZEY",
    value: "57399",
    type: "common",
  },
  {
    label: "BEZANGE-LA-PETITE",
    value: "57077",
    type: "common",
  },
  {
    label: "JUVRECOURT",
    value: "54285",
    type: "common",
  },
  {
    label: "VIC-SUR-SEILLE",
    value: "57712",
    type: "common",
  },
  {
    label: "BEZANGE-LA-GRANDE",
    value: "54071",
    type: "common",
  },
  {
    label: "ARRACOURT",
    value: "54023",
    type: "common",
  },
  {
    label: "SORNEVILLE",
    value: "54510",
    type: "common",
  },
  {
    label: "MONCEL-SUR-SEILLE",
    value: "54374",
    type: "common",
  },
  {
    label: "MAZERULLES",
    value: "54358",
    type: "common",
  },
  {
    label: "PETTONCOURT",
    value: "57538",
    type: "common",
  },
  {
    label: "CHAMPENOUX",
    value: "54113",
    type: "common",
  },
  {
    label: "BRIN-SUR-SEILLE",
    value: "54100",
    type: "common",
  },
  {
    label: "AMANCE",
    value: "54012",
    type: "common",
  },
  {
    label: "LAY-SAINT-CHRISTOPHE",
    value: "54305",
    type: "common",
  },
  {
    label: "LAITRE-SOUS-AMANCE",
    value: "54289",
    type: "common",
  },
  {
    label: "EULMONT",
    value: "54186",
    type: "common",
  },
  {
    label: "BOUXIERES-AUX-CHENES",
    value: "54089",
    type: "common",
  },
  {
    label: "MONTENOY",
    value: "54376",
    type: "common",
  },
  {
    label: "FAULX",
    value: "54188",
    type: "common",
  },
  {
    label: "CUSTINES",
    value: "54150",
    type: "common",
  },
  {
    label: "BOUXIERES-AUX-DAMES",
    value: "54090",
    type: "common",
  },
  {
    label: "POMPEY",
    value: "54430",
    type: "common",
  },
  {
    label: "MALLELOY",
    value: "54338",
    type: "common",
  },
  {
    label: "FROUARD",
    value: "54215",
    type: "common",
  },
  {
    label: "SAIZERAIS",
    value: "54490",
    type: "common",
  },
  {
    label: "MARBACHE",
    value: "54351",
    type: "common",
  },
  {
    label: "LIVERDUN",
    value: "54318",
    type: "common",
  },
  {
    label: "TREMBLECOURT",
    value: "54532",
    type: "common",
  },
  {
    label: "ROSIERES-EN-HAYE",
    value: "54463",
    type: "common",
  },
  {
    label: "JAILLON",
    value: "54272",
    type: "common",
  },
  {
    label: "AVRAINVILLE",
    value: "54034",
    type: "common",
  },
  {
    label: "MANONCOURT-EN-WOEVRE",
    value: "54346",
    type: "common",
  },
  {
    label: "ANDILLY",
    value: "54016",
    type: "common",
  },
  {
    label: "SANZEY",
    value: "54492",
    type: "common",
  },
  {
    label: "ROYAUMEIX",
    value: "54466",
    type: "common",
  },
  {
    label: "MENIL-LA-TOUR",
    value: "54360",
    type: "common",
  },
  {
    label: "BOUCQ",
    value: "54086",
    type: "common",
  },
  {
    label: "GEVILLE",
    value: "55258",
    type: "common",
  },
  {
    label: "VIGNOT",
    value: "55553",
    type: "common",
  },
  {
    label: "SAINT-JULIEN-SOUS-LES-COTES",
    value: "55460",
    type: "common",
  },
  {
    label: "GIRAUVOISIN",
    value: "55212",
    type: "common",
  },
  {
    label: "FREMEREVILLE-SOUS-LES-COTES",
    value: "55196",
    type: "common",
  },
  {
    label: "BONCOURT-SUR-MEUSE",
    value: "55058",
    type: "common",
  },
  {
    label: "VADONVILLE",
    value: "55526",
    type: "common",
  },
  {
    label: "PONT-SUR-MEUSE",
    value: "55407",
    type: "common",
  },
  {
    label: "LEROUVILLE",
    value: "55288",
    type: "common",
  },
  {
    label: "CHONVILLE-MALAUMONT",
    value: "55114",
    type: "common",
  },
  {
    label: "COUSANCES-LES-TRICONVILLE",
    value: "55518",
    type: "common",
  },
  {
    label: "MENIL-AUX-BOIS",
    value: "55333",
    type: "common",
  },
  {
    label: "LIGNIERES-SUR-AIRE",
    value: "55290",
    type: "common",
  },
  {
    label: "GRIMAUCOURT-PRES-SAMPIGNY",
    value: "55220",
    type: "common",
  },
  {
    label: "LAVALLEE",
    value: "55282",
    type: "common",
  },
  {
    label: "DAGONVILLE",
    value: "55141",
    type: "common",
  },
  {
    label: "RESSON",
    value: "55426",
    type: "common",
  },
  {
    label: "NAIVES-ROSIERES",
    value: "55369",
    type: "common",
  },
  {
    label: "LOISEY",
    value: "55298",
    type: "common",
  },
  {
    label: "GERY",
    value: "55207",
    type: "common",
  },
  {
    label: "ERIZE-SAINT-DIZIER",
    value: "55178",
    type: "common",
  },
  {
    label: "VAVINCOURT",
    value: "55541",
    type: "common",
  },
  {
    label: "BEHONNE",
    value: "55041",
    type: "common",
  },
  {
    label: "NEUVILLE-SUR-ORNAIN",
    value: "55382",
    type: "common",
  },
  {
    label: "VAL-D'ORNAIN",
    value: "55366",
    type: "common",
  },
  {
    label: "FAINS-VEEL",
    value: "55186",
    type: "common",
  },
  {
    label: "COUVONGES",
    value: "55134",
    type: "common",
  },
  {
    label: "VASSINCOURT",
    value: "55531",
    type: "common",
  },
  {
    label: "REVIGNY-SUR-ORNAIN",
    value: "55427",
    type: "common",
  },
  {
    label: "MOGNEVILLE",
    value: "55340",
    type: "common",
  },
  {
    label: "CONTRISSON",
    value: "55125",
    type: "common",
  },
  {
    label: "ANDERNAY",
    value: "55011",
    type: "common",
  },
  {
    label: "BETTANCOURT-LA-LONGUE",
    value: "51057",
    type: "common",
  },
  {
    label: "ALLIANCELLES",
    value: "51006",
    type: "common",
  },
  {
    label: "VILLERS-LE-SEC",
    value: "51635",
    type: "common",
  },
  {
    label: "SERMAIZE-LES-BAINS",
    value: "51531",
    type: "common",
  },
  {
    label: "REMENNECOURT",
    value: "55424",
    type: "common",
  },
  {
    label: "RANCOURT-SUR-ORNAIN",
    value: "55414",
    type: "common",
  },
  {
    label: "SOGNY-EN-L'ANGLE",
    value: "51539",
    type: "common",
  },
  {
    label: "HEILTZ-LE-MAURUPT",
    value: "51289",
    type: "common",
  },
  {
    label: "JUSSECOURT-MINECOURT",
    value: "51311",
    type: "common",
  },
  {
    label: "VAVRAY-LE-PETIT",
    value: "51602",
    type: "common",
  },
  {
    label: "VAVRAY-LE-GRAND",
    value: "51601",
    type: "common",
  },
  {
    label: "VAL-DE-VIERE",
    value: "51218",
    type: "common",
  },
  {
    label: "CHANGY",
    value: "51122",
    type: "common",
  },
  {
    label: "BASSU",
    value: "51039",
    type: "common",
  },
  {
    label: "BASSUET",
    value: "51040",
    type: "common",
  },
  {
    label: "SAINT-LUMIER-EN-CHAMPAGNE",
    value: "51496",
    type: "common",
  },
  {
    label: "SAINT-AMAND-SUR-FION",
    value: "51472",
    type: "common",
  },
  {
    label: "LISSE-EN-CHAMPAGNE",
    value: "51325",
    type: "common",
  },
  {
    label: "SOULANGES",
    value: "51557",
    type: "common",
  },
  {
    label: "ABLANCOURT",
    value: "51001",
    type: "common",
  },
  {
    label: "AULNAY-L'AITRE",
    value: "51022",
    type: "common",
  },
  {
    label: "SAINT-MARTIN-AUX-CHAMPS",
    value: "51502",
    type: "common",
  },
  {
    label: "VITRY-LA-VILLE",
    value: "51648",
    type: "common",
  },
  {
    label: "CHEPPES-LA-PRAIRIE",
    value: "51148",
    type: "common",
  },
  {
    label: "SONGY",
    value: "51552",
    type: "common",
  },
  {
    label: "FAUX-VESIGNEUL",
    value: "51244",
    type: "common",
  },
  {
    label: "COUPETZ",
    value: "51178",
    type: "common",
  },
  {
    label: "CERNON",
    value: "51106",
    type: "common",
  },
  {
    label: "BUSSY-LETTREE",
    value: "51099",
    type: "common",
  },
  {
    label: "VATRY",
    value: "51595",
    type: "common",
  },
  {
    label: "SOUDRON",
    value: "51556",
    type: "common",
  },
  {
    label: "VILLESENEUX",
    value: "51638",
    type: "common",
  },
  {
    label: "CLAMANGES",
    value: "51154",
    type: "common",
  },
  {
    label: "ECURY-LE-REPOS",
    value: "51226",
    type: "common",
  },
  {
    label: "PIERRE-MORAINS",
    value: "51430",
    type: "common",
  },
  {
    label: "VAL-DES-MARAIS",
    value: "51158",
    type: "common",
  },
  {
    label: "VERT-TOULON",
    value: "51611",
    type: "common",
  },
  {
    label: "BANNES",
    value: "51035",
    type: "common",
  },
  {
    label: "VILLEVENARD",
    value: "51641",
    type: "common",
  },
  {
    label: "COIZARD-JOCHES",
    value: "51157",
    type: "common",
  },
  {
    label: "COURJEONNET",
    value: "51186",
    type: "common",
  },
  {
    label: "REUVES",
    value: "51458",
    type: "common",
  },
  {
    label: "TALUS-SAINT-PRIX",
    value: "51563",
    type: "common",
  },
  {
    label: "SOIZY-AUX-BOIS",
    value: "51542",
    type: "common",
  },
  {
    label: "OYES",
    value: "51421",
    type: "common",
  },
  {
    label: "CORFELIX",
    value: "51170",
    type: "common",
  },
  {
    label: "LA VILLENEUVE-LES-CHARLEVILLE",
    value: "51626",
    type: "common",
  },
  {
    label: "CHARLEVILLE",
    value: "51129",
    type: "common",
  },
  {
    label: "BOISSY-LE-REPOS",
    value: "51070",
    type: "common",
  },
  {
    label: "MORSAINS",
    value: "51386",
    type: "common",
  },
  {
    label: "BERGERES-SOUS-MONTMIRAIL",
    value: "51050",
    type: "common",
  },
  {
    label: "LE GAULT-SOIGNY",
    value: "51264",
    type: "common",
  },
  {
    label: "LE VEZIER",
    value: "51618",
    type: "common",
  },
  {
    label: "TREFOLS",
    value: "51579",
    type: "common",
  },
  {
    label: "RIEUX",
    value: "51460",
    type: "common",
  },
  {
    label: "MONTOLIVET",
    value: "77314",
    type: "common",
  },
  {
    label: "MONTENILS",
    value: "77304",
    type: "common",
  },
  {
    label: "SAINT-BARTHELEMY",
    value: "77402",
    type: "common",
  },
  {
    label: "MONTDAUPHIN",
    value: "77303",
    type: "common",
  },
  {
    label: "SAINT-LEGER",
    value: "77417",
    type: "common",
  },
  {
    label: "JOUY-SUR-MORIN",
    value: "77240",
    type: "common",
  },
  {
    label: "BELLOT",
    value: "77030",
    type: "common",
  },
  {
    label: "SAINT-SIMEON",
    value: "77436",
    type: "common",
  },
  {
    label: "SAINT-REMY-LA-VANNE",
    value: "77432",
    type: "common",
  },
  {
    label: "SAINT-DENIS-LES-REBAIS",
    value: "77406",
    type: "common",
  },
  {
    label: "CHAUFFRY",
    value: "77106",
    type: "common",
  },
  {
    label: "SAINT-GERMAIN-SOUS-DOUE",
    value: "77411",
    type: "common",
  },
  {
    label: "COULOMMIERS",
    value: "77131",
    type: "common",
  },
  {
    label: "BOISSY-LE-CHATEL",
    value: "77042",
    type: "common",
  },
  {
    label: "AULNOY",
    value: "77013",
    type: "common",
  },
  {
    label: "POMMEUSE",
    value: "77371",
    type: "common",
  },
  {
    label: "MOUROUX",
    value: "77320",
    type: "common",
  },
  {
    label: "MAISONCELLES-EN-BRIE",
    value: "77270",
    type: "common",
  },
  {
    label: "GIREMOUTIERS",
    value: "77206",
    type: "common",
  },
  {
    label: "TIGEAUX",
    value: "77466",
    type: "common",
  },
  {
    label: "GUERARD",
    value: "77219",
    type: "common",
  },
  {
    label: "DAMMARTIN-SUR-TIGEAUX",
    value: "77154",
    type: "common",
  },
  {
    label: "LA CELLE-SUR-MORIN",
    value: "77063",
    type: "common",
  },
  {
    label: "BAILLY-ROMAINVILLIERS",
    value: "77018",
    type: "common",
  },
  {
    label: "VOULANGIS",
    value: "77529",
    type: "common",
  },
  {
    label: "VILLIERS-SUR-MORIN",
    value: "77521",
    type: "common",
  },
  {
    label: "VILLENEUVE-LE-COMTE",
    value: "77508",
    type: "common",
  },
  {
    label: "COUTEVROULT",
    value: "77141",
    type: "common",
  },
  {
    label: "BUSSY-SAINT-GEORGES",
    value: "77058",
    type: "common",
  },
  {
    label: "CONCHES-SUR-GONDOIRE",
    value: "77124",
    type: "common",
  },
  {
    label: "SERRIS",
    value: "77449",
    type: "common",
  },
  {
    label: "JOSSIGNY",
    value: "77237",
    type: "common",
  },
  {
    label: "CHANTELOUP-EN-BRIE",
    value: "77085",
    type: "common",
  },
  {
    label: "VILLENEUVE-SAINT-DENIS",
    value: "77510",
    type: "common",
  },
  {
    label: "FERRIERES-EN-BRIE",
    value: "77181",
    type: "common",
  },
  {
    label: "TORCY",
    value: "77468",
    type: "common",
  },
  {
    label: "COLLEGIEN",
    value: "77121",
    type: "common",
  },
  {
    label: "CROISSY-BEAUBOURG",
    value: "77146",
    type: "common",
  },
  {
    label: "BUSSY-SAINT-MARTIN",
    value: "77059",
    type: "common",
  },
  {
    label: "GUERMANTES",
    value: "77221",
    type: "common",
  },
  {
    label: "LOGNES",
    value: "77258",
    type: "common",
  },
  {
    label: "GOUVERNES",
    value: "77209",
    type: "common",
  },
  {
    label: "NOISIEL",
    value: "77337",
    type: "common",
  },
  {
    label: "EMERAINVILLE",
    value: "77169",
    type: "common",
  },
  {
    label: "CHAMPS-SUR-MARNE",
    value: "77083",
    type: "common",
  },
  {
    label: "VILLIERS-SUR-MARNE",
    value: "94079",
    type: "common",
  },
  {
    label: "LE PLESSIS-TREVISE",
    value: "94059",
    type: "common",
  },
  {
    label: "CHENNEVIERES-SUR-MARNE",
    value: "94019",
    type: "common",
  },
  {
    label: "NOISY-LE-GRAND",
    value: "93051",
    type: "common",
  },
  {
    label: "NEUILLY-SUR-MARNE",
    value: "93050",
    type: "common",
  },
  {
    label: "LE PERREUX-SUR-MARNE",
    value: "94058",
    type: "common",
  },
  {
    label: "NOGENT-SUR-MARNE",
    value: "94052",
    type: "common",
  },
  {
    label: "JOINVILLE-LE-PONT",
    value: "94042",
    type: "common",
  },
  {
    label: "FONTENAY-SOUS-BOIS",
    value: "94033",
    type: "common",
  },
  {
    label: "CHAMPIGNY-SUR-MARNE",
    value: "94017",
    type: "common",
  },
  {
    label: "BRY-SUR-MARNE",
    value: "94015",
    type: "common",
  },
  {
    label: "VINCENNES",
    value: "94080",
    type: "common",
  },
  {
    label: "SAINT-MAURICE",
    value: "94069",
    type: "common",
  },
  {
    label: "SAINT-MANDE",
    value: "94067",
    type: "common",
  },
  {
    label: "MAISONS-ALFORT",
    value: "94046",
    type: "common",
  },
  {
    label: "IVRY-SUR-SEINE",
    value: "94041",
    type: "common",
  },
  {
    label: "CHARENTON-LE-PONT",
    value: "94018",
    type: "common",
  },
  {
    label: "LE KREMLIN-BICETRE",
    value: "94043",
    type: "common",
  },
  {
    label: "GENTILLY",
    value: "94037",
    type: "common",
  },
  {
    label: "ARCUEIL",
    value: "94003",
    type: "common",
  },
  {
    label: "VANVES",
    value: "92075",
    type: "common",
  },
  {
    label: "MONTROUGE",
    value: "92049",
    type: "common",
  },
  {
    label: "MALAKOFF",
    value: "92046",
    type: "common",
  },
  {
    label: "CHATILLON",
    value: "92020",
    type: "common",
  },
  {
    label: "SEVRES",
    value: "92072",
    type: "common",
  },
  {
    label: "SAINT-CLOUD",
    value: "92064",
    type: "common",
  },
  {
    label: "MEUDON",
    value: "92048",
    type: "common",
  },
  {
    label: "ISSY-LES-MOULINEAUX",
    value: "92040",
    type: "common",
  },
  {
    label: "GARCHES",
    value: "92033",
    type: "common",
  },
  {
    label: "CHAVILLE",
    value: "92022",
    type: "common",
  },
  {
    label: "BOULOGNE-BILLANCOURT",
    value: "92012",
    type: "common",
  },
  {
    label: "VILLE-D'AVRAY",
    value: "92077",
    type: "common",
  },
  {
    label: "VAUCRESSON",
    value: "92076",
    type: "common",
  },
  {
    label: "MARNES-LA-COQUETTE",
    value: "92047",
    type: "common",
  },
  {
    label: "VIROFLAY",
    value: "78686",
    type: "common",
  },
  {
    label: "VERSAILLES",
    value: "78646",
    type: "common",
  },
  {
    label: "LA CELLE-SAINT-CLOUD",
    value: "78126",
    type: "common",
  },
  {
    label: "VILLEPREUX",
    value: "78674",
    type: "common",
  },
  {
    label: "SAINT-CYR-L'ECOLE",
    value: "78545",
    type: "common",
  },
  {
    label: "RENNEMOULIN",
    value: "78518",
    type: "common",
  },
  {
    label: "NOISY-LE-ROI",
    value: "78455",
    type: "common",
  },
  {
    label: "FONTENAY-LE-FLEURY",
    value: "78242",
    type: "common",
  },
  {
    label: "BAILLY",
    value: "78043",
    type: "common",
  },
  {
    label: "THIVERVAL-GRIGNON",
    value: "78615",
    type: "common",
  },
  {
    label: "PLAISIR",
    value: "78490",
    type: "common",
  },
  {
    label: "LES CLAYES-SOUS-BOIS",
    value: "78165",
    type: "common",
  },
  {
    label: "CHAVENAY",
    value: "78152",
    type: "common",
  },
  {
    label: "VILLIERS-SAINT-FREDERIC",
    value: "78683",
    type: "common",
  },
  {
    label: "VICQ",
    value: "78653",
    type: "common",
  },
  {
    label: "SAULX-MARCHAIS",
    value: "78588",
    type: "common",
  },
  {
    label: "SAINT-GERMAIN-DE-LA-GRANGE",
    value: "78550",
    type: "common",
  },
  {
    label: "NEAUPHLE-LE-VIEUX",
    value: "78443",
    type: "common",
  },
  {
    label: "NEAUPHLE-LE-CHATEAU",
    value: "78442",
    type: "common",
  },
  {
    label: "BEYNES",
    value: "78062",
    type: "common",
  },
  {
    label: "VILLIERS-LE-MAHIEU",
    value: "78681",
    type: "common",
  },
  {
    label: "GARANCIERES",
    value: "78265",
    type: "common",
  },
  {
    label: "FLEXANVILLE",
    value: "78236",
    type: "common",
  },
  {
    label: "BOISSY-SANS-AVOIR",
    value: "78084",
    type: "common",
  },
  {
    label: "AUTOUILLET",
    value: "78036",
    type: "common",
  },
  {
    label: "AUTEUIL",
    value: "78034",
    type: "common",
  },
  {
    label: "TACOIGNIERES",
    value: "78605",
    type: "common",
  },
  {
    label: "ORGERUS",
    value: "78465",
    type: "common",
  },
  {
    label: "MILLEMONT",
    value: "78404",
    type: "common",
  },
  {
    label: "BEHOUST",
    value: "78053",
    type: "common",
  },
  {
    label: "BAZAINVILLE",
    value: "78048",
    type: "common",
  },
  {
    label: "RICHEBOURG",
    value: "78520",
    type: "common",
  },
  {
    label: "ORVILLIERS",
    value: "78474",
    type: "common",
  },
  {
    label: "GRESSEY",
    value: "78285",
    type: "common",
  },
  {
    label: "SAINT-LUBIN-DE-LA-HAYE",
    value: "28347",
    type: "common",
  },
  {
    label: "SAINT-OUEN-MARCHEFROY",
    value: "28355",
    type: "common",
  },
  {
    label: "ROUVRES",
    value: "28321",
    type: "common",
  },
  {
    label: "BU",
    value: "28064",
    type: "common",
  },
  {
    label: "BERCHERES-SUR-VESGRE",
    value: "28036",
    type: "common",
  },
  {
    label: "SOREL-MOUSSEL",
    value: "28377",
    type: "common",
  },
  {
    label: "SAUSSAY",
    value: "28371",
    type: "common",
  },
  {
    label: "BONCOURT",
    value: "28050",
    type: "common",
  },
  {
    label: "ANET",
    value: "28007",
    type: "common",
  },
  {
    label: "SAINT-LAURENT-DES-BOIS",
    value: "27555",
    type: "common",
  },
  {
    label: "MARCILLY-SUR-EURE",
    value: "27391",
    type: "common",
  },
  {
    label: "CROTH",
    value: "27193",
    type: "common",
  },
  {
    label: "LIGNEROLLES",
    value: "27368",
    type: "common",
  },
  {
    label: "ILLIERS-L'EVEQUE",
    value: "27350",
    type: "common",
  },
  {
    label: "COURDEMANCHE",
    value: "27181",
    type: "common",
  },
  {
    label: "MOISVILLE",
    value: "27411",
    type: "common",
  },
  {
    label: "MARCILLY-LA-CAMPAGNE",
    value: "27390",
    type: "common",
  },
  {
    label: "SAINTE-MARIE-D'ATTEZ",
    value: "27578",
    type: "common",
  },
  {
    label: "BEMECOURT",
    value: "27054",
    type: "common",
  },
  {
    label: "RUGLES",
    value: "27502",
    type: "common",
  },
  {
    label: "BOIS-ARNAULT",
    value: "27069",
    type: "common",
  },
  {
    label: "AMBENAY",
    value: "27009",
    type: "common",
  },
  {
    label: "SAINT-NICOLAS-DE-SOMMAIRE",
    value: "61435",
    type: "common",
  },
  {
    label: "SAINT-MARTIN-D'ECUBLEI",
    value: "61423",
    type: "common",
  },
  {
    label: "SAINT-ANTONIN-DE-SOMMAIRE",
    value: "27508",
    type: "common",
  },
  {
    label: "LA FERTE-EN-OUCHE",
    value: "61167",
    type: "common",
  },
  {
    label: "TOUQUETTES",
    value: "61488",
    type: "common",
  },
  {
    label: "LA GONFRIERE",
    value: "61193",
    type: "common",
  },
  {
    label: "LA TRINITE-DES-LAITIERS",
    value: "61493",
    type: "common",
  },
  {
    label: "LE SAP-ANDRE",
    value: "61461",
    type: "common",
  },
  {
    label: "SAINT-EVROULT-DE-MONTFORT",
    value: "61385",
    type: "common",
  },
  {
    label: "GACE",
    value: "61181",
    type: "common",
  },
  {
    label: "CHAUMONT",
    value: "61103",
    type: "common",
  },
  {
    label: "RESENLIEU",
    value: "61347",
    type: "common",
  },
  {
    label: "MENIL-HUBERT-EN-EXMES",
    value: "61268",
    type: "common",
  },
  {
    label: "MARDILLY",
    value: "61252",
    type: "common",
  },
  {
    label: "LA FRESNAIE-FAYEL",
    value: "61178",
    type: "common",
  },
  {
    label: "MONT-ORMEL",
    value: "61289",
    type: "common",
  },
  {
    label: "TOURNAI-SUR-DIVE",
    value: "61490",
    type: "common",
  },
  {
    label: "SAINT-LAMBERT-SUR-DIVE",
    value: "61413",
    type: "common",
  },
  {
    label: "VILLEDIEU-LES-BAILLEUL",
    value: "61505",
    type: "common",
  },
  {
    label: "GUEPREI",
    value: "61197",
    type: "common",
  },
  {
    label: "COULONCES",
    value: "61123",
    type: "common",
  },
  {
    label: "BAILLEUL",
    value: "61023",
    type: "common",
  },
  {
    label: "RONAI",
    value: "61352",
    type: "common",
  },
  {
    label: "NECY",
    value: "61303",
    type: "common",
  },
  {
    label: "MONTABARD",
    value: "61283",
    type: "common",
  },
  {
    label: "COMMEAUX",
    value: "61114",
    type: "common",
  },
  {
    label: "BRIEUX",
    value: "61062",
    type: "common",
  },
  {
    label: "RI",
    value: "61349",
    type: "common",
  },
  {
    label: "NEUVY-AU-HOULME",
    value: "61308",
    type: "common",
  },
  {
    label: "HABLOVILLE",
    value: "61199",
    type: "common",
  },
  {
    label: "CHAMPCERIE",
    value: "61084",
    type: "common",
  },
  {
    label: "BAZOCHES-AU-HOULME",
    value: "61028",
    type: "common",
  },
  {
    label: "SAINT-PHILBERT-SUR-ORNE",
    value: "61444",
    type: "common",
  },
  {
    label: "SAINTE-HONORINE-LA-GUILLAUME",
    value: "61408",
    type: "common",
  },
  {
    label: "MENIL-HERMEI",
    value: "61267",
    type: "common",
  },
  {
    label: "SAINTE-HONORINE-LA-CHARDONNE",
    value: "61407",
    type: "common",
  },
  {
    label: "LA LANDE-SAINT-SIMEON",
    value: "61219",
    type: "common",
  },
  {
    label: "MONTILLY-SUR-NOIREAU",
    value: "61287",
    type: "common",
  },
  {
    label: "AUBUSSON",
    value: "61011",
    type: "common",
  },
  {
    label: "ATHIS-VAL DE ROUVRE",
    value: "61007",
    type: "common",
  },
  {
    label: "SAINT-PIERRE-D'ENTREMONT",
    value: "61445",
    type: "common",
  },
  {
    label: "CERISY-BELLE-ETOILE",
    value: "61078",
    type: "common",
  },
  {
    label: "CALIGNY",
    value: "61070",
    type: "common",
  },
  {
    label: "LA BAZOQUE",
    value: "61030",
    type: "common",
  },
  {
    label: "SAINT-QUENTIN-LES-CHARDONNETS",
    value: "61451",
    type: "common",
  },
  {
    label: "MONTSECRET-CLAIREFOUGERE",
    value: "61292",
    type: "common",
  },
  {
    label: "SAINT-MICHEL-DE-MONTJOIE",
    value: "50525",
    type: "common",
  },
  {
    label: "GATHEMO",
    value: "50195",
    type: "common",
  },
  {
    label: "COULOUVRAY-BOISBENATRE",
    value: "50144",
    type: "common",
  },
  {
    label: "SAINT-NICOLAS-DES-BOIS",
    value: "50529",
    type: "common",
  },
  {
    label: "SAINT-MARTIN-LE-BOUILLANT",
    value: "50518",
    type: "common",
  },
  {
    label: "SAINT-JEAN-DU-CORAIL-DES-BOIS",
    value: "50495",
    type: "common",
  },
  {
    label: "LES LOGES-SUR-BRECEY",
    value: "50275",
    type: "common",
  },
  {
    label: "CHERENCE-LE-HERON",
    value: "50130",
    type: "common",
  },
  {
    label: "BOISYVON",
    value: "50062",
    type: "common",
  },
  {
    label: "LA TRINITE",
    value: "50607",
    type: "common",
  },
  {
    label: "LE PARC",
    value: "50535",
    type: "common",
  },
  {
    label: "LA CHAISE-BAUDOUIN",
    value: "50112",
    type: "common",
  },
  {
    label: "BOURGUENOLLES",
    value: "50069",
    type: "common",
  },
  {
    label: "LE TANU",
    value: "50590",
    type: "common",
  },
  {
    label: "SUBLIGNY",
    value: "50584",
    type: "common",
  },
  {
    label: "LA MOUCHE",
    value: "50361",
    type: "common",
  },
  {
    label: "LE LUOT",
    value: "50282",
    type: "common",
  },
  {
    label: "SARTILLY-BAIE-BOCAGE",
    value: "50565",
    type: "common",
  },
  {
    label: "LA LUCERNE-D'OUTREMER",
    value: "50281",
    type: "common",
  },
  {
    label: "LE GRIPPON",
    value: "50115",
    type: "common",
  },
  {
    label: "JULLOUVILLE",
    value: "50066",
    type: "common",
  },
  {
    label: "CAROLLES",
    value: "50102",
    type: "common",
  },
  {
    label: "SAINT-PIERRE-LANGERS",
    value: "50540",
    type: "common",
  },
  {
    label: "PLOUHA",
    value: "22222",
    type: "common",
  },
  {
    label: "LANLOUP",
    value: "22109",
    type: "common",
  },
  {
    label: "PLEHEDEL",
    value: "22178",
    type: "common",
  },
  {
    label: "LANLEFF",
    value: "22108",
    type: "common",
  },
  {
    label: "YVIAS",
    value: "22390",
    type: "common",
  },
  {
    label: "KERFOT",
    value: "22086",
    type: "common",
  },
  {
    label: "LE FAOUET",
    value: "22057",
    type: "common",
  },
  {
    label: "QUEMPER-GUEZENNEC",
    value: "22256",
    type: "common",
  },
  {
    label: "PONTRIEUX",
    value: "22250",
    type: "common",
  },
  {
    label: "PLOUEC-DU-TRIEUX",
    value: "22212",
    type: "common",
  },
  {
    label: "PLOEZAL",
    value: "22204",
    type: "common",
  },
  {
    label: "RUNAN",
    value: "22269",
    type: "common",
  },
  {
    label: "PRAT",
    value: "22254",
    type: "common",
  },
  {
    label: "MANTALLOT",
    value: "22141",
    type: "common",
  },
  {
    label: "BERHET",
    value: "22006",
    type: "common",
  },
  {
    label: "CAVAN",
    value: "22034",
    type: "common",
  },
  {
    label: "TONQUEDEC",
    value: "22340",
    type: "common",
  },
  {
    label: "CAOUENNEC-LANVEZEAC",
    value: "22030",
    type: "common",
  },
  {
    label: "PLOUBEZRE",
    value: "22211",
    type: "common",
  },
  {
    label: "TREDREZ-LOCQUEMEAU",
    value: "22349",
    type: "common",
  },
  {
    label: "SAINT-MICHEL-EN-GREVE",
    value: "22319",
    type: "common",
  },
  {
    label: "TREDUDER",
    value: "22350",
    type: "common",
  },
  {
    label: "PLOUMILLIAU",
    value: "22226",
    type: "common",
  },
  {
    label: "PLESTIN-LES-GREVES",
    value: "22194",
    type: "common",
  },
  {
    label: "LOCQUIREC",
    value: "29133",
    type: "common",
  },
  {
    label: "SAINT-JEAN-DU-DOIGT",
    value: "29251",
    type: "common",
  },
  {
    label: "LANMEUR",
    value: "29113",
    type: "common",
  },
  {
    label: "GUIMAEC",
    value: "29073",
    type: "common",
  },
  {
    label: "PLOUEZOC'H",
    value: "29186",
    type: "common",
  },
  {
    label: "PLOUGASNOU",
    value: "29188",
    type: "common",
  },
  {
    label: "SAINT-POL-DE-LEON",
    value: "29259",
    type: "common",
  },
  {
    label: "PLOUGOULM",
    value: "29192",
    type: "common",
  },
  {
    label: "SIBIRIL",
    value: "29276",
    type: "common",
  },
  {
    label: "CLEDER",
    value: "29030",
    type: "common",
  },
  {
    label: "PLOUNEVEZ-LOCHRIST",
    value: "29206",
    type: "common",
  },
  {
    label: "PLOUESCAT",
    value: "29185",
    type: "common",
  },
  {
    label: "TREFLEZ",
    value: "29287",
    type: "common",
  },
  {
    label: "GOULVEN",
    value: "29064",
    type: "common",
  },
  {
    label: "KERLOUAN",
    value: "29091",
    type: "common",
  },
  {
    label: "PLOUNEOUR-BRIGNOGAN-PLAGES",
    value: "29021",
    type: "common",
  },
  {
    label: "GUISSENY",
    value: "29077",
    type: "common",
  },
  {
    label: "PLOUGUERNEAU",
    value: "29195",
    type: "common",
  },
  {
    label: "LANDEDA",
    value: "29101",
    type: "common",
  },
  {
    label: "LA WANTZENAU",
    value: "67519",
    type: "common",
  },
  {
    label: "VENDENHEIM",
    value: "67506",
    type: "common",
  },
  {
    label: "SOUFFELWEYERSHEIM",
    value: "67471",
    type: "common",
  },
  {
    label: "REICHSTETT",
    value: "67389",
    type: "common",
  },
  {
    label: "NIEDERHAUSBERGEN",
    value: "67326",
    type: "common",
  },
  {
    label: "MUNDOLSHEIM",
    value: "67309",
    type: "common",
  },
  {
    label: "HŒNHEIM",
    value: "67204",
    type: "common",
  },
  {
    label: "BISCHHEIM",
    value: "67043",
    type: "common",
  },
  {
    label: "TRUCHTERSHEIM",
    value: "67495",
    type: "common",
  },
  {
    label: "STUTZHEIM-OFFENHEIM",
    value: "67485",
    type: "common",
  },
  {
    label: "PFULGRIESHEIM",
    value: "67375",
    type: "common",
  },
  {
    label: "LAMPERTHEIM",
    value: "67256",
    type: "common",
  },
  {
    label: "GRIESHEIM-SUR-SOUFFEL",
    value: "67173",
    type: "common",
  },
  {
    label: "BERSTETT",
    value: "67034",
    type: "common",
  },
  {
    label: "WIWERSHEIM",
    value: "67548",
    type: "common",
  },
  {
    label: "SCHNERSHEIM",
    value: "67452",
    type: "common",
  },
  {
    label: "QUATZENHEIM",
    value: "67382",
    type: "common",
  },
  {
    label: "KUTTOLSHEIM",
    value: "67253",
    type: "common",
  },
  {
    label: "NEUGARTHEIM-ITTLENHEIM",
    value: "67228",
    type: "common",
  },
  {
    label: "FESSENHEIM-LE-BAS",
    value: "67138",
    type: "common",
  },
  {
    label: "DURNINGEN",
    value: "67109",
    type: "common",
  },
  {
    label: "DOSSENHEIM-KOCHERSBERG",
    value: "67102",
    type: "common",
  },
  {
    label: "ZEINHEIM",
    value: "67556",
    type: "common",
  },
  {
    label: "ZEHNACKER",
    value: "67555",
    type: "common",
  },
  {
    label: "WINTZENHEIM-KOCHERSBERG",
    value: "67542",
    type: "common",
  },
  {
    label: "WILLGOTTHEIM",
    value: "67532",
    type: "common",
  },
  {
    label: "WESTHOUSE-MARMOUTIER",
    value: "67527",
    type: "common",
  },
  {
    label: "WASSELONNE",
    value: "67520",
    type: "common",
  },
  {
    label: "RANGEN",
    value: "67383",
    type: "common",
  },
  {
    label: "NORDHEIM",
    value: "67335",
    type: "common",
  },
  {
    label: "KNŒRSHEIM",
    value: "67245",
    type: "common",
  },
  {
    label: "KLEINGŒFT",
    value: "67244",
    type: "common",
  },
  {
    label: "HOHENGŒFT",
    value: "67208",
    type: "common",
  },
  {
    label: "ROMANSWILLER",
    value: "67408",
    type: "common",
  },
  {
    label: "REUTENBOURG",
    value: "67395",
    type: "common",
  },
  {
    label: "JETTERSWILLER",
    value: "67229",
    type: "common",
  },
  {
    label: "DIMBSTHAL",
    value: "67096",
    type: "common",
  },
  {
    label: "CRASTATT",
    value: "67078",
    type: "common",
  },
  {
    label: "SOMMERAU",
    value: "67004",
    type: "common",
  },
  {
    label: "REINHARDSMUNSTER",
    value: "67391",
    type: "common",
  },
  {
    label: "HENGWILLER",
    value: "67190",
    type: "common",
  },
  {
    label: "HOMMERT",
    value: "57334",
    type: "common",
  },
  {
    label: "HASELBOURG",
    value: "57300",
    type: "common",
  },
  {
    label: "HARREBERG",
    value: "57298",
    type: "common",
  },
  {
    label: "DABO",
    value: "57163",
    type: "common",
  },
  {
    label: "VOYER",
    value: "57734",
    type: "common",
  },
  {
    label: "TROISFONTAINES",
    value: "57680",
    type: "common",
  },
  {
    label: "SCHNECKENBUSCH",
    value: "57637",
    type: "common",
  },
  {
    label: "PLAINE-DE-WALSCH",
    value: "57544",
    type: "common",
  },
  {
    label: "HARTZVILLER",
    value: "57299",
    type: "common",
  },
  {
    label: "GUNTZVILLER",
    value: "57280",
    type: "common",
  },
  {
    label: "BROUDERDORFF",
    value: "57113",
    type: "common",
  },
  {
    label: "XOUAXANGE",
    value: "57756",
    type: "common",
  },
  {
    label: "NITTING",
    value: "57509",
    type: "common",
  },
  {
    label: "LORQUIN",
    value: "57414",
    type: "common",
  },
  {
    label: "HESSE",
    value: "57321",
    type: "common",
  },
  {
    label: "HERMELANGE",
    value: "57318",
    type: "common",
  },
  {
    label: "SAINT-GEORGES",
    value: "57611",
    type: "common",
  },
  {
    label: "NEUFMOULINS",
    value: "57500",
    type: "common",
  },
  {
    label: "LANDANGE",
    value: "57377",
    type: "common",
  },
  {
    label: "HERTZING",
    value: "57320",
    type: "common",
  },
  {
    label: "HEMING",
    value: "57314",
    type: "common",
  },
  {
    label: "GONDREXANGE",
    value: "57253",
    type: "common",
  },
  {
    label: "BARCHAIN",
    value: "57050",
    type: "common",
  },
  {
    label: "ASPACH",
    value: "57034",
    type: "common",
  },
  {
    label: "RECHICOURT-LE-CHATEAU",
    value: "57564",
    type: "common",
  },
  {
    label: "MOUSSEY",
    value: "57488",
    type: "common",
  },
  {
    label: "MAIZIERES-LES-VIC",
    value: "57434",
    type: "common",
  },
  {
    label: "XURES",
    value: "54601",
    type: "common",
  },
  {
    label: "MOUACOURT",
    value: "54388",
    type: "common",
  },
  {
    label: "OMMERAY",
    value: "57524",
    type: "common",
  },
  {
    label: "MONCOURT",
    value: "57473",
    type: "common",
  },
  {
    label: "LAGARDE",
    value: "57375",
    type: "common",
  },
  {
    label: "VAUCOURT",
    value: "54551",
    type: "common",
  },
  {
    label: "COINCOURT",
    value: "54133",
    type: "common",
  },
  {
    label: "RECHICOURT-LA-PETITE",
    value: "54446",
    type: "common",
  },
  {
    label: "HENAMENIL",
    value: "54258",
    type: "common",
  },
  {
    label: "BURES",
    value: "54106",
    type: "common",
  },
  {
    label: "VALHEY",
    value: "54541",
    type: "common",
  },
  {
    label: "BAUZEMONT",
    value: "54053",
    type: "common",
  },
  {
    label: "BATHELEMONT",
    value: "54050",
    type: "common",
  },
  {
    label: "ATHIENVILLE",
    value: "54026",
    type: "common",
  },
  {
    label: "SERRES",
    value: "54502",
    type: "common",
  },
  {
    label: "REMEREVILLE",
    value: "54456",
    type: "common",
  },
  {
    label: "HOEVILLE",
    value: "54262",
    type: "common",
  },
  {
    label: "GELLENONCOURT",
    value: "54219",
    type: "common",
  },
  {
    label: "ERBEVILLER-SUR-AMEZULE",
    value: "54180",
    type: "common",
  },
  {
    label: "COURBESSEAUX",
    value: "54139",
    type: "common",
  },
  {
    label: "VELAINE-SOUS-AMANCE",
    value: "54558",
    type: "common",
  },
  {
    label: "LANEUVELOTTE",
    value: "54296",
    type: "common",
  },
  {
    label: "CERVILLE",
    value: "54110",
    type: "common",
  },
  {
    label: "BUISSONCOURT",
    value: "54104",
    type: "common",
  },
  {
    label: "SEICHAMPS",
    value: "54498",
    type: "common",
  },
  {
    label: "SAULXURES-LES-NANCY",
    value: "54495",
    type: "common",
  },
  {
    label: "PULNOY",
    value: "54439",
    type: "common",
  },
  {
    label: "DOMMARTIN-SOUS-AMANCE",
    value: "54168",
    type: "common",
  },
  {
    label: "AGINCOURT",
    value: "54006",
    type: "common",
  },
  {
    label: "TOMBLAINE",
    value: "54526",
    type: "common",
  },
  {
    label: "SAINT-MAX",
    value: "54482",
    type: "common",
  },
  {
    label: "ESSEY-LES-NANCY",
    value: "54184",
    type: "common",
  },
  {
    label: "DOMMARTEMONT",
    value: "54165",
    type: "common",
  },
  {
    label: "MAXEVILLE",
    value: "54357",
    type: "common",
  },
  {
    label: "LAXOU",
    value: "54304",
    type: "common",
  },
  {
    label: "MALZEVILLE",
    value: "54339",
    type: "common",
  },
  {
    label: "NANCY",
    value: "54395",
    type: "common",
  },
  {
    label: "CHAMPIGNEULLES",
    value: "54115",
    type: "common",
  },
  {
    label: "AINGERAY",
    value: "54007",
    type: "common",
  },
  {
    label: "VILLEY-SAINT-ETIENNE",
    value: "54584",
    type: "common",
  },
  {
    label: "FRANCHEVILLE",
    value: "54208",
    type: "common",
  },
  {
    label: "FONTENOY-SUR-MOSELLE",
    value: "54202",
    type: "common",
  },
  {
    label: "TOUL",
    value: "54528",
    type: "common",
  },
  {
    label: "PAGNEY-DERRIERE-BARINE",
    value: "54414",
    type: "common",
  },
  {
    label: "BRULEY",
    value: "54102",
    type: "common",
  },
  {
    label: "BOUVRON",
    value: "54088",
    type: "common",
  },
  {
    label: "TRONDES",
    value: "54534",
    type: "common",
  },
  {
    label: "LUCEY",
    value: "54327",
    type: "common",
  },
  {
    label: "LANEUVEVILLE-DERRIERE-FOUG",
    value: "54298",
    type: "common",
  },
  {
    label: "LAGNEY",
    value: "54288",
    type: "common",
  },
  {
    label: "TROUSSEY",
    value: "55520",
    type: "common",
  },
  {
    label: "SORCY-SAINT-MARTIN",
    value: "55496",
    type: "common",
  },
  {
    label: "LANEUVILLE-AU-RUPT",
    value: "55278",
    type: "common",
  },
  {
    label: "EUVILLE",
    value: "55184",
    type: "common",
  },
  {
    label: "COMMERCY",
    value: "55122",
    type: "common",
  },
  {
    label: "MENIL-LA-HORGNE",
    value: "55334",
    type: "common",
  },
  {
    label: "SAINT-AUBIN-SUR-AIRE",
    value: "55454",
    type: "common",
  },
  {
    label: "NANCOIS-LE-GRAND",
    value: "55371",
    type: "common",
  },
  {
    label: "ERNEVILLE-AUX-BOIS",
    value: "55179",
    type: "common",
  },
  {
    label: "WILLERONCOURT",
    value: "55581",
    type: "common",
  },
  {
    label: "SALMAGNE",
    value: "55466",
    type: "common",
  },
  {
    label: "NANCOIS-SUR-ORNAIN",
    value: "55372",
    type: "common",
  },
  {
    label: "TRONVILLE-EN-BARROIS",
    value: "55519",
    type: "common",
  },
  {
    label: "SILMONT",
    value: "55488",
    type: "common",
  },
  {
    label: "GUERPONT",
    value: "55221",
    type: "common",
  },
  {
    label: "SAVONNIERES-DEVANT-BAR",
    value: "55476",
    type: "common",
  },
  {
    label: "LONGEVILLE-EN-BARROIS",
    value: "55302",
    type: "common",
  },
  {
    label: "BAR-LE-DUC",
    value: "55029",
    type: "common",
  },
  {
    label: "VILLE-SUR-SAULX",
    value: "55568",
    type: "common",
  },
  {
    label: "TREMONT-SUR-SAULX",
    value: "55514",
    type: "common",
  },
  {
    label: "L'ISLE-EN-RIGAULT",
    value: "55296",
    type: "common",
  },
  {
    label: "COMBLES-EN-BARROIS",
    value: "55120",
    type: "common",
  },
  {
    label: "TROIS-FONTAINES-L'ABBAYE",
    value: "51583",
    type: "common",
  },
  {
    label: "ROBERT-ESPAGNE",
    value: "55435",
    type: "common",
  },
  {
    label: "BEUREY-SUR-SAULX",
    value: "55049",
    type: "common",
  },
  {
    label: "CHEMINON",
    value: "51144",
    type: "common",
  },
  {
    label: "MAURUPT-LE-MONTOIS",
    value: "51358",
    type: "common",
  },
  {
    label: "BIGNICOURT-SUR-SAULX",
    value: "51060",
    type: "common",
  },
  {
    label: "SAINT-LUMIER-LA-POPULEUSE",
    value: "51497",
    type: "common",
  },
  {
    label: "PARGNY-SUR-SAULX",
    value: "51423",
    type: "common",
  },
  {
    label: "ETREPY",
    value: "51240",
    type: "common",
  },
  {
    label: "BLESME",
    value: "51068",
    type: "common",
  },
  {
    label: "OUTREPONT",
    value: "51420",
    type: "common",
  },
  {
    label: "HEILTZ-L'EVEQUE",
    value: "51290",
    type: "common",
  },
  {
    label: "PONTHION",
    value: "51441",
    type: "common",
  },
  {
    label: "FAVRESSE",
    value: "51246",
    type: "common",
  },
  {
    label: "DOMPREMY",
    value: "51215",
    type: "common",
  },
  {
    label: "LE BUISSON",
    value: "51095",
    type: "common",
  },
  {
    label: "BRUSSON",
    value: "51094",
    type: "common",
  },
  {
    label: "VITRY-LE-FRANCOIS",
    value: "51649",
    type: "common",
  },
  {
    label: "VITRY-EN-PERTHOIS",
    value: "51647",
    type: "common",
  },
  {
    label: "SAINT-QUENTIN-LES-MARAIS",
    value: "51510",
    type: "common",
  },
  {
    label: "MERLAUT",
    value: "51363",
    type: "common",
  },
  {
    label: "PLICHANCOURT",
    value: "51433",
    type: "common",
  },
  {
    label: "REIMS-LA-BRULEE",
    value: "51455",
    type: "common",
  },
  {
    label: "LOISY-SUR-MARNE",
    value: "51328",
    type: "common",
  },
  {
    label: "DROUILLY",
    value: "51220",
    type: "common",
  },
  {
    label: "BLACY",
    value: "51065",
    type: "common",
  },
  {
    label: "COUVROT",
    value: "51195",
    type: "common",
  },
  {
    label: "PRINGY",
    value: "51446",
    type: "common",
  },
  {
    label: "MAISONS-EN-CHAMPAGNE",
    value: "51340",
    type: "common",
  },
  {
    label: "COOLE",
    value: "51167",
    type: "common",
  },
  {
    label: "DOMMARTIN-LETTREE",
    value: "51212",
    type: "common",
  },
  {
    label: "VASSIMONT-ET-CHAPELAINE",
    value: "51594",
    type: "common",
  },
  {
    label: "HAUSSIMONT",
    value: "51285",
    type: "common",
  },
  {
    label: "LENHARREE",
    value: "51319",
    type: "common",
  },
  {
    label: "CONNANTRAY-VAUREFROY",
    value: "51164",
    type: "common",
  },
  {
    label: "FERE-CHAMPENOISE",
    value: "51248",
    type: "common",
  },
  {
    label: "CONNANTRE",
    value: "51165",
    type: "common",
  },
  {
    label: "BROUSSY-LE-GRAND",
    value: "51090",
    type: "common",
  },
  {
    label: "LINTHES",
    value: "51324",
    type: "common",
  },
  {
    label: "SAINT-LOUP",
    value: "51495",
    type: "common",
  },
  {
    label: "BROUSSY-LE-PETIT",
    value: "51091",
    type: "common",
  },
  {
    label: "ALLEMANT",
    value: "51005",
    type: "common",
  },
  {
    label: "BROYES",
    value: "51092",
    type: "common",
  },
  {
    label: "MONDEMENT-MONTGIVROUX",
    value: "51374",
    type: "common",
  },
  {
    label: "LES ESSARTS-LES-SEZANNE",
    value: "51235",
    type: "common",
  },
  {
    label: "LA NOUE",
    value: "51407",
    type: "common",
  },
  {
    label: "MŒURS-VERDEY",
    value: "51369",
    type: "common",
  },
  {
    label: "LACHY",
    value: "51313",
    type: "common",
  },
  {
    label: "CHAMPGUYON",
    value: "51116",
    type: "common",
  },
  {
    label: "VILLENEUVE-LA-LIONNE",
    value: "51625",
    type: "common",
  },
  {
    label: "REVEILLON",
    value: "51459",
    type: "common",
  },
  {
    label: "NEUVY",
    value: "51402",
    type: "common",
  },
  {
    label: "JOISELLE",
    value: "51306",
    type: "common",
  },
  {
    label: "MEILLERAY",
    value: "77287",
    type: "common",
  },
  {
    label: "LESCHEROLLES",
    value: "77247",
    type: "common",
  },
  {
    label: "LA CHAPELLE-MOUTILS",
    value: "77093",
    type: "common",
  },
  {
    label: "SAINT-MARTIN-DES-CHAMPS",
    value: "77423",
    type: "common",
  },
  {
    label: "LA FERTE-GAUCHER",
    value: "77182",
    type: "common",
  },
  {
    label: "CHARTRONGES",
    value: "77097",
    type: "common",
  },
  {
    label: "MAROLLES-EN-BRIE",
    value: "77278",
    type: "common",
  },
  {
    label: "CHOISY-EN-BRIE",
    value: "77116",
    type: "common",
  },
  {
    label: "CHAILLY-EN-BRIE",
    value: "77070",
    type: "common",
  },
  {
    label: "SAINT-AUGUSTIN",
    value: "77400",
    type: "common",
  },
  {
    label: "MAUPERTHUIS",
    value: "77281",
    type: "common",
  },
  {
    label: "FAREMOUTIERS",
    value: "77176",
    type: "common",
  },
  {
    label: "HAUTEFEUILLE",
    value: "77224",
    type: "common",
  },
  {
    label: "CREVECŒUR-EN-BRIE",
    value: "77144",
    type: "common",
  },
  {
    label: "NEUFMOUTIERS-EN-BRIE",
    value: "77336",
    type: "common",
  },
  {
    label: "MORTCERF",
    value: "77318",
    type: "common",
  },
  {
    label: "LA HOUSSAYE-EN-BRIE",
    value: "77229",
    type: "common",
  },
  {
    label: "LES CHAPELLES-BOURBON",
    value: "77091",
    type: "common",
  },
  {
    label: "TOURNAN-EN-BRIE",
    value: "77470",
    type: "common",
  },
  {
    label: "GRETZ-ARMAINVILLIERS",
    value: "77215",
    type: "common",
  },
  {
    label: "FAVIERES",
    value: "77177",
    type: "common",
  },
  {
    label: "ROISSY-EN-BRIE",
    value: "77390",
    type: "common",
  },
  {
    label: "PONTCARRE",
    value: "77374",
    type: "common",
  },
  {
    label: "OZOIR-LA-FERRIERE",
    value: "77350",
    type: "common",
  },
  {
    label: "PONTAULT-COMBAULT",
    value: "77373",
    type: "common",
  },
  {
    label: "SUCY-EN-BRIE",
    value: "94071",
    type: "common",
  },
  {
    label: "LA QUEUE-EN-BRIE",
    value: "94060",
    type: "common",
  },
  {
    label: "NOISEAU",
    value: "94053",
    type: "common",
  },
  {
    label: "LESIGNY",
    value: "77249",
    type: "common",
  },
  {
    label: "VALENTON",
    value: "94074",
    type: "common",
  },
  {
    label: "SAINT-MAUR-DES-FOSSES",
    value: "94068",
    type: "common",
  },
  {
    label: "ORMESSON-SUR-MARNE",
    value: "94055",
    type: "common",
  },
  {
    label: "LIMEIL-BREVANNES",
    value: "94044",
    type: "common",
  },
  {
    label: "CRETEIL",
    value: "94028",
    type: "common",
  },
  {
    label: "BONNEUIL-SUR-MARNE",
    value: "94011",
    type: "common",
  },
  {
    label: "BOISSY-SAINT-LEGER",
    value: "94004",
    type: "common",
  },
  {
    label: "VITRY-SUR-SEINE",
    value: "94081",
    type: "common",
  },
  {
    label: "THIAIS",
    value: "94073",
    type: "common",
  },
  {
    label: "CHOISY-LE-ROI",
    value: "94022",
    type: "common",
  },
  {
    label: "ALFORTVILLE",
    value: "94002",
    type: "common",
  },
  {
    label: "VILLEJUIF",
    value: "94076",
    type: "common",
  },
  {
    label: "RUNGIS",
    value: "94065",
    type: "common",
  },
  {
    label: "L'HAŸ-LES-ROSES",
    value: "94038",
    type: "common",
  },
  {
    label: "FRESNES",
    value: "94034",
    type: "common",
  },
  {
    label: "CHEVILLY-LARUE",
    value: "94021",
    type: "common",
  },
  {
    label: "CACHAN",
    value: "94016",
    type: "common",
  },
  {
    label: "SCEAUX",
    value: "92071",
    type: "common",
  },
  {
    label: "FONTENAY-AUX-ROSES",
    value: "92032",
    type: "common",
  },
  {
    label: "BOURG-LA-REINE",
    value: "92014",
    type: "common",
  },
  {
    label: "BAGNEUX",
    value: "92007",
    type: "common",
  },
  {
    label: "ANTONY",
    value: "92002",
    type: "common",
  },
  {
    label: "LE PLESSIS-ROBINSON",
    value: "92060",
    type: "common",
  },
  {
    label: "CLAMART",
    value: "92023",
    type: "common",
  },
  {
    label: "CHATENAY-MALABRY",
    value: "92019",
    type: "common",
  },
  {
    label: "VERRIERES-LE-BUISSON",
    value: "91645",
    type: "common",
  },
  {
    label: "BIEVRES",
    value: "91064",
    type: "common",
  },
  {
    label: "VELIZY-VILLACOUBLAY",
    value: "78640",
    type: "common",
  },
  {
    label: "TOUSSUS-LE-NOBLE",
    value: "78620",
    type: "common",
  },
  {
    label: "LES LOGES-EN-JOSAS",
    value: "78343",
    type: "common",
  },
  {
    label: "JOUY-EN-JOSAS",
    value: "78322",
    type: "common",
  },
  {
    label: "BUC",
    value: "78117",
    type: "common",
  },
  {
    label: "GUYANCOURT",
    value: "78297",
    type: "common",
  },
  {
    label: "MONTIGNY-LE-BRETONNEUX",
    value: "78423",
    type: "common",
  },
  {
    label: "VOISINS-LE-BRETONNEUX",
    value: "78688",
    type: "common",
  },
  {
    label: "BOIS-D'ARCY",
    value: "78073",
    type: "common",
  },
  {
    label: "LA VERRIERE",
    value: "78644",
    type: "common",
  },
  {
    label: "TRAPPES",
    value: "78621",
    type: "common",
  },
  {
    label: "MAUREPAS",
    value: "78383",
    type: "common",
  },
  {
    label: "ELANCOURT",
    value: "78208",
    type: "common",
  },
  {
    label: "COIGNIERES",
    value: "78168",
    type: "common",
  },
  {
    label: "LE TREMBLAY-SUR-MAULDRE",
    value: "78623",
    type: "common",
  },
  {
    label: "SAINT-REMY-L'HONORE",
    value: "78576",
    type: "common",
  },
  {
    label: "LES MESNULS",
    value: "78398",
    type: "common",
  },
  {
    label: "MAREIL-LE-GUYON",
    value: "78366",
    type: "common",
  },
  {
    label: "JOUARS-PONTCHARTRAIN",
    value: "78321",
    type: "common",
  },
  {
    label: "BAZOCHES-SUR-GUYONNE",
    value: "78050",
    type: "common",
  },
  {
    label: "LA QUEUE-LES-YVELINES",
    value: "78513",
    type: "common",
  },
  {
    label: "MONTFORT-L'AMAURY",
    value: "78420",
    type: "common",
  },
  {
    label: "MERE",
    value: "78389",
    type: "common",
  },
  {
    label: "GROSROUVRE",
    value: "78289",
    type: "common",
  },
  {
    label: "GALLUIS",
    value: "78262",
    type: "common",
  },
  {
    label: "GAMBAISEUIL",
    value: "78264",
    type: "common",
  },
  {
    label: "GAMBAIS",
    value: "78263",
    type: "common",
  },
  {
    label: "BOURDONNE",
    value: "78096",
    type: "common",
  },
  {
    label: "MAULETTE",
    value: "78381",
    type: "common",
  },
  {
    label: "HOUDAN",
    value: "78310",
    type: "common",
  },
  {
    label: "DANNEMARIE",
    value: "78194",
    type: "common",
  },
  {
    label: "SERVILLE",
    value: "28375",
    type: "common",
  },
  {
    label: "MARCHEZAIS",
    value: "28235",
    type: "common",
  },
  {
    label: "HAVELU",
    value: "28193",
    type: "common",
  },
  {
    label: "GOUSSAINVILLE",
    value: "28185",
    type: "common",
  },
  {
    label: "GERMAINVILLE",
    value: "28178",
    type: "common",
  },
  {
    label: "BROUE",
    value: "28062",
    type: "common",
  },
  {
    label: "MONTREUIL",
    value: "28267",
    type: "common",
  },
  {
    label: "CHERISY",
    value: "28098",
    type: "common",
  },
  {
    label: "ABONDANT",
    value: "28001",
    type: "common",
  },
  {
    label: "DREUX",
    value: "28134",
    type: "common",
  },
  {
    label: "SAINT-GEORGES-MOTEL",
    value: "27543",
    type: "common",
  },
  {
    label: "MUZY",
    value: "27423",
    type: "common",
  },
  {
    label: "MESNIL-SUR-L'ESTREE",
    value: "27406",
    type: "common",
  },
  {
    label: "LOUYE",
    value: "27376",
    type: "common",
  },
  {
    label: "SAINT-REMY-SUR-AVRE",
    value: "28359",
    type: "common",
  },
  {
    label: "VERT-EN-DROUAIS",
    value: "28405",
    type: "common",
  },
  {
    label: "SAINT-GERMAIN-SUR-AVRE",
    value: "27548",
    type: "common",
  },
  {
    label: "LA MADELEINE-DE-NONANCOURT",
    value: "27378",
    type: "common",
  },
  {
    label: "SAINT-LUBIN-DES-JONCHERETS",
    value: "28348",
    type: "common",
  },
  {
    label: "DAMPIERRE-SUR-AVRE",
    value: "28124",
    type: "common",
  },
  {
    label: "NONANCOURT",
    value: "27438",
    type: "common",
  },
  {
    label: "DROISY",
    value: "27206",
    type: "common",
  },
  {
    label: "TILLIERES-SUR-AVRE",
    value: "27643",
    type: "common",
  },
  {
    label: "L'HOSMES",
    value: "27341",
    type: "common",
  },
  {
    label: "BREUX-SUR-AVRE",
    value: "27115",
    type: "common",
  },
  {
    label: "ACON",
    value: "27002",
    type: "common",
  },
  {
    label: "PISEUX",
    value: "27457",
    type: "common",
  },
  {
    label: "COURTEILLES",
    value: "27182",
    type: "common",
  },
  {
    label: "BALINES",
    value: "27036",
    type: "common",
  },
  {
    label: "VERNEUIL D'AVRE ET D'ITON",
    value: "27679",
    type: "common",
  },
  {
    label: "MANDRES",
    value: "27383",
    type: "common",
  },
  {
    label: "CHERONVILLIERS",
    value: "27156",
    type: "common",
  },
  {
    label: "CHAISE-DIEU-DU-THEIL",
    value: "27137",
    type: "common",
  },
  {
    label: "BOURTH",
    value: "27108",
    type: "common",
  },
  {
    label: "SAINT-OUEN-SUR-ITON",
    value: "61440",
    type: "common",
  },
  {
    label: "SAINT-MICHEL-TUBŒUF",
    value: "61432",
    type: "common",
  },
  {
    label: "CHANDAI",
    value: "61092",
    type: "common",
  },
  {
    label: "SAINT-SULPICE-SUR-RISLE",
    value: "61456",
    type: "common",
  },
  {
    label: "L'AIGLE",
    value: "61214",
    type: "common",
  },
  {
    label: "SAINT-SYMPHORIEN-DES-BRUYERES",
    value: "61457",
    type: "common",
  },
  {
    label: "RAI",
    value: "61342",
    type: "common",
  },
  {
    label: "BEAUFAI",
    value: "61032",
    type: "common",
  },
  {
    label: "AUBE",
    value: "61008",
    type: "common",
  },
  {
    label: "SAINT-PIERRE-DES-LOGES",
    value: "61446",
    type: "common",
  },
  {
    label: "SAINT-EVROULT-NOTRE-DAME-DU-BOIS",
    value: "61386",
    type: "common",
  },
  {
    label: "ORGERES",
    value: "61317",
    type: "common",
  },
  {
    label: "CISAI-SAINT-AUBIN",
    value: "61108",
    type: "common",
  },
  {
    label: "CHAMP-HAUT",
    value: "61088",
    type: "common",
  },
  {
    label: "SAINT-GERMAIN-DE-CLAIREFEUILLE",
    value: "61393",
    type: "common",
  },
  {
    label: "LE MENIL-VICOMTE",
    value: "61272",
    type: "common",
  },
  {
    label: "MENIL-FROGER",
    value: "61264",
    type: "common",
  },
  {
    label: "LIGNERES",
    value: "61225",
    type: "common",
  },
  {
    label: "CROISILLES",
    value: "61138",
    type: "common",
  },
  {
    label: "COULMER",
    value: "61122",
    type: "common",
  },
  {
    label: "LE PIN-AU-HARAS",
    value: "61328",
    type: "common",
  },
  {
    label: "GINAI",
    value: "61190",
    type: "common",
  },
  {
    label: "GOUFFERN EN AUGE",
    value: "61474",
    type: "common",
  },
  {
    label: "SEVIGNY",
    value: "61472",
    type: "common",
  },
  {
    label: "SAI",
    value: "61358",
    type: "common",
  },
  {
    label: "ARGENTAN",
    value: "61006",
    type: "common",
  },
  {
    label: "OCCAGNES",
    value: "61314",
    type: "common",
  },
  {
    label: "MOULINS-SUR-ORNE",
    value: "61298",
    type: "common",
  },
  {
    label: "MONTS-SUR-ORNE",
    value: "61194",
    type: "common",
  },
  {
    label: "GIEL-COURTEILLES",
    value: "61189",
    type: "common",
  },
  {
    label: "PUTANGES-LE-LAC",
    value: "61339",
    type: "common",
  },
  {
    label: "MENIL-GONDOUIN",
    value: "61265",
    type: "common",
  },
  {
    label: "SAINT-ANDRE-DE-BRIOUZE",
    value: "61361",
    type: "common",
  },
  {
    label: "CRAMENIL",
    value: "61137",
    type: "common",
  },
  {
    label: "SAINTE-OPPORTUNE",
    value: "61436",
    type: "common",
  },
  {
    label: "LANDIGOU",
    value: "61221",
    type: "common",
  },
  {
    label: "DURCET",
    value: "61148",
    type: "common",
  },
  {
    label: "LA SELLE-LA-FORGE",
    value: "61466",
    type: "common",
  },
  {
    label: "SAINT-GEORGES-DES-GROSEILLERS",
    value: "61391",
    type: "common",
  },
  {
    label: "MESSEI",
    value: "61278",
    type: "common",
  },
  {
    label: "FLERS",
    value: "61169",
    type: "common",
  },
  {
    label: "ECHALOU",
    value: "61149",
    type: "common",
  },
  {
    label: "SAINT-PAUL",
    value: "61443",
    type: "common",
  },
  {
    label: "LANDISACQ",
    value: "61222",
    type: "common",
  },
  {
    label: "LA LANDE-PATRY",
    value: "61218",
    type: "common",
  },
  {
    label: "LA CHAPELLE-BICHE",
    value: "61095",
    type: "common",
  },
  {
    label: "TINCHEBRAY-BOCAGE",
    value: "61486",
    type: "common",
  },
  {
    label: "CHANU",
    value: "61093",
    type: "common",
  },
  {
    label: "SAINT-CHRISTOPHE-DE-CHAULIEU",
    value: "61374",
    type: "common",
  },
  {
    label: "LE MENIL-CIBOULT",
    value: "61262",
    type: "common",
  },
  {
    label: "LE FRESNE-PORET",
    value: "50193",
    type: "common",
  },
  {
    label: "SOURDEVAL",
    value: "50582",
    type: "common",
  },
  {
    label: "CHAULIEU",
    value: "50514",
    type: "common",
  },
  {
    label: "PERRIERS-EN-BEAUFICEL",
    value: "50397",
    type: "common",
  },
  {
    label: "BROUAINS",
    value: "50088",
    type: "common",
  },
  {
    label: "BEAUFICEL",
    value: "50040",
    type: "common",
  },
  {
    label: "SAINT-POIS",
    value: "50542",
    type: "common",
  },
  {
    label: "SAINT-LAURENT-DE-CUVES",
    value: "50499",
    type: "common",
  },
  {
    label: "LE MESNIL-GILBERT",
    value: "50312",
    type: "common",
  },
  {
    label: "LE MESNIL-ADELEE",
    value: "50300",
    type: "common",
  },
  {
    label: "LINGEARD",
    value: "50271",
    type: "common",
  },
  {
    label: "CUVES",
    value: "50158",
    type: "common",
  },
  {
    label: "NOTRE-DAME-DE-LIVOYE",
    value: "50379",
    type: "common",
  },
  {
    label: "LES CRESNAYS",
    value: "50152",
    type: "common",
  },
  {
    label: "BRECEY",
    value: "50074",
    type: "common",
  },
  {
    label: "VERNIX",
    value: "50628",
    type: "common",
  },
  {
    label: "SAINT-GEORGES-DE-LIVOYE",
    value: "50472",
    type: "common",
  },
  {
    label: "LE PETIT-CELLAND",
    value: "50399",
    type: "common",
  },
  {
    label: "LA GODEFROY",
    value: "50205",
    type: "common",
  },
  {
    label: "SAINT-JEAN-DE-LA-HAIZE",
    value: "50489",
    type: "common",
  },
  {
    label: "SAINT-BRICE",
    value: "50451",
    type: "common",
  },
  {
    label: "PONTS",
    value: "50411",
    type: "common",
  },
  {
    label: "CHAVOY",
    value: "50126",
    type: "common",
  },
  {
    label: "MARCEY-LES-GREVES",
    value: "50288",
    type: "common",
  },
  {
    label: "LOLIF",
    value: "50276",
    type: "common",
  },
  {
    label: "BACILLY",
    value: "50027",
    type: "common",
  },
  {
    label: "SAINT-JEAN-LE-THOMAS",
    value: "50496",
    type: "common",
  },
  {
    label: "GENETS",
    value: "50199",
    type: "common",
  },
  {
    label: "DRAGEY-RONTHON",
    value: "50167",
    type: "common",
  },
  {
    label: "CHAMPEAUX",
    value: "50117",
    type: "common",
  },
  {
    label: "CANCALE",
    value: "35049",
    type: "common",
  },
  {
    label: "SAINT-COULOMB",
    value: "35263",
    type: "common",
  },
  {
    label: "PLEVENON",
    value: "22201",
    type: "common",
  },
  {
    label: "SAINT-QUAY-PORTRIEUX",
    value: "22325",
    type: "common",
  },
  {
    label: "TREVENEUC",
    value: "22377",
    type: "common",
  },
  {
    label: "PLOURHAN",
    value: "22232",
    type: "common",
  },
  {
    label: "PLEGUIEN",
    value: "22177",
    type: "common",
  },
  {
    label: "PLUDUAL",
    value: "22236",
    type: "common",
  },
  {
    label: "TREVEREC",
    value: "22378",
    type: "common",
  },
  {
    label: "TREMEVEN",
    value: "22370",
    type: "common",
  },
  {
    label: "LANNEBERT",
    value: "22112",
    type: "common",
  },
  {
    label: "LANVOLLON",
    value: "22121",
    type: "common",
  },
  {
    label: "GOMMENEC'H",
    value: "22063",
    type: "common",
  },
  {
    label: "POMMERIT-LE-VICOMTE",
    value: "22248",
    type: "common",
  },
  {
    label: "SAINT-GILLES-LES-BOIS",
    value: "22293",
    type: "common",
  },
  {
    label: "SAINT-CLET",
    value: "22283",
    type: "common",
  },
  {
    label: "SQUIFFIEC",
    value: "22338",
    type: "common",
  },
  {
    label: "LANDEBAERON",
    value: "22095",
    type: "common",
  },
  {
    label: "KERMOROC'H",
    value: "22091",
    type: "common",
  },
  {
    label: "BRELIDY",
    value: "22018",
    type: "common",
  },
  {
    label: "COATASCORN",
    value: "22041",
    type: "common",
  },
  {
    label: "BEGARD",
    value: "22004",
    type: "common",
  },
  {
    label: "PLUZUNET",
    value: "22245",
    type: "common",
  },
  {
    label: "LE VIEUX-MARCHE",
    value: "22387",
    type: "common",
  },
  {
    label: "PLOUARET",
    value: "22207",
    type: "common",
  },
  {
    label: "PLUFUR",
    value: "22238",
    type: "common",
  },
  {
    label: "PLOUZELAMBRE",
    value: "22235",
    type: "common",
  },
  {
    label: "LANVELLEC",
    value: "22119",
    type: "common",
  },
  {
    label: "TREMEL",
    value: "22366",
    type: "common",
  },
  {
    label: "PLOUEGAT-GUERAND",
    value: "29182",
    type: "common",
  },
  {
    label: "GARLAN",
    value: "29059",
    type: "common",
  },
  {
    label: "MORLAIX",
    value: "29151",
    type: "common",
  },
  {
    label: "LOCQUENOLE",
    value: "29132",
    type: "common",
  },
  {
    label: "TAULE",
    value: "29279",
    type: "common",
  },
  {
    label: "HENVIC",
    value: "29079",
    type: "common",
  },
  {
    label: "PLOUVORN",
    value: "29210",
    type: "common",
  },
  {
    label: "PLOUENAN",
    value: "29184",
    type: "common",
  },
  {
    label: "MESPAUL",
    value: "29148",
    type: "common",
  },
  {
    label: "TREZILIDE",
    value: "29301",
    type: "common",
  },
  {
    label: "TREFLAOUENAN",
    value: "29285",
    type: "common",
  },
  {
    label: "PLOUZEVEDE",
    value: "29213",
    type: "common",
  },
  {
    label: "SAINT-VOUGAY",
    value: "29271",
    type: "common",
  },
  {
    label: "LANHOUARNEAU",
    value: "29111",
    type: "common",
  },
  {
    label: "PLOUIDER",
    value: "29198",
    type: "common",
  },
  {
    label: "SAINT-FREGANT",
    value: "29248",
    type: "common",
  },
  {
    label: "LESNEVEN",
    value: "29124",
    type: "common",
  },
  {
    label: "LANARVILY",
    value: "29100",
    type: "common",
  },
  {
    label: "KERNOUES",
    value: "29094",
    type: "common",
  },
  {
    label: "LE FOLGOET",
    value: "29055",
    type: "common",
  },
  {
    label: "LOC-BREVALAIRE",
    value: "29126",
    type: "common",
  },
  {
    label: "KERNILIS",
    value: "29093",
    type: "common",
  },
  {
    label: "TREGLONOU",
    value: "29290",
    type: "common",
  },
  {
    label: "LANNILIS",
    value: "29117",
    type: "common",
  },
  {
    label: "SAINT-PABU",
    value: "29257",
    type: "common",
  },
  {
    label: "PLOUDALMEZEAU",
    value: "29178",
    type: "common",
  },
  {
    label: "LANDUNVEZ",
    value: "29109",
    type: "common",
  },
  {
    label: "LAMPAUL-PLOUDALMEZEAU",
    value: "29099",
    type: "common",
  },
  {
    label: "STRASBOURG",
    value: "67482",
    type: "common",
  },
  {
    label: "SCHILTIGHEIM",
    value: "67447",
    type: "common",
  },
  {
    label: "WOLFISHEIM",
    value: "67551",
    type: "common",
  },
  {
    label: "OBERSCHAEFFOLSHEIM",
    value: "67350",
    type: "common",
  },
  {
    label: "OBERHAUSBERGEN",
    value: "67343",
    type: "common",
  },
  {
    label: "MITTELHAUSBERGEN",
    value: "67296",
    type: "common",
  },
  {
    label: "ITTENHEIM",
    value: "67226",
    type: "common",
  },
  {
    label: "HURTIGHEIM",
    value: "67214",
    type: "common",
  },
  {
    label: "ECKBOLSHEIM",
    value: "67118",
    type: "common",
  },
  {
    label: "DINGSHEIM",
    value: "67097",
    type: "common",
  },
  {
    label: "ACHENHEIM",
    value: "67001",
    type: "common",
  },
  {
    label: "OSTHOFFEN",
    value: "67363",
    type: "common",
  },
  {
    label: "HANDSCHUHEIM",
    value: "67181",
    type: "common",
  },
  {
    label: "FURDENHEIM",
    value: "67150",
    type: "common",
  },
  {
    label: "ERGERSHEIM",
    value: "67127",
    type: "common",
  },
  {
    label: "DAHLENHEIM",
    value: "67081",
    type: "common",
  },
  {
    label: "BREUSCHWICKERSHEIM",
    value: "67065",
    type: "common",
  },
  {
    label: "WANGEN",
    value: "67517",
    type: "common",
  },
  {
    label: "TRAENHEIM",
    value: "67492",
    type: "common",
  },
  {
    label: "SCHARRACHBERGHEIM-IRMSTETT",
    value: "67442",
    type: "common",
  },
  {
    label: "ODRATZHEIM",
    value: "67354",
    type: "common",
  },
  {
    label: "MARLENHEIM",
    value: "67282",
    type: "common",
  },
  {
    label: "KIRCHHEIM",
    value: "67240",
    type: "common",
  },
  {
    label: "BERGBIETEN",
    value: "67030",
    type: "common",
  },
  {
    label: "WESTHOFFEN",
    value: "67525",
    type: "common",
  },
  {
    label: "COSSWILLER",
    value: "67077",
    type: "common",
  },
  {
    label: "BALBRONN",
    value: "67018",
    type: "common",
  },
  {
    label: "WANGENBOURG-ENGENTHAL",
    value: "67122",
    type: "common",
  },
  {
    label: "WALSCHEID",
    value: "57742",
    type: "common",
  },
  {
    label: "SAINT-QUIRIN",
    value: "57623",
    type: "common",
  },
  {
    label: "ABRESCHVILLER",
    value: "57003",
    type: "common",
  },
  {
    label: "VASPERVILLER",
    value: "57697",
    type: "common",
  },
  {
    label: "NIDERHOFF",
    value: "57504",
    type: "common",
  },
  {
    label: "METAIRIES-SAINT-QUIRIN",
    value: "57461",
    type: "common",
  },
  {
    label: "LANEUVEVILLE-LES-LORQUIN",
    value: "57380",
    type: "common",
  },
  {
    label: "LAFRIMBOLLE",
    value: "57374",
    type: "common",
  },
  {
    label: "FRAQUELFING",
    value: "57233",
    type: "common",
  },
  {
    label: "RICHEVAL",
    value: "57583",
    type: "common",
  },
  {
    label: "IBIGNY",
    value: "57342",
    type: "common",
  },
  {
    label: "HATTIGNY",
    value: "57302",
    type: "common",
  },
  {
    label: "TANCONVILLE",
    value: "54512",
    type: "common",
  },
  {
    label: "FOULCREY",
    value: "57229",
    type: "common",
  },
  {
    label: "AVRICOURT",
    value: "57042",
    type: "common",
  },
  {
    label: "REPAIX",
    value: "54458",
    type: "common",
  },
  {
    label: "IGNEY",
    value: "54271",
    type: "common",
  },
  {
    label: "GOGNEY",
    value: "54230",
    type: "common",
  },
  {
    label: "FREMONVILLE",
    value: "54211",
    type: "common",
  },
  {
    label: "AVRICOURT",
    value: "54035",
    type: "common",
  },
  {
    label: "REMONCOURT",
    value: "54457",
    type: "common",
  },
  {
    label: "LEINTREY",
    value: "54308",
    type: "common",
  },
  {
    label: "GONDREXON",
    value: "54233",
    type: "common",
  },
  {
    label: "AUTREPIERRE",
    value: "54030",
    type: "common",
  },
  {
    label: "AMENONCOURT",
    value: "54013",
    type: "common",
  },
  {
    label: "XOUSSE",
    value: "54600",
    type: "common",
  },
  {
    label: "VEHO",
    value: "54556",
    type: "common",
  },
  {
    label: "LANEUVEVILLE-AUX-BOIS",
    value: "54297",
    type: "common",
  },
  {
    label: "EMBERMENIL",
    value: "54177",
    type: "common",
  },
  {
    label: "PARROY",
    value: "54418",
    type: "common",
  },
  {
    label: "SIONVILLER",
    value: "54507",
    type: "common",
  },
  {
    label: "CROISMARE",
    value: "54148",
    type: "common",
  },
  {
    label: "CRION",
    value: "54147",
    type: "common",
  },
  {
    label: "RAVILLE-SUR-SANON",
    value: "54445",
    type: "common",
  },
  {
    label: "JOLIVET",
    value: "54281",
    type: "common",
  },
  {
    label: "EINVILLE-AU-JARD",
    value: "54176",
    type: "common",
  },
  {
    label: "DEUXVILLE",
    value: "54155",
    type: "common",
  },
  {
    label: "BONVILLER",
    value: "54083",
    type: "common",
  },
  {
    label: "BIENVILLE-LA-PETITE",
    value: "54074",
    type: "common",
  },
  {
    label: "SOMMERVILLER",
    value: "54509",
    type: "common",
  },
  {
    label: "MAIXE",
    value: "54335",
    type: "common",
  },
  {
    label: "FLAINVAL",
    value: "54195",
    type: "common",
  },
  {
    label: "DROUVILLE",
    value: "54173",
    type: "common",
  },
  {
    label: "DOMBASLE-SUR-MEURTHE",
    value: "54159",
    type: "common",
  },
  {
    label: "CREVIC",
    value: "54145",
    type: "common",
  },
  {
    label: "VARANGEVILLE",
    value: "54549",
    type: "common",
  },
  {
    label: "SAINT-NICOLAS-DE-PORT",
    value: "54483",
    type: "common",
  },
  {
    label: "LENONCOURT",
    value: "54311",
    type: "common",
  },
  {
    label: "HARAUCOURT",
    value: "54250",
    type: "common",
  },
  {
    label: "LANEUVEVILLE-DEVANT-NANCY",
    value: "54300",
    type: "common",
  },
  {
    label: "JARVILLE-LA-MALGRANGE",
    value: "54274",
    type: "common",
  },
  {
    label: "HEILLECOURT",
    value: "54257",
    type: "common",
  },
  {
    label: "FLEVILLE-DEVANT-NANCY",
    value: "54197",
    type: "common",
  },
  {
    label: "ART-SUR-MEURTHE",
    value: "54025",
    type: "common",
  },
  {
    label: "VILLERS-LES-NANCY",
    value: "54578",
    type: "common",
  },
  {
    label: "VANDŒUVRE-LES-NANCY",
    value: "54547",
    type: "common",
  },
  {
    label: "LUDRES",
    value: "54328",
    type: "common",
  },
  {
    label: "HOUDEMONT",
    value: "54265",
    type: "common",
  },
  {
    label: "CHAVIGNY",
    value: "54123",
    type: "common",
  },
  {
    label: "SEXEY-AUX-FORGES",
    value: "54505",
    type: "common",
  },
  {
    label: "MARON",
    value: "54352",
    type: "common",
  },
  {
    label: "CHALIGNY",
    value: "54111",
    type: "common",
  },
  {
    label: "VILLEY-LE-SEC",
    value: "54583",
    type: "common",
  },
  {
    label: "PIERRE-LA-TREICHE",
    value: "54426",
    type: "common",
  },
  {
    label: "GONDREVILLE",
    value: "54232",
    type: "common",
  },
  {
    label: "DOMMARTIN-LES-TOUL",
    value: "54167",
    type: "common",
  },
  {
    label: "CHAUDENEY-SUR-MOSELLE",
    value: "54122",
    type: "common",
  },
  {
    label: "ECROUVES",
    value: "54174",
    type: "common",
  },
  {
    label: "DOMGERMAIN",
    value: "54162",
    type: "common",
  },
  {
    label: "LAY-SAINT-REMY",
    value: "54306",
    type: "common",
  },
  {
    label: "FOUG",
    value: "54205",
    type: "common",
  },
  {
    label: "CHOLOY-MENILLOT",
    value: "54128",
    type: "common",
  },
  {
    label: "UGNY-SUR-MEUSE",
    value: "55522",
    type: "common",
  },
  {
    label: "SAINT-GERMAIN-SUR-MEUSE",
    value: "55456",
    type: "common",
  },
  {
    label: "PAGNY-SUR-MEUSE",
    value: "55398",
    type: "common",
  },
  {
    label: "OURCHES-SUR-MEUSE",
    value: "55396",
    type: "common",
  },
  {
    label: "VOID-VACON",
    value: "55573",
    type: "common",
  },
  {
    label: "NAIVES-EN-BLOIS",
    value: "55368",
    type: "common",
  },
  {
    label: "MELIGNY-LE-GRAND",
    value: "55330",
    type: "common",
  },
  {
    label: "SAULVAUX",
    value: "55472",
    type: "common",
  },
  {
    label: "CHANTERAINE",
    value: "55358",
    type: "common",
  },
  {
    label: "MELIGNY-LE-PETIT",
    value: "55331",
    type: "common",
  },
  {
    label: "BOVIOLLES",
    value: "55067",
    type: "common",
  },
  {
    label: "MENAUCOURT",
    value: "55332",
    type: "common",
  },
  {
    label: "GIVRAUVAL",
    value: "55214",
    type: "common",
  },
  {
    label: "VELAINES",
    value: "55543",
    type: "common",
  },
  {
    label: "TANNOIS",
    value: "55504",
    type: "common",
  },
  {
    label: "NANT-LE-PETIT",
    value: "55374",
    type: "common",
  },
  {
    label: "NANT-LE-GRAND",
    value: "55373",
    type: "common",
  },
  {
    label: "MAULAN",
    value: "55326",
    type: "common",
  },
  {
    label: "LIGNY-EN-BARROIS",
    value: "55291",
    type: "common",
  },
  {
    label: "MONTPLONNE",
    value: "55352",
    type: "common",
  },
  {
    label: "LAVINCOURT",
    value: "55284",
    type: "common",
  },
  {
    label: "BAZINCOURT-SUR-SAULX",
    value: "55035",
    type: "common",
  },
  {
    label: "SOMMELONNE",
    value: "55494",
    type: "common",
  },
  {
    label: "SAUDRUPT",
    value: "55470",
    type: "common",
  },
  {
    label: "RUPT-AUX-NONAINS",
    value: "55447",
    type: "common",
  },
  {
    label: "HAIRONVILLE",
    value: "55224",
    type: "common",
  },
  {
    label: "BRILLON-EN-BARROIS",
    value: "55079",
    type: "common",
  },
  {
    label: "CHANCENAY",
    value: "52104",
    type: "common",
  },
  {
    label: "BAUDONVILLIERS",
    value: "55031",
    type: "common",
  },
  {
    label: "SAINT-EULIEN",
    value: "51478",
    type: "common",
  },
  {
    label: "VILLIERS-EN-LIEU",
    value: "52534",
    type: "common",
  },
  {
    label: "HEILTZ-LE-HUTIER",
    value: "51288",
    type: "common",
  },
  {
    label: "VOUILLERS",
    value: "51654",
    type: "common",
  },
  {
    label: "SCRUPT",
    value: "51528",
    type: "common",
  },
  {
    label: "SAINT-VRAIN",
    value: "51521",
    type: "common",
  },
  {
    label: "PERTHES",
    value: "52386",
    type: "common",
  },
  {
    label: "THIEBLEMONT-FAREMONT",
    value: "51567",
    type: "common",
  },
  {
    label: "ORCONTE",
    value: "51417",
    type: "common",
  },
  {
    label: "HAUSSIGNEMONT",
    value: "51284",
    type: "common",
  },
  {
    label: "ECRIENNES",
    value: "51224",
    type: "common",
  },
  {
    label: "BIGNICOURT-SUR-MARNE",
    value: "51059",
    type: "common",
  },
  {
    label: "LUXEMONT-ET-VILLOTTE",
    value: "51334",
    type: "common",
  },
  {
    label: "MAROLLES",
    value: "51352",
    type: "common",
  },
  {
    label: "FRIGNICOURT",
    value: "51262",
    type: "common",
  },
  {
    label: "VAUCLERC",
    value: "51598",
    type: "common",
  },
  {
    label: "NORROIS",
    value: "51406",
    type: "common",
  },
  {
    label: "MATIGNICOURT-GONCOURT",
    value: "51356",
    type: "common",
  },
  {
    label: "BLAISE-SOUS-ARZILLIERES",
    value: "51066",
    type: "common",
  },
  {
    label: "GLANNES",
    value: "51275",
    type: "common",
  },
  {
    label: "COURDEMANGES",
    value: "51184",
    type: "common",
  },
  {
    label: "HUIRON",
    value: "51295",
    type: "common",
  },
  {
    label: "SOMPUIS",
    value: "51550",
    type: "common",
  },
  {
    label: "SOUDE",
    value: "51555",
    type: "common",
  },
  {
    label: "POIVRES",
    value: "10293",
    type: "common",
  },
  {
    label: "SOMMESOUS",
    value: "51545",
    type: "common",
  },
  {
    label: "MAILLY-LE-CAMP",
    value: "10216",
    type: "common",
  },
  {
    label: "MONTEPREUX",
    value: "51377",
    type: "common",
  },
  {
    label: "SEMOINE",
    value: "10369",
    type: "common",
  },
  {
    label: "GOURGANCON",
    value: "51276",
    type: "common",
  },
  {
    label: "EUVY",
    value: "51241",
    type: "common",
  },
  {
    label: "OGNES",
    value: "51412",
    type: "common",
  },
  {
    label: "CORROY",
    value: "51176",
    type: "common",
  },
  {
    label: "PLEURS",
    value: "51432",
    type: "common",
  },
  {
    label: "LINTHELLES",
    value: "51323",
    type: "common",
  },
  {
    label: "GAYE",
    value: "51265",
    type: "common",
  },
  {
    label: "SAINT-REMY-SOUS-BROYES",
    value: "51514",
    type: "common",
  },
  {
    label: "VINDEY",
    value: "51645",
    type: "common",
  },
  {
    label: "SAUDOY",
    value: "51526",
    type: "common",
  },
  {
    label: "SEZANNE",
    value: "51535",
    type: "common",
  },
  {
    label: "PEAS",
    value: "51426",
    type: "common",
  },
  {
    label: "CHICHEY",
    value: "51151",
    type: "common",
  },
  {
    label: "LE MEIX-SAINT-EPOING",
    value: "51360",
    type: "common",
  },
  {
    label: "ESTERNAY",
    value: "51237",
    type: "common",
  },
  {
    label: "ESCARDES",
    value: "51233",
    type: "common",
  },
  {
    label: "CHATILLON-SUR-MORIN",
    value: "51137",
    type: "common",
  },
  {
    label: "COURGIVAUX",
    value: "51185",
    type: "common",
  },
  {
    label: "SAINT-MARTIN-DU-BOSCHET",
    value: "77424",
    type: "common",
  },
  {
    label: "MONTCEAUX-LES-PROVINS",
    value: "77301",
    type: "common",
  },
  {
    label: "SANCY-LES-PROVINS",
    value: "77444",
    type: "common",
  },
  {
    label: "CERNEUX",
    value: "77066",
    type: "common",
  },
  {
    label: "SAINT-MARS-VIEUX-MAISONS",
    value: "77421",
    type: "common",
  },
  {
    label: "LEUDON-EN-BRIE",
    value: "77250",
    type: "common",
  },
  {
    label: "COURTACON",
    value: "77137",
    type: "common",
  },
  {
    label: "FRETOY",
    value: "77197",
    type: "common",
  },
  {
    label: "DAGNY",
    value: "77151",
    type: "common",
  },
  {
    label: "CHEVRU",
    value: "77113",
    type: "common",
  },
  {
    label: "BETON-BAZOCHES",
    value: "77032",
    type: "common",
  },
  {
    label: "VAUDOY-EN-BRIE",
    value: "77486",
    type: "common",
  },
  {
    label: "AMILLIS",
    value: "77002",
    type: "common",
  },
  {
    label: "VOINSLES",
    value: "77527",
    type: "common",
  },
  {
    label: "TOUQUIN",
    value: "77469",
    type: "common",
  },
  {
    label: "LE PLESSIS-FEU-AUSSOUX",
    value: "77365",
    type: "common",
  },
  {
    label: "PEZARCHES",
    value: "77360",
    type: "common",
  },
  {
    label: "ROZAY-EN-BRIE",
    value: "77393",
    type: "common",
  },
  {
    label: "LUMIGNY-NESLES-ORMEAUX",
    value: "77264",
    type: "common",
  },
  {
    label: "MARLES-EN-BRIE",
    value: "77277",
    type: "common",
  },
  {
    label: "FONTENAY-TRESIGNY",
    value: "77192",
    type: "common",
  },
  {
    label: "CHATRES",
    value: "77104",
    type: "common",
  },
  {
    label: "PRESLES-EN-BRIE",
    value: "77377",
    type: "common",
  },
  {
    label: "LIVERDY-EN-BRIE",
    value: "77254",
    type: "common",
  },
  {
    label: "FEROLLES-ATTILLY",
    value: "77180",
    type: "common",
  },
  {
    label: "CHEVRY-COSSIGNY",
    value: "77114",
    type: "common",
  },
  {
    label: "SANTENY",
    value: "94070",
    type: "common",
  },
  {
    label: "PERIGNY",
    value: "94056",
    type: "common",
  },
  {
    label: "MAROLLES-EN-BRIE",
    value: "94048",
    type: "common",
  },
  {
    label: "MANDRES-LES-ROSES",
    value: "94047",
    type: "common",
  },
  {
    label: "SERVON",
    value: "77450",
    type: "common",
  },
  {
    label: "BRIE-COMTE-ROBERT",
    value: "77053",
    type: "common",
  },
  {
    label: "VILLECRESNES",
    value: "94075",
    type: "common",
  },
  {
    label: "YERRES",
    value: "91691",
    type: "common",
  },
  {
    label: "MONTGERON",
    value: "91421",
    type: "common",
  },
  {
    label: "EPINAY-SOUS-SENART",
    value: "91215",
    type: "common",
  },
  {
    label: "CROSNE",
    value: "91191",
    type: "common",
  },
  {
    label: "BRUNOY",
    value: "91114",
    type: "common",
  },
  {
    label: "BOUSSY-SAINT-ANTOINE",
    value: "91097",
    type: "common",
  },
  {
    label: "VILLENEUVE-SAINT-GEORGES",
    value: "94078",
    type: "common",
  },
  {
    label: "VILLENEUVE-LE-ROI",
    value: "94077",
    type: "common",
  },
  {
    label: "ORLY",
    value: "94054",
    type: "common",
  },
  {
    label: "ABLON-SUR-SEINE",
    value: "94001",
    type: "common",
  },
  {
    label: "VIGNEUX-SUR-SEINE",
    value: "91657",
    type: "common",
  },
  {
    label: "JUVISY-SUR-ORGE",
    value: "91326",
    type: "common",
  },
  {
    label: "ATHIS-MONS",
    value: "91027",
    type: "common",
  },
  {
    label: "WISSOUS",
    value: "91689",
    type: "common",
  },
  {
    label: "SAVIGNY-SUR-ORGE",
    value: "91589",
    type: "common",
  },
  {
    label: "PARAY-VIEILLE-POSTE",
    value: "91479",
    type: "common",
  },
  {
    label: "MORANGIS",
    value: "91432",
    type: "common",
  },
  {
    label: "LONGJUMEAU",
    value: "91345",
    type: "common",
  },
  {
    label: "CHILLY-MAZARIN",
    value: "91161",
    type: "common",
  },
  {
    label: "CHAMPLAN",
    value: "91136",
    type: "common",
  },
  {
    label: "VILLEBON-SUR-YVETTE",
    value: "91661",
    type: "common",
  },
  {
    label: "VAUHALLAN",
    value: "91635",
    type: "common",
  },
  {
    label: "SAULX-LES-CHARTREUX",
    value: "91587",
    type: "common",
  },
  {
    label: "PALAISEAU",
    value: "91477",
    type: "common",
  },
  {
    label: "ORSAY",
    value: "91471",
    type: "common",
  },
  {
    label: "MASSY",
    value: "91377",
    type: "common",
  },
  {
    label: "IGNY",
    value: "91312",
    type: "common",
  },
  {
    label: "VILLIERS-LE-BACLE",
    value: "91679",
    type: "common",
  },
  {
    label: "SAINT-AUBIN",
    value: "91538",
    type: "common",
  },
  {
    label: "SACLAY",
    value: "91534",
    type: "common",
  },
  {
    label: "GIF-SUR-YVETTE",
    value: "91272",
    type: "common",
  },
  {
    label: "BURES-SUR-YVETTE",
    value: "91122",
    type: "common",
  },
  {
    label: "CHATEAUFORT",
    value: "78143",
    type: "common",
  },
  {
    label: "SAINT-REMY-LES-CHEVREUSE",
    value: "78575",
    type: "common",
  },
  {
    label: "MILON-LA-CHAPELLE",
    value: "78406",
    type: "common",
  },
  {
    label: "MAGNY-LES-HAMEAUX",
    value: "78356",
    type: "common",
  },
  {
    label: "CHEVREUSE",
    value: "78160",
    type: "common",
  },
  {
    label: "SENLISSE",
    value: "78590",
    type: "common",
  },
  {
    label: "SAINT-LAMBERT",
    value: "78561",
    type: "common",
  },
  {
    label: "SAINT-FORGET",
    value: "78548",
    type: "common",
  },
  {
    label: "LE MESNIL-SAINT-DENIS",
    value: "78397",
    type: "common",
  },
  {
    label: "LEVIS-SAINT-NOM",
    value: "78334",
    type: "common",
  },
  {
    label: "DAMPIERRE-EN-YVELINES",
    value: "78193",
    type: "common",
  },
  {
    label: "LE PERRAY-EN-YVELINES",
    value: "78486",
    type: "common",
  },
  {
    label: "LES ESSARTS-LE-ROI",
    value: "78220",
    type: "common",
  },
  {
    label: "AUFFARGIS",
    value: "78030",
    type: "common",
  },
  {
    label: "SAINT-LEGER-EN-YVELINES",
    value: "78562",
    type: "common",
  },
  {
    label: "POIGNY-LA-FORET",
    value: "78497",
    type: "common",
  },
  {
    label: "LES BREVIAIRES",
    value: "78108",
    type: "common",
  },
  {
    label: "CONDE-SUR-VESGRE",
    value: "78171",
    type: "common",
  },
  {
    label: "LA BOISSIERE-ECOLE",
    value: "78077",
    type: "common",
  },
  {
    label: "ADAINVILLE",
    value: "78006",
    type: "common",
  },
  {
    label: "LE TARTRE-GAUDRAN",
    value: "78606",
    type: "common",
  },
  {
    label: "LA HAUTEVILLE",
    value: "78302",
    type: "common",
  },
  {
    label: "GRANDCHAMP",
    value: "78283",
    type: "common",
  },
  {
    label: "LES PINTHIERES",
    value: "28299",
    type: "common",
  },
  {
    label: "SAINT-LAURENT-LA-GATINE",
    value: "28343",
    type: "common",
  },
  {
    label: "OUERRE",
    value: "28292",
    type: "common",
  },
  {
    label: "CROISILLES",
    value: "28118",
    type: "common",
  },
  {
    label: "LA CHAPELLE-FORAINVILLIERS",
    value: "28076",
    type: "common",
  },
  {
    label: "BOUTIGNY-PROUAIS",
    value: "28056",
    type: "common",
  },
  {
    label: "SAINTE-GEMME-MORONVAL",
    value: "28332",
    type: "common",
  },
  {
    label: "MEZIERES-EN-DROUAIS",
    value: "28251",
    type: "common",
  },
  {
    label: "LURAY",
    value: "28223",
    type: "common",
  },
  {
    label: "ECLUZELLES",
    value: "28136",
    type: "common",
  },
  {
    label: "CHARPONT",
    value: "28082",
    type: "common",
  },
  {
    label: "VERNOUILLET",
    value: "28404",
    type: "common",
  },
  {
    label: "GARNAY",
    value: "28171",
    type: "common",
  },
  {
    label: "ALLAINVILLE",
    value: "28003",
    type: "common",
  },
  {
    label: "ESCORPAIN",
    value: "28143",
    type: "common",
  },
  {
    label: "CHATAINCOURT",
    value: "28087",
    type: "common",
  },
  {
    label: "LOUVILLIERS-EN-DROUAIS",
    value: "28216",
    type: "common",
  },
  {
    label: "GARANCIERES-EN-DROUAIS",
    value: "28170",
    type: "common",
  },
  {
    label: "CRECY-COUVE",
    value: "28117",
    type: "common",
  },
  {
    label: "BOISSY-EN-DROUAIS",
    value: "28045",
    type: "common",
  },
  {
    label: "PRUDEMANCHE",
    value: "28308",
    type: "common",
  },
  {
    label: "LAONS",
    value: "28206",
    type: "common",
  },
  {
    label: "FESSANVILLIERS-MATTANVILLIERS",
    value: "28151",
    type: "common",
  },
  {
    label: "SAINT-LUBIN-DE-CRAVANT",
    value: "28346",
    type: "common",
  },
  {
    label: "REVERCOURT",
    value: "28315",
    type: "common",
  },
  {
    label: "BREZOLLES",
    value: "28059",
    type: "common",
  },
  {
    label: "BEROU-LA-MULOTIERE",
    value: "28037",
    type: "common",
  },
  {
    label: "RUEIL-LA-GADELIERE",
    value: "28322",
    type: "common",
  },
  {
    label: "MONTIGNY-SUR-AVRE",
    value: "28263",
    type: "common",
  },
  {
    label: "BEAUCHE",
    value: "28030",
    type: "common",
  },
  {
    label: "BOISSY-LES-PERCHE",
    value: "28046",
    type: "common",
  },
  {
    label: "SAINT-VICTOR-SUR-AVRE",
    value: "27610",
    type: "common",
  },
  {
    label: "PULLAY",
    value: "27481",
    type: "common",
  },
  {
    label: "SAINT-CHRISTOPHE-SUR-AVRE",
    value: "27521",
    type: "common",
  },
  {
    label: "GOURNAY-LE-GUERIN",
    value: "27291",
    type: "common",
  },
  {
    label: "CHENNEBRUN",
    value: "27155",
    type: "common",
  },
  {
    label: "LES BARILS",
    value: "27038",
    type: "common",
  },
  {
    label: "ARMENTIERES-SUR-AVRE",
    value: "27019",
    type: "common",
  },
  {
    label: "VITRAI-SOUS-LAIGLE",
    value: "61510",
    type: "common",
  },
  {
    label: "IRAI",
    value: "61208",
    type: "common",
  },
  {
    label: "LES ASPRES",
    value: "61422",
    type: "common",
  },
  {
    label: "CRULAI",
    value: "61140",
    type: "common",
  },
  {
    label: "LA CHAPELLE-VIEL",
    value: "61100",
    type: "common",
  },
  {
    label: "SAINT-HILAIRE-SUR-RISLE",
    value: "61406",
    type: "common",
  },
  {
    label: "LE MENIL-BERARD",
    value: "61259",
    type: "common",
  },
  {
    label: "LA FERRIERE-AU-DOYEN",
    value: "61162",
    type: "common",
  },
  {
    label: "ECORCEI",
    value: "61151",
    type: "common",
  },
  {
    label: "BRETHEL",
    value: "61060",
    type: "common",
  },
  {
    label: "BONNEFOI",
    value: "61052",
    type: "common",
  },
  {
    label: "AUGUAISE",
    value: "61012",
    type: "common",
  },
  {
    label: "SAINTE-GAUBURGE-SAINTE-COLOMBE",
    value: "61389",
    type: "common",
  },
  {
    label: "PLANCHES",
    value: "61330",
    type: "common",
  },
  {
    label: "LA GENEVRAIE",
    value: "61188",
    type: "common",
  },
  {
    label: "ECHAUFFOUR",
    value: "61150",
    type: "common",
  },
  {
    label: "LES AUTHIEUX-DU-PUITS",
    value: "61017",
    type: "common",
  },
  {
    label: "LE MERLERAULT",
    value: "61275",
    type: "common",
  },
  {
    label: "GODISSON",
    value: "61192",
    type: "common",
  },
  {
    label: "NONANT-LE-PIN",
    value: "61310",
    type: "common",
  },
  {
    label: "MEDAVY",
    value: "61256",
    type: "common",
  },
  {
    label: "LE CHATEAU-D'ALMENECHES",
    value: "61101",
    type: "common",
  },
  {
    label: "BOISSEI-LA-LANDE",
    value: "61049",
    type: "common",
  },
  {
    label: "AUNOU-LE-FAUCON",
    value: "61014",
    type: "common",
  },
  {
    label: "ALMENECHES",
    value: "61002",
    type: "common",
  },
  {
    label: "SARCEAUX",
    value: "61462",
    type: "common",
  },
  {
    label: "JUVIGNY-SUR-ORNE",
    value: "61212",
    type: "common",
  },
  {
    label: "TANQUES",
    value: "61479",
    type: "common",
  },
  {
    label: "FLEURE",
    value: "61170",
    type: "common",
  },
  {
    label: "ECOUCHE-LES-VALLEES",
    value: "61153",
    type: "common",
  },
  {
    label: "AVOINE",
    value: "61020",
    type: "common",
  },
  {
    label: "SEVRAI",
    value: "61473",
    type: "common",
  },
  {
    label: "SAINT-BRICE-SOUS-RANES",
    value: "61371",
    type: "common",
  },
  {
    label: "LOUGE-SUR-MAIRE",
    value: "61237",
    type: "common",
  },
  {
    label: "JOUE-DU-PLAIN",
    value: "61210",
    type: "common",
  },
  {
    label: "LES YVETEAUX",
    value: "61512",
    type: "common",
  },
  {
    label: "MONTREUIL-AU-HOULME",
    value: "61290",
    type: "common",
  },
  {
    label: "LA LANDE-DE-LOUGE",
    value: "61217",
    type: "common",
  },
  {
    label: "FAVEROLLES",
    value: "61158",
    type: "common",
  },
  {
    label: "SAINT-HILAIRE-DE-BRIOUZE",
    value: "61402",
    type: "common",
  },
  {
    label: "POINTEL",
    value: "61332",
    type: "common",
  },
  {
    label: "LONLAY-LE-TESSON",
    value: "61233",
    type: "common",
  },
  {
    label: "LIGNOU",
    value: "61227",
    type: "common",
  },
  {
    label: "BRIOUZE",
    value: "61063",
    type: "common",
  },
  {
    label: "SAIRES-LA-VERRERIE",
    value: "61459",
    type: "common",
  },
  {
    label: "LE MENIL-DE-BRIOUZE",
    value: "61260",
    type: "common",
  },
  {
    label: "BELLOU-EN-HOULME",
    value: "61040",
    type: "common",
  },
  {
    label: "SAINT-ANDRE-DE-MESSEI",
    value: "61362",
    type: "common",
  },
  {
    label: "LA FERRIERE-AUX-ETANGS",
    value: "61163",
    type: "common",
  },
  {
    label: "BANVOU",
    value: "61024",
    type: "common",
  },
  {
    label: "SAINT-CLAIR-DE-HALOUZE",
    value: "61376",
    type: "common",
  },
  {
    label: "SAINT-BOMER-LES-FORGES",
    value: "61369",
    type: "common",
  },
  {
    label: "LE CHATELLIER",
    value: "61102",
    type: "common",
  },
  {
    label: "LA CHAPELLE-AU-MOINE",
    value: "61094",
    type: "common",
  },
  {
    label: "LONLAY-L'ABBAYE",
    value: "61232",
    type: "common",
  },
  {
    label: "GER",
    value: "50200",
    type: "common",
  },
  {
    label: "SAINT-CLEMENT-RANCOUDRAY",
    value: "50456",
    type: "common",
  },
  {
    label: "SAINT-BARTHELEMY",
    value: "50450",
    type: "common",
  },
  {
    label: "LE NEUFBOURG",
    value: "50371",
    type: "common",
  },
  {
    label: "MORTAIN-BOCAGE",
    value: "50359",
    type: "common",
  },
  {
    label: "JUVIGNY LES VALLEES",
    value: "50260",
    type: "common",
  },
  {
    label: "REFFUVEILLE",
    value: "50428",
    type: "common",
  },
  {
    label: "LE GRAND-CELLAND",
    value: "50217",
    type: "common",
  },
  {
    label: "LA CHAPELLE-UREE",
    value: "50124",
    type: "common",
  },
  {
    label: "SAINT-OVIN",
    value: "50531",
    type: "common",
  },
  {
    label: "LE MESNIL-OZENNE",
    value: "50317",
    type: "common",
  },
  {
    label: "MARCILLY",
    value: "50290",
    type: "common",
  },
  {
    label: "SAINT-SENIER-SOUS-AVRANCHES",
    value: "50554",
    type: "common",
  },
  {
    label: "SAINT-QUENTIN-SUR-LE-HOMME",
    value: "50543",
    type: "common",
  },
  {
    label: "SAINT-LOUP",
    value: "50505",
    type: "common",
  },
  {
    label: "LE VAL-SAINT-PERE",
    value: "50616",
    type: "common",
  },
  {
    label: "VAINS",
    value: "50612",
    type: "common",
  },
  {
    label: "COURTILS",
    value: "50146",
    type: "common",
  },
  {
    label: "CEAUX",
    value: "50108",
    type: "common",
  },
  {
    label: "LE MONT-SAINT-MICHEL",
    value: "50353",
    type: "common",
  },
  {
    label: "SAINT-BENOIT-DES-ONDES",
    value: "35255",
    type: "common",
  },
  {
    label: "SAINT-MELOIR-DES-ONDES",
    value: "35299",
    type: "common",
  },
  {
    label: "SAINT-MALO",
    value: "35288",
    type: "common",
  },
  {
    label: "LA RICHARDAIS",
    value: "35241",
    type: "common",
  },
  {
    label: "DINARD",
    value: "35093",
    type: "common",
  },
  {
    label: "LANCIEUX",
    value: "22094",
    type: "common",
  },
  {
    label: "SAINT-LUNAIRE",
    value: "35287",
    type: "common",
  },
  {
    label: "SAINT-BRIAC-SUR-MER",
    value: "35256",
    type: "common",
  },
  {
    label: "SAINT-JACUT-DE-LA-MER",
    value: "22302",
    type: "common",
  },
  {
    label: "SAINT-CAST-LE-GUILDO",
    value: "22282",
    type: "common",
  },
  {
    label: "PLEBOULLE",
    value: "22174",
    type: "common",
  },
  {
    label: "MATIGNON",
    value: "22143",
    type: "common",
  },
  {
    label: "FREHEL",
    value: "22179",
    type: "common",
  },
  {
    label: "PLURIEN",
    value: "22242",
    type: "common",
  },
  {
    label: "ERQUY",
    value: "22054",
    type: "common",
  },
  {
    label: "PLENEUF-VAL-ANDRE",
    value: "22186",
    type: "common",
  },
  {
    label: "BINIC-ETABLES-SUR-MER",
    value: "22055",
    type: "common",
  },
  {
    label: "PORDIC",
    value: "22251",
    type: "common",
  },
  {
    label: "LANTIC",
    value: "22117",
    type: "common",
  },
  {
    label: "TREGUIDEL",
    value: "22361",
    type: "common",
  },
  {
    label: "TRESSIGNAUX",
    value: "22375",
    type: "common",
  },
  {
    label: "GOUDELIN",
    value: "22065",
    type: "common",
  },
  {
    label: "BRINGOLO",
    value: "22019",
    type: "common",
  },
  {
    label: "LE MERZER",
    value: "22150",
    type: "common",
  },
  {
    label: "PABU",
    value: "22161",
    type: "common",
  },
  {
    label: "TREGONNEAU",
    value: "22358",
    type: "common",
  },
  {
    label: "PLOUISY",
    value: "22223",
    type: "common",
  },
  {
    label: "GUINGAMP",
    value: "22070",
    type: "common",
  },
  {
    label: "TREGLAMUS",
    value: "22354",
    type: "common",
  },
  {
    label: "SAINT-LAURENT",
    value: "22310",
    type: "common",
  },
  {
    label: "PEDERNEC",
    value: "22164",
    type: "common",
  },
  {
    label: "TREGROM",
    value: "22359",
    type: "common",
  },
  {
    label: "LOUARGAT",
    value: "22135",
    type: "common",
  },
  {
    label: "PLOUNEVEZ-MOEDEC",
    value: "22228",
    type: "common",
  },
  {
    label: "PLOUNERIN",
    value: "22227",
    type: "common",
  },
  {
    label: "PLOUEGAT-MOYSAN",
    value: "29183",
    type: "common",
  },
  {
    label: "SAINT-MARTIN-DES-CHAMPS",
    value: "29254",
    type: "common",
  },
  {
    label: "PLOURIN-LES-MORLAIX",
    value: "29207",
    type: "common",
  },
  {
    label: "SAINTE-SEVE",
    value: "29265",
    type: "common",
  },
  {
    label: "GUICLAN",
    value: "29068",
    type: "common",
  },
  {
    label: "PLOUGOURVEST",
    value: "29193",
    type: "common",
  },
  {
    label: "LANDIVISIAU",
    value: "29105",
    type: "common",
  },
  {
    label: "SAINT-SERVAIS",
    value: "29264",
    type: "common",
  },
  {
    label: "SAINT-DERRIEN",
    value: "29244",
    type: "common",
  },
  {
    label: "PLOUGAR",
    value: "29187",
    type: "common",
  },
  {
    label: "BODILIS",
    value: "29010",
    type: "common",
  },
  {
    label: "TREMAOUEZAN",
    value: "29295",
    type: "common",
  },
  {
    label: "TREGARANTEC",
    value: "29288",
    type: "common",
  },
  {
    label: "SAINT-MEEN",
    value: "29255",
    type: "common",
  },
  {
    label: "PLOUNEVENTER",
    value: "29204",
    type: "common",
  },
  {
    label: "PLOUDANIEL",
    value: "29179",
    type: "common",
  },
  {
    label: "LE DRENNEC",
    value: "29047",
    type: "common",
  },
  {
    label: "PLOUVIEN",
    value: "29209",
    type: "common",
  },
  {
    label: "COAT-MEAL",
    value: "29035",
    type: "common",
  },
  {
    label: "BOURG-BLANC",
    value: "29015",
    type: "common",
  },
  {
    label: "TREOUERGAT",
    value: "29299",
    type: "common",
  },
  {
    label: "PLOUGUIN",
    value: "29196",
    type: "common",
  },
  {
    label: "MILIZAC-GUIPRONVEL",
    value: "29076",
    type: "common",
  },
  {
    label: "PLOURIN",
    value: "29208",
    type: "common",
  },
  {
    label: "PORSPODER",
    value: "29221",
    type: "common",
  },
  {
    label: "LANILDUT",
    value: "29112",
    type: "common",
  },
  {
    label: "OUESSANT",
    value: "29155",
    type: "common",
  },
  {
    label: "OSTWALD",
    value: "67365",
    type: "common",
  },
  {
    label: "ILLKIRCH-GRAFFENSTADEN",
    value: "67218",
    type: "common",
  },
  {
    label: "LINGOLSHEIM",
    value: "67267",
    type: "common",
  },
  {
    label: "HOLTZHEIM",
    value: "67212",
    type: "common",
  },
  {
    label: "HANGENBIETEN",
    value: "67182",
    type: "common",
  },
  {
    label: "GEISPOLSHEIM",
    value: "67152",
    type: "common",
  },
  {
    label: "ENTZHEIM",
    value: "67124",
    type: "common",
  },
  {
    label: "KOLBSHEIM",
    value: "67247",
    type: "common",
  },
  {
    label: "ERNOLSHEIM-BRUCHE",
    value: "67128",
    type: "common",
  },
  {
    label: "DUTTLENHEIM",
    value: "67112",
    type: "common",
  },
  {
    label: "DUPPIGHEIM",
    value: "67108",
    type: "common",
  },
  {
    label: "DACHSTEIN",
    value: "67080",
    type: "common",
  },
  {
    label: "ALTORF",
    value: "67008",
    type: "common",
  },
  {
    label: "DINSHEIM-SUR-BRUCHE",
    value: "67098",
    type: "common",
  },
  {
    label: "WOLXHEIM",
    value: "67554",
    type: "common",
  },
  {
    label: "SOULTZ-LES-BAINS",
    value: "67473",
    type: "common",
  },
  {
    label: "MUTZIG",
    value: "67313",
    type: "common",
  },
  {
    label: "MOLSHEIM",
    value: "67300",
    type: "common",
  },
  {
    label: "DORLISHEIM",
    value: "67101",
    type: "common",
  },
  {
    label: "DANGOLSHEIM",
    value: "67085",
    type: "common",
  },
  {
    label: "AVOLSHEIM",
    value: "67016",
    type: "common",
  },
  {
    label: "STILL",
    value: "67480",
    type: "common",
  },
  {
    label: "NIEDERHASLACH",
    value: "67325",
    type: "common",
  },
  {
    label: "HEILIGENBERG",
    value: "67188",
    type: "common",
  },
  {
    label: "GRESSWILLER",
    value: "67168",
    type: "common",
  },
  {
    label: "FLEXBOURG",
    value: "67139",
    type: "common",
  },
  {
    label: "URMATT",
    value: "67500",
    type: "common",
  },
  {
    label: "OBERHASLACH",
    value: "67342",
    type: "common",
  },
  {
    label: "LUTZELHOUSE",
    value: "67276",
    type: "common",
  },
  {
    label: "TURQUESTEIN-BLANCRUPT",
    value: "57682",
    type: "common",
  },
  {
    label: "VAL-ET-CHATILLON",
    value: "54540",
    type: "common",
  },
  {
    label: "PETITMONT",
    value: "54421",
    type: "common",
  },
  {
    label: "BERTRAMBOIS",
    value: "54064",
    type: "common",
  },
  {
    label: "PARUX",
    value: "54419",
    type: "common",
  },
  {
    label: "NONHIGNY",
    value: "54401",
    type: "common",
  },
  {
    label: "HARBOUEY",
    value: "54251",
    type: "common",
  },
  {
    label: "CIREY-SUR-VEZOUZE",
    value: "54129",
    type: "common",
  },
  {
    label: "HALLOVILLE",
    value: "54246",
    type: "common",
  },
  {
    label: "DOMEVRE-SUR-VEZOUZE",
    value: "54161",
    type: "common",
  },
  {
    label: "BLAMONT",
    value: "54077",
    type: "common",
  },
  {
    label: "BARBAS",
    value: "54044",
    type: "common",
  },
  {
    label: "VERDENAL",
    value: "54562",
    type: "common",
  },
  {
    label: "SAINT-MARTIN",
    value: "54480",
    type: "common",
  },
  {
    label: "REILLON",
    value: "54452",
    type: "common",
  },
  {
    label: "OGEVILLER",
    value: "54406",
    type: "common",
  },
  {
    label: "HERBEVILLER",
    value: "54259",
    type: "common",
  },
  {
    label: "CHAZELLES-SUR-ALBE",
    value: "54124",
    type: "common",
  },
  {
    label: "BLEMEREY",
    value: "54078",
    type: "common",
  },
  {
    label: "THIEBAUMENIL",
    value: "54520",
    type: "common",
  },
  {
    label: "MANONVILLER",
    value: "54349",
    type: "common",
  },
  {
    label: "FREMENIL",
    value: "54210",
    type: "common",
  },
  {
    label: "DOMJEVIN",
    value: "54163",
    type: "common",
  },
  {
    label: "BENAMENIL",
    value: "54061",
    type: "common",
  },
  {
    label: "MONCEL-LES-LUNEVILLE",
    value: "54373",
    type: "common",
  },
  {
    label: "MARAINVILLER",
    value: "54350",
    type: "common",
  },
  {
    label: "REHAINVILLER",
    value: "54449",
    type: "common",
  },
  {
    label: "LUNEVILLE",
    value: "54329",
    type: "common",
  },
  {
    label: "HERIMENIL",
    value: "54260",
    type: "common",
  },
  {
    label: "CHANTEHEUX",
    value: "54116",
    type: "common",
  },
  {
    label: "VITRIMONT",
    value: "54588",
    type: "common",
  },
  {
    label: "HUDIVILLER",
    value: "54269",
    type: "common",
  },
  {
    label: "DAMELEVIERES",
    value: "54152",
    type: "common",
  },
  {
    label: "ANTHELUPT",
    value: "54020",
    type: "common",
  },
  {
    label: "VIGNEULLES",
    value: "54565",
    type: "common",
  },
  {
    label: "ROSIERES-AUX-SALINES",
    value: "54462",
    type: "common",
  },
  {
    label: "MANONCOURT-EN-VERMOIS",
    value: "54345",
    type: "common",
  },
  {
    label: "COYVILLER",
    value: "54141",
    type: "common",
  },
  {
    label: "VILLE-EN-VERMOIS",
    value: "54571",
    type: "common",
  },
  {
    label: "LUPCOURT",
    value: "54330",
    type: "common",
  },
  {
    label: "FLAVIGNY-SUR-MOSELLE",
    value: "54196",
    type: "common",
  },
  {
    label: "BURTHECOURT-AUX-CHENES",
    value: "54108",
    type: "common",
  },
  {
    label: "AZELOT",
    value: "54037",
    type: "common",
  },
  {
    label: "RICHARDMENIL",
    value: "54459",
    type: "common",
  },
  {
    label: "NEUVES-MAISONS",
    value: "54397",
    type: "common",
  },
  {
    label: "MESSEIN",
    value: "54366",
    type: "common",
  },
  {
    label: "MEREVILLE",
    value: "54364",
    type: "common",
  },
  {
    label: "FROLOIS",
    value: "54214",
    type: "common",
  },
  {
    label: "VITERNE",
    value: "54586",
    type: "common",
  },
  {
    label: "PONT-SAINT-VINCENT",
    value: "54432",
    type: "common",
  },
  {
    label: "MAIZIERES",
    value: "54336",
    type: "common",
  },
  {
    label: "BAINVILLE-SUR-MADON",
    value: "54043",
    type: "common",
  },
  {
    label: "THUILLEY-AUX-GROSEILLES",
    value: "54523",
    type: "common",
  },
  {
    label: "OCHEY",
    value: "54405",
    type: "common",
  },
  {
    label: "BICQUELEY",
    value: "54073",
    type: "common",
  },
  {
    label: "MOUTROT",
    value: "54392",
    type: "common",
  },
  {
    label: "MONT-LE-VIGNOBLE",
    value: "54380",
    type: "common",
  },
  {
    label: "GYE",
    value: "54242",
    type: "common",
  },
  {
    label: "CREZILLES",
    value: "54146",
    type: "common",
  },
  {
    label: "CHARMES-LA-COTE",
    value: "54120",
    type: "common",
  },
  {
    label: "BULLIGNY",
    value: "54105",
    type: "common",
  },
  {
    label: "BLENOD-LES-TOUL",
    value: "54080",
    type: "common",
  },
  {
    label: "RIGNY-SAINT-MARTIN",
    value: "55434",
    type: "common",
  },
  {
    label: "VANNES-LE-CHATEL",
    value: "54548",
    type: "common",
  },
  {
    label: "GIBEAUMEIX",
    value: "54226",
    type: "common",
  },
  {
    label: "RIGNY-LA-SALLE",
    value: "55433",
    type: "common",
  },
  {
    label: "CHALAINES",
    value: "55097",
    type: "common",
  },
  {
    label: "VILLEROY-SUR-MEHOLLE",
    value: "55559",
    type: "common",
  },
  {
    label: "VAUCOULEURS",
    value: "55533",
    type: "common",
  },
  {
    label: "SAUVOY",
    value: "55475",
    type: "common",
  },
  {
    label: "MONTIGNY-LES-VAUCOULEURS",
    value: "55350",
    type: "common",
  },
  {
    label: "MAUVAGES",
    value: "55327",
    type: "common",
  },
  {
    label: "BROUSSEY-EN-BLOIS",
    value: "55084",
    type: "common",
  },
  {
    label: "BOVEE-SUR-BARBOURE",
    value: "55066",
    type: "common",
  },
  {
    label: "TREVERAY",
    value: "55516",
    type: "common",
  },
  {
    label: "SAINT-JOIRE",
    value: "55459",
    type: "common",
  },
  {
    label: "SAINT-AMAND-SUR-ORNAIN",
    value: "55452",
    type: "common",
  },
  {
    label: "REFFROY",
    value: "55421",
    type: "common",
  },
  {
    label: "MARSON-SUR-BARBOURE",
    value: "55322",
    type: "common",
  },
  {
    label: "VILLERS-LE-SEC",
    value: "55562",
    type: "common",
  },
  {
    label: "NANTOIS",
    value: "55376",
    type: "common",
  },
  {
    label: "NAIX-AUX-FORGES",
    value: "55370",
    type: "common",
  },
  {
    label: "LONGEAUX",
    value: "55300",
    type: "common",
  },
  {
    label: "HEVILLIERS",
    value: "55246",
    type: "common",
  },
  {
    label: "MENIL-SUR-SAULX",
    value: "55335",
    type: "common",
  },
  {
    label: "FOUCHERES-AUX-BOIS",
    value: "55195",
    type: "common",
  },
  {
    label: "DAMMARIE-SUR-SAULX",
    value: "55144",
    type: "common",
  },
  {
    label: "LE BOUCHON-SUR-SAULX",
    value: "55061",
    type: "common",
  },
  {
    label: "STAINVILLE",
    value: "55501",
    type: "common",
  },
  {
    label: "SAVONNIERES-EN-PERTHOIS",
    value: "55477",
    type: "common",
  },
  {
    label: "JUVIGNY-EN-PERTHOIS",
    value: "55261",
    type: "common",
  },
  {
    label: "AULNOIS-EN-PERTHOIS",
    value: "55015",
    type: "common",
  },
  {
    label: "CHAMOUILLEY",
    value: "52099",
    type: "common",
  },
  {
    label: "COUSANCES-LES-FORGES",
    value: "55132",
    type: "common",
  },
  {
    label: "ANCERVILLE",
    value: "55010",
    type: "common",
  },
  {
    label: "SAINT-DIZIER",
    value: "52448",
    type: "common",
  },
  {
    label: "ROCHES-SUR-MARNE",
    value: "52429",
    type: "common",
  },
  {
    label: "BETTANCOURT-LA-FERREE",
    value: "52045",
    type: "common",
  },
  {
    label: "MOESLAINS",
    value: "52327",
    type: "common",
  },
  {
    label: "HALLIGNICOURT",
    value: "52235",
    type: "common",
  },
  {
    label: "VALCOURT",
    value: "52500",
    type: "common",
  },
  {
    label: "LANEUVILLE-AU-PONT",
    value: "52267",
    type: "common",
  },
  {
    label: "SAPIGNICOURT",
    value: "51522",
    type: "common",
  },
  {
    label: "SAINTE-MARIE-DU-LAC-NUISEMENT",
    value: "51277",
    type: "common",
  },
  {
    label: "LANDRICOURT",
    value: "51315",
    type: "common",
  },
  {
    label: "HAUTEVILLE",
    value: "51286",
    type: "common",
  },
  {
    label: "AMBRIERES",
    value: "51008",
    type: "common",
  },
  {
    label: "LARZICOURT",
    value: "51316",
    type: "common",
  },
  {
    label: "ISLE-SUR-MARNE",
    value: "51300",
    type: "common",
  },
  {
    label: "ECOLLEMONT",
    value: "51223",
    type: "common",
  },
  {
    label: "ARRIGNY",
    value: "51016",
    type: "common",
  },
  {
    label: "MONCETZ-L'ABBAYE",
    value: "51373",
    type: "common",
  },
  {
    label: "CLOYES-SUR-MARNE",
    value: "51156",
    type: "common",
  },
  {
    label: "SAINT-REMY-EN-BOUZEMONT-SAINT-GENEST-ET-ISSON",
    value: "51513",
    type: "common",
  },
  {
    label: "ARZILLIERES-NEUVILLE",
    value: "51017",
    type: "common",
  },
  {
    label: "LES RIVIERES-HENRUEL",
    value: "51463",
    type: "common",
  },
  {
    label: "SAINT-CHERON",
    value: "51475",
    type: "common",
  },
  {
    label: "GIGNY-BUSSY",
    value: "51270",
    type: "common",
  },
  {
    label: "CHATELRAOULD-SAINT-LOUVENT",
    value: "51134",
    type: "common",
  },
  {
    label: "SAINT-OUEN-DOMPROT",
    value: "51508",
    type: "common",
  },
  {
    label: "LE MEIX-TIERCELIN",
    value: "51361",
    type: "common",
  },
  {
    label: "HUMBAUVILLE",
    value: "51296",
    type: "common",
  },
  {
    label: "DOSNON",
    value: "10130",
    type: "common",
  },
  {
    label: "TROUANS",
    value: "10386",
    type: "common",
  },
  {
    label: "VILLIERS-HERBISSE",
    value: "10430",
    type: "common",
  },
  {
    label: "HERBISSE",
    value: "10172",
    type: "common",
  },
  {
    label: "SALON",
    value: "10365",
    type: "common",
  },
  {
    label: "ANGLUZELLES-ET-COURCELLES",
    value: "51010",
    type: "common",
  },
  {
    label: "FAUX-FRESNAY",
    value: "51243",
    type: "common",
  },
  {
    label: "COURCEMAIN",
    value: "51182",
    type: "common",
  },
  {
    label: "SAINT-SATURNIN",
    value: "51516",
    type: "common",
  },
  {
    label: "THAAS",
    value: "51565",
    type: "common",
  },
  {
    label: "MARIGNY",
    value: "51351",
    type: "common",
  },
  {
    label: "LA CHAPELLE-LASSON",
    value: "51127",
    type: "common",
  },
  {
    label: "SAINT-QUENTIN-LE-VERGER",
    value: "51511",
    type: "common",
  },
  {
    label: "QUEUDES",
    value: "51451",
    type: "common",
  },
  {
    label: "VILLENEUVE-SAINT-VISTRE-ET-VILLEVOTTE",
    value: "51628",
    type: "common",
  },
  {
    label: "CHANTEMERLE",
    value: "51124",
    type: "common",
  },
  {
    label: "FONTAINE-DENIS-NUISY",
    value: "51254",
    type: "common",
  },
  {
    label: "BARBONNE-FAYEL",
    value: "51036",
    type: "common",
  },
  {
    label: "LES ESSARTS-LE-VICOMTE",
    value: "51236",
    type: "common",
  },
  {
    label: "LA FORESTIERE",
    value: "51258",
    type: "common",
  },
  {
    label: "NESLE-LA-REPOSTE",
    value: "51395",
    type: "common",
  },
  {
    label: "SAINT-BON",
    value: "51473",
    type: "common",
  },
  {
    label: "BOUCHY-SAINT-GENEST",
    value: "51071",
    type: "common",
  },
  {
    label: "VILLIERS-SAINT-GEORGES",
    value: "77519",
    type: "common",
  },
  {
    label: "AUGERS-EN-BRIE",
    value: "77012",
    type: "common",
  },
  {
    label: "RUPEREUX",
    value: "77396",
    type: "common",
  },
  {
    label: "LES MARETS",
    value: "77275",
    type: "common",
  },
  {
    label: "COURCHAMP",
    value: "77134",
    type: "common",
  },
  {
    label: "CHAMPCENEST",
    value: "77080",
    type: "common",
  },
  {
    label: "BOISDON",
    value: "77036",
    type: "common",
  },
  {
    label: "BEZALLES",
    value: "77033",
    type: "common",
  },
  {
    label: "BANNOST-VILLEGAGNON",
    value: "77020",
    type: "common",
  },
  {
    label: "JOUY-LE-CHATEL",
    value: "77239",
    type: "common",
  },
  {
    label: "PECY",
    value: "77357",
    type: "common",
  },
  {
    label: "GASTINS",
    value: "77201",
    type: "common",
  },
  {
    label: "LA CHAPELLE-IGER",
    value: "77087",
    type: "common",
  },
  {
    label: "COURTOMER",
    value: "77138",
    type: "common",
  },
  {
    label: "COURPALAY",
    value: "77135",
    type: "common",
  },
  {
    label: "BERNAY-VILBERT",
    value: "77031",
    type: "common",
  },
  {
    label: "VERNEUIL-L'ETANG",
    value: "77493",
    type: "common",
  },
  {
    label: "CHAUMES-EN-BRIE",
    value: "77107",
    type: "common",
  },
  {
    label: "BEAUVOIR",
    value: "77029",
    type: "common",
  },
  {
    label: "ARGENTIERES",
    value: "77007",
    type: "common",
  },
  {
    label: "YEBLES",
    value: "77534",
    type: "common",
  },
  {
    label: "SOLERS",
    value: "77457",
    type: "common",
  },
  {
    label: "OZOUER-LE-VOULGIS",
    value: "77352",
    type: "common",
  },
  {
    label: "GUIGNES",
    value: "77222",
    type: "common",
  },
  {
    label: "COURQUETAINE",
    value: "77136",
    type: "common",
  },
  {
    label: "SOIGNOLLES-EN-BRIE",
    value: "77455",
    type: "common",
  },
  {
    label: "LIMOGES-FOURCHES",
    value: "77252",
    type: "common",
  },
  {
    label: "GRISY-SUISNES",
    value: "77217",
    type: "common",
  },
  {
    label: "EVRY-GREGY-SUR-YERRE",
    value: "77175",
    type: "common",
  },
  {
    label: "COUBERT",
    value: "77127",
    type: "common",
  },
  {
    label: "MOISSY-CRAMAYEL",
    value: "77296",
    type: "common",
  },
  {
    label: "LIEUSAINT",
    value: "77251",
    type: "common",
  },
  {
    label: "COMBS-LA-VILLE",
    value: "77122",
    type: "common",
  },
  {
    label: "VARENNES-JARCY",
    value: "91631",
    type: "common",
  },
  {
    label: "TIGERY",
    value: "91617",
    type: "common",
  },
  {
    label: "SOISY-SUR-SEINE",
    value: "91600",
    type: "common",
  },
  {
    label: "QUINCY-SOUS-SENART",
    value: "91514",
    type: "common",
  },
  {
    label: "ETIOLLES",
    value: "91225",
    type: "common",
  },
  {
    label: "VIRY-CHATILLON",
    value: "91687",
    type: "common",
  },
  {
    label: "RIS-ORANGIS",
    value: "91521",
    type: "common",
  },
  {
    label: "GRIGNY",
    value: "91286",
    type: "common",
  },
  {
    label: "FLEURY-MEROGIS",
    value: "91235",
    type: "common",
  },
  {
    label: "DRAVEIL",
    value: "91201",
    type: "common",
  },
  {
    label: "VILLIERS-SUR-ORGE",
    value: "91685",
    type: "common",
  },
  {
    label: "VILLEMOISSON-SUR-ORGE",
    value: "91667",
    type: "common",
  },
  {
    label: "SAINT-MICHEL-SUR-ORGE",
    value: "91570",
    type: "common",
  },
  {
    label: "SAINTE-GENEVIEVE-DES-BOIS",
    value: "91549",
    type: "common",
  },
  {
    label: "MORSANG-SUR-ORGE",
    value: "91434",
    type: "common",
  },
  {
    label: "LONGPONT-SUR-ORGE",
    value: "91347",
    type: "common",
  },
  {
    label: "EPINAY-SUR-ORGE",
    value: "91216",
    type: "common",
  },
  {
    label: "BALLAINVILLIERS",
    value: "91044",
    type: "common",
  },
  {
    label: "LES ULIS",
    value: "91692",
    type: "common",
  },
  {
    label: "VILLEJUST",
    value: "91666",
    type: "common",
  },
  {
    label: "LA VILLE-DU-BOIS",
    value: "91665",
    type: "common",
  },
  {
    label: "NOZAY",
    value: "91458",
    type: "common",
  },
  {
    label: "MONTLHERY",
    value: "91425",
    type: "common",
  },
  {
    label: "MARCOUSSIS",
    value: "91363",
    type: "common",
  },
  {
    label: "LINAS",
    value: "91339",
    type: "common",
  },
  {
    label: "SAINT-JEAN-DE-BEAUREGARD",
    value: "91560",
    type: "common",
  },
  {
    label: "JANVRY",
    value: "91319",
    type: "common",
  },
  {
    label: "GOMETZ-LE-CHATEL",
    value: "91275",
    type: "common",
  },
  {
    label: "GOMETZ-LA-VILLE",
    value: "91274",
    type: "common",
  },
  {
    label: "BRIIS-SOUS-FORGES",
    value: "91111",
    type: "common",
  },
  {
    label: "PECQUEUSE",
    value: "91482",
    type: "common",
  },
  {
    label: "LES MOLIERES",
    value: "91411",
    type: "common",
  },
  {
    label: "LIMOURS",
    value: "91338",
    type: "common",
  },
  {
    label: "BOULLAY-LES-TROUX",
    value: "91093",
    type: "common",
  },
  {
    label: "CHOISEL",
    value: "78162",
    type: "common",
  },
  {
    label: "CERNAY-LA-VILLE",
    value: "78128",
    type: "common",
  },
  {
    label: "LA CELLE-LES-BORDES",
    value: "78125",
    type: "common",
  },
  {
    label: "VIEILLE-EGLISE-EN-YVELINES",
    value: "78655",
    type: "common",
  },
  {
    label: "RAMBOUILLET",
    value: "78517",
    type: "common",
  },
  {
    label: "SAINT-HILARION",
    value: "78557",
    type: "common",
  },
  {
    label: "RAIZEUX",
    value: "78516",
    type: "common",
  },
  {
    label: "HERMERAY",
    value: "78307",
    type: "common",
  },
  {
    label: "MITTAINVILLE",
    value: "78407",
    type: "common",
  },
  {
    label: "VILLIERS-LE-MORHIER",
    value: "28417",
    type: "common",
  },
  {
    label: "SENANTES",
    value: "28372",
    type: "common",
  },
  {
    label: "SAINT-LUCIEN",
    value: "28349",
    type: "common",
  },
  {
    label: "FAVEROLLES",
    value: "28146",
    type: "common",
  },
  {
    label: "ORMOY",
    value: "28289",
    type: "common",
  },
  {
    label: "NOGENT-LE-ROI",
    value: "28279",
    type: "common",
  },
  {
    label: "LORMAYE",
    value: "28213",
    type: "common",
  },
  {
    label: "COULOMBS",
    value: "28113",
    type: "common",
  },
  {
    label: "CHAUDON",
    value: "28094",
    type: "common",
  },
  {
    label: "BRECHAMPS",
    value: "28058",
    type: "common",
  },
  {
    label: "VILLEMEUX-SUR-EURE",
    value: "28415",
    type: "common",
  },
  {
    label: "MARVILLE-MOUTIERS-BRULE",
    value: "28239",
    type: "common",
  },
  {
    label: "LE BOULLAY-THIERRY",
    value: "28055",
    type: "common",
  },
  {
    label: "LE BOULLAY-MIVOYE",
    value: "28054",
    type: "common",
  },
  {
    label: "TREON",
    value: "28394",
    type: "common",
  },
  {
    label: "PUISEUX",
    value: "28312",
    type: "common",
  },
  {
    label: "LE BOULLAY-LES-DEUX-EGLISES",
    value: "28053",
    type: "common",
  },
  {
    label: "AUNAY-SOUS-CRECY",
    value: "28014",
    type: "common",
  },
  {
    label: "SAULNIERES",
    value: "28369",
    type: "common",
  },
  {
    label: "SAINT-JEAN-DE-REBERVILLIERS",
    value: "28341",
    type: "common",
  },
  {
    label: "SAINT-ANGE-ET-TORCAY",
    value: "28323",
    type: "common",
  },
  {
    label: "FONTAINE-LES-RIBOUTS",
    value: "28155",
    type: "common",
  },
  {
    label: "MAILLEBOIS",
    value: "28226",
    type: "common",
  },
  {
    label: "CRUCEY-VILLAGES",
    value: "28120",
    type: "common",
  },
  {
    label: "LA SAUCELLE",
    value: "28368",
    type: "common",
  },
  {
    label: "LA MANCELIERE",
    value: "28231",
    type: "common",
  },
  {
    label: "LES CHATELETS",
    value: "28090",
    type: "common",
  },
  {
    label: "MORVILLIERS",
    value: "28271",
    type: "common",
  },
  {
    label: "LAMBLORE",
    value: "28202",
    type: "common",
  },
  {
    label: "LA CHAPELLE-FORTIN",
    value: "28077",
    type: "common",
  },
  {
    label: "ROHAIRE",
    value: "28316",
    type: "common",
  },
  {
    label: "CHARENCEY",
    value: "61429",
    type: "common",
  },
  {
    label: "BEAULIEU",
    value: "61034",
    type: "common",
  },
  {
    label: "SOLIGNY-LA-TRAPPE",
    value: "61475",
    type: "common",
  },
  {
    label: "SAINT-AQUILIN-DE-CORBION",
    value: "61363",
    type: "common",
  },
  {
    label: "LES GENETTES",
    value: "61187",
    type: "common",
  },
  {
    label: "BONSMOULINS",
    value: "61053",
    type: "common",
  },
  {
    label: "SAINT-MARTIN-DES-PEZERITS",
    value: "61425",
    type: "common",
  },
  {
    label: "SAINT-AUBIN-DE-COURTERAIE",
    value: "61367",
    type: "common",
  },
  {
    label: "SAINT-AGNAN-SUR-SARTHE",
    value: "61360",
    type: "common",
  },
  {
    label: "MOULINS-LA-MARCHE",
    value: "61297",
    type: "common",
  },
  {
    label: "MAHERU",
    value: "61244",
    type: "common",
  },
  {
    label: "TELLIERES-LE-PLESSIS",
    value: "61481",
    type: "common",
  },
  {
    label: "FERRIERES-LA-VERRERIE",
    value: "61166",
    type: "common",
  },
  {
    label: "FAY",
    value: "61159",
    type: "common",
  },
  {
    label: "COURTOMER",
    value: "61133",
    type: "common",
  },
  {
    label: "BRULLEMAIL",
    value: "61064",
    type: "common",
  },
  {
    label: "SAINT-LEONARD-DES-PARCS",
    value: "61416",
    type: "common",
  },
  {
    label: "SAINT-GERMAIN-LE-VIEUX",
    value: "61398",
    type: "common",
  },
  {
    label: "GAPREE",
    value: "61183",
    type: "common",
  },
  {
    label: "AUNOU-SUR-ORNE",
    value: "61015",
    type: "common",
  },
  {
    label: "SEES",
    value: "61464",
    type: "common",
  },
  {
    label: "MACE",
    value: "61240",
    type: "common",
  },
  {
    label: "CHAILLOUE",
    value: "61081",
    type: "common",
  },
  {
    label: "BELFONDS",
    value: "61036",
    type: "common",
  },
  {
    label: "BOISCHAMPRE",
    value: "61375",
    type: "common",
  },
  {
    label: "MONTMERREI",
    value: "61288",
    type: "common",
  },
  {
    label: "LA BELLIERE",
    value: "61039",
    type: "common",
  },
  {
    label: "LE MENIL-SCELLEUR",
    value: "61271",
    type: "common",
  },
  {
    label: "FRANCHEVILLE",
    value: "61176",
    type: "common",
  },
  {
    label: "BOUCE",
    value: "61055",
    type: "common",
  },
  {
    label: "VIEUX-PONT",
    value: "61503",
    type: "common",
  },
  {
    label: "SAINT-MARTIN-L'AIGUILLON",
    value: "61427",
    type: "common",
  },
  {
    label: "SAINTE-MARIE-LA-ROBERT",
    value: "61420",
    type: "common",
  },
  {
    label: "RANES",
    value: "61344",
    type: "common",
  },
  {
    label: "LE CHAMP-DE-LA-PIERRE",
    value: "61085",
    type: "common",
  },
  {
    label: "SAINT-GEORGES-D'ANNEBECQ",
    value: "61390",
    type: "common",
  },
  {
    label: "LA CHAUX",
    value: "61104",
    type: "common",
  },
  {
    label: "LE GRAIS",
    value: "61195",
    type: "common",
  },
  {
    label: "BEAUVAIN",
    value: "61035",
    type: "common",
  },
  {
    label: "LES MONTS D'ANDAINE",
    value: "61463",
    type: "common",
  },
  {
    label: "LA COULONCHE",
    value: "61124",
    type: "common",
  },
  {
    label: "DOMPIERRE",
    value: "61146",
    type: "common",
  },
  {
    label: "CHAMPSECRET",
    value: "61091",
    type: "common",
  },
  {
    label: "SAINT-GILLES-DES-MARAIS",
    value: "61401",
    type: "common",
  },
  {
    label: "SAINT-GEORGES-DE-ROUELLEY",
    value: "50474",
    type: "common",
  },
  {
    label: "BARENTON",
    value: "50029",
    type: "common",
  },
  {
    label: "ROMAGNY FONTENAY",
    value: "50436",
    type: "common",
  },
  {
    label: "GRANDPARIGNY",
    value: "50391",
    type: "common",
  },
  {
    label: "LE MESNILLARD",
    value: "50315",
    type: "common",
  },
  {
    label: "ISIGNY-LE-BUAT",
    value: "50256",
    type: "common",
  },
  {
    label: "SAINT-LAURENT-DE-TERREGATTE",
    value: "50500",
    type: "common",
  },
  {
    label: "DUCEY-LES CHERIS",
    value: "50168",
    type: "common",
  },
  {
    label: "SAINT-AUBIN-DE-TERREGATTE",
    value: "50448",
    type: "common",
  },
  {
    label: "PONTAUBAULT",
    value: "50408",
    type: "common",
  },
  {
    label: "POILLEY",
    value: "50407",
    type: "common",
  },
  {
    label: "JUILLEY",
    value: "50259",
    type: "common",
  },
  {
    label: "TANIS",
    value: "50589",
    type: "common",
  },
  {
    label: "SERVON",
    value: "50574",
    type: "common",
  },
  {
    label: "PRECEY",
    value: "50413",
    type: "common",
  },
  {
    label: "HUISNES-SUR-MER",
    value: "50253",
    type: "common",
  },
  {
    label: "CROLLON",
    value: "50155",
    type: "common",
  },
  {
    label: "PONTORSON",
    value: "50410",
    type: "common",
  },
  {
    label: "BEAUVOIR",
    value: "50042",
    type: "common",
  },
  {
    label: "SAINT-MARCAN",
    value: "35291",
    type: "common",
  },
  {
    label: "SAINT-GEORGES-DE-GREHAIGNE",
    value: "35270",
    type: "common",
  },
  {
    label: "ROZ-SUR-COUESNON",
    value: "35247",
    type: "common",
  },
  {
    label: "SAINT-BROLADRE",
    value: "35259",
    type: "common",
  },
  {
    label: "CHERRUEIX",
    value: "35078",
    type: "common",
  },
  {
    label: "LE VIVIER-SUR-MER",
    value: "35361",
    type: "common",
  },
  {
    label: "MONT-DOL",
    value: "35186",
    type: "common",
  },
  {
    label: "HIREL",
    value: "35132",
    type: "common",
  },
  {
    label: "SAINT-GUINOUX",
    value: "35279",
    type: "common",
  },
  {
    label: "LILLEMER",
    value: "35153",
    type: "common",
  },
  {
    label: "LA GOUESNIERE",
    value: "35122",
    type: "common",
  },
  {
    label: "LA FRESNAIS",
    value: "35116",
    type: "common",
  },
  {
    label: "SAINT-SULIAC",
    value: "35314",
    type: "common",
  },
  {
    label: "SAINT-PERE-MARC-EN-POULET",
    value: "35306",
    type: "common",
  },
  {
    label: "SAINT-JOUAN-DES-GUERETS",
    value: "35284",
    type: "common",
  },
  {
    label: "CHATEAUNEUF-D'ILLE-ET-VILAINE",
    value: "35070",
    type: "common",
  },
  {
    label: "TREMEREUC",
    value: "22368",
    type: "common",
  },
  {
    label: "LANGROLAY-SUR-RANCE",
    value: "22103",
    type: "common",
  },
  {
    label: "PLEURTUIT",
    value: "35228",
    type: "common",
  },
  {
    label: "LE MINIHIC-SUR-RANCE",
    value: "35181",
    type: "common",
  },
  {
    label: "BEAUSSAIS-SUR-MER",
    value: "22209",
    type: "common",
  },
  {
    label: "SAINT-LORMEL",
    value: "22311",
    type: "common",
  },
  {
    label: "SAINT-POTAN",
    value: "22323",
    type: "common",
  },
  {
    label: "RUCA",
    value: "22268",
    type: "common",
  },
  {
    label: "HENANBIHEN",
    value: "22076",
    type: "common",
  },
  {
    label: "HENANSAL",
    value: "22077",
    type: "common",
  },
  {
    label: "LA BOUILLIE",
    value: "22012",
    type: "common",
  },
  {
    label: "SAINT-ALBAN",
    value: "22273",
    type: "common",
  },
  {
    label: "PLERIN",
    value: "22187",
    type: "common",
  },
  {
    label: "TREMUSON",
    value: "22372",
    type: "common",
  },
  {
    label: "TREGOMEUR",
    value: "22356",
    type: "common",
  },
  {
    label: "PLELO",
    value: "22182",
    type: "common",
  },
  {
    label: "SAINT-JEAN-KERDANIEL",
    value: "22304",
    type: "common",
  },
  {
    label: "SAINT-AGATHON",
    value: "22272",
    type: "common",
  },
  {
    label: "PLOUMAGOAR",
    value: "22225",
    type: "common",
  },
  {
    label: "MOUSTERU",
    value: "22156",
    type: "common",
  },
  {
    label: "GRACES",
    value: "22067",
    type: "common",
  },
  {
    label: "COADOUT",
    value: "22040",
    type: "common",
  },
  {
    label: "GURUNHUEL",
    value: "22072",
    type: "common",
  },
  {
    label: "BELLE-ISLE-EN-TERRE",
    value: "22005",
    type: "common",
  },
  {
    label: "LOGUIVY-PLOUGRAS",
    value: "22131",
    type: "common",
  },
  {
    label: "LOC-ENVEL",
    value: "22129",
    type: "common",
  },
  {
    label: "PLOUGRAS",
    value: "22217",
    type: "common",
  },
  {
    label: "GUERLESQUIN",
    value: "29067",
    type: "common",
  },
  {
    label: "LANNEANOU",
    value: "29114",
    type: "common",
  },
  {
    label: "BOTSORHEL",
    value: "29014",
    type: "common",
  },
  {
    label: "PLOUGONVEN",
    value: "29191",
    type: "common",
  },
  {
    label: "PLEYBER-CHRIST",
    value: "29163",
    type: "common",
  },
  {
    label: "SAINT-THEGONNEC LOC-EGUINER",
    value: "29266",
    type: "common",
  },
  {
    label: "GUIMILIAU",
    value: "29074",
    type: "common",
  },
  {
    label: "LOC-EGUINER",
    value: "29128",
    type: "common",
  },
  {
    label: "LAMPAUL-GUIMILIAU",
    value: "29097",
    type: "common",
  },
  {
    label: "LA ROCHE-MAURICE",
    value: "29237",
    type: "common",
  },
  {
    label: "PLOUDIRY",
    value: "29180",
    type: "common",
  },
  {
    label: "LANNEUFFRET",
    value: "29116",
    type: "common",
  },
  {
    label: "PLOUEDERN",
    value: "29181",
    type: "common",
  },
  {
    label: "PENCRAN",
    value: "29156",
    type: "common",
  },
  {
    label: "LANDERNEAU",
    value: "29103",
    type: "common",
  },
  {
    label: "SAINT-THONAN",
    value: "29268",
    type: "common",
  },
  {
    label: "SAINT-DIVY",
    value: "29245",
    type: "common",
  },
  {
    label: "KERSAINT-PLABENNEC",
    value: "29095",
    type: "common",
  },
  {
    label: "GOUESNOU",
    value: "29061",
    type: "common",
  },
  {
    label: "PLABENNEC",
    value: "29160",
    type: "common",
  },
  {
    label: "GUIPAVAS",
    value: "29075",
    type: "common",
  },
  {
    label: "BOHARS",
    value: "29011",
    type: "common",
  },
  {
    label: "LANRIVOARE",
    value: "29119",
    type: "common",
  },
  {
    label: "PLOUARZEL",
    value: "29177",
    type: "common",
  },
  {
    label: "BRELES",
    value: "29017",
    type: "common",
  },
  {
    label: "LAMPAUL-PLOUARZEL",
    value: "29098",
    type: "common",
  },
  {
    label: "ILE-MOLENE",
    value: "29084",
    type: "common",
  },
  {
    label: "LE CONQUET",
    value: "29040",
    type: "common",
  },
  {
    label: "PLOBSHEIM",
    value: "67378",
    type: "common",
  },
  {
    label: "NORDHOUSE",
    value: "67336",
    type: "common",
  },
  {
    label: "ICHTRATZHEIM",
    value: "67217",
    type: "common",
  },
  {
    label: "ESCHAU",
    value: "67131",
    type: "common",
  },
  {
    label: "LIPSHEIM",
    value: "67268",
    type: "common",
  },
  {
    label: "LIMERSHEIM",
    value: "67266",
    type: "common",
  },
  {
    label: "HIPSHEIM",
    value: "67200",
    type: "common",
  },
  {
    label: "HINDISHEIM",
    value: "67197",
    type: "common",
  },
  {
    label: "FEGERSHEIM",
    value: "67137",
    type: "common",
  },
  {
    label: "BLAESHEIM",
    value: "67049",
    type: "common",
  },
  {
    label: "MEISTRATZHEIM",
    value: "67286",
    type: "common",
  },
  {
    label: "KRAUTERGERSHEIM",
    value: "67248",
    type: "common",
  },
  {
    label: "INNENHEIM",
    value: "67223",
    type: "common",
  },
  {
    label: "GRIESHEIM-PRES-MOLSHEIM",
    value: "67172",
    type: "common",
  },
  {
    label: "BISCHOFFSHEIM",
    value: "67045",
    type: "common",
  },
  {
    label: "ROSHEIM",
    value: "67411",
    type: "common",
  },
  {
    label: "ROSENWILLER",
    value: "67410",
    type: "common",
  },
  {
    label: "OBERNAI",
    value: "67348",
    type: "common",
  },
  {
    label: "MOLLKIRCH",
    value: "67299",
    type: "common",
  },
  {
    label: "BŒRSCH",
    value: "67052",
    type: "common",
  },
  {
    label: "RUSS",
    value: "67420",
    type: "common",
  },
  {
    label: "MUHLBACH-SUR-BRUCHE",
    value: "67306",
    type: "common",
  },
  {
    label: "GRENDELBRUCH",
    value: "67167",
    type: "common",
  },
  {
    label: "WISCHES",
    value: "67543",
    type: "common",
  },
  {
    label: "SCHIRMECK",
    value: "67448",
    type: "common",
  },
  {
    label: "LUVIGNY",
    value: "88277",
    type: "common",
  },
  {
    label: "VEXAINCOURT",
    value: "88503",
    type: "common",
  },
  {
    label: "RAON-SUR-PLAINE",
    value: "88373",
    type: "common",
  },
  {
    label: "GRANDFONTAINE",
    value: "67165",
    type: "common",
  },
  {
    label: "RAON-LES-LEAU",
    value: "54443",
    type: "common",
  },
  {
    label: "SAINT-SAUVEUR",
    value: "54488",
    type: "common",
  },
  {
    label: "BIONVILLE",
    value: "54075",
    type: "common",
  },
  {
    label: "ANGOMONT",
    value: "54017",
    type: "common",
  },
  {
    label: "MONTREUX",
    value: "54381",
    type: "common",
  },
  {
    label: "FENNEVILLER",
    value: "54191",
    type: "common",
  },
  {
    label: "BREMENIL",
    value: "54097",
    type: "common",
  },
  {
    label: "BADONVILLER",
    value: "54040",
    type: "common",
  },
  {
    label: "SAINTE-POLE",
    value: "54484",
    type: "common",
  },
  {
    label: "SAINT-MAURICE-AUX-FORGES",
    value: "54481",
    type: "common",
  },
  {
    label: "NEUVILLER-LES-BADONVILLER",
    value: "54398",
    type: "common",
  },
  {
    label: "MONTIGNY",
    value: "54377",
    type: "common",
  },
  {
    label: "ANCERVILLER",
    value: "54014",
    type: "common",
  },
  {
    label: "VAXAINVILLE",
    value: "54555",
    type: "common",
  },
  {
    label: "REHERREY",
    value: "54450",
    type: "common",
  },
  {
    label: "RECLONVILLE",
    value: "54447",
    type: "common",
  },
  {
    label: "PETTONVILLE",
    value: "54422",
    type: "common",
  },
  {
    label: "MIGNEVILLE",
    value: "54368",
    type: "common",
  },
  {
    label: "HABLAINVILLE",
    value: "54243",
    type: "common",
  },
  {
    label: "BROUVILLE",
    value: "54101",
    type: "common",
  },
  {
    label: "SAINT-CLEMENT",
    value: "54472",
    type: "common",
  },
  {
    label: "FLIN",
    value: "54199",
    type: "common",
  },
  {
    label: "CHENEVIERES",
    value: "54125",
    type: "common",
  },
  {
    label: "BURIVILLE",
    value: "54107",
    type: "common",
  },
  {
    label: "AZERAILLES",
    value: "54038",
    type: "common",
  },
  {
    label: "VATHIMENIL",
    value: "54550",
    type: "common",
  },
  {
    label: "LARONXE",
    value: "54303",
    type: "common",
  },
  {
    label: "FRAIMBOIS",
    value: "54206",
    type: "common",
  },
  {
    label: "XERMAMENIL",
    value: "54595",
    type: "common",
  },
  {
    label: "HAUDONVILLE",
    value: "54255",
    type: "common",
  },
  {
    label: "GERBEVILLER",
    value: "54222",
    type: "common",
  },
  {
    label: "FRANCONVILLE",
    value: "54209",
    type: "common",
  },
  {
    label: "ROMAIN",
    value: "54461",
    type: "common",
  },
  {
    label: "MONT-SUR-MEURTHE",
    value: "54383",
    type: "common",
  },
  {
    label: "MEHONCOURT",
    value: "54359",
    type: "common",
  },
  {
    label: "LANDECOURT",
    value: "54293",
    type: "common",
  },
  {
    label: "LAMATH",
    value: "54292",
    type: "common",
  },
  {
    label: "CHARMOIS",
    value: "54121",
    type: "common",
  },
  {
    label: "BLAINVILLE-SUR-L'EAU",
    value: "54076",
    type: "common",
  },
  {
    label: "VELLE-SUR-MOSELLE",
    value: "54559",
    type: "common",
  },
  {
    label: "SAINT-MARD",
    value: "54479",
    type: "common",
  },
  {
    label: "SAFFAIS",
    value: "54468",
    type: "common",
  },
  {
    label: "HAUSSONVILLE",
    value: "54256",
    type: "common",
  },
  {
    label: "FERRIERES",
    value: "54192",
    type: "common",
  },
  {
    label: "DOMPTAIL-EN-L'AIR",
    value: "54170",
    type: "common",
  },
  {
    label: "BARBONVILLE",
    value: "54045",
    type: "common",
  },
  {
    label: "TONNOY",
    value: "54527",
    type: "common",
  },
  {
    label: "CREVECHAMPS",
    value: "54144",
    type: "common",
  },
  {
    label: "BENNEY",
    value: "54062",
    type: "common",
  },
  {
    label: "VOINEMONT",
    value: "54591",
    type: "common",
  },
  {
    label: "PULLIGNY",
    value: "54437",
    type: "common",
  },
  {
    label: "PIERREVILLE",
    value: "54429",
    type: "common",
  },
  {
    label: "HOUDREVILLE",
    value: "54266",
    type: "common",
  },
  {
    label: "HOUDELMONT",
    value: "54264",
    type: "common",
  },
  {
    label: "CLEREY-SUR-BRENON",
    value: "54132",
    type: "common",
  },
  {
    label: "CEINTREY",
    value: "54109",
    type: "common",
  },
  {
    label: "AUTREY",
    value: "54032",
    type: "common",
  },
  {
    label: "XEUILLEY",
    value: "54596",
    type: "common",
  },
  {
    label: "THELOD",
    value: "54515",
    type: "common",
  },
  {
    label: "PAREY-SAINT-CESAIRE",
    value: "54417",
    type: "common",
  },
  {
    label: "MARTHEMONT",
    value: "54354",
    type: "common",
  },
  {
    label: "GERMINY",
    value: "54223",
    type: "common",
  },
  {
    label: "CREPEY",
    value: "54143",
    type: "common",
  },
  {
    label: "ALLAIN",
    value: "54008",
    type: "common",
  },
  {
    label: "COLOMBEY-LES-BELLES",
    value: "54135",
    type: "common",
  },
  {
    label: "BARISEY-LA-COTE",
    value: "54047",
    type: "common",
  },
  {
    label: "BARISEY-AU-PLAIN",
    value: "54046",
    type: "common",
  },
  {
    label: "BAGNEUX",
    value: "54041",
    type: "common",
  },
  {
    label: "PAGNY-LA-BLANCHE-COTE",
    value: "55397",
    type: "common",
  },
  {
    label: "URUFFE",
    value: "54538",
    type: "common",
  },
  {
    label: "SAULXURES-LES-VANNES",
    value: "54496",
    type: "common",
  },
  {
    label: "ALLAMPS",
    value: "54010",
    type: "common",
  },
  {
    label: "SEPVIGNY",
    value: "55485",
    type: "common",
  },
  {
    label: "NEUVILLE-LES-VAUCOULEURS",
    value: "55381",
    type: "common",
  },
  {
    label: "MAXEY-SUR-VAISE",
    value: "55328",
    type: "common",
  },
  {
    label: "CHAMPOUGNY",
    value: "55100",
    type: "common",
  },
  {
    label: "BUREY-EN-VAUX",
    value: "55088",
    type: "common",
  },
  {
    label: "EPIEZ-SUR-MEUSE",
    value: "55173",
    type: "common",
  },
  {
    label: "BADONVILLIERS-GERAUVILLIERS",
    value: "55026",
    type: "common",
  },
  {
    label: "DELOUZE-ROSIERES",
    value: "55148",
    type: "common",
  },
  {
    label: "ABAINVILLE",
    value: "55001",
    type: "common",
  },
  {
    label: "HOUDELAINCOURT",
    value: "55248",
    type: "common",
  },
  {
    label: "BONNET",
    value: "55059",
    type: "common",
  },
  {
    label: "RIBEAUCOURT",
    value: "55430",
    type: "common",
  },
  {
    label: "COUVERTPUIS",
    value: "55133",
    type: "common",
  },
  {
    label: "BIENCOURT-SUR-ORGE",
    value: "55051",
    type: "common",
  },
  {
    label: "MORLEY",
    value: "55359",
    type: "common",
  },
  {
    label: "MONTIERS-SUR-SAULX",
    value: "55348",
    type: "common",
  },
  {
    label: "FONTAINES-SUR-MARNE",
    value: "52203",
    type: "common",
  },
  {
    label: "BRAUVILLIERS",
    value: "55075",
    type: "common",
  },
  {
    label: "NARCY",
    value: "52347",
    type: "common",
  },
  {
    label: "BAYARD-SUR-MARNE",
    value: "52265",
    type: "common",
  },
  {
    label: "EURVILLE-BIENVILLE",
    value: "52194",
    type: "common",
  },
  {
    label: "ATTANCOURT",
    value: "52021",
    type: "common",
  },
  {
    label: "TROISFONTAINES-LA-VILLE",
    value: "52497",
    type: "common",
  },
  {
    label: "LOUVEMONT",
    value: "52294",
    type: "common",
  },
  {
    label: "ALLICHAMPS",
    value: "52006",
    type: "common",
  },
  {
    label: "HUMBECOURT",
    value: "52244",
    type: "common",
  },
  {
    label: "ECLARON-BRAUCOURT-SAINTE-LIVIERE",
    value: "52182",
    type: "common",
  },
  {
    label: "CHATILLON-SUR-BROUE",
    value: "51135",
    type: "common",
  },
  {
    label: "OUTINES",
    value: "51419",
    type: "common",
  },
  {
    label: "GIFFAUMONT-CHAMPAUBERT",
    value: "51269",
    type: "common",
  },
  {
    label: "DROSNAY",
    value: "51219",
    type: "common",
  },
  {
    label: "BRANDONVILLERS",
    value: "51080",
    type: "common",
  },
  {
    label: "MARGERIE-HANCOURT",
    value: "51349",
    type: "common",
  },
  {
    label: "LIGNON",
    value: "51322",
    type: "common",
  },
  {
    label: "SAINT-UTIN",
    value: "51520",
    type: "common",
  },
  {
    label: "CHAPELAINE",
    value: "51125",
    type: "common",
  },
  {
    label: "SOMSOIS",
    value: "51551",
    type: "common",
  },
  {
    label: "CORBEIL",
    value: "51169",
    type: "common",
  },
  {
    label: "BREBAN",
    value: "51084",
    type: "common",
  },
  {
    label: "DAMPIERRE",
    value: "10121",
    type: "common",
  },
  {
    label: "LHUITRE",
    value: "10195",
    type: "common",
  },
  {
    label: "GRANDVILLE",
    value: "10167",
    type: "common",
  },
  {
    label: "LE CHENE",
    value: "10095",
    type: "common",
  },
  {
    label: "CHAMPIGNY-SUR-AUBE",
    value: "10077",
    type: "common",
  },
  {
    label: "ALLIBAUDIERES",
    value: "10004",
    type: "common",
  },
  {
    label: "ORMES",
    value: "10272",
    type: "common",
  },
  {
    label: "VIAPRES-LE-PETIT",
    value: "10408",
    type: "common",
  },
  {
    label: "PLANCY-L'ABBAYE",
    value: "10289",
    type: "common",
  },
  {
    label: "CHAMPFLEURY",
    value: "10075",
    type: "common",
  },
  {
    label: "VOUARCES",
    value: "51652",
    type: "common",
  },
  {
    label: "BOULAGES",
    value: "10052",
    type: "common",
  },
  {
    label: "ETRELLES-SUR-AUBE",
    value: "10144",
    type: "common",
  },
  {
    label: "MARSANGIS",
    value: "51353",
    type: "common",
  },
  {
    label: "GRANGES-SUR-AUBE",
    value: "51279",
    type: "common",
  },
  {
    label: "BAGNEUX",
    value: "51032",
    type: "common",
  },
  {
    label: "ANGLURE",
    value: "51009",
    type: "common",
  },
  {
    label: "ALLEMANCHE-LAUNAY-ET-SOYER",
    value: "51004",
    type: "common",
  },
  {
    label: "SARON-SUR-AUBE",
    value: "51524",
    type: "common",
  },
  {
    label: "MARCILLY-SUR-SEINE",
    value: "51343",
    type: "common",
  },
  {
    label: "BAUDEMENT",
    value: "51041",
    type: "common",
  },
  {
    label: "POTANGIS",
    value: "51443",
    type: "common",
  },
  {
    label: "BETHON",
    value: "51056",
    type: "common",
  },
  {
    label: "VILLIERS-AUX-CORNEILLES",
    value: "51642",
    type: "common",
  },
  {
    label: "LA CELLE-SOUS-CHANTEMERLE",
    value: "51103",
    type: "common",
  },
  {
    label: "MONTGENOST",
    value: "51376",
    type: "common",
  },
  {
    label: "PLESSIS-BARBUISE",
    value: "10291",
    type: "common",
  },
  {
    label: "VILLENAUXE-LA-GRANDE",
    value: "10420",
    type: "common",
  },
  {
    label: "MONTPOTHIER",
    value: "10254",
    type: "common",
  },
  {
    label: "LOUAN-VILLEGRUIS-FONTAINE",
    value: "77262",
    type: "common",
  },
  {
    label: "VOULTON",
    value: "77530",
    type: "common",
  },
  {
    label: "LECHELLE",
    value: "77246",
    type: "common",
  },
  {
    label: "BEAUCHERY-SAINT-MARTIN",
    value: "77026",
    type: "common",
  },
  {
    label: "SAINT-HILLIERS",
    value: "77414",
    type: "common",
  },
  {
    label: "SAINT-BRICE",
    value: "77403",
    type: "common",
  },
  {
    label: "ROUILLY",
    value: "77391",
    type: "common",
  },
  {
    label: "PROVINS",
    value: "77379",
    type: "common",
  },
  {
    label: "VULAINES-LES-PROVINS",
    value: "77532",
    type: "common",
  },
  {
    label: "MORTERY",
    value: "77319",
    type: "common",
  },
  {
    label: "VIEUX-CHAMPAGNE",
    value: "77496",
    type: "common",
  },
  {
    label: "SAINT-JUST-EN-BRIE",
    value: "77416",
    type: "common",
  },
  {
    label: "CHATEAUBLEAU",
    value: "77098",
    type: "common",
  },
  {
    label: "LA CROIX-EN-BRIE",
    value: "77147",
    type: "common",
  },
  {
    label: "CLOS-FONTAINE",
    value: "77119",
    type: "common",
  },
  {
    label: "QUIERS",
    value: "77381",
    type: "common",
  },
  {
    label: "GRANDPUITS-BAILLY-CARROIS",
    value: "77211",
    type: "common",
  },
  {
    label: "AUBEPIERRE-OZOUER-LE-REPOS",
    value: "77010",
    type: "common",
  },
  {
    label: "SAINT-MERY",
    value: "77426",
    type: "common",
  },
  {
    label: "MORMANT",
    value: "77317",
    type: "common",
  },
  {
    label: "BOMBON",
    value: "77044",
    type: "common",
  },
  {
    label: "ANDREZEL",
    value: "77004",
    type: "common",
  },
  {
    label: "FOUJU",
    value: "77195",
    type: "common",
  },
  {
    label: "CRISENOY",
    value: "77145",
    type: "common",
  },
  {
    label: "CHAMPEAUX",
    value: "77082",
    type: "common",
  },
  {
    label: "CHAMPDEUIL",
    value: "77081",
    type: "common",
  },
  {
    label: "VERT-SAINT-DENIS",
    value: "77495",
    type: "common",
  },
  {
    label: "VOISENON",
    value: "77528",
    type: "common",
  },
  {
    label: "SAINT-GERMAIN-LAXIS",
    value: "77410",
    type: "common",
  },
  {
    label: "RUBELLES",
    value: "77394",
    type: "common",
  },
  {
    label: "MONTEREAU-SUR-LE-JARD",
    value: "77306",
    type: "common",
  },
  {
    label: "LISSY",
    value: "77253",
    type: "common",
  },
  {
    label: "REAU",
    value: "77384",
    type: "common",
  },
  {
    label: "SAVIGNY-LE-TEMPLE",
    value: "77445",
    type: "common",
  },
  {
    label: "NANDY",
    value: "77326",
    type: "common",
  },
  {
    label: "VILLABE",
    value: "91659",
    type: "common",
  },
  {
    label: "SAINTRY-SUR-SEINE",
    value: "91577",
    type: "common",
  },
  {
    label: "SAINT-PIERRE-DU-PERRAY",
    value: "91573",
    type: "common",
  },
  {
    label: "SAINT-GERMAIN-LES-CORBEIL",
    value: "91553",
    type: "common",
  },
  {
    label: "ORMOY",
    value: "91468",
    type: "common",
  },
  {
    label: "MORSANG-SUR-SEINE",
    value: "91435",
    type: "common",
  },
  {
    label: "CORBEIL-ESSONNES",
    value: "91174",
    type: "common",
  },
  {
    label: "VERT-LE-GRAND",
    value: "91648",
    type: "common",
  },
  {
    label: "LISSES",
    value: "91340",
    type: "common",
  },
  {
    label: "ECHARCON",
    value: "91204",
    type: "common",
  },
  {
    label: "BONDOUFLE",
    value: "91086",
    type: "common",
  },
  {
    label: "LE PLESSIS-PATE",
    value: "91494",
    type: "common",
  },
  {
    label: "BRETIGNY-SUR-ORGE",
    value: "91103",
    type: "common",
  },
  {
    label: "SAINT-GERMAIN-LES-ARPAJON",
    value: "91552",
    type: "common",
  },
  {
    label: "OLLAINVILLE",
    value: "91461",
    type: "common",
  },
  {
    label: "LA NORVILLE",
    value: "91457",
    type: "common",
  },
  {
    label: "LEUVILLE-SUR-ORGE",
    value: "91333",
    type: "common",
  },
  {
    label: "GUIBEVILLE",
    value: "91292",
    type: "common",
  },
  {
    label: "EGLY",
    value: "91207",
    type: "common",
  },
  {
    label: "BRUYERES-LE-CHATEL",
    value: "91115",
    type: "common",
  },
  {
    label: "ARPAJON",
    value: "91021",
    type: "common",
  },
  {
    label: "VAUGRIGNEUSE",
    value: "91634",
    type: "common",
  },
  {
    label: "SAINT-MAURICE-MONTCOURONNE",
    value: "91568",
    type: "common",
  },
  {
    label: "FONTENAY-LES-BRIIS",
    value: "91243",
    type: "common",
  },
  {
    label: "COURSON-MONTELOUP",
    value: "91186",
    type: "common",
  },
  {
    label: "BREUILLET",
    value: "91105",
    type: "common",
  },
  {
    label: "SAINT-CYR-SOUS-DOURDAN",
    value: "91546",
    type: "common",
  },
  {
    label: "FORGES-LES-BAINS",
    value: "91249",
    type: "common",
  },
  {
    label: "ANGERVILLIERS",
    value: "91017",
    type: "common",
  },
  {
    label: "BONNELLES",
    value: "78087",
    type: "common",
  },
  {
    label: "ROCHEFORT-EN-YVELINES",
    value: "78522",
    type: "common",
  },
  {
    label: "LONGVILLIERS",
    value: "78349",
    type: "common",
  },
  {
    label: "BULLION",
    value: "78120",
    type: "common",
  },
  {
    label: "SONCHAMP",
    value: "78601",
    type: "common",
  },
  {
    label: "CLAIREFONTAINE-EN-YVELINES",
    value: "78164",
    type: "common",
  },
  {
    label: "ORPHIN",
    value: "78470",
    type: "common",
  },
  {
    label: "ORCEMONT",
    value: "78464",
    type: "common",
  },
  {
    label: "GAZERAN",
    value: "78269",
    type: "common",
  },
  {
    label: "EMANCE",
    value: "78209",
    type: "common",
  },
  {
    label: "GAS",
    value: "28172",
    type: "common",
  },
  {
    label: "EPERNON",
    value: "28140",
    type: "common",
  },
  {
    label: "DROUE-SUR-DROUETTE",
    value: "28135",
    type: "common",
  },
  {
    label: "SAINT-MARTIN-DE-NIGELLES",
    value: "28352",
    type: "common",
  },
  {
    label: "MAINTENON",
    value: "28227",
    type: "common",
  },
  {
    label: "HOUX",
    value: "28195",
    type: "common",
  },
  {
    label: "HANCHES",
    value: "28191",
    type: "common",
  },
  {
    label: "PIERRES",
    value: "28298",
    type: "common",
  },
  {
    label: "NERON",
    value: "28275",
    type: "common",
  },
  {
    label: "SERAZEREUX",
    value: "28374",
    type: "common",
  },
  {
    label: "TREMBLAY-LES-VILLAGES",
    value: "28393",
    type: "common",
  },
  {
    label: "SAINT-SAUVEUR-MARVILLE",
    value: "28360",
    type: "common",
  },
  {
    label: "CHATEAUNEUF-EN-THYMERAIS",
    value: "28089",
    type: "common",
  },
  {
    label: "SAINT-MAIXME-HAUTERIVE",
    value: "28351",
    type: "common",
  },
  {
    label: "JAUDRAIS",
    value: "28200",
    type: "common",
  },
  {
    label: "LE MESNIL-THOMAS",
    value: "28248",
    type: "common",
  },
  {
    label: "LOUVILLIERS-LES-PERCHE",
    value: "28217",
    type: "common",
  },
  {
    label: "SENONCHES",
    value: "28373",
    type: "common",
  },
  {
    label: "LA PUISAYE",
    value: "28310",
    type: "common",
  },
  {
    label: "LA FRAMBOISIERE",
    value: "28159",
    type: "common",
  },
  {
    label: "LES RESSUINTES",
    value: "28314",
    type: "common",
  },
  {
    label: "LA FERTE-VIDAME",
    value: "28149",
    type: "common",
  },
  {
    label: "LA VENTROUZE",
    value: "61500",
    type: "common",
  },
  {
    label: "L'HOME-CHAMONDOT",
    value: "61206",
    type: "common",
  },
  {
    label: "TOUROUVRE AU PERCHE",
    value: "61491",
    type: "common",
  },
  {
    label: "SAINT-HILAIRE-LE-CHATEL",
    value: "61404",
    type: "common",
  },
  {
    label: "SAINTE-CERONNE-LES-MORTAGNE",
    value: "61373",
    type: "common",
  },
  {
    label: "SAINT-OUEN-DE-SECHEROUVRE",
    value: "61438",
    type: "common",
  },
  {
    label: "SAINT-GERMAIN-DE-MARTIGNY",
    value: "61396",
    type: "common",
  },
  {
    label: "CHAMPEAUX-SUR-SARTHE",
    value: "61087",
    type: "common",
  },
  {
    label: "BAZOCHES-SUR-HOENE",
    value: "61029",
    type: "common",
  },
  {
    label: "SAINTE-SCOLASSE-SUR-SARTHE",
    value: "61454",
    type: "common",
  },
  {
    label: "LE PLANTIS",
    value: "61331",
    type: "common",
  },
  {
    label: "MONTCHEVREL",
    value: "61284",
    type: "common",
  },
  {
    label: "BURES",
    value: "61067",
    type: "common",
  },
  {
    label: "TREMONT",
    value: "61492",
    type: "common",
  },
  {
    label: "LE MENIL-GUYON",
    value: "61266",
    type: "common",
  },
  {
    label: "LE CHALANGE",
    value: "61082",
    type: "common",
  },
  {
    label: "BOITRON",
    value: "61051",
    type: "common",
  },
  {
    label: "NEAUPHE-SOUS-ESSAI",
    value: "61301",
    type: "common",
  },
  {
    label: "LA CHAPELLE-PRES-SEES",
    value: "61098",
    type: "common",
  },
  {
    label: "LA FERRIERE-BECHET",
    value: "61164",
    type: "common",
  },
  {
    label: "LE BOUILLON",
    value: "61056",
    type: "common",
  },
  {
    label: "TANVILLE",
    value: "61480",
    type: "common",
  },
  {
    label: "LE CERCUEIL",
    value: "61076",
    type: "common",
  },
  {
    label: "SAINT-SAUVEUR-DE-CARROUGES",
    value: "61453",
    type: "common",
  },
  {
    label: "ROUPERROUX",
    value: "61357",
    type: "common",
  },
  {
    label: "LA LANDE-DE-GOULT",
    value: "61216",
    type: "common",
  },
  {
    label: "CHAHAINS",
    value: "61080",
    type: "common",
  },
  {
    label: "SAINT-MARTIN-DES-LANDES",
    value: "61424",
    type: "common",
  },
  {
    label: "SAINTE-MARGUERITE-DE-CARROUGES",
    value: "61419",
    type: "common",
  },
  {
    label: "CARROUGES",
    value: "61074",
    type: "common",
  },
  {
    label: "SAINT-PATRICE-DU-DESERT",
    value: "61442",
    type: "common",
  },
  {
    label: "LA MOTTE-FOUQUET",
    value: "61295",
    type: "common",
  },
  {
    label: "JOUE-DU-BOIS",
    value: "61209",
    type: "common",
  },
  {
    label: "MAGNY-LE-DESERT",
    value: "61243",
    type: "common",
  },
  {
    label: "LA FERTE MACE",
    value: "61168",
    type: "common",
  },
  {
    label: "BAGNOLES DE L'ORNE NORMANDIE",
    value: "61483",
    type: "common",
  },
  {
    label: "RIVES D'ANDAINE",
    value: "61096",
    type: "common",
  },
  {
    label: "PERROU",
    value: "61326",
    type: "common",
  },
  {
    label: "JUVIGNY VAL D'ANDAINE",
    value: "61211",
    type: "common",
  },
  {
    label: "SAINT-BRICE",
    value: "61370",
    type: "common",
  },
  {
    label: "DOMFRONT EN POIRAIE",
    value: "61145",
    type: "common",
  },
  {
    label: "AVRILLY",
    value: "61021",
    type: "common",
  },
  {
    label: "TORCHAMP",
    value: "61487",
    type: "common",
  },
  {
    label: "SAINT-ROCH-SUR-EGRENNE",
    value: "61452",
    type: "common",
  },
  {
    label: "SAINT-MARS-D'EGRENNE",
    value: "61421",
    type: "common",
  },
  {
    label: "SAINT-CYR-DU-BAILLEUL",
    value: "50462",
    type: "common",
  },
  {
    label: "LE TEILLEUL",
    value: "50591",
    type: "common",
  },
  {
    label: "BUAIS-LES-MONTS",
    value: "50090",
    type: "common",
  },
  {
    label: "SAVIGNY-LE-VIEUX",
    value: "50570",
    type: "common",
  },
  {
    label: "SAINT-HILAIRE-DU-HARCOUET",
    value: "50484",
    type: "common",
  },
  {
    label: "MOULINES",
    value: "50362",
    type: "common",
  },
  {
    label: "LES LOGES-MARCHIS",
    value: "50274",
    type: "common",
  },
  {
    label: "LAPENTY",
    value: "50263",
    type: "common",
  },
  {
    label: "SAINT-BRICE-DE-LANDELLES",
    value: "50452",
    type: "common",
  },
  {
    label: "HAMELIN",
    value: "50229",
    type: "common",
  },
  {
    label: "SAINT-SENIER-DE-BEUVRON",
    value: "50553",
    type: "common",
  },
  {
    label: "SAINT-JAMES",
    value: "50487",
    type: "common",
  },
  {
    label: "MONTJOIE-SAINT-MARTIN",
    value: "50347",
    type: "common",
  },
  {
    label: "AUCEY-LA-PLAINE",
    value: "50019",
    type: "common",
  },
  {
    label: "SOUGEAL",
    value: "35329",
    type: "common",
  },
  {
    label: "SAINS",
    value: "35248",
    type: "common",
  },
  {
    label: "PLEINE-FOUGERES",
    value: "35222",
    type: "common",
  },
  {
    label: "EPINIAC",
    value: "35104",
    type: "common",
  },
  {
    label: "LA BOUSSAC",
    value: "35034",
    type: "common",
  },
  {
    label: "BAGUER-PICAN",
    value: "35010",
    type: "common",
  },
  {
    label: "DOL-DE-BRETAGNE",
    value: "35095",
    type: "common",
  },
  {
    label: "BAGUER-MORVAN",
    value: "35009",
    type: "common",
  },
  {
    label: "LE TRONCHET",
    value: "35362",
    type: "common",
  },
  {
    label: "ROZ-LANDRIEUX",
    value: "35246",
    type: "common",
  },
  {
    label: "PLERGUER",
    value: "35224",
    type: "common",
  },
  {
    label: "MINIAC-MORVAN",
    value: "35179",
    type: "common",
  },
  {
    label: "LA VICOMTE-SUR-RANCE",
    value: "22385",
    type: "common",
  },
  {
    label: "PLEUDIHEN-SUR-RANCE",
    value: "22197",
    type: "common",
  },
  {
    label: "LA VILLE-ES-NONAIS",
    value: "35358",
    type: "common",
  },
  {
    label: "SAINT-SAMSON-SUR-RANCE",
    value: "22327",
    type: "common",
  },
  {
    label: "TADEN",
    value: "22339",
    type: "common",
  },
  {
    label: "PLOUER-SUR-RANCE",
    value: "22213",
    type: "common",
  },
  {
    label: "PLESLIN-TRIGAVOU",
    value: "22190",
    type: "common",
  },
  {
    label: "LANGUENAN",
    value: "22105",
    type: "common",
  },
  {
    label: "CORSEUL",
    value: "22048",
    type: "common",
  },
  {
    label: "PLANCOET",
    value: "22172",
    type: "common",
  },
  {
    label: "CREHEN",
    value: "22049",
    type: "common",
  },
  {
    label: "PLUDUNO",
    value: "22237",
    type: "common",
  },
  {
    label: "PLEVEN",
    value: "22200",
    type: "common",
  },
  {
    label: "LANDEBIA",
    value: "22096",
    type: "common",
  },
  {
    label: "SAINT-DENOUAL",
    value: "22286",
    type: "common",
  },
  {
    label: "QUINTENIC",
    value: "22261",
    type: "common",
  },
  {
    label: "LAMBALLE-ARMOR",
    value: "22093",
    type: "common",
  },
  {
    label: "COETMIEUX",
    value: "22044",
    type: "common",
  },
  {
    label: "ANDEL",
    value: "22002",
    type: "common",
  },
  {
    label: "YFFINIAC",
    value: "22389",
    type: "common",
  },
  {
    label: "HILLION",
    value: "22081",
    type: "common",
  },
  {
    label: "SAINT-BRIEUC",
    value: "22278",
    type: "common",
  },
  {
    label: "TREGUEUX",
    value: "22360",
    type: "common",
  },
  {
    label: "LANGUEUX",
    value: "22106",
    type: "common",
  },
  {
    label: "PLOUFRAGAN",
    value: "22215",
    type: "common",
  },
  {
    label: "LA MEAUGON",
    value: "22144",
    type: "common",
  },
  {
    label: "PLERNEUF",
    value: "22188",
    type: "common",
  },
  {
    label: "PLOUVARA",
    value: "22234",
    type: "common",
  },
  {
    label: "COHINIAC",
    value: "22045",
    type: "common",
  },
  {
    label: "SAINT-DONAN",
    value: "22287",
    type: "common",
  },
  {
    label: "BOQUEHO",
    value: "22011",
    type: "common",
  },
  {
    label: "SAINT-ADRIEN",
    value: "22271",
    type: "common",
  },
  {
    label: "SAINT-PEVER",
    value: "22322",
    type: "common",
  },
  {
    label: "SAINT-FIACRE",
    value: "22289",
    type: "common",
  },
  {
    label: "LANRODEC",
    value: "22116",
    type: "common",
  },
  {
    label: "BOURBRIAC",
    value: "22013",
    type: "common",
  },
  {
    label: "PONT-MELVEZ",
    value: "22249",
    type: "common",
  },
  {
    label: "BULAT-PESTIVIEN",
    value: "22023",
    type: "common",
  },
  {
    label: "PLOUGONVER",
    value: "22216",
    type: "common",
  },
  {
    label: "CALANHEL",
    value: "22024",
    type: "common",
  },
  {
    label: "LA CHAPELLE-NEUVE",
    value: "22037",
    type: "common",
  },
  {
    label: "LOHUEC",
    value: "22132",
    type: "common",
  },
  {
    label: "BOLAZEC",
    value: "29012",
    type: "common",
  },
  {
    label: "SCRIGNAC",
    value: "29275",
    type: "common",
  },
  {
    label: "LE CLOITRE-SAINT-THEGONNEC",
    value: "29034",
    type: "common",
  },
  {
    label: "PLOUNEOUR-MENEZ",
    value: "29202",
    type: "common",
  },
  {
    label: "SAINT-SAUVEUR",
    value: "29262",
    type: "common",
  },
  {
    label: "COMMANA",
    value: "29038",
    type: "common",
  },
  {
    label: "LOCMELAR",
    value: "29131",
    type: "common",
  },
  {
    label: "LE TREHOU",
    value: "29294",
    type: "common",
  },
  {
    label: "TREFLEVENEZ",
    value: "29286",
    type: "common",
  },
  {
    label: "LA MARTYRE",
    value: "29144",
    type: "common",
  },
  {
    label: "SAINT-URBAIN",
    value: "29270",
    type: "common",
  },
  {
    label: "DIRINON",
    value: "29045",
    type: "common",
  },
  {
    label: "LOPERHET",
    value: "29140",
    type: "common",
  },
  {
    label: "LA FOREST-LANDERNEAU",
    value: "29056",
    type: "common",
  },
  {
    label: "LE RELECQ-KERHUON",
    value: "29235",
    type: "common",
  },
  {
    label: "BREST",
    value: "29019",
    type: "common",
  },
  {
    label: "PLOUZANE",
    value: "29212",
    type: "common",
  },
  {
    label: "GUILERS",
    value: "29069",
    type: "common",
  },
  {
    label: "SAINT-RENAN",
    value: "29260",
    type: "common",
  },
  {
    label: "PLOUMOGUER",
    value: "29201",
    type: "common",
  },
  {
    label: "PLOUGONVELIN",
    value: "29190",
    type: "common",
  },
  {
    label: "LOCMARIA-PLOUZANE",
    value: "29130",
    type: "common",
  },
  {
    label: "TREBABU",
    value: "29282",
    type: "common",
  },
  {
    label: "ERSTEIN",
    value: "67130",
    type: "common",
  },
  {
    label: "UTTENHEIM",
    value: "67501",
    type: "common",
  },
  {
    label: "SCHAEFFERSHEIM",
    value: "67438",
    type: "common",
  },
  {
    label: "OSTHOUSE",
    value: "67364",
    type: "common",
  },
  {
    label: "BOLSENHEIM",
    value: "67054",
    type: "common",
  },
  {
    label: "ZELLWILLER",
    value: "67557",
    type: "common",
  },
  {
    label: "WESTHOUSE",
    value: "67526",
    type: "common",
  },
  {
    label: "VALFF",
    value: "67504",
    type: "common",
  },
  {
    label: "NIEDERNAI",
    value: "67329",
    type: "common",
  },
  {
    label: "SAINT-NABOR",
    value: "67428",
    type: "common",
  },
  {
    label: "HEILIGENSTEIN",
    value: "67189",
    type: "common",
  },
  {
    label: "GOXWILLER",
    value: "67164",
    type: "common",
  },
  {
    label: "GERTWILLER",
    value: "67155",
    type: "common",
  },
  {
    label: "BOURGHEIM",
    value: "67060",
    type: "common",
  },
  {
    label: "BERNARDSWILLER",
    value: "67031",
    type: "common",
  },
  {
    label: "OTTROTT",
    value: "67368",
    type: "common",
  },
  {
    label: "BARR",
    value: "67021",
    type: "common",
  },
  {
    label: "BELMONT",
    value: "67027",
    type: "common",
  },
  {
    label: "NEUVILLER-LA-ROCHE",
    value: "67321",
    type: "common",
  },
  {
    label: "NATZWILLER",
    value: "67314",
    type: "common",
  },
  {
    label: "LE HOHWALD",
    value: "67210",
    type: "common",
  },
  {
    label: "BAREMBACH",
    value: "67020",
    type: "common",
  },
  {
    label: "WALDERSBACH",
    value: "67513",
    type: "common",
  },
  {
    label: "FOUDAY",
    value: "67144",
    type: "common",
  },
  {
    label: "WILDERSBACH",
    value: "67531",
    type: "common",
  },
  {
    label: "SOLBACH",
    value: "67470",
    type: "common",
  },
  {
    label: "ROTHAU",
    value: "67414",
    type: "common",
  },
  {
    label: "LA BROQUE",
    value: "67066",
    type: "common",
  },
  {
    label: "BLANCHERUPT",
    value: "67050",
    type: "common",
  },
  {
    label: "LE SAULCY",
    value: "88444",
    type: "common",
  },
  {
    label: "MOUSSEY",
    value: "88317",
    type: "common",
  },
  {
    label: "PLAINE",
    value: "67377",
    type: "common",
  },
  {
    label: "ALLARMONT",
    value: "88005",
    type: "common",
  },
  {
    label: "CELLES-SUR-PLAINE",
    value: "88082",
    type: "common",
  },
  {
    label: "PIERRE-PERCEE",
    value: "54427",
    type: "common",
  },
  {
    label: "NEUFMAISONS",
    value: "54396",
    type: "common",
  },
  {
    label: "VENEY",
    value: "54560",
    type: "common",
  },
  {
    label: "VACQUEVILLE",
    value: "54539",
    type: "common",
  },
  {
    label: "PEXONNE",
    value: "54423",
    type: "common",
  },
  {
    label: "BERTRICHAMPS",
    value: "54065",
    type: "common",
  },
  {
    label: "MERVILLER",
    value: "54365",
    type: "common",
  },
  {
    label: "GELACOURT",
    value: "54217",
    type: "common",
  },
  {
    label: "BACCARAT",
    value: "54039",
    type: "common",
  },
  {
    label: "DOMPTAIL",
    value: "88153",
    type: "common",
  },
  {
    label: "GLONVILLE",
    value: "54229",
    type: "common",
  },
  {
    label: "FONTENOY-LA-JOUTE",
    value: "54201",
    type: "common",
  },
  {
    label: "SAINT-PIERREMONT",
    value: "88432",
    type: "common",
  },
  {
    label: "VALLOIS",
    value: "54543",
    type: "common",
  },
  {
    label: "MOYEN",
    value: "54393",
    type: "common",
  },
  {
    label: "MAGNIERES",
    value: "54331",
    type: "common",
  },
  {
    label: "VENNEZEY",
    value: "54561",
    type: "common",
  },
  {
    label: "SERANVILLE",
    value: "54501",
    type: "common",
  },
  {
    label: "REMENOVILLE",
    value: "54455",
    type: "common",
  },
  {
    label: "MORIVILLER",
    value: "54386",
    type: "common",
  },
  {
    label: "MATTEXEY",
    value: "54356",
    type: "common",
  },
  {
    label: "GIRIVILLER",
    value: "54228",
    type: "common",
  },
  {
    label: "ROZELIEURES",
    value: "54467",
    type: "common",
  },
  {
    label: "FROVILLE",
    value: "54216",
    type: "common",
  },
  {
    label: "EINVAUX",
    value: "54175",
    type: "common",
  },
  {
    label: "CLAYEURES",
    value: "54130",
    type: "common",
  },
  {
    label: "BREMONCOURT",
    value: "54098",
    type: "common",
  },
  {
    label: "BORVILLE",
    value: "54085",
    type: "common",
  },
  {
    label: "VIRECOURT",
    value: "54585",
    type: "common",
  },
  {
    label: "ROVILLE-DEVANT-BAYON",
    value: "54465",
    type: "common",
  },
  {
    label: "NEUVILLER-SUR-MOSELLE",
    value: "54399",
    type: "common",
  },
  {
    label: "MANGONVILLE",
    value: "54344",
    type: "common",
  },
  {
    label: "LOREY",
    value: "54324",
    type: "common",
  },
  {
    label: "LANEUVEVILLE-DEVANT-BAYON",
    value: "54299",
    type: "common",
  },
  {
    label: "HAIGNEVILLE",
    value: "54245",
    type: "common",
  },
  {
    label: "BAYON",
    value: "54054",
    type: "common",
  },
  {
    label: "CRANTENOY",
    value: "54142",
    type: "common",
  },
  {
    label: "ORMES-ET-VILLE",
    value: "54411",
    type: "common",
  },
  {
    label: "VAUDEVILLE",
    value: "54553",
    type: "common",
  },
  {
    label: "SAINT-REMIMONT",
    value: "54486",
    type: "common",
  },
  {
    label: "LEMENIL-MITRY",
    value: "54310",
    type: "common",
  },
  {
    label: "LEMAINVILLE",
    value: "54309",
    type: "common",
  },
  {
    label: "HAROUE",
    value: "54252",
    type: "common",
  },
  {
    label: "VEZELISE",
    value: "54563",
    type: "common",
  },
  {
    label: "TANTONVILLE",
    value: "54513",
    type: "common",
  },
  {
    label: "QUEVILLONCOURT",
    value: "54442",
    type: "common",
  },
  {
    label: "OMELMONT",
    value: "54409",
    type: "common",
  },
  {
    label: "GERBECOURT-ET-HAPLEMONT",
    value: "54221",
    type: "common",
  },
  {
    label: "FORCELLES-SAINT-GORGON",
    value: "54203",
    type: "common",
  },
  {
    label: "AFFRACOURT",
    value: "54005",
    type: "common",
  },
  {
    label: "VRONCOURT",
    value: "54592",
    type: "common",
  },
  {
    label: "VITREY",
    value: "54587",
    type: "common",
  },
  {
    label: "OGNEVILLE",
    value: "54407",
    type: "common",
  },
  {
    label: "LALŒUF",
    value: "54291",
    type: "common",
  },
  {
    label: "HAMMEVILLE",
    value: "54247",
    type: "common",
  },
  {
    label: "GOVILLER",
    value: "54235",
    type: "common",
  },
  {
    label: "ETREVAL",
    value: "54185",
    type: "common",
  },
  {
    label: "SELAINCOURT",
    value: "54500",
    type: "common",
  },
  {
    label: "SAULXEROTTE",
    value: "54494",
    type: "common",
  },
  {
    label: "GELAUCOURT",
    value: "54218",
    type: "common",
  },
  {
    label: "FAVIERES",
    value: "54189",
    type: "common",
  },
  {
    label: "DOLCOURT",
    value: "54158",
    type: "common",
  },
  {
    label: "HARMONVILLE",
    value: "88232",
    type: "common",
  },
  {
    label: "AUTREVILLE",
    value: "88020",
    type: "common",
  },
  {
    label: "RUPPES",
    value: "88407",
    type: "common",
  },
  {
    label: "PUNEROT",
    value: "88363",
    type: "common",
  },
  {
    label: "JUBAINVILLE",
    value: "88255",
    type: "common",
  },
  {
    label: "CLEREY-LA-COTE",
    value: "88107",
    type: "common",
  },
  {
    label: "SAUVIGNY",
    value: "55474",
    type: "common",
  },
  {
    label: "MONT-L'ETROIT",
    value: "54379",
    type: "common",
  },
  {
    label: "TAILLANCOURT",
    value: "55503",
    type: "common",
  },
  {
    label: "MONTBRAS",
    value: "55344",
    type: "common",
  },
  {
    label: "GOUSSAINCOURT",
    value: "55217",
    type: "common",
  },
  {
    label: "BUREY-LA-COTE",
    value: "55089",
    type: "common",
  },
  {
    label: "BRIXEY-AUX-CHANOINES",
    value: "55080",
    type: "common",
  },
  {
    label: "VOUTHON-HAUT",
    value: "55575",
    type: "common",
  },
  {
    label: "VOUTHON-BAS",
    value: "55574",
    type: "common",
  },
  {
    label: "LES ROISES",
    value: "55436",
    type: "common",
  },
  {
    label: "AMANTY",
    value: "55005",
    type: "common",
  },
  {
    label: "HORVILLE-EN-ORNOIS",
    value: "55247",
    type: "common",
  },
  {
    label: "GONDRECOURT-LE-CHATEAU",
    value: "55215",
    type: "common",
  },
  {
    label: "MANDRES-EN-BARROIS",
    value: "55315",
    type: "common",
  },
  {
    label: "SAUDRON",
    value: "52463",
    type: "common",
  },
  {
    label: "GILLAUME",
    value: "52222",
    type: "common",
  },
  {
    label: "BURE",
    value: "55087",
    type: "common",
  },
  {
    label: "PAROY-SUR-SAULX",
    value: "52378",
    type: "common",
  },
  {
    label: "PANSEY",
    value: "52376",
    type: "common",
  },
  {
    label: "EFFINCOURT",
    value: "52184",
    type: "common",
  },
  {
    label: "CHEVILLON",
    value: "52123",
    type: "common",
  },
  {
    label: "OSNE-LE-VAL",
    value: "52370",
    type: "common",
  },
  {
    label: "CUREL",
    value: "52156",
    type: "common",
  },
  {
    label: "AUTIGNY-LE-PETIT",
    value: "52030",
    type: "common",
  },
  {
    label: "RACHECOURT-SUR-MARNE",
    value: "52414",
    type: "common",
  },
  {
    label: "MAIZIERES",
    value: "52302",
    type: "common",
  },
  {
    label: "SOMMANCOURT",
    value: "52475",
    type: "common",
  },
  {
    label: "WASSY",
    value: "52550",
    type: "common",
  },
  {
    label: "VALLERET",
    value: "52502",
    type: "common",
  },
  {
    label: "MAGNEUX",
    value: "52300",
    type: "common",
  },
  {
    label: "BROUSSEVAL",
    value: "52079",
    type: "common",
  },
  {
    label: "VOILLECOMTE",
    value: "52543",
    type: "common",
  },
  {
    label: "PLANRUPT",
    value: "52391",
    type: "common",
  },
  {
    label: "FRAMPAS",
    value: "52206",
    type: "common",
  },
  {
    label: "RIVES DERVOISES",
    value: "52411",
    type: "common",
  },
  {
    label: "CHAVANGES",
    value: "10094",
    type: "common",
  },
  {
    label: "JONCREUIL",
    value: "10180",
    type: "common",
  },
  {
    label: "LENTILLES",
    value: "10192",
    type: "common",
  },
  {
    label: "BAILLY-LE-FRANC",
    value: "10026",
    type: "common",
  },
  {
    label: "ARREMBECOURT",
    value: "10010",
    type: "common",
  },
  {
    label: "PARS-LES-CHAVANGES",
    value: "10279",
    type: "common",
  },
  {
    label: "MONTMORENCY-BEAUFORT",
    value: "10253",
    type: "common",
  },
  {
    label: "SAINT-LEGER-SOUS-MARGERIE",
    value: "10346",
    type: "common",
  },
  {
    label: "BALIGNICOURT",
    value: "10027",
    type: "common",
  },
  {
    label: "DONNEMENT",
    value: "10128",
    type: "common",
  },
  {
    label: "VAUCOGNE",
    value: "10398",
    type: "common",
  },
  {
    label: "MOREMBERT",
    value: "10257",
    type: "common",
  },
  {
    label: "DOMMARTIN-LE-COQ",
    value: "10127",
    type: "common",
  },
  {
    label: "JASSEINES",
    value: "10175",
    type: "common",
  },
  {
    label: "ISLE-AUBIGNY",
    value: "10174",
    type: "common",
  },
  {
    label: "VAUPOISSON",
    value: "10400",
    type: "common",
  },
  {
    label: "RAMERUPT",
    value: "10314",
    type: "common",
  },
  {
    label: "VINETS",
    value: "10436",
    type: "common",
  },
  {
    label: "ORTILLON",
    value: "10273",
    type: "common",
  },
  {
    label: "SAINT-NABORD-SUR-AUBE",
    value: "10354",
    type: "common",
  },
  {
    label: "MESNIL-LA-COMTESSE",
    value: "10235",
    type: "common",
  },
  {
    label: "TORCY-LE-PETIT",
    value: "10380",
    type: "common",
  },
  {
    label: "TORCY-LE-GRAND",
    value: "10379",
    type: "common",
  },
  {
    label: "ARCIS-SUR-AUBE",
    value: "10006",
    type: "common",
  },
  {
    label: "VILLETTE-SUR-AUBE",
    value: "10429",
    type: "common",
  },
  {
    label: "POUAN-LES-VALLEES",
    value: "10299",
    type: "common",
  },
  {
    label: "NOZAY",
    value: "10269",
    type: "common",
  },
  {
    label: "PREMIERFAIT",
    value: "10305",
    type: "common",
  },
  {
    label: "BESSY",
    value: "10043",
    type: "common",
  },
  {
    label: "RHEGES",
    value: "10316",
    type: "common",
  },
  {
    label: "MERY-SUR-SEINE",
    value: "10233",
    type: "common",
  },
  {
    label: "DROUPT-SAINTE-MARIE",
    value: "10132",
    type: "common",
  },
  {
    label: "LONGUEVILLE-SUR-AUBE",
    value: "10207",
    type: "common",
  },
  {
    label: "SAINT-OULPH",
    value: "10356",
    type: "common",
  },
  {
    label: "CHARNY-LE-BACHOT",
    value: "10086",
    type: "common",
  },
  {
    label: "CLESLES",
    value: "51155",
    type: "common",
  },
  {
    label: "MAIZIERES-LA-GRANDE-PAROISSE",
    value: "10220",
    type: "common",
  },
  {
    label: "SAINT-JUST-SAUVAGE",
    value: "51492",
    type: "common",
  },
  {
    label: "ROMILLY-SUR-SEINE",
    value: "10323",
    type: "common",
  },
  {
    label: "CONFLANS-SUR-SEINE",
    value: "51162",
    type: "common",
  },
  {
    label: "ESCLAVOLLES-LUREY",
    value: "51234",
    type: "common",
  },
  {
    label: "SAINT-HILAIRE-SOUS-ROMILLY",
    value: "10341",
    type: "common",
  },
  {
    label: "CRANCEY",
    value: "10114",
    type: "common",
  },
  {
    label: "LA VILLENEUVE-AU-CHATELOT",
    value: "10421",
    type: "common",
  },
  {
    label: "PERIGNY-LA-ROSE",
    value: "10284",
    type: "common",
  },
  {
    label: "MARNAY-SUR-SEINE",
    value: "10225",
    type: "common",
  },
  {
    label: "PONT-SUR-SEINE",
    value: "10298",
    type: "common",
  },
  {
    label: "BARBUISE",
    value: "10031",
    type: "common",
  },
  {
    label: "LE MERIOT",
    value: "10231",
    type: "common",
  },
  {
    label: "SAINT-NICOLAS-LA-CHAPELLE",
    value: "10355",
    type: "common",
  },
  {
    label: "NOGENT-SUR-SEINE",
    value: "10268",
    type: "common",
  },
  {
    label: "LA SAULSOTTE",
    value: "10367",
    type: "common",
  },
  {
    label: "CHALAUTRE-LA-GRANDE",
    value: "77072",
    type: "common",
  },
  {
    label: "SOURDUN",
    value: "77459",
    type: "common",
  },
  {
    label: "MELZ-SUR-SEINE",
    value: "77289",
    type: "common",
  },
  {
    label: "SOISY-BOUY",
    value: "77456",
    type: "common",
  },
  {
    label: "SAINTE-COLOMBE",
    value: "77404",
    type: "common",
  },
  {
    label: "POIGNY",
    value: "77368",
    type: "common",
  },
  {
    label: "CHALAUTRE-LA-PETITE",
    value: "77073",
    type: "common",
  },
  {
    label: "SOGNOLLES-EN-MONTOIS",
    value: "77454",
    type: "common",
  },
  {
    label: "SAVINS",
    value: "77446",
    type: "common",
  },
  {
    label: "SAINT-LOUP-DE-NAUD",
    value: "77418",
    type: "common",
  },
  {
    label: "LONGUEVILLE",
    value: "77260",
    type: "common",
  },
  {
    label: "LIZINES",
    value: "77256",
    type: "common",
  },
  {
    label: "LA CHAPELLE-SAINT-SULPICE",
    value: "77090",
    type: "common",
  },
  {
    label: "VANVILLE",
    value: "77481",
    type: "common",
  },
  {
    label: "RAMPILLON",
    value: "77383",
    type: "common",
  },
  {
    label: "MEIGNEUX",
    value: "77286",
    type: "common",
  },
  {
    label: "MAISON-ROUGE",
    value: "77272",
    type: "common",
  },
  {
    label: "NANGIS",
    value: "77327",
    type: "common",
  },
  {
    label: "FONTAINS",
    value: "77190",
    type: "common",
  },
  {
    label: "SAINT-OUEN-EN-BRIE",
    value: "77428",
    type: "common",
  },
  {
    label: "FONTENAILLES",
    value: "77191",
    type: "common",
  },
  {
    label: "LA CHAPELLE-RABLAIS",
    value: "77089",
    type: "common",
  },
  {
    label: "CHATILLON-LA-BORDE",
    value: "77103",
    type: "common",
  },
  {
    label: "LA CHAPELLE-GAUTHIER",
    value: "77086",
    type: "common",
  },
  {
    label: "BREAU",
    value: "77052",
    type: "common",
  },
  {
    label: "SIVRY-COURTRY",
    value: "77453",
    type: "common",
  },
  {
    label: "MOISENAY",
    value: "77295",
    type: "common",
  },
  {
    label: "BLANDY",
    value: "77034",
    type: "common",
  },
  {
    label: "LE MEE-SUR-SEINE",
    value: "77285",
    type: "common",
  },
  {
    label: "VAUX-LE-PENIL",
    value: "77487",
    type: "common",
  },
  {
    label: "LA ROCHETTE",
    value: "77389",
    type: "common",
  },
  {
    label: "MELUN",
    value: "77288",
    type: "common",
  },
  {
    label: "MAINCY",
    value: "77269",
    type: "common",
  },
  {
    label: "DAMMARIE-LES-LYS",
    value: "77152",
    type: "common",
  },
  {
    label: "CESSON",
    value: "77067",
    type: "common",
  },
  {
    label: "BOISSISE-LA-BERTRAND",
    value: "77039",
    type: "common",
  },
  {
    label: "BOISSETTES",
    value: "77038",
    type: "common",
  },
  {
    label: "SEINE-PORT",
    value: "77447",
    type: "common",
  },
  {
    label: "PRINGY",
    value: "77378",
    type: "common",
  },
  {
    label: "LE COUDRAY-MONTCEAUX",
    value: "91179",
    type: "common",
  },
  {
    label: "AUVERNAUX",
    value: "91037",
    type: "common",
  },
  {
    label: "SAINT-FARGEAU-PONTHIERRY",
    value: "77407",
    type: "common",
  },
  {
    label: "VERT-LE-PETIT",
    value: "91649",
    type: "common",
  },
  {
    label: "MENNECY",
    value: "91386",
    type: "common",
  },
  {
    label: "FONTENAY-LE-VICOMTE",
    value: "91244",
    type: "common",
  },
  {
    label: "CHEVANNES",
    value: "91159",
    type: "common",
  },
  {
    label: "CHAMPCUEIL",
    value: "91135",
    type: "common",
  },
  {
    label: "BALLANCOURT-SUR-ESSONNE",
    value: "91045",
    type: "common",
  },
  {
    label: "SAINT-VRAIN",
    value: "91579",
    type: "common",
  },
  {
    label: "MAROLLES-EN-HUREPOIX",
    value: "91376",
    type: "common",
  },
  {
    label: "LEUDEVILLE",
    value: "91332",
    type: "common",
  },
  {
    label: "LARDY",
    value: "91330",
    type: "common",
  },
  {
    label: "ITTEVILLE",
    value: "91315",
    type: "common",
  },
  {
    label: "CHEPTAINVILLE",
    value: "91156",
    type: "common",
  },
  {
    label: "BOURAY-SUR-JUINE",
    value: "91095",
    type: "common",
  },
  {
    label: "TORFOU",
    value: "91619",
    type: "common",
  },
  {
    label: "SAINT-YON",
    value: "91581",
    type: "common",
  },
  {
    label: "SAINT-SULPICE-DE-FAVIERES",
    value: "91578",
    type: "common",
  },
  {
    label: "MAUCHAMPS",
    value: "91378",
    type: "common",
  },
  {
    label: "CHAMARANDE",
    value: "91132",
    type: "common",
  },
  {
    label: "BOISSY-SOUS-SAINT-YON",
    value: "91085",
    type: "common",
  },
  {
    label: "AVRAINVILLE",
    value: "91041",
    type: "common",
  },
  {
    label: "SOUZY-LA-BRICHE",
    value: "91602",
    type: "common",
  },
  {
    label: "SAINT-CHERON",
    value: "91540",
    type: "common",
  },
  {
    label: "CHAUFFOUR-LES-ETRECHY",
    value: "91148",
    type: "common",
  },
  {
    label: "BREUX-JOUY",
    value: "91106",
    type: "common",
  },
  {
    label: "LE VAL-SAINT-GERMAIN",
    value: "91630",
    type: "common",
  },
  {
    label: "SERMAISE",
    value: "91593",
    type: "common",
  },
  {
    label: "ROINVILLE",
    value: "91525",
    type: "common",
  },
  {
    label: "DOURDAN",
    value: "91200",
    type: "common",
  },
  {
    label: "SAINTE-MESME",
    value: "78569",
    type: "common",
  },
  {
    label: "SAINT-ARNOULT-EN-YVELINES",
    value: "78537",
    type: "common",
  },
  {
    label: "SAINT-MARTIN-DE-BRETHENCOURT",
    value: "78564",
    type: "common",
  },
  {
    label: "PONTHEVRARD",
    value: "78499",
    type: "common",
  },
  {
    label: "ABLIS",
    value: "78003",
    type: "common",
  },
  {
    label: "PRUNAY-EN-YVELINES",
    value: "78506",
    type: "common",
  },
  {
    label: "YMERAY",
    value: "28425",
    type: "common",
  },
  {
    label: "GALLARDON",
    value: "28168",
    type: "common",
  },
  {
    label: "ECROSNES",
    value: "28137",
    type: "common",
  },
  {
    label: "YERMENONVILLE",
    value: "28423",
    type: "common",
  },
  {
    label: "SOULAIRES",
    value: "28379",
    type: "common",
  },
  {
    label: "SAINT-PIAT",
    value: "28357",
    type: "common",
  },
  {
    label: "MEVOISINS",
    value: "28249",
    type: "common",
  },
  {
    label: "BAILLEAU-ARMENONVILLE",
    value: "28023",
    type: "common",
  },
  {
    label: "JOUY",
    value: "28201",
    type: "common",
  },
  {
    label: "CHARTAINVILLIERS",
    value: "28084",
    type: "common",
  },
  {
    label: "BOUGLAINVAL",
    value: "28052",
    type: "common",
  },
  {
    label: "POISVILLIERS",
    value: "28301",
    type: "common",
  },
  {
    label: "FRESNAY-LE-GILMERT",
    value: "28163",
    type: "common",
  },
  {
    label: "CLEVILLIERS",
    value: "28102",
    type: "common",
  },
  {
    label: "CHALLET",
    value: "28068",
    type: "common",
  },
  {
    label: "BRICONVILLE",
    value: "28060",
    type: "common",
  },
  {
    label: "BERCHERES-SAINT-GERMAIN",
    value: "28034",
    type: "common",
  },
  {
    label: "DANGERS",
    value: "28128",
    type: "common",
  },
  {
    label: "THIMERT-GATELLES",
    value: "28386",
    type: "common",
  },
  {
    label: "FAVIERES",
    value: "28147",
    type: "common",
  },
  {
    label: "DIGNY",
    value: "28130",
    type: "common",
  },
  {
    label: "ARDELLES",
    value: "28008",
    type: "common",
  },
  {
    label: "BELHOMERT-GUEHOUVILLE",
    value: "28033",
    type: "common",
  },
  {
    label: "MANOU",
    value: "28232",
    type: "common",
  },
  {
    label: "FONTAINE-SIMON",
    value: "28156",
    type: "common",
  },
  {
    label: "LES MENUS",
    value: "61274",
    type: "common",
  },
  {
    label: "LE MAGE",
    value: "61242",
    type: "common",
  },
  {
    label: "LONGNY LES VILLAGES",
    value: "61230",
    type: "common",
  },
  {
    label: "VILLIERS-SOUS-MORTAGNE",
    value: "61507",
    type: "common",
  },
  {
    label: "SAINT-MARD-DE-RENO",
    value: "61418",
    type: "common",
  },
  {
    label: "FEINGS",
    value: "61160",
    type: "common",
  },
  {
    label: "SAINT-LANGIS-LES-MORTAGNE",
    value: "61414",
    type: "common",
  },
  {
    label: "MORTAGNE-AU-PERCHE",
    value: "61293",
    type: "common",
  },
  {
    label: "LOISAIL",
    value: "61229",
    type: "common",
  },
  {
    label: "LA MESNIERE",
    value: "61277",
    type: "common",
  },
  {
    label: "COURGEOUT",
    value: "61130",
    type: "common",
  },
  {
    label: "BOECE",
    value: "61048",
    type: "common",
  },
  {
    label: "SAINT-JULIEN-SUR-SARTHE",
    value: "61412",
    type: "common",
  },
  {
    label: "SAINT-AUBIN-D'APPENAI",
    value: "61365",
    type: "common",
  },
  {
    label: "LE MELE-SUR-SARTHE",
    value: "61258",
    type: "common",
  },
  {
    label: "LALEU",
    value: "61215",
    type: "common",
  },
  {
    label: "COULONGES-SUR-SARTHE",
    value: "61126",
    type: "common",
  },
  {
    label: "BURE",
    value: "61066",
    type: "common",
  },
  {
    label: "LES VENTES-DE-BOURSE",
    value: "61499",
    type: "common",
  },
  {
    label: "SAINT-LEGER-SUR-SARTHE",
    value: "61415",
    type: "common",
  },
  {
    label: "MARCHEMAISONS",
    value: "61251",
    type: "common",
  },
  {
    label: "ESSAY",
    value: "61156",
    type: "common",
  },
  {
    label: "AUNAY-LES-BOIS",
    value: "61013",
    type: "common",
  },
  {
    label: "NEUILLY-LE-BISSON",
    value: "61304",
    type: "common",
  },
  {
    label: "MENIL-ERREUX",
    value: "61263",
    type: "common",
  },
  {
    label: "LARRE",
    value: "61224",
    type: "common",
  },
  {
    label: "BURSARD",
    value: "61068",
    type: "common",
  },
  {
    label: "SAINT-GERVAIS-DU-PERRON",
    value: "61400",
    type: "common",
  },
  {
    label: "ECOUVES",
    value: "61341",
    type: "common",
  },
  {
    label: "SAINT-NICOLAS-DES-BOIS",
    value: "61433",
    type: "common",
  },
  {
    label: "SAINT-ELLIER-LES-BOIS",
    value: "61384",
    type: "common",
  },
  {
    label: "LA ROCHE-MABILE",
    value: "61350",
    type: "common",
  },
  {
    label: "CIRAL",
    value: "61107",
    type: "common",
  },
  {
    label: "LIGNIERES-ORGERES",
    value: "53133",
    type: "common",
  },
  {
    label: "SAINT-CALAIS-DU-DESERT",
    value: "53204",
    type: "common",
  },
  {
    label: "LA PALLU",
    value: "53173",
    type: "common",
  },
  {
    label: "COUPTRAIN",
    value: "53080",
    type: "common",
  },
  {
    label: "SAINT-OUEN-LE-BRISOULT",
    value: "61439",
    type: "common",
  },
  {
    label: "MEHOUDIN",
    value: "61257",
    type: "common",
  },
  {
    label: "NEUILLY-LE-VENDIN",
    value: "53164",
    type: "common",
  },
  {
    label: "TESSE-FROULAY",
    value: "61482",
    type: "common",
  },
  {
    label: "THUBŒUF",
    value: "53263",
    type: "common",
  },
  {
    label: "SAINT-JULIEN-DU-TERROUX",
    value: "53230",
    type: "common",
  },
  {
    label: "RENNES-EN-GRENOUILLES",
    value: "53189",
    type: "common",
  },
  {
    label: "CEAUCE",
    value: "61075",
    type: "common",
  },
  {
    label: "SAINT-FRAIMBAULT",
    value: "61387",
    type: "common",
  },
  {
    label: "SOUCE",
    value: "53261",
    type: "common",
  },
  {
    label: "PASSAIS VILLAGES",
    value: "61324",
    type: "common",
  },
  {
    label: "MANTILLY",
    value: "61248",
    type: "common",
  },
  {
    label: "DESERTINES",
    value: "53091",
    type: "common",
  },
  {
    label: "FOUGEROLLES-DU-PLESSIS",
    value: "53100",
    type: "common",
  },
  {
    label: "LANDIVY",
    value: "53125",
    type: "common",
  },
  {
    label: "MONTHAULT",
    value: "35190",
    type: "common",
  },
  {
    label: "LOUVIGNE-DU-DESERT",
    value: "35162",
    type: "common",
  },
  {
    label: "VILLAMEE",
    value: "35357",
    type: "common",
  },
  {
    label: "SAINT-GEORGES-DE-REINTEMBAULT",
    value: "35271",
    type: "common",
  },
  {
    label: "POILLEY",
    value: "35230",
    type: "common",
  },
  {
    label: "MELLE",
    value: "35174",
    type: "common",
  },
  {
    label: "LE FERRE",
    value: "35111",
    type: "common",
  },
  {
    label: "SACEY",
    value: "50443",
    type: "common",
  },
  {
    label: "TRANS-LA-FORET",
    value: "35339",
    type: "common",
  },
  {
    label: "VIEUX-VIEL",
    value: "35354",
    type: "common",
  },
  {
    label: "BAZOUGES-LA-PEROUSE",
    value: "35019",
    type: "common",
  },
  {
    label: "TREMEHEUC",
    value: "35342",
    type: "common",
  },
  {
    label: "BROUALAN",
    value: "35044",
    type: "common",
  },
  {
    label: "LOURMAIS",
    value: "35159",
    type: "common",
  },
  {
    label: "BONNEMAIN",
    value: "35029",
    type: "common",
  },
  {
    label: "SAINT-HELEN",
    value: "22299",
    type: "common",
  },
  {
    label: "LES CHAMPS-GERAUX",
    value: "22035",
    type: "common",
  },
  {
    label: "DINAN",
    value: "22050",
    type: "common",
  },
  {
    label: "LANVALLAY",
    value: "22118",
    type: "common",
  },
  {
    label: "TRELIVAN",
    value: "22364",
    type: "common",
  },
  {
    label: "QUEVERT",
    value: "22259",
    type: "common",
  },
  {
    label: "AUCALEUC",
    value: "22003",
    type: "common",
  },
  {
    label: "VILDE-GUINGALAN",
    value: "22388",
    type: "common",
  },
  {
    label: "SAINT-MICHEL-DE-PLELAN",
    value: "22318",
    type: "common",
  },
  {
    label: "SAINT-MAUDEZ",
    value: "22315",
    type: "common",
  },
  {
    label: "PLELAN-LE-PETIT",
    value: "22180",
    type: "common",
  },
  {
    label: "LA LANDEC",
    value: "22097",
    type: "common",
  },
  {
    label: "SAINT-MELOIR-DES-BOIS",
    value: "22317",
    type: "common",
  },
  {
    label: "PLOREC-SUR-ARGUENON",
    value: "22205",
    type: "common",
  },
  {
    label: "BOURSEUL",
    value: "22014",
    type: "common",
  },
  {
    label: "PLEDELIAC",
    value: "22175",
    type: "common",
  },
  {
    label: "PLESTAN",
    value: "22193",
    type: "common",
  },
  {
    label: "SAINT-RIEUL",
    value: "22326",
    type: "common",
  },
  {
    label: "NOYAL",
    value: "22160",
    type: "common",
  },
  {
    label: "LANDEHEN",
    value: "22098",
    type: "common",
  },
  {
    label: "QUESSOY",
    value: "22258",
    type: "common",
  },
  {
    label: "POMMERET",
    value: "22246",
    type: "common",
  },
  {
    label: "PLEDRAN",
    value: "22176",
    type: "common",
  },
  {
    label: "SAINT-CARREUC",
    value: "22281",
    type: "common",
  },
  {
    label: "SAINT-BRANDAN",
    value: "22277",
    type: "common",
  },
  {
    label: "PLAINE-HAUTE",
    value: "22170",
    type: "common",
  },
  {
    label: "SAINT-JULIEN",
    value: "22307",
    type: "common",
  },
  {
    label: "PLAINTEL",
    value: "22171",
    type: "common",
  },
  {
    label: "QUINTIN",
    value: "22262",
    type: "common",
  },
  {
    label: "LE FŒIL",
    value: "22059",
    type: "common",
  },
  {
    label: "LE VIEUX-BOURG",
    value: "22386",
    type: "common",
  },
  {
    label: "SAINT-GILDAS",
    value: "22291",
    type: "common",
  },
  {
    label: "LE LESLAY",
    value: "22126",
    type: "common",
  },
  {
    label: "SENVEN-LEHART",
    value: "22335",
    type: "common",
  },
  {
    label: "PLESIDY",
    value: "22189",
    type: "common",
  },
  {
    label: "SAINT-CONNAN",
    value: "22284",
    type: "common",
  },
  {
    label: "MAGOAR",
    value: "22139",
    type: "common",
  },
  {
    label: "KERPERT",
    value: "22092",
    type: "common",
  },
  {
    label: "MAEL-PESTIVIEN",
    value: "22138",
    type: "common",
  },
  {
    label: "KERIEN",
    value: "22088",
    type: "common",
  },
  {
    label: "SAINT-SERVAIS",
    value: "22328",
    type: "common",
  },
  {
    label: "CALLAC",
    value: "22025",
    type: "common",
  },
  {
    label: "PLUSQUELLEC",
    value: "22243",
    type: "common",
  },
  {
    label: "PLOURAC'H",
    value: "22231",
    type: "common",
  },
  {
    label: "CARNOET",
    value: "22031",
    type: "common",
  },
  {
    label: "HUELGOAT",
    value: "29081",
    type: "common",
  },
  {
    label: "BRENNILIS",
    value: "29018",
    type: "common",
  },
  {
    label: "BERRIEN",
    value: "29007",
    type: "common",
  },
  {
    label: "LA FEUILLEE",
    value: "29054",
    type: "common",
  },
  {
    label: "BOTMEUR",
    value: "29013",
    type: "common",
  },
  {
    label: "SAINT-RIVOAL",
    value: "29261",
    type: "common",
  },
  {
    label: "SIZUN",
    value: "29277",
    type: "common",
  },
  {
    label: "SAINT-ELOY",
    value: "29246",
    type: "common",
  },
  {
    label: "HANVEC",
    value: "29078",
    type: "common",
  },
  {
    label: "IRVILLAC",
    value: "29086",
    type: "common",
  },
  {
    label: "DAOULAS",
    value: "29043",
    type: "common",
  },
  {
    label: "LOGONNA-DAOULAS",
    value: "29137",
    type: "common",
  },
  {
    label: "PLOUGASTEL-DAOULAS",
    value: "29189",
    type: "common",
  },
  {
    label: "ROSCANVEL",
    value: "29238",
    type: "common",
  },
  {
    label: "OBENHEIM",
    value: "67338",
    type: "common",
  },
  {
    label: "GERSTHEIM",
    value: "67154",
    type: "common",
  },
  {
    label: "DAUBENSAND",
    value: "67086",
    type: "common",
  },
  {
    label: "BOOFZHEIM",
    value: "67055",
    type: "common",
  },
  {
    label: "SAND",
    value: "67433",
    type: "common",
  },
  {
    label: "MATZENHEIM",
    value: "67285",
    type: "common",
  },
  {
    label: "HUTTENHEIM",
    value: "67216",
    type: "common",
  },
  {
    label: "HERBSHEIM",
    value: "67192",
    type: "common",
  },
  {
    label: "BENFELD",
    value: "67028",
    type: "common",
  },
  {
    label: "STOTZHEIM",
    value: "67481",
    type: "common",
  },
  {
    label: "SERMERSHEIM",
    value: "67464",
    type: "common",
  },
  {
    label: "KERTZFELD",
    value: "67233",
    type: "common",
  },
  {
    label: "SAINT-PIERRE",
    value: "67429",
    type: "common",
  },
  {
    label: "NOTHALTEN",
    value: "67337",
    type: "common",
  },
  {
    label: "MITTELBERGHEIM",
    value: "67295",
    type: "common",
  },
  {
    label: "ITTERSWILLER",
    value: "67227",
    type: "common",
  },
  {
    label: "EPFIG",
    value: "67125",
    type: "common",
  },
  {
    label: "EICHHOFFEN",
    value: "67120",
    type: "common",
  },
  {
    label: "BLIENSCHWILLER",
    value: "67051",
    type: "common",
  },
  {
    label: "BERNARDVILLE",
    value: "67032",
    type: "common",
  },
  {
    label: "TRIEMBACH-AU-VAL",
    value: "67493",
    type: "common",
  },
  {
    label: "REICHSFELD",
    value: "67387",
    type: "common",
  },
  {
    label: "ANDLAU",
    value: "67010",
    type: "common",
  },
  {
    label: "ALBE",
    value: "67003",
    type: "common",
  },
  {
    label: "BELLEFOSSE",
    value: "67026",
    type: "common",
  },
  {
    label: "STEIGE",
    value: "67477",
    type: "common",
  },
  {
    label: "SAINT-MARTIN",
    value: "67426",
    type: "common",
  },
  {
    label: "MAISONSGOUTTE",
    value: "67280",
    type: "common",
  },
  {
    label: "BREITENBACH",
    value: "67063",
    type: "common",
  },
  {
    label: "SAINT-BLAISE-LA-ROCHE",
    value: "67424",
    type: "common",
  },
  {
    label: "RANRUPT",
    value: "67384",
    type: "common",
  },
  {
    label: "COLROY-LA-ROCHE",
    value: "67076",
    type: "common",
  },
  {
    label: "BOURG-BRUCHE",
    value: "67059",
    type: "common",
  },
  {
    label: "LE VERMONT",
    value: "88501",
    type: "common",
  },
  {
    label: "SAINT-STAIL",
    value: "88436",
    type: "common",
  },
  {
    label: "GRANDRUPT",
    value: "88215",
    type: "common",
  },
  {
    label: "BELVAL",
    value: "88053",
    type: "common",
  },
  {
    label: "SAULXURES",
    value: "67436",
    type: "common",
  },
  {
    label: "SAALES",
    value: "67421",
    type: "common",
  },
  {
    label: "MENIL-DE-SENONES",
    value: "88300",
    type: "common",
  },
  {
    label: "VIEUX-MOULIN",
    value: "88506",
    type: "common",
  },
  {
    label: "LE PUID",
    value: "88362",
    type: "common",
  },
  {
    label: "LA PETITE-RAON",
    value: "88346",
    type: "common",
  },
  {
    label: "LE MONT",
    value: "88306",
    type: "common",
  },
  {
    label: "CHATAS",
    value: "88093",
    type: "common",
  },
  {
    label: "MOYENMOUTIER",
    value: "88319",
    type: "common",
  },
  {
    label: "SENONES",
    value: "88451",
    type: "common",
  },
  {
    label: "RAON-L'ETAPE",
    value: "88372",
    type: "common",
  },
  {
    label: "ETIVAL-CLAIREFONTAINE",
    value: "88165",
    type: "common",
  },
  {
    label: "THIAVILLE-SUR-MEURTHE",
    value: "54519",
    type: "common",
  },
  {
    label: "SAINTE-BARBE",
    value: "88410",
    type: "common",
  },
  {
    label: "LACHAPELLE",
    value: "54287",
    type: "common",
  },
  {
    label: "DENEUVRE",
    value: "54154",
    type: "common",
  },
  {
    label: "NOSSONCOURT",
    value: "88333",
    type: "common",
  },
  {
    label: "MENIL-SUR-BELVITTE",
    value: "88301",
    type: "common",
  },
  {
    label: "MENARMONT",
    value: "88298",
    type: "common",
  },
  {
    label: "DONCIERES",
    value: "88156",
    type: "common",
  },
  {
    label: "BAZIEN",
    value: "88042",
    type: "common",
  },
  {
    label: "ANGLEMONT",
    value: "88008",
    type: "common",
  },
  {
    label: "XAFFEVILLERS",
    value: "88527",
    type: "common",
  },
  {
    label: "SAINT-MAURICE-SUR-MORTAGNE",
    value: "88425",
    type: "common",
  },
  {
    label: "ROVILLE-AUX-CHENES",
    value: "88402",
    type: "common",
  },
  {
    label: "HARDANCOURT",
    value: "88230",
    type: "common",
  },
  {
    label: "DEINVILLERS",
    value: "88127",
    type: "common",
  },
  {
    label: "CLEZENTAINE",
    value: "88110",
    type: "common",
  },
  {
    label: "HAILLAINVILLE",
    value: "88228",
    type: "common",
  },
  {
    label: "SAINT-BOINGT",
    value: "54471",
    type: "common",
  },
  {
    label: "ESSEY-LA-COTE",
    value: "54183",
    type: "common",
  },
  {
    label: "DAMAS-AUX-BOIS",
    value: "88121",
    type: "common",
  },
  {
    label: "SAINT-REMY-AUX-BOIS",
    value: "54487",
    type: "common",
  },
  {
    label: "LOROMONTZEY",
    value: "54325",
    type: "common",
  },
  {
    label: "CHARMES",
    value: "88090",
    type: "common",
  },
  {
    label: "CHAMAGNE",
    value: "88084",
    type: "common",
  },
  {
    label: "VILLACOURT",
    value: "54567",
    type: "common",
  },
  {
    label: "SAINT-GERMAIN",
    value: "54475",
    type: "common",
  },
  {
    label: "BAINVILLE-AUX-MIROIRS",
    value: "54042",
    type: "common",
  },
  {
    label: "VAUDIGNY",
    value: "54554",
    type: "common",
  },
  {
    label: "SOCOURT",
    value: "88458",
    type: "common",
  },
  {
    label: "HERGUGNEY",
    value: "88239",
    type: "common",
  },
  {
    label: "BATTEXEY",
    value: "88038",
    type: "common",
  },
  {
    label: "XIROCOURT",
    value: "54597",
    type: "common",
  },
  {
    label: "LEBEUVILLE",
    value: "54307",
    type: "common",
  },
  {
    label: "GRIPPORT",
    value: "54238",
    type: "common",
  },
  {
    label: "GERMONVILLE",
    value: "54224",
    type: "common",
  },
  {
    label: "BRALLEVILLE",
    value: "54094",
    type: "common",
  },
  {
    label: "MARAINVILLE-SUR-MADON",
    value: "88286",
    type: "common",
  },
  {
    label: "SAXON-SION",
    value: "54497",
    type: "common",
  },
  {
    label: "SAINT-FIRMIN",
    value: "54473",
    type: "common",
  },
  {
    label: "PRAYE",
    value: "54434",
    type: "common",
  },
  {
    label: "JEVONCOURT",
    value: "54278",
    type: "common",
  },
  {
    label: "HOUSSEVILLE",
    value: "54268",
    type: "common",
  },
  {
    label: "FORCELLES-SOUS-GUGNEY",
    value: "54204",
    type: "common",
  },
  {
    label: "DIARVILLE",
    value: "54156",
    type: "common",
  },
  {
    label: "VAUDEMONT",
    value: "54552",
    type: "common",
  },
  {
    label: "THOREY-LYAUTEY",
    value: "54522",
    type: "common",
  },
  {
    label: "THEY-SOUS-VAUDEMONT",
    value: "54516",
    type: "common",
  },
  {
    label: "PULNEY",
    value: "54438",
    type: "common",
  },
  {
    label: "GUGNEY",
    value: "54241",
    type: "common",
  },
  {
    label: "FECOCOURT",
    value: "54190",
    type: "common",
  },
  {
    label: "DOMMARIE-EULMONT",
    value: "54164",
    type: "common",
  },
  {
    label: "CHAOUILLEY",
    value: "54117",
    type: "common",
  },
  {
    label: "VANDELEVILLE",
    value: "54545",
    type: "common",
  },
  {
    label: "TRAMONT-SAINT-ANDRE",
    value: "54531",
    type: "common",
  },
  {
    label: "TRAMONT-LASSUS",
    value: "54530",
    type: "common",
  },
  {
    label: "TRAMONT-EMY",
    value: "54529",
    type: "common",
  },
  {
    label: "BATTIGNY",
    value: "54052",
    type: "common",
  },
  {
    label: "TRANQUEVILLE-GRAUX",
    value: "88478",
    type: "common",
  },
  {
    label: "ATTIGNEVILLE",
    value: "88015",
    type: "common",
  },
  {
    label: "AROFFE",
    value: "88013",
    type: "common",
  },
  {
    label: "GEMONVILLE",
    value: "54220",
    type: "common",
  },
  {
    label: "SOULOSSE-SOUS-SAINT-ELOPHE",
    value: "88460",
    type: "common",
  },
  {
    label: "MARTIGNY-LES-GERBONVAUX",
    value: "88290",
    type: "common",
  },
  {
    label: "HARCHECHAMP",
    value: "88229",
    type: "common",
  },
  {
    label: "AUTIGNY-LA-TOUR",
    value: "88019",
    type: "common",
  },
  {
    label: "MONCEL-SUR-VAIR",
    value: "88305",
    type: "common",
  },
  {
    label: "MAXEY-SUR-MEUSE",
    value: "88293",
    type: "common",
  },
  {
    label: "GREUX",
    value: "88219",
    type: "common",
  },
  {
    label: "DOMREMY-LA-PUCELLE",
    value: "88154",
    type: "common",
  },
  {
    label: "COUSSEY",
    value: "88118",
    type: "common",
  },
  {
    label: "SERAUMONT",
    value: "88453",
    type: "common",
  },
  {
    label: "CHERMISEY",
    value: "88102",
    type: "common",
  },
  {
    label: "VAUDEVILLE-LE-HAUT",
    value: "55534",
    type: "common",
  },
  {
    label: "AVRANVILLE",
    value: "88025",
    type: "common",
  },
  {
    label: "DAINVILLE-BERTHELEVILLE",
    value: "55142",
    type: "common",
  },
  {
    label: "CIRFONTAINES-EN-ORNOIS",
    value: "52131",
    type: "common",
  },
  {
    label: "LEZEVILLE",
    value: "52288",
    type: "common",
  },
  {
    label: "CHASSEY-BEAUPRE",
    value: "55104",
    type: "common",
  },
  {
    label: "THONNANCE-LES-MOULINS",
    value: "52491",
    type: "common",
  },
  {
    label: "GERMAY",
    value: "52218",
    type: "common",
  },
  {
    label: "ECHENAY",
    value: "52181",
    type: "common",
  },
  {
    label: "MONTREUIL-SUR-THONNANCE",
    value: "52337",
    type: "common",
  },
  {
    label: "SAILLY",
    value: "52443",
    type: "common",
  },
  {
    label: "POISSONS",
    value: "52398",
    type: "common",
  },
  {
    label: "AINGOULAINCOURT",
    value: "52004",
    type: "common",
  },
  {
    label: "THONNANCE-LES-JOINVILLE",
    value: "52490",
    type: "common",
  },
  {
    label: "VECQUEVILLE",
    value: "52512",
    type: "common",
  },
  {
    label: "JOINVILLE",
    value: "52250",
    type: "common",
  },
  {
    label: "AUTIGNY-LE-GRAND",
    value: "52029",
    type: "common",
  },
  {
    label: "RUPT",
    value: "52442",
    type: "common",
  },
  {
    label: "SUZANNECOURT",
    value: "52484",
    type: "common",
  },
  {
    label: "FAYS",
    value: "52198",
    type: "common",
  },
  {
    label: "CHATONRUPT-SOMMERMONT",
    value: "52118",
    type: "common",
  },
  {
    label: "MATHONS",
    value: "52316",
    type: "common",
  },
  {
    label: "NOMECOURT",
    value: "52356",
    type: "common",
  },
  {
    label: "GUINDRECOURT-AUX-ORMES",
    value: "52231",
    type: "common",
  },
  {
    label: "RACHECOURT-SUZEMONT",
    value: "52413",
    type: "common",
  },
  {
    label: "DOMMARTIN-LE-FRANC",
    value: "52171",
    type: "common",
  },
  {
    label: "VAUX-SUR-BLAISE",
    value: "52510",
    type: "common",
  },
  {
    label: "VILLE-EN-BLAISOIS",
    value: "52528",
    type: "common",
  },
  {
    label: "MONTREUIL-SUR-BLAISE",
    value: "52336",
    type: "common",
  },
  {
    label: "MORANCOURT",
    value: "52341",
    type: "common",
  },
  {
    label: "DOULEVANT-LE-PETIT",
    value: "52179",
    type: "common",
  },
  {
    label: "DOMBLAIN",
    value: "52169",
    type: "common",
  },
  {
    label: "MERTRUD",
    value: "52321",
    type: "common",
  },
  {
    label: "BAILLY-AUX-FORGES",
    value: "52034",
    type: "common",
  },
  {
    label: "LA PORTE DU DER",
    value: "52331",
    type: "common",
  },
  {
    label: "THILLEUX",
    value: "52487",
    type: "common",
  },
  {
    label: "CEFFONDS",
    value: "52088",
    type: "common",
  },
  {
    label: "VILLERET",
    value: "10424",
    type: "common",
  },
  {
    label: "MAIZIERES-LES-BRIENNE",
    value: "10221",
    type: "common",
  },
  {
    label: "HAMPIGNY",
    value: "10171",
    type: "common",
  },
  {
    label: "VALLENTIGNY",
    value: "10393",
    type: "common",
  },
  {
    label: "RANCES",
    value: "10315",
    type: "common",
  },
  {
    label: "PERTHES-LES-BRIENNE",
    value: "10285",
    type: "common",
  },
  {
    label: "YEVRES-LE-PETIT",
    value: "10445",
    type: "common",
  },
  {
    label: "ROSNAY-L'HOPITAL",
    value: "10326",
    type: "common",
  },
  {
    label: "LASSICOURT",
    value: "10189",
    type: "common",
  },
  {
    label: "COURCELLES-SUR-VOIRE",
    value: "10105",
    type: "common",
  },
  {
    label: "BLIGNICOURT",
    value: "10047",
    type: "common",
  },
  {
    label: "BRAUX",
    value: "10059",
    type: "common",
  },
  {
    label: "CHALETTE-SUR-VOIRE",
    value: "10073",
    type: "common",
  },
  {
    label: "AULNAY",
    value: "10017",
    type: "common",
  },
  {
    label: "SAINT-CHRISTOPHE-DODINICOURT",
    value: "10337",
    type: "common",
  },
  {
    label: "BETIGNICOURT",
    value: "10044",
    type: "common",
  },
  {
    label: "BRILLECOURT",
    value: "10065",
    type: "common",
  },
  {
    label: "VERRICOURT",
    value: "10405",
    type: "common",
  },
  {
    label: "COCLOIS",
    value: "10101",
    type: "common",
  },
  {
    label: "POUGY",
    value: "10300",
    type: "common",
  },
  {
    label: "MAGNICOURT",
    value: "10214",
    type: "common",
  },
  {
    label: "MOLINS-SUR-AUBE",
    value: "10243",
    type: "common",
  },
  {
    label: "AVANT-LES-RAMERUPT",
    value: "10021",
    type: "common",
  },
  {
    label: "MESNIL-LETTRE",
    value: "10236",
    type: "common",
  },
  {
    label: "NOGENT-SUR-AUBE",
    value: "10267",
    type: "common",
  },
  {
    label: "CHAUDREY",
    value: "10091",
    type: "common",
  },
  {
    label: "MONTSUZAIN",
    value: "10256",
    type: "common",
  },
  {
    label: "SAINT-REMY-SOUS-BARBUISE",
    value: "10361",
    type: "common",
  },
  {
    label: "VOUE",
    value: "10442",
    type: "common",
  },
  {
    label: "SAINT-ETIENNE-SOUS-BARBUISE",
    value: "10338",
    type: "common",
  },
  {
    label: "DROUPT-SAINT-BASLE",
    value: "10131",
    type: "common",
  },
  {
    label: "RILLY-SAINTE-SYRE",
    value: "10320",
    type: "common",
  },
  {
    label: "LES GRANDES-CHAPELLES",
    value: "10166",
    type: "common",
  },
  {
    label: "VALLANT-SAINT-GEORGES",
    value: "10392",
    type: "common",
  },
  {
    label: "MESGRIGNY",
    value: "10234",
    type: "common",
  },
  {
    label: "ORVILLIERS-SAINT-JULIEN",
    value: "10274",
    type: "common",
  },
  {
    label: "ORIGNY-LE-SEC",
    value: "10271",
    type: "common",
  },
  {
    label: "CHATRES",
    value: "10089",
    type: "common",
  },
  {
    label: "OSSEY-LES-TROIS-MAISONS",
    value: "10275",
    type: "common",
  },
  {
    label: "SAINT-MARTIN-DE-BOSSENAY",
    value: "10351",
    type: "common",
  },
  {
    label: "PARS-LES-ROMILLY",
    value: "10280",
    type: "common",
  },
  {
    label: "SAINT-LOUP-DE-BUFFIGNY",
    value: "10347",
    type: "common",
  },
  {
    label: "LA FOSSE-CORDUAN",
    value: "10157",
    type: "common",
  },
  {
    label: "GELANNES",
    value: "10164",
    type: "common",
  },
  {
    label: "FERREUX-QUINCEY",
    value: "10148",
    type: "common",
  },
  {
    label: "SAINT-AUBIN",
    value: "10334",
    type: "common",
  },
  {
    label: "LA MOTTE-TILLY",
    value: "10259",
    type: "common",
  },
  {
    label: "FONTENAY-DE-BOSSERY",
    value: "10154",
    type: "common",
  },
  {
    label: "FONTAINE-MACON",
    value: "10153",
    type: "common",
  },
  {
    label: "COURCEROY",
    value: "10106",
    type: "common",
  },
  {
    label: "VILLIERS-SUR-SEINE",
    value: "77522",
    type: "common",
  },
  {
    label: "NOYEN-SUR-SEINE",
    value: "77341",
    type: "common",
  },
  {
    label: "HERME",
    value: "77227",
    type: "common",
  },
  {
    label: "GOUAIX",
    value: "77208",
    type: "common",
  },
  {
    label: "EVERLY",
    value: "77174",
    type: "common",
  },
  {
    label: "CHALMAISON",
    value: "77076",
    type: "common",
  },
  {
    label: "VIMPELLES",
    value: "77524",
    type: "common",
  },
  {
    label: "THENISY",
    value: "77461",
    type: "common",
  },
  {
    label: "SIGY",
    value: "77452",
    type: "common",
  },
  {
    label: "PAROY",
    value: "77355",
    type: "common",
  },
  {
    label: "LES ORMES-SUR-VOULZIE",
    value: "77347",
    type: "common",
  },
  {
    label: "LUISETAINES",
    value: "77263",
    type: "common",
  },
  {
    label: "JUTIGNY",
    value: "77242",
    type: "common",
  },
  {
    label: "MONS-EN-MONTOIS",
    value: "77298",
    type: "common",
  },
  {
    label: "GURCY-LE-CHATEL",
    value: "77223",
    type: "common",
  },
  {
    label: "DONNEMARIE-DONTILLY",
    value: "77159",
    type: "common",
  },
  {
    label: "CESSOY-EN-MONTOIS",
    value: "77068",
    type: "common",
  },
  {
    label: "VILLENEUVE-LES-BORDES",
    value: "77509",
    type: "common",
  },
  {
    label: "MONTIGNY-LENCOUP",
    value: "77311",
    type: "common",
  },
  {
    label: "COUTENCON",
    value: "77140",
    type: "common",
  },
  {
    label: "LAVAL-EN-BRIE",
    value: "77245",
    type: "common",
  },
  {
    label: "ECHOUBOULAINS",
    value: "77164",
    type: "common",
  },
  {
    label: "PAMFOU",
    value: "77354",
    type: "common",
  },
  {
    label: "LES ECRENNES",
    value: "77165",
    type: "common",
  },
  {
    label: "SAMOIS-SUR-SEINE",
    value: "77441",
    type: "common",
  },
  {
    label: "FONTAINE-LE-PORT",
    value: "77188",
    type: "common",
  },
  {
    label: "FERICY",
    value: "77179",
    type: "common",
  },
  {
    label: "LE CHATELET-EN-BRIE",
    value: "77100",
    type: "common",
  },
  {
    label: "LIVRY-SUR-SEINE",
    value: "77255",
    type: "common",
  },
  {
    label: "CHARTRETTES",
    value: "77096",
    type: "common",
  },
  {
    label: "BOIS-LE-ROI",
    value: "77037",
    type: "common",
  },
  {
    label: "VILLIERS-EN-BIERE",
    value: "77518",
    type: "common",
  },
  {
    label: "PERTHES",
    value: "77359",
    type: "common",
  },
  {
    label: "CHAILLY-EN-BIERE",
    value: "77069",
    type: "common",
  },
  {
    label: "BOISSISE-LE-ROI",
    value: "77040",
    type: "common",
  },
  {
    label: "BARBIZON",
    value: "77022",
    type: "common",
  },
  {
    label: "SOISY-SUR-ECOLE",
    value: "91599",
    type: "common",
  },
  {
    label: "NAINVILLE-LES-ROCHES",
    value: "91441",
    type: "common",
  },
  {
    label: "DANNEMOIS",
    value: "91195",
    type: "common",
  },
  {
    label: "SAINT-SAUVEUR-SUR-ECOLE",
    value: "77435",
    type: "common",
  },
  {
    label: "SAINT-GERMAIN-SUR-ECOLE",
    value: "77412",
    type: "common",
  },
  {
    label: "CELY",
    value: "77065",
    type: "common",
  },
  {
    label: "VIDELLES",
    value: "91654",
    type: "common",
  },
  {
    label: "MONDEVILLE",
    value: "91412",
    type: "common",
  },
  {
    label: "GUIGNEVILLE-SUR-ESSONNE",
    value: "91293",
    type: "common",
  },
  {
    label: "LA FERTE-ALAIS",
    value: "91232",
    type: "common",
  },
  {
    label: "BAULNE",
    value: "91047",
    type: "common",
  },
  {
    label: "ORVEAU",
    value: "91473",
    type: "common",
  },
  {
    label: "D'HUISON-LONGUEVILLE",
    value: "91198",
    type: "common",
  },
  {
    label: "CERNY",
    value: "91129",
    type: "common",
  },
  {
    label: "BOISSY-LE-CUTTE",
    value: "91080",
    type: "common",
  },
  {
    label: "VILLENEUVE-SUR-AUVERS",
    value: "91671",
    type: "common",
  },
  {
    label: "JANVILLE-SUR-JUINE",
    value: "91318",
    type: "common",
  },
  {
    label: "AUVERS-SAINT-GEORGES",
    value: "91038",
    type: "common",
  },
  {
    label: "VILLECONIN",
    value: "91662",
    type: "common",
  },
  {
    label: "ETRECHY",
    value: "91226",
    type: "common",
  },
  {
    label: "BRIERES-LES-SCELLES",
    value: "91109",
    type: "common",
  },
  {
    label: "LES GRANGES-LE-ROI",
    value: "91284",
    type: "common",
  },
  {
    label: "LA FORET-LE-ROI",
    value: "91247",
    type: "common",
  },
  {
    label: "BOUTERVILLIERS",
    value: "91098",
    type: "common",
  },
  {
    label: "BOISSY-LE-SEC",
    value: "91081",
    type: "common",
  },
  {
    label: "RICHARVILLE",
    value: "91519",
    type: "common",
  },
  {
    label: "CORBREUSE",
    value: "91175",
    type: "common",
  },
  {
    label: "CHATIGNONVILLE",
    value: "91145",
    type: "common",
  },
  {
    label: "AUTHON-LA-PLAINE",
    value: "91035",
    type: "common",
  },
  {
    label: "PARAY-DOUAVILLE",
    value: "78478",
    type: "common",
  },
  {
    label: "ORSONVILLE",
    value: "78472",
    type: "common",
  },
  {
    label: "BOINVILLE-LE-GAILLARD",
    value: "78071",
    type: "common",
  },
  {
    label: "ALLAINVILLE",
    value: "78009",
    type: "common",
  },
  {
    label: "LEVAINVILLE",
    value: "28208",
    type: "common",
  },
  {
    label: "AUNEAU-BLEURY-SAINT-SYMPHORIEN",
    value: "28015",
    type: "common",
  },
  {
    label: "UMPEAU",
    value: "28397",
    type: "common",
  },
  {
    label: "OINVILLE-SOUS-AUNEAU",
    value: "28285",
    type: "common",
  },
  {
    label: "LE GUE-DE-LONGROI",
    value: "28188",
    type: "common",
  },
  {
    label: "NOGENT-LE-PHAYE",
    value: "28278",
    type: "common",
  },
  {
    label: "HOUVILLE-LA-BRANCHE",
    value: "28194",
    type: "common",
  },
  {
    label: "COLTAINVILLE",
    value: "28104",
    type: "common",
  },
  {
    label: "CHAMPSERU",
    value: "28073",
    type: "common",
  },
  {
    label: "SAINT-PREST",
    value: "28358",
    type: "common",
  },
  {
    label: "GASVILLE-OISEME",
    value: "28173",
    type: "common",
  },
  {
    label: "CHARTRES",
    value: "28085",
    type: "common",
  },
  {
    label: "CHAMPHOL",
    value: "28070",
    type: "common",
  },
  {
    label: "MAINVILLIERS",
    value: "28229",
    type: "common",
  },
  {
    label: "LEVES",
    value: "28209",
    type: "common",
  },
  {
    label: "BAILLEAU-L'EVEQUE",
    value: "28022",
    type: "common",
  },
  {
    label: "AMILLY",
    value: "28006",
    type: "common",
  },
  {
    label: "SAINT-AUBIN-DES-BOIS",
    value: "28325",
    type: "common",
  },
  {
    label: "MITTAINVILLIERS-VERIGNY",
    value: "28254",
    type: "common",
  },
  {
    label: "FONTAINE-LA-GUYON",
    value: "28154",
    type: "common",
  },
  {
    label: "CINTRAY",
    value: "28100",
    type: "common",
  },
  {
    label: "SAINT-ARNOULT-DES-BOIS",
    value: "28324",
    type: "common",
  },
  {
    label: "COURVILLE-SUR-EURE",
    value: "28116",
    type: "common",
  },
  {
    label: "BILLANCELLES",
    value: "28040",
    type: "common",
  },
  {
    label: "PONTGOUIN",
    value: "28302",
    type: "common",
  },
  {
    label: "LANDELLES",
    value: "28203",
    type: "common",
  },
  {
    label: "SAINT-MAURICE-SAINT-GERMAIN",
    value: "28354",
    type: "common",
  },
  {
    label: "SAINT-ELIPH",
    value: "28335",
    type: "common",
  },
  {
    label: "LE FAVRIL",
    value: "28148",
    type: "common",
  },
  {
    label: "VAUPILLON",
    value: "28401",
    type: "common",
  },
  {
    label: "MEAUCE",
    value: "28240",
    type: "common",
  },
  {
    label: "LA LOUPE",
    value: "28214",
    type: "common",
  },
  {
    label: "LE PAS-SAINT-L'HOMER",
    value: "61323",
    type: "common",
  },
  {
    label: "MOUTIERS-AU-PERCHE",
    value: "61300",
    type: "common",
  },
  {
    label: "LA MADELEINE-BOUVET",
    value: "61241",
    type: "common",
  },
  {
    label: "BRETONCELLES",
    value: "61061",
    type: "common",
  },
  {
    label: "REMALARD EN PERCHE",
    value: "61345",
    type: "common",
  },
  {
    label: "COUR-MAUGIS SUR HUISNE",
    value: "61050",
    type: "common",
  },
  {
    label: "BIZOU",
    value: "61046",
    type: "common",
  },
  {
    label: "MAUVES-SUR-HUISNE",
    value: "61255",
    type: "common",
  },
  {
    label: "COURGEON",
    value: "61129",
    type: "common",
  },
  {
    label: "CORBON",
    value: "61118",
    type: "common",
  },
  {
    label: "COMBLOT",
    value: "61113",
    type: "common",
  },
  {
    label: "LA CHAPELLE-MONTLIGEON",
    value: "61097",
    type: "common",
  },
  {
    label: "SAINT-DENIS-SUR-HUISNE",
    value: "61381",
    type: "common",
  },
  {
    label: "REVEILLON",
    value: "61348",
    type: "common",
  },
  {
    label: "LE PIN-LA-GARENNE",
    value: "61329",
    type: "common",
  },
  {
    label: "PARFONDEVAL",
    value: "61322",
    type: "common",
  },
  {
    label: "SAINT-QUENTIN-DE-BLAVOU",
    value: "61450",
    type: "common",
  },
  {
    label: "SAINT-JOUIN-DE-BLAVOU",
    value: "61411",
    type: "common",
  },
  {
    label: "COULIMER",
    value: "61121",
    type: "common",
  },
  {
    label: "BLEVES",
    value: "72037",
    type: "common",
  },
  {
    label: "LES AULNEAUX",
    value: "72015",
    type: "common",
  },
  {
    label: "VIDAI",
    value: "61502",
    type: "common",
  },
  {
    label: "PERVENCHERES",
    value: "61327",
    type: "common",
  },
  {
    label: "BARVILLE",
    value: "61026",
    type: "common",
  },
  {
    label: "VILLENEUVE-EN-PERSEIGNE",
    value: "72137",
    type: "common",
  },
  {
    label: "LE MENIL-BROUT",
    value: "61261",
    type: "common",
  },
  {
    label: "CHENAY",
    value: "72076",
    type: "common",
  },
  {
    label: "SEMALLE",
    value: "61467",
    type: "common",
  },
  {
    label: "HAUTERIVE",
    value: "61202",
    type: "common",
  },
  {
    label: "VALFRAMBERT",
    value: "61497",
    type: "common",
  },
  {
    label: "DAMIGNY",
    value: "61143",
    type: "common",
  },
  {
    label: "CERISE",
    value: "61077",
    type: "common",
  },
  {
    label: "ALENCON",
    value: "61001",
    type: "common",
  },
  {
    label: "PACE",
    value: "61321",
    type: "common",
  },
  {
    label: "LONRAI",
    value: "61234",
    type: "common",
  },
  {
    label: "CUISSAI",
    value: "61141",
    type: "common",
  },
  {
    label: "CONDE-SUR-SARTHE",
    value: "61117",
    type: "common",
  },
  {
    label: "COLOMBIERS",
    value: "61111",
    type: "common",
  },
  {
    label: "SAINT-DENIS-SUR-SARTHON",
    value: "61382",
    type: "common",
  },
  {
    label: "GANDELAIN",
    value: "61182",
    type: "common",
  },
  {
    label: "RAVIGNY",
    value: "53187",
    type: "common",
  },
  {
    label: "CHAMPFREMONT",
    value: "53052",
    type: "common",
  },
  {
    label: "LALACELLE",
    value: "61213",
    type: "common",
  },
  {
    label: "PRE-EN-PAIL-SAINT-SAMSON",
    value: "53185",
    type: "common",
  },
  {
    label: "BOULAY-LES-IFS",
    value: "53038",
    type: "common",
  },
  {
    label: "SAINT-CYR-EN-PAIL",
    value: "53208",
    type: "common",
  },
  {
    label: "SAINT-AIGNAN-DE-COUPTRAIN",
    value: "53196",
    type: "common",
  },
  {
    label: "MADRE",
    value: "53142",
    type: "common",
  },
  {
    label: "JAVRON-LES-CHAPELLES",
    value: "53121",
    type: "common",
  },
  {
    label: "CHEVAIGNE-DU-MAINE",
    value: "53069",
    type: "common",
  },
  {
    label: "SAINTE-MARIE-DU-BOIS",
    value: "53235",
    type: "common",
  },
  {
    label: "LASSAY-LES-CHATEAUX",
    value: "53127",
    type: "common",
  },
  {
    label: "LE HOUSSEAU-BRETIGNOLLES",
    value: "53118",
    type: "common",
  },
  {
    label: "AMBRIERES-LES-VALLEES",
    value: "53003",
    type: "common",
  },
  {
    label: "CHANTRIGNE",
    value: "53055",
    type: "common",
  },
  {
    label: "LE PAS",
    value: "53176",
    type: "common",
  },
  {
    label: "COUESMES-VAUCE",
    value: "53079",
    type: "common",
  },
  {
    label: "LESBOIS",
    value: "53131",
    type: "common",
  },
  {
    label: "GORRON",
    value: "53107",
    type: "common",
  },
  {
    label: "VIEUVY",
    value: "53270",
    type: "common",
  },
  {
    label: "SAINT-AUBIN-FOSSE-LOUVAIN",
    value: "53199",
    type: "common",
  },
  {
    label: "LEVARE",
    value: "53132",
    type: "common",
  },
  {
    label: "HERCE",
    value: "53115",
    type: "common",
  },
  {
    label: "SAINT-BERTHEVIN-LA-TANNIERE",
    value: "53202",
    type: "common",
  },
  {
    label: "LA DOREE",
    value: "53093",
    type: "common",
  },
  {
    label: "SAINT-MARS-SUR-LA-FUTAIE",
    value: "53238",
    type: "common",
  },
  {
    label: "SAINT-ELLIER-DU-MAINE",
    value: "53213",
    type: "common",
  },
  {
    label: "PONTMAIN",
    value: "53181",
    type: "common",
  },
  {
    label: "LANDEAN",
    value: "35142",
    type: "common",
  },
  {
    label: "LA BAZOUGE-DU-DESERT",
    value: "35018",
    type: "common",
  },
  {
    label: "PARIGNE",
    value: "35215",
    type: "common",
  },
  {
    label: "LE CHATELLIER",
    value: "35071",
    type: "common",
  },
  {
    label: "LES PORTES DU COGLAIS",
    value: "35191",
    type: "common",
  },
  {
    label: "MAEN ROCH",
    value: "35257",
    type: "common",
  },
  {
    label: "RIMOU",
    value: "35242",
    type: "common",
  },
  {
    label: "NOYAL-SOUS-BAZOUGES",
    value: "35205",
    type: "common",
  },
  {
    label: "SAINT-LEGER-DES-PRES",
    value: "35286",
    type: "common",
  },
  {
    label: "LANRIGAN",
    value: "35148",
    type: "common",
  },
  {
    label: "CUGUEN",
    value: "35092",
    type: "common",
  },
  {
    label: "COMBOURG",
    value: "35085",
    type: "common",
  },
  {
    label: "PLEUGUENEUC",
    value: "35226",
    type: "common",
  },
  {
    label: "MEILLAC",
    value: "35172",
    type: "common",
  },
  {
    label: "LA CHAPELLE-AUX-FILTZMEENS",
    value: "35056",
    type: "common",
  },
  {
    label: "EVRAN",
    value: "22056",
    type: "common",
  },
  {
    label: "TREVERIEN",
    value: "35345",
    type: "common",
  },
  {
    label: "PLESDER",
    value: "35225",
    type: "common",
  },
  {
    label: "SAINT-ANDRE-DES-EAUX",
    value: "22274",
    type: "common",
  },
  {
    label: "SAINT-CARNE",
    value: "22280",
    type: "common",
  },
  {
    label: "CALORGUEN",
    value: "22026",
    type: "common",
  },
  {
    label: "TREVRON",
    value: "22380",
    type: "common",
  },
  {
    label: "LE HINGLE",
    value: "22082",
    type: "common",
  },
  {
    label: "BRUSVILY",
    value: "22021",
    type: "common",
  },
  {
    label: "BOBITAL",
    value: "22008",
    type: "common",
  },
  {
    label: "TREDIAS",
    value: "22348",
    type: "common",
  },
  {
    label: "TREBEDAN",
    value: "22342",
    type: "common",
  },
  {
    label: "LANGUEDIAS",
    value: "22104",
    type: "common",
  },
  {
    label: "MEGRIT",
    value: "22145",
    type: "common",
  },
  {
    label: "JUGON-LES-LACS - COMMUNE NOUVELLE",
    value: "22084",
    type: "common",
  },
  {
    label: "TRAMAIN",
    value: "22341",
    type: "common",
  },
  {
    label: "PLENEE-JUGON",
    value: "22185",
    type: "common",
  },
  {
    label: "PENGUILY",
    value: "22165",
    type: "common",
  },
  {
    label: "LA MALHOURE",
    value: "22140",
    type: "common",
  },
  {
    label: "TREBRY",
    value: "22345",
    type: "common",
  },
  {
    label: "SAINT-TRIMOEL",
    value: "22332",
    type: "common",
  },
  {
    label: "SAINT-GLEN",
    value: "22296",
    type: "common",
  },
  {
    label: "BREHAND",
    value: "22015",
    type: "common",
  },
  {
    label: "TREDANIEL",
    value: "22346",
    type: "common",
  },
  {
    label: "MONCONTOUR",
    value: "22153",
    type: "common",
  },
  {
    label: "HENON",
    value: "22079",
    type: "common",
  },
  {
    label: "PLŒUC-L'HERMITAGE",
    value: "22203",
    type: "common",
  },
  {
    label: "LANFAINS",
    value: "22099",
    type: "common",
  },
  {
    label: "SAINT-BIHY",
    value: "22276",
    type: "common",
  },
  {
    label: "LA HARMOYE",
    value: "22073",
    type: "common",
  },
  {
    label: "LE HAUT-CORLAY",
    value: "22074",
    type: "common",
  },
  {
    label: "SAINT-GILLES-PLIGEAUX",
    value: "22294",
    type: "common",
  },
  {
    label: "CANIHUEL",
    value: "22029",
    type: "common",
  },
  {
    label: "SAINT-NICOLAS-DU-PELEM",
    value: "22321",
    type: "common",
  },
  {
    label: "TREMARGAT",
    value: "22365",
    type: "common",
  },
  {
    label: "PEUMERIT-QUINTIN",
    value: "22169",
    type: "common",
  },
  {
    label: "LANRIVAIN",
    value: "22115",
    type: "common",
  },
  {
    label: "SAINT-NICODEME",
    value: "22320",
    type: "common",
  },
  {
    label: "DUAULT",
    value: "22052",
    type: "common",
  },
  {
    label: "LOCARN",
    value: "22128",
    type: "common",
  },
  {
    label: "TREBRIVAN",
    value: "22344",
    type: "common",
  },
  {
    label: "PLOUNEVEZEL",
    value: "29205",
    type: "common",
  },
  {
    label: "PLOUYE",
    value: "29211",
    type: "common",
  },
  {
    label: "COLLOREC",
    value: "29036",
    type: "common",
  },
  {
    label: "LOQUEFFRET",
    value: "29141",
    type: "common",
  },
  {
    label: "LANNEDERN",
    value: "29115",
    type: "common",
  },
  {
    label: "BRASPARTS",
    value: "29016",
    type: "common",
  },
  {
    label: "LOPEREC",
    value: "29139",
    type: "common",
  },
  {
    label: "PONT-DE-BUIS-LES-QUIMERCH",
    value: "29302",
    type: "common",
  },
  {
    label: "LE FAOU",
    value: "29053",
    type: "common",
  },
  {
    label: "ROSNOEN",
    value: "29240",
    type: "common",
  },
  {
    label: "HOPITAL-CAMFROUT",
    value: "29080",
    type: "common",
  },
  {
    label: "LANDEVENNEC",
    value: "29104",
    type: "common",
  },
  {
    label: "ARGOL",
    value: "29001",
    type: "common",
  },
  {
    label: "LANVEOC",
    value: "29120",
    type: "common",
  },
  {
    label: "CAMARET-SUR-MER",
    value: "29022",
    type: "common",
  },
  {
    label: "RHINAU",
    value: "67397",
    type: "common",
  },
  {
    label: "WITTERNHEIM",
    value: "67545",
    type: "common",
  },
  {
    label: "ROSSFELD",
    value: "67412",
    type: "common",
  },
  {
    label: "HILSENHEIM",
    value: "67196",
    type: "common",
  },
  {
    label: "FRIESENHEIM",
    value: "67146",
    type: "common",
  },
  {
    label: "DIEBOLSHEIM",
    value: "67090",
    type: "common",
  },
  {
    label: "BINDERNHEIM",
    value: "67040",
    type: "common",
  },
  {
    label: "KOGENHEIM",
    value: "67246",
    type: "common",
  },
  {
    label: "EBERSMUNSTER",
    value: "67116",
    type: "common",
  },
  {
    label: "EBERSHEIM",
    value: "67115",
    type: "common",
  },
  {
    label: "SCHERWILLER",
    value: "67445",
    type: "common",
  },
  {
    label: "DIEFFENTHAL",
    value: "67094",
    type: "common",
  },
  {
    label: "DAMBACH-LA-VILLE",
    value: "67084",
    type: "common",
  },
  {
    label: "THANVILLE",
    value: "67490",
    type: "common",
  },
  {
    label: "SAINT-PIERRE-BOIS",
    value: "67430",
    type: "common",
  },
  {
    label: "SAINT-MAURICE",
    value: "67427",
    type: "common",
  },
  {
    label: "NEUVE-EGLISE",
    value: "67320",
    type: "common",
  },
  {
    label: "NEUBOIS",
    value: "67317",
    type: "common",
  },
  {
    label: "DIEFFENBACH-AU-VAL",
    value: "67092",
    type: "common",
  },
  {
    label: "CHATENOIS",
    value: "67073",
    type: "common",
  },
  {
    label: "ROMBACH-LE-FRANC",
    value: "68283",
    type: "common",
  },
  {
    label: "VILLE",
    value: "67507",
    type: "common",
  },
  {
    label: "LALAYE",
    value: "67255",
    type: "common",
  },
  {
    label: "FOUCHY",
    value: "67143",
    type: "common",
  },
  {
    label: "BREITENAU",
    value: "67062",
    type: "common",
  },
  {
    label: "BASSEMBERG",
    value: "67022",
    type: "common",
  },
  {
    label: "LUBINE",
    value: "88275",
    type: "common",
  },
  {
    label: "URBEIS",
    value: "67499",
    type: "common",
  },
  {
    label: "FRAPELLE",
    value: "88182",
    type: "common",
  },
  {
    label: "LA GRANDE-FOSSE",
    value: "88213",
    type: "common",
  },
  {
    label: "PROVENCHERES-ET-COLROY",
    value: "88361",
    type: "common",
  },
  {
    label: "LA PETITE-FOSSE",
    value: "88345",
    type: "common",
  },
  {
    label: "LE BEULAY",
    value: "88057",
    type: "common",
  },
  {
    label: "SAINT-JEAN-D'ORMONT",
    value: "88419",
    type: "common",
  },
  {
    label: "BAN-DE-SAPT",
    value: "88033",
    type: "common",
  },
  {
    label: "DENIPAIRE",
    value: "88128",
    type: "common",
  },
  {
    label: "SAINT-DIE-DES-VOSGES",
    value: "88413",
    type: "common",
  },
  {
    label: "HURBACHE",
    value: "88245",
    type: "common",
  },
  {
    label: "SAINT-MICHEL-SUR-MEURTHE",
    value: "88428",
    type: "common",
  },
  {
    label: "LA VOIVRE",
    value: "88519",
    type: "common",
  },
  {
    label: "LA SALLE",
    value: "88438",
    type: "common",
  },
  {
    label: "SAINT-REMY",
    value: "88435",
    type: "common",
  },
  {
    label: "NOMPATELIZE",
    value: "88328",
    type: "common",
  },
  {
    label: "SAINT-BENOIT-LA-CHIPOTTE",
    value: "88412",
    type: "common",
  },
  {
    label: "JEANMENIL",
    value: "88251",
    type: "common",
  },
  {
    label: "HOUSSERAS",
    value: "88243",
    type: "common",
  },
  {
    label: "VOMECOURT",
    value: "88521",
    type: "common",
  },
  {
    label: "SAINT-GORGON",
    value: "88417",
    type: "common",
  },
  {
    label: "RAMBERVILLERS",
    value: "88367",
    type: "common",
  },
  {
    label: "BRU",
    value: "88077",
    type: "common",
  },
  {
    label: "ROMONT",
    value: "88395",
    type: "common",
  },
  {
    label: "MOYEMONT",
    value: "88318",
    type: "common",
  },
  {
    label: "FAUCONCOURT",
    value: "88168",
    type: "common",
  },
  {
    label: "SAINT-GENEST",
    value: "88416",
    type: "common",
  },
  {
    label: "REHAINCOURT",
    value: "88379",
    type: "common",
  },
  {
    label: "ORTONCOURT",
    value: "88338",
    type: "common",
  },
  {
    label: "HADIGNY-LES-VERRIERES",
    value: "88224",
    type: "common",
  },
  {
    label: "PORTIEUX",
    value: "88355",
    type: "common",
  },
  {
    label: "MORIVILLE",
    value: "88313",
    type: "common",
  },
  {
    label: "VINCEY",
    value: "88513",
    type: "common",
  },
  {
    label: "VARMONZEY",
    value: "88493",
    type: "common",
  },
  {
    label: "UBEXY",
    value: "88480",
    type: "common",
  },
  {
    label: "RUGNEY",
    value: "88406",
    type: "common",
  },
  {
    label: "LANGLEY",
    value: "88260",
    type: "common",
  },
  {
    label: "FLOREMONT",
    value: "88173",
    type: "common",
  },
  {
    label: "EVAUX-ET-MENIL",
    value: "88166",
    type: "common",
  },
  {
    label: "ESSEGNEY",
    value: "88163",
    type: "common",
  },
  {
    label: "BRANTIGNY",
    value: "88073",
    type: "common",
  },
  {
    label: "XARONVAL",
    value: "88529",
    type: "common",
  },
  {
    label: "VOMECOURT-SUR-MADON",
    value: "88522",
    type: "common",
  },
  {
    label: "SAVIGNY",
    value: "88449",
    type: "common",
  },
  {
    label: "GIRCOURT-LES-VIEVILLE",
    value: "88202",
    type: "common",
  },
  {
    label: "BOUXURULLES",
    value: "88070",
    type: "common",
  },
  {
    label: "BETTONCOURT",
    value: "88056",
    type: "common",
  },
  {
    label: "AVRAINVILLE",
    value: "88024",
    type: "common",
  },
  {
    label: "PUZIEUX",
    value: "88364",
    type: "common",
  },
  {
    label: "PONT-SUR-MADON",
    value: "88354",
    type: "common",
  },
  {
    label: "FRENELLE-LA-GRANDE",
    value: "88185",
    type: "common",
  },
  {
    label: "CHAUFFECOURT",
    value: "88097",
    type: "common",
  },
  {
    label: "BOULAINCOURT",
    value: "88066",
    type: "common",
  },
  {
    label: "AMBACOURT",
    value: "88006",
    type: "common",
  },
  {
    label: "BOUZANVILLE",
    value: "54092",
    type: "common",
  },
  {
    label: "JUVAINCOURT",
    value: "88257",
    type: "common",
  },
  {
    label: "FRENELLE-LA-PETITE",
    value: "88186",
    type: "common",
  },
  {
    label: "CHEF-HAUT",
    value: "88100",
    type: "common",
  },
  {
    label: "BLEMEREY",
    value: "88060",
    type: "common",
  },
  {
    label: "GRIMONVILLER",
    value: "54237",
    type: "common",
  },
  {
    label: "FRAISNES-EN-SAINTOIS",
    value: "54207",
    type: "common",
  },
  {
    label: "COURCELLES",
    value: "54140",
    type: "common",
  },
  {
    label: "VICHEREY",
    value: "88504",
    type: "common",
  },
  {
    label: "SONCOURT",
    value: "88459",
    type: "common",
  },
  {
    label: "SAINT-PRANCHER",
    value: "88433",
    type: "common",
  },
  {
    label: "REPEL",
    value: "88389",
    type: "common",
  },
  {
    label: "PLEUVEZAIN",
    value: "88350",
    type: "common",
  },
  {
    label: "MACONCOURT",
    value: "88278",
    type: "common",
  },
  {
    label: "DOMMARTIN-SUR-VRAINE",
    value: "88150",
    type: "common",
  },
  {
    label: "BEUVEZIN",
    value: "54068",
    type: "common",
  },
  {
    label: "ABONCOURT",
    value: "54003",
    type: "common",
  },
  {
    label: "REMOVILLE",
    value: "88387",
    type: "common",
  },
  {
    label: "RAINVILLE",
    value: "88366",
    type: "common",
  },
  {
    label: "AOUZE",
    value: "88010",
    type: "common",
  },
  {
    label: "VOUXEY",
    value: "88523",
    type: "common",
  },
  {
    label: "ROLLAINVILLE",
    value: "88393",
    type: "common",
  },
  {
    label: "HOUEVILLE",
    value: "88242",
    type: "common",
  },
  {
    label: "DOLAINCOURT",
    value: "88137",
    type: "common",
  },
  {
    label: "BARVILLE",
    value: "88036",
    type: "common",
  },
  {
    label: "NEUFCHATEAU",
    value: "88321",
    type: "common",
  },
  {
    label: "MONT-LES-NEUFCHATEAU",
    value: "88308",
    type: "common",
  },
  {
    label: "FREBECOURT",
    value: "88183",
    type: "common",
  },
  {
    label: "VILLOUXEL",
    value: "88511",
    type: "common",
  },
  {
    label: "SIONNE",
    value: "88457",
    type: "common",
  },
  {
    label: "PARGNY-SOUS-MUREAU",
    value: "88344",
    type: "common",
  },
  {
    label: "MIDREVAUX",
    value: "88303",
    type: "common",
  },
  {
    label: "GRAND",
    value: "88212",
    type: "common",
  },
  {
    label: "BRECHAINVILLE",
    value: "88074",
    type: "common",
  },
  {
    label: "MORIONVILLIERS",
    value: "52342",
    type: "common",
  },
  {
    label: "CHAMBRONCOURT",
    value: "52097",
    type: "common",
  },
  {
    label: "TRAMPOT",
    value: "88477",
    type: "common",
  },
  {
    label: "GERMISAY",
    value: "52219",
    type: "common",
  },
  {
    label: "EPIZON",
    value: "52187",
    type: "common",
  },
  {
    label: "NONCOURT-SUR-LE-RONGEANT",
    value: "52357",
    type: "common",
  },
  {
    label: "ANNONVILLE",
    value: "52012",
    type: "common",
  },
  {
    label: "VAUX-SUR-SAINT-URBAIN",
    value: "52511",
    type: "common",
  },
  {
    label: "SAINT-URBAIN-MACONCOURT",
    value: "52456",
    type: "common",
  },
  {
    label: "DOMREMY-LANDEVILLE",
    value: "52173",
    type: "common",
  },
  {
    label: "FRONVILLE",
    value: "52212",
    type: "common",
  },
  {
    label: "ROUVROY-SUR-MARNE",
    value: "52440",
    type: "common",
  },
  {
    label: "MUSSEY-SUR-MARNE",
    value: "52346",
    type: "common",
  },
  {
    label: "BRACHAY",
    value: "52066",
    type: "common",
  },
  {
    label: "FERRIERE-ET-LAFOLIE",
    value: "52199",
    type: "common",
  },
  {
    label: "BLECOURT",
    value: "52055",
    type: "common",
  },
  {
    label: "COURCELLES-SUR-BLAISE",
    value: "52149",
    type: "common",
  },
  {
    label: "CHARMES-LA-GRANDE",
    value: "52110",
    type: "common",
  },
  {
    label: "BAUDRECOURT",
    value: "52039",
    type: "common",
  },
  {
    label: "DOULEVANT-LE-CHATEAU",
    value: "52178",
    type: "common",
  },
  {
    label: "DOMMARTIN-LE-SAINT-PERE",
    value: "52172",
    type: "common",
  },
  {
    label: "NULLY",
    value: "52359",
    type: "common",
  },
  {
    label: "TREMILLY",
    value: "52495",
    type: "common",
  },
  {
    label: "SOMMEVOIRE",
    value: "52479",
    type: "common",
  },
  {
    label: "SOULAINES-DHUYS",
    value: "10372",
    type: "common",
  },
  {
    label: "LA VILLE-AUX-BOIS",
    value: "10411",
    type: "common",
  },
  {
    label: "EPOTHEMONT",
    value: "10139",
    type: "common",
  },
  {
    label: "MORVILLIERS",
    value: "10258",
    type: "common",
  },
  {
    label: "CRESPY-LE-NEUF",
    value: "10117",
    type: "common",
  },
  {
    label: "JUZANVIGNY",
    value: "10184",
    type: "common",
  },
  {
    label: "BRIENNE-LE-CHATEAU",
    value: "10064",
    type: "common",
  },
  {
    label: "SAINT-LEGER-SOUS-BRIENNE",
    value: "10345",
    type: "common",
  },
  {
    label: "BRIENNE-LA-VIEILLE",
    value: "10063",
    type: "common",
  },
  {
    label: "PEL-ET-DER",
    value: "10283",
    type: "common",
  },
  {
    label: "PRECY-NOTRE-DAME",
    value: "10303",
    type: "common",
  },
  {
    label: "PRECY-SAINT-MARTIN",
    value: "10304",
    type: "common",
  },
  {
    label: "LESMONT",
    value: "10193",
    type: "common",
  },
  {
    label: "EPAGNE",
    value: "10138",
    type: "common",
  },
  {
    label: "BLAINCOURT-SUR-AUBE",
    value: "10046",
    type: "common",
  },
  {
    label: "VAL-D'AUZON",
    value: "10019",
    type: "common",
  },
  {
    label: "LONGSOLS",
    value: "10206",
    type: "common",
  },
  {
    label: "ONJON",
    value: "10270",
    type: "common",
  },
  {
    label: "BOUY-LUXEMBOURG",
    value: "10056",
    type: "common",
  },
  {
    label: "LUYERES",
    value: "10210",
    type: "common",
  },
  {
    label: "CHARMONT-SOUS-BARBUISE",
    value: "10084",
    type: "common",
  },
  {
    label: "SAINT-BENOIT-SUR-SEINE",
    value: "10336",
    type: "common",
  },
  {
    label: "FEUGES",
    value: "10149",
    type: "common",
  },
  {
    label: "AUBETERRE",
    value: "10015",
    type: "common",
  },
  {
    label: "VILLACERF",
    value: "10409",
    type: "common",
  },
  {
    label: "MERGEY",
    value: "10230",
    type: "common",
  },
  {
    label: "CHAPELLE-VALLON",
    value: "10082",
    type: "common",
  },
  {
    label: "CHAUCHIGNY",
    value: "10090",
    type: "common",
  },
  {
    label: "SAINT-MESMIN",
    value: "10353",
    type: "common",
  },
  {
    label: "SAVIERES",
    value: "10368",
    type: "common",
  },
  {
    label: "FONTAINE-LES-GRES",
    value: "10151",
    type: "common",
  },
  {
    label: "ECHEMINES",
    value: "10134",
    type: "common",
  },
  {
    label: "SAINT-FLAVY",
    value: "10339",
    type: "common",
  },
  {
    label: "MARIGNY-LE-CHATEL",
    value: "10224",
    type: "common",
  },
  {
    label: "RIGNY-LA-NONNEUSE",
    value: "10318",
    type: "common",
  },
  {
    label: "FAY-LES-MARCILLY",
    value: "10146",
    type: "common",
  },
  {
    label: "AVON-LA-PEZE",
    value: "10023",
    type: "common",
  },
  {
    label: "SOLIGNY-LES-ETANGS",
    value: "10370",
    type: "common",
  },
  {
    label: "CHARMOY",
    value: "10085",
    type: "common",
  },
  {
    label: "AVANT-LES-MARCILLY",
    value: "10020",
    type: "common",
  },
  {
    label: "LA LOUPTIERE-THENARD",
    value: "10208",
    type: "common",
  },
  {
    label: "TRAINEL",
    value: "10382",
    type: "common",
  },
  {
    label: "GUMERY",
    value: "10169",
    type: "common",
  },
  {
    label: "BOUY-SUR-ORVIN",
    value: "10057",
    type: "common",
  },
  {
    label: "VILLUIS",
    value: "77523",
    type: "common",
  },
  {
    label: "PASSY-SUR-SEINE",
    value: "77356",
    type: "common",
  },
  {
    label: "FONTAINE-FOURCHES",
    value: "77187",
    type: "common",
  },
  {
    label: "VILLENAUXE-LA-PETITE",
    value: "77507",
    type: "common",
  },
  {
    label: "MOUY-SUR-SEINE",
    value: "77325",
    type: "common",
  },
  {
    label: "JAULNES",
    value: "77236",
    type: "common",
  },
  {
    label: "GRISY-SUR-SEINE",
    value: "77218",
    type: "common",
  },
  {
    label: "SAINT-SAUVEUR-LES-BRAY",
    value: "77434",
    type: "common",
  },
  {
    label: "MOUSSEAUX-LES-BRAY",
    value: "77321",
    type: "common",
  },
  {
    label: "BRAY-SUR-SEINE",
    value: "77051",
    type: "common",
  },
  {
    label: "BAZOCHES-LES-BRAY",
    value: "77025",
    type: "common",
  },
  {
    label: "LA TOMBE",
    value: "77467",
    type: "common",
  },
  {
    label: "GRAVON",
    value: "77212",
    type: "common",
  },
  {
    label: "EGLIGNY",
    value: "77167",
    type: "common",
  },
  {
    label: "CHATENAY-SUR-SEINE",
    value: "77101",
    type: "common",
  },
  {
    label: "BALLOY",
    value: "77019",
    type: "common",
  },
  {
    label: "SALINS",
    value: "77439",
    type: "common",
  },
  {
    label: "SAINT-GERMAIN-LAVAL",
    value: "77409",
    type: "common",
  },
  {
    label: "COURCELLES-EN-BASSEE",
    value: "77133",
    type: "common",
  },
  {
    label: "MONTEREAU-FAULT-YONNE",
    value: "77305",
    type: "common",
  },
  {
    label: "FORGES",
    value: "77194",
    type: "common",
  },
  {
    label: "LA GRANDE-PAROISSE",
    value: "77210",
    type: "common",
  },
  {
    label: "VERNOU-LA-CELLE-SUR-SEINE",
    value: "77494",
    type: "common",
  },
  {
    label: "VALENCE-EN-BRIE",
    value: "77480",
    type: "common",
  },
  {
    label: "MACHAULT",
    value: "77266",
    type: "common",
  },
  {
    label: "CHAMPAGNE-SUR-SEINE",
    value: "77079",
    type: "common",
  },
  {
    label: "VULAINES-SUR-SEINE",
    value: "77533",
    type: "common",
  },
  {
    label: "THOMERY",
    value: "77463",
    type: "common",
  },
  {
    label: "SAMOREAU",
    value: "77442",
    type: "common",
  },
  {
    label: "HERICY",
    value: "77226",
    type: "common",
  },
  {
    label: "AVON",
    value: "77014",
    type: "common",
  },
  {
    label: "FONTAINEBLEAU",
    value: "77186",
    type: "common",
  },
  {
    label: "SAINT-MARTIN-EN-BIERE",
    value: "77425",
    type: "common",
  },
  {
    label: "ARBONNE-LA-FORET",
    value: "77006",
    type: "common",
  },
  {
    label: "MILLY-LA-FORET",
    value: "91405",
    type: "common",
  },
  {
    label: "COURANCES",
    value: "91180",
    type: "common",
  },
  {
    label: "FLEURY-EN-BIERE",
    value: "77185",
    type: "common",
  },
  {
    label: "MOIGNY-SUR-ECOLE",
    value: "91408",
    type: "common",
  },
  {
    label: "MAISSE",
    value: "91359",
    type: "common",
  },
  {
    label: "COURDIMANCHE-SUR-ESSONNE",
    value: "91184",
    type: "common",
  },
  {
    label: "BOUTIGNY-SUR-ESSONNE",
    value: "91099",
    type: "common",
  },
  {
    label: "VAYRES-SUR-ESSONNE",
    value: "91639",
    type: "common",
  },
  {
    label: "BOUVILLE",
    value: "91100",
    type: "common",
  },
  {
    label: "PUISELET-LE-MARAIS",
    value: "91508",
    type: "common",
  },
  {
    label: "MORIGNY-CHAMPIGNY",
    value: "91433",
    type: "common",
  },
  {
    label: "ORMOY-LA-RIVIERE",
    value: "91469",
    type: "common",
  },
  {
    label: "ETAMPES",
    value: "91223",
    type: "common",
  },
  {
    label: "SAINT-HILAIRE",
    value: "91556",
    type: "common",
  },
  {
    label: "PLESSIS-SAINT-BENOIST",
    value: "91495",
    type: "common",
  },
  {
    label: "CHALO-SAINT-MARS",
    value: "91130",
    type: "common",
  },
  {
    label: "SAINT-ESCOBILLE",
    value: "91547",
    type: "common",
  },
  {
    label: "MEROBERT",
    value: "91393",
    type: "common",
  },
  {
    label: "OYSONVILLE",
    value: "28294",
    type: "common",
  },
  {
    label: "SAINVILLE",
    value: "28363",
    type: "common",
  },
  {
    label: "MAISONS",
    value: "28230",
    type: "common",
  },
  {
    label: "GARANCIERES-EN-BEAUCE",
    value: "28169",
    type: "common",
  },
  {
    label: "SAINT-LEGER-DES-AUBEES",
    value: "28344",
    type: "common",
  },
  {
    label: "ROINVILLE",
    value: "28317",
    type: "common",
  },
  {
    label: "DENONVILLE",
    value: "28129",
    type: "common",
  },
  {
    label: "LA CHAPELLE-D'AUNAINVILLE",
    value: "28074",
    type: "common",
  },
  {
    label: "AUNAY-SOUS-AUNEAU",
    value: "28013",
    type: "common",
  },
  {
    label: "VOISE",
    value: "28421",
    type: "common",
  },
  {
    label: "FRANCOURVILLE",
    value: "28160",
    type: "common",
  },
  {
    label: "BEVILLE-LE-COMTE",
    value: "28039",
    type: "common",
  },
  {
    label: "SOURS",
    value: "28380",
    type: "common",
  },
  {
    label: "MORANCEZ",
    value: "28269",
    type: "common",
  },
  {
    label: "GELLAINVILLE",
    value: "28177",
    type: "common",
  },
  {
    label: "LE COUDRAY",
    value: "28110",
    type: "common",
  },
  {
    label: "LUISANT",
    value: "28220",
    type: "common",
  },
  {
    label: "LUCE",
    value: "28218",
    type: "common",
  },
  {
    label: "FONTENAY-SUR-EURE",
    value: "28158",
    type: "common",
  },
  {
    label: "BARJOUVILLE",
    value: "28024",
    type: "common",
  },
  {
    label: "SAINT-LUPERCE",
    value: "28350",
    type: "common",
  },
  {
    label: "SAINT-GEORGES-SUR-EURE",
    value: "28337",
    type: "common",
  },
  {
    label: "NOGENT-SUR-EURE",
    value: "28281",
    type: "common",
  },
  {
    label: "CHAUFFOURS",
    value: "28095",
    type: "common",
  },
  {
    label: "SAINT-GERMAIN-LE-GAILLARD",
    value: "28339",
    type: "common",
  },
  {
    label: "ORROUER",
    value: "28290",
    type: "common",
  },
  {
    label: "OLLE",
    value: "28286",
    type: "common",
  },
  {
    label: "VILLEBON",
    value: "28414",
    type: "common",
  },
  {
    label: "LE THIEULIN",
    value: "28385",
    type: "common",
  },
  {
    label: "FRUNCE",
    value: "28167",
    type: "common",
  },
  {
    label: "CHUISNES",
    value: "28099",
    type: "common",
  },
  {
    label: "MONTIREAU",
    value: "28264",
    type: "common",
  },
  {
    label: "FRIAIZE",
    value: "28166",
    type: "common",
  },
  {
    label: "CHAMPROND-EN-GATINE",
    value: "28071",
    type: "common",
  },
  {
    label: "SAINT-VICTOR-DE-BUTHON",
    value: "28362",
    type: "common",
  },
  {
    label: "MONTLANDON",
    value: "28265",
    type: "common",
  },
  {
    label: "SABLONS SUR HUISNE",
    value: "61116",
    type: "common",
  },
  {
    label: "SAINT-GERMAIN-DES-GROIS",
    value: "61395",
    type: "common",
  },
  {
    label: "VERRIERES",
    value: "61501",
    type: "common",
  },
  {
    label: "PERCHE EN NOCE",
    value: "61309",
    type: "common",
  },
  {
    label: "BELLAVILLIERS",
    value: "61037",
    type: "common",
  },
  {
    label: "MAROLLETTE",
    value: "72188",
    type: "common",
  },
  {
    label: "CONTILLY",
    value: "72091",
    type: "common",
  },
  {
    label: "MONTGAUDRY",
    value: "61286",
    type: "common",
  },
  {
    label: "VILLAINES-LA-CARELLE",
    value: "72374",
    type: "common",
  },
  {
    label: "NEUFCHATEL-EN-SAOSNOIS",
    value: "72215",
    type: "common",
  },
  {
    label: "LOUZES",
    value: "72171",
    type: "common",
  },
  {
    label: "AILLIERES-BEAUVOIR",
    value: "72002",
    type: "common",
  },
  {
    label: "SAINT-PATERNE - LE CHEVAIN",
    value: "72308",
    type: "common",
  },
  {
    label: "CHAMPFLEUR",
    value: "72056",
    type: "common",
  },
  {
    label: "BERUS",
    value: "72034",
    type: "common",
  },
  {
    label: "ARCONNAY",
    value: "72006",
    type: "common",
  },
  {
    label: "SAINT-GERMAIN-DU-CORBEIS",
    value: "61397",
    type: "common",
  },
  {
    label: "MOULINS-LE-CARBONNEL",
    value: "72212",
    type: "common",
  },
  {
    label: "MIEUXCE",
    value: "61279",
    type: "common",
  },
  {
    label: "HELOUP",
    value: "61203",
    type: "common",
  },
  {
    label: "SAINT-CENERI-LE-GEREI",
    value: "61372",
    type: "common",
  },
  {
    label: "LA FERRIERE-BOCHARD",
    value: "61165",
    type: "common",
  },
  {
    label: "SAINT-PIERRE-DES-NIDS",
    value: "53246",
    type: "common",
  },
  {
    label: "GESVRES",
    value: "53106",
    type: "common",
  },
  {
    label: "VILLEPAIL",
    value: "53272",
    type: "common",
  },
  {
    label: "CRENNES-SUR-FRAUBEE",
    value: "53085",
    type: "common",
  },
  {
    label: "LE HAM",
    value: "53112",
    type: "common",
  },
  {
    label: "LE RIBAY",
    value: "53190",
    type: "common",
  },
  {
    label: "LE HORPS",
    value: "53116",
    type: "common",
  },
  {
    label: "CHARCHIGNE",
    value: "53061",
    type: "common",
  },
  {
    label: "MONTREUIL-POULAY",
    value: "53160",
    type: "common",
  },
  {
    label: "CHAMPEON",
    value: "53051",
    type: "common",
  },
  {
    label: "LA HAIE-TRAVERSAINE",
    value: "53111",
    type: "common",
  },
  {
    label: "SAINT-LOUP-DU-GAST",
    value: "53234",
    type: "common",
  },
  {
    label: "SAINT-MARS-SUR-COLMONT",
    value: "53237",
    type: "common",
  },
  {
    label: "OISSEAU",
    value: "53170",
    type: "common",
  },
  {
    label: "BRECE",
    value: "53042",
    type: "common",
  },
  {
    label: "SAINT-DENIS-DE-GASTINES",
    value: "53211",
    type: "common",
  },
  {
    label: "COLOMBIERS-DU-PLESSIS",
    value: "53071",
    type: "common",
  },
  {
    label: "CARELLES",
    value: "53047",
    type: "common",
  },
  {
    label: "MONTAUDIN",
    value: "53154",
    type: "common",
  },
  {
    label: "LARCHAMP",
    value: "53126",
    type: "common",
  },
  {
    label: "LE LOROUX",
    value: "35157",
    type: "common",
  },
  {
    label: "LAIGNELET",
    value: "35138",
    type: "common",
  },
  {
    label: "FLEURIGNE",
    value: "35112",
    type: "common",
  },
  {
    label: "BEAUCE",
    value: "35021",
    type: "common",
  },
  {
    label: "SAINT-GERMAIN-EN-COGLES",
    value: "35273",
    type: "common",
  },
  {
    label: "LECOUSSE",
    value: "35150",
    type: "common",
  },
  {
    label: "FOUGERES",
    value: "35115",
    type: "common",
  },
  {
    label: "SAINT-SAUVEUR-DES-LANDES",
    value: "35310",
    type: "common",
  },
  {
    label: "ROMAGNE",
    value: "35243",
    type: "common",
  },
  {
    label: "LE TIERCENT",
    value: "35336",
    type: "common",
  },
  {
    label: "SAINT-OUEN-DES-ALLEUX",
    value: "35304",
    type: "common",
  },
  {
    label: "SAINT-HILAIRE-DES-LANDES",
    value: "35280",
    type: "common",
  },
  {
    label: "VIEUX-VY-SUR-COUESNON",
    value: "35355",
    type: "common",
  },
  {
    label: "SAINT-CHRISTOPHE-DE-VALAINS",
    value: "35261",
    type: "common",
  },
  {
    label: "ROMAZY",
    value: "35244",
    type: "common",
  },
  {
    label: "CHAUVIGNE",
    value: "35075",
    type: "common",
  },
  {
    label: "SENS-DE-BRETAGNE",
    value: "35326",
    type: "common",
  },
  {
    label: "SAINT-REMY-DU-PLAIN",
    value: "35309",
    type: "common",
  },
  {
    label: "MARCILLE-RAOUL",
    value: "35164",
    type: "common",
  },
  {
    label: "FEINS",
    value: "35110",
    type: "common",
  },
  {
    label: "DINGE",
    value: "35094",
    type: "common",
  },
  {
    label: "TINTENIAC",
    value: "35337",
    type: "common",
  },
  {
    label: "SAINT-DOMINEUC",
    value: "35265",
    type: "common",
  },
  {
    label: "QUEBRIAC",
    value: "35233",
    type: "common",
  },
  {
    label: "SAINT-JUDOCE",
    value: "22306",
    type: "common",
  },
  {
    label: "TRIMER",
    value: "35346",
    type: "common",
  },
  {
    label: "SAINT-THUAL",
    value: "35318",
    type: "common",
  },
  {
    label: "LA BAUSSAINE",
    value: "35017",
    type: "common",
  },
  {
    label: "TREFUMEL",
    value: "22352",
    type: "common",
  },
  {
    label: "SAINT-JUVAT",
    value: "22308",
    type: "common",
  },
  {
    label: "PLOUASNE",
    value: "22208",
    type: "common",
  },
  {
    label: "LE QUIOU",
    value: "22263",
    type: "common",
  },
  {
    label: "SAINT-MADEN",
    value: "22312",
    type: "common",
  },
  {
    label: "PLUMAUDAN",
    value: "22239",
    type: "common",
  },
  {
    label: "GUENROC",
    value: "22069",
    type: "common",
  },
  {
    label: "YVIGNAC-LA-TOUR",
    value: "22391",
    type: "common",
  },
  {
    label: "CAULNES",
    value: "22032",
    type: "common",
  },
  {
    label: "TREMEUR",
    value: "22369",
    type: "common",
  },
  {
    label: "BROONS",
    value: "22020",
    type: "common",
  },
  {
    label: "SEVIGNAC",
    value: "22337",
    type: "common",
  },
  {
    label: "ROUILLAC",
    value: "22267",
    type: "common",
  },
  {
    label: "LE MENE",
    value: "22046",
    type: "common",
  },
  {
    label: "PLEMY",
    value: "22184",
    type: "common",
  },
  {
    label: "GAUSSON",
    value: "22060",
    type: "common",
  },
  {
    label: "UZEL",
    value: "22384",
    type: "common",
  },
  {
    label: "LE BODEO",
    value: "22009",
    type: "common",
  },
  {
    label: "MERLEAC",
    value: "22149",
    type: "common",
  },
  {
    label: "ALLINEUC",
    value: "22001",
    type: "common",
  },
  {
    label: "SAINT-MAYEUX",
    value: "22316",
    type: "common",
  },
  {
    label: "SAINT-MARTIN-DES-PRES",
    value: "22313",
    type: "common",
  },
  {
    label: "PLUSSULIEN",
    value: "22244",
    type: "common",
  },
  {
    label: "CORLAY",
    value: "22047",
    type: "common",
  },
  {
    label: "SAINT-IGEAUX",
    value: "22334",
    type: "common",
  },
  {
    label: "SAINTE-TREPHINE",
    value: "22331",
    type: "common",
  },
  {
    label: "PLOUNEVEZ-QUINTIN",
    value: "22229",
    type: "common",
  },
  {
    label: "KERGRIST-MOELOU",
    value: "22087",
    type: "common",
  },
  {
    label: "MAEL-CARHAIX",
    value: "22137",
    type: "common",
  },
  {
    label: "TREFFRIN",
    value: "22351",
    type: "common",
  },
  {
    label: "LE MOUSTOIR",
    value: "22157",
    type: "common",
  },
  {
    label: "KERGLOFF",
    value: "29089",
    type: "common",
  },
  {
    label: "CARHAIX-PLOUGUER",
    value: "29024",
    type: "common",
  },
  {
    label: "CLEDEN-POHER",
    value: "29029",
    type: "common",
  },
  {
    label: "PLONEVEZ-DU-FAOU",
    value: "29175",
    type: "common",
  },
  {
    label: "LANDELEAU",
    value: "29102",
    type: "common",
  },
  {
    label: "LE CLOITRE-PLEYBEN",
    value: "29033",
    type: "common",
  },
  {
    label: "PLEYBEN",
    value: "29162",
    type: "common",
  },
  {
    label: "SAINT-SEGAL",
    value: "29263",
    type: "common",
  },
  {
    label: "PORT-LAUNAY",
    value: "29222",
    type: "common",
  },
  {
    label: "TREGARVAN",
    value: "29289",
    type: "common",
  },
  {
    label: "DINEAULT",
    value: "29044",
    type: "common",
  },
  {
    label: "SAINT-NIC",
    value: "29256",
    type: "common",
  },
  {
    label: "TELGRUC-SUR-MER",
    value: "29280",
    type: "common",
  },
  {
    label: "CROZON",
    value: "29042",
    type: "common",
  },
  {
    label: "WITTISHEIM",
    value: "67547",
    type: "common",
  },
  {
    label: "SUNDHOUSE",
    value: "67486",
    type: "common",
  },
  {
    label: "SCHWOBSHEIM",
    value: "67461",
    type: "common",
  },
  {
    label: "SCHŒNAU",
    value: "67453",
    type: "common",
  },
  {
    label: "SAASENHEIM",
    value: "67422",
    type: "common",
  },
  {
    label: "RICHTOLSHEIM",
    value: "67398",
    type: "common",
  },
  {
    label: "MUTTERSHOLTZ",
    value: "67311",
    type: "common",
  },
  {
    label: "MUSSIG",
    value: "67310",
    type: "common",
  },
  {
    label: "BŒSENBIESEN",
    value: "67053",
    type: "common",
  },
  {
    label: "BALDENHEIM",
    value: "67019",
    type: "common",
  },
  {
    label: "SELESTAT",
    value: "67462",
    type: "common",
  },
  {
    label: "SAINT-HIPPOLYTE",
    value: "68296",
    type: "common",
  },
  {
    label: "RODERN",
    value: "68280",
    type: "common",
  },
  {
    label: "LA VANCELLE",
    value: "67505",
    type: "common",
  },
  {
    label: "ORSCHWILLER",
    value: "67362",
    type: "common",
  },
  {
    label: "KINTZHEIM",
    value: "67239",
    type: "common",
  },
  {
    label: "THANNENKIRCH",
    value: "68335",
    type: "common",
  },
  {
    label: "LIEPVRE",
    value: "68185",
    type: "common",
  },
  {
    label: "SAINTE-MARIE-AUX-MINES",
    value: "68298",
    type: "common",
  },
  {
    label: "SAINTE-CROIX-AUX-MINES",
    value: "68294",
    type: "common",
  },
  {
    label: "LESSEUX",
    value: "88268",
    type: "common",
  },
  {
    label: "BERTRIMOUTIER",
    value: "88054",
    type: "common",
  },
  {
    label: "COMBRIMONT",
    value: "88113",
    type: "common",
  },
  {
    label: "WISEMBACH",
    value: "88526",
    type: "common",
  },
  {
    label: "RAVES",
    value: "88375",
    type: "common",
  },
  {
    label: "GEMAINGOUTTE",
    value: "88193",
    type: "common",
  },
  {
    label: "LUSSE",
    value: "88276",
    type: "common",
  },
  {
    label: "NEUVILLERS-SUR-FAVE",
    value: "88326",
    type: "common",
  },
  {
    label: "NAYEMONT-LES-FOSSES",
    value: "88320",
    type: "common",
  },
  {
    label: "SAINTE-MARGUERITE",
    value: "88424",
    type: "common",
  },
  {
    label: "SAULCY-SUR-MEURTHE",
    value: "88445",
    type: "common",
  },
  {
    label: "REMOMEIX",
    value: "88386",
    type: "common",
  },
  {
    label: "PAIR-ET-GRANDRUPT",
    value: "88341",
    type: "common",
  },
  {
    label: "COINCHES",
    value: "88111",
    type: "common",
  },
  {
    label: "TAINTRUX",
    value: "88463",
    type: "common",
  },
  {
    label: "MORTAGNE",
    value: "88315",
    type: "common",
  },
  {
    label: "LES ROUGES-EAUX",
    value: "88398",
    type: "common",
  },
  {
    label: "LA BOURGONCE",
    value: "88068",
    type: "common",
  },
  {
    label: "FREMIFONTAINE",
    value: "88184",
    type: "common",
  },
  {
    label: "AUTREY",
    value: "88021",
    type: "common",
  },
  {
    label: "SAINTE-HELENE",
    value: "88418",
    type: "common",
  },
  {
    label: "PIERREPONT-SUR-L'ARENTELE",
    value: "88348",
    type: "common",
  },
  {
    label: "NONZEVILLE",
    value: "88331",
    type: "common",
  },
  {
    label: "DESTORD",
    value: "88130",
    type: "common",
  },
  {
    label: "VILLONCOURT",
    value: "88509",
    type: "common",
  },
  {
    label: "PADOUX",
    value: "88340",
    type: "common",
  },
  {
    label: "BULT",
    value: "88080",
    type: "common",
  },
  {
    label: "BADMENIL-AUX-BOIS",
    value: "88027",
    type: "common",
  },
  {
    label: "ZINCOURT",
    value: "88532",
    type: "common",
  },
  {
    label: "PALLEGNEY",
    value: "88342",
    type: "common",
  },
  {
    label: "DOMEVRE-SUR-DURBION",
    value: "88143",
    type: "common",
  },
  {
    label: "BAYECOURT",
    value: "88040",
    type: "common",
  },
  {
    label: "VAXONCOURT",
    value: "88497",
    type: "common",
  },
  {
    label: "NOMEXY",
    value: "88327",
    type: "common",
  },
  {
    label: "IGNEY",
    value: "88247",
    type: "common",
  },
  {
    label: "FRIZON",
    value: "88190",
    type: "common",
  },
  {
    label: "CHATEL-SUR-MOSELLE",
    value: "88094",
    type: "common",
  },
  {
    label: "SAINT-VALLIER",
    value: "88437",
    type: "common",
  },
  {
    label: "REGNEY",
    value: "88378",
    type: "common",
  },
  {
    label: "RAPEY",
    value: "88374",
    type: "common",
  },
  {
    label: "MADEGNEY",
    value: "88280",
    type: "common",
  },
  {
    label: "GUGNEY-AUX-AULX",
    value: "88223",
    type: "common",
  },
  {
    label: "DERBAMONT",
    value: "88129",
    type: "common",
  },
  {
    label: "BETTEGNEY-SAINT-BRICE",
    value: "88055",
    type: "common",
  },
  {
    label: "VROVILLE",
    value: "88525",
    type: "common",
  },
  {
    label: "VILLERS",
    value: "88507",
    type: "common",
  },
  {
    label: "VAUBEXY",
    value: "88494",
    type: "common",
  },
  {
    label: "JORXEY",
    value: "88254",
    type: "common",
  },
  {
    label: "AVILLERS",
    value: "88023",
    type: "common",
  },
  {
    label: "AHEVILLE",
    value: "88002",
    type: "common",
  },
  {
    label: "THIRAUCOURT",
    value: "88469",
    type: "common",
  },
  {
    label: "RAMECOURT",
    value: "88368",
    type: "common",
  },
  {
    label: "POUSSAY",
    value: "88357",
    type: "common",
  },
  {
    label: "MIRECOURT",
    value: "88304",
    type: "common",
  },
  {
    label: "MAZIROT",
    value: "88295",
    type: "common",
  },
  {
    label: "MATTAINCOURT",
    value: "88292",
    type: "common",
  },
  {
    label: "DOMVALLIER",
    value: "88155",
    type: "common",
  },
  {
    label: "TOTAINVILLE",
    value: "88476",
    type: "common",
  },
  {
    label: "ROUVRES-EN-XAINTOIS",
    value: "88400",
    type: "common",
  },
  {
    label: "REMICOURT",
    value: "88382",
    type: "common",
  },
  {
    label: "OFFROICOURT",
    value: "88335",
    type: "common",
  },
  {
    label: "OELLEVILLE",
    value: "88334",
    type: "common",
  },
  {
    label: "DOMBASLE-EN-XAINTOIS",
    value: "88139",
    type: "common",
  },
  {
    label: "BAUDRICOURT",
    value: "88039",
    type: "common",
  },
  {
    label: "SAINT-MENGE",
    value: "88427",
    type: "common",
  },
  {
    label: "MORELMAISON",
    value: "88312",
    type: "common",
  },
  {
    label: "MENIL-EN-XAINTOIS",
    value: "88299",
    type: "common",
  },
  {
    label: "HOUECOURT",
    value: "88241",
    type: "common",
  },
  {
    label: "GIRONCOURT-SUR-VRAINE",
    value: "88206",
    type: "common",
  },
  {
    label: "GEMMELAINCOURT",
    value: "88194",
    type: "common",
  },
  {
    label: "BIECOURT",
    value: "88058",
    type: "common",
  },
  {
    label: "VIOCOURT",
    value: "88514",
    type: "common",
  },
  {
    label: "SAINT-PAUL",
    value: "88431",
    type: "common",
  },
  {
    label: "LA NEUVEVILLE-SOUS-CHATENOIS",
    value: "88324",
    type: "common",
  },
  {
    label: "LONGCHAMP-SOUS-CHATENOIS",
    value: "88274",
    type: "common",
  },
  {
    label: "DARNEY-AUX-CHENES",
    value: "88125",
    type: "common",
  },
  {
    label: "COURCELLES-SOUS-CHATENOIS",
    value: "88117",
    type: "common",
  },
  {
    label: "CHATENOIS",
    value: "88095",
    type: "common",
  },
  {
    label: "BALLEVILLE",
    value: "88031",
    type: "common",
  },
  {
    label: "TILLEUX",
    value: "88474",
    type: "common",
  },
  {
    label: "ROUVRES-LA-CHETIVE",
    value: "88401",
    type: "common",
  },
  {
    label: "LANDAVILLE",
    value: "88259",
    type: "common",
  },
  {
    label: "CERTILLEUX",
    value: "88083",
    type: "common",
  },
  {
    label: "REBEUVILLE",
    value: "88376",
    type: "common",
  },
  {
    label: "CIRCOURT-SUR-MOUZON",
    value: "88104",
    type: "common",
  },
  {
    label: "BAZOILLES-SUR-MEUSE",
    value: "88044",
    type: "common",
  },
  {
    label: "LIFFOL-LE-PETIT",
    value: "52289",
    type: "common",
  },
  {
    label: "LIFFOL-LE-GRAND",
    value: "88270",
    type: "common",
  },
  {
    label: "FREVILLE",
    value: "88189",
    type: "common",
  },
  {
    label: "AILLIANVILLE",
    value: "52003",
    type: "common",
  },
  {
    label: "LAFAUCHE",
    value: "52256",
    type: "common",
  },
  {
    label: "ORQUEVAUX",
    value: "52369",
    type: "common",
  },
  {
    label: "LEURVILLE",
    value: "52286",
    type: "common",
  },
  {
    label: "HUMBERVILLE",
    value: "52245",
    type: "common",
  },
  {
    label: "BUSSON",
    value: "52084",
    type: "common",
  },
  {
    label: "REYNEL",
    value: "52420",
    type: "common",
  },
  {
    label: "ROCHES-BETTAINCOURT",
    value: "52044",
    type: "common",
  },
  {
    label: "DOULAINCOURT-SAUCOURT",
    value: "52177",
    type: "common",
  },
  {
    label: "GUDMONT-VILLIERS",
    value: "52230",
    type: "common",
  },
  {
    label: "DONJEUX",
    value: "52175",
    type: "common",
  },
  {
    label: "FRONCLES",
    value: "52211",
    type: "common",
  },
  {
    label: "LESCHERES-SUR-LE-BLAISERON",
    value: "52284",
    type: "common",
  },
  {
    label: "FLAMMERECOURT",
    value: "52201",
    type: "common",
  },
  {
    label: "AMBONVILLE",
    value: "52007",
    type: "common",
  },
  {
    label: "CERISIERES",
    value: "52091",
    type: "common",
  },
  {
    label: "ROUECOURT",
    value: "52436",
    type: "common",
  },
  {
    label: "CHARMES-EN-L'ANGLE",
    value: "52109",
    type: "common",
  },
  {
    label: "CIREY-SUR-BLAISE",
    value: "52129",
    type: "common",
  },
  {
    label: "BOUZANCOURT",
    value: "52065",
    type: "common",
  },
  {
    label: "BEURVILLE",
    value: "52047",
    type: "common",
  },
  {
    label: "BLUMERAY",
    value: "52057",
    type: "common",
  },
  {
    label: "ARNANCOURT",
    value: "52019",
    type: "common",
  },
  {
    label: "FRESNAY",
    value: "10161",
    type: "common",
  },
  {
    label: "THORS",
    value: "10378",
    type: "common",
  },
  {
    label: "THIL",
    value: "10377",
    type: "common",
  },
  {
    label: "VILLE-SUR-TERRE",
    value: "10428",
    type: "common",
  },
  {
    label: "VERNONVILLIERS",
    value: "10403",
    type: "common",
  },
  {
    label: "FULIGNY",
    value: "10163",
    type: "common",
  },
  {
    label: "LA CHAISE",
    value: "10072",
    type: "common",
  },
  {
    label: "JUVANZE",
    value: "10183",
    type: "common",
  },
  {
    label: "PETIT-MESNIL",
    value: "10286",
    type: "common",
  },
  {
    label: "CHAUMESNIL",
    value: "10093",
    type: "common",
  },
  {
    label: "UNIENVILLE",
    value: "10389",
    type: "common",
  },
  {
    label: "LA ROTHIERE",
    value: "10327",
    type: "common",
  },
  {
    label: "DIENVILLE",
    value: "10123",
    type: "common",
  },
  {
    label: "BREVONNES",
    value: "10061",
    type: "common",
  },
  {
    label: "RADONVILLIERS",
    value: "10313",
    type: "common",
  },
  {
    label: "MATHAUX",
    value: "10228",
    type: "common",
  },
  {
    label: "PINEY",
    value: "10287",
    type: "common",
  },
  {
    label: "ROUILLY-SACEY",
    value: "10328",
    type: "common",
  },
  {
    label: "DOSCHES",
    value: "10129",
    type: "common",
  },
  {
    label: "CRENEY-PRES-TROYES",
    value: "10115",
    type: "common",
  },
  {
    label: "VILLECHETIF",
    value: "10412",
    type: "common",
  },
  {
    label: "MESNIL-SELLIERES",
    value: "10239",
    type: "common",
  },
  {
    label: "ASSENCIERES",
    value: "10014",
    type: "common",
  },
  {
    label: "BOURANTON",
    value: "10053",
    type: "common",
  },
  {
    label: "SAINTE-MAURE",
    value: "10352",
    type: "common",
  },
  {
    label: "VAILLY",
    value: "10391",
    type: "common",
  },
  {
    label: "PONT-SAINTE-MARIE",
    value: "10297",
    type: "common",
  },
  {
    label: "LAVAU",
    value: "10191",
    type: "common",
  },
  {
    label: "SAINT-LYE",
    value: "10349",
    type: "common",
  },
  {
    label: "PAYNS",
    value: "10282",
    type: "common",
  },
  {
    label: "BARBEREY-SAINT-SULPICE",
    value: "10030",
    type: "common",
  },
  {
    label: "LE PAVILLON-SAINTE-JULIE",
    value: "10281",
    type: "common",
  },
  {
    label: "VILLELOUP",
    value: "10414",
    type: "common",
  },
  {
    label: "DIERREY-SAINT-PIERRE",
    value: "10125",
    type: "common",
  },
  {
    label: "PRUNAY-BELLEVILLE",
    value: "10308",
    type: "common",
  },
  {
    label: "SAINT-LUPIEN",
    value: "10348",
    type: "common",
  },
  {
    label: "FAUX-VILLECERF",
    value: "10145",
    type: "common",
  },
  {
    label: "MARCILLY-LE-HAYER",
    value: "10223",
    type: "common",
  },
  {
    label: "BOURDENAY",
    value: "10054",
    type: "common",
  },
  {
    label: "TRANCAULT",
    value: "10383",
    type: "common",
  },
  {
    label: "BERCENAY-LE-HAYER",
    value: "10038",
    type: "common",
  },
  {
    label: "SAINT-MAURICE-AUX-RICHES-HOMMES",
    value: "89359",
    type: "common",
  },
  {
    label: "PERCENEIGE",
    value: "89469",
    type: "common",
  },
  {
    label: "PAILLY",
    value: "89285",
    type: "common",
  },
  {
    label: "BABY",
    value: "77015",
    type: "common",
  },
  {
    label: "SERGINES",
    value: "89391",
    type: "common",
  },
  {
    label: "PLESSIS-SAINT-JEAN",
    value: "89302",
    type: "common",
  },
  {
    label: "COMPIGNY",
    value: "89115",
    type: "common",
  },
  {
    label: "MONTIGNY-LE-GUESDIER",
    value: "77310",
    type: "common",
  },
  {
    label: "SERBONNES",
    value: "89390",
    type: "common",
  },
  {
    label: "COURLON-SUR-YONNE",
    value: "89124",
    type: "common",
  },
  {
    label: "MISY-SUR-YONNE",
    value: "77293",
    type: "common",
  },
  {
    label: "VINNEUF",
    value: "89480",
    type: "common",
  },
  {
    label: "VILLEBLEVIN",
    value: "89449",
    type: "common",
  },
  {
    label: "MAROLLES-SUR-SEINE",
    value: "77279",
    type: "common",
  },
  {
    label: "LA BROSSE-MONTCEAUX",
    value: "77054",
    type: "common",
  },
  {
    label: "CANNES-ECLUSE",
    value: "77061",
    type: "common",
  },
  {
    label: "BARBEY",
    value: "77021",
    type: "common",
  },
  {
    label: "VILLENEUVE-LA-GUYARD",
    value: "89460",
    type: "common",
  },
  {
    label: "VARENNES-SUR-SEINE",
    value: "77482",
    type: "common",
  },
  {
    label: "ESMANS",
    value: "77172",
    type: "common",
  },
  {
    label: "VILLE-SAINT-JACQUES",
    value: "77516",
    type: "common",
  },
  {
    label: "NOISY-RUDIGNON",
    value: "77338",
    type: "common",
  },
  {
    label: "SAINT-MAMMES",
    value: "77419",
    type: "common",
  },
  {
    label: "MORET-LOING-ET-ORVANNE",
    value: "77316",
    type: "common",
  },
  {
    label: "MONTIGNY-SUR-LOING",
    value: "77312",
    type: "common",
  },
  {
    label: "RECLOSES",
    value: "77386",
    type: "common",
  },
  {
    label: "BOURRON-MARLOTTE",
    value: "77048",
    type: "common",
  },
  {
    label: "URY",
    value: "77477",
    type: "common",
  },
  {
    label: "ACHERES-LA-FORET",
    value: "77001",
    type: "common",
  },
  {
    label: "ONCY-SUR-ECOLE",
    value: "91463",
    type: "common",
  },
  {
    label: "LE VAUDOUE",
    value: "77485",
    type: "common",
  },
  {
    label: "TOUSSON",
    value: "77471",
    type: "common",
  },
  {
    label: "NOISY-SUR-ECOLE",
    value: "77339",
    type: "common",
  },
  {
    label: "BUNO-BONNEVAUX",
    value: "91121",
    type: "common",
  },
  {
    label: "VALPUISEAUX",
    value: "91629",
    type: "common",
  },
  {
    label: "PRUNAY-SUR-ESSONNE",
    value: "91507",
    type: "common",
  },
  {
    label: "MESPUITS",
    value: "91399",
    type: "common",
  },
  {
    label: "GIRONVILLE-SUR-ESSONNE",
    value: "91273",
    type: "common",
  },
  {
    label: "CHAMPMOTTEUX",
    value: "91137",
    type: "common",
  },
  {
    label: "ROINVILLIERS",
    value: "91526",
    type: "common",
  },
  {
    label: "MAROLLES-EN-BEAUCE",
    value: "91374",
    type: "common",
  },
  {
    label: "LA FORET-SAINTE-CROIX",
    value: "91248",
    type: "common",
  },
  {
    label: "BOIS-HERPIN",
    value: "91075",
    type: "common",
  },
  {
    label: "ABBEVILLE-LA-RIVIERE",
    value: "91001",
    type: "common",
  },
  {
    label: "SAINT-CYR-LA-RIVIERE",
    value: "91544",
    type: "common",
  },
  {
    label: "SACLAS",
    value: "91533",
    type: "common",
  },
  {
    label: "FONTAINE-LA-RIVIERE",
    value: "91240",
    type: "common",
  },
  {
    label: "BOISSY-LA-RIVIERE",
    value: "91079",
    type: "common",
  },
  {
    label: "MONNERVILLE",
    value: "91414",
    type: "common",
  },
  {
    label: "GUILLERVAL",
    value: "91294",
    type: "common",
  },
  {
    label: "CHALOU-MOULINEUX",
    value: "91131",
    type: "common",
  },
  {
    label: "CONGERVILLE-THIONVILLE",
    value: "91613",
    type: "common",
  },
  {
    label: "PUSSAY",
    value: "91511",
    type: "common",
  },
  {
    label: "GOMMERVILLE",
    value: "28183",
    type: "common",
  },
  {
    label: "VIERVILLE",
    value: "28408",
    type: "common",
  },
  {
    label: "MORAINVILLE",
    value: "28268",
    type: "common",
  },
  {
    label: "LETHUIN",
    value: "28207",
    type: "common",
  },
  {
    label: "GOUILLONS",
    value: "28184",
    type: "common",
  },
  {
    label: "CHATENAY",
    value: "28092",
    type: "common",
  },
  {
    label: "ARDELU",
    value: "28009",
    type: "common",
  },
  {
    label: "OUARVILLE",
    value: "28291",
    type: "common",
  },
  {
    label: "MONDONVILLE-SAINT-JEAN",
    value: "28257",
    type: "common",
  },
  {
    label: "LOUVILLE-LA-CHENARD",
    value: "28215",
    type: "common",
  },
  {
    label: "SANTEUIL",
    value: "28366",
    type: "common",
  },
  {
    label: "RECLAINVILLE",
    value: "28313",
    type: "common",
  },
  {
    label: "MOINVILLE-LA-JEULIN",
    value: "28255",
    type: "common",
  },
  {
    label: "BOISVILLE-LA-SAINT-PERE",
    value: "28047",
    type: "common",
  },
  {
    label: "THEUVILLE",
    value: "28383",
    type: "common",
  },
  {
    label: "PRUNAY-LE-GILLON",
    value: "28309",
    type: "common",
  },
  {
    label: "ALLONNES",
    value: "28004",
    type: "common",
  },
  {
    label: "VER-LES-CHARTRES",
    value: "28403",
    type: "common",
  },
  {
    label: "DAMMARIE",
    value: "28122",
    type: "common",
  },
  {
    label: "CORANCEZ",
    value: "28107",
    type: "common",
  },
  {
    label: "BERCHERES-LES-PIERRES",
    value: "28035",
    type: "common",
  },
  {
    label: "THIVARS",
    value: "28388",
    type: "common",
  },
  {
    label: "MIGNIERES",
    value: "28253",
    type: "common",
  },
  {
    label: "MESLAY-LE-GRENET",
    value: "28245",
    type: "common",
  },
  {
    label: "SANDARVILLE",
    value: "28365",
    type: "common",
  },
  {
    label: "ERMENONVILLE-LA-GRANDE",
    value: "28141",
    type: "common",
  },
  {
    label: "BAILLEAU-LE-PIN",
    value: "28021",
    type: "common",
  },
  {
    label: "MARCHEVILLE",
    value: "28234",
    type: "common",
  },
  {
    label: "MAGNY",
    value: "28225",
    type: "common",
  },
  {
    label: "CERNAY",
    value: "28067",
    type: "common",
  },
  {
    label: "SAINT-DENIS-DES-PUITS",
    value: "28333",
    type: "common",
  },
  {
    label: "NONVILLIERS-GRANDHOUX",
    value: "28282",
    type: "common",
  },
  {
    label: "LES CORVEES-LES-YYS",
    value: "28109",
    type: "common",
  },
  {
    label: "LES CHATELLIERS-NOTRE-DAME",
    value: "28091",
    type: "common",
  },
  {
    label: "COMBRES",
    value: "28105",
    type: "common",
  },
  {
    label: "MAROLLES-LES-BUIS",
    value: "28237",
    type: "common",
  },
  {
    label: "SAINT-PIERRE-LA-BRUYERE",
    value: "61448",
    type: "common",
  },
  {
    label: "NOGENT-LE-ROTROU",
    value: "28280",
    type: "common",
  },
  {
    label: "BERD'HUIS",
    value: "61043",
    type: "common",
  },
  {
    label: "SAINT-CYR-LA-ROSIERE",
    value: "61379",
    type: "common",
  },
  {
    label: "DAME-MARIE",
    value: "61142",
    type: "common",
  },
  {
    label: "SAINT-MARTIN-DU-VIEUX-BELLEME",
    value: "61426",
    type: "common",
  },
  {
    label: "IGE",
    value: "61207",
    type: "common",
  },
  {
    label: "BELFORET-EN-PERCHE",
    value: "61196",
    type: "common",
  },
  {
    label: "LA CHAPELLE-SOUEF",
    value: "61099",
    type: "common",
  },
  {
    label: "BELLEME",
    value: "61038",
    type: "common",
  },
  {
    label: "APPENAI-SOUS-BELLEME",
    value: "61005",
    type: "common",
  },
  {
    label: "VAUNOISE",
    value: "61498",
    type: "common",
  },
  {
    label: "SAINT-FULGENT-DES-ORMES",
    value: "61388",
    type: "common",
  },
  {
    label: "ORIGNY-LE-ROUX",
    value: "61319",
    type: "common",
  },
  {
    label: "CHEMILLI",
    value: "61105",
    type: "common",
  },
  {
    label: "SAINT-REMY-DES-MONTS",
    value: "72316",
    type: "common",
  },
  {
    label: "SAINT-LONGIS",
    value: "72295",
    type: "common",
  },
  {
    label: "PIZIEUX",
    value: "72238",
    type: "common",
  },
  {
    label: "MAMERS",
    value: "72180",
    type: "common",
  },
  {
    label: "COMMERVEIL",
    value: "72086",
    type: "common",
  },
  {
    label: "SURE",
    value: "61476",
    type: "common",
  },
  {
    label: "VEZOT",
    value: "72372",
    type: "common",
  },
  {
    label: "SAOSNES",
    value: "72326",
    type: "common",
  },
  {
    label: "SAINT-REMY-DU-VAL",
    value: "72317",
    type: "common",
  },
  {
    label: "PANON",
    value: "72227",
    type: "common",
  },
  {
    label: "THOIRE-SOUS-CONTENSOR",
    value: "72355",
    type: "common",
  },
  {
    label: "LES MEES",
    value: "72192",
    type: "common",
  },
  {
    label: "LOUVIGNY",
    value: "72170",
    type: "common",
  },
  {
    label: "LIVET-EN-SAOSNOIS",
    value: "72164",
    type: "common",
  },
  {
    label: "ANCINNES",
    value: "72005",
    type: "common",
  },
  {
    label: "ROUESSE-FONTAINE",
    value: "72254",
    type: "common",
  },
  {
    label: "OISSEAU-LE-PETIT",
    value: "72225",
    type: "common",
  },
  {
    label: "FYE",
    value: "72139",
    type: "common",
  },
  {
    label: "CHERISAY",
    value: "72079",
    type: "common",
  },
  {
    label: "BOURG-LE-ROI",
    value: "72043",
    type: "common",
  },
  {
    label: "BETHON",
    value: "72036",
    type: "common",
  },
  {
    label: "SAINT-VICTEUR",
    value: "72323",
    type: "common",
  },
  {
    label: "GESNES-LE-GANDELIN",
    value: "72141",
    type: "common",
  },
  {
    label: "ASSE-LE-BOISNE",
    value: "72011",
    type: "common",
  },
  {
    label: "SOUGE-LE-GANELON",
    value: "72337",
    type: "common",
  },
  {
    label: "SAINT-LEONARD-DES-BOIS",
    value: "72294",
    type: "common",
  },
  {
    label: "SAINT-PAUL-LE-GAULTIER",
    value: "72309",
    type: "common",
  },
  {
    label: "SAINT-AUBIN-DU-DESERT",
    value: "53198",
    type: "common",
  },
  {
    label: "VILLAINES-LA-JUHEL",
    value: "53271",
    type: "common",
  },
  {
    label: "COURCITE",
    value: "53083",
    type: "common",
  },
  {
    label: "AVERTON",
    value: "53013",
    type: "common",
  },
  {
    label: "LOUPFOUGERES",
    value: "53139",
    type: "common",
  },
  {
    label: "HARDANGES",
    value: "53114",
    type: "common",
  },
  {
    label: "LA CHAPELLE-AU-RIBOUL",
    value: "53057",
    type: "common",
  },
  {
    label: "MARCILLE-LA-VILLE",
    value: "53144",
    type: "common",
  },
  {
    label: "SAINT-FRAIMBAULT-DE-PRIERES",
    value: "53216",
    type: "common",
  },
  {
    label: "PARIGNE-SUR-BRAYE",
    value: "53174",
    type: "common",
  },
  {
    label: "MAYENNE",
    value: "53147",
    type: "common",
  },
  {
    label: "ARON",
    value: "53008",
    type: "common",
  },
  {
    label: "SAINT-GEORGES-BUTTAVENT",
    value: "53219",
    type: "common",
  },
  {
    label: "CHATILLON-SUR-COLMONT",
    value: "53064",
    type: "common",
  },
  {
    label: "VAUTORTE",
    value: "53269",
    type: "common",
  },
  {
    label: "ERNEE",
    value: "53096",
    type: "common",
  },
  {
    label: "SAINT-PIERRE-DES-LANDES",
    value: "53245",
    type: "common",
  },
  {
    label: "LA PELLERINE",
    value: "53177",
    type: "common",
  },
  {
    label: "LA SELLE-EN-LUITRE",
    value: "35324",
    type: "common",
  },
  {
    label: "LA CHAPELLE-JANSON",
    value: "35062",
    type: "common",
  },
  {
    label: "JAVENE",
    value: "35137",
    type: "common",
  },
  {
    label: "BILLE",
    value: "35025",
    type: "common",
  },
  {
    label: "LA CHAPELLE-SAINT-AUBERT",
    value: "35063",
    type: "common",
  },
  {
    label: "MEZIERES-SUR-COUESNON",
    value: "35178",
    type: "common",
  },
  {
    label: "GAHARD",
    value: "35118",
    type: "common",
  },
  {
    label: "ANDOUILLE-NEUVILLE",
    value: "35003",
    type: "common",
  },
  {
    label: "SAINT-MEDARD-SUR-ILLE",
    value: "35296",
    type: "common",
  },
  {
    label: "MONTREUIL-SUR-ILLE",
    value: "35195",
    type: "common",
  },
  {
    label: "AUBIGNE",
    value: "35007",
    type: "common",
  },
  {
    label: "VIGNOC",
    value: "35356",
    type: "common",
  },
  {
    label: "HEDE-BAZOUGES",
    value: "35130",
    type: "common",
  },
  {
    label: "GUIPEL",
    value: "35128",
    type: "common",
  },
  {
    label: "SAINT-GONDRAN",
    value: "35276",
    type: "common",
  },
  {
    label: "SAINT-BRIEUC-DES-IFFS",
    value: "35258",
    type: "common",
  },
  {
    label: "LES IFFS",
    value: "35134",
    type: "common",
  },
  {
    label: "LA CHAPELLE-CHAUSSEE",
    value: "35058",
    type: "common",
  },
  {
    label: "MINIAC-SOUS-BECHEREL",
    value: "35180",
    type: "common",
  },
  {
    label: "LONGAULNAY",
    value: "35156",
    type: "common",
  },
  {
    label: "CARDROC",
    value: "35050",
    type: "common",
  },
  {
    label: "BECHEREL",
    value: "35022",
    type: "common",
  },
  {
    label: "SAINT-PERN",
    value: "35307",
    type: "common",
  },
  {
    label: "MEDREAC",
    value: "35171",
    type: "common",
  },
  {
    label: "GUITTE",
    value: "22071",
    type: "common",
  },
  {
    label: "LA CHAPELLE-BLANCHE",
    value: "22036",
    type: "common",
  },
  {
    label: "SAINT-JOUAN-DE-L'ISLE",
    value: "22305",
    type: "common",
  },
  {
    label: "PLUMAUGAT",
    value: "22240",
    type: "common",
  },
  {
    label: "LANRELAS",
    value: "22114",
    type: "common",
  },
  {
    label: "EREAC",
    value: "22053",
    type: "common",
  },
  {
    label: "MERILLAC",
    value: "22148",
    type: "common",
  },
  {
    label: "SAINT-VRAN",
    value: "22333",
    type: "common",
  },
  {
    label: "LA MOTTE",
    value: "22155",
    type: "common",
  },
  {
    label: "SAINT-THELO",
    value: "22330",
    type: "common",
  },
  {
    label: "SAINT-HERVE",
    value: "22300",
    type: "common",
  },
  {
    label: "GRACE-UZEL",
    value: "22068",
    type: "common",
  },
  {
    label: "LE QUILLIO",
    value: "22260",
    type: "common",
  },
  {
    label: "SAINT-GILLES-VIEUX-MARCHE",
    value: "22295",
    type: "common",
  },
  {
    label: "CAUREL",
    value: "22033",
    type: "common",
  },
  {
    label: "BON REPOS SUR BLAVET",
    value: "22107",
    type: "common",
  },
  {
    label: "GOUAREC",
    value: "22064",
    type: "common",
  },
  {
    label: "PLOUGUERNEVEL",
    value: "22220",
    type: "common",
  },
  {
    label: "ROSTRENEN",
    value: "22266",
    type: "common",
  },
  {
    label: "GLOMEL",
    value: "22061",
    type: "common",
  },
  {
    label: "PAULE",
    value: "22163",
    type: "common",
  },
  {
    label: "PLEVIN",
    value: "22202",
    type: "common",
  },
  {
    label: "SAINT-HERNIN",
    value: "29250",
    type: "common",
  },
  {
    label: "MOTREFF",
    value: "29152",
    type: "common",
  },
  {
    label: "SPEZET",
    value: "29278",
    type: "common",
  },
  {
    label: "LENNON",
    value: "29123",
    type: "common",
  },
  {
    label: "CHATEAUNEUF-DU-FAOU",
    value: "29027",
    type: "common",
  },
  {
    label: "GOUEZEC",
    value: "29062",
    type: "common",
  },
  {
    label: "SAINT-COULITZ",
    value: "29243",
    type: "common",
  },
  {
    label: "LOTHEY",
    value: "29142",
    type: "common",
  },
  {
    label: "CHATEAULIN",
    value: "29026",
    type: "common",
  },
  {
    label: "PLOMODIERN",
    value: "29172",
    type: "common",
  },
  {
    label: "PLOEVEN",
    value: "29166",
    type: "common",
  },
  {
    label: "MACKENHEIM",
    value: "67277",
    type: "common",
  },
  {
    label: "BOOTZHEIM",
    value: "67056",
    type: "common",
  },
  {
    label: "ARTOLSHEIM",
    value: "67011",
    type: "common",
  },
  {
    label: "OHNENHEIM",
    value: "67360",
    type: "common",
  },
  {
    label: "HESSENHEIM",
    value: "67195",
    type: "common",
  },
  {
    label: "HEIDOLSHEIM",
    value: "67187",
    type: "common",
  },
  {
    label: "ELSENHEIM",
    value: "67121",
    type: "common",
  },
  {
    label: "ILLHAEUSERN",
    value: "68153",
    type: "common",
  },
  {
    label: "GUEMAR",
    value: "68113",
    type: "common",
  },
  {
    label: "ZELLENBERG",
    value: "68383",
    type: "common",
  },
  {
    label: "RORSCHWIHR",
    value: "68285",
    type: "common",
  },
  {
    label: "HUNAWIHR",
    value: "68147",
    type: "common",
  },
  {
    label: "BERGHEIM",
    value: "68028",
    type: "common",
  },
  {
    label: "RIQUEWIHR",
    value: "68277",
    type: "common",
  },
  {
    label: "RIBEAUVILLE",
    value: "68269",
    type: "common",
  },
  {
    label: "FRELAND",
    value: "68097",
    type: "common",
  },
  {
    label: "AUBURE",
    value: "68014",
    type: "common",
  },
  {
    label: "BAN-DE-LAVELINE",
    value: "88032",
    type: "common",
  },
  {
    label: "LA CROIX-AUX-MINES",
    value: "88120",
    type: "common",
  },
  {
    label: "MANDRAY",
    value: "88284",
    type: "common",
  },
  {
    label: "FRAIZE",
    value: "88181",
    type: "common",
  },
  {
    label: "ENTRE-DEUX-EAUX",
    value: "88159",
    type: "common",
  },
  {
    label: "SAINT-LEONARD",
    value: "88423",
    type: "common",
  },
  {
    label: "ANOULD",
    value: "88009",
    type: "common",
  },
  {
    label: "LA HOUSSIERE",
    value: "88244",
    type: "common",
  },
  {
    label: "LES POULIERES",
    value: "88356",
    type: "common",
  },
  {
    label: "BOIS-DE-CHAMP",
    value: "88064",
    type: "common",
  },
  {
    label: "BIFFONTAINE",
    value: "88059",
    type: "common",
  },
  {
    label: "BELMONT-SUR-BUTTANT",
    value: "88050",
    type: "common",
  },
  {
    label: "VERVEZELLE",
    value: "88502",
    type: "common",
  },
  {
    label: "DOMFAING",
    value: "88145",
    type: "common",
  },
  {
    label: "BROUVELIEURES",
    value: "88076",
    type: "common",
  },
  {
    label: "LAVAL-SUR-VOLOGNE",
    value: "88261",
    type: "common",
  },
  {
    label: "CHAMP-LE-DUC",
    value: "88086",
    type: "common",
  },
  {
    label: "BRUYERES",
    value: "88078",
    type: "common",
  },
  {
    label: "VIMENIL",
    value: "88512",
    type: "common",
  },
  {
    label: "MEMENIL",
    value: "88297",
    type: "common",
  },
  {
    label: "GUGNECOURT",
    value: "88222",
    type: "common",
  },
  {
    label: "GRANDVILLERS",
    value: "88216",
    type: "common",
  },
  {
    label: "GIRECOURT-SUR-DURBION",
    value: "88203",
    type: "common",
  },
  {
    label: "FAYS",
    value: "88169",
    type: "common",
  },
  {
    label: "VAUDEVILLE",
    value: "88495",
    type: "common",
  },
  {
    label: "SERCŒUR",
    value: "88454",
    type: "common",
  },
  {
    label: "LONGCHAMP",
    value: "88273",
    type: "common",
  },
  {
    label: "FONTENAY",
    value: "88175",
    type: "common",
  },
  {
    label: "DOMPIERRE",
    value: "88152",
    type: "common",
  },
  {
    label: "DEYVILLERS",
    value: "88132",
    type: "common",
  },
  {
    label: "AYDOILLES",
    value: "88026",
    type: "common",
  },
  {
    label: "JEUXEY",
    value: "88253",
    type: "common",
  },
  {
    label: "GOLBEY",
    value: "88209",
    type: "common",
  },
  {
    label: "DOGNEVILLE",
    value: "88136",
    type: "common",
  },
  {
    label: "DIGNONVILLE",
    value: "88133",
    type: "common",
  },
  {
    label: "CHAVELOT",
    value: "88099",
    type: "common",
  },
  {
    label: "CAPAVENIR VOSGES",
    value: "88465",
    type: "common",
  },
  {
    label: "MAZELEY",
    value: "88294",
    type: "common",
  },
  {
    label: "GIGNEY",
    value: "88200",
    type: "common",
  },
  {
    label: "FOMEREY",
    value: "88174",
    type: "common",
  },
  {
    label: "DOMEVRE-SUR-AVIERE",
    value: "88142",
    type: "common",
  },
  {
    label: "HENNECOURT",
    value: "88237",
    type: "common",
  },
  {
    label: "DAMAS-ET-BETTEGNEY",
    value: "88122",
    type: "common",
  },
  {
    label: "CIRCOURT",
    value: "88103",
    type: "common",
  },
  {
    label: "BOUXIERES-AUX-BOIS",
    value: "88069",
    type: "common",
  },
  {
    label: "BOCQUEGNEY",
    value: "88063",
    type: "common",
  },
  {
    label: "VELOTTE-ET-TATIGNECOURT",
    value: "88499",
    type: "common",
  },
  {
    label: "RACECOURT",
    value: "88365",
    type: "common",
  },
  {
    label: "MARONCOURT",
    value: "88288",
    type: "common",
  },
  {
    label: "MADONNE-ET-LAMEREY",
    value: "88281",
    type: "common",
  },
  {
    label: "HAGECOURT",
    value: "88226",
    type: "common",
  },
  {
    label: "DOMPAIRE",
    value: "88151",
    type: "common",
  },
  {
    label: "BOUZEMONT",
    value: "88071",
    type: "common",
  },
  {
    label: "BEGNECOURT",
    value: "88047",
    type: "common",
  },
  {
    label: "BAZEGNEY",
    value: "88041",
    type: "common",
  },
  {
    label: "VALLEROY-AUX-SAULES",
    value: "88489",
    type: "common",
  },
  {
    label: "ROZEROTTE",
    value: "88403",
    type: "common",
  },
  {
    label: "RANCOURT",
    value: "88370",
    type: "common",
  },
  {
    label: "MADECOURT",
    value: "88279",
    type: "common",
  },
  {
    label: "HYMONT",
    value: "88246",
    type: "common",
  },
  {
    label: "DOMEVRE-SOUS-MONTFORT",
    value: "88144",
    type: "common",
  },
  {
    label: "BAZOILLES-ET-MENIL",
    value: "88043",
    type: "common",
  },
  {
    label: "VIVIERS-LES-OFFROICOURT",
    value: "88518",
    type: "common",
  },
  {
    label: "REMONCOURT",
    value: "88385",
    type: "common",
  },
  {
    label: "LA NEUVEVILLE-SOUS-MONTFORT",
    value: "88325",
    type: "common",
  },
  {
    label: "ESTRENNES",
    value: "88164",
    type: "common",
  },
  {
    label: "DOMJULIEN",
    value: "88146",
    type: "common",
  },
  {
    label: "THEY-SOUS-MONTFORT",
    value: "88466",
    type: "common",
  },
  {
    label: "SAINT-REMIMONT",
    value: "88434",
    type: "common",
  },
  {
    label: "PAREY-SOUS-MONTFORT",
    value: "88343",
    type: "common",
  },
  {
    label: "BELMONT-SUR-VAIR",
    value: "88051",
    type: "common",
  },
  {
    label: "SANDAUCOURT",
    value: "88440",
    type: "common",
  },
  {
    label: "OLLAINVILLE",
    value: "88336",
    type: "common",
  },
  {
    label: "MANDRES-SUR-VAIR",
    value: "88285",
    type: "common",
  },
  {
    label: "DOMBROT-SUR-VAIR",
    value: "88141",
    type: "common",
  },
  {
    label: "AUZAINVILLIERS",
    value: "88022",
    type: "common",
  },
  {
    label: "MORVILLE",
    value: "88316",
    type: "common",
  },
  {
    label: "MALAINCOURT",
    value: "88283",
    type: "common",
  },
  {
    label: "LEMMECOURT",
    value: "88265",
    type: "common",
  },
  {
    label: "HAGNEVILLE-ET-RONCOURT",
    value: "88227",
    type: "common",
  },
  {
    label: "GENDREVILLE",
    value: "88195",
    type: "common",
  },
  {
    label: "BEAUFREMONT",
    value: "88045",
    type: "common",
  },
  {
    label: "AULNOIS",
    value: "88017",
    type: "common",
  },
  {
    label: "SOMMERECOURT",
    value: "52476",
    type: "common",
  },
  {
    label: "SARTES",
    value: "88443",
    type: "common",
  },
  {
    label: "POMPIERRE",
    value: "88352",
    type: "common",
  },
  {
    label: "JAINVILLOTTE",
    value: "88249",
    type: "common",
  },
  {
    label: "HARREVILLE-LES-CHANTEURS",
    value: "52237",
    type: "common",
  },
  {
    label: "SEMILLY",
    value: "52468",
    type: "common",
  },
  {
    label: "CHALVRAINES",
    value: "52095",
    type: "common",
  },
  {
    label: "PREZ-SOUS-LAFAUCHE",
    value: "52407",
    type: "common",
  },
  {
    label: "VESAIGNES-SOUS-LAFAUCHE",
    value: "52517",
    type: "common",
  },
  {
    label: "SAINT-BLIN",
    value: "52444",
    type: "common",
  },
  {
    label: "MANOIS",
    value: "52306",
    type: "common",
  },
  {
    label: "VIGNES-LA-COTE",
    value: "52523",
    type: "common",
  },
  {
    label: "RIMAUCOURT",
    value: "52423",
    type: "common",
  },
  {
    label: "ANDELOT-BLANCHEVILLE",
    value: "52008",
    type: "common",
  },
  {
    label: "MONTOT-SUR-ROGNON",
    value: "52335",
    type: "common",
  },
  {
    label: "SIGNEVILLE",
    value: "52473",
    type: "common",
  },
  {
    label: "VOUECOURT",
    value: "52547",
    type: "common",
  },
  {
    label: "VIEVILLE",
    value: "52522",
    type: "common",
  },
  {
    label: "SONCOURT-SUR-MARNE",
    value: "52480",
    type: "common",
  },
  {
    label: "VIGNORY",
    value: "52524",
    type: "common",
  },
  {
    label: "MIRBEL",
    value: "52326",
    type: "common",
  },
  {
    label: "MARBEVILLE",
    value: "52310",
    type: "common",
  },
  {
    label: "LA GENEVROYE",
    value: "52214",
    type: "common",
  },
  {
    label: "GUINDRECOURT-SUR-BLAISE",
    value: "52232",
    type: "common",
  },
  {
    label: "DAILLANCOURT",
    value: "52160",
    type: "common",
  },
  {
    label: "COLOMBEY LES DEUX EGLISES",
    value: "52140",
    type: "common",
  },
  {
    label: "CURMONT",
    value: "52157",
    type: "common",
  },
  {
    label: "RIZAUCOURT-BUCHEY",
    value: "52426",
    type: "common",
  },
  {
    label: "SAULCY",
    value: "10366",
    type: "common",
  },
  {
    label: "MAISONS-LES-SOULAINES",
    value: "10219",
    type: "common",
  },
  {
    label: "COLOMBE-LA-FOSSE",
    value: "10102",
    type: "common",
  },
  {
    label: "COLOMBE-LE-SEC",
    value: "10103",
    type: "common",
  },
  {
    label: "ENGENTE",
    value: "10137",
    type: "common",
  },
  {
    label: "LEVIGNY",
    value: "10194",
    type: "common",
  },
  {
    label: "MONTIER-EN-L'ISLE",
    value: "10250",
    type: "common",
  },
  {
    label: "ARRENTIERES",
    value: "10011",
    type: "common",
  },
  {
    label: "AILLEVILLE",
    value: "10002",
    type: "common",
  },
  {
    label: "TRANNES",
    value: "10384",
    type: "common",
  },
  {
    label: "DOLANCOURT",
    value: "10126",
    type: "common",
  },
  {
    label: "BOSSANCOURT",
    value: "10050",
    type: "common",
  },
  {
    label: "ARGANCON",
    value: "10008",
    type: "common",
  },
  {
    label: "ECLANCE",
    value: "10135",
    type: "common",
  },
  {
    label: "ARSONVAL",
    value: "10012",
    type: "common",
  },
  {
    label: "JAUCOURT",
    value: "10176",
    type: "common",
  },
  {
    label: "VAUCHONVILLIERS",
    value: "10397",
    type: "common",
  },
  {
    label: "AMANCE",
    value: "10005",
    type: "common",
  },
  {
    label: "JESSAINS",
    value: "10178",
    type: "common",
  },
  {
    label: "LA LOGE-AUX-CHEVRES",
    value: "10200",
    type: "common",
  },
  {
    label: "GERAUDOT",
    value: "10165",
    type: "common",
  },
  {
    label: "MESNIL-SAINT-PERE",
    value: "10238",
    type: "common",
  },
  {
    label: "LAUBRESSEL",
    value: "10190",
    type: "common",
  },
  {
    label: "LUSIGNY-SUR-BARSE",
    value: "10209",
    type: "common",
  },
  {
    label: "COURTERANGES",
    value: "10110",
    type: "common",
  },
  {
    label: "SAINT-PARRES-AUX-TERTRES",
    value: "10357",
    type: "common",
  },
  {
    label: "RUVIGNY",
    value: "10332",
    type: "common",
  },
  {
    label: "ROUILLY-SAINT-LOUP",
    value: "10329",
    type: "common",
  },
  {
    label: "THENNELIERES",
    value: "10375",
    type: "common",
  },
  {
    label: "LA CHAPELLE-SAINT-LUC",
    value: "10081",
    type: "common",
  },
  {
    label: "LES NOES-PRES-TROYES",
    value: "10265",
    type: "common",
  },
  {
    label: "SAINT-JULIEN-LES-VILLAS",
    value: "10343",
    type: "common",
  },
  {
    label: "ROSIERES-PRES-TROYES",
    value: "10325",
    type: "common",
  },
  {
    label: "TROYES",
    value: "10387",
    type: "common",
  },
  {
    label: "SAINT-ANDRE-LES-VERGERS",
    value: "10333",
    type: "common",
  },
  {
    label: "LA RIVIERE-DE-CORPS",
    value: "10321",
    type: "common",
  },
  {
    label: "TORVILLIERS",
    value: "10381",
    type: "common",
  },
  {
    label: "SAINTE-SAVINE",
    value: "10362",
    type: "common",
  },
  {
    label: "MONTGUEUX",
    value: "10248",
    type: "common",
  },
  {
    label: "MACEY",
    value: "10211",
    type: "common",
  },
  {
    label: "MESSON",
    value: "10240",
    type: "common",
  },
  {
    label: "FONTVANNES",
    value: "10156",
    type: "common",
  },
  {
    label: "DIERREY-SAINT-JULIEN",
    value: "10124",
    type: "common",
  },
  {
    label: "VILLADIN",
    value: "10410",
    type: "common",
  },
  {
    label: "MESNIL-SAINT-LOUP",
    value: "10237",
    type: "common",
  },
  {
    label: "POUY-SUR-VANNES",
    value: "10301",
    type: "common",
  },
  {
    label: "PLANTY",
    value: "10290",
    type: "common",
  },
  {
    label: "COURGENAY",
    value: "89122",
    type: "common",
  },
  {
    label: "LA POSTOLLE",
    value: "89310",
    type: "common",
  },
  {
    label: "LAILLY",
    value: "89214",
    type: "common",
  },
  {
    label: "THORIGNY-SUR-OREUSE",
    value: "89414",
    type: "common",
  },
  {
    label: "MICHERY",
    value: "89255",
    type: "common",
  },
  {
    label: "GISY-LES-NOBLES",
    value: "89189",
    type: "common",
  },
  {
    label: "EVRY",
    value: "89162",
    type: "common",
  },
  {
    label: "LA CHAPELLE-SUR-OREUSE",
    value: "89080",
    type: "common",
  },
  {
    label: "VILLEMANOCHE",
    value: "89456",
    type: "common",
  },
  {
    label: "PONT-SUR-YONNE",
    value: "89309",
    type: "common",
  },
  {
    label: "CHAUMONT",
    value: "89093",
    type: "common",
  },
  {
    label: "CHAMPIGNY",
    value: "89074",
    type: "common",
  },
  {
    label: "SAINT-AGNAN",
    value: "89332",
    type: "common",
  },
  {
    label: "MONTMACHOUX",
    value: "77313",
    type: "common",
  },
  {
    label: "DIANT",
    value: "77158",
    type: "common",
  },
  {
    label: "VOULX",
    value: "77531",
    type: "common",
  },
  {
    label: "THOURY-FEROTTES",
    value: "77465",
    type: "common",
  },
  {
    label: "FLAGY",
    value: "77184",
    type: "common",
  },
  {
    label: "VILLEMER",
    value: "77506",
    type: "common",
  },
  {
    label: "VILLECERF",
    value: "77501",
    type: "common",
  },
  {
    label: "DORMELLES",
    value: "77161",
    type: "common",
  },
  {
    label: "TREUZY-LEVELAY",
    value: "77473",
    type: "common",
  },
  {
    label: "NONVILLE",
    value: "77340",
    type: "common",
  },
  {
    label: "LA GENEVRAYE",
    value: "77202",
    type: "common",
  },
  {
    label: "DARVAULT",
    value: "77156",
    type: "common",
  },
  {
    label: "VILLIERS-SOUS-GREZ",
    value: "77520",
    type: "common",
  },
  {
    label: "MONTCOURT-FROMONVILLE",
    value: "77302",
    type: "common",
  },
  {
    label: "GREZ-SUR-LOING",
    value: "77216",
    type: "common",
  },
  {
    label: "LARCHANT",
    value: "77244",
    type: "common",
  },
  {
    label: "LA CHAPELLE-LA-REINE",
    value: "77088",
    type: "common",
  },
  {
    label: "RUMONT",
    value: "77395",
    type: "common",
  },
  {
    label: "BUTHIERS",
    value: "77060",
    type: "common",
  },
  {
    label: "BOISSY-AUX-CAILLES",
    value: "77041",
    type: "common",
  },
  {
    label: "AMPONVILLE",
    value: "77003",
    type: "common",
  },
  {
    label: "BOIGNEVILLE",
    value: "91069",
    type: "common",
  },
  {
    label: "NANTEAU-SUR-ESSONNE",
    value: "77328",
    type: "common",
  },
  {
    label: "LE MALESHERBOIS",
    value: "45191",
    type: "common",
  },
  {
    label: "BROUY",
    value: "91112",
    type: "common",
  },
  {
    label: "BLANDY",
    value: "91067",
    type: "common",
  },
  {
    label: "SERMAISES",
    value: "45310",
    type: "common",
  },
  {
    label: "ROUVRES-SAINT-JEAN",
    value: "45263",
    type: "common",
  },
  {
    label: "AUDEVILLE",
    value: "45012",
    type: "common",
  },
  {
    label: "ARRANCOURT",
    value: "91022",
    type: "common",
  },
  {
    label: "THIGNONVILLE",
    value: "45320",
    type: "common",
  },
  {
    label: "PANNECIERES",
    value: "45246",
    type: "common",
  },
  {
    label: "AUTRUY-SUR-JUINE",
    value: "45015",
    type: "common",
  },
  {
    label: "ANDONVILLE",
    value: "45005",
    type: "common",
  },
  {
    label: "ANGERVILLE",
    value: "91016",
    type: "common",
  },
  {
    label: "ROUVRAY-SAINT-DENIS",
    value: "28319",
    type: "common",
  },
  {
    label: "INTREVILLE",
    value: "28197",
    type: "common",
  },
  {
    label: "MEROUVILLE",
    value: "28243",
    type: "common",
  },
  {
    label: "LEVESVILLE-LA-CHENARD",
    value: "28210",
    type: "common",
  },
  {
    label: "BAUDREVILLE",
    value: "28026",
    type: "common",
  },
  {
    label: "MOUTIERS",
    value: "28274",
    type: "common",
  },
  {
    label: "PRASVILLE",
    value: "28304",
    type: "common",
  },
  {
    label: "LES VILLAGES VOVEENS",
    value: "28422",
    type: "common",
  },
  {
    label: "BEAUVILLIERS",
    value: "28032",
    type: "common",
  },
  {
    label: "MESLAY-LE-VIDAME",
    value: "28246",
    type: "common",
  },
  {
    label: "BONCE",
    value: "28049",
    type: "common",
  },
  {
    label: "VITRAY-EN-BEAUCE",
    value: "28419",
    type: "common",
  },
  {
    label: "FRESNAY-LE-COMTE",
    value: "28162",
    type: "common",
  },
  {
    label: "LA BOURDINIERE-SAINT-LOUP",
    value: "28048",
    type: "common",
  },
  {
    label: "LUPLANTE",
    value: "28222",
    type: "common",
  },
  {
    label: "ERMENONVILLE-LA-PETITE",
    value: "28142",
    type: "common",
  },
  {
    label: "EPEAUTROLLES",
    value: "28139",
    type: "common",
  },
  {
    label: "CHARONVILLE",
    value: "28081",
    type: "common",
  },
  {
    label: "SAINT-AVIT-LES-GUESPIERES",
    value: "28326",
    type: "common",
  },
  {
    label: "BLANDAINVILLE",
    value: "28041",
    type: "common",
  },
  {
    label: "SAINT-EMAN",
    value: "28336",
    type: "common",
  },
  {
    label: "MONTIGNY-LE-CHARTIF",
    value: "28261",
    type: "common",
  },
  {
    label: "MEREGLISE",
    value: "28242",
    type: "common",
  },
  {
    label: "ILLIERS-COMBRAY",
    value: "28196",
    type: "common",
  },
  {
    label: "HAPPONVILLIERS",
    value: "28192",
    type: "common",
  },
  {
    label: "FRAZE",
    value: "28161",
    type: "common",
  },
  {
    label: "LA CROIX-DU-PERCHE",
    value: "28119",
    type: "common",
  },
  {
    label: "CHASSANT",
    value: "28086",
    type: "common",
  },
  {
    label: "THIRON-GARDAIS",
    value: "28387",
    type: "common",
  },
  {
    label: "LA GAUDAINE",
    value: "28175",
    type: "common",
  },
  {
    label: "ARGENVILLIERS",
    value: "28010",
    type: "common",
  },
  {
    label: "VICHERES",
    value: "28407",
    type: "common",
  },
  {
    label: "TRIZAY-COUTRETOT-SAINT-SERGE",
    value: "28395",
    type: "common",
  },
  {
    label: "CHAMPROND-EN-PERCHET",
    value: "28072",
    type: "common",
  },
  {
    label: "SOUANCE-AU-PERCHE",
    value: "28378",
    type: "common",
  },
  {
    label: "SAINT-JEAN-PIERRE-FIXTE",
    value: "28342",
    type: "common",
  },
  {
    label: "SAINT-HILAIRE-SUR-ERRE",
    value: "61405",
    type: "common",
  },
  {
    label: "VAL-AU-PERCHE",
    value: "61484",
    type: "common",
  },
  {
    label: "SAINT-GERMAIN-DE-LA-COUDRE",
    value: "61394",
    type: "common",
  },
  {
    label: "POUVRAI",
    value: "61336",
    type: "common",
  },
  {
    label: "BELLOU-LE-TRICHARD",
    value: "61041",
    type: "common",
  },
  {
    label: "SAINT-COSME-EN-VAIRAIS",
    value: "72276",
    type: "common",
  },
  {
    label: "SAINT-VINCENT-DES-PRES",
    value: "72324",
    type: "common",
  },
  {
    label: "SAINT-PIERRE-DES-ORMES",
    value: "72313",
    type: "common",
  },
  {
    label: "MONCE-EN-SAOSNOIS",
    value: "72201",
    type: "common",
  },
  {
    label: "AVESNES-EN-SAOSNOIS",
    value: "72018",
    type: "common",
  },
  {
    label: "THOIGNE",
    value: "72354",
    type: "common",
  },
  {
    label: "SAINT-CALEZ-EN-SAOSNOIS",
    value: "72270",
    type: "common",
  },
  {
    label: "MONHOUDOU",
    value: "72202",
    type: "common",
  },
  {
    label: "COURGAINS",
    value: "72104",
    type: "common",
  },
  {
    label: "RENE",
    value: "72251",
    type: "common",
  },
  {
    label: "GRANDCHAMP",
    value: "72142",
    type: "common",
  },
  {
    label: "DOUCELLES",
    value: "72120",
    type: "common",
  },
  {
    label: "CHERANCE",
    value: "72078",
    type: "common",
  },
  {
    label: "PIACE",
    value: "72235",
    type: "common",
  },
  {
    label: "SAINT-OUEN-DE-MIMBRE",
    value: "72305",
    type: "common",
  },
  {
    label: "SAINT-AUBIN-DE-LOCQUENAY",
    value: "72266",
    type: "common",
  },
  {
    label: "SAINT-GEORGES-LE-GAULTIER",
    value: "72282",
    type: "common",
  },
  {
    label: "DOUILLET",
    value: "72121",
    type: "common",
  },
  {
    label: "MONT-SAINT-JEAN",
    value: "72211",
    type: "common",
  },
  {
    label: "SAINT-MARS-DU-DESERT",
    value: "53236",
    type: "common",
  },
  {
    label: "SAINT-GERMAIN-DE-COULAMER",
    value: "53223",
    type: "common",
  },
  {
    label: "SAINT-THOMAS-DE-COURCERIERS",
    value: "53256",
    type: "common",
  },
  {
    label: "TRANS",
    value: "53266",
    type: "common",
  },
  {
    label: "CHAMPGENETEUX",
    value: "53053",
    type: "common",
  },
  {
    label: "BAIS",
    value: "53016",
    type: "common",
  },
  {
    label: "HAMBERS",
    value: "53113",
    type: "common",
  },
  {
    label: "JUBLAINS",
    value: "53122",
    type: "common",
  },
  {
    label: "GRAZAY",
    value: "53109",
    type: "common",
  },
  {
    label: "MOULAY",
    value: "53162",
    type: "common",
  },
  {
    label: "COMMER",
    value: "53072",
    type: "common",
  },
  {
    label: "BELGEARD",
    value: "53028",
    type: "common",
  },
  {
    label: "LA BAZOGE-MONTPINCON",
    value: "53021",
    type: "common",
  },
  {
    label: "SAINT-BAUDELLE",
    value: "53200",
    type: "common",
  },
  {
    label: "CONTEST",
    value: "53074",
    type: "common",
  },
  {
    label: "PLACE",
    value: "53179",
    type: "common",
  },
  {
    label: "MONTENAY",
    value: "53155",
    type: "common",
  },
  {
    label: "CHAILLAND",
    value: "53048",
    type: "common",
  },
  {
    label: "SAINT-HILAIRE-DU-MAINE",
    value: "53226",
    type: "common",
  },
  {
    label: "JUVIGNE",
    value: "53123",
    type: "common",
  },
  {
    label: "PRINCE",
    value: "35232",
    type: "common",
  },
  {
    label: "SAINT-CHRISTOPHE-DES-BOIS",
    value: "35260",
    type: "common",
  },
  {
    label: "PARCE",
    value: "35214",
    type: "common",
  },
  {
    label: "MONTREUIL-DES-LANDES",
    value: "35192",
    type: "common",
  },
  {
    label: "CHATILLON-EN-VENDELAIS",
    value: "35072",
    type: "common",
  },
  {
    label: "MECE",
    value: "35170",
    type: "common",
  },
  {
    label: "COMBOURTILLE",
    value: "35086",
    type: "common",
  },
  {
    label: "SAINT-AUBIN-DU-CORMIER",
    value: "35253",
    type: "common",
  },
  {
    label: "LIVRE-SUR-CHANGEON",
    value: "35154",
    type: "common",
  },
  {
    label: "GOSNE",
    value: "35121",
    type: "common",
  },
  {
    label: "ERCE-PRES-LIFFRE",
    value: "35107",
    type: "common",
  },
  {
    label: "SAINT-SULPICE-LA-FORET",
    value: "35315",
    type: "common",
  },
  {
    label: "SAINT-AUBIN-D'AUBIGNE",
    value: "35251",
    type: "common",
  },
  {
    label: "MOUAZE",
    value: "35197",
    type: "common",
  },
  {
    label: "CHASNE-SUR-ILLET",
    value: "35067",
    type: "common",
  },
  {
    label: "SAINT-GERMAIN-SUR-ILLE",
    value: "35274",
    type: "common",
  },
  {
    label: "MELESSE",
    value: "35173",
    type: "common",
  },
  {
    label: "CHEVAIGNE",
    value: "35079",
    type: "common",
  },
  {
    label: "MONTREUIL-LE-GAST",
    value: "35193",
    type: "common",
  },
  {
    label: "LA MEZIERE",
    value: "35177",
    type: "common",
  },
  {
    label: "ROMILLE",
    value: "35245",
    type: "common",
  },
  {
    label: "LANGOUET",
    value: "35146",
    type: "common",
  },
  {
    label: "LANGAN",
    value: "35144",
    type: "common",
  },
  {
    label: "GEVEZE",
    value: "35120",
    type: "common",
  },
  {
    label: "IRODOUER",
    value: "35135",
    type: "common",
  },
  {
    label: "LANDUJAN",
    value: "35143",
    type: "common",
  },
  {
    label: "LA CHAPELLE DU LOU DU LAC",
    value: "35060",
    type: "common",
  },
  {
    label: "SAINT-MEEN-LE-GRAND",
    value: "35297",
    type: "common",
  },
  {
    label: "QUEDILLAC",
    value: "35234",
    type: "common",
  },
  {
    label: "LE CROUAIS",
    value: "35091",
    type: "common",
  },
  {
    label: "TREMOREL",
    value: "22371",
    type: "common",
  },
  {
    label: "LOSCOUET-SUR-MEU",
    value: "22133",
    type: "common",
  },
  {
    label: "SAINT-LAUNEUC",
    value: "22309",
    type: "common",
  },
  {
    label: "MERDRIGNAC",
    value: "22147",
    type: "common",
  },
  {
    label: "LAURENAN",
    value: "22122",
    type: "common",
  },
  {
    label: "LA PRENESSAYE",
    value: "22255",
    type: "common",
  },
  {
    label: "PLEMET",
    value: "22183",
    type: "common",
  },
  {
    label: "TREVE",
    value: "22376",
    type: "common",
  },
  {
    label: "LOUDEAC",
    value: "22136",
    type: "common",
  },
  {
    label: "SAINT-CARADEC",
    value: "22279",
    type: "common",
  },
  {
    label: "HEMONSTOIR",
    value: "22075",
    type: "common",
  },
  {
    label: "SAINT-CONNEC",
    value: "22285",
    type: "common",
  },
  {
    label: "GUERLEDAN",
    value: "22158",
    type: "common",
  },
  {
    label: "SAINT-AIGNAN",
    value: "56203",
    type: "common",
  },
  {
    label: "SILFIAC",
    value: "56245",
    type: "common",
  },
  {
    label: "SAINTE-BRIGITTE",
    value: "56209",
    type: "common",
  },
  {
    label: "PLELAUFF",
    value: "22181",
    type: "common",
  },
  {
    label: "LESCOUET-GOUAREC",
    value: "22124",
    type: "common",
  },
  {
    label: "MELLIONNEC",
    value: "22146",
    type: "common",
  },
  {
    label: "PLOURAY",
    value: "56170",
    type: "common",
  },
  {
    label: "TREOGAN",
    value: "22373",
    type: "common",
  },
  {
    label: "LANGONNET",
    value: "56100",
    type: "common",
  },
  {
    label: "GOURIN",
    value: "56066",
    type: "common",
  },
  {
    label: "ROUDOUALLEC",
    value: "56199",
    type: "common",
  },
  {
    label: "SAINT-GOAZEC",
    value: "29249",
    type: "common",
  },
  {
    label: "LAZ",
    value: "29122",
    type: "common",
  },
  {
    label: "SAINT-THOIS",
    value: "29267",
    type: "common",
  },
  {
    label: "EDERN",
    value: "29048",
    type: "common",
  },
  {
    label: "BRIEC",
    value: "29020",
    type: "common",
  },
  {
    label: "QUEMENEVEN",
    value: "29229",
    type: "common",
  },
  {
    label: "CAST",
    value: "29025",
    type: "common",
  },
  {
    label: "PLONEVEZ-PORZAY",
    value: "29176",
    type: "common",
  },
  {
    label: "LOCRONAN",
    value: "29134",
    type: "common",
  },
  {
    label: "KERLAZ",
    value: "29090",
    type: "common",
  },
  {
    label: "DOUARNENEZ",
    value: "29046",
    type: "common",
  },
  {
    label: "BEUZEC-CAP-SIZUN",
    value: "29008",
    type: "common",
  },
  {
    label: "JEBSHEIM",
    value: "68157",
    type: "common",
  },
  {
    label: "GRUSSENHEIM",
    value: "68110",
    type: "common",
  },
  {
    label: "BALTZENHEIM",
    value: "68019",
    type: "common",
  },
  {
    label: "ARTZENHEIM",
    value: "68009",
    type: "common",
  },
  {
    label: "MARCKOLSHEIM",
    value: "67281",
    type: "common",
  },
  {
    label: "WICKERSCHWIHR",
    value: "68366",
    type: "common",
  },
  {
    label: "PORTE DU RIED",
    value: "68143",
    type: "common",
  },
  {
    label: "OSTHEIM",
    value: "68252",
    type: "common",
  },
  {
    label: "MITTELWIHR",
    value: "68209",
    type: "common",
  },
  {
    label: "HOUSSEN",
    value: "68146",
    type: "common",
  },
  {
    label: "BENNWIHR",
    value: "68026",
    type: "common",
  },
  {
    label: "BEBLENHEIM",
    value: "68023",
    type: "common",
  },
  {
    label: "KAYSERSBERG VIGNOBLE",
    value: "68162",
    type: "common",
  },
  {
    label: "AMMERSCHWIHR",
    value: "68005",
    type: "common",
  },
  {
    label: "ORBEY",
    value: "68249",
    type: "common",
  },
  {
    label: "LAPOUTROIE",
    value: "68175",
    type: "common",
  },
  {
    label: "LABAROCHE",
    value: "68173",
    type: "common",
  },
  {
    label: "PLAINFAING",
    value: "88349",
    type: "common",
  },
  {
    label: "LE BONHOMME",
    value: "68044",
    type: "common",
  },
  {
    label: "BAN-SUR-MEURTHE-CLEFCY",
    value: "88106",
    type: "common",
  },
  {
    label: "ARRENTES-DE-CORCIEUX",
    value: "88014",
    type: "common",
  },
  {
    label: "GERBEPAL",
    value: "88198",
    type: "common",
  },
  {
    label: "CORCIEUX",
    value: "88115",
    type: "common",
  },
  {
    label: "BARBEY-SEROUX",
    value: "88035",
    type: "common",
  },
  {
    label: "GRANGES-AUMONTZEY",
    value: "88218",
    type: "common",
  },
  {
    label: "VIENVILLE",
    value: "88505",
    type: "common",
  },
  {
    label: "LA CHAPELLE-DEVANT-BRUYERES",
    value: "88089",
    type: "common",
  },
  {
    label: "HERPELMONT",
    value: "88240",
    type: "common",
  },
  {
    label: "JUSSARUPT",
    value: "88256",
    type: "common",
  },
  {
    label: "PREY",
    value: "88359",
    type: "common",
  },
  {
    label: "LAVELINE-DU-HOUX",
    value: "88263",
    type: "common",
  },
  {
    label: "LAVELINE-DEVANT-BRUYERES",
    value: "88262",
    type: "common",
  },
  {
    label: "FIMENIL",
    value: "88172",
    type: "common",
  },
  {
    label: "BEAUMENIL",
    value: "88046",
    type: "common",
  },
  {
    label: "LE ROULIER",
    value: "88399",
    type: "common",
  },
  {
    label: "LA NEUVEVILLE-DEVANT-LEPANGES",
    value: "88322",
    type: "common",
  },
  {
    label: "LEPANGES-SUR-VOLOGNE",
    value: "88266",
    type: "common",
  },
  {
    label: "FAUCOMPIERRE",
    value: "88167",
    type: "common",
  },
  {
    label: "DOCELLES",
    value: "88135",
    type: "common",
  },
  {
    label: "DEYCIMONT",
    value: "88131",
    type: "common",
  },
  {
    label: "CHENIMENIL",
    value: "88101",
    type: "common",
  },
  {
    label: "CHARMOIS-DEVANT-BRUYERES",
    value: "88091",
    type: "common",
  },
  {
    label: "LA BAFFE",
    value: "88028",
    type: "common",
  },
  {
    label: "EPINAL",
    value: "88160",
    type: "common",
  },
  {
    label: "CHANTRAINE",
    value: "88087",
    type: "common",
  },
  {
    label: "UXEGNEY",
    value: "88483",
    type: "common",
  },
  {
    label: "SANCHEY",
    value: "88439",
    type: "common",
  },
  {
    label: "RENAUVOID",
    value: "88388",
    type: "common",
  },
  {
    label: "GIRANCOURT",
    value: "88201",
    type: "common",
  },
  {
    label: "LES FORGES",
    value: "88178",
    type: "common",
  },
  {
    label: "DARNIEULLES",
    value: "88126",
    type: "common",
  },
  {
    label: "CHAUMOUSEY",
    value: "88098",
    type: "common",
  },
  {
    label: "HAROL",
    value: "88233",
    type: "common",
  },
  {
    label: "GORHEY",
    value: "88210",
    type: "common",
  },
  {
    label: "DOMMARTIN-AUX-BOIS",
    value: "88147",
    type: "common",
  },
  {
    label: "VILLE-SUR-ILLON",
    value: "88508",
    type: "common",
  },
  {
    label: "PIERREFITTE",
    value: "88347",
    type: "common",
  },
  {
    label: "LEGEVILLE-ET-BONFAYS",
    value: "88264",
    type: "common",
  },
  {
    label: "GELVECOURT-ET-ADOMPT",
    value: "88192",
    type: "common",
  },
  {
    label: "LES ABLEUVENETTES",
    value: "88001",
    type: "common",
  },
  {
    label: "VALFROICOURT",
    value: "88488",
    type: "common",
  },
  {
    label: "SANS-VALLOIS",
    value: "88441",
    type: "common",
  },
  {
    label: "PONT-LES-BONFAYS",
    value: "88353",
    type: "common",
  },
  {
    label: "FRENOIS",
    value: "88187",
    type: "common",
  },
  {
    label: "DOMMARTIN-LES-VALLOIS",
    value: "88149",
    type: "common",
  },
  {
    label: "BAINVILLE-AUX-SAULES",
    value: "88030",
    type: "common",
  },
  {
    label: "VALLEROY-LE-SEC",
    value: "88490",
    type: "common",
  },
  {
    label: "MONTHUREUX-LE-SEC",
    value: "88309",
    type: "common",
  },
  {
    label: "HAREVILLE",
    value: "88231",
    type: "common",
  },
  {
    label: "ESLEY",
    value: "88162",
    type: "common",
  },
  {
    label: "VITTEL",
    value: "88516",
    type: "common",
  },
  {
    label: "NORROY",
    value: "88332",
    type: "common",
  },
  {
    label: "LIGNEVILLE",
    value: "88271",
    type: "common",
  },
  {
    label: "CONTREXEVILLE",
    value: "88114",
    type: "common",
  },
  {
    label: "VAUDONCOURT",
    value: "88496",
    type: "common",
  },
  {
    label: "SURIAUVILLE",
    value: "88461",
    type: "common",
  },
  {
    label: "SAULXURES-LES-BULGNEVILLE",
    value: "88446",
    type: "common",
  },
  {
    label: "BULGNEVILLE",
    value: "88079",
    type: "common",
  },
  {
    label: "URVILLE",
    value: "88482",
    type: "common",
  },
  {
    label: "SAINT-OUEN-LES-PAREY",
    value: "88430",
    type: "common",
  },
  {
    label: "MEDONVILLE",
    value: "88296",
    type: "common",
  },
  {
    label: "AINGEVILLE",
    value: "88003",
    type: "common",
  },
  {
    label: "GRAFFIGNY-CHEMIN",
    value: "52227",
    type: "common",
  },
  {
    label: "VAUDRECOURT",
    value: "52505",
    type: "common",
  },
  {
    label: "SOULAUCOURT-SUR-MOUZON",
    value: "52482",
    type: "common",
  },
  {
    label: "OUTREMECOURT",
    value: "52372",
    type: "common",
  },
  {
    label: "VRECOURT",
    value: "88524",
    type: "common",
  },
  {
    label: "SAINT-THIEBAULT",
    value: "52455",
    type: "common",
  },
  {
    label: "BOURMONT-ENTRE-MEUSE-ET-MOUZON",
    value: "52064",
    type: "common",
  },
  {
    label: "BRAINVILLE-SUR-MEUSE",
    value: "52067",
    type: "common",
  },
  {
    label: "ILLOUD",
    value: "52247",
    type: "common",
  },
  {
    label: "BOURG-SAINTE-MARIE",
    value: "52063",
    type: "common",
  },
  {
    label: "ROMAIN-SUR-MEUSE",
    value: "52433",
    type: "common",
  },
  {
    label: "CLINCHAMP",
    value: "52133",
    type: "common",
  },
  {
    label: "ECOT-LA-COMBE",
    value: "52183",
    type: "common",
  },
  {
    label: "BOURDONS-SUR-ROGNON",
    value: "52061",
    type: "common",
  },
  {
    label: "CIREY-LES-MAREILLES",
    value: "52128",
    type: "common",
  },
  {
    label: "ROCHEFORT-SUR-LA-COTE",
    value: "52428",
    type: "common",
  },
  {
    label: "CHANTRAINES",
    value: "52107",
    type: "common",
  },
  {
    label: "BRIAUCOURT",
    value: "52075",
    type: "common",
  },
  {
    label: "BOLOGNE",
    value: "52058",
    type: "common",
  },
  {
    label: "VRAINCOURT",
    value: "52548",
    type: "common",
  },
  {
    label: "LAMANCINE",
    value: "52260",
    type: "common",
  },
  {
    label: "ORMOY-LES-SEXFONTAINES",
    value: "52367",
    type: "common",
  },
  {
    label: "ANNEVILLE-LA-PRAIRIE",
    value: "52011",
    type: "common",
  },
  {
    label: "OUDINCOURT",
    value: "52371",
    type: "common",
  },
  {
    label: "SEXFONTAINES",
    value: "52472",
    type: "common",
  },
  {
    label: "MEURES",
    value: "52322",
    type: "common",
  },
  {
    label: "LACHAPELLE-EN-BLAISY",
    value: "52254",
    type: "common",
  },
  {
    label: "JUZENNECOURT",
    value: "52253",
    type: "common",
  },
  {
    label: "VOIGNY",
    value: "10440",
    type: "common",
  },
  {
    label: "ROUVRES-LES-VIGNES",
    value: "10330",
    type: "common",
  },
  {
    label: "LIGNOL-LE-CHATEAU",
    value: "10197",
    type: "common",
  },
  {
    label: "BAYEL",
    value: "10035",
    type: "common",
  },
  {
    label: "PROVERVILLE",
    value: "10306",
    type: "common",
  },
  {
    label: "FONTAINE",
    value: "10150",
    type: "common",
  },
  {
    label: "COUVIGNON",
    value: "10113",
    type: "common",
  },
  {
    label: "BAR-SUR-AUBE",
    value: "10033",
    type: "common",
  },
  {
    label: "SPOY",
    value: "10374",
    type: "common",
  },
  {
    label: "MAISON-DES-CHAMPS",
    value: "10217",
    type: "common",
  },
  {
    label: "MEURVILLE",
    value: "10242",
    type: "common",
  },
  {
    label: "FRAVAUX",
    value: "10160",
    type: "common",
  },
  {
    label: "MONTMARTIN-LE-HAUT",
    value: "10252",
    type: "common",
  },
  {
    label: "PUITS-ET-NUISEMENT",
    value: "10310",
    type: "common",
  },
  {
    label: "MAGNY-FOUCHARD",
    value: "10215",
    type: "common",
  },
  {
    label: "THIEFFRAIN",
    value: "10376",
    type: "common",
  },
  {
    label: "VILLY-EN-TRODES",
    value: "10433",
    type: "common",
  },
  {
    label: "CHAMP-SUR-BARSE",
    value: "10078",
    type: "common",
  },
  {
    label: "LA VILLENEUVE-AU-CHENE",
    value: "10423",
    type: "common",
  },
  {
    label: "VENDEUVRE-SUR-BARSE",
    value: "10401",
    type: "common",
  },
  {
    label: "MONTIERAMEY",
    value: "10249",
    type: "common",
  },
  {
    label: "BRIEL-SUR-BARSE",
    value: "10062",
    type: "common",
  },
  {
    label: "MONTREUIL-SUR-BARSE",
    value: "10255",
    type: "common",
  },
  {
    label: "FRESNOY-LE-CHATEAU",
    value: "10162",
    type: "common",
  },
  {
    label: "MONTAULIN",
    value: "10245",
    type: "common",
  },
  {
    label: "CLEREY",
    value: "10100",
    type: "common",
  },
  {
    label: "VERRIERES",
    value: "10406",
    type: "common",
  },
  {
    label: "ISLE-AUMONT",
    value: "10173",
    type: "common",
  },
  {
    label: "SAINT-THIBAULT",
    value: "10363",
    type: "common",
  },
  {
    label: "SAINT-LEGER-PRES-TROYES",
    value: "10344",
    type: "common",
  },
  {
    label: "VILLEMEREUIL",
    value: "10416",
    type: "common",
  },
  {
    label: "SAINT-POUANGE",
    value: "10360",
    type: "common",
  },
  {
    label: "MOUSSEY",
    value: "10260",
    type: "common",
  },
  {
    label: "BUCHERES",
    value: "10067",
    type: "common",
  },
  {
    label: "BREVIANDES",
    value: "10060",
    type: "common",
  },
  {
    label: "SOULIGNY",
    value: "10373",
    type: "common",
  },
  {
    label: "SAINT-GERMAIN",
    value: "10340",
    type: "common",
  },
  {
    label: "LAINES-AUX-BOIS",
    value: "10186",
    type: "common",
  },
  {
    label: "BUCEY-EN-OTHE",
    value: "10066",
    type: "common",
  },
  {
    label: "VAUCHASSIS",
    value: "10396",
    type: "common",
  },
  {
    label: "PRUGNY",
    value: "10307",
    type: "common",
  },
  {
    label: "NEUVILLE-SUR-VANNE",
    value: "10263",
    type: "common",
  },
  {
    label: "ESTISSAC",
    value: "10142",
    type: "common",
  },
  {
    label: "CHENNEGY",
    value: "10096",
    type: "common",
  },
  {
    label: "PAISY-COSDON",
    value: "10276",
    type: "common",
  },
  {
    label: "VULAINES",
    value: "10444",
    type: "common",
  },
  {
    label: "SAINT-BENOIST-SUR-VANNE",
    value: "10335",
    type: "common",
  },
  {
    label: "BAGNEAUX",
    value: "89027",
    type: "common",
  },
  {
    label: "VILLENEUVE-L'ARCHEVEQUE",
    value: "89461",
    type: "common",
  },
  {
    label: "MOLINONS",
    value: "89261",
    type: "common",
  },
  {
    label: "FLACY",
    value: "89165",
    type: "common",
  },
  {
    label: "FOISSY-SUR-VANNE",
    value: "89171",
    type: "common",
  },
  {
    label: "LES CLERIMOIS",
    value: "89111",
    type: "common",
  },
  {
    label: "VOISINES",
    value: "89483",
    type: "common",
  },
  {
    label: "SALIGNY",
    value: "89373",
    type: "common",
  },
  {
    label: "FONTAINE-LA-GAILLARDE",
    value: "89172",
    type: "common",
  },
  {
    label: "SOUCY",
    value: "89399",
    type: "common",
  },
  {
    label: "SAINT-DENIS-LES-SENS",
    value: "89342",
    type: "common",
  },
  {
    label: "SAINT-CLEMENT",
    value: "89338",
    type: "common",
  },
  {
    label: "CUY",
    value: "89136",
    type: "common",
  },
  {
    label: "COURTOIS-SUR-YONNE",
    value: "89127",
    type: "common",
  },
  {
    label: "VILLEPERROT",
    value: "89465",
    type: "common",
  },
  {
    label: "VILLENAVOTTE",
    value: "89458",
    type: "common",
  },
  {
    label: "SAINT-SEROTIN",
    value: "89369",
    type: "common",
  },
  {
    label: "NAILLY",
    value: "89274",
    type: "common",
  },
  {
    label: "VILLETHIERRY",
    value: "89467",
    type: "common",
  },
  {
    label: "LIXY",
    value: "89229",
    type: "common",
  },
  {
    label: "BRANNAY",
    value: "89054",
    type: "common",
  },
  {
    label: "VALLERY",
    value: "89428",
    type: "common",
  },
  {
    label: "DOLLOT",
    value: "89143",
    type: "common",
  },
  {
    label: "BLENNES",
    value: "77035",
    type: "common",
  },
  {
    label: "VAUX-SUR-LUNAIN",
    value: "77489",
    type: "common",
  },
  {
    label: "LORREZ-LE-BOCAGE-PREAUX",
    value: "77261",
    type: "common",
  },
  {
    label: "CHEVRY-EN-SEREINE",
    value: "77115",
    type: "common",
  },
  {
    label: "REMAUVILLE",
    value: "77387",
    type: "common",
  },
  {
    label: "PALEY",
    value: "77353",
    type: "common",
  },
  {
    label: "NANTEAU-SUR-LUNAIN",
    value: "77329",
    type: "common",
  },
  {
    label: "POLIGNY",
    value: "77370",
    type: "common",
  },
  {
    label: "NEMOURS",
    value: "77333",
    type: "common",
  },
  {
    label: "SAINT-PIERRE-LES-NEMOURS",
    value: "77431",
    type: "common",
  },
  {
    label: "ORMESSON",
    value: "77348",
    type: "common",
  },
  {
    label: "FAŸ-LES-NEMOURS",
    value: "77178",
    type: "common",
  },
  {
    label: "CHATENOY",
    value: "77102",
    type: "common",
  },
  {
    label: "BAGNEAUX-SUR-LOING",
    value: "77016",
    type: "common",
  },
  {
    label: "OBSONVILLE",
    value: "77342",
    type: "common",
  },
  {
    label: "GUERCHEVILLE",
    value: "77220",
    type: "common",
  },
  {
    label: "GARENTREVILLE",
    value: "77200",
    type: "common",
  },
  {
    label: "CHEVRAINVILLIERS",
    value: "77112",
    type: "common",
  },
  {
    label: "FROMONT",
    value: "77198",
    type: "common",
  },
  {
    label: "BURCY",
    value: "77056",
    type: "common",
  },
  {
    label: "BOULANCOURT",
    value: "77046",
    type: "common",
  },
  {
    label: "ORVILLE",
    value: "45237",
    type: "common",
  },
  {
    label: "DESMONTS",
    value: "45124",
    type: "common",
  },
  {
    label: "DIMANCHEVILLE",
    value: "45125",
    type: "common",
  },
  {
    label: "BRIARRES-SUR-ESSONNE",
    value: "45054",
    type: "common",
  },
  {
    label: "AUGERVILLE-LA-RIVIERE",
    value: "45013",
    type: "common",
  },
  {
    label: "RAMOULU",
    value: "45260",
    type: "common",
  },
  {
    label: "MARSAINVILLIERS",
    value: "45198",
    type: "common",
  },
  {
    label: "CESARVILLE-DOSSAINVILLE",
    value: "45065",
    type: "common",
  },
  {
    label: "INTVILLE-LA-GUETARD",
    value: "45170",
    type: "common",
  },
  {
    label: "ENGENVILLE",
    value: "45133",
    type: "common",
  },
  {
    label: "MORVILLE-EN-BEAUCE",
    value: "45217",
    type: "common",
  },
  {
    label: "GUIGNEVILLE",
    value: "45162",
    type: "common",
  },
  {
    label: "CHARMONT-EN-BEAUCE",
    value: "45080",
    type: "common",
  },
  {
    label: "OUTARVILLE",
    value: "45240",
    type: "common",
  },
  {
    label: "LEOUVILLE",
    value: "45181",
    type: "common",
  },
  {
    label: "ERCEVILLE",
    value: "45135",
    type: "common",
  },
  {
    label: "BOISSEAUX",
    value: "45037",
    type: "common",
  },
  {
    label: "OINVILLE-SAINT-LIPHARD",
    value: "28284",
    type: "common",
  },
  {
    label: "BARMAINVILLE",
    value: "28025",
    type: "common",
  },
  {
    label: "TRANCRAINVILLE",
    value: "28392",
    type: "common",
  },
  {
    label: "NEUVY-EN-BEAUCE",
    value: "28276",
    type: "common",
  },
  {
    label: "YMONVILLE",
    value: "28426",
    type: "common",
  },
  {
    label: "GUILLEVILLE",
    value: "28189",
    type: "common",
  },
  {
    label: "FRESNAY-L'EVEQUE",
    value: "28164",
    type: "common",
  },
  {
    label: "EOLE-EN-BEAUCE",
    value: "28406",
    type: "common",
  },
  {
    label: "VILLARS",
    value: "28411",
    type: "common",
  },
  {
    label: "LE GAULT-SAINT-DENIS",
    value: "28176",
    type: "common",
  },
  {
    label: "PRE-SAINT-MARTIN",
    value: "28306",
    type: "common",
  },
  {
    label: "MORIERS",
    value: "28270",
    type: "common",
  },
  {
    label: "MONTBOISSIER",
    value: "28259",
    type: "common",
  },
  {
    label: "SAUMERAY",
    value: "28370",
    type: "common",
  },
  {
    label: "BOUVILLE",
    value: "28057",
    type: "common",
  },
  {
    label: "ALLUYES",
    value: "28005",
    type: "common",
  },
  {
    label: "VIEUVICQ",
    value: "28409",
    type: "common",
  },
  {
    label: "MOTTEREAU",
    value: "28272",
    type: "common",
  },
  {
    label: "DAMPIERRE-SOUS-BROU",
    value: "28123",
    type: "common",
  },
  {
    label: "MOULHARD",
    value: "28273",
    type: "common",
  },
  {
    label: "MIERMAIGNE",
    value: "28252",
    type: "common",
  },
  {
    label: "LUIGNY",
    value: "28219",
    type: "common",
  },
  {
    label: "BETHONVILLIERS",
    value: "28038",
    type: "common",
  },
  {
    label: "BEAUMONT-LES-AUTELS",
    value: "28031",
    type: "common",
  },
  {
    label: "LES ETILLEUX",
    value: "28144",
    type: "common",
  },
  {
    label: "COUDRAY-AU-PERCHE",
    value: "28111",
    type: "common",
  },
  {
    label: "CETON",
    value: "61079",
    type: "common",
  },
  {
    label: "SOUVIGNE-SUR-MEME",
    value: "72342",
    type: "common",
  },
  {
    label: "PREVAL",
    value: "72245",
    type: "common",
  },
  {
    label: "AVEZE",
    value: "72020",
    type: "common",
  },
  {
    label: "SAINT-GEORGES-DU-ROSAY",
    value: "72281",
    type: "common",
  },
  {
    label: "DEHAULT",
    value: "72114",
    type: "common",
  },
  {
    label: "LA CHAPELLE-DU-BOIS",
    value: "72062",
    type: "common",
  },
  {
    label: "ROUPERROUX-LE-COQUET",
    value: "72259",
    type: "common",
  },
  {
    label: "NOGENT-LE-BERNARD",
    value: "72220",
    type: "common",
  },
  {
    label: "TERREHAULT",
    value: "72352",
    type: "common",
  },
  {
    label: "SAINT-AIGNAN",
    value: "72265",
    type: "common",
  },
  {
    label: "PERAY",
    value: "72233",
    type: "common",
  },
  {
    label: "NAUVAY",
    value: "72214",
    type: "common",
  },
  {
    label: "JAUZE",
    value: "72148",
    type: "common",
  },
  {
    label: "COURCIVAL",
    value: "72102",
    type: "common",
  },
  {
    label: "DANGEUL",
    value: "72112",
    type: "common",
  },
  {
    label: "CONGE-SUR-ORNE",
    value: "72088",
    type: "common",
  },
  {
    label: "VIVOIN",
    value: "72380",
    type: "common",
  },
  {
    label: "NOUANS",
    value: "72222",
    type: "common",
  },
  {
    label: "MEURCE",
    value: "72194",
    type: "common",
  },
  {
    label: "LUCE-SOUS-BALLON",
    value: "72174",
    type: "common",
  },
  {
    label: "MOITRON-SUR-SARTHE",
    value: "72199",
    type: "common",
  },
  {
    label: "MARESCHE",
    value: "72186",
    type: "common",
  },
  {
    label: "JUILLE",
    value: "72152",
    type: "common",
  },
  {
    label: "BEAUMONT-SUR-SARTHE",
    value: "72029",
    type: "common",
  },
  {
    label: "ASSE-LE-RIBOUL",
    value: "72012",
    type: "common",
  },
  {
    label: "SEGRIE",
    value: "72332",
    type: "common",
  },
  {
    label: "SAINT-CHRISTOPHE-DU-JAMBET",
    value: "72273",
    type: "common",
  },
  {
    label: "PEZE-LE-ROBERT",
    value: "72234",
    type: "common",
  },
  {
    label: "MONTREUIL-LE-CHETIF",
    value: "72209",
    type: "common",
  },
  {
    label: "SILLE-LE-GUILLAUME",
    value: "72334",
    type: "common",
  },
  {
    label: "LE GREZ",
    value: "72145",
    type: "common",
  },
  {
    label: "SAINTE-GEMMES-LE-ROBERT",
    value: "53218",
    type: "common",
  },
  {
    label: "IZE",
    value: "53120",
    type: "common",
  },
  {
    label: "MEZANGERS",
    value: "53153",
    type: "common",
  },
  {
    label: "LA BAZOUGE-DES-ALLEUX",
    value: "53023",
    type: "common",
  },
  {
    label: "SACE",
    value: "53195",
    type: "common",
  },
  {
    label: "MARTIGNE-SUR-MAYENNE",
    value: "53146",
    type: "common",
  },
  {
    label: "SAINT-GERMAIN-D'ANXURE",
    value: "53222",
    type: "common",
  },
  {
    label: "LA BIGOTTIERE",
    value: "53031",
    type: "common",
  },
  {
    label: "ANDOUILLE",
    value: "53005",
    type: "common",
  },
  {
    label: "ALEXAIN",
    value: "53002",
    type: "common",
  },
  {
    label: "SAINT-GERMAIN-LE-GUILLAUME",
    value: "53225",
    type: "common",
  },
  {
    label: "LA BACONNIERE",
    value: "53015",
    type: "common",
  },
  {
    label: "LE BOURGNEUF-LA-FORET",
    value: "53039",
    type: "common",
  },
  {
    label: "LA CROIXILLE",
    value: "53086",
    type: "common",
  },
  {
    label: "SAINT-M'HERVE",
    value: "35300",
    type: "common",
  },
  {
    label: "MONTAUTOUR",
    value: "35185",
    type: "common",
  },
  {
    label: "TAILLIS",
    value: "35330",
    type: "common",
  },
  {
    label: "BALAZE",
    value: "35015",
    type: "common",
  },
  {
    label: "VAL-D'IZE",
    value: "35347",
    type: "common",
  },
  {
    label: "LANDAVRAN",
    value: "35141",
    type: "common",
  },
  {
    label: "DOURDAIN",
    value: "35101",
    type: "common",
  },
  {
    label: "LA BOUEXIERE",
    value: "35031",
    type: "common",
  },
  {
    label: "THORIGNE-FOUILLARD",
    value: "35334",
    type: "common",
  },
  {
    label: "LIFFRE",
    value: "35152",
    type: "common",
  },
  {
    label: "BETTON",
    value: "35024",
    type: "common",
  },
  {
    label: "SAINT-GREGOIRE",
    value: "35278",
    type: "common",
  },
  {
    label: "MONTGERMONT",
    value: "35189",
    type: "common",
  },
  {
    label: "LA CHAPELLE-DES-FOUGERETZ",
    value: "35059",
    type: "common",
  },
  {
    label: "PACE",
    value: "35210",
    type: "common",
  },
  {
    label: "CLAYES",
    value: "35081",
    type: "common",
  },
  {
    label: "SAINT-GILLES",
    value: "35275",
    type: "common",
  },
  {
    label: "PARTHENAY-DE-BRETAGNE",
    value: "35216",
    type: "common",
  },
  {
    label: "PLEUMELEUC",
    value: "35227",
    type: "common",
  },
  {
    label: "BRETEIL",
    value: "35040",
    type: "common",
  },
  {
    label: "SAINT-UNIAC",
    value: "35320",
    type: "common",
  },
  {
    label: "LA NOUAYE",
    value: "35203",
    type: "common",
  },
  {
    label: "BEDEE",
    value: "35023",
    type: "common",
  },
  {
    label: "BOISGERVILLY",
    value: "35027",
    type: "common",
  },
  {
    label: "SAINT-MAUGAN",
    value: "35295",
    type: "common",
  },
  {
    label: "SAINT-ONEN-LA-CHAPELLE",
    value: "35302",
    type: "common",
  },
  {
    label: "ILLIFAUT",
    value: "22083",
    type: "common",
  },
  {
    label: "MENEAC",
    value: "56129",
    type: "common",
  },
  {
    label: "GOMENE",
    value: "22062",
    type: "common",
  },
  {
    label: "COETLOGON",
    value: "22043",
    type: "common",
  },
  {
    label: "SAINT-BARNABE",
    value: "22275",
    type: "common",
  },
  {
    label: "LA CHEZE",
    value: "22039",
    type: "common",
  },
  {
    label: "SAINT-MAUDAN",
    value: "22314",
    type: "common",
  },
  {
    label: "SAINT-GONNERY",
    value: "56215",
    type: "common",
  },
  {
    label: "SAINT-GERAND",
    value: "56213",
    type: "common",
  },
  {
    label: "KERGRIST",
    value: "56093",
    type: "common",
  },
  {
    label: "CROIXANVEC",
    value: "56049",
    type: "common",
  },
  {
    label: "NEULLIAC",
    value: "56146",
    type: "common",
  },
  {
    label: "CLEGUEREC",
    value: "56041",
    type: "common",
  },
  {
    label: "SEGLIEN",
    value: "56242",
    type: "common",
  },
  {
    label: "LANGOELAN",
    value: "56099",
    type: "common",
  },
  {
    label: "SAINT-TUGDUAL",
    value: "56238",
    type: "common",
  },
  {
    label: "LE SAINT",
    value: "56201",
    type: "common",
  },
  {
    label: "LEUHAN",
    value: "29125",
    type: "common",
  },
  {
    label: "TREGOUREZ",
    value: "29291",
    type: "common",
  },
  {
    label: "CORAY",
    value: "29041",
    type: "common",
  },
  {
    label: "LANGOLEN",
    value: "29110",
    type: "common",
  },
  {
    label: "LANDUDAL",
    value: "29107",
    type: "common",
  },
  {
    label: "LANDREVARZEC",
    value: "29106",
    type: "common",
  },
  {
    label: "PLOGONNEC",
    value: "29169",
    type: "common",
  },
  {
    label: "GUENGAT",
    value: "29066",
    type: "common",
  },
  {
    label: "LE JUCH",
    value: "29087",
    type: "common",
  },
  {
    label: "GOURLIZON",
    value: "29065",
    type: "common",
  },
  {
    label: "POULLAN-SUR-MER",
    value: "29226",
    type: "common",
  },
  {
    label: "POULDERGAT",
    value: "29224",
    type: "common",
  },
  {
    label: "CONFORT-MEILARS",
    value: "29145",
    type: "common",
  },
  {
    label: "PONT-CROIX",
    value: "29218",
    type: "common",
  },
  {
    label: "PLOUHINEC",
    value: "29197",
    type: "common",
  },
  {
    label: "MAHALON",
    value: "29143",
    type: "common",
  },
  {
    label: "GOULIEN",
    value: "29063",
    type: "common",
  },
  {
    label: "AUDIERNE",
    value: "29003",
    type: "common",
  },
  {
    label: "PRIMELIN",
    value: "29228",
    type: "common",
  },
  {
    label: "PLOGOFF",
    value: "29168",
    type: "common",
  },
  {
    label: "CLEDEN-CAP-SIZUN",
    value: "29028",
    type: "common",
  },
  {
    label: "ILE-DE-SEIN",
    value: "29083",
    type: "common",
  },
  {
    label: "WIDENSOLEN",
    value: "68367",
    type: "common",
  },
  {
    label: "URSCHENHEIM",
    value: "68345",
    type: "common",
  },
  {
    label: "KUNHEIM",
    value: "68172",
    type: "common",
  },
  {
    label: "DURRENENTZEN",
    value: "68076",
    type: "common",
  },
  {
    label: "BIESHEIM",
    value: "68036",
    type: "common",
  },
  {
    label: "SUNDHOFFEN",
    value: "68331",
    type: "common",
  },
  {
    label: "MUNTZENHEIM",
    value: "68227",
    type: "common",
  },
  {
    label: "HORBOURG-WIHR",
    value: "68145",
    type: "common",
  },
  {
    label: "FORTSCHWIHR",
    value: "68095",
    type: "common",
  },
  {
    label: "BISCHWIHR",
    value: "68038",
    type: "common",
  },
  {
    label: "ANDOLSHEIM",
    value: "68007",
    type: "common",
  },
  {
    label: "WETTOLSHEIM",
    value: "68365",
    type: "common",
  },
  {
    label: "INGERSHEIM",
    value: "68155",
    type: "common",
  },
  {
    label: "COLMAR",
    value: "68066",
    type: "common",
  },
  {
    label: "ZIMMERBACH",
    value: "68385",
    type: "common",
  },
  {
    label: "WINTZENHEIM",
    value: "68374",
    type: "common",
  },
  {
    label: "WALBACH",
    value: "68354",
    type: "common",
  },
  {
    label: "TURCKHEIM",
    value: "68338",
    type: "common",
  },
  {
    label: "NIEDERMORSCHWIHR",
    value: "68237",
    type: "common",
  },
  {
    label: "KATZENTHAL",
    value: "68161",
    type: "common",
  },
  {
    label: "WIHR-AU-VAL",
    value: "68368",
    type: "common",
  },
  {
    label: "HOHROD",
    value: "68142",
    type: "common",
  },
  {
    label: "GUNSBACH",
    value: "68117",
    type: "common",
  },
  {
    label: "LE VALTIN",
    value: "88492",
    type: "common",
  },
  {
    label: "SOULTZEREN",
    value: "68317",
    type: "common",
  },
  {
    label: "XONRUPT-LONGEMER",
    value: "88531",
    type: "common",
  },
  {
    label: "LIEZEY",
    value: "88269",
    type: "common",
  },
  {
    label: "GERARDMER",
    value: "88196",
    type: "common",
  },
  {
    label: "REHAUPAL",
    value: "88380",
    type: "common",
  },
  {
    label: "CHAMPDRAY",
    value: "88085",
    type: "common",
  },
  {
    label: "LE THOLY",
    value: "88470",
    type: "common",
  },
  {
    label: "XAMONTARUPT",
    value: "88528",
    type: "common",
  },
  {
    label: "TENDON",
    value: "88464",
    type: "common",
  },
  {
    label: "JARMENIL",
    value: "88250",
    type: "common",
  },
  {
    label: "ELOYES",
    value: "88158",
    type: "common",
  },
  {
    label: "POUXEUX",
    value: "88358",
    type: "common",
  },
  {
    label: "ARCHETTES",
    value: "88012",
    type: "common",
  },
  {
    label: "ARCHES",
    value: "88011",
    type: "common",
  },
  {
    label: "DOUNOUX",
    value: "88157",
    type: "common",
  },
  {
    label: "DINOZE",
    value: "88134",
    type: "common",
  },
  {
    label: "UZEMAIN",
    value: "88484",
    type: "common",
  },
  {
    label: "URIMENIL",
    value: "88481",
    type: "common",
  },
  {
    label: "CHARMOIS-L'ORGUEILLEUX",
    value: "88092",
    type: "common",
  },
  {
    label: "VIOMENIL",
    value: "88515",
    type: "common",
  },
  {
    label: "ESCLES",
    value: "88161",
    type: "common",
  },
  {
    label: "LES VALLOIS",
    value: "88491",
    type: "common",
  },
  {
    label: "SENONGES",
    value: "88452",
    type: "common",
  },
  {
    label: "LERRAIN",
    value: "88267",
    type: "common",
  },
  {
    label: "JESONVILLE",
    value: "88252",
    type: "common",
  },
  {
    label: "DOMBASLE-DEVANT-DARNEY",
    value: "88138",
    type: "common",
  },
  {
    label: "THUILLIERES",
    value: "88472",
    type: "common",
  },
  {
    label: "SAINT-BASLEMONT",
    value: "88411",
    type: "common",
  },
  {
    label: "RELANGES",
    value: "88381",
    type: "common",
  },
  {
    label: "BONVILLET",
    value: "88065",
    type: "common",
  },
  {
    label: "VIVIERS-LE-GRAS",
    value: "88517",
    type: "common",
  },
  {
    label: "PROVENCHERES-LES-DARNEY",
    value: "88360",
    type: "common",
  },
  {
    label: "GIGNEVILLE",
    value: "88199",
    type: "common",
  },
  {
    label: "DOMBROT-LE-SEC",
    value: "88140",
    type: "common",
  },
  {
    label: "LA VACHERESSE-ET-LA-ROUILLIE",
    value: "88485",
    type: "common",
  },
  {
    label: "MARTIGNY-LES-BAINS",
    value: "88289",
    type: "common",
  },
  {
    label: "CRAINVILLIERS",
    value: "88119",
    type: "common",
  },
  {
    label: "SAUVILLE",
    value: "88448",
    type: "common",
  },
  {
    label: "ROZIERES-SUR-MOUZON",
    value: "88404",
    type: "common",
  },
  {
    label: "ROBECOURT",
    value: "88390",
    type: "common",
  },
  {
    label: "GERMAINVILLIERS",
    value: "52217",
    type: "common",
  },
  {
    label: "CHAMPIGNEULLES-EN-BASSIGNY",
    value: "52101",
    type: "common",
  },
  {
    label: "CHAUMONT-LA-VILLE",
    value: "52122",
    type: "common",
  },
  {
    label: "BLEVAINCOURT",
    value: "88062",
    type: "common",
  },
  {
    label: "AUDELONCOURT",
    value: "52025",
    type: "common",
  },
  {
    label: "MAISONCELLES",
    value: "52301",
    type: "common",
  },
  {
    label: "MALAINCOURT-SUR-MEUSE",
    value: "52304",
    type: "common",
  },
  {
    label: "DONCOURT-SUR-MEUSE",
    value: "52174",
    type: "common",
  },
  {
    label: "LEVECOURT",
    value: "52287",
    type: "common",
  },
  {
    label: "HACOURT",
    value: "52234",
    type: "common",
  },
  {
    label: "LONGCHAMP",
    value: "52291",
    type: "common",
  },
  {
    label: "CLEFMONT",
    value: "52132",
    type: "common",
  },
  {
    label: "THOL-LES-MILLIERES",
    value: "52489",
    type: "common",
  },
  {
    label: "HUILLIECOURT",
    value: "52243",
    type: "common",
  },
  {
    label: "VRONCOURT-LA-COTE",
    value: "52549",
    type: "common",
  },
  {
    label: "OZIERES",
    value: "52373",
    type: "common",
  },
  {
    label: "MILLIERES",
    value: "52325",
    type: "common",
  },
  {
    label: "FORCEY",
    value: "52204",
    type: "common",
  },
  {
    label: "CONSIGNY",
    value: "52142",
    type: "common",
  },
  {
    label: "MAREILLES",
    value: "52313",
    type: "common",
  },
  {
    label: "ESNOUVEAUX",
    value: "52190",
    type: "common",
  },
  {
    label: "TREIX",
    value: "52494",
    type: "common",
  },
  {
    label: "DARMANNES",
    value: "52167",
    type: "common",
  },
  {
    label: "RIAUCOURT",
    value: "52421",
    type: "common",
  },
  {
    label: "CONDES",
    value: "52141",
    type: "common",
  },
  {
    label: "BRETHENAY",
    value: "52072",
    type: "common",
  },
  {
    label: "EUFFIGNEIX",
    value: "52193",
    type: "common",
  },
  {
    label: "JONCHERY",
    value: "52251",
    type: "common",
  },
  {
    label: "BLAISY",
    value: "52053",
    type: "common",
  },
  {
    label: "AUTREVILLE-SUR-LA-RENNE",
    value: "52031",
    type: "common",
  },
  {
    label: "GILLANCOURT",
    value: "52221",
    type: "common",
  },
  {
    label: "MONTHERIES",
    value: "52330",
    type: "common",
  },
  {
    label: "VAUDREMONT",
    value: "52506",
    type: "common",
  },
  {
    label: "RENNEPONT",
    value: "52419",
    type: "common",
  },
  {
    label: "MARANVILLE",
    value: "52308",
    type: "common",
  },
  {
    label: "VILLE-SOUS-LA-FERTE",
    value: "10426",
    type: "common",
  },
  {
    label: "LONGCHAMP-SUR-AUJON",
    value: "10203",
    type: "common",
  },
  {
    label: "BAROVILLE",
    value: "10032",
    type: "common",
  },
  {
    label: "BERGERES",
    value: "10039",
    type: "common",
  },
  {
    label: "URVILLE",
    value: "10390",
    type: "common",
  },
  {
    label: "ARCONVILLE",
    value: "10007",
    type: "common",
  },
  {
    label: "VITRY-LE-CROISE",
    value: "10438",
    type: "common",
  },
  {
    label: "BLIGNY",
    value: "10048",
    type: "common",
  },
  {
    label: "EGUILLY-SOUS-BOIS",
    value: "10136",
    type: "common",
  },
  {
    label: "BERTIGNOLLES",
    value: "10041",
    type: "common",
  },
  {
    label: "LONGPRE-LE-SEC",
    value: "10205",
    type: "common",
  },
  {
    label: "CHERVEY",
    value: "10097",
    type: "common",
  },
  {
    label: "MAGNANT",
    value: "10213",
    type: "common",
  },
  {
    label: "BUXIERES-SUR-ARCE",
    value: "10069",
    type: "common",
  },
  {
    label: "BEUREY",
    value: "10045",
    type: "common",
  },
  {
    label: "BOURGUIGNONS",
    value: "10055",
    type: "common",
  },
  {
    label: "COURTENOT",
    value: "10109",
    type: "common",
  },
  {
    label: "POLIGNY",
    value: "10294",
    type: "common",
  },
  {
    label: "MAROLLES-LES-BAILLY",
    value: "10226",
    type: "common",
  },
  {
    label: "FRALIGNES",
    value: "10159",
    type: "common",
  },
  {
    label: "CHAUFFOUR-LES-BAILLY",
    value: "10092",
    type: "common",
  },
  {
    label: "VILLEMOYENNE",
    value: "10419",
    type: "common",
  },
  {
    label: "FOUCHERES",
    value: "10158",
    type: "common",
  },
  {
    label: "SAINT-PARRES-LES-VAUDES",
    value: "10358",
    type: "common",
  },
  {
    label: "CHAPPES",
    value: "10083",
    type: "common",
  },
  {
    label: "MONTCEAUX-LES-VAUDES",
    value: "10246",
    type: "common",
  },
  {
    label: "CORMOST",
    value: "10104",
    type: "common",
  },
  {
    label: "VAUDES",
    value: "10399",
    type: "common",
  },
  {
    label: "LONGEVILLE-SUR-MOGNE",
    value: "10204",
    type: "common",
  },
  {
    label: "VILLY-LE-MARECHAL",
    value: "10435",
    type: "common",
  },
  {
    label: "SAINT-JEAN-DE-BONNEVAL",
    value: "10342",
    type: "common",
  },
  {
    label: "LES BORDES-AUMONT",
    value: "10049",
    type: "common",
  },
  {
    label: "VILLY-LE-BOIS",
    value: "10434",
    type: "common",
  },
  {
    label: "RONCENAY",
    value: "10324",
    type: "common",
  },
  {
    label: "MACHY",
    value: "10212",
    type: "common",
  },
  {
    label: "LIREY",
    value: "10198",
    type: "common",
  },
  {
    label: "ASSENAY",
    value: "10013",
    type: "common",
  },
  {
    label: "VILLERY",
    value: "10425",
    type: "common",
  },
  {
    label: "SOMMEVAL",
    value: "10371",
    type: "common",
  },
  {
    label: "JAVERNANT",
    value: "10177",
    type: "common",
  },
  {
    label: "CRESANTIGNES",
    value: "10116",
    type: "common",
  },
  {
    label: "BOUILLY",
    value: "10051",
    type: "common",
  },
  {
    label: "BERCENAY-EN-OTHE",
    value: "10037",
    type: "common",
  },
  {
    label: "VILLEMOIRON-EN-OTHE",
    value: "10417",
    type: "common",
  },
  {
    label: "SAINT-MARDS-EN-OTHE",
    value: "10350",
    type: "common",
  },
  {
    label: "AIX-VILLEMAUR-PALIS",
    value: "10003",
    type: "common",
  },
  {
    label: "BERULLE",
    value: "10042",
    type: "common",
  },
  {
    label: "RIGNY-LE-FERRON",
    value: "10319",
    type: "common",
  },
  {
    label: "FOURNAUDIN",
    value: "89181",
    type: "common",
  },
  {
    label: "CERILLY",
    value: "89065",
    type: "common",
  },
  {
    label: "LES SIEGES",
    value: "89395",
    type: "common",
  },
  {
    label: "COULOURS",
    value: "89120",
    type: "common",
  },
  {
    label: "LES VALLEES DE LA VANNE",
    value: "89411",
    type: "common",
  },
  {
    label: "PONT-SUR-VANNE",
    value: "89308",
    type: "common",
  },
  {
    label: "VILLIERS-LOUIS",
    value: "89471",
    type: "common",
  },
  {
    label: "NOE",
    value: "89278",
    type: "common",
  },
  {
    label: "MALAY-LE-PETIT",
    value: "89240",
    type: "common",
  },
  {
    label: "MALAY-LE-GRAND",
    value: "89239",
    type: "common",
  },
  {
    label: "SENS",
    value: "89387",
    type: "common",
  },
  {
    label: "SAINT-MARTIN-DU-TERTRE",
    value: "89354",
    type: "common",
  },
  {
    label: "MAILLOT",
    value: "89236",
    type: "common",
  },
  {
    label: "VILLEROY",
    value: "89466",
    type: "common",
  },
  {
    label: "VILLEBOUGIS",
    value: "89450",
    type: "common",
  },
  {
    label: "SUBLIGNY",
    value: "89404",
    type: "common",
  },
  {
    label: "PARON",
    value: "89287",
    type: "common",
  },
  {
    label: "COLLEMIERS",
    value: "89113",
    type: "common",
  },
  {
    label: "SAINT-VALERIEN",
    value: "89370",
    type: "common",
  },
  {
    label: "FOUCHERES",
    value: "89180",
    type: "common",
  },
  {
    label: "MONTACHER-VILLEGARDIN",
    value: "89264",
    type: "common",
  },
  {
    label: "CHEROY",
    value: "89100",
    type: "common",
  },
  {
    label: "JOUY",
    value: "89209",
    type: "common",
  },
  {
    label: "VILLEBEON",
    value: "77500",
    type: "common",
  },
  {
    label: "EGREVILLE",
    value: "77168",
    type: "common",
  },
  {
    label: "CHAINTREAUX",
    value: "77071",
    type: "common",
  },
  {
    label: "SOUPPES-SUR-LOING",
    value: "77458",
    type: "common",
  },
  {
    label: "LA MADELEINE-SUR-LOING",
    value: "77267",
    type: "common",
  },
  {
    label: "CHENOU",
    value: "77110",
    type: "common",
  },
  {
    label: "BOUGLIGNY",
    value: "77045",
    type: "common",
  },
  {
    label: "MAISONCELLES-EN-GATINAIS",
    value: "77271",
    type: "common",
  },
  {
    label: "ICHY",
    value: "77230",
    type: "common",
  },
  {
    label: "AUFFERVILLE",
    value: "77011",
    type: "common",
  },
  {
    label: "ARVILLE",
    value: "77009",
    type: "common",
  },
  {
    label: "GIRONVILLE",
    value: "77207",
    type: "common",
  },
  {
    label: "PUISEAUX",
    value: "45258",
    type: "common",
  },
  {
    label: "BROMEILLES",
    value: "45056",
    type: "common",
  },
  {
    label: "ONDREVILLE-SUR-ESSONNE",
    value: "45233",
    type: "common",
  },
  {
    label: "LA NEUVILLE-SUR-ESSONNE",
    value: "45225",
    type: "common",
  },
  {
    label: "GRANGERMONT",
    value: "45159",
    type: "common",
  },
  {
    label: "ECHILLEUSES",
    value: "45131",
    type: "common",
  },
  {
    label: "AULNAY-LA-RIVIERE",
    value: "45014",
    type: "common",
  },
  {
    label: "ESTOUY",
    value: "45139",
    type: "common",
  },
  {
    label: "BONDAROY",
    value: "45038",
    type: "common",
  },
  {
    label: "PITHIVIERS-LE-VIEIL",
    value: "45253",
    type: "common",
  },
  {
    label: "PITHIVIERS",
    value: "45252",
    type: "common",
  },
  {
    label: "GRENEVILLE-EN-BEAUCE",
    value: "45160",
    type: "common",
  },
  {
    label: "CHATILLON-LE-ROI",
    value: "45086",
    type: "common",
  },
  {
    label: "BAZOCHES-LES-GALLERANDES",
    value: "45025",
    type: "common",
  },
  {
    label: "TIVERNON",
    value: "45325",
    type: "common",
  },
  {
    label: "CHAUSSY",
    value: "45088",
    type: "common",
  },
  {
    label: "TOURY",
    value: "28391",
    type: "common",
  },
  {
    label: "SANTILLY",
    value: "28367",
    type: "common",
  },
  {
    label: "POINVILLE",
    value: "28300",
    type: "common",
  },
  {
    label: "TILLAY-LE-PENEUX",
    value: "28390",
    type: "common",
  },
  {
    label: "BAZOCHES-LES-HAUTES",
    value: "28029",
    type: "common",
  },
  {
    label: "FONTENAY-SUR-CONIE",
    value: "28157",
    type: "common",
  },
  {
    label: "SANCHEVILLE",
    value: "28364",
    type: "common",
  },
  {
    label: "COURBEHAYE",
    value: "28114",
    type: "common",
  },
  {
    label: "NEUVY-EN-DUNOIS",
    value: "28277",
    type: "common",
  },
  {
    label: "BULLAINVILLE",
    value: "28065",
    type: "common",
  },
  {
    label: "SAINT-MAUR-SUR-LE-LOIR",
    value: "28353",
    type: "common",
  },
  {
    label: "PRE-SAINT-EVROULT",
    value: "28305",
    type: "common",
  },
  {
    label: "DANCY",
    value: "28126",
    type: "common",
  },
  {
    label: "TRIZAY-LES-BONNEVAL",
    value: "28396",
    type: "common",
  },
  {
    label: "MONTHARVILLE",
    value: "28260",
    type: "common",
  },
  {
    label: "FLACEY",
    value: "28153",
    type: "common",
  },
  {
    label: "BONNEVAL",
    value: "28051",
    type: "common",
  },
  {
    label: "GOHORY",
    value: "28182",
    type: "common",
  },
  {
    label: "DANGEAU",
    value: "28127",
    type: "common",
  },
  {
    label: "YEVRES",
    value: "28424",
    type: "common",
  },
  {
    label: "BROU",
    value: "28061",
    type: "common",
  },
  {
    label: "UNVERRE",
    value: "28398",
    type: "common",
  },
  {
    label: "CHAPELLE-ROYALE",
    value: "28079",
    type: "common",
  },
  {
    label: "LES AUTELS-VILLEVILLON",
    value: "28016",
    type: "common",
  },
  {
    label: "CHARBONNIERES",
    value: "28080",
    type: "common",
  },
  {
    label: "THELIGNY",
    value: "72353",
    type: "common",
  },
  {
    label: "SAINT-ULPHACE",
    value: "72322",
    type: "common",
  },
  {
    label: "SAINT-BOMER",
    value: "28327",
    type: "common",
  },
  {
    label: "COURGENARD",
    value: "72105",
    type: "common",
  },
  {
    label: "CORMES",
    value: "72093",
    type: "common",
  },
  {
    label: "LA FERTE-BERNARD",
    value: "72132",
    type: "common",
  },
  {
    label: "SAINT-MARTIN-DES-MONTS",
    value: "72302",
    type: "common",
  },
  {
    label: "SAINT-AUBIN-DES-COUDRAIS",
    value: "72267",
    type: "common",
  },
  {
    label: "LA BOSSE",
    value: "72040",
    type: "common",
  },
  {
    label: "SAINT-DENIS-DES-COUDRAIS",
    value: "72277",
    type: "common",
  },
  {
    label: "PREVELLES",
    value: "72246",
    type: "common",
  },
  {
    label: "BONNETABLE",
    value: "72039",
    type: "common",
  },
  {
    label: "COURCEMONT",
    value: "72101",
    type: "common",
  },
  {
    label: "BRIOSNE-LES-SABLES",
    value: "72048",
    type: "common",
  },
  {
    label: "MEZIERES-SUR-PONTHOUIN",
    value: "72196",
    type: "common",
  },
  {
    label: "BEAUFAY",
    value: "72026",
    type: "common",
  },
  {
    label: "TEILLE",
    value: "72349",
    type: "common",
  },
  {
    label: "SOULIGNE-SOUS-BALLON",
    value: "72340",
    type: "common",
  },
  {
    label: "SAINTE-JAMME-SUR-SARTHE",
    value: "72289",
    type: "common",
  },
  {
    label: "MONTBIZOT",
    value: "72205",
    type: "common",
  },
  {
    label: "BALLON-SAINT MARS",
    value: "72023",
    type: "common",
  },
  {
    label: "LE TRONCHET",
    value: "72362",
    type: "common",
  },
  {
    label: "SAINTE-SABINE-SUR-LONGEVE",
    value: "72319",
    type: "common",
  },
  {
    label: "SAINT-MARCEAU",
    value: "72297",
    type: "common",
  },
  {
    label: "SAINT-JEAN-D'ASSE",
    value: "72290",
    type: "common",
  },
  {
    label: "VERNIE",
    value: "72370",
    type: "common",
  },
  {
    label: "NEUVILLALAIS",
    value: "72216",
    type: "common",
  },
  {
    label: "MEZIERES-SOUS-LAVARDIN",
    value: "72197",
    type: "common",
  },
  {
    label: "SAINT-REMY-DE-SILLE",
    value: "72315",
    type: "common",
  },
  {
    label: "CRISSE",
    value: "72109",
    type: "common",
  },
  {
    label: "ROUEZ",
    value: "72256",
    type: "common",
  },
  {
    label: "ROUESSE-VASSE",
    value: "72255",
    type: "common",
  },
  {
    label: "VOUTRE",
    value: "53276",
    type: "common",
  },
  {
    label: "SAINT-GEORGES-SUR-ERVE",
    value: "53221",
    type: "common",
  },
  {
    label: "ASSE-LE-BERENGER",
    value: "53010",
    type: "common",
  },
  {
    label: "NEAU",
    value: "53163",
    type: "common",
  },
  {
    label: "BREE",
    value: "53043",
    type: "common",
  },
  {
    label: "MONTSURS",
    value: "53161",
    type: "common",
  },
  {
    label: "GESNES",
    value: "53105",
    type: "common",
  },
  {
    label: "LOUVERNE",
    value: "53140",
    type: "common",
  },
  {
    label: "LA CHAPELLE-ANTHENAISE",
    value: "53056",
    type: "common",
  },
  {
    label: "CHALONS-DU-MAINE",
    value: "53049",
    type: "common",
  },
  {
    label: "SAINT-JEAN-SUR-MAYENNE",
    value: "53229",
    type: "common",
  },
  {
    label: "MONTFLOURS",
    value: "53156",
    type: "common",
  },
  {
    label: "SAINT-OUEN-DES-TOITS",
    value: "53243",
    type: "common",
  },
  {
    label: "SAINT-GERMAIN-LE-FOUILLOUX",
    value: "53224",
    type: "common",
  },
  {
    label: "PORT-BRILLET",
    value: "53182",
    type: "common",
  },
  {
    label: "OLIVET",
    value: "53169",
    type: "common",
  },
  {
    label: "SAINT-PIERRE-LA-COUR",
    value: "53247",
    type: "common",
  },
  {
    label: "LAUNAY-VILLIERS",
    value: "53129",
    type: "common",
  },
  {
    label: "BOURGON",
    value: "53040",
    type: "common",
  },
  {
    label: "ERBREE",
    value: "35105",
    type: "common",
  },
  {
    label: "LA CHAPELLE-ERBREE",
    value: "35061",
    type: "common",
  },
  {
    label: "VITRE",
    value: "35360",
    type: "common",
  },
  {
    label: "MONTREUIL-SOUS-PEROUSE",
    value: "35194",
    type: "common",
  },
  {
    label: "SAINT-AUBIN-DES-LANDES",
    value: "35252",
    type: "common",
  },
  {
    label: "POCE-LES-BOIS",
    value: "35229",
    type: "common",
  },
  {
    label: "CHAMPEAUX",
    value: "35052",
    type: "common",
  },
  {
    label: "SAINT-JEAN-SUR-VILAINE",
    value: "35283",
    type: "common",
  },
  {
    label: "MARPIRE",
    value: "35166",
    type: "common",
  },
  {
    label: "CHATEAUBOURG",
    value: "35068",
    type: "common",
  },
  {
    label: "SERVON-SUR-VILAINE",
    value: "35327",
    type: "common",
  },
  {
    label: "NOYAL-SUR-VILAINE",
    value: "35207",
    type: "common",
  },
  {
    label: "BRECE",
    value: "35039",
    type: "common",
  },
  {
    label: "CESSON-SEVIGNE",
    value: "35051",
    type: "common",
  },
  {
    label: "ACIGNE",
    value: "35001",
    type: "common",
  },
  {
    label: "RENNES",
    value: "35238",
    type: "common",
  },
  {
    label: "VEZIN-LE-COQUET",
    value: "35353",
    type: "common",
  },
  {
    label: "LA CHAPELLE-THOUARAULT",
    value: "35065",
    type: "common",
  },
  {
    label: "LE RHEU",
    value: "35240",
    type: "common",
  },
  {
    label: "MORDELLES",
    value: "35196",
    type: "common",
  },
  {
    label: "L'HERMITAGE",
    value: "35131",
    type: "common",
  },
  {
    label: "TALENSAC",
    value: "35331",
    type: "common",
  },
  {
    label: "CINTRE",
    value: "35080",
    type: "common",
  },
  {
    label: "MONTFORT-SUR-MEU",
    value: "35188",
    type: "common",
  },
  {
    label: "IFFENDIC",
    value: "35133",
    type: "common",
  },
  {
    label: "SAINT-GONLAY",
    value: "35277",
    type: "common",
  },
  {
    label: "SAINT-MALON-SUR-MEL",
    value: "35290",
    type: "common",
  },
  {
    label: "BLERUAIS",
    value: "35026",
    type: "common",
  },
  {
    label: "CONCORET",
    value: "56043",
    type: "common",
  },
  {
    label: "MUEL",
    value: "35201",
    type: "common",
  },
  {
    label: "SAINT-LERY",
    value: "56225",
    type: "common",
  },
  {
    label: "MAURON",
    value: "56127",
    type: "common",
  },
  {
    label: "GAEL",
    value: "35117",
    type: "common",
  },
  {
    label: "SAINT-BRIEUC-DE-MAURON",
    value: "56208",
    type: "common",
  },
  {
    label: "BRIGNAC",
    value: "56025",
    type: "common",
  },
  {
    label: "EVRIGUET",
    value: "56056",
    type: "common",
  },
  {
    label: "LA TRINITE-PORHOET",
    value: "56257",
    type: "common",
  },
  {
    label: "MOHON",
    value: "56134",
    type: "common",
  },
  {
    label: "SAINT-ETIENNE-DU-GUE-DE-L'ISLE",
    value: "22288",
    type: "common",
  },
  {
    label: "PLUMIEUX",
    value: "22241",
    type: "common",
  },
  {
    label: "LE CAMBOUT",
    value: "22027",
    type: "common",
  },
  {
    label: "ROHAN",
    value: "56198",
    type: "common",
  },
  {
    label: "BREHAN",
    value: "56024",
    type: "common",
  },
  {
    label: "GUELTAS",
    value: "56072",
    type: "common",
  },
  {
    label: "NOYAL-PONTIVY",
    value: "56151",
    type: "common",
  },
  {
    label: "LE SOURN",
    value: "56246",
    type: "common",
  },
  {
    label: "PONTIVY",
    value: "56178",
    type: "common",
  },
  {
    label: "MALGUENAC",
    value: "56125",
    type: "common",
  },
  {
    label: "LOCMALO",
    value: "56113",
    type: "common",
  },
  {
    label: "GUERN",
    value: "56076",
    type: "common",
  },
  {
    label: "GUEMENE-SUR-SCORFF",
    value: "56073",
    type: "common",
  },
  {
    label: "SAINT-CARADEC-TREGOMEL",
    value: "56210",
    type: "common",
  },
  {
    label: "PLOERDUT",
    value: "56163",
    type: "common",
  },
  {
    label: "LIGNOL",
    value: "56110",
    type: "common",
  },
  {
    label: "PRIZIAC",
    value: "56182",
    type: "common",
  },
  {
    label: "LE CROISTY",
    value: "56048",
    type: "common",
  },
  {
    label: "LE FAOUET",
    value: "56057",
    type: "common",
  },
  {
    label: "LANVENEGEN",
    value: "56105",
    type: "common",
  },
  {
    label: "GUISCRIFF",
    value: "56081",
    type: "common",
  },
  {
    label: "SCAER",
    value: "29274",
    type: "common",
  },
  {
    label: "TOURCH",
    value: "29281",
    type: "common",
  },
  {
    label: "ELLIANT",
    value: "29049",
    type: "common",
  },
  {
    label: "ERGUE-GABERIC",
    value: "29051",
    type: "common",
  },
  {
    label: "QUIMPER",
    value: "29232",
    type: "common",
  },
  {
    label: "PLONEIS",
    value: "29173",
    type: "common",
  },
  {
    label: "PLOGASTEL-SAINT-GERMAIN",
    value: "29167",
    type: "common",
  },
  {
    label: "LANDUDEC",
    value: "29108",
    type: "common",
  },
  {
    label: "GUILER-SUR-GOYEN",
    value: "29070",
    type: "common",
  },
  {
    label: "PLOZEVET",
    value: "29215",
    type: "common",
  },
  {
    label: "VOGELGRUN",
    value: "68351",
    type: "common",
  },
  {
    label: "OBERSAASHEIM",
    value: "68246",
    type: "common",
  },
  {
    label: "GEISWASSER",
    value: "68104",
    type: "common",
  },
  {
    label: "ALGOLSHEIM",
    value: "68001",
    type: "common",
  },
  {
    label: "WOLFGANTZEN",
    value: "68379",
    type: "common",
  },
  {
    label: "WECKOLSHEIM",
    value: "68360",
    type: "common",
  },
  {
    label: "VOLGELSHEIM",
    value: "68352",
    type: "common",
  },
  {
    label: "NEUF-BRISACH",
    value: "68231",
    type: "common",
  },
  {
    label: "SAINTE-CROIX-EN-PLAINE",
    value: "68295",
    type: "common",
  },
  {
    label: "NIEDERHERGHEIM",
    value: "68235",
    type: "common",
  },
  {
    label: "LOGELHEIM",
    value: "68189",
    type: "common",
  },
  {
    label: "HETTENSCHLAG",
    value: "68136",
    type: "common",
  },
  {
    label: "APPENWIHR",
    value: "68008",
    type: "common",
  },
  {
    label: "OBERMORSCHWIHR",
    value: "68244",
    type: "common",
  },
  {
    label: "HERRLISHEIM-PRES-COLMAR",
    value: "68134",
    type: "common",
  },
  {
    label: "EGUISHEIM",
    value: "68078",
    type: "common",
  },
  {
    label: "VŒGTLINSHOFFEN",
    value: "68350",
    type: "common",
  },
  {
    label: "SOULTZBACH-LES-BAINS",
    value: "68316",
    type: "common",
  },
  {
    label: "OSENBACH",
    value: "68251",
    type: "common",
  },
  {
    label: "HUSSEREN-LES-CHATEAUX",
    value: "68150",
    type: "common",
  },
  {
    label: "HATTSTATT",
    value: "68123",
    type: "common",
  },
  {
    label: "GUEBERSCHWIHR",
    value: "68111",
    type: "common",
  },
  {
    label: "WASSERBOURG",
    value: "68358",
    type: "common",
  },
  {
    label: "SOULTZMATT",
    value: "68318",
    type: "common",
  },
  {
    label: "MUNSTER",
    value: "68226",
    type: "common",
  },
  {
    label: "LUTTENBACH-PRES-MUNSTER",
    value: "68193",
    type: "common",
  },
  {
    label: "GRIESBACH-AU-VAL",
    value: "68109",
    type: "common",
  },
  {
    label: "ESCHBACH-AU-VAL",
    value: "68083",
    type: "common",
  },
  {
    label: "BREITENBACH-HAUT-RHIN",
    value: "68051",
    type: "common",
  },
  {
    label: "STOSSWIHR",
    value: "68329",
    type: "common",
  },
  {
    label: "MUHLBACH-SUR-MUNSTER",
    value: "68223",
    type: "common",
  },
  {
    label: "METZERAL",
    value: "68204",
    type: "common",
  },
  {
    label: "LA BRESSE",
    value: "88075",
    type: "common",
  },
  {
    label: "SAPOIS",
    value: "88442",
    type: "common",
  },
  {
    label: "ROCHESSON",
    value: "88391",
    type: "common",
  },
  {
    label: "VAGNEY",
    value: "88486",
    type: "common",
  },
  {
    label: "LE SYNDICAT",
    value: "88462",
    type: "common",
  },
  {
    label: "LA FORGE",
    value: "88177",
    type: "common",
  },
  {
    label: "CLEURIE",
    value: "88109",
    type: "common",
  },
  {
    label: "SAINT-ETIENNE-LES-REMIREMONT",
    value: "88415",
    type: "common",
  },
  {
    label: "SAINT-AME",
    value: "88409",
    type: "common",
  },
  {
    label: "SAINT-NABORD",
    value: "88429",
    type: "common",
  },
  {
    label: "RAON-AUX-BOIS",
    value: "88371",
    type: "common",
  },
  {
    label: "HADOL",
    value: "88225",
    type: "common",
  },
  {
    label: "XERTIGNY",
    value: "88530",
    type: "common",
  },
  {
    label: "LES VOIVRES",
    value: "88520",
    type: "common",
  },
  {
    label: "LA HAYE",
    value: "88236",
    type: "common",
  },
  {
    label: "GRUEY-LES-SURANCE",
    value: "88221",
    type: "common",
  },
  {
    label: "GRANDRUPT-DE-BAINS",
    value: "88214",
    type: "common",
  },
  {
    label: "HENNEZEL",
    value: "88238",
    type: "common",
  },
  {
    label: "DARNEY",
    value: "88124",
    type: "common",
  },
  {
    label: "BELRUPT",
    value: "88052",
    type: "common",
  },
  {
    label: "NONVILLE",
    value: "88330",
    type: "common",
  },
  {
    label: "BELMONT-LES-DARNEY",
    value: "88049",
    type: "common",
  },
  {
    label: "ATTIGNY",
    value: "88016",
    type: "common",
  },
  {
    label: "TIGNECOURT",
    value: "88473",
    type: "common",
  },
  {
    label: "SEROCOURT",
    value: "88456",
    type: "common",
  },
  {
    label: "MAREY",
    value: "88287",
    type: "common",
  },
  {
    label: "FRAIN",
    value: "88180",
    type: "common",
  },
  {
    label: "BLEURVILLE",
    value: "88061",
    type: "common",
  },
  {
    label: "SERECOURT",
    value: "88455",
    type: "common",
  },
  {
    label: "MORIZECOURT",
    value: "88314",
    type: "common",
  },
  {
    label: "VILLOTTE",
    value: "88510",
    type: "common",
  },
  {
    label: "TOLLAINCOURT",
    value: "88475",
    type: "common",
  },
  {
    label: "ROMAIN-AUX-BOIS",
    value: "88394",
    type: "common",
  },
  {
    label: "LAMARCHE",
    value: "88258",
    type: "common",
  },
  {
    label: "DAMBLAIN",
    value: "88123",
    type: "common",
  },
  {
    label: "BASSONCOURT",
    value: "52038",
    type: "common",
  },
  {
    label: "BREUVANNES-EN-BASSIGNY",
    value: "52074",
    type: "common",
  },
  {
    label: "MERREY",
    value: "52320",
    type: "common",
  },
  {
    label: "CHOISEUL",
    value: "52127",
    type: "common",
  },
  {
    label: "PERRUSSE",
    value: "52385",
    type: "common",
  },
  {
    label: "NOYERS",
    value: "52358",
    type: "common",
  },
  {
    label: "DAILLECOURT",
    value: "52161",
    type: "common",
  },
  {
    label: "BUXIERES-LES-CLEFMONT",
    value: "52085",
    type: "common",
  },
  {
    label: "NINVILLE",
    value: "52352",
    type: "common",
  },
  {
    label: "MENNOUVEAUX",
    value: "52319",
    type: "common",
  },
  {
    label: "CUVES",
    value: "52159",
    type: "common",
  },
  {
    label: "LANQUES-SUR-ROGNON",
    value: "52271",
    type: "common",
  },
  {
    label: "SARCEY",
    value: "52459",
    type: "common",
  },
  {
    label: "MANDRES-LA-COTE",
    value: "52305",
    type: "common",
  },
  {
    label: "BIESLES",
    value: "52050",
    type: "common",
  },
  {
    label: "AGEVILLE",
    value: "52001",
    type: "common",
  },
  {
    label: "CHAMARANDES-CHOIGNES",
    value: "52125",
    type: "common",
  },
  {
    label: "LAVILLE-AUX-BOIS",
    value: "52276",
    type: "common",
  },
  {
    label: "VERBIESLES",
    value: "52514",
    type: "common",
  },
  {
    label: "CHAUMONT",
    value: "52121",
    type: "common",
  },
  {
    label: "SEMOUTIERS-MONTSAON",
    value: "52469",
    type: "common",
  },
  {
    label: "VILLIERS-LE-SEC",
    value: "52535",
    type: "common",
  },
  {
    label: "BUXIERES-LES-VILLIERS",
    value: "52087",
    type: "common",
  },
  {
    label: "BRAUX-LE-CHATEL",
    value: "52069",
    type: "common",
  },
  {
    label: "ORGES",
    value: "52365",
    type: "common",
  },
  {
    label: "BRICON",
    value: "52076",
    type: "common",
  },
  {
    label: "PONT-LA-VILLE",
    value: "52399",
    type: "common",
  },
  {
    label: "CIRFONTAINES-EN-AZOIS",
    value: "52130",
    type: "common",
  },
  {
    label: "AIZANVILLE",
    value: "52005",
    type: "common",
  },
  {
    label: "LAFERTE-SUR-AUBE",
    value: "52258",
    type: "common",
  },
  {
    label: "JUVANCOURT",
    value: "10182",
    type: "common",
  },
  {
    label: "VILLARS-EN-AZOIS",
    value: "52525",
    type: "common",
  },
  {
    label: "CHAMPIGNOL-LEZ-MONDEVILLE",
    value: "10076",
    type: "common",
  },
  {
    label: "SAINT-USAGE",
    value: "10364",
    type: "common",
  },
  {
    label: "NOE-LES-MALLETS",
    value: "10264",
    type: "common",
  },
  {
    label: "FONTETTE",
    value: "10155",
    type: "common",
  },
  {
    label: "VIVIERS-SUR-ARTAUT",
    value: "10439",
    type: "common",
  },
  {
    label: "CHACENAY",
    value: "10071",
    type: "common",
  },
  {
    label: "LANDREVILLE",
    value: "10187",
    type: "common",
  },
  {
    label: "VILLE-SUR-ARCE",
    value: "10427",
    type: "common",
  },
  {
    label: "MERREY-SUR-ARCE",
    value: "10232",
    type: "common",
  },
  {
    label: "BAR-SUR-SEINE",
    value: "10034",
    type: "common",
  },
  {
    label: "VILLEMORIEN",
    value: "10418",
    type: "common",
  },
  {
    label: "VIREY-SOUS-BAR",
    value: "10437",
    type: "common",
  },
  {
    label: "VOUGREY",
    value: "10443",
    type: "common",
  },
  {
    label: "JULLY-SUR-SARCE",
    value: "10181",
    type: "common",
  },
  {
    label: "RUMILLY-LES-VAUDES",
    value: "10331",
    type: "common",
  },
  {
    label: "LES LOGES-MARGUERON",
    value: "10202",
    type: "common",
  },
  {
    label: "LA VENDUE-MIGNOT",
    value: "10402",
    type: "common",
  },
  {
    label: "MAUPAS",
    value: "10229",
    type: "common",
  },
  {
    label: "JEUGNY",
    value: "10179",
    type: "common",
  },
  {
    label: "SAINT-PHAL",
    value: "10359",
    type: "common",
  },
  {
    label: "CHAMOY",
    value: "10074",
    type: "common",
  },
  {
    label: "FAYS-LA-CHAPELLE",
    value: "10147",
    type: "common",
  },
  {
    label: "MARAYE-EN-OTHE",
    value: "10222",
    type: "common",
  },
  {
    label: "EAUX-PUISEAUX",
    value: "10133",
    type: "common",
  },
  {
    label: "AUXON",
    value: "10018",
    type: "common",
  },
  {
    label: "NOGENT-EN-OTHE",
    value: "10266",
    type: "common",
  },
  {
    label: "VILLENEUVE-AU-CHEMIN",
    value: "10422",
    type: "common",
  },
  {
    label: "VOSNON",
    value: "10441",
    type: "common",
  },
  {
    label: "SORMERY",
    value: "89398",
    type: "common",
  },
  {
    label: "BŒURS-EN-OTHE",
    value: "89048",
    type: "common",
  },
  {
    label: "CHAILLEY",
    value: "89069",
    type: "common",
  },
  {
    label: "ARCES-DILO",
    value: "89014",
    type: "common",
  },
  {
    label: "VILLECHETIVE",
    value: "89451",
    type: "common",
  },
  {
    label: "VAUDEURS",
    value: "89432",
    type: "common",
  },
  {
    label: "CERISIERS",
    value: "89066",
    type: "common",
  },
  {
    label: "VAUMORT",
    value: "89434",
    type: "common",
  },
  {
    label: "VERON",
    value: "89443",
    type: "common",
  },
  {
    label: "LES BORDES",
    value: "89051",
    type: "common",
  },
  {
    label: "ROUSSON",
    value: "89327",
    type: "common",
  },
  {
    label: "PASSY",
    value: "89291",
    type: "common",
  },
  {
    label: "MARSANGY",
    value: "89245",
    type: "common",
  },
  {
    label: "GRON",
    value: "89195",
    type: "common",
  },
  {
    label: "ETIGNY",
    value: "89160",
    type: "common",
  },
  {
    label: "EGRISELLES-LE-BOCAGE",
    value: "89151",
    type: "common",
  },
  {
    label: "CORNANT",
    value: "89116",
    type: "common",
  },
  {
    label: "VILLENEUVE-LA-DONDAGRE",
    value: "89459",
    type: "common",
  },
  {
    label: "VERNOY",
    value: "89442",
    type: "common",
  },
  {
    label: "COURTOIN",
    value: "89126",
    type: "common",
  },
  {
    label: "LA BELLIOLE",
    value: "89036",
    type: "common",
  },
  {
    label: "DOMATS",
    value: "89144",
    type: "common",
  },
  {
    label: "FOUCHEROLLES",
    value: "45149",
    type: "common",
  },
  {
    label: "ERVAUVILLE",
    value: "45136",
    type: "common",
  },
  {
    label: "BAZOCHES-SUR-LE-BETZ",
    value: "45026",
    type: "common",
  },
  {
    label: "LE BIGNON-MIRABEAU",
    value: "45032",
    type: "common",
  },
  {
    label: "CHEVRY-SOUS-LE-BIGNON",
    value: "45094",
    type: "common",
  },
  {
    label: "ROZOY-LE-VIEIL",
    value: "45265",
    type: "common",
  },
  {
    label: "PERS-EN-GATINAIS",
    value: "45250",
    type: "common",
  },
  {
    label: "CHEVANNES",
    value: "45091",
    type: "common",
  },
  {
    label: "FERRIERES-EN-GATINAIS",
    value: "45145",
    type: "common",
  },
  {
    label: "BRANSLES",
    value: "77050",
    type: "common",
  },
  {
    label: "NARGIS",
    value: "45222",
    type: "common",
  },
  {
    label: "DORDIVES",
    value: "45127",
    type: "common",
  },
  {
    label: "PREFONTAINES",
    value: "45255",
    type: "common",
  },
  {
    label: "COURTEMPIERRE",
    value: "45114",
    type: "common",
  },
  {
    label: "CHATEAU-LANDON",
    value: "77099",
    type: "common",
  },
  {
    label: "SCEAUX-DU-GATINAIS",
    value: "45303",
    type: "common",
  },
  {
    label: "MONDREVILLE",
    value: "77297",
    type: "common",
  },
  {
    label: "BORDEAUX-EN-GATINAIS",
    value: "45041",
    type: "common",
  },
  {
    label: "BEAUMONT-DU-GATINAIS",
    value: "77027",
    type: "common",
  },
  {
    label: "AUXY",
    value: "45018",
    type: "common",
  },
  {
    label: "BOESSES",
    value: "45033",
    type: "common",
  },
  {
    label: "GIVRAINES",
    value: "45157",
    type: "common",
  },
  {
    label: "GAUBERTIN",
    value: "45151",
    type: "common",
  },
  {
    label: "EGRY",
    value: "45132",
    type: "common",
  },
  {
    label: "BARVILLE-EN-GATINAIS",
    value: "45021",
    type: "common",
  },
  {
    label: "YEVRE-LA-VILLE",
    value: "45348",
    type: "common",
  },
  {
    label: "COURCELLES-LE-ROI",
    value: "45110",
    type: "common",
  },
  {
    label: "BOYNES",
    value: "45050",
    type: "common",
  },
  {
    label: "BOUILLY-EN-GATINAIS",
    value: "45045",
    type: "common",
  },
  {
    label: "MAREAU-AUX-BOIS",
    value: "45195",
    type: "common",
  },
  {
    label: "LAAS",
    value: "45177",
    type: "common",
  },
  {
    label: "ESCRENNES",
    value: "45137",
    type: "common",
  },
  {
    label: "DADONVILLE",
    value: "45119",
    type: "common",
  },
  {
    label: "BOUZONVILLE-AUX-BOIS",
    value: "45047",
    type: "common",
  },
  {
    label: "ASCOUX",
    value: "45010",
    type: "common",
  },
  {
    label: "SANTEAU",
    value: "45301",
    type: "common",
  },
  {
    label: "MONTIGNY",
    value: "45214",
    type: "common",
  },
  {
    label: "JOUY-EN-PITHIVERAIS",
    value: "45174",
    type: "common",
  },
  {
    label: "ATTRAY",
    value: "45011",
    type: "common",
  },
  {
    label: "CROTTES-EN-PITHIVERAIS",
    value: "45118",
    type: "common",
  },
  {
    label: "RUAN",
    value: "45266",
    type: "common",
  },
  {
    label: "OISON",
    value: "45231",
    type: "common",
  },
  {
    label: "LION-EN-BEAUCE",
    value: "45183",
    type: "common",
  },
  {
    label: "ASCHERES-LE-MARCHE",
    value: "45009",
    type: "common",
  },
  {
    label: "DAMBRON",
    value: "28121",
    type: "common",
  },
  {
    label: "POUPRY",
    value: "28303",
    type: "common",
  },
  {
    label: "LUMEAU",
    value: "28221",
    type: "common",
  },
  {
    label: "LOIGNY-LA-BATAILLE",
    value: "28212",
    type: "common",
  },
  {
    label: "BAIGNEAUX",
    value: "28019",
    type: "common",
  },
  {
    label: "ORGERES-EN-BEAUCE",
    value: "28287",
    type: "common",
  },
  {
    label: "GUILLONVILLE",
    value: "28190",
    type: "common",
  },
  {
    label: "CORMAINVILLE",
    value: "28108",
    type: "common",
  },
  {
    label: "BAZOCHES-EN-DUNOIS",
    value: "28028",
    type: "common",
  },
  {
    label: "VILLIERS-SAINT-ORIEN",
    value: "28418",
    type: "common",
  },
  {
    label: "NOTTONVILLE",
    value: "28283",
    type: "common",
  },
  {
    label: "MOLEANS",
    value: "28256",
    type: "common",
  },
  {
    label: "CONIE-MOLITARD",
    value: "28106",
    type: "common",
  },
  {
    label: "SAINT-CHRISTOPHE",
    value: "28329",
    type: "common",
  },
  {
    label: "MARBOUE",
    value: "28233",
    type: "common",
  },
  {
    label: "DONNEMAIN-SAINT-MAMES",
    value: "28132",
    type: "common",
  },
  {
    label: "LOGRON",
    value: "28211",
    type: "common",
  },
  {
    label: "COMMUNE NOUVELLE D'ARROU",
    value: "28012",
    type: "common",
  },
  {
    label: "LE GAULT-DU-PERCHE",
    value: "41096",
    type: "common",
  },
  {
    label: "LA BAZOCHE-GOUET",
    value: "28027",
    type: "common",
  },
  {
    label: "CHAPELLE-GUILLAUME",
    value: "28078",
    type: "common",
  },
  {
    label: "MELLERAY",
    value: "72193",
    type: "common",
  },
  {
    label: "GREEZ-SUR-ROC",
    value: "72144",
    type: "common",
  },
  {
    label: "LE PLESSIS-DORIN",
    value: "41177",
    type: "common",
  },
  {
    label: "SAINT-JEAN-DES-ECHELLES",
    value: "72292",
    type: "common",
  },
  {
    label: "MONTMIRAIL",
    value: "72208",
    type: "common",
  },
  {
    label: "LAMNAY",
    value: "72156",
    type: "common",
  },
  {
    label: "VILLAINES-LA-GONAIS",
    value: "72375",
    type: "common",
  },
  {
    label: "SAINT-MAIXENT",
    value: "72296",
    type: "common",
  },
  {
    label: "VOUVRAY-SUR-HUISNE",
    value: "72383",
    type: "common",
  },
  {
    label: "TUFFE VAL DE LA CHERONNE",
    value: "72363",
    type: "common",
  },
  {
    label: "SCEAUX-SUR-HUISNE",
    value: "72331",
    type: "common",
  },
  {
    label: "BOESSE-LE-SEC",
    value: "72038",
    type: "common",
  },
  {
    label: "SAINT-CELERIN",
    value: "72271",
    type: "common",
  },
  {
    label: "LA CHAPELLE-SAINT-REMY",
    value: "72067",
    type: "common",
  },
  {
    label: "TORCE-EN-VALLEE",
    value: "72359",
    type: "common",
  },
  {
    label: "SILLE-LE-PHILIPPE",
    value: "72335",
    type: "common",
  },
  {
    label: "SAINT-CORNEILLE",
    value: "72275",
    type: "common",
  },
  {
    label: "LOMBRON",
    value: "72165",
    type: "common",
  },
  {
    label: "SAVIGNE-L'EVEQUE",
    value: "72329",
    type: "common",
  },
  {
    label: "COURCEBŒUFS",
    value: "72099",
    type: "common",
  },
  {
    label: "SOUILLE",
    value: "72338",
    type: "common",
  },
  {
    label: "JOUE-L'ABBE",
    value: "72150",
    type: "common",
  },
  {
    label: "LA GUIERCHE",
    value: "72147",
    type: "common",
  },
  {
    label: "LA CHAPELLE-SAINT-FRAY",
    value: "72066",
    type: "common",
  },
  {
    label: "LA BAZOGE",
    value: "72024",
    type: "common",
  },
  {
    label: "LAVARDIN",
    value: "72157",
    type: "common",
  },
  {
    label: "DOMFRONT-EN-CHAMPAGNE",
    value: "72119",
    type: "common",
  },
  {
    label: "CURES",
    value: "72111",
    type: "common",
  },
  {
    label: "CONLIE",
    value: "72089",
    type: "common",
  },
  {
    label: "TENNIE",
    value: "72351",
    type: "common",
  },
  {
    label: "SAINT-SYMPHORIEN",
    value: "72321",
    type: "common",
  },
  {
    label: "PARENNES",
    value: "72229",
    type: "common",
  },
  {
    label: "NEUVILLETTE-EN-CHARNIE",
    value: "72218",
    type: "common",
  },
  {
    label: "TORCE-VIVIERS-EN-CHARNIE",
    value: "53265",
    type: "common",
  },
  {
    label: "SAINTE-SUZANNE-ET-CHAMMES",
    value: "53255",
    type: "common",
  },
  {
    label: "SAINT-LEGER",
    value: "53232",
    type: "common",
  },
  {
    label: "LIVET",
    value: "53134",
    type: "common",
  },
  {
    label: "LA CHAPELLE-RAINSOUIN",
    value: "53059",
    type: "common",
  },
  {
    label: "SOULGE-SUR-OUETTE",
    value: "53262",
    type: "common",
  },
  {
    label: "ARGENTRE",
    value: "53007",
    type: "common",
  },
  {
    label: "BONCHAMP-LES-LAVAL",
    value: "53034",
    type: "common",
  },
  {
    label: "LAVAL",
    value: "53130",
    type: "common",
  },
  {
    label: "CHANGE",
    value: "53054",
    type: "common",
  },
  {
    label: "SAINT-BERTHEVIN",
    value: "53201",
    type: "common",
  },
  {
    label: "LE GENEST-SAINT-ISLE",
    value: "53103",
    type: "common",
  },
  {
    label: "LOIRON-RUILLE",
    value: "53137",
    type: "common",
  },
  {
    label: "LA BRULATTE",
    value: "53045",
    type: "common",
  },
  {
    label: "LA GRAVELLE",
    value: "53108",
    type: "common",
  },
  {
    label: "BREAL-SOUS-VITRE",
    value: "35038",
    type: "common",
  },
  {
    label: "MONDEVERT",
    value: "35183",
    type: "common",
  },
  {
    label: "ETRELLES",
    value: "35109",
    type: "common",
  },
  {
    label: "TORCE",
    value: "35338",
    type: "common",
  },
  {
    label: "CORNILLE",
    value: "35087",
    type: "common",
  },
  {
    label: "SAINT-DIDIER",
    value: "35264",
    type: "common",
  },
  {
    label: "LOUVIGNE-DE-BAIS",
    value: "35161",
    type: "common",
  },
  {
    label: "DOMAGNE",
    value: "35096",
    type: "common",
  },
  {
    label: "CHATEAUGIRON",
    value: "35069",
    type: "common",
  },
  {
    label: "NOUVOITOU",
    value: "35204",
    type: "common",
  },
  {
    label: "DOMLOUP",
    value: "35099",
    type: "common",
  },
  {
    label: "NOYAL-CHATILLON-SUR-SEICHE",
    value: "35206",
    type: "common",
  },
  {
    label: "VERN-SUR-SEICHE",
    value: "35352",
    type: "common",
  },
  {
    label: "CHANTEPIE",
    value: "35055",
    type: "common",
  },
  {
    label: "CHARTRES-DE-BRETAGNE",
    value: "35066",
    type: "common",
  },
  {
    label: "BRUZ",
    value: "35047",
    type: "common",
  },
  {
    label: "SAINT-JACQUES-DE-LA-LANDE",
    value: "35281",
    type: "common",
  },
  {
    label: "CHAVAGNE",
    value: "35076",
    type: "common",
  },
  {
    label: "LE VERGER",
    value: "35351",
    type: "common",
  },
  {
    label: "SAINT-THURIAL",
    value: "35319",
    type: "common",
  },
  {
    label: "BREAL-SOUS-MONTFORT",
    value: "35037",
    type: "common",
  },
  {
    label: "TREFFENDEL",
    value: "35340",
    type: "common",
  },
  {
    label: "MONTERFIL",
    value: "35187",
    type: "common",
  },
  {
    label: "SAINT-PERAN",
    value: "35305",
    type: "common",
  },
  {
    label: "PAIMPONT",
    value: "35211",
    type: "common",
  },
  {
    label: "TREHORENTEUC",
    value: "56256",
    type: "common",
  },
  {
    label: "NEANT-SUR-YVEL",
    value: "56145",
    type: "common",
  },
  {
    label: "SAINT-MALO-DES-TROIS-FONTAINES",
    value: "56227",
    type: "common",
  },
  {
    label: "GUILLIERS",
    value: "56080",
    type: "common",
  },
  {
    label: "LA GREE-SAINT-LAURENT",
    value: "56068",
    type: "common",
  },
  {
    label: "PLEUGRIFFET",
    value: "56160",
    type: "common",
  },
  {
    label: "REGUINY",
    value: "56190",
    type: "common",
  },
  {
    label: "CREDIN",
    value: "56047",
    type: "common",
  },
  {
    label: "EVELLYS",
    value: "56144",
    type: "common",
  },
  {
    label: "KERFOURN",
    value: "56092",
    type: "common",
  },
  {
    label: "SAINT-THURIAU",
    value: "56237",
    type: "common",
  },
  {
    label: "MELRAND",
    value: "56128",
    type: "common",
  },
  {
    label: "PERSQUEN",
    value: "56156",
    type: "common",
  },
  {
    label: "BUBRY",
    value: "56026",
    type: "common",
  },
  {
    label: "KERNASCLEDEN",
    value: "56264",
    type: "common",
  },
  {
    label: "BERNE",
    value: "56014",
    type: "common",
  },
  {
    label: "MESLAN",
    value: "56131",
    type: "common",
  },
  {
    label: "QUERRIEN",
    value: "29230",
    type: "common",
  },
  {
    label: "SAINT-THURIEN",
    value: "29269",
    type: "common",
  },
  {
    label: "BANNALEC",
    value: "29004",
    type: "common",
  },
  {
    label: "ROSPORDEN",
    value: "29241",
    type: "common",
  },
  {
    label: "SAINT-YVI",
    value: "29272",
    type: "common",
  },
  {
    label: "SAINT-EVARZEC",
    value: "29247",
    type: "common",
  },
  {
    label: "GOUESNACH",
    value: "29060",
    type: "common",
  },
  {
    label: "PLUGUFFAN",
    value: "29216",
    type: "common",
  },
  {
    label: "PLOMELIN",
    value: "29170",
    type: "common",
  },
  {
    label: "PLONEOUR-LANVERN",
    value: "29174",
    type: "common",
  },
  {
    label: "PEUMERIT",
    value: "29159",
    type: "common",
  },
  {
    label: "TREOGAT",
    value: "29298",
    type: "common",
  },
  {
    label: "POULDREUZIC",
    value: "29225",
    type: "common",
  },
  {
    label: "PLOVAN",
    value: "29214",
    type: "common",
  },
  {
    label: "NAMBSHEIM",
    value: "68230",
    type: "common",
  },
  {
    label: "HEITEREN",
    value: "68130",
    type: "common",
  },
  {
    label: "RUSTENHART",
    value: "68290",
    type: "common",
  },
  {
    label: "DESSENHEIM",
    value: "68069",
    type: "common",
  },
  {
    label: "BALGAU",
    value: "68016",
    type: "common",
  },
  {
    label: "OBERHERGHEIM",
    value: "68242",
    type: "common",
  },
  {
    label: "OBERENTZEN",
    value: "68241",
    type: "common",
  },
  {
    label: "NIEDERENTZEN",
    value: "68234",
    type: "common",
  },
  {
    label: "BILTZHEIM",
    value: "68037",
    type: "common",
  },
  {
    label: "ROUFFACH",
    value: "68287",
    type: "common",
  },
  {
    label: "MUNWILLER",
    value: "68228",
    type: "common",
  },
  {
    label: "WESTHALTEN",
    value: "68364",
    type: "common",
  },
  {
    label: "PFAFFENHEIM",
    value: "68255",
    type: "common",
  },
  {
    label: "ORSCHWIHR",
    value: "68250",
    type: "common",
  },
  {
    label: "LAUTENBACH",
    value: "68177",
    type: "common",
  },
  {
    label: "SONDERNACH",
    value: "68311",
    type: "common",
  },
  {
    label: "LINTHAL",
    value: "68188",
    type: "common",
  },
  {
    label: "WILDENSTEIN",
    value: "68370",
    type: "common",
  },
  {
    label: "MITTLACH",
    value: "68210",
    type: "common",
  },
  {
    label: "KRUTH",
    value: "68171",
    type: "common",
  },
  {
    label: "CORNIMONT",
    value: "88116",
    type: "common",
  },
  {
    label: "SAULXURES-SUR-MOSELOTTE",
    value: "88447",
    type: "common",
  },
  {
    label: "GERBAMONT",
    value: "88197",
    type: "common",
  },
  {
    label: "BASSE-SUR-LE-RUPT",
    value: "88037",
    type: "common",
  },
  {
    label: "THIEFOSSE",
    value: "88467",
    type: "common",
  },
  {
    label: "VECOUX",
    value: "88498",
    type: "common",
  },
  {
    label: "REMIREMONT",
    value: "88383",
    type: "common",
  },
  {
    label: "DOMMARTIN-LES-REMIREMONT",
    value: "88148",
    type: "common",
  },
  {
    label: "PLOMBIERES-LES-BAINS",
    value: "88351",
    type: "common",
  },
  {
    label: "BELLEFONTAINE",
    value: "88048",
    type: "common",
  },
  {
    label: "LA CHAPELLE-AUX-BOIS",
    value: "88088",
    type: "common",
  },
  {
    label: "LA VOGE-LES-BAINS",
    value: "88029",
    type: "common",
  },
  {
    label: "MONTMOTIER",
    value: "88311",
    type: "common",
  },
  {
    label: "FONTENOY-LE-CHATEAU",
    value: "88176",
    type: "common",
  },
  {
    label: "AMBIEVILLERS",
    value: "70013",
    type: "common",
  },
  {
    label: "PONT-DU-BOIS",
    value: "70419",
    type: "common",
  },
  {
    label: "PASSAVANT-LA-ROCHERE",
    value: "70404",
    type: "common",
  },
  {
    label: "REGNEVELLE",
    value: "88377",
    type: "common",
  },
  {
    label: "MONTHUREUX-SUR-SAONE",
    value: "88310",
    type: "common",
  },
  {
    label: "MARTINVELLE",
    value: "88291",
    type: "common",
  },
  {
    label: "CLAUDON",
    value: "88105",
    type: "common",
  },
  {
    label: "LES THONS",
    value: "88471",
    type: "common",
  },
  {
    label: "SAINT-JULIEN",
    value: "88421",
    type: "common",
  },
  {
    label: "GODONCOURT",
    value: "88208",
    type: "common",
  },
  {
    label: "MONT-LES-LAMARCHE",
    value: "88307",
    type: "common",
  },
  {
    label: "ISCHES",
    value: "88248",
    type: "common",
  },
  {
    label: "FOUCHECOURT",
    value: "88179",
    type: "common",
  },
  {
    label: "AINVELLE",
    value: "88004",
    type: "common",
  },
  {
    label: "LARIVIERE-ARNONCOURT",
    value: "52273",
    type: "common",
  },
  {
    label: "SERQUEUX",
    value: "52470",
    type: "common",
  },
  {
    label: "AIGREMONT",
    value: "52002",
    type: "common",
  },
  {
    label: "PARNOY-EN-BASSIGNY",
    value: "52377",
    type: "common",
  },
  {
    label: "VAL-DE-MEUSE",
    value: "52332",
    type: "common",
  },
  {
    label: "RANGECOURT",
    value: "52416",
    type: "common",
  },
  {
    label: "IS-EN-BASSIGNY",
    value: "52248",
    type: "common",
  },
  {
    label: "LAVILLENEUVE",
    value: "52277",
    type: "common",
  },
  {
    label: "NOGENT",
    value: "52353",
    type: "common",
  },
  {
    label: "SARREY",
    value: "52461",
    type: "common",
  },
  {
    label: "LOUVIERES",
    value: "52295",
    type: "common",
  },
  {
    label: "VESAIGNES-SUR-MARNE",
    value: "52518",
    type: "common",
  },
  {
    label: "POULANGY",
    value: "52401",
    type: "common",
  },
  {
    label: "MARNAY-SUR-MARNE",
    value: "52315",
    type: "common",
  },
  {
    label: "LUZY-SUR-MARNE",
    value: "52297",
    type: "common",
  },
  {
    label: "FOULAIN",
    value: "52205",
    type: "common",
  },
  {
    label: "NEUILLY-SUR-SUIZE",
    value: "52349",
    type: "common",
  },
  {
    label: "BLESSONVILLE",
    value: "52056",
    type: "common",
  },
  {
    label: "CHATEAUVILLAIN",
    value: "52114",
    type: "common",
  },
  {
    label: "LANTY-SUR-AUBE",
    value: "52272",
    type: "common",
  },
  {
    label: "SILVAROUVRES",
    value: "52474",
    type: "common",
  },
  {
    label: "DINTEVILLE",
    value: "52168",
    type: "common",
  },
  {
    label: "CUNFIN",
    value: "10119",
    type: "common",
  },
  {
    label: "VERPILLIERES-SUR-OURCE",
    value: "10404",
    type: "common",
  },
  {
    label: "LOCHES-SUR-OURCE",
    value: "10199",
    type: "common",
  },
  {
    label: "ESSOYES",
    value: "10141",
    type: "common",
  },
  {
    label: "NEUVILLE-SUR-SEINE",
    value: "10262",
    type: "common",
  },
  {
    label: "COURTERON",
    value: "10111",
    type: "common",
  },
  {
    label: "CELLES-SUR-OURCE",
    value: "10070",
    type: "common",
  },
  {
    label: "GYE-SUR-SEINE",
    value: "10170",
    type: "common",
  },
  {
    label: "BUXEUIL",
    value: "10068",
    type: "common",
  },
  {
    label: "BALNOT-SUR-LAIGNES",
    value: "10029",
    type: "common",
  },
  {
    label: "AVIREY-LINGEY",
    value: "10022",
    type: "common",
  },
  {
    label: "POLISY",
    value: "10296",
    type: "common",
  },
  {
    label: "POLISOT",
    value: "10295",
    type: "common",
  },
  {
    label: "ARRELLES",
    value: "10009",
    type: "common",
  },
  {
    label: "VILLIERS-SOUS-PRASLIN",
    value: "10432",
    type: "common",
  },
  {
    label: "PRASLIN",
    value: "10302",
    type: "common",
  },
  {
    label: "LANTAGES",
    value: "10188",
    type: "common",
  },
  {
    label: "CHAOURCE",
    value: "10080",
    type: "common",
  },
  {
    label: "LES GRANGES",
    value: "10168",
    type: "common",
  },
  {
    label: "TURGY",
    value: "10388",
    type: "common",
  },
  {
    label: "METZ-ROBERT",
    value: "10241",
    type: "common",
  },
  {
    label: "LA LOGE-POMBLIN",
    value: "10201",
    type: "common",
  },
  {
    label: "MONTIGNY-LES-MONTS",
    value: "10251",
    type: "common",
  },
  {
    label: "DAVREY",
    value: "10122",
    type: "common",
  },
  {
    label: "AVREUIL",
    value: "10024",
    type: "common",
  },
  {
    label: "ERVY-LE-CHATEL",
    value: "10140",
    type: "common",
  },
  {
    label: "MONTFEY",
    value: "10247",
    type: "common",
  },
  {
    label: "COURTAOULT",
    value: "10108",
    type: "common",
  },
  {
    label: "RACINES",
    value: "10312",
    type: "common",
  },
  {
    label: "COURSAN-EN-OTHE",
    value: "10107",
    type: "common",
  },
  {
    label: "NEUVY-SAUTOUR",
    value: "89276",
    type: "common",
  },
  {
    label: "LASSON",
    value: "89219",
    type: "common",
  },
  {
    label: "TURNY",
    value: "89425",
    type: "common",
  },
  {
    label: "MERCY",
    value: "89249",
    type: "common",
  },
  {
    label: "VENIZY",
    value: "89436",
    type: "common",
  },
  {
    label: "CHAMPLOST",
    value: "89076",
    type: "common",
  },
  {
    label: "PAROY-EN-OTHE",
    value: "89288",
    type: "common",
  },
  {
    label: "BELLECHAUME",
    value: "89035",
    type: "common",
  },
  {
    label: "BUSSY-EN-OTHE",
    value: "89059",
    type: "common",
  },
  {
    label: "DIXMONT",
    value: "89142",
    type: "common",
  },
  {
    label: "ARMEAU",
    value: "89018",
    type: "common",
  },
  {
    label: "VILLENEUVE-SUR-YONNE",
    value: "89464",
    type: "common",
  },
  {
    label: "SAINT-JULIEN-DU-SAULT",
    value: "89348",
    type: "common",
  },
  {
    label: "CHAUMOT",
    value: "89094",
    type: "common",
  },
  {
    label: "BUSSY-LE-REPOS",
    value: "89060",
    type: "common",
  },
  {
    label: "SAVIGNY-SUR-CLAIRIS",
    value: "89380",
    type: "common",
  },
  {
    label: "PIFFONDS",
    value: "89298",
    type: "common",
  },
  {
    label: "SAINT-HILAIRE-LES-ANDRESIS",
    value: "45281",
    type: "common",
  },
  {
    label: "LA SELLE-SUR-LE-BIED",
    value: "45307",
    type: "common",
  },
  {
    label: "MERINVILLE",
    value: "45201",
    type: "common",
  },
  {
    label: "COURTEMAUX",
    value: "45113",
    type: "common",
  },
  {
    label: "CHANTECOQ",
    value: "45073",
    type: "common",
  },
  {
    label: "GRISELLES",
    value: "45161",
    type: "common",
  },
  {
    label: "LOUZOUER",
    value: "45189",
    type: "common",
  },
  {
    label: "FONTENAY-SUR-LOING",
    value: "45148",
    type: "common",
  },
  {
    label: "GIROLLES",
    value: "45156",
    type: "common",
  },
  {
    label: "CEPOY",
    value: "45061",
    type: "common",
  },
  {
    label: "TREILLES-EN-GATINAIS",
    value: "45328",
    type: "common",
  },
  {
    label: "GONDREVILLE",
    value: "45158",
    type: "common",
  },
  {
    label: "CORQUILLEROY",
    value: "45104",
    type: "common",
  },
  {
    label: "MIGNERES",
    value: "45206",
    type: "common",
  },
  {
    label: "MIGNERETTE",
    value: "45207",
    type: "common",
  },
  {
    label: "CORBEILLES",
    value: "45103",
    type: "common",
  },
  {
    label: "CHAPELON",
    value: "45078",
    type: "common",
  },
  {
    label: "JURANVILLE",
    value: "45176",
    type: "common",
  },
  {
    label: "SAINT-MICHEL",
    value: "45294",
    type: "common",
  },
  {
    label: "SAINT-LOUP-DES-VIGNES",
    value: "45288",
    type: "common",
  },
  {
    label: "MONTBARROIS",
    value: "45209",
    type: "common",
  },
  {
    label: "BEAUNE-LA-ROLANDE",
    value: "45030",
    type: "common",
  },
  {
    label: "BATILLY-EN-GATINAIS",
    value: "45022",
    type: "common",
  },
  {
    label: "NANCRAY-SUR-RIMARDE",
    value: "45220",
    type: "common",
  },
  {
    label: "CHAMBON-LA-FORET",
    value: "45069",
    type: "common",
  },
  {
    label: "BOISCOMMUN",
    value: "45035",
    type: "common",
  },
  {
    label: "VRIGNY",
    value: "45347",
    type: "common",
  },
  {
    label: "COURCY-AUX-LOGES",
    value: "45111",
    type: "common",
  },
  {
    label: "CHILLEURS-AUX-BOIS",
    value: "45095",
    type: "common",
  },
  {
    label: "NEUVILLE-AUX-BOIS",
    value: "45224",
    type: "common",
  },
  {
    label: "VILLEREAU",
    value: "45342",
    type: "common",
  },
  {
    label: "TRINAY",
    value: "45330",
    type: "common",
  },
  {
    label: "SAINT-LYE-LA-FORET",
    value: "45289",
    type: "common",
  },
  {
    label: "BUCY-LE-ROI",
    value: "45058",
    type: "common",
  },
  {
    label: "CHEVILLY",
    value: "45093",
    type: "common",
  },
  {
    label: "ARTENAY",
    value: "45008",
    type: "common",
  },
  {
    label: "SOUGY",
    value: "45313",
    type: "common",
  },
  {
    label: "ROUVRAY-SAINTE-CROIX",
    value: "45262",
    type: "common",
  },
  {
    label: "PATAY",
    value: "45248",
    type: "common",
  },
  {
    label: "TERMINIERS",
    value: "28382",
    type: "common",
  },
  {
    label: "VILLENEUVE-SUR-CONIE",
    value: "45341",
    type: "common",
  },
  {
    label: "LA CHAPELLE-ONZERAIN",
    value: "45074",
    type: "common",
  },
  {
    label: "PERONVILLE",
    value: "28296",
    type: "common",
  },
  {
    label: "VILLAMPUY",
    value: "28410",
    type: "common",
  },
  {
    label: "VARIZE",
    value: "28400",
    type: "common",
  },
  {
    label: "VILLEMAURY",
    value: "28330",
    type: "common",
  },
  {
    label: "JALLANS",
    value: "28198",
    type: "common",
  },
  {
    label: "CHATEAUDUN",
    value: "28088",
    type: "common",
  },
  {
    label: "LA CHAPELLE-DU-NOYER",
    value: "28075",
    type: "common",
  },
  {
    label: "LE POISLAY",
    value: "41179",
    type: "common",
  },
  {
    label: "DROUE",
    value: "41075",
    type: "common",
  },
  {
    label: "LA FONTENELLE",
    value: "41089",
    type: "common",
  },
  {
    label: "COUETRON-AU-PERCHE",
    value: "41248",
    type: "common",
  },
  {
    label: "VIBRAYE",
    value: "72373",
    type: "common",
  },
  {
    label: "CHAMPROND",
    value: "72057",
    type: "common",
  },
  {
    label: "LAVARE",
    value: "72158",
    type: "common",
  },
  {
    label: "BOUER",
    value: "72041",
    type: "common",
  },
  {
    label: "THORIGNE-SUR-DUE",
    value: "72358",
    type: "common",
  },
  {
    label: "LE LUART",
    value: "72172",
    type: "common",
  },
  {
    label: "DUNEAU",
    value: "72122",
    type: "common",
  },
  {
    label: "DOLLON",
    value: "72118",
    type: "common",
  },
  {
    label: "SOULITRE",
    value: "72341",
    type: "common",
  },
  {
    label: "CONNERRE",
    value: "72090",
    type: "common",
  },
  {
    label: "BEILLE",
    value: "72031",
    type: "common",
  },
  {
    label: "MONTFORT-LE-GESNOIS",
    value: "72241",
    type: "common",
  },
  {
    label: "YVRE-L'EVEQUE",
    value: "72386",
    type: "common",
  },
  {
    label: "SARGE-LES-LE-MANS",
    value: "72328",
    type: "common",
  },
  {
    label: "FATINES",
    value: "72129",
    type: "common",
  },
  {
    label: "SAINT-SATURNIN",
    value: "72320",
    type: "common",
  },
  {
    label: "SAINT-PAVACE",
    value: "72310",
    type: "common",
  },
  {
    label: "NEUVILLE-SUR-SARTHE",
    value: "72217",
    type: "common",
  },
  {
    label: "COULAINES",
    value: "72095",
    type: "common",
  },
  {
    label: "TRANGE",
    value: "72360",
    type: "common",
  },
  {
    label: "LA MILESSE",
    value: "72198",
    type: "common",
  },
  {
    label: "LA CHAPELLE-SAINT-AUBIN",
    value: "72065",
    type: "common",
  },
  {
    label: "AIGNE",
    value: "72001",
    type: "common",
  },
  {
    label: "LA QUINTE",
    value: "72249",
    type: "common",
  },
  {
    label: "DEGRE",
    value: "72113",
    type: "common",
  },
  {
    label: "COULANS-SUR-GEE",
    value: "72096",
    type: "common",
  },
  {
    label: "CHAUFOUR-NOTRE-DAME",
    value: "72073",
    type: "common",
  },
  {
    label: "RUILLE-EN-CHAMPAGNE",
    value: "72261",
    type: "common",
  },
  {
    label: "LONGNES",
    value: "72166",
    type: "common",
  },
  {
    label: "AMNE",
    value: "72004",
    type: "common",
  },
  {
    label: "EPINEU-LE-CHEVREUIL",
    value: "72126",
    type: "common",
  },
  {
    label: "CHASSILLE",
    value: "72070",
    type: "common",
  },
  {
    label: "JOUE-EN-CHARNIE",
    value: "72149",
    type: "common",
  },
  {
    label: "CHEMIRE-EN-CHARNIE",
    value: "72074",
    type: "common",
  },
  {
    label: "SAINT-DENIS-D'ORQUES",
    value: "72278",
    type: "common",
  },
  {
    label: "THORIGNE-EN-CHARNIE",
    value: "53264",
    type: "common",
  },
  {
    label: "SAINT-PIERRE-SUR-ERVE",
    value: "53248",
    type: "common",
  },
  {
    label: "BLANDOUET-SAINT JEAN",
    value: "53228",
    type: "common",
  },
  {
    label: "VAIGES",
    value: "53267",
    type: "common",
  },
  {
    label: "SAINT-GEORGES-LE-FLECHARD",
    value: "53220",
    type: "common",
  },
  {
    label: "LA BAZOUGE-DE-CHEMERE",
    value: "53022",
    type: "common",
  },
  {
    label: "BAZOUGERS",
    value: "53025",
    type: "common",
  },
  {
    label: "PARNE-SUR-ROC",
    value: "53175",
    type: "common",
  },
  {
    label: "LOUVIGNE",
    value: "53141",
    type: "common",
  },
  {
    label: "FORCE",
    value: "53099",
    type: "common",
  },
  {
    label: "L'HUISSERIE",
    value: "53119",
    type: "common",
  },
  {
    label: "ENTRAMMES",
    value: "53094",
    type: "common",
  },
  {
    label: "MONTIGNE-LE-BRILLANT",
    value: "53157",
    type: "common",
  },
  {
    label: "AHUILLE",
    value: "53001",
    type: "common",
  },
  {
    label: "MONTJEAN",
    value: "53158",
    type: "common",
  },
  {
    label: "SAINT-CYR-LE-GRAVELAIS",
    value: "53209",
    type: "common",
  },
  {
    label: "BEAULIEU-SUR-OUDON",
    value: "53026",
    type: "common",
  },
  {
    label: "LE PERTRE",
    value: "35217",
    type: "common",
  },
  {
    label: "GENNES-SUR-SEICHE",
    value: "35119",
    type: "common",
  },
  {
    label: "BRIELLES",
    value: "35042",
    type: "common",
  },
  {
    label: "ARGENTRE-DU-PLESSIS",
    value: "35006",
    type: "common",
  },
  {
    label: "SAINT-GERMAIN-DU-PINEL",
    value: "35272",
    type: "common",
  },
  {
    label: "DOMALAIN",
    value: "35097",
    type: "common",
  },
  {
    label: "VERGEAL",
    value: "35350",
    type: "common",
  },
  {
    label: "BAIS",
    value: "35014",
    type: "common",
  },
  {
    label: "MOULINS",
    value: "35198",
    type: "common",
  },
  {
    label: "AMANLIS",
    value: "35002",
    type: "common",
  },
  {
    label: "SAINT-ARMEL",
    value: "35250",
    type: "common",
  },
  {
    label: "CORPS-NUDS",
    value: "35088",
    type: "common",
  },
  {
    label: "ORGERES",
    value: "35208",
    type: "common",
  },
  {
    label: "SAINT-ERBLON",
    value: "35266",
    type: "common",
  },
  {
    label: "BOURGBARRE",
    value: "35032",
    type: "common",
  },
  {
    label: "PONT-PEAN",
    value: "35363",
    type: "common",
  },
  {
    label: "GUICHEN",
    value: "35126",
    type: "common",
  },
  {
    label: "GOVEN",
    value: "35123",
    type: "common",
  },
  {
    label: "LASSY",
    value: "35149",
    type: "common",
  },
  {
    label: "BAULON",
    value: "35016",
    type: "common",
  },
  {
    label: "MAXENT",
    value: "35169",
    type: "common",
  },
  {
    label: "PLELAN-LE-GRAND",
    value: "35223",
    type: "common",
  },
  {
    label: "SAINT-MALO-DE-BEIGNON",
    value: "56226",
    type: "common",
  },
  {
    label: "BEIGNON",
    value: "56012",
    type: "common",
  },
  {
    label: "CAMPENEAC",
    value: "56032",
    type: "common",
  },
  {
    label: "TAUPONT",
    value: "56249",
    type: "common",
  },
  {
    label: "LOYAT",
    value: "56122",
    type: "common",
  },
  {
    label: "JOSSELIN",
    value: "56091",
    type: "common",
  },
  {
    label: "HELLEAN",
    value: "56082",
    type: "common",
  },
  {
    label: "LA CROIX-HELLEAN",
    value: "56050",
    type: "common",
  },
  {
    label: "RADENAC",
    value: "56189",
    type: "common",
  },
  {
    label: "LANTILLAC",
    value: "56103",
    type: "common",
  },
  {
    label: "MOREAC",
    value: "56140",
    type: "common",
  },
  {
    label: "SAINT-BARTHELEMY",
    value: "56207",
    type: "common",
  },
  {
    label: "QUISTINIC",
    value: "56188",
    type: "common",
  },
  {
    label: "LANVAUDAN",
    value: "56104",
    type: "common",
  },
  {
    label: "INGUINIEL",
    value: "56089",
    type: "common",
  },
  {
    label: "PLOUAY",
    value: "56166",
    type: "common",
  },
  {
    label: "GUILLIGOMARC'H",
    value: "29071",
    type: "common",
  },
  {
    label: "LOCUNOLE",
    value: "29136",
    type: "common",
  },
  {
    label: "ARZANO",
    value: "29002",
    type: "common",
  },
  {
    label: "TREMEVEN",
    value: "29297",
    type: "common",
  },
  {
    label: "MELLAC",
    value: "29147",
    type: "common",
  },
  {
    label: "LE TREVOUX",
    value: "29300",
    type: "common",
  },
  {
    label: "PONT-AVEN",
    value: "29217",
    type: "common",
  },
  {
    label: "MELGVEN",
    value: "29146",
    type: "common",
  },
  {
    label: "CONCARNEAU",
    value: "29039",
    type: "common",
  },
  {
    label: "PLEUVEN",
    value: "29161",
    type: "common",
  },
  {
    label: "FOUESNANT",
    value: "29058",
    type: "common",
  },
  {
    label: "LA FORET-FOUESNANT",
    value: "29057",
    type: "common",
  },
  {
    label: "CLOHARS-FOUESNANT",
    value: "29032",
    type: "common",
  },
  {
    label: "BENODET",
    value: "29006",
    type: "common",
  },
  {
    label: "COMBRIT",
    value: "29037",
    type: "common",
  },
  {
    label: "TREMEOC",
    value: "29296",
    type: "common",
  },
  {
    label: "PONT-L'ABBE",
    value: "29220",
    type: "common",
  },
  {
    label: "TREGUENNEC",
    value: "29292",
    type: "common",
  },
  {
    label: "SAINT-JEAN-TROLIMON",
    value: "29252",
    type: "common",
  },
  {
    label: "ROGGENHOUSE",
    value: "68281",
    type: "common",
  },
  {
    label: "HIRTZFELDEN",
    value: "68140",
    type: "common",
  },
  {
    label: "FESSENHEIM",
    value: "68091",
    type: "common",
  },
  {
    label: "BLODELSHEIM",
    value: "68041",
    type: "common",
  },
  {
    label: "REGUISHEIM",
    value: "68266",
    type: "common",
  },
  {
    label: "MEYENHEIM",
    value: "68205",
    type: "common",
  },
  {
    label: "UNGERSHEIM",
    value: "68343",
    type: "common",
  },
  {
    label: "RAEDERSHEIM",
    value: "68260",
    type: "common",
  },
  {
    label: "MERXHEIM",
    value: "68203",
    type: "common",
  },
  {
    label: "GUNDOLSHEIM",
    value: "68116",
    type: "common",
  },
  {
    label: "ISSENHEIM",
    value: "68156",
    type: "common",
  },
  {
    label: "GUEBWILLER",
    value: "68112",
    type: "common",
  },
  {
    label: "BERGHOLTZZELL",
    value: "68030",
    type: "common",
  },
  {
    label: "BERGHOLTZ",
    value: "68029",
    type: "common",
  },
  {
    label: "SOULTZ-HAUT-RHIN",
    value: "68315",
    type: "common",
  },
  {
    label: "RIMBACHZELL",
    value: "68276",
    type: "common",
  },
  {
    label: "RIMBACH-PRES-GUEBWILLER",
    value: "68274",
    type: "common",
  },
  {
    label: "MURBACH",
    value: "68229",
    type: "common",
  },
  {
    label: "JUNGHOLTZ",
    value: "68159",
    type: "common",
  },
  {
    label: "BUHL",
    value: "68058",
    type: "common",
  },
  {
    label: "SAINT-AMARIN",
    value: "68292",
    type: "common",
  },
  {
    label: "RANSPACH",
    value: "68262",
    type: "common",
  },
  {
    label: "LAUTENBACHZELL",
    value: "68178",
    type: "common",
  },
  {
    label: "GEISHOUSE",
    value: "68102",
    type: "common",
  },
  {
    label: "ODEREN",
    value: "68247",
    type: "common",
  },
  {
    label: "FELLERING",
    value: "68089",
    type: "common",
  },
  {
    label: "VENTRON",
    value: "88500",
    type: "common",
  },
  {
    label: "BUSSANG",
    value: "88081",
    type: "common",
  },
  {
    label: "LE MENIL",
    value: "88302",
    type: "common",
  },
  {
    label: "RUPT-SUR-MOSELLE",
    value: "88408",
    type: "common",
  },
  {
    label: "RAMONCHAMP",
    value: "88369",
    type: "common",
  },
  {
    label: "FERDRUPT",
    value: "88170",
    type: "common",
  },
  {
    label: "LA ROSIERE",
    value: "70453",
    type: "common",
  },
  {
    label: "LA MONTAGNE",
    value: "70352",
    type: "common",
  },
  {
    label: "LA LONGINE",
    value: "70308",
    type: "common",
  },
  {
    label: "GIRMONT-VAL-D'AJOL",
    value: "88205",
    type: "common",
  },
  {
    label: "LE VAL-D'AJOL",
    value: "88487",
    type: "common",
  },
  {
    label: "LE CLERJUS",
    value: "88108",
    type: "common",
  },
  {
    label: "LA VAIVRE",
    value: "70512",
    type: "common",
  },
  {
    label: "AILLEVILLERS-ET-LYAUMONT",
    value: "70006",
    type: "common",
  },
  {
    label: "TREMONZEY",
    value: "88479",
    type: "common",
  },
  {
    label: "FLEUREY-LES-SAINT-LOUP",
    value: "70238",
    type: "common",
  },
  {
    label: "CUVE",
    value: "70194",
    type: "common",
  },
  {
    label: "BOULIGNEY",
    value: "70083",
    type: "common",
  },
  {
    label: "MAILLERONCOURT-SAINT-PANCRAS",
    value: "70323",
    type: "common",
  },
  {
    label: "FONTENOIS-LA-VILLE",
    value: "70242",
    type: "common",
  },
  {
    label: "DAMPVALLEY-SAINT-PANCRAS",
    value: "70200",
    type: "common",
  },
  {
    label: "BETONCOURT-SAINT-PANCRAS",
    value: "70069",
    type: "common",
  },
  {
    label: "VAUVILLERS",
    value: "70526",
    type: "common",
  },
  {
    label: "SELLES",
    value: "70485",
    type: "common",
  },
  {
    label: "LA BASSE-VAIVRE",
    value: "70051",
    type: "common",
  },
  {
    label: "ALAINCOURT",
    value: "70010",
    type: "common",
  },
  {
    label: "VOUGECOURT",
    value: "70576",
    type: "common",
  },
  {
    label: "MONTCOURT",
    value: "70359",
    type: "common",
  },
  {
    label: "DEMANGEVELLE",
    value: "70202",
    type: "common",
  },
  {
    label: "CORRE",
    value: "70177",
    type: "common",
  },
  {
    label: "LIRONCOURT",
    value: "88272",
    type: "common",
  },
  {
    label: "GRIGNONCOURT",
    value: "88220",
    type: "common",
  },
  {
    label: "FIGNEVELLE",
    value: "88171",
    type: "common",
  },
  {
    label: "CHATILLON-SUR-SAONE",
    value: "88096",
    type: "common",
  },
  {
    label: "AMEUVELLE",
    value: "88007",
    type: "common",
  },
  {
    label: "JONVELLE",
    value: "70291",
    type: "common",
  },
  {
    label: "BOUSSERAUCOURT",
    value: "70091",
    type: "common",
  },
  {
    label: "FRESNES-SUR-APANCE",
    value: "52208",
    type: "common",
  },
  {
    label: "SENAIDE",
    value: "88450",
    type: "common",
  },
  {
    label: "BOURBONNE-LES-BAINS",
    value: "52060",
    type: "common",
  },
  {
    label: "LE CHATELET-SUR-MEUSE",
    value: "52400",
    type: "common",
  },
  {
    label: "DAMREMONT",
    value: "52164",
    type: "common",
  },
  {
    label: "LANEUVELLE",
    value: "52264",
    type: "common",
  },
  {
    label: "RANCONNIERES",
    value: "52415",
    type: "common",
  },
  {
    label: "DAMMARTIN-SUR-MEUSE",
    value: "52162",
    type: "common",
  },
  {
    label: "FRECOURT",
    value: "52207",
    type: "common",
  },
  {
    label: "BONNECOURT",
    value: "52059",
    type: "common",
  },
  {
    label: "CHAUFFOURT",
    value: "52120",
    type: "common",
  },
  {
    label: "POINSON-LES-NOGENT",
    value: "52396",
    type: "common",
  },
  {
    label: "DAMPIERRE",
    value: "52163",
    type: "common",
  },
  {
    label: "VITRY-LES-NOGENT",
    value: "52541",
    type: "common",
  },
  {
    label: "THIVET",
    value: "52488",
    type: "common",
  },
  {
    label: "ROLAMPONT",
    value: "52432",
    type: "common",
  },
  {
    label: "VILLIERS-SUR-SUIZE",
    value: "52538",
    type: "common",
  },
  {
    label: "FAVEROLLES",
    value: "52196",
    type: "common",
  },
  {
    label: "LEFFONDS",
    value: "52282",
    type: "common",
  },
  {
    label: "BUGNIERES",
    value: "52082",
    type: "common",
  },
  {
    label: "RICHEBOURG",
    value: "52422",
    type: "common",
  },
  {
    label: "ARC-EN-BARROIS",
    value: "52017",
    type: "common",
  },
  {
    label: "COUPRAY",
    value: "52146",
    type: "common",
  },
  {
    label: "COUR-L'EVEQUE",
    value: "52151",
    type: "common",
  },
  {
    label: "LATRECEY-ORMOY-SUR-AUBE",
    value: "52274",
    type: "common",
  },
  {
    label: "MONTIGNY-SUR-AUBE",
    value: "21432",
    type: "common",
  },
  {
    label: "GEVROLLES",
    value: "21296",
    type: "common",
  },
  {
    label: "RIEL-LES-EAUX",
    value: "21524",
    type: "common",
  },
  {
    label: "GRANCEY-SUR-OURCE",
    value: "21305",
    type: "common",
  },
  {
    label: "BELAN-SUR-OURCE",
    value: "21058",
    type: "common",
  },
  {
    label: "AUTRICOURT",
    value: "21034",
    type: "common",
  },
  {
    label: "PLAINES-SAINT-LANGE",
    value: "10288",
    type: "common",
  },
  {
    label: "MUSSY-SUR-SEINE",
    value: "10261",
    type: "common",
  },
  {
    label: "LES RICEYS",
    value: "10317",
    type: "common",
  },
  {
    label: "BRAGELOGNE-BEAUVOIR",
    value: "10058",
    type: "common",
  },
  {
    label: "BAGNEUX-LA-FOSSE",
    value: "10025",
    type: "common",
  },
  {
    label: "BALNOT-LA-GRANGE",
    value: "10028",
    type: "common",
  },
  {
    label: "PARGUES",
    value: "10278",
    type: "common",
  },
  {
    label: "LAGESSE",
    value: "10185",
    type: "common",
  },
  {
    label: "MAISONS-LES-CHAOURCE",
    value: "10218",
    type: "common",
  },
  {
    label: "VALLIERES",
    value: "10394",
    type: "common",
  },
  {
    label: "CHESLEY",
    value: "10098",
    type: "common",
  },
  {
    label: "PRUSY",
    value: "10309",
    type: "common",
  },
  {
    label: "CUSSANGY",
    value: "10120",
    type: "common",
  },
  {
    label: "VANLAY",
    value: "10395",
    type: "common",
  },
  {
    label: "BERNON",
    value: "10040",
    type: "common",
  },
  {
    label: "LES CROUTES",
    value: "10118",
    type: "common",
  },
  {
    label: "CHESSY-LES-PRES",
    value: "10099",
    type: "common",
  },
  {
    label: "SOUMAINTRAIN",
    value: "89402",
    type: "common",
  },
  {
    label: "PERCEY",
    value: "89292",
    type: "common",
  },
  {
    label: "GERMIGNY",
    value: "89186",
    type: "common",
  },
  {
    label: "BUTTEAUX",
    value: "89061",
    type: "common",
  },
  {
    label: "BEUGNON",
    value: "89041",
    type: "common",
  },
  {
    label: "SAINT-FLORENTIN",
    value: "89345",
    type: "common",
  },
  {
    label: "CHEU",
    value: "89101",
    type: "common",
  },
  {
    label: "BRIENON-SUR-ARMANCON",
    value: "89055",
    type: "common",
  },
  {
    label: "ESNON",
    value: "89156",
    type: "common",
  },
  {
    label: "MIGENNES",
    value: "89257",
    type: "common",
  },
  {
    label: "LOOZE",
    value: "89230",
    type: "common",
  },
  {
    label: "LAROCHE-SAINT-CYDROINE",
    value: "89218",
    type: "common",
  },
  {
    label: "BRION",
    value: "89056",
    type: "common",
  },
  {
    label: "VILLEVALLIER",
    value: "89468",
    type: "common",
  },
  {
    label: "VILLECIEN",
    value: "89452",
    type: "common",
  },
  {
    label: "SAINT-AUBIN-SUR-YONNE",
    value: "89335",
    type: "common",
  },
  {
    label: "JOIGNY",
    value: "89206",
    type: "common",
  },
  {
    label: "CEZY",
    value: "89067",
    type: "common",
  },
  {
    label: "LA CELLE-SAINT-CYR",
    value: "89063",
    type: "common",
  },
  {
    label: "VERLIN",
    value: "89440",
    type: "common",
  },
  {
    label: "SAINT-MARTIN-D'ORDON",
    value: "89353",
    type: "common",
  },
  {
    label: "PRECY-SUR-VRIN",
    value: "89313",
    type: "common",
  },
  {
    label: "CUDOT",
    value: "89133",
    type: "common",
  },
  {
    label: "SAINT-LOUP-D'ORDON",
    value: "89350",
    type: "common",
  },
  {
    label: "COURTENAY",
    value: "45115",
    type: "common",
  },
  {
    label: "CHUELLES",
    value: "45097",
    type: "common",
  },
  {
    label: "LA SELLE-EN-HERMOY",
    value: "45306",
    type: "common",
  },
  {
    label: "THORAILLES",
    value: "45322",
    type: "common",
  },
  {
    label: "LA CHAPELLE-SAINT-SEPULCRE",
    value: "45076",
    type: "common",
  },
  {
    label: "PAUCOURT",
    value: "45249",
    type: "common",
  },
  {
    label: "MONTARGIS",
    value: "45208",
    type: "common",
  },
  {
    label: "CHALETTE-SUR-LOING",
    value: "45068",
    type: "common",
  },
  {
    label: "AMILLY",
    value: "45004",
    type: "common",
  },
  {
    label: "VILLEMANDEUR",
    value: "45338",
    type: "common",
  },
  {
    label: "PANNES",
    value: "45247",
    type: "common",
  },
  {
    label: "VILLEVOQUES",
    value: "45343",
    type: "common",
  },
  {
    label: "VILLEMOUTIERS",
    value: "45339",
    type: "common",
  },
  {
    label: "SAINT-MAURICE-SUR-FESSARD",
    value: "45293",
    type: "common",
  },
  {
    label: "MOULON",
    value: "45219",
    type: "common",
  },
  {
    label: "OUZOUER-SOUS-BELLEGARDE",
    value: "45243",
    type: "common",
  },
  {
    label: "MEZIERES-EN-GATINAIS",
    value: "45205",
    type: "common",
  },
  {
    label: "LORCY",
    value: "45186",
    type: "common",
  },
  {
    label: "LADON",
    value: "45178",
    type: "common",
  },
  {
    label: "QUIERS-SUR-BEZONDE",
    value: "45259",
    type: "common",
  },
  {
    label: "NESPLOY",
    value: "45223",
    type: "common",
  },
  {
    label: "MONTLIARD",
    value: "45215",
    type: "common",
  },
  {
    label: "FREVILLE-DU-GATINAIS",
    value: "45150",
    type: "common",
  },
  {
    label: "BELLEGARDE",
    value: "45031",
    type: "common",
  },
  {
    label: "NIBELLE",
    value: "45228",
    type: "common",
  },
  {
    label: "INGRANNES",
    value: "45168",
    type: "common",
  },
  {
    label: "LOURY",
    value: "45188",
    type: "common",
  },
  {
    label: "REBRECHIEN",
    value: "45261",
    type: "common",
  },
  {
    label: "BOUGY-LEZ-NEUVILLE",
    value: "45044",
    type: "common",
  },
  {
    label: "CHANTEAU",
    value: "45072",
    type: "common",
  },
  {
    label: "CERCOTTES",
    value: "45062",
    type: "common",
  },
  {
    label: "HUETRE",
    value: "45166",
    type: "common",
  },
  {
    label: "GIDY",
    value: "45154",
    type: "common",
  },
  {
    label: "BRICY",
    value: "45055",
    type: "common",
  },
  {
    label: "BOULAY-LES-BARRES",
    value: "45046",
    type: "common",
  },
  {
    label: "SAINT-SIGISMOND",
    value: "45299",
    type: "common",
  },
  {
    label: "SAINT-PERAVY-LA-COLOMBE",
    value: "45296",
    type: "common",
  },
  {
    label: "COINCES",
    value: "45099",
    type: "common",
  },
  {
    label: "TOURNOISIS",
    value: "45326",
    type: "common",
  },
  {
    label: "VILLAMBLAIN",
    value: "45337",
    type: "common",
  },
  {
    label: "THIVILLE",
    value: "28389",
    type: "common",
  },
  {
    label: "CLOYES-LES-TROIS-RIVIERES",
    value: "28103",
    type: "common",
  },
  {
    label: "VILLEBOUT",
    value: "41277",
    type: "common",
  },
  {
    label: "RUAN-SUR-EGVONNE",
    value: "41196",
    type: "common",
  },
  {
    label: "FONTAINE-RAOUL",
    value: "41088",
    type: "common",
  },
  {
    label: "BOUFFRY",
    value: "41022",
    type: "common",
  },
  {
    label: "SAINT-MARC-DU-COR",
    value: "41224",
    type: "common",
  },
  {
    label: "LA CHAPELLE-VICOMTESSE",
    value: "41041",
    type: "common",
  },
  {
    label: "BOURSAY",
    value: "41024",
    type: "common",
  },
  {
    label: "MONDOUBLEAU",
    value: "41143",
    type: "common",
  },
  {
    label: "CHOUE",
    value: "41053",
    type: "common",
  },
  {
    label: "VALENNES",
    value: "72366",
    type: "common",
  },
  {
    label: "BAILLOU",
    value: "41012",
    type: "common",
  },
  {
    label: "CONFLANS-SUR-ANILLE",
    value: "72087",
    type: "common",
  },
  {
    label: "BERFAY",
    value: "72032",
    type: "common",
  },
  {
    label: "SEMUR-EN-VALLON",
    value: "72333",
    type: "common",
  },
  {
    label: "COUDRECIEUX",
    value: "72094",
    type: "common",
  },
  {
    label: "SAINT-MICHEL-DE-CHAVAIGNES",
    value: "72303",
    type: "common",
  },
  {
    label: "BOULOIRE",
    value: "72042",
    type: "common",
  },
  {
    label: "SURFONDS",
    value: "72345",
    type: "common",
  },
  {
    label: "NUILLE-LE-JALAIS",
    value: "72224",
    type: "common",
  },
  {
    label: "LE BREIL-SUR-MERIZE",
    value: "72046",
    type: "common",
  },
  {
    label: "SAINT-MARS-LA-BRIERE",
    value: "72300",
    type: "common",
  },
  {
    label: "ARDENAY-SUR-MERIZE",
    value: "72007",
    type: "common",
  },
  {
    label: "CHANGE",
    value: "72058",
    type: "common",
  },
  {
    label: "CHAMPAGNE",
    value: "72054",
    type: "common",
  },
  {
    label: "LE MANS",
    value: "72181",
    type: "common",
  },
  {
    label: "SAINT-GEORGES-DU-BOIS",
    value: "72280",
    type: "common",
  },
  {
    label: "ROUILLON",
    value: "72257",
    type: "common",
  },
  {
    label: "PRUILLE-LE-CHETIF",
    value: "72247",
    type: "common",
  },
  {
    label: "ETIVAL-LES-LE-MANS",
    value: "72127",
    type: "common",
  },
  {
    label: "ALLONNES",
    value: "72003",
    type: "common",
  },
  {
    label: "SOULIGNE-FLACE",
    value: "72339",
    type: "common",
  },
  {
    label: "FAY",
    value: "72130",
    type: "common",
  },
  {
    label: "VALLON-SUR-GEE",
    value: "72367",
    type: "common",
  },
  {
    label: "TASSILLE",
    value: "72348",
    type: "common",
  },
  {
    label: "CRANNES-EN-CHAMPAGNE",
    value: "72107",
    type: "common",
  },
  {
    label: "BRAINS-SUR-GEE",
    value: "72045",
    type: "common",
  },
  {
    label: "AUVERS-SOUS-MONTFAUCON",
    value: "72017",
    type: "common",
  },
  {
    label: "SAINT-OUEN-EN-CHAMPAGNE",
    value: "72307",
    type: "common",
  },
  {
    label: "SAINT-CHRISTOPHE-EN-CHAMPAGNE",
    value: "72274",
    type: "common",
  },
  {
    label: "MAREIL-EN-CHAMPAGNE",
    value: "72184",
    type: "common",
  },
  {
    label: "LOUE",
    value: "72168",
    type: "common",
  },
  {
    label: "BRULON",
    value: "72050",
    type: "common",
  },
  {
    label: "AVESSE",
    value: "72019",
    type: "common",
  },
  {
    label: "VIRE-EN-CHAMPAGNE",
    value: "72379",
    type: "common",
  },
  {
    label: "COSSE-EN-CHAMPAGNE",
    value: "53076",
    type: "common",
  },
  {
    label: "BANNES",
    value: "53019",
    type: "common",
  },
  {
    label: "SAULGES",
    value: "53257",
    type: "common",
  },
  {
    label: "SAINT-DENIS-DU-MAINE",
    value: "53212",
    type: "common",
  },
  {
    label: "LA CROPTE",
    value: "53087",
    type: "common",
  },
  {
    label: "CHEMERE-LE-ROI",
    value: "53067",
    type: "common",
  },
  {
    label: "MESLAY-DU-MAINE",
    value: "53152",
    type: "common",
  },
  {
    label: "ARQUENAY",
    value: "53009",
    type: "common",
  },
  {
    label: "MAISONCELLES-DU-MAINE",
    value: "53143",
    type: "common",
  },
  {
    label: "LE BIGNON-DU-MAINE",
    value: "53030",
    type: "common",
  },
  {
    label: "ORIGNE",
    value: "53172",
    type: "common",
  },
  {
    label: "NUILLE-SUR-VICOIN",
    value: "53168",
    type: "common",
  },
  {
    label: "ASTILLE",
    value: "53011",
    type: "common",
  },
  {
    label: "COURBEVEILLE",
    value: "53082",
    type: "common",
  },
  {
    label: "COSSE-LE-VIVIEN",
    value: "53077",
    type: "common",
  },
  {
    label: "SAINT-POIX",
    value: "53250",
    type: "common",
  },
  {
    label: "MERAL",
    value: "53151",
    type: "common",
  },
  {
    label: "LAUBRIERES",
    value: "53128",
    type: "common",
  },
  {
    label: "GASTINES",
    value: "53102",
    type: "common",
  },
  {
    label: "CUILLE",
    value: "53088",
    type: "common",
  },
  {
    label: "LA SELLE-GUERCHAISE",
    value: "35325",
    type: "common",
  },
  {
    label: "MOUTIERS",
    value: "35200",
    type: "common",
  },
  {
    label: "AVAILLES-SUR-SEICHE",
    value: "35008",
    type: "common",
  },
  {
    label: "VISSEICHE",
    value: "35359",
    type: "common",
  },
  {
    label: "LA GUERCHE-DE-BRETAGNE",
    value: "35125",
    type: "common",
  },
  {
    label: "ARBRISSEL",
    value: "35005",
    type: "common",
  },
  {
    label: "MARCILLE-ROBERT",
    value: "35165",
    type: "common",
  },
  {
    label: "BOISTRUDAN",
    value: "35028",
    type: "common",
  },
  {
    label: "LE THEIL-DE-BRETAGNE",
    value: "35333",
    type: "common",
  },
  {
    label: "ESSE",
    value: "35108",
    type: "common",
  },
  {
    label: "SAULNIERES",
    value: "35321",
    type: "common",
  },
  {
    label: "JANZE",
    value: "35136",
    type: "common",
  },
  {
    label: "BRIE",
    value: "35041",
    type: "common",
  },
  {
    label: "CREVIN",
    value: "35090",
    type: "common",
  },
  {
    label: "LE PETIT-FOUGERAY",
    value: "35218",
    type: "common",
  },
  {
    label: "CHANTELOUP",
    value: "35054",
    type: "common",
  },
  {
    label: "LAILLE",
    value: "35139",
    type: "common",
  },
  {
    label: "BOURG-DES-COMPTES",
    value: "35033",
    type: "common",
  },
  {
    label: "GUIGNEN",
    value: "35127",
    type: "common",
  },
  {
    label: "LA CHAPELLE-BOUEXIC",
    value: "35057",
    type: "common",
  },
  {
    label: "BOVEL",
    value: "35035",
    type: "common",
  },
  {
    label: "LOUTEHEL",
    value: "35160",
    type: "common",
  },
  {
    label: "PORCARO",
    value: "56180",
    type: "common",
  },
  {
    label: "GUER",
    value: "56075",
    type: "common",
  },
  {
    label: "AUGAN",
    value: "56006",
    type: "common",
  },
  {
    label: "GOURHEL",
    value: "56065",
    type: "common",
  },
  {
    label: "MONTERTELOT",
    value: "56139",
    type: "common",
  },
  {
    label: "SAINT-SERVANT",
    value: "56236",
    type: "common",
  },
  {
    label: "GUILLAC",
    value: "56079",
    type: "common",
  },
  {
    label: "GUEGON",
    value: "56070",
    type: "common",
  },
  {
    label: "GUEHENNO",
    value: "56071",
    type: "common",
  },
  {
    label: "BULEON",
    value: "56027",
    type: "common",
  },
  {
    label: "SAINT-ALLOUESTRE",
    value: "56204",
    type: "common",
  },
  {
    label: "BIGNAN",
    value: "56017",
    type: "common",
  },
  {
    label: "PLUMELIN",
    value: "56174",
    type: "common",
  },
  {
    label: "LOCMINE",
    value: "56117",
    type: "common",
  },
  {
    label: "GUENIN",
    value: "56074",
    type: "common",
  },
  {
    label: "LA CHAPELLE-NEUVE",
    value: "56039",
    type: "common",
  },
  {
    label: "BAUD",
    value: "56010",
    type: "common",
  },
  {
    label: "INZINZAC-LOCHRIST",
    value: "56090",
    type: "common",
  },
  {
    label: "CALAN",
    value: "56029",
    type: "common",
  },
  {
    label: "CLEGUER",
    value: "56040",
    type: "common",
  },
  {
    label: "PONT-SCORFF",
    value: "56179",
    type: "common",
  },
  {
    label: "REDENE",
    value: "29234",
    type: "common",
  },
  {
    label: "QUIMPERLE",
    value: "29233",
    type: "common",
  },
  {
    label: "RIEC-SUR-BELON",
    value: "29236",
    type: "common",
  },
  {
    label: "BAYE",
    value: "29005",
    type: "common",
  },
  {
    label: "TREGUNC",
    value: "29293",
    type: "common",
  },
  {
    label: "LOCTUDY",
    value: "29135",
    type: "common",
  },
  {
    label: "ILE-TUDY",
    value: "29085",
    type: "common",
  },
  {
    label: "PLOBANNALEC-LESCONIL",
    value: "29165",
    type: "common",
  },
  {
    label: "TREFFIAGAT",
    value: "29284",
    type: "common",
  },
  {
    label: "GUILVINEC",
    value: "29072",
    type: "common",
  },
  {
    label: "PLOMEUR",
    value: "29171",
    type: "common",
  },
  {
    label: "PENMARCH",
    value: "29158",
    type: "common",
  },
  {
    label: "CHALAMPE",
    value: "68064",
    type: "common",
  },
  {
    label: "RUMERSHEIM-LE-HAUT",
    value: "68291",
    type: "common",
  },
  {
    label: "MUNCHHOUSE",
    value: "68225",
    type: "common",
  },
  {
    label: "BANTZENHEIM",
    value: "68020",
    type: "common",
  },
  {
    label: "ENSISHEIM",
    value: "68082",
    type: "common",
  },
  {
    label: "BATTENHEIM",
    value: "68022",
    type: "common",
  },
  {
    label: "WITTENHEIM",
    value: "68376",
    type: "common",
  },
  {
    label: "RUELISHEIM",
    value: "68289",
    type: "common",
  },
  {
    label: "PULVERSHEIM",
    value: "68258",
    type: "common",
  },
  {
    label: "FELDKIRCH",
    value: "68088",
    type: "common",
  },
  {
    label: "WUENHEIM",
    value: "68381",
    type: "common",
  },
  {
    label: "UFFHOLTZ",
    value: "68342",
    type: "common",
  },
  {
    label: "STAFFELFELDEN",
    value: "68321",
    type: "common",
  },
  {
    label: "HARTMANNSWILLER",
    value: "68122",
    type: "common",
  },
  {
    label: "BOLLWILLER",
    value: "68043",
    type: "common",
  },
  {
    label: "BERRWILLER",
    value: "68032",
    type: "common",
  },
  {
    label: "WILLER-SUR-THUR",
    value: "68372",
    type: "common",
  },
  {
    label: "WATTWILLER",
    value: "68359",
    type: "common",
  },
  {
    label: "STEINBACH",
    value: "68322",
    type: "common",
  },
  {
    label: "GOLDBACH-ALTENBACH",
    value: "68106",
    type: "common",
  },
  {
    label: "MOOSCH",
    value: "68217",
    type: "common",
  },
  {
    label: "MALMERSPACH",
    value: "68199",
    type: "common",
  },
  {
    label: "BITSCHWILLER-LES-THANN",
    value: "68040",
    type: "common",
  },
  {
    label: "URBES",
    value: "68344",
    type: "common",
  },
  {
    label: "STORCKENSOHN",
    value: "68328",
    type: "common",
  },
  {
    label: "RIMBACH-PRES-MASEVAUX",
    value: "68275",
    type: "common",
  },
  {
    label: "MOLLAU",
    value: "68213",
    type: "common",
  },
  {
    label: "MITZACH",
    value: "68211",
    type: "common",
  },
  {
    label: "HUSSEREN-WESSERLING",
    value: "68151",
    type: "common",
  },
  {
    label: "SAINT-MAURICE-SUR-MOSELLE",
    value: "88426",
    type: "common",
  },
  {
    label: "LE THILLOT",
    value: "88468",
    type: "common",
  },
  {
    label: "FRESSE-SUR-MOSELLE",
    value: "88188",
    type: "common",
  },
  {
    label: "HAUT-DU-THEM-CHATEAU-LAMBERT",
    value: "70283",
    type: "common",
  },
  {
    label: "BEULOTTE-SAINT-LAURENT",
    value: "70071",
    type: "common",
  },
  {
    label: "ESMOULIERES",
    value: "70217",
    type: "common",
  },
  {
    label: "CORRAVILLERS",
    value: "70176",
    type: "common",
  },
  {
    label: "AMONT-ET-EFFRENEY",
    value: "70016",
    type: "common",
  },
  {
    label: "SAINT-BRESSON",
    value: "70460",
    type: "common",
  },
  {
    label: "AMAGE",
    value: "70011",
    type: "common",
  },
  {
    label: "RADDON-ET-CHAPENDU",
    value: "70435",
    type: "common",
  },
  {
    label: "FONTAINE-LES-LUXEUIL",
    value: "70240",
    type: "common",
  },
  {
    label: "CORBENAY",
    value: "70171",
    type: "common",
  },
  {
    label: "SAINT-LOUP-SUR-SEMOUSE",
    value: "70467",
    type: "common",
  },
  {
    label: "LA PISSEURE",
    value: "70411",
    type: "common",
  },
  {
    label: "MAGNONCOURT",
    value: "70315",
    type: "common",
  },
  {
    label: "JASNEY",
    value: "70290",
    type: "common",
  },
  {
    label: "GIREFONTAINE",
    value: "70269",
    type: "common",
  },
  {
    label: "ANJEUX",
    value: "70023",
    type: "common",
  },
  {
    label: "POLAINCOURT-ET-CLAIREFONTAINE",
    value: "70415",
    type: "common",
  },
  {
    label: "MONTDORE",
    value: "70360",
    type: "common",
  },
  {
    label: "MELINCOURT",
    value: "70338",
    type: "common",
  },
  {
    label: "HURECOURT",
    value: "70287",
    type: "common",
  },
  {
    label: "ANCHENONCOURT-ET-CHAZEL",
    value: "70017",
    type: "common",
  },
  {
    label: "SAPONCOURT",
    value: "70476",
    type: "common",
  },
  {
    label: "RANZEVELLE",
    value: "70437",
    type: "common",
  },
  {
    label: "ORMOY",
    value: "70399",
    type: "common",
  },
  {
    label: "AISEY-ET-RICHECOURT",
    value: "70009",
    type: "common",
  },
  {
    label: "VILLARS-LE-PAUTEL",
    value: "70554",
    type: "common",
  },
  {
    label: "BOURBEVELLE",
    value: "70086",
    type: "common",
  },
  {
    label: "BLONDEFONTAINE",
    value: "70074",
    type: "common",
  },
  {
    label: "BETAUCOURT",
    value: "70066",
    type: "common",
  },
  {
    label: "ENFONVELLE",
    value: "52185",
    type: "common",
  },
  {
    label: "MELAY",
    value: "52318",
    type: "common",
  },
  {
    label: "VOISEY",
    value: "52544",
    type: "common",
  },
  {
    label: "MONTCHARVOT",
    value: "52328",
    type: "common",
  },
  {
    label: "COIFFY-LE-HAUT",
    value: "52136",
    type: "common",
  },
  {
    label: "VICQ",
    value: "52520",
    type: "common",
  },
  {
    label: "VARENNES-SUR-AMANCE",
    value: "52504",
    type: "common",
  },
  {
    label: "COIFFY-LE-BAS",
    value: "52135",
    type: "common",
  },
  {
    label: "CHAMPIGNY-SOUS-VARENNES",
    value: "52103",
    type: "common",
  },
  {
    label: "CELLES-EN-BASSIGNY",
    value: "52089",
    type: "common",
  },
  {
    label: "MARCILLY-EN-BASSIGNY",
    value: "52311",
    type: "common",
  },
  {
    label: "LAVERNOY",
    value: "52275",
    type: "common",
  },
  {
    label: "ANDILLY-EN-BASSIGNY",
    value: "52009",
    type: "common",
  },
  {
    label: "PLESNOY",
    value: "52392",
    type: "common",
  },
  {
    label: "ORBIGNY-AU-MONT",
    value: "52362",
    type: "common",
  },
  {
    label: "POISEUL",
    value: "52397",
    type: "common",
  },
  {
    label: "NEUILLY-L'EVEQUE",
    value: "52348",
    type: "common",
  },
  {
    label: "CHAMPIGNY-LES-LANGRES",
    value: "52102",
    type: "common",
  },
  {
    label: "PEIGNEY",
    value: "52380",
    type: "common",
  },
  {
    label: "ORBIGNY-AU-VAL",
    value: "52363",
    type: "common",
  },
  {
    label: "CHARMES",
    value: "52108",
    type: "common",
  },
  {
    label: "CHANGEY",
    value: "52105",
    type: "common",
  },
  {
    label: "BANNES",
    value: "52037",
    type: "common",
  },
  {
    label: "CHANOY",
    value: "52106",
    type: "common",
  },
  {
    label: "SAINT-MARTIN-LES-LANGRES",
    value: "52452",
    type: "common",
  },
  {
    label: "SAINT-CIERGUES",
    value: "52447",
    type: "common",
  },
  {
    label: "HUMES-JORQUENAY",
    value: "52246",
    type: "common",
  },
  {
    label: "ORMANCEY",
    value: "52366",
    type: "common",
  },
  {
    label: "MARDOR",
    value: "52312",
    type: "common",
  },
  {
    label: "BEAUCHEMIN",
    value: "52042",
    type: "common",
  },
  {
    label: "TERNAT",
    value: "52486",
    type: "common",
  },
  {
    label: "MARAC",
    value: "52307",
    type: "common",
  },
  {
    label: "GIEY-SUR-AUJON",
    value: "52220",
    type: "common",
  },
  {
    label: "AUBEPIERRE-SUR-AUBE",
    value: "52022",
    type: "common",
  },
  {
    label: "DANCEVOIR",
    value: "52165",
    type: "common",
  },
  {
    label: "LIGNEROLLES",
    value: "21350",
    type: "common",
  },
  {
    label: "BOUDREVILLE",
    value: "21090",
    type: "common",
  },
  {
    label: "VEUXHAULLES-SUR-AUBE",
    value: "21674",
    type: "common",
  },
  {
    label: "LOUESME",
    value: "21357",
    type: "common",
  },
  {
    label: "COURBAN",
    value: "21202",
    type: "common",
  },
  {
    label: "THOIRES",
    value: "21628",
    type: "common",
  },
  {
    label: "BRION-SUR-OURCE",
    value: "21109",
    type: "common",
  },
  {
    label: "BISSEY-LA-COTE",
    value: "21077",
    type: "common",
  },
  {
    label: "VANNAIRE",
    value: "21653",
    type: "common",
  },
  {
    label: "OBTREE",
    value: "21465",
    type: "common",
  },
  {
    label: "MOSSON",
    value: "21444",
    type: "common",
  },
  {
    label: "MASSINGY",
    value: "21393",
    type: "common",
  },
  {
    label: "CHAUMONT-LE-BOIS",
    value: "21161",
    type: "common",
  },
  {
    label: "VIX",
    value: "21711",
    type: "common",
  },
  {
    label: "VILLERS-PATRAS",
    value: "21700",
    type: "common",
  },
  {
    label: "POTHIERES",
    value: "21499",
    type: "common",
  },
  {
    label: "NOIRON-SUR-SEINE",
    value: "21460",
    type: "common",
  },
  {
    label: "GOMMEVILLE",
    value: "21302",
    type: "common",
  },
  {
    label: "CHARREY-SUR-SEINE",
    value: "21149",
    type: "common",
  },
  {
    label: "VILLEDIEU",
    value: "21693",
    type: "common",
  },
  {
    label: "LARREY",
    value: "21343",
    type: "common",
  },
  {
    label: "BOUIX",
    value: "21093",
    type: "common",
  },
  {
    label: "MOLESME",
    value: "21419",
    type: "common",
  },
  {
    label: "VERTAULT",
    value: "21671",
    type: "common",
  },
  {
    label: "CHANNES",
    value: "10079",
    type: "common",
  },
  {
    label: "ARTHONNAY",
    value: "89019",
    type: "common",
  },
  {
    label: "VILLIERS-LE-BOIS",
    value: "10431",
    type: "common",
  },
  {
    label: "ETOURVY",
    value: "10143",
    type: "common",
  },
  {
    label: "TRICHEY",
    value: "89422",
    type: "common",
  },
  {
    label: "QUINCEROT",
    value: "89320",
    type: "common",
  },
  {
    label: "CHASEREY",
    value: "10087",
    type: "common",
  },
  {
    label: "MELISEY",
    value: "89247",
    type: "common",
  },
  {
    label: "LIGNIERES",
    value: "10196",
    type: "common",
  },
  {
    label: "COUSSEGREY",
    value: "10112",
    type: "common",
  },
  {
    label: "TRONCHOY",
    value: "89423",
    type: "common",
  },
  {
    label: "MOLOSMES",
    value: "89262",
    type: "common",
  },
  {
    label: "CHENEY",
    value: "89098",
    type: "common",
  },
  {
    label: "MAROLLES-SOUS-LIGNIERES",
    value: "10227",
    type: "common",
  },
  {
    label: "ROFFEY",
    value: "89323",
    type: "common",
  },
  {
    label: "FLOGNY-LA-CHAPELLE",
    value: "89169",
    type: "common",
  },
  {
    label: "VILLIERS-VINEUX",
    value: "89474",
    type: "common",
  },
  {
    label: "VARENNES",
    value: "89430",
    type: "common",
  },
  {
    label: "JAULGES",
    value: "89205",
    type: "common",
  },
  {
    label: "CARISEY",
    value: "89062",
    type: "common",
  },
  {
    label: "VERGIGNY",
    value: "89439",
    type: "common",
  },
  {
    label: "LIGNY-LE-CHATEL",
    value: "89227",
    type: "common",
  },
  {
    label: "MONT-SAINT-SULPICE",
    value: "89268",
    type: "common",
  },
  {
    label: "HAUTERIVE",
    value: "89200",
    type: "common",
  },
  {
    label: "ORMOY",
    value: "89282",
    type: "common",
  },
  {
    label: "CHENY",
    value: "89099",
    type: "common",
  },
  {
    label: "BONNARD",
    value: "89050",
    type: "common",
  },
  {
    label: "BEAUMONT",
    value: "89031",
    type: "common",
  },
  {
    label: "BASSOU",
    value: "89029",
    type: "common",
  },
  {
    label: "EPINEAU-LES-VOVES",
    value: "89152",
    type: "common",
  },
  {
    label: "CHARMOY",
    value: "89085",
    type: "common",
  },
  {
    label: "CHAMPLAY",
    value: "89075",
    type: "common",
  },
  {
    label: "PAROY-SUR-THOLON",
    value: "89289",
    type: "common",
  },
  {
    label: "CHAMVRES",
    value: "89079",
    type: "common",
  },
  {
    label: "BEON",
    value: "89037",
    type: "common",
  },
  {
    label: "SEPEAUX-SAINT ROMAIN",
    value: "89388",
    type: "common",
  },
  {
    label: "TRIGUERES",
    value: "45329",
    type: "common",
  },
  {
    label: "DOUCHY-MONTCORBON",
    value: "45129",
    type: "common",
  },
  {
    label: "SAINT-FIRMIN-DES-BOIS",
    value: "45275",
    type: "common",
  },
  {
    label: "CHATEAU-RENARD",
    value: "45083",
    type: "common",
  },
  {
    label: "SAINT-GERMAIN-DES-PRES",
    value: "45279",
    type: "common",
  },
  {
    label: "GY-LES-NONAINS",
    value: "45165",
    type: "common",
  },
  {
    label: "MORMANT-SUR-VERNISSON",
    value: "45216",
    type: "common",
  },
  {
    label: "CONFLANS-SUR-LOING",
    value: "45102",
    type: "common",
  },
  {
    label: "VIMORY",
    value: "45345",
    type: "common",
  },
  {
    label: "LOMBREUIL",
    value: "45185",
    type: "common",
  },
  {
    label: "CHEVILLON-SUR-HUILLARD",
    value: "45092",
    type: "common",
  },
  {
    label: "THIMORY",
    value: "45321",
    type: "common",
  },
  {
    label: "PRESNOY",
    value: "45256",
    type: "common",
  },
  {
    label: "CHAILLY-EN-GATINAIS",
    value: "45066",
    type: "common",
  },
  {
    label: "BEAUCHAMPS-SUR-HUILLARD",
    value: "45027",
    type: "common",
  },
  {
    label: "AUVILLIERS-EN-GATINAIS",
    value: "45017",
    type: "common",
  },
  {
    label: "SURY-AUX-BOIS",
    value: "45316",
    type: "common",
  },
  {
    label: "CHATENOY",
    value: "45084",
    type: "common",
  },
  {
    label: "SEICHEBRIERES",
    value: "45305",
    type: "common",
  },
  {
    label: "COMBREUX",
    value: "45101",
    type: "common",
  },
  {
    label: "VITRY-AUX-LOGES",
    value: "45346",
    type: "common",
  },
  {
    label: "TRAINOU",
    value: "45327",
    type: "common",
  },
  {
    label: "SULLY-LA-CHAPELLE",
    value: "45314",
    type: "common",
  },
  {
    label: "FAY-AUX-LOGES",
    value: "45142",
    type: "common",
  },
  {
    label: "VENNECY",
    value: "45333",
    type: "common",
  },
  {
    label: "MARIGNY-LES-USAGES",
    value: "45197",
    type: "common",
  },
  {
    label: "BOIGNY-SUR-BIONNE",
    value: "45034",
    type: "common",
  },
  {
    label: "SEMOY",
    value: "45308",
    type: "common",
  },
  {
    label: "SARAN",
    value: "45302",
    type: "common",
  },
  {
    label: "FLEURY-LES-AUBRAIS",
    value: "45147",
    type: "common",
  },
  {
    label: "ORMES",
    value: "45235",
    type: "common",
  },
  {
    label: "BUCY-SAINT-LIPHARD",
    value: "45059",
    type: "common",
  },
  {
    label: "ROZIERES-EN-BEAUCE",
    value: "45264",
    type: "common",
  },
  {
    label: "GEMIGNY",
    value: "45152",
    type: "common",
  },
  {
    label: "EPIEDS-EN-BEAUCE",
    value: "45134",
    type: "common",
  },
  {
    label: "COULMIERS",
    value: "45109",
    type: "common",
  },
  {
    label: "CHARSONVILLE",
    value: "45081",
    type: "common",
  },
  {
    label: "OUZOUER-LE-DOYEN",
    value: "41172",
    type: "common",
  },
  {
    label: "MOISY",
    value: "41141",
    type: "common",
  },
  {
    label: "MOREE",
    value: "41154",
    type: "common",
  },
  {
    label: "BREVAINVILLE",
    value: "41026",
    type: "common",
  },
  {
    label: "SAINT-JEAN-FROIDMENTEL",
    value: "41216",
    type: "common",
  },
  {
    label: "SAINT-HILAIRE-LA-GRAVELLE",
    value: "41214",
    type: "common",
  },
  {
    label: "LA VILLE-AUX-CLERCS",
    value: "41275",
    type: "common",
  },
  {
    label: "CHAUVIGNY-DU-PERCHE",
    value: "41048",
    type: "common",
  },
  {
    label: "ROMILLY",
    value: "41193",
    type: "common",
  },
  {
    label: "BEAUCHENE",
    value: "41014",
    type: "common",
  },
  {
    label: "LE TEMPLE",
    value: "41254",
    type: "common",
  },
  {
    label: "CORMENON",
    value: "41060",
    type: "common",
  },
  {
    label: "RAHAY",
    value: "72250",
    type: "common",
  },
  {
    label: "MAROLLES-LES-SAINT-CALAIS",
    value: "72190",
    type: "common",
  },
  {
    label: "SARGE-SUR-BRAYE",
    value: "41235",
    type: "common",
  },
  {
    label: "SAINT-CALAIS",
    value: "72269",
    type: "common",
  },
  {
    label: "MONTAILLE",
    value: "72204",
    type: "common",
  },
  {
    label: "ECORPAIN",
    value: "72125",
    type: "common",
  },
  {
    label: "MAISONCELLES",
    value: "72178",
    type: "common",
  },
  {
    label: "VOLNAY",
    value: "72382",
    type: "common",
  },
  {
    label: "SAINT-MARS-DE-LOCQUENAY",
    value: "72298",
    type: "common",
  },
  {
    label: "PARIGNE-L'EVEQUE",
    value: "72231",
    type: "common",
  },
  {
    label: "CHALLES",
    value: "72053",
    type: "common",
  },
  {
    label: "RUAUDIN",
    value: "72260",
    type: "common",
  },
  {
    label: "BRETTE-LES-PINS",
    value: "72047",
    type: "common",
  },
  {
    label: "MULSANNE",
    value: "72213",
    type: "common",
  },
  {
    label: "MONCE-EN-BELIN",
    value: "72200",
    type: "common",
  },
  {
    label: "ARNAGE",
    value: "72008",
    type: "common",
  },
  {
    label: "VOIVRES-LES-LE-MANS",
    value: "72381",
    type: "common",
  },
  {
    label: "SPAY",
    value: "72344",
    type: "common",
  },
  {
    label: "FILLE",
    value: "72133",
    type: "common",
  },
  {
    label: "ROEZE-SUR-SARTHE",
    value: "72253",
    type: "common",
  },
  {
    label: "LOUPLANDE",
    value: "72169",
    type: "common",
  },
  {
    label: "PIRMIL",
    value: "72237",
    type: "common",
  },
  {
    label: "MAIGNE",
    value: "72177",
    type: "common",
  },
  {
    label: "FERCE-SUR-SARTHE",
    value: "72131",
    type: "common",
  },
  {
    label: "CHEMIRE-LE-GAUDIN",
    value: "72075",
    type: "common",
  },
  {
    label: "SAINT-PIERRE-DES-BOIS",
    value: "72312",
    type: "common",
  },
  {
    label: "CHANTENAY-VILLEDIEU",
    value: "72059",
    type: "common",
  },
  {
    label: "FONTENAY-SUR-VEGRE",
    value: "72136",
    type: "common",
  },
  {
    label: "CHEVILLE",
    value: "72083",
    type: "common",
  },
  {
    label: "POILLE-SUR-VEGRE",
    value: "72239",
    type: "common",
  },
  {
    label: "AUVERS-LE-HAMON",
    value: "72016",
    type: "common",
  },
  {
    label: "BEAUMONT-PIED-DE-BŒUF",
    value: "53027",
    type: "common",
  },
  {
    label: "VAL-DU-MAINE",
    value: "53017",
    type: "common",
  },
  {
    label: "PREAUX",
    value: "53184",
    type: "common",
  },
  {
    label: "LE BURET",
    value: "53046",
    type: "common",
  },
  {
    label: "SAINT-CHARLES-LA-FORET",
    value: "53206",
    type: "common",
  },
  {
    label: "VILLIERS-CHARLEMAGNE",
    value: "53273",
    type: "common",
  },
  {
    label: "RUILLE-FROID-FONDS",
    value: "53193",
    type: "common",
  },
  {
    label: "HOUSSAY",
    value: "53117",
    type: "common",
  },
  {
    label: "SIMPLE",
    value: "53260",
    type: "common",
  },
  {
    label: "QUELAINES-SAINT-GAULT",
    value: "53186",
    type: "common",
  },
  {
    label: "PEUTON",
    value: "53178",
    type: "common",
  },
  {
    label: "DENAZE",
    value: "53090",
    type: "common",
  },
  {
    label: "COSMES",
    value: "53075",
    type: "common",
  },
  {
    label: "LA CHAPELLE-CRAONNAISE",
    value: "53058",
    type: "common",
  },
  {
    label: "ATHEE",
    value: "53012",
    type: "common",
  },
  {
    label: "LIVRE-LA-TOUCHE",
    value: "53135",
    type: "common",
  },
  {
    label: "BALLOTS",
    value: "53018",
    type: "common",
  },
  {
    label: "SAINT-MICHEL-DE-LA-ROE",
    value: "53242",
    type: "common",
  },
  {
    label: "LA ROE",
    value: "53191",
    type: "common",
  },
  {
    label: "FONTAINE-COUVERTE",
    value: "53098",
    type: "common",
  },
  {
    label: "BRAINS-SUR-LES-MARCHES",
    value: "53041",
    type: "common",
  },
  {
    label: "RANNEE",
    value: "35235",
    type: "common",
  },
  {
    label: "MOUSSE",
    value: "35199",
    type: "common",
  },
  {
    label: "FORGES-LA-FORET",
    value: "35114",
    type: "common",
  },
  {
    label: "DROUGES",
    value: "35102",
    type: "common",
  },
  {
    label: "RETIERS",
    value: "35239",
    type: "common",
  },
  {
    label: "SAINTE-COLOMBE",
    value: "35262",
    type: "common",
  },
  {
    label: "COESMES",
    value: "35082",
    type: "common",
  },
  {
    label: "TRESBŒUF",
    value: "35343",
    type: "common",
  },
  {
    label: "LALLEU",
    value: "35140",
    type: "common",
  },
  {
    label: "LA COUYERE",
    value: "35089",
    type: "common",
  },
  {
    label: "LE SEL-DE-BRETAGNE",
    value: "35322",
    type: "common",
  },
  {
    label: "PANCE",
    value: "35212",
    type: "common",
  },
  {
    label: "LA BOSSE-DE-BRETAGNE",
    value: "35030",
    type: "common",
  },
  {
    label: "POLIGNE",
    value: "35231",
    type: "common",
  },
  {
    label: "PLECHATEL",
    value: "35221",
    type: "common",
  },
  {
    label: "SAINT-SENOUX",
    value: "35312",
    type: "common",
  },
  {
    label: "SAINT-MALO-DE-PHILY",
    value: "35289",
    type: "common",
  },
  {
    label: "LOHEAC",
    value: "35155",
    type: "common",
  },
  {
    label: "MERNEL",
    value: "35175",
    type: "common",
  },
  {
    label: "VAL D'ANAST",
    value: "35168",
    type: "common",
  },
  {
    label: "LIEURON",
    value: "35151",
    type: "common",
  },
  {
    label: "SAINT-SEGLIN",
    value: "35311",
    type: "common",
  },
  {
    label: "COMBLESSAC",
    value: "35084",
    type: "common",
  },
  {
    label: "LES BRULAIS",
    value: "35046",
    type: "common",
  },
  {
    label: "MONTENEUF",
    value: "56136",
    type: "common",
  },
  {
    label: "TREAL",
    value: "56253",
    type: "common",
  },
  {
    label: "REMINIAC",
    value: "56191",
    type: "common",
  },
  {
    label: "CARO",
    value: "56035",
    type: "common",
  },
  {
    label: "SAINT-ABRAHAM",
    value: "56202",
    type: "common",
  },
  {
    label: "VAL D'OUST",
    value: "56197",
    type: "common",
  },
  {
    label: "SERENT",
    value: "56244",
    type: "common",
  },
  {
    label: "LIZIO",
    value: "56112",
    type: "common",
  },
  {
    label: "PLUMELEC",
    value: "56172",
    type: "common",
  },
  {
    label: "CRUGUEL",
    value: "56051",
    type: "common",
  },
  {
    label: "BILLIO",
    value: "56019",
    type: "common",
  },
  {
    label: "SAINT-JEAN-BREVELAY",
    value: "56222",
    type: "common",
  },
  {
    label: "MOUSTOIR-AC",
    value: "56141",
    type: "common",
  },
  {
    label: "COLPO",
    value: "56042",
    type: "common",
  },
  {
    label: "CAMORS",
    value: "56031",
    type: "common",
  },
  {
    label: "LANGUIDIC",
    value: "56101",
    type: "common",
  },
  {
    label: "BRANDERION",
    value: "56021",
    type: "common",
  },
  {
    label: "HENNEBONT",
    value: "56083",
    type: "common",
  },
  {
    label: "CAUDAN",
    value: "56036",
    type: "common",
  },
  {
    label: "QUEVEN",
    value: "56185",
    type: "common",
  },
  {
    label: "GUIDEL",
    value: "56078",
    type: "common",
  },
  {
    label: "GESTEL",
    value: "56063",
    type: "common",
  },
  {
    label: "CLOHARS-CARNOET",
    value: "29031",
    type: "common",
  },
  {
    label: "MOELAN-SUR-MER",
    value: "29150",
    type: "common",
  },
  {
    label: "NEVEZ",
    value: "29153",
    type: "common",
  },
  {
    label: "OTTMARSHEIM",
    value: "68253",
    type: "common",
  },
  {
    label: "HOMBOURG",
    value: "68144",
    type: "common",
  },
  {
    label: "SAUSHEIM",
    value: "68300",
    type: "common",
  },
  {
    label: "ILLZACH",
    value: "68154",
    type: "common",
  },
  {
    label: "BALDERSHEIM",
    value: "68015",
    type: "common",
  },
  {
    label: "RICHWILLER",
    value: "68270",
    type: "common",
  },
  {
    label: "PFASTATT",
    value: "68256",
    type: "common",
  },
  {
    label: "LUTTERBACH",
    value: "68195",
    type: "common",
  },
  {
    label: "KINGERSHEIM",
    value: "68166",
    type: "common",
  },
  {
    label: "WITTELSHEIM",
    value: "68375",
    type: "common",
  },
  {
    label: "CERNAY",
    value: "68063",
    type: "common",
  },
  {
    label: "VIEUX-THANN",
    value: "68348",
    type: "common",
  },
  {
    label: "RODEREN",
    value: "68279",
    type: "common",
  },
  {
    label: "LEIMBACH",
    value: "68180",
    type: "common",
  },
  {
    label: "ASPACH-MICHELBACH",
    value: "68012",
    type: "common",
  },
  {
    label: "ASPACH-LE-BAS",
    value: "68011",
    type: "common",
  },
  {
    label: "THANN",
    value: "68334",
    type: "common",
  },
  {
    label: "RAMMERSMATT",
    value: "68261",
    type: "common",
  },
  {
    label: "BOURBACH-LE-HAUT",
    value: "68046",
    type: "common",
  },
  {
    label: "BOURBACH-LE-BAS",
    value: "68045",
    type: "common",
  },
  {
    label: "WEGSCHEID",
    value: "68361",
    type: "common",
  },
  {
    label: "SICKERT",
    value: "68308",
    type: "common",
  },
  {
    label: "MASEVAUX-NIEDERBRUCK",
    value: "68201",
    type: "common",
  },
  {
    label: "KIRCHBERG",
    value: "68167",
    type: "common",
  },
  {
    label: "DOLLEREN",
    value: "68073",
    type: "common",
  },
  {
    label: "SEWEN",
    value: "68307",
    type: "common",
  },
  {
    label: "OBERBRUCK",
    value: "68239",
    type: "common",
  },
  {
    label: "PLANCHER-LES-MINES",
    value: "70414",
    type: "common",
  },
  {
    label: "SERVANCE-MIELLIN",
    value: "70489",
    type: "common",
  },
  {
    label: "BELFAHY",
    value: "70061",
    type: "common",
  },
  {
    label: "LA VOIVRE",
    value: "70573",
    type: "common",
  },
  {
    label: "TERNUAY-MELAY-ET-SAINT-HILAIRE",
    value: "70498",
    type: "common",
  },
  {
    label: "FAUCOGNEY-ET-LA-MER",
    value: "70227",
    type: "common",
  },
  {
    label: "ECROMAGNY",
    value: "70210",
    type: "common",
  },
  {
    label: "SAINTE-MARIE-EN-CHANOIS",
    value: "70469",
    type: "common",
  },
  {
    label: "LA PROISELIERE-ET-LANGLE",
    value: "70425",
    type: "common",
  },
  {
    label: "LA LANTERNE-ET-LES-ARMONTS",
    value: "70295",
    type: "common",
  },
  {
    label: "LES FESSEY",
    value: "70233",
    type: "common",
  },
  {
    label: "LA CORBIERE",
    value: "70172",
    type: "common",
  },
  {
    label: "SAINT-SAUVEUR",
    value: "70473",
    type: "common",
  },
  {
    label: "FROIDECONCHE",
    value: "70258",
    type: "common",
  },
  {
    label: "ESBOZ-BREST",
    value: "70216",
    type: "common",
  },
  {
    label: "LA BRUYERE",
    value: "70103",
    type: "common",
  },
  {
    label: "BREUCHOTTE",
    value: "70094",
    type: "common",
  },
  {
    label: "SAINTE-MARIE-EN-CHAUX",
    value: "70470",
    type: "common",
  },
  {
    label: "ORMOICHE",
    value: "70398",
    type: "common",
  },
  {
    label: "LUXEUIL-LES-BAINS",
    value: "70311",
    type: "common",
  },
  {
    label: "BREUCHES",
    value: "70093",
    type: "common",
  },
  {
    label: "PLAINEMONT",
    value: "70412",
    type: "common",
  },
  {
    label: "HAUTEVELLE",
    value: "70284",
    type: "common",
  },
  {
    label: "FRANCALMONT",
    value: "70249",
    type: "common",
  },
  {
    label: "CONFLANS-SUR-LANTERNE",
    value: "70168",
    type: "common",
  },
  {
    label: "BRIAUCOURT",
    value: "70097",
    type: "common",
  },
  {
    label: "AINVELLE",
    value: "70008",
    type: "common",
  },
  {
    label: "DAMPIERRE-LES-CONFLANS",
    value: "70196",
    type: "common",
  },
  {
    label: "CUBRY-LES-FAVERNEY",
    value: "70190",
    type: "common",
  },
  {
    label: "BOURGUIGNON-LES-CONFLANS",
    value: "70087",
    type: "common",
  },
  {
    label: "BASSIGNEY",
    value: "70052",
    type: "common",
  },
  {
    label: "SENONCOURT",
    value: "70488",
    type: "common",
  },
  {
    label: "SAINT-REMY-EN-COMTE",
    value: "70472",
    type: "common",
  },
  {
    label: "MENOUX",
    value: "70341",
    type: "common",
  },
  {
    label: "VENISEY",
    value: "70545",
    type: "common",
  },
  {
    label: "TARTECOURT",
    value: "70496",
    type: "common",
  },
  {
    label: "MONTUREUX-LES-BAULAY",
    value: "70372",
    type: "common",
  },
  {
    label: "MAGNY-LES-JUSSEY",
    value: "70320",
    type: "common",
  },
  {
    label: "CONTREGLISE",
    value: "70170",
    type: "common",
  },
  {
    label: "BUFFIGNECOURT",
    value: "70106",
    type: "common",
  },
  {
    label: "RAINCOURT",
    value: "70436",
    type: "common",
  },
  {
    label: "JUSSEY",
    value: "70292",
    type: "common",
  },
  {
    label: "CENDRECOURT",
    value: "70114",
    type: "common",
  },
  {
    label: "NEUVELLE-LES-VOISEY",
    value: "52350",
    type: "common",
  },
  {
    label: "VERNOIS-SUR-MANCE",
    value: "70548",
    type: "common",
  },
  {
    label: "SAINT-MARCEL",
    value: "70468",
    type: "common",
  },
  {
    label: "ROSIERES-SUR-MANCE",
    value: "70454",
    type: "common",
  },
  {
    label: "CEMBOING",
    value: "70112",
    type: "common",
  },
  {
    label: "BARGES",
    value: "70049",
    type: "common",
  },
  {
    label: "LAFERTE-SUR-AMANCE",
    value: "52257",
    type: "common",
  },
  {
    label: "VELLES",
    value: "52513",
    type: "common",
  },
  {
    label: "SOYERS",
    value: "52483",
    type: "common",
  },
  {
    label: "PISSELOUP",
    value: "52390",
    type: "common",
  },
  {
    label: "GUYONVELLE",
    value: "52233",
    type: "common",
  },
  {
    label: "VITREY-SUR-MANCE",
    value: "70572",
    type: "common",
  },
  {
    label: "BETONCOURT-SUR-MANCE",
    value: "70070",
    type: "common",
  },
  {
    label: "ANROSEY",
    value: "52013",
    type: "common",
  },
  {
    label: "MAIZIERES-SUR-AMANCE",
    value: "52303",
    type: "common",
  },
  {
    label: "BIZE",
    value: "52051",
    type: "common",
  },
  {
    label: "ARBIGNY-SOUS-VARENNES",
    value: "52015",
    type: "common",
  },
  {
    label: "HAUTE-AMANCE",
    value: "52242",
    type: "common",
  },
  {
    label: "CHAUDENAY",
    value: "52119",
    type: "common",
  },
  {
    label: "CULMONT",
    value: "52155",
    type: "common",
  },
  {
    label: "CELSOY",
    value: "52090",
    type: "common",
  },
  {
    label: "LECEY",
    value: "52280",
    type: "common",
  },
  {
    label: "CHATENAY-VAUDIN",
    value: "52116",
    type: "common",
  },
  {
    label: "SAINT-VALLIER-SUR-MARNE",
    value: "52457",
    type: "common",
  },
  {
    label: "SAINT-MAURICE",
    value: "52453",
    type: "common",
  },
  {
    label: "LANGRES",
    value: "52269",
    type: "common",
  },
  {
    label: "CHATENAY-MACHERON",
    value: "52115",
    type: "common",
  },
  {
    label: "NOIDANT-LE-ROCHEUX",
    value: "52355",
    type: "common",
  },
  {
    label: "SAINTS-GEOSMES",
    value: "52449",
    type: "common",
  },
  {
    label: "PERRANCEY-LES-VIEUX-MOULINS",
    value: "52383",
    type: "common",
  },
  {
    label: "VOISINES",
    value: "52545",
    type: "common",
  },
  {
    label: "COURCELLES-EN-MONTAGNE",
    value: "52147",
    type: "common",
  },
  {
    label: "SAINT-LOUP-SUR-AUJON",
    value: "52450",
    type: "common",
  },
  {
    label: "ROCHETAILLEE",
    value: "52431",
    type: "common",
  },
  {
    label: "VAUXBONS",
    value: "52507",
    type: "common",
  },
  {
    label: "AULNOY-SUR-AUBE",
    value: "52028",
    type: "common",
  },
  {
    label: "ARBOT",
    value: "52016",
    type: "common",
  },
  {
    label: "ROUVRES-SUR-AUBE",
    value: "52439",
    type: "common",
  },
  {
    label: "GURGY-LA-VILLE",
    value: "21312",
    type: "common",
  },
  {
    label: "LES GOULLES",
    value: "21303",
    type: "common",
  },
  {
    label: "LUCEY",
    value: "21359",
    type: "common",
  },
  {
    label: "LA CHAUME",
    value: "21159",
    type: "common",
  },
  {
    label: "VILLOTTE-SUR-OURCE",
    value: "21706",
    type: "common",
  },
  {
    label: "VANVEY",
    value: "21655",
    type: "common",
  },
  {
    label: "MAISEY-LE-DUC",
    value: "21372",
    type: "common",
  },
  {
    label: "PRUSLY-SUR-OURCE",
    value: "21510",
    type: "common",
  },
  {
    label: "MONTLIOT-ET-COURCELLES",
    value: "21435",
    type: "common",
  },
  {
    label: "CHATILLON-SUR-SEINE",
    value: "21154",
    type: "common",
  },
  {
    label: "SAINTE-COLOMBE-SUR-SEINE",
    value: "21545",
    type: "common",
  },
  {
    label: "ETROCHEY",
    value: "21258",
    type: "common",
  },
  {
    label: "CERILLY",
    value: "21125",
    type: "common",
  },
  {
    label: "POINCON-LES-LARREY",
    value: "21488",
    type: "common",
  },
  {
    label: "MARCENAY",
    value: "21378",
    type: "common",
  },
  {
    label: "BISSEY-LA-PIERRE",
    value: "21078",
    type: "common",
  },
  {
    label: "NICEY",
    value: "21454",
    type: "common",
  },
  {
    label: "GRISELLES",
    value: "21309",
    type: "common",
  },
  {
    label: "CHANNAY",
    value: "21143",
    type: "common",
  },
  {
    label: "CRUZY-LE-CHATEL",
    value: "89131",
    type: "common",
  },
  {
    label: "VILLON",
    value: "89475",
    type: "common",
  },
  {
    label: "THOREY",
    value: "89413",
    type: "common",
  },
  {
    label: "RUGNY",
    value: "89329",
    type: "common",
  },
  {
    label: "BAON",
    value: "89028",
    type: "common",
  },
  {
    label: "SAINT-MARTIN-SUR-ARMANCON",
    value: "89355",
    type: "common",
  },
  {
    label: "JUNAY",
    value: "89211",
    type: "common",
  },
  {
    label: "EPINEUIL",
    value: "89153",
    type: "common",
  },
  {
    label: "DANNEMOINE",
    value: "89137",
    type: "common",
  },
  {
    label: "VEZINNES",
    value: "89447",
    type: "common",
  },
  {
    label: "VEZANNES",
    value: "89445",
    type: "common",
  },
  {
    label: "TISSEY",
    value: "89417",
    type: "common",
  },
  {
    label: "DYE",
    value: "89149",
    type: "common",
  },
  {
    label: "BERNOUIL",
    value: "89038",
    type: "common",
  },
  {
    label: "MERE",
    value: "89250",
    type: "common",
  },
  {
    label: "MALIGNY",
    value: "89242",
    type: "common",
  },
  {
    label: "VILLY",
    value: "89477",
    type: "common",
  },
  {
    label: "VENOUSE",
    value: "89437",
    type: "common",
  },
  {
    label: "PONTIGNY",
    value: "89307",
    type: "common",
  },
  {
    label: "MONTIGNY-LA-RESLE",
    value: "89265",
    type: "common",
  },
  {
    label: "VILLENEUVE-SAINT-SALVES",
    value: "89463",
    type: "common",
  },
  {
    label: "SEIGNELAY",
    value: "89382",
    type: "common",
  },
  {
    label: "ROUVRAY",
    value: "89328",
    type: "common",
  },
  {
    label: "HERY",
    value: "89201",
    type: "common",
  },
  {
    label: "APPOIGNY",
    value: "89013",
    type: "common",
  },
  {
    label: "GURGY",
    value: "89198",
    type: "common",
  },
  {
    label: "CHICHERY",
    value: "89105",
    type: "common",
  },
  {
    label: "CHEMILLY-SUR-YONNE",
    value: "89096",
    type: "common",
  },
  {
    label: "VALRAVILLON",
    value: "89196",
    type: "common",
  },
  {
    label: "FLEURY-LA-VALLEE",
    value: "89167",
    type: "common",
  },
  {
    label: "BRANCHES",
    value: "89053",
    type: "common",
  },
  {
    label: "SENAN",
    value: "89384",
    type: "common",
  },
  {
    label: "POILLY-SUR-THOLON",
    value: "89304",
    type: "common",
  },
  {
    label: "LA FERTE-LOUPIERE",
    value: "89163",
    type: "common",
  },
  {
    label: "MONTHOLON",
    value: "89003",
    type: "common",
  },
  {
    label: "CHARNY OREE DE PUISAYE",
    value: "89086",
    type: "common",
  },
  {
    label: "MELLEROY",
    value: "45199",
    type: "common",
  },
  {
    label: "MONTBOUY",
    value: "45210",
    type: "common",
  },
  {
    label: "LA CHAPELLE-SUR-AVEYRON",
    value: "45077",
    type: "common",
  },
  {
    label: "SOLTERRE",
    value: "45312",
    type: "common",
  },
  {
    label: "PRESSIGNY-LES-PINS",
    value: "45257",
    type: "common",
  },
  {
    label: "OUZOUER-DES-CHAMPS",
    value: "45242",
    type: "common",
  },
  {
    label: "MONTCRESSON",
    value: "45212",
    type: "common",
  },
  {
    label: "CORTRAT",
    value: "45105",
    type: "common",
  },
  {
    label: "SAINT-HILAIRE-SUR-PUISEAUX",
    value: "45283",
    type: "common",
  },
  {
    label: "OUSSOY-EN-GATINAIS",
    value: "45239",
    type: "common",
  },
  {
    label: "NOYERS",
    value: "45230",
    type: "common",
  },
  {
    label: "LA COUR-MARIGNY",
    value: "45112",
    type: "common",
  },
  {
    label: "LORRIS",
    value: "45187",
    type: "common",
  },
  {
    label: "COUDROY",
    value: "45107",
    type: "common",
  },
  {
    label: "VIEILLES-MAISONS-SUR-JOUDRY",
    value: "45334",
    type: "common",
  },
  {
    label: "SAINT-MARTIN-D'ABBAT",
    value: "45290",
    type: "common",
  },
  {
    label: "CHATEAUNEUF-SUR-LOIRE",
    value: "45082",
    type: "common",
  },
  {
    label: "SAINT-DENIS-DE-L'HOTEL",
    value: "45273",
    type: "common",
  },
  {
    label: "MARDIE",
    value: "45194",
    type: "common",
  },
  {
    label: "DONNERY",
    value: "45126",
    type: "common",
  },
  {
    label: "CHECY",
    value: "45089",
    type: "common",
  },
  {
    label: "BOU",
    value: "45043",
    type: "common",
  },
  {
    label: "SAINT-JEAN-LE-BLANC",
    value: "45286",
    type: "common",
  },
  {
    label: "SAINT-JEAN-DE-BRAYE",
    value: "45284",
    type: "common",
  },
  {
    label: "SAINT-DENIS-EN-VAL",
    value: "45274",
    type: "common",
  },
  {
    label: "COMBLEUX",
    value: "45100",
    type: "common",
  },
  {
    label: "SAINT-PRYVE-SAINT-MESMIN",
    value: "45298",
    type: "common",
  },
  {
    label: "SAINT-JEAN-DE-LA-RUELLE",
    value: "45285",
    type: "common",
  },
  {
    label: "ORLEANS",
    value: "45234",
    type: "common",
  },
  {
    label: "OLIVET",
    value: "45232",
    type: "common",
  },
  {
    label: "SAINT-AY",
    value: "45269",
    type: "common",
  },
  {
    label: "INGRE",
    value: "45169",
    type: "common",
  },
  {
    label: "LA CHAPELLE-SAINT-MESMIN",
    value: "45075",
    type: "common",
  },
  {
    label: "CHAINGY",
    value: "45067",
    type: "common",
  },
  {
    label: "HUISSEAU-SUR-MAUVES",
    value: "45167",
    type: "common",
  },
  {
    label: "BACCON",
    value: "45019",
    type: "common",
  },
  {
    label: "VILLERMAIN",
    value: "41289",
    type: "common",
  },
  {
    label: "BEAUCE LA ROMAINE",
    value: "41173",
    type: "common",
  },
  {
    label: "SAINT-LAURENT-DES-BOIS",
    value: "41219",
    type: "common",
  },
  {
    label: "BINAS",
    value: "41017",
    type: "common",
  },
  {
    label: "AUTAINVILLE",
    value: "41006",
    type: "common",
  },
  {
    label: "VIEVY-LE-RAYE",
    value: "41273",
    type: "common",
  },
  {
    label: "PEZOU",
    value: "41175",
    type: "common",
  },
  {
    label: "LIGNIERES",
    value: "41115",
    type: "common",
  },
  {
    label: "FRETEVAL",
    value: "41095",
    type: "common",
  },
  {
    label: "BUSLOUP",
    value: "41028",
    type: "common",
  },
  {
    label: "RAHART",
    value: "41186",
    type: "common",
  },
  {
    label: "LISLE",
    value: "41116",
    type: "common",
  },
  {
    label: "DANZE",
    value: "41073",
    type: "common",
  },
  {
    label: "AZE",
    value: "41010",
    type: "common",
  },
  {
    label: "EPUISAY",
    value: "41078",
    type: "common",
  },
  {
    label: "SAVIGNY-SUR-BRAYE",
    value: "41238",
    type: "common",
  },
  {
    label: "SAINT-GERVAIS-DE-VIC",
    value: "72286",
    type: "common",
  },
  {
    label: "LA CHAPELLE-HUON",
    value: "72064",
    type: "common",
  },
  {
    label: "SAINTE-CEROTTE",
    value: "72272",
    type: "common",
  },
  {
    label: "COGNERS",
    value: "72085",
    type: "common",
  },
  {
    label: "TRESSON",
    value: "72361",
    type: "common",
  },
  {
    label: "MONTREUIL-LE-HENRI",
    value: "72210",
    type: "common",
  },
  {
    label: "VILLAINES-SOUS-LUCE",
    value: "72376",
    type: "common",
  },
  {
    label: "LE GRAND-LUCE",
    value: "72143",
    type: "common",
  },
  {
    label: "TELOCHE",
    value: "72350",
    type: "common",
  },
  {
    label: "SAINT-MARS-D'OUTILLE",
    value: "72299",
    type: "common",
  },
  {
    label: "SAINT-GERVAIS-EN-BELIN",
    value: "72287",
    type: "common",
  },
  {
    label: "LAIGNE-EN-BELIN",
    value: "72155",
    type: "common",
  },
  {
    label: "PARIGNE-LE-POLIN",
    value: "72230",
    type: "common",
  },
  {
    label: "GUECELARD",
    value: "72146",
    type: "common",
  },
  {
    label: "LA SUZE-SUR-SARTHE",
    value: "72346",
    type: "common",
  },
  {
    label: "CERANS-FOULLETOURTE",
    value: "72051",
    type: "common",
  },
  {
    label: "SAINT-JEAN-DU-BOIS",
    value: "72293",
    type: "common",
  },
  {
    label: "TASSE",
    value: "72347",
    type: "common",
  },
  {
    label: "NOYEN-SUR-SARTHE",
    value: "72223",
    type: "common",
  },
  {
    label: "AVOISE",
    value: "72021",
    type: "common",
  },
  {
    label: "ASNIERES-SUR-VEGRE",
    value: "72010",
    type: "common",
  },
  {
    label: "SOLESMES",
    value: "72336",
    type: "common",
  },
  {
    label: "JUIGNE-SUR-SARTHE",
    value: "72151",
    type: "common",
  },
  {
    label: "SOUVIGNE-SUR-SARTHE",
    value: "72343",
    type: "common",
  },
  {
    label: "SABLE-SUR-SARTHE",
    value: "72264",
    type: "common",
  },
  {
    label: "SAINT-LOUP-DU-DORAT",
    value: "53233",
    type: "common",
  },
  {
    label: "SAINT-BRICE",
    value: "53203",
    type: "common",
  },
  {
    label: "BOUESSAY",
    value: "53037",
    type: "common",
  },
  {
    label: "BOUERE",
    value: "53036",
    type: "common",
  },
  {
    label: "GREZ-EN-BOUERE",
    value: "53110",
    type: "common",
  },
  {
    label: "FROMENTIERES",
    value: "53101",
    type: "common",
  },
  {
    label: "MARIGNE-PEUTON",
    value: "53145",
    type: "common",
  },
  {
    label: "PREE-D'ANJOU",
    value: "53124",
    type: "common",
  },
  {
    label: "POMMERIEUX",
    value: "53180",
    type: "common",
  },
  {
    label: "CRAON",
    value: "53084",
    type: "common",
  },
  {
    label: "SAINT-MARTIN-DU-LIMET",
    value: "53240",
    type: "common",
  },
  {
    label: "NIAFLES",
    value: "53165",
    type: "common",
  },
  {
    label: "LA SELLE-CRAONNAISE",
    value: "53258",
    type: "common",
  },
  {
    label: "SAINT-SATURNIN-DU-LIMET",
    value: "53253",
    type: "common",
  },
  {
    label: "SAINT-AIGNAN-SUR-ROE",
    value: "53197",
    type: "common",
  },
  {
    label: "CONGRIER",
    value: "53073",
    type: "common",
  },
  {
    label: "SENONNES",
    value: "53259",
    type: "common",
  },
  {
    label: "LA ROUAUDIERE",
    value: "53192",
    type: "common",
  },
  {
    label: "EANCE",
    value: "35103",
    type: "common",
  },
  {
    label: "CHELUN",
    value: "35077",
    type: "common",
  },
  {
    label: "MARTIGNE-FERCHAUD",
    value: "35167",
    type: "common",
  },
  {
    label: "SOULVACHE",
    value: "44200",
    type: "common",
  },
  {
    label: "THOURIE",
    value: "35335",
    type: "common",
  },
  {
    label: "TEILLAY",
    value: "35332",
    type: "common",
  },
  {
    label: "ERCE-EN-LAMEE",
    value: "35106",
    type: "common",
  },
  {
    label: "LA NOE-BLANCHE",
    value: "35202",
    type: "common",
  },
  {
    label: "BAIN-DE-BRETAGNE",
    value: "35012",
    type: "common",
  },
  {
    label: "GUIPRY-MESSAC",
    value: "35176",
    type: "common",
  },
  {
    label: "PIPRIAC",
    value: "35219",
    type: "common",
  },
  {
    label: "BRUC-SUR-AFF",
    value: "35045",
    type: "common",
  },
  {
    label: "SIXT-SUR-AFF",
    value: "35328",
    type: "common",
  },
  {
    label: "LA GACILLY",
    value: "56061",
    type: "common",
  },
  {
    label: "CARENTOIR",
    value: "56033",
    type: "common",
  },
  {
    label: "SAINT-NICOLAS-DU-TERTRE",
    value: "56230",
    type: "common",
  },
  {
    label: "SAINT-LAURENT-SUR-OUST",
    value: "56224",
    type: "common",
  },
  {
    label: "SAINT-CONGARD",
    value: "56211",
    type: "common",
  },
  {
    label: "RUFFIAC",
    value: "56200",
    type: "common",
  },
  {
    label: "MISSIRIAC",
    value: "56133",
    type: "common",
  },
  {
    label: "SAINT-MARCEL",
    value: "56228",
    type: "common",
  },
  {
    label: "PLEUCADEUC",
    value: "56159",
    type: "common",
  },
  {
    label: "MALESTROIT",
    value: "56124",
    type: "common",
  },
  {
    label: "BOHAL",
    value: "56020",
    type: "common",
  },
  {
    label: "SAINT-GUYOMARD",
    value: "56219",
    type: "common",
  },
  {
    label: "TREDION",
    value: "56254",
    type: "common",
  },
  {
    label: "PLAUDREN",
    value: "56157",
    type: "common",
  },
  {
    label: "LOCQUELTAS",
    value: "56120",
    type: "common",
  },
  {
    label: "LOCMARIA-GRAND-CHAMP",
    value: "56115",
    type: "common",
  },
  {
    label: "GRAND-CHAMP",
    value: "56067",
    type: "common",
  },
  {
    label: "BRANDIVY",
    value: "56022",
    type: "common",
  },
  {
    label: "PLUVIGNER",
    value: "56177",
    type: "common",
  },
  {
    label: "LANDEVANT",
    value: "56097",
    type: "common",
  },
  {
    label: "LANDAUL",
    value: "56096",
    type: "common",
  },
  {
    label: "NOSTANG",
    value: "56148",
    type: "common",
  },
  {
    label: "MERLEVENEZ",
    value: "56130",
    type: "common",
  },
  {
    label: "KERVIGNAC",
    value: "56094",
    type: "common",
  },
  {
    label: "LORIENT",
    value: "56121",
    type: "common",
  },
  {
    label: "LOCMIQUELIC",
    value: "56118",
    type: "common",
  },
  {
    label: "LANESTER",
    value: "56098",
    type: "common",
  },
  {
    label: "PLOEMEUR",
    value: "56162",
    type: "common",
  },
  {
    label: "PETIT-LANDAU",
    value: "68254",
    type: "common",
  },
  {
    label: "NIFFER",
    value: "68238",
    type: "common",
  },
  {
    label: "HABSHEIM",
    value: "68118",
    type: "common",
  },
  {
    label: "ZIMMERSHEIM",
    value: "68386",
    type: "common",
  },
  {
    label: "RIXHEIM",
    value: "68278",
    type: "common",
  },
  {
    label: "RIEDISHEIM",
    value: "68271",
    type: "common",
  },
  {
    label: "ESCHENTZWILLER",
    value: "68084",
    type: "common",
  },
  {
    label: "DIETWILLER",
    value: "68072",
    type: "common",
  },
  {
    label: "BRUEBACH",
    value: "68055",
    type: "common",
  },
  {
    label: "MULHOUSE",
    value: "68224",
    type: "common",
  },
  {
    label: "MORSCHWILLER-LE-BAS",
    value: "68218",
    type: "common",
  },
  {
    label: "HOCHSTATT",
    value: "68141",
    type: "common",
  },
  {
    label: "BRUNSTATT-DIDENHEIM",
    value: "68056",
    type: "common",
  },
  {
    label: "REININGUE",
    value: "68267",
    type: "common",
  },
  {
    label: "HEIMSBRUNN",
    value: "68129",
    type: "common",
  },
  {
    label: "GALFINGUE",
    value: "68101",
    type: "common",
  },
  {
    label: "SOPPE-LE-BAS",
    value: "68313",
    type: "common",
  },
  {
    label: "SCHWEIGHOUSE-THANN",
    value: "68302",
    type: "common",
  },
  {
    label: "GUEWENHEIM",
    value: "68115",
    type: "common",
  },
  {
    label: "DIEFMATTEN",
    value: "68071",
    type: "common",
  },
  {
    label: "BURNHAUPT-LE-HAUT",
    value: "68060",
    type: "common",
  },
  {
    label: "BURNHAUPT-LE-BAS",
    value: "68059",
    type: "common",
  },
  {
    label: "PETITEFONTAINE",
    value: "90078",
    type: "common",
  },
  {
    label: "LEVAL",
    value: "90066",
    type: "common",
  },
  {
    label: "LACHAPELLE-SOUS-ROUGEMONT",
    value: "90058",
    type: "common",
  },
  {
    label: "SENTHEIM",
    value: "68304",
    type: "common",
  },
  {
    label: "LE HAUT SOULTZBACH",
    value: "68219",
    type: "common",
  },
  {
    label: "LAUW",
    value: "68179",
    type: "common",
  },
  {
    label: "BRETTEN",
    value: "68052",
    type: "common",
  },
  {
    label: "ROUGEMONT-LE-CHATEAU",
    value: "90089",
    type: "common",
  },
  {
    label: "ROMAGNY-SOUS-ROUGEMONT",
    value: "90086",
    type: "common",
  },
  {
    label: "ETUEFFONT",
    value: "90041",
    type: "common",
  },
  {
    label: "VESCEMONT",
    value: "90102",
    type: "common",
  },
  {
    label: "ROUGEGOUTTE",
    value: "90088",
    type: "common",
  },
  {
    label: "RIERVESCEMONT",
    value: "90085",
    type: "common",
  },
  {
    label: "PETITMAGNY",
    value: "90079",
    type: "common",
  },
  {
    label: "LAMADELEINE-VAL-DES-ANGES",
    value: "90061",
    type: "common",
  },
  {
    label: "GROSMAGNY",
    value: "90054",
    type: "common",
  },
  {
    label: "GIROMAGNY",
    value: "90052",
    type: "common",
  },
  {
    label: "CHAUX",
    value: "90023",
    type: "common",
  },
  {
    label: "LEPUIX",
    value: "90065",
    type: "common",
  },
  {
    label: "AUXELLES-HAUT",
    value: "90006",
    type: "common",
  },
  {
    label: "AUXELLES-BAS",
    value: "90005",
    type: "common",
  },
  {
    label: "PLANCHER-BAS",
    value: "70413",
    type: "common",
  },
  {
    label: "FRESSE",
    value: "70256",
    type: "common",
  },
  {
    label: "SAINT-BARTHELEMY",
    value: "70459",
    type: "common",
  },
  {
    label: "MONTESSAUX",
    value: "70361",
    type: "common",
  },
  {
    label: "MELISEY",
    value: "70339",
    type: "common",
  },
  {
    label: "BELONCHAMP",
    value: "70063",
    type: "common",
  },
  {
    label: "SAINT-GERMAIN",
    value: "70464",
    type: "common",
  },
  {
    label: "RIGNOVELLE",
    value: "70445",
    type: "common",
  },
  {
    label: "LINEXERT",
    value: "70304",
    type: "common",
  },
  {
    label: "LANTENOT",
    value: "70294",
    type: "common",
  },
  {
    label: "BELMONT",
    value: "70062",
    type: "common",
  },
  {
    label: "QUERS",
    value: "70432",
    type: "common",
  },
  {
    label: "MAGNIVRAY",
    value: "70314",
    type: "common",
  },
  {
    label: "FRANCHEVELLE",
    value: "70250",
    type: "common",
  },
  {
    label: "CITERS",
    value: "70155",
    type: "common",
  },
  {
    label: "LA CHAPELLE-LES-LUXEUIL",
    value: "70128",
    type: "common",
  },
  {
    label: "AILLONCOURT",
    value: "70007",
    type: "common",
  },
  {
    label: "EHUNS",
    value: "70213",
    type: "common",
  },
  {
    label: "BROTTE-LES-LUXEUIL",
    value: "70098",
    type: "common",
  },
  {
    label: "BETONCOURT-LES-BROTTE",
    value: "70067",
    type: "common",
  },
  {
    label: "BAUDONCOURT",
    value: "70055",
    type: "common",
  },
  {
    label: "VISONCOURT",
    value: "70571",
    type: "common",
  },
  {
    label: "VILLERS-LES-LUXEUIL",
    value: "70564",
    type: "common",
  },
  {
    label: "VELORCEY",
    value: "70541",
    type: "common",
  },
  {
    label: "MEURCOURT",
    value: "70344",
    type: "common",
  },
  {
    label: "ABELCOURT",
    value: "70001",
    type: "common",
  },
  {
    label: "LA VILLEDIEU-EN-FONTENETTE",
    value: "70555",
    type: "common",
  },
  {
    label: "LE VAL-SAINT-ELOI",
    value: "70518",
    type: "common",
  },
  {
    label: "NEUREY-EN-VAUX",
    value: "70380",
    type: "common",
  },
  {
    label: "MERSUAY",
    value: "70343",
    type: "common",
  },
  {
    label: "EQUEVILLEY",
    value: "70214",
    type: "common",
  },
  {
    label: "BREUREY-LES-FAVERNEY",
    value: "70095",
    type: "common",
  },
  {
    label: "FLEUREY-LES-FAVERNEY",
    value: "70236",
    type: "common",
  },
  {
    label: "FAVERNEY",
    value: "70228",
    type: "common",
  },
  {
    label: "AMANCE",
    value: "70012",
    type: "common",
  },
  {
    label: "PURGEROT",
    value: "70427",
    type: "common",
  },
  {
    label: "FOUCHECOURT",
    value: "70244",
    type: "common",
  },
  {
    label: "BAULAY",
    value: "70056",
    type: "common",
  },
  {
    label: "ABONCOURT-GESINCOURT",
    value: "70002",
    type: "common",
  },
  {
    label: "LAMBREY",
    value: "70293",
    type: "common",
  },
  {
    label: "GEVIGNEY-ET-MERCEY",
    value: "70267",
    type: "common",
  },
  {
    label: "BOUGEY",
    value: "70078",
    type: "common",
  },
  {
    label: "AUGICOURT",
    value: "70035",
    type: "common",
  },
  {
    label: "PREIGNEY",
    value: "70423",
    type: "common",
  },
  {
    label: "OIGNEY",
    value: "70392",
    type: "common",
  },
  {
    label: "MONTIGNY-LES-CHERLIEU",
    value: "70362",
    type: "common",
  },
  {
    label: "LA ROCHELLE",
    value: "70450",
    type: "common",
  },
  {
    label: "LA QUARTE",
    value: "70430",
    type: "common",
  },
  {
    label: "OUGE",
    value: "70400",
    type: "common",
  },
  {
    label: "CINTREY",
    value: "70153",
    type: "common",
  },
  {
    label: "CHAUVIREY-LE-VIEIL",
    value: "70144",
    type: "common",
  },
  {
    label: "CHAUVIREY-LE-CHATEL",
    value: "70143",
    type: "common",
  },
  {
    label: "FAYL-BILLOT",
    value: "52197",
    type: "common",
  },
  {
    label: "PIERREMONT-SUR-AMANCE",
    value: "52388",
    type: "common",
  },
  {
    label: "ROUGEUX",
    value: "52438",
    type: "common",
  },
  {
    label: "CHAMPSEVRAINE",
    value: "52083",
    type: "common",
  },
  {
    label: "TORCENAY",
    value: "52492",
    type: "common",
  },
  {
    label: "LES LOGES",
    value: "52290",
    type: "common",
  },
  {
    label: "VIOLOT",
    value: "52539",
    type: "common",
  },
  {
    label: "CHALINDREY",
    value: "52093",
    type: "common",
  },
  {
    label: "NOIDANT-CHATENOY",
    value: "52354",
    type: "common",
  },
  {
    label: "LE PAILLY",
    value: "52374",
    type: "common",
  },
  {
    label: "PALAISEUL",
    value: "52375",
    type: "common",
  },
  {
    label: "COHONS",
    value: "52134",
    type: "common",
  },
  {
    label: "ORCEVAUX",
    value: "52364",
    type: "common",
  },
  {
    label: "VERSEILLES-LE-HAUT",
    value: "52516",
    type: "common",
  },
  {
    label: "BRENNES",
    value: "52070",
    type: "common",
  },
  {
    label: "BOURG",
    value: "52062",
    type: "common",
  },
  {
    label: "FLAGEY",
    value: "52200",
    type: "common",
  },
  {
    label: "PERROGNEY-LES-FONTAINES",
    value: "52384",
    type: "common",
  },
  {
    label: "APREY",
    value: "52014",
    type: "common",
  },
  {
    label: "VITRY-EN-MONTAGNE",
    value: "52540",
    type: "common",
  },
  {
    label: "ROUELLES",
    value: "52437",
    type: "common",
  },
  {
    label: "GERMAINES",
    value: "52216",
    type: "common",
  },
  {
    label: "COLMIER-LE-HAUT",
    value: "52138",
    type: "common",
  },
  {
    label: "BAY-SUR-AUBE",
    value: "52040",
    type: "common",
  },
  {
    label: "COLMIER-LE-BAS",
    value: "52137",
    type: "common",
  },
  {
    label: "MENESBLE",
    value: "21402",
    type: "common",
  },
  {
    label: "GURGY-LE-CHATEAU",
    value: "21313",
    type: "common",
  },
  {
    label: "CHAMBAIN",
    value: "21129",
    type: "common",
  },
  {
    label: "BUXEROLLES",
    value: "21123",
    type: "common",
  },
  {
    label: "RECEY-SUR-OURCE",
    value: "21519",
    type: "common",
  },
  {
    label: "LEUGLAY",
    value: "21346",
    type: "common",
  },
  {
    label: "FAVEROLLES-LES-LUCEY",
    value: "21262",
    type: "common",
  },
  {
    label: "VOULAINES-LES-TEMPLIERS",
    value: "21717",
    type: "common",
  },
  {
    label: "VILLIERS-LE-DUC",
    value: "21704",
    type: "common",
  },
  {
    label: "BUNCEY",
    value: "21115",
    type: "common",
  },
  {
    label: "CHAMESSON",
    value: "21134",
    type: "common",
  },
  {
    label: "AMPILLY-LE-SEC",
    value: "21012",
    type: "common",
  },
  {
    label: "BALOT",
    value: "21044",
    type: "common",
  },
  {
    label: "SENNEVOY-LE-BAS",
    value: "89385",
    type: "common",
  },
  {
    label: "GIGNY",
    value: "89187",
    type: "common",
  },
  {
    label: "LAIGNES",
    value: "21336",
    type: "common",
  },
  {
    label: "FONTAINES-LES-SECHES",
    value: "21279",
    type: "common",
  },
  {
    label: "SENNEVOY-LE-HAUT",
    value: "89386",
    type: "common",
  },
  {
    label: "GLAND",
    value: "89191",
    type: "common",
  },
  {
    label: "PIMELLES",
    value: "89299",
    type: "common",
  },
  {
    label: "ARGENTENAY",
    value: "89016",
    type: "common",
  },
  {
    label: "ANCY-LE-LIBRE",
    value: "89006",
    type: "common",
  },
  {
    label: "VIREAUX",
    value: "89481",
    type: "common",
  },
  {
    label: "TANLAY",
    value: "89407",
    type: "common",
  },
  {
    label: "LEZINNES",
    value: "89223",
    type: "common",
  },
  {
    label: "YROUERRE",
    value: "89486",
    type: "common",
  },
  {
    label: "TONNERRE",
    value: "89418",
    type: "common",
  },
  {
    label: "VIVIERS",
    value: "89482",
    type: "common",
  },
  {
    label: "SERRIGNY",
    value: "89393",
    type: "common",
  },
  {
    label: "FLEYS",
    value: "89168",
    type: "common",
  },
  {
    label: "COLLAN",
    value: "89112",
    type: "common",
  },
  {
    label: "BERU",
    value: "89039",
    type: "common",
  },
  {
    label: "FONTENAY-PRES-CHABLIS",
    value: "89175",
    type: "common",
  },
  {
    label: "LA CHAPELLE-VAUPELTEIGNE",
    value: "89081",
    type: "common",
  },
  {
    label: "CHABLIS",
    value: "89068",
    type: "common",
  },
  {
    label: "LIGNORELLES",
    value: "89226",
    type: "common",
  },
  {
    label: "BLEIGNY-LE-CARREAU",
    value: "89045",
    type: "common",
  },
  {
    label: "BEINE",
    value: "89034",
    type: "common",
  },
  {
    label: "VENOY",
    value: "89438",
    type: "common",
  },
  {
    label: "MONETEAU",
    value: "89263",
    type: "common",
  },
  {
    label: "PERRIGNY",
    value: "89295",
    type: "common",
  },
  {
    label: "SAINT-GEORGES-SUR-BAULCHE",
    value: "89346",
    type: "common",
  },
  {
    label: "CHARBUY",
    value: "89083",
    type: "common",
  },
  {
    label: "LINDRY",
    value: "89228",
    type: "common",
  },
  {
    label: "SAINT-MAURICE-THIZOUAILLE",
    value: "89361",
    type: "common",
  },
  {
    label: "SAINT-MAURICE-LE-VIEIL",
    value: "89360",
    type: "common",
  },
  {
    label: "EGLENY",
    value: "89150",
    type: "common",
  },
  {
    label: "CHASSY",
    value: "89088",
    type: "common",
  },
  {
    label: "LE VAL D'OCRE",
    value: "89334",
    type: "common",
  },
  {
    label: "LES ORMES",
    value: "89281",
    type: "common",
  },
  {
    label: "SOMMECAISE",
    value: "89397",
    type: "common",
  },
  {
    label: "SAINT-MAURICE-SUR-AVEYRON",
    value: "45292",
    type: "common",
  },
  {
    label: "CHATILLON-COLIGNY",
    value: "45085",
    type: "common",
  },
  {
    label: "AILLANT-SUR-MILLERON",
    value: "45002",
    type: "common",
  },
  {
    label: "SAINTE-GENEVIEVE-DES-BOIS",
    value: "45278",
    type: "common",
  },
  {
    label: "NOGENT-SUR-VERNISSON",
    value: "45229",
    type: "common",
  },
  {
    label: "VARENNES-CHANGY",
    value: "45332",
    type: "common",
  },
  {
    label: "LANGESSE",
    value: "45180",
    type: "common",
  },
  {
    label: "LE MOULINET-SUR-SOLIN",
    value: "45218",
    type: "common",
  },
  {
    label: "MONTEREAU",
    value: "45213",
    type: "common",
  },
  {
    label: "BRAY-SAINT-AIGNAN",
    value: "45051",
    type: "common",
  },
  {
    label: "BOUZY-LA-FORET",
    value: "45049",
    type: "common",
  },
  {
    label: "LES BORDES",
    value: "45042",
    type: "common",
  },
  {
    label: "SAINT-BENOIT-SUR-LOIRE",
    value: "45270",
    type: "common",
  },
  {
    label: "SIGLOY",
    value: "45311",
    type: "common",
  },
  {
    label: "GUILLY",
    value: "45164",
    type: "common",
  },
  {
    label: "GERMIGNY-DES-PRES",
    value: "45153",
    type: "common",
  },
  {
    label: "OUVROUER-LES-CHAMPS",
    value: "45241",
    type: "common",
  },
  {
    label: "JARGEAU",
    value: "45173",
    type: "common",
  },
  {
    label: "FEROLLES",
    value: "45144",
    type: "common",
  },
  {
    label: "SANDILLON",
    value: "45300",
    type: "common",
  },
  {
    label: "DARVOY",
    value: "45123",
    type: "common",
  },
  {
    label: "SAINT-CYR-EN-VAL",
    value: "45272",
    type: "common",
  },
  {
    label: "SAINT-HILAIRE-SAINT-MESMIN",
    value: "45282",
    type: "common",
  },
  {
    label: "MEZIERES-LEZ-CLERY",
    value: "45204",
    type: "common",
  },
  {
    label: "MAREAU-AUX-PRES",
    value: "45196",
    type: "common",
  },
  {
    label: "CLERY-SAINT-ANDRE",
    value: "45098",
    type: "common",
  },
  {
    label: "MEUNG-SUR-LOIRE",
    value: "45203",
    type: "common",
  },
  {
    label: "BAULE",
    value: "45024",
    type: "common",
  },
  {
    label: "VILLORCEAU",
    value: "45344",
    type: "common",
  },
  {
    label: "MESSAS",
    value: "45202",
    type: "common",
  },
  {
    label: "CRAVANT",
    value: "45116",
    type: "common",
  },
  {
    label: "LE BARDON",
    value: "45020",
    type: "common",
  },
  {
    label: "LORGES",
    value: "41119",
    type: "common",
  },
  {
    label: "LE PLESSIS-L'ECHELLE",
    value: "41178",
    type: "common",
  },
  {
    label: "MARCHENOIR",
    value: "41123",
    type: "common",
  },
  {
    label: "BRIOU",
    value: "41027",
    type: "common",
  },
  {
    label: "SAINT-LEONARD-EN-BEAUCE",
    value: "41221",
    type: "common",
  },
  {
    label: "OUCQUES LA NOUVELLE",
    value: "41171",
    type: "common",
  },
  {
    label: "EPIAIS",
    value: "41077",
    type: "common",
  },
  {
    label: "ROCE",
    value: "41190",
    type: "common",
  },
  {
    label: "RENAY",
    value: "41187",
    type: "common",
  },
  {
    label: "FAYE",
    value: "41081",
    type: "common",
  },
  {
    label: "LA CHAPELLE-ENCHERIE",
    value: "41037",
    type: "common",
  },
  {
    label: "SAINT-OUEN",
    value: "41226",
    type: "common",
  },
  {
    label: "SAINT-FIRMIN-DES-PRES",
    value: "41209",
    type: "common",
  },
  {
    label: "MESLAY",
    value: "41138",
    type: "common",
  },
  {
    label: "AREINES",
    value: "41003",
    type: "common",
  },
  {
    label: "VILLIERS-SUR-LOIR",
    value: "41294",
    type: "common",
  },
  {
    label: "VENDOME",
    value: "41269",
    type: "common",
  },
  {
    label: "MAZANGE",
    value: "41131",
    type: "common",
  },
  {
    label: "LUNAY",
    value: "41120",
    type: "common",
  },
  {
    label: "FORTAN",
    value: "41090",
    type: "common",
  },
  {
    label: "FONTAINE-LES-COTEAUX",
    value: "41087",
    type: "common",
  },
  {
    label: "CELLE",
    value: "41030",
    type: "common",
  },
  {
    label: "BESSE-SUR-BRAYE",
    value: "72035",
    type: "common",
  },
  {
    label: "BONNEVEAU",
    value: "41020",
    type: "common",
  },
  {
    label: "VANCE",
    value: "72368",
    type: "common",
  },
  {
    label: "LOIR EN VALLEE",
    value: "72262",
    type: "common",
  },
  {
    label: "SAINT-GEORGES-DE-LA-COUEE",
    value: "72279",
    type: "common",
  },
  {
    label: "COURDEMANCHE",
    value: "72103",
    type: "common",
  },
  {
    label: "SAINT-VINCENT-DU-LOROUER",
    value: "72325",
    type: "common",
  },
  {
    label: "SAINT-PIERRE-DU-LOROUER",
    value: "72314",
    type: "common",
  },
  {
    label: "PRUILLE-L'EGUILLE",
    value: "72248",
    type: "common",
  },
  {
    label: "MARIGNE-LAILLE",
    value: "72187",
    type: "common",
  },
  {
    label: "JUPILLES",
    value: "72153",
    type: "common",
  },
  {
    label: "ECOMMOY",
    value: "72124",
    type: "common",
  },
  {
    label: "SAINT-OUEN-EN-BELIN",
    value: "72306",
    type: "common",
  },
  {
    label: "SAINT-BIEZ-EN-BELIN",
    value: "72268",
    type: "common",
  },
  {
    label: "CHATEAU-L'HERMITAGE",
    value: "72072",
    type: "common",
  },
  {
    label: "YVRE-LE-POLIN",
    value: "72385",
    type: "common",
  },
  {
    label: "REQUEIL",
    value: "72252",
    type: "common",
  },
  {
    label: "OIZE",
    value: "72226",
    type: "common",
  },
  {
    label: "LA FONTAINE-SAINT-MARTIN",
    value: "72135",
    type: "common",
  },
  {
    label: "MEZERAY",
    value: "72195",
    type: "common",
  },
  {
    label: "MALICORNE-SUR-SARTHE",
    value: "72179",
    type: "common",
  },
  {
    label: "COURCELLES-LA-FORET",
    value: "72100",
    type: "common",
  },
  {
    label: "DUREIL",
    value: "72123",
    type: "common",
  },
  {
    label: "ARTHEZE",
    value: "72009",
    type: "common",
  },
  {
    label: "PARCE-SUR-SARTHE",
    value: "72228",
    type: "common",
  },
  {
    label: "LOUAILLES",
    value: "72167",
    type: "common",
  },
  {
    label: "VION",
    value: "72378",
    type: "common",
  },
  {
    label: "COURTILLERS",
    value: "72106",
    type: "common",
  },
  {
    label: "PINCE",
    value: "72236",
    type: "common",
  },
  {
    label: "SAINT-DENIS-D'ANJOU",
    value: "53210",
    type: "common",
  },
  {
    label: "CHATELAIN",
    value: "53063",
    type: "common",
  },
  {
    label: "COUDRAY",
    value: "53078",
    type: "common",
  },
  {
    label: "CHEMAZE",
    value: "53066",
    type: "common",
  },
  {
    label: "SAINT-QUENTIN-LES-ANGES",
    value: "53251",
    type: "common",
  },
  {
    label: "MEE",
    value: "53148",
    type: "common",
  },
  {
    label: "CHERANCE",
    value: "53068",
    type: "common",
  },
  {
    label: "RENAZE",
    value: "53188",
    type: "common",
  },
  {
    label: "BOUCHAMPS-LES-CRAON",
    value: "53035",
    type: "common",
  },
  {
    label: "LA BOISSIERE",
    value: "53033",
    type: "common",
  },
  {
    label: "SAINT-ERBLON",
    value: "53214",
    type: "common",
  },
  {
    label: "OMBREE D'ANJOU",
    value: "49248",
    type: "common",
  },
  {
    label: "VILLEPOT",
    value: "44218",
    type: "common",
  },
  {
    label: "NOYAL-SUR-BRUTZ",
    value: "44112",
    type: "common",
  },
  {
    label: "FERCE",
    value: "44058",
    type: "common",
  },
  {
    label: "ROUGE",
    value: "44146",
    type: "common",
  },
  {
    label: "RUFFIGNE",
    value: "44148",
    type: "common",
  },
  {
    label: "SAINT-SULPICE-DES-LANDES",
    value: "35316",
    type: "common",
  },
  {
    label: "LA DOMINELAIS",
    value: "35098",
    type: "common",
  },
  {
    label: "SAINTE-ANNE-SUR-VILAINE",
    value: "35249",
    type: "common",
  },
  {
    label: "SAINT-GANTON",
    value: "35268",
    type: "common",
  },
  {
    label: "LANGON",
    value: "35145",
    type: "common",
  },
  {
    label: "SAINT-JUST",
    value: "35285",
    type: "common",
  },
  {
    label: "RENAC",
    value: "35237",
    type: "common",
  },
  {
    label: "BAINS-SUR-OUST",
    value: "35013",
    type: "common",
  },
  {
    label: "COURNON",
    value: "56044",
    type: "common",
  },
  {
    label: "SAINT-MARTIN-SUR-OUST",
    value: "56229",
    type: "common",
  },
  {
    label: "PEILLAC",
    value: "56154",
    type: "common",
  },
  {
    label: "LES FOUGERETS",
    value: "56060",
    type: "common",
  },
  {
    label: "SAINT-GRAVE",
    value: "56218",
    type: "common",
  },
  {
    label: "PLUHERLIN",
    value: "56171",
    type: "common",
  },
  {
    label: "MOLAC",
    value: "56135",
    type: "common",
  },
  {
    label: "LARRE",
    value: "56108",
    type: "common",
  },
  {
    label: "LE COURS",
    value: "56045",
    type: "common",
  },
  {
    label: "ELVEN",
    value: "56053",
    type: "common",
  },
  {
    label: "SAINT-NOLFF",
    value: "56231",
    type: "common",
  },
  {
    label: "MONTERBLANC",
    value: "56137",
    type: "common",
  },
  {
    label: "SAINT-AVE",
    value: "56206",
    type: "common",
  },
  {
    label: "MEUCON",
    value: "56132",
    type: "common",
  },
  {
    label: "PLESCOP",
    value: "56158",
    type: "common",
  },
  {
    label: "SAINTE-ANNE-D'AURAY",
    value: "56263",
    type: "common",
  },
  {
    label: "PLUNERET",
    value: "56176",
    type: "common",
  },
  {
    label: "PLUMERGAT",
    value: "56175",
    type: "common",
  },
  {
    label: "BRECH",
    value: "56023",
    type: "common",
  },
  {
    label: "LOCOAL-MENDON",
    value: "56119",
    type: "common",
  },
  {
    label: "SAINTE-HELENE",
    value: "56220",
    type: "common",
  },
  {
    label: "BELZ",
    value: "56013",
    type: "common",
  },
  {
    label: "RIANTEC",
    value: "56193",
    type: "common",
  },
  {
    label: "PLOUHINEC",
    value: "56169",
    type: "common",
  },
  {
    label: "PORT-LOUIS",
    value: "56181",
    type: "common",
  },
  {
    label: "LARMOR-PLAGE",
    value: "56107",
    type: "common",
  },
  {
    label: "GAVRES",
    value: "56062",
    type: "common",
  },
  {
    label: "ROSENAU",
    value: "68286",
    type: "common",
  },
  {
    label: "UFFHEIM",
    value: "68341",
    type: "common",
  },
  {
    label: "SIERENTZ",
    value: "68309",
    type: "common",
  },
  {
    label: "SCHLIERBACH",
    value: "68301",
    type: "common",
  },
  {
    label: "KEMBS",
    value: "68163",
    type: "common",
  },
  {
    label: "BARTENHEIM",
    value: "68021",
    type: "common",
  },
  {
    label: "WALTENHEIM",
    value: "68357",
    type: "common",
  },
  {
    label: "STEINBRUNN-LE-HAUT",
    value: "68324",
    type: "common",
  },
  {
    label: "STEINBRUNN-LE-BAS",
    value: "68323",
    type: "common",
  },
  {
    label: "RANTZWILLER",
    value: "68265",
    type: "common",
  },
  {
    label: "MAGSTATT-LE-BAS",
    value: "68197",
    type: "common",
  },
  {
    label: "LANDSER",
    value: "68174",
    type: "common",
  },
  {
    label: "KŒTZINGUE",
    value: "68170",
    type: "common",
  },
  {
    label: "GEISPITZEN",
    value: "68103",
    type: "common",
  },
  {
    label: "ZILLISHEIM",
    value: "68384",
    type: "common",
  },
  {
    label: "WALHEIM",
    value: "68356",
    type: "common",
  },
  {
    label: "TAGOLSHEIM",
    value: "68332",
    type: "common",
  },
  {
    label: "OBERMORSCHWILLER",
    value: "68245",
    type: "common",
  },
  {
    label: "LUEMSCHWILLER",
    value: "68191",
    type: "common",
  },
  {
    label: "ILLFURTH",
    value: "68152",
    type: "common",
  },
  {
    label: "FRŒNINGEN",
    value: "68099",
    type: "common",
  },
  {
    label: "FLAXLANDEN",
    value: "68093",
    type: "common",
  },
  {
    label: "SPECHBACH",
    value: "68320",
    type: "common",
  },
  {
    label: "HEIDWILLER",
    value: "68127",
    type: "common",
  },
  {
    label: "HAGENBACH",
    value: "68119",
    type: "common",
  },
  {
    label: "SAINT-BERNARD",
    value: "68081",
    type: "common",
  },
  {
    label: "EGLINGEN",
    value: "68077",
    type: "common",
  },
  {
    label: "BALSCHWILLER",
    value: "68018",
    type: "common",
  },
  {
    label: "ASPACH",
    value: "68010",
    type: "common",
  },
  {
    label: "BERNWILLER",
    value: "68006",
    type: "common",
  },
  {
    label: "TRAUBACH-LE-HAUT",
    value: "68337",
    type: "common",
  },
  {
    label: "TRAUBACH-LE-BAS",
    value: "68336",
    type: "common",
  },
  {
    label: "STERNENBERG",
    value: "68326",
    type: "common",
  },
  {
    label: "HECKEN",
    value: "68125",
    type: "common",
  },
  {
    label: "GUEVENATTEN",
    value: "68114",
    type: "common",
  },
  {
    label: "GOMMERSDORF",
    value: "68107",
    type: "common",
  },
  {
    label: "GILDWILLER",
    value: "68105",
    type: "common",
  },
  {
    label: "FALKWILLER",
    value: "68086",
    type: "common",
  },
  {
    label: "BUETHWILLER",
    value: "68057",
    type: "common",
  },
  {
    label: "VAUTHIERMONT",
    value: "90100",
    type: "common",
  },
  {
    label: "REPPE",
    value: "90084",
    type: "common",
  },
  {
    label: "LARIVIERE",
    value: "90062",
    type: "common",
  },
  {
    label: "FONTAINE",
    value: "90047",
    type: "common",
  },
  {
    label: "ANGEOT",
    value: "90002",
    type: "common",
  },
  {
    label: "SAINT-COSME",
    value: "68293",
    type: "common",
  },
  {
    label: "ETEIMBES",
    value: "68085",
    type: "common",
  },
  {
    label: "BRECHAUMONT",
    value: "68050",
    type: "common",
  },
  {
    label: "BELLEMAGNY",
    value: "68024",
    type: "common",
  },
  {
    label: "SAINT-GERMAIN-LE-CHATELET",
    value: "90091",
    type: "common",
  },
  {
    label: "ROPPE",
    value: "90087",
    type: "common",
  },
  {
    label: "PHAFFANS",
    value: "90080",
    type: "common",
  },
  {
    label: "MENONCOURT",
    value: "90067",
    type: "common",
  },
  {
    label: "LAGRANGE",
    value: "90060",
    type: "common",
  },
  {
    label: "LACOLLONGE",
    value: "90059",
    type: "common",
  },
  {
    label: "FELON",
    value: "90044",
    type: "common",
  },
  {
    label: "EGUENIGUE",
    value: "90036",
    type: "common",
  },
  {
    label: "DENNEY",
    value: "90034",
    type: "common",
  },
  {
    label: "BOURG-SOUS-CHATELET",
    value: "90016",
    type: "common",
  },
  {
    label: "BETHONVILLIERS",
    value: "90013",
    type: "common",
  },
  {
    label: "ANJOUTEY",
    value: "90003",
    type: "common",
  },
  {
    label: "VETRIGNE",
    value: "90103",
    type: "common",
  },
  {
    label: "VALDOIE",
    value: "90099",
    type: "common",
  },
  {
    label: "SERMAMAGNY",
    value: "90093",
    type: "common",
  },
  {
    label: "OFFEMONT",
    value: "90075",
    type: "common",
  },
  {
    label: "ELOIE",
    value: "90037",
    type: "common",
  },
  {
    label: "LACHAPELLE-SOUS-CHAUX",
    value: "90057",
    type: "common",
  },
  {
    label: "EVETTE-SALBERT",
    value: "90042",
    type: "common",
  },
  {
    label: "FRAHIER-ET-CHATEBIER",
    value: "70248",
    type: "common",
  },
  {
    label: "ERREVET",
    value: "70215",
    type: "common",
  },
  {
    label: "CHAMPAGNEY",
    value: "70120",
    type: "common",
  },
  {
    label: "RONCHAMP",
    value: "70451",
    type: "common",
  },
  {
    label: "PALANTE",
    value: "70403",
    type: "common",
  },
  {
    label: "LA NEUVELLE-LES-LURE",
    value: "70385",
    type: "common",
  },
  {
    label: "MALBOUHANS",
    value: "70328",
    type: "common",
  },
  {
    label: "MAGNY-DANIGON",
    value: "70318",
    type: "common",
  },
  {
    label: "LA COTE",
    value: "70178",
    type: "common",
  },
  {
    label: "ROYE",
    value: "70455",
    type: "common",
  },
  {
    label: "MAGNY-VERNOIS",
    value: "70321",
    type: "common",
  },
  {
    label: "LURE",
    value: "70310",
    type: "common",
  },
  {
    label: "FROIDETERRE",
    value: "70259",
    type: "common",
  },
  {
    label: "ADELANS-ET-LE-VAL-DE-BITHAINE",
    value: "70004",
    type: "common",
  },
  {
    label: "GENEVREUILLE",
    value: "70262",
    type: "common",
  },
  {
    label: "BOUHANS-LES-LURE",
    value: "70081",
    type: "common",
  },
  {
    label: "AMBLANS-ET-VELOTTE",
    value: "70014",
    type: "common",
  },
  {
    label: "SERVIGNEY",
    value: "70490",
    type: "common",
  },
  {
    label: "GENEVREY",
    value: "70263",
    type: "common",
  },
  {
    label: "DAMBENOIT-LES-COLOMBE",
    value: "70195",
    type: "common",
  },
  {
    label: "CREVENEY",
    value: "70188",
    type: "common",
  },
  {
    label: "LA CREUSE",
    value: "70186",
    type: "common",
  },
  {
    label: "CHATENOIS",
    value: "70141",
    type: "common",
  },
  {
    label: "CHATENEY",
    value: "70140",
    type: "common",
  },
  {
    label: "VILORY",
    value: "70569",
    type: "common",
  },
  {
    label: "LA VILLENEUVE-BELLENOYE-ET-LA-MAIZE",
    value: "70558",
    type: "common",
  },
  {
    label: "VELLEFRIE",
    value: "70534",
    type: "common",
  },
  {
    label: "VAROGNE",
    value: "70522",
    type: "common",
  },
  {
    label: "SAULX",
    value: "70478",
    type: "common",
  },
  {
    label: "MAILLERONCOURT-CHARETTE",
    value: "70322",
    type: "common",
  },
  {
    label: "PROVENCHERE",
    value: "70426",
    type: "common",
  },
  {
    label: "FLAGY",
    value: "70235",
    type: "common",
  },
  {
    label: "AUXON",
    value: "70044",
    type: "common",
  },
  {
    label: "VILLERS-SUR-PORT",
    value: "70566",
    type: "common",
  },
  {
    label: "PORT-SUR-SAONE",
    value: "70421",
    type: "common",
  },
  {
    label: "CONFLANDEY",
    value: "70167",
    type: "common",
  },
  {
    label: "CHAUX-LES-PORT",
    value: "70146",
    type: "common",
  },
  {
    label: "BOUGNON",
    value: "70079",
    type: "common",
  },
  {
    label: "AMONCOURT",
    value: "70015",
    type: "common",
  },
  {
    label: "CHARGEY-LES-PORT",
    value: "70133",
    type: "common",
  },
  {
    label: "SEMMADON",
    value: "70486",
    type: "common",
  },
  {
    label: "LA NEUVELLE-LES-SCEY",
    value: "70386",
    type: "common",
  },
  {
    label: "COMBEAUFONTAINE",
    value: "70165",
    type: "common",
  },
  {
    label: "ARBECEY",
    value: "70025",
    type: "common",
  },
  {
    label: "MELIN",
    value: "70337",
    type: "common",
  },
  {
    label: "MALVILLERS",
    value: "70329",
    type: "common",
  },
  {
    label: "LAVIGNEY",
    value: "70298",
    type: "common",
  },
  {
    label: "GOURGEON",
    value: "70272",
    type: "common",
  },
  {
    label: "CORNOT",
    value: "70175",
    type: "common",
  },
  {
    label: "FARINCOURT",
    value: "52195",
    type: "common",
  },
  {
    label: "LA ROCHE-MOREY",
    value: "70373",
    type: "common",
  },
  {
    label: "MOLAY",
    value: "70350",
    type: "common",
  },
  {
    label: "CHARMES-SAINT-VALBERT",
    value: "70135",
    type: "common",
  },
  {
    label: "BOURGUIGNON-LES-MOREY",
    value: "70089",
    type: "common",
  },
  {
    label: "POINSON-LES-FAYL",
    value: "52394",
    type: "common",
  },
  {
    label: "VONCOURT",
    value: "52546",
    type: "common",
  },
  {
    label: "VALLEROY",
    value: "52503",
    type: "common",
  },
  {
    label: "SAVIGNY",
    value: "52467",
    type: "common",
  },
  {
    label: "PRESSIGNY",
    value: "52406",
    type: "common",
  },
  {
    label: "GENEVRIERES",
    value: "52213",
    type: "common",
  },
  {
    label: "SAULLES",
    value: "52464",
    type: "common",
  },
  {
    label: "BELMONT",
    value: "52043",
    type: "common",
  },
  {
    label: "GRENANT",
    value: "52229",
    type: "common",
  },
  {
    label: "RIVIERES-LE-BOIS",
    value: "52424",
    type: "common",
  },
  {
    label: "GRANDCHAMP",
    value: "52228",
    type: "common",
  },
  {
    label: "MAATZ",
    value: "52298",
    type: "common",
  },
  {
    label: "SAINT-BROINGT-LE-BOIS",
    value: "52445",
    type: "common",
  },
  {
    label: "HEUILLEY-LE-GRAND",
    value: "52240",
    type: "common",
  },
  {
    label: "CHASSIGNY",
    value: "52113",
    type: "common",
  },
  {
    label: "LONGEAU-PERCEY",
    value: "52292",
    type: "common",
  },
  {
    label: "VERSEILLES-LE-BAS",
    value: "52515",
    type: "common",
  },
  {
    label: "VILLEGUSIEN-LE-LAC",
    value: "52529",
    type: "common",
  },
  {
    label: "BAISSEY",
    value: "52035",
    type: "common",
  },
  {
    label: "SAINT-BROINGT-LES-FOSSES",
    value: "52446",
    type: "common",
  },
  {
    label: "VILLIERS-LES-APREY",
    value: "52536",
    type: "common",
  },
  {
    label: "AUJEURRES",
    value: "52027",
    type: "common",
  },
  {
    label: "LEUCHEY",
    value: "52285",
    type: "common",
  },
  {
    label: "VAILLANT",
    value: "52499",
    type: "common",
  },
  {
    label: "PRASLAY",
    value: "52403",
    type: "common",
  },
  {
    label: "AUBERIVE",
    value: "52023",
    type: "common",
  },
  {
    label: "POINSENOT",
    value: "52393",
    type: "common",
  },
  {
    label: "VIVEY",
    value: "52542",
    type: "common",
  },
  {
    label: "VILLARS-SANTENOGE",
    value: "52526",
    type: "common",
  },
  {
    label: "CHAUGEY",
    value: "21157",
    type: "common",
  },
  {
    label: "BURE-LES-TEMPLIERS",
    value: "21116",
    type: "common",
  },
  {
    label: "TERREFONDREE",
    value: "21626",
    type: "common",
  },
  {
    label: "MONTMOYEN",
    value: "21438",
    type: "common",
  },
  {
    label: "ESSAROIS",
    value: "21250",
    type: "common",
  },
  {
    label: "BEAULIEU",
    value: "21052",
    type: "common",
  },
  {
    label: "ROCHEFORT-SUR-BREVON",
    value: "21526",
    type: "common",
  },
  {
    label: "SAINT-GERMAIN-LE-ROCHEUX",
    value: "21549",
    type: "common",
  },
  {
    label: "BUSSEAUT",
    value: "21117",
    type: "common",
  },
  {
    label: "NOD-SUR-SEINE",
    value: "21455",
    type: "common",
  },
  {
    label: "CHEMIN-D'AISEY",
    value: "21165",
    type: "common",
  },
  {
    label: "BREMUR-ET-VAUROIS",
    value: "21104",
    type: "common",
  },
  {
    label: "AISEY-SUR-SEINE",
    value: "21006",
    type: "common",
  },
  {
    label: "PUITS",
    value: "21511",
    type: "common",
  },
  {
    label: "COULMIER-LE-SEC",
    value: "21201",
    type: "common",
  },
  {
    label: "PLANAY",
    value: "21484",
    type: "common",
  },
  {
    label: "NESLE-ET-MASSOULT",
    value: "21451",
    type: "common",
  },
  {
    label: "JULLY",
    value: "89210",
    type: "common",
  },
  {
    label: "VERDONNET",
    value: "21664",
    type: "common",
  },
  {
    label: "STIGNY",
    value: "89403",
    type: "common",
  },
  {
    label: "RAVIERES",
    value: "89321",
    type: "common",
  },
  {
    label: "FULVY",
    value: "89184",
    type: "common",
  },
  {
    label: "CHASSIGNELLES",
    value: "89087",
    type: "common",
  },
  {
    label: "ANCY-LE-FRANC",
    value: "89005",
    type: "common",
  },
  {
    label: "PACY-SUR-ARMANCON",
    value: "89284",
    type: "common",
  },
  {
    label: "MOULINS-EN-TONNERROIS",
    value: "89271",
    type: "common",
  },
  {
    label: "ARGENTEUIL-SUR-ARMANCON",
    value: "89017",
    type: "common",
  },
  {
    label: "SAMBOURG",
    value: "89374",
    type: "common",
  },
  {
    label: "MOLAY",
    value: "89259",
    type: "common",
  },
  {
    label: "FRESNES",
    value: "89183",
    type: "common",
  },
  {
    label: "SAINTE-VERTU",
    value: "89371",
    type: "common",
  },
  {
    label: "POILLY-SUR-SEREIN",
    value: "89303",
    type: "common",
  },
  {
    label: "CHEMILLY-SUR-SEREIN",
    value: "89095",
    type: "common",
  },
  {
    label: "PREHY",
    value: "89315",
    type: "common",
  },
  {
    label: "CHICHEE",
    value: "89104",
    type: "common",
  },
  {
    label: "COURGIS",
    value: "89123",
    type: "common",
  },
  {
    label: "CHITRY",
    value: "89108",
    type: "common",
  },
  {
    label: "QUENNE",
    value: "89319",
    type: "common",
  },
  {
    label: "CHAMPS-SUR-YONNE",
    value: "89077",
    type: "common",
  },
  {
    label: "AUGY",
    value: "89023",
    type: "common",
  },
  {
    label: "VALLAN",
    value: "89427",
    type: "common",
  },
  {
    label: "AUXERRE",
    value: "89024",
    type: "common",
  },
  {
    label: "CHEVANNES",
    value: "89102",
    type: "common",
  },
  {
    label: "VILLEFARGEAU",
    value: "89453",
    type: "common",
  },
  {
    label: "POURRAIN",
    value: "89311",
    type: "common",
  },
  {
    label: "PARLY",
    value: "89286",
    type: "common",
  },
  {
    label: "BEAUVOIR",
    value: "89033",
    type: "common",
  },
  {
    label: "VILLIERS-SAINT-BENOIT",
    value: "89472",
    type: "common",
  },
  {
    label: "MERRY-LA-VALLEE",
    value: "89251",
    type: "common",
  },
  {
    label: "DRACY",
    value: "89147",
    type: "common",
  },
  {
    label: "VILLENEUVE-LES-GENETS",
    value: "89462",
    type: "common",
  },
  {
    label: "CHAMPIGNELLES",
    value: "89073",
    type: "common",
  },
  {
    label: "CHAMPCEVRAIS",
    value: "89072",
    type: "common",
  },
  {
    label: "LE CHARME",
    value: "45079",
    type: "common",
  },
  {
    label: "FEINS-EN-GATINAIS",
    value: "45143",
    type: "common",
  },
  {
    label: "DAMMARIE-SUR-LOING",
    value: "45121",
    type: "common",
  },
  {
    label: "BOISMORAND",
    value: "45036",
    type: "common",
  },
  {
    label: "ADON",
    value: "45001",
    type: "common",
  },
  {
    label: "LES CHOUX",
    value: "45096",
    type: "common",
  },
  {
    label: "DAMPIERRE-EN-BURLY",
    value: "45122",
    type: "common",
  },
  {
    label: "OUZOUER-SUR-LOIRE",
    value: "45244",
    type: "common",
  },
  {
    label: "SULLY-SUR-LOIRE",
    value: "45315",
    type: "common",
  },
  {
    label: "SAINT-PERE-SUR-LOIRE",
    value: "45297",
    type: "common",
  },
  {
    label: "BONNEE",
    value: "45039",
    type: "common",
  },
  {
    label: "TIGY",
    value: "45324",
    type: "common",
  },
  {
    label: "NEUVY-EN-SULLIAS",
    value: "45226",
    type: "common",
  },
  {
    label: "VIENNE-EN-VAL",
    value: "45335",
    type: "common",
  },
  {
    label: "MARCILLY-EN-VILLETTE",
    value: "45193",
    type: "common",
  },
  {
    label: "ARDON",
    value: "45006",
    type: "common",
  },
  {
    label: "JOUY-LE-POTIER",
    value: "45175",
    type: "common",
  },
  {
    label: "LAILLY-EN-VAL",
    value: "45179",
    type: "common",
  },
  {
    label: "DRY",
    value: "45130",
    type: "common",
  },
  {
    label: "TAVERS",
    value: "45317",
    type: "common",
  },
  {
    label: "BEAUGENCY",
    value: "45028",
    type: "common",
  },
  {
    label: "SERIS",
    value: "41245",
    type: "common",
  },
  {
    label: "LESTIOU",
    value: "41114",
    type: "common",
  },
  {
    label: "JOSNES",
    value: "41105",
    type: "common",
  },
  {
    label: "VILLEXANTON",
    value: "41292",
    type: "common",
  },
  {
    label: "TALCY",
    value: "41253",
    type: "common",
  },
  {
    label: "ROCHES",
    value: "41191",
    type: "common",
  },
  {
    label: "CONCRIERS",
    value: "41058",
    type: "common",
  },
  {
    label: "VILLENEUVE-FROUVILLE",
    value: "41284",
    type: "common",
  },
  {
    label: "LA MADELEINE-VILLEFROUIN",
    value: "41121",
    type: "common",
  },
  {
    label: "BOISSEAU",
    value: "41019",
    type: "common",
  },
  {
    label: "RHODON",
    value: "41188",
    type: "common",
  },
  {
    label: "CONAN",
    value: "41057",
    type: "common",
  },
  {
    label: "VILLETRUN",
    value: "41291",
    type: "common",
  },
  {
    label: "SELOMMES",
    value: "41243",
    type: "common",
  },
  {
    label: "PERIGNY",
    value: "41174",
    type: "common",
  },
  {
    label: "COULOMMIERS-LA-TOUR",
    value: "41065",
    type: "common",
  },
  {
    label: "SAINTE-ANNE",
    value: "41200",
    type: "common",
  },
  {
    label: "CRUCHERAY",
    value: "41072",
    type: "common",
  },
  {
    label: "VILLIERSFAUX",
    value: "41293",
    type: "common",
  },
  {
    label: "VILLERABLE",
    value: "41287",
    type: "common",
  },
  {
    label: "THORE-LA-ROCHETTE",
    value: "41259",
    type: "common",
  },
  {
    label: "NAVEIL",
    value: "41158",
    type: "common",
  },
  {
    label: "MARCILLY-EN-BEAUCE",
    value: "41124",
    type: "common",
  },
  {
    label: "VILLAVARD",
    value: "41274",
    type: "common",
  },
  {
    label: "SAINT-RIMAY",
    value: "41228",
    type: "common",
  },
  {
    label: "LES ROCHES-L'EVEQUE",
    value: "41192",
    type: "common",
  },
  {
    label: "LAVARDIN",
    value: "41113",
    type: "common",
  },
  {
    label: "HOUSSAY",
    value: "41102",
    type: "common",
  },
  {
    label: "SAINT-JACQUES-DES-GUERETS",
    value: "41215",
    type: "common",
  },
  {
    label: "MONTOIRE-SUR-LE-LOIR",
    value: "41149",
    type: "common",
  },
  {
    label: "TROO",
    value: "41265",
    type: "common",
  },
  {
    label: "TERNAY",
    value: "41255",
    type: "common",
  },
  {
    label: "SOUGE",
    value: "41250",
    type: "common",
  },
  {
    label: "LES ESSARTS",
    value: "41079",
    type: "common",
  },
  {
    label: "ARTINS",
    value: "41004",
    type: "common",
  },
  {
    label: "LHOMME",
    value: "72161",
    type: "common",
  },
  {
    label: "LA CHARTRE-SUR-LE-LOIR",
    value: "72068",
    type: "common",
  },
  {
    label: "THOIRE-SUR-DINAN",
    value: "72356",
    type: "common",
  },
  {
    label: "FLEE",
    value: "72134",
    type: "common",
  },
  {
    label: "CHAHAIGNES",
    value: "72052",
    type: "common",
  },
  {
    label: "BEAUMONT-PIED-DE-BŒUF",
    value: "72028",
    type: "common",
  },
  {
    label: "MAYET",
    value: "72191",
    type: "common",
  },
  {
    label: "LAVERNAT",
    value: "72160",
    type: "common",
  },
  {
    label: "SARCE",
    value: "72327",
    type: "common",
  },
  {
    label: "PONTVALLAIN",
    value: "72243",
    type: "common",
  },
  {
    label: "MANSIGNE",
    value: "72182",
    type: "common",
  },
  {
    label: "SAINT-JEAN-DE-LA-MOTTE",
    value: "72291",
    type: "common",
  },
  {
    label: "LIGRON",
    value: "72163",
    type: "common",
  },
  {
    label: "CLERMONT-CREANS",
    value: "72084",
    type: "common",
  },
  {
    label: "BOUSSE",
    value: "72044",
    type: "common",
  },
  {
    label: "VILLAINES-SOUS-MALICORNE",
    value: "72377",
    type: "common",
  },
  {
    label: "CROSMIERES",
    value: "72110",
    type: "common",
  },
  {
    label: "LA CHAPELLE-D'ALIGNE",
    value: "72061",
    type: "common",
  },
  {
    label: "LE BAILLEUL",
    value: "72022",
    type: "common",
  },
  {
    label: "PRECIGNE",
    value: "72244",
    type: "common",
  },
  {
    label: "NOTRE-DAME-DU-PE",
    value: "72232",
    type: "common",
  },
  {
    label: "MORANNES SUR SARTHE-DAUMERAY",
    value: "49220",
    type: "common",
  },
  {
    label: "MIRE",
    value: "49205",
    type: "common",
  },
  {
    label: "MENIL",
    value: "53150",
    type: "common",
  },
  {
    label: "DAON",
    value: "53089",
    type: "common",
  },
  {
    label: "LA JAILLE-YVON",
    value: "49161",
    type: "common",
  },
  {
    label: "SEGRE-EN-ANJOU BLEU",
    value: "49331",
    type: "common",
  },
  {
    label: "BOURG-L'EVEQUE",
    value: "49038",
    type: "common",
  },
  {
    label: "BOUILLE-MENARD",
    value: "49036",
    type: "common",
  },
  {
    label: "ARMAILLE",
    value: "49010",
    type: "common",
  },
  {
    label: "JUIGNE-DES-MOUTIERS",
    value: "44078",
    type: "common",
  },
  {
    label: "CARBAY",
    value: "49056",
    type: "common",
  },
  {
    label: "SOUDAN",
    value: "44199",
    type: "common",
  },
  {
    label: "CHATEAUBRIANT",
    value: "44036",
    type: "common",
  },
  {
    label: "SAINT-AUBIN-DES-CHATEAUX",
    value: "44153",
    type: "common",
  },
  {
    label: "SION-LES-MINES",
    value: "44197",
    type: "common",
  },
  {
    label: "LUSANGER",
    value: "44086",
    type: "common",
  },
  {
    label: "MOUAIS",
    value: "44105",
    type: "common",
  },
  {
    label: "GRAND-FOUGERAY",
    value: "35124",
    type: "common",
  },
  {
    label: "PIERRIC",
    value: "44123",
    type: "common",
  },
  {
    label: "MASSERAC",
    value: "44092",
    type: "common",
  },
  {
    label: "LA CHAPELLE-DE-BRAIN",
    value: "35064",
    type: "common",
  },
  {
    label: "SAINTE-MARIE",
    value: "35294",
    type: "common",
  },
  {
    label: "REDON",
    value: "35236",
    type: "common",
  },
  {
    label: "SAINT-VINCENT-SUR-OUST",
    value: "56239",
    type: "common",
  },
  {
    label: "SAINT-PERREUX",
    value: "56232",
    type: "common",
  },
  {
    label: "SAINT-JACUT-LES-PINS",
    value: "56221",
    type: "common",
  },
  {
    label: "ROCHEFORT-EN-TERRE",
    value: "56196",
    type: "common",
  },
  {
    label: "MALANSAC",
    value: "56123",
    type: "common",
  },
  {
    label: "QUESTEMBERT",
    value: "56184",
    type: "common",
  },
  {
    label: "LA VRAIE-CROIX",
    value: "56261",
    type: "common",
  },
  {
    label: "TREFFLEAN",
    value: "56255",
    type: "common",
  },
  {
    label: "SULNIAC",
    value: "56247",
    type: "common",
  },
  {
    label: "BERRIC",
    value: "56015",
    type: "common",
  },
  {
    label: "THEIX-NOYALO",
    value: "56251",
    type: "common",
  },
  {
    label: "VANNES",
    value: "56260",
    type: "common",
  },
  {
    label: "PLOEREN",
    value: "56164",
    type: "common",
  },
  {
    label: "ARRADON",
    value: "56003",
    type: "common",
  },
  {
    label: "BONO",
    value: "56262",
    type: "common",
  },
  {
    label: "PLOUGOUMELEN",
    value: "56167",
    type: "common",
  },
  {
    label: "CRACH",
    value: "56046",
    type: "common",
  },
  {
    label: "AURAY",
    value: "56007",
    type: "common",
  },
  {
    label: "PLOEMEL",
    value: "56161",
    type: "common",
  },
  {
    label: "ETEL",
    value: "56055",
    type: "common",
  },
  {
    label: "ERDEVEN",
    value: "56054",
    type: "common",
  },
  {
    label: "GROIX",
    value: "56069",
    type: "common",
  },
  {
    label: "VILLAGE-NEUF",
    value: "68349",
    type: "common",
  },
  {
    label: "SAINT-LOUIS",
    value: "68297",
    type: "common",
  },
  {
    label: "HUNINGUE",
    value: "68149",
    type: "common",
  },
  {
    label: "STETTEN",
    value: "68327",
    type: "common",
  },
  {
    label: "RANSPACH-LE-HAUT",
    value: "68264",
    type: "common",
  },
  {
    label: "RANSPACH-LE-BAS",
    value: "68263",
    type: "common",
  },
  {
    label: "MICHELBACH-LE-BAS",
    value: "68207",
    type: "common",
  },
  {
    label: "KAPPELEN",
    value: "68160",
    type: "common",
  },
  {
    label: "HESINGUE",
    value: "68135",
    type: "common",
  },
  {
    label: "BRINCKHEIM",
    value: "68054",
    type: "common",
  },
  {
    label: "BLOTZHEIM",
    value: "68042",
    type: "common",
  },
  {
    label: "ZAESSINGUE",
    value: "68382",
    type: "common",
  },
  {
    label: "WAHLBACH",
    value: "68353",
    type: "common",
  },
  {
    label: "MAGSTATT-LE-HAUT",
    value: "68198",
    type: "common",
  },
  {
    label: "JETTINGEN",
    value: "68158",
    type: "common",
  },
  {
    label: "HUNDSBACH",
    value: "68148",
    type: "common",
  },
  {
    label: "HELFRANTZKIRCH",
    value: "68132",
    type: "common",
  },
  {
    label: "FRANKEN",
    value: "68096",
    type: "common",
  },
  {
    label: "BERENTZWILLER",
    value: "68027",
    type: "common",
  },
  {
    label: "WITTERSDORF",
    value: "68377",
    type: "common",
  },
  {
    label: "WILLER",
    value: "68371",
    type: "common",
  },
  {
    label: "TAGSDORF",
    value: "68333",
    type: "common",
  },
  {
    label: "SCHWOBEN",
    value: "68303",
    type: "common",
  },
  {
    label: "HIRSINGUE",
    value: "68138",
    type: "common",
  },
  {
    label: "HEIWILLER",
    value: "68131",
    type: "common",
  },
  {
    label: "HAUSGAUEN",
    value: "68124",
    type: "common",
  },
  {
    label: "EMLINGEN",
    value: "68080",
    type: "common",
  },
  {
    label: "BETTENDORF",
    value: "68033",
    type: "common",
  },
  {
    label: "ALTKIRCH",
    value: "68004",
    type: "common",
  },
  {
    label: "HIRTZBACH",
    value: "68139",
    type: "common",
  },
  {
    label: "FULLEREN",
    value: "68100",
    type: "common",
  },
  {
    label: "CARSPACH",
    value: "68062",
    type: "common",
  },
  {
    label: "WOLFERSDORF",
    value: "68378",
    type: "common",
  },
  {
    label: "SAINT-ULRICH",
    value: "68299",
    type: "common",
  },
  {
    label: "RETZWILLER",
    value: "68268",
    type: "common",
  },
  {
    label: "MERTZEN",
    value: "68202",
    type: "common",
  },
  {
    label: "MANSPACH",
    value: "68200",
    type: "common",
  },
  {
    label: "DANNEMARIE",
    value: "68068",
    type: "common",
  },
  {
    label: "BALLERSDORF",
    value: "68017",
    type: "common",
  },
  {
    label: "ALTENACH",
    value: "68002",
    type: "common",
  },
  {
    label: "MONTREUX-CHATEAU",
    value: "90071",
    type: "common",
  },
  {
    label: "FOUSSEMAGNE",
    value: "90049",
    type: "common",
  },
  {
    label: "CUNELIERES",
    value: "90031",
    type: "common",
  },
  {
    label: "CHAVANNES-LES-GRANDS",
    value: "90025",
    type: "common",
  },
  {
    label: "BRETAGNE",
    value: "90019",
    type: "common",
  },
  {
    label: "ROMAGNY",
    value: "68282",
    type: "common",
  },
  {
    label: "MONTREUX-VIEUX",
    value: "68215",
    type: "common",
  },
  {
    label: "MONTREUX-JEUNE",
    value: "68214",
    type: "common",
  },
  {
    label: "MAGNY",
    value: "68196",
    type: "common",
  },
  {
    label: "VALDIEU-LUTRAN",
    value: "68192",
    type: "common",
  },
  {
    label: "ELBACH",
    value: "68079",
    type: "common",
  },
  {
    label: "CHAVANNES-SUR-L'ETANG",
    value: "68065",
    type: "common",
  },
  {
    label: "VEZELOIS",
    value: "90104",
    type: "common",
  },
  {
    label: "AUTRECHENE",
    value: "90082",
    type: "common",
  },
  {
    label: "PETIT-CROIX",
    value: "90077",
    type: "common",
  },
  {
    label: "NOVILLARD",
    value: "90074",
    type: "common",
  },
  {
    label: "FRAIS",
    value: "90050",
    type: "common",
  },
  {
    label: "FONTENELLE",
    value: "90048",
    type: "common",
  },
  {
    label: "CHEVREMONT",
    value: "90026",
    type: "common",
  },
  {
    label: "BESSONCOURT",
    value: "90012",
    type: "common",
  },
  {
    label: "PEROUSE",
    value: "90076",
    type: "common",
  },
  {
    label: "ESSERT",
    value: "90039",
    type: "common",
  },
  {
    label: "DANJOUTIN",
    value: "90032",
    type: "common",
  },
  {
    label: "CRAVANCHE",
    value: "90029",
    type: "common",
  },
  {
    label: "BOTANS",
    value: "90015",
    type: "common",
  },
  {
    label: "BELFORT",
    value: "90010",
    type: "common",
  },
  {
    label: "BAVILLIERS",
    value: "90008",
    type: "common",
  },
  {
    label: "ARGIESANS",
    value: "90004",
    type: "common",
  },
  {
    label: "ANDELNANS",
    value: "90001",
    type: "common",
  },
  {
    label: "CHALONVILLARS",
    value: "70117",
    type: "common",
  },
  {
    label: "MANDREVILLARS",
    value: "70330",
    type: "common",
  },
  {
    label: "URCEREY",
    value: "90098",
    type: "common",
  },
  {
    label: "BUC",
    value: "90020",
    type: "common",
  },
  {
    label: "ECHENANS-SOUS-MONT-VAUDOIS",
    value: "70206",
    type: "common",
  },
  {
    label: "ECHAVANNE",
    value: "70205",
    type: "common",
  },
  {
    label: "CHAGEY",
    value: "70116",
    type: "common",
  },
  {
    label: "LUZE",
    value: "70312",
    type: "common",
  },
  {
    label: "ETOBON",
    value: "70221",
    type: "common",
  },
  {
    label: "COURMONT",
    value: "70182",
    type: "common",
  },
  {
    label: "CHENEBIER",
    value: "70149",
    type: "common",
  },
  {
    label: "BELVERNE",
    value: "70064",
    type: "common",
  },
  {
    label: "MAGNY-JOBERT",
    value: "70319",
    type: "common",
  },
  {
    label: "LYOFFANS",
    value: "70313",
    type: "common",
  },
  {
    label: "LOMONT",
    value: "70306",
    type: "common",
  },
  {
    label: "FREDERIC-FONTAINE",
    value: "70254",
    type: "common",
  },
  {
    label: "FAYMONT",
    value: "70229",
    type: "common",
  },
  {
    label: "CLAIREGOUTTE",
    value: "70157",
    type: "common",
  },
  {
    label: "ANDORNAY",
    value: "70021",
    type: "common",
  },
  {
    label: "VOUHENANS",
    value: "70577",
    type: "common",
  },
  {
    label: "LA VERGENNE",
    value: "70544",
    type: "common",
  },
  {
    label: "LE VAL-DE-GOUHENANS",
    value: "70515",
    type: "common",
  },
  {
    label: "MOFFANS-ET-VACHERESSE",
    value: "70348",
    type: "common",
  },
  {
    label: "FROTEY-LES-LURE",
    value: "70260",
    type: "common",
  },
  {
    label: "VY-LES-LURE",
    value: "70581",
    type: "common",
  },
  {
    label: "MOLLANS",
    value: "70351",
    type: "common",
  },
  {
    label: "LES AYNANS",
    value: "70046",
    type: "common",
  },
  {
    label: "ARPENANS",
    value: "70029",
    type: "common",
  },
  {
    label: "VELLEMINFROY",
    value: "70537",
    type: "common",
  },
  {
    label: "POMOY",
    value: "70416",
    type: "common",
  },
  {
    label: "MONTJUSTIN-ET-VELOTTE",
    value: "70364",
    type: "common",
  },
  {
    label: "LIEVANS",
    value: "70303",
    type: "common",
  },
  {
    label: "AUTREY-LES-CERRE",
    value: "70040",
    type: "common",
  },
  {
    label: "MONTCEY",
    value: "70358",
    type: "common",
  },
  {
    label: "DAMPVALLEY-LES-COLOMBE",
    value: "70199",
    type: "common",
  },
  {
    label: "COMBERJON",
    value: "70166",
    type: "common",
  },
  {
    label: "COLOMBOTTE",
    value: "70164",
    type: "common",
  },
  {
    label: "COLOMBIER",
    value: "70163",
    type: "common",
  },
  {
    label: "CALMOUTIER",
    value: "70111",
    type: "common",
  },
  {
    label: "VILLEPAROIS",
    value: "70559",
    type: "common",
  },
  {
    label: "VESOUL",
    value: "70550",
    type: "common",
  },
  {
    label: "PUSY-ET-EPENOUX",
    value: "70429",
    type: "common",
  },
  {
    label: "PUSEY",
    value: "70428",
    type: "common",
  },
  {
    label: "FROTEY-LES-VESOUL",
    value: "70261",
    type: "common",
  },
  {
    label: "COULEVON",
    value: "70179",
    type: "common",
  },
  {
    label: "VAUCHOUX",
    value: "70524",
    type: "common",
  },
  {
    label: "VAIVRE-ET-MONTOILLE",
    value: "70513",
    type: "common",
  },
  {
    label: "SCYE",
    value: "70483",
    type: "common",
  },
  {
    label: "PONTCEY",
    value: "70417",
    type: "common",
  },
  {
    label: "MONTIGNY-LES-VESOUL",
    value: "70363",
    type: "common",
  },
  {
    label: "GRATTERY",
    value: "70278",
    type: "common",
  },
  {
    label: "CHARMOILLE",
    value: "70136",
    type: "common",
  },
  {
    label: "SCEY-SUR-SAONE-ET-SAINT-ALBIN",
    value: "70482",
    type: "common",
  },
  {
    label: "OVANCHES",
    value: "70401",
    type: "common",
  },
  {
    label: "FERRIERES-LES-SCEY",
    value: "70232",
    type: "common",
  },
  {
    label: "CHEMILLY",
    value: "70148",
    type: "common",
  },
  {
    label: "CHASSEY-LES-SCEY",
    value: "70138",
    type: "common",
  },
  {
    label: "BUCEY-LES-TRAVES",
    value: "70105",
    type: "common",
  },
  {
    label: "VY-LES-RUPT",
    value: "70582",
    type: "common",
  },
  {
    label: "RUPT-SUR-SAONE",
    value: "70457",
    type: "common",
  },
  {
    label: "GRANDECOURT",
    value: "70274",
    type: "common",
  },
  {
    label: "CONFRACOURT",
    value: "70169",
    type: "common",
  },
  {
    label: "VAUCONCOURT-NERVEZAIN",
    value: "70525",
    type: "common",
  },
  {
    label: "RENAUCOURT",
    value: "70442",
    type: "common",
  },
  {
    label: "MONT-SAINT-LEGER",
    value: "70369",
    type: "common",
  },
  {
    label: "FLEUREY-LES-LAVONCOURT",
    value: "70237",
    type: "common",
  },
  {
    label: "VILLERS-VAUDEY",
    value: "70568",
    type: "common",
  },
  {
    label: "FRANCOURT",
    value: "70251",
    type: "common",
  },
  {
    label: "TORNAY",
    value: "52493",
    type: "common",
  },
  {
    label: "GILLEY",
    value: "52223",
    type: "common",
  },
  {
    label: "FOUVENT-SAINT-ANDOCHE",
    value: "70247",
    type: "common",
  },
  {
    label: "ARGILLIERES",
    value: "70027",
    type: "common",
  },
  {
    label: "PIERRECOURT",
    value: "70409",
    type: "common",
  },
  {
    label: "COUBLANC",
    value: "52145",
    type: "common",
  },
  {
    label: "CHOILLEY-DARDENAY",
    value: "52126",
    type: "common",
  },
  {
    label: "DOMMARIEN",
    value: "52170",
    type: "common",
  },
  {
    label: "LE MONTSAUGEONNAIS",
    value: "52405",
    type: "common",
  },
  {
    label: "RIVIERE-LES-FOSSES",
    value: "52425",
    type: "common",
  },
  {
    label: "LE VAL-D'ESNOMS",
    value: "52189",
    type: "common",
  },
  {
    label: "VESVRES-SOUS-CHALANCEY",
    value: "52519",
    type: "common",
  },
  {
    label: "VERNOIS-LES-VESVRES",
    value: "21665",
    type: "common",
  },
  {
    label: "MOUILLERON",
    value: "52344",
    type: "common",
  },
  {
    label: "VALS-DES-TILLES",
    value: "52094",
    type: "common",
  },
  {
    label: "CHALANCEY",
    value: "52092",
    type: "common",
  },
  {
    label: "GRANCEY-LE-CHATEAU-NEUVELLE",
    value: "21304",
    type: "common",
  },
  {
    label: "BUSSEROTTE-ET-MONTENAILLE",
    value: "21118",
    type: "common",
  },
  {
    label: "POINSON-LES-GRANCEY",
    value: "52395",
    type: "common",
  },
  {
    label: "BUSSIERES",
    value: "21119",
    type: "common",
  },
  {
    label: "BENEUVRE",
    value: "21063",
    type: "common",
  },
  {
    label: "SAINT-BROING-LES-MOINES",
    value: "21543",
    type: "common",
  },
  {
    label: "MOITRON",
    value: "21418",
    type: "common",
  },
  {
    label: "MINOT",
    value: "21415",
    type: "common",
  },
  {
    label: "BEAUNOTTE",
    value: "21055",
    type: "common",
  },
  {
    label: "AIGNAY-LE-DUC",
    value: "21004",
    type: "common",
  },
  {
    label: "MEULSON",
    value: "21410",
    type: "common",
  },
  {
    label: "MAUVILLY",
    value: "21396",
    type: "common",
  },
  {
    label: "BELLENOD-SUR-SEINE",
    value: "21061",
    type: "common",
  },
  {
    label: "SEMOND",
    value: "21602",
    type: "common",
  },
  {
    label: "SAINT-MARC-SUR-SEINE",
    value: "21557",
    type: "common",
  },
  {
    label: "ORIGNY",
    value: "21470",
    type: "common",
  },
  {
    label: "MAGNY-LAMBERT",
    value: "21364",
    type: "common",
  },
  {
    label: "VILLAINES-EN-DUESMOIS",
    value: "21685",
    type: "common",
  },
  {
    label: "SAVOISY",
    value: "21594",
    type: "common",
  },
  {
    label: "ETAIS",
    value: "21252",
    type: "common",
  },
  {
    label: "MONTBARD",
    value: "21425",
    type: "common",
  },
  {
    label: "ASNIERES-EN-MONTAGNE",
    value: "21026",
    type: "common",
  },
  {
    label: "ARRANS",
    value: "21025",
    type: "common",
  },
  {
    label: "PERRIGNY-SUR-ARMANCON",
    value: "89296",
    type: "common",
  },
  {
    label: "NUITS",
    value: "89280",
    type: "common",
  },
  {
    label: "CRY",
    value: "89132",
    type: "common",
  },
  {
    label: "ROUGEMONT",
    value: "21530",
    type: "common",
  },
  {
    label: "VILLIERS-LES-HAUTS",
    value: "89470",
    type: "common",
  },
  {
    label: "ETIVEY",
    value: "89161",
    type: "common",
  },
  {
    label: "PASILLY",
    value: "89290",
    type: "common",
  },
  {
    label: "JOUANCY",
    value: "89207",
    type: "common",
  },
  {
    label: "CENSY",
    value: "89064",
    type: "common",
  },
  {
    label: "NOYERS",
    value: "89279",
    type: "common",
  },
  {
    label: "ANNAY-SUR-SEREIN",
    value: "89010",
    type: "common",
  },
  {
    label: "NITRY",
    value: "89277",
    type: "common",
  },
  {
    label: "AIGREMONT",
    value: "89002",
    type: "common",
  },
  {
    label: "SAINT-CYR-LES-COLONS",
    value: "89341",
    type: "common",
  },
  {
    label: "LICHERES-PRES-AIGREMONT",
    value: "89224",
    type: "common",
  },
  {
    label: "VERMENTON",
    value: "89441",
    type: "common",
  },
  {
    label: "IRANCY",
    value: "89202",
    type: "common",
  },
  {
    label: "DEUX RIVIERES",
    value: "89130",
    type: "common",
  },
  {
    label: "VINCELOTTES",
    value: "89479",
    type: "common",
  },
  {
    label: "VINCELLES",
    value: "89478",
    type: "common",
  },
  {
    label: "SAINT-BRIS-LE-VINEUX",
    value: "89337",
    type: "common",
  },
  {
    label: "ESCOLIVES-SAINTE-CAMILLE",
    value: "89155",
    type: "common",
  },
  {
    label: "COULANGES-LA-VINEUSE",
    value: "89118",
    type: "common",
  },
  {
    label: "MIGE",
    value: "89256",
    type: "common",
  },
  {
    label: "JUSSY",
    value: "89212",
    type: "common",
  },
  {
    label: "GY-L'EVEQUE",
    value: "89199",
    type: "common",
  },
  {
    label: "ESCAMPS",
    value: "89154",
    type: "common",
  },
  {
    label: "COULANGERON",
    value: "89117",
    type: "common",
  },
  {
    label: "MOULINS-SUR-OUANNE",
    value: "89272",
    type: "common",
  },
  {
    label: "LEUGNY",
    value: "89221",
    type: "common",
  },
  {
    label: "DIGES",
    value: "89139",
    type: "common",
  },
  {
    label: "TOUCY",
    value: "89419",
    type: "common",
  },
  {
    label: "LALANDE",
    value: "89217",
    type: "common",
  },
  {
    label: "FONTAINES",
    value: "89173",
    type: "common",
  },
  {
    label: "MEZILLES",
    value: "89254",
    type: "common",
  },
  {
    label: "RONCHERES",
    value: "89325",
    type: "common",
  },
  {
    label: "TANNERRE-EN-PUISAYE",
    value: "89408",
    type: "common",
  },
  {
    label: "SAINT-PRIVE",
    value: "89365",
    type: "common",
  },
  {
    label: "ROGNY-LES-SEPT-ECLUSES",
    value: "89324",
    type: "common",
  },
  {
    label: "BLENEAU",
    value: "89046",
    type: "common",
  },
  {
    label: "ESCRIGNELLES",
    value: "45138",
    type: "common",
  },
  {
    label: "LA BUSSIERE",
    value: "45060",
    type: "common",
  },
  {
    label: "GIEN",
    value: "45155",
    type: "common",
  },
  {
    label: "SAINT-GONDON",
    value: "45280",
    type: "common",
  },
  {
    label: "NEVOY",
    value: "45227",
    type: "common",
  },
  {
    label: "SAINT-FLORENT",
    value: "45277",
    type: "common",
  },
  {
    label: "LION-EN-SULLIAS",
    value: "45184",
    type: "common",
  },
  {
    label: "SAINT-AIGNAN-LE-JAILLARD",
    value: "45268",
    type: "common",
  },
  {
    label: "VIGLAIN",
    value: "45336",
    type: "common",
  },
  {
    label: "VANNES-SUR-COSSON",
    value: "45331",
    type: "common",
  },
  {
    label: "SENNELY",
    value: "45309",
    type: "common",
  },
  {
    label: "MENESTREAU-EN-VILLETTE",
    value: "45200",
    type: "common",
  },
  {
    label: "LA FERTE-SAINT-AUBIN",
    value: "45146",
    type: "common",
  },
  {
    label: "LIGNY-LE-RIBAULT",
    value: "45182",
    type: "common",
  },
  {
    label: "SAINT-LAURENT-NOUAN",
    value: "41220",
    type: "common",
  },
  {
    label: "MER",
    value: "41136",
    type: "common",
  },
  {
    label: "COURBOUZON",
    value: "41066",
    type: "common",
  },
  {
    label: "AVARAY",
    value: "41008",
    type: "common",
  },
  {
    label: "LA CHAPELLE-SAINT-MARTIN-EN-PLAINE",
    value: "41039",
    type: "common",
  },
  {
    label: "MULSANS",
    value: "41156",
    type: "common",
  },
  {
    label: "MAVES",
    value: "41130",
    type: "common",
  },
  {
    label: "LA CHAPELLE-VENDOMOISE",
    value: "41040",
    type: "common",
  },
  {
    label: "CHAMPIGNY-EN-BEAUCE",
    value: "41035",
    type: "common",
  },
  {
    label: "AVERDON",
    value: "41009",
    type: "common",
  },
  {
    label: "VILLEROMAIN",
    value: "41290",
    type: "common",
  },
  {
    label: "VILLEMARDY",
    value: "41283",
    type: "common",
  },
  {
    label: "VILLEFRANCŒUR",
    value: "41281",
    type: "common",
  },
  {
    label: "TOURAILLES",
    value: "41261",
    type: "common",
  },
  {
    label: "PRAY",
    value: "41182",
    type: "common",
  },
  {
    label: "NOURRAY",
    value: "41163",
    type: "common",
  },
  {
    label: "LANCE",
    value: "41107",
    type: "common",
  },
  {
    label: "SAINT-AMAND-LONGPRE",
    value: "41199",
    type: "common",
  },
  {
    label: "HUISSEAU-EN-BEAUCE",
    value: "41103",
    type: "common",
  },
  {
    label: "AMBLOY",
    value: "41001",
    type: "common",
  },
  {
    label: "SASNIERES",
    value: "41236",
    type: "common",
  },
  {
    label: "PRUNAY-CASSEREAU",
    value: "41184",
    type: "common",
  },
  {
    label: "SAINT-MARTIN-DES-BOIS",
    value: "41225",
    type: "common",
  },
  {
    label: "SAINT-ARNOULT",
    value: "41201",
    type: "common",
  },
  {
    label: "MONTROUVEAU",
    value: "41153",
    type: "common",
  },
  {
    label: "LES HAYES",
    value: "41100",
    type: "common",
  },
  {
    label: "VILLEDIEU-LE-CHATEAU",
    value: "41279",
    type: "common",
  },
  {
    label: "BEAUMONT-SUR-DEME",
    value: "72027",
    type: "common",
  },
  {
    label: "MARCON",
    value: "72183",
    type: "common",
  },
  {
    label: "DISSAY-SOUS-COURCILLON",
    value: "72115",
    type: "common",
  },
  {
    label: "LUCEAU",
    value: "72173",
    type: "common",
  },
  {
    label: "MONTVAL-SUR-LOIR",
    value: "72071",
    type: "common",
  },
  {
    label: "VERNEIL-LE-CHETIF",
    value: "72369",
    type: "common",
  },
  {
    label: "COULONGE",
    value: "72098",
    type: "common",
  },
  {
    label: "AUBIGNE-RACAN",
    value: "72013",
    type: "common",
  },
  {
    label: "LE LUDE",
    value: "72176",
    type: "common",
  },
  {
    label: "THOREE-LES-PINS",
    value: "72357",
    type: "common",
  },
  {
    label: "MAREIL-SUR-LOIR",
    value: "72185",
    type: "common",
  },
  {
    label: "LUCHE-PRINGE",
    value: "72175",
    type: "common",
  },
  {
    label: "LA FLECHE",
    value: "72154",
    type: "common",
  },
  {
    label: "BAZOUGES CRE SUR LOIR",
    value: "72025",
    type: "common",
  },
  {
    label: "DURTAL",
    value: "49127",
    type: "common",
  },
  {
    label: "JUVARDEIL",
    value: "49170",
    type: "common",
  },
  {
    label: "ETRICHE",
    value: "49132",
    type: "common",
  },
  {
    label: "LES HAUTS-D'ANJOU",
    value: "49080",
    type: "common",
  },
  {
    label: "CHENILLE-CHAMPTEUSSE",
    value: "49067",
    type: "common",
  },
  {
    label: "MONTREUIL-SUR-MAINE",
    value: "49217",
    type: "common",
  },
  {
    label: "CHAMBELLAY",
    value: "49064",
    type: "common",
  },
  {
    label: "CHALLAIN-LA-POTHERIE",
    value: "49061",
    type: "common",
  },
  {
    label: "SAINT-JULIEN-DE-VOUVANTES",
    value: "44170",
    type: "common",
  },
  {
    label: "ERBRAY",
    value: "44054",
    type: "common",
  },
  {
    label: "LOUISFERT",
    value: "44085",
    type: "common",
  },
  {
    label: "ISSE",
    value: "44075",
    type: "common",
  },
  {
    label: "SAINT-VINCENT-DES-LANDES",
    value: "44193",
    type: "common",
  },
  {
    label: "JANS",
    value: "44076",
    type: "common",
  },
  {
    label: "DERVAL",
    value: "44051",
    type: "common",
  },
  {
    label: "CONQUEREUIL",
    value: "44044",
    type: "common",
  },
  {
    label: "GUEMENE-PENFAO",
    value: "44067",
    type: "common",
  },
  {
    label: "AVESSAC",
    value: "44007",
    type: "common",
  },
  {
    label: "SAINT-NICOLAS-DE-REDON",
    value: "44185",
    type: "common",
  },
  {
    label: "SAINT-JEAN-LA-POTERIE",
    value: "56223",
    type: "common",
  },
  {
    label: "RIEUX",
    value: "56194",
    type: "common",
  },
  {
    label: "SAINT-GORGON",
    value: "56216",
    type: "common",
  },
  {
    label: "BEGANNE",
    value: "56011",
    type: "common",
  },
  {
    label: "ALLAIRE",
    value: "56001",
    type: "common",
  },
  {
    label: "CADEN",
    value: "56028",
    type: "common",
  },
  {
    label: "LIMERZEL",
    value: "56111",
    type: "common",
  },
  {
    label: "NOYAL-MUZILLAC",
    value: "56149",
    type: "common",
  },
  {
    label: "LA TRINITE-SURZUR",
    value: "56259",
    type: "common",
  },
  {
    label: "LAUZACH",
    value: "56109",
    type: "common",
  },
  {
    label: "LE HEZO",
    value: "56084",
    type: "common",
  },
  {
    label: "SENE",
    value: "56243",
    type: "common",
  },
  {
    label: "ILE-D'ARZ",
    value: "56088",
    type: "common",
  },
  {
    label: "ILE-AUX-MOINES",
    value: "56087",
    type: "common",
  },
  {
    label: "LARMOR-BADEN",
    value: "56106",
    type: "common",
  },
  {
    label: "BADEN",
    value: "56008",
    type: "common",
  },
  {
    label: "ARZON",
    value: "56005",
    type: "common",
  },
  {
    label: "LA TRINITE-SUR-MER",
    value: "56258",
    type: "common",
  },
  {
    label: "SAINT-PHILIBERT",
    value: "56233",
    type: "common",
  },
  {
    label: "LOCMARIAQUER",
    value: "56116",
    type: "common",
  },
  {
    label: "PLOUHARNEL",
    value: "56168",
    type: "common",
  },
  {
    label: "CARNAC",
    value: "56034",
    type: "common",
  },
  {
    label: "NEUWILLER",
    value: "68232",
    type: "common",
  },
  {
    label: "HEGENHEIM",
    value: "68126",
    type: "common",
  },
  {
    label: "WENTZWILLER",
    value: "68362",
    type: "common",
  },
  {
    label: "MICHELBACH-LE-HAUT",
    value: "68208",
    type: "common",
  },
  {
    label: "HAGENTHAL-LE-HAUT",
    value: "68121",
    type: "common",
  },
  {
    label: "HAGENTHAL-LE-BAS",
    value: "68120",
    type: "common",
  },
  {
    label: "FOLGENSBOURG",
    value: "68094",
    type: "common",
  },
  {
    label: "BUSCHWILLER",
    value: "68061",
    type: "common",
  },
  {
    label: "BETTLACH",
    value: "68034",
    type: "common",
  },
  {
    label: "ATTENSCHWILLER",
    value: "68013",
    type: "common",
  },
  {
    label: "WERENTZHOUSE",
    value: "68363",
    type: "common",
  },
  {
    label: "STEINSOULTZ",
    value: "68325",
    type: "common",
  },
  {
    label: "ROPPENTZWILLER",
    value: "68284",
    type: "common",
  },
  {
    label: "MUESPACH-LE-HAUT",
    value: "68222",
    type: "common",
  },
  {
    label: "MUESPACH",
    value: "68221",
    type: "common",
  },
  {
    label: "LINSDORF",
    value: "68187",
    type: "common",
  },
  {
    label: "KNŒRINGUE",
    value: "68168",
    type: "common",
  },
  {
    label: "FISLIS",
    value: "68092",
    type: "common",
  },
  {
    label: "DURMENACH",
    value: "68075",
    type: "common",
  },
  {
    label: "WALDIGHOFEN",
    value: "68355",
    type: "common",
  },
  {
    label: "RUEDERBACH",
    value: "68288",
    type: "common",
  },
  {
    label: "RIESPACH",
    value: "68273",
    type: "common",
  },
  {
    label: "ILLTAL",
    value: "68240",
    type: "common",
  },
  {
    label: "HEIMERSDORF",
    value: "68128",
    type: "common",
  },
  {
    label: "FELDBACH",
    value: "68087",
    type: "common",
  },
  {
    label: "UEBERSTRASS",
    value: "68340",
    type: "common",
  },
  {
    label: "SEPPOIS-LE-HAUT",
    value: "68306",
    type: "common",
  },
  {
    label: "SEPPOIS-LE-BAS",
    value: "68305",
    type: "common",
  },
  {
    label: "LARGITZEN",
    value: "68176",
    type: "common",
  },
  {
    label: "FRIESEN",
    value: "68098",
    type: "common",
  },
  {
    label: "BISEL",
    value: "68039",
    type: "common",
  },
  {
    label: "SUARCE",
    value: "90095",
    type: "common",
  },
  {
    label: "LEPUIX-NEUF",
    value: "90064",
    type: "common",
  },
  {
    label: "COURTELEVANT",
    value: "90028",
    type: "common",
  },
  {
    label: "CHAVANATTE",
    value: "90024",
    type: "common",
  },
  {
    label: "STRUETH",
    value: "68330",
    type: "common",
  },
  {
    label: "HINDLINGEN",
    value: "68137",
    type: "common",
  },
  {
    label: "VELLESCOT",
    value: "90101",
    type: "common",
  },
  {
    label: "RECOUVRANCE",
    value: "90083",
    type: "common",
  },
  {
    label: "JONCHEREY",
    value: "90056",
    type: "common",
  },
  {
    label: "GROSNE",
    value: "90055",
    type: "common",
  },
  {
    label: "FLORIMONT",
    value: "90046",
    type: "common",
  },
  {
    label: "FAVEROIS",
    value: "90043",
    type: "common",
  },
  {
    label: "BORON",
    value: "90014",
    type: "common",
  },
  {
    label: "MORVILLARS",
    value: "90072",
    type: "common",
  },
  {
    label: "MEZIRE",
    value: "90069",
    type: "common",
  },
  {
    label: "GRANDVILLARS",
    value: "90053",
    type: "common",
  },
  {
    label: "FROIDEFONTAINE",
    value: "90051",
    type: "common",
  },
  {
    label: "CHARMOIS",
    value: "90021",
    type: "common",
  },
  {
    label: "BREBOTTE",
    value: "90018",
    type: "common",
  },
  {
    label: "BOUROGNE",
    value: "90017",
    type: "common",
  },
  {
    label: "ALLENJOIE",
    value: "25011",
    type: "common",
  },
  {
    label: "TREVENANS",
    value: "90097",
    type: "common",
  },
  {
    label: "SEVENANS",
    value: "90094",
    type: "common",
  },
  {
    label: "DORANS",
    value: "90035",
    type: "common",
  },
  {
    label: "CHATENOIS-LES-FORGES",
    value: "90022",
    type: "common",
  },
  {
    label: "BERMONT",
    value: "90011",
    type: "common",
  },
  {
    label: "BANVILLARS",
    value: "90007",
    type: "common",
  },
  {
    label: "NOMMAY",
    value: "25428",
    type: "common",
  },
  {
    label: "DAMBENOIS",
    value: "25188",
    type: "common",
  },
  {
    label: "BETHONCOURT",
    value: "25057",
    type: "common",
  },
  {
    label: "VYANS-LE-VAL",
    value: "70579",
    type: "common",
  },
  {
    label: "VERLANS",
    value: "70547",
    type: "common",
  },
  {
    label: "HERICOURT",
    value: "70285",
    type: "common",
  },
  {
    label: "COUTHENANS",
    value: "70184",
    type: "common",
  },
  {
    label: "BREVILLIERS",
    value: "70096",
    type: "common",
  },
  {
    label: "RAYNANS",
    value: "25481",
    type: "common",
  },
  {
    label: "LAIRE",
    value: "25322",
    type: "common",
  },
  {
    label: "VILLERS-SUR-SAULNOT",
    value: "70567",
    type: "common",
  },
  {
    label: "TREMOINS",
    value: "70506",
    type: "common",
  },
  {
    label: "COISEVAUX",
    value: "70160",
    type: "common",
  },
  {
    label: "CHAVANNE",
    value: "70147",
    type: "common",
  },
  {
    label: "CHAMPEY",
    value: "70121",
    type: "common",
  },
  {
    label: "LE VERNOY",
    value: "25608",
    type: "common",
  },
  {
    label: "SEMONDANS",
    value: "25540",
    type: "common",
  },
  {
    label: "AIBRE",
    value: "25008",
    type: "common",
  },
  {
    label: "SECENANS",
    value: "70484",
    type: "common",
  },
  {
    label: "SAULNOT",
    value: "70477",
    type: "common",
  },
  {
    label: "MIGNAVILLERS",
    value: "70347",
    type: "common",
  },
  {
    label: "GRANGES-LE-BOURG",
    value: "70277",
    type: "common",
  },
  {
    label: "GRANGES-LA-VILLE",
    value: "70276",
    type: "common",
  },
  {
    label: "CREVANS-ET-LA-CHAPELLE-LES-GRANGES",
    value: "70187",
    type: "common",
  },
  {
    label: "VILLERS-LA-VILLE",
    value: "70562",
    type: "common",
  },
  {
    label: "VILLAFANS",
    value: "70552",
    type: "common",
  },
  {
    label: "SENARGENT-MIGNAFANS",
    value: "70487",
    type: "common",
  },
  {
    label: "GOUHENANS",
    value: "70271",
    type: "common",
  },
  {
    label: "BEVEUGE",
    value: "70072",
    type: "common",
  },
  {
    label: "ATHESANS-ETROITEFONTAINE",
    value: "70031",
    type: "common",
  },
  {
    label: "VILLERSEXEL",
    value: "70561",
    type: "common",
  },
  {
    label: "SAINT-SULPICE",
    value: "70474",
    type: "common",
  },
  {
    label: "ORICOURT",
    value: "70396",
    type: "common",
  },
  {
    label: "OPPENANS",
    value: "70395",
    type: "common",
  },
  {
    label: "MOIMAY",
    value: "70349",
    type: "common",
  },
  {
    label: "MARAST",
    value: "70332",
    type: "common",
  },
  {
    label: "LONGEVELLE",
    value: "70307",
    type: "common",
  },
  {
    label: "AILLEVANS",
    value: "70005",
    type: "common",
  },
  {
    label: "CERRE-LES-NOROY",
    value: "70115",
    type: "common",
  },
  {
    label: "BOREY",
    value: "70077",
    type: "common",
  },
  {
    label: "VILLERS-LE-SEC",
    value: "70563",
    type: "common",
  },
  {
    label: "NOROY-LE-BOURG",
    value: "70390",
    type: "common",
  },
  {
    label: "COLOMBE-LES-VESOUL",
    value: "70162",
    type: "common",
  },
  {
    label: "VALLEROIS-LORIOZ",
    value: "70517",
    type: "common",
  },
  {
    label: "QUINCEY",
    value: "70433",
    type: "common",
  },
  {
    label: "NOIDANS-LES-VESOUL",
    value: "70388",
    type: "common",
  },
  {
    label: "NAVENNE",
    value: "70378",
    type: "common",
  },
  {
    label: "ECHENOZ-LA-MELINE",
    value: "70207",
    type: "common",
  },
  {
    label: "LA DEMIE",
    value: "70203",
    type: "common",
  },
  {
    label: "VELLE-LE-CHATEL",
    value: "70536",
    type: "common",
  },
  {
    label: "MONT-LE-VERNOIS",
    value: "70367",
    type: "common",
  },
  {
    label: "CLANS",
    value: "70158",
    type: "common",
  },
  {
    label: "CHARIEZ",
    value: "70134",
    type: "common",
  },
  {
    label: "BOURSIERES",
    value: "70090",
    type: "common",
  },
  {
    label: "BAIGNES",
    value: "70047",
    type: "common",
  },
  {
    label: "ANDELARROT",
    value: "70020",
    type: "common",
  },
  {
    label: "ANDELARRE",
    value: "70019",
    type: "common",
  },
  {
    label: "VY-LE-FERROUX",
    value: "70580",
    type: "common",
  },
  {
    label: "TRAVES",
    value: "70504",
    type: "common",
  },
  {
    label: "RAZE",
    value: "70439",
    type: "common",
  },
  {
    label: "NOIDANS-LE-FERROUX",
    value: "70387",
    type: "common",
  },
  {
    label: "AROZ",
    value: "70028",
    type: "common",
  },
  {
    label: "SOING-CUBRY-CHARENTENAY",
    value: "70492",
    type: "common",
  },
  {
    label: "FEDRY",
    value: "70230",
    type: "common",
  },
  {
    label: "CHANTES",
    value: "70127",
    type: "common",
  },
  {
    label: "VANNE",
    value: "70520",
    type: "common",
  },
  {
    label: "TINCEY-ET-PONTREBEAU",
    value: "70502",
    type: "common",
  },
  {
    label: "THEULEY",
    value: "70499",
    type: "common",
  },
  {
    label: "RECOLOGNE",
    value: "70440",
    type: "common",
  },
  {
    label: "RAY-SUR-SAONE",
    value: "70438",
    type: "common",
  },
  {
    label: "LAVONCOURT",
    value: "70299",
    type: "common",
  },
  {
    label: "FERRIERES-LES-RAY",
    value: "70231",
    type: "common",
  },
  {
    label: "VOLON",
    value: "70574",
    type: "common",
  },
  {
    label: "VAITE",
    value: "70511",
    type: "common",
  },
  {
    label: "ROCHE-ET-RAUCOURT",
    value: "70448",
    type: "common",
  },
  {
    label: "MEMBREY",
    value: "70340",
    type: "common",
  },
  {
    label: "BROTTE-LES-RAY",
    value: "70099",
    type: "common",
  },
  {
    label: "LARRET",
    value: "70297",
    type: "common",
  },
  {
    label: "DELAIN",
    value: "70201",
    type: "common",
  },
  {
    label: "COURTESOULT-ET-GATEY",
    value: "70183",
    type: "common",
  },
  {
    label: "CHAMPLITTE",
    value: "70122",
    type: "common",
  },
  {
    label: "ORAIN",
    value: "21468",
    type: "common",
  },
  {
    label: "CUSEY",
    value: "52158",
    type: "common",
  },
  {
    label: "PERCEY-LE-GRAND",
    value: "70406",
    type: "common",
  },
  {
    label: "OCCEY",
    value: "52360",
    type: "common",
  },
  {
    label: "ISOMES",
    value: "52249",
    type: "common",
  },
  {
    label: "BOUSSENOIS",
    value: "21096",
    type: "common",
  },
  {
    label: "MAREY-SUR-TILLE",
    value: "21385",
    type: "common",
  },
  {
    label: "FONCEGRIVE",
    value: "21275",
    type: "common",
  },
  {
    label: "CUSSEY-LES-FORGES",
    value: "21220",
    type: "common",
  },
  {
    label: "COURLON",
    value: "21207",
    type: "common",
  },
  {
    label: "AVOT",
    value: "21041",
    type: "common",
  },
  {
    label: "SALIVES",
    value: "21579",
    type: "common",
  },
  {
    label: "FRAIGNOT-ET-VESVROTTE",
    value: "21283",
    type: "common",
  },
  {
    label: "BARJON",
    value: "21049",
    type: "common",
  },
  {
    label: "ECHALOT",
    value: "21237",
    type: "common",
  },
  {
    label: "ETALANTE",
    value: "21253",
    type: "common",
  },
  {
    label: "QUEMIGNY-SUR-SEINE",
    value: "21514",
    type: "common",
  },
  {
    label: "ORRET",
    value: "21471",
    type: "common",
  },
  {
    label: "DUESME",
    value: "21235",
    type: "common",
  },
  {
    label: "BAIGNEUX-LES-JUIFS",
    value: "21043",
    type: "common",
  },
  {
    label: "AMPILLY-LES-BORDES",
    value: "21011",
    type: "common",
  },
  {
    label: "JOURS-LES-BAIGNEUX",
    value: "21326",
    type: "common",
  },
  {
    label: "CHAUME-LES-BAIGNEUX",
    value: "21160",
    type: "common",
  },
  {
    label: "LUCENAY-LE-DUC",
    value: "21358",
    type: "common",
  },
  {
    label: "FONTAINES-EN-DUESMOIS",
    value: "21276",
    type: "common",
  },
  {
    label: "TOUILLON",
    value: "21641",
    type: "common",
  },
  {
    label: "MARMAGNE",
    value: "21389",
    type: "common",
  },
  {
    label: "FRESNES",
    value: "21287",
    type: "common",
  },
  {
    label: "FAIN-LES-MONTBARD",
    value: "21259",
    type: "common",
  },
  {
    label: "SAINT-REMY",
    value: "21568",
    type: "common",
  },
  {
    label: "CREPAND",
    value: "21212",
    type: "common",
  },
  {
    label: "AISY-SUR-ARMANCON",
    value: "89004",
    type: "common",
  },
  {
    label: "QUINCY-LE-VICOMTE",
    value: "21518",
    type: "common",
  },
  {
    label: "QUINCEROT",
    value: "21516",
    type: "common",
  },
  {
    label: "BUFFON",
    value: "21114",
    type: "common",
  },
  {
    label: "BIERRY-LES-BELLES-FONTAINES",
    value: "89042",
    type: "common",
  },
  {
    label: "SARRY",
    value: "89376",
    type: "common",
  },
  {
    label: "CHATEL-GERARD",
    value: "89092",
    type: "common",
  },
  {
    label: "ANNOUX",
    value: "89012",
    type: "common",
  },
  {
    label: "MASSANGIS",
    value: "89246",
    type: "common",
  },
  {
    label: "GRIMAULT",
    value: "89194",
    type: "common",
  },
  {
    label: "JOUX-LA-VILLE",
    value: "89208",
    type: "common",
  },
  {
    label: "LUCY-SUR-CURE",
    value: "89233",
    type: "common",
  },
  {
    label: "SERY",
    value: "89394",
    type: "common",
  },
  {
    label: "SAINTE-PALLAYE",
    value: "89363",
    type: "common",
  },
  {
    label: "PREGILBERT",
    value: "89314",
    type: "common",
  },
  {
    label: "BESSY-SUR-CURE",
    value: "89040",
    type: "common",
  },
  {
    label: "VAL-DE-MERCY",
    value: "89426",
    type: "common",
  },
  {
    label: "TRUCY-SUR-YONNE",
    value: "89424",
    type: "common",
  },
  {
    label: "FONTENAY-SOUS-FOURONNES",
    value: "89177",
    type: "common",
  },
  {
    label: "BAZARNES",
    value: "89030",
    type: "common",
  },
  {
    label: "MOUFFY",
    value: "89270",
    type: "common",
  },
  {
    label: "CHARENTENAY",
    value: "89084",
    type: "common",
  },
  {
    label: "OUANNE",
    value: "89283",
    type: "common",
  },
  {
    label: "MERRY-SEC",
    value: "89252",
    type: "common",
  },
  {
    label: "SEMENTRON",
    value: "89383",
    type: "common",
  },
  {
    label: "LEVIS",
    value: "89222",
    type: "common",
  },
  {
    label: "SAINTS-EN-PUISAYE",
    value: "89367",
    type: "common",
  },
  {
    label: "FONTENOY",
    value: "89179",
    type: "common",
  },
  {
    label: "SAINT-SAUVEUR-EN-PUISAYE",
    value: "89368",
    type: "common",
  },
  {
    label: "SAINT-FARGEAU",
    value: "89344",
    type: "common",
  },
  {
    label: "SAINT-MARTIN-DES-CHAMPS",
    value: "89352",
    type: "common",
  },
  {
    label: "CHAMPOULET",
    value: "45070",
    type: "common",
  },
  {
    label: "BRETEAU",
    value: "45052",
    type: "common",
  },
  {
    label: "OUZOUER-SUR-TREZEE",
    value: "45245",
    type: "common",
  },
  {
    label: "DAMMARIE-EN-PUISAYE",
    value: "45120",
    type: "common",
  },
  {
    label: "BRIARE",
    value: "45053",
    type: "common",
  },
  {
    label: "SAINT-MARTIN-SUR-OCRE",
    value: "45291",
    type: "common",
  },
  {
    label: "SAINT-BRISSON-SUR-LOIRE",
    value: "45271",
    type: "common",
  },
  {
    label: "POILLY-LEZ-GIEN",
    value: "45254",
    type: "common",
  },
  {
    label: "COULLONS",
    value: "45108",
    type: "common",
  },
  {
    label: "CERDON",
    value: "45063",
    type: "common",
  },
  {
    label: "VILLEMURLIN",
    value: "45340",
    type: "common",
  },
  {
    label: "ISDES",
    value: "45171",
    type: "common",
  },
  {
    label: "SOUVIGNY-EN-SOLOGNE",
    value: "41251",
    type: "common",
  },
  {
    label: "VOUZON",
    value: "41296",
    type: "common",
  },
  {
    label: "YVOY-LE-MARRON",
    value: "41297",
    type: "common",
  },
  {
    label: "LA FERTE-SAINT-CYR",
    value: "41085",
    type: "common",
  },
  {
    label: "CROUY-SUR-COSSON",
    value: "41071",
    type: "common",
  },
  {
    label: "SAINT-DYE-SUR-LOIRE",
    value: "41207",
    type: "common",
  },
  {
    label: "MUIDES-SUR-LOIRE",
    value: "41155",
    type: "common",
  },
  {
    label: "SUEVRES",
    value: "41252",
    type: "common",
  },
  {
    label: "SAINT-CLAUDE-DE-DIRAY",
    value: "41204",
    type: "common",
  },
  {
    label: "MONTLIVAULT",
    value: "41148",
    type: "common",
  },
  {
    label: "MASLIVES",
    value: "41129",
    type: "common",
  },
  {
    label: "COUR-SUR-LOIRE",
    value: "41069",
    type: "common",
  },
  {
    label: "VILLERBON",
    value: "41288",
    type: "common",
  },
  {
    label: "VILLEBAROU",
    value: "41276",
    type: "common",
  },
  {
    label: "SAINT-DENIS-SUR-LOIRE",
    value: "41206",
    type: "common",
  },
  {
    label: "MENARS",
    value: "41134",
    type: "common",
  },
  {
    label: "SAINT-BOHAIRE",
    value: "41203",
    type: "common",
  },
  {
    label: "MAROLLES",
    value: "41128",
    type: "common",
  },
  {
    label: "FOSSE",
    value: "41091",
    type: "common",
  },
  {
    label: "SAINT-LUBIN-EN-VERGONNOIS",
    value: "41223",
    type: "common",
  },
  {
    label: "LANDES-LE-GAULOIS",
    value: "41109",
    type: "common",
  },
  {
    label: "LANCOME",
    value: "41108",
    type: "common",
  },
  {
    label: "GOMBERGEAN",
    value: "41098",
    type: "common",
  },
  {
    label: "FRANCAY",
    value: "41093",
    type: "common",
  },
  {
    label: "VILLEPORCHER",
    value: "41286",
    type: "common",
  },
  {
    label: "SAINT-GOURGON",
    value: "41213",
    type: "common",
  },
  {
    label: "SAINT-CYR-DU-GAULT",
    value: "41205",
    type: "common",
  },
  {
    label: "VILLECHAUVE",
    value: "41278",
    type: "common",
  },
  {
    label: "AUTHON",
    value: "41007",
    type: "common",
  },
  {
    label: "NEUVILLE-SUR-BRENNE",
    value: "37169",
    type: "common",
  },
  {
    label: "MONTHODON",
    value: "37155",
    type: "common",
  },
  {
    label: "LES HERMITES",
    value: "37116",
    type: "common",
  },
  {
    label: "LA FERRIERE",
    value: "37106",
    type: "common",
  },
  {
    label: "MARRAY",
    value: "37149",
    type: "common",
  },
  {
    label: "CHEMILLE-SUR-DEME",
    value: "37068",
    type: "common",
  },
  {
    label: "EPEIGNE-SUR-DEME",
    value: "37101",
    type: "common",
  },
  {
    label: "BUEIL-EN-TOURAINE",
    value: "37041",
    type: "common",
  },
  {
    label: "VILLEBOURG",
    value: "37274",
    type: "common",
  },
  {
    label: "SAINT-CHRISTOPHE-SUR-LE-NAIS",
    value: "37213",
    type: "common",
  },
  {
    label: "SAINT-PIERRE-DE-CHEVILLE",
    value: "72311",
    type: "common",
  },
  {
    label: "NOGENT-SUR-LOIR",
    value: "72221",
    type: "common",
  },
  {
    label: "LA BRUERE-SUR-LOIR",
    value: "72049",
    type: "common",
  },
  {
    label: "SAINT-AUBIN-LE-DEPEINT",
    value: "37207",
    type: "common",
  },
  {
    label: "VAAS",
    value: "72364",
    type: "common",
  },
  {
    label: "SAINT-GERMAIN-D'ARCE",
    value: "72283",
    type: "common",
  },
  {
    label: "LA CHAPELLE-AUX-CHOUX",
    value: "72060",
    type: "common",
  },
  {
    label: "SAVIGNE-SOUS-LE-LUDE",
    value: "72330",
    type: "common",
  },
  {
    label: "LES RAIRIES",
    value: "49257",
    type: "common",
  },
  {
    label: "MONTIGNE-LES-RAIRIES",
    value: "49209",
    type: "common",
  },
  {
    label: "LA CHAPELLE-SAINT-LAUD",
    value: "49076",
    type: "common",
  },
  {
    label: "SEICHES-SUR-LE-LOIR",
    value: "49333",
    type: "common",
  },
  {
    label: "BARACE",
    value: "49017",
    type: "common",
  },
  {
    label: "TIERCE",
    value: "49347",
    type: "common",
  },
  {
    label: "CHEFFES",
    value: "49090",
    type: "common",
  },
  {
    label: "ECUILLE",
    value: "49130",
    type: "common",
  },
  {
    label: "THORIGNE-D'ANJOU",
    value: "49344",
    type: "common",
  },
  {
    label: "SCEAUX-D'ANJOU",
    value: "49330",
    type: "common",
  },
  {
    label: "FENEU",
    value: "49135",
    type: "common",
  },
  {
    label: "LE LION-D'ANGERS",
    value: "49176",
    type: "common",
  },
  {
    label: "GREZ-NEUVILLE",
    value: "49155",
    type: "common",
  },
  {
    label: "ERDRE-EN-ANJOU",
    value: "49367",
    type: "common",
  },
  {
    label: "CHAZE-SUR-ARGOS",
    value: "49089",
    type: "common",
  },
  {
    label: "LOIRE",
    value: "49178",
    type: "common",
  },
  {
    label: "LE PIN",
    value: "44124",
    type: "common",
  },
  {
    label: "LA CHAPELLE-GLAIN",
    value: "44031",
    type: "common",
  },
  {
    label: "PETIT-AUVERNE",
    value: "44121",
    type: "common",
  },
  {
    label: "MOISDON-LA-RIVIERE",
    value: "44099",
    type: "common",
  },
  {
    label: "GRAND-AUVERNE",
    value: "44065",
    type: "common",
  },
  {
    label: "LA MEILLERAYE-DE-BRETAGNE",
    value: "44095",
    type: "common",
  },
  {
    label: "TREFFIEUX",
    value: "44208",
    type: "common",
  },
  {
    label: "NOZAY",
    value: "44113",
    type: "common",
  },
  {
    label: "MARSAC-SUR-DON",
    value: "44091",
    type: "common",
  },
  {
    label: "PLESSE",
    value: "44128",
    type: "common",
  },
  {
    label: "SEVERAC",
    value: "44196",
    type: "common",
  },
  {
    label: "FEGREAC",
    value: "44057",
    type: "common",
  },
  {
    label: "THEHILLAC",
    value: "56250",
    type: "common",
  },
  {
    label: "SAINT-DOLAY",
    value: "56212",
    type: "common",
  },
  {
    label: "PEAULE",
    value: "56153",
    type: "common",
  },
  {
    label: "NIVILLAC",
    value: "56147",
    type: "common",
  },
  {
    label: "MARZAN",
    value: "56126",
    type: "common",
  },
  {
    label: "LE GUERNO",
    value: "56077",
    type: "common",
  },
  {
    label: "MUZILLAC",
    value: "56143",
    type: "common",
  },
  {
    label: "DAMGAN",
    value: "56052",
    type: "common",
  },
  {
    label: "AMBON",
    value: "56002",
    type: "common",
  },
  {
    label: "LE TOUR-DU-PARC",
    value: "56252",
    type: "common",
  },
  {
    label: "SURZUR",
    value: "56248",
    type: "common",
  },
  {
    label: "SAINT-ARMEL",
    value: "56205",
    type: "common",
  },
  {
    label: "SARZEAU",
    value: "56240",
    type: "common",
  },
  {
    label: "SAINT-GILDAS-DE-RHUYS",
    value: "56214",
    type: "common",
  },
  {
    label: "SAINT-PIERRE-QUIBERON",
    value: "56234",
    type: "common",
  },
  {
    label: "WOLSCHWILLER",
    value: "68380",
    type: "common",
  },
  {
    label: "LIEBENSWILLER",
    value: "68183",
    type: "common",
  },
  {
    label: "LEYMEN",
    value: "68182",
    type: "common",
  },
  {
    label: "BIEDERTHAL",
    value: "68035",
    type: "common",
  },
  {
    label: "SONDERSDORF",
    value: "68312",
    type: "common",
  },
  {
    label: "RAEDERSDORF",
    value: "68259",
    type: "common",
  },
  {
    label: "OLTINGUE",
    value: "68248",
    type: "common",
  },
  {
    label: "LUTTER",
    value: "68194",
    type: "common",
  },
  {
    label: "BOUXWILLER",
    value: "68049",
    type: "common",
  },
  {
    label: "VIEUX-FERRETTE",
    value: "68347",
    type: "common",
  },
  {
    label: "MŒRNACH",
    value: "68212",
    type: "common",
  },
  {
    label: "LIGSDORF",
    value: "68186",
    type: "common",
  },
  {
    label: "KŒSTLACH",
    value: "68169",
    type: "common",
  },
  {
    label: "FERRETTE",
    value: "68090",
    type: "common",
  },
  {
    label: "BENDORF",
    value: "68025",
    type: "common",
  },
  {
    label: "PFETTERHOUSE",
    value: "68257",
    type: "common",
  },
  {
    label: "MOOSLARGUE",
    value: "68216",
    type: "common",
  },
  {
    label: "LIEBSDORF",
    value: "68184",
    type: "common",
  },
  {
    label: "DURLINSDORF",
    value: "68074",
    type: "common",
  },
  {
    label: "COURTAVON",
    value: "68067",
    type: "common",
  },
  {
    label: "RECHESY",
    value: "90081",
    type: "common",
  },
  {
    label: "COURCELLES",
    value: "90027",
    type: "common",
  },
  {
    label: "THIANCOURT",
    value: "90096",
    type: "common",
  },
  {
    label: "DELLE",
    value: "90033",
    type: "common",
  },
  {
    label: "LEBETAIN",
    value: "90063",
    type: "common",
  },
  {
    label: "FECHE-L'EGLISE",
    value: "90045",
    type: "common",
  },
  {
    label: "BEAUCOURT",
    value: "90009",
    type: "common",
  },
  {
    label: "FESCHES-LE-CHATEL",
    value: "25237",
    type: "common",
  },
  {
    label: "DASLE",
    value: "25196",
    type: "common",
  },
  {
    label: "DAMPIERRE-LES-BOIS",
    value: "25190",
    type: "common",
  },
  {
    label: "BADEVEL",
    value: "25040",
    type: "common",
  },
  {
    label: "VIEUX-CHARMONT",
    value: "25614",
    type: "common",
  },
  {
    label: "TAILLECOURT",
    value: "25555",
    type: "common",
  },
  {
    label: "SOCHAUX",
    value: "25547",
    type: "common",
  },
  {
    label: "GRAND-CHARMONT",
    value: "25284",
    type: "common",
  },
  {
    label: "EXINCOURT",
    value: "25230",
    type: "common",
  },
  {
    label: "ETUPES",
    value: "25228",
    type: "common",
  },
  {
    label: "BROGNARD",
    value: "25097",
    type: "common",
  },
  {
    label: "AUDINCOURT",
    value: "25031",
    type: "common",
  },
  {
    label: "ARBOUANS",
    value: "25020",
    type: "common",
  },
  {
    label: "SAINTE-SUZANNE",
    value: "25526",
    type: "common",
  },
  {
    label: "PRESENTEVILLERS",
    value: "25469",
    type: "common",
  },
  {
    label: "MONTBELIARD",
    value: "25388",
    type: "common",
  },
  {
    label: "ISSANS",
    value: "25316",
    type: "common",
  },
  {
    label: "DUNG",
    value: "25207",
    type: "common",
  },
  {
    label: "COURCELLES-LES-MONTBELIARD",
    value: "25170",
    type: "common",
  },
  {
    label: "BART",
    value: "25043",
    type: "common",
  },
  {
    label: "ALLONDANS",
    value: "25013",
    type: "common",
  },
  {
    label: "SAINTE-MARIE",
    value: "25523",
    type: "common",
  },
  {
    label: "SAINT-JULIEN-LES-MONTBELIARD",
    value: "25521",
    type: "common",
  },
  {
    label: "MONTENOIS",
    value: "25394",
    type: "common",
  },
  {
    label: "ECHENANS",
    value: "25210",
    type: "common",
  },
  {
    label: "DESANDANS",
    value: "25198",
    type: "common",
  },
  {
    label: "BRETIGNEY",
    value: "25093",
    type: "common",
  },
  {
    label: "ARCEY",
    value: "25022",
    type: "common",
  },
  {
    label: "COURCHATON",
    value: "70180",
    type: "common",
  },
  {
    label: "ONANS",
    value: "25431",
    type: "common",
  },
  {
    label: "MARVELISE",
    value: "25369",
    type: "common",
  },
  {
    label: "GEMONVAL",
    value: "25264",
    type: "common",
  },
  {
    label: "ACCOLANS",
    value: "25005",
    type: "common",
  },
  {
    label: "VILLARGENT",
    value: "70553",
    type: "common",
  },
  {
    label: "VELLECHEVREUX-ET-COURBENANS",
    value: "70530",
    type: "common",
  },
  {
    label: "SAINT-FERJEUX",
    value: "70462",
    type: "common",
  },
  {
    label: "MELECEY",
    value: "70336",
    type: "common",
  },
  {
    label: "GRAMMONT",
    value: "70273",
    type: "common",
  },
  {
    label: "GEORFANS",
    value: "70264",
    type: "common",
  },
  {
    label: "FALLON",
    value: "70226",
    type: "common",
  },
  {
    label: "ABBENANS",
    value: "25003",
    type: "common",
  },
  {
    label: "PONT-SUR-L'OGNON",
    value: "70420",
    type: "common",
  },
  {
    label: "LES MAGNY",
    value: "70317",
    type: "common",
  },
  {
    label: "AUTREY-LE-VAY",
    value: "70042",
    type: "common",
  },
  {
    label: "CUBRY",
    value: "25182",
    type: "common",
  },
  {
    label: "CUBRIAL",
    value: "25181",
    type: "common",
  },
  {
    label: "BONNAL",
    value: "25072",
    type: "common",
  },
  {
    label: "VALLEROIS-LE-BOIS",
    value: "70516",
    type: "common",
  },
  {
    label: "THIEFFRANS",
    value: "70500",
    type: "common",
  },
  {
    label: "ESPRELS",
    value: "70219",
    type: "common",
  },
  {
    label: "COGNIERES",
    value: "70159",
    type: "common",
  },
  {
    label: "CHASSEY-LES-MONTBOZON",
    value: "70137",
    type: "common",
  },
  {
    label: "TRESSANDANS",
    value: "25570",
    type: "common",
  },
  {
    label: "VY-LES-FILAIN",
    value: "70583",
    type: "common",
  },
  {
    label: "DAMPIERRE-SUR-LINOTTE",
    value: "70197",
    type: "common",
  },
  {
    label: "VELLEFAUX",
    value: "70532",
    type: "common",
  },
  {
    label: "NEUREY-LES-LA-DEMIE",
    value: "70381",
    type: "common",
  },
  {
    label: "FILAIN",
    value: "70234",
    type: "common",
  },
  {
    label: "ECHENOZ-LE-SEC",
    value: "70208",
    type: "common",
  },
  {
    label: "VELLEGUINDRY-ET-LEVRECEY",
    value: "70535",
    type: "common",
  },
  {
    label: "ROSEY",
    value: "70452",
    type: "common",
  },
  {
    label: "MAILLEY-ET-CHAZELOT",
    value: "70324",
    type: "common",
  },
  {
    label: "LE MAGNORAY",
    value: "70316",
    type: "common",
  },
  {
    label: "NEUVELLE-LES-LA-CHARITE",
    value: "70384",
    type: "common",
  },
  {
    label: "LIEFFRANS",
    value: "70301",
    type: "common",
  },
  {
    label: "GRANDVELLE-ET-LE-PERRENOT",
    value: "70275",
    type: "common",
  },
  {
    label: "BOURGUIGNON-LES-LA-CHARITE",
    value: "70088",
    type: "common",
  },
  {
    label: "LA VERNOTTE",
    value: "70549",
    type: "common",
  },
  {
    label: "LA ROMAINE",
    value: "70418",
    type: "common",
  },
  {
    label: "FRESNE-SAINT-MAMES",
    value: "70255",
    type: "common",
  },
  {
    label: "VELLEXON-QUEUTREY-ET-VAUDEY",
    value: "70539",
    type: "common",
  },
  {
    label: "SAVOYEUX",
    value: "70481",
    type: "common",
  },
  {
    label: "MERCEY-SUR-SAONE",
    value: "70342",
    type: "common",
  },
  {
    label: "DAMPIERRE-SUR-SALON",
    value: "70198",
    type: "common",
  },
  {
    label: "AUTET",
    value: "70037",
    type: "common",
  },
  {
    label: "VEREUX",
    value: "70546",
    type: "common",
  },
  {
    label: "MONTOT",
    value: "70368",
    type: "common",
  },
  {
    label: "DENEVRE",
    value: "70204",
    type: "common",
  },
  {
    label: "ACHEY",
    value: "70003",
    type: "common",
  },
  {
    label: "VARS",
    value: "70523",
    type: "common",
  },
  {
    label: "OYRIERES",
    value: "70402",
    type: "common",
  },
  {
    label: "FRAMONT",
    value: "70252",
    type: "common",
  },
  {
    label: "ECUELLE",
    value: "70211",
    type: "common",
  },
  {
    label: "POUILLY-SUR-VINGEANNE",
    value: "21503",
    type: "common",
  },
  {
    label: "MONTIGNY-MORNAY-VILLENEUVE-SUR-VINGEANNE",
    value: "21433",
    type: "common",
  },
  {
    label: "SAINT-MAURICE-SUR-VINGEANNE",
    value: "21562",
    type: "common",
  },
  {
    label: "FONTAINE-FRANCAISE",
    value: "21277",
    type: "common",
  },
  {
    label: "CHAUME-ET-COURCHAMP",
    value: "21158",
    type: "common",
  },
  {
    label: "VERONNES",
    value: "21667",
    type: "common",
  },
  {
    label: "SACQUENAY",
    value: "21536",
    type: "common",
  },
  {
    label: "CHAZEUIL",
    value: "21163",
    type: "common",
  },
  {
    label: "SELONGEY",
    value: "21599",
    type: "common",
  },
  {
    label: "ORVILLE",
    value: "21472",
    type: "common",
  },
  {
    label: "ECHEVANNES",
    value: "21240",
    type: "common",
  },
  {
    label: "VILLEY-SUR-TILLE",
    value: "21702",
    type: "common",
  },
  {
    label: "CRECEY-SUR-TILLE",
    value: "21211",
    type: "common",
  },
  {
    label: "SAULX-LE-DUC",
    value: "21587",
    type: "common",
  },
  {
    label: "POISEUL-LES-SAULX",
    value: "21491",
    type: "common",
  },
  {
    label: "AVELANGES",
    value: "21039",
    type: "common",
  },
  {
    label: "MOLOY",
    value: "21421",
    type: "common",
  },
  {
    label: "LE MEIX",
    value: "21400",
    type: "common",
  },
  {
    label: "COURTIVRON",
    value: "21208",
    type: "common",
  },
  {
    label: "LERY",
    value: "21345",
    type: "common",
  },
  {
    label: "POISEUL-LA-GRANGE",
    value: "21489",
    type: "common",
  },
  {
    label: "OIGNY",
    value: "21466",
    type: "common",
  },
  {
    label: "BILLY-LES-CHANCEAUX",
    value: "21075",
    type: "common",
  },
  {
    label: "POISEUL-LA-VILLE-ET-LAPERRIERE",
    value: "21490",
    type: "common",
  },
  {
    label: "LA VILLENEUVE-LES-CONVERS",
    value: "21695",
    type: "common",
  },
  {
    label: "ETORMAY",
    value: "21257",
    type: "common",
  },
  {
    label: "DARCEY",
    value: "21226",
    type: "common",
  },
  {
    label: "CORPOYER-LA-CHAPELLE",
    value: "21197",
    type: "common",
  },
  {
    label: "MENETREUX-LE-PITOIS",
    value: "21404",
    type: "common",
  },
  {
    label: "GRESIGNY-SAINTE-REINE",
    value: "21307",
    type: "common",
  },
  {
    label: "ERINGES",
    value: "21248",
    type: "common",
  },
  {
    label: "BUSSY-LE-GRAND",
    value: "21122",
    type: "common",
  },
  {
    label: "SEIGNY",
    value: "21598",
    type: "common",
  },
  {
    label: "NOGENT-LES-MONTBARD",
    value: "21456",
    type: "common",
  },
  {
    label: "GRIGNON",
    value: "21308",
    type: "common",
  },
  {
    label: "COURCELLES-LES-MONTBARD",
    value: "21204",
    type: "common",
  },
  {
    label: "BENOISEY",
    value: "21064",
    type: "common",
  },
  {
    label: "VISERNY",
    value: "21709",
    type: "common",
  },
  {
    label: "VILLAINES-LES-PREVOTES",
    value: "21686",
    type: "common",
  },
  {
    label: "SAINT-GERMAIN-LES-SENAILLY",
    value: "21550",
    type: "common",
  },
  {
    label: "MONTIGNY-MONTFORT",
    value: "21429",
    type: "common",
  },
  {
    label: "CHAMP-D'OISEAU",
    value: "21137",
    type: "common",
  },
  {
    label: "SENAILLY",
    value: "21604",
    type: "common",
  },
  {
    label: "MOUTIERS-SAINT-JEAN",
    value: "21446",
    type: "common",
  },
  {
    label: "FAIN-LES-MOUTIERS",
    value: "21260",
    type: "common",
  },
  {
    label: "ATHIE",
    value: "21029",
    type: "common",
  },
  {
    label: "VASSY-SOUS-PISY",
    value: "89431",
    type: "common",
  },
  {
    label: "SANTIGNY",
    value: "89375",
    type: "common",
  },
  {
    label: "PISY",
    value: "89300",
    type: "common",
  },
  {
    label: "MARMEAUX",
    value: "89244",
    type: "common",
  },
  {
    label: "THIZY",
    value: "89412",
    type: "common",
  },
  {
    label: "TALCY",
    value: "89406",
    type: "common",
  },
  {
    label: "L'ISLE-SUR-SEREIN",
    value: "89204",
    type: "common",
  },
  {
    label: "BLACY",
    value: "89043",
    type: "common",
  },
  {
    label: "SAINTE-COLOMBE",
    value: "89339",
    type: "common",
  },
  {
    label: "DISSANGIS",
    value: "89141",
    type: "common",
  },
  {
    label: "COUTARNOUX",
    value: "89128",
    type: "common",
  },
  {
    label: "ANGELY",
    value: "89008",
    type: "common",
  },
  {
    label: "THORY",
    value: "89415",
    type: "common",
  },
  {
    label: "LUCY-LE-BOIS",
    value: "89232",
    type: "common",
  },
  {
    label: "ANNAY-LA-COTE",
    value: "89009",
    type: "common",
  },
  {
    label: "VOUTENAY-SUR-CURE",
    value: "89485",
    type: "common",
  },
  {
    label: "SAINT-MORE",
    value: "89362",
    type: "common",
  },
  {
    label: "PRECY-LE-SEC",
    value: "89312",
    type: "common",
  },
  {
    label: "MAILLY-LA-VILLE",
    value: "89237",
    type: "common",
  },
  {
    label: "ARCY-SUR-CURE",
    value: "89015",
    type: "common",
  },
  {
    label: "MERRY-SUR-YONNE",
    value: "89253",
    type: "common",
  },
  {
    label: "MAILLY-LE-CHATEAU",
    value: "89238",
    type: "common",
  },
  {
    label: "FOURONNES",
    value: "89182",
    type: "common",
  },
  {
    label: "COURSON-LES-CARRIERES",
    value: "89125",
    type: "common",
  },
  {
    label: "LES HAUTS DE FORTERRE",
    value: "89405",
    type: "common",
  },
  {
    label: "SOUGERES-EN-PUISAYE",
    value: "89400",
    type: "common",
  },
  {
    label: "LAIN",
    value: "89215",
    type: "common",
  },
  {
    label: "THURY",
    value: "89416",
    type: "common",
  },
  {
    label: "MOUTIERS-EN-PUISAYE",
    value: "89273",
    type: "common",
  },
  {
    label: "LAVAU",
    value: "89220",
    type: "common",
  },
  {
    label: "THOU",
    value: "45323",
    type: "common",
  },
  {
    label: "FAVERELLES",
    value: "45141",
    type: "common",
  },
  {
    label: "BATILLY-EN-PUISAYE",
    value: "45023",
    type: "common",
  },
  {
    label: "BONNY-SUR-LOIRE",
    value: "45040",
    type: "common",
  },
  {
    label: "OUSSON-SUR-LOIRE",
    value: "45238",
    type: "common",
  },
  {
    label: "CHATILLON-SUR-LOIRE",
    value: "45087",
    type: "common",
  },
  {
    label: "SAINT-FIRMIN-SUR-LOIRE",
    value: "45276",
    type: "common",
  },
  {
    label: "AUTRY-LE-CHATEL",
    value: "45016",
    type: "common",
  },
  {
    label: "ARGENT-SUR-SAULDRE",
    value: "18011",
    type: "common",
  },
  {
    label: "BRINON-SUR-SAULDRE",
    value: "18037",
    type: "common",
  },
  {
    label: "CHAON",
    value: "41036",
    type: "common",
  },
  {
    label: "LAMOTTE-BEUVRON",
    value: "41106",
    type: "common",
  },
  {
    label: "CHAUMONT-SUR-THARONNE",
    value: "41046",
    type: "common",
  },
  {
    label: "VILLENY",
    value: "41285",
    type: "common",
  },
  {
    label: "LA MAROLLE-EN-SOLOGNE",
    value: "41127",
    type: "common",
  },
  {
    label: "DHUIZON",
    value: "41074",
    type: "common",
  },
  {
    label: "THOURY",
    value: "41260",
    type: "common",
  },
  {
    label: "NEUVY",
    value: "41160",
    type: "common",
  },
  {
    label: "CHAMBORD",
    value: "41034",
    type: "common",
  },
  {
    label: "HUISSEAU-SUR-COSSON",
    value: "41104",
    type: "common",
  },
  {
    label: "VINEUIL",
    value: "41295",
    type: "common",
  },
  {
    label: "LA CHAUSSEE-SAINT-VICTOR",
    value: "41047",
    type: "common",
  },
  {
    label: "SAINT-SULPICE-DE-POMMERAY",
    value: "41230",
    type: "common",
  },
  {
    label: "VALENCISSE",
    value: "41142",
    type: "common",
  },
  {
    label: "BLOIS",
    value: "41018",
    type: "common",
  },
  {
    label: "HERBAULT",
    value: "41101",
    type: "common",
  },
  {
    label: "SANTENAY",
    value: "41234",
    type: "common",
  },
  {
    label: "SAINT-ETIENNE-DES-GUERETS",
    value: "41208",
    type: "common",
  },
  {
    label: "SAINT-NICOLAS-DES-MOTETS",
    value: "37229",
    type: "common",
  },
  {
    label: "MORAND",
    value: "37160",
    type: "common",
  },
  {
    label: "SAUNAY",
    value: "37240",
    type: "common",
  },
  {
    label: "CHATEAU-RENAULT",
    value: "37063",
    type: "common",
  },
  {
    label: "AUZOUER-EN-TOURAINE",
    value: "37010",
    type: "common",
  },
  {
    label: "LE BOULAY",
    value: "37030",
    type: "common",
  },
  {
    label: "SAINT-LAURENT-EN-GATINES",
    value: "37224",
    type: "common",
  },
  {
    label: "BEAUMONT-LOUESTAULT",
    value: "37021",
    type: "common",
  },
  {
    label: "NEUVY-LE-ROI",
    value: "37170",
    type: "common",
  },
  {
    label: "SAINT-PATERNE-RACAN",
    value: "37231",
    type: "common",
  },
  {
    label: "CHENU",
    value: "72077",
    type: "common",
  },
  {
    label: "BRECHES",
    value: "37037",
    type: "common",
  },
  {
    label: "VILLIERS-AU-BOUIN",
    value: "37279",
    type: "common",
  },
  {
    label: "COUESMES",
    value: "37084",
    type: "common",
  },
  {
    label: "BRAYE-SUR-MAULNE",
    value: "37036",
    type: "common",
  },
  {
    label: "BAUGE-EN-ANJOU",
    value: "49018",
    type: "common",
  },
  {
    label: "JARZE VILLAGES",
    value: "49163",
    type: "common",
  },
  {
    label: "MARCE",
    value: "49188",
    type: "common",
  },
  {
    label: "MONTREUIL-SUR-LOIR",
    value: "49216",
    type: "common",
  },
  {
    label: "CORZE",
    value: "49110",
    type: "common",
  },
  {
    label: "BRIOLLAY",
    value: "49048",
    type: "common",
  },
  {
    label: "SOULAIRE-ET-BOURG",
    value: "49339",
    type: "common",
  },
  {
    label: "CANTENAY-EPINARD",
    value: "49055",
    type: "common",
  },
  {
    label: "MONTREUIL-JUIGNE",
    value: "49214",
    type: "common",
  },
  {
    label: "LONGUENEE-EN-ANJOU",
    value: "49200",
    type: "common",
  },
  {
    label: "SAINT-CLEMENT-DE-LA-PLACE",
    value: "49271",
    type: "common",
  },
  {
    label: "VAL D'ERDRE-AUXENCE",
    value: "49183",
    type: "common",
  },
  {
    label: "CANDE",
    value: "49054",
    type: "common",
  },
  {
    label: "ANGRIE",
    value: "49008",
    type: "common",
  },
  {
    label: "VALLONS-DE-L'ERDRE",
    value: "44180",
    type: "common",
  },
  {
    label: "RIAILLE",
    value: "44144",
    type: "common",
  },
  {
    label: "JOUE-SUR-ERDRE",
    value: "44077",
    type: "common",
  },
  {
    label: "ABBARETZ",
    value: "44001",
    type: "common",
  },
  {
    label: "PUCEUL",
    value: "44138",
    type: "common",
  },
  {
    label: "LA GRIGONNAIS",
    value: "44224",
    type: "common",
  },
  {
    label: "VAY",
    value: "44214",
    type: "common",
  },
  {
    label: "LE GAVRE",
    value: "44062",
    type: "common",
  },
  {
    label: "GUENROUET",
    value: "44068",
    type: "common",
  },
  {
    label: "SAINT-GILDAS-DES-BOIS",
    value: "44161",
    type: "common",
  },
  {
    label: "LA ROCHE-BERNARD",
    value: "56195",
    type: "common",
  },
  {
    label: "FEREL",
    value: "56058",
    type: "common",
  },
  {
    label: "CAMOEL",
    value: "56030",
    type: "common",
  },
  {
    label: "ARZAL",
    value: "56004",
    type: "common",
  },
  {
    label: "PENESTIN",
    value: "56155",
    type: "common",
  },
  {
    label: "BILLIERS",
    value: "56018",
    type: "common",
  },
  {
    label: "QUIBERON",
    value: "56186",
    type: "common",
  },
  {
    label: "KIFFIS",
    value: "68165",
    type: "common",
  },
  {
    label: "WINKEL",
    value: "68373",
    type: "common",
  },
  {
    label: "OBERLARG",
    value: "68243",
    type: "common",
  },
  {
    label: "LUCELLE",
    value: "68190",
    type: "common",
  },
  {
    label: "LEVONCOURT",
    value: "68181",
    type: "common",
  },
  {
    label: "VILLARS-LE-SEC",
    value: "90105",
    type: "common",
  },
  {
    label: "SAINT-DIZIER-L'EVEQUE",
    value: "90090",
    type: "common",
  },
  {
    label: "MONTBOUTON",
    value: "90070",
    type: "common",
  },
  {
    label: "CROIX",
    value: "90030",
    type: "common",
  },
  {
    label: "VANDONCOURT",
    value: "25586",
    type: "common",
  },
  {
    label: "MESLIERES",
    value: "25378",
    type: "common",
  },
  {
    label: "HERIMONCOURT",
    value: "25304",
    type: "common",
  },
  {
    label: "ABBEVILLERS",
    value: "25004",
    type: "common",
  },
  {
    label: "VALENTIGNEY",
    value: "25580",
    type: "common",
  },
  {
    label: "THULAY",
    value: "25562",
    type: "common",
  },
  {
    label: "SELONCOURT",
    value: "25539",
    type: "common",
  },
  {
    label: "MANDEURE",
    value: "25367",
    type: "common",
  },
  {
    label: "BONDEVAL",
    value: "25071",
    type: "common",
  },
  {
    label: "VOUJEAUCOURT",
    value: "25632",
    type: "common",
  },
  {
    label: "MATHAY",
    value: "25370",
    type: "common",
  },
  {
    label: "ETOUVANS",
    value: "25224",
    type: "common",
  },
  {
    label: "ECOT",
    value: "25214",
    type: "common",
  },
  {
    label: "DAMPIERRE-SUR-LE-DOUBS",
    value: "25191",
    type: "common",
  },
  {
    label: "BERCHE",
    value: "25054",
    type: "common",
  },
  {
    label: "BAVANS",
    value: "25048",
    type: "common",
  },
  {
    label: "SAINT-MAURICE-COLOMBIER",
    value: "25524",
    type: "common",
  },
  {
    label: "LOUGRES",
    value: "25350",
    type: "common",
  },
  {
    label: "LONGEVELLE-SUR-DOUBS",
    value: "25345",
    type: "common",
  },
  {
    label: "COLOMBIER-FONTAINE",
    value: "25159",
    type: "common",
  },
  {
    label: "BEUTAL",
    value: "25059",
    type: "common",
  },
  {
    label: "LA PRETIERE",
    value: "25470",
    type: "common",
  },
  {
    label: "MEDIERE",
    value: "25372",
    type: "common",
  },
  {
    label: "MANCENANS",
    value: "25365",
    type: "common",
  },
  {
    label: "L'ISLE-SUR-LE-DOUBS",
    value: "25315",
    type: "common",
  },
  {
    label: "GENEY",
    value: "25266",
    type: "common",
  },
  {
    label: "FAIMBE",
    value: "25232",
    type: "common",
  },
  {
    label: "ETRAPPE",
    value: "25226",
    type: "common",
  },
  {
    label: "BLUSSANGEAUX",
    value: "25066",
    type: "common",
  },
  {
    label: "APPENANS",
    value: "25019",
    type: "common",
  },
  {
    label: "SOYE",
    value: "25553",
    type: "common",
  },
  {
    label: "GONDENANS-MONTBY",
    value: "25276",
    type: "common",
  },
  {
    label: "BOURNOIS",
    value: "25083",
    type: "common",
  },
  {
    label: "UZELLE",
    value: "25574",
    type: "common",
  },
  {
    label: "ROMAIN",
    value: "25499",
    type: "common",
  },
  {
    label: "NANS",
    value: "25419",
    type: "common",
  },
  {
    label: "GOUHELANS",
    value: "25279",
    type: "common",
  },
  {
    label: "GONDENANS-LES-MOULINS",
    value: "25277",
    type: "common",
  },
  {
    label: "FONTENELLE-MONTBY",
    value: "25247",
    type: "common",
  },
  {
    label: "CUSE-ET-ADRISANS",
    value: "25184",
    type: "common",
  },
  {
    label: "THIENANS",
    value: "70501",
    type: "common",
  },
  {
    label: "BOUHANS-LES-MONTBOZON",
    value: "70082",
    type: "common",
  },
  {
    label: "ROUGEMONT",
    value: "25505",
    type: "common",
  },
  {
    label: "MONTAGNEY-SERVIGNEY",
    value: "25385",
    type: "common",
  },
  {
    label: "MONDON",
    value: "25384",
    type: "common",
  },
  {
    label: "ROCHE-SUR-LINOTTE-ET-SORANS-LES-CORDIERS",
    value: "70449",
    type: "common",
  },
  {
    label: "ORMENANS",
    value: "70397",
    type: "common",
  },
  {
    label: "MONTBOZON",
    value: "70357",
    type: "common",
  },
  {
    label: "LOULANS-VERCHAMP",
    value: "70309",
    type: "common",
  },
  {
    label: "FONTENOIS-LES-MONTBOZON",
    value: "70243",
    type: "common",
  },
  {
    label: "BESNANS",
    value: "70065",
    type: "common",
  },
  {
    label: "VILLERS-PATER",
    value: "70565",
    type: "common",
  },
  {
    label: "RUHANS",
    value: "70456",
    type: "common",
  },
  {
    label: "QUENOCHE",
    value: "70431",
    type: "common",
  },
  {
    label: "AUTHOISON",
    value: "70038",
    type: "common",
  },
  {
    label: "PENNESIERES",
    value: "70405",
    type: "common",
  },
  {
    label: "LA MALACHERE",
    value: "70326",
    type: "common",
  },
  {
    label: "HYET",
    value: "70288",
    type: "common",
  },
  {
    label: "FONDREMAND",
    value: "70239",
    type: "common",
  },
  {
    label: "VILLERS-BOUTON",
    value: "70560",
    type: "common",
  },
  {
    label: "RECOLOGNE-LES-RIOZ",
    value: "70441",
    type: "common",
  },
  {
    label: "MAIZIERES",
    value: "70325",
    type: "common",
  },
  {
    label: "FRETIGNEY-ET-VELLOREILLE",
    value: "70257",
    type: "common",
  },
  {
    label: "VAUX-LE-MONCELOT",
    value: "70527",
    type: "common",
  },
  {
    label: "FRASNE-LE-CHATEAU",
    value: "70253",
    type: "common",
  },
  {
    label: "ETRELLES-ET-LA-MONTBLEUSE",
    value: "70222",
    type: "common",
  },
  {
    label: "LES BATIES",
    value: "70053",
    type: "common",
  },
  {
    label: "VELLEMOZ",
    value: "70538",
    type: "common",
  },
  {
    label: "SAINTE-REINE",
    value: "70471",
    type: "common",
  },
  {
    label: "SAINT-GAND",
    value: "70463",
    type: "common",
  },
  {
    label: "IGNY",
    value: "70289",
    type: "common",
  },
  {
    label: "LA CHAPELLE-SAINT-QUILLAIN",
    value: "70129",
    type: "common",
  },
  {
    label: "SAUVIGNEY-LES-GRAY",
    value: "70479",
    type: "common",
  },
  {
    label: "SAINT-BROING",
    value: "70461",
    type: "common",
  },
  {
    label: "BEAUJEU-SAINT-VALLIER-PIERREJUX-ET-QUITTEUR",
    value: "70058",
    type: "common",
  },
  {
    label: "RIGNY",
    value: "70446",
    type: "common",
  },
  {
    label: "MONTUREUX-ET-PRANTIGNY",
    value: "70371",
    type: "common",
  },
  {
    label: "CHARGEY-LES-GRAY",
    value: "70132",
    type: "common",
  },
  {
    label: "ARC-LES-GRAY",
    value: "70026",
    type: "common",
  },
  {
    label: "BOUHANS-ET-FEURG",
    value: "70080",
    type: "common",
  },
  {
    label: "AUVET-ET-LA-CHAPELOTTE",
    value: "70043",
    type: "common",
  },
  {
    label: "FAHY-LES-AUTREY",
    value: "70225",
    type: "common",
  },
  {
    label: "BROYE-LES-LOUPS-ET-VERFONTAINE",
    value: "70100",
    type: "common",
  },
  {
    label: "ATTRICOURT",
    value: "70032",
    type: "common",
  },
  {
    label: "SAINT-SEINE-SUR-VINGEANNE",
    value: "21574",
    type: "common",
  },
  {
    label: "LŒUILLEY",
    value: "70305",
    type: "common",
  },
  {
    label: "LICEY-SUR-VINGEANNE",
    value: "21348",
    type: "common",
  },
  {
    label: "FONTENELLE",
    value: "21281",
    type: "common",
  },
  {
    label: "DAMPIERRE-ET-FLEE",
    value: "21225",
    type: "common",
  },
  {
    label: "BOURBERAIN",
    value: "21094",
    type: "common",
  },
  {
    label: "TIL-CHATEL",
    value: "21638",
    type: "common",
  },
  {
    label: "LUX",
    value: "21361",
    type: "common",
  },
  {
    label: "GEMEAUX",
    value: "21290",
    type: "common",
  },
  {
    label: "MARCILLY-SUR-TILLE",
    value: "21383",
    type: "common",
  },
  {
    label: "IS-SUR-TILLE",
    value: "21317",
    type: "common",
  },
  {
    label: "VILLECOMTE",
    value: "21692",
    type: "common",
  },
  {
    label: "TARSUL",
    value: "21620",
    type: "common",
  },
  {
    label: "DIENAY",
    value: "21230",
    type: "common",
  },
  {
    label: "VERNOT",
    value: "21666",
    type: "common",
  },
  {
    label: "FRENOIS",
    value: "21286",
    type: "common",
  },
  {
    label: "LAMARGELLE",
    value: "21338",
    type: "common",
  },
  {
    label: "PONCEY-SUR-L'IGNON",
    value: "21494",
    type: "common",
  },
  {
    label: "PELLEREY",
    value: "21479",
    type: "common",
  },
  {
    label: "CHANCEAUX",
    value: "21142",
    type: "common",
  },
  {
    label: "FROLOIS",
    value: "21288",
    type: "common",
  },
  {
    label: "SOURCE-SEINE",
    value: "21084",
    type: "common",
  },
  {
    label: "THENISSEY",
    value: "21627",
    type: "common",
  },
  {
    label: "HAUTEROCHE",
    value: "21314",
    type: "common",
  },
  {
    label: "GISSEY-SOUS-FLAVIGNY",
    value: "21299",
    type: "common",
  },
  {
    label: "POUILLENAY",
    value: "21500",
    type: "common",
  },
  {
    label: "FLAVIGNY-SUR-OZERAIN",
    value: "21271",
    type: "common",
  },
  {
    label: "ALISE-SAINTE-REINE",
    value: "21008",
    type: "common",
  },
  {
    label: "VILLARS-ET-VILLENOTTE",
    value: "21689",
    type: "common",
  },
  {
    label: "VENAREY-LES-LAUMES",
    value: "21663",
    type: "common",
  },
  {
    label: "SOUHEY",
    value: "21612",
    type: "common",
  },
  {
    label: "MUSSY-LA-FOSSE",
    value: "21448",
    type: "common",
  },
  {
    label: "MASSINGY-LES-SEMUR",
    value: "21394",
    type: "common",
  },
  {
    label: "LANTILLY",
    value: "21341",
    type: "common",
  },
  {
    label: "JUILLY",
    value: "21329",
    type: "common",
  },
  {
    label: "SEMUR-EN-AUXOIS",
    value: "21603",
    type: "common",
  },
  {
    label: "MILLERY",
    value: "21413",
    type: "common",
  },
  {
    label: "GENAY",
    value: "21291",
    type: "common",
  },
  {
    label: "TORCY-ET-POULIGNY",
    value: "21640",
    type: "common",
  },
  {
    label: "JEUX-LES-BARD",
    value: "21324",
    type: "common",
  },
  {
    label: "CORSAINT",
    value: "21199",
    type: "common",
  },
  {
    label: "CORROMBLES",
    value: "21198",
    type: "common",
  },
  {
    label: "BARD-LES-EPOISSES",
    value: "21047",
    type: "common",
  },
  {
    label: "TOUTRY",
    value: "21642",
    type: "common",
  },
  {
    label: "EPOISSES",
    value: "21247",
    type: "common",
  },
  {
    label: "SAVIGNY-EN-TERRE-PLAINE",
    value: "89379",
    type: "common",
  },
  {
    label: "MONTREAL",
    value: "89267",
    type: "common",
  },
  {
    label: "SAUVIGNY-LE-BOIS",
    value: "89378",
    type: "common",
  },
  {
    label: "PROVENCY",
    value: "89316",
    type: "common",
  },
  {
    label: "ATHIE",
    value: "89022",
    type: "common",
  },
  {
    label: "VAULT-DE-LUGNY",
    value: "89433",
    type: "common",
  },
  {
    label: "THAROT",
    value: "89410",
    type: "common",
  },
  {
    label: "ETAULE",
    value: "89159",
    type: "common",
  },
  {
    label: "ANNEOT",
    value: "89011",
    type: "common",
  },
  {
    label: "SERMIZELLES",
    value: "89392",
    type: "common",
  },
  {
    label: "GIVRY",
    value: "89190",
    type: "common",
  },
  {
    label: "GIROLLES",
    value: "89188",
    type: "common",
  },
  {
    label: "BLANNAY",
    value: "89044",
    type: "common",
  },
  {
    label: "MONTILLOT",
    value: "89266",
    type: "common",
  },
  {
    label: "BROSSES",
    value: "89057",
    type: "common",
  },
  {
    label: "BOIS-D'ARCY",
    value: "89049",
    type: "common",
  },
  {
    label: "CHATEL-CENSOIR",
    value: "89091",
    type: "common",
  },
  {
    label: "LUCY-SUR-YONNE",
    value: "89234",
    type: "common",
  },
  {
    label: "FESTIGNY",
    value: "89164",
    type: "common",
  },
  {
    label: "CRAIN",
    value: "89129",
    type: "common",
  },
  {
    label: "COULANGES-SUR-YONNE",
    value: "89119",
    type: "common",
  },
  {
    label: "SURGY",
    value: "58282",
    type: "common",
  },
  {
    label: "POUSSEAUX",
    value: "58217",
    type: "common",
  },
  {
    label: "DRUYES-LES-BELLES-FONTAINES",
    value: "89148",
    type: "common",
  },
  {
    label: "ANDRYES",
    value: "89007",
    type: "common",
  },
  {
    label: "ETAIS-LA-SAUVIN",
    value: "89158",
    type: "common",
  },
  {
    label: "SAINPUITS",
    value: "89331",
    type: "common",
  },
  {
    label: "LAINSECQ",
    value: "89216",
    type: "common",
  },
  {
    label: "TREIGNY-PERREUSE-SAINTE-COLOMBE",
    value: "89420",
    type: "common",
  },
  {
    label: "SAINT-AMAND-EN-PUISAYE",
    value: "58227",
    type: "common",
  },
  {
    label: "DAMPIERRE-SOUS-BOUHY",
    value: "58094",
    type: "common",
  },
  {
    label: "ARQUIAN",
    value: "58012",
    type: "common",
  },
  {
    label: "NEUVY-SUR-LOIRE",
    value: "58193",
    type: "common",
  },
  {
    label: "ANNAY",
    value: "58007",
    type: "common",
  },
  {
    label: "BEAULIEU-SUR-LOIRE",
    value: "45029",
    type: "common",
  },
  {
    label: "SANTRANGES",
    value: "18243",
    type: "common",
  },
  {
    label: "PIERREFITTE-ES-BOIS",
    value: "45251",
    type: "common",
  },
  {
    label: "CERNOY-EN-BERRY",
    value: "45064",
    type: "common",
  },
  {
    label: "BLANCAFORT",
    value: "18030",
    type: "common",
  },
  {
    label: "SAINTE-MONTAINE",
    value: "18227",
    type: "common",
  },
  {
    label: "CLEMONT",
    value: "18067",
    type: "common",
  },
  {
    label: "PIERREFITTE-SUR-SAULDRE",
    value: "41176",
    type: "common",
  },
  {
    label: "NOUAN-LE-FUZELIER",
    value: "41161",
    type: "common",
  },
  {
    label: "SAINT-VIATRE",
    value: "41231",
    type: "common",
  },
  {
    label: "LA FERTE-BEAUHARNAIS",
    value: "41083",
    type: "common",
  },
  {
    label: "NEUNG-SUR-BEUVRON",
    value: "41159",
    type: "common",
  },
  {
    label: "MONTRIEUX-EN-SOLOGNE",
    value: "41152",
    type: "common",
  },
  {
    label: "BAUZY",
    value: "41013",
    type: "common",
  },
  {
    label: "TOUR-EN-SOLOGNE",
    value: "41262",
    type: "common",
  },
  {
    label: "BRACIEUX",
    value: "41025",
    type: "common",
  },
  {
    label: "MONT-PRES-CHAMBORD",
    value: "41150",
    type: "common",
  },
  {
    label: "COUR-CHEVERNY",
    value: "41067",
    type: "common",
  },
  {
    label: "SEUR",
    value: "41246",
    type: "common",
  },
  {
    label: "SAINT-GERVAIS-LA-FORET",
    value: "41212",
    type: "common",
  },
  {
    label: "CHAILLES",
    value: "41032",
    type: "common",
  },
  {
    label: "CELLETTES",
    value: "41031",
    type: "common",
  },
  {
    label: "LES MONTILS",
    value: "41147",
    type: "common",
  },
  {
    label: "VALLOIRE-SUR-CISSE",
    value: "41055",
    type: "common",
  },
  {
    label: "CANDE-SUR-BEUVRON",
    value: "41029",
    type: "common",
  },
  {
    label: "VEUZAIN-SUR-LOIRE",
    value: "41167",
    type: "common",
  },
  {
    label: "MESLAND",
    value: "41137",
    type: "common",
  },
  {
    label: "DAME-MARIE-LES-BOIS",
    value: "37095",
    type: "common",
  },
  {
    label: "AUTRECHE",
    value: "37009",
    type: "common",
  },
  {
    label: "NEUILLE-LE-LIERRE",
    value: "37166",
    type: "common",
  },
  {
    label: "VILLEDOMER",
    value: "37276",
    type: "common",
  },
  {
    label: "CROTELLES",
    value: "37092",
    type: "common",
  },
  {
    label: "NOUZILLY",
    value: "37175",
    type: "common",
  },
  {
    label: "MONNAIE",
    value: "37153",
    type: "common",
  },
  {
    label: "SAINT-ANTOINE-DU-ROCHER",
    value: "37206",
    type: "common",
  },
  {
    label: "ROUZIERS-DE-TOURAINE",
    value: "37204",
    type: "common",
  },
  {
    label: "CERELLES",
    value: "37047",
    type: "common",
  },
  {
    label: "SEMBLANCAY",
    value: "37245",
    type: "common",
  },
  {
    label: "NEUILLE-PONT-PIERRE",
    value: "37167",
    type: "common",
  },
  {
    label: "SONZAY",
    value: "37249",
    type: "common",
  },
  {
    label: "SOUVIGNE",
    value: "37251",
    type: "common",
  },
  {
    label: "SAINT-LAURENT-DE-LIN",
    value: "37223",
    type: "common",
  },
  {
    label: "COURCELLES-DE-TOURAINE",
    value: "37086",
    type: "common",
  },
  {
    label: "CHATEAU-LA-VALLIERE",
    value: "37062",
    type: "common",
  },
  {
    label: "MARCILLY-SUR-MAULNE",
    value: "37146",
    type: "common",
  },
  {
    label: "LUBLE",
    value: "37137",
    type: "common",
  },
  {
    label: "NOYANT-VILLAGES",
    value: "49228",
    type: "common",
  },
  {
    label: "SERMAISE",
    value: "49334",
    type: "common",
  },
  {
    label: "LES BOIS D'ANJOU",
    value: "49138",
    type: "common",
  },
  {
    label: "CORNILLE-LES-CAVES",
    value: "49107",
    type: "common",
  },
  {
    label: "SARRIGNE",
    value: "49326",
    type: "common",
  },
  {
    label: "VERRIERES-EN-ANJOU",
    value: "49323",
    type: "common",
  },
  {
    label: "SAINT-BARTHELEMY-D'ANJOU",
    value: "49267",
    type: "common",
  },
  {
    label: "LE PLESSIS-GRAMMOIRE",
    value: "49241",
    type: "common",
  },
  {
    label: "ECOUFLANT",
    value: "49129",
    type: "common",
  },
  {
    label: "BEAUCOUZE",
    value: "49020",
    type: "common",
  },
  {
    label: "AVRILLE",
    value: "49015",
    type: "common",
  },
  {
    label: "SAINT-LAMBERT-LA-POTHERIE",
    value: "49294",
    type: "common",
  },
  {
    label: "BECON-LES-GRANITS",
    value: "49026",
    type: "common",
  },
  {
    label: "POUILLE-LES-COTEAUX",
    value: "44134",
    type: "common",
  },
  {
    label: "TEILLE",
    value: "44202",
    type: "common",
  },
  {
    label: "PANNECE",
    value: "44118",
    type: "common",
  },
  {
    label: "TRANS-SUR-ERDRE",
    value: "44207",
    type: "common",
  },
  {
    label: "NORT-SUR-ERDRE",
    value: "44110",
    type: "common",
  },
  {
    label: "SAFFRE",
    value: "44149",
    type: "common",
  },
  {
    label: "LA CHEVALLERAIS",
    value: "44221",
    type: "common",
  },
  {
    label: "BLAIN",
    value: "44015",
    type: "common",
  },
  {
    label: "QUILLY",
    value: "44139",
    type: "common",
  },
  {
    label: "SAINTE-ANNE-SUR-BRIVET",
    value: "44152",
    type: "common",
  },
  {
    label: "DREFFEAC",
    value: "44053",
    type: "common",
  },
  {
    label: "PONTCHATEAU",
    value: "44129",
    type: "common",
  },
  {
    label: "SAINTE-REINE-DE-BRETAGNE",
    value: "44189",
    type: "common",
  },
  {
    label: "MISSILLAC",
    value: "44098",
    type: "common",
  },
  {
    label: "HERBIGNAC",
    value: "44072",
    type: "common",
  },
  {
    label: "LA CHAPELLE-DES-MARAIS",
    value: "44030",
    type: "common",
  },
  {
    label: "ASSERAC",
    value: "44006",
    type: "common",
  },
  {
    label: "PIRIAC-SUR-MER",
    value: "44125",
    type: "common",
  },
  {
    label: "ILE-D'HOUAT",
    value: "56086",
    type: "common",
  },
  {
    label: "SAUZON",
    value: "56241",
    type: "common",
  },
  {
    label: "MONTANCY",
    value: "25386",
    type: "common",
  },
  {
    label: "GLAY",
    value: "25274",
    type: "common",
  },
  {
    label: "DANNEMARIE",
    value: "25194",
    type: "common",
  },
  {
    label: "VILLARS-LES-BLAMONT",
    value: "25615",
    type: "common",
  },
  {
    label: "ROCHES-LES-BLAMONT",
    value: "25497",
    type: "common",
  },
  {
    label: "PIERREFONTAINE-LES-BLAMONT",
    value: "25452",
    type: "common",
  },
  {
    label: "ECURCEY",
    value: "25216",
    type: "common",
  },
  {
    label: "BLAMONT",
    value: "25063",
    type: "common",
  },
  {
    label: "AUTECHAUX-ROIDE",
    value: "25033",
    type: "common",
  },
  {
    label: "REMONDANS-VAIVRE",
    value: "25485",
    type: "common",
  },
  {
    label: "PONT-DE-ROIDE-VERMONDANS",
    value: "25463",
    type: "common",
  },
  {
    label: "NEUCHATEL-URTIERE",
    value: "25422",
    type: "common",
  },
  {
    label: "BOURGUIGNON",
    value: "25082",
    type: "common",
  },
  {
    label: "DAMBELIN",
    value: "25187",
    type: "common",
  },
  {
    label: "VILLARS-SOUS-ECOT",
    value: "25618",
    type: "common",
  },
  {
    label: "SOURANS",
    value: "25552",
    type: "common",
  },
  {
    label: "LANTHENANS",
    value: "25327",
    type: "common",
  },
  {
    label: "HYEMONDANS",
    value: "25311",
    type: "common",
  },
  {
    label: "GOUX-LES-DAMBELIN",
    value: "25281",
    type: "common",
  },
  {
    label: "BLUSSANS",
    value: "25067",
    type: "common",
  },
  {
    label: "SAINT-GEORGES-ARMONT",
    value: "25516",
    type: "common",
  },
  {
    label: "RANG",
    value: "25479",
    type: "common",
  },
  {
    label: "ANTEUIL",
    value: "25018",
    type: "common",
  },
  {
    label: "POMPIERRE-SUR-DOUBS",
    value: "25461",
    type: "common",
  },
  {
    label: "L'HOPITAL-SAINT-LIEFFROY",
    value: "25306",
    type: "common",
  },
  {
    label: "FONTAINE-LES-CLERVAL",
    value: "25246",
    type: "common",
  },
  {
    label: "BRANNE",
    value: "25087",
    type: "common",
  },
  {
    label: "VOILLANS",
    value: "25629",
    type: "common",
  },
  {
    label: "VIETHOREY",
    value: "25613",
    type: "common",
  },
  {
    label: "VERNE",
    value: "25604",
    type: "common",
  },
  {
    label: "VERGRANNE",
    value: "25602",
    type: "common",
  },
  {
    label: "RILLANS",
    value: "25492",
    type: "common",
  },
  {
    label: "MESANDANS",
    value: "25377",
    type: "common",
  },
  {
    label: "HYEVRE-PAROISSE",
    value: "25313",
    type: "common",
  },
  {
    label: "AUTECHAUX",
    value: "25032",
    type: "common",
  },
  {
    label: "TROUVANS",
    value: "25572",
    type: "common",
  },
  {
    label: "TOURNANS",
    value: "25567",
    type: "common",
  },
  {
    label: "TALLANS",
    value: "25556",
    type: "common",
  },
  {
    label: "ROGNON",
    value: "25498",
    type: "common",
  },
  {
    label: "PUESSANS",
    value: "25472",
    type: "common",
  },
  {
    label: "MONTUSSAINT",
    value: "25408",
    type: "common",
  },
  {
    label: "LUXIOL",
    value: "25354",
    type: "common",
  },
  {
    label: "HUANNE-MONTMARTIN",
    value: "25310",
    type: "common",
  },
  {
    label: "BATTENANS-LES-MINES",
    value: "25045",
    type: "common",
  },
  {
    label: "AVILLEY",
    value: "25038",
    type: "common",
  },
  {
    label: "MAUSSANS",
    value: "70335",
    type: "common",
  },
  {
    label: "LARIANS-ET-MUNANS",
    value: "70296",
    type: "common",
  },
  {
    label: "CENANS",
    value: "70113",
    type: "common",
  },
  {
    label: "LA TOUR-DE-SCAY",
    value: "25566",
    type: "common",
  },
  {
    label: "ROUGEMONTOT",
    value: "25506",
    type: "common",
  },
  {
    label: "RIGNOSOT",
    value: "25491",
    type: "common",
  },
  {
    label: "OLLANS",
    value: "25430",
    type: "common",
  },
  {
    label: "GERMONDANS",
    value: "25269",
    type: "common",
  },
  {
    label: "FLAGEY-RIGNEY",
    value: "25242",
    type: "common",
  },
  {
    label: "CENDREY",
    value: "25107",
    type: "common",
  },
  {
    label: "VANDELANS",
    value: "70519",
    type: "common",
  },
  {
    label: "TRAITIEFONTAINE",
    value: "70503",
    type: "common",
  },
  {
    label: "CIREY",
    value: "70154",
    type: "common",
  },
  {
    label: "CHAMBORNAY-LES-BELLEVAUX",
    value: "70118",
    type: "common",
  },
  {
    label: "BEAUMOTTE-AUBERTANS",
    value: "70059",
    type: "common",
  },
  {
    label: "LA BARRE",
    value: "70050",
    type: "common",
  },
  {
    label: "VALLEROY",
    value: "25582",
    type: "common",
  },
  {
    label: "RIGNEY",
    value: "25490",
    type: "common",
  },
  {
    label: "BLARIANS",
    value: "25065",
    type: "common",
  },
  {
    label: "TRESILLEY",
    value: "70507",
    type: "common",
  },
  {
    label: "SORANS-LES-BREUREY",
    value: "70493",
    type: "common",
  },
  {
    label: "RIOZ",
    value: "70447",
    type: "common",
  },
  {
    label: "NEUVELLE-LES-CROMARY",
    value: "70383",
    type: "common",
  },
  {
    label: "OISELAY-ET-GRACHAUX",
    value: "70393",
    type: "common",
  },
  {
    label: "MONTARLOT-LES-RIOZ",
    value: "70355",
    type: "common",
  },
  {
    label: "CORDONNET",
    value: "70174",
    type: "common",
  },
  {
    label: "BONNEVENT-VELLOREILLE",
    value: "70076",
    type: "common",
  },
  {
    label: "VELLECLAIRE",
    value: "70531",
    type: "common",
  },
  {
    label: "VANTOUX-ET-LONGEVELLE",
    value: "70521",
    type: "common",
  },
  {
    label: "VILLERS-CHEMIN-ET-MONT-LES-ETRELLES",
    value: "70366",
    type: "common",
  },
  {
    label: "BUCEY-LES-GY",
    value: "70104",
    type: "common",
  },
  {
    label: "VELLEFREY-ET-VELLEFRANGE",
    value: "70533",
    type: "common",
  },
  {
    label: "GY",
    value: "70282",
    type: "common",
  },
  {
    label: "CITEY",
    value: "70156",
    type: "common",
  },
  {
    label: "ANGIREY",
    value: "70022",
    type: "common",
  },
  {
    label: "VILLEFRANCON",
    value: "70557",
    type: "common",
  },
  {
    label: "VELESMES-ECHEVANNE",
    value: "70528",
    type: "common",
  },
  {
    label: "SAINT-LOUP-NANTOUARD",
    value: "70466",
    type: "common",
  },
  {
    label: "GRAY",
    value: "70279",
    type: "common",
  },
  {
    label: "CRESANCEY",
    value: "70185",
    type: "common",
  },
  {
    label: "BATTRANS",
    value: "70054",
    type: "common",
  },
  {
    label: "ANCIER",
    value: "70018",
    type: "common",
  },
  {
    label: "VELET",
    value: "70529",
    type: "common",
  },
  {
    label: "NANTILLY",
    value: "70376",
    type: "common",
  },
  {
    label: "MANTOCHE",
    value: "70331",
    type: "common",
  },
  {
    label: "GRAY-LA-VILLE",
    value: "70280",
    type: "common",
  },
  {
    label: "ESMOULINS",
    value: "70218",
    type: "common",
  },
  {
    label: "RENEVE",
    value: "21522",
    type: "common",
  },
  {
    label: "CHAMPAGNE-SUR-VINGEANNE",
    value: "21135",
    type: "common",
  },
  {
    label: "POYANS",
    value: "70422",
    type: "common",
  },
  {
    label: "AUTREY-LES-GRAY",
    value: "70041",
    type: "common",
  },
  {
    label: "OISILLY",
    value: "21467",
    type: "common",
  },
  {
    label: "BLAGNY-SUR-VINGEANNE",
    value: "21079",
    type: "common",
  },
  {
    label: "BEAUMONT-SUR-VINGEANNE",
    value: "21053",
    type: "common",
  },
  {
    label: "VIEVIGNE",
    value: "21682",
    type: "common",
  },
  {
    label: "TANAY",
    value: "21619",
    type: "common",
  },
  {
    label: "NOIRON-SUR-BEZE",
    value: "21459",
    type: "common",
  },
  {
    label: "BEZE",
    value: "21071",
    type: "common",
  },
  {
    label: "BEIRE-LE-CHATEL",
    value: "21056",
    type: "common",
  },
  {
    label: "SPOY",
    value: "21614",
    type: "common",
  },
  {
    label: "PICHANGES",
    value: "21483",
    type: "common",
  },
  {
    label: "BROGNON",
    value: "21111",
    type: "common",
  },
  {
    label: "MARSANNAY-LE-BOIS",
    value: "21391",
    type: "common",
  },
  {
    label: "FLACEY",
    value: "21266",
    type: "common",
  },
  {
    label: "CLENAY",
    value: "21179",
    type: "common",
  },
  {
    label: "SAVIGNY-LE-SEC",
    value: "21591",
    type: "common",
  },
  {
    label: "SAUSSY",
    value: "21589",
    type: "common",
  },
  {
    label: "MESSIGNY-ET-VANTOUX",
    value: "21408",
    type: "common",
  },
  {
    label: "EPAGNY",
    value: "21245",
    type: "common",
  },
  {
    label: "CHAIGNAY",
    value: "21127",
    type: "common",
  },
  {
    label: "VAL-SUZON",
    value: "21651",
    type: "common",
  },
  {
    label: "CURTIL-SAINT-SEINE",
    value: "21218",
    type: "common",
  },
  {
    label: "VAUX-SAULES",
    value: "21659",
    type: "common",
  },
  {
    label: "FRANCHEVILLE",
    value: "21284",
    type: "common",
  },
  {
    label: "VILLOTTE-SAINT-SEINE",
    value: "21705",
    type: "common",
  },
  {
    label: "SAINT-SEINE-L'ABBAYE",
    value: "21573",
    type: "common",
  },
  {
    label: "CHAMPAGNY",
    value: "21136",
    type: "common",
  },
  {
    label: "BLIGNY-LE-SEC",
    value: "21085",
    type: "common",
  },
  {
    label: "VERREY-SOUS-SALMAISE",
    value: "21670",
    type: "common",
  },
  {
    label: "SALMAISE",
    value: "21580",
    type: "common",
  },
  {
    label: "BOUX-SOUS-SALMAISE",
    value: "21098",
    type: "common",
  },
  {
    label: "VILLEBERNY",
    value: "21690",
    type: "common",
  },
  {
    label: "LA ROCHE-VANNEAU",
    value: "21528",
    type: "common",
  },
  {
    label: "JAILLY-LES-MOULINS",
    value: "21321",
    type: "common",
  },
  {
    label: "DAMPIERRE-EN-MONTAGNE",
    value: "21224",
    type: "common",
  },
  {
    label: "VILLEFERRY",
    value: "21694",
    type: "common",
  },
  {
    label: "SAINTE-COLOMBE-EN-AUXOIS",
    value: "21544",
    type: "common",
  },
  {
    label: "BRAIN",
    value: "21100",
    type: "common",
  },
  {
    label: "ARNAY-SOUS-VITTEAUX",
    value: "21024",
    type: "common",
  },
  {
    label: "VILLENEUVE-SOUS-CHARIGNY",
    value: "21696",
    type: "common",
  },
  {
    label: "SAINT-EUPHRONE",
    value: "21547",
    type: "common",
  },
  {
    label: "MONTIGNY-SUR-ARMANCON",
    value: "21431",
    type: "common",
  },
  {
    label: "MARIGNY-LE-CAHOUET",
    value: "21386",
    type: "common",
  },
  {
    label: "MAGNY-LA-VILLE",
    value: "21365",
    type: "common",
  },
  {
    label: "CHASSEY",
    value: "21151",
    type: "common",
  },
  {
    label: "CHARIGNY",
    value: "21145",
    type: "common",
  },
  {
    label: "PONT-ET-MASSENE",
    value: "21497",
    type: "common",
  },
  {
    label: "COURCELLES-LES-SEMUR",
    value: "21205",
    type: "common",
  },
  {
    label: "VIC-DE-CHASSENAY",
    value: "21676",
    type: "common",
  },
  {
    label: "THOSTE",
    value: "21635",
    type: "common",
  },
  {
    label: "FORLEANS",
    value: "21282",
    type: "common",
  },
  {
    label: "COURCELLES-FREMOY",
    value: "21203",
    type: "common",
  },
  {
    label: "SAUVIGNY-LE-BEUREAL",
    value: "89377",
    type: "common",
  },
  {
    label: "VIEUX-CHATEAU",
    value: "21681",
    type: "common",
  },
  {
    label: "SINCEY-LES-ROUVRAY",
    value: "21608",
    type: "common",
  },
  {
    label: "MONTBERTHAULT",
    value: "21426",
    type: "common",
  },
  {
    label: "SAINTE-MAGNANCE",
    value: "89351",
    type: "common",
  },
  {
    label: "SAINT-ANDRE-EN-TERRE-PLAINE",
    value: "89333",
    type: "common",
  },
  {
    label: "CUSSY-LES-FORGES",
    value: "89134",
    type: "common",
  },
  {
    label: "MAGNY",
    value: "89235",
    type: "common",
  },
  {
    label: "PONTAUBERT",
    value: "89306",
    type: "common",
  },
  {
    label: "ISLAND",
    value: "89203",
    type: "common",
  },
  {
    label: "AVALLON",
    value: "89025",
    type: "common",
  },
  {
    label: "THAROISEAU",
    value: "89409",
    type: "common",
  },
  {
    label: "SAINT-PERE",
    value: "89364",
    type: "common",
  },
  {
    label: "MENADES",
    value: "89248",
    type: "common",
  },
  {
    label: "FOISSY-LES-VEZELAY",
    value: "89170",
    type: "common",
  },
  {
    label: "DOMECY-SUR-LE-VAULT",
    value: "89146",
    type: "common",
  },
  {
    label: "VEZELAY",
    value: "89446",
    type: "common",
  },
  {
    label: "ASQUINS",
    value: "89021",
    type: "common",
  },
  {
    label: "LICHERES-SUR-YONNE",
    value: "89225",
    type: "common",
  },
  {
    label: "CHAMOUX",
    value: "89071",
    type: "common",
  },
  {
    label: "ASNIERES-SOUS-BOIS",
    value: "89020",
    type: "common",
  },
  {
    label: "DORNECY",
    value: "58103",
    type: "common",
  },
  {
    label: "CLAMECY",
    value: "58079",
    type: "common",
  },
  {
    label: "CHEVROCHES",
    value: "58073",
    type: "common",
  },
  {
    label: "ARMES",
    value: "58011",
    type: "common",
  },
  {
    label: "OISY",
    value: "58198",
    type: "common",
  },
  {
    label: "BILLY-SUR-OISY",
    value: "58032",
    type: "common",
  },
  {
    label: "TRUCY-L'ORGUEILLEUX",
    value: "58299",
    type: "common",
  },
  {
    label: "ENTRAINS-SUR-NOHAIN",
    value: "58109",
    type: "common",
  },
  {
    label: "CIEZ",
    value: "58077",
    type: "common",
  },
  {
    label: "BOUHY",
    value: "58036",
    type: "common",
  },
  {
    label: "BITRY",
    value: "58033",
    type: "common",
  },
  {
    label: "ALLIGNY-COSNE",
    value: "58002",
    type: "common",
  },
  {
    label: "SAINT-VERAIN",
    value: "58270",
    type: "common",
  },
  {
    label: "SAINT-LOUP",
    value: "58251",
    type: "common",
  },
  {
    label: "MYENNES",
    value: "58187",
    type: "common",
  },
  {
    label: "LA CELLE-SUR-LOIRE",
    value: "58044",
    type: "common",
  },
  {
    label: "SURY-PRES-LERE",
    value: "18257",
    type: "common",
  },
  {
    label: "SAVIGNY-EN-SANCERRE",
    value: "18246",
    type: "common",
  },
  {
    label: "LERE",
    value: "18125",
    type: "common",
  },
  {
    label: "BELLEVILLE-SUR-LOIRE",
    value: "18026",
    type: "common",
  },
  {
    label: "SURY-ES-BOIS",
    value: "18259",
    type: "common",
  },
  {
    label: "VAILLY-SUR-SAULDRE",
    value: "18269",
    type: "common",
  },
  {
    label: "BARLIEU",
    value: "18022",
    type: "common",
  },
  {
    label: "DAMPIERRE-EN-CROT",
    value: "18084",
    type: "common",
  },
  {
    label: "CONCRESSAULT",
    value: "18070",
    type: "common",
  },
  {
    label: "OIZON",
    value: "18170",
    type: "common",
  },
  {
    label: "AUBIGNY-SUR-NERE",
    value: "18015",
    type: "common",
  },
  {
    label: "MENETREOL-SUR-SAULDRE",
    value: "18147",
    type: "common",
  },
  {
    label: "SOUESMES",
    value: "41249",
    type: "common",
  },
  {
    label: "MARCILLY-EN-GAULT",
    value: "41125",
    type: "common",
  },
  {
    label: "MILLANCAY",
    value: "41140",
    type: "common",
  },
  {
    label: "VERNOU-EN-SOLOGNE",
    value: "41271",
    type: "common",
  },
  {
    label: "COURMEMIN",
    value: "41068",
    type: "common",
  },
  {
    label: "FONTAINES-EN-SOLOGNE",
    value: "41086",
    type: "common",
  },
  {
    label: "CORMERAY",
    value: "41061",
    type: "common",
  },
  {
    label: "CHEVERNY",
    value: "41050",
    type: "common",
  },
  {
    label: "CHITENAY",
    value: "41052",
    type: "common",
  },
  {
    label: "VALAIRE",
    value: "41266",
    type: "common",
  },
  {
    label: "MONTHOU-SUR-BIEVRE",
    value: "41145",
    type: "common",
  },
  {
    label: "CHAUMONT-SUR-LOIRE",
    value: "41045",
    type: "common",
  },
  {
    label: "RILLY-SUR-LOIRE",
    value: "41189",
    type: "common",
  },
  {
    label: "MONTEAUX",
    value: "41144",
    type: "common",
  },
  {
    label: "MOSNES",
    value: "37161",
    type: "common",
  },
  {
    label: "CANGEY",
    value: "37043",
    type: "common",
  },
  {
    label: "SAINT-OUEN-LES-VIGNES",
    value: "37230",
    type: "common",
  },
  {
    label: "POCE-SUR-CISSE",
    value: "37185",
    type: "common",
  },
  {
    label: "LIMERAY",
    value: "37131",
    type: "common",
  },
  {
    label: "MONTREUIL-EN-TOURAINE",
    value: "37158",
    type: "common",
  },
  {
    label: "CHANCAY",
    value: "37052",
    type: "common",
  },
  {
    label: "VERNOU-SUR-BRENNE",
    value: "37270",
    type: "common",
  },
  {
    label: "REUGNY",
    value: "37194",
    type: "common",
  },
  {
    label: "PARCAY-MESLAY",
    value: "37179",
    type: "common",
  },
  {
    label: "NOTRE-DAME-D'OE",
    value: "37172",
    type: "common",
  },
  {
    label: "METTRAY",
    value: "37152",
    type: "common",
  },
  {
    label: "LA MEMBROLLE-SUR-CHOISILLE",
    value: "37151",
    type: "common",
  },
  {
    label: "CHANCEAUX-SUR-CHOISILLE",
    value: "37054",
    type: "common",
  },
  {
    label: "SAINT-ROCH",
    value: "37237",
    type: "common",
  },
  {
    label: "CHARENTILLY",
    value: "37059",
    type: "common",
  },
  {
    label: "PERNAY",
    value: "37182",
    type: "common",
  },
  {
    label: "AMBILLOU",
    value: "37002",
    type: "common",
  },
  {
    label: "CLERE-LES-PINS",
    value: "37081",
    type: "common",
  },
  {
    label: "SAVIGNE-SUR-LATHAN",
    value: "37241",
    type: "common",
  },
  {
    label: "RILLE",
    value: "37198",
    type: "common",
  },
  {
    label: "CHANNAY-SUR-LATHAN",
    value: "37055",
    type: "common",
  },
  {
    label: "LA PELLERINE",
    value: "49237",
    type: "common",
  },
  {
    label: "MOULIHERNE",
    value: "49221",
    type: "common",
  },
  {
    label: "LA LANDE-CHASLES",
    value: "49171",
    type: "common",
  },
  {
    label: "BEAUFORT-EN-ANJOU",
    value: "49021",
    type: "common",
  },
  {
    label: "LOIRE-AUTHION",
    value: "49307",
    type: "common",
  },
  {
    label: "MAZE-MILON",
    value: "49194",
    type: "common",
  },
  {
    label: "TRELAZE",
    value: "49353",
    type: "common",
  },
  {
    label: "SAINT-JEAN-DE-LA-CROIX",
    value: "49288",
    type: "common",
  },
  {
    label: "SAINTE-GEMMES-SUR-LOIRE",
    value: "49278",
    type: "common",
  },
  {
    label: "LES PONTS-DE-CE",
    value: "49246",
    type: "common",
  },
  {
    label: "ANGERS",
    value: "49007",
    type: "common",
  },
  {
    label: "SAVENNIERES",
    value: "49329",
    type: "common",
  },
  {
    label: "BOUCHEMAINE",
    value: "49035",
    type: "common",
  },
  {
    label: "SAINT-MARTIN-DU-FOUILLOUX",
    value: "49306",
    type: "common",
  },
  {
    label: "SAINT-GEORGES-SUR-LOIRE",
    value: "49283",
    type: "common",
  },
  {
    label: "SAINT-GERMAIN-DES-PRES",
    value: "49284",
    type: "common",
  },
  {
    label: "SAINT-AUGUSTIN-DES-BOIS",
    value: "49266",
    type: "common",
  },
  {
    label: "SAINT-SIGISMOND",
    value: "49321",
    type: "common",
  },
  {
    label: "INGRANDES-LE FRESNE SUR LOIRE",
    value: "49160",
    type: "common",
  },
  {
    label: "CHAMPTOCE-SUR-LOIRE",
    value: "49068",
    type: "common",
  },
  {
    label: "MONTRELAIS",
    value: "44104",
    type: "common",
  },
  {
    label: "VAIR-SUR-LOIRE",
    value: "44163",
    type: "common",
  },
  {
    label: "LA ROCHE-BLANCHE",
    value: "44222",
    type: "common",
  },
  {
    label: "MESANGER",
    value: "44096",
    type: "common",
  },
  {
    label: "MOUZEIL",
    value: "44107",
    type: "common",
  },
  {
    label: "COUFFE",
    value: "44048",
    type: "common",
  },
  {
    label: "LES TOUCHES",
    value: "44205",
    type: "common",
  },
  {
    label: "PETIT-MARS",
    value: "44122",
    type: "common",
  },
  {
    label: "LIGNE",
    value: "44082",
    type: "common",
  },
  {
    label: "HERIC",
    value: "44073",
    type: "common",
  },
  {
    label: "CASSON",
    value: "44027",
    type: "common",
  },
  {
    label: "FAY-DE-BRETAGNE",
    value: "44056",
    type: "common",
  },
  {
    label: "BOUVRON",
    value: "44023",
    type: "common",
  },
  {
    label: "CAMPBON",
    value: "44025",
    type: "common",
  },
  {
    label: "PRINQUIAU",
    value: "44137",
    type: "common",
  },
  {
    label: "BESNE",
    value: "44013",
    type: "common",
  },
  {
    label: "SAINT-JOACHIM",
    value: "44168",
    type: "common",
  },
  {
    label: "CROSSAC",
    value: "44050",
    type: "common",
  },
  {
    label: "SAINT-LYPHARD",
    value: "44175",
    type: "common",
  },
  {
    label: "SAINT-MOLF",
    value: "44183",
    type: "common",
  },
  {
    label: "MESQUER",
    value: "44097",
    type: "common",
  },
  {
    label: "HŒDIC",
    value: "56085",
    type: "common",
  },
  {
    label: "LE PALAIS",
    value: "56152",
    type: "common",
  },
  {
    label: "BANGOR",
    value: "56009",
    type: "common",
  },
  {
    label: "INDEVILLERS",
    value: "25314",
    type: "common",
  },
  {
    label: "GLERE",
    value: "25275",
    type: "common",
  },
  {
    label: "BURNEVILLERS",
    value: "25102",
    type: "common",
  },
  {
    label: "VAUFREY",
    value: "25591",
    type: "common",
  },
  {
    label: "LES PLAINS-ET-GRANDS-ESSARTS",
    value: "25458",
    type: "common",
  },
  {
    label: "MONTJOIE-LE-CHATEAU",
    value: "25402",
    type: "common",
  },
  {
    label: "COURTEFONTAINE",
    value: "25174",
    type: "common",
  },
  {
    label: "SOULCE-CERNAY",
    value: "25551",
    type: "common",
  },
  {
    label: "SAINT-HIPPOLYTE",
    value: "25519",
    type: "common",
  },
  {
    label: "MONTECHEROUX",
    value: "25393",
    type: "common",
  },
  {
    label: "MONTANDON",
    value: "25387",
    type: "common",
  },
  {
    label: "LIEBVILLERS",
    value: "25335",
    type: "common",
  },
  {
    label: "CHAMESOL",
    value: "25114",
    type: "common",
  },
  {
    label: "VILLARS-SOUS-DAMPJOUX",
    value: "25617",
    type: "common",
  },
  {
    label: "SOLEMONT",
    value: "25548",
    type: "common",
  },
  {
    label: "NOIREFONTAINE",
    value: "25426",
    type: "common",
  },
  {
    label: "FEULE",
    value: "25239",
    type: "common",
  },
  {
    label: "DAMPJOUX",
    value: "25192",
    type: "common",
  },
  {
    label: "LES TERRES-DE-CHAUX",
    value: "25138",
    type: "common",
  },
  {
    label: "BIEF",
    value: "25061",
    type: "common",
  },
  {
    label: "VYT-LES-BELVOIR",
    value: "25635",
    type: "common",
  },
  {
    label: "VERNOIS-LES-BELVOIR",
    value: "25607",
    type: "common",
  },
  {
    label: "VALONNE",
    value: "25583",
    type: "common",
  },
  {
    label: "ROSIERES-SUR-BARBECHE",
    value: "25503",
    type: "common",
  },
  {
    label: "PESEUX",
    value: "25449",
    type: "common",
  },
  {
    label: "BELVOIR",
    value: "25053",
    type: "common",
  },
  {
    label: "VELLEROT-LES-BELVOIR",
    value: "25595",
    type: "common",
  },
  {
    label: "RAHON",
    value: "25476",
    type: "common",
  },
  {
    label: "ORVE",
    value: "25436",
    type: "common",
  },
  {
    label: "CHAZOT",
    value: "25145",
    type: "common",
  },
  {
    label: "ROCHE-LES-CLERVAL",
    value: "25496",
    type: "common",
  },
  {
    label: "LOMONT-SUR-CRETE",
    value: "25341",
    type: "common",
  },
  {
    label: "CROSEY-LE-PETIT",
    value: "25178",
    type: "common",
  },
  {
    label: "CROSEY-LE-GRAND",
    value: "25177",
    type: "common",
  },
  {
    label: "VILLERS-SAINT-MARTIN",
    value: "25626",
    type: "common",
  },
  {
    label: "PONT-LES-MOULINS",
    value: "25465",
    type: "common",
  },
  {
    label: "HYEVRE-MAGNY",
    value: "25312",
    type: "common",
  },
  {
    label: "CUSANCE",
    value: "25183",
    type: "common",
  },
  {
    label: "VAL-DE-ROULANS",
    value: "25579",
    type: "common",
  },
  {
    label: "SILLEY-BLEFOND",
    value: "25546",
    type: "common",
  },
  {
    label: "SECHIN",
    value: "25538",
    type: "common",
  },
  {
    label: "GROSBOIS",
    value: "25298",
    type: "common",
  },
  {
    label: "FOURBANNE",
    value: "25251",
    type: "common",
  },
  {
    label: "FONTENOTTE",
    value: "25249",
    type: "common",
  },
  {
    label: "ESNANS",
    value: "25221",
    type: "common",
  },
  {
    label: "LA BRETENIERE",
    value: "25092",
    type: "common",
  },
  {
    label: "BRECONCHAUX",
    value: "25088",
    type: "common",
  },
  {
    label: "BAUME-LES-DAMES",
    value: "25047",
    type: "common",
  },
  {
    label: "VILLERS-GRELOT",
    value: "25624",
    type: "common",
  },
  {
    label: "VENNANS",
    value: "25599",
    type: "common",
  },
  {
    label: "SAINT-HILAIRE",
    value: "25518",
    type: "common",
  },
  {
    label: "LE PUY",
    value: "25474",
    type: "common",
  },
  {
    label: "POULIGNEY-LUSANS",
    value: "25468",
    type: "common",
  },
  {
    label: "L'ECOUVOTTE",
    value: "25215",
    type: "common",
  },
  {
    label: "CORCELLE-MIESLOT",
    value: "25163",
    type: "common",
  },
  {
    label: "AULX-LES-CROMARY",
    value: "70036",
    type: "common",
  },
  {
    label: "VENISE",
    value: "25598",
    type: "common",
  },
  {
    label: "THUREY-LE-MONT",
    value: "25563",
    type: "common",
  },
  {
    label: "PALISE",
    value: "25444",
    type: "common",
  },
  {
    label: "MONCEY",
    value: "25382",
    type: "common",
  },
  {
    label: "CHATILLON-GUYOTTE",
    value: "25132",
    type: "common",
  },
  {
    label: "CHAMPOUX",
    value: "25117",
    type: "common",
  },
  {
    label: "VORAY-SUR-L'OGNON",
    value: "70575",
    type: "common",
  },
  {
    label: "PERROUSE",
    value: "70407",
    type: "common",
  },
  {
    label: "CROMARY",
    value: "70189",
    type: "common",
  },
  {
    label: "BUTHIERS",
    value: "70109",
    type: "common",
  },
  {
    label: "BOULT",
    value: "70085",
    type: "common",
  },
  {
    label: "VIEILLEY",
    value: "25612",
    type: "common",
  },
  {
    label: "MEREY-VIEILLEY",
    value: "25376",
    type: "common",
  },
  {
    label: "BONNAY",
    value: "25073",
    type: "common",
  },
  {
    label: "ETUZ",
    value: "70224",
    type: "common",
  },
  {
    label: "CHAUX-LA-LOTIERE",
    value: "70145",
    type: "common",
  },
  {
    label: "BUSSIERES",
    value: "70107",
    type: "common",
  },
  {
    label: "BOULOT",
    value: "70084",
    type: "common",
  },
  {
    label: "CHEVROZ",
    value: "25153",
    type: "common",
  },
  {
    label: "GEZIER-ET-FONTENELAY",
    value: "70268",
    type: "common",
  },
  {
    label: "PIN",
    value: "70410",
    type: "common",
  },
  {
    label: "MONTBOILLON",
    value: "70356",
    type: "common",
  },
  {
    label: "CHAMBORNAY-LES-PIN",
    value: "70119",
    type: "common",
  },
  {
    label: "COURCUIRE",
    value: "70181",
    type: "common",
  },
  {
    label: "CHOYE",
    value: "70152",
    type: "common",
  },
  {
    label: "CHARCENNE",
    value: "70130",
    type: "common",
  },
  {
    label: "AVRIGNEY-VIREY",
    value: "70045",
    type: "common",
  },
  {
    label: "AUTOREILLE",
    value: "70039",
    type: "common",
  },
  {
    label: "VENERE",
    value: "70542",
    type: "common",
  },
  {
    label: "VELLOREILLE-LES-CHOYE",
    value: "70540",
    type: "common",
  },
  {
    label: "ONAY",
    value: "70394",
    type: "common",
  },
  {
    label: "CUGNEY",
    value: "70192",
    type: "common",
  },
  {
    label: "CHAMPTONNAY",
    value: "70124",
    type: "common",
  },
  {
    label: "BONBOILLON",
    value: "70075",
    type: "common",
  },
  {
    label: "VALAY",
    value: "70514",
    type: "common",
  },
  {
    label: "VADANS",
    value: "70510",
    type: "common",
  },
  {
    label: "LE TREMBLOIS",
    value: "70505",
    type: "common",
  },
  {
    label: "NOIRON",
    value: "70389",
    type: "common",
  },
  {
    label: "LIEUCOURT",
    value: "70302",
    type: "common",
  },
  {
    label: "CHAMPVANS",
    value: "70125",
    type: "common",
  },
  {
    label: "ARSANS",
    value: "70030",
    type: "common",
  },
  {
    label: "GERMIGNEY",
    value: "70265",
    type: "common",
  },
  {
    label: "APREMONT",
    value: "70024",
    type: "common",
  },
  {
    label: "JANCIGNY",
    value: "21323",
    type: "common",
  },
  {
    label: "ESSERTENNE-ET-CECEY",
    value: "70220",
    type: "common",
  },
  {
    label: "TALMAY",
    value: "21618",
    type: "common",
  },
  {
    label: "SAINT-SAUVEUR",
    value: "21571",
    type: "common",
  },
  {
    label: "MIREBEAU-SUR-BEZE",
    value: "21416",
    type: "common",
  },
  {
    label: "TROCHERES",
    value: "21644",
    type: "common",
  },
  {
    label: "CHARMES",
    value: "21146",
    type: "common",
  },
  {
    label: "CUISEREY",
    value: "21215",
    type: "common",
  },
  {
    label: "CHEUGE",
    value: "21167",
    type: "common",
  },
  {
    label: "BEZOUOTTE",
    value: "21072",
    type: "common",
  },
  {
    label: "MONTMANCON",
    value: "21437",
    type: "common",
  },
  {
    label: "SAVOLLES",
    value: "21595",
    type: "common",
  },
  {
    label: "MAGNY-SAINT-MEDARD",
    value: "21369",
    type: "common",
  },
  {
    label: "BELLENEUVE",
    value: "21060",
    type: "common",
  },
  {
    label: "ARCEAU",
    value: "21016",
    type: "common",
  },
  {
    label: "SAINT-JULIEN",
    value: "21555",
    type: "common",
  },
  {
    label: "ORGEUX",
    value: "21469",
    type: "common",
  },
  {
    label: "VAROIS-ET-CHAIGNOT",
    value: "21657",
    type: "common",
  },
  {
    label: "RUFFEY-LES-ECHIREY",
    value: "21535",
    type: "common",
  },
  {
    label: "NORGES-LA-VILLE",
    value: "21462",
    type: "common",
  },
  {
    label: "BRETIGNY",
    value: "21107",
    type: "common",
  },
  {
    label: "BELLEFOND",
    value: "21059",
    type: "common",
  },
  {
    label: "HAUTEVILLE-LES-DIJON",
    value: "21315",
    type: "common",
  },
  {
    label: "ASNIERES-LES-DIJON",
    value: "21027",
    type: "common",
  },
  {
    label: "AHUY",
    value: "21003",
    type: "common",
  },
  {
    label: "PRENOIS",
    value: "21508",
    type: "common",
  },
  {
    label: "ETAULES",
    value: "21255",
    type: "common",
  },
  {
    label: "DAROIS",
    value: "21227",
    type: "common",
  },
  {
    label: "SAINT-MARTIN-DU-MONT",
    value: "21561",
    type: "common",
  },
  {
    label: "PASQUES",
    value: "21478",
    type: "common",
  },
  {
    label: "PANGES",
    value: "21477",
    type: "common",
  },
  {
    label: "TURCEY",
    value: "21648",
    type: "common",
  },
  {
    label: "TROUHAUT",
    value: "21646",
    type: "common",
  },
  {
    label: "BLAISY-BAS",
    value: "21080",
    type: "common",
  },
  {
    label: "VILLY-EN-AUXOIS",
    value: "21707",
    type: "common",
  },
  {
    label: "VERREY-SOUS-DREE",
    value: "21669",
    type: "common",
  },
  {
    label: "SAINT-HELIER",
    value: "21552",
    type: "common",
  },
  {
    label: "CHEVANNAY",
    value: "21168",
    type: "common",
  },
  {
    label: "CHARENCEY",
    value: "21144",
    type: "common",
  },
  {
    label: "CHAMPRENAULT",
    value: "21141",
    type: "common",
  },
  {
    label: "AVOSNES",
    value: "21040",
    type: "common",
  },
  {
    label: "VITTEAUX",
    value: "21710",
    type: "common",
  },
  {
    label: "SAFFRES",
    value: "21537",
    type: "common",
  },
  {
    label: "MASSINGY-LES-VITTEAUX",
    value: "21395",
    type: "common",
  },
  {
    label: "MARCILLY-ET-DRACY",
    value: "21381",
    type: "common",
  },
  {
    label: "VESVRES",
    value: "21672",
    type: "common",
  },
  {
    label: "VELOGNY",
    value: "21662",
    type: "common",
  },
  {
    label: "SAINT-THIBAULT",
    value: "21576",
    type: "common",
  },
  {
    label: "POSANGES",
    value: "21498",
    type: "common",
  },
  {
    label: "NAN-SOUS-THIL",
    value: "21449",
    type: "common",
  },
  {
    label: "MARCIGNY-SOUS-THIL",
    value: "21380",
    type: "common",
  },
  {
    label: "CLAMEREY",
    value: "21177",
    type: "common",
  },
  {
    label: "BRIANNY",
    value: "21108",
    type: "common",
  },
  {
    label: "BRAUX",
    value: "21101",
    type: "common",
  },
  {
    label: "VIC-SOUS-THIL",
    value: "21678",
    type: "common",
  },
  {
    label: "ROILLY",
    value: "21529",
    type: "common",
  },
  {
    label: "PRECY-SOUS-THIL",
    value: "21505",
    type: "common",
  },
  {
    label: "AISY-SOUS-THIL",
    value: "21007",
    type: "common",
  },
  {
    label: "MONTIGNY-SAINT-BARTHELEMY",
    value: "21430",
    type: "common",
  },
  {
    label: "DOMPIERRE-EN-MORVAN",
    value: "21232",
    type: "common",
  },
  {
    label: "SAINT-GERMAIN-DE-MODEON",
    value: "21548",
    type: "common",
  },
  {
    label: "ROUVRAY",
    value: "21531",
    type: "common",
  },
  {
    label: "LA ROCHE-EN-BRENIL",
    value: "21525",
    type: "common",
  },
  {
    label: "SAINT-LEGER-VAUBAN",
    value: "89349",
    type: "common",
  },
  {
    label: "BUSSIERES",
    value: "89058",
    type: "common",
  },
  {
    label: "BEAUVILLIERS",
    value: "89032",
    type: "common",
  },
  {
    label: "SAINT-ANDEUX",
    value: "21538",
    type: "common",
  },
  {
    label: "SAINT-BRANCHER",
    value: "89336",
    type: "common",
  },
  {
    label: "SAINT-GERMAIN-DES-CHAMPS",
    value: "89347",
    type: "common",
  },
  {
    label: "CHASTELLUX-SUR-CURE",
    value: "89089",
    type: "common",
  },
  {
    label: "SAINT-ANDRE-EN-MORVAN",
    value: "58229",
    type: "common",
  },
  {
    label: "PIERRE-PERTHUIS",
    value: "89297",
    type: "common",
  },
  {
    label: "DOMECY-SUR-CURE",
    value: "89145",
    type: "common",
  },
  {
    label: "SAINT-AUBIN-DES-CHAUMES",
    value: "58230",
    type: "common",
  },
  {
    label: "FONTENAY-PRES-VEZELAY",
    value: "89176",
    type: "common",
  },
  {
    label: "TEIGNY",
    value: "58288",
    type: "common",
  },
  {
    label: "NUARS",
    value: "58197",
    type: "common",
  },
  {
    label: "LA MAISON-DIEU",
    value: "58154",
    type: "common",
  },
  {
    label: "METZ-LE-COMTE",
    value: "58165",
    type: "common",
  },
  {
    label: "BREVES",
    value: "58039",
    type: "common",
  },
  {
    label: "ASNOIS",
    value: "58016",
    type: "common",
  },
  {
    label: "VILLIERS-SUR-YONNE",
    value: "58312",
    type: "common",
  },
  {
    label: "RIX",
    value: "58222",
    type: "common",
  },
  {
    label: "OUAGNE",
    value: "58200",
    type: "common",
  },
  {
    label: "SAINT-PIERRE-DU-MONT",
    value: "58263",
    type: "common",
  },
  {
    label: "BREUGNON",
    value: "58038",
    type: "common",
  },
  {
    label: "COURCELLES",
    value: "58090",
    type: "common",
  },
  {
    label: "CORVOL-L'ORGUEILLEUX",
    value: "58085",
    type: "common",
  },
  {
    label: "LA CHAPELLE-SAINT-ANDRE",
    value: "58058",
    type: "common",
  },
  {
    label: "MENESTREAU",
    value: "58162",
    type: "common",
  },
  {
    label: "PERROY",
    value: "58209",
    type: "common",
  },
  {
    label: "COULOUTRE",
    value: "58089",
    type: "common",
  },
  {
    label: "DONZY",
    value: "58102",
    type: "common",
  },
  {
    label: "SAINT-PERE",
    value: "58261",
    type: "common",
  },
  {
    label: "POUGNY",
    value: "58213",
    type: "common",
  },
  {
    label: "COSNE-COURS-SUR-LOIRE",
    value: "58086",
    type: "common",
  },
  {
    label: "BOULLERET",
    value: "18032",
    type: "common",
  },
  {
    label: "SAINTE-GEMME-EN-SANCERROIS",
    value: "18208",
    type: "common",
  },
  {
    label: "SUBLIGNY",
    value: "18256",
    type: "common",
  },
  {
    label: "ASSIGNY",
    value: "18014",
    type: "common",
  },
  {
    label: "THOU",
    value: "18264",
    type: "common",
  },
  {
    label: "JARS",
    value: "18117",
    type: "common",
  },
  {
    label: "VILLEGENON",
    value: "18284",
    type: "common",
  },
  {
    label: "ENNORDRES",
    value: "18088",
    type: "common",
  },
  {
    label: "PRESLY",
    value: "18185",
    type: "common",
  },
  {
    label: "SALBRIS",
    value: "41232",
    type: "common",
  },
  {
    label: "LA FERTE-IMBAULT",
    value: "41084",
    type: "common",
  },
  {
    label: "LOREUX",
    value: "41118",
    type: "common",
  },
  {
    label: "VEILLEINS",
    value: "41268",
    type: "common",
  },
  {
    label: "MUR-DE-SOLOGNE",
    value: "41157",
    type: "common",
  },
  {
    label: "SOINGS-EN-SOLOGNE",
    value: "41247",
    type: "common",
  },
  {
    label: "SASSAY",
    value: "41237",
    type: "common",
  },
  {
    label: "FRESNES",
    value: "41094",
    type: "common",
  },
  {
    label: "OISLY",
    value: "41166",
    type: "common",
  },
  {
    label: "SAMBIN",
    value: "41233",
    type: "common",
  },
  {
    label: "PONTLEVOY",
    value: "41180",
    type: "common",
  },
  {
    label: "VALLIERES-LES-GRANDES",
    value: "41267",
    type: "common",
  },
  {
    label: "SOUVIGNY-DE-TOURAINE",
    value: "37252",
    type: "common",
  },
  {
    label: "SAINT-REGLE",
    value: "37236",
    type: "common",
  },
  {
    label: "CHARGE",
    value: "37060",
    type: "common",
  },
  {
    label: "AMBOISE",
    value: "37003",
    type: "common",
  },
  {
    label: "NOIZAY",
    value: "37171",
    type: "common",
  },
  {
    label: "NAZELLES-NEGRON",
    value: "37163",
    type: "common",
  },
  {
    label: "LUSSAULT-SUR-LOIRE",
    value: "37138",
    type: "common",
  },
  {
    label: "VOUVRAY",
    value: "37281",
    type: "common",
  },
  {
    label: "MONTLOUIS-SUR-LOIRE",
    value: "37156",
    type: "common",
  },
  {
    label: "LA VILLE-AUX-DAMES",
    value: "37273",
    type: "common",
  },
  {
    label: "SAINT-PIERRE-DES-CORPS",
    value: "37233",
    type: "common",
  },
  {
    label: "ROCHECORBON",
    value: "37203",
    type: "common",
  },
  {
    label: "TOURS",
    value: "37261",
    type: "common",
  },
  {
    label: "SAINT-CYR-SUR-LOIRE",
    value: "37214",
    type: "common",
  },
  {
    label: "LA RICHE",
    value: "37195",
    type: "common",
  },
  {
    label: "FONDETTES",
    value: "37109",
    type: "common",
  },
  {
    label: "SAINT-ETIENNE-DE-CHIGNY",
    value: "37217",
    type: "common",
  },
  {
    label: "LUYNES",
    value: "37139",
    type: "common",
  },
  {
    label: "MAZIERES-DE-TOURAINE",
    value: "37150",
    type: "common",
  },
  {
    label: "HOMMES",
    value: "37117",
    type: "common",
  },
  {
    label: "AVRILLE-LES-PONCEAUX",
    value: "37013",
    type: "common",
  },
  {
    label: "GIZEUX",
    value: "37112",
    type: "common",
  },
  {
    label: "VERNOIL-LE-FOURRIER",
    value: "49369",
    type: "common",
  },
  {
    label: "COURLEON",
    value: "49114",
    type: "common",
  },
  {
    label: "VERNANTES",
    value: "49368",
    type: "common",
  },
  {
    label: "SAINT-PHILBERT-DU-PEUPLE",
    value: "49311",
    type: "common",
  },
  {
    label: "BLOU",
    value: "49030",
    type: "common",
  },
  {
    label: "LONGUE-JUMELLES",
    value: "49180",
    type: "common",
  },
  {
    label: "GENNES-VAL-DE-LOIRE",
    value: "49261",
    type: "common",
  },
  {
    label: "LA MENITRE",
    value: "49201",
    type: "common",
  },
  {
    label: "BLAISON-SAINT-SULPICE",
    value: "49029",
    type: "common",
  },
  {
    label: "LES GARENNES SUR LOIRE",
    value: "49167",
    type: "common",
  },
  {
    label: "SOULAINES-SUR-AUBANCE",
    value: "49338",
    type: "common",
  },
  {
    label: "SAINT-MELAINE-SUR-AUBANCE",
    value: "49308",
    type: "common",
  },
  {
    label: "MURS-ERIGNE",
    value: "49223",
    type: "common",
  },
  {
    label: "ROCHEFORT-SUR-LOIRE",
    value: "49259",
    type: "common",
  },
  {
    label: "DENEE",
    value: "49120",
    type: "common",
  },
  {
    label: "BEHUARD",
    value: "49028",
    type: "common",
  },
  {
    label: "LA POSSONNIERE",
    value: "49247",
    type: "common",
  },
  {
    label: "CHALONNES-SUR-LOIRE",
    value: "49063",
    type: "common",
  },
  {
    label: "MAUGES-SUR-LOIRE",
    value: "49244",
    type: "common",
  },
  {
    label: "LOIREAUXENCE",
    value: "44213",
    type: "common",
  },
  {
    label: "OUDON",
    value: "44115",
    type: "common",
  },
  {
    label: "LE CELLIER",
    value: "44028",
    type: "common",
  },
  {
    label: "SAINT-MARS-DU-DESERT",
    value: "44179",
    type: "common",
  },
  {
    label: "SUCE-SUR-ERDRE",
    value: "44201",
    type: "common",
  },
  {
    label: "TREILLIERES",
    value: "44209",
    type: "common",
  },
  {
    label: "GRANDCHAMPS-DES-FONTAINES",
    value: "44066",
    type: "common",
  },
  {
    label: "NOTRE-DAME-DES-LANDES",
    value: "44111",
    type: "common",
  },
  {
    label: "VIGNEUX-DE-BRETAGNE",
    value: "44217",
    type: "common",
  },
  {
    label: "LE TEMPLE-DE-BRETAGNE",
    value: "44203",
    type: "common",
  },
  {
    label: "MALVILLE",
    value: "44089",
    type: "common",
  },
  {
    label: "SAVENAY",
    value: "44195",
    type: "common",
  },
  {
    label: "LAVAU-SUR-LOIRE",
    value: "44080",
    type: "common",
  },
  {
    label: "LA CHAPELLE-LAUNAY",
    value: "44033",
    type: "common",
  },
  {
    label: "DONGES",
    value: "44052",
    type: "common",
  },
  {
    label: "MONTOIR-DE-BRETAGNE",
    value: "44103",
    type: "common",
  },
  {
    label: "TRIGNAC",
    value: "44210",
    type: "common",
  },
  {
    label: "SAINT-MALO-DE-GUERSAC",
    value: "44176",
    type: "common",
  },
  {
    label: "SAINT-ANDRE-DES-EAUX",
    value: "44151",
    type: "common",
  },
  {
    label: "GUERANDE",
    value: "44069",
    type: "common",
  },
  {
    label: "LA TURBALLE",
    value: "44211",
    type: "common",
  },
  {
    label: "LE CROISIC",
    value: "44049",
    type: "common",
  },
  {
    label: "LOCMARIA",
    value: "56114",
    type: "common",
  },
  {
    label: "URTIERE",
    value: "25573",
    type: "common",
  },
  {
    label: "TREVILLERS",
    value: "25571",
    type: "common",
  },
  {
    label: "GOUMOIS",
    value: "25280",
    type: "common",
  },
  {
    label: "FESSEVILLERS",
    value: "25238",
    type: "common",
  },
  {
    label: "FERRIERES-LE-LAC",
    value: "25234",
    type: "common",
  },
  {
    label: "DAMPRICHARD",
    value: "25193",
    type: "common",
  },
  {
    label: "BELFAYS",
    value: "25049",
    type: "common",
  },
  {
    label: "THIEBOUHANS",
    value: "25559",
    type: "common",
  },
  {
    label: "MAICHE",
    value: "25356",
    type: "common",
  },
  {
    label: "CERNAY-L'EGLISE",
    value: "25108",
    type: "common",
  },
  {
    label: "LES BRESEUX",
    value: "25091",
    type: "common",
  },
  {
    label: "VAUCLUSOTTE",
    value: "25589",
    type: "common",
  },
  {
    label: "VAUCLUSE",
    value: "25588",
    type: "common",
  },
  {
    label: "VALOREILLE",
    value: "25584",
    type: "common",
  },
  {
    label: "ORGEANS-BLANCHEFONTAINE",
    value: "25433",
    type: "common",
  },
  {
    label: "MANCENANS-LIZERNE",
    value: "25366",
    type: "common",
  },
  {
    label: "FROIDEVAUX",
    value: "25261",
    type: "common",
  },
  {
    label: "FLEUREY",
    value: "25244",
    type: "common",
  },
  {
    label: "COUR-SAINT-MAURICE",
    value: "25173",
    type: "common",
  },
  {
    label: "SURMONT",
    value: "25554",
    type: "common",
  },
  {
    label: "PROVENCHERE",
    value: "25471",
    type: "common",
  },
  {
    label: "LA GRANGE",
    value: "25290",
    type: "common",
  },
  {
    label: "BELLEHERBE",
    value: "25051",
    type: "common",
  },
  {
    label: "SANCEY",
    value: "25529",
    type: "common",
  },
  {
    label: "RANDEVILLERS",
    value: "25478",
    type: "common",
  },
  {
    label: "LAVIRON",
    value: "25333",
    type: "common",
  },
  {
    label: "VELLEVANS",
    value: "25597",
    type: "common",
  },
  {
    label: "SERVIN",
    value: "25544",
    type: "common",
  },
  {
    label: "OUVANS",
    value: "25441",
    type: "common",
  },
  {
    label: "LANDRESSE",
    value: "25325",
    type: "common",
  },
  {
    label: "LANANS",
    value: "25324",
    type: "common",
  },
  {
    label: "VAUDRIVILLERS",
    value: "25590",
    type: "common",
  },
  {
    label: "PASSAVANT",
    value: "25446",
    type: "common",
  },
  {
    label: "ORSANS",
    value: "25435",
    type: "common",
  },
  {
    label: "MONTIVERNAGE",
    value: "25401",
    type: "common",
  },
  {
    label: "GUILLON-LES-BAINS",
    value: "25299",
    type: "common",
  },
  {
    label: "COURTETAIN-ET-SALANS",
    value: "25175",
    type: "common",
  },
  {
    label: "ADAM-LES-PASSAVANT",
    value: "25006",
    type: "common",
  },
  {
    label: "SAINT-JUAN",
    value: "25520",
    type: "common",
  },
  {
    label: "OUGNEY-DOUVOT",
    value: "25439",
    type: "common",
  },
  {
    label: "GLAMONDANS",
    value: "25273",
    type: "common",
  },
  {
    label: "DAMMARTIN-LES-TEMPLIERS",
    value: "25189",
    type: "common",
  },
  {
    label: "BRETIGNEY-NOTRE-DAME",
    value: "25094",
    type: "common",
  },
  {
    label: "AISSEY",
    value: "25009",
    type: "common",
  },
  {
    label: "ROULANS",
    value: "25508",
    type: "common",
  },
  {
    label: "OSSE",
    value: "25437",
    type: "common",
  },
  {
    label: "LAISSEY",
    value: "25323",
    type: "common",
  },
  {
    label: "DELUZ",
    value: "25197",
    type: "common",
  },
  {
    label: "CHAMPLIVE",
    value: "25116",
    type: "common",
  },
  {
    label: "VAIRE",
    value: "25575",
    type: "common",
  },
  {
    label: "ROCHE-LEZ-BEAUPRE",
    value: "25495",
    type: "common",
  },
  {
    label: "NOVILLARS",
    value: "25429",
    type: "common",
  },
  {
    label: "MARCHAUX-CHAUDEFONTAINE",
    value: "25368",
    type: "common",
  },
  {
    label: "AMAGNEY",
    value: "25014",
    type: "common",
  },
  {
    label: "THISE",
    value: "25560",
    type: "common",
  },
  {
    label: "TALLENAY",
    value: "25557",
    type: "common",
  },
  {
    label: "DEVECEY",
    value: "25200",
    type: "common",
  },
  {
    label: "BRAILLANS",
    value: "25086",
    type: "common",
  },
  {
    label: "PELOUSEY",
    value: "25448",
    type: "common",
  },
  {
    label: "MISEREY-SALINES",
    value: "25381",
    type: "common",
  },
  {
    label: "GENEUILLE",
    value: "25265",
    type: "common",
  },
  {
    label: "ECOLE-VALENTIN",
    value: "25212",
    type: "common",
  },
  {
    label: "CUSSEY-SUR-L'OGNON",
    value: "25186",
    type: "common",
  },
  {
    label: "CHATILLON-LE-DUC",
    value: "25133",
    type: "common",
  },
  {
    label: "LES AUXONS",
    value: "25035",
    type: "common",
  },
  {
    label: "VREGILLE",
    value: "70578",
    type: "common",
  },
  {
    label: "BEAUMOTTE-LES-PIN",
    value: "70060",
    type: "common",
  },
  {
    label: "SAUVAGNEY",
    value: "25536",
    type: "common",
  },
  {
    label: "RECOLOGNE",
    value: "25482",
    type: "common",
  },
  {
    label: "NOIRONTE",
    value: "25427",
    type: "common",
  },
  {
    label: "MONCLEY",
    value: "25383",
    type: "common",
  },
  {
    label: "EMAGNY",
    value: "25217",
    type: "common",
  },
  {
    label: "CHEVIGNEY-SUR-L'OGNON",
    value: "25150",
    type: "common",
  },
  {
    label: "CHAUCENNE",
    value: "25136",
    type: "common",
  },
  {
    label: "MARNAY",
    value: "70334",
    type: "common",
  },
  {
    label: "CULT",
    value: "70193",
    type: "common",
  },
  {
    label: "BRUSSEY",
    value: "70102",
    type: "common",
  },
  {
    label: "RUFFEY-LE-CHATEAU",
    value: "25510",
    type: "common",
  },
  {
    label: "TROMAREY",
    value: "70509",
    type: "common",
  },
  {
    label: "SORNAY",
    value: "70494",
    type: "common",
  },
  {
    label: "MOTEY-BESUCHE",
    value: "70374",
    type: "common",
  },
  {
    label: "MONTAGNEY",
    value: "70353",
    type: "common",
  },
  {
    label: "HUGIER",
    value: "70286",
    type: "common",
  },
  {
    label: "CHANCEY",
    value: "70126",
    type: "common",
  },
  {
    label: "BAY",
    value: "70057",
    type: "common",
  },
  {
    label: "SAUVIGNEY-LES-PESMES",
    value: "70480",
    type: "common",
  },
  {
    label: "LA RESIE-SAINT-MARTIN",
    value: "70444",
    type: "common",
  },
  {
    label: "CHEVIGNEY",
    value: "70151",
    type: "common",
  },
  {
    label: "CHAUMERCENNE",
    value: "70142",
    type: "common",
  },
  {
    label: "LA GRANDE-RESIE",
    value: "70443",
    type: "common",
  },
  {
    label: "BROYE-AUBIGNEY-MONTSEUGNY",
    value: "70101",
    type: "common",
  },
  {
    label: "CLERY",
    value: "21180",
    type: "common",
  },
  {
    label: "PONTAILLER-SUR-SAONE",
    value: "21496",
    type: "common",
  },
  {
    label: "PERRIGNY-SUR-L'OGNON",
    value: "21482",
    type: "common",
  },
  {
    label: "MAXILLY-SUR-SAONE",
    value: "21398",
    type: "common",
  },
  {
    label: "HEUILLEY-SUR-SAONE",
    value: "21316",
    type: "common",
  },
  {
    label: "VONGES",
    value: "21713",
    type: "common",
  },
  {
    label: "SAINT-LEGER-TRIEY",
    value: "21556",
    type: "common",
  },
  {
    label: "MARANDEUIL",
    value: "21376",
    type: "common",
  },
  {
    label: "ETEVAUX",
    value: "21256",
    type: "common",
  },
  {
    label: "DRAMBON",
    value: "21233",
    type: "common",
  },
  {
    label: "CIREY-LES-PONTAILLER",
    value: "21175",
    type: "common",
  },
  {
    label: "REMILLY-SUR-TILLE",
    value: "21521",
    type: "common",
  },
  {
    label: "BINGES",
    value: "21076",
    type: "common",
  },
  {
    label: "COUTERNON",
    value: "21209",
    type: "common",
  },
  {
    label: "BRESSEY-SUR-TILLE",
    value: "21105",
    type: "common",
  },
  {
    label: "ARC-SUR-TILLE",
    value: "21021",
    type: "common",
  },
  {
    label: "SAINT-APOLLINAIRE",
    value: "21540",
    type: "common",
  },
  {
    label: "QUETIGNY",
    value: "21515",
    type: "common",
  },
  {
    label: "CHEVIGNY-SAINT-SAUVEUR",
    value: "21171",
    type: "common",
  },
  {
    label: "TALANT",
    value: "21617",
    type: "common",
  },
  {
    label: "FONTAINE-LES-DIJON",
    value: "21278",
    type: "common",
  },
  {
    label: "DIJON",
    value: "21231",
    type: "common",
  },
  {
    label: "DAIX",
    value: "21223",
    type: "common",
  },
  {
    label: "VELARS-SUR-OUCHE",
    value: "21661",
    type: "common",
  },
  {
    label: "PLOMBIERES-LES-DIJON",
    value: "21485",
    type: "common",
  },
  {
    label: "CORCELLES-LES-MONTS",
    value: "21192",
    type: "common",
  },
  {
    label: "MALAIN",
    value: "21373",
    type: "common",
  },
  {
    label: "LANTENAY",
    value: "21339",
    type: "common",
  },
  {
    label: "FLEUREY-SUR-OUCHE",
    value: "21273",
    type: "common",
  },
  {
    label: "BAULME-LA-ROCHE",
    value: "21051",
    type: "common",
  },
  {
    label: "ANCEY",
    value: "21013",
    type: "common",
  },
  {
    label: "SAVIGNY-SOUS-MALAIN",
    value: "21592",
    type: "common",
  },
  {
    label: "PRALON",
    value: "21504",
    type: "common",
  },
  {
    label: "MESMONT",
    value: "21406",
    type: "common",
  },
  {
    label: "BUSSY-LA-PESLE",
    value: "21121",
    type: "common",
  },
  {
    label: "BLAISY-HAUT",
    value: "21081",
    type: "common",
  },
  {
    label: "VIEILMOULIN",
    value: "21679",
    type: "common",
  },
  {
    label: "SOMBERNON",
    value: "21611",
    type: "common",
  },
  {
    label: "SAINT-MESMIN",
    value: "21563",
    type: "common",
  },
  {
    label: "SAINT-ANTHOT",
    value: "21539",
    type: "common",
  },
  {
    label: "DREE",
    value: "21234",
    type: "common",
  },
  {
    label: "UNCEY-LE-FRANC",
    value: "21649",
    type: "common",
  },
  {
    label: "SOUSSEY-SUR-BRIONNE",
    value: "21613",
    type: "common",
  },
  {
    label: "MARCELLOIS",
    value: "21377",
    type: "common",
  },
  {
    label: "GROSBOIS-EN-MONTAGNE",
    value: "21310",
    type: "common",
  },
  {
    label: "BOUSSEY",
    value: "21097",
    type: "common",
  },
  {
    label: "THOREY-SOUS-CHARNY",
    value: "21633",
    type: "common",
  },
  {
    label: "GISSEY-LE-VIEIL",
    value: "21298",
    type: "common",
  },
  {
    label: "BEURIZOT",
    value: "21069",
    type: "common",
  },
  {
    label: "NORMIER",
    value: "21463",
    type: "common",
  },
  {
    label: "NOIDAN",
    value: "21457",
    type: "common",
  },
  {
    label: "FONTANGY",
    value: "21280",
    type: "common",
  },
  {
    label: "CHARNY",
    value: "21147",
    type: "common",
  },
  {
    label: "LA MOTTE-TERNANT",
    value: "21445",
    type: "common",
  },
  {
    label: "JUILLENAY",
    value: "21328",
    type: "common",
  },
  {
    label: "MONTLAY-EN-AUXOIS",
    value: "21434",
    type: "common",
  },
  {
    label: "MOLPHEY",
    value: "21422",
    type: "common",
  },
  {
    label: "LACOUR-D'ARCENAY",
    value: "21335",
    type: "common",
  },
  {
    label: "SAINT-AGNAN",
    value: "58226",
    type: "common",
  },
  {
    label: "QUARRE-LES-TOMBES",
    value: "89318",
    type: "common",
  },
  {
    label: "MARIGNY-L'EGLISE",
    value: "58157",
    type: "common",
  },
  {
    label: "SAINT-MARTIN-DU-PUY",
    value: "58255",
    type: "common",
  },
  {
    label: "CHALAUX",
    value: "58049",
    type: "common",
  },
  {
    label: "POUQUES-LORMES",
    value: "58216",
    type: "common",
  },
  {
    label: "EMPURY",
    value: "58108",
    type: "common",
  },
  {
    label: "BAZOCHES",
    value: "58023",
    type: "common",
  },
  {
    label: "VIGNOL",
    value: "58308",
    type: "common",
  },
  {
    label: "SAIZY",
    value: "58271",
    type: "common",
  },
  {
    label: "NEUFFONTAINES",
    value: "58190",
    type: "common",
  },
  {
    label: "MONCEAUX-LE-COMTE",
    value: "58170",
    type: "common",
  },
  {
    label: "MOISSY-MOULINOT",
    value: "58169",
    type: "common",
  },
  {
    label: "TANNAY",
    value: "58286",
    type: "common",
  },
  {
    label: "SAINT-DIDIER",
    value: "58237",
    type: "common",
  },
  {
    label: "LYS",
    value: "58150",
    type: "common",
  },
  {
    label: "FLEZ-CUZY",
    value: "58116",
    type: "common",
  },
  {
    label: "TALON",
    value: "58284",
    type: "common",
  },
  {
    label: "SAINT-GERMAIN-DES-BOIS",
    value: "58242",
    type: "common",
  },
  {
    label: "BEUVRON",
    value: "58029",
    type: "common",
  },
  {
    label: "AMAZY",
    value: "58005",
    type: "common",
  },
  {
    label: "VILLIERS-LE-SEC",
    value: "58310",
    type: "common",
  },
  {
    label: "PARIGNY-LA-ROSE",
    value: "58206",
    type: "common",
  },
  {
    label: "CUNCY-LES-VARZY",
    value: "58093",
    type: "common",
  },
  {
    label: "VARZY",
    value: "58304",
    type: "common",
  },
  {
    label: "OUDAN",
    value: "58201",
    type: "common",
  },
  {
    label: "MENOU",
    value: "58163",
    type: "common",
  },
  {
    label: "COLMERY",
    value: "58081",
    type: "common",
  },
  {
    label: "CESSY-LES-BOIS",
    value: "58048",
    type: "common",
  },
  {
    label: "SUILLY-LA-TOUR",
    value: "58281",
    type: "common",
  },
  {
    label: "SAINT-LAURENT-L'ABBAYE",
    value: "58248",
    type: "common",
  },
  {
    label: "SAINT-MARTIN-SUR-NOHAIN",
    value: "58256",
    type: "common",
  },
  {
    label: "VERDIGNY",
    value: "18274",
    type: "common",
  },
  {
    label: "SURY-EN-VAUX",
    value: "18258",
    type: "common",
  },
  {
    label: "SAINT-SATUR",
    value: "18233",
    type: "common",
  },
  {
    label: "BANNAY",
    value: "18020",
    type: "common",
  },
  {
    label: "MENETOU-RATEL",
    value: "18144",
    type: "common",
  },
  {
    label: "LE NOYER",
    value: "18168",
    type: "common",
  },
  {
    label: "LA CHAPELOTTE",
    value: "18051",
    type: "common",
  },
  {
    label: "IVOY-LE-PRE",
    value: "18115",
    type: "common",
  },
  {
    label: "LA CHAPELLE-D'ANGILLON",
    value: "18047",
    type: "common",
  },
  {
    label: "NANCAY",
    value: "18159",
    type: "common",
  },
  {
    label: "SELLES-SAINT-DENIS",
    value: "41241",
    type: "common",
  },
  {
    label: "VILLEHERVIERS",
    value: "41282",
    type: "common",
  },
  {
    label: "ROMORANTIN-LANTHENAY",
    value: "41194",
    type: "common",
  },
  {
    label: "PRUNIERS-EN-SOLOGNE",
    value: "41185",
    type: "common",
  },
  {
    label: "LASSAY-SUR-CROISNE",
    value: "41112",
    type: "common",
  },
  {
    label: "GY-EN-SOLOGNE",
    value: "41099",
    type: "common",
  },
  {
    label: "ROUGEOU",
    value: "41195",
    type: "common",
  },
  {
    label: "CHEMERY",
    value: "41049",
    type: "common",
  },
  {
    label: "COUDDES",
    value: "41062",
    type: "common",
  },
  {
    label: "CHOUSSY",
    value: "41054",
    type: "common",
  },
  {
    label: "MONTHOU-SUR-CHER",
    value: "41146",
    type: "common",
  },
  {
    label: "MONTRICHARD VAL DE CHER",
    value: "41151",
    type: "common",
  },
  {
    label: "CHISSAY-EN-TOURAINE",
    value: "41051",
    type: "common",
  },
  {
    label: "CHISSEAUX",
    value: "37073",
    type: "common",
  },
  {
    label: "CHENONCEAUX",
    value: "37070",
    type: "common",
  },
  {
    label: "LA CROIX-EN-TOURAINE",
    value: "37091",
    type: "common",
  },
  {
    label: "CIVRAY-DE-TOURAINE",
    value: "37079",
    type: "common",
  },
  {
    label: "SAINT-MARTIN-LE-BEAU",
    value: "37225",
    type: "common",
  },
  {
    label: "DIERRE",
    value: "37096",
    type: "common",
  },
  {
    label: "VERETZ",
    value: "37267",
    type: "common",
  },
  {
    label: "AZAY-SUR-CHER",
    value: "37015",
    type: "common",
  },
  {
    label: "SAINT-AVERTIN",
    value: "37208",
    type: "common",
  },
  {
    label: "LARCAY",
    value: "37124",
    type: "common",
  },
  {
    label: "JOUE-LES-TOURS",
    value: "37122",
    type: "common",
  },
  {
    label: "CHAMBRAY-LES-TOURS",
    value: "37050",
    type: "common",
  },
  {
    label: "SAVONNIERES",
    value: "37243",
    type: "common",
  },
  {
    label: "SAINT-GENOUPH",
    value: "37219",
    type: "common",
  },
  {
    label: "BALLAN-MIRE",
    value: "37018",
    type: "common",
  },
  {
    label: "VILLANDRY",
    value: "37272",
    type: "common",
  },
  {
    label: "CINQ-MARS-LA-PILE",
    value: "37077",
    type: "common",
  },
  {
    label: "BERTHENAY",
    value: "37025",
    type: "common",
  },
  {
    label: "LA CHAPELLE-AUX-NAUX",
    value: "37056",
    type: "common",
  },
  {
    label: "LANGEAIS",
    value: "37123",
    type: "common",
  },
  {
    label: "CONTINVOIR",
    value: "37082",
    type: "common",
  },
  {
    label: "BENAIS",
    value: "37024",
    type: "common",
  },
  {
    label: "BOURGUEIL",
    value: "37031",
    type: "common",
  },
  {
    label: "LA BREILLE-LES-PINS",
    value: "49045",
    type: "common",
  },
  {
    label: "BRAIN-SUR-ALLONNES",
    value: "49041",
    type: "common",
  },
  {
    label: "NEUILLE",
    value: "49224",
    type: "common",
  },
  {
    label: "ALLONNES",
    value: "49002",
    type: "common",
  },
  {
    label: "VIVY",
    value: "49378",
    type: "common",
  },
  {
    label: "SAINT-CLEMENT-DES-LEVEES",
    value: "49272",
    type: "common",
  },
  {
    label: "BRISSAC LOIRE AUBANCE",
    value: "49050",
    type: "common",
  },
  {
    label: "MOZE-SUR-LOUET",
    value: "49222",
    type: "common",
  },
  {
    label: "VAL-DU-LAYON",
    value: "49292",
    type: "common",
  },
  {
    label: "BEAULIEU-SUR-LAYON",
    value: "49022",
    type: "common",
  },
  {
    label: "CHAUDEFONDS-SUR-LAYON",
    value: "49082",
    type: "common",
  },
  {
    label: "OREE D'ANJOU",
    value: "49069",
    type: "common",
  },
  {
    label: "DIVATTE-SUR-LOIRE",
    value: "44029",
    type: "common",
  },
  {
    label: "THOUARE-SUR-LOIRE",
    value: "44204",
    type: "common",
  },
  {
    label: "MAUVES-SUR-LOIRE",
    value: "44094",
    type: "common",
  },
  {
    label: "CARQUEFOU",
    value: "44026",
    type: "common",
  },
  {
    label: "LA CHAPELLE-SUR-ERDRE",
    value: "44035",
    type: "common",
  },
  {
    label: "SAUTRON",
    value: "44194",
    type: "common",
  },
  {
    label: "ORVAULT",
    value: "44114",
    type: "common",
  },
  {
    label: "SAINT-ETIENNE-DE-MONTLUC",
    value: "44158",
    type: "common",
  },
  {
    label: "CORDEMAIS",
    value: "44045",
    type: "common",
  },
  {
    label: "BOUEE",
    value: "44019",
    type: "common",
  },
  {
    label: "PAIMBŒUF",
    value: "44116",
    type: "common",
  },
  {
    label: "CORSEPT",
    value: "44046",
    type: "common",
  },
  {
    label: "SAINT-NAZAIRE",
    value: "44184",
    type: "common",
  },
  {
    label: "PORNICHET",
    value: "44132",
    type: "common",
  },
  {
    label: "LA BAULE-ESCOUBLAC",
    value: "44055",
    type: "common",
  },
  {
    label: "LE POULIGUEN",
    value: "44135",
    type: "common",
  },
  {
    label: "BATZ-SUR-MER",
    value: "44010",
    type: "common",
  },
  {
    label: "CHARMAUVILLERS",
    value: "25124",
    type: "common",
  },
  {
    label: "LES ECORCES",
    value: "25213",
    type: "common",
  },
  {
    label: "CHARQUEMONT",
    value: "25127",
    type: "common",
  },
  {
    label: "SAINT-JULIEN-LES-RUSSEY",
    value: "25522",
    type: "common",
  },
  {
    label: "MONT-DE-VOUGNEY",
    value: "25392",
    type: "common",
  },
  {
    label: "FRAMBOUHANS",
    value: "25256",
    type: "common",
  },
  {
    label: "LES FONTENELLES",
    value: "25248",
    type: "common",
  },
  {
    label: "BATTENANS-VARIN",
    value: "25046",
    type: "common",
  },
  {
    label: "ROSUREUX",
    value: "25504",
    type: "common",
  },
  {
    label: "PLAIMBOIS-DU-MIROIR",
    value: "25456",
    type: "common",
  },
  {
    label: "LONGEVELLE-LES-RUSSEY",
    value: "25344",
    type: "common",
  },
  {
    label: "CHARMOILLE",
    value: "25125",
    type: "common",
  },
  {
    label: "CHAMESEY",
    value: "25113",
    type: "common",
  },
  {
    label: "BRETONVILLERS",
    value: "25095",
    type: "common",
  },
  {
    label: "LA SOMMETTE",
    value: "25550",
    type: "common",
  },
  {
    label: "PIERREFONTAINE-LES-VARANS",
    value: "25453",
    type: "common",
  },
  {
    label: "VILLERS-LA-COMBE",
    value: "25625",
    type: "common",
  },
  {
    label: "VILLERS-CHIEF",
    value: "25623",
    type: "common",
  },
  {
    label: "VELLEROT-LES-VERCEL",
    value: "25596",
    type: "common",
  },
  {
    label: "GERMEFONTAINE",
    value: "25268",
    type: "common",
  },
  {
    label: "EYSSON",
    value: "25231",
    type: "common",
  },
  {
    label: "DOMPREL",
    value: "25203",
    type: "common",
  },
  {
    label: "EPENOUSE",
    value: "25218",
    type: "common",
  },
  {
    label: "CHAUX-LES-PASSAVANT",
    value: "25141",
    type: "common",
  },
  {
    label: "BREMONDANS",
    value: "25089",
    type: "common",
  },
  {
    label: "BELMONT",
    value: "25052",
    type: "common",
  },
  {
    label: "MAGNY-CHATELARD",
    value: "25355",
    type: "common",
  },
  {
    label: "GONSANS",
    value: "25278",
    type: "common",
  },
  {
    label: "COTEBRUNE",
    value: "25166",
    type: "common",
  },
  {
    label: "NANCRAY",
    value: "25418",
    type: "common",
  },
  {
    label: "NAISEY-LES-GRANGES",
    value: "25417",
    type: "common",
  },
  {
    label: "BOUCLANS",
    value: "25078",
    type: "common",
  },
  {
    label: "SAONE",
    value: "25532",
    type: "common",
  },
  {
    label: "MONTFAUCON",
    value: "25395",
    type: "common",
  },
  {
    label: "GENNES",
    value: "25267",
    type: "common",
  },
  {
    label: "LA CHEVILLOTTE",
    value: "25152",
    type: "common",
  },
  {
    label: "CHALEZE",
    value: "25111",
    type: "common",
  },
  {
    label: "MORRE",
    value: "25410",
    type: "common",
  },
  {
    label: "CHALEZEULE",
    value: "25112",
    type: "common",
  },
  {
    label: "BESANCON",
    value: "25056",
    type: "common",
  },
  {
    label: "SERRE-LES-SAPINS",
    value: "25542",
    type: "common",
  },
  {
    label: "POUILLEY-LES-VIGNES",
    value: "25467",
    type: "common",
  },
  {
    label: "PIREY",
    value: "25454",
    type: "common",
  },
  {
    label: "FRANOIS",
    value: "25258",
    type: "common",
  },
  {
    label: "CHAMPVANS-LES-MOULINS",
    value: "25119",
    type: "common",
  },
  {
    label: "VILLERS-BUZON",
    value: "25622",
    type: "common",
  },
  {
    label: "PLACEY",
    value: "25455",
    type: "common",
  },
  {
    label: "MAZEROLLES-LE-SALIN",
    value: "25371",
    type: "common",
  },
  {
    label: "CORCONDRAY",
    value: "25164",
    type: "common",
  },
  {
    label: "CHEMAUDIN ET VAUX",
    value: "25147",
    type: "common",
  },
  {
    label: "CHAMPAGNEY",
    value: "25115",
    type: "common",
  },
  {
    label: "AUDEUX",
    value: "25030",
    type: "common",
  },
  {
    label: "LAVERNAY",
    value: "25332",
    type: "common",
  },
  {
    label: "LANTENNE-VERTIERE",
    value: "25326",
    type: "common",
  },
  {
    label: "FRANEY",
    value: "25257",
    type: "common",
  },
  {
    label: "ETRABONNE",
    value: "25225",
    type: "common",
  },
  {
    label: "COURCHAPON",
    value: "25172",
    type: "common",
  },
  {
    label: "CORCELLES-FERRIERES",
    value: "25162",
    type: "common",
  },
  {
    label: "BURGILLE",
    value: "25101",
    type: "common",
  },
  {
    label: "CHENEVREY-ET-MOROGNE",
    value: "70150",
    type: "common",
  },
  {
    label: "VITREUX",
    value: "39581",
    type: "common",
  },
  {
    label: "TAXENNE",
    value: "39527",
    type: "common",
  },
  {
    label: "ROUFFANGE",
    value: "39469",
    type: "common",
  },
  {
    label: "PAGNEY",
    value: "39402",
    type: "common",
  },
  {
    label: "OUGNEY",
    value: "39398",
    type: "common",
  },
  {
    label: "LE MOUTHEROT",
    value: "25414",
    type: "common",
  },
  {
    label: "JALLERANGE",
    value: "25317",
    type: "common",
  },
  {
    label: "PESMES",
    value: "70408",
    type: "common",
  },
  {
    label: "MALANS",
    value: "70327",
    type: "common",
  },
  {
    label: "BRESILLEY",
    value: "70092",
    type: "common",
  },
  {
    label: "BARD-LES-PESMES",
    value: "70048",
    type: "common",
  },
  {
    label: "THERVAY",
    value: "39528",
    type: "common",
  },
  {
    label: "SALIGNEY",
    value: "39499",
    type: "common",
  },
  {
    label: "BRANS",
    value: "39074",
    type: "common",
  },
  {
    label: "MUTIGNEY",
    value: "39377",
    type: "common",
  },
  {
    label: "MONTMIREY-LE-CHATEAU",
    value: "39361",
    type: "common",
  },
  {
    label: "DAMMARTIN-MARPAIN",
    value: "39188",
    type: "common",
  },
  {
    label: "CHAMPAGNEY",
    value: "39096",
    type: "common",
  },
  {
    label: "VIELVERGE",
    value: "21680",
    type: "common",
  },
  {
    label: "SOISSONS-SUR-NACEY",
    value: "21610",
    type: "common",
  },
  {
    label: "FLAMMERANS",
    value: "21269",
    type: "common",
  },
  {
    label: "PONCEY-LES-ATHEE",
    value: "21493",
    type: "common",
  },
  {
    label: "MAGNY-MONTARLOT",
    value: "21367",
    type: "common",
  },
  {
    label: "LAMARCHE-SUR-SAONE",
    value: "21337",
    type: "common",
  },
  {
    label: "TELLECEY",
    value: "21624",
    type: "common",
  },
  {
    label: "LONGCHAMP",
    value: "21351",
    type: "common",
  },
  {
    label: "LABERGEMENT-FOIGNEY",
    value: "21330",
    type: "common",
  },
  {
    label: "CHAMBEIRE",
    value: "21130",
    type: "common",
  },
  {
    label: "CESSEY-SUR-TILLE",
    value: "21126",
    type: "common",
  },
  {
    label: "BEIRE-LE-FORT",
    value: "21057",
    type: "common",
  },
  {
    label: "VARANGES",
    value: "21656",
    type: "common",
  },
  {
    label: "ROUVRES-EN-PLAINE",
    value: "21532",
    type: "common",
  },
  {
    label: "MAGNY-SUR-TILLE",
    value: "21370",
    type: "common",
  },
  {
    label: "IZIER",
    value: "21320",
    type: "common",
  },
  {
    label: "GENLIS",
    value: "21292",
    type: "common",
  },
  {
    label: "FAUVERNEY",
    value: "21261",
    type: "common",
  },
  {
    label: "SENNECEY-LES-DIJON",
    value: "21605",
    type: "common",
  },
  {
    label: "OUGES",
    value: "21473",
    type: "common",
  },
  {
    label: "LONGVIC",
    value: "21355",
    type: "common",
  },
  {
    label: "FENAY",
    value: "21263",
    type: "common",
  },
  {
    label: "BRETENIERE",
    value: "21106",
    type: "common",
  },
  {
    label: "PERRIGNY-LES-DIJON",
    value: "21481",
    type: "common",
  },
  {
    label: "MARSANNAY-LA-COTE",
    value: "21390",
    type: "common",
  },
  {
    label: "FIXIN",
    value: "21265",
    type: "common",
  },
  {
    label: "CHENOVE",
    value: "21166",
    type: "common",
  },
  {
    label: "FLAVIGNEROT",
    value: "21270",
    type: "common",
  },
  {
    label: "COUCHEY",
    value: "21200",
    type: "common",
  },
  {
    label: "URCY",
    value: "21650",
    type: "common",
  },
  {
    label: "SAINTE-MARIE-SUR-OUCHE",
    value: "21559",
    type: "common",
  },
  {
    label: "ARCEY",
    value: "21018",
    type: "common",
  },
  {
    label: "REMILLY-EN-MONTAGNE",
    value: "21520",
    type: "common",
  },
  {
    label: "GRENANT-LES-SOMBERNON",
    value: "21306",
    type: "common",
  },
  {
    label: "GISSEY-SUR-OUCHE",
    value: "21300",
    type: "common",
  },
  {
    label: "BARBIREY-SUR-OUCHE",
    value: "21045",
    type: "common",
  },
  {
    label: "AGEY",
    value: "21002",
    type: "common",
  },
  {
    label: "SEMAREY",
    value: "21600",
    type: "common",
  },
  {
    label: "MONTOILLOT",
    value: "21439",
    type: "common",
  },
  {
    label: "ECHANNAY",
    value: "21238",
    type: "common",
  },
  {
    label: "COMMARIN",
    value: "21187",
    type: "common",
  },
  {
    label: "AUBIGNY-LES-SOMBERNON",
    value: "21033",
    type: "common",
  },
  {
    label: "POUILLY-EN-AUXOIS",
    value: "21501",
    type: "common",
  },
  {
    label: "MARTROIS",
    value: "21392",
    type: "common",
  },
  {
    label: "CIVRY-EN-MONTAGNE",
    value: "21176",
    type: "common",
  },
  {
    label: "BELLENOT-SOUS-POUILLY",
    value: "21062",
    type: "common",
  },
  {
    label: "THOISY-LE-DESERT",
    value: "21630",
    type: "common",
  },
  {
    label: "EGUILLY",
    value: "21244",
    type: "common",
  },
  {
    label: "CHAILLY-SUR-ARMANCON",
    value: "21128",
    type: "common",
  },
  {
    label: "BLANCEY",
    value: "21082",
    type: "common",
  },
  {
    label: "MONT-SAINT-JEAN",
    value: "21441",
    type: "common",
  },
  {
    label: "MISSERY",
    value: "21417",
    type: "common",
  },
  {
    label: "VILLARGOIX",
    value: "21687",
    type: "common",
  },
  {
    label: "THOISY-LA-BERCHERE",
    value: "21629",
    type: "common",
  },
  {
    label: "SAULIEU",
    value: "21584",
    type: "common",
  },
  {
    label: "CHAMPEAU-EN-MORVAN",
    value: "21139",
    type: "common",
  },
  {
    label: "SAINT-DIDIER",
    value: "21546",
    type: "common",
  },
  {
    label: "SAINT-BRISSON",
    value: "58235",
    type: "common",
  },
  {
    label: "DUN-LES-PLACES",
    value: "58106",
    type: "common",
  },
  {
    label: "BRASSY",
    value: "58037",
    type: "common",
  },
  {
    label: "MAGNY-LORMES",
    value: "58153",
    type: "common",
  },
  {
    label: "LORMES",
    value: "58145",
    type: "common",
  },
  {
    label: "RUAGES",
    value: "58224",
    type: "common",
  },
  {
    label: "ANTHIEN",
    value: "58008",
    type: "common",
  },
  {
    label: "MARIGNY-SUR-YONNE",
    value: "58159",
    type: "common",
  },
  {
    label: "GERMENAY",
    value: "58123",
    type: "common",
  },
  {
    label: "DIROL",
    value: "58098",
    type: "common",
  },
  {
    label: "CHITRY-LES-MINES",
    value: "58075",
    type: "common",
  },
  {
    label: "CHALLEMENT",
    value: "58050",
    type: "common",
  },
  {
    label: "TACONNAY",
    value: "58283",
    type: "common",
  },
  {
    label: "MORACHES",
    value: "58181",
    type: "common",
  },
  {
    label: "GRENOIS",
    value: "58130",
    type: "common",
  },
  {
    label: "BRINON-SUR-BEUVRON",
    value: "58041",
    type: "common",
  },
  {
    label: "ASNAN",
    value: "58015",
    type: "common",
  },
  {
    label: "CORVOL-D'EMBERNARD",
    value: "58084",
    type: "common",
  },
  {
    label: "CHEVANNES-CHANGY",
    value: "58071",
    type: "common",
  },
  {
    label: "MARCY",
    value: "58156",
    type: "common",
  },
  {
    label: "CHAZEUIL",
    value: "58070",
    type: "common",
  },
  {
    label: "CHAMPLEMY",
    value: "58053",
    type: "common",
  },
  {
    label: "SAINT-MALO-EN-DONZIOIS",
    value: "58252",
    type: "common",
  },
  {
    label: "SAINTE-COLOMBE-DES-BOIS",
    value: "58236",
    type: "common",
  },
  {
    label: "CHATEAUNEUF-VAL-DE-BARGIS",
    value: "58064",
    type: "common",
  },
  {
    label: "VIELMANAY",
    value: "58307",
    type: "common",
  },
  {
    label: "SAINT-QUENTIN-SUR-NOHAIN",
    value: "58265",
    type: "common",
  },
  {
    label: "SAINT-ANDELAIN",
    value: "58228",
    type: "common",
  },
  {
    label: "POUILLY-SUR-LOIRE",
    value: "58215",
    type: "common",
  },
  {
    label: "GARCHY",
    value: "58122",
    type: "common",
  },
  {
    label: "TRACY-SUR-LOIRE",
    value: "58295",
    type: "common",
  },
  {
    label: "SAINT-BOUIZE",
    value: "18200",
    type: "common",
  },
  {
    label: "COUARGUES",
    value: "18074",
    type: "common",
  },
  {
    label: "VINON",
    value: "18287",
    type: "common",
  },
  {
    label: "THAUVENAY",
    value: "18262",
    type: "common",
  },
  {
    label: "SANCERRE",
    value: "18241",
    type: "common",
  },
  {
    label: "MENETREOL-SOUS-SANCERRE",
    value: "18146",
    type: "common",
  },
  {
    label: "BUE",
    value: "18039",
    type: "common",
  },
  {
    label: "VEAUGUES",
    value: "18272",
    type: "common",
  },
  {
    label: "CREZANCY-EN-SANCERRE",
    value: "18079",
    type: "common",
  },
  {
    label: "SENS-BEAUJEU",
    value: "18249",
    type: "common",
  },
  {
    label: "NEUVY-DEUX-CLOCHERS",
    value: "18163",
    type: "common",
  },
  {
    label: "NEUILLY-EN-SANCERRE",
    value: "18162",
    type: "common",
  },
  {
    label: "HENRICHEMONT",
    value: "18109",
    type: "common",
  },
  {
    label: "ACHERES",
    value: "18001",
    type: "common",
  },
  {
    label: "MERY-ES-BOIS",
    value: "18149",
    type: "common",
  },
  {
    label: "VOUZERON",
    value: "18290",
    type: "common",
  },
  {
    label: "NEUVY-SUR-BARANGEON",
    value: "18165",
    type: "common",
  },
  {
    label: "ORCAY",
    value: "41168",
    type: "common",
  },
  {
    label: "THEILLAY",
    value: "41256",
    type: "common",
  },
  {
    label: "CHATRES-SUR-CHER",
    value: "41044",
    type: "common",
  },
  {
    label: "MENNETOU-SUR-CHER",
    value: "41135",
    type: "common",
  },
  {
    label: "LANGON-SUR-CHER",
    value: "41110",
    type: "common",
  },
  {
    label: "VILLEFRANCHE-SUR-CHER",
    value: "41280",
    type: "common",
  },
  {
    label: "GIEVRES",
    value: "41097",
    type: "common",
  },
  {
    label: "SELLES-SUR-CHER",
    value: "41242",
    type: "common",
  },
  {
    label: "BILLY",
    value: "41016",
    type: "common",
  },
  {
    label: "MEHERS",
    value: "41132",
    type: "common",
  },
  {
    label: "CHATILLON-SUR-CHER",
    value: "41043",
    type: "common",
  },
  {
    label: "THESEE",
    value: "41258",
    type: "common",
  },
  {
    label: "SAINT-ROMAIN-SUR-CHER",
    value: "41229",
    type: "common",
  },
  {
    label: "NOYERS-SUR-CHER",
    value: "41164",
    type: "common",
  },
  {
    label: "POUILLE",
    value: "41181",
    type: "common",
  },
  {
    label: "MAREUIL-SUR-CHER",
    value: "41126",
    type: "common",
  },
  {
    label: "ANGE",
    value: "41002",
    type: "common",
  },
  {
    label: "SAINT-JULIEN-DE-CHEDON",
    value: "41217",
    type: "common",
  },
  {
    label: "FAVEROLLES-SUR-CHER",
    value: "41080",
    type: "common",
  },
  {
    label: "SAINT-GEORGES-SUR-CHER",
    value: "41211",
    type: "common",
  },
  {
    label: "FRANCUEIL",
    value: "37110",
    type: "common",
  },
  {
    label: "EPEIGNE-LES-BOIS",
    value: "37100",
    type: "common",
  },
  {
    label: "SUBLAINES",
    value: "37253",
    type: "common",
  },
  {
    label: "LUZILLE",
    value: "37141",
    type: "common",
  },
  {
    label: "BLERE",
    value: "37027",
    type: "common",
  },
  {
    label: "CIGOGNE",
    value: "37075",
    type: "common",
  },
  {
    label: "ATHEE-SUR-CHER",
    value: "37008",
    type: "common",
  },
  {
    label: "TRUYES",
    value: "37263",
    type: "common",
  },
  {
    label: "CORMERY",
    value: "37083",
    type: "common",
  },
  {
    label: "VEIGNE",
    value: "37266",
    type: "common",
  },
  {
    label: "ESVRES",
    value: "37104",
    type: "common",
  },
  {
    label: "MONTS",
    value: "37159",
    type: "common",
  },
  {
    label: "MONTBAZON",
    value: "37154",
    type: "common",
  },
  {
    label: "PONT-DE-RUAN",
    value: "37186",
    type: "common",
  },
  {
    label: "ARTANNES-SUR-INDRE",
    value: "37006",
    type: "common",
  },
  {
    label: "VALLERES",
    value: "37264",
    type: "common",
  },
  {
    label: "DRUYE",
    value: "37099",
    type: "common",
  },
  {
    label: "LIGNIERES-DE-TOURAINE",
    value: "37128",
    type: "common",
  },
  {
    label: "AZAY-LE-RIDEAU",
    value: "37014",
    type: "common",
  },
  {
    label: "COTEAUX-SUR-LOIRE",
    value: "37232",
    type: "common",
  },
  {
    label: "RIGNY-USSE",
    value: "37197",
    type: "common",
  },
  {
    label: "BREHEMONT",
    value: "37038",
    type: "common",
  },
  {
    label: "RESTIGNE",
    value: "37193",
    type: "common",
  },
  {
    label: "LA CHAPELLE-SUR-LOIRE",
    value: "37058",
    type: "common",
  },
  {
    label: "SAINT-NICOLAS-DE-BOURGUEIL",
    value: "37228",
    type: "common",
  },
  {
    label: "CHOUZE-SUR-LOIRE",
    value: "37074",
    type: "common",
  },
  {
    label: "VARENNES-SUR-LOIRE",
    value: "49361",
    type: "common",
  },
  {
    label: "VILLEBERNIER",
    value: "49374",
    type: "common",
  },
  {
    label: "SAUMUR",
    value: "49328",
    type: "common",
  },
  {
    label: "VERRIE",
    value: "49370",
    type: "common",
  },
  {
    label: "ROU-MARSON",
    value: "49262",
    type: "common",
  },
  {
    label: "DENEZE-SOUS-DOUE",
    value: "49121",
    type: "common",
  },
  {
    label: "TUFFALUN",
    value: "49003",
    type: "common",
  },
  {
    label: "TERRANJOU",
    value: "49086",
    type: "common",
  },
  {
    label: "BELLEVIGNE-EN-LAYON",
    value: "49345",
    type: "common",
  },
  {
    label: "CHEMILLE-EN-ANJOU",
    value: "49092",
    type: "common",
  },
  {
    label: "MONTREVAULT-SUR-EVRE",
    value: "49218",
    type: "common",
  },
  {
    label: "LA REMAUDIERE",
    value: "44141",
    type: "common",
  },
  {
    label: "LA BOISSIERE-DU-DORE",
    value: "44016",
    type: "common",
  },
  {
    label: "LE LOROUX-BOTTEREAU",
    value: "44084",
    type: "common",
  },
  {
    label: "SAINT-JULIEN-DE-CONCELLES",
    value: "44169",
    type: "common",
  },
  {
    label: "SAINTE-LUCE-SUR-LOIRE",
    value: "44172",
    type: "common",
  },
  {
    label: "BASSE-GOULAINE",
    value: "44009",
    type: "common",
  },
  {
    label: "NANTES",
    value: "44109",
    type: "common",
  },
  {
    label: "SAINT-HERBLAIN",
    value: "44162",
    type: "common",
  },
  {
    label: "INDRE",
    value: "44074",
    type: "common",
  },
  {
    label: "COUERON",
    value: "44047",
    type: "common",
  },
  {
    label: "LE PELLERIN",
    value: "44120",
    type: "common",
  },
  {
    label: "VUE",
    value: "44220",
    type: "common",
  },
  {
    label: "FROSSAY",
    value: "44061",
    type: "common",
  },
  {
    label: "SAINT-VIAUD",
    value: "44192",
    type: "common",
  },
  {
    label: "SAINT-PERE-EN-RETZ",
    value: "44187",
    type: "common",
  },
  {
    label: "SAINT-BREVIN-LES-PINS",
    value: "44154",
    type: "common",
  },
  {
    label: "GRAND'COMBE-DES-BOIS",
    value: "25286",
    type: "common",
  },
  {
    label: "FOURNET-BLANCHEROCHE",
    value: "25255",
    type: "common",
  },
  {
    label: "LE RUSSEY",
    value: "25512",
    type: "common",
  },
  {
    label: "NARBIEF",
    value: "25421",
    type: "common",
  },
  {
    label: "LE MEMONT",
    value: "25373",
    type: "common",
  },
  {
    label: "BONNETAGE",
    value: "25074",
    type: "common",
  },
  {
    label: "MONT-DE-LAVAL",
    value: "25391",
    type: "common",
  },
  {
    label: "MONTBELIARDOT",
    value: "25389",
    type: "common",
  },
  {
    label: "LE LUHIER",
    value: "25351",
    type: "common",
  },
  {
    label: "LAVAL-LE-PRIEURE",
    value: "25329",
    type: "common",
  },
  {
    label: "CONSOLATION-MAISONNETTES",
    value: "25161",
    type: "common",
  },
  {
    label: "LA BOSSE",
    value: "25077",
    type: "common",
  },
  {
    label: "LE BIZOT",
    value: "25062",
    type: "common",
  },
  {
    label: "VENNES",
    value: "25600",
    type: "common",
  },
  {
    label: "PLAIMBOIS-VENNES",
    value: "25457",
    type: "common",
  },
  {
    label: "GUYANS-VENNES",
    value: "25301",
    type: "common",
  },
  {
    label: "LORAY",
    value: "25349",
    type: "common",
  },
  {
    label: "LONGECHAUX",
    value: "25342",
    type: "common",
  },
  {
    label: "GRANDFONTAINE-SUR-CREUSE",
    value: "25289",
    type: "common",
  },
  {
    label: "AVOUDREY",
    value: "25039",
    type: "common",
  },
  {
    label: "CHEVIGNEY-LES-VERCEL",
    value: "25151",
    type: "common",
  },
  {
    label: "VERCEL-VILLEDIEU-LE-CAMP",
    value: "25601",
    type: "common",
  },
  {
    label: "ADAM-LES-VERCEL",
    value: "25007",
    type: "common",
  },
  {
    label: "VALDAHON",
    value: "25578",
    type: "common",
  },
  {
    label: "TREPOT",
    value: "25569",
    type: "common",
  },
  {
    label: "L'HOPITAL-DU-GROSBOIS",
    value: "25305",
    type: "common",
  },
  {
    label: "ETALANS",
    value: "25222",
    type: "common",
  },
  {
    label: "MAMIROLLE",
    value: "25364",
    type: "common",
  },
  {
    label: "LE GRATTERIS",
    value: "25297",
    type: "common",
  },
  {
    label: "LA VEZE",
    value: "25611",
    type: "common",
  },
  {
    label: "MEREY-SOUS-MONTROND",
    value: "25375",
    type: "common",
  },
  {
    label: "BEURE",
    value: "25058",
    type: "common",
  },
  {
    label: "THORAISE",
    value: "25561",
    type: "common",
  },
  {
    label: "RANCENAY",
    value: "25477",
    type: "common",
  },
  {
    label: "PUGEY",
    value: "25473",
    type: "common",
  },
  {
    label: "MONTFERRAND-LE-CHATEAU",
    value: "25397",
    type: "common",
  },
  {
    label: "LARNOD",
    value: "25328",
    type: "common",
  },
  {
    label: "BUSY",
    value: "25103",
    type: "common",
  },
  {
    label: "AVANNE-AVENEY",
    value: "25036",
    type: "common",
  },
  {
    label: "VELESMES-ESSARTS",
    value: "25594",
    type: "common",
  },
  {
    label: "TORPES",
    value: "25564",
    type: "common",
  },
  {
    label: "POUILLEY-FRANCAIS",
    value: "25466",
    type: "common",
  },
  {
    label: "GRANDFONTAINE",
    value: "25287",
    type: "common",
  },
  {
    label: "DANNEMARIE-SUR-CRETE",
    value: "25195",
    type: "common",
  },
  {
    label: "SALANS",
    value: "39498",
    type: "common",
  },
  {
    label: "EVANS",
    value: "39219",
    type: "common",
  },
  {
    label: "SAINT-VIT",
    value: "25527",
    type: "common",
  },
  {
    label: "MERCEY-LE-GRAND",
    value: "25374",
    type: "common",
  },
  {
    label: "FERRIERES-LES-BOIS",
    value: "25235",
    type: "common",
  },
  {
    label: "BERTHELANGE",
    value: "25055",
    type: "common",
  },
  {
    label: "SERMANGE",
    value: "39513",
    type: "common",
  },
  {
    label: "ROMAIN",
    value: "39464",
    type: "common",
  },
  {
    label: "LOUVATANGE",
    value: "39302",
    type: "common",
  },
  {
    label: "GENDREY",
    value: "39246",
    type: "common",
  },
  {
    label: "AUXANGE",
    value: "39031",
    type: "common",
  },
  {
    label: "VRIANGE",
    value: "39584",
    type: "common",
  },
  {
    label: "SERRE-LES-MOULIERES",
    value: "39514",
    type: "common",
  },
  {
    label: "OFFLANGES",
    value: "39392",
    type: "common",
  },
  {
    label: "MALANGE",
    value: "39308",
    type: "common",
  },
  {
    label: "AMANGE",
    value: "39008",
    type: "common",
  },
  {
    label: "FRASNE-LES-MEULIERES",
    value: "39238",
    type: "common",
  },
  {
    label: "POINTRE",
    value: "39432",
    type: "common",
  },
  {
    label: "MONTMIREY-LA-VILLE",
    value: "39360",
    type: "common",
  },
  {
    label: "MOISSEY",
    value: "39335",
    type: "common",
  },
  {
    label: "MENOTEY",
    value: "39323",
    type: "common",
  },
  {
    label: "CHEVIGNY",
    value: "39141",
    type: "common",
  },
  {
    label: "PEINTRE",
    value: "39409",
    type: "common",
  },
  {
    label: "AUXONNE",
    value: "21038",
    type: "common",
  },
  {
    label: "VILLERS-LES-POTS",
    value: "21699",
    type: "common",
  },
  {
    label: "TILLENAY",
    value: "21639",
    type: "common",
  },
  {
    label: "PONT",
    value: "21495",
    type: "common",
  },
  {
    label: "ATHEE",
    value: "21028",
    type: "common",
  },
  {
    label: "SOIRANS",
    value: "21609",
    type: "common",
  },
  {
    label: "TRECLUN",
    value: "21643",
    type: "common",
  },
  {
    label: "TART-LE-BAS",
    value: "21622",
    type: "common",
  },
  {
    label: "PLUVET",
    value: "21487",
    type: "common",
  },
  {
    label: "CHAMPDOTRE",
    value: "21138",
    type: "common",
  },
  {
    label: "MARLIENS",
    value: "21388",
    type: "common",
  },
  {
    label: "LONGECOURT-EN-PLAINE",
    value: "21353",
    type: "common",
  },
  {
    label: "ECHIGEY",
    value: "21242",
    type: "common",
  },
  {
    label: "THOREY-EN-PLAINE",
    value: "21632",
    type: "common",
  },
  {
    label: "SAVOUGES",
    value: "21596",
    type: "common",
  },
  {
    label: "SAULON-LA-RUE",
    value: "21586",
    type: "common",
  },
  {
    label: "SAULON-LA-CHAPELLE",
    value: "21585",
    type: "common",
  },
  {
    label: "NOIRON-SOUS-GEVREY",
    value: "21458",
    type: "common",
  },
  {
    label: "BARGES",
    value: "21048",
    type: "common",
  },
  {
    label: "SAINT-PHILIBERT",
    value: "21565",
    type: "common",
  },
  {
    label: "MOREY-SAINT-DENIS",
    value: "21442",
    type: "common",
  },
  {
    label: "GEVREY-CHAMBERTIN",
    value: "21295",
    type: "common",
  },
  {
    label: "BROINDON",
    value: "21113",
    type: "common",
  },
  {
    label: "BROCHON",
    value: "21110",
    type: "common",
  },
  {
    label: "REULLE-VERGY",
    value: "21523",
    type: "common",
  },
  {
    label: "CURLEY",
    value: "21217",
    type: "common",
  },
  {
    label: "CHAMBOLLE-MUSIGNY",
    value: "21133",
    type: "common",
  },
  {
    label: "CHAMBŒUF",
    value: "21132",
    type: "common",
  },
  {
    label: "TERNANT",
    value: "21625",
    type: "common",
  },
  {
    label: "SEMEZANGES",
    value: "21601",
    type: "common",
  },
  {
    label: "GERGUEIL",
    value: "21293",
    type: "common",
  },
  {
    label: "SAINT-VICTOR-SUR-OUCHE",
    value: "21578",
    type: "common",
  },
  {
    label: "SAINT-JEAN-DE-BŒUF",
    value: "21553",
    type: "common",
  },
  {
    label: "VANDENESSE-EN-AUXOIS",
    value: "21652",
    type: "common",
  },
  {
    label: "CHATEAUNEUF",
    value: "21152",
    type: "common",
  },
  {
    label: "LA BUSSIERE-SUR-OUCHE",
    value: "21120",
    type: "common",
  },
  {
    label: "BOUHEY",
    value: "21091",
    type: "common",
  },
  {
    label: "ROUVRES-SOUS-MEILLY",
    value: "21533",
    type: "common",
  },
  {
    label: "MEILLY-SUR-ROUVRES",
    value: "21399",
    type: "common",
  },
  {
    label: "MACONGE",
    value: "21362",
    type: "common",
  },
  {
    label: "CREANCEY",
    value: "21210",
    type: "common",
  },
  {
    label: "ESSEY",
    value: "21251",
    type: "common",
  },
  {
    label: "CLOMOT",
    value: "21181",
    type: "common",
  },
  {
    label: "CHATELLENOT",
    value: "21153",
    type: "common",
  },
  {
    label: "ARCONCEY",
    value: "21020",
    type: "common",
  },
  {
    label: "SUSSEY",
    value: "21615",
    type: "common",
  },
  {
    label: "MARCILLY-OGNY",
    value: "21382",
    type: "common",
  },
  {
    label: "BEUREY-BAUGUAY",
    value: "21068",
    type: "common",
  },
  {
    label: "ALLEREY",
    value: "21009",
    type: "common",
  },
  {
    label: "LIERNAIS",
    value: "21349",
    type: "common",
  },
  {
    label: "CENSEREY",
    value: "21124",
    type: "common",
  },
  {
    label: "SAINT-MARTIN-DE-LA-MER",
    value: "21560",
    type: "common",
  },
  {
    label: "ALLIGNY-EN-MORVAN",
    value: "58003",
    type: "common",
  },
  {
    label: "MONTSAUCHE-LES-SETTONS",
    value: "58180",
    type: "common",
  },
  {
    label: "GOULOUX",
    value: "58129",
    type: "common",
  },
  {
    label: "GACOGNE",
    value: "58120",
    type: "common",
  },
  {
    label: "VAUCLAIX",
    value: "58305",
    type: "common",
  },
  {
    label: "CERVON",
    value: "58047",
    type: "common",
  },
  {
    label: "CORBIGNY",
    value: "58083",
    type: "common",
  },
  {
    label: "PAZY",
    value: "58208",
    type: "common",
  },
  {
    label: "HERY",
    value: "58133",
    type: "common",
  },
  {
    label: "GUIPY",
    value: "58132",
    type: "common",
  },
  {
    label: "CHAUMOT",
    value: "58069",
    type: "common",
  },
  {
    label: "SAINT-REVERIEN",
    value: "58266",
    type: "common",
  },
  {
    label: "NEUILLY",
    value: "58191",
    type: "common",
  },
  {
    label: "BEAULIEU",
    value: "58026",
    type: "common",
  },
  {
    label: "MONTENOISON",
    value: "58174",
    type: "common",
  },
  {
    label: "CHAMPLIN",
    value: "58054",
    type: "common",
  },
  {
    label: "CHAMPALLEMENT",
    value: "58052",
    type: "common",
  },
  {
    label: "BUSSY-LA-PESLE",
    value: "58043",
    type: "common",
  },
  {
    label: "AUTHIOU",
    value: "58018",
    type: "common",
  },
  {
    label: "ARTHEL",
    value: "58013",
    type: "common",
  },
  {
    label: "GIRY",
    value: "58127",
    type: "common",
  },
  {
    label: "ARZEMBOUY",
    value: "58014",
    type: "common",
  },
  {
    label: "SAINT-BONNOT",
    value: "58234",
    type: "common",
  },
  {
    label: "DOMPIERRE-SUR-NIEVRE",
    value: "58101",
    type: "common",
  },
  {
    label: "NANNAY",
    value: "58188",
    type: "common",
  },
  {
    label: "CHASNAY",
    value: "58061",
    type: "common",
  },
  {
    label: "LA CELLE-SUR-NIEVRE",
    value: "58045",
    type: "common",
  },
  {
    label: "ARBOURSE",
    value: "58009",
    type: "common",
  },
  {
    label: "NARCY",
    value: "58189",
    type: "common",
  },
  {
    label: "MESVES-SUR-LOIRE",
    value: "58164",
    type: "common",
  },
  {
    label: "BULCY",
    value: "58042",
    type: "common",
  },
  {
    label: "HERRY",
    value: "18110",
    type: "common",
  },
  {
    label: "GARDEFORT",
    value: "18098",
    type: "common",
  },
  {
    label: "FEUX",
    value: "18094",
    type: "common",
  },
  {
    label: "JALOGNES",
    value: "18116",
    type: "common",
  },
  {
    label: "SAINT-CEOLS",
    value: "18202",
    type: "common",
  },
  {
    label: "MONTIGNY",
    value: "18151",
    type: "common",
  },
  {
    label: "HUMBLIGNY",
    value: "18111",
    type: "common",
  },
  {
    label: "PARASSY",
    value: "18176",
    type: "common",
  },
  {
    label: "MOROGUES",
    value: "18156",
    type: "common",
  },
  {
    label: "AUBINGES",
    value: "18016",
    type: "common",
  },
  {
    label: "MENETOU-SALON",
    value: "18145",
    type: "common",
  },
  {
    label: "SAINT-PALAIS",
    value: "18229",
    type: "common",
  },
  {
    label: "QUANTILLY",
    value: "18189",
    type: "common",
  },
  {
    label: "ALLOGNY",
    value: "18004",
    type: "common",
  },
  {
    label: "SAINT-LAURENT",
    value: "18219",
    type: "common",
  },
  {
    label: "VIERZON",
    value: "18279",
    type: "common",
  },
  {
    label: "THENIOUX",
    value: "18263",
    type: "common",
  },
  {
    label: "SAINT-GEORGES-SUR-LA-PREE",
    value: "18210",
    type: "common",
  },
  {
    label: "MERY-SUR-CHER",
    value: "18150",
    type: "common",
  },
  {
    label: "MARAY",
    value: "41122",
    type: "common",
  },
  {
    label: "SAINT-LOUP",
    value: "41222",
    type: "common",
  },
  {
    label: "SAINT-JULIEN-SUR-CHER",
    value: "41218",
    type: "common",
  },
  {
    label: "LA CHAPELLE-MONTMARTIN",
    value: "41038",
    type: "common",
  },
  {
    label: "SEMBLECAY",
    value: "36217",
    type: "common",
  },
  {
    label: "DUN-LE-POELIER",
    value: "36068",
    type: "common",
  },
  {
    label: "MENETOU-SUR-NAHON",
    value: "36115",
    type: "common",
  },
  {
    label: "CHABRIS",
    value: "36034",
    type: "common",
  },
  {
    label: "MEUSNES",
    value: "41139",
    type: "common",
  },
  {
    label: "LA VERNELLE",
    value: "36233",
    type: "common",
  },
  {
    label: "COUFFY",
    value: "41063",
    type: "common",
  },
  {
    label: "LYE",
    value: "36107",
    type: "common",
  },
  {
    label: "SEIGY",
    value: "41239",
    type: "common",
  },
  {
    label: "SAINT-AIGNAN",
    value: "41198",
    type: "common",
  },
  {
    label: "CHATEAUVIEUX",
    value: "41042",
    type: "common",
  },
  {
    label: "ORBIGNY",
    value: "37177",
    type: "common",
  },
  {
    label: "CERE-LA-RONDE",
    value: "37046",
    type: "common",
  },
  {
    label: "LE LIEGE",
    value: "37127",
    type: "common",
  },
  {
    label: "SAINT-QUENTIN-SUR-INDROIS",
    value: "37234",
    type: "common",
  },
  {
    label: "CHEDIGNY",
    value: "37066",
    type: "common",
  },
  {
    label: "REIGNAC-SUR-INDRE",
    value: "37192",
    type: "common",
  },
  {
    label: "AZAY-SUR-INDRE",
    value: "37016",
    type: "common",
  },
  {
    label: "TAUXIGNY-SAINT-BAULD",
    value: "37254",
    type: "common",
  },
  {
    label: "COURCAY",
    value: "37085",
    type: "common",
  },
  {
    label: "SAINT-BRANCHS",
    value: "37211",
    type: "common",
  },
  {
    label: "SORIGNY",
    value: "37250",
    type: "common",
  },
  {
    label: "THILOUZE",
    value: "37257",
    type: "common",
  },
  {
    label: "VILLAINES-LES-ROCHERS",
    value: "37271",
    type: "common",
  },
  {
    label: "SACHE",
    value: "37205",
    type: "common",
  },
  {
    label: "CHEILLE",
    value: "37067",
    type: "common",
  },
  {
    label: "SAINT-BENOIT-LA-FORET",
    value: "37210",
    type: "common",
  },
  {
    label: "RIVARENNES",
    value: "37200",
    type: "common",
  },
  {
    label: "HUISMES",
    value: "37118",
    type: "common",
  },
  {
    label: "SAVIGNY-EN-VERON",
    value: "37242",
    type: "common",
  },
  {
    label: "BEAUMONT-EN-VERON",
    value: "37022",
    type: "common",
  },
  {
    label: "AVOINE",
    value: "37011",
    type: "common",
  },
  {
    label: "TURQUANT",
    value: "49358",
    type: "common",
  },
  {
    label: "MONTSOREAU",
    value: "49219",
    type: "common",
  },
  {
    label: "CANDES-SAINT-MARTIN",
    value: "37042",
    type: "common",
  },
  {
    label: "VARRAINS",
    value: "49362",
    type: "common",
  },
  {
    label: "SOUZAY-CHAMPIGNY",
    value: "49341",
    type: "common",
  },
  {
    label: "PARNAY",
    value: "49235",
    type: "common",
  },
  {
    label: "DISTRE",
    value: "49123",
    type: "common",
  },
  {
    label: "LE COUDRAY-MACOUARD",
    value: "49112",
    type: "common",
  },
  {
    label: "ARTANNES-SUR-THOUET",
    value: "49011",
    type: "common",
  },
  {
    label: "LES ULMES",
    value: "49359",
    type: "common",
  },
  {
    label: "COURCHAMPS",
    value: "49113",
    type: "common",
  },
  {
    label: "CIZAY-LA-MADELEINE",
    value: "49100",
    type: "common",
  },
  {
    label: "LOURESSE-ROCHEMENIER",
    value: "49182",
    type: "common",
  },
  {
    label: "DOUE-EN-ANJOU",
    value: "49125",
    type: "common",
  },
  {
    label: "AUBIGNE-SUR-LAYON",
    value: "49012",
    type: "common",
  },
  {
    label: "MONTILLIERS",
    value: "49211",
    type: "common",
  },
  {
    label: "BEAUPREAU-EN-MAUGES",
    value: "49023",
    type: "common",
  },
  {
    label: "VALLET",
    value: "44212",
    type: "common",
  },
  {
    label: "LA REGRIPPIERE",
    value: "44140",
    type: "common",
  },
  {
    label: "LE LANDREAU",
    value: "44079",
    type: "common",
  },
  {
    label: "LA CHAPELLE-HEULIN",
    value: "44032",
    type: "common",
  },
  {
    label: "HAUTE-GOULAINE",
    value: "44071",
    type: "common",
  },
  {
    label: "LA HAIE-FOUASSIERE",
    value: "44070",
    type: "common",
  },
  {
    label: "VERTOU",
    value: "44215",
    type: "common",
  },
  {
    label: "SAINT-SEBASTIEN-SUR-LOIRE",
    value: "44190",
    type: "common",
  },
  {
    label: "REZE",
    value: "44143",
    type: "common",
  },
  {
    label: "LA MONTAGNE",
    value: "44101",
    type: "common",
  },
  {
    label: "BOUGUENAIS",
    value: "44020",
    type: "common",
  },
  {
    label: "BOUAYE",
    value: "44018",
    type: "common",
  },
  {
    label: "SAINT-LEGER-LES-VIGNES",
    value: "44171",
    type: "common",
  },
  {
    label: "SAINT-JEAN-DE-BOISEAU",
    value: "44166",
    type: "common",
  },
  {
    label: "PORT-SAINT-PERE",
    value: "44133",
    type: "common",
  },
  {
    label: "BRAINS",
    value: "44024",
    type: "common",
  },
  {
    label: "ROUANS",
    value: "44145",
    type: "common",
  },
  {
    label: "CHEIX-EN-RETZ",
    value: "44039",
    type: "common",
  },
  {
    label: "CHAUMES-EN-RETZ",
    value: "44005",
    type: "common",
  },
  {
    label: "CHAUVE",
    value: "44038",
    type: "common",
  },
  {
    label: "SAINT-MICHEL-CHEF-CHEF",
    value: "44182",
    type: "common",
  },
  {
    label: "PREFAILLES",
    value: "44136",
    type: "common",
  },
  {
    label: "LA PLAINE-SUR-MER",
    value: "44126",
    type: "common",
  },
  {
    label: "VILLERS-LE-LAC",
    value: "25321",
    type: "common",
  },
  {
    label: "LA CHENALOTTE",
    value: "25148",
    type: "common",
  },
  {
    label: "LE BARBOUX",
    value: "25042",
    type: "common",
  },
  {
    label: "NOEL-CERNEUX",
    value: "25425",
    type: "common",
  },
  {
    label: "LES FINS",
    value: "25240",
    type: "common",
  },
  {
    label: "LE BELIEU",
    value: "25050",
    type: "common",
  },
  {
    label: "ORCHAMPS-VENNES",
    value: "25432",
    type: "common",
  },
  {
    label: "FOURNETS-LUISANS",
    value: "25288",
    type: "common",
  },
  {
    label: "FUANS",
    value: "25262",
    type: "common",
  },
  {
    label: "PASSONFONTAINE",
    value: "25447",
    type: "common",
  },
  {
    label: "LONGEMAISON",
    value: "25343",
    type: "common",
  },
  {
    label: "FLANGEBOUCHE",
    value: "25243",
    type: "common",
  },
  {
    label: "LES PREMIERS SAPINS",
    value: "25424",
    type: "common",
  },
  {
    label: "ETRAY",
    value: "25227",
    type: "common",
  },
  {
    label: "EPENOY",
    value: "25219",
    type: "common",
  },
  {
    label: "VOIRES",
    value: "25630",
    type: "common",
  },
  {
    label: "VERNIERFONTAINE",
    value: "25605",
    type: "common",
  },
  {
    label: "FALLERANS",
    value: "25233",
    type: "common",
  },
  {
    label: "SAULES",
    value: "25535",
    type: "common",
  },
  {
    label: "MONTGESOYE",
    value: "25400",
    type: "common",
  },
  {
    label: "GUYANS-DURNES",
    value: "25300",
    type: "common",
  },
  {
    label: "DURNES",
    value: "25208",
    type: "common",
  },
  {
    label: "VILLERS-SOUS-MONTROND",
    value: "25628",
    type: "common",
  },
  {
    label: "SCEY-MAISIERES",
    value: "25537",
    type: "common",
  },
  {
    label: "ORNANS",
    value: "25434",
    type: "common",
  },
  {
    label: "MALBRANS",
    value: "25360",
    type: "common",
  },
  {
    label: "RUREY",
    value: "25511",
    type: "common",
  },
  {
    label: "MONTROND-LE-CHATEAU",
    value: "25406",
    type: "common",
  },
  {
    label: "EPEUGNEY",
    value: "25220",
    type: "common",
  },
  {
    label: "CADEMENE",
    value: "25106",
    type: "common",
  },
  {
    label: "VORGES-LES-PINS",
    value: "25631",
    type: "common",
  },
  {
    label: "COURCELLES",
    value: "25171",
    type: "common",
  },
  {
    label: "CHOUZELOT",
    value: "25154",
    type: "common",
  },
  {
    label: "CHENECEY-BUILLON",
    value: "25149",
    type: "common",
  },
  {
    label: "CHARNAY",
    value: "25126",
    type: "common",
  },
  {
    label: "CESSEY",
    value: "25109",
    type: "common",
  },
  {
    label: "VILLARS-SAINT-GEORGES",
    value: "25616",
    type: "common",
  },
  {
    label: "ROSET-FLUANS",
    value: "25502",
    type: "common",
  },
  {
    label: "QUINGEY",
    value: "25475",
    type: "common",
  },
  {
    label: "OSSELLE-ROUTELLE",
    value: "25438",
    type: "common",
  },
  {
    label: "BYANS-SUR-DOUBS",
    value: "25105",
    type: "common",
  },
  {
    label: "BOUSSIERES",
    value: "25084",
    type: "common",
  },
  {
    label: "ABBANS-DESSUS",
    value: "25002",
    type: "common",
  },
  {
    label: "ABBANS-DESSOUS",
    value: "25001",
    type: "common",
  },
  {
    label: "FRAISANS",
    value: "39235",
    type: "common",
  },
  {
    label: "COURTEFONTAINE",
    value: "39172",
    type: "common",
  },
  {
    label: "RANS",
    value: "39452",
    type: "common",
  },
  {
    label: "RANCHOT",
    value: "39451",
    type: "common",
  },
  {
    label: "PLUMONT",
    value: "39430",
    type: "common",
  },
  {
    label: "OUR",
    value: "39400",
    type: "common",
  },
  {
    label: "ORCHAMPS",
    value: "39396",
    type: "common",
  },
  {
    label: "MONTEPLAIN",
    value: "39352",
    type: "common",
  },
  {
    label: "ETREPIGNEY",
    value: "39218",
    type: "common",
  },
  {
    label: "LA BRETENIERE",
    value: "39076",
    type: "common",
  },
  {
    label: "LA BARRE",
    value: "39039",
    type: "common",
  },
  {
    label: "ROMANGE",
    value: "39465",
    type: "common",
  },
  {
    label: "LAVANS-LES-DOLE",
    value: "39285",
    type: "common",
  },
  {
    label: "LAVANGEOT",
    value: "39284",
    type: "common",
  },
  {
    label: "AUDELANGE",
    value: "39024",
    type: "common",
  },
  {
    label: "ROCHEFORT-SUR-NENON",
    value: "39462",
    type: "common",
  },
  {
    label: "JOUHE",
    value: "39270",
    type: "common",
  },
  {
    label: "GREDISANS",
    value: "39262",
    type: "common",
  },
  {
    label: "CHATENOIS",
    value: "39121",
    type: "common",
  },
  {
    label: "AUTHUME",
    value: "39030",
    type: "common",
  },
  {
    label: "ARCHELANGE",
    value: "39014",
    type: "common",
  },
  {
    label: "SAMPANS",
    value: "39501",
    type: "common",
  },
  {
    label: "RAINANS",
    value: "39449",
    type: "common",
  },
  {
    label: "MONNIERES",
    value: "39345",
    type: "common",
  },
  {
    label: "BIARNE",
    value: "39051",
    type: "common",
  },
  {
    label: "VILLERS-ROTIN",
    value: "21701",
    type: "common",
  },
  {
    label: "FLAGEY-LES-AUXONNE",
    value: "21268",
    type: "common",
  },
  {
    label: "BILLEY",
    value: "21074",
    type: "common",
  },
  {
    label: "SAINT-SEINE-EN-BACHE",
    value: "21572",
    type: "common",
  },
  {
    label: "LES MAILLYS",
    value: "21371",
    type: "common",
  },
  {
    label: "LABERGEMENT-LES-AUXONNE",
    value: "21331",
    type: "common",
  },
  {
    label: "TROUHANS",
    value: "21645",
    type: "common",
  },
  {
    label: "MONTOT",
    value: "21440",
    type: "common",
  },
  {
    label: "ECHENON",
    value: "21239",
    type: "common",
  },
  {
    label: "BRAZEY-EN-PLAINE",
    value: "21103",
    type: "common",
  },
  {
    label: "BESSEY-LES-CITEAUX",
    value: "21067",
    type: "common",
  },
  {
    label: "AUBIGNY-EN-PLAINE",
    value: "21031",
    type: "common",
  },
  {
    label: "AISEREY",
    value: "21005",
    type: "common",
  },
  {
    label: "VILLEBICHOT",
    value: "21691",
    type: "common",
  },
  {
    label: "SAINT-NICOLAS-LES-CITEAUX",
    value: "21564",
    type: "common",
  },
  {
    label: "IZEURE",
    value: "21319",
    type: "common",
  },
  {
    label: "CORCELLES-LES-CITEAUX",
    value: "21191",
    type: "common",
  },
  {
    label: "VOUGEOT",
    value: "21716",
    type: "common",
  },
  {
    label: "VOSNE-ROMANEE",
    value: "21714",
    type: "common",
  },
  {
    label: "SAINT-BERNARD",
    value: "21542",
    type: "common",
  },
  {
    label: "GILLY-LES-CITEAUX",
    value: "21297",
    type: "common",
  },
  {
    label: "FLAGEY-ECHEZEAUX",
    value: "21267",
    type: "common",
  },
  {
    label: "EPERNAY-SOUS-GEVREY",
    value: "21246",
    type: "common",
  },
  {
    label: "BONCOURT-LE-BOIS",
    value: "21088",
    type: "common",
  },
  {
    label: "AGENCOURT",
    value: "21001",
    type: "common",
  },
  {
    label: "VILLARS-FONTAINE",
    value: "21688",
    type: "common",
  },
  {
    label: "SEGROIS",
    value: "21597",
    type: "common",
  },
  {
    label: "NUITS-SAINT-GEORGES",
    value: "21464",
    type: "common",
  },
  {
    label: "MEUILLEY",
    value: "21409",
    type: "common",
  },
  {
    label: "L'ETANG-VERGY",
    value: "21254",
    type: "common",
  },
  {
    label: "CURTIL-VERGY",
    value: "21219",
    type: "common",
  },
  {
    label: "CHAUX",
    value: "21162",
    type: "common",
  },
  {
    label: "MESSANGES",
    value: "21407",
    type: "common",
  },
  {
    label: "DETAIN-ET-BRUANT",
    value: "21228",
    type: "common",
  },
  {
    label: "COLLONGES-LES-BEVY",
    value: "21182",
    type: "common",
  },
  {
    label: "CHEVANNES",
    value: "21169",
    type: "common",
  },
  {
    label: "BEVY",
    value: "21070",
    type: "common",
  },
  {
    label: "ARCENANT",
    value: "21017",
    type: "common",
  },
  {
    label: "VEUVEY-SUR-OUCHE",
    value: "21673",
    type: "common",
  },
  {
    label: "BOUILLAND",
    value: "21092",
    type: "common",
  },
  {
    label: "AUBAINE",
    value: "21030",
    type: "common",
  },
  {
    label: "ANTHEUIL",
    value: "21014",
    type: "common",
  },
  {
    label: "THOREY-SUR-OUCHE",
    value: "21634",
    type: "common",
  },
  {
    label: "SAINTE-SABINE",
    value: "21570",
    type: "common",
  },
  {
    label: "PAINBLANC",
    value: "21476",
    type: "common",
  },
  {
    label: "CRUGEY",
    value: "21214",
    type: "common",
  },
  {
    label: "COLOMBIER",
    value: "21184",
    type: "common",
  },
  {
    label: "CHAUDENAY-LE-CHATEAU",
    value: "21156",
    type: "common",
  },
  {
    label: "CHAUDENAY-LA-VILLE",
    value: "21155",
    type: "common",
  },
  {
    label: "AUXANT",
    value: "21036",
    type: "common",
  },
  {
    label: "MUSIGNY",
    value: "21447",
    type: "common",
  },
  {
    label: "LONGECOURT-LES-CULETRE",
    value: "21354",
    type: "common",
  },
  {
    label: "CUSSY-LE-CHATEL",
    value: "21222",
    type: "common",
  },
  {
    label: "CULETRE",
    value: "21216",
    type: "common",
  },
  {
    label: "CHAZILLY",
    value: "21164",
    type: "common",
  },
  {
    label: "MIMEURE",
    value: "21414",
    type: "common",
  },
  {
    label: "LE FETE",
    value: "21264",
    type: "common",
  },
  {
    label: "ARNAY-LE-DUC",
    value: "21023",
    type: "common",
  },
  {
    label: "MARCHESEUIL",
    value: "21379",
    type: "common",
  },
  {
    label: "JOUEY",
    value: "21325",
    type: "common",
  },
  {
    label: "DIANCEY",
    value: "21229",
    type: "common",
  },
  {
    label: "VIANGES",
    value: "21675",
    type: "common",
  },
  {
    label: "BRAZEY-EN-MORVAN",
    value: "21102",
    type: "common",
  },
  {
    label: "BARD-LE-REGULIER",
    value: "21046",
    type: "common",
  },
  {
    label: "VILLIERS-EN-MORVAN",
    value: "21703",
    type: "common",
  },
  {
    label: "BLANOT",
    value: "21083",
    type: "common",
  },
  {
    label: "MOUX-EN-MORVAN",
    value: "58185",
    type: "common",
  },
  {
    label: "GIEN-SUR-CURE",
    value: "58125",
    type: "common",
  },
  {
    label: "OUROUX-EN-MORVAN",
    value: "58205",
    type: "common",
  },
  {
    label: "CHAUMARD",
    value: "58068",
    type: "common",
  },
  {
    label: "MONTIGNY-EN-MORVAN",
    value: "58177",
    type: "common",
  },
  {
    label: "MHERE",
    value: "58166",
    type: "common",
  },
  {
    label: "MOURON-SUR-YONNE",
    value: "58183",
    type: "common",
  },
  {
    label: "MONTREUILLON",
    value: "58179",
    type: "common",
  },
  {
    label: "SARDY-LES-EPIRY",
    value: "58272",
    type: "common",
  },
  {
    label: "EPIRY",
    value: "58110",
    type: "common",
  },
  {
    label: "LA COLLANCELLE",
    value: "58080",
    type: "common",
  },
  {
    label: "BAZOLLES",
    value: "58024",
    type: "common",
  },
  {
    label: "VITRY-LACHE",
    value: "58313",
    type: "common",
  },
  {
    label: "CRUX-LA-VILLE",
    value: "58092",
    type: "common",
  },
  {
    label: "SAINT-FRANCHY",
    value: "58240",
    type: "common",
  },
  {
    label: "MOUSSY",
    value: "58184",
    type: "common",
  },
  {
    label: "PREMERY",
    value: "58218",
    type: "common",
  },
  {
    label: "OULON",
    value: "58203",
    type: "common",
  },
  {
    label: "LURCY-LE-BOURG",
    value: "58147",
    type: "common",
  },
  {
    label: "SICHAMPS",
    value: "58279",
    type: "common",
  },
  {
    label: "BEAUMONT-LA-FERRIERE",
    value: "58027",
    type: "common",
  },
  {
    label: "MURLIN",
    value: "58186",
    type: "common",
  },
  {
    label: "VARENNES-LES-NARCY",
    value: "58302",
    type: "common",
  },
  {
    label: "RAVEAU",
    value: "58220",
    type: "common",
  },
  {
    label: "LA MARCHE",
    value: "58155",
    type: "common",
  },
  {
    label: "LA CHARITE-SUR-LOIRE",
    value: "58059",
    type: "common",
  },
  {
    label: "LA CHAPELLE-MONTLINARD",
    value: "18049",
    type: "common",
  },
  {
    label: "SANCERGUES",
    value: "18240",
    type: "common",
  },
  {
    label: "SAINT-MARTIN-DES-CHAMPS",
    value: "18224",
    type: "common",
  },
  {
    label: "LUGNY-CHAMPAGNE",
    value: "18132",
    type: "common",
  },
  {
    label: "GROISES",
    value: "18104",
    type: "common",
  },
  {
    label: "ETRECHY",
    value: "18090",
    type: "common",
  },
  {
    label: "AZY",
    value: "18019",
    type: "common",
  },
  {
    label: "RIANS",
    value: "18194",
    type: "common",
  },
  {
    label: "LES AIX-D'ANGILLON",
    value: "18003",
    type: "common",
  },
  {
    label: "VIGNOUX-SOUS-LES-AIX",
    value: "18280",
    type: "common",
  },
  {
    label: "SOULANGIS",
    value: "18253",
    type: "common",
  },
  {
    label: "VASSELAY",
    value: "18271",
    type: "common",
  },
  {
    label: "SAINT-MARTIN-D'AUXIGNY",
    value: "18223",
    type: "common",
  },
  {
    label: "SAINT-GEORGES-SUR-MOULON",
    value: "18211",
    type: "common",
  },
  {
    label: "PIGNY",
    value: "18179",
    type: "common",
  },
  {
    label: "SAINT-ELOY-DE-GY",
    value: "18206",
    type: "common",
  },
  {
    label: "VIGNOUX-SUR-BARANGEON",
    value: "18281",
    type: "common",
  },
  {
    label: "ALLOUIS",
    value: "18005",
    type: "common",
  },
  {
    label: "FOECY",
    value: "18096",
    type: "common",
  },
  {
    label: "BRINAY",
    value: "18036",
    type: "common",
  },
  {
    label: "MEREAU",
    value: "18148",
    type: "common",
  },
  {
    label: "SAINT-HILAIRE-DE-COURT",
    value: "18214",
    type: "common",
  },
  {
    label: "MASSAY",
    value: "18140",
    type: "common",
  },
  {
    label: "DAMPIERRE-EN-GRACAY",
    value: "18085",
    type: "common",
  },
  {
    label: "GENOUILLY",
    value: "18100",
    type: "common",
  },
  {
    label: "BAGNEUX",
    value: "36011",
    type: "common",
  },
  {
    label: "ANJOUIN",
    value: "36004",
    type: "common",
  },
  {
    label: "SAINT-CHRISTOPHE-EN-BAZELLE",
    value: "36185",
    type: "common",
  },
  {
    label: "VAL-FOUZON",
    value: "36229",
    type: "common",
  },
  {
    label: "POULAINES",
    value: "36162",
    type: "common",
  },
  {
    label: "VALENCAY",
    value: "36228",
    type: "common",
  },
  {
    label: "FONTGUENAND",
    value: "36077",
    type: "common",
  },
  {
    label: "MONTRESOR",
    value: "37157",
    type: "common",
  },
  {
    label: "BEAUMONT-VILLAGE",
    value: "37023",
    type: "common",
  },
  {
    label: "GENILLE",
    value: "37111",
    type: "common",
  },
  {
    label: "CHAMBOURG-SUR-INDRE",
    value: "37049",
    type: "common",
  },
  {
    label: "DOLUS-LE-SEC",
    value: "37097",
    type: "common",
  },
  {
    label: "LE LOUROUX",
    value: "37136",
    type: "common",
  },
  {
    label: "LOUANS",
    value: "37134",
    type: "common",
  },
  {
    label: "VILLEPERDUE",
    value: "37278",
    type: "common",
  },
  {
    label: "SAINTE-CATHERINE-DE-FIERBOIS",
    value: "37212",
    type: "common",
  },
  {
    label: "SAINT-EPAIN",
    value: "37216",
    type: "common",
  },
  {
    label: "NEUIL",
    value: "37165",
    type: "common",
  },
  {
    label: "CRISSAY-SUR-MANSE",
    value: "37090",
    type: "common",
  },
  {
    label: "AVON-LES-ROCHES",
    value: "37012",
    type: "common",
  },
  {
    label: "PANZOULT",
    value: "37178",
    type: "common",
  },
  {
    label: "CRAVANT-LES-COTEAUX",
    value: "37089",
    type: "common",
  },
  {
    label: "RIVIERE",
    value: "37201",
    type: "common",
  },
  {
    label: "CHINON",
    value: "37072",
    type: "common",
  },
  {
    label: "THIZAY",
    value: "37258",
    type: "common",
  },
  {
    label: "CINAIS",
    value: "37076",
    type: "common",
  },
  {
    label: "FONTEVRAUD-L'ABBAYE",
    value: "49140",
    type: "common",
  },
  {
    label: "SAINT-GERMAIN-SUR-VIENNE",
    value: "37220",
    type: "common",
  },
  {
    label: "LERNE",
    value: "37126",
    type: "common",
  },
  {
    label: "COUZIERS",
    value: "37088",
    type: "common",
  },
  {
    label: "SAIX",
    value: "86250",
    type: "common",
  },
  {
    label: "EPIEDS",
    value: "49131",
    type: "common",
  },
  {
    label: "SAINT-JUST-SUR-DIVE",
    value: "49291",
    type: "common",
  },
  {
    label: "MONTREUIL-BELLAY",
    value: "49215",
    type: "common",
  },
  {
    label: "VAUDELNAY",
    value: "49364",
    type: "common",
  },
  {
    label: "BROSSAY",
    value: "49053",
    type: "common",
  },
  {
    label: "CERNUSSON",
    value: "49057",
    type: "common",
  },
  {
    label: "LYS-HAUT-LAYON",
    value: "49373",
    type: "common",
  },
  {
    label: "CORON",
    value: "49109",
    type: "common",
  },
  {
    label: "VEZINS",
    value: "49371",
    type: "common",
  },
  {
    label: "TREMENTINES",
    value: "49355",
    type: "common",
  },
  {
    label: "LE MAY-SUR-EVRE",
    value: "49193",
    type: "common",
  },
  {
    label: "SEVREMOINE",
    value: "49301",
    type: "common",
  },
  {
    label: "BEGROLLES-EN-MAUGES",
    value: "49027",
    type: "common",
  },
  {
    label: "LE PALLET",
    value: "44117",
    type: "common",
  },
  {
    label: "MOUZILLON",
    value: "44108",
    type: "common",
  },
  {
    label: "MONNIERES",
    value: "44100",
    type: "common",
  },
  {
    label: "GORGES",
    value: "44064",
    type: "common",
  },
  {
    label: "CLISSON",
    value: "44043",
    type: "common",
  },
  {
    label: "SAINT-FIACRE-SUR-MAINE",
    value: "44159",
    type: "common",
  },
  {
    label: "MAISDON-SUR-SEVRE",
    value: "44088",
    type: "common",
  },
  {
    label: "CHATEAU-THEBAUD",
    value: "44037",
    type: "common",
  },
  {
    label: "LES SORINIERES",
    value: "44198",
    type: "common",
  },
  {
    label: "LE BIGNON",
    value: "44014",
    type: "common",
  },
  {
    label: "PONT-SAINT-MARTIN",
    value: "44130",
    type: "common",
  },
  {
    label: "LA CHEVROLIERE",
    value: "44041",
    type: "common",
  },
  {
    label: "SAINT-AIGNAN-GRANDLIEU",
    value: "44150",
    type: "common",
  },
  {
    label: "SAINT-MARS-DE-COUTAIS",
    value: "44178",
    type: "common",
  },
  {
    label: "SAINTE-PAZANNE",
    value: "44186",
    type: "common",
  },
  {
    label: "SAINT-HILAIRE-DE-CHALEONS",
    value: "44164",
    type: "common",
  },
  {
    label: "PORNIC",
    value: "44131",
    type: "common",
  },
  {
    label: "LA BERNERIE-EN-RETZ",
    value: "44012",
    type: "common",
  },
  {
    label: "MONTLEBON",
    value: "25403",
    type: "common",
  },
  {
    label: "MORTEAU",
    value: "25411",
    type: "common",
  },
  {
    label: "GRAND'COMBE-CHATELEU",
    value: "25285",
    type: "common",
  },
  {
    label: "LES COMBES",
    value: "25160",
    type: "common",
  },
  {
    label: "LA LONGEVILLE",
    value: "25347",
    type: "common",
  },
  {
    label: "GILLEY",
    value: "25271",
    type: "common",
  },
  {
    label: "LA CHAUX",
    value: "25139",
    type: "common",
  },
  {
    label: "AUBONNE",
    value: "25029",
    type: "common",
  },
  {
    label: "ARC-SOUS-CICON",
    value: "25025",
    type: "common",
  },
  {
    label: "MOUTHIER-HAUTE-PIERRE",
    value: "25415",
    type: "common",
  },
  {
    label: "LODS",
    value: "25339",
    type: "common",
  },
  {
    label: "LAVANS-VUILLAFANS",
    value: "25331",
    type: "common",
  },
  {
    label: "VUILLAFANS",
    value: "25633",
    type: "common",
  },
  {
    label: "ECHEVANNES",
    value: "25211",
    type: "common",
  },
  {
    label: "CHATEAUVIEUX-LES-FOSSES",
    value: "25130",
    type: "common",
  },
  {
    label: "CHANTRANS",
    value: "25120",
    type: "common",
  },
  {
    label: "FLAGEY",
    value: "25241",
    type: "common",
  },
  {
    label: "CHASSAGNE-SAINT-DENIS",
    value: "25129",
    type: "common",
  },
  {
    label: "MALANS",
    value: "25359",
    type: "common",
  },
  {
    label: "LIZINE",
    value: "25338",
    type: "common",
  },
  {
    label: "FERTANS",
    value: "25236",
    type: "common",
  },
  {
    label: "CLERON",
    value: "25155",
    type: "common",
  },
  {
    label: "AMONDANS",
    value: "25017",
    type: "common",
  },
  {
    label: "ROUHE",
    value: "25507",
    type: "common",
  },
  {
    label: "RONCHAUX",
    value: "25500",
    type: "common",
  },
  {
    label: "LE VAL",
    value: "25460",
    type: "common",
  },
  {
    label: "PALANTINE",
    value: "25443",
    type: "common",
  },
  {
    label: "GOUX-SOUS-LANDET",
    value: "25283",
    type: "common",
  },
  {
    label: "ECHAY",
    value: "25209",
    type: "common",
  },
  {
    label: "CUSSEY-SUR-LISON",
    value: "25185",
    type: "common",
  },
  {
    label: "CHATILLON-SUR-LISON",
    value: "25134",
    type: "common",
  },
  {
    label: "BARTHERANS",
    value: "25044",
    type: "common",
  },
  {
    label: "SAMSON",
    value: "25528",
    type: "common",
  },
  {
    label: "PESSANS",
    value: "25450",
    type: "common",
  },
  {
    label: "MESMAY",
    value: "25379",
    type: "common",
  },
  {
    label: "LOMBARD",
    value: "25340",
    type: "common",
  },
  {
    label: "LAVANS-QUINGEY",
    value: "25330",
    type: "common",
  },
  {
    label: "BRERES",
    value: "25090",
    type: "common",
  },
  {
    label: "CHISSEY-SUR-LOUE",
    value: "39149",
    type: "common",
  },
  {
    label: "LIESLE",
    value: "25336",
    type: "common",
  },
  {
    label: "FOURG",
    value: "25253",
    type: "common",
  },
  {
    label: "SANTANS",
    value: "39502",
    type: "common",
  },
  {
    label: "CHATELAY",
    value: "39117",
    type: "common",
  },
  {
    label: "LA VIEILLE-LOYE",
    value: "39559",
    type: "common",
  },
  {
    label: "FALLETANS",
    value: "39220",
    type: "common",
  },
  {
    label: "ECLANS-NENON",
    value: "39205",
    type: "common",
  },
  {
    label: "DOLE",
    value: "39198",
    type: "common",
  },
  {
    label: "BREVANS",
    value: "39078",
    type: "common",
  },
  {
    label: "BAVERANS",
    value: "39042",
    type: "common",
  },
  {
    label: "FOUCHERANS",
    value: "39233",
    type: "common",
  },
  {
    label: "DAMPARIS",
    value: "39189",
    type: "common",
  },
  {
    label: "CHOISEY",
    value: "39150",
    type: "common",
  },
  {
    label: "CHAMPVANS",
    value: "39101",
    type: "common",
  },
  {
    label: "AUMUR",
    value: "39029",
    type: "common",
  },
  {
    label: "ABERGEMENT-LA-RONCE",
    value: "39001",
    type: "common",
  },
  {
    label: "SAMEREY",
    value: "21581",
    type: "common",
  },
  {
    label: "SAINT-SYMPHORIEN-SUR-SAONE",
    value: "21575",
    type: "common",
  },
  {
    label: "LAPERRIERE-SUR-SAONE",
    value: "21342",
    type: "common",
  },
  {
    label: "SAINT-USAGE",
    value: "21577",
    type: "common",
  },
  {
    label: "SAINT-JEAN-DE-LOSNE",
    value: "21554",
    type: "common",
  },
  {
    label: "LOSNE",
    value: "21356",
    type: "common",
  },
  {
    label: "MAGNY-LES-AUBIGNY",
    value: "21366",
    type: "common",
  },
  {
    label: "ESBARRES",
    value: "21249",
    type: "common",
  },
  {
    label: "CHARREY-SUR-SAONE",
    value: "21148",
    type: "common",
  },
  {
    label: "BONNENCONTRE",
    value: "21089",
    type: "common",
  },
  {
    label: "BROIN",
    value: "21112",
    type: "common",
  },
  {
    label: "AUVILLARS-SUR-SAONE",
    value: "21035",
    type: "common",
  },
  {
    label: "QUINCEY",
    value: "21517",
    type: "common",
  },
  {
    label: "GERLAND",
    value: "21294",
    type: "common",
  },
  {
    label: "ARGILLY",
    value: "21022",
    type: "common",
  },
  {
    label: "VILLERS-LA-FAYE",
    value: "21698",
    type: "common",
  },
  {
    label: "PREMEAUX-PRISSEY",
    value: "21506",
    type: "common",
  },
  {
    label: "MAGNY-LES-VILLERS",
    value: "21368",
    type: "common",
  },
  {
    label: "CORGOLOIN",
    value: "21194",
    type: "common",
  },
  {
    label: "COMBLANCHIEN",
    value: "21186",
    type: "common",
  },
  {
    label: "SAVIGNY-LES-BEAUNE",
    value: "21590",
    type: "common",
  },
  {
    label: "PERNAND-VERGELESSES",
    value: "21480",
    type: "common",
  },
  {
    label: "MAREY-LES-FUSSEY",
    value: "21384",
    type: "common",
  },
  {
    label: "FUSSEY",
    value: "21289",
    type: "common",
  },
  {
    label: "ECHEVRONNE",
    value: "21241",
    type: "common",
  },
  {
    label: "ALOXE-CORTON",
    value: "21010",
    type: "common",
  },
  {
    label: "BESSEY-EN-CHAUME",
    value: "21065",
    type: "common",
  },
  {
    label: "VIC-DES-PRES",
    value: "21677",
    type: "common",
  },
  {
    label: "LUSIGNY-SUR-OUCHE",
    value: "21360",
    type: "common",
  },
  {
    label: "ECUTIGNY",
    value: "21243",
    type: "common",
  },
  {
    label: "BLIGNY-SUR-OUCHE",
    value: "21087",
    type: "common",
  },
  {
    label: "VEILLY",
    value: "21660",
    type: "common",
  },
  {
    label: "THOMIREY",
    value: "21631",
    type: "common",
  },
  {
    label: "LACANCHE",
    value: "21334",
    type: "common",
  },
  {
    label: "FOISSY",
    value: "21274",
    type: "common",
  },
  {
    label: "BESSEY-LA-COUR",
    value: "21066",
    type: "common",
  },
  {
    label: "ANTIGNY-LA-VILLE",
    value: "21015",
    type: "common",
  },
  {
    label: "SAINT-PRIX-LES-ARNAY",
    value: "21567",
    type: "common",
  },
  {
    label: "MALIGNY",
    value: "21374",
    type: "common",
  },
  {
    label: "MAGNIEN",
    value: "21363",
    type: "common",
  },
  {
    label: "VOUDENAY",
    value: "21715",
    type: "common",
  },
  {
    label: "LUCENAY-L'EVEQUE",
    value: "71266",
    type: "common",
  },
  {
    label: "BARNAY",
    value: "71020",
    type: "common",
  },
  {
    label: "SAVILLY",
    value: "21593",
    type: "common",
  },
  {
    label: "MANLAY",
    value: "21375",
    type: "common",
  },
  {
    label: "CHISSEY-EN-MORVAN",
    value: "71129",
    type: "common",
  },
  {
    label: "CUSSY-EN-MORVAN",
    value: "71165",
    type: "common",
  },
  {
    label: "MENESSAIRE",
    value: "21403",
    type: "common",
  },
  {
    label: "ANOST",
    value: "71009",
    type: "common",
  },
  {
    label: "PLANCHEZ",
    value: "58210",
    type: "common",
  },
  {
    label: "LAVAULT-DE-FRETOY",
    value: "58141",
    type: "common",
  },
  {
    label: "CORANCY",
    value: "58082",
    type: "common",
  },
  {
    label: "CHATIN",
    value: "58066",
    type: "common",
  },
  {
    label: "DUN-SUR-GRANDRY",
    value: "58107",
    type: "common",
  },
  {
    label: "CHOUGNY",
    value: "58076",
    type: "common",
  },
  {
    label: "BLISMES",
    value: "58034",
    type: "common",
  },
  {
    label: "AUNAY-EN-BAZOIS",
    value: "58017",
    type: "common",
  },
  {
    label: "ACHUN",
    value: "58001",
    type: "common",
  },
  {
    label: "SAINT-MAURICE",
    value: "58257",
    type: "common",
  },
  {
    label: "MONT-ET-MARRE",
    value: "58175",
    type: "common",
  },
  {
    label: "SAINT-SAULGE",
    value: "58267",
    type: "common",
  },
  {
    label: "SAINTE-MARIE",
    value: "58253",
    type: "common",
  },
  {
    label: "SAINT-BENIN-DES-BOIS",
    value: "58233",
    type: "common",
  },
  {
    label: "JAILLY",
    value: "58136",
    type: "common",
  },
  {
    label: "NOLAY",
    value: "58196",
    type: "common",
  },
  {
    label: "POISEUX",
    value: "58212",
    type: "common",
  },
  {
    label: "SAINT-AUBIN-LES-FORGES",
    value: "58231",
    type: "common",
  },
  {
    label: "PARIGNY-LES-VAUX",
    value: "58207",
    type: "common",
  },
  {
    label: "GUERIGNY",
    value: "58131",
    type: "common",
  },
  {
    label: "CHAULGNES",
    value: "58067",
    type: "common",
  },
  {
    label: "CHAMPVOUX",
    value: "58056",
    type: "common",
  },
  {
    label: "TRONSANGES",
    value: "58298",
    type: "common",
  },
  {
    label: "SAINT-LEGER-LE-PETIT",
    value: "18220",
    type: "common",
  },
  {
    label: "BEFFES",
    value: "18025",
    type: "common",
  },
  {
    label: "ARGENVIERES",
    value: "18012",
    type: "common",
  },
  {
    label: "PRECY",
    value: "18184",
    type: "common",
  },
  {
    label: "JUSSY-LE-CHAUDRIER",
    value: "18120",
    type: "common",
  },
  {
    label: "GARIGNY",
    value: "18099",
    type: "common",
  },
  {
    label: "SEVRY",
    value: "18251",
    type: "common",
  },
  {
    label: "COUY",
    value: "18077",
    type: "common",
  },
  {
    label: "CHARENTONNAY",
    value: "18053",
    type: "common",
  },
  {
    label: "GRON",
    value: "18105",
    type: "common",
  },
  {
    label: "CHAUMOUX-MARCILLY",
    value: "18061",
    type: "common",
  },
  {
    label: "VILLABON",
    value: "18282",
    type: "common",
  },
  {
    label: "BRECY",
    value: "18035",
    type: "common",
  },
  {
    label: "SAINTE-SOLANGE",
    value: "18235",
    type: "common",
  },
  {
    label: "NOHANT-EN-GOUT",
    value: "18166",
    type: "common",
  },
  {
    label: "SAINT-MICHEL-DE-VOLANGIS",
    value: "18226",
    type: "common",
  },
  {
    label: "SAINT-GERMAIN-DU-PUY",
    value: "18213",
    type: "common",
  },
  {
    label: "FUSSY",
    value: "18097",
    type: "common",
  },
  {
    label: "SAINT-DOULCHARD",
    value: "18205",
    type: "common",
  },
  {
    label: "BERRY-BOUY",
    value: "18028",
    type: "common",
  },
  {
    label: "MEHUN-SUR-YEVRE",
    value: "18141",
    type: "common",
  },
  {
    label: "MARMAGNE",
    value: "18138",
    type: "common",
  },
  {
    label: "QUINCY",
    value: "18190",
    type: "common",
  },
  {
    label: "PREUILLY",
    value: "18186",
    type: "common",
  },
  {
    label: "CERBOIS",
    value: "18044",
    type: "common",
  },
  {
    label: "LURY-SUR-ARNON",
    value: "18134",
    type: "common",
  },
  {
    label: "CHERY",
    value: "18064",
    type: "common",
  },
  {
    label: "SAINT-PIERRE-DE-JARDS",
    value: "36205",
    type: "common",
  },
  {
    label: "MEUNET-SUR-VATAN",
    value: "36122",
    type: "common",
  },
  {
    label: "LUCAY-LE-LIBRE",
    value: "36102",
    type: "common",
  },
  {
    label: "NOHANT-EN-GRACAY",
    value: "18167",
    type: "common",
  },
  {
    label: "GRACAY",
    value: "18103",
    type: "common",
  },
  {
    label: "SAINT-FLORENTIN",
    value: "36191",
    type: "common",
  },
  {
    label: "REBOURSIN",
    value: "36170",
    type: "common",
  },
  {
    label: "ORVILLE",
    value: "36147",
    type: "common",
  },
  {
    label: "SAINT-OUTRILLE",
    value: "18228",
    type: "common",
  },
  {
    label: "BUXEUIL",
    value: "36029",
    type: "common",
  },
  {
    label: "AIZE",
    value: "36002",
    type: "common",
  },
  {
    label: "VICQ-SUR-NAHON",
    value: "36237",
    type: "common",
  },
  {
    label: "VEUIL",
    value: "36235",
    type: "common",
  },
  {
    label: "LUCAY-LE-MALE",
    value: "36103",
    type: "common",
  },
  {
    label: "NOUANS-LES-FONTAINES",
    value: "37173",
    type: "common",
  },
  {
    label: "VILLELOIN-COULANGE",
    value: "37277",
    type: "common",
  },
  {
    label: "CHEMILLE-SUR-INDROIS",
    value: "37069",
    type: "common",
  },
  {
    label: "SENNEVIERES",
    value: "37246",
    type: "common",
  },
  {
    label: "SAINT-JEAN-SAINT-GERMAIN",
    value: "37222",
    type: "common",
  },
  {
    label: "PERRUSSON",
    value: "37183",
    type: "common",
  },
  {
    label: "FERRIERE-SUR-BEAULIEU",
    value: "37108",
    type: "common",
  },
  {
    label: "BEAULIEU-LES-LOCHES",
    value: "37020",
    type: "common",
  },
  {
    label: "MOUZAY",
    value: "37162",
    type: "common",
  },
  {
    label: "LOCHES",
    value: "37132",
    type: "common",
  },
  {
    label: "CHANCEAUX-PRES-LOCHES",
    value: "37053",
    type: "common",
  },
  {
    label: "VOU",
    value: "37280",
    type: "common",
  },
  {
    label: "MANTHELAN",
    value: "37143",
    type: "common",
  },
  {
    label: "LA CHAPELLE-BLANCHE-SAINT-MARTIN",
    value: "37057",
    type: "common",
  },
  {
    label: "BOSSEE",
    value: "37029",
    type: "common",
  },
  {
    label: "SEPMES",
    value: "37247",
    type: "common",
  },
  {
    label: "SAINTE-MAURE-DE-TOURAINE",
    value: "37226",
    type: "common",
  },
  {
    label: "NOYANT-DE-TOURAINE",
    value: "37176",
    type: "common",
  },
  {
    label: "TROGUES",
    value: "37262",
    type: "common",
  },
  {
    label: "PARCAY-SUR-VIENNE",
    value: "37180",
    type: "common",
  },
  {
    label: "CROUZILLES",
    value: "37093",
    type: "common",
  },
  {
    label: "THENEUIL",
    value: "37256",
    type: "common",
  },
  {
    label: "TAVANT",
    value: "37255",
    type: "common",
  },
  {
    label: "L'ILE-BOUCHARD",
    value: "37119",
    type: "common",
  },
  {
    label: "BRIZAY",
    value: "37040",
    type: "common",
  },
  {
    label: "SAZILLY",
    value: "37244",
    type: "common",
  },
  {
    label: "LEMERE",
    value: "37125",
    type: "common",
  },
  {
    label: "ANCHE",
    value: "37004",
    type: "common",
  },
  {
    label: "LA ROCHE-CLERMAULT",
    value: "37202",
    type: "common",
  },
  {
    label: "LIGRE",
    value: "37129",
    type: "common",
  },
  {
    label: "ASSAY",
    value: "37007",
    type: "common",
  },
  {
    label: "VEZIERES",
    value: "86287",
    type: "common",
  },
  {
    label: "BEUXES",
    value: "86026",
    type: "common",
  },
  {
    label: "SEUILLY",
    value: "37248",
    type: "common",
  },
  {
    label: "ROIFFE",
    value: "86210",
    type: "common",
  },
  {
    label: "SAINT-LEGER-DE-MONTBRILLAIS",
    value: "86229",
    type: "common",
  },
  {
    label: "RASLAY",
    value: "86206",
    type: "common",
  },
  {
    label: "MORTON",
    value: "86169",
    type: "common",
  },
  {
    label: "POUANCAY",
    value: "86196",
    type: "common",
  },
  {
    label: "ANTOIGNE",
    value: "49009",
    type: "common",
  },
  {
    label: "SAINT-MARTIN-DE-SANZAY",
    value: "79277",
    type: "common",
  },
  {
    label: "LE PUY-NOTRE-DAME",
    value: "49253",
    type: "common",
  },
  {
    label: "SAINT-MACAIRE-DU-BOIS",
    value: "49302",
    type: "common",
  },
  {
    label: "PASSAVANT-SUR-LAYON",
    value: "49236",
    type: "common",
  },
  {
    label: "CLERE-SUR-LAYON",
    value: "49102",
    type: "common",
  },
  {
    label: "SAINT-PAUL-DU-BOIS",
    value: "49310",
    type: "common",
  },
  {
    label: "LA PLAINE",
    value: "49240",
    type: "common",
  },
  {
    label: "CHANTELOUP-LES-BOIS",
    value: "49070",
    type: "common",
  },
  {
    label: "TOUTLEMONDE",
    value: "49352",
    type: "common",
  },
  {
    label: "NUAILLE",
    value: "49231",
    type: "common",
  },
  {
    label: "MAZIERES-EN-MAUGES",
    value: "49195",
    type: "common",
  },
  {
    label: "SAINT-LEGER-SOUS-CHOLET",
    value: "49299",
    type: "common",
  },
  {
    label: "LA SEGUINIERE",
    value: "49332",
    type: "common",
  },
  {
    label: "LA ROMAGNE",
    value: "49260",
    type: "common",
  },
  {
    label: "BOUSSAY",
    value: "44022",
    type: "common",
  },
  {
    label: "CUGAND",
    value: "85076",
    type: "common",
  },
  {
    label: "GETIGNE",
    value: "44063",
    type: "common",
  },
  {
    label: "LA BERNARDIERE",
    value: "85021",
    type: "common",
  },
  {
    label: "SAINT-HILAIRE-DE-CLISSON",
    value: "44165",
    type: "common",
  },
  {
    label: "SAINT-LUMINE-DE-CLISSON",
    value: "44173",
    type: "common",
  },
  {
    label: "REMOUILLE",
    value: "44142",
    type: "common",
  },
  {
    label: "AIGREFEUILLE-SUR-MAINE",
    value: "44002",
    type: "common",
  },
  {
    label: "GENESTON",
    value: "44223",
    type: "common",
  },
  {
    label: "MONTBERT",
    value: "44102",
    type: "common",
  },
  {
    label: "SAINT-PHILBERT-DE-GRAND-LIEU",
    value: "44188",
    type: "common",
  },
  {
    label: "SAINT-LUMINE-DE-COUTAIS",
    value: "44174",
    type: "common",
  },
  {
    label: "VILLENEUVE-EN-RETZ",
    value: "44021",
    type: "common",
  },
  {
    label: "LES MOUTIERS-EN-RETZ",
    value: "44106",
    type: "common",
  },
  {
    label: "NOIRMOUTIER-EN-L'ILE",
    value: "85163",
    type: "common",
  },
  {
    label: "LES GRAS",
    value: "25296",
    type: "common",
  },
  {
    label: "VILLE-DU-PONT",
    value: "25620",
    type: "common",
  },
  {
    label: "MONTFLOVIN",
    value: "25398",
    type: "common",
  },
  {
    label: "MONTBENOIT",
    value: "25390",
    type: "common",
  },
  {
    label: "MAISONS-DU-BOIS-LIEVREMONT",
    value: "25357",
    type: "common",
  },
  {
    label: "HAUTERIVE-LA-FRESSE",
    value: "25303",
    type: "common",
  },
  {
    label: "SAINT-GORGON-MAIN",
    value: "25517",
    type: "common",
  },
  {
    label: "BUGNY",
    value: "25099",
    type: "common",
  },
  {
    label: "RENEDALE",
    value: "25487",
    type: "common",
  },
  {
    label: "OUHANS",
    value: "25440",
    type: "common",
  },
  {
    label: "GOUX-LES-USIERS",
    value: "25282",
    type: "common",
  },
  {
    label: "SEPTFONTAINES",
    value: "25541",
    type: "common",
  },
  {
    label: "REUGNEY",
    value: "25489",
    type: "common",
  },
  {
    label: "LONGEVILLE",
    value: "25346",
    type: "common",
  },
  {
    label: "EVILLERS",
    value: "25229",
    type: "common",
  },
  {
    label: "AMATHAY-VESIGNEUX",
    value: "25016",
    type: "common",
  },
  {
    label: "SILLEY-AMANCEY",
    value: "25545",
    type: "common",
  },
  {
    label: "DESERVILLERS",
    value: "25199",
    type: "common",
  },
  {
    label: "BOLANDOZ",
    value: "25070",
    type: "common",
  },
  {
    label: "AMANCEY",
    value: "25015",
    type: "common",
  },
  {
    label: "SARAZ",
    value: "25533",
    type: "common",
  },
  {
    label: "NANS-SOUS-SAINTE-ANNE",
    value: "25420",
    type: "common",
  },
  {
    label: "MONTMAHOUX",
    value: "25404",
    type: "common",
  },
  {
    label: "ETERNOZ",
    value: "25223",
    type: "common",
  },
  {
    label: "IVREY",
    value: "39268",
    type: "common",
  },
  {
    label: "MYON",
    value: "25416",
    type: "common",
  },
  {
    label: "BY",
    value: "25104",
    type: "common",
  },
  {
    label: "PORT-LESNEY",
    value: "39439",
    type: "common",
  },
  {
    label: "GRANGE-DE-VAIVRE",
    value: "39259",
    type: "common",
  },
  {
    label: "LA CHAPELLE-SUR-FURIEUSE",
    value: "39103",
    type: "common",
  },
  {
    label: "RENNES-SUR-LOUE",
    value: "25488",
    type: "common",
  },
  {
    label: "PAROY",
    value: "25445",
    type: "common",
  },
  {
    label: "CHAY",
    value: "25143",
    type: "common",
  },
  {
    label: "BUFFARD",
    value: "25098",
    type: "common",
  },
  {
    label: "VILLERS-FARLAY",
    value: "39569",
    type: "common",
  },
  {
    label: "ECLEUX",
    value: "39206",
    type: "common",
  },
  {
    label: "CRAMANS",
    value: "39176",
    type: "common",
  },
  {
    label: "CHAMPAGNE-SUR-LOUE",
    value: "39095",
    type: "common",
  },
  {
    label: "ARC-ET-SENANS",
    value: "25021",
    type: "common",
  },
  {
    label: "OUNANS",
    value: "39399",
    type: "common",
  },
  {
    label: "MONTBARREY",
    value: "39350",
    type: "common",
  },
  {
    label: "GERMIGNEY",
    value: "39249",
    type: "common",
  },
  {
    label: "CHAMBLAY",
    value: "39093",
    type: "common",
  },
  {
    label: "LA LOYE",
    value: "39305",
    type: "common",
  },
  {
    label: "BELMONT",
    value: "39048",
    type: "common",
  },
  {
    label: "AUGERANS",
    value: "39026",
    type: "common",
  },
  {
    label: "VILLETTE-LES-DOLE",
    value: "39573",
    type: "common",
  },
  {
    label: "RAHON",
    value: "39448",
    type: "common",
  },
  {
    label: "PARCEY",
    value: "39405",
    type: "common",
  },
  {
    label: "NEVY-LES-DOLE",
    value: "39387",
    type: "common",
  },
  {
    label: "CRISSEY",
    value: "39182",
    type: "common",
  },
  {
    label: "TAVAUX",
    value: "39526",
    type: "common",
  },
  {
    label: "MOLAY",
    value: "39338",
    type: "common",
  },
  {
    label: "GEVRY",
    value: "39252",
    type: "common",
  },
  {
    label: "CHAMPDIVERS",
    value: "39099",
    type: "common",
  },
  {
    label: "SAINT-LOUP",
    value: "39490",
    type: "common",
  },
  {
    label: "SAINT-AUBIN",
    value: "39476",
    type: "common",
  },
  {
    label: "TICHEY",
    value: "21637",
    type: "common",
  },
  {
    label: "PAGNY-LE-CHATEAU",
    value: "21475",
    type: "common",
  },
  {
    label: "MONTAGNY-LES-SEURRE",
    value: "21424",
    type: "common",
  },
  {
    label: "GROSBOIS-LES-TICHEY",
    value: "21311",
    type: "common",
  },
  {
    label: "FRANXAULT",
    value: "21285",
    type: "common",
  },
  {
    label: "POUILLY-SUR-SAONE",
    value: "21502",
    type: "common",
  },
  {
    label: "PAGNY-LA-VILLE",
    value: "21474",
    type: "common",
  },
  {
    label: "LECHATELET",
    value: "21344",
    type: "common",
  },
  {
    label: "LANTHES",
    value: "21340",
    type: "common",
  },
  {
    label: "LABRUYERE",
    value: "21333",
    type: "common",
  },
  {
    label: "CHAMBLANC",
    value: "21131",
    type: "common",
  },
  {
    label: "MONTMAIN",
    value: "21436",
    type: "common",
  },
  {
    label: "GLANON",
    value: "21301",
    type: "common",
  },
  {
    label: "BAGNOT",
    value: "21042",
    type: "common",
  },
  {
    label: "VILLY-LE-MOUTIER",
    value: "21708",
    type: "common",
  },
  {
    label: "MARIGNY-LES-REULLEE",
    value: "21387",
    type: "common",
  },
  {
    label: "CORBERON",
    value: "21189",
    type: "common",
  },
  {
    label: "VIGNOLES",
    value: "21684",
    type: "common",
  },
  {
    label: "LADOIX-SERRIGNY",
    value: "21606",
    type: "common",
  },
  {
    label: "RUFFEY-LES-BEAUNE",
    value: "21534",
    type: "common",
  },
  {
    label: "CHOREY-LES-BEAUNE",
    value: "21173",
    type: "common",
  },
  {
    label: "POMMARD",
    value: "21492",
    type: "common",
  },
  {
    label: "BEAUNE",
    value: "21054",
    type: "common",
  },
  {
    label: "SAINT-ROMAIN",
    value: "21569",
    type: "common",
  },
  {
    label: "NANTOUX",
    value: "21450",
    type: "common",
  },
  {
    label: "MELOISEY",
    value: "21401",
    type: "common",
  },
  {
    label: "MAVILLY-MANDELOT",
    value: "21397",
    type: "common",
  },
  {
    label: "BOUZE-LES-BEAUNE",
    value: "21099",
    type: "common",
  },
  {
    label: "SAUSSEY",
    value: "21588",
    type: "common",
  },
  {
    label: "MONTCEAU-ET-ECHARNANT",
    value: "21427",
    type: "common",
  },
  {
    label: "CUSSY-LA-COLONNE",
    value: "21221",
    type: "common",
  },
  {
    label: "THURY",
    value: "21636",
    type: "common",
  },
  {
    label: "SAINT-PIERRE-EN-VAUX",
    value: "21566",
    type: "common",
  },
  {
    label: "MOLINOT",
    value: "21420",
    type: "common",
  },
  {
    label: "VAL-MONT",
    value: "21327",
    type: "common",
  },
  {
    label: "CHAMPIGNOLLES",
    value: "21140",
    type: "common",
  },
  {
    label: "SAINT-LEGER-DU-BOIS",
    value: "71438",
    type: "common",
  },
  {
    label: "VIEVY",
    value: "21683",
    type: "common",
  },
  {
    label: "IGORNAY",
    value: "71237",
    type: "common",
  },
  {
    label: "RECLESNE",
    value: "71368",
    type: "common",
  },
  {
    label: "CORDESSE",
    value: "71144",
    type: "common",
  },
  {
    label: "SOMMANT",
    value: "71527",
    type: "common",
  },
  {
    label: "ROUSSILLON-EN-MORVAN",
    value: "71376",
    type: "common",
  },
  {
    label: "LA PETITE-VERRIERE",
    value: "71349",
    type: "common",
  },
  {
    label: "ARLEUF",
    value: "58010",
    type: "common",
  },
  {
    label: "CHATEAU-CHINON (CAMPAGNE)",
    value: "58063",
    type: "common",
  },
  {
    label: "CHATEAU-CHINON (VILLE)",
    value: "58062",
    type: "common",
  },
  {
    label: "SAINT-HILAIRE-EN-MORVAN",
    value: "58244",
    type: "common",
  },
  {
    label: "DOMMARTIN",
    value: "58099",
    type: "common",
  },
  {
    label: "SAINT-PEREUSE",
    value: "58262",
    type: "common",
  },
  {
    label: "MAUX",
    value: "58161",
    type: "common",
  },
  {
    label: "TAMNAY-EN-BAZOIS",
    value: "58285",
    type: "common",
  },
  {
    label: "OUGNY",
    value: "58202",
    type: "common",
  },
  {
    label: "MONTAPAS",
    value: "58171",
    type: "common",
  },
  {
    label: "CHATILLON-EN-BAZOIS",
    value: "58065",
    type: "common",
  },
  {
    label: "ALLUY",
    value: "58004",
    type: "common",
  },
  {
    label: "SAXI-BOURDON",
    value: "58275",
    type: "common",
  },
  {
    label: "ROUY",
    value: "58223",
    type: "common",
  },
  {
    label: "SAINT-FIRMIN",
    value: "58239",
    type: "common",
  },
  {
    label: "BONA",
    value: "58035",
    type: "common",
  },
  {
    label: "SAINT-SULPICE",
    value: "58269",
    type: "common",
  },
  {
    label: "SAINT-MARTIN-D'HEUILLE",
    value: "58254",
    type: "common",
  },
  {
    label: "VAUX D'AMOGNES",
    value: "58204",
    type: "common",
  },
  {
    label: "VARENNES-VAUZELLES",
    value: "58303",
    type: "common",
  },
  {
    label: "URZY",
    value: "58300",
    type: "common",
  },
  {
    label: "POUGUES-LES-EAUX",
    value: "58214",
    type: "common",
  },
  {
    label: "GARCHIZY",
    value: "58121",
    type: "common",
  },
  {
    label: "MARSEILLES-LES-AUBIGNY",
    value: "18139",
    type: "common",
  },
  {
    label: "GERMIGNY-SUR-LOIRE",
    value: "58124",
    type: "common",
  },
  {
    label: "JOUET-SUR-L'AUBOIS",
    value: "18118",
    type: "common",
  },
  {
    label: "MENETOU-COUTURE",
    value: "18143",
    type: "common",
  },
  {
    label: "VILLEQUIERS",
    value: "18286",
    type: "common",
  },
  {
    label: "MORNAY-BERRY",
    value: "18154",
    type: "common",
  },
  {
    label: "CHASSY",
    value: "18056",
    type: "common",
  },
  {
    label: "FARGES-EN-SEPTAINE",
    value: "18092",
    type: "common",
  },
  {
    label: "SAVIGNY-EN-SEPTAINE",
    value: "18247",
    type: "common",
  },
  {
    label: "OSMOY",
    value: "18174",
    type: "common",
  },
  {
    label: "MOULINS-SUR-YEVRE",
    value: "18158",
    type: "common",
  },
  {
    label: "BOURGES",
    value: "18033",
    type: "common",
  },
  {
    label: "LA CHAPELLE-SAINT-URSIN",
    value: "18050",
    type: "common",
  },
  {
    label: "SAINTE-THORETTE",
    value: "18237",
    type: "common",
  },
  {
    label: "MORTHOMIERS",
    value: "18157",
    type: "common",
  },
  {
    label: "PLOU",
    value: "18181",
    type: "common",
  },
  {
    label: "LIMEUX",
    value: "18128",
    type: "common",
  },
  {
    label: "LAZENAY",
    value: "18124",
    type: "common",
  },
  {
    label: "REUILLY",
    value: "36171",
    type: "common",
  },
  {
    label: "DIOU",
    value: "36065",
    type: "common",
  },
  {
    label: "PAUDY",
    value: "36152",
    type: "common",
  },
  {
    label: "GIROUX",
    value: "36083",
    type: "common",
  },
  {
    label: "VATAN",
    value: "36230",
    type: "common",
  },
  {
    label: "LA CHAPELLE-SAINT-LAURIAN",
    value: "36041",
    type: "common",
  },
  {
    label: "GUILLY",
    value: "36085",
    type: "common",
  },
  {
    label: "FONTENAY",
    value: "36075",
    type: "common",
  },
  {
    label: "ROUVRES-LES-BOIS",
    value: "36175",
    type: "common",
  },
  {
    label: "BOUGES-LE-CHATEAU",
    value: "36023",
    type: "common",
  },
  {
    label: "LANGE",
    value: "36092",
    type: "common",
  },
  {
    label: "BAUDRES",
    value: "36013",
    type: "common",
  },
  {
    label: "JEU-MALOCHES",
    value: "36090",
    type: "common",
  },
  {
    label: "GEHEE",
    value: "36082",
    type: "common",
  },
  {
    label: "HEUGNES",
    value: "36086",
    type: "common",
  },
  {
    label: "ECUEILLE",
    value: "36069",
    type: "common",
  },
  {
    label: "VILLEDOMAIN",
    value: "37275",
    type: "common",
  },
  {
    label: "PREAUX",
    value: "36166",
    type: "common",
  },
  {
    label: "LOCHE-SUR-INDROIS",
    value: "37133",
    type: "common",
  },
  {
    label: "SAINT-HIPPOLYTE",
    value: "37221",
    type: "common",
  },
  {
    label: "BRIDORE",
    value: "37039",
    type: "common",
  },
  {
    label: "VERNEUIL-SUR-INDRE",
    value: "37269",
    type: "common",
  },
  {
    label: "VARENNES",
    value: "37265",
    type: "common",
  },
  {
    label: "SAINT-SENOCH",
    value: "37238",
    type: "common",
  },
  {
    label: "ESVES-LE-MOUTIER",
    value: "37103",
    type: "common",
  },
  {
    label: "LIGUEIL",
    value: "37130",
    type: "common",
  },
  {
    label: "CIRAN",
    value: "37078",
    type: "common",
  },
  {
    label: "BOURNAN",
    value: "37032",
    type: "common",
  },
  {
    label: "MARCE-SUR-ESVES",
    value: "37145",
    type: "common",
  },
  {
    label: "CIVRAY-SUR-ESVES",
    value: "37080",
    type: "common",
  },
  {
    label: "POUZAY",
    value: "37188",
    type: "common",
  },
  {
    label: "NOUATRE",
    value: "37174",
    type: "common",
  },
  {
    label: "MAILLE",
    value: "37142",
    type: "common",
  },
  {
    label: "DRACHE",
    value: "37098",
    type: "common",
  },
  {
    label: "RILLY-SUR-VIENNE",
    value: "37199",
    type: "common",
  },
  {
    label: "MARCILLY-SUR-VIENNE",
    value: "37147",
    type: "common",
  },
  {
    label: "VERNEUIL-LE-CHATEAU",
    value: "37268",
    type: "common",
  },
  {
    label: "LA TOUR-SAINT-GELIN",
    value: "37260",
    type: "common",
  },
  {
    label: "COURCOUE",
    value: "37087",
    type: "common",
  },
  {
    label: "CHEZELLES",
    value: "37071",
    type: "common",
  },
  {
    label: "CHAMPIGNY-SUR-VEUDE",
    value: "37051",
    type: "common",
  },
  {
    label: "POUANT",
    value: "86197",
    type: "common",
  },
  {
    label: "CEAUX-EN-LOUDUN",
    value: "86044",
    type: "common",
  },
  {
    label: "MARCAY",
    value: "37144",
    type: "common",
  },
  {
    label: "SAMMARCOLLES",
    value: "86252",
    type: "common",
  },
  {
    label: "BASSES",
    value: "86018",
    type: "common",
  },
  {
    label: "BOURNAND",
    value: "86036",
    type: "common",
  },
  {
    label: "LES TROIS-MOUTIERS",
    value: "86274",
    type: "common",
  },
  {
    label: "TERNAY",
    value: "86269",
    type: "common",
  },
  {
    label: "CURCAY-SUR-DIVE",
    value: "86090",
    type: "common",
  },
  {
    label: "BERRIE",
    value: "86022",
    type: "common",
  },
  {
    label: "TOURTENAY",
    value: "79331",
    type: "common",
  },
  {
    label: "SAINT-MARTIN-DE-MACON",
    value: "79274",
    type: "common",
  },
  {
    label: "SAINT-CYR-LA-LANDE",
    value: "79244",
    type: "common",
  },
  {
    label: "LOUZY",
    value: "79157",
    type: "common",
  },
  {
    label: "BRION-PRES-THOUET",
    value: "79056",
    type: "common",
  },
  {
    label: "SAINTE-VERGE",
    value: "79300",
    type: "common",
  },
  {
    label: "LORETZ-D'ARGENTON",
    value: "79014",
    type: "common",
  },
  {
    label: "VAL EN VIGNES",
    value: "79063",
    type: "common",
  },
  {
    label: "GENNETON",
    value: "79132",
    type: "common",
  },
  {
    label: "SAINT MAURICE ETUSSON",
    value: "79280",
    type: "common",
  },
  {
    label: "LES CERQUEUX",
    value: "49058",
    type: "common",
  },
  {
    label: "SOMLOIRE",
    value: "49336",
    type: "common",
  },
  {
    label: "YZERNAY",
    value: "49381",
    type: "common",
  },
  {
    label: "MAULEVRIER",
    value: "49192",
    type: "common",
  },
  {
    label: "LA TESSOUALLE",
    value: "49343",
    type: "common",
  },
  {
    label: "CHOLET",
    value: "49099",
    type: "common",
  },
  {
    label: "MORTAGNE-SUR-SEVRE",
    value: "85151",
    type: "common",
  },
  {
    label: "SAINT-CHRISTOPHE-DU-BOIS",
    value: "49269",
    type: "common",
  },
  {
    label: "SAINT-AUBIN-DES-ORMEAUX",
    value: "85198",
    type: "common",
  },
  {
    label: "TIFFAUGES",
    value: "85293",
    type: "common",
  },
  {
    label: "TREIZE-SEPTIERS",
    value: "85295",
    type: "common",
  },
  {
    label: "LA BRUFFIERE",
    value: "85039",
    type: "common",
  },
  {
    label: "VIEILLEVIGNE",
    value: "44216",
    type: "common",
  },
  {
    label: "SAINT-PHILBERT-DE-BOUAINE",
    value: "85262",
    type: "common",
  },
  {
    label: "LA PLANCHE",
    value: "44127",
    type: "common",
  },
  {
    label: "SAINT-COLOMBAN",
    value: "44155",
    type: "common",
  },
  {
    label: "LA LIMOUZINIERE",
    value: "44083",
    type: "common",
  },
  {
    label: "LA MARNE",
    value: "44090",
    type: "common",
  },
  {
    label: "PAULX",
    value: "44119",
    type: "common",
  },
  {
    label: "MACHECOUL-SAINT-MEME",
    value: "44087",
    type: "common",
  },
  {
    label: "BOUIN",
    value: "85029",
    type: "common",
  },
  {
    label: "LA GUERINIERE",
    value: "85106",
    type: "common",
  },
  {
    label: "L'EPINE",
    value: "85083",
    type: "common",
  },
  {
    label: "LES ALLIES",
    value: "25012",
    type: "common",
  },
  {
    label: "VUILLECIN",
    value: "25634",
    type: "common",
  },
  {
    label: "DOUBS",
    value: "25204",
    type: "common",
  },
  {
    label: "ARCON",
    value: "25024",
    type: "common",
  },
  {
    label: "SOMBACOUR",
    value: "25549",
    type: "common",
  },
  {
    label: "DOMMARTIN",
    value: "25201",
    type: "common",
  },
  {
    label: "CHAFFOIS",
    value: "25110",
    type: "common",
  },
  {
    label: "BIANS-LES-USIERS",
    value: "25060",
    type: "common",
  },
  {
    label: "CHAPELLE-D'HUIN",
    value: "25122",
    type: "common",
  },
  {
    label: "LEVIER",
    value: "25334",
    type: "common",
  },
  {
    label: "VILLENEUVE-D'AMONT",
    value: "25621",
    type: "common",
  },
  {
    label: "SAINTE-ANNE",
    value: "25513",
    type: "common",
  },
  {
    label: "GEVRESIN",
    value: "25270",
    type: "common",
  },
  {
    label: "CROUZET-MIGETTE",
    value: "25180",
    type: "common",
  },
  {
    label: "ARC-SOUS-MONTENOT",
    value: "25026",
    type: "common",
  },
  {
    label: "SAIZENAY",
    value: "39497",
    type: "common",
  },
  {
    label: "GERAISE",
    value: "39248",
    type: "common",
  },
  {
    label: "DOURNON",
    value: "39202",
    type: "common",
  },
  {
    label: "CLUCY",
    value: "39155",
    type: "common",
  },
  {
    label: "CERNANS",
    value: "39084",
    type: "common",
  },
  {
    label: "SALINS-LES-BAINS",
    value: "39500",
    type: "common",
  },
  {
    label: "SAINT-THIEBAUD",
    value: "39495",
    type: "common",
  },
  {
    label: "PRETIN",
    value: "39444",
    type: "common",
  },
  {
    label: "PAGNOZ",
    value: "39403",
    type: "common",
  },
  {
    label: "MARNOZ",
    value: "39315",
    type: "common",
  },
  {
    label: "BRACON",
    value: "39072",
    type: "common",
  },
  {
    label: "AIGLEPIERRE",
    value: "39006",
    type: "common",
  },
  {
    label: "VILLETTE-LES-ARBOIS",
    value: "39572",
    type: "common",
  },
  {
    label: "VILLENEUVE-D'AVAL",
    value: "39565",
    type: "common",
  },
  {
    label: "SAINT-CYR-MONTMALIN",
    value: "39479",
    type: "common",
  },
  {
    label: "MOUCHARD",
    value: "39370",
    type: "common",
  },
  {
    label: "MONTIGNY-LES-ARSURES",
    value: "39355",
    type: "common",
  },
  {
    label: "LES ARSURES",
    value: "39019",
    type: "common",
  },
  {
    label: "VADANS",
    value: "39539",
    type: "common",
  },
  {
    label: "MOLAMBOZ",
    value: "39337",
    type: "common",
  },
  {
    label: "MATHENAY",
    value: "39319",
    type: "common",
  },
  {
    label: "LA FERTE",
    value: "39223",
    type: "common",
  },
  {
    label: "VAUDREY",
    value: "39546",
    type: "common",
  },
  {
    label: "SOUVANS",
    value: "39520",
    type: "common",
  },
  {
    label: "MONT-SOUS-VAUDREY",
    value: "39365",
    type: "common",
  },
  {
    label: "BANS",
    value: "39037",
    type: "common",
  },
  {
    label: "VILLERS-ROBERT",
    value: "39571",
    type: "common",
  },
  {
    label: "SELIGNEY",
    value: "39507",
    type: "common",
  },
  {
    label: "LE DESCHAUX",
    value: "39193",
    type: "common",
  },
  {
    label: "SAINT-BARAING",
    value: "39477",
    type: "common",
  },
  {
    label: "GATEY",
    value: "39245",
    type: "common",
  },
  {
    label: "CHAUSSIN",
    value: "39128",
    type: "common",
  },
  {
    label: "BALAISEAUX",
    value: "39034",
    type: "common",
  },
  {
    label: "ASNANS-BEAUVOISIN",
    value: "39022",
    type: "common",
  },
  {
    label: "PESEUX",
    value: "39412",
    type: "common",
  },
  {
    label: "LONGWY-SUR-LE-DOUBS",
    value: "39299",
    type: "common",
  },
  {
    label: "CHEMIN",
    value: "39138",
    type: "common",
  },
  {
    label: "POURLANS",
    value: "71357",
    type: "common",
  },
  {
    label: "ANNOIRE",
    value: "39011",
    type: "common",
  },
  {
    label: "BOUSSELANGE",
    value: "21095",
    type: "common",
  },
  {
    label: "CLUX-VILLENEUVE",
    value: "71578",
    type: "common",
  },
  {
    label: "MONT-LES-SEURRE",
    value: "71315",
    type: "common",
  },
  {
    label: "TRUGNY",
    value: "21647",
    type: "common",
  },
  {
    label: "SEURRE",
    value: "21607",
    type: "common",
  },
  {
    label: "JALLANGES",
    value: "21322",
    type: "common",
  },
  {
    label: "ECUELLES",
    value: "71186",
    type: "common",
  },
  {
    label: "LABERGEMENT-LES-SEURRE",
    value: "21332",
    type: "common",
  },
  {
    label: "CHIVRES",
    value: "21172",
    type: "common",
  },
  {
    label: "SAINT-MARTIN-EN-GATINOIS",
    value: "71457",
    type: "common",
  },
  {
    label: "SAINT-GERVAIS-EN-VALLIERE",
    value: "71423",
    type: "common",
  },
  {
    label: "PALLEAU",
    value: "71341",
    type: "common",
  },
  {
    label: "MEURSANGES",
    value: "21411",
    type: "common",
  },
  {
    label: "CORGENGOUX",
    value: "21193",
    type: "common",
  },
  {
    label: "CHEVIGNY-EN-VALIERE",
    value: "21170",
    type: "common",
  },
  {
    label: "SAINTE-MARIE-LA-BLANCHE",
    value: "21558",
    type: "common",
  },
  {
    label: "MERCEUIL",
    value: "21405",
    type: "common",
  },
  {
    label: "LEVERNOIS",
    value: "21347",
    type: "common",
  },
  {
    label: "COMBERTAULT",
    value: "21185",
    type: "common",
  },
  {
    label: "VOLNAY",
    value: "21712",
    type: "common",
  },
  {
    label: "TAILLY",
    value: "21616",
    type: "common",
  },
  {
    label: "MONTAGNY-LES-BEAUNE",
    value: "21423",
    type: "common",
  },
  {
    label: "MEURSAULT",
    value: "21412",
    type: "common",
  },
  {
    label: "BLIGNY-LES-BEAUNE",
    value: "21086",
    type: "common",
  },
  {
    label: "SAINT-AUBIN",
    value: "21541",
    type: "common",
  },
  {
    label: "MONTHELIE",
    value: "21428",
    type: "common",
  },
  {
    label: "AUXEY-DURESSES",
    value: "21037",
    type: "common",
  },
  {
    label: "SANTOSSE",
    value: "21583",
    type: "common",
  },
  {
    label: "NOLAY",
    value: "21461",
    type: "common",
  },
  {
    label: "CORMOT-VAUCHIGNON",
    value: "21195",
    type: "common",
  },
  {
    label: "BAUBIGNY",
    value: "21050",
    type: "common",
  },
  {
    label: "SAISY",
    value: "71493",
    type: "common",
  },
  {
    label: "EPINAC",
    value: "71190",
    type: "common",
  },
  {
    label: "AUBIGNY-LA-RONCE",
    value: "21032",
    type: "common",
  },
  {
    label: "SULLY",
    value: "71530",
    type: "common",
  },
  {
    label: "MORLET",
    value: "71322",
    type: "common",
  },
  {
    label: "CURGY",
    value: "71162",
    type: "common",
  },
  {
    label: "SAINT-FORGEOT",
    value: "71414",
    type: "common",
  },
  {
    label: "DRACY-SAINT-LOUP",
    value: "71184",
    type: "common",
  },
  {
    label: "TAVERNAY",
    value: "71535",
    type: "common",
  },
  {
    label: "LA CELLE-EN-MORVAN",
    value: "71509",
    type: "common",
  },
  {
    label: "MONTHELON",
    value: "71313",
    type: "common",
  },
  {
    label: "LA GRANDE-VERRIERE",
    value: "71223",
    type: "common",
  },
  {
    label: "SAINT-PRIX",
    value: "71472",
    type: "common",
  },
  {
    label: "FACHIN",
    value: "58111",
    type: "common",
  },
  {
    label: "SERMAGES",
    value: "58277",
    type: "common",
  },
  {
    label: "SAINT-LEGER-DE-FOUGERET",
    value: "58249",
    type: "common",
  },
  {
    label: "ONLAY",
    value: "58199",
    type: "common",
  },
  {
    label: "MOULINS-ENGILBERT",
    value: "58182",
    type: "common",
  },
  {
    label: "LIMANTON",
    value: "58142",
    type: "common",
  },
  {
    label: "BRINAY",
    value: "58040",
    type: "common",
  },
  {
    label: "BICHES",
    value: "58030",
    type: "common",
  },
  {
    label: "TINTURY",
    value: "58292",
    type: "common",
  },
  {
    label: "FRASNAY-REUGNY",
    value: "58119",
    type: "common",
  },
  {
    label: "ANLEZY",
    value: "58006",
    type: "common",
  },
  {
    label: "SAINT-BENIN-D'AZY",
    value: "58232",
    type: "common",
  },
  {
    label: "CIZELY",
    value: "58078",
    type: "common",
  },
  {
    label: "BILLY-CHEVANNES",
    value: "58031",
    type: "common",
  },
  {
    label: "SAINT-JEAN-AUX-AMOGNES",
    value: "58247",
    type: "common",
  },
  {
    label: "SAUVIGNY-LES-BOIS",
    value: "58273",
    type: "common",
  },
  {
    label: "MONTIGNY-AUX-AMOGNES",
    value: "58176",
    type: "common",
  },
  {
    label: "SAINT-ELOI",
    value: "58238",
    type: "common",
  },
  {
    label: "NEVERS",
    value: "58194",
    type: "common",
  },
  {
    label: "COULANGES-LES-NEVERS",
    value: "58088",
    type: "common",
  },
  {
    label: "MARZY",
    value: "58160",
    type: "common",
  },
  {
    label: "FOURCHAMBAULT",
    value: "58117",
    type: "common",
  },
  {
    label: "TORTERON",
    value: "18265",
    type: "common",
  },
  {
    label: "COURS-LES-BARRES",
    value: "18075",
    type: "common",
  },
  {
    label: "LE CHAUTAY",
    value: "18062",
    type: "common",
  },
  {
    label: "SAINT-HILAIRE-DE-GONDILLY",
    value: "18215",
    type: "common",
  },
  {
    label: "TENDRON",
    value: "18260",
    type: "common",
  },
  {
    label: "NERONDES",
    value: "18160",
    type: "common",
  },
  {
    label: "BENGY-SUR-CRAON",
    value: "18027",
    type: "common",
  },
  {
    label: "JUSSY-CHAMPAGNE",
    value: "18119",
    type: "common",
  },
  {
    label: "AVORD",
    value: "18018",
    type: "common",
  },
  {
    label: "CROSSES",
    value: "18081",
    type: "common",
  },
  {
    label: "SOYE-EN-SEPTAINE",
    value: "18254",
    type: "common",
  },
  {
    label: "SAINT-JUST",
    value: "18218",
    type: "common",
  },
  {
    label: "TROUY",
    value: "18267",
    type: "common",
  },
  {
    label: "PLAIMPIED-GIVAUDINS",
    value: "18180",
    type: "common",
  },
  {
    label: "LISSAY-LOCHY",
    value: "18129",
    type: "common",
  },
  {
    label: "LE SUBDRAY",
    value: "18255",
    type: "common",
  },
  {
    label: "VILLENEUVE-SUR-CHER",
    value: "18285",
    type: "common",
  },
  {
    label: "SAINT-FLORENT-SUR-CHER",
    value: "18207",
    type: "common",
  },
  {
    label: "CHAROST",
    value: "18055",
    type: "common",
  },
  {
    label: "SAINT-GEORGES-SUR-ARNON",
    value: "36195",
    type: "common",
  },
  {
    label: "MIGNY",
    value: "36125",
    type: "common",
  },
  {
    label: "POISIEUX",
    value: "18182",
    type: "common",
  },
  {
    label: "SAINTE-LIZAIGNE",
    value: "36199",
    type: "common",
  },
  {
    label: "LES BORDES",
    value: "36021",
    type: "common",
  },
  {
    label: "LIZERAY",
    value: "36098",
    type: "common",
  },
  {
    label: "MENETREOLS-SOUS-VATAN",
    value: "36116",
    type: "common",
  },
  {
    label: "LINIEZ",
    value: "36097",
    type: "common",
  },
  {
    label: "BRETAGNE",
    value: "36024",
    type: "common",
  },
  {
    label: "LEVROUX",
    value: "36093",
    type: "common",
  },
  {
    label: "MOULINS-SUR-CEPHONS",
    value: "36135",
    type: "common",
  },
  {
    label: "SELLES-SUR-NAHON",
    value: "36216",
    type: "common",
  },
  {
    label: "PELLEVOISIN",
    value: "36155",
    type: "common",
  },
  {
    label: "FREDILLE",
    value: "36080",
    type: "common",
  },
  {
    label: "VILLEGOUIN",
    value: "36243",
    type: "common",
  },
  {
    label: "SAINT-MEDARD",
    value: "36203",
    type: "common",
  },
  {
    label: "LE TRANGER",
    value: "36225",
    type: "common",
  },
  {
    label: "CHATILLON-SUR-INDRE",
    value: "36045",
    type: "common",
  },
  {
    label: "SAINT-CYRAN-DU-JAMBOT",
    value: "36188",
    type: "common",
  },
  {
    label: "FLERE-LA-RIVIERE",
    value: "36074",
    type: "common",
  },
  {
    label: "SAINT-FLOVIER",
    value: "37218",
    type: "common",
  },
  {
    label: "BETZ-LE-CHATEAU",
    value: "37026",
    type: "common",
  },
  {
    label: "PAULMY",
    value: "37181",
    type: "common",
  },
  {
    label: "FERRIERE-LARCON",
    value: "37107",
    type: "common",
  },
  {
    label: "NEUILLY-LE-BRIGNON",
    value: "37168",
    type: "common",
  },
  {
    label: "CUSSAY",
    value: "37094",
    type: "common",
  },
  {
    label: "BUXEUIL",
    value: "86042",
    type: "common",
  },
  {
    label: "DESCARTES",
    value: "37115",
    type: "common",
  },
  {
    label: "PORT-DE-PILES",
    value: "86195",
    type: "common",
  },
  {
    label: "LES ORMES",
    value: "86183",
    type: "common",
  },
  {
    label: "PUSSIGNY",
    value: "37190",
    type: "common",
  },
  {
    label: "LA CELLE-SAINT-AVANT",
    value: "37045",
    type: "common",
  },
  {
    label: "ANTOGNY-LE-TILLAC",
    value: "37005",
    type: "common",
  },
  {
    label: "PORTS-SUR-VIENNE",
    value: "37187",
    type: "common",
  },
  {
    label: "MARIGNY-MARMANDE",
    value: "37148",
    type: "common",
  },
  {
    label: "LUZE",
    value: "37140",
    type: "common",
  },
  {
    label: "RAZINES",
    value: "37191",
    type: "common",
  },
  {
    label: "JAULNAY",
    value: "37121",
    type: "common",
  },
  {
    label: "BRASLOU",
    value: "37034",
    type: "common",
  },
  {
    label: "RICHELIEU",
    value: "37196",
    type: "common",
  },
  {
    label: "CHAVEIGNES",
    value: "37065",
    type: "common",
  },
  {
    label: "BRAYE-SOUS-FAYE",
    value: "37035",
    type: "common",
  },
  {
    label: "NUEIL-SOUS-FAYE",
    value: "86181",
    type: "common",
  },
  {
    label: "MAULAY",
    value: "86151",
    type: "common",
  },
  {
    label: "MESSEME",
    value: "86156",
    type: "common",
  },
  {
    label: "LA ROCHE-RIGAULT",
    value: "86079",
    type: "common",
  },
  {
    label: "LOUDUN",
    value: "86137",
    type: "common",
  },
  {
    label: "CHALAIS",
    value: "86049",
    type: "common",
  },
  {
    label: "SAINT-LAON",
    value: "86227",
    type: "common",
  },
  {
    label: "RANTON",
    value: "86205",
    type: "common",
  },
  {
    label: "MOUTERRE-SILLY",
    value: "86173",
    type: "common",
  },
  {
    label: "GLENOUZE",
    value: "86106",
    type: "common",
  },
  {
    label: "SAINT-LEGER-DE-MONTBRUN",
    value: "79265",
    type: "common",
  },
  {
    label: "PAS-DE-JEU",
    value: "79203",
    type: "common",
  },
  {
    label: "SAINT-JEAN-DE-THOUARS",
    value: "79259",
    type: "common",
  },
  {
    label: "SAINT-JACQUES-DE-THOUARS",
    value: "79258",
    type: "common",
  },
  {
    label: "ARGENTONNAY",
    value: "79013",
    type: "common",
  },
  {
    label: "VOULMENTIN",
    value: "79242",
    type: "common",
  },
  {
    label: "SAINT-PIERRE-DES-ECHAUBROGNES",
    value: "79289",
    type: "common",
  },
  {
    label: "MAULEON",
    value: "79079",
    type: "common",
  },
  {
    label: "SAINT-LAURENT-SUR-SEVRE",
    value: "85238",
    type: "common",
  },
  {
    label: "SAINT-MALO-DU-BOIS",
    value: "85240",
    type: "common",
  },
  {
    label: "SAINT-MARTIN-DES-TILLEULS",
    value: "85247",
    type: "common",
  },
  {
    label: "LA GAUBRETIERE",
    value: "85097",
    type: "common",
  },
  {
    label: "LES LANDES-GENUSSON",
    value: "85119",
    type: "common",
  },
  {
    label: "BAZOGES-EN-PAILLERS",
    value: "85013",
    type: "common",
  },
  {
    label: "LA BOISSIERE-DE-MONTAIGU",
    value: "85025",
    type: "common",
  },
  {
    label: "MONTREVERD",
    value: "85197",
    type: "common",
  },
  {
    label: "L'HERBERGEMENT",
    value: "85108",
    type: "common",
  },
  {
    label: "ROCHESERVIERE",
    value: "85190",
    type: "common",
  },
  {
    label: "CORCOUE-SUR-LOGNE",
    value: "44156",
    type: "common",
  },
  {
    label: "TOUVOIS",
    value: "44206",
    type: "common",
  },
  {
    label: "SAINT-ETIENNE-DE-MER-MORTE",
    value: "44157",
    type: "common",
  },
  {
    label: "LA GARNACHE",
    value: "85096",
    type: "common",
  },
  {
    label: "CHATEAUNEUF",
    value: "85062",
    type: "common",
  },
  {
    label: "BOIS-DE-CENE",
    value: "85024",
    type: "common",
  },
  {
    label: "SAINT-GERVAIS",
    value: "85221",
    type: "common",
  },
  {
    label: "BEAUVOIR-SUR-MER",
    value: "85018",
    type: "common",
  },
  {
    label: "BARBATRE",
    value: "85011",
    type: "common",
  },
  {
    label: "VERRIERES-DE-JOUX",
    value: "25609",
    type: "common",
  },
  {
    label: "LES FOURGS",
    value: "25254",
    type: "common",
  },
  {
    label: "PONTARLIER",
    value: "25462",
    type: "common",
  },
  {
    label: "OYE-ET-PALLET",
    value: "25442",
    type: "common",
  },
  {
    label: "GRANGES-NARBOZ",
    value: "25293",
    type: "common",
  },
  {
    label: "LA CLUSE-ET-MIJOUX",
    value: "25157",
    type: "common",
  },
  {
    label: "SAINTE-COLOMBE",
    value: "25515",
    type: "common",
  },
  {
    label: "HOUTAUD",
    value: "25309",
    type: "common",
  },
  {
    label: "BANNANS",
    value: "25041",
    type: "common",
  },
  {
    label: "LA RIVIERE-DRUGEON",
    value: "25493",
    type: "common",
  },
  {
    label: "FRASNE",
    value: "25259",
    type: "common",
  },
  {
    label: "DOMPIERRE-LES-TILLEULS",
    value: "25202",
    type: "common",
  },
  {
    label: "BULLE",
    value: "25100",
    type: "common",
  },
  {
    label: "COURVIERES",
    value: "25176",
    type: "common",
  },
  {
    label: "BOUJAILLES",
    value: "25079",
    type: "common",
  },
  {
    label: "LEMUY",
    value: "39291",
    type: "common",
  },
  {
    label: "VILLERS-SOUS-CHALAMONT",
    value: "25627",
    type: "common",
  },
  {
    label: "ARESCHES",
    value: "39586",
    type: "common",
  },
  {
    label: "THESY",
    value: "39529",
    type: "common",
  },
  {
    label: "PONT-D'HERY",
    value: "39436",
    type: "common",
  },
  {
    label: "MONTMARLON",
    value: "39359",
    type: "common",
  },
  {
    label: "ANDELOT-EN-MONTAGNE",
    value: "39009",
    type: "common",
  },
  {
    label: "ABERGEMENT-LES-THESY",
    value: "39004",
    type: "common",
  },
  {
    label: "LES PLANCHES-PRES-ARBOIS",
    value: "39425",
    type: "common",
  },
  {
    label: "MESNAY",
    value: "39325",
    type: "common",
  },
  {
    label: "IVORY",
    value: "39267",
    type: "common",
  },
  {
    label: "CHILLY-SUR-SALINS",
    value: "39147",
    type: "common",
  },
  {
    label: "CHAUX-CHAMPAGNY",
    value: "39133",
    type: "common",
  },
  {
    label: "PUPILLIN",
    value: "39446",
    type: "common",
  },
  {
    label: "BUVILLY",
    value: "39081",
    type: "common",
  },
  {
    label: "ARBOIS",
    value: "39013",
    type: "common",
  },
  {
    label: "MONTHOLIER",
    value: "39354",
    type: "common",
  },
  {
    label: "GROZON",
    value: "39263",
    type: "common",
  },
  {
    label: "BRAINANS",
    value: "39073",
    type: "common",
  },
  {
    label: "AUMONT",
    value: "39028",
    type: "common",
  },
  {
    label: "ABERGEMENT-LE-PETIT",
    value: "39003",
    type: "common",
  },
  {
    label: "ABERGEMENT-LE-GRAND",
    value: "39002",
    type: "common",
  },
  {
    label: "VILLERS-LES-BOIS",
    value: "39570",
    type: "common",
  },
  {
    label: "OUSSIERES",
    value: "39401",
    type: "common",
  },
  {
    label: "NEUVILLEY",
    value: "39386",
    type: "common",
  },
  {
    label: "COLONNE",
    value: "39159",
    type: "common",
  },
  {
    label: "TASSENIERES",
    value: "39525",
    type: "common",
  },
  {
    label: "SERGENON",
    value: "39512",
    type: "common",
  },
  {
    label: "SERGENAUX",
    value: "39511",
    type: "common",
  },
  {
    label: "LES DEUX-FAYS",
    value: "39196",
    type: "common",
  },
  {
    label: "CHENE-BERNARD",
    value: "39139",
    type: "common",
  },
  {
    label: "LE CHATELEY",
    value: "39119",
    type: "common",
  },
  {
    label: "CHAMPROUGIER",
    value: "39100",
    type: "common",
  },
  {
    label: "BRETENIERES",
    value: "39077",
    type: "common",
  },
  {
    label: "BIEFMORIN",
    value: "39054",
    type: "common",
  },
  {
    label: "RYE",
    value: "39472",
    type: "common",
  },
  {
    label: "PLEURE",
    value: "39429",
    type: "common",
  },
  {
    label: "LES HAYS",
    value: "39266",
    type: "common",
  },
  {
    label: "LES ESSARDS-TAIGNEVAUX",
    value: "39211",
    type: "common",
  },
  {
    label: "CHAINEE-DES-COUPIS",
    value: "39090",
    type: "common",
  },
  {
    label: "AUTHUMES",
    value: "71013",
    type: "common",
  },
  {
    label: "PETIT-NOIR",
    value: "39415",
    type: "common",
  },
  {
    label: "NEUBLANS-ABERGEMENT",
    value: "39385",
    type: "common",
  },
  {
    label: "CHARETTE-VARENNES",
    value: "71101",
    type: "common",
  },
  {
    label: "PIERRE-DE-BRESSE",
    value: "71351",
    type: "common",
  },
  {
    label: "LONGEPIERRE",
    value: "71262",
    type: "common",
  },
  {
    label: "LAYS-SUR-LE-DOUBS",
    value: "71254",
    type: "common",
  },
  {
    label: "FRETTERANS",
    value: "71207",
    type: "common",
  },
  {
    label: "PONTOUX",
    value: "71355",
    type: "common",
  },
  {
    label: "NAVILLY",
    value: "71329",
    type: "common",
  },
  {
    label: "FRONTENARD",
    value: "71208",
    type: "common",
  },
  {
    label: "SERMESSE",
    value: "71517",
    type: "common",
  },
  {
    label: "SAUNIERES",
    value: "71504",
    type: "common",
  },
  {
    label: "CHARNAY-LES-CHALON",
    value: "71104",
    type: "common",
  },
  {
    label: "LES BORDES",
    value: "71043",
    type: "common",
  },
  {
    label: "VERDUN-SUR-LE-DOUBS",
    value: "71566",
    type: "common",
  },
  {
    label: "BRAGNY-SUR-SAONE",
    value: "71054",
    type: "common",
  },
  {
    label: "ALLEREY-SUR-SAONE",
    value: "71003",
    type: "common",
  },
  {
    label: "SAINT-LOUP-GEANGES",
    value: "71443",
    type: "common",
  },
  {
    label: "DEMIGNY",
    value: "71170",
    type: "common",
  },
  {
    label: "CHAUDENAY",
    value: "71119",
    type: "common",
  },
  {
    label: "EBATY",
    value: "21236",
    type: "common",
  },
  {
    label: "CORCELLES-LES-ARTS",
    value: "21190",
    type: "common",
  },
  {
    label: "REMIGNY",
    value: "71369",
    type: "common",
  },
  {
    label: "BOUZERON",
    value: "71051",
    type: "common",
  },
  {
    label: "SANTENAY",
    value: "21582",
    type: "common",
  },
  {
    label: "PULIGNY-MONTRACHET",
    value: "21512",
    type: "common",
  },
  {
    label: "CORPEAU",
    value: "21196",
    type: "common",
  },
  {
    label: "CHASSAGNE-MONTRACHET",
    value: "21150",
    type: "common",
  },
  {
    label: "SAMPIGNY-LES-MARANGES",
    value: "71496",
    type: "common",
  },
  {
    label: "PARIS-L'HOPITAL",
    value: "71343",
    type: "common",
  },
  {
    label: "EPERTULLY",
    value: "71188",
    type: "common",
  },
  {
    label: "DEZIZE-LES-MARANGES",
    value: "71174",
    type: "common",
  },
  {
    label: "CREOT",
    value: "71151",
    type: "common",
  },
  {
    label: "CHANGE",
    value: "71085",
    type: "common",
  },
  {
    label: "LA ROCHEPOT",
    value: "21527",
    type: "common",
  },
  {
    label: "SAINT-MARTIN-DE-COMMUNE",
    value: "71450",
    type: "common",
  },
  {
    label: "SAINT-GERVAIS-SUR-COUCHES",
    value: "71424",
    type: "common",
  },
  {
    label: "COLLONGE-LA-MADELEINE",
    value: "71140",
    type: "common",
  },
  {
    label: "TINTRY",
    value: "71539",
    type: "common",
  },
  {
    label: "SAINT-EMILAND",
    value: "71409",
    type: "common",
  },
  {
    label: "AUXY",
    value: "71015",
    type: "common",
  },
  {
    label: "AUTUN",
    value: "71014",
    type: "common",
  },
  {
    label: "LAIZY",
    value: "71251",
    type: "common",
  },
  {
    label: "BRION",
    value: "71062",
    type: "common",
  },
  {
    label: "SAINT-LEGER-SOUS-BEUVRAY",
    value: "71440",
    type: "common",
  },
  {
    label: "GLUX-EN-GLENNE",
    value: "58128",
    type: "common",
  },
  {
    label: "VILLAPOURCON",
    value: "58309",
    type: "common",
  },
  {
    label: "PREPORCHE",
    value: "58219",
    type: "common",
  },
  {
    label: "VANDENESSE",
    value: "58301",
    type: "common",
  },
  {
    label: "MONTIGNY-SUR-CANNE",
    value: "58178",
    type: "common",
  },
  {
    label: "ISENAY",
    value: "58135",
    type: "common",
  },
  {
    label: "FERTREVE",
    value: "58113",
    type: "common",
  },
  {
    label: "VILLE-LANGY",
    value: "58311",
    type: "common",
  },
  {
    label: "THIANGES",
    value: "58291",
    type: "common",
  },
  {
    label: "TROIS-VEVRES",
    value: "58297",
    type: "common",
  },
  {
    label: "LIMON",
    value: "58143",
    type: "common",
  },
  {
    label: "LA FERMETE",
    value: "58112",
    type: "common",
  },
  {
    label: "BEAUMONT-SARDOLLES",
    value: "58028",
    type: "common",
  },
  {
    label: "IMPHY",
    value: "58134",
    type: "common",
  },
  {
    label: "CHEVENON",
    value: "58072",
    type: "common",
  },
  {
    label: "SERMOISE-SUR-LOIRE",
    value: "58278",
    type: "common",
  },
  {
    label: "CHALLUY",
    value: "58051",
    type: "common",
  },
  {
    label: "GIMOUILLE",
    value: "58126",
    type: "common",
  },
  {
    label: "CUFFY",
    value: "18082",
    type: "common",
  },
  {
    label: "LA GUERCHE-SUR-L'AUBOIS",
    value: "18108",
    type: "common",
  },
  {
    label: "GERMIGNY-L'EXEMPT",
    value: "18101",
    type: "common",
  },
  {
    label: "IGNOL",
    value: "18113",
    type: "common",
  },
  {
    label: "FLAVIGNY",
    value: "18095",
    type: "common",
  },
  {
    label: "CROISY",
    value: "18080",
    type: "common",
  },
  {
    label: "OUROUER-LES-BOURDELINS",
    value: "18175",
    type: "common",
  },
  {
    label: "LUGNY-BOURBONNAIS",
    value: "18131",
    type: "common",
  },
  {
    label: "CORNUSSE",
    value: "18072",
    type: "common",
  },
  {
    label: "RAYMOND",
    value: "18191",
    type: "common",
  },
  {
    label: "OSMERY",
    value: "18173",
    type: "common",
  },
  {
    label: "VORNAY",
    value: "18289",
    type: "common",
  },
  {
    label: "ANNOIX",
    value: "18006",
    type: "common",
  },
  {
    label: "VORLY",
    value: "18288",
    type: "common",
  },
  {
    label: "SAINT-DENIS-DE-PALIN",
    value: "18204",
    type: "common",
  },
  {
    label: "SENNECAY",
    value: "18248",
    type: "common",
  },
  {
    label: "LEVET",
    value: "18126",
    type: "common",
  },
  {
    label: "SAINT-CAPRAIS",
    value: "18201",
    type: "common",
  },
  {
    label: "LAPAN",
    value: "18122",
    type: "common",
  },
  {
    label: "ARCAY",
    value: "18008",
    type: "common",
  },
  {
    label: "LUNERY",
    value: "18133",
    type: "common",
  },
  {
    label: "SAUGY",
    value: "18244",
    type: "common",
  },
  {
    label: "SAINT-AMBROIX",
    value: "18198",
    type: "common",
  },
  {
    label: "CIVRAY",
    value: "18066",
    type: "common",
  },
  {
    label: "CHOUDAY",
    value: "36052",
    type: "common",
  },
  {
    label: "SAINT-AOUSTRILLE",
    value: "36179",
    type: "common",
  },
  {
    label: "ISSOUDUN",
    value: "36088",
    type: "common",
  },
  {
    label: "SAINT-VALENTIN",
    value: "36209",
    type: "common",
  },
  {
    label: "LA CHAMPENOISE",
    value: "36037",
    type: "common",
  },
  {
    label: "BRION",
    value: "36026",
    type: "common",
  },
  {
    label: "VILLEGONGIS",
    value: "36242",
    type: "common",
  },
  {
    label: "FRANCILLON",
    value: "36079",
    type: "common",
  },
  {
    label: "SOUGE",
    value: "36218",
    type: "common",
  },
  {
    label: "ARGY",
    value: "36007",
    type: "common",
  },
  {
    label: "SAINT-GENOU",
    value: "36194",
    type: "common",
  },
  {
    label: "PALLUAU-SUR-INDRE",
    value: "36149",
    type: "common",
  },
  {
    label: "MURS",
    value: "36136",
    type: "common",
  },
  {
    label: "CLION",
    value: "36055",
    type: "common",
  },
  {
    label: "CLERE-DU-BOIS",
    value: "36054",
    type: "common",
  },
  {
    label: "CHARNIZAY",
    value: "37061",
    type: "common",
  },
  {
    label: "LE PETIT-PRESSIGNY",
    value: "37184",
    type: "common",
  },
  {
    label: "LA CELLE-GUENAND",
    value: "37044",
    type: "common",
  },
  {
    label: "LE GRAND-PRESSIGNY",
    value: "37113",
    type: "common",
  },
  {
    label: "ABILLY",
    value: "37001",
    type: "common",
  },
  {
    label: "SAINT-REMY-SUR-CREUSE",
    value: "86241",
    type: "common",
  },
  {
    label: "VAUX-SUR-VIENNE",
    value: "86279",
    type: "common",
  },
  {
    label: "DANGE-SAINT-ROMAIN",
    value: "86092",
    type: "common",
  },
  {
    label: "VELLECHES",
    value: "86280",
    type: "common",
  },
  {
    label: "MONDION",
    value: "86162",
    type: "common",
  },
  {
    label: "LEIGNE-SUR-USSEAU",
    value: "86127",
    type: "common",
  },
  {
    label: "SERIGNY",
    value: "86260",
    type: "common",
  },
  {
    label: "SAINT-CHRISTOPHE",
    value: "86217",
    type: "common",
  },
  {
    label: "FAYE-LA-VINEUSE",
    value: "37105",
    type: "common",
  },
  {
    label: "PRINCAY",
    value: "86201",
    type: "common",
  },
  {
    label: "DERCE",
    value: "86093",
    type: "common",
  },
  {
    label: "BERTHEGON",
    value: "86023",
    type: "common",
  },
  {
    label: "MONTS-SUR-GUESNES",
    value: "86167",
    type: "common",
  },
  {
    label: "GUESNES",
    value: "86109",
    type: "common",
  },
  {
    label: "MARTAIZE",
    value: "86149",
    type: "common",
  },
  {
    label: "AULNAY",
    value: "86013",
    type: "common",
  },
  {
    label: "ANGLIERS",
    value: "86005",
    type: "common",
  },
  {
    label: "ARCAY",
    value: "86008",
    type: "common",
  },
  {
    label: "SAINT-VARENT",
    value: "79299",
    type: "common",
  },
  {
    label: "SAINT-GENEROUX",
    value: "79252",
    type: "common",
  },
  {
    label: "LUZAY",
    value: "79161",
    type: "common",
  },
  {
    label: "SAINTE-GEMME",
    value: "79250",
    type: "common",
  },
  {
    label: "LUCHE-THOUARSAIS",
    value: "79159",
    type: "common",
  },
  {
    label: "COULONGES-THOUARSAIS",
    value: "79102",
    type: "common",
  },
  {
    label: "SAINT-AUBIN-DU-PLAIN",
    value: "79238",
    type: "common",
  },
  {
    label: "NUEIL-LES-AUBIERS",
    value: "79195",
    type: "common",
  },
  {
    label: "LE PIN",
    value: "79210",
    type: "common",
  },
  {
    label: "SAINT-AMAND-SUR-SEVRE",
    value: "79235",
    type: "common",
  },
  {
    label: "LA PETITE-BOISSIERE",
    value: "79207",
    type: "common",
  },
  {
    label: "TREIZE-VENTS",
    value: "85296",
    type: "common",
  },
  {
    label: "MALLIEVRE",
    value: "85134",
    type: "common",
  },
  {
    label: "LES EPESSES",
    value: "85082",
    type: "common",
  },
  {
    label: "LES HERBIERS",
    value: "85109",
    type: "common",
  },
  {
    label: "SAINT-FULGENT",
    value: "85215",
    type: "common",
  },
  {
    label: "BEAUREPAIRE",
    value: "85017",
    type: "common",
  },
  {
    label: "CHAVAGNES-EN-PAILLERS",
    value: "85065",
    type: "common",
  },
  {
    label: "LES BROUZILS",
    value: "85038",
    type: "common",
  },
  {
    label: "LES LUCS-SUR-BOULOGNE",
    value: "85129",
    type: "common",
  },
  {
    label: "LEGE",
    value: "44081",
    type: "common",
  },
  {
    label: "GRAND'LANDES",
    value: "85102",
    type: "common",
  },
  {
    label: "FROIDFOND",
    value: "85095",
    type: "common",
  },
  {
    label: "FALLERON",
    value: "85086",
    type: "common",
  },
  {
    label: "SALLERTAINE",
    value: "85280",
    type: "common",
  },
  {
    label: "SAINT-URBAIN",
    value: "85273",
    type: "common",
  },
  {
    label: "NOTRE-DAME-DE-MONTS",
    value: "85164",
    type: "common",
  },
  {
    label: "LA BARRE-DE-MONTS",
    value: "85012",
    type: "common",
  },
  {
    label: "TOUILLON-ET-LOUTELET",
    value: "25565",
    type: "common",
  },
  {
    label: "MONTPERREUX",
    value: "25405",
    type: "common",
  },
  {
    label: "MALBUISSON",
    value: "25361",
    type: "common",
  },
  {
    label: "LES HOPITAUX-VIEUX",
    value: "25308",
    type: "common",
  },
  {
    label: "LES HOPITAUX-NEUFS",
    value: "25307",
    type: "common",
  },
  {
    label: "LES GRANGETTES",
    value: "25295",
    type: "common",
  },
  {
    label: "VAUX-ET-CHANTEGRUE",
    value: "25592",
    type: "common",
  },
  {
    label: "SAINT-POINT-LAC",
    value: "25525",
    type: "common",
  },
  {
    label: "LA PLANEE",
    value: "25459",
    type: "common",
  },
  {
    label: "MALPAS",
    value: "25362",
    type: "common",
  },
  {
    label: "BOUVERANS",
    value: "25085",
    type: "common",
  },
  {
    label: "BONNEVAUX",
    value: "25075",
    type: "common",
  },
  {
    label: "CUVIER",
    value: "39187",
    type: "common",
  },
  {
    label: "CENSEAU",
    value: "39083",
    type: "common",
  },
  {
    label: "BIEF-DU-FOURG",
    value: "39053",
    type: "common",
  },
  {
    label: "SUPT",
    value: "39522",
    type: "common",
  },
  {
    label: "PLENISETTE",
    value: "39428",
    type: "common",
  },
  {
    label: "PLENISE",
    value: "39427",
    type: "common",
  },
  {
    label: "ESSERVAL-TARTRE",
    value: "39214",
    type: "common",
  },
  {
    label: "VERS-EN-MONTAGNE",
    value: "39554",
    type: "common",
  },
  {
    label: "LE LATET",
    value: "39281",
    type: "common",
  },
  {
    label: "LE LARDERET",
    value: "39277",
    type: "common",
  },
  {
    label: "CHAPOIS",
    value: "39105",
    type: "common",
  },
  {
    label: "VALEMPOULIERES",
    value: "39540",
    type: "common",
  },
  {
    label: "MOLAIN",
    value: "39336",
    type: "common",
  },
  {
    label: "LA CHATELAINE",
    value: "39116",
    type: "common",
  },
  {
    label: "VAUX-SUR-POLIGNY",
    value: "39548",
    type: "common",
  },
  {
    label: "CHAUSSENANS",
    value: "39127",
    type: "common",
  },
  {
    label: "CHAMOLE",
    value: "39094",
    type: "common",
  },
  {
    label: "BARRETAINE",
    value: "39040",
    type: "common",
  },
  {
    label: "VILLERSERINE",
    value: "39568",
    type: "common",
  },
  {
    label: "TOURMONT",
    value: "39535",
    type: "common",
  },
  {
    label: "SAINT-LOTHAIN",
    value: "39489",
    type: "common",
  },
  {
    label: "MIERY",
    value: "39330",
    type: "common",
  },
  {
    label: "TOULOUSE-LE-CHATEAU",
    value: "39533",
    type: "common",
  },
  {
    label: "SELLIERES",
    value: "39508",
    type: "common",
  },
  {
    label: "MONAY",
    value: "39342",
    type: "common",
  },
  {
    label: "DARBONNAY",
    value: "39191",
    type: "common",
  },
  {
    label: "LA CHARME",
    value: "39110",
    type: "common",
  },
  {
    label: "BERSAILLIN",
    value: "39049",
    type: "common",
  },
  {
    label: "LE VILLEY",
    value: "39575",
    type: "common",
  },
  {
    label: "VERS-SOUS-SELLIERES",
    value: "39555",
    type: "common",
  },
  {
    label: "FRANCHEVILLE",
    value: "39236",
    type: "common",
  },
  {
    label: "FOULENAY",
    value: "39234",
    type: "common",
  },
  {
    label: "CHEMENOT",
    value: "39136",
    type: "common",
  },
  {
    label: "LA CHAUX-EN-BRESSE",
    value: "39132",
    type: "common",
  },
  {
    label: "CHAUMERGY",
    value: "39124",
    type: "common",
  },
  {
    label: "LA CHASSAGNE",
    value: "39112",
    type: "common",
  },
  {
    label: "BOIS-DE-GAND",
    value: "39060",
    type: "common",
  },
  {
    label: "MOUTHIER-EN-BRESSE",
    value: "71326",
    type: "common",
  },
  {
    label: "BEAUVERNOIS",
    value: "71028",
    type: "common",
  },
  {
    label: "CHENE-SEC",
    value: "39140",
    type: "common",
  },
  {
    label: "TORPES",
    value: "71541",
    type: "common",
  },
  {
    label: "BELLEVESVRE",
    value: "71029",
    type: "common",
  },
  {
    label: "DAMPIERRE-EN-BRESSE",
    value: "71168",
    type: "common",
  },
  {
    label: "LA CHAUX",
    value: "71121",
    type: "common",
  },
  {
    label: "LA CHAPELLE-SAINT-SAUVEUR",
    value: "71093",
    type: "common",
  },
  {
    label: "TOUTENANT",
    value: "71544",
    type: "common",
  },
  {
    label: "SERRIGNY-EN-BRESSE",
    value: "71519",
    type: "common",
  },
  {
    label: "SAINT-BONNET-EN-BRESSE",
    value: "71396",
    type: "common",
  },
  {
    label: "LA RACINEUSE",
    value: "71364",
    type: "common",
  },
  {
    label: "SAINT-DIDIER-EN-BRESSE",
    value: "71405",
    type: "common",
  },
  {
    label: "CIEL",
    value: "71131",
    type: "common",
  },
  {
    label: "VERJUX",
    value: "71570",
    type: "common",
  },
  {
    label: "SAINT-MAURICE-EN-RIVIERE",
    value: "71462",
    type: "common",
  },
  {
    label: "DAMEREY",
    value: "71167",
    type: "common",
  },
  {
    label: "VIREY-LE-GRAND",
    value: "71585",
    type: "common",
  },
  {
    label: "SASSENAY",
    value: "71502",
    type: "common",
  },
  {
    label: "GERGY",
    value: "71215",
    type: "common",
  },
  {
    label: "LESSARD-LE-NATIONAL",
    value: "71257",
    type: "common",
  },
  {
    label: "FRAGNES-LA LOYERE",
    value: "71204",
    type: "common",
  },
  {
    label: "FONTAINES",
    value: "71202",
    type: "common",
  },
  {
    label: "FARGES-LES-CHALON",
    value: "71194",
    type: "common",
  },
  {
    label: "CHAGNY",
    value: "71073",
    type: "common",
  },
  {
    label: "RULLY",
    value: "71378",
    type: "common",
  },
  {
    label: "CHASSEY-LE-CAMP",
    value: "71109",
    type: "common",
  },
  {
    label: "ALUZE",
    value: "71005",
    type: "common",
  },
  {
    label: "SAINT-SERNIN-DU-PLAIN",
    value: "71480",
    type: "common",
  },
  {
    label: "SAINT-MAURICE-LES-COUCHES",
    value: "71464",
    type: "common",
  },
  {
    label: "SAINT-LEGER-SUR-DHEUNE",
    value: "71442",
    type: "common",
  },
  {
    label: "SAINT-GILLES",
    value: "71425",
    type: "common",
  },
  {
    label: "DENNEVY",
    value: "71171",
    type: "common",
  },
  {
    label: "CHEILLY-LES-MARANGES",
    value: "71122",
    type: "common",
  },
  {
    label: "CHARRECEY",
    value: "71107",
    type: "common",
  },
  {
    label: "CHAMILLY",
    value: "71078",
    type: "common",
  },
  {
    label: "SAINT-PIERRE-DE-VARENNES",
    value: "71468",
    type: "common",
  },
  {
    label: "SAINT-JEAN-DE-TREZY",
    value: "71431",
    type: "common",
  },
  {
    label: "DRACY-LES-COUCHES",
    value: "71183",
    type: "common",
  },
  {
    label: "COUCHES",
    value: "71149",
    type: "common",
  },
  {
    label: "SAINT-FIRMIN",
    value: "71413",
    type: "common",
  },
  {
    label: "MARMAGNE",
    value: "71282",
    type: "common",
  },
  {
    label: "ANTULLY",
    value: "71010",
    type: "common",
  },
  {
    label: "BROYE",
    value: "71063",
    type: "common",
  },
  {
    label: "MESVRES",
    value: "71297",
    type: "common",
  },
  {
    label: "ETANG-SUR-ARROUX",
    value: "71192",
    type: "common",
  },
  {
    label: "LA COMELLE",
    value: "71142",
    type: "common",
  },
  {
    label: "POIL",
    value: "58211",
    type: "common",
  },
  {
    label: "LAROCHEMILLAY",
    value: "58140",
    type: "common",
  },
  {
    label: "CHIDDES",
    value: "58074",
    type: "common",
  },
  {
    label: "SEMELAY",
    value: "58276",
    type: "common",
  },
  {
    label: "SAINT-HONORE-LES-BAINS",
    value: "58246",
    type: "common",
  },
  {
    label: "MONTARON",
    value: "58173",
    type: "common",
  },
  {
    label: "THAIX",
    value: "58290",
    type: "common",
  },
  {
    label: "SAINT-GRATIEN-SAVIGNY",
    value: "58243",
    type: "common",
  },
  {
    label: "VERNEUIL",
    value: "58306",
    type: "common",
  },
  {
    label: "DIENNES-AUBIGNY",
    value: "58097",
    type: "common",
  },
  {
    label: "CERCY-LA-TOUR",
    value: "58046",
    type: "common",
  },
  {
    label: "CHAMPVERT",
    value: "58055",
    type: "common",
  },
  {
    label: "SOUGY-SUR-LOIRE",
    value: "58280",
    type: "common",
  },
  {
    label: "LA MACHINE",
    value: "58151",
    type: "common",
  },
  {
    label: "SAINT-OUEN-SUR-LOIRE",
    value: "58258",
    type: "common",
  },
  {
    label: "DRUY-PARIGNY",
    value: "58105",
    type: "common",
  },
  {
    label: "BEARD",
    value: "58025",
    type: "common",
  },
  {
    label: "LUTHENAY-UXELOUP",
    value: "58148",
    type: "common",
  },
  {
    label: "MAGNY-COURS",
    value: "58152",
    type: "common",
  },
  {
    label: "SAINCAIZE-MEAUCE",
    value: "58225",
    type: "common",
  },
  {
    label: "MARS-SUR-ALLIER",
    value: "58158",
    type: "common",
  },
  {
    label: "NEUVY-LE-BARROIS",
    value: "18164",
    type: "common",
  },
  {
    label: "APREMONT-SUR-ALLIER",
    value: "18007",
    type: "common",
  },
  {
    label: "VEREAUX",
    value: "18275",
    type: "common",
  },
  {
    label: "GROSSOUVRE",
    value: "18106",
    type: "common",
  },
  {
    label: "LA CHAPELLE-HUGON",
    value: "18048",
    type: "common",
  },
  {
    label: "SAGONNE",
    value: "18195",
    type: "common",
  },
  {
    label: "CHARLY",
    value: "18054",
    type: "common",
  },
  {
    label: "CHALIVOY-MILON",
    value: "18045",
    type: "common",
  },
  {
    label: "BLET",
    value: "18031",
    type: "common",
  },
  {
    label: "LANTAN",
    value: "18121",
    type: "common",
  },
  {
    label: "DUN-SUR-AURON",
    value: "18087",
    type: "common",
  },
  {
    label: "BUSSY",
    value: "18040",
    type: "common",
  },
  {
    label: "SAINT-GERMAIN-DES-BOIS",
    value: "18212",
    type: "common",
  },
  {
    label: "SERRUELLES",
    value: "18250",
    type: "common",
  },
  {
    label: "CHATEAUNEUF-SUR-CHER",
    value: "18058",
    type: "common",
  },
  {
    label: "PRIMELLES",
    value: "18188",
    type: "common",
  },
  {
    label: "MAREUIL-SUR-ARNON",
    value: "18137",
    type: "common",
  },
  {
    label: "SEGRY",
    value: "36215",
    type: "common",
  },
  {
    label: "THIZAY",
    value: "36222",
    type: "common",
  },
  {
    label: "CONDE",
    value: "36059",
    type: "common",
  },
  {
    label: "NEUVY-PAILLOUX",
    value: "36140",
    type: "common",
  },
  {
    label: "BRIVES",
    value: "36027",
    type: "common",
  },
  {
    label: "MONTIERCHAUME",
    value: "36128",
    type: "common",
  },
  {
    label: "COINGS",
    value: "36057",
    type: "common",
  },
  {
    label: "VINEUIL",
    value: "36247",
    type: "common",
  },
  {
    label: "CHEZELLES",
    value: "36050",
    type: "common",
  },
  {
    label: "SAINT-LACTENCIN",
    value: "36198",
    type: "common",
  },
  {
    label: "SAINTE-GEMME",
    value: "36193",
    type: "common",
  },
  {
    label: "BUZANCAIS",
    value: "36031",
    type: "common",
  },
  {
    label: "SAULNAY",
    value: "36212",
    type: "common",
  },
  {
    label: "ARPHEUILLES",
    value: "36008",
    type: "common",
  },
  {
    label: "VILLIERS",
    value: "36246",
    type: "common",
  },
  {
    label: "OBTERRE",
    value: "36145",
    type: "common",
  },
  {
    label: "AZAY-LE-FERRON",
    value: "36010",
    type: "common",
  },
  {
    label: "PREUILLY-SUR-CLAISE",
    value: "37189",
    type: "common",
  },
  {
    label: "CHAUMUSSAY",
    value: "37064",
    type: "common",
  },
  {
    label: "BOUSSAY",
    value: "37033",
    type: "common",
  },
  {
    label: "BARROU",
    value: "37019",
    type: "common",
  },
  {
    label: "OYRE",
    value: "86186",
    type: "common",
  },
  {
    label: "MAIRE",
    value: "86143",
    type: "common",
  },
  {
    label: "LEUGNY",
    value: "86130",
    type: "common",
  },
  {
    label: "LA GUERCHE",
    value: "37114",
    type: "common",
  },
  {
    label: "INGRANDES",
    value: "86111",
    type: "common",
  },
  {
    label: "USSEAU",
    value: "86275",
    type: "common",
  },
  {
    label: "ANTRAN",
    value: "86007",
    type: "common",
  },
  {
    label: "THURE",
    value: "86272",
    type: "common",
  },
  {
    label: "SOSSAIS",
    value: "86265",
    type: "common",
  },
  {
    label: "SAINT-GERVAIS-LES-TROIS-CLOCHERS",
    value: "86224",
    type: "common",
  },
  {
    label: "ORCHES",
    value: "86182",
    type: "common",
  },
  {
    label: "SAVIGNY-SOUS-FAYE",
    value: "86257",
    type: "common",
  },
  {
    label: "SAIRES",
    value: "86249",
    type: "common",
  },
  {
    label: "DOUSSAY",
    value: "86096",
    type: "common",
  },
  {
    label: "VERRUE",
    value: "86286",
    type: "common",
  },
  {
    label: "SAINT-JEAN-DE-SAUVES",
    value: "86225",
    type: "common",
  },
  {
    label: "SAINT-CLAIR",
    value: "86218",
    type: "common",
  },
  {
    label: "LA CHAUSSEE",
    value: "86069",
    type: "common",
  },
  {
    label: "MONCONTOUR",
    value: "86161",
    type: "common",
  },
  {
    label: "MARNES",
    value: "79167",
    type: "common",
  },
  {
    label: "IRAIS",
    value: "79141",
    type: "common",
  },
  {
    label: "AVAILLES-THOUARSAIS",
    value: "79022",
    type: "common",
  },
  {
    label: "AIRVAULT",
    value: "79005",
    type: "common",
  },
  {
    label: "PIERREFITTE",
    value: "79209",
    type: "common",
  },
  {
    label: "GLENAY",
    value: "79134",
    type: "common",
  },
  {
    label: "GEAY",
    value: "79131",
    type: "common",
  },
  {
    label: "FAYE-L'ABBESSE",
    value: "79116",
    type: "common",
  },
  {
    label: "BRESSUIRE",
    value: "79049",
    type: "common",
  },
  {
    label: "CIRIERES",
    value: "79091",
    type: "common",
  },
  {
    label: "BRETIGNOLLES",
    value: "79050",
    type: "common",
  },
  {
    label: "MONTRAVERS",
    value: "79183",
    type: "common",
  },
  {
    label: "COMBRAND",
    value: "79096",
    type: "common",
  },
  {
    label: "CERIZAY",
    value: "79062",
    type: "common",
  },
  {
    label: "SEVREMONT",
    value: "85090",
    type: "common",
  },
  {
    label: "SAINT-PAUL-EN-PAREDS",
    value: "85259",
    type: "common",
  },
  {
    label: "SAINT-MARS-LA-REORTHE",
    value: "85242",
    type: "common",
  },
  {
    label: "VENDRENNES",
    value: "85301",
    type: "common",
  },
  {
    label: "MESNARD-LA-BAROTIERE",
    value: "85144",
    type: "common",
  },
  {
    label: "SAINT-ANDRE-GOULE-D'OIE",
    value: "85196",
    type: "common",
  },
  {
    label: "LA RABATELIERE",
    value: "85186",
    type: "common",
  },
  {
    label: "CHAUCHE",
    value: "85064",
    type: "common",
  },
  {
    label: "SAINT-DENIS-LA-CHEVASSE",
    value: "85208",
    type: "common",
  },
  {
    label: "LA COPECHAGNIERE",
    value: "85072",
    type: "common",
  },
  {
    label: "BEAUFOU",
    value: "85015",
    type: "common",
  },
  {
    label: "SAINT-PAUL-MONT-PENIT",
    value: "85260",
    type: "common",
  },
  {
    label: "SAINT-ETIENNE-DU-BOIS",
    value: "85210",
    type: "common",
  },
  {
    label: "PALLUAU",
    value: "85169",
    type: "common",
  },
  {
    label: "LA CHAPELLE-PALLUAU",
    value: "85055",
    type: "common",
  },
  {
    label: "SAINT-CHRISTOPHE-DU-LIGNERON",
    value: "85204",
    type: "common",
  },
  {
    label: "CHALLANS",
    value: "85047",
    type: "common",
  },
  {
    label: "SOULLANS",
    value: "85284",
    type: "common",
  },
  {
    label: "LE PERRIER",
    value: "85172",
    type: "common",
  },
  {
    label: "SAINT-JEAN-DE-MONTS",
    value: "85234",
    type: "common",
  },
  {
    label: "JOUGNE",
    value: "25318",
    type: "common",
  },
  {
    label: "SAINT-ANTOINE",
    value: "25514",
    type: "common",
  },
  {
    label: "METABIEF",
    value: "25380",
    type: "common",
  },
  {
    label: "LONGEVILLES-MONT-D'OR",
    value: "25348",
    type: "common",
  },
  {
    label: "FOURCATIER-ET-MAISON-NEUVE",
    value: "25252",
    type: "common",
  },
  {
    label: "LABERGEMENT-SAINTE-MARIE",
    value: "25320",
    type: "common",
  },
  {
    label: "GELLIN",
    value: "25263",
    type: "common",
  },
  {
    label: "BREY-ET-MAISON-DU-BOIS",
    value: "25096",
    type: "common",
  },
  {
    label: "RONDEFONTAINE",
    value: "25501",
    type: "common",
  },
  {
    label: "REMORAY-BOUJEONS",
    value: "25486",
    type: "common",
  },
  {
    label: "RIX",
    value: "39461",
    type: "common",
  },
  {
    label: "MIGNOVILLARD",
    value: "39331",
    type: "common",
  },
  {
    label: "LONGCOCHON",
    value: "39298",
    type: "common",
  },
  {
    label: "LA LATETTE",
    value: "39282",
    type: "common",
  },
  {
    label: "CERNIEBAUD",
    value: "39085",
    type: "common",
  },
  {
    label: "BILLECUL",
    value: "39055",
    type: "common",
  },
  {
    label: "ONGLIERES",
    value: "39393",
    type: "common",
  },
  {
    label: "NOZEROY",
    value: "39391",
    type: "common",
  },
  {
    label: "LES NANS",
    value: "39381",
    type: "common",
  },
  {
    label: "MOURNANS-CHARBONNY",
    value: "39372",
    type: "common",
  },
  {
    label: "MIEGES",
    value: "39329",
    type: "common",
  },
  {
    label: "LENT",
    value: "39292",
    type: "common",
  },
  {
    label: "LA FAVIERE",
    value: "39221",
    type: "common",
  },
  {
    label: "DOYE",
    value: "39203",
    type: "common",
  },
  {
    label: "CONTE",
    value: "39165",
    type: "common",
  },
  {
    label: "CHARENCY",
    value: "39108",
    type: "common",
  },
  {
    label: "VANNOZ",
    value: "39543",
    type: "common",
  },
  {
    label: "SAPOIS",
    value: "39503",
    type: "common",
  },
  {
    label: "SAINT-GERMAIN-EN-MONTAGNE",
    value: "39481",
    type: "common",
  },
  {
    label: "LE PASQUIER",
    value: "39406",
    type: "common",
  },
  {
    label: "MOUTOUX",
    value: "39376",
    type: "common",
  },
  {
    label: "EQUEVILLON",
    value: "39210",
    type: "common",
  },
  {
    label: "CHAMPAGNOLE",
    value: "39097",
    type: "common",
  },
  {
    label: "ARDON",
    value: "39015",
    type: "common",
  },
  {
    label: "MONTROND",
    value: "39364",
    type: "common",
  },
  {
    label: "CROTENAY",
    value: "39183",
    type: "common",
  },
  {
    label: "BESAIN",
    value: "39050",
    type: "common",
  },
  {
    label: "POLIGNY",
    value: "39434",
    type: "common",
  },
  {
    label: "PICARREAU",
    value: "39418",
    type: "common",
  },
  {
    label: "LE FIED",
    value: "39225",
    type: "common",
  },
  {
    label: "FAY-EN-MONTAGNE",
    value: "39222",
    type: "common",
  },
  {
    label: "PLASNE",
    value: "39426",
    type: "common",
  },
  {
    label: "PASSENANS",
    value: "39407",
    type: "common",
  },
  {
    label: "MENETRU-LE-VIGNOBLE",
    value: "39321",
    type: "common",
  },
  {
    label: "LADOYE-SUR-SEILLE",
    value: "39272",
    type: "common",
  },
  {
    label: "FRONTENAY",
    value: "39244",
    type: "common",
  },
  {
    label: "CHATEAU-CHALON",
    value: "39114",
    type: "common",
  },
  {
    label: "SAINT-LAMAIN",
    value: "39486",
    type: "common",
  },
  {
    label: "MANTRY",
    value: "39310",
    type: "common",
  },
  {
    label: "VINCENT-FROIDEVILLE",
    value: "39577",
    type: "common",
  },
  {
    label: "RECANOZ",
    value: "39454",
    type: "common",
  },
  {
    label: "LOMBARD",
    value: "39296",
    type: "common",
  },
  {
    label: "DESNES",
    value: "39194",
    type: "common",
  },
  {
    label: "ARLAY",
    value: "39017",
    type: "common",
  },
  {
    label: "RELANS",
    value: "39456",
    type: "common",
  },
  {
    label: "COMMENAILLES",
    value: "39160",
    type: "common",
  },
  {
    label: "CHAPELLE-VOLAND",
    value: "39104",
    type: "common",
  },
  {
    label: "LE PLANOIS",
    value: "71352",
    type: "common",
  },
  {
    label: "MONTJAY",
    value: "71314",
    type: "common",
  },
  {
    label: "BOUHANS",
    value: "71045",
    type: "common",
  },
  {
    label: "BOSJEAN",
    value: "71044",
    type: "common",
  },
  {
    label: "SERLEY",
    value: "71516",
    type: "common",
  },
  {
    label: "VILLEGAUDIN",
    value: "71577",
    type: "common",
  },
  {
    label: "MERVANS",
    value: "71295",
    type: "common",
  },
  {
    label: "DICONNE",
    value: "71175",
    type: "common",
  },
  {
    label: "DEVROUZE",
    value: "71173",
    type: "common",
  },
  {
    label: "SAINT-MARTIN-EN-BRESSE",
    value: "71456",
    type: "common",
  },
  {
    label: "GUERFAND",
    value: "71228",
    type: "common",
  },
  {
    label: "OSLON",
    value: "71333",
    type: "common",
  },
  {
    label: "MONTCOY",
    value: "71312",
    type: "common",
  },
  {
    label: "BEY",
    value: "71033",
    type: "common",
  },
  {
    label: "ALLERIOT",
    value: "71004",
    type: "common",
  },
  {
    label: "SAINT-MARCEL",
    value: "71445",
    type: "common",
  },
  {
    label: "CRISSEY",
    value: "71154",
    type: "common",
  },
  {
    label: "CHATENOY-EN-BRESSE",
    value: "71117",
    type: "common",
  },
  {
    label: "CHALON-SUR-SAONE",
    value: "71076",
    type: "common",
  },
  {
    label: "DRACY-LE-FORT",
    value: "71182",
    type: "common",
  },
  {
    label: "CHATENOY-LE-ROYAL",
    value: "71118",
    type: "common",
  },
  {
    label: "CHAMPFORGEUIL",
    value: "71081",
    type: "common",
  },
  {
    label: "SAINT-MARTIN-SOUS-MONTAIGU",
    value: "71459",
    type: "common",
  },
  {
    label: "SAINT-JEAN-DE-VAUX",
    value: "71430",
    type: "common",
  },
  {
    label: "SAINT-DENIS-DE-VAUX",
    value: "71403",
    type: "common",
  },
  {
    label: "MERCUREY",
    value: "71294",
    type: "common",
  },
  {
    label: "MELLECEY",
    value: "71292",
    type: "common",
  },
  {
    label: "JAMBLES",
    value: "71241",
    type: "common",
  },
  {
    label: "SAINT-MARD-DE-VAUX",
    value: "71447",
    type: "common",
  },
  {
    label: "SAINT-BERAIN-SUR-DHEUNE",
    value: "71391",
    type: "common",
  },
  {
    label: "CHATEL-MORON",
    value: "71115",
    type: "common",
  },
  {
    label: "BARIZEY",
    value: "71019",
    type: "common",
  },
  {
    label: "PERREUIL",
    value: "71347",
    type: "common",
  },
  {
    label: "MOREY",
    value: "71321",
    type: "common",
  },
  {
    label: "ESSERTENNE",
    value: "71191",
    type: "common",
  },
  {
    label: "LE CREUSOT",
    value: "71153",
    type: "common",
  },
  {
    label: "LE BREUIL",
    value: "71059",
    type: "common",
  },
  {
    label: "SAINT-SERNIN-DU-BOIS",
    value: "71479",
    type: "common",
  },
  {
    label: "MONTCENIS",
    value: "71309",
    type: "common",
  },
  {
    label: "UCHON",
    value: "71551",
    type: "common",
  },
  {
    label: "SAINT-SYMPHORIEN-DE-MARMAGNE",
    value: "71482",
    type: "common",
  },
  {
    label: "LA TAGNIERE",
    value: "71531",
    type: "common",
  },
  {
    label: "LA CHAPELLE-SOUS-UCHON",
    value: "71096",
    type: "common",
  },
  {
    label: "THIL-SUR-ARROUX",
    value: "71537",
    type: "common",
  },
  {
    label: "SAINT-NIZIER-SUR-ARROUX",
    value: "71466",
    type: "common",
  },
  {
    label: "SAINT-DIDIER-SUR-ARROUX",
    value: "71407",
    type: "common",
  },
  {
    label: "MILLAY",
    value: "58168",
    type: "common",
  },
  {
    label: "LUZY",
    value: "58149",
    type: "common",
  },
  {
    label: "LANTY",
    value: "58139",
    type: "common",
  },
  {
    label: "FLETY",
    value: "58114",
    type: "common",
  },
  {
    label: "AVREE",
    value: "58019",
    type: "common",
  },
  {
    label: "REMILLY",
    value: "58221",
    type: "common",
  },
  {
    label: "FOURS",
    value: "58118",
    type: "common",
  },
  {
    label: "CHARRIN",
    value: "58060",
    type: "common",
  },
  {
    label: "DEVAY",
    value: "58096",
    type: "common",
  },
  {
    label: "SAINT-LEGER-DES-VIGNES",
    value: "58250",
    type: "common",
  },
  {
    label: "DECIZE",
    value: "58095",
    type: "common",
  },
  {
    label: "FLEURY-SUR-LOIRE",
    value: "58115",
    type: "common",
  },
  {
    label: "AVRIL-SUR-LOIRE",
    value: "58020",
    type: "common",
  },
  {
    label: "SAINT-PARIZE-LE-CHATEL",
    value: "58260",
    type: "common",
  },
  {
    label: "SAINT-PIERRE-LE-MOUTIER",
    value: "58264",
    type: "common",
  },
  {
    label: "LANGERON",
    value: "58138",
    type: "common",
  },
  {
    label: "MORNAY-SUR-ALLIER",
    value: "18155",
    type: "common",
  },
  {
    label: "SANCOINS",
    value: "18242",
    type: "common",
  },
  {
    label: "GIVARDON",
    value: "18102",
    type: "common",
  },
  {
    label: "NEUILLY-EN-DUN",
    value: "18161",
    type: "common",
  },
  {
    label: "CHAUMONT",
    value: "18060",
    type: "common",
  },
  {
    label: "BANNEGON",
    value: "18021",
    type: "common",
  },
  {
    label: "THAUMIERS",
    value: "18261",
    type: "common",
  },
  {
    label: "LE PONDY",
    value: "18183",
    type: "common",
  },
  {
    label: "COGNY",
    value: "18068",
    type: "common",
  },
  {
    label: "VERNEUIL",
    value: "18277",
    type: "common",
  },
  {
    label: "PARNAY",
    value: "18177",
    type: "common",
  },
  {
    label: "UZAY-LE-VENON",
    value: "18268",
    type: "common",
  },
  {
    label: "CONTRES",
    value: "18071",
    type: "common",
  },
  {
    label: "SAINT-LOUP-DES-CHAUMES",
    value: "18221",
    type: "common",
  },
  {
    label: "CHAVANNES",
    value: "18063",
    type: "common",
  },
  {
    label: "VENESMES",
    value: "18273",
    type: "common",
  },
  {
    label: "SAINT-SYMPHORIEN",
    value: "18236",
    type: "common",
  },
  {
    label: "CREZANCAY-SUR-CHER",
    value: "18078",
    type: "common",
  },
  {
    label: "VILLECELIN",
    value: "18283",
    type: "common",
  },
  {
    label: "SAINT-BAUDEL",
    value: "18199",
    type: "common",
  },
  {
    label: "MONTLOUIS",
    value: "18152",
    type: "common",
  },
  {
    label: "CHEZAL-BENOIT",
    value: "18065",
    type: "common",
  },
  {
    label: "SAINT-AUBIN",
    value: "36181",
    type: "common",
  },
  {
    label: "MEUNET-PLANCHES",
    value: "36121",
    type: "common",
  },
  {
    label: "VOUILLON",
    value: "36248",
    type: "common",
  },
  {
    label: "SAINTE-FAUSTE",
    value: "36190",
    type: "common",
  },
  {
    label: "MARON",
    value: "36112",
    type: "common",
  },
  {
    label: "DIORS",
    value: "36064",
    type: "common",
  },
  {
    label: "DEOLS",
    value: "36063",
    type: "common",
  },
  {
    label: "CHATEAUROUX",
    value: "36044",
    type: "common",
  },
  {
    label: "VILLEDIEU-SUR-INDRE",
    value: "36241",
    type: "common",
  },
  {
    label: "NIHERNE",
    value: "36142",
    type: "common",
  },
  {
    label: "LA CHAPELLE-ORTHEMALE",
    value: "36040",
    type: "common",
  },
  {
    label: "VENDŒUVRES",
    value: "36232",
    type: "common",
  },
  {
    label: "MEZIERES-EN-BRENNE",
    value: "36123",
    type: "common",
  },
  {
    label: "PAULNAY",
    value: "36153",
    type: "common",
  },
  {
    label: "MARTIZAY",
    value: "36113",
    type: "common",
  },
  {
    label: "BOSSAY-SUR-CLAISE",
    value: "37028",
    type: "common",
  },
  {
    label: "YZEURES-SUR-CREUSE",
    value: "37282",
    type: "common",
  },
  {
    label: "CHAMBON",
    value: "37048",
    type: "common",
  },
  {
    label: "LESIGNY",
    value: "86129",
    type: "common",
  },
  {
    label: "COUSSAY-LES-BOIS",
    value: "86086",
    type: "common",
  },
  {
    label: "SENILLE-SAINT-SAUVEUR",
    value: "86245",
    type: "common",
  },
  {
    label: "CHATELLERAULT",
    value: "86066",
    type: "common",
  },
  {
    label: "SCORBE-CLAIRVAUX",
    value: "86258",
    type: "common",
  },
  {
    label: "COLOMBIERS",
    value: "86081",
    type: "common",
  },
  {
    label: "SAINT-GENEST-D'AMBIERE",
    value: "86221",
    type: "common",
  },
  {
    label: "LENCLOITRE",
    value: "86128",
    type: "common",
  },
  {
    label: "CERNAY",
    value: "86047",
    type: "common",
  },
  {
    label: "MIREBEAU",
    value: "86160",
    type: "common",
  },
  {
    label: "COUSSAY",
    value: "86085",
    type: "common",
  },
  {
    label: "CHOUPPES",
    value: "86075",
    type: "common",
  },
  {
    label: "MAZEUIL",
    value: "86154",
    type: "common",
  },
  {
    label: "LA GRIMAUDIERE",
    value: "86108",
    type: "common",
  },
  {
    label: "ASSAIS-LES-JUMEAUX",
    value: "79016",
    type: "common",
  },
  {
    label: "SAINT-LOUP-LAMAIRE",
    value: "79268",
    type: "common",
  },
  {
    label: "LOUIN",
    value: "79156",
    type: "common",
  },
  {
    label: "MAISONTIERS",
    value: "79165",
    type: "common",
  },
  {
    label: "BOUSSAIS",
    value: "79047",
    type: "common",
  },
  {
    label: "CHICHE",
    value: "79088",
    type: "common",
  },
  {
    label: "BOISME",
    value: "79038",
    type: "common",
  },
  {
    label: "CHANTELOUP",
    value: "79069",
    type: "common",
  },
  {
    label: "COURLAY",
    value: "79103",
    type: "common",
  },
  {
    label: "SAINT-ANDRE-SUR-SEVRE",
    value: "79236",
    type: "common",
  },
  {
    label: "SAINT-MESMIN",
    value: "85254",
    type: "common",
  },
  {
    label: "MONTOURNAIS",
    value: "85147",
    type: "common",
  },
  {
    label: "POUZAUGES",
    value: "85182",
    type: "common",
  },
  {
    label: "SAINT-PROUANT",
    value: "85266",
    type: "common",
  },
  {
    label: "LE BOUPERE",
    value: "85031",
    type: "common",
  },
  {
    label: "ROCHETREJOUX",
    value: "85192",
    type: "common",
  },
  {
    label: "MOUCHAMPS",
    value: "85153",
    type: "common",
  },
  {
    label: "ESSARTS EN BOCAGE",
    value: "85084",
    type: "common",
  },
  {
    label: "LA MERLATIERE",
    value: "85142",
    type: "common",
  },
  {
    label: "DOMPIERRE-SUR-YON",
    value: "85081",
    type: "common",
  },
  {
    label: "BELLEVIGNY",
    value: "85019",
    type: "common",
  },
  {
    label: "LE POIRE-SUR-VIE",
    value: "85178",
    type: "common",
  },
  {
    label: "AIZENAY",
    value: "85003",
    type: "common",
  },
  {
    label: "MACHE",
    value: "85130",
    type: "common",
  },
  {
    label: "APREMONT",
    value: "85006",
    type: "common",
  },
  {
    label: "SAINT-MAIXENT-SUR-VIE",
    value: "85239",
    type: "common",
  },
  {
    label: "COMMEQUIERS",
    value: "85071",
    type: "common",
  },
  {
    label: "NOTRE-DAME-DE-RIEZ",
    value: "85189",
    type: "common",
  },
  {
    label: "SAINT-HILAIRE-DE-RIEZ",
    value: "85226",
    type: "common",
  },
  {
    label: "L'ILE-D'YEU",
    value: "85113",
    type: "common",
  },
  {
    label: "ROCHEJEAN",
    value: "25494",
    type: "common",
  },
  {
    label: "LES VILLEDIEU",
    value: "25619",
    type: "common",
  },
  {
    label: "SARRAGEOIS",
    value: "25534",
    type: "common",
  },
  {
    label: "RECULFOZ",
    value: "25483",
    type: "common",
  },
  {
    label: "LES PONTETS",
    value: "25464",
    type: "common",
  },
  {
    label: "PETITE-CHAUX",
    value: "25451",
    type: "common",
  },
  {
    label: "MOUTHE",
    value: "25413",
    type: "common",
  },
  {
    label: "FRAROZ",
    value: "39237",
    type: "common",
  },
  {
    label: "BIEF-DES-MAISONS",
    value: "39052",
    type: "common",
  },
  {
    label: "ARSURE-ARSURETTE",
    value: "39020",
    type: "common",
  },
  {
    label: "LE CROUZET",
    value: "25179",
    type: "common",
  },
  {
    label: "SIROD",
    value: "39517",
    type: "common",
  },
  {
    label: "GILLOIS",
    value: "39254",
    type: "common",
  },
  {
    label: "CRANS",
    value: "39178",
    type: "common",
  },
  {
    label: "LES CHALESMES",
    value: "39091",
    type: "common",
  },
  {
    label: "LE VAUDIOUX",
    value: "39545",
    type: "common",
  },
  {
    label: "SYAM",
    value: "39523",
    type: "common",
  },
  {
    label: "PILLEMOINE",
    value: "39419",
    type: "common",
  },
  {
    label: "NEY",
    value: "39389",
    type: "common",
  },
  {
    label: "LOULLE",
    value: "39301",
    type: "common",
  },
  {
    label: "CIZE",
    value: "39153",
    type: "common",
  },
  {
    label: "BOURG-DE-SIROD",
    value: "39070",
    type: "common",
  },
  {
    label: "MONT-SUR-MONNET",
    value: "39366",
    type: "common",
  },
  {
    label: "MONTIGNY-SUR-L'AIN",
    value: "39356",
    type: "common",
  },
  {
    label: "MONNET-LA-VILLE",
    value: "39344",
    type: "common",
  },
  {
    label: "PONT-DU-NAVOY",
    value: "39437",
    type: "common",
  },
  {
    label: "BONNEFONTAINE",
    value: "39065",
    type: "common",
  },
  {
    label: "NEVY-SUR-SEILLE",
    value: "39388",
    type: "common",
  },
  {
    label: "LA MARRE",
    value: "39317",
    type: "common",
  },
  {
    label: "HAUTEROCHE",
    value: "39177",
    type: "common",
  },
  {
    label: "BLOIS-SUR-SEILLE",
    value: "39057",
    type: "common",
  },
  {
    label: "BAUME-LES-MESSIEURS",
    value: "39041",
    type: "common",
  },
  {
    label: "VOITEUR",
    value: "39582",
    type: "common",
  },
  {
    label: "VILLENEUVE-SOUS-PYMONT",
    value: "39567",
    type: "common",
  },
  {
    label: "LE VERNOIS",
    value: "39553",
    type: "common",
  },
  {
    label: "PLAINOISEAU",
    value: "39422",
    type: "common",
  },
  {
    label: "LE PIN",
    value: "39421",
    type: "common",
  },
  {
    label: "PANNESSIERES",
    value: "39404",
    type: "common",
  },
  {
    label: "MONTAIN",
    value: "39349",
    type: "common",
  },
  {
    label: "LE LOUVEROT",
    value: "39304",
    type: "common",
  },
  {
    label: "LAVIGNY",
    value: "39288",
    type: "common",
  },
  {
    label: "CHILLE",
    value: "39145",
    type: "common",
  },
  {
    label: "VILLEVIEUX",
    value: "39574",
    type: "common",
  },
  {
    label: "SAINT-DIDIER",
    value: "39480",
    type: "common",
  },
  {
    label: "RUFFEY-SUR-SEILLE",
    value: "39471",
    type: "common",
  },
  {
    label: "QUINTIGNY",
    value: "39447",
    type: "common",
  },
  {
    label: "LARNAUD",
    value: "39279",
    type: "common",
  },
  {
    label: "L'ETOILE",
    value: "39217",
    type: "common",
  },
  {
    label: "SAILLENARD",
    value: "71380",
    type: "common",
  },
  {
    label: "NANCE",
    value: "39379",
    type: "common",
  },
  {
    label: "COSGES",
    value: "39167",
    type: "common",
  },
  {
    label: "BLETTERANS",
    value: "39056",
    type: "common",
  },
  {
    label: "LE TARTRE",
    value: "71534",
    type: "common",
  },
  {
    label: "SENS-SUR-SEILLE",
    value: "71514",
    type: "common",
  },
  {
    label: "FRANGY-EN-BRESSE",
    value: "71205",
    type: "common",
  },
  {
    label: "SAINT-GERMAIN-DU-BOIS",
    value: "71419",
    type: "common",
  },
  {
    label: "VERISSEY",
    value: "71568",
    type: "common",
  },
  {
    label: "THUREY",
    value: "71538",
    type: "common",
  },
  {
    label: "SIMARD",
    value: "71523",
    type: "common",
  },
  {
    label: "TRONCHY",
    value: "71548",
    type: "common",
  },
  {
    label: "SAINT-ETIENNE-EN-BRESSE",
    value: "71410",
    type: "common",
  },
  {
    label: "LESSARD-EN-BRESSE",
    value: "71256",
    type: "common",
  },
  {
    label: "L'ABERGEMENT-SAINTE-COLOMBE",
    value: "71002",
    type: "common",
  },
  {
    label: "SAINT-CHRISTOPHE-EN-BRESSE",
    value: "71398",
    type: "common",
  },
  {
    label: "OUROUX-SUR-SAONE",
    value: "71336",
    type: "common",
  },
  {
    label: "LANS",
    value: "71253",
    type: "common",
  },
  {
    label: "VARENNES-LE-GRAND",
    value: "71555",
    type: "common",
  },
  {
    label: "SAINT-LOUP-DE-VARENNES",
    value: "71444",
    type: "common",
  },
  {
    label: "LUX",
    value: "71269",
    type: "common",
  },
  {
    label: "EPERVANS",
    value: "71189",
    type: "common",
  },
  {
    label: "SEVREY",
    value: "71520",
    type: "common",
  },
  {
    label: "SAINT-REMY",
    value: "71475",
    type: "common",
  },
  {
    label: "LA CHARMEE",
    value: "71102",
    type: "common",
  },
  {
    label: "SAINT-DESERT",
    value: "71404",
    type: "common",
  },
  {
    label: "ROSEY",
    value: "71374",
    type: "common",
  },
  {
    label: "GRANGES",
    value: "71225",
    type: "common",
  },
  {
    label: "GIVRY",
    value: "71221",
    type: "common",
  },
  {
    label: "BUXY",
    value: "71070",
    type: "common",
  },
  {
    label: "BISSEY-SOUS-CRUCHAUD",
    value: "71034",
    type: "common",
  },
  {
    label: "VILLENEUVE-EN-MONTAGNE",
    value: "71579",
    type: "common",
  },
  {
    label: "SASSANGY",
    value: "71501",
    type: "common",
  },
  {
    label: "SAINTE-HELENE",
    value: "71426",
    type: "common",
  },
  {
    label: "MOROGES",
    value: "71324",
    type: "common",
  },
  {
    label: "MARCILLY-LES-BUXY",
    value: "71277",
    type: "common",
  },
  {
    label: "SAINT-MARTIN-D'AUXY",
    value: "71449",
    type: "common",
  },
  {
    label: "SAINT-LAURENT-D'ANDENAY",
    value: "71436",
    type: "common",
  },
  {
    label: "SAINT-JULIEN-SUR-DHEUNE",
    value: "71435",
    type: "common",
  },
  {
    label: "ECUISSES",
    value: "71187",
    type: "common",
  },
  {
    label: "TORCY",
    value: "71540",
    type: "common",
  },
  {
    label: "MONTCHANIN",
    value: "71310",
    type: "common",
  },
  {
    label: "LES BIZOTS",
    value: "71038",
    type: "common",
  },
  {
    label: "CHARMOY",
    value: "71103",
    type: "common",
  },
  {
    label: "SAINT-EUGENE",
    value: "71411",
    type: "common",
  },
  {
    label: "DETTEY",
    value: "71172",
    type: "common",
  },
  {
    label: "MONTMORT",
    value: "71317",
    type: "common",
  },
  {
    label: "CHARBONNAT",
    value: "71098",
    type: "common",
  },
  {
    label: "LA BOULAYE",
    value: "71046",
    type: "common",
  },
  {
    label: "CUZY",
    value: "71166",
    type: "common",
  },
  {
    label: "MARLY-SOUS-ISSY",
    value: "71280",
    type: "common",
  },
  {
    label: "TAZILLY",
    value: "58287",
    type: "common",
  },
  {
    label: "TERNANT",
    value: "58289",
    type: "common",
  },
  {
    label: "SAVIGNY-POIL-FOL",
    value: "58274",
    type: "common",
  },
  {
    label: "LA NOCLE-MAULAIX",
    value: "58195",
    type: "common",
  },
  {
    label: "MONTAMBERT",
    value: "58172",
    type: "common",
  },
  {
    label: "SAINT-HILAIRE-FONTAINE",
    value: "58245",
    type: "common",
  },
  {
    label: "LAMENAY-SUR-LOIRE",
    value: "58137",
    type: "common",
  },
  {
    label: "COSSAYE",
    value: "58087",
    type: "common",
  },
  {
    label: "SAINT-GERMAIN-CHASSENAY",
    value: "58241",
    type: "common",
  },
  {
    label: "SAINT-PARIZE-EN-VIRY",
    value: "58259",
    type: "common",
  },
  {
    label: "NEUVILLE-LES-DECIZE",
    value: "58192",
    type: "common",
  },
  {
    label: "AZY-LE-VIF",
    value: "58021",
    type: "common",
  },
  {
    label: "CHANTENAY-SAINT-IMBERT",
    value: "58057",
    type: "common",
  },
  {
    label: "LIVRY",
    value: "58144",
    type: "common",
  },
  {
    label: "LE VEURDRE",
    value: "03309",
    type: "common",
  },
  {
    label: "NEURE",
    value: "03198",
    type: "common",
  },
  {
    label: "CHATEAU-SUR-ALLIER",
    value: "03064",
    type: "common",
  },
  {
    label: "LURCY-LEVIS",
    value: "03155",
    type: "common",
  },
  {
    label: "SAINT-AIGNAN-DES-NOYERS",
    value: "18196",
    type: "common",
  },
  {
    label: "AUGY-SUR-AUBOIS",
    value: "18017",
    type: "common",
  },
  {
    label: "VERNAIS",
    value: "18276",
    type: "common",
  },
  {
    label: "CHARENTON-DU-CHER",
    value: "18052",
    type: "common",
  },
  {
    label: "SAINT-PIERRE-LES-ETIEUX",
    value: "18231",
    type: "common",
  },
  {
    label: "ARPHEUILLES",
    value: "18013",
    type: "common",
  },
  {
    label: "MEILLANT",
    value: "18142",
    type: "common",
  },
  {
    label: "LA CELLE",
    value: "18042",
    type: "common",
  },
  {
    label: "VALLENAY",
    value: "18270",
    type: "common",
  },
  {
    label: "FARGES-ALLICHAMPS",
    value: "18091",
    type: "common",
  },
  {
    label: "BRUERE-ALLICHAMPS",
    value: "18038",
    type: "common",
  },
  {
    label: "INEUIL",
    value: "18114",
    type: "common",
  },
  {
    label: "CHAMBON",
    value: "18046",
    type: "common",
  },
  {
    label: "LIGNIERES",
    value: "18127",
    type: "common",
  },
  {
    label: "LA CELLE-CONDE",
    value: "18043",
    type: "common",
  },
  {
    label: "PRUNIERS",
    value: "36169",
    type: "common",
  },
  {
    label: "BOMMIERS",
    value: "36019",
    type: "common",
  },
  {
    label: "AMBRAULT",
    value: "36003",
    type: "common",
  },
  {
    label: "SASSIERGES-SAINT-GERMAIN",
    value: "36211",
    type: "common",
  },
  {
    label: "ETRECHET",
    value: "36071",
    type: "common",
  },
  {
    label: "ARDENTES",
    value: "36005",
    type: "common",
  },
  {
    label: "LE POINCONNET",
    value: "36159",
    type: "common",
  },
  {
    label: "SAINT-MAUR",
    value: "36202",
    type: "common",
  },
  {
    label: "NEUILLAY-LES-BOIS",
    value: "36139",
    type: "common",
  },
  {
    label: "MEOBECQ",
    value: "36118",
    type: "common",
  },
  {
    label: "SAINT-MICHEL-EN-BRENNE",
    value: "36204",
    type: "common",
  },
  {
    label: "LINGE",
    value: "36096",
    type: "common",
  },
  {
    label: "LUREUIL",
    value: "36105",
    type: "common",
  },
  {
    label: "TOURNON-SAINT-PIERRE",
    value: "37259",
    type: "common",
  },
  {
    label: "TOURNON-SAINT-MARTIN",
    value: "36224",
    type: "common",
  },
  {
    label: "NEONS-SUR-CREUSE",
    value: "36137",
    type: "common",
  },
  {
    label: "VICQ-SUR-GARTEMPE",
    value: "86288",
    type: "common",
  },
  {
    label: "LA ROCHE-POSAY",
    value: "86207",
    type: "common",
  },
  {
    label: "PLEUMARTIN",
    value: "86193",
    type: "common",
  },
  {
    label: "LEIGNE-LES-BOIS",
    value: "86125",
    type: "common",
  },
  {
    label: "CHENEVELLES",
    value: "86072",
    type: "common",
  },
  {
    label: "MONTHOIRON",
    value: "86164",
    type: "common",
  },
  {
    label: "AVAILLES-EN-CHATELLERAULT",
    value: "86014",
    type: "common",
  },
  {
    label: "NAINTRE",
    value: "86174",
    type: "common",
  },
  {
    label: "CENON-SUR-VIENNE",
    value: "86046",
    type: "common",
  },
  {
    label: "BEAUMONT SAINT-CYR",
    value: "86019",
    type: "common",
  },
  {
    label: "SAINT-MARTIN-LA-PALLU",
    value: "86281",
    type: "common",
  },
  {
    label: "OUZILLY",
    value: "86184",
    type: "common",
  },
  {
    label: "THURAGEAU",
    value: "86271",
    type: "common",
  },
  {
    label: "CHAMPIGNY EN ROCHEREAU",
    value: "86053",
    type: "common",
  },
  {
    label: "AMBERRE",
    value: "86002",
    type: "common",
  },
  {
    label: "VOUZAILLES",
    value: "86299",
    type: "common",
  },
  {
    label: "MASSOGNES",
    value: "86150",
    type: "common",
  },
  {
    label: "MAISONNEUVE",
    value: "86144",
    type: "common",
  },
  {
    label: "CUHON",
    value: "86089",
    type: "common",
  },
  {
    label: "CRAON",
    value: "86087",
    type: "common",
  },
  {
    label: "DOUX",
    value: "79108",
    type: "common",
  },
  {
    label: "PRESSIGNY",
    value: "79218",
    type: "common",
  },
  {
    label: "LE CHILLOU",
    value: "79089",
    type: "common",
  },
  {
    label: "AUBIGNY",
    value: "79019",
    type: "common",
  },
  {
    label: "GOURGE",
    value: "79135",
    type: "common",
  },
  {
    label: "LAGEON",
    value: "79145",
    type: "common",
  },
  {
    label: "SAINT-GERMAIN-DE-LONGUE-CHAUME",
    value: "79255",
    type: "common",
  },
  {
    label: "AMAILLOUX",
    value: "79008",
    type: "common",
  },
  {
    label: "CLESSE",
    value: "79094",
    type: "common",
  },
  {
    label: "LA CHAPELLE-SAINT-LAURENT",
    value: "79076",
    type: "common",
  },
  {
    label: "SAINT-PIERRE-DU-CHEMIN",
    value: "85264",
    type: "common",
  },
  {
    label: "LA FORET-SUR-SEVRE",
    value: "79123",
    type: "common",
  },
  {
    label: "REAUMUR",
    value: "85187",
    type: "common",
  },
  {
    label: "MENOMBLET",
    value: "85141",
    type: "common",
  },
  {
    label: "TALLUD-SAINTE-GEMME",
    value: "85287",
    type: "common",
  },
  {
    label: "LA MEILLERAIE-TILLAY",
    value: "85140",
    type: "common",
  },
  {
    label: "MONSIREIGNE",
    value: "85145",
    type: "common",
  },
  {
    label: "CHAVAGNES-LES-REDOUX",
    value: "85066",
    type: "common",
  },
  {
    label: "SIGOURNAIS",
    value: "85282",
    type: "common",
  },
  {
    label: "SAINT-GERMAIN-DE-PRINCAY",
    value: "85220",
    type: "common",
  },
  {
    label: "SAINT-VINCENT-STERLANGES",
    value: "85276",
    type: "common",
  },
  {
    label: "SAINTE-CECILE",
    value: "85202",
    type: "common",
  },
  {
    label: "SAINT-MARTIN-DES-NOYERS",
    value: "85246",
    type: "common",
  },
  {
    label: "SAINT-HILAIRE-LE-VOUHIS",
    value: "85232",
    type: "common",
  },
  {
    label: "LA FERRIERE",
    value: "85089",
    type: "common",
  },
  {
    label: "MOUILLERON-LE-CAPTIF",
    value: "85155",
    type: "common",
  },
  {
    label: "VENANSAULT",
    value: "85300",
    type: "common",
  },
  {
    label: "LA GENETOUZE",
    value: "85098",
    type: "common",
  },
  {
    label: "BEAULIEU-SOUS-LA-ROCHE",
    value: "85016",
    type: "common",
  },
  {
    label: "COEX",
    value: "85070",
    type: "common",
  },
  {
    label: "LA CHAPELLE-HERMIER",
    value: "85054",
    type: "common",
  },
  {
    label: "SAINT-REVEREND",
    value: "85268",
    type: "common",
  },
  {
    label: "L'AIGUILLON-SUR-VIE",
    value: "85002",
    type: "common",
  },
  {
    label: "SAINT-GILLES-CROIX-DE-VIE",
    value: "85222",
    type: "common",
  },
  {
    label: "GIVRAND",
    value: "85100",
    type: "common",
  },
  {
    label: "LE FENOUILLER",
    value: "85088",
    type: "common",
  },
  {
    label: "CHAUX-NEUVE",
    value: "25142",
    type: "common",
  },
  {
    label: "FONCINE-LE-HAUT",
    value: "39228",
    type: "common",
  },
  {
    label: "CHATELBLANC",
    value: "25131",
    type: "common",
  },
  {
    label: "LES PLANCHES-EN-MONTAGNE",
    value: "39424",
    type: "common",
  },
  {
    label: "FORT-DU-PLASNE",
    value: "39232",
    type: "common",
  },
  {
    label: "FONCINE-LE-BAS",
    value: "39227",
    type: "common",
  },
  {
    label: "ENTRE-DEUX-MONTS",
    value: "39208",
    type: "common",
  },
  {
    label: "CHAUX-DES-CROTENAY",
    value: "39129",
    type: "common",
  },
  {
    label: "LE FRASNOIS",
    value: "39240",
    type: "common",
  },
  {
    label: "CHATELNEUF",
    value: "39120",
    type: "common",
  },
  {
    label: "SONGESON",
    value: "39518",
    type: "common",
  },
  {
    label: "SAFFLOZ",
    value: "39473",
    type: "common",
  },
  {
    label: "FONTENU",
    value: "39230",
    type: "common",
  },
  {
    label: "CHEVROTAINE",
    value: "39143",
    type: "common",
  },
  {
    label: "MARIGNY",
    value: "39313",
    type: "common",
  },
  {
    label: "DOUCIER",
    value: "39201",
    type: "common",
  },
  {
    label: "CHATILLON",
    value: "39122",
    type: "common",
  },
  {
    label: "CHARCIER",
    value: "39107",
    type: "common",
  },
  {
    label: "VEVY",
    value: "39558",
    type: "common",
  },
  {
    label: "VERGES",
    value: "39550",
    type: "common",
  },
  {
    label: "BRIOD",
    value: "39079",
    type: "common",
  },
  {
    label: "PERRIGNY",
    value: "39411",
    type: "common",
  },
  {
    label: "MONTAIGU",
    value: "39348",
    type: "common",
  },
  {
    label: "MOIRON",
    value: "39334",
    type: "common",
  },
  {
    label: "MACORNAY",
    value: "39306",
    type: "common",
  },
  {
    label: "LONS-LE-SAUNIER",
    value: "39300",
    type: "common",
  },
  {
    label: "CONLIEGE",
    value: "39164",
    type: "common",
  },
  {
    label: "TRENAL",
    value: "39537",
    type: "common",
  },
  {
    label: "MONTMOROT",
    value: "39362",
    type: "common",
  },
  {
    label: "MESSIA-SUR-SORNE",
    value: "39327",
    type: "common",
  },
  {
    label: "GEVINGEY",
    value: "39251",
    type: "common",
  },
  {
    label: "FREBUANS",
    value: "39241",
    type: "common",
  },
  {
    label: "COURLAOUX",
    value: "39171",
    type: "common",
  },
  {
    label: "COURLANS",
    value: "39170",
    type: "common",
  },
  {
    label: "COURBOUZON",
    value: "39169",
    type: "common",
  },
  {
    label: "CHILLY-LE-VIGNOBLE",
    value: "39146",
    type: "common",
  },
  {
    label: "BEAUREPAIRE-EN-BRESSE",
    value: "71027",
    type: "common",
  },
  {
    label: "LES REPOTS",
    value: "39457",
    type: "common",
  },
  {
    label: "FONTAINEBRUX",
    value: "39229",
    type: "common",
  },
  {
    label: "CONDAMINE",
    value: "39162",
    type: "common",
  },
  {
    label: "MONTCONY",
    value: "71311",
    type: "common",
  },
  {
    label: "MONTAGNY-PRES-LOUHANS",
    value: "71303",
    type: "common",
  },
  {
    label: "LE FAY",
    value: "71196",
    type: "common",
  },
  {
    label: "VINCELLES",
    value: "71580",
    type: "common",
  },
  {
    label: "SAINT-USUGE",
    value: "71484",
    type: "common",
  },
  {
    label: "MONTRET",
    value: "71319",
    type: "common",
  },
  {
    label: "JUIF",
    value: "71246",
    type: "common",
  },
  {
    label: "BRANGES",
    value: "71056",
    type: "common",
  },
  {
    label: "SAINT-VINCENT-EN-BRESSE",
    value: "71489",
    type: "common",
  },
  {
    label: "SAINT-ANDRE-EN-BRESSE",
    value: "71386",
    type: "common",
  },
  {
    label: "SAINT-GERMAIN-DU-PLAIN",
    value: "71420",
    type: "common",
  },
  {
    label: "GIGNY-SUR-SAONE",
    value: "71219",
    type: "common",
  },
  {
    label: "BAUDRIERES",
    value: "71023",
    type: "common",
  },
  {
    label: "SAINT-CYR",
    value: "71402",
    type: "common",
  },
  {
    label: "MARNAY",
    value: "71283",
    type: "common",
  },
  {
    label: "BEAUMONT-SUR-GROSNE",
    value: "71026",
    type: "common",
  },
  {
    label: "SAINT-GERMAIN-LES-BUXY",
    value: "71422",
    type: "common",
  },
  {
    label: "SAINT-AMBREUIL",
    value: "71384",
    type: "common",
  },
  {
    label: "LAIVES",
    value: "71249",
    type: "common",
  },
  {
    label: "JULLY-LES-BUXY",
    value: "71247",
    type: "common",
  },
  {
    label: "CHENOVES",
    value: "71124",
    type: "common",
  },
  {
    label: "SAVIANGES",
    value: "71505",
    type: "common",
  },
  {
    label: "SAINT-VALLERIN",
    value: "71485",
    type: "common",
  },
  {
    label: "MONTAGNY-LES-BUXY",
    value: "71302",
    type: "common",
  },
  {
    label: "GERMAGNY",
    value: "71216",
    type: "common",
  },
  {
    label: "FLEY",
    value: "71201",
    type: "common",
  },
  {
    label: "CERSOT",
    value: "71072",
    type: "common",
  },
  {
    label: "BISSY-SUR-FLEY",
    value: "71037",
    type: "common",
  },
  {
    label: "SAINT-PRIVE",
    value: "71471",
    type: "common",
  },
  {
    label: "SAINT-MICAUD",
    value: "71465",
    type: "common",
  },
  {
    label: "LE PULEY",
    value: "71363",
    type: "common",
  },
  {
    label: "SAINT-EUSEBE",
    value: "71412",
    type: "common",
  },
  {
    label: "MARIGNY",
    value: "71278",
    type: "common",
  },
  {
    label: "MONTCEAU-LES-MINES",
    value: "71306",
    type: "common",
  },
  {
    label: "BLANZY",
    value: "71040",
    type: "common",
  },
  {
    label: "SANVIGNES-LES-MINES",
    value: "71499",
    type: "common",
  },
  {
    label: "SAINT-BERAIN-SOUS-SANVIGNES",
    value: "71390",
    type: "common",
  },
  {
    label: "DOMPIERRE-SOUS-SANVIGNES",
    value: "71179",
    type: "common",
  },
  {
    label: "TOULON-SUR-ARROUX",
    value: "71542",
    type: "common",
  },
  {
    label: "SAINTE-RADEGONDE",
    value: "71474",
    type: "common",
  },
  {
    label: "ISSY-L'EVEQUE",
    value: "71239",
    type: "common",
  },
  {
    label: "GRURY",
    value: "71227",
    type: "common",
  },
  {
    label: "CRESSY-SUR-SOMME",
    value: "71152",
    type: "common",
  },
  {
    label: "MALTAT",
    value: "71273",
    type: "common",
  },
  {
    label: "SAINT-SEINE",
    value: "58268",
    type: "common",
  },
  {
    label: "VITRY-SUR-LOIRE",
    value: "71589",
    type: "common",
  },
  {
    label: "CRONAT",
    value: "71155",
    type: "common",
  },
  {
    label: "GANNAY-SUR-LOIRE",
    value: "03119",
    type: "common",
  },
  {
    label: "LUCENAY-LES-AIX",
    value: "58146",
    type: "common",
  },
  {
    label: "TOURY-LURCY",
    value: "58293",
    type: "common",
  },
  {
    label: "SAINT-ENNEMOND",
    value: "03229",
    type: "common",
  },
  {
    label: "DORNES",
    value: "58104",
    type: "common",
  },
  {
    label: "TOURY-SUR-JOUR",
    value: "58294",
    type: "common",
  },
  {
    label: "VILLENEUVE-SUR-ALLIER",
    value: "03316",
    type: "common",
  },
  {
    label: "TRESNAY",
    value: "58296",
    type: "common",
  },
  {
    label: "SAINT-LEOPARDIN-D'AUGY",
    value: "03241",
    type: "common",
  },
  {
    label: "POUZY-MESANGY",
    value: "03210",
    type: "common",
  },
  {
    label: "COULEUVRE",
    value: "03087",
    type: "common",
  },
  {
    label: "VALIGNY",
    value: "03296",
    type: "common",
  },
  {
    label: "BESSAIS-LE-FROMENTAL",
    value: "18029",
    type: "common",
  },
  {
    label: "AINAY-LE-CHATEAU",
    value: "03003",
    type: "common",
  },
  {
    label: "COUST",
    value: "18076",
    type: "common",
  },
  {
    label: "COLOMBIERS",
    value: "18069",
    type: "common",
  },
  {
    label: "SAINT-GEORGES-DE-POISIEUX",
    value: "18209",
    type: "common",
  },
  {
    label: "SAINT-AMAND-MONTROND",
    value: "18197",
    type: "common",
  },
  {
    label: "ORVAL",
    value: "18172",
    type: "common",
  },
  {
    label: "LA GROUTTE",
    value: "18107",
    type: "common",
  },
  {
    label: "DREVANT",
    value: "18086",
    type: "common",
  },
  {
    label: "BOUZAIS",
    value: "18034",
    type: "common",
  },
  {
    label: "ORCENAIS",
    value: "18171",
    type: "common",
  },
  {
    label: "NOZIERES",
    value: "18169",
    type: "common",
  },
  {
    label: "MORLAC",
    value: "18153",
    type: "common",
  },
  {
    label: "MARCAIS",
    value: "18136",
    type: "common",
  },
  {
    label: "TOUCHAY",
    value: "18266",
    type: "common",
  },
  {
    label: "IDS-SAINT-ROCH",
    value: "18112",
    type: "common",
  },
  {
    label: "SAINT-CHRISTOPHE-EN-BOUCHERIE",
    value: "36186",
    type: "common",
  },
  {
    label: "SAINT-HILAIRE-EN-LIGNIERES",
    value: "18216",
    type: "common",
  },
  {
    label: "LA BERTHENOUX",
    value: "36017",
    type: "common",
  },
  {
    label: "SAINT-AOUT",
    value: "36180",
    type: "common",
  },
  {
    label: "JEU-LES-BOIS",
    value: "36089",
    type: "common",
  },
  {
    label: "ARTHON",
    value: "36009",
    type: "common",
  },
  {
    label: "VELLES",
    value: "36231",
    type: "common",
  },
  {
    label: "LA PEROUILLE",
    value: "36157",
    type: "common",
  },
  {
    label: "LUANT",
    value: "36101",
    type: "common",
  },
  {
    label: "NURET-LE-FERRON",
    value: "36144",
    type: "common",
  },
  {
    label: "MIGNE",
    value: "36124",
    type: "common",
  },
  {
    label: "ROSNAY",
    value: "36173",
    type: "common",
  },
  {
    label: "DOUADIC",
    value: "36066",
    type: "common",
  },
  {
    label: "POULIGNY-SAINT-PIERRE",
    value: "36165",
    type: "common",
  },
  {
    label: "PREUILLY-LA-VILLE",
    value: "36167",
    type: "common",
  },
  {
    label: "LURAIS",
    value: "36104",
    type: "common",
  },
  {
    label: "FONTGOMBAULT",
    value: "36076",
    type: "common",
  },
  {
    label: "ANGLES-SUR-L'ANGLIN",
    value: "86004",
    type: "common",
  },
  {
    label: "ARCHIGNY",
    value: "86009",
    type: "common",
  },
  {
    label: "BONNEUIL-MATOURS",
    value: "86032",
    type: "common",
  },
  {
    label: "VOUNEUIL-SUR-VIENNE",
    value: "86298",
    type: "common",
  },
  {
    label: "DISSAY",
    value: "86095",
    type: "common",
  },
  {
    label: "JAUNAY-MARIGNY",
    value: "86115",
    type: "common",
  },
  {
    label: "AVANTON",
    value: "86016",
    type: "common",
  },
  {
    label: "NEUVILLE-DE-POITOU",
    value: "86177",
    type: "common",
  },
  {
    label: "CHABOURNAY",
    value: "86048",
    type: "common",
  },
  {
    label: "YVERSAY",
    value: "86300",
    type: "common",
  },
  {
    label: "VILLIERS",
    value: "86292",
    type: "common",
  },
  {
    label: "MAILLE",
    value: "86142",
    type: "common",
  },
  {
    label: "FROZES",
    value: "86102",
    type: "common",
  },
  {
    label: "CHERVES",
    value: "86073",
    type: "common",
  },
  {
    label: "THENEZAY",
    value: "79326",
    type: "common",
  },
  {
    label: "OROUX",
    value: "79197",
    type: "common",
  },
  {
    label: "LA PEYRATTE",
    value: "79208",
    type: "common",
  },
  {
    label: "LHOUMOIS",
    value: "79149",
    type: "common",
  },
  {
    label: "VIENNAY",
    value: "79347",
    type: "common",
  },
  {
    label: "CHATILLON-SUR-THOUET",
    value: "79080",
    type: "common",
  },
  {
    label: "SAINT-AUBIN-LE-CLOUD",
    value: "79239",
    type: "common",
  },
  {
    label: "FENERY",
    value: "79118",
    type: "common",
  },
  {
    label: "ADILLY",
    value: "79002",
    type: "common",
  },
  {
    label: "POUGNE-HERISSON",
    value: "79215",
    type: "common",
  },
  {
    label: "NEUVY-BOUIN",
    value: "79190",
    type: "common",
  },
  {
    label: "TRAYES",
    value: "79332",
    type: "common",
  },
  {
    label: "LARGEASSE",
    value: "79147",
    type: "common",
  },
  {
    label: "L'ABSIE",
    value: "79001",
    type: "common",
  },
  {
    label: "SAINT-PAUL-EN-GATINE",
    value: "79286",
    type: "common",
  },
  {
    label: "BREUIL-BARRET",
    value: "85037",
    type: "common",
  },
  {
    label: "LA TARDIERE",
    value: "85289",
    type: "common",
  },
  {
    label: "CHEFFOIS",
    value: "85067",
    type: "common",
  },
  {
    label: "LA CHATAIGNERAIE",
    value: "85059",
    type: "common",
  },
  {
    label: "MOUILLERON-SAINT-GERMAIN",
    value: "85154",
    type: "common",
  },
  {
    label: "LA JAUDONNIERE",
    value: "85115",
    type: "common",
  },
  {
    label: "BAZOGES-EN-PAREDS",
    value: "85014",
    type: "common",
  },
  {
    label: "CHANTONNAY",
    value: "85051",
    type: "common",
  },
  {
    label: "BOURNEZEAU",
    value: "85034",
    type: "common",
  },
  {
    label: "FOUGERE",
    value: "85093",
    type: "common",
  },
  {
    label: "LA CHAIZE-LE-VICOMTE",
    value: "85046",
    type: "common",
  },
  {
    label: "LA ROCHE-SUR-YON",
    value: "85191",
    type: "common",
  },
  {
    label: "LANDERONDE",
    value: "85118",
    type: "common",
  },
  {
    label: "SAINT-GEORGES-DE-POINTINDOUX",
    value: "85218",
    type: "common",
  },
  {
    label: "LES ACHARDS",
    value: "85152",
    type: "common",
  },
  {
    label: "VAIRE",
    value: "85298",
    type: "common",
  },
  {
    label: "SAINT-JULIEN-DES-LANDES",
    value: "85236",
    type: "common",
  },
  {
    label: "MARTINET",
    value: "85138",
    type: "common",
  },
  {
    label: "BREM-SUR-MER",
    value: "85243",
    type: "common",
  },
  {
    label: "LANDEVIEILLE",
    value: "85120",
    type: "common",
  },
  {
    label: "LA CHAIZE-GIRAUD",
    value: "85045",
    type: "common",
  },
  {
    label: "BRETIGNOLLES-SUR-MER",
    value: "85035",
    type: "common",
  },
  {
    label: "BELLEFONTAINE",
    value: "39047",
    type: "common",
  },
  {
    label: "CHAPELLE-DES-BOIS",
    value: "25121",
    type: "common",
  },
  {
    label: "SAINT-LAURENT-EN-GRANDVAUX",
    value: "39487",
    type: "common",
  },
  {
    label: "MORBIER",
    value: "39367",
    type: "common",
  },
  {
    label: "LAC-DES-ROUGES-TRUITES",
    value: "39271",
    type: "common",
  },
  {
    label: "SAINT-PIERRE",
    value: "39494",
    type: "common",
  },
  {
    label: "LA CHAUX-DU-DOMBIEF",
    value: "39131",
    type: "common",
  },
  {
    label: "LA CHAUMUSSE",
    value: "39126",
    type: "common",
  },
  {
    label: "UXELLES",
    value: "39538",
    type: "common",
  },
  {
    label: "SAUGEOT",
    value: "39505",
    type: "common",
  },
  {
    label: "MENETRUX-EN-JOUX",
    value: "39322",
    type: "common",
  },
  {
    label: "DENEZIERES",
    value: "39192",
    type: "common",
  },
  {
    label: "BONLIEU",
    value: "39063",
    type: "common",
  },
  {
    label: "VERTAMBOZ",
    value: "39556",
    type: "common",
  },
  {
    label: "PATORNAY",
    value: "39408",
    type: "common",
  },
  {
    label: "COGNA",
    value: "39156",
    type: "common",
  },
  {
    label: "CHAREZIER",
    value: "39109",
    type: "common",
  },
  {
    label: "BOISSIA",
    value: "39061",
    type: "common",
  },
  {
    label: "PUBLY",
    value: "39445",
    type: "common",
  },
  {
    label: "PONT-DE-POITTE",
    value: "39435",
    type: "common",
  },
  {
    label: "POIDS-DE-FIOLE",
    value: "39431",
    type: "common",
  },
  {
    label: "NOGNA",
    value: "39390",
    type: "common",
  },
  {
    label: "MESNOIS",
    value: "39326",
    type: "common",
  },
  {
    label: "MARNEZIA",
    value: "39314",
    type: "common",
  },
  {
    label: "BLYE",
    value: "39058",
    type: "common",
  },
  {
    label: "VERNANTOIS",
    value: "39552",
    type: "common",
  },
  {
    label: "SAINT-MAUR",
    value: "39492",
    type: "common",
  },
  {
    label: "REVIGNY",
    value: "39458",
    type: "common",
  },
  {
    label: "GERUGE",
    value: "39250",
    type: "common",
  },
  {
    label: "COURBETTE",
    value: "39168",
    type: "common",
  },
  {
    label: "BORNAY",
    value: "39066",
    type: "common",
  },
  {
    label: "ALIEZE",
    value: "39007",
    type: "common",
  },
  {
    label: "VAL-SONNETTE",
    value: "39576",
    type: "common",
  },
  {
    label: "SAINTE-AGNES",
    value: "39474",
    type: "common",
  },
  {
    label: "ROTALIER",
    value: "39467",
    type: "common",
  },
  {
    label: "CESANCEY",
    value: "39088",
    type: "common",
  },
  {
    label: "SAVIGNY-EN-REVERMONT",
    value: "71506",
    type: "common",
  },
  {
    label: "FLACEY-EN-BRESSE",
    value: "71198",
    type: "common",
  },
  {
    label: "SAINT-MARTIN-DU-MONT",
    value: "71454",
    type: "common",
  },
  {
    label: "SAGY",
    value: "71379",
    type: "common",
  },
  {
    label: "RATTE",
    value: "71367",
    type: "common",
  },
  {
    label: "LOUHANS",
    value: "71263",
    type: "common",
  },
  {
    label: "LA CHAPELLE-NAUDE",
    value: "71092",
    type: "common",
  },
  {
    label: "BRUAILLES",
    value: "71064",
    type: "common",
  },
  {
    label: "SORNAY",
    value: "71528",
    type: "common",
  },
  {
    label: "BANTANGES",
    value: "71018",
    type: "common",
  },
  {
    label: "SAVIGNY-SUR-SEILLE",
    value: "71508",
    type: "common",
  },
  {
    label: "RANCY",
    value: "71365",
    type: "common",
  },
  {
    label: "HUILLY-SUR-SEILLE",
    value: "71234",
    type: "common",
  },
  {
    label: "LA FRETTE",
    value: "71206",
    type: "common",
  },
  {
    label: "SIMANDRE",
    value: "71522",
    type: "common",
  },
  {
    label: "ORMES",
    value: "71332",
    type: "common",
  },
  {
    label: "SENNECEY-LE-GRAND",
    value: "71512",
    type: "common",
  },
  {
    label: "JUGY",
    value: "71245",
    type: "common",
  },
  {
    label: "BOYER",
    value: "71052",
    type: "common",
  },
  {
    label: "NANTON",
    value: "71328",
    type: "common",
  },
  {
    label: "MONTCEAUX-RAGNY",
    value: "71308",
    type: "common",
  },
  {
    label: "LALHEUE",
    value: "71252",
    type: "common",
  },
  {
    label: "LA CHAPELLE-DE-BRAGNY",
    value: "71089",
    type: "common",
  },
  {
    label: "SERCY",
    value: "71515",
    type: "common",
  },
  {
    label: "SANTILLY",
    value: "71498",
    type: "common",
  },
  {
    label: "SAINT-BOIL",
    value: "71392",
    type: "common",
  },
  {
    label: "MESSEY-SUR-GROSNE",
    value: "71296",
    type: "common",
  },
  {
    label: "VAUX-EN-PRE",
    value: "71563",
    type: "common",
  },
  {
    label: "SAULES",
    value: "71503",
    type: "common",
  },
  {
    label: "SAINT-MAURICE-DES-CHAMPS",
    value: "71461",
    type: "common",
  },
  {
    label: "SAINT-MARTIN-DU-TARTRE",
    value: "71455",
    type: "common",
  },
  {
    label: "SAINT-GENGOUX-LE-NATIONAL",
    value: "71417",
    type: "common",
  },
  {
    label: "SAINT-CLEMENT-SUR-GUYE",
    value: "71400",
    type: "common",
  },
  {
    label: "CULLES-LES-ROCHES",
    value: "71159",
    type: "common",
  },
  {
    label: "BURNAND",
    value: "71067",
    type: "common",
  },
  {
    label: "JONCY",
    value: "71242",
    type: "common",
  },
  {
    label: "GENOUILLY",
    value: "71214",
    type: "common",
  },
  {
    label: "COLLONGE-EN-CHAROLLAIS",
    value: "71139",
    type: "common",
  },
  {
    label: "SAINT-ROMAIN-SOUS-GOURDON",
    value: "71477",
    type: "common",
  },
  {
    label: "MONT-SAINT-VINCENT",
    value: "71320",
    type: "common",
  },
  {
    label: "MARY",
    value: "71286",
    type: "common",
  },
  {
    label: "GOURDON",
    value: "71222",
    type: "common",
  },
  {
    label: "SAINT-VALLIER",
    value: "71486",
    type: "common",
  },
  {
    label: "POUILLOUX",
    value: "71356",
    type: "common",
  },
  {
    label: "SAINT-ROMAIN-SOUS-VERSIGNY",
    value: "71478",
    type: "common",
  },
  {
    label: "PERRECY-LES-FORGES",
    value: "71346",
    type: "common",
  },
  {
    label: "MARLY-SUR-ARROUX",
    value: "71281",
    type: "common",
  },
  {
    label: "VENDENESSE-SUR-ARROUX",
    value: "71565",
    type: "common",
  },
  {
    label: "UXEAU",
    value: "71552",
    type: "common",
  },
  {
    label: "LA CHAPELLE-AU-MANS",
    value: "71088",
    type: "common",
  },
  {
    label: "MONT",
    value: "71301",
    type: "common",
  },
  {
    label: "BOURBON-LANCY",
    value: "71047",
    type: "common",
  },
  {
    label: "LESME",
    value: "71255",
    type: "common",
  },
  {
    label: "SAINT-MARTIN-DES-LAIS",
    value: "03245",
    type: "common",
  },
  {
    label: "GARNAT-SUR-ENGIEVRE",
    value: "03120",
    type: "common",
  },
  {
    label: "PARAY-LE-FRESIL",
    value: "03203",
    type: "common",
  },
  {
    label: "LA CHAPELLE-AUX-CHASSES",
    value: "03057",
    type: "common",
  },
  {
    label: "GENNETINES",
    value: "03121",
    type: "common",
  },
  {
    label: "TREVOL",
    value: "03290",
    type: "common",
  },
  {
    label: "AUROUER",
    value: "03011",
    type: "common",
  },
  {
    label: "BAGNEUX",
    value: "03015",
    type: "common",
  },
  {
    label: "AUBIGNY",
    value: "03009",
    type: "common",
  },
  {
    label: "LIMOISE",
    value: "03146",
    type: "common",
  },
  {
    label: "COUZON",
    value: "03090",
    type: "common",
  },
  {
    label: "FRANCHESSE",
    value: "03117",
    type: "common",
  },
  {
    label: "SAINT-PLAISIR",
    value: "03251",
    type: "common",
  },
  {
    label: "CERILLY",
    value: "03048",
    type: "common",
  },
  {
    label: "ISLE-ET-BARDAIS",
    value: "03130",
    type: "common",
  },
  {
    label: "SAINT-BONNET-TRONCAIS",
    value: "03221",
    type: "common",
  },
  {
    label: "BRAIZE",
    value: "03037",
    type: "common",
  },
  {
    label: "LA PERCHE",
    value: "18178",
    type: "common",
  },
  {
    label: "AINAY-LE-VIEIL",
    value: "18002",
    type: "common",
  },
  {
    label: "URCAY",
    value: "03293",
    type: "common",
  },
  {
    label: "LETELON",
    value: "03143",
    type: "common",
  },
  {
    label: "FAVERDINES",
    value: "18093",
    type: "common",
  },
  {
    label: "LA CELETTE",
    value: "18041",
    type: "common",
  },
  {
    label: "LOYE-SUR-ARNON",
    value: "18130",
    type: "common",
  },
  {
    label: "ARCOMPS",
    value: "18009",
    type: "common",
  },
  {
    label: "SAINT-PIERRE-LES-BOIS",
    value: "18230",
    type: "common",
  },
  {
    label: "LE CHATELET",
    value: "18059",
    type: "common",
  },
  {
    label: "ARDENAIS",
    value: "18010",
    type: "common",
  },
  {
    label: "MAISONNAIS",
    value: "18135",
    type: "common",
  },
  {
    label: "VICQ-EXEMPLET",
    value: "36236",
    type: "common",
  },
  {
    label: "REZAY",
    value: "18193",
    type: "common",
  },
  {
    label: "THEVET-SAINT-JULIEN",
    value: "36221",
    type: "common",
  },
  {
    label: "VERNEUIL-SUR-IGNERAIE",
    value: "36234",
    type: "common",
  },
  {
    label: "SAINT-CHARTIER",
    value: "36184",
    type: "common",
  },
  {
    label: "NOHANT-VIC",
    value: "36143",
    type: "common",
  },
  {
    label: "TRANZAULT",
    value: "36226",
    type: "common",
  },
  {
    label: "MONTIPOURET",
    value: "36129",
    type: "common",
  },
  {
    label: "MERS-SUR-INDRE",
    value: "36120",
    type: "common",
  },
  {
    label: "LYS-SAINT-GEORGES",
    value: "36108",
    type: "common",
  },
  {
    label: "BUXIERES-D'AILLAC",
    value: "36030",
    type: "common",
  },
  {
    label: "BOUESSE",
    value: "36022",
    type: "common",
  },
  {
    label: "MOSNAY",
    value: "36131",
    type: "common",
  },
  {
    label: "TENDU",
    value: "36219",
    type: "common",
  },
  {
    label: "SAINT-GAULTIER",
    value: "36192",
    type: "common",
  },
  {
    label: "LE PONT-CHRETIEN-CHABENET",
    value: "36161",
    type: "common",
  },
  {
    label: "CHASSENEUIL",
    value: "36042",
    type: "common",
  },
  {
    label: "CHITRAY",
    value: "36051",
    type: "common",
  },
  {
    label: "RUFFEC",
    value: "36176",
    type: "common",
  },
  {
    label: "CIRON",
    value: "36053",
    type: "common",
  },
  {
    label: "LE BLANC",
    value: "36018",
    type: "common",
  },
  {
    label: "SAINT-AIGNY",
    value: "36178",
    type: "common",
  },
  {
    label: "SAUZELLES",
    value: "36213",
    type: "common",
  },
  {
    label: "MERIGNY",
    value: "36119",
    type: "common",
  },
  {
    label: "SAINT-PIERRE-DE-MAILLE",
    value: "86236",
    type: "common",
  },
  {
    label: "NALLIERS",
    value: "86175",
    type: "common",
  },
  {
    label: "LA BUSSIERE",
    value: "86040",
    type: "common",
  },
  {
    label: "LA PUYE",
    value: "86202",
    type: "common",
  },
  {
    label: "SAINTE-RADEGONDE",
    value: "86239",
    type: "common",
  },
  {
    label: "BONNES",
    value: "86031",
    type: "common",
  },
  {
    label: "BELLEFONDS",
    value: "86020",
    type: "common",
  },
  {
    label: "LINIERS",
    value: "86135",
    type: "common",
  },
  {
    label: "LA CHAPELLE-MOULIERE",
    value: "86058",
    type: "common",
  },
  {
    label: "SAINT-GEORGES-LES-BAILLARGEAUX",
    value: "86222",
    type: "common",
  },
  {
    label: "MONTAMISE",
    value: "86163",
    type: "common",
  },
  {
    label: "BIGNOUX",
    value: "86028",
    type: "common",
  },
  {
    label: "CHASSENEUIL-DU-POITOU",
    value: "86062",
    type: "common",
  },
  {
    label: "BUXEROLLES",
    value: "86041",
    type: "common",
  },
  {
    label: "MIGNE-AUXANCES",
    value: "86158",
    type: "common",
  },
  {
    label: "CISSE",
    value: "86076",
    type: "common",
  },
  {
    label: "VOUILLE",
    value: "86294",
    type: "common",
  },
  {
    label: "LATILLE",
    value: "86121",
    type: "common",
  },
  {
    label: "CHIRE-EN-MONTREUIL",
    value: "86074",
    type: "common",
  },
  {
    label: "AYRON",
    value: "86017",
    type: "common",
  },
  {
    label: "CHALANDRAY",
    value: "86050",
    type: "common",
  },
  {
    label: "LA FERRIERE-EN-PARTHENAY",
    value: "79120",
    type: "common",
  },
  {
    label: "SAURAIS",
    value: "79306",
    type: "common",
  },
  {
    label: "SAINT-MARTIN-DU-FOUILLOUX",
    value: "79278",
    type: "common",
  },
  {
    label: "LA CHAPELLE-BERTRAND",
    value: "79071",
    type: "common",
  },
  {
    label: "LE TALLUD",
    value: "79322",
    type: "common",
  },
  {
    label: "POMPAIRE",
    value: "79213",
    type: "common",
  },
  {
    label: "PARTHENAY",
    value: "79202",
    type: "common",
  },
  {
    label: "AZAY-SUR-THOUET",
    value: "79025",
    type: "common",
  },
  {
    label: "SECONDIGNY",
    value: "79311",
    type: "common",
  },
  {
    label: "VERNOUX-EN-GATINE",
    value: "79342",
    type: "common",
  },
  {
    label: "SCILLE",
    value: "79309",
    type: "common",
  },
  {
    label: "LE BUSSEAU",
    value: "79059",
    type: "common",
  },
  {
    label: "SAINT-HILAIRE-DE-VOUST",
    value: "85229",
    type: "common",
  },
  {
    label: "LOGE-FOUGEREUSE",
    value: "85125",
    type: "common",
  },
  {
    label: "LA CHAPELLE-AUX-LYS",
    value: "85053",
    type: "common",
  },
  {
    label: "SAINT-MAURICE-DES-NOUES",
    value: "85251",
    type: "common",
  },
  {
    label: "CEZAIS",
    value: "85041",
    type: "common",
  },
  {
    label: "ANTIGNY",
    value: "85005",
    type: "common",
  },
  {
    label: "THOUARSAIS-BOUILDROUX",
    value: "85292",
    type: "common",
  },
  {
    label: "SAINT-SULPICE-EN-PAREDS",
    value: "85271",
    type: "common",
  },
  {
    label: "SAINT-MAURICE-LE-GIRARD",
    value: "85252",
    type: "common",
  },
  {
    label: "SAINT-CYR-DES-GATS",
    value: "85205",
    type: "common",
  },
  {
    label: "SAINT-LAURENT-DE-LA-SALLE",
    value: "85237",
    type: "common",
  },
  {
    label: "LA CAILLERE-SAINT-HILAIRE",
    value: "85040",
    type: "common",
  },
  {
    label: "SAINT-MARTIN-LARS-EN-SAINTE-HERMINE",
    value: "85248",
    type: "common",
  },
  {
    label: "SAINT-JUIRE-CHAMPGILLON",
    value: "85235",
    type: "common",
  },
  {
    label: "LA REORTHE",
    value: "85188",
    type: "common",
  },
  {
    label: "LES PINEAUX",
    value: "85175",
    type: "common",
  },
  {
    label: "THORIGNY",
    value: "85291",
    type: "common",
  },
  {
    label: "CHATEAU-GUIBERT",
    value: "85061",
    type: "common",
  },
  {
    label: "RIVES DE L'YON",
    value: "85213",
    type: "common",
  },
  {
    label: "NESMY",
    value: "85160",
    type: "common",
  },
  {
    label: "LA BOISSIERE-DES-LANDES",
    value: "85026",
    type: "common",
  },
  {
    label: "AUBIGNY-LES CLOUZEAUX",
    value: "85008",
    type: "common",
  },
  {
    label: "NIEUL-LE-DOLENT",
    value: "85161",
    type: "common",
  },
  {
    label: "SAINTE-FLAIVE-DES-LOUPS",
    value: "85211",
    type: "common",
  },
  {
    label: "LE GIROUARD",
    value: "85099",
    type: "common",
  },
  {
    label: "SAINT-MATHURIN",
    value: "85250",
    type: "common",
  },
  {
    label: "L'ILE-D'OLONNE",
    value: "85112",
    type: "common",
  },
  {
    label: "BOIS-D'AMONT",
    value: "39059",
    type: "common",
  },
  {
    label: "HAUTS DE BIENNE",
    value: "39368",
    type: "common",
  },
  {
    label: "SAINT-MAURICE-CRILLAT",
    value: "39493",
    type: "common",
  },
  {
    label: "HAUTECOUR",
    value: "39265",
    type: "common",
  },
  {
    label: "LA FRASNEE",
    value: "39239",
    type: "common",
  },
  {
    label: "CHATEL-DE-JOUX",
    value: "39118",
    type: "common",
  },
  {
    label: "THOIRIA",
    value: "39531",
    type: "common",
  },
  {
    label: "SOUCIA",
    value: "39519",
    type: "common",
  },
  {
    label: "CLAIRVAUX-LES-LACS",
    value: "39154",
    type: "common",
  },
  {
    label: "BARESIA-SUR-L'AIN",
    value: "39038",
    type: "common",
  },
  {
    label: "LA TOUR-DU-MEIX",
    value: "39534",
    type: "common",
  },
  {
    label: "PLAISIA",
    value: "39423",
    type: "common",
  },
  {
    label: "MERONA",
    value: "39324",
    type: "common",
  },
  {
    label: "LARGILLAY-MARSONNAY",
    value: "39278",
    type: "common",
  },
  {
    label: "DOMPIERRE-SUR-MONT",
    value: "39200",
    type: "common",
  },
  {
    label: "REITHOUSE",
    value: "39455",
    type: "common",
  },
  {
    label: "PRESILLY",
    value: "39443",
    type: "common",
  },
  {
    label: "ORGELET",
    value: "39397",
    type: "common",
  },
  {
    label: "MOUTONNE",
    value: "39375",
    type: "common",
  },
  {
    label: "BEFFIA",
    value: "39045",
    type: "common",
  },
  {
    label: "LA CHAILLEUSE",
    value: "39021",
    type: "common",
  },
  {
    label: "ROSAY",
    value: "39466",
    type: "common",
  },
  {
    label: "CRESSIA",
    value: "39180",
    type: "common",
  },
  {
    label: "AUGISEY",
    value: "39027",
    type: "common",
  },
  {
    label: "MAYNAL",
    value: "39320",
    type: "common",
  },
  {
    label: "GIZIA",
    value: "39255",
    type: "common",
  },
  {
    label: "CUISIA",
    value: "39185",
    type: "common",
  },
  {
    label: "COUSANCE",
    value: "39173",
    type: "common",
  },
  {
    label: "AUGEA",
    value: "39025",
    type: "common",
  },
  {
    label: "LE MIROIR",
    value: "71300",
    type: "common",
  },
  {
    label: "FRONTENAUD",
    value: "71209",
    type: "common",
  },
  {
    label: "SAINTE-CROIX-EN-BRESSE",
    value: "71401",
    type: "common",
  },
  {
    label: "MONTPONT-EN-BRESSE",
    value: "71318",
    type: "common",
  },
  {
    label: "MENETREUIL",
    value: "71293",
    type: "common",
  },
  {
    label: "LA CHAPELLE-THECLE",
    value: "71097",
    type: "common",
  },
  {
    label: "JOUVENCON",
    value: "71244",
    type: "common",
  },
  {
    label: "LA GENETE",
    value: "71213",
    type: "common",
  },
  {
    label: "BRIENNE",
    value: "71061",
    type: "common",
  },
  {
    label: "LACROST",
    value: "71248",
    type: "common",
  },
  {
    label: "PRETY",
    value: "71359",
    type: "common",
  },
  {
    label: "LOISY",
    value: "71261",
    type: "common",
  },
  {
    label: "CUISERY",
    value: "71158",
    type: "common",
  },
  {
    label: "L'ABERGEMENT-DE-CUISERY",
    value: "71001",
    type: "common",
  },
  {
    label: "TOURNUS",
    value: "71543",
    type: "common",
  },
  {
    label: "OZENAY",
    value: "71338",
    type: "common",
  },
  {
    label: "VERS",
    value: "71572",
    type: "common",
  },
  {
    label: "MANCEY",
    value: "71274",
    type: "common",
  },
  {
    label: "LA CHAPELLE-SOUS-BRANCION",
    value: "71094",
    type: "common",
  },
  {
    label: "ROYER",
    value: "71377",
    type: "common",
  },
  {
    label: "ETRIGNY",
    value: "71193",
    type: "common",
  },
  {
    label: "MALAY",
    value: "71272",
    type: "common",
  },
  {
    label: "CHAPAIZE",
    value: "71087",
    type: "common",
  },
  {
    label: "CHAMPAGNY-SOUS-UXELLES",
    value: "71080",
    type: "common",
  },
  {
    label: "BRESSE-SUR-GROSNE",
    value: "71058",
    type: "common",
  },
  {
    label: "BISSY-SOUS-UXELLES",
    value: "71036",
    type: "common",
  },
  {
    label: "SAVIGNY-SUR-GROSNE",
    value: "71507",
    type: "common",
  },
  {
    label: "SAINT-YTHAIRE",
    value: "71492",
    type: "common",
  },
  {
    label: "CURTIL-SOUS-BURNAND",
    value: "71164",
    type: "common",
  },
  {
    label: "BURZY",
    value: "71068",
    type: "common",
  },
  {
    label: "BONNAY",
    value: "71042",
    type: "common",
  },
  {
    label: "SIGY-LE-CHATEL",
    value: "71521",
    type: "common",
  },
  {
    label: "SAINT-MARTIN-LA-PATROUILLE",
    value: "71458",
    type: "common",
  },
  {
    label: "SAINT-MARCELIN-DE-CRAY",
    value: "71446",
    type: "common",
  },
  {
    label: "SAINT-HURUGE",
    value: "71427",
    type: "common",
  },
  {
    label: "PASSY",
    value: "71344",
    type: "common",
  },
  {
    label: "LE ROUSSET-MARIZY",
    value: "71279",
    type: "common",
  },
  {
    label: "CIRY-LE-NOBLE",
    value: "71132",
    type: "common",
  },
  {
    label: "PALINGES",
    value: "71340",
    type: "common",
  },
  {
    label: "OUDRY",
    value: "71334",
    type: "common",
  },
  {
    label: "GENELARD",
    value: "71212",
    type: "common",
  },
  {
    label: "CLESSY",
    value: "71136",
    type: "common",
  },
  {
    label: "CHASSY",
    value: "71111",
    type: "common",
  },
  {
    label: "GUEUGNON",
    value: "71230",
    type: "common",
  },
  {
    label: "CURDIN",
    value: "71161",
    type: "common",
  },
  {
    label: "NEUVY-GRANDCHAMP",
    value: "71330",
    type: "common",
  },
  {
    label: "CHALMOUX",
    value: "71075",
    type: "common",
  },
  {
    label: "SAINT-AUBIN-SUR-LOIRE",
    value: "71389",
    type: "common",
  },
  {
    label: "BEAULON",
    value: "03019",
    type: "common",
  },
  {
    label: "CHEVAGNES",
    value: "03074",
    type: "common",
  },
  {
    label: "LUSIGNY",
    value: "03156",
    type: "common",
  },
  {
    label: "CHEZY",
    value: "03076",
    type: "common",
  },
  {
    label: "YZEURE",
    value: "03321",
    type: "common",
  },
  {
    label: "MOULINS",
    value: "03190",
    type: "common",
  },
  {
    label: "AVERMES",
    value: "03013",
    type: "common",
  },
  {
    label: "NEUVY",
    value: "03200",
    type: "common",
  },
  {
    label: "MONTILLY",
    value: "03184",
    type: "common",
  },
  {
    label: "SAINT-MENOUX",
    value: "03247",
    type: "common",
  },
  {
    label: "MARIGNY",
    value: "03162",
    type: "common",
  },
  {
    label: "AGONGES",
    value: "03002",
    type: "common",
  },
  {
    label: "BOURBON-L'ARCHAMBAULT",
    value: "03036",
    type: "common",
  },
  {
    label: "THENEUILLE",
    value: "03282",
    type: "common",
  },
  {
    label: "LE VILHAIN",
    value: "03313",
    type: "common",
  },
  {
    label: "LE BRETHON",
    value: "03041",
    type: "common",
  },
  {
    label: "EPINEUIL-LE-FLEURIEL",
    value: "18089",
    type: "common",
  },
  {
    label: "MEAULNE-VITRAY",
    value: "03168",
    type: "common",
  },
  {
    label: "SAULZAIS-LE-POTIER",
    value: "18245",
    type: "common",
  },
  {
    label: "SAINT-CHRISTOPHE-LE-CHAUDRY",
    value: "18203",
    type: "common",
  },
  {
    label: "SAINT-MAUR",
    value: "18225",
    type: "common",
  },
  {
    label: "REIGNY",
    value: "18192",
    type: "common",
  },
  {
    label: "SAINT-JEANVRIN",
    value: "18217",
    type: "common",
  },
  {
    label: "BEDDES",
    value: "18024",
    type: "common",
  },
  {
    label: "NERET",
    value: "36138",
    type: "common",
  },
  {
    label: "MONTLEVICQ",
    value: "36130",
    type: "common",
  },
  {
    label: "LOUROUER-SAINT-LAURENT",
    value: "36100",
    type: "common",
  },
  {
    label: "LACS",
    value: "36091",
    type: "common",
  },
  {
    label: "MONTGIVRAY",
    value: "36127",
    type: "common",
  },
  {
    label: "LA CHATRE",
    value: "36046",
    type: "common",
  },
  {
    label: "SARZAY",
    value: "36210",
    type: "common",
  },
  {
    label: "NEUVY-SAINT-SEPULCHRE",
    value: "36141",
    type: "common",
  },
  {
    label: "MOUHERS",
    value: "36133",
    type: "common",
  },
  {
    label: "MAILLET",
    value: "36110",
    type: "common",
  },
  {
    label: "GOURNAY",
    value: "36084",
    type: "common",
  },
  {
    label: "MALICORNAY",
    value: "36111",
    type: "common",
  },
  {
    label: "SAINT-MARCEL",
    value: "36200",
    type: "common",
  },
  {
    label: "LE PECHEREAU",
    value: "36154",
    type: "common",
  },
  {
    label: "THENAY",
    value: "36220",
    type: "common",
  },
  {
    label: "ARGENTON-SUR-CREUSE",
    value: "36006",
    type: "common",
  },
  {
    label: "RIVARENNES",
    value: "36172",
    type: "common",
  },
  {
    label: "OULCHES",
    value: "36148",
    type: "common",
  },
  {
    label: "MAUVIERES",
    value: "36114",
    type: "common",
  },
  {
    label: "SAINT-HILAIRE-SUR-BENAIZE",
    value: "36197",
    type: "common",
  },
  {
    label: "CONCREMIERS",
    value: "36058",
    type: "common",
  },
  {
    label: "SAINT-GERMAIN",
    value: "86223",
    type: "common",
  },
  {
    label: "INGRANDES",
    value: "36087",
    type: "common",
  },
  {
    label: "SAINT-SAVIN",
    value: "86246",
    type: "common",
  },
  {
    label: "PAIZAY-LE-SEC",
    value: "86187",
    type: "common",
  },
  {
    label: "FLEIX",
    value: "86098",
    type: "common",
  },
  {
    label: "LAUTHIERS",
    value: "86122",
    type: "common",
  },
  {
    label: "CHAUVIGNY",
    value: "86070",
    type: "common",
  },
  {
    label: "JARDRES",
    value: "86114",
    type: "common",
  },
  {
    label: "SAINT-JULIEN-L'ARS",
    value: "86226",
    type: "common",
  },
  {
    label: "LAVOUX",
    value: "86124",
    type: "common",
  },
  {
    label: "SEVRES-ANXAUMONT",
    value: "86261",
    type: "common",
  },
  {
    label: "MIGNALOUX-BEAUVOIR",
    value: "86157",
    type: "common",
  },
  {
    label: "SAINT-BENOIT",
    value: "86214",
    type: "common",
  },
  {
    label: "POITIERS",
    value: "86194",
    type: "common",
  },
  {
    label: "VOUNEUIL-SOUS-BIARD",
    value: "86297",
    type: "common",
  },
  {
    label: "BIARD",
    value: "86027",
    type: "common",
  },
  {
    label: "QUINCAY",
    value: "86204",
    type: "common",
  },
  {
    label: "BERUGES",
    value: "86024",
    type: "common",
  },
  {
    label: "LES FORGES",
    value: "79124",
    type: "common",
  },
  {
    label: "VASLES",
    value: "79339",
    type: "common",
  },
  {
    label: "VAUSSEROUX",
    value: "79340",
    type: "common",
  },
  {
    label: "REFFANNES",
    value: "79225",
    type: "common",
  },
  {
    label: "VOUHE",
    value: "79354",
    type: "common",
  },
  {
    label: "BEAULIEU-SOUS-PARTHENAY",
    value: "79029",
    type: "common",
  },
  {
    label: "LE RETAIL",
    value: "79226",
    type: "common",
  },
  {
    label: "LES GROSEILLERS",
    value: "79139",
    type: "common",
  },
  {
    label: "LA BOISSIERE-EN-GATINE",
    value: "79040",
    type: "common",
  },
  {
    label: "ALLONNE",
    value: "79007",
    type: "common",
  },
  {
    label: "FENIOUX",
    value: "79119",
    type: "common",
  },
  {
    label: "FAYMOREAU",
    value: "85087",
    type: "common",
  },
  {
    label: "SAINT-LAURS",
    value: "79263",
    type: "common",
  },
  {
    label: "PUY-DE-SERRE",
    value: "85184",
    type: "common",
  },
  {
    label: "MARILLET",
    value: "85136",
    type: "common",
  },
  {
    label: "FOUSSAIS-PAYRE",
    value: "85094",
    type: "common",
  },
  {
    label: "VOUVANT",
    value: "85305",
    type: "common",
  },
  {
    label: "MERVENT",
    value: "85143",
    type: "common",
  },
  {
    label: "MARSAIS-SAINTE-RADEGONDE",
    value: "85137",
    type: "common",
  },
  {
    label: "BOURNEAU",
    value: "85033",
    type: "common",
  },
  {
    label: "SAINT-VALERIEN",
    value: "85274",
    type: "common",
  },
  {
    label: "SAINT-MARTIN-DES-FONTAINES",
    value: "85245",
    type: "common",
  },
  {
    label: "L'HERMENAULT",
    value: "85110",
    type: "common",
  },
  {
    label: "LA CHAPELLE-THEMER",
    value: "85056",
    type: "common",
  },
  {
    label: "THIRE",
    value: "85290",
    type: "common",
  },
  {
    label: "SAINT-ETIENNE-DE-BRILLOUET",
    value: "85209",
    type: "common",
  },
  {
    label: "SAINTE-PEXINE",
    value: "85261",
    type: "common",
  },
  {
    label: "SAINT-JEAN-DE-BEUGNE",
    value: "85233",
    type: "common",
  },
  {
    label: "SAINTE-HERMINE",
    value: "85223",
    type: "common",
  },
  {
    label: "MOUTIERS-SUR-LE-LAY",
    value: "85157",
    type: "common",
  },
  {
    label: "MAREUIL-SUR-LAY-DISSAIS",
    value: "85135",
    type: "common",
  },
  {
    label: "BESSAY",
    value: "85023",
    type: "common",
  },
  {
    label: "ROSNAY",
    value: "85193",
    type: "common",
  },
  {
    label: "LA COUTURE",
    value: "85074",
    type: "common",
  },
  {
    label: "LE TABLIER",
    value: "85285",
    type: "common",
  },
  {
    label: "SAINT-VINCENT-SUR-GRAON",
    value: "85277",
    type: "common",
  },
  {
    label: "LE CHAMP-SAINT-PERE",
    value: "85050",
    type: "common",
  },
  {
    label: "SAINT-AVAUGOURD-DES-LANDES",
    value: "85200",
    type: "common",
  },
  {
    label: "POIROUX",
    value: "85179",
    type: "common",
  },
  {
    label: "GROSBREUIL",
    value: "85103",
    type: "common",
  },
  {
    label: "SAINTE-FOY",
    value: "85214",
    type: "common",
  },
  {
    label: "LES ROUSSES",
    value: "39470",
    type: "common",
  },
  {
    label: "PREMANON",
    value: "39441",
    type: "common",
  },
  {
    label: "LONGCHAUMOIS",
    value: "39297",
    type: "common",
  },
  {
    label: "LA RIXOUSE",
    value: "39460",
    type: "common",
  },
  {
    label: "LESCHERES",
    value: "39293",
    type: "common",
  },
  {
    label: "ETIVAL",
    value: "39216",
    type: "common",
  },
  {
    label: "LES CROZETS",
    value: "39184",
    type: "common",
  },
  {
    label: "NANCHEZ",
    value: "39130",
    type: "common",
  },
  {
    label: "MEUSSIA",
    value: "39328",
    type: "common",
  },
  {
    label: "MAISOD",
    value: "39307",
    type: "common",
  },
  {
    label: "CRENANS",
    value: "39179",
    type: "common",
  },
  {
    label: "COYRON",
    value: "39175",
    type: "common",
  },
  {
    label: "CHARCHILLA",
    value: "39106",
    type: "common",
  },
  {
    label: "SARROGNA",
    value: "39504",
    type: "common",
  },
  {
    label: "ONOZ",
    value: "39394",
    type: "common",
  },
  {
    label: "ECRILLE",
    value: "39207",
    type: "common",
  },
  {
    label: "ROTHONAY",
    value: "39468",
    type: "common",
  },
  {
    label: "NANCUISE",
    value: "39380",
    type: "common",
  },
  {
    label: "CHAVERIA",
    value: "39134",
    type: "common",
  },
  {
    label: "CHAMBERIA",
    value: "39092",
    type: "common",
  },
  {
    label: "PIMORIN",
    value: "39420",
    type: "common",
  },
  {
    label: "MONNETAY",
    value: "39343",
    type: "common",
  },
  {
    label: "LOISIA",
    value: "39295",
    type: "common",
  },
  {
    label: "GRAYE-ET-CHARNAY",
    value: "39261",
    type: "common",
  },
  {
    label: "CUISEAUX",
    value: "71157",
    type: "common",
  },
  {
    label: "CHAMPAGNAT",
    value: "71079",
    type: "common",
  },
  {
    label: "VERIA",
    value: "39551",
    type: "common",
  },
  {
    label: "DIGNA",
    value: "39197",
    type: "common",
  },
  {
    label: "CHEVREAUX",
    value: "39142",
    type: "common",
  },
  {
    label: "JOUDES",
    value: "71243",
    type: "common",
  },
  {
    label: "DOMMARTIN-LES-CUISEAUX",
    value: "71177",
    type: "common",
  },
  {
    label: "VARENNES-SAINT-SAUVEUR",
    value: "71558",
    type: "common",
  },
  {
    label: "ROMENAY",
    value: "71373",
    type: "common",
  },
  {
    label: "VERNOUX",
    value: "01433",
    type: "common",
  },
  {
    label: "CURCIAT-DONGALON",
    value: "01139",
    type: "common",
  },
  {
    label: "VESCOURS",
    value: "01437",
    type: "common",
  },
  {
    label: "LA TRUCHERE",
    value: "71549",
    type: "common",
  },
  {
    label: "RATENELLE",
    value: "71366",
    type: "common",
  },
  {
    label: "SERMOYER",
    value: "01402",
    type: "common",
  },
  {
    label: "PLOTTES",
    value: "71353",
    type: "common",
  },
  {
    label: "LE VILLARS",
    value: "71576",
    type: "common",
  },
  {
    label: "UCHIZY",
    value: "71550",
    type: "common",
  },
  {
    label: "FARGES-LES-MACON",
    value: "71195",
    type: "common",
  },
  {
    label: "CHARDONNAY",
    value: "71100",
    type: "common",
  },
  {
    label: "MARTAILLY-LES-BRANCION",
    value: "71284",
    type: "common",
  },
  {
    label: "LUGNY",
    value: "71267",
    type: "common",
  },
  {
    label: "GREVILLY",
    value: "71226",
    type: "common",
  },
  {
    label: "CRUZILLE",
    value: "71156",
    type: "common",
  },
  {
    label: "BISSY-LA-MACONNAISE",
    value: "71035",
    type: "common",
  },
  {
    label: "TAIZE",
    value: "71532",
    type: "common",
  },
  {
    label: "MASSILLY",
    value: "71287",
    type: "common",
  },
  {
    label: "CORMATIN",
    value: "71145",
    type: "common",
  },
  {
    label: "CHISSEY-LES-MACON",
    value: "71130",
    type: "common",
  },
  {
    label: "BRAY",
    value: "71057",
    type: "common",
  },
  {
    label: "BLANOT",
    value: "71039",
    type: "common",
  },
  {
    label: "SALORNAY-SUR-GUYE",
    value: "71495",
    type: "common",
  },
  {
    label: "FLAGY",
    value: "71199",
    type: "common",
  },
  {
    label: "CORTEVAIX",
    value: "71147",
    type: "common",
  },
  {
    label: "AMEUGNY",
    value: "71007",
    type: "common",
  },
  {
    label: "SAINT-MARTIN-DE-SALENCEY",
    value: "71452",
    type: "common",
  },
  {
    label: "SAINT-ANDRE-LE-DESERT",
    value: "71387",
    type: "common",
  },
  {
    label: "SAILLY",
    value: "71381",
    type: "common",
  },
  {
    label: "CHEVAGNY-SUR-GUYE",
    value: "71127",
    type: "common",
  },
  {
    label: "CHERIZET",
    value: "71125",
    type: "common",
  },
  {
    label: "LA GUICHE",
    value: "71231",
    type: "common",
  },
  {
    label: "MORNAY",
    value: "71323",
    type: "common",
  },
  {
    label: "MARTIGNY-LE-COMTE",
    value: "71285",
    type: "common",
  },
  {
    label: "BALLORE",
    value: "71017",
    type: "common",
  },
  {
    label: "SAINT-BONNET-DE-VIEILLE-VIGNE",
    value: "71395",
    type: "common",
  },
  {
    label: "GRANDVAUX",
    value: "71224",
    type: "common",
  },
  {
    label: "BARON",
    value: "71021",
    type: "common",
  },
  {
    label: "SAINT-AUBIN-EN-CHAROLLAIS",
    value: "71388",
    type: "common",
  },
  {
    label: "SAINT-VINCENT-BRAGNY",
    value: "71490",
    type: "common",
  },
  {
    label: "RIGNY-SUR-ARROUX",
    value: "71370",
    type: "common",
  },
  {
    label: "LA MOTTE-SAINT-JEAN",
    value: "71325",
    type: "common",
  },
  {
    label: "LES GUERREAUX",
    value: "71229",
    type: "common",
  },
  {
    label: "SAINT-AGNAN",
    value: "71382",
    type: "common",
  },
  {
    label: "PERRIGNY-SUR-LOIRE",
    value: "71348",
    type: "common",
  },
  {
    label: "GILLY-SUR-LOIRE",
    value: "71220",
    type: "common",
  },
  {
    label: "PIERREFITTE-SUR-LOIRE",
    value: "03207",
    type: "common",
  },
  {
    label: "DIOU",
    value: "03100",
    type: "common",
  },
  {
    label: "DOMPIERRE-SUR-BESBRE",
    value: "03102",
    type: "common",
  },
  {
    label: "THIEL-SUR-ACOLIN",
    value: "03283",
    type: "common",
  },
  {
    label: "MONTBEUGNY",
    value: "03180",
    type: "common",
  },
  {
    label: "TOULON-SUR-ALLIER",
    value: "03286",
    type: "common",
  },
  {
    label: "COULANDON",
    value: "03085",
    type: "common",
  },
  {
    label: "BRESSOLLES",
    value: "03040",
    type: "common",
  },
  {
    label: "SOUVIGNY",
    value: "03275",
    type: "common",
  },
  {
    label: "MEILLERS",
    value: "03170",
    type: "common",
  },
  {
    label: "GIPCY",
    value: "03122",
    type: "common",
  },
  {
    label: "AUTRY-ISSARDS",
    value: "03012",
    type: "common",
  },
  {
    label: "SAINT-AUBIN-LE-MONIAL",
    value: "03218",
    type: "common",
  },
  {
    label: "YGRANDE",
    value: "03320",
    type: "common",
  },
  {
    label: "VIEURE",
    value: "03312",
    type: "common",
  },
  {
    label: "LOUROUX-BOURBONNAIS",
    value: "03150",
    type: "common",
  },
  {
    label: "SAINT-CAPRAIS",
    value: "03222",
    type: "common",
  },
  {
    label: "HERISSON",
    value: "03127",
    type: "common",
  },
  {
    label: "SAINT-VITTE",
    value: "18238",
    type: "common",
  },
  {
    label: "VALLON-EN-SULLY",
    value: "03297",
    type: "common",
  },
  {
    label: "SAINT-DESIRE",
    value: "03225",
    type: "common",
  },
  {
    label: "VESDUN",
    value: "18278",
    type: "common",
  },
  {
    label: "SIDIAILLES",
    value: "18252",
    type: "common",
  },
  {
    label: "CULAN",
    value: "18083",
    type: "common",
  },
  {
    label: "SAINT-SATURNIN",
    value: "18234",
    type: "common",
  },
  {
    label: "CHATEAUMEILLANT",
    value: "18057",
    type: "common",
  },
  {
    label: "URCIERS",
    value: "36227",
    type: "common",
  },
  {
    label: "CHAMPILLET",
    value: "36038",
    type: "common",
  },
  {
    label: "LA MOTTE-FEUILLY",
    value: "36132",
    type: "common",
  },
  {
    label: "FEUSINES",
    value: "36073",
    type: "common",
  },
  {
    label: "BRIANTES",
    value: "36025",
    type: "common",
  },
  {
    label: "POULIGNY-SAINT-MARTIN",
    value: "36164",
    type: "common",
  },
  {
    label: "LE MAGNY",
    value: "36109",
    type: "common",
  },
  {
    label: "SAINT-DENIS-DE-JOUHET",
    value: "36189",
    type: "common",
  },
  {
    label: "FOUGEROLLES",
    value: "36078",
    type: "common",
  },
  {
    label: "CHASSIGNOLLES",
    value: "36043",
    type: "common",
  },
  {
    label: "CLUIS",
    value: "36056",
    type: "common",
  },
  {
    label: "POMMIERS",
    value: "36160",
    type: "common",
  },
  {
    label: "BADECON-LE-PIN",
    value: "36158",
    type: "common",
  },
  {
    label: "GARGILESSE-DAMPIERRE",
    value: "36081",
    type: "common",
  },
  {
    label: "CHAVIN",
    value: "36048",
    type: "common",
  },
  {
    label: "LE MENOUX",
    value: "36117",
    type: "common",
  },
  {
    label: "CELON",
    value: "36033",
    type: "common",
  },
  {
    label: "CEAULMONT",
    value: "36032",
    type: "common",
  },
  {
    label: "VIGOUX",
    value: "36239",
    type: "common",
  },
  {
    label: "CHAZELET",
    value: "36049",
    type: "common",
  },
  {
    label: "SACIERGES-SAINT-MARTIN",
    value: "36177",
    type: "common",
  },
  {
    label: "LUZERET",
    value: "36106",
    type: "common",
  },
  {
    label: "PRISSAC",
    value: "36168",
    type: "common",
  },
  {
    label: "CHALAIS",
    value: "36036",
    type: "common",
  },
  {
    label: "LIGLET",
    value: "86132",
    type: "common",
  },
  {
    label: "BELABRE",
    value: "36016",
    type: "common",
  },
  {
    label: "VILLEMORT",
    value: "86291",
    type: "common",
  },
  {
    label: "HAIMS",
    value: "86110",
    type: "common",
  },
  {
    label: "BETHINES",
    value: "86025",
    type: "common",
  },
  {
    label: "ANTIGNY",
    value: "86006",
    type: "common",
  },
  {
    label: "LEIGNES-SUR-FONTAINE",
    value: "86126",
    type: "common",
  },
  {
    label: "VALDIVIENNE",
    value: "86233",
    type: "common",
  },
  {
    label: "POUILLE",
    value: "86198",
    type: "common",
  },
  {
    label: "TERCE",
    value: "86268",
    type: "common",
  },
  {
    label: "FLEURE",
    value: "86099",
    type: "common",
  },
  {
    label: "SAVIGNY-LEVESCAULT",
    value: "86256",
    type: "common",
  },
  {
    label: "NOUAILLE-MAUPERTUIS",
    value: "86180",
    type: "common",
  },
  {
    label: "SMARVES",
    value: "86263",
    type: "common",
  },
  {
    label: "LIGUGE",
    value: "86133",
    type: "common",
  },
  {
    label: "FONTAINE-LE-COMTE",
    value: "86100",
    type: "common",
  },
  {
    label: "CROUTELLE",
    value: "86088",
    type: "common",
  },
  {
    label: "COULOMBIERS",
    value: "86083",
    type: "common",
  },
  {
    label: "SANXAY",
    value: "86253",
    type: "common",
  },
  {
    label: "CURZAY-SUR-VONNE",
    value: "86091",
    type: "common",
  },
  {
    label: "MENIGOUTE",
    value: "79176",
    type: "common",
  },
  {
    label: "VAUTEBIS",
    value: "79341",
    type: "common",
  },
  {
    label: "VERRUYES",
    value: "79345",
    type: "common",
  },
  {
    label: "SAINT-LIN",
    value: "79267",
    type: "common",
  },
  {
    label: "SAINT-GEORGES-DE-NOISNE",
    value: "79253",
    type: "common",
  },
  {
    label: "CLAVE",
    value: "79092",
    type: "common",
  },
  {
    label: "SAINT-MARC-LA-LANDE",
    value: "79271",
    type: "common",
  },
  {
    label: "MAZIERES-EN-GATINE",
    value: "79172",
    type: "common",
  },
  {
    label: "SURIN",
    value: "79320",
    type: "common",
  },
  {
    label: "PAMPLIE",
    value: "79200",
    type: "common",
  },
  {
    label: "COURS",
    value: "79104",
    type: "common",
  },
  {
    label: "CHAMPDENIERS",
    value: "79066",
    type: "common",
  },
  {
    label: "XAINTRAY",
    value: "79357",
    type: "common",
  },
  {
    label: "BECELEUF",
    value: "79032",
    type: "common",
  },
  {
    label: "SAINT-MAIXENT-DE-BEUGNE",
    value: "79269",
    type: "common",
  },
  {
    label: "PUIHARDY",
    value: "79223",
    type: "common",
  },
  {
    label: "COULONGES-SUR-L'AUTIZE",
    value: "79101",
    type: "common",
  },
  {
    label: "ARDIN",
    value: "79012",
    type: "common",
  },
  {
    label: "SAINT-HILAIRE-DES-LOGES",
    value: "85227",
    type: "common",
  },
  {
    label: "SAINT-MICHEL-LE-CLOUCQ",
    value: "85256",
    type: "common",
  },
  {
    label: "L'ORBRIE",
    value: "85167",
    type: "common",
  },
  {
    label: "FONTENAY-LE-COMTE",
    value: "85092",
    type: "common",
  },
  {
    label: "SERIGNE",
    value: "85281",
    type: "common",
  },
  {
    label: "PISSOTTE",
    value: "85176",
    type: "common",
  },
  {
    label: "LONGEVES",
    value: "85126",
    type: "common",
  },
  {
    label: "POUILLE",
    value: "85181",
    type: "common",
  },
  {
    label: "PETOSSE",
    value: "85174",
    type: "common",
  },
  {
    label: "NALLIERS",
    value: "85159",
    type: "common",
  },
  {
    label: "MOUZEUIL-SAINT-MARTIN",
    value: "85158",
    type: "common",
  },
  {
    label: "SAINTE-GEMME-LA-PLAINE",
    value: "85216",
    type: "common",
  },
  {
    label: "SAINT-AUBIN-LA-PLAINE",
    value: "85199",
    type: "common",
  },
  {
    label: "LES MAGNILS-REIGNIERS",
    value: "85131",
    type: "common",
  },
  {
    label: "LUCON",
    value: "85128",
    type: "common",
  },
  {
    label: "CORPE",
    value: "85073",
    type: "common",
  },
  {
    label: "LA BRETONNIERE-LA-CLAYE",
    value: "85036",
    type: "common",
  },
  {
    label: "PEAULT",
    value: "85171",
    type: "common",
  },
  {
    label: "LAIROUX",
    value: "85117",
    type: "common",
  },
  {
    label: "CURZON",
    value: "85077",
    type: "common",
  },
  {
    label: "SAINT-CYR-EN-TALMONDAIS",
    value: "85206",
    type: "common",
  },
  {
    label: "MOUTIERS-LES-MAUXFAITS",
    value: "85156",
    type: "common",
  },
  {
    label: "LE GIVRE",
    value: "85101",
    type: "common",
  },
  {
    label: "LE BERNARD",
    value: "85022",
    type: "common",
  },
  {
    label: "SAINT-HILAIRE-LA-FORET",
    value: "85231",
    type: "common",
  },
  {
    label: "AVRILLE",
    value: "85010",
    type: "common",
  },
  {
    label: "TALMONT-SAINT-HILAIRE",
    value: "85288",
    type: "common",
  },
  {
    label: "THOLLON-LES-MEMISES",
    value: "74279",
    type: "common",
  },
  {
    label: "MEILLERIE",
    value: "74175",
    type: "common",
  },
  {
    label: "SAINT-GINGOLPH",
    value: "74237",
    type: "common",
  },
  {
    label: "NOVEL",
    value: "74203",
    type: "common",
  },
  {
    label: "SAINT-PAUL-EN-CHABLAIS",
    value: "74249",
    type: "common",
  },
  {
    label: "MAXILLY-SUR-LEMAN",
    value: "74172",
    type: "common",
  },
  {
    label: "LUGRIN",
    value: "74154",
    type: "common",
  },
  {
    label: "EVIAN-LES-BAINS",
    value: "74119",
    type: "common",
  },
  {
    label: "PUBLIER",
    value: "74218",
    type: "common",
  },
  {
    label: "NEUVECELLE",
    value: "74200",
    type: "common",
  },
  {
    label: "LARRINGES",
    value: "74146",
    type: "common",
  },
  {
    label: "CHAMPANGES",
    value: "74057",
    type: "common",
  },
  {
    label: "THONON-LES-BAINS",
    value: "74281",
    type: "common",
  },
  {
    label: "MARIN",
    value: "74166",
    type: "common",
  },
  {
    label: "LAMOURA",
    value: "39275",
    type: "common",
  },
  {
    label: "LAJOUX",
    value: "39274",
    type: "common",
  },
  {
    label: "SAINT-CLAUDE",
    value: "39478",
    type: "common",
  },
  {
    label: "COTEAUX DU LIZON",
    value: "39491",
    type: "common",
  },
  {
    label: "RAVILLOLES",
    value: "39453",
    type: "common",
  },
  {
    label: "AVIGNON-LES-SAINT-CLAUDE",
    value: "39032",
    type: "common",
  },
  {
    label: "VILLARDS-D'HERIA",
    value: "39561",
    type: "common",
  },
  {
    label: "MOIRANS-EN-MONTAGNE",
    value: "39333",
    type: "common",
  },
  {
    label: "VALZIN EN PETITE MONTAGNE",
    value: "39290",
    type: "common",
  },
  {
    label: "CERNON",
    value: "39086",
    type: "common",
  },
  {
    label: "MARIGNA-SUR-VALOUSE",
    value: "39312",
    type: "common",
  },
  {
    label: "DRAMELAY",
    value: "39204",
    type: "common",
  },
  {
    label: "LA BOISSIERE",
    value: "39062",
    type: "common",
  },
  {
    label: "ARINTHOD",
    value: "39016",
    type: "common",
  },
  {
    label: "MONTREVEL",
    value: "39363",
    type: "common",
  },
  {
    label: "GIGNY",
    value: "39253",
    type: "common",
  },
  {
    label: "THOISSIA",
    value: "39532",
    type: "common",
  },
  {
    label: "LES TROIS-CHATEAUX",
    value: "39378",
    type: "common",
  },
  {
    label: "MONTAGNA-LE-RECONDUIT",
    value: "39346",
    type: "common",
  },
  {
    label: "BALANOD",
    value: "39035",
    type: "common",
  },
  {
    label: "ANDELOT-MORVAL",
    value: "39010",
    type: "common",
  },
  {
    label: "DOMSURE",
    value: "01147",
    type: "common",
  },
  {
    label: "CONDAL",
    value: "71143",
    type: "common",
  },
  {
    label: "SAINT-AMOUR",
    value: "39475",
    type: "common",
  },
  {
    label: "CORMOZ",
    value: "01124",
    type: "common",
  },
  {
    label: "BEAUPONT",
    value: "01029",
    type: "common",
  },
  {
    label: "SAINT-NIZIER-LE-BOUCHOUX",
    value: "01380",
    type: "common",
  },
  {
    label: "MANTENAY-MONTLIN",
    value: "01230",
    type: "common",
  },
  {
    label: "LESCHEROUX",
    value: "01212",
    type: "common",
  },
  {
    label: "COURTES",
    value: "01128",
    type: "common",
  },
  {
    label: "SERVIGNAT",
    value: "01406",
    type: "common",
  },
  {
    label: "SAINT-TRIVIER-DE-COURTES",
    value: "01388",
    type: "common",
  },
  {
    label: "CHAVANNES-SUR-REYSSOUZE",
    value: "01094",
    type: "common",
  },
  {
    label: "SAINT-BENIGNE",
    value: "01337",
    type: "common",
  },
  {
    label: "PONT-DE-VAUX",
    value: "01305",
    type: "common",
  },
  {
    label: "GORREVOD",
    value: "01175",
    type: "common",
  },
  {
    label: "ARBIGNY",
    value: "01016",
    type: "common",
  },
  {
    label: "REYSSOUZE",
    value: "01323",
    type: "common",
  },
  {
    label: "FLEURVILLE",
    value: "71591",
    type: "common",
  },
  {
    label: "VIRE",
    value: "71584",
    type: "common",
  },
  {
    label: "SAINT-ALBAIN",
    value: "71383",
    type: "common",
  },
  {
    label: "MONTBELLET",
    value: "71305",
    type: "common",
  },
  {
    label: "SAINT-GENGOUX-DE-SCISSE",
    value: "71416",
    type: "common",
  },
  {
    label: "PERONNE",
    value: "71345",
    type: "common",
  },
  {
    label: "CLESSE",
    value: "71135",
    type: "common",
  },
  {
    label: "BURGY",
    value: "71066",
    type: "common",
  },
  {
    label: "AZE",
    value: "71016",
    type: "common",
  },
  {
    label: "DONZY-LE-PERTUIS",
    value: "71181",
    type: "common",
  },
  {
    label: "CORTAMBERT",
    value: "71146",
    type: "common",
  },
  {
    label: "CLUNY",
    value: "71137",
    type: "common",
  },
  {
    label: "LA VINEUSE SUR FREGANDE",
    value: "71582",
    type: "common",
  },
  {
    label: "LOURNAND",
    value: "71264",
    type: "common",
  },
  {
    label: "CHATEAU",
    value: "71112",
    type: "common",
  },
  {
    label: "SIVIGNON",
    value: "71524",
    type: "common",
  },
  {
    label: "SAINT-VINCENT-DES-PRES",
    value: "71488",
    type: "common",
  },
  {
    label: "PRESSY-SOUS-DONDIN",
    value: "71358",
    type: "common",
  },
  {
    label: "CHIDDES",
    value: "71128",
    type: "common",
  },
  {
    label: "BUFFIERES",
    value: "71065",
    type: "common",
  },
  {
    label: "SUIN",
    value: "71529",
    type: "common",
  },
  {
    label: "SAINT-BONNET-DE-JOUX",
    value: "71394",
    type: "common",
  },
  {
    label: "VIRY",
    value: "71586",
    type: "common",
  },
  {
    label: "VENDENESSE-LES-CHAROLLES",
    value: "71564",
    type: "common",
  },
  {
    label: "FONTENAY",
    value: "71203",
    type: "common",
  },
  {
    label: "CHAROLLES",
    value: "71106",
    type: "common",
  },
  {
    label: "VOLESVRES",
    value: "71590",
    type: "common",
  },
  {
    label: "HAUTEFOND",
    value: "71232",
    type: "common",
  },
  {
    label: "CHAMPLECY",
    value: "71082",
    type: "common",
  },
  {
    label: "SAINT-LEGER-LES-PARAY",
    value: "71439",
    type: "common",
  },
  {
    label: "PARAY-LE-MONIAL",
    value: "71342",
    type: "common",
  },
  {
    label: "VITRY-EN-CHAROLLAIS",
    value: "71588",
    type: "common",
  },
  {
    label: "VARENNE-SAINT-GERMAIN",
    value: "71557",
    type: "common",
  },
  {
    label: "DIGOIN",
    value: "71176",
    type: "common",
  },
  {
    label: "MOLINET",
    value: "03173",
    type: "common",
  },
  {
    label: "CHASSENARD",
    value: "03063",
    type: "common",
  },
  {
    label: "MONETAY-SUR-LOIRE",
    value: "03177",
    type: "common",
  },
  {
    label: "COULANGES",
    value: "03086",
    type: "common",
  },
  {
    label: "SALIGNY-SUR-ROUDON",
    value: "03265",
    type: "common",
  },
  {
    label: "SAINT-POURCAIN-SUR-BESBRE",
    value: "03253",
    type: "common",
  },
  {
    label: "VAUMAS",
    value: "03300",
    type: "common",
  },
  {
    label: "MERCY",
    value: "03171",
    type: "common",
  },
  {
    label: "CHAPEAU",
    value: "03054",
    type: "common",
  },
  {
    label: "NEUILLY-LE-REAL",
    value: "03197",
    type: "common",
  },
  {
    label: "CHEMILLY",
    value: "03073",
    type: "common",
  },
  {
    label: "BESSAY-SUR-ALLIER",
    value: "03025",
    type: "common",
  },
  {
    label: "BESSON",
    value: "03026",
    type: "common",
  },
  {
    label: "CHATILLON",
    value: "03069",
    type: "common",
  },
  {
    label: "NOYANT-D'ALLIER",
    value: "03202",
    type: "common",
  },
  {
    label: "SAINT-HILAIRE",
    value: "03238",
    type: "common",
  },
  {
    label: "ROCLES",
    value: "03214",
    type: "common",
  },
  {
    label: "TORTEZAIS",
    value: "03285",
    type: "common",
  },
  {
    label: "BUXIERES-LES-MINES",
    value: "03046",
    type: "common",
  },
  {
    label: "COSNE-D'ALLIER",
    value: "03084",
    type: "common",
  },
  {
    label: "VENAS",
    value: "03303",
    type: "common",
  },
  {
    label: "REUGNY",
    value: "03213",
    type: "common",
  },
  {
    label: "HAUT-BOCAGE",
    value: "03158",
    type: "common",
  },
  {
    label: "NASSIGNY",
    value: "03193",
    type: "common",
  },
  {
    label: "AUDES",
    value: "03010",
    type: "common",
  },
  {
    label: "COURCAIS",
    value: "03088",
    type: "common",
  },
  {
    label: "CHAZEMAIS",
    value: "03072",
    type: "common",
  },
  {
    label: "VIPLAIX",
    value: "03317",
    type: "common",
  },
  {
    label: "SAINT-ELOY-D'ALLIER",
    value: "03228",
    type: "common",
  },
  {
    label: "SAINT-PALAIS",
    value: "03249",
    type: "common",
  },
  {
    label: "PREVERANGES",
    value: "18187",
    type: "common",
  },
  {
    label: "PERASSAY",
    value: "36156",
    type: "common",
  },
  {
    label: "LIGNEROLLES",
    value: "36095",
    type: "common",
  },
  {
    label: "SAINT-PRIEST-LA-MARCHE",
    value: "18232",
    type: "common",
  },
  {
    label: "SAZERAY",
    value: "36214",
    type: "common",
  },
  {
    label: "SAINTE-SEVERE-SUR-INDRE",
    value: "36208",
    type: "common",
  },
  {
    label: "POULIGNY-NOTRE-DAME",
    value: "36163",
    type: "common",
  },
  {
    label: "CREVANT",
    value: "36060",
    type: "common",
  },
  {
    label: "CROZON-SUR-VAUVRE",
    value: "36061",
    type: "common",
  },
  {
    label: "LA BUXERETTE",
    value: "36028",
    type: "common",
  },
  {
    label: "AIGURANDE",
    value: "36001",
    type: "common",
  },
  {
    label: "ORSENNES",
    value: "36146",
    type: "common",
  },
  {
    label: "MONTCHEVRIER",
    value: "36126",
    type: "common",
  },
  {
    label: "CUZION",
    value: "36062",
    type: "common",
  },
  {
    label: "BAZAIGES",
    value: "36014",
    type: "common",
  },
  {
    label: "BARAIZE",
    value: "36012",
    type: "common",
  },
  {
    label: "SAINT-GILLES",
    value: "36196",
    type: "common",
  },
  {
    label: "PARNAC",
    value: "36150",
    type: "common",
  },
  {
    label: "SAINT-CIVRAN",
    value: "36187",
    type: "common",
  },
  {
    label: "SAINT-BENOIT-DU-SAULT",
    value: "36182",
    type: "common",
  },
  {
    label: "ROUSSINES",
    value: "36174",
    type: "common",
  },
  {
    label: "DUNET",
    value: "36067",
    type: "common",
  },
  {
    label: "LIGNAC",
    value: "36094",
    type: "common",
  },
  {
    label: "THOLLET",
    value: "86270",
    type: "common",
  },
  {
    label: "LA TRIMOUILLE",
    value: "86273",
    type: "common",
  },
  {
    label: "JOURNET",
    value: "86118",
    type: "common",
  },
  {
    label: "JOUHET",
    value: "86117",
    type: "common",
  },
  {
    label: "PINDRAY",
    value: "86191",
    type: "common",
  },
  {
    label: "CHAPELLE-VIVIERS",
    value: "86059",
    type: "common",
  },
  {
    label: "CIVAUX",
    value: "86077",
    type: "common",
  },
  {
    label: "LHOMMAIZE",
    value: "86131",
    type: "common",
  },
  {
    label: "VERNON",
    value: "86284",
    type: "common",
  },
  {
    label: "DIENNE",
    value: "86094",
    type: "common",
  },
  {
    label: "NIEUIL-L'ESPOIR",
    value: "86178",
    type: "common",
  },
  {
    label: "GIZAY",
    value: "86105",
    type: "common",
  },
  {
    label: "LA VILLEDIEU-DU-CLAIN",
    value: "86290",
    type: "common",
  },
  {
    label: "ROCHES-PREMARIE-ANDILLE",
    value: "86209",
    type: "common",
  },
  {
    label: "ASLONNES",
    value: "86010",
    type: "common",
  },
  {
    label: "ITEUIL",
    value: "86113",
    type: "common",
  },
  {
    label: "MARIGNY-CHEMEREAU",
    value: "86147",
    type: "common",
  },
  {
    label: "MARCAY",
    value: "86145",
    type: "common",
  },
  {
    label: "CLOUE",
    value: "86080",
    type: "common",
  },
  {
    label: "LUSIGNAN",
    value: "86139",
    type: "common",
  },
  {
    label: "JAZENEUIL",
    value: "86116",
    type: "common",
  },
  {
    label: "ROUILLE",
    value: "86213",
    type: "common",
  },
  {
    label: "SOUDAN",
    value: "79316",
    type: "common",
  },
  {
    label: "SAINT-GERMIER",
    value: "79256",
    type: "common",
  },
  {
    label: "PAMPROUX",
    value: "79201",
    type: "common",
  },
  {
    label: "NANTEUIL",
    value: "79189",
    type: "common",
  },
  {
    label: "FOMPERRON",
    value: "79121",
    type: "common",
  },
  {
    label: "EXIREUIL",
    value: "79114",
    type: "common",
  },
  {
    label: "SAIVRES",
    value: "79302",
    type: "common",
  },
  {
    label: "SAINT-CHRISTOPHE-SUR-ROC",
    value: "79241",
    type: "common",
  },
  {
    label: "CHERVEUX",
    value: "79086",
    type: "common",
  },
  {
    label: "LA CHAPELLE-BATON",
    value: "79070",
    type: "common",
  },
  {
    label: "AUGE",
    value: "79020",
    type: "common",
  },
  {
    label: "SAINTE-OUENNE",
    value: "79284",
    type: "common",
  },
  {
    label: "GERMOND-ROUVRE",
    value: "79133",
    type: "common",
  },
  {
    label: "VILLIERS-EN-PLAINE",
    value: "79351",
    type: "common",
  },
  {
    label: "FAYE-SUR-ARDIN",
    value: "79117",
    type: "common",
  },
  {
    label: "SAINT-POMPAIN",
    value: "79290",
    type: "common",
  },
  {
    label: "XANTON-CHASSENON",
    value: "85306",
    type: "common",
  },
  {
    label: "SAINT-MARTIN-DE-FRAIGNEAU",
    value: "85244",
    type: "common",
  },
  {
    label: "MONTREUIL",
    value: "85148",
    type: "common",
  },
  {
    label: "AUCHAY-SUR-VENDEE",
    value: "85009",
    type: "common",
  },
  {
    label: "LE LANGON",
    value: "85121",
    type: "common",
  },
  {
    label: "MOREILLES",
    value: "85149",
    type: "common",
  },
  {
    label: "SAINT-DENIS-DU-PAYRE",
    value: "85207",
    type: "common",
  },
  {
    label: "CHASNAIS",
    value: "85058",
    type: "common",
  },
  {
    label: "SAINT-BENOIST-SUR-MER",
    value: "85201",
    type: "common",
  },
  {
    label: "LA JONCHERE",
    value: "85116",
    type: "common",
  },
  {
    label: "GRUES",
    value: "85104",
    type: "common",
  },
  {
    label: "LONGEVILLE-SUR-MER",
    value: "85127",
    type: "common",
  },
  {
    label: "ANGLES",
    value: "85004",
    type: "common",
  },
  {
    label: "SAINT-VINCENT-SUR-JARD",
    value: "85278",
    type: "common",
  },
  {
    label: "JARD-SUR-MER",
    value: "85114",
    type: "common",
  },
  {
    label: "LA CHAPELLE-D'ABONDANCE",
    value: "74058",
    type: "common",
  },
  {
    label: "VACHERESSE",
    value: "74286",
    type: "common",
  },
  {
    label: "BERNEX",
    value: "74033",
    type: "common",
  },
  {
    label: "LA FORCLAZ",
    value: "74129",
    type: "common",
  },
  {
    label: "CHEVENOZ",
    value: "74073",
    type: "common",
  },
  {
    label: "VINZIER",
    value: "74308",
    type: "common",
  },
  {
    label: "FETERNES",
    value: "74127",
    type: "common",
  },
  {
    label: "LA VERNAZ",
    value: "74295",
    type: "common",
  },
  {
    label: "REYVROZ",
    value: "74222",
    type: "common",
  },
  {
    label: "ORCIER",
    value: "74206",
    type: "common",
  },
  {
    label: "ALLINGES",
    value: "74005",
    type: "common",
  },
  {
    label: "LYAUD",
    value: "74157",
    type: "common",
  },
  {
    label: "ARMOY",
    value: "74020",
    type: "common",
  },
  {
    label: "SCIEZ",
    value: "74263",
    type: "common",
  },
  {
    label: "MARGENCEL",
    value: "74163",
    type: "common",
  },
  {
    label: "ANTHY-SUR-LEMAN",
    value: "74013",
    type: "common",
  },
  {
    label: "MESSERY",
    value: "74180",
    type: "common",
  },
  {
    label: "YVOIRE",
    value: "74315",
    type: "common",
  },
  {
    label: "NERNIER",
    value: "74199",
    type: "common",
  },
  {
    label: "EXCENEVEX",
    value: "74121",
    type: "common",
  },
  {
    label: "MASSONGY",
    value: "74171",
    type: "common",
  },
  {
    label: "GRILLY",
    value: "01180",
    type: "common",
  },
  {
    label: "DIVONNE-LES-BAINS",
    value: "01143",
    type: "common",
  },
  {
    label: "VESANCY",
    value: "01436",
    type: "common",
  },
  {
    label: "GEX",
    value: "01173",
    type: "common",
  },
  {
    label: "MIJOUX",
    value: "01247",
    type: "common",
  },
  {
    label: "VILLARD-SAINT-SAUVEUR",
    value: "39560",
    type: "common",
  },
  {
    label: "SEPTMONCEL LES MOLUNES",
    value: "39510",
    type: "common",
  },
  {
    label: "LAVANS-LES-SAINT-CLAUDE",
    value: "39286",
    type: "common",
  },
  {
    label: "LARRIVOIRE",
    value: "39280",
    type: "common",
  },
  {
    label: "COYRIERE",
    value: "39174",
    type: "common",
  },
  {
    label: "COISERETTE",
    value: "39157",
    type: "common",
  },
  {
    label: "VAUX-LES-SAINT-CLAUDE",
    value: "39547",
    type: "common",
  },
  {
    label: "MARTIGNA",
    value: "39318",
    type: "common",
  },
  {
    label: "LAVANCIA-EPERCY",
    value: "39283",
    type: "common",
  },
  {
    label: "JEURRE",
    value: "39269",
    type: "common",
  },
  {
    label: "VESCLES",
    value: "39557",
    type: "common",
  },
  {
    label: "MONTCUSEL",
    value: "39351",
    type: "common",
  },
  {
    label: "LECT",
    value: "39289",
    type: "common",
  },
  {
    label: "CHANCIA",
    value: "39102",
    type: "common",
  },
  {
    label: "GENOD",
    value: "39247",
    type: "common",
  },
  {
    label: "VAL SURAN",
    value: "39485",
    type: "common",
  },
  {
    label: "MONTLAINSIA",
    value: "39273",
    type: "common",
  },
  {
    label: "BROISSIA",
    value: "39080",
    type: "common",
  },
  {
    label: "VERJON",
    value: "01432",
    type: "common",
  },
  {
    label: "SALAVRE",
    value: "01391",
    type: "common",
  },
  {
    label: "VAL-D'EPY",
    value: "39209",
    type: "common",
  },
  {
    label: "PIRAJOUX",
    value: "01296",
    type: "common",
  },
  {
    label: "COLIGNY",
    value: "01108",
    type: "common",
  },
  {
    label: "MARBOZ",
    value: "01232",
    type: "common",
  },
  {
    label: "FOISSIAT",
    value: "01163",
    type: "common",
  },
  {
    label: "SAINT-JULIEN-SUR-REYSSOUZE",
    value: "01367",
    type: "common",
  },
  {
    label: "JAYAT",
    value: "01196",
    type: "common",
  },
  {
    label: "SAINT-JEAN-SUR-REYSSOUZE",
    value: "01364",
    type: "common",
  },
  {
    label: "BEREZIAT",
    value: "01040",
    type: "common",
  },
  {
    label: "SAINT-ETIENNE-SUR-REYSSOUZE",
    value: "01352",
    type: "common",
  },
  {
    label: "CHEVROUX",
    value: "01102",
    type: "common",
  },
  {
    label: "BOISSEY",
    value: "01050",
    type: "common",
  },
  {
    label: "VESINES",
    value: "01439",
    type: "common",
  },
  {
    label: "OZAN",
    value: "01284",
    type: "common",
  },
  {
    label: "MANZIAT",
    value: "01231",
    type: "common",
  },
  {
    label: "BOZ",
    value: "01057",
    type: "common",
  },
  {
    label: "ASNIERES-SUR-SAONE",
    value: "01023",
    type: "common",
  },
  {
    label: "SENOZAN",
    value: "71513",
    type: "common",
  },
  {
    label: "LA SALLE",
    value: "71494",
    type: "common",
  },
  {
    label: "SAINT-MARTIN-BELLE-ROCHE",
    value: "71448",
    type: "common",
  },
  {
    label: "MACON",
    value: "71270",
    type: "common",
  },
  {
    label: "CHARBONNIERES",
    value: "71099",
    type: "common",
  },
  {
    label: "VERZE",
    value: "71574",
    type: "common",
  },
  {
    label: "SAINT-MAURICE-DE-SATONNAY",
    value: "71460",
    type: "common",
  },
  {
    label: "LAIZE",
    value: "71250",
    type: "common",
  },
  {
    label: "SOLOGNY",
    value: "71525",
    type: "common",
  },
  {
    label: "IGE",
    value: "71236",
    type: "common",
  },
  {
    label: "BERZE-LA-VILLE",
    value: "71032",
    type: "common",
  },
  {
    label: "BERZE-LE-CHATEL",
    value: "71031",
    type: "common",
  },
  {
    label: "SAINTE-CECILE",
    value: "71397",
    type: "common",
  },
  {
    label: "MAZILLE",
    value: "71290",
    type: "common",
  },
  {
    label: "JALOGNY",
    value: "71240",
    type: "common",
  },
  {
    label: "BOURGVILAIN",
    value: "71050",
    type: "common",
  },
  {
    label: "CURTIL-SOUS-BUFFIERES",
    value: "71163",
    type: "common",
  },
  {
    label: "LA CHAPELLE-DU-MONT-DE-FRANCE",
    value: "71091",
    type: "common",
  },
  {
    label: "BERGESSERIN",
    value: "71030",
    type: "common",
  },
  {
    label: "VEROSVRES",
    value: "71571",
    type: "common",
  },
  {
    label: "TRIVY",
    value: "71547",
    type: "common",
  },
  {
    label: "OZOLLES",
    value: "71339",
    type: "common",
  },
  {
    label: "BEAUBERY",
    value: "71025",
    type: "common",
  },
  {
    label: "VAUDEBARRIER",
    value: "71562",
    type: "common",
  },
  {
    label: "MARCILLY-LA-GUEURCE",
    value: "71276",
    type: "common",
  },
  {
    label: "CHANGY",
    value: "71086",
    type: "common",
  },
  {
    label: "SAINT-JULIEN-DE-CIVRY",
    value: "71433",
    type: "common",
  },
  {
    label: "NOCHIZE",
    value: "71331",
    type: "common",
  },
  {
    label: "LUGNY-LES-CHAROLLES",
    value: "71268",
    type: "common",
  },
  {
    label: "POISSON",
    value: "71354",
    type: "common",
  },
  {
    label: "SAINT-YAN",
    value: "71491",
    type: "common",
  },
  {
    label: "L'HOPITAL-LE-MERCIER",
    value: "71233",
    type: "common",
  },
  {
    label: "SAINT-LEGER-SUR-VOUZANCE",
    value: "03239",
    type: "common",
  },
  {
    label: "SAINT-DIDIER-EN-DONJON",
    value: "03226",
    type: "common",
  },
  {
    label: "LE PIN",
    value: "03208",
    type: "common",
  },
  {
    label: "LIERNOLLES",
    value: "03144",
    type: "common",
  },
  {
    label: "SAINT-LEON",
    value: "03240",
    type: "common",
  },
  {
    label: "THIONNE",
    value: "03284",
    type: "common",
  },
  {
    label: "JALIGNY-SUR-BESBRE",
    value: "03132",
    type: "common",
  },
  {
    label: "CHATELPERRON",
    value: "03067",
    type: "common",
  },
  {
    label: "SAINT-VOIR",
    value: "03263",
    type: "common",
  },
  {
    label: "SAINT-GERAND-DE-VAUX",
    value: "03234",
    type: "common",
  },
  {
    label: "GOUISE",
    value: "03124",
    type: "common",
  },
  {
    label: "LA FERTE-HAUTERIVE",
    value: "03114",
    type: "common",
  },
  {
    label: "MONETAY-SUR-ALLIER",
    value: "03176",
    type: "common",
  },
  {
    label: "MEILLARD",
    value: "03169",
    type: "common",
  },
  {
    label: "CHATEL-DE-NEUVRE",
    value: "03065",
    type: "common",
  },
  {
    label: "BRESNAY",
    value: "03039",
    type: "common",
  },
  {
    label: "TREBAN",
    value: "03287",
    type: "common",
  },
  {
    label: "CRESSANGES",
    value: "03092",
    type: "common",
  },
  {
    label: "TRONGET",
    value: "03292",
    type: "common",
  },
  {
    label: "LE MONTET",
    value: "03183",
    type: "common",
  },
  {
    label: "SAINT-SORNIN",
    value: "03260",
    type: "common",
  },
  {
    label: "MURAT",
    value: "03191",
    type: "common",
  },
  {
    label: "CHAVENON",
    value: "03070",
    type: "common",
  },
  {
    label: "CHAPPES",
    value: "03058",
    type: "common",
  },
  {
    label: "VILLEFRANCHE-D'ALLIER",
    value: "03315",
    type: "common",
  },
  {
    label: "SAUVAGNY",
    value: "03269",
    type: "common",
  },
  {
    label: "BIZENEUILLE",
    value: "03031",
    type: "common",
  },
  {
    label: "VERNEIX",
    value: "03305",
    type: "common",
  },
  {
    label: "ESTIVAREILLES",
    value: "03111",
    type: "common",
  },
  {
    label: "VAUX",
    value: "03301",
    type: "common",
  },
  {
    label: "SAINT-VICTOR",
    value: "03262",
    type: "common",
  },
  {
    label: "LA CHAPELAUDE",
    value: "03055",
    type: "common",
  },
  {
    label: "MESPLES",
    value: "03172",
    type: "common",
  },
  {
    label: "CHAMBERAT",
    value: "03051",
    type: "common",
  },
  {
    label: "SAINT-SAUVIER",
    value: "03259",
    type: "common",
  },
  {
    label: "SAINT-PIERRE-LE-BOST",
    value: "23233",
    type: "common",
  },
  {
    label: "SAINT-MARIEN",
    value: "23213",
    type: "common",
  },
  {
    label: "VIJON",
    value: "36240",
    type: "common",
  },
  {
    label: "BUSSIERE-SAINT-GEORGES",
    value: "23038",
    type: "common",
  },
  {
    label: "VIGOULANT",
    value: "36238",
    type: "common",
  },
  {
    label: "TERCILLAT",
    value: "23252",
    type: "common",
  },
  {
    label: "NOUZIERS",
    value: "23148",
    type: "common",
  },
  {
    label: "MOUTIER-MALCARD",
    value: "23139",
    type: "common",
  },
  {
    label: "LA CELLETTE",
    value: "23041",
    type: "common",
  },
  {
    label: "MORTROUX",
    value: "23136",
    type: "common",
  },
  {
    label: "LA FORET-DU-TEMPLE",
    value: "23084",
    type: "common",
  },
  {
    label: "MEASNES",
    value: "23130",
    type: "common",
  },
  {
    label: "LOURDOUEIX-SAINT-PIERRE",
    value: "23112",
    type: "common",
  },
  {
    label: "LOURDOUEIX-SAINT-MICHEL",
    value: "36099",
    type: "common",
  },
  {
    label: "NOUZEROLLES",
    value: "23147",
    type: "common",
  },
  {
    label: "FRESSELINES",
    value: "23087",
    type: "common",
  },
  {
    label: "SAINT-PLANTAIRE",
    value: "36207",
    type: "common",
  },
  {
    label: "EGUZON-CHANTOME",
    value: "36070",
    type: "common",
  },
  {
    label: "SAINT-SEBASTIEN",
    value: "23239",
    type: "common",
  },
  {
    label: "MOUHET",
    value: "36134",
    type: "common",
  },
  {
    label: "LA CHATRE-LANGLIN",
    value: "36047",
    type: "common",
  },
  {
    label: "CHAILLAC",
    value: "36035",
    type: "common",
  },
  {
    label: "BEAULIEU",
    value: "36015",
    type: "common",
  },
  {
    label: "COULONGES",
    value: "86084",
    type: "common",
  },
  {
    label: "TILLY",
    value: "36223",
    type: "common",
  },
  {
    label: "BONNEUIL",
    value: "36020",
    type: "common",
  },
  {
    label: "BRIGUEIL-LE-CHANTRE",
    value: "86037",
    type: "common",
  },
  {
    label: "SAINT-LEOMER",
    value: "86230",
    type: "common",
  },
  {
    label: "MONTMORILLON",
    value: "86165",
    type: "common",
  },
  {
    label: "SILLARS",
    value: "86262",
    type: "common",
  },
  {
    label: "MAZEROLLES",
    value: "86153",
    type: "common",
  },
  {
    label: "LUSSAC-LES-CHATEAUX",
    value: "86140",
    type: "common",
  },
  {
    label: "VERRIERES",
    value: "86285",
    type: "common",
  },
  {
    label: "SAINT-LAURENT-DE-JOURDES",
    value: "86228",
    type: "common",
  },
  {
    label: "SAINT-MAURICE-LA-CLOUERE",
    value: "86235",
    type: "common",
  },
  {
    label: "GENCAY",
    value: "86103",
    type: "common",
  },
  {
    label: "MARNAY",
    value: "86148",
    type: "common",
  },
  {
    label: "VIVONNE",
    value: "86293",
    type: "common",
  },
  {
    label: "CHATEAU-LARCHER",
    value: "86065",
    type: "common",
  },
  {
    label: "CELLE-LEVESCAULT",
    value: "86045",
    type: "common",
  },
  {
    label: "AVON",
    value: "79023",
    type: "common",
  },
  {
    label: "SALLES",
    value: "79303",
    type: "common",
  },
  {
    label: "BOUGON",
    value: "79042",
    type: "common",
  },
  {
    label: "SOUVIGNE",
    value: "79319",
    type: "common",
  },
  {
    label: "SAINTE-EANNE",
    value: "79246",
    type: "common",
  },
  {
    label: "SAINTE-NEOMAYE",
    value: "79283",
    type: "common",
  },
  {
    label: "SAINT-MARTIN-DE-SAINT-MAIXENT",
    value: "79276",
    type: "common",
  },
  {
    label: "SAINT-MAIXENT-L'ECOLE",
    value: "79270",
    type: "common",
  },
  {
    label: "ROMANS",
    value: "79231",
    type: "common",
  },
  {
    label: "AZAY-LE-BRULE",
    value: "79024",
    type: "common",
  },
  {
    label: "FRANCOIS",
    value: "79128",
    type: "common",
  },
  {
    label: "LA CRECHE",
    value: "79048",
    type: "common",
  },
  {
    label: "SAINT-GELAIS",
    value: "79249",
    type: "common",
  },
  {
    label: "ECHIRE",
    value: "79109",
    type: "common",
  },
  {
    label: "CHAURAY",
    value: "79081",
    type: "common",
  },
  {
    label: "SCIECQ",
    value: "79308",
    type: "common",
  },
  {
    label: "SAINT-REMY",
    value: "79293",
    type: "common",
  },
  {
    label: "SAINT-MAXIRE",
    value: "79281",
    type: "common",
  },
  {
    label: "BENET",
    value: "85020",
    type: "common",
  },
  {
    label: "LIEZ",
    value: "85123",
    type: "common",
  },
  {
    label: "BOUILLE-COURDAULT",
    value: "85028",
    type: "common",
  },
  {
    label: "SAINT-PIERRE-LE-VIEUX",
    value: "85265",
    type: "common",
  },
  {
    label: "MAILLEZAIS",
    value: "85133",
    type: "common",
  },
  {
    label: "MAILLE",
    value: "85132",
    type: "common",
  },
  {
    label: "VIX",
    value: "85303",
    type: "common",
  },
  {
    label: "DOIX LES FONTAINES",
    value: "85080",
    type: "common",
  },
  {
    label: "LA TAILLEE",
    value: "85286",
    type: "common",
  },
  {
    label: "LE GUE-DE-VELLUIRE",
    value: "85105",
    type: "common",
  },
  {
    label: "VOUILLE-LES-MARAIS",
    value: "85304",
    type: "common",
  },
  {
    label: "CHAILLE-LES-MARAIS",
    value: "85042",
    type: "common",
  },
  {
    label: "SAINTE-RADEGONDE-DES-NOYERS",
    value: "85267",
    type: "common",
  },
  {
    label: "PUYRAVAULT",
    value: "85185",
    type: "common",
  },
  {
    label: "CHAMPAGNE-LES-MARAIS",
    value: "85049",
    type: "common",
  },
  {
    label: "TRIAIZE",
    value: "85297",
    type: "common",
  },
  {
    label: "SAINT-MICHEL-EN-L'HERM",
    value: "85255",
    type: "common",
  },
  {
    label: "LA TRANCHE-SUR-MER",
    value: "85294",
    type: "common",
  },
  {
    label: "CHATEL",
    value: "74063",
    type: "common",
  },
  {
    label: "ABONDANCE",
    value: "74001",
    type: "common",
  },
  {
    label: "LE BIOT",
    value: "74034",
    type: "common",
  },
  {
    label: "BONNEVAUX",
    value: "74041",
    type: "common",
  },
  {
    label: "LA BAUME",
    value: "74030",
    type: "common",
  },
  {
    label: "VAILLY",
    value: "74287",
    type: "common",
  },
  {
    label: "DRAILLANT",
    value: "74106",
    type: "common",
  },
  {
    label: "CERVENS",
    value: "74053",
    type: "common",
  },
  {
    label: "HABERE-POCHE",
    value: "74140",
    type: "common",
  },
  {
    label: "LULLIN",
    value: "74155",
    type: "common",
  },
  {
    label: "PERRIGNIER",
    value: "74210",
    type: "common",
  },
  {
    label: "BRENTHONNE",
    value: "74048",
    type: "common",
  },
  {
    label: "LULLY",
    value: "74156",
    type: "common",
  },
  {
    label: "FESSY",
    value: "74126",
    type: "common",
  },
  {
    label: "BONS-EN-CHABLAIS",
    value: "74043",
    type: "common",
  },
  {
    label: "BALLAISON",
    value: "74025",
    type: "common",
  },
  {
    label: "DOUVAINE",
    value: "74105",
    type: "common",
  },
  {
    label: "LOISIN",
    value: "74150",
    type: "common",
  },
  {
    label: "VEIGY-FONCENEX",
    value: "74293",
    type: "common",
  },
  {
    label: "CHENS-SUR-LEMAN",
    value: "74070",
    type: "common",
  },
  {
    label: "SAUVERNY",
    value: "01397",
    type: "common",
  },
  {
    label: "VERSONNEX",
    value: "01435",
    type: "common",
  },
  {
    label: "SEGNY",
    value: "01399",
    type: "common",
  },
  {
    label: "ORNEX",
    value: "01281",
    type: "common",
  },
  {
    label: "CHEVRY",
    value: "01103",
    type: "common",
  },
  {
    label: "CESSY",
    value: "01071",
    type: "common",
  },
  {
    label: "LELEX",
    value: "01210",
    type: "common",
  },
  {
    label: "ECHENEVEX",
    value: "01153",
    type: "common",
  },
  {
    label: "CROZET",
    value: "01135",
    type: "common",
  },
  {
    label: "LA PESSE",
    value: "39413",
    type: "common",
  },
  {
    label: "LES MOUSSIERES",
    value: "39373",
    type: "common",
  },
  {
    label: "BELLECOMBE",
    value: "39046",
    type: "common",
  },
  {
    label: "VULVOZ",
    value: "39585",
    type: "common",
  },
  {
    label: "CHOUX",
    value: "39151",
    type: "common",
  },
  {
    label: "LES BOUCHOUX",
    value: "39068",
    type: "common",
  },
  {
    label: "ARBENT",
    value: "01014",
    type: "common",
  },
  {
    label: "VIRY",
    value: "39579",
    type: "common",
  },
  {
    label: "ROGNA",
    value: "39463",
    type: "common",
  },
  {
    label: "DORTAN",
    value: "01148",
    type: "common",
  },
  {
    label: "CONDES",
    value: "39163",
    type: "common",
  },
  {
    label: "VOSBLES-VALFIN",
    value: "39583",
    type: "common",
  },
  {
    label: "CORNOD",
    value: "39166",
    type: "common",
  },
  {
    label: "POUILLAT",
    value: "01309",
    type: "common",
  },
  {
    label: "MONTFLEUR",
    value: "39353",
    type: "common",
  },
  {
    label: "CHARNOD",
    value: "39111",
    type: "common",
  },
  {
    label: "AROMAS",
    value: "39018",
    type: "common",
  },
  {
    label: "COURMANGOUX",
    value: "01127",
    type: "common",
  },
  {
    label: "VILLEMOTIER",
    value: "01445",
    type: "common",
  },
  {
    label: "BENY",
    value: "01038",
    type: "common",
  },
  {
    label: "SAINT-MARTIN-LE-CHATEL",
    value: "01375",
    type: "common",
  },
  {
    label: "MONTREVEL-EN-BRESSE",
    value: "01266",
    type: "common",
  },
  {
    label: "MALAFRETAZ",
    value: "01229",
    type: "common",
  },
  {
    label: "SAINT-SULPICE",
    value: "01387",
    type: "common",
  },
  {
    label: "SAINT-DIDIER-D'AUSSIAT",
    value: "01346",
    type: "common",
  },
  {
    label: "MARSONNAS",
    value: "01236",
    type: "common",
  },
  {
    label: "BAGE-LE-CHATEL",
    value: "01026",
    type: "common",
  },
  {
    label: "BAGE-DOMMARTIN",
    value: "01025",
    type: "common",
  },
  {
    label: "SAINT-LAURENT-SUR-SAONE",
    value: "01370",
    type: "common",
  },
  {
    label: "SAINT-ANDRE-DE-BAGE",
    value: "01332",
    type: "common",
  },
  {
    label: "REPLONGES",
    value: "01320",
    type: "common",
  },
  {
    label: "FEILLENS",
    value: "01159",
    type: "common",
  },
  {
    label: "SANCE",
    value: "71497",
    type: "common",
  },
  {
    label: "LA ROCHE-VINEUSE",
    value: "71371",
    type: "common",
  },
  {
    label: "PRISSE",
    value: "71360",
    type: "common",
  },
  {
    label: "HURIGNY",
    value: "71235",
    type: "common",
  },
  {
    label: "DAVAYE",
    value: "71169",
    type: "common",
  },
  {
    label: "CHEVAGNY-LES-CHEVRIERES",
    value: "71126",
    type: "common",
  },
  {
    label: "CHARNAY-LES-MACON",
    value: "71105",
    type: "common",
  },
  {
    label: "VERGISSON",
    value: "71567",
    type: "common",
  },
  {
    label: "SERRIERES",
    value: "71518",
    type: "common",
  },
  {
    label: "PIERRECLOS",
    value: "71350",
    type: "common",
  },
  {
    label: "MILLY-LAMARTINE",
    value: "71299",
    type: "common",
  },
  {
    label: "BUSSIERES",
    value: "71069",
    type: "common",
  },
  {
    label: "TRAMAYES",
    value: "71545",
    type: "common",
  },
  {
    label: "SAINT-POINT",
    value: "71470",
    type: "common",
  },
  {
    label: "TRAMBLY",
    value: "71546",
    type: "common",
  },
  {
    label: "SAINT-LEGER-SOUS-LA-BUSSIERE",
    value: "71441",
    type: "common",
  },
  {
    label: "MONTMELARD",
    value: "71316",
    type: "common",
  },
  {
    label: "MATOUR",
    value: "71289",
    type: "common",
  },
  {
    label: "DOMPIERRE-LES-ORMES",
    value: "71178",
    type: "common",
  },
  {
    label: "GIBLES",
    value: "71218",
    type: "common",
  },
  {
    label: "CURBIGNY",
    value: "71160",
    type: "common",
  },
  {
    label: "COLOMBIER-EN-BRIONNAIS",
    value: "71141",
    type: "common",
  },
  {
    label: "BOIS-SAINTE-MARIE",
    value: "71041",
    type: "common",
  },
  {
    label: "SAINT-SYMPHORIEN-DES-BOIS",
    value: "71483",
    type: "common",
  },
  {
    label: "SAINT-GERMAIN-EN-BRIONNAIS",
    value: "71421",
    type: "common",
  },
  {
    label: "OUROUX-SOUS-LE-BOIS-SAINTE-MARIE",
    value: "71335",
    type: "common",
  },
  {
    label: "DYO",
    value: "71185",
    type: "common",
  },
  {
    label: "AMANZE",
    value: "71006",
    type: "common",
  },
  {
    label: "VARENNE-L'ARCONCE",
    value: "71554",
    type: "common",
  },
  {
    label: "PRIZY",
    value: "71361",
    type: "common",
  },
  {
    label: "OYE",
    value: "71337",
    type: "common",
  },
  {
    label: "VERSAUGUES",
    value: "71573",
    type: "common",
  },
  {
    label: "SARRY",
    value: "71500",
    type: "common",
  },
  {
    label: "SAINT-DIDIER-EN-BRIONNAIS",
    value: "71406",
    type: "common",
  },
  {
    label: "ANZY-LE-DUC",
    value: "71011",
    type: "common",
  },
  {
    label: "VINDECY",
    value: "71581",
    type: "common",
  },
  {
    label: "MONTCEAUX-L'ETOILE",
    value: "71307",
    type: "common",
  },
  {
    label: "LUNEAU",
    value: "03154",
    type: "common",
  },
  {
    label: "AVRILLY",
    value: "03014",
    type: "common",
  },
  {
    label: "NEUILLY-EN-DONJON",
    value: "03196",
    type: "common",
  },
  {
    label: "MONTCOMBROUX-LES-MINES",
    value: "03181",
    type: "common",
  },
  {
    label: "LE DONJON",
    value: "03103",
    type: "common",
  },
  {
    label: "VARENNES-SUR-TECHE",
    value: "03299",
    type: "common",
  },
  {
    label: "SORBIER",
    value: "03274",
    type: "common",
  },
  {
    label: "BERT",
    value: "03024",
    type: "common",
  },
  {
    label: "TREZELLES",
    value: "03291",
    type: "common",
  },
  {
    label: "CHAVROCHES",
    value: "03071",
    type: "common",
  },
  {
    label: "TRETEAU",
    value: "03289",
    type: "common",
  },
  {
    label: "CINDRE",
    value: "03079",
    type: "common",
  },
  {
    label: "BOUCE",
    value: "03034",
    type: "common",
  },
  {
    label: "MONTOLDRE",
    value: "03187",
    type: "common",
  },
  {
    label: "SAINT-LOUP",
    value: "03242",
    type: "common",
  },
  {
    label: "CONTIGNY",
    value: "03083",
    type: "common",
  },
  {
    label: "VERNEUIL-EN-BOURBONNAIS",
    value: "03307",
    type: "common",
  },
  {
    label: "SAULCET",
    value: "03267",
    type: "common",
  },
  {
    label: "LE THEIL",
    value: "03281",
    type: "common",
  },
  {
    label: "LAFELINE",
    value: "03134",
    type: "common",
  },
  {
    label: "VOUSSAC",
    value: "03319",
    type: "common",
  },
  {
    label: "SAZERET",
    value: "03270",
    type: "common",
  },
  {
    label: "SAINT-MARCEL-EN-MURAT",
    value: "03243",
    type: "common",
  },
  {
    label: "DEUX-CHAISES",
    value: "03099",
    type: "common",
  },
  {
    label: "SAINT-PRIEST-EN-MURAT",
    value: "03256",
    type: "common",
  },
  {
    label: "DOYET",
    value: "03104",
    type: "common",
  },
  {
    label: "BEZENET",
    value: "03027",
    type: "common",
  },
  {
    label: "DENEUILLE-LES-MINES",
    value: "03097",
    type: "common",
  },
  {
    label: "CHAMBLET",
    value: "03052",
    type: "common",
  },
  {
    label: "SAINT-ANGEL",
    value: "03217",
    type: "common",
  },
  {
    label: "DESERTINES",
    value: "03098",
    type: "common",
  },
  {
    label: "MONTLUCON",
    value: "03185",
    type: "common",
  },
  {
    label: "DOMERAT",
    value: "03101",
    type: "common",
  },
  {
    label: "HURIEL",
    value: "03128",
    type: "common",
  },
  {
    label: "SAINT-MARTINIEN",
    value: "03246",
    type: "common",
  },
  {
    label: "ARCHIGNAT",
    value: "03005",
    type: "common",
  },
  {
    label: "LEYRAT",
    value: "23108",
    type: "common",
  },
  {
    label: "TREIGNAT",
    value: "03288",
    type: "common",
  },
  {
    label: "SAINT-SILVAIN-BAS-LE-ROC",
    value: "23240",
    type: "common",
  },
  {
    label: "BOUSSAC-BOURG",
    value: "23032",
    type: "common",
  },
  {
    label: "BOUSSAC",
    value: "23031",
    type: "common",
  },
  {
    label: "NOUZERINES",
    value: "23146",
    type: "common",
  },
  {
    label: "MALLERET-BOUSSAC",
    value: "23120",
    type: "common",
  },
  {
    label: "BETETE",
    value: "23022",
    type: "common",
  },
  {
    label: "GENOUILLAC",
    value: "23089",
    type: "common",
  },
  {
    label: "BONNAT",
    value: "23025",
    type: "common",
  },
  {
    label: "CHENIERS",
    value: "23062",
    type: "common",
  },
  {
    label: "CHAMBON-SAINTE-CROIX",
    value: "23044",
    type: "common",
  },
  {
    label: "MAISON-FEYNE",
    value: "23117",
    type: "common",
  },
  {
    label: "LAFAT",
    value: "23103",
    type: "common",
  },
  {
    label: "CROZANT",
    value: "23070",
    type: "common",
  },
  {
    label: "LA CHAPELLE-BALOUE",
    value: "23050",
    type: "common",
  },
  {
    label: "BAZELAT",
    value: "23018",
    type: "common",
  },
  {
    label: "AZERABLES",
    value: "23015",
    type: "common",
  },
  {
    label: "SAINT-GEORGES-LES-LANDES",
    value: "87145",
    type: "common",
  },
  {
    label: "LES GRANDS-CHEZEAUX",
    value: "87074",
    type: "common",
  },
  {
    label: "JOUAC",
    value: "87080",
    type: "common",
  },
  {
    label: "CROMAC",
    value: "87053",
    type: "common",
  },
  {
    label: "SAINT-MARTIN-LE-MAULT",
    value: "87165",
    type: "common",
  },
  {
    label: "LUSSAC-LES-EGLISES",
    value: "87087",
    type: "common",
  },
  {
    label: "VERNEUIL-MOUSTIERS",
    value: "87200",
    type: "common",
  },
  {
    label: "BOURG-ARCHAMBAULT",
    value: "86035",
    type: "common",
  },
  {
    label: "LATHUS-SAINT-REMY",
    value: "86120",
    type: "common",
  },
  {
    label: "SAULGE",
    value: "86254",
    type: "common",
  },
  {
    label: "MOULISMES",
    value: "86170",
    type: "common",
  },
  {
    label: "PERSAC",
    value: "86190",
    type: "common",
  },
  {
    label: "GOUEX",
    value: "86107",
    type: "common",
  },
  {
    label: "QUEAUX",
    value: "86203",
    type: "common",
  },
  {
    label: "BOURESSE",
    value: "86034",
    type: "common",
  },
  {
    label: "SAINT-SECONDIN",
    value: "86248",
    type: "common",
  },
  {
    label: "BRION",
    value: "86038",
    type: "common",
  },
  {
    label: "LA FERRIERE-AIROUX",
    value: "86097",
    type: "common",
  },
  {
    label: "MAGNE",
    value: "86141",
    type: "common",
  },
  {
    label: "CHAMPAGNE-SAINT-HILAIRE",
    value: "86052",
    type: "common",
  },
  {
    label: "VOULON",
    value: "86296",
    type: "common",
  },
  {
    label: "ANCHE",
    value: "86003",
    type: "common",
  },
  {
    label: "SAINT-SAUVANT",
    value: "86244",
    type: "common",
  },
  {
    label: "ROM",
    value: "79230",
    type: "common",
  },
  {
    label: "VANCAIS",
    value: "79336",
    type: "common",
  },
  {
    label: "CHENAY",
    value: "79084",
    type: "common",
  },
  {
    label: "EXOUDUN",
    value: "79115",
    type: "common",
  },
  {
    label: "LA MOTHE-SAINT-HERAY",
    value: "79184",
    type: "common",
  },
  {
    label: "VOUILLE",
    value: "79355",
    type: "common",
  },
  {
    label: "FRESSINES",
    value: "79129",
    type: "common",
  },
  {
    label: "NIORT",
    value: "79191",
    type: "common",
  },
  {
    label: "BESSINES",
    value: "79034",
    type: "common",
  },
  {
    label: "MAGNE",
    value: "79162",
    type: "common",
  },
  {
    label: "COULON",
    value: "79100",
    type: "common",
  },
  {
    label: "LE VANNEAU-IRLEAU",
    value: "79337",
    type: "common",
  },
  {
    label: "LE MAZEAU",
    value: "85139",
    type: "common",
  },
  {
    label: "ARCAIS",
    value: "79010",
    type: "common",
  },
  {
    label: "SAINT-SIGISMOND",
    value: "85269",
    type: "common",
  },
  {
    label: "DAMVIX",
    value: "85078",
    type: "common",
  },
  {
    label: "LA RONDE",
    value: "17303",
    type: "common",
  },
  {
    label: "TAUGON",
    value: "17439",
    type: "common",
  },
  {
    label: "L'ILE-D'ELLE",
    value: "85111",
    type: "common",
  },
  {
    label: "SAINT-JEAN-DE-LIVERSAY",
    value: "17349",
    type: "common",
  },
  {
    label: "MARANS",
    value: "17218",
    type: "common",
  },
  {
    label: "CHARRON",
    value: "17091",
    type: "common",
  },
  {
    label: "L'AIGUILLON-SUR-MER",
    value: "85001",
    type: "common",
  },
  {
    label: "LA FAUTE-SUR-MER",
    value: "85307",
    type: "common",
  },
  {
    label: "MONTRIOND",
    value: "74188",
    type: "common",
  },
  {
    label: "SAINT-JEAN-D'AULPS",
    value: "74238",
    type: "common",
  },
  {
    label: "ESSERT-ROMAND",
    value: "74114",
    type: "common",
  },
  {
    label: "SEYTROUX",
    value: "74271",
    type: "common",
  },
  {
    label: "BELLEVAUX",
    value: "74032",
    type: "common",
  },
  {
    label: "HABERE-LULLIN",
    value: "74139",
    type: "common",
  },
  {
    label: "VILLARD",
    value: "74301",
    type: "common",
  },
  {
    label: "BOGEVE",
    value: "74038",
    type: "common",
  },
  {
    label: "MEGEVETTE",
    value: "74174",
    type: "common",
  },
  {
    label: "SAXEL",
    value: "74261",
    type: "common",
  },
  {
    label: "BURDIGNIN",
    value: "74050",
    type: "common",
  },
  {
    label: "BOEGE",
    value: "74037",
    type: "common",
  },
  {
    label: "SAINT-CERGUES",
    value: "74229",
    type: "common",
  },
  {
    label: "MACHILLY",
    value: "74158",
    type: "common",
  },
  {
    label: "JUVIGNY",
    value: "74145",
    type: "common",
  },
  {
    label: "CRANVES-SALES",
    value: "74094",
    type: "common",
  },
  {
    label: "VILLE-LA-GRAND",
    value: "74305",
    type: "common",
  },
  {
    label: "FERNEY-VOLTAIRE",
    value: "01160",
    type: "common",
  },
  {
    label: "SAINT-GENIS-POUILLY",
    value: "01354",
    type: "common",
  },
  {
    label: "PREVESSIN-MOENS",
    value: "01313",
    type: "common",
  },
  {
    label: "THOIRY",
    value: "01419",
    type: "common",
  },
  {
    label: "SERGY",
    value: "01401",
    type: "common",
  },
  {
    label: "SAINT-JEAN-DE-GONVILLE",
    value: "01360",
    type: "common",
  },
  {
    label: "CHEZERY-FORENS",
    value: "01104",
    type: "common",
  },
  {
    label: "GIRON",
    value: "01174",
    type: "common",
  },
  {
    label: "BELLEYDOUX",
    value: "01035",
    type: "common",
  },
  {
    label: "ECHALLON",
    value: "01152",
    type: "common",
  },
  {
    label: "OYONNAX",
    value: "01283",
    type: "common",
  },
  {
    label: "GROISSIAT",
    value: "01181",
    type: "common",
  },
  {
    label: "GEOVREISSET",
    value: "01171",
    type: "common",
  },
  {
    label: "BELLIGNAT",
    value: "01031",
    type: "common",
  },
  {
    label: "SONTHONNAX-LA-MONTAGNE",
    value: "01410",
    type: "common",
  },
  {
    label: "SAMOGNAT",
    value: "01392",
    type: "common",
  },
  {
    label: "MATAFELON-GRANGES",
    value: "01240",
    type: "common",
  },
  {
    label: "THOIRETTE-COISIA",
    value: "39530",
    type: "common",
  },
  {
    label: "SIMANDRE-SUR-SURAN",
    value: "01408",
    type: "common",
  },
  {
    label: "CORVEISSIAT",
    value: "01125",
    type: "common",
  },
  {
    label: "VAL-REVERMONT",
    value: "01426",
    type: "common",
  },
  {
    label: "MEILLONNAS",
    value: "01241",
    type: "common",
  },
  {
    label: "NIVIGNE ET SURAN",
    value: "01095",
    type: "common",
  },
  {
    label: "SAINT-ETIENNE-DU-BOIS",
    value: "01350",
    type: "common",
  },
  {
    label: "VIRIAT",
    value: "01451",
    type: "common",
  },
  {
    label: "ATTIGNAT",
    value: "01024",
    type: "common",
  },
  {
    label: "POLLIAT",
    value: "01301",
    type: "common",
  },
  {
    label: "CURTAFOND",
    value: "01140",
    type: "common",
  },
  {
    label: "SAINT-GENIS-SUR-MENTHON",
    value: "01355",
    type: "common",
  },
  {
    label: "MEZERIAT",
    value: "01246",
    type: "common",
  },
  {
    label: "CONFRANCON",
    value: "01115",
    type: "common",
  },
  {
    label: "SAINT-JEAN-SUR-VEYLE",
    value: "01365",
    type: "common",
  },
  {
    label: "SAINT-CYR-SUR-MENTHON",
    value: "01343",
    type: "common",
  },
  {
    label: "PERREX",
    value: "01291",
    type: "common",
  },
  {
    label: "PONT-DE-VEYLE",
    value: "01306",
    type: "common",
  },
  {
    label: "LAIZ",
    value: "01203",
    type: "common",
  },
  {
    label: "GRIEGES",
    value: "01179",
    type: "common",
  },
  {
    label: "CROTTET",
    value: "01134",
    type: "common",
  },
  {
    label: "VINZELLES",
    value: "71583",
    type: "common",
  },
  {
    label: "VARENNES-LES-MACON",
    value: "71556",
    type: "common",
  },
  {
    label: "FUISSE",
    value: "71210",
    type: "common",
  },
  {
    label: "CRECHES-SUR-SAONE",
    value: "71150",
    type: "common",
  },
  {
    label: "CHANES",
    value: "71084",
    type: "common",
  },
  {
    label: "CHAINTRE",
    value: "71074",
    type: "common",
  },
  {
    label: "SOLUTRE-POUILLY",
    value: "71526",
    type: "common",
  },
  {
    label: "SAINT-VERAND",
    value: "71487",
    type: "common",
  },
  {
    label: "PRUZILLY",
    value: "71362",
    type: "common",
  },
  {
    label: "LEYNES",
    value: "71258",
    type: "common",
  },
  {
    label: "CHASSELAS",
    value: "71108",
    type: "common",
  },
  {
    label: "JULLIE",
    value: "69104",
    type: "common",
  },
  {
    label: "CENVES",
    value: "69035",
    type: "common",
  },
  {
    label: "GERMOLLES-SUR-GROSNE",
    value: "71217",
    type: "common",
  },
  {
    label: "SAINT-PIERRE-LE-VIEUX",
    value: "71469",
    type: "common",
  },
  {
    label: "SAINT-BONNET-DES-BRUYERES",
    value: "69182",
    type: "common",
  },
  {
    label: "AIGUEPERSE",
    value: "69002",
    type: "common",
  },
  {
    label: "VARENNES-SOUS-DUN",
    value: "71559",
    type: "common",
  },
  {
    label: "SAINT-RACHO",
    value: "71473",
    type: "common",
  },
  {
    label: "CHATENAY",
    value: "71116",
    type: "common",
  },
  {
    label: "VAREILLES",
    value: "71553",
    type: "common",
  },
  {
    label: "SAINT-LAURENT-EN-BRIONNAIS",
    value: "71437",
    type: "common",
  },
  {
    label: "LA CLAYETTE",
    value: "71133",
    type: "common",
  },
  {
    label: "LA CHAPELLE-SOUS-DUN",
    value: "71095",
    type: "common",
  },
  {
    label: "BAUDEMONT",
    value: "71022",
    type: "common",
  },
  {
    label: "VAUBAN",
    value: "71561",
    type: "common",
  },
  {
    label: "SAINT-CHRISTOPHE-EN-BRIONNAIS",
    value: "71399",
    type: "common",
  },
  {
    label: "BRIANT",
    value: "71060",
    type: "common",
  },
  {
    label: "SEMUR-EN-BRIONNAIS",
    value: "71510",
    type: "common",
  },
  {
    label: "SAINTE-FOY",
    value: "71415",
    type: "common",
  },
  {
    label: "MARCIGNY",
    value: "71275",
    type: "common",
  },
  {
    label: "CHAMBILLY",
    value: "71077",
    type: "common",
  },
  {
    label: "BOURG-LE-COMTE",
    value: "71048",
    type: "common",
  },
  {
    label: "BAUGY",
    value: "71024",
    type: "common",
  },
  {
    label: "CERON",
    value: "71071",
    type: "common",
  },
  {
    label: "LE BOUCHAUD",
    value: "03035",
    type: "common",
  },
  {
    label: "MONTAIGUET-EN-FOREZ",
    value: "03178",
    type: "common",
  },
  {
    label: "LENAX",
    value: "03142",
    type: "common",
  },
  {
    label: "LODDES",
    value: "03147",
    type: "common",
  },
  {
    label: "BARRAIS-BUSSOLLES",
    value: "03017",
    type: "common",
  },
  {
    label: "SERVILLY",
    value: "03272",
    type: "common",
  },
  {
    label: "LAPALISSE",
    value: "03138",
    type: "common",
  },
  {
    label: "SAINT-GERAND-LE-PUY",
    value: "03235",
    type: "common",
  },
  {
    label: "MONTAIGU-LE-BLIN",
    value: "03179",
    type: "common",
  },
  {
    label: "VARENNES-SUR-ALLIER",
    value: "03298",
    type: "common",
  },
  {
    label: "RONGERES",
    value: "03215",
    type: "common",
  },
  {
    label: "LANGY",
    value: "03137",
    type: "common",
  },
  {
    label: "CRECHY",
    value: "03091",
    type: "common",
  },
  {
    label: "SAINT-POURCAIN-SUR-SIOULE",
    value: "03254",
    type: "common",
  },
  {
    label: "PARAY-SOUS-BRIAILLES",
    value: "03204",
    type: "common",
  },
  {
    label: "LORIGES",
    value: "03148",
    type: "common",
  },
  {
    label: "MONTORD",
    value: "03188",
    type: "common",
  },
  {
    label: "LOUCHY-MONTFAND",
    value: "03149",
    type: "common",
  },
  {
    label: "CHAREIL-CINTRAT",
    value: "03059",
    type: "common",
  },
  {
    label: "BRANSAT",
    value: "03038",
    type: "common",
  },
  {
    label: "FLEURIEL",
    value: "03115",
    type: "common",
  },
  {
    label: "CESSET",
    value: "03049",
    type: "common",
  },
  {
    label: "TARGET",
    value: "03277",
    type: "common",
  },
  {
    label: "MONESTIER",
    value: "03175",
    type: "common",
  },
  {
    label: "BLOMARD",
    value: "03032",
    type: "common",
  },
  {
    label: "SAINT-BONNET-DE-FOUR",
    value: "03219",
    type: "common",
  },
  {
    label: "MONTMARAULT",
    value: "03186",
    type: "common",
  },
  {
    label: "BEAUNE-D'ALLIER",
    value: "03020",
    type: "common",
  },
  {
    label: "MONTVICQ",
    value: "03189",
    type: "common",
  },
  {
    label: "LOUROUX-DE-BEAUNE",
    value: "03151",
    type: "common",
  },
  {
    label: "HYDS",
    value: "03129",
    type: "common",
  },
  {
    label: "COLOMBIER",
    value: "03081",
    type: "common",
  },
  {
    label: "MALICORNE",
    value: "03159",
    type: "common",
  },
  {
    label: "COMMENTRY",
    value: "03082",
    type: "common",
  },
  {
    label: "VILLEBRET",
    value: "03314",
    type: "common",
  },
  {
    label: "NERIS-LES-BAINS",
    value: "03195",
    type: "common",
  },
  {
    label: "PREMILHAT",
    value: "03211",
    type: "common",
  },
  {
    label: "LIGNEROLLES",
    value: "03145",
    type: "common",
  },
  {
    label: "LAVAULT-SAINTE-ANNE",
    value: "03140",
    type: "common",
  },
  {
    label: "TEILLET-ARGENTY",
    value: "03279",
    type: "common",
  },
  {
    label: "QUINSSAINES",
    value: "03212",
    type: "common",
  },
  {
    label: "NOUHANT",
    value: "23145",
    type: "common",
  },
  {
    label: "LAMAIDS",
    value: "03136",
    type: "common",
  },
  {
    label: "SOUMANS",
    value: "23174",
    type: "common",
  },
  {
    label: "TOULX-SAINTE-CROIX",
    value: "23254",
    type: "common",
  },
  {
    label: "LAVAUFRANCHE",
    value: "23104",
    type: "common",
  },
  {
    label: "CLUGNAT",
    value: "23064",
    type: "common",
  },
  {
    label: "SAINT-DIZIER-LES-DOMAINES",
    value: "23188",
    type: "common",
  },
  {
    label: "JALESCHES",
    value: "23098",
    type: "common",
  },
  {
    label: "CHATELUS-MALVALEIX",
    value: "23057",
    type: "common",
  },
  {
    label: "ROCHES",
    value: "23162",
    type: "common",
  },
  {
    label: "JOUILLAT",
    value: "23101",
    type: "common",
  },
  {
    label: "CHAMPSANGLARD",
    value: "23049",
    type: "common",
  },
  {
    label: "LA CELLE-DUNOISE",
    value: "23039",
    type: "common",
  },
  {
    label: "LE BOURG-D'HEM",
    value: "23029",
    type: "common",
  },
  {
    label: "VILLARD",
    value: "23263",
    type: "common",
  },
  {
    label: "SAINT-SULPICE-LE-DUNOIS",
    value: "23244",
    type: "common",
  },
  {
    label: "SAGNAT",
    value: "23166",
    type: "common",
  },
  {
    label: "DUN-LE-PALESTEL",
    value: "23075",
    type: "common",
  },
  {
    label: "COLONDANNES",
    value: "23065",
    type: "common",
  },
  {
    label: "SAINT-LEGER-BRIDEREIX",
    value: "23207",
    type: "common",
  },
  {
    label: "SAINT-GERMAIN-BEAUPRE",
    value: "23199",
    type: "common",
  },
  {
    label: "VAREILLES",
    value: "23258",
    type: "common",
  },
  {
    label: "SAINT-AGNANT-DE-VERSILLAT",
    value: "23177",
    type: "common",
  },
  {
    label: "SAINT-SULPICE-LES-FEUILLES",
    value: "87182",
    type: "common",
  },
  {
    label: "ARNAC-LA-POSTE",
    value: "87003",
    type: "common",
  },
  {
    label: "MAILHAC-SUR-BENAIZE",
    value: "87090",
    type: "common",
  },
  {
    label: "SAINT-LEGER-MAGNAZEIX",
    value: "87160",
    type: "common",
  },
  {
    label: "TERSANNES",
    value: "87195",
    type: "common",
  },
  {
    label: "AZAT-LE-RIS",
    value: "87006",
    type: "common",
  },
  {
    label: "PLAISANCE",
    value: "86192",
    type: "common",
  },
  {
    label: "ADRIERS",
    value: "86001",
    type: "common",
  },
  {
    label: "NERIGNAC",
    value: "86176",
    type: "common",
  },
  {
    label: "MOUSSAC",
    value: "86171",
    type: "common",
  },
  {
    label: "USSON-DU-POITOU",
    value: "86276",
    type: "common",
  },
  {
    label: "CHATEAU-GARNIER",
    value: "86064",
    type: "common",
  },
  {
    label: "SOMMIERES-DU-CLAIN",
    value: "86264",
    type: "common",
  },
  {
    label: "BRUX",
    value: "86039",
    type: "common",
  },
  {
    label: "MESSE",
    value: "79177",
    type: "common",
  },
  {
    label: "SAINTE-SOLINE",
    value: "79297",
    type: "common",
  },
  {
    label: "SEPVRET",
    value: "79313",
    type: "common",
  },
  {
    label: "LEZAY",
    value: "79148",
    type: "common",
  },
  {
    label: "CHEY",
    value: "79087",
    type: "common",
  },
  {
    label: "BEAUSSAIS-VITRE",
    value: "79030",
    type: "common",
  },
  {
    label: "CELLES-SUR-BELLE",
    value: "79061",
    type: "common",
  },
  {
    label: "PRAHECQ",
    value: "79216",
    type: "common",
  },
  {
    label: "AIGONDIGNE",
    value: "79185",
    type: "common",
  },
  {
    label: "FORS",
    value: "79125",
    type: "common",
  },
  {
    label: "AIFFRES",
    value: "79003",
    type: "common",
  },
  {
    label: "SAINT-SYMPHORIEN",
    value: "79298",
    type: "common",
  },
  {
    label: "SANSAIS",
    value: "79304",
    type: "common",
  },
  {
    label: "FRONTENAY-ROHAN-ROHAN",
    value: "79130",
    type: "common",
  },
  {
    label: "AMURE",
    value: "79009",
    type: "common",
  },
  {
    label: "SAINT-GEORGES-DE-REX",
    value: "79254",
    type: "common",
  },
  {
    label: "LE BOURDET",
    value: "79046",
    type: "common",
  },
  {
    label: "SAINT-HILAIRE-LA-PALUD",
    value: "79257",
    type: "common",
  },
  {
    label: "LA GREVE-SUR-MIGNON",
    value: "17182",
    type: "common",
  },
  {
    label: "SAINT-CYR-DU-DORET",
    value: "17322",
    type: "common",
  },
  {
    label: "FERRIERES",
    value: "17158",
    type: "common",
  },
  {
    label: "COURCON",
    value: "17127",
    type: "common",
  },
  {
    label: "NUAILLE-D'AUNIS",
    value: "17267",
    type: "common",
  },
  {
    label: "SAINT-OUEN-D'AUNIS",
    value: "17376",
    type: "common",
  },
  {
    label: "LONGEVES",
    value: "17208",
    type: "common",
  },
  {
    label: "ANDILLY",
    value: "17008",
    type: "common",
  },
  {
    label: "VILLEDOUX",
    value: "17472",
    type: "common",
  },
  {
    label: "ESNANDES",
    value: "17153",
    type: "common",
  },
  {
    label: "MARSILLY",
    value: "17222",
    type: "common",
  },
  {
    label: "LOIX",
    value: "17207",
    type: "common",
  },
  {
    label: "SAINT-CLEMENT-DES-BALEINES",
    value: "17318",
    type: "common",
  },
  {
    label: "LES PORTES-EN-RE",
    value: "17286",
    type: "common",
  },
  {
    label: "ARS-EN-RE",
    value: "17019",
    type: "common",
  },
  {
    label: "MORZINE",
    value: "74191",
    type: "common",
  },
  {
    label: "VERCHAIX",
    value: "74294",
    type: "common",
  },
  {
    label: "LES GETS",
    value: "74134",
    type: "common",
  },
  {
    label: "LA COTE-D'ARBROZ",
    value: "74091",
    type: "common",
  },
  {
    label: "MIEUSSY",
    value: "74183",
    type: "common",
  },
  {
    label: "ONNION",
    value: "74205",
    type: "common",
  },
  {
    label: "SAINT-ANDRE-DE-BOEGE",
    value: "74226",
    type: "common",
  },
  {
    label: "VIUZ-EN-SALLAZ",
    value: "74311",
    type: "common",
  },
  {
    label: "VILLE-EN-SALLAZ",
    value: "74304",
    type: "common",
  },
  {
    label: "MARCELLAZ",
    value: "74162",
    type: "common",
  },
  {
    label: "NANGY",
    value: "74197",
    type: "common",
  },
  {
    label: "LUCINGES",
    value: "74153",
    type: "common",
  },
  {
    label: "FILLINGES",
    value: "74128",
    type: "common",
  },
  {
    label: "BONNE",
    value: "74040",
    type: "common",
  },
  {
    label: "ARTHAZ-PONT-NOTRE-DAME",
    value: "74021",
    type: "common",
  },
  {
    label: "VETRAZ-MONTHOUX",
    value: "74298",
    type: "common",
  },
  {
    label: "MONNETIER-MORNEX",
    value: "74185",
    type: "common",
  },
  {
    label: "GAILLARD",
    value: "74133",
    type: "common",
  },
  {
    label: "ETREMBIERES",
    value: "74118",
    type: "common",
  },
  {
    label: "ANNEMASSE",
    value: "74012",
    type: "common",
  },
  {
    label: "AMBILLY",
    value: "74008",
    type: "common",
  },
  {
    label: "BOSSEY",
    value: "74044",
    type: "common",
  },
  {
    label: "CHALLEX",
    value: "01078",
    type: "common",
  },
  {
    label: "PERON",
    value: "01288",
    type: "common",
  },
  {
    label: "FARGES",
    value: "01158",
    type: "common",
  },
  {
    label: "MONTANGES",
    value: "01257",
    type: "common",
  },
  {
    label: "CONFORT",
    value: "01114",
    type: "common",
  },
  {
    label: "CHAMPFROMIER",
    value: "01081",
    type: "common",
  },
  {
    label: "SAINT-GERMAIN-DE-JOUX",
    value: "01357",
    type: "common",
  },
  {
    label: "PLAGNE",
    value: "01298",
    type: "common",
  },
  {
    label: "CHARIX",
    value: "01087",
    type: "common",
  },
  {
    label: "MONTREAL-LA-CLUSE",
    value: "01265",
    type: "common",
  },
  {
    label: "MARTIGNAT",
    value: "01237",
    type: "common",
  },
  {
    label: "APREMONT",
    value: "01011",
    type: "common",
  },
  {
    label: "NURIEUX-VOLOGNAT",
    value: "01267",
    type: "common",
  },
  {
    label: "IZERNORE",
    value: "01192",
    type: "common",
  },
  {
    label: "BEARD-GEOVREISSIAT",
    value: "01170",
    type: "common",
  },
  {
    label: "BRION",
    value: "01063",
    type: "common",
  },
  {
    label: "GRAND-CORENT",
    value: "01177",
    type: "common",
  },
  {
    label: "CIZE",
    value: "01106",
    type: "common",
  },
  {
    label: "BOLOZON",
    value: "01051",
    type: "common",
  },
  {
    label: "VILLEREVERSURE",
    value: "01447",
    type: "common",
  },
  {
    label: "RAMASSE",
    value: "01317",
    type: "common",
  },
  {
    label: "DROM",
    value: "01150",
    type: "common",
  },
  {
    label: "SAINT-JUST",
    value: "01369",
    type: "common",
  },
  {
    label: "JASSERON",
    value: "01195",
    type: "common",
  },
  {
    label: "CEYZERIAT",
    value: "01072",
    type: "common",
  },
  {
    label: "SAINT-REMY",
    value: "01385",
    type: "common",
  },
  {
    label: "SAINT-DENIS-LES-BOURG",
    value: "01344",
    type: "common",
  },
  {
    label: "BOURG-EN-BRESSE",
    value: "01053",
    type: "common",
  },
  {
    label: "MONTRACOL",
    value: "01264",
    type: "common",
  },
  {
    label: "MONTCET",
    value: "01259",
    type: "common",
  },
  {
    label: "BUELLAS",
    value: "01065",
    type: "common",
  },
  {
    label: "VONNAS",
    value: "01457",
    type: "common",
  },
  {
    label: "VANDEINS",
    value: "01429",
    type: "common",
  },
  {
    label: "CHAVEYRIAT",
    value: "01096",
    type: "common",
  },
  {
    label: "CHANOZ-CHATENAY",
    value: "01084",
    type: "common",
  },
  {
    label: "SAINT-JULIEN-SUR-VEYLE",
    value: "01368",
    type: "common",
  },
  {
    label: "SAINT-ANDRE-D'HUIRIAT",
    value: "01334",
    type: "common",
  },
  {
    label: "BIZIAT",
    value: "01046",
    type: "common",
  },
  {
    label: "ILLIAT",
    value: "01188",
    type: "common",
  },
  {
    label: "GARNERANS",
    value: "01167",
    type: "common",
  },
  {
    label: "CRUZILLES-LES-MEPILLAT",
    value: "01136",
    type: "common",
  },
  {
    label: "CORMORANCHE-SUR-SAONE",
    value: "01123",
    type: "common",
  },
  {
    label: "BEY",
    value: "01042",
    type: "common",
  },
  {
    label: "SAINT-SYMPHORIEN-D'ANCELLES",
    value: "71481",
    type: "common",
  },
  {
    label: "SAINT-AMOUR-BELLEVUE",
    value: "71385",
    type: "common",
  },
  {
    label: "ROMANECHE-THORINS",
    value: "71372",
    type: "common",
  },
  {
    label: "LA CHAPELLE-DE-GUINCHAY",
    value: "71090",
    type: "common",
  },
  {
    label: "JULIENAS",
    value: "69103",
    type: "common",
  },
  {
    label: "FLEURIE",
    value: "69084",
    type: "common",
  },
  {
    label: "EMERINGES",
    value: "69082",
    type: "common",
  },
  {
    label: "CHIROUBLES",
    value: "69058",
    type: "common",
  },
  {
    label: "CHENAS",
    value: "69053",
    type: "common",
  },
  {
    label: "VAUXRENARD",
    value: "69258",
    type: "common",
  },
  {
    label: "LES ARDILLATS",
    value: "69012",
    type: "common",
  },
  {
    label: "SAINT-IGNY-DE-VERS",
    value: "69209",
    type: "common",
  },
  {
    label: "SAINT-CLEMENT-DE-VERS",
    value: "69186",
    type: "common",
  },
  {
    label: "PROPIERES",
    value: "69161",
    type: "common",
  },
  {
    label: "AZOLETTE",
    value: "69016",
    type: "common",
  },
  {
    label: "MUSSY-SOUS-DUN",
    value: "71327",
    type: "common",
  },
  {
    label: "CHAUFFAILLES",
    value: "71120",
    type: "common",
  },
  {
    label: "ANGLURE-SOUS-DUN",
    value: "71008",
    type: "common",
  },
  {
    label: "SAINT-GERMAIN-LA-MONTAGNE",
    value: "42229",
    type: "common",
  },
  {
    label: "TANCON",
    value: "71533",
    type: "common",
  },
  {
    label: "SAINT-MAURICE-LES-CHATEAUNEUF",
    value: "71463",
    type: "common",
  },
  {
    label: "SAINT-MARTIN-DE-LIXY",
    value: "71451",
    type: "common",
  },
  {
    label: "CHATEAUNEUF",
    value: "71113",
    type: "common",
  },
  {
    label: "CHASSIGNY-SOUS-DUN",
    value: "71110",
    type: "common",
  },
  {
    label: "SAINT-EDMOND",
    value: "71408",
    type: "common",
  },
  {
    label: "SAINT-BONNET-DE-CRAY",
    value: "71393",
    type: "common",
  },
  {
    label: "LIGNY-EN-BRIONNAIS",
    value: "71259",
    type: "common",
  },
  {
    label: "SAINT-MARTIN-DU-LAC",
    value: "71453",
    type: "common",
  },
  {
    label: "SAINT-JULIEN-DE-JONZY",
    value: "71434",
    type: "common",
  },
  {
    label: "MAILLY",
    value: "71271",
    type: "common",
  },
  {
    label: "IGUERANDE",
    value: "71238",
    type: "common",
  },
  {
    label: "FLEURY-LA-MONTAGNE",
    value: "71200",
    type: "common",
  },
  {
    label: "MELAY",
    value: "71291",
    type: "common",
  },
  {
    label: "ARTAIX",
    value: "71012",
    type: "common",
  },
  {
    label: "CHENAY-LE-CHATEL",
    value: "71123",
    type: "common",
  },
  {
    label: "URBISE",
    value: "42317",
    type: "common",
  },
  {
    label: "SAINT-MARTIN-D'ESTREAUX",
    value: "42257",
    type: "common",
  },
  {
    label: "SAIL-LES-BAINS",
    value: "42194",
    type: "common",
  },
  {
    label: "ANDELAROCHE",
    value: "03004",
    type: "common",
  },
  {
    label: "SAINT-PRIX",
    value: "03257",
    type: "common",
  },
  {
    label: "DROITURIER",
    value: "03105",
    type: "common",
  },
  {
    label: "PERIGNY",
    value: "03205",
    type: "common",
  },
  {
    label: "BILLEZOIS",
    value: "03028",
    type: "common",
  },
  {
    label: "SEUILLET",
    value: "03273",
    type: "common",
  },
  {
    label: "SANSSAT",
    value: "03266",
    type: "common",
  },
  {
    label: "SAINT-FELIX",
    value: "03232",
    type: "common",
  },
  {
    label: "MAGNET",
    value: "03157",
    type: "common",
  },
  {
    label: "MARCENAT",
    value: "03160",
    type: "common",
  },
  {
    label: "BILLY",
    value: "03029",
    type: "common",
  },
  {
    label: "SAINT-DIDIER-LA-FORET",
    value: "03227",
    type: "common",
  },
  {
    label: "ETROUSSAT",
    value: "03112",
    type: "common",
  },
  {
    label: "BAYET",
    value: "03018",
    type: "common",
  },
  {
    label: "BARBERIER",
    value: "03016",
    type: "common",
  },
  {
    label: "USSEL-D'ALLIER",
    value: "03294",
    type: "common",
  },
  {
    label: "TAXAT-SENAT",
    value: "03278",
    type: "common",
  },
  {
    label: "FOURILLES",
    value: "03116",
    type: "common",
  },
  {
    label: "DENEUILLE-LES-CHANTELLE",
    value: "03096",
    type: "common",
  },
  {
    label: "CHANTELLE",
    value: "03053",
    type: "common",
  },
  {
    label: "CHEZELLE",
    value: "03075",
    type: "common",
  },
  {
    label: "VERNUSSE",
    value: "03308",
    type: "common",
  },
  {
    label: "LOUROUX-DE-BOUBLE",
    value: "03152",
    type: "common",
  },
  {
    label: "COUTANSOUZE",
    value: "03089",
    type: "common",
  },
  {
    label: "CHIRAT-L'EGLISE",
    value: "03077",
    type: "common",
  },
  {
    label: "LAPEYROUSE",
    value: "63187",
    type: "common",
  },
  {
    label: "LA CELLE",
    value: "03047",
    type: "common",
  },
  {
    label: "TERJAT",
    value: "03280",
    type: "common",
  },
  {
    label: "DURDAT-LAREQUILLE",
    value: "03106",
    type: "common",
  },
  {
    label: "ARPHEUILLES-SAINT-PRIEST",
    value: "03007",
    type: "common",
  },
  {
    label: "SAINTE-THERENCE",
    value: "03261",
    type: "common",
  },
  {
    label: "SAINT-GENEST",
    value: "03233",
    type: "common",
  },
  {
    label: "MAZIRAT",
    value: "03167",
    type: "common",
  },
  {
    label: "BUDELIERE",
    value: "23035",
    type: "common",
  },
  {
    label: "VIERSAT",
    value: "23261",
    type: "common",
  },
  {
    label: "LEPAUD",
    value: "23106",
    type: "common",
  },
  {
    label: "VERNEIGES",
    value: "23259",
    type: "common",
  },
  {
    label: "BORD-SAINT-GEORGES",
    value: "23026",
    type: "common",
  },
  {
    label: "AUGE",
    value: "23009",
    type: "common",
  },
  {
    label: "TROIS-FONDS",
    value: "23255",
    type: "common",
  },
  {
    label: "LA CELLE-SOUS-GOUZON",
    value: "23040",
    type: "common",
  },
  {
    label: "SAINT-SILVAIN-SOUS-TOULX",
    value: "23243",
    type: "common",
  },
  {
    label: "DOMEYROT",
    value: "23072",
    type: "common",
  },
  {
    label: "LADAPEYRE",
    value: "23102",
    type: "common",
  },
  {
    label: "BLAUDEIX",
    value: "23023",
    type: "common",
  },
  {
    label: "GLENIC",
    value: "23092",
    type: "common",
  },
  {
    label: "AJAIN",
    value: "23002",
    type: "common",
  },
  {
    label: "SAINT-FIEL",
    value: "23195",
    type: "common",
  },
  {
    label: "ANZEME",
    value: "23004",
    type: "common",
  },
  {
    label: "BUSSIERE-DUNOISE",
    value: "23036",
    type: "common",
  },
  {
    label: "FLEURAT",
    value: "23082",
    type: "common",
  },
  {
    label: "SAINT-PRIEST-LA-PLAINE",
    value: "23236",
    type: "common",
  },
  {
    label: "NAILLAT",
    value: "23141",
    type: "common",
  },
  {
    label: "NOTH",
    value: "23143",
    type: "common",
  },
  {
    label: "LIZIERES",
    value: "23111",
    type: "common",
  },
  {
    label: "LA SOUTERRAINE",
    value: "23176",
    type: "common",
  },
  {
    label: "SAINT-MAURICE-LA-SOUTERRAINE",
    value: "23219",
    type: "common",
  },
  {
    label: "SAINT-HILAIRE-LA-TREILLE",
    value: "87149",
    type: "common",
  },
  {
    label: "DOMPIERRE-LES-EGLISES",
    value: "87057",
    type: "common",
  },
  {
    label: "MAGNAC-LAVAL",
    value: "87089",
    type: "common",
  },
  {
    label: "DINSAC",
    value: "87056",
    type: "common",
  },
  {
    label: "LA BAZEUGE",
    value: "87008",
    type: "common",
  },
  {
    label: "ORADOUR-SAINT-GENEST",
    value: "87109",
    type: "common",
  },
  {
    label: "MOUTERRE-SUR-BLOURDE",
    value: "86172",
    type: "common",
  },
  {
    label: "L'ISLE-JOURDAIN",
    value: "86112",
    type: "common",
  },
  {
    label: "LE VIGEANT",
    value: "86289",
    type: "common",
  },
  {
    label: "SAINT-MARTIN-L'ARS",
    value: "86234",
    type: "common",
  },
  {
    label: "PAYROUX",
    value: "86189",
    type: "common",
  },
  {
    label: "JOUSSE",
    value: "86119",
    type: "common",
  },
  {
    label: "SAINT-ROMAIN",
    value: "86242",
    type: "common",
  },
  {
    label: "LA CHAPELLE-BATON",
    value: "86055",
    type: "common",
  },
  {
    label: "CHAMPNIERS",
    value: "86054",
    type: "common",
  },
  {
    label: "ROMAGNE",
    value: "86211",
    type: "common",
  },
  {
    label: "BLANZAY",
    value: "86029",
    type: "common",
  },
  {
    label: "CHAUNAY",
    value: "86068",
    type: "common",
  },
  {
    label: "CHAMPAGNE-LE-SEC",
    value: "86051",
    type: "common",
  },
  {
    label: "VANZAY",
    value: "79338",
    type: "common",
  },
  {
    label: "PERS",
    value: "79205",
    type: "common",
  },
  {
    label: "CAUNAY",
    value: "79060",
    type: "common",
  },
  {
    label: "SAINT-COUTANT",
    value: "79243",
    type: "common",
  },
  {
    label: "CLUSSAIS-LA-POMMERAIE",
    value: "79095",
    type: "common",
  },
  {
    label: "SAINT-VINCENT-LA-CHATRE",
    value: "79301",
    type: "common",
  },
  {
    label: "MAISONNAY",
    value: "79164",
    type: "common",
  },
  {
    label: "SAINT-ROMANS-LES-MELLE",
    value: "79295",
    type: "common",
  },
  {
    label: "PERIGNE",
    value: "79204",
    type: "common",
  },
  {
    label: "SAINT-ROMANS-DES-CHAMPS",
    value: "79294",
    type: "common",
  },
  {
    label: "SAINT-MARTIN-DE-BERNEGOUE",
    value: "79273",
    type: "common",
  },
  {
    label: "BRULAIN",
    value: "79058",
    type: "common",
  },
  {
    label: "MARIGNY",
    value: "79166",
    type: "common",
  },
  {
    label: "JUSCORPS",
    value: "79144",
    type: "common",
  },
  {
    label: "GRANZAY-GRIPT",
    value: "79137",
    type: "common",
  },
  {
    label: "BEAUVOIR-SUR-NIORT",
    value: "79031",
    type: "common",
  },
  {
    label: "VALLANS",
    value: "79335",
    type: "common",
  },
  {
    label: "LA ROCHENARD",
    value: "79229",
    type: "common",
  },
  {
    label: "LA FOYE-MONJAULT",
    value: "79127",
    type: "common",
  },
  {
    label: "EPANNES",
    value: "79112",
    type: "common",
  },
  {
    label: "PRIN-DEYRANCON",
    value: "79220",
    type: "common",
  },
  {
    label: "MAUZE-SUR-LE-MIGNON",
    value: "79170",
    type: "common",
  },
  {
    label: "SAINT-PIERRE-D'AMILLY",
    value: "17382",
    type: "common",
  },
  {
    label: "LA LAIGNE",
    value: "17201",
    type: "common",
  },
  {
    label: "CRAMCHABAN",
    value: "17132",
    type: "common",
  },
  {
    label: "BOUHET",
    value: "17057",
    type: "common",
  },
  {
    label: "BENON",
    value: "17041",
    type: "common",
  },
  {
    label: "SAINT-SAUVEUR-D'AUNIS",
    value: "17396",
    type: "common",
  },
  {
    label: "LE GUE-D'ALLERE",
    value: "17186",
    type: "common",
  },
  {
    label: "ANAIS",
    value: "17007",
    type: "common",
  },
  {
    label: "VERINES",
    value: "17466",
    type: "common",
  },
  {
    label: "SAINTE-SOULLE",
    value: "17407",
    type: "common",
  },
  {
    label: "BOURGNEUF",
    value: "17059",
    type: "common",
  },
  {
    label: "ANGLIERS",
    value: "17009",
    type: "common",
  },
  {
    label: "SAINT-XANDRE",
    value: "17414",
    type: "common",
  },
  {
    label: "PUILBOREAU",
    value: "17291",
    type: "common",
  },
  {
    label: "PERIGNY",
    value: "17274",
    type: "common",
  },
  {
    label: "DOMPIERRE-SUR-MER",
    value: "17142",
    type: "common",
  },
  {
    label: "LA ROCHELLE",
    value: "17300",
    type: "common",
  },
  {
    label: "NIEUL-SUR-MER",
    value: "17264",
    type: "common",
  },
  {
    label: "LAGORD",
    value: "17200",
    type: "common",
  },
  {
    label: "L'HOUMEAU",
    value: "17190",
    type: "common",
  },
  {
    label: "RIVEDOUX-PLAGE",
    value: "17297",
    type: "common",
  },
  {
    label: "SAINT-MARTIN-DE-RE",
    value: "17369",
    type: "common",
  },
  {
    label: "SAINTE-MARIE-DE-RE",
    value: "17360",
    type: "common",
  },
  {
    label: "LA FLOTTE",
    value: "17161",
    type: "common",
  },
  {
    label: "LA COUARDE-SUR-MER",
    value: "17121",
    type: "common",
  },
  {
    label: "LE BOIS-PLAGE-EN-RE",
    value: "17051",
    type: "common",
  },
  {
    label: "SAMOENS",
    value: "74258",
    type: "common",
  },
  {
    label: "TANINGES",
    value: "74276",
    type: "common",
  },
  {
    label: "SAINT-SIGISMOND",
    value: "74252",
    type: "common",
  },
  {
    label: "LA RIVIERE-ENVERSE",
    value: "74223",
    type: "common",
  },
  {
    label: "THYEZ",
    value: "74278",
    type: "common",
  },
  {
    label: "CHATILLON-SUR-CLUSES",
    value: "74064",
    type: "common",
  },
  {
    label: "SAINT-JEOIRE",
    value: "74241",
    type: "common",
  },
  {
    label: "MARIGNIER",
    value: "74164",
    type: "common",
  },
  {
    label: "AYSE",
    value: "74024",
    type: "common",
  },
  {
    label: "LA TOUR",
    value: "74284",
    type: "common",
  },
  {
    label: "SAINT-JEAN-DE-THOLOME",
    value: "74240",
    type: "common",
  },
  {
    label: "PEILLONNEX",
    value: "74209",
    type: "common",
  },
  {
    label: "FAUCIGNY",
    value: "74122",
    type: "common",
  },
  {
    label: "SCIENTRIER",
    value: "74262",
    type: "common",
  },
  {
    label: "PERS-JUSSY",
    value: "74211",
    type: "common",
  },
  {
    label: "CORNIER",
    value: "74090",
    type: "common",
  },
  {
    label: "CONTAMINE-SUR-ARVE",
    value: "74087",
    type: "common",
  },
  {
    label: "ARENTHON",
    value: "74018",
    type: "common",
  },
  {
    label: "REIGNIER-ESERY",
    value: "74220",
    type: "common",
  },
  {
    label: "LA MURAZ",
    value: "74193",
    type: "common",
  },
  {
    label: "ARBUSIGNY",
    value: "74015",
    type: "common",
  },
  {
    label: "LE SAPPEY",
    value: "74259",
    type: "common",
  },
  {
    label: "NEYDENS",
    value: "74201",
    type: "common",
  },
  {
    label: "COLLONGES-SOUS-SALEVE",
    value: "74082",
    type: "common",
  },
  {
    label: "BEAUMONT",
    value: "74031",
    type: "common",
  },
  {
    label: "ARCHAMPS",
    value: "74016",
    type: "common",
  },
  {
    label: "VIRY",
    value: "74309",
    type: "common",
  },
  {
    label: "VERS",
    value: "74296",
    type: "common",
  },
  {
    label: "SAINT-JULIEN-EN-GENEVOIS",
    value: "74243",
    type: "common",
  },
  {
    label: "FEIGERES",
    value: "74124",
    type: "common",
  },
  {
    label: "POUGNY",
    value: "01308",
    type: "common",
  },
  {
    label: "VULBENS",
    value: "74314",
    type: "common",
  },
  {
    label: "VALLEIRY",
    value: "74288",
    type: "common",
  },
  {
    label: "CHENEX",
    value: "74069",
    type: "common",
  },
  {
    label: "LEAZ",
    value: "01209",
    type: "common",
  },
  {
    label: "COLLONGES",
    value: "01109",
    type: "common",
  },
  {
    label: "CHEVRIER",
    value: "74074",
    type: "common",
  },
  {
    label: "LE POIZAT-LALLEYRIAT",
    value: "01204",
    type: "common",
  },
  {
    label: "LES NEYROLLES",
    value: "01274",
    type: "common",
  },
  {
    label: "NANTUA",
    value: "01269",
    type: "common",
  },
  {
    label: "SAINT-MARTIN-DU-FRENE",
    value: "01373",
    type: "common",
  },
  {
    label: "PORT",
    value: "01307",
    type: "common",
  },
  {
    label: "PEYRIAT",
    value: "01293",
    type: "common",
  },
  {
    label: "MAILLAT",
    value: "01228",
    type: "common",
  },
  {
    label: "CONDAMINE",
    value: "01112",
    type: "common",
  },
  {
    label: "CHEVILLARD",
    value: "01101",
    type: "common",
  },
  {
    label: "SERRIERES-SUR-AIN",
    value: "01404",
    type: "common",
  },
  {
    label: "LEYSSARD",
    value: "01214",
    type: "common",
  },
  {
    label: "HAUTECOURT-ROMANECHE",
    value: "01184",
    type: "common",
  },
  {
    label: "CHALLES-LA-MONTAGNE",
    value: "01077",
    type: "common",
  },
  {
    label: "CEIGNES",
    value: "01067",
    type: "common",
  },
  {
    label: "REVONNAS",
    value: "01321",
    type: "common",
  },
  {
    label: "BOHAS-MEYRIAT-RIGNAT",
    value: "01245",
    type: "common",
  },
  {
    label: "JOURNANS",
    value: "01197",
    type: "common",
  },
  {
    label: "TOSSIAT",
    value: "01422",
    type: "common",
  },
  {
    label: "MONTAGNAT",
    value: "01254",
    type: "common",
  },
  {
    label: "CERTINES",
    value: "01069",
    type: "common",
  },
  {
    label: "SERVAS",
    value: "01405",
    type: "common",
  },
  {
    label: "PERONNAS",
    value: "01289",
    type: "common",
  },
  {
    label: "LENT",
    value: "01211",
    type: "common",
  },
  {
    label: "SAINT-ANDRE-SUR-VIEUX-JONC",
    value: "01336",
    type: "common",
  },
  {
    label: "SAINT-ANDRE-LE-BOUCHOUX",
    value: "01335",
    type: "common",
  },
  {
    label: "CONDEISSIAT",
    value: "01113",
    type: "common",
  },
  {
    label: "ROMANS",
    value: "01328",
    type: "common",
  },
  {
    label: "NEUVILLE-LES-DAMES",
    value: "01272",
    type: "common",
  },
  {
    label: "SULIGNAT",
    value: "01412",
    type: "common",
  },
  {
    label: "L'ABERGEMENT-CLEMENCIAT",
    value: "01001",
    type: "common",
  },
  {
    label: "SAINT-ETIENNE-SUR-CHALARONNE",
    value: "01351",
    type: "common",
  },
  {
    label: "SAINT-DIDIER-SUR-CHALARONNE",
    value: "01348",
    type: "common",
  },
  {
    label: "PEYZIEUX-SUR-SAONE",
    value: "01295",
    type: "common",
  },
  {
    label: "DOMPIERRE-SUR-CHALARONNE",
    value: "01146",
    type: "common",
  },
  {
    label: "THOISSEY",
    value: "01420",
    type: "common",
  },
  {
    label: "MOGNENEINS",
    value: "01252",
    type: "common",
  },
  {
    label: "TAPONAS",
    value: "69242",
    type: "common",
  },
  {
    label: "DRACE",
    value: "69077",
    type: "common",
  },
  {
    label: "VILLIE-MORGON",
    value: "69267",
    type: "common",
  },
  {
    label: "LANCIE",
    value: "69108",
    type: "common",
  },
  {
    label: "CORCELLES-EN-BEAUJOLAIS",
    value: "69065",
    type: "common",
  },
  {
    label: "CERCIE",
    value: "69036",
    type: "common",
  },
  {
    label: "REGNIE-DURETTE",
    value: "69165",
    type: "common",
  },
  {
    label: "LANTIGNIE",
    value: "69109",
    type: "common",
  },
  {
    label: "BEAUJEU",
    value: "69018",
    type: "common",
  },
  {
    label: "VERNAY",
    value: "69261",
    type: "common",
  },
  {
    label: "SAINT-DIDIER-SUR-BEAUJEU",
    value: "69196",
    type: "common",
  },
  {
    label: "CHENELETTE",
    value: "69054",
    type: "common",
  },
  {
    label: "POULE-LES-ECHARMEAUX",
    value: "69160",
    type: "common",
  },
  {
    label: "BELLEROCHE",
    value: "42014",
    type: "common",
  },
  {
    label: "BELMONT-DE-LA-LOIRE",
    value: "42015",
    type: "common",
  },
  {
    label: "SAINT-IGNY-DE-ROCHE",
    value: "71428",
    type: "common",
  },
  {
    label: "COUBLANC",
    value: "71148",
    type: "common",
  },
  {
    label: "MARS",
    value: "42141",
    type: "common",
  },
  {
    label: "MAIZILLY",
    value: "42131",
    type: "common",
  },
  {
    label: "ECOCHE",
    value: "42086",
    type: "common",
  },
  {
    label: "ARCINGES",
    value: "42007",
    type: "common",
  },
  {
    label: "SAINT-DENIS-DE-CABANNE",
    value: "42215",
    type: "common",
  },
  {
    label: "CHARLIEU",
    value: "42052",
    type: "common",
  },
  {
    label: "CHANDON",
    value: "42048",
    type: "common",
  },
  {
    label: "SAINT-PIERRE-LA-NOAILLE",
    value: "42273",
    type: "common",
  },
  {
    label: "SAINT-NIZIER-SOUS-CHARLIEU",
    value: "42267",
    type: "common",
  },
  {
    label: "POUILLY-SOUS-CHARLIEU",
    value: "42177",
    type: "common",
  },
  {
    label: "NOAILLY",
    value: "42157",
    type: "common",
  },
  {
    label: "LA BENISSON-DIEU",
    value: "42016",
    type: "common",
  },
  {
    label: "VIVANS",
    value: "42337",
    type: "common",
  },
  {
    label: "SAINT-FORGEUX-LESPINASSE",
    value: "42220",
    type: "common",
  },
  {
    label: "CHANGY",
    value: "42049",
    type: "common",
  },
  {
    label: "LA PACAUDIERE",
    value: "42163",
    type: "common",
  },
  {
    label: "LE CROZET",
    value: "42078",
    type: "common",
  },
  {
    label: "SAINT-PIERRE-LAVAL",
    value: "03250",
    type: "common",
  },
  {
    label: "CHATELUS",
    value: "03068",
    type: "common",
  },
  {
    label: "ARFEUILLES",
    value: "03006",
    type: "common",
  },
  {
    label: "LE BREUIL",
    value: "03042",
    type: "common",
  },
  {
    label: "SAINT-CHRISTOPHE",
    value: "03223",
    type: "common",
  },
  {
    label: "ISSERPENT",
    value: "03131",
    type: "common",
  },
  {
    label: "SAINT-ETIENNE-DE-VICQ",
    value: "03230",
    type: "common",
  },
  {
    label: "BOST",
    value: "03033",
    type: "common",
  },
  {
    label: "SAINT-REMY-EN-ROLLAT",
    value: "03258",
    type: "common",
  },
  {
    label: "SAINT-GERMAIN-DES-FOSSES",
    value: "03236",
    type: "common",
  },
  {
    label: "CREUZIER-LE-VIEUX",
    value: "03094",
    type: "common",
  },
  {
    label: "CREUZIER-LE-NEUF",
    value: "03093",
    type: "common",
  },
  {
    label: "CHARMEIL",
    value: "03060",
    type: "common",
  },
  {
    label: "VENDAT",
    value: "03304",
    type: "common",
  },
  {
    label: "SAINT-PONT",
    value: "03252",
    type: "common",
  },
  {
    label: "SAINT-GERMAIN-DE-SALLES",
    value: "03237",
    type: "common",
  },
  {
    label: "LE MAYET-D'ECOLE",
    value: "03164",
    type: "common",
  },
  {
    label: "BROUT-VERNET",
    value: "03043",
    type: "common",
  },
  {
    label: "SAINT-BONNET-DE-ROCHEFORT",
    value: "03220",
    type: "common",
  },
  {
    label: "JENZAT",
    value: "03133",
    type: "common",
  },
  {
    label: "CHARROUX",
    value: "03062",
    type: "common",
  },
  {
    label: "VICQ",
    value: "03311",
    type: "common",
  },
  {
    label: "VEAUCE",
    value: "03302",
    type: "common",
  },
  {
    label: "VALIGNAT",
    value: "03295",
    type: "common",
  },
  {
    label: "SUSSAT",
    value: "03276",
    type: "common",
  },
  {
    label: "NAVES",
    value: "03194",
    type: "common",
  },
  {
    label: "BELLENAVES",
    value: "03022",
    type: "common",
  },
  {
    label: "NADES",
    value: "03192",
    type: "common",
  },
  {
    label: "LALIZOLLE",
    value: "03135",
    type: "common",
  },
  {
    label: "MOUREUILLE",
    value: "63243",
    type: "common",
  },
  {
    label: "DURMIGNAT",
    value: "63140",
    type: "common",
  },
  {
    label: "ECHASSIERES",
    value: "03108",
    type: "common",
  },
  {
    label: "SAINT-ELOY-LES-MINES",
    value: "63338",
    type: "common",
  },
  {
    label: "MONTAIGUT",
    value: "63233",
    type: "common",
  },
  {
    label: "BUXIERES-SOUS-MONTAIGUT",
    value: "63062",
    type: "common",
  },
  {
    label: "VIRLET",
    value: "63462",
    type: "common",
  },
  {
    label: "LA CROUZILLE",
    value: "63130",
    type: "common",
  },
  {
    label: "ARS-LES-FAVETS",
    value: "63011",
    type: "common",
  },
  {
    label: "RONNET",
    value: "03216",
    type: "common",
  },
  {
    label: "MARCILLAT-EN-COMBRAILLE",
    value: "03161",
    type: "common",
  },
  {
    label: "CHAMBONCHARD",
    value: "23046",
    type: "common",
  },
  {
    label: "SAINT-MARCEL-EN-MARCILLAT",
    value: "03244",
    type: "common",
  },
  {
    label: "LA PETITE-MARCHE",
    value: "03206",
    type: "common",
  },
  {
    label: "EVAUX-LES-BAINS",
    value: "23076",
    type: "common",
  },
  {
    label: "CHAMBON-SUR-VOUEIZE",
    value: "23045",
    type: "common",
  },
  {
    label: "LUSSAT",
    value: "23114",
    type: "common",
  },
  {
    label: "PIERREFITTE",
    value: "23152",
    type: "common",
  },
  {
    label: "GOUZON",
    value: "23093",
    type: "common",
  },
  {
    label: "PARSAC-RIMONDEIX",
    value: "23149",
    type: "common",
  },
  {
    label: "VIGEVILLE",
    value: "23262",
    type: "common",
  },
  {
    label: "JARNAGES",
    value: "23100",
    type: "common",
  },
  {
    label: "SAINT-LAURENT",
    value: "23206",
    type: "common",
  },
  {
    label: "PIONNAT",
    value: "23154",
    type: "common",
  },
  {
    label: "SAINTE-FEYRE",
    value: "23193",
    type: "common",
  },
  {
    label: "GUERET",
    value: "23096",
    type: "common",
  },
  {
    label: "SAINT-SULPICE-LE-GUERETOIS",
    value: "23245",
    type: "common",
  },
  {
    label: "SAINT-LEGER-LE-GUERETOIS",
    value: "23208",
    type: "common",
  },
  {
    label: "LA BRIONNE",
    value: "23033",
    type: "common",
  },
  {
    label: "SAINT-VAURY",
    value: "23247",
    type: "common",
  },
  {
    label: "GARTEMPE",
    value: "23088",
    type: "common",
  },
  {
    label: "LE GRAND-BOURG",
    value: "23095",
    type: "common",
  },
  {
    label: "SAINT-PRIEST-LA-FEUILLE",
    value: "23235",
    type: "common",
  },
  {
    label: "CHAMBORAND",
    value: "23047",
    type: "common",
  },
  {
    label: "SAINT-AMAND-MAGNAZEIX",
    value: "87133",
    type: "common",
  },
  {
    label: "FROMENTAL",
    value: "87068",
    type: "common",
  },
  {
    label: "SAINT-SORNIN-LEULAC",
    value: "87180",
    type: "common",
  },
  {
    label: "VILLEFAVARD",
    value: "87206",
    type: "common",
  },
  {
    label: "DROUX",
    value: "87061",
    type: "common",
  },
  {
    label: "SAINT-OUEN-SUR-GARTEMPE",
    value: "87172",
    type: "common",
  },
  {
    label: "LE DORAT",
    value: "87059",
    type: "common",
  },
  {
    label: "LA CROIX-SUR-GARTEMPE",
    value: "87052",
    type: "common",
  },
  {
    label: "SAINT-SORNIN-LA-MARCHE",
    value: "87179",
    type: "common",
  },
  {
    label: "SAINT-BONNET-DE-BELLAC",
    value: "87139",
    type: "common",
  },
  {
    label: "SAINT-MARTIAL-SUR-ISOP",
    value: "87163",
    type: "common",
  },
  {
    label: "LUCHAPT",
    value: "86138",
    type: "common",
  },
  {
    label: "ASNIERES-SUR-BLOUR",
    value: "86011",
    type: "common",
  },
  {
    label: "MILLAC",
    value: "86159",
    type: "common",
  },
  {
    label: "MAUPREVOIR",
    value: "86152",
    type: "common",
  },
  {
    label: "SAVIGNE",
    value: "86255",
    type: "common",
  },
  {
    label: "CHARROUX",
    value: "86061",
    type: "common",
  },
  {
    label: "SAINT-PIERRE-D'EXIDEUIL",
    value: "86237",
    type: "common",
  },
  {
    label: "CIVRAY",
    value: "86078",
    type: "common",
  },
  {
    label: "SAINT-SAVIOL",
    value: "86247",
    type: "common",
  },
  {
    label: "LINAZAY",
    value: "86134",
    type: "common",
  },
  {
    label: "LIMALONGES",
    value: "79150",
    type: "common",
  },
  {
    label: "PLIBOUX",
    value: "79212",
    type: "common",
  },
  {
    label: "MAIRE-LEVESCAULT",
    value: "79163",
    type: "common",
  },
  {
    label: "MELLERAN",
    value: "79175",
    type: "common",
  },
  {
    label: "LA CHAPELLE-POUILLOUX",
    value: "79074",
    type: "common",
  },
  {
    label: "ALLOINAY",
    value: "79136",
    type: "common",
  },
  {
    label: "LUSSERAY",
    value: "79160",
    type: "common",
  },
  {
    label: "LUCHE-SUR-BRIOUX",
    value: "79158",
    type: "common",
  },
  {
    label: "VERNOUX-SUR-BOUTONNE",
    value: "79343",
    type: "common",
  },
  {
    label: "BRIOUX-SUR-BOUTONNE",
    value: "79057",
    type: "common",
  },
  {
    label: "VILLEFOLLET",
    value: "79348",
    type: "common",
  },
  {
    label: "SELIGNE",
    value: "79312",
    type: "common",
  },
  {
    label: "SECONDIGNE-SUR-BELLE",
    value: "79310",
    type: "common",
  },
  {
    label: "BRIEUIL-SUR-CHIZE",
    value: "79055",
    type: "common",
  },
  {
    label: "VILLIERS-EN-BOIS",
    value: "79350",
    type: "common",
  },
  {
    label: "LES FOSSES",
    value: "79126",
    type: "common",
  },
  {
    label: "PLAINE-D'ARGENSON",
    value: "79078",
    type: "common",
  },
  {
    label: "DŒUIL-SUR-LE-MIGNON",
    value: "17139",
    type: "common",
  },
  {
    label: "SAINT-SATURNIN-DU-BOIS",
    value: "17394",
    type: "common",
  },
  {
    label: "MARSAIS",
    value: "17221",
    type: "common",
  },
  {
    label: "SAINT-GEORGES-DU-BOIS",
    value: "17338",
    type: "common",
  },
  {
    label: "VOUHE",
    value: "17482",
    type: "common",
  },
  {
    label: "PUYRAVAULT",
    value: "17293",
    type: "common",
  },
  {
    label: "CHAMBON",
    value: "17080",
    type: "common",
  },
  {
    label: "VIRSON",
    value: "17480",
    type: "common",
  },
  {
    label: "SAINT-CHRISTOPHE",
    value: "17315",
    type: "common",
  },
  {
    label: "FORGES",
    value: "17166",
    type: "common",
  },
  {
    label: "AIGREFEUILLE-D'AUNIS",
    value: "17003",
    type: "common",
  },
  {
    label: "SAINT-MEDARD-D'AUNIS",
    value: "17373",
    type: "common",
  },
  {
    label: "MONTROY",
    value: "17245",
    type: "common",
  },
  {
    label: "LA JARRIE",
    value: "17194",
    type: "common",
  },
  {
    label: "CROIX-CHAPEAU",
    value: "17136",
    type: "common",
  },
  {
    label: "SALLES-SUR-MER",
    value: "17420",
    type: "common",
  },
  {
    label: "SAINT-ROGATIEN",
    value: "17391",
    type: "common",
  },
  {
    label: "LA JARNE",
    value: "17193",
    type: "common",
  },
  {
    label: "CLAVETTE",
    value: "17109",
    type: "common",
  },
  {
    label: "AYTRE",
    value: "17028",
    type: "common",
  },
  {
    label: "ANGOULINS",
    value: "17010",
    type: "common",
  },
  {
    label: "VALLORCINE",
    value: "74290",
    type: "common",
  },
  {
    label: "SIXT-FER-A-CHEVAL",
    value: "74273",
    type: "common",
  },
  {
    label: "ARACHES-LA-FRASSE",
    value: "74014",
    type: "common",
  },
  {
    label: "MORILLON",
    value: "74190",
    type: "common",
  },
  {
    label: "SCIONZIER",
    value: "74264",
    type: "common",
  },
  {
    label: "NANCY-SUR-CLUSES",
    value: "74196",
    type: "common",
  },
  {
    label: "MARNAZ",
    value: "74169",
    type: "common",
  },
  {
    label: "CLUSES",
    value: "74081",
    type: "common",
  },
  {
    label: "VOUGY",
    value: "74312",
    type: "common",
  },
  {
    label: "MONT-SAXONNEX",
    value: "74189",
    type: "common",
  },
  {
    label: "BRIZON",
    value: "74049",
    type: "common",
  },
  {
    label: "SAINT-PIERRE-EN-FAUCIGNY",
    value: "74250",
    type: "common",
  },
  {
    label: "SAINT-LAURENT",
    value: "74244",
    type: "common",
  },
  {
    label: "BONNEVILLE",
    value: "74042",
    type: "common",
  },
  {
    label: "SAINT-SIXT",
    value: "74253",
    type: "common",
  },
  {
    label: "LA ROCHE-SUR-FORON",
    value: "74224",
    type: "common",
  },
  {
    label: "ETAUX",
    value: "74116",
    type: "common",
  },
  {
    label: "AMANCY",
    value: "74007",
    type: "common",
  },
  {
    label: "MENTHONNEX-EN-BORNES",
    value: "74177",
    type: "common",
  },
  {
    label: "LA CHAPELLE-RAMBAUD",
    value: "74059",
    type: "common",
  },
  {
    label: "VOVRAY-EN-BORNES",
    value: "74313",
    type: "common",
  },
  {
    label: "VILLY-LE-BOUVERET",
    value: "74306",
    type: "common",
  },
  {
    label: "SAINT-BLAISE",
    value: "74228",
    type: "common",
  },
  {
    label: "PRESILLY",
    value: "74216",
    type: "common",
  },
  {
    label: "CRUSEILLES",
    value: "74096",
    type: "common",
  },
  {
    label: "COPPONEX",
    value: "74088",
    type: "common",
  },
  {
    label: "CHAVANNAZ",
    value: "74066",
    type: "common",
  },
  {
    label: "CERNEX",
    value: "74052",
    type: "common",
  },
  {
    label: "ANDILLY",
    value: "74009",
    type: "common",
  },
  {
    label: "SAVIGNY",
    value: "74260",
    type: "common",
  },
  {
    label: "MINZIER",
    value: "74184",
    type: "common",
  },
  {
    label: "JONZIER-EPAGNY",
    value: "74144",
    type: "common",
  },
  {
    label: "DINGY-EN-VUACHE",
    value: "74101",
    type: "common",
  },
  {
    label: "CHAUMONT",
    value: "74065",
    type: "common",
  },
  {
    label: "ELOISE",
    value: "74109",
    type: "common",
  },
  {
    label: "CLARAFOND-ARCINE",
    value: "74077",
    type: "common",
  },
  {
    label: "CHESSENAZ",
    value: "74071",
    type: "common",
  },
  {
    label: "CHENE-EN-SEMINE",
    value: "74068",
    type: "common",
  },
  {
    label: "INJOUX-GENISSIAT",
    value: "01189",
    type: "common",
  },
  {
    label: "BILLIAT",
    value: "01044",
    type: "common",
  },
  {
    label: "SAINT-GERMAIN-SUR-RHONE",
    value: "74235",
    type: "common",
  },
  {
    label: "FRANCLENS",
    value: "74130",
    type: "common",
  },
  {
    label: "VILLES",
    value: "01448",
    type: "common",
  },
  {
    label: "BRENOD",
    value: "01060",
    type: "common",
  },
  {
    label: "VIEU-D'IZENAVE",
    value: "01441",
    type: "common",
  },
  {
    label: "OUTRIAZ",
    value: "01282",
    type: "common",
  },
  {
    label: "LANTENAY",
    value: "01206",
    type: "common",
  },
  {
    label: "SAINT-ALBAN",
    value: "01331",
    type: "common",
  },
  {
    label: "PONCIN",
    value: "01303",
    type: "common",
  },
  {
    label: "MERIGNAT",
    value: "01242",
    type: "common",
  },
  {
    label: "LABALME",
    value: "01200",
    type: "common",
  },
  {
    label: "JUJURIEUX",
    value: "01199",
    type: "common",
  },
  {
    label: "CERDON",
    value: "01068",
    type: "common",
  },
  {
    label: "SAINT-MARTIN-DU-MONT",
    value: "01374",
    type: "common",
  },
  {
    label: "PONT-D'AIN",
    value: "01304",
    type: "common",
  },
  {
    label: "NEUVILLE-SUR-AIN",
    value: "01273",
    type: "common",
  },
  {
    label: "LA TRANCLIERE",
    value: "01425",
    type: "common",
  },
  {
    label: "DRUILLAT",
    value: "01151",
    type: "common",
  },
  {
    label: "DOMPIERRE-SUR-VEYLE",
    value: "01145",
    type: "common",
  },
  {
    label: "SAINT-PAUL-DE-VARAX",
    value: "01383",
    type: "common",
  },
  {
    label: "SAINT-NIZIER-LE-DESERT",
    value: "01381",
    type: "common",
  },
  {
    label: "SAINT-GERMAIN-SUR-RENON",
    value: "01359",
    type: "common",
  },
  {
    label: "SAINT-GEORGES-SUR-RENON",
    value: "01356",
    type: "common",
  },
  {
    label: "LA CHAPELLE-DU-CHATELARD",
    value: "01085",
    type: "common",
  },
  {
    label: "RELEVANT",
    value: "01319",
    type: "common",
  },
  {
    label: "CHATILLON-SUR-CHALARONNE",
    value: "01093",
    type: "common",
  },
  {
    label: "VALEINS",
    value: "01428",
    type: "common",
  },
  {
    label: "SAINT-TRIVIER-SUR-MOIGNANS",
    value: "01389",
    type: "common",
  },
  {
    label: "CHANEINS",
    value: "01083",
    type: "common",
  },
  {
    label: "BANEINS",
    value: "01028",
    type: "common",
  },
  {
    label: "FRANCHELEINS",
    value: "01165",
    type: "common",
  },
  {
    label: "MONTMERLE-SUR-SAONE",
    value: "01263",
    type: "common",
  },
  {
    label: "MONTCEAUX",
    value: "01258",
    type: "common",
  },
  {
    label: "GUEREINS",
    value: "01183",
    type: "common",
  },
  {
    label: "GENOUILLEUX",
    value: "01169",
    type: "common",
  },
  {
    label: "SAINT-LAGER",
    value: "69218",
    type: "common",
  },
  {
    label: "CHARENTAY",
    value: "69045",
    type: "common",
  },
  {
    label: "LE PERREON",
    value: "69151",
    type: "common",
  },
  {
    label: "SAINT-ETIENNE-LA-VARENNE",
    value: "69198",
    type: "common",
  },
  {
    label: "QUINCIE-EN-BEAUJOLAIS",
    value: "69162",
    type: "common",
  },
  {
    label: "ODENAS",
    value: "69145",
    type: "common",
  },
  {
    label: "MARCHAMPT",
    value: "69124",
    type: "common",
  },
  {
    label: "CLAVEISOLLES",
    value: "69060",
    type: "common",
  },
  {
    label: "SAINT-NIZIER-D'AZERGUES",
    value: "69229",
    type: "common",
  },
  {
    label: "SAINT-BONNET-LE-TRONCY",
    value: "69183",
    type: "common",
  },
  {
    label: "RANCHAL",
    value: "69164",
    type: "common",
  },
  {
    label: "SAINT-VINCENT-DE-REINS",
    value: "69240",
    type: "common",
  },
  {
    label: "COURS",
    value: "69066",
    type: "common",
  },
  {
    label: "SEVELINGES",
    value: "42300",
    type: "common",
  },
  {
    label: "JARNOSSE",
    value: "42112",
    type: "common",
  },
  {
    label: "CUINZIER",
    value: "42079",
    type: "common",
  },
  {
    label: "LE CERGNE",
    value: "42033",
    type: "common",
  },
  {
    label: "VILLERS",
    value: "42333",
    type: "common",
  },
  {
    label: "SAINT-HILAIRE-SOUS-CHARLIEU",
    value: "42236",
    type: "common",
  },
  {
    label: "NANDAX",
    value: "42152",
    type: "common",
  },
  {
    label: "COUTOUVRE",
    value: "42074",
    type: "common",
  },
  {
    label: "BOYER",
    value: "42025",
    type: "common",
  },
  {
    label: "VOUGY",
    value: "42338",
    type: "common",
  },
  {
    label: "MABLY",
    value: "42127",
    type: "common",
  },
  {
    label: "BRIENNON",
    value: "42026",
    type: "common",
  },
  {
    label: "SAINT-ROMAIN-LA-MOTTE",
    value: "42284",
    type: "common",
  },
  {
    label: "SAINT-GERMAIN-LESPINASSE",
    value: "42231",
    type: "common",
  },
  {
    label: "AMBIERLE",
    value: "42003",
    type: "common",
  },
  {
    label: "SAINT-BONNET-DES-QUARTS",
    value: "42203",
    type: "common",
  },
  {
    label: "NIZEROLLES",
    value: "03201",
    type: "common",
  },
  {
    label: "CHATEL-MONTAGNE",
    value: "03066",
    type: "common",
  },
  {
    label: "MOLLES",
    value: "03174",
    type: "common",
  },
  {
    label: "LA CHAPELLE",
    value: "03056",
    type: "common",
  },
  {
    label: "LE VERNET",
    value: "03306",
    type: "common",
  },
  {
    label: "CUSSET",
    value: "03095",
    type: "common",
  },
  {
    label: "VICHY",
    value: "03310",
    type: "common",
  },
  {
    label: "BELLERIVE-SUR-ALLIER",
    value: "03023",
    type: "common",
  },
  {
    label: "ABREST",
    value: "03001",
    type: "common",
  },
  {
    label: "SERBANNES",
    value: "03271",
    type: "common",
  },
  {
    label: "ESPINASSE-VOZELLE",
    value: "03110",
    type: "common",
  },
  {
    label: "COGNAT-LYONNE",
    value: "03080",
    type: "common",
  },
  {
    label: "SAULZET",
    value: "03268",
    type: "common",
  },
  {
    label: "MONTEIGNET-SUR-L'ANDELOT",
    value: "03182",
    type: "common",
  },
  {
    label: "ESCUROLLES",
    value: "03109",
    type: "common",
  },
  {
    label: "MAZERIER",
    value: "03166",
    type: "common",
  },
  {
    label: "GANNAT",
    value: "03118",
    type: "common",
  },
  {
    label: "BEGUES",
    value: "03021",
    type: "common",
  },
  {
    label: "SAINT-QUINTIN-SUR-SIOULE",
    value: "63390",
    type: "common",
  },
  {
    label: "EBREUIL",
    value: "03107",
    type: "common",
  },
  {
    label: "SAINT-GAL-SUR-SIOULE",
    value: "63344",
    type: "common",
  },
  {
    label: "POUZOL",
    value: "63286",
    type: "common",
  },
  {
    label: "CHOUVIGNY",
    value: "03078",
    type: "common",
  },
  {
    label: "SERVANT",
    value: "63419",
    type: "common",
  },
  {
    label: "NEUF-EGLISE",
    value: "63251",
    type: "common",
  },
  {
    label: "MENAT",
    value: "63223",
    type: "common",
  },
  {
    label: "YOUX",
    value: "63471",
    type: "common",
  },
  {
    label: "TEILHET",
    value: "63428",
    type: "common",
  },
  {
    label: "LE QUARTIER",
    value: "63293",
    type: "common",
  },
  {
    label: "LA CELLETTE",
    value: "63067",
    type: "common",
  },
  {
    label: "SAINT-HILAIRE",
    value: "63360",
    type: "common",
  },
  {
    label: "PIONSAT",
    value: "63281",
    type: "common",
  },
  {
    label: "SAINT-FARGEOL",
    value: "03231",
    type: "common",
  },
  {
    label: "CHATEAU-SUR-CHER",
    value: "63101",
    type: "common",
  },
  {
    label: "SAINT-JULIEN-LA-GENETE",
    value: "23203",
    type: "common",
  },
  {
    label: "RETERRE",
    value: "23160",
    type: "common",
  },
  {
    label: "FONTANIERES",
    value: "23083",
    type: "common",
  },
  {
    label: "SANNAT",
    value: "23167",
    type: "common",
  },
  {
    label: "TARDES",
    value: "23251",
    type: "common",
  },
  {
    label: "SAINT-LOUP",
    value: "23209",
    type: "common",
  },
  {
    label: "SAINT-JULIEN-LE-CHATEL",
    value: "23204",
    type: "common",
  },
  {
    label: "LE CHAUCHET",
    value: "23058",
    type: "common",
  },
  {
    label: "SAINT-CHABRAIS",
    value: "23185",
    type: "common",
  },
  {
    label: "SAINT-PARDOUX-LES-CARDS",
    value: "23229",
    type: "common",
  },
  {
    label: "SAINT-DIZIER-LA-TOUR",
    value: "23187",
    type: "common",
  },
  {
    label: "CHENERAILLES",
    value: "23061",
    type: "common",
  },
  {
    label: "MOUTIER-D'AHUN",
    value: "23138",
    type: "common",
  },
  {
    label: "CRESSAT",
    value: "23068",
    type: "common",
  },
  {
    label: "SAINT-YRIEIX-LES-BOIS",
    value: "23250",
    type: "common",
  },
  {
    label: "SAINT-HILAIRE-LA-PLAINE",
    value: "23201",
    type: "common",
  },
  {
    label: "LA SAUNIERE",
    value: "23169",
    type: "common",
  },
  {
    label: "MAZEIRAT",
    value: "23128",
    type: "common",
  },
  {
    label: "SAINT-CHRISTOPHE",
    value: "23186",
    type: "common",
  },
  {
    label: "SAVENNES",
    value: "23170",
    type: "common",
  },
  {
    label: "PEYRABOUT",
    value: "23150",
    type: "common",
  },
  {
    label: "SAINT-VICTOR-EN-MARCHE",
    value: "23248",
    type: "common",
  },
  {
    label: "LA CHAPELLE-TAILLEFERT",
    value: "23052",
    type: "common",
  },
  {
    label: "SAINT-SILVAIN-MONTAIGUT",
    value: "23242",
    type: "common",
  },
  {
    label: "MONTAIGUT-LE-BLANC",
    value: "23132",
    type: "common",
  },
  {
    label: "AULON",
    value: "23011",
    type: "common",
  },
  {
    label: "BENEVENT-L'ABBAYE",
    value: "23021",
    type: "common",
  },
  {
    label: "FURSAC",
    value: "23192",
    type: "common",
  },
  {
    label: "MARSAC",
    value: "23124",
    type: "common",
  },
  {
    label: "FOLLES",
    value: "87067",
    type: "common",
  },
  {
    label: "BESSINES-SUR-GARTEMPE",
    value: "87014",
    type: "common",
  },
  {
    label: "CHATEAUPONSAC",
    value: "87041",
    type: "common",
  },
  {
    label: "RANCON",
    value: "87121",
    type: "common",
  },
  {
    label: "BALLEDENT",
    value: "87007",
    type: "common",
  },
  {
    label: "SAINT-JUNIEN-LES-COMBES",
    value: "87155",
    type: "common",
  },
  {
    label: "BLANZAC",
    value: "87017",
    type: "common",
  },
  {
    label: "BELLAC",
    value: "87011",
    type: "common",
  },
  {
    label: "PEYRAT-DE-BELLAC",
    value: "87116",
    type: "common",
  },
  {
    label: "VAL D'ISSOIRE",
    value: "87097",
    type: "common",
  },
  {
    label: "GAJOUBERT",
    value: "87069",
    type: "common",
  },
  {
    label: "ORADOUR-FANAIS",
    value: "16249",
    type: "common",
  },
  {
    label: "ABZAC",
    value: "16001",
    type: "common",
  },
  {
    label: "AVAILLES-LIMOUZINE",
    value: "86015",
    type: "common",
  },
  {
    label: "PRESSAC",
    value: "86200",
    type: "common",
  },
  {
    label: "CHATAIN",
    value: "86063",
    type: "common",
  },
  {
    label: "PLEUVILLE",
    value: "16264",
    type: "common",
  },
  {
    label: "SURIN",
    value: "86266",
    type: "common",
  },
  {
    label: "GENOUILLE",
    value: "86104",
    type: "common",
  },
  {
    label: "ASNOIS",
    value: "86012",
    type: "common",
  },
  {
    label: "SAINT-GAUDENT",
    value: "86220",
    type: "common",
  },
  {
    label: "LIZANT",
    value: "86136",
    type: "common",
  },
  {
    label: "VOULEME",
    value: "86295",
    type: "common",
  },
  {
    label: "SAINT-MACOUX",
    value: "86231",
    type: "common",
  },
  {
    label: "MONTALEMBERT",
    value: "79180",
    type: "common",
  },
  {
    label: "LES ADJOTS",
    value: "16002",
    type: "common",
  },
  {
    label: "SAUZE-VAUSSAIS",
    value: "79307",
    type: "common",
  },
  {
    label: "MONTJEAN",
    value: "16229",
    type: "common",
  },
  {
    label: "LONDIGNY",
    value: "16189",
    type: "common",
  },
  {
    label: "LA FORET-DE-TESSE",
    value: "16142",
    type: "common",
  },
  {
    label: "LORIGNE",
    value: "79152",
    type: "common",
  },
  {
    label: "LOUBIGNE",
    value: "79153",
    type: "common",
  },
  {
    label: "PAIZAY-LE-CHAPT",
    value: "79198",
    type: "common",
  },
  {
    label: "FONTENILLE-SAINT-MARTIN-D'ENTRAIGUES",
    value: "79122",
    type: "common",
  },
  {
    label: "CHERIGNE",
    value: "79085",
    type: "common",
  },
  {
    label: "JUILLE",
    value: "79142",
    type: "common",
  },
  {
    label: "ENSIGNE",
    value: "79111",
    type: "common",
  },
  {
    label: "ASNIERES-EN-POITOU",
    value: "79015",
    type: "common",
  },
  {
    label: "VILLIERS-SUR-CHIZE",
    value: "79352",
    type: "common",
  },
  {
    label: "LA VILLEDIEU",
    value: "17471",
    type: "common",
  },
  {
    label: "LE VERT",
    value: "79346",
    type: "common",
  },
  {
    label: "CHIZE",
    value: "79090",
    type: "common",
  },
  {
    label: "SAINT-SEVERIN-SUR-BOUTONNE",
    value: "17401",
    type: "common",
  },
  {
    label: "DAMPIERRE-SUR-BOUTONNE",
    value: "17138",
    type: "common",
  },
  {
    label: "VILLENEUVE-LA-COMTESSE",
    value: "17474",
    type: "common",
  },
  {
    label: "VERGNE",
    value: "17464",
    type: "common",
  },
  {
    label: "LA CROIX-COMTESSE",
    value: "17137",
    type: "common",
  },
  {
    label: "COIVERT",
    value: "17114",
    type: "common",
  },
  {
    label: "MIGRE",
    value: "17234",
    type: "common",
  },
  {
    label: "SAINT-FELIX",
    value: "17327",
    type: "common",
  },
  {
    label: "BERNAY-SAINT-MARTIN",
    value: "17043",
    type: "common",
  },
  {
    label: "LA DEVISE",
    value: "17457",
    type: "common",
  },
  {
    label: "SURGERES",
    value: "17434",
    type: "common",
  },
  {
    label: "SAINT-MARD",
    value: "17359",
    type: "common",
  },
  {
    label: "BREUIL-LA-REORTE",
    value: "17063",
    type: "common",
  },
  {
    label: "SAINT-PIERRE-LA-NOUE",
    value: "17340",
    type: "common",
  },
  {
    label: "LANDRAIS",
    value: "17203",
    type: "common",
  },
  {
    label: "LE THOU",
    value: "17447",
    type: "common",
  },
  {
    label: "ARDILLIERES",
    value: "17018",
    type: "common",
  },
  {
    label: "THAIRE",
    value: "17443",
    type: "common",
  },
  {
    label: "BALLON",
    value: "17032",
    type: "common",
  },
  {
    label: "SAINT-VIVIEN",
    value: "17413",
    type: "common",
  },
  {
    label: "CHATELAILLON-PLAGE",
    value: "17094",
    type: "common",
  },
  {
    label: "CHAMONIX-MONT-BLANC",
    value: "74056",
    type: "common",
  },
  {
    label: "SERVOZ",
    value: "74266",
    type: "common",
  },
  {
    label: "MAGLAND",
    value: "74159",
    type: "common",
  },
  {
    label: "LE REPOSOIR",
    value: "74221",
    type: "common",
  },
  {
    label: "LE GRAND-BORNAND",
    value: "74136",
    type: "common",
  },
  {
    label: "FILLIERE",
    value: "74282",
    type: "common",
  },
  {
    label: "GROISY",
    value: "74137",
    type: "common",
  },
  {
    label: "VILLY-LE-PELLOUX",
    value: "74307",
    type: "common",
  },
  {
    label: "CUVAT",
    value: "74098",
    type: "common",
  },
  {
    label: "CHARVONNEX",
    value: "74062",
    type: "common",
  },
  {
    label: "ALLONZIER-LA-CAILLE",
    value: "74006",
    type: "common",
  },
  {
    label: "MESIGNY",
    value: "74179",
    type: "common",
  },
  {
    label: "MARLIOZ",
    value: "74168",
    type: "common",
  },
  {
    label: "CHOISY",
    value: "74076",
    type: "common",
  },
  {
    label: "CERCIER",
    value: "74051",
    type: "common",
  },
  {
    label: "SALLENOVES",
    value: "74257",
    type: "common",
  },
  {
    label: "MUSIEGES",
    value: "74195",
    type: "common",
  },
  {
    label: "FRANGY",
    value: "74131",
    type: "common",
  },
  {
    label: "CONTAMINE-SARZIN",
    value: "74086",
    type: "common",
  },
  {
    label: "CHILLY",
    value: "74075",
    type: "common",
  },
  {
    label: "VANZY",
    value: "74291",
    type: "common",
  },
  {
    label: "USINENS",
    value: "74285",
    type: "common",
  },
  {
    label: "DESINGY",
    value: "74100",
    type: "common",
  },
  {
    label: "BASSY",
    value: "74029",
    type: "common",
  },
  {
    label: "CHANAY",
    value: "01082",
    type: "common",
  },
  {
    label: "CHALLONGES",
    value: "74055",
    type: "common",
  },
  {
    label: "HAUT VALROMEY",
    value: "01187",
    type: "common",
  },
  {
    label: "RUFFIEU",
    value: "01330",
    type: "common",
  },
  {
    label: "CHAMPDOR-CORCELLES",
    value: "01080",
    type: "common",
  },
  {
    label: "IZENAVE",
    value: "01191",
    type: "common",
  },
  {
    label: "CORLIER",
    value: "01121",
    type: "common",
  },
  {
    label: "ARANC",
    value: "01012",
    type: "common",
  },
  {
    label: "NIVOLLET-MONTGRIFFON",
    value: "01277",
    type: "common",
  },
  {
    label: "BOYEUX-SAINT-JEROME",
    value: "01056",
    type: "common",
  },
  {
    label: "L'ABERGEMENT-DE-VAREY",
    value: "01002",
    type: "common",
  },
  {
    label: "SAINT-JEAN-LE-VIEUX",
    value: "01363",
    type: "common",
  },
  {
    label: "AMBRONAY",
    value: "01007",
    type: "common",
  },
  {
    label: "VARAMBON",
    value: "01430",
    type: "common",
  },
  {
    label: "PRIAY",
    value: "01314",
    type: "common",
  },
  {
    label: "VILLETTE-SUR-AIN",
    value: "01449",
    type: "common",
  },
  {
    label: "CHATENAY",
    value: "01090",
    type: "common",
  },
  {
    label: "CHALAMONT",
    value: "01074",
    type: "common",
  },
  {
    label: "LE PLANTAY",
    value: "01299",
    type: "common",
  },
  {
    label: "MARLIEUX",
    value: "01235",
    type: "common",
  },
  {
    label: "SANDRANS",
    value: "01393",
    type: "common",
  },
  {
    label: "BOULIGNEUX",
    value: "01052",
    type: "common",
  },
  {
    label: "SAINTE-OLIVE",
    value: "01382",
    type: "common",
  },
  {
    label: "VILLENEUVE",
    value: "01446",
    type: "common",
  },
  {
    label: "MESSIMY-SUR-SAONE",
    value: "01243",
    type: "common",
  },
  {
    label: "LURCY",
    value: "01225",
    type: "common",
  },
  {
    label: "FAREINS",
    value: "01157",
    type: "common",
  },
  {
    label: "CHALEINS",
    value: "01075",
    type: "common",
  },
  {
    label: "SAINT-JULIEN",
    value: "69215",
    type: "common",
  },
  {
    label: "ARNAS",
    value: "69013",
    type: "common",
  },
  {
    label: "SAINT-GEORGES-DE-RENEINS",
    value: "69206",
    type: "common",
  },
  {
    label: "SAINT-ETIENNE-DES-OULLIERES",
    value: "69197",
    type: "common",
  },
  {
    label: "VAUX-EN-BEAUJOLAIS",
    value: "69257",
    type: "common",
  },
  {
    label: "SALLES-ARBUISSONNAS-EN-BEAUJOLAIS",
    value: "69172",
    type: "common",
  },
  {
    label: "RIVOLET",
    value: "69167",
    type: "common",
  },
  {
    label: "MONTMELAS-SAINT-SORLIN",
    value: "69137",
    type: "common",
  },
  {
    label: "BLACE",
    value: "69023",
    type: "common",
  },
  {
    label: "SAINT-CYR-LE-CHATOUX",
    value: "69192",
    type: "common",
  },
  {
    label: "LAMURE-SUR-AZERGUES",
    value: "69107",
    type: "common",
  },
  {
    label: "MEAUX-LA-MONTAGNE",
    value: "69130",
    type: "common",
  },
  {
    label: "GRANDRIS",
    value: "69093",
    type: "common",
  },
  {
    label: "THIZY-LES-BOURGS",
    value: "69248",
    type: "common",
  },
  {
    label: "CUBLIZE",
    value: "69070",
    type: "common",
  },
  {
    label: "MONTAGNY",
    value: "42145",
    type: "common",
  },
  {
    label: "LA GRESLE",
    value: "42104",
    type: "common",
  },
  {
    label: "COMBRE",
    value: "42068",
    type: "common",
  },
  {
    label: "PERREUX",
    value: "42170",
    type: "common",
  },
  {
    label: "ROANNE",
    value: "42187",
    type: "common",
  },
  {
    label: "SAINT-LEGER-SUR-ROANNE",
    value: "42253",
    type: "common",
  },
  {
    label: "RIORGES",
    value: "42184",
    type: "common",
  },
  {
    label: "POUILLY-LES-NONAINS",
    value: "42176",
    type: "common",
  },
  {
    label: "SAINT-HAON-LE-VIEUX",
    value: "42233",
    type: "common",
  },
  {
    label: "SAINT-HAON-LE-CHATEL",
    value: "42232",
    type: "common",
  },
  {
    label: "SAINT-ANDRE-D'APCHON",
    value: "42199",
    type: "common",
  },
  {
    label: "RENAISON",
    value: "42182",
    type: "common",
  },
  {
    label: "SAINT-RIRAND",
    value: "42281",
    type: "common",
  },
  {
    label: "LES NOES",
    value: "42158",
    type: "common",
  },
  {
    label: "SAINT-NICOLAS-DES-BIEFS",
    value: "03248",
    type: "common",
  },
  {
    label: "SAINT-CLEMENT",
    value: "03224",
    type: "common",
  },
  {
    label: "LA CHABANNE",
    value: "03050",
    type: "common",
  },
  {
    label: "LE MAYET-DE-MONTAGNE",
    value: "03165",
    type: "common",
  },
  {
    label: "FERRIERES-SUR-SICHON",
    value: "03113",
    type: "common",
  },
  {
    label: "ARRONNES",
    value: "03008",
    type: "common",
  },
  {
    label: "SAINT-YORRE",
    value: "03264",
    type: "common",
  },
  {
    label: "MARIOL",
    value: "03163",
    type: "common",
  },
  {
    label: "BUSSET",
    value: "03045",
    type: "common",
  },
  {
    label: "SAINT-SYLVESTRE-PRAGOULIN",
    value: "63400",
    type: "common",
  },
  {
    label: "SAINT-PRIEST-BRAMEFANT",
    value: "63387",
    type: "common",
  },
  {
    label: "HAUTERIVE",
    value: "03126",
    type: "common",
  },
  {
    label: "BRUGHEAS",
    value: "03044",
    type: "common",
  },
  {
    label: "BAS-ET-LEZAT",
    value: "63030",
    type: "common",
  },
  {
    label: "BIOZAT",
    value: "03030",
    type: "common",
  },
  {
    label: "SAINT-GENES-DU-RETZ",
    value: "63347",
    type: "common",
  },
  {
    label: "MONTPENSIER",
    value: "63240",
    type: "common",
  },
  {
    label: "EFFIAT",
    value: "63143",
    type: "common",
  },
  {
    label: "POEZAT",
    value: "03209",
    type: "common",
  },
  {
    label: "CHARMES",
    value: "03061",
    type: "common",
  },
  {
    label: "VENSAT",
    value: "63446",
    type: "common",
  },
  {
    label: "SAINT-AGOULIN",
    value: "63311",
    type: "common",
  },
  {
    label: "CHAPTUZAT",
    value: "63090",
    type: "common",
  },
  {
    label: "SAINT-PRIEST-D'ANDELOT",
    value: "03255",
    type: "common",
  },
  {
    label: "SAINT-HILAIRE-LA-CROIX",
    value: "63358",
    type: "common",
  },
  {
    label: "CHAMPS",
    value: "63082",
    type: "common",
  },
  {
    label: "SAINT-PARDOUX",
    value: "63382",
    type: "common",
  },
  {
    label: "MARCILLAT",
    value: "63208",
    type: "common",
  },
  {
    label: "SAINT-REMY-DE-BLOT",
    value: "63391",
    type: "common",
  },
  {
    label: "LISSEUIL",
    value: "63197",
    type: "common",
  },
  {
    label: "BLOT-L'EGLISE",
    value: "63043",
    type: "common",
  },
  {
    label: "AYAT-SUR-SIOULE",
    value: "63025",
    type: "common",
  },
  {
    label: "SAINTE-CHRISTINE",
    value: "63329",
    type: "common",
  },
  {
    label: "SAINT-JULIEN-LA-GENESTE",
    value: "63369",
    type: "common",
  },
  {
    label: "GOUTTIERES",
    value: "63171",
    type: "common",
  },
  {
    label: "ESPINASSE",
    value: "63152",
    type: "common",
  },
  {
    label: "SAINT-MAIGNER",
    value: "63373",
    type: "common",
  },
  {
    label: "ROCHE-D'AGOUX",
    value: "63304",
    type: "common",
  },
  {
    label: "BUSSIERES",
    value: "63060",
    type: "common",
  },
  {
    label: "SAINT-MAURICE-PRES-PIONSAT",
    value: "63377",
    type: "common",
  },
  {
    label: "ROUGNAT",
    value: "23164",
    type: "common",
  },
  {
    label: "MAINSAT",
    value: "23116",
    type: "common",
  },
  {
    label: "ARFEUILLE-CHATAIN",
    value: "23005",
    type: "common",
  },
  {
    label: "SAINT-PRIEST",
    value: "23234",
    type: "common",
  },
  {
    label: "SAINT-DOMET",
    value: "23190",
    type: "common",
  },
  {
    label: "LA SERRE-BUSSIERE-VIEILLE",
    value: "23172",
    type: "common",
  },
  {
    label: "PUY-MALSIGNAT",
    value: "23159",
    type: "common",
  },
  {
    label: "PEYRAT-LA-NONIERE",
    value: "23151",
    type: "common",
  },
  {
    label: "ISSOUDUN-LETRIEIX",
    value: "23097",
    type: "common",
  },
  {
    label: "SAINT-MARTIAL-LE-MONT",
    value: "23214",
    type: "common",
  },
  {
    label: "LAVAVEIX-LES-MINES",
    value: "23105",
    type: "common",
  },
  {
    label: "CHAMBERAUD",
    value: "23043",
    type: "common",
  },
  {
    label: "AHUN",
    value: "23001",
    type: "common",
  },
  {
    label: "SOUS-PARSAT",
    value: "23175",
    type: "common",
  },
  {
    label: "LE DONZEIL",
    value: "23074",
    type: "common",
  },
  {
    label: "MAISONNISSES",
    value: "23118",
    type: "common",
  },
  {
    label: "LEPINAS",
    value: "23107",
    type: "common",
  },
  {
    label: "SAINT-ELOI",
    value: "23191",
    type: "common",
  },
  {
    label: "SARDENT",
    value: "23168",
    type: "common",
  },
  {
    label: "AZAT-CHATENET",
    value: "23014",
    type: "common",
  },
  {
    label: "JANAILLAT",
    value: "23099",
    type: "common",
  },
  {
    label: "AUGERES",
    value: "23010",
    type: "common",
  },
  {
    label: "MOURIOUX-VIEILLEVILLE",
    value: "23137",
    type: "common",
  },
  {
    label: "CEYROUX",
    value: "23042",
    type: "common",
  },
  {
    label: "SAINT-GOUSSAUD",
    value: "23200",
    type: "common",
  },
  {
    label: "ARRENES",
    value: "23006",
    type: "common",
  },
  {
    label: "SAINT-SULPICE-LAURIERE",
    value: "87181",
    type: "common",
  },
  {
    label: "LAURIERE",
    value: "87083",
    type: "common",
  },
  {
    label: "SAINT-LEGER-LA-MONTAGNE",
    value: "87159",
    type: "common",
  },
  {
    label: "BERSAC-SUR-RIVALIER",
    value: "87013",
    type: "common",
  },
  {
    label: "RAZES",
    value: "87122",
    type: "common",
  },
  {
    label: "LE BUIS",
    value: "87023",
    type: "common",
  },
  {
    label: "BREUILAUFA",
    value: "87022",
    type: "common",
  },
  {
    label: "BERNEUIL",
    value: "87012",
    type: "common",
  },
  {
    label: "BLOND",
    value: "87018",
    type: "common",
  },
  {
    label: "MORTEMART",
    value: "87101",
    type: "common",
  },
  {
    label: "NOUIC",
    value: "87108",
    type: "common",
  },
  {
    label: "BRILLAC",
    value: "16065",
    type: "common",
  },
  {
    label: "ESSE",
    value: "16131",
    type: "common",
  },
  {
    label: "LESSAC",
    value: "16181",
    type: "common",
  },
  {
    label: "CONFOLENS",
    value: "16106",
    type: "common",
  },
  {
    label: "HIESSE",
    value: "16164",
    type: "common",
  },
  {
    label: "EPENEDE",
    value: "16128",
    type: "common",
  },
  {
    label: "BENEST",
    value: "16038",
    type: "common",
  },
  {
    label: "VIEUX-RUFFEC",
    value: "16404",
    type: "common",
  },
  {
    label: "LE BOUCHAGE",
    value: "16054",
    type: "common",
  },
  {
    label: "TAIZE-AIZIE",
    value: "16378",
    type: "common",
  },
  {
    label: "BIOUSSAC",
    value: "16044",
    type: "common",
  },
  {
    label: "RUFFEC",
    value: "16292",
    type: "common",
  },
  {
    label: "CONDAC",
    value: "16104",
    type: "common",
  },
  {
    label: "BERNAC",
    value: "16039",
    type: "common",
  },
  {
    label: "VILLIERS-LE-ROUX",
    value: "16413",
    type: "common",
  },
  {
    label: "VILLEFAGNAN",
    value: "16409",
    type: "common",
  },
  {
    label: "SAINT-MARTIN-DU-CLOCHER",
    value: "16335",
    type: "common",
  },
  {
    label: "LA MAGDELEINE",
    value: "16197",
    type: "common",
  },
  {
    label: "LA FAYE",
    value: "16136",
    type: "common",
  },
  {
    label: "LA CHEVRERIE",
    value: "16098",
    type: "common",
  },
  {
    label: "THEIL-RABIER",
    value: "16381",
    type: "common",
  },
  {
    label: "PAIZAY-NAUDOUIN-EMBOURIE",
    value: "16253",
    type: "common",
  },
  {
    label: "EMPURE",
    value: "16127",
    type: "common",
  },
  {
    label: "LOUBILLE",
    value: "79154",
    type: "common",
  },
  {
    label: "VILLEMAIN",
    value: "79349",
    type: "common",
  },
  {
    label: "AUBIGNE",
    value: "79018",
    type: "common",
  },
  {
    label: "VINAX",
    value: "17478",
    type: "common",
  },
  {
    label: "SALEIGNES",
    value: "17416",
    type: "common",
  },
  {
    label: "CONTRE",
    value: "17117",
    type: "common",
  },
  {
    label: "SAINT-MANDE-SUR-BREDOIRE",
    value: "17358",
    type: "common",
  },
  {
    label: "AULNAY",
    value: "17024",
    type: "common",
  },
  {
    label: "SAINT-GEORGES-DE-LONGUEPIERRE",
    value: "17334",
    type: "common",
  },
  {
    label: "NUAILLE-SUR-BOUTONNE",
    value: "17268",
    type: "common",
  },
  {
    label: "BLANZAY-SUR-BOUTONNE",
    value: "17049",
    type: "common",
  },
  {
    label: "SAINT-PIERRE-DE-L'ISLE",
    value: "17384",
    type: "common",
  },
  {
    label: "SAINT-PARDOULT",
    value: "17381",
    type: "common",
  },
  {
    label: "SAINT-MARTIAL",
    value: "17361",
    type: "common",
  },
  {
    label: "LOULAY",
    value: "17211",
    type: "common",
  },
  {
    label: "LA JARRIE-AUDOUIN",
    value: "17195",
    type: "common",
  },
  {
    label: "ESSOUVERT",
    value: "17277",
    type: "common",
  },
  {
    label: "LOZAY",
    value: "17213",
    type: "common",
  },
  {
    label: "COURANT",
    value: "17124",
    type: "common",
  },
  {
    label: "SAINT-LOUP",
    value: "17356",
    type: "common",
  },
  {
    label: "PUYROLLAND",
    value: "17294",
    type: "common",
  },
  {
    label: "NACHAMPS",
    value: "17254",
    type: "common",
  },
  {
    label: "SAINT-CREPIN",
    value: "17321",
    type: "common",
  },
  {
    label: "ANNEZAY",
    value: "17012",
    type: "common",
  },
  {
    label: "GENOUILLE",
    value: "17174",
    type: "common",
  },
  {
    label: "MURON",
    value: "17253",
    type: "common",
  },
  {
    label: "LOIRE-LES-MARAIS",
    value: "17205",
    type: "common",
  },
  {
    label: "CIRE-D'AUNIS",
    value: "17107",
    type: "common",
  },
  {
    label: "SAINT-LAURENT-DE-LA-PREE",
    value: "17353",
    type: "common",
  },
  {
    label: "BREUIL-MAGNE",
    value: "17065",
    type: "common",
  },
  {
    label: "YVES",
    value: "17483",
    type: "common",
  },
  {
    label: "FOURAS",
    value: "17168",
    type: "common",
  },
  {
    label: "ILE-D'AIX",
    value: "17004",
    type: "common",
  },
  {
    label: "LA BREE-LES-BAINS",
    value: "17486",
    type: "common",
  },
  {
    label: "SAINT-GEORGES-D'OLERON",
    value: "17337",
    type: "common",
  },
  {
    label: "SAINT-DENIS-D'OLERON",
    value: "17323",
    type: "common",
  },
  {
    label: "LES HOUCHES",
    value: "74143",
    type: "common",
  },
  {
    label: "PASSY",
    value: "74208",
    type: "common",
  },
  {
    label: "SALLANCHES",
    value: "74256",
    type: "common",
  },
  {
    label: "DOMANCY",
    value: "74103",
    type: "common",
  },
  {
    label: "COMBLOUX",
    value: "74083",
    type: "common",
  },
  {
    label: "CORDON",
    value: "74089",
    type: "common",
  },
  {
    label: "LA CLUSAZ",
    value: "74080",
    type: "common",
  },
  {
    label: "LES VILLARDS-SUR-THONES",
    value: "74302",
    type: "common",
  },
  {
    label: "SAINT-JEAN-DE-SIXT",
    value: "74239",
    type: "common",
  },
  {
    label: "DINGY-SAINT-CLAIR",
    value: "74102",
    type: "common",
  },
  {
    label: "LA BALME-DE-THUY",
    value: "74027",
    type: "common",
  },
  {
    label: "VILLAZ",
    value: "74303",
    type: "common",
  },
  {
    label: "NAVES-PARMELAN",
    value: "74198",
    type: "common",
  },
  {
    label: "EPAGNY METZ-TESSY",
    value: "74112",
    type: "common",
  },
  {
    label: "ARGONAY",
    value: "74019",
    type: "common",
  },
  {
    label: "VAULX",
    value: "74292",
    type: "common",
  },
  {
    label: "SILLINGY",
    value: "74272",
    type: "common",
  },
  {
    label: "POISY",
    value: "74213",
    type: "common",
  },
  {
    label: "NONGLARD",
    value: "74202",
    type: "common",
  },
  {
    label: "LA BALME-DE-SILLINGY",
    value: "74026",
    type: "common",
  },
  {
    label: "VERSONNEX",
    value: "74297",
    type: "common",
  },
  {
    label: "THUSY",
    value: "74283",
    type: "common",
  },
  {
    label: "SAINT-EUSEBE",
    value: "74231",
    type: "common",
  },
  {
    label: "MENTHONNEX-SOUS-CLERMONT",
    value: "74178",
    type: "common",
  },
  {
    label: "SEYSSEL",
    value: "01407",
    type: "common",
  },
  {
    label: "SEYSSEL",
    value: "74269",
    type: "common",
  },
  {
    label: "DROISY",
    value: "74107",
    type: "common",
  },
  {
    label: "CREMPIGNY-BONNEGUETE",
    value: "74095",
    type: "common",
  },
  {
    label: "CLERMONT",
    value: "74078",
    type: "common",
  },
  {
    label: "MOTZ",
    value: "73180",
    type: "common",
  },
  {
    label: "CORBONOD",
    value: "01118",
    type: "common",
  },
  {
    label: "CHAMPAGNE-EN-VALROMEY",
    value: "01079",
    type: "common",
  },
  {
    label: "EVOSGES",
    value: "01155",
    type: "common",
  },
  {
    label: "CHALEY",
    value: "01076",
    type: "common",
  },
  {
    label: "SAINT-RAMBERT-EN-BUGEY",
    value: "01384",
    type: "common",
  },
  {
    label: "ONCIEU",
    value: "01279",
    type: "common",
  },
  {
    label: "ARGIS",
    value: "01017",
    type: "common",
  },
  {
    label: "SAINT-DENIS-EN-BUGEY",
    value: "01345",
    type: "common",
  },
  {
    label: "DOUVRES",
    value: "01149",
    type: "common",
  },
  {
    label: "BETTANT",
    value: "01041",
    type: "common",
  },
  {
    label: "AMBUTRIX",
    value: "01008",
    type: "common",
  },
  {
    label: "AMBERIEU-EN-BUGEY",
    value: "01004",
    type: "common",
  },
  {
    label: "SAINT-MAURICE-DE-REMENS",
    value: "01379",
    type: "common",
  },
  {
    label: "CHATILLON-LA-PALUD",
    value: "01092",
    type: "common",
  },
  {
    label: "CHATEAU-GAILLARD",
    value: "01089",
    type: "common",
  },
  {
    label: "RIGNIEUX-LE-FRANC",
    value: "01325",
    type: "common",
  },
  {
    label: "CRANS",
    value: "01129",
    type: "common",
  },
  {
    label: "VERSAILLEUX",
    value: "01434",
    type: "common",
  },
  {
    label: "JOYEUX",
    value: "01198",
    type: "common",
  },
  {
    label: "VILLARS-LES-DOMBES",
    value: "01443",
    type: "common",
  },
  {
    label: "SAINT-MARCEL",
    value: "01371",
    type: "common",
  },
  {
    label: "LAPEYROUSE",
    value: "01207",
    type: "common",
  },
  {
    label: "BIRIEUX",
    value: "01045",
    type: "common",
  },
  {
    label: "SAINT-JEAN-DE-THURIGNEUX",
    value: "01362",
    type: "common",
  },
  {
    label: "MONTHIEUX",
    value: "01261",
    type: "common",
  },
  {
    label: "AMBERIEUX-EN-DOMBES",
    value: "01005",
    type: "common",
  },
  {
    label: "TOUSSIEUX",
    value: "01423",
    type: "common",
  },
  {
    label: "SAVIGNEUX",
    value: "01398",
    type: "common",
  },
  {
    label: "RANCE",
    value: "01318",
    type: "common",
  },
  {
    label: "MISERIEUX",
    value: "01250",
    type: "common",
  },
  {
    label: "ARS-SUR-FORMANS",
    value: "01021",
    type: "common",
  },
  {
    label: "SAINTE-EUPHEMIE",
    value: "01353",
    type: "common",
  },
  {
    label: "SAINT-DIDIER-DE-FORMANS",
    value: "01347",
    type: "common",
  },
  {
    label: "SAINT-BERNARD",
    value: "01339",
    type: "common",
  },
  {
    label: "JASSANS-RIOTTIER",
    value: "01194",
    type: "common",
  },
  {
    label: "FRANS",
    value: "01166",
    type: "common",
  },
  {
    label: "BEAUREGARD",
    value: "01030",
    type: "common",
  },
  {
    label: "VILLEFRANCHE-SUR-SAONE",
    value: "69264",
    type: "common",
  },
  {
    label: "LIMAS",
    value: "69115",
    type: "common",
  },
  {
    label: "LACENAS",
    value: "69105",
    type: "common",
  },
  {
    label: "GLEIZE",
    value: "69092",
    type: "common",
  },
  {
    label: "DENICE",
    value: "69074",
    type: "common",
  },
  {
    label: "PORTE DES PIERRES DOREES",
    value: "69159",
    type: "common",
  },
  {
    label: "POMMIERS",
    value: "69156",
    type: "common",
  },
  {
    label: "ANSE",
    value: "69009",
    type: "common",
  },
  {
    label: "COGNY",
    value: "69061",
    type: "common",
  },
  {
    label: "VILLE-SUR-JARNIOUX",
    value: "69265",
    type: "common",
  },
  {
    label: "SAINTE-PAULE",
    value: "69230",
    type: "common",
  },
  {
    label: "LETRA",
    value: "69113",
    type: "common",
  },
  {
    label: "CHAMELET",
    value: "69039",
    type: "common",
  },
  {
    label: "CHAMBOST-ALLIERES",
    value: "69037",
    type: "common",
  },
  {
    label: "SAINT-JUST-D'AVRAY",
    value: "69217",
    type: "common",
  },
  {
    label: "SAINT-APPOLINAIRE",
    value: "69181",
    type: "common",
  },
  {
    label: "DIEME",
    value: "69075",
    type: "common",
  },
  {
    label: "SAINT-JEAN-LA-BUSSIERE",
    value: "69214",
    type: "common",
  },
  {
    label: "RONNO",
    value: "69169",
    type: "common",
  },
  {
    label: "AMPLEPUIS",
    value: "69006",
    type: "common",
  },
  {
    label: "SAINT-VICTOR-SUR-RHINS",
    value: "42293",
    type: "common",
  },
  {
    label: "LAY",
    value: "42118",
    type: "common",
  },
  {
    label: "REGNY",
    value: "42181",
    type: "common",
  },
  {
    label: "PRADINES",
    value: "42178",
    type: "common",
  },
  {
    label: "NEAUX",
    value: "42153",
    type: "common",
  },
  {
    label: "SAINT-VINCENT-DE-BOISSET",
    value: "42294",
    type: "common",
  },
  {
    label: "SAINT-CYR-DE-FAVIERES",
    value: "42212",
    type: "common",
  },
  {
    label: "PARIGNY",
    value: "42166",
    type: "common",
  },
  {
    label: "NOTRE-DAME-DE-BOISSET",
    value: "42161",
    type: "common",
  },
  {
    label: "LE COTEAU",
    value: "42071",
    type: "common",
  },
  {
    label: "COMMELLE-VERNAY",
    value: "42069",
    type: "common",
  },
  {
    label: "VILLEREST",
    value: "42332",
    type: "common",
  },
  {
    label: "OUCHES",
    value: "42162",
    type: "common",
  },
  {
    label: "LENTIGNY",
    value: "42120",
    type: "common",
  },
  {
    label: "VILLEMONTAIS",
    value: "42331",
    type: "common",
  },
  {
    label: "SAINT-ALBAN-LES-EAUX",
    value: "42198",
    type: "common",
  },
  {
    label: "CHERIER",
    value: "42061",
    type: "common",
  },
  {
    label: "LA TUILIERE",
    value: "42314",
    type: "common",
  },
  {
    label: "ARCON",
    value: "42008",
    type: "common",
  },
  {
    label: "LAPRUGNE",
    value: "03139",
    type: "common",
  },
  {
    label: "LAVOINE",
    value: "03141",
    type: "common",
  },
  {
    label: "LA GUILLERMIE",
    value: "03125",
    type: "common",
  },
  {
    label: "LACHAUX",
    value: "63184",
    type: "common",
  },
  {
    label: "CHATELDON",
    value: "63102",
    type: "common",
  },
  {
    label: "RIS",
    value: "63301",
    type: "common",
  },
  {
    label: "MONS",
    value: "63232",
    type: "common",
  },
  {
    label: "LIMONS",
    value: "63196",
    type: "common",
  },
  {
    label: "BEAUMONT-LES-RANDAN",
    value: "63033",
    type: "common",
  },
  {
    label: "VILLENEUVE-LES-CERFS",
    value: "63459",
    type: "common",
  },
  {
    label: "SAINT-DENIS-COMBARNAZAT",
    value: "63333",
    type: "common",
  },
  {
    label: "RANDAN",
    value: "63295",
    type: "common",
  },
  {
    label: "THURET",
    value: "63432",
    type: "common",
  },
  {
    label: "SAINT-CLEMENT-DE-REGNAT",
    value: "63332",
    type: "common",
  },
  {
    label: "BUSSIERES-ET-PRUNS",
    value: "63061",
    type: "common",
  },
  {
    label: "SAINT-MYON",
    value: "63379",
    type: "common",
  },
  {
    label: "AUBIAT",
    value: "63013",
    type: "common",
  },
  {
    label: "ARTONNE",
    value: "63012",
    type: "common",
  },
  {
    label: "AIGUEPERSE",
    value: "63001",
    type: "common",
  },
  {
    label: "MONTCEL",
    value: "63235",
    type: "common",
  },
  {
    label: "JOZERAND",
    value: "63181",
    type: "common",
  },
  {
    label: "COMBRONDE",
    value: "63116",
    type: "common",
  },
  {
    label: "CHARBONNIERES-LES-VIEILLES",
    value: "63093",
    type: "common",
  },
  {
    label: "VITRAC",
    value: "63464",
    type: "common",
  },
  {
    label: "SAINT-ANGEL",
    value: "63318",
    type: "common",
  },
  {
    label: "CHATEAUNEUF-LES-BAINS",
    value: "63100",
    type: "common",
  },
  {
    label: "SAURET-BESSERVE",
    value: "63408",
    type: "common",
  },
  {
    label: "SAINT-GERVAIS-D'AUVERGNE",
    value: "63354",
    type: "common",
  },
  {
    label: "QUEUILLE",
    value: "63294",
    type: "common",
  },
  {
    label: "SAINT-PRIEST-DES-CHAMPS",
    value: "63388",
    type: "common",
  },
  {
    label: "VERGHEAS",
    value: "63447",
    type: "common",
  },
  {
    label: "BIOLLET",
    value: "63041",
    type: "common",
  },
  {
    label: "DONTREIX",
    value: "23073",
    type: "common",
  },
  {
    label: "CHARRON",
    value: "23054",
    type: "common",
  },
  {
    label: "LES MARS",
    value: "23123",
    type: "common",
  },
  {
    label: "LE COMPAS",
    value: "23066",
    type: "common",
  },
  {
    label: "BROUSSE",
    value: "23034",
    type: "common",
  },
  {
    label: "AUZANCES",
    value: "23013",
    type: "common",
  },
  {
    label: "BUSSIERE-NOUVELLE",
    value: "23037",
    type: "common",
  },
  {
    label: "LUPERSAT",
    value: "23113",
    type: "common",
  },
  {
    label: "CHAMPAGNAT",
    value: "23048",
    type: "common",
  },
  {
    label: "BELLEGARDE-EN-MARCHE",
    value: "23020",
    type: "common",
  },
  {
    label: "SAINT-MAIXANT",
    value: "23210",
    type: "common",
  },
  {
    label: "SAINT-AMAND",
    value: "23180",
    type: "common",
  },
  {
    label: "SAINT-ALPINIEN",
    value: "23179",
    type: "common",
  },
  {
    label: "LA CHAUSSADE",
    value: "23059",
    type: "common",
  },
  {
    label: "BOSROGER",
    value: "23028",
    type: "common",
  },
  {
    label: "SAINT-MEDARD-LA-ROCHETTE",
    value: "23220",
    type: "common",
  },
  {
    label: "ALLEYRAT",
    value: "23003",
    type: "common",
  },
  {
    label: "SAINT-AVIT-LE-PAUVRE",
    value: "23183",
    type: "common",
  },
  {
    label: "FRANSECHES",
    value: "23086",
    type: "common",
  },
  {
    label: "ARS",
    value: "23007",
    type: "common",
  },
  {
    label: "SAINT-SULPICE-LES-CHAMPS",
    value: "23246",
    type: "common",
  },
  {
    label: "SAINT-GEORGES-LA-POUGE",
    value: "23197",
    type: "common",
  },
  {
    label: "SAINT-HILAIRE-LE-CHATEAU",
    value: "23202",
    type: "common",
  },
  {
    label: "LA CHAPELLE-SAINT-MARTIAL",
    value: "23051",
    type: "common",
  },
  {
    label: "THAURON",
    value: "23253",
    type: "common",
  },
  {
    label: "PONTARION",
    value: "23155",
    type: "common",
  },
  {
    label: "BOSMOREAU-LES-MINES",
    value: "23027",
    type: "common",
  },
  {
    label: "CHATELUS-LE-MARCHEIX",
    value: "23056",
    type: "common",
  },
  {
    label: "JABREILLES-LES-BORDES",
    value: "87076",
    type: "common",
  },
  {
    label: "LES BILLANGES",
    value: "87016",
    type: "common",
  },
  {
    label: "SAINT-LAURENT-LES-EGLISES",
    value: "87157",
    type: "common",
  },
  {
    label: "LA JONCHERE-SAINT-MAURICE",
    value: "87079",
    type: "common",
  },
  {
    label: "SAINT-SYLVESTRE",
    value: "87183",
    type: "common",
  },
  {
    label: "COMPREIGNAC",
    value: "87047",
    type: "common",
  },
  {
    label: "THOURON",
    value: "87197",
    type: "common",
  },
  {
    label: "NANTIAT",
    value: "87103",
    type: "common",
  },
  {
    label: "PEYRILHAC",
    value: "87118",
    type: "common",
  },
  {
    label: "CHAMBORET",
    value: "87033",
    type: "common",
  },
  {
    label: "VAULRY",
    value: "87198",
    type: "common",
  },
  {
    label: "CIEUX",
    value: "87045",
    type: "common",
  },
  {
    label: "MONTROL-SENARD",
    value: "87100",
    type: "common",
  },
  {
    label: "SAINT-CHRISTOPHE",
    value: "16306",
    type: "common",
  },
  {
    label: "MONTROLLET",
    value: "16231",
    type: "common",
  },
  {
    label: "LESTERPS",
    value: "16182",
    type: "common",
  },
  {
    label: "SAINT-MAURICE-DES-LIONS",
    value: "16337",
    type: "common",
  },
  {
    label: "ANSAC-SUR-VIENNE",
    value: "16016",
    type: "common",
  },
  {
    label: "AMBERNAC",
    value: "16009",
    type: "common",
  },
  {
    label: "ALLOUE",
    value: "16007",
    type: "common",
  },
  {
    label: "LE VIEUX-CERIER",
    value: "16403",
    type: "common",
  },
  {
    label: "SAINT-COUTANT",
    value: "16310",
    type: "common",
  },
  {
    label: "CHAMPAGNE-MOUTON",
    value: "16076",
    type: "common",
  },
  {
    label: "SAINT-GEORGES",
    value: "16321",
    type: "common",
  },
  {
    label: "POURSAC",
    value: "16268",
    type: "common",
  },
  {
    label: "NANTEUIL-EN-VALLEE",
    value: "16242",
    type: "common",
  },
  {
    label: "VERTEUIL-SUR-CHARENTE",
    value: "16400",
    type: "common",
  },
  {
    label: "SALLES-DE-VILLEFAGNAN",
    value: "16361",
    type: "common",
  },
  {
    label: "BARRO",
    value: "16031",
    type: "common",
  },
  {
    label: "RAIX",
    value: "16273",
    type: "common",
  },
  {
    label: "CHARME",
    value: "16083",
    type: "common",
  },
  {
    label: "BESSE",
    value: "16042",
    type: "common",
  },
  {
    label: "SOUVIGNE",
    value: "16373",
    type: "common",
  },
  {
    label: "BRETTES",
    value: "16059",
    type: "common",
  },
  {
    label: "COUTURE-D'ARGENSON",
    value: "79106",
    type: "common",
  },
  {
    label: "SAINT-FRAIGNE",
    value: "16317",
    type: "common",
  },
  {
    label: "LONGRE",
    value: "16190",
    type: "common",
  },
  {
    label: "LES GOURS",
    value: "16155",
    type: "common",
  },
  {
    label: "VILLIERS-COUTURE",
    value: "17477",
    type: "common",
  },
  {
    label: "ROMAZIERES",
    value: "17301",
    type: "common",
  },
  {
    label: "FONTAINE-CHALENDRAY",
    value: "17162",
    type: "common",
  },
  {
    label: "CHIVES",
    value: "17105",
    type: "common",
  },
  {
    label: "SEIGNE",
    value: "17422",
    type: "common",
  },
  {
    label: "NERE",
    value: "17257",
    type: "common",
  },
  {
    label: "LES EDUTS",
    value: "17149",
    type: "common",
  },
  {
    label: "VILLEMORIN",
    value: "17473",
    type: "common",
  },
  {
    label: "LOIRE-SUR-NIE",
    value: "17206",
    type: "common",
  },
  {
    label: "CHERBONNIERES",
    value: "17101",
    type: "common",
  },
  {
    label: "SAINT-PIERRE-DE-JUILLERS",
    value: "17383",
    type: "common",
  },
  {
    label: "PAILLE",
    value: "17271",
    type: "common",
  },
  {
    label: "LES EGLISES-D'ARGENTEUIL",
    value: "17150",
    type: "common",
  },
  {
    label: "VERVANT",
    value: "17467",
    type: "common",
  },
  {
    label: "POURSAY-GARNAUD",
    value: "17288",
    type: "common",
  },
  {
    label: "COURCELLES",
    value: "17125",
    type: "common",
  },
  {
    label: "ANTEZANT-LA-CHAPELLE",
    value: "17013",
    type: "common",
  },
  {
    label: "LA VERGNE",
    value: "17465",
    type: "common",
  },
  {
    label: "TERNANT",
    value: "17440",
    type: "common",
  },
  {
    label: "SAINT-JEAN-D'ANGELY",
    value: "17347",
    type: "common",
  },
  {
    label: "VOISSAY",
    value: "17481",
    type: "common",
  },
  {
    label: "TORXE",
    value: "17450",
    type: "common",
  },
  {
    label: "LES NOUILLERS",
    value: "17266",
    type: "common",
  },
  {
    label: "LANDES",
    value: "17202",
    type: "common",
  },
  {
    label: "CHANTEMERLE-SUR-LA-SOIE",
    value: "17087",
    type: "common",
  },
  {
    label: "TONNAY-BOUTONNE",
    value: "17448",
    type: "common",
  },
  {
    label: "PUY-DU-LAC",
    value: "17292",
    type: "common",
  },
  {
    label: "ARCHINGEAY",
    value: "17017",
    type: "common",
  },
  {
    label: "SAINT-COUTANT-LE-GRAND",
    value: "17320",
    type: "common",
  },
  {
    label: "MORAGNE",
    value: "17246",
    type: "common",
  },
  {
    label: "LUSSANT",
    value: "17216",
    type: "common",
  },
  {
    label: "TONNAY-CHARENTE",
    value: "17449",
    type: "common",
  },
  {
    label: "VERGEROUX",
    value: "17463",
    type: "common",
  },
  {
    label: "ROCHEFORT",
    value: "17299",
    type: "common",
  },
  {
    label: "SAINT-NAZAIRE-SUR-CHARENTE",
    value: "17375",
    type: "common",
  },
  {
    label: "PORT-DES-BARQUES",
    value: "17484",
    type: "common",
  },
  {
    label: "SAINT-PIERRE-D'OLERON",
    value: "17385",
    type: "common",
  },
  {
    label: "SAINT-GERVAIS-LES-BAINS",
    value: "74236",
    type: "common",
  },
  {
    label: "MEGEVE",
    value: "74173",
    type: "common",
  },
  {
    label: "DEMI-QUARTIER",
    value: "74099",
    type: "common",
  },
  {
    label: "LA GIETTAZ",
    value: "73123",
    type: "common",
  },
  {
    label: "FLUMET",
    value: "73114",
    type: "common",
  },
  {
    label: "THONES",
    value: "74280",
    type: "common",
  },
  {
    label: "MANIGOD",
    value: "74160",
    type: "common",
  },
  {
    label: "VEYRIER-DU-LAC",
    value: "74299",
    type: "common",
  },
  {
    label: "MENTHON-SAINT-BERNARD",
    value: "74176",
    type: "common",
  },
  {
    label: "BLUFFY",
    value: "74036",
    type: "common",
  },
  {
    label: "ALEX",
    value: "74003",
    type: "common",
  },
  {
    label: "SEVRIER",
    value: "74267",
    type: "common",
  },
  {
    label: "ANNECY",
    value: "74010",
    type: "common",
  },
  {
    label: "MONTAGNY-LES-LANCHES",
    value: "74186",
    type: "common",
  },
  {
    label: "MARCELLAZ-ALBANAIS",
    value: "74161",
    type: "common",
  },
  {
    label: "LOVAGNY",
    value: "74152",
    type: "common",
  },
  {
    label: "ETERCY",
    value: "74117",
    type: "common",
  },
  {
    label: "CHAVANOD",
    value: "74067",
    type: "common",
  },
  {
    label: "SALES",
    value: "74255",
    type: "common",
  },
  {
    label: "RUMILLY",
    value: "74225",
    type: "common",
  },
  {
    label: "HAUTEVILLE-SUR-FIER",
    value: "74141",
    type: "common",
  },
  {
    label: "MOYE",
    value: "74192",
    type: "common",
  },
  {
    label: "LORNAY",
    value: "74151",
    type: "common",
  },
  {
    label: "SERRIERES-EN-CHAUTAGNE",
    value: "73286",
    type: "common",
  },
  {
    label: "ANGLEFORT",
    value: "01010",
    type: "common",
  },
  {
    label: "TALISSIEU",
    value: "01415",
    type: "common",
  },
  {
    label: "ARTEMARE",
    value: "01022",
    type: "common",
  },
  {
    label: "TENAY",
    value: "01416",
    type: "common",
  },
  {
    label: "PREMILLIEU",
    value: "01311",
    type: "common",
  },
  {
    label: "ARANDAS",
    value: "01013",
    type: "common",
  },
  {
    label: "TORCIEU",
    value: "01421",
    type: "common",
  },
  {
    label: "SOUCLIN",
    value: "01411",
    type: "common",
  },
  {
    label: "CONAND",
    value: "01111",
    type: "common",
  },
  {
    label: "CLEYZIEU",
    value: "01107",
    type: "common",
  },
  {
    label: "VAUX-EN-BUGEY",
    value: "01431",
    type: "common",
  },
  {
    label: "SAINT-SORLIN-EN-BUGEY",
    value: "01386",
    type: "common",
  },
  {
    label: "LAGNIEU",
    value: "01202",
    type: "common",
  },
  {
    label: "VERTRIEU",
    value: "38539",
    type: "common",
  },
  {
    label: "SAINTE-JULIE",
    value: "01366",
    type: "common",
  },
  {
    label: "LEYMENT",
    value: "01213",
    type: "common",
  },
  {
    label: "CHAZEY-SUR-AIN",
    value: "01099",
    type: "common",
  },
  {
    label: "VILLIEU-LOYES-MOLLON",
    value: "01450",
    type: "common",
  },
  {
    label: "SAINT-ELOI",
    value: "01349",
    type: "common",
  },
  {
    label: "PEROUGES",
    value: "01290",
    type: "common",
  },
  {
    label: "MEXIMIEUX",
    value: "01244",
    type: "common",
  },
  {
    label: "PIZAY",
    value: "01297",
    type: "common",
  },
  {
    label: "LE MONTELLIER",
    value: "01260",
    type: "common",
  },
  {
    label: "FARAMANS",
    value: "01156",
    type: "common",
  },
  {
    label: "BOURG-SAINT-CHRISTOPHE",
    value: "01054",
    type: "common",
  },
  {
    label: "SAINTE-CROIX",
    value: "01342",
    type: "common",
  },
  {
    label: "SAINT-ANDRE-DE-CORCY",
    value: "01333",
    type: "common",
  },
  {
    label: "MIONNAY",
    value: "01248",
    type: "common",
  },
  {
    label: "REYRIEUX",
    value: "01322",
    type: "common",
  },
  {
    label: "PARCIEUX",
    value: "01285",
    type: "common",
  },
  {
    label: "MASSIEUX",
    value: "01238",
    type: "common",
  },
  {
    label: "CIVRIEUX",
    value: "01105",
    type: "common",
  },
  {
    label: "GENAY",
    value: "69278",
    type: "common",
  },
  {
    label: "TREVOUX",
    value: "01427",
    type: "common",
  },
  {
    label: "QUINCIEUX",
    value: "69163",
    type: "common",
  },
  {
    label: "LES CHERES",
    value: "69055",
    type: "common",
  },
  {
    label: "AMBERIEUX",
    value: "69005",
    type: "common",
  },
  {
    label: "MORANCE",
    value: "69140",
    type: "common",
  },
  {
    label: "MARCY",
    value: "69126",
    type: "common",
  },
  {
    label: "LUCENAY",
    value: "69122",
    type: "common",
  },
  {
    label: "LACHASSAGNE",
    value: "69106",
    type: "common",
  },
  {
    label: "CHARNAY",
    value: "69047",
    type: "common",
  },
  {
    label: "ALIX",
    value: "69004",
    type: "common",
  },
  {
    label: "THEIZE",
    value: "69246",
    type: "common",
  },
  {
    label: "MOIRE",
    value: "69134",
    type: "common",
  },
  {
    label: "LEGNY",
    value: "69111",
    type: "common",
  },
  {
    label: "FRONTENAS",
    value: "69090",
    type: "common",
  },
  {
    label: "CHESSY",
    value: "69056",
    type: "common",
  },
  {
    label: "LE BREUIL",
    value: "69026",
    type: "common",
  },
  {
    label: "VAL D'OINGT",
    value: "69024",
    type: "common",
  },
  {
    label: "BAGNOLS",
    value: "69017",
    type: "common",
  },
  {
    label: "TERNAND",
    value: "69245",
    type: "common",
  },
  {
    label: "SAINT-VERAND",
    value: "69239",
    type: "common",
  },
  {
    label: "VALSONNE",
    value: "69254",
    type: "common",
  },
  {
    label: "TARARE",
    value: "69243",
    type: "common",
  },
  {
    label: "SAINT-CLEMENT-SUR-VALSONNE",
    value: "69188",
    type: "common",
  },
  {
    label: "LES SAUVAGES",
    value: "69174",
    type: "common",
  },
  {
    label: "MACHEZAL",
    value: "42128",
    type: "common",
  },
  {
    label: "SAINT-SYMPHORIEN-DE-LAY",
    value: "42289",
    type: "common",
  },
  {
    label: "FOURNEAUX",
    value: "42098",
    type: "common",
  },
  {
    label: "CROIZET-SUR-GAND",
    value: "42077",
    type: "common",
  },
  {
    label: "CHIRASSIMONT",
    value: "42063",
    type: "common",
  },
  {
    label: "VENDRANGES",
    value: "42325",
    type: "common",
  },
  {
    label: "NEULISE",
    value: "42156",
    type: "common",
  },
  {
    label: "SAINT-PRIEST-LA-ROCHE",
    value: "42277",
    type: "common",
  },
  {
    label: "CORDELLE",
    value: "42070",
    type: "common",
  },
  {
    label: "SAINT-POLGUES",
    value: "42274",
    type: "common",
  },
  {
    label: "SAINT-JEAN-SAINT-MAURICE-SUR-LOIRE",
    value: "42239",
    type: "common",
  },
  {
    label: "BULLY",
    value: "42027",
    type: "common",
  },
  {
    label: "CREMEAUX",
    value: "42076",
    type: "common",
  },
  {
    label: "SAINT-JUST-EN-CHEVALET",
    value: "42248",
    type: "common",
  },
  {
    label: "SAINT-PRIEST-LA-PRUGNE",
    value: "42276",
    type: "common",
  },
  {
    label: "PALLADUC",
    value: "63267",
    type: "common",
  },
  {
    label: "SAINT-VICTOR-MONTVIANEIX",
    value: "63402",
    type: "common",
  },
  {
    label: "PUY-GUILLAUME",
    value: "63291",
    type: "common",
  },
  {
    label: "PASLIERES",
    value: "63271",
    type: "common",
  },
  {
    label: "NOALHAT",
    value: "63253",
    type: "common",
  },
  {
    label: "VINZELLES",
    value: "63461",
    type: "common",
  },
  {
    label: "LUZILLAT",
    value: "63201",
    type: "common",
  },
  {
    label: "CHARNAT",
    value: "63095",
    type: "common",
  },
  {
    label: "SAINT-ANDRE-LE-COQ",
    value: "63317",
    type: "common",
  },
  {
    label: "MARINGUES",
    value: "63210",
    type: "common",
  },
  {
    label: "SURAT",
    value: "63424",
    type: "common",
  },
  {
    label: "SARDON",
    value: "63406",
    type: "common",
  },
  {
    label: "SAINT-IGNAT",
    value: "63362",
    type: "common",
  },
  {
    label: "MARTRES-SUR-MORGE",
    value: "63215",
    type: "common",
  },
  {
    label: "VARENNES-SUR-MORGE",
    value: "63443",
    type: "common",
  },
  {
    label: "PESSAT-VILLENEUVE",
    value: "63278",
    type: "common",
  },
  {
    label: "CHAMBARON SUR MORGE",
    value: "63244",
    type: "common",
  },
  {
    label: "CLERLANDE",
    value: "63112",
    type: "common",
  },
  {
    label: "LE CHEIX",
    value: "63108",
    type: "common",
  },
  {
    label: "YSSAC-LA-TOURETTE",
    value: "63473",
    type: "common",
  },
  {
    label: "TEILHEDE",
    value: "63427",
    type: "common",
  },
  {
    label: "SAINT-BONNET-PRES-RIOM",
    value: "63327",
    type: "common",
  },
  {
    label: "PROMPSAT",
    value: "63288",
    type: "common",
  },
  {
    label: "GIMEAUX",
    value: "63167",
    type: "common",
  },
  {
    label: "DAVAYAT",
    value: "63135",
    type: "common",
  },
  {
    label: "CHATEL-GUYON",
    value: "63103",
    type: "common",
  },
  {
    label: "BEAUREGARD-VENDON",
    value: "63035",
    type: "common",
  },
  {
    label: "LOUBEYRAT",
    value: "63198",
    type: "common",
  },
  {
    label: "MANZAT",
    value: "63206",
    type: "common",
  },
  {
    label: "SAINT-GEORGES-DE-MONS",
    value: "63349",
    type: "common",
  },
  {
    label: "LES ANCIZES-COMPS",
    value: "63004",
    type: "common",
  },
  {
    label: "VILLOSSANGES",
    value: "63460",
    type: "common",
  },
  {
    label: "CHARENSAT",
    value: "63094",
    type: "common",
  },
  {
    label: "MONTEL-DE-GELAT",
    value: "63237",
    type: "common",
  },
  {
    label: "CHATELARD",
    value: "23055",
    type: "common",
  },
  {
    label: "CHARD",
    value: "23053",
    type: "common",
  },
  {
    label: "SAINT-BARD",
    value: "23184",
    type: "common",
  },
  {
    label: "SERMUR",
    value: "23171",
    type: "common",
  },
  {
    label: "MAUTES",
    value: "23127",
    type: "common",
  },
  {
    label: "LIOUX-LES-MONGES",
    value: "23110",
    type: "common",
  },
  {
    label: "LA VILLETELLE",
    value: "23266",
    type: "common",
  },
  {
    label: "SAINT-SILVAIN-BELLEGARDE",
    value: "23241",
    type: "common",
  },
  {
    label: "SAINT-AVIT-DE-TARDES",
    value: "23182",
    type: "common",
  },
  {
    label: "SAINT-PARDOUX-LE-NEUF",
    value: "23228",
    type: "common",
  },
  {
    label: "NEOUX",
    value: "23142",
    type: "common",
  },
  {
    label: "MOUTIER-ROZEILLE",
    value: "23140",
    type: "common",
  },
  {
    label: "SAINT-MARC-A-FRONGIER",
    value: "23211",
    type: "common",
  },
  {
    label: "BLESSAC",
    value: "23024",
    type: "common",
  },
  {
    label: "AUBUSSON",
    value: "23008",
    type: "common",
  },
  {
    label: "SAINT-MICHEL-DE-VEISSE",
    value: "23222",
    type: "common",
  },
  {
    label: "LA POUGE",
    value: "23157",
    type: "common",
  },
  {
    label: "CHAVANAT",
    value: "23060",
    type: "common",
  },
  {
    label: "BANIZE",
    value: "23016",
    type: "common",
  },
  {
    label: "VIDAILLAT",
    value: "23260",
    type: "common",
  },
  {
    label: "SOUBREBOST",
    value: "23173",
    type: "common",
  },
  {
    label: "MANSAT-LA-COURRIERE",
    value: "23122",
    type: "common",
  },
  {
    label: "FAUX-MAZURAS",
    value: "23078",
    type: "common",
  },
  {
    label: "BOURGANEUF",
    value: "23030",
    type: "common",
  },
  {
    label: "SAINT-PIERRE-CHERIGNAT",
    value: "23230",
    type: "common",
  },
  {
    label: "MONTBOUCHER",
    value: "23133",
    type: "common",
  },
  {
    label: "SAINT-MARTIN-SAINTE-CATHERINE",
    value: "23217",
    type: "common",
  },
  {
    label: "SAINT-MARTIN-TERRESSUS",
    value: "87167",
    type: "common",
  },
  {
    label: "AMBAZAC",
    value: "87002",
    type: "common",
  },
  {
    label: "RILHAC-RANCON",
    value: "87125",
    type: "common",
  },
  {
    label: "BONNAC-LA-COTE",
    value: "87020",
    type: "common",
  },
  {
    label: "SAINT-JOUVENT",
    value: "87152",
    type: "common",
  },
  {
    label: "NIEUL",
    value: "87107",
    type: "common",
  },
  {
    label: "CHAPTELAT",
    value: "87038",
    type: "common",
  },
  {
    label: "SAINT-GENCE",
    value: "87143",
    type: "common",
  },
  {
    label: "VEYRAC",
    value: "87202",
    type: "common",
  },
  {
    label: "ORADOUR-SUR-GLANE",
    value: "87110",
    type: "common",
  },
  {
    label: "JAVERDAT",
    value: "87078",
    type: "common",
  },
  {
    label: "BRIGUEUIL",
    value: "16064",
    type: "common",
  },
  {
    label: "SAULGOND",
    value: "16363",
    type: "common",
  },
  {
    label: "CHIRAC",
    value: "16100",
    type: "common",
  },
  {
    label: "CHABRAC",
    value: "16071",
    type: "common",
  },
  {
    label: "MANOT",
    value: "16205",
    type: "common",
  },
  {
    label: "SAINT-LAURENT-DE-CERIS",
    value: "16329",
    type: "common",
  },
  {
    label: "SAINT-CLAUD",
    value: "16308",
    type: "common",
  },
  {
    label: "PARZAC",
    value: "16255",
    type: "common",
  },
  {
    label: "LE GRAND-MADIEU",
    value: "16157",
    type: "common",
  },
  {
    label: "TURGON",
    value: "16389",
    type: "common",
  },
  {
    label: "CHASSIECQ",
    value: "16087",
    type: "common",
  },
  {
    label: "BEAULIEU-SUR-SONNETTE",
    value: "16035",
    type: "common",
  },
  {
    label: "VENTOUSE",
    value: "16396",
    type: "common",
  },
  {
    label: "SAINT-SULPICE-DE-RUFFEC",
    value: "16356",
    type: "common",
  },
  {
    label: "SAINT-GOURSON",
    value: "16325",
    type: "common",
  },
  {
    label: "SAINT-FRONT",
    value: "16318",
    type: "common",
  },
  {
    label: "COUTURE",
    value: "16114",
    type: "common",
  },
  {
    label: "AUNAC-SUR-CHARENTE",
    value: "16023",
    type: "common",
  },
  {
    label: "MOUTONNEAU",
    value: "16238",
    type: "common",
  },
  {
    label: "LONNES",
    value: "16191",
    type: "common",
  },
  {
    label: "LICHERES",
    value: "16184",
    type: "common",
  },
  {
    label: "FONTENILLE",
    value: "16141",
    type: "common",
  },
  {
    label: "FONTCLAIREAU",
    value: "16140",
    type: "common",
  },
  {
    label: "CHENON",
    value: "16095",
    type: "common",
  },
  {
    label: "LUXE",
    value: "16196",
    type: "common",
  },
  {
    label: "LIGNE",
    value: "16185",
    type: "common",
  },
  {
    label: "JUILLE",
    value: "16173",
    type: "common",
  },
  {
    label: "TUSSON",
    value: "16390",
    type: "common",
  },
  {
    label: "EBREON",
    value: "16122",
    type: "common",
  },
  {
    label: "ORADOUR",
    value: "16248",
    type: "common",
  },
  {
    label: "LUPSAULT",
    value: "16194",
    type: "common",
  },
  {
    label: "BEAUVAIS-SUR-MATHA",
    value: "17037",
    type: "common",
  },
  {
    label: "BAZAUGES",
    value: "17035",
    type: "common",
  },
  {
    label: "RANVILLE-BREUILLAUD",
    value: "16275",
    type: "common",
  },
  {
    label: "BARBEZIERES",
    value: "16027",
    type: "common",
  },
  {
    label: "GOURVILLETTE",
    value: "17180",
    type: "common",
  },
  {
    label: "LE GICQ",
    value: "17177",
    type: "common",
  },
  {
    label: "CRESSE",
    value: "17135",
    type: "common",
  },
  {
    label: "LES TOUCHES-DE-PERIGNY",
    value: "17451",
    type: "common",
  },
  {
    label: "SAINT-MARTIN-DE-JUILLERS",
    value: "17367",
    type: "common",
  },
  {
    label: "GIBOURNE",
    value: "17176",
    type: "common",
  },
  {
    label: "BAGNIZEAU",
    value: "17029",
    type: "common",
  },
  {
    label: "VARAIZE",
    value: "17459",
    type: "common",
  },
  {
    label: "LA BROUSSE",
    value: "17071",
    type: "common",
  },
  {
    label: "AUMAGNE",
    value: "17025",
    type: "common",
  },
  {
    label: "SAINTE-MEME",
    value: "17374",
    type: "common",
  },
  {
    label: "SAINT-JULIEN-DE-L'ESCAP",
    value: "17350",
    type: "common",
  },
  {
    label: "FONTENET",
    value: "17165",
    type: "common",
  },
  {
    label: "ASNIERES-LA-GIRAUD",
    value: "17022",
    type: "common",
  },
  {
    label: "MAZERAY",
    value: "17226",
    type: "common",
  },
  {
    label: "FENIOUX",
    value: "17157",
    type: "common",
  },
  {
    label: "TAILLANT",
    value: "17435",
    type: "common",
  },
  {
    label: "SAINT-SAVINIEN",
    value: "17397",
    type: "common",
  },
  {
    label: "BIGNAY",
    value: "17046",
    type: "common",
  },
  {
    label: "LA VALLEE",
    value: "17455",
    type: "common",
  },
  {
    label: "CHAMPDOLENT",
    value: "17085",
    type: "common",
  },
  {
    label: "CABARIOT",
    value: "17075",
    type: "common",
  },
  {
    label: "BORDS",
    value: "17053",
    type: "common",
  },
  {
    label: "TRIZAY",
    value: "17453",
    type: "common",
  },
  {
    label: "SAINT-HIPPOLYTE",
    value: "17346",
    type: "common",
  },
  {
    label: "BEURLAY",
    value: "17045",
    type: "common",
  },
  {
    label: "SOUBISE",
    value: "17429",
    type: "common",
  },
  {
    label: "SAINT-AGNANT",
    value: "17308",
    type: "common",
  },
  {
    label: "ECHILLAIS",
    value: "17146",
    type: "common",
  },
  {
    label: "BEAUGEAY",
    value: "17036",
    type: "common",
  },
  {
    label: "SAINT-FROULT",
    value: "17329",
    type: "common",
  },
  {
    label: "MOEZE",
    value: "17237",
    type: "common",
  },
  {
    label: "LE GRAND-VILLAGE-PLAGE",
    value: "17485",
    type: "common",
  },
  {
    label: "DOLUS-D'OLERON",
    value: "17140",
    type: "common",
  },
  {
    label: "LE CHATEAU-D'OLERON",
    value: "17093",
    type: "common",
  },
  {
    label: "LES CONTAMINES-MONTJOIE",
    value: "74085",
    type: "common",
  },
  {
    label: "PRAZ-SUR-ARLY",
    value: "74215",
    type: "common",
  },
  {
    label: "NOTRE-DAME-DE-BELLECOMBE",
    value: "73186",
    type: "common",
  },
  {
    label: "CREST-VOLAND",
    value: "73094",
    type: "common",
  },
  {
    label: "SAINT-NICOLAS-LA-CHAPELLE",
    value: "73262",
    type: "common",
  },
  {
    label: "SERRAVAL",
    value: "74265",
    type: "common",
  },
  {
    label: "LES CLEFS",
    value: "74079",
    type: "common",
  },
  {
    label: "LE BOUCHET-MONT-CHARVIN",
    value: "74045",
    type: "common",
  },
  {
    label: "SAINT-FERREOL",
    value: "74234",
    type: "common",
  },
  {
    label: "TALLOIRES-MONTMIN",
    value: "74275",
    type: "common",
  },
  {
    label: "DUINGT",
    value: "74108",
    type: "common",
  },
  {
    label: "VIUZ-LA-CHIESAZ",
    value: "74310",
    type: "common",
  },
  {
    label: "SAINT-JORIOZ",
    value: "74242",
    type: "common",
  },
  {
    label: "SAINT-EUSTACHE",
    value: "74232",
    type: "common",
  },
  {
    label: "QUINTAL",
    value: "74219",
    type: "common",
  },
  {
    label: "SAINT-SYLVESTRE",
    value: "74254",
    type: "common",
  },
  {
    label: "MURES",
    value: "74194",
    type: "common",
  },
  {
    label: "CHAPEIRY",
    value: "74061",
    type: "common",
  },
  {
    label: "ALBY-SUR-CHERAN",
    value: "74002",
    type: "common",
  },
  {
    label: "SAINT-FELIX",
    value: "74233",
    type: "common",
  },
  {
    label: "MASSINGY",
    value: "74170",
    type: "common",
  },
  {
    label: "MARIGNY-SAINT-MARCEL",
    value: "74165",
    type: "common",
  },
  {
    label: "BOUSSY",
    value: "74046",
    type: "common",
  },
  {
    label: "BLOYE",
    value: "74035",
    type: "common",
  },
  {
    label: "RUFFIEUX",
    value: "73218",
    type: "common",
  },
  {
    label: "CHINDRIEUX",
    value: "73085",
    type: "common",
  },
  {
    label: "LAVOURS",
    value: "01208",
    type: "common",
  },
  {
    label: "FLAXIEU",
    value: "01162",
    type: "common",
  },
  {
    label: "CULOZ",
    value: "01138",
    type: "common",
  },
  {
    label: "BEON",
    value: "01039",
    type: "common",
  },
  {
    label: "VIONS",
    value: "73327",
    type: "common",
  },
  {
    label: "CHANAZ",
    value: "73073",
    type: "common",
  },
  {
    label: "VONGNES",
    value: "01456",
    type: "common",
  },
  {
    label: "SAINT-MARTIN-DE-BAVEL",
    value: "01372",
    type: "common",
  },
  {
    label: "CUZIEU",
    value: "01141",
    type: "common",
  },
  {
    label: "CEYZERIEU",
    value: "01073",
    type: "common",
  },
  {
    label: "VIRIEU-LE-GRAND",
    value: "01452",
    type: "common",
  },
  {
    label: "ROSSILLON",
    value: "01329",
    type: "common",
  },
  {
    label: "CHEIGNIEU-LA-BALME",
    value: "01100",
    type: "common",
  },
  {
    label: "ARMIX",
    value: "01019",
    type: "common",
  },
  {
    label: "ORDONNAZ",
    value: "01280",
    type: "common",
  },
  {
    label: "LOMPNAS",
    value: "01219",
    type: "common",
  },
  {
    label: "LA BURBANCHE",
    value: "01066",
    type: "common",
  },
  {
    label: "VILLEBOIS",
    value: "01444",
    type: "common",
  },
  {
    label: "SERRIERES-DE-BRIORD",
    value: "01403",
    type: "common",
  },
  {
    label: "SAULT-BRENAZ",
    value: "01396",
    type: "common",
  },
  {
    label: "BENONCES",
    value: "01037",
    type: "common",
  },
  {
    label: "PORCIEU-AMBLAGNIEU",
    value: "38320",
    type: "common",
  },
  {
    label: "PARMILIEU",
    value: "38295",
    type: "common",
  },
  {
    label: "LA BALME-LES-GROTTES",
    value: "38026",
    type: "common",
  },
  {
    label: "SAINT-VULBAS",
    value: "01390",
    type: "common",
  },
  {
    label: "BLYES",
    value: "01047",
    type: "common",
  },
  {
    label: "SAINT-JEAN-DE-NIOST",
    value: "01361",
    type: "common",
  },
  {
    label: "CHARNOZ-SUR-AIN",
    value: "01088",
    type: "common",
  },
  {
    label: "DAGNEUX",
    value: "01142",
    type: "common",
  },
  {
    label: "BRESSOLLES",
    value: "01062",
    type: "common",
  },
  {
    label: "BELIGNEUX",
    value: "01032",
    type: "common",
  },
  {
    label: "BALAN",
    value: "01027",
    type: "common",
  },
  {
    label: "SAINT-MAURICE-DE-BEYNOST",
    value: "01376",
    type: "common",
  },
  {
    label: "NIEVROZ",
    value: "01276",
    type: "common",
  },
  {
    label: "MONTLUEL",
    value: "01262",
    type: "common",
  },
  {
    label: "LA BOISSE",
    value: "01049",
    type: "common",
  },
  {
    label: "BEYNOST",
    value: "01043",
    type: "common",
  },
  {
    label: "TRAMOYES",
    value: "01424",
    type: "common",
  },
  {
    label: "MIRIBEL",
    value: "01249",
    type: "common",
  },
  {
    label: "SATHONAY-VILLAGE",
    value: "69293",
    type: "common",
  },
  {
    label: "SATHONAY-CAMP",
    value: "69292",
    type: "common",
  },
  {
    label: "MONTANAY",
    value: "69284",
    type: "common",
  },
  {
    label: "SAINT-ROMAIN-AU-MONT-D'OR",
    value: "69233",
    type: "common",
  },
  {
    label: "ROCHETAILLEE-SUR-SAONE",
    value: "69168",
    type: "common",
  },
  {
    label: "NEUVILLE-SUR-SAONE",
    value: "69143",
    type: "common",
  },
  {
    label: "FONTAINES-SUR-SAONE",
    value: "69088",
    type: "common",
  },
  {
    label: "FONTAINES-SAINT-MARTIN",
    value: "69087",
    type: "common",
  },
  {
    label: "FLEURIEU-SUR-SAONE",
    value: "69085",
    type: "common",
  },
  {
    label: "CURIS-AU-MONT-D'OR",
    value: "69071",
    type: "common",
  },
  {
    label: "COUZON-AU-MONT-D'OR",
    value: "69068",
    type: "common",
  },
  {
    label: "CAILLOUX-SUR-FONTAINES",
    value: "69033",
    type: "common",
  },
  {
    label: "ALBIGNY-SUR-SAONE",
    value: "69003",
    type: "common",
  },
  {
    label: "SAINT-GERMAIN-AU-MONT-D'OR",
    value: "69207",
    type: "common",
  },
  {
    label: "POLEYMIEUX-AU-MONT-D'OR",
    value: "69153",
    type: "common",
  },
  {
    label: "MARCILLY-D'AZERGUES",
    value: "69125",
    type: "common",
  },
  {
    label: "LISSIEU",
    value: "69117",
    type: "common",
  },
  {
    label: "LIMONEST",
    value: "69116",
    type: "common",
  },
  {
    label: "CHASSELAY",
    value: "69049",
    type: "common",
  },
  {
    label: "BELMONT-D'AZERGUES",
    value: "69020",
    type: "common",
  },
  {
    label: "SAINT-JEAN-DES-VIGNES",
    value: "69212",
    type: "common",
  },
  {
    label: "LOZANNE",
    value: "69121",
    type: "common",
  },
  {
    label: "FLEURIEUX-SUR-L'ARBRESLE",
    value: "69086",
    type: "common",
  },
  {
    label: "DOMMARTIN",
    value: "69076",
    type: "common",
  },
  {
    label: "CIVRIEUX-D'AZERGUES",
    value: "69059",
    type: "common",
  },
  {
    label: "CHAZAY-D'AZERGUES",
    value: "69052",
    type: "common",
  },
  {
    label: "SAINT-GERMAIN-NUELLES",
    value: "69208",
    type: "common",
  },
  {
    label: "SARCEY",
    value: "69173",
    type: "common",
  },
  {
    label: "CHATILLON",
    value: "69050",
    type: "common",
  },
  {
    label: "BULLY",
    value: "69032",
    type: "common",
  },
  {
    label: "L'ARBRESLE",
    value: "69010",
    type: "common",
  },
  {
    label: "SAINT-ROMAIN-DE-POPEY",
    value: "69234",
    type: "common",
  },
  {
    label: "SAINT-MARCEL-L'ECLAIRE",
    value: "69225",
    type: "common",
  },
  {
    label: "SAINT-FORGEUX",
    value: "69200",
    type: "common",
  },
  {
    label: "AFFOUX",
    value: "69001",
    type: "common",
  },
  {
    label: "JOUX",
    value: "69102",
    type: "common",
  },
  {
    label: "VIOLAY",
    value: "42334",
    type: "common",
  },
  {
    label: "SAINT-CYR-DE-VALORGES",
    value: "42213",
    type: "common",
  },
  {
    label: "SAINT-JUST-LA-PENDUE",
    value: "42249",
    type: "common",
  },
  {
    label: "SAINTE-COLOMBE-SUR-GAND",
    value: "42209",
    type: "common",
  },
  {
    label: "BUSSIERES",
    value: "42029",
    type: "common",
  },
  {
    label: "SAINT-MARCEL-DE-FELINES",
    value: "42254",
    type: "common",
  },
  {
    label: "SAINT-JODARD",
    value: "42241",
    type: "common",
  },
  {
    label: "SAINT-GEORGES-DE-BAROILLE",
    value: "42226",
    type: "common",
  },
  {
    label: "PINAY",
    value: "42171",
    type: "common",
  },
  {
    label: "SOUTERNON",
    value: "42303",
    type: "common",
  },
  {
    label: "SAINT-JULIEN-D'ODDES",
    value: "42243",
    type: "common",
  },
  {
    label: "SAINT-MARCEL-D'URFE",
    value: "42255",
    type: "common",
  },
  {
    label: "LURE",
    value: "42125",
    type: "common",
  },
  {
    label: "JURE",
    value: "42116",
    type: "common",
  },
  {
    label: "GREZOLLES",
    value: "42106",
    type: "common",
  },
  {
    label: "LES SALLES",
    value: "42295",
    type: "common",
  },
  {
    label: "SAINT-ROMAIN-D'URFE",
    value: "42282",
    type: "common",
  },
  {
    label: "CHAMPOLY",
    value: "42047",
    type: "common",
  },
  {
    label: "ARCONSAT",
    value: "63008",
    type: "common",
  },
  {
    label: "CHAUSSETERRE",
    value: "42339",
    type: "common",
  },
  {
    label: "CERVIERES",
    value: "42034",
    type: "common",
  },
  {
    label: "CHABRELOCHE",
    value: "63072",
    type: "common",
  },
  {
    label: "CELLES-SUR-DUROLLE",
    value: "63066",
    type: "common",
  },
  {
    label: "SAINT-REMY-SUR-DUROLLE",
    value: "63393",
    type: "common",
  },
  {
    label: "LA MONNERIE-LE-MONTEL",
    value: "63231",
    type: "common",
  },
  {
    label: "THIERS",
    value: "63430",
    type: "common",
  },
  {
    label: "DORAT",
    value: "63138",
    type: "common",
  },
  {
    label: "ORLEAT",
    value: "63265",
    type: "common",
  },
  {
    label: "CREVANT-LAVEINE",
    value: "63128",
    type: "common",
  },
  {
    label: "BULHON",
    value: "63058",
    type: "common",
  },
  {
    label: "SAINT-LAURE",
    value: "63372",
    type: "common",
  },
  {
    label: "JOZE",
    value: "63180",
    type: "common",
  },
  {
    label: "CULHAT",
    value: "63131",
    type: "common",
  },
  {
    label: "ENTRAIGUES",
    value: "63149",
    type: "common",
  },
  {
    label: "ENNEZAT",
    value: "63148",
    type: "common",
  },
  {
    label: "CHAVAROUX",
    value: "63107",
    type: "common",
  },
  {
    label: "CHAPPES",
    value: "63089",
    type: "common",
  },
  {
    label: "SAINT-BEAUZIRE",
    value: "63322",
    type: "common",
  },
  {
    label: "RIOM",
    value: "63300",
    type: "common",
  },
  {
    label: "MENETROL",
    value: "63224",
    type: "common",
  },
  {
    label: "MOZAC",
    value: "63245",
    type: "common",
  },
  {
    label: "MARSAT",
    value: "63212",
    type: "common",
  },
  {
    label: "MALAUZAT",
    value: "63203",
    type: "common",
  },
  {
    label: "ENVAL",
    value: "63150",
    type: "common",
  },
  {
    label: "CHATEAUGAY",
    value: "63099",
    type: "common",
  },
  {
    label: "VOLVIC",
    value: "63470",
    type: "common",
  },
  {
    label: "CHARBONNIERES-LES-VARENNES",
    value: "63092",
    type: "common",
  },
  {
    label: "PULVERIERES",
    value: "63290",
    type: "common",
  },
  {
    label: "MONTFERMY",
    value: "63238",
    type: "common",
  },
  {
    label: "CHAPDES-BEAUFORT",
    value: "63085",
    type: "common",
  },
  {
    label: "SAINT-JACQUES-D'AMBUR",
    value: "63363",
    type: "common",
  },
  {
    label: "MIREMONT",
    value: "63228",
    type: "common",
  },
  {
    label: "PONTAUMUR",
    value: "63283",
    type: "common",
  },
  {
    label: "LANDOGNE",
    value: "63186",
    type: "common",
  },
  {
    label: "TRALAIGUES",
    value: "63436",
    type: "common",
  },
  {
    label: "SAINT-AVIT",
    value: "63320",
    type: "common",
  },
  {
    label: "MERINCHAL",
    value: "23131",
    type: "common",
  },
  {
    label: "LA VILLENEUVE",
    value: "23265",
    type: "common",
  },
  {
    label: "SAINT-ORADOUX-PRES-CROCQ",
    value: "23225",
    type: "common",
  },
  {
    label: "LA MAZIERE-AUX-BONS-HOMMES",
    value: "23129",
    type: "common",
  },
  {
    label: "CROCQ",
    value: "23069",
    type: "common",
  },
  {
    label: "BASVILLE",
    value: "23017",
    type: "common",
  },
  {
    label: "SAINT-PARDOUX-D'ARNET",
    value: "23226",
    type: "common",
  },
  {
    label: "SAINT-MAURICE-PRES-CROCQ",
    value: "23218",
    type: "common",
  },
  {
    label: "SAINT-FRION",
    value: "23196",
    type: "common",
  },
  {
    label: "SAINTE-FEYRE-LA-MONTAGNE",
    value: "23194",
    type: "common",
  },
  {
    label: "PONTCHARRAUD",
    value: "23156",
    type: "common",
  },
  {
    label: "SAINT-QUENTIN-LA-CHABANNE",
    value: "23238",
    type: "common",
  },
  {
    label: "FELLETIN",
    value: "23079",
    type: "common",
  },
  {
    label: "VALLIERE",
    value: "23257",
    type: "common",
  },
  {
    label: "SAINT-YRIEIX-LA-MONTAGNE",
    value: "23249",
    type: "common",
  },
  {
    label: "LE MONTEIL-AU-VICOMTE",
    value: "23134",
    type: "common",
  },
  {
    label: "SAINT-PIERRE-BELLEVUE",
    value: "23232",
    type: "common",
  },
  {
    label: "SAINT-PARDOUX-MORTEROLLES",
    value: "23227",
    type: "common",
  },
  {
    label: "SAINT-JUNIEN-LA-BREGERE",
    value: "23205",
    type: "common",
  },
  {
    label: "SAINT-PRIEST-PALUS",
    value: "23237",
    type: "common",
  },
  {
    label: "SAINT-AMAND-JARTOUDEIX",
    value: "23181",
    type: "common",
  },
  {
    label: "AURIAT",
    value: "23012",
    type: "common",
  },
  {
    label: "SAUVIAT-SUR-VIGE",
    value: "87190",
    type: "common",
  },
  {
    label: "MOISSANNES",
    value: "87099",
    type: "common",
  },
  {
    label: "ROYERES",
    value: "87129",
    type: "common",
  },
  {
    label: "LE CHATENET-EN-DOGNON",
    value: "87042",
    type: "common",
  },
  {
    label: "SAINT-PRIEST-TAURION",
    value: "87178",
    type: "common",
  },
  {
    label: "SAINT-JUST-LE-MARTEL",
    value: "87156",
    type: "common",
  },
  {
    label: "LE PALAIS-SUR-VIENNE",
    value: "87113",
    type: "common",
  },
  {
    label: "COUZEIX",
    value: "87050",
    type: "common",
  },
  {
    label: "VERNEUIL-SUR-VIENNE",
    value: "87201",
    type: "common",
  },
  {
    label: "SAINT-YRIEIX-SOUS-AIXE",
    value: "87188",
    type: "common",
  },
  {
    label: "SAINT-VICTURNIEN",
    value: "87185",
    type: "common",
  },
  {
    label: "SAINTE-MARIE-DE-VAUX",
    value: "87162",
    type: "common",
  },
  {
    label: "SAINT-MARTIN-DE-JUSSAC",
    value: "87164",
    type: "common",
  },
  {
    label: "SAINT-BRICE-SUR-VIENNE",
    value: "87140",
    type: "common",
  },
  {
    label: "SAINT-JUNIEN",
    value: "87154",
    type: "common",
  },
  {
    label: "CHAILLAC-SUR-VIENNE",
    value: "87030",
    type: "common",
  },
  {
    label: "SAILLAT-SUR-VIENNE",
    value: "87131",
    type: "common",
  },
  {
    label: "ETAGNAC",
    value: "16132",
    type: "common",
  },
  {
    label: "CHASSENON",
    value: "16086",
    type: "common",
  },
  {
    label: "CHABANAIS",
    value: "16070",
    type: "common",
  },
  {
    label: "EXIDEUIL-SUR-VIENNE",
    value: "16134",
    type: "common",
  },
  {
    label: "SUAUX",
    value: "16375",
    type: "common",
  },
  {
    label: "NIEUIL",
    value: "16245",
    type: "common",
  },
  {
    label: "LUSSAC",
    value: "16195",
    type: "common",
  },
  {
    label: "LA TACHE",
    value: "16377",
    type: "common",
  },
  {
    label: "SAINT-MARY",
    value: "16336",
    type: "common",
  },
  {
    label: "CELLEFROUIN",
    value: "16068",
    type: "common",
  },
  {
    label: "VALENCE",
    value: "16392",
    type: "common",
  },
  {
    label: "SAINT-CIERS-SUR-BONNIEURE",
    value: "16307",
    type: "common",
  },
  {
    label: "VAL-DE-BONNIEURE",
    value: "16300",
    type: "common",
  },
  {
    label: "PUYREAUX",
    value: "16272",
    type: "common",
  },
  {
    label: "NANCLARS",
    value: "16241",
    type: "common",
  },
  {
    label: "MOUTON",
    value: "16237",
    type: "common",
  },
  {
    label: "MANSLE",
    value: "16206",
    type: "common",
  },
  {
    label: "MAINE-DE-BOIXE",
    value: "16200",
    type: "common",
  },
  {
    label: "VILLOGNON",
    value: "16414",
    type: "common",
  },
  {
    label: "VERVANT",
    value: "16401",
    type: "common",
  },
  {
    label: "SAINT-GROUX",
    value: "16326",
    type: "common",
  },
  {
    label: "COULONGES",
    value: "16108",
    type: "common",
  },
  {
    label: "CELLETTES",
    value: "16069",
    type: "common",
  },
  {
    label: "MARCILLAC-LANVILLE",
    value: "16207",
    type: "common",
  },
  {
    label: "FOUQUEURE",
    value: "16144",
    type: "common",
  },
  {
    label: "AMBERAC",
    value: "16008",
    type: "common",
  },
  {
    label: "MONS",
    value: "16221",
    type: "common",
  },
  {
    label: "SAINT-OUEN-LA-THENE",
    value: "17377",
    type: "common",
  },
  {
    label: "BRESDON",
    value: "17062",
    type: "common",
  },
  {
    label: "VERDILLE",
    value: "16397",
    type: "common",
  },
  {
    label: "SIECQ",
    value: "17427",
    type: "common",
  },
  {
    label: "MASSAC",
    value: "17223",
    type: "common",
  },
  {
    label: "LOUZIGNAC",
    value: "17212",
    type: "common",
  },
  {
    label: "THORS",
    value: "17446",
    type: "common",
  },
  {
    label: "SONNAC",
    value: "17428",
    type: "common",
  },
  {
    label: "MATHA",
    value: "17224",
    type: "common",
  },
  {
    label: "HAIMPS",
    value: "17188",
    type: "common",
  },
  {
    label: "PRIGNAC",
    value: "17290",
    type: "common",
  },
  {
    label: "COURCERAC",
    value: "17126",
    type: "common",
  },
  {
    label: "BLANZAC-LES-MATHA",
    value: "17048",
    type: "common",
  },
  {
    label: "AUJAC",
    value: "17023",
    type: "common",
  },
  {
    label: "NANTILLE",
    value: "17256",
    type: "common",
  },
  {
    label: "BERCLOUX",
    value: "17042",
    type: "common",
  },
  {
    label: "AUTHON-EBEON",
    value: "17026",
    type: "common",
  },
  {
    label: "JUICQ",
    value: "17198",
    type: "common",
  },
  {
    label: "TAILLEBOURG",
    value: "17436",
    type: "common",
  },
  {
    label: "SAINT-VAIZE",
    value: "17412",
    type: "common",
  },
  {
    label: "PORT-D'ENVAUX",
    value: "17285",
    type: "common",
  },
  {
    label: "GRANDJEAN",
    value: "17181",
    type: "common",
  },
  {
    label: "ANNEPONT",
    value: "17011",
    type: "common",
  },
  {
    label: "PLASSAY",
    value: "17280",
    type: "common",
  },
  {
    label: "LE MUNG",
    value: "17252",
    type: "common",
  },
  {
    label: "GEAY",
    value: "17171",
    type: "common",
  },
  {
    label: "CRAZANNES",
    value: "17134",
    type: "common",
  },
  {
    label: "SAINT-SULPICE-D'ARNOULT",
    value: "17408",
    type: "common",
  },
  {
    label: "SAINT-PORCHAIRE",
    value: "17387",
    type: "common",
  },
  {
    label: "ROMEGOUX",
    value: "17302",
    type: "common",
  },
  {
    label: "SAINTE-RADEGONDE",
    value: "17389",
    type: "common",
  },
  {
    label: "PONT-L'ABBE-D'ARNOULT",
    value: "17284",
    type: "common",
  },
  {
    label: "CHAMPAGNE",
    value: "17083",
    type: "common",
  },
  {
    label: "SAINT-JEAN-D'ANGLE",
    value: "17348",
    type: "common",
  },
  {
    label: "BOURCEFRANC-LE-CHAPUS",
    value: "17058",
    type: "common",
  },
  {
    label: "SAINT-TROJAN-LES-BAINS",
    value: "17411",
    type: "common",
  },
  {
    label: "HAUTELUCE",
    value: "73132",
    type: "common",
  },
  {
    label: "VILLARD-SUR-DORON",
    value: "73317",
    type: "common",
  },
  {
    label: "COHENNOZ",
    value: "73088",
    type: "common",
  },
  {
    label: "UGINE",
    value: "73303",
    type: "common",
  },
  {
    label: "VAL DE CHAISE",
    value: "74167",
    type: "common",
  },
  {
    label: "MARTHOD",
    value: "73153",
    type: "common",
  },
  {
    label: "GIEZ",
    value: "74135",
    type: "common",
  },
  {
    label: "FAVERGES-SEYTHENEX",
    value: "74123",
    type: "common",
  },
  {
    label: "LATHUILE",
    value: "74147",
    type: "common",
  },
  {
    label: "ENTREVERNES",
    value: "74111",
    type: "common",
  },
  {
    label: "DOUSSARD",
    value: "74104",
    type: "common",
  },
  {
    label: "CHEVALINE",
    value: "74072",
    type: "common",
  },
  {
    label: "LA CHAPELLE-SAINT-MAURICE",
    value: "74060",
    type: "common",
  },
  {
    label: "LESCHAUX",
    value: "74148",
    type: "common",
  },
  {
    label: "ALLEVES",
    value: "74004",
    type: "common",
  },
  {
    label: "BELLECOMBE-EN-BAUGES",
    value: "73036",
    type: "common",
  },
  {
    label: "HERY-SUR-ALBY",
    value: "74142",
    type: "common",
  },
  {
    label: "GRUFFY",
    value: "74138",
    type: "common",
  },
  {
    label: "CUSY",
    value: "74097",
    type: "common",
  },
  {
    label: "CHAINAZ-LES-FRASSES",
    value: "74054",
    type: "common",
  },
  {
    label: "SAINT-OFFENGE",
    value: "73263",
    type: "common",
  },
  {
    label: "ENTRELACS",
    value: "73010",
    type: "common",
  },
  {
    label: "SAINT-OURS",
    value: "73265",
    type: "common",
  },
  {
    label: "LA BIOLLE",
    value: "73043",
    type: "common",
  },
  {
    label: "SAINT-PIERRE-DE-CURTILLE",
    value: "73273",
    type: "common",
  },
  {
    label: "ONTEX",
    value: "73193",
    type: "common",
  },
  {
    label: "CONJUX",
    value: "73091",
    type: "common",
  },
  {
    label: "BRISON-SAINT-INNOCENT",
    value: "73059",
    type: "common",
  },
  {
    label: "POLLIEU",
    value: "01302",
    type: "common",
  },
  {
    label: "MASSIGNIEU-DE-RIVES",
    value: "01239",
    type: "common",
  },
  {
    label: "CRESSIN-ROCHEFORT",
    value: "01133",
    type: "common",
  },
  {
    label: "LUCEY",
    value: "73149",
    type: "common",
  },
  {
    label: "MARIGNIEU",
    value: "01234",
    type: "common",
  },
  {
    label: "CHAZEY-BONS",
    value: "01098",
    type: "common",
  },
  {
    label: "BELLEY",
    value: "01034",
    type: "common",
  },
  {
    label: "ANDERT-ET-CONDON",
    value: "01009",
    type: "common",
  },
  {
    label: "SAINT-GERMAIN-LES-PAROISSES",
    value: "01358",
    type: "common",
  },
  {
    label: "INNIMOND",
    value: "01190",
    type: "common",
  },
  {
    label: "CONTREVOZ",
    value: "01116",
    type: "common",
  },
  {
    label: "AMBLEON",
    value: "01006",
    type: "common",
  },
  {
    label: "SEILLONNAZ",
    value: "01400",
    type: "common",
  },
  {
    label: "MARCHAMP",
    value: "01233",
    type: "common",
  },
  {
    label: "LHUIS",
    value: "01216",
    type: "common",
  },
  {
    label: "BRIORD",
    value: "01064",
    type: "common",
  },
  {
    label: "MONTAGNIEU",
    value: "01255",
    type: "common",
  },
  {
    label: "MONTALIEU-VERCIEU",
    value: "38247",
    type: "common",
  },
  {
    label: "BOUVESSE-QUIRIEU",
    value: "38054",
    type: "common",
  },
  {
    label: "SAINT-BAUDILLE-DE-LA-TOUR",
    value: "38365",
    type: "common",
  },
  {
    label: "CHARETTE",
    value: "38083",
    type: "common",
  },
  {
    label: "VERNAS",
    value: "38535",
    type: "common",
  },
  {
    label: "HIERES-SUR-AMBY",
    value: "38190",
    type: "common",
  },
  {
    label: "SAINT-MAURICE-DE-GOURDANS",
    value: "01378",
    type: "common",
  },
  {
    label: "LOYETTES",
    value: "01224",
    type: "common",
  },
  {
    label: "CHAVANOZ",
    value: "38097",
    type: "common",
  },
  {
    label: "ANTHON",
    value: "38011",
    type: "common",
  },
  {
    label: "JONS",
    value: "69280",
    type: "common",
  },
  {
    label: "VILLETTE-D'ANTHON",
    value: "38557",
    type: "common",
  },
  {
    label: "THIL",
    value: "01418",
    type: "common",
  },
  {
    label: "MEYZIEU",
    value: "69282",
    type: "common",
  },
  {
    label: "JONAGE",
    value: "69279",
    type: "common",
  },
  {
    label: "NEYRON",
    value: "01275",
    type: "common",
  },
  {
    label: "RILLIEUX-LA-PAPE",
    value: "69286",
    type: "common",
  },
  {
    label: "VAULX-EN-VELIN",
    value: "69256",
    type: "common",
  },
  {
    label: "VILLEURBANNE",
    value: "69266",
    type: "common",
  },
  {
    label: "SAINT-CYR-AU-MONT-D'OR",
    value: "69191",
    type: "common",
  },
  {
    label: "COLLONGES-AU-MONT-D'OR",
    value: "69063",
    type: "common",
  },
  {
    label: "CALUIRE-ET-CUIRE",
    value: "69034",
    type: "common",
  },
  {
    label: "ECULLY",
    value: "69081",
    type: "common",
  },
  {
    label: "DARDILLY",
    value: "69072",
    type: "common",
  },
  {
    label: "CHAMPAGNE-AU-MONT-D'OR",
    value: "69040",
    type: "common",
  },
  {
    label: "SAINT-DIDIER-AU-MONT-D'OR",
    value: "69194",
    type: "common",
  },
  {
    label: "CHARBONNIERES-LES-BAINS",
    value: "69044",
    type: "common",
  },
  {
    label: "LA TOUR-DE-SALVAGNY",
    value: "69250",
    type: "common",
  },
  {
    label: "MARCY-L'ETOILE",
    value: "69127",
    type: "common",
  },
  {
    label: "LENTILLY",
    value: "69112",
    type: "common",
  },
  {
    label: "SAINT-PIERRE-LA-PALUD",
    value: "69231",
    type: "common",
  },
  {
    label: "SOURCIEUX-LES-MINES",
    value: "69177",
    type: "common",
  },
  {
    label: "SAVIGNY",
    value: "69175",
    type: "common",
  },
  {
    label: "SAIN-BEL",
    value: "69171",
    type: "common",
  },
  {
    label: "EVEUX",
    value: "69083",
    type: "common",
  },
  {
    label: "SAINT-JULIEN-SUR-BIBOST",
    value: "69216",
    type: "common",
  },
  {
    label: "BIBOST",
    value: "69022",
    type: "common",
  },
  {
    label: "ANCY",
    value: "69008",
    type: "common",
  },
  {
    label: "VILLECHENEVE",
    value: "69263",
    type: "common",
  },
  {
    label: "MONTROTTIER",
    value: "69139",
    type: "common",
  },
  {
    label: "LONGESSAIGNE",
    value: "69120",
    type: "common",
  },
  {
    label: "SAINTE-AGATHE-EN-DONZY",
    value: "42196",
    type: "common",
  },
  {
    label: "PANISSIERES",
    value: "42165",
    type: "common",
  },
  {
    label: "MONTCHAL",
    value: "42148",
    type: "common",
  },
  {
    label: "ROZIER-EN-DONZY",
    value: "42193",
    type: "common",
  },
  {
    label: "POUILLY-LES-FEURS",
    value: "42175",
    type: "common",
  },
  {
    label: "NERONDE",
    value: "42154",
    type: "common",
  },
  {
    label: "COTTANCE",
    value: "42073",
    type: "common",
  },
  {
    label: "NERVIEUX",
    value: "42155",
    type: "common",
  },
  {
    label: "MIZERIEUX",
    value: "42143",
    type: "common",
  },
  {
    label: "EPERCIEUX-SAINT-PAUL",
    value: "42088",
    type: "common",
  },
  {
    label: "BALBIGNY",
    value: "42011",
    type: "common",
  },
  {
    label: "POMMIERS-EN-FOREZ",
    value: "42173",
    type: "common",
  },
  {
    label: "SAINT-GERMAIN-LAVAL",
    value: "42230",
    type: "common",
  },
  {
    label: "NOLLIEUX",
    value: "42160",
    type: "common",
  },
  {
    label: "CEZAY",
    value: "42035",
    type: "common",
  },
  {
    label: "BUSSY-ALBIEUX",
    value: "42030",
    type: "common",
  },
  {
    label: "SAINT-MARTIN-LA-SAUVETE",
    value: "42260",
    type: "common",
  },
  {
    label: "AILLEUX",
    value: "42002",
    type: "common",
  },
  {
    label: "SAINT-PRIEST-LA-VETRE",
    value: "42278",
    type: "common",
  },
  {
    label: "SAINT-DIDIER-SUR-ROCHEFORT",
    value: "42217",
    type: "common",
  },
  {
    label: "NOIRETABLE",
    value: "42159",
    type: "common",
  },
  {
    label: "VISCOMTAT",
    value: "63463",
    type: "common",
  },
  {
    label: "SAINTE-AGATHE",
    value: "63310",
    type: "common",
  },
  {
    label: "ESCOUTOUX",
    value: "63151",
    type: "common",
  },
  {
    label: "PESCHADOIRES",
    value: "63276",
    type: "common",
  },
  {
    label: "NERONDE-SUR-DORE",
    value: "63249",
    type: "common",
  },
  {
    label: "SAINT-JEAN-D'HEURS",
    value: "63364",
    type: "common",
  },
  {
    label: "LEZOUX",
    value: "63195",
    type: "common",
  },
  {
    label: "SEYCHALLES",
    value: "63420",
    type: "common",
  },
  {
    label: "LEMPTY",
    value: "63194",
    type: "common",
  },
  {
    label: "BEAUREGARD-L'EVEQUE",
    value: "63034",
    type: "common",
  },
  {
    label: "PONT-DU-CHATEAU",
    value: "63284",
    type: "common",
  },
  {
    label: "LES MARTRES-D'ARTIERE",
    value: "63213",
    type: "common",
  },
  {
    label: "LUSSAT",
    value: "63200",
    type: "common",
  },
  {
    label: "MALINTRAT",
    value: "63204",
    type: "common",
  },
  {
    label: "GERZAT",
    value: "63164",
    type: "common",
  },
  {
    label: "AULNAT",
    value: "63019",
    type: "common",
  },
  {
    label: "NOHANENT",
    value: "63254",
    type: "common",
  },
  {
    label: "DURTOL",
    value: "63141",
    type: "common",
  },
  {
    label: "CEBAZAT",
    value: "63063",
    type: "common",
  },
  {
    label: "BLANZAT",
    value: "63042",
    type: "common",
  },
  {
    label: "SAYAT",
    value: "63417",
    type: "common",
  },
  {
    label: "CHANAT-LA-MOUTEYRE",
    value: "63083",
    type: "common",
  },
  {
    label: "SAINT-OURS",
    value: "63381",
    type: "common",
  },
  {
    label: "SAINT-PIERRE-LE-CHASTEL",
    value: "63385",
    type: "common",
  },
  {
    label: "PONTGIBAUD",
    value: "63285",
    type: "common",
  },
  {
    label: "BROMONT-LAMOTHE",
    value: "63055",
    type: "common",
  },
  {
    label: "LA GOUTELLE",
    value: "63170",
    type: "common",
  },
  {
    label: "CISTERNES-LA-FORET",
    value: "63110",
    type: "common",
  },
  {
    label: "SAINT-HILAIRE-LES-MONGES",
    value: "63359",
    type: "common",
  },
  {
    label: "COMBRAILLES",
    value: "63115",
    type: "common",
  },
  {
    label: "VOINGT",
    value: "63467",
    type: "common",
  },
  {
    label: "SAINT-ETIENNE-DES-CHAMPS",
    value: "63339",
    type: "common",
  },
  {
    label: "CONDAT-EN-COMBRAILLE",
    value: "63118",
    type: "common",
  },
  {
    label: "GIAT",
    value: "63165",
    type: "common",
  },
  {
    label: "LA CELLE",
    value: "63064",
    type: "common",
  },
  {
    label: "FERNOEL",
    value: "63159",
    type: "common",
  },
  {
    label: "SAINT-GEORGES-NIGREMONT",
    value: "23198",
    type: "common",
  },
  {
    label: "SAINT-AGNANT-PRES-CROCQ",
    value: "23178",
    type: "common",
  },
  {
    label: "MAGNAT-L'ETRANGE",
    value: "23115",
    type: "common",
  },
  {
    label: "POUSSANGES",
    value: "23158",
    type: "common",
  },
  {
    label: "GIOUX",
    value: "23091",
    type: "common",
  },
  {
    label: "CROZE",
    value: "23071",
    type: "common",
  },
  {
    label: "LA NOUAILLE",
    value: "23144",
    type: "common",
  },
  {
    label: "SAINT-MARC-A-LOUBAUD",
    value: "23212",
    type: "common",
  },
  {
    label: "ROYERE-DE-VASSIVIERE",
    value: "23165",
    type: "common",
  },
  {
    label: "PEYRAT-LE-CHATEAU",
    value: "87117",
    type: "common",
  },
  {
    label: "SAINT-MARTIN-CHATEAU",
    value: "23216",
    type: "common",
  },
  {
    label: "SAINT-JULIEN-LE-PETIT",
    value: "87153",
    type: "common",
  },
  {
    label: "SAINT-MOREIL",
    value: "23223",
    type: "common",
  },
  {
    label: "CHEISSOUX",
    value: "87043",
    type: "common",
  },
  {
    label: "BUJALEUF",
    value: "87024",
    type: "common",
  },
  {
    label: "CHAMPNETERY",
    value: "87035",
    type: "common",
  },
  {
    label: "SAINT-LEONARD-DE-NOBLAT",
    value: "87161",
    type: "common",
  },
  {
    label: "LA GENEYTOUSE",
    value: "87070",
    type: "common",
  },
  {
    label: "EYBOULEUF",
    value: "87062",
    type: "common",
  },
  {
    label: "AUREIL",
    value: "87005",
    type: "common",
  },
  {
    label: "PANAZOL",
    value: "87114",
    type: "common",
  },
  {
    label: "FEYTIAT",
    value: "87065",
    type: "common",
  },
  {
    label: "LIMOGES",
    value: "87085",
    type: "common",
  },
  {
    label: "ISLE",
    value: "87075",
    type: "common",
  },
  {
    label: "AIXE-SUR-VIENNE",
    value: "87001",
    type: "common",
  },
  {
    label: "SAINT-PRIEST-SOUS-AIXE",
    value: "87177",
    type: "common",
  },
  {
    label: "SAINT-CYR",
    value: "87141",
    type: "common",
  },
  {
    label: "COGNAC-LA-FORET",
    value: "87046",
    type: "common",
  },
  {
    label: "SAINT-AUVENT",
    value: "87135",
    type: "common",
  },
  {
    label: "ROCHECHOUART",
    value: "87126",
    type: "common",
  },
  {
    label: "VERNEUIL",
    value: "16398",
    type: "common",
  },
  {
    label: "SAINT-QUENTIN-SUR-CHARENTE",
    value: "16345",
    type: "common",
  },
  {
    label: "PRESSIGNAC",
    value: "16270",
    type: "common",
  },
  {
    label: "MOUZON",
    value: "16239",
    type: "common",
  },
  {
    label: "LESIGNAC-DURAND",
    value: "16183",
    type: "common",
  },
  {
    label: "CHERVES-CHATELARS",
    value: "16096",
    type: "common",
  },
  {
    label: "VITRAC-SAINT-VINCENT",
    value: "16416",
    type: "common",
  },
  {
    label: "CHASSENEUIL-SUR-BONNIEURE",
    value: "16085",
    type: "common",
  },
  {
    label: "LES PINS",
    value: "16261",
    type: "common",
  },
  {
    label: "LA ROCHETTE",
    value: "16282",
    type: "common",
  },
  {
    label: "COULGENS",
    value: "16107",
    type: "common",
  },
  {
    label: "AGRIS",
    value: "16003",
    type: "common",
  },
  {
    label: "VILLEJOUBERT",
    value: "16412",
    type: "common",
  },
  {
    label: "TOURRIERS",
    value: "16383",
    type: "common",
  },
  {
    label: "JAULDES",
    value: "16168",
    type: "common",
  },
  {
    label: "AUSSAC-VADALLE",
    value: "16024",
    type: "common",
  },
  {
    label: "XAMBES",
    value: "16423",
    type: "common",
  },
  {
    label: "VOUHARTE",
    value: "16419",
    type: "common",
  },
  {
    label: "SAINT-AMANT-DE-BOIXE",
    value: "16295",
    type: "common",
  },
  {
    label: "MONTIGNAC-CHARENTE",
    value: "16226",
    type: "common",
  },
  {
    label: "GENAC-BIGNAC",
    value: "16148",
    type: "common",
  },
  {
    label: "LA CHAPELLE",
    value: "16081",
    type: "common",
  },
  {
    label: "SAINT-CYBARDEAUX",
    value: "16312",
    type: "common",
  },
  {
    label: "ROUILLAC",
    value: "16286",
    type: "common",
  },
  {
    label: "NEUVICQ-LE-CHATEAU",
    value: "17261",
    type: "common",
  },
  {
    label: "MACQUEVILLE",
    value: "17217",
    type: "common",
  },
  {
    label: "BALLANS",
    value: "17031",
    type: "common",
  },
  {
    label: "SAINTE-SEVERE",
    value: "16349",
    type: "common",
  },
  {
    label: "COURBILLAC",
    value: "16109",
    type: "common",
  },
  {
    label: "BRIE-SOUS-MATHA",
    value: "17067",
    type: "common",
  },
  {
    label: "BREVILLE",
    value: "16060",
    type: "common",
  },
  {
    label: "LE SEURE",
    value: "17426",
    type: "common",
  },
  {
    label: "MONS",
    value: "17239",
    type: "common",
  },
  {
    label: "MIGRON",
    value: "17235",
    type: "common",
  },
  {
    label: "MESNAC",
    value: "16218",
    type: "common",
  },
  {
    label: "VILLARS-LES-BOIS",
    value: "17470",
    type: "common",
  },
  {
    label: "SAINT-BRIS-DES-BOIS",
    value: "17313",
    type: "common",
  },
  {
    label: "BURIE",
    value: "17072",
    type: "common",
  },
  {
    label: "BRIZAMBOURG",
    value: "17070",
    type: "common",
  },
  {
    label: "VENERAND",
    value: "17462",
    type: "common",
  },
  {
    label: "ECOYEUX",
    value: "17147",
    type: "common",
  },
  {
    label: "LE DOUHET",
    value: "17143",
    type: "common",
  },
  {
    label: "LA CHAPELLE-DES-POTS",
    value: "17089",
    type: "common",
  },
  {
    label: "FONTCOUVERTE",
    value: "17164",
    type: "common",
  },
  {
    label: "BUSSAC-SUR-CHARENTE",
    value: "17073",
    type: "common",
  },
  {
    label: "SAINT-GEORGES-DES-COTEAUX",
    value: "17336",
    type: "common",
  },
  {
    label: "NIEUL-LES-SAINTES",
    value: "17262",
    type: "common",
  },
  {
    label: "ECURAT",
    value: "17148",
    type: "common",
  },
  {
    label: "SOULIGNONNE",
    value: "17431",
    type: "common",
  },
  {
    label: "LES ESSARDS",
    value: "17154",
    type: "common",
  },
  {
    label: "CORME-ROYAL",
    value: "17120",
    type: "common",
  },
  {
    label: "SAINTE-GEMME",
    value: "17330",
    type: "common",
  },
  {
    label: "SAINT-SORNIN",
    value: "17406",
    type: "common",
  },
  {
    label: "LA GRIPPERIE-SAINT-SYMPHORIEN",
    value: "17184",
    type: "common",
  },
  {
    label: "SAINT-JUST-LUZAC",
    value: "17351",
    type: "common",
  },
  {
    label: "NIEULLE-SUR-SEUDRE",
    value: "17265",
    type: "common",
  },
  {
    label: "ARVERT",
    value: "17021",
    type: "common",
  },
  {
    label: "LA TREMBLADE",
    value: "17452",
    type: "common",
  },
  {
    label: "SEEZ",
    value: "73285",
    type: "common",
  },
  {
    label: "BOURG-SAINT-MAURICE",
    value: "73054",
    type: "common",
  },
  {
    label: "BEAUFORT",
    value: "73034",
    type: "common",
  },
  {
    label: "VENTHON",
    value: "73308",
    type: "common",
  },
  {
    label: "TOURS-EN-SAVOIE",
    value: "73298",
    type: "common",
  },
  {
    label: "QUEIGE",
    value: "73211",
    type: "common",
  },
  {
    label: "CESARCHES",
    value: "73061",
    type: "common",
  },
  {
    label: "THENESOL",
    value: "73292",
    type: "common",
  },
  {
    label: "PALLUD",
    value: "73196",
    type: "common",
  },
  {
    label: "MERCURY",
    value: "73154",
    type: "common",
  },
  {
    label: "ALLONDAZ",
    value: "73014",
    type: "common",
  },
  {
    label: "ALBERTVILLE",
    value: "73011",
    type: "common",
  },
  {
    label: "PLANCHERINE",
    value: "73202",
    type: "common",
  },
  {
    label: "DOUCY-EN-BAUGES",
    value: "73101",
    type: "common",
  },
  {
    label: "LA MOTTE-EN-BAUGES",
    value: "73178",
    type: "common",
  },
  {
    label: "LESCHERAINES",
    value: "73146",
    type: "common",
  },
  {
    label: "LE CHATELARD",
    value: "73081",
    type: "common",
  },
  {
    label: "SAINT-FRANCOIS-DE-SALES",
    value: "73234",
    type: "common",
  },
  {
    label: "MONTCEL",
    value: "73164",
    type: "common",
  },
  {
    label: "ARITH",
    value: "73020",
    type: "common",
  },
  {
    label: "TREVIGNIN",
    value: "73301",
    type: "common",
  },
  {
    label: "PUGNY-CHATENOD",
    value: "73208",
    type: "common",
  },
  {
    label: "MOUXY",
    value: "73182",
    type: "common",
  },
  {
    label: "GRESY-SUR-AIX",
    value: "73128",
    type: "common",
  },
  {
    label: "AIX-LES-BAINS",
    value: "73008",
    type: "common",
  },
  {
    label: "SAINT-JEAN-DE-CHEVELU",
    value: "73245",
    type: "common",
  },
  {
    label: "LA CHAPELLE-DU-MONT-DU-CHAT",
    value: "73076",
    type: "common",
  },
  {
    label: "BILLIEME",
    value: "73042",
    type: "common",
  },
  {
    label: "PARVES ET NATTAGES",
    value: "01286",
    type: "common",
  },
  {
    label: "YENNE",
    value: "73330",
    type: "common",
  },
  {
    label: "JONGIEUX",
    value: "73140",
    type: "common",
  },
  {
    label: "VIRIGNIN",
    value: "01454",
    type: "common",
  },
  {
    label: "BRENS",
    value: "01061",
    type: "common",
  },
  {
    label: "ARBOYS EN BUGEY",
    value: "01015",
    type: "common",
  },
  {
    label: "CONZIEU",
    value: "01117",
    type: "common",
  },
  {
    label: "COLOMIEU",
    value: "01110",
    type: "common",
  },
  {
    label: "SAINT-VICTOR-DE-MORESTEL",
    value: "38465",
    type: "common",
  },
  {
    label: "BRANGUES",
    value: "38055",
    type: "common",
  },
  {
    label: "CREYS-MEPIEU",
    value: "38139",
    type: "common",
  },
  {
    label: "COURTENAY",
    value: "38135",
    type: "common",
  },
  {
    label: "SOLEYMIEU",
    value: "38494",
    type: "common",
  },
  {
    label: "SICCIEU-SAINT-JULIEN-ET-CARISIEU",
    value: "38488",
    type: "common",
  },
  {
    label: "OPTEVOZ",
    value: "38282",
    type: "common",
  },
  {
    label: "SAINT-ROMAIN-DE-JALIONAS",
    value: "38451",
    type: "common",
  },
  {
    label: "LEYRIEU",
    value: "38210",
    type: "common",
  },
  {
    label: "DIZIMIEU",
    value: "38146",
    type: "common",
  },
  {
    label: "CREMIEU",
    value: "38138",
    type: "common",
  },
  {
    label: "ANNOISIN-CHATELANS",
    value: "38010",
    type: "common",
  },
  {
    label: "VILLEMOIRIEU",
    value: "38554",
    type: "common",
  },
  {
    label: "TIGNIEU-JAMEYZIEU",
    value: "38507",
    type: "common",
  },
  {
    label: "PONT-DE-CHERUY",
    value: "38316",
    type: "common",
  },
  {
    label: "CHARVIEU-CHAVAGNEUX",
    value: "38085",
    type: "common",
  },
  {
    label: "COLOMBIER-SAUGNIEU",
    value: "69299",
    type: "common",
  },
  {
    label: "PUSIGNAN",
    value: "69285",
    type: "common",
  },
  {
    label: "JANNEYRIAS",
    value: "38197",
    type: "common",
  },
  {
    label: "GENAS",
    value: "69277",
    type: "common",
  },
  {
    label: "DECINES-CHARPIEU",
    value: "69275",
    type: "common",
  },
  {
    label: "CHASSIEU",
    value: "69271",
    type: "common",
  },
  {
    label: "BRON",
    value: "69029",
    type: "common",
  },
  {
    label: "OULLINS",
    value: "69149",
    type: "common",
  },
  {
    label: "LA MULATIERE",
    value: "69142",
    type: "common",
  },
  {
    label: "LYON",
    value: "69123",
    type: "common",
  },
  {
    label: "TASSIN-LA-DEMI-LUNE",
    value: "69244",
    type: "common",
  },
  {
    label: "SAINT-GENIS-LES-OLLIERES",
    value: "69205",
    type: "common",
  },
  {
    label: "SAINTE-FOY-LES-LYON",
    value: "69202",
    type: "common",
  },
  {
    label: "FRANCHEVILLE",
    value: "69089",
    type: "common",
  },
  {
    label: "CRAPONNE",
    value: "69069",
    type: "common",
  },
  {
    label: "CHAPONOST",
    value: "69043",
    type: "common",
  },
  {
    label: "VAUGNERAY",
    value: "69255",
    type: "common",
  },
  {
    label: "SAINTE-CONSORCE",
    value: "69190",
    type: "common",
  },
  {
    label: "POLLIONNAY",
    value: "69154",
    type: "common",
  },
  {
    label: "GREZIEU-LA-VARENNE",
    value: "69094",
    type: "common",
  },
  {
    label: "BRINDAS",
    value: "69028",
    type: "common",
  },
  {
    label: "COURZIEU",
    value: "69067",
    type: "common",
  },
  {
    label: "CHEVINAY",
    value: "69057",
    type: "common",
  },
  {
    label: "SAINT-LAURENT-DE-CHAMOUSSET",
    value: "69220",
    type: "common",
  },
  {
    label: "SAINT-GENIS-L'ARGENTIERE",
    value: "69203",
    type: "common",
  },
  {
    label: "BRUSSIEU",
    value: "69031",
    type: "common",
  },
  {
    label: "BRULLIOLES",
    value: "69030",
    type: "common",
  },
  {
    label: "BESSENAY",
    value: "69021",
    type: "common",
  },
  {
    label: "SAINT-CLEMENT-LES-PLACES",
    value: "69187",
    type: "common",
  },
  {
    label: "HAUTE-RIVOIRE",
    value: "69099",
    type: "common",
  },
  {
    label: "LES HALLES",
    value: "69098",
    type: "common",
  },
  {
    label: "CHAMBOST-LONGESSAIGNE",
    value: "69038",
    type: "common",
  },
  {
    label: "SAINT-MARTIN-LESTRA",
    value: "42261",
    type: "common",
  },
  {
    label: "SAINT-BARTHELEMY-LESTRA",
    value: "42202",
    type: "common",
  },
  {
    label: "JAS",
    value: "42113",
    type: "common",
  },
  {
    label: "ESSERTINES-EN-DONZY",
    value: "42090",
    type: "common",
  },
  {
    label: "SALVIZINET",
    value: "42297",
    type: "common",
  },
  {
    label: "SALT-EN-DONZY",
    value: "42296",
    type: "common",
  },
  {
    label: "FEURS",
    value: "42094",
    type: "common",
  },
  {
    label: "CIVENS",
    value: "42065",
    type: "common",
  },
  {
    label: "PONCINS",
    value: "42174",
    type: "common",
  },
  {
    label: "CLEPPE",
    value: "42066",
    type: "common",
  },
  {
    label: "SAINTE-FOY-SAINT-SULPICE",
    value: "42221",
    type: "common",
  },
  {
    label: "SAINT-ETIENNE-LE-MOLARD",
    value: "42219",
    type: "common",
  },
  {
    label: "SAINTE-AGATHE-LA-BOUTERESSE",
    value: "42197",
    type: "common",
  },
  {
    label: "TRELINS",
    value: "42313",
    type: "common",
  },
  {
    label: "SAINT-SIXTE",
    value: "42288",
    type: "common",
  },
  {
    label: "SAIL-SOUS-COUZAN",
    value: "42195",
    type: "common",
  },
  {
    label: "LEIGNEUX",
    value: "42119",
    type: "common",
  },
  {
    label: "BOEN-SUR-LIGNON",
    value: "42019",
    type: "common",
  },
  {
    label: "ARTHUN",
    value: "42009",
    type: "common",
  },
  {
    label: "SAINT-LAURENT-ROCHEFORT",
    value: "42252",
    type: "common",
  },
  {
    label: "SAINT-JUST-EN-BAS",
    value: "42247",
    type: "common",
  },
  {
    label: "PALOGNEUX",
    value: "42164",
    type: "common",
  },
  {
    label: "L'HOPITAL-SOUS-ROCHEFORT",
    value: "42109",
    type: "common",
  },
  {
    label: "DEBATS-RIVIERE-D'ORPRA",
    value: "42084",
    type: "common",
  },
  {
    label: "LA VALLA-SUR-ROCHEFORT",
    value: "42321",
    type: "common",
  },
  {
    label: "SAINT-JEAN-LA-VETRE",
    value: "42238",
    type: "common",
  },
  {
    label: "LA COTE-EN-COUZAN",
    value: "42072",
    type: "common",
  },
  {
    label: "LA RENAUDIE",
    value: "63298",
    type: "common",
  },
  {
    label: "LA CHAMBONIE",
    value: "42045",
    type: "common",
  },
  {
    label: "LA CHAMBA",
    value: "42040",
    type: "common",
  },
  {
    label: "VOLLORE-MONTAGNE",
    value: "63468",
    type: "common",
  },
  {
    label: "AUGEROLLES",
    value: "63016",
    type: "common",
  },
  {
    label: "VOLLORE-VILLE",
    value: "63469",
    type: "common",
  },
  {
    label: "COURPIERE",
    value: "63125",
    type: "common",
  },
  {
    label: "AUBUSSON-D'AUVERGNE",
    value: "63015",
    type: "common",
  },
  {
    label: "SERMENTIZON",
    value: "63418",
    type: "common",
  },
  {
    label: "RAVEL",
    value: "63296",
    type: "common",
  },
  {
    label: "NEUVILLE",
    value: "63252",
    type: "common",
  },
  {
    label: "GLAINE-MONTAIGUT",
    value: "63168",
    type: "common",
  },
  {
    label: "BORT-L'ETANG",
    value: "63045",
    type: "common",
  },
  {
    label: "VASSEL",
    value: "63445",
    type: "common",
  },
  {
    label: "REIGNAT",
    value: "63297",
    type: "common",
  },
  {
    label: "MOISSAT",
    value: "63229",
    type: "common",
  },
  {
    label: "ESPIRAT",
    value: "63154",
    type: "common",
  },
  {
    label: "CHAS",
    value: "63096",
    type: "common",
  },
  {
    label: "BOUZEL",
    value: "63049",
    type: "common",
  },
  {
    label: "VERTAIZON",
    value: "63453",
    type: "common",
  },
  {
    label: "SAINT-BONNET-LES-ALLIER",
    value: "63325",
    type: "common",
  },
  {
    label: "CHAURIAT",
    value: "63106",
    type: "common",
  },
  {
    label: "LEMPDES",
    value: "63193",
    type: "common",
  },
  {
    label: "COURNON-D'AUVERGNE",
    value: "63124",
    type: "common",
  },
  {
    label: "AUBIERE",
    value: "63014",
    type: "common",
  },
  {
    label: "ROYAT",
    value: "63308",
    type: "common",
  },
  {
    label: "CLERMONT-FERRAND",
    value: "63113",
    type: "common",
  },
  {
    label: "CHAMALIERES",
    value: "63075",
    type: "common",
  },
  {
    label: "CEYRAT",
    value: "63070",
    type: "common",
  },
  {
    label: "BEAUMONT",
    value: "63032",
    type: "common",
  },
  {
    label: "ORCINES",
    value: "63263",
    type: "common",
  },
  {
    label: "OLBY",
    value: "63257",
    type: "common",
  },
  {
    label: "MAZAYE",
    value: "63219",
    type: "common",
  },
  {
    label: "CEYSSAT",
    value: "63071",
    type: "common",
  },
  {
    label: "GELLES",
    value: "63163",
    type: "common",
  },
  {
    label: "SAUVAGNAT",
    value: "63410",
    type: "common",
  },
  {
    label: "PUY-SAINT-GULMIER",
    value: "63292",
    type: "common",
  },
  {
    label: "PRONDINES",
    value: "63289",
    type: "common",
  },
  {
    label: "SAINT-GERMAIN-PRES-HERMENT",
    value: "63351",
    type: "common",
  },
  {
    label: "HERMENT",
    value: "63175",
    type: "common",
  },
  {
    label: "VERNEUGHEOL",
    value: "63450",
    type: "common",
  },
  {
    label: "SAINT-MERD-LA-BREUILLE",
    value: "23221",
    type: "common",
  },
  {
    label: "FLAYAT",
    value: "23081",
    type: "common",
  },
  {
    label: "MALLERET",
    value: "23119",
    type: "common",
  },
  {
    label: "BEISSAT",
    value: "23019",
    type: "common",
  },
  {
    label: "LA COURTINE",
    value: "23067",
    type: "common",
  },
  {
    label: "LE MAS-D'ARTIGE",
    value: "23125",
    type: "common",
  },
  {
    label: "CLAIRAVAUX",
    value: "23063",
    type: "common",
  },
  {
    label: "GENTIOUX-PIGEROLLES",
    value: "23090",
    type: "common",
  },
  {
    label: "FAUX-LA-MONTAGNE",
    value: "23077",
    type: "common",
  },
  {
    label: "SAINT-AMAND-LE-PETIT",
    value: "87132",
    type: "common",
  },
  {
    label: "BEAUMONT-DU-LAC",
    value: "87009",
    type: "common",
  },
  {
    label: "AUGNE",
    value: "87004",
    type: "common",
  },
  {
    label: "NEUVIC-ENTIER",
    value: "87105",
    type: "common",
  },
  {
    label: "SAINT-DENIS-DES-MURS",
    value: "87142",
    type: "common",
  },
  {
    label: "ROZIERS-SAINT-GEORGES",
    value: "87130",
    type: "common",
  },
  {
    label: "MASLEON",
    value: "87093",
    type: "common",
  },
  {
    label: "SAINT-PAUL",
    value: "87174",
    type: "common",
  },
  {
    label: "EYJEAUX",
    value: "87063",
    type: "common",
  },
  {
    label: "BOISSEUIL",
    value: "87019",
    type: "common",
  },
  {
    label: "SOLIGNAC",
    value: "87192",
    type: "common",
  },
  {
    label: "CONDAT-SUR-VIENNE",
    value: "87048",
    type: "common",
  },
  {
    label: "BOSMIE-L'AIGUILLE",
    value: "87021",
    type: "common",
  },
  {
    label: "SAINT-MARTIN-LE-VIEUX",
    value: "87166",
    type: "common",
  },
  {
    label: "BURGNAC",
    value: "87025",
    type: "common",
  },
  {
    label: "BEYNAC",
    value: "87015",
    type: "common",
  },
  {
    label: "SEREILHAC",
    value: "87191",
    type: "common",
  },
  {
    label: "SAINT-LAURENT-SUR-GORRE",
    value: "87158",
    type: "common",
  },
  {
    label: "GORRE",
    value: "87073",
    type: "common",
  },
  {
    label: "ORADOUR-SUR-VAYRES",
    value: "87111",
    type: "common",
  },
  {
    label: "VAYRES",
    value: "87199",
    type: "common",
  },
  {
    label: "SAINT-BAZILE",
    value: "87137",
    type: "common",
  },
  {
    label: "VIDEIX",
    value: "87204",
    type: "common",
  },
  {
    label: "LES SALLES-LAVAUGUYON",
    value: "87189",
    type: "common",
  },
  {
    label: "CHERONNAC",
    value: "87044",
    type: "common",
  },
  {
    label: "SAUVAGNAC",
    value: "16364",
    type: "common",
  },
  {
    label: "ROUSSINES",
    value: "16289",
    type: "common",
  },
  {
    label: "MASSIGNAC",
    value: "16212",
    type: "common",
  },
  {
    label: "MONTEMBŒUF",
    value: "16225",
    type: "common",
  },
  {
    label: "MAZEROLLES",
    value: "16213",
    type: "common",
  },
  {
    label: "LE LINDOIS",
    value: "16188",
    type: "common",
  },
  {
    label: "YVRAC-ET-MALLEYRAND",
    value: "16425",
    type: "common",
  },
  {
    label: "SAINT-ADJUTORY",
    value: "16293",
    type: "common",
  },
  {
    label: "MARILLAC-LE-FRANC",
    value: "16209",
    type: "common",
  },
  {
    label: "TAPONNAT-FLEURIGNAC",
    value: "16379",
    type: "common",
  },
  {
    label: "RIVIERES",
    value: "16280",
    type: "common",
  },
  {
    label: "BRIE",
    value: "16061",
    type: "common",
  },
  {
    label: "CHAMPNIERS",
    value: "16078",
    type: "common",
  },
  {
    label: "ANAIS",
    value: "16011",
    type: "common",
  },
  {
    label: "VINDELLE",
    value: "16415",
    type: "common",
  },
  {
    label: "VARS",
    value: "16393",
    type: "common",
  },
  {
    label: "SAINT-GENIS-D'HIERSAC",
    value: "16320",
    type: "common",
  },
  {
    label: "MARSAC",
    value: "16210",
    type: "common",
  },
  {
    label: "SAINT-AMANT-DE-NOUERE",
    value: "16298",
    type: "common",
  },
  {
    label: "ECHALLAT",
    value: "16123",
    type: "common",
  },
  {
    label: "VAUX-ROUILLAC",
    value: "16395",
    type: "common",
  },
  {
    label: "MAREUIL",
    value: "16208",
    type: "common",
  },
  {
    label: "FOUSSIGNAC",
    value: "16145",
    type: "common",
  },
  {
    label: "FLEURAC",
    value: "16139",
    type: "common",
  },
  {
    label: "SIGOGNE",
    value: "16369",
    type: "common",
  },
  {
    label: "REPARSAC",
    value: "16277",
    type: "common",
  },
  {
    label: "HOULETTE",
    value: "16165",
    type: "common",
  },
  {
    label: "CHASSORS",
    value: "16088",
    type: "common",
  },
  {
    label: "NERCILLAC",
    value: "16243",
    type: "common",
  },
  {
    label: "BOUTIERS-SAINT-TROJAN",
    value: "16058",
    type: "common",
  },
  {
    label: "SAINT-SULPICE-DE-COGNAC",
    value: "16355",
    type: "common",
  },
  {
    label: "LOUZAC-SAINT-ANDRE",
    value: "16193",
    type: "common",
  },
  {
    label: "CHERVES-RICHEMONT",
    value: "16097",
    type: "common",
  },
  {
    label: "SAINT-SAUVANT",
    value: "17395",
    type: "common",
  },
  {
    label: "SAINT-CESAIRE",
    value: "17314",
    type: "common",
  },
  {
    label: "DOMPIERRE-SUR-CHARENTE",
    value: "17141",
    type: "common",
  },
  {
    label: "CHERAC",
    value: "17100",
    type: "common",
  },
  {
    label: "COURCOURY",
    value: "17128",
    type: "common",
  },
  {
    label: "CHANIERS",
    value: "17086",
    type: "common",
  },
  {
    label: "SAINTES",
    value: "17415",
    type: "common",
  },
  {
    label: "LES GONDS",
    value: "17179",
    type: "common",
  },
  {
    label: "VARZAY",
    value: "17460",
    type: "common",
  },
  {
    label: "PESSINES",
    value: "17275",
    type: "common",
  },
  {
    label: "PISANY",
    value: "17278",
    type: "common",
  },
  {
    label: "LUCHAT",
    value: "17214",
    type: "common",
  },
  {
    label: "LA CLISSE",
    value: "17112",
    type: "common",
  },
  {
    label: "SABLONCEAUX",
    value: "17307",
    type: "common",
  },
  {
    label: "NANCRAS",
    value: "17255",
    type: "common",
  },
  {
    label: "BALANZAC",
    value: "17030",
    type: "common",
  },
  {
    label: "LE GUA",
    value: "17185",
    type: "common",
  },
  {
    label: "L'EGUILLE",
    value: "17151",
    type: "common",
  },
  {
    label: "MORNAC-SUR-SEUDRE",
    value: "17247",
    type: "common",
  },
  {
    label: "CHAILLEVETTE",
    value: "17079",
    type: "common",
  },
  {
    label: "BREUILLET",
    value: "17064",
    type: "common",
  },
  {
    label: "SAINT-AUGUSTIN",
    value: "17311",
    type: "common",
  },
  {
    label: "LES MATHES",
    value: "17225",
    type: "common",
  },
  {
    label: "ETAULES",
    value: "17155",
    type: "common",
  },
  {
    label: "SAINTE-FOY-TARENTAISE",
    value: "73232",
    type: "common",
  },
  {
    label: "MONTVALEZAN",
    value: "73176",
    type: "common",
  },
  {
    label: "LES CHAPELLES",
    value: "73077",
    type: "common",
  },
  {
    label: "CEVINS",
    value: "73063",
    type: "common",
  },
  {
    label: "ESSERTS-BLAY",
    value: "73110",
    type: "common",
  },
  {
    label: "LA BATHIE",
    value: "73032",
    type: "common",
  },
  {
    label: "TOURNON",
    value: "73297",
    type: "common",
  },
  {
    label: "NOTRE-DAME-DES-MILLIERES",
    value: "73188",
    type: "common",
  },
  {
    label: "MONTHION",
    value: "73170",
    type: "common",
  },
  {
    label: "GRIGNON",
    value: "73130",
    type: "common",
  },
  {
    label: "GILLY-SUR-ISERE",
    value: "73124",
    type: "common",
  },
  {
    label: "VERRENS-ARVEY",
    value: "73312",
    type: "common",
  },
  {
    label: "SAINT-VITAL",
    value: "73283",
    type: "common",
  },
  {
    label: "MONTAILLEUR",
    value: "73162",
    type: "common",
  },
  {
    label: "FRONTENEX",
    value: "73121",
    type: "common",
  },
  {
    label: "CLERY",
    value: "73086",
    type: "common",
  },
  {
    label: "JARSY",
    value: "73139",
    type: "common",
  },
  {
    label: "ECOLE",
    value: "73106",
    type: "common",
  },
  {
    label: "LA COMPOTE",
    value: "73090",
    type: "common",
  },
  {
    label: "LE NOYER",
    value: "73192",
    type: "common",
  },
  {
    label: "AILLON-LE-VIEUX",
    value: "73005",
    type: "common",
  },
  {
    label: "LES DESERTS",
    value: "73098",
    type: "common",
  },
  {
    label: "VOGLANS",
    value: "73329",
    type: "common",
  },
  {
    label: "VIVIERS-DU-LAC",
    value: "73328",
    type: "common",
  },
  {
    label: "VEREL-PRAGONDRAN",
    value: "73310",
    type: "common",
  },
  {
    label: "MERY",
    value: "73155",
    type: "common",
  },
  {
    label: "DRUMETTAZ-CLARAFOND",
    value: "73103",
    type: "common",
  },
  {
    label: "TRESSERVE",
    value: "73300",
    type: "common",
  },
  {
    label: "LE BOURGET-DU-LAC",
    value: "73051",
    type: "common",
  },
  {
    label: "BOURDEAU",
    value: "73050",
    type: "common",
  },
  {
    label: "VERTHEMEX",
    value: "73313",
    type: "common",
  },
  {
    label: "TRAIZE",
    value: "73299",
    type: "common",
  },
  {
    label: "SAINT-PAUL",
    value: "73269",
    type: "common",
  },
  {
    label: "MEYRIEUX-TROUET",
    value: "73156",
    type: "common",
  },
  {
    label: "LOISIEUX",
    value: "73147",
    type: "common",
  },
  {
    label: "LA CHAPELLE-SAINT-MARTIN",
    value: "73078",
    type: "common",
  },
  {
    label: "PREMEYZEL",
    value: "01310",
    type: "common",
  },
  {
    label: "PEYRIEU",
    value: "01294",
    type: "common",
  },
  {
    label: "MURS-ET-GELIGNIEUX",
    value: "01268",
    type: "common",
  },
  {
    label: "CHAMPAGNEUX",
    value: "73070",
    type: "common",
  },
  {
    label: "LA BALME",
    value: "73028",
    type: "common",
  },
  {
    label: "GROSLEE-SAINT-BENOIT",
    value: "01338",
    type: "common",
  },
  {
    label: "IZIEU",
    value: "01193",
    type: "common",
  },
  {
    label: "BREGNIER-CORDON",
    value: "01058",
    type: "common",
  },
  {
    label: "LES AVENIERES VEYRINS-THUELLIN",
    value: "38022",
    type: "common",
  },
  {
    label: "LE BOUCHAGE",
    value: "38050",
    type: "common",
  },
  {
    label: "VEZERONCE-CURTIN",
    value: "38543",
    type: "common",
  },
  {
    label: "SERMERIEU",
    value: "38483",
    type: "common",
  },
  {
    label: "ARANDON-PASSINS",
    value: "38297",
    type: "common",
  },
  {
    label: "MORESTEL",
    value: "38261",
    type: "common",
  },
  {
    label: "TREPT",
    value: "38515",
    type: "common",
  },
  {
    label: "SALAGNON",
    value: "38467",
    type: "common",
  },
  {
    label: "SAINT-CHEF",
    value: "38374",
    type: "common",
  },
  {
    label: "VEYSSILIEU",
    value: "38542",
    type: "common",
  },
  {
    label: "VENERIEU",
    value: "38532",
    type: "common",
  },
  {
    label: "SAINT-MARCEL-BEL-ACCUEIL",
    value: "38415",
    type: "common",
  },
  {
    label: "SAINT-HILAIRE-DE-BRENS",
    value: "38392",
    type: "common",
  },
  {
    label: "MORAS",
    value: "38260",
    type: "common",
  },
  {
    label: "PANOSSAS",
    value: "38294",
    type: "common",
  },
  {
    label: "FRONTONAS",
    value: "38176",
    type: "common",
  },
  {
    label: "CHOZEAU",
    value: "38109",
    type: "common",
  },
  {
    label: "CHAMAGNIEU",
    value: "38067",
    type: "common",
  },
  {
    label: "SAINT-LAURENT-DE-MURE",
    value: "69288",
    type: "common",
  },
  {
    label: "SATOLAS-ET-BONCE",
    value: "38475",
    type: "common",
  },
  {
    label: "GRENAY",
    value: "38184",
    type: "common",
  },
  {
    label: "TOUSSIEU",
    value: "69298",
    type: "common",
  },
  {
    label: "SAINT-BONNET-DE-MURE",
    value: "69287",
    type: "common",
  },
  {
    label: "SAINT-PRIEST",
    value: "69290",
    type: "common",
  },
  {
    label: "MIONS",
    value: "69283",
    type: "common",
  },
  {
    label: "CORBAS",
    value: "69273",
    type: "common",
  },
  {
    label: "FEYZIN",
    value: "69276",
    type: "common",
  },
  {
    label: "VENISSIEUX",
    value: "69259",
    type: "common",
  },
  {
    label: "SAINT-FONS",
    value: "69199",
    type: "common",
  },
  {
    label: "PIERRE-BENITE",
    value: "69152",
    type: "common",
  },
  {
    label: "IRIGNY",
    value: "69100",
    type: "common",
  },
  {
    label: "VOURLES",
    value: "69268",
    type: "common",
  },
  {
    label: "SAINT-GENIS-LAVAL",
    value: "69204",
    type: "common",
  },
  {
    label: "ORLIENAS",
    value: "69148",
    type: "common",
  },
  {
    label: "BRIGNAIS",
    value: "69027",
    type: "common",
  },
  {
    label: "SOUCIEU-EN-JARREST",
    value: "69176",
    type: "common",
  },
  {
    label: "MESSIMY",
    value: "69131",
    type: "common",
  },
  {
    label: "YZERON",
    value: "69269",
    type: "common",
  },
  {
    label: "THURINS",
    value: "69249",
    type: "common",
  },
  {
    label: "SAINT-MARTIN-EN-HAUT",
    value: "69227",
    type: "common",
  },
  {
    label: "RONTALON",
    value: "69170",
    type: "common",
  },
  {
    label: "SAINTE-FOY-L'ARGENTIERE",
    value: "69201",
    type: "common",
  },
  {
    label: "MONTROMANT",
    value: "69138",
    type: "common",
  },
  {
    label: "DUERNE",
    value: "69078",
    type: "common",
  },
  {
    label: "AVEIZE",
    value: "69014",
    type: "common",
  },
  {
    label: "SOUZY",
    value: "69178",
    type: "common",
  },
  {
    label: "MEYS",
    value: "69132",
    type: "common",
  },
  {
    label: "GREZIEU-LE-MARCHE",
    value: "69095",
    type: "common",
  },
  {
    label: "VIRIGNEUX",
    value: "42336",
    type: "common",
  },
  {
    label: "MARINGES",
    value: "42138",
    type: "common",
  },
  {
    label: "VALEILLE",
    value: "42319",
    type: "common",
  },
  {
    label: "SAINT-LAURENT-LA-CONCHE",
    value: "42251",
    type: "common",
  },
  {
    label: "SAINT-CYR-LES-VIGNES",
    value: "42214",
    type: "common",
  },
  {
    label: "MARCLOPT",
    value: "42135",
    type: "common",
  },
  {
    label: "MAGNEUX-HAUTE-RIVE",
    value: "42130",
    type: "common",
  },
  {
    label: "CHAMBEON",
    value: "42041",
    type: "common",
  },
  {
    label: "MORNAND-EN-FOREZ",
    value: "42151",
    type: "common",
  },
  {
    label: "SAINT-PAUL-D'UZORE",
    value: "42269",
    type: "common",
  },
  {
    label: "MONTVERDUN",
    value: "42150",
    type: "common",
  },
  {
    label: "CHALAIN-D'UZORE",
    value: "42037",
    type: "common",
  },
  {
    label: "MARCOUX",
    value: "42136",
    type: "common",
  },
  {
    label: "MARCILLY-LE-CHATEL",
    value: "42134",
    type: "common",
  },
  {
    label: "SAINT-GEORGES-EN-COUZAN",
    value: "42227",
    type: "common",
  },
  {
    label: "SAUVAIN",
    value: "42298",
    type: "common",
  },
  {
    label: "CHALMAZEL-JEANSAGNIERE",
    value: "42039",
    type: "common",
  },
  {
    label: "SAINT-PIERRE-LA-BOURLHONNE",
    value: "63384",
    type: "common",
  },
  {
    label: "LE BRUGERON",
    value: "63057",
    type: "common",
  },
  {
    label: "OLMET",
    value: "63260",
    type: "common",
  },
  {
    label: "OLLIERGUES",
    value: "63258",
    type: "common",
  },
  {
    label: "TOURS-SUR-MEYMONT",
    value: "63434",
    type: "common",
  },
  {
    label: "SAUVIAT",
    value: "63414",
    type: "common",
  },
  {
    label: "SAINT-GERVAIS-SOUS-MEYMONT",
    value: "63355",
    type: "common",
  },
  {
    label: "TREZIOUX",
    value: "63438",
    type: "common",
  },
  {
    label: "SAINT-FLOUR-L'ETANG",
    value: "63343",
    type: "common",
  },
  {
    label: "DOMAIZE",
    value: "63136",
    type: "common",
  },
  {
    label: "MAUZUN",
    value: "63216",
    type: "common",
  },
  {
    label: "FAYET-LE-CHATEAU",
    value: "63157",
    type: "common",
  },
  {
    label: "ESTANDEUIL",
    value: "63155",
    type: "common",
  },
  {
    label: "EGLISENEUVE-PRES-BILLOM",
    value: "63146",
    type: "common",
  },
  {
    label: "BONGHEAT",
    value: "63044",
    type: "common",
  },
  {
    label: "SAINT-JULIEN-DE-COPPEL",
    value: "63368",
    type: "common",
  },
  {
    label: "MONTMORIN",
    value: "63239",
    type: "common",
  },
  {
    label: "BILLOM",
    value: "63040",
    type: "common",
  },
  {
    label: "SAINT-GEORGES-SUR-ALLIER",
    value: "63350",
    type: "common",
  },
  {
    label: "LA ROCHE-NOIRE",
    value: "63306",
    type: "common",
  },
  {
    label: "PERIGNAT-SUR-ALLIER",
    value: "63273",
    type: "common",
  },
  {
    label: "MIREFLEURS",
    value: "63227",
    type: "common",
  },
  {
    label: "BUSSEOL",
    value: "63059",
    type: "common",
  },
  {
    label: "VEYRE-MONTON",
    value: "63455",
    type: "common",
  },
  {
    label: "LA ROCHE-BLANCHE",
    value: "63302",
    type: "common",
  },
  {
    label: "PERIGNAT-LES-SARLIEVE",
    value: "63272",
    type: "common",
  },
  {
    label: "ORCET",
    value: "63262",
    type: "common",
  },
  {
    label: "LES MARTRES-DE-VEYRE",
    value: "63214",
    type: "common",
  },
  {
    label: "LE CREST",
    value: "63126",
    type: "common",
  },
  {
    label: "LE CENDRE",
    value: "63069",
    type: "common",
  },
  {
    label: "ROMAGNAT",
    value: "63307",
    type: "common",
  },
  {
    label: "CHANONAT",
    value: "63084",
    type: "common",
  },
  {
    label: "SAINT-GENES-CHAMPANELLE",
    value: "63345",
    type: "common",
  },
  {
    label: "SAINT-BONNET-PRES-ORCIVAL",
    value: "63326",
    type: "common",
  },
  {
    label: "NEBOUZAT",
    value: "63248",
    type: "common",
  },
  {
    label: "AURIERES",
    value: "63020",
    type: "common",
  },
  {
    label: "SAINT-PIERRE-ROCHE",
    value: "63386",
    type: "common",
  },
  {
    label: "HEUME-L'EGLISE",
    value: "63176",
    type: "common",
  },
  {
    label: "TORTEBESSE",
    value: "63433",
    type: "common",
  },
  {
    label: "BRIFFONS",
    value: "63053",
    type: "common",
  },
  {
    label: "LASTIC",
    value: "63191",
    type: "common",
  },
  {
    label: "LAROCHE-PRES-FEYT",
    value: "19108",
    type: "common",
  },
  {
    label: "FEYT",
    value: "19083",
    type: "common",
  },
  {
    label: "EYGURANDE",
    value: "19080",
    type: "common",
  },
  {
    label: "SAINT-ORADOUX-DE-CHIROUZE",
    value: "23224",
    type: "common",
  },
  {
    label: "SAINT-MARTIAL-LE-VIEUX",
    value: "23215",
    type: "common",
  },
  {
    label: "SORNAC",
    value: "19261",
    type: "common",
  },
  {
    label: "FENIERS",
    value: "23080",
    type: "common",
  },
  {
    label: "SAINT-SETIERS",
    value: "19241",
    type: "common",
  },
  {
    label: "PEYRELEVADE",
    value: "19164",
    type: "common",
  },
  {
    label: "REMPNAT",
    value: "87123",
    type: "common",
  },
  {
    label: "LA VILLEDIEU",
    value: "23264",
    type: "common",
  },
  {
    label: "NEDDE",
    value: "87104",
    type: "common",
  },
  {
    label: "EYMOUTIERS",
    value: "87064",
    type: "common",
  },
  {
    label: "SAINTE-ANNE-SAINT-PRIEST",
    value: "87134",
    type: "common",
  },
  {
    label: "CHATEAUNEUF-LA-FORET",
    value: "87040",
    type: "common",
  },
  {
    label: "LINARDS",
    value: "87086",
    type: "common",
  },
  {
    label: "SAINT-BONNET-BRIANCE",
    value: "87138",
    type: "common",
  },
  {
    label: "SAINT-HILAIRE-BONNEVAL",
    value: "87148",
    type: "common",
  },
  {
    label: "SAINT-GENEST-SUR-ROSELLE",
    value: "87144",
    type: "common",
  },
  {
    label: "PIERRE-BUFFIERE",
    value: "87119",
    type: "common",
  },
  {
    label: "LE VIGEN",
    value: "87205",
    type: "common",
  },
  {
    label: "SAINT-JEAN-LIGOURE",
    value: "87151",
    type: "common",
  },
  {
    label: "SAINT-MAURICE-LES-BROUSSES",
    value: "87169",
    type: "common",
  },
  {
    label: "JOURGNAC",
    value: "87081",
    type: "common",
  },
  {
    label: "MEILHAC",
    value: "87094",
    type: "common",
  },
  {
    label: "LAVIGNAC",
    value: "87084",
    type: "common",
  },
  {
    label: "PAGEAS",
    value: "87112",
    type: "common",
  },
  {
    label: "FLAVIGNAC",
    value: "87066",
    type: "common",
  },
  {
    label: "LES CARS",
    value: "87029",
    type: "common",
  },
  {
    label: "CHAMPSAC",
    value: "87036",
    type: "common",
  },
  {
    label: "CUSSAC",
    value: "87054",
    type: "common",
  },
  {
    label: "CHAMPAGNAC-LA-RIVIERE",
    value: "87034",
    type: "common",
  },
  {
    label: "SAINT-MATHIEU",
    value: "87168",
    type: "common",
  },
  {
    label: "MAISONNAIS-SUR-TARDOIRE",
    value: "87091",
    type: "common",
  },
  {
    label: "CHAMPNIERS-ET-REILHAC",
    value: "24100",
    type: "common",
  },
  {
    label: "BUSSEROLLES",
    value: "24070",
    type: "common",
  },
  {
    label: "ROUZEDE",
    value: "16290",
    type: "common",
  },
  {
    label: "ECURAS",
    value: "16124",
    type: "common",
  },
  {
    label: "SAINT-SORNIN",
    value: "16353",
    type: "common",
  },
  {
    label: "ORGEDEUIL",
    value: "16250",
    type: "common",
  },
  {
    label: "MONTBRON",
    value: "16223",
    type: "common",
  },
  {
    label: "PRANZAC",
    value: "16269",
    type: "common",
  },
  {
    label: "BUNZAC",
    value: "16067",
    type: "common",
  },
  {
    label: "TOUVRE",
    value: "16385",
    type: "common",
  },
  {
    label: "MORNAC",
    value: "16232",
    type: "common",
  },
  {
    label: "RUELLE-SUR-TOUVRE",
    value: "16291",
    type: "common",
  },
  {
    label: "MAGNAC-SUR-TOUVRE",
    value: "16199",
    type: "common",
  },
  {
    label: "L'ISLE-D'ESPAGNAC",
    value: "16166",
    type: "common",
  },
  {
    label: "SAINT-YRIEIX-SUR-CHARENTE",
    value: "16358",
    type: "common",
  },
  {
    label: "GOND-PONTOUVRE",
    value: "16154",
    type: "common",
  },
  {
    label: "BALZAC",
    value: "16026",
    type: "common",
  },
  {
    label: "SAINT-SATURNIN",
    value: "16348",
    type: "common",
  },
  {
    label: "HIERSAC",
    value: "16163",
    type: "common",
  },
  {
    label: "FLEAC",
    value: "16138",
    type: "common",
  },
  {
    label: "ASNIERES-SUR-NOUERE",
    value: "16019",
    type: "common",
  },
  {
    label: "SAINT-SIMON",
    value: "16352",
    type: "common",
  },
  {
    label: "MOULIDARS",
    value: "16234",
    type: "common",
  },
  {
    label: "MERIGNAC",
    value: "16216",
    type: "common",
  },
  {
    label: "DOUZAT",
    value: "16121",
    type: "common",
  },
  {
    label: "TRIAC-LAUTRAIT",
    value: "16387",
    type: "common",
  },
  {
    label: "BASSAC",
    value: "16032",
    type: "common",
  },
  {
    label: "LES METAIRIES",
    value: "16220",
    type: "common",
  },
  {
    label: "JULIENNE",
    value: "16174",
    type: "common",
  },
  {
    label: "JARNAC",
    value: "16167",
    type: "common",
  },
  {
    label: "BOURG-CHARENTE",
    value: "16056",
    type: "common",
  },
  {
    label: "SAINT-BRICE",
    value: "16304",
    type: "common",
  },
  {
    label: "GENSAC-LA-PALLUE",
    value: "16150",
    type: "common",
  },
  {
    label: "COGNAC",
    value: "16102",
    type: "common",
  },
  {
    label: "CHATEAUBERNARD",
    value: "16089",
    type: "common",
  },
  {
    label: "SAINT-LAURENT-DE-COGNAC",
    value: "16330",
    type: "common",
  },
  {
    label: "MERPINS",
    value: "16217",
    type: "common",
  },
  {
    label: "JAVREZAC",
    value: "16169",
    type: "common",
  },
  {
    label: "SALIGNAC-SUR-CHARENTE",
    value: "17418",
    type: "common",
  },
  {
    label: "ROUFFIAC",
    value: "17304",
    type: "common",
  },
  {
    label: "BRIVES-SUR-CHARENTE",
    value: "17069",
    type: "common",
  },
  {
    label: "SAINT-SEVER-DE-SAINTONGE",
    value: "17400",
    type: "common",
  },
  {
    label: "MONTILS",
    value: "17242",
    type: "common",
  },
  {
    label: "LA JARD",
    value: "17191",
    type: "common",
  },
  {
    label: "THENAC",
    value: "17444",
    type: "common",
  },
  {
    label: "PREGUILLAC",
    value: "17289",
    type: "common",
  },
  {
    label: "BERNEUIL",
    value: "17044",
    type: "common",
  },
  {
    label: "RIOUX",
    value: "17298",
    type: "common",
  },
  {
    label: "RETAUD",
    value: "17296",
    type: "common",
  },
  {
    label: "CHERMIGNAC",
    value: "17102",
    type: "common",
  },
  {
    label: "THEZAC",
    value: "17445",
    type: "common",
  },
  {
    label: "MEURSAC",
    value: "17232",
    type: "common",
  },
  {
    label: "SAINT-ROMAIN-DE-BENET",
    value: "17393",
    type: "common",
  },
  {
    label: "CORME-ECLUSE",
    value: "17119",
    type: "common",
  },
  {
    label: "LE CHAY",
    value: "17097",
    type: "common",
  },
  {
    label: "SAUJON",
    value: "17421",
    type: "common",
  },
  {
    label: "SAINT-SULPICE-DE-ROYAN",
    value: "17409",
    type: "common",
  },
  {
    label: "MEDIS",
    value: "17228",
    type: "common",
  },
  {
    label: "VAUX-SUR-MER",
    value: "17461",
    type: "common",
  },
  {
    label: "ROYAN",
    value: "17306",
    type: "common",
  },
  {
    label: "SAINT-PALAIS-SUR-MER",
    value: "17380",
    type: "common",
  },
  {
    label: "VILLAROGER",
    value: "73323",
    type: "common",
  },
  {
    label: "LANDRY",
    value: "73142",
    type: "common",
  },
  {
    label: "AIME-LA-PLAGNE",
    value: "73006",
    type: "common",
  },
  {
    label: "LA LECHERE",
    value: "73187",
    type: "common",
  },
  {
    label: "SAINT-PAUL-SUR-ISERE",
    value: "73268",
    type: "common",
  },
  {
    label: "ROGNAIX",
    value: "73216",
    type: "common",
  },
  {
    label: "SAINTE-HELENE-SUR-ISERE",
    value: "73241",
    type: "common",
  },
  {
    label: "BONVILLARET",
    value: "73049",
    type: "common",
  },
  {
    label: "BONVILLARD",
    value: "73048",
    type: "common",
  },
  {
    label: "GRESY-SUR-ISERE",
    value: "73129",
    type: "common",
  },
  {
    label: "BOURGNEUF",
    value: "73053",
    type: "common",
  },
  {
    label: "AITON",
    value: "73007",
    type: "common",
  },
  {
    label: "SAINTE-REINE",
    value: "73277",
    type: "common",
  },
  {
    label: "SAINT-PIERRE-D'ALBIGNY",
    value: "73270",
    type: "common",
  },
  {
    label: "FRETERIVE",
    value: "73120",
    type: "common",
  },
  {
    label: "CHAMOUSSET",
    value: "73068",
    type: "common",
  },
  {
    label: "AILLON-LE-JEUNE",
    value: "73004",
    type: "common",
  },
  {
    label: "THOIRY",
    value: "73293",
    type: "common",
  },
  {
    label: "SAINT-JEAN-D'ARVEY",
    value: "73243",
    type: "common",
  },
  {
    label: "PUYGROS",
    value: "73210",
    type: "common",
  },
  {
    label: "CURIENNE",
    value: "73097",
    type: "common",
  },
  {
    label: "BARBY",
    value: "73030",
    type: "common",
  },
  {
    label: "SONNAZ",
    value: "73288",
    type: "common",
  },
  {
    label: "SAINT-ALBAN-LEYSSE",
    value: "73222",
    type: "common",
  },
  {
    label: "CHAMBERY",
    value: "73065",
    type: "common",
  },
  {
    label: "BASSENS",
    value: "73031",
    type: "common",
  },
  {
    label: "LA MOTTE-SERVOLEX",
    value: "73179",
    type: "common",
  },
  {
    label: "SAINT-PIERRE-D'ALVEY",
    value: "73271",
    type: "common",
  },
  {
    label: "NOVALAISE",
    value: "73191",
    type: "common",
  },
  {
    label: "NANCES",
    value: "73184",
    type: "common",
  },
  {
    label: "MARCIEUX",
    value: "73152",
    type: "common",
  },
  {
    label: "GERBAIX",
    value: "73122",
    type: "common",
  },
  {
    label: "AYN",
    value: "73027",
    type: "common",
  },
  {
    label: "SAINTE-MARIE-D'ALVEY",
    value: "73254",
    type: "common",
  },
  {
    label: "ROCHEFORT",
    value: "73214",
    type: "common",
  },
  {
    label: "GRANIEU",
    value: "38183",
    type: "common",
  },
  {
    label: "CHIMILIN",
    value: "38104",
    type: "common",
  },
  {
    label: "AOSTE",
    value: "38012",
    type: "common",
  },
  {
    label: "SAINT-SORLIN-DE-MORESTEL",
    value: "38458",
    type: "common",
  },
  {
    label: "FAVERGES-DE-LA-TOUR",
    value: "38162",
    type: "common",
  },
  {
    label: "DOLOMIEU",
    value: "38148",
    type: "common",
  },
  {
    label: "CORBELIN",
    value: "38124",
    type: "common",
  },
  {
    label: "LA CHAPELLE-DE-LA-TOUR",
    value: "38076",
    type: "common",
  },
  {
    label: "LA BATIE-MONTGASCON",
    value: "38029",
    type: "common",
  },
  {
    label: "VASSELIN",
    value: "38525",
    type: "common",
  },
  {
    label: "VIGNIEU",
    value: "38546",
    type: "common",
  },
  {
    label: "LA TOUR-DU-PIN",
    value: "38509",
    type: "common",
  },
  {
    label: "SAINT-JEAN-DE-SOUDAIN",
    value: "38401",
    type: "common",
  },
  {
    label: "ROCHETOIRIN",
    value: "38341",
    type: "common",
  },
  {
    label: "MONTCARRA",
    value: "38250",
    type: "common",
  },
  {
    label: "SAINT-SAVIN",
    value: "38455",
    type: "common",
  },
  {
    label: "RUY-MONTCEAU",
    value: "38348",
    type: "common",
  },
  {
    label: "SAINT-ALBAN-DE-ROCHE",
    value: "38352",
    type: "common",
  },
  {
    label: "L'ISLE-D'ABEAU",
    value: "38193",
    type: "common",
  },
  {
    label: "BOURGOIN-JALLIEU",
    value: "38053",
    type: "common",
  },
  {
    label: "VILLEFONTAINE",
    value: "38553",
    type: "common",
  },
  {
    label: "LA VERPILLIERE",
    value: "38537",
    type: "common",
  },
  {
    label: "VAULX-MILIEU",
    value: "38530",
    type: "common",
  },
  {
    label: "SAINT-QUENTIN-FALLAVIER",
    value: "38449",
    type: "common",
  },
  {
    label: "HEYRIEUX",
    value: "38189",
    type: "common",
  },
  {
    label: "BONNEFAMILLE",
    value: "38048",
    type: "common",
  },
  {
    label: "SAINT-PIERRE-DE-CHANDIEU",
    value: "69289",
    type: "common",
  },
  {
    label: "VALENCIN",
    value: "38519",
    type: "common",
  },
  {
    label: "SAINT-JUST-CHALEYSSIN",
    value: "38408",
    type: "common",
  },
  {
    label: "MARENNES",
    value: "69281",
    type: "common",
  },
  {
    label: "CHAPONNAY",
    value: "69270",
    type: "common",
  },
  {
    label: "VILLETTE-DE-VIENNE",
    value: "38558",
    type: "common",
  },
  {
    label: "LUZINAY",
    value: "38215",
    type: "common",
  },
  {
    label: "TERNAY",
    value: "69297",
    type: "common",
  },
  {
    label: "SOLAIZE",
    value: "69296",
    type: "common",
  },
  {
    label: "SIMANDRES",
    value: "69295",
    type: "common",
  },
  {
    label: "SEREZIN-DU-RHONE",
    value: "69294",
    type: "common",
  },
  {
    label: "SAINT-SYMPHORIEN-D'OZON",
    value: "69291",
    type: "common",
  },
  {
    label: "COMMUNAY",
    value: "69272",
    type: "common",
  },
  {
    label: "VERNAISON",
    value: "69260",
    type: "common",
  },
  {
    label: "TALUYERS",
    value: "69241",
    type: "common",
  },
  {
    label: "MONTAGNY",
    value: "69136",
    type: "common",
  },
  {
    label: "MILLERY",
    value: "69133",
    type: "common",
  },
  {
    label: "GRIGNY",
    value: "69096",
    type: "common",
  },
  {
    label: "CHARLY",
    value: "69046",
    type: "common",
  },
  {
    label: "SAINT-LAURENT-D'AGNY",
    value: "69219",
    type: "common",
  },
  {
    label: "MORNANT",
    value: "69141",
    type: "common",
  },
  {
    label: "CHAUSSAN",
    value: "69051",
    type: "common",
  },
  {
    label: "SAINTE-CATHERINE",
    value: "69184",
    type: "common",
  },
  {
    label: "SAINT-ANDRE-LA-COTE",
    value: "69180",
    type: "common",
  },
  {
    label: "RIVERIE",
    value: "69166",
    type: "common",
  },
  {
    label: "LARAJASSE",
    value: "69110",
    type: "common",
  },
  {
    label: "COISE",
    value: "69062",
    type: "common",
  },
  {
    label: "LA CHAPELLE-SUR-COISE",
    value: "69042",
    type: "common",
  },
  {
    label: "SAINT-SYMPHORIEN-SUR-COISE",
    value: "69238",
    type: "common",
  },
  {
    label: "POMEYS",
    value: "69155",
    type: "common",
  },
  {
    label: "VIRICELLES",
    value: "42335",
    type: "common",
  },
  {
    label: "SAINT-DENIS-SUR-COISE",
    value: "42216",
    type: "common",
  },
  {
    label: "SAINT-GALMIER",
    value: "42222",
    type: "common",
  },
  {
    label: "CHAZELLES-SUR-LYON",
    value: "42059",
    type: "common",
  },
  {
    label: "BELLEGARDE-EN-FOREZ",
    value: "42013",
    type: "common",
  },
  {
    label: "UNIAS",
    value: "42315",
    type: "common",
  },
  {
    label: "SAINT-ANDRE-LE-PUY",
    value: "42200",
    type: "common",
  },
  {
    label: "MONTROND-LES-BAINS",
    value: "42149",
    type: "common",
  },
  {
    label: "CUZIEU",
    value: "42081",
    type: "common",
  },
  {
    label: "GREZIEUX-LE-FROMENTAL",
    value: "42105",
    type: "common",
  },
  {
    label: "CHALAIN-LE-COMTAL",
    value: "42038",
    type: "common",
  },
  {
    label: "BOISSET-LES-MONTROND",
    value: "42020",
    type: "common",
  },
  {
    label: "SAVIGNEUX",
    value: "42299",
    type: "common",
  },
  {
    label: "CHAMPDIEU",
    value: "42046",
    type: "common",
  },
  {
    label: "PRALONG",
    value: "42179",
    type: "common",
  },
  {
    label: "ESSERTINES-EN-CHATELNEUF",
    value: "42089",
    type: "common",
  },
  {
    label: "CHATELNEUF",
    value: "42054",
    type: "common",
  },
  {
    label: "SAINT-BONNET-LE-COURREAU",
    value: "42205",
    type: "common",
  },
  {
    label: "ROCHE",
    value: "42188",
    type: "common",
  },
  {
    label: "VERTOLAYE",
    value: "63454",
    type: "common",
  },
  {
    label: "JOB",
    value: "63179",
    type: "common",
  },
  {
    label: "MARAT",
    value: "63207",
    type: "common",
  },
  {
    label: "LA CHAPELLE-AGNON",
    value: "63086",
    type: "common",
  },
  {
    label: "BERTIGNAT",
    value: "63037",
    type: "common",
  },
  {
    label: "CUNLHAT",
    value: "63132",
    type: "common",
  },
  {
    label: "SAINT-DIER-D'AUVERGNE",
    value: "63334",
    type: "common",
  },
  {
    label: "CEILLOUX",
    value: "63065",
    type: "common",
  },
  {
    label: "SAINT-JEAN-DES-OLLIERES",
    value: "63365",
    type: "common",
  },
  {
    label: "ISSERTEAUX",
    value: "63177",
    type: "common",
  },
  {
    label: "SALLEDES",
    value: "63405",
    type: "common",
  },
  {
    label: "VIC-LE-COMTE",
    value: "63457",
    type: "common",
  },
  {
    label: "SAINT-MAURICE",
    value: "63378",
    type: "common",
  },
  {
    label: "PIGNOLS",
    value: "63280",
    type: "common",
  },
  {
    label: "PARENT",
    value: "63269",
    type: "common",
  },
  {
    label: "MONTPEYROUX",
    value: "63241",
    type: "common",
  },
  {
    label: "LAPS",
    value: "63188",
    type: "common",
  },
  {
    label: "TALLENDE",
    value: "63425",
    type: "common",
  },
  {
    label: "LA SAUVETAT",
    value: "63413",
    type: "common",
  },
  {
    label: "PLAUZAT",
    value: "63282",
    type: "common",
  },
  {
    label: "CORENT",
    value: "63120",
    type: "common",
  },
  {
    label: "AUTHEZAT",
    value: "63021",
    type: "common",
  },
  {
    label: "SAINT-SATURNIN",
    value: "63396",
    type: "common",
  },
  {
    label: "SAINT-SANDOUX",
    value: "63395",
    type: "common",
  },
  {
    label: "SAINT-AMANT-TALLENDE",
    value: "63315",
    type: "common",
  },
  {
    label: "OLLOIX",
    value: "63259",
    type: "common",
  },
  {
    label: "COURNOLS",
    value: "63123",
    type: "common",
  },
  {
    label: "AYDAT",
    value: "63026",
    type: "common",
  },
  {
    label: "VERNINES",
    value: "63451",
    type: "common",
  },
  {
    label: "LE VERNET-SAINTE-MARGUERITE",
    value: "63449",
    type: "common",
  },
  {
    label: "SAULZET-LE-FROID",
    value: "63407",
    type: "common",
  },
  {
    label: "ROCHEFORT-MONTAGNE",
    value: "63305",
    type: "common",
  },
  {
    label: "ORCIVAL",
    value: "63264",
    type: "common",
  },
  {
    label: "PERPEZAT",
    value: "63274",
    type: "common",
  },
  {
    label: "LAQUEUILLE",
    value: "63189",
    type: "common",
  },
  {
    label: "SAINT-SULPICE",
    value: "63399",
    type: "common",
  },
  {
    label: "SAINT-JULIEN-PUY-LAVEZE",
    value: "63370",
    type: "common",
  },
  {
    label: "BOURG-LASTIC",
    value: "63048",
    type: "common",
  },
  {
    label: "MONESTIER-MERLINES",
    value: "19141",
    type: "common",
  },
  {
    label: "MERLINES",
    value: "19134",
    type: "common",
  },
  {
    label: "LAMAZIERE-HAUTE",
    value: "19103",
    type: "common",
  },
  {
    label: "AIX",
    value: "19002",
    type: "common",
  },
  {
    label: "COURTEIX",
    value: "19065",
    type: "common",
  },
  {
    label: "COUFFY-SUR-SARSONNE",
    value: "19064",
    type: "common",
  },
  {
    label: "SAINT-REMY",
    value: "19238",
    type: "common",
  },
  {
    label: "BELLECHASSAGNE",
    value: "19021",
    type: "common",
  },
  {
    label: "SAINT-MERD-LES-OUSSINES",
    value: "19226",
    type: "common",
  },
  {
    label: "MILLEVACHES",
    value: "19139",
    type: "common",
  },
  {
    label: "TARNAC",
    value: "19265",
    type: "common",
  },
  {
    label: "TOY-VIAM",
    value: "19268",
    type: "common",
  },
  {
    label: "LACELLE",
    value: "19095",
    type: "common",
  },
  {
    label: "L'EGLISE-AUX-BOIS",
    value: "19074",
    type: "common",
  },
  {
    label: "DOMPS",
    value: "87058",
    type: "common",
  },
  {
    label: "SUSSAC",
    value: "87194",
    type: "common",
  },
  {
    label: "SAINT-GILLES-LES-FORETS",
    value: "87147",
    type: "common",
  },
  {
    label: "LA CROISILLE-SUR-BRIANCE",
    value: "87051",
    type: "common",
  },
  {
    label: "SAINT-VITTE-SUR-BRIANCE",
    value: "87186",
    type: "common",
  },
  {
    label: "SAINT-MEARD",
    value: "87170",
    type: "common",
  },
  {
    label: "GLANGES",
    value: "87072",
    type: "common",
  },
  {
    label: "VICQ-SUR-BREUILH",
    value: "87203",
    type: "common",
  },
  {
    label: "SAINT-PRIEST-LIGOURE",
    value: "87176",
    type: "common",
  },
  {
    label: "NEXON",
    value: "87106",
    type: "common",
  },
  {
    label: "LA MEYZE",
    value: "87096",
    type: "common",
  },
  {
    label: "JANAILHAC",
    value: "87077",
    type: "common",
  },
  {
    label: "SAINT-HILAIRE-LES-PLACES",
    value: "87150",
    type: "common",
  },
  {
    label: "RILHAC-LASTOURS",
    value: "87124",
    type: "common",
  },
  {
    label: "BUSSIERE-GALANT",
    value: "87027",
    type: "common",
  },
  {
    label: "CHALUS",
    value: "87032",
    type: "common",
  },
  {
    label: "DOURNAZAC",
    value: "87060",
    type: "common",
  },
  {
    label: "LA CHAPELLE-MONTBRANDEIX",
    value: "87037",
    type: "common",
  },
  {
    label: "MARVAL",
    value: "87092",
    type: "common",
  },
  {
    label: "SAINT-BARTHELEMY-DE-BUSSIERE",
    value: "24381",
    type: "common",
  },
  {
    label: "PIEGUT-PLUVIERS",
    value: "24328",
    type: "common",
  },
  {
    label: "SAINT-ESTEPHE",
    value: "24398",
    type: "common",
  },
  {
    label: "ETOUARS",
    value: "24163",
    type: "common",
  },
  {
    label: "BUSSIERE-BADIL",
    value: "24071",
    type: "common",
  },
  {
    label: "VARAIGNES",
    value: "24565",
    type: "common",
  },
  {
    label: "SOUDAT",
    value: "24541",
    type: "common",
  },
  {
    label: "EYMOUTHIERS",
    value: "16135",
    type: "common",
  },
  {
    label: "VOUTHON",
    value: "16421",
    type: "common",
  },
  {
    label: "MARTHON",
    value: "16211",
    type: "common",
  },
  {
    label: "SAINT-GERMAIN-DE-MONTBRON",
    value: "16323",
    type: "common",
  },
  {
    label: "CHAZELLES",
    value: "16093",
    type: "common",
  },
  {
    label: "BOUEX",
    value: "16055",
    type: "common",
  },
  {
    label: "SOYAUX",
    value: "16374",
    type: "common",
  },
  {
    label: "PUYMOYEN",
    value: "16271",
    type: "common",
  },
  {
    label: "GARAT",
    value: "16146",
    type: "common",
  },
  {
    label: "DIRAC",
    value: "16120",
    type: "common",
  },
  {
    label: "SAINT-MICHEL",
    value: "16341",
    type: "common",
  },
  {
    label: "LA COURONNE",
    value: "16113",
    type: "common",
  },
  {
    label: "ANGOULEME",
    value: "16015",
    type: "common",
  },
  {
    label: "TROIS-PALIS",
    value: "16388",
    type: "common",
  },
  {
    label: "SIREUIL",
    value: "16370",
    type: "common",
  },
  {
    label: "NERSAC",
    value: "16244",
    type: "common",
  },
  {
    label: "LINARS",
    value: "16187",
    type: "common",
  },
  {
    label: "VIBRAC",
    value: "16402",
    type: "common",
  },
  {
    label: "CHATEAUNEUF-SUR-CHARENTE",
    value: "16090",
    type: "common",
  },
  {
    label: "CHAMPMILLON",
    value: "16077",
    type: "common",
  },
  {
    label: "ANGEAC-CHARENTE",
    value: "16013",
    type: "common",
  },
  {
    label: "GRAVES-SAINT-AMANT",
    value: "16297",
    type: "common",
  },
  {
    label: "SAINT-MEME-LES-CARRIERES",
    value: "16340",
    type: "common",
  },
  {
    label: "BOUTEVILLE",
    value: "16057",
    type: "common",
  },
  {
    label: "SEGONZAC",
    value: "16366",
    type: "common",
  },
  {
    label: "SAINT-PREUIL",
    value: "16343",
    type: "common",
  },
  {
    label: "JUILLAC-LE-COQ",
    value: "16171",
    type: "common",
  },
  {
    label: "GENTE",
    value: "16151",
    type: "common",
  },
  {
    label: "ANGEAC-CHAMPAGNE",
    value: "16012",
    type: "common",
  },
  {
    label: "LONZAC",
    value: "17209",
    type: "common",
  },
  {
    label: "CELLES",
    value: "17076",
    type: "common",
  },
  {
    label: "SALLES-D'ANGLES",
    value: "16359",
    type: "common",
  },
  {
    label: "GIMEUX",
    value: "16152",
    type: "common",
  },
  {
    label: "ARS",
    value: "16018",
    type: "common",
  },
  {
    label: "PERIGNAC",
    value: "17273",
    type: "common",
  },
  {
    label: "COULONGES",
    value: "17122",
    type: "common",
  },
  {
    label: "SAINT-SEURIN-DE-PALENNE",
    value: "17398",
    type: "common",
  },
  {
    label: "SAINT-LEGER",
    value: "17354",
    type: "common",
  },
  {
    label: "COLOMBIERS",
    value: "17115",
    type: "common",
  },
  {
    label: "BOUGNEAU",
    value: "17056",
    type: "common",
  },
  {
    label: "VILLARS-EN-PONS",
    value: "17469",
    type: "common",
  },
  {
    label: "TESSON",
    value: "17441",
    type: "common",
  },
  {
    label: "JAZENNES",
    value: "17196",
    type: "common",
  },
  {
    label: "SAINT-SIMON-DE-PELLOUAILLE",
    value: "17404",
    type: "common",
  },
  {
    label: "CRAVANS",
    value: "17133",
    type: "common",
  },
  {
    label: "THAIMS",
    value: "17442",
    type: "common",
  },
  {
    label: "SAINT-ANDRE-DE-LIDON",
    value: "17310",
    type: "common",
  },
  {
    label: "MONTPELLIER-DE-MEDILLAN",
    value: "17244",
    type: "common",
  },
  {
    label: "COZES",
    value: "17131",
    type: "common",
  },
  {
    label: "GREZAC",
    value: "17183",
    type: "common",
  },
  {
    label: "SEMUSSAC",
    value: "17425",
    type: "common",
  },
  {
    label: "SAINT-GEORGES-DE-DIDONNE",
    value: "17333",
    type: "common",
  },
  {
    label: "MESCHERS-SUR-GIRONDE",
    value: "17230",
    type: "common",
  },
  {
    label: "TIGNES",
    value: "73296",
    type: "common",
  },
  {
    label: "PEISEY-NANCROIX",
    value: "73197",
    type: "common",
  },
  {
    label: "LA PLAGNE TARENTAISE",
    value: "73150",
    type: "common",
  },
  {
    label: "SAINT-MARCEL",
    value: "73253",
    type: "common",
  },
  {
    label: "NOTRE-DAME-DU-PRE",
    value: "73190",
    type: "common",
  },
  {
    label: "MOUTIERS",
    value: "73181",
    type: "common",
  },
  {
    label: "HAUTECOUR",
    value: "73131",
    type: "common",
  },
  {
    label: "MONTSAPEY",
    value: "73175",
    type: "common",
  },
  {
    label: "SAINT-GEORGES-D'HURTIERES",
    value: "73237",
    type: "common",
  },
  {
    label: "LE PONTET",
    value: "73205",
    type: "common",
  },
  {
    label: "MONTGILBERT",
    value: "73168",
    type: "common",
  },
  {
    label: "MONTENDRY",
    value: "73166",
    type: "common",
  },
  {
    label: "VILLARD-LEGER",
    value: "73315",
    type: "common",
  },
  {
    label: "VILLARD-D'HERY",
    value: "73314",
    type: "common",
  },
  {
    label: "LA TRINITE",
    value: "73302",
    type: "common",
  },
  {
    label: "HAUTEVILLE",
    value: "73133",
    type: "common",
  },
  {
    label: "CHATEAUNEUF",
    value: "73079",
    type: "common",
  },
  {
    label: "CHAMP-LAURENT",
    value: "73072",
    type: "common",
  },
  {
    label: "CHAMOUX-SUR-GELON",
    value: "73069",
    type: "common",
  },
  {
    label: "BETTON-BETTONET",
    value: "73041",
    type: "common",
  },
  {
    label: "LA THUILE",
    value: "73294",
    type: "common",
  },
  {
    label: "SAINT-JEAN-DE-LA-PORTE",
    value: "73247",
    type: "common",
  },
  {
    label: "PLANAISE",
    value: "73200",
    type: "common",
  },
  {
    label: "MONTMELIAN",
    value: "73171",
    type: "common",
  },
  {
    label: "CRUET",
    value: "73096",
    type: "common",
  },
  {
    label: "COISE-SAINT-JEAN-PIED-GAUTHIER",
    value: "73089",
    type: "common",
  },
  {
    label: "ARBIN",
    value: "73018",
    type: "common",
  },
  {
    label: "SAINT-JEOIRE-PRIEURE",
    value: "73249",
    type: "common",
  },
  {
    label: "MYANS",
    value: "73183",
    type: "common",
  },
  {
    label: "CHIGNIN",
    value: "73084",
    type: "common",
  },
  {
    label: "CHALLES-LES-EAUX",
    value: "73064",
    type: "common",
  },
  {
    label: "SAINT-BALDOPH",
    value: "73225",
    type: "common",
  },
  {
    label: "LA RAVOIRE",
    value: "73213",
    type: "common",
  },
  {
    label: "MONTAGNOLE",
    value: "73160",
    type: "common",
  },
  {
    label: "JACOB-BELLECOMBETTE",
    value: "73137",
    type: "common",
  },
  {
    label: "COGNIN",
    value: "73087",
    type: "common",
  },
  {
    label: "BARBERAZ",
    value: "73029",
    type: "common",
  },
  {
    label: "APREMONT",
    value: "73017",
    type: "common",
  },
  {
    label: "VIMINES",
    value: "73326",
    type: "common",
  },
  {
    label: "SAINT-SULPICE",
    value: "73281",
    type: "common",
  },
  {
    label: "SAINT-CASSIN",
    value: "73228",
    type: "common",
  },
  {
    label: "AIGUEBELETTE-LE-LAC",
    value: "73001",
    type: "common",
  },
  {
    label: "VEREL-DE-MONTBEL",
    value: "73309",
    type: "common",
  },
  {
    label: "SAINT-ALBAN-DE-MONTBEL",
    value: "73219",
    type: "common",
  },
  {
    label: "LEPIN-LE-LAC",
    value: "73145",
    type: "common",
  },
  {
    label: "DULLIN",
    value: "73104",
    type: "common",
  },
  {
    label: "LA BRIDOIRE",
    value: "73058",
    type: "common",
  },
  {
    label: "ATTIGNAT-ONCIN",
    value: "73022",
    type: "common",
  },
  {
    label: "LE PONT-DE-BEAUVOISIN",
    value: "73204",
    type: "common",
  },
  {
    label: "DOMESSIN",
    value: "73100",
    type: "common",
  },
  {
    label: "BELMONT-TRAMONET",
    value: "73039",
    type: "common",
  },
  {
    label: "AVRESSIEUX",
    value: "73025",
    type: "common",
  },
  {
    label: "ROMAGNIEU",
    value: "38343",
    type: "common",
  },
  {
    label: "LE PONT-DE-BEAUVOISIN",
    value: "38315",
    type: "common",
  },
  {
    label: "PRESSINS",
    value: "38323",
    type: "common",
  },
  {
    label: "CHARANCIEU",
    value: "38080",
    type: "common",
  },
  {
    label: "LES ABRETS EN DAUPHINE",
    value: "38001",
    type: "common",
  },
  {
    label: "SAINT-DIDIER-DE-LA-TOUR",
    value: "38381",
    type: "common",
  },
  {
    label: "SAINT-CLAIR-DE-LA-TOUR",
    value: "38377",
    type: "common",
  },
  {
    label: "SAINT-ANDRE-LE-GAZ",
    value: "38357",
    type: "common",
  },
  {
    label: "LE PASSAGE",
    value: "38296",
    type: "common",
  },
  {
    label: "TORCHEFELON",
    value: "38508",
    type: "common",
  },
  {
    label: "SAINT-VICTOR-DE-CESSIEU",
    value: "38464",
    type: "common",
  },
  {
    label: "SAINTE-BLANDINE",
    value: "38369",
    type: "common",
  },
  {
    label: "MONTAGNIEU",
    value: "38246",
    type: "common",
  },
  {
    label: "SUCCIEU",
    value: "38498",
    type: "common",
  },
  {
    label: "SEREZIN-DE-LA-TOUR",
    value: "38481",
    type: "common",
  },
  {
    label: "NIVOLAS-VERMELLE",
    value: "38276",
    type: "common",
  },
  {
    label: "CESSIEU",
    value: "38064",
    type: "common",
  },
  {
    label: "SAINT-AGNIN-SUR-BION",
    value: "38351",
    type: "common",
  },
  {
    label: "MEYRIE",
    value: "38230",
    type: "common",
  },
  {
    label: "MAUBEC",
    value: "38223",
    type: "common",
  },
  {
    label: "LES EPARRES",
    value: "38156",
    type: "common",
  },
  {
    label: "DOMARIN",
    value: "38149",
    type: "common",
  },
  {
    label: "CULIN",
    value: "38141",
    type: "common",
  },
  {
    label: "CRACHIER",
    value: "38136",
    type: "common",
  },
  {
    label: "CHEZENEUVE",
    value: "38102",
    type: "common",
  },
  {
    label: "ROCHE",
    value: "38339",
    type: "common",
  },
  {
    label: "FOUR",
    value: "38172",
    type: "common",
  },
  {
    label: "ARTAS",
    value: "38015",
    type: "common",
  },
  {
    label: "SAINT-GEORGES-D'ESPERANCHE",
    value: "38389",
    type: "common",
  },
  {
    label: "DIEMOZ",
    value: "38144",
    type: "common",
  },
  {
    label: "CHARANTONNAY",
    value: "38081",
    type: "common",
  },
  {
    label: "SEPTEME",
    value: "38480",
    type: "common",
  },
  {
    label: "OYTIER-SAINT-OBLAS",
    value: "38288",
    type: "common",
  },
  {
    label: "SERPAIZE",
    value: "38484",
    type: "common",
  },
  {
    label: "PONT-EVEQUE",
    value: "38318",
    type: "common",
  },
  {
    label: "SAINT-ROMAIN-EN-GAL",
    value: "69235",
    type: "common",
  },
  {
    label: "SEYSSUEL",
    value: "38487",
    type: "common",
  },
  {
    label: "CHUZELLES",
    value: "38110",
    type: "common",
  },
  {
    label: "CHASSE-SUR-RHONE",
    value: "38087",
    type: "common",
  },
  {
    label: "LOIRE-SUR-RHONE",
    value: "69118",
    type: "common",
  },
  {
    label: "GIVORS",
    value: "69091",
    type: "common",
  },
  {
    label: "ECHALAS",
    value: "69080",
    type: "common",
  },
  {
    label: "SAINT-ROMAIN-EN-GIER",
    value: "69236",
    type: "common",
  },
  {
    label: "CHABANIERE",
    value: "69228",
    type: "common",
  },
  {
    label: "BEAUVALLON",
    value: "69179",
    type: "common",
  },
  {
    label: "TARTARAS",
    value: "42307",
    type: "common",
  },
  {
    label: "DARGOIRE",
    value: "42083",
    type: "common",
  },
  {
    label: "SAINT-MARTIN-LA-PLAINE",
    value: "42259",
    type: "common",
  },
  {
    label: "SAINT-JOSEPH",
    value: "42242",
    type: "common",
  },
  {
    label: "VALFLEURY",
    value: "42320",
    type: "common",
  },
  {
    label: "SAINT-ROMAIN-EN-JAREZ",
    value: "42283",
    type: "common",
  },
  {
    label: "MARCENOD",
    value: "42133",
    type: "common",
  },
  {
    label: "CHATELUS",
    value: "42055",
    type: "common",
  },
  {
    label: "GRAMMOND",
    value: "42102",
    type: "common",
  },
  {
    label: "LA GIMOND",
    value: "42100",
    type: "common",
  },
  {
    label: "FONTANES",
    value: "42096",
    type: "common",
  },
  {
    label: "CHEVRIERES",
    value: "42062",
    type: "common",
  },
  {
    label: "AVEIZIEUX",
    value: "42010",
    type: "common",
  },
  {
    label: "VEAUCHE",
    value: "42323",
    type: "common",
  },
  {
    label: "SAINT-MEDARD-EN-FOREZ",
    value: "42264",
    type: "common",
  },
  {
    label: "SAINT-BONNET-LES-OULES",
    value: "42206",
    type: "common",
  },
  {
    label: "CHAMBŒUF",
    value: "42043",
    type: "common",
  },
  {
    label: "VEAUCHETTE",
    value: "42324",
    type: "common",
  },
  {
    label: "RIVAS",
    value: "42185",
    type: "common",
  },
  {
    label: "CRAINTILLEUX",
    value: "42075",
    type: "common",
  },
  {
    label: "SURY-LE-COMTAL",
    value: "42304",
    type: "common",
  },
  {
    label: "SAINT-ROMAIN-LE-PUY",
    value: "42285",
    type: "common",
  },
  {
    label: "PRECIEUX",
    value: "42180",
    type: "common",
  },
  {
    label: "L'HOPITAL-LE-GRAND",
    value: "42108",
    type: "common",
  },
  {
    label: "SAINT-THOMAS-LA-GARDE",
    value: "42290",
    type: "common",
  },
  {
    label: "MONTBRISON",
    value: "42147",
    type: "common",
  },
  {
    label: "LEZIGNEUX",
    value: "42122",
    type: "common",
  },
  {
    label: "ECOTAY-L'OLME",
    value: "42087",
    type: "common",
  },
  {
    label: "VERRIERES-EN-FOREZ",
    value: "42328",
    type: "common",
  },
  {
    label: "BARD",
    value: "42012",
    type: "common",
  },
  {
    label: "LERIGNEUX",
    value: "42121",
    type: "common",
  },
  {
    label: "VALCIVIERES",
    value: "63441",
    type: "common",
  },
  {
    label: "LA FORIE",
    value: "63161",
    type: "common",
  },
  {
    label: "AMBERT",
    value: "63003",
    type: "common",
  },
  {
    label: "THIOLIERES",
    value: "63431",
    type: "common",
  },
  {
    label: "SAINT-AMANT-ROCHE-SAVINE",
    value: "63314",
    type: "common",
  },
  {
    label: "LE MONESTIER",
    value: "63230",
    type: "common",
  },
  {
    label: "GRANDVAL",
    value: "63174",
    type: "common",
  },
  {
    label: "SAINT-ELOY-LA-GLACIERE",
    value: "63337",
    type: "common",
  },
  {
    label: "CONDAT-LES-MONTBOISSIER",
    value: "63119",
    type: "common",
  },
  {
    label: "BROUSSE",
    value: "63056",
    type: "common",
  },
  {
    label: "AUZELLES",
    value: "63023",
    type: "common",
  },
  {
    label: "SUGERES",
    value: "63423",
    type: "common",
  },
  {
    label: "SAUXILLANGES",
    value: "63415",
    type: "common",
  },
  {
    label: "EGLISENEUVE-DES-LIARDS",
    value: "63145",
    type: "common",
  },
  {
    label: "SAINT-BABEL",
    value: "63321",
    type: "common",
  },
  {
    label: "MANGLIEU",
    value: "63205",
    type: "common",
  },
  {
    label: "AULHAT-FLAT",
    value: "63160",
    type: "common",
  },
  {
    label: "YRONDE-ET-BURON",
    value: "63472",
    type: "common",
  },
  {
    label: "SAUVAGNAT-SAINTE-MARTHE",
    value: "63411",
    type: "common",
  },
  {
    label: "SAINT-YVOINE",
    value: "63404",
    type: "common",
  },
  {
    label: "ORBEIL",
    value: "63261",
    type: "common",
  },
  {
    label: "COUDES",
    value: "63121",
    type: "common",
  },
  {
    label: "PARDINES",
    value: "63268",
    type: "common",
  },
  {
    label: "NESCHERS",
    value: "63250",
    type: "common",
  },
  {
    label: "CHIDRAC",
    value: "63109",
    type: "common",
  },
  {
    label: "CHAMPEIX",
    value: "63080",
    type: "common",
  },
  {
    label: "CHADELEUF",
    value: "63073",
    type: "common",
  },
  {
    label: "VERRIERES",
    value: "63452",
    type: "common",
  },
  {
    label: "MONTAIGUT-LE-BLANC",
    value: "63234",
    type: "common",
  },
  {
    label: "LUDESSE",
    value: "63199",
    type: "common",
  },
  {
    label: "GRANDEYROLLES",
    value: "63172",
    type: "common",
  },
  {
    label: "CLEMENSAT",
    value: "63111",
    type: "common",
  },
  {
    label: "SAINT-NECTAIRE",
    value: "63380",
    type: "common",
  },
  {
    label: "MUROL",
    value: "63247",
    type: "common",
  },
  {
    label: "CHAMBON-SUR-LAC",
    value: "63077",
    type: "common",
  },
  {
    label: "MONT-DORE",
    value: "63236",
    type: "common",
  },
  {
    label: "SAINT-SAUVES-D'AUVERGNE",
    value: "63397",
    type: "common",
  },
  {
    label: "MURAT-LE-QUAIRE",
    value: "63246",
    type: "common",
  },
  {
    label: "LA BOURBOULE",
    value: "63047",
    type: "common",
  },
  {
    label: "TAUVES",
    value: "63426",
    type: "common",
  },
  {
    label: "MESSEIX",
    value: "63225",
    type: "common",
  },
  {
    label: "AVEZE",
    value: "63024",
    type: "common",
  },
  {
    label: "SAVENNES",
    value: "63416",
    type: "common",
  },
  {
    label: "SAINT-ETIENNE-AUX-CLOS",
    value: "19199",
    type: "common",
  },
  {
    label: "SAINT-PARDOUX-LE-NEUF",
    value: "19232",
    type: "common",
  },
  {
    label: "LIGNAREIX",
    value: "19114",
    type: "common",
  },
  {
    label: "SAINT-PARDOUX-LE-VIEUX",
    value: "19233",
    type: "common",
  },
  {
    label: "SAINT-GERMAIN-LAVOLPS",
    value: "19206",
    type: "common",
  },
  {
    label: "CHAVEROCHE",
    value: "19053",
    type: "common",
  },
  {
    label: "ALLEYRAT",
    value: "19006",
    type: "common",
  },
  {
    label: "SAINT-SULPICE-LES-BOIS",
    value: "19244",
    type: "common",
  },
  {
    label: "MEYMAC",
    value: "19136",
    type: "common",
  },
  {
    label: "CHAVANAC",
    value: "19052",
    type: "common",
  },
  {
    label: "PEROLS-SUR-VEZERE",
    value: "19160",
    type: "common",
  },
  {
    label: "VIAM",
    value: "19284",
    type: "common",
  },
  {
    label: "BUGEAT",
    value: "19033",
    type: "common",
  },
  {
    label: "SAINT-HILAIRE-LES-COURBES",
    value: "19209",
    type: "common",
  },
  {
    label: "CHAMBERET",
    value: "19036",
    type: "common",
  },
  {
    label: "SURDOUX",
    value: "87193",
    type: "common",
  },
  {
    label: "LA PORCHERIE",
    value: "87120",
    type: "common",
  },
  {
    label: "SAINT-GERMAIN-LES-BELLES",
    value: "87146",
    type: "common",
  },
  {
    label: "MAGNAC-BOURG",
    value: "87088",
    type: "common",
  },
  {
    label: "CHATEAU-CHERVIX",
    value: "87039",
    type: "common",
  },
  {
    label: "LA ROCHE-L'ABEILLE",
    value: "87127",
    type: "common",
  },
  {
    label: "LADIGNAC-LE-LONG",
    value: "87082",
    type: "common",
  },
  {
    label: "LE CHALARD",
    value: "87031",
    type: "common",
  },
  {
    label: "SAINT-PIERRE-DE-FRUGIE",
    value: "24486",
    type: "common",
  },
  {
    label: "FIRBEIX",
    value: "24180",
    type: "common",
  },
  {
    label: "MIALET",
    value: "24269",
    type: "common",
  },
  {
    label: "PENSOL",
    value: "87115",
    type: "common",
  },
  {
    label: "ABJAT-SUR-BANDIAT",
    value: "24001",
    type: "common",
  },
  {
    label: "SAVIGNAC-DE-NONTRON",
    value: "24525",
    type: "common",
  },
  {
    label: "AUGIGNAC",
    value: "24016",
    type: "common",
  },
  {
    label: "SAINT-MARTIN-LE-PIN",
    value: "24458",
    type: "common",
  },
  {
    label: "LE BOURDEIX",
    value: "24056",
    type: "common",
  },
  {
    label: "TEYJAT",
    value: "24548",
    type: "common",
  },
  {
    label: "JAVERLHAC-ET-LA-CHAPELLE-SAINT-ROBERT",
    value: "24214",
    type: "common",
  },
  {
    label: "SOUFFRIGNAC",
    value: "16372",
    type: "common",
  },
  {
    label: "MAINZAC",
    value: "16203",
    type: "common",
  },
  {
    label: "FEUILLADE",
    value: "16137",
    type: "common",
  },
  {
    label: "VOUZAN",
    value: "16422",
    type: "common",
  },
  {
    label: "GRASSAC",
    value: "16158",
    type: "common",
  },
  {
    label: "CHARRAS",
    value: "16084",
    type: "common",
  },
  {
    label: "SERS",
    value: "16368",
    type: "common",
  },
  {
    label: "DIGNAC",
    value: "16119",
    type: "common",
  },
  {
    label: "TORSAC",
    value: "16382",
    type: "common",
  },
  {
    label: "VŒUIL-ET-GIGET",
    value: "16418",
    type: "common",
  },
  {
    label: "MOUTHIERS-SUR-BOEME",
    value: "16236",
    type: "common",
  },
  {
    label: "ROULLET-SAINT-ESTEPHE",
    value: "16287",
    type: "common",
  },
  {
    label: "CLAIX",
    value: "16101",
    type: "common",
  },
  {
    label: "BIRAC",
    value: "16045",
    type: "common",
  },
  {
    label: "BELLEVIGNE",
    value: "16204",
    type: "common",
  },
  {
    label: "BONNEUIL",
    value: "16050",
    type: "common",
  },
  {
    label: "LIGNIERES-SONNEVILLE",
    value: "16186",
    type: "common",
  },
  {
    label: "CRITEUIL-LA-MAGDELEINE",
    value: "16116",
    type: "common",
  },
  {
    label: "AMBLEVILLE",
    value: "16010",
    type: "common",
  },
  {
    label: "CIERZAC",
    value: "17106",
    type: "common",
  },
  {
    label: "VERRIERES",
    value: "16399",
    type: "common",
  },
  {
    label: "SAINT-PALAIS-DU-NE",
    value: "16342",
    type: "common",
  },
  {
    label: "SAINT-FORT-SUR-LE-NE",
    value: "16316",
    type: "common",
  },
  {
    label: "SAINT-MARTIAL-SUR-NE",
    value: "17364",
    type: "common",
  },
  {
    label: "GERMIGNAC",
    value: "17175",
    type: "common",
  },
  {
    label: "JARNAC-CHAMPAGNE",
    value: "17192",
    type: "common",
  },
  {
    label: "ECHEBRUNE",
    value: "17145",
    type: "common",
  },
  {
    label: "CHADENAC",
    value: "17078",
    type: "common",
  },
  {
    label: "BIRON",
    value: "17047",
    type: "common",
  },
  {
    label: "PONS",
    value: "17283",
    type: "common",
  },
  {
    label: "FLEAC-SUR-SEUGNE",
    value: "17159",
    type: "common",
  },
  {
    label: "BELLUIRE",
    value: "17039",
    type: "common",
  },
  {
    label: "AVY",
    value: "17027",
    type: "common",
  },
  {
    label: "TANZAC",
    value: "17438",
    type: "common",
  },
  {
    label: "SAINT-QUANTIN-DE-RANCANNE",
    value: "17388",
    type: "common",
  },
  {
    label: "MAZEROLLES",
    value: "17227",
    type: "common",
  },
  {
    label: "GIVREZAC",
    value: "17178",
    type: "common",
  },
  {
    label: "VIROLLET",
    value: "17479",
    type: "common",
  },
  {
    label: "GEMOZAC",
    value: "17172",
    type: "common",
  },
  {
    label: "EPARGNES",
    value: "17152",
    type: "common",
  },
  {
    label: "TALMONT-SUR-GIRONDE",
    value: "17437",
    type: "common",
  },
  {
    label: "BARZAN",
    value: "17034",
    type: "common",
  },
  {
    label: "ARCES",
    value: "17015",
    type: "common",
  },
  {
    label: "LE VERDON-SUR-MER",
    value: "33544",
    type: "common",
  },
  {
    label: "VAL-D'ISERE",
    value: "73304",
    type: "common",
  },
  {
    label: "CHAMPAGNY-EN-VANOISE",
    value: "73071",
    type: "common",
  },
  {
    label: "PLANAY",
    value: "73201",
    type: "common",
  },
  {
    label: "BOZEL",
    value: "73055",
    type: "common",
  },
  {
    label: "MONTAGNY",
    value: "73161",
    type: "common",
  },
  {
    label: "FEISSONS-SUR-SALINS",
    value: "73113",
    type: "common",
  },
  {
    label: "BRIDES-LES-BAINS",
    value: "73057",
    type: "common",
  },
  {
    label: "SALINS-FONTAINE",
    value: "73284",
    type: "common",
  },
  {
    label: "LES AVANCHERS-VALMOREL",
    value: "73024",
    type: "common",
  },
  {
    label: "EPIERRE",
    value: "73109",
    type: "common",
  },
  {
    label: "ARGENTINE",
    value: "73019",
    type: "common",
  },
  {
    label: "SAINT-PIERRE-DE-BELLEVILLE",
    value: "73272",
    type: "common",
  },
  {
    label: "SAINT-LEGER",
    value: "73252",
    type: "common",
  },
  {
    label: "SAINT-ALBAN-D'HURTIERES",
    value: "73220",
    type: "common",
  },
  {
    label: "VILLARD-SALLET",
    value: "73316",
    type: "common",
  },
  {
    label: "LE VERNEIL",
    value: "73311",
    type: "common",
  },
  {
    label: "LA TABLE",
    value: "73289",
    type: "common",
  },
  {
    label: "ROTHERENS",
    value: "73217",
    type: "common",
  },
  {
    label: "PRESLE",
    value: "73207",
    type: "common",
  },
  {
    label: "BOURGET-EN-HUILE",
    value: "73052",
    type: "common",
  },
  {
    label: "VILLAROUX",
    value: "73324",
    type: "common",
  },
  {
    label: "SAINT-PIERRE-DE-SOUCY",
    value: "73276",
    type: "common",
  },
  {
    label: "SAINTE-HELENE-DU-LAC",
    value: "73240",
    type: "common",
  },
  {
    label: "LES MOLLETTES",
    value: "73159",
    type: "common",
  },
  {
    label: "DETRIER",
    value: "73099",
    type: "common",
  },
  {
    label: "LA CROIX-DE-LA-ROCHETTE",
    value: "73095",
    type: "common",
  },
  {
    label: "LA CHAVANNE",
    value: "73082",
    type: "common",
  },
  {
    label: "LA CHAPELLE-BLANCHE",
    value: "73075",
    type: "common",
  },
  {
    label: "LAISSAUD",
    value: "73141",
    type: "common",
  },
  {
    label: "CHAPAREILLAN",
    value: "38075",
    type: "common",
  },
  {
    label: "ENTREMONT-LE-VIEUX",
    value: "73107",
    type: "common",
  },
  {
    label: "SAINT-THIBAUD-DE-COUZ",
    value: "73282",
    type: "common",
  },
  {
    label: "SAINT-JEAN-DE-COUZ",
    value: "73246",
    type: "common",
  },
  {
    label: "SAINT-PIERRE-DE-GENEBROZ",
    value: "73275",
    type: "common",
  },
  {
    label: "SAINT-FRANC",
    value: "73233",
    type: "common",
  },
  {
    label: "SAINT-CHRISTOPHE",
    value: "73229",
    type: "common",
  },
  {
    label: "SAINT-BERON",
    value: "73226",
    type: "common",
  },
  {
    label: "LA BAUCHE",
    value: "73033",
    type: "common",
  },
  {
    label: "VOISSANT",
    value: "38564",
    type: "common",
  },
  {
    label: "VELANNE",
    value: "38531",
    type: "common",
  },
  {
    label: "SAINT-MARTIN-DE-VAULSERRE",
    value: "38420",
    type: "common",
  },
  {
    label: "SAINT-JEAN-D'AVELANNE",
    value: "38398",
    type: "common",
  },
  {
    label: "SAINT-BUEIL",
    value: "38372",
    type: "common",
  },
  {
    label: "SAINT-ALBIN-DE-VAULSERRE",
    value: "38354",
    type: "common",
  },
  {
    label: "SAINT-SULPICE-DES-RIVOIRES",
    value: "38460",
    type: "common",
  },
  {
    label: "VILLAGES DU LAC DE PALADRU",
    value: "38292",
    type: "common",
  },
  {
    label: "MONTFERRAT",
    value: "38256",
    type: "common",
  },
  {
    label: "VALENCOGNE",
    value: "38520",
    type: "common",
  },
  {
    label: "SAINT-ONDRAS",
    value: "38434",
    type: "common",
  },
  {
    label: "CHELIEU",
    value: "38098",
    type: "common",
  },
  {
    label: "CHASSIGNIEU",
    value: "38089",
    type: "common",
  },
  {
    label: "MONTREVEL",
    value: "38257",
    type: "common",
  },
  {
    label: "DOISSIN",
    value: "38147",
    type: "common",
  },
  {
    label: "BLANDIN",
    value: "38047",
    type: "common",
  },
  {
    label: "SAINT-DIDIER-DE-BIZONNES",
    value: "38380",
    type: "common",
  },
  {
    label: "FLACHERES",
    value: "38167",
    type: "common",
  },
  {
    label: "ECLOSE-BADINIERES",
    value: "38152",
    type: "common",
  },
  {
    label: "CHATEAUVILAIN",
    value: "38091",
    type: "common",
  },
  {
    label: "BIOL",
    value: "38044",
    type: "common",
  },
  {
    label: "BELMONT",
    value: "38038",
    type: "common",
  },
  {
    label: "TRAMOLE",
    value: "38512",
    type: "common",
  },
  {
    label: "SAINTE-ANNE-SUR-GERVONDE",
    value: "38358",
    type: "common",
  },
  {
    label: "CHATONNAY",
    value: "38094",
    type: "common",
  },
  {
    label: "SAINT-JEAN-DE-BOURNAY",
    value: "38399",
    type: "common",
  },
  {
    label: "MEYRIEU-LES-ETANGS",
    value: "38231",
    type: "common",
  },
  {
    label: "SAVAS-MEPIN",
    value: "38476",
    type: "common",
  },
  {
    label: "ROYAS",
    value: "38346",
    type: "common",
  },
  {
    label: "BEAUVOIR-DE-MARC",
    value: "38035",
    type: "common",
  },
  {
    label: "MOIDIEU-DETOURBE",
    value: "38238",
    type: "common",
  },
  {
    label: "EYZIN-PINET",
    value: "38160",
    type: "common",
  },
  {
    label: "VIENNE",
    value: "38544",
    type: "common",
  },
  {
    label: "SAINT-SORLIN-DE-VIENNE",
    value: "38459",
    type: "common",
  },
  {
    label: "JARDIN",
    value: "38199",
    type: "common",
  },
  {
    label: "ESTRABLIN",
    value: "38157",
    type: "common",
  },
  {
    label: "SAINT-CYR-SUR-LE-RHONE",
    value: "69193",
    type: "common",
  },
  {
    label: "SAINTE-COLOMBE",
    value: "69189",
    type: "common",
  },
  {
    label: "AMPUIS",
    value: "69007",
    type: "common",
  },
  {
    label: "REVENTIN-VAUGRIS",
    value: "38336",
    type: "common",
  },
  {
    label: "TUPIN-ET-SEMONS",
    value: "69253",
    type: "common",
  },
  {
    label: "LES HAIES",
    value: "69097",
    type: "common",
  },
  {
    label: "CONDRIEU",
    value: "69064",
    type: "common",
  },
  {
    label: "TREVES",
    value: "69252",
    type: "common",
  },
  {
    label: "LONGES",
    value: "69119",
    type: "common",
  },
  {
    label: "CHATEAUNEUF",
    value: "42053",
    type: "common",
  },
  {
    label: "SAINT-PAUL-EN-JAREZ",
    value: "42271",
    type: "common",
  },
  {
    label: "GENILAC",
    value: "42225",
    type: "common",
  },
  {
    label: "RIVE-DE-GIER",
    value: "42186",
    type: "common",
  },
  {
    label: "LORETTE",
    value: "42123",
    type: "common",
  },
  {
    label: "L'HORME",
    value: "42110",
    type: "common",
  },
  {
    label: "LA GRAND-CROIX",
    value: "42103",
    type: "common",
  },
  {
    label: "FARNAY",
    value: "42093",
    type: "common",
  },
  {
    label: "CHAGNON",
    value: "42036",
    type: "common",
  },
  {
    label: "SAINT-CHRISTO-EN-JAREZ",
    value: "42208",
    type: "common",
  },
  {
    label: "CELLIEU",
    value: "42032",
    type: "common",
  },
  {
    label: "LA TOUR-EN-JAREZ",
    value: "42311",
    type: "common",
  },
  {
    label: "SORBIERS",
    value: "42302",
    type: "common",
  },
  {
    label: "SAINT-HEAND",
    value: "42234",
    type: "common",
  },
  {
    label: "LA FOUILLOUSE",
    value: "42097",
    type: "common",
  },
  {
    label: "L'ETRAT",
    value: "42092",
    type: "common",
  },
  {
    label: "SAINT-JUST-SAINT-RAMBERT",
    value: "42279",
    type: "common",
  },
  {
    label: "SAINT-CYPRIEN",
    value: "42211",
    type: "common",
  },
  {
    label: "BONSON",
    value: "42022",
    type: "common",
  },
  {
    label: "ANDREZIEUX-BOUTHEON",
    value: "42005",
    type: "common",
  },
  {
    label: "SAINT-MARCELLIN-EN-FOREZ",
    value: "42256",
    type: "common",
  },
  {
    label: "SOLEYMIEUX",
    value: "42301",
    type: "common",
  },
  {
    label: "SAINT-GEORGES-HAUTE-VILLE",
    value: "42228",
    type: "common",
  },
  {
    label: "MARGERIE-CHANTAGRET",
    value: "42137",
    type: "common",
  },
  {
    label: "LAVIEU",
    value: "42117",
    type: "common",
  },
  {
    label: "CHENEREILLES",
    value: "42060",
    type: "common",
  },
  {
    label: "BOISSET-SAINT-PRIEST",
    value: "42021",
    type: "common",
  },
  {
    label: "SAINT-JEAN-SOLEYMIEUX",
    value: "42240",
    type: "common",
  },
  {
    label: "GUMIERES",
    value: "42107",
    type: "common",
  },
  {
    label: "CHAZELLES-SUR-LAVIEU",
    value: "42058",
    type: "common",
  },
  {
    label: "SAINT-ROMAIN",
    value: "63394",
    type: "common",
  },
  {
    label: "SAINT-CLEMENT-DE-VALORGUE",
    value: "63331",
    type: "common",
  },
  {
    label: "SAINT-ANTHEME",
    value: "63319",
    type: "common",
  },
  {
    label: "SAINT-MARTIN-DES-OLMES",
    value: "63374",
    type: "common",
  },
  {
    label: "GRANDRIF",
    value: "63173",
    type: "common",
  },
  {
    label: "SAINT-FERREOL-DES-COTES",
    value: "63341",
    type: "common",
  },
  {
    label: "CHAMPETIERES",
    value: "63081",
    type: "common",
  },
  {
    label: "FOURNOLS",
    value: "63162",
    type: "common",
  },
  {
    label: "ECHANDELYS",
    value: "63142",
    type: "common",
  },
  {
    label: "SAINT-GENES-LA-TOURETTE",
    value: "63348",
    type: "common",
  },
  {
    label: "AIX-LA-FAYETTE",
    value: "63002",
    type: "common",
  },
  {
    label: "SAINT-QUENTIN-SUR-SAUXILLANGES",
    value: "63389",
    type: "common",
  },
  {
    label: "SAINT-JEAN-EN-VAL",
    value: "63366",
    type: "common",
  },
  {
    label: "SAINT-ETIENNE-SUR-USSON",
    value: "63340",
    type: "common",
  },
  {
    label: "VARENNES-SUR-USSON",
    value: "63444",
    type: "common",
  },
  {
    label: "USSON",
    value: "63439",
    type: "common",
  },
  {
    label: "SAINT-REMY-DE-CHARGNAT",
    value: "63392",
    type: "common",
  },
  {
    label: "SAINT-MARTIN-DES-PLAINS",
    value: "63375",
    type: "common",
  },
  {
    label: "LES PRADEAUX",
    value: "63287",
    type: "common",
  },
  {
    label: "PARENTIGNAT",
    value: "63270",
    type: "common",
  },
  {
    label: "BRENAT",
    value: "63051",
    type: "common",
  },
  {
    label: "ISSOIRE",
    value: "63178",
    type: "common",
  },
  {
    label: "LE BROC",
    value: "63054",
    type: "common",
  },
  {
    label: "BERGONNE",
    value: "63036",
    type: "common",
  },
  {
    label: "VODABLE",
    value: "63466",
    type: "common",
  },
  {
    label: "SOLIGNAT",
    value: "63422",
    type: "common",
  },
  {
    label: "SAINT-VINCENT",
    value: "63403",
    type: "common",
  },
  {
    label: "SAINT-CIRGUES-SUR-COUZE",
    value: "63330",
    type: "common",
  },
  {
    label: "PERRIER",
    value: "63275",
    type: "common",
  },
  {
    label: "MEILHAUD",
    value: "63222",
    type: "common",
  },
  {
    label: "ANTOINGT",
    value: "63005",
    type: "common",
  },
  {
    label: "TOURZEL-RONZIERES",
    value: "63435",
    type: "common",
  },
  {
    label: "SAURIER",
    value: "63409",
    type: "common",
  },
  {
    label: "SAINT-FLORET",
    value: "63342",
    type: "common",
  },
  {
    label: "COURGOUL",
    value: "63122",
    type: "common",
  },
  {
    label: "SAINT-PIERRE-COLAMINE",
    value: "63383",
    type: "common",
  },
  {
    label: "SAINT-VICTOR-LA-RIVIERE",
    value: "63401",
    type: "common",
  },
  {
    label: "LA TOUR-D'AUVERGNE",
    value: "63192",
    type: "common",
  },
  {
    label: "CHASTREIX",
    value: "63098",
    type: "common",
  },
  {
    label: "SINGLES",
    value: "63421",
    type: "common",
  },
  {
    label: "LARODDE",
    value: "63190",
    type: "common",
  },
  {
    label: "THALAMY",
    value: "19266",
    type: "common",
  },
  {
    label: "CONFOLENT-PORT-DIEU",
    value: "19167",
    type: "common",
  },
  {
    label: "SAINT-FREJOUX",
    value: "19204",
    type: "common",
  },
  {
    label: "SAINT-EXUPERY-LES-ROCHES",
    value: "19201",
    type: "common",
  },
  {
    label: "SAINT-BONNET-PRES-BORT",
    value: "19190",
    type: "common",
  },
  {
    label: "USSEL",
    value: "19275",
    type: "common",
  },
  {
    label: "SAINT-ANGEL",
    value: "19180",
    type: "common",
  },
  {
    label: "AMBRUGEAT",
    value: "19008",
    type: "common",
  },
  {
    label: "BONNEFOND",
    value: "19027",
    type: "common",
  },
  {
    label: "PRADINES",
    value: "19168",
    type: "common",
  },
  {
    label: "GOURDON-MURAT",
    value: "19087",
    type: "common",
  },
  {
    label: "VEIX",
    value: "19281",
    type: "common",
  },
  {
    label: "TREIGNAC",
    value: "19269",
    type: "common",
  },
  {
    label: "LESTARDS",
    value: "19112",
    type: "common",
  },
  {
    label: "SOUDAINE-LAVINADIERE",
    value: "19262",
    type: "common",
  },
  {
    label: "RILHAC-TREIGNAC",
    value: "19172",
    type: "common",
  },
  {
    label: "PEYRISSAC",
    value: "19165",
    type: "common",
  },
  {
    label: "AFFIEUX",
    value: "19001",
    type: "common",
  },
  {
    label: "MEILHARDS",
    value: "19131",
    type: "common",
  },
  {
    label: "SALON-LA-TOUR",
    value: "19250",
    type: "common",
  },
  {
    label: "LAMONGERIE",
    value: "19104",
    type: "common",
  },
  {
    label: "MASSERET",
    value: "19129",
    type: "common",
  },
  {
    label: "BENAYES",
    value: "19022",
    type: "common",
  },
  {
    label: "MEUZAC",
    value: "87095",
    type: "common",
  },
  {
    label: "MONTGIBAUD",
    value: "19144",
    type: "common",
  },
  {
    label: "COUSSAC-BONNEVAL",
    value: "87049",
    type: "common",
  },
  {
    label: "SAINT-YRIEIX-LA-PERCHE",
    value: "87187",
    type: "common",
  },
  {
    label: "SAINT-PRIEST-LES-FOUGERES",
    value: "24489",
    type: "common",
  },
  {
    label: "JUMILHAC-LE-GRAND",
    value: "24218",
    type: "common",
  },
  {
    label: "LA COQUILLE",
    value: "24133",
    type: "common",
  },
  {
    label: "CHALAIS",
    value: "24095",
    type: "common",
  },
  {
    label: "SAINT-JORY-DE-CHALAIS",
    value: "24428",
    type: "common",
  },
  {
    label: "SAINT-SAUD-LACOUSSIERE",
    value: "24498",
    type: "common",
  },
  {
    label: "SAINT-PARDOUX-LA-RIVIERE",
    value: "24479",
    type: "common",
  },
  {
    label: "CHAMPS-ROMAIN",
    value: "24101",
    type: "common",
  },
  {
    label: "SAINT-MARTIAL-DE-VALETTE",
    value: "24451",
    type: "common",
  },
  {
    label: "NONTRON",
    value: "24311",
    type: "common",
  },
  {
    label: "LUSSAS-ET-NONTRONNEAU",
    value: "24248",
    type: "common",
  },
  {
    label: "RUDEAU-LADOSSE",
    value: "24221",
    type: "common",
  },
  {
    label: "CONNEZAC",
    value: "24131",
    type: "common",
  },
  {
    label: "HAUTEFAYE",
    value: "24209",
    type: "common",
  },
  {
    label: "ROUGNAC",
    value: "16285",
    type: "common",
  },
  {
    label: "EDON",
    value: "16125",
    type: "common",
  },
  {
    label: "COMBIERS",
    value: "16103",
    type: "common",
  },
  {
    label: "GARDES-LE-PONTAROUX",
    value: "16147",
    type: "common",
  },
  {
    label: "MAGNAC-LAVALETTE-VILLARS",
    value: "16198",
    type: "common",
  },
  {
    label: "FOUQUEBRUNE",
    value: "16143",
    type: "common",
  },
  {
    label: "VOULGEZAC",
    value: "16420",
    type: "common",
  },
  {
    label: "BOISNE-LA TUDE",
    value: "16082",
    type: "common",
  },
  {
    label: "CHADURIE",
    value: "16072",
    type: "common",
  },
  {
    label: "PLASSAC-ROUFFIAC",
    value: "16263",
    type: "common",
  },
  {
    label: "CHAMPAGNE-VIGNY",
    value: "16075",
    type: "common",
  },
  {
    label: "COTEAUX-DU-BLANZACAIS",
    value: "16046",
    type: "common",
  },
  {
    label: "BECHERESSE",
    value: "16036",
    type: "common",
  },
  {
    label: "LADIVILLE",
    value: "16177",
    type: "common",
  },
  {
    label: "VAL DES VIGNES",
    value: "16175",
    type: "common",
  },
  {
    label: "ETRIAC",
    value: "16133",
    type: "common",
  },
  {
    label: "ANGEDUC",
    value: "16014",
    type: "common",
  },
  {
    label: "VIGNOLLES",
    value: "16405",
    type: "common",
  },
  {
    label: "SAINT-MEDARD",
    value: "16338",
    type: "common",
  },
  {
    label: "LAGARDE-SUR-LE-NE",
    value: "16178",
    type: "common",
  },
  {
    label: "LACHAISE",
    value: "16176",
    type: "common",
  },
  {
    label: "BARRET",
    value: "16030",
    type: "common",
  },
  {
    label: "BARBEZIEUX-SAINT-HILAIRE",
    value: "16028",
    type: "common",
  },
  {
    label: "SAINT-EUGENE",
    value: "17326",
    type: "common",
  },
  {
    label: "BRIE-SOUS-ARCHIAC",
    value: "17066",
    type: "common",
  },
  {
    label: "ARTHENAC",
    value: "17020",
    type: "common",
  },
  {
    label: "ARCHIAC",
    value: "17016",
    type: "common",
  },
  {
    label: "SAINTE-LHEURINE",
    value: "17355",
    type: "common",
  },
  {
    label: "REAUX SUR TREFLE",
    value: "17295",
    type: "common",
  },
  {
    label: "NEUILLAC",
    value: "17258",
    type: "common",
  },
  {
    label: "ALLAS-CHAMPAGNE",
    value: "17006",
    type: "common",
  },
  {
    label: "SAINT-GERMAIN-DE-LUSIGNAN",
    value: "17339",
    type: "common",
  },
  {
    label: "SAINT-GEORGES-ANTIGNAC",
    value: "17332",
    type: "common",
  },
  {
    label: "NEULLES",
    value: "17259",
    type: "common",
  },
  {
    label: "MARIGNAC",
    value: "17220",
    type: "common",
  },
  {
    label: "LUSSAC",
    value: "17215",
    type: "common",
  },
  {
    label: "CLAM",
    value: "17108",
    type: "common",
  },
  {
    label: "SAINT-GREGOIRE-D'ARDENNES",
    value: "17343",
    type: "common",
  },
  {
    label: "MOSNAC",
    value: "17250",
    type: "common",
  },
  {
    label: "CLION",
    value: "17111",
    type: "common",
  },
  {
    label: "SAINT-PALAIS-DE-PHIOLIN",
    value: "17379",
    type: "common",
  },
  {
    label: "SAINT-GENIS-DE-SAINTONGE",
    value: "17331",
    type: "common",
  },
  {
    label: "CHAMPAGNOLLES",
    value: "17084",
    type: "common",
  },
  {
    label: "BOIS",
    value: "17050",
    type: "common",
  },
  {
    label: "SAINT-GERMAIN-DU-SEUDRE",
    value: "17342",
    type: "common",
  },
  {
    label: "SAINT-FORT-SUR-GIRONDE",
    value: "17328",
    type: "common",
  },
  {
    label: "LORIGNAC",
    value: "17210",
    type: "common",
  },
  {
    label: "BRIE-SOUS-MORTAGNE",
    value: "17068",
    type: "common",
  },
  {
    label: "MORTAGNE-SUR-GIRONDE",
    value: "17248",
    type: "common",
  },
  {
    label: "FLOIRAC",
    value: "17160",
    type: "common",
  },
  {
    label: "CHENAC-SAINT-SEURIN-D'UZET",
    value: "17098",
    type: "common",
  },
  {
    label: "BOUTENAC-TOUVENT",
    value: "17060",
    type: "common",
  },
  {
    label: "TALAIS",
    value: "33521",
    type: "common",
  },
  {
    label: "SAINT-VIVIEN-DE-MEDOC",
    value: "33490",
    type: "common",
  },
  {
    label: "SOULAC-SUR-MER",
    value: "33514",
    type: "common",
  },
  {
    label: "BONNEVAL-SUR-ARC",
    value: "73047",
    type: "common",
  },
  {
    label: "VAL-CENIS",
    value: "73290",
    type: "common",
  },
  {
    label: "COURCHEVEL",
    value: "73227",
    type: "common",
  },
  {
    label: "LES ALLUES",
    value: "73015",
    type: "common",
  },
  {
    label: "SAINT-MARTIN-SUR-LA-CHAMBRE",
    value: "73259",
    type: "common",
  },
  {
    label: "SAINT FRANCOIS LONGCHAMP",
    value: "73235",
    type: "common",
  },
  {
    label: "NOTRE-DAME-DU-CRUET",
    value: "73189",
    type: "common",
  },
  {
    label: "LA CHAPELLE",
    value: "73074",
    type: "common",
  },
  {
    label: "SAINT-REMY-DE-MAURIENNE",
    value: "73278",
    type: "common",
  },
  {
    label: "LES CHAVANNES-EN-MAURIENNE",
    value: "73083",
    type: "common",
  },
  {
    label: "ARVILLARD",
    value: "73021",
    type: "common",
  },
  {
    label: "LA CHAPELLE-DU-BARD",
    value: "38078",
    type: "common",
  },
  {
    label: "ALLEVARD",
    value: "38006",
    type: "common",
  },
  {
    label: "SAINT-MAXIMIN",
    value: "38426",
    type: "common",
  },
  {
    label: "LE MOUTARET",
    value: "38268",
    type: "common",
  },
  {
    label: "PONTCHARRA",
    value: "38314",
    type: "common",
  },
  {
    label: "LA FLACHERE",
    value: "38166",
    type: "common",
  },
  {
    label: "LE CHEYLAS",
    value: "38100",
    type: "common",
  },
  {
    label: "LA BUISSIERE",
    value: "38062",
    type: "common",
  },
  {
    label: "BARRAUX",
    value: "38027",
    type: "common",
  },
  {
    label: "SAINTE-MARIE-DU-MONT",
    value: "38418",
    type: "common",
  },
  {
    label: "SAINT-PIERRE-D'ENTREMONT",
    value: "73274",
    type: "common",
  },
  {
    label: "CORBEL",
    value: "73092",
    type: "common",
  },
  {
    label: "SAINT-PIERRE-D'ENTREMONT",
    value: "38446",
    type: "common",
  },
  {
    label: "SAINT-CHRISTOPHE-SUR-GUIERS",
    value: "38376",
    type: "common",
  },
  {
    label: "LES ECHELLES",
    value: "73105",
    type: "common",
  },
  {
    label: "MIRIBEL-LES-ECHELLES",
    value: "38236",
    type: "common",
  },
  {
    label: "ENTRE-DEUX-GUIERS",
    value: "38155",
    type: "common",
  },
  {
    label: "SAINT-GEOIRE-EN-VALDAINE",
    value: "38386",
    type: "common",
  },
  {
    label: "SAINT-AUPRE",
    value: "38362",
    type: "common",
  },
  {
    label: "MERLAS",
    value: "38228",
    type: "common",
  },
  {
    label: "SAINT-NICOLAS-DE-MACHERIN",
    value: "38432",
    type: "common",
  },
  {
    label: "MASSIEU",
    value: "38222",
    type: "common",
  },
  {
    label: "CHIRENS",
    value: "38105",
    type: "common",
  },
  {
    label: "BILIEU",
    value: "38043",
    type: "common",
  },
  {
    label: "OYEU",
    value: "38287",
    type: "common",
  },
  {
    label: "CHARAVINES",
    value: "38082",
    type: "common",
  },
  {
    label: "LE GRAND-LEMPS",
    value: "38182",
    type: "common",
  },
  {
    label: "CHABONS",
    value: "38065",
    type: "common",
  },
  {
    label: "BURCIN",
    value: "38063",
    type: "common",
  },
  {
    label: "BIZONNES",
    value: "38046",
    type: "common",
  },
  {
    label: "MOTTIER",
    value: "38267",
    type: "common",
  },
  {
    label: "LONGECHENAL",
    value: "38213",
    type: "common",
  },
  {
    label: "EYDOCHE",
    value: "38159",
    type: "common",
  },
  {
    label: "CHAMPIER",
    value: "38069",
    type: "common",
  },
  {
    label: "LIEUDIEU",
    value: "38211",
    type: "common",
  },
  {
    label: "BOSSIEU",
    value: "38049",
    type: "common",
  },
  {
    label: "VILLENEUVE-DE-MARC",
    value: "38555",
    type: "common",
  },
  {
    label: "SAINT-JULIEN-DE-L'HERMS",
    value: "38406",
    type: "common",
  },
  {
    label: "MEYSSIEZ",
    value: "38232",
    type: "common",
  },
  {
    label: "MONTSEVEROUX",
    value: "38259",
    type: "common",
  },
  {
    label: "COUR-ET-BUIS",
    value: "38134",
    type: "common",
  },
  {
    label: "VERNIOZ",
    value: "38536",
    type: "common",
  },
  {
    label: "MONSTEROUX-MILIEU",
    value: "38244",
    type: "common",
  },
  {
    label: "LES COTES-D'AREY",
    value: "38131",
    type: "common",
  },
  {
    label: "CHALON",
    value: "38066",
    type: "common",
  },
  {
    label: "SAINT-PRIM",
    value: "38448",
    type: "common",
  },
  {
    label: "CHONAS-L'AMBALLAN",
    value: "38107",
    type: "common",
  },
  {
    label: "CHEYSSIEU",
    value: "38101",
    type: "common",
  },
  {
    label: "AUBERIVES-SUR-VAREZE",
    value: "38019",
    type: "common",
  },
  {
    label: "VERIN",
    value: "42327",
    type: "common",
  },
  {
    label: "SAINT-MICHEL-SUR-RHONE",
    value: "42265",
    type: "common",
  },
  {
    label: "CHAVANAY",
    value: "42056",
    type: "common",
  },
  {
    label: "LA CHAPELLE-VILLARS",
    value: "42051",
    type: "common",
  },
  {
    label: "SAINT-CLAIR-DU-RHONE",
    value: "38378",
    type: "common",
  },
  {
    label: "LES ROCHES-DE-CONDRIEU",
    value: "38340",
    type: "common",
  },
  {
    label: "SAINTE-CROIX-EN-JAREZ",
    value: "42210",
    type: "common",
  },
  {
    label: "PELUSSIN",
    value: "42168",
    type: "common",
  },
  {
    label: "PAVEZIN",
    value: "42167",
    type: "common",
  },
  {
    label: "CHUYER",
    value: "42064",
    type: "common",
  },
  {
    label: "LA TERRASSE-SUR-DORLAY",
    value: "42308",
    type: "common",
  },
  {
    label: "SAINT-CHAMOND",
    value: "42207",
    type: "common",
  },
  {
    label: "LA TALAUDIERE",
    value: "42305",
    type: "common",
  },
  {
    label: "SAINT-PRIEST-EN-JAREZ",
    value: "42275",
    type: "common",
  },
  {
    label: "SAINT-JEAN-BONNEFONDS",
    value: "42237",
    type: "common",
  },
  {
    label: "SAINT-ETIENNE",
    value: "42218",
    type: "common",
  },
  {
    label: "VILLARS",
    value: "42330",
    type: "common",
  },
  {
    label: "SAINT-GENEST-LERPT",
    value: "42223",
    type: "common",
  },
  {
    label: "ROCHE-LA-MOLIERE",
    value: "42189",
    type: "common",
  },
  {
    label: "CHAMBLES",
    value: "42042",
    type: "common",
  },
  {
    label: "PERIGNEUX",
    value: "42169",
    type: "common",
  },
  {
    label: "LURIECQ",
    value: "42126",
    type: "common",
  },
  {
    label: "MONTARCHER",
    value: "42146",
    type: "common",
  },
  {
    label: "MAROLS",
    value: "42140",
    type: "common",
  },
  {
    label: "LA CHAPELLE-EN-LAFAYE",
    value: "42050",
    type: "common",
  },
  {
    label: "VIVEROLS",
    value: "63465",
    type: "common",
  },
  {
    label: "SAILLANT",
    value: "63309",
    type: "common",
  },
  {
    label: "EGLISOLLES",
    value: "63147",
    type: "common",
  },
  {
    label: "LA CHAULME",
    value: "63104",
    type: "common",
  },
  {
    label: "SAINT-JUST",
    value: "63371",
    type: "common",
  },
  {
    label: "BAFFIE",
    value: "63027",
    type: "common",
  },
  {
    label: "MARSAC-EN-LIVRADOIS",
    value: "63211",
    type: "common",
  },
  {
    label: "CHAUMONT-LE-BOURG",
    value: "63105",
    type: "common",
  },
  {
    label: "BEURIERES",
    value: "63039",
    type: "common",
  },
  {
    label: "SAINT-BONNET-LE-CHASTEL",
    value: "63324",
    type: "common",
  },
  {
    label: "CHAMBON-SUR-DOLORE",
    value: "63076",
    type: "common",
  },
  {
    label: "SAINT-GERMAIN-L'HERM",
    value: "63353",
    type: "common",
  },
  {
    label: "SAINTE-CATHERINE",
    value: "63328",
    type: "common",
  },
  {
    label: "PESLIERES",
    value: "63277",
    type: "common",
  },
  {
    label: "ESTEIL",
    value: "63156",
    type: "common",
  },
  {
    label: "LA CHAPELLE-SUR-USSON",
    value: "63088",
    type: "common",
  },
  {
    label: "CHAMPAGNAT-LE-JEUNE",
    value: "63079",
    type: "common",
  },
  {
    label: "BANSAT",
    value: "63029",
    type: "common",
  },
  {
    label: "AUZAT-LA-COMBELLE",
    value: "63022",
    type: "common",
  },
  {
    label: "NONETTE-ORSONNETTE",
    value: "63255",
    type: "common",
  },
  {
    label: "LAMONTGIE",
    value: "63185",
    type: "common",
  },
  {
    label: "BEAULIEU",
    value: "63031",
    type: "common",
  },
  {
    label: "SAINT-GERMAIN-LEMBRON",
    value: "63352",
    type: "common",
  },
  {
    label: "GIGNAT",
    value: "63166",
    type: "common",
  },
  {
    label: "CHALUS",
    value: "63074",
    type: "common",
  },
  {
    label: "LE BREUIL-SUR-COUZE",
    value: "63052",
    type: "common",
  },
  {
    label: "VILLENEUVE",
    value: "63458",
    type: "common",
  },
  {
    label: "TERNANT-LES-EAUX",
    value: "63429",
    type: "common",
  },
  {
    label: "SAINT-HERENT",
    value: "63357",
    type: "common",
  },
  {
    label: "MAREUGHEOL",
    value: "63209",
    type: "common",
  },
  {
    label: "BOUDES",
    value: "63046",
    type: "common",
  },
  {
    label: "DAUZAT-SUR-VODABLE",
    value: "63134",
    type: "common",
  },
  {
    label: "CHASSAGNE",
    value: "63097",
    type: "common",
  },
  {
    label: "LA CHAPELLE-MARCOUSSE",
    value: "63087",
    type: "common",
  },
  {
    label: "VALBELEIX",
    value: "63440",
    type: "common",
  },
  {
    label: "ROCHE-CHARLES-LA-MAYRAND",
    value: "63303",
    type: "common",
  },
  {
    label: "BESSE-ET-SAINT-ANASTAISE",
    value: "63038",
    type: "common",
  },
  {
    label: "PICHERANDE",
    value: "63279",
    type: "common",
  },
  {
    label: "SAINT-DONAT",
    value: "63336",
    type: "common",
  },
  {
    label: "BAGNOLS",
    value: "63028",
    type: "common",
  },
  {
    label: "TREMOUILLE-SAINT-LOUP",
    value: "63437",
    type: "common",
  },
  {
    label: "LABESSETTE",
    value: "63183",
    type: "common",
  },
  {
    label: "CROS",
    value: "63129",
    type: "common",
  },
  {
    label: "MONESTIER-PORT-DIEU",
    value: "19142",
    type: "common",
  },
  {
    label: "BEAULIEU",
    value: "15020",
    type: "common",
  },
  {
    label: "VEYRIERES",
    value: "19283",
    type: "common",
  },
  {
    label: "SAINT-VICTOUR",
    value: "19247",
    type: "common",
  },
  {
    label: "MARGERIDES",
    value: "19128",
    type: "common",
  },
  {
    label: "VALIERGUES",
    value: "19277",
    type: "common",
  },
  {
    label: "SAINT-ETIENNE-LA-GENESTE",
    value: "19200",
    type: "common",
  },
  {
    label: "MESTES",
    value: "19135",
    type: "common",
  },
  {
    label: "CHIRAC-BELLEVUE",
    value: "19055",
    type: "common",
  },
  {
    label: "MAUSSAC",
    value: "19130",
    type: "common",
  },
  {
    label: "COMBRESSOL",
    value: "19058",
    type: "common",
  },
  {
    label: "SOUDEILLES",
    value: "19263",
    type: "common",
  },
  {
    label: "PERET-BEL-AIR",
    value: "19159",
    type: "common",
  },
  {
    label: "DAVIGNAC",
    value: "19071",
    type: "common",
  },
  {
    label: "SAINT-YRIEIX-LE-DEJALAT",
    value: "19249",
    type: "common",
  },
  {
    label: "GRANDSAIGNE",
    value: "19088",
    type: "common",
  },
  {
    label: "CHAUMEIL",
    value: "19051",
    type: "common",
  },
  {
    label: "SAINT-AUGUSTIN",
    value: "19181",
    type: "common",
  },
  {
    label: "MADRANGES",
    value: "19122",
    type: "common",
  },
  {
    label: "LE LONZAC",
    value: "19118",
    type: "common",
  },
  {
    label: "EYBURIE",
    value: "19079",
    type: "common",
  },
  {
    label: "SAINT-YBARD",
    value: "19248",
    type: "common",
  },
  {
    label: "CONDAT-SUR-GANAVEIX",
    value: "19060",
    type: "common",
  },
  {
    label: "SAINT-MARTIN-SEPERT",
    value: "19223",
    type: "common",
  },
  {
    label: "LUBERSAC",
    value: "19121",
    type: "common",
  },
  {
    label: "SAINT-JULIEN-LE-VENDOMOIS",
    value: "19216",
    type: "common",
  },
  {
    label: "SAINT-ELOY-LES-TUILERIES",
    value: "19198",
    type: "common",
  },
  {
    label: "GLANDON",
    value: "87071",
    type: "common",
  },
  {
    label: "SARLANDE",
    value: "24519",
    type: "common",
  },
  {
    label: "ANGOISSE",
    value: "24008",
    type: "common",
  },
  {
    label: "SARRAZAC",
    value: "24522",
    type: "common",
  },
  {
    label: "SAINT-PAUL-LA-ROCHE",
    value: "24481",
    type: "common",
  },
  {
    label: "SAINT-MARTIN-DE-FRESSENGEAS",
    value: "24453",
    type: "common",
  },
  {
    label: "MILHAC-DE-NONTRON",
    value: "24271",
    type: "common",
  },
  {
    label: "SAINT-FRONT-LA-RIVIERE",
    value: "24410",
    type: "common",
  },
  {
    label: "QUINSAC",
    value: "24346",
    type: "common",
  },
  {
    label: "SCEAU-SAINT-ANGEL",
    value: "24528",
    type: "common",
  },
  {
    label: "SAINT-FRONT-SUR-NIZONNE",
    value: "24411",
    type: "common",
  },
  {
    label: "LA CHAPELLE-MONTMOREAU",
    value: "24111",
    type: "common",
  },
  {
    label: "MAREUIL EN PERIGORD",
    value: "24253",
    type: "common",
  },
  {
    label: "SAINTE-CROIX-DE-MAREUIL",
    value: "24394",
    type: "common",
  },
  {
    label: "LA ROCHEBEAUCOURT-ET-ARGENTINE",
    value: "24353",
    type: "common",
  },
  {
    label: "CHAMPAGNE-ET-FONTAINE",
    value: "24097",
    type: "common",
  },
  {
    label: "VILLEBOIS-LAVALETTE",
    value: "16408",
    type: "common",
  },
  {
    label: "BLANZAGUET-SAINT-CYBARD",
    value: "16047",
    type: "common",
  },
  {
    label: "RONSENAC",
    value: "16283",
    type: "common",
  },
  {
    label: "GURAT",
    value: "16162",
    type: "common",
  },
  {
    label: "MONTMOREAU",
    value: "16230",
    type: "common",
  },
  {
    label: "PERIGNAC",
    value: "16258",
    type: "common",
  },
  {
    label: "NONAC",
    value: "16246",
    type: "common",
  },
  {
    label: "SAINT-AULAIS-LA-CHAPELLE",
    value: "16301",
    type: "common",
  },
  {
    label: "BRIE-SOUS-BARBEZIEUX",
    value: "16062",
    type: "common",
  },
  {
    label: "BESSAC",
    value: "16041",
    type: "common",
  },
  {
    label: "SALLES-DE-BARBEZIEUX",
    value: "16360",
    type: "common",
  },
  {
    label: "SAINT-BONNET",
    value: "16303",
    type: "common",
  },
  {
    label: "CHALLIGNAC",
    value: "16074",
    type: "common",
  },
  {
    label: "REIGNAC",
    value: "16276",
    type: "common",
  },
  {
    label: "MONTMERAC",
    value: "16224",
    type: "common",
  },
  {
    label: "SAINT-MAIGRIN",
    value: "17357",
    type: "common",
  },
  {
    label: "SAINT-CIERS-CHAMPAGNE",
    value: "17316",
    type: "common",
  },
  {
    label: "GUIMPS",
    value: "16160",
    type: "common",
  },
  {
    label: "SAINT-GERMAIN-DE-VIBRAC",
    value: "17341",
    type: "common",
  },
  {
    label: "OZILLAC",
    value: "17270",
    type: "common",
  },
  {
    label: "MEUX",
    value: "17233",
    type: "common",
  },
  {
    label: "CHAMPAGNAC",
    value: "17082",
    type: "common",
  },
  {
    label: "SAINT-SIMON-DE-BORDES",
    value: "17403",
    type: "common",
  },
  {
    label: "SAINT-MARTIAL-DE-VITATERNE",
    value: "17363",
    type: "common",
  },
  {
    label: "JONZAC",
    value: "17197",
    type: "common",
  },
  {
    label: "SAINT-SIGISMOND-DE-CLERMONT",
    value: "17402",
    type: "common",
  },
  {
    label: "SAINT-HILAIRE-DU-BOIS",
    value: "17345",
    type: "common",
  },
  {
    label: "GUITINIERES",
    value: "17187",
    type: "common",
  },
  {
    label: "SAINT-CIERS-DU-TAILLON",
    value: "17317",
    type: "common",
  },
  {
    label: "PLASSAC",
    value: "17279",
    type: "common",
  },
  {
    label: "CONSAC",
    value: "17116",
    type: "common",
  },
  {
    label: "SAINTE-RAMEE",
    value: "17390",
    type: "common",
  },
  {
    label: "SAINT-DIZANT-DU-GUA",
    value: "17325",
    type: "common",
  },
  {
    label: "JAU-DIGNAC-ET-LOIRAC",
    value: "33208",
    type: "common",
  },
  {
    label: "VENSAC",
    value: "33541",
    type: "common",
  },
  {
    label: "GRAYAN-ET-L'HOPITAL",
    value: "33193",
    type: "common",
  },
  {
    label: "BESSANS",
    value: "73040",
    type: "common",
  },
  {
    label: "PRALOGNAN-LA-VANOISE",
    value: "73206",
    type: "common",
  },
  {
    label: "LES BELLEVILLE",
    value: "73257",
    type: "common",
  },
  {
    label: "SAINTE-MARIE-DE-CUINES",
    value: "73255",
    type: "common",
  },
  {
    label: "SAINT-AVRE",
    value: "73224",
    type: "common",
  },
  {
    label: "MONTVERNIER",
    value: "73177",
    type: "common",
  },
  {
    label: "LA CHAMBRE",
    value: "73067",
    type: "common",
  },
  {
    label: "SAINT-ETIENNE-DE-CUINES",
    value: "73231",
    type: "common",
  },
  {
    label: "CRETS EN BELLEDONNE",
    value: "38439",
    type: "common",
  },
  {
    label: "SAINT-VINCENT-DE-MERCUZE",
    value: "38466",
    type: "common",
  },
  {
    label: "SAINTE-MARIE-D'ALLOIX",
    value: "38417",
    type: "common",
  },
  {
    label: "GONCELIN",
    value: "38181",
    type: "common",
  },
  {
    label: "LE TOUVET",
    value: "38511",
    type: "common",
  },
  {
    label: "LA TERRASSE",
    value: "38503",
    type: "common",
  },
  {
    label: "SAINT-PIERRE-DE-CHARTREUSE",
    value: "38442",
    type: "common",
  },
  {
    label: "SAINT-LAURENT-DU-PONT",
    value: "38412",
    type: "common",
  },
  {
    label: "LA SURE EN CHARTREUSE",
    value: "38407",
    type: "common",
  },
  {
    label: "SAINT-JOSEPH-DE-RIVIERE",
    value: "38405",
    type: "common",
  },
  {
    label: "SAINT-ETIENNE-DE-CROSSEY",
    value: "38383",
    type: "common",
  },
  {
    label: "VOIRON",
    value: "38563",
    type: "common",
  },
  {
    label: "SAINT-JEAN-DE-MOIRANS",
    value: "38400",
    type: "common",
  },
  {
    label: "SAINT-CASSIEN",
    value: "38373",
    type: "common",
  },
  {
    label: "LA MURETTE",
    value: "38270",
    type: "common",
  },
  {
    label: "COUBLEVIE",
    value: "38133",
    type: "common",
  },
  {
    label: "SAINT-BLAISE-DU-BUIS",
    value: "38368",
    type: "common",
  },
  {
    label: "RIVES",
    value: "38337",
    type: "common",
  },
  {
    label: "REAUMONT",
    value: "38331",
    type: "common",
  },
  {
    label: "CHARNECLES",
    value: "38084",
    type: "common",
  },
  {
    label: "BEAUCROISSANT",
    value: "38030",
    type: "common",
  },
  {
    label: "APPRIEU",
    value: "38013",
    type: "common",
  },
  {
    label: "SILLANS",
    value: "38490",
    type: "common",
  },
  {
    label: "IZEAUX",
    value: "38194",
    type: "common",
  },
  {
    label: "COLOMBE",
    value: "38118",
    type: "common",
  },
  {
    label: "BEVENAIS",
    value: "38042",
    type: "common",
  },
  {
    label: "SAINT-HILAIRE-DE-LA-COTE",
    value: "38393",
    type: "common",
  },
  {
    label: "SAINT-ETIENNE-DE-SAINT-GEOIRS",
    value: "38384",
    type: "common",
  },
  {
    label: "GILLONNAY",
    value: "38180",
    type: "common",
  },
  {
    label: "LA FRETTE",
    value: "38174",
    type: "common",
  },
  {
    label: "SARDIEU",
    value: "38473",
    type: "common",
  },
  {
    label: "LA COTE-SAINT-ANDRE",
    value: "38130",
    type: "common",
  },
  {
    label: "PENOL",
    value: "38300",
    type: "common",
  },
  {
    label: "PAJAY",
    value: "38291",
    type: "common",
  },
  {
    label: "FARAMANS",
    value: "38161",
    type: "common",
  },
  {
    label: "POMMIER-DE-BEAUREPAIRE",
    value: "38311",
    type: "common",
  },
  {
    label: "PISIEU",
    value: "38307",
    type: "common",
  },
  {
    label: "REVEL-TOURDAN",
    value: "38335",
    type: "common",
  },
  {
    label: "PRIMARETTE",
    value: "38324",
    type: "common",
  },
  {
    label: "PACT",
    value: "38290",
    type: "common",
  },
  {
    label: "MOISSIEU-SUR-DOLON",
    value: "38240",
    type: "common",
  },
  {
    label: "SONNAY",
    value: "38496",
    type: "common",
  },
  {
    label: "SAINT-ROMAIN-DE-SURIEU",
    value: "38452",
    type: "common",
  },
  {
    label: "LA CHAPELLE-DE-SURIEU",
    value: "38077",
    type: "common",
  },
  {
    label: "BELLEGARDE-POUSSIEU",
    value: "38037",
    type: "common",
  },
  {
    label: "ASSIEU",
    value: "38017",
    type: "common",
  },
  {
    label: "VILLE-SOUS-ANJOU",
    value: "38556",
    type: "common",
  },
  {
    label: "ROUSSILLON",
    value: "38344",
    type: "common",
  },
  {
    label: "CLONAS-SUR-VAREZE",
    value: "38114",
    type: "common",
  },
  {
    label: "SAINT-PIERRE-DE-BŒUF",
    value: "42272",
    type: "common",
  },
  {
    label: "MALLEVAL",
    value: "42132",
    type: "common",
  },
  {
    label: "SAINT-MAURICE-L'EXIL",
    value: "38425",
    type: "common",
  },
  {
    label: "SAINT-ALBAN-DU-RHONE",
    value: "38353",
    type: "common",
  },
  {
    label: "LE PEAGE-DE-ROUSSILLON",
    value: "38298",
    type: "common",
  },
  {
    label: "VERANNE",
    value: "42326",
    type: "common",
  },
  {
    label: "ROISEY",
    value: "42191",
    type: "common",
  },
  {
    label: "LUPE",
    value: "42124",
    type: "common",
  },
  {
    label: "BESSEY",
    value: "42018",
    type: "common",
  },
  {
    label: "GRAIX",
    value: "42101",
    type: "common",
  },
  {
    label: "DOIZIEUX",
    value: "42085",
    type: "common",
  },
  {
    label: "LA VALLA-EN-GIER",
    value: "42322",
    type: "common",
  },
  {
    label: "TARENTAISE",
    value: "42306",
    type: "common",
  },
  {
    label: "LE BESSAT",
    value: "42017",
    type: "common",
  },
  {
    label: "SAINT-ROMAIN-LES-ATHEUX",
    value: "42286",
    type: "common",
  },
  {
    label: "PLANFOY",
    value: "42172",
    type: "common",
  },
  {
    label: "LA RICAMARIE",
    value: "42183",
    type: "common",
  },
  {
    label: "FIRMINY",
    value: "42095",
    type: "common",
  },
  {
    label: "LE CHAMBON-FEUGEROLLES",
    value: "42044",
    type: "common",
  },
  {
    label: "AUREC-SUR-LOIRE",
    value: "43012",
    type: "common",
  },
  {
    label: "UNIEUX",
    value: "42316",
    type: "common",
  },
  {
    label: "SAINT-PAUL-EN-CORNILLON",
    value: "42270",
    type: "common",
  },
  {
    label: "FRAISSES",
    value: "42099",
    type: "common",
  },
  {
    label: "CALOIRE",
    value: "42031",
    type: "common",
  },
  {
    label: "SAINT-MAURICE-EN-GOURGOIS",
    value: "42262",
    type: "common",
  },
  {
    label: "ABOEN",
    value: "42001",
    type: "common",
  },
  {
    label: "LA TOURETTE",
    value: "42312",
    type: "common",
  },
  {
    label: "SAINT-NIZIER-DE-FORNAS",
    value: "42266",
    type: "common",
  },
  {
    label: "SAINT-HILAIRE-CUSSON-LA-VALMITTE",
    value: "42235",
    type: "common",
  },
  {
    label: "SAINT-BONNET-LE-CHATEAU",
    value: "42204",
    type: "common",
  },
  {
    label: "ROZIER-COTES-D'AUREC",
    value: "42192",
    type: "common",
  },
  {
    label: "MERLE-LEIGNEC",
    value: "42142",
    type: "common",
  },
  {
    label: "ESTIVAREILLES",
    value: "42091",
    type: "common",
  },
  {
    label: "APINAC",
    value: "42006",
    type: "common",
  },
  {
    label: "USSON-EN-FOREZ",
    value: "42318",
    type: "common",
  },
  {
    label: "SAUVESSANGES",
    value: "63412",
    type: "common",
  },
  {
    label: "MEDEYROLLES",
    value: "63221",
    type: "common",
  },
  {
    label: "MAYRES",
    value: "63218",
    type: "common",
  },
  {
    label: "DORE-L'EGLISE",
    value: "63139",
    type: "common",
  },
  {
    label: "ARLANC",
    value: "63010",
    type: "common",
  },
  {
    label: "SAINT-SAUVEUR-LA-SAGNE",
    value: "63398",
    type: "common",
  },
  {
    label: "NOVACELLES",
    value: "63256",
    type: "common",
  },
  {
    label: "SAINT-BONNET-LE-BOURG",
    value: "63323",
    type: "common",
  },
  {
    label: "FAYET-RONAYE",
    value: "63158",
    type: "common",
  },
  {
    label: "DORANGES",
    value: "63137",
    type: "common",
  },
  {
    label: "SAINT-VERT",
    value: "43226",
    type: "common",
  },
  {
    label: "SAINT-MARTIN-D'OLLIERES",
    value: "63376",
    type: "common",
  },
  {
    label: "CHASSIGNOLLES",
    value: "43064",
    type: "common",
  },
  {
    label: "VALZ-SOUS-CHATEAUNEUF",
    value: "63442",
    type: "common",
  },
  {
    label: "SAINT-JEAN-SAINT-GERVAIS",
    value: "63367",
    type: "common",
  },
  {
    label: "SAINT-HILAIRE",
    value: "43193",
    type: "common",
  },
  {
    label: "AUZON",
    value: "43016",
    type: "common",
  },
  {
    label: "JUMEAUX",
    value: "63182",
    type: "common",
  },
  {
    label: "CHARBONNIER-LES-MINES",
    value: "63091",
    type: "common",
  },
  {
    label: "BRASSAC-LES-MINES",
    value: "63050",
    type: "common",
  },
  {
    label: "VEZEZOUX",
    value: "43261",
    type: "common",
  },
  {
    label: "SAINTE-FLORINE",
    value: "43185",
    type: "common",
  },
  {
    label: "FRUGERES-LES-MINES",
    value: "43099",
    type: "common",
  },
  {
    label: "LEMPDES-SUR-ALLAGNON",
    value: "43120",
    type: "common",
  },
  {
    label: "VICHEL",
    value: "63456",
    type: "common",
  },
  {
    label: "SAINT-GERVAZY",
    value: "63356",
    type: "common",
  },
  {
    label: "MORIAT",
    value: "63242",
    type: "common",
  },
  {
    label: "COLLANGES",
    value: "63114",
    type: "common",
  },
  {
    label: "CHAMBEZON",
    value: "43050",
    type: "common",
  },
  {
    label: "RENTIERES",
    value: "63299",
    type: "common",
  },
  {
    label: "MADRIAT",
    value: "63202",
    type: "common",
  },
  {
    label: "AUGNAT",
    value: "63017",
    type: "common",
  },
  {
    label: "ARDES",
    value: "63009",
    type: "common",
  },
  {
    label: "MAZOIRES",
    value: "63220",
    type: "common",
  },
  {
    label: "SAINT-ALYRE-ES-MONTAGNE",
    value: "63313",
    type: "common",
  },
  {
    label: "LA GODIVELLE",
    value: "63169",
    type: "common",
  },
  {
    label: "ESPINCHAL",
    value: "63153",
    type: "common",
  },
  {
    label: "COMPAINS",
    value: "63117",
    type: "common",
  },
  {
    label: "EGLISENEUVE-D'ENTRAIGUES",
    value: "63144",
    type: "common",
  },
  {
    label: "SAINT-GENES-CHAMPESPE",
    value: "63346",
    type: "common",
  },
  {
    label: "TREMOUILLE",
    value: "15240",
    type: "common",
  },
  {
    label: "LANOBRE",
    value: "15092",
    type: "common",
  },
  {
    label: "CHAMPS-SUR-TARENTAINE-MARCHAL",
    value: "15038",
    type: "common",
  },
  {
    label: "SARROUX - SAINT JULIEN",
    value: "19252",
    type: "common",
  },
  {
    label: "BORT-LES-ORGUES",
    value: "19028",
    type: "common",
  },
  {
    label: "ROCHE-LE-PEYROUX",
    value: "19175",
    type: "common",
  },
  {
    label: "SAINT-PIERRE",
    value: "15206",
    type: "common",
  },
  {
    label: "SAINTE-MARIE-LAPANOUZE",
    value: "19219",
    type: "common",
  },
  {
    label: "LIGINIAC",
    value: "19113",
    type: "common",
  },
  {
    label: "PALISSE",
    value: "19157",
    type: "common",
  },
  {
    label: "DARNETS",
    value: "19070",
    type: "common",
  },
  {
    label: "MOUSTIER-VENTADOUR",
    value: "19145",
    type: "common",
  },
  {
    label: "EGLETONS",
    value: "19073",
    type: "common",
  },
  {
    label: "SARRAN",
    value: "19251",
    type: "common",
  },
  {
    label: "MEYRIGNAC-L'EGLISE",
    value: "19137",
    type: "common",
  },
  {
    label: "BEAUMONT",
    value: "19020",
    type: "common",
  },
  {
    label: "SAINT-SALVADOUR",
    value: "19240",
    type: "common",
  },
  {
    label: "CHAMBOULIVE",
    value: "19037",
    type: "common",
  },
  {
    label: "SAINT-JAL",
    value: "19213",
    type: "common",
  },
  {
    label: "PIERREFITTE",
    value: "19166",
    type: "common",
  },
  {
    label: "UZERCHE",
    value: "19276",
    type: "common",
  },
  {
    label: "ESPARTIGNAC",
    value: "19076",
    type: "common",
  },
  {
    label: "TROCHE",
    value: "19270",
    type: "common",
  },
  {
    label: "SAINT-PARDOUX-CORBIER",
    value: "19230",
    type: "common",
  },
  {
    label: "ARNAC-POMPADOUR",
    value: "19011",
    type: "common",
  },
  {
    label: "SEGUR-LE-CHATEAU",
    value: "19254",
    type: "common",
  },
  {
    label: "BEYSSENAC",
    value: "19025",
    type: "common",
  },
  {
    label: "SAVIGNAC-LEDRIER",
    value: "24526",
    type: "common",
  },
  {
    label: "PAYZAC",
    value: "24320",
    type: "common",
  },
  {
    label: "LANOUAILLE",
    value: "24227",
    type: "common",
  },
  {
    label: "SAINT-SULPICE-D'EXCIDEUIL",
    value: "24505",
    type: "common",
  },
  {
    label: "DUSSAC",
    value: "24158",
    type: "common",
  },
  {
    label: "NANTHIAT",
    value: "24305",
    type: "common",
  },
  {
    label: "NANTHEUIL",
    value: "24304",
    type: "common",
  },
  {
    label: "CORGNAC-SUR-L'ISLE",
    value: "24134",
    type: "common",
  },
  {
    label: "THIVIERS",
    value: "24551",
    type: "common",
  },
  {
    label: "SAINT-ROMAIN-ET-SAINT-CLEMENT",
    value: "24496",
    type: "common",
  },
  {
    label: "EYZERAC",
    value: "24171",
    type: "common",
  },
  {
    label: "VILLARS",
    value: "24582",
    type: "common",
  },
  {
    label: "SAINT-PIERRE-DE-COLE",
    value: "24485",
    type: "common",
  },
  {
    label: "SAINT-JEAN-DE-COLE",
    value: "24425",
    type: "common",
  },
  {
    label: "LA CHAPELLE-FAUCHER",
    value: "24107",
    type: "common",
  },
  {
    label: "CHAMPAGNAC-DE-BELAIR",
    value: "24096",
    type: "common",
  },
  {
    label: "SAINT-PANCRACE",
    value: "24474",
    type: "common",
  },
  {
    label: "SAINT-FELIX-DE-BOURDEILLES",
    value: "24403",
    type: "common",
  },
  {
    label: "LA CHAPELLE-MONTABOURLET",
    value: "24110",
    type: "common",
  },
  {
    label: "GOUT-ROSSIGNOL",
    value: "24199",
    type: "common",
  },
  {
    label: "CHERVAL",
    value: "24119",
    type: "common",
  },
  {
    label: "VENDOIRE",
    value: "24569",
    type: "common",
  },
  {
    label: "NANTEUIL-AURIAC-DE-BOURZAC",
    value: "24303",
    type: "common",
  },
  {
    label: "LA CHAPELLE-GRESIGNAC",
    value: "24109",
    type: "common",
  },
  {
    label: "VAUX-LAVALETTE",
    value: "16394",
    type: "common",
  },
  {
    label: "SALLES-LAVALETTE",
    value: "16362",
    type: "common",
  },
  {
    label: "JUIGNAC",
    value: "16170",
    type: "common",
  },
  {
    label: "SAINT-MARTIAL",
    value: "16334",
    type: "common",
  },
  {
    label: "COURGEAC",
    value: "16111",
    type: "common",
  },
  {
    label: "SAINTE-SOULINE",
    value: "16354",
    type: "common",
  },
  {
    label: "SAINT-FELIX",
    value: "16315",
    type: "common",
  },
  {
    label: "POULLIGNAC",
    value: "16267",
    type: "common",
  },
  {
    label: "DEVIAT",
    value: "16118",
    type: "common",
  },
  {
    label: "ORIOLLES",
    value: "16251",
    type: "common",
  },
  {
    label: "CONDEON",
    value: "16105",
    type: "common",
  },
  {
    label: "CHILLAC",
    value: "16099",
    type: "common",
  },
  {
    label: "BERNEUIL",
    value: "16040",
    type: "common",
  },
  {
    label: "TOUVERAC",
    value: "16384",
    type: "common",
  },
  {
    label: "LE TATRE",
    value: "16380",
    type: "common",
  },
  {
    label: "VANZAC",
    value: "17458",
    type: "common",
  },
  {
    label: "BAIGNES-SAINTE-RADEGONDE",
    value: "16025",
    type: "common",
  },
  {
    label: "SAINT-MEDARD",
    value: "17372",
    type: "common",
  },
  {
    label: "MORTIERS",
    value: "17249",
    type: "common",
  },
  {
    label: "LEOVILLE",
    value: "17204",
    type: "common",
  },
  {
    label: "FONTAINES-D'OZILLAC",
    value: "17163",
    type: "common",
  },
  {
    label: "CHAUNAC",
    value: "17096",
    type: "common",
  },
  {
    label: "VILLEXAVIER",
    value: "17476",
    type: "common",
  },
  {
    label: "TUGERAS-SAINT-MAURICE",
    value: "17454",
    type: "common",
  },
  {
    label: "SALIGNAC-DE-MIRAMBEAU",
    value: "17417",
    type: "common",
  },
  {
    label: "AGUDELLE",
    value: "17002",
    type: "common",
  },
  {
    label: "SOUBRAN",
    value: "17430",
    type: "common",
  },
  {
    label: "NIEUL-LE-VIROUIL",
    value: "17263",
    type: "common",
  },
  {
    label: "MIRAMBEAU",
    value: "17236",
    type: "common",
  },
  {
    label: "ALLAS-BOCAGE",
    value: "17005",
    type: "common",
  },
  {
    label: "SEMOUSSAC",
    value: "17424",
    type: "common",
  },
  {
    label: "SEMILLAC",
    value: "17423",
    type: "common",
  },
  {
    label: "SAINT-MARTIAL-DE-MIRAMBEAU",
    value: "17362",
    type: "common",
  },
  {
    label: "SAINT-DIZANT-DU-BOIS",
    value: "17324",
    type: "common",
  },
  {
    label: "SAINT-BONNET-SUR-GIRONDE",
    value: "17312",
    type: "common",
  },
  {
    label: "SAINT-THOMAS-DE-CONAC",
    value: "17410",
    type: "common",
  },
  {
    label: "SAINT-SORLIN-DE-CONAC",
    value: "17405",
    type: "common",
  },
  {
    label: "SAINT-GEORGES-DES-AGOUTS",
    value: "17335",
    type: "common",
  },
  {
    label: "VALEYRAC",
    value: "33538",
    type: "common",
  },
  {
    label: "SAINT-CHRISTOLY-MEDOC",
    value: "33383",
    type: "common",
  },
  {
    label: "COUQUEQUES",
    value: "33134",
    type: "common",
  },
  {
    label: "BEGADAN",
    value: "33038",
    type: "common",
  },
  {
    label: "CIVRAC-EN-MEDOC",
    value: "33128",
    type: "common",
  },
  {
    label: "QUEYRAC",
    value: "33348",
    type: "common",
  },
  {
    label: "VENDAYS-MONTALIVET",
    value: "33540",
    type: "common",
  },
  {
    label: "AUSSOIS",
    value: "73023",
    type: "common",
  },
  {
    label: "SAINT-MARTIN-DE-LA-PORTE",
    value: "73258",
    type: "common",
  },
  {
    label: "VILLARGONDRAN",
    value: "73320",
    type: "common",
  },
  {
    label: "SAINT-JULIEN-MONT-DENIS",
    value: "73250",
    type: "common",
  },
  {
    label: "LA TOUR-EN-MAURIENNE",
    value: "73135",
    type: "common",
  },
  {
    label: "SAINT-PANCRACE",
    value: "73267",
    type: "common",
  },
  {
    label: "SAINT-JEAN-DE-MAURIENNE",
    value: "73248",
    type: "common",
  },
  {
    label: "JARRIER",
    value: "73138",
    type: "common",
  },
  {
    label: "SAINT-ALBAN-DES-VILLARDS",
    value: "73221",
    type: "common",
  },
  {
    label: "SAINT-COLOMBAN-DES-VILLARDS",
    value: "73230",
    type: "common",
  },
  {
    label: "THEYS",
    value: "38504",
    type: "common",
  },
  {
    label: "TENCIN",
    value: "38501",
    type: "common",
  },
  {
    label: "HURTIERES",
    value: "38192",
    type: "common",
  },
  {
    label: "LA PIERRE",
    value: "38303",
    type: "common",
  },
  {
    label: "LUMBIN",
    value: "38214",
    type: "common",
  },
  {
    label: "CROLLES",
    value: "38140",
    type: "common",
  },
  {
    label: "LE CHAMP-PRES-FROGES",
    value: "38070",
    type: "common",
  },
  {
    label: "SARCENAS",
    value: "38472",
    type: "common",
  },
  {
    label: "PROVEYSIEUX",
    value: "38325",
    type: "common",
  },
  {
    label: "VOREPPE",
    value: "38565",
    type: "common",
  },
  {
    label: "MONT-SAINT-MARTIN",
    value: "38258",
    type: "common",
  },
  {
    label: "LA BUISSE",
    value: "38061",
    type: "common",
  },
  {
    label: "MOIRANS",
    value: "38239",
    type: "common",
  },
  {
    label: "VOUREY",
    value: "38566",
    type: "common",
  },
  {
    label: "TULLINS",
    value: "38517",
    type: "common",
  },
  {
    label: "RENAGE",
    value: "38332",
    type: "common",
  },
  {
    label: "MORETTE",
    value: "38263",
    type: "common",
  },
  {
    label: "SAINT-PAUL-D'IZEAUX",
    value: "38437",
    type: "common",
  },
  {
    label: "PLAN",
    value: "38308",
    type: "common",
  },
  {
    label: "LA FORTERESSE",
    value: "38171",
    type: "common",
  },
  {
    label: "SAINT-PIERRE-DE-BRESSIEUX",
    value: "38440",
    type: "common",
  },
  {
    label: "SAINT-MICHEL-DE-SAINT-GEOIRS",
    value: "38427",
    type: "common",
  },
  {
    label: "SAINT-GEOIRS",
    value: "38387",
    type: "common",
  },
  {
    label: "BRION",
    value: "38060",
    type: "common",
  },
  {
    label: "BREZINS",
    value: "38058",
    type: "common",
  },
  {
    label: "VIRIVILLE",
    value: "38561",
    type: "common",
  },
  {
    label: "SAINT-SIMEON-DE-BRESSIEUX",
    value: "38457",
    type: "common",
  },
  {
    label: "MARNANS",
    value: "38221",
    type: "common",
  },
  {
    label: "CHATENAY",
    value: "38093",
    type: "common",
  },
  {
    label: "BRESSIEUX",
    value: "38056",
    type: "common",
  },
  {
    label: "THODURE",
    value: "38505",
    type: "common",
  },
  {
    label: "MARCILLOLES",
    value: "38218",
    type: "common",
  },
  {
    label: "BEAUFORT",
    value: "38032",
    type: "common",
  },
  {
    label: "SAINT-BARTHELEMY",
    value: "38363",
    type: "common",
  },
  {
    label: "MARCOLLIN",
    value: "38219",
    type: "common",
  },
  {
    label: "LENTIOL",
    value: "38209",
    type: "common",
  },
  {
    label: "BEAUREPAIRE",
    value: "38034",
    type: "common",
  },
  {
    label: "LENS-LESTANG",
    value: "26162",
    type: "common",
  },
  {
    label: "JARCIEU",
    value: "38198",
    type: "common",
  },
  {
    label: "MANTHES",
    value: "26172",
    type: "common",
  },
  {
    label: "LAPEYROUSE-MORNAY",
    value: "26155",
    type: "common",
  },
  {
    label: "BOUGE-CHAMBALUD",
    value: "38051",
    type: "common",
  },
  {
    label: "ANJOU",
    value: "38009",
    type: "common",
  },
  {
    label: "EPINOUZE",
    value: "26118",
    type: "common",
  },
  {
    label: "SALAISE-SUR-SANNE",
    value: "38468",
    type: "common",
  },
  {
    label: "SABLONS",
    value: "38349",
    type: "common",
  },
  {
    label: "CHANAS",
    value: "38072",
    type: "common",
  },
  {
    label: "AGNIN",
    value: "38003",
    type: "common",
  },
  {
    label: "VINZIEUX",
    value: "07344",
    type: "common",
  },
  {
    label: "SERRIERES",
    value: "07313",
    type: "common",
  },
  {
    label: "PEYRAUD",
    value: "07174",
    type: "common",
  },
  {
    label: "LIMONY",
    value: "07143",
    type: "common",
  },
  {
    label: "FELINES",
    value: "07089",
    type: "common",
  },
  {
    label: "CHARNAS",
    value: "07056",
    type: "common",
  },
  {
    label: "SAINT-JULIEN-MOLIN-MOLETTE",
    value: "42246",
    type: "common",
  },
  {
    label: "SAINT-APPOLINARD",
    value: "42201",
    type: "common",
  },
  {
    label: "MACLAS",
    value: "42129",
    type: "common",
  },
  {
    label: "SAINT-JACQUES-D'ATTICIEUX",
    value: "07243",
    type: "common",
  },
  {
    label: "BROSSAINC",
    value: "07044",
    type: "common",
  },
  {
    label: "THELIS-LA-COMBE",
    value: "42310",
    type: "common",
  },
  {
    label: "COLOMBIER",
    value: "42067",
    type: "common",
  },
  {
    label: "BOURG-ARGENTAL",
    value: "42023",
    type: "common",
  },
  {
    label: "LA VERSANNE",
    value: "42329",
    type: "common",
  },
  {
    label: "SAINT-GENEST-MALIFAUX",
    value: "42224",
    type: "common",
  },
  {
    label: "SAINT-JUST-MALMONT",
    value: "43205",
    type: "common",
  },
  {
    label: "SAINT-DIDIER-EN-VELAY",
    value: "43177",
    type: "common",
  },
  {
    label: "JONZIEUX",
    value: "42115",
    type: "common",
  },
  {
    label: "SAINT-FERREOL-D'AUROURE",
    value: "43184",
    type: "common",
  },
  {
    label: "PONT-SALOMON",
    value: "43153",
    type: "common",
  },
  {
    label: "LA CHAPELLE-D'AUREC",
    value: "43058",
    type: "common",
  },
  {
    label: "MALVALETTE",
    value: "43127",
    type: "common",
  },
  {
    label: "VALPRIVAS",
    value: "43249",
    type: "common",
  },
  {
    label: "BAS-EN-BASSET",
    value: "43020",
    type: "common",
  },
  {
    label: "SAINT-PAL-DE-CHALENCON",
    value: "43212",
    type: "common",
  },
  {
    label: "BOISSET",
    value: "43034",
    type: "common",
  },
  {
    label: "SAINT-JEAN-D'AUBRIGOUX",
    value: "43196",
    type: "common",
  },
  {
    label: "CRAPONNE-SUR-ARZON",
    value: "43080",
    type: "common",
  },
  {
    label: "SAINT-VICTOR-SUR-ARLANC",
    value: "43228",
    type: "common",
  },
  {
    label: "MALVIERES",
    value: "43128",
    type: "common",
  },
  {
    label: "SAINT-ALYRE-D'ARLANC",
    value: "63312",
    type: "common",
  },
  {
    label: "LA CHAPELLE-GENESTE",
    value: "43059",
    type: "common",
  },
  {
    label: "LAVAL-SUR-DOULON",
    value: "43116",
    type: "common",
  },
  {
    label: "CISTRIERES",
    value: "43073",
    type: "common",
  },
  {
    label: "CHANIAT",
    value: "43055",
    type: "common",
  },
  {
    label: "CHAMPAGNAC-LE-VIEUX",
    value: "43052",
    type: "common",
  },
  {
    label: "AGNAT",
    value: "43001",
    type: "common",
  },
  {
    label: "COHADE",
    value: "43074",
    type: "common",
  },
  {
    label: "AZERAT",
    value: "43017",
    type: "common",
  },
  {
    label: "VERGONGHEON",
    value: "43258",
    type: "common",
  },
  {
    label: "SAINT-GERON",
    value: "43191",
    type: "common",
  },
  {
    label: "BOURNONCLE-SAINT-PIERRE",
    value: "43038",
    type: "common",
  },
  {
    label: "TORSIAC",
    value: "43247",
    type: "common",
  },
  {
    label: "LORLANGES",
    value: "43123",
    type: "common",
  },
  {
    label: "LEOTOING",
    value: "43121",
    type: "common",
  },
  {
    label: "APCHAT",
    value: "63007",
    type: "common",
  },
  {
    label: "AUTRAC",
    value: "43014",
    type: "common",
  },
  {
    label: "LEYVAUX",
    value: "15105",
    type: "common",
  },
  {
    label: "ANZAT-LE-LUGUET",
    value: "63006",
    type: "common",
  },
  {
    label: "MONTGRELEIX",
    value: "15132",
    type: "common",
  },
  {
    label: "CONDAT",
    value: "15054",
    type: "common",
  },
  {
    label: "CHANTERELLE",
    value: "15040",
    type: "common",
  },
  {
    label: "SAINT-AMANDIN",
    value: "15170",
    type: "common",
  },
  {
    label: "MONTBOUDIF",
    value: "15129",
    type: "common",
  },
  {
    label: "SAINT-ETIENNE-DE-CHOMEIL",
    value: "15185",
    type: "common",
  },
  {
    label: "ANTIGNAC",
    value: "15008",
    type: "common",
  },
  {
    label: "YDES",
    value: "15265",
    type: "common",
  },
  {
    label: "VEBRET",
    value: "15250",
    type: "common",
  },
  {
    label: "SAIGNES",
    value: "15169",
    type: "common",
  },
  {
    label: "MADIC",
    value: "15111",
    type: "common",
  },
  {
    label: "CHAMPAGNAC",
    value: "15037",
    type: "common",
  },
  {
    label: "SERANDON",
    value: "19256",
    type: "common",
  },
  {
    label: "SAINT-HILAIRE-LUC",
    value: "19210",
    type: "common",
  },
  {
    label: "NEUVIC",
    value: "19148",
    type: "common",
  },
  {
    label: "LAMAZIERE-BASSE",
    value: "19102",
    type: "common",
  },
  {
    label: "MONTAIGNAC-SAINT-HIPPOLYTE",
    value: "19143",
    type: "common",
  },
  {
    label: "CHAPELLE-SPINASSE",
    value: "19046",
    type: "common",
  },
  {
    label: "ROSIERS-D'EGLETONS",
    value: "19176",
    type: "common",
  },
  {
    label: "VITRAC-SUR-MONTANE",
    value: "19287",
    type: "common",
  },
  {
    label: "EYREIN",
    value: "19081",
    type: "common",
  },
  {
    label: "CORREZE",
    value: "19062",
    type: "common",
  },
  {
    label: "ORLIAC-DE-BAR",
    value: "19155",
    type: "common",
  },
  {
    label: "BAR",
    value: "19016",
    type: "common",
  },
  {
    label: "SEILHAC",
    value: "19255",
    type: "common",
  },
  {
    label: "SAINT-CLEMENT",
    value: "19194",
    type: "common",
  },
  {
    label: "LAGRAULIERE",
    value: "19100",
    type: "common",
  },
  {
    label: "PERPEZAC-LE-NOIR",
    value: "19162",
    type: "common",
  },
  {
    label: "VIGEOIS",
    value: "19285",
    type: "common",
  },
  {
    label: "ORGNAC-SUR-VEZERE",
    value: "19154",
    type: "common",
  },
  {
    label: "VIGNOLS",
    value: "19286",
    type: "common",
  },
  {
    label: "SAINT-SORNIN-LAVOLPS",
    value: "19243",
    type: "common",
  },
  {
    label: "LASCAUX",
    value: "19109",
    type: "common",
  },
  {
    label: "BEYSSAC",
    value: "19024",
    type: "common",
  },
  {
    label: "SAINT-CYR-LES-CHAMPAGNES",
    value: "24397",
    type: "common",
  },
  {
    label: "CONCEZE",
    value: "19059",
    type: "common",
  },
  {
    label: "SAINT-MESMIN",
    value: "24464",
    type: "common",
  },
  {
    label: "PREYSSAC-D'EXCIDEUIL",
    value: "24339",
    type: "common",
  },
  {
    label: "GENIS",
    value: "24196",
    type: "common",
  },
  {
    label: "SAINT-MEDARD-D'EXCIDEUIL",
    value: "24463",
    type: "common",
  },
  {
    label: "SAINT-MARTIAL-D'ALBAREDE",
    value: "24448",
    type: "common",
  },
  {
    label: "EXCIDEUIL",
    value: "24164",
    type: "common",
  },
  {
    label: "CLERMONT-D'EXCIDEUIL",
    value: "24124",
    type: "common",
  },
  {
    label: "SAINT-JORY-LAS-BLOUX",
    value: "24429",
    type: "common",
  },
  {
    label: "SAINT-GERMAIN-DES-PRES",
    value: "24417",
    type: "common",
  },
  {
    label: "VAUNAC",
    value: "24567",
    type: "common",
  },
  {
    label: "NEGRONDES",
    value: "24308",
    type: "common",
  },
  {
    label: "SAINT-FRONT-D'ALEMPS",
    value: "24408",
    type: "common",
  },
  {
    label: "LEMPZOURS",
    value: "24238",
    type: "common",
  },
  {
    label: "CONDAT-SUR-TRINCOU",
    value: "24129",
    type: "common",
  },
  {
    label: "BRANTOME EN PERIGORD",
    value: "24064",
    type: "common",
  },
  {
    label: "PAUSSAC-ET-SAINT-VIVIEN",
    value: "24319",
    type: "common",
  },
  {
    label: "CREYSSAC",
    value: "24144",
    type: "common",
  },
  {
    label: "SAINT-JUST",
    value: "24434",
    type: "common",
  },
  {
    label: "CHAPDEUIL",
    value: "24105",
    type: "common",
  },
  {
    label: "VERTEILLAC",
    value: "24573",
    type: "common",
  },
  {
    label: "LA TOUR-BLANCHE-CERCLES",
    value: "24554",
    type: "common",
  },
  {
    label: "COUTURES",
    value: "24141",
    type: "common",
  },
  {
    label: "BOURG-DES-MAISONS",
    value: "24057",
    type: "common",
  },
  {
    label: "SAINT-PAUL-LIZONNE",
    value: "24482",
    type: "common",
  },
  {
    label: "SAINT-MARTIAL-VIVEYROL",
    value: "24452",
    type: "common",
  },
  {
    label: "LUSIGNAC",
    value: "24247",
    type: "common",
  },
  {
    label: "BOUTEILLES-SAINT-SEBASTIEN",
    value: "24062",
    type: "common",
  },
  {
    label: "BERTRIC-BUREE",
    value: "24038",
    type: "common",
  },
  {
    label: "SAINT-SEVERIN",
    value: "16350",
    type: "common",
  },
  {
    label: "PALLUAUD",
    value: "16254",
    type: "common",
  },
  {
    label: "MONTIGNAC-LE-COQ",
    value: "16227",
    type: "common",
  },
  {
    label: "PILLAC",
    value: "16260",
    type: "common",
  },
  {
    label: "BORS (CANTON DE TUDE-ET-LAVALETTE)",
    value: "16052",
    type: "common",
  },
  {
    label: "BELLON",
    value: "16037",
    type: "common",
  },
  {
    label: "SAINT-LAURENT-DES-COMBES",
    value: "16331",
    type: "common",
  },
  {
    label: "MONTBOYER",
    value: "16222",
    type: "common",
  },
  {
    label: "COURLAC",
    value: "16112",
    type: "common",
  },
  {
    label: "BARDENAC",
    value: "16029",
    type: "common",
  },
  {
    label: "BRIE-SOUS-CHALAIS",
    value: "16063",
    type: "common",
  },
  {
    label: "PASSIRAC",
    value: "16256",
    type: "common",
  },
  {
    label: "CHATIGNAC",
    value: "16091",
    type: "common",
  },
  {
    label: "BROSSAC",
    value: "16066",
    type: "common",
  },
  {
    label: "GUIZENGEARD",
    value: "16161",
    type: "common",
  },
  {
    label: "CHEVANCEAUX",
    value: "17104",
    type: "common",
  },
  {
    label: "BORS (CANTON DE CHARENTE-SUD)",
    value: "16053",
    type: "common",
  },
  {
    label: "BOISBRETEAU",
    value: "16048",
    type: "common",
  },
  {
    label: "LE PIN",
    value: "17276",
    type: "common",
  },
  {
    label: "MESSAC",
    value: "17231",
    type: "common",
  },
  {
    label: "MERIGNAC",
    value: "17229",
    type: "common",
  },
  {
    label: "CHATENET",
    value: "17095",
    type: "common",
  },
  {
    label: "BRAN",
    value: "17061",
    type: "common",
  },
  {
    label: "CHANTILLAC",
    value: "16079",
    type: "common",
  },
  {
    label: "VIBRAC",
    value: "17468",
    type: "common",
  },
  {
    label: "SOUSMOULINS",
    value: "17433",
    type: "common",
  },
  {
    label: "POMMIERS-MOULONS",
    value: "17282",
    type: "common",
  },
  {
    label: "EXPIREMONT",
    value: "17156",
    type: "common",
  },
  {
    label: "SOUMERAS",
    value: "17432",
    type: "common",
  },
  {
    label: "ROUFFIGNAC",
    value: "17305",
    type: "common",
  },
  {
    label: "COUX",
    value: "17130",
    type: "common",
  },
  {
    label: "CHARTUZAC",
    value: "17092",
    type: "common",
  },
  {
    label: "CHAMOUILLAC",
    value: "17081",
    type: "common",
  },
  {
    label: "COURPIGNAC",
    value: "17129",
    type: "common",
  },
  {
    label: "BOISREDON",
    value: "17052",
    type: "common",
  },
  {
    label: "SAINT-PALAIS",
    value: "33456",
    type: "common",
  },
  {
    label: "PLEINE-SELVE",
    value: "33326",
    type: "common",
  },
  {
    label: "SAINT-CIERS-SUR-GIRONDE",
    value: "33389",
    type: "common",
  },
  {
    label: "SAINT-YZANS-DE-MEDOC",
    value: "33493",
    type: "common",
  },
  {
    label: "SAINT-SEURIN-DE-CADOURNE",
    value: "33476",
    type: "common",
  },
  {
    label: "ORDONNAC",
    value: "33309",
    type: "common",
  },
  {
    label: "LESPARRE-MEDOC",
    value: "33240",
    type: "common",
  },
  {
    label: "GAILLAN-EN-MEDOC",
    value: "33177",
    type: "common",
  },
  {
    label: "VILLARODIN-BOURGET",
    value: "73322",
    type: "common",
  },
  {
    label: "FRENEY",
    value: "73119",
    type: "common",
  },
  {
    label: "SAINT-ANDRE",
    value: "73223",
    type: "common",
  },
  {
    label: "ORELLE",
    value: "73194",
    type: "common",
  },
  {
    label: "SAINT-MICHEL-DE-MAURIENNE",
    value: "73261",
    type: "common",
  },
  {
    label: "SAINT-MARTIN-D'ARC",
    value: "73256",
    type: "common",
  },
  {
    label: "MONTRICHER-ALBANNE",
    value: "73173",
    type: "common",
  },
  {
    label: "FONTCOUVERTE-LA-TOUSSUIRE",
    value: "73116",
    type: "common",
  },
  {
    label: "ALBIEZ-LE-JEUNE",
    value: "73012",
    type: "common",
  },
  {
    label: "VILLAREMBERT",
    value: "73318",
    type: "common",
  },
  {
    label: "SAINT-SORLIN-D'ARVES",
    value: "73280",
    type: "common",
  },
  {
    label: "SAINTE-AGNES",
    value: "38350",
    type: "common",
  },
  {
    label: "LAVAL-EN-BELLEDONNE",
    value: "38206",
    type: "common",
  },
  {
    label: "LES ADRETS",
    value: "38002",
    type: "common",
  },
  {
    label: "VILLARD-BONNOT",
    value: "38547",
    type: "common",
  },
  {
    label: "SAINT-JEAN-LE-VIEUX",
    value: "38404",
    type: "common",
  },
  {
    label: "FROGES",
    value: "38175",
    type: "common",
  },
  {
    label: "LE VERSOUD",
    value: "38538",
    type: "common",
  },
  {
    label: "SAINT-NAZAIRE-LES-EYMES",
    value: "38431",
    type: "common",
  },
  {
    label: "SAINT-ISMIER",
    value: "38397",
    type: "common",
  },
  {
    label: "MONTBONNOT-SAINT-MARTIN",
    value: "38249",
    type: "common",
  },
  {
    label: "MEYLAN",
    value: "38229",
    type: "common",
  },
  {
    label: "BIVIERS",
    value: "38045",
    type: "common",
  },
  {
    label: "BERNIN",
    value: "38039",
    type: "common",
  },
  {
    label: "LA TRONCHE",
    value: "38516",
    type: "common",
  },
  {
    label: "LE SAPPEY-EN-CHARTREUSE",
    value: "38471",
    type: "common",
  },
  {
    label: "SAINT-MARTIN-LE-VINOUX",
    value: "38423",
    type: "common",
  },
  {
    label: "QUAIX-EN-CHARTREUSE",
    value: "38328",
    type: "common",
  },
  {
    label: "CORENC",
    value: "38126",
    type: "common",
  },
  {
    label: "SASSENAGE",
    value: "38474",
    type: "common",
  },
  {
    label: "SAINT-EGREVE",
    value: "38382",
    type: "common",
  },
  {
    label: "NOYAREY",
    value: "38281",
    type: "common",
  },
  {
    label: "FONTANIL-CORNILLON",
    value: "38170",
    type: "common",
  },
  {
    label: "VEUREY-VOROIZE",
    value: "38540",
    type: "common",
  },
  {
    label: "SAINT-QUENTIN-SUR-ISERE",
    value: "38450",
    type: "common",
  },
  {
    label: "MONTAUD",
    value: "38248",
    type: "common",
  },
  {
    label: "LA RIVIERE",
    value: "38338",
    type: "common",
  },
  {
    label: "POLIENAS",
    value: "38310",
    type: "common",
  },
  {
    label: "L'ALBENC",
    value: "38004",
    type: "common",
  },
  {
    label: "VATILIEU",
    value: "38526",
    type: "common",
  },
  {
    label: "QUINCIEU",
    value: "38330",
    type: "common",
  },
  {
    label: "NOTRE-DAME-DE-L'OSIER",
    value: "38278",
    type: "common",
  },
  {
    label: "SERRE-NERPOL",
    value: "38275",
    type: "common",
  },
  {
    label: "CRAS",
    value: "38137",
    type: "common",
  },
  {
    label: "CHANTESSE",
    value: "38074",
    type: "common",
  },
  {
    label: "VARACIEUX",
    value: "38523",
    type: "common",
  },
  {
    label: "CHASSELAY",
    value: "38086",
    type: "common",
  },
  {
    label: "ROYBON",
    value: "38347",
    type: "common",
  },
  {
    label: "SAINT-CLAIR-SUR-GALAURE",
    value: "38379",
    type: "common",
  },
  {
    label: "MONTFALCON",
    value: "38255",
    type: "common",
  },
  {
    label: "HAUTERIVES",
    value: "26148",
    type: "common",
  },
  {
    label: "LE GRAND-SERRE",
    value: "26143",
    type: "common",
  },
  {
    label: "SAINT-SORLIN-EN-VALLOIRE",
    value: "26330",
    type: "common",
  },
  {
    label: "MORAS-EN-VALLOIRE",
    value: "26213",
    type: "common",
  },
  {
    label: "CHATEAUNEUF-DE-GALAURE",
    value: "26083",
    type: "common",
  },
  {
    label: "ANNEYRON",
    value: "26010",
    type: "common",
  },
  {
    label: "SAINT-RAMBERT-D'ALBON",
    value: "26325",
    type: "common",
  },
  {
    label: "ANDANCETTE",
    value: "26009",
    type: "common",
  },
  {
    label: "ALBON",
    value: "26002",
    type: "common",
  },
  {
    label: "CHAMPAGNE",
    value: "07051",
    type: "common",
  },
  {
    label: "SAINT-ETIENNE-DE-VALOUX",
    value: "07234",
    type: "common",
  },
  {
    label: "SAINT-DESIRAT",
    value: "07228",
    type: "common",
  },
  {
    label: "SAINT-CYR",
    value: "07227",
    type: "common",
  },
  {
    label: "PEAUGRES",
    value: "07172",
    type: "common",
  },
  {
    label: "DAVEZIEUX",
    value: "07078",
    type: "common",
  },
  {
    label: "COLOMBIER-LE-CARDINAL",
    value: "07067",
    type: "common",
  },
  {
    label: "BOGY",
    value: "07036",
    type: "common",
  },
  {
    label: "SAVAS",
    value: "07310",
    type: "common",
  },
  {
    label: "SAINT-MARCEL-LES-ANNONAY",
    value: "07265",
    type: "common",
  },
  {
    label: "SAINT-CLAIR",
    value: "07225",
    type: "common",
  },
  {
    label: "BOULIEU-LES-ANNONAY",
    value: "07041",
    type: "common",
  },
  {
    label: "ANNONAY",
    value: "07010",
    type: "common",
  },
  {
    label: "BURDIGNES",
    value: "42028",
    type: "common",
  },
  {
    label: "SAINT-SAUVEUR-EN-RUE",
    value: "42287",
    type: "common",
  },
  {
    label: "SAINT-REGIS-DU-COIN",
    value: "42280",
    type: "common",
  },
  {
    label: "MARLHES",
    value: "42139",
    type: "common",
  },
  {
    label: "SAINT-VICTOR-MALESCOURS",
    value: "43227",
    type: "common",
  },
  {
    label: "SAINT-ROMAIN-LACHALM",
    value: "43223",
    type: "common",
  },
  {
    label: "SAINT-PAL-DE-MONS",
    value: "43213",
    type: "common",
  },
  {
    label: "LA SEAUVE-SUR-SEMENE",
    value: "43236",
    type: "common",
  },
  {
    label: "MONISTROL-SUR-LOIRE",
    value: "43137",
    type: "common",
  },
  {
    label: "BEAUZAC",
    value: "43025",
    type: "common",
  },
  {
    label: "TIRANGES",
    value: "43246",
    type: "common",
  },
  {
    label: "SOLIGNAC-SOUS-ROCHE",
    value: "43240",
    type: "common",
  },
  {
    label: "SAINT-ANDRE-DE-CHALENCON",
    value: "43166",
    type: "common",
  },
  {
    label: "SAINT-JULIEN-D'ANCE",
    value: "43201",
    type: "common",
  },
  {
    label: "SAINT-GEORGES-LAGRICOL",
    value: "43189",
    type: "common",
  },
  {
    label: "JULLIANGES",
    value: "43108",
    type: "common",
  },
  {
    label: "CHOMELIX",
    value: "43071",
    type: "common",
  },
  {
    label: "BEAUNE-SUR-ARZON",
    value: "43023",
    type: "common",
  },
  {
    label: "SEMBADEL",
    value: "43237",
    type: "common",
  },
  {
    label: "FELINES",
    value: "43093",
    type: "common",
  },
  {
    label: "BONNEVAL",
    value: "43035",
    type: "common",
  },
  {
    label: "SAINT-PAL-DE-SENOUIRE",
    value: "43214",
    type: "common",
  },
  {
    label: "CONNANGLES",
    value: "43076",
    type: "common",
  },
  {
    label: "LA CHAISE-DIEU",
    value: "43048",
    type: "common",
  },
  {
    label: "VALS-LE-CHASTEL",
    value: "43250",
    type: "common",
  },
  {
    label: "SAINT-DIDIER-SUR-DOULON",
    value: "43178",
    type: "common",
  },
  {
    label: "MONTCLARD",
    value: "43139",
    type: "common",
  },
  {
    label: "BERBEZIT",
    value: "43027",
    type: "common",
  },
  {
    label: "LAVAUDIEU",
    value: "43117",
    type: "common",
  },
  {
    label: "JAVAUGUES",
    value: "43105",
    type: "common",
  },
  {
    label: "FRUGIERES-LE-PIN",
    value: "43100",
    type: "common",
  },
  {
    label: "LAMOTHE",
    value: "43110",
    type: "common",
  },
  {
    label: "FONTANNES",
    value: "43096",
    type: "common",
  },
  {
    label: "BRIOUDE",
    value: "43040",
    type: "common",
  },
  {
    label: "SAINT-LAURENT-CHABREUGES",
    value: "43207",
    type: "common",
  },
  {
    label: "PAULHAC",
    value: "43147",
    type: "common",
  },
  {
    label: "BEAUMONT",
    value: "43022",
    type: "common",
  },
  {
    label: "SAINT-BEAUZIRE",
    value: "43170",
    type: "common",
  },
  {
    label: "GRENIER-MONTGON",
    value: "43103",
    type: "common",
  },
  {
    label: "ESPALEM",
    value: "43088",
    type: "common",
  },
  {
    label: "SAINT-ETIENNE-SUR-BLESLE",
    value: "43182",
    type: "common",
  },
  {
    label: "BLESLE",
    value: "43033",
    type: "common",
  },
  {
    label: "AURIAC-L'EGLISE",
    value: "15013",
    type: "common",
  },
  {
    label: "MOLEDES",
    value: "15126",
    type: "common",
  },
  {
    label: "LAURIE",
    value: "15098",
    type: "common",
  },
  {
    label: "VEZE",
    value: "15256",
    type: "common",
  },
  {
    label: "PRADIERS",
    value: "15155",
    type: "common",
  },
  {
    label: "LANDEYRAT",
    value: "15091",
    type: "common",
  },
  {
    label: "SAINT-BONNET-DE-CONDAT",
    value: "15173",
    type: "common",
  },
  {
    label: "MARCENAT",
    value: "15114",
    type: "common",
  },
  {
    label: "MARCHASTEL",
    value: "15116",
    type: "common",
  },
  {
    label: "LUGARDE",
    value: "15110",
    type: "common",
  },
  {
    label: "RIOM-ES-MONTAGNES",
    value: "15162",
    type: "common",
  },
  {
    label: "VALETTE",
    value: "15246",
    type: "common",
  },
  {
    label: "LA MONSELIE",
    value: "15128",
    type: "common",
  },
  {
    label: "MENET",
    value: "15124",
    type: "common",
  },
  {
    label: "SAUVAT",
    value: "15223",
    type: "common",
  },
  {
    label: "LE MONTEIL",
    value: "15131",
    type: "common",
  },
  {
    label: "AUZERS",
    value: "15015",
    type: "common",
  },
  {
    label: "VEYRIERES",
    value: "15254",
    type: "common",
  },
  {
    label: "MEALLET",
    value: "15123",
    type: "common",
  },
  {
    label: "BASSIGNAC",
    value: "15019",
    type: "common",
  },
  {
    label: "SOURNIAC",
    value: "15230",
    type: "common",
  },
  {
    label: "ARCHES",
    value: "15010",
    type: "common",
  },
  {
    label: "SAINT-PANTALEON-DE-LAPLEAU",
    value: "19228",
    type: "common",
  },
  {
    label: "LATRONCHE",
    value: "19110",
    type: "common",
  },
  {
    label: "SAINT-HILAIRE-FOISSAC",
    value: "19208",
    type: "common",
  },
  {
    label: "LAPLEAU",
    value: "19106",
    type: "common",
  },
  {
    label: "LAFAGE-SUR-SOMBRE",
    value: "19097",
    type: "common",
  },
  {
    label: "LE JARDIN",
    value: "19092",
    type: "common",
  },
  {
    label: "CLERGOUX",
    value: "19056",
    type: "common",
  },
  {
    label: "CHAMPAGNAC-LA-NOAILLE",
    value: "19039",
    type: "common",
  },
  {
    label: "SAINT-PRIEST-DE-GIMEL",
    value: "19236",
    type: "common",
  },
  {
    label: "SAINT-MARTIAL-DE-GIMEL",
    value: "19220",
    type: "common",
  },
  {
    label: "GIMEL-LES-CASCADES",
    value: "19085",
    type: "common",
  },
  {
    label: "LES ANGLES-SUR-CORREZE",
    value: "19009",
    type: "common",
  },
  {
    label: "TULLE",
    value: "19272",
    type: "common",
  },
  {
    label: "NAVES",
    value: "19146",
    type: "common",
  },
  {
    label: "SAINT-MEXANT",
    value: "19227",
    type: "common",
  },
  {
    label: "SAINT-GERMAIN-LES-VERGNES",
    value: "19207",
    type: "common",
  },
  {
    label: "FAVARS",
    value: "19082",
    type: "common",
  },
  {
    label: "CHANTEIX",
    value: "19042",
    type: "common",
  },
  {
    label: "SAINT-PARDOUX-L'ORTIGIER",
    value: "19234",
    type: "common",
  },
  {
    label: "SAINT-BONNET-L'ENFANTIER",
    value: "19188",
    type: "common",
  },
  {
    label: "SADROC",
    value: "19178",
    type: "common",
  },
  {
    label: "VOUTEZAC",
    value: "19288",
    type: "common",
  },
  {
    label: "ESTIVAUX",
    value: "19078",
    type: "common",
  },
  {
    label: "SAINT-SOLVE",
    value: "19242",
    type: "common",
  },
  {
    label: "SAINT-CYR-LA-ROCHE",
    value: "19196",
    type: "common",
  },
  {
    label: "SAINT-BONNET-LA-RIVIERE",
    value: "19187",
    type: "common",
  },
  {
    label: "ROSIERS-DE-JUILLAC",
    value: "19177",
    type: "common",
  },
  {
    label: "JUILLAC",
    value: "19094",
    type: "common",
  },
  {
    label: "CHABRIGNAC",
    value: "19035",
    type: "common",
  },
  {
    label: "TEILLOTS",
    value: "24545",
    type: "common",
  },
  {
    label: "SALAGNAC",
    value: "24515",
    type: "common",
  },
  {
    label: "SAINTE-TRIE",
    value: "24507",
    type: "common",
  },
  {
    label: "SEGONZAC",
    value: "19253",
    type: "common",
  },
  {
    label: "HAUTEFORT",
    value: "24210",
    type: "common",
  },
  {
    label: "CHERVEIX-CUBAS",
    value: "24120",
    type: "common",
  },
  {
    label: "BOISSEUILH",
    value: "24046",
    type: "common",
  },
  {
    label: "ANLHIAC",
    value: "24009",
    type: "common",
  },
  {
    label: "TOURTOIRAC",
    value: "24555",
    type: "common",
  },
  {
    label: "SAINT-RAPHAEL",
    value: "24493",
    type: "common",
  },
  {
    label: "SAINT-PANTALY-D'EXCIDEUIL",
    value: "24476",
    type: "common",
  },
  {
    label: "MAYAC",
    value: "24262",
    type: "common",
  },
  {
    label: "COULAURES",
    value: "24137",
    type: "common",
  },
  {
    label: "SORGES ET LIGUEUX EN PERIGORD",
    value: "24540",
    type: "common",
  },
  {
    label: "SAVIGNAC-LES-EGLISES",
    value: "24527",
    type: "common",
  },
  {
    label: "CHATEAU-L'EVEQUE",
    value: "24115",
    type: "common",
  },
  {
    label: "AGONAC",
    value: "24002",
    type: "common",
  },
  {
    label: "BIRAS",
    value: "24042",
    type: "common",
  },
  {
    label: "LISLE",
    value: "24243",
    type: "common",
  },
  {
    label: "BUSSAC",
    value: "24069",
    type: "common",
  },
  {
    label: "BOURDEILLES",
    value: "24055",
    type: "common",
  },
  {
    label: "SAINT-VICTOR",
    value: "24508",
    type: "common",
  },
  {
    label: "MONTAGRIER",
    value: "24286",
    type: "common",
  },
  {
    label: "GRAND-BRASSAC",
    value: "24200",
    type: "common",
  },
  {
    label: "VILLETOUREIX",
    value: "24586",
    type: "common",
  },
  {
    label: "CELLES",
    value: "24090",
    type: "common",
  },
  {
    label: "COMBERANCHE-ET-EPELUCHE",
    value: "24128",
    type: "common",
  },
  {
    label: "ALLEMANS",
    value: "24007",
    type: "common",
  },
  {
    label: "PETIT-BERSAC",
    value: "24323",
    type: "common",
  },
  {
    label: "CHASSAIGNES",
    value: "24114",
    type: "common",
  },
  {
    label: "BOURG-DU-BOST",
    value: "24058",
    type: "common",
  },
  {
    label: "NABINAUD",
    value: "16240",
    type: "common",
  },
  {
    label: "SAINT-ROMAIN",
    value: "16347",
    type: "common",
  },
  {
    label: "LAPRADE",
    value: "16180",
    type: "common",
  },
  {
    label: "AUBETERRE-SUR-DRONNE",
    value: "16020",
    type: "common",
  },
  {
    label: "SAINT-QUENTIN-DE-CHALAIS",
    value: "16346",
    type: "common",
  },
  {
    label: "SAINT-AVIT",
    value: "16302",
    type: "common",
  },
  {
    label: "ROUFFIAC",
    value: "16284",
    type: "common",
  },
  {
    label: "ORIVAL",
    value: "16252",
    type: "common",
  },
  {
    label: "CHALAIS",
    value: "16073",
    type: "common",
  },
  {
    label: "YVIERS",
    value: "16424",
    type: "common",
  },
  {
    label: "RIOUX-MARTIN",
    value: "16279",
    type: "common",
  },
  {
    label: "CURAC",
    value: "16117",
    type: "common",
  },
  {
    label: "BORESSE-ET-MARTRON",
    value: "17054",
    type: "common",
  },
  {
    label: "SAUVIGNAC",
    value: "16365",
    type: "common",
  },
  {
    label: "SAINT-VALLIER",
    value: "16357",
    type: "common",
  },
  {
    label: "SAINT-PALAIS-DE-NEGRIGNAC",
    value: "17378",
    type: "common",
  },
  {
    label: "NEUVICQ",
    value: "17260",
    type: "common",
  },
  {
    label: "SAINTE-COLOMBE",
    value: "17319",
    type: "common",
  },
  {
    label: "POUILLAC",
    value: "17287",
    type: "common",
  },
  {
    label: "POLIGNAC",
    value: "17281",
    type: "common",
  },
  {
    label: "MONTENDRE",
    value: "17240",
    type: "common",
  },
  {
    label: "JUSSAS",
    value: "17199",
    type: "common",
  },
  {
    label: "CORIGNAC",
    value: "17118",
    type: "common",
  },
  {
    label: "CHEPNIERS",
    value: "17099",
    type: "common",
  },
  {
    label: "REIGNAC",
    value: "33351",
    type: "common",
  },
  {
    label: "SAINT-AUBIN-DE-BLAYE",
    value: "33374",
    type: "common",
  },
  {
    label: "BRAUD-ET-SAINT-LOUIS",
    value: "33073",
    type: "common",
  },
  {
    label: "SAINT-ESTEPHE",
    value: "33395",
    type: "common",
  },
  {
    label: "VERTHEUIL",
    value: "33545",
    type: "common",
  },
  {
    label: "SAINT-GERMAIN-D'ESTEUIL",
    value: "33412",
    type: "common",
  },
  {
    label: "NAUJAC-SUR-MER",
    value: "33300",
    type: "common",
  },
  {
    label: "AVRIEUX",
    value: "73026",
    type: "common",
  },
  {
    label: "MODANE",
    value: "73157",
    type: "common",
  },
  {
    label: "FOURNEAUX",
    value: "73117",
    type: "common",
  },
  {
    label: "VALMEINIER",
    value: "73307",
    type: "common",
  },
  {
    label: "ALBIEZ-MONTROND",
    value: "73013",
    type: "common",
  },
  {
    label: "SAINT-JEAN-D'ARVES",
    value: "73242",
    type: "common",
  },
  {
    label: "VAUJANY",
    value: "38527",
    type: "common",
  },
  {
    label: "ALLEMOND",
    value: "38005",
    type: "common",
  },
  {
    label: "SAINT-MURY-MONTEYMOND",
    value: "38430",
    type: "common",
  },
  {
    label: "REVEL",
    value: "38334",
    type: "common",
  },
  {
    label: "LA COMBE-DE-LANCEY",
    value: "38120",
    type: "common",
  },
  {
    label: "VENON",
    value: "38533",
    type: "common",
  },
  {
    label: "MURIANETTE",
    value: "38271",
    type: "common",
  },
  {
    label: "GIERES",
    value: "38179",
    type: "common",
  },
  {
    label: "DOMENE",
    value: "38150",
    type: "common",
  },
  {
    label: "SAINT-MARTIN-D'HERES",
    value: "38421",
    type: "common",
  },
  {
    label: "GRENOBLE",
    value: "38185",
    type: "common",
  },
  {
    label: "SEYSSINET-PARISET",
    value: "38485",
    type: "common",
  },
  {
    label: "SAINT-NIZIER-DU-MOUCHEROTTE",
    value: "38433",
    type: "common",
  },
  {
    label: "FONTAINE",
    value: "38169",
    type: "common",
  },
  {
    label: "ENGINS",
    value: "38153",
    type: "common",
  },
  {
    label: "SAINT-GERVAIS",
    value: "38390",
    type: "common",
  },
  {
    label: "ROVON",
    value: "38345",
    type: "common",
  },
  {
    label: "VINAY",
    value: "38559",
    type: "common",
  },
  {
    label: "TECHE",
    value: "38500",
    type: "common",
  },
  {
    label: "COGNIN-LES-GORGES",
    value: "38117",
    type: "common",
  },
  {
    label: "BEAULIEU",
    value: "38033",
    type: "common",
  },
  {
    label: "SAINT-VERAND",
    value: "38463",
    type: "common",
  },
  {
    label: "MURINAIS",
    value: "38272",
    type: "common",
  },
  {
    label: "CHEVRIERES",
    value: "38099",
    type: "common",
  },
  {
    label: "SAINT ANTOINE L'ABBAYE",
    value: "38359",
    type: "common",
  },
  {
    label: "SAINT-APPOLINARD",
    value: "38360",
    type: "common",
  },
  {
    label: "BESSINS",
    value: "38041",
    type: "common",
  },
  {
    label: "SAINT-LAURENT-D'ONAY",
    value: "26310",
    type: "common",
  },
  {
    label: "SAINT-CHRISTOPHE-ET-LE-LARIS",
    value: "26298",
    type: "common",
  },
  {
    label: "MONTCHENU",
    value: "26194",
    type: "common",
  },
  {
    label: "CREPOL",
    value: "26107",
    type: "common",
  },
  {
    label: "TERSANNE",
    value: "26349",
    type: "common",
  },
  {
    label: "SAINT-MARTIN-D'AOUT",
    value: "26314",
    type: "common",
  },
  {
    label: "SAINT-AVIT",
    value: "26293",
    type: "common",
  },
  {
    label: "RATIERES",
    value: "26259",
    type: "common",
  },
  {
    label: "BATHERNAY",
    value: "26028",
    type: "common",
  },
  {
    label: "SAINT-UZE",
    value: "26332",
    type: "common",
  },
  {
    label: "MUREILS",
    value: "26219",
    type: "common",
  },
  {
    label: "LA MOTTE-DE-GALAURE",
    value: "26216",
    type: "common",
  },
  {
    label: "FAY-LE-CLOS",
    value: "26133",
    type: "common",
  },
  {
    label: "CLAVEYSON",
    value: "26094",
    type: "common",
  },
  {
    label: "SAINT-VALLIER",
    value: "26333",
    type: "common",
  },
  {
    label: "LAVEYRON",
    value: "26160",
    type: "common",
  },
  {
    label: "BEAUSEMBLANT",
    value: "26041",
    type: "common",
  },
  {
    label: "SARRAS",
    value: "07308",
    type: "common",
  },
  {
    label: "ANDANCE",
    value: "07009",
    type: "common",
  },
  {
    label: "VERNOSC-LES-ANNONAY",
    value: "07337",
    type: "common",
  },
  {
    label: "THORRENC",
    value: "07321",
    type: "common",
  },
  {
    label: "TALENCIEUX",
    value: "07317",
    type: "common",
  },
  {
    label: "ARDOIX",
    value: "07013",
    type: "common",
  },
  {
    label: "SAINT-ALBAN-D'AY",
    value: "07205",
    type: "common",
  },
  {
    label: "ROIFFIEUX",
    value: "07197",
    type: "common",
  },
  {
    label: "QUINTENAS",
    value: "07188",
    type: "common",
  },
  {
    label: "VOCANCE",
    value: "07347",
    type: "common",
  },
  {
    label: "VILLEVOCANCE",
    value: "07342",
    type: "common",
  },
  {
    label: "VANOSC",
    value: "07333",
    type: "common",
  },
  {
    label: "MONESTIER",
    value: "07160",
    type: "common",
  },
  {
    label: "SAINT-JULIEN-MOLHESABATE",
    value: "43204",
    type: "common",
  },
  {
    label: "RIOTORD",
    value: "43163",
    type: "common",
  },
  {
    label: "RAUCOULES",
    value: "43159",
    type: "common",
  },
  {
    label: "DUNIERES",
    value: "43087",
    type: "common",
  },
  {
    label: "SAINTE-SIGOLENE",
    value: "43224",
    type: "common",
  },
  {
    label: "LES VILLETTES",
    value: "43265",
    type: "common",
  },
  {
    label: "SAINT-MAURICE-DE-LIGNON",
    value: "43211",
    type: "common",
  },
  {
    label: "GRAZAC",
    value: "43102",
    type: "common",
  },
  {
    label: "BEAUX",
    value: "43024",
    type: "common",
  },
  {
    label: "RETOURNAC",
    value: "43162",
    type: "common",
  },
  {
    label: "VOREY",
    value: "43267",
    type: "common",
  },
  {
    label: "SAINT-PIERRE-DU-CHAMP",
    value: "43217",
    type: "common",
  },
  {
    label: "ROCHE-EN-REGNIER",
    value: "43164",
    type: "common",
  },
  {
    label: "BELLEVUE-LA-MONTAGNE",
    value: "43026",
    type: "common",
  },
  {
    label: "MONLET",
    value: "43138",
    type: "common",
  },
  {
    label: "LA CHAPELLE-BERTIN",
    value: "43057",
    type: "common",
  },
  {
    label: "SAINT-PREJET-ARMANDON",
    value: "43219",
    type: "common",
  },
  {
    label: "SAINTE-MARGUERITE",
    value: "43208",
    type: "common",
  },
  {
    label: "JOSAT",
    value: "43107",
    type: "common",
  },
  {
    label: "COLLAT",
    value: "43075",
    type: "common",
  },
  {
    label: "CHASSAGNES",
    value: "43063",
    type: "common",
  },
  {
    label: "SALZUIT",
    value: "43232",
    type: "common",
  },
  {
    label: "PAULHAGUET",
    value: "43148",
    type: "common",
  },
  {
    label: "DOMEYRAT",
    value: "43086",
    type: "common",
  },
  {
    label: "LA CHOMETTE",
    value: "43072",
    type: "common",
  },
  {
    label: "VIEILLE-BRIOUDE",
    value: "43262",
    type: "common",
  },
  {
    label: "SAINT-JUST-PRES-BRIOUDE",
    value: "43206",
    type: "common",
  },
  {
    label: "LUBILHAC",
    value: "43125",
    type: "common",
  },
  {
    label: "MASSIAC",
    value: "15119",
    type: "common",
  },
  {
    label: "MOLOMPIZE",
    value: "15127",
    type: "common",
  },
  {
    label: "BONNAC",
    value: "15022",
    type: "common",
  },
  {
    label: "CHARMENSAC",
    value: "15043",
    type: "common",
  },
  {
    label: "ALLANCHE",
    value: "15001",
    type: "common",
  },
  {
    label: "VERNOLS",
    value: "15253",
    type: "common",
  },
  {
    label: "SEGUR-LES-VILLAS",
    value: "15225",
    type: "common",
  },
  {
    label: "SAINT-SATURNIN",
    value: "15213",
    type: "common",
  },
  {
    label: "SAINT-HIPPOLYTE",
    value: "15190",
    type: "common",
  },
  {
    label: "APCHON",
    value: "15009",
    type: "common",
  },
  {
    label: "COLLANDRES",
    value: "15052",
    type: "common",
  },
  {
    label: "SAINT-VINCENT-DE-SALERS",
    value: "15218",
    type: "common",
  },
  {
    label: "TRIZAC",
    value: "15243",
    type: "common",
  },
  {
    label: "MOUSSAGES",
    value: "15137",
    type: "common",
  },
  {
    label: "LE VIGEAN",
    value: "15261",
    type: "common",
  },
  {
    label: "JALEYRAC",
    value: "15079",
    type: "common",
  },
  {
    label: "MAURIAC",
    value: "15120",
    type: "common",
  },
  {
    label: "CHALVIGNAC",
    value: "15036",
    type: "common",
  },
  {
    label: "SOURSAC",
    value: "19264",
    type: "common",
  },
  {
    label: "LAVAL-SUR-LUZEGE",
    value: "19111",
    type: "common",
  },
  {
    label: "SAINT-MERD-DE-LAPLEAU",
    value: "19225",
    type: "common",
  },
  {
    label: "MARCILLAC-LA-CROISILLE",
    value: "19125",
    type: "common",
  },
  {
    label: "SAINT-PARDOUX-LA-CROISILLE",
    value: "19231",
    type: "common",
  },
  {
    label: "GUMOND",
    value: "19090",
    type: "common",
  },
  {
    label: "GROS-CHASTANG",
    value: "19089",
    type: "common",
  },
  {
    label: "SAINT-PAUL",
    value: "19235",
    type: "common",
  },
  {
    label: "ESPAGNAC",
    value: "19075",
    type: "common",
  },
  {
    label: "PANDRIGNES",
    value: "19158",
    type: "common",
  },
  {
    label: "LADIGNAC-SUR-RONDELLES",
    value: "19096",
    type: "common",
  },
  {
    label: "CHANAC-LES-MINES",
    value: "19041",
    type: "common",
  },
  {
    label: "SAINTE-FORTUNADE",
    value: "19203",
    type: "common",
  },
  {
    label: "CORNIL",
    value: "19061",
    type: "common",
  },
  {
    label: "CHAMEYRAT",
    value: "19038",
    type: "common",
  },
  {
    label: "SAINT-HILAIRE-PEYROUX",
    value: "19211",
    type: "common",
  },
  {
    label: "SAINTE-FEREOLE",
    value: "19202",
    type: "common",
  },
  {
    label: "DONZENAC",
    value: "19072",
    type: "common",
  },
  {
    label: "SAINT-VIANCE",
    value: "19246",
    type: "common",
  },
  {
    label: "ALLASSAC",
    value: "19005",
    type: "common",
  },
  {
    label: "YSSANDON",
    value: "19289",
    type: "common",
  },
  {
    label: "VARS-SUR-ROSEIX",
    value: "19279",
    type: "common",
  },
  {
    label: "SAINT-AULAIRE",
    value: "19182",
    type: "common",
  },
  {
    label: "OBJAT",
    value: "19153",
    type: "common",
  },
  {
    label: "SAINT-ROBERT",
    value: "19239",
    type: "common",
  },
  {
    label: "SAINT-CYPRIEN",
    value: "19195",
    type: "common",
  },
  {
    label: "PERPEZAC-LE-BLANC",
    value: "19161",
    type: "common",
  },
  {
    label: "AYEN",
    value: "19015",
    type: "common",
  },
  {
    label: "COUBJOURS",
    value: "24136",
    type: "common",
  },
  {
    label: "BADEFOLS-D'ANS",
    value: "24021",
    type: "common",
  },
  {
    label: "LOUIGNAC",
    value: "19120",
    type: "common",
  },
  {
    label: "NAILHAC",
    value: "24302",
    type: "common",
  },
  {
    label: "GRANGES-D'ANS",
    value: "24202",
    type: "common",
  },
  {
    label: "TEMPLE-LAGUYON",
    value: "24546",
    type: "common",
  },
  {
    label: "SAINTE-ORSE",
    value: "24473",
    type: "common",
  },
  {
    label: "SAINTE-EULALIE-D'ANS",
    value: "24401",
    type: "common",
  },
  {
    label: "GABILLOU",
    value: "24192",
    type: "common",
  },
  {
    label: "CHOURGNAC",
    value: "24121",
    type: "common",
  },
  {
    label: "BROUCHAUD",
    value: "24066",
    type: "common",
  },
  {
    label: "SARLIAC-SUR-L'ISLE",
    value: "24521",
    type: "common",
  },
  {
    label: "SAINT-VINCENT-SUR-L'ISLE",
    value: "24513",
    type: "common",
  },
  {
    label: "CUBJAC-AUVEZERE-VAL D'ANS",
    value: "24147",
    type: "common",
  },
  {
    label: "TRELISSAC",
    value: "24557",
    type: "common",
  },
  {
    label: "ESCOIRE",
    value: "24162",
    type: "common",
  },
  {
    label: "CORNILLE",
    value: "24135",
    type: "common",
  },
  {
    label: "ANTONNE-ET-TRIGONANT",
    value: "24011",
    type: "common",
  },
  {
    label: "PERIGUEUX",
    value: "24322",
    type: "common",
  },
  {
    label: "CHAMPCEVINEL",
    value: "24098",
    type: "common",
  },
  {
    label: "LA CHAPELLE-GONAGUET",
    value: "24108",
    type: "common",
  },
  {
    label: "CHANCELADE",
    value: "24102",
    type: "common",
  },
  {
    label: "MENSIGNAC",
    value: "24266",
    type: "common",
  },
  {
    label: "TOCANE-SAINT-APRE",
    value: "24553",
    type: "common",
  },
  {
    label: "DOUCHAPT",
    value: "24154",
    type: "common",
  },
  {
    label: "SEGONZAC",
    value: "24529",
    type: "common",
  },
  {
    label: "SAINT-SULPICE-DE-ROUMAGNAC",
    value: "24504",
    type: "common",
  },
  {
    label: "SAINT-PARDOUX-DE-DRONE",
    value: "24477",
    type: "common",
  },
  {
    label: "SAINT-MEARD-DE-DRONE",
    value: "24460",
    type: "common",
  },
  {
    label: "SAINT-MARTIN-DE-RIBERAC",
    value: "24455",
    type: "common",
  },
  {
    label: "VANXAINS",
    value: "24564",
    type: "common",
  },
  {
    label: "RIBERAC",
    value: "24352",
    type: "common",
  },
  {
    label: "SAINT-VINCENT-JALMOUTIERS",
    value: "24511",
    type: "common",
  },
  {
    label: "SAINT PRIVAT EN PERIGORD",
    value: "24490",
    type: "common",
  },
  {
    label: "SAINT AULAYE-PUYMANGOU",
    value: "24376",
    type: "common",
  },
  {
    label: "LES ESSARDS",
    value: "16130",
    type: "common",
  },
  {
    label: "BONNES",
    value: "16049",
    type: "common",
  },
  {
    label: "PARCOUL-CHENAUD",
    value: "24316",
    type: "common",
  },
  {
    label: "BAZAC",
    value: "16034",
    type: "common",
  },
  {
    label: "LA GENETOUZE",
    value: "17173",
    type: "common",
  },
  {
    label: "MEDILLAC",
    value: "16215",
    type: "common",
  },
  {
    label: "LE FOUILLOUX",
    value: "17167",
    type: "common",
  },
  {
    label: "BOSCAMNANT",
    value: "17055",
    type: "common",
  },
  {
    label: "SAINT-MARTIN-D'ARY",
    value: "17365",
    type: "common",
  },
  {
    label: "MONTGUYON",
    value: "17241",
    type: "common",
  },
  {
    label: "ORIGNOLLES",
    value: "17269",
    type: "common",
  },
  {
    label: "MONTLIEU-LA-GARDE",
    value: "17243",
    type: "common",
  },
  {
    label: "BUSSAC-FORET",
    value: "17074",
    type: "common",
  },
  {
    label: "BEDENAC",
    value: "17038",
    type: "common",
  },
  {
    label: "DONNEZAC",
    value: "33151",
    type: "common",
  },
  {
    label: "SAUGON",
    value: "33502",
    type: "common",
  },
  {
    label: "CAMPUGNAN",
    value: "33089",
    type: "common",
  },
  {
    label: "FOURS",
    value: "33172",
    type: "common",
  },
  {
    label: "EYRANS",
    value: "33161",
    type: "common",
  },
  {
    label: "ETAULIERS",
    value: "33159",
    type: "common",
  },
  {
    label: "CARTELEGUE",
    value: "33101",
    type: "common",
  },
  {
    label: "ANGLADE",
    value: "33006",
    type: "common",
  },
  {
    label: "SAINT-GENES-DE-BLAYE",
    value: "33405",
    type: "common",
  },
  {
    label: "SAINT-ANDRONY",
    value: "33370",
    type: "common",
  },
  {
    label: "PAUILLAC",
    value: "33314",
    type: "common",
  },
  {
    label: "SAINT-SAUVEUR",
    value: "33471",
    type: "common",
  },
  {
    label: "CISSAC-MEDOC",
    value: "33125",
    type: "common",
  },
  {
    label: "HOURTIN",
    value: "33203",
    type: "common",
  },
  {
    label: "VALLOIRE",
    value: "73306",
    type: "common",
  },
  {
    label: "BESSE",
    value: "38040",
    type: "common",
  },
  {
    label: "CLAVANS-EN-HAUT-OISANS",
    value: "38112",
    type: "common",
  },
  {
    label: "VILLARD-RECULAS",
    value: "38550",
    type: "common",
  },
  {
    label: "OZ",
    value: "38289",
    type: "common",
  },
  {
    label: "HUEZ",
    value: "38191",
    type: "common",
  },
  {
    label: "CHAMROUSSE",
    value: "38567",
    type: "common",
  },
  {
    label: "SAINT-MARTIN-D'URIAGE",
    value: "38422",
    type: "common",
  },
  {
    label: "LIVET-ET-GAVET",
    value: "38212",
    type: "common",
  },
  {
    label: "VAULNAVEYS-LE-HAUT",
    value: "38529",
    type: "common",
  },
  {
    label: "VAULNAVEYS-LE-BAS",
    value: "38528",
    type: "common",
  },
  {
    label: "HERBEYS",
    value: "38188",
    type: "common",
  },
  {
    label: "BRIE-ET-ANGONNES",
    value: "38059",
    type: "common",
  },
  {
    label: "LE PONT-DE-CLAIX",
    value: "38317",
    type: "common",
  },
  {
    label: "POISAT",
    value: "38309",
    type: "common",
  },
  {
    label: "JARRIE",
    value: "38200",
    type: "common",
  },
  {
    label: "EYBENS",
    value: "38158",
    type: "common",
  },
  {
    label: "ECHIROLLES",
    value: "38151",
    type: "common",
  },
  {
    label: "CHAMPAGNIER",
    value: "38068",
    type: "common",
  },
  {
    label: "BRESSON",
    value: "38057",
    type: "common",
  },
  {
    label: "SEYSSINS",
    value: "38486",
    type: "common",
  },
  {
    label: "CLAIX",
    value: "38111",
    type: "common",
  },
  {
    label: "AUTRANS-MEAUDRE EN VERCORS",
    value: "38225",
    type: "common",
  },
  {
    label: "LANS-EN-VERCORS",
    value: "38205",
    type: "common",
  },
  {
    label: "RENCUREL",
    value: "38333",
    type: "common",
  },
  {
    label: "MALLEVAL-EN-VERCORS",
    value: "38216",
    type: "common",
  },
  {
    label: "SAINT-PIERRE-DE-CHERENNES",
    value: "38443",
    type: "common",
  },
  {
    label: "IZERON",
    value: "38195",
    type: "common",
  },
  {
    label: "LA SONE",
    value: "38495",
    type: "common",
  },
  {
    label: "SAINT-SAUVEUR",
    value: "38454",
    type: "common",
  },
  {
    label: "SAINT-MARCELLIN",
    value: "38416",
    type: "common",
  },
  {
    label: "BEAUVOIR-EN-ROYANS",
    value: "38036",
    type: "common",
  },
  {
    label: "SAINT-BONNET-DE-CHAVAGNE",
    value: "38370",
    type: "common",
  },
  {
    label: "MONTAGNE",
    value: "38245",
    type: "common",
  },
  {
    label: "CHATTE",
    value: "38095",
    type: "common",
  },
  {
    label: "SAINT-MICHEL-SUR-SAVASSE",
    value: "26319",
    type: "common",
  },
  {
    label: "PARNANS",
    value: "26225",
    type: "common",
  },
  {
    label: "MONTMIRAL",
    value: "26207",
    type: "common",
  },
  {
    label: "MARGES",
    value: "26174",
    type: "common",
  },
  {
    label: "GEYSSANS",
    value: "26140",
    type: "common",
  },
  {
    label: "LE CHALON",
    value: "26068",
    type: "common",
  },
  {
    label: "ARTHEMONAY",
    value: "26014",
    type: "common",
  },
  {
    label: "SAINT-DONAT-SUR-L'HERBASSE",
    value: "26301",
    type: "common",
  },
  {
    label: "CHARMES-SUR-L'HERBASSE",
    value: "26077",
    type: "common",
  },
  {
    label: "SAINT-BARTHELEMY-DE-VALS",
    value: "26295",
    type: "common",
  },
  {
    label: "MARSAZ",
    value: "26177",
    type: "common",
  },
  {
    label: "CHANTEMERLE-LES-BLES",
    value: "26072",
    type: "common",
  },
  {
    label: "BREN",
    value: "26061",
    type: "common",
  },
  {
    label: "SERVES-SUR-RHONE",
    value: "26341",
    type: "common",
  },
  {
    label: "PONSAS",
    value: "26247",
    type: "common",
  },
  {
    label: "EROME",
    value: "26119",
    type: "common",
  },
  {
    label: "OZON",
    value: "07169",
    type: "common",
  },
  {
    label: "ARRAS-SUR-RHONE",
    value: "07015",
    type: "common",
  },
  {
    label: "SECHERAS",
    value: "07312",
    type: "common",
  },
  {
    label: "SAINT-JEURE-D'AY",
    value: "07250",
    type: "common",
  },
  {
    label: "ECLASSAN",
    value: "07084",
    type: "common",
  },
  {
    label: "CHEMINAS",
    value: "07063",
    type: "common",
  },
  {
    label: "SAINT-ROMAIN-D'AY",
    value: "07292",
    type: "common",
  },
  {
    label: "PREAUX",
    value: "07185",
    type: "common",
  },
  {
    label: "SATILLIEU",
    value: "07309",
    type: "common",
  },
  {
    label: "SAINT-SYMPHORIEN-DE-MAHUN",
    value: "07299",
    type: "common",
  },
  {
    label: "SAINT-PIERRE-SUR-DOUX",
    value: "07285",
    type: "common",
  },
  {
    label: "SAINT-JULIEN-VOCANCE",
    value: "07258",
    type: "common",
  },
  {
    label: "SAINT-BONNET-LE-FROID",
    value: "43172",
    type: "common",
  },
  {
    label: "MONTREGARD",
    value: "43142",
    type: "common",
  },
  {
    label: "MONTFAUCON-EN-VELAY",
    value: "43141",
    type: "common",
  },
  {
    label: "LAPTE",
    value: "43114",
    type: "common",
  },
  {
    label: "CHENEREILLES",
    value: "43069",
    type: "common",
  },
  {
    label: "YSSINGEAUX",
    value: "43268",
    type: "common",
  },
  {
    label: "SAINT-JULIEN-DU-PINET",
    value: "43203",
    type: "common",
  },
  {
    label: "ROSIERES",
    value: "43165",
    type: "common",
  },
  {
    label: "MEZERES",
    value: "43134",
    type: "common",
  },
  {
    label: "CHAMALIERES-SUR-LOIRE",
    value: "43049",
    type: "common",
  },
  {
    label: "BEAULIEU",
    value: "43021",
    type: "common",
  },
  {
    label: "SAINT-VINCENT",
    value: "43230",
    type: "common",
  },
  {
    label: "SAINT-PAULIEN",
    value: "43216",
    type: "common",
  },
  {
    label: "SAINT-GENEYS-PRES-SAINT-PAULIEN",
    value: "43187",
    type: "common",
  },
  {
    label: "VERNASSAL",
    value: "43259",
    type: "common",
  },
  {
    label: "CEAUX-D'ALLEGRE",
    value: "43043",
    type: "common",
  },
  {
    label: "VARENNES-SAINT-HONORAT",
    value: "43252",
    type: "common",
  },
  {
    label: "SAINTE-EUGENIE-DE-VILLENEUVE",
    value: "43183",
    type: "common",
  },
  {
    label: "JAX",
    value: "43106",
    type: "common",
  },
  {
    label: "FIX-SAINT-GENEYS",
    value: "43095",
    type: "common",
  },
  {
    label: "ALLEGRE",
    value: "43003",
    type: "common",
  },
  {
    label: "MAZERAT-AUROUZE",
    value: "43131",
    type: "common",
  },
  {
    label: "SAINT-GEORGES-D'AURAC",
    value: "43188",
    type: "common",
  },
  {
    label: "CHAVANIAC-LAFAYETTE",
    value: "43067",
    type: "common",
  },
  {
    label: "COUTEUGES",
    value: "43079",
    type: "common",
  },
  {
    label: "CERZAT",
    value: "43044",
    type: "common",
  },
  {
    label: "VILLENEUVE-D'ALLIER",
    value: "43264",
    type: "common",
  },
  {
    label: "SAINT-PRIVAT-DU-DRAGON",
    value: "43222",
    type: "common",
  },
  {
    label: "SAINT-ILPIZE",
    value: "43195",
    type: "common",
  },
  {
    label: "LAVOUTE-CHILHAC",
    value: "43118",
    type: "common",
  },
  {
    label: "CHILHAC",
    value: "43070",
    type: "common",
  },
  {
    label: "BLASSAC",
    value: "43031",
    type: "common",
  },
  {
    label: "MERCŒUR",
    value: "43133",
    type: "common",
  },
  {
    label: "ALLY",
    value: "43006",
    type: "common",
  },
  {
    label: "LA CHAPELLE-LAURENT",
    value: "15042",
    type: "common",
  },
  {
    label: "SAINT-PONCY",
    value: "15207",
    type: "common",
  },
  {
    label: "SAINT-MARY-LE-PLAIN",
    value: "15203",
    type: "common",
  },
  {
    label: "VALJOUZE",
    value: "15247",
    type: "common",
  },
  {
    label: "PEYRUSSE",
    value: "15151",
    type: "common",
  },
  {
    label: "FERRIERES-SAINT-MARY",
    value: "15069",
    type: "common",
  },
  {
    label: "JOURSAC",
    value: "15080",
    type: "common",
  },
  {
    label: "DIENNE",
    value: "15061",
    type: "common",
  },
  {
    label: "LE CLAUX",
    value: "15050",
    type: "common",
  },
  {
    label: "CHEYLADE",
    value: "15049",
    type: "common",
  },
  {
    label: "LE VAULMIER",
    value: "15249",
    type: "common",
  },
  {
    label: "SAINT-BONNET-DE-SALERS",
    value: "15174",
    type: "common",
  },
  {
    label: "ANGLARDS-DE-SALERS",
    value: "15006",
    type: "common",
  },
  {
    label: "SALINS",
    value: "15220",
    type: "common",
  },
  {
    label: "DRUGEAC",
    value: "15063",
    type: "common",
  },
  {
    label: "ESCORAILLES",
    value: "15064",
    type: "common",
  },
  {
    label: "ALLY",
    value: "15003",
    type: "common",
  },
  {
    label: "RILHAC-XAINTRIE",
    value: "19173",
    type: "common",
  },
  {
    label: "PLEAUX",
    value: "15153",
    type: "common",
  },
  {
    label: "CHAUSSENAC",
    value: "15046",
    type: "common",
  },
  {
    label: "BRAGEAC",
    value: "15024",
    type: "common",
  },
  {
    label: "BARRIAC-LES-BOSQUETS",
    value: "15018",
    type: "common",
  },
  {
    label: "AURIAC",
    value: "19014",
    type: "common",
  },
  {
    label: "DARAZAC",
    value: "19069",
    type: "common",
  },
  {
    label: "BASSIGNAC-LE-HAUT",
    value: "19018",
    type: "common",
  },
  {
    label: "SAINT-MARTIN-LA-MEANNE",
    value: "19222",
    type: "common",
  },
  {
    label: "LA ROCHE-CANILLAC",
    value: "19174",
    type: "common",
  },
  {
    label: "SAINT-SYLVAIN",
    value: "19245",
    type: "common",
  },
  {
    label: "SAINT-BONNET-ELVERT",
    value: "19186",
    type: "common",
  },
  {
    label: "FORGES",
    value: "19084",
    type: "common",
  },
  {
    label: "CHAMPAGNAC-LA-PRUNE",
    value: "19040",
    type: "common",
  },
  {
    label: "PALAZINGES",
    value: "19156",
    type: "common",
  },
  {
    label: "LE CHASTANG",
    value: "19048",
    type: "common",
  },
  {
    label: "DAMPNIAT",
    value: "19068",
    type: "common",
  },
  {
    label: "AUBAZINES",
    value: "19013",
    type: "common",
  },
  {
    label: "ALBIGNAC",
    value: "19003",
    type: "common",
  },
  {
    label: "MALEMORT",
    value: "19123",
    type: "common",
  },
  {
    label: "USSAC",
    value: "19274",
    type: "common",
  },
  {
    label: "BRIVE-LA-GAILLARDE",
    value: "19031",
    type: "common",
  },
  {
    label: "VARETZ",
    value: "19278",
    type: "common",
  },
  {
    label: "SAINT-PANTALEON-DE-LARCHE",
    value: "19229",
    type: "common",
  },
  {
    label: "MANSAC",
    value: "19124",
    type: "common",
  },
  {
    label: "CUBLAC",
    value: "19066",
    type: "common",
  },
  {
    label: "BRIGNAC-LA-PLAINE",
    value: "19030",
    type: "common",
  },
  {
    label: "PEYRIGNAC",
    value: "24324",
    type: "common",
  },
  {
    label: "BEAUREGARD-DE-TERRASSON",
    value: "24030",
    type: "common",
  },
  {
    label: "VILLAC",
    value: "24580",
    type: "common",
  },
  {
    label: "CHATRES",
    value: "24116",
    type: "common",
  },
  {
    label: "SAINT-RABIER",
    value: "24491",
    type: "common",
  },
  {
    label: "LA CHAPELLE-SAINT-JEAN",
    value: "24113",
    type: "common",
  },
  {
    label: "AZERAT",
    value: "24019",
    type: "common",
  },
  {
    label: "AJAT",
    value: "24004",
    type: "common",
  },
  {
    label: "MONTAGNAC-D'AUBEROCHE",
    value: "24284",
    type: "common",
  },
  {
    label: "LIMEYRAT",
    value: "24241",
    type: "common",
  },
  {
    label: "BASSILLAC ET AUBEROCHE",
    value: "24026",
    type: "common",
  },
  {
    label: "BOULAZAC ISLE MANOIRE",
    value: "24053",
    type: "common",
  },
  {
    label: "RAZAC-SUR-L'ISLE",
    value: "24350",
    type: "common",
  },
  {
    label: "MARSAC-SUR-L'ISLE",
    value: "24256",
    type: "common",
  },
  {
    label: "COURSAC",
    value: "24139",
    type: "common",
  },
  {
    label: "COULOUNIEIX-CHAMIERS",
    value: "24138",
    type: "common",
  },
  {
    label: "LEGUILLAC-DE-L'AUCHE",
    value: "24236",
    type: "common",
  },
  {
    label: "ANNESSE-ET-BEAULIEU",
    value: "24010",
    type: "common",
  },
  {
    label: "SAINT-ASTIER",
    value: "24372",
    type: "common",
  },
  {
    label: "SAINT-AQUILIN",
    value: "24371",
    type: "common",
  },
  {
    label: "SAINT-VINCENT-DE-CONNEZAC",
    value: "24509",
    type: "common",
  },
  {
    label: "SAINT-JEAN-D'ATAUX",
    value: "24424",
    type: "common",
  },
  {
    label: "CHANTERAC",
    value: "24104",
    type: "common",
  },
  {
    label: "SIORAC-DE-RIBERAC",
    value: "24537",
    type: "common",
  },
  {
    label: "SAINT-ANDRE-DE-DOUBLE",
    value: "24367",
    type: "common",
  },
  {
    label: "LA JEMAYE-PONTEYRAUD",
    value: "24216",
    type: "common",
  },
  {
    label: "SERVANCHES",
    value: "24533",
    type: "common",
  },
  {
    label: "LA ROCHE-CHALAIS",
    value: "24354",
    type: "common",
  },
  {
    label: "SAINT-AIGULIN",
    value: "17309",
    type: "common",
  },
  {
    label: "SAINT-MARTIN-DE-COUX",
    value: "17366",
    type: "common",
  },
  {
    label: "LA BARDE",
    value: "17033",
    type: "common",
  },
  {
    label: "SAINT-PIERRE-DU-PALAIS",
    value: "17386",
    type: "common",
  },
  {
    label: "LA CLOTTE",
    value: "17113",
    type: "common",
  },
  {
    label: "CERCOUX",
    value: "17077",
    type: "common",
  },
  {
    label: "CLERAC",
    value: "17110",
    type: "common",
  },
  {
    label: "LARUSCADE",
    value: "33233",
    type: "common",
  },
  {
    label: "SAINT-YZAN-DE-SOUDIAC",
    value: "33492",
    type: "common",
  },
  {
    label: "SAINT-SAVIN",
    value: "33473",
    type: "common",
  },
  {
    label: "SAINT-MARIENS",
    value: "33439",
    type: "common",
  },
  {
    label: "CIVRAC-DE-BLAYE",
    value: "33126",
    type: "common",
  },
  {
    label: "SAINT-GIRONS-D'AIGUEVIVES",
    value: "33416",
    type: "common",
  },
  {
    label: "SAINT-CHRISTOLY-DE-BLAYE",
    value: "33382",
    type: "common",
  },
  {
    label: "GENERAC",
    value: "33184",
    type: "common",
  },
  {
    label: "SAINT-SEURIN-DE-CURSAC",
    value: "33477",
    type: "common",
  },
  {
    label: "SAINT-PAUL",
    value: "33458",
    type: "common",
  },
  {
    label: "MAZION",
    value: "33280",
    type: "common",
  },
  {
    label: "CARS",
    value: "33100",
    type: "common",
  },
  {
    label: "BERSON",
    value: "33047",
    type: "common",
  },
  {
    label: "SAINT-MARTIN-LACAUSSADE",
    value: "33441",
    type: "common",
  },
  {
    label: "BLAYE",
    value: "33058",
    type: "common",
  },
  {
    label: "SAINT-JULIEN-BEYCHEVELLE",
    value: "33423",
    type: "common",
  },
  {
    label: "CUSSAC-FORT-MEDOC",
    value: "33146",
    type: "common",
  },
  {
    label: "SAINT-LAURENT-MEDOC",
    value: "33424",
    type: "common",
  },
  {
    label: "NEVACHE",
    value: "05093",
    type: "common",
  },
  {
    label: "LA GRAVE",
    value: "05063",
    type: "common",
  },
  {
    label: "MIZOEN",
    value: "38237",
    type: "common",
  },
  {
    label: "LE FRENEY-D'OISANS",
    value: "38173",
    type: "common",
  },
  {
    label: "LA GARDE",
    value: "38177",
    type: "common",
  },
  {
    label: "AURIS",
    value: "38020",
    type: "common",
  },
  {
    label: "VILLARD-REYMOND",
    value: "38551",
    type: "common",
  },
  {
    label: "OULLES",
    value: "38286",
    type: "common",
  },
  {
    label: "ORNON",
    value: "38285",
    type: "common",
  },
  {
    label: "LE BOURG-D'OISANS",
    value: "38052",
    type: "common",
  },
  {
    label: "VIZILLE",
    value: "38562",
    type: "common",
  },
  {
    label: "SECHILIENNE",
    value: "38478",
    type: "common",
  },
  {
    label: "SAINT-PIERRE-DE-MESAGE",
    value: "38445",
    type: "common",
  },
  {
    label: "SAINT-BARTHELEMY-DE-SECHILIENNE",
    value: "38364",
    type: "common",
  },
  {
    label: "VARCES-ALLIERES-ET-RISSET",
    value: "38524",
    type: "common",
  },
  {
    label: "SAINT-GEORGES-DE-COMMIERS",
    value: "38388",
    type: "common",
  },
  {
    label: "NOTRE-DAME-DE-MESAGE",
    value: "38279",
    type: "common",
  },
  {
    label: "MONTCHABOUD",
    value: "38252",
    type: "common",
  },
  {
    label: "CHAMP-SUR-DRAC",
    value: "38071",
    type: "common",
  },
  {
    label: "VIF",
    value: "38545",
    type: "common",
  },
  {
    label: "SAINT-PAUL-DE-VARCES",
    value: "38436",
    type: "common",
  },
  {
    label: "VILLARD-DE-LANS",
    value: "38548",
    type: "common",
  },
  {
    label: "SAINT-JULIEN-EN-VERCORS",
    value: "26309",
    type: "common",
  },
  {
    label: "PRESLES",
    value: "38322",
    type: "common",
  },
  {
    label: "CHORANCHE",
    value: "38108",
    type: "common",
  },
  {
    label: "CHATELUS",
    value: "38092",
    type: "common",
  },
  {
    label: "SAINT-ROMANS",
    value: "38453",
    type: "common",
  },
  {
    label: "SAINT-JUST-DE-CLAIX",
    value: "38409",
    type: "common",
  },
  {
    label: "SAINT-ANDRE-EN-ROYANS",
    value: "38356",
    type: "common",
  },
  {
    label: "PONT-EN-ROYANS",
    value: "38319",
    type: "common",
  },
  {
    label: "AUBERIVES-EN-ROYANS",
    value: "38018",
    type: "common",
  },
  {
    label: "SAINT-HILAIRE-DU-ROSIER",
    value: "38394",
    type: "common",
  },
  {
    label: "SAINT-NAZAIRE-EN-ROYANS",
    value: "26320",
    type: "common",
  },
  {
    label: "LA BAUME-D'HOSTUN",
    value: "26034",
    type: "common",
  },
  {
    label: "SAINT-LATTIER",
    value: "38410",
    type: "common",
  },
  {
    label: "TRIORS",
    value: "26355",
    type: "common",
  },
  {
    label: "SAINT-PAUL-LES-ROMANS",
    value: "26323",
    type: "common",
  },
  {
    label: "EYMEUX",
    value: "26129",
    type: "common",
  },
  {
    label: "CHATILLON-SAINT-JEAN",
    value: "26087",
    type: "common",
  },
  {
    label: "PEYRINS",
    value: "26231",
    type: "common",
  },
  {
    label: "MOURS-SAINT-EUSEBE",
    value: "26218",
    type: "common",
  },
  {
    label: "GENISSIEUX",
    value: "26139",
    type: "common",
  },
  {
    label: "SAINT-BARDOUX",
    value: "26294",
    type: "common",
  },
  {
    label: "CLERIEUX",
    value: "26096",
    type: "common",
  },
  {
    label: "MERCUROL-VEAUNES",
    value: "26179",
    type: "common",
  },
  {
    label: "LARNAGE",
    value: "26156",
    type: "common",
  },
  {
    label: "CHAVANNES",
    value: "26092",
    type: "common",
  },
  {
    label: "CHANOS-CURSON",
    value: "26071",
    type: "common",
  },
  {
    label: "GERVANS",
    value: "26380",
    type: "common",
  },
  {
    label: "TAIN-L'HERMITAGE",
    value: "26347",
    type: "common",
  },
  {
    label: "CROZES-HERMITAGE",
    value: "26110",
    type: "common",
  },
  {
    label: "VION",
    value: "07345",
    type: "common",
  },
  {
    label: "SAINT-JEAN-DE-MUZOLS",
    value: "07245",
    type: "common",
  },
  {
    label: "LEMPS",
    value: "07140",
    type: "common",
  },
  {
    label: "ETABLES",
    value: "07086",
    type: "common",
  },
  {
    label: "VAUDEVANT",
    value: "07335",
    type: "common",
  },
  {
    label: "SAINT-VICTOR",
    value: "07301",
    type: "common",
  },
  {
    label: "SAINT-FELICIEN",
    value: "07236",
    type: "common",
  },
  {
    label: "PAILHARES",
    value: "07170",
    type: "common",
  },
  {
    label: "LALOUVESC",
    value: "07128",
    type: "common",
  },
  {
    label: "ROCHEPAULE",
    value: "07192",
    type: "common",
  },
  {
    label: "LAFARRE",
    value: "07124",
    type: "common",
  },
  {
    label: "LE MAS-DE-TENCE",
    value: "43129",
    type: "common",
  },
  {
    label: "SAINT-ANDRE-EN-VIVARAIS",
    value: "07212",
    type: "common",
  },
  {
    label: "TENCE",
    value: "43244",
    type: "common",
  },
  {
    label: "SAINT-JEURES",
    value: "43199",
    type: "common",
  },
  {
    label: "SAINT-HOSTIEN",
    value: "43194",
    type: "common",
  },
  {
    label: "LE PERTUIS",
    value: "43150",
    type: "common",
  },
  {
    label: "BESSAMOREL",
    value: "43028",
    type: "common",
  },
  {
    label: "SAINT-ETIENNE-LARDEYROL",
    value: "43181",
    type: "common",
  },
  {
    label: "MALREVERS",
    value: "43126",
    type: "common",
  },
  {
    label: "POLIGNAC",
    value: "43152",
    type: "common",
  },
  {
    label: "LAVOUTE-SUR-LOIRE",
    value: "43119",
    type: "common",
  },
  {
    label: "CHASPINHAC",
    value: "43061",
    type: "common",
  },
  {
    label: "BLANZAC",
    value: "43030",
    type: "common",
  },
  {
    label: "BORNE",
    value: "43036",
    type: "common",
  },
  {
    label: "VAZEILLES-LIMANDRE",
    value: "43254",
    type: "common",
  },
  {
    label: "LOUDES",
    value: "43124",
    type: "common",
  },
  {
    label: "LISSAC",
    value: "43122",
    type: "common",
  },
  {
    label: "VISSAC-AUTEYRAC",
    value: "43013",
    type: "common",
  },
  {
    label: "SAINT-ARCONS-D'ALLIER",
    value: "43167",
    type: "common",
  },
  {
    label: "MAZEYRAT-D'ALLIER",
    value: "43132",
    type: "common",
  },
  {
    label: "AUBAZAT",
    value: "43011",
    type: "common",
  },
  {
    label: "SAINT-CIRGUES",
    value: "43175",
    type: "common",
  },
  {
    label: "FERRUSSAC",
    value: "43094",
    type: "common",
  },
  {
    label: "ARLET",
    value: "43009",
    type: "common",
  },
  {
    label: "SAINT-AUSTREMOINE",
    value: "43169",
    type: "common",
  },
  {
    label: "CHAZELLES",
    value: "15048",
    type: "common",
  },
  {
    label: "SOULAGES",
    value: "15229",
    type: "common",
  },
  {
    label: "RAGEADE",
    value: "15158",
    type: "common",
  },
  {
    label: "LASTIC",
    value: "15097",
    type: "common",
  },
  {
    label: "CELOUX",
    value: "15032",
    type: "common",
  },
  {
    label: "VIEILLESPESSE",
    value: "15259",
    type: "common",
  },
  {
    label: "TALIZAT",
    value: "15231",
    type: "common",
  },
  {
    label: "REZENTIERES",
    value: "15161",
    type: "common",
  },
  {
    label: "COREN",
    value: "15055",
    type: "common",
  },
  {
    label: "NEUSSARGUES EN PINATELLE",
    value: "15141",
    type: "common",
  },
  {
    label: "COLTINES",
    value: "15053",
    type: "common",
  },
  {
    label: "VIRARGUES",
    value: "15263",
    type: "common",
  },
  {
    label: "MURAT",
    value: "15138",
    type: "common",
  },
  {
    label: "LA CHAPELLE-D'ALAGNON",
    value: "15041",
    type: "common",
  },
  {
    label: "LAVEISSIERE",
    value: "15101",
    type: "common",
  },
  {
    label: "LAVIGERIE",
    value: "15102",
    type: "common",
  },
  {
    label: "LE FALGOUX",
    value: "15066",
    type: "common",
  },
  {
    label: "SAINT-PAUL-DE-SALERS",
    value: "15205",
    type: "common",
  },
  {
    label: "LE FAU",
    value: "15067",
    type: "common",
  },
  {
    label: "SALERS",
    value: "15219",
    type: "common",
  },
  {
    label: "SAINT-MARTIN-VALMEROUX",
    value: "15202",
    type: "common",
  },
  {
    label: "SAINT-CHAMANT",
    value: "15176",
    type: "common",
  },
  {
    label: "FONTANGES",
    value: "15070",
    type: "common",
  },
  {
    label: "SAINTE-EULALIE",
    value: "15186",
    type: "common",
  },
  {
    label: "BESSE",
    value: "15269",
    type: "common",
  },
  {
    label: "SAINT-MARTIN-CANTALES",
    value: "15200",
    type: "common",
  },
  {
    label: "SAINT-JULIEN-AUX-BOIS",
    value: "19214",
    type: "common",
  },
  {
    label: "SAINT-PRIVAT",
    value: "19237",
    type: "common",
  },
  {
    label: "SAINT-CIRGUES-LA-LOUTRE",
    value: "19193",
    type: "common",
  },
  {
    label: "SERVIERES-LE-CHATEAU",
    value: "19258",
    type: "common",
  },
  {
    label: "SAINT-MARTIAL-ENTRAYGUES",
    value: "19221",
    type: "common",
  },
  {
    label: "HAUTEFAGE",
    value: "19091",
    type: "common",
  },
  {
    label: "SAINT-CHAMANT",
    value: "19192",
    type: "common",
  },
  {
    label: "ARGENTAT-SUR-DORDOGNE",
    value: "19010",
    type: "common",
  },
  {
    label: "NEUVILLE",
    value: "19149",
    type: "common",
  },
  {
    label: "MENOIRE",
    value: "19132",
    type: "common",
  },
  {
    label: "ALBUSSAC",
    value: "19004",
    type: "common",
  },
  {
    label: "SERILHAC",
    value: "19257",
    type: "common",
  },
  {
    label: "BEYNAT",
    value: "19023",
    type: "common",
  },
  {
    label: "LANTEUIL",
    value: "19105",
    type: "common",
  },
  {
    label: "LA CHAPELLE-AUX-BROCS",
    value: "19043",
    type: "common",
  },
  {
    label: "JUGEALS-NAZARETH",
    value: "19093",
    type: "common",
  },
  {
    label: "COSNAC",
    value: "19063",
    type: "common",
  },
  {
    label: "NOAILLES",
    value: "19151",
    type: "common",
  },
  {
    label: "LISSAC-SUR-COUZE",
    value: "19117",
    type: "common",
  },
  {
    label: "CHASTEAUX",
    value: "19049",
    type: "common",
  },
  {
    label: "PAZAYAC",
    value: "24321",
    type: "common",
  },
  {
    label: "LA FEUILLADE",
    value: "24179",
    type: "common",
  },
  {
    label: "LES COTEAUX PERIGOURDINS",
    value: "24117",
    type: "common",
  },
  {
    label: "SAINT-CERNIN-DE-LARCHE",
    value: "19191",
    type: "common",
  },
  {
    label: "LARCHE",
    value: "19107",
    type: "common",
  },
  {
    label: "TERRASSON-LAVILLEDIEU",
    value: "24547",
    type: "common",
  },
  {
    label: "LE LARDIN-SAINT-LAZARE",
    value: "24229",
    type: "common",
  },
  {
    label: "CONDAT-SUR-VEZERE",
    value: "24130",
    type: "common",
  },
  {
    label: "LES FARGES",
    value: "24175",
    type: "common",
  },
  {
    label: "AUBAS",
    value: "24014",
    type: "common",
  },
  {
    label: "LA BACHELLERIE",
    value: "24020",
    type: "common",
  },
  {
    label: "AURIAC-DU-PERIGORD",
    value: "24018",
    type: "common",
  },
  {
    label: "THENON",
    value: "24550",
    type: "common",
  },
  {
    label: "BARS",
    value: "24025",
    type: "common",
  },
  {
    label: "FOSSEMAGNE",
    value: "24188",
    type: "common",
  },
  {
    label: "SAINT-PIERRE-DE-CHIGNAC",
    value: "24484",
    type: "common",
  },
  {
    label: "SAINT-CREPIN-D'AUBEROCHE",
    value: "24390",
    type: "common",
  },
  {
    label: "SANILHAC",
    value: "24312",
    type: "common",
  },
  {
    label: "EGLISE-NEUVE-DE-VERGT",
    value: "24160",
    type: "common",
  },
  {
    label: "SAINT-PAUL-DE-SERRE",
    value: "24480",
    type: "common",
  },
  {
    label: "CHALAGNAC",
    value: "24094",
    type: "common",
  },
  {
    label: "MONTREM",
    value: "24295",
    type: "common",
  },
  {
    label: "MANZAC-SUR-VERN",
    value: "24251",
    type: "common",
  },
  {
    label: "GRIGNOLS",
    value: "24205",
    type: "common",
  },
  {
    label: "SAINT-LEON-SUR-L'ISLE",
    value: "24442",
    type: "common",
  },
  {
    label: "NEUVIC",
    value: "24309",
    type: "common",
  },
  {
    label: "SAINT-GERMAIN-DU-SALEMBRE",
    value: "24418",
    type: "common",
  },
  {
    label: "DOUZILLAC",
    value: "24157",
    type: "common",
  },
  {
    label: "SAINT-ETIENNE-DE-PUYCORBIER",
    value: "24399",
    type: "common",
  },
  {
    label: "BEAURONNE",
    value: "24032",
    type: "common",
  },
  {
    label: "SAINT-MICHEL-DE-DOUBLE",
    value: "24465",
    type: "common",
  },
  {
    label: "ECHOURGNAC",
    value: "24159",
    type: "common",
  },
  {
    label: "SAINT-BARTHELEMY-DE-BELLEGARDE",
    value: "24380",
    type: "common",
  },
  {
    label: "EYGURANDE-ET-GARDEDEUIL",
    value: "24165",
    type: "common",
  },
  {
    label: "SAINT-CHRISTOPHE-DE-DOUBLE",
    value: "33385",
    type: "common",
  },
  {
    label: "LES EGLISOTTES-ET-CHALAURES",
    value: "33154",
    type: "common",
  },
  {
    label: "LES PEINTURES",
    value: "33315",
    type: "common",
  },
  {
    label: "CHAMADELLE",
    value: "33124",
    type: "common",
  },
  {
    label: "LAGORCE",
    value: "33218",
    type: "common",
  },
  {
    label: "BAYAS",
    value: "33034",
    type: "common",
  },
  {
    label: "MARANSIN",
    value: "33264",
    type: "common",
  },
  {
    label: "LAPOUYADE",
    value: "33230",
    type: "common",
  },
  {
    label: "TIZAC-DE-LAPOUYADE",
    value: "33532",
    type: "common",
  },
  {
    label: "MARSAS",
    value: "33272",
    type: "common",
  },
  {
    label: "CAVIGNAC",
    value: "33114",
    type: "common",
  },
  {
    label: "CUBNEZAIS",
    value: "33142",
    type: "common",
  },
  {
    label: "CEZAC",
    value: "33123",
    type: "common",
  },
  {
    label: "TEUILLAC",
    value: "33530",
    type: "common",
  },
  {
    label: "TAURIAC",
    value: "33525",
    type: "common",
  },
  {
    label: "SAINT-VIVIEN-DE-BLAYE",
    value: "33489",
    type: "common",
  },
  {
    label: "PUGNAC",
    value: "33341",
    type: "common",
  },
  {
    label: "MOMBRIER",
    value: "33285",
    type: "common",
  },
  {
    label: "LANSAC",
    value: "33228",
    type: "common",
  },
  {
    label: "SAMONAC",
    value: "33500",
    type: "common",
  },
  {
    label: "SAINT-TROJAN",
    value: "33486",
    type: "common",
  },
  {
    label: "SAINT-SEURIN-DE-BOURG",
    value: "33475",
    type: "common",
  },
  {
    label: "SAINT-CIERS-DE-CANESSE",
    value: "33388",
    type: "common",
  },
  {
    label: "GAURIAC",
    value: "33182",
    type: "common",
  },
  {
    label: "COMPS",
    value: "33132",
    type: "common",
  },
  {
    label: "VILLENEUVE",
    value: "33551",
    type: "common",
  },
  {
    label: "SOUSSANS",
    value: "33517",
    type: "common",
  },
  {
    label: "PLASSAC",
    value: "33325",
    type: "common",
  },
  {
    label: "MARGAUX-CANTENAC",
    value: "33268",
    type: "common",
  },
  {
    label: "LAMARQUE",
    value: "33220",
    type: "common",
  },
  {
    label: "ARCINS",
    value: "33010",
    type: "common",
  },
  {
    label: "MOULIS-EN-MEDOC",
    value: "33297",
    type: "common",
  },
  {
    label: "LISTRAC-MEDOC",
    value: "33248",
    type: "common",
  },
  {
    label: "BRACH",
    value: "33070",
    type: "common",
  },
  {
    label: "CARCANS",
    value: "33097",
    type: "common",
  },
  {
    label: "MONTGENEVRE",
    value: "05085",
    type: "common",
  },
  {
    label: "VAL-DES-PRES",
    value: "05174",
    type: "common",
  },
  {
    label: "LE MONETIER-LES-BAINS",
    value: "05079",
    type: "common",
  },
  {
    label: "VILLAR-D'ARENE",
    value: "05181",
    type: "common",
  },
  {
    label: "LES DEUX ALPES",
    value: "38253",
    type: "common",
  },
  {
    label: "VILLARD-NOTRE-DAME",
    value: "38549",
    type: "common",
  },
  {
    label: "LA MORTE",
    value: "38264",
    type: "common",
  },
  {
    label: "LAVALDENS",
    value: "38207",
    type: "common",
  },
  {
    label: "VILLARD-SAINT-CHRISTOPHE",
    value: "38552",
    type: "common",
  },
  {
    label: "SAINT-THEOFFREY",
    value: "38462",
    type: "common",
  },
  {
    label: "LAFFREY",
    value: "38203",
    type: "common",
  },
  {
    label: "CHOLONGE",
    value: "38106",
    type: "common",
  },
  {
    label: "NOTRE-DAME-DE-VAULX",
    value: "38280",
    type: "common",
  },
  {
    label: "SAINT-JEAN-DE-VAULX",
    value: "38402",
    type: "common",
  },
  {
    label: "NOTRE-DAME-DE-COMMIERS",
    value: "38277",
    type: "common",
  },
  {
    label: "MONTEYNARD",
    value: "38254",
    type: "common",
  },
  {
    label: "LE GUA",
    value: "38187",
    type: "common",
  },
  {
    label: "SAINT-MARTIN-DE-LA-CLUZE",
    value: "38115",
    type: "common",
  },
  {
    label: "CORRENCON-EN-VERCORS",
    value: "38129",
    type: "common",
  },
  {
    label: "CHATEAU-BERNARD",
    value: "38090",
    type: "common",
  },
  {
    label: "SAINT-MARTIN-EN-VERCORS",
    value: "26315",
    type: "common",
  },
  {
    label: "ECHEVIS",
    value: "26117",
    type: "common",
  },
  {
    label: "SAINT-THOMAS-EN-ROYANS",
    value: "26331",
    type: "common",
  },
  {
    label: "SAINT-LAURENT-EN-ROYANS",
    value: "26311",
    type: "common",
  },
  {
    label: "SAINT-JEAN-EN-ROYANS",
    value: "26307",
    type: "common",
  },
  {
    label: "SAINTE-EULALIE-EN-ROYANS",
    value: "26302",
    type: "common",
  },
  {
    label: "LA MOTTE-FANJAS",
    value: "26217",
    type: "common",
  },
  {
    label: "ROCHECHINARD",
    value: "26270",
    type: "common",
  },
  {
    label: "ORIOL-EN-ROYANS",
    value: "26223",
    type: "common",
  },
  {
    label: "HOSTUN",
    value: "26149",
    type: "common",
  },
  {
    label: "BEAUREGARD-BARET",
    value: "26039",
    type: "common",
  },
  {
    label: "JAILLANS",
    value: "26381",
    type: "common",
  },
  {
    label: "CHATUZANGE-LE-GOUBET",
    value: "26088",
    type: "common",
  },
  {
    label: "ROMANS-SUR-ISERE",
    value: "26281",
    type: "common",
  },
  {
    label: "BOURG-DE-PEAGE",
    value: "26057",
    type: "common",
  },
  {
    label: "GRANGES-LES-BEAUMONT",
    value: "26379",
    type: "common",
  },
  {
    label: "CHATEAUNEUF-SUR-ISERE",
    value: "26084",
    type: "common",
  },
  {
    label: "PONT-DE-L'ISERE",
    value: "26250",
    type: "common",
  },
  {
    label: "BEAUMONT-MONTEUX",
    value: "26038",
    type: "common",
  },
  {
    label: "LA ROCHE-DE-GLUN",
    value: "26271",
    type: "common",
  },
  {
    label: "TOURNON-SUR-RHONE",
    value: "07324",
    type: "common",
  },
  {
    label: "PLATS",
    value: "07177",
    type: "common",
  },
  {
    label: "MAUVES",
    value: "07152",
    type: "common",
  },
  {
    label: "GLUN",
    value: "07097",
    type: "common",
  },
  {
    label: "SAINT-BARTHELEMY-LE-PLAIN",
    value: "07217",
    type: "common",
  },
  {
    label: "COLOMBIER-LE-VIEUX",
    value: "07069",
    type: "common",
  },
  {
    label: "COLOMBIER-LE-JEUNE",
    value: "07068",
    type: "common",
  },
  {
    label: "EMPURANY",
    value: "07085",
    type: "common",
  },
  {
    label: "BOUCIEU-LE-ROI",
    value: "07040",
    type: "common",
  },
  {
    label: "BOZAS",
    value: "07039",
    type: "common",
  },
  {
    label: "ARLEBOSC",
    value: "07014",
    type: "common",
  },
  {
    label: "NOZIERES",
    value: "07166",
    type: "common",
  },
  {
    label: "SAINT-JEURE-D'ANDAURE",
    value: "07249",
    type: "common",
  },
  {
    label: "LABATIE-D'ANDAURE",
    value: "07114",
    type: "common",
  },
  {
    label: "DEVESSET",
    value: "07080",
    type: "common",
  },
  {
    label: "LE CHAMBON-SUR-LIGNON",
    value: "43051",
    type: "common",
  },
  {
    label: "MAZET-SAINT-VOY",
    value: "43130",
    type: "common",
  },
  {
    label: "CHAMPCLAUSE",
    value: "43053",
    type: "common",
  },
  {
    label: "ARAULES",
    value: "43007",
    type: "common",
  },
  {
    label: "SAINT-PIERRE-EYNAC",
    value: "43218",
    type: "common",
  },
  {
    label: "SAINT-JULIEN-CHAPTEUIL",
    value: "43200",
    type: "common",
  },
  {
    label: "QUEYRIERES",
    value: "43158",
    type: "common",
  },
  {
    label: "SAINT-GERMAIN-LAPRADE",
    value: "43190",
    type: "common",
  },
  {
    label: "BLAVOZY",
    value: "43032",
    type: "common",
  },
  {
    label: "VALS-PRES-LE-PUY",
    value: "43251",
    type: "common",
  },
  {
    label: "LE PUY-EN-VELAY",
    value: "43157",
    type: "common",
  },
  {
    label: "LE MONTEIL",
    value: "43140",
    type: "common",
  },
  {
    label: "ESPALY-SAINT-MARCEL",
    value: "43089",
    type: "common",
  },
  {
    label: "CHADRAC",
    value: "43046",
    type: "common",
  },
  {
    label: "BRIVES-CHARENSAC",
    value: "43041",
    type: "common",
  },
  {
    label: "AIGUILHE",
    value: "43002",
    type: "common",
  },
  {
    label: "SANSSAC-L'EGLISE",
    value: "43233",
    type: "common",
  },
  {
    label: "SAINT-VIDAL",
    value: "43229",
    type: "common",
  },
  {
    label: "CEYSSAC",
    value: "43045",
    type: "common",
  },
  {
    label: "VERGEZAC",
    value: "43257",
    type: "common",
  },
  {
    label: "SAINT-JEAN-DE-NAY",
    value: "43197",
    type: "common",
  },
  {
    label: "CHASPUZAC",
    value: "43062",
    type: "common",
  },
  {
    label: "LE VERNET",
    value: "43260",
    type: "common",
  },
  {
    label: "SIAUGUES-SAINTE-MARIE",
    value: "43239",
    type: "common",
  },
  {
    label: "SAINT-BERAIN",
    value: "43171",
    type: "common",
  },
  {
    label: "SAINT-JULIEN-DES-CHAZES",
    value: "43202",
    type: "common",
  },
  {
    label: "PRADES",
    value: "43155",
    type: "common",
  },
  {
    label: "CHARRAIX",
    value: "43060",
    type: "common",
  },
  {
    label: "CHANTEUGES",
    value: "43056",
    type: "common",
  },
  {
    label: "TAILHAC",
    value: "43242",
    type: "common",
  },
  {
    label: "PEBRAC",
    value: "43149",
    type: "common",
  },
  {
    label: "LANGEAC",
    value: "43112",
    type: "common",
  },
  {
    label: "DESGES",
    value: "43085",
    type: "common",
  },
  {
    label: "CHAZELLES",
    value: "43068",
    type: "common",
  },
  {
    label: "PINOLS",
    value: "43151",
    type: "common",
  },
  {
    label: "CRONCE",
    value: "43082",
    type: "common",
  },
  {
    label: "CHASTEL",
    value: "43065",
    type: "common",
  },
  {
    label: "VEDRINES-SAINT-LOUP",
    value: "15251",
    type: "common",
  },
  {
    label: "VABRES",
    value: "15245",
    type: "common",
  },
  {
    label: "MONTCHAMP",
    value: "15130",
    type: "common",
  },
  {
    label: "TIVIERS",
    value: "15237",
    type: "common",
  },
  {
    label: "SAINT-GEORGES",
    value: "15188",
    type: "common",
  },
  {
    label: "MENTIERES",
    value: "15125",
    type: "common",
  },
  {
    label: "SAINT-FLOUR",
    value: "15187",
    type: "common",
  },
  {
    label: "ANDELAT",
    value: "15004",
    type: "common",
  },
  {
    label: "TANAVELLE",
    value: "15232",
    type: "common",
  },
  {
    label: "ROFFIAC",
    value: "15164",
    type: "common",
  },
  {
    label: "VALUEJOLS",
    value: "15248",
    type: "common",
  },
  {
    label: "USSEL",
    value: "15244",
    type: "common",
  },
  {
    label: "LAVEISSENET",
    value: "15100",
    type: "common",
  },
  {
    label: "ALBEPIERRE-BREDONS",
    value: "15025",
    type: "common",
  },
  {
    label: "SAINT-JACQUES-DES-BLATS",
    value: "15192",
    type: "common",
  },
  {
    label: "MANDAILLES-SAINT-JULIEN",
    value: "15113",
    type: "common",
  },
  {
    label: "SAINT-PROJET-DE-SALERS",
    value: "15208",
    type: "common",
  },
  {
    label: "SAINT-CIRGUES-DE-JORDANNE",
    value: "15178",
    type: "common",
  },
  {
    label: "LASCELLE",
    value: "15096",
    type: "common",
  },
  {
    label: "GIRGOLS",
    value: "15075",
    type: "common",
  },
  {
    label: "TOURNEMIRE",
    value: "15238",
    type: "common",
  },
  {
    label: "SAINT-CIRGUES-DE-MALBERT",
    value: "15179",
    type: "common",
  },
  {
    label: "SAINT-CERNIN",
    value: "15175",
    type: "common",
  },
  {
    label: "SAINT-ILLIDE",
    value: "15191",
    type: "common",
  },
  {
    label: "ARNAC",
    value: "15011",
    type: "common",
  },
  {
    label: "ROUFFIAC",
    value: "15165",
    type: "common",
  },
  {
    label: "CROS-DE-MONTVERT",
    value: "15057",
    type: "common",
  },
  {
    label: "SAINT-GENIEZ-O-MERLE",
    value: "19205",
    type: "common",
  },
  {
    label: "SAINT-BONNET-LES-TOURS-DE-MERLE",
    value: "19189",
    type: "common",
  },
  {
    label: "SEXCLES",
    value: "19259",
    type: "common",
  },
  {
    label: "LA CHAPELLE-SAINT-GERAUD",
    value: "19045",
    type: "common",
  },
  {
    label: "MONCEAUX-SUR-DORDOGNE",
    value: "19140",
    type: "common",
  },
  {
    label: "TUDEILS",
    value: "19271",
    type: "common",
  },
  {
    label: "SAINT-HILAIRE-TAURIEUX",
    value: "19212",
    type: "common",
  },
  {
    label: "LOSTANGES",
    value: "19119",
    type: "common",
  },
  {
    label: "CHENAILLER-MASCHEIX",
    value: "19054",
    type: "common",
  },
  {
    label: "SAINT-JULIEN-MAUMONT",
    value: "19217",
    type: "common",
  },
  {
    label: "SAINT-BAZILE-DE-MEYSSAC",
    value: "19184",
    type: "common",
  },
  {
    label: "LE PESCHER",
    value: "19163",
    type: "common",
  },
  {
    label: "MARCILLAC-LA-CROZE",
    value: "19126",
    type: "common",
  },
  {
    label: "LAGLEYGEOLLE",
    value: "19099",
    type: "common",
  },
  {
    label: "SAILLAC",
    value: "19179",
    type: "common",
  },
  {
    label: "NOAILHAC",
    value: "19150",
    type: "common",
  },
  {
    label: "MEYSSAC",
    value: "19138",
    type: "common",
  },
  {
    label: "LIGNEYRAC",
    value: "19115",
    type: "common",
  },
  {
    label: "COLLONGES-LA-ROUGE",
    value: "19057",
    type: "common",
  },
  {
    label: "TURENNE",
    value: "19273",
    type: "common",
  },
  {
    label: "NESPOULS",
    value: "19147",
    type: "common",
  },
  {
    label: "ESTIVALS",
    value: "19077",
    type: "common",
  },
  {
    label: "NADAILLAC",
    value: "24301",
    type: "common",
  },
  {
    label: "CHARTRIER-FERRIERE",
    value: "19047",
    type: "common",
  },
  {
    label: "JAYAC",
    value: "24215",
    type: "common",
  },
  {
    label: "LA CASSAGNE",
    value: "24085",
    type: "common",
  },
  {
    label: "LA DORNAC",
    value: "24153",
    type: "common",
  },
  {
    label: "MONTIGNAC-LASCAUX",
    value: "24291",
    type: "common",
  },
  {
    label: "THONAC",
    value: "24552",
    type: "common",
  },
  {
    label: "PLAZAC",
    value: "24330",
    type: "common",
  },
  {
    label: "FANLAC",
    value: "24174",
    type: "common",
  },
  {
    label: "ROUFFIGNAC-SAINT-CERNIN-DE-REILHAC",
    value: "24356",
    type: "common",
  },
  {
    label: "SAINT-GEYRAC",
    value: "24421",
    type: "common",
  },
  {
    label: "SALON",
    value: "24518",
    type: "common",
  },
  {
    label: "LACROPTE",
    value: "24220",
    type: "common",
  },
  {
    label: "LA DOUZE",
    value: "24156",
    type: "common",
  },
  {
    label: "VERGT",
    value: "24571",
    type: "common",
  },
  {
    label: "GRUN-BORDAS",
    value: "24208",
    type: "common",
  },
  {
    label: "SAINT-MAYME-DE-PEREYROL",
    value: "24459",
    type: "common",
  },
  {
    label: "CREYSSENSAC-ET-PISSOT",
    value: "24146",
    type: "common",
  },
  {
    label: "VILLAMBLARD",
    value: "24581",
    type: "common",
  },
  {
    label: "JAURE",
    value: "24213",
    type: "common",
  },
  {
    label: "BOURROU",
    value: "24061",
    type: "common",
  },
  {
    label: "VALLEREUIL",
    value: "24562",
    type: "common",
  },
  {
    label: "SAINT-SEVERIN-D'ESTISSAC",
    value: "24502",
    type: "common",
  },
  {
    label: "SAINT-JEAN-D'ESTISSAC",
    value: "24426",
    type: "common",
  },
  {
    label: "SAINT-HILAIRE-D'ESTISSAC",
    value: "24422",
    type: "common",
  },
  {
    label: "ISSAC",
    value: "24211",
    type: "common",
  },
  {
    label: "SOURZAC",
    value: "24543",
    type: "common",
  },
  {
    label: "SAINT-LOUIS-EN-L'ISLE",
    value: "24444",
    type: "common",
  },
  {
    label: "SAINT-FRONT-DE-PRADOUX",
    value: "24409",
    type: "common",
  },
  {
    label: "BOURGNAC",
    value: "24059",
    type: "common",
  },
  {
    label: "SAINT-MEDARD-DE-MUSSIDAN",
    value: "24462",
    type: "common",
  },
  {
    label: "SAINT-MARTIN-L'ASTIER",
    value: "24457",
    type: "common",
  },
  {
    label: "MUSSIDAN",
    value: "24299",
    type: "common",
  },
  {
    label: "SAINT-LAURENT-DES-HOMMES",
    value: "24436",
    type: "common",
  },
  {
    label: "MONTPON-MENESTEROL",
    value: "24294",
    type: "common",
  },
  {
    label: "SAINT-ANTOINE-SUR-L'ISLE",
    value: "33373",
    type: "common",
  },
  {
    label: "LE PIZOU",
    value: "24329",
    type: "common",
  },
  {
    label: "SAINT-SEURIN-SUR-L'ISLE",
    value: "33478",
    type: "common",
  },
  {
    label: "PORCHERES",
    value: "33332",
    type: "common",
  },
  {
    label: "LE FIEU",
    value: "33166",
    type: "common",
  },
  {
    label: "CAMPS-SUR-L'ISLE",
    value: "33088",
    type: "common",
  },
  {
    label: "SAINT-MEDARD-DE-GUIZIERES",
    value: "33447",
    type: "common",
  },
  {
    label: "COUTRAS",
    value: "33138",
    type: "common",
  },
  {
    label: "ABZAC",
    value: "33001",
    type: "common",
  },
  {
    label: "SABLONS",
    value: "33362",
    type: "common",
  },
  {
    label: "GUITRES",
    value: "33198",
    type: "common",
  },
  {
    label: "SAINT-MARTIN-DU-BOIS",
    value: "33445",
    type: "common",
  },
  {
    label: "SAINT-MARTIN-DE-LAYE",
    value: "33442",
    type: "common",
  },
  {
    label: "SAINT-CIERS-D'ABZAC",
    value: "33387",
    type: "common",
  },
  {
    label: "BONZAC",
    value: "33062",
    type: "common",
  },
  {
    label: "SAINT-GENES-DE-FRONSAC",
    value: "33407",
    type: "common",
  },
  {
    label: "PERISSAC",
    value: "33317",
    type: "common",
  },
  {
    label: "MOUILLAC",
    value: "33295",
    type: "common",
  },
  {
    label: "MARCENAIS",
    value: "33266",
    type: "common",
  },
  {
    label: "VIRSAC",
    value: "33553",
    type: "common",
  },
  {
    label: "SAINT-LAURENT-D'ARCE",
    value: "33425",
    type: "common",
  },
  {
    label: "SAINT-GERVAIS",
    value: "33415",
    type: "common",
  },
  {
    label: "SAINT-ANDRE-DE-CUBZAC",
    value: "33366",
    type: "common",
  },
  {
    label: "PEUJARD",
    value: "33321",
    type: "common",
  },
  {
    label: "GAURIAGUET",
    value: "33183",
    type: "common",
  },
  {
    label: "VAL DE VIRVEE",
    value: "33018",
    type: "common",
  },
  {
    label: "PRIGNAC-ET-MARCAMPS",
    value: "33339",
    type: "common",
  },
  {
    label: "MACAU",
    value: "33262",
    type: "common",
  },
  {
    label: "BOURG",
    value: "33067",
    type: "common",
  },
  {
    label: "BAYON-SUR-GIRONDE",
    value: "33035",
    type: "common",
  },
  {
    label: "AMBES",
    value: "33004",
    type: "common",
  },
  {
    label: "LABARDE",
    value: "33211",
    type: "common",
  },
  {
    label: "ARSAC",
    value: "33012",
    type: "common",
  },
  {
    label: "AVENSAN",
    value: "33022",
    type: "common",
  },
  {
    label: "CASTELNAU-DE-MEDOC",
    value: "33104",
    type: "common",
  },
  {
    label: "LACANAU",
    value: "33214",
    type: "common",
  },
  {
    label: "PUY-SAINT-PIERRE",
    value: "05109",
    type: "common",
  },
  {
    label: "BRIANCON",
    value: "05023",
    type: "common",
  },
  {
    label: "SAINT-CHAFFREY",
    value: "05133",
    type: "common",
  },
  {
    label: "LA SALLE-LES-ALPES",
    value: "05161",
    type: "common",
  },
  {
    label: "PUY-SAINT-ANDRE",
    value: "05107",
    type: "common",
  },
  {
    label: "VALLOUISE-PELVOUX",
    value: "05101",
    type: "common",
  },
  {
    label: "SAINT-CHRISTOPHE-EN-OISANS",
    value: "38375",
    type: "common",
  },
  {
    label: "LA VALETTE",
    value: "38521",
    type: "common",
  },
  {
    label: "ORIS-EN-RATTIER",
    value: "38283",
    type: "common",
  },
  {
    label: "SUSVILLE",
    value: "38499",
    type: "common",
  },
  {
    label: "SAINT-HONORE",
    value: "38396",
    type: "common",
  },
  {
    label: "PIERRE-CHATEL",
    value: "38304",
    type: "common",
  },
  {
    label: "NANTES-EN-RATIER",
    value: "38273",
    type: "common",
  },
  {
    label: "LA MOTTE-SAINT-MARTIN",
    value: "38266",
    type: "common",
  },
  {
    label: "LA MOTTE-D'AVEILLANS",
    value: "38265",
    type: "common",
  },
  {
    label: "MARCIEU",
    value: "38217",
    type: "common",
  },
  {
    label: "SINARD",
    value: "38492",
    type: "common",
  },
  {
    label: "MONESTIER-DE-CLERMONT",
    value: "38242",
    type: "common",
  },
  {
    label: "MIRIBEL-LANCHATRE",
    value: "38235",
    type: "common",
  },
  {
    label: "AVIGNONET",
    value: "38023",
    type: "common",
  },
  {
    label: "SAINT-GUILLAUME",
    value: "38391",
    type: "common",
  },
  {
    label: "SAINT-ANDEOL",
    value: "38355",
    type: "common",
  },
  {
    label: "LA CHAPELLE-EN-VERCORS",
    value: "26074",
    type: "common",
  },
  {
    label: "SAINT-MARTIN-LE-COLONEL",
    value: "26316",
    type: "common",
  },
  {
    label: "BOUVANTE",
    value: "26059",
    type: "common",
  },
  {
    label: "ROCHEFORT-SAMSON",
    value: "26273",
    type: "common",
  },
  {
    label: "MARCHES",
    value: "26173",
    type: "common",
  },
  {
    label: "BARBIERES",
    value: "26023",
    type: "common",
  },
  {
    label: "CHARPEY",
    value: "26079",
    type: "common",
  },
  {
    label: "BESAYES",
    value: "26049",
    type: "common",
  },
  {
    label: "ALIXAN",
    value: "26004",
    type: "common",
  },
  {
    label: "SAINT-MARCEL-LES-VALENCE",
    value: "26313",
    type: "common",
  },
  {
    label: "MONTELIER",
    value: "26197",
    type: "common",
  },
  {
    label: "BOURG-LES-VALENCE",
    value: "26058",
    type: "common",
  },
  {
    label: "SAINT-ROMAIN-DE-LERPS",
    value: "07293",
    type: "common",
  },
  {
    label: "CORNAS",
    value: "07070",
    type: "common",
  },
  {
    label: "CHATEAUBOURG",
    value: "07059",
    type: "common",
  },
  {
    label: "SAINT-SYLVESTRE",
    value: "07297",
    type: "common",
  },
  {
    label: "CHAMPIS",
    value: "07052",
    type: "common",
  },
  {
    label: "SAINT-BARTHELEMY-GROZON",
    value: "07216",
    type: "common",
  },
  {
    label: "GILHOC-SUR-ORMEZE",
    value: "07095",
    type: "common",
  },
  {
    label: "LE CRESTET",
    value: "07073",
    type: "common",
  },
  {
    label: "SAINT-BASILE",
    value: "07218",
    type: "common",
  },
  {
    label: "LAMASTRE",
    value: "07129",
    type: "common",
  },
  {
    label: "DESAIGNES",
    value: "07079",
    type: "common",
  },
  {
    label: "SAINT-JEAN-ROURE",
    value: "07248",
    type: "common",
  },
  {
    label: "SAINT-AGREVE",
    value: "07204",
    type: "common",
  },
  {
    label: "LES VASTRES",
    value: "43253",
    type: "common",
  },
  {
    label: "MARS",
    value: "07151",
    type: "common",
  },
  {
    label: "LACHAPELLE-SOUS-CHANEAC",
    value: "07123",
    type: "common",
  },
  {
    label: "FAY-SUR-LIGNON",
    value: "43092",
    type: "common",
  },
  {
    label: "SAINT-FRONT",
    value: "43186",
    type: "common",
  },
  {
    label: "MONTUSCLAT",
    value: "43143",
    type: "common",
  },
  {
    label: "LAUSSONNE",
    value: "43115",
    type: "common",
  },
  {
    label: "LANTRIAC",
    value: "43113",
    type: "common",
  },
  {
    label: "ARSAC-EN-VELAY",
    value: "43010",
    type: "common",
  },
  {
    label: "SOLIGNAC-SUR-LOIRE",
    value: "43241",
    type: "common",
  },
  {
    label: "CUSSAC-SUR-LOIRE",
    value: "43084",
    type: "common",
  },
  {
    label: "COUBON",
    value: "43078",
    type: "common",
  },
  {
    label: "SAINT-CHRISTOPHE-SUR-DOLAISON",
    value: "43174",
    type: "common",
  },
  {
    label: "BAINS",
    value: "43018",
    type: "common",
  },
  {
    label: "SAINT-PRIVAT-D'ALLIER",
    value: "43221",
    type: "common",
  },
  {
    label: "CUBELLES",
    value: "43083",
    type: "common",
  },
  {
    label: "VENTEUGES",
    value: "43256",
    type: "common",
  },
  {
    label: "AUVERS",
    value: "43015",
    type: "common",
  },
  {
    label: "CLAVIERES",
    value: "15051",
    type: "common",
  },
  {
    label: "RUYNES-EN-MARGERIDE",
    value: "15168",
    type: "common",
  },
  {
    label: "CHALIERS",
    value: "15034",
    type: "common",
  },
  {
    label: "ANGLARDS-DE-SAINT-FLOUR",
    value: "15005",
    type: "common",
  },
  {
    label: "VILLEDIEU",
    value: "15262",
    type: "common",
  },
  {
    label: "LES TERNES",
    value: "15235",
    type: "common",
  },
  {
    label: "PAULHAC",
    value: "15148",
    type: "common",
  },
  {
    label: "CUSSAC",
    value: "15059",
    type: "common",
  },
  {
    label: "CEZENS",
    value: "15033",
    type: "common",
  },
  {
    label: "BREZONS",
    value: "15026",
    type: "common",
  },
  {
    label: "MALBO",
    value: "15112",
    type: "common",
  },
  {
    label: "VIC-SUR-CERE",
    value: "15258",
    type: "common",
  },
  {
    label: "THIEZAC",
    value: "15236",
    type: "common",
  },
  {
    label: "SAINT-CLEMENT",
    value: "15180",
    type: "common",
  },
  {
    label: "VELZIC",
    value: "15252",
    type: "common",
  },
  {
    label: "LAROQUEVIEILLE",
    value: "15095",
    type: "common",
  },
  {
    label: "MARMANHAC",
    value: "15118",
    type: "common",
  },
  {
    label: "TEISSIERES-DE-CORNET",
    value: "15233",
    type: "common",
  },
  {
    label: "JUSSAC",
    value: "15083",
    type: "common",
  },
  {
    label: "FREIX-ANGLARDS",
    value: "15072",
    type: "common",
  },
  {
    label: "SAINT-VICTOR",
    value: "15217",
    type: "common",
  },
  {
    label: "AYRENS",
    value: "15016",
    type: "common",
  },
  {
    label: "SAINT-SANTIN-CANTALES",
    value: "15211",
    type: "common",
  },
  {
    label: "NIEUDAN",
    value: "15143",
    type: "common",
  },
  {
    label: "LAROQUEBROU",
    value: "15094",
    type: "common",
  },
  {
    label: "MONTVERT",
    value: "15135",
    type: "common",
  },
  {
    label: "SAINT-JULIEN-LE-PELERIN",
    value: "19215",
    type: "common",
  },
  {
    label: "GOULLES",
    value: "19086",
    type: "common",
  },
  {
    label: "MERCŒUR",
    value: "19133",
    type: "common",
  },
  {
    label: "CAMPS-SAINT-MATHURIN-LEOBAZEL",
    value: "19034",
    type: "common",
  },
  {
    label: "REYGADE",
    value: "19171",
    type: "common",
  },
  {
    label: "BASSIGNAC-LE-BAS",
    value: "19017",
    type: "common",
  },
  {
    label: "ALTILLAC",
    value: "19007",
    type: "common",
  },
  {
    label: "SIONIAC",
    value: "19260",
    type: "common",
  },
  {
    label: "NONARDS",
    value: "19152",
    type: "common",
  },
  {
    label: "VEGENNES",
    value: "19280",
    type: "common",
  },
  {
    label: "PUY-D'ARNAC",
    value: "19169",
    type: "common",
  },
  {
    label: "CUREMONTE",
    value: "19067",
    type: "common",
  },
  {
    label: "LA CHAPELLE-AUX-SAINTS",
    value: "19044",
    type: "common",
  },
  {
    label: "BRANCEILLES",
    value: "19029",
    type: "common",
  },
  {
    label: "STRENQUELS",
    value: "46312",
    type: "common",
  },
  {
    label: "SAINT-MICHEL-DE-BANNIERES",
    value: "46283",
    type: "common",
  },
  {
    label: "CONDAT",
    value: "46074",
    type: "common",
  },
  {
    label: "CAVAGNAC",
    value: "46065",
    type: "common",
  },
  {
    label: "CHAUFFOUR-SUR-VELL",
    value: "19050",
    type: "common",
  },
  {
    label: "GIGNAC",
    value: "46118",
    type: "common",
  },
  {
    label: "BORREZE",
    value: "24050",
    type: "common",
  },
  {
    label: "ARCHIGNAC",
    value: "24012",
    type: "common",
  },
  {
    label: "PAULIN",
    value: "24317",
    type: "common",
  },
  {
    label: "SAINT-GENIES",
    value: "24412",
    type: "common",
  },
  {
    label: "VALOJOULX",
    value: "24563",
    type: "common",
  },
  {
    label: "TAMNIES",
    value: "24544",
    type: "common",
  },
  {
    label: "LA CHAPELLE-AUBAREIL",
    value: "24106",
    type: "common",
  },
  {
    label: "TURSAC",
    value: "24559",
    type: "common",
  },
  {
    label: "SERGEAC",
    value: "24531",
    type: "common",
  },
  {
    label: "SAINT-LEON-SUR-VEZERE",
    value: "24443",
    type: "common",
  },
  {
    label: "PEYZAC-LE-MOUSTIER",
    value: "24326",
    type: "common",
  },
  {
    label: "SAVIGNAC-DE-MIREMONT",
    value: "24524",
    type: "common",
  },
  {
    label: "FLEURAC",
    value: "24183",
    type: "common",
  },
  {
    label: "SAINT-FELIX-DE-REILLAC-ET-MORTEMART",
    value: "24404",
    type: "common",
  },
  {
    label: "MAUZENS-ET-MIREMONT",
    value: "24261",
    type: "common",
  },
  {
    label: "JOURNIAC",
    value: "24217",
    type: "common",
  },
  {
    label: "VEYRINES-DE-VERGT",
    value: "24576",
    type: "common",
  },
  {
    label: "SAINT-MICHEL-DE-VILLADEIX",
    value: "24468",
    type: "common",
  },
  {
    label: "SAINT-AMAND-DE-VERGT",
    value: "24365",
    type: "common",
  },
  {
    label: "SAINT-MARTIN-DES-COMBES",
    value: "24456",
    type: "common",
  },
  {
    label: "FOULEIX",
    value: "24190",
    type: "common",
  },
  {
    label: "BEAUREGARD-ET-BASSAC",
    value: "24031",
    type: "common",
  },
  {
    label: "MONTAGNAC-LA-CREMPSE",
    value: "24285",
    type: "common",
  },
  {
    label: "DOUVILLE",
    value: "24155",
    type: "common",
  },
  {
    label: "BELEYMAS",
    value: "24034",
    type: "common",
  },
  {
    label: "LES LECHES",
    value: "24234",
    type: "common",
  },
  {
    label: "EGLISE-NEUVE-D'ISSAC",
    value: "24161",
    type: "common",
  },
  {
    label: "SAINT-GERY",
    value: "24420",
    type: "common",
  },
  {
    label: "BOSSET",
    value: "24051",
    type: "common",
  },
  {
    label: "BEAUPOUYET",
    value: "24029",
    type: "common",
  },
  {
    label: "SAINT-SAUVEUR-LALANDE",
    value: "24500",
    type: "common",
  },
  {
    label: "SAINT-MARTIAL-D'ARTENSET",
    value: "24449",
    type: "common",
  },
  {
    label: "SAINT-REMY",
    value: "24494",
    type: "common",
  },
  {
    label: "SAINT-MARTIN-DE-GURSON",
    value: "24454",
    type: "common",
  },
  {
    label: "VILLEFRANCHE-DE-LONCHAT",
    value: "24584",
    type: "common",
  },
  {
    label: "MOULIN-NEUF",
    value: "24297",
    type: "common",
  },
  {
    label: "MENESPLET",
    value: "24264",
    type: "common",
  },
  {
    label: "TAYAC",
    value: "33526",
    type: "common",
  },
  {
    label: "SAINT-SAUVEUR-DE-PUYNORMAND",
    value: "33472",
    type: "common",
  },
  {
    label: "PUYNORMAND",
    value: "33347",
    type: "common",
  },
  {
    label: "PETIT-PALAIS-ET-CORNEMPS",
    value: "33320",
    type: "common",
  },
  {
    label: "GOURS",
    value: "33191",
    type: "common",
  },
  {
    label: "MINZAC",
    value: "24272",
    type: "common",
  },
  {
    label: "LUSSAC",
    value: "33261",
    type: "common",
  },
  {
    label: "SAINT-DENIS-DE-PILE",
    value: "33393",
    type: "common",
  },
  {
    label: "NEAC",
    value: "33302",
    type: "common",
  },
  {
    label: "LALANDE-DE-POMEROL",
    value: "33222",
    type: "common",
  },
  {
    label: "LES ARTIGUES-DE-LUSSAC",
    value: "33014",
    type: "common",
  },
  {
    label: "SAVIGNAC-DE-L'ISLE",
    value: "33509",
    type: "common",
  },
  {
    label: "SAINT-AIGNAN",
    value: "33365",
    type: "common",
  },
  {
    label: "SAILLANS",
    value: "33364",
    type: "common",
  },
  {
    label: "GALGON",
    value: "33179",
    type: "common",
  },
  {
    label: "LES BILLAUX",
    value: "33052",
    type: "common",
  },
  {
    label: "VILLEGOUGE",
    value: "33548",
    type: "common",
  },
  {
    label: "VERAC",
    value: "33542",
    type: "common",
  },
  {
    label: "TARNES",
    value: "33524",
    type: "common",
  },
  {
    label: "SAINT-GERMAIN-DE-LA-RIVIERE",
    value: "33414",
    type: "common",
  },
  {
    label: "LA RIVIERE",
    value: "33356",
    type: "common",
  },
  {
    label: "LUGON-ET-L'ILE-DU-CARNAY",
    value: "33259",
    type: "common",
  },
  {
    label: "LA LANDE-DE-FRONSAC",
    value: "33219",
    type: "common",
  },
  {
    label: "CADILLAC-EN-FRONSADAIS",
    value: "33082",
    type: "common",
  },
  {
    label: "SAINT-ROMAIN-LA-VIRVEE",
    value: "33470",
    type: "common",
  },
  {
    label: "CUBZAC-LES-PONTS",
    value: "33143",
    type: "common",
  },
  {
    label: "ASQUES",
    value: "33016",
    type: "common",
  },
  {
    label: "SAINT-VINCENT-DE-PAUL",
    value: "33487",
    type: "common",
  },
  {
    label: "SAINT-LOUIS-DE-MONTFERRAND",
    value: "33434",
    type: "common",
  },
  {
    label: "AMBARES-ET-LAGRAVE",
    value: "33003",
    type: "common",
  },
  {
    label: "PAREMPUYRE",
    value: "33312",
    type: "common",
  },
  {
    label: "LUDON-MEDOC",
    value: "33256",
    type: "common",
  },
  {
    label: "LE PIAN-MEDOC",
    value: "33322",
    type: "common",
  },
  {
    label: "SAINT-AUBIN-DE-MEDOC",
    value: "33376",
    type: "common",
  },
  {
    label: "SALAUNES",
    value: "33494",
    type: "common",
  },
  {
    label: "SAINTE-HELENE",
    value: "33417",
    type: "common",
  },
  {
    label: "CERVIERES",
    value: "05027",
    type: "common",
  },
  {
    label: "VILLAR-SAINT-PANCRACE",
    value: "05183",
    type: "common",
  },
  {
    label: "SAINT-MARTIN-DE-QUEYRIERES",
    value: "05151",
    type: "common",
  },
  {
    label: "VALJOUFFREY",
    value: "38522",
    type: "common",
  },
  {
    label: "ENTRAIGUES",
    value: "38154",
    type: "common",
  },
  {
    label: "VALBONNAIS",
    value: "38518",
    type: "common",
  },
  {
    label: "SIEVOZ",
    value: "38489",
    type: "common",
  },
  {
    label: "LA SALLE-EN-BEAUMONT",
    value: "38470",
    type: "common",
  },
  {
    label: "SAINT-MICHEL-EN-BEAUMONT",
    value: "38428",
    type: "common",
  },
  {
    label: "SOUSVILLE",
    value: "38497",
    type: "common",
  },
  {
    label: "SAINT-PIERRE-DE-MEAROZ",
    value: "38444",
    type: "common",
  },
  {
    label: "SAINT-LAURENT-EN-BEAUMONT",
    value: "38413",
    type: "common",
  },
  {
    label: "PONSONNAS",
    value: "38313",
    type: "common",
  },
  {
    label: "LA MURE",
    value: "38269",
    type: "common",
  },
  {
    label: "COGNET",
    value: "38116",
    type: "common",
  },
  {
    label: "SAINT-AREY",
    value: "38361",
    type: "common",
  },
  {
    label: "PRUNIERES",
    value: "38326",
    type: "common",
  },
  {
    label: "MAYRES-SAVEL",
    value: "38224",
    type: "common",
  },
  {
    label: "TREFFORT",
    value: "38513",
    type: "common",
  },
  {
    label: "SAINT-PAUL-LES-MONESTIER",
    value: "38438",
    type: "common",
  },
  {
    label: "SAINT-MICHEL-LES-PORTES",
    value: "38429",
    type: "common",
  },
  {
    label: "ROISSARD",
    value: "38342",
    type: "common",
  },
  {
    label: "GRESSE-EN-VERCORS",
    value: "38186",
    type: "common",
  },
  {
    label: "SAINT-AGNAN-EN-VERCORS",
    value: "26290",
    type: "common",
  },
  {
    label: "VASSIEUX-EN-VERCORS",
    value: "26364",
    type: "common",
  },
  {
    label: "LEONCEL",
    value: "26163",
    type: "common",
  },
  {
    label: "SAINT-VINCENT-LA-COMMANDERIE",
    value: "26382",
    type: "common",
  },
  {
    label: "PEYRUS",
    value: "26232",
    type: "common",
  },
  {
    label: "CHATEAUDOUBLE",
    value: "26081",
    type: "common",
  },
  {
    label: "MALISSARD",
    value: "26170",
    type: "common",
  },
  {
    label: "CHABEUIL",
    value: "26064",
    type: "common",
  },
  {
    label: "GUILHERAND-GRANGES",
    value: "07102",
    type: "common",
  },
  {
    label: "VALENCE",
    value: "26362",
    type: "common",
  },
  {
    label: "TOULAUD",
    value: "07323",
    type: "common",
  },
  {
    label: "SOYONS",
    value: "07316",
    type: "common",
  },
  {
    label: "SAINT-PERAY",
    value: "07281",
    type: "common",
  },
  {
    label: "BOFFRES",
    value: "07035",
    type: "common",
  },
  {
    label: "ALBOUSSIERE",
    value: "07007",
    type: "common",
  },
  {
    label: "VERNOUX-EN-VIVARAIS",
    value: "07338",
    type: "common",
  },
  {
    label: "CHATEAUNEUF-DE-VERNOUX",
    value: "07060",
    type: "common",
  },
  {
    label: "SAINT-JEAN-CHAMBRE",
    value: "07244",
    type: "common",
  },
  {
    label: "SAINT-APOLLINAIRE-DE-RIAS",
    value: "07214",
    type: "common",
  },
  {
    label: "SAINT-PRIX",
    value: "07290",
    type: "common",
  },
  {
    label: "SAINT-MICHEL-D'AURANCE",
    value: "07276",
    type: "common",
  },
  {
    label: "SAINT-CIERGE-SOUS-LE-CHEYLARD",
    value: "07222",
    type: "common",
  },
  {
    label: "SAINT-MARTIN-DE-VALAMAS",
    value: "07269",
    type: "common",
  },
  {
    label: "JAUNAC",
    value: "07108",
    type: "common",
  },
  {
    label: "LE CHEYLARD",
    value: "07064",
    type: "common",
  },
  {
    label: "CHANEAC",
    value: "07054",
    type: "common",
  },
  {
    label: "ARCENS",
    value: "07012",
    type: "common",
  },
  {
    label: "CHAUDEYROLLES",
    value: "43066",
    type: "common",
  },
  {
    label: "SAINT-CLEMENT",
    value: "07226",
    type: "common",
  },
  {
    label: "LA ROCHETTE",
    value: "07195",
    type: "common",
  },
  {
    label: "BOREE",
    value: "07037",
    type: "common",
  },
  {
    label: "LES ESTABLES",
    value: "43091",
    type: "common",
  },
  {
    label: "MOUDEYRES",
    value: "43144",
    type: "common",
  },
  {
    label: "FREYCENET-LA-TOUR",
    value: "43098",
    type: "common",
  },
  {
    label: "FREYCENET-LA-CUCHE",
    value: "43097",
    type: "common",
  },
  {
    label: "SAINT-MARTIN-DE-FUGERES",
    value: "43210",
    type: "common",
  },
  {
    label: "LE MONASTIER-SUR-GAZEILLE",
    value: "43135",
    type: "common",
  },
  {
    label: "CHADRON",
    value: "43047",
    type: "common",
  },
  {
    label: "LE BRIGNON",
    value: "43039",
    type: "common",
  },
  {
    label: "SENEUJOLS",
    value: "43238",
    type: "common",
  },
  {
    label: "CAYRES",
    value: "43042",
    type: "common",
  },
  {
    label: "SAINT-JEAN-LACHALM",
    value: "43198",
    type: "common",
  },
  {
    label: "OUIDES",
    value: "43145",
    type: "common",
  },
  {
    label: "SAINT-PREJET-D'ALLIER",
    value: "43220",
    type: "common",
  },
  {
    label: "MONISTROL-D'ALLIER",
    value: "43136",
    type: "common",
  },
  {
    label: "ALLEYRAS",
    value: "43005",
    type: "common",
  },
  {
    label: "SAUGUES",
    value: "43234",
    type: "common",
  },
  {
    label: "ESPLANTAS-VAZEILLES",
    value: "43090",
    type: "common",
  },
  {
    label: "GREZES",
    value: "43104",
    type: "common",
  },
  {
    label: "SAINT-PRIVAT-DU-FAU",
    value: "48179",
    type: "common",
  },
  {
    label: "PAULHAC-EN-MARGERIDE",
    value: "48110",
    type: "common",
  },
  {
    label: "LA BESSEYRE-SAINT-MARY",
    value: "43029",
    type: "common",
  },
  {
    label: "JULIANGES",
    value: "48077",
    type: "common",
  },
  {
    label: "LORCIERES",
    value: "15107",
    type: "common",
  },
  {
    label: "CHAULHAC",
    value: "48046",
    type: "common",
  },
  {
    label: "VAL D'ARCOMIE",
    value: "15108",
    type: "common",
  },
  {
    label: "FRIDEFONT",
    value: "15073",
    type: "common",
  },
  {
    label: "ALLEUZE",
    value: "15002",
    type: "common",
  },
  {
    label: "NEUVEGLISE-SUR-TRUYERE",
    value: "15142",
    type: "common",
  },
  {
    label: "GOURDIEGES",
    value: "15077",
    type: "common",
  },
  {
    label: "SAINT-MARTIN-SOUS-VIGOUROUX",
    value: "15201",
    type: "common",
  },
  {
    label: "PIERREFORT",
    value: "15152",
    type: "common",
  },
  {
    label: "PAILHEROLS",
    value: "15146",
    type: "common",
  },
  {
    label: "NARNHAC",
    value: "15139",
    type: "common",
  },
  {
    label: "LACAPELLE-BARRES",
    value: "15086",
    type: "common",
  },
  {
    label: "JOU-SOUS-MONJOU",
    value: "15081",
    type: "common",
  },
  {
    label: "BADAILHAC",
    value: "15017",
    type: "common",
  },
  {
    label: "YOLET",
    value: "15266",
    type: "common",
  },
  {
    label: "SAINT-ETIENNE-DE-CARLAT",
    value: "15183",
    type: "common",
  },
  {
    label: "POLMINHAC",
    value: "15154",
    type: "common",
  },
  {
    label: "SAINT-SIMON",
    value: "15215",
    type: "common",
  },
  {
    label: "GIOU-DE-MAMOU",
    value: "15074",
    type: "common",
  },
  {
    label: "AURILLAC",
    value: "15014",
    type: "common",
  },
  {
    label: "REILHAC",
    value: "15160",
    type: "common",
  },
  {
    label: "NAUCELLES",
    value: "15140",
    type: "common",
  },
  {
    label: "YTRAC",
    value: "15267",
    type: "common",
  },
  {
    label: "SAINT-PAUL-DES-LANDES",
    value: "15204",
    type: "common",
  },
  {
    label: "LACAPELLE-VIESCAMP",
    value: "15088",
    type: "common",
  },
  {
    label: "CRANDELLES",
    value: "15056",
    type: "common",
  },
  {
    label: "SAINT-GERONS",
    value: "15189",
    type: "common",
  },
  {
    label: "SAINT-ETIENNE-CANTALES",
    value: "15182",
    type: "common",
  },
  {
    label: "SIRAN",
    value: "15228",
    type: "common",
  },
  {
    label: "TEYSSIEU",
    value: "46315",
    type: "common",
  },
  {
    label: "LAVAL-DE-CERE",
    value: "46163",
    type: "common",
  },
  {
    label: "GLANES",
    value: "46124",
    type: "common",
  },
  {
    label: "GAGNAC-SUR-CERE",
    value: "46117",
    type: "common",
  },
  {
    label: "ESTAL",
    value: "46097",
    type: "common",
  },
  {
    label: "CAHUS",
    value: "46043",
    type: "common",
  },
  {
    label: "PUYBRUN",
    value: "46229",
    type: "common",
  },
  {
    label: "GIRAC",
    value: "46123",
    type: "common",
  },
  {
    label: "BRETENOUX",
    value: "46038",
    type: "common",
  },
  {
    label: "BIARS-SUR-CERE",
    value: "46029",
    type: "common",
  },
  {
    label: "LIOURDRES",
    value: "19116",
    type: "common",
  },
  {
    label: "ASTAILLAC",
    value: "19012",
    type: "common",
  },
  {
    label: "CARENNAC",
    value: "46058",
    type: "common",
  },
  {
    label: "BETAILLE",
    value: "46028",
    type: "common",
  },
  {
    label: "QUEYSSAC-LES-VIGNES",
    value: "19170",
    type: "common",
  },
  {
    label: "BILHAC",
    value: "19026",
    type: "common",
  },
  {
    label: "VAYRAC",
    value: "46330",
    type: "common",
  },
  {
    label: "SAINT-DENIS-LES-MARTEL",
    value: "46265",
    type: "common",
  },
  {
    label: "MARTEL",
    value: "46185",
    type: "common",
  },
  {
    label: "CUZANCE",
    value: "46086",
    type: "common",
  },
  {
    label: "BALADOU",
    value: "46016",
    type: "common",
  },
  {
    label: "LACHAPELLE-AUZAC",
    value: "46145",
    type: "common",
  },
  {
    label: "ORLIAGUET",
    value: "24314",
    type: "common",
  },
  {
    label: "SALIGNAC-EYVIGUES",
    value: "24516",
    type: "common",
  },
  {
    label: "SIMEYROLS",
    value: "24535",
    type: "common",
  },
  {
    label: "SAINTE-NATHALENE",
    value: "24471",
    type: "common",
  },
  {
    label: "SAINT-CREPIN-ET-CARLUCET",
    value: "24392",
    type: "common",
  },
  {
    label: "PROISSANS",
    value: "24341",
    type: "common",
  },
  {
    label: "MARCILLAC-SAINT-QUENTIN",
    value: "24252",
    type: "common",
  },
  {
    label: "MARQUAY",
    value: "24255",
    type: "common",
  },
  {
    label: "MEYRALS",
    value: "24268",
    type: "common",
  },
  {
    label: "LE BUGUE",
    value: "24067",
    type: "common",
  },
  {
    label: "SAINT-AVIT-DE-VIALARD",
    value: "24377",
    type: "common",
  },
  {
    label: "VAL DE LOUYRE ET CAUDEAU",
    value: "24362",
    type: "common",
  },
  {
    label: "PEZULS",
    value: "24327",
    type: "common",
  },
  {
    label: "PAUNAT",
    value: "24318",
    type: "common",
  },
  {
    label: "SAINT-MARCEL-DU-PERIGORD",
    value: "24445",
    type: "common",
  },
  {
    label: "SAINTE-FOY-DE-LONGAS",
    value: "24407",
    type: "common",
  },
  {
    label: "SAINT-FELIX-DE-VILLADEIX",
    value: "24405",
    type: "common",
  },
  {
    label: "LIORAC-SUR-LOUYRE",
    value: "24242",
    type: "common",
  },
  {
    label: "CLERMONT-DE-BEAUREGARD",
    value: "24123",
    type: "common",
  },
  {
    label: "SAINT-GEORGES-DE-MONTCLARD",
    value: "24414",
    type: "common",
  },
  {
    label: "LAMONZIE-MONTASTRUC",
    value: "24224",
    type: "common",
  },
  {
    label: "CAMPSEGRET",
    value: "24077",
    type: "common",
  },
  {
    label: "QUEYSSAC",
    value: "24345",
    type: "common",
  },
  {
    label: "LUNAS",
    value: "24246",
    type: "common",
  },
  {
    label: "GINESTET",
    value: "24197",
    type: "common",
  },
  {
    label: "SAINT-GEORGES-BLANCANEIX",
    value: "24413",
    type: "common",
  },
  {
    label: "FRAISSE",
    value: "24191",
    type: "common",
  },
  {
    label: "SAINT-GERAUD-DE-CORPS",
    value: "24415",
    type: "common",
  },
  {
    label: "MONFAUCON",
    value: "24277",
    type: "common",
  },
  {
    label: "SAINT-MEARD-DE-GURCON",
    value: "24461",
    type: "common",
  },
  {
    label: "MONTAZEAU",
    value: "24288",
    type: "common",
  },
  {
    label: "SAINT-VIVIEN",
    value: "24514",
    type: "common",
  },
  {
    label: "MONTPEYROUX",
    value: "24292",
    type: "common",
  },
  {
    label: "CARSAC-DE-GURSON",
    value: "24083",
    type: "common",
  },
  {
    label: "LES SALLES-DE-CASTILLON",
    value: "33499",
    type: "common",
  },
  {
    label: "SAINT-PHILIPPE-D'AIGUILLE",
    value: "33461",
    type: "common",
  },
  {
    label: "SAINT-CIBARD",
    value: "33386",
    type: "common",
  },
  {
    label: "GARDEGAN-ET-TOURTIRAC",
    value: "33181",
    type: "common",
  },
  {
    label: "FRANCS",
    value: "33173",
    type: "common",
  },
  {
    label: "SAINT-GENES-DE-CASTILLON",
    value: "33406",
    type: "common",
  },
  {
    label: "SAINT-CHRISTOPHE-DES-BARDES",
    value: "33384",
    type: "common",
  },
  {
    label: "PUISSEGUIN",
    value: "33342",
    type: "common",
  },
  {
    label: "MONTAGNE",
    value: "33290",
    type: "common",
  },
  {
    label: "SAINT-EMILION",
    value: "33394",
    type: "common",
  },
  {
    label: "POMEROL",
    value: "33328",
    type: "common",
  },
  {
    label: "LIBOURNE",
    value: "33243",
    type: "common",
  },
  {
    label: "FRONSAC",
    value: "33174",
    type: "common",
  },
  {
    label: "ARVEYRES",
    value: "33015",
    type: "common",
  },
  {
    label: "VAYRES",
    value: "33539",
    type: "common",
  },
  {
    label: "SAINT-MICHEL-DE-FRONSAC",
    value: "33451",
    type: "common",
  },
  {
    label: "IZON",
    value: "33207",
    type: "common",
  },
  {
    label: "SAINT-SULPICE-ET-CAMEYRAC",
    value: "33483",
    type: "common",
  },
  {
    label: "SAINT-LOUBES",
    value: "33433",
    type: "common",
  },
  {
    label: "MONTUSSAN",
    value: "33293",
    type: "common",
  },
  {
    label: "YVRAC",
    value: "33554",
    type: "common",
  },
  {
    label: "SAINTE-EULALIE",
    value: "33397",
    type: "common",
  },
  {
    label: "LORMONT",
    value: "33249",
    type: "common",
  },
  {
    label: "CARBON-BLANC",
    value: "33096",
    type: "common",
  },
  {
    label: "BASSENS",
    value: "33032",
    type: "common",
  },
  {
    label: "BRUGES",
    value: "33075",
    type: "common",
  },
  {
    label: "BLANQUEFORT",
    value: "33056",
    type: "common",
  },
  {
    label: "LE TAILLAN-MEDOC",
    value: "33519",
    type: "common",
  },
  {
    label: "EYSINES",
    value: "33162",
    type: "common",
  },
  {
    label: "SAINT-MEDARD-EN-JALLES",
    value: "33449",
    type: "common",
  },
  {
    label: "LE TEMPLE",
    value: "33528",
    type: "common",
  },
  {
    label: "SAUMOS",
    value: "33503",
    type: "common",
  },
  {
    label: "LE PORGE",
    value: "33333",
    type: "common",
  },
  {
    label: "AIGUILLES",
    value: "05003",
    type: "common",
  },
  {
    label: "CHATEAU-VILLE-VIEILLE",
    value: "05038",
    type: "common",
  },
  {
    label: "ARVIEUX",
    value: "05007",
    type: "common",
  },
  {
    label: "LES VIGNEAUX",
    value: "05180",
    type: "common",
  },
  {
    label: "PUY-SAINT-VINCENT",
    value: "05110",
    type: "common",
  },
  {
    label: "L'ARGENTIERE-LA-BESSEE",
    value: "05006",
    type: "common",
  },
  {
    label: "LA CHAPELLE-EN-VALGAUDEMAR",
    value: "05064",
    type: "common",
  },
  {
    label: "VILLAR-LOUBIERE",
    value: "05182",
    type: "common",
  },
  {
    label: "SAINT-MAURICE-EN-VALGODEMARD",
    value: "05152",
    type: "common",
  },
  {
    label: "LA SALETTE-FALLAVAUX",
    value: "38469",
    type: "common",
  },
  {
    label: "LES COTES-DE-CORPS",
    value: "38132",
    type: "common",
  },
  {
    label: "CORPS",
    value: "38128",
    type: "common",
  },
  {
    label: "AMBEL",
    value: "38008",
    type: "common",
  },
  {
    label: "ASPRES-LES-CORPS",
    value: "05009",
    type: "common",
  },
  {
    label: "SAINTE-LUCE",
    value: "38414",
    type: "common",
  },
  {
    label: "QUET-EN-BEAUMONT",
    value: "38329",
    type: "common",
  },
  {
    label: "CHATEL-EN-TRIEVES",
    value: "38456",
    type: "common",
  },
  {
    label: "MENS",
    value: "38226",
    type: "common",
  },
  {
    label: "SAINT-JEAN-D'HERANS",
    value: "38403",
    type: "common",
  },
  {
    label: "LAVARS",
    value: "38208",
    type: "common",
  },
  {
    label: "CORNILLON-EN-TRIEVES",
    value: "38127",
    type: "common",
  },
  {
    label: "SAINT-MARTIN-DE-CLELLES",
    value: "38419",
    type: "common",
  },
  {
    label: "CLELLES",
    value: "38113",
    type: "common",
  },
  {
    label: "CHICHILIANNE",
    value: "38103",
    type: "common",
  },
  {
    label: "CHAMALOC",
    value: "26069",
    type: "common",
  },
  {
    label: "SAINT-JULIEN-EN-QUINT",
    value: "26308",
    type: "common",
  },
  {
    label: "PLAN-DE-BAIX",
    value: "26240",
    type: "common",
  },
  {
    label: "OMBLEZE",
    value: "26221",
    type: "common",
  },
  {
    label: "COMBOVIN",
    value: "26100",
    type: "common",
  },
  {
    label: "LE CHAFFAL",
    value: "26066",
    type: "common",
  },
  {
    label: "LA BAUME-CORNILLANE",
    value: "26032",
    type: "common",
  },
  {
    label: "BARCELONNE",
    value: "26024",
    type: "common",
  },
  {
    label: "MONTVENDRE",
    value: "26212",
    type: "common",
  },
  {
    label: "MONTMEYRAN",
    value: "26206",
    type: "common",
  },
  {
    label: "MONTELEGER",
    value: "26196",
    type: "common",
  },
  {
    label: "BEAUMONT-LES-VALENCE",
    value: "26037",
    type: "common",
  },
  {
    label: "PORTES-LES-VALENCE",
    value: "26252",
    type: "common",
  },
  {
    label: "ETOILE-SUR-RHONE",
    value: "26124",
    type: "common",
  },
  {
    label: "BEAUVALLON",
    value: "26042",
    type: "common",
  },
  {
    label: "SAINT-GEORGES-LES-BAINS",
    value: "07240",
    type: "common",
  },
  {
    label: "CHARMES-SUR-RHONE",
    value: "07055",
    type: "common",
  },
  {
    label: "BEAUCHASTEL",
    value: "07027",
    type: "common",
  },
  {
    label: "SAINT-FORTUNAT-SUR-EYRIEUX",
    value: "07237",
    type: "common",
  },
  {
    label: "GILHAC-ET-BRUZAC",
    value: "07094",
    type: "common",
  },
  {
    label: "DUNIERE-SUR-EYRIEUX",
    value: "07083",
    type: "common",
  },
  {
    label: "SILHAC",
    value: "07314",
    type: "common",
  },
  {
    label: "SAINT-MICHEL-DE-CHABRILLANOUX",
    value: "07278",
    type: "common",
  },
  {
    label: "SAINT-JULIEN-LE-ROUX",
    value: "07257",
    type: "common",
  },
  {
    label: "SAINT-MAURICE-EN-CHALENCON",
    value: "07274",
    type: "common",
  },
  {
    label: "GLUIRAS",
    value: "07096",
    type: "common",
  },
  {
    label: "CHALENCON",
    value: "07048",
    type: "common",
  },
  {
    label: "SAINT-CHRISTOL",
    value: "07220",
    type: "common",
  },
  {
    label: "SAINT-BARTHELEMY-LE-MEIL",
    value: "07215",
    type: "common",
  },
  {
    label: "BEAUVENE",
    value: "07030",
    type: "common",
  },
  {
    label: "SAINT-GENEST-LACHAMP",
    value: "07239",
    type: "common",
  },
  {
    label: "DORNAS",
    value: "07082",
    type: "common",
  },
  {
    label: "ACCONS",
    value: "07001",
    type: "common",
  },
  {
    label: "SAINT-ANDEOL-DE-FOURCHADES",
    value: "07209",
    type: "common",
  },
  {
    label: "MARIAC",
    value: "07150",
    type: "common",
  },
  {
    label: "SAINT-MARTIAL",
    value: "07267",
    type: "common",
  },
  {
    label: "LE BEAGE",
    value: "07026",
    type: "common",
  },
  {
    label: "PRESAILLES",
    value: "43156",
    type: "common",
  },
  {
    label: "ISSARLES",
    value: "07106",
    type: "common",
  },
  {
    label: "VIELPRAT",
    value: "43263",
    type: "common",
  },
  {
    label: "SALETTES",
    value: "43231",
    type: "common",
  },
  {
    label: "ALLEYRAC",
    value: "43004",
    type: "common",
  },
  {
    label: "GOUDET",
    value: "43101",
    type: "common",
  },
  {
    label: "ARLEMPDES",
    value: "43008",
    type: "common",
  },
  {
    label: "SAINT-HAON",
    value: "43192",
    type: "common",
  },
  {
    label: "LANDOS",
    value: "43111",
    type: "common",
  },
  {
    label: "COSTAROS",
    value: "43077",
    type: "common",
  },
  {
    label: "LE BOUCHET-SAINT-NICOLAS",
    value: "43037",
    type: "common",
  },
  {
    label: "SAINT-VENERAND",
    value: "43225",
    type: "common",
  },
  {
    label: "THORAS",
    value: "43245",
    type: "common",
  },
  {
    label: "CHANALEILLES",
    value: "43054",
    type: "common",
  },
  {
    label: "LE MALZIEU-FORAIN",
    value: "48089",
    type: "common",
  },
  {
    label: "SAINT-LEGER-DU-MALZIEU",
    value: "48169",
    type: "common",
  },
  {
    label: "LE MALZIEU-VILLE",
    value: "48090",
    type: "common",
  },
  {
    label: "BLAVIGNAC",
    value: "48026",
    type: "common",
  },
  {
    label: "ALBARET-SAINTE-MARIE",
    value: "48002",
    type: "common",
  },
  {
    label: "ALBARET-LE-COMTAL",
    value: "48001",
    type: "common",
  },
  {
    label: "SAINT-MARTIAL",
    value: "15199",
    type: "common",
  },
  {
    label: "MAURINES",
    value: "15121",
    type: "common",
  },
  {
    label: "SAINTE-MARIE",
    value: "15198",
    type: "common",
  },
  {
    label: "ESPINASSE",
    value: "15065",
    type: "common",
  },
  {
    label: "PAULHENC",
    value: "15149",
    type: "common",
  },
  {
    label: "THERONDELS",
    value: "12280",
    type: "common",
  },
  {
    label: "RAULHAC",
    value: "15159",
    type: "common",
  },
  {
    label: "MUR-DE-BARREZ",
    value: "12164",
    type: "common",
  },
  {
    label: "LABROUSSE",
    value: "15085",
    type: "common",
  },
  {
    label: "CROS-DE-RONESQUE",
    value: "15058",
    type: "common",
  },
  {
    label: "CARLAT",
    value: "15028",
    type: "common",
  },
  {
    label: "VEZAC",
    value: "15255",
    type: "common",
  },
  {
    label: "ARPAJON-SUR-CERE",
    value: "15012",
    type: "common",
  },
  {
    label: "SANSAC-DE-MARMIESSE",
    value: "15221",
    type: "common",
  },
  {
    label: "LE ROUGET-PERS",
    value: "15268",
    type: "common",
  },
  {
    label: "LA SEGALASSIERE",
    value: "15224",
    type: "common",
  },
  {
    label: "OMPS",
    value: "15144",
    type: "common",
  },
  {
    label: "SAINT-SAURY",
    value: "15214",
    type: "common",
  },
  {
    label: "GLENAT",
    value: "15076",
    type: "common",
  },
  {
    label: "SOUSCEYRAC-EN-QUERCY",
    value: "46311",
    type: "common",
  },
  {
    label: "SAINT-LAURENT-LES-TOURS",
    value: "46273",
    type: "common",
  },
  {
    label: "FRAYSSINHES",
    value: "46115",
    type: "common",
  },
  {
    label: "CORNAC",
    value: "46076",
    type: "common",
  },
  {
    label: "BELMONT-BRETENOUX",
    value: "46024",
    type: "common",
  },
  {
    label: "SAINT-MICHEL-LOUBEJOU",
    value: "46284",
    type: "common",
  },
  {
    label: "SAINT-MEDARD-DE-PRESQUE",
    value: "46281",
    type: "common",
  },
  {
    label: "SAINT-JEAN-LESPINASSE",
    value: "46271",
    type: "common",
  },
  {
    label: "PRUDHOMAT",
    value: "46228",
    type: "common",
  },
  {
    label: "LOUBRESSAC",
    value: "46177",
    type: "common",
  },
  {
    label: "AUTOIRE",
    value: "46011",
    type: "common",
  },
  {
    label: "TAURIAC",
    value: "46313",
    type: "common",
  },
  {
    label: "PADIRAC",
    value: "46213",
    type: "common",
  },
  {
    label: "MIERS",
    value: "46193",
    type: "common",
  },
  {
    label: "GINTRAC",
    value: "46122",
    type: "common",
  },
  {
    label: "MONTVALENT",
    value: "46208",
    type: "common",
  },
  {
    label: "FLOIRAC",
    value: "46106",
    type: "common",
  },
  {
    label: "MAYRAC",
    value: "46337",
    type: "common",
  },
  {
    label: "SAINT-SOZY",
    value: "46293",
    type: "common",
  },
  {
    label: "MEYRONNE",
    value: "46192",
    type: "common",
  },
  {
    label: "CREYSSE",
    value: "46084",
    type: "common",
  },
  {
    label: "SOUILLAC",
    value: "46309",
    type: "common",
  },
  {
    label: "PINSAC",
    value: "46220",
    type: "common",
  },
  {
    label: "LANZAC",
    value: "46153",
    type: "common",
  },
  {
    label: "LE ROC",
    value: "46239",
    type: "common",
  },
  {
    label: "NADAILLAC-DE-ROUGE",
    value: "46209",
    type: "common",
  },
  {
    label: "SAINT-JULIEN-DE-LAMPON",
    value: "24432",
    type: "common",
  },
  {
    label: "PEYRILLAC-ET-MILLAC",
    value: "24325",
    type: "common",
  },
  {
    label: "CAZOULES",
    value: "24089",
    type: "common",
  },
  {
    label: "PRATS-DE-CARLUX",
    value: "24336",
    type: "common",
  },
  {
    label: "CARLUX",
    value: "24081",
    type: "common",
  },
  {
    label: "CALVIAC-EN-PERIGORD",
    value: "24074",
    type: "common",
  },
  {
    label: "SARLAT-LA-CANEDA",
    value: "24520",
    type: "common",
  },
  {
    label: "SAINT-VINCENT-LE-PALUEL",
    value: "24512",
    type: "common",
  },
  {
    label: "SAINT-ANDRE-D'ALLAS",
    value: "24366",
    type: "common",
  },
  {
    label: "BEYNAC-ET-CAZENAC",
    value: "24040",
    type: "common",
  },
  {
    label: "SAINT-CYPRIEN",
    value: "24396",
    type: "common",
  },
  {
    label: "CASTELS ET BEZENAC",
    value: "24087",
    type: "common",
  },
  {
    label: "CAMPAGNE",
    value: "24076",
    type: "common",
  },
  {
    label: "SAINT-CHAMASSY",
    value: "24388",
    type: "common",
  },
  {
    label: "LIMEUIL",
    value: "24240",
    type: "common",
  },
  {
    label: "AUDRIX",
    value: "24015",
    type: "common",
  },
  {
    label: "ALLES-SUR-DORDOGNE",
    value: "24005",
    type: "common",
  },
  {
    label: "TREMOLAT",
    value: "24558",
    type: "common",
  },
  {
    label: "CALES",
    value: "24073",
    type: "common",
  },
  {
    label: "PRESSIGNAC-VICQ",
    value: "24338",
    type: "common",
  },
  {
    label: "MAUZAC-ET-GRAND-CASTANG",
    value: "24260",
    type: "common",
  },
  {
    label: "LALINDE",
    value: "24223",
    type: "common",
  },
  {
    label: "SAINT-CAPRAISE-DE-LALINDE",
    value: "24382",
    type: "common",
  },
  {
    label: "MOULEYDIER",
    value: "24296",
    type: "common",
  },
  {
    label: "CAUSE-DE-CLERANS",
    value: "24088",
    type: "common",
  },
  {
    label: "BANEUIL",
    value: "24023",
    type: "common",
  },
  {
    label: "SAINT-SAUVEUR",
    value: "24499",
    type: "common",
  },
  {
    label: "CREYSSE",
    value: "24145",
    type: "common",
  },
  {
    label: "LEMBRAS",
    value: "24237",
    type: "common",
  },
  {
    label: "BERGERAC",
    value: "24037",
    type: "common",
  },
  {
    label: "PRIGONRIEUX",
    value: "24340",
    type: "common",
  },
  {
    label: "LAMONZIE-SAINT-MARTIN",
    value: "24225",
    type: "common",
  },
  {
    label: "SAINT-PIERRE-D'EYRAUD",
    value: "24487",
    type: "common",
  },
  {
    label: "LA FORCE",
    value: "24222",
    type: "common",
  },
  {
    label: "SAINTE-FOY-LA-GRANDE",
    value: "33402",
    type: "common",
  },
  {
    label: "SAINT-AVIT-SAINT-NAZAIRE",
    value: "33378",
    type: "common",
  },
  {
    label: "LE FLEIX",
    value: "24182",
    type: "common",
  },
  {
    label: "SAINT-ANTOINE-DE-BREUILH",
    value: "24370",
    type: "common",
  },
  {
    label: "PORT-SAINTE-FOY-ET-PONCHAPT",
    value: "24335",
    type: "common",
  },
  {
    label: "NASTRINGUES",
    value: "24306",
    type: "common",
  },
  {
    label: "FOUGUEYROLLES",
    value: "24189",
    type: "common",
  },
  {
    label: "VELINES",
    value: "24568",
    type: "common",
  },
  {
    label: "SAINT-SEURIN-DE-PRATS",
    value: "24501",
    type: "common",
  },
  {
    label: "MONTCARET",
    value: "24289",
    type: "common",
  },
  {
    label: "BONNEVILLE-ET-SAINT-AVIT-DE-FUMADIERES",
    value: "24048",
    type: "common",
  },
  {
    label: "MOULIETS-ET-VILLEMARTIN",
    value: "33296",
    type: "common",
  },
  {
    label: "FLAUJAGUES",
    value: "33168",
    type: "common",
  },
  {
    label: "CASTILLON-LA-BATAILLE",
    value: "33108",
    type: "common",
  },
  {
    label: "BELVES-DE-CASTILLON",
    value: "33045",
    type: "common",
  },
  {
    label: "SAINT-MICHEL-DE-MONTAIGNE",
    value: "24466",
    type: "common",
  },
  {
    label: "LAMOTHE-MONTRAVEL",
    value: "24226",
    type: "common",
  },
  {
    label: "CIVRAC-SUR-DORDOGNE",
    value: "33127",
    type: "common",
  },
  {
    label: "SAINTE-TERRE",
    value: "33485",
    type: "common",
  },
  {
    label: "SAINT-PEY-D'ARMENS",
    value: "33459",
    type: "common",
  },
  {
    label: "SAINT-MAGNE-DE-CASTILLON",
    value: "33437",
    type: "common",
  },
  {
    label: "SAINT-HIPPOLYTE",
    value: "33420",
    type: "common",
  },
  {
    label: "SAINT-ETIENNE-DE-LISSE",
    value: "33396",
    type: "common",
  },
  {
    label: "SAINTE-COLOMBE",
    value: "33390",
    type: "common",
  },
  {
    label: "VIGNONET",
    value: "33546",
    type: "common",
  },
  {
    label: "SAINT-SULPICE-DE-FALEYRENS",
    value: "33480",
    type: "common",
  },
  {
    label: "SAINT-LAURENT-DES-COMBES",
    value: "33426",
    type: "common",
  },
  {
    label: "CABARA",
    value: "33078",
    type: "common",
  },
  {
    label: "BRANNE",
    value: "33071",
    type: "common",
  },
  {
    label: "TIZAC-DE-CURTON",
    value: "33531",
    type: "common",
  },
  {
    label: "NERIGEAN",
    value: "33303",
    type: "common",
  },
  {
    label: "MOULON",
    value: "33298",
    type: "common",
  },
  {
    label: "GENISSAC",
    value: "33185",
    type: "common",
  },
  {
    label: "CADARSAC",
    value: "33079",
    type: "common",
  },
  {
    label: "SAINT-GERMAIN-DU-PUCH",
    value: "33413",
    type: "common",
  },
  {
    label: "CROIGNON",
    value: "33141",
    type: "common",
  },
  {
    label: "CAMARSAC",
    value: "33083",
    type: "common",
  },
  {
    label: "BEYCHAC-ET-CAILLAU",
    value: "33049",
    type: "common",
  },
  {
    label: "BARON",
    value: "33028",
    type: "common",
  },
  {
    label: "SALLEBŒUF",
    value: "33496",
    type: "common",
  },
  {
    label: "POMPIGNAC",
    value: "33330",
    type: "common",
  },
  {
    label: "FARGUES-SAINT-HILAIRE",
    value: "33165",
    type: "common",
  },
  {
    label: "TRESSES",
    value: "33535",
    type: "common",
  },
  {
    label: "FLOIRAC",
    value: "33167",
    type: "common",
  },
  {
    label: "CENON",
    value: "33119",
    type: "common",
  },
  {
    label: "BOULIAC",
    value: "33065",
    type: "common",
  },
  {
    label: "ARTIGUES-PRES-BORDEAUX",
    value: "33013",
    type: "common",
  },
  {
    label: "LE BOUSCAT",
    value: "33069",
    type: "common",
  },
  {
    label: "BORDEAUX",
    value: "33063",
    type: "common",
  },
  {
    label: "MERIGNAC",
    value: "33281",
    type: "common",
  },
  {
    label: "LE HAILLAN",
    value: "33200",
    type: "common",
  },
  {
    label: "MARTIGNAS-SUR-JALLE",
    value: "33273",
    type: "common",
  },
  {
    label: "MOLINES-EN-QUEYRAS",
    value: "05077",
    type: "common",
  },
  {
    label: "SAINT-CREPIN",
    value: "05136",
    type: "common",
  },
  {
    label: "LA ROCHE-DE-RAME",
    value: "05122",
    type: "common",
  },
  {
    label: "CHAMPCELLA",
    value: "05031",
    type: "common",
  },
  {
    label: "FREISSINIERES",
    value: "05058",
    type: "common",
  },
  {
    label: "CHAMPOLEON",
    value: "05032",
    type: "common",
  },
  {
    label: "LA MOTTE-EN-CHAMPSAUR",
    value: "05090",
    type: "common",
  },
  {
    label: "SAINT-JACQUES-EN-VALGODEMARD",
    value: "05144",
    type: "common",
  },
  {
    label: "SAINT-FIRMIN",
    value: "05142",
    type: "common",
  },
  {
    label: "AUBESSAGNE",
    value: "05039",
    type: "common",
  },
  {
    label: "MONESTIER-D'AMBEL",
    value: "38241",
    type: "common",
  },
  {
    label: "BEAUFIN",
    value: "38031",
    type: "common",
  },
  {
    label: "LE GLAIZIL",
    value: "05062",
    type: "common",
  },
  {
    label: "PELLAFOL",
    value: "38299",
    type: "common",
  },
  {
    label: "SAINT-BAUDILLE-ET-PIPET",
    value: "38366",
    type: "common",
  },
  {
    label: "PREBOIS",
    value: "38321",
    type: "common",
  },
  {
    label: "SAINT-MAURICE-EN-TRIEVES",
    value: "38424",
    type: "common",
  },
  {
    label: "PERCY",
    value: "38301",
    type: "common",
  },
  {
    label: "LE MONESTIER-DU-PERCY",
    value: "38243",
    type: "common",
  },
  {
    label: "ROMEYER",
    value: "26282",
    type: "common",
  },
  {
    label: "LAVAL-D'AIX",
    value: "26159",
    type: "common",
  },
  {
    label: "DIE",
    value: "26113",
    type: "common",
  },
  {
    label: "SAINTE-CROIX",
    value: "26299",
    type: "common",
  },
  {
    label: "SAINT-ANDEOL",
    value: "26291",
    type: "common",
  },
  {
    label: "PONTAIX",
    value: "26248",
    type: "common",
  },
  {
    label: "PONET-ET-SAINT-AUBAN",
    value: "26246",
    type: "common",
  },
  {
    label: "MARIGNAC-EN-DIOIS",
    value: "26175",
    type: "common",
  },
  {
    label: "VACHERES-EN-QUINT",
    value: "26359",
    type: "common",
  },
  {
    label: "EYGLUY-ESCOULIN",
    value: "26128",
    type: "common",
  },
  {
    label: "SUZE",
    value: "26346",
    type: "common",
  },
  {
    label: "GIGORS-ET-LOZERON",
    value: "26141",
    type: "common",
  },
  {
    label: "BEAUFORT-SUR-GERVANNE",
    value: "26035",
    type: "common",
  },
  {
    label: "VAUNAVEYS-LA-ROCHETTE",
    value: "26365",
    type: "common",
  },
  {
    label: "OURCHES",
    value: "26224",
    type: "common",
  },
  {
    label: "UPIE",
    value: "26358",
    type: "common",
  },
  {
    label: "MONTOISON",
    value: "26208",
    type: "common",
  },
  {
    label: "LIVRON-SUR-DROME",
    value: "26165",
    type: "common",
  },
  {
    label: "AMBONIL",
    value: "26007",
    type: "common",
  },
  {
    label: "LA VOULTE-SUR-RHONE",
    value: "07349",
    type: "common",
  },
  {
    label: "SAINT-LAURENT-DU-PAPE",
    value: "07261",
    type: "common",
  },
  {
    label: "SAINT-CIERGE-LA-SERRE",
    value: "07221",
    type: "common",
  },
  {
    label: "ROMPON",
    value: "07198",
    type: "common",
  },
  {
    label: "SAINT-VINCENT-DE-DURFORT",
    value: "07303",
    type: "common",
  },
  {
    label: "LES OLLIERES-SUR-EYRIEUX",
    value: "07167",
    type: "common",
  },
  {
    label: "SAINT-SAUVEUR-DE-MONTAGUT",
    value: "07295",
    type: "common",
  },
  {
    label: "SAINT-ETIENNE-DE-SERRE",
    value: "07233",
    type: "common",
  },
  {
    label: "PRANLES",
    value: "07184",
    type: "common",
  },
  {
    label: "SAINT-PIERREVILLE",
    value: "07286",
    type: "common",
  },
  {
    label: "ISSAMOULENC",
    value: "07104",
    type: "common",
  },
  {
    label: "ALBON-D'ARDECHE",
    value: "07006",
    type: "common",
  },
  {
    label: "MARCOLS-LES-EAUX",
    value: "07149",
    type: "common",
  },
  {
    label: "MEZILHAC",
    value: "07158",
    type: "common",
  },
  {
    label: "LACHAMP-RAPHAEL",
    value: "07120",
    type: "common",
  },
  {
    label: "LE CHAMBON",
    value: "07049",
    type: "common",
  },
  {
    label: "SAINTE-EULALIE",
    value: "07235",
    type: "common",
  },
  {
    label: "SAGNES-ET-GOUDOULET",
    value: "07203",
    type: "common",
  },
  {
    label: "PEREYRES",
    value: "07173",
    type: "common",
  },
  {
    label: "CROS-DE-GEORAND",
    value: "07075",
    type: "common",
  },
  {
    label: "LACHAPELLE-GRAILLOUSE",
    value: "07121",
    type: "common",
  },
  {
    label: "LE LAC-D'ISSARLES",
    value: "07119",
    type: "common",
  },
  {
    label: "LAFARRE",
    value: "43109",
    type: "common",
  },
  {
    label: "COUCOURON",
    value: "07071",
    type: "common",
  },
  {
    label: "SAINT-PAUL-DE-TARTAS",
    value: "43215",
    type: "common",
  },
  {
    label: "SAINT-ARCONS-DE-BARGES",
    value: "43168",
    type: "common",
  },
  {
    label: "BARGES",
    value: "43019",
    type: "common",
  },
  {
    label: "SAINT-ETIENNE-DU-VIGAN",
    value: "43180",
    type: "common",
  },
  {
    label: "RAURET",
    value: "43160",
    type: "common",
  },
  {
    label: "SAINT BONNET-LAVAL",
    value: "48139",
    type: "common",
  },
  {
    label: "SAINT-CHRISTOPHE-D'ALLIER",
    value: "43173",
    type: "common",
  },
  {
    label: "SAINT-PAUL-LE-FROID",
    value: "48174",
    type: "common",
  },
  {
    label: "SAINT-ALBAN-SUR-LIMAGNOLE",
    value: "48132",
    type: "common",
  },
  {
    label: "LAJO",
    value: "48079",
    type: "common",
  },
  {
    label: "SAINT-PIERRE-LE-VIEUX",
    value: "48177",
    type: "common",
  },
  {
    label: "SAINT-CHELY-D'APCHER",
    value: "48140",
    type: "common",
  },
  {
    label: "PRUNIERES",
    value: "48121",
    type: "common",
  },
  {
    label: "LA FAGE-SAINT-JULIEN",
    value: "48059",
    type: "common",
  },
  {
    label: "LES MONTS-VERTS",
    value: "48012",
    type: "common",
  },
  {
    label: "TERMES",
    value: "48190",
    type: "common",
  },
  {
    label: "FOURNELS",
    value: "48064",
    type: "common",
  },
  {
    label: "ARZENC-D'APCHER",
    value: "48007",
    type: "common",
  },
  {
    label: "SAINT-JUERY",
    value: "48161",
    type: "common",
  },
  {
    label: "NOALHAC",
    value: "48106",
    type: "common",
  },
  {
    label: "ANTERRIEUX",
    value: "15007",
    type: "common",
  },
  {
    label: "JABRUN",
    value: "15078",
    type: "common",
  },
  {
    label: "DEUX-VERGES",
    value: "15060",
    type: "common",
  },
  {
    label: "CHAUDES-AIGUES",
    value: "15045",
    type: "common",
  },
  {
    label: "LIEUTADES",
    value: "15106",
    type: "common",
  },
  {
    label: "CANTOIN",
    value: "12051",
    type: "common",
  },
  {
    label: "ARGENCES EN AUBRAC",
    value: "12223",
    type: "common",
  },
  {
    label: "BROMMAT",
    value: "12036",
    type: "common",
  },
  {
    label: "TAUSSAC",
    value: "12277",
    type: "common",
  },
  {
    label: "VEZELS-ROUSSY",
    value: "15257",
    type: "common",
  },
  {
    label: "TEISSIERES-LES-BOULIES",
    value: "15234",
    type: "common",
  },
  {
    label: "PRUNET",
    value: "15156",
    type: "common",
  },
  {
    label: "ROANNES-SAINT-MARY",
    value: "15163",
    type: "common",
  },
  {
    label: "LAFEUILLADE-EN-VEZIE",
    value: "15090",
    type: "common",
  },
  {
    label: "VITRAC",
    value: "15264",
    type: "common",
  },
  {
    label: "SAINT-MAMET-LA-SALVETAT",
    value: "15196",
    type: "common",
  },
  {
    label: "ROUZIERS",
    value: "15167",
    type: "common",
  },
  {
    label: "ROUMEGOUX",
    value: "15166",
    type: "common",
  },
  {
    label: "CAYROLS",
    value: "15030",
    type: "common",
  },
  {
    label: "BESSONIES",
    value: "46338",
    type: "common",
  },
  {
    label: "LABASTIDE-DU-HAUT-MONT",
    value: "46135",
    type: "common",
  },
  {
    label: "PARLAN",
    value: "15147",
    type: "common",
  },
  {
    label: "SENAILLAC-LATRONQUIERE",
    value: "46302",
    type: "common",
  },
  {
    label: "LATRONQUIERE",
    value: "46160",
    type: "common",
  },
  {
    label: "LATOUILLE-LENTILLAC",
    value: "46159",
    type: "common",
  },
  {
    label: "LADIRAT",
    value: "46146",
    type: "common",
  },
  {
    label: "GORSES",
    value: "46125",
    type: "common",
  },
  {
    label: "SAINT-VINCENT-DU-PENDIT",
    value: "46295",
    type: "common",
  },
  {
    label: "SAINT-PAUL-DE-VERN",
    value: "46286",
    type: "common",
  },
  {
    label: "SAINT-CERE",
    value: "46251",
    type: "common",
  },
  {
    label: "BANNES",
    value: "46017",
    type: "common",
  },
  {
    label: "SAINT-JEAN-LAGINESTE",
    value: "46339",
    type: "common",
  },
  {
    label: "MAYRINHAC-LENTOUR",
    value: "46189",
    type: "common",
  },
  {
    label: "AYNAC",
    value: "46012",
    type: "common",
  },
  {
    label: "THEGRA",
    value: "46317",
    type: "common",
  },
  {
    label: "RIGNAC",
    value: "46238",
    type: "common",
  },
  {
    label: "LAVERGNE",
    value: "46165",
    type: "common",
  },
  {
    label: "ROCAMADOUR",
    value: "46240",
    type: "common",
  },
  {
    label: "ALVIGNAC",
    value: "46003",
    type: "common",
  },
  {
    label: "LACAVE",
    value: "46144",
    type: "common",
  },
  {
    label: "CALES",
    value: "46047",
    type: "common",
  },
  {
    label: "PAYRAC",
    value: "46215",
    type: "common",
  },
  {
    label: "LOUPIAC",
    value: "46178",
    type: "common",
  },
  {
    label: "ROUFFILHAC",
    value: "46241",
    type: "common",
  },
  {
    label: "MASCLAT",
    value: "46186",
    type: "common",
  },
  {
    label: "LAMOTHE-FENELON",
    value: "46152",
    type: "common",
  },
  {
    label: "FAJOLES",
    value: "46098",
    type: "common",
  },
  {
    label: "SAINT-CIRQ-MADELON",
    value: "46257",
    type: "common",
  },
  {
    label: "MILHAC",
    value: "46194",
    type: "common",
  },
  {
    label: "VEYRIGNAC",
    value: "24574",
    type: "common",
  },
  {
    label: "SAINTE-MONDANE",
    value: "24470",
    type: "common",
  },
  {
    label: "GROLEJAC",
    value: "24207",
    type: "common",
  },
  {
    label: "VITRAC",
    value: "24587",
    type: "common",
  },
  {
    label: "DOMME",
    value: "24152",
    type: "common",
  },
  {
    label: "CARSAC-AILLAC",
    value: "24082",
    type: "common",
  },
  {
    label: "VEZAC",
    value: "24577",
    type: "common",
  },
  {
    label: "LA ROQUE-GAGEAC",
    value: "24355",
    type: "common",
  },
  {
    label: "CASTELNAUD-LA-CHAPELLE",
    value: "24086",
    type: "common",
  },
  {
    label: "VEYRINES-DE-DOMME",
    value: "24575",
    type: "common",
  },
  {
    label: "SAINT-VINCENT-DE-COSSE",
    value: "24510",
    type: "common",
  },
  {
    label: "SAINT-GERMAIN-DE-BELVES",
    value: "24416",
    type: "common",
  },
  {
    label: "CLADECH",
    value: "24122",
    type: "common",
  },
  {
    label: "BERBIGUIERES",
    value: "24036",
    type: "common",
  },
  {
    label: "ALLAS-LES-MINES",
    value: "24006",
    type: "common",
  },
  {
    label: "SIORAC-EN-PERIGORD",
    value: "24538",
    type: "common",
  },
  {
    label: "MONPLAISANT",
    value: "24293",
    type: "common",
  },
  {
    label: "MARNAC",
    value: "24254",
    type: "common",
  },
  {
    label: "COUX ET BIGAROQUE-MOUZENS",
    value: "24142",
    type: "common",
  },
  {
    label: "URVAL",
    value: "24560",
    type: "common",
  },
  {
    label: "LE BUISSON-DE-CADOUIN",
    value: "24068",
    type: "common",
  },
  {
    label: "MOLIERES",
    value: "24273",
    type: "common",
  },
  {
    label: "BADEFOLS-SUR-DORDOGNE",
    value: "24022",
    type: "common",
  },
  {
    label: "BOURNIQUEL",
    value: "24060",
    type: "common",
  },
  {
    label: "BAYAC",
    value: "24027",
    type: "common",
  },
  {
    label: "PONTOURS",
    value: "24334",
    type: "common",
  },
  {
    label: "COUZE-ET-SAINT-FRONT",
    value: "24143",
    type: "common",
  },
  {
    label: "VERDON",
    value: "24570",
    type: "common",
  },
  {
    label: "VARENNES",
    value: "24566",
    type: "common",
  },
  {
    label: "SAINT-AGNE",
    value: "24361",
    type: "common",
  },
  {
    label: "LANQUAIS",
    value: "24228",
    type: "common",
  },
  {
    label: "FAUX",
    value: "24177",
    type: "common",
  },
  {
    label: "SAINT-NEXANS",
    value: "24472",
    type: "common",
  },
  {
    label: "SAINT-GERMAIN-ET-MONS",
    value: "24419",
    type: "common",
  },
  {
    label: "SAINT-AUBIN-DE-LANQUAIS",
    value: "24374",
    type: "common",
  },
  {
    label: "COURS-DE-PILE",
    value: "24140",
    type: "common",
  },
  {
    label: "CONNE-DE-LABARDE",
    value: "24132",
    type: "common",
  },
  {
    label: "MONBAZILLAC",
    value: "24274",
    type: "common",
  },
  {
    label: "COLOMBIER",
    value: "24126",
    type: "common",
  },
  {
    label: "SAINT-LAURENT-DES-VIGNES",
    value: "24437",
    type: "common",
  },
  {
    label: "POMPORT",
    value: "24331",
    type: "common",
  },
  {
    label: "SAUSSIGNAC",
    value: "24523",
    type: "common",
  },
  {
    label: "RAZAC-DE-SAUSSIGNAC",
    value: "24349",
    type: "common",
  },
  {
    label: "MONESTIER",
    value: "24276",
    type: "common",
  },
  {
    label: "GARDONNE",
    value: "24194",
    type: "common",
  },
  {
    label: "GAGEAC-ET-ROUILLAC",
    value: "24193",
    type: "common",
  },
  {
    label: "CUNEGES",
    value: "24148",
    type: "common",
  },
  {
    label: "SAINT-PHILIPPE-DU-SEIGNAL",
    value: "33462",
    type: "common",
  },
  {
    label: "LA ROQUILLE",
    value: "33360",
    type: "common",
  },
  {
    label: "PINEUILH",
    value: "33324",
    type: "common",
  },
  {
    label: "LIGUEUX",
    value: "33246",
    type: "common",
  },
  {
    label: "SAINT-QUENTIN-DE-CAPLONG",
    value: "33467",
    type: "common",
  },
  {
    label: "SAINT-ANDRE-ET-APPELLES",
    value: "33369",
    type: "common",
  },
  {
    label: "LES LEVES-ET-THOUMEYRAGUES",
    value: "33242",
    type: "common",
  },
  {
    label: "EYNESSE",
    value: "33160",
    type: "common",
  },
  {
    label: "SAINT-AVIT-DE-SOULEGE",
    value: "33377",
    type: "common",
  },
  {
    label: "PESSAC-SUR-DORDOGNE",
    value: "33319",
    type: "common",
  },
  {
    label: "JUILLAC",
    value: "33210",
    type: "common",
  },
  {
    label: "GENSAC",
    value: "33186",
    type: "common",
  },
  {
    label: "COUBEYRAC",
    value: "33133",
    type: "common",
  },
  {
    label: "SAINTE-RADEGONDE",
    value: "33468",
    type: "common",
  },
  {
    label: "RUCH",
    value: "33361",
    type: "common",
  },
  {
    label: "PUJOLS",
    value: "33344",
    type: "common",
  },
  {
    label: "DOULEZON",
    value: "33153",
    type: "common",
  },
  {
    label: "SAINT-VINCENT-DE-PERTIGNAS",
    value: "33488",
    type: "common",
  },
  {
    label: "SAINT-PEY-DE-CASTETS",
    value: "33460",
    type: "common",
  },
  {
    label: "SAINTE-FLORENCE",
    value: "33401",
    type: "common",
  },
  {
    label: "MERIGNAS",
    value: "33282",
    type: "common",
  },
  {
    label: "BOSSUGAN",
    value: "33064",
    type: "common",
  },
  {
    label: "SAINT-JEAN-DE-BLAIGNAC",
    value: "33421",
    type: "common",
  },
  {
    label: "SAINT-AUBIN-DE-BRANNE",
    value: "33375",
    type: "common",
  },
  {
    label: "ROMAGNE",
    value: "33358",
    type: "common",
  },
  {
    label: "RAUZAN",
    value: "33350",
    type: "common",
  },
  {
    label: "NAUJAN-ET-POSTIAC",
    value: "33301",
    type: "common",
  },
  {
    label: "LUGAIGNAC",
    value: "33257",
    type: "common",
  },
  {
    label: "JUGAZAN",
    value: "33209",
    type: "common",
  },
  {
    label: "BELLEFOND",
    value: "33044",
    type: "common",
  },
  {
    label: "SAINT-QUENTIN-DE-BARON",
    value: "33466",
    type: "common",
  },
  {
    label: "SAINT-LEON",
    value: "33431",
    type: "common",
  },
  {
    label: "GUILLAC",
    value: "33196",
    type: "common",
  },
  {
    label: "GREZILLAC",
    value: "33194",
    type: "common",
  },
  {
    label: "FALEYRAS",
    value: "33163",
    type: "common",
  },
  {
    label: "ESPIET",
    value: "33157",
    type: "common",
  },
  {
    label: "DARDENAC",
    value: "33148",
    type: "common",
  },
  {
    label: "DAIGNAC",
    value: "33147",
    type: "common",
  },
  {
    label: "BLESIGNAC",
    value: "33059",
    type: "common",
  },
  {
    label: "LA SAUVE",
    value: "33505",
    type: "common",
  },
  {
    label: "LE POUT",
    value: "33335",
    type: "common",
  },
  {
    label: "CURSAN",
    value: "33145",
    type: "common",
  },
  {
    label: "CREON",
    value: "33140",
    type: "common",
  },
  {
    label: "CAMIAC-ET-SAINT-DENIS",
    value: "33086",
    type: "common",
  },
  {
    label: "SADIRAC",
    value: "33363",
    type: "common",
  },
  {
    label: "LOUPES",
    value: "33252",
    type: "common",
  },
  {
    label: "LIGNAN-DE-BORDEAUX",
    value: "33245",
    type: "common",
  },
  {
    label: "BONNETAN",
    value: "33061",
    type: "common",
  },
  {
    label: "LATRESNE",
    value: "33234",
    type: "common",
  },
  {
    label: "CENAC",
    value: "33118",
    type: "common",
  },
  {
    label: "CARIGNAN-DE-BORDEAUX",
    value: "33099",
    type: "common",
  },
  {
    label: "CAMBLANES-ET-MEYNAC",
    value: "33085",
    type: "common",
  },
  {
    label: "VILLENAVE-D'ORNON",
    value: "33550",
    type: "common",
  },
  {
    label: "TALENCE",
    value: "33522",
    type: "common",
  },
  {
    label: "GRADIGNAN",
    value: "33192",
    type: "common",
  },
  {
    label: "BEGLES",
    value: "33039",
    type: "common",
  },
  {
    label: "PESSAC",
    value: "33318",
    type: "common",
  },
  {
    label: "CANEJAN",
    value: "33090",
    type: "common",
  },
  {
    label: "SAINT-JEAN-D'ILLAC",
    value: "33422",
    type: "common",
  },
  {
    label: "LANTON",
    value: "33229",
    type: "common",
  },
  {
    label: "ARES",
    value: "33011",
    type: "common",
  },
  {
    label: "ANDERNOS-LES-BAINS",
    value: "33005",
    type: "common",
  },
  {
    label: "LEGE-CAP-FERRET",
    value: "33236",
    type: "common",
  },
  {
    label: "SAINT-VERAN",
    value: "05157",
    type: "common",
  },
  {
    label: "CEILLAC",
    value: "05026",
    type: "common",
  },
  {
    label: "EYGLIERS",
    value: "05052",
    type: "common",
  },
  {
    label: "MONT-DAUPHIN",
    value: "05082",
    type: "common",
  },
  {
    label: "GUILLESTRE",
    value: "05065",
    type: "common",
  },
  {
    label: "REOTIER",
    value: "05116",
    type: "common",
  },
  {
    label: "ORCIERES",
    value: "05096",
    type: "common",
  },
  {
    label: "SAINT-MICHEL-DE-CHAILLOL",
    value: "05153",
    type: "common",
  },
  {
    label: "SAINT-JEAN-SAINT-NICOLAS",
    value: "05145",
    type: "common",
  },
  {
    label: "SAINT-BONNET-EN-CHAMPSAUR",
    value: "05132",
    type: "common",
  },
  {
    label: "POLIGNY",
    value: "05104",
    type: "common",
  },
  {
    label: "LE NOYER",
    value: "05095",
    type: "common",
  },
  {
    label: "DEVOLUY",
    value: "05139",
    type: "common",
  },
  {
    label: "TREMINIS",
    value: "38514",
    type: "common",
  },
  {
    label: "LALLEY",
    value: "38204",
    type: "common",
  },
  {
    label: "LUS-LA-CROIX-HAUTE",
    value: "26168",
    type: "common",
  },
  {
    label: "GLANDAGE",
    value: "26142",
    type: "common",
  },
  {
    label: "SOLAURE EN DIOIS",
    value: "26001",
    type: "common",
  },
  {
    label: "BARSAC",
    value: "26027",
    type: "common",
  },
  {
    label: "AUREL",
    value: "26019",
    type: "common",
  },
  {
    label: "VERONNE",
    value: "26371",
    type: "common",
  },
  {
    label: "VERCHENY",
    value: "26368",
    type: "common",
  },
  {
    label: "MONTCLAR-SUR-GERVANNE",
    value: "26195",
    type: "common",
  },
  {
    label: "MIRABEL-ET-BLACONS",
    value: "26183",
    type: "common",
  },
  {
    label: "CREST",
    value: "26108",
    type: "common",
  },
  {
    label: "COBONNE",
    value: "26098",
    type: "common",
  },
  {
    label: "AOUSTE-SUR-SYE",
    value: "26011",
    type: "common",
  },
  {
    label: "EURRE",
    value: "26125",
    type: "common",
  },
  {
    label: "CHABRILLAN",
    value: "26065",
    type: "common",
  },
  {
    label: "GRANE",
    value: "26144",
    type: "common",
  },
  {
    label: "ALLEX",
    value: "26006",
    type: "common",
  },
  {
    label: "SAULCE-SUR-RHONE",
    value: "26337",
    type: "common",
  },
  {
    label: "LORIOL-SUR-DROME",
    value: "26166",
    type: "common",
  },
  {
    label: "CLIOUSCLAT",
    value: "26097",
    type: "common",
  },
  {
    label: "SAINT-SYMPHORIEN-SOUS-CHOMERAC",
    value: "07298",
    type: "common",
  },
  {
    label: "SAINT-JULIEN-EN-SAINT-ALBAN",
    value: "07255",
    type: "common",
  },
  {
    label: "LE POUZIN",
    value: "07181",
    type: "common",
  },
  {
    label: "PRIVAS",
    value: "07186",
    type: "common",
  },
  {
    label: "LYAS",
    value: "07146",
    type: "common",
  },
  {
    label: "FLAVIAC",
    value: "07090",
    type: "common",
  },
  {
    label: "COUX",
    value: "07072",
    type: "common",
  },
  {
    label: "CHOMERAC",
    value: "07066",
    type: "common",
  },
  {
    label: "VEYRAS",
    value: "07340",
    type: "common",
  },
  {
    label: "SAINT-PRIEST",
    value: "07288",
    type: "common",
  },
  {
    label: "CREYSSEILLES",
    value: "07074",
    type: "common",
  },
  {
    label: "SAINT-JULIEN-DU-GUA",
    value: "07253",
    type: "common",
  },
  {
    label: "POURCHERES",
    value: "07179",
    type: "common",
  },
  {
    label: "GOURDON",
    value: "07098",
    type: "common",
  },
  {
    label: "AJOUX",
    value: "07004",
    type: "common",
  },
  {
    label: "SAINT-JOSEPH-DES-BANCS",
    value: "07251",
    type: "common",
  },
  {
    label: "GENESTELLE",
    value: "07093",
    type: "common",
  },
  {
    label: "LABASTIDE-SUR-BESORGUES",
    value: "07112",
    type: "common",
  },
  {
    label: "LAVIOLLE",
    value: "07139",
    type: "common",
  },
  {
    label: "AIZAC",
    value: "07003",
    type: "common",
  },
  {
    label: "BURZET",
    value: "07045",
    type: "common",
  },
  {
    label: "USCLADES-ET-RIEUTORD",
    value: "07326",
    type: "common",
  },
  {
    label: "SAINT-CIRGUES-EN-MONTAGNE",
    value: "07224",
    type: "common",
  },
  {
    label: "LE ROUX",
    value: "07200",
    type: "common",
  },
  {
    label: "MAZAN-L'ABBAYE",
    value: "07154",
    type: "common",
  },
  {
    label: "LAVILLATTE",
    value: "07137",
    type: "common",
  },
  {
    label: "LANARCE",
    value: "07130",
    type: "common",
  },
  {
    label: "ISSANLAS",
    value: "07105",
    type: "common",
  },
  {
    label: "PRADELLES",
    value: "43154",
    type: "common",
  },
  {
    label: "LESPERON",
    value: "07142",
    type: "common",
  },
  {
    label: "NAUSSAC-FONTANES",
    value: "48105",
    type: "common",
  },
  {
    label: "CHASTANIER",
    value: "48041",
    type: "common",
  },
  {
    label: "AUROUX",
    value: "48010",
    type: "common",
  },
  {
    label: "GRANDRIEU",
    value: "48070",
    type: "common",
  },
  {
    label: "LA PANOUSE",
    value: "48108",
    type: "common",
  },
  {
    label: "SAINTE-EULALIE",
    value: "48149",
    type: "common",
  },
  {
    label: "SAINT-DENIS-EN-MARGERIDE",
    value: "48145",
    type: "common",
  },
  {
    label: "FONTANS",
    value: "48063",
    type: "common",
  },
  {
    label: "RIMEIZE",
    value: "48128",
    type: "common",
  },
  {
    label: "LES BESSONS",
    value: "48025",
    type: "common",
  },
  {
    label: "SAINT-LAURENT-DE-VEYRES",
    value: "48167",
    type: "common",
  },
  {
    label: "LA FAGE-MONTIVERNOUX",
    value: "48058",
    type: "common",
  },
  {
    label: "GRANDVALS",
    value: "48071",
    type: "common",
  },
  {
    label: "CHAUCHAILLES",
    value: "48044",
    type: "common",
  },
  {
    label: "BRION",
    value: "48031",
    type: "common",
  },
  {
    label: "SAINT-REMY-DE-CHAUDES-AIGUES",
    value: "15209",
    type: "common",
  },
  {
    label: "LA TRINITAT",
    value: "15241",
    type: "common",
  },
  {
    label: "SAINT-SYMPHORIEN-DE-THENIERES",
    value: "12250",
    type: "common",
  },
  {
    label: "LACROIX-BARREZ",
    value: "12118",
    type: "common",
  },
  {
    label: "LEUCAMP",
    value: "15103",
    type: "common",
  },
  {
    label: "MUROLS",
    value: "12166",
    type: "common",
  },
  {
    label: "LADINHAC",
    value: "15089",
    type: "common",
  },
  {
    label: "LABESSERETTE",
    value: "15084",
    type: "common",
  },
  {
    label: "SANSAC-VEINAZES",
    value: "15222",
    type: "common",
  },
  {
    label: "LACAPELLE-DEL-FRAISSE",
    value: "15087",
    type: "common",
  },
  {
    label: "SAINT-ANTOINE",
    value: "15172",
    type: "common",
  },
  {
    label: "MARCOLES",
    value: "15117",
    type: "common",
  },
  {
    label: "LEYNHAC",
    value: "15104",
    type: "common",
  },
  {
    label: "SAINT-JULIEN-DE-TOURSAC",
    value: "15194",
    type: "common",
  },
  {
    label: "BOISSET",
    value: "15021",
    type: "common",
  },
  {
    label: "SAINT-HILAIRE",
    value: "46269",
    type: "common",
  },
  {
    label: "SAINT-CIRGUES",
    value: "46255",
    type: "common",
  },
  {
    label: "LAURESSES",
    value: "46161",
    type: "common",
  },
  {
    label: "QUEZAC",
    value: "15157",
    type: "common",
  },
  {
    label: "MONTET-ET-BOUXAL",
    value: "46203",
    type: "common",
  },
  {
    label: "TERROU",
    value: "46314",
    type: "common",
  },
  {
    label: "SAINT-MEDARD-NICOURBY",
    value: "46282",
    type: "common",
  },
  {
    label: "SAINT-MAURICE-EN-QUERCY",
    value: "46279",
    type: "common",
  },
  {
    label: "LABATHUDE",
    value: "46139",
    type: "common",
  },
  {
    label: "MOLIERES",
    value: "46195",
    type: "common",
  },
  {
    label: "LEYME",
    value: "46170",
    type: "common",
  },
  {
    label: "ESPEYROUX",
    value: "46096",
    type: "common",
  },
  {
    label: "ANGLARS",
    value: "46004",
    type: "common",
  },
  {
    label: "THEMINES",
    value: "46318",
    type: "common",
  },
  {
    label: "SAIGNES",
    value: "46246",
    type: "common",
  },
  {
    label: "RUEYRES",
    value: "46243",
    type: "common",
  },
  {
    label: "BIO",
    value: "46030",
    type: "common",
  },
  {
    label: "ALBIAC",
    value: "46002",
    type: "common",
  },
  {
    label: "ISSENDOLUS",
    value: "46132",
    type: "common",
  },
  {
    label: "GRAMAT",
    value: "46128",
    type: "common",
  },
  {
    label: "COUZOU",
    value: "46078",
    type: "common",
  },
  {
    label: "CARLUCET",
    value: "46059",
    type: "common",
  },
  {
    label: "SAINT-PROJET",
    value: "46290",
    type: "common",
  },
  {
    label: "REILHAGUET",
    value: "46236",
    type: "common",
  },
  {
    label: "LE VIGAN",
    value: "46334",
    type: "common",
  },
  {
    label: "ANGLARS-NOZAC",
    value: "46006",
    type: "common",
  },
  {
    label: "PAYRIGNAC",
    value: "46216",
    type: "common",
  },
  {
    label: "SAINT-AUBIN-DE-NABIRAT",
    value: "24375",
    type: "common",
  },
  {
    label: "NABIRAT",
    value: "24300",
    type: "common",
  },
  {
    label: "SAINT-MARTIAL-DE-NABIRAT",
    value: "24450",
    type: "common",
  },
  {
    label: "CENAC-ET-SAINT-JULIEN",
    value: "24091",
    type: "common",
  },
  {
    label: "SAINT-CYBRANET",
    value: "24395",
    type: "common",
  },
  {
    label: "DAGLAN",
    value: "24150",
    type: "common",
  },
  {
    label: "SAINT-LAURENT-LA-VALLEE",
    value: "24438",
    type: "common",
  },
  {
    label: "GRIVES",
    value: "24206",
    type: "common",
  },
  {
    label: "CARVES",
    value: "24084",
    type: "common",
  },
  {
    label: "SAINT-PARDOUX-ET-VIELVIC",
    value: "24478",
    type: "common",
  },
  {
    label: "SAINTE-FOY-DE-BELVES",
    value: "24406",
    type: "common",
  },
  {
    label: "SAGELAT",
    value: "24360",
    type: "common",
  },
  {
    label: "LARZAC",
    value: "24230",
    type: "common",
  },
  {
    label: "PAYS DE BELVES",
    value: "24035",
    type: "common",
  },
  {
    label: "MONTFERRAND-DU-PERIGORD",
    value: "24290",
    type: "common",
  },
  {
    label: "SAINT-AVIT-RIVIERE",
    value: "24378",
    type: "common",
  },
  {
    label: "BOUILLAC",
    value: "24052",
    type: "common",
  },
  {
    label: "SAINTE-CROIX",
    value: "24393",
    type: "common",
  },
  {
    label: "SAINT-AVIT-SENIEUR",
    value: "24379",
    type: "common",
  },
  {
    label: "NAUSSANNES",
    value: "24307",
    type: "common",
  },
  {
    label: "BEAUMONTOIS EN PERIGORD",
    value: "24028",
    type: "common",
  },
  {
    label: "MONSAC",
    value: "24281",
    type: "common",
  },
  {
    label: "MONTAUT",
    value: "24287",
    type: "common",
  },
  {
    label: "BARDOU",
    value: "24024",
    type: "common",
  },
  {
    label: "SAINT-CERNIN-DE-LABARDE",
    value: "24385",
    type: "common",
  },
  {
    label: "MONSAGUEL",
    value: "24282",
    type: "common",
  },
  {
    label: "MONMADALES",
    value: "24278",
    type: "common",
  },
  {
    label: "ISSIGEAC",
    value: "24212",
    type: "common",
  },
  {
    label: "SINGLEYRAC",
    value: "24536",
    type: "common",
  },
  {
    label: "SAINT-PERDOUX",
    value: "24483",
    type: "common",
  },
  {
    label: "SADILLAC",
    value: "24359",
    type: "common",
  },
  {
    label: "RIBAGNAC",
    value: "24351",
    type: "common",
  },
  {
    label: "BOUNIAGUES",
    value: "24054",
    type: "common",
  },
  {
    label: "ROUFFIGNAC-DE-SIGOULES",
    value: "24357",
    type: "common",
  },
  {
    label: "MESCOULES",
    value: "24267",
    type: "common",
  },
  {
    label: "LOUBES-BERNAC",
    value: "47151",
    type: "common",
  },
  {
    label: "THENAC",
    value: "24549",
    type: "common",
  },
  {
    label: "VILLENEUVE-DE-DURAS",
    value: "47321",
    type: "common",
  },
  {
    label: "SAINT-ASTIER",
    value: "47229",
    type: "common",
  },
  {
    label: "MARGUERON",
    value: "33269",
    type: "common",
  },
  {
    label: "ESCLOTTES",
    value: "47089",
    type: "common",
  },
  {
    label: "SAVIGNAC-DE-DURAS",
    value: "47294",
    type: "common",
  },
  {
    label: "RIOCAUD",
    value: "33354",
    type: "common",
  },
  {
    label: "LANDERROUAT",
    value: "33223",
    type: "common",
  },
  {
    label: "CAPLONG",
    value: "33094",
    type: "common",
  },
  {
    label: "PELLEGRUE",
    value: "33316",
    type: "common",
  },
  {
    label: "MASSUGAS",
    value: "33277",
    type: "common",
  },
  {
    label: "AURIOLLES",
    value: "33020",
    type: "common",
  },
  {
    label: "SOUSSAC",
    value: "33516",
    type: "common",
  },
  {
    label: "SAINT-ANTOINE-DU-QUEYRET",
    value: "33372",
    type: "common",
  },
  {
    label: "MAURIAC",
    value: "33278",
    type: "common",
  },
  {
    label: "LISTRAC-DE-DUREZE",
    value: "33247",
    type: "common",
  },
  {
    label: "CAZAUGITAT",
    value: "33117",
    type: "common",
  },
  {
    label: "CLEYRAC",
    value: "33129",
    type: "common",
  },
  {
    label: "BLASIMON",
    value: "33057",
    type: "common",
  },
  {
    label: "MARTRES",
    value: "33275",
    type: "common",
  },
  {
    label: "LUGASSON",
    value: "33258",
    type: "common",
  },
  {
    label: "FRONTENAC",
    value: "33175",
    type: "common",
  },
  {
    label: "DAUBEZE",
    value: "33149",
    type: "common",
  },
  {
    label: "COURPIAC",
    value: "33135",
    type: "common",
  },
  {
    label: "CESSAC",
    value: "33121",
    type: "common",
  },
  {
    label: "BAIGNEAUX",
    value: "33025",
    type: "common",
  },
  {
    label: "TARGON",
    value: "33523",
    type: "common",
  },
  {
    label: "MONTIGNAC",
    value: "33292",
    type: "common",
  },
  {
    label: "BELLEBAT",
    value: "33043",
    type: "common",
  },
  {
    label: "HAUX",
    value: "33201",
    type: "common",
  },
  {
    label: "CAPIAN",
    value: "33093",
    type: "common",
  },
  {
    label: "LE TOURNE",
    value: "33534",
    type: "common",
  },
  {
    label: "TABANAC",
    value: "33518",
    type: "common",
  },
  {
    label: "SAINT-GENES-DE-LOMBAUD",
    value: "33408",
    type: "common",
  },
  {
    label: "SAINT-CAPRAIS-DE-BORDEAUX",
    value: "33381",
    type: "common",
  },
  {
    label: "MADIRAC",
    value: "33263",
    type: "common",
  },
  {
    label: "LANGOIRAN",
    value: "33226",
    type: "common",
  },
  {
    label: "BAURECH",
    value: "33033",
    type: "common",
  },
  {
    label: "SAINT-MEDARD-D'EYRANS",
    value: "33448",
    type: "common",
  },
  {
    label: "QUINSAC",
    value: "33349",
    type: "common",
  },
  {
    label: "ISLE-SAINT-GEORGES",
    value: "33206",
    type: "common",
  },
  {
    label: "CAMBES",
    value: "33084",
    type: "common",
  },
  {
    label: "BEAUTIRAN",
    value: "33037",
    type: "common",
  },
  {
    label: "AYGUEMORTE-LES-GRAVES",
    value: "33023",
    type: "common",
  },
  {
    label: "MARTILLAC",
    value: "33274",
    type: "common",
  },
  {
    label: "LEOGNAN",
    value: "33238",
    type: "common",
  },
  {
    label: "CADAUJAC",
    value: "33080",
    type: "common",
  },
  {
    label: "CESTAS",
    value: "33122",
    type: "common",
  },
  {
    label: "MARCHEPRIME",
    value: "33555",
    type: "common",
  },
  {
    label: "AUDENGE",
    value: "33019",
    type: "common",
  },
  {
    label: "LA TESTE-DE-BUCH",
    value: "33529",
    type: "common",
  },
  {
    label: "VARS",
    value: "05177",
    type: "common",
  },
  {
    label: "SAINT-CLEMENT-SUR-DURANCE",
    value: "05134",
    type: "common",
  },
  {
    label: "RISOUL",
    value: "05119",
    type: "common",
  },
  {
    label: "CHATEAUROUX-LES-ALPES",
    value: "05036",
    type: "common",
  },
  {
    label: "REALLON",
    value: "05114",
    type: "common",
  },
  {
    label: "SAINT-LEGER-LES-MELEZES",
    value: "05149",
    type: "common",
  },
  {
    label: "CHABOTTES",
    value: "05029",
    type: "common",
  },
  {
    label: "ANCELLE",
    value: "05004",
    type: "common",
  },
  {
    label: "SAINT-LAURENT-DU-CROS",
    value: "05148",
    type: "common",
  },
  {
    label: "SAINT-JULIEN-EN-CHAMPSAUR",
    value: "05147",
    type: "common",
  },
  {
    label: "FOREST-SAINT-JULIEN",
    value: "05056",
    type: "common",
  },
  {
    label: "BUISSARD",
    value: "05025",
    type: "common",
  },
  {
    label: "LAYE",
    value: "05072",
    type: "common",
  },
  {
    label: "LA FARE-EN-CHAMPSAUR",
    value: "05054",
    type: "common",
  },
  {
    label: "SAINT-JULIEN-EN-BEAUCHENE",
    value: "05146",
    type: "common",
  },
  {
    label: "BOULC",
    value: "26055",
    type: "common",
  },
  {
    label: "SAINT-ROMAN",
    value: "26327",
    type: "common",
  },
  {
    label: "MENGLON",
    value: "26178",
    type: "common",
  },
  {
    label: "RECOUBEAU-JANSAC",
    value: "26262",
    type: "common",
  },
  {
    label: "MONTMAUR-EN-DIOIS",
    value: "26205",
    type: "common",
  },
  {
    label: "BARNAVE",
    value: "26025",
    type: "common",
  },
  {
    label: "SAINT-BENOIT-EN-DIOIS",
    value: "26296",
    type: "common",
  },
  {
    label: "RIMON-ET-SAVEL",
    value: "26266",
    type: "common",
  },
  {
    label: "PENNES-LE-SEC",
    value: "26228",
    type: "common",
  },
  {
    label: "SAILLANS",
    value: "26289",
    type: "common",
  },
  {
    label: "ESPENEL",
    value: "26122",
    type: "common",
  },
  {
    label: "CHASTEL-ARNAUD",
    value: "26080",
    type: "common",
  },
  {
    label: "SAOU",
    value: "26336",
    type: "common",
  },
  {
    label: "SAINT-SAUVEUR-EN-DIOIS",
    value: "26328",
    type: "common",
  },
  {
    label: "PIEGROS-LA-CLASTRE",
    value: "26234",
    type: "common",
  },
  {
    label: "AUBENASSON",
    value: "26015",
    type: "common",
  },
  {
    label: "DIVAJEU",
    value: "26115",
    type: "common",
  },
  {
    label: "LA REPARA-AURIPLES",
    value: "26020",
    type: "common",
  },
  {
    label: "ROYNAC",
    value: "26287",
    type: "common",
  },
  {
    label: "LA ROCHE-SUR-GRANE",
    value: "26277",
    type: "common",
  },
  {
    label: "AUTICHAMP",
    value: "26021",
    type: "common",
  },
  {
    label: "MIRMANDE",
    value: "26185",
    type: "common",
  },
  {
    label: "LES TOURRETTES",
    value: "26353",
    type: "common",
  },
  {
    label: "CRUAS",
    value: "07076",
    type: "common",
  },
  {
    label: "SAINT-VINCENT-DE-BARRES",
    value: "07302",
    type: "common",
  },
  {
    label: "SAINT-LAGER-BRESSAC",
    value: "07260",
    type: "common",
  },
  {
    label: "BAIX",
    value: "07022",
    type: "common",
  },
  {
    label: "SAINT-PIERRE-LA-ROCHE",
    value: "07283",
    type: "common",
  },
  {
    label: "SAINT-BAUZILE",
    value: "07219",
    type: "common",
  },
  {
    label: "ROCHESSAUVE",
    value: "07194",
    type: "common",
  },
  {
    label: "ALISSAS",
    value: "07008",
    type: "common",
  },
  {
    label: "FREYSSENET",
    value: "07092",
    type: "common",
  },
  {
    label: "DARBRES",
    value: "07077",
    type: "common",
  },
  {
    label: "BERZEME",
    value: "07032",
    type: "common",
  },
  {
    label: "VESSEAUX",
    value: "07339",
    type: "common",
  },
  {
    label: "SAINT-MICHEL-DE-BOULOGNE",
    value: "07277",
    type: "common",
  },
  {
    label: "SAINT-LAURENT-SOUS-COIRON",
    value: "07263",
    type: "common",
  },
  {
    label: "SAINT-ETIENNE-DE-BOULOGNE",
    value: "07230",
    type: "common",
  },
  {
    label: "VALS-LES-BAINS",
    value: "07331",
    type: "common",
  },
  {
    label: "SAINT-JULIEN-DU-SERRE",
    value: "07254",
    type: "common",
  },
  {
    label: "SAINT-ANDEOL-DE-VALS",
    value: "07210",
    type: "common",
  },
  {
    label: "SAINT-PIERRE-DE-COLOMBIER",
    value: "07282",
    type: "common",
  },
  {
    label: "PONT-DE-LABEAUME",
    value: "07178",
    type: "common",
  },
  {
    label: "MEYRAS",
    value: "07156",
    type: "common",
  },
  {
    label: "JUVINAS",
    value: "07111",
    type: "common",
  },
  {
    label: "CHIROLS",
    value: "07065",
    type: "common",
  },
  {
    label: "THUEYTS",
    value: "07322",
    type: "common",
  },
  {
    label: "MONTPEZAT-SOUS-BAUZON",
    value: "07161",
    type: "common",
  },
  {
    label: "MAYRES",
    value: "07153",
    type: "common",
  },
  {
    label: "BARNAS",
    value: "07025",
    type: "common",
  },
  {
    label: "ASTET",
    value: "07018",
    type: "common",
  },
  {
    label: "LE PLAGNAL",
    value: "07175",
    type: "common",
  },
  {
    label: "SAINT-ALBAN-EN-MONTAGNE",
    value: "07206",
    type: "common",
  },
  {
    label: "CELLIER-DU-LUC",
    value: "07047",
    type: "common",
  },
  {
    label: "SAINT-FLOUR-DE-MERCOIRE",
    value: "48150",
    type: "common",
  },
  {
    label: "ROCLES",
    value: "48129",
    type: "common",
  },
  {
    label: "LANGOGNE",
    value: "48080",
    type: "common",
  },
  {
    label: "SAINT-JEAN-LA-FOUILLOUSE",
    value: "48160",
    type: "common",
  },
  {
    label: "PIERREFICHE",
    value: "48112",
    type: "common",
  },
  {
    label: "SAINT-SAUVEUR-DE-GINESTOUX",
    value: "48182",
    type: "common",
  },
  {
    label: "LES LAUBIES",
    value: "48083",
    type: "common",
  },
  {
    label: "SERVERETTE",
    value: "48188",
    type: "common",
  },
  {
    label: "PEYRE EN AUBRAC",
    value: "48009",
    type: "common",
  },
  {
    label: "PRINSUEJOLS-MALBOUZON",
    value: "48087",
    type: "common",
  },
  {
    label: "RECOULES-D'AUBRAC",
    value: "48123",
    type: "common",
  },
  {
    label: "SAINT-URCIZE",
    value: "15216",
    type: "common",
  },
  {
    label: "LAGUIOLE",
    value: "12119",
    type: "common",
  },
  {
    label: "SOULAGES-BONNEVAL",
    value: "12273",
    type: "common",
  },
  {
    label: "CASSUEJOULS",
    value: "12058",
    type: "common",
  },
  {
    label: "HUPARLAC",
    value: "12116",
    type: "common",
  },
  {
    label: "SAINT-AMANS-DES-COTS",
    value: "12209",
    type: "common",
  },
  {
    label: "MONTEZIC",
    value: "12151",
    type: "common",
  },
  {
    label: "LAPEYRUGUE",
    value: "15093",
    type: "common",
  },
  {
    label: "SAINT-HIPPOLYTE",
    value: "12226",
    type: "common",
  },
  {
    label: "CAMPOURIEZ",
    value: "12048",
    type: "common",
  },
  {
    label: "MONTSALVY",
    value: "15134",
    type: "common",
  },
  {
    label: "JUNHAC",
    value: "15082",
    type: "common",
  },
  {
    label: "SENEZERGUES",
    value: "15226",
    type: "common",
  },
  {
    label: "SAINT-ETIENNE-DE-MAURS",
    value: "15184",
    type: "common",
  },
  {
    label: "SAINT-CONSTANT-FOURNOULES",
    value: "15181",
    type: "common",
  },
  {
    label: "LINAC",
    value: "46174",
    type: "common",
  },
  {
    label: "MAURS",
    value: "15122",
    type: "common",
  },
  {
    label: "SAINT-PERDOUX",
    value: "46288",
    type: "common",
  },
  {
    label: "SABADEL-LATRONQUIERE",
    value: "46244",
    type: "common",
  },
  {
    label: "PRENDEIGNES",
    value: "46226",
    type: "common",
  },
  {
    label: "SAINTE-COLOMBE",
    value: "46260",
    type: "common",
  },
  {
    label: "SAINT-BRESSOU",
    value: "46249",
    type: "common",
  },
  {
    label: "CARDAILLAC",
    value: "46057",
    type: "common",
  },
  {
    label: "SONAC",
    value: "46306",
    type: "common",
  },
  {
    label: "RUDELLE",
    value: "46242",
    type: "common",
  },
  {
    label: "LACAPELLE-MARIVAL",
    value: "46143",
    type: "common",
  },
  {
    label: "ISSEPTS",
    value: "46133",
    type: "common",
  },
  {
    label: "LE BOUYSSOU",
    value: "46036",
    type: "common",
  },
  {
    label: "LE BOURG",
    value: "46034",
    type: "common",
  },
  {
    label: "ASSIER",
    value: "46009",
    type: "common",
  },
  {
    label: "THEMINETTES",
    value: "46319",
    type: "common",
  },
  {
    label: "SAINT-SIMON",
    value: "46292",
    type: "common",
  },
  {
    label: "REILHAC",
    value: "46235",
    type: "common",
  },
  {
    label: "FLAUJAC-GARE",
    value: "46104",
    type: "common",
  },
  {
    label: "DURBANS",
    value: "46090",
    type: "common",
  },
  {
    label: "LUNEGARDE",
    value: "46181",
    type: "common",
  },
  {
    label: "LE BASTIT",
    value: "46018",
    type: "common",
  },
  {
    label: "SENIERGUES",
    value: "46304",
    type: "common",
  },
  {
    label: "MONTFAUCON",
    value: "46204",
    type: "common",
  },
  {
    label: "GINOUILLAC",
    value: "46121",
    type: "common",
  },
  {
    label: "SOUCIRAC",
    value: "46308",
    type: "common",
  },
  {
    label: "SAINT-CHAMARAND",
    value: "46253",
    type: "common",
  },
  {
    label: "SAINT-CLAIR",
    value: "46259",
    type: "common",
  },
  {
    label: "SAINT-CIRQ-SOUILLAGUET",
    value: "46258",
    type: "common",
  },
  {
    label: "CONCORES",
    value: "46072",
    type: "common",
  },
  {
    label: "LEOBARD",
    value: "46169",
    type: "common",
  },
  {
    label: "GOURDON",
    value: "46127",
    type: "common",
  },
  {
    label: "DEGAGNAC",
    value: "46087",
    type: "common",
  },
  {
    label: "SALVIAC",
    value: "46297",
    type: "common",
  },
  {
    label: "FLORIMONT-GAUMIER",
    value: "24184",
    type: "common",
  },
  {
    label: "BOUZIC",
    value: "24063",
    type: "common",
  },
  {
    label: "SAINT-POMPONT",
    value: "24488",
    type: "common",
  },
  {
    label: "CAMPAGNAC-LES-QUERCY",
    value: "24075",
    type: "common",
  },
  {
    label: "PRATS-DU-PERIGORD",
    value: "24337",
    type: "common",
  },
  {
    label: "ORLIAC",
    value: "24313",
    type: "common",
  },
  {
    label: "DOISSAT",
    value: "24151",
    type: "common",
  },
  {
    label: "SALLES-DE-BELVES",
    value: "24517",
    type: "common",
  },
  {
    label: "MAZEYROLLES",
    value: "24263",
    type: "common",
  },
  {
    label: "SAINT-ROMAIN-DE-MONPAZIER",
    value: "24495",
    type: "common",
  },
  {
    label: "SAINT-MARCORY",
    value: "24446",
    type: "common",
  },
  {
    label: "MONPAZIER",
    value: "24280",
    type: "common",
  },
  {
    label: "MARSALES",
    value: "24257",
    type: "common",
  },
  {
    label: "GAUGEAC",
    value: "24195",
    type: "common",
  },
  {
    label: "CAPDROT",
    value: "24080",
    type: "common",
  },
  {
    label: "RAMPIEUX",
    value: "24347",
    type: "common",
  },
  {
    label: "TOURLIAC",
    value: "47311",
    type: "common",
  },
  {
    label: "SAINT-CASSIEN",
    value: "24384",
    type: "common",
  },
  {
    label: "LOLME",
    value: "24244",
    type: "common",
  },
  {
    label: "LAVALADE",
    value: "24231",
    type: "common",
  },
  {
    label: "RAYET",
    value: "47219",
    type: "common",
  },
  {
    label: "DOUDRAC",
    value: "47083",
    type: "common",
  },
  {
    label: "CAVARC",
    value: "47063",
    type: "common",
  },
  {
    label: "SAINTE-RADEGONDE",
    value: "24492",
    type: "common",
  },
  {
    label: "SAINT-LEON-D'ISSIGEAC",
    value: "24441",
    type: "common",
  },
  {
    label: "FAURILLES",
    value: "24176",
    type: "common",
  },
  {
    label: "BOISSE",
    value: "24045",
    type: "common",
  },
  {
    label: "SAINT-QUENTIN-DU-DROPT",
    value: "47272",
    type: "common",
  },
  {
    label: "CAHUZAC",
    value: "47044",
    type: "common",
  },
  {
    label: "MONMARVES",
    value: "24279",
    type: "common",
  },
  {
    label: "PLAISANCE",
    value: "24168",
    type: "common",
  },
  {
    label: "LALANDUSSE",
    value: "47132",
    type: "common",
  },
  {
    label: "SAINT-CAPRAISE-D'EYMET",
    value: "24383",
    type: "common",
  },
  {
    label: "SAINT-AUBIN-DE-CADELECH",
    value: "24373",
    type: "common",
  },
  {
    label: "RAZAC-D'EYMET",
    value: "24348",
    type: "common",
  },
  {
    label: "SERRES-ET-MONTGUYARD",
    value: "24532",
    type: "common",
  },
  {
    label: "FONROQUE",
    value: "24186",
    type: "common",
  },
  {
    label: "EYMET",
    value: "24167",
    type: "common",
  },
  {
    label: "SAINT-JEAN-DE-DURAS",
    value: "47247",
    type: "common",
  },
  {
    label: "SOUMENSAC",
    value: "47303",
    type: "common",
  },
  {
    label: "AURIAC-SUR-DROPT",
    value: "47018",
    type: "common",
  },
  {
    label: "PARDAILLAN",
    value: "47199",
    type: "common",
  },
  {
    label: "SAINT-SERNIN",
    value: "47278",
    type: "common",
  },
  {
    label: "BALEYSSAGUES",
    value: "47020",
    type: "common",
  },
  {
    label: "DURAS",
    value: "47086",
    type: "common",
  },
  {
    label: "SAINTE-COLOMBE-DE-DURAS",
    value: "47236",
    type: "common",
  },
  {
    label: "SAINT-FERME",
    value: "33400",
    type: "common",
  },
  {
    label: "LE PUY",
    value: "33345",
    type: "common",
  },
  {
    label: "DIEULIVOL",
    value: "33150",
    type: "common",
  },
  {
    label: "SAINT-MARTIN-DU-PUY",
    value: "33446",
    type: "common",
  },
  {
    label: "RIMONS",
    value: "33353",
    type: "common",
  },
  {
    label: "LANDERROUET-SUR-SEGUR",
    value: "33224",
    type: "common",
  },
  {
    label: "CAUMONT",
    value: "33112",
    type: "common",
  },
  {
    label: "CASTELMORON-D'ALBRET",
    value: "33103",
    type: "common",
  },
  {
    label: "SAUVETERRE-DE-GUYENNE",
    value: "33506",
    type: "common",
  },
  {
    label: "SAINT-SULPICE-DE-POMMIERS",
    value: "33482",
    type: "common",
  },
  {
    label: "SAINT-HILAIRE-DU-BOIS",
    value: "33419",
    type: "common",
  },
  {
    label: "SAINT-FELIX-DE-FONCAUDE",
    value: "33399",
    type: "common",
  },
  {
    label: "SAINT-GENIS-DU-BOIS",
    value: "33409",
    type: "common",
  },
  {
    label: "SAINT-BRICE",
    value: "33379",
    type: "common",
  },
  {
    label: "GORNAC",
    value: "33189",
    type: "common",
  },
  {
    label: "COIRAC",
    value: "33131",
    type: "common",
  },
  {
    label: "CASTELVIEL",
    value: "33105",
    type: "common",
  },
  {
    label: "SAINT-PIERRE-DE-BAT",
    value: "33464",
    type: "common",
  },
  {
    label: "LADAUX",
    value: "33215",
    type: "common",
  },
  {
    label: "ESCOUSSANS",
    value: "33156",
    type: "common",
  },
  {
    label: "DONZAC",
    value: "33152",
    type: "common",
  },
  {
    label: "VILLENAVE-DE-RIONS",
    value: "33549",
    type: "common",
  },
  {
    label: "SOULIGNAC",
    value: "33515",
    type: "common",
  },
  {
    label: "RIONS",
    value: "33355",
    type: "common",
  },
  {
    label: "PODENSAC",
    value: "33327",
    type: "common",
  },
  {
    label: "PAILLET",
    value: "33311",
    type: "common",
  },
  {
    label: "OMET",
    value: "33308",
    type: "common",
  },
  {
    label: "LAROQUE",
    value: "33231",
    type: "common",
  },
  {
    label: "CARDAN",
    value: "33098",
    type: "common",
  },
  {
    label: "BEGUEY",
    value: "33040",
    type: "common",
  },
  {
    label: "VIRELADE",
    value: "33552",
    type: "common",
  },
  {
    label: "PORTETS",
    value: "33334",
    type: "common",
  },
  {
    label: "LESTIAC-SUR-GARONNE",
    value: "33241",
    type: "common",
  },
  {
    label: "ARBANATS",
    value: "33007",
    type: "common",
  },
  {
    label: "SAINT-SELVE",
    value: "33474",
    type: "common",
  },
  {
    label: "SAINT-MORILLON",
    value: "33454",
    type: "common",
  },
  {
    label: "LA BREDE",
    value: "33213",
    type: "common",
  },
  {
    label: "CASTRES-GIRONDE",
    value: "33109",
    type: "common",
  },
  {
    label: "SAUCATS",
    value: "33501",
    type: "common",
  },
  {
    label: "LE BARP",
    value: "33029",
    type: "common",
  },
  {
    label: "BIGANOS",
    value: "33051",
    type: "common",
  },
  {
    label: "ARCACHON",
    value: "33009",
    type: "common",
  },
  {
    label: "SAINT-PAUL-SUR-UBAYE",
    value: "04193",
    type: "common",
  },
  {
    label: "SAINT-ANDRE-D'EMBRUN",
    value: "05128",
    type: "common",
  },
  {
    label: "CREVOUX",
    value: "05044",
    type: "common",
  },
  {
    label: "PUY-SANIERES",
    value: "05111",
    type: "common",
  },
  {
    label: "PUY-SAINT-EUSEBE",
    value: "05108",
    type: "common",
  },
  {
    label: "EMBRUN",
    value: "05046",
    type: "common",
  },
  {
    label: "SAINT-APOLLINAIRE",
    value: "05130",
    type: "common",
  },
  {
    label: "CHORGES",
    value: "05040",
    type: "common",
  },
  {
    label: "LA BATIE-VIEILLE",
    value: "05018",
    type: "common",
  },
  {
    label: "LA BATIE-NEUVE",
    value: "05017",
    type: "common",
  },
  {
    label: "LA ROCHETTE",
    value: "05124",
    type: "common",
  },
  {
    label: "RABOU",
    value: "05112",
    type: "common",
  },
  {
    label: "GAP",
    value: "05061",
    type: "common",
  },
  {
    label: "LA ROCHE-DES-ARNAUDS",
    value: "05123",
    type: "common",
  },
  {
    label: "VEYNES",
    value: "05179",
    type: "common",
  },
  {
    label: "MONTMAUR",
    value: "05087",
    type: "common",
  },
  {
    label: "MONTBRAND",
    value: "05080",
    type: "common",
  },
  {
    label: "VAL-MARAVEL",
    value: "26136",
    type: "common",
  },
  {
    label: "MISCON",
    value: "26186",
    type: "common",
  },
  {
    label: "LESCHES-EN-DIOIS",
    value: "26164",
    type: "common",
  },
  {
    label: "POYOLS",
    value: "26253",
    type: "common",
  },
  {
    label: "LUC-EN-DIOIS",
    value: "26167",
    type: "common",
  },
  {
    label: "MONTLAUR-EN-DIOIS",
    value: "26204",
    type: "common",
  },
  {
    label: "BRETTE",
    value: "26062",
    type: "common",
  },
  {
    label: "AUCELON",
    value: "26017",
    type: "common",
  },
  {
    label: "PRADELLE",
    value: "26254",
    type: "common",
  },
  {
    label: "LES TONILS",
    value: "26351",
    type: "common",
  },
  {
    label: "ROCHEFOURCHAT",
    value: "26274",
    type: "common",
  },
  {
    label: "LA CHAUDIERE",
    value: "26090",
    type: "common",
  },
  {
    label: "BEZAUDUN-SUR-BINE",
    value: "26051",
    type: "common",
  },
  {
    label: "LE POET-CELARD",
    value: "26241",
    type: "common",
  },
  {
    label: "MORNANS",
    value: "26214",
    type: "common",
  },
  {
    label: "BOURDEAUX",
    value: "26056",
    type: "common",
  },
  {
    label: "SOYANS",
    value: "26344",
    type: "common",
  },
  {
    label: "PONT-DE-BARRET",
    value: "26249",
    type: "common",
  },
  {
    label: "FRANCILLON-SUR-ROUBION",
    value: "26137",
    type: "common",
  },
  {
    label: "PUY-SAINT-MARTIN",
    value: "26258",
    type: "common",
  },
  {
    label: "MANAS",
    value: "26171",
    type: "common",
  },
  {
    label: "CLEON-D'ANDRAN",
    value: "26095",
    type: "common",
  },
  {
    label: "CHAROLS",
    value: "26078",
    type: "common",
  },
  {
    label: "MARSANNE",
    value: "26176",
    type: "common",
  },
  {
    label: "LA LAUPIE",
    value: "26157",
    type: "common",
  },
  {
    label: "BONLIEU-SUR-ROUBION",
    value: "26052",
    type: "common",
  },
  {
    label: "SAVASSE",
    value: "26339",
    type: "common",
  },
  {
    label: "SAINT-MARCEL-LES-SAUZET",
    value: "26312",
    type: "common",
  },
  {
    label: "LA COUCOURDE",
    value: "26106",
    type: "common",
  },
  {
    label: "CONDILLAC",
    value: "26102",
    type: "common",
  },
  {
    label: "MEYSSE",
    value: "07157",
    type: "common",
  },
  {
    label: "SCEAUTRES",
    value: "07311",
    type: "common",
  },
  {
    label: "SAINT-MARTIN-SUR-LAVEZON",
    value: "07270",
    type: "common",
  },
  {
    label: "SAINT-PONS",
    value: "07287",
    type: "common",
  },
  {
    label: "SAINT-GINEYS-EN-COIRON",
    value: "07242",
    type: "common",
  },
  {
    label: "MIRABEL",
    value: "07159",
    type: "common",
  },
  {
    label: "LUSSAS",
    value: "07145",
    type: "common",
  },
  {
    label: "UCEL",
    value: "07325",
    type: "common",
  },
  {
    label: "SAINT-PRIVAT",
    value: "07289",
    type: "common",
  },
  {
    label: "SAINT-ETIENNE-DE-FONTBELLON",
    value: "07231",
    type: "common",
  },
  {
    label: "SAINT-DIDIER-SOUS-AUBENAS",
    value: "07229",
    type: "common",
  },
  {
    label: "MERCUER",
    value: "07155",
    type: "common",
  },
  {
    label: "LABEGUDE",
    value: "07116",
    type: "common",
  },
  {
    label: "AUBENAS",
    value: "07019",
    type: "common",
  },
  {
    label: "SAINT-CIRGUES-DE-PRADES",
    value: "07223",
    type: "common",
  },
  {
    label: "PRADES",
    value: "07182",
    type: "common",
  },
  {
    label: "LENTILLERES",
    value: "07141",
    type: "common",
  },
  {
    label: "LALEVADE-D'ARDECHE",
    value: "07127",
    type: "common",
  },
  {
    label: "FABRAS",
    value: "07087",
    type: "common",
  },
  {
    label: "AILHON",
    value: "07002",
    type: "common",
  },
  {
    label: "JAUJAC",
    value: "07107",
    type: "common",
  },
  {
    label: "LA SOUCHE",
    value: "07315",
    type: "common",
  },
  {
    label: "BORNE",
    value: "07038",
    type: "common",
  },
  {
    label: "SAINT-ETIENNE-DE-LUGDARES",
    value: "07232",
    type: "common",
  },
  {
    label: "LUC",
    value: "48086",
    type: "common",
  },
  {
    label: "LAVEYRUNE",
    value: "07136",
    type: "common",
  },
  {
    label: "CHEYLARD-L'EVEQUE",
    value: "48048",
    type: "common",
  },
  {
    label: "CHAUDEYRAC",
    value: "48045",
    type: "common",
  },
  {
    label: "CHATEAUNEUF-DE-RANDON",
    value: "48043",
    type: "common",
  },
  {
    label: "ARZENC-DE-RANDON",
    value: "48008",
    type: "common",
  },
  {
    label: "SAINT-GAL",
    value: "48153",
    type: "common",
  },
  {
    label: "RECOULES-DE-FUMAS",
    value: "48124",
    type: "common",
  },
  {
    label: "LE BUISSON",
    value: "48032",
    type: "common",
  },
  {
    label: "MARCHASTEL",
    value: "48091",
    type: "common",
  },
  {
    label: "NASBINALS",
    value: "48104",
    type: "common",
  },
  {
    label: "CURIERES",
    value: "12088",
    type: "common",
  },
  {
    label: "MONTPEYROUX",
    value: "12156",
    type: "common",
  },
  {
    label: "FLORENTIN-LA-CAPELLE",
    value: "12103",
    type: "common",
  },
  {
    label: "ESPEYRAC",
    value: "12097",
    type: "common",
  },
  {
    label: "ENTRAYGUES-SUR-TRUYERE",
    value: "12094",
    type: "common",
  },
  {
    label: "LE FEL",
    value: "12093",
    type: "common",
  },
  {
    label: "SENERGUES",
    value: "12268",
    type: "common",
  },
  {
    label: "VIEILLEVIE",
    value: "15260",
    type: "common",
  },
  {
    label: "CASSANIOUZE",
    value: "15029",
    type: "common",
  },
  {
    label: "SAINT-PARTHEM",
    value: "12240",
    type: "common",
  },
  {
    label: "LE TRIOULOU",
    value: "15242",
    type: "common",
  },
  {
    label: "SAINT-SANTIN-DE-MAURS",
    value: "15212",
    type: "common",
  },
  {
    label: "MONTMURAT",
    value: "15133",
    type: "common",
  },
  {
    label: "SAINT-SANTIN",
    value: "12246",
    type: "common",
  },
  {
    label: "SAINT-JEAN-MIRABEL",
    value: "46272",
    type: "common",
  },
  {
    label: "BAGNAC-SUR-CELE",
    value: "46015",
    type: "common",
  },
  {
    label: "VIAZAC",
    value: "46332",
    type: "common",
  },
  {
    label: "PLANIOLES",
    value: "46221",
    type: "common",
  },
  {
    label: "LISSAC-ET-MOURET",
    value: "46175",
    type: "common",
  },
  {
    label: "FOURMAGNAC",
    value: "46111",
    type: "common",
  },
  {
    label: "FONS",
    value: "46108",
    type: "common",
  },
  {
    label: "CAMBURAT",
    value: "46053",
    type: "common",
  },
  {
    label: "REYREVIGNES",
    value: "46237",
    type: "common",
  },
  {
    label: "CAMBES",
    value: "46051",
    type: "common",
  },
  {
    label: "LIVERNON",
    value: "46176",
    type: "common",
  },
  {
    label: "GREZES",
    value: "46131",
    type: "common",
  },
  {
    label: "QUISSAC",
    value: "46233",
    type: "common",
  },
  {
    label: "ESPEDAILLAC",
    value: "46094",
    type: "common",
  },
  {
    label: "CANIAC-DU-CAUSSE",
    value: "46054",
    type: "common",
  },
  {
    label: "SOULOMES",
    value: "46310",
    type: "common",
  },
  {
    label: "CŒUR DE CAUSSE",
    value: "46138",
    type: "common",
  },
  {
    label: "LAMOTHE-CASSEL",
    value: "46151",
    type: "common",
  },
  {
    label: "FRAYSSINET",
    value: "46113",
    type: "common",
  },
  {
    label: "SAINT-GERMAIN-DU-BEL-AIR",
    value: "46267",
    type: "common",
  },
  {
    label: "PEYRILLES",
    value: "46219",
    type: "common",
  },
  {
    label: "RAMPOUX",
    value: "46234",
    type: "common",
  },
  {
    label: "LAVERCANTIERE",
    value: "46164",
    type: "common",
  },
  {
    label: "MONTCLERA",
    value: "46200",
    type: "common",
  },
  {
    label: "GINDOU",
    value: "46120",
    type: "common",
  },
  {
    label: "CAZALS",
    value: "46066",
    type: "common",
  },
  {
    label: "SAINT-CAPRAIS",
    value: "46250",
    type: "common",
  },
  {
    label: "MARMINIAC",
    value: "46184",
    type: "common",
  },
  {
    label: "VILLEFRANCHE-DU-PERIGORD",
    value: "24585",
    type: "common",
  },
  {
    label: "SAINT-CERNIN-DE-L'HERM",
    value: "24386",
    type: "common",
  },
  {
    label: "BESSE",
    value: "24039",
    type: "common",
  },
  {
    label: "BLANQUEFORT-SUR-BRIOLANCE",
    value: "47029",
    type: "common",
  },
  {
    label: "LAVAUR",
    value: "24232",
    type: "common",
  },
  {
    label: "SOULAURES",
    value: "24542",
    type: "common",
  },
  {
    label: "BIRON",
    value: "24043",
    type: "common",
  },
  {
    label: "SAINT-MARTIN-DE-VILLEREAL",
    value: "47256",
    type: "common",
  },
  {
    label: "PARRANQUET",
    value: "47200",
    type: "common",
  },
  {
    label: "DEVILLAC",
    value: "47080",
    type: "common",
  },
  {
    label: "VERGT-DE-BIRON",
    value: "24572",
    type: "common",
  },
  {
    label: "VILLEREAL",
    value: "47324",
    type: "common",
  },
  {
    label: "SAINT-ETIENNE-DE-VILLEREAL",
    value: "47240",
    type: "common",
  },
  {
    label: "RIVES",
    value: "47223",
    type: "common",
  },
  {
    label: "MAZIERES-NARESSE",
    value: "47164",
    type: "common",
  },
  {
    label: "FERRENSAC",
    value: "47096",
    type: "common",
  },
  {
    label: "BOURNEL",
    value: "47037",
    type: "common",
  },
  {
    label: "MONTAURIOL",
    value: "47183",
    type: "common",
  },
  {
    label: "DOUZAINS",
    value: "47084",
    type: "common",
  },
  {
    label: "CASTILLONNES",
    value: "47057",
    type: "common",
  },
  {
    label: "SAINT-COLOMB-DE-LAUZUN",
    value: "47235",
    type: "common",
  },
  {
    label: "LAUZUN",
    value: "47142",
    type: "common",
  },
  {
    label: "SERIGNAC-PEBOUDOU",
    value: "47299",
    type: "common",
  },
  {
    label: "BOURGOUGNAGUE",
    value: "47035",
    type: "common",
  },
  {
    label: "AGNAC",
    value: "47003",
    type: "common",
  },
  {
    label: "ROUMAGNE",
    value: "47226",
    type: "common",
  },
  {
    label: "SAINT-PARDOUX-ISAAC",
    value: "47264",
    type: "common",
  },
  {
    label: "PUYSSERAMPION",
    value: "47218",
    type: "common",
  },
  {
    label: "MOUSTIER",
    value: "47194",
    type: "common",
  },
  {
    label: "LA SAUVETAT-DU-DROPT",
    value: "47290",
    type: "common",
  },
  {
    label: "MONTETON",
    value: "47187",
    type: "common",
  },
  {
    label: "ALLEMANS-DU-DROPT",
    value: "47005",
    type: "common",
  },
  {
    label: "LEVIGNAC-DE-GUYENNE",
    value: "47147",
    type: "common",
  },
  {
    label: "SAINT-PIERRE-SUR-DROPT",
    value: "47271",
    type: "common",
  },
  {
    label: "SAINT-GERAUD",
    value: "47245",
    type: "common",
  },
  {
    label: "TAILLECAVAT",
    value: "33520",
    type: "common",
  },
  {
    label: "SAINT-VIVIEN-DE-MONSEGUR",
    value: "33491",
    type: "common",
  },
  {
    label: "SAINT-SULPICE-DE-GUILLERAGUES",
    value: "33481",
    type: "common",
  },
  {
    label: "SAINTE-GEMME",
    value: "33404",
    type: "common",
  },
  {
    label: "MONSEGUR",
    value: "33289",
    type: "common",
  },
  {
    label: "FOSSES-ET-BALEYSSAC",
    value: "33171",
    type: "common",
  },
  {
    label: "COURS-DE-MONSEGUR",
    value: "33136",
    type: "common",
  },
  {
    label: "SAINT-SEVE",
    value: "33479",
    type: "common",
  },
  {
    label: "SAINT-HILAIRE-DE-LA-NOAILLE",
    value: "33418",
    type: "common",
  },
  {
    label: "ROQUEBRUNE",
    value: "33359",
    type: "common",
  },
  {
    label: "NEUFFONS",
    value: "33304",
    type: "common",
  },
  {
    label: "MESTERRIEUX",
    value: "33283",
    type: "common",
  },
  {
    label: "LOUBENS",
    value: "33250",
    type: "common",
  },
  {
    label: "COUTURES",
    value: "33139",
    type: "common",
  },
  {
    label: "SAINT-MARTIN-DE-LERM",
    value: "33443",
    type: "common",
  },
  {
    label: "SAINT-EXUPERY",
    value: "33398",
    type: "common",
  },
  {
    label: "MORIZES",
    value: "33294",
    type: "common",
  },
  {
    label: "GIRONDE-SUR-DROPT",
    value: "33187",
    type: "common",
  },
  {
    label: "LES ESSEINTES",
    value: "33158",
    type: "common",
  },
  {
    label: "CAMIRAN",
    value: "33087",
    type: "common",
  },
  {
    label: "BAGAS",
    value: "33024",
    type: "common",
  },
  {
    label: "SAINT-MARTIAL",
    value: "33440",
    type: "common",
  },
  {
    label: "SAINT-LAURENT-DU-PLAN",
    value: "33428",
    type: "common",
  },
  {
    label: "SAINT-LAURENT-DU-BOIS",
    value: "33427",
    type: "common",
  },
  {
    label: "SAINTE-FOY-LA-LONGUE",
    value: "33403",
    type: "common",
  },
  {
    label: "SAINT-ANDRE-DU-BOIS",
    value: "33367",
    type: "common",
  },
  {
    label: "CASSEUIL",
    value: "33102",
    type: "common",
  },
  {
    label: "VERDELAIS",
    value: "33543",
    type: "common",
  },
  {
    label: "SEMENS",
    value: "33510",
    type: "common",
  },
  {
    label: "SAINT-GERMAIN-DE-GRAVE",
    value: "33411",
    type: "common",
  },
  {
    label: "SAINTE-CROIX-DU-MONT",
    value: "33392",
    type: "common",
  },
  {
    label: "MOURENS",
    value: "33299",
    type: "common",
  },
  {
    label: "MONPRIMBLANC",
    value: "33288",
    type: "common",
  },
  {
    label: "GABARNAC",
    value: "33176",
    type: "common",
  },
  {
    label: "LOUPIAC",
    value: "33253",
    type: "common",
  },
  {
    label: "CERONS",
    value: "33120",
    type: "common",
  },
  {
    label: "CADILLAC",
    value: "33081",
    type: "common",
  },
  {
    label: "BARSAC",
    value: "33030",
    type: "common",
  },
  {
    label: "SAINT-MICHEL-DE-RIEUFRET",
    value: "33452",
    type: "common",
  },
  {
    label: "ILLATS",
    value: "33205",
    type: "common",
  },
  {
    label: "CABANAC-ET-VILLAGRAINS",
    value: "33077",
    type: "common",
  },
  {
    label: "MIOS",
    value: "33284",
    type: "common",
  },
  {
    label: "LE TEICH",
    value: "33527",
    type: "common",
  },
  {
    label: "GUJAN-MESTRAS",
    value: "33199",
    type: "common",
  },
  {
    label: "VAL D'ORONAYE",
    value: "04120",
    type: "common",
  },
  {
    label: "LES ORRES",
    value: "05098",
    type: "common",
  },
  {
    label: "SAINT-SAUVEUR",
    value: "05156",
    type: "common",
  },
  {
    label: "BARATIER",
    value: "05012",
    type: "common",
  },
  {
    label: "CROTS",
    value: "05045",
    type: "common",
  },
  {
    label: "LE SAUZE-DU-LAC",
    value: "05163",
    type: "common",
  },
  {
    label: "SAVINES-LE-LAC",
    value: "05164",
    type: "common",
  },
  {
    label: "PRUNIERES",
    value: "05106",
    type: "common",
  },
  {
    label: "PONTIS",
    value: "04154",
    type: "common",
  },
  {
    label: "ROUSSET",
    value: "05127",
    type: "common",
  },
  {
    label: "MONTGARDIN",
    value: "05084",
    type: "common",
  },
  {
    label: "ESPINASSES",
    value: "05050",
    type: "common",
  },
  {
    label: "SAINT-ETIENNE-LE-LAUS",
    value: "05140",
    type: "common",
  },
  {
    label: "AVANCON",
    value: "05011",
    type: "common",
  },
  {
    label: "VALSERRES",
    value: "05176",
    type: "common",
  },
  {
    label: "RAMBAUD",
    value: "05113",
    type: "common",
  },
  {
    label: "JARJAYES",
    value: "05068",
    type: "common",
  },
  {
    label: "PELLEAUTIER",
    value: "05100",
    type: "common",
  },
  {
    label: "NEFFES",
    value: "05092",
    type: "common",
  },
  {
    label: "LA FREISSINOUSE",
    value: "05059",
    type: "common",
  },
  {
    label: "MANTEYER",
    value: "05075",
    type: "common",
  },
  {
    label: "FURMEYER",
    value: "05060",
    type: "common",
  },
  {
    label: "CHATEAUNEUF-D'OZE",
    value: "05035",
    type: "common",
  },
  {
    label: "OZE",
    value: "05099",
    type: "common",
  },
  {
    label: "ASPRES-SUR-BUECH",
    value: "05010",
    type: "common",
  },
  {
    label: "SAINT-PIERRE-D'ARGENCON",
    value: "05154",
    type: "common",
  },
  {
    label: "LA FAURIE",
    value: "05055",
    type: "common",
  },
  {
    label: "LES PRES",
    value: "26255",
    type: "common",
  },
  {
    label: "LA BATIE-DES-FONDS",
    value: "26030",
    type: "common",
  },
  {
    label: "LA HAUTE-BEAUME",
    value: "05066",
    type: "common",
  },
  {
    label: "LA BEAUME",
    value: "05019",
    type: "common",
  },
  {
    label: "CHARENS",
    value: "26076",
    type: "common",
  },
  {
    label: "BEAURIERES",
    value: "26040",
    type: "common",
  },
  {
    label: "SAINT-DIZIER-EN-DIOIS",
    value: "26300",
    type: "common",
  },
  {
    label: "BELLEGARDE-EN-DIOIS",
    value: "26047",
    type: "common",
  },
  {
    label: "BEAUMONT-EN-DIOIS",
    value: "26036",
    type: "common",
  },
  {
    label: "VOLVENT",
    value: "26378",
    type: "common",
  },
  {
    label: "JONCHERES",
    value: "26152",
    type: "common",
  },
  {
    label: "CHALANCON",
    value: "26067",
    type: "common",
  },
  {
    label: "SAINT-NAZAIRE-LE-DESERT",
    value: "26321",
    type: "common",
  },
  {
    label: "CRUPIES",
    value: "26111",
    type: "common",
  },
  {
    label: "BOUVIERES",
    value: "26060",
    type: "common",
  },
  {
    label: "TRUINAS",
    value: "26356",
    type: "common",
  },
  {
    label: "ORCINAS",
    value: "26222",
    type: "common",
  },
  {
    label: "COMPS",
    value: "26101",
    type: "common",
  },
  {
    label: "ROCHEBAUDIN",
    value: "26268",
    type: "common",
  },
  {
    label: "LE POET-LAVAL",
    value: "26243",
    type: "common",
  },
  {
    label: "FELINES-SUR-RIMANDOULE",
    value: "26134",
    type: "common",
  },
  {
    label: "EYZAHUT",
    value: "26131",
    type: "common",
  },
  {
    label: "SOUSPIERRE",
    value: "26343",
    type: "common",
  },
  {
    label: "SALETTES",
    value: "26334",
    type: "common",
  },
  {
    label: "LA BEGUDE-DE-MAZENC",
    value: "26045",
    type: "common",
  },
  {
    label: "SAINT-GERVAIS-SUR-ROUBION",
    value: "26305",
    type: "common",
  },
  {
    label: "PUYGIRON",
    value: "26257",
    type: "common",
  },
  {
    label: "LA BATIE-ROLLAND",
    value: "26031",
    type: "common",
  },
  {
    label: "SAUZET",
    value: "26338",
    type: "common",
  },
  {
    label: "MONTBOUCHER-SUR-JABRON",
    value: "26191",
    type: "common",
  },
  {
    label: "MONTELIMAR",
    value: "26198",
    type: "common",
  },
  {
    label: "ANCONE",
    value: "26008",
    type: "common",
  },
  {
    label: "LE TEIL",
    value: "07319",
    type: "common",
  },
  {
    label: "ROCHEMAURE",
    value: "07191",
    type: "common",
  },
  {
    label: "AUBIGNAS",
    value: "07020",
    type: "common",
  },
  {
    label: "ALBA-LA-ROMAINE",
    value: "07005",
    type: "common",
  },
  {
    label: "SAINT-JEAN-LE-CENTENIER",
    value: "07247",
    type: "common",
  },
  {
    label: "VILLENEUVE-DE-BERG",
    value: "07341",
    type: "common",
  },
  {
    label: "SAINT-GERMAIN",
    value: "07241",
    type: "common",
  },
  {
    label: "LAVILLEDIEU",
    value: "07138",
    type: "common",
  },
  {
    label: "VOGUE",
    value: "07348",
    type: "common",
  },
  {
    label: "SAINT-SERNIN",
    value: "07296",
    type: "common",
  },
  {
    label: "LACHAPELLE-SOUS-AUBENAS",
    value: "07122",
    type: "common",
  },
  {
    label: "FONS",
    value: "07091",
    type: "common",
  },
  {
    label: "LARGENTIERE",
    value: "07132",
    type: "common",
  },
  {
    label: "TAURIERS",
    value: "07318",
    type: "common",
  },
  {
    label: "ROCHER",
    value: "07193",
    type: "common",
  },
  {
    label: "CHAZEAUX",
    value: "07062",
    type: "common",
  },
  {
    label: "CHASSIERS",
    value: "07058",
    type: "common",
  },
  {
    label: "ROCLES",
    value: "07196",
    type: "common",
  },
  {
    label: "PRUNET",
    value: "07187",
    type: "common",
  },
  {
    label: "JOANNAS",
    value: "07109",
    type: "common",
  },
  {
    label: "VALGORGE",
    value: "07329",
    type: "common",
  },
  {
    label: "LABOULE",
    value: "07118",
    type: "common",
  },
  {
    label: "DOMPNAC",
    value: "07081",
    type: "common",
  },
  {
    label: "BEAUMONT",
    value: "07029",
    type: "common",
  },
  {
    label: "SABLIERES",
    value: "07202",
    type: "common",
  },
  {
    label: "LOUBARESSE",
    value: "07144",
    type: "common",
  },
  {
    label: "LA BASTIDE-PUYLAURENT",
    value: "48021",
    type: "common",
  },
  {
    label: "SAINT-FREZAL-D'ALBUGES",
    value: "48151",
    type: "common",
  },
  {
    label: "MONTBEL",
    value: "48100",
    type: "common",
  },
  {
    label: "PELOUSE",
    value: "48111",
    type: "common",
  },
  {
    label: "LAUBERT",
    value: "48082",
    type: "common",
  },
  {
    label: "ALLENC",
    value: "48003",
    type: "common",
  },
  {
    label: "LE BORN",
    value: "48029",
    type: "common",
  },
  {
    label: "CHASTEL-NOUVEL",
    value: "48042",
    type: "common",
  },
  {
    label: "GABRIAS",
    value: "48068",
    type: "common",
  },
  {
    label: "SAINT-LEGER-DE-PEYRE",
    value: "48168",
    type: "common",
  },
  {
    label: "MONTRODAT",
    value: "48103",
    type: "common",
  },
  {
    label: "MARVEJOLS",
    value: "48092",
    type: "common",
  },
  {
    label: "ANTRENAS",
    value: "48005",
    type: "common",
  },
  {
    label: "LES SALCES",
    value: "48187",
    type: "common",
  },
  {
    label: "SAINT-LAURENT-DE-MURET",
    value: "48165",
    type: "common",
  },
  {
    label: "SAINT-CHELY-D'AUBRAC",
    value: "12214",
    type: "common",
  },
  {
    label: "CONDOM-D'AUBRAC",
    value: "12074",
    type: "common",
  },
  {
    label: "LE CAYROL",
    value: "12064",
    type: "common",
  },
  {
    label: "COUBISOU",
    value: "12079",
    type: "common",
  },
  {
    label: "LE NAYRAC",
    value: "12172",
    type: "common",
  },
  {
    label: "ESTAING",
    value: "12098",
    type: "common",
  },
  {
    label: "GOLINHAC",
    value: "12110",
    type: "common",
  },
  {
    label: "CAMPUAC",
    value: "12049",
    type: "common",
  },
  {
    label: "SAINT-FELIX-DE-LUNEL",
    value: "12221",
    type: "common",
  },
  {
    label: "CONQUES-EN-ROUERGUE",
    value: "12076",
    type: "common",
  },
  {
    label: "ALMONT-LES-JUNIES",
    value: "12004",
    type: "common",
  },
  {
    label: "MONTREDON",
    value: "46207",
    type: "common",
  },
  {
    label: "LIVINHAC-LE-HAUT",
    value: "12130",
    type: "common",
  },
  {
    label: "FLAGNAC",
    value: "12101",
    type: "common",
  },
  {
    label: "DECAZEVILLE",
    value: "12089",
    type: "common",
  },
  {
    label: "BOISSE-PENCHOT",
    value: "12028",
    type: "common",
  },
  {
    label: "SAINT-FELIX",
    value: "46266",
    type: "common",
  },
  {
    label: "LENTILLAC-SAINT-BLAISE",
    value: "46168",
    type: "common",
  },
  {
    label: "FELZINS",
    value: "46101",
    type: "common",
  },
  {
    label: "CUZAC",
    value: "46085",
    type: "common",
  },
  {
    label: "BOUILLAC",
    value: "12030",
    type: "common",
  },
  {
    label: "LUNAN",
    value: "46180",
    type: "common",
  },
  {
    label: "FIGEAC",
    value: "46102",
    type: "common",
  },
  {
    label: "CAPDENAC",
    value: "46055",
    type: "common",
  },
  {
    label: "CAPDENAC-GARE",
    value: "12052",
    type: "common",
  },
  {
    label: "FAYCELLES",
    value: "46100",
    type: "common",
  },
  {
    label: "CAMBOULIT",
    value: "46052",
    type: "common",
  },
  {
    label: "CORN",
    value: "46075",
    type: "common",
  },
  {
    label: "BOUSSAC",
    value: "46035",
    type: "common",
  },
  {
    label: "BEDUER",
    value: "46021",
    type: "common",
  },
  {
    label: "SAINT-SULPICE",
    value: "46294",
    type: "common",
  },
  {
    label: "ESPAGNAC-SAINTE-EULALIE",
    value: "46093",
    type: "common",
  },
  {
    label: "BRENGUES",
    value: "46039",
    type: "common",
  },
  {
    label: "BLARS",
    value: "46031",
    type: "common",
  },
  {
    label: "LENTILLAC-DU-CAUSSE",
    value: "46167",
    type: "common",
  },
  {
    label: "SENAILLAC-LAUZES",
    value: "46303",
    type: "common",
  },
  {
    label: "LES PECHS DU VERS",
    value: "46252",
    type: "common",
  },
  {
    label: "SABADEL-LAUZES",
    value: "46245",
    type: "common",
  },
  {
    label: "LAUZES",
    value: "46162",
    type: "common",
  },
  {
    label: "CRAS",
    value: "46079",
    type: "common",
  },
  {
    label: "USSEL",
    value: "46323",
    type: "common",
  },
  {
    label: "NADILLAC",
    value: "46210",
    type: "common",
  },
  {
    label: "MONTAMEL",
    value: "46196",
    type: "common",
  },
  {
    label: "MECHMONT",
    value: "46190",
    type: "common",
  },
  {
    label: "UZECH",
    value: "46324",
    type: "common",
  },
  {
    label: "SAINT-DENIS-CATUS",
    value: "46264",
    type: "common",
  },
  {
    label: "GIGOUZAC",
    value: "46119",
    type: "common",
  },
  {
    label: "THEDIRAC",
    value: "46316",
    type: "common",
  },
  {
    label: "MONTGESTY",
    value: "46205",
    type: "common",
  },
  {
    label: "CATUS",
    value: "46064",
    type: "common",
  },
  {
    label: "LHERM",
    value: "46171",
    type: "common",
  },
  {
    label: "LES ARQUES",
    value: "46008",
    type: "common",
  },
  {
    label: "POMAREDE",
    value: "46222",
    type: "common",
  },
  {
    label: "GOUJOUNAC",
    value: "46126",
    type: "common",
  },
  {
    label: "FRAYSSINET-LE-GELAT",
    value: "46114",
    type: "common",
  },
  {
    label: "CASSAGNES",
    value: "46061",
    type: "common",
  },
  {
    label: "MONTCABRIER",
    value: "46199",
    type: "common",
  },
  {
    label: "LOUBEJAC",
    value: "24245",
    type: "common",
  },
  {
    label: "SAUVETERRE-LA-LEMANCE",
    value: "47292",
    type: "common",
  },
  {
    label: "SAINT-FRONT-SUR-LEMANCE",
    value: "47242",
    type: "common",
  },
  {
    label: "LACAPELLE-BIRON",
    value: "47123",
    type: "common",
  },
  {
    label: "GAVAUDUN",
    value: "47109",
    type: "common",
  },
  {
    label: "CUZORN",
    value: "47077",
    type: "common",
  },
  {
    label: "PAULHIAC",
    value: "47202",
    type: "common",
  },
  {
    label: "LAUSSOU",
    value: "47141",
    type: "common",
  },
  {
    label: "SAINT-EUTROPE-DE-BORN",
    value: "47241",
    type: "common",
  },
  {
    label: "MONTAUT",
    value: "47184",
    type: "common",
  },
  {
    label: "BOUDY-DE-BEAUREGARD",
    value: "47033",
    type: "common",
  },
  {
    label: "SAINT-MAURICE-DE-LESTAPEL",
    value: "47259",
    type: "common",
  },
  {
    label: "MOULINET",
    value: "47193",
    type: "common",
  },
  {
    label: "LOUGRATTE",
    value: "47152",
    type: "common",
  },
  {
    label: "SEGALAS",
    value: "47296",
    type: "common",
  },
  {
    label: "MONTIGNAC-DE-LAUZUN",
    value: "47188",
    type: "common",
  },
  {
    label: "MONVIEL",
    value: "47192",
    type: "common",
  },
  {
    label: "LAPERCHE",
    value: "47136",
    type: "common",
  },
  {
    label: "ARMILLAC",
    value: "47014",
    type: "common",
  },
  {
    label: "LAVERGNE",
    value: "47144",
    type: "common",
  },
  {
    label: "MONTIGNAC-TOUPINERIE",
    value: "47189",
    type: "common",
  },
  {
    label: "PEYRIERE",
    value: "47204",
    type: "common",
  },
  {
    label: "MIRAMONT-DE-GUYENNE",
    value: "47168",
    type: "common",
  },
  {
    label: "CAMBES",
    value: "47047",
    type: "common",
  },
  {
    label: "SAINT-AVIT",
    value: "47231",
    type: "common",
  },
  {
    label: "LACHAPELLE",
    value: "47126",
    type: "common",
  },
  {
    label: "ESCASSEFORT",
    value: "47088",
    type: "common",
  },
  {
    label: "SEYCHES",
    value: "47301",
    type: "common",
  },
  {
    label: "MAUVEZIN-SUR-GUPIE",
    value: "47163",
    type: "common",
  },
  {
    label: "CAUBON-SAINT-SAUVEUR",
    value: "47059",
    type: "common",
  },
  {
    label: "CASTELNAU-SUR-GUPIE",
    value: "47056",
    type: "common",
  },
  {
    label: "LAGUPIE",
    value: "47131",
    type: "common",
  },
  {
    label: "SAINT-MARTIN-PETIT",
    value: "47257",
    type: "common",
  },
  {
    label: "SAINT-MICHEL-DE-LAPUJADE",
    value: "33453",
    type: "common",
  },
  {
    label: "LAMOTHE-LANDERRON",
    value: "33221",
    type: "common",
  },
  {
    label: "JUSIX",
    value: "47120",
    type: "common",
  },
  {
    label: "MONTAGOUDIN",
    value: "33291",
    type: "common",
  },
  {
    label: "MONGAUZY",
    value: "33287",
    type: "common",
  },
  {
    label: "FONTET",
    value: "33170",
    type: "common",
  },
  {
    label: "BOURDELLES",
    value: "33066",
    type: "common",
  },
  {
    label: "LA REOLE",
    value: "33352",
    type: "common",
  },
  {
    label: "PUYBARBAN",
    value: "33346",
    type: "common",
  },
  {
    label: "PONDAURAT",
    value: "33331",
    type: "common",
  },
  {
    label: "LOUPIAC-DE-LA-REOLE",
    value: "33254",
    type: "common",
  },
  {
    label: "FLOUDES",
    value: "33169",
    type: "common",
  },
  {
    label: "BLAIGNAC",
    value: "33054",
    type: "common",
  },
  {
    label: "BASSANNE",
    value: "33031",
    type: "common",
  },
  {
    label: "BARIE",
    value: "33027",
    type: "common",
  },
  {
    label: "SAINT-PIERRE-D'AURILLAC",
    value: "33463",
    type: "common",
  },
  {
    label: "SAINT-PARDON-DE-CONQUES",
    value: "33457",
    type: "common",
  },
  {
    label: "SAINT-MARTIN-DE-SESCAS",
    value: "33444",
    type: "common",
  },
  {
    label: "SAINT-LOUBERT",
    value: "33432",
    type: "common",
  },
  {
    label: "CAUDROT",
    value: "33111",
    type: "common",
  },
  {
    label: "CASTETS ET CASTILLON",
    value: "33106",
    type: "common",
  },
  {
    label: "BIEUJAC",
    value: "33050",
    type: "common",
  },
  {
    label: "TOULENNE",
    value: "33533",
    type: "common",
  },
  {
    label: "SAINT-PIERRE-DE-MONS",
    value: "33465",
    type: "common",
  },
  {
    label: "SAINT-MAIXANT",
    value: "33438",
    type: "common",
  },
  {
    label: "SAINT-MACAIRE",
    value: "33435",
    type: "common",
  },
  {
    label: "LE PIAN-SUR-GARONNE",
    value: "33323",
    type: "common",
  },
  {
    label: "LANGON",
    value: "33227",
    type: "common",
  },
  {
    label: "SAUTERNES",
    value: "33504",
    type: "common",
  },
  {
    label: "PUJOLS-SUR-CIRON",
    value: "33343",
    type: "common",
  },
  {
    label: "PREIGNAC",
    value: "33337",
    type: "common",
  },
  {
    label: "FARGUES",
    value: "33164",
    type: "common",
  },
  {
    label: "BOMMES",
    value: "33060",
    type: "common",
  },
  {
    label: "LANDIRAS",
    value: "33225",
    type: "common",
  },
  {
    label: "BUDOS",
    value: "33076",
    type: "common",
  },
  {
    label: "GUILLOS",
    value: "33197",
    type: "common",
  },
  {
    label: "SAINT-MAGNE",
    value: "33436",
    type: "common",
  },
  {
    label: "SALLES",
    value: "33498",
    type: "common",
  },
  {
    label: "FAUCON-DE-BARCELONNETTE",
    value: "04086",
    type: "common",
  },
  {
    label: "LA CONDAMINE-CHATELARD",
    value: "04062",
    type: "common",
  },
  {
    label: "SAINT-PONS",
    value: "04195",
    type: "common",
  },
  {
    label: "LE LAUZET-UBAYE",
    value: "04102",
    type: "common",
  },
  {
    label: "UBAYE-SERRE-PONCON",
    value: "04033",
    type: "common",
  },
  {
    label: "THEUS",
    value: "05171",
    type: "common",
  },
  {
    label: "ROCHEBRUNE",
    value: "05121",
    type: "common",
  },
  {
    label: "REMOLLON",
    value: "05115",
    type: "common",
  },
  {
    label: "LETTRET",
    value: "05074",
    type: "common",
  },
  {
    label: "VENTEROL",
    value: "04234",
    type: "common",
  },
  {
    label: "PIEGUT",
    value: "04150",
    type: "common",
  },
  {
    label: "TALLARD",
    value: "05170",
    type: "common",
  },
  {
    label: "FOUILLOUSE",
    value: "05057",
    type: "common",
  },
  {
    label: "CHATEAUVIEUX",
    value: "05037",
    type: "common",
  },
  {
    label: "SIGOYER",
    value: "05168",
    type: "common",
  },
  {
    label: "LARDIER-ET-VALENCA",
    value: "05071",
    type: "common",
  },
  {
    label: "ESPARRON",
    value: "05049",
    type: "common",
  },
  {
    label: "LE SAIX",
    value: "05158",
    type: "common",
  },
  {
    label: "SAINT-AUBAN-D'OZE",
    value: "05131",
    type: "common",
  },
  {
    label: "CHABESTAN",
    value: "05028",
    type: "common",
  },
  {
    label: "LA BATIE-MONTSALEON",
    value: "05016",
    type: "common",
  },
  {
    label: "SIGOTTIER",
    value: "05167",
    type: "common",
  },
  {
    label: "ASPREMONT",
    value: "05008",
    type: "common",
  },
  {
    label: "LA PIARRE",
    value: "05102",
    type: "common",
  },
  {
    label: "VALDROME",
    value: "26361",
    type: "common",
  },
  {
    label: "VALDOULE",
    value: "05024",
    type: "common",
  },
  {
    label: "ROTTIER",
    value: "26283",
    type: "common",
  },
  {
    label: "ESTABLET",
    value: "26123",
    type: "common",
  },
  {
    label: "LA CHARCE",
    value: "26075",
    type: "common",
  },
  {
    label: "LA MOTTE-CHALANCON",
    value: "26215",
    type: "common",
  },
  {
    label: "CORNILLON-SUR-L'OULE",
    value: "26105",
    type: "common",
  },
  {
    label: "GUMIANE",
    value: "26147",
    type: "common",
  },
  {
    label: "ARNAYON",
    value: "26012",
    type: "common",
  },
  {
    label: "CHAUDEBONNE",
    value: "26089",
    type: "common",
  },
  {
    label: "VESC",
    value: "26373",
    type: "common",
  },
  {
    label: "MONTJOUX",
    value: "26202",
    type: "common",
  },
  {
    label: "ROCHE-SAINT-SECRET-BECONNE",
    value: "26276",
    type: "common",
  },
  {
    label: "DIEULEFIT",
    value: "26114",
    type: "common",
  },
  {
    label: "SALLES-SOUS-BOIS",
    value: "26335",
    type: "common",
  },
  {
    label: "PORTES-EN-VALDAINE",
    value: "26251",
    type: "common",
  },
  {
    label: "ALEYRAC",
    value: "26003",
    type: "common",
  },
  {
    label: "LA TOUCHE",
    value: "26352",
    type: "common",
  },
  {
    label: "ROCHEFORT-EN-VALDAINE",
    value: "26272",
    type: "common",
  },
  {
    label: "MONTJOYER",
    value: "26203",
    type: "common",
  },
  {
    label: "MALATAVERNE",
    value: "26169",
    type: "common",
  },
  {
    label: "ESPELUCHE",
    value: "26121",
    type: "common",
  },
  {
    label: "ALLAN",
    value: "26005",
    type: "common",
  },
  {
    label: "CHATEAUNEUF-DU-RHONE",
    value: "26085",
    type: "common",
  },
  {
    label: "VIVIERS",
    value: "07346",
    type: "common",
  },
  {
    label: "SAINT-THOME",
    value: "07300",
    type: "common",
  },
  {
    label: "VALVIGNERES",
    value: "07332",
    type: "common",
  },
  {
    label: "SAINT-ANDEOL-DE-BERG",
    value: "07208",
    type: "common",
  },
  {
    label: "SAINT-MAURICE-D'IBIE",
    value: "07273",
    type: "common",
  },
  {
    label: "ROCHECOLOMBE",
    value: "07190",
    type: "common",
  },
  {
    label: "SAINT-MAURICE-D'ARDECHE",
    value: "07272",
    type: "common",
  },
  {
    label: "LANAS",
    value: "07131",
    type: "common",
  },
  {
    label: "CHAUZON",
    value: "07061",
    type: "common",
  },
  {
    label: "BALAZUC",
    value: "07023",
    type: "common",
  },
  {
    label: "VINEZAC",
    value: "07343",
    type: "common",
  },
  {
    label: "UZER",
    value: "07327",
    type: "common",
  },
  {
    label: "ROSIERES",
    value: "07199",
    type: "common",
  },
  {
    label: "MONTREAL",
    value: "07162",
    type: "common",
  },
  {
    label: "LAURAC-EN-VIVARAIS",
    value: "07134",
    type: "common",
  },
  {
    label: "VERNON",
    value: "07336",
    type: "common",
  },
  {
    label: "SANILHAC",
    value: "07307",
    type: "common",
  },
  {
    label: "RIBES",
    value: "07189",
    type: "common",
  },
  {
    label: "SAINT-MELANY",
    value: "07275",
    type: "common",
  },
  {
    label: "SAINT-ANDRE-LACHAMP",
    value: "07213",
    type: "common",
  },
  {
    label: "PLANZOLLES",
    value: "07176",
    type: "common",
  },
  {
    label: "PIED-DE-BORNE",
    value: "48015",
    type: "common",
  },
  {
    label: "SAINTE-MARGUERITE-LAFIGERE",
    value: "07266",
    type: "common",
  },
  {
    label: "MONTSELGUES",
    value: "07163",
    type: "common",
  },
  {
    label: "PREVENCHERES",
    value: "48119",
    type: "common",
  },
  {
    label: "CUBIERES",
    value: "48053",
    type: "common",
  },
  {
    label: "MONT LOZERE ET GOULET",
    value: "48027",
    type: "common",
  },
  {
    label: "SAINTE-HELENE",
    value: "48157",
    type: "common",
  },
  {
    label: "CHADENET",
    value: "48037",
    type: "common",
  },
  {
    label: "LANUEJOLS",
    value: "48081",
    type: "common",
  },
  {
    label: "BADAROUX",
    value: "48013",
    type: "common",
  },
  {
    label: "MENDE",
    value: "48095",
    type: "common",
  },
  {
    label: "BARJAC",
    value: "48018",
    type: "common",
  },
  {
    label: "SAINT-BONNET-DE-CHIRAC",
    value: "48138",
    type: "common",
  },
  {
    label: "PALHERS",
    value: "48107",
    type: "common",
  },
  {
    label: "GREZES",
    value: "48072",
    type: "common",
  },
  {
    label: "BOURGS SUR COLAGNE",
    value: "48099",
    type: "common",
  },
  {
    label: "LES HERMAUX",
    value: "48073",
    type: "common",
  },
  {
    label: "TRELANS",
    value: "48192",
    type: "common",
  },
  {
    label: "PRADES-D'AUBRAC",
    value: "12187",
    type: "common",
  },
  {
    label: "CASTELNAU-DE-MANDAILLES",
    value: "12061",
    type: "common",
  },
  {
    label: "SAINT-COME-D'OLT",
    value: "12216",
    type: "common",
  },
  {
    label: "ESPALION",
    value: "12096",
    type: "common",
  },
  {
    label: "BESSUEJOULS",
    value: "12027",
    type: "common",
  },
  {
    label: "SEBRAZAC",
    value: "12265",
    type: "common",
  },
  {
    label: "VILLECOMTAL",
    value: "12298",
    type: "common",
  },
  {
    label: "PRUINES",
    value: "12193",
    type: "common",
  },
  {
    label: "MOURET",
    value: "12161",
    type: "common",
  },
  {
    label: "NAUVIALE",
    value: "12171",
    type: "common",
  },
  {
    label: "FIRMI",
    value: "12100",
    type: "common",
  },
  {
    label: "CRANSAC",
    value: "12083",
    type: "common",
  },
  {
    label: "VIVIEZ",
    value: "12305",
    type: "common",
  },
  {
    label: "VALZERGUES",
    value: "12289",
    type: "common",
  },
  {
    label: "AUBIN",
    value: "12013",
    type: "common",
  },
  {
    label: "SONNAC",
    value: "12272",
    type: "common",
  },
  {
    label: "PEYRUSSE-LE-ROC",
    value: "12181",
    type: "common",
  },
  {
    label: "GALGAN",
    value: "12108",
    type: "common",
  },
  {
    label: "ASPRIERES",
    value: "12012",
    type: "common",
  },
  {
    label: "LES ALBRES",
    value: "12003",
    type: "common",
  },
  {
    label: "CAUSSE-ET-DIEGE",
    value: "12257",
    type: "common",
  },
  {
    label: "NAUSSAC",
    value: "12170",
    type: "common",
  },
  {
    label: "SAINT-PIERRE-TOIRAC",
    value: "46289",
    type: "common",
  },
  {
    label: "FRONTENAC",
    value: "46116",
    type: "common",
  },
  {
    label: "FOISSAC",
    value: "12104",
    type: "common",
  },
  {
    label: "BALAGUIER-D'OLT",
    value: "12018",
    type: "common",
  },
  {
    label: "MONTBRUN",
    value: "46198",
    type: "common",
  },
  {
    label: "LARROQUE-TOIRAC",
    value: "46157",
    type: "common",
  },
  {
    label: "GREALOU",
    value: "46129",
    type: "common",
  },
  {
    label: "CARAYAC",
    value: "46056",
    type: "common",
  },
  {
    label: "CADRIEU",
    value: "46041",
    type: "common",
  },
  {
    label: "SAINT-CHELS",
    value: "46254",
    type: "common",
  },
  {
    label: "SAULIAC-SUR-CELE",
    value: "46299",
    type: "common",
  },
  {
    label: "MARCILHAC-SUR-CELE",
    value: "46183",
    type: "common",
  },
  {
    label: "ORNIAC",
    value: "46212",
    type: "common",
  },
  {
    label: "CABRERETS",
    value: "46040",
    type: "common",
  },
  {
    label: "SAINT-PIERRE-LAFEUILLE",
    value: "46340",
    type: "common",
  },
  {
    label: "FRANCOULES",
    value: "46112",
    type: "common",
  },
  {
    label: "NUZEJOULS",
    value: "46211",
    type: "common",
  },
  {
    label: "MERCUES",
    value: "46191",
    type: "common",
  },
  {
    label: "MAXOU",
    value: "46188",
    type: "common",
  },
  {
    label: "CALAMANE",
    value: "46046",
    type: "common",
  },
  {
    label: "BOISSIERES",
    value: "46032",
    type: "common",
  },
  {
    label: "SAINT-MEDARD",
    value: "46280",
    type: "common",
  },
  {
    label: "ESPERE",
    value: "46095",
    type: "common",
  },
  {
    label: "CRAYSSAC",
    value: "46080",
    type: "common",
  },
  {
    label: "PONTCIRQ",
    value: "46223",
    type: "common",
  },
  {
    label: "LABASTIDE-DU-VERT",
    value: "46136",
    type: "common",
  },
  {
    label: "LES JUNIES",
    value: "46134",
    type: "common",
  },
  {
    label: "CASTELFRANC",
    value: "46062",
    type: "common",
  },
  {
    label: "PUY-L'EVEQUE",
    value: "46231",
    type: "common",
  },
  {
    label: "PRAYSSAC",
    value: "46225",
    type: "common",
  },
  {
    label: "PESCADOIRES",
    value: "46218",
    type: "common",
  },
  {
    label: "TOUZAC",
    value: "46321",
    type: "common",
  },
  {
    label: "DURAVEL",
    value: "46089",
    type: "common",
  },
  {
    label: "FUMEL",
    value: "47106",
    type: "common",
  },
  {
    label: "SOTURAC",
    value: "46307",
    type: "common",
  },
  {
    label: "SAINT-MARTIN-LE-REDON",
    value: "46277",
    type: "common",
  },
  {
    label: "SALLES",
    value: "47284",
    type: "common",
  },
  {
    label: "MONSEMPRON-LIBOS",
    value: "47179",
    type: "common",
  },
  {
    label: "CONDEZAYGUES",
    value: "47070",
    type: "common",
  },
  {
    label: "MONTAGNAC-SUR-LEDE",
    value: "47181",
    type: "common",
  },
  {
    label: "LACAUSSADE",
    value: "47124",
    type: "common",
  },
  {
    label: "MONFLANQUIN",
    value: "47175",
    type: "common",
  },
  {
    label: "CASTELNAUD-DE-GRATECAMBE",
    value: "47055",
    type: "common",
  },
  {
    label: "CANCON",
    value: "47048",
    type: "common",
  },
  {
    label: "SAINT-PASTOUR",
    value: "47265",
    type: "common",
  },
  {
    label: "BEAUGAS",
    value: "47023",
    type: "common",
  },
  {
    label: "VILLEBRAMAR",
    value: "47319",
    type: "common",
  },
  {
    label: "MONTASTRUC",
    value: "47182",
    type: "common",
  },
  {
    label: "MONBAHUS",
    value: "47170",
    type: "common",
  },
  {
    label: "TOURTRES",
    value: "47313",
    type: "common",
  },
  {
    label: "TOMBEBŒUF",
    value: "47309",
    type: "common",
  },
  {
    label: "PUYMICLAN",
    value: "47216",
    type: "common",
  },
  {
    label: "SAINT-BARTHELEMY-D'AGENAIS",
    value: "47232",
    type: "common",
  },
  {
    label: "AGME",
    value: "47002",
    type: "common",
  },
  {
    label: "LABRETONIE",
    value: "47122",
    type: "common",
  },
  {
    label: "BIRAC-SUR-TREC",
    value: "47028",
    type: "common",
  },
  {
    label: "VIRAZEIL",
    value: "47326",
    type: "common",
  },
  {
    label: "MARMANDE",
    value: "47157",
    type: "common",
  },
  {
    label: "BEAUPUY",
    value: "47024",
    type: "common",
  },
  {
    label: "GAUJAC",
    value: "47108",
    type: "common",
  },
  {
    label: "COUTHURES-SUR-GARONNE",
    value: "47074",
    type: "common",
  },
  {
    label: "MARCELLUS",
    value: "47156",
    type: "common",
  },
  {
    label: "SAINTE-BAZEILLE",
    value: "47233",
    type: "common",
  },
  {
    label: "MEILHAN-SUR-GARONNE",
    value: "47165",
    type: "common",
  },
  {
    label: "SAINT-SAUVEUR-DE-MEILHAN",
    value: "47277",
    type: "common",
  },
  {
    label: "NOAILLAC",
    value: "33306",
    type: "common",
  },
  {
    label: "HURE",
    value: "33204",
    type: "common",
  },
  {
    label: "SAVIGNAC",
    value: "33508",
    type: "common",
  },
  {
    label: "AILLAS",
    value: "33002",
    type: "common",
  },
  {
    label: "BROUQUEYRAN",
    value: "33074",
    type: "common",
  },
  {
    label: "BRANNENS",
    value: "33072",
    type: "common",
  },
  {
    label: "BERTHEZ",
    value: "33048",
    type: "common",
  },
  {
    label: "AUROS",
    value: "33021",
    type: "common",
  },
  {
    label: "MAZERES",
    value: "33279",
    type: "common",
  },
  {
    label: "COIMERES",
    value: "33130",
    type: "common",
  },
  {
    label: "CAZATS",
    value: "33116",
    type: "common",
  },
  {
    label: "AUBIAC",
    value: "33017",
    type: "common",
  },
  {
    label: "ROAILLAN",
    value: "33357",
    type: "common",
  },
  {
    label: "NOAILLAN",
    value: "33307",
    type: "common",
  },
  {
    label: "LE NIZAN",
    value: "33305",
    type: "common",
  },
  {
    label: "LEOGEATS",
    value: "33237",
    type: "common",
  },
  {
    label: "ORIGNE",
    value: "33310",
    type: "common",
  },
  {
    label: "BALIZAC",
    value: "33026",
    type: "common",
  },
  {
    label: "LOUCHATS",
    value: "33251",
    type: "common",
  },
  {
    label: "HOSTENS",
    value: "33202",
    type: "common",
  },
  {
    label: "BELIN-BELIET",
    value: "33042",
    type: "common",
  },
  {
    label: "LUGOS",
    value: "33260",
    type: "common",
  },
  {
    label: "SANGUINET",
    value: "40287",
    type: "common",
  },
  {
    label: "JAUSIERS",
    value: "04096",
    type: "common",
  },
  {
    label: "ENCHASTRAYES",
    value: "04073",
    type: "common",
  },
  {
    label: "BARCELONNETTE",
    value: "04019",
    type: "common",
  },
  {
    label: "LES THUILES",
    value: "04220",
    type: "common",
  },
  {
    label: "MEOLANS-REVEL",
    value: "04161",
    type: "common",
  },
  {
    label: "MONTCLAR",
    value: "04126",
    type: "common",
  },
  {
    label: "SELONNET",
    value: "04203",
    type: "common",
  },
  {
    label: "SAINT-MARTIN-LES-SEYNE",
    value: "04191",
    type: "common",
  },
  {
    label: "BREZIERS",
    value: "05022",
    type: "common",
  },
  {
    label: "TURRIERS",
    value: "04222",
    type: "common",
  },
  {
    label: "BELLAFFAIRE",
    value: "04026",
    type: "common",
  },
  {
    label: "GIGORS",
    value: "04093",
    type: "common",
  },
  {
    label: "FAUCON-DU-CAIRE",
    value: "04085",
    type: "common",
  },
  {
    label: "LE CAIRE",
    value: "04037",
    type: "common",
  },
  {
    label: "LA SAULCE",
    value: "05162",
    type: "common",
  },
  {
    label: "CURBANS",
    value: "04066",
    type: "common",
  },
  {
    label: "VITROLLES",
    value: "05184",
    type: "common",
  },
  {
    label: "MONETIER-ALLEMONT",
    value: "05078",
    type: "common",
  },
  {
    label: "BARCILLONNETTE",
    value: "05013",
    type: "common",
  },
  {
    label: "VENTAVON",
    value: "05178",
    type: "common",
  },
  {
    label: "SAVOURNON",
    value: "05165",
    type: "common",
  },
  {
    label: "MONTROND",
    value: "05089",
    type: "common",
  },
  {
    label: "LE BERSAC",
    value: "05021",
    type: "common",
  },
  {
    label: "SERRES",
    value: "05166",
    type: "common",
  },
  {
    label: "MONTCLUS",
    value: "05081",
    type: "common",
  },
  {
    label: "L'EPINE",
    value: "05048",
    type: "common",
  },
  {
    label: "RIBEYRET",
    value: "05117",
    type: "common",
  },
  {
    label: "MOYDANS",
    value: "05091",
    type: "common",
  },
  {
    label: "POMMEROL",
    value: "26245",
    type: "common",
  },
  {
    label: "CORNILLAC",
    value: "26104",
    type: "common",
  },
  {
    label: "ROSANS",
    value: "05126",
    type: "common",
  },
  {
    label: "VERCLAUSE",
    value: "26369",
    type: "common",
  },
  {
    label: "SAINT-MAY",
    value: "26318",
    type: "common",
  },
  {
    label: "REMUZAT",
    value: "26264",
    type: "common",
  },
  {
    label: "VILLEPERDRIX",
    value: "26376",
    type: "common",
  },
  {
    label: "SAHUNE",
    value: "26288",
    type: "common",
  },
  {
    label: "MONTREAL-LES-SOURCES",
    value: "26209",
    type: "common",
  },
  {
    label: "EYROLES",
    value: "26130",
    type: "common",
  },
  {
    label: "VALOUSE",
    value: "26363",
    type: "common",
  },
  {
    label: "SAINT-FERREOL-TRENTE-PAS",
    value: "26304",
    type: "common",
  },
  {
    label: "CONDORCET",
    value: "26103",
    type: "common",
  },
  {
    label: "TEYSSIERES",
    value: "26350",
    type: "common",
  },
  {
    label: "MONTBRISON-SUR-LEZ",
    value: "26192",
    type: "common",
  },
  {
    label: "ROUSSET-LES-VIGNES",
    value: "26285",
    type: "common",
  },
  {
    label: "LE PEGUE",
    value: "26226",
    type: "common",
  },
  {
    label: "TAULIGNAN",
    value: "26348",
    type: "common",
  },
  {
    label: "REAUVILLE",
    value: "26261",
    type: "common",
  },
  {
    label: "GRIGNAN",
    value: "26146",
    type: "common",
  },
  {
    label: "VALAURIE",
    value: "26360",
    type: "common",
  },
  {
    label: "ROUSSAS",
    value: "26284",
    type: "common",
  },
  {
    label: "LES GRANGES-GONTARDES",
    value: "26145",
    type: "common",
  },
  {
    label: "DONZERE",
    value: "26116",
    type: "common",
  },
  {
    label: "SAINT-MONTAN",
    value: "07279",
    type: "common",
  },
  {
    label: "LARNAS",
    value: "07133",
    type: "common",
  },
  {
    label: "GRAS",
    value: "07099",
    type: "common",
  },
  {
    label: "RUOMS",
    value: "07201",
    type: "common",
  },
  {
    label: "PRADONS",
    value: "07183",
    type: "common",
  },
  {
    label: "LAGORCE",
    value: "07126",
    type: "common",
  },
  {
    label: "SAINT-ALBAN-AURIOLLES",
    value: "07207",
    type: "common",
  },
  {
    label: "LABEAUME",
    value: "07115",
    type: "common",
  },
  {
    label: "SAINT-GENEST-DE-BEAUZON",
    value: "07238",
    type: "common",
  },
  {
    label: "LABLACHERE",
    value: "07117",
    type: "common",
  },
  {
    label: "JOYEUSE",
    value: "07110",
    type: "common",
  },
  {
    label: "LES ASSIONS",
    value: "07017",
    type: "common",
  },
  {
    label: "LES SALELLES",
    value: "07305",
    type: "common",
  },
  {
    label: "SAINT-PIERRE-SAINT-JEAN",
    value: "07284",
    type: "common",
  },
  {
    label: "PAYZAC",
    value: "07171",
    type: "common",
  },
  {
    label: "FAUGERES",
    value: "07088",
    type: "common",
  },
  {
    label: "CHAMBONAS",
    value: "07050",
    type: "common",
  },
  {
    label: "MALARCE-SUR-LA-THINES",
    value: "07147",
    type: "common",
  },
  {
    label: "VILLEFORT",
    value: "48198",
    type: "common",
  },
  {
    label: "ALTIER",
    value: "48004",
    type: "common",
  },
  {
    label: "CUBIERETTES",
    value: "48054",
    type: "common",
  },
  {
    label: "SAINT-ETIENNE-DU-VALDONNEZ",
    value: "48147",
    type: "common",
  },
  {
    label: "BRENOUX",
    value: "48030",
    type: "common",
  },
  {
    label: "SAINT-BAUZILE",
    value: "48137",
    type: "common",
  },
  {
    label: "BALSIEGES",
    value: "48016",
    type: "common",
  },
  {
    label: "ESCLANEDES",
    value: "48056",
    type: "common",
  },
  {
    label: "CULTURES",
    value: "48055",
    type: "common",
  },
  {
    label: "LES SALELLES",
    value: "48185",
    type: "common",
  },
  {
    label: "CHANAC",
    value: "48039",
    type: "common",
  },
  {
    label: "SAINT-PIERRE-DE-NOGARET",
    value: "48175",
    type: "common",
  },
  {
    label: "SAINT-GERMAIN-DU-TEIL",
    value: "48156",
    type: "common",
  },
  {
    label: "SAINT-LAURENT-D'OLT",
    value: "12237",
    type: "common",
  },
  {
    label: "POMAYROLS",
    value: "12184",
    type: "common",
  },
  {
    label: "LA CAPELLE-BONANCE",
    value: "12055",
    type: "common",
  },
  {
    label: "SAINT GENIEZ D'OLT ET D'AUBRAC",
    value: "12224",
    type: "common",
  },
  {
    label: "SAINTE-EULALIE-D'OLT",
    value: "12219",
    type: "common",
  },
  {
    label: "LASSOUTS",
    value: "12124",
    type: "common",
  },
  {
    label: "GABRIAC",
    value: "12106",
    type: "common",
  },
  {
    label: "BOZOULS",
    value: "12033",
    type: "common",
  },
  {
    label: "RODELLE",
    value: "12201",
    type: "common",
  },
  {
    label: "SALLES-LA-SOURCE",
    value: "12254",
    type: "common",
  },
  {
    label: "MURET-LE-CHATEAU",
    value: "12165",
    type: "common",
  },
  {
    label: "MARCILLAC-VALLON",
    value: "12138",
    type: "common",
  },
  {
    label: "VALADY",
    value: "12288",
    type: "common",
  },
  {
    label: "SAINT-CHRISTOPHE-VALLON",
    value: "12215",
    type: "common",
  },
  {
    label: "GOUTRENS",
    value: "12111",
    type: "common",
  },
  {
    label: "ESCANDOLIERES",
    value: "12095",
    type: "common",
  },
  {
    label: "BOURNAZEL",
    value: "12031",
    type: "common",
  },
  {
    label: "AUZITS",
    value: "12016",
    type: "common",
  },
  {
    label: "ROUSSENNAC",
    value: "12206",
    type: "common",
  },
  {
    label: "MONTBAZENS",
    value: "12148",
    type: "common",
  },
  {
    label: "LUGAN",
    value: "12134",
    type: "common",
  },
  {
    label: "VAUREILLES",
    value: "12290",
    type: "common",
  },
  {
    label: "DRULHE",
    value: "12091",
    type: "common",
  },
  {
    label: "SALLES-COURBATIES",
    value: "12252",
    type: "common",
  },
  {
    label: "SAINT-IGEST",
    value: "12227",
    type: "common",
  },
  {
    label: "VILLENEUVE",
    value: "12301",
    type: "common",
  },
  {
    label: "MONTSALES",
    value: "12158",
    type: "common",
  },
  {
    label: "SAUJAC",
    value: "12261",
    type: "common",
  },
  {
    label: "SALVAGNAC-CAJARC",
    value: "12256",
    type: "common",
  },
  {
    label: "OLS-ET-RINHODES",
    value: "12175",
    type: "common",
  },
  {
    label: "LA CAPELLE-BALAGUIER",
    value: "12053",
    type: "common",
  },
  {
    label: "AMBEYRAC",
    value: "12007",
    type: "common",
  },
  {
    label: "CALVIGNAC",
    value: "46049",
    type: "common",
  },
  {
    label: "CAJARC",
    value: "46045",
    type: "common",
  },
  {
    label: "SAINT-MARTIN-LABOUVAL",
    value: "46276",
    type: "common",
  },
  {
    label: "LARNAGOL",
    value: "46155",
    type: "common",
  },
  {
    label: "CENEVIERES",
    value: "46068",
    type: "common",
  },
  {
    label: "TOUR-DE-FAURE",
    value: "46320",
    type: "common",
  },
  {
    label: "SAINT-CIRQ-LAPOPIE",
    value: "46256",
    type: "common",
  },
  {
    label: "BOUZIES",
    value: "46037",
    type: "common",
  },
  {
    label: "BERGANTY",
    value: "46027",
    type: "common",
  },
  {
    label: "SAINT GERY-VERS",
    value: "46268",
    type: "common",
  },
  {
    label: "ARCAMBAL",
    value: "46007",
    type: "common",
  },
  {
    label: "BELLEFONT-LA RAUZE",
    value: "46156",
    type: "common",
  },
  {
    label: "LAMAGDELAINE",
    value: "46149",
    type: "common",
  },
  {
    label: "PRADINES",
    value: "46224",
    type: "common",
  },
  {
    label: "SAINT-VINCENT-RIVE-D'OLT",
    value: "46296",
    type: "common",
  },
  {
    label: "PARNAC",
    value: "46214",
    type: "common",
  },
  {
    label: "DOUELLE",
    value: "46088",
    type: "common",
  },
  {
    label: "CAILLAC",
    value: "46044",
    type: "common",
  },
  {
    label: "LUZECH",
    value: "46182",
    type: "common",
  },
  {
    label: "ALBAS",
    value: "46001",
    type: "common",
  },
  {
    label: "LAGARDELLE",
    value: "46147",
    type: "common",
  },
  {
    label: "GREZELS",
    value: "46130",
    type: "common",
  },
  {
    label: "BELAYE",
    value: "46022",
    type: "common",
  },
  {
    label: "ANGLARS-JUILLAC",
    value: "46005",
    type: "common",
  },
  {
    label: "VIRE-SUR-LOT",
    value: "46336",
    type: "common",
  },
  {
    label: "MAUROUX",
    value: "46187",
    type: "common",
  },
  {
    label: "LACAPELLE-CABANAC",
    value: "46142",
    type: "common",
  },
  {
    label: "FLORESSAS",
    value: "46107",
    type: "common",
  },
  {
    label: "THEZAC",
    value: "47307",
    type: "common",
  },
  {
    label: "MONTAYRAL",
    value: "47185",
    type: "common",
  },
  {
    label: "SAINT-GEORGES",
    value: "47328",
    type: "common",
  },
  {
    label: "SAINT-VITE",
    value: "47283",
    type: "common",
  },
  {
    label: "TRENTELS",
    value: "47315",
    type: "common",
  },
  {
    label: "SAVIGNAC-SUR-LEYZE",
    value: "47295",
    type: "common",
  },
  {
    label: "SAINT-AUBIN",
    value: "47230",
    type: "common",
  },
  {
    label: "MONSEGUR",
    value: "47178",
    type: "common",
  },
  {
    label: "LA SAUVETAT-SUR-LEDE",
    value: "47291",
    type: "common",
  },
  {
    label: "PAILLOLES",
    value: "47198",
    type: "common",
  },
  {
    label: "LEDAT",
    value: "47146",
    type: "common",
  },
  {
    label: "PINEL-HAUTERIVE",
    value: "47206",
    type: "common",
  },
  {
    label: "CASSENEUIL",
    value: "47049",
    type: "common",
  },
  {
    label: "COULX",
    value: "47071",
    type: "common",
  },
  {
    label: "MONCLAR",
    value: "47173",
    type: "common",
  },
  {
    label: "BRUGNAC",
    value: "47042",
    type: "common",
  },
  {
    label: "VERTEUIL-D'AGENAIS",
    value: "47317",
    type: "common",
  },
  {
    label: "VARES",
    value: "47316",
    type: "common",
  },
  {
    label: "HAUTESVIGNES",
    value: "47118",
    type: "common",
  },
  {
    label: "FAUILLET",
    value: "47095",
    type: "common",
  },
  {
    label: "SENESTIS",
    value: "47298",
    type: "common",
  },
  {
    label: "FAUGUEROLLES",
    value: "47094",
    type: "common",
  },
  {
    label: "LONGUEVILLE",
    value: "47150",
    type: "common",
  },
  {
    label: "GONTAUD-DE-NOGARET",
    value: "47110",
    type: "common",
  },
  {
    label: "SAINTE-MARTHE",
    value: "47253",
    type: "common",
  },
  {
    label: "CAUMONT-SUR-GARONNE",
    value: "47061",
    type: "common",
  },
  {
    label: "FOURQUES-SUR-GARONNE",
    value: "47101",
    type: "common",
  },
  {
    label: "TAILLEBOURG",
    value: "47304",
    type: "common",
  },
  {
    label: "SAINT-PARDOUX-DU-BREUIL",
    value: "47263",
    type: "common",
  },
  {
    label: "MONTPOUILLAN",
    value: "47191",
    type: "common",
  },
  {
    label: "SAMAZAN",
    value: "47285",
    type: "common",
  },
  {
    label: "COCUMONT",
    value: "47068",
    type: "common",
  },
  {
    label: "ROMESTAING",
    value: "47224",
    type: "common",
  },
  {
    label: "SIGALENS",
    value: "33512",
    type: "common",
  },
  {
    label: "SENDETS",
    value: "33511",
    type: "common",
  },
  {
    label: "LABESCAU",
    value: "33212",
    type: "common",
  },
  {
    label: "CAUVIGNAC",
    value: "33113",
    type: "common",
  },
  {
    label: "SAINT-COME",
    value: "33391",
    type: "common",
  },
  {
    label: "LADOS",
    value: "33216",
    type: "common",
  },
  {
    label: "GANS",
    value: "33180",
    type: "common",
  },
  {
    label: "GAJAC",
    value: "33178",
    type: "common",
  },
  {
    label: "LIGNAN-DE-BAZAS",
    value: "33244",
    type: "common",
  },
  {
    label: "BAZAS",
    value: "33036",
    type: "common",
  },
  {
    label: "UZESTE",
    value: "33537",
    type: "common",
  },
  {
    label: "VILLANDRAUT",
    value: "33547",
    type: "common",
  },
  {
    label: "SAINT-LEGER-DE-BALSON",
    value: "33429",
    type: "common",
  },
  {
    label: "LE TUZAN",
    value: "33536",
    type: "common",
  },
  {
    label: "SAINT-SYMPHORIEN",
    value: "33484",
    type: "common",
  },
  {
    label: "MANO",
    value: "40171",
    type: "common",
  },
  {
    label: "SAUGNACQ-ET-MURET",
    value: "40295",
    type: "common",
  },
  {
    label: "BISCARROSSE",
    value: "40046",
    type: "common",
  },
  {
    label: "UVERNET-FOURS",
    value: "04226",
    type: "common",
  },
  {
    label: "SEYNE",
    value: "04205",
    type: "common",
  },
  {
    label: "BAYONS",
    value: "04023",
    type: "common",
  },
  {
    label: "CLAMENSANE",
    value: "04057",
    type: "common",
  },
  {
    label: "LA MOTTE-DU-CAIRE",
    value: "04134",
    type: "common",
  },
  {
    label: "MELVE",
    value: "04118",
    type: "common",
  },
  {
    label: "THEZE",
    value: "04216",
    type: "common",
  },
  {
    label: "SIGOYER",
    value: "04207",
    type: "common",
  },
  {
    label: "CLARET",
    value: "04058",
    type: "common",
  },
  {
    label: "UPAIX",
    value: "05173",
    type: "common",
  },
  {
    label: "LAZER",
    value: "05073",
    type: "common",
  },
  {
    label: "LARAGNE-MONTEGLIN",
    value: "05070",
    type: "common",
  },
  {
    label: "GARDE-COLOMBE",
    value: "05053",
    type: "common",
  },
  {
    label: "TRESCLEOUX",
    value: "05172",
    type: "common",
  },
  {
    label: "MEREUIL",
    value: "05076",
    type: "common",
  },
  {
    label: "CHANOUSSE",
    value: "05033",
    type: "common",
  },
  {
    label: "SORBIERS",
    value: "05169",
    type: "common",
  },
  {
    label: "MONTJAY",
    value: "05086",
    type: "common",
  },
  {
    label: "SAINT-ANDRE-DE-ROSANS",
    value: "05129",
    type: "common",
  },
  {
    label: "MONTFERRAND-LA-FARE",
    value: "26199",
    type: "common",
  },
  {
    label: "LEMPS",
    value: "26161",
    type: "common",
  },
  {
    label: "LE POET-SIGILLAT",
    value: "26244",
    type: "common",
  },
  {
    label: "PELONNE",
    value: "26227",
    type: "common",
  },
  {
    label: "BELLECOMBE-TARENDOL",
    value: "26046",
    type: "common",
  },
  {
    label: "CURNIER",
    value: "26112",
    type: "common",
  },
  {
    label: "ARPAVON",
    value: "26013",
    type: "common",
  },
  {
    label: "LES PILLES",
    value: "26238",
    type: "common",
  },
  {
    label: "MONTAULIEU",
    value: "26190",
    type: "common",
  },
  {
    label: "VENTEROL",
    value: "26367",
    type: "common",
  },
  {
    label: "NYONS",
    value: "26220",
    type: "common",
  },
  {
    label: "AUBRES",
    value: "26016",
    type: "common",
  },
  {
    label: "VALREAS",
    value: "84138",
    type: "common",
  },
  {
    label: "SAINT-PANTALEON-LES-VIGNES",
    value: "26322",
    type: "common",
  },
  {
    label: "GRILLON",
    value: "84053",
    type: "common",
  },
  {
    label: "RICHERENCHES",
    value: "84097",
    type: "common",
  },
  {
    label: "MONTSEGUR-SUR-LAUZON",
    value: "26211",
    type: "common",
  },
  {
    label: "COLONZELLE",
    value: "26099",
    type: "common",
  },
  {
    label: "CHANTEMERLE-LES-GRIGNAN",
    value: "26073",
    type: "common",
  },
  {
    label: "CHAMARET",
    value: "26070",
    type: "common",
  },
  {
    label: "LA GARDE-ADHEMAR",
    value: "26138",
    type: "common",
  },
  {
    label: "CLANSAYES",
    value: "26093",
    type: "common",
  },
  {
    label: "PIERRELATTE",
    value: "26235",
    type: "common",
  },
  {
    label: "BOURG-SAINT-ANDEOL",
    value: "07042",
    type: "common",
  },
  {
    label: "BIDON",
    value: "07034",
    type: "common",
  },
  {
    label: "SAINT-REMEZE",
    value: "07291",
    type: "common",
  },
  {
    label: "VALLON-PONT-D'ARC",
    value: "07330",
    type: "common",
  },
  {
    label: "SALAVAS",
    value: "07304",
    type: "common",
  },
  {
    label: "SAMPZON",
    value: "07306",
    type: "common",
  },
  {
    label: "GROSPIERRES",
    value: "07101",
    type: "common",
  },
  {
    label: "CHANDOLAS",
    value: "07053",
    type: "common",
  },
  {
    label: "BERRIAS-ET-CASTELJAU",
    value: "07031",
    type: "common",
  },
  {
    label: "LES VANS",
    value: "07334",
    type: "common",
  },
  {
    label: "MALONS-ET-ELZE",
    value: "30153",
    type: "common",
  },
  {
    label: "BONNEVAUX",
    value: "30044",
    type: "common",
  },
  {
    label: "GRAVIERES",
    value: "07100",
    type: "common",
  },
  {
    label: "SAINT-ANDRE-CAPCEZE",
    value: "48135",
    type: "common",
  },
  {
    label: "PONTEILS-ET-BRESIS",
    value: "30201",
    type: "common",
  },
  {
    label: "CONCOULES",
    value: "30090",
    type: "common",
  },
  {
    label: "POURCHARESSES",
    value: "48117",
    type: "common",
  },
  {
    label: "PONT DE MONTVERT - SUD MONT LOZERE",
    value: "48116",
    type: "common",
  },
  {
    label: "LES BONDONS",
    value: "48028",
    type: "common",
  },
  {
    label: "ISPAGNAC",
    value: "48075",
    type: "common",
  },
  {
    label: "GORGES DU TARN CAUSSES",
    value: "48146",
    type: "common",
  },
  {
    label: "SAINT-SATURNIN",
    value: "48181",
    type: "common",
  },
  {
    label: "LA CANOURGUE",
    value: "48034",
    type: "common",
  },
  {
    label: "BANASSAC-CANILHAC",
    value: "48017",
    type: "common",
  },
  {
    label: "LA TIEULE",
    value: "48191",
    type: "common",
  },
  {
    label: "SAINT-SATURNIN-DE-LENNE",
    value: "12247",
    type: "common",
  },
  {
    label: "CAMPAGNAC",
    value: "12047",
    type: "common",
  },
  {
    label: "SAINT-MARTIN-DE-LENNE",
    value: "12239",
    type: "common",
  },
  {
    label: "VIMENET",
    value: "12303",
    type: "common",
  },
  {
    label: "PIERREFICHE",
    value: "12182",
    type: "common",
  },
  {
    label: "PALMAS D'AVEYRON",
    value: "12177",
    type: "common",
  },
  {
    label: "BERTHOLENE",
    value: "12026",
    type: "common",
  },
  {
    label: "MONTROZIER",
    value: "12157",
    type: "common",
  },
  {
    label: "SEBAZAC-CONCOURES",
    value: "12264",
    type: "common",
  },
  {
    label: "LA LOUBIERE",
    value: "12131",
    type: "common",
  },
  {
    label: "ONET-LE-CHATEAU",
    value: "12176",
    type: "common",
  },
  {
    label: "MAYRAN",
    value: "12142",
    type: "common",
  },
  {
    label: "CLAIRVAUX-D'AVEYRON",
    value: "12066",
    type: "common",
  },
  {
    label: "RIGNAC",
    value: "12199",
    type: "common",
  },
  {
    label: "BELCASTEL",
    value: "12024",
    type: "common",
  },
  {
    label: "ANGLARS-SAINT-FELIX",
    value: "12008",
    type: "common",
  },
  {
    label: "PRIVEZAC",
    value: "12191",
    type: "common",
  },
  {
    label: "LANUEJOULS",
    value: "12121",
    type: "common",
  },
  {
    label: "BRANDONNET",
    value: "12034",
    type: "common",
  },
  {
    label: "SAINT-REMY",
    value: "12242",
    type: "common",
  },
  {
    label: "MALEVILLE",
    value: "12136",
    type: "common",
  },
  {
    label: "TOULONJAC",
    value: "12281",
    type: "common",
  },
  {
    label: "SAINTE-CROIX",
    value: "12217",
    type: "common",
  },
  {
    label: "MARTIEL",
    value: "12140",
    type: "common",
  },
  {
    label: "SAINT-JEAN-DE-LAUR",
    value: "46270",
    type: "common",
  },
  {
    label: "PUYJOURDES",
    value: "46230",
    type: "common",
  },
  {
    label: "LUGAGNAC",
    value: "46179",
    type: "common",
  },
  {
    label: "LIMOGNE-EN-QUERCY",
    value: "46173",
    type: "common",
  },
  {
    label: "CREGOLS",
    value: "46081",
    type: "common",
  },
  {
    label: "CONCOTS",
    value: "46073",
    type: "common",
  },
  {
    label: "LABURGADE",
    value: "46140",
    type: "common",
  },
  {
    label: "ESCLAUZELS",
    value: "46092",
    type: "common",
  },
  {
    label: "CREMPS",
    value: "46082",
    type: "common",
  },
  {
    label: "AUJOLS",
    value: "46010",
    type: "common",
  },
  {
    label: "FLAUJAC-POUJOLS",
    value: "46105",
    type: "common",
  },
  {
    label: "LABASTIDE-MARNHAC",
    value: "46137",
    type: "common",
  },
  {
    label: "CAHORS",
    value: "46042",
    type: "common",
  },
  {
    label: "VILLESEQUE",
    value: "46335",
    type: "common",
  },
  {
    label: "TRESPOUX-RASSIELS",
    value: "46322",
    type: "common",
  },
  {
    label: "CAMBAYRAC",
    value: "46050",
    type: "common",
  },
  {
    label: "SAUZET",
    value: "46301",
    type: "common",
  },
  {
    label: "CARNAC-ROUFFIAC",
    value: "46060",
    type: "common",
  },
  {
    label: "SERIGNAC",
    value: "46305",
    type: "common",
  },
  {
    label: "TOURNON-D'AGENAIS",
    value: "47312",
    type: "common",
  },
  {
    label: "MASQUIERES",
    value: "47160",
    type: "common",
  },
  {
    label: "COURBIAC",
    value: "47072",
    type: "common",
  },
  {
    label: "BOURLENS",
    value: "47036",
    type: "common",
  },
  {
    label: "TREMONS",
    value: "47314",
    type: "common",
  },
  {
    label: "DAUSSE",
    value: "47079",
    type: "common",
  },
  {
    label: "CAZIDEROQUE",
    value: "47064",
    type: "common",
  },
  {
    label: "ANTHE",
    value: "47011",
    type: "common",
  },
  {
    label: "SAINT-SYLVESTRE-SUR-LOT",
    value: "47280",
    type: "common",
  },
  {
    label: "VILLENEUVE-SUR-LOT",
    value: "47323",
    type: "common",
  },
  {
    label: "PUJOLS",
    value: "47215",
    type: "common",
  },
  {
    label: "BIAS",
    value: "47027",
    type: "common",
  },
  {
    label: "SAINTE-LIVRADE-SUR-LOT",
    value: "47252",
    type: "common",
  },
  {
    label: "SAINT-ETIENNE-DE-FOUGERES",
    value: "47239",
    type: "common",
  },
  {
    label: "ALLEZ-ET-CAZENEUVE",
    value: "47006",
    type: "common",
  },
  {
    label: "CASTELMORON-SUR-LOT",
    value: "47054",
    type: "common",
  },
  {
    label: "LE TEMPLE-SUR-LOT",
    value: "47306",
    type: "common",
  },
  {
    label: "GRANGES-SUR-LOT",
    value: "47111",
    type: "common",
  },
  {
    label: "FONGRAVE",
    value: "47099",
    type: "common",
  },
  {
    label: "CLAIRAC",
    value: "47065",
    type: "common",
  },
  {
    label: "GRATELOUP-SAINT-GAYRAND",
    value: "47112",
    type: "common",
  },
  {
    label: "LAPARADE",
    value: "47135",
    type: "common",
  },
  {
    label: "TONNEINS",
    value: "47310",
    type: "common",
  },
  {
    label: "CALONGES",
    value: "47046",
    type: "common",
  },
  {
    label: "VILLETON",
    value: "47325",
    type: "common",
  },
  {
    label: "LAGRUERE",
    value: "47130",
    type: "common",
  },
  {
    label: "LE MAS-D'AGENAIS",
    value: "47159",
    type: "common",
  },
  {
    label: "SAINTE-GEMME-MARTAILLAC",
    value: "47244",
    type: "common",
  },
  {
    label: "BOUGLON",
    value: "47034",
    type: "common",
  },
  {
    label: "ARGENTON",
    value: "47013",
    type: "common",
  },
  {
    label: "GREZET-CAVAGNAN",
    value: "47114",
    type: "common",
  },
  {
    label: "ANTAGNAC",
    value: "47010",
    type: "common",
  },
  {
    label: "RUFFIAC",
    value: "47227",
    type: "common",
  },
  {
    label: "GUERIN",
    value: "47115",
    type: "common",
  },
  {
    label: "GRIGNOLS",
    value: "33195",
    type: "common",
  },
  {
    label: "COURS-LES-BAINS",
    value: "33137",
    type: "common",
  },
  {
    label: "SILLAS",
    value: "33513",
    type: "common",
  },
  {
    label: "MASSEILLES",
    value: "33276",
    type: "common",
  },
  {
    label: "MARIONS",
    value: "33271",
    type: "common",
  },
  {
    label: "SAUVIAC",
    value: "33507",
    type: "common",
  },
  {
    label: "LAVAZAN",
    value: "33235",
    type: "common",
  },
  {
    label: "CUDOS",
    value: "33144",
    type: "common",
  },
  {
    label: "BIRAC",
    value: "33053",
    type: "common",
  },
  {
    label: "MARIMBAULT",
    value: "33270",
    type: "common",
  },
  {
    label: "BERNOS-BEAULAC",
    value: "33046",
    type: "common",
  },
  {
    label: "POMPEJAC",
    value: "33329",
    type: "common",
  },
  {
    label: "PRECHAC",
    value: "33336",
    type: "common",
  },
  {
    label: "BOURIDEYS",
    value: "33068",
    type: "common",
  },
  {
    label: "ARGELOUSE",
    value: "40008",
    type: "common",
  },
  {
    label: "MOUSTEY",
    value: "40200",
    type: "common",
  },
  {
    label: "BELHADE",
    value: "40032",
    type: "common",
  },
  {
    label: "YCHOUX",
    value: "40332",
    type: "common",
  },
  {
    label: "PARENTIS-EN-BORN",
    value: "40217",
    type: "common",
  },
  {
    label: "SAINT-ETIENNE-DE-TINEE",
    value: "06120",
    type: "common",
  },
  {
    label: "SAINT-DALMAS-LE-SELVAGE",
    value: "06119",
    type: "common",
  },
  {
    label: "ENTRAUNES",
    value: "06056",
    type: "common",
  },
  {
    label: "ALLOS",
    value: "04006",
    type: "common",
  },
  {
    label: "LE VERNET",
    value: "04237",
    type: "common",
  },
  {
    label: "VERDACHES",
    value: "04235",
    type: "common",
  },
  {
    label: "AUZET",
    value: "04017",
    type: "common",
  },
  {
    label: "BARLES",
    value: "04020",
    type: "common",
  },
  {
    label: "VALAVOIRE",
    value: "04228",
    type: "common",
  },
  {
    label: "NIBLES",
    value: "04137",
    type: "common",
  },
  {
    label: "CHATEAUFORT",
    value: "04050",
    type: "common",
  },
  {
    label: "LE POET",
    value: "05103",
    type: "common",
  },
  {
    label: "VAUMEILH",
    value: "04233",
    type: "common",
  },
  {
    label: "MISON",
    value: "04123",
    type: "common",
  },
  {
    label: "SALEON",
    value: "05159",
    type: "common",
  },
  {
    label: "NOSSAGE-ET-BENEVENT",
    value: "05094",
    type: "common",
  },
  {
    label: "SAINTE-COLOMBE",
    value: "05135",
    type: "common",
  },
  {
    label: "ORPIERRE",
    value: "05097",
    type: "common",
  },
  {
    label: "ETOILE-SAINT-CYRICE",
    value: "05051",
    type: "common",
  },
  {
    label: "VILLEBOIS-LES-PINS",
    value: "26374",
    type: "common",
  },
  {
    label: "LABOREL",
    value: "26153",
    type: "common",
  },
  {
    label: "CHAUVAC-LAUX-MONTAUX",
    value: "26091",
    type: "common",
  },
  {
    label: "ROUSSIEUX",
    value: "26286",
    type: "common",
  },
  {
    label: "SAINT-AUBAN-SUR-L'OUVEZE",
    value: "26292",
    type: "common",
  },
  {
    label: "MONTGUERS",
    value: "26201",
    type: "common",
  },
  {
    label: "VERCOIRAN",
    value: "26370",
    type: "common",
  },
  {
    label: "SAINT-SAUVEUR-GOUVERNET",
    value: "26329",
    type: "common",
  },
  {
    label: "SAINTE-EUPHEMIE-SUR-OUVEZE",
    value: "26303",
    type: "common",
  },
  {
    label: "BESIGNAN",
    value: "26050",
    type: "common",
  },
  {
    label: "SAINTE-JALLE",
    value: "26306",
    type: "common",
  },
  {
    label: "ROCHEBRUNE",
    value: "26269",
    type: "common",
  },
  {
    label: "CHATEAUNEUF-DE-BORDETTE",
    value: "26082",
    type: "common",
  },
  {
    label: "BENIVAY-OLLON",
    value: "26048",
    type: "common",
  },
  {
    label: "BEAUVOISIN",
    value: "26043",
    type: "common",
  },
  {
    label: "PIEGON",
    value: "26233",
    type: "common",
  },
  {
    label: "MIRABEL-AUX-BARONNIES",
    value: "26182",
    type: "common",
  },
  {
    label: "VILLEDIEU",
    value: "84146",
    type: "common",
  },
  {
    label: "VINSOBRES",
    value: "26377",
    type: "common",
  },
  {
    label: "SAINT-MAURICE-SUR-EYGUES",
    value: "26317",
    type: "common",
  },
  {
    label: "VISAN",
    value: "84150",
    type: "common",
  },
  {
    label: "SOLERIEUX",
    value: "26342",
    type: "common",
  },
  {
    label: "BOUCHET",
    value: "26054",
    type: "common",
  },
  {
    label: "LA BAUME-DE-TRANSIT",
    value: "26033",
    type: "common",
  },
  {
    label: "SAINT-RESTITUT",
    value: "26326",
    type: "common",
  },
  {
    label: "SAINT-PAUL-TROIS-CHATEAUX",
    value: "26324",
    type: "common",
  },
  {
    label: "LAPALUD",
    value: "84064",
    type: "common",
  },
  {
    label: "SAINT-MARTIN-D'ARDECHE",
    value: "07268",
    type: "common",
  },
  {
    label: "SAINT-MARCEL-D'ARDECHE",
    value: "07264",
    type: "common",
  },
  {
    label: "LAVAL-SAINT-ROMAN",
    value: "30143",
    type: "common",
  },
  {
    label: "AIGUEZE",
    value: "30005",
    type: "common",
  },
  {
    label: "LE GARN",
    value: "30124",
    type: "common",
  },
  {
    label: "ORGNAC-L'AVEN",
    value: "07168",
    type: "common",
  },
  {
    label: "BARJAC",
    value: "30029",
    type: "common",
  },
  {
    label: "VAGNAS",
    value: "07328",
    type: "common",
  },
  {
    label: "LABASTIDE-DE-VIRAC",
    value: "07113",
    type: "common",
  },
  {
    label: "BESSAS",
    value: "07033",
    type: "common",
  },
  {
    label: "BEAULIEU",
    value: "07028",
    type: "common",
  },
  {
    label: "SAINT-ANDRE-DE-CRUZIERES",
    value: "07211",
    type: "common",
  },
  {
    label: "GAGNIERES",
    value: "30120",
    type: "common",
  },
  {
    label: "SAINT-PAUL-LE-JEUNE",
    value: "07280",
    type: "common",
  },
  {
    label: "BANNE",
    value: "07024",
    type: "common",
  },
  {
    label: "SENECHAS",
    value: "30316",
    type: "common",
  },
  {
    label: "BORDEZAC",
    value: "30045",
    type: "common",
  },
  {
    label: "AUJAC",
    value: "30022",
    type: "common",
  },
  {
    label: "MALBOSC",
    value: "07148",
    type: "common",
  },
  {
    label: "GENOLHAC",
    value: "30130",
    type: "common",
  },
  {
    label: "VIALAS",
    value: "48194",
    type: "common",
  },
  {
    label: "BEDOUES-COCURES",
    value: "48050",
    type: "common",
  },
  {
    label: "MAS-SAINT-CHELY",
    value: "48141",
    type: "common",
  },
  {
    label: "LAVAL-DU-TARN",
    value: "48085",
    type: "common",
  },
  {
    label: "GAILLAC-D'AVEYRON",
    value: "12107",
    type: "common",
  },
  {
    label: "LAISSAC-SEVERAC L'EGLISE",
    value: "12120",
    type: "common",
  },
  {
    label: "ARQUES",
    value: "12010",
    type: "common",
  },
  {
    label: "AGEN-D'AVEYRON",
    value: "12001",
    type: "common",
  },
  {
    label: "SAINTE-RADEGONDE",
    value: "12241",
    type: "common",
  },
  {
    label: "RODEZ",
    value: "12202",
    type: "common",
  },
  {
    label: "OLEMPS",
    value: "12174",
    type: "common",
  },
  {
    label: "LE MONASTERE",
    value: "12146",
    type: "common",
  },
  {
    label: "DRUELLE BALSAC",
    value: "12090",
    type: "common",
  },
  {
    label: "MOYRAZES",
    value: "12162",
    type: "common",
  },
  {
    label: "COLOMBIES",
    value: "12068",
    type: "common",
  },
  {
    label: "PREVINQUIERES",
    value: "12190",
    type: "common",
  },
  {
    label: "COMPOLIBAT",
    value: "12071",
    type: "common",
  },
  {
    label: "LE BAS SEGALA",
    value: "12021",
    type: "common",
  },
  {
    label: "VILLEFRANCHE-DE-ROUERGUE",
    value: "12300",
    type: "common",
  },
  {
    label: "MORLHON-LE-HAUT",
    value: "12159",
    type: "common",
  },
  {
    label: "SAVIGNAC",
    value: "12263",
    type: "common",
  },
  {
    label: "LARAMIERE",
    value: "46154",
    type: "common",
  },
  {
    label: "VIDAILLAC",
    value: "46333",
    type: "common",
  },
  {
    label: "PROMILHANES",
    value: "46227",
    type: "common",
  },
  {
    label: "VARAIRE",
    value: "46328",
    type: "common",
  },
  {
    label: "SAILLAC",
    value: "46247",
    type: "common",
  },
  {
    label: "BEAUREGARD",
    value: "46020",
    type: "common",
  },
  {
    label: "VAYLATS",
    value: "46329",
    type: "common",
  },
  {
    label: "BACH",
    value: "46013",
    type: "common",
  },
  {
    label: "LALBENQUE",
    value: "46148",
    type: "common",
  },
  {
    label: "ESCAMPS",
    value: "46091",
    type: "common",
  },
  {
    label: "FONTANES",
    value: "46109",
    type: "common",
  },
  {
    label: "CIEURAC",
    value: "46070",
    type: "common",
  },
  {
    label: "PERN",
    value: "46217",
    type: "common",
  },
  {
    label: "LE MONTAT",
    value: "46197",
    type: "common",
  },
  {
    label: "LHOSPITALET",
    value: "46172",
    type: "common",
  },
  {
    label: "CEZAC",
    value: "46069",
    type: "common",
  },
  {
    label: "MONTCUQ-EN-QUERCY-BLANC",
    value: "46201",
    type: "common",
  },
  {
    label: "BELVEZE",
    value: "82016",
    type: "common",
  },
  {
    label: "MONTAIGU-DE-QUERCY",
    value: "82117",
    type: "common",
  },
  {
    label: "VALEILLES",
    value: "82185",
    type: "common",
  },
  {
    label: "SAINT-BEAUZEIL",
    value: "82157",
    type: "common",
  },
  {
    label: "SAINT-AMANS-DU-PECH",
    value: "82153",
    type: "common",
  },
  {
    label: "ROQUECOR",
    value: "82151",
    type: "common",
  },
  {
    label: "PENNE-D'AGENAIS",
    value: "47203",
    type: "common",
  },
  {
    label: "MASSOULES",
    value: "47162",
    type: "common",
  },
  {
    label: "MASSELS",
    value: "47161",
    type: "common",
  },
  {
    label: "FRESPECH",
    value: "47105",
    type: "common",
  },
  {
    label: "AURADOU",
    value: "47017",
    type: "common",
  },
  {
    label: "SAINT-ANTOINE-DE-FICALBA",
    value: "47228",
    type: "common",
  },
  {
    label: "MONBALEN",
    value: "47171",
    type: "common",
  },
  {
    label: "HAUTEFAGE-LA-TOUR",
    value: "47117",
    type: "common",
  },
  {
    label: "SEMBAS",
    value: "47297",
    type: "common",
  },
  {
    label: "SAINTE-COLOMBE-DE-VILLENEUVE",
    value: "47237",
    type: "common",
  },
  {
    label: "CASTELLA",
    value: "47053",
    type: "common",
  },
  {
    label: "DOLMAYRAC",
    value: "47081",
    type: "common",
  },
  {
    label: "COURS",
    value: "47073",
    type: "common",
  },
  {
    label: "SAINT-SARDOS",
    value: "47276",
    type: "common",
  },
  {
    label: "MONTPEZAT",
    value: "47190",
    type: "common",
  },
  {
    label: "LACEPEDE",
    value: "47125",
    type: "common",
  },
  {
    label: "LAFITTE-SUR-LOT",
    value: "47127",
    type: "common",
  },
  {
    label: "BOURRAN",
    value: "47038",
    type: "common",
  },
  {
    label: "NICOLE",
    value: "47196",
    type: "common",
  },
  {
    label: "MONHEURT",
    value: "47177",
    type: "common",
  },
  {
    label: "PUCH-D'AGENAIS",
    value: "47214",
    type: "common",
  },
  {
    label: "VILLEFRANCHE-DU-QUEYRAN",
    value: "47320",
    type: "common",
  },
  {
    label: "RAZIMET",
    value: "47220",
    type: "common",
  },
  {
    label: "LEYRITZ-MONCASSIN",
    value: "47148",
    type: "common",
  },
  {
    label: "POUSSIGNAC",
    value: "47212",
    type: "common",
  },
  {
    label: "LABASTIDE-CASTEL-AMOUROUX",
    value: "47121",
    type: "common",
  },
  {
    label: "CASTELJALOUX",
    value: "47052",
    type: "common",
  },
  {
    label: "BEAUZIAC",
    value: "47026",
    type: "common",
  },
  {
    label: "SAINT-MARTIN-CURTON",
    value: "47254",
    type: "common",
  },
  {
    label: "LERM-ET-MUSSET",
    value: "33239",
    type: "common",
  },
  {
    label: "GOUALADE",
    value: "33190",
    type: "common",
  },
  {
    label: "ESCAUDES",
    value: "33155",
    type: "common",
  },
  {
    label: "LUCMAU",
    value: "33255",
    type: "common",
  },
  {
    label: "CAZALIS",
    value: "33115",
    type: "common",
  },
  {
    label: "CALLEN",
    value: "40060",
    type: "common",
  },
  {
    label: "SORE",
    value: "40307",
    type: "common",
  },
  {
    label: "PISSOS",
    value: "40227",
    type: "common",
  },
  {
    label: "LIPOSTHEY",
    value: "40156",
    type: "common",
  },
  {
    label: "GASTES",
    value: "40108",
    type: "common",
  },
  {
    label: "SAINTE-EULALIE-EN-BORN",
    value: "40257",
    type: "common",
  },
  {
    label: "LA BRIGUE",
    value: "06162",
    type: "common",
  },
  {
    label: "ISOLA",
    value: "06073",
    type: "common",
  },
  {
    label: "VILLARS-COLMARS",
    value: "04240",
    type: "common",
  },
  {
    label: "PRADS-HAUTE-BLEONE",
    value: "04155",
    type: "common",
  },
  {
    label: "LA JAVIE",
    value: "04097",
    type: "common",
  },
  {
    label: "BEAUJEU",
    value: "04024",
    type: "common",
  },
  {
    label: "LA ROBINE-SUR-GALABRE",
    value: "04167",
    type: "common",
  },
  {
    label: "HAUTES-DUYES",
    value: "04177",
    type: "common",
  },
  {
    label: "LE CASTELLARD-MELAN",
    value: "04040",
    type: "common",
  },
  {
    label: "AUTHON",
    value: "04016",
    type: "common",
  },
  {
    label: "SAINT-GENIEZ",
    value: "04179",
    type: "common",
  },
  {
    label: "VALERNES",
    value: "04231",
    type: "common",
  },
  {
    label: "SISTERON",
    value: "04209",
    type: "common",
  },
  {
    label: "VAL BUECH-MEOUGE",
    value: "05118",
    type: "common",
  },
  {
    label: "SAINT-PIERRE-AVEZ",
    value: "05155",
    type: "common",
  },
  {
    label: "EOURRES",
    value: "05047",
    type: "common",
  },
  {
    label: "BARRET-SUR-MEOUGE",
    value: "05014",
    type: "common",
  },
  {
    label: "LACHAU",
    value: "26154",
    type: "common",
  },
  {
    label: "BALLONS",
    value: "26022",
    type: "common",
  },
  {
    label: "SALERANS",
    value: "05160",
    type: "common",
  },
  {
    label: "IZON-LA-BRUISSE",
    value: "26150",
    type: "common",
  },
  {
    label: "EYGALAYES",
    value: "26126",
    type: "common",
  },
  {
    label: "VERS-SUR-MEOUGE",
    value: "26372",
    type: "common",
  },
  {
    label: "MONTAUBAN-SUR-L'OUVEZE",
    value: "26189",
    type: "common",
  },
  {
    label: "MEVOUILLON",
    value: "26181",
    type: "common",
  },
  {
    label: "LA ROCHETTE-DU-BUIS",
    value: "26279",
    type: "common",
  },
  {
    label: "RIOMS",
    value: "26267",
    type: "common",
  },
  {
    label: "LE POET-EN-PERCIP",
    value: "26242",
    type: "common",
  },
  {
    label: "AULAN",
    value: "26018",
    type: "common",
  },
  {
    label: "LA ROCHE-SUR-LE-BUIS",
    value: "26278",
    type: "common",
  },
  {
    label: "LA PENNE-SUR-L'OUVEZE",
    value: "26229",
    type: "common",
  },
  {
    label: "EYGALIERS",
    value: "26127",
    type: "common",
  },
  {
    label: "BUIS-LES-BARONNIES",
    value: "26063",
    type: "common",
  },
  {
    label: "PROPIAC",
    value: "26256",
    type: "common",
  },
  {
    label: "PIERRELONGUE",
    value: "26236",
    type: "common",
  },
  {
    label: "MOLLANS-SUR-OUVEZE",
    value: "26188",
    type: "common",
  },
  {
    label: "MERINDOL-LES-OLIVIERS",
    value: "26180",
    type: "common",
  },
  {
    label: "SAINT-ROMAIN-EN-VIENNOIS",
    value: "84116",
    type: "common",
  },
  {
    label: "SAINT-MARCELLIN-LES-VAISON",
    value: "84111",
    type: "common",
  },
  {
    label: "PUYMERAS",
    value: "84094",
    type: "common",
  },
  {
    label: "FAUCON",
    value: "84045",
    type: "common",
  },
  {
    label: "VAISON-LA-ROMAINE",
    value: "84137",
    type: "common",
  },
  {
    label: "ROAIX",
    value: "84098",
    type: "common",
  },
  {
    label: "RASTEAU",
    value: "84096",
    type: "common",
  },
  {
    label: "BUISSON",
    value: "84022",
    type: "common",
  },
  {
    label: "SAINT-ROMAN-DE-MALEGARDE",
    value: "84117",
    type: "common",
  },
  {
    label: "TULETTE",
    value: "26357",
    type: "common",
  },
  {
    label: "SAINTE-CECILE-LES-VIGNES",
    value: "84106",
    type: "common",
  },
  {
    label: "SUZE-LA-ROUSSE",
    value: "26345",
    type: "common",
  },
  {
    label: "ROCHEGUDE",
    value: "26275",
    type: "common",
  },
  {
    label: "MONDRAGON",
    value: "84078",
    type: "common",
  },
  {
    label: "BOLLENE",
    value: "84019",
    type: "common",
  },
  {
    label: "LAMOTTE-DU-RHONE",
    value: "84063",
    type: "common",
  },
  {
    label: "SAINT-PAULET-DE-CAISSON",
    value: "30290",
    type: "common",
  },
  {
    label: "PONT-SAINT-ESPRIT",
    value: "30202",
    type: "common",
  },
  {
    label: "SAINT-JUST-D'ARDECHE",
    value: "07259",
    type: "common",
  },
  {
    label: "SALAZAC",
    value: "30304",
    type: "common",
  },
  {
    label: "SAINT-JULIEN-DE-PEYROLAS",
    value: "30273",
    type: "common",
  },
  {
    label: "SAINT-CHRISTOL-DE-RODIERES",
    value: "30242",
    type: "common",
  },
  {
    label: "SAINT-ANDRE-DE-ROQUEPERTUIS",
    value: "30230",
    type: "common",
  },
  {
    label: "MONTCLUS",
    value: "30175",
    type: "common",
  },
  {
    label: "ISSIRAC",
    value: "30134",
    type: "common",
  },
  {
    label: "SAINT-PRIVAT-DE-CHAMPCLOS",
    value: "30293",
    type: "common",
  },
  {
    label: "SAINT-JEAN-DE-MARUEJOLS-ET-AVEJAN",
    value: "30266",
    type: "common",
  },
  {
    label: "SAINT-SAUVEUR-DE-CRUZIERES",
    value: "07294",
    type: "common",
  },
  {
    label: "SAINT-VICTOR-DE-MALCAP",
    value: "30303",
    type: "common",
  },
  {
    label: "SAINT-BRES",
    value: "30237",
    type: "common",
  },
  {
    label: "SAINT-AMBROIX",
    value: "30227",
    type: "common",
  },
  {
    label: "ROBIAC-ROCHESSADOULE",
    value: "30216",
    type: "common",
  },
  {
    label: "MOLIERES-SUR-CEZE",
    value: "30171",
    type: "common",
  },
  {
    label: "MEYRANNES",
    value: "30167",
    type: "common",
  },
  {
    label: "LE MARTINET",
    value: "30159",
    type: "common",
  },
  {
    label: "COURRY",
    value: "30097",
    type: "common",
  },
  {
    label: "BESSEGES",
    value: "30037",
    type: "common",
  },
  {
    label: "LA VERNAREDE",
    value: "30345",
    type: "common",
  },
  {
    label: "PORTES",
    value: "30203",
    type: "common",
  },
  {
    label: "PEYREMALE",
    value: "30194",
    type: "common",
  },
  {
    label: "CHAMBON",
    value: "30079",
    type: "common",
  },
  {
    label: "SAINT-JULIEN-DES-POINTS",
    value: "48163",
    type: "common",
  },
  {
    label: "LE COLLET-DE-DEZE",
    value: "48051",
    type: "common",
  },
  {
    label: "SAINTE-CECILE-D'ANDORGE",
    value: "30239",
    type: "common",
  },
  {
    label: "CHAMBORIGAUD",
    value: "30080",
    type: "common",
  },
  {
    label: "SAINT-HILAIRE-DE-LAVIT",
    value: "48158",
    type: "common",
  },
  {
    label: "VENTALON EN CEVENNES",
    value: "48152",
    type: "common",
  },
  {
    label: "SAINT-PRIVAT-DE-VALLONGUE",
    value: "48178",
    type: "common",
  },
  {
    label: "SAINT-ANDRE-DE-LANCIZE",
    value: "48136",
    type: "common",
  },
  {
    label: "CASSAGNAS",
    value: "48036",
    type: "common",
  },
  {
    label: "CANS ET CEVENNES",
    value: "48166",
    type: "common",
  },
  {
    label: "BARRE-DES-CEVENNES",
    value: "48019",
    type: "common",
  },
  {
    label: "VEBRON",
    value: "48193",
    type: "common",
  },
  {
    label: "FLORAC TROIS RIVIERES",
    value: "48061",
    type: "common",
  },
  {
    label: "LA MALENE",
    value: "48088",
    type: "common",
  },
  {
    label: "MASSEGROS CAUSSES GORGES",
    value: "48094",
    type: "common",
  },
  {
    label: "SEVERAC D'AVEYRON",
    value: "12270",
    type: "common",
  },
  {
    label: "VEZINS-DE-LEVEZOU",
    value: "12294",
    type: "common",
  },
  {
    label: "SEGUR",
    value: "12266",
    type: "common",
  },
  {
    label: "LE VIBAL",
    value: "12297",
    type: "common",
  },
  {
    label: "PONT-DE-SALARS",
    value: "12185",
    type: "common",
  },
  {
    label: "FLAVIN",
    value: "12102",
    type: "common",
  },
  {
    label: "LUC-LA-PRIMAUBE",
    value: "12133",
    type: "common",
  },
  {
    label: "CALMONT",
    value: "12043",
    type: "common",
  },
  {
    label: "BARAQUEVILLE",
    value: "12056",
    type: "common",
  },
  {
    label: "GRAMOND",
    value: "12113",
    type: "common",
  },
  {
    label: "BOUSSAC",
    value: "12032",
    type: "common",
  },
  {
    label: "CASTANET",
    value: "12059",
    type: "common",
  },
  {
    label: "RIEUPEYROUX",
    value: "12198",
    type: "common",
  },
  {
    label: "LA CAPELLE-BLEYS",
    value: "12054",
    type: "common",
  },
  {
    label: "SANVENSA",
    value: "12259",
    type: "common",
  },
  {
    label: "LA ROUQUETTE",
    value: "12205",
    type: "common",
  },
  {
    label: "MONTEILS",
    value: "12150",
    type: "common",
  },
  {
    label: "PARISOT",
    value: "82137",
    type: "common",
  },
  {
    label: "VAILHOURLES",
    value: "12287",
    type: "common",
  },
  {
    label: "SAINT-PROJET",
    value: "82172",
    type: "common",
  },
  {
    label: "PUYLAGARDE",
    value: "82147",
    type: "common",
  },
  {
    label: "LACAPELLE-LIVRON",
    value: "82082",
    type: "common",
  },
  {
    label: "LOZE",
    value: "82100",
    type: "common",
  },
  {
    label: "MOUILLAC",
    value: "82133",
    type: "common",
  },
  {
    label: "BELMONT-SAINTE-FOI",
    value: "46026",
    type: "common",
  },
  {
    label: "LABASTIDE-DE-PENNE",
    value: "82078",
    type: "common",
  },
  {
    label: "MONTDOUMERC",
    value: "46202",
    type: "common",
  },
  {
    label: "BELFORT-DU-QUERCY",
    value: "46023",
    type: "common",
  },
  {
    label: "SAINT-PAUL-FLAUGNAC",
    value: "46103",
    type: "common",
  },
  {
    label: "SAUVETERRE",
    value: "82177",
    type: "common",
  },
  {
    label: "LENDOU-EN-QUERCY",
    value: "46262",
    type: "common",
  },
  {
    label: "TREJOULS",
    value: "82183",
    type: "common",
  },
  {
    label: "SAINTE-JULIETTE",
    value: "82164",
    type: "common",
  },
  {
    label: "MONTLAUZUN",
    value: "46206",
    type: "common",
  },
  {
    label: "LAUZERTE",
    value: "82094",
    type: "common",
  },
  {
    label: "BOULOC-EN-QUERCY",
    value: "82021",
    type: "common",
  },
  {
    label: "TOUFFAILLES",
    value: "82182",
    type: "common",
  },
  {
    label: "LACOUR",
    value: "82084",
    type: "common",
  },
  {
    label: "ENGAYRAC",
    value: "47087",
    type: "common",
  },
  {
    label: "BEAUVILLE",
    value: "47025",
    type: "common",
  },
  {
    label: "CAUZAC",
    value: "47062",
    type: "common",
  },
  {
    label: "BLAYMONT",
    value: "47030",
    type: "common",
  },
  {
    label: "SAINT-ROBERT",
    value: "47273",
    type: "common",
  },
  {
    label: "LAROQUE-TIMBAUT",
    value: "47138",
    type: "common",
  },
  {
    label: "CASSIGNAS",
    value: "47050",
    type: "common",
  },
  {
    label: "FOULAYRONNES",
    value: "47100",
    type: "common",
  },
  {
    label: "LA CROIX-BLANCHE",
    value: "47075",
    type: "common",
  },
  {
    label: "BAJAMONT",
    value: "47019",
    type: "common",
  },
  {
    label: "MADAILLAN",
    value: "47155",
    type: "common",
  },
  {
    label: "LAUGNAC",
    value: "47140",
    type: "common",
  },
  {
    label: "SAINT-HILAIRE-DE-LUSIGNAN",
    value: "47246",
    type: "common",
  },
  {
    label: "PRAYSSAS",
    value: "47213",
    type: "common",
  },
  {
    label: "LUSIGNAN-PETIT",
    value: "47154",
    type: "common",
  },
  {
    label: "SAINT-SALVY",
    value: "47275",
    type: "common",
  },
  {
    label: "GALAPIAN",
    value: "47107",
    type: "common",
  },
  {
    label: "FREGIMONT",
    value: "47104",
    type: "common",
  },
  {
    label: "BAZENS",
    value: "47022",
    type: "common",
  },
  {
    label: "THOUARS-SUR-GARONNE",
    value: "47308",
    type: "common",
  },
  {
    label: "SAINT-LEGER",
    value: "47250",
    type: "common",
  },
  {
    label: "PORT-SAINTE-MARIE",
    value: "47210",
    type: "common",
  },
  {
    label: "LAGARRIGUE",
    value: "47129",
    type: "common",
  },
  {
    label: "AIGUILLON",
    value: "47004",
    type: "common",
  },
  {
    label: "SAINT-PIERRE-DE-BUZET",
    value: "47267",
    type: "common",
  },
  {
    label: "SAINT-LEON",
    value: "47251",
    type: "common",
  },
  {
    label: "DAMAZAN",
    value: "47078",
    type: "common",
  },
  {
    label: "BUZET-SUR-BAISE",
    value: "47043",
    type: "common",
  },
  {
    label: "CAUBEYRES",
    value: "47058",
    type: "common",
  },
  {
    label: "ANZEX",
    value: "47012",
    type: "common",
  },
  {
    label: "LA REUNION",
    value: "47222",
    type: "common",
  },
  {
    label: "POMPOGNE",
    value: "47208",
    type: "common",
  },
  {
    label: "PINDERES",
    value: "47205",
    type: "common",
  },
  {
    label: "SAINT-MICHEL-DE-CASTELNAU",
    value: "33450",
    type: "common",
  },
  {
    label: "LARTIGUE",
    value: "33232",
    type: "common",
  },
  {
    label: "GISCOS",
    value: "33188",
    type: "common",
  },
  {
    label: "CAPTIEUX",
    value: "33095",
    type: "common",
  },
  {
    label: "LABOUHEYRE",
    value: "40134",
    type: "common",
  },
  {
    label: "LUE",
    value: "40163",
    type: "common",
  },
  {
    label: "SAINT-PAUL-EN-BORN",
    value: "40278",
    type: "common",
  },
  {
    label: "PONTENX-LES-FORGES",
    value: "40229",
    type: "common",
  },
  {
    label: "AUREILHAN",
    value: "40019",
    type: "common",
  },
  {
    label: "TENDE",
    value: "06163",
    type: "common",
  },
  {
    label: "SAINT-SAUVEUR-SUR-TINEE",
    value: "06129",
    type: "common",
  },
  {
    label: "ROURE",
    value: "06111",
    type: "common",
  },
  {
    label: "PEONE",
    value: "06094",
    type: "common",
  },
  {
    label: "CHATEAUNEUF-D'ENTRAUNES",
    value: "06040",
    type: "common",
  },
  {
    label: "SAINT-MARTIN-D'ENTRAUNES",
    value: "06125",
    type: "common",
  },
  {
    label: "COLMARS",
    value: "04061",
    type: "common",
  },
  {
    label: "BEAUVEZER",
    value: "04025",
    type: "common",
  },
  {
    label: "DRAIX",
    value: "04072",
    type: "common",
  },
  {
    label: "LE BRUSQUET",
    value: "04036",
    type: "common",
  },
  {
    label: "MARCOUX",
    value: "04113",
    type: "common",
  },
  {
    label: "THOARD",
    value: "04217",
    type: "common",
  },
  {
    label: "SOURRIBES",
    value: "04211",
    type: "common",
  },
  {
    label: "SALIGNAC",
    value: "04200",
    type: "common",
  },
  {
    label: "ENTREPIERRES",
    value: "04075",
    type: "common",
  },
  {
    label: "NOYERS-SUR-JABRON",
    value: "04139",
    type: "common",
  },
  {
    label: "BEVONS",
    value: "04027",
    type: "common",
  },
  {
    label: "SAINT-VINCENT-SUR-JABRON",
    value: "04199",
    type: "common",
  },
  {
    label: "MONTFROC",
    value: "26200",
    type: "common",
  },
  {
    label: "CUREL",
    value: "04067",
    type: "common",
  },
  {
    label: "CHATEAUNEUF-MIRAVAIL",
    value: "04051",
    type: "common",
  },
  {
    label: "SEDERON",
    value: "26340",
    type: "common",
  },
  {
    label: "VILLEFRANCHE-LE-CHATEAU",
    value: "26375",
    type: "common",
  },
  {
    label: "BARRET-DE-LIOURE",
    value: "26026",
    type: "common",
  },
  {
    label: "REILHANETTE",
    value: "26263",
    type: "common",
  },
  {
    label: "MONTBRUN-LES-BAINS",
    value: "26193",
    type: "common",
  },
  {
    label: "SAVOILLAN",
    value: "84125",
    type: "common",
  },
  {
    label: "BRANTES",
    value: "84021",
    type: "common",
  },
  {
    label: "PLAISIANS",
    value: "26239",
    type: "common",
  },
  {
    label: "SAINT-LEGER-DU-VENTOUX",
    value: "84110",
    type: "common",
  },
  {
    label: "BEAUMONT-DU-VENTOUX",
    value: "84015",
    type: "common",
  },
  {
    label: "SUZETTE",
    value: "84130",
    type: "common",
  },
  {
    label: "MALAUCENE",
    value: "84069",
    type: "common",
  },
  {
    label: "ENTRECHAUX",
    value: "84044",
    type: "common",
  },
  {
    label: "CRESTET",
    value: "84040",
    type: "common",
  },
  {
    label: "SEGURET",
    value: "84126",
    type: "common",
  },
  {
    label: "SABLET",
    value: "84104",
    type: "common",
  },
  {
    label: "VIOLES",
    value: "84149",
    type: "common",
  },
  {
    label: "TRAVAILLAN",
    value: "84134",
    type: "common",
  },
  {
    label: "CAIRANNE",
    value: "84028",
    type: "common",
  },
  {
    label: "SERIGNAN-DU-COMTAT",
    value: "84127",
    type: "common",
  },
  {
    label: "LAGARDE-PAREOL",
    value: "84061",
    type: "common",
  },
  {
    label: "UCHAUX",
    value: "84135",
    type: "common",
  },
  {
    label: "PIOLENC",
    value: "84091",
    type: "common",
  },
  {
    label: "MORNAS",
    value: "84083",
    type: "common",
  },
  {
    label: "VENEJAN",
    value: "30342",
    type: "common",
  },
  {
    label: "SAINT-ETIENNE-DES-SORTS",
    value: "30251",
    type: "common",
  },
  {
    label: "SAINT-NAZAIRE",
    value: "30288",
    type: "common",
  },
  {
    label: "SAINT-GERVAIS",
    value: "30256",
    type: "common",
  },
  {
    label: "SAINT-ALEXANDRE",
    value: "30226",
    type: "common",
  },
  {
    label: "CARSAN",
    value: "30070",
    type: "common",
  },
  {
    label: "SAINT-MICHEL-D'EUZET",
    value: "30287",
    type: "common",
  },
  {
    label: "SAINT-LAURENT-DE-CARNOLS",
    value: "30277",
    type: "common",
  },
  {
    label: "LA ROQUE-SUR-CEZE",
    value: "30222",
    type: "common",
  },
  {
    label: "CORNILLON",
    value: "30096",
    type: "common",
  },
  {
    label: "GOUDARGUES",
    value: "30131",
    type: "common",
  },
  {
    label: "MEJANNES-LE-CLAP",
    value: "30164",
    type: "common",
  },
  {
    label: "FONS-SUR-LUSSAN",
    value: "30113",
    type: "common",
  },
  {
    label: "ALLEGRE-LES-FUMADES",
    value: "30008",
    type: "common",
  },
  {
    label: "THARAUX",
    value: "30327",
    type: "common",
  },
  {
    label: "SAINT-DENIS",
    value: "30247",
    type: "common",
  },
  {
    label: "ROCHEGUDE",
    value: "30218",
    type: "common",
  },
  {
    label: "RIVIERES",
    value: "30215",
    type: "common",
  },
  {
    label: "SAINT-JULIEN-DE-CASSAGNAS",
    value: "30271",
    type: "common",
  },
  {
    label: "ROUSSON",
    value: "30223",
    type: "common",
  },
  {
    label: "POTELIERES",
    value: "30204",
    type: "common",
  },
  {
    label: "LES MAGES",
    value: "30152",
    type: "common",
  },
  {
    label: "SAINT-JULIEN-LES-ROSIERS",
    value: "30274",
    type: "common",
  },
  {
    label: "SAINT-JEAN-DE-VALERISCLE",
    value: "30268",
    type: "common",
  },
  {
    label: "SAINT-FLORENT-SUR-AUZONNET",
    value: "30253",
    type: "common",
  },
  {
    label: "LES SALLES-DU-GARDON",
    value: "30307",
    type: "common",
  },
  {
    label: "LAVAL-PRADEL",
    value: "30142",
    type: "common",
  },
  {
    label: "LA GRAND-COMBE",
    value: "30132",
    type: "common",
  },
  {
    label: "LAMELOUZE",
    value: "30137",
    type: "common",
  },
  {
    label: "BRANOUX-LES-TAILLADES",
    value: "30051",
    type: "common",
  },
  {
    label: "SAINT-MICHEL-DE-DEZE",
    value: "48173",
    type: "common",
  },
  {
    label: "SAINT-MARTIN-DE-BOUBAUX",
    value: "48170",
    type: "common",
  },
  {
    label: "SAINT-MARTIN-DE-LANSUSCLE",
    value: "48171",
    type: "common",
  },
  {
    label: "SAINT-GERMAIN-DE-CALBERTE",
    value: "48155",
    type: "common",
  },
  {
    label: "MOLEZON",
    value: "48098",
    type: "common",
  },
  {
    label: "HURES-LA-PARADE",
    value: "48074",
    type: "common",
  },
  {
    label: "SAINT-PIERRE-DES-TRIPIERS",
    value: "48176",
    type: "common",
  },
  {
    label: "MOSTUEJOULS",
    value: "12160",
    type: "common",
  },
  {
    label: "RIVIERE-SUR-TARN",
    value: "12200",
    type: "common",
  },
  {
    label: "VERRIERES",
    value: "12291",
    type: "common",
  },
  {
    label: "SAINT-LEONS",
    value: "12238",
    type: "common",
  },
  {
    label: "SAINT-LAURENT-DE-LEVEZOU",
    value: "12236",
    type: "common",
  },
  {
    label: "CURAN",
    value: "12307",
    type: "common",
  },
  {
    label: "PRADES-SALARS",
    value: "12188",
    type: "common",
  },
  {
    label: "CANET-DE-SALARS",
    value: "12050",
    type: "common",
  },
  {
    label: "TREMOUILLES",
    value: "12283",
    type: "common",
  },
  {
    label: "COMPS-LA-GRAND-VILLE",
    value: "12073",
    type: "common",
  },
  {
    label: "SAINTE-JULIETTE-SUR-VIAUR",
    value: "12234",
    type: "common",
  },
  {
    label: "MANHAC",
    value: "12137",
    type: "common",
  },
  {
    label: "CAMBOULAZET",
    value: "12045",
    type: "common",
  },
  {
    label: "QUINS",
    value: "12194",
    type: "common",
  },
  {
    label: "SAUVETERRE-DE-ROUERGUE",
    value: "12262",
    type: "common",
  },
  {
    label: "TAYRAC",
    value: "12278",
    type: "common",
  },
  {
    label: "LA SALVETAT-PEYRALES",
    value: "12258",
    type: "common",
  },
  {
    label: "PRADINAS",
    value: "12189",
    type: "common",
  },
  {
    label: "LESCURE-JAOUL",
    value: "12128",
    type: "common",
  },
  {
    label: "LUNAC",
    value: "12135",
    type: "common",
  },
  {
    label: "LA FOUILLADE",
    value: "12105",
    type: "common",
  },
  {
    label: "NAJAC",
    value: "12167",
    type: "common",
  },
  {
    label: "GINALS",
    value: "82069",
    type: "common",
  },
  {
    label: "CASTANET",
    value: "82029",
    type: "common",
  },
  {
    label: "CAYLUS",
    value: "82038",
    type: "common",
  },
  {
    label: "SAINT-GEORGES",
    value: "82162",
    type: "common",
  },
  {
    label: "PUYLAROQUE",
    value: "82148",
    type: "common",
  },
  {
    label: "LAPENCHE",
    value: "82092",
    type: "common",
  },
  {
    label: "CAYRIECH",
    value: "82040",
    type: "common",
  },
  {
    label: "MONTPEZAT-DE-QUERCY",
    value: "82131",
    type: "common",
  },
  {
    label: "MONTALZAT",
    value: "82119",
    type: "common",
  },
  {
    label: "MONTFERMIER",
    value: "82128",
    type: "common",
  },
  {
    label: "LABARTHE",
    value: "82077",
    type: "common",
  },
  {
    label: "CASTELNAU MONTRATIER-SAINTE ALAUZIE",
    value: "46063",
    type: "common",
  },
  {
    label: "CAZES-MONDENARD",
    value: "82042",
    type: "common",
  },
  {
    label: "SAINT-AMANS-DE-PELLAGAL",
    value: "82154",
    type: "common",
  },
  {
    label: "MONTBARLA",
    value: "82122",
    type: "common",
  },
  {
    label: "MONTAGUDET",
    value: "82116",
    type: "common",
  },
  {
    label: "MIRAMONT-DE-QUERCY",
    value: "82111",
    type: "common",
  },
  {
    label: "FAUROUX",
    value: "82060",
    type: "common",
  },
  {
    label: "BRASSAC",
    value: "82024",
    type: "common",
  },
  {
    label: "MONTJOI",
    value: "82130",
    type: "common",
  },
  {
    label: "BOURG-DE-VISA",
    value: "82022",
    type: "common",
  },
  {
    label: "SAINT-MAURIN",
    value: "47260",
    type: "common",
  },
  {
    label: "TAYRAC",
    value: "47305",
    type: "common",
  },
  {
    label: "SAINT-MARTIN-DE-BEAUVILLE",
    value: "47255",
    type: "common",
  },
  {
    label: "DONDAS",
    value: "47082",
    type: "common",
  },
  {
    label: "LA SAUVETAT-DE-SAVERES",
    value: "47289",
    type: "common",
  },
  {
    label: "SAUVAGNAS",
    value: "47288",
    type: "common",
  },
  {
    label: "SAINT-CAPRAIS-DE-LERM",
    value: "47234",
    type: "common",
  },
  {
    label: "PONT-DU-CASSE",
    value: "47209",
    type: "common",
  },
  {
    label: "BON-ENCONTRE",
    value: "47032",
    type: "common",
  },
  {
    label: "LE PASSAGE",
    value: "47201",
    type: "common",
  },
  {
    label: "COLAYRAC-SAINT-CIRQ",
    value: "47069",
    type: "common",
  },
  {
    label: "BRAX",
    value: "47040",
    type: "common",
  },
  {
    label: "AGEN",
    value: "47001",
    type: "common",
  },
  {
    label: "SERIGNAC-SUR-GARONNE",
    value: "47300",
    type: "common",
  },
  {
    label: "SAINTE-COLOMBE-EN-BRUILHOIS",
    value: "47238",
    type: "common",
  },
  {
    label: "SAINT-LAURENT",
    value: "47249",
    type: "common",
  },
  {
    label: "MONTESQUIEU",
    value: "47186",
    type: "common",
  },
  {
    label: "CLERMONT-DESSOUS",
    value: "47066",
    type: "common",
  },
  {
    label: "BRUCH",
    value: "47041",
    type: "common",
  },
  {
    label: "VIANNE",
    value: "47318",
    type: "common",
  },
  {
    label: "FEUGAROLLES",
    value: "47097",
    type: "common",
  },
  {
    label: "XAINTRAILLES",
    value: "47327",
    type: "common",
  },
  {
    label: "MONGAILLARD",
    value: "47176",
    type: "common",
  },
  {
    label: "AMBRUS",
    value: "47008",
    type: "common",
  },
  {
    label: "POMPIEY",
    value: "47207",
    type: "common",
  },
  {
    label: "FARGUES-SUR-OURBISE",
    value: "47093",
    type: "common",
  },
  {
    label: "HOUEILLES",
    value: "47119",
    type: "common",
  },
  {
    label: "SAUMEJAN",
    value: "47286",
    type: "common",
  },
  {
    label: "ALLONS",
    value: "47007",
    type: "common",
  },
  {
    label: "MAILLAS",
    value: "40169",
    type: "common",
  },
  {
    label: "LUXEY",
    value: "40167",
    type: "common",
  },
  {
    label: "TRENSACQ",
    value: "40319",
    type: "common",
  },
  {
    label: "COMMENSACQ",
    value: "40085",
    type: "common",
  },
  {
    label: "ESCOURCE",
    value: "40094",
    type: "common",
  },
  {
    label: "MIMIZAN",
    value: "40184",
    type: "common",
  },
  {
    label: "BELVEDERE",
    value: "06013",
    type: "common",
  },
  {
    label: "SAINT-MARTIN-VESUBIE",
    value: "06127",
    type: "common",
  },
  {
    label: "ROQUEBILLIERE",
    value: "06103",
    type: "common",
  },
  {
    label: "VENANSON",
    value: "06156",
    type: "common",
  },
  {
    label: "VALDEBLORE",
    value: "06153",
    type: "common",
  },
  {
    label: "RIMPLAS",
    value: "06102",
    type: "common",
  },
  {
    label: "ROUBION",
    value: "06110",
    type: "common",
  },
  {
    label: "BEUIL",
    value: "06016",
    type: "common",
  },
  {
    label: "GUILLAUMES",
    value: "06071",
    type: "common",
  },
  {
    label: "SAUZE",
    value: "06133",
    type: "common",
  },
  {
    label: "VILLENEUVE-D'ENTRAUNES",
    value: "06160",
    type: "common",
  },
  {
    label: "THORAME-HAUTE",
    value: "04219",
    type: "common",
  },
  {
    label: "THORAME-BASSE",
    value: "04218",
    type: "common",
  },
  {
    label: "TARTONNE",
    value: "04214",
    type: "common",
  },
  {
    label: "ARCHAIL",
    value: "04009",
    type: "common",
  },
  {
    label: "DIGNE-LES-BAINS",
    value: "04070",
    type: "common",
  },
  {
    label: "CHAMPTERCIER",
    value: "04047",
    type: "common",
  },
  {
    label: "BARRAS",
    value: "04021",
    type: "common",
  },
  {
    label: "CHATEAU-ARNOUX-SAINT-AUBAN",
    value: "04049",
    type: "common",
  },
  {
    label: "VOLONNE",
    value: "04244",
    type: "common",
  },
  {
    label: "PEIPIN",
    value: "04145",
    type: "common",
  },
  {
    label: "CHATEAUNEUF-VAL-SAINT-DONAT",
    value: "04053",
    type: "common",
  },
  {
    label: "AUBIGNOSC",
    value: "04013",
    type: "common",
  },
  {
    label: "VALBELLE",
    value: "04229",
    type: "common",
  },
  {
    label: "LA ROCHEGIRON",
    value: "04169",
    type: "common",
  },
  {
    label: "L'HOSPITALET",
    value: "04095",
    type: "common",
  },
  {
    label: "REDORTIERS",
    value: "04159",
    type: "common",
  },
  {
    label: "LES OMERGUES",
    value: "04140",
    type: "common",
  },
  {
    label: "FERRASSIERES",
    value: "26135",
    type: "common",
  },
  {
    label: "AUREL",
    value: "84005",
    type: "common",
  },
  {
    label: "BEDOIN",
    value: "84017",
    type: "common",
  },
  {
    label: "CRILLON-LE-BRAVE",
    value: "84041",
    type: "common",
  },
  {
    label: "SAINT-HIPPOLYTE-LE-GRAVEYRON",
    value: "84109",
    type: "common",
  },
  {
    label: "LA ROQUE-ALRIC",
    value: "84100",
    type: "common",
  },
  {
    label: "MODENE",
    value: "84077",
    type: "common",
  },
  {
    label: "CAROMB",
    value: "84030",
    type: "common",
  },
  {
    label: "LE BARROUX",
    value: "84008",
    type: "common",
  },
  {
    label: "LAFARE",
    value: "84059",
    type: "common",
  },
  {
    label: "GIGONDAS",
    value: "84049",
    type: "common",
  },
  {
    label: "BEAUMES-DE-VENISE",
    value: "84012",
    type: "common",
  },
  {
    label: "VACQUEYRAS",
    value: "84136",
    type: "common",
  },
  {
    label: "JONQUIERES",
    value: "84056",
    type: "common",
  },
  {
    label: "CAMARET-SUR-AIGUES",
    value: "84029",
    type: "common",
  },
  {
    label: "ORANGE",
    value: "84087",
    type: "common",
  },
  {
    label: "ORSAN",
    value: "30191",
    type: "common",
  },
  {
    label: "CODOLET",
    value: "30084",
    type: "common",
  },
  {
    label: "CHUSCLAN",
    value: "30081",
    type: "common",
  },
  {
    label: "BAGNOLS-SUR-CEZE",
    value: "30028",
    type: "common",
  },
  {
    label: "SAINT-ANDRE-D'OLERARGUES",
    value: "30232",
    type: "common",
  },
  {
    label: "SABRAN",
    value: "30225",
    type: "common",
  },
  {
    label: "VERFEUIL",
    value: "30343",
    type: "common",
  },
  {
    label: "SAINT-MARCEL-DE-CAREIRET",
    value: "30282",
    type: "common",
  },
  {
    label: "VALLERARGUES",
    value: "30338",
    type: "common",
  },
  {
    label: "LUSSAN",
    value: "30151",
    type: "common",
  },
  {
    label: "BROUZET-LES-ALES",
    value: "30055",
    type: "common",
  },
  {
    label: "BOUQUET",
    value: "30048",
    type: "common",
  },
  {
    label: "SERVAS",
    value: "30318",
    type: "common",
  },
  {
    label: "LES PLANS",
    value: "30197",
    type: "common",
  },
  {
    label: "NAVACELLES",
    value: "30187",
    type: "common",
  },
  {
    label: "SALINDRES",
    value: "30305",
    type: "common",
  },
  {
    label: "SAINT-PRIVAT-DES-VIEUX",
    value: "30294",
    type: "common",
  },
  {
    label: "SOUSTELLE",
    value: "30323",
    type: "common",
  },
  {
    label: "SAINT-MARTIN-DE-VALGALGUES",
    value: "30284",
    type: "common",
  },
  {
    label: "CENDRAS",
    value: "30077",
    type: "common",
  },
  {
    label: "SAINT-PAUL-LA-COSTE",
    value: "30291",
    type: "common",
  },
  {
    label: "SAINT-ETIENNE-VALLEE-FRANCAISE",
    value: "48148",
    type: "common",
  },
  {
    label: "MOISSAC-VALLEE-FRANCAISE",
    value: "48097",
    type: "common",
  },
  {
    label: "SAINTE-CROIX-VALLEE-FRANCAISE",
    value: "48144",
    type: "common",
  },
  {
    label: "LE POMPIDOU",
    value: "48115",
    type: "common",
  },
  {
    label: "GABRIAC",
    value: "48067",
    type: "common",
  },
  {
    label: "SAINT-ANDRE-DE-VALBORGNE",
    value: "30231",
    type: "common",
  },
  {
    label: "BASSURELS",
    value: "48020",
    type: "common",
  },
  {
    label: "ROUSSES",
    value: "48130",
    type: "common",
  },
  {
    label: "FRAISSINET-DE-FOURQUES",
    value: "48065",
    type: "common",
  },
  {
    label: "MEYRUEIS",
    value: "48096",
    type: "common",
  },
  {
    label: "GATUZIERES",
    value: "48069",
    type: "common",
  },
  {
    label: "VEYREAU",
    value: "12293",
    type: "common",
  },
  {
    label: "SAINT-ANDRE-DE-VEZINES",
    value: "12211",
    type: "common",
  },
  {
    label: "LE ROZIER",
    value: "48131",
    type: "common",
  },
  {
    label: "PEYRELEAU",
    value: "12180",
    type: "common",
  },
  {
    label: "PAULHE",
    value: "12178",
    type: "common",
  },
  {
    label: "LA CRESSE",
    value: "12086",
    type: "common",
  },
  {
    label: "COMPEYRE",
    value: "12070",
    type: "common",
  },
  {
    label: "AGUESSAC",
    value: "12002",
    type: "common",
  },
  {
    label: "SAINT-BEAUZELY",
    value: "12213",
    type: "common",
  },
  {
    label: "SALLES-CURAN",
    value: "12253",
    type: "common",
  },
  {
    label: "ARVIEU",
    value: "12011",
    type: "common",
  },
  {
    label: "SALMIECH",
    value: "12255",
    type: "common",
  },
  {
    label: "AURIAC-LAGAST",
    value: "12015",
    type: "common",
  },
  {
    label: "CASSAGNES-BEGONHES",
    value: "12057",
    type: "common",
  },
  {
    label: "CENTRES",
    value: "12065",
    type: "common",
  },
  {
    label: "CAMJAC",
    value: "12046",
    type: "common",
  },
  {
    label: "TAURIAC-DE-NAUCELLE",
    value: "12276",
    type: "common",
  },
  {
    label: "NAUCELLE",
    value: "12169",
    type: "common",
  },
  {
    label: "CABANES",
    value: "12041",
    type: "common",
  },
  {
    label: "CRESPIN",
    value: "12085",
    type: "common",
  },
  {
    label: "CASTELMARY",
    value: "12060",
    type: "common",
  },
  {
    label: "MIRANDOL-BOURGNOUNAC",
    value: "81168",
    type: "common",
  },
  {
    label: "JOUQUEVIEL",
    value: "81110",
    type: "common",
  },
  {
    label: "SAINT-CHRISTOPHE",
    value: "81245",
    type: "common",
  },
  {
    label: "MONTIRAT",
    value: "81180",
    type: "common",
  },
  {
    label: "BOR-ET-BAR",
    value: "12029",
    type: "common",
  },
  {
    label: "LAGUEPIE",
    value: "82088",
    type: "common",
  },
  {
    label: "SAINT-ANDRE-DE-NAJAC",
    value: "12210",
    type: "common",
  },
  {
    label: "VERFEIL",
    value: "82191",
    type: "common",
  },
  {
    label: "VAREN",
    value: "82187",
    type: "common",
  },
  {
    label: "LE RIOLS",
    value: "81224",
    type: "common",
  },
  {
    label: "FENEYROLS",
    value: "82061",
    type: "common",
  },
  {
    label: "ESPINAS",
    value: "82056",
    type: "common",
  },
  {
    label: "SAINT-ANTONIN-NOBLE-VAL",
    value: "82155",
    type: "common",
  },
  {
    label: "LAVAURETTE",
    value: "82095",
    type: "common",
  },
  {
    label: "SEPTFONDS",
    value: "82179",
    type: "common",
  },
  {
    label: "MONTEILS",
    value: "82126",
    type: "common",
  },
  {
    label: "SAINT-VINCENT-D'AUTEJAC",
    value: "82174",
    type: "common",
  },
  {
    label: "CAUSSADE",
    value: "82037",
    type: "common",
  },
  {
    label: "AUTY",
    value: "82007",
    type: "common",
  },
  {
    label: "MOLIERES",
    value: "82113",
    type: "common",
  },
  {
    label: "MIRABEL",
    value: "82110",
    type: "common",
  },
  {
    label: "PUYCORNET",
    value: "82144",
    type: "common",
  },
  {
    label: "VAZERAC",
    value: "82189",
    type: "common",
  },
  {
    label: "DURFORT-LACAPELETTE",
    value: "82051",
    type: "common",
  },
  {
    label: "MONTESQUIEU",
    value: "82127",
    type: "common",
  },
  {
    label: "SAINT-PAUL-D'ESPIS",
    value: "82170",
    type: "common",
  },
  {
    label: "SAINT-NAZAIRE-DE-VALENTANE",
    value: "82168",
    type: "common",
  },
  {
    label: "SAINT-CLAIR",
    value: "82160",
    type: "common",
  },
  {
    label: "GASQUES",
    value: "82065",
    type: "common",
  },
  {
    label: "CASTELSAGRAT",
    value: "82032",
    type: "common",
  },
  {
    label: "PERVILLE",
    value: "82138",
    type: "common",
  },
  {
    label: "SAINT-URCISSE",
    value: "47281",
    type: "common",
  },
  {
    label: "PUYMIROL",
    value: "47217",
    type: "common",
  },
  {
    label: "GRAYSSAS",
    value: "47113",
    type: "common",
  },
  {
    label: "CLERMONT-SOUBIRAN",
    value: "47067",
    type: "common",
  },
  {
    label: "SAINT-ROMAIN-LE-NOBLE",
    value: "47274",
    type: "common",
  },
  {
    label: "SAINT-PIERRE-DE-CLAIRAC",
    value: "47269",
    type: "common",
  },
  {
    label: "SAINT-JEAN-DE-THURAC",
    value: "47248",
    type: "common",
  },
  {
    label: "SAUVETERRE-SAINT-DENIS",
    value: "47293",
    type: "common",
  },
  {
    label: "LAFOX",
    value: "47128",
    type: "common",
  },
  {
    label: "CASTELCULIER",
    value: "47051",
    type: "common",
  },
  {
    label: "BOE",
    value: "47031",
    type: "common",
  },
  {
    label: "MOIRAX",
    value: "47169",
    type: "common",
  },
  {
    label: "ESTILLAC",
    value: "47091",
    type: "common",
  },
  {
    label: "AUBIAC",
    value: "47016",
    type: "common",
  },
  {
    label: "ROQUEFORT",
    value: "47225",
    type: "common",
  },
  {
    label: "MONCAUT",
    value: "47172",
    type: "common",
  },
  {
    label: "SAUMONT",
    value: "47287",
    type: "common",
  },
  {
    label: "MONTAGNAC-SUR-AUVIGNON",
    value: "47180",
    type: "common",
  },
  {
    label: "CALIGNAC",
    value: "47045",
    type: "common",
  },
  {
    label: "NERAC",
    value: "47195",
    type: "common",
  },
  {
    label: "ESPIENS",
    value: "47090",
    type: "common",
  },
  {
    label: "LAVARDAC",
    value: "47143",
    type: "common",
  },
  {
    label: "BARBASTE",
    value: "47021",
    type: "common",
  },
  {
    label: "DURANCE",
    value: "47085",
    type: "common",
  },
  {
    label: "BOUSSES",
    value: "47039",
    type: "common",
  },
  {
    label: "BOURRIOT-BERGONCE",
    value: "40053",
    type: "common",
  },
  {
    label: "RETJONS",
    value: "40164",
    type: "common",
  },
  {
    label: "LENCOUACQ",
    value: "40149",
    type: "common",
  },
  {
    label: "LE SEN",
    value: "40297",
    type: "common",
  },
  {
    label: "LABRIT",
    value: "40135",
    type: "common",
  },
  {
    label: "SABRES",
    value: "40246",
    type: "common",
  },
  {
    label: "SOLFERINO",
    value: "40303",
    type: "common",
  },
  {
    label: "MEZOS",
    value: "40182",
    type: "common",
  },
  {
    label: "BIAS",
    value: "40043",
    type: "common",
  },
  {
    label: "SAINT-JULIEN-EN-BORN",
    value: "40266",
    type: "common",
  },
  {
    label: "SAORGE",
    value: "06132",
    type: "common",
  },
  {
    label: "FONTAN",
    value: "06062",
    type: "common",
  },
  {
    label: "LA BOLLENE-VESUBIE",
    value: "06020",
    type: "common",
  },
  {
    label: "CLANS",
    value: "06042",
    type: "common",
  },
  {
    label: "MARIE",
    value: "06080",
    type: "common",
  },
  {
    label: "BAIROLS",
    value: "06009",
    type: "common",
  },
  {
    label: "PIERLAS",
    value: "06096",
    type: "common",
  },
  {
    label: "LIEUCHE",
    value: "06076",
    type: "common",
  },
  {
    label: "ILONSE",
    value: "06072",
    type: "common",
  },
  {
    label: "RIGAUD",
    value: "06101",
    type: "common",
  },
  {
    label: "LA CROIX-SUR-ROUDOULE",
    value: "06051",
    type: "common",
  },
  {
    label: "AUVARE",
    value: "06008",
    type: "common",
  },
  {
    label: "SAINT-LEGER",
    value: "06124",
    type: "common",
  },
  {
    label: "DALUIS",
    value: "06053",
    type: "common",
  },
  {
    label: "SAUSSES",
    value: "04202",
    type: "common",
  },
  {
    label: "CASTELLET-LES-SAUSSES",
    value: "04042",
    type: "common",
  },
  {
    label: "MEAILLES",
    value: "04115",
    type: "common",
  },
  {
    label: "LE FUGERET",
    value: "04090",
    type: "common",
  },
  {
    label: "LA MURE-ARGENS",
    value: "04136",
    type: "common",
  },
  {
    label: "LAMBRUISSE",
    value: "04099",
    type: "common",
  },
  {
    label: "CLUMANC",
    value: "04059",
    type: "common",
  },
  {
    label: "ENTRAGES",
    value: "04074",
    type: "common",
  },
  {
    label: "CHATEAUREDON",
    value: "04054",
    type: "common",
  },
  {
    label: "MALLEMOISSON",
    value: "04110",
    type: "common",
  },
  {
    label: "AIGLUN",
    value: "04001",
    type: "common",
  },
  {
    label: "MIRABEAU",
    value: "04122",
    type: "common",
  },
  {
    label: "MALIJAI",
    value: "04108",
    type: "common",
  },
  {
    label: "L'ESCALE",
    value: "04079",
    type: "common",
  },
  {
    label: "MONTFORT",
    value: "04127",
    type: "common",
  },
  {
    label: "MALLEFOUGASSE-AUGES",
    value: "04109",
    type: "common",
  },
  {
    label: "MONTLAUX",
    value: "04130",
    type: "common",
  },
  {
    label: "CRUIS",
    value: "04065",
    type: "common",
  },
  {
    label: "SAINT-ETIENNE-LES-ORGUES",
    value: "04178",
    type: "common",
  },
  {
    label: "SAUMANE",
    value: "04201",
    type: "common",
  },
  {
    label: "LARDIERS",
    value: "04101",
    type: "common",
  },
  {
    label: "SAINT-TRINIT",
    value: "84120",
    type: "common",
  },
  {
    label: "REVEST-DU-BION",
    value: "04163",
    type: "common",
  },
  {
    label: "SAULT",
    value: "84123",
    type: "common",
  },
  {
    label: "MONIEUX",
    value: "84079",
    type: "common",
  },
  {
    label: "VILLES-SUR-AUZON",
    value: "84148",
    type: "common",
  },
  {
    label: "FLASSAN",
    value: "84046",
    type: "common",
  },
  {
    label: "SAINT-PIERRE-DE-VASSOLS",
    value: "84115",
    type: "common",
  },
  {
    label: "MORMOIRON",
    value: "84082",
    type: "common",
  },
  {
    label: "MAZAN",
    value: "84072",
    type: "common",
  },
  {
    label: "LORIOL-DU-COMTAT",
    value: "84067",
    type: "common",
  },
  {
    label: "AUBIGNAN",
    value: "84004",
    type: "common",
  },
  {
    label: "SARRIANS",
    value: "84122",
    type: "common",
  },
  {
    label: "COURTHEZON",
    value: "84039",
    type: "common",
  },
  {
    label: "CHATEAUNEUF-DU-PAPE",
    value: "84037",
    type: "common",
  },
  {
    label: "CADEROUSSE",
    value: "84027",
    type: "common",
  },
  {
    label: "MONTFAUCON",
    value: "30178",
    type: "common",
  },
  {
    label: "LAUDUN-L'ARDOISE",
    value: "30141",
    type: "common",
  },
  {
    label: "SAINT-GENIES-DE-COMOLAS",
    value: "30254",
    type: "common",
  },
  {
    label: "SAINT-PAUL-LES-FONTS",
    value: "30355",
    type: "common",
  },
  {
    label: "TRESQUES",
    value: "30331",
    type: "common",
  },
  {
    label: "GAUJAC",
    value: "30127",
    type: "common",
  },
  {
    label: "CONNAUX",
    value: "30092",
    type: "common",
  },
  {
    label: "VALLABRIX",
    value: "30337",
    type: "common",
  },
  {
    label: "SAINT-PONS-LA-CALM",
    value: "30292",
    type: "common",
  },
  {
    label: "POUGNADORESSE",
    value: "30205",
    type: "common",
  },
  {
    label: "LE PIN",
    value: "30196",
    type: "common",
  },
  {
    label: "CAVILLARGUES",
    value: "30076",
    type: "common",
  },
  {
    label: "SAINT-LAURENT-LA-VERNEDE",
    value: "30279",
    type: "common",
  },
  {
    label: "FONTARECHES",
    value: "30115",
    type: "common",
  },
  {
    label: "LA BASTIDE-D'ENGRAS",
    value: "30031",
    type: "common",
  },
  {
    label: "LA BRUGUIERE",
    value: "30056",
    type: "common",
  },
  {
    label: "BELVEZET",
    value: "30035",
    type: "common",
  },
  {
    label: "SEYNES",
    value: "30320",
    type: "common",
  },
  {
    label: "AIGALIERS",
    value: "30001",
    type: "common",
  },
  {
    label: "SAINT-JUST-ET-VACQUIERES",
    value: "30275",
    type: "common",
  },
  {
    label: "SAINT-HIPPOLYTE-DE-CATON",
    value: "30261",
    type: "common",
  },
  {
    label: "MONTEILS",
    value: "30177",
    type: "common",
  },
  {
    label: "MONS",
    value: "30173",
    type: "common",
  },
  {
    label: "EUZET",
    value: "30109",
    type: "common",
  },
  {
    label: "SAINT-HILAIRE-DE-BRETHMAS",
    value: "30259",
    type: "common",
  },
  {
    label: "MEJANNES-LES-ALES",
    value: "30165",
    type: "common",
  },
  {
    label: "DEAUX",
    value: "30101",
    type: "common",
  },
  {
    label: "ALES",
    value: "30007",
    type: "common",
  },
  {
    label: "SAINT-JEAN-DU-PIN",
    value: "30270",
    type: "common",
  },
  {
    label: "SAINT-CHRISTOL-LEZ-ALES",
    value: "30243",
    type: "common",
  },
  {
    label: "SAINT-SEBASTIEN-D'AIGREFEUILLE",
    value: "30298",
    type: "common",
  },
  {
    label: "MIALET",
    value: "30168",
    type: "common",
  },
  {
    label: "GENERARGUES",
    value: "30129",
    type: "common",
  },
  {
    label: "CORBES",
    value: "30094",
    type: "common",
  },
  {
    label: "SAINT-JEAN-DU-GARD",
    value: "30269",
    type: "common",
  },
  {
    label: "SAUMANE",
    value: "30310",
    type: "common",
  },
  {
    label: "PEYROLLES",
    value: "30195",
    type: "common",
  },
  {
    label: "L'ESTRECHURE",
    value: "30108",
    type: "common",
  },
  {
    label: "LES PLANTIERS",
    value: "30198",
    type: "common",
  },
  {
    label: "SAINT-SAUVEUR-CAMPRIEU",
    value: "30297",
    type: "common",
  },
  {
    label: "LANUEJOLS",
    value: "30139",
    type: "common",
  },
  {
    label: "REVENS",
    value: "30213",
    type: "common",
  },
  {
    label: "LA ROQUE-SAINTE-MARGUERITE",
    value: "12204",
    type: "common",
  },
  {
    label: "MILLAU",
    value: "12145",
    type: "common",
  },
  {
    label: "COMPREGNAC",
    value: "12072",
    type: "common",
  },
  {
    label: "MONTJAUX",
    value: "12153",
    type: "common",
  },
  {
    label: "CASTELNAU-PEGAYROLS",
    value: "12062",
    type: "common",
  },
  {
    label: "VILLEFRANCHE-DE-PANAT",
    value: "12299",
    type: "common",
  },
  {
    label: "AYSSENES",
    value: "12017",
    type: "common",
  },
  {
    label: "DURENQUE",
    value: "12092",
    type: "common",
  },
  {
    label: "ALRANCE",
    value: "12006",
    type: "common",
  },
  {
    label: "LA SELVE",
    value: "12267",
    type: "common",
  },
  {
    label: "RULLAC-SAINT-CIRQ",
    value: "12207",
    type: "common",
  },
  {
    label: "SAINT-JUST-SUR-VIAUR",
    value: "12235",
    type: "common",
  },
  {
    label: "MELJAC",
    value: "12144",
    type: "common",
  },
  {
    label: "TREBAN",
    value: "81302",
    type: "common",
  },
  {
    label: "TANUS",
    value: "81292",
    type: "common",
  },
  {
    label: "PAMPELONNE",
    value: "81201",
    type: "common",
  },
  {
    label: "TREVIEN",
    value: "81304",
    type: "common",
  },
  {
    label: "SAINTE-GEMME",
    value: "81249",
    type: "common",
  },
  {
    label: "ALMAYRAC",
    value: "81008",
    type: "common",
  },
  {
    label: "LE SEGUR",
    value: "81280",
    type: "common",
  },
  {
    label: "SAINT-MARTIN-LAGUEPIE",
    value: "81263",
    type: "common",
  },
  {
    label: "SAINT-MARCEL-CAMPES",
    value: "81262",
    type: "common",
  },
  {
    label: "LACAPELLE-SEGALAR",
    value: "81123",
    type: "common",
  },
  {
    label: "BOURNAZEL",
    value: "81035",
    type: "common",
  },
  {
    label: "LAPARROUQUIAL",
    value: "81135",
    type: "common",
  },
  {
    label: "MOUZIEYS-PANENS",
    value: "81191",
    type: "common",
  },
  {
    label: "MILHARS",
    value: "81165",
    type: "common",
  },
  {
    label: "SAINT-MICHEL-DE-VAX",
    value: "81265",
    type: "common",
  },
  {
    label: "ROUSSAYROLLES",
    value: "81234",
    type: "common",
  },
  {
    label: "MONTROSIER",
    value: "81184",
    type: "common",
  },
  {
    label: "MARNAVES",
    value: "81154",
    type: "common",
  },
  {
    label: "CAZALS",
    value: "82041",
    type: "common",
  },
  {
    label: "SAINT-CIRQ",
    value: "82159",
    type: "common",
  },
  {
    label: "MONTRICOUX",
    value: "82132",
    type: "common",
  },
  {
    label: "BIOULE",
    value: "82018",
    type: "common",
  },
  {
    label: "REALVILLE",
    value: "82149",
    type: "common",
  },
  {
    label: "CAYRAC",
    value: "82039",
    type: "common",
  },
  {
    label: "LAMOTHE-CAPDEVILLE",
    value: "82090",
    type: "common",
  },
  {
    label: "ALBIAS",
    value: "82002",
    type: "common",
  },
  {
    label: "PIQUECOS",
    value: "82140",
    type: "common",
  },
  {
    label: "L'HONOR-DE-COS",
    value: "82076",
    type: "common",
  },
  {
    label: "MONTASTRUC",
    value: "82120",
    type: "common",
  },
  {
    label: "MEAUZAC",
    value: "82108",
    type: "common",
  },
  {
    label: "LAFRANCAISE",
    value: "82087",
    type: "common",
  },
  {
    label: "LIZAC",
    value: "82099",
    type: "common",
  },
  {
    label: "LABASTIDE-DU-TEMPLE",
    value: "82080",
    type: "common",
  },
  {
    label: "LES BARTHES",
    value: "82012",
    type: "common",
  },
  {
    label: "MOISSAC",
    value: "82112",
    type: "common",
  },
  {
    label: "BOUDOU",
    value: "82019",
    type: "common",
  },
  {
    label: "VALENCE",
    value: "82186",
    type: "common",
  },
  {
    label: "SAINT-VINCENT-LESPINASSE",
    value: "82175",
    type: "common",
  },
  {
    label: "POMMEVIC",
    value: "82141",
    type: "common",
  },
  {
    label: "MALAUSE",
    value: "82101",
    type: "common",
  },
  {
    label: "GOUDOURVILLE",
    value: "82073",
    type: "common",
  },
  {
    label: "SAINT-LOUP",
    value: "82165",
    type: "common",
  },
  {
    label: "LAMAGISTERE",
    value: "82089",
    type: "common",
  },
  {
    label: "GOLFECH",
    value: "82072",
    type: "common",
  },
  {
    label: "DONZAC",
    value: "82049",
    type: "common",
  },
  {
    label: "DUNES",
    value: "82050",
    type: "common",
  },
  {
    label: "SAINT-SIXTE",
    value: "47279",
    type: "common",
  },
  {
    label: "SAINT-NICOLAS-DE-LA-BALERME",
    value: "47262",
    type: "common",
  },
  {
    label: "CAUDECOSTE",
    value: "47060",
    type: "common",
  },
  {
    label: "LAYRAC",
    value: "47145",
    type: "common",
  },
  {
    label: "FALS",
    value: "47092",
    type: "common",
  },
  {
    label: "CUQ",
    value: "47076",
    type: "common",
  },
  {
    label: "MARMONT-PACHAS",
    value: "47158",
    type: "common",
  },
  {
    label: "SAINT-VINCENT-DE-LAMONTJOIE",
    value: "47282",
    type: "common",
  },
  {
    label: "LAPLUME",
    value: "47137",
    type: "common",
  },
  {
    label: "LAMONTJOIE",
    value: "47133",
    type: "common",
  },
  {
    label: "NOMDIEU",
    value: "47197",
    type: "common",
  },
  {
    label: "FIEUX",
    value: "47098",
    type: "common",
  },
  {
    label: "LASSERRE",
    value: "47139",
    type: "common",
  },
  {
    label: "FRECHOU",
    value: "47103",
    type: "common",
  },
  {
    label: "ANDIRAN",
    value: "47009",
    type: "common",
  },
  {
    label: "REAUP-LISSE",
    value: "47221",
    type: "common",
  },
  {
    label: "ARX",
    value: "40015",
    type: "common",
  },
  {
    label: "RIMBEZ-ET-BAUDIETS",
    value: "40242",
    type: "common",
  },
  {
    label: "BAUDIGNAN",
    value: "40030",
    type: "common",
  },
  {
    label: "LUBBON",
    value: "40161",
    type: "common",
  },
  {
    label: "LOSSE",
    value: "40158",
    type: "common",
  },
  {
    label: "VIELLE-SOUBIRAN",
    value: "40327",
    type: "common",
  },
  {
    label: "SAINT-GOR",
    value: "40262",
    type: "common",
  },
  {
    label: "ARUE",
    value: "40014",
    type: "common",
  },
  {
    label: "CACHEN",
    value: "40058",
    type: "common",
  },
  {
    label: "BELIS",
    value: "40033",
    type: "common",
  },
  {
    label: "VERT",
    value: "40323",
    type: "common",
  },
  {
    label: "GAREIN",
    value: "40105",
    type: "common",
  },
  {
    label: "LUGLON",
    value: "40165",
    type: "common",
  },
  {
    label: "ONESSE-LAHARIE",
    value: "40210",
    type: "common",
  },
  {
    label: "UZA",
    value: "40322",
    type: "common",
  },
  {
    label: "BREIL-SUR-ROYA",
    value: "06023",
    type: "common",
  },
  {
    label: "MOULINET",
    value: "06086",
    type: "common",
  },
  {
    label: "LANTOSQUE",
    value: "06074",
    type: "common",
  },
  {
    label: "LA TOUR",
    value: "06144",
    type: "common",
  },
  {
    label: "TOURNEFORT",
    value: "06146",
    type: "common",
  },
  {
    label: "MASSOINS",
    value: "06082",
    type: "common",
  },
  {
    label: "VILLARS-SUR-VAR",
    value: "06158",
    type: "common",
  },
  {
    label: "TOUET-SUR-VAR",
    value: "06143",
    type: "common",
  },
  {
    label: "THIERY",
    value: "06139",
    type: "common",
  },
  {
    label: "PUGET-ROSTANG",
    value: "06098",
    type: "common",
  },
  {
    label: "PUGET-THENIERS",
    value: "06099",
    type: "common",
  },
  {
    label: "ENTREVAUX",
    value: "04076",
    type: "common",
  },
  {
    label: "SAINT-BENOIT",
    value: "04174",
    type: "common",
  },
  {
    label: "BRAUX",
    value: "04032",
    type: "common",
  },
  {
    label: "ANNOT",
    value: "04008",
    type: "common",
  },
  {
    label: "ANGLES",
    value: "04007",
    type: "common",
  },
  {
    label: "ALLONS",
    value: "04005",
    type: "common",
  },
  {
    label: "SAINT-ANDRE-LES-ALPES",
    value: "04173",
    type: "common",
  },
  {
    label: "MORIEZ",
    value: "04133",
    type: "common",
  },
  {
    label: "SAINT-LIONS",
    value: "04187",
    type: "common",
  },
  {
    label: "SAINT-JACQUES",
    value: "04180",
    type: "common",
  },
  {
    label: "CHAUDON-NORANTE",
    value: "04055",
    type: "common",
  },
  {
    label: "BEYNES",
    value: "04028",
    type: "common",
  },
  {
    label: "SAINT-JEANNET",
    value: "04181",
    type: "common",
  },
  {
    label: "MEZEL",
    value: "04121",
    type: "common",
  },
  {
    label: "LE CHAFFAUT-SAINT-JURSON",
    value: "04046",
    type: "common",
  },
  {
    label: "PUIMICHEL",
    value: "04156",
    type: "common",
  },
  {
    label: "LES MEES",
    value: "04116",
    type: "common",
  },
  {
    label: "PEYRUIS",
    value: "04149",
    type: "common",
  },
  {
    label: "LURS",
    value: "04106",
    type: "common",
  },
  {
    label: "GANAGOBIE",
    value: "04091",
    type: "common",
  },
  {
    label: "SIGONCE",
    value: "04206",
    type: "common",
  },
  {
    label: "REVEST-SAINT-MARTIN",
    value: "04164",
    type: "common",
  },
  {
    label: "FONTIENNE",
    value: "04087",
    type: "common",
  },
  {
    label: "ONGLES",
    value: "04141",
    type: "common",
  },
  {
    label: "LIMANS",
    value: "04104",
    type: "common",
  },
  {
    label: "BANON",
    value: "04018",
    type: "common",
  },
  {
    label: "SIMIANE-LA-ROTONDE",
    value: "04208",
    type: "common",
  },
  {
    label: "MONTSALIER",
    value: "04132",
    type: "common",
  },
  {
    label: "SAINT-CHRISTOL",
    value: "84107",
    type: "common",
  },
  {
    label: "LAGARDE-D'APT",
    value: "84060",
    type: "common",
  },
  {
    label: "METHAMIS",
    value: "84075",
    type: "common",
  },
  {
    label: "BLAUVAC",
    value: "84018",
    type: "common",
  },
  {
    label: "MALEMORT-DU-COMTAT",
    value: "84070",
    type: "common",
  },
  {
    label: "SAINT-DIDIER",
    value: "84108",
    type: "common",
  },
  {
    label: "CARPENTRAS",
    value: "84031",
    type: "common",
  },
  {
    label: "PERNES-LES-FONTAINES",
    value: "84088",
    type: "common",
  },
  {
    label: "MONTEUX",
    value: "84080",
    type: "common",
  },
  {
    label: "ENTRAIGUES-SUR-LA-SORGUE",
    value: "84043",
    type: "common",
  },
  {
    label: "BEDARRIDES",
    value: "84016",
    type: "common",
  },
  {
    label: "ALTHEN-DES-PALUDS",
    value: "84001",
    type: "common",
  },
  {
    label: "SORGUES",
    value: "84129",
    type: "common",
  },
  {
    label: "SAUVETERRE",
    value: "30312",
    type: "common",
  },
  {
    label: "ROQUEMAURE",
    value: "30221",
    type: "common",
  },
  {
    label: "PUJAUT",
    value: "30209",
    type: "common",
  },
  {
    label: "TAVEL",
    value: "30326",
    type: "common",
  },
  {
    label: "SAINT-LAURENT-DES-ARBRES",
    value: "30278",
    type: "common",
  },
  {
    label: "LIRAC",
    value: "30149",
    type: "common",
  },
  {
    label: "VALLIGUIERES",
    value: "30340",
    type: "common",
  },
  {
    label: "SAINT-VICTOR-LA-COSTE",
    value: "30302",
    type: "common",
  },
  {
    label: "POUZILHAC",
    value: "30207",
    type: "common",
  },
  {
    label: "SAINT-VICTOR-DES-OULES",
    value: "30301",
    type: "common",
  },
  {
    label: "SAINT-HIPPOLYTE-DE-MONTAIGU",
    value: "30262",
    type: "common",
  },
  {
    label: "FLAUX",
    value: "30110",
    type: "common",
  },
  {
    label: "LA CAPELLE-ET-MASMOLENE",
    value: "30067",
    type: "common",
  },
  {
    label: "UZES",
    value: "30334",
    type: "common",
  },
  {
    label: "SAINT-SIFFRET",
    value: "30299",
    type: "common",
  },
  {
    label: "SAINT-QUENTIN-LA-POTERIE",
    value: "30295",
    type: "common",
  },
  {
    label: "SERVIERS-ET-LABAUME",
    value: "30319",
    type: "common",
  },
  {
    label: "MONTAREN-ET-SAINT-MEDIERS",
    value: "30174",
    type: "common",
  },
  {
    label: "AUBUSSARGUES",
    value: "30021",
    type: "common",
  },
  {
    label: "SAINT-MAURICE-DE-CAZEVIEILLE",
    value: "30285",
    type: "common",
  },
  {
    label: "FOISSAC",
    value: "30111",
    type: "common",
  },
  {
    label: "COLLORGUES",
    value: "30086",
    type: "common",
  },
  {
    label: "BARON",
    value: "30030",
    type: "common",
  },
  {
    label: "SAINT-JEAN-DE-CEYRARGUES",
    value: "30264",
    type: "common",
  },
  {
    label: "SAINT-ETIENNE-DE-L'OLM",
    value: "30250",
    type: "common",
  },
  {
    label: "SAINT-CESAIRE-DE-GAUZIGNAN",
    value: "30240",
    type: "common",
  },
  {
    label: "NERS",
    value: "30188",
    type: "common",
  },
  {
    label: "MARTIGNARGUES",
    value: "30158",
    type: "common",
  },
  {
    label: "CRUVIERS-LASCOURS",
    value: "30100",
    type: "common",
  },
  {
    label: "VEZENOBRES",
    value: "30348",
    type: "common",
  },
  {
    label: "RIBAUTE-LES-TAVERNES",
    value: "30214",
    type: "common",
  },
  {
    label: "MASSANES",
    value: "30161",
    type: "common",
  },
  {
    label: "CASSAGNOLES",
    value: "30071",
    type: "common",
  },
  {
    label: "CARDET",
    value: "30068",
    type: "common",
  },
  {
    label: "MASSILLARGUES-ATTUECH",
    value: "30162",
    type: "common",
  },
  {
    label: "BOISSET-ET-GAUJAC",
    value: "30042",
    type: "common",
  },
  {
    label: "BAGARD",
    value: "30027",
    type: "common",
  },
  {
    label: "TORNAC",
    value: "30330",
    type: "common",
  },
  {
    label: "THOIRAS",
    value: "30329",
    type: "common",
  },
  {
    label: "SAINT-FELIX-DE-PALLIERES",
    value: "30252",
    type: "common",
  },
  {
    label: "ANDUZE",
    value: "30010",
    type: "common",
  },
  {
    label: "VABRES",
    value: "30335",
    type: "common",
  },
  {
    label: "SAINTE-CROIX-DE-CADERLE",
    value: "30246",
    type: "common",
  },
  {
    label: "SAINT-BONNET-DE-SALENDRINQUE",
    value: "30236",
    type: "common",
  },
  {
    label: "LASALLE",
    value: "30140",
    type: "common",
  },
  {
    label: "SOUDORGUES",
    value: "30322",
    type: "common",
  },
  {
    label: "SAINT-MARTIAL",
    value: "30283",
    type: "common",
  },
  {
    label: "COLOGNAC",
    value: "30087",
    type: "common",
  },
  {
    label: "SAINT-ANDRE-DE-MAJENCOULES",
    value: "30229",
    type: "common",
  },
  {
    label: "MANDAGOUT",
    value: "30154",
    type: "common",
  },
  {
    label: "ARPHY",
    value: "30015",
    type: "common",
  },
  {
    label: "AUMESSAS",
    value: "30025",
    type: "common",
  },
  {
    label: "DOURBIES",
    value: "30105",
    type: "common",
  },
  {
    label: "TREVES",
    value: "30332",
    type: "common",
  },
  {
    label: "CAUSSE-BEGON",
    value: "30074",
    type: "common",
  },
  {
    label: "SAINT-JEAN-DU-BRUEL",
    value: "12231",
    type: "common",
  },
  {
    label: "NANT",
    value: "12168",
    type: "common",
  },
  {
    label: "CREISSELS",
    value: "12084",
    type: "common",
  },
  {
    label: "SAINT-GEORGES-DE-LUZENCON",
    value: "12225",
    type: "common",
  },
  {
    label: "SAINT-ROME-DE-TARN",
    value: "12244",
    type: "common",
  },
  {
    label: "VIALA-DU-TARN",
    value: "12296",
    type: "common",
  },
  {
    label: "SAINT-VICTOR-ET-MELVIEU",
    value: "12251",
    type: "common",
  },
  {
    label: "LE TRUEL",
    value: "12284",
    type: "common",
  },
  {
    label: "LESTRADE-ET-THOUELS",
    value: "12129",
    type: "common",
  },
  {
    label: "REQUISTA",
    value: "12197",
    type: "common",
  },
  {
    label: "SAINT-JEAN-DELNOUS",
    value: "12230",
    type: "common",
  },
  {
    label: "LEDERGUES",
    value: "12127",
    type: "common",
  },
  {
    label: "PADIES",
    value: "81199",
    type: "common",
  },
  {
    label: "LEDAS-ET-PENTHIES",
    value: "81141",
    type: "common",
  },
  {
    label: "FAUSSERGUES",
    value: "81089",
    type: "common",
  },
  {
    label: "MOULARES",
    value: "81186",
    type: "common",
  },
  {
    label: "MONTAURIOL",
    value: "81172",
    type: "common",
  },
  {
    label: "LACAPELLE-PINET",
    value: "81122",
    type: "common",
  },
  {
    label: "CRESPIN",
    value: "81072",
    type: "common",
  },
  {
    label: "SAINT-JEAN-DE-MARCEL",
    value: "81254",
    type: "common",
  },
  {
    label: "SAINT-BENOIT-DE-CARMAUX",
    value: "81244",
    type: "common",
  },
  {
    label: "ROSIERES",
    value: "81230",
    type: "common",
  },
  {
    label: "CARMAUX",
    value: "81060",
    type: "common",
  },
  {
    label: "BLAYE-LES-MINES",
    value: "81033",
    type: "common",
  },
  {
    label: "VIRAC",
    value: "81322",
    type: "common",
  },
  {
    label: "SALLES",
    value: "81275",
    type: "common",
  },
  {
    label: "MONESTIES",
    value: "81170",
    type: "common",
  },
  {
    label: "LABASTIDE-GABAUSSE",
    value: "81114",
    type: "common",
  },
  {
    label: "COMBEFA",
    value: "81068",
    type: "common",
  },
  {
    label: "CORDES-SUR-CIEL",
    value: "81069",
    type: "common",
  },
  {
    label: "SOUEL",
    value: "81290",
    type: "common",
  },
  {
    label: "LIVERS-CAZELLES",
    value: "81146",
    type: "common",
  },
  {
    label: "MILHAVET",
    value: "81166",
    type: "common",
  },
  {
    label: "VINDRAC-ALAYRAC",
    value: "81320",
    type: "common",
  },
  {
    label: "LOUBERS",
    value: "81148",
    type: "common",
  },
  {
    label: "LABARTHE-BLEYS",
    value: "81111",
    type: "common",
  },
  {
    label: "FRAUSSEILLES",
    value: "81095",
    type: "common",
  },
  {
    label: "AMARENS",
    value: "81009",
    type: "common",
  },
  {
    label: "LES CABANNES",
    value: "81045",
    type: "common",
  },
  {
    label: "TONNAC",
    value: "81300",
    type: "common",
  },
  {
    label: "VAOUR",
    value: "81309",
    type: "common",
  },
  {
    label: "SAINT-BEAUZILE",
    value: "81243",
    type: "common",
  },
  {
    label: "ITZAC",
    value: "81108",
    type: "common",
  },
  {
    label: "CAMPAGNAC",
    value: "81056",
    type: "common",
  },
  {
    label: "PENNE",
    value: "81206",
    type: "common",
  },
  {
    label: "BRUNIQUEL",
    value: "82026",
    type: "common",
  },
  {
    label: "VAISSAC",
    value: "82184",
    type: "common",
  },
  {
    label: "NEGREPELISSE",
    value: "82134",
    type: "common",
  },
  {
    label: "SAINT-ETIENNE-DE-TULMONT",
    value: "82161",
    type: "common",
  },
  {
    label: "MONTAUBAN",
    value: "82121",
    type: "common",
  },
  {
    label: "VILLEMADE",
    value: "82195",
    type: "common",
  },
  {
    label: "LA VILLE-DIEU-DU-TEMPLE",
    value: "82096",
    type: "common",
  },
  {
    label: "BARRY-D'ISLEMADE",
    value: "82011",
    type: "common",
  },
  {
    label: "ALBEFEUILLE-LAGARDE",
    value: "82001",
    type: "common",
  },
  {
    label: "CASTELSARRASIN",
    value: "82033",
    type: "common",
  },
  {
    label: "SAINT-AIGNAN",
    value: "82152",
    type: "common",
  },
  {
    label: "CASTELMAYRAN",
    value: "82031",
    type: "common",
  },
  {
    label: "SAINT-NICOLAS-DE-LA-GRAVE",
    value: "82169",
    type: "common",
  },
  {
    label: "LE PIN",
    value: "82139",
    type: "common",
  },
  {
    label: "CAUMONT",
    value: "82035",
    type: "common",
  },
  {
    label: "SAINT-MICHEL",
    value: "82166",
    type: "common",
  },
  {
    label: "MERLES",
    value: "82109",
    type: "common",
  },
  {
    label: "ESPALAIS",
    value: "82054",
    type: "common",
  },
  {
    label: "BARDIGUES",
    value: "82010",
    type: "common",
  },
  {
    label: "SAINT-CIRICE",
    value: "82158",
    type: "common",
  },
  {
    label: "MANSONVILLE",
    value: "82102",
    type: "common",
  },
  {
    label: "AUVILLAR",
    value: "82008",
    type: "common",
  },
  {
    label: "SAINT-ANTOINE",
    value: "32358",
    type: "common",
  },
  {
    label: "SISTELS",
    value: "82181",
    type: "common",
  },
  {
    label: "FLAMARENS",
    value: "32131",
    type: "common",
  },
  {
    label: "ASTAFFORT",
    value: "47015",
    type: "common",
  },
  {
    label: "GIMBREDE",
    value: "32146",
    type: "common",
  },
  {
    label: "SEMPESSERRE",
    value: "32429",
    type: "common",
  },
  {
    label: "SAINT-MEZARD",
    value: "32396",
    type: "common",
  },
  {
    label: "PERGAIN-TAILLAC",
    value: "32311",
    type: "common",
  },
  {
    label: "POUY-ROQUELAURE",
    value: "32328",
    type: "common",
  },
  {
    label: "LIGARDES",
    value: "32212",
    type: "common",
  },
  {
    label: "FRANCESCAS",
    value: "47102",
    type: "common",
  },
  {
    label: "GAZAUPOUY",
    value: "32143",
    type: "common",
  },
  {
    label: "MONCRABEAU",
    value: "47174",
    type: "common",
  },
  {
    label: "MEZIN",
    value: "47167",
    type: "common",
  },
  {
    label: "LANNES",
    value: "47134",
    type: "common",
  },
  {
    label: "SAINTE-MAURE-DE-PEYRIAC",
    value: "47258",
    type: "common",
  },
  {
    label: "POUDENAS",
    value: "47211",
    type: "common",
  },
  {
    label: "SOS",
    value: "47302",
    type: "common",
  },
  {
    label: "ESCALANS",
    value: "40093",
    type: "common",
  },
  {
    label: "HERRE",
    value: "40124",
    type: "common",
  },
  {
    label: "ESTIGARDE",
    value: "40096",
    type: "common",
  },
  {
    label: "SARBAZAN",
    value: "40288",
    type: "common",
  },
  {
    label: "ROQUEFORT",
    value: "40245",
    type: "common",
  },
  {
    label: "MAILLERES",
    value: "40170",
    type: "common",
  },
  {
    label: "CANENX-ET-REAUT",
    value: "40064",
    type: "common",
  },
  {
    label: "CERE",
    value: "40081",
    type: "common",
  },
  {
    label: "BROCAS",
    value: "40056",
    type: "common",
  },
  {
    label: "YGOS-SAINT-SATURNIN",
    value: "40333",
    type: "common",
  },
  {
    label: "ARENGOSSE",
    value: "40006",
    type: "common",
  },
  {
    label: "LEVIGNACQ",
    value: "40154",
    type: "common",
  },
  {
    label: "LIT-ET-MIXE",
    value: "40157",
    type: "common",
  },
  {
    label: "SOSPEL",
    value: "06136",
    type: "common",
  },
  {
    label: "TOUET-DE-L'ESCARENE",
    value: "06142",
    type: "common",
  },
  {
    label: "LUCERAM",
    value: "06077",
    type: "common",
  },
  {
    label: "DURANUS",
    value: "06055",
    type: "common",
  },
  {
    label: "COARAZE",
    value: "06043",
    type: "common",
  },
  {
    label: "UTELLE",
    value: "06151",
    type: "common",
  },
  {
    label: "REVEST-LES-ROCHES",
    value: "06100",
    type: "common",
  },
  {
    label: "BONSON",
    value: "06021",
    type: "common",
  },
  {
    label: "TOURETTE-DU-CHATEAU",
    value: "06145",
    type: "common",
  },
  {
    label: "TOUDON",
    value: "06141",
    type: "common",
  },
  {
    label: "MALAUSSENE",
    value: "06078",
    type: "common",
  },
  {
    label: "PIERREFEU",
    value: "06097",
    type: "common",
  },
  {
    label: "ASCROS",
    value: "06005",
    type: "common",
  },
  {
    label: "SIGALE",
    value: "06135",
    type: "common",
  },
  {
    label: "SAINT-ANTONIN",
    value: "06115",
    type: "common",
  },
  {
    label: "LA PENNE",
    value: "06093",
    type: "common",
  },
  {
    label: "CUEBRIS",
    value: "06052",
    type: "common",
  },
  {
    label: "SAINT-PIERRE",
    value: "04194",
    type: "common",
  },
  {
    label: "SALLAGRIFFON",
    value: "06131",
    type: "common",
  },
  {
    label: "COLLONGUES",
    value: "06045",
    type: "common",
  },
  {
    label: "LA ROCHETTE",
    value: "04170",
    type: "common",
  },
  {
    label: "AMIRAT",
    value: "06002",
    type: "common",
  },
  {
    label: "VAL-DE-CHALVAGNE",
    value: "04043",
    type: "common",
  },
  {
    label: "UBRAYE",
    value: "04224",
    type: "common",
  },
  {
    label: "VERGONS",
    value: "04236",
    type: "common",
  },
  {
    label: "SAINT-JULIEN-DU-VERDON",
    value: "04183",
    type: "common",
  },
  {
    label: "BARREME",
    value: "04022",
    type: "common",
  },
  {
    label: "SENEZ",
    value: "04204",
    type: "common",
  },
  {
    label: "MAJASTRES",
    value: "04107",
    type: "common",
  },
  {
    label: "SAINT-JURS",
    value: "04184",
    type: "common",
  },
  {
    label: "ESTOUBLON",
    value: "04084",
    type: "common",
  },
  {
    label: "BRAS-D'ASSE",
    value: "04031",
    type: "common",
  },
  {
    label: "SAINT-JULIEN-D'ASSE",
    value: "04182",
    type: "common",
  },
  {
    label: "ENTREVENNES",
    value: "04077",
    type: "common",
  },
  {
    label: "LE CASTELLET",
    value: "04041",
    type: "common",
  },
  {
    label: "ORAISON",
    value: "04143",
    type: "common",
  },
  {
    label: "LA BRILLANNE",
    value: "04034",
    type: "common",
  },
  {
    label: "PIERRERUE",
    value: "04151",
    type: "common",
  },
  {
    label: "NIOZELLES",
    value: "04138",
    type: "common",
  },
  {
    label: "MANE",
    value: "04111",
    type: "common",
  },
  {
    label: "FORCALQUIER",
    value: "04088",
    type: "common",
  },
  {
    label: "VACHERES",
    value: "04227",
    type: "common",
  },
  {
    label: "REVEST-DES-BROUSSES",
    value: "04162",
    type: "common",
  },
  {
    label: "AUBENAS-LES-ALPES",
    value: "04012",
    type: "common",
  },
  {
    label: "OPPEDETTE",
    value: "04142",
    type: "common",
  },
  {
    label: "RUSTREL",
    value: "84103",
    type: "common",
  },
  {
    label: "GIGNAC",
    value: "84048",
    type: "common",
  },
  {
    label: "VILLARS",
    value: "84145",
    type: "common",
  },
  {
    label: "SAINT-SATURNIN-LES-APT",
    value: "84118",
    type: "common",
  },
  {
    label: "LIOUX",
    value: "84066",
    type: "common",
  },
  {
    label: "MURS",
    value: "84085",
    type: "common",
  },
  {
    label: "VENASQUE",
    value: "84143",
    type: "common",
  },
  {
    label: "SAUMANE-DE-VAUCLUSE",
    value: "84124",
    type: "common",
  },
  {
    label: "LA ROQUE-SUR-PERNES",
    value: "84101",
    type: "common",
  },
  {
    label: "LE BEAUCET",
    value: "84011",
    type: "common",
  },
  {
    label: "VELLERON",
    value: "84142",
    type: "common",
  },
  {
    label: "VEDENE",
    value: "84141",
    type: "common",
  },
  {
    label: "SAINT-SATURNIN-LES-AVIGNON",
    value: "84119",
    type: "common",
  },
  {
    label: "JONQUERETTES",
    value: "84055",
    type: "common",
  },
  {
    label: "LE PONTET",
    value: "84092",
    type: "common",
  },
  {
    label: "VILLENEUVE-LES-AVIGNON",
    value: "30351",
    type: "common",
  },
  {
    label: "LES ANGLES",
    value: "30011",
    type: "common",
  },
  {
    label: "SAZE",
    value: "30315",
    type: "common",
  },
  {
    label: "ROCHEFORT-DU-GARD",
    value: "30217",
    type: "common",
  },
  {
    label: "SAINT-HILAIRE-D'OZILHAN",
    value: "30260",
    type: "common",
  },
  {
    label: "ESTEZARGUES",
    value: "30107",
    type: "common",
  },
  {
    label: "VERS-PONT-DU-GARD",
    value: "30346",
    type: "common",
  },
  {
    label: "CASTILLON-DU-GARD",
    value: "30073",
    type: "common",
  },
  {
    label: "ARGILLIERS",
    value: "30013",
    type: "common",
  },
  {
    label: "SAINT-MAXIMIN",
    value: "30286",
    type: "common",
  },
  {
    label: "COLLIAS",
    value: "30085",
    type: "common",
  },
  {
    label: "BOURDIC",
    value: "30049",
    type: "common",
  },
  {
    label: "BLAUZAC",
    value: "30041",
    type: "common",
  },
  {
    label: "ARPAILLARGUES-ET-AUREILLAC",
    value: "30014",
    type: "common",
  },
  {
    label: "SAINT-DEZERY",
    value: "30248",
    type: "common",
  },
  {
    label: "SAINT-CHAPTES",
    value: "30241",
    type: "common",
  },
  {
    label: "GARRIGUES-SAINTE-EULALIE",
    value: "30126",
    type: "common",
  },
  {
    label: "CASTELNAU-VALENCE",
    value: "30072",
    type: "common",
  },
  {
    label: "SAUZET",
    value: "30313",
    type: "common",
  },
  {
    label: "MOUSSAC",
    value: "30184",
    type: "common",
  },
  {
    label: "MAURESSARGUES",
    value: "30163",
    type: "common",
  },
  {
    label: "DOMESSARGUES",
    value: "30104",
    type: "common",
  },
  {
    label: "BRIGNON",
    value: "30053",
    type: "common",
  },
  {
    label: "BOUCOIRAN-ET-NOZIERES",
    value: "30046",
    type: "common",
  },
  {
    label: "SAVIGNARGUES",
    value: "30314",
    type: "common",
  },
  {
    label: "SAINT-JEAN-DE-SERRES",
    value: "30267",
    type: "common",
  },
  {
    label: "SAINT-BENEZET",
    value: "30234",
    type: "common",
  },
  {
    label: "MARUEJOLS-LES-GARDON",
    value: "30160",
    type: "common",
  },
  {
    label: "LEDIGNAN",
    value: "30146",
    type: "common",
  },
  {
    label: "AIGREMONT",
    value: "30002",
    type: "common",
  },
  {
    label: "SAINT-NAZAIRE-DES-GARDIES",
    value: "30289",
    type: "common",
  },
  {
    label: "SAINT-JEAN-DE-CRIEULON",
    value: "30265",
    type: "common",
  },
  {
    label: "LEZAN",
    value: "30147",
    type: "common",
  },
  {
    label: "CANAULES-ET-ARGENTIERES",
    value: "30065",
    type: "common",
  },
  {
    label: "FRESSAC",
    value: "30119",
    type: "common",
  },
  {
    label: "DURFORT-ET-SAINT-MARTIN-DE-SOSSENAC",
    value: "30106",
    type: "common",
  },
  {
    label: "MONOBLET",
    value: "30172",
    type: "common",
  },
  {
    label: "CROS",
    value: "30099",
    type: "common",
  },
  {
    label: "SAINT-ROMAN-DE-CODIERES",
    value: "30296",
    type: "common",
  },
  {
    label: "SUMENE",
    value: "30325",
    type: "common",
  },
  {
    label: "SAINT-JULIEN-DE-LA-NEF",
    value: "30272",
    type: "common",
  },
  {
    label: "LE VIGAN",
    value: "30350",
    type: "common",
  },
  {
    label: "ROQUEDUR",
    value: "30220",
    type: "common",
  },
  {
    label: "AVEZE",
    value: "30026",
    type: "common",
  },
  {
    label: "AULAS",
    value: "30024",
    type: "common",
  },
  {
    label: "MOLIERES-CAVAILLAC",
    value: "30170",
    type: "common",
  },
  {
    label: "BEZ-ET-ESPARON",
    value: "30038",
    type: "common",
  },
  {
    label: "ARRIGAS",
    value: "30017",
    type: "common",
  },
  {
    label: "ALZON",
    value: "30009",
    type: "common",
  },
  {
    label: "SAUCLIERES",
    value: "12260",
    type: "common",
  },
  {
    label: "L'HOSPITALET-DU-LARZAC",
    value: "12115",
    type: "common",
  },
  {
    label: "LA CAVALERIE",
    value: "12063",
    type: "common",
  },
  {
    label: "LAPANOUSE-DE-CERNON",
    value: "12122",
    type: "common",
  },
  {
    label: "TOURNEMIRE",
    value: "12282",
    type: "common",
  },
  {
    label: "LA BASTIDE-PRADINES",
    value: "12022",
    type: "common",
  },
  {
    label: "SAINT-ROME-DE-CERNON",
    value: "12243",
    type: "common",
  },
  {
    label: "ROQUEFORT-SUR-SOULZON",
    value: "12203",
    type: "common",
  },
  {
    label: "LES COSTES-GOZON",
    value: "12078",
    type: "common",
  },
  {
    label: "SAINT-IZAIRE",
    value: "12228",
    type: "common",
  },
  {
    label: "BROQUIES",
    value: "12037",
    type: "common",
  },
  {
    label: "MONTCLAR",
    value: "12149",
    type: "common",
  },
  {
    label: "BROUSSE-LE-CHATEAU",
    value: "12038",
    type: "common",
  },
  {
    label: "CONNAC",
    value: "12075",
    type: "common",
  },
  {
    label: "BRASC",
    value: "12035",
    type: "common",
  },
  {
    label: "FRAISSINES",
    value: "81094",
    type: "common",
  },
  {
    label: "LE DOURN",
    value: "81082",
    type: "common",
  },
  {
    label: "CADIX",
    value: "81047",
    type: "common",
  },
  {
    label: "VALENCE-D'ALBIGEOIS",
    value: "81308",
    type: "common",
  },
  {
    label: "SAINT-MICHEL-LABADIE",
    value: "81264",
    type: "common",
  },
  {
    label: "SAINT-CIRGUE",
    value: "81247",
    type: "common",
  },
  {
    label: "ASSAC",
    value: "81019",
    type: "common",
  },
  {
    label: "SAINT-JULIEN-GAULENE",
    value: "81259",
    type: "common",
  },
  {
    label: "ANDOUQUE",
    value: "81013",
    type: "common",
  },
  {
    label: "VALDERIES",
    value: "81306",
    type: "common",
  },
  {
    label: "SAUSSENAC",
    value: "81277",
    type: "common",
  },
  {
    label: "TAIX",
    value: "81291",
    type: "common",
  },
  {
    label: "LE GARRIC",
    value: "81101",
    type: "common",
  },
  {
    label: "CAGNAC-LES-MINES",
    value: "81048",
    type: "common",
  },
  {
    label: "SAINTE-CROIX",
    value: "81326",
    type: "common",
  },
  {
    label: "MAILHOC",
    value: "81152",
    type: "common",
  },
  {
    label: "CASTANET",
    value: "81061",
    type: "common",
  },
  {
    label: "NOAILLES",
    value: "81197",
    type: "common",
  },
  {
    label: "VILLENEUVE-SUR-VERE",
    value: "81319",
    type: "common",
  },
  {
    label: "CESTAYROLS",
    value: "81067",
    type: "common",
  },
  {
    label: "DONNAZAC",
    value: "81080",
    type: "common",
  },
  {
    label: "VIEUX",
    value: "81316",
    type: "common",
  },
  {
    label: "CAHUZAC-SUR-VERE",
    value: "81051",
    type: "common",
  },
  {
    label: "ANDILLAC",
    value: "81012",
    type: "common",
  },
  {
    label: "ALOS",
    value: "81007",
    type: "common",
  },
  {
    label: "LE VERDIER",
    value: "81313",
    type: "common",
  },
  {
    label: "SAINTE-CECILE-DU-CAYROU",
    value: "81246",
    type: "common",
  },
  {
    label: "PUYCELSI",
    value: "81217",
    type: "common",
  },
  {
    label: "PUYGAILLARD-DE-QUERCY",
    value: "82145",
    type: "common",
  },
  {
    label: "LA SAUZIERE-SAINT-JEAN",
    value: "81279",
    type: "common",
  },
  {
    label: "LARROQUE",
    value: "81136",
    type: "common",
  },
  {
    label: "MONCLAR-DE-QUERCY",
    value: "82115",
    type: "common",
  },
  {
    label: "LA SALVETAT-BELMONTET",
    value: "82176",
    type: "common",
  },
  {
    label: "GENEBRIERES",
    value: "82066",
    type: "common",
  },
  {
    label: "LEOJAC",
    value: "82098",
    type: "common",
  },
  {
    label: "MONTBETON",
    value: "82124",
    type: "common",
  },
  {
    label: "LACOURT-SAINT-PIERRE",
    value: "82085",
    type: "common",
  },
  {
    label: "SAINT-PORQUIER",
    value: "82171",
    type: "common",
  },
  {
    label: "ESCATALENS",
    value: "82052",
    type: "common",
  },
  {
    label: "CORDES-TOLOSANNES",
    value: "82045",
    type: "common",
  },
  {
    label: "LAFITTE",
    value: "82086",
    type: "common",
  },
  {
    label: "GARGANVILLAR",
    value: "82063",
    type: "common",
  },
  {
    label: "CASTELFERRUS",
    value: "82030",
    type: "common",
  },
  {
    label: "SAINT-ARROUMEX",
    value: "82156",
    type: "common",
  },
  {
    label: "FAJOLLES",
    value: "82058",
    type: "common",
  },
  {
    label: "COUTURES",
    value: "82046",
    type: "common",
  },
  {
    label: "ANGEVILLE",
    value: "82003",
    type: "common",
  },
  {
    label: "CASTERA-BOUZET",
    value: "82034",
    type: "common",
  },
  {
    label: "ASQUES",
    value: "82004",
    type: "common",
  },
  {
    label: "SAINT-JEAN-DU-BOUZET",
    value: "82163",
    type: "common",
  },
  {
    label: "PUYGAILLARD-DE-LOMAGNE",
    value: "82146",
    type: "common",
  },
  {
    label: "POUPAS",
    value: "82143",
    type: "common",
  },
  {
    label: "LACHAPELLE",
    value: "82083",
    type: "common",
  },
  {
    label: "PEYRECAVE",
    value: "32314",
    type: "common",
  },
  {
    label: "MIRADOUX",
    value: "32253",
    type: "common",
  },
  {
    label: "SAINTE-MERE",
    value: "32395",
    type: "common",
  },
  {
    label: "SAINT-AVIT-FRANDAT",
    value: "32364",
    type: "common",
  },
  {
    label: "CASTET-ARROUY",
    value: "32085",
    type: "common",
  },
  {
    label: "SAINT-MARTIN-DE-GOYNE",
    value: "32391",
    type: "common",
  },
  {
    label: "CASTERA-LECTOUROIS",
    value: "32082",
    type: "common",
  },
  {
    label: "LA ROMIEU",
    value: "32345",
    type: "common",
  },
  {
    label: "LARROQUE-ENGALIN",
    value: "32195",
    type: "common",
  },
  {
    label: "LAGARDE",
    value: "32176",
    type: "common",
  },
  {
    label: "BERRAC",
    value: "32047",
    type: "common",
  },
  {
    label: "CASTELNAU-SUR-L'AUVIGNON",
    value: "32080",
    type: "common",
  },
  {
    label: "CONDOM",
    value: "32107",
    type: "common",
  },
  {
    label: "LARROQUE-SUR-L'OSSE",
    value: "32197",
    type: "common",
  },
  {
    label: "FOURCES",
    value: "32133",
    type: "common",
  },
  {
    label: "SAINT-PE-SAINT-SIMON",
    value: "47266",
    type: "common",
  },
  {
    label: "CASTELNAU D'AUZAN LABARRERE",
    value: "32079",
    type: "common",
  },
  {
    label: "GABARRET",
    value: "40102",
    type: "common",
  },
  {
    label: "LAGRANGE",
    value: "40140",
    type: "common",
  },
  {
    label: "CREON-D'ARMAGNAC",
    value: "40087",
    type: "common",
  },
  {
    label: "SAINT-JULIEN-D'ARMAGNAC",
    value: "40265",
    type: "common",
  },
  {
    label: "MAUVEZIN-D'ARMAGNAC",
    value: "40176",
    type: "common",
  },
  {
    label: "BETBEZER-D'ARMAGNAC",
    value: "40039",
    type: "common",
  },
  {
    label: "SAINT-JUSTIN",
    value: "40267",
    type: "common",
  },
  {
    label: "LABASTIDE-D'ARMAGNAC",
    value: "40131",
    type: "common",
  },
  {
    label: "LACQUY",
    value: "40137",
    type: "common",
  },
  {
    label: "POUYDESSEAUX",
    value: "40234",
    type: "common",
  },
  {
    label: "GAILLERES",
    value: "40103",
    type: "common",
  },
  {
    label: "BOSTENS",
    value: "40050",
    type: "common",
  },
  {
    label: "SAINT-AVIT",
    value: "40250",
    type: "common",
  },
  {
    label: "LUCBARDEZ-ET-BARGUES",
    value: "40162",
    type: "common",
  },
  {
    label: "UCHACQ-ET-PARENTIS",
    value: "40320",
    type: "common",
  },
  {
    label: "GELOUX",
    value: "40111",
    type: "common",
  },
  {
    label: "SAINT-MARTIN-D'ONEY",
    value: "40274",
    type: "common",
  },
  {
    label: "VILLENAVE",
    value: "40330",
    type: "common",
  },
  {
    label: "OUSSE-SUZAN",
    value: "40215",
    type: "common",
  },
  {
    label: "BEYLONGUE",
    value: "40040",
    type: "common",
  },
  {
    label: "RION-DES-LANDES",
    value: "40243",
    type: "common",
  },
  {
    label: "LESPERON",
    value: "40152",
    type: "common",
  },
  {
    label: "LINXE",
    value: "40155",
    type: "common",
  },
  {
    label: "VIELLE-SAINT-GIRONS",
    value: "40326",
    type: "common",
  },
  {
    label: "CASTELLAR",
    value: "06035",
    type: "common",
  },
  {
    label: "SAINTE-AGNES",
    value: "06113",
    type: "common",
  },
  {
    label: "PEILLE",
    value: "06091",
    type: "common",
  },
  {
    label: "CASTILLON",
    value: "06036",
    type: "common",
  },
  {
    label: "BERRE-LES-ALPES",
    value: "06015",
    type: "common",
  },
  {
    label: "L'ESCARENE",
    value: "06057",
    type: "common",
  },
  {
    label: "BLAUSASC",
    value: "06019",
    type: "common",
  },
  {
    label: "CONTES",
    value: "06048",
    type: "common",
  },
  {
    label: "BENDEJUN",
    value: "06014",
    type: "common",
  },
  {
    label: "SAINT-MARTIN-DU-VAR",
    value: "06126",
    type: "common",
  },
  {
    label: "SAINT-BLAISE",
    value: "06117",
    type: "common",
  },
  {
    label: "LA ROQUETTE-SUR-VAR",
    value: "06109",
    type: "common",
  },
  {
    label: "LEVENS",
    value: "06075",
    type: "common",
  },
  {
    label: "GILETTE",
    value: "06066",
    type: "common",
  },
  {
    label: "LES FERRES",
    value: "06061",
    type: "common",
  },
  {
    label: "BOUYON",
    value: "06022",
    type: "common",
  },
  {
    label: "ROQUESTERON",
    value: "06106",
    type: "common",
  },
  {
    label: "CONSEGUDES",
    value: "06047",
    type: "common",
  },
  {
    label: "LA ROQUE-EN-PROVENCE",
    value: "06107",
    type: "common",
  },
  {
    label: "AIGLUN",
    value: "06001",
    type: "common",
  },
  {
    label: "LES MUJOULS",
    value: "06087",
    type: "common",
  },
  {
    label: "LE MAS",
    value: "06081",
    type: "common",
  },
  {
    label: "GARS",
    value: "06063",
    type: "common",
  },
  {
    label: "SAINT-AUBAN",
    value: "06116",
    type: "common",
  },
  {
    label: "BRIANCONNET",
    value: "06024",
    type: "common",
  },
  {
    label: "SOLEILHAS",
    value: "04210",
    type: "common",
  },
  {
    label: "LA GARDE",
    value: "04092",
    type: "common",
  },
  {
    label: "DEMANDOLX",
    value: "04069",
    type: "common",
  },
  {
    label: "CASTELLANE",
    value: "04039",
    type: "common",
  },
  {
    label: "BLIEUX",
    value: "04030",
    type: "common",
  },
  {
    label: "MOUSTIERS-SAINTE-MARIE",
    value: "04135",
    type: "common",
  },
  {
    label: "PUIMOISSON",
    value: "04157",
    type: "common",
  },
  {
    label: "BRUNET",
    value: "04035",
    type: "common",
  },
  {
    label: "VOLX",
    value: "04245",
    type: "common",
  },
  {
    label: "VILLENEUVE",
    value: "04242",
    type: "common",
  },
  {
    label: "SAINT-MAIME",
    value: "04188",
    type: "common",
  },
  {
    label: "VILLEMUS",
    value: "04241",
    type: "common",
  },
  {
    label: "SAINT-MICHEL-L'OBSERVATOIRE",
    value: "04192",
    type: "common",
  },
  {
    label: "SAINT-MARTIN-LES-EAUX",
    value: "04190",
    type: "common",
  },
  {
    label: "DAUPHIN",
    value: "04068",
    type: "common",
  },
  {
    label: "SAINTE-CROIX-A-LAUZE",
    value: "04175",
    type: "common",
  },
  {
    label: "REILLANNE",
    value: "04160",
    type: "common",
  },
  {
    label: "VIENS",
    value: "84144",
    type: "common",
  },
  {
    label: "CASENEUVE",
    value: "84032",
    type: "common",
  },
  {
    label: "APT",
    value: "84003",
    type: "common",
  },
  {
    label: "GARGAS",
    value: "84047",
    type: "common",
  },
  {
    label: "SAINT-PANTALEON",
    value: "84114",
    type: "common",
  },
  {
    label: "ROUSSILLON",
    value: "84102",
    type: "common",
  },
  {
    label: "JOUCAS",
    value: "84057",
    type: "common",
  },
  {
    label: "GOULT",
    value: "84051",
    type: "common",
  },
  {
    label: "GORDES",
    value: "84050",
    type: "common",
  },
  {
    label: "CABRIERES-D'AVIGNON",
    value: "84025",
    type: "common",
  },
  {
    label: "FONTAINE-DE-VAUCLUSE",
    value: "84139",
    type: "common",
  },
  {
    label: "LAGNES",
    value: "84062",
    type: "common",
  },
  {
    label: "L'ISLE-SUR-LA-SORGUE",
    value: "84054",
    type: "common",
  },
  {
    label: "LE THOR",
    value: "84132",
    type: "common",
  },
  {
    label: "MORIERES-LES-AVIGNON",
    value: "84081",
    type: "common",
  },
  {
    label: "CHATEAUNEUF-DE-GADAGNE",
    value: "84036",
    type: "common",
  },
  {
    label: "CAUMONT-SUR-DURANCE",
    value: "84034",
    type: "common",
  },
  {
    label: "AVIGNON",
    value: "84007",
    type: "common",
  },
  {
    label: "CHATEAURENARD",
    value: "13027",
    type: "common",
  },
  {
    label: "ROGNONAS",
    value: "13083",
    type: "common",
  },
  {
    label: "BARBENTANE",
    value: "13010",
    type: "common",
  },
  {
    label: "DOMAZAN",
    value: "30103",
    type: "common",
  },
  {
    label: "ARAMON",
    value: "30012",
    type: "common",
  },
  {
    label: "THEZIERS",
    value: "30328",
    type: "common",
  },
  {
    label: "REMOULINS",
    value: "30212",
    type: "common",
  },
  {
    label: "FOURNES",
    value: "30116",
    type: "common",
  },
  {
    label: "SERNHAC",
    value: "30317",
    type: "common",
  },
  {
    label: "SAINT-BONNET-DU-GARD",
    value: "30235",
    type: "common",
  },
  {
    label: "LEDENON",
    value: "30145",
    type: "common",
  },
  {
    label: "SANILHAC-SAGRIES",
    value: "30308",
    type: "common",
  },
  {
    label: "POULX",
    value: "30206",
    type: "common",
  },
  {
    label: "CABRIERES",
    value: "30057",
    type: "common",
  },
  {
    label: "SAINTE-ANASTASIE",
    value: "30228",
    type: "common",
  },
  {
    label: "LA ROUVIERE",
    value: "30224",
    type: "common",
  },
  {
    label: "DIONS",
    value: "30102",
    type: "common",
  },
  {
    label: "LA CALMETTE",
    value: "30061",
    type: "common",
  },
  {
    label: "MONTAGNAC",
    value: "30354",
    type: "common",
  },
  {
    label: "SAINT-GENIES-DE-MALGOIRES",
    value: "30255",
    type: "common",
  },
  {
    label: "SAINT-BAUZELY",
    value: "30233",
    type: "common",
  },
  {
    label: "MONTIGNARGUES",
    value: "30180",
    type: "common",
  },
  {
    label: "GAJAN",
    value: "30122",
    type: "common",
  },
  {
    label: "FONS",
    value: "30112",
    type: "common",
  },
  {
    label: "SAINT-THEODORIT",
    value: "30300",
    type: "common",
  },
  {
    label: "MOULEZAN",
    value: "30183",
    type: "common",
  },
  {
    label: "MONTMIRAT",
    value: "30181",
    type: "common",
  },
  {
    label: "CANNES-ET-CLAIRAN",
    value: "30066",
    type: "common",
  },
  {
    label: "QUISSAC",
    value: "30210",
    type: "common",
  },
  {
    label: "PUECHREDON",
    value: "30208",
    type: "common",
  },
  {
    label: "LOGRIAN-FLORIAN",
    value: "30150",
    type: "common",
  },
  {
    label: "BRAGASSARGUES",
    value: "30050",
    type: "common",
  },
  {
    label: "SAUVE",
    value: "30311",
    type: "common",
  },
  {
    label: "SAINT-HIPPOLYTE-DU-FORT",
    value: "30263",
    type: "common",
  },
  {
    label: "POMPIGNAN",
    value: "30200",
    type: "common",
  },
  {
    label: "CONQUEYRAC",
    value: "30093",
    type: "common",
  },
  {
    label: "SAINT-BAUZILLE-DE-PUTOIS",
    value: "34243",
    type: "common",
  },
  {
    label: "MOULES-ET-BAUCELS",
    value: "34174",
    type: "common",
  },
  {
    label: "MONTOULIEU",
    value: "34171",
    type: "common",
  },
  {
    label: "LA CADIERE-ET-CAMBO",
    value: "30058",
    type: "common",
  },
  {
    label: "LAROQUE",
    value: "34128",
    type: "common",
  },
  {
    label: "GANGES",
    value: "34111",
    type: "common",
  },
  {
    label: "CAZILHAC",
    value: "34067",
    type: "common",
  },
  {
    label: "AGONES",
    value: "34005",
    type: "common",
  },
  {
    label: "SAINT-LAURENT-LE-MINIER",
    value: "30280",
    type: "common",
  },
  {
    label: "SAINT-BRESSON",
    value: "30238",
    type: "common",
  },
  {
    label: "POMMIERS",
    value: "30199",
    type: "common",
  },
  {
    label: "MONTDARDIER",
    value: "30176",
    type: "common",
  },
  {
    label: "BLANDAS",
    value: "30040",
    type: "common",
  },
  {
    label: "ARRE",
    value: "30016",
    type: "common",
  },
  {
    label: "CAMPESTRE-ET-LUC",
    value: "30064",
    type: "common",
  },
  {
    label: "LA COUVERTOIRADE",
    value: "12082",
    type: "common",
  },
  {
    label: "SAINTE-EULALIE-DE-CERNON",
    value: "12220",
    type: "common",
  },
  {
    label: "SAINT-BEAULIZE",
    value: "12212",
    type: "common",
  },
  {
    label: "VIALA-DU-PAS-DE-JAUX",
    value: "12295",
    type: "common",
  },
  {
    label: "SAINT-JEAN-ET-SAINT-PAUL",
    value: "12232",
    type: "common",
  },
  {
    label: "SAINT-JEAN-D'ALCAPIES",
    value: "12229",
    type: "common",
  },
  {
    label: "SAINT-AFFRIQUE",
    value: "12208",
    type: "common",
  },
  {
    label: "VABRES-L'ABBAYE",
    value: "12286",
    type: "common",
  },
  {
    label: "SAINT-JUERY",
    value: "12233",
    type: "common",
  },
  {
    label: "CALMELS-ET-LE-VIALA",
    value: "12042",
    type: "common",
  },
  {
    label: "MARTRIN",
    value: "12141",
    type: "common",
  },
  {
    label: "PLAISANCE",
    value: "12183",
    type: "common",
  },
  {
    label: "COUPIAC",
    value: "12080",
    type: "common",
  },
  {
    label: "TREBAS",
    value: "81303",
    type: "common",
  },
  {
    label: "SAINT-ANDRE",
    value: "81240",
    type: "common",
  },
  {
    label: "CURVALLE",
    value: "81077",
    type: "common",
  },
  {
    label: "LA BASTIDE-SOLAGES",
    value: "12023",
    type: "common",
  },
  {
    label: "COURRIS",
    value: "81071",
    type: "common",
  },
  {
    label: "AMBIALET",
    value: "81010",
    type: "common",
  },
  {
    label: "SERENAC",
    value: "81285",
    type: "common",
  },
  {
    label: "CRESPINET",
    value: "81073",
    type: "common",
  },
  {
    label: "SAINT-JUERY",
    value: "81257",
    type: "common",
  },
  {
    label: "SAINT-GREGOIRE",
    value: "81253",
    type: "common",
  },
  {
    label: "CUNAC",
    value: "81074",
    type: "common",
  },
  {
    label: "CAMBON",
    value: "81052",
    type: "common",
  },
  {
    label: "ARTHES",
    value: "81018",
    type: "common",
  },
  {
    label: "LESCURE-D'ALBIGEOIS",
    value: "81144",
    type: "common",
  },
  {
    label: "ALBI",
    value: "81004",
    type: "common",
  },
  {
    label: "MARSSAC-SUR-TARN",
    value: "81156",
    type: "common",
  },
  {
    label: "TERSSAC",
    value: "81297",
    type: "common",
  },
  {
    label: "LE SEQUESTRE",
    value: "81284",
    type: "common",
  },
  {
    label: "CASTELNAU-DE-LEVIS",
    value: "81063",
    type: "common",
  },
  {
    label: "RIVIERES",
    value: "81225",
    type: "common",
  },
  {
    label: "LABASTIDE-DE-LEVIS",
    value: "81112",
    type: "common",
  },
  {
    label: "FAYSSAC",
    value: "81087",
    type: "common",
  },
  {
    label: "BERNAC",
    value: "81029",
    type: "common",
  },
  {
    label: "SENOUILLAC",
    value: "81283",
    type: "common",
  },
  {
    label: "MONTELS",
    value: "81176",
    type: "common",
  },
  {
    label: "GAILLAC",
    value: "81099",
    type: "common",
  },
  {
    label: "BROZE",
    value: "81041",
    type: "common",
  },
  {
    label: "CASTELNAU-DE-MONTMIRAL",
    value: "81064",
    type: "common",
  },
  {
    label: "SALVAGNAC",
    value: "81276",
    type: "common",
  },
  {
    label: "SAINT-URCISSE",
    value: "81272",
    type: "common",
  },
  {
    label: "MONTGAILLARD",
    value: "81178",
    type: "common",
  },
  {
    label: "MONTDURAUSSE",
    value: "81175",
    type: "common",
  },
  {
    label: "VERLHAC-TESCOU",
    value: "82192",
    type: "common",
  },
  {
    label: "VARENNES",
    value: "82188",
    type: "common",
  },
  {
    label: "VILLEBRUMIER",
    value: "82194",
    type: "common",
  },
  {
    label: "SAINT-NAUPHARY",
    value: "82167",
    type: "common",
  },
  {
    label: "REYNIES",
    value: "82150",
    type: "common",
  },
  {
    label: "CORBARIEU",
    value: "82044",
    type: "common",
  },
  {
    label: "LABASTIDE-SAINT-PIERRE",
    value: "82079",
    type: "common",
  },
  {
    label: "BRESSOLS",
    value: "82025",
    type: "common",
  },
  {
    label: "MONTECH",
    value: "82125",
    type: "common",
  },
  {
    label: "MONTBARTIER",
    value: "82123",
    type: "common",
  },
  {
    label: "FINHAN",
    value: "82062",
    type: "common",
  },
  {
    label: "BOURRET",
    value: "82023",
    type: "common",
  },
  {
    label: "SAINT-SARDOS",
    value: "82173",
    type: "common",
  },
  {
    label: "MONTAIN",
    value: "82118",
    type: "common",
  },
  {
    label: "LARRAZET",
    value: "82093",
    type: "common",
  },
  {
    label: "LABOURGADE",
    value: "82081",
    type: "common",
  },
  {
    label: "BELBEZE-EN-LOMAGNE",
    value: "82015",
    type: "common",
  },
  {
    label: "SERIGNAC",
    value: "82180",
    type: "common",
  },
  {
    label: "MAUMUSSON",
    value: "82107",
    type: "common",
  },
  {
    label: "LAVIT",
    value: "82097",
    type: "common",
  },
  {
    label: "GENSAC",
    value: "82067",
    type: "common",
  },
  {
    label: "ESPARSAC",
    value: "82055",
    type: "common",
  },
  {
    label: "MONTGAILLARD",
    value: "82129",
    type: "common",
  },
  {
    label: "MARSAC",
    value: "82104",
    type: "common",
  },
  {
    label: "BALIGNAC",
    value: "82009",
    type: "common",
  },
  {
    label: "MAUROUX",
    value: "32248",
    type: "common",
  },
  {
    label: "CASTERON",
    value: "32084",
    type: "common",
  },
  {
    label: "GRAMONT",
    value: "82074",
    type: "common",
  },
  {
    label: "SAINT-CREAC",
    value: "32371",
    type: "common",
  },
  {
    label: "SAINT-CLAR",
    value: "32370",
    type: "common",
  },
  {
    label: "PLIEUX",
    value: "32320",
    type: "common",
  },
  {
    label: "L'ISLE-BOUZON",
    value: "32158",
    type: "common",
  },
  {
    label: "LECTOURE",
    value: "32208",
    type: "common",
  },
  {
    label: "TERRAUBE",
    value: "32442",
    type: "common",
  },
  {
    label: "MAS-D'AUVIGNON",
    value: "32241",
    type: "common",
  },
  {
    label: "MARSOLAN",
    value: "32239",
    type: "common",
  },
  {
    label: "BLAZIERT",
    value: "32057",
    type: "common",
  },
  {
    label: "SAINT-ORENS-POUY-PETIT",
    value: "32400",
    type: "common",
  },
  {
    label: "ROQUEPINE",
    value: "32350",
    type: "common",
  },
  {
    label: "CAUSSENS",
    value: "32095",
    type: "common",
  },
  {
    label: "BERAUT",
    value: "32044",
    type: "common",
  },
  {
    label: "CASSAIGNE",
    value: "32075",
    type: "common",
  },
  {
    label: "MOUCHAN",
    value: "32292",
    type: "common",
  },
  {
    label: "LAURAET",
    value: "32203",
    type: "common",
  },
  {
    label: "LARRESSINGLE",
    value: "32194",
    type: "common",
  },
  {
    label: "BEAUMONT",
    value: "32037",
    type: "common",
  },
  {
    label: "MONTREAL",
    value: "32290",
    type: "common",
  },
  {
    label: "LAGRAULET-DU-GERS",
    value: "32180",
    type: "common",
  },
  {
    label: "CAZENEUVE",
    value: "32100",
    type: "common",
  },
  {
    label: "BRETAGNE-D'ARMAGNAC",
    value: "32064",
    type: "common",
  },
  {
    label: "PARLEBOSCQ",
    value: "40218",
    type: "common",
  },
  {
    label: "LAREE",
    value: "32193",
    type: "common",
  },
  {
    label: "CAZAUBON",
    value: "32096",
    type: "common",
  },
  {
    label: "MONCLAR",
    value: "32264",
    type: "common",
  },
  {
    label: "MAULEON-D'ARMAGNAC",
    value: "32243",
    type: "common",
  },
  {
    label: "LE FRECHE",
    value: "40100",
    type: "common",
  },
  {
    label: "ARTHEZ-D'ARMAGNAC",
    value: "40013",
    type: "common",
  },
  {
    label: "LANNEMAIGNAN",
    value: "32189",
    type: "common",
  },
  {
    label: "VILLENEUVE-DE-MARSAN",
    value: "40331",
    type: "common",
  },
  {
    label: "SAINTE-FOY",
    value: "40258",
    type: "common",
  },
  {
    label: "SAINT-CRICQ-VILLENEUVE",
    value: "40255",
    type: "common",
  },
  {
    label: "BOUGUE",
    value: "40051",
    type: "common",
  },
  {
    label: "MAZEROLLES",
    value: "40178",
    type: "common",
  },
  {
    label: "SAINT-PIERRE-DU-MONT",
    value: "40281",
    type: "common",
  },
  {
    label: "MONT-DE-MARSAN",
    value: "40192",
    type: "common",
  },
  {
    label: "SAINT-PERDON",
    value: "40280",
    type: "common",
  },
  {
    label: "CAMPET-ET-LAMOLERE",
    value: "40062",
    type: "common",
  },
  {
    label: "CAMPAGNE",
    value: "40061",
    type: "common",
  },
  {
    label: "SAINT-YAGUEN",
    value: "40285",
    type: "common",
  },
  {
    label: "CARCEN-PONSON",
    value: "40067",
    type: "common",
  },
  {
    label: "LESGOR",
    value: "40151",
    type: "common",
  },
  {
    label: "TALLER",
    value: "40311",
    type: "common",
  },
  {
    label: "CASTETS",
    value: "40075",
    type: "common",
  },
  {
    label: "SAINT-MICHEL-ESCALUS",
    value: "40276",
    type: "common",
  },
  {
    label: "LEON",
    value: "40150",
    type: "common",
  },
  {
    label: "MOLIETS-ET-MAA",
    value: "40187",
    type: "common",
  },
  {
    label: "MENTON",
    value: "06083",
    type: "common",
  },
  {
    label: "ROQUEBRUNE-CAP-MARTIN",
    value: "06104",
    type: "common",
  },
  {
    label: "GORBIO",
    value: "06067",
    type: "common",
  },
  {
    label: "LA TURBIE",
    value: "06150",
    type: "common",
  },
  {
    label: "BEAUSOLEIL",
    value: "06012",
    type: "common",
  },
  {
    label: "LA TRINITE",
    value: "06149",
    type: "common",
  },
  {
    label: "PEILLON",
    value: "06092",
    type: "common",
  },
  {
    label: "DRAP",
    value: "06054",
    type: "common",
  },
  {
    label: "SAINT-ANDRE-DE-LA-ROCHE",
    value: "06114",
    type: "common",
  },
  {
    label: "CHATEAUNEUF-VILLEVIEILLE",
    value: "06039",
    type: "common",
  },
  {
    label: "TOURRETTE-LEVENS",
    value: "06147",
    type: "common",
  },
  {
    label: "FALICON",
    value: "06060",
    type: "common",
  },
  {
    label: "CANTARON",
    value: "06031",
    type: "common",
  },
  {
    label: "ASPREMONT",
    value: "06006",
    type: "common",
  },
  {
    label: "GATTIERES",
    value: "06064",
    type: "common",
  },
  {
    label: "COLOMARS",
    value: "06046",
    type: "common",
  },
  {
    label: "CASTAGNIERS",
    value: "06034",
    type: "common",
  },
  {
    label: "CARROS",
    value: "06033",
    type: "common",
  },
  {
    label: "SAINT-JEANNET",
    value: "06122",
    type: "common",
  },
  {
    label: "LE BROC",
    value: "06025",
    type: "common",
  },
  {
    label: "BEZAUDUN-LES-ALPES",
    value: "06017",
    type: "common",
  },
  {
    label: "COURSEGOULES",
    value: "06050",
    type: "common",
  },
  {
    label: "COURMES",
    value: "06049",
    type: "common",
  },
  {
    label: "GREOLIERES",
    value: "06070",
    type: "common",
  },
  {
    label: "CIPIERES",
    value: "06041",
    type: "common",
  },
  {
    label: "CAILLE",
    value: "06028",
    type: "common",
  },
  {
    label: "ANDON",
    value: "06003",
    type: "common",
  },
  {
    label: "VALDEROURE",
    value: "06154",
    type: "common",
  },
  {
    label: "SERANON",
    value: "06134",
    type: "common",
  },
  {
    label: "CHATEAUVIEUX",
    value: "83040",
    type: "common",
  },
  {
    label: "PEYROULES",
    value: "04148",
    type: "common",
  },
  {
    label: "LE BOURGUET",
    value: "83020",
    type: "common",
  },
  {
    label: "ROUGON",
    value: "04171",
    type: "common",
  },
  {
    label: "LA PALUD-SUR-VERDON",
    value: "04144",
    type: "common",
  },
  {
    label: "ROUMOULES",
    value: "04172",
    type: "common",
  },
  {
    label: "RIEZ",
    value: "04166",
    type: "common",
  },
  {
    label: "ALLEMAGNE-EN-PROVENCE",
    value: "04004",
    type: "common",
  },
  {
    label: "VALENSOLE",
    value: "04230",
    type: "common",
  },
  {
    label: "MANOSQUE",
    value: "04112",
    type: "common",
  },
  {
    label: "PIERREVERT",
    value: "04152",
    type: "common",
  },
  {
    label: "MONTJUSTIN",
    value: "04129",
    type: "common",
  },
  {
    label: "MONTFURON",
    value: "04128",
    type: "common",
  },
  {
    label: "VITROLLES-EN-LUBERON",
    value: "84151",
    type: "common",
  },
  {
    label: "CERESTE",
    value: "04045",
    type: "common",
  },
  {
    label: "SAINT-MARTIN-DE-CASTILLON",
    value: "84112",
    type: "common",
  },
  {
    label: "CASTELLET-EN-LUBERON",
    value: "84033",
    type: "common",
  },
  {
    label: "SIVERGUES",
    value: "84128",
    type: "common",
  },
  {
    label: "SAIGNON",
    value: "84105",
    type: "common",
  },
  {
    label: "BUOUX",
    value: "84023",
    type: "common",
  },
  {
    label: "AURIBEAU",
    value: "84006",
    type: "common",
  },
  {
    label: "BONNIEUX",
    value: "84020",
    type: "common",
  },
  {
    label: "MENERBES",
    value: "84073",
    type: "common",
  },
  {
    label: "LACOSTE",
    value: "84058",
    type: "common",
  },
  {
    label: "OPPEDE",
    value: "84086",
    type: "common",
  },
  {
    label: "MAUBEC",
    value: "84071",
    type: "common",
  },
  {
    label: "BEAUMETTES",
    value: "84013",
    type: "common",
  },
  {
    label: "TAILLADES",
    value: "84131",
    type: "common",
  },
  {
    label: "ROBION",
    value: "84099",
    type: "common",
  },
  {
    label: "CAVAILLON",
    value: "84035",
    type: "common",
  },
  {
    label: "PLAN-D'ORGON",
    value: "13076",
    type: "common",
  },
  {
    label: "VERQUIERES",
    value: "13116",
    type: "common",
  },
  {
    label: "SAINT-ANDIOL",
    value: "13089",
    type: "common",
  },
  {
    label: "NOVES",
    value: "13066",
    type: "common",
  },
  {
    label: "CABANNES",
    value: "13018",
    type: "common",
  },
  {
    label: "EYRAGUES",
    value: "13036",
    type: "common",
  },
  {
    label: "MAILLANE",
    value: "13052",
    type: "common",
  },
  {
    label: "GRAVESON",
    value: "13045",
    type: "common",
  },
  {
    label: "SAINT-PIERRE-DE-MEZOARGUES",
    value: "13061",
    type: "common",
  },
  {
    label: "BOULBON",
    value: "13017",
    type: "common",
  },
  {
    label: "VALLABREGUES",
    value: "30336",
    type: "common",
  },
  {
    label: "MONTFRIN",
    value: "30179",
    type: "common",
  },
  {
    label: "MEYNES",
    value: "30166",
    type: "common",
  },
  {
    label: "JONQUIERES-SAINT-VINCENT",
    value: "30135",
    type: "common",
  },
  {
    label: "COMPS",
    value: "30089",
    type: "common",
  },
  {
    label: "REDESSAN",
    value: "30211",
    type: "common",
  },
  {
    label: "BEZOUCE",
    value: "30039",
    type: "common",
  },
  {
    label: "SAINT-GERVASY",
    value: "30257",
    type: "common",
  },
  {
    label: "MARGUERITTES",
    value: "30156",
    type: "common",
  },
  {
    label: "NIMES",
    value: "30189",
    type: "common",
  },
  {
    label: "CAVEIRAC",
    value: "30075",
    type: "common",
  },
  {
    label: "SAINT-MAMERT-DU-GARD",
    value: "30281",
    type: "common",
  },
  {
    label: "SAINT-COME-ET-MARUEJOLS",
    value: "30245",
    type: "common",
  },
  {
    label: "PARIGNARGUES",
    value: "30193",
    type: "common",
  },
  {
    label: "MONTPEZAT",
    value: "30182",
    type: "common",
  },
  {
    label: "CLARENSAC",
    value: "30082",
    type: "common",
  },
  {
    label: "VIC-LE-FESQ",
    value: "30349",
    type: "common",
  },
  {
    label: "CRESPIAN",
    value: "30098",
    type: "common",
  },
  {
    label: "COMBAS",
    value: "30088",
    type: "common",
  },
  {
    label: "SARDAN",
    value: "30309",
    type: "common",
  },
  {
    label: "ORTHOUX-SERIGNAC-QUILHAN",
    value: "30192",
    type: "common",
  },
  {
    label: "LECQUES",
    value: "30144",
    type: "common",
  },
  {
    label: "GAILHAN",
    value: "30121",
    type: "common",
  },
  {
    label: "LIOUC",
    value: "30148",
    type: "common",
  },
  {
    label: "CORCONNE",
    value: "30095",
    type: "common",
  },
  {
    label: "BROUZET-LES-QUISSAC",
    value: "30054",
    type: "common",
  },
  {
    label: "CLARET",
    value: "34078",
    type: "common",
  },
  {
    label: "FERRIERES-LES-VERRERIES",
    value: "34099",
    type: "common",
  },
  {
    label: "BRISSAC",
    value: "34042",
    type: "common",
  },
  {
    label: "SAINT-ANDRE-DE-BUEGES",
    value: "34238",
    type: "common",
  },
  {
    label: "GORNIES",
    value: "34115",
    type: "common",
  },
  {
    label: "SAINT-MAURICE-NAVACELLES",
    value: "34277",
    type: "common",
  },
  {
    label: "ROGUES",
    value: "30219",
    type: "common",
  },
  {
    label: "VISSEC",
    value: "30353",
    type: "common",
  },
  {
    label: "SORBS",
    value: "34303",
    type: "common",
  },
  {
    label: "SAINT-MICHEL",
    value: "34278",
    type: "common",
  },
  {
    label: "LE CROS",
    value: "34091",
    type: "common",
  },
  {
    label: "LES RIVES",
    value: "34230",
    type: "common",
  },
  {
    label: "LE CAYLAR",
    value: "34064",
    type: "common",
  },
  {
    label: "CORNUS",
    value: "12077",
    type: "common",
  },
  {
    label: "FONDAMENTE",
    value: "12155",
    type: "common",
  },
  {
    label: "MARNHAGUES-ET-LATOUR",
    value: "12139",
    type: "common",
  },
  {
    label: "SAINT-FELIX-DE-SORGUES",
    value: "12222",
    type: "common",
  },
  {
    label: "VERSOLS-ET-LAPEYRE",
    value: "12292",
    type: "common",
  },
  {
    label: "GISSAC",
    value: "12109",
    type: "common",
  },
  {
    label: "MONTLAUR",
    value: "12154",
    type: "common",
  },
  {
    label: "REBOURGUIL",
    value: "12195",
    type: "common",
  },
  {
    label: "LA SERRE",
    value: "12269",
    type: "common",
  },
  {
    label: "SAINT-SERNIN-SUR-RANCE",
    value: "12248",
    type: "common",
  },
  {
    label: "MIOLLES",
    value: "81167",
    type: "common",
  },
  {
    label: "POUSTHOMY",
    value: "12186",
    type: "common",
  },
  {
    label: "BALAGUIER-SUR-RANCE",
    value: "12019",
    type: "common",
  },
  {
    label: "MASSALS",
    value: "81161",
    type: "common",
  },
  {
    label: "ALBAN",
    value: "81003",
    type: "common",
  },
  {
    label: "LE FRAYSSE",
    value: "81096",
    type: "common",
  },
  {
    label: "VILLEFRANCHE-D'ALBIGEOIS",
    value: "81317",
    type: "common",
  },
  {
    label: "FREJAIROLLES",
    value: "81097",
    type: "common",
  },
  {
    label: "MOUZIEYS-TEULET",
    value: "81190",
    type: "common",
  },
  {
    label: "BELLEGARDE-MARSAL",
    value: "81026",
    type: "common",
  },
  {
    label: "SALIES",
    value: "81274",
    type: "common",
  },
  {
    label: "PUYGOUZON",
    value: "81218",
    type: "common",
  },
  {
    label: "LAMILLARIE",
    value: "81133",
    type: "common",
  },
  {
    label: "ROUFFIAC",
    value: "81232",
    type: "common",
  },
  {
    label: "POULAN-POUZOLS",
    value: "81211",
    type: "common",
  },
  {
    label: "FLORENTIN",
    value: "81093",
    type: "common",
  },
  {
    label: "FENOLS",
    value: "81090",
    type: "common",
  },
  {
    label: "CARLUS",
    value: "81059",
    type: "common",
  },
  {
    label: "AUSSAC",
    value: "81020",
    type: "common",
  },
  {
    label: "LAGRAVE",
    value: "81131",
    type: "common",
  },
  {
    label: "BRENS",
    value: "81038",
    type: "common",
  },
  {
    label: "MONTANS",
    value: "81171",
    type: "common",
  },
  {
    label: "LISLE-SUR-TARN",
    value: "81145",
    type: "common",
  },
  {
    label: "GRAZAC",
    value: "81106",
    type: "common",
  },
  {
    label: "TAURIAC",
    value: "81293",
    type: "common",
  },
  {
    label: "MONTVALEN",
    value: "81185",
    type: "common",
  },
  {
    label: "BEAUVAIS-SUR-TESCOU",
    value: "81024",
    type: "common",
  },
  {
    label: "BONDIGOUX",
    value: "31073",
    type: "common",
  },
  {
    label: "VILLEMUR-SUR-TARN",
    value: "31584",
    type: "common",
  },
  {
    label: "LE BORN",
    value: "31077",
    type: "common",
  },
  {
    label: "ORGUEIL",
    value: "82136",
    type: "common",
  },
  {
    label: "NOHIC",
    value: "82135",
    type: "common",
  },
  {
    label: "FABAS",
    value: "82057",
    type: "common",
  },
  {
    label: "CANALS",
    value: "82028",
    type: "common",
  },
  {
    label: "CAMPSAS",
    value: "82027",
    type: "common",
  },
  {
    label: "VERDUN-SUR-GARONNE",
    value: "82190",
    type: "common",
  },
  {
    label: "MONBEQUI",
    value: "82114",
    type: "common",
  },
  {
    label: "DIEUPENTALE",
    value: "82048",
    type: "common",
  },
  {
    label: "BESSENS",
    value: "82017",
    type: "common",
  },
  {
    label: "MAS-GRENIER",
    value: "82105",
    type: "common",
  },
  {
    label: "COMBEROUGER",
    value: "82043",
    type: "common",
  },
  {
    label: "BOUILLAC",
    value: "82020",
    type: "common",
  },
  {
    label: "VIGUERON",
    value: "82193",
    type: "common",
  },
  {
    label: "BEAUMONT-DE-LOMAGNE",
    value: "82013",
    type: "common",
  },
  {
    label: "MARIGNAC",
    value: "82103",
    type: "common",
  },
  {
    label: "LAMOTHE-CUMONT",
    value: "82091",
    type: "common",
  },
  {
    label: "GLATENS",
    value: "82070",
    type: "common",
  },
  {
    label: "GIMAT",
    value: "82068",
    type: "common",
  },
  {
    label: "CUMONT",
    value: "82047",
    type: "common",
  },
  {
    label: "AUTERIVE",
    value: "82006",
    type: "common",
  },
  {
    label: "TOURNECOUPE",
    value: "32452",
    type: "common",
  },
  {
    label: "PESSOULENS",
    value: "32313",
    type: "common",
  },
  {
    label: "GAUDONVILLE",
    value: "32139",
    type: "common",
  },
  {
    label: "SAINT-LEONARD",
    value: "32385",
    type: "common",
  },
  {
    label: "AVEZAN",
    value: "32023",
    type: "common",
  },
  {
    label: "URDENS",
    value: "32457",
    type: "common",
  },
  {
    label: "MAGNAS",
    value: "32223",
    type: "common",
  },
  {
    label: "CASTELNAU-D'ARBIEU",
    value: "32078",
    type: "common",
  },
  {
    label: "BRUGNENS",
    value: "32066",
    type: "common",
  },
  {
    label: "SAINTE-RADEGONDE",
    value: "32405",
    type: "common",
  },
  {
    label: "PAUILHAC",
    value: "32306",
    type: "common",
  },
  {
    label: "LAMOTHE-GOAS",
    value: "32188",
    type: "common",
  },
  {
    label: "LA SAUVETAT",
    value: "32417",
    type: "common",
  },
  {
    label: "SAINT-PUY",
    value: "32404",
    type: "common",
  },
  {
    label: "VALENCE-SUR-BAISE",
    value: "32459",
    type: "common",
  },
  {
    label: "MANSENCOME",
    value: "32230",
    type: "common",
  },
  {
    label: "MAIGNAUT-TAUZIA",
    value: "32224",
    type: "common",
  },
  {
    label: "BEAUCAIRE",
    value: "32035",
    type: "common",
  },
  {
    label: "ROQUES",
    value: "32351",
    type: "common",
  },
  {
    label: "LAGARDERE",
    value: "32178",
    type: "common",
  },
  {
    label: "GONDRIN",
    value: "32149",
    type: "common",
  },
  {
    label: "COURRENSAN",
    value: "32110",
    type: "common",
  },
  {
    label: "EAUZE",
    value: "32119",
    type: "common",
  },
  {
    label: "REANS",
    value: "32340",
    type: "common",
  },
  {
    label: "CAMPAGNE-D'ARMAGNAC",
    value: "32073",
    type: "common",
  },
  {
    label: "PANJAS",
    value: "32305",
    type: "common",
  },
  {
    label: "MARGUESTAU",
    value: "32236",
    type: "common",
  },
  {
    label: "LIAS-D'ARMAGNAC",
    value: "32211",
    type: "common",
  },
  {
    label: "BOURROUILLAN",
    value: "32062",
    type: "common",
  },
  {
    label: "AYZIEU",
    value: "32025",
    type: "common",
  },
  {
    label: "MAUPAS",
    value: "32246",
    type: "common",
  },
  {
    label: "ESTANG",
    value: "32127",
    type: "common",
  },
  {
    label: "CASTEX-D'ARMAGNAC",
    value: "32087",
    type: "common",
  },
  {
    label: "MONTEGUT",
    value: "40193",
    type: "common",
  },
  {
    label: "BOURDALAT",
    value: "40052",
    type: "common",
  },
  {
    label: "TOUJOUSE",
    value: "32449",
    type: "common",
  },
  {
    label: "MONGUILHEM",
    value: "32271",
    type: "common",
  },
  {
    label: "SAINT-GEIN",
    value: "40259",
    type: "common",
  },
  {
    label: "PERQUIE",
    value: "40221",
    type: "common",
  },
  {
    label: "HONTANX",
    value: "40127",
    type: "common",
  },
  {
    label: "PUJO-LE-PLAN",
    value: "40238",
    type: "common",
  },
  {
    label: "MAURRIN",
    value: "40175",
    type: "common",
  },
  {
    label: "LAGLORIEUSE",
    value: "40139",
    type: "common",
  },
  {
    label: "ARTASSENX",
    value: "40012",
    type: "common",
  },
  {
    label: "BRETAGNE-DE-MARSAN",
    value: "40055",
    type: "common",
  },
  {
    label: "BASCONS",
    value: "40025",
    type: "common",
  },
  {
    label: "HAUT-MAUCO",
    value: "40122",
    type: "common",
  },
  {
    label: "BENQUET",
    value: "40037",
    type: "common",
  },
  {
    label: "MEILHAN",
    value: "40180",
    type: "common",
  },
  {
    label: "LE LEUY",
    value: "40153",
    type: "common",
  },
  {
    label: "TARTAS",
    value: "40313",
    type: "common",
  },
  {
    label: "CARCARES-SAINTE-CROIX",
    value: "40066",
    type: "common",
  },
  {
    label: "BEGAAR",
    value: "40031",
    type: "common",
  },
  {
    label: "LALUQUE",
    value: "40142",
    type: "common",
  },
  {
    label: "GOURBERA",
    value: "40114",
    type: "common",
  },
  {
    label: "HERM",
    value: "40123",
    type: "common",
  },
  {
    label: "AZUR",
    value: "40021",
    type: "common",
  },
  {
    label: "MESSANGES",
    value: "40181",
    type: "common",
  },
  {
    label: "CAP-D'AIL",
    value: "06032",
    type: "common",
  },
  {
    label: "VILLEFRANCHE-SUR-MER",
    value: "06159",
    type: "common",
  },
  {
    label: "SAINT-JEAN-CAP-FERRAT",
    value: "06121",
    type: "common",
  },
  {
    label: "EZE",
    value: "06059",
    type: "common",
  },
  {
    label: "BEAULIEU-SUR-MER",
    value: "06011",
    type: "common",
  },
  {
    label: "NICE",
    value: "06088",
    type: "common",
  },
  {
    label: "SAINT-LAURENT-DU-VAR",
    value: "06123",
    type: "common",
  },
  {
    label: "LA GAUDE",
    value: "06065",
    type: "common",
  },
  {
    label: "VENCE",
    value: "06157",
    type: "common",
  },
  {
    label: "SAINT-PAUL-DE-VENCE",
    value: "06128",
    type: "common",
  },
  {
    label: "LA COLLE-SUR-LOUP",
    value: "06044",
    type: "common",
  },
  {
    label: "TOURRETTES-SUR-LOUP",
    value: "06148",
    type: "common",
  },
  {
    label: "GOURDON",
    value: "06068",
    type: "common",
  },
  {
    label: "SAINT-VALLIER-DE-THIEY",
    value: "06130",
    type: "common",
  },
  {
    label: "CAUSSOLS",
    value: "06037",
    type: "common",
  },
  {
    label: "ESCRAGNOLLES",
    value: "06058",
    type: "common",
  },
  {
    label: "MONS",
    value: "83080",
    type: "common",
  },
  {
    label: "LA ROQUE-ESCLAPON",
    value: "83109",
    type: "common",
  },
  {
    label: "LA MARTRE",
    value: "83074",
    type: "common",
  },
  {
    label: "LA BASTIDE",
    value: "83013",
    type: "common",
  },
  {
    label: "BRENON",
    value: "83022",
    type: "common",
  },
  {
    label: "BARGEME",
    value: "83010",
    type: "common",
  },
  {
    label: "TRIGANCE",
    value: "83142",
    type: "common",
  },
  {
    label: "LES SALLES-SUR-VERDON",
    value: "83122",
    type: "common",
  },
  {
    label: "BAUDUEN",
    value: "83015",
    type: "common",
  },
  {
    label: "SAINTE-CROIX-DU-VERDON",
    value: "04176",
    type: "common",
  },
  {
    label: "MONTAGNAC-MONTPEZAT",
    value: "04124",
    type: "common",
  },
  {
    label: "SAINT-MARTIN-DE-BROMES",
    value: "04189",
    type: "common",
  },
  {
    label: "ESPARRON-DE-VERDON",
    value: "04081",
    type: "common",
  },
  {
    label: "GREOUX-LES-BAINS",
    value: "04094",
    type: "common",
  },
  {
    label: "BEAUMONT-DE-PERTUIS",
    value: "84014",
    type: "common",
  },
  {
    label: "SAINTE-TULLE",
    value: "04197",
    type: "common",
  },
  {
    label: "CORBIERES-EN-PROVENCE",
    value: "04063",
    type: "common",
  },
  {
    label: "LA BASTIDE-DES-JOURDANS",
    value: "84009",
    type: "common",
  },
  {
    label: "SAINT-MARTIN-DE-LA-BRASQUE",
    value: "84113",
    type: "common",
  },
  {
    label: "PEYPIN-D'AIGUES",
    value: "84090",
    type: "common",
  },
  {
    label: "GRAMBOIS",
    value: "84052",
    type: "common",
  },
  {
    label: "SANNES",
    value: "84121",
    type: "common",
  },
  {
    label: "LA MOTTE-D'AIGUES",
    value: "84084",
    type: "common",
  },
  {
    label: "CABRIERES-D'AIGUES",
    value: "84024",
    type: "common",
  },
  {
    label: "VAUGINES",
    value: "84140",
    type: "common",
  },
  {
    label: "CUCURON",
    value: "84042",
    type: "common",
  },
  {
    label: "PUYVERT",
    value: "84095",
    type: "common",
  },
  {
    label: "LOURMARIN",
    value: "84068",
    type: "common",
  },
  {
    label: "LAURIS",
    value: "84065",
    type: "common",
  },
  {
    label: "PUGET",
    value: "84093",
    type: "common",
  },
  {
    label: "CHEVAL-BLANC",
    value: "84038",
    type: "common",
  },
  {
    label: "ORGON",
    value: "13067",
    type: "common",
  },
  {
    label: "MOLLEGES",
    value: "13064",
    type: "common",
  },
  {
    label: "EYGALIERES",
    value: "13034",
    type: "common",
  },
  {
    label: "SAINT-REMY-DE-PROVENCE",
    value: "13100",
    type: "common",
  },
  {
    label: "SAINT-ETIENNE-DU-GRES",
    value: "13094",
    type: "common",
  },
  {
    label: "MAS-BLANC-DES-ALPILLES",
    value: "13057",
    type: "common",
  },
  {
    label: "TARASCON",
    value: "13108",
    type: "common",
  },
  {
    label: "BEAUCAIRE",
    value: "30032",
    type: "common",
  },
  {
    label: "MANDUEL",
    value: "30155",
    type: "common",
  },
  {
    label: "RODILHAN",
    value: "30356",
    type: "common",
  },
  {
    label: "GARONS",
    value: "30125",
    type: "common",
  },
  {
    label: "BOUILLARGUES",
    value: "30047",
    type: "common",
  },
  {
    label: "CAISSARGUES",
    value: "30060",
    type: "common",
  },
  {
    label: "NAGES-ET-SOLORGUES",
    value: "30186",
    type: "common",
  },
  {
    label: "MILHAUD",
    value: "30169",
    type: "common",
  },
  {
    label: "LANGLADE",
    value: "30138",
    type: "common",
  },
  {
    label: "SAINT-DIONISY",
    value: "30249",
    type: "common",
  },
  {
    label: "CONGENIES",
    value: "30091",
    type: "common",
  },
  {
    label: "CALVISSON",
    value: "30062",
    type: "common",
  },
  {
    label: "VILLEVIEILLE",
    value: "30352",
    type: "common",
  },
  {
    label: "SOUVIGNARGUES",
    value: "30324",
    type: "common",
  },
  {
    label: "FONTANES",
    value: "30114",
    type: "common",
  },
  {
    label: "AUJARGUES",
    value: "30023",
    type: "common",
  },
  {
    label: "GARRIGUES",
    value: "34112",
    type: "common",
  },
  {
    label: "CAMPAGNE",
    value: "34048",
    type: "common",
  },
  {
    label: "SALINELLES",
    value: "30306",
    type: "common",
  },
  {
    label: "SAINT-CLEMENT",
    value: "30244",
    type: "common",
  },
  {
    label: "CARNAS",
    value: "30069",
    type: "common",
  },
  {
    label: "ASPERES",
    value: "30018",
    type: "common",
  },
  {
    label: "VACQUIERES",
    value: "34318",
    type: "common",
  },
  {
    label: "SAUTEYRARGUES",
    value: "34297",
    type: "common",
  },
  {
    label: "FONTANES",
    value: "34102",
    type: "common",
  },
  {
    label: "VALFLAUNES",
    value: "34322",
    type: "common",
  },
  {
    label: "LAURET",
    value: "34131",
    type: "common",
  },
  {
    label: "ROUET",
    value: "34236",
    type: "common",
  },
  {
    label: "NOTRE-DAME-DE-LONDRES",
    value: "34185",
    type: "common",
  },
  {
    label: "MAS-DE-LONDRES",
    value: "34152",
    type: "common",
  },
  {
    label: "SAINT-MARTIN-DE-LONDRES",
    value: "34274",
    type: "common",
  },
  {
    label: "SAINT-JEAN-DE-BUEGES",
    value: "34264",
    type: "common",
  },
  {
    label: "PEGAIROLLES-DE-BUEGES",
    value: "34195",
    type: "common",
  },
  {
    label: "CAUSSE-DE-LA-SELLE",
    value: "34060",
    type: "common",
  },
  {
    label: "LA VACQUERIE-ET-SAINT-MARTIN-DE-CASTRIES",
    value: "34317",
    type: "common",
  },
  {
    label: "SAINT-PIERRE-DE-LA-FAGE",
    value: "34283",
    type: "common",
  },
  {
    label: "SAINT-ETIENNE-DE-GOURGAS",
    value: "34251",
    type: "common",
  },
  {
    label: "SAINT-FELIX-DE-L'HERAS",
    value: "34253",
    type: "common",
  },
  {
    label: "PEGAIROLLES-DE-L'ESCALETTE",
    value: "34196",
    type: "common",
  },
  {
    label: "LAUROUX",
    value: "34132",
    type: "common",
  },
  {
    label: "ROQUEREDONDE",
    value: "34233",
    type: "common",
  },
  {
    label: "ROMIGUIERES",
    value: "34231",
    type: "common",
  },
  {
    label: "CEILHES-ET-ROCOZELS",
    value: "34071",
    type: "common",
  },
  {
    label: "LE CLAPIER",
    value: "12067",
    type: "common",
  },
  {
    label: "MONTAGNOL",
    value: "12147",
    type: "common",
  },
  {
    label: "SYLVANES",
    value: "12274",
    type: "common",
  },
  {
    label: "FAYET",
    value: "12099",
    type: "common",
  },
  {
    label: "CAMARES",
    value: "12044",
    type: "common",
  },
  {
    label: "MOUNES-PROHENCOUX",
    value: "12192",
    type: "common",
  },
  {
    label: "COMBRET",
    value: "12069",
    type: "common",
  },
  {
    label: "BELMONT-SUR-RANCE",
    value: "12025",
    type: "common",
  },
  {
    label: "LAVAL-ROQUECEZIERE",
    value: "12125",
    type: "common",
  },
  {
    label: "SAINT-SALVI-DE-CARCAVES",
    value: "81268",
    type: "common",
  },
  {
    label: "LE MASNAU-MASSUGUIES",
    value: "81158",
    type: "common",
  },
  {
    label: "MONTFRANC",
    value: "12152",
    type: "common",
  },
  {
    label: "RAYSSAC",
    value: "81221",
    type: "common",
  },
  {
    label: "PAULINET",
    value: "81203",
    type: "common",
  },
  {
    label: "MONT-ROC",
    value: "81183",
    type: "common",
  },
  {
    label: "TEILLET",
    value: "81295",
    type: "common",
  },
  {
    label: "FAUCH",
    value: "81088",
    type: "common",
  },
  {
    label: "DENAT",
    value: "81079",
    type: "common",
  },
  {
    label: "LOMBERS",
    value: "81147",
    type: "common",
  },
  {
    label: "SIEURAC",
    value: "81287",
    type: "common",
  },
  {
    label: "ORBAN",
    value: "81198",
    type: "common",
  },
  {
    label: "LASGRAISSES",
    value: "81138",
    type: "common",
  },
  {
    label: "LABESSIERE-CANDEIL",
    value: "81117",
    type: "common",
  },
  {
    label: "CADALEN",
    value: "81046",
    type: "common",
  },
  {
    label: "TECOU",
    value: "81294",
    type: "common",
  },
  {
    label: "PEYROLE",
    value: "81208",
    type: "common",
  },
  {
    label: "PARISOT",
    value: "81202",
    type: "common",
  },
  {
    label: "RABASTENS",
    value: "81220",
    type: "common",
  },
  {
    label: "LOUPIAC",
    value: "81149",
    type: "common",
  },
  {
    label: "COUFOULEUX",
    value: "81070",
    type: "common",
  },
  {
    label: "MEZENS",
    value: "81164",
    type: "common",
  },
  {
    label: "ROQUEMAURE",
    value: "81228",
    type: "common",
  },
  {
    label: "MIREPOIX-SUR-TARN",
    value: "31346",
    type: "common",
  },
  {
    label: "LAYRAC-SUR-TARN",
    value: "31288",
    type: "common",
  },
  {
    label: "BESSIERES",
    value: "31066",
    type: "common",
  },
  {
    label: "LA MAGDELAINE-SUR-TARN",
    value: "31311",
    type: "common",
  },
  {
    label: "VILLEMATIER",
    value: "31583",
    type: "common",
  },
  {
    label: "VACQUIERS",
    value: "31563",
    type: "common",
  },
  {
    label: "VILLAUDRIC",
    value: "31581",
    type: "common",
  },
  {
    label: "BOULOC",
    value: "31079",
    type: "common",
  },
  {
    label: "POMPIGNAN",
    value: "82142",
    type: "common",
  },
  {
    label: "SAINT-RUSTICE",
    value: "31515",
    type: "common",
  },
  {
    label: "ONDES",
    value: "31403",
    type: "common",
  },
  {
    label: "FRONTON",
    value: "31202",
    type: "common",
  },
  {
    label: "CASTELNAU-D'ESTRETEFONDS",
    value: "31118",
    type: "common",
  },
  {
    label: "GRISOLLES",
    value: "82075",
    type: "common",
  },
  {
    label: "AUCAMVILLE",
    value: "82005",
    type: "common",
  },
  {
    label: "SAVENES",
    value: "82178",
    type: "common",
  },
  {
    label: "LE BURGAUD",
    value: "31093",
    type: "common",
  },
  {
    label: "BEAUPUY",
    value: "82014",
    type: "common",
  },
  {
    label: "LAGRAULET-SAINT-NICOLAS",
    value: "31265",
    type: "common",
  },
  {
    label: "BELLESSERRE",
    value: "31062",
    type: "common",
  },
  {
    label: "GARIES",
    value: "82064",
    type: "common",
  },
  {
    label: "ESCAZEAUX",
    value: "82053",
    type: "common",
  },
  {
    label: "CABANAC-SEGUENVILLE",
    value: "31096",
    type: "common",
  },
  {
    label: "MAUBEC",
    value: "82106",
    type: "common",
  },
  {
    label: "GOAS",
    value: "82071",
    type: "common",
  },
  {
    label: "FAUDOAS",
    value: "82059",
    type: "common",
  },
  {
    label: "LE CAUSE",
    value: "82036",
    type: "common",
  },
  {
    label: "AVENSAC",
    value: "32021",
    type: "common",
  },
  {
    label: "SOLOMIAC",
    value: "32436",
    type: "common",
  },
  {
    label: "MONFORT",
    value: "32269",
    type: "common",
  },
  {
    label: "HOMPS",
    value: "32154",
    type: "common",
  },
  {
    label: "ESTRAMIAC",
    value: "32129",
    type: "common",
  },
  {
    label: "TAYBOSC",
    value: "32441",
    type: "common",
  },
  {
    label: "CADEILHAN",
    value: "32068",
    type: "common",
  },
  {
    label: "BIVES",
    value: "32055",
    type: "common",
  },
  {
    label: "BAJONNETTE",
    value: "32026",
    type: "common",
  },
  {
    label: "PIS",
    value: "32318",
    type: "common",
  },
  {
    label: "LALANNE",
    value: "32184",
    type: "common",
  },
  {
    label: "GOUTZ",
    value: "32150",
    type: "common",
  },
  {
    label: "CERAN",
    value: "32101",
    type: "common",
  },
  {
    label: "MONTESTRUC-SUR-GERS",
    value: "32286",
    type: "common",
  },
  {
    label: "FLEURANCE",
    value: "32132",
    type: "common",
  },
  {
    label: "REJAUMONT",
    value: "32341",
    type: "common",
  },
  {
    label: "PRECHAC",
    value: "32329",
    type: "common",
  },
  {
    label: "CEZAN",
    value: "32102",
    type: "common",
  },
  {
    label: "LARROQUE-SAINT-SERNIN",
    value: "32196",
    type: "common",
  },
  {
    label: "CASTERA-VERDUZAN",
    value: "32083",
    type: "common",
  },
  {
    label: "BONAS",
    value: "32059",
    type: "common",
  },
  {
    label: "AYGUETINTE",
    value: "32024",
    type: "common",
  },
  {
    label: "SAINT-PAUL-DE-BAISE",
    value: "32402",
    type: "common",
  },
  {
    label: "ROZES",
    value: "32352",
    type: "common",
  },
  {
    label: "BEZOLLES",
    value: "32052",
    type: "common",
  },
  {
    label: "MOUREDE",
    value: "32294",
    type: "common",
  },
  {
    label: "MARAMBAT",
    value: "32231",
    type: "common",
  },
  {
    label: "JUSTIAN",
    value: "32166",
    type: "common",
  },
  {
    label: "RAMOUZENS",
    value: "32338",
    type: "common",
  },
  {
    label: "LANNEPAX",
    value: "32190",
    type: "common",
  },
  {
    label: "NOULENS",
    value: "32299",
    type: "common",
  },
  {
    label: "ESPAS",
    value: "32125",
    type: "common",
  },
  {
    label: "BASCOUS",
    value: "32031",
    type: "common",
  },
  {
    label: "MANCIET",
    value: "32227",
    type: "common",
  },
  {
    label: "CRAVENCERES",
    value: "32113",
    type: "common",
  },
  {
    label: "SALLES-D'ARMAGNAC",
    value: "32408",
    type: "common",
  },
  {
    label: "SAINTE-CHRISTIE-D'ARMAGNAC",
    value: "32369",
    type: "common",
  },
  {
    label: "CAUPENNE-D'ARMAGNAC",
    value: "32094",
    type: "common",
  },
  {
    label: "PERCHEDE",
    value: "32310",
    type: "common",
  },
  {
    label: "MORMES",
    value: "32291",
    type: "common",
  },
  {
    label: "MONLEZUN-D'ARMAGNAC",
    value: "32274",
    type: "common",
  },
  {
    label: "MAGNAN",
    value: "32222",
    type: "common",
  },
  {
    label: "LAUJUZAN",
    value: "32202",
    type: "common",
  },
  {
    label: "LUSSAGNET",
    value: "40166",
    type: "common",
  },
  {
    label: "LE HOUGA",
    value: "32155",
    type: "common",
  },
  {
    label: "LE VIGNAU",
    value: "40329",
    type: "common",
  },
  {
    label: "CAZERES-SUR-L'ADOUR",
    value: "40080",
    type: "common",
  },
  {
    label: "CASTANDET",
    value: "40070",
    type: "common",
  },
  {
    label: "BORDERES-ET-LAMENSANS",
    value: "40049",
    type: "common",
  },
  {
    label: "SAINT-MAURICE-SUR-ADOUR",
    value: "40275",
    type: "common",
  },
  {
    label: "MONTGAILLARD",
    value: "40195",
    type: "common",
  },
  {
    label: "LARRIVIERE-SAINT-SAVIN",
    value: "40145",
    type: "common",
  },
  {
    label: "GRENADE-SUR-L'ADOUR",
    value: "40117",
    type: "common",
  },
  {
    label: "BAS-MAUCO",
    value: "40026",
    type: "common",
  },
  {
    label: "SAINT-SEVER",
    value: "40282",
    type: "common",
  },
  {
    label: "CAUNA",
    value: "40076",
    type: "common",
  },
  {
    label: "AURICE",
    value: "40020",
    type: "common",
  },
  {
    label: "TOULOUZETTE",
    value: "40318",
    type: "common",
  },
  {
    label: "SOUPROSSE",
    value: "40309",
    type: "common",
  },
  {
    label: "NERBIS",
    value: "40204",
    type: "common",
  },
  {
    label: "LAMOTHE",
    value: "40143",
    type: "common",
  },
  {
    label: "LAUREDE",
    value: "40147",
    type: "common",
  },
  {
    label: "GOUTS",
    value: "40116",
    type: "common",
  },
  {
    label: "VICQ-D'AURIBAT",
    value: "40324",
    type: "common",
  },
  {
    label: "SAINT-JEAN-DE-LIER",
    value: "40263",
    type: "common",
  },
  {
    label: "SAINT-GEOURS-D'AURIBAT",
    value: "40260",
    type: "common",
  },
  {
    label: "POYANNE",
    value: "40235",
    type: "common",
  },
  {
    label: "ONARD",
    value: "40208",
    type: "common",
  },
  {
    label: "LOUER",
    value: "40159",
    type: "common",
  },
  {
    label: "CASSEN",
    value: "40068",
    type: "common",
  },
  {
    label: "AUDON",
    value: "40018",
    type: "common",
  },
  {
    label: "TETHIEU",
    value: "40315",
    type: "common",
  },
  {
    label: "PRECHACQ-LES-BAINS",
    value: "40237",
    type: "common",
  },
  {
    label: "PONTONX-SUR-L'ADOUR",
    value: "40230",
    type: "common",
  },
  {
    label: "GOUSSE",
    value: "40115",
    type: "common",
  },
  {
    label: "SAINT-VINCENT-DE-PAUL",
    value: "40283",
    type: "common",
  },
  {
    label: "SAINT-PAUL-LES-DAX",
    value: "40279",
    type: "common",
  },
  {
    label: "MAGESCQ",
    value: "40168",
    type: "common",
  },
  {
    label: "SOUSTONS",
    value: "40310",
    type: "common",
  },
  {
    label: "VIEUX-BOUCAU-LES-BAINS",
    value: "40328",
    type: "common",
  },
  {
    label: "CAGNES-SUR-MER",
    value: "06027",
    type: "common",
  },
  {
    label: "VILLENEUVE-LOUBET",
    value: "06161",
    type: "common",
  },
  {
    label: "BIOT",
    value: "06018",
    type: "common",
  },
  {
    label: "LE ROURET",
    value: "06112",
    type: "common",
  },
  {
    label: "ROQUEFORT-LES-PINS",
    value: "06105",
    type: "common",
  },
  {
    label: "OPIO",
    value: "06089",
    type: "common",
  },
  {
    label: "GRASSE",
    value: "06069",
    type: "common",
  },
  {
    label: "CHATEAUNEUF-GRASSE",
    value: "06038",
    type: "common",
  },
  {
    label: "LE BAR-SUR-LOUP",
    value: "06010",
    type: "common",
  },
  {
    label: "SPERACEDES",
    value: "06137",
    type: "common",
  },
  {
    label: "CABRIS",
    value: "06026",
    type: "common",
  },
  {
    label: "SAINT-CEZAIRE-SUR-SIAGNE",
    value: "06118",
    type: "common",
  },
  {
    label: "COMPS-SUR-ARTUBY",
    value: "83044",
    type: "common",
  },
  {
    label: "MONTFERRAT",
    value: "83082",
    type: "common",
  },
  {
    label: "VERIGNON",
    value: "83147",
    type: "common",
  },
  {
    label: "AIGUINES",
    value: "83002",
    type: "common",
  },
  {
    label: "REGUSSE",
    value: "83102",
    type: "common",
  },
  {
    label: "BAUDINARD-SUR-VERDON",
    value: "83014",
    type: "common",
  },
  {
    label: "ARTIGNOSC-SUR-VERDON",
    value: "83005",
    type: "common",
  },
  {
    label: "SAINT-LAURENT-DU-VERDON",
    value: "04186",
    type: "common",
  },
  {
    label: "QUINSON",
    value: "04158",
    type: "common",
  },
  {
    label: "SAINT-JULIEN",
    value: "83113",
    type: "common",
  },
  {
    label: "VINON-SUR-VERDON",
    value: "83150",
    type: "common",
  },
  {
    label: "SAINT-PAUL-LES-DURANCE",
    value: "13099",
    type: "common",
  },
  {
    label: "MIRABEAU",
    value: "84076",
    type: "common",
  },
  {
    label: "LA TOUR-D'AIGUES",
    value: "84133",
    type: "common",
  },
  {
    label: "LA BASTIDONNE",
    value: "84010",
    type: "common",
  },
  {
    label: "ANSOUIS",
    value: "84002",
    type: "common",
  },
  {
    label: "VILLELAURE",
    value: "84147",
    type: "common",
  },
  {
    label: "CADENET",
    value: "84026",
    type: "common",
  },
  {
    label: "SAINT-ESTEVE-JANSON",
    value: "13093",
    type: "common",
  },
  {
    label: "LA ROQUE-D'ANTHERON",
    value: "13084",
    type: "common",
  },
  {
    label: "CHARLEVAL",
    value: "13024",
    type: "common",
  },
  {
    label: "MERINDOL",
    value: "84074",
    type: "common",
  },
  {
    label: "MALLEMORT",
    value: "13053",
    type: "common",
  },
  {
    label: "ALLEINS",
    value: "13003",
    type: "common",
  },
  {
    label: "SENAS",
    value: "13105",
    type: "common",
  },
  {
    label: "LAMANON",
    value: "13049",
    type: "common",
  },
  {
    label: "EYGUIERES",
    value: "13035",
    type: "common",
  },
  {
    label: "AUREILLE",
    value: "13006",
    type: "common",
  },
  {
    label: "MAUSSANE-LES-ALPILLES",
    value: "13058",
    type: "common",
  },
  {
    label: "LES BAUX-DE-PROVENCE",
    value: "13011",
    type: "common",
  },
  {
    label: "PARADOU",
    value: "13068",
    type: "common",
  },
  {
    label: "FONTVIEILLE",
    value: "13038",
    type: "common",
  },
  {
    label: "FOURQUES",
    value: "30117",
    type: "common",
  },
  {
    label: "BELLEGARDE",
    value: "30034",
    type: "common",
  },
  {
    label: "GENERAC",
    value: "30128",
    type: "common",
  },
  {
    label: "AUBORD",
    value: "30020",
    type: "common",
  },
  {
    label: "VESTRIC-ET-CANDIAC",
    value: "30347",
    type: "common",
  },
  {
    label: "UCHAUD",
    value: "30333",
    type: "common",
  },
  {
    label: "CODOGNAN",
    value: "30083",
    type: "common",
  },
  {
    label: "BERNIS",
    value: "30036",
    type: "common",
  },
  {
    label: "VERGEZE",
    value: "30344",
    type: "common",
  },
  {
    label: "MUS",
    value: "30185",
    type: "common",
  },
  {
    label: "BOISSIERES",
    value: "30043",
    type: "common",
  },
  {
    label: "AUBAIS",
    value: "30019",
    type: "common",
  },
  {
    label: "AIGUES-VIVES",
    value: "30004",
    type: "common",
  },
  {
    label: "VILLETELLE",
    value: "34340",
    type: "common",
  },
  {
    label: "SAINT-SERIES",
    value: "34288",
    type: "common",
  },
  {
    label: "BOISSERON",
    value: "34033",
    type: "common",
  },
  {
    label: "SOMMIERES",
    value: "30321",
    type: "common",
  },
  {
    label: "JUNAS",
    value: "30136",
    type: "common",
  },
  {
    label: "SAUSSINES",
    value: "34296",
    type: "common",
  },
  {
    label: "SAINT-JEAN-DE-CORNIES",
    value: "34265",
    type: "common",
  },
  {
    label: "SAINT-HILAIRE-DE-BEAUVOIR",
    value: "34263",
    type: "common",
  },
  {
    label: "RESTINCLIERES",
    value: "34227",
    type: "common",
  },
  {
    label: "GALARGUES",
    value: "34110",
    type: "common",
  },
  {
    label: "BUZIGNARGUES",
    value: "34043",
    type: "common",
  },
  {
    label: "BEAULIEU",
    value: "34027",
    type: "common",
  },
  {
    label: "SAINT-DREZERY",
    value: "34249",
    type: "common",
  },
  {
    label: "SAINTE-CROIX-DE-QUINTILLARGUES",
    value: "34248",
    type: "common",
  },
  {
    label: "SAINT-BAUZILLE-DE-MONTMEL",
    value: "34242",
    type: "common",
  },
  {
    label: "MONTAUD",
    value: "34164",
    type: "common",
  },
  {
    label: "GUZARGUES",
    value: "34118",
    type: "common",
  },
  {
    label: "LE TRIADOU",
    value: "34314",
    type: "common",
  },
  {
    label: "SAINT-MATHIEU-DE-TREVIERS",
    value: "34276",
    type: "common",
  },
  {
    label: "SAINT-JEAN-DE-CUCULLES",
    value: "34266",
    type: "common",
  },
  {
    label: "LES MATELLES",
    value: "34153",
    type: "common",
  },
  {
    label: "CAZEVIEILLE",
    value: "34066",
    type: "common",
  },
  {
    label: "VIOLS-LE-FORT",
    value: "34343",
    type: "common",
  },
  {
    label: "VIOLS-EN-LAVAL",
    value: "34342",
    type: "common",
  },
  {
    label: "PUECHABON",
    value: "34221",
    type: "common",
  },
  {
    label: "SAINT-GUILHEM-LE-DESERT",
    value: "34261",
    type: "common",
  },
  {
    label: "SAINT-PRIVAT",
    value: "34286",
    type: "common",
  },
  {
    label: "SOUBES",
    value: "34304",
    type: "common",
  },
  {
    label: "FOZIERES",
    value: "34106",
    type: "common",
  },
  {
    label: "POUJOLS",
    value: "34212",
    type: "common",
  },
  {
    label: "LES PLANS",
    value: "34205",
    type: "common",
  },
  {
    label: "LODEVE",
    value: "34142",
    type: "common",
  },
  {
    label: "JONCELS",
    value: "34121",
    type: "common",
  },
  {
    label: "AVENE",
    value: "34019",
    type: "common",
  },
  {
    label: "TAURIAC-DE-CAMARES",
    value: "12275",
    type: "common",
  },
  {
    label: "MELAGUES",
    value: "12143",
    type: "common",
  },
  {
    label: "BRUSQUE",
    value: "12039",
    type: "common",
  },
  {
    label: "PEUX-ET-COUFFOULEUX",
    value: "12179",
    type: "common",
  },
  {
    label: "BARRE",
    value: "81023",
    type: "common",
  },
  {
    label: "MURASSON",
    value: "12163",
    type: "common",
  },
  {
    label: "SAINT-SEVER-DU-MOUSTIER",
    value: "12249",
    type: "common",
  },
  {
    label: "SENAUX",
    value: "81282",
    type: "common",
  },
  {
    label: "ESCROUX",
    value: "81085",
    type: "common",
  },
  {
    label: "VIANE",
    value: "81314",
    type: "common",
  },
  {
    label: "SAINT-PIERRE-DE-TRIVISY",
    value: "81267",
    type: "common",
  },
  {
    label: "ARIFAT",
    value: "81017",
    type: "common",
  },
  {
    label: "MONTREDON-LABESSONNIE",
    value: "81182",
    type: "common",
  },
  {
    label: "VENES",
    value: "81311",
    type: "common",
  },
  {
    label: "SAINT-GENEST-DE-CONTEST",
    value: "81250",
    type: "common",
  },
  {
    label: "REALMONT",
    value: "81222",
    type: "common",
  },
  {
    label: "LABOUTARIE",
    value: "81119",
    type: "common",
  },
  {
    label: "SAINT-JULIEN-DU-PUY",
    value: "81258",
    type: "common",
  },
  {
    label: "MONTDRAGON",
    value: "81174",
    type: "common",
  },
  {
    label: "GRAULHET",
    value: "81105",
    type: "common",
  },
  {
    label: "SAINT-GAUZENS",
    value: "81248",
    type: "common",
  },
  {
    label: "PUYBEGON",
    value: "81215",
    type: "common",
  },
  {
    label: "BUSQUE",
    value: "81043",
    type: "common",
  },
  {
    label: "BRIATEXTE",
    value: "81039",
    type: "common",
  },
  {
    label: "GIROUSSENS",
    value: "81104",
    type: "common",
  },
  {
    label: "SAINT-LIEUX-LES-LAVAUR",
    value: "81261",
    type: "common",
  },
  {
    label: "SAINT-JEAN-DE-RIVES",
    value: "81255",
    type: "common",
  },
  {
    label: "LUGAN",
    value: "81150",
    type: "common",
  },
  {
    label: "SAINT-SULPICE-LA-POINTE",
    value: "81271",
    type: "common",
  },
  {
    label: "ROQUESERIERE",
    value: "31459",
    type: "common",
  },
  {
    label: "PAULHAC",
    value: "31407",
    type: "common",
  },
  {
    label: "GEMIL",
    value: "31216",
    type: "common",
  },
  {
    label: "BUZET-SUR-TARN",
    value: "31094",
    type: "common",
  },
  {
    label: "VILLARIES",
    value: "31579",
    type: "common",
  },
  {
    label: "MONTJOIRE",
    value: "31383",
    type: "common",
  },
  {
    label: "BAZUS",
    value: "31049",
    type: "common",
  },
  {
    label: "VILLENEUVE-LES-BOULOC",
    value: "31587",
    type: "common",
  },
  {
    label: "SAINT-SAUVEUR",
    value: "31516",
    type: "common",
  },
  {
    label: "LABASTIDE-SAINT-SERNIN",
    value: "31252",
    type: "common",
  },
  {
    label: "GARGAS",
    value: "31211",
    type: "common",
  },
  {
    label: "CEPET",
    value: "31136",
    type: "common",
  },
  {
    label: "SAINT-JORY",
    value: "31490",
    type: "common",
  },
  {
    label: "LARRA",
    value: "31592",
    type: "common",
  },
  {
    label: "GRENADE",
    value: "31232",
    type: "common",
  },
  {
    label: "SAINT-CEZERT",
    value: "31473",
    type: "common",
  },
  {
    label: "LAUNAC",
    value: "31281",
    type: "common",
  },
  {
    label: "PUYSSEGUR",
    value: "31444",
    type: "common",
  },
  {
    label: "PELLEPORT",
    value: "31413",
    type: "common",
  },
  {
    label: "DRUDAS",
    value: "31164",
    type: "common",
  },
  {
    label: "LAREOLE",
    value: "31275",
    type: "common",
  },
  {
    label: "COX",
    value: "31156",
    type: "common",
  },
  {
    label: "CADOURS",
    value: "31098",
    type: "common",
  },
  {
    label: "BRIGNEMONT",
    value: "31090",
    type: "common",
  },
  {
    label: "SARRANT",
    value: "32416",
    type: "common",
  },
  {
    label: "SAINT-GEORGES",
    value: "32377",
    type: "common",
  },
  {
    label: "SAINTE-ANNE",
    value: "32357",
    type: "common",
  },
  {
    label: "SEREMPUY",
    value: "32431",
    type: "common",
  },
  {
    label: "MAUVEZIN",
    value: "32249",
    type: "common",
  },
  {
    label: "LABRIHE",
    value: "32173",
    type: "common",
  },
  {
    label: "SAINTE-GEMME",
    value: "32376",
    type: "common",
  },
  {
    label: "SAINT-BRES",
    value: "32366",
    type: "common",
  },
  {
    label: "PUYCASQUIER",
    value: "32335",
    type: "common",
  },
  {
    label: "MARAVAT",
    value: "32232",
    type: "common",
  },
  {
    label: "MANSEMPUY",
    value: "32229",
    type: "common",
  },
  {
    label: "AUGNAX",
    value: "32014",
    type: "common",
  },
  {
    label: "TOURRENQUETS",
    value: "32453",
    type: "common",
  },
  {
    label: "MIREPOIX",
    value: "32258",
    type: "common",
  },
  {
    label: "MIRAMONT-LATOUR",
    value: "32255",
    type: "common",
  },
  {
    label: "GAVARRET-SUR-AULOUSTE",
    value: "32142",
    type: "common",
  },
  {
    label: "SAINTE-CHRISTIE",
    value: "32368",
    type: "common",
  },
  {
    label: "ROQUELAURE",
    value: "32348",
    type: "common",
  },
  {
    label: "ROQUEFORT",
    value: "32347",
    type: "common",
  },
  {
    label: "PUYSEGUR",
    value: "32337",
    type: "common",
  },
  {
    label: "PREIGNAN",
    value: "32331",
    type: "common",
  },
  {
    label: "SAINT-LARY",
    value: "32384",
    type: "common",
  },
  {
    label: "PEYRUSSE-MASSAS",
    value: "32316",
    type: "common",
  },
  {
    label: "MERENS",
    value: "32251",
    type: "common",
  },
  {
    label: "LAVARDENS",
    value: "32204",
    type: "common",
  },
  {
    label: "JEGUN",
    value: "32162",
    type: "common",
  },
  {
    label: "ANTRAS",
    value: "32003",
    type: "common",
  },
  {
    label: "SAINT-JEAN-POUTGE",
    value: "32382",
    type: "common",
  },
  {
    label: "VIC-FEZENSAC",
    value: "32462",
    type: "common",
  },
  {
    label: "PRENERON",
    value: "32332",
    type: "common",
  },
  {
    label: "DEMU",
    value: "32115",
    type: "common",
  },
  {
    label: "CASTILLON-DEBATS",
    value: "32088",
    type: "common",
  },
  {
    label: "SEAILLES",
    value: "32423",
    type: "common",
  },
  {
    label: "MARGOUET-MEYMES",
    value: "32235",
    type: "common",
  },
  {
    label: "SION",
    value: "32434",
    type: "common",
  },
  {
    label: "SABAZAN",
    value: "32354",
    type: "common",
  },
  {
    label: "LOUBEDAT",
    value: "32214",
    type: "common",
  },
  {
    label: "BETOUS",
    value: "32049",
    type: "common",
  },
  {
    label: "AVERON-BERGELLE",
    value: "32022",
    type: "common",
  },
  {
    label: "URGOSSE",
    value: "32458",
    type: "common",
  },
  {
    label: "SORBETS",
    value: "32437",
    type: "common",
  },
  {
    label: "SAINT-MARTIN-D'ARMAGNAC",
    value: "32390",
    type: "common",
  },
  {
    label: "NOGARO",
    value: "32296",
    type: "common",
  },
  {
    label: "ARBLADE-LE-HAUT",
    value: "32005",
    type: "common",
  },
  {
    label: "SAINT-GRIEDE",
    value: "32380",
    type: "common",
  },
  {
    label: "LUPPE-VIOLLES",
    value: "32220",
    type: "common",
  },
  {
    label: "LELIN-LAPUJOLLE",
    value: "32209",
    type: "common",
  },
  {
    label: "LANNE-SOUBIRAN",
    value: "32191",
    type: "common",
  },
  {
    label: "VERGOIGNAN",
    value: "32460",
    type: "common",
  },
  {
    label: "ARBLADE-LE-BAS",
    value: "32004",
    type: "common",
  },
  {
    label: "DUHORT-BACHEN",
    value: "40091",
    type: "common",
  },
  {
    label: "RENUNG",
    value: "40240",
    type: "common",
  },
  {
    label: "CLASSUN",
    value: "40082",
    type: "common",
  },
  {
    label: "SARRAZIET",
    value: "40289",
    type: "common",
  },
  {
    label: "FARGUES",
    value: "40099",
    type: "common",
  },
  {
    label: "BUANES",
    value: "40057",
    type: "common",
  },
  {
    label: "MONTSOUE",
    value: "40196",
    type: "common",
  },
  {
    label: "EYRES-MONCUBE",
    value: "40098",
    type: "common",
  },
  {
    label: "DUMES",
    value: "40092",
    type: "common",
  },
  {
    label: "DOAZIT",
    value: "40089",
    type: "common",
  },
  {
    label: "BANOS",
    value: "40024",
    type: "common",
  },
  {
    label: "AUDIGNON",
    value: "40017",
    type: "common",
  },
  {
    label: "MONTAUT",
    value: "40191",
    type: "common",
  },
  {
    label: "MAYLIS",
    value: "40177",
    type: "common",
  },
  {
    label: "HAURIET",
    value: "40121",
    type: "common",
  },
  {
    label: "SAINT-AUBIN",
    value: "40249",
    type: "common",
  },
  {
    label: "MUGRON",
    value: "40201",
    type: "common",
  },
  {
    label: "LOURQUEN",
    value: "40160",
    type: "common",
  },
  {
    label: "LARBEY",
    value: "40144",
    type: "common",
  },
  {
    label: "LAHOSSE",
    value: "40141",
    type: "common",
  },
  {
    label: "CAUPENNE",
    value: "40078",
    type: "common",
  },
  {
    label: "POYARTIN",
    value: "40236",
    type: "common",
  },
  {
    label: "NOUSSE",
    value: "40205",
    type: "common",
  },
  {
    label: "MONTFORT-EN-CHALOSSE",
    value: "40194",
    type: "common",
  },
  {
    label: "GAMARDE-LES-BAINS",
    value: "40104",
    type: "common",
  },
  {
    label: "HINX",
    value: "40126",
    type: "common",
  },
  {
    label: "GOOS",
    value: "40113",
    type: "common",
  },
  {
    label: "YZOSSE",
    value: "40334",
    type: "common",
  },
  {
    label: "NARROSSE",
    value: "40202",
    type: "common",
  },
  {
    label: "CANDRESSE",
    value: "40063",
    type: "common",
  },
  {
    label: "SEYRESSE",
    value: "40300",
    type: "common",
  },
  {
    label: "MEES",
    value: "40179",
    type: "common",
  },
  {
    label: "DAX",
    value: "40088",
    type: "common",
  },
  {
    label: "RIVIERE-SAAS-ET-GOURBY",
    value: "40244",
    type: "common",
  },
  {
    label: "ANGOUME",
    value: "40003",
    type: "common",
  },
  {
    label: "SAINT-GEOURS-DE-MAREMNE",
    value: "40261",
    type: "common",
  },
  {
    label: "TOSSE",
    value: "40317",
    type: "common",
  },
  {
    label: "SAUBION",
    value: "40291",
    type: "common",
  },
  {
    label: "SOORTS-HOSSEGOR",
    value: "40304",
    type: "common",
  },
  {
    label: "SEIGNOSSE",
    value: "40296",
    type: "common",
  },
  {
    label: "ANTIBES",
    value: "06004",
    type: "common",
  },
  {
    label: "VALLAURIS",
    value: "06155",
    type: "common",
  },
  {
    label: "VALBONNE",
    value: "06152",
    type: "common",
  },
  {
    label: "MOUGINS",
    value: "06085",
    type: "common",
  },
  {
    label: "LE CANNET",
    value: "06030",
    type: "common",
  },
  {
    label: "LA ROQUETTE-SUR-SIAGNE",
    value: "06108",
    type: "common",
  },
  {
    label: "PEGOMAS",
    value: "06090",
    type: "common",
  },
  {
    label: "MOUANS-SARTOUX",
    value: "06084",
    type: "common",
  },
  {
    label: "AURIBEAU-SUR-SIAGNE",
    value: "06007",
    type: "common",
  },
  {
    label: "LE TIGNET",
    value: "06140",
    type: "common",
  },
  {
    label: "PEYMEINADE",
    value: "06095",
    type: "common",
  },
  {
    label: "MONTAUROUX",
    value: "83081",
    type: "common",
  },
  {
    label: "CALLIAN",
    value: "83029",
    type: "common",
  },
  {
    label: "TOURRETTES",
    value: "83138",
    type: "common",
  },
  {
    label: "FAYENCE",
    value: "83055",
    type: "common",
  },
  {
    label: "SEILLANS",
    value: "83124",
    type: "common",
  },
  {
    label: "CLAVIERS",
    value: "83041",
    type: "common",
  },
  {
    label: "BARGEMON",
    value: "83011",
    type: "common",
  },
  {
    label: "CHATEAUDOUBLE",
    value: "83038",
    type: "common",
  },
  {
    label: "AMPUS",
    value: "83003",
    type: "common",
  },
  {
    label: "AUPS",
    value: "83007",
    type: "common",
  },
  {
    label: "MOISSAC-BELLEVUE",
    value: "83078",
    type: "common",
  },
  {
    label: "MONTMEYAN",
    value: "83084",
    type: "common",
  },
  {
    label: "LA VERDIERE",
    value: "83146",
    type: "common",
  },
  {
    label: "GINASSERVIS",
    value: "83066",
    type: "common",
  },
  {
    label: "JOUQUES",
    value: "13048",
    type: "common",
  },
  {
    label: "PEYROLLES-EN-PROVENCE",
    value: "13074",
    type: "common",
  },
  {
    label: "PERTUIS",
    value: "84089",
    type: "common",
  },
  {
    label: "MEYRARGUES",
    value: "13059",
    type: "common",
  },
  {
    label: "LE PUY-SAINTE-REPARADE",
    value: "13080",
    type: "common",
  },
  {
    label: "ROGNES",
    value: "13082",
    type: "common",
  },
  {
    label: "LAMBESC",
    value: "13050",
    type: "common",
  },
  {
    label: "AURONS",
    value: "13008",
    type: "common",
  },
  {
    label: "VERNEGUES",
    value: "13115",
    type: "common",
  },
  {
    label: "SALON-DE-PROVENCE",
    value: "13103",
    type: "common",
  },
  {
    label: "MOURIES",
    value: "13065",
    type: "common",
  },
  {
    label: "SAINT-GILLES",
    value: "30258",
    type: "common",
  },
  {
    label: "BEAUVOISIN",
    value: "30033",
    type: "common",
  },
  {
    label: "LE CAILAR",
    value: "30059",
    type: "common",
  },
  {
    label: "GALLARGUES-LE-MONTUEUX",
    value: "30123",
    type: "common",
  },
  {
    label: "AIMARGUES",
    value: "30006",
    type: "common",
  },
  {
    label: "SATURARGUES",
    value: "34294",
    type: "common",
  },
  {
    label: "LUNEL-VIEL",
    value: "34146",
    type: "common",
  },
  {
    label: "LUNEL",
    value: "34145",
    type: "common",
  },
  {
    label: "VALERGUES",
    value: "34321",
    type: "common",
  },
  {
    label: "SUSSARGUES",
    value: "34307",
    type: "common",
  },
  {
    label: "SAINT-GENIES-DES-MOURGUES",
    value: "34256",
    type: "common",
  },
  {
    label: "SAINT-BRES",
    value: "34244",
    type: "common",
  },
  {
    label: "VENDARGUES",
    value: "34327",
    type: "common",
  },
  {
    label: "TEYRAN",
    value: "34309",
    type: "common",
  },
  {
    label: "CASTRIES",
    value: "34058",
    type: "common",
  },
  {
    label: "ASSAS",
    value: "34014",
    type: "common",
  },
  {
    label: "SAINT-VINCENT-DE-BARBEYRARGUES",
    value: "34290",
    type: "common",
  },
  {
    label: "SAINT-CLEMENT-DE-RIVIERE",
    value: "34247",
    type: "common",
  },
  {
    label: "PRADES-LE-LEZ",
    value: "34217",
    type: "common",
  },
  {
    label: "MONTFERRIER-SUR-LEZ",
    value: "34169",
    type: "common",
  },
  {
    label: "SAINT-GELY-DU-FESC",
    value: "34255",
    type: "common",
  },
  {
    label: "MURLES",
    value: "34177",
    type: "common",
  },
  {
    label: "COMBAILLAUX",
    value: "34082",
    type: "common",
  },
  {
    label: "VAILHAUQUES",
    value: "34320",
    type: "common",
  },
  {
    label: "ARGELLIERS",
    value: "34012",
    type: "common",
  },
  {
    label: "ANIANE",
    value: "34010",
    type: "common",
  },
  {
    label: "SAINT-JEAN-DE-FOS",
    value: "34267",
    type: "common",
  },
  {
    label: "MONTPEYROUX",
    value: "34173",
    type: "common",
  },
  {
    label: "LAGAMAS",
    value: "34125",
    type: "common",
  },
  {
    label: "SAINT-SATURNIN-DE-LUCIAN",
    value: "34287",
    type: "common",
  },
  {
    label: "SAINT-JEAN-DE-LA-BLAQUIERE",
    value: "34268",
    type: "common",
  },
  {
    label: "SAINT-GUIRAUD",
    value: "34262",
    type: "common",
  },
  {
    label: "JONQUIERES",
    value: "34122",
    type: "common",
  },
  {
    label: "ARBORAS",
    value: "34011",
    type: "common",
  },
  {
    label: "USCLAS-DU-BOSC",
    value: "34316",
    type: "common",
  },
  {
    label: "SOUMONT",
    value: "34306",
    type: "common",
  },
  {
    label: "CELLES",
    value: "34072",
    type: "common",
  },
  {
    label: "LE BOSC",
    value: "34036",
    type: "common",
  },
  {
    label: "LE PUECH",
    value: "34220",
    type: "common",
  },
  {
    label: "OLMET-ET-VILLECUN",
    value: "34188",
    type: "common",
  },
  {
    label: "LAVALETTE",
    value: "34133",
    type: "common",
  },
  {
    label: "LUNAS",
    value: "34144",
    type: "common",
  },
  {
    label: "DIO-ET-VALQUIERES",
    value: "34093",
    type: "common",
  },
  {
    label: "CAMPLONG",
    value: "34049",
    type: "common",
  },
  {
    label: "LE BOUSQUET-D'ORB",
    value: "34038",
    type: "common",
  },
  {
    label: "GRAISSESSAC",
    value: "34117",
    type: "common",
  },
  {
    label: "SAINT-GENIES-DE-VARENSAL",
    value: "34257",
    type: "common",
  },
  {
    label: "CASTANET-LE-HAUT",
    value: "34055",
    type: "common",
  },
  {
    label: "ARNAC-SUR-DOURDOU",
    value: "12009",
    type: "common",
  },
  {
    label: "MURAT-SUR-VEBRE",
    value: "81192",
    type: "common",
  },
  {
    label: "MOULIN-MAGE",
    value: "81188",
    type: "common",
  },
  {
    label: "LACAUNE",
    value: "81124",
    type: "common",
  },
  {
    label: "GIJOUNET",
    value: "81103",
    type: "common",
  },
  {
    label: "ESPERAUSSES",
    value: "81086",
    type: "common",
  },
  {
    label: "BERLATS",
    value: "81028",
    type: "common",
  },
  {
    label: "LACAZE",
    value: "81125",
    type: "common",
  },
  {
    label: "VABRE",
    value: "81305",
    type: "common",
  },
  {
    label: "SAINT-JEAN-DE-VALS",
    value: "81256",
    type: "common",
  },
  {
    label: "PEYREGOUX",
    value: "81207",
    type: "common",
  },
  {
    label: "MONTFA",
    value: "81177",
    type: "common",
  },
  {
    label: "MONTPINIER",
    value: "81181",
    type: "common",
  },
  {
    label: "LAUTREC",
    value: "81139",
    type: "common",
  },
  {
    label: "PUYCALVEL",
    value: "81216",
    type: "common",
  },
  {
    label: "BROUSSE",
    value: "81040",
    type: "common",
  },
  {
    label: "MOULAYRES",
    value: "81187",
    type: "common",
  },
  {
    label: "MISSECLE",
    value: "81169",
    type: "common",
  },
  {
    label: "DAMIATTE",
    value: "81078",
    type: "common",
  },
  {
    label: "CABANES",
    value: "81044",
    type: "common",
  },
  {
    label: "VITERBE",
    value: "81323",
    type: "common",
  },
  {
    label: "FIAC",
    value: "81092",
    type: "common",
  },
  {
    label: "LAVAUR",
    value: "81140",
    type: "common",
  },
  {
    label: "LABASTIDE-SAINT-GEORGES",
    value: "81116",
    type: "common",
  },
  {
    label: "AMBRES",
    value: "81011",
    type: "common",
  },
  {
    label: "SAINT-AGNAN",
    value: "81236",
    type: "common",
  },
  {
    label: "GARRIGUES",
    value: "81102",
    type: "common",
  },
  {
    label: "MONTPITOL",
    value: "31388",
    type: "common",
  },
  {
    label: "AZAS",
    value: "31038",
    type: "common",
  },
  {
    label: "SAINT-JEAN-LHERM",
    value: "31489",
    type: "common",
  },
  {
    label: "MONTASTRUC-LA-CONSEILLERE",
    value: "31358",
    type: "common",
  },
  {
    label: "GRAGNAGUE",
    value: "31228",
    type: "common",
  },
  {
    label: "GARIDECH",
    value: "31212",
    type: "common",
  },
  {
    label: "BONREPOS-RIQUET",
    value: "31074",
    type: "common",
  },
  {
    label: "SAINT-LOUP-CAMMAS",
    value: "31497",
    type: "common",
  },
  {
    label: "SAINT-GENIES-BELLEVUE",
    value: "31484",
    type: "common",
  },
  {
    label: "MONTBERON",
    value: "31364",
    type: "common",
  },
  {
    label: "LAPEYROUSE-FOSSAT",
    value: "31273",
    type: "common",
  },
  {
    label: "CASTELMAUROU",
    value: "31117",
    type: "common",
  },
  {
    label: "SAINT-ALBAN",
    value: "31467",
    type: "common",
  },
  {
    label: "PECHBONNIEU",
    value: "31410",
    type: "common",
  },
  {
    label: "GRATENTOUR",
    value: "31230",
    type: "common",
  },
  {
    label: "FONBEAUZARD",
    value: "31186",
    type: "common",
  },
  {
    label: "FENOUILLET",
    value: "31182",
    type: "common",
  },
  {
    label: "CASTELGINEST",
    value: "31116",
    type: "common",
  },
  {
    label: "BRUGUIERES",
    value: "31091",
    type: "common",
  },
  {
    label: "AUCAMVILLE",
    value: "31022",
    type: "common",
  },
  {
    label: "SEILH",
    value: "31541",
    type: "common",
  },
  {
    label: "LESPINASSE",
    value: "31293",
    type: "common",
  },
  {
    label: "GAGNAC-SUR-GARONNE",
    value: "31205",
    type: "common",
  },
  {
    label: "AUSSONNE",
    value: "31032",
    type: "common",
  },
  {
    label: "SAINT-PAUL-SUR-SAVE",
    value: "31507",
    type: "common",
  },
  {
    label: "MONTAIGUT-SUR-SAVE",
    value: "31356",
    type: "common",
  },
  {
    label: "MERVILLE",
    value: "31341",
    type: "common",
  },
  {
    label: "DAUX",
    value: "31160",
    type: "common",
  },
  {
    label: "THIL",
    value: "31553",
    type: "common",
  },
  {
    label: "MENVILLE",
    value: "31338",
    type: "common",
  },
  {
    label: "LE CASTERA",
    value: "31120",
    type: "common",
  },
  {
    label: "BRETX",
    value: "31089",
    type: "common",
  },
  {
    label: "VIGNAUX",
    value: "31577",
    type: "common",
  },
  {
    label: "LE GRES",
    value: "31234",
    type: "common",
  },
  {
    label: "GARAC",
    value: "31209",
    type: "common",
  },
  {
    label: "CAUBIAC",
    value: "31126",
    type: "common",
  },
  {
    label: "BELLEGARDE-SAINTE-MARIE",
    value: "31061",
    type: "common",
  },
  {
    label: "THOUX",
    value: "32444",
    type: "common",
  },
  {
    label: "SAINT-CRICQ",
    value: "32372",
    type: "common",
  },
  {
    label: "MONBRUN",
    value: "32262",
    type: "common",
  },
  {
    label: "ENCAUSSE",
    value: "32120",
    type: "common",
  },
  {
    label: "ARDIZAS",
    value: "32007",
    type: "common",
  },
  {
    label: "TOUGET",
    value: "32448",
    type: "common",
  },
  {
    label: "SIRAC",
    value: "32435",
    type: "common",
  },
  {
    label: "SAINT-ORENS",
    value: "32399",
    type: "common",
  },
  {
    label: "SAINT-GERMIER",
    value: "32379",
    type: "common",
  },
  {
    label: "COLOGNE",
    value: "32106",
    type: "common",
  },
  {
    label: "SAINTE-MARIE",
    value: "32388",
    type: "common",
  },
  {
    label: "SAINT-ANTONIN",
    value: "32359",
    type: "common",
  },
  {
    label: "SAINT-SAUVY",
    value: "32406",
    type: "common",
  },
  {
    label: "BLANQUEFORT",
    value: "32056",
    type: "common",
  },
  {
    label: "ANSAN",
    value: "32002",
    type: "common",
  },
  {
    label: "NOUGAROULET",
    value: "32298",
    type: "common",
  },
  {
    label: "LEBOULIN",
    value: "32207",
    type: "common",
  },
  {
    label: "CRASTES",
    value: "32112",
    type: "common",
  },
  {
    label: "MONTAUT-LES-CRENEAUX",
    value: "32279",
    type: "common",
  },
  {
    label: "DURAN",
    value: "32117",
    type: "common",
  },
  {
    label: "CASTIN",
    value: "32091",
    type: "common",
  },
  {
    label: "CASTILLON-MASSAS",
    value: "32089",
    type: "common",
  },
  {
    label: "ORDAN-LARROQUE",
    value: "32301",
    type: "common",
  },
  {
    label: "CAILLAVET",
    value: "32071",
    type: "common",
  },
  {
    label: "LE BROUILH-MONBERT",
    value: "32065",
    type: "common",
  },
  {
    label: "BIRAN",
    value: "32054",
    type: "common",
  },
  {
    label: "TUDELLE",
    value: "32456",
    type: "common",
  },
  {
    label: "ROQUEBRUNE",
    value: "32346",
    type: "common",
  },
  {
    label: "BAZIAN",
    value: "32033",
    type: "common",
  },
  {
    label: "LUPIAC",
    value: "32219",
    type: "common",
  },
  {
    label: "BELMONT",
    value: "32043",
    type: "common",
  },
  {
    label: "CASTELNAVET",
    value: "32081",
    type: "common",
  },
  {
    label: "POUYDRAGUIN",
    value: "32325",
    type: "common",
  },
  {
    label: "LOUSSOUS-DEBAT",
    value: "32218",
    type: "common",
  },
  {
    label: "FUSTEROUAU",
    value: "32135",
    type: "common",
  },
  {
    label: "BOUZON-GELLENAVE",
    value: "32063",
    type: "common",
  },
  {
    label: "AIGNAN",
    value: "32001",
    type: "common",
  },
  {
    label: "TERMES-D'ARMAGNAC",
    value: "32443",
    type: "common",
  },
  {
    label: "SARRAGACHIES",
    value: "32414",
    type: "common",
  },
  {
    label: "IZOTGES",
    value: "32161",
    type: "common",
  },
  {
    label: "TARSAC",
    value: "32439",
    type: "common",
  },
  {
    label: "SAINT-GERME",
    value: "32378",
    type: "common",
  },
  {
    label: "MAULICHERES",
    value: "32244",
    type: "common",
  },
  {
    label: "CAUMONT",
    value: "32093",
    type: "common",
  },
  {
    label: "LANNUX",
    value: "32192",
    type: "common",
  },
  {
    label: "GEE-RIVIERE",
    value: "32145",
    type: "common",
  },
  {
    label: "CORNEILLAN",
    value: "32108",
    type: "common",
  },
  {
    label: "BERNEDE",
    value: "32046",
    type: "common",
  },
  {
    label: "BARCELONNE-DU-GERS",
    value: "32027",
    type: "common",
  },
  {
    label: "AIRE-SUR-L'ADOUR",
    value: "40001",
    type: "common",
  },
  {
    label: "SORBETS",
    value: "40305",
    type: "common",
  },
  {
    label: "PECORADE",
    value: "40220",
    type: "common",
  },
  {
    label: "EUGENIE-LES-BAINS",
    value: "40097",
    type: "common",
  },
  {
    label: "BAHUS-SOUBIRAN",
    value: "40022",
    type: "common",
  },
  {
    label: "VIELLE-TURSAN",
    value: "40325",
    type: "common",
  },
  {
    label: "URGONS",
    value: "40321",
    type: "common",
  },
  {
    label: "SAINT-LOUBOUER",
    value: "40270",
    type: "common",
  },
  {
    label: "CASTELNAU-TURSAN",
    value: "40072",
    type: "common",
  },
  {
    label: "BATS",
    value: "40029",
    type: "common",
  },
  {
    label: "SERRES-GASTON",
    value: "40298",
    type: "common",
  },
  {
    label: "SAINTE-COLOMBE",
    value: "40252",
    type: "common",
  },
  {
    label: "COUDURES",
    value: "40086",
    type: "common",
  },
  {
    label: "AUBAGNAN",
    value: "40016",
    type: "common",
  },
  {
    label: "SERRESLOUS-ET-ARRIBANS",
    value: "40299",
    type: "common",
  },
  {
    label: "HORSARRIEU",
    value: "40128",
    type: "common",
  },
  {
    label: "HAGETMAU",
    value: "40119",
    type: "common",
  },
  {
    label: "SAINT-CRICQ-CHALOSSE",
    value: "40253",
    type: "common",
  },
  {
    label: "BERGOUEY",
    value: "40038",
    type: "common",
  },
  {
    label: "GAUJACQ",
    value: "40109",
    type: "common",
  },
  {
    label: "BASTENNES",
    value: "40028",
    type: "common",
  },
  {
    label: "BAIGTS",
    value: "40023",
    type: "common",
  },
  {
    label: "OZOURT",
    value: "40216",
    type: "common",
  },
  {
    label: "GIBRET",
    value: "40112",
    type: "common",
  },
  {
    label: "DONZACQ",
    value: "40090",
    type: "common",
  },
  {
    label: "CASTELNAU-CHALOSSE",
    value: "40071",
    type: "common",
  },
  {
    label: "SORT-EN-CHALOSSE",
    value: "40308",
    type: "common",
  },
  {
    label: "MIMBASTE",
    value: "40183",
    type: "common",
  },
  {
    label: "GARREY",
    value: "40106",
    type: "common",
  },
  {
    label: "CLERMONT",
    value: "40084",
    type: "common",
  },
  {
    label: "SAUGNAC-ET-CAMBRAN",
    value: "40294",
    type: "common",
  },
  {
    label: "SAINT-PANDELON",
    value: "40277",
    type: "common",
  },
  {
    label: "BENESSE-LES-DAX",
    value: "40035",
    type: "common",
  },
  {
    label: "TERCIS-LES-BAINS",
    value: "40314",
    type: "common",
  },
  {
    label: "OEYRELUY",
    value: "40207",
    type: "common",
  },
  {
    label: "HEUGAS",
    value: "40125",
    type: "common",
  },
  {
    label: "SIEST",
    value: "40301",
    type: "common",
  },
  {
    label: "SAUBUSSE",
    value: "40293",
    type: "common",
  },
  {
    label: "SAINT-LON-LES-MINES",
    value: "40269",
    type: "common",
  },
  {
    label: "PEY",
    value: "40222",
    type: "common",
  },
  {
    label: "ORIST",
    value: "40211",
    type: "common",
  },
  {
    label: "SAINT-JEAN-DE-MARSACQ",
    value: "40264",
    type: "common",
  },
  {
    label: "JOSSE",
    value: "40129",
    type: "common",
  },
  {
    label: "SAINT-VINCENT-DE-TYROSSE",
    value: "40284",
    type: "common",
  },
  {
    label: "BENESSE-MAREMNE",
    value: "40036",
    type: "common",
  },
  {
    label: "ANGRESSE",
    value: "40004",
    type: "common",
  },
  {
    label: "CAPBRETON",
    value: "40065",
    type: "common",
  },
  {
    label: "CANNES",
    value: "06029",
    type: "common",
  },
  {
    label: "MANDELIEU-LA-NAPOULE",
    value: "06079",
    type: "common",
  },
  {
    label: "TANNERON",
    value: "83133",
    type: "common",
  },
  {
    label: "LES ADRETS-DE-L'ESTEREL",
    value: "83001",
    type: "common",
  },
  {
    label: "SAINT-PAUL-EN-FORET",
    value: "83117",
    type: "common",
  },
  {
    label: "BAGNOLS-EN-FORET",
    value: "83008",
    type: "common",
  },
  {
    label: "FIGANIERES",
    value: "83056",
    type: "common",
  },
  {
    label: "CALLAS",
    value: "83028",
    type: "common",
  },
  {
    label: "DRAGUIGNAN",
    value: "83050",
    type: "common",
  },
  {
    label: "FLAYOSC",
    value: "83058",
    type: "common",
  },
  {
    label: "VILLECROZE",
    value: "83149",
    type: "common",
  },
  {
    label: "TOURTOUR",
    value: "83139",
    type: "common",
  },
  {
    label: "SALERNES",
    value: "83121",
    type: "common",
  },
  {
    label: "SILLANS-LA-CASCADE",
    value: "83128",
    type: "common",
  },
  {
    label: "FOX-AMPHOUX",
    value: "83060",
    type: "common",
  },
  {
    label: "TAVERNES",
    value: "83135",
    type: "common",
  },
  {
    label: "PONTEVES",
    value: "83095",
    type: "common",
  },
  {
    label: "VARAGES",
    value: "83145",
    type: "common",
  },
  {
    label: "SAINT-MARTIN-DE-PALLIERES",
    value: "83114",
    type: "common",
  },
  {
    label: "ESPARRON",
    value: "83052",
    type: "common",
  },
  {
    label: "ARTIGUES",
    value: "83006",
    type: "common",
  },
  {
    label: "RIANS",
    value: "83104",
    type: "common",
  },
  {
    label: "VENELLES",
    value: "13113",
    type: "common",
  },
  {
    label: "SAINT-CANNAT",
    value: "13091",
    type: "common",
  },
  {
    label: "LA BARBEN",
    value: "13009",
    type: "common",
  },
  {
    label: "PELISSANNE",
    value: "13069",
    type: "common",
  },
  {
    label: "GRANS",
    value: "13044",
    type: "common",
  },
  {
    label: "MIRAMAS",
    value: "13063",
    type: "common",
  },
  {
    label: "SAINT-MARTIN-DE-CRAU",
    value: "13097",
    type: "common",
  },
  {
    label: "VAUVERT",
    value: "30341",
    type: "common",
  },
  {
    label: "MARSILLARGUES",
    value: "34151",
    type: "common",
  },
  {
    label: "SAINT-NAZAIRE-DE-PEZAN",
    value: "34280",
    type: "common",
  },
  {
    label: "SAINT-JUST",
    value: "34272",
    type: "common",
  },
  {
    label: "LANSARGUES",
    value: "34127",
    type: "common",
  },
  {
    label: "CANDILLARGUES",
    value: "34050",
    type: "common",
  },
  {
    label: "MUDAISON",
    value: "34176",
    type: "common",
  },
  {
    label: "BAILLARGUES",
    value: "34022",
    type: "common",
  },
  {
    label: "LE CRES",
    value: "34090",
    type: "common",
  },
  {
    label: "CASTELNAU-LE-LEZ",
    value: "34057",
    type: "common",
  },
  {
    label: "SAINT-AUNES",
    value: "34240",
    type: "common",
  },
  {
    label: "JACOU",
    value: "34120",
    type: "common",
  },
  {
    label: "MONTPELLIER",
    value: "34172",
    type: "common",
  },
  {
    label: "CLAPIERS",
    value: "34077",
    type: "common",
  },
  {
    label: "SAINT-GEORGES-D'ORQUES",
    value: "34259",
    type: "common",
  },
  {
    label: "JUVIGNAC",
    value: "34123",
    type: "common",
  },
  {
    label: "GRABELS",
    value: "34116",
    type: "common",
  },
  {
    label: "SAINT-PAUL-ET-VALMALLE",
    value: "34282",
    type: "common",
  },
  {
    label: "MONTARNAUD",
    value: "34163",
    type: "common",
  },
  {
    label: "LA BOISSIERE",
    value: "34035",
    type: "common",
  },
  {
    label: "SAINT-ANDRE-DE-SANGONIS",
    value: "34239",
    type: "common",
  },
  {
    label: "POUZOLS",
    value: "34215",
    type: "common",
  },
  {
    label: "POPIAN",
    value: "34208",
    type: "common",
  },
  {
    label: "GIGNAC",
    value: "34114",
    type: "common",
  },
  {
    label: "SAINT-FELIX-DE-LODEZ",
    value: "34254",
    type: "common",
  },
  {
    label: "LACOSTE",
    value: "34124",
    type: "common",
  },
  {
    label: "CLERMONT-L'HERAULT",
    value: "34079",
    type: "common",
  },
  {
    label: "CEYRAS",
    value: "34076",
    type: "common",
  },
  {
    label: "BRIGNAC",
    value: "34041",
    type: "common",
  },
  {
    label: "VILLENEUVETTE",
    value: "34338",
    type: "common",
  },
  {
    label: "LIAUSSON",
    value: "34137",
    type: "common",
  },
  {
    label: "SALASC",
    value: "34292",
    type: "common",
  },
  {
    label: "OCTON",
    value: "34186",
    type: "common",
  },
  {
    label: "MERIFONS",
    value: "34156",
    type: "common",
  },
  {
    label: "CARLENCAS-ET-LEVAS",
    value: "34053",
    type: "common",
  },
  {
    label: "BRENAS",
    value: "34040",
    type: "common",
  },
  {
    label: "BEDARIEUX",
    value: "34028",
    type: "common",
  },
  {
    label: "VILLEMAGNE-L'ARGENTIERE",
    value: "34335",
    type: "common",
  },
  {
    label: "LA TOUR-SUR-ORB",
    value: "34312",
    type: "common",
  },
  {
    label: "SAINT-ETIENNE-ESTRECHOUX",
    value: "34252",
    type: "common",
  },
  {
    label: "LE PRADAL",
    value: "34216",
    type: "common",
  },
  {
    label: "TAUSSAC-LA-BILLIERE",
    value: "34308",
    type: "common",
  },
  {
    label: "SAINT-GERVAIS-SUR-MARE",
    value: "34260",
    type: "common",
  },
  {
    label: "ROSIS",
    value: "34235",
    type: "common",
  },
  {
    label: "CAMBON-ET-SALVERGUES",
    value: "34046",
    type: "common",
  },
  {
    label: "NAGES",
    value: "81193",
    type: "common",
  },
  {
    label: "FONTRIEU",
    value: "81062",
    type: "common",
  },
  {
    label: "BRASSAC",
    value: "81037",
    type: "common",
  },
  {
    label: "LE BEZ",
    value: "81031",
    type: "common",
  },
  {
    label: "LACROUZETTE",
    value: "81128",
    type: "common",
  },
  {
    label: "BURLATS",
    value: "81042",
    type: "common",
  },
  {
    label: "SAINT-GERMIER",
    value: "81252",
    type: "common",
  },
  {
    label: "ROQUECOURBE",
    value: "81227",
    type: "common",
  },
  {
    label: "LABOULBENE",
    value: "81118",
    type: "common",
  },
  {
    label: "CASTRES",
    value: "81065",
    type: "common",
  },
  {
    label: "JONQUIERES",
    value: "81109",
    type: "common",
  },
  {
    label: "CARBES",
    value: "81058",
    type: "common",
  },
  {
    label: "VIELMUR-SUR-AGOUT",
    value: "81315",
    type: "common",
  },
  {
    label: "SERVIES",
    value: "81286",
    type: "common",
  },
  {
    label: "GUITALENS-L'ALBAREDE",
    value: "81132",
    type: "common",
  },
  {
    label: "CUQ",
    value: "81075",
    type: "common",
  },
  {
    label: "SAINT-PAUL-CAP-DE-JOUX",
    value: "81266",
    type: "common",
  },
  {
    label: "TEYSSODE",
    value: "81299",
    type: "common",
  },
  {
    label: "PRATVIEL",
    value: "81213",
    type: "common",
  },
  {
    label: "MAGRIN",
    value: "81151",
    type: "common",
  },
  {
    label: "VIVIERS-LES-LAVAUR",
    value: "81324",
    type: "common",
  },
  {
    label: "ROQUEVIDAL",
    value: "81229",
    type: "common",
  },
  {
    label: "MASSAC-SERAN",
    value: "81159",
    type: "common",
  },
  {
    label: "MARZENS",
    value: "81157",
    type: "common",
  },
  {
    label: "LACOUGOTTE-CADOUL",
    value: "81126",
    type: "common",
  },
  {
    label: "TEULAT",
    value: "81298",
    type: "common",
  },
  {
    label: "MONTCABRIER",
    value: "81173",
    type: "common",
  },
  {
    label: "BELCASTEL",
    value: "81025",
    type: "common",
  },
  {
    label: "BANNIERES",
    value: "81022",
    type: "common",
  },
  {
    label: "VERFEIL",
    value: "31573",
    type: "common",
  },
  {
    label: "SAINT-PIERRE",
    value: "31511",
    type: "common",
  },
  {
    label: "GAURE",
    value: "31215",
    type: "common",
  },
  {
    label: "SAINT-MARCEL-PAULEL",
    value: "31501",
    type: "common",
  },
  {
    label: "MONDOUZIL",
    value: "31352",
    type: "common",
  },
  {
    label: "LAVALETTE",
    value: "31285",
    type: "common",
  },
  {
    label: "BEAUPUY",
    value: "31053",
    type: "common",
  },
  {
    label: "L'UNION",
    value: "31561",
    type: "common",
  },
  {
    label: "SAINT-JEAN",
    value: "31488",
    type: "common",
  },
  {
    label: "ROUFFIAC-TOLOSAN",
    value: "31462",
    type: "common",
  },
  {
    label: "PIN-BALMA",
    value: "31418",
    type: "common",
  },
  {
    label: "MONTRABE",
    value: "31389",
    type: "common",
  },
  {
    label: "BALMA",
    value: "31044",
    type: "common",
  },
  {
    label: "LAUNAGUET",
    value: "31282",
    type: "common",
  },
  {
    label: "CORNEBARRIEU",
    value: "31150",
    type: "common",
  },
  {
    label: "BLAGNAC",
    value: "31069",
    type: "common",
  },
  {
    label: "BEAUZELLE",
    value: "31056",
    type: "common",
  },
  {
    label: "COLOMIERS",
    value: "31149",
    type: "common",
  },
  {
    label: "MONDONVILLE",
    value: "31351",
    type: "common",
  },
  {
    label: "PIBRAC",
    value: "31417",
    type: "common",
  },
  {
    label: "BRAX",
    value: "31088",
    type: "common",
  },
  {
    label: "MERENVIELLE",
    value: "31339",
    type: "common",
  },
  {
    label: "LEVIGNAC",
    value: "31297",
    type: "common",
  },
  {
    label: "LASSERRE-PRADERE",
    value: "31277",
    type: "common",
  },
  {
    label: "SEGOUFIELLE",
    value: "32425",
    type: "common",
  },
  {
    label: "L'ISLE-JOURDAIN",
    value: "32160",
    type: "common",
  },
  {
    label: "SAINTE-LIVRADE",
    value: "31496",
    type: "common",
  },
  {
    label: "ROQUELAURE-SAINT-AUBIN",
    value: "32349",
    type: "common",
  },
  {
    label: "RAZENGUES",
    value: "32339",
    type: "common",
  },
  {
    label: "CLERMONT-SAVES",
    value: "32105",
    type: "common",
  },
  {
    label: "BEAUPUY",
    value: "32038",
    type: "common",
  },
  {
    label: "MONFERRAN-SAVES",
    value: "32268",
    type: "common",
  },
  {
    label: "GISCARO",
    value: "32148",
    type: "common",
  },
  {
    label: "ESCORNEBŒUF",
    value: "32123",
    type: "common",
  },
  {
    label: "CATONVIELLE",
    value: "32092",
    type: "common",
  },
  {
    label: "JUILLES",
    value: "32165",
    type: "common",
  },
  {
    label: "GIMONT",
    value: "32147",
    type: "common",
  },
  {
    label: "LUSSAN",
    value: "32221",
    type: "common",
  },
  {
    label: "L'ISLE-ARNE",
    value: "32157",
    type: "common",
  },
  {
    label: "AUBIET",
    value: "32012",
    type: "common",
  },
  {
    label: "PESSAN",
    value: "32312",
    type: "common",
  },
  {
    label: "MONTEGUT",
    value: "32282",
    type: "common",
  },
  {
    label: "MARSAN",
    value: "32237",
    type: "common",
  },
  {
    label: "LAHITTE",
    value: "32183",
    type: "common",
  },
  {
    label: "PAVIE",
    value: "32307",
    type: "common",
  },
  {
    label: "LASSERAN",
    value: "32200",
    type: "common",
  },
  {
    label: "AUCH",
    value: "32013",
    type: "common",
  },
  {
    label: "BARRAN",
    value: "32029",
    type: "common",
  },
  {
    label: "SAINT-ARAILLES",
    value: "32360",
    type: "common",
  },
  {
    label: "RIGUEPEU",
    value: "32343",
    type: "common",
  },
  {
    label: "MIRANNES",
    value: "32257",
    type: "common",
  },
  {
    label: "CAZAUX-D'ANGLES",
    value: "32097",
    type: "common",
  },
  {
    label: "CASTELNAU-D'ANGLES",
    value: "32077",
    type: "common",
  },
  {
    label: "CALLIAN",
    value: "32072",
    type: "common",
  },
  {
    label: "PEYRUSSE-VIEILLE",
    value: "32317",
    type: "common",
  },
  {
    label: "PEYRUSSE-GRANDE",
    value: "32315",
    type: "common",
  },
  {
    label: "LOUSLITGES",
    value: "32217",
    type: "common",
  },
  {
    label: "GAZAX-ET-BACCARISSE",
    value: "32144",
    type: "common",
  },
  {
    label: "SAINT-PIERRE-D'AUBEZIES",
    value: "32403",
    type: "common",
  },
  {
    label: "COULOUME-MONDEBAT",
    value: "32109",
    type: "common",
  },
  {
    label: "BEAUMARCHES",
    value: "32036",
    type: "common",
  },
  {
    label: "TASQUE",
    value: "32440",
    type: "common",
  },
  {
    label: "PLAISANCE",
    value: "32319",
    type: "common",
  },
  {
    label: "LASSERRADE",
    value: "32199",
    type: "common",
  },
  {
    label: "GALIAX",
    value: "32136",
    type: "common",
  },
  {
    label: "PRECHAC-SUR-ADOUR",
    value: "32330",
    type: "common",
  },
  {
    label: "GOUX",
    value: "32151",
    type: "common",
  },
  {
    label: "CAHUZAC-SUR-ADOUR",
    value: "32070",
    type: "common",
  },
  {
    label: "SAINT-LANNE",
    value: "65387",
    type: "common",
  },
  {
    label: "VIELLA",
    value: "32463",
    type: "common",
  },
  {
    label: "SAINT-MONT",
    value: "32398",
    type: "common",
  },
  {
    label: "MAUMUSSON-LAGUIAN",
    value: "32245",
    type: "common",
  },
  {
    label: "VERLUS",
    value: "32461",
    type: "common",
  },
  {
    label: "PROJAN",
    value: "32333",
    type: "common",
  },
  {
    label: "LABARTHETE",
    value: "32170",
    type: "common",
  },
  {
    label: "AURENSAN",
    value: "32017",
    type: "common",
  },
  {
    label: "SARRON",
    value: "40290",
    type: "common",
  },
  {
    label: "SAINT-AGNET",
    value: "40247",
    type: "common",
  },
  {
    label: "LATRILLE",
    value: "40146",
    type: "common",
  },
  {
    label: "SEGOS",
    value: "32424",
    type: "common",
  },
  {
    label: "PAYROS-CAZAUTETS",
    value: "40219",
    type: "common",
  },
  {
    label: "MIRAMONT-SENSACQ",
    value: "40185",
    type: "common",
  },
  {
    label: "MAURIES",
    value: "40174",
    type: "common",
  },
  {
    label: "GEAUNE",
    value: "40110",
    type: "common",
  },
  {
    label: "CLEDES",
    value: "40083",
    type: "common",
  },
  {
    label: "PUYOL-CAZALET",
    value: "40239",
    type: "common",
  },
  {
    label: "LACAJUNTE",
    value: "40136",
    type: "common",
  },
  {
    label: "ARBOUCAVE",
    value: "40005",
    type: "common",
  },
  {
    label: "SAMADET",
    value: "40286",
    type: "common",
  },
  {
    label: "MONSEGUR",
    value: "40190",
    type: "common",
  },
  {
    label: "MANT",
    value: "40172",
    type: "common",
  },
  {
    label: "POUDENX",
    value: "40232",
    type: "common",
  },
  {
    label: "MORGANX",
    value: "40198",
    type: "common",
  },
  {
    label: "MOMUY",
    value: "40188",
    type: "common",
  },
  {
    label: "LACRABE",
    value: "40138",
    type: "common",
  },
  {
    label: "LABASTIDE-CHALOSSE",
    value: "40130",
    type: "common",
  },
  {
    label: "ARGELOS",
    value: "40007",
    type: "common",
  },
  {
    label: "NASSIET",
    value: "40203",
    type: "common",
  },
  {
    label: "MARPAPS",
    value: "40173",
    type: "common",
  },
  {
    label: "CAZALIS",
    value: "40079",
    type: "common",
  },
  {
    label: "CASTAIGNOS-SOUSLENS",
    value: "40069",
    type: "common",
  },
  {
    label: "BRASSEMPOUY",
    value: "40054",
    type: "common",
  },
  {
    label: "CASTEL-SARRAZIN",
    value: "40074",
    type: "common",
  },
  {
    label: "ARSAGUE",
    value: "40011",
    type: "common",
  },
  {
    label: "AMOU",
    value: "40002",
    type: "common",
  },
  {
    label: "TILH",
    value: "40316",
    type: "common",
  },
  {
    label: "POMAREZ",
    value: "40228",
    type: "common",
  },
  {
    label: "MOUSCARDES",
    value: "40199",
    type: "common",
  },
  {
    label: "MISSON",
    value: "40186",
    type: "common",
  },
  {
    label: "HABAS",
    value: "40118",
    type: "common",
  },
  {
    label: "ESTIBEAUX",
    value: "40095",
    type: "common",
  },
  {
    label: "POUILLON",
    value: "40233",
    type: "common",
  },
  {
    label: "GAAS",
    value: "40101",
    type: "common",
  },
  {
    label: "PEYREHORADE",
    value: "40224",
    type: "common",
  },
  {
    label: "CAUNEILLE",
    value: "40077",
    type: "common",
  },
  {
    label: "CAGNOTTE",
    value: "40059",
    type: "common",
  },
  {
    label: "BELUS",
    value: "40034",
    type: "common",
  },
  {
    label: "SAINT-ETIENNE-D'ORTHE",
    value: "40256",
    type: "common",
  },
  {
    label: "PORT-DE-LANNE",
    value: "40231",
    type: "common",
  },
  {
    label: "ORTHEVIELLE",
    value: "40212",
    type: "common",
  },
  {
    label: "SAINT-MARTIN-DE-HINX",
    value: "40272",
    type: "common",
  },
  {
    label: "BIARROTTE",
    value: "40042",
    type: "common",
  },
  {
    label: "SAUBRIGUES",
    value: "40292",
    type: "common",
  },
  {
    label: "SAINT-ANDRE-DE-SEIGNANX",
    value: "40248",
    type: "common",
  },
  {
    label: "ORX",
    value: "40213",
    type: "common",
  },
  {
    label: "LABENNE",
    value: "40133",
    type: "common",
  },
  {
    label: "ONDRES",
    value: "40209",
    type: "common",
  },
  {
    label: "THEOULE-SUR-MER",
    value: "06138",
    type: "common",
  },
  {
    label: "SAINT-RAPHAEL",
    value: "83118",
    type: "common",
  },
  {
    label: "PUGET-SUR-ARGENS",
    value: "83099",
    type: "common",
  },
  {
    label: "LE MUY",
    value: "83086",
    type: "common",
  },
  {
    label: "TRANS-EN-PROVENCE",
    value: "83141",
    type: "common",
  },
  {
    label: "LA MOTTE",
    value: "83085",
    type: "common",
  },
  {
    label: "SAINT-ANTONIN-DU-VAR",
    value: "83154",
    type: "common",
  },
  {
    label: "ENTRECASTEAUX",
    value: "83051",
    type: "common",
  },
  {
    label: "COTIGNAC",
    value: "83046",
    type: "common",
  },
  {
    label: "CORRENS",
    value: "83045",
    type: "common",
  },
  {
    label: "CHATEAUVERT",
    value: "83039",
    type: "common",
  },
  {
    label: "BRUE-AURIAC",
    value: "83025",
    type: "common",
  },
  {
    label: "BARJOLS",
    value: "83012",
    type: "common",
  },
  {
    label: "SEILLONS-SOURCE-D'ARGENS",
    value: "83125",
    type: "common",
  },
  {
    label: "OLLIERES",
    value: "83089",
    type: "common",
  },
  {
    label: "VAUVENARGUES",
    value: "13111",
    type: "common",
  },
  {
    label: "SAINT-ANTONIN-SUR-BAYON",
    value: "13090",
    type: "common",
  },
  {
    label: "PUYLOUBIER",
    value: "13079",
    type: "common",
  },
  {
    label: "SAINT-MARC-JAUMEGARDE",
    value: "13095",
    type: "common",
  },
  {
    label: "BEAURECUEIL",
    value: "13012",
    type: "common",
  },
  {
    label: "LE THOLONET",
    value: "13109",
    type: "common",
  },
  {
    label: "VENTABREN",
    value: "13114",
    type: "common",
  },
  {
    label: "EGUILLES",
    value: "13032",
    type: "common",
  },
  {
    label: "VELAUX",
    value: "13112",
    type: "common",
  },
  {
    label: "LA FARE-LES-OLIVIERS",
    value: "13037",
    type: "common",
  },
  {
    label: "COUDOUX",
    value: "13118",
    type: "common",
  },
  {
    label: "LANCON-PROVENCE",
    value: "13051",
    type: "common",
  },
  {
    label: "SAINT-CHAMAS",
    value: "13092",
    type: "common",
  },
  {
    label: "CORNILLON-CONFOUX",
    value: "13029",
    type: "common",
  },
  {
    label: "ISTRES",
    value: "13047",
    type: "common",
  },
  {
    label: "ARLES",
    value: "13004",
    type: "common",
  },
  {
    label: "SAINT-LAURENT-D'AIGOUZE",
    value: "30276",
    type: "common",
  },
  {
    label: "AIGUES-MORTES",
    value: "30003",
    type: "common",
  },
  {
    label: "LA GRANDE-MOTTE",
    value: "34344",
    type: "common",
  },
  {
    label: "MAUGUIO",
    value: "34154",
    type: "common",
  },
  {
    label: "PEROLS",
    value: "34198",
    type: "common",
  },
  {
    label: "LATTES",
    value: "34129",
    type: "common",
  },
  {
    label: "SAINT-JEAN-DE-VEDAS",
    value: "34270",
    type: "common",
  },
  {
    label: "SAUSSAN",
    value: "34295",
    type: "common",
  },
  {
    label: "PIGNAN",
    value: "34202",
    type: "common",
  },
  {
    label: "MURVIEL-LES-MONTPELLIER",
    value: "34179",
    type: "common",
  },
  {
    label: "LAVERUNE",
    value: "34134",
    type: "common",
  },
  {
    label: "COURNONTERRAL",
    value: "34088",
    type: "common",
  },
  {
    label: "AUMELAS",
    value: "34016",
    type: "common",
  },
  {
    label: "VENDEMIAN",
    value: "34328",
    type: "common",
  },
  {
    label: "SAINT-BAUZILLE-DE-LA-SYLVE",
    value: "34241",
    type: "common",
  },
  {
    label: "PUILACHER",
    value: "34222",
    type: "common",
  },
  {
    label: "LE POUGET",
    value: "34210",
    type: "common",
  },
  {
    label: "PLAISSAN",
    value: "34204",
    type: "common",
  },
  {
    label: "BELARGA",
    value: "34029",
    type: "common",
  },
  {
    label: "TRESSAN",
    value: "34313",
    type: "common",
  },
  {
    label: "NEBIAN",
    value: "34180",
    type: "common",
  },
  {
    label: "CANET",
    value: "34051",
    type: "common",
  },
  {
    label: "ASPIRAN",
    value: "34013",
    type: "common",
  },
  {
    label: "PERET",
    value: "34197",
    type: "common",
  },
  {
    label: "MOUREZE",
    value: "34175",
    type: "common",
  },
  {
    label: "LIEURAN-CABRIERES",
    value: "34138",
    type: "common",
  },
  {
    label: "CABRIERES",
    value: "34045",
    type: "common",
  },
  {
    label: "VALMASCLE",
    value: "34323",
    type: "common",
  },
  {
    label: "MONTESQUIEU",
    value: "34168",
    type: "common",
  },
  {
    label: "ROQUESSELS",
    value: "34234",
    type: "common",
  },
  {
    label: "PEZENES-LES-MINES",
    value: "34200",
    type: "common",
  },
  {
    label: "FOS",
    value: "34104",
    type: "common",
  },
  {
    label: "FAUGERES",
    value: "34096",
    type: "common",
  },
  {
    label: "HEREPIAN",
    value: "34119",
    type: "common",
  },
  {
    label: "LES AIRES",
    value: "34008",
    type: "common",
  },
  {
    label: "LE POUJOL-SUR-ORB",
    value: "34211",
    type: "common",
  },
  {
    label: "LAMALOU-LES-BAINS",
    value: "34126",
    type: "common",
  },
  {
    label: "COMBES",
    value: "34083",
    type: "common",
  },
  {
    label: "COLOMBIERES-SUR-ORB",
    value: "34080",
    type: "common",
  },
  {
    label: "SAINT-MARTIN-DE-L'ARCON",
    value: "34273",
    type: "common",
  },
  {
    label: "MONS",
    value: "34160",
    type: "common",
  },
  {
    label: "SAINT-VINCENT-D'OLARGUES",
    value: "34291",
    type: "common",
  },
  {
    label: "SAINT-JULIEN",
    value: "34271",
    type: "common",
  },
  {
    label: "FRAISSE-SUR-AGOUT",
    value: "34107",
    type: "common",
  },
  {
    label: "LA SALVETAT-SUR-AGOUT",
    value: "34293",
    type: "common",
  },
  {
    label: "LAMONTELARIE",
    value: "81134",
    type: "common",
  },
  {
    label: "LASFAILLADES",
    value: "81137",
    type: "common",
  },
  {
    label: "CAMBOUNES",
    value: "81053",
    type: "common",
  },
  {
    label: "SAINT-SALVY-DE-LA-BALME",
    value: "81269",
    type: "common",
  },
  {
    label: "BOISSEZON",
    value: "81034",
    type: "common",
  },
  {
    label: "VALDURENQUE",
    value: "81307",
    type: "common",
  },
  {
    label: "NOAILHAC",
    value: "81196",
    type: "common",
  },
  {
    label: "LAGARRIGUE",
    value: "81130",
    type: "common",
  },
  {
    label: "NAVES",
    value: "81195",
    type: "common",
  },
  {
    label: "SAIX",
    value: "81273",
    type: "common",
  },
  {
    label: "FREJEVILLE",
    value: "81098",
    type: "common",
  },
  {
    label: "SEMALENS",
    value: "81281",
    type: "common",
  },
  {
    label: "SAINT-GERMAIN-DES-PRES",
    value: "81251",
    type: "common",
  },
  {
    label: "CAMBOUNET-SUR-LE-SOR",
    value: "81054",
    type: "common",
  },
  {
    label: "PUYLAURENS",
    value: "81219",
    type: "common",
  },
  {
    label: "PRADES",
    value: "81212",
    type: "common",
  },
  {
    label: "APPELLE",
    value: "81015",
    type: "common",
  },
  {
    label: "LACROISILLE",
    value: "81127",
    type: "common",
  },
  {
    label: "CUQ-TOULZA",
    value: "81076",
    type: "common",
  },
  {
    label: "BERTRE",
    value: "81030",
    type: "common",
  },
  {
    label: "ALGANS",
    value: "81006",
    type: "common",
  },
  {
    label: "VEILHES",
    value: "81310",
    type: "common",
  },
  {
    label: "MAURENS-SCOPONT",
    value: "81162",
    type: "common",
  },
  {
    label: "CAMBON-LES-LAVAUR",
    value: "81050",
    type: "common",
  },
  {
    label: "LE FAGET",
    value: "31179",
    type: "common",
  },
  {
    label: "VILLENEUVE-LES-LAVAUR",
    value: "81318",
    type: "common",
  },
  {
    label: "VENDINE",
    value: "31571",
    type: "common",
  },
  {
    label: "SAUSSENS",
    value: "31534",
    type: "common",
  },
  {
    label: "PRUNET",
    value: "31441",
    type: "common",
  },
  {
    label: "MASCARVILLE",
    value: "31325",
    type: "common",
  },
  {
    label: "LOUBENS-LAURAGAIS",
    value: "31304",
    type: "common",
  },
  {
    label: "FRANCARVILLE",
    value: "31194",
    type: "common",
  },
  {
    label: "ALBIAC",
    value: "31006",
    type: "common",
  },
  {
    label: "VALLESVILLES",
    value: "31567",
    type: "common",
  },
  {
    label: "LANTA",
    value: "31271",
    type: "common",
  },
  {
    label: "BOURG-SAINT-BERNARD",
    value: "31082",
    type: "common",
  },
  {
    label: "SAINT-PIERRE-DE-LAGES",
    value: "31512",
    type: "common",
  },
  {
    label: "MONS",
    value: "31355",
    type: "common",
  },
  {
    label: "LAUZERVILLE",
    value: "31284",
    type: "common",
  },
  {
    label: "FLOURENS",
    value: "31184",
    type: "common",
  },
  {
    label: "DREMIL-LAFAGE",
    value: "31163",
    type: "common",
  },
  {
    label: "AIGREFEUILLE",
    value: "31003",
    type: "common",
  },
  {
    label: "QUINT-FONSEGRIVES",
    value: "31445",
    type: "common",
  },
  {
    label: "SAINT-ORENS-DE-GAMEVILLE",
    value: "31506",
    type: "common",
  },
  {
    label: "TOULOUSE",
    value: "31555",
    type: "common",
  },
  {
    label: "TOURNEFEUILLE",
    value: "31557",
    type: "common",
  },
  {
    label: "LA SALVETAT-SAINT-GILLES",
    value: "31526",
    type: "common",
  },
  {
    label: "LEGUEVIN",
    value: "31291",
    type: "common",
  },
  {
    label: "PUJAUDRAN",
    value: "32334",
    type: "common",
  },
  {
    label: "FONTENILLES",
    value: "31188",
    type: "common",
  },
  {
    label: "LIAS",
    value: "32210",
    type: "common",
  },
  {
    label: "AURADE",
    value: "32016",
    type: "common",
  },
  {
    label: "MARESTAING",
    value: "32234",
    type: "common",
  },
  {
    label: "CASTILLON-SAVES",
    value: "32090",
    type: "common",
  },
  {
    label: "MAURENS",
    value: "32247",
    type: "common",
  },
  {
    label: "FREGOUVILLE",
    value: "32134",
    type: "common",
  },
  {
    label: "SAINT-ANDRE",
    value: "32356",
    type: "common",
  },
  {
    label: "MONTIRON",
    value: "32288",
    type: "common",
  },
  {
    label: "LAHAS",
    value: "32182",
    type: "common",
  },
  {
    label: "AURIMONT",
    value: "32018",
    type: "common",
  },
  {
    label: "SAINT-CAPRAIS",
    value: "32467",
    type: "common",
  },
  {
    label: "BOULAUR",
    value: "32061",
    type: "common",
  },
  {
    label: "BEDECHAN",
    value: "32040",
    type: "common",
  },
  {
    label: "HAULIES",
    value: "32153",
    type: "common",
  },
  {
    label: "CASTELNAU-BARBARENS",
    value: "32076",
    type: "common",
  },
  {
    label: "ORBESSAN",
    value: "32300",
    type: "common",
  },
  {
    label: "LASSEUBE-PROPRE",
    value: "32201",
    type: "common",
  },
  {
    label: "BOUCAGNERES",
    value: "32060",
    type: "common",
  },
  {
    label: "AUTERIVE",
    value: "32019",
    type: "common",
  },
  {
    label: "SAINT-JEAN-LE-COMTAL",
    value: "32381",
    type: "common",
  },
  {
    label: "LABEJAN",
    value: "32172",
    type: "common",
  },
  {
    label: "DURBAN",
    value: "32118",
    type: "common",
  },
  {
    label: "MOUCHES",
    value: "32293",
    type: "common",
  },
  {
    label: "LAMAZERE",
    value: "32187",
    type: "common",
  },
  {
    label: "L'ISLE-DE-NOE",
    value: "32159",
    type: "common",
  },
  {
    label: "ESTIPOUY",
    value: "32128",
    type: "common",
  },
  {
    label: "POUYLEBON",
    value: "32326",
    type: "common",
  },
  {
    label: "MONTESQUIOU",
    value: "32285",
    type: "common",
  },
  {
    label: "SCIEURAC-ET-FLOURES",
    value: "32422",
    type: "common",
  },
  {
    label: "MASCARAS",
    value: "32240",
    type: "common",
  },
  {
    label: "BASSOUES",
    value: "32032",
    type: "common",
  },
  {
    label: "ARMOUS-ET-CAU",
    value: "32009",
    type: "common",
  },
  {
    label: "TOURDUN",
    value: "32450",
    type: "common",
  },
  {
    label: "JUILLAC",
    value: "32164",
    type: "common",
  },
  {
    label: "COURTIES",
    value: "32111",
    type: "common",
  },
  {
    label: "TIESTE-URAGNOUX",
    value: "32445",
    type: "common",
  },
  {
    label: "SAINT-AUNIX-LENGROS",
    value: "32362",
    type: "common",
  },
  {
    label: "LADEVEZE-RIVIERE",
    value: "32174",
    type: "common",
  },
  {
    label: "JU-BELLOC",
    value: "32163",
    type: "common",
  },
  {
    label: "SOUBLECAUSE",
    value: "65432",
    type: "common",
  },
  {
    label: "MADIRAN",
    value: "65296",
    type: "common",
  },
  {
    label: "HERES",
    value: "65219",
    type: "common",
  },
  {
    label: "CASTELNAU-RIVIERE-BASSE",
    value: "65130",
    type: "common",
  },
  {
    label: "MONT-DISSE",
    value: "64401",
    type: "common",
  },
  {
    label: "CROUSEILLES",
    value: "64196",
    type: "common",
  },
  {
    label: "AYDIE",
    value: "64084",
    type: "common",
  },
  {
    label: "AURIONS-IDERNES",
    value: "64079",
    type: "common",
  },
  {
    label: "AUBOUS",
    value: "64074",
    type: "common",
  },
  {
    label: "ARROSES",
    value: "64056",
    type: "common",
  },
  {
    label: "TADOUSSE-USSAU",
    value: "64532",
    type: "common",
  },
  {
    label: "SAINT-JEAN-POUDGE",
    value: "64486",
    type: "common",
  },
  {
    label: "PORTET",
    value: "64455",
    type: "common",
  },
  {
    label: "MONCLA",
    value: "64392",
    type: "common",
  },
  {
    label: "MASCARAAS-HARON",
    value: "64366",
    type: "common",
  },
  {
    label: "DIUSSE",
    value: "64199",
    type: "common",
  },
  {
    label: "CONCHEZ-DE-BEARN",
    value: "64192",
    type: "common",
  },
  {
    label: "CASTETPUGON",
    value: "64180",
    type: "common",
  },
  {
    label: "GARLIN",
    value: "64233",
    type: "common",
  },
  {
    label: "BALIRACQ-MAUMUSSON",
    value: "64090",
    type: "common",
  },
  {
    label: "POURSIUGUES-BOUCOUE",
    value: "64457",
    type: "common",
  },
  {
    label: "POULIACQ",
    value: "64456",
    type: "common",
  },
  {
    label: "COUBLUCQ",
    value: "64195",
    type: "common",
  },
  {
    label: "BOUEILH-BOUEILHO-LASQUE",
    value: "64141",
    type: "common",
  },
  {
    label: "PIMBO",
    value: "40226",
    type: "common",
  },
  {
    label: "LAURET",
    value: "40148",
    type: "common",
  },
  {
    label: "VIGNES",
    value: "64557",
    type: "common",
  },
  {
    label: "MALAUSSANNE",
    value: "64365",
    type: "common",
  },
  {
    label: "CABIDOS",
    value: "64158",
    type: "common",
  },
  {
    label: "ARZACQ-ARRAZIGUET",
    value: "64063",
    type: "common",
  },
  {
    label: "PHILONDENX",
    value: "40225",
    type: "common",
  },
  {
    label: "PIETS-PLASENCE-MOUSTROU",
    value: "64447",
    type: "common",
  },
  {
    label: "MONTAGUT",
    value: "64397",
    type: "common",
  },
  {
    label: "CASTEIDE-CANDAU",
    value: "64172",
    type: "common",
  },
  {
    label: "ARGET",
    value: "64044",
    type: "common",
  },
  {
    label: "MONGET",
    value: "40189",
    type: "common",
  },
  {
    label: "SAINT-MEDARD",
    value: "64491",
    type: "common",
  },
  {
    label: "LABEYRIE",
    value: "64295",
    type: "common",
  },
  {
    label: "PEYRE",
    value: "40223",
    type: "common",
  },
  {
    label: "CASTELNER",
    value: "40073",
    type: "common",
  },
  {
    label: "BASSERCLES",
    value: "40027",
    type: "common",
  },
  {
    label: "SAULT-DE-NAVAILLES",
    value: "64510",
    type: "common",
  },
  {
    label: "SALLESPISSE",
    value: "64501",
    type: "common",
  },
  {
    label: "LACADEE",
    value: "64296",
    type: "common",
  },
  {
    label: "BONNEGARDE",
    value: "40047",
    type: "common",
  },
  {
    label: "BEYRIES",
    value: "40041",
    type: "common",
  },
  {
    label: "BONNUT",
    value: "64135",
    type: "common",
  },
  {
    label: "SAINT-GIRONS-EN-BEARN",
    value: "64479",
    type: "common",
  },
  {
    label: "SAINT-BOES",
    value: "64471",
    type: "common",
  },
  {
    label: "BAIGTS-DE-BEARN",
    value: "64087",
    type: "common",
  },
  {
    label: "RAMOUS",
    value: "64462",
    type: "common",
  },
  {
    label: "PUYOO",
    value: "64461",
    type: "common",
  },
  {
    label: "OSSAGES",
    value: "40214",
    type: "common",
  },
  {
    label: "LAHONTAN",
    value: "64305",
    type: "common",
  },
  {
    label: "SAINT-CRICQ-DU-GAVE",
    value: "40254",
    type: "common",
  },
  {
    label: "LABATUT",
    value: "40132",
    type: "common",
  },
  {
    label: "LEREN",
    value: "64334",
    type: "common",
  },
  {
    label: "SORDE-L'ABBAYE",
    value: "40306",
    type: "common",
  },
  {
    label: "OEYREGAVE",
    value: "40206",
    type: "common",
  },
  {
    label: "SAMES",
    value: "64502",
    type: "common",
  },
  {
    label: "HASTINGUES",
    value: "40120",
    type: "common",
  },
  {
    label: "GUICHE",
    value: "64250",
    type: "common",
  },
  {
    label: "SAINTE-MARIE-DE-GOSSE",
    value: "40271",
    type: "common",
  },
  {
    label: "SAINT-LAURENT-DE-GOSSE",
    value: "40268",
    type: "common",
  },
  {
    label: "SAINT-BARTHELEMY",
    value: "40251",
    type: "common",
  },
  {
    label: "BIAUDOS",
    value: "40044",
    type: "common",
  },
  {
    label: "SAINT-MARTIN-DE-SEIGNANX",
    value: "40273",
    type: "common",
  },
  {
    label: "BOUCAU",
    value: "64140",
    type: "common",
  },
  {
    label: "ANGLET",
    value: "64024",
    type: "common",
  },
  {
    label: "TARNOS",
    value: "40312",
    type: "common",
  },
  {
    label: "FREJUS",
    value: "83061",
    type: "common",
  },
  {
    label: "ROQUEBRUNE-SUR-ARGENS",
    value: "83107",
    type: "common",
  },
  {
    label: "LES ARCS",
    value: "83004",
    type: "common",
  },
  {
    label: "TARADEAU",
    value: "83134",
    type: "common",
  },
  {
    label: "LORGUES",
    value: "83072",
    type: "common",
  },
  {
    label: "LE THORONET",
    value: "83136",
    type: "common",
  },
  {
    label: "CARCES",
    value: "83032",
    type: "common",
  },
  {
    label: "CABASSE",
    value: "83026",
    type: "common",
  },
  {
    label: "VINS-SUR-CARAMY",
    value: "83151",
    type: "common",
  },
  {
    label: "MONTFORT-SUR-ARGENS",
    value: "83083",
    type: "common",
  },
  {
    label: "LE VAL",
    value: "83143",
    type: "common",
  },
  {
    label: "BRAS",
    value: "83021",
    type: "common",
  },
  {
    label: "SAINT-MAXIMIN-LA-SAINTE-BAUME",
    value: "83116",
    type: "common",
  },
  {
    label: "POURCIEUX",
    value: "83096",
    type: "common",
  },
  {
    label: "POURRIERES",
    value: "83097",
    type: "common",
  },
  {
    label: "ROUSSET",
    value: "13087",
    type: "common",
  },
  {
    label: "FUVEAU",
    value: "13040",
    type: "common",
  },
  {
    label: "CHATEAUNEUF-LE-ROUGE",
    value: "13025",
    type: "common",
  },
  {
    label: "MEYREUIL",
    value: "13060",
    type: "common",
  },
  {
    label: "AIX-EN-PROVENCE",
    value: "13001",
    type: "common",
  },
  {
    label: "ROGNAC",
    value: "13081",
    type: "common",
  },
  {
    label: "BERRE-L'ETANG",
    value: "13014",
    type: "common",
  },
  {
    label: "SAINTES-MARIES-DE-LA-MER",
    value: "13096",
    type: "common",
  },
  {
    label: "LE GRAU-DU-ROI",
    value: "30133",
    type: "common",
  },
  {
    label: "PALAVAS-LES-FLOTS",
    value: "34192",
    type: "common",
  },
  {
    label: "VILLENEUVE-LES-MAGUELONE",
    value: "34337",
    type: "common",
  },
  {
    label: "FABREGUES",
    value: "34095",
    type: "common",
  },
  {
    label: "MIREVAL",
    value: "34159",
    type: "common",
  },
  {
    label: "COURNONSEC",
    value: "34087",
    type: "common",
  },
  {
    label: "POUSSAN",
    value: "34213",
    type: "common",
  },
  {
    label: "MONTBAZIN",
    value: "34165",
    type: "common",
  },
  {
    label: "GIGEAN",
    value: "34113",
    type: "common",
  },
  {
    label: "VILLEVEYRAC",
    value: "34341",
    type: "common",
  },
  {
    label: "SAINT-PONS-DE-MAUCHIENS",
    value: "34285",
    type: "common",
  },
  {
    label: "SAINT-PARGOIRE",
    value: "34281",
    type: "common",
  },
  {
    label: "CAMPAGNAN",
    value: "34047",
    type: "common",
  },
  {
    label: "USCLAS-D'HERAULT",
    value: "34315",
    type: "common",
  },
  {
    label: "PAULHAN",
    value: "34194",
    type: "common",
  },
  {
    label: "NIZAS",
    value: "34184",
    type: "common",
  },
  {
    label: "LEZIGNAN-LA-CEBE",
    value: "34136",
    type: "common",
  },
  {
    label: "CAZOULS-D'HERAULT",
    value: "34068",
    type: "common",
  },
  {
    label: "ADISSAN",
    value: "34002",
    type: "common",
  },
  {
    label: "NEFFIES",
    value: "34181",
    type: "common",
  },
  {
    label: "FONTES",
    value: "34103",
    type: "common",
  },
  {
    label: "CAUX",
    value: "34063",
    type: "common",
  },
  {
    label: "VAILHAN",
    value: "34319",
    type: "common",
  },
  {
    label: "ROUJAN",
    value: "34237",
    type: "common",
  },
  {
    label: "GABIAN",
    value: "34109",
    type: "common",
  },
  {
    label: "LAURENS",
    value: "34130",
    type: "common",
  },
  {
    label: "FOUZILHON",
    value: "34105",
    type: "common",
  },
  {
    label: "CAUSSINIOJOULS",
    value: "34062",
    type: "common",
  },
  {
    label: "CABREROLLES",
    value: "34044",
    type: "common",
  },
  {
    label: "AUTIGNAC",
    value: "34018",
    type: "common",
  },
  {
    label: "SAINT-NAZAIRE-DE-LADAREZ",
    value: "34279",
    type: "common",
  },
  {
    label: "VIEUSSAN",
    value: "34334",
    type: "common",
  },
  {
    label: "ROQUEBRUN",
    value: "34232",
    type: "common",
  },
  {
    label: "BERLOU",
    value: "34030",
    type: "common",
  },
  {
    label: "SAINT-ETIENNE-D'ALBAGNAN",
    value: "34250",
    type: "common",
  },
  {
    label: "OLARGUES",
    value: "34187",
    type: "common",
  },
  {
    label: "RIOLS",
    value: "34229",
    type: "common",
  },
  {
    label: "PREMIAN",
    value: "34219",
    type: "common",
  },
  {
    label: "LE SOULIE",
    value: "34305",
    type: "common",
  },
  {
    label: "ROUAIROUX",
    value: "81231",
    type: "common",
  },
  {
    label: "ANGLES",
    value: "81014",
    type: "common",
  },
  {
    label: "LE VINTROU",
    value: "81321",
    type: "common",
  },
  {
    label: "SAINT-AMANS-VALTORET",
    value: "81239",
    type: "common",
  },
  {
    label: "LE RIALET",
    value: "81223",
    type: "common",
  },
  {
    label: "PONT-DE-LARN",
    value: "81209",
    type: "common",
  },
  {
    label: "AUSSILLON",
    value: "81021",
    type: "common",
  },
  {
    label: "PAYRIN-AUGMONTEL",
    value: "81204",
    type: "common",
  },
  {
    label: "CAUCALIERES",
    value: "81066",
    type: "common",
  },
  {
    label: "SAINT-AFFRIQUE-LES-MONTAGNES",
    value: "81235",
    type: "common",
  },
  {
    label: "VIVIERS-LES-MONTAGNES",
    value: "81325",
    type: "common",
  },
  {
    label: "SOUAL",
    value: "81289",
    type: "common",
  },
  {
    label: "SAINT-AVIT",
    value: "81242",
    type: "common",
  },
  {
    label: "LESCOUT",
    value: "81143",
    type: "common",
  },
  {
    label: "LEMPAUT",
    value: "81142",
    type: "common",
  },
  {
    label: "LAGARDIOLLE",
    value: "81129",
    type: "common",
  },
  {
    label: "SAINT-SERNIN-LES-LAVAUR",
    value: "81270",
    type: "common",
  },
  {
    label: "POUDIS",
    value: "81210",
    type: "common",
  },
  {
    label: "PALLEVILLE",
    value: "81200",
    type: "common",
  },
  {
    label: "BLAN",
    value: "81032",
    type: "common",
  },
  {
    label: "PUECHOURSI",
    value: "81214",
    type: "common",
  },
  {
    label: "PECHAUDIER",
    value: "81205",
    type: "common",
  },
  {
    label: "MOUZENS",
    value: "81189",
    type: "common",
  },
  {
    label: "MONTGEY",
    value: "81179",
    type: "common",
  },
  {
    label: "AGUTS",
    value: "81001",
    type: "common",
  },
  {
    label: "SAINT-JULIA",
    value: "31491",
    type: "common",
  },
  {
    label: "LA SALVETAT-LAURAGAIS",
    value: "31527",
    type: "common",
  },
  {
    label: "LE CABANIAL",
    value: "31097",
    type: "common",
  },
  {
    label: "AURIAC-SUR-VENDINELLE",
    value: "31026",
    type: "common",
  },
  {
    label: "MAUREVILLE",
    value: "31331",
    type: "common",
  },
  {
    label: "CARAMAN",
    value: "31106",
    type: "common",
  },
  {
    label: "TARABEL",
    value: "31551",
    type: "common",
  },
  {
    label: "PRESERVILLE",
    value: "31439",
    type: "common",
  },
  {
    label: "CARAGOUDES",
    value: "31105",
    type: "common",
  },
  {
    label: "AURIN",
    value: "31029",
    type: "common",
  },
  {
    label: "SAINTE-FOY-D'AIGREFEUILLE",
    value: "31480",
    type: "common",
  },
  {
    label: "ODARS",
    value: "31402",
    type: "common",
  },
  {
    label: "ESCALQUENS",
    value: "31169",
    type: "common",
  },
  {
    label: "BELBERAUD",
    value: "31057",
    type: "common",
  },
  {
    label: "AUZIELLE",
    value: "31036",
    type: "common",
  },
  {
    label: "RAMONVILLE-SAINT-AGNE",
    value: "31446",
    type: "common",
  },
  {
    label: "POMPERTUZAT",
    value: "31429",
    type: "common",
  },
  {
    label: "PECHABOU",
    value: "31409",
    type: "common",
  },
  {
    label: "MERVILLA",
    value: "31340",
    type: "common",
  },
  {
    label: "LABEGE",
    value: "31254",
    type: "common",
  },
  {
    label: "CASTANET-TOLOSAN",
    value: "31113",
    type: "common",
  },
  {
    label: "AUZEVILLE-TOLOSANE",
    value: "31035",
    type: "common",
  },
  {
    label: "PORTET-SUR-GARONNE",
    value: "31433",
    type: "common",
  },
  {
    label: "PINSAGUEL",
    value: "31420",
    type: "common",
  },
  {
    label: "VIGOULET-AUZIL",
    value: "31578",
    type: "common",
  },
  {
    label: "VIEILLE-TOULOUSE",
    value: "31575",
    type: "common",
  },
  {
    label: "PECHBUSQUE",
    value: "31411",
    type: "common",
  },
  {
    label: "LACROIX-FALGARDE",
    value: "31259",
    type: "common",
  },
  {
    label: "ROQUETTES",
    value: "31460",
    type: "common",
  },
  {
    label: "ROQUES",
    value: "31458",
    type: "common",
  },
  {
    label: "VILLENEUVE-TOLOSANE",
    value: "31588",
    type: "common",
  },
  {
    label: "CUGNAUX",
    value: "31157",
    type: "common",
  },
  {
    label: "FROUZINS",
    value: "31203",
    type: "common",
  },
  {
    label: "SEYSSES",
    value: "31547",
    type: "common",
  },
  {
    label: "PLAISANCE-DU-TOUCH",
    value: "31424",
    type: "common",
  },
  {
    label: "FONSORBES",
    value: "31187",
    type: "common",
  },
  {
    label: "SAINT-LYS",
    value: "31499",
    type: "common",
  },
  {
    label: "BONREPOS-SUR-AUSSONNELLE",
    value: "31075",
    type: "common",
  },
  {
    label: "SAINT-THOMAS",
    value: "31518",
    type: "common",
  },
  {
    label: "SAIGUEDE",
    value: "31466",
    type: "common",
  },
  {
    label: "EMPEAUX",
    value: "31166",
    type: "common",
  },
  {
    label: "BRAGAYRAC",
    value: "31087",
    type: "common",
  },
  {
    label: "SEYSSES-SAVES",
    value: "32432",
    type: "common",
  },
  {
    label: "POMPIAC",
    value: "32322",
    type: "common",
  },
  {
    label: "NIZAS",
    value: "32295",
    type: "common",
  },
  {
    label: "ENDOUFIELLE",
    value: "32121",
    type: "common",
  },
  {
    label: "SAMATAN",
    value: "32410",
    type: "common",
  },
  {
    label: "NOILHAN",
    value: "32297",
    type: "common",
  },
  {
    label: "LABASTIDE-SAVES",
    value: "32171",
    type: "common",
  },
  {
    label: "CAZAUX-SAVES",
    value: "32098",
    type: "common",
  },
  {
    label: "SAINT-SOULAN",
    value: "32407",
    type: "common",
  },
  {
    label: "POLASTRON",
    value: "32321",
    type: "common",
  },
  {
    label: "MONTAMAT",
    value: "32277",
    type: "common",
  },
  {
    label: "BEZERIL",
    value: "32051",
    type: "common",
  },
  {
    label: "TIRENT-PONTEJAC",
    value: "32447",
    type: "common",
  },
  {
    label: "SARAMON",
    value: "32412",
    type: "common",
  },
  {
    label: "SAINT-MARTIN-GIMOIS",
    value: "32392",
    type: "common",
  },
  {
    label: "SAINT-ELIX-D'ASTARAC",
    value: "32374",
    type: "common",
  },
  {
    label: "MONGAUSY",
    value: "32270",
    type: "common",
  },
  {
    label: "SEMEZIES-CACHAN",
    value: "32428",
    type: "common",
  },
  {
    label: "LARTIGUE",
    value: "32198",
    type: "common",
  },
  {
    label: "LAMAGUERE",
    value: "32186",
    type: "common",
  },
  {
    label: "FAGET-ABBATIAL",
    value: "32130",
    type: "common",
  },
  {
    label: "TRAVERSERES",
    value: "32454",
    type: "common",
  },
  {
    label: "SEISSAN",
    value: "32426",
    type: "common",
  },
  {
    label: "SANSAN",
    value: "32411",
    type: "common",
  },
  {
    label: "ORNEZAN",
    value: "32302",
    type: "common",
  },
  {
    label: "MONFERRAN-PLAVES",
    value: "32267",
    type: "common",
  },
  {
    label: "LOUBERSAN",
    value: "32215",
    type: "common",
  },
  {
    label: "SAINT-MEDARD",
    value: "32394",
    type: "common",
  },
  {
    label: "MIRAMONT-D'ASTARAC",
    value: "32254",
    type: "common",
  },
  {
    label: "IDRAC-RESPAILLES",
    value: "32156",
    type: "common",
  },
  {
    label: "SAINT-MAUR",
    value: "32393",
    type: "common",
  },
  {
    label: "SAINT-MARTIN",
    value: "32389",
    type: "common",
  },
  {
    label: "MONCLAR-SUR-LOSSE",
    value: "32265",
    type: "common",
  },
  {
    label: "MIRANDE",
    value: "32256",
    type: "common",
  },
  {
    label: "TILLAC",
    value: "32446",
    type: "common",
  },
  {
    label: "SAINT-CHRISTAUD",
    value: "32367",
    type: "common",
  },
  {
    label: "PALLANNE",
    value: "32303",
    type: "common",
  },
  {
    label: "MARSEILLAN",
    value: "32238",
    type: "common",
  },
  {
    label: "BARS",
    value: "32030",
    type: "common",
  },
  {
    label: "RICOURT",
    value: "32342",
    type: "common",
  },
  {
    label: "MONLEZUN",
    value: "32273",
    type: "common",
  },
  {
    label: "LAVERAET",
    value: "32205",
    type: "common",
  },
  {
    label: "SAUVETERRE",
    value: "65412",
    type: "common",
  },
  {
    label: "SAINT-JUSTIN",
    value: "32383",
    type: "common",
  },
  {
    label: "MARCIAC",
    value: "32233",
    type: "common",
  },
  {
    label: "ARMENTIEUX",
    value: "32008",
    type: "common",
  },
  {
    label: "MAUBOURGUET",
    value: "65304",
    type: "common",
  },
  {
    label: "LABATUT-RIVIERE",
    value: "65240",
    type: "common",
  },
  {
    label: "ESTIRAC",
    value: "65174",
    type: "common",
  },
  {
    label: "AURIEBAT",
    value: "65049",
    type: "common",
  },
  {
    label: "LADEVEZE-VILLE",
    value: "32175",
    type: "common",
  },
  {
    label: "VILLEFRANQUE",
    value: "65472",
    type: "common",
  },
  {
    label: "SOMBRUN",
    value: "65429",
    type: "common",
  },
  {
    label: "LASCAZERES",
    value: "65264",
    type: "common",
  },
  {
    label: "HAGEDET",
    value: "65215",
    type: "common",
  },
  {
    label: "CAUSSADE-RIVIERE",
    value: "65137",
    type: "common",
  },
  {
    label: "MONPEZAT",
    value: "64394",
    type: "common",
  },
  {
    label: "MONCAUP",
    value: "64390",
    type: "common",
  },
  {
    label: "BETRACQ",
    value: "64118",
    type: "common",
  },
  {
    label: "SEMEACQ-BLACHON",
    value: "64517",
    type: "common",
  },
  {
    label: "LASSERRE",
    value: "64323",
    type: "common",
  },
  {
    label: "CORBERE-ABERES",
    value: "64193",
    type: "common",
  },
  {
    label: "CASTILLON (CANTON DE LEMBEYE)",
    value: "64182",
    type: "common",
  },
  {
    label: "ARRICAU-BORDES",
    value: "64052",
    type: "common",
  },
  {
    label: "VIALER",
    value: "64552",
    type: "common",
  },
  {
    label: "LESPIELLE",
    value: "64337",
    type: "common",
  },
  {
    label: "LANNECAUBE",
    value: "64311",
    type: "common",
  },
  {
    label: "LALONGUE",
    value: "64307",
    type: "common",
  },
  {
    label: "GAYON",
    value: "64236",
    type: "common",
  },
  {
    label: "CADILLON",
    value: "64159",
    type: "common",
  },
  {
    label: "BUROSSE-MENDOUSSE",
    value: "64153",
    type: "common",
  },
  {
    label: "TARON-SADIRAC-VIELLENAVE",
    value: "64534",
    type: "common",
  },
  {
    label: "RIBARROUY",
    value: "64464",
    type: "common",
  },
  {
    label: "MOUHOUS",
    value: "64408",
    type: "common",
  },
  {
    label: "MIOSSENS-LANUSSE",
    value: "64385",
    type: "common",
  },
  {
    label: "LALONQUETTE",
    value: "64308",
    type: "common",
  },
  {
    label: "CLARACQ",
    value: "64190",
    type: "common",
  },
  {
    label: "CARRERE",
    value: "64167",
    type: "common",
  },
  {
    label: "THEZE",
    value: "64536",
    type: "common",
  },
  {
    label: "MERACQ",
    value: "64380",
    type: "common",
  },
  {
    label: "LEME",
    value: "64332",
    type: "common",
  },
  {
    label: "GARLEDE-MONDEBAT",
    value: "64232",
    type: "common",
  },
  {
    label: "AUGA",
    value: "64077",
    type: "common",
  },
  {
    label: "SEBY",
    value: "64514",
    type: "common",
  },
  {
    label: "MIALOS",
    value: "64383",
    type: "common",
  },
  {
    label: "LOUVIGNY",
    value: "64355",
    type: "common",
  },
  {
    label: "LONCON",
    value: "64347",
    type: "common",
  },
  {
    label: "LARREULE",
    value: "64318",
    type: "common",
  },
  {
    label: "FICHOUS-RIUMAYOU",
    value: "64226",
    type: "common",
  },
  {
    label: "UZAN",
    value: "64548",
    type: "common",
  },
  {
    label: "MORLANNE",
    value: "64406",
    type: "common",
  },
  {
    label: "GEUS-D'ARZACQ",
    value: "64243",
    type: "common",
  },
  {
    label: "GAROS",
    value: "64234",
    type: "common",
  },
  {
    label: "BOUILLON",
    value: "64143",
    type: "common",
  },
  {
    label: "ARNOS",
    value: "64048",
    type: "common",
  },
  {
    label: "POMPS",
    value: "64450",
    type: "common",
  },
  {
    label: "HAGETAUBIN",
    value: "64254",
    type: "common",
  },
  {
    label: "CASTILLON (CANTON D'ARTHEZ-DE-BEARN)",
    value: "64181",
    type: "common",
  },
  {
    label: "ARTHEZ-DE-BEARN",
    value: "64057",
    type: "common",
  },
  {
    label: "SARPOURENX",
    value: "64505",
    type: "common",
  },
  {
    label: "MESPLEDE",
    value: "64382",
    type: "common",
  },
  {
    label: "BALANSUN",
    value: "64088",
    type: "common",
  },
  {
    label: "ARGAGNON",
    value: "64042",
    type: "common",
  },
  {
    label: "ORTHEZ",
    value: "64430",
    type: "common",
  },
  {
    label: "LAA-MONDRANS",
    value: "64286",
    type: "common",
  },
  {
    label: "CASTETIS",
    value: "64177",
    type: "common",
  },
  {
    label: "BIRON",
    value: "64131",
    type: "common",
  },
  {
    label: "SALLES-MONGISCARD",
    value: "64500",
    type: "common",
  },
  {
    label: "LANNEPLAA",
    value: "64312",
    type: "common",
  },
  {
    label: "BERENX",
    value: "64112",
    type: "common",
  },
  {
    label: "SALIES-DE-BEARN",
    value: "64499",
    type: "common",
  },
  {
    label: "BELLOCQ",
    value: "64108",
    type: "common",
  },
  {
    label: "SAINT-DOS",
    value: "64474",
    type: "common",
  },
  {
    label: "ESCOS",
    value: "64205",
    type: "common",
  },
  {
    label: "CASTAGNEDE",
    value: "64170",
    type: "common",
  },
  {
    label: "CARRESSE-CASSABER",
    value: "64168",
    type: "common",
  },
  {
    label: "AUTERRIVE",
    value: "64082",
    type: "common",
  },
  {
    label: "SAINT-PE-DE-LEREN",
    value: "64494",
    type: "common",
  },
  {
    label: "LABASTIDE-VILLEFRANCHE",
    value: "64291",
    type: "common",
  },
  {
    label: "CAME",
    value: "64161",
    type: "common",
  },
  {
    label: "ARANCOU",
    value: "64031",
    type: "common",
  },
  {
    label: "BIDACHE",
    value: "64123",
    type: "common",
  },
  {
    label: "BARDOS",
    value: "64094",
    type: "common",
  },
  {
    label: "URT",
    value: "64546",
    type: "common",
  },
  {
    label: "URCUIT",
    value: "64540",
    type: "common",
  },
  {
    label: "BRISCOUS",
    value: "64147",
    type: "common",
  },
  {
    label: "VILLEFRANQUE",
    value: "64558",
    type: "common",
  },
  {
    label: "SAINT-PIERRE-D'IRUBE",
    value: "64496",
    type: "common",
  },
  {
    label: "MOUGUERRE",
    value: "64407",
    type: "common",
  },
  {
    label: "LAHONCE",
    value: "64304",
    type: "common",
  },
  {
    label: "BAYONNE",
    value: "64102",
    type: "common",
  },
  {
    label: "BASSUSSARRY",
    value: "64100",
    type: "common",
  },
  {
    label: "ARCANGUES",
    value: "64038",
    type: "common",
  },
  {
    label: "BIDART",
    value: "64125",
    type: "common",
  },
  {
    label: "BIARRITZ",
    value: "64122",
    type: "common",
  },
  {
    label: "SAINTE-MAXIME",
    value: "83115",
    type: "common",
  },
  {
    label: "VIDAUBAN",
    value: "83148",
    type: "common",
  },
  {
    label: "LE CANNET-DES-MAURES",
    value: "83031",
    type: "common",
  },
  {
    label: "LE LUC",
    value: "83073",
    type: "common",
  },
  {
    label: "FLASSANS-SUR-ISSOLE",
    value: "83057",
    type: "common",
  },
  {
    label: "CAMPS-LA-SOURCE",
    value: "83030",
    type: "common",
  },
  {
    label: "BRIGNOLES",
    value: "83023",
    type: "common",
  },
  {
    label: "LA CELLE",
    value: "83037",
    type: "common",
  },
  {
    label: "TOURVES",
    value: "83140",
    type: "common",
  },
  {
    label: "NANS-LES-PINS",
    value: "83087",
    type: "common",
  },
  {
    label: "TRETS",
    value: "13110",
    type: "common",
  },
  {
    label: "PEYNIER",
    value: "13072",
    type: "common",
  },
  {
    label: "LA BOUILLADISSE",
    value: "13016",
    type: "common",
  },
  {
    label: "SAINT-SAVOURNIN",
    value: "13101",
    type: "common",
  },
  {
    label: "GREASQUE",
    value: "13046",
    type: "common",
  },
  {
    label: "CADOLIVE",
    value: "13020",
    type: "common",
  },
  {
    label: "BELCODENE",
    value: "13013",
    type: "common",
  },
  {
    label: "SIMIANE-COLLONGUE",
    value: "13107",
    type: "common",
  },
  {
    label: "MIMET",
    value: "13062",
    type: "common",
  },
  {
    label: "GARDANNE",
    value: "13041",
    type: "common",
  },
  {
    label: "SEPTEMES-LES-VALLONS",
    value: "13106",
    type: "common",
  },
  {
    label: "BOUC-BEL-AIR",
    value: "13015",
    type: "common",
  },
  {
    label: "LES PENNES-MIRABEAU",
    value: "13071",
    type: "common",
  },
  {
    label: "CABRIES",
    value: "13019",
    type: "common",
  },
  {
    label: "VITROLLES",
    value: "13117",
    type: "common",
  },
  {
    label: "SAINT-VICTORET",
    value: "13102",
    type: "common",
  },
  {
    label: "MARIGNANE",
    value: "13054",
    type: "common",
  },
  {
    label: "SAINT-MITRE-LES-REMPARTS",
    value: "13098",
    type: "common",
  },
  {
    label: "PORT-DE-BOUC",
    value: "13077",
    type: "common",
  },
  {
    label: "FOS-SUR-MER",
    value: "13039",
    type: "common",
  },
  {
    label: "PORT-SAINT-LOUIS-DU-RHONE",
    value: "13078",
    type: "common",
  },
  {
    label: "VIC-LA-GARDIOLE",
    value: "34333",
    type: "common",
  },
  {
    label: "FRONTIGNAN",
    value: "34108",
    type: "common",
  },
  {
    label: "BOUZIGUES",
    value: "34039",
    type: "common",
  },
  {
    label: "BALARUC-LE-VIEUX",
    value: "34024",
    type: "common",
  },
  {
    label: "BALARUC-LES-BAINS",
    value: "34023",
    type: "common",
  },
  {
    label: "MEZE",
    value: "34157",
    type: "common",
  },
  {
    label: "LOUPIAN",
    value: "34143",
    type: "common",
  },
  {
    label: "MONTAGNAC",
    value: "34162",
    type: "common",
  },
  {
    label: "PEZENAS",
    value: "34199",
    type: "common",
  },
  {
    label: "CASTELNAU-DE-GUERS",
    value: "34056",
    type: "common",
  },
  {
    label: "AUMES",
    value: "34017",
    type: "common",
  },
  {
    label: "TOURBES",
    value: "34311",
    type: "common",
  },
  {
    label: "ALIGNAN-DU-VENT",
    value: "34009",
    type: "common",
  },
  {
    label: "POUZOLLES",
    value: "34214",
    type: "common",
  },
  {
    label: "MARGON",
    value: "34149",
    type: "common",
  },
  {
    label: "ESPONDEILHAN",
    value: "34094",
    type: "common",
  },
  {
    label: "COULOBRES",
    value: "34085",
    type: "common",
  },
  {
    label: "ABEILHAN",
    value: "34001",
    type: "common",
  },
  {
    label: "SAINT-GENIES-DE-FONTEDIT",
    value: "34258",
    type: "common",
  },
  {
    label: "PUISSALICON",
    value: "34224",
    type: "common",
  },
  {
    label: "PUIMISSON",
    value: "34223",
    type: "common",
  },
  {
    label: "PAILHES",
    value: "34191",
    type: "common",
  },
  {
    label: "MAGALAS",
    value: "34147",
    type: "common",
  },
  {
    label: "MURVIEL-LES-BEZIERS",
    value: "34178",
    type: "common",
  },
  {
    label: "CAUSSES-ET-VEYRAN",
    value: "34061",
    type: "common",
  },
  {
    label: "CESSENON-SUR-ORB",
    value: "34074",
    type: "common",
  },
  {
    label: "PRADES-SUR-VERNAZOBRE",
    value: "34218",
    type: "common",
  },
  {
    label: "FERRIERES-POUSSAROU",
    value: "34100",
    type: "common",
  },
  {
    label: "BABEAU-BOULDOUX",
    value: "34021",
    type: "common",
  },
  {
    label: "PARDAILHAN",
    value: "34193",
    type: "common",
  },
  {
    label: "SAINT-PONS-DE-THOMIERES",
    value: "34284",
    type: "common",
  },
  {
    label: "COURNIOU",
    value: "34086",
    type: "common",
  },
  {
    label: "LABASTIDE-ROUAIROUX",
    value: "81115",
    type: "common",
  },
  {
    label: "VERRERIES-DE-MOUSSANS",
    value: "34331",
    type: "common",
  },
  {
    label: "SAUVETERRE",
    value: "81278",
    type: "common",
  },
  {
    label: "LACABAREDE",
    value: "81121",
    type: "common",
  },
  {
    label: "ALBINE",
    value: "81005",
    type: "common",
  },
  {
    label: "SAINT-AMANS-SOULT",
    value: "81238",
    type: "common",
  },
  {
    label: "MAZAMET",
    value: "81163",
    type: "common",
  },
  {
    label: "BOUT-DU-PONT-DE-LARN",
    value: "81036",
    type: "common",
  },
  {
    label: "AIGUEFONDE",
    value: "81002",
    type: "common",
  },
  {
    label: "LAPRADE",
    value: "11189",
    type: "common",
  },
  {
    label: "LABRUGUIERE",
    value: "81120",
    type: "common",
  },
  {
    label: "ESCOUSSENS",
    value: "81084",
    type: "common",
  },
  {
    label: "VERDALLE",
    value: "81312",
    type: "common",
  },
  {
    label: "MASSAGUEL",
    value: "81160",
    type: "common",
  },
  {
    label: "DOURGNE",
    value: "81081",
    type: "common",
  },
  {
    label: "SAINT-AMANCET",
    value: "81237",
    type: "common",
  },
  {
    label: "CAHUZAC",
    value: "81049",
    type: "common",
  },
  {
    label: "BELLESERRE",
    value: "81027",
    type: "common",
  },
  {
    label: "GARREVAQUES",
    value: "81100",
    type: "common",
  },
  {
    label: "REVEL",
    value: "31451",
    type: "common",
  },
  {
    label: "SAINT-FELIX-LAURAGAIS",
    value: "31478",
    type: "common",
  },
  {
    label: "ROUMENS",
    value: "31463",
    type: "common",
  },
  {
    label: "NOGARET",
    value: "31400",
    type: "common",
  },
  {
    label: "MONTEGUT-LAURAGAIS",
    value: "31371",
    type: "common",
  },
  {
    label: "VAUX",
    value: "31570",
    type: "common",
  },
  {
    label: "MAURENS",
    value: "31329",
    type: "common",
  },
  {
    label: "FALGA",
    value: "31180",
    type: "common",
  },
  {
    label: "BELESTA-EN-LAURAGAIS",
    value: "31060",
    type: "common",
  },
  {
    label: "TREBONS-SUR-LA-GRASSE",
    value: "31560",
    type: "common",
  },
  {
    label: "TOUTENS",
    value: "31558",
    type: "common",
  },
  {
    label: "SEGREVILLE",
    value: "31540",
    type: "common",
  },
  {
    label: "SAINT-VINCENT",
    value: "31519",
    type: "common",
  },
  {
    label: "SAINT-GERMIER",
    value: "31485",
    type: "common",
  },
  {
    label: "JUZES",
    value: "31243",
    type: "common",
  },
  {
    label: "CESSALES",
    value: "31137",
    type: "common",
  },
  {
    label: "CAMBIAC",
    value: "31102",
    type: "common",
  },
  {
    label: "BEAUVILLE",
    value: "31055",
    type: "common",
  },
  {
    label: "VILLENOUVELLE",
    value: "31589",
    type: "common",
  },
  {
    label: "VARENNES",
    value: "31568",
    type: "common",
  },
  {
    label: "MOURVILLES-BASSES",
    value: "31392",
    type: "common",
  },
  {
    label: "MAUREMONT",
    value: "31328",
    type: "common",
  },
  {
    label: "LABASTIDE-BEAUVOIR",
    value: "31249",
    type: "common",
  },
  {
    label: "MONTLAUR",
    value: "31384",
    type: "common",
  },
  {
    label: "MONTGISCARD",
    value: "31381",
    type: "common",
  },
  {
    label: "FOURQUEVAUX",
    value: "31192",
    type: "common",
  },
  {
    label: "BELBEZE-DE-LAURAGAIS",
    value: "31058",
    type: "common",
  },
  {
    label: "BAZIEGE",
    value: "31048",
    type: "common",
  },
  {
    label: "REBIGUE",
    value: "31448",
    type: "common",
  },
  {
    label: "MONTBRUN-LAURAGAIS",
    value: "31366",
    type: "common",
  },
  {
    label: "ESPANES",
    value: "31171",
    type: "common",
  },
  {
    label: "DONNEVILLE",
    value: "31162",
    type: "common",
  },
  {
    label: "DEYME",
    value: "31161",
    type: "common",
  },
  {
    label: "CORRONSAC",
    value: "31151",
    type: "common",
  },
  {
    label: "PINS-JUSTARET",
    value: "31421",
    type: "common",
  },
  {
    label: "LABARTHE-SUR-LEZE",
    value: "31248",
    type: "common",
  },
  {
    label: "VENERQUE",
    value: "31572",
    type: "common",
  },
  {
    label: "GOYRANS",
    value: "31227",
    type: "common",
  },
  {
    label: "CLERMONT-LE-FORT",
    value: "31148",
    type: "common",
  },
  {
    label: "AUREVILLE",
    value: "31025",
    type: "common",
  },
  {
    label: "VILLATE",
    value: "31580",
    type: "common",
  },
  {
    label: "SAUBENS",
    value: "31533",
    type: "common",
  },
  {
    label: "MURET",
    value: "31395",
    type: "common",
  },
  {
    label: "LAMASQUERE",
    value: "31269",
    type: "common",
  },
  {
    label: "LABASTIDETTE",
    value: "31253",
    type: "common",
  },
  {
    label: "SAINT-CLAR-DE-RIVIERE",
    value: "31475",
    type: "common",
  },
  {
    label: "CAMBERNARD",
    value: "31101",
    type: "common",
  },
  {
    label: "SAINTE-FOY-DE-PEYROLIERES",
    value: "31481",
    type: "common",
  },
  {
    label: "BEAUFORT",
    value: "31051",
    type: "common",
  },
  {
    label: "SAVIGNAC-MONA",
    value: "32421",
    type: "common",
  },
  {
    label: "SAINT-LOUBE",
    value: "32387",
    type: "common",
  },
  {
    label: "PEBEES",
    value: "32308",
    type: "common",
  },
  {
    label: "MONBLANC",
    value: "32261",
    type: "common",
  },
  {
    label: "SABONNERES",
    value: "31464",
    type: "common",
  },
  {
    label: "MONTGRAS",
    value: "31382",
    type: "common",
  },
  {
    label: "LAHAGE",
    value: "31266",
    type: "common",
  },
  {
    label: "FORGUES",
    value: "31189",
    type: "common",
  },
  {
    label: "SAUVIMONT",
    value: "32420",
    type: "common",
  },
  {
    label: "PUYLAUSIC",
    value: "32336",
    type: "common",
  },
  {
    label: "MONTEGUT-SAVES",
    value: "32284",
    type: "common",
  },
  {
    label: "LOMBEZ",
    value: "32213",
    type: "common",
  },
  {
    label: "SAUVETERRE",
    value: "32418",
    type: "common",
  },
  {
    label: "MONTADET",
    value: "32276",
    type: "common",
  },
  {
    label: "GAUJAC",
    value: "32140",
    type: "common",
  },
  {
    label: "ESPAON",
    value: "32124",
    type: "common",
  },
  {
    label: "TOURNAN",
    value: "32451",
    type: "common",
  },
  {
    label: "SABAILLAN",
    value: "32353",
    type: "common",
  },
  {
    label: "PELLEFIGUE",
    value: "32309",
    type: "common",
  },
  {
    label: "TACHOIRES",
    value: "32438",
    type: "common",
  },
  {
    label: "SIMORRE",
    value: "32433",
    type: "common",
  },
  {
    label: "BETCAVE-AGUIN",
    value: "32048",
    type: "common",
  },
  {
    label: "POUY-LOUBRIN",
    value: "32327",
    type: "common",
  },
  {
    label: "MONCORNEIL-GRAZAN",
    value: "32266",
    type: "common",
  },
  {
    label: "MASSEUBE",
    value: "32242",
    type: "common",
  },
  {
    label: "LABARTHE",
    value: "32169",
    type: "common",
  },
  {
    label: "SAINT-ARROMAN",
    value: "32361",
    type: "common",
  },
  {
    label: "LOURTIES-MONBRUN",
    value: "32216",
    type: "common",
  },
  {
    label: "CLERMONT-POUYGUILLES",
    value: "32104",
    type: "common",
  },
  {
    label: "SAINT-ELIX-THEUX",
    value: "32375",
    type: "common",
  },
  {
    label: "MONCASSIN",
    value: "32263",
    type: "common",
  },
  {
    label: "BELLOC-SAINT-CLAMENS",
    value: "32042",
    type: "common",
  },
  {
    label: "BERDOUES",
    value: "32045",
    type: "common",
  },
  {
    label: "PONSAMPERE",
    value: "32323",
    type: "common",
  },
  {
    label: "SAINT-MICHEL",
    value: "32397",
    type: "common",
  },
  {
    label: "MIELAN",
    value: "32252",
    type: "common",
  },
  {
    label: "LAAS",
    value: "32167",
    type: "common",
  },
  {
    label: "BAZUGUES",
    value: "32034",
    type: "common",
  },
  {
    label: "AUX-AUSSAT",
    value: "32020",
    type: "common",
  },
  {
    label: "TRONCENS",
    value: "32455",
    type: "common",
  },
  {
    label: "MONPARDIAC",
    value: "32275",
    type: "common",
  },
  {
    label: "MALABAT",
    value: "32225",
    type: "common",
  },
  {
    label: "BLOUSSON-SERIAN",
    value: "32058",
    type: "common",
  },
  {
    label: "BETPLAN",
    value: "32050",
    type: "common",
  },
  {
    label: "MONFAUCON",
    value: "65314",
    type: "common",
  },
  {
    label: "LIAC",
    value: "65273",
    type: "common",
  },
  {
    label: "BUZON",
    value: "65114",
    type: "common",
  },
  {
    label: "BARBACHEN",
    value: "65061",
    type: "common",
  },
  {
    label: "ANSOST",
    value: "65013",
    type: "common",
  },
  {
    label: "SEMBOUES",
    value: "32427",
    type: "common",
  },
  {
    label: "HAGET",
    value: "32152",
    type: "common",
  },
  {
    label: "CAZAUX-VILLECOMTAL",
    value: "32099",
    type: "common",
  },
  {
    label: "BECCAS",
    value: "32039",
    type: "common",
  },
  {
    label: "NOUILHAN",
    value: "65330",
    type: "common",
  },
  {
    label: "LARREULE",
    value: "65262",
    type: "common",
  },
  {
    label: "LAFITOLE",
    value: "65243",
    type: "common",
  },
  {
    label: "GENSAC",
    value: "65196",
    type: "common",
  },
  {
    label: "VIDOUZE",
    value: "65462",
    type: "common",
  },
  {
    label: "LAHITTE-TOUPIERE",
    value: "65248",
    type: "common",
  },
  {
    label: "MONSEGUR",
    value: "64395",
    type: "common",
  },
  {
    label: "LABATUT",
    value: "64293",
    type: "common",
  },
  {
    label: "SAMSONS-LION",
    value: "64503",
    type: "common",
  },
  {
    label: "PEYRELONGUE-ABOS",
    value: "64446",
    type: "common",
  },
  {
    label: "LUC-ARMAU",
    value: "64356",
    type: "common",
  },
  {
    label: "LEMBEYE",
    value: "64331",
    type: "common",
  },
  {
    label: "ESCURES",
    value: "64210",
    type: "common",
  },
  {
    label: "BASSILLON-VAUZE",
    value: "64098",
    type: "common",
  },
  {
    label: "SIMACOURBE",
    value: "64524",
    type: "common",
  },
  {
    label: "MONASSUT-AUDIRACQ",
    value: "64389",
    type: "common",
  },
  {
    label: "MASPIE-LALONQUERE-JUILLACQ",
    value: "64369",
    type: "common",
  },
  {
    label: "LUSSAGNET-LUSSON",
    value: "64361",
    type: "common",
  },
  {
    label: "GERDEREST",
    value: "64239",
    type: "common",
  },
  {
    label: "SEVIGNACQ",
    value: "64523",
    type: "common",
  },
  {
    label: "SAINT-ARMOU",
    value: "64470",
    type: "common",
  },
  {
    label: "LASCLAVERIES",
    value: "64321",
    type: "common",
  },
  {
    label: "ESCOUBES",
    value: "64208",
    type: "common",
  },
  {
    label: "COSLEDAA-LUBE-BOAST",
    value: "64194",
    type: "common",
  },
  {
    label: "VIVEN",
    value: "64560",
    type: "common",
  },
  {
    label: "NAVAILLES-ANGOS",
    value: "64415",
    type: "common",
  },
  {
    label: "DOUMY",
    value: "64203",
    type: "common",
  },
  {
    label: "AURIAC",
    value: "64078",
    type: "common",
  },
  {
    label: "ASTIS",
    value: "64070",
    type: "common",
  },
  {
    label: "ARGELOS",
    value: "64043",
    type: "common",
  },
  {
    label: "MOMAS",
    value: "64387",
    type: "common",
  },
  {
    label: "CAUBIOS-LOOS",
    value: "64183",
    type: "common",
  },
  {
    label: "BOURNOS",
    value: "64146",
    type: "common",
  },
  {
    label: "AUBIN",
    value: "64073",
    type: "common",
  },
  {
    label: "VIELLENAVE-D'ARTHEZ",
    value: "64554",
    type: "common",
  },
  {
    label: "MAZEROLLES",
    value: "64374",
    type: "common",
  },
  {
    label: "CESCAU",
    value: "64184",
    type: "common",
  },
  {
    label: "CASTEIDE-CAMI",
    value: "64171",
    type: "common",
  },
  {
    label: "BOUMOURT",
    value: "64144",
    type: "common",
  },
  {
    label: "URDES",
    value: "64541",
    type: "common",
  },
  {
    label: "SERRES-SAINTE-MARIE",
    value: "64521",
    type: "common",
  },
  {
    label: "LACQ",
    value: "64300",
    type: "common",
  },
  {
    label: "DOAZON",
    value: "64200",
    type: "common",
  },
  {
    label: "ARTIX",
    value: "64061",
    type: "common",
  },
  {
    label: "MONT",
    value: "64396",
    type: "common",
  },
  {
    label: "MASLACQ",
    value: "64367",
    type: "common",
  },
  {
    label: "SAUVELADE",
    value: "64512",
    type: "common",
  },
  {
    label: "LOUBIENG",
    value: "64349",
    type: "common",
  },
  {
    label: "CASTETNER",
    value: "64179",
    type: "common",
  },
  {
    label: "OZENX-MONTESTRUCQ",
    value: "64440",
    type: "common",
  },
  {
    label: "ORRIULE",
    value: "64428",
    type: "common",
  },
  {
    label: "ORION",
    value: "64427",
    type: "common",
  },
  {
    label: "L'HOPITAL-D'ORION",
    value: "64263",
    type: "common",
  },
  {
    label: "CASTETBON",
    value: "64176",
    type: "common",
  },
  {
    label: "SAUVETERRE-DE-BEARN",
    value: "64513",
    type: "common",
  },
  {
    label: "BURGARONNE",
    value: "64151",
    type: "common",
  },
  {
    label: "ANDREIN",
    value: "64022",
    type: "common",
  },
  {
    label: "ILHARRE",
    value: "64272",
    type: "common",
  },
  {
    label: "ORAAS",
    value: "64423",
    type: "common",
  },
  {
    label: "AUTEVIELLE-SAINT-MARTIN-BIDEREN",
    value: "64083",
    type: "common",
  },
  {
    label: "ATHOS-ASPIS",
    value: "64071",
    type: "common",
  },
  {
    label: "ABITAIN",
    value: "64004",
    type: "common",
  },
  {
    label: "MASPARRAUTE",
    value: "64368",
    type: "common",
  },
  {
    label: "ARRAUTE-CHARRITTE",
    value: "64051",
    type: "common",
  },
  {
    label: "BERGOUEY-VIELLENAVE",
    value: "64113",
    type: "common",
  },
  {
    label: "OREGUE",
    value: "64425",
    type: "common",
  },
  {
    label: "LA BASTIDE-CLAIRENCE",
    value: "64289",
    type: "common",
  },
  {
    label: "HASPARREN",
    value: "64256",
    type: "common",
  },
  {
    label: "JATXOU",
    value: "64282",
    type: "common",
  },
  {
    label: "HALSOU",
    value: "64255",
    type: "common",
  },
  {
    label: "USTARITZ",
    value: "64547",
    type: "common",
  },
  {
    label: "ARBONNE",
    value: "64035",
    type: "common",
  },
  {
    label: "AHETZE",
    value: "64009",
    type: "common",
  },
  {
    label: "SAINT-JEAN-DE-LUZ",
    value: "64483",
    type: "common",
  },
  {
    label: "GUETHARY",
    value: "64249",
    type: "common",
  },
  {
    label: "CIBOURE",
    value: "64189",
    type: "common",
  },
  {
    label: "HENDAYE",
    value: "64260",
    type: "common",
  },
  {
    label: "LE PLAN-DE-LA-TOUR",
    value: "83094",
    type: "common",
  },
  {
    label: "LA GARDE-FREINET",
    value: "83063",
    type: "common",
  },
  {
    label: "GONFARON",
    value: "83067",
    type: "common",
  },
  {
    label: "BESSE-SUR-ISSOLE",
    value: "83018",
    type: "common",
  },
  {
    label: "SAINTE-ANASTASIE-SUR-ISSOLE",
    value: "83111",
    type: "common",
  },
  {
    label: "FORCALQUEIRET",
    value: "83059",
    type: "common",
  },
  {
    label: "GAREOULT",
    value: "83064",
    type: "common",
  },
  {
    label: "LA ROQUEBRUSSANNE",
    value: "83108",
    type: "common",
  },
  {
    label: "ROUGIERS",
    value: "83110",
    type: "common",
  },
  {
    label: "MAZAUGUES",
    value: "83076",
    type: "common",
  },
  {
    label: "PLAN-D'AUPS-SAINTE-BAUME",
    value: "83093",
    type: "common",
  },
  {
    label: "SAINT-ZACHARIE",
    value: "83120",
    type: "common",
  },
  {
    label: "ROQUEVAIRE",
    value: "13086",
    type: "common",
  },
  {
    label: "LA DESTROUSSE",
    value: "13031",
    type: "common",
  },
  {
    label: "AURIOL",
    value: "13007",
    type: "common",
  },
  {
    label: "PEYPIN",
    value: "13073",
    type: "common",
  },
  {
    label: "ALLAUCH",
    value: "13002",
    type: "common",
  },
  {
    label: "PLAN-DE-CUQUES",
    value: "13075",
    type: "common",
  },
  {
    label: "LE ROVE",
    value: "13088",
    type: "common",
  },
  {
    label: "GIGNAC-LA-NERTHE",
    value: "13043",
    type: "common",
  },
  {
    label: "ENSUES-LA-REDONNE",
    value: "13033",
    type: "common",
  },
  {
    label: "SAUSSET-LES-PINS",
    value: "13104",
    type: "common",
  },
  {
    label: "CHATEAUNEUF-LES-MARTIGUES",
    value: "13026",
    type: "common",
  },
  {
    label: "MARTIGUES",
    value: "13056",
    type: "common",
  },
  {
    label: "SETE",
    value: "34301",
    type: "common",
  },
  {
    label: "POMEROLS",
    value: "34207",
    type: "common",
  },
  {
    label: "PINET",
    value: "34203",
    type: "common",
  },
  {
    label: "SAINT-THIBERY",
    value: "34289",
    type: "common",
  },
  {
    label: "FLORENSAC",
    value: "34101",
    type: "common",
  },
  {
    label: "VALROS",
    value: "34325",
    type: "common",
  },
  {
    label: "NEZIGNAN-L'EVEQUE",
    value: "34182",
    type: "common",
  },
  {
    label: "SERVIAN",
    value: "34300",
    type: "common",
  },
  {
    label: "BOUJAN-SUR-LIBRON",
    value: "34037",
    type: "common",
  },
  {
    label: "BASSAN",
    value: "34025",
    type: "common",
  },
  {
    label: "LIGNAN-SUR-ORB",
    value: "34140",
    type: "common",
  },
  {
    label: "LIEURAN-LES-BEZIERS",
    value: "34139",
    type: "common",
  },
  {
    label: "CORNEILHAN",
    value: "34084",
    type: "common",
  },
  {
    label: "THEZAN-LES-BEZIERS",
    value: "34310",
    type: "common",
  },
  {
    label: "CAZOULS-LES-BEZIERS",
    value: "34069",
    type: "common",
  },
  {
    label: "PUISSERGUIER",
    value: "34225",
    type: "common",
  },
  {
    label: "CAZEDARNES",
    value: "34065",
    type: "common",
  },
  {
    label: "PIERRERUE",
    value: "34201",
    type: "common",
  },
  {
    label: "CREISSAN",
    value: "34089",
    type: "common",
  },
  {
    label: "CEBAZAN",
    value: "34070",
    type: "common",
  },
  {
    label: "VILLESPASSANS",
    value: "34339",
    type: "common",
  },
  {
    label: "SAINT-CHINIAN",
    value: "34245",
    type: "common",
  },
  {
    label: "ASSIGNAN",
    value: "34015",
    type: "common",
  },
  {
    label: "SAINT-JEAN-DE-MINERVOIS",
    value: "34269",
    type: "common",
  },
  {
    label: "VELIEUX",
    value: "34326",
    type: "common",
  },
  {
    label: "RIEUSSEC",
    value: "34228",
    type: "common",
  },
  {
    label: "BOISSET",
    value: "34034",
    type: "common",
  },
  {
    label: "FERRALS-LES-MONTAGNES",
    value: "34098",
    type: "common",
  },
  {
    label: "CASSAGNOLES",
    value: "34054",
    type: "common",
  },
  {
    label: "LESPINASSIERE",
    value: "11200",
    type: "common",
  },
  {
    label: "CASTANS",
    value: "11075",
    type: "common",
  },
  {
    label: "ROQUEFERE",
    value: "11319",
    type: "common",
  },
  {
    label: "PRADELLES-CABARDES",
    value: "11297",
    type: "common",
  },
  {
    label: "LABASTIDE-ESPARBAIRENQUE",
    value: "11180",
    type: "common",
  },
  {
    label: "LA TOURETTE-CABARDES",
    value: "11391",
    type: "common",
  },
  {
    label: "MIRAVAL-CABARDES",
    value: "11232",
    type: "common",
  },
  {
    label: "MAS-CABARDES",
    value: "11222",
    type: "common",
  },
  {
    label: "LES MARTYS",
    value: "11221",
    type: "common",
  },
  {
    label: "CAUDEBRONDE",
    value: "11079",
    type: "common",
  },
  {
    label: "LACOMBE",
    value: "11182",
    type: "common",
  },
  {
    label: "ARFONS",
    value: "81016",
    type: "common",
  },
  {
    label: "SOREZE",
    value: "81288",
    type: "common",
  },
  {
    label: "DURFORT",
    value: "81083",
    type: "common",
  },
  {
    label: "LES CAMMAZES",
    value: "81055",
    type: "common",
  },
  {
    label: "LES BRUNELS",
    value: "11054",
    type: "common",
  },
  {
    label: "VAUDREUILLE",
    value: "31569",
    type: "common",
  },
  {
    label: "LABECEDE-LAURAGAIS",
    value: "11181",
    type: "common",
  },
  {
    label: "TREVILLE",
    value: "11399",
    type: "common",
  },
  {
    label: "SOUPEX",
    value: "11385",
    type: "common",
  },
  {
    label: "SAINT-PAULET",
    value: "11362",
    type: "common",
  },
  {
    label: "PUGINIER",
    value: "11300",
    type: "common",
  },
  {
    label: "LA POMAREDE",
    value: "11292",
    type: "common",
  },
  {
    label: "RIEUMAJOU",
    value: "31453",
    type: "common",
  },
  {
    label: "MOURVILLES-HAUTES",
    value: "31393",
    type: "common",
  },
  {
    label: "FOLCARDE",
    value: "31185",
    type: "common",
  },
  {
    label: "MONTMAUR",
    value: "11252",
    type: "common",
  },
  {
    label: "LES CASSES",
    value: "11074",
    type: "common",
  },
  {
    label: "VILLEFRANCHE-DE-LAURAGAIS",
    value: "31582",
    type: "common",
  },
  {
    label: "VALLEGUE",
    value: "31566",
    type: "common",
  },
  {
    label: "RENNEVILLE",
    value: "31450",
    type: "common",
  },
  {
    label: "LUX",
    value: "31310",
    type: "common",
  },
  {
    label: "AVIGNONET-LAURAGAIS",
    value: "31037",
    type: "common",
  },
  {
    label: "VIEILLEVIGNE",
    value: "31576",
    type: "common",
  },
  {
    label: "SAINT-ROME",
    value: "31514",
    type: "common",
  },
  {
    label: "MONTGAILLARD-LAURAGAIS",
    value: "31377",
    type: "common",
  },
  {
    label: "GARDOUCH",
    value: "31210",
    type: "common",
  },
  {
    label: "SAINT-LEON",
    value: "31495",
    type: "common",
  },
  {
    label: "MONTESQUIEU-LAURAGAIS",
    value: "31374",
    type: "common",
  },
  {
    label: "AYGUESVIVES",
    value: "31004",
    type: "common",
  },
  {
    label: "POUZE",
    value: "31437",
    type: "common",
  },
  {
    label: "NOUEILLES",
    value: "31401",
    type: "common",
  },
  {
    label: "ISSUS",
    value: "31240",
    type: "common",
  },
  {
    label: "AURAGNE",
    value: "31024",
    type: "common",
  },
  {
    label: "LAGARDELLE-SUR-LEZE",
    value: "31263",
    type: "common",
  },
  {
    label: "VERNET",
    value: "31574",
    type: "common",
  },
  {
    label: "MIREMONT",
    value: "31345",
    type: "common",
  },
  {
    label: "LABRUYERE-DORSA",
    value: "31256",
    type: "common",
  },
  {
    label: "GREPIAC",
    value: "31233",
    type: "common",
  },
  {
    label: "EAUNES",
    value: "31165",
    type: "common",
  },
  {
    label: "BEAUMONT-SUR-LEZE",
    value: "31052",
    type: "common",
  },
  {
    label: "SAINT-HILAIRE",
    value: "31486",
    type: "common",
  },
  {
    label: "MAUZAC",
    value: "31334",
    type: "common",
  },
  {
    label: "LHERM",
    value: "31299",
    type: "common",
  },
  {
    label: "LAVERNOSE-LACASSE",
    value: "31287",
    type: "common",
  },
  {
    label: "LE FAUGA",
    value: "31181",
    type: "common",
  },
  {
    label: "POUCHARRAMET",
    value: "31435",
    type: "common",
  },
  {
    label: "BERAT",
    value: "31065",
    type: "common",
  },
  {
    label: "RIEUMES",
    value: "31454",
    type: "common",
  },
  {
    label: "PLAGNOLE",
    value: "31423",
    type: "common",
  },
  {
    label: "LAUTIGNAC",
    value: "31283",
    type: "common",
  },
  {
    label: "LAYMONT",
    value: "32206",
    type: "common",
  },
  {
    label: "SAJAS",
    value: "31520",
    type: "common",
  },
  {
    label: "LE PIN-MURELET",
    value: "31419",
    type: "common",
  },
  {
    label: "MONES",
    value: "31353",
    type: "common",
  },
  {
    label: "SAINT-LIZIER-DU-PLANTE",
    value: "32386",
    type: "common",
  },
  {
    label: "MONTPEZAT",
    value: "32289",
    type: "common",
  },
  {
    label: "GARRAVET",
    value: "32138",
    type: "common",
  },
  {
    label: "MAUVEZIN",
    value: "31333",
    type: "common",
  },
  {
    label: "GOUDEX",
    value: "31223",
    type: "common",
  },
  {
    label: "FRONTIGNAN-SAVES",
    value: "31201",
    type: "common",
  },
  {
    label: "AMBAX",
    value: "31007",
    type: "common",
  },
  {
    label: "CADEILLAN",
    value: "32069",
    type: "common",
  },
  {
    label: "MIRAMBEAU",
    value: "31343",
    type: "common",
  },
  {
    label: "MARTISSERRE",
    value: "31322",
    type: "common",
  },
  {
    label: "L'ISLE-EN-DODON",
    value: "31239",
    type: "common",
  },
  {
    label: "BOISSEDE",
    value: "31072",
    type: "common",
  },
  {
    label: "MOLAS",
    value: "31347",
    type: "common",
  },
  {
    label: "VILLEFRANCHE",
    value: "32465",
    type: "common",
  },
  {
    label: "SARCOS",
    value: "32413",
    type: "common",
  },
  {
    label: "MONTIES",
    value: "32287",
    type: "common",
  },
  {
    label: "MONBARDON",
    value: "32260",
    type: "common",
  },
  {
    label: "MEILHAN",
    value: "32250",
    type: "common",
  },
  {
    label: "GAUJAN",
    value: "32141",
    type: "common",
  },
  {
    label: "AUSSOS",
    value: "32468",
    type: "common",
  },
  {
    label: "SERE",
    value: "32430",
    type: "common",
  },
  {
    label: "BEZUES-BAJON",
    value: "32053",
    type: "common",
  },
  {
    label: "BELLEGARDE",
    value: "32041",
    type: "common",
  },
  {
    label: "SAMARAN",
    value: "32409",
    type: "common",
  },
  {
    label: "PANASSAC",
    value: "32304",
    type: "common",
  },
  {
    label: "ESCLASSAN-LABASTIDE",
    value: "32122",
    type: "common",
  },
  {
    label: "AUJAN-MOURNEDE",
    value: "32015",
    type: "common",
  },
  {
    label: "VIOZAN",
    value: "32466",
    type: "common",
  },
  {
    label: "SAUVIAC",
    value: "32419",
    type: "common",
  },
  {
    label: "SAINT-OST",
    value: "32401",
    type: "common",
  },
  {
    label: "SAINTE-AURENCE-CAZAUX",
    value: "32363",
    type: "common",
  },
  {
    label: "LAGARDE-HACHAN",
    value: "32177",
    type: "common",
  },
  {
    label: "SAINTE-DODE",
    value: "32373",
    type: "common",
  },
  {
    label: "SADEILLAN",
    value: "32355",
    type: "common",
  },
  {
    label: "MONT-DE-MARRAST",
    value: "32281",
    type: "common",
  },
  {
    label: "MONTAUT",
    value: "32278",
    type: "common",
  },
  {
    label: "MANAS-BASTANOUS",
    value: "32226",
    type: "common",
  },
  {
    label: "BARCUGNAN",
    value: "32028",
    type: "common",
  },
  {
    label: "ESTAMPURES",
    value: "65170",
    type: "common",
  },
  {
    label: "SARRAGUZAN",
    value: "32415",
    type: "common",
  },
  {
    label: "ESTAMPES",
    value: "32126",
    type: "common",
  },
  {
    label: "CASTEX",
    value: "32086",
    type: "common",
  },
  {
    label: "SENAC",
    value: "65418",
    type: "common",
  },
  {
    label: "MOUMOULOUS",
    value: "65325",
    type: "common",
  },
  {
    label: "FRECHEDE",
    value: "65178",
    type: "common",
  },
  {
    label: "VILLECOMTAL-SUR-ARROS",
    value: "32464",
    type: "common",
  },
  {
    label: "MONTEGUT-ARROS",
    value: "32283",
    type: "common",
  },
  {
    label: "LAGUIAN-MAZOUS",
    value: "32181",
    type: "common",
  },
  {
    label: "SEGALAS",
    value: "65414",
    type: "common",
  },
  {
    label: "SARRIAC-BIGORRE",
    value: "65409",
    type: "common",
  },
  {
    label: "RABASTENS-DE-BIGORRE",
    value: "65375",
    type: "common",
  },
  {
    label: "MINGOT",
    value: "65311",
    type: "common",
  },
  {
    label: "BAZILLAC",
    value: "65073",
    type: "common",
  },
  {
    label: "VIC-EN-BIGORRE",
    value: "65460",
    type: "common",
  },
  {
    label: "SAINT-LEZER",
    value: "65390",
    type: "common",
  },
  {
    label: "CAMALES",
    value: "65121",
    type: "common",
  },
  {
    label: "CAIXON",
    value: "65119",
    type: "common",
  },
  {
    label: "ARTAGNAN",
    value: "65035",
    type: "common",
  },
  {
    label: "SANOUS",
    value: "65403",
    type: "common",
  },
  {
    label: "PONTIACQ-VIELLEPINTE",
    value: "64454",
    type: "common",
  },
  {
    label: "LAMAYOU",
    value: "64309",
    type: "common",
  },
  {
    label: "CASTERA-LOUBIX",
    value: "64174",
    type: "common",
  },
  {
    label: "CASTEIDE-DOAT",
    value: "64173",
    type: "common",
  },
  {
    label: "BENTAYOU-SEREE",
    value: "64111",
    type: "common",
  },
  {
    label: "SEDZE-MAUBECQ",
    value: "64515",
    type: "common",
  },
  {
    label: "MOMY",
    value: "64388",
    type: "common",
  },
  {
    label: "MAURE",
    value: "64372",
    type: "common",
  },
  {
    label: "LUCARRE",
    value: "64357",
    type: "common",
  },
  {
    label: "BALEIX",
    value: "64089",
    type: "common",
  },
  {
    label: "ANOYE",
    value: "64028",
    type: "common",
  },
  {
    label: "SAINT-LAURENT-BRETAGNE",
    value: "64488",
    type: "common",
  },
  {
    label: "LESPOURCY",
    value: "64338",
    type: "common",
  },
  {
    label: "GABASTON",
    value: "64227",
    type: "common",
  },
  {
    label: "ABERE",
    value: "64002",
    type: "common",
  },
  {
    label: "SAINT-JAMMES",
    value: "64482",
    type: "common",
  },
  {
    label: "RIUPEYROUS",
    value: "64465",
    type: "common",
  },
  {
    label: "MAUCOR",
    value: "64370",
    type: "common",
  },
  {
    label: "HIGUERES-SOUYE",
    value: "64262",
    type: "common",
  },
  {
    label: "BERNADETS",
    value: "64114",
    type: "common",
  },
  {
    label: "BARINQUE",
    value: "64095",
    type: "common",
  },
  {
    label: "ANOS",
    value: "64027",
    type: "common",
  },
  {
    label: "SERRES-CASTET",
    value: "64519",
    type: "common",
  },
  {
    label: "SAINT-CASTIN",
    value: "64472",
    type: "common",
  },
  {
    label: "MONTARDON",
    value: "64399",
    type: "common",
  },
  {
    label: "BUROS",
    value: "64152",
    type: "common",
  },
  {
    label: "UZEIN",
    value: "64549",
    type: "common",
  },
  {
    label: "SAUVAGNON",
    value: "64511",
    type: "common",
  },
  {
    label: "POEY-DE-LESCAR",
    value: "64448",
    type: "common",
  },
  {
    label: "TARSACQ",
    value: "64535",
    type: "common",
  },
  {
    label: "SIROS",
    value: "64525",
    type: "common",
  },
  {
    label: "LABASTIDE-MONREJEAU",
    value: "64290",
    type: "common",
  },
  {
    label: "LABASTIDE-CEZERACQ",
    value: "64288",
    type: "common",
  },
  {
    label: "DENGUIN",
    value: "64198",
    type: "common",
  },
  {
    label: "BOUGARBER",
    value: "64142",
    type: "common",
  },
  {
    label: "BEYRIE-EN-BEARN",
    value: "64121",
    type: "common",
  },
  {
    label: "AUSSEVIELLE",
    value: "64080",
    type: "common",
  },
  {
    label: "PARDIES",
    value: "64443",
    type: "common",
  },
  {
    label: "OS-MARSILLON",
    value: "64431",
    type: "common",
  },
  {
    label: "NOGUERES",
    value: "64418",
    type: "common",
  },
  {
    label: "MOURENX",
    value: "64410",
    type: "common",
  },
  {
    label: "BESINGRAND",
    value: "64117",
    type: "common",
  },
  {
    label: "ABOS",
    value: "64005",
    type: "common",
  },
  {
    label: "ABIDOS",
    value: "64003",
    type: "common",
  },
  {
    label: "VIELLESEGURE",
    value: "64556",
    type: "common",
  },
  {
    label: "LAHOURCADE",
    value: "64306",
    type: "common",
  },
  {
    label: "LAGOR",
    value: "64301",
    type: "common",
  },
  {
    label: "MERITEIN",
    value: "64381",
    type: "common",
  },
  {
    label: "BUGNEIN",
    value: "64149",
    type: "common",
  },
  {
    label: "BASTANES",
    value: "64099",
    type: "common",
  },
  {
    label: "AUDAUX",
    value: "64075",
    type: "common",
  },
  {
    label: "VIELLENAVE-DE-NAVARRENX",
    value: "64555",
    type: "common",
  },
  {
    label: "RIVEHAUTE",
    value: "64466",
    type: "common",
  },
  {
    label: "OSSENX",
    value: "64434",
    type: "common",
  },
  {
    label: "NARP",
    value: "64414",
    type: "common",
  },
  {
    label: "MONTFORT",
    value: "64403",
    type: "common",
  },
  {
    label: "LAAS",
    value: "64287",
    type: "common",
  },
  {
    label: "ARAUX",
    value: "64033",
    type: "common",
  },
  {
    label: "ARAUJUZON",
    value: "64032",
    type: "common",
  },
  {
    label: "OSSERAIN-RIVAREYTE",
    value: "64435",
    type: "common",
  },
  {
    label: "GESTAS",
    value: "64242",
    type: "common",
  },
  {
    label: "ETCHARRY",
    value: "64221",
    type: "common",
  },
  {
    label: "TABAILLE-USQUAIN",
    value: "64531",
    type: "common",
  },
  {
    label: "SAINT-GLADIE-ARRIVE-MUNEIN",
    value: "64480",
    type: "common",
  },
  {
    label: "NABAS",
    value: "64412",
    type: "common",
  },
  {
    label: "GUINARTHE-PARENTIES",
    value: "64251",
    type: "common",
  },
  {
    label: "ESPIUTE",
    value: "64215",
    type: "common",
  },
  {
    label: "BARRAUTE-CAMU",
    value: "64096",
    type: "common",
  },
  {
    label: "DOMEZAIN-BERRAUTE",
    value: "64202",
    type: "common",
  },
  {
    label: "ARBERATS-SILLEGUE",
    value: "64034",
    type: "common",
  },
  {
    label: "ARBOUET-SUSSAUTE",
    value: "64036",
    type: "common",
  },
  {
    label: "AICIRITS-CAMOU-SUHAST",
    value: "64010",
    type: "common",
  },
  {
    label: "LUXE-SUMBERRAUTE",
    value: "64362",
    type: "common",
  },
  {
    label: "GABAT",
    value: "64228",
    type: "common",
  },
  {
    label: "LABETS-BISCAY",
    value: "64294",
    type: "common",
  },
  {
    label: "BEGUIOS",
    value: "64105",
    type: "common",
  },
  {
    label: "AMENDEUIX-ONEIX",
    value: "64018",
    type: "common",
  },
  {
    label: "GARRIS",
    value: "64235",
    type: "common",
  },
  {
    label: "AMOROTS-SUCCOS",
    value: "64019",
    type: "common",
  },
  {
    label: "SAINT-MARTIN-D'ARBEROUE",
    value: "64489",
    type: "common",
  },
  {
    label: "MEHARIN",
    value: "64375",
    type: "common",
  },
  {
    label: "SAINT-ESTEBEN",
    value: "64476",
    type: "common",
  },
  {
    label: "ISTURITS",
    value: "64277",
    type: "common",
  },
  {
    label: "BONLOC",
    value: "64134",
    type: "common",
  },
  {
    label: "AYHERRE",
    value: "64086",
    type: "common",
  },
  {
    label: "MENDIONDE",
    value: "64377",
    type: "common",
  },
  {
    label: "MACAYE",
    value: "64364",
    type: "common",
  },
  {
    label: "ITXASSOU",
    value: "64279",
    type: "common",
  },
  {
    label: "CAMBO-LES-BAINS",
    value: "64160",
    type: "common",
  },
  {
    label: "SOURAIDE",
    value: "64527",
    type: "common",
  },
  {
    label: "LARRESSORE",
    value: "64317",
    type: "common",
  },
  {
    label: "ESPELETTE",
    value: "64213",
    type: "common",
  },
  {
    label: "SAINT-PEE-SUR-NIVELLE",
    value: "64495",
    type: "common",
  },
  {
    label: "ASCAIN",
    value: "64065",
    type: "common",
  },
  {
    label: "URRUGNE",
    value: "64545",
    type: "common",
  },
  {
    label: "BIRIATOU",
    value: "64130",
    type: "common",
  },
  {
    label: "SAINT-TROPEZ",
    value: "83119",
    type: "common",
  },
  {
    label: "GASSIN",
    value: "83065",
    type: "common",
  },
  {
    label: "GRIMAUD",
    value: "83068",
    type: "common",
  },
  {
    label: "COGOLIN",
    value: "83042",
    type: "common",
  },
  {
    label: "LES MAYONS",
    value: "83075",
    type: "common",
  },
  {
    label: "COLLOBRIERES",
    value: "83043",
    type: "common",
  },
  {
    label: "PIGNANS",
    value: "83092",
    type: "common",
  },
  {
    label: "PUGET-VILLE",
    value: "83100",
    type: "common",
  },
  {
    label: "CARNOULES",
    value: "83033",
    type: "common",
  },
  {
    label: "ROCBARON",
    value: "83106",
    type: "common",
  },
  {
    label: "NEOULES",
    value: "83088",
    type: "common",
  },
  {
    label: "MEOUNES-LES-MONTRIEUX",
    value: "83077",
    type: "common",
  },
  {
    label: "SIGNES",
    value: "83127",
    type: "common",
  },
  {
    label: "RIBOUX",
    value: "83105",
    type: "common",
  },
  {
    label: "CUGES-LES-PINS",
    value: "13030",
    type: "common",
  },
  {
    label: "GEMENOS",
    value: "13042",
    type: "common",
  },
  {
    label: "AUBAGNE",
    value: "13005",
    type: "common",
  },
  {
    label: "LA PENNE-SUR-HUVEAUNE",
    value: "13070",
    type: "common",
  },
  {
    label: "MARSEILLE",
    value: "13055",
    type: "common",
  },
  {
    label: "CARRY-LE-ROUET",
    value: "13021",
    type: "common",
  },
  {
    label: "MARSEILLAN",
    value: "34150",
    type: "common",
  },
  {
    label: "BESSAN",
    value: "34031",
    type: "common",
  },
  {
    label: "AGDE",
    value: "34003",
    type: "common",
  },
  {
    label: "MONTBLANC",
    value: "34166",
    type: "common",
  },
  {
    label: "VILLENEUVE-LES-BEZIERS",
    value: "34336",
    type: "common",
  },
  {
    label: "CERS",
    value: "34073",
    type: "common",
  },
  {
    label: "BEZIERS",
    value: "34032",
    type: "common",
  },
  {
    label: "MONTADY",
    value: "34161",
    type: "common",
  },
  {
    label: "MAUREILHAN",
    value: "34155",
    type: "common",
  },
  {
    label: "MARAUSSAN",
    value: "34148",
    type: "common",
  },
  {
    label: "CAPESTANG",
    value: "34052",
    type: "common",
  },
  {
    label: "QUARANTE",
    value: "34226",
    type: "common",
  },
  {
    label: "MONTOULIERS",
    value: "34170",
    type: "common",
  },
  {
    label: "CRUZY",
    value: "34092",
    type: "common",
  },
  {
    label: "AGEL",
    value: "34004",
    type: "common",
  },
  {
    label: "LA CAUNETTE",
    value: "34059",
    type: "common",
  },
  {
    label: "AIGUES-VIVES",
    value: "34007",
    type: "common",
  },
  {
    label: "AIGNE",
    value: "34006",
    type: "common",
  },
  {
    label: "MINERVE",
    value: "34158",
    type: "common",
  },
  {
    label: "CESSERAS",
    value: "34075",
    type: "common",
  },
  {
    label: "AZILLANET",
    value: "34020",
    type: "common",
  },
  {
    label: "SIRAN",
    value: "34302",
    type: "common",
  },
  {
    label: "LA LIVINIERE",
    value: "34141",
    type: "common",
  },
  {
    label: "FELINES-MINERVOIS",
    value: "34097",
    type: "common",
  },
  {
    label: "TRAUSSE",
    value: "11396",
    type: "common",
  },
  {
    label: "CITOU",
    value: "11092",
    type: "common",
  },
  {
    label: "CAUNES-MINERVOIS",
    value: "11081",
    type: "common",
  },
  {
    label: "VILLENEUVE-MINERVOIS",
    value: "11433",
    type: "common",
  },
  {
    label: "TRASSANEL",
    value: "11395",
    type: "common",
  },
  {
    label: "CABRESPINE",
    value: "11056",
    type: "common",
  },
  {
    label: "LIMOUSIS",
    value: "11205",
    type: "common",
  },
  {
    label: "LASTOURS",
    value: "11194",
    type: "common",
  },
  {
    label: "LES ILHES",
    value: "11174",
    type: "common",
  },
  {
    label: "FOURNES-CABARDES",
    value: "11154",
    type: "common",
  },
  {
    label: "VILLARDONNEL",
    value: "11413",
    type: "common",
  },
  {
    label: "VILLANIERE",
    value: "11411",
    type: "common",
  },
  {
    label: "SALSIGNE",
    value: "11372",
    type: "common",
  },
  {
    label: "CUXAC-CABARDES",
    value: "11115",
    type: "common",
  },
  {
    label: "SAINT-DENIS",
    value: "11339",
    type: "common",
  },
  {
    label: "FRAISSE-CABARDES",
    value: "11156",
    type: "common",
  },
  {
    label: "FONTIERS-CABARDES",
    value: "11150",
    type: "common",
  },
  {
    label: "BROUSSES-ET-VILLARET",
    value: "11052",
    type: "common",
  },
  {
    label: "VILLEMAGNE",
    value: "11428",
    type: "common",
  },
  {
    label: "SAISSAC",
    value: "11367",
    type: "common",
  },
  {
    label: "CENNE-MONESTIES",
    value: "11089",
    type: "common",
  },
  {
    label: "VILLESPY",
    value: "11439",
    type: "common",
  },
  {
    label: "VERDUN-EN-LAURAGAIS",
    value: "11407",
    type: "common",
  },
  {
    label: "SAINT-PAPOUL",
    value: "11361",
    type: "common",
  },
  {
    label: "ISSEL",
    value: "11175",
    type: "common",
  },
  {
    label: "CASTELNAUDARY",
    value: "11076",
    type: "common",
  },
  {
    label: "SOUILHE",
    value: "11383",
    type: "common",
  },
  {
    label: "SOUILHANELS",
    value: "11382",
    type: "common",
  },
  {
    label: "RICAUD",
    value: "11313",
    type: "common",
  },
  {
    label: "PEYRENS",
    value: "11284",
    type: "common",
  },
  {
    label: "AIROUX",
    value: "11002",
    type: "common",
  },
  {
    label: "MONTFERRAND",
    value: "11243",
    type: "common",
  },
  {
    label: "LABASTIDE-D'ANJOU",
    value: "11178",
    type: "common",
  },
  {
    label: "BELFLOU",
    value: "11030",
    type: "common",
  },
  {
    label: "BARAIGNE",
    value: "11026",
    type: "common",
  },
  {
    label: "MONTCLAR-LAURAGAIS",
    value: "31368",
    type: "common",
  },
  {
    label: "BEAUTEVILLE",
    value: "31054",
    type: "common",
  },
  {
    label: "SAINT-MICHEL-DE-LANES",
    value: "11359",
    type: "common",
  },
  {
    label: "GOURVIEILLE",
    value: "11166",
    type: "common",
  },
  {
    label: "SEYRE",
    value: "31546",
    type: "common",
  },
  {
    label: "MONTGEARD",
    value: "31380",
    type: "common",
  },
  {
    label: "MONESTROL",
    value: "31354",
    type: "common",
  },
  {
    label: "LAGARDE",
    value: "31262",
    type: "common",
  },
  {
    label: "CAIGNAC",
    value: "31099",
    type: "common",
  },
  {
    label: "NAILLOUX",
    value: "31396",
    type: "common",
  },
  {
    label: "AIGNES",
    value: "31002",
    type: "common",
  },
  {
    label: "MAUVAISIN",
    value: "31332",
    type: "common",
  },
  {
    label: "AUTERIVE",
    value: "31033",
    type: "common",
  },
  {
    label: "PUYDANIEL",
    value: "31442",
    type: "common",
  },
  {
    label: "MAURESSAC",
    value: "31330",
    type: "common",
  },
  {
    label: "LAGRACE-DIEU",
    value: "31264",
    type: "common",
  },
  {
    label: "SAINT-SULPICE-SUR-LEZE",
    value: "31517",
    type: "common",
  },
  {
    label: "MONTAUT",
    value: "31361",
    type: "common",
  },
  {
    label: "AURIBAIL",
    value: "31027",
    type: "common",
  },
  {
    label: "NOE",
    value: "31399",
    type: "common",
  },
  {
    label: "CAPENS",
    value: "31104",
    type: "common",
  },
  {
    label: "PEYSSIES",
    value: "31416",
    type: "common",
  },
  {
    label: "LONGAGES",
    value: "31303",
    type: "common",
  },
  {
    label: "BOIS-DE-LA-PIERRE",
    value: "31071",
    type: "common",
  },
  {
    label: "SAVERES",
    value: "31538",
    type: "common",
  },
  {
    label: "LABASTIDE-CLERMONT",
    value: "31250",
    type: "common",
  },
  {
    label: "GRATENS",
    value: "31229",
    type: "common",
  },
  {
    label: "SAINT-ARAILLE",
    value: "31469",
    type: "common",
  },
  {
    label: "POUY-DE-TOUGES",
    value: "31436",
    type: "common",
  },
  {
    label: "MONTASTRUC-SAVES",
    value: "31359",
    type: "common",
  },
  {
    label: "CASTIES-LABRANDE",
    value: "31122",
    type: "common",
  },
  {
    label: "CAZAC",
    value: "31593",
    type: "common",
  },
  {
    label: "SENARENS",
    value: "31543",
    type: "common",
  },
  {
    label: "RIOLAS",
    value: "31456",
    type: "common",
  },
  {
    label: "POLASTRON",
    value: "31428",
    type: "common",
  },
  {
    label: "LABASTIDE-PAUMES",
    value: "31251",
    type: "common",
  },
  {
    label: "SAINT-FRAJOU",
    value: "31482",
    type: "common",
  },
  {
    label: "FABAS",
    value: "31178",
    type: "common",
  },
  {
    label: "COUEILLES",
    value: "31152",
    type: "common",
  },
  {
    label: "CASTELGAILLARD",
    value: "31115",
    type: "common",
  },
  {
    label: "AGASSAC",
    value: "31001",
    type: "common",
  },
  {
    label: "SAINT-LAURENT",
    value: "31494",
    type: "common",
  },
  {
    label: "PUYMAURIN",
    value: "31443",
    type: "common",
  },
  {
    label: "MONTESQUIEU-GUITTAUT",
    value: "31373",
    type: "common",
  },
  {
    label: "ANAN",
    value: "31008",
    type: "common",
  },
  {
    label: "SAINT-FERREOL-DE-COMMINGES",
    value: "31479",
    type: "common",
  },
  {
    label: "PEGUILHAN",
    value: "31412",
    type: "common",
  },
  {
    label: "NENIGAN",
    value: "31397",
    type: "common",
  },
  {
    label: "SAINT-BLANCARD",
    value: "32365",
    type: "common",
  },
  {
    label: "MANENT-MONTANE",
    value: "32228",
    type: "common",
  },
  {
    label: "LALANNE-ARQUE",
    value: "32185",
    type: "common",
  },
  {
    label: "CABAS-LOUMASSES",
    value: "32067",
    type: "common",
  },
  {
    label: "SARIAC-MAGNOAC",
    value: "65404",
    type: "common",
  },
  {
    label: "PEYRET-SAINT-ANDRE",
    value: "65358",
    type: "common",
  },
  {
    label: "CASTERETS",
    value: "65134",
    type: "common",
  },
  {
    label: "MONT-D'ASTARAC",
    value: "32280",
    type: "common",
  },
  {
    label: "MONLAUR-BERNET",
    value: "32272",
    type: "common",
  },
  {
    label: "CHELAN",
    value: "32103",
    type: "common",
  },
  {
    label: "ARROUEDE",
    value: "32010",
    type: "common",
  },
  {
    label: "LARROQUE",
    value: "65263",
    type: "common",
  },
  {
    label: "GUIZERIX",
    value: "65213",
    type: "common",
  },
  {
    label: "PONSAN-SOUBIRAN",
    value: "32324",
    type: "common",
  },
  {
    label: "CUELAS",
    value: "32114",
    type: "common",
  },
  {
    label: "TRIE-SUR-BAISE",
    value: "65452",
    type: "common",
  },
  {
    label: "SADOURNIN",
    value: "65383",
    type: "common",
  },
  {
    label: "FONTRAILLES",
    value: "65177",
    type: "common",
  },
  {
    label: "DUFFORT",
    value: "32116",
    type: "common",
  },
  {
    label: "OSMETS",
    value: "65342",
    type: "common",
  },
  {
    label: "MAZEROLLES",
    value: "65308",
    type: "common",
  },
  {
    label: "LUBRET-SAINT-LUC",
    value: "65288",
    type: "common",
  },
  {
    label: "LAPEYRE",
    value: "65260",
    type: "common",
  },
  {
    label: "LALANNE-TRIE",
    value: "65250",
    type: "common",
  },
  {
    label: "BOUILH-DEVANT",
    value: "65102",
    type: "common",
  },
  {
    label: "BERNADETS-DEBAT",
    value: "65085",
    type: "common",
  },
  {
    label: "ANTIN",
    value: "65015",
    type: "common",
  },
  {
    label: "TROULEY-LABARTHE",
    value: "65454",
    type: "common",
  },
  {
    label: "SAINT-SEVER-DE-RUSTAN",
    value: "65397",
    type: "common",
  },
  {
    label: "PEYRUN",
    value: "65361",
    type: "common",
  },
  {
    label: "MANSAN",
    value: "65297",
    type: "common",
  },
  {
    label: "LAMEAC",
    value: "65254",
    type: "common",
  },
  {
    label: "JACQUE",
    value: "65232",
    type: "common",
  },
  {
    label: "BOUILH-PEREUILH",
    value: "65103",
    type: "common",
  },
  {
    label: "UGNOUAS",
    value: "65457",
    type: "common",
  },
  {
    label: "TOSTAT",
    value: "65446",
    type: "common",
  },
  {
    label: "SOREAC",
    value: "65430",
    type: "common",
  },
  {
    label: "LOUIT",
    value: "65285",
    type: "common",
  },
  {
    label: "LESCURRY",
    value: "65269",
    type: "common",
  },
  {
    label: "LACASSAGNE",
    value: "65242",
    type: "common",
  },
  {
    label: "ESCONDEAUX",
    value: "65161",
    type: "common",
  },
  {
    label: "DOURS",
    value: "65156",
    type: "common",
  },
  {
    label: "CHIS",
    value: "65146",
    type: "common",
  },
  {
    label: "CASTERA-LOU",
    value: "65133",
    type: "common",
  },
  {
    label: "VILLENAVE-PRES-MARSAC",
    value: "65477",
    type: "common",
  },
  {
    label: "TALAZAC",
    value: "65438",
    type: "common",
  },
  {
    label: "SIARROUY",
    value: "65425",
    type: "common",
  },
  {
    label: "SARNIGUET",
    value: "65406",
    type: "common",
  },
  {
    label: "PUJO",
    value: "65372",
    type: "common",
  },
  {
    label: "MARSAC",
    value: "65299",
    type: "common",
  },
  {
    label: "LAGARDE",
    value: "65244",
    type: "common",
  },
  {
    label: "GAYAN",
    value: "65189",
    type: "common",
  },
  {
    label: "AURENSAN",
    value: "65048",
    type: "common",
  },
  {
    label: "ANDREST",
    value: "65007",
    type: "common",
  },
  {
    label: "TARASTEIX",
    value: "65439",
    type: "common",
  },
  {
    label: "OROIX",
    value: "65341",
    type: "common",
  },
  {
    label: "PONSON-DESSUS",
    value: "64452",
    type: "common",
  },
  {
    label: "PONSON-DEBAT-POUTS",
    value: "64451",
    type: "common",
  },
  {
    label: "MONTANER",
    value: "64398",
    type: "common",
  },
  {
    label: "SAUBOLE",
    value: "64507",
    type: "common",
  },
  {
    label: "LOMBIA",
    value: "64346",
    type: "common",
  },
  {
    label: "ESLOURENTIES-DABAN",
    value: "64211",
    type: "common",
  },
  {
    label: "BEDEILLE",
    value: "64103",
    type: "common",
  },
  {
    label: "AAST",
    value: "64001",
    type: "common",
  },
  {
    label: "UROST",
    value: "64544",
    type: "common",
  },
  {
    label: "SEDZERE",
    value: "64516",
    type: "common",
  },
  {
    label: "ESPECHEDE",
    value: "64212",
    type: "common",
  },
  {
    label: "ARRIEN",
    value: "64053",
    type: "common",
  },
  {
    label: "SENDETS",
    value: "64518",
    type: "common",
  },
  {
    label: "SERRES-MORLAAS",
    value: "64520",
    type: "common",
  },
  {
    label: "OUILLON",
    value: "64438",
    type: "common",
  },
  {
    label: "MORLAAS",
    value: "64405",
    type: "common",
  },
  {
    label: "LEE",
    value: "64329",
    type: "common",
  },
  {
    label: "ANDOINS",
    value: "64021",
    type: "common",
  },
  {
    label: "IDRON",
    value: "64269",
    type: "common",
  },
  {
    label: "PAU",
    value: "64445",
    type: "common",
  },
  {
    label: "BIZANOS",
    value: "64132",
    type: "common",
  },
  {
    label: "LONS",
    value: "64348",
    type: "common",
  },
  {
    label: "LESCAR",
    value: "64335",
    type: "common",
  },
  {
    label: "LAROIN",
    value: "64315",
    type: "common",
  },
  {
    label: "BILLERE",
    value: "64129",
    type: "common",
  },
  {
    label: "ARTIGUELOUVE",
    value: "64060",
    type: "common",
  },
  {
    label: "ARBUS",
    value: "64037",
    type: "common",
  },
  {
    label: "PARBAYSE",
    value: "64442",
    type: "common",
  },
  {
    label: "MONEIN",
    value: "64393",
    type: "common",
  },
  {
    label: "CUQUERON",
    value: "64197",
    type: "common",
  },
  {
    label: "OGENNE-CAMPTORT",
    value: "64420",
    type: "common",
  },
  {
    label: "LUCQ-DE-BEARN",
    value: "64359",
    type: "common",
  },
  {
    label: "SUSMIOU",
    value: "64530",
    type: "common",
  },
  {
    label: "SUS",
    value: "64529",
    type: "common",
  },
  {
    label: "NAVARRENX",
    value: "64416",
    type: "common",
  },
  {
    label: "LAY-LAMIDOU",
    value: "64326",
    type: "common",
  },
  {
    label: "JASSES",
    value: "64281",
    type: "common",
  },
  {
    label: "GURS",
    value: "64253",
    type: "common",
  },
  {
    label: "DOGNEN",
    value: "64201",
    type: "common",
  },
  {
    label: "CHARRE",
    value: "64186",
    type: "common",
  },
  {
    label: "CASTETNAU-CAMBLONG",
    value: "64178",
    type: "common",
  },
  {
    label: "ARRAST-LARREBIEU",
    value: "64050",
    type: "common",
  },
  {
    label: "ANGOUS",
    value: "64025",
    type: "common",
  },
  {
    label: "AROUE-ITHOROTS-OLHAIBY",
    value: "64049",
    type: "common",
  },
  {
    label: "LICHOS",
    value: "64341",
    type: "common",
  },
  {
    label: "ESPES-UNDUREIN",
    value: "64214",
    type: "common",
  },
  {
    label: "CHARRITTE-DE-BAS",
    value: "64187",
    type: "common",
  },
  {
    label: "LOHITZUN-OYHERCQ",
    value: "64345",
    type: "common",
  },
  {
    label: "LARRIBAR-SORHAPURU",
    value: "64319",
    type: "common",
  },
  {
    label: "UHART-MIXE",
    value: "64539",
    type: "common",
  },
  {
    label: "BEHASQUE-LAPISTE",
    value: "64106",
    type: "common",
  },
  {
    label: "BEYRIE-SUR-JOYEUSE",
    value: "64120",
    type: "common",
  },
  {
    label: "ORSANCO",
    value: "64429",
    type: "common",
  },
  {
    label: "SAINT-PALAIS",
    value: "64493",
    type: "common",
  },
  {
    label: "IHOLDY",
    value: "64271",
    type: "common",
  },
  {
    label: "ARMENDARITS",
    value: "64046",
    type: "common",
  },
  {
    label: "HELETTE",
    value: "64259",
    type: "common",
  },
  {
    label: "LOUHOSSOA",
    value: "64350",
    type: "common",
  },
  {
    label: "BIDARRAY",
    value: "64124",
    type: "common",
  },
  {
    label: "AINHOA",
    value: "64014",
    type: "common",
  },
  {
    label: "SARE",
    value: "64504",
    type: "common",
  },
  {
    label: "RAMATUELLE",
    value: "83101",
    type: "common",
  },
  {
    label: "LA CROIX-VALMER",
    value: "83048",
    type: "common",
  },
  {
    label: "LA MOLE",
    value: "83079",
    type: "common",
  },
  {
    label: "CAVALAIRE-SUR-MER",
    value: "83036",
    type: "common",
  },
  {
    label: "PIERREFEU-DU-VAR",
    value: "83091",
    type: "common",
  },
  {
    label: "SOLLIES-TOUCAS",
    value: "83131",
    type: "common",
  },
  {
    label: "CUERS",
    value: "83049",
    type: "common",
  },
  {
    label: "BELGENTIER",
    value: "83017",
    type: "common",
  },
  {
    label: "LE BEAUSSET",
    value: "83016",
    type: "common",
  },
  {
    label: "LE CASTELLET",
    value: "83035",
    type: "common",
  },
  {
    label: "ROQUEFORT-LA-BEDOULE",
    value: "13085",
    type: "common",
  },
  {
    label: "CEYRESTE",
    value: "13023",
    type: "common",
  },
  {
    label: "CARNOUX-EN-PROVENCE",
    value: "13119",
    type: "common",
  },
  {
    label: "CASSIS",
    value: "13022",
    type: "common",
  },
  {
    label: "VIAS",
    value: "34332",
    type: "common",
  },
  {
    label: "PORTIRAGNES",
    value: "34209",
    type: "common",
  },
  {
    label: "SERIGNAN",
    value: "34299",
    type: "common",
  },
  {
    label: "SAUVIAN",
    value: "34298",
    type: "common",
  },
  {
    label: "VENDRES",
    value: "34329",
    type: "common",
  },
  {
    label: "LESPIGNAN",
    value: "34135",
    type: "common",
  },
  {
    label: "POILHES",
    value: "34206",
    type: "common",
  },
  {
    label: "NISSAN-LEZ-ENSERUNE",
    value: "34183",
    type: "common",
  },
  {
    label: "COLOMBIERS",
    value: "34081",
    type: "common",
  },
  {
    label: "MONTELS",
    value: "34167",
    type: "common",
  },
  {
    label: "SALLELES-D'AUDE",
    value: "11369",
    type: "common",
  },
  {
    label: "OUVEILLAN",
    value: "11269",
    type: "common",
  },
  {
    label: "CUXAC-D'AUDE",
    value: "11116",
    type: "common",
  },
  {
    label: "SAINTE-VALIERE",
    value: "11366",
    type: "common",
  },
  {
    label: "MIREPEISSET",
    value: "11233",
    type: "common",
  },
  {
    label: "GINESTAS",
    value: "11164",
    type: "common",
  },
  {
    label: "BIZE-MINERVOIS",
    value: "11041",
    type: "common",
  },
  {
    label: "ARGELIERS",
    value: "11012",
    type: "common",
  },
  {
    label: "OUPIA",
    value: "34190",
    type: "common",
  },
  {
    label: "BEAUFORT",
    value: "34026",
    type: "common",
  },
  {
    label: "POUZOLS-MINERVOIS",
    value: "11296",
    type: "common",
  },
  {
    label: "MAILHAC",
    value: "11212",
    type: "common",
  },
  {
    label: "OLONZAC",
    value: "34189",
    type: "common",
  },
  {
    label: "PEPIEUX",
    value: "11280",
    type: "common",
  },
  {
    label: "HOMPS",
    value: "11172",
    type: "common",
  },
  {
    label: "AZILLE",
    value: "11022",
    type: "common",
  },
  {
    label: "RIEUX-MINERVOIS",
    value: "11315",
    type: "common",
  },
  {
    label: "PEYRIAC-MINERVOIS",
    value: "11286",
    type: "common",
  },
  {
    label: "VILLEGLY",
    value: "11426",
    type: "common",
  },
  {
    label: "VILLARZEL-CABARDES",
    value: "11416",
    type: "common",
  },
  {
    label: "LAURE-MINERVOIS",
    value: "11198",
    type: "common",
  },
  {
    label: "BAGNOLES",
    value: "11025",
    type: "common",
  },
  {
    label: "SALLELES-CABARDES",
    value: "11368",
    type: "common",
  },
  {
    label: "CONQUES-SUR-ORBIEL",
    value: "11099",
    type: "common",
  },
  {
    label: "VILLEGAILHENC",
    value: "11425",
    type: "common",
  },
  {
    label: "VENTENAC-CABARDES",
    value: "11404",
    type: "common",
  },
  {
    label: "ARAGON",
    value: "11011",
    type: "common",
  },
  {
    label: "MOUSSOULENS",
    value: "11259",
    type: "common",
  },
  {
    label: "MONTOLIEU",
    value: "11253",
    type: "common",
  },
  {
    label: "SAINT-MARTIN-LE-VIEIL",
    value: "11357",
    type: "common",
  },
  {
    label: "RAISSAC-SUR-LAMPY",
    value: "11308",
    type: "common",
  },
  {
    label: "CARLIPA",
    value: "11070",
    type: "common",
  },
  {
    label: "VILLEPINTE",
    value: "11434",
    type: "common",
  },
  {
    label: "PEXIORA",
    value: "11281",
    type: "common",
  },
  {
    label: "LASBORDES",
    value: "11192",
    type: "common",
  },
  {
    label: "SAINT-MARTIN-LALANDE",
    value: "11356",
    type: "common",
  },
  {
    label: "VILLENEUVE-LA-COMPTAL",
    value: "11430",
    type: "common",
  },
  {
    label: "MAS-SAINTES-PUELLES",
    value: "11225",
    type: "common",
  },
  {
    label: "FENDEILLE",
    value: "11138",
    type: "common",
  },
  {
    label: "PAYRA-SUR-L'HERS",
    value: "11275",
    type: "common",
  },
  {
    label: "MONTAURIOL",
    value: "11239",
    type: "common",
  },
  {
    label: "MOLLEVILLE",
    value: "11238",
    type: "common",
  },
  {
    label: "CUMIES",
    value: "11114",
    type: "common",
  },
  {
    label: "SALLES-SUR-L'HERS",
    value: "11371",
    type: "common",
  },
  {
    label: "SAINTE-CAMELLE",
    value: "11334",
    type: "common",
  },
  {
    label: "MARQUEIN",
    value: "11218",
    type: "common",
  },
  {
    label: "LA LOUVIERE-LAURAGAIS",
    value: "11208",
    type: "common",
  },
  {
    label: "FAJAC-LA-RELENQUE",
    value: "11134",
    type: "common",
  },
  {
    label: "GIBEL",
    value: "31220",
    type: "common",
  },
  {
    label: "CALMONT",
    value: "31100",
    type: "common",
  },
  {
    label: "CINTEGABELLE",
    value: "31145",
    type: "common",
  },
  {
    label: "SAINT-QUIRC",
    value: "09275",
    type: "common",
  },
  {
    label: "LISSAC",
    value: "09170",
    type: "common",
  },
  {
    label: "LABATUT",
    value: "09147",
    type: "common",
  },
  {
    label: "GRAZAC",
    value: "31231",
    type: "common",
  },
  {
    label: "GAILLAC-TOULZA",
    value: "31206",
    type: "common",
  },
  {
    label: "ESPERCE",
    value: "31173",
    type: "common",
  },
  {
    label: "CAUJAC",
    value: "31128",
    type: "common",
  },
  {
    label: "LEZAT-SUR-LEZE",
    value: "09167",
    type: "common",
  },
  {
    label: "MONTGAZIN",
    value: "31379",
    type: "common",
  },
  {
    label: "MARQUEFAVE",
    value: "31320",
    type: "common",
  },
  {
    label: "LACAUGNE",
    value: "31258",
    type: "common",
  },
  {
    label: "SALLES-SUR-GARONNE",
    value: "31525",
    type: "common",
  },
  {
    label: "RIEUX-VOLVESTRE",
    value: "31455",
    type: "common",
  },
  {
    label: "LAFITTE-VIGORDANE",
    value: "31261",
    type: "common",
  },
  {
    label: "CARBONNE",
    value: "31107",
    type: "common",
  },
  {
    label: "SAINT-ELIX-LE-CHATEAU",
    value: "31476",
    type: "common",
  },
  {
    label: "MARIGNAC-LASCLARES",
    value: "31317",
    type: "common",
  },
  {
    label: "LAVELANET-DE-COMMINGES",
    value: "31286",
    type: "common",
  },
  {
    label: "MONTOUSSIN",
    value: "31387",
    type: "common",
  },
  {
    label: "FRANCON",
    value: "31196",
    type: "common",
  },
  {
    label: "LE FOUSSERET",
    value: "31193",
    type: "common",
  },
  {
    label: "CASTELNAU-PICAMPEAU",
    value: "31119",
    type: "common",
  },
  {
    label: "SAMOUILLAN",
    value: "31529",
    type: "common",
  },
  {
    label: "PEYRISSAS",
    value: "31414",
    type: "common",
  },
  {
    label: "MONTEGUT-BOURJAC",
    value: "31370",
    type: "common",
  },
  {
    label: "LUSSAN-ADEILHAC",
    value: "31309",
    type: "common",
  },
  {
    label: "FUSTIGNAC",
    value: "31204",
    type: "common",
  },
  {
    label: "BENQUE",
    value: "31063",
    type: "common",
  },
  {
    label: "SALERM",
    value: "31522",
    type: "common",
  },
  {
    label: "SAINT-ANDRE",
    value: "31468",
    type: "common",
  },
  {
    label: "EOUX",
    value: "31168",
    type: "common",
  },
  {
    label: "MONTBERNARD",
    value: "31363",
    type: "common",
  },
  {
    label: "LILHAC",
    value: "31301",
    type: "common",
  },
  {
    label: "ESPARRON",
    value: "31172",
    type: "common",
  },
  {
    label: "ESCANECRABE",
    value: "31170",
    type: "common",
  },
  {
    label: "CASTERA-VIGNOLES",
    value: "31121",
    type: "common",
  },
  {
    label: "SAINT-PE-DELBOSC",
    value: "31510",
    type: "common",
  },
  {
    label: "MONTGAILLARD-SUR-SAVE",
    value: "31378",
    type: "common",
  },
  {
    label: "MONDILHAN",
    value: "31350",
    type: "common",
  },
  {
    label: "CIADOUX",
    value: "31141",
    type: "common",
  },
  {
    label: "THERMES-MAGNOAC",
    value: "65442",
    type: "common",
  },
  {
    label: "GENSAC-DE-BOULOGNE",
    value: "31218",
    type: "common",
  },
  {
    label: "BOULOGNE-SUR-GESSE",
    value: "31080",
    type: "common",
  },
  {
    label: "BLAJAN",
    value: "31070",
    type: "common",
  },
  {
    label: "VILLEMUR",
    value: "65475",
    type: "common",
  },
  {
    label: "POUY",
    value: "65368",
    type: "common",
  },
  {
    label: "LALANNE",
    value: "65249",
    type: "common",
  },
  {
    label: "DEVEZE",
    value: "65155",
    type: "common",
  },
  {
    label: "CASTELNAU-MAGNOAC",
    value: "65129",
    type: "common",
  },
  {
    label: "BETBEZE",
    value: "65088",
    type: "common",
  },
  {
    label: "ARIES-ESPENAN",
    value: "65026",
    type: "common",
  },
  {
    label: "VIEUZOS",
    value: "65468",
    type: "common",
  },
  {
    label: "PUNTOUS",
    value: "65373",
    type: "common",
  },
  {
    label: "ORGAN",
    value: "65336",
    type: "common",
  },
  {
    label: "HACHAN",
    value: "65214",
    type: "common",
  },
  {
    label: "CIZOS",
    value: "65148",
    type: "common",
  },
  {
    label: "CAMPUZAN",
    value: "65126",
    type: "common",
  },
  {
    label: "BETPOUY",
    value: "65090",
    type: "common",
  },
  {
    label: "BARTHE",
    value: "65068",
    type: "common",
  },
  {
    label: "TOURNOUS-DEVANT",
    value: "65449",
    type: "common",
  },
  {
    label: "TOURNOUS-DARRE",
    value: "65448",
    type: "common",
  },
  {
    label: "SENTOUS",
    value: "65419",
    type: "common",
  },
  {
    label: "PUYDARRIEUX",
    value: "65374",
    type: "common",
  },
  {
    label: "LIBAROS",
    value: "65274",
    type: "common",
  },
  {
    label: "BONNEFONT",
    value: "65095",
    type: "common",
  },
  {
    label: "VILLEMBITS",
    value: "65474",
    type: "common",
  },
  {
    label: "VIDOU",
    value: "65461",
    type: "common",
  },
  {
    label: "SERE-RUSTAING",
    value: "65423",
    type: "common",
  },
  {
    label: "PEYRIGUERE",
    value: "65359",
    type: "common",
  },
  {
    label: "MUN",
    value: "65326",
    type: "common",
  },
  {
    label: "LUSTAR",
    value: "65293",
    type: "common",
  },
  {
    label: "LUBY-BETMONT",
    value: "65289",
    type: "common",
  },
  {
    label: "LAMARQUE-RUSTAING",
    value: "65253",
    type: "common",
  },
  {
    label: "BUGARD",
    value: "65110",
    type: "common",
  },
  {
    label: "THUY",
    value: "65443",
    type: "common",
  },
  {
    label: "MARSEILLAN",
    value: "65301",
    type: "common",
  },
  {
    label: "MARQUERIE",
    value: "65298",
    type: "common",
  },
  {
    label: "GOUDON",
    value: "65206",
    type: "common",
  },
  {
    label: "COUSSAN",
    value: "65153",
    type: "common",
  },
  {
    label: "CHELLE-DEBAT",
    value: "65142",
    type: "common",
  },
  {
    label: "CASTELVIEILH",
    value: "65131",
    type: "common",
  },
  {
    label: "CABANAC",
    value: "65115",
    type: "common",
  },
  {
    label: "AUBAREDE",
    value: "65044",
    type: "common",
  },
  {
    label: "SOUYEAUX",
    value: "65436",
    type: "common",
  },
  {
    label: "SABALOS",
    value: "65380",
    type: "common",
  },
  {
    label: "POUYASTRUC",
    value: "65369",
    type: "common",
  },
  {
    label: "ORLEIX",
    value: "65340",
    type: "common",
  },
  {
    label: "OLEAC-DEBAT",
    value: "65332",
    type: "common",
  },
  {
    label: "LIZOS",
    value: "65276",
    type: "common",
  },
  {
    label: "HOURC",
    value: "65225",
    type: "common",
  },
  {
    label: "COLLONGUES",
    value: "65151",
    type: "common",
  },
  {
    label: "BOULIN",
    value: "65104",
    type: "common",
  },
  {
    label: "AUREILHAN",
    value: "65047",
    type: "common",
  },
  {
    label: "OURSBELILLE",
    value: "65350",
    type: "common",
  },
  {
    label: "BOURS",
    value: "65108",
    type: "common",
  },
  {
    label: "BORDERES-SUR-L'ECHEZ",
    value: "65100",
    type: "common",
  },
  {
    label: "BAZET",
    value: "65072",
    type: "common",
  },
  {
    label: "PINTAC",
    value: "65364",
    type: "common",
  },
  {
    label: "GER",
    value: "64238",
    type: "common",
  },
  {
    label: "LIVRON",
    value: "64344",
    type: "common",
  },
  {
    label: "SOUMOULOU",
    value: "64526",
    type: "common",
  },
  {
    label: "NOUSTY",
    value: "64419",
    type: "common",
  },
  {
    label: "LOURENTIES",
    value: "64352",
    type: "common",
  },
  {
    label: "LIMENDOUS",
    value: "64343",
    type: "common",
  },
  {
    label: "GOMER",
    value: "64246",
    type: "common",
  },
  {
    label: "ESPOEY",
    value: "64216",
    type: "common",
  },
  {
    label: "OUSSE",
    value: "64439",
    type: "common",
  },
  {
    label: "BORDES",
    value: "64138",
    type: "common",
  },
  {
    label: "ANGAIS",
    value: "64023",
    type: "common",
  },
  {
    label: "ASSAT",
    value: "64067",
    type: "common",
  },
  {
    label: "ARTIGUELOUTAN",
    value: "64059",
    type: "common",
  },
  {
    label: "UZOS",
    value: "64550",
    type: "common",
  },
  {
    label: "RONTIGNON",
    value: "64467",
    type: "common",
  },
  {
    label: "NARCASTET",
    value: "64413",
    type: "common",
  },
  {
    label: "MEILLON",
    value: "64376",
    type: "common",
  },
  {
    label: "MAZERES-LEZONS",
    value: "64373",
    type: "common",
  },
  {
    label: "GELOS",
    value: "64237",
    type: "common",
  },
  {
    label: "ARESSY",
    value: "64041",
    type: "common",
  },
  {
    label: "SAINT-FAUST",
    value: "64478",
    type: "common",
  },
  {
    label: "JURANCON",
    value: "64284",
    type: "common",
  },
  {
    label: "LACOMMANDE",
    value: "64299",
    type: "common",
  },
  {
    label: "AUBERTIN",
    value: "64072",
    type: "common",
  },
  {
    label: "LEDEUIX",
    value: "64328",
    type: "common",
  },
  {
    label: "GOES",
    value: "64245",
    type: "common",
  },
  {
    label: "ESTIALESCQ",
    value: "64219",
    type: "common",
  },
  {
    label: "CARDESSE",
    value: "64165",
    type: "common",
  },
  {
    label: "VERDETS",
    value: "64551",
    type: "common",
  },
  {
    label: "SAUCEDE",
    value: "64508",
    type: "common",
  },
  {
    label: "PRECHACQ-NAVARRENX",
    value: "64459",
    type: "common",
  },
  {
    label: "POEY-D'OLORON",
    value: "64449",
    type: "common",
  },
  {
    label: "ORIN",
    value: "64426",
    type: "common",
  },
  {
    label: "SAINT-GOIN",
    value: "64481",
    type: "common",
  },
  {
    label: "PRECHACQ-JOSBAIG",
    value: "64458",
    type: "common",
  },
  {
    label: "L'HOPITAL-SAINT-BLAISE",
    value: "64264",
    type: "common",
  },
  {
    label: "GEUS-D'OLORON",
    value: "64244",
    type: "common",
  },
  {
    label: "AREN",
    value: "64039",
    type: "common",
  },
  {
    label: "MONCAYOLLE-LARRORY-MENDIBIEU",
    value: "64391",
    type: "common",
  },
  {
    label: "CHERAUTE",
    value: "64188",
    type: "common",
  },
  {
    label: "BERROGAIN-LARUNS",
    value: "64115",
    type: "common",
  },
  {
    label: "VIODOS-ABENSE-DE-BAS",
    value: "64559",
    type: "common",
  },
  {
    label: "MAULEON-LICHARRE",
    value: "64371",
    type: "common",
  },
  {
    label: "GARINDEIN",
    value: "64231",
    type: "common",
  },
  {
    label: "AINHARP",
    value: "64012",
    type: "common",
  },
  {
    label: "PAGOLLE",
    value: "64441",
    type: "common",
  },
  {
    label: "ARHANSUS",
    value: "64045",
    type: "common",
  },
  {
    label: "OSTABAT-ASME",
    value: "64437",
    type: "common",
  },
  {
    label: "LARCEVEAU-ARROS-CIBITS",
    value: "64314",
    type: "common",
  },
  {
    label: "JUXUE",
    value: "64285",
    type: "common",
  },
  {
    label: "BUNUS",
    value: "64150",
    type: "common",
  },
  {
    label: "LANTABAT",
    value: "64313",
    type: "common",
  },
  {
    label: "AINHICE-MONGELOS",
    value: "64013",
    type: "common",
  },
  {
    label: "SUHESCUN",
    value: "64528",
    type: "common",
  },
  {
    label: "JAXU",
    value: "64283",
    type: "common",
  },
  {
    label: "IRISSARRY",
    value: "64273",
    type: "common",
  },
  {
    label: "SAINT-MARTIN-D'ARROSSA",
    value: "64490",
    type: "common",
  },
  {
    label: "OSSES",
    value: "64436",
    type: "common",
  },
  {
    label: "ROGLIANO",
    value: "2B261",
    type: "common",
  },
  {
    label: "ERSA",
    value: "2B107",
    type: "common",
  },
  {
    label: "RAYOL-CANADEL-SUR-MER",
    value: "83152",
    type: "common",
  },
  {
    label: "LE LAVANDOU",
    value: "83070",
    type: "common",
  },
  {
    label: "BORMES-LES-MIMOSAS",
    value: "83019",
    type: "common",
  },
  {
    label: "LA LONDE-LES-MAURES",
    value: "83071",
    type: "common",
  },
  {
    label: "SOLLIES-PONT",
    value: "83130",
    type: "common",
  },
  {
    label: "LA CRAU",
    value: "83047",
    type: "common",
  },
  {
    label: "LA VALETTE-DU-VAR",
    value: "83144",
    type: "common",
  },
  {
    label: "SOLLIES-VILLE",
    value: "83132",
    type: "common",
  },
  {
    label: "LA FARLEDE",
    value: "83054",
    type: "common",
  },
  {
    label: "LE REVEST-LES-EAUX",
    value: "83103",
    type: "common",
  },
  {
    label: "EVENOS",
    value: "83053",
    type: "common",
  },
  {
    label: "BANDOL",
    value: "83009",
    type: "common",
  },
  {
    label: "SAINT-CYR-SUR-MER",
    value: "83112",
    type: "common",
  },
  {
    label: "LA CADIERE-D'AZUR",
    value: "83027",
    type: "common",
  },
  {
    label: "LA CIOTAT",
    value: "13028",
    type: "common",
  },
  {
    label: "VALRAS-PLAGE",
    value: "34324",
    type: "common",
  },
  {
    label: "FLEURY",
    value: "11145",
    type: "common",
  },
  {
    label: "VINASSAN",
    value: "11441",
    type: "common",
  },
  {
    label: "SALLES-D'AUDE",
    value: "11370",
    type: "common",
  },
  {
    label: "COURSAN",
    value: "11106",
    type: "common",
  },
  {
    label: "SAINT-MARCEL-SUR-AUDE",
    value: "11353",
    type: "common",
  },
  {
    label: "MOUSSAN",
    value: "11258",
    type: "common",
  },
  {
    label: "VILLEDAIGNE",
    value: "11421",
    type: "common",
  },
  {
    label: "VENTENAC-EN-MINERVOIS",
    value: "11405",
    type: "common",
  },
  {
    label: "SAINT-NAZAIRE-D'AUDE",
    value: "11360",
    type: "common",
  },
  {
    label: "RAISSAC-D'AUDE",
    value: "11307",
    type: "common",
  },
  {
    label: "NEVIAN",
    value: "11264",
    type: "common",
  },
  {
    label: "MARCORIGNAN",
    value: "11217",
    type: "common",
  },
  {
    label: "ROUBIA",
    value: "11324",
    type: "common",
  },
  {
    label: "PARAZA",
    value: "11273",
    type: "common",
  },
  {
    label: "CRUSCADES",
    value: "11111",
    type: "common",
  },
  {
    label: "CANET",
    value: "11067",
    type: "common",
  },
  {
    label: "ARGENS-MINERVOIS",
    value: "11013",
    type: "common",
  },
  {
    label: "TOUROUZELLE",
    value: "11393",
    type: "common",
  },
  {
    label: "MONTBRUN-DES-CORBIERES",
    value: "11241",
    type: "common",
  },
  {
    label: "LEZIGNAN-CORBIERES",
    value: "11203",
    type: "common",
  },
  {
    label: "ESCALES",
    value: "11126",
    type: "common",
  },
  {
    label: "LA REDORTE",
    value: "11190",
    type: "common",
  },
  {
    label: "SAINT-COUAT-D'AUDE",
    value: "11337",
    type: "common",
  },
  {
    label: "ROQUECOURBE-MINERVOIS",
    value: "11318",
    type: "common",
  },
  {
    label: "PUICHERIC",
    value: "11301",
    type: "common",
  },
  {
    label: "CASTELNAU-D'AUDE",
    value: "11077",
    type: "common",
  },
  {
    label: "SAINT-FRICHOUX",
    value: "11342",
    type: "common",
  },
  {
    label: "MARSEILLETTE",
    value: "11220",
    type: "common",
  },
  {
    label: "BLOMAC",
    value: "11042",
    type: "common",
  },
  {
    label: "AIGUES-VIVES",
    value: "11001",
    type: "common",
  },
  {
    label: "TREBES",
    value: "11397",
    type: "common",
  },
  {
    label: "RUSTIQUES",
    value: "11330",
    type: "common",
  },
  {
    label: "MALVES-EN-MINERVOIS",
    value: "11215",
    type: "common",
  },
  {
    label: "BOUILHONNAC",
    value: "11043",
    type: "common",
  },
  {
    label: "BADENS",
    value: "11023",
    type: "common",
  },
  {
    label: "VILLEMOUSTAUSSOU",
    value: "11429",
    type: "common",
  },
  {
    label: "VILLEDUBERT",
    value: "11422",
    type: "common",
  },
  {
    label: "VILLALIER",
    value: "11410",
    type: "common",
  },
  {
    label: "BERRIAC",
    value: "11037",
    type: "common",
  },
  {
    label: "PENNAUTIER",
    value: "11279",
    type: "common",
  },
  {
    label: "CARCASSONNE",
    value: "11069",
    type: "common",
  },
  {
    label: "VILLESEQUELANDE",
    value: "11437",
    type: "common",
  },
  {
    label: "SAINTE-EULALIE",
    value: "11340",
    type: "common",
  },
  {
    label: "PEZENS",
    value: "11288",
    type: "common",
  },
  {
    label: "CAUX-ET-SAUZENS",
    value: "11084",
    type: "common",
  },
  {
    label: "ALZONNE",
    value: "11009",
    type: "common",
  },
  {
    label: "VILLESISCLE",
    value: "11438",
    type: "common",
  },
  {
    label: "BRAM",
    value: "11049",
    type: "common",
  },
  {
    label: "LAURABUC",
    value: "11195",
    type: "common",
  },
  {
    label: "MIREVAL-LAURAGAIS",
    value: "11234",
    type: "common",
  },
  {
    label: "VILLASAVARY",
    value: "11418",
    type: "common",
  },
  {
    label: "LAURAC",
    value: "11196",
    type: "common",
  },
  {
    label: "SAINT-AMANS",
    value: "11331",
    type: "common",
  },
  {
    label: "GENERVILLE",
    value: "11162",
    type: "common",
  },
  {
    label: "FONTERS-DU-RAZES",
    value: "11149",
    type: "common",
  },
  {
    label: "SAINT-SERNIN",
    value: "11365",
    type: "common",
  },
  {
    label: "PEYREFITTE-SUR-L'HERS",
    value: "11283",
    type: "common",
  },
  {
    label: "PECH-LUNA",
    value: "11278",
    type: "common",
  },
  {
    label: "MAYREVILLE",
    value: "11226",
    type: "common",
  },
  {
    label: "MOLANDIER",
    value: "11236",
    type: "common",
  },
  {
    label: "MEZERVILLE",
    value: "11231",
    type: "common",
  },
  {
    label: "BELPECH",
    value: "11033",
    type: "common",
  },
  {
    label: "MAZERES",
    value: "09185",
    type: "common",
  },
  {
    label: "SAVERDUN",
    value: "09282",
    type: "common",
  },
  {
    label: "JUSTINIAC",
    value: "09146",
    type: "common",
  },
  {
    label: "CANTE",
    value: "09076",
    type: "common",
  },
  {
    label: "BRIE",
    value: "09067",
    type: "common",
  },
  {
    label: "MARLIAC",
    value: "31319",
    type: "common",
  },
  {
    label: "VILLENEUVE-DU-LATOU",
    value: "09338",
    type: "common",
  },
  {
    label: "MASSABRAC",
    value: "31326",
    type: "common",
  },
  {
    label: "CASTAGNAC",
    value: "31111",
    type: "common",
  },
  {
    label: "CANENS",
    value: "31103",
    type: "common",
  },
  {
    label: "SAINTE-SUZANNE",
    value: "09342",
    type: "common",
  },
  {
    label: "SAINT-YBARS",
    value: "09277",
    type: "common",
  },
  {
    label: "MAILHOLAS",
    value: "31312",
    type: "common",
  },
  {
    label: "LATRAPE",
    value: "31280",
    type: "common",
  },
  {
    label: "LATOUR",
    value: "31279",
    type: "common",
  },
  {
    label: "LAPEYRERE",
    value: "31272",
    type: "common",
  },
  {
    label: "BAX",
    value: "31047",
    type: "common",
  },
  {
    label: "SAINT-JULIEN-SUR-GARONNE",
    value: "31492",
    type: "common",
  },
  {
    label: "GOUTEVERNISSE",
    value: "31225",
    type: "common",
  },
  {
    label: "GENSAC-SUR-GARONNE",
    value: "31219",
    type: "common",
  },
  {
    label: "COULADERE",
    value: "31153",
    type: "common",
  },
  {
    label: "CAZERES",
    value: "31135",
    type: "common",
  },
  {
    label: "SANA",
    value: "31530",
    type: "common",
  },
  {
    label: "PALAMINY",
    value: "31406",
    type: "common",
  },
  {
    label: "MONDAVEZAN",
    value: "31349",
    type: "common",
  },
  {
    label: "MARTRES-TOLOSANE",
    value: "31324",
    type: "common",
  },
  {
    label: "LESCUNS",
    value: "31292",
    type: "common",
  },
  {
    label: "TERREBASSE",
    value: "31552",
    type: "common",
  },
  {
    label: "MARIGNAC-LASPEYRES",
    value: "31318",
    type: "common",
  },
  {
    label: "BACHAS",
    value: "31039",
    type: "common",
  },
  {
    label: "ALAN",
    value: "31005",
    type: "common",
  },
  {
    label: "SAINT-ELIX-SEGLAN",
    value: "31477",
    type: "common",
  },
  {
    label: "PEYROUZET",
    value: "31415",
    type: "common",
  },
  {
    label: "MONTOULIEU-SAINT-BERNARD",
    value: "31386",
    type: "common",
  },
  {
    label: "BOUSSAN",
    value: "31083",
    type: "common",
  },
  {
    label: "AURIGNAC",
    value: "31028",
    type: "common",
  },
  {
    label: "CASSAGNABERE-TOURNAS",
    value: "31109",
    type: "common",
  },
  {
    label: "SAMAN",
    value: "31528",
    type: "common",
  },
  {
    label: "SAINT-MARCET",
    value: "31502",
    type: "common",
  },
  {
    label: "SAINT-LARY-BOUJEAN",
    value: "31493",
    type: "common",
  },
  {
    label: "LESPUGUE",
    value: "31295",
    type: "common",
  },
  {
    label: "CHARLAS",
    value: "31138",
    type: "common",
  },
  {
    label: "CARDEILHAC",
    value: "31108",
    type: "common",
  },
  {
    label: "SARREMEZAN",
    value: "31532",
    type: "common",
  },
  {
    label: "SARRECAVE",
    value: "31531",
    type: "common",
  },
  {
    label: "NIZAN-GESSE",
    value: "31398",
    type: "common",
  },
  {
    label: "MONTMAURIN",
    value: "31385",
    type: "common",
  },
  {
    label: "LARROQUE",
    value: "31276",
    type: "common",
  },
  {
    label: "MONLEON-MAGNOAC",
    value: "65315",
    type: "common",
  },
  {
    label: "BAZORDAN",
    value: "65074",
    type: "common",
  },
  {
    label: "SAINT-LOUP-EN-COMMINGES",
    value: "31498",
    type: "common",
  },
  {
    label: "BALESTA",
    value: "31043",
    type: "common",
  },
  {
    label: "SABARROS",
    value: "65381",
    type: "common",
  },
  {
    label: "RECURT",
    value: "65376",
    type: "common",
  },
  {
    label: "MONLONG",
    value: "65316",
    type: "common",
  },
  {
    label: "LASSALES",
    value: "65266",
    type: "common",
  },
  {
    label: "LARAN",
    value: "65261",
    type: "common",
  },
  {
    label: "GAUSSAN",
    value: "65187",
    type: "common",
  },
  {
    label: "CAUBOUS",
    value: "65136",
    type: "common",
  },
  {
    label: "GALEZ",
    value: "65184",
    type: "common",
  },
  {
    label: "GALAN",
    value: "65183",
    type: "common",
  },
  {
    label: "CASTELBAJAC",
    value: "65128",
    type: "common",
  },
  {
    label: "BONREPOS",
    value: "65097",
    type: "common",
  },
  {
    label: "ORIEUX",
    value: "65337",
    type: "common",
  },
  {
    label: "MONTASTRUC",
    value: "65318",
    type: "common",
  },
  {
    label: "BURG",
    value: "65113",
    type: "common",
  },
  {
    label: "BERNADETS-DESSUS",
    value: "65086",
    type: "common",
  },
  {
    label: "TOURNAY",
    value: "65447",
    type: "common",
  },
  {
    label: "SINZOS",
    value: "65426",
    type: "common",
  },
  {
    label: "PEYRAUBE",
    value: "65357",
    type: "common",
  },
  {
    label: "MOULEDOUS",
    value: "65324",
    type: "common",
  },
  {
    label: "LHEZ",
    value: "65272",
    type: "common",
  },
  {
    label: "GONEZ",
    value: "65204",
    type: "common",
  },
  {
    label: "CLARAC",
    value: "65149",
    type: "common",
  },
  {
    label: "BORDES",
    value: "65101",
    type: "common",
  },
  {
    label: "SEMEAC",
    value: "65417",
    type: "common",
  },
  {
    label: "SARROUILLES",
    value: "65410",
    type: "common",
  },
  {
    label: "MASCARAS",
    value: "65303",
    type: "common",
  },
  {
    label: "LESPOUEY",
    value: "65270",
    type: "common",
  },
  {
    label: "LASLADES",
    value: "65265",
    type: "common",
  },
  {
    label: "LANSAC",
    value: "65259",
    type: "common",
  },
  {
    label: "CALAVANTE",
    value: "65120",
    type: "common",
  },
  {
    label: "BARBAZAN-DEBAT",
    value: "65062",
    type: "common",
  },
  {
    label: "ANGOS",
    value: "65010",
    type: "common",
  },
  {
    label: "ALLIER",
    value: "65005",
    type: "common",
  },
  {
    label: "TARBES",
    value: "65440",
    type: "common",
  },
  {
    label: "SOUES",
    value: "65433",
    type: "common",
  },
  {
    label: "SALLES-ADOUR",
    value: "65401",
    type: "common",
  },
  {
    label: "ODOS",
    value: "65331",
    type: "common",
  },
  {
    label: "LALOUBERE",
    value: "65251",
    type: "common",
  },
  {
    label: "HORGUES",
    value: "65223",
    type: "common",
  },
  {
    label: "OSSUN",
    value: "65344",
    type: "common",
  },
  {
    label: "LOUEY",
    value: "65284",
    type: "common",
  },
  {
    label: "JUILLAN",
    value: "65235",
    type: "common",
  },
  {
    label: "IBOS",
    value: "65226",
    type: "common",
  },
  {
    label: "AZEREIX",
    value: "65057",
    type: "common",
  },
  {
    label: "PONTACQ",
    value: "64453",
    type: "common",
  },
  {
    label: "BARZUN",
    value: "64097",
    type: "common",
  },
  {
    label: "BENEJACQ",
    value: "64109",
    type: "common",
  },
  {
    label: "BORDERES",
    value: "64137",
    type: "common",
  },
  {
    label: "LUCGARIER",
    value: "64358",
    type: "common",
  },
  {
    label: "LABATMALE",
    value: "64292",
    type: "common",
  },
  {
    label: "HOURS",
    value: "64266",
    type: "common",
  },
  {
    label: "BOURDETTES",
    value: "64145",
    type: "common",
  },
  {
    label: "SAINT-ABIT",
    value: "64469",
    type: "common",
  },
  {
    label: "ARROS-DE-NAY",
    value: "64054",
    type: "common",
  },
  {
    label: "LAGOS",
    value: "64302",
    type: "common",
  },
  {
    label: "BAUDREIX",
    value: "64101",
    type: "common",
  },
  {
    label: "MIREPEIX",
    value: "64386",
    type: "common",
  },
  {
    label: "BOEIL-BEZING",
    value: "64133",
    type: "common",
  },
  {
    label: "BEUSTE",
    value: "64119",
    type: "common",
  },
  {
    label: "NAY",
    value: "64417",
    type: "common",
  },
  {
    label: "PARDIES-PIETAT",
    value: "64444",
    type: "common",
  },
  {
    label: "BALIROS",
    value: "64091",
    type: "common",
  },
  {
    label: "HAUT-DE-BOSDARROS",
    value: "64257",
    type: "common",
  },
  {
    label: "BOSDARROS",
    value: "64139",
    type: "common",
  },
  {
    label: "LASSEUBETAT",
    value: "64325",
    type: "common",
  },
  {
    label: "GAN",
    value: "64230",
    type: "common",
  },
  {
    label: "LASSEUBE",
    value: "64324",
    type: "common",
  },
  {
    label: "ESCOU",
    value: "64207",
    type: "common",
  },
  {
    label: "PRECILHON",
    value: "64460",
    type: "common",
  },
  {
    label: "ESTOS",
    value: "64220",
    type: "common",
  },
  {
    label: "ESCOUT",
    value: "64209",
    type: "common",
  },
  {
    label: "BIDOS",
    value: "64126",
    type: "common",
  },
  {
    label: "OLORON-SAINTE-MARIE",
    value: "64422",
    type: "common",
  },
  {
    label: "MOUMOUR",
    value: "64409",
    type: "common",
  },
  {
    label: "GERONCE",
    value: "64241",
    type: "common",
  },
  {
    label: "ANCE FEAS",
    value: "64225",
    type: "common",
  },
  {
    label: "ESQUIULE",
    value: "64217",
    type: "common",
  },
  {
    label: "ROQUIAGUE",
    value: "64468",
    type: "common",
  },
  {
    label: "BARCUS",
    value: "64093",
    type: "common",
  },
  {
    label: "SAUGUIS-SAINT-ETIENNE",
    value: "64509",
    type: "common",
  },
  {
    label: "ORDIARP",
    value: "64424",
    type: "common",
  },
  {
    label: "MENDITTE",
    value: "64378",
    type: "common",
  },
  {
    label: "IDAUX-MENDY",
    value: "64268",
    type: "common",
  },
  {
    label: "GOTEIN-LIBARRENX",
    value: "64247",
    type: "common",
  },
  {
    label: "MUSCULDY",
    value: "64411",
    type: "common",
  },
  {
    label: "SAINT-JUST-IBARRE",
    value: "64487",
    type: "common",
  },
  {
    label: "IBARROLLE",
    value: "64267",
    type: "common",
  },
  {
    label: "LACARRE",
    value: "64297",
    type: "common",
  },
  {
    label: "GAMARTHE",
    value: "64229",
    type: "common",
  },
  {
    label: "BUSSUNARITS-SARRASQUETTE",
    value: "64154",
    type: "common",
  },
  {
    label: "SAINT-JEAN-PIED-DE-PORT",
    value: "64485",
    type: "common",
  },
  {
    label: "SAINT-JEAN-LE-VIEUX",
    value: "64484",
    type: "common",
  },
  {
    label: "ISPOURE",
    value: "64275",
    type: "common",
  },
  {
    label: "CARO",
    value: "64166",
    type: "common",
  },
  {
    label: "BUSTINCE-IRIBERRY",
    value: "64155",
    type: "common",
  },
  {
    label: "ASCARAT",
    value: "64066",
    type: "common",
  },
  {
    label: "IROULEGUY",
    value: "64274",
    type: "common",
  },
  {
    label: "ANHAUX",
    value: "64026",
    type: "common",
  },
  {
    label: "SAINT-ETIENNE-DE-BAIGORRY",
    value: "64477",
    type: "common",
  },
  {
    label: "TOMINO",
    value: "2B327",
    type: "common",
  },
  {
    label: "MERIA",
    value: "2B159",
    type: "common",
  },
  {
    label: "MORSIGLIA",
    value: "2B170",
    type: "common",
  },
  {
    label: "CENTURI",
    value: "2B086",
    type: "common",
  },
  {
    label: "HYERES",
    value: "83069",
    type: "common",
  },
  {
    label: "CARQUEIRANNE",
    value: "83034",
    type: "common",
  },
  {
    label: "LE PRADET",
    value: "83098",
    type: "common",
  },
  {
    label: "LA GARDE",
    value: "83062",
    type: "common",
  },
  {
    label: "TOULON",
    value: "83137",
    type: "common",
  },
  {
    label: "SIX-FOURS-LES-PLAGES",
    value: "83129",
    type: "common",
  },
  {
    label: "LA SEYNE-SUR-MER",
    value: "83126",
    type: "common",
  },
  {
    label: "OLLIOULES",
    value: "83090",
    type: "common",
  },
  {
    label: "SANARY-SUR-MER",
    value: "83123",
    type: "common",
  },
  {
    label: "ARMISSAN",
    value: "11014",
    type: "common",
  },
  {
    label: "NARBONNE",
    value: "11262",
    type: "common",
  },
  {
    label: "MONTREDON-DES-CORBIERES",
    value: "11255",
    type: "common",
  },
  {
    label: "BIZANET",
    value: "11040",
    type: "common",
  },
  {
    label: "ORNAISONS",
    value: "11267",
    type: "common",
  },
  {
    label: "LUC-SUR-ORBIEU",
    value: "11210",
    type: "common",
  },
  {
    label: "BOUTENAC",
    value: "11048",
    type: "common",
  },
  {
    label: "FONTCOUVERTE",
    value: "11148",
    type: "common",
  },
  {
    label: "FERRALS-LES-CORBIERES",
    value: "11140",
    type: "common",
  },
  {
    label: "CONILHAC-CORBIERES",
    value: "11098",
    type: "common",
  },
  {
    label: "MOUX",
    value: "11261",
    type: "common",
  },
  {
    label: "DOUZENS",
    value: "11122",
    type: "common",
  },
  {
    label: "COMIGNE",
    value: "11095",
    type: "common",
  },
  {
    label: "CAPENDU",
    value: "11068",
    type: "common",
  },
  {
    label: "MONZE",
    value: "11257",
    type: "common",
  },
  {
    label: "MONTIRAT",
    value: "11248",
    type: "common",
  },
  {
    label: "FONTIES-D'AUDE",
    value: "11151",
    type: "common",
  },
  {
    label: "FLOURE",
    value: "11146",
    type: "common",
  },
  {
    label: "BARBAIRA",
    value: "11027",
    type: "common",
  },
  {
    label: "PALAJA",
    value: "11272",
    type: "common",
  },
  {
    label: "CAZILHAC",
    value: "11088",
    type: "common",
  },
  {
    label: "PREIXAN",
    value: "11299",
    type: "common",
  },
  {
    label: "COUFFOULENS",
    value: "11102",
    type: "common",
  },
  {
    label: "CAVANAC",
    value: "11085",
    type: "common",
  },
  {
    label: "VILLARZEL-DU-RAZES",
    value: "11417",
    type: "common",
  },
  {
    label: "ROULLENS",
    value: "11327",
    type: "common",
  },
  {
    label: "LAVALETTE",
    value: "11199",
    type: "common",
  },
  {
    label: "ARZENS",
    value: "11018",
    type: "common",
  },
  {
    label: "ALAIRAC",
    value: "11005",
    type: "common",
  },
  {
    label: "MONTREAL",
    value: "11254",
    type: "common",
  },
  {
    label: "CAILHAVEL",
    value: "11059",
    type: "common",
  },
  {
    label: "CAILHAU",
    value: "11058",
    type: "common",
  },
  {
    label: "VILLENEUVE-LES-MONTREAL",
    value: "11432",
    type: "common",
  },
  {
    label: "LASSERRE-DE-PROUILLE",
    value: "11193",
    type: "common",
  },
  {
    label: "LA FORCE",
    value: "11153",
    type: "common",
  },
  {
    label: "FERRAN",
    value: "11141",
    type: "common",
  },
  {
    label: "BREZILHAC",
    value: "11051",
    type: "common",
  },
  {
    label: "ORSANS",
    value: "11268",
    type: "common",
  },
  {
    label: "FENOUILLET-DU-RAZES",
    value: "11139",
    type: "common",
  },
  {
    label: "FANJEAUX",
    value: "11136",
    type: "common",
  },
  {
    label: "LA CASSAIGNE",
    value: "11072",
    type: "common",
  },
  {
    label: "SAINT-JULIEN-DE-BRIOLA",
    value: "11348",
    type: "common",
  },
  {
    label: "RIBOUISSE",
    value: "11312",
    type: "common",
  },
  {
    label: "GAJA-LA-SELVE",
    value: "11159",
    type: "common",
  },
  {
    label: "CAZALRENOUX",
    value: "11087",
    type: "common",
  },
  {
    label: "VILLAUTOU",
    value: "11419",
    type: "common",
  },
  {
    label: "PLAIGNE",
    value: "11290",
    type: "common",
  },
  {
    label: "PECHARIC-ET-LE-PY",
    value: "11277",
    type: "common",
  },
  {
    label: "LAFAGE",
    value: "11184",
    type: "common",
  },
  {
    label: "CAHUZAC",
    value: "11057",
    type: "common",
  },
  {
    label: "TREMOULET",
    value: "09315",
    type: "common",
  },
  {
    label: "LAPENNE",
    value: "09153",
    type: "common",
  },
  {
    label: "GAUDIES",
    value: "09132",
    type: "common",
  },
  {
    label: "VILLENEUVE-DU-PAREAGE",
    value: "09339",
    type: "common",
  },
  {
    label: "MONTAUT",
    value: "09199",
    type: "common",
  },
  {
    label: "LE VERNET",
    value: "09331",
    type: "common",
  },
  {
    label: "BONNAC",
    value: "09060",
    type: "common",
  },
  {
    label: "BEZAC",
    value: "09056",
    type: "common",
  },
  {
    label: "UNZENT",
    value: "09319",
    type: "common",
  },
  {
    label: "SAINT-MARTIN-D'OYDES",
    value: "09270",
    type: "common",
  },
  {
    label: "SAINT-AMANS",
    value: "09255",
    type: "common",
  },
  {
    label: "LESCOUSSE",
    value: "09163",
    type: "common",
  },
  {
    label: "ESPLAS",
    value: "09117",
    type: "common",
  },
  {
    label: "LE FOSSAT",
    value: "09124",
    type: "common",
  },
  {
    label: "DURFORT",
    value: "09109",
    type: "common",
  },
  {
    label: "ARTIGAT",
    value: "09019",
    type: "common",
  },
  {
    label: "SIEURAS",
    value: "09294",
    type: "common",
  },
  {
    label: "CASTEX",
    value: "09084",
    type: "common",
  },
  {
    label: "CARLA-BAYLE",
    value: "09079",
    type: "common",
  },
  {
    label: "THOUARS-SUR-ARIZE",
    value: "09310",
    type: "common",
  },
  {
    label: "MERAS",
    value: "09186",
    type: "common",
  },
  {
    label: "LOUBAUT",
    value: "09172",
    type: "common",
  },
  {
    label: "FORNEX",
    value: "09123",
    type: "common",
  },
  {
    label: "DAUMAZAN-SUR-ARIZE",
    value: "09105",
    type: "common",
  },
  {
    label: "LA BASTIDE-DE-BESPLAS",
    value: "09038",
    type: "common",
  },
  {
    label: "MONTESQUIEU-VOLVESTRE",
    value: "31375",
    type: "common",
  },
  {
    label: "MONTBERAUD",
    value: "31362",
    type: "common",
  },
  {
    label: "LAHITERE",
    value: "31267",
    type: "common",
  },
  {
    label: "GOUZENS",
    value: "31226",
    type: "common",
  },
  {
    label: "SAINT-MICHEL",
    value: "31505",
    type: "common",
  },
  {
    label: "SAINT-CHRISTAUD",
    value: "31474",
    type: "common",
  },
  {
    label: "LE PLAN",
    value: "31425",
    type: "common",
  },
  {
    label: "PLAGNE",
    value: "31422",
    type: "common",
  },
  {
    label: "MONTCLAR-DE-COMMINGES",
    value: "31367",
    type: "common",
  },
  {
    label: "MAURAN",
    value: "31327",
    type: "common",
  },
  {
    label: "AUSSEING",
    value: "31030",
    type: "common",
  },
  {
    label: "SAINT-MARTORY",
    value: "31503",
    type: "common",
  },
  {
    label: "ROQUEFORT-SUR-GARONNE",
    value: "31457",
    type: "common",
  },
  {
    label: "MAZERES-SUR-SALAT",
    value: "31336",
    type: "common",
  },
  {
    label: "MANCIOUX",
    value: "31314",
    type: "common",
  },
  {
    label: "LE FRECHET",
    value: "31198",
    type: "common",
  },
  {
    label: "BOUSSENS",
    value: "31084",
    type: "common",
  },
  {
    label: "SEPX",
    value: "31545",
    type: "common",
  },
  {
    label: "PROUPIARY",
    value: "31440",
    type: "common",
  },
  {
    label: "LAFFITE-TOUPIERE",
    value: "31260",
    type: "common",
  },
  {
    label: "CAZENEUVE-MONTAUT",
    value: "31134",
    type: "common",
  },
  {
    label: "BOUZIN",
    value: "31086",
    type: "common",
  },
  {
    label: "AUZAS",
    value: "31034",
    type: "common",
  },
  {
    label: "ARNAUD-GUILHEM",
    value: "31018",
    type: "common",
  },
  {
    label: "LATOUE",
    value: "31278",
    type: "common",
  },
  {
    label: "AULON",
    value: "31023",
    type: "common",
  },
  {
    label: "SAUX-ET-POMAREDE",
    value: "31536",
    type: "common",
  },
  {
    label: "SAINT-IGNAN",
    value: "31487",
    type: "common",
  },
  {
    label: "LARCAN",
    value: "31274",
    type: "common",
  },
  {
    label: "LALOURET-LAFFITEAU",
    value: "31268",
    type: "common",
  },
  {
    label: "LODES",
    value: "31302",
    type: "common",
  },
  {
    label: "LE CUING",
    value: "31159",
    type: "common",
  },
  {
    label: "SEDEILHAC",
    value: "31539",
    type: "common",
  },
  {
    label: "SAINT-PLANCARD",
    value: "31513",
    type: "common",
  },
  {
    label: "LOUDET",
    value: "31305",
    type: "common",
  },
  {
    label: "CAZARIL-TAMBOURES",
    value: "31130",
    type: "common",
  },
  {
    label: "BOUDRAC",
    value: "31078",
    type: "common",
  },
  {
    label: "UGLAS",
    value: "65456",
    type: "common",
  },
  {
    label: "TAJAN",
    value: "65437",
    type: "common",
  },
  {
    label: "REJAUMONT",
    value: "65377",
    type: "common",
  },
  {
    label: "ARNE",
    value: "65028",
    type: "common",
  },
  {
    label: "VILLENEUVE-LECUSSAN",
    value: "31586",
    type: "common",
  },
  {
    label: "LECUSSAN",
    value: "31289",
    type: "common",
  },
  {
    label: "HOUEYDETS",
    value: "65224",
    type: "common",
  },
  {
    label: "CLARENS",
    value: "65150",
    type: "common",
  },
  {
    label: "CAMPISTROUS",
    value: "65125",
    type: "common",
  },
  {
    label: "RICAUD",
    value: "65378",
    type: "common",
  },
  {
    label: "PERE",
    value: "65356",
    type: "common",
  },
  {
    label: "LUTILHOUS",
    value: "65294",
    type: "common",
  },
  {
    label: "LANESPEDE",
    value: "65256",
    type: "common",
  },
  {
    label: "LAGRANGE",
    value: "65245",
    type: "common",
  },
  {
    label: "GOURGUE",
    value: "65207",
    type: "common",
  },
  {
    label: "CASTERA-LANUSSE",
    value: "65132",
    type: "common",
  },
  {
    label: "CAHARET",
    value: "65118",
    type: "common",
  },
  {
    label: "BEGOLE",
    value: "65079",
    type: "common",
  },
  {
    label: "POUMAROUS",
    value: "65367",
    type: "common",
  },
  {
    label: "OZON",
    value: "65353",
    type: "common",
  },
  {
    label: "OLEAC-DESSUS",
    value: "65333",
    type: "common",
  },
  {
    label: "CHELLE-SPOU",
    value: "65143",
    type: "common",
  },
  {
    label: "ARTIGUEMY",
    value: "65037",
    type: "common",
  },
  {
    label: "VIELLE-ADOUR",
    value: "65464",
    type: "common",
  },
  {
    label: "OUEILLOUX",
    value: "65346",
    type: "common",
  },
  {
    label: "ORIGNAC",
    value: "65338",
    type: "common",
  },
  {
    label: "MONTIGNAC",
    value: "65321",
    type: "common",
  },
  {
    label: "LUC",
    value: "65290",
    type: "common",
  },
  {
    label: "HITTE",
    value: "65222",
    type: "common",
  },
  {
    label: "FRECHOU-FRECHET",
    value: "65181",
    type: "common",
  },
  {
    label: "BERNAC-DESSUS",
    value: "65084",
    type: "common",
  },
  {
    label: "BERNAC-DEBAT",
    value: "65083",
    type: "common",
  },
  {
    label: "BARBAZAN-DESSUS",
    value: "65063",
    type: "common",
  },
  {
    label: "VISKER",
    value: "65479",
    type: "common",
  },
  {
    label: "SAINT-MARTIN",
    value: "65392",
    type: "common",
  },
  {
    label: "ORINCLES",
    value: "65339",
    type: "common",
  },
  {
    label: "MONTGAILLARD",
    value: "65320",
    type: "common",
  },
  {
    label: "MOMERES",
    value: "65313",
    type: "common",
  },
  {
    label: "LAYRISSE",
    value: "65268",
    type: "common",
  },
  {
    label: "HIIS",
    value: "65221",
    type: "common",
  },
  {
    label: "HIBARETTE",
    value: "65220",
    type: "common",
  },
  {
    label: "BENAC",
    value: "65080",
    type: "common",
  },
  {
    label: "ARCIZAC-ADOUR",
    value: "65019",
    type: "common",
  },
  {
    label: "LANNE",
    value: "65257",
    type: "common",
  },
  {
    label: "JULOS",
    value: "65236",
    type: "common",
  },
  {
    label: "BARTRES",
    value: "65070",
    type: "common",
  },
  {
    label: "BARRY",
    value: "65067",
    type: "common",
  },
  {
    label: "AVERAN",
    value: "65052",
    type: "common",
  },
  {
    label: "ADE",
    value: "65002",
    type: "common",
  },
  {
    label: "POUEYFERRE",
    value: "65366",
    type: "common",
  },
  {
    label: "LOUBAJAC",
    value: "65280",
    type: "common",
  },
  {
    label: "LAMARQUE-PONTACQ",
    value: "65252",
    type: "common",
  },
  {
    label: "BARLEST",
    value: "65065",
    type: "common",
  },
  {
    label: "LESTELLE-BETHARRAM",
    value: "64339",
    type: "common",
  },
  {
    label: "SAINT-VINCENT",
    value: "64498",
    type: "common",
  },
  {
    label: "MONTAUT",
    value: "64400",
    type: "common",
  },
  {
    label: "COARRAZE",
    value: "64191",
    type: "common",
  },
  {
    label: "IGON",
    value: "64270",
    type: "common",
  },
  {
    label: "BRUGES-CAPBIS-MIFAGET",
    value: "64148",
    type: "common",
  },
  {
    label: "SEVIGNACQ-MEYRACQ",
    value: "64522",
    type: "common",
  },
  {
    label: "LYS",
    value: "64363",
    type: "common",
  },
  {
    label: "SAINTE-COLOME",
    value: "64473",
    type: "common",
  },
  {
    label: "REBENACQ",
    value: "64463",
    type: "common",
  },
  {
    label: "BUZY",
    value: "64157",
    type: "common",
  },
  {
    label: "BESCAT",
    value: "64116",
    type: "common",
  },
  {
    label: "OGEU-LES-BAINS",
    value: "64421",
    type: "common",
  },
  {
    label: "BUZIET",
    value: "64156",
    type: "common",
  },
  {
    label: "HERRERE",
    value: "64261",
    type: "common",
  },
  {
    label: "GURMENCON",
    value: "64252",
    type: "common",
  },
  {
    label: "EYSUS",
    value: "64224",
    type: "common",
  },
  {
    label: "ASASP-ARROS",
    value: "64064",
    type: "common",
  },
  {
    label: "AGNOS",
    value: "64007",
    type: "common",
  },
  {
    label: "ARAMITS",
    value: "64029",
    type: "common",
  },
  {
    label: "TARDETS-SORHOLUS",
    value: "64533",
    type: "common",
  },
  {
    label: "MONTORY",
    value: "64404",
    type: "common",
  },
  {
    label: "LAGUINGE-RESTOUE",
    value: "64303",
    type: "common",
  },
  {
    label: "TROIS-VILLES",
    value: "64537",
    type: "common",
  },
  {
    label: "OSSAS-SUHARE",
    value: "64432",
    type: "common",
  },
  {
    label: "CAMOU-CIHIGUE",
    value: "64162",
    type: "common",
  },
  {
    label: "ALOS-SIBAS-ABENSE",
    value: "64017",
    type: "common",
  },
  {
    label: "AUSSURUCQ",
    value: "64081",
    type: "common",
  },
  {
    label: "MENDIVE",
    value: "64379",
    type: "common",
  },
  {
    label: "HOSTA",
    value: "64265",
    type: "common",
  },
  {
    label: "BEHORLEGUY",
    value: "64107",
    type: "common",
  },
  {
    label: "AHAXE-ALCIETTE-BASCASSAN",
    value: "64008",
    type: "common",
  },
  {
    label: "UHART-CIZE",
    value: "64538",
    type: "common",
  },
  {
    label: "AINCILLE",
    value: "64011",
    type: "common",
  },
  {
    label: "LASSE",
    value: "64322",
    type: "common",
  },
  {
    label: "ARNEGUY",
    value: "64047",
    type: "common",
  },
  {
    label: "BANCA",
    value: "64092",
    type: "common",
  },
  {
    label: "ALDUDES",
    value: "64016",
    type: "common",
  },
  {
    label: "LURI",
    value: "2B152",
    type: "common",
  },
  {
    label: "CAGNANO",
    value: "2B046",
    type: "common",
  },
  {
    label: "PINO",
    value: "2B233",
    type: "common",
  },
  {
    label: "BARRETTALI",
    value: "2B030",
    type: "common",
  },
  {
    label: "SAINT-MANDRIER-SUR-MER",
    value: "83153",
    type: "common",
  },
  {
    label: "GRUISSAN",
    value: "11170",
    type: "common",
  },
  {
    label: "PEYRIAC-DE-MER",
    value: "11285",
    type: "common",
  },
  {
    label: "BAGES",
    value: "11024",
    type: "common",
  },
  {
    label: "SAINT-ANDRE-DE-ROQUELONGUE",
    value: "11332",
    type: "common",
  },
  {
    label: "MONTSERET",
    value: "11256",
    type: "common",
  },
  {
    label: "SAINT-LAURENT-DE-LA-CABRERISSE",
    value: "11351",
    type: "common",
  },
  {
    label: "FABREZAN",
    value: "11132",
    type: "common",
  },
  {
    label: "TOURNISSAN",
    value: "11392",
    type: "common",
  },
  {
    label: "RIBAUTE",
    value: "11311",
    type: "common",
  },
  {
    label: "CAMPLONG-D'AUDE",
    value: "11064",
    type: "common",
  },
  {
    label: "SERVIES-EN-VAL",
    value: "11378",
    type: "common",
  },
  {
    label: "LAGRASSE",
    value: "11185",
    type: "common",
  },
  {
    label: "FAJAC-EN-VAL",
    value: "11133",
    type: "common",
  },
  {
    label: "ARQUETTES-EN-VAL",
    value: "11016",
    type: "common",
  },
  {
    label: "VILLEFLOURE",
    value: "11423",
    type: "common",
  },
  {
    label: "MAS-DES-COURS",
    value: "11223",
    type: "common",
  },
  {
    label: "LADERN-SUR-LAUQUET",
    value: "11183",
    type: "common",
  },
  {
    label: "VERZEILLE",
    value: "11408",
    type: "common",
  },
  {
    label: "ROUFFIAC-D'AUDE",
    value: "11325",
    type: "common",
  },
  {
    label: "POMAS",
    value: "11293",
    type: "common",
  },
  {
    label: "LEUC",
    value: "11201",
    type: "common",
  },
  {
    label: "SAINT-MARTIN-DE-VILLEREGLAN",
    value: "11355",
    type: "common",
  },
  {
    label: "MONTCLAR",
    value: "11242",
    type: "common",
  },
  {
    label: "CEPIE",
    value: "11090",
    type: "common",
  },
  {
    label: "ROUTIER",
    value: "11328",
    type: "common",
  },
  {
    label: "MALVIES",
    value: "11216",
    type: "common",
  },
  {
    label: "LAURAGUEL",
    value: "11197",
    type: "common",
  },
  {
    label: "CAMBIEURE",
    value: "11061",
    type: "common",
  },
  {
    label: "BRUGAIROLLES",
    value: "11053",
    type: "common",
  },
  {
    label: "MAZEROLLES-DU-RAZES",
    value: "11228",
    type: "common",
  },
  {
    label: "GRAMAZIE",
    value: "11167",
    type: "common",
  },
  {
    label: "BELVEZE-DU-RAZES",
    value: "11034",
    type: "common",
  },
  {
    label: "BELLEGARDE-DU-RAZES",
    value: "11032",
    type: "common",
  },
  {
    label: "ALAIGNE",
    value: "11004",
    type: "common",
  },
  {
    label: "SEIGNALENS",
    value: "11375",
    type: "common",
  },
  {
    label: "MONTGRADAIL",
    value: "11246",
    type: "common",
  },
  {
    label: "LIGNAIROLLES",
    value: "11204",
    type: "common",
  },
  {
    label: "HOUNOUX",
    value: "11173",
    type: "common",
  },
  {
    label: "ESCUEILLENS-ET-SAINT-JUST-DE-BELENGARD",
    value: "11128",
    type: "common",
  },
  {
    label: "LA COURTETE",
    value: "11108",
    type: "common",
  },
  {
    label: "SAINT-GAUDERIC",
    value: "11343",
    type: "common",
  },
  {
    label: "PLAVILLA",
    value: "11291",
    type: "common",
  },
  {
    label: "SAINTE-FOI",
    value: "09260",
    type: "common",
  },
  {
    label: "MIREPOIX",
    value: "09194",
    type: "common",
  },
  {
    label: "MALEGOUDE",
    value: "09178",
    type: "common",
  },
  {
    label: "CAZALS-DES-BAYLES",
    value: "09089",
    type: "common",
  },
  {
    label: "MANSES",
    value: "09180",
    type: "common",
  },
  {
    label: "VALS",
    value: "09323",
    type: "common",
  },
  {
    label: "TEILHET",
    value: "09309",
    type: "common",
  },
  {
    label: "SAINT-FELIX-DE-TOURNEGAT",
    value: "09259",
    type: "common",
  },
  {
    label: "SAINT-AMADOU",
    value: "09254",
    type: "common",
  },
  {
    label: "LES PUJOLS",
    value: "09238",
    type: "common",
  },
  {
    label: "LA BASTIDE-DE-LORDAT",
    value: "09040",
    type: "common",
  },
  {
    label: "LA TOUR-DU-CRIEU",
    value: "09312",
    type: "common",
  },
  {
    label: "LUDIES",
    value: "09175",
    type: "common",
  },
  {
    label: "LE CARLARET",
    value: "09081",
    type: "common",
  },
  {
    label: "SAINT-JEAN-DU-FALGA",
    value: "09265",
    type: "common",
  },
  {
    label: "SAINT-BAUZEIL",
    value: "09256",
    type: "common",
  },
  {
    label: "PAMIERS",
    value: "09225",
    type: "common",
  },
  {
    label: "SAINT-VICTOR-ROUZAUD",
    value: "09276",
    type: "common",
  },
  {
    label: "SAINT-MICHEL",
    value: "09271",
    type: "common",
  },
  {
    label: "MONESPLE",
    value: "09195",
    type: "common",
  },
  {
    label: "MADIERE",
    value: "09177",
    type: "common",
  },
  {
    label: "ESCOSSE",
    value: "09116",
    type: "common",
  },
  {
    label: "SABARAT",
    value: "09253",
    type: "common",
  },
  {
    label: "PAILHES",
    value: "09224",
    type: "common",
  },
  {
    label: "LANOUX",
    value: "09151",
    type: "common",
  },
  {
    label: "CASTERAS",
    value: "09083",
    type: "common",
  },
  {
    label: "CAMPAGNE-SUR-ARIZE",
    value: "09075",
    type: "common",
  },
  {
    label: "LES BORDES-SUR-ARIZE",
    value: "09061",
    type: "common",
  },
  {
    label: "MONTBRUN-BOCAGE",
    value: "31365",
    type: "common",
  },
  {
    label: "MONTFA",
    value: "09205",
    type: "common",
  },
  {
    label: "SAINTE-CROIX-VOLVESTRE",
    value: "09257",
    type: "common",
  },
  {
    label: "MERIGON",
    value: "09190",
    type: "common",
  },
  {
    label: "LASSERRE",
    value: "09158",
    type: "common",
  },
  {
    label: "TOURTOUSE",
    value: "09313",
    type: "common",
  },
  {
    label: "FABAS",
    value: "09120",
    type: "common",
  },
  {
    label: "CERIZOLS",
    value: "09094",
    type: "common",
  },
  {
    label: "BEDEILLE",
    value: "09046",
    type: "common",
  },
  {
    label: "ESCOULIS",
    value: "31591",
    type: "common",
  },
  {
    label: "MARSOULAS",
    value: "31321",
    type: "common",
  },
  {
    label: "CASSAGNE",
    value: "31110",
    type: "common",
  },
  {
    label: "BELBEZE-EN-COMMINGES",
    value: "31059",
    type: "common",
  },
  {
    label: "BETCHAT",
    value: "09054",
    type: "common",
  },
  {
    label: "TOUILLE",
    value: "31554",
    type: "common",
  },
  {
    label: "SALIES-DU-SALAT",
    value: "31523",
    type: "common",
  },
  {
    label: "MONTSAUNES",
    value: "31391",
    type: "common",
  },
  {
    label: "MANE",
    value: "31315",
    type: "common",
  },
  {
    label: "MONTESPAN",
    value: "31372",
    type: "common",
  },
  {
    label: "LESTELLE-DE-SAINT-MARTORY",
    value: "31296",
    type: "common",
  },
  {
    label: "LABARTHE-INARD",
    value: "31246",
    type: "common",
  },
  {
    label: "FIGAROL",
    value: "31183",
    type: "common",
  },
  {
    label: "CASTILLON-DE-SAINT-MARTORY",
    value: "31124",
    type: "common",
  },
  {
    label: "BEAUCHALOT",
    value: "31050",
    type: "common",
  },
  {
    label: "SAVARTHES",
    value: "31537",
    type: "common",
  },
  {
    label: "SAINT-MEDARD",
    value: "31504",
    type: "common",
  },
  {
    label: "RIEUCAZE",
    value: "31452",
    type: "common",
  },
  {
    label: "POINTIS-INARD",
    value: "31427",
    type: "common",
  },
  {
    label: "MIRAMONT-DE-COMMINGES",
    value: "31344",
    type: "common",
  },
  {
    label: "LANDORTHE",
    value: "31270",
    type: "common",
  },
  {
    label: "ESTANCARBON",
    value: "31175",
    type: "common",
  },
  {
    label: "VALENTINE",
    value: "31565",
    type: "common",
  },
  {
    label: "SAINT-GAUDENS",
    value: "31483",
    type: "common",
  },
  {
    label: "VILLENEUVE-DE-RIVIERE",
    value: "31585",
    type: "common",
  },
  {
    label: "PONLAT-TAILLEBOURG",
    value: "31430",
    type: "common",
  },
  {
    label: "POINTIS-DE-RIVIERE",
    value: "31426",
    type: "common",
  },
  {
    label: "MARTRES-DE-RIVIERE",
    value: "31323",
    type: "common",
  },
  {
    label: "LABARTHE-RIVIERE",
    value: "31247",
    type: "common",
  },
  {
    label: "CLARAC",
    value: "31147",
    type: "common",
  },
  {
    label: "BORDES-DE-RIVIERE",
    value: "31076",
    type: "common",
  },
  {
    label: "SAINT-PAUL",
    value: "65394",
    type: "common",
  },
  {
    label: "MAZERES-DE-NESTE",
    value: "65307",
    type: "common",
  },
  {
    label: "LES TOURREILLES",
    value: "31556",
    type: "common",
  },
  {
    label: "MONTREJEAU",
    value: "31390",
    type: "common",
  },
  {
    label: "GOURDAN-POLIGNAN",
    value: "31224",
    type: "common",
  },
  {
    label: "FRANQUEVIELLE",
    value: "31197",
    type: "common",
  },
  {
    label: "CUGURON",
    value: "31158",
    type: "common",
  },
  {
    label: "AUSSON",
    value: "31031",
    type: "common",
  },
  {
    label: "CANTAOUS",
    value: "65482",
    type: "common",
  },
  {
    label: "TUZAGUET",
    value: "65455",
    type: "common",
  },
  {
    label: "SAINT-LAURENT-DE-NESTE",
    value: "65389",
    type: "common",
  },
  {
    label: "PINAS",
    value: "65363",
    type: "common",
  },
  {
    label: "ANERES",
    value: "65009",
    type: "common",
  },
  {
    label: "LANNEMEZAN",
    value: "65258",
    type: "common",
  },
  {
    label: "ESCALA",
    value: "65159",
    type: "common",
  },
  {
    label: "LA BARTHE-DE-NESTE",
    value: "65069",
    type: "common",
  },
  {
    label: "TILHOUSE",
    value: "65445",
    type: "common",
  },
  {
    label: "SARLABOUS",
    value: "65405",
    type: "common",
  },
  {
    label: "MAUVEZIN",
    value: "65306",
    type: "common",
  },
  {
    label: "CAPVERN",
    value: "65127",
    type: "common",
  },
  {
    label: "BENQUE-MOLERE",
    value: "65081",
    type: "common",
  },
  {
    label: "ARGELES-BAGNERES",
    value: "65024",
    type: "common",
  },
  {
    label: "UZER",
    value: "65459",
    type: "common",
  },
  {
    label: "ESPIEILH",
    value: "65167",
    type: "common",
  },
  {
    label: "ESCONNETS",
    value: "65162",
    type: "common",
  },
  {
    label: "CIEUTAT",
    value: "65147",
    type: "common",
  },
  {
    label: "CASTILLON",
    value: "65135",
    type: "common",
  },
  {
    label: "BOURG-DE-BIGORRE",
    value: "65105",
    type: "common",
  },
  {
    label: "BONNEMAZON",
    value: "65096",
    type: "common",
  },
  {
    label: "BETTES",
    value: "65091",
    type: "common",
  },
  {
    label: "TREBONS",
    value: "65451",
    type: "common",
  },
  {
    label: "POUZAC",
    value: "65370",
    type: "common",
  },
  {
    label: "ORDIZAN",
    value: "65335",
    type: "common",
  },
  {
    label: "MERILHEU",
    value: "65310",
    type: "common",
  },
  {
    label: "HAUBAN",
    value: "65216",
    type: "common",
  },
  {
    label: "ANTIST",
    value: "65016",
    type: "common",
  },
  {
    label: "OSSUN-EZ-ANGLES",
    value: "65345",
    type: "common",
  },
  {
    label: "NEUILH",
    value: "65328",
    type: "common",
  },
  {
    label: "LOUCRUP",
    value: "65281",
    type: "common",
  },
  {
    label: "ARRAYOU-LAHITTE",
    value: "65247",
    type: "common",
  },
  {
    label: "LABASSERE",
    value: "65238",
    type: "common",
  },
  {
    label: "ASTUGUE",
    value: "65043",
    type: "common",
  },
  {
    label: "ARRODETS-EZ-ANGLES",
    value: "65033",
    type: "common",
  },
  {
    label: "SERE-LANSO",
    value: "65421",
    type: "common",
  },
  {
    label: "SAINT-CREAC",
    value: "65386",
    type: "common",
  },
  {
    label: "PAREAC",
    value: "65355",
    type: "common",
  },
  {
    label: "LUGAGNAN",
    value: "65291",
    type: "common",
  },
  {
    label: "LOURDES",
    value: "65286",
    type: "common",
  },
  {
    label: "LEZIGNAN",
    value: "65271",
    type: "common",
  },
  {
    label: "JARRET",
    value: "65233",
    type: "common",
  },
  {
    label: "GEZ-EZ-ANGLES",
    value: "65203",
    type: "common",
  },
  {
    label: "ESCOUBES-POUTS",
    value: "65164",
    type: "common",
  },
  {
    label: "BOURREAC",
    value: "65107",
    type: "common",
  },
  {
    label: "ASPIN-EN-LAVEDAN",
    value: "65040",
    type: "common",
  },
  {
    label: "ARTIGUES",
    value: "65038",
    type: "common",
  },
  {
    label: "ARCIZAC-EZ-ANGLES",
    value: "65020",
    type: "common",
  },
  {
    label: "LES ANGLES",
    value: "65011",
    type: "common",
  },
  {
    label: "PEYROUSE",
    value: "65360",
    type: "common",
  },
  {
    label: "OSSEN",
    value: "65343",
    type: "common",
  },
  {
    label: "OMEX",
    value: "65334",
    type: "common",
  },
  {
    label: "SAINT-PE-DE-BIGORRE",
    value: "65395",
    type: "common",
  },
  {
    label: "ARTHEZ-D'ASSON",
    value: "64058",
    type: "common",
  },
  {
    label: "ASSON",
    value: "64068",
    type: "common",
  },
  {
    label: "LOUVIE-JUZON",
    value: "64353",
    type: "common",
  },
  {
    label: "CASTET",
    value: "64175",
    type: "common",
  },
  {
    label: "IZESTE",
    value: "64280",
    type: "common",
  },
  {
    label: "BILHERES",
    value: "64128",
    type: "common",
  },
  {
    label: "ARUDY",
    value: "64062",
    type: "common",
  },
  {
    label: "SARRANCE",
    value: "64506",
    type: "common",
  },
  {
    label: "LURBE-SAINT-CHRISTAU",
    value: "64360",
    type: "common",
  },
  {
    label: "ESCOT",
    value: "64206",
    type: "common",
  },
  {
    label: "ISSOR",
    value: "64276",
    type: "common",
  },
  {
    label: "LANNE-EN-BARETOUS",
    value: "64310",
    type: "common",
  },
  {
    label: "HAUX",
    value: "64258",
    type: "common",
  },
  {
    label: "LICQ-ATHEREY",
    value: "64342",
    type: "common",
  },
  {
    label: "LICHANS-SUNHAR",
    value: "64340",
    type: "common",
  },
  {
    label: "ETCHEBAR",
    value: "64222",
    type: "common",
  },
  {
    label: "LACARRY-ARHAN-CHARRITTE-DE-HAUT",
    value: "64298",
    type: "common",
  },
  {
    label: "ALCAY-ALCABEHETY-SUNHARETTE",
    value: "64015",
    type: "common",
  },
  {
    label: "LECUMBERRY",
    value: "64327",
    type: "common",
  },
  {
    label: "ESTERENCUBY",
    value: "64218",
    type: "common",
  },
  {
    label: "SAINT-MICHEL",
    value: "64492",
    type: "common",
  },
  {
    label: "UREPEL",
    value: "64543",
    type: "common",
  },
  {
    label: "SISCO",
    value: "2B281",
    type: "common",
  },
  {
    label: "PIETRACORBARA",
    value: "2B224",
    type: "common",
  },
  {
    label: "OLCANI",
    value: "2B184",
    type: "common",
  },
  {
    label: "OGLIASTRO",
    value: "2B183",
    type: "common",
  },
  {
    label: "CANARI",
    value: "2B058",
    type: "common",
  },
  {
    label: "SIGEAN",
    value: "11379",
    type: "common",
  },
  {
    label: "PORTEL-DES-CORBIERES",
    value: "11295",
    type: "common",
  },
  {
    label: "THEZAN-DES-CORBIERES",
    value: "11390",
    type: "common",
  },
  {
    label: "FONTJONCOUSE",
    value: "11152",
    type: "common",
  },
  {
    label: "JONQUIERES",
    value: "11176",
    type: "common",
  },
  {
    label: "COUSTOUGE",
    value: "11110",
    type: "common",
  },
  {
    label: "TALAIRAN",
    value: "11386",
    type: "common",
  },
  {
    label: "SAINT-PIERRE-DES-CHAMPS",
    value: "11363",
    type: "common",
  },
  {
    label: "SAINT-MARTIN-DES-PUITS",
    value: "11354",
    type: "common",
  },
  {
    label: "RIEUX-EN-VAL",
    value: "11314",
    type: "common",
  },
  {
    label: "MAYRONNES",
    value: "11227",
    type: "common",
  },
  {
    label: "CAUNETTES-EN-VAL",
    value: "11083",
    type: "common",
  },
  {
    label: "VILLETRITOULS",
    value: "11440",
    type: "common",
  },
  {
    label: "VILLAR-EN-VAL",
    value: "11414",
    type: "common",
  },
  {
    label: "TAURIZE",
    value: "11387",
    type: "common",
  },
  {
    label: "LABASTIDE-EN-VAL",
    value: "11179",
    type: "common",
  },
  {
    label: "GREFFEIL",
    value: "11169",
    type: "common",
  },
  {
    label: "CLERMONT-SUR-LAUQUET",
    value: "11094",
    type: "common",
  },
  {
    label: "CAUNETTE-SUR-LAUQUET",
    value: "11082",
    type: "common",
  },
  {
    label: "VILLEBAZY",
    value: "11420",
    type: "common",
  },
  {
    label: "VILLAR-SAINT-ANSELME",
    value: "11415",
    type: "common",
  },
  {
    label: "SAINT-POLYCARPE",
    value: "11364",
    type: "common",
  },
  {
    label: "SAINT-HILAIRE",
    value: "11344",
    type: "common",
  },
  {
    label: "GARDIE",
    value: "11161",
    type: "common",
  },
  {
    label: "BELCASTEL-ET-BUC",
    value: "11029",
    type: "common",
  },
  {
    label: "PIEUSSE",
    value: "11289",
    type: "common",
  },
  {
    label: "LIMOUX",
    value: "11206",
    type: "common",
  },
  {
    label: "COURNANEL",
    value: "11105",
    type: "common",
  },
  {
    label: "PAULIGNE",
    value: "11274",
    type: "common",
  },
  {
    label: "MALRAS",
    value: "11214",
    type: "common",
  },
  {
    label: "GAJA-ET-VILLEDIEU",
    value: "11158",
    type: "common",
  },
  {
    label: "DONAZAC",
    value: "11121",
    type: "common",
  },
  {
    label: "LA DIGNE-D'AVAL",
    value: "11120",
    type: "common",
  },
  {
    label: "LA DIGNE-D'AMONT",
    value: "11119",
    type: "common",
  },
  {
    label: "AJAC",
    value: "11003",
    type: "common",
  },
  {
    label: "VILLELONGUE-D'AUDE",
    value: "11427",
    type: "common",
  },
  {
    label: "POMY",
    value: "11294",
    type: "common",
  },
  {
    label: "MONTHAUT",
    value: "11247",
    type: "common",
  },
  {
    label: "LOUPIA",
    value: "11207",
    type: "common",
  },
  {
    label: "COURTAULY",
    value: "11107",
    type: "common",
  },
  {
    label: "LA BEZOLE",
    value: "11039",
    type: "common",
  },
  {
    label: "TREZIERS",
    value: "11400",
    type: "common",
  },
  {
    label: "PEYREFITTE-DU-RAZES",
    value: "11282",
    type: "common",
  },
  {
    label: "CORBIERES",
    value: "11100",
    type: "common",
  },
  {
    label: "VAL DE LAMBRONNE",
    value: "11080",
    type: "common",
  },
  {
    label: "SAINT-QUENTIN-LA-TOUR",
    value: "09274",
    type: "common",
  },
  {
    label: "ROUMENGOUX",
    value: "09251",
    type: "common",
  },
  {
    label: "MOULIN-NEUF",
    value: "09213",
    type: "common",
  },
  {
    label: "LAGARDE",
    value: "09150",
    type: "common",
  },
  {
    label: "LA BASTIDE-DE-BOUSIGNAC",
    value: "09039",
    type: "common",
  },
  {
    label: "TOURTROL",
    value: "09314",
    type: "common",
  },
  {
    label: "SAINT-JULIEN-DE-GRAS-CAPOU",
    value: "09266",
    type: "common",
  },
  {
    label: "COUTENS",
    value: "09102",
    type: "common",
  },
  {
    label: "BESSET",
    value: "09052",
    type: "common",
  },
  {
    label: "VIVIES",
    value: "09341",
    type: "common",
  },
  {
    label: "VIRA",
    value: "09340",
    type: "common",
  },
  {
    label: "RIEUCROS",
    value: "09244",
    type: "common",
  },
  {
    label: "MALLEON",
    value: "09179",
    type: "common",
  },
  {
    label: "LES ISSARDS",
    value: "09145",
    type: "common",
  },
  {
    label: "DUN",
    value: "09107",
    type: "common",
  },
  {
    label: "CALZAN",
    value: "09072",
    type: "common",
  },
  {
    label: "ARVIGNA",
    value: "09022",
    type: "common",
  },
  {
    label: "VERNIOLLE",
    value: "09332",
    type: "common",
  },
  {
    label: "SEGURA",
    value: "09284",
    type: "common",
  },
  {
    label: "SAINT-FELIX-DE-RIEUTORD",
    value: "09258",
    type: "common",
  },
  {
    label: "DALOU",
    value: "09104",
    type: "common",
  },
  {
    label: "COUSSA",
    value: "09101",
    type: "common",
  },
  {
    label: "VARILHES",
    value: "09324",
    type: "common",
  },
  {
    label: "RIEUX-DE-PELLEPORT",
    value: "09245",
    type: "common",
  },
  {
    label: "CRAMPAGNA",
    value: "09103",
    type: "common",
  },
  {
    label: "BENAGUES",
    value: "09050",
    type: "common",
  },
  {
    label: "ARTIX",
    value: "09021",
    type: "common",
  },
  {
    label: "MONTEGUT-PLANTAUREL",
    value: "09202",
    type: "common",
  },
  {
    label: "LOUBENS",
    value: "09173",
    type: "common",
  },
  {
    label: "CAZAUX",
    value: "09090",
    type: "common",
  },
  {
    label: "SUZAN",
    value: "09304",
    type: "common",
  },
  {
    label: "GABRE",
    value: "09127",
    type: "common",
  },
  {
    label: "AIGUES-JUNTES",
    value: "09001",
    type: "common",
  },
  {
    label: "LE MAS-D'AZIL",
    value: "09181",
    type: "common",
  },
  {
    label: "DURBAN-SUR-ARIZE",
    value: "09108",
    type: "common",
  },
  {
    label: "ALLIERES",
    value: "09007",
    type: "common",
  },
  {
    label: "MONTSERON",
    value: "09212",
    type: "common",
  },
  {
    label: "CLERMONT",
    value: "09097",
    type: "common",
  },
  {
    label: "CAMARADE",
    value: "09073",
    type: "common",
  },
  {
    label: "MONTESQUIEU-AVANTES",
    value: "09204",
    type: "common",
  },
  {
    label: "MONTARDIT",
    value: "09198",
    type: "common",
  },
  {
    label: "MAUVEZIN-DE-SAINTE-CROIX",
    value: "09184",
    type: "common",
  },
  {
    label: "CONTRAZY",
    value: "09098",
    type: "common",
  },
  {
    label: "TAURIGNAN-VIEUX",
    value: "09308",
    type: "common",
  },
  {
    label: "TAURIGNAN-CASTET",
    value: "09307",
    type: "common",
  },
  {
    label: "GAJAN",
    value: "09128",
    type: "common",
  },
  {
    label: "CAUMONT",
    value: "09086",
    type: "common",
  },
  {
    label: "BARJAC",
    value: "09037",
    type: "common",
  },
  {
    label: "PRAT-BONREPAUX",
    value: "09235",
    type: "common",
  },
  {
    label: "MERCENAC",
    value: "09187",
    type: "common",
  },
  {
    label: "LACAVE",
    value: "09148",
    type: "common",
  },
  {
    label: "LA BASTIDE-DU-SALAT",
    value: "09041",
    type: "common",
  },
  {
    label: "BAGERT",
    value: "09033",
    type: "common",
  },
  {
    label: "SALEICH",
    value: "31521",
    type: "common",
  },
  {
    label: "MONTGAILLARD-DE-SALIES",
    value: "31376",
    type: "common",
  },
  {
    label: "HIS",
    value: "31237",
    type: "common",
  },
  {
    label: "CASTELBIAGUE",
    value: "31114",
    type: "common",
  },
  {
    label: "CASTAGNEDE",
    value: "31112",
    type: "common",
  },
  {
    label: "MAUVEZIN-DE-PRAT",
    value: "09183",
    type: "common",
  },
  {
    label: "ROUEDE",
    value: "31461",
    type: "common",
  },
  {
    label: "MONTASTRUC-DE-SALIES",
    value: "31357",
    type: "common",
  },
  {
    label: "GANTIES",
    value: "31208",
    type: "common",
  },
  {
    label: "ESTADENS",
    value: "31174",
    type: "common",
  },
  {
    label: "SOUEICH",
    value: "31550",
    type: "common",
  },
  {
    label: "LESPITEAU",
    value: "31294",
    type: "common",
  },
  {
    label: "COURET",
    value: "31155",
    type: "common",
  },
  {
    label: "REGADES",
    value: "31449",
    type: "common",
  },
  {
    label: "PAYSSOUS",
    value: "31408",
    type: "common",
  },
  {
    label: "ENCAUSSE-LES-THERMES",
    value: "31167",
    type: "common",
  },
  {
    label: "CABANAC-CAZAUX",
    value: "31095",
    type: "common",
  },
  {
    label: "ASPRET-SARRAT",
    value: "31021",
    type: "common",
  },
  {
    label: "LOURES-BAROUSSE",
    value: "65287",
    type: "common",
  },
  {
    label: "IZAOURT",
    value: "65230",
    type: "common",
  },
  {
    label: "SAUVETERRE-DE-COMMINGES",
    value: "31535",
    type: "common",
  },
  {
    label: "LUSCAN",
    value: "31308",
    type: "common",
  },
  {
    label: "LABROQUERE",
    value: "31255",
    type: "common",
  },
  {
    label: "HUOS",
    value: "31238",
    type: "common",
  },
  {
    label: "CIER-DE-RIVIERE",
    value: "31143",
    type: "common",
  },
  {
    label: "BARBAZAN",
    value: "31045",
    type: "common",
  },
  {
    label: "ARDIEGE",
    value: "31013",
    type: "common",
  },
  {
    label: "TIBIRAN-JAUNAC",
    value: "65444",
    type: "common",
  },
  {
    label: "SARP",
    value: "65407",
    type: "common",
  },
  {
    label: "GENEREST",
    value: "65194",
    type: "common",
  },
  {
    label: "AVENTIGNAN",
    value: "65051",
    type: "common",
  },
  {
    label: "VALCABRERE",
    value: "31564",
    type: "common",
  },
  {
    label: "SEILHAN",
    value: "31542",
    type: "common",
  },
  {
    label: "SAINT-BERTRAND-DE-COMMINGES",
    value: "31472",
    type: "common",
  },
  {
    label: "NESTIER",
    value: "65327",
    type: "common",
  },
  {
    label: "MONTSERIE",
    value: "65323",
    type: "common",
  },
  {
    label: "MONTEGUT",
    value: "65319",
    type: "common",
  },
  {
    label: "LOMBRES",
    value: "65277",
    type: "common",
  },
  {
    label: "HAUTAGET",
    value: "65217",
    type: "common",
  },
  {
    label: "BIZOUS",
    value: "65094",
    type: "common",
  },
  {
    label: "BIZE",
    value: "65093",
    type: "common",
  },
  {
    label: "SAINT-ARROMAN",
    value: "65385",
    type: "common",
  },
  {
    label: "MONTOUSSE",
    value: "65322",
    type: "common",
  },
  {
    label: "MAZOUAU",
    value: "65309",
    type: "common",
  },
  {
    label: "LORTET",
    value: "65279",
    type: "common",
  },
  {
    label: "IZAUX",
    value: "65231",
    type: "common",
  },
  {
    label: "GAZAVE",
    value: "65190",
    type: "common",
  },
  {
    label: "BAZUS-NESTE",
    value: "65076",
    type: "common",
  },
  {
    label: "LOMNE",
    value: "65278",
    type: "common",
  },
  {
    label: "LABORDE",
    value: "65241",
    type: "common",
  },
  {
    label: "LABASTIDE",
    value: "65239",
    type: "common",
  },
  {
    label: "ESPECHE",
    value: "65166",
    type: "common",
  },
  {
    label: "ESPARROS",
    value: "65165",
    type: "common",
  },
  {
    label: "BULAN",
    value: "65111",
    type: "common",
  },
  {
    label: "BATSERE",
    value: "65071",
    type: "common",
  },
  {
    label: "AVEZAC-PRAT-LAHITTE",
    value: "65054",
    type: "common",
  },
  {
    label: "ARRODETS",
    value: "65034",
    type: "common",
  },
  {
    label: "MARSAS",
    value: "65300",
    type: "common",
  },
  {
    label: "LIES",
    value: "65275",
    type: "common",
  },
  {
    label: "FRECHENDETS",
    value: "65179",
    type: "common",
  },
  {
    label: "ESCOTS",
    value: "65163",
    type: "common",
  },
  {
    label: "BANIOS",
    value: "65060",
    type: "common",
  },
  {
    label: "ASTE",
    value: "65042",
    type: "common",
  },
  {
    label: "ASQUE",
    value: "65041",
    type: "common",
  },
  {
    label: "GERDE",
    value: "65198",
    type: "common",
  },
  {
    label: "GERMS-SUR-L'OUSSOUET",
    value: "65200",
    type: "common",
  },
  {
    label: "CHEUST",
    value: "65144",
    type: "common",
  },
  {
    label: "BAGNERES-DE-BIGORRE",
    value: "65059",
    type: "common",
  },
  {
    label: "SAINT-PASTOUS",
    value: "65393",
    type: "common",
  },
  {
    label: "OUSTE",
    value: "65351",
    type: "common",
  },
  {
    label: "OURDON",
    value: "65349",
    type: "common",
  },
  {
    label: "OURDIS-COTDOUSSAN",
    value: "65348",
    type: "common",
  },
  {
    label: "JUNCALAS",
    value: "65237",
    type: "common",
  },
  {
    label: "GER",
    value: "65197",
    type: "common",
  },
  {
    label: "GAZOST",
    value: "65191",
    type: "common",
  },
  {
    label: "BERBERUST-LIAS",
    value: "65082",
    type: "common",
  },
  {
    label: "VIGER",
    value: "65470",
    type: "common",
  },
  {
    label: "SEGUS",
    value: "65415",
    type: "common",
  },
  {
    label: "OUZOUS",
    value: "65352",
    type: "common",
  },
  {
    label: "GEZ",
    value: "65202",
    type: "common",
  },
  {
    label: "GEU",
    value: "65201",
    type: "common",
  },
  {
    label: "BOO-SILHEN",
    value: "65098",
    type: "common",
  },
  {
    label: "AYZAC-OST",
    value: "65056",
    type: "common",
  },
  {
    label: "AYROS-ARBOUIX",
    value: "65055",
    type: "common",
  },
  {
    label: "ARGELES-GAZOST",
    value: "65025",
    type: "common",
  },
  {
    label: "AGOS-VIDALOS",
    value: "65004",
    type: "common",
  },
  {
    label: "SERE-EN-LAVEDAN",
    value: "65420",
    type: "common",
  },
  {
    label: "SALLES",
    value: "65400",
    type: "common",
  },
  {
    label: "GAILLAGOS",
    value: "65182",
    type: "common",
  },
  {
    label: "ARCIZANS-DESSUS",
    value: "65022",
    type: "common",
  },
  {
    label: "FERRIERES",
    value: "65176",
    type: "common",
  },
  {
    label: "LOUVIE-SOUBIRON",
    value: "64354",
    type: "common",
  },
  {
    label: "GERE-BELESTEN",
    value: "64240",
    type: "common",
  },
  {
    label: "ASTE-BEON",
    value: "64069",
    type: "common",
  },
  {
    label: "BIELLE",
    value: "64127",
    type: "common",
  },
  {
    label: "AYDIUS",
    value: "64085",
    type: "common",
  },
  {
    label: "BEDOUS",
    value: "64104",
    type: "common",
  },
  {
    label: "OSSE-EN-ASPE",
    value: "64433",
    type: "common",
  },
  {
    label: "LOURDIOS-ICHERE",
    value: "64351",
    type: "common",
  },
  {
    label: "ARETTE",
    value: "64040",
    type: "common",
  },
  {
    label: "SAINTE-ENGRACE",
    value: "64475",
    type: "common",
  },
  {
    label: "LARRAU",
    value: "64316",
    type: "common",
  },
  {
    label: "SANTA-MARIA-DI-LOTA",
    value: "2B309",
    type: "common",
  },
  {
    label: "SAN-MARTINO-DI-LOTA",
    value: "2B305",
    type: "common",
  },
  {
    label: "BRANDO",
    value: "2B043",
    type: "common",
  },
  {
    label: "OLMETA-DI-CAPOCORSO",
    value: "2B187",
    type: "common",
  },
  {
    label: "NONZA",
    value: "2B178",
    type: "common",
  },
  {
    label: "PORT-LA-NOUVELLE",
    value: "11266",
    type: "common",
  },
  {
    label: "LA PALME",
    value: "11188",
    type: "common",
  },
  {
    label: "ROQUEFORT-DES-CORBIERES",
    value: "11322",
    type: "common",
  },
  {
    label: "VILLESEQUE-DES-CORBIERES",
    value: "11436",
    type: "common",
  },
  {
    label: "DURBAN-CORBIERES",
    value: "11124",
    type: "common",
  },
  {
    label: "CASCASTEL-DES-CORBIERES",
    value: "11071",
    type: "common",
  },
  {
    label: "ALBAS",
    value: "11006",
    type: "common",
  },
  {
    label: "VILLEROUGE-TERMENES",
    value: "11435",
    type: "common",
  },
  {
    label: "FELINES-TERMENES",
    value: "11137",
    type: "common",
  },
  {
    label: "VIGNEVIEILLE",
    value: "11409",
    type: "common",
  },
  {
    label: "TERMES",
    value: "11388",
    type: "common",
  },
  {
    label: "SALZA",
    value: "11374",
    type: "common",
  },
  {
    label: "MONTJOI",
    value: "11250",
    type: "common",
  },
  {
    label: "LAIRIERE",
    value: "11186",
    type: "common",
  },
  {
    label: "VILLARDEBELLE",
    value: "11412",
    type: "common",
  },
  {
    label: "VALMIGERE",
    value: "11402",
    type: "common",
  },
  {
    label: "MISSEGRE",
    value: "11235",
    type: "common",
  },
  {
    label: "BOUISSE",
    value: "11044",
    type: "common",
  },
  {
    label: "VERAZA",
    value: "11406",
    type: "common",
  },
  {
    label: "TERROLES",
    value: "11389",
    type: "common",
  },
  {
    label: "MAGRIE",
    value: "11211",
    type: "common",
  },
  {
    label: "ALET-LES-BAINS",
    value: "11008",
    type: "common",
  },
  {
    label: "TOURREILLES",
    value: "11394",
    type: "common",
  },
  {
    label: "CASTELRENG",
    value: "11078",
    type: "common",
  },
  {
    label: "BOURIEGE",
    value: "11045",
    type: "common",
  },
  {
    label: "SAINT-COUAT-DU-RAZES",
    value: "11338",
    type: "common",
  },
  {
    label: "SAINT-BENOIT",
    value: "11333",
    type: "common",
  },
  {
    label: "MONTJARDIN",
    value: "11249",
    type: "common",
  },
  {
    label: "BOURIGEOLE",
    value: "11046",
    type: "common",
  },
  {
    label: "SONNAC-SUR-L'HERS",
    value: "11380",
    type: "common",
  },
  {
    label: "CHALABRE",
    value: "11091",
    type: "common",
  },
  {
    label: "MONTBEL",
    value: "09200",
    type: "common",
  },
  {
    label: "CAMON",
    value: "09074",
    type: "common",
  },
  {
    label: "TROYE-D'ARIEGE",
    value: "09316",
    type: "common",
  },
  {
    label: "REGAT",
    value: "09243",
    type: "common",
  },
  {
    label: "LERAN",
    value: "09161",
    type: "common",
  },
  {
    label: "BELLOC",
    value: "09048",
    type: "common",
  },
  {
    label: "TABRE",
    value: "09305",
    type: "common",
  },
  {
    label: "SAUTEL",
    value: "09281",
    type: "common",
  },
  {
    label: "PRADETTES",
    value: "09233",
    type: "common",
  },
  {
    label: "LIMBRASSAC",
    value: "09169",
    type: "common",
  },
  {
    label: "LAROQUE-D'OLMES",
    value: "09157",
    type: "common",
  },
  {
    label: "ESCLAGNE",
    value: "09115",
    type: "common",
  },
  {
    label: "AIGUES-VIVES",
    value: "09002",
    type: "common",
  },
  {
    label: "VENTENAC",
    value: "09327",
    type: "common",
  },
  {
    label: "LIEURAC",
    value: "09168",
    type: "common",
  },
  {
    label: "CARLA-DE-ROQUEFORT",
    value: "09080",
    type: "common",
  },
  {
    label: "L'HERM",
    value: "09138",
    type: "common",
  },
  {
    label: "GUDAS",
    value: "09137",
    type: "common",
  },
  {
    label: "VERNAJOUL",
    value: "09329",
    type: "common",
  },
  {
    label: "SAINT-JEAN-DE-VERGES",
    value: "09264",
    type: "common",
  },
  {
    label: "LOUBIERES",
    value: "09174",
    type: "common",
  },
  {
    label: "COS",
    value: "09099",
    type: "common",
  },
  {
    label: "ARABAUX",
    value: "09013",
    type: "common",
  },
  {
    label: "SERRES-SUR-ARGET",
    value: "09293",
    type: "common",
  },
  {
    label: "SAINT-PIERRE-DE-RIVIERE",
    value: "09273",
    type: "common",
  },
  {
    label: "SAINT-MARTIN-DE-CARALP",
    value: "09269",
    type: "common",
  },
  {
    label: "CADARCET",
    value: "09071",
    type: "common",
  },
  {
    label: "BAULOU",
    value: "09044",
    type: "common",
  },
  {
    label: "NESCUS",
    value: "09216",
    type: "common",
  },
  {
    label: "MONTELS",
    value: "09203",
    type: "common",
  },
  {
    label: "MONTAGAGNE",
    value: "09196",
    type: "common",
  },
  {
    label: "LARBONT",
    value: "09154",
    type: "common",
  },
  {
    label: "LA BASTIDE-DE-SEROU",
    value: "09042",
    type: "common",
  },
  {
    label: "ALZEN",
    value: "09009",
    type: "common",
  },
  {
    label: "ESPLAS-DE-SEROU",
    value: "09118",
    type: "common",
  },
  {
    label: "CASTELNAU-DURBAN",
    value: "09082",
    type: "common",
  },
  {
    label: "RIMONT",
    value: "09246",
    type: "common",
  },
  {
    label: "LESCURE",
    value: "09164",
    type: "common",
  },
  {
    label: "SAINT-GIRONS",
    value: "09261",
    type: "common",
  },
  {
    label: "MONTJOIE-EN-COUSERANS",
    value: "09209",
    type: "common",
  },
  {
    label: "ENCOURTIECH",
    value: "09110",
    type: "common",
  },
  {
    label: "LORP-SENTARAILLE",
    value: "09289",
    type: "common",
  },
  {
    label: "SAINT-LIZIER",
    value: "09268",
    type: "common",
  },
  {
    label: "MONTGAUCH",
    value: "09208",
    type: "common",
  },
  {
    label: "MONTEGUT-EN-COUSERANS",
    value: "09201",
    type: "common",
  },
  {
    label: "FRANCAZAL",
    value: "31195",
    type: "common",
  },
  {
    label: "CAZAVET",
    value: "09091",
    type: "common",
  },
  {
    label: "BALAGUERES",
    value: "09035",
    type: "common",
  },
  {
    label: "URAU",
    value: "31562",
    type: "common",
  },
  {
    label: "FOUGARON",
    value: "31191",
    type: "common",
  },
  {
    label: "HERRAN",
    value: "31236",
    type: "common",
  },
  {
    label: "CHEIN-DESSUS",
    value: "31140",
    type: "common",
  },
  {
    label: "ARBAS",
    value: "31011",
    type: "common",
  },
  {
    label: "RAZECUEILLE",
    value: "31447",
    type: "common",
  },
  {
    label: "MILHAS",
    value: "31342",
    type: "common",
  },
  {
    label: "JUZET-D'IZAUT",
    value: "31245",
    type: "common",
  },
  {
    label: "ASPET",
    value: "31020",
    type: "common",
  },
  {
    label: "SAINT-PE-D'ARDET",
    value: "31509",
    type: "common",
  },
  {
    label: "MONCAUP",
    value: "31348",
    type: "common",
  },
  {
    label: "MALVEZIE",
    value: "31313",
    type: "common",
  },
  {
    label: "IZAUT-DE-L'HOTEL",
    value: "31241",
    type: "common",
  },
  {
    label: "CAZAUNOUS",
    value: "31131",
    type: "common",
  },
  {
    label: "ARGUENOS",
    value: "31014",
    type: "common",
  },
  {
    label: "ARBON",
    value: "31012",
    type: "common",
  },
  {
    label: "ANTICHAN-DE-FRONTIGNES",
    value: "31009",
    type: "common",
  },
  {
    label: "THEBE",
    value: "65441",
    type: "common",
  },
  {
    label: "SIRADAN",
    value: "65427",
    type: "common",
  },
  {
    label: "SAMURAN",
    value: "65402",
    type: "common",
  },
  {
    label: "SALECHAN",
    value: "65398",
    type: "common",
  },
  {
    label: "SAINTE-MARIE",
    value: "65391",
    type: "common",
  },
  {
    label: "ILHEU",
    value: "65229",
    type: "common",
  },
  {
    label: "BERTREN",
    value: "65087",
    type: "common",
  },
  {
    label: "ORE",
    value: "31405",
    type: "common",
  },
  {
    label: "MONT-DE-GALIE",
    value: "31369",
    type: "common",
  },
  {
    label: "LOURDE",
    value: "31306",
    type: "common",
  },
  {
    label: "GENOS",
    value: "31217",
    type: "common",
  },
  {
    label: "GALIE",
    value: "31207",
    type: "common",
  },
  {
    label: "FRONTIGNAN-DE-COMMINGES",
    value: "31200",
    type: "common",
  },
  {
    label: "BAGIRY",
    value: "31041",
    type: "common",
  },
  {
    label: "TROUBAT",
    value: "65453",
    type: "common",
  },
  {
    label: "SACOUE",
    value: "65382",
    type: "common",
  },
  {
    label: "OURDE",
    value: "65347",
    type: "common",
  },
  {
    label: "GEMBRIE",
    value: "65193",
    type: "common",
  },
  {
    label: "GAUDENT",
    value: "65186",
    type: "common",
  },
  {
    label: "CRECHETS",
    value: "65154",
    type: "common",
  },
  {
    label: "BRAMEVAQUE",
    value: "65109",
    type: "common",
  },
  {
    label: "AVEUX",
    value: "65053",
    type: "common",
  },
  {
    label: "ANTICHAN",
    value: "65014",
    type: "common",
  },
  {
    label: "ANLA",
    value: "65012",
    type: "common",
  },
  {
    label: "SEICH",
    value: "65416",
    type: "common",
  },
  {
    label: "NISTOS",
    value: "65329",
    type: "common",
  },
  {
    label: "ILHET",
    value: "65228",
    type: "common",
  },
  {
    label: "HECHES",
    value: "65218",
    type: "common",
  },
  {
    label: "BEAUDEAN",
    value: "65078",
    type: "common",
  },
  {
    label: "VILLELONGUE",
    value: "65473",
    type: "common",
  },
  {
    label: "VIER-BORDES",
    value: "65467",
    type: "common",
  },
  {
    label: "BEAUCENS",
    value: "65077",
    type: "common",
  },
  {
    label: "ARTALENS-SOUIN",
    value: "65036",
    type: "common",
  },
  {
    label: "UZ",
    value: "65458",
    type: "common",
  },
  {
    label: "SOULOM",
    value: "65435",
    type: "common",
  },
  {
    label: "SAINT-SAVIN",
    value: "65396",
    type: "common",
  },
  {
    label: "PRECHAC",
    value: "65371",
    type: "common",
  },
  {
    label: "PIERREFITTE-NESTALAS",
    value: "65362",
    type: "common",
  },
  {
    label: "LAU-BALAGNAS",
    value: "65267",
    type: "common",
  },
  {
    label: "ARCIZANS-AVANT",
    value: "65021",
    type: "common",
  },
  {
    label: "ADAST",
    value: "65001",
    type: "common",
  },
  {
    label: "SIREIX",
    value: "65428",
    type: "common",
  },
  {
    label: "BUN",
    value: "65112",
    type: "common",
  },
  {
    label: "ARRAS-EN-LAVEDAN",
    value: "65029",
    type: "common",
  },
  {
    label: "AUCUN",
    value: "65045",
    type: "common",
  },
  {
    label: "ARRENS-MARSOUS",
    value: "65032",
    type: "common",
  },
  {
    label: "ARBEOST",
    value: "65018",
    type: "common",
  },
  {
    label: "EAUX-BONNES",
    value: "64204",
    type: "common",
  },
  {
    label: "BEOST",
    value: "64110",
    type: "common",
  },
  {
    label: "CETTE-EYGUN",
    value: "64185",
    type: "common",
  },
  {
    label: "ACCOUS",
    value: "64006",
    type: "common",
  },
  {
    label: "LESCUN",
    value: "64336",
    type: "common",
  },
  {
    label: "LEES-ATHAS",
    value: "64330",
    type: "common",
  },
  {
    label: "VILLE-DI-PIETRABUGNO",
    value: "2B353",
    type: "common",
  },
  {
    label: "BASTIA",
    value: "2B033",
    type: "common",
  },
  {
    label: "PATRIMONIO",
    value: "2B205",
    type: "common",
  },
  {
    label: "FARINOLE",
    value: "2B109",
    type: "common",
  },
  {
    label: "BARBAGGIO",
    value: "2B029",
    type: "common",
  },
  {
    label: "LEUCATE",
    value: "11202",
    type: "common",
  },
  {
    label: "TREILLES",
    value: "11398",
    type: "common",
  },
  {
    label: "CAVES",
    value: "11086",
    type: "common",
  },
  {
    label: "FRAISSE-DES-CORBIERES",
    value: "11157",
    type: "common",
  },
  {
    label: "FEUILLA",
    value: "11143",
    type: "common",
  },
  {
    label: "SAINT-JEAN-DE-BARROU",
    value: "11345",
    type: "common",
  },
  {
    label: "EMBRES-ET-CASTELMAURE",
    value: "11125",
    type: "common",
  },
  {
    label: "VILLENEUVE-LES-CORBIERES",
    value: "11431",
    type: "common",
  },
  {
    label: "QUINTILLAN",
    value: "11305",
    type: "common",
  },
  {
    label: "PALAIRAC",
    value: "11271",
    type: "common",
  },
  {
    label: "MAISONS",
    value: "11213",
    type: "common",
  },
  {
    label: "DAVEJEAN",
    value: "11117",
    type: "common",
  },
  {
    label: "MOUTHOUMET",
    value: "11260",
    type: "common",
  },
  {
    label: "MASSAC",
    value: "11224",
    type: "common",
  },
  {
    label: "LAROQUE-DE-FA",
    value: "11191",
    type: "common",
  },
  {
    label: "DERNACUEILLETTE",
    value: "11118",
    type: "common",
  },
  {
    label: "LANET",
    value: "11187",
    type: "common",
  },
  {
    label: "FOURTOU",
    value: "11155",
    type: "common",
  },
  {
    label: "AURIAC",
    value: "11020",
    type: "common",
  },
  {
    label: "ALBIERES",
    value: "11007",
    type: "common",
  },
  {
    label: "ARQUES",
    value: "11015",
    type: "common",
  },
  {
    label: "SERRES",
    value: "11377",
    type: "common",
  },
  {
    label: "RENNES-LES-BAINS",
    value: "11310",
    type: "common",
  },
  {
    label: "PEYROLLES",
    value: "11287",
    type: "common",
  },
  {
    label: "LUC-SUR-AUDE",
    value: "11209",
    type: "common",
  },
  {
    label: "COUSTAUSSA",
    value: "11109",
    type: "common",
  },
  {
    label: "CASSAIGNES",
    value: "11073",
    type: "common",
  },
  {
    label: "RENNES-LE-CHATEAU",
    value: "11309",
    type: "common",
  },
  {
    label: "MONTAZELS",
    value: "11240",
    type: "common",
  },
  {
    label: "ESPERAZA",
    value: "11129",
    type: "common",
  },
  {
    label: "COUIZA",
    value: "11103",
    type: "common",
  },
  {
    label: "CAMPAGNE-SUR-AUDE",
    value: "11063",
    type: "common",
  },
  {
    label: "ANTUGNAC",
    value: "11010",
    type: "common",
  },
  {
    label: "LA SERPENT",
    value: "11376",
    type: "common",
  },
  {
    label: "FESTES-ET-SAINT-ANDRE",
    value: "11142",
    type: "common",
  },
  {
    label: "VILLEFORT",
    value: "11424",
    type: "common",
  },
  {
    label: "SAINT-JEAN-DE-PARACOL",
    value: "11346",
    type: "common",
  },
  {
    label: "PUIVERT",
    value: "11303",
    type: "common",
  },
  {
    label: "SAINTE-COLOMBE-SUR-L'HERS",
    value: "11336",
    type: "common",
  },
  {
    label: "RIVEL",
    value: "11316",
    type: "common",
  },
  {
    label: "SAINT-JEAN-D'AIGUES-VIVES",
    value: "09262",
    type: "common",
  },
  {
    label: "LE PEYRAT",
    value: "09229",
    type: "common",
  },
  {
    label: "LESPARROU",
    value: "09165",
    type: "common",
  },
  {
    label: "LA BASTIDE-SUR-L'HERS",
    value: "09043",
    type: "common",
  },
  {
    label: "L'AIGUILLON",
    value: "09003",
    type: "common",
  },
  {
    label: "VILLENEUVE-D'OLMES",
    value: "09336",
    type: "common",
  },
  {
    label: "RAISSAC",
    value: "09242",
    type: "common",
  },
  {
    label: "PEREILLE",
    value: "09227",
    type: "common",
  },
  {
    label: "LAVELANET",
    value: "09160",
    type: "common",
  },
  {
    label: "DREUILHE",
    value: "09106",
    type: "common",
  },
  {
    label: "ROQUEFORT-LES-CASCADES",
    value: "09250",
    type: "common",
  },
  {
    label: "ROQUEFIXADE",
    value: "09249",
    type: "common",
  },
  {
    label: "NALZEN",
    value: "09215",
    type: "common",
  },
  {
    label: "LEYCHERT",
    value: "09166",
    type: "common",
  },
  {
    label: "ILHAT",
    value: "09142",
    type: "common",
  },
  {
    label: "SOULA",
    value: "09300",
    type: "common",
  },
  {
    label: "PRADIERES",
    value: "09234",
    type: "common",
  },
  {
    label: "MONTGAILLARD",
    value: "09207",
    type: "common",
  },
  {
    label: "CELLES",
    value: "09093",
    type: "common",
  },
  {
    label: "PRAYOLS",
    value: "09236",
    type: "common",
  },
  {
    label: "FOIX",
    value: "09122",
    type: "common",
  },
  {
    label: "FERRIERES-SUR-ARIEGE",
    value: "09121",
    type: "common",
  },
  {
    label: "GANAC",
    value: "09130",
    type: "common",
  },
  {
    label: "BRASSAC",
    value: "09066",
    type: "common",
  },
  {
    label: "BENAC",
    value: "09049",
    type: "common",
  },
  {
    label: "BURRET",
    value: "09068",
    type: "common",
  },
  {
    label: "LE BOSC",
    value: "09063",
    type: "common",
  },
  {
    label: "SENTENAC-DE-SEROU",
    value: "09292",
    type: "common",
  },
  {
    label: "BOUSSENAC",
    value: "09065",
    type: "common",
  },
  {
    label: "SOULAN",
    value: "09301",
    type: "common",
  },
  {
    label: "RIVERENERT",
    value: "09247",
    type: "common",
  },
  {
    label: "LACOURT",
    value: "09149",
    type: "common",
  },
  {
    label: "ERP",
    value: "09114",
    type: "common",
  },
  {
    label: "MOULIS",
    value: "09214",
    type: "common",
  },
  {
    label: "EYCHEIL",
    value: "09119",
    type: "common",
  },
  {
    label: "ALOS",
    value: "09008",
    type: "common",
  },
  {
    label: "SOR",
    value: "09297",
    type: "common",
  },
  {
    label: "SALSEIN",
    value: "09279",
    type: "common",
  },
  {
    label: "ENGOMER",
    value: "09111",
    type: "common",
  },
  {
    label: "CESCAU",
    value: "09095",
    type: "common",
  },
  {
    label: "CASTILLON-EN-COUSERANS",
    value: "09085",
    type: "common",
  },
  {
    label: "AUDRESSEIN",
    value: "09026",
    type: "common",
  },
  {
    label: "ARROUT",
    value: "09018",
    type: "common",
  },
  {
    label: "VILLENEUVE",
    value: "09335",
    type: "common",
  },
  {
    label: "SAINT-JEAN-DU-CASTILLONNAIS",
    value: "09263",
    type: "common",
  },
  {
    label: "ORGIBET",
    value: "09219",
    type: "common",
  },
  {
    label: "ILLARTEIN",
    value: "09141",
    type: "common",
  },
  {
    label: "BUZAN",
    value: "09069",
    type: "common",
  },
  {
    label: "AUCAZEIN",
    value: "09025",
    type: "common",
  },
  {
    label: "ARGEIN",
    value: "09014",
    type: "common",
  },
  {
    label: "PORTET-D'ASPET",
    value: "31431",
    type: "common",
  },
  {
    label: "SAINT-LARY",
    value: "09267",
    type: "common",
  },
  {
    label: "GALEY",
    value: "09129",
    type: "common",
  },
  {
    label: "AUGIREIN",
    value: "09027",
    type: "common",
  },
  {
    label: "SENGOUAGNET",
    value: "31544",
    type: "common",
  },
  {
    label: "BOUTX",
    value: "31085",
    type: "common",
  },
  {
    label: "EUP",
    value: "31177",
    type: "common",
  },
  {
    label: "BEZINS-GARRAUX",
    value: "31067",
    type: "common",
  },
  {
    label: "BINOS",
    value: "31590",
    type: "common",
  },
  {
    label: "SIGNAC",
    value: "31548",
    type: "common",
  },
  {
    label: "MARIGNAC",
    value: "31316",
    type: "common",
  },
  {
    label: "FRONSAC",
    value: "31199",
    type: "common",
  },
  {
    label: "ESTENOS",
    value: "31176",
    type: "common",
  },
  {
    label: "CIERP-GAUD",
    value: "31144",
    type: "common",
  },
  {
    label: "CHAUM",
    value: "31139",
    type: "common",
  },
  {
    label: "BACHOS",
    value: "31040",
    type: "common",
  },
  {
    label: "SOST",
    value: "65431",
    type: "common",
  },
  {
    label: "MAULEON-BAROUSSE",
    value: "65305",
    type: "common",
  },
  {
    label: "ESBAREICH",
    value: "65158",
    type: "common",
  },
  {
    label: "CAZARILH",
    value: "65139",
    type: "common",
  },
  {
    label: "SARRANCOLIN",
    value: "65408",
    type: "common",
  },
  {
    label: "FERRERE",
    value: "65175",
    type: "common",
  },
  {
    label: "PAILHAC",
    value: "65354",
    type: "common",
  },
  {
    label: "JEZEAU",
    value: "65234",
    type: "common",
  },
  {
    label: "FRECHET-AURE",
    value: "65180",
    type: "common",
  },
  {
    label: "ARDENGOST",
    value: "65023",
    type: "common",
  },
  {
    label: "CADEAC",
    value: "65116",
    type: "common",
  },
  {
    label: "BARRANCOUEU",
    value: "65066",
    type: "common",
  },
  {
    label: "ASPIN-AURE",
    value: "65039",
    type: "common",
  },
  {
    label: "ARREAU",
    value: "65031",
    type: "common",
  },
  {
    label: "CAMPAN",
    value: "65123",
    type: "common",
  },
  {
    label: "SERS",
    value: "65424",
    type: "common",
  },
  {
    label: "VIEY",
    value: "65469",
    type: "common",
  },
  {
    label: "SALIGOS",
    value: "65399",
    type: "common",
  },
  {
    label: "CHEZE",
    value: "65145",
    type: "common",
  },
  {
    label: "VISCOS",
    value: "65478",
    type: "common",
  },
  {
    label: "GRUST",
    value: "65210",
    type: "common",
  },
  {
    label: "LARUNS",
    value: "64320",
    type: "common",
  },
  {
    label: "ETSAUT",
    value: "64223",
    type: "common",
  },
  {
    label: "BORCE",
    value: "64136",
    type: "common",
  },
  {
    label: "FURIANI",
    value: "2B120",
    type: "common",
  },
  {
    label: "BIGUGLIA",
    value: "2B037",
    type: "common",
  },
  {
    label: "POGGIO-D'OLETTA",
    value: "2B239",
    type: "common",
  },
  {
    label: "OLETTA",
    value: "2B185",
    type: "common",
  },
  {
    label: "SAINT-FLORENT",
    value: "2B298",
    type: "common",
  },
  {
    label: "RAPALE",
    value: "2B257",
    type: "common",
  },
  {
    label: "SANTO-PIETRO-DI-TENDA",
    value: "2B314",
    type: "common",
  },
  {
    label: "SAN-GAVINO-DI-TENDA",
    value: "2B301",
    type: "common",
  },
  {
    label: "PALASCA",
    value: "2B199",
    type: "common",
  },
  {
    label: "L'ILE-ROUSSE",
    value: "2B134",
    type: "common",
  },
  {
    label: "FITOU",
    value: "11144",
    type: "common",
  },
  {
    label: "OPOUL-PERILLOS",
    value: "66127",
    type: "common",
  },
  {
    label: "VINGRAU",
    value: "66231",
    type: "common",
  },
  {
    label: "TUCHAN",
    value: "11401",
    type: "common",
  },
  {
    label: "PAZIOLS",
    value: "11276",
    type: "common",
  },
  {
    label: "PADERN",
    value: "11270",
    type: "common",
  },
  {
    label: "MONTGAILLARD",
    value: "11245",
    type: "common",
  },
  {
    label: "CUCUGNAN",
    value: "11113",
    type: "common",
  },
  {
    label: "ROUFFIAC-DES-CORBIERES",
    value: "11326",
    type: "common",
  },
  {
    label: "DUILHAC-SOUS-PEYREPERTUSE",
    value: "11123",
    type: "common",
  },
  {
    label: "SOULATGE",
    value: "11384",
    type: "common",
  },
  {
    label: "CUBIERES-SUR-CINOBLE",
    value: "11112",
    type: "common",
  },
  {
    label: "SOUGRAIGNE",
    value: "11381",
    type: "common",
  },
  {
    label: "CAMPS-SUR-L'AGLY",
    value: "11065",
    type: "common",
  },
  {
    label: "SAINT-LOUIS-ET-PARAHOU",
    value: "11352",
    type: "common",
  },
  {
    label: "SAINT-JUST-ET-LE-BEZU",
    value: "11350",
    type: "common",
  },
  {
    label: "BUGARACH",
    value: "11055",
    type: "common",
  },
  {
    label: "SAINT-JULIA-DE-BEC",
    value: "11347",
    type: "common",
  },
  {
    label: "SAINT-FERRIOL",
    value: "11341",
    type: "common",
  },
  {
    label: "GRANES",
    value: "11168",
    type: "common",
  },
  {
    label: "QUILLAN",
    value: "11304",
    type: "common",
  },
  {
    label: "GINOLES",
    value: "11165",
    type: "common",
  },
  {
    label: "NEBIAS",
    value: "11263",
    type: "common",
  },
  {
    label: "COUDONS",
    value: "11101",
    type: "common",
  },
  {
    label: "BELVIS",
    value: "11036",
    type: "common",
  },
  {
    label: "ROQUEFEUIL",
    value: "11320",
    type: "common",
  },
  {
    label: "ESPEZEL",
    value: "11130",
    type: "common",
  },
  {
    label: "FOUGAX-ET-BARRINEUF",
    value: "09125",
    type: "common",
  },
  {
    label: "BELESTA",
    value: "09047",
    type: "common",
  },
  {
    label: "BENAIX",
    value: "09051",
    type: "common",
  },
  {
    label: "MONTFERRIER",
    value: "09206",
    type: "common",
  },
  {
    label: "FREYCHENET",
    value: "09126",
    type: "common",
  },
  {
    label: "SAINT-PAUL-DE-JARRAT",
    value: "09272",
    type: "common",
  },
  {
    label: "MERCUS-GARRABET",
    value: "09188",
    type: "common",
  },
  {
    label: "ARNAVE",
    value: "09016",
    type: "common",
  },
  {
    label: "SURBA",
    value: "09303",
    type: "common",
  },
  {
    label: "MONTOULIEU",
    value: "09210",
    type: "common",
  },
  {
    label: "BOMPAS",
    value: "09058",
    type: "common",
  },
  {
    label: "BEDEILHAC-ET-AYNAT",
    value: "09045",
    type: "common",
  },
  {
    label: "ARIGNAC",
    value: "09015",
    type: "common",
  },
  {
    label: "SAURAT",
    value: "09280",
    type: "common",
  },
  {
    label: "RABAT-LES-TROIS-SEIGNEURS",
    value: "09241",
    type: "common",
  },
  {
    label: "MASSAT",
    value: "09182",
    type: "common",
  },
  {
    label: "BIERT",
    value: "09057",
    type: "common",
  },
  {
    label: "ALEU",
    value: "09005",
    type: "common",
  },
  {
    label: "SOUEIX-ROGALLE",
    value: "09299",
    type: "common",
  },
  {
    label: "OUST",
    value: "09223",
    type: "common",
  },
  {
    label: "SENTENAC-D'OUST",
    value: "09291",
    type: "common",
  },
  {
    label: "BETHMALE",
    value: "09055",
    type: "common",
  },
  {
    label: "ARRIEN-EN-BETHMALE",
    value: "09017",
    type: "common",
  },
  {
    label: "BONAC-IRAZEIN",
    value: "09059",
    type: "common",
  },
  {
    label: "BALACET",
    value: "09034",
    type: "common",
  },
  {
    label: "SENTEIN",
    value: "09290",
    type: "common",
  },
  {
    label: "ANTRAS",
    value: "09011",
    type: "common",
  },
  {
    label: "MELLES",
    value: "31337",
    type: "common",
  },
  {
    label: "FOS",
    value: "31190",
    type: "common",
  },
  {
    label: "ARLOS",
    value: "31017",
    type: "common",
  },
  {
    label: "ARGUT-DESSOUS",
    value: "31015",
    type: "common",
  },
  {
    label: "GURAN",
    value: "31235",
    type: "common",
  },
  {
    label: "GOUAUX-DE-LUCHON",
    value: "31222",
    type: "common",
  },
  {
    label: "CAZAUX-LAYRISSE",
    value: "31132",
    type: "common",
  },
  {
    label: "BURGALAYS",
    value: "31092",
    type: "common",
  },
  {
    label: "BAREN",
    value: "31046",
    type: "common",
  },
  {
    label: "MAYREGNE",
    value: "31335",
    type: "common",
  },
  {
    label: "LEGE",
    value: "31290",
    type: "common",
  },
  {
    label: "CIER-DE-LUCHON",
    value: "31142",
    type: "common",
  },
  {
    label: "BAREILLES",
    value: "65064",
    type: "common",
  },
  {
    label: "CIRES",
    value: "31146",
    type: "common",
  },
  {
    label: "CAUBOUS",
    value: "31127",
    type: "common",
  },
  {
    label: "BOURG-D'OUEIL",
    value: "31081",
    type: "common",
  },
  {
    label: "VIELLE-LOURON",
    value: "65466",
    type: "common",
  },
  {
    label: "RIS",
    value: "65379",
    type: "common",
  },
  {
    label: "LANCON",
    value: "65255",
    type: "common",
  },
  {
    label: "GRAILHEN",
    value: "65208",
    type: "common",
  },
  {
    label: "GOUAUX",
    value: "65205",
    type: "common",
  },
  {
    label: "CAZAUX-FRECHET-ANERAN-CAMORS",
    value: "65141",
    type: "common",
  },
  {
    label: "CAZAUX-DEBAT",
    value: "65140",
    type: "common",
  },
  {
    label: "BORDERES-LOURON",
    value: "65099",
    type: "common",
  },
  {
    label: "AVAJAN",
    value: "65050",
    type: "common",
  },
  {
    label: "GUCHEN",
    value: "65212",
    type: "common",
  },
  {
    label: "GUCHAN",
    value: "65211",
    type: "common",
  },
  {
    label: "GREZIAN",
    value: "65209",
    type: "common",
  },
  {
    label: "CAMPARAN",
    value: "65124",
    type: "common",
  },
  {
    label: "BAZUS-AURE",
    value: "65075",
    type: "common",
  },
  {
    label: "ANCIZAN",
    value: "65006",
    type: "common",
  },
  {
    label: "VIELLE-AURE",
    value: "65465",
    type: "common",
  },
  {
    label: "AULON",
    value: "65046",
    type: "common",
  },
  {
    label: "BAREGES",
    value: "65481",
    type: "common",
  },
  {
    label: "BETPOUEY",
    value: "65089",
    type: "common",
  },
  {
    label: "VIELLA",
    value: "65463",
    type: "common",
  },
  {
    label: "SAZOS",
    value: "65413",
    type: "common",
  },
  {
    label: "SASSIS",
    value: "65411",
    type: "common",
  },
  {
    label: "LUZ-SAINT-SAUVEUR",
    value: "65295",
    type: "common",
  },
  {
    label: "ESTERRE",
    value: "65173",
    type: "common",
  },
  {
    label: "ESQUIEZE-SERE",
    value: "65168",
    type: "common",
  },
  {
    label: "CAUTERETS",
    value: "65138",
    type: "common",
  },
  {
    label: "ESTAING",
    value: "65169",
    type: "common",
  },
  {
    label: "URDOS",
    value: "64542",
    type: "common",
  },
  {
    label: "BORGO",
    value: "2B042",
    type: "common",
  },
  {
    label: "RUTALI",
    value: "2B265",
    type: "common",
  },
  {
    label: "VALLECALLE",
    value: "2B333",
    type: "common",
  },
  {
    label: "OLMETA-DI-TUDA",
    value: "2B188",
    type: "common",
  },
  {
    label: "SORIO",
    value: "2B287",
    type: "common",
  },
  {
    label: "PIEVE",
    value: "2B230",
    type: "common",
  },
  {
    label: "LAMA",
    value: "2B136",
    type: "common",
  },
  {
    label: "URTACA",
    value: "2B332",
    type: "common",
  },
  {
    label: "NOVELLA",
    value: "2B180",
    type: "common",
  },
  {
    label: "VILLE-DI-PARASO",
    value: "2B352",
    type: "common",
  },
  {
    label: "OCCHIATANA",
    value: "2B182",
    type: "common",
  },
  {
    label: "BELGODERE",
    value: "2B034",
    type: "common",
  },
  {
    label: "SANTA-REPARATA-DI-BALAGNA",
    value: "2B316",
    type: "common",
  },
  {
    label: "PIGNA",
    value: "2B231",
    type: "common",
  },
  {
    label: "MONTICELLO",
    value: "2B168",
    type: "common",
  },
  {
    label: "CORBARA",
    value: "2B093",
    type: "common",
  },
  {
    label: "ALGAJOLA",
    value: "2B010",
    type: "common",
  },
  {
    label: "SAINT-LAURENT-DE-LA-SALANQUE",
    value: "66180",
    type: "common",
  },
  {
    label: "LE BARCARES",
    value: "66017",
    type: "common",
  },
  {
    label: "SALSES-LE-CHATEAU",
    value: "66190",
    type: "common",
  },
  {
    label: "SAINT-HIPPOLYTE",
    value: "66176",
    type: "common",
  },
  {
    label: "ESPIRA-DE-L'AGLY",
    value: "66069",
    type: "common",
  },
  {
    label: "TAUTAVEL",
    value: "66205",
    type: "common",
  },
  {
    label: "MAURY",
    value: "66107",
    type: "common",
  },
  {
    label: "LESQUERDE",
    value: "66097",
    type: "common",
  },
  {
    label: "SAINT-PAUL-DE-FENOUILLET",
    value: "66187",
    type: "common",
  },
  {
    label: "PRUGNANES",
    value: "66152",
    type: "common",
  },
  {
    label: "CAUDIES-DE-FENOUILLEDES",
    value: "66046",
    type: "common",
  },
  {
    label: "PUILAURENS",
    value: "11302",
    type: "common",
  },
  {
    label: "SAINT-MARTIN-LYS",
    value: "11358",
    type: "common",
  },
  {
    label: "BELVIANES-ET-CAVIRAC",
    value: "11035",
    type: "common",
  },
  {
    label: "AXAT",
    value: "11021",
    type: "common",
  },
  {
    label: "ARTIGUES",
    value: "11017",
    type: "common",
  },
  {
    label: "QUIRBAJOU",
    value: "11306",
    type: "common",
  },
  {
    label: "MARSA",
    value: "11219",
    type: "common",
  },
  {
    label: "CAILLA",
    value: "11060",
    type: "common",
  },
  {
    label: "RODOME",
    value: "11317",
    type: "common",
  },
  {
    label: "JOUCOU",
    value: "11177",
    type: "common",
  },
  {
    label: "GALINAGUES",
    value: "11160",
    type: "common",
  },
  {
    label: "BELFORT-SUR-REBENTY",
    value: "11031",
    type: "common",
  },
  {
    label: "AUNAT",
    value: "11019",
    type: "common",
  },
  {
    label: "MAZUBY",
    value: "11229",
    type: "common",
  },
  {
    label: "COMUS",
    value: "11096",
    type: "common",
  },
  {
    label: "CAMURAC",
    value: "11066",
    type: "common",
  },
  {
    label: "BELCAIRE",
    value: "11028",
    type: "common",
  },
  {
    label: "PRADES",
    value: "09232",
    type: "common",
  },
  {
    label: "MONTSEGUR",
    value: "09211",
    type: "common",
  },
  {
    label: "LORDAT",
    value: "09171",
    type: "common",
  },
  {
    label: "CAYCHAX",
    value: "09088",
    type: "common",
  },
  {
    label: "AXIAT",
    value: "09031",
    type: "common",
  },
  {
    label: "APPY",
    value: "09012",
    type: "common",
  },
  {
    label: "VERDUN",
    value: "09328",
    type: "common",
  },
  {
    label: "SENCONAC",
    value: "09287",
    type: "common",
  },
  {
    label: "ORNOLAC-USSAT-LES-BAINS",
    value: "09221",
    type: "common",
  },
  {
    label: "CAZENAVE-SERRES-ET-ALLENS",
    value: "09092",
    type: "common",
  },
  {
    label: "BOUAN",
    value: "09064",
    type: "common",
  },
  {
    label: "USSAT",
    value: "09321",
    type: "common",
  },
  {
    label: "TARASCON-SUR-ARIEGE",
    value: "09306",
    type: "common",
  },
  {
    label: "QUIE",
    value: "09240",
    type: "common",
  },
  {
    label: "NIAUX",
    value: "09217",
    type: "common",
  },
  {
    label: "LARNAT",
    value: "09156",
    type: "common",
  },
  {
    label: "GENAT",
    value: "09133",
    type: "common",
  },
  {
    label: "CAPOULET-ET-JUNAC",
    value: "09077",
    type: "common",
  },
  {
    label: "ALLIAT",
    value: "09006",
    type: "common",
  },
  {
    label: "ORUS",
    value: "09222",
    type: "common",
  },
  {
    label: "LAPEGE",
    value: "09152",
    type: "common",
  },
  {
    label: "GOURBIT",
    value: "09136",
    type: "common",
  },
  {
    label: "LE PORT",
    value: "09231",
    type: "common",
  },
  {
    label: "USTOU",
    value: "09322",
    type: "common",
  },
  {
    label: "ERCE",
    value: "09113",
    type: "common",
  },
  {
    label: "SEIX",
    value: "09285",
    type: "common",
  },
  {
    label: "BORDES-UCHENTEIN",
    value: "09062",
    type: "common",
  },
  {
    label: "SODE",
    value: "31549",
    type: "common",
  },
  {
    label: "SALLES-ET-PRATVIEL",
    value: "31524",
    type: "common",
  },
  {
    label: "MONTAUBAN-DE-LUCHON",
    value: "31360",
    type: "common",
  },
  {
    label: "JUZET-DE-LUCHON",
    value: "31244",
    type: "common",
  },
  {
    label: "ARTIGUE",
    value: "31019",
    type: "common",
  },
  {
    label: "TREBONS-DE-LUCHON",
    value: "31559",
    type: "common",
  },
  {
    label: "SAINT-PAUL-D'OUEIL",
    value: "31508",
    type: "common",
  },
  {
    label: "SACCOURVIELLE",
    value: "31465",
    type: "common",
  },
  {
    label: "MOUSTAJON",
    value: "31394",
    type: "common",
  },
  {
    label: "CAZARILH-LASPENES",
    value: "31129",
    type: "common",
  },
  {
    label: "BILLIERE",
    value: "31068",
    type: "common",
  },
  {
    label: "BENQUE-DESSOUS-ET-DESSUS",
    value: "31064",
    type: "common",
  },
  {
    label: "ANTIGNAC",
    value: "31010",
    type: "common",
  },
  {
    label: "MONT",
    value: "65317",
    type: "common",
  },
  {
    label: "LOUDERVIELLE",
    value: "65283",
    type: "common",
  },
  {
    label: "POUBEAU",
    value: "31434",
    type: "common",
  },
  {
    label: "PORTET-DE-LUCHON",
    value: "31432",
    type: "common",
  },
  {
    label: "JURVIELLE",
    value: "31242",
    type: "common",
  },
  {
    label: "GOUAUX-DE-LARBOUST",
    value: "31221",
    type: "common",
  },
  {
    label: "GARIN",
    value: "31213",
    type: "common",
  },
  {
    label: "CATHERVIELLE",
    value: "31125",
    type: "common",
  },
  {
    label: "GERM",
    value: "65199",
    type: "common",
  },
  {
    label: "ESTARVIELLE",
    value: "65171",
    type: "common",
  },
  {
    label: "ADERVIELLE-POUCHERGUES",
    value: "65003",
    type: "common",
  },
  {
    label: "VIGNEC",
    value: "65471",
    type: "common",
  },
  {
    label: "SAILHAN",
    value: "65384",
    type: "common",
  },
  {
    label: "ESTENSAN",
    value: "65172",
    type: "common",
  },
  {
    label: "ENS",
    value: "65157",
    type: "common",
  },
  {
    label: "CADEILHAN-TRACHERE",
    value: "65117",
    type: "common",
  },
  {
    label: "BOURISP",
    value: "65106",
    type: "common",
  },
  {
    label: "AZET",
    value: "65058",
    type: "common",
  },
  {
    label: "SAINT-LARY-SOULAN",
    value: "65388",
    type: "common",
  },
  {
    label: "ARAGNOUET",
    value: "65017",
    type: "common",
  },
  {
    label: "GAVARNIE-GEDRE",
    value: "65192",
    type: "common",
  },
  {
    label: "VESCOVATO",
    value: "2B346",
    type: "common",
  },
  {
    label: "VENZOLASCA",
    value: "2B343",
    type: "common",
  },
  {
    label: "LUCCIANA",
    value: "2B148",
    type: "common",
  },
  {
    label: "VIGNALE",
    value: "2B350",
    type: "common",
  },
  {
    label: "PRUNELLI-DI-CASACCONI",
    value: "2B250",
    type: "common",
  },
  {
    label: "VOLPAJOLA",
    value: "2B355",
    type: "common",
  },
  {
    label: "SCOLCA",
    value: "2B274",
    type: "common",
  },
  {
    label: "MURATO",
    value: "2B172",
    type: "common",
  },
  {
    label: "CAMPITELLO",
    value: "2B055",
    type: "common",
  },
  {
    label: "BIGORNO",
    value: "2B036",
    type: "common",
  },
  {
    label: "LENTO",
    value: "2B140",
    type: "common",
  },
  {
    label: "PIETRALBA",
    value: "2B223",
    type: "common",
  },
  {
    label: "CASTIFAO",
    value: "2B080",
    type: "common",
  },
  {
    label: "PIOGGIOLA",
    value: "2B235",
    type: "common",
  },
  {
    label: "OLMI-CAPPELLA",
    value: "2B190",
    type: "common",
  },
  {
    label: "COSTA",
    value: "2B097",
    type: "common",
  },
  {
    label: "SANT'ANTONINO",
    value: "2B296",
    type: "common",
  },
  {
    label: "SPELONCATO",
    value: "2B290",
    type: "common",
  },
  {
    label: "NESSA",
    value: "2B175",
    type: "common",
  },
  {
    label: "MURO",
    value: "2B173",
    type: "common",
  },
  {
    label: "FELICETO",
    value: "2B112",
    type: "common",
  },
  {
    label: "CATERI",
    value: "2B084",
    type: "common",
  },
  {
    label: "AVAPESSA",
    value: "2B025",
    type: "common",
  },
  {
    label: "MONTEGROSSO",
    value: "2B167",
    type: "common",
  },
  {
    label: "LUMIO",
    value: "2B150",
    type: "common",
  },
  {
    label: "LAVATOGGIO",
    value: "2B138",
    type: "common",
  },
  {
    label: "AREGNO",
    value: "2B020",
    type: "common",
  },
  {
    label: "TORREILLES",
    value: "66212",
    type: "common",
  },
  {
    label: "PIA",
    value: "66141",
    type: "common",
  },
  {
    label: "CLAIRA",
    value: "66050",
    type: "common",
  },
  {
    label: "RIVESALTES",
    value: "66164",
    type: "common",
  },
  {
    label: "PEYRESTORTES",
    value: "66138",
    type: "common",
  },
  {
    label: "CASES-DE-PENE",
    value: "66041",
    type: "common",
  },
  {
    label: "CALCE",
    value: "66030",
    type: "common",
  },
  {
    label: "BAIXAS",
    value: "66014",
    type: "common",
  },
  {
    label: "MONTNER",
    value: "66118",
    type: "common",
  },
  {
    label: "ESTAGEL",
    value: "66071",
    type: "common",
  },
  {
    label: "PLANEZES",
    value: "66143",
    type: "common",
  },
  {
    label: "LATOUR-DE-FRANCE",
    value: "66096",
    type: "common",
  },
  {
    label: "CASSAGNES",
    value: "66042",
    type: "common",
  },
  {
    label: "TRILLA",
    value: "66216",
    type: "common",
  },
  {
    label: "SAINT-ARNAC",
    value: "66169",
    type: "common",
  },
  {
    label: "RASIGUERES",
    value: "66158",
    type: "common",
  },
  {
    label: "LANSAC",
    value: "66092",
    type: "common",
  },
  {
    label: "ANSIGNAN",
    value: "66006",
    type: "common",
  },
  {
    label: "LE VIVIER",
    value: "66234",
    type: "common",
  },
  {
    label: "SAINT-MARTIN-DE-FENOUILLET",
    value: "66184",
    type: "common",
  },
  {
    label: "PRATS-DE-SOURNIA",
    value: "66151",
    type: "common",
  },
  {
    label: "PEZILLA-DE-CONFLENT",
    value: "66139",
    type: "common",
  },
  {
    label: "FEILLUNS",
    value: "66076",
    type: "common",
  },
  {
    label: "VIRA",
    value: "66232",
    type: "common",
  },
  {
    label: "FOSSE",
    value: "66083",
    type: "common",
  },
  {
    label: "FENOUILLET",
    value: "66077",
    type: "common",
  },
  {
    label: "SALVEZINES",
    value: "11373",
    type: "common",
  },
  {
    label: "MONTFORT-SUR-BOULZANE",
    value: "11244",
    type: "common",
  },
  {
    label: "GINCLA",
    value: "11163",
    type: "common",
  },
  {
    label: "SAINTE-COLOMBE-SUR-GUETTE",
    value: "11335",
    type: "common",
  },
  {
    label: "ROQUEFORT-DE-SAULT",
    value: "11321",
    type: "common",
  },
  {
    label: "LE CLAT",
    value: "11093",
    type: "common",
  },
  {
    label: "BESSEDE-DE-SAULT",
    value: "11038",
    type: "common",
  },
  {
    label: "FONTANES-DE-SAULT",
    value: "11147",
    type: "common",
  },
  {
    label: "CAMPAGNA-DE-SAULT",
    value: "11062",
    type: "common",
  },
  {
    label: "ROUZE",
    value: "09252",
    type: "common",
  },
  {
    label: "NIORT-DE-SAULT",
    value: "11265",
    type: "common",
  },
  {
    label: "MERIAL",
    value: "11230",
    type: "common",
  },
  {
    label: "LA FAJOLLE",
    value: "11135",
    type: "common",
  },
  {
    label: "SORGEAT",
    value: "09298",
    type: "common",
  },
  {
    label: "MONTAILLOU",
    value: "09197",
    type: "common",
  },
  {
    label: "VAYCHIS",
    value: "09325",
    type: "common",
  },
  {
    label: "TIGNAC",
    value: "09311",
    type: "common",
  },
  {
    label: "IGNAUX",
    value: "09140",
    type: "common",
  },
  {
    label: "CAUSSOU",
    value: "09087",
    type: "common",
  },
  {
    label: "BESTIAC",
    value: "09053",
    type: "common",
  },
  {
    label: "VERNAUX",
    value: "09330",
    type: "common",
  },
  {
    label: "VEBRE",
    value: "09326",
    type: "common",
  },
  {
    label: "URS",
    value: "09320",
    type: "common",
  },
  {
    label: "UNAC",
    value: "09318",
    type: "common",
  },
  {
    label: "GARANOU",
    value: "09131",
    type: "common",
  },
  {
    label: "PECH",
    value: "09226",
    type: "common",
  },
  {
    label: "LASSUR",
    value: "09159",
    type: "common",
  },
  {
    label: "LARCAT",
    value: "09155",
    type: "common",
  },
  {
    label: "CHATEAU-VERDUN",
    value: "09096",
    type: "common",
  },
  {
    label: "LES CABANNES",
    value: "09070",
    type: "common",
  },
  {
    label: "ALBIES",
    value: "09004",
    type: "common",
  },
  {
    label: "MIGLOS",
    value: "09192",
    type: "common",
  },
  {
    label: "ILLIER-ET-LARAMADE",
    value: "09143",
    type: "common",
  },
  {
    label: "AULUS-LES-BAINS",
    value: "09029",
    type: "common",
  },
  {
    label: "COUFLENS",
    value: "09100",
    type: "common",
  },
  {
    label: "SAINT-MAMET",
    value: "31500",
    type: "common",
  },
  {
    label: "BAGNERES-DE-LUCHON",
    value: "31042",
    type: "common",
  },
  {
    label: "SAINT-AVENTIN",
    value: "31470",
    type: "common",
  },
  {
    label: "CAZEAUX-DE-LARBOUST",
    value: "31133",
    type: "common",
  },
  {
    label: "CASTILLON-DE-LARBOUST",
    value: "31123",
    type: "common",
  },
  {
    label: "OO",
    value: "31404",
    type: "common",
  },
  {
    label: "LOUDENVIELLE",
    value: "65282",
    type: "common",
  },
  {
    label: "GENOS",
    value: "65195",
    type: "common",
  },
  {
    label: "TRAMEZAIGUES",
    value: "65450",
    type: "common",
  },
  {
    label: "TAGLIO-ISOLACCIO",
    value: "2B318",
    type: "common",
  },
  {
    label: "SORBO-OCAGNANO",
    value: "2B286",
    type: "common",
  },
  {
    label: "PORRI",
    value: "2B245",
    type: "common",
  },
  {
    label: "PENTA-DI-CASINCA",
    value: "2B207",
    type: "common",
  },
  {
    label: "CASTELLARE-DI-CASINCA",
    value: "2B077",
    type: "common",
  },
  {
    label: "SILVARECCIO",
    value: "2B280",
    type: "common",
  },
  {
    label: "PIANO",
    value: "2B214",
    type: "common",
  },
  {
    label: "PENTA-ACQUATELLA",
    value: "2B206",
    type: "common",
  },
  {
    label: "OLMO",
    value: "2B192",
    type: "common",
  },
  {
    label: "MONTE",
    value: "2B166",
    type: "common",
  },
  {
    label: "LORETO-DI-CASINCA",
    value: "2B145",
    type: "common",
  },
  {
    label: "CASALTA",
    value: "2B072",
    type: "common",
  },
  {
    label: "CASABIANCA",
    value: "2B069",
    type: "common",
  },
  {
    label: "ORTIPORIO",
    value: "2B195",
    type: "common",
  },
  {
    label: "GIOCATOJO",
    value: "2B125",
    type: "common",
  },
  {
    label: "CROCICCHIA",
    value: "2B102",
    type: "common",
  },
  {
    label: "CASTELLO-DI-ROSTINO",
    value: "2B079",
    type: "common",
  },
  {
    label: "CAMPILE",
    value: "2B054",
    type: "common",
  },
  {
    label: "BISINCHI",
    value: "2B039",
    type: "common",
  },
  {
    label: "VALLE-DI-ROSTINO",
    value: "2B337",
    type: "common",
  },
  {
    label: "CANAVAGGIA",
    value: "2B059",
    type: "common",
  },
  {
    label: "MOLTIFAO",
    value: "2B162",
    type: "common",
  },
  {
    label: "VALLICA",
    value: "2B339",
    type: "common",
  },
  {
    label: "MAUSOLEO",
    value: "2B156",
    type: "common",
  },
  {
    label: "ZILIA",
    value: "2B361",
    type: "common",
  },
  {
    label: "MONCALE",
    value: "2B165",
    type: "common",
  },
  {
    label: "CALVI",
    value: "2B050",
    type: "common",
  },
  {
    label: "SAINTE-MARIE-LA-MER",
    value: "66182",
    type: "common",
  },
  {
    label: "CANET-EN-ROUSSILLON",
    value: "66037",
    type: "common",
  },
  {
    label: "VILLELONGUE-DE-LA-SALANQUE",
    value: "66224",
    type: "common",
  },
  {
    label: "PERPIGNAN",
    value: "66136",
    type: "common",
  },
  {
    label: "CABESTANY",
    value: "66028",
    type: "common",
  },
  {
    label: "BOMPAS",
    value: "66021",
    type: "common",
  },
  {
    label: "SAINT-ESTEVE",
    value: "66172",
    type: "common",
  },
  {
    label: "VILLENEUVE-LA-RIVIERE",
    value: "66228",
    type: "common",
  },
  {
    label: "LE SOLER",
    value: "66195",
    type: "common",
  },
  {
    label: "PEZILLA-LA-RIVIERE",
    value: "66140",
    type: "common",
  },
  {
    label: "BAHO",
    value: "66012",
    type: "common",
  },
  {
    label: "SAINT-FELIU-D'AVALL",
    value: "66174",
    type: "common",
  },
  {
    label: "SAINT-FELIU-D'AMONT",
    value: "66173",
    type: "common",
  },
  {
    label: "MILLAS",
    value: "66108",
    type: "common",
  },
  {
    label: "CORNEILLA-LA-RIVIERE",
    value: "66058",
    type: "common",
  },
  {
    label: "NEFIACH",
    value: "66121",
    type: "common",
  },
  {
    label: "ILLE-SUR-TET",
    value: "66088",
    type: "common",
  },
  {
    label: "BELESTA",
    value: "66019",
    type: "common",
  },
  {
    label: "MONTALBA-LE-CHATEAU",
    value: "66111",
    type: "common",
  },
  {
    label: "CARAMANY",
    value: "66039",
    type: "common",
  },
  {
    label: "TREVILLACH",
    value: "66215",
    type: "common",
  },
  {
    label: "TARERACH",
    value: "66201",
    type: "common",
  },
  {
    label: "CAMPOUSSY",
    value: "66035",
    type: "common",
  },
  {
    label: "SOURNIA",
    value: "66198",
    type: "common",
  },
  {
    label: "RABOUILLET",
    value: "66156",
    type: "common",
  },
  {
    label: "MOSSET",
    value: "66119",
    type: "common",
  },
  {
    label: "COUNOZOULS",
    value: "11104",
    type: "common",
  },
  {
    label: "ESCOULOUBRE",
    value: "11127",
    type: "common",
  },
  {
    label: "LE BOUSQUET",
    value: "11047",
    type: "common",
  },
  {
    label: "CARCANIERES",
    value: "09078",
    type: "common",
  },
  {
    label: "QUERIGUT",
    value: "09239",
    type: "common",
  },
  {
    label: "LE PUCH",
    value: "09237",
    type: "common",
  },
  {
    label: "LE PLA",
    value: "09230",
    type: "common",
  },
  {
    label: "MIJANES",
    value: "09193",
    type: "common",
  },
  {
    label: "ARTIGUES",
    value: "09020",
    type: "common",
  },
  {
    label: "ORGEIX",
    value: "09218",
    type: "common",
  },
  {
    label: "ASCOU",
    value: "09023",
    type: "common",
  },
  {
    label: "AX-LES-THERMES",
    value: "09032",
    type: "common",
  },
  {
    label: "SAVIGNAC-LES-ORMEAUX",
    value: "09283",
    type: "common",
  },
  {
    label: "PERLES-ET-CASTELET",
    value: "09228",
    type: "common",
  },
  {
    label: "LUZENAC",
    value: "09176",
    type: "common",
  },
  {
    label: "GESTIES",
    value: "09134",
    type: "common",
  },
  {
    label: "SIGUER",
    value: "09295",
    type: "common",
  },
  {
    label: "LERCOUL",
    value: "09162",
    type: "common",
  },
  {
    label: "AUZAT",
    value: "09030",
    type: "common",
  },
  {
    label: "SANTA-LUCIA-DI-MORIANI",
    value: "2B307",
    type: "common",
  },
  {
    label: "VELONE-ORNETO",
    value: "2B340",
    type: "common",
  },
  {
    label: "TALASANI",
    value: "2B319",
    type: "common",
  },
  {
    label: "PRUNO",
    value: "2B252",
    type: "common",
  },
  {
    label: "POGGIO-MEZZANA",
    value: "2B242",
    type: "common",
  },
  {
    label: "PERO-CASEVECCHIE",
    value: "2B210",
    type: "common",
  },
  {
    label: "VERDESE",
    value: "2B344",
    type: "common",
  },
  {
    label: "SAN-GAVINO-D'AMPUGNANI",
    value: "2B299",
    type: "common",
  },
  {
    label: "SAN-DAMIANO",
    value: "2B297",
    type: "common",
  },
  {
    label: "STAZZONA",
    value: "2B291",
    type: "common",
  },
  {
    label: "SCATA",
    value: "2B273",
    type: "common",
  },
  {
    label: "POLVEROSO",
    value: "2B243",
    type: "common",
  },
  {
    label: "PIEDICROCE",
    value: "2B219",
    type: "common",
  },
  {
    label: "PIAZZOLE",
    value: "2B217",
    type: "common",
  },
  {
    label: "MONACIA-D'OREZZA",
    value: "2B164",
    type: "common",
  },
  {
    label: "FICAJA",
    value: "2B113",
    type: "common",
  },
  {
    label: "CROCE",
    value: "2B101",
    type: "common",
  },
  {
    label: "QUERCITELLO",
    value: "2B255",
    type: "common",
  },
  {
    label: "LA PORTA",
    value: "2B246",
    type: "common",
  },
  {
    label: "POGGIO-MARINACCIO",
    value: "2B241",
    type: "common",
  },
  {
    label: "NOCARIO",
    value: "2B176",
    type: "common",
  },
  {
    label: "MOROSAGLIA",
    value: "2B169",
    type: "common",
  },
  {
    label: "GAVIGNANO",
    value: "2B122",
    type: "common",
  },
  {
    label: "CAMPANA",
    value: "2B052",
    type: "common",
  },
  {
    label: "SALICETO",
    value: "2B267",
    type: "common",
  },
  {
    label: "CASTINETA",
    value: "2B082",
    type: "common",
  },
  {
    label: "AITI",
    value: "2B003",
    type: "common",
  },
  {
    label: "PRATO-DI-GIOVELLINA",
    value: "2B248",
    type: "common",
  },
  {
    label: "POPOLASCA",
    value: "2B244",
    type: "common",
  },
  {
    label: "PIEDIGRIGGIO",
    value: "2B220",
    type: "common",
  },
  {
    label: "CASTIGLIONE",
    value: "2B081",
    type: "common",
  },
  {
    label: "ASCO",
    value: "2B023",
    type: "common",
  },
  {
    label: "CALENZANA",
    value: "2B049",
    type: "common",
  },
  {
    label: "SAINT-CYPRIEN",
    value: "66171",
    type: "common",
  },
  {
    label: "SALEILLES",
    value: "66189",
    type: "common",
  },
  {
    label: "ALENYA",
    value: "66002",
    type: "common",
  },
  {
    label: "SAINT-NAZAIRE",
    value: "66186",
    type: "common",
  },
  {
    label: "THEZA",
    value: "66208",
    type: "common",
  },
  {
    label: "MONTESCOT",
    value: "66114",
    type: "common",
  },
  {
    label: "CORNEILLA-DEL-VERCOL",
    value: "66059",
    type: "common",
  },
  {
    label: "VILLENEUVE-DE-LA-RAHO",
    value: "66227",
    type: "common",
  },
  {
    label: "POLLESTRES",
    value: "66144",
    type: "common",
  },
  {
    label: "TOULOUGES",
    value: "66213",
    type: "common",
  },
  {
    label: "THUIR",
    value: "66210",
    type: "common",
  },
  {
    label: "PONTEILLA",
    value: "66145",
    type: "common",
  },
  {
    label: "LLUPIA",
    value: "66101",
    type: "common",
  },
  {
    label: "CANOHES",
    value: "66038",
    type: "common",
  },
  {
    label: "SAINTE-COLOMBE-DE-LA-COMMANDERIE",
    value: "66170",
    type: "common",
  },
  {
    label: "CORBERE-LES-CABANES",
    value: "66056",
    type: "common",
  },
  {
    label: "CAMELAS",
    value: "66033",
    type: "common",
  },
  {
    label: "SAINT-MICHEL-DE-LLOTES",
    value: "66185",
    type: "common",
  },
  {
    label: "CORBERE",
    value: "66055",
    type: "common",
  },
  {
    label: "CASEFABRE",
    value: "66040",
    type: "common",
  },
  {
    label: "VINCA",
    value: "66230",
    type: "common",
  },
  {
    label: "RODES",
    value: "66165",
    type: "common",
  },
  {
    label: "RIGARDA",
    value: "66162",
    type: "common",
  },
  {
    label: "JOCH",
    value: "66089",
    type: "common",
  },
  {
    label: "BOULETERNERE",
    value: "66023",
    type: "common",
  },
  {
    label: "LOS MASOS",
    value: "66104",
    type: "common",
  },
  {
    label: "MARQUIXANES",
    value: "66103",
    type: "common",
  },
  {
    label: "EUS",
    value: "66074",
    type: "common",
  },
  {
    label: "ESPIRA-DE-CONFLENT",
    value: "66070",
    type: "common",
  },
  {
    label: "ARBOUSSOLS",
    value: "66007",
    type: "common",
  },
  {
    label: "MOLITG-LES-BAINS",
    value: "66109",
    type: "common",
  },
  {
    label: "CATLLAR",
    value: "66045",
    type: "common",
  },
  {
    label: "CAMPOME",
    value: "66034",
    type: "common",
  },
  {
    label: "URBANYA",
    value: "66219",
    type: "common",
  },
  {
    label: "NOHEDES",
    value: "66122",
    type: "common",
  },
  {
    label: "SANSA",
    value: "66191",
    type: "common",
  },
  {
    label: "REAL",
    value: "66159",
    type: "common",
  },
  {
    label: "PUYVALADOR",
    value: "66154",
    type: "common",
  },
  {
    label: "FONTRABIOUSE",
    value: "66081",
    type: "common",
  },
  {
    label: "ORLU",
    value: "09220",
    type: "common",
  },
  {
    label: "MERENS-LES-VALS",
    value: "09189",
    type: "common",
  },
  {
    label: "ASTON",
    value: "09024",
    type: "common",
  },
  {
    label: "VALLE-DI-CAMPOLORO",
    value: "2B335",
    type: "common",
  },
  {
    label: "SANTA-MARIA-POGGIO",
    value: "2B311",
    type: "common",
  },
  {
    label: "CERVIONE",
    value: "2B087",
    type: "common",
  },
  {
    label: "VALLE-D'ALESANI",
    value: "2B334",
    type: "common",
  },
  {
    label: "SANTA-REPARATA-DI-MORIANI",
    value: "2B317",
    type: "common",
  },
  {
    label: "SAN-NICOLAO",
    value: "2B313",
    type: "common",
  },
  {
    label: "SAN-GIOVANNI-DI-MORIANI",
    value: "2B302",
    type: "common",
  },
  {
    label: "VALLE-D'OREZZA",
    value: "2B338",
    type: "common",
  },
  {
    label: "TARRANO",
    value: "2B321",
    type: "common",
  },
  {
    label: "RAPAGGIO",
    value: "2B256",
    type: "common",
  },
  {
    label: "PIOBETTA",
    value: "2B234",
    type: "common",
  },
  {
    label: "PIETRICAGGIO",
    value: "2B227",
    type: "common",
  },
  {
    label: "PIAZZALI",
    value: "2B216",
    type: "common",
  },
  {
    label: "PARATA",
    value: "2B202",
    type: "common",
  },
  {
    label: "FELCE",
    value: "2B111",
    type: "common",
  },
  {
    label: "CARPINETO",
    value: "2B067",
    type: "common",
  },
  {
    label: "CARCHETO-BRUSTICO",
    value: "2B063",
    type: "common",
  },
  {
    label: "SAN-LORENZO",
    value: "2B304",
    type: "common",
  },
  {
    label: "PIE-D'OREZZA",
    value: "2B222",
    type: "common",
  },
  {
    label: "PIEDIPARTINO",
    value: "2B221",
    type: "common",
  },
  {
    label: "CARTICASI",
    value: "2B068",
    type: "common",
  },
  {
    label: "CAMBIA",
    value: "2B051",
    type: "common",
  },
  {
    label: "BUSTANICO",
    value: "2B045",
    type: "common",
  },
  {
    label: "RUSIO",
    value: "2B264",
    type: "common",
  },
  {
    label: "OMESSA",
    value: "2B193",
    type: "common",
  },
  {
    label: "LANO",
    value: "2B137",
    type: "common",
  },
  {
    label: "ERONE",
    value: "2B106",
    type: "common",
  },
  {
    label: "TRALONCA",
    value: "2B329",
    type: "common",
  },
  {
    label: "SOVERIA",
    value: "2B289",
    type: "common",
  },
  {
    label: "CASTIRLA",
    value: "2B083",
    type: "common",
  },
  {
    label: "CORSCIA",
    value: "2B095",
    type: "common",
  },
  {
    label: "LOZZI",
    value: "2B147",
    type: "common",
  },
  {
    label: "MANSO",
    value: "2B153",
    type: "common",
  },
  {
    label: "GALERIA",
    value: "2B121",
    type: "common",
  },
  {
    label: "LATOUR-BAS-ELNE",
    value: "66094",
    type: "common",
  },
  {
    label: "SAINT-ANDRE",
    value: "66168",
    type: "common",
  },
  {
    label: "PALAU-DEL-VIDRE",
    value: "66133",
    type: "common",
  },
  {
    label: "ELNE",
    value: "66065",
    type: "common",
  },
  {
    label: "VILLEMOLAQUE",
    value: "66226",
    type: "common",
  },
  {
    label: "SAINT-JEAN-LASSEILLE",
    value: "66177",
    type: "common",
  },
  {
    label: "ORTAFFA",
    value: "66129",
    type: "common",
  },
  {
    label: "BROUILLA",
    value: "66026",
    type: "common",
  },
  {
    label: "BANYULS-DELS-ASPRES",
    value: "66015",
    type: "common",
  },
  {
    label: "BAGES",
    value: "66011",
    type: "common",
  },
  {
    label: "TROUILLAS",
    value: "66217",
    type: "common",
  },
  {
    label: "TRESSERRE",
    value: "66214",
    type: "common",
  },
  {
    label: "TORDERES",
    value: "66211",
    type: "common",
  },
  {
    label: "TERRATS",
    value: "66207",
    type: "common",
  },
  {
    label: "PASSA",
    value: "66134",
    type: "common",
  },
  {
    label: "FOURQUES",
    value: "66084",
    type: "common",
  },
  {
    label: "MONTAURIOL",
    value: "66112",
    type: "common",
  },
  {
    label: "CASTELNOU",
    value: "66044",
    type: "common",
  },
  {
    label: "PRUNET-ET-BELPUIG",
    value: "66153",
    type: "common",
  },
  {
    label: "CAIXAS",
    value: "66029",
    type: "common",
  },
  {
    label: "GLORIANES",
    value: "66086",
    type: "common",
  },
  {
    label: "FINESTRET",
    value: "66079",
    type: "common",
  },
  {
    label: "BOULE-D'AMONT",
    value: "66022",
    type: "common",
  },
  {
    label: "BAILLESTAVY",
    value: "66013",
    type: "common",
  },
  {
    label: "TAURINYA",
    value: "66204",
    type: "common",
  },
  {
    label: "ESTOHER",
    value: "66073",
    type: "common",
  },
  {
    label: "CLARA-VILLERACH",
    value: "66051",
    type: "common",
  },
  {
    label: "RIA-SIRACH",
    value: "66161",
    type: "common",
  },
  {
    label: "PRADES",
    value: "66149",
    type: "common",
  },
  {
    label: "FILLOLS",
    value: "66078",
    type: "common",
  },
  {
    label: "CORNEILLA-DE-CONFLENT",
    value: "66057",
    type: "common",
  },
  {
    label: "CODALET",
    value: "66052",
    type: "common",
  },
  {
    label: "VILLEFRANCHE-DE-CONFLENT",
    value: "66223",
    type: "common",
  },
  {
    label: "SERDINYA",
    value: "66193",
    type: "common",
  },
  {
    label: "JUJOLS",
    value: "66090",
    type: "common",
  },
  {
    label: "FUILLA",
    value: "66085",
    type: "common",
  },
  {
    label: "CONAT",
    value: "66054",
    type: "common",
  },
  {
    label: "OREILLA",
    value: "66128",
    type: "common",
  },
  {
    label: "OLETTE",
    value: "66125",
    type: "common",
  },
  {
    label: "RAILLEU",
    value: "66157",
    type: "common",
  },
  {
    label: "CAUDIES-DE-CONFLENT",
    value: "66047",
    type: "common",
  },
  {
    label: "MATEMALE",
    value: "66105",
    type: "common",
  },
  {
    label: "FORMIGUERES",
    value: "66082",
    type: "common",
  },
  {
    label: "LES ANGLES",
    value: "66004",
    type: "common",
  },
  {
    label: "ANGOUSTRINE-VILLENEUVE-DES-ESCALDES",
    value: "66005",
    type: "common",
  },
  {
    label: "PORTE-PUYMORENS",
    value: "66147",
    type: "common",
  },
  {
    label: "L'HOSPITALET-PRES-L'ANDORRE",
    value: "09139",
    type: "common",
  },
  {
    label: "SAN-GIULIANO",
    value: "2B303",
    type: "common",
  },
  {
    label: "CANALE-DI-VERDE",
    value: "2B057",
    type: "common",
  },
  {
    label: "SANT'ANDREA-DI-COTONE",
    value: "2B293",
    type: "common",
  },
  {
    label: "PIETRA-DI-VERDE",
    value: "2B225",
    type: "common",
  },
  {
    label: "ORTALE",
    value: "2B194",
    type: "common",
  },
  {
    label: "CHIATRA",
    value: "2B088",
    type: "common",
  },
  {
    label: "CAMPI",
    value: "2B053",
    type: "common",
  },
  {
    label: "ZUANI",
    value: "2B364",
    type: "common",
  },
  {
    label: "PIANELLO",
    value: "2B213",
    type: "common",
  },
  {
    label: "PERELLI",
    value: "2B208",
    type: "common",
  },
  {
    label: "NOVALE",
    value: "2B179",
    type: "common",
  },
  {
    label: "MOITA",
    value: "2B161",
    type: "common",
  },
  {
    label: "MATRA",
    value: "2B155",
    type: "common",
  },
  {
    label: "SANT'ANDREA-DI-BOZIO",
    value: "2B292",
    type: "common",
  },
  {
    label: "SERMANO",
    value: "2B275",
    type: "common",
  },
  {
    label: "MAZZOLA",
    value: "2B157",
    type: "common",
  },
  {
    label: "ALZI",
    value: "2B013",
    type: "common",
  },
  {
    label: "ALANDO",
    value: "2B005",
    type: "common",
  },
  {
    label: "SANTA-LUCIA-DI-MERCURIO",
    value: "2B306",
    type: "common",
  },
  {
    label: "FAVALELLO",
    value: "2B110",
    type: "common",
  },
  {
    label: "CASTELLARE-DI-MERCURIO",
    value: "2B078",
    type: "common",
  },
  {
    label: "CORTE",
    value: "2B096",
    type: "common",
  },
  {
    label: "CALACUCCIA",
    value: "2B047",
    type: "common",
  },
  {
    label: "CASAMACCIOLI",
    value: "2B073",
    type: "common",
  },
  {
    label: "ALBERTACCE",
    value: "2B007",
    type: "common",
  },
  {
    label: "SERRIERA",
    value: "2A279",
    type: "common",
  },
  {
    label: "PARTINELLO",
    value: "2A203",
    type: "common",
  },
  {
    label: "OSANI",
    value: "2A197",
    type: "common",
  },
  {
    label: "PORT-VENDRES",
    value: "66148",
    type: "common",
  },
  {
    label: "COLLIOURE",
    value: "66053",
    type: "common",
  },
  {
    label: "ARGELES-SUR-MER",
    value: "66008",
    type: "common",
  },
  {
    label: "SAINT-GENIS-DES-FONTAINES",
    value: "66175",
    type: "common",
  },
  {
    label: "LAROQUE-DES-ALBERES",
    value: "66093",
    type: "common",
  },
  {
    label: "MONTESQUIEU-DES-ALBERES",
    value: "66115",
    type: "common",
  },
  {
    label: "VILLELONGUE-DELS-MONTS",
    value: "66225",
    type: "common",
  },
  {
    label: "VIVES",
    value: "66233",
    type: "common",
  },
  {
    label: "SAINT-JEAN-PLA-DE-CORTS",
    value: "66178",
    type: "common",
  },
  {
    label: "LE BOULOU",
    value: "66024",
    type: "common",
  },
  {
    label: "TAILLET",
    value: "66199",
    type: "common",
  },
  {
    label: "OMS",
    value: "66126",
    type: "common",
  },
  {
    label: "LLAURO",
    value: "66099",
    type: "common",
  },
  {
    label: "TAULIS",
    value: "66203",
    type: "common",
  },
  {
    label: "SAINT-MARSAL",
    value: "66183",
    type: "common",
  },
  {
    label: "CALMEILLES",
    value: "66032",
    type: "common",
  },
  {
    label: "LA BASTIDE",
    value: "66018",
    type: "common",
  },
  {
    label: "VALMANYA",
    value: "66221",
    type: "common",
  },
  {
    label: "VERNET-LES-BAINS",
    value: "66222",
    type: "common",
  },
  {
    label: "SAHORRE",
    value: "66166",
    type: "common",
  },
  {
    label: "CASTEIL",
    value: "66043",
    type: "common",
  },
  {
    label: "SOUANYAS",
    value: "66197",
    type: "common",
  },
  {
    label: "NYER",
    value: "66123",
    type: "common",
  },
  {
    label: "ESCARO",
    value: "66068",
    type: "common",
  },
  {
    label: "THUES-ENTRE-VALLS",
    value: "66209",
    type: "common",
  },
  {
    label: "CANAVEILLES",
    value: "66036",
    type: "common",
  },
  {
    label: "SAUTO",
    value: "66192",
    type: "common",
  },
  {
    label: "AYGUATEBIA-TALAU",
    value: "66010",
    type: "common",
  },
  {
    label: "MONT-LOUIS",
    value: "66117",
    type: "common",
  },
  {
    label: "LA LLAGONNE",
    value: "66098",
    type: "common",
  },
  {
    label: "LA CABANASSE",
    value: "66027",
    type: "common",
  },
  {
    label: "BOLQUERE",
    value: "66020",
    type: "common",
  },
  {
    label: "TARGASSONNE",
    value: "66202",
    type: "common",
  },
  {
    label: "FONT-ROMEU-ODEILLO-VIA",
    value: "66124",
    type: "common",
  },
  {
    label: "EGAT",
    value: "66064",
    type: "common",
  },
  {
    label: "ENVEITG",
    value: "66066",
    type: "common",
  },
  {
    label: "DORRES",
    value: "66062",
    type: "common",
  },
  {
    label: "PORTA",
    value: "66146",
    type: "common",
  },
  {
    label: "LINGUIZZETTA",
    value: "2B143",
    type: "common",
  },
  {
    label: "TOX",
    value: "2B328",
    type: "common",
  },
  {
    label: "ZALANA",
    value: "2B356",
    type: "common",
  },
  {
    label: "PIETRASERENA",
    value: "2B226",
    type: "common",
  },
  {
    label: "AMPRIANI",
    value: "2B015",
    type: "common",
  },
  {
    label: "PIEDICORTE-DI-GAGGIO",
    value: "2B218",
    type: "common",
  },
  {
    label: "FOCICCHIA",
    value: "2B116",
    type: "common",
  },
  {
    label: "ERBAJOLO",
    value: "2B105",
    type: "common",
  },
  {
    label: "ALTIANI",
    value: "2B012",
    type: "common",
  },
  {
    label: "RIVENTOSA",
    value: "2B260",
    type: "common",
  },
  {
    label: "POGGIO-DI-VENACO",
    value: "2B238",
    type: "common",
  },
  {
    label: "SANTO-PIETRO-DI-VENACO",
    value: "2B315",
    type: "common",
  },
  {
    label: "CASANOVA",
    value: "2B074",
    type: "common",
  },
  {
    label: "CRISTINACCE",
    value: "2A100",
    type: "common",
  },
  {
    label: "EVISA",
    value: "2A108",
    type: "common",
  },
  {
    label: "OTA",
    value: "2A198",
    type: "common",
  },
  {
    label: "CERBERE",
    value: "66048",
    type: "common",
  },
  {
    label: "BANYULS-SUR-MER",
    value: "66016",
    type: "common",
  },
  {
    label: "SOREDE",
    value: "66196",
    type: "common",
  },
  {
    label: "LE PERTHUS",
    value: "66137",
    type: "common",
  },
  {
    label: "LES CLUSES",
    value: "66063",
    type: "common",
  },
  {
    label: "L'ALBERE",
    value: "66001",
    type: "common",
  },
  {
    label: "MAUREILLAS-LAS-ILLAS",
    value: "66106",
    type: "common",
  },
  {
    label: "CERET",
    value: "66049",
    type: "common",
  },
  {
    label: "REYNES",
    value: "66160",
    type: "common",
  },
  {
    label: "MONTBOLO",
    value: "66113",
    type: "common",
  },
  {
    label: "ARLES-SUR-TECH",
    value: "66009",
    type: "common",
  },
  {
    label: "MONTFERRER",
    value: "66116",
    type: "common",
  },
  {
    label: "CORSAVY",
    value: "66060",
    type: "common",
  },
  {
    label: "LE TECH",
    value: "66206",
    type: "common",
  },
  {
    label: "PY",
    value: "66155",
    type: "common",
  },
  {
    label: "MANTET",
    value: "66102",
    type: "common",
  },
  {
    label: "FONTPEDROUSE",
    value: "66080",
    type: "common",
  },
  {
    label: "PLANES",
    value: "66142",
    type: "common",
  },
  {
    label: "SAINT-PIERRE-DELS-FORCATS",
    value: "66188",
    type: "common",
  },
  {
    label: "LLO",
    value: "66100",
    type: "common",
  },
  {
    label: "EYNE",
    value: "66075",
    type: "common",
  },
  {
    label: "SAILLAGOUSE",
    value: "66167",
    type: "common",
  },
  {
    label: "ESTAVAR",
    value: "66072",
    type: "common",
  },
  {
    label: "UR",
    value: "66218",
    type: "common",
  },
  {
    label: "BOURG-MADAME",
    value: "66025",
    type: "common",
  },
  {
    label: "LATOUR-DE-CAROL",
    value: "66095",
    type: "common",
  },
  {
    label: "TALLONE",
    value: "2B320",
    type: "common",
  },
  {
    label: "PANCHERACCIA",
    value: "2B201",
    type: "common",
  },
  {
    label: "GIUNCAGGIO",
    value: "2B126",
    type: "common",
  },
  {
    label: "ANTISANTI",
    value: "2B016",
    type: "common",
  },
  {
    label: "VEZZANI",
    value: "2B347",
    type: "common",
  },
  {
    label: "ROSPIGLIANI",
    value: "2B263",
    type: "common",
  },
  {
    label: "NOCETA",
    value: "2B177",
    type: "common",
  },
  {
    label: "MURACCIOLE",
    value: "2B171",
    type: "common",
  },
  {
    label: "VENACO",
    value: "2B341",
    type: "common",
  },
  {
    label: "ORTO",
    value: "2A196",
    type: "common",
  },
  {
    label: "GUAGNO",
    value: "2A131",
    type: "common",
  },
  {
    label: "SOCCIA",
    value: "2A282",
    type: "common",
  },
  {
    label: "LETIA",
    value: "2A141",
    type: "common",
  },
  {
    label: "RENNO",
    value: "2A258",
    type: "common",
  },
  {
    label: "BALOGNA",
    value: "2A028",
    type: "common",
  },
  {
    label: "PIANA",
    value: "2A212",
    type: "common",
  },
  {
    label: "MARIGNANA",
    value: "2A154",
    type: "common",
  },
  {
    label: "AMELIE-LES-BAINS-PALALDA",
    value: "66003",
    type: "common",
  },
  {
    label: "SAINT-LAURENT-DE-CERDANS",
    value: "66179",
    type: "common",
  },
  {
    label: "PRATS-DE-MOLLO-LA-PRESTE",
    value: "66150",
    type: "common",
  },
  {
    label: "ERR",
    value: "66067",
    type: "common",
  },
  {
    label: "OSSEJA",
    value: "66130",
    type: "common",
  },
  {
    label: "VALCEBOLLERE",
    value: "66220",
    type: "common",
  },
  {
    label: "SAINTE-LEOCADIE",
    value: "66181",
    type: "common",
  },
  {
    label: "PALAU-DE-CERDAGNE",
    value: "66132",
    type: "common",
  },
  {
    label: "NAHUJA",
    value: "66120",
    type: "common",
  },
  {
    label: "ALERIA",
    value: "2B009",
    type: "common",
  },
  {
    label: "AGHIONE",
    value: "2B002",
    type: "common",
  },
  {
    label: "CASEVECCHIE",
    value: "2B075",
    type: "common",
  },
  {
    label: "PIETROSO",
    value: "2B229",
    type: "common",
  },
  {
    label: "GHISONI",
    value: "2B124",
    type: "common",
  },
  {
    label: "VIVARIO",
    value: "2B354",
    type: "common",
  },
  {
    label: "PASTRICCIOLA",
    value: "2A204",
    type: "common",
  },
  {
    label: "REZZA",
    value: "2A259",
    type: "common",
  },
  {
    label: "ROSAZIA",
    value: "2A262",
    type: "common",
  },
  {
    label: "POGGIOLO",
    value: "2A240",
    type: "common",
  },
  {
    label: "MURZO",
    value: "2A174",
    type: "common",
  },
  {
    label: "ARBORI",
    value: "2A019",
    type: "common",
  },
  {
    label: "VICO",
    value: "2A348",
    type: "common",
  },
  {
    label: "CARGESE",
    value: "2A065",
    type: "common",
  },
  {
    label: "COUSTOUGES",
    value: "66061",
    type: "common",
  },
  {
    label: "SERRALONGUE",
    value: "66194",
    type: "common",
  },
  {
    label: "LAMANERE",
    value: "66091",
    type: "common",
  },
  {
    label: "GHISONACCIA",
    value: "2B123",
    type: "common",
  },
  {
    label: "LUGO-DI-NAZZA",
    value: "2B149",
    type: "common",
  },
  {
    label: "POGGIO-DI-NAZZA",
    value: "2B236",
    type: "common",
  },
  {
    label: "TAVERA",
    value: "2A324",
    type: "common",
  },
  {
    label: "BOCOGNANO",
    value: "2A040",
    type: "common",
  },
  {
    label: "VERO",
    value: "2A345",
    type: "common",
  },
  {
    label: "UCCIANI",
    value: "2A330",
    type: "common",
  },
  {
    label: "AZZANA",
    value: "2A027",
    type: "common",
  },
  {
    label: "SALICE",
    value: "2A266",
    type: "common",
  },
  {
    label: "LOPIGNA",
    value: "2A144",
    type: "common",
  },
  {
    label: "SARI-D'ORCINO",
    value: "2A270",
    type: "common",
  },
  {
    label: "ARRO",
    value: "2A022",
    type: "common",
  },
  {
    label: "AMBIEGNA",
    value: "2A014",
    type: "common",
  },
  {
    label: "COGGIA",
    value: "2A090",
    type: "common",
  },
  {
    label: "CASAGLIONE",
    value: "2A070",
    type: "common",
  },
  {
    label: "SERRA-DI-FIUMORBO",
    value: "2B277",
    type: "common",
  },
  {
    label: "PRUNELLI-DI-FIUMORBO",
    value: "2B251",
    type: "common",
  },
  {
    label: "ISOLACCIO-DI-FIUMORBO",
    value: "2B135",
    type: "common",
  },
  {
    label: "PALNECA",
    value: "2A200",
    type: "common",
  },
  {
    label: "PERI",
    value: "2A209",
    type: "common",
  },
  {
    label: "CARBUCCIA",
    value: "2A062",
    type: "common",
  },
  {
    label: "TAVACO",
    value: "2A323",
    type: "common",
  },
  {
    label: "VALLE-DI-MEZZANA",
    value: "2A336",
    type: "common",
  },
  {
    label: "SANT'ANDREA-D'ORCINO",
    value: "2A295",
    type: "common",
  },
  {
    label: "SARROLA-CARCOPINO",
    value: "2A271",
    type: "common",
  },
  {
    label: "CANNELLE",
    value: "2A060",
    type: "common",
  },
  {
    label: "CALCATOGGIO",
    value: "2A048",
    type: "common",
  },
  {
    label: "VENTISERI",
    value: "2B342",
    type: "common",
  },
  {
    label: "CHISA",
    value: "2B366",
    type: "common",
  },
  {
    label: "SAN-GAVINO-DI-FIUMORBO",
    value: "2B365",
    type: "common",
  },
  {
    label: "COZZANO",
    value: "2A099",
    type: "common",
  },
  {
    label: "TASSO",
    value: "2A322",
    type: "common",
  },
  {
    label: "SAMPOLO",
    value: "2A268",
    type: "common",
  },
  {
    label: "CIAMANNACCE",
    value: "2A089",
    type: "common",
  },
  {
    label: "BASTELICA",
    value: "2A031",
    type: "common",
  },
  {
    label: "TOLLA",
    value: "2A326",
    type: "common",
  },
  {
    label: "OCANA",
    value: "2A181",
    type: "common",
  },
  {
    label: "CUTTOLI-CORTICCHIATO",
    value: "2A103",
    type: "common",
  },
  {
    label: "AFA",
    value: "2A001",
    type: "common",
  },
  {
    label: "VILLANOVA",
    value: "2A351",
    type: "common",
  },
  {
    label: "APPIETTO",
    value: "2A017",
    type: "common",
  },
  {
    label: "ALATA",
    value: "2A006",
    type: "common",
  },
  {
    label: "SOLARO",
    value: "2B283",
    type: "common",
  },
  {
    label: "ZICAVO",
    value: "2A359",
    type: "common",
  },
  {
    label: "GUITERA-LES-BAINS",
    value: "2A133",
    type: "common",
  },
  {
    label: "CORRANO",
    value: "2A094",
    type: "common",
  },
  {
    label: "ZEVACO",
    value: "2A358",
    type: "common",
  },
  {
    label: "QUASQUARA",
    value: "2A253",
    type: "common",
  },
  {
    label: "FRASSETO",
    value: "2A119",
    type: "common",
  },
  {
    label: "CAMPO",
    value: "2A056",
    type: "common",
  },
  {
    label: "SANTA-MARIA-SICHE",
    value: "2A312",
    type: "common",
  },
  {
    label: "CAURO",
    value: "2A085",
    type: "common",
  },
  {
    label: "GROSSETO-PRUGNA",
    value: "2A130",
    type: "common",
  },
  {
    label: "ECCICA-SUARELLA",
    value: "2A104",
    type: "common",
  },
  {
    label: "BASTELICACCIA",
    value: "2A032",
    type: "common",
  },
  {
    label: "AJACCIO",
    value: "2A004",
    type: "common",
  },
  {
    label: "SARI-SOLENZARA",
    value: "2A269",
    type: "common",
  },
  {
    label: "OLIVESE",
    value: "2A186",
    type: "common",
  },
  {
    label: "AULLENE",
    value: "2A024",
    type: "common",
  },
  {
    label: "ZIGLIARA",
    value: "2A360",
    type: "common",
  },
  {
    label: "FORCIOLO",
    value: "2A117",
    type: "common",
  },
  {
    label: "AZILONE-AMPAZA",
    value: "2A026",
    type: "common",
  },
  {
    label: "ARGIUSTA-MORICCIO",
    value: "2A021",
    type: "common",
  },
  {
    label: "URBALACONE",
    value: "2A331",
    type: "common",
  },
  {
    label: "GUARGUALE",
    value: "2A132",
    type: "common",
  },
  {
    label: "CARDO-TORGIA",
    value: "2A064",
    type: "common",
  },
  {
    label: "PIETROSELLA",
    value: "2A228",
    type: "common",
  },
  {
    label: "ALBITRECCIA",
    value: "2A008",
    type: "common",
  },
  {
    label: "CONCA",
    value: "2A092",
    type: "common",
  },
  {
    label: "ZONZA",
    value: "2A362",
    type: "common",
  },
  {
    label: "QUENZA",
    value: "2A254",
    type: "common",
  },
  {
    label: "SORBOLLANO",
    value: "2A285",
    type: "common",
  },
  {
    label: "SERRA-DI-SCOPAMENE",
    value: "2A278",
    type: "common",
  },
  {
    label: "PETRETO-BICCHISANO",
    value: "2A211",
    type: "common",
  },
  {
    label: "MOCA-CROCE",
    value: "2A160",
    type: "common",
  },
  {
    label: "PILA-CANALE",
    value: "2A232",
    type: "common",
  },
  {
    label: "CASALABRIVA",
    value: "2A071",
    type: "common",
  },
  {
    label: "COGNOCOLI-MONTICCHI",
    value: "2A091",
    type: "common",
  },
  {
    label: "COTI-CHIAVARI",
    value: "2A098",
    type: "common",
  },
  {
    label: "ZOZA",
    value: "2A363",
    type: "common",
  },
  {
    label: "ZERUBIA",
    value: "2A357",
    type: "common",
  },
  {
    label: "LORETO-DI-TALLANO",
    value: "2A146",
    type: "common",
  },
  {
    label: "CARGIACA",
    value: "2A066",
    type: "common",
  },
  {
    label: "ALTAGENE",
    value: "2A011",
    type: "common",
  },
  {
    label: "SANTA-MARIA-FIGANIELLA",
    value: "2A310",
    type: "common",
  },
  {
    label: "SOLLACARO",
    value: "2A284",
    type: "common",
  },
  {
    label: "OLMETO",
    value: "2A189",
    type: "common",
  },
  {
    label: "SERRA-DI-FERRO",
    value: "2A276",
    type: "common",
  },
  {
    label: "LECCI",
    value: "2A139",
    type: "common",
  },
  {
    label: "SAN-GAVINO-DI-CARBINI",
    value: "2A300",
    type: "common",
  },
  {
    label: "LEVIE",
    value: "2A142",
    type: "common",
  },
  {
    label: "CARBINI",
    value: "2A061",
    type: "common",
  },
  {
    label: "SAINTE-LUCIE-DE-TALLANO",
    value: "2A308",
    type: "common",
  },
  {
    label: "OLMICCIA",
    value: "2A191",
    type: "common",
  },
  {
    label: "MELA",
    value: "2A158",
    type: "common",
  },
  {
    label: "GRANACE",
    value: "2A128",
    type: "common",
  },
  {
    label: "FOZZANO",
    value: "2A118",
    type: "common",
  },
  {
    label: "ARBELLARA",
    value: "2A018",
    type: "common",
  },
  {
    label: "VIGGIANELLO",
    value: "2A349",
    type: "common",
  },
  {
    label: "PROPRIANO",
    value: "2A249",
    type: "common",
  },
  {
    label: "PORTO-VECCHIO",
    value: "2A247",
    type: "common",
  },
  {
    label: "GIUNCHETO",
    value: "2A127",
    type: "common",
  },
  {
    label: "FOCE",
    value: "2A115",
    type: "common",
  },
  {
    label: "BILIA",
    value: "2A038",
    type: "common",
  },
  {
    label: "GROSSA",
    value: "2A129",
    type: "common",
  },
  {
    label: "BELVEDERE-CAMPOMORO",
    value: "2A035",
    type: "common",
  },
  {
    label: "SOTTA",
    value: "2A288",
    type: "common",
  },
  {
    label: "FIGARI",
    value: "2A114",
    type: "common",
  },
  {
    label: "MONACIA-D'AULLENE",
    value: "2A163",
    type: "common",
  },
  {
    label: "SARTENE",
    value: "2A272",
    type: "common",
  },
  {
    label: "BONIFACIO",
    value: "2A041",
    type: "common",
  },
  {
    label: "PIANOTTOLI-CALDARELLO",
    value: "2A215",
    type: "common",
  },
  {
    label: "VILLENAVE-PRES-BEARN",
    value: "65476",
    type: "common",
  },
  {
    label: "LUQUET",
    value: "65292",
    type: "common",
  },
  {
    label: "ESCAUNETS",
    value: "65160",
    type: "common",
  },
  {
    label: "SERON",
    value: "65422",
    type: "common",
  },
  {
    label: "GARDERES",
    value: "65185",
    type: "common",
  },
  {
    label: "CESNY-AUX-VIGNES",
    value: "14149",
    type: "common",
  },
  {
    label: "OUEZY",
    value: "14482",
    type: "common",
  },
  {
    label: "CUISLES",
    value: "51201",
    type: "common",
  },
  {
    label: "BOSSELSHAUSEN",
    value: "67057",
    type: "common",
  },
  {
    label: "VERQUIGNEUL",
    value: "62847",
    type: "common",
  },
  {
    label: "SAINT-SYMPHORIEN",
    value: "35317",
    type: "common",
  },
  {
    label: "LIEOUX",
    value: "31300",
    type: "common",
  },
  {
    label: "ROSOY",
    value: "89326",
    type: "common",
  },
  {
    label: "CHEZEAUX",
    value: "52124",
    type: "common",
  },
  {
    label: "LANEUVILLE-A-REMY",
    value: "52266",
    type: "common",
  },
  {
    label: "LAVILLENEUVE-AU-ROI",
    value: "52278",
    type: "common",
  },
  {
    label: "AVRECOURT",
    value: "52033",
    type: "common",
  },
  {
    label: "SAULXURES",
    value: "52465",
    type: "common",
  },
  {
    label: "BIHOREL",
    value: "76095",
    type: "common",
  },
  {
    label: "CULEY",
    value: "55138",
    type: "common",
  },
  {
    label: "ANSE-BERTRAND",
    value: "97102",
    type: "common",
  },
  {
    label: "PETIT-CANAL",
    value: "97119",
    type: "common",
  },
  {
    label: "LE GOSIER",
    value: "97113",
    type: "common",
  },
  {
    label: "SAINT-LOUIS",
    value: "97126",
    type: "common",
  },
  {
    label: "MORNE-A-L'EAU",
    value: "97116",
    type: "common",
  },
  {
    label: "SAINTE-ANNE",
    value: "97128",
    type: "common",
  },
  {
    label: "LA DESIRADE",
    value: "97110",
    type: "common",
  },
  {
    label: "LE MOULE",
    value: "97117",
    type: "common",
  },
  {
    label: "SAINT-FRANCOIS",
    value: "97125",
    type: "common",
  },
  {
    label: "DESHAIES",
    value: "97111",
    type: "common",
  },
  {
    label: "BAIE-MAHAULT",
    value: "97103",
    type: "common",
  },
  {
    label: "POINTE-NOIRE",
    value: "97121",
    type: "common",
  },
  {
    label: "PETIT-BOURG",
    value: "97118",
    type: "common",
  },
  {
    label: "VIEUX-HABITANTS",
    value: "97134",
    type: "common",
  },
  {
    label: "GOURBEYRE",
    value: "97109",
    type: "common",
  },
  {
    label: "BASSE-TERRE",
    value: "97105",
    type: "common",
  },
  {
    label: "POINTE-A-PITRE",
    value: "97120",
    type: "common",
  },
  {
    label: "GOYAVE",
    value: "97114",
    type: "common",
  },
  {
    label: "BOUILLANTE",
    value: "97106",
    type: "common",
  },
  {
    label: "CAPESTERRE-BELLE-EAU",
    value: "97107",
    type: "common",
  },
  {
    label: "SAINT-CLAUDE",
    value: "97124",
    type: "common",
  },
  {
    label: "BAILLIF",
    value: "97104",
    type: "common",
  },
  {
    label: "TROIS-RIVIERES",
    value: "97132",
    type: "common",
  },
  {
    label: "LES ABYMES",
    value: "97101",
    type: "common",
  },
  {
    label: "SAINTE-ROSE",
    value: "97129",
    type: "common",
  },
  {
    label: "LAMENTIN",
    value: "97115",
    type: "common",
  },
  {
    label: "PORT-LOUIS",
    value: "97122",
    type: "common",
  },
  {
    label: "CAPESTERRE-DE-MARIE-GALANTE",
    value: "97108",
    type: "common",
  },
  {
    label: "GRAND-BOURG",
    value: "97112",
    type: "common",
  },
  {
    label: "VIEUX-FORT",
    value: "97133",
    type: "common",
  },
  {
    label: "TERRE-DE-BAS",
    value: "97130",
    type: "common",
  },
  {
    label: "TERRE-DE-HAUT",
    value: "97131",
    type: "common",
  },
  {
    label: "LE MARIGOT",
    value: "97216",
    type: "common",
  },
  {
    label: "SAINTE-MARIE",
    value: "97228",
    type: "common",
  },
  {
    label: "LA TRINITE",
    value: "97230",
    type: "common",
  },
  {
    label: "GRAND'RIVIERE",
    value: "97211",
    type: "common",
  },
  {
    label: "MACOUBA",
    value: "97215",
    type: "common",
  },
  {
    label: "SAINT-PIERRE",
    value: "97225",
    type: "common",
  },
  {
    label: "L'AJOUPA-BOUILLON",
    value: "97201",
    type: "common",
  },
  {
    label: "BASSE-POINTE",
    value: "97203",
    type: "common",
  },
  {
    label: "LE MORNE-ROUGE",
    value: "97218",
    type: "common",
  },
  {
    label: "LE LORRAIN",
    value: "97214",
    type: "common",
  },
  {
    label: "LE PRECHEUR",
    value: "97219",
    type: "common",
  },
  {
    label: "LE ROBERT",
    value: "97222",
    type: "common",
  },
  {
    label: "FORT-DE-FRANCE",
    value: "97209",
    type: "common",
  },
  {
    label: "SAINT-JOSEPH",
    value: "97224",
    type: "common",
  },
  {
    label: "GROS-MORNE",
    value: "97212",
    type: "common",
  },
  {
    label: "LE LAMENTIN",
    value: "97213",
    type: "common",
  },
  {
    label: "BELLEFONTAINE",
    value: "97234",
    type: "common",
  },
  {
    label: "LE MORNE-VERT",
    value: "97233",
    type: "common",
  },
  {
    label: "FONDS-SAINT-DENIS",
    value: "97208",
    type: "common",
  },
  {
    label: "LE CARBET",
    value: "97204",
    type: "common",
  },
  {
    label: "SCHŒLCHER",
    value: "97229",
    type: "common",
  },
  {
    label: "CASE-PILOTE",
    value: "97205",
    type: "common",
  },
  {
    label: "SAINT-ESPRIT",
    value: "97223",
    type: "common",
  },
  {
    label: "LE VAUCLIN",
    value: "97232",
    type: "common",
  },
  {
    label: "LE FRANCOIS",
    value: "97210",
    type: "common",
  },
  {
    label: "RIVIERE-PILOTE",
    value: "97220",
    type: "common",
  },
  {
    label: "RIVIERE-SALEE",
    value: "97221",
    type: "common",
  },
  {
    label: "DUCOS",
    value: "97207",
    type: "common",
  },
  {
    label: "LES TROIS-ILETS",
    value: "97231",
    type: "common",
  },
  {
    label: "LE MARIN",
    value: "97217",
    type: "common",
  },
  {
    label: "SAINTE-LUCE",
    value: "97227",
    type: "common",
  },
  {
    label: "SAINTE-ANNE",
    value: "97226",
    type: "common",
  },
  {
    label: "LE DIAMANT",
    value: "97206",
    type: "common",
  },
  {
    label: "LES ANSES-D'ARLET",
    value: "97202",
    type: "common",
  },
  {
    label: "OUANARY",
    value: "97314",
    type: "common",
  },
  {
    label: "SAINT-ELIE",
    value: "97358",
    type: "common",
  },
  {
    label: "MATOURY",
    value: "97307",
    type: "common",
  },
  {
    label: "MACOURIA",
    value: "97305",
    type: "common",
  },
  {
    label: "MONTSINERY-TONNEGRANDE",
    value: "97313",
    type: "common",
  },
  {
    label: "REMIRE-MONTJOLY",
    value: "97309",
    type: "common",
  },
  {
    label: "ROURA",
    value: "97310",
    type: "common",
  },
  {
    label: "SINNAMARY",
    value: "97312",
    type: "common",
  },
  {
    label: "CAYENNE",
    value: "97302",
    type: "common",
  },
  {
    label: "KOUROU",
    value: "97304",
    type: "common",
  },
  {
    label: "PAPAICHTON",
    value: "97362",
    type: "common",
  },
  {
    label: "SAINT-LAURENT-DU-MARONI",
    value: "97311",
    type: "common",
  },
  {
    label: "MANA",
    value: "97306",
    type: "common",
  },
  {
    label: "AWALA-YALIMAPO",
    value: "97361",
    type: "common",
  },
  {
    label: "IRACOUBO",
    value: "97303",
    type: "common",
  },
  {
    label: "GRAND-SANTI",
    value: "97357",
    type: "common",
  },
  {
    label: "APATOU",
    value: "97360",
    type: "common",
  },
  {
    label: "SAINT-GEORGES",
    value: "97308",
    type: "common",
  },
  {
    label: "CAMOPI",
    value: "97356",
    type: "common",
  },
  {
    label: "REGINA",
    value: "97301",
    type: "common",
  },
  {
    label: "MARIPASOULA",
    value: "97353",
    type: "common",
  },
  {
    label: "SAUL",
    value: "97352",
    type: "common",
  },
  {
    label: "SAINT-ANDRE",
    value: "97409",
    type: "common",
  },
  {
    label: "SAINTE-SUZANNE",
    value: "97420",
    type: "common",
  },
  {
    label: "SAINTE-MARIE",
    value: "97418",
    type: "common",
  },
  {
    label: "LE PORT",
    value: "97407",
    type: "common",
  },
  {
    label: "LA POSSESSION",
    value: "97408",
    type: "common",
  },
  {
    label: "SAINT-DENIS",
    value: "97411",
    type: "common",
  },
  {
    label: "SAINTE-ROSE",
    value: "97419",
    type: "common",
  },
  {
    label: "LA PLAINE-DES-PALMISTES",
    value: "97406",
    type: "common",
  },
  {
    label: "SAINT-BENOIT",
    value: "97410",
    type: "common",
  },
  {
    label: "BRAS-PANON",
    value: "97402",
    type: "common",
  },
  {
    label: "LE TAMPON",
    value: "97422",
    type: "common",
  },
  {
    label: "SALAZIE",
    value: "97421",
    type: "common",
  },
  {
    label: "LES TROIS-BASSINS",
    value: "97423",
    type: "common",
  },
  {
    label: "LES AVIRONS",
    value: "97401",
    type: "common",
  },
  {
    label: "SAINT-PAUL",
    value: "97415",
    type: "common",
  },
  {
    label: "SAINT-LEU",
    value: "97413",
    type: "common",
  },
  {
    label: "CILAOS",
    value: "97424",
    type: "common",
  },
  {
    label: "SAINT-LOUIS",
    value: "97414",
    type: "common",
  },
  {
    label: "ENTRE-DEUX",
    value: "97403",
    type: "common",
  },
  {
    label: "L'ETANG-SALE",
    value: "97404",
    type: "common",
  },
  {
    label: "PETITE-ILE",
    value: "97405",
    type: "common",
  },
  {
    label: "SAINT-JOSEPH",
    value: "97412",
    type: "common",
  },
  {
    label: "SAINT-PHILIPPE",
    value: "97417",
    type: "common",
  },
  {
    label: "SAINT-PIERRE",
    value: "97416",
    type: "common",
  },
  {
    label: "DZAOUDZI",
    value: "97608",
    type: "common",
  },
  {
    label: "KOUNGOU",
    value: "97610",
    type: "common",
  },
  {
    label: "MAMOUDZOU",
    value: "97611",
    type: "common",
  },
  {
    label: "TSINGONI",
    value: "97617",
    type: "common",
  },
  {
    label: "BANDRABOUA",
    value: "97602",
    type: "common",
  },
  {
    label: "MTSAMBORO",
    value: "97612",
    type: "common",
  },
  {
    label: "M'TSANGAMOUJI",
    value: "97613",
    type: "common",
  },
  {
    label: "ACOUA",
    value: "97601",
    type: "common",
  },
  {
    label: "PAMANDZI",
    value: "97615",
    type: "common",
  },
  {
    label: "OUANGANI",
    value: "97614",
    type: "common",
  },
  {
    label: "DEMBENI",
    value: "97607",
    type: "common",
  },
  {
    label: "CHICONI",
    value: "97605",
    type: "common",
  },
  {
    label: "SADA",
    value: "97616",
    type: "common",
  },
  {
    label: "BANDRELE",
    value: "97603",
    type: "common",
  },
  {
    label: "CHIRONGUI",
    value: "97606",
    type: "common",
  },
  {
    label: "KANI-KELI",
    value: "97609",
    type: "common",
  },
  {
    label: "BOUENI",
    value: "97604",
    type: "common",
  },
  {
    label: "SAINT-LUCIEN",
    value: "76601",
    type: "common",
  },
  {
    label: "MOULINS-SUR-TARDOIRE",
    value: "16406",
    type: "common",
  },
  {
    label: "LE PERREY",
    value: "27263",
    type: "common",
  },
  {
    label: "VALSERHONE",
    value: "01033",
    type: "common",
  },
  {
    label: "VALROMEY-SUR-SERAN",
    value: "01036",
    type: "common",
  },
  {
    label: "TIREPIED-SUR-SEE",
    value: "50597",
    type: "common",
  },
  {
    label: "BRESSE VALLONS",
    value: "01130",
    type: "common",
  },
  {
    label: "PLATEAU D'HAUTEVILLE",
    value: "01185",
    type: "common",
  },
  {
    label: "SURJOUX-LHOPITAL",
    value: "01215",
    type: "common",
  },
  {
    label: "MAGNIEU",
    value: "01227",
    type: "common",
  },
  {
    label: "ARVIERE-EN-VALROMEY",
    value: "01453",
    type: "common",
  },
  {
    label: "ANIZY-LE-GRAND",
    value: "02018",
    type: "common",
  },
  {
    label: "CESSIERES-SUZY",
    value: "02153",
    type: "common",
  },
  {
    label: "VILLENEUVE-SUR-AISNE",
    value: "02360",
    type: "common",
  },
  {
    label: "ABRIES-RISTOLAS",
    value: "05001",
    type: "common",
  },
  {
    label: "VALLEES-D'ANTRAIGUES-ASPERJOC",
    value: "07011",
    type: "common",
  },
  {
    label: "SAINT-JULIEN-D'INTRES",
    value: "07103",
    type: "common",
  },
  {
    label: "BELSENTES",
    value: "07165",
    type: "common",
  },
  {
    label: "SAINT-LAURENT-LES-BAINS-LAVAL-D'AURELLE",
    value: "07262",
    type: "common",
  },
  {
    label: "FLIZE",
    value: "08173",
    type: "common",
  },
  {
    label: "AULOS-SINSAT",
    value: "09296",
    type: "common",
  },
  {
    label: "VAL-DE-SOS",
    value: "09334",
    type: "common",
  },
  {
    label: "VAL-DU-FABY",
    value: "11131",
    type: "common",
  },
  {
    label: "VAL-DE-DAGNE",
    value: "11251",
    type: "common",
  },
  {
    label: "ROQUETAILLADE-ET-CONILHAC",
    value: "11323",
    type: "common",
  },
  {
    label: "CAMBREMER",
    value: "14126",
    type: "common",
  },
  {
    label: "CESNY-LES-SOURCES",
    value: "14150",
    type: "common",
  },
  {
    label: "PONT-L'EVEQUE",
    value: "14514",
    type: "common",
  },
  {
    label: "CASTINE-EN-PLAINE",
    value: "14538",
    type: "common",
  },
  {
    label: "LE CASTELET",
    value: "14554",
    type: "common",
  },
  {
    label: "MONTILLIERES-SUR-ORNE",
    value: "14713",
    type: "common",
  },
  {
    label: "PUYCAPEL",
    value: "15027",
    type: "common",
  },
  {
    label: "AIGRE",
    value: "16005",
    type: "common",
  },
  {
    label: "COURCOME",
    value: "16110",
    type: "common",
  },
  {
    label: "MAINXE-GONDEVILLE",
    value: "16153",
    type: "common",
  },
  {
    label: "TERRES-DE-HAUTE-CHARENTE",
    value: "16192",
    type: "common",
  },
  {
    label: "LA ROCHEFOUCAULD-EN-ANGOUMOIS",
    value: "16281",
    type: "common",
  },
  {
    label: "VAL-D'AUGE",
    value: "16339",
    type: "common",
  },
  {
    label: "MARENNES-HIERS-BROUAGE",
    value: "17219",
    type: "common",
  },
  {
    label: "SAINT-HILAIRE-DE-VILLEFRANCHE",
    value: "17344",
    type: "common",
  },
  {
    label: "BAUGY",
    value: "18023",
    type: "common",
  },
  {
    label: "CORQUOY",
    value: "18073",
    type: "common",
  },
  {
    label: "BEAULIEU-SUR-DORDOGNE",
    value: "19019",
    type: "common",
  },
  {
    label: "LAGARDE-MARC-LA-TOUR",
    value: "19098",
    type: "common",
  },
  {
    label: "LAGUENNE-SUR-AVALOUZE",
    value: "19101",
    type: "common",
  },
  {
    label: "VALFORET",
    value: "21178",
    type: "common",
  },
  {
    label: "LE VAL-LARREY",
    value: "21272",
    type: "common",
  },
  {
    label: "LONGEAULT-PLUVAULT",
    value: "21352",
    type: "common",
  },
  {
    label: "CHATELAUDREN-PLOUAGAT",
    value: "22206",
    type: "common",
  },
  {
    label: "PLOUGUENAST-LANGAST",
    value: "22219",
    type: "common",
  },
  {
    label: "LA ROCHE-JAUDY",
    value: "22264",
    type: "common",
  },
  {
    label: "LINARD-MALVAL",
    value: "23109",
    type: "common",
  },
  {
    label: "SAINT-DIZIER-MASBARAUD",
    value: "23189",
    type: "common",
  },
  {
    label: "LES EYZIES",
    value: "24172",
    type: "common",
  },
  {
    label: "EYRAUD-CREMPSE-MAURENS",
    value: "24259",
    type: "common",
  },
  {
    label: "COLY-SAINT-AMAND",
    value: "24364",
    type: "common",
  },
  {
    label: "SAINT-JULIEN-INNOCENCE-EULALIE",
    value: "24423",
    type: "common",
  },
  {
    label: "SIGOULES-ET-FLAUGEAC",
    value: "24534",
    type: "common",
  },
  {
    label: "PAYS-DE-CLERVAL",
    value: "25156",
    type: "common",
  },
  {
    label: "FONTAIN",
    value: "25245",
    type: "common",
  },
  {
    label: "TARCENAY-FOUCHERANS",
    value: "25558",
    type: "common",
  },
  {
    label: "CHATILLON-EN-DIOIS",
    value: "26086",
    type: "common",
  },
  {
    label: "VALHERBASSE",
    value: "26210",
    type: "common",
  },
  {
    label: "FRENELLES-EN-VEXIN",
    value: "27070",
    type: "common",
  },
  {
    label: "TREIS-SANTS-EN-OUCHE",
    value: "27516",
    type: "common",
  },
  {
    label: "LE MESNIL-SAINT-JEAN",
    value: "27541",
    type: "common",
  },
  {
    label: "LA VIEILLE-LYRE",
    value: "27685",
    type: "common",
  },
  {
    label: "AUTHON-DU-PERCHE",
    value: "28018",
    type: "common",
  },
  {
    label: "JANVILLE-EN-BEAUCE",
    value: "28199",
    type: "common",
  },
  {
    label: "ARCISSES",
    value: "28236",
    type: "common",
  },
  {
    label: "SAINTIGNY",
    value: "28331",
    type: "common",
  },
  {
    label: "SAINT-DENIS-LANNERAY",
    value: "28334",
    type: "common",
  },
  {
    label: "PLOUIGNEAU",
    value: "29199",
    type: "common",
  },
  {
    label: "POULLAOUEN",
    value: "29227",
    type: "common",
  },
  {
    label: "BREAU-MARS",
    value: "30052",
    type: "common",
  },
  {
    label: "VAL-D'AIGOUAL",
    value: "30339",
    type: "common",
  },
  {
    label: "SAINT-BEAT-LEZ",
    value: "31471",
    type: "common",
  },
  {
    label: "RISCLE",
    value: "32344",
    type: "common",
  },
  {
    label: "PORTE-DE-BENAUGE",
    value: "33008",
    type: "common",
  },
  {
    label: "BLAIGNAN-PRIGNAC",
    value: "33055",
    type: "common",
  },
  {
    label: "VAL-DE-LIVENNE",
    value: "33380",
    type: "common",
  },
  {
    label: "ENTRE-VIGNES",
    value: "34246",
    type: "common",
  },
  {
    label: "VAL-COUESNON",
    value: "35004",
    type: "common",
  },
  {
    label: "LUITRE-DOMPIERRE",
    value: "35163",
    type: "common",
  },
  {
    label: "MONTAUBAN-DE-BRETAGNE",
    value: "35184",
    type: "common",
  },
  {
    label: "PIRE-CHANCE",
    value: "35220",
    type: "common",
  },
  {
    label: "RIVES-DU-COUESNON",
    value: "35282",
    type: "common",
  },
  {
    label: "SAINT-MARC-LE-BLANC",
    value: "35292",
    type: "common",
  },
  {
    label: "MESNIL-ROC'H",
    value: "35308",
    type: "common",
  },
  {
    label: "VILLENTROIS-FAVEROLLES-EN-BERRY",
    value: "36244",
    type: "common",
  },
  {
    label: "CHANTEPERIER",
    value: "38073",
    type: "common",
  },
  {
    label: "LE HAUT-BREDA",
    value: "38163",
    type: "common",
  },
  {
    label: "ORNACIEUX-BALBINS",
    value: "38284",
    type: "common",
  },
  {
    label: "PLATEAU-DES-PETITES-ROCHES",
    value: "38395",
    type: "common",
  },
  {
    label: "PORTE-DES-BONNEVAUX",
    value: "38479",
    type: "common",
  },
  {
    label: "VAL-DE-VIRIEU",
    value: "38560",
    type: "common",
  },
  {
    label: "BEAUFORT-ORBAGNA",
    value: "39043",
    type: "common",
  },
  {
    label: "SAINT-HYMETIERE-SUR-VALOUSE",
    value: "39137",
    type: "common",
  },
  {
    label: "DAMPIERRE",
    value: "39190",
    type: "common",
  },
  {
    label: "DOMBLANS",
    value: "39199",
    type: "common",
  },
  {
    label: "GRANDE-RIVIERE CHATEAU",
    value: "39258",
    type: "common",
  },
  {
    label: "CHASSAL-MOLINGES",
    value: "39339",
    type: "common",
  },
  {
    label: "MORCENX-LA-NOUVELLE",
    value: "40197",
    type: "common",
  },
  {
    label: "LE CONTROIS-EN-SOLOGNE",
    value: "41059",
    type: "common",
  },
  {
    label: "VALLEE-DE-RONSARD",
    value: "41070",
    type: "common",
  },
  {
    label: "VETRE-SUR-ANZON",
    value: "42245",
    type: "common",
  },
  {
    label: "VEZELIN-SUR-LOIRE",
    value: "42268",
    type: "common",
  },
  {
    label: "ANCENIS-SAINT-GEREON",
    value: "44003",
    type: "common",
  },
  {
    label: "PORTE-DU-QUERCY",
    value: "46033",
    type: "common",
  },
  {
    label: "CRESSENSAC-SARRAZAC",
    value: "46083",
    type: "common",
  },
  {
    label: "LE VIGNON-EN-QUERCY",
    value: "46232",
    type: "common",
  },
  {
    label: "BARGUELONNE-EN-QUERCY",
    value: "46263",
    type: "common",
  },
  {
    label: "BEL-AIR-VAL-D'ANCE",
    value: "48038",
    type: "common",
  },
  {
    label: "LACHAMP-RIBENNES",
    value: "48126",
    type: "common",
  },
  {
    label: "MONTS-DE-RANDON",
    value: "48127",
    type: "common",
  },
  {
    label: "BELLEVIGNE-LES-CHATEAUX",
    value: "49060",
    type: "common",
  },
  {
    label: "HUILLE-LEZIGNE",
    value: "49174",
    type: "common",
  },
  {
    label: "SAINT-LEGER-DE-LINIERES",
    value: "49298",
    type: "common",
  },
  {
    label: "RIVES-DU-LOIR-EN-ANJOU",
    value: "49377",
    type: "common",
  },
  {
    label: "AVRANCHES",
    value: "50025",
    type: "common",
  },
  {
    label: "PORT-BAIL-SUR-MER",
    value: "50412",
    type: "common",
  },
  {
    label: "QUETTEHOU",
    value: "50417",
    type: "common",
  },
  {
    label: "SAINT-SAUVEUR-VILLAGES",
    value: "50550",
    type: "common",
  },
  {
    label: "BIERNE-LES-VILLAGES",
    value: "53029",
    type: "common",
  },
  {
    label: "CHATEAU-GONTIER-SUR-MAYENNE",
    value: "53062",
    type: "common",
  },
  {
    label: "EVRON",
    value: "53097",
    type: "common",
  },
  {
    label: "GENNES-LONGUEFUYE",
    value: "53104",
    type: "common",
  },
  {
    label: "LA ROCHE-NEUVILLE",
    value: "53136",
    type: "common",
  },
  {
    label: "BOIS-DE-HAYE",
    value: "54557",
    type: "common",
  },
  {
    label: "DEMANGE-BAUDIGNECOURT",
    value: "55150",
    type: "common",
  },
  {
    label: "DOUAUMONT-VAUX",
    value: "55537",
    type: "common",
  },
  {
    label: "FORGES DE LANOUEE",
    value: "56102",
    type: "common",
  },
  {
    label: "PLOERMEL",
    value: "56165",
    type: "common",
  },
  {
    label: "PLUMELIAU-BIEUZY",
    value: "56173",
    type: "common",
  },
  {
    label: "MANDEREN-RITZING",
    value: "57439",
    type: "common",
  },
  {
    label: "REZONVILLE-VIONVILLE",
    value: "57578",
    type: "common",
  },
  {
    label: "LES HAUTS-TALICAN",
    value: "60054",
    type: "common",
  },
  {
    label: "LA CORNE-EN-VEXIN",
    value: "60209",
    type: "common",
  },
  {
    label: "FORMERIE",
    value: "60245",
    type: "common",
  },
  {
    label: "MONTCHEVREUIL",
    value: "60256",
    type: "common",
  },
  {
    label: "VILLERS-SAINT-FRAMBOURG-OGNON",
    value: "60682",
    type: "common",
  },
  {
    label: "L'OREE-D'ECOUVES",
    value: "61228",
    type: "common",
  },
  {
    label: "MORTREE",
    value: "61294",
    type: "common",
  },
  {
    label: "BONNIERES",
    value: "62154",
    type: "common",
  },
  {
    label: "MUR-SUR-ALLIER",
    value: "63226",
    type: "common",
  },
  {
    label: "SAINT-DIERY",
    value: "63335",
    type: "common",
  },
  {
    label: "LE VERNET-CHAMEANE",
    value: "63448",
    type: "common",
  },
  {
    label: "ROUNTZENHEIM-AUENHEIM",
    value: "67418",
    type: "common",
  },
  {
    label: "BELLEVILLE-EN-BEAUJOLAIS",
    value: "69019",
    type: "common",
  },
  {
    label: "DEUX-GROSNES",
    value: "69135",
    type: "common",
  },
  {
    label: "VINDRY-SUR-TURDINE",
    value: "69157",
    type: "common",
  },
  {
    label: "FOUGEROLLES-SAINT-VALBERT",
    value: "70245",
    type: "common",
  },
  {
    label: "SEVEUX-MOTEY",
    value: "70491",
    type: "common",
  },
  {
    label: "NAVOUR-SUR-GROSNE",
    value: "71134",
    type: "common",
  },
  {
    label: "CHERRE-AU",
    value: "72080",
    type: "common",
  },
  {
    label: "VAL-D'ETANGSON",
    value: "72128",
    type: "common",
  },
  {
    label: "MAROLLES-LES-BRAULTS",
    value: "72189",
    type: "common",
  },
  {
    label: "BERNAY-NEUVY-EN-CHAMPAGNE",
    value: "72219",
    type: "common",
  },
  {
    label: "GRAND-AIGUEBLANCHE",
    value: "73003",
    type: "common",
  },
  {
    label: "PORTE-DE-SAVOIE",
    value: "73151",
    type: "common",
  },
  {
    label: "VAL-D'ARC",
    value: "73212",
    type: "common",
  },
  {
    label: "VALGELON-LA ROCHETTE",
    value: "73215",
    type: "common",
  },
  {
    label: "SAINT-GENIX-LES-VILLAGES",
    value: "73236",
    type: "common",
  },
  {
    label: "GLIERES-VAL-DE-BORNE",
    value: "74212",
    type: "common",
  },
  {
    label: "VALLIERES-SUR-FIER",
    value: "74289",
    type: "common",
  },
  {
    label: "VAL-DE-SCIE",
    value: "76034",
    type: "common",
  },
  {
    label: "LES HAUTS-DE-CAUX",
    value: "76041",
    type: "common",
  },
  {
    label: "CHENOISE-CUCHARMOY",
    value: "77109",
    type: "common",
  },
  {
    label: "BEAUTHEIL-SAINTS",
    value: "77433",
    type: "common",
  },
  {
    label: "VILLEMARECHAL",
    value: "77504",
    type: "common",
  },
  {
    label: "LE CHESNAY-ROCQUENCOURT",
    value: "78158",
    type: "common",
  },
  {
    label: "NOTRE-DAME-DE-LA-MER",
    value: "78320",
    type: "common",
  },
  {
    label: "SAINT-GERMAIN-EN-LAYE",
    value: "78551",
    type: "common",
  },
  {
    label: "FONTIVILLIE",
    value: "79064",
    type: "common",
  },
  {
    label: "BEUGNON-THIREUIL",
    value: "79077",
    type: "common",
  },
  {
    label: "CHEF-BOUTONNE",
    value: "79083",
    type: "common",
  },
  {
    label: "LES CHATELIERS",
    value: "79105",
    type: "common",
  },
  {
    label: "VALDELAUME",
    value: "79140",
    type: "common",
  },
  {
    label: "MELLE",
    value: "79174",
    type: "common",
  },
  {
    label: "MONCOUTANT-SUR-SEVRE",
    value: "79179",
    type: "common",
  },
  {
    label: "PLAINE-ET-VALLEES",
    value: "79196",
    type: "common",
  },
  {
    label: "PRAILLES-LA COUARDE",
    value: "79217",
    type: "common",
  },
  {
    label: "MARCILLE",
    value: "79251",
    type: "common",
  },
  {
    label: "SAINT-PARDOUX-SOUTIERS",
    value: "79285",
    type: "common",
  },
  {
    label: "THOUARS",
    value: "79329",
    type: "common",
  },
  {
    label: "VAL-DU-MIGNON",
    value: "79334",
    type: "common",
  },
  {
    label: "HOMBLEUX",
    value: "80442",
    type: "common",
  },
  {
    label: "O-DE-SELLE",
    value: "80485",
    type: "common",
  },
  {
    label: "CARNOY-MAMETZ",
    value: "80505",
    type: "common",
  },
  {
    label: "MARCHELEPOT-MISERY",
    value: "80509",
    type: "common",
  },
  {
    label: "TROIS-RIVIERES",
    value: "80625",
    type: "common",
  },
  {
    label: "TERRE-DE-BANCALIE",
    value: "81233",
    type: "common",
  },
  {
    label: "MONTAIGU-VENDEE",
    value: "85146",
    type: "common",
  },
  {
    label: "RIVES-D'AUTISE",
    value: "85162",
    type: "common",
  },
  {
    label: "LES VELLUIRE-SUR-VENDEE",
    value: "85177",
    type: "common",
  },
  {
    label: "LES SABLES-D'OLONNE",
    value: "85194",
    type: "common",
  },
  {
    label: "CHANVERRIE",
    value: "85302",
    type: "common",
  },
  {
    label: "VALENCE-EN-POITOU",
    value: "86082",
    type: "common",
  },
  {
    label: "BOIVRE-LA-VALLEE",
    value: "86123",
    type: "common",
  },
  {
    label: "VAL-D'OIRE-ET-GARTEMPE",
    value: "87028",
    type: "common",
  },
  {
    label: "SAINT-PARDOUX-LE-LAC",
    value: "87128",
    type: "common",
  },
  {
    label: "MEROUX-MOVAL",
    value: "90068",
    type: "common",
  },
  {
    label: "EVRY-COURCOURONNES",
    value: "91228",
    type: "common",
  },
  {
    label: "LE MEREVILLOIS",
    value: "91390",
    type: "common",
  },
  {
    label: "BEYREDE-JUMET-CAMOUS",
    value: "65092",
    type: "common",
  },
  {
    label: "FRESNAY-SUR-SARTHE",
    value: "72138",
    type: "common",
  },
  {
    label: "GUILLON-TERRE-PLAINE",
    value: "89197",
    type: "common",
  },
  {
    label: "TART",
    value: "21623",
    type: "common",
  },
  {
    label: "COLLONGES-ET-PREMIERES",
    value: "21183",
    type: "common",
  },
  {
    label: "NEUILLY-CRIMOLOIS",
    value: "21452",
    type: "common",
  },
  {
    label: "TROARN",
    value: "14712",
    type: "common",
  },
  {
    label: "SANNERVILLE",
    value: "14666",
    type: "common",
  },
  {
    label: "MOSNAC-SAINT-SIMEUX",
    value: "16233",
    type: "common",
  },
  {
    label: "VIMARTIN-SUR-ORTHE",
    value: "53249",
    type: "common",
  },
];

export const localTypes = [
  { value: "COMMERCE", label: "Commerce" },
  { value: "INDUSTRIE", label: "Industrie" },
  { value: "SANTE", label: "Santé" },
  { value: "LOCAUX D'ENTREPRISE", label: "Locaux d’entreprise" },
  { value: "LOGEMENT", label: "Logement" },
  { value: "HOTELLERIE", label: "Hôtellerie" },
  { value: "ENSEIGNEMENT", label: "Enseignement" },
  { value: "CULTURE/SPORT", label: "Culture / sport" },
  { value: "ADMINISTRATION", label: "Administration" },
  { value: "AGRICOLE", label: "Agricole" },
  { value: "DIVERS", label: "Divers" },
];

export const workTypes = [
  { value: "Construction neuve", label: "Construction neuve" },
  { value: "Rénovation", label: "Rénovation" },
];

export const locationsList = [
  ...regionsList,
  ...departementsList,
  ...communesList,

].map(item => {
  const label = item.label.split("-").map(part => {
      return part[0].toUpperCase() + part.substring(1).toLowerCase();
    }).join("-");
  item.label = label;
  return item;
});
