import React from "react";
import { Stack, Text } from "@chakra-ui/react";
import { useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import {
  createPayment,
  createSubscription,
} from "../../redux/actionsCreators/paymentActionCreators";
import CustomButton from "../../shared/Button/CustomButton";
import CustomForm from "../../shared/Form/CustomForm";
import { orderTypes } from "../../constants/orderConstants";
import "./PaymentForm.css";

const CARD_OPTIONS = {
  iconStyle: "solid",
  style: {
    base: {
      iconColor: "#f39200",
      color: "#1a202c",
      fontSize: "16px",
      fontWeight: "medium",
      fontSmoothing: "antialiased",
      ":-webkit-autofill": { color: "#1a202c" },
      "::placeholder": { color: "#a8a8a8" },
    },
    invalid: {
      color: "#fa755a",
      iconColor: "#fa755a",
    },
  },
};

const PaymentForm = ({ className, orderType, location }) => {
  const stripe = useStripe();
  const elements = useElements();

  const dispatch = useDispatch();

  const handleSubmit = async (values, actions) => {
    if (orderType === orderTypes.product) await handleOneTimePayment();
    if (orderType === orderTypes.subscription) await handleSubscription();
  };

  const handleOneTimePayment = async () => {
    await dispatch(createPayment(stripe, elements, CardElement));
  };

  const handleSubscription = async () => {
    const project = location?.state?.project;
    await dispatch(createSubscription(stripe, elements, CardElement, project));
  };

  return (
    <Stack
      className={className}
      justifyContent="center"
      alignItems="center"
      width="100%"
      flex={1}
    >
      <Text className="onetimepayment_text">
        Veuillez saisir les données de votre carte de crédit
      </Text>
      <CustomForm onSubmit={handleSubmit}>
        {(formProps) => {
          return (
            <Stack className="Stripe-form">
              <CardElement
                className="cardElement_wrapper"
                options={CARD_OPTIONS}
              />
              <CustomButton
                type="submit"
                isLoading={formProps.isSubmitting}
                loadingText="Paiement en cours"
              >
                Payez
              </CustomButton>
            </Stack>
          );
        }}
      </CustomForm>
    </Stack>
  );
};

export default withRouter(PaymentForm);
