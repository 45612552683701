import React from "react";
import { Stack } from "@chakra-ui/react";
import Landing from "./Landing";
import PostSources from "./PostSources";
import Features from "./Features";
import "./EasyAOHome.css";

const EasyAOHome = ({ history }) => {
  return (
    <Stack flex={1}>
      <Landing history={history} />
      <PostSources />
      <Features />
    </Stack>
  );
};
export default EasyAOHome;
