import React from "react";
import { useSelector } from "react-redux";
import { VStack, HStack, Stack, Text, Box } from "@chakra-ui/react";
import CustomButton from "../../shared/Button/CustomButton";
import { getUrlParams } from "../../helpers/routerHelper";
import "./ErrorPage.css";
import { productsLabels } from "../../constants/generalConstants";

const mappingContent = (type, project) => {
  if(type === "alreadyActive") return {
    pageTitle: "Activation du compte",
    bodyTitle: "Oops !",
    bodyContent: (
      <VStack className="errorPage_paragraph" spacing="0">
        <Text className="errorPage_text">
          Ce compte est déja <strong>activé</strong>, cliquez sur commncer pour
          utiliser notre moteur de recherche
        </Text>
        <Text className="errorPage_text">
          et nos outils de gestion des {productsLabels[project]}.
        </Text>
      </VStack>
    ),
    buttonText: "Commencer !",
  };

  if(type === "resetPasswordInvalidToken") return {
    pageTitle: "Réinitialisation du mot de passe",
    bodyTitle: "Oops !",
    bodyContent: (
      <VStack className="errorPage_paragraph" spacing="0">
        <Text className="errorPage_text">
          Désolé, ce lien n'est pas encore valide
        </Text>
      </VStack>
    ),
    buttonText: "Continuer !",
  };

  if(type === "enableAccountInvalidToken") return {
    pageTitle: "Activation du compte",
    bodyTitle: "Oops !",
    bodyContent: (
      <VStack className="errorPage_paragraph" spacing="0">
        <Text className="errorPage_text">
          Désolé, ce lien n'est pas encore valide
        </Text>
      </VStack>
    ),
    buttonText: "Continuer !",
  }

  if(type === "userNotFound") return  {
    pageTitle: "Activation du compte",
    bodyTitle: "Oops !",
    bodyContent: (
      <VStack className="errorPage_paragraph" spacing="0">
        <Text className="errorPage_text">
          Désolé, veuillez s'inscrire, ce compte est introuvable
        </Text>
      </VStack>
    ),
    buttonText: "Continuer !",
  };
};

const ErrorPage = ({ history }) => {
  const navigateToSearch = () => history.push("/search");
  const { type } = getUrlParams();
  const { currentProject } = useSelector((state) => state.generalReducer);
  const content = mappingContent(type, currentProject);

  return (
    <Stack flex={1}>
      <VStack className="errorPage_wrapper" spacing="30px">
        <HStack className="errorPage_header">
          <Text className="errorPage_title">{content.pageTitle}</Text>
        </HStack>
        <VStack className="errorPage_content">
          <Text className="errorPage_subTitle">{content.bodyTitle}</Text>
          {content.bodyContent}
          <Box height="4px" />
          <CustomButton onClick={navigateToSearch}>
            {content.buttonText}
          </CustomButton>
        </VStack>
      </VStack>
    </Stack>
  );
};

export default ErrorPage;
