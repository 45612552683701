import React from "react";
import { VStack, HStack, Text, Image } from "@chakra-ui/react";
import commercialeInformation from "../../assets/images/commerciale_information.png";

const EasyProjectsSources = () => {
  return (
    <VStack
      className="easyProjectsSources"
      spacing="40px"
      style={{ margin: 0 }}
    >
      <Text className="easyProjects_headingPrimary">
        Nous transformons l’information en opportunité commerciale
      </Text>

      <HStack className="easyProjectsSources_content" spacing="100px">
        <Image
          src={commercialeInformation}
          alt="Easy Marchés centralise tous les marchés publics"
          width="600px"
        />
        <VStack spacing="30px">
          <VStack className="easyProjectsSources_text" spacing="20px">
            <Text>
            Easy Projets cible, détecte, qualifie et transmet chaque jour de nombreuses opportunités commerciales. Vous saurez ainsi vous positionner en amont de toute décision en fonction des dates de début et de fin des travaux estimées et de votre activité.
            Ainsi, votre force de vente peut <strong>intervenir au moment opportun.</strong>
            </Text>
            {/* <Text>
            Ainsi, votre force de vente peut <strong>intervenir au moment opportun.</strong>
            </Text> */}
            <Text>
            Le service d’intelligence commerciale de Easy Projets vous place <strong>au cœur du processus de décision</strong> lié à la construction et aux projets immobiliers. Vous accédez ainsi à une base de données vous renseignant <strong>tous les intervenants du projets</strong> recensés par nos services. 
            </Text>
          </VStack>

          <VStack alignItems="flex-start">
            <Text
              fontSize="26px"
              fontWeight="bold"
              color="var(--orange)"
              letterSpacing="1px"
            >
              D’où viennent nos informations ?
            </Text>
            <Text className="easyProjectsSources_text">
              Afin d’extraire les informations commerciales que vous pourrez
              utiliser dans votre prospection, Easy Projets puise ses
              informations dans des milliers de sources : La CDAC et la CNAC
              (Commissions départementale et nationale d’aménagement
              commercial), les articles de journaux, comptes rendus des conseils
              municipaux, arrêtés municipaux et préfectoraux, articles web,
              dépôts de permis de construire / démolir...
            </Text>
          </VStack>
        </VStack>
      </HStack>
    </VStack>
  );
};

export default EasyProjectsSources;
