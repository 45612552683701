import { stripeErrorsMessages } from "../constants/notificationConstants";

export const getErrorMessage = (err) => {
  console.trace({ err });
  if (stripeErrorsMessages[err.code]) return stripeErrorsMessages[err.code];
  const message = err?.response?.data?.message;
  return message ? message : err.message;
};

export const getErrorKey = (err) => {
  return err?.response?.data?.key;
};
