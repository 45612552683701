import { orderSteps, orderTypes } from "../../constants/orderConstants";
import orderHttpApi from "../../api/orderHttpApi";
import { getRouterHistory } from "../../helpers/routerHelper";
import { getErrorMessage, getErrorKey } from "../../helpers/errorHelper";
import cartActionTypes from "../actionsTypes/cartActionTypes";
import { showNotif } from "./appActionCreators";
import {
  notifMessages,
  notifTypes,
} from "../../constants/notificationConstants";
import { CACHE_TIME } from "../../constants/generalConstants";
import generalHttpApi from "../../api/generalHttpApi";
import { addToQueue } from "../../util/queueManager";

/**
 *  Mettre à jour la quantité de l'article du panier
 *
 * @async
 * @param {object} data
 * @param {string} data.itemId
 * @param {number} data.qty
 */
export const updateCartItemQty =
  ({ itemId, qty }) =>
  async (dispatch) => {
    try {
      dispatch({
        type: cartActionTypes.UPDATE_CART_PENDING,
        payload: true,
      });
      dispatch({
        type: cartActionTypes.UPDATE_CART_ITEM_QTY,
        payload: { itemId, qty },
      });
      if (!isNaN(parseInt(qty))) {
        const response = await orderHttpApi.updateCartItemQty(itemId, qty);
        const cart = response?.data?.data;
        dispatch({
          type: cartActionTypes.UPDATE_CART,
          payload: cart,
        });
      }
    } catch (err) {
    } finally {
      dispatch({
        type: cartActionTypes.UPDATE_CART_PENDING,
        payload: false,
      });
    }
  };

/**
 *  Supprimer l'article du panier
 *
 * @async
 * @param {object} itemId
 */
export const removeItemFromCart = (itemId) => async (dispatch) => {
  try {
    dispatch({
      type: cartActionTypes.UPDATE_CART_PENDING,
      payload: true,
    });
    dispatch({
      type: cartActionTypes.REMOVE_ITEM_PENDING,
      payload: { [itemId]: true },
    });
    const response = await orderHttpApi.removeItemFromCart(itemId);
    const cart = response?.data?.data;
    dispatch({
      type: cartActionTypes.UPDATE_CART,
      payload: cart,
    });
    if (!cart.promoCode) dispatch(clearPromoCode());
  } catch (err) {
  } finally {
    dispatch({
      type: cartActionTypes.UPDATE_CART_PENDING,
      payload: false,
    });
    dispatch({
      type: cartActionTypes.REMOVE_ITEM_PENDING,
      payload: { [itemId]: false },
    });
  }
};

/**
 *  Vider le panier
 *
 * @async
 */
export const clearCart = () => async (dispatch) => {
  dispatch({
    type: cartActionTypes.CLEAR_CART,
  });
};
/**
 *  Supprimer le coupon du store
 *
 * @async
 */
export const clearPromoCode = () => async (dispatch) => {
  dispatch({
    type: cartActionTypes.CLEAR_PROMOCODE,
  });
};

/**
 *  Récupérer le panier
 *
 * @async
 */
export const initCart = () => async (dispatch, getState) => {
  try {
    const { isAuthenticated } = getState().userReducer;
    if (!isAuthenticated) return;
    const cartResponse = await orderHttpApi.getCart();
    const cart = cartResponse?.data?.data;
    dispatch({
      type: cartActionTypes.INIT_CART,
      payload: cart,
    });
    const promoCode = cart?.promoCode;
    if (promoCode) {
      const promoCodeResponse = await orderHttpApi.getPromoCodeById(promoCode);
      const promoCodeData = promoCodeResponse?.data?.data;

      dispatch({
        type: cartActionTypes.SET_PROMOCODE,
        payload: promoCodeData,
      });
    }
  } catch (err) {}
};

/**
 * Créer le panier (une commande de type "cart") avec la première article
 *
 * @async
 * @param {object} data - les données du panier
 */
export const newOrder = (order) => async (dispatch, getState) => {
  try {
    const res = await orderHttpApi.newOrder(order);

    dispatch({
      type: cartActionTypes.INIT_CART,
      payload: res?.data?.data,
    });
    if (order.type === orderTypes.subscription)
      dispatch(navigateToOrderStep(orderSteps.step1, order.project));
  } catch (err) {
    const errorKey = getErrorKey(err);
    const message = getErrorMessage(err);
    if (errorKey !== "orderAlreadyExist")
      dispatch(showNotif({ message, type: notifTypes.error }));
    else if (order.type === orderTypes.subscription)
      dispatch(navigateToOrderStep(orderSteps.step1, order.project));
  }
};

/**
 * Ajouter une article au panier
 *
 * @async
 * @param {object} data
 * @param {string} data.item - l'article à ajouter
 * @param {string} data.type - le type du commande (abonnement ou service)
 */
export const addItemToCart =
  ({ item, type = orderTypes.product, project }) =>
  async (dispatch, getState) => {
    await addToQueue("addItemToCart", async () => {
      try {
        const { cartId } = getState().orderReducer;
        const cartResponse = await orderHttpApi.addItemToCart({
          cartId,
          item,
          project,
        });

        dispatch({
          type: cartActionTypes.UPDATE_CART,
          payload: cartResponse?.data?.data,
        });
      } catch (err) {
        const errorKey = getErrorKey(err);
        if (errorKey === "cartNotFound")
          return dispatch(newOrder({ item, type, project }));
        dispatch(
          showNotif({ message: getErrorMessage(err), type: notifTypes.error })
        );
      }
    });
  };

/**
 * Récupérer la liste des commandes
 *
 * @async
 */
export const initOrders = () => async (dispatch, getState) => {
  try {
    const { ordersInitializedAt } = getState().orderReducer;
    if (ordersInitializedAt && new Date() - ordersInitializedAt < CACHE_TIME)
      return;
    const { user } = getState().userReducer;
    const res = await orderHttpApi.getUserConfirmedOrders(user.id);
    dispatch({
      type: cartActionTypes.INIT_ORDERS,
      payload: res?.data?.data,
    });
  } catch (err) {
    dispatch(
      showNotif({ message: getErrorMessage(err), type: notifTypes.error })
    );
  }
};

/**
 * Annuler une commande
 *
 * @async
 * @param {string} orderId - l'ID du commande
 */
export const cancelOrder = (orderId) => async (dispatch) => {
  try {
    await orderHttpApi.updateOrder(orderId, { state: "canceled" });
    dispatch({
      type: cartActionTypes.CANCEL_ORDER,
      payload: orderId,
    });
  } catch (err) {
    dispatch(
      showNotif({ message: getErrorMessage(err), type: notifTypes.error })
    );
  }
};

/**
 * Sauvegarder l'étape de la commande en attente
 *
 * @async
 * @param {string} pendingOrderStep - l'étape du commande (step1 ,step2 ou step3)
 */
export const setPendingOrderStep = (pendingOrderStep) => async (dispatch) => {
  dispatch({
    type: cartActionTypes.SET_PENDING_SUBSCRIPTION,
    payload: pendingOrderStep,
  });
};

/**
 * Aller à une étape de la commande
 *
 * @async
 * @param {string} step - l'étape du commande (step1 ,step2 ou step3)
 */
export const navigateToOrderStep = (step, project) => async (dispatch) => {
  const history = getRouterHistory();
  dispatch(setPendingOrderStep(step));
  history.push("/cart/order/" + step, { project });
};

/**
 *  Récupérer le TVA
 *
 * @async
 */
export const initTVA = () => async (dispatch, getState) => {
  try {
    const res = await generalHttpApi.getTVA();

    dispatch({
      type: cartActionTypes.INIT_TVA,
      payload: res?.data?.data,
    });
  } catch (err) {}
};

/**
 *  Récupérer les données d'abonnement
 *
 * @async
 */
export const getSubscriptionOrders = () => async (dispatch) => {
  try {
    const res = await orderHttpApi.getUserSubscriptionOrders();

    dispatch({
      type: cartActionTypes.INIT_SUBSCRIPTION_ORDERS,
      payload: res?.data?.data,
    });
  } catch (err) {}
};

/**
 *  Appliquer le code promo sur le panier
 *
 * @async
 * @param {string} cartId
 * @param {string} couponCode
 */
export const applyPromoCodeOnCart = (cartId, promoCode) => async (dispatch) => {
  try {
    const promoCodeResponse = await orderHttpApi.getPromoCodeByCode(promoCode);
    const cartResponse = await orderHttpApi.applyPromoCodeOnCart(
      cartId,
      promoCodeResponse?.data?.data?.id
    );
    dispatch({
      type: cartActionTypes.SET_PROMOCODE,
      payload: promoCodeResponse?.data?.data,
    });
    dispatch({
      type: cartActionTypes.INIT_CART,
      payload: cartResponse?.data?.data,
    });
    dispatch(showNotif(notifMessages.promoCodeApplied));
    return cartResponse?.data?.data;
  } catch (err) {
    dispatch(
      showNotif({ message: getErrorMessage(err), type: notifTypes.error })
    );
    dispatch(removePromoCodeFromCart(cartId, false))
    return false;
  }
};

/**
 *  Supprimer le code promo du panier
 *
 * @async
 * @param {string} cartId
 */
export const removePromoCodeFromCart = (cartId, notify=true) => async (dispatch) => {
  try {
    const cartResponse = await orderHttpApi.removePromoCodeFromCart(cartId);
    dispatch({
      type: cartActionTypes.SET_PROMOCODE,
      payload: null,
    });
    const cart = cartResponse?.data?.data;
    dispatch({
      type: cartActionTypes.INIT_CART,
      payload: cart,
    });
    if(notify) dispatch(showNotif(notifMessages.promoCodeRemoved));
    return cart;
  } catch (err) {
    console.log({ err });
    if(notify) dispatch(
      showNotif({ message: getErrorMessage(err), type: notifTypes.error })
    );
  }
};
