import AxiosHttpApi from "../core/AxiosHttpApi";
const BASE_TARGET = `/payments`;
const STRIPE_BASE_TARGET = `/stripe`;

class PaymentHttpApi extends AxiosHttpApi {
  createOneTimePayment = (data) => {
    return this.postRequest(`${STRIPE_BASE_TARGET}/one-time-payment`, data);
  };
  createPortalSession = (data) => {
    return this.postRequest(
      `${STRIPE_BASE_TARGET}/create-portal-session`,
      data
    );
  };
  createSubscription = (data) => {
    return this.postRequest(`${STRIPE_BASE_TARGET}/create-subscription`, data);
  };

  getPayments = (userId) => {
    return this.getRequest(`${BASE_TARGET}/?userId=${userId}`);
  };

  getPaymentMethods = (userStripeId) => {
    return this.getRequest(
      `${STRIPE_BASE_TARGET}/${userStripeId}/payment-methods`
    );
  };

  detachPaymentMethod = (paymentMethodId) => {
    return this.postRequest(`${STRIPE_BASE_TARGET}/detach-payment-method`, {
      paymentMethodId,
    });
  };
  
  createIbanPayment = (data) => {
    return this.postRequest(`${STRIPE_BASE_TARGET}/iban-payment`, data);
  };

  createSetupIntent = (data) => {
    return this.postRequest(`${STRIPE_BASE_TARGET}/create-setup-intent`, data);
  };
}

export default new PaymentHttpApi();
