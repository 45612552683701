import React from "react";
import { Link, withRouter } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Box, Text, HStack, Image, VStack, Switch } from "@chakra-ui/react";
import AccountNavBarItem from "./AccountNavBarItem";
import NavBarItem from "./NavBarItem";
import CustomIcon from "../../shared/Icon/CustomIcon";
import { userTypes } from "../../constants/userConstants";
import AuthorizationWrapper from "../../components/AuthorizationWrapper/AuthorizationWrapper";
import { PROJECTS } from "../../constants/generalConstants";
import { switchProject } from "../../redux/actionsCreators/generalActionCreator";
import "./NavBar.css";
import logo from "../../assets/images/logo.png";
import easyProjectsLogo from "../../assets/images/logoEasyProjects.png";

const showSwicth = (location) => {
  if(!location?.pathname?.split) return true;
  const path = location.pathname.split('/')[1]; 
  return !['shop','account','detail','cart'].includes(path);
}

const NavBar = ({ location }) => {
  const { isAuthenticated, user } = useSelector((state) => state.userReducer);
  const { cart } = useSelector((state) => state.orderReducer);
  const { currentProject } = useSelector((state) => state.generalReducer);
  const dispatch = useDispatch();

  const numberOfCartItems = cart?.products?.length || 0;

  const HandleChangeProject = (e) => {
    dispatch(switchProject());
  };

  return (
    <HStack
      alignItems="center"
      justifyContent="space-between"
      paddingX="20px"
      shadow="lg"
      width="100%"
      zIndex="11"
      position="relative"
    >
      <Box>
        <Link to="/">
          <Box>
            <Image
              width="170px"
              src={currentProject === PROJECTS.easyao ? logo : easyProjectsLogo}
            />
          </Box>
        </Link>
        {/* <Box className="changeSector">
          <Switch id='changeSector' colorScheme='orange' />
        </Box> */}
      </Box>
      {/* <Spacer /> */}
      <HStack spacing="15px" paddingTop="15px" alignSelf="end">
        {!isAuthenticated && (
          <NavBarItem target="/" iconName="home">
            <Text className="navItem_text">Présentation</Text>
          </NavBarItem>
        )}
        <NavBarItem target="/search" iconName="search">
          <Text className="navItem_text">Recherche</Text>
        </NavBarItem>
        {/* <NavBarItem target="/favorites" iconName="star">
            <Text className="navItem_text">Mes favoris</Text>
          </NavBarItem> */}
        <AuthorizationWrapper roles={[userTypes.premium]}>
          <NavBarItem target="/favorites" iconName="star">
            <Text className="navItem_text">Mes favoris</Text>
          </NavBarItem>
        </AuthorizationWrapper>
        <NavBarItem target="/shop" iconName="store">
          <Text className="navItem_text">Nos offres</Text>
        </NavBarItem>
        <AuthorizationWrapper roles={[userTypes.premium]}>
          <NavBarItem target="/cart">
            <VStack className="badge_wrapper" spacing="0">
              <Box className="badge">
                <Text className="badge_text">{numberOfCartItems}</Text>
              </Box>
              <CustomIcon className="navItem_icon" name="cart" />
              <Text className="navItem_text">Panier</Text>
            </VStack>
          </NavBarItem>
        </AuthorizationWrapper>

        <AccountNavBarItem user={user} isAuthenticated={isAuthenticated} />
      </HStack>

      {showSwicth(location) && (
        <HStack
          style={{
            position: "absolute",
            bottom: "-34px",
            left: "50%",
            transform: "translateX(-50%)",
            backgroundColor: "white",
            height: "34px",
            alignItems: "flex-end",
            borderBottomLeftRadius: "30px",
            borderBottomRightRadius: "30px",
          }}
          spacing="4px"
          shadow="lg"
          px="10px"
          pb="10px"
        >
          <Text
            width="180px"
            textAlign="center"
            fontSize="18px"
            fontWeight={currentProject === PROJECTS.easyao ? "bold" : "300"}
          >
            Marchés Publics
          </Text>
          <Switch
            size="lg"
            onChange={HandleChangeProject}
            isChecked={currentProject === PROJECTS.easyprojects}
          />
          <Text
            width="200px"
            textAlign="center"
            fontSize="18px"
            fontWeight={
              currentProject === PROJECTS.easyprojects ? "bold" : "300"
            }
          >
            Projets Privés
          </Text>
        </HStack>
      )}
    </HStack>
  );
};
export default withRouter(NavBar);
