import {
  createStore,
  combineReducers,
  applyMiddleware,
} from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import userReducer from "./reducers/userReducer";
import appReducer from "./reducers/appReducer";
import generalReducer from "./reducers/generalReducer";
import paymentReducer from "./reducers/paymentReducer";
import orderReducer from "./reducers/orderReducer";
import productReducer from "./reducers/productReducer";
import searchReducer from "./reducers/searchReducer";
import favorisReducer from "./reducers/favorisReducer";
import projectsSearchReducer from "./reducers/projectsSearchReducer";
import favoritesProjectsReducer from "./reducers/favoritesProjectsReducer";

const reducer = combineReducers({
  userReducer,
  appReducer,
  generalReducer,
  paymentReducer,
  orderReducer,
  productReducer,
  searchReducer,
  favorisReducer,
  projectsSearchReducer,
  favoritesProjectsReducer
});

const initialState = {};

const middlewares = [thunk];

const store = createStore(
  reducer,
  initialState,
  composeWithDevTools(applyMiddleware(...middlewares))
);

export default store;
