import React from "react";
import { Form, Formik } from "formik";

const CustomForm = ({
  initialValues,
  validationSchema,
  onSubmit,
  children,
  enableReinitialize,
  onChange,
}) => {
  return (
    <Formik
      initialValues={initialValues || {}}
      enableReinitialize={enableReinitialize}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      validateOnBlur={false}
      validateOnChange={true}
    >
      {(formProps) => {
        return (
          <Form onChange={onChange} style={{ width: "100%" }}>
            {children(formProps)}
          </Form>
        );
      }}
    </Formik>
  );
};

export default CustomForm;
