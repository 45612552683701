import React from "react";
import { Stack } from "@chakra-ui/react";
import EasyProjectsLanding from "./EasyProjectsLanding";
import EasyProjectsSources from "./EasyProjectsSources";
import EasyProjectsFeatures from "./EasyProjectsFeatures";
import EasyProjectsResume from "./EasyProjectsResume";

const EasyProjectsHome = ({ history }) => {
  return (
    <Stack flex={1} spacing="0">
      <EasyProjectsLanding history={history} />
      <EasyProjectsSources />
      <EasyProjectsFeatures />
      <EasyProjectsResume />
    </Stack>
  );
};

export default EasyProjectsHome;
