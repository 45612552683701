import favorisActionTypes from "../actionsTypes/favorisActionTypes";

const initialState = {
  favoris: [],
};

const initFavoris = (state, payload) => {
  return { ...state, favoris: payload };
};


const favorisReducer = (state = initialState, action) => {
  switch (action.type) {
    case favorisActionTypes.INIT_FAVORIS:
      return initFavoris(state, action.payload);

    default:
      return state;
  }
};

export default favorisReducer;
