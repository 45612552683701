import AxiosHttpApi from "../core/AxiosHttpApi";

class GeneralHttpApi extends AxiosHttpApi {
  getSectors() {
    return this.getRequest(`/sectors`);
  }

  getPlans = () => {
    return this.getRequest(`/plans`);
  };

  getEasyProjectsPlans = () => {
    return this.getRequest(`/easyprojectsplans`);
  };

  getCompletePlan = () => {
    return this.getRequest(`/plans/complete`);
  };

  getPlansDiscountForSubscribed = () => {
    return this.getRequest('/stripe/plansdiscount');
  }

  getTVA = () => {
    return this.getRequest(`/taxes/tva`);
  };

  geturl = (ref, uident, project) => {
    return this.getRequest(`/createurltoshare/${ref}/${uident}?project=${project}`);
  };

  verifyRecaptchaResponse = (token) => {
    return this.postRequest(
      `/verify-recaptcha-response?secret=${process.env.REACT_APP_RECAPTCHA_SECRET}&response=${token}`
    );
  };
}

export default new GeneralHttpApi();
