import React, { useMemo } from "react";
import { withRouter } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useStripe, useElements, IbanElement } from "@stripe/react-stripe-js";
import {
  createIbanPayment,
  createSetupIntent,
} from "../../redux/actionsCreators/paymentActionCreators";
import CustomButton from "../../shared/Button/CustomButton";
import { VStack, Text } from "@chakra-ui/react";
import "./ibanForm.css";
import { orderTypes } from "../../constants/orderConstants";
import CustomForm from "../../shared/Form/CustomForm";

const useOptions = () => {
  const options = useMemo(
    () => ({
      iconStyle: "solid",
      style: {
        base: {
          iconColor: "#f39200",
          color: "#1a202c",
          fontSize: "16px",
          fontWeight: "medium",
          fontSmoothing: "antialiased",
          ":-webkit-autofill": { color: "#1a202c" },
          "::placeholder": { color: "#a8a8a8" },
        },
        invalid: {
          color: "#fa755a",
          iconColor: "#fa755a",
        },
      },
      supportedCountries: ["SEPA"],
    }),
    []
  );

  return options;
};

const IbanForm = ({ history, location }) => {
  const stripe = useStripe();
  const elements = useElements();
  const options = useOptions();
  const dispatch = useDispatch();
  const { cart } = useSelector((state) => state.orderReducer);

  const handleSubmit = async (values, actions) => {
    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }

    if (cart.type === orderTypes.product) {
      await dispatch(createIbanPayment(stripe, elements, IbanElement, history));
    } else {
      const project = location?.state?.project;
      await dispatch(createSetupIntent(stripe, elements, IbanElement, history, project));
    }
    actions.setSubmitting(false);
  };

  return (
    <CustomForm className="ibanForm" onSubmit={handleSubmit}>
      {(formProps) => (
        <>
          <Text className="onetimepayment_text">
            Veuillez saisir votre carte IBAN
          </Text>
          <VStack className="ibanForm_content">
            <IbanElement
              className="cardElement_wrapper"
              options={options}
              onReady={() => {
                console.log("IbanElement [ready]");
              }}
              onChange={(event) => {
                console.log("IbanElement [change]", event);
              }}
              onBlur={() => {
                console.log("IbanElement [blur]");
              }}
              onFocus={() => {
                console.log("IbanElement [focus]");
              }}
            />
            <VStack className="ibanForm_content" spacing="30px">
              <div className="mandate-acceptance">
                En fournissant vos informations de paiement et en confirmant ce
                paiement, vous autorisez (A) Wanao et Stripe, notre prestataire
                de services de paiement et/ou PPRO, son prestataire de services
                local, à envoyer des instructions à votre banque pour débiter
                votre compte et (B) votre banque à débiter votre compte
                conformément à ces instructions. Vous avez, entre autres, le
                droit de vous faire rembourser par votre banque selon les
                modalités et conditions du contrat conclu avec votre banque. La
                demande de remboursement doit être soumise dans un délai de 8
                semaines à compter de la date à laquelle votre compte a été
                débité. Vos droits sont expliqués dans une déclaration
                disponible auprès de votre banque. Vous acceptez de recevoir des
                notifications des débits à venir dans les 2 jours précédant leur
                réalisation.
              </div>

              <CustomButton
                className="ibanForm_button"
                type="submit"
                disabled={!stripe}
                isLoading={formProps.isSubmitting}
                loadingText="Paiement en cours"
              >
                Payez
              </CustomButton>
            </VStack>
          </VStack>
        </>
      )}
    </CustomForm>
  );
};

export default withRouter(IbanForm);
