import React from "react";
import { VStack, Text } from "@chakra-ui/react";
import EasyProjectsFeatureItem from "./EasyProjectsFeatureItem";
import businessDetection from "../../assets/images/business_detection.png";
import constructionAdjusting from "../../assets/images/construction_adjusting.png";
import buildingDelivery from "../../assets/images/building_delivery.png";

const features = [
  // {
  //   imageSrc: businessDetection,
  //   title: "Détection des signaux d’affaires",
  //   description: `Easy Marchés scanne l'actualité et surveille tous les Projets Privés qui font la vie des entreprises : investissements, sinistres, recrutements, fusions, acquisitions, implantations, développement économique, transferts,...`,
  //   forWho: `Ingénieurs, architectes, bureaux d’études, Maître d’oeuvre, promoteurs..`,
  // },
  {
    imageSrc: constructionAdjusting,
    title: "Construction et aménagement",
    description: `Nos équipes qualifient les projets en contactant les différents protagonistes afin de vous permettre de consulter les différents intervenants du projet consulté.`,
    forWho: `Équipementiers du chantier et du bâtiment finit, artisans...`,
  },
  {
    imageSrc: buildingDelivery,
    title: "Jusqu’à la fin des travaux",
    description: `Easy Marchés suit toutes les évolutions des projets jusqu’à leur terme, vous permettant d'intervenir au bon moment selon votre activité. Nous référençons plus de 15 000 projets de ce type chaque année.`,
    forWho: `Sociétés de services (nettoyage de fin de chantier, gardiennage, assureurs, mutuelles, agences de communication...)`,
  },
];

const EasyProjectsFeatures = () => {
  return (
    <VStack
      className="easyProjectsFeatures"
      spacing="40px"
      style={{ margin: 0 }}
    >
      <VStack width="100%" spacing="20px">
        <Text className="easyProjects_headingPrimary">
          Qui utilise nos informations commerciales ?
        </Text>
        <Text className="easyProjectsFeatures_description">
        Chaque activité cible un <strong>intervenant précis</strong> dans le processus de construction afin de <strong>se positionner au moment opportun.</strong> Ainsi, vous pouvez accéder à l’information dès les premiers signaux (bureaux d’études, maîtres d’œuvre...) ou simplement au moment où les différents acteurs - vos futurs interlocuteurs - sont désignés.
        </Text>
      </VStack>
      {features.map((feature, index) => (
        <EasyProjectsFeatureItem
          key={index}
          imageSrc={feature.imageSrc}
          title={feature.title}
          description={feature.description}
          forWho={feature.forWho}
        />
      ))}
    </VStack>
  );
};

export default EasyProjectsFeatures;
