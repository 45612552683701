import keyMirror from "keymirror";

export default keyMirror({
  INIT_SECTORS: null,
  INIT_PLANS: null,
  INIT_EASYPROJECTS_PLANS: null,
  INIT_COMPLETE_PLAN: null,
  SWITCH_PROJECT: null,
  SET_PROJECT: null,
  SET_PLAN_DISCOUNT: null,
});
