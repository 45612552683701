import { PAGE_SIZE } from "../../constants/generalConstants";
import projectsSearchActionTypes from "../actionsTypes/projectsSearchActionTypes";

const initialState = {
  projectsSearchResults: [],
  projectsSearchParams: {},
  projectsSearchStatus: {},
  currentPage: 1,
  pageSize: PAGE_SIZE,
  projectDetails: {},
  isSearchLoading: false,
  totalResults: 0,
};

const setSearchResulsts = (state, payload) => {
  return {
    ...state,
    projectsSearchResults: payload.projects,
    totalResults: payload.totalResults,
  };
};

const setSearchParams = (state, payload) => {
  return { ...state, projectsSearchParams: payload, currentPage:payload.numPage };
};

const setPageSize = (state, payload) => {
  return { ...state, pageSize: payload };
};

const setEssentialDetails = (state, essentialDetails) => {
  return {
    ...state,
    projectDetails: { ...state.projectDetails, essentialDetails },
  };
};

const setAssetTypes = (state, assetTypes) => {
  return {
    ...state,
    projectDetails: { ...state.projectDetails, assetTypes },
  };
};

const setMOAInfos = (state, buildingOwnerInfos) => {
  return {
    ...state,
    projectDetails: { ...state.projectDetails, buildingOwnerInfos },
  };
};

const setBuildingPermit = (state, buildingPermitData) => {
  return {
    ...state,
    projectDetails: { ...state.projectDetails, buildingPermitData },
  };
};

const setProjectBasicInfos = (state, projectCard) => {
  return {
    ...state,
    projectDetails: { ...state.projectDetails, projectCard },
  };
};

const setProjectManagersDetails = (state, projectManagersDetails) => {
  return {
    ...state,
    projectDetails: { ...state.projectDetails, projectManagersDetails },
  };
};

const setSearchLoading = (state, payload) => {
  return {
    ...state,
    isSearchLoading: payload,
  };
};

const setSearchStatus = (state, payload) => {
  return {
    ...state,
    projectsSearchStatus: payload,
  };
};

const setProjectVisualizationData = (state, payload) => {
  return {
    ...state,
    projectVisualizationData: payload,
  };
};

const clearProjectDetails = (state) => {
  return {
    ...state,
    projectDetails: {}
  }
}

const projectsSearchReducer = (state = initialState, action) => {
  switch (action.type) {
    case projectsSearchActionTypes.SET_SEARCH_RESULTS:
      return setSearchResulsts(state, action.payload);

    case projectsSearchActionTypes.SET_SEARCH_PARAMS:
      return setSearchParams(state, action.payload);

    case projectsSearchActionTypes.SET_PAGE_SIZE:
      return setPageSize(state, action.payload);

    case projectsSearchActionTypes.SET_ESSENTIAL_DETAILS:
      return setEssentialDetails(state, action.payload);

    case projectsSearchActionTypes.SET_ASSET_TYPES:
      return setAssetTypes(state, action.payload);

    case projectsSearchActionTypes.SET_MOA_INFOS:
      return setMOAInfos(state, action.payload);

    case projectsSearchActionTypes.SET_BUILDING_PERMIT:
      return setBuildingPermit(state, action.payload);

    case projectsSearchActionTypes.SET_PROJECT_BASIC_INFOS:
      return setProjectBasicInfos(state, action.payload);

    case projectsSearchActionTypes.SET_MOE_INFOS:
      return setProjectManagersDetails(state, action.payload);

    case projectsSearchActionTypes.SEARCH_LOADING:
      return setSearchLoading(state, action.payload);

    case projectsSearchActionTypes.SET_SEARCH_STATUS:
      return setSearchStatus(state, action.payload);

    case projectsSearchActionTypes.SET_PROJECT_VISUALIZATION_DATA:
      return setProjectVisualizationData(state, action.payload);

    case projectsSearchActionTypes.CLEAR_PROJECT_DETAILS:
      return clearProjectDetails(state);

    default:
      return state;
  }
};

export default projectsSearchReducer;
