export const getToken = () => {
  return localStorage.getItem("token");
};

export const setToken = (token) => {
  localStorage.setItem("token", token);
};

export const isTokenExpired = () => {
  try {
    const token = getToken();
    if (!token) return true;
    const parsedToken = JSON.parse(Buffer.from(token.split(".")[1], "base64"));
    return new Date() > (parsedToken?.exp || 0) * 1000;
  } catch (err) {
    return false;
  }
};
