import React from "react";
import { useSelector } from "react-redux";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import IbanForm from "./IbanForm";

const stripeTestPromise = loadStripe(
  process.env.REACT_APP_STRIPE_PUBLIC_KEY_TEST
);

export default function IbanFormContainer({ className }) {
  const { cart } = useSelector((state) => state.orderReducer);
  return (
    <Elements stripe={stripeTestPromise}>
      <IbanForm orderType={cart.type} />
    </Elements>
  );
}
