import favoritesProjectsActionTypes from "../actionsTypes/favoritesProjectsActionTypes";

const initialState = {
  favoritesProjects: [],
};

const initFavoritesProjects = (state, payload) => {
  return { ...state, favoritesProjects: payload };
};

const addFavoriteProject = (state, payload) => {
  const favoritesProjects = [...state.favoritesProjects, payload];
  return { ...state, favoritesProjects };
};

const removeFavoriteProject = (state, payload) => {
  const favoritesProjects = [...state.favoritesProjects].filter(favProj => favProj.id !== payload);
  return { ...state, favoritesProjects };
};

const favoritesProjectsReducer = (state = initialState, action) => {
  switch (action.type) {
    case favoritesProjectsActionTypes.INIT_FAVORITES_PROJECTS:
      return initFavoritesProjects(state, action.payload);

    case favoritesProjectsActionTypes.ADD_PROJECT_TO_FAVORITES:
      return addFavoriteProject(state, action.payload);

    case favoritesProjectsActionTypes.REMOVE_PROJECT_FROM_FAVORITES:
      return removeFavoriteProject(state, action.payload);

    default:
      return state;
  }
};

export default favoritesProjectsReducer;
