import React, { useState } from "react";
import { VStack, HStack, Text, Stack } from "@chakra-ui/react";
import { withRouter } from "react-router-dom";
import PaymentMethod from "./PaymentMethod";
import CustomIcon from "../../shared/Icon/CustomIcon";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { useDispatch, useSelector } from "react-redux";
import { orderTypes } from "../../constants/orderConstants";
import {
  createPayment,
  createPaymentWithSelectedMethod,
  createSubscription,
  createSubscriptionWithPaymentMethod,
} from "../../redux/actionsCreators/paymentActionCreators";
import CustomButton from "../../shared/Button/CustomButton";
import { showNotif } from "../../redux/actionsCreators/appActionCreators";
import { notifMessages } from "../../constants/notificationConstants";
import "./PaymentMethod.css";

const CARD_OPTIONS = {
  iconStyle: "solid",
  style: {
    base: {
      iconColor: "#f39200",
      color: "#1a202c",
      fontSize: "16px",
      fontWeight: "medium",
      fontSmoothing: "antialiased",
      ":-webkit-autofill": { color: "#1a202c" },
      "::placeholder": { color: "#a8a8a8" },
    },
    invalid: {
      color: "#fa755a",
      iconColor: "#fa755a",
    },
  },
};

const PaymentMethodSelector = ({ paymentMethods, location }) => {
  const [paymentFormVisible, setPaymentFormVisible] = useState(false);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null);
  const [paymentInProgress, setPaymentInProgress] = useState(false);
  const { cart } = useSelector((state) => state.orderReducer);

  const orderType = cart.type;
  const selectPaymentMethod = (paymentMethod) => {
    const isSelect =
      paymentMethod && selectedPaymentMethod?.id !== paymentMethod.id;
    setSelectedPaymentMethod(isSelect ? paymentMethod : null);
    if (isSelect) setPaymentFormVisible(!isSelect);
  };

  const toggleCardPaymentForm = () => {
    selectPaymentMethod(null);
    setPaymentFormVisible(!paymentFormVisible);
  };

  const stripe = useStripe();
  const elements = useElements();

  const dispatch = useDispatch();

  const handleSubmit = async () => {
    try {
      const project = location?.state?.project;
      setPaymentInProgress(true);
      if (selectedPaymentMethod?.id) {
        if (orderType === orderTypes.product)
          return await dispatch(
            createPaymentWithSelectedMethod(stripe, selectedPaymentMethod.id)
          );

        if (orderType === orderTypes.subscription)
          return await dispatch(
            createSubscriptionWithPaymentMethod(
              stripe,
              selectedPaymentMethod.id,
              project
            )
          );
      }
      if (paymentFormVisible) {
        if (orderType === orderTypes.product)
          return await dispatch(createPayment(stripe, elements, CardElement));
        if (orderType === orderTypes.subscription)
          return await dispatch(
            createSubscription(stripe, elements, CardElement, project)
          );
      }
      dispatch(showNotif(notifMessages.noPaymentMethodSelected));
    } catch (err) {
    } finally {
      setPaymentInProgress(false);
    }
  };

  return (
    <VStack className="paymentMethods">
      <VStack className="paymentMethods_list">
        {paymentMethods?.map((method) => (
          <PaymentMethod
            key={method.id}
            isSelected={selectedPaymentMethod?.id === method.id}
            paymentMethod={method}
            onSelect={selectPaymentMethod}
          />
        ))}
      </VStack>
      <VStack className="otherCard">
        <VStack
          className={`otherCard_wrapper ${paymentFormVisible ? "visible" : ""}`}
          spacing={"20px"}
        >
          <HStack
            onClick={toggleCardPaymentForm}
            className="otherCard_label"
            spacing="10px"
          >
            <CustomIcon
              name="arrowup"
              className={`otherCard_arrowIcon ${
                paymentFormVisible ? "down" : ""
              }`}
            />
            <Text className="cardPaymentMethod_text--newCard">
              Saisir une nouvelle carte bancaire
            </Text>
          </HStack>

          <VStack
            className={`otherCard_formWrapper ${
              paymentFormVisible ? "visible" : ""
            }`}
          >
            <Stack
              className="otherCard_form"
              justifyContent="center"
              alignItems="center"
              flex={1}
            >
              <CardElement
                className="cardElement_wrapper"
                options={CARD_OPTIONS}
              />
            </Stack>
          </VStack>
        </VStack>
      </VStack>
      <VStack className="paymentMethod_buttonWrapper">
        <CustomButton
          className="paymentMethod_button"
          onClick={handleSubmit}
          isLoading={paymentInProgress}
          loadingText="Paiement en cours"
        >
          Payez
        </CustomButton>
      </VStack>
    </VStack>
  );
};

export default withRouter(PaymentMethodSelector);
