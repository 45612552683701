import React from "react";
import Icon from "@chakra-ui/icon";
import { getIcon } from "../../core/IconResolver";

const CustomIcon = ({ name, className, width, height, ...rest }) => {
  return (
    <Icon
      className={className}
      {...rest}
      width={width || "24px"}
      height={height || "24px"}
      as={getIcon(name)}
    />
  );
};

export default CustomIcon;
