///////////////////// Fonctions communes au projet ////////////////////////

import { PAGE_SIZE, PROJECTS } from "../constants/generalConstants";
import {
  communesList,
  departementsList,
  regionsList,
} from "../constants/searchConstants";
import { userTypes } from "../constants/userConstants";

// Conversion string en minuscule sans accents
export function strConvert(str) {
  var accent = [
    /[\300-\306]/g,
    /[\340-\346]/g, // A, a
    /[\310-\313]/g,
    /[\350-\353]/g, // E, e
    /[\314-\317]/g,
    /[\354-\357]/g, // I, i
    /[\322-\330]/g,
    /[\362-\370]/g, // O, o
    /[\331-\334]/g,
    /[\371-\374]/g, // U, u
    /[\321]/g,
    /[\361]/g, // N, n
    /[\307]/g,
    /[\347]/g, // C, c
    /[’]/g, // ' (ici on considère cet apostrophe particulier comme le caractère accentué de l'apostrophe habituellement utilisé => ')
    /Œ/g,
    /œ/g, // OE, oe
    /Æ/g,
    /æ/g, // AE, ae
  ];
  var noaccent = [
    "A",
    "a",
    "E",
    "e",
    "I",
    "i",
    "O",
    "o",
    "U",
    "u",
    "N",
    "n",
    "C",
    "c",
    "'",
    "OE",
    "oe",
    "AE",
    "ae",
  ];

  for (var i = 0; i < accent.length; i++) {
    str = str.replace(accent[i], noaccent[i]).toLowerCase();
  }

  return str;
}

// Suppression de doublons dans un array simple
export function removeDuplicates(anArray) {
  let unique = {};

  anArray.forEach(function (i) {
    if (!unique[i]) {
      unique[i] = true;
    }
  });

  return Object.keys(unique);
}

// Suppression de doublon dans l'array d'objets des réponses de cloudsearch
export function removeDuplicatesObject(anArray) {
  const newArray = [];
  anArray.forEach((obj) => {
    if (!newArray.some((o) => o.Keyword === obj.Keyword && o.Uid === obj.Uid)) {
      newArray.push(obj);
    }
  });

  return newArray;
}

// Fx nécessaire pour injection HTML
// Exemple d'appel : return <span dangerouslySetInnerHTML={serviceWorker.createMarkup(theCodeHtml)} />
export function createMarkup(myHtml) {
  return {
    __html: myHtml,
  };
}

// Fonction d'encodage / décodage HTML
// cf. https://www.commentcamarche.net/contents/489-caracteres-speciaux-html pour les codes à affecter
export function codingHTML(type, str) {
  var tab = [
    { carSpecial: "@", codeHtml: "&#64;" },
    { carSpecial: "°", codeHtml: "&#176;" },
    { carSpecial: ".", codeHtml: "&#46;" },
    { carSpecial: "-", codeHtml: "&#45;" },
    { carSpecial: " ", codeHtml: "&#32;" },
    { carSpecial: ":", codeHtml: "&#58;" },
    { carSpecial: "'", codeHtml: "&#x27;" },
    { carSpecial: ",", codeHtml: "&#44;" },
    { carSpecial: "€", codeHtml: "&#128;" },
    { carSpecial: "²", codeHtml: "&#178;" },
    { carSpecial: "³", codeHtml: "&#179;" },
    { carSpecial: "µ", codeHtml: "&#181;" },
    { carSpecial: "à", codeHtml: "&#224;" },
    { carSpecial: "â", codeHtml: "&#226;" },
    { carSpecial: "é", codeHtml: "&#233;" },
    { carSpecial: "è", codeHtml: "&#232;" },
    { carSpecial: "ê", codeHtml: "&#234;" },
    { carSpecial: "ë", codeHtml: "&#235;" },
    { carSpecial: "î", codeHtml: "&#238;" },
    { carSpecial: "ï", codeHtml: "&#239;" },
    { carSpecial: "ô", codeHtml: "&#244;" },
    { carSpecial: "û", codeHtml: "&#251;" },
    { carSpecial: "ù", codeHtml: "&#249;" },
    { carSpecial: "ç", codeHtml: "&#231;" },
    { carSpecial: "À", codeHtml: "&#192;" },
    { carSpecial: "Â", codeHtml: "&#194;" },
    { carSpecial: "È", codeHtml: "&#200;" },
    { carSpecial: "É", codeHtml: "&#201;" },
    { carSpecial: "Ê", codeHtml: "&#202;" },
    { carSpecial: "Ë", codeHtml: "&#203;" },
    { carSpecial: "Î", codeHtml: "&#206;" },
    { carSpecial: "Ï", codeHtml: "&#207;" },
    { carSpecial: "Ô", codeHtml: "&#212;" },
    { carSpecial: "Û", codeHtml: "&#219;" },
    { carSpecial: "Ù", codeHtml: "&#217;" },
    { carSpecial: "Ç", codeHtml: "&#199;" },
  ];

  for (let i = 0; i < tab.length; i++) {
    if (type === "encode") {
      let regex = new RegExp(tab[i].carSpecial.replace(".", "\\."), "g");
      str = str.replace(regex, tab[i].codeHtml);
    } else if (type === "decode") {
      let regex = new RegExp(tab[i].codeHtml, "g");
      str = str.replace(regex, tab[i].carSpecial);
    } else {
      console.log(
        "Le type transmis pour encodage/décodage HTML n'est pas valide !"
      );
    }
  }
  return str;
}

export function sendMessage(templateName, msg, idDest, domain) {
  var jsonString = JSON.stringify({
    sender: templateName,
    message: msg,
  });
  if (
    typeof domain === "undefined" ||
    domain === null ||
    domain.trim() === ""
  ) {
    domain = "*";
  }
  console.log(window);
  console.log(jsonString);
  if (
    typeof idDest === "undefined" ||
    idDest === null ||
    idDest.trim() === ""
  ) {
    window.parent.postMessage(jsonString, domain);
  } else {
    document
      .getElementById(idDest)
      .contentWindow.postMessage(jsonString, domain);
  }
}

////////////////////////////// Requêtage PBWS via Fetch /////////////////////////////////////////

export function requestPostJsonFetch(
  url_serveur,
  rq_name,
  rq_params,
  token,
  callback
) {
  let rq_params_text = "";
  if (rq_params.length > 0) {
    rq_params.forEach((param) => {
      rq_params_text += "§" + param;
    });
  }

  var myHeaders = new Headers();
  myHeaders.append("X-Requested-With", "xmlhttprequest");
  myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

  var urlencoded = new URLSearchParams();
  urlencoded.append("format", "JSON");
  urlencoded.append("template", "ReturnJson");
  urlencoded.append("ver", "1");
  urlencoded.append("psql", "PSQL=" + rq_name + rq_params_text);
  urlencoded.append("token", token);

  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: urlencoded,
    redirect: "follow",
  };

  fetch(url_serveur, requestOptions)
    .then((response) => {
      if (response.ok) {
        console.log("Réponse du serveur : " + response.statusText);
        response.json().then((result) => {
          callback(result);
        });
      } else {
        alert(
          "Une erreur est survenue : " +
            response.status +
            " : " +
            response.statusText
        );
      }
    })
    .catch((error) => {
      console.error("Fetch error : ", error);
    });
}

////////////////////////////// Fonctions pour SpyLog /////////////////////////////////////////

export var SpyLogger = {};
SpyLogger.templateName = "";

//renvoie le outerhtml sans les enfants à l'intérieur
function getSimpleOuterHtml(aElement) {
  return aElement.outerHTML.slice(
    0,
    aElement.outerHTML.indexOf(aElement.innerHTML)
  );
}

//retourne un format de message compatible log dans unigui
function getMsgForLogger(aTemplateName, aElement, evtType) {
  let componame = "";
  if (aElement.name) {
    componame = aElement.name;
  } else {
    if (aElement.id) {
      componame = aElement.id;
    } else {
      componame = getSimpleOuterHtml(aElement);
    }
  }
  let MsgForLogger = {};
  MsgForLogger.form_name = aTemplateName;
  MsgForLogger.control_name = componame;
  MsgForLogger.evt_name = evtType;
  return MsgForLogger;
}

//retourne la propriété d'un objet
function havelistener(obj, eventname) {
  if (obj) {
    return obj["on" + eventname];
  } else {
    return false;
  }
}

//permet d'ajouter un log pour un event particulier
function addspylog(aEventName) {
  document.addEventListener(
    aEventName,
    function (event) {
      // console.log("targetevent");
      //  console.log(event.target);
      var firstElemEvent;
      var searchelement = event.target;
      if (havelistener(searchelement, aEventName)) {
        firstElemEvent = searchelement;
      } else {
        while (searchelement) {
          if (havelistener(searchelement, aEventName)) {
            firstElemEvent = searchelement;
            break;
          } else {
            searchelement = searchelement.parentElement;
            //   console.log("searchelement");
            //    console.log(searchelement);
          }
        }
      }
      // console.log("firstElemEvent");
      //  console.log(firstElemEvent);
      if (firstElemEvent) {
        console.log(
          "pbwslogger : ",
          SpyLogger.templateName,
          firstElemEvent,
          aEventName
        );
        sendMessage(
          "pbwslogger",
          btoa(
            JSON.stringify(
              getMsgForLogger(
                SpyLogger.templateName,
                firstElemEvent,
                aEventName
              )
            )
          )
        );
      }
    },
    true
  );
}

export function extractParamsUrl(chaineGET, aparam) {
  chaineGET = chaineGET.split("&");
  var result = "";
  chaineGET.forEach(function (el) {
    var param = el.split("=");
    param[0] = param[0].replace("?", "");
    if (param[0] === aparam) {
      result = param[1];
    }
  });
  return result;
}

//ajoute des logs pour certains events
export function addEventLogger(aTemplateName) {
  console.log("add logger : " + aTemplateName);
  SpyLogger.templateName = aTemplateName;
  addspylog("click");
  addspylog("change");
}

////////////////////////////// Fonctions pour Gestion des droits /////////////////////////////////////////

export var objetDisplayRight = {};

objetDisplayRight.rights = {}; //objet contenant toutes les propriétés

objetDisplayRight.initDisplayRight = function (templateName) {
  sendMessage(templateName, "ACTION=GETDISPLAYRIGHT"); //envoi de la demande

  bindEvent(window, "message", function (e) {
    // todo remettre le ctrl de domaine une fois en prod
    var curDomain = domainFromUrl(e.origin);
    console.log("Controle du domaine : " + curDomain);
    /* if (curDomain !== 'wanao.com' &&curDomain !== 'wanao.fr' && curDomain !== 'sendao.fr') {
            console.error("Réception d'un message provenant d'un domaine non autorisé : " + curDomain);
            return;
        } */
    var msgUnigui;
    try {
      msgUnigui = JSON.parse(e.data);
      switch (msgUnigui.sender) {
        case "SETDISPLAYRIGHT":
          instanciateDisplayRightObject(msgUnigui.message);
          break;
        default:
          return;
      }
    } catch (e) {
      return;
    }
  });
};

function instanciateDisplayRightObject(aJson) {
  /* Rappel liste des droits :
        - SeeAllot
        - SeeAttribOnDemand
        - SeeBpu
        - SeeColUser
        - SeeConfigFiltreAlerte
        - SeeConfigPersoCol
        - SeeConfigRegle
        - SeeDCE
        - SeeDoublonSearch
        - SeeEvolution
        - SeeFctWanao
        - SeeFilterDistant
        - SeeFilterLocal
        - SeeFilterRemote
        - SeeFormulaire
        - SeeFusionDoc
        - SeeGestionClient
        - SeeGoogle
        - SeeGridLot
        - SeeInfoOrganisme
        - SeeMyDoc
        - SeeNewAO
        - SeePersoCol
        - SeePrevisuDCE
        - SeeRegle
        - SeeSendMail
        - SeeStandartAllot
    */

  Object.defineProperty(objetDisplayRight, "rights", {
    value: aJson,
    writable: false,
  });

  try {
    console.log(objetDisplayRight.rights);
  } catch (e) {
    console.error("Droits non gérés dans le template " + e.message);
  }
}

// addEventListener support for IE6-11
export function bindEvent(element, eventName, eventHandler) {
  if (element.addEventListener) {
    element.addEventListener(eventName, eventHandler, false);
  } else if (element.attachEvent) {
    element.attachEvent("on" + eventName, eventHandler);
  }
}

//fonction get domaine from url
function domainFromUrl(url) {
  var result;
  var match;
  var re1 = new RegExp(
    "^(?:https?:\\/\\/)?(?:[^@\\n]+@)?(?:www\\.)?([^:\\/\\n\\?\\=]+)",
    "im"
  );
  var re2 = new RegExp("^[^\\.]+\\.(.+\\..+)$");
  if (match === url.match(re1)) {
    result = match[1];
    if (match === result.match(re2)) {
      result = match[1];
    }
  }
  return result;
}

//// Fonctions de calcul de dates ////
export var getDiffDate = (date1, date2) => {
  const diffInMs = date2 - date1;
  if (diffInMs > 172800000) {
    return (
      "Clôture dans " + (diffInMs / (1000 * 60 * 60 * 24)).toFixed(0) + " jours"
    );
  } else if (diffInMs <= 172800000 && diffInMs > 3600000) {
    return (
      "Clôture dans " + (diffInMs / (1000 * 60 * 60)).toFixed(0) + " heures"
    );
  } else if (diffInMs <= 3600000 && diffInMs > 0) {
    return "Clôture dans " + (diffInMs / (1000 * 60)).toFixed(0) + " minutes";
  } else if (diffInMs <= 0 && diffInMs > -3600000) {
    return (
      "Clôturé depuis " +
      Math.abs(diffInMs / (1000 * 60)).toFixed(0) +
      " minutes"
    );
  } else if (diffInMs <= -3600000 && diffInMs > -172800000) {
    return (
      "Clôturé depuis " +
      Math.abs(diffInMs / (1000 * 60 * 60)).toFixed(0) +
      " heures"
    );
  } else if (diffInMs < -172800000) {
    // si date2 supérieur à 1980 > on affiche
    if (diffInMs > -1000825593816) {
      return (
        "Clôturé depuis " +
        Math.abs(diffInMs / (1000 * 60 * 60 * 24)).toFixed(0) +
        " jours"
      );
    }
    // sinon > on retourne "vide" pour ne pas afficher
    else {
      return "";
    }
  }
};

export var formatFrenchDate = (date) => {
  let frenchDate = date.replace(
    /(\d{4})-(\d{2})-(\d{2})T?[\d:]*[\d+\.Z:\+]*/,
    "$3/$2/$1"
  );
  // let frenchHour = /^.+T([\d:]+)[\d+\.Z]*$/.test(date) ? date.replace(/\d{4}-\d{2}-\d{2}T([\d:]+)[\d+\.Z]*/, " à $1") : ""
  return frenchDate;
};

/////////////// Fonctions pour controler l'exécution multiple ///////////

let debounceTimer;

export const debounce = (callback, time) => {
  window.clearTimeout(debounceTimer);
  debounceTimer = window.setTimeout(callback, time);
};

////////////// Fonctions pour formater les localisations sélectionnés en listLocality //////////////////

export const formatSelectedLocationsToListLocality = (selectedLocations) => {
  return {
    City: selectedLocations
      .filter((item) => item.type === "common")
      .map((item) => parseInt(item.value)),
    Dep: selectedLocations
      .filter((item) => item.type === "depart")
      .map((item) => parseInt(item.value)),
    Region: selectedLocations
      .filter((item) => item.type === "region")
      .map((item) => parseInt(item.value)),
  };
};

////////////// Fonctions pour récupérer les localisations à partir de listLocality //////////////////

const defaultListLocality = { City: [], Dep: [], Region: [] };

export const getSelectedLocationsFromListLocality = ({
  City,
  Dep,
  Region,
} = defaultListLocality) => {
  const cities = City?.map((cityCode) =>
    communesList.find((item) => item.value === cityCode?.toString())
  );

  const departs = Dep?.map((depCode) =>
    departementsList.find((item) => item.value === depCode?.toString())
  );

  const regions = Region?.map((regionCode) =>
    regionsList.find((item) => item.value === regionCode?.toString())
  );

  return [...cities, ...departs, ...regions];
};

///////////// Fonction pour vérifier si les paramètres de recherche sont vides ///////////////////

const defaultSearchParams = {
  listKeyWords: [],
  listLocality: {
    City: [],
    Dep: [],
    Region: [],
  },
  projWorkStartDate: {
    valueStart: "",
    valueEnd: "",
  },
  projWorkEndDate: {
    valueStart: "",
    valueEnd: "",
  },
  projNature: [],
  workType: [],
  lotNumber: [],
  totalArea: [],
};

export const areSearchParametersEmpty = (searchParams = {}) => {
  const {
    listKeyWords,
    listLocality,
    lotNumber,
    projNature,
    projWorkEndDate,
    projWorkStartDate,
    totalArea,
    workType,
  } = { ...defaultSearchParams, ...searchParams };

  if (
    !listKeyWords?.length &&
    !listLocality?.City?.length &&
    !listLocality?.Dep?.length &&
    !listLocality?.Region?.length &&
    !lotNumber?.length &&
    !projNature?.length &&
    !projWorkStartDate?.valueStart &&
    !projWorkStartDate?.valueEnd &&
    !projWorkEndDate?.valueStart &&
    !projWorkEndDate?.valueEnd &&
    !totalArea?.length &&
    !workType?.length
  )
    return true;
  return false;
};

///////////// Fonction pour récupérer le plan d'abonnement premium de l'utilisateur ///////////////////

export const getUserPremiumPlan = (user) => {
  if (
    user?.type === userTypes.premium &&
    user?.easyProjectsType !== userTypes.premium
  )
    return PROJECTS.easyao;

  if (
    user?.type !== userTypes.premium &&
    user?.easyProjectsType === userTypes.premium
  )
    return PROJECTS.easyprojects;

  if (
    user?.type === userTypes.premium &&
    user?.easyProjectsType === userTypes.premium
  )
    return "complete";

  return false;
};

//////////////////// Fonction pour récupérer les credtis MOEs d'un utilisateur ///////////

export const getUserMoeCredits = (user) => {
  const params = user?.easyProjectsParameters;
  const nmbrMOEAccessible = params?.nmbrMOEAccessible || 0;
  const purchasedMoeNumber = params?.purchasedMoeNumber || 0;
  return nmbrMOEAccessible + purchasedMoeNumber;
};

/////////////////  Fonction pour formater les paramètres de recherche ////////////

export const formatSearchParams = (params) => {
  const searchParams = {
    ...defaultSearchParams,
    numPage: 1,
    pageSize: PAGE_SIZE,
    ...params,
  };
  delete searchParams?.lastSearchParamsChange;
  const lotNumber = searchParams.lotNumber?.map((i) => parseInt(i));
  const totalArea = searchParams.totalArea?.map((i) => parseInt(i));
  const projWorkStartDate = { ...(searchParams?.projWorkStartDate || {}) };
  const projWorkEndDate = { ...(searchParams?.projWorkEndDate || {}) };
  const numPage = searchParams?.numPage ? searchParams.numPage - 1 : 0;

  projWorkStartDate.valueStart = !searchParams?.projWorkStartDate?.valueStart
    ? null
    : searchParams?.projWorkStartDate?.valueStart;
  projWorkStartDate.valueEnd = !searchParams?.projWorkStartDate?.valueEnd
    ? null
    : searchParams?.projWorkStartDate?.valueEnd;

  projWorkEndDate.valueStart = !searchParams?.projWorkEndDate?.valueStart
    ? null
    : searchParams?.projWorkEndDate?.valueStart;
  projWorkEndDate.valueEnd = !searchParams?.projWorkEndDate?.valueEnd
    ? null
    : searchParams?.projWorkEndDate?.valueEnd;

  const listKeyWords = searchParams?.listKeyWords.map((keyWord) =>
    keyWord.replace("+", ";")
  );

  if (lotNumber.length > 0) {
    if (isNaN(lotNumber[0])) lotNumber[0] = 0;
    if (isNaN(lotNumber[1])) lotNumber[1] = 999999;
  }
  if (totalArea.length > 0) {
    if (isNaN(totalArea[0])) totalArea[0] = 0;
    if (isNaN(totalArea[1])) totalArea[1] = 999999;
  }

  return {
    ...searchParams,
    lotNumber,
    totalArea,
    listKeyWords,
    projWorkStartDate,
    projWorkEndDate,
    numPage,
  };
};
