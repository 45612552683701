import React from "react";
import { Button } from "@chakra-ui/react";

const CustomButton = ({
  text,
  icon,
  backgroundColor,
  color,
  disabled,
  onClick,
  children,
  isLoading,
  loadingText,
  size,
  padding,
  borderRadius,
  ...restProps
}) => {
  const focusHoverStyle = {
    outline: "none",
    boxShadow: "0 4px 12px rgba(0, 0, 0, 0.3)",
    transform: "translateY(-3px)",
  };

  const activeStyle = {
    backgroundColor,
    color,
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.3)",
    transform: "translateY(-2px)",
  };

  const leftIcon = icon
    ? React.cloneElement(icon, {
        fill: color,
      })
    : undefined;

  const sizeMapping = {
    xs: {
      fontSize: "12px",
      padding: "10px 20px",
    },
    sm: {
      fontSize: "14px",
      padding: "15px 20px",
    },
    md: {
      fontSize: "16px",
      padding: "20px 25px",
    },
    lg: {
      fontSize: "18px",
      padding: "22px 20px",
    },
  };

  return (
    <Button
      iconSpacing="9px"
      leftIcon={leftIcon}
      fontSize={sizeMapping[size || "lg"].fontSize}
      padding={padding ? padding : sizeMapping[size || "lg"].padding}
      backgroundColor={backgroundColor}
      color={color}
      borderRadius={borderRadius || 30}
      transform="translateY(-1px)"
      boxShadow="0 4px 6px rgba(0, 0, 0, 0.3)"
      className="button"
      size={size}
      _active={activeStyle}
      _hover={focusHoverStyle}
      _focus={focusHoverStyle}
      disabled={disabled || isLoading}
      isLoading={isLoading}
      onClick={onClick}
      value={text}
      loadingText={loadingText}
      {...restProps}
    >
      {children}
    </Button>
  );
};

CustomButton.defaultProps = {
  color: "#fff",
  backgroundColor: "#f39200",
};

export default CustomButton;
