import React, { useEffect, lazy, Suspense } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Stack } from "@chakra-ui/react";
import { Switch, Route, withRouter, Redirect, useLocation } from "react-router-dom";
import { userTypes } from "../constants/userConstants";
import HomeRoute from "./HomeRoute";
import AccountRoutes from "./AccountRoutes";
import OrderStepsRoutes from "./OrderStepsRoutes";
import PrivateRoute from "./PrivateRoute";
import { hideAllNotifs } from "../redux/actionsCreators/appActionCreators";
import { closeUserSession } from "../redux/actionsCreators/userActionCreators";
import { isTokenExpired } from "../helpers/sessionHelper";
import appActionTypes from "../redux/actionsTypes/appActionTypes";
import ErrorPage from "../pages/ErrorPages/ErrorPage";
import LegalNotice from "../pages/LegalNotice/LegalNotice";
import GeneralConditionsOfUse from "../components/GeneralConditionsOfUse/GeneralConditionsOfUse";
import GeneralConditionsOfSale from "../pages/GeneralConditionsOfSale/GeneralConditionsOfSale";

const SearchEnginePage = lazy(() =>
  import("../pages/SearchEnginePage/SearchEnginePage")
);
const DetailFichePage = lazy(() =>
  import("../pages/DetailFichePage/DetailFichePage")
);
const StorePage = lazy(() => import("../pages/StorePage/StorePage"));
const CartPage = lazy(() => import("../pages/CartPage/CartPage"));
const Page404 = lazy(() => import("../pages/ErrorPages/Page404"));
const PaymentStatusPage = lazy(() =>
  import("../pages/PaymentStatusPage/PaymentStatusPage")
);
// const FavoritesAOPage = lazy(() =>
//   import("../pages/FavoritesAOPage/FavoritesAOPage")
// );
const FavoritesPage = lazy(() => import("../pages/FavoritesPage/FavoritesPage"));

const Main = ({ history, location }) => {
  const dispatch = useDispatch();
  const { isAuthenticated } = useSelector((state) => state.userReducer);
  const { routingData } = useSelector((state) => state.appReducer);

    useEffect(() => {
      window.gtag("event", "page_view", {
        page_path: location.pathname + location.search + location.hash,
        page_search: location.search,
        page_hash: location.hash,
      });
    }, [location]);


  useEffect(() => {
    dispatch({
      type: appActionTypes.INIT_ROUTING_DATA,
      payload: { history, location },
    });
    if (history.length > 1) dispatch(hideAllNotifs());
    if (isAuthenticated && isTokenExpired()) {
      dispatch(closeUserSession());
    }
    return () => {};
  }, [dispatch, location, history, isAuthenticated]);


  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location?.pathname]);

  return (
    <Suspense fallback={<div></div>}>
      {routingData && (
        <Stack className="main" flex={1} width="100%">
          <Switch>
            <Route path="/404" component={Page404} />
            <Route exact path="/" component={HomeRoute} />
            <Route exact path="/search" component={SearchEnginePage} />
            <Route exact path="/detail/:ref" component={DetailFichePage} />
            <Route exact path="/shop" component={StorePage} />
            <PrivateRoute
              roles={[userTypes.premium]}
              exact
              path="/cart"
              component={CartPage}
            />
            <Route path="/cart/order" component={OrderStepsRoutes} />
            <Route path="/account" component={AccountRoutes} />
            <Route path="/paymentstatus" component={PaymentStatusPage} />
            <Route path="/favorites" component={FavoritesPage} />
            <Route path="/legalNotice" component={LegalNotice} />
            <Route
              path="/generalConditionsOfUse"
              component={GeneralConditionsOfUse}
            />
            <Route
              path="/generalConditionsOfSale"
              component={GeneralConditionsOfSale}
            />
            <Route path="/error" component={ErrorPage} />
            <Redirect to="/404" />
          </Switch>
        </Stack>
      )}
    </Suspense>
  );
};
export default withRouter(Main);
