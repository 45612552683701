import React from "react";
import { Checkbox, FormControl, FormErrorMessage } from "@chakra-ui/react";
import { Field } from "formik";
import "./CustomCheckbox.css";

const CustomCheckbox = ({ name, children, onClick, ...rest }) => {
  return (
    <Field name={name} type="checkbox">
      {({ field, form }) => {
        return (
          <FormControl onClick={onClick} isInvalid={form.errors[name]}>
            <Checkbox
              className={`customCheckbox`}
              {...field}
              {...rest}
              defaultIsChecked={field.checked}
            >
              {children}
            </Checkbox>
            <FormErrorMessage marginTop="-2px">
              {form.errors[name]}
            </FormErrorMessage>
          </FormControl>
        );
      }}
    </Field>
  );
};

export default CustomCheckbox;
