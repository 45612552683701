import React from "react";
import { VStack, HStack, Text, Divider } from "@chakra-ui/react";
import CustomIcon from "../../shared/Icon/CustomIcon";

const FeatureItem = ({ iconName, title, paragraph }) => {
  return (
    <HStack className="feature" spacing="30px">
      <CustomIcon className="feature_icon" name={iconName} />
      <Divider className="feature_divider" orientation="vertical" />
      <VStack className="feature_content">
        <Text className="feature_title">{title}</Text>
        <Text className="feature_paragraph">{paragraph}</Text>
      </VStack>
    </HStack>
  );
};

export default FeatureItem;
