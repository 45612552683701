import React from "react";
import { useSelector } from "react-redux";
import EasyAOHome from "../../components/EasyAOHome/EasyAOHome";
import EasyProjectsHome from "../../components/EasyProjectsHome/EasyProjectsHome";
import { PROJECTS } from "../../constants/generalConstants";

const HomePage = ({ history }) => {
  const { currentProject } = useSelector((state) => state.generalReducer);
  return currentProject === PROJECTS.easyao ? (
    <EasyAOHome history={history} />
  ) : (
    <EasyProjectsHome history={history} />
  );
};

export default HomePage;
