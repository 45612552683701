import store from "../redux/store";

export const getRouterLocation = () => {
  const { routingData } = store.getState().appReducer;
  return routingData?.location;
};

export const getRouterHistory = () => {
  const { routingData } = store.getState().appReducer;
  return routingData?.history;
};

export const getUrlParams = () => {
  const location = getRouterLocation();
  if (!location) return {};
  return location.search.split("?").reduce((prev, acc) => {
    const key = acc.split("=")[0];
    const value = acc.split("=")[1];
    if (key) prev[key] = value;
    return prev;
  }, {});
};
