import React from "react";
import { Stack, VStack, Divider, HStack, Spacer, Text } from "@chakra-ui/react";
import CustomInput from "../../shared/Input/CustomInput";
import CustomSelect from "../../shared/Select/CustomSelect";
import CustomForm from "../../shared/Form/CustomForm";
import CustomButton from "../../shared/Button/CustomButton";
import { useDispatch, useSelector } from "react-redux";
import {
  setPendingUserData,
  updateUserData,
} from "../../redux/actionsCreators/userActionCreators";
import { editBillingInfosSchema } from "../../validation/UserSchemas";
import { navigateToOrderStep } from "../../redux/actionsCreators/orderActionCreators";
import { orderSteps } from "../../constants/orderConstants";
import { userTypes } from "../../constants/userConstants";
import { PROJECTS } from "../../constants/generalConstants";
import Banner from "../../includes/Banner/Banner";

const OrderStepTwoPage = ({ location }) => {
  const dispatch = useDispatch();
  const { user, pendingUser } = useSelector((state) => state.userReducer);
  //pendingUser sont les données de user saisies dans le step1
  const userData = { ...user, ...pendingUser };
  const { sectors } = useSelector((state) => state.generalReducer);
  const sectorOptions = sectors.map((sector) => ({
    key: sector.id,
    value: sector.title,
  }));

  const project = location?.state?.project;

  const userType =
    project === PROJECTS.easyao ? user?.type : user?.easyProjectsType;

  const handleSubmit = async (values, actions) => {
    const isOrder = true;
    await dispatch(
      updateUserData({ ...userData, ...values }, isOrder, project)
    );
    actions.setSubmitting(false);
  };

  const handleChange = (e) => {
    userData[e.target.name] = e.target.value;
    dispatch(setPendingUserData(userData));
  };

  const navigateToStepOne = () => {
    dispatch(navigateToOrderStep(orderSteps.step1, project));
  };

  const initialValues = {
    entrepriseName: userData.entrepriseName || "",
    sector: userData.sector || "",
    siret: userData.siret || "",
    billingEmail: userData.billingEmail || "",
    address: userData.address || "",
    cp: userData.cp || "",
    city: userData.city || "",
    phone: userData.phone || "",
    website: userData.website || "",
  };

  return (
    <Stack flex={1}>
      <VStack flex={1}>
        <Banner
          title={
            userType === userTypes.discovery
              ? "Inscription"
              : ["Vérification", "des informations"]
          }
          secondaryTitle="Etape 2"
        />
        <VStack className="account_content">
          <VStack className="accountInfos_section">
            <Text className="accountInfos_title">Adresse de facturation</Text>
            <Divider className="accountInfos_divider" />
            <CustomForm
              initialValues={initialValues}
              validationSchema={editBillingInfosSchema}
              enableReinitialize
              onSubmit={handleSubmit}
              onChange={handleChange}
            >
              {(formProps) => {
                return (
                  <VStack className="form">
                    <HStack className="form_row">
                      <CustomInput
                        id="entrepriseName"
                        name="entrepriseName"
                        placeholder="Entrer le nom de votre enterprise"
                      />
                      <CustomSelect
                        id="sector"
                        name="sector"
                        placeholder="Sélectionner le secteur"
                        options={sectorOptions}
                      />
                    </HStack>

                    <HStack className="form_row">
                      <CustomInput
                        id="siret"
                        name="siret"
                        placeholder="SIRET*"
                      />
                      <CustomInput
                        id="billingEmail"
                        name="billingEmail"
                        placeholder="Email de facturation"
                        type="email"
                      />
                    </HStack>
                    <HStack className="form_row">
                      <CustomInput
                        id="address"
                        name="address"
                        placeholder="Adresse"
                      />
                    </HStack>
                    <HStack className="form_row">
                      <CustomInput
                        id="cp"
                        name="cp"
                        type="number"
                        placeholder="CP"
                      />
                      <CustomInput id="city" name="city" placeholder="Ville" />
                    </HStack>
                    <HStack className="form_row">
                      <CustomInput
                        id="phone"
                        name="phone"
                        placeholder="Téléphone"
                      />
                      <CustomInput
                        id="website"
                        name="website"
                        placeholder="Site web"
                      />
                    </HStack>
                    <HStack className="form_row step_buttons">
                      <CustomButton onClick={navigateToStepOne}>
                        Précédent
                      </CustomButton>
                      <Spacer />
                      <CustomButton
                        loadingText="Suivant"
                        isLoading={formProps.isSubmitting}
                        type="submit"
                      >
                        Suivant
                      </CustomButton>
                    </HStack>
                  </VStack>
                );
              }}
            </CustomForm>
          </VStack>
        </VStack>
      </VStack>
    </Stack>
  );
};

export default OrderStepTwoPage;
