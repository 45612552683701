import { orderTypes } from "../../constants/orderConstants";
import cartActionTypes from "../actionsTypes/cartActionTypes";

const emptyCart = {
  products: [],
  currencyCode: "eur",
  totalHt: 0,
  tvaTotal: 0,
  type: orderTypes.product,
};

const initialState = {
  orders: [],
  cart: emptyCart,
  cartId: localStorage.getItem("cartId"),
  pendingOrderStep: false,
  ordersInitializedAt: null,
  tva: null,
  subscriptionOrders: null,
  promoCodeData: null,
  isUpdatingCart: false,
  pendingRemovedItems: {},
};

const initOrders = (state, payload) => {
  return { ...state, orders: payload, ordersInitializedAt: new Date() };
};

const initCart = (state, payload) => {
  if (payload?.id) localStorage.setItem("cartId", payload.id);
  return {
    ...state,
    cart: payload || emptyCart,
    cartId: payload?.id,
  };
};

const updateCart = (state, payload) => {
  if (payload?.id) localStorage.setItem("cartId", payload.id);
  return {
    ...state,
    cart: payload || emptyCart,
    cartId: payload?.id,
  };
};

const addCartItem = (state, payload) => {
  const products = state.cart.products || [];
  const updatedProducts = [...products];
  const { item } = payload;

  const existingProductIndex = products.findIndex((p) => p.id === item.id);
  const existingProduct = products[existingProductIndex];

  if (existingProduct) {
    const updatedProduct = {
      ...existingProduct,
      ...item,
      OrderProduct: {
        ...existingProduct.OrderProduct,
        qty:
          parseInt(existingProduct.OrderProduct.qty) +
          parseInt(item.OrderProduct.qty),
      },
    };
    updatedProducts.splice(existingProductIndex, 1, updatedProduct);
  } else {
    updatedProducts.push(item);
  }

  return {
    ...state,
    cart: {
      ...state.cart,
      products: updatedProducts,
    },
  };
};

const updateCartItemQty = (state, payload) => {
  const products = state.cart?.products || [];
  const productIndex = products.findIndex(
    (item) => item.OrderProduct.id === payload.itemId
  );

  const cartItem = products[productIndex];

  const updatedCartItem = {
    ...cartItem,
    OrderProduct: {
      ...(cartItem?.OrderProduct || {}),
      qty: parseInt(payload.qty),
    },
  };

  const updatedProducts = [...products];
  updatedProducts.splice(productIndex, 1, updatedCartItem);

  return {
    ...state,
    cart: {
      ...state.cart,
      products: updatedProducts,
    },
  };
};
const removeItemFromCart = (state, payload) => {
  const id = payload;
  const products = state.cart?.products || [];
  const updatedProducts = [...products].filter((item) => item.id !== id);

  return {
    ...state,
    cart: {
      ...state.cart,
      products: updatedProducts,
    },
  };
};
const clearCart = (state) => {
  localStorage.removeItem("cartId");
  return { ...state, cart: emptyCart, cartId: null };
};

const cancelOrder = (state, orderId) => {
  const orders = [...state.orders];

  if (state.cart?.id === orderId) {
    const order = {
      ...state.cart,
      state: "canceled",
    };
    orders.unshift(order);
    localStorage.removeItem("cartId");
    return { ...state, orders, cart: emptyCart, cartId: null };
  }
  const orderIndex = orders.findIndex((ord) => ord.id === orderId);
  if (orderIndex > -1) {
    const order = orders[orderIndex];
    orders.splice(orderIndex, 1, { ...order, state: "canceled" });
    return { ...state, orders };
  }

  return state;
};

const setSubscriptionOrders = (state, payload) => {
  return { ...state, subscriptionOrders: payload };
};

const setPendingOrderStep = (state, payload) => {
  return { ...state, pendingOrderStep: payload };
};

const initTVA = (state, payload) => {
  return { ...state, tva: payload };
};

const setPromoCode = (state, payload) => {
  return { ...state, promoCodeData: payload };
};

const clearPromoCode = (state) => {
  return { ...state, promoCodeData: null };
};

const setUpdateCartPending = (state, paylaod) => {
  return { ...state, isUpdatingCart: paylaod };
};

const setPendingRemovedItems = (state, paylaod) => {
  const pendingRemovedItems = {
    ...state.pendingRemovedItems,
    ...(paylaod || {}),
  };

  Object.keys(pendingRemovedItems).forEach((itemId) => {
    if (!pendingRemovedItems[itemId]) delete pendingRemovedItems[itemId];
  });

  return {
    ...state,
    pendingRemovedItems,
  };
};

const orderReducer = (state = initialState, action) => {
  switch (action.type) {
    case cartActionTypes.INIT_ORDERS:
      return initOrders(state, action.payload);

    case cartActionTypes.INIT_CART:
      return initCart(state, action.payload);

    case cartActionTypes.UPDATE_CART:
      return updateCart(state, action.payload);

    case cartActionTypes.ADD_CART_ITEM:
      return addCartItem(state, action.payload);

    case cartActionTypes.UPDATE_CART_ITEM_QTY:
      return updateCartItemQty(state, action.payload);

    case cartActionTypes.REMOVE_CART_ITEM:
      return removeItemFromCart(state, action.payload);

    case cartActionTypes.CLEAR_CART:
      return clearCart(state);

    case cartActionTypes.CANCEL_ORDER:
      return cancelOrder(state, action.payload);

    case cartActionTypes.SET_PENDING_SUBSCRIPTION:
      return setPendingOrderStep(state, action.payload);

    case cartActionTypes.INIT_SUBSCRIPTION_ORDERS:
      return setSubscriptionOrders(state, action.payload);

    case cartActionTypes.INIT_TVA:
      return initTVA(state, action.payload);

    case cartActionTypes.SET_PROMOCODE:
      return setPromoCode(state, action.payload);

    case cartActionTypes.CLEAR_PROMOCODE:
      return clearPromoCode(state, action.payload);

    case cartActionTypes.UPDATE_CART_PENDING:
      return setUpdateCartPending(state, action.payload);

    case cartActionTypes.REMOVE_ITEM_PENDING:
      return setPendingRemovedItems(state, action.payload);

    default:
      return state;
  }
};

export default orderReducer;
