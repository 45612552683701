import keyMirror from "keymirror";

/**
 * Les types de la commande
 * @readonly
 * @enum {string}
 */
export const orderTypes = keyMirror({
  product: null,
  subscription: null,
});

/**
 * Les étapes du commande
 * @readonly
 * @enum {string}
 */
export const orderSteps = keyMirror({
  step1: null,
  step2: null,
  step3: null,
});
