import Queue from "promise-queue";
const queues = {};
const maxConcurrent = 1;
const maxQueue = Infinity;

const createQueue = (identifier) => {
  const queue = new Queue(maxConcurrent, maxQueue);
  queues[identifier] = queue;
  return queue;
};

const getQueue = (identifier) => {
  return queues[identifier];
};

export const addToQueue = (queueIdentifier, taskCallback) => {
  const queue = getQueue(queueIdentifier) || createQueue(queueIdentifier);
  return queue.add(() => {
    if (typeof taskCallback === "function") return taskCallback();
  });
};
