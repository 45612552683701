import appActionTypes from "../actionsTypes/appActionTypes";
import { v4 as uuidV4 } from "uuid";
const initialState = {
  isLoading: true,
  notifs: [],
};

const setLoading = (state, payload) => {
  return { ...state, isLoading: payload };
};

const showNotif = (state, notif) => {
  if (state.notifs.map((notif) => notif.message).includes(notif.message))
    return state;
  notif.id = uuidV4();
  return { ...state, notifs: [...state.notifs, notif] };
};

const hideNotif = (state, notifId) => {
  const notifs = state.notifs.filter((notif) => notif.id !== notifId);
  return { ...state, notifs };
};

const hideAllNotifs = (state) => {
  return { ...state, notifs: [] };
};

const initRoutingData = (state, payload) => {
  return { ...state, routingData: payload };
};

const appReducer = (state = initialState, action) => {
  switch (action.type) {
    case appActionTypes.SET_LOADING:
      return setLoading(state, action.payload);

    case appActionTypes.SHOW_NOTIF:
      return showNotif(state, action.payload);

    case appActionTypes.HIDE_NOTIF:
      return hideNotif(state, action.payload);

    case appActionTypes.HIDE_ALL_NOTIFS:
      return hideAllNotifs(state);

    case appActionTypes.INIT_ROUTING_DATA:
      return initRoutingData(state, action.payload);

    default:
      return state;
  }
};

export default appReducer;
