import { RiErrorWarningLine, RiSearchFill, RiSendPlaneFill, RiVisaLine } from "react-icons/ri";
import { GiArcheryTarget } from "react-icons/gi";
import {
  FaStore,
  FaShoppingCart,
  FaUserCircle,
  FaFolderOpen,
  FaBuilding,
  FaPlay,
  FaStop,
  FaEnvelope,
  FaUserFriends,
  FaMoneyBillWave,
  FaChartPie,
} from "react-icons/fa";
import {
  AiFillHome,
  AiFillTrophy,
  AiFillCheckSquare,
  AiFillInfoCircle,
  AiFillCloseCircle,
  AiFillCheckCircle,
  AiOutlineCheck,
  AiOutlineDoubleRight,
  AiFillPhone,
  AiOutlineSafetyCertificate,
  AiOutlineExpand,
  AiOutlineCompress,
} from "react-icons/ai";
import { IoIosNotifications, IoIosArrowUp } from "react-icons/io";
import { BiLineChart, BiCart, BiWorld } from "react-icons/bi";
import {
  BsFillFileEarmarkPdfFill,
  BsCreditCard2Back,
  BsCalendarPlus,
  BsStar,
  BsStarFill,
  BsFillShareFill,
  BsCalendar3Event,
  BsCalendar2X,
} from "react-icons/bs";
import {
  IoWarning,
  IoClose,
  IoLocationOutline,
} from "react-icons/io5";
import { MdAccountBalance, MdCake, MdDelete, MdError, MdLocationPin } from "react-icons/md";
import { FiEdit } from "react-icons/fi";
import { CgSpinner } from "react-icons/cg";
import { HiOutlineSearch } from "react-icons/hi";
import { GoChevronLeft, GoChevronRight } from "react-icons/go";
import { BsFillArrowRightCircleFill } from "react-icons/bs";

const iconsByNames = {
  home: AiFillHome,
  search: RiSearchFill,
  outlineSearch: HiOutlineSearch,
  store: FaStore,
  cart: FaShoppingCart,
  user: FaUserCircle,
  target: GiArcheryTarget,
  folder: FaFolderOpen,
  trophy: AiFillTrophy,
  alert: IoIosNotifications,
  chart: BiLineChart,
  chartPie: FaChartPie,
  check: AiFillCheckSquare,
  outlineCheck: AiOutlineCheck,
  visa: RiVisaLine,
  pdf: BsFillFileEarmarkPdfFill,
  warning: IoWarning,
  warningCircle: RiErrorWarningLine,
  info: AiFillInfoCircle,
  error: AiFillCloseCircle,
  exclamation: MdError,
  close: IoClose,
  success: AiFillCheckCircle,
  outlineCart: BiCart,
  delete: MdDelete,
  creditCard: BsCreditCard2Back,
  edit: FiEdit,
  send: RiSendPlaneFill,
  star: BsStarFill,
  starOutline: BsStar,
  arrowup: IoIosArrowUp,
  spinner: CgSpinner,
  location: MdLocationPin,
  locationOutline: IoLocationOutline,
  building: FaBuilding,
  share: BsFillShareFill,
  calendarPlus: BsCalendarPlus,
  calendarStart: BsCalendar3Event,
  calendarEnd: BsCalendar2X,
  play: FaPlay,
  stop: FaStop,
  arrowLeft: GoChevronLeft,
  arrowRight: GoChevronRight,
  arrowRightCircle: BsFillArrowRightCircleFill,
  doubleRight:AiOutlineDoubleRight,
  phone: AiFillPhone,
  envelope: FaEnvelope,
  world: BiWorld,
  birthdayCake: MdCake,
  group: FaUserFriends,
  money: FaMoneyBillWave,
  certificate: AiOutlineSafetyCertificate,
  bank: MdAccountBalance,
  expand: AiOutlineExpand,
  compress: AiOutlineCompress
};

export const getIcon = (name) => {
  if (!iconsByNames[name]) return false;
  return iconsByNames[name];
};
