import React from "react";
import { Box, HStack, Image, Text } from "@chakra-ui/react";
import headerBackgroundImg from "../../assets/images/header_background.png";

const Banner = ({ title, secondaryTitle }) => {
  return (
    <HStack width="100%" height="140px" position="relative">
      <Image src={headerBackgroundImg} height="100%" />
      <Box position="absolute" left="50px">
        {typeof title === "string" ? (
          <Text
            color="#fff"
            fontWeight="extrabold"
            lineHeight="46px"
            fontSize="40px"
          >
            {title.toUpperCase()}
          </Text>
        ) : (
          title.map((item) => (
            <Text
              color="#fff"
              fontWeight="extrabold"
              lineHeight="46px"
              fontSize="40px"
            >
              {item.toUpperCase()}
            </Text>
          ))
        )}
      </Box>

      {secondaryTitle && (
        <Text
          position="absolute"
          left="50%"
          transform="translateX(-50%)"
          bottom="20px"
          fontSize="30px"
        >
          {secondaryTitle}
        </Text>
      )}
    </HStack>
  );
};

export default Banner;
