import generalHttpApi from "../../api/generalHttpApi";
import { CACHE_TIME } from "../../constants/generalConstants";
import generalActionTypes from "../actionsTypes/generalActionTypes";
import { setLoading } from "./appActionCreators";
import { initCart, initTVA } from "./orderActionCreators";
import { initUser } from "./userActionCreators";

/**
 *  Récupérer les secteurs d'activités
 *
 * @async
 */
export const initSectors = () => async (dispatch, getState) => {
  try {
    const { sectorsInitializedAt } = getState().generalReducer;
    if (sectorsInitializedAt && new Date() - sectorsInitializedAt < CACHE_TIME)
      return;
    const res = await generalHttpApi.getSectors();
    dispatch({
      type: generalActionTypes.INIT_SECTORS,
      payload: res?.data?.data,
    });
  } catch (err) {}
};

/**
 *  Récupérer les offres
 *
 * @async
 */
export const initPlans = () => async (dispatch, getState) => {
  try {
    const { plansInitializedAt } = getState().generalReducer;
    if (plansInitializedAt && new Date() - plansInitializedAt < CACHE_TIME)
      return;
    const res = await generalHttpApi.getPlans();
    dispatch({
      type: generalActionTypes.INIT_PLANS,
      payload: res?.data?.data,
    });
  } catch (err) {}
};

/**
 *  Récupérer les offres
 *
 * @async
 */
export const initEasyProjectsPlans = () => async (dispatch, getState) => {
  try {
    const { easyProjectsPlansInitializedAt } = getState().generalReducer;
    if (
      easyProjectsPlansInitializedAt &&
      new Date() - easyProjectsPlansInitializedAt < CACHE_TIME
    )
      return;
    const res = await generalHttpApi.getEasyProjectsPlans();
    dispatch({
      type: generalActionTypes.INIT_EASYPROJECTS_PLANS,
      payload: res?.data?.data,
    });
  } catch (err) {}
};

/**
 *  Récupérer le plan complet
 *
 * @async
 */
export const initCompletePlan = () => async (dispatch, getState) => {
  try {
    const { completePlanInitializedAt } = getState().generalReducer;
    if (
      completePlanInitializedAt &&
      new Date() - completePlanInitializedAt < CACHE_TIME
    )
      return;
    dispatch(setLoading(true));
    const res = await generalHttpApi.getCompletePlan();
    dispatch(setLoading(false));
    dispatch({
      type: generalActionTypes.INIT_COMPLETE_PLAN,
      payload: res?.data?.data,
    });
  } catch (err) {
    dispatch(setLoading(false));
  }
};

/**
 *  Changer le projet (EasyAO vers EasyProjects)
 *
 * @async
 */
export const switchProject = () => async (dispatch, getState) => {
  dispatch({
    type: generalActionTypes.SWITCH_PROJECT,
  });
};

/**
 *  Récupérer la remise sur les plans pour les utilisateurs qui ont déjà un abonnement premium
 *
 * @async
 */
export const initPlansDiscount = () => async (dispatch, getState) => {
  const res = await generalHttpApi.getPlansDiscountForSubscribed();
  dispatch({
    type: generalActionTypes.SET_PLAN_DISCOUNT,
    payload: res?.data?.data,
  });
};

export const bootApp = () => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    await Promise.all([
      dispatch(initUser()),
      dispatch(initSectors()),
      dispatch(initCart()),
      dispatch(initTVA()),
      dispatch(initPlans()),
      dispatch(initEasyProjectsPlans()),
    ]);
    dispatch(setLoading(false));
  } catch (err) {
    console.log("BOOTAPP Error:", err);
    dispatch(setLoading(false));
  }
};
