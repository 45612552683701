import productActionTypes from "../actionsTypes/productActionTypes";

const initialState = {
  products: [],
  productsInitializedAt: null,
};

const initProducts = (state, payload) => {
  return { ...state, products: payload, productsInitializedAt: new Date() };
};

const productReducer = (state = initialState, action) => {
  switch (action.type) {
    case productActionTypes.INIT_PRODUCTS:
      return initProducts(state, action.payload);

    default:
      return state;
  }
};

export default productReducer;
