import { useSelector } from "react-redux";
import { PROJECTS } from "../../constants/generalConstants";

const AuthorizationWrapper = ({
  roles = [],
  project = "default",
  children,
}) => {
  const { user, isAuthenticated } = useSelector((state) => state.userReducer);
  if (!isAuthenticated) return null;

  const isAuthorizedEasyAO =
    roles && roles.length > 0 ? roles.includes(user?.type) : true;

  const isAuthorizedEasyProjects =
    roles && roles.length > 0 ? roles.includes(user?.easyProjectsType) : true;

  const isAuthorized = {
    [PROJECTS.easyao]: isAuthorizedEasyAO,
    [PROJECTS.easyprojects]: isAuthorizedEasyProjects,
    default: isAuthorizedEasyAO || isAuthorizedEasyProjects,
  };

  if (!isAuthorized[project]) return null;

  return children;
};

export default AuthorizationWrapper;
