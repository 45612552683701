import React from "react";
import { Text, VStack } from "@chakra-ui/react";

const EasyProjectsResume = () => {
  return (
    <VStack
      paddingTop="60px"
      paddingBottom="80px"
      boxShadow="0 -6px 16px rgb(0 0 0 / 15%)"
      spacing="0"
      alignItems="center"
    >
      <VStack width="90%" maxW="1000px" spacing="40px">
        <Text className="easyProjects_headingPrimary">
          Nos offres : Easy Marchés et Easy Projets
        </Text>
        <VStack spacing="25px">
          <Text
            fontSize="18px"
            fontWeight="300"
            lineHeight="28px"
            textAlign="center"
          >
            Selon l’activité, la taille et la politique de votre entreprise,
            vous aurez besoin d’informations stratégiques pertinentes afin de
            développer vos ventes. Nos services vous fournissent tous les
            renseignements nécessaires à votre croissance commerciale.
          </Text>
          <Text
            fontSize="18px"
            fontWeight="300"
            lineHeight="28px"
            textAlign="center"
          >
            Selon l’activité, la taille et la politique de votre entreprise, vous aurez besoin d’informations stratégiques pertinentes afin de développer vos ventes. Nos services vous fournissent tous les renseignements nécessaires à votre croissance commerciale. Aussi bien sur les marchés publics grâce aux outils de recherche de Easy Marchés qu’avec les projets de construction de Easy Projets. 
          </Text>
          <Text
            fontSize="18px"
            fontWeight="bold"
            lineHeight="28px"
            textAlign="center"
          >
            Une offre à 360° et sans engagement vous permettant de ne plus
            manquer les opportunités d’affaires de votre zone de chalandise !
          </Text>
        </VStack>
      </VStack>
    </VStack>
  );
};

export default EasyProjectsResume;
