import paymentActionTypes from "../actionsTypes/paymentActionTypes";

const initialState = {
  portalSession: null,
  payments: [],
  paymentMethods: [],
};

const createPayment = (state, payload) => {
  return state;
};

const initPayments = (state, payload) => {
  return { ...state, payments: payload };
};

const initPaymentMethods = (state, payload) => {
  return { ...state, paymentMethods: payload };
};

const paymentReducer = (state = initialState, action) => {
  switch (action.type) {
    case paymentActionTypes.CREATE_PAYMENT:
      return createPayment(state, action.payload);

    case paymentActionTypes.INIT_PAYMENTS:
      return initPayments(state, action.payload);

    case paymentActionTypes.INIT_PAYMENT_METHODS:
      return initPaymentMethods(state, action.payload);

    default:
      return state;
  }
};

export default paymentReducer;
