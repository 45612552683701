import React from "react";
import CardPaymentMethod from "./CardPaymentMethod";

const PaymentMethod = ({ paymentMethod, onSelect, isSelected }) => {
  const handleSelect = () => {
    if (typeof onSelect === "function") onSelect(paymentMethod);
  };
  if (paymentMethod?.type === "card")
    return (
      <CardPaymentMethod
        isSelected={isSelected}
        card={paymentMethod.card}
        handleSelect={handleSelect}
      />
    );
  return null;
};

export default PaymentMethod;
