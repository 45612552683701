import { PROJECTS } from "../../constants/generalConstants";
import generalActionTypes from "../actionsTypes/generalActionTypes";

const initialState = {
  sectors: [],
  plans: [],
  easyProjectsPlans: [],
  plansInitializedAt: null,
  easyProjectsPlansInitializedAt: null,
  sectorsInitializedAt: null,
  tva: null,
  routingData: null,
  completePlan: null,
  completePlanInitializedAt: null,
  currentProject: PROJECTS.easyao,
  plansDiscountForSubscribed: null
};

const initSectors = (state, payload) => {
  return { ...state, sectors: payload || [], sectorsInitializedAt: new Date() };
};

const initPlans = (state, payload) => {
  return { ...state, plans: payload || [], plansInitializedAt: new Date() };
};

const initEasyProjectsPlans = (state, payload) => {
  return {
    ...state,
    easyProjectsPlans: payload || [],
    easyProjectsPlansInitializedAt: new Date(),
  };
};

const initCompletePlan = (state, payload) => {
  return {
    ...state,
    completePlan: payload || [],
    completePlanInitializedAt: new Date(),
  };
};

const switchProject = (state) => {
  return {
    ...state,
    currentProject:
      state.currentProject === PROJECTS.easyao
        ? PROJECTS.easyprojects
        : PROJECTS.easyao,
  };
};

const setProject = (state, project) => {
  return {
    ...state,
    currentProject: project
  };
};

const setPlansDiscount = (state, payload) => {
  return {
    ...state,
    plansDiscountForSubscribed: payload
  }
}

const generalReducer = (state = initialState, action) => {
  switch (action.type) {
    case generalActionTypes.INIT_SECTORS:
      return initSectors(state, action.payload);

    case generalActionTypes.INIT_PLANS:
      return initPlans(state, action.payload);

    case generalActionTypes.INIT_EASYPROJECTS_PLANS:
      return initEasyProjectsPlans(state, action.payload);

    case generalActionTypes.INIT_COMPLETE_PLAN:
      return initCompletePlan(state, action.payload);

    case generalActionTypes.SWITCH_PROJECT:
      return switchProject(state);

    case generalActionTypes.SET_PROJECT:
      return setProject(state, action.payload);

    case generalActionTypes.SET_PLAN_DISCOUNT:
      return setPlansDiscount(state, action.payload);

    default:
      return state;
  }
};

export default generalReducer;
