import React, { memo } from "react";
import {
  Input,
  FormControl,
  FormErrorMessage,
  FormLabel,
} from "@chakra-ui/react";
import { Field } from "formik";
const CustomInput = ({
  name,
  label,
  onChange,
  children,
  autoComplete = "on",
  defaultValue,
  inputRef,
  ...rest
}) => {
  const autoCompleteValue = autoComplete
    ? autoComplete
    : rest.type === "password"
    ? "new-password"
    : "off";
  return (
    <Field name={name}>
      {({ field, form }) => {
        return (
          <FormControl isInvalid={form.errors[name]}>
            <FormLabel htmlFor={name}>{label}</FormLabel>
            <Input
              ref={inputRef}
              fontSize="14px"
              fontWeight="medium"
              {...field}
              {...rest}
              backgroundColor="#efefef"
              _placeholder={{ color: "#a8a8a8" }}
              autoComplete={autoCompleteValue}
              defaultValue={defaultValue}
            >
              {children}
            </Input>
            <FormErrorMessage>{form.errors[name]}</FormErrorMessage>
          </FormControl>
        );
      }}
    </Field>
  );
};

export default memo(CustomInput);
