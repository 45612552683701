import keyMirror from "keymirror";

export default keyMirror({
  SET_SEARCH_RESULTS: null,
  SET_SEARCH_PARAMS: null,
  SET_PAGE_SIZE: null,
  SET_PROJECT_DETAILS: null,
  SET_ESSENTIAL_DETAILS: null,
  SET_ASSET_TYPES: null,
  SET_MOA_INFOS: null,
  SET_MOE_INFOS: null,
  SET_BUILDING_PERMIT: null,
  SET_PROJECT_BASIC_INFOS: null,
  SET_SEARCH_STATUS: null,
  SEARCH_LOADING: null,
  SET_PROJECT_VISUALIZATION_DATA:null,
  CLEAR_PROJECT_DETAILS:null
});
