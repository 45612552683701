import React from "react";
import { VStack, Text } from "@chakra-ui/react";

import FeatureItem from "./FeatureItem";

const features = [
  {
    iconName: "target",
    title: "Trouvez facilement les appels d'offres qui vous intéressent",
    paragraph: `Recherchez vos marchés publics avec vos termes de recherche et enregistrez en favoris les appels d’offres qui vous intéressent.`,
  },
  {
    iconName: "folder",
    title: "Consultez et analyser rapidement les DCE",
    paragraph: `Notre outil révolutionnaire d’analyse des DCE vous permet de trouver rapidement les informations qui vous intéressent dans les cahiers des charges.`,
  },
  {
    iconName: "trophy",
    title: "Suivez l'activité de vos concurents",
    paragraph: `Nous rattachons les avis d’attribution disponibles à vos marchés pour vous permettre un suivi précis des positions de vos concurrents.`,
  },
  {
    iconName: "chart",
    title: "Réalisez vos études de marché",
    paragraph: `L’analyse des marchés passés vous donnera de précieuses informations sur le potentiel de vos produits et services.`,
  },
];

const Features = () => {
  return (
    <VStack className="features" spacing="40px" style={{ margin: 0 }}>
      <h2 className="heading_primary">Gagnez du temps et en efficacité avec Easy Marchés</h2>
      {features.map((feature,index) => (
        <FeatureItem
          iconName={feature.iconName}
          title={feature.title}
          paragraph={feature.paragraph}
          key={index}
        />
      ))}
    </VStack>
  );
};

export default Features;
