import React from "react";
import { Stack, Image } from "@chakra-ui/react";
import spinnerImage from "../../assets/svgs/spinner.svg";

const Loader = ({ isLoading }) => {
  if (!isLoading) return null;
  return (
    <Stack
      position="fixed"
      top="0"
      left="0"
      width="100vw"
      height="100vh"
      alignItems="center"
      justifyContent="center"
      backgroundColor="white"
      zIndex="9999"
    >
      <Image src={spinnerImage} />
    </Stack>
  );
};

export default Loader;
