import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  VStack,
  Text,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from "@chakra-ui/react";

import { initPaymentMethods } from "../../redux/actionsCreators/paymentActionCreators";
import "./OneTimePayment.css";
import PaymentMethodSelectorContainer from "../PaymentMethod/PaymentMethodSelectorContainer";
import PaymentFormContainer from "../Stripe/PaymentFormContainer";
import IbanFormContainer from "../Stripe/IbanFormContainer";

const OneTimePayment = () => {
  const { paymentMethods } = useSelector((state) => state.paymentReducer);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(initPaymentMethods());
    return () => {};
  }, [dispatch]);

  const selectedTabStyle = {
    color: "#f39200",
    borderColor: "inherit",
    borderBottomColor: "white",
  };

  return (
    <VStack className="onetimepayment" spacing="30px">
      <Tabs className="onetimepayment_tabs" variant="enclosed">
        <TabList>
          <Tab _selected={selectedTabStyle} className="onetimepayment_tab">
            CB
          </Tab>
          <Tab _selected={selectedTabStyle} className="onetimepayment_tab">
            IBAN
          </Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            <VStack className="onetimepayment_wrapper">
              {paymentMethods?.length > 0 ? (
                <>
                  <Text className="onetimepayment_text">
                    Veuillez sélectionner votre mode de paiement préféré :
                  </Text>
                  <PaymentMethodSelectorContainer
                    paymentMethods={paymentMethods}
                  />
                </>
              ) : (
                <PaymentFormContainer className="onetimepayment_form" />
              )}
            </VStack>
          </TabPanel>
          <TabPanel>
            <VStack className="onetimepayment_wrapper">
              <IbanFormContainer />
            </VStack>
          </TabPanel>
        </TabPanels>
      </Tabs>
    </VStack>
  );
};

export default OneTimePayment;
