import AxiosHttpApi from "../core/AxiosHttpApi";
const BASE_TARGET = `/orders`;

class OrderHttpApi extends AxiosHttpApi {
  getUserOrders = async (userId) => {
    return this.getRequest(`${BASE_TARGET}/?userId=${userId}`);
  };

  getUserConfirmedOrders = async (userId) => {
    return this.getRequest(`${BASE_TARGET}/?userId=${userId}&withoutcart=true`);
  };

  newOrder = async (data) => {
    return this.postRequest(`${BASE_TARGET}`, data);
  };

  updateOrder = async (orderId, data) => {
    return this.putRequest(`${BASE_TARGET}/${orderId}`, data);
  };

  getCart = async () => {
    return this.getRequest(`${BASE_TARGET}/cart`);
  };

  updateCartItemQty = async (itemId, qty) => {
    return this.putRequest(`${BASE_TARGET}/cart/${itemId}`, { qty });
  };

  removeItemFromCart = async (itemId) => {
    return this.deleteRequest(`${BASE_TARGET}/cart/${itemId}`);
  };

  addItemToCart = async (data) => {
    return this.postRequest(`${BASE_TARGET}/cart`, data);
  };

  getUserSubscriptionOrders = async () => {
    return this.getRequest(`${BASE_TARGET}/subscriptions`);
  };

  getCoupon = async (couponCode) => {
    return this.getRequest(`coupons/${couponCode}`);
  };

  getPromoCodeByCode = async (code) => {
    return this.getRequest(`promocodes/codes/${code}`);
  };

  getPromoCodeById = async (id) => {
    return this.getRequest(`promocodes/${id}`);
  };

  applyPromoCodeOnCart = async (cartId, promoCodeId) => {
    return this.putRequest(`${BASE_TARGET}/cart/apply-promocode/${cartId}`, {
      promoCodeId,
    });
  };

  removePromoCodeFromCart = async (cartId) => {
    return this.putRequest(`${BASE_TARGET}/cart/remove-promocode/${cartId}`);
  };
}

export default new OrderHttpApi();
