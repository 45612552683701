import KeyMirror from "keymirror";

/**
 * Les types du user
 * @readonly
 * @enum {string}
 */
export const userTypes = KeyMirror({
  premium: null,
  discovery: null,
  trial: null,
});


/**
 * La période de paiement d'abonnement
 * @readonly
 * @enum {string}
 */
export const planPaymentPeriod = KeyMirror({
  year: null,
  month: null,
  week: null,
});