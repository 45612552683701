import KeyMirror from "keymirror";

/**
 * Les types de la notification
 * @readonly
 * @enum {string}
 */
export const notifTypes = KeyMirror({
  success: null,
  info: null,
  warning: null,
  error: null,
});

export const notifMessages = {
  accountActivated: {
    message: "Votre compte a été activé avec succès",
    type: notifTypes.success,
  },
  passwordUpdated: {
    message: "Votre mot de passe a été mis à jour avec succès",
    type: notifTypes.success,
  },
  alreadySubscribed: {
    type: notifTypes.info,
    message: "Vous êtes déja souscris à Easy Marchés premium",
  },
  alreadySubscribedToEasyProjects: {
    type: notifTypes.info,
    message: "Vous êtes déja souscris à Easy Projets premium",
  },
  alreadySubscribedToCompleteOffer: {
    type: notifTypes.info,
    message: "Vous êtes déja souscris à l'offre complète",
  },
  informationUpdated: {
    message: "Vos informations ont été mises à jour",
    type: notifTypes.success,
  },
  sessionExpired: {
    message: "Votre session a expiré veuillez vous reconnecter",
    type: notifTypes.info,
  },
  productAddedSuccessfully: {
    message: "Le produit est ajouté au panier avec succès",
    type: notifTypes.success,
  },
  noPaymentMethodSelected: {
    message: "Veuillez sélectionner une méthode de paiement",
    type: notifTypes.warning,
  },
  paymentHasFailed: {
    message: "Votre paiement a échoué. Veuillez réessayer.",
    type: notifTypes.error,
  },
  paymentProcessing: {
    message: "Le paiement est en cours",
    type: notifTypes.info,
  },
  paymentSucceeded: {
    message: "Le paiement a été effectué avec succès",
    type: notifTypes.success,
  },
  invalidPromoCode: {
    message: "Le code promo est invalide",
    type: notifTypes.error,
  },
  promoCodeApplied: {
    message: "Le code promo est bien appliqué",
    type: notifTypes.success,
  },
  promoCodeRemoved: {
    message: "Le code promo est annulé",
    type: notifTypes.info,
  },
  promoCodeAlreadyApplied: {
    message: "Ce code promo est déjà appliqué",
    type: notifTypes.warning,
  },
  projectAddedToFavorites:{
    message: 'le projet a été ajouté aux favoris',
    type: notifTypes.success,
  },
  projectRemovedFromFavorites:{
    message: 'le projet a été supprimé du favoris',
    type: notifTypes.success,
  },
  favoriteProjectUpdated:{
    message: 'La modification est effectuée avec succès',
    type: notifTypes.success,
  },
  moeHaveBeenVisualized:{
    message: "les maître(s) d'oeuvre(s) ont été visualisé",
    type: notifTypes.success,
  }
};

export const stripeErrorsMessages = {
  card_declined: "Votre carte a été refusée",
  invalid_number: "Votre numéro de carte est invalide",
  incomplete_number: "Votre numéro de carte est incomplet",
  invalid_iban: "Votre Iban est invalide",
  invalid_iban_country_code: "Votre Iban est invalide",
};
