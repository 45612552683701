import React from "react";
import { HStack, Text, VStack } from "@chakra-ui/react";
import CustomButton from "../../shared/Button/CustomButton";
import "./EasyProjectsHome.css";
import sendmailhelper from "../../helpers/sendmailhelper";

const EasyProjectsLanding = ({ history }) => {
  const getInTouch = () => sendmailhelper.getInTouch();
  return (
    <VStack
      className="easyProjectsLanding"
      justifyContent="center"
      spacing="0"
    >
      <VStack spacing="20px">
        <VStack
          textTransform="uppercase"
          fontSize="45px"
          fontWeight="900"
          letterSpacing="2px"
          color="white"
          textShadow="4px 4px 8px rgba(0,0,0,0.9)"
          textAlign="center"
          spacing="-10px"
        >
          <Text>Les projets privés</Text>
          <Text>de votre secteur</Text>
        </VStack>
        <HStack spacing="35px">
          <CustomButton
            onClick={getInTouch}
            borderRadius="60px"
          >
            Je souhaite être contacté
          </CustomButton>
        </HStack>
      </VStack>
      <VStack
        style={{
          position: "absolute",
          bottom: "0",
          width: "100%",
          maxWidth: "1000px",
          backgroundColor: "rgba(255, 255, 255, 0.7)",
          textAlign: "center",
          padding: "20px 40px",
          borderRadius: " 4px",
        }}
        spacing="15px"
      >
        <Text fontSize="28px" fontWeight="bold">
          Restez au contact des projets de votre secteur !
        </Text>
        <VStack
          fontSize="18px"
          spacing="-4px"
          fontWeight="medium"
          textShadow="4px 4px 8px rgba(0,0,0,0.4)"
        >
          <Text>
            Pour gagner des marchés et développer votre activité, il est impératif d’être bien informé, de se faire connaître au bon moment du bon interlocuteur. C’est pour cela que nous mettons à votre disposition des milliers de projets privés, des informations qualifiées et vérifiée par nos équipes.
          </Text>
          <Text>
            Vous serez ainsi informé au bon moment de tous les projets de votre zone de chalandise.
          </Text>
        </VStack>
      </VStack>
    </VStack>
  );
};

export default EasyProjectsLanding;
