import React, { lazy, Suspense } from "react";
import { Route, Redirect, Switch } from "react-router-dom";
import PrivateRoute from "./PrivateRoute";
import { useSelector } from "react-redux";
import { userTypes } from "../constants/userConstants";
const SignInPage = lazy(() => import("../pages/SignInPage/SignInPage"));
const AccountPage = lazy(() => import("../pages/AccountPage/AccountPage"));
const BillingPage = lazy(() => import("../pages/BillingPage/BillingPage"));
const ConsommationPage = lazy(() =>
  import("../pages/ConsommationPage/ConsommationPage")
);
const ContactsPage = lazy(() =>
  import("../pages/ContactsPage/ContactsPage")
);
const SignUpPage = lazy(() => import("../pages/SignUpPage/SignUpPage"));
const AccountCreatedPage = lazy(() =>
  import("../pages/AccountCreatedPage/AccountCreatedPage")
);
const ResetPasswordPage = lazy(() =>
  import("../pages/ResetPasswordPage/ResetPasswordPage")
);
const ForgotPasswordPage = lazy(() =>
  import("../pages/ForgotPasswordPage/ForgotPasswordPage")
);
const EmailSentPage = lazy(() =>
  import("../pages/EmailSentPage/EmailSentPage")
);
const SubscriptionSucceededPage = lazy(() =>
  import("../pages/SubscriptionSucceededPage/SubscriptionSucceededPage")
);
const ResendActivationEmailPage = lazy(() =>
  import("../pages/ResendActivationEmail/ResendActivationEmailPage")
);

const AccountRoutes = () => {
  const { isAuthenticated } = useSelector((state) => state.userReducer);

  return (
    <Suspense fallback={<div></div>}>
      <Switch>
        <Route
          exact
          path="/account/resendemail"
          component={ResendActivationEmailPage}
        />
        <Route exact path="/account/created" component={AccountCreatedPage} />
        <Route
          exact
          path="/account/forgotpassword"
          component={ForgotPasswordPage}
        />
        <Route exact path="/account/emailsent" component={EmailSentPage} />
        <Route
          exact
          path="/account/resetpassword/:token"
          component={ResetPasswordPage}
        />
        <PrivateRoute
          exact
          path="/account/subscriptionsucceeded"
          component={SubscriptionSucceededPage}
        />
        <PrivateRoute
          exact
          roles={[userTypes.premium]}
          path="/account/billing"
          component={BillingPage}
        />
        <PrivateRoute
          exact
          path="/account/consommation"
          component={ConsommationPage}
        />
        <PrivateRoute
          exact
          path={["/account", "/account/infos"]}
          component={AccountPage}
        />
        <PrivateRoute
          exact
          path="/account/contacts"
          component={ContactsPage}
        />
        {!isAuthenticated && (
          <Route
            exact
            path={["/account", "/account/signin"]}
            component={SignInPage}
          />
        )}
        {!isAuthenticated && (
          <Route exact path={"/account/signup"} component={SignUpPage} />
        )}
        <Redirect to="/account" />
      </Switch>
    </Suspense>
  );
};

export default AccountRoutes;
