import { Link as RouterLink, withRouter } from "react-router-dom";
import {
  Text,
  HStack,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
} from "@chakra-ui/react";
import NavBarItem from "./NavBarItem";
import { useDispatch } from "react-redux";
import { logOut } from "../../redux/actionsCreators/userActionCreators";
import { userTypes } from "../../constants/userConstants";
import AuthorizationWrapper from "../../components/AuthorizationWrapper/AuthorizationWrapper";
import { capitalizeWord } from "../../helpers/formatHelper";

const AccountNavBarItem = ({ user, isAuthenticated, history }) => {
  const dispatch = useDispatch();

  const handleLogout = async () => {
    dispatch(logOut());
    history.push("/account/signin");
  };

  if (!isAuthenticated)
    return (
      <NavBarItem target="/account/signin" iconName="user">
        <Text className="navItem_text">Connexion</Text>
      </NavBarItem>
    );

  return (
    <Menu>
      <MenuButton>
        <NavBarItem target="/account" iconName="user">
          <Text className="navItem_text">{capitalizeWord(user.firstname)}</Text>
        </NavBarItem>
      </MenuButton>
      <MenuList
        shadow="md"
        zIndex="dropdown"
        marginTop="-10px"
        borderTop="none"
        borderTopRadius="none"
      >
        <MenuItem as={RouterLink} to="/account/infos">
          <HStack className="dropdownItem">
            <Text className="dropdownItem_text">Mes informations</Text>
          </HStack>
        </MenuItem>
        <AuthorizationWrapper roles={[userTypes.premium]}>
          <MenuItem as={RouterLink} to="/account/billing">
            <HStack className="dropdownItem">
              <Text className="dropdownItem_text">Facturation & Paiement</Text>
            </HStack>
          </MenuItem>
        </AuthorizationWrapper>

        <MenuItem as={RouterLink} to="/account/consommation">
          <HStack className="dropdownItem">
            <Text className="dropdownItem_text">Consommation</Text>
          </HStack>
        </MenuItem>
        <MenuItem as={RouterLink} to="/account/contacts">
          <HStack className="dropdownItem">
            <Text className="dropdownItem_text">Contacts</Text>
          </HStack>
        </MenuItem>
        <MenuItem onClick={handleLogout}>
          <HStack className="dropdownItem">
            <Text className="dropdownItem_text">Se déconnecter</Text>
          </HStack>
        </MenuItem>
      </MenuList>
    </Menu>
  );
};

export default withRouter(AccountNavBarItem);
