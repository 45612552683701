import userActionsTypes from "../actionsTypes/userActionTypes";
import { getToken } from "../../helpers/sessionHelper";

const getPendingUserData = () => {
  const data = localStorage.getItem("pendingUser");
  return data ? JSON.parse(data) : {};
};
const token = getToken();

const initialState = {
  user: {},
  pendingUser: getPendingUserData(),
  isAuthenticated: !!token,
  isLoadingUser: true,
  authenticatedAt: null,
};

const logOut = (state) => {
  localStorage.removeItem("token");
  localStorage.removeItem("userId");
  localStorage.removeItem("pendingUser");
  return { ...state, user: null, pendingUser: {}, isAuthenticated: false };
};

const signIn = (state, payload) => {
  localStorage.setItem("token", payload?.token);
  localStorage.setItem("userId", payload?.user?.id);
  localStorage.removeItem("pendingUser");
  return {
    ...state,
    user: payload?.user,
    pendingUser: {},
    isAuthenticated: true,
    authenticatedAt: new Date(),
  };
};

const setLoadingUser = (state, payload) => {
  return { ...state, isLoadingUser: payload };
};

const initUser = (state, payload) => {
  return {
    ...state,
    user: { ...payload },
    isAuthenticated: true,
    authenticatedAt: new Date(),
  };
};

const setPendingUserData = (state, payload) => {
  const pendingUser = { ...state.pendingUser, ...payload };
  localStorage.setItem("pendingUser", JSON.stringify(pendingUser));
  return { ...state, pendingUser };
};

const updateUser = (state, payload) => {
  localStorage.removeItem("pendingUser");
  return {
    ...state,
    user: { ...state.user, ...payload },
    pendingUser: {},
  };
};

const setPlan = (state, plan) => {
  return {
    ...state,
    user: { ...state.user, plan },
  };
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case userActionsTypes.LOADING_USER_DATA:
      return setLoadingUser(state, action.payload);

    case userActionsTypes.INIT_USER_DATA:
      return initUser(state, action.payload);

    case userActionsTypes.SIGNIN:
      return signIn(state, action.payload);

    case userActionsTypes.LOGOUT:
      return logOut(state);

    case userActionsTypes.SET_PENDING_USER_DATA:
      return setPendingUserData(state, action.payload);

    case userActionsTypes.UPDATE_USER:
      return updateUser(state, action.payload);

    case userActionsTypes.SET_PLAN:
      return setPlan(state, action.payload);

    default:
      return state;
  }
};

export default userReducer;
