import React from "react";
import { HStack, Image, Spacer, Text } from "@chakra-ui/react";
import CustomIcon from "../../shared/Icon/CustomIcon";
import masterCardImage from "../../assets/images/masterCard.jpeg";
import visaCardImage from "../../assets/images/visaCard.jpeg";

const cardsImageMapping = {
  mastercard: masterCardImage,
  visa: visaCardImage,
};

const CardPaymentMethod = ({ card, handleSelect, isSelected }) => {
  return (
    <HStack
      onClick={handleSelect}
      className={`cardPaymentMethod ${isSelected ? "selected" : ""}`}
      spacing="0"
    >
      <HStack spacing="15px">
        <Image
          src={cardsImageMapping[card.brand]}
          className="cardPaymentMethod_image"
        />
        <Text className="cardPaymentMethod_text">
          **** **** **** {card.last4}
        </Text>
      </HStack>
      <Spacer />
      <CustomIcon
        name="outlineCheck"
        className={`cardPaymentMethod_checkIcon ${isSelected ? "visible" : ""}`}
      />
    </HStack>
  );
};

export default CardPaymentMethod;
