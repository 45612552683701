import React from "react";
import { Stack, VStack, HStack, Text } from "@chakra-ui/react";
import CustomButton from "../../shared/Button/CustomButton";

const Landing = ({ history }) => {
  const navigateToOffers = () => history.push("/shop");
  const navigateToSearch = () => history.push("/search");

  return (
    <Stack className="landingTitle">
      <VStack spacing="40px">
        <VStack
          textTransform="uppercase"
          fontSize="45px"
          fontWeight="900"
          letterSpacing="2px"
          color="white"
          textShadow="4px 4px 8px rgba(0,0,0,0.7)"
          textAlign="center"
        >
          <h1>tous les marchés publics</h1>
          <h1>sont sur EasyMarchés</h1>
        </VStack>
        <HStack spacing="35px">
          <CustomButton
            className="landingContent_button"
            onClick={navigateToSearch}
          >
            J'essaie
          </CustomButton>
          <CustomButton
            className="landingContent_button"
            onClick={navigateToOffers}
          >
            Je m'inscris
          </CustomButton>
        </HStack>
      </VStack>
      <VStack className="landingContent">
        <h2 className="landingContent_title">
          EasyMarchés, c'est simple, rapide et efficace 
        </h2>
        <Text className="landingContent_text">

          <div>Tous les appels d’offres de votre secteur sont réunis sur une seule plateforme: Easy Marchés</div>
          <div>Accédez aux DCE et analysez les grâce à un outil révolutionnaire</div>
          <div>Suivez vos marchés et gagnez du temps dans vos réponses</div>
        </Text>
      </VStack>
    </Stack>
  );
};
export default Landing;
