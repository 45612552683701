import appActionTypes from "../actionsTypes/appActionTypes";

/**
 *  Changer l'état de chargement dans le store
 *
 * @param {boolean} isLoading
 */
export const setLoading = (isLoading) => (dispatch) => {
  if (!isLoading) {
    const timeoutLoading = setTimeout(() => {
      dispatch({
        type: appActionTypes.SET_LOADING,
        payload: isLoading,
      });
      clearTimeout(timeoutLoading);
    }, 500);
  } else {
    dispatch({
      type: appActionTypes.SET_LOADING,
      payload: isLoading,
    });
  }
};

/**
 *  Afficher la notification
 *
 * @param {object} notif
 * @param {string} notif.type
 * @param {string} notif.message
 */
export const showNotif = (notif) => (dispatch) => {
  if (!notif.message) return;
  dispatch({
    type: appActionTypes.SHOW_NOTIF,
    payload: notif,
  });
};

/**
 *  Masquer la notification
 *
 * @param {string} notifId
 */
export const hideNotif = (notifId) => (dispatch) => {
  dispatch({
    type: appActionTypes.HIDE_NOTIF,
    payload: notifId,
  });
};

/**
 *  Masquer toutes les notifications
 *
 */
export const hideAllNotifs = () => (dispatch) => {
  dispatch({
    type: appActionTypes.HIDE_ALL_NOTIFS,
  });
};
