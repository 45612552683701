import React from "react";
import { useSelector } from "react-redux";
import { Redirect, Route } from "react-router-dom";
import { PROJECTS } from "../constants/generalConstants";

const PrivateRoute = ({
  component: Component,
  project = "default",
  roles = [],
  ...rest
}) => {
  const { isAuthenticated, user } = useSelector((state) => state.userReducer);

  const isAuthorizedEasyAO =
    roles && roles.length > 0 ? roles.includes(user?.type) : true;

  const isAuthorizedEasyProjects =
    roles && roles.length > 0 ? roles.includes(user?.easyProjectsType) : true;

  const isAuthorized = {
    [PROJECTS.easyao]: isAuthorizedEasyAO,
    [PROJECTS.easyprojects]: isAuthorizedEasyProjects,
    default: isAuthorizedEasyAO || isAuthorizedEasyProjects,
  };

  const renderPage = (props) => {
    if (!isAuthenticated) return <Redirect to="/account/signin" />;
    if (!isAuthorized[project]) return <Redirect to="/" />;
    return <Component {...props} />;
  };

  return <Route {...rest} render={renderPage} />;
};

export default PrivateRoute;
