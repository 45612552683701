import React, { useEffect, useState } from "react";
import {
  VStack,
  HStack,
  Text,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Tooltip,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  useDisclosure,
} from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import {
  applyPromoCodeOnCart,
  initCart,
  removePromoCodeFromCart,
} from "../../redux/actionsCreators/orderActionCreators";
import { formatPrice } from "../../helpers/formatHelper";
import PaymentFormContainer from "../Stripe/PaymentFormContainer";
import {
  currencyCodeMapping,
  mappedPaymentPeriod,
} from "../../constants/paymentConstants";
import { initPaymentMethods } from "../../redux/actionsCreators/paymentActionCreators";
import PaymentMethodSelectorContainer from "../PaymentMethod/PaymentMethodSelectorContainer";
import "./SubscriptionPayment.css";
import IbanFormContainer from "../Stripe/IbanFormContainer";
import CustomForm from "../../shared/Form/CustomForm";
import CustomInput from "../../shared/Input/CustomInput";
import CustomButton from "../../shared/Button/CustomButton";
import { useRef } from "react";
import {
  OfferTitles,
  OfferTypesLabels,
  PROJECTS,
} from "../../constants/generalConstants";
import { getUserPremiumPlan } from "../../helpers/commonUtils";
import CustomIcon from "../../shared/Icon/CustomIcon";
import { showNotif } from "../../redux/actionsCreators/appActionCreators";
import { notifMessages } from "../../constants/notificationConstants";

const SubscriptionPriceInfo = ({
  totalHt,
  tvaTotal,
  currencyCode,
  withPaymentMethods,
  plan,
  showPlanDiscount,
  plansDiscountForSubscribed,
  premiumPlan,
}) => {
  const { promoCodeData, cart, tva } = useSelector(
    (state) => state.orderReducer
  );
  const [promoCode, setPromoCode] = useState(null);
  const [isApplyingPromoCode, setIsApplyingPromoCode] = useState(false);
  const [removePromoCodePending, setRemovePromoCodePending] = useState(false);
  const promoCodeInput = useRef();
  const dispatch = useDispatch();
  const coupon = promoCodeData?.coupon;
  const { isOpen, onOpen, onClose } = useDisclosure();
  const getCouponRemise = () => {
    if (coupon?.percent_off)
      return {
        label: `(${coupon?.percent_off}%)`,
        value: -formatPrice((coupon?.percent_off / 100 || 0) * totalHt),
      };
    if (coupon?.amount_off)
      return {
        label: "",
        value: -formatPrice(coupon?.amount_off / 100),
      };

    return {
      label: "",
      value: 0,
    };
  };
  const couponRemise = getCouponRemise();
  const discountValue =
    showPlanDiscount && !coupon
      ? -(
          parseFloat(totalHt) *
          parseFloat(plansDiscountForSubscribed?.percent_off)
        ) / 100
      : parseFloat(couponRemise.value);

  const tvaValue =
    showPlanDiscount && !coupon
      ? (parseFloat(totalHt) + discountValue) * tva?.value
      : tvaTotal;
  const priceTtcTotal = formatPrice(
    parseFloat(totalHt) + discountValue + parseFloat(tvaValue)
  );

  const confirmApplyingPromoCode = async () => {
    onClose();
    setIsApplyingPromoCode(true);
    await dispatch(applyPromoCodeOnCart(cart.id, promoCode));
    setIsApplyingPromoCode(false);
  };

  const applyPromoCode = () => {
    if (
      promoCodeValue === promoCodeData?.code &&
      cart.promoCode === promoCodeData?.id
    )
      return dispatch(showNotif(notifMessages.promoCodeAlreadyApplied));
    if (showPlanDiscount) {
      onOpen();
    } else {
      confirmApplyingPromoCode();
    }
  };

  const removePromoCode = async () => {
    setRemovePromoCodePending(true);
    await dispatch(removePromoCodeFromCart(cart.id));
    setPromoCode("");
    setRemovePromoCodePending(false);
  };

  const handlePromoCodeChange = (event) => {
    setPromoCode(event.target.value);
  };

  const promoCodeValue = promoCode ?? promoCodeData?.code;

  return (
    <VStack>
      <VStack>
        <Text
          className={`subscription_title${
            withPaymentMethods ? "--withPaymentMethods" : ""
          }`}
        >
          {OfferTypesLabels[plan?.accountType]}
        </Text>
        <HStack spacing="0">
          <Text
            className={`subscription_price${
              withPaymentMethods ? "--withPaymentMethods" : ""
            }`}
          >
            {priceTtcTotal}€
          </Text>
          <Text className="subscription_subTitle">
            / {mappedPaymentPeriod[plan?.paymentPeriod]}
          </Text>
        </HStack>
      </VStack>

      <HStack className="subscription_priceInfos">
        <VStack className="subscription_priceInfosLabels" spacing="0">
          <Text>Prix HT</Text>
          <Text>Remise {couponRemise.label}</Text>
          <Text>TVA 20%</Text>
          <Text>Prix TTC</Text>
        </VStack>
        <VStack
          className="subscription_priceInfosValues"
          spacing="0"
          alignItems="flex-end"
        >
          <Text>
            {formatPrice(totalHt)}
            {currencyCodeMapping[currencyCode]}
          </Text>
          <HStack position="relative">
            <Text>
              {formatPrice(discountValue)}
              {currencyCodeMapping[currencyCode]}
            </Text>

            {showPlanDiscount && !coupon && (
              <HStack position="absolute" left="100%" spacing="2px">
                <Text
                  color="#fff"
                  fontWeight="medium"
                >{`${plansDiscountForSubscribed?.percent_off}%`}</Text>
                <Tooltip
                  label={`Votre abonnement Premium ${OfferTitles[premiumPlan]} vous permet de bénéficier d'une remise complémentaire sur cette offre!`}
                  hasArrow
                >
                  <VStack cursor="pointer" justifyContent="center">
                    <CustomIcon
                      name="info"
                      color="#fff"
                      width="18px"
                      height="18px"
                    />
                  </VStack>
                </Tooltip>
              </HStack>
            )}
          </HStack>
          <Text>
            {formatPrice(tvaValue)}
            {currencyCodeMapping[currencyCode]}
          </Text>
          <Text>
            {priceTtcTotal}
            {currencyCodeMapping[currencyCode]}
          </Text>
        </VStack>
      </HStack>

      <VStack>
        <VStack className="subscriptionRemiseCode_wrapper">
          <Text className="subscriptionRemiseCode_label">Code de remise</Text>
          <CustomForm
            onChange={handlePromoCodeChange}
            onSubmit={applyPromoCode}
          >
            {(formProps) => {
              return (
                <VStack
                  className="subscriptionRemiseCode_formWrapper"
                  spacing="20px"
                >
                  <CustomInput
                    inputRef={promoCodeInput}
                    className="subscriptionRemiseCode_input"
                    id="subscriptionRemiseCode"
                    name="subscriptionRemiseCode"
                    placeholder=""
                    onChange={handlePromoCodeChange}
                    value={promoCodeValue}
                  />
                  <HStack>
                    <CustomButton
                      backgroundColor="white"
                      color="#f39200"
                      type="submit"
                      disabled={
                        !(
                          typeof promoCodeValue === "string" &&
                          promoCodeValue.length > 0
                        )
                      }
                      loadingText="Appliquer"
                      isLoading={isApplyingPromoCode}
                    >
                      Appliquer
                    </CustomButton>
                    <CustomButton
                      onClick={removePromoCode}
                      color="#fff"
                      backgroundColor="#e84e1b"
                      disabled={
                        !(
                          typeof promoCodeValue === "string" &&
                          promoCodeValue.length > 0
                        )
                      }
                      loadingText="Supprimer"
                      isLoading={removePromoCodePending}
                    >
                      Supprimer
                    </CustomButton>
                  </HStack>
                </VStack>
              );
            }}
          </CustomForm>
        </VStack>
      </VStack>
      {showPlanDiscount && (
        <Modal
          isOpen={isOpen}
          onClose={onClose}
          isCentered
          size="xl"
          closeOnOverlayClick={false}
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader
              backgroundColor="#f39200"
              color="white"
              fontSize="18px"
              textAlign="center"
              borderTopRadius="5px"
            >
              <Text>Appliquer un code promo </Text>
            </ModalHeader>
            <ModalCloseButton color="white" _focusVisible={false} />
            <ModalBody>
              <VStack px="10px" py="15px">
                <Text textAlign="center">
                  Attention, si vous appliquez un code promo, vous ne
                  bénéficierez pas de la remise 30%
                </Text>
              </VStack>
            </ModalBody>

            <ModalFooter justifyContent="center">
              <HStack justifyContent="center">
                <CustomButton
                  backgroundColor="#3784ec"
                  size="md"
                  onClick={confirmApplyingPromoCode}
                  borderRadius="6px"
                >
                  Continuer
                </CustomButton>
                <CustomButton
                  backgroundColor="#e94e1b"
                  size="md"
                  onClick={onClose}
                  borderRadius="6px"
                >
                  Annuler
                </CustomButton>
              </HStack>
            </ModalFooter>
          </ModalContent>
        </Modal>
      )}
    </VStack>
  );
};

const SubscriptionPayment = ({ project }) => {
  const { cart } = useSelector((state) => state.orderReducer);
  const { paymentMethods } = useSelector((state) => state.paymentReducer);
  const { plans, easyProjectsPlans, plansDiscountForSubscribed } = useSelector(
    (state) => state.generalReducer
  );
  const { user } = useSelector((state) => state.userReducer);

  const premiumPlan = getUserPremiumPlan(user);
  const showPlanDiscount = premiumPlan && premiumPlan !== "complete";

  const isCompleteOffer = cart?.planId === "completePlan";
  const plan = (
    project === PROJECTS.easyao
      ? [...(plans || [])]
      : [...(easyProjectsPlans || [])]
  ).find((p) => parseInt(p.id) === parseInt(cart.planId));

  const dispatch = useDispatch();
  const { totalHt, tvaTotal, currencyCode } = cart;

  const selectedTabStyle = {
    color: "#f39200",
    borderColor: "inherit",
    borderBottomColor: "white",
  };

  useEffect(() => {
    dispatch(initCart());
    dispatch(initPaymentMethods());
    return () => {};
  }, [dispatch]);
  return (
    <HStack
      className={`subscription_wrapper${
        paymentMethods?.length > 0 ? "--withPaymentMethods" : ""
      }`}
      spacing="100px"
    >
      <VStack flex={1} spacing="0">
        <Text fontSize="45px" fontWeight="bold" color="white">
          {isCompleteOffer ? "Offre 360" : OfferTitles[project]}
        </Text>

        <SubscriptionPriceInfo
          withPaymentMethods
          totalHt={totalHt}
          tvaTotal={tvaTotal}
          currencyCode={currencyCode}
          plan={plan}
          showPlanDiscount={showPlanDiscount}
          plansDiscountForSubscribed={plansDiscountForSubscribed}
          premiumPlan={premiumPlan}
        />
      </VStack>
      <VStack className="subscription_tabsWrapper" flex={1.5} spacing="30px">
        <Tabs className="subscription_tabs" variant="enclosed">
          <TabList>
            <Tab _selected={selectedTabStyle} className="subscription_tab">
              CB
            </Tab>
            <Tab _selected={selectedTabStyle} className="subscription_tab">
              IBAN
            </Tab>
          </TabList>
          <TabPanels className="subscription_tabPanels">
            <TabPanel className="subscription_tabPanel">
              <VStack className="subscription_rightSide" spacing="20px">
                {paymentMethods?.length > 0 ? (
                  <VStack className="subscription_paymentMethods">
                    <Text className="subscription_text">
                      Veuillez sélectionner votre mode de paiement préféré :
                    </Text>
                    <PaymentMethodSelectorContainer
                      paymentMethods={paymentMethods}
                    />
                  </VStack>
                ) : (
                  <VStack className="subscription_formWrapper">
                    <PaymentFormContainer className="subscription_form" />
                  </VStack>
                )}
              </VStack>
            </TabPanel>
            <TabPanel className="subscription_tabPanel">
              <VStack className="subscription_ibanWrapper">
                <IbanFormContainer />
              </VStack>
            </TabPanel>
          </TabPanels>
        </Tabs>
      </VStack>
    </HStack>
  );
};

export default SubscriptionPayment;
