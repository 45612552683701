import React from "react";
import { useSelector } from "react-redux";
import { Redirect, Route } from "react-router-dom";
import HomePage from "../pages/HomePage/HomePage";

const HomeRoute = () => {
  const { isAuthenticated } = useSelector((state) => state.userReducer);

  return (
    <Route
      exact
      path="/"
      render={(props) =>
        isAuthenticated ? <Redirect to="/search" /> : <HomePage {...props} />
      }
    />
  );
};

export default HomeRoute;
