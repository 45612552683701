import * as Yup from "yup";
const PASSWORD_REGEX_PATTERN =
  /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})/;

export const SignupSchema = Yup.object().shape({
  firstname: Yup.string()
    .min(2, "Trop court!")
    .max(50, "Trop long!")
    .required("Ce champs est obligatoire"),
  lastname: Yup.string()
    .min(2, "Trop court!")
    .max(50, "Trop long!")
    .required("Ce champs est obligatoire"),
  password: Yup.string()
    .min(8, "le mot de passe doit contenir au moins 8 caractères")
    .required("Ce champs est obligatoire")
    .matches(
      PASSWORD_REGEX_PATTERN,
      "le mot de passe doit contenir des lettres minuscules et majuscules, des chiffres et au moins un caractère spécial"
    ),
  confirmPassword: Yup.string()
    .min(8, "le mot de passe doit contenir au moins 8 caractères")
    .required("Ce champs est obligatoire")
    .test(
      "passwords-match",
      "Les mots de passe doivent correspondre",
      function (value) {
        return this.parent.password === value;
      }
    ),
  email: Yup.string()
    .email("Email invalide")
    .required("Ce champs est obligatoire"),
  confirmEmail: Yup.string()
    .required("Ce champs est obligatoire")
    .oneOf([Yup.ref("email"), null], "Les emails doivent correspondre"),
  entrepriseName: Yup.string()
    .min(2, "Trop court!")
    .required("Ce champs est obligatoire"),
  sector: Yup.string().required("Ce champs est obligatoire"),
  acceptConditions: Yup.boolean()
    .required("Il faut accepter les conditions pour continuer")
    .not([false], "Il faut accepter les conditions pour continuer"),
  receiveNewsletters: Yup.boolean(),
  receivePromotions: Yup.boolean(),
});

export const UpdateAccountSchema = Yup.object().shape({
  firstname: Yup.string()
    .min(2, "Trop court!")
    .max(50, "Trop long!")
    .required("Ce champs est obligatoire"),
  lastname: Yup.string()
    .min(2, "Trop court!")
    .max(50, "Trop long!")
    .required("Ce champs est obligatoire"),
  password: Yup.string()
    .min(8, "le mot de passe doit contenir au moins 8 caractères")
    .matches(
      PASSWORD_REGEX_PATTERN,
      "le mot de passe doit contenir des lettres minuscules et majuscules, des chiffres et au moins un caractère spécial"
    ),
  confirmPassword: Yup.string()
    .min(8, "le mot de passe doit contenir au moins 8 caractères")
    .test(
      "passwords-match",
      "Les mots de passe doivent correspondre",
      function (value) {
        return this.parent.password === value;
      }
    ),
  email: Yup.string()
    .email("Email invalide")
    .required("Ce champs est obligatoire"),
  confirmEmail: Yup.string()
    .required("Ce champs est obligatoire")
    .oneOf([Yup.ref("email"), null], "Les emails doivent correspondre"),
  entrepriseName: Yup.string()
    .min(2, "Trop court!")
    .required("Ce champs est obligatoire"),
  sector: Yup.string().required("Ce champs est obligatoire"),
  receiveNewsletters: Yup.boolean(),
  receivePromotions: Yup.boolean(),
  cp: Yup.number(),
});

export const StepOneInfosSchema = Yup.object().shape({
  firstname: Yup.string()
    .min(2, "Trop court!")
    .max(50, "Trop long!")
    .required("Ce champs est obligatoire"),
  lastname: Yup.string()
    .min(2, "Trop court!")
    .max(50, "Trop long!")
    .required("Ce champs est obligatoire"),
  password: Yup.string()
    .min(8, "le mot de passe doit contenir au moins 8 caractères")
    .matches(
      PASSWORD_REGEX_PATTERN,
      "le mot de passe doit contenir des lettres minuscules et majuscules, des chiffres et au moins un caractère spécial"
    ),
  confirmPassword: Yup.string()
    .min(8, "le mot de passe doit contenir au moins 8 caractères")
    .test(
      "passwords-match",
      "Les mots de passe doivent correspondre",
      function (value) {
        return this.parent.password === value;
      }
    ),
  email: Yup.string()
    .email("Email invalide")
    .required("Ce champs est obligatoire"),
  confirmEmail: Yup.string()
    .required("Ce champs est obligatoire")
    .oneOf([Yup.ref("email"), null], "Les emails doivent correspondre"),
  entrepriseName: Yup.string()
    .min(2, "Trop court!")
    .required("Ce champs est obligatoire"),
  sector: Yup.string().required("Ce champs est obligatoire"),
  acceptConditions: Yup.boolean()
    .required("Il faut accepter les conditions pour continuer")
    .not([false], "Il faut accepter les conditions pour continuer"),
  receiveNewsletters: Yup.boolean(),
  receivePromotions: Yup.boolean(),
});

export const SignInSchema = Yup.object().shape({
  identifiant: Yup.string()
    .min(2, "Trop court!")
    .max(50, "Trop long!")
    .required("Ce champs est obligatoire"),

  password: Yup.string()
  .min(8, "le mot de passe doit contenir au moins 8 caractères")
  .matches(
    PASSWORD_REGEX_PATTERN,
    "le mot de passe doit contenir des lettres minuscules et majuscules, des chiffres et au moins un caractère spécial"
  ),
});

export const ForgotPasswordSchema = Yup.object().shape({
  email: Yup.string()
    .min(2, "Trop court!")
    .max(50, "Trop long!")
    .required("Ce champs est obligatoire"),
});

export const ResetPasswordSchema = Yup.object().shape({
  password: Yup.string()
    .min(8, "le mot de passe doit contenir au moins 8 caractères")
    .required("Ce champs est obligatoire")
    .matches(
      PASSWORD_REGEX_PATTERN,
      "le mot de passe doit contenir des lettres minuscules et majuscules, des chiffres et au moins un caractère spécial"
    ),

  confirmPassword: Yup.string()
    .min(8, "le mot de passe doit contenir au moins 8 caractères")
    .required("Ce champs est obligatoire")
    .test(
      "passwords-match",
      "Les mots de passe doivent correspondre",
      function (value) {
        return this.parent.password === value;
      }
    ),
});

export const editBillingInfosSchema = Yup.object().shape({
  entrepriseName: Yup.string(),
  sector: Yup.string(),
  siret: Yup.string().required("Ce champs est obligatoire"),
  billingEmail: Yup.string()
    .email("Email invalide")
    .required("Ce champs est obligatoire"),
  address: Yup.string(),
  cp: Yup.number(),
  city: Yup.string(),
  phone: Yup.string().required("Ce champs est obligatoire"),
  website: Yup.string(),
});

export const updatePasswordSchema = Yup.object().shape({
  oldPassword: Yup.string()
    .min(8, "le mot de passe doit contenir au moins 8 caractères")
    .required("Ce champs est obligatoire")
    .matches(
      PASSWORD_REGEX_PATTERN,
      "le mot de passe doit contenir des lettres minuscules et majuscules, des chiffres et au moins un caractère spécial"
    ),
  password: Yup.string()
    .min(8, "le mot de passe doit contenir au moins 8 caractères")
    .required("Ce champs est obligatoire")
    .matches(
      PASSWORD_REGEX_PATTERN,
      "le mot de passe doit contenir des lettres minuscules et majuscules, des chiffres et au moins un caractère spécial"
    ),
  confirmPassword: Yup.string()
    .min(8, "le mot de passe doit contenir au moins 8 caractères")
    .required("Ce champs est obligatoire")
    .test(
      "passwords-match",
      "Les mots de passe doivent correspondre",
      function (value) {
        return this.parent.password === value;
      }
    ),
});
