import React from "react";
import {
  Select,
  FormControl,
  FormErrorMessage,
  FormLabel,
} from "@chakra-ui/react";
import { Field } from "formik";
const CustomSelect = ({ name, label, options = [], ...rest }) => {
  return (
    <Field name={name}>
      {({ field, form }) => (
        <FormControl isInvalid={form.errors[name]}>
          <FormLabel htmlFor={name}>{label}</FormLabel>
          <Select
            fontSize="14px"
            fontWeight="medium"
            {...field}
            {...rest}
            backgroundColor="#efefef"
          >
            {options.map((opt) => (
              <option key={opt.key}>{opt.value}</option>
            ))}
          </Select>
          <FormErrorMessage>{form.errors[name]}</FormErrorMessage>
        </FormControl>
      )}
    </Field>
  );
};

export default CustomSelect;
