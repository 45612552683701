import React from "react";
import { Stack, Text } from "@chakra-ui/react";

const LegalNotice = () => {
  return (
    <Stack flex={1} alignItems={"center"} justifyContent={"center"}>
      <Text fontWeight="bold" mb="0.6rem">
        Éditeur
      </Text>

      <Text mb="1.2rem">
        Le Site est édité par la société WANAO, société par actions simplifiée à associé unique au capital de 138 200 euros
      </Text>
      <br />
      <Text fontWeight="bold" mb="0.6rem">
        Siège social
      </Text>

      <Text mb="1.2rem">
        Technopole Izarbel – 230, allée Théodore Monod à Bidart (64210)
      </Text>
      <br />
      <Text fontWeight="bold" mb="0.6rem">
        Immatriculation
      </Text>

      <Text mb="1.2rem">
        Registre du commerce et des sociétés de Bayonne sous le numéro 438 772 089
      </Text>
      <br />
      <Text fontWeight="bold" mb="0.6rem">
        Numéro de téléphone
      </Text>

      <Text mb="1.2rem">
        05 59 01 30 70
      </Text>
      <br />
      <Text fontWeight="bold" mb="0.6rem">
        Hébergement
      </Text>

      <Text mb="1.2rem">
        Ce site est hébergé par AMAZON WEB SERVICES EMEA SARL, 31 PL DES COROLLES 92400 COURBEVOIE
      </Text>
    </Stack>
  );
};

export default LegalNotice;
