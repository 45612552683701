import React from "react";
import {
  VStack,
  HStack,
  Text,
  Image,
  UnorderedList,
  ListItem,
} from "@chakra-ui/react";
import laptopImage from "../../assets/images/laptop_services.png";

const PostSources = () => {
  return (
    <VStack className="postSources" spacing="40px" style={{ margin: 0 }}>
      <h2 className="heading_primary">
        Easy Marchés centralise tous les marchés publics
      </h2>
      <HStack className="postSources_content" spacing="100px">
        <Image src={laptopImage} className="postSources_image" alt="Easy Marchés centralise tous les marchés publics"/>
        <VStack className="postSources_text" spacing="20px">
          <Text>
          Easy Marchés consulte quotidiennement toutes les sources de publication afin de centraliser et de standardiser tous les appels d’offres publics : 
          </Text>
          <UnorderedList className="postSources_list">
            <ListItem>Le BOAMP </ListItem>
            <ListItem>Le JOUE </ListItem>
            <ListItem>Tous les “Journaux d’annonces légales”</ListItem>
            <ListItem>Sites concentrateurs et plateformes des organismes</ListItem>
            <ListItem>La Presse quotidienne régionale</ListItem>
          </UnorderedList>
        </VStack>
      </HStack>
    </VStack>
  );
};

export default PostSources;
