import React from "react";
import { VStack, HStack, Text, Divider, Image } from "@chakra-ui/react";
import CustomIcon from "../../shared/Icon/CustomIcon";

const EasyProjectsFeatureItem = ({ imageSrc, title, description, forWho }) => {
  return (
    <HStack className="easyProjectsFeature" spacing="30px">
      <Image src={imageSrc} width="300px"/>
      <Divider className="easyProjectsFeature_divider" orientation="vertical" />
      <VStack className="easyProjectsFeature_content" spacing="30px">
        <VStack className="easyProjectsFeature_description">
          <Text className="easyProjectsFeature_title">{title}</Text>
          <Text className="easyProjectsFeature_paragraph">{description}</Text>
        </VStack>
        <HStack className="easyProjectsFeature_forWho">
          <CustomIcon
            name="arrowRightCircle"
            color="var(--orange)"
            width="24px"
            height="24px"
            alignSelf="flex-start"
          />
          <VStack className="easyProjectsFeature_forWhoContent" spacing="4px">
            <Text className="easyProjectsFeature_forWhoTitle">Pour qui ?</Text>
            <Text className="easyProjectsFeature_forWhoParagraph">
              {forWho}
            </Text>
          </VStack>
        </HStack>
      </VStack>
    </HStack>
  );
};

export default EasyProjectsFeatureItem;
