import React from "react";
import { Stack, Text, Container, Link } from "@chakra-ui/react";

const GeneralConditionsOfSale = () => {
  return (
    <Container maxW='1000px' mt='50px'>
      <Text fontWeight="bold" mb="1.2rem" textAlign="center">
        Politique de protection des données pour les plateformes EasyMarchés et EasyProjets 
      </Text>
      <Text mb="1.2rem" textAlign="justify">  
        <br />WANAO accorde une place importante à la confidentialité de vos informations et au respect de la réglementation en matière de protection des données personnelles. 
        <br />Nous avons établi la présente politique de protection des données qui est destinée à tout visiteur et tout utilisateur du site internet easymarches.fr, comprenant à la fois les plateformes EasyMarchés et EasyProjets (ci-après le <b>« Site »</b>).  
        <br />Nous vous invitons à lire attentivement la présente politique qui a été écrite pour vous aider à comprendre quelles données personnelles WANAO est susceptible de traiter via son Site, pourquoi elle les traite et les droits que vous pouvez exercer sur vos données personnelles.  
        <br />Les « données à caractère personnel » ou « données personnelles » désignent toute information susceptible d’identifier directement ou indirectement une personne physique.  
      </Text>

      <Text fontWeight="bold" mb="0.6rem" style={{marginLeft: '30px'}}>
        1. Qui est le responsable de traitement des données personnelles ? 
      </Text> 
      <Text mb="1.2rem" textAlign="justify">
        WANAO (société par actions simplifiée à associé unique au capital de 138 200 euros, dont le siège est situé au Technopole Izarbel – 230, allée Théodore Monod à Bidart 64210, immatriculée au registre du commerce et des sociétés de Bayonne sous le numéro 438 772 089) est le responsable de tous les traitements de données personnelles effectués via le Site.  
        <br />Vous pouvez contacter WANAO via les coordonnées suivantes : 
        <ul>
          <li style={{marginLeft: '30px'}}>Numéro de téléphone : <Link HREF="tel:0559013070">05 59 01 30 70</Link>  </li>
          <li style={{marginLeft: '30px'}}>Adresse mail : <Link HREF="mailto:support@easymarches.fr">support@easymarches.fr</Link> </li>
        </ul> 
         
      </Text>

      <Text fontWeight="bold" mb="0.6rem" style={{marginLeft: '30px'}}>
        2. Quelles sont les données personnelles collectées par WANAO ?  
      </Text>
      <Text mb="1.2rem" textAlign="justify">
        WANAO peut collecter et traiter certaines données personnelles dans le cadre de l’utilisation du Site et en fonction de votre situation, notamment :  

        <br /><b>Données personnelles collectées pour tout visiteur du Site :</b>
        <ul>
          <li style={{marginLeft: '30px'}}><u>Données de connexion</u> : données de navigation sur notre Site et données collectées par les cookies et traceurs si vous y avez consenti. </li>
        </ul>

        <br /><b>Données personnelles relatives aux clients et utilisateurs disposant d'un compte de connexion sur le Site : </b>
        <ul>
          <li style={{marginLeft: '30px'}}><u>Données d’identification</u> : nom, prénom</li> 
          <li style={{marginLeft: '30px'}}><u>Données professionnelles</u> : adresse mail professionnelle, numéro de téléphone de la ligne directe ou mobile professionnelle, adresse postale (pour des raisons de facturation et uniquement si le client est une société unipersonnelle) </li>
          <li style={{marginLeft: '30px'}}><u>Informations de connexion</u> : logs de connexion </li>
          <li style={{marginLeft: '30px'}}><u>Données bancaires</u> : un numéro de carte bancaire ou IBAN est nécessaire si vous souhaitez disposer d’un abonnement payant. WANAO ne stocke et ne conserve pas ces données, elles sont gérées par notre prestataire STRIPE.  </li>
        </ul>
        

        <br /><b>Les informations présentes sur notre Site et mises à disposition des utilisateurs du Site :</b>
        <br />il s’agit de données uniquement professionnelles relatives à des sociétés, des appels d’offres ou encore dans des documents administratifs. Il peut notamment s’agir des données suivantes : nom, prénom, numéro de téléphone professionnel, adresse mail professionnelle, fonction, entreprise. 

        <br /><br />Aucune donnée sensible n’est traitée par WANAO dans le cadre de l’utilisation du Site.  
      </Text>

      <Text fontWeight="bold" mb="0.6rem" style={{marginLeft: '30px'}}>
        3. Pourquoi WANAO collecte-t-elle vos données personnelles ? 
      </Text> 
      <Text mb="1.2rem" textAlign="justify">
        WANAO est susceptible de traiter vos données personnelles pour les finalités principales suivantes :  
        <ul>
          <li style={{marginLeft: '30px'}}>La gestion de votre abonnement et de votre compte client </li> 
          <li style={{marginLeft: '30px'}}>La gestion de la facturation de votre abonnement le cas échéant </li>
          <li style={{marginLeft: '30px'}}>La gestion du Site internet easymarchés.fr  </li>
          <li style={{marginLeft: '30px'}}>La gestion de vos demandes de contact</li>  
          <li style={{marginLeft: '30px'}}>La communication d’informations sur nos offres et services à destination de nos clients et prospects</li> 
          <li style={{marginLeft: '30px'}}>Des statistiques d’utilisation du Site </li>
          <li style={{marginLeft: '30px'}}>La mise à disposition pour les utilisateurs du Site d’informations liées à des sociétés, des projets, des appels d’offres de marchés publics</li>  
          <li style={{marginLeft: '30px'}}>La prévention et la détection de problèmes de sécurité, de menaces, de fraude ou toute autre activité illicite concernant l’utilisation de nos services</li>
        </ul> 
      </Text>

      <Text fontWeight="bold" mb="0.6rem" style={{marginLeft: '30px'}}>
        4. Sur quelles bases juridiques reposent les traitements de données ?  
      </Text>
      <Text mb="1.2rem" textAlign="justify">
        En fonction des finalités, la base juridique peut être :  
        <ul>
          <li style={{marginLeft: '30px'}}><u>L’exécution du contrat ou de mesures précontractuelles</u> : le traitement des données personnelles des clients et utilisateurs est nécessaire pour l’exécution du contrat auquel ils ont souscrit ou en amont de la conclusion de ce contrat.  </li>
          <li style={{marginLeft: '30px'}}><u>Le consentement de la personne</u> : lorsqu’il y a consentement au dépôt de cookies et traceurs. </li>
          <li style={{marginLeft: '30px'}}><u>L’obligation légale</u> : la loi soumet WANAO à l’obligation de traiter certaines données personnelles (exemple : obligation d’établir une facture).  </li>
          <li style={{marginLeft: '30px'}}><u>L’intérêt légitime</u> : WANAO justifie d’un intérêt légitime lié au développement commercial de son activité lorsqu’elle traite certaines données. </li>
        </ul>
      </Text>

      <Text fontWeight="bold" mb="0.6rem" style={{marginLeft: '30px'}}>
        5. Comment WANAO collecte-t-elle vos données personnelles ?  
      </Text>
      <Text mb="1.2rem" textAlign="justify">
        Nous pouvons collecter vos données personnelles directement auprès de vous, lorsque vous créez votre compte et que vous souscrivez à un abonnement, ou lorsque vous nous contactez via le formulaire de contact disponible sur le Site. 
        <br />Nous collectons également des données de façon indirecte : les données présentes sur le Site peuvent provenir de ressources accessibles publiquement, tels que des documents administratifs et des appels d’offres publics. Elles peuvent également être transmises par notre partenaire EXPLORE, société d’EXPLORE Group, dont fait partie WANAO.  
        <br />Pour tous les visiteurs du Site, certaines données sont collectées par nos outils informatiques et par le biais des cookies présents sur le Site si vous y avez consenti.  
      </Text>

      <Text fontWeight="bold" mb="0.6rem" style={{marginLeft: '30px'}}>
        6. Quels sont les destinataires de vos données personnelles ?  
      </Text>
      <Text mb="1.2rem" textAlign="justify">
        Aux fins des finalités précitées, les destinataires suivants peuvent avoir accès aux données personnelles selon les cas :  
        <ul>
          <li style={{marginLeft: '30px'}}>Certains services internes de WANAO : Service administratif, Service informatique, Service Product Management, Service Projets.</li> 
          <li style={{marginLeft: '30px'}}>Certains services internes des entités d’EXPLORE Group, comme le Service informatique en charge de la sécurité des outils du Groupe.  </li>
          <li style={{marginLeft: '30px'}}>Certains sous-traitants et prestataires de WANAO (exemple : prestataire de paiement STRIPE).  </li>
          <li style={{marginLeft: '30px'}}>Sur requête et sous réserve du respect des procédures : les autorités administratives, judiciaires ou des organismes détenant des pouvoirs et autorisations spéciaux.  </li>
          <li style={{marginLeft: '30px'}}>Concernant les informations mises à disposition sur le Site : les utilisateurs du Site.</li> 
        </ul> 
      </Text>
  

      <Text fontWeight="bold" mb="0.6rem" style={{marginLeft: '30px'}}>
        7. Quelles sont les durées de conservation de vos données personnelles ?  
      </Text>
      <Text mb="1.2rem" textAlign="justify">
        WANAO conserve vos données jusqu’à ce que les finalités pour lesquelles celles-ci ont été collectées soient atteintes ainsi que dans le respect de la législation en vigueur. 
        <br />Les délais de conservation des données varient selon la nature des données, la finalité des traitements ou les exigences légales ou réglementaires. Par exemple : 
        <ul>
          <li style={{marginLeft: '30px'}}>Lorsque l’utilisateur qui bénéficie d’une Offre Découverte ne se connecte pas à son compte pendant 3 ans, toutes les données personnelles fournies par l’utilisateur sont supprimées dans un délai d’un mois après l’envoi d’un mail invitant l’utilisateur à se reconnecter.  </li> 
          <li style={{marginLeft: '30px'}}>Les documents comptables (commandes, factures) sont conservés pendant une durée de 10 ans. </li> 
          <li style={{marginLeft: '30px'}}>Les données des prospects sont conservées pendant un délai de 3 ans à compter du dernier contact.</li> 
        </ul>  
      </Text>
  

      <Text fontWeight="bold" mb="0.6rem" style={{marginLeft: '30px'}}>
        8. Quelles sont les mesures de sécurité permettant la protection de vos données personnelles ?  
      </Text>
      <Text mb="1.2rem" textAlign="justify">
        WANAO met tout en œuvre pour sécuriser vos données, et notamment protéger vos données contre tout accès, toute diffusion, toute modification ou destruction non autorisée.  
        <br />Des mesures de sécurité techniques et organisationnelles sont mises en place, par exemple :  
        <ul>
          <li style={{marginLeft: '30px'}}>Chiffrement des données personnelles lors de transferts  </li>
          <li style={{marginLeft: '30px'}}>Mise à jour régulière des équipements de l’infrastructure  </li>
          <li style={{marginLeft: '30px'}}>Cloisonnement des réseaux par des équipements filtrants  </li>
        </ul>
      </Text>
  

    <Text fontWeight="bold" mb="0.6rem" style={{marginLeft: '30px'}}>
      9. Quels sont les droits dont vous disposez concernant vos données personnelles ? 
    </Text>
    <Text mb="1.2rem" textAlign="justify">
      Conformément à la réglementation, toute personne dont WANAO traite les données personnelles bénéficie des droits suivants :  

      <ul>
        <li style={{marginLeft: '30px'}}><u>Droit d’accès et d’information</u> : vous pouvez obtenir une copie de vos données personnelles et des informations concernant les traitements effectués (articles 13, 14 et 15 du RGPD) ;  </li>
        <li style={{marginLeft: '30px'}}><u>Droit de rectification</u> : vous pouvez demander la rectification de vos données personnelles dans le cas où elles seraient inexactes (article 16 du RGPD) ; </li>
        <li style={{marginLeft: '30px'}}><u>Droit d’opposition</u> : vous pouvez vous opposer au traitement de vos données personnelles sous certaines conditions (article 21 du RGPD) ; </li>
        <li style={{marginLeft: '30px'}}><u>Droit à l’effacement</u> : sous certaines conditions, vous pouvez demander l’effacement de vos données personnelles (article 17 du RGPD) ; </li>
        <li style={{marginLeft: '30px'}}><u>Droit à la limitation</u> : sous certaines conditions, vous pouvez demander à limiter le traitement de vos données personnelles (article 18 du RGPD) ;  </li>
        <li style={{marginLeft: '30px'}}><u>Droit à la portabilité</u> : vous pouvez recevoir dans un format structuré les données personnelles détenues par WANAO et demander à les transmettre à un autre responsable de traitement, lorsque le traitement est fondé sur le consentement ou sur le contrat et qu’il est effectué à l’aide de procédés automatisés (article 20 du RGPD) ; </li>
        <li style={{marginLeft: '30px'}}><u>Droit de retrait du consentement</u> : vous pouvez retirer votre consentement à tout moment lorsque le traitement est fondé sur celui-ci (article 13 du RGPD) ; </li>
        <li style={{marginLeft: '30px'}}><u>Droit d’établir des directives post-mortem</u> : vous pouvez définir les directives relatives au sort de vos données personnelles après votre décès. En l’absence de ces dernières, vos héritiers peuvent exercer certains droits sur vos données personnelles.  </li>
      </ul>
      Vous pouvez exercer ces droits en effectuant une demande par mail à l’adresse suivante : 
      <Text mb="1.2rem" textAlign="center"><Link HREF="mailto:support@easymarches.fr">support@easymarches.fr</Link></Text> 
      ou par courrier à l’adresse postale :
      <Text mb="1.2rem" textAlign="center">WANAO, Technopole Izarbel, 230 allée Théodore Monod, Bidart (64210).</Text>  
      En cas de doute sur votre identité dans le cadre de l’exercice de vos droits, WANAO se réserve le droit de vous demander de justifier celle-ci.  
      <br />Vous avez également le droit d’adresser une réclamation auprès de l’autorité de contrôle compétente, à savoir la CNIL en France.
    </Text>  

    <Text fontWeight="bold" mb="0.6rem" style={{marginLeft: '30px'}}>
      10. Vos données personnelles sont-elles transférées hors UE ?  
    </Text>
    <Text mb="1.2rem" textAlign="justify">
      WANAO stocke les données personnelles qu’elle traite en France. 
      <br />Certaines données personnelles traitées par WANAO peuvent faire l’objet d’un transfert hors de l’Union européenne, au sens du RGPD, lorsqu’elles sont traitées par certains prestataires ou via certains outils informatiques. Dans ce cas, WANAO prend toutes les mesures nécessaires afin de garantir la protection des données personnelles et ce, en conformité avec la réglementation. 
      <br />Lorsque la législation du pays tiers ne fait pas l’objet d’une décision d’adéquation de la Commission européenne, WANAO met ainsi en œuvre les garanties appropriées afin d’assurer un niveau de protection des données personnelles équivalent à celui prévu par l’Union Européenne, notamment via la conclusion des clauses contractuelles types établies par la Commission européenne. 
    </Text>

    <Text fontWeight="bold" mb="0.6rem" style={{marginLeft: '30px'}}>
      11. Modification de la politique de protection des données 
    </Text> 
    <Text mb="1.2rem" textAlign="justify">
      Si nous modifions la présente politique de protection des données, nous publierons la version révisée avec une date de révision mise à jour sur le Site. 
    </Text>

  <Text fontWeight="bold" mb="0.6rem" style={{marginLeft: '30px'}}>
    12. Contact  
  </Text>
  <Text mb="1.2rem" textAlign="justify">
    Pour toute information ou question relative à la présente politique, vous pouvez contacter le/la Délégué.e à la protection des données (DPO) en charge des questions relatives à la protection des données personnelles à l’adresse mail : 
    <Text mb="1.2rem" textAlign="center"><Link HREF="mailto:dpo_wanao@wanao.fr">dpo_wanao@wanao.fr</Link></Text> 
    ou à l’adresse postale suivante : 
    <Text mb="1.2rem" textAlign="center">WANAO, DPO, Technopole Izarbel, 230 allée Théodore Monod, 64210 Bidart.</Text> 
    <br /><br />Cette politique de protection des données a été mise à jour le 3 mars 2023.  
  </Text>
</Container>
 

 

 

 

 
  );
};

export default GeneralConditionsOfSale;
