import AxiosHttpApi from "../core/AxiosHttpApi";
import { areSearchParametersEmpty } from "../helpers/commonUtils";
const BASE_TARGET = `/projects`;

class ProjectsHttpApi extends AxiosHttpApi {
  abortController = null;

  getSearchStats = async () => {
    return this.getRequest(`${BASE_TARGET}/stats`);
  };

  searchProjects = async (searchParams, isAuthenticated) => {
    const route = isAuthenticated ? "search" : "public-search"
    if(this.abortController) this.abortController.abort();
    this.abortController = new AbortController();
    if(areSearchParametersEmpty(searchParams)) throw new Error("Search Parameters are empty");
    return this.postRequest(`${BASE_TARGET}/${route}`, searchParams, this.abortController.signal);
  };

  getProjectBasicDetails = async (projectId, userId) => {
    return this.getRequest(
      `${BASE_TARGET}/${projectId}/detail` + (userId ? `?userId=${userId}` : "")
    );
  };

  getProjectInformations = async (projectId) => {
    return this.getRequest(`${BASE_TARGET}/${projectId}/proj`);
  };

  getAssetsTypes = async (projectId) => {
    return this.getRequest(`${BASE_TARGET}/${projectId}/nature`);
  };

  getMOAdata = async (projectId) => {
    return this.getRequest(`${BASE_TARGET}/${projectId}/moa`);
  };

  getMOEdata = async (projectId) => {
    return this.getRequest(`${BASE_TARGET}/${projectId}/moe`);
  };

  getMOEPreviewData = async (projectId) => {
    return this.getRequest(`${BASE_TARGET}/${projectId}/moe/preview`);
  };

  getProjectPermitData = async (projectId) => {
    return this.getRequest(`${BASE_TARGET}/${projectId}/permit`);
  }
}

export default new ProjectsHttpApi();
