import userHttpApi from "../../api/userHttpApi";
import { userTypes } from "../../constants/userConstants";
import {
  notifMessages,
  notifTypes,
} from "../../constants/notificationConstants";
import { orderSteps } from "../../constants/orderConstants";
import { getRouterHistory, getUrlParams } from "../../helpers/routerHelper";
import { getErrorKey, getErrorMessage } from "../../helpers/errorHelper";
import userActionTypes from "../actionsTypes/userActionTypes";
import { setLoading, showNotif } from "./appActionCreators";
import {
  clearCart,
  navigateToOrderStep,
  removePromoCodeFromCart,
  setPendingOrderStep,
} from "./orderActionCreators";
import { getToken } from "../../helpers/sessionHelper";
import { PROJECTS } from "../../constants/generalConstants";
import generalActionTypes from "../actionsTypes/generalActionTypes";
import { clearSearch } from "./searchProjectsActionCreators";

/**
 *  Authentifier le user
 *  et le rediriger vers la page offres
 *  s'il est arrivé de celle-là
 *
 * @async
 * @param  {object} signInData - L'identifiant et le mot de passe
 */
export const signIn = (signInData, project) => async (dispatch, getState) => {
  const history = getRouterHistory();
  try {
    const params = getUrlParams();
    const redirectUrl = params.redirect;
    const res = await userHttpApi.signIn(signInData);
    dispatch({
      type: userActionTypes.SIGNIN,
      payload: res?.data?.data,
    });
    const { user } = getState().userReducer;

    const isSubscribedToCompleteOffer =
      user?.type === userTypes.premium &&
      user?.easyProjectsType === userTypes.premium;

    const type =
      project === PROJECTS.easyao ? user?.type : user?.easyProjectsType;
    if (redirectUrl) {
      if (type === userTypes.premium) {
        history.push("/search");
        dispatch(setPendingOrderStep(false));
        return dispatch(
          showNotif(
            isSubscribedToCompleteOffer
              ? notifMessages.alreadySubscribedToCompleteOffer
              : project === PROJECTS.easyao
              ? notifMessages.alreadySubscribed
              : notifMessages.alreadySubscribedToEasyProjects
          )
        );
      }
      return history.push(redirectUrl, { project });
    }
    if (
      user?.type === userTypes.premium &&
      user?.easyProjectsType !== userTypes.premium
    ) {
      dispatch({
        type: generalActionTypes.SET_PROJECT,
        payload: PROJECTS.easyao,
      });
    } else if (
      user?.type !== userTypes.premium &&
      user?.easyProjectsType === userTypes.premium
    ) {
      dispatch({
        type: generalActionTypes.SET_PROJECT,
        payload: PROJECTS.easyprojects,
      });
    }
    history.push("/search");
  } catch (err) {
    const errorKey = getErrorKey(err);
    if (errorKey === "accountNotEnabled")
      return history.push("/account/resendemail");
    dispatch(
      showNotif({ message: getErrorMessage(err), type: notifTypes.error })
    );
  }
};

/**
 *  Inscrire le user
 *
 * @async
 * @param  {object} userData - Les données du user
 */
export const signUp = (userData) => async (dispatch) => {
  const history = getRouterHistory();
  try {
    const res = await userHttpApi.signUp(userData);

    history.push("/account/created");
    dispatch({
      type: userActionTypes.SIGNUP,
      payload: res?.data?.data,
    });
  } catch (err) {
    dispatch(
      showNotif({ message: getErrorMessage(err), type: notifTypes.error })
    );
  }
};

/**
 *  Récupérer les données du user
 *
 * @async
 */
export const initUser = (showLoader = false) => async (dispatch, getState) => {
  try {
    const token = getToken();
    if (!token) throw new Error();
    if(showLoader) dispatch(setLoading(true));
    const res = await userHttpApi.getUser();
    dispatch({
      type: userActionTypes.INIT_USER_DATA,
      payload: res?.data?.data,
    });
    dispatch({
      type: userActionTypes.LOADING_USER_DATA,
      payload: false,
    });
    if(showLoader) dispatch(setLoading(false));
  } catch (err) {
    const isAuthenticated = getState()?.userReducer?.isAuthenticated;
    if (isAuthenticated) dispatch(logOut());
    if(showLoader) dispatch(setLoading(false));
    dispatch({
      type: userActionTypes.LOADING_USER_DATA,
      payload: false,
    });
  }
};

/**
 *  Envoyer un email pour demander la réinitialisation du mot de passe
 *
 * @async
 * @param  {object} data - Objet contient l'email du user
 */
export const resetPassword = (data) => async (dispatch) => {
  const history = getRouterHistory();
  try {
    await userHttpApi.resetPassword(data);
    history.push("/account/emailsent?context=forgotpassword");
  } catch (err) {
    dispatch(
      showNotif({ message: getErrorMessage(err), type: notifTypes.error })
    );
  }
};

/**
 *  Envoyer le nouveau mot de passe pour changer l'ancien
 *
 * @async
 * @param  {object} data - Objet contient le nouveau mot de passe
 */
export const confirmResetPassword = (data) => async (dispatch) => {
  const history = getRouterHistory();
  try {
    await userHttpApi.confirmResetPassword(data);
    history.push("/account/signin/?notify=passwordUpdated");
  } catch (err) {
    dispatch(
      showNotif({ message: getErrorMessage(err), type: notifTypes.error })
    );
  }
};

/**
 *  Sauvegarder les données insérées dans la 1ère étape de la commande
 *
 * @async
 * @param  {object} data
 */
export const setPendingUserData = (data) => async (dispatch) => {
  dispatch({
    type: userActionTypes.SET_PENDING_USER_DATA,
    payload: data,
  });
};

/**
 *  Mettre à jour les données user
 *
 * @async
 * @param  {object} data - les nouvelles données du user
 */
export const updateUserData =
  (userData, isOrder = false, project) =>
  async (dispatch) => {
    try {
      userData.confirmEmail = userData.email;
      const res = await userHttpApi.updateUserData(userData);
      dispatch({
        type: userActionTypes.UPDATE_USER,
        payload: res?.data?.data,
      });
      if (!isOrder) dispatch(showNotif(notifMessages.informationUpdated));
      else {
        dispatch(navigateToOrderStep(orderSteps.step3, project));
      }
    } catch (err) {
      dispatch(
        showNotif({ message: getErrorMessage(err), type: notifTypes.error })
      );
    }
  };

/**
 *  Mettre à jour le mot de passe d'utilisateur
 *
 * @async
 * @param  {object} data
 * @param  {string} data.oldPassword
 * @param  {string} data.password
 * @param  {string} data.confirmPassword
 */
export const updateUserPassword =
  (data, callBack = false) =>
  async (dispatch) => {
    try {
      await userHttpApi.updateUserPassword(data);
      dispatch(showNotif(notifMessages.passwordUpdated));
      if (typeof callBack === "function") callBack();
    } catch (err) {
      dispatch(
        showNotif({ message: getErrorMessage(err), type: notifTypes.error })
      );
    }
  };

/**
 *  Déconnecter le user et vider le panier
 *
 * @async
 * @param  {object} data - les nouvelles données du user
 */

export const logOut = () => async (dispatch, getState) => {
  const { cart } = getState().orderReducer;
  dispatch(removePromoCodeFromCart(cart?.id, false));
  dispatch(clearCart());
  dispatch(setPendingOrderStep(false));
  dispatch(clearSearch());
  dispatch({
    type: userActionTypes.LOGOUT,
  });
};

/**
 *  Renvoyer l'email d'activation
 *
 * @async
 * @param  {object} data
 * @param  {string} data.email
 */
export const sendActivationEmail = (data) => async (dispatch) => {
  const history = getRouterHistory();
  try {
    await userHttpApi.sendActivationEmail(data);
    history.push("/account/emailsent?context=activation");
  } catch (err) {
    dispatch(
      showNotif({ message: getErrorMessage(err), type: notifTypes.error })
    );
  }
};

/**
 *  Fermer la session utilisateur
 *
 * @async
 * @param  {object} data
 * @param  {string} data.email
 */
export const closeUserSession = () => async (dispatch) => {
  const history = getRouterHistory();
  dispatch(logOut());
  history.push("/account/signin");
  const timeout = setTimeout(() => {
    dispatch(showNotif(notifMessages.sessionExpired));
    clearTimeout(timeout);
  }, 100);
};
